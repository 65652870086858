{
  "cities": [
    {
      "id": 0,
      "name": "Tokyo",
      "names": {
        "es": "Tokio",
        "zh": "东京",
        "de": "Tokio",
        "ja": "東京都",
        "th": "โตเกียว",
        "pt": "Tóquio"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6812546,
        "lng": 139.766706
      },
      "country": "Japan",
      "importance": 0,
      "bbox": [
        139.761706,
        35.6762546,
        139.771706,
        35.6862546
      ]
    },
    {
      "id": 1,
      "name": "Jakarta",
      "names": {
        "es": "Yakarta",
        "zh": "雅加达",
        "ja": "ジャカルタ",
        "th": "จาการ์ตา",
        "pt": "Jacarta"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.182283,
        "lng": 106.839426
      },
      "country": "Indonesia",
      "importance": 0,
      "bbox": [
        106.791799,
        -6.229509,
        106.881399,
        -6.135184
      ]
    },
    {
      "id": 2,
      "name": "Delhi",
      "names": {
        "zh": "新德里",
        "ja": "デリー",
        "th": "นิวเดลี",
        "pt": "Deli"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6273928,
        "lng": 77.1716954
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        76.8388351,
        28.4046285,
        77.3452524,
        28.8834464
      ]
    },
    {
      "id": 3,
      "name": "Manila",
      "names": {
        "fr": "Manille",
        "zh": "马尼拉",
        "ja": "マニラ",
        "th": "มะนิลา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5906346,
        "lng": 120.9799964
      },
      "country": "Philippines",
      "importance": 0,
      "bbox": [
        120.7917034,
        14.5508249,
        121.0261672,
        14.6395473
      ]
    },
    {
      "id": 4,
      "name": "Sao Paulo",
      "names": {
        "zh": "圣保罗",
        "ja": "サンパウロ",
        "th": "เซาเปาโล",
        "pt": "São Paulo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5506507,
        "lng": -46.6333824
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -46.8262692,
        -24.0079003,
        -46.3650898,
        -23.3577551
      ]
    },
    {
      "id": 5,
      "name": "Seoul",
      "names": {
        "es": "Seúl",
        "fr": "Séoul",
        "zh": "首尔",
        "ja": "ソウル",
        "th": "โซล",
        "pt": "Seul"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.5666791,
        "lng": 126.9782914
      },
      "country": "South Korea",
      "importance": 0,
      "bbox": [
        126.7644328,
        37.4285424,
        127.1837702,
        37.7014794
      ]
    },
    {
      "id": 6,
      "name": "Mumbai",
      "names": {
        "zh": "孟买",
        "ja": "ムンバイ",
        "th": "มุมไบ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.08157715,
        "lng": 72.88662753964906
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        72.776333,
        18.8939566,
        72.9817485,
        19.2694771
      ]
    },
    {
      "id": 7,
      "name": "Shanghai",
      "names": {
        "zh": "上海",
        "ja": "上海",
        "th": "เซี่ยงไฮ้"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2312707,
        "lng": 121.4700152
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        118.6001842,
        30.6693052,
        123.3342088,
        33.4431436
      ]
    },
    {
      "id": 8,
      "name": "Mexico City",
      "names": {
        "es": "Ciudad de México",
        "fr": "Mexico",
        "zh": "墨西哥城市",
        "de": "Mexiko Stadt",
        "it": "Città del Messico",
        "ja": "メキシコシティ",
        "th": "เม็กซิโกซิตี้",
        "pt": "Cidade do México"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4326296,
        "lng": -99.1331785
      },
      "country": "Mexico",
      "importance": 0,
      "bbox": [
        -99.3649242,
        19.0487187,
        -98.9403028,
        19.5927573
      ]
    },
    {
      "id": 9,
      "name": "Guangzhou",
      "names": {
        "zh": "广州",
        "ja": "広州",
        "th": "กว่างโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1301964,
        "lng": 113.2592945
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        112.9523727,
        22.5607979,
        114.055299,
        23.935994
      ]
    },
    {
      "id": 10,
      "name": "Cairo",
      "names": {
        "es": "El Cairo",
        "fr": "Le Caire",
        "zh": "开罗",
        "de": "Kairo",
        "it": "Il Cairo",
        "id": "Kairo",
        "ja": "カイロ",
        "th": "ไคโร"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.0443879,
        "lng": 31.2357257
      },
      "country": "Egypt",
      "importance": 0,
      "bbox": [
        31.2200331,
        29.7483062,
        31.9090054,
        30.3209168
      ]
    },
    {
      "id": 11,
      "name": "Beijing",
      "names": {
        "zh": "北京",
        "de": "Peking",
        "it": "Pechino",
        "ja": "北京",
        "th": "ปักกิ่ง",
        "pt": "Pequim"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9057136,
        "lng": 116.3912972
      },
      "country": "China",
      "importance": 0,
      "bbox": [
        116.2312972,
        39.7457136,
        116.5512972,
        40.0657136
      ]
    },
    {
      "id": 12,
      "name": "New York",
      "names": {
        "es": "Nueva York",
        "zh": "纽约",
        "ja": "ニューヨーク",
        "th": "นิวยอร์ก",
        "pt": "Nova york"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7127281,
        "lng": -74.0060152
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.258843,
        40.476578,
        -73.700233,
        40.91763
      ]
    },
    {
      "id": 13,
      "name": "Kolkata",
      "names": {
        "zh": "加尔各答",
        "ja": "コルカタ",
        "th": "โกลกาตา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.5726459,
        "lng": 88.3638953
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        88.233628,
        22.4520292,
        88.4610776,
        22.6188255
      ]
    },
    {
      "id": 14,
      "name": "Moscow",
      "names": {
        "es": "Moscú",
        "fr": "Moscou",
        "zh": "莫斯科",
        "de": "Moskau",
        "it": "Mosca",
        "id": "Moskow",
        "ja": "モスクワ",
        "th": "มอสโก"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7505412,
        "lng": 37.6174782
      },
      "country": "Russia",
      "importance": 0,
      "bbox": [
        37.290502,
        55.4913076,
        37.9674277,
        55.9577717
      ]
    },
    {
      "id": 15,
      "name": "Bangkok",
      "names": {
        "zh": "曼谷",
        "ja": "バンコク",
        "th": "กรุงเทพฯ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.7390309,
        "lng": 100.5167281
      },
      "country": "Thailand",
      "importance": 0,
      "bbox": [
        100.5117281,
        13.7340309,
        100.5217281,
        13.7440309
      ]
    },
    {
      "id": 16,
      "name": "Dhaka",
      "names": {
        "zh": "达卡",
        "it": "Dacca",
        "ja": "ダッカ",
        "th": "ธากา"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.7644025,
        "lng": 90.389015
      },
      "country": "Bangladesh",
      "importance": 0,
      "bbox": [
        90.229015,
        23.6044025,
        90.549015,
        23.9244025
      ]
    },
    {
      "id": 17,
      "name": "Buenos Aires",
      "names": {
        "zh": "布宜诺斯艾利斯",
        "ja": "ブエノスアイレス",
        "th": "บัวโนสไอเรส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6073387,
        "lng": -58.4432852
      },
      "country": "Argentina",
      "importance": 0,
      "bbox": [
        -58.5314494,
        -34.705637,
        -58.3351423,
        -34.5265535
      ]
    },
    {
      "id": 18,
      "name": "Osaka",
      "names": {
        "zh": "大阪",
        "ja": "大阪",
        "th": "โอซาก้า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7021912,
        "lng": 135.4955866
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        135.4905866,
        34.6971912,
        135.5005866,
        34.7071912
      ]
    },
    {
      "id": 19,
      "name": "Lagos",
      "names": {
        "zh": "拉戈斯",
        "ja": "ラゴス",
        "th": "ลากอส"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.4550575,
        "lng": 3.3941795
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        3.2341795,
        6.2950575,
        3.5541795,
        6.6150575
      ]
    },
    {
      "id": 20,
      "name": "Istanbul",
      "names": {
        "es": "Estambul",
        "zh": "伊斯坦布尔",
        "ja": "イスタンブール",
        "th": "อิสตันบูล"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.006381,
        "lng": 28.9758715
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        28.8158715,
        40.846381,
        29.1358715,
        41.166381
      ]
    },
    {
      "id": 21,
      "name": "Karachi",
      "names": {
        "zh": "卡拉奇",
        "de": "Karatschi",
        "ja": "カラチ",
        "th": "การาจี"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 24.8546842,
        "lng": 67.0207055
      },
      "country": "Pakistan",
      "importance": 1,
      "bbox": [
        66.2862312,
        24.4273517,
        67.5827753,
        25.676796
      ]
    },
    {
      "id": 22,
      "name": "Kinshasa",
      "names": {
        "zh": "金沙萨",
        "ja": "キンシャサ",
        "th": "กินชาซา"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -4.32724375,
        "lng": 15.310943690229927
      },
      "country": "Congo (Kinshasa)",
      "importance": 0,
      "bbox": [
        15.3076845,
        -4.329711,
        15.3140597,
        -4.325191
      ]
    },
    {
      "id": 23,
      "name": "Shenzhen",
      "names": {
        "zh": "深圳",
        "ja": "深セン",
        "th": "เซินเจิ้น"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5445741,
        "lng": 114.0545429
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.6793565,
        22.2875999,
        115.3839104,
        23.0165174
      ]
    },
    {
      "id": 24,
      "name": "Bangalore",
      "names": {
        "fr": "Bangalore,",
        "zh": "班加罗尔",
        "ja": "バンガロール",
        "th": "บังกาลอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.9767936,
        "lng": 77.590082
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        77.430082,
        12.8167936,
        77.750082,
        13.1367936
      ]
    },
    {
      "id": 25,
      "name": "Ho Chi Minh City",
      "names": {
        "es": "Ciudad Ho Chi Minh",
        "fr": "Ho Chi Minh-Ville",
        "zh": "胡志明市",
        "id": "Kota Ho Chi Minh",
        "ja": "ホーチミン市",
        "th": "เมืองโฮจิมินห์",
        "pt": "Cidade de Ho Chi Minh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.781971,
        "lng": 106.693081
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.594505,
        10.694283,
        106.881266,
        10.903333
      ]
    },
    {
      "id": 26,
      "name": "Tehran",
      "names": {
        "es": "Teherán",
        "fr": "Téhéran",
        "zh": "德黑兰",
        "de": "Teheran",
        "it": "Teheran",
        "id": "Teheran",
        "ja": "テヘラン",
        "th": "เตหะราน",
        "pt": "Teerã"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.6892523,
        "lng": 51.3896004
      },
      "country": "Iran",
      "importance": 0,
      "bbox": [
        51.0892219,
        35.5682071,
        51.6063007,
        35.8284702
      ]
    },
    {
      "id": 27,
      "name": "Los Angeles",
      "names": {
        "zh": "洛杉矶",
        "ja": "ロサンゼルス",
        "th": "ลอสแองเจลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0536909,
        "lng": -118.242766
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.6681798,
        33.659541,
        -118.1552983,
        34.337306
      ]
    },
    {
      "id": 28,
      "name": "Rio de Janeiro",
      "names": {
        "es": "Río de Janeiro",
        "zh": "里约热内卢",
        "ja": "リオデジャネイロ",
        "th": "ริโอเดอจาเนโร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9110137,
        "lng": -43.2093727
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -43.796252,
        -23.0827051,
        -43.0990811,
        -22.7460878
      ]
    },
    {
      "id": 29,
      "name": "Chengdu",
      "names": {
        "zh": "成都",
        "ja": "成都",
        "th": "เฉิงตู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6598628,
        "lng": 104.0633717
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        102.989623,
        30.09155,
        104.8948475,
        31.4370968
      ]
    },
    {
      "id": 30,
      "name": "Baoding",
      "names": {
        "zh": "保定"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8579735,
        "lng": 115.490696
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.7599148,
        38.2322179,
        116.2421325,
        39.9512102
      ]
    },
    {
      "id": 31,
      "name": "Chennai",
      "names": {
        "zh": "钦奈",
        "ja": "チェンナイ",
        "th": "เจนไน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.0836939,
        "lng": 80.270186
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        80.110186,
        12.9236939,
        80.430186,
        13.2436939
      ]
    },
    {
      "id": 32,
      "name": "Lahore",
      "names": {
        "zh": "拉合尔",
        "ja": "ラホール",
        "th": "์"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.5656822,
        "lng": 74.3141829
      },
      "country": "Pakistan",
      "importance": 1,
      "bbox": [
        74.1541829,
        31.4056822,
        74.4741829,
        31.7256822
      ]
    },
    {
      "id": 33,
      "name": "London",
      "names": {
        "es": "Londres",
        "fr": "Londres",
        "zh": "伦敦",
        "it": "Londra",
        "ja": "ロンドン",
        "th": "ลอนดอน",
        "pt": "Londres"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5074456,
        "lng": -0.1277653
      },
      "country": "United Kingdom",
      "importance": 0,
      "bbox": [
        -0.5103751,
        51.2867601,
        0.3340155,
        51.6918741
      ]
    },
    {
      "id": 34,
      "name": "Paris",
      "names": {
        "es": "París",
        "zh": "巴黎",
        "it": "Parigi",
        "ja": "パリ",
        "th": "ปารีส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8534951,
        "lng": 2.3483915
      },
      "country": "France",
      "importance": 0,
      "bbox": [
        2.224122,
        48.8155755,
        2.4697602,
        48.902156
      ]
    },
    {
      "id": 35,
      "name": "Tianjin",
      "names": {
        "zh": "天津",
        "ja": "天津",
        "th": "เทียนจิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1357517,
        "lng": 117.2036841
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        117.1986841,
        39.1307517,
        117.2086841,
        39.1407517
      ]
    },
    {
      "id": 36,
      "name": "Linyi",
      "names": {
        "zh": "临沂"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1032403,
        "lng": 118.3506988
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.4111247,
        34.3785778,
        119.1951052,
        36.2064568
      ]
    },
    {
      "id": 37,
      "name": "Shijiazhuang",
      "names": {
        "zh": "石家庄",
        "ja": "石家荘",
        "th": "ฉือเจียจวง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0429742,
        "lng": 114.5088385
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        113.5171832,
        37.4411105,
        115.4770713,
        38.7626343
      ]
    },
    {
      "id": 38,
      "name": "Zhengzhou",
      "names": {
        "zh": "郑州",
        "ja": "鄭州",
        "th": "เจิ้งโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7533392,
        "lng": 113.6599983
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        112.7142104,
        34.2629201,
        114.2054503,
        34.9877486
      ]
    },
    {
      "id": 39,
      "name": "Nanyang",
      "names": {
        "zh": "南洋",
        "th": "นันยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.0010496,
        "lng": 112.5292693
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.9761698,
        32.271201,
        113.8090804,
        33.8019402
      ]
    },
    {
      "id": 40,
      "name": "Hyderabad",
      "names": {
        "zh": "海得拉巴",
        "ja": "ハイデラバード",
        "th": "ในไฮเดอราบาด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.360589,
        "lng": 78.4740613
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        78.2387067,
        17.2916377,
        78.6223912,
        17.5608321
      ]
    },
    {
      "id": 41,
      "name": "Wuhan",
      "names": {
        "zh": "武汉",
        "ja": "武漢",
        "th": "หวู่ฮั่น"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5951051,
        "lng": 114.2999353
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        113.696653,
        29.9719559,
        115.076933,
        31.362241
      ]
    },
    {
      "id": 42,
      "name": "Handan",
      "names": {
        "zh": "邯郸",
        "ja": "邯鄲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.60972,
        "lng": 114.487632
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.408436,
        36.547323,
        114.600627,
        36.662717
      ]
    },
    {
      "id": 43,
      "name": "Nagoya",
      "names": {
        "zh": "名古屋",
        "ja": "名古屋",
        "th": "นาโกย่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1687934,
        "lng": 136.882394
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        136.877394,
        35.1637934,
        136.887394,
        35.1737934
      ]
    },
    {
      "id": 44,
      "name": "Weifang",
      "names": {
        "zh": "潍坊",
        "ja": "濰坊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6961413,
        "lng": 119.0915313
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.0865313,
        36.6911413,
        119.0965313,
        36.7011413
      ]
    },
    {
      "id": 45,
      "name": "Lima",
      "names": {
        "zh": "利马",
        "ja": "リマ",
        "th": "ลิมา"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.0621065,
        "lng": -77.0365256
      },
      "country": "Peru",
      "importance": 0,
      "bbox": [
        -77.1992129,
        -12.5199316,
        -76.6208244,
        -11.5724356
      ]
    },
    {
      "id": 46,
      "name": "Zhoukou",
      "names": {
        "zh": "周口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.6306299,
        "lng": 114.6423617
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.4823617,
        33.4706299,
        114.8023617,
        33.7906299
      ]
    },
    {
      "id": 47,
      "name": "Luanda",
      "names": {
        "zh": "罗安达",
        "ja": "ルアンダ",
        "th": "ลูอันดา"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -8.8272699,
        "lng": 13.2439512
      },
      "country": "Angola",
      "importance": 0,
      "bbox": [
        13.1732251,
        -8.9208267,
        13.3108619,
        -8.759227
      ]
    },
    {
      "id": 48,
      "name": "Ganzhou",
      "names": {
        "zh": "赣州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.8332103,
        "lng": 114.9287362
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.7687362,
        25.6732103,
        115.0887362,
        25.9932103
      ]
    },
    {
      "id": 49,
      "name": "Tongshan",
      "names": {
        "zh": "铜山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1824217,
        "lng": 117.1638669
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.8072958,
        34.022111,
        117.6983807,
        34.8173647
      ]
    },
    {
      "id": 50,
      "name": "Kuala Lumpur",
      "names": {
        "zh": "吉隆坡",
        "ja": "クアラルンプール",
        "th": "กัวลาลัมเปอร์"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.1516964,
        "lng": 101.6942371
      },
      "country": "Malaysia",
      "importance": 0,
      "bbox": [
        101.615139,
        3.0334334,
        101.7584948,
        3.2445538
      ]
    },
    {
      "id": 51,
      "name": "Chicago",
      "names": {
        "zh": "芝加哥",
        "ja": "シカゴ",
        "th": "ชิคาโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.87897,
        "lng": -87.66063
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.940114,
        41.644543,
        -87.524137,
        42.023039
      ]
    },
    {
      "id": 52,
      "name": "Heze",
      "names": {
        "zh": "菏泽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2477266,
        "lng": 115.4464761
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.8169103,
        34.552748,
        116.4078069,
        35.8728091
      ]
    },
    {
      "id": 53,
      "name": "Chongqing",
      "names": {
        "zh": "重庆",
        "ja": "重慶",
        "th": "ฉงชิ่ง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5515144,
        "lng": 106.5415811
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        106.5365811,
        29.5465144,
        106.5465811,
        29.5565144
      ]
    },
    {
      "id": 54,
      "name": "Hanoi",
      "names": {
        "zh": "河内",
        "ja": "ハノイ",
        "th": "ฮานอย"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.0242596,
        "lng": 105.8406959
      },
      "country": "Vietnam",
      "importance": 0,
      "bbox": [
        105.8356959,
        21.0192596,
        105.8456959,
        21.0292596
      ]
    },
    {
      "id": 55,
      "name": "Fuyang",
      "names": {
        "zh": "阜阳",
        "ja": "阜陽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.8926763,
        "lng": 115.8090001
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.8761629,
        32.4059374,
        116.628499,
        33.5861619
      ]
    },
    {
      "id": 56,
      "name": "Changsha",
      "names": {
        "zh": "长沙",
        "ja": "長沙",
        "th": "ฉางชา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2312227,
        "lng": 112.9334574
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        112.7734574,
        28.0712227,
        113.0934574,
        28.3912227
      ]
    },
    {
      "id": 57,
      "name": "Dongguan",
      "names": {
        "zh": "东莞",
        "ja": "東莞",
        "th": "ตงกวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0205969,
        "lng": 113.7457788
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.5164763,
        22.6564773,
        114.2552753,
        23.1449285
      ]
    },
    {
      "id": 58,
      "name": "Jining",
      "names": {
        "zh": "济宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4125047,
        "lng": 116.5849266
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.8648677,
        34.4306468,
        117.588867,
        35.981523
      ]
    },
    {
      "id": 59,
      "name": "Jinan",
      "names": {
        "zh": "济南",
        "ja": "済南",
        "th": "จี่หนาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6705337,
        "lng": 116.985375
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        116.980375,
        36.6655337,
        116.990375,
        36.6755337
      ]
    },
    {
      "id": 60,
      "name": "Pune",
      "names": {
        "zh": "浦那",
        "ja": "プネー",
        "th": "ปูน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 18.5213738,
        "lng": 73.8545071
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.7498473,
        18.429497,
        74.020214,
        18.6208699
      ]
    },
    {
      "id": 61,
      "name": "Foshan",
      "names": {
        "zh": "佛山",
        "ja": "佛山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0239788,
        "lng": 113.1159558
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.3854945,
        22.6452239,
        113.3879656,
        23.576193
      ]
    },
    {
      "id": 62,
      "name": "Bogota",
      "names": {
        "es": "Bogotá",
        "zh": "波哥大",
        "ja": "ボゴタ",
        "th": "โบโกตา",
        "pt": "Bogotá"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6533816,
        "lng": -74.0836333
      },
      "country": "Colombia",
      "importance": 0,
      "bbox": [
        -74.4509502,
        3.7300533,
        -73.9859033,
        4.8370154
      ]
    },
    {
      "id": 63,
      "name": "Ahmedabad",
      "names": {
        "zh": "艾哈迈达巴德",
        "ja": "アーメダバッド",
        "th": "อาเมดาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0215374,
        "lng": 72.5800568
      },
      "country": "India",
      "importance": 2,
      "bbox": [
        72.4200568,
        22.8615374,
        72.7400568,
        23.1815374
      ]
    },
    {
      "id": 64,
      "name": "Nanjing",
      "names": {
        "zh": "南京",
        "ja": "南京",
        "th": "หนานจิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.0438284,
        "lng": 118.7788631
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        118.3345905,
        31.2267477,
        119.2395068,
        32.615778
      ]
    },
    {
      "id": 65,
      "name": "Changchun",
      "names": {
        "zh": "长春",
        "ja": "長春",
        "th": "ฉางชุน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9088384,
        "lng": 125.3177297
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        125.3127297,
        43.9038384,
        125.3227297,
        43.9138384
      ]
    },
    {
      "id": 66,
      "name": "Tangshan",
      "names": {
        "zh": "唐山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6295951,
        "lng": 118.1738712
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.4974113,
        38.7737476,
        119.5470425,
        40.4632588
      ]
    },
    {
      "id": 67,
      "name": "Cangzhou",
      "names": {
        "zh": "沧州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.3042796,
        "lng": 116.8511597
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.6988989,
        37.4754882,
        118.0432888,
        38.8742249
      ]
    },
    {
      "id": 68,
      "name": "Dar es Salaam",
      "names": {
        "zh": "达累斯萨拉姆",
        "de": "Dars Salaam",
        "ja": "ダルエスサラーム",
        "th": "ดาร์เอสซาลาแฮม"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.807654449999999,
        "lng": 39.286938046328814
      },
      "country": "Tanzania",
      "importance": 0,
      "bbox": [
        39.2866507,
        -6.807848,
        39.287213,
        -6.8072644
      ]
    },
    {
      "id": 69,
      "name": "Hefei",
      "names": {
        "zh": "合肥",
        "ja": "合肥",
        "th": "เหอเฟย์"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8665676,
        "lng": 117.281428
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        116.6813863,
        30.9530627,
        117.9687804,
        32.542299
      ]
    },
    {
      "id": 70,
      "name": "Hong Kong",
      "names": {
        "zh": "香港",
        "de": "Hongkong",
        "id": "Hongkong",
        "ja": "香港",
        "th": "ฮ่องกง"
      },
      "countryCode": "HK",
      "latLng": {
        "lat": 22.284768800000002,
        "lng": 114.15790368553898
      },
      "country": "Hong Kong",
      "importance": 0,
      "bbox": [
        114.1569492,
        22.2840036,
        114.1591957,
        22.2854299
      ]
    },
    {
      "id": 71,
      "name": "Shaoyang",
      "names": {
        "zh": "邵阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2431609,
        "lng": 111.4624572
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.3024572,
        27.0831609,
        111.6224572,
        27.4031609
      ]
    },
    {
      "id": 72,
      "name": "Zhanjiang",
      "names": {
        "zh": "湛江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.18979,
        "lng": 110.3824986
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.3774986,
        21.18479,
        110.3874986,
        21.19479
      ]
    },
    {
      "id": 73,
      "name": "Shangqiu",
      "names": {
        "zh": "商丘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.4168215,
        "lng": 115.6507447
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.8224402,
        33.7075219,
        116.6456169,
        34.8634331
      ]
    },
    {
      "id": 74,
      "name": "Nantong",
      "names": {
        "zh": "南通",
        "ja": "南通",
        "th": "หนานทง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9827896,
        "lng": 120.8904588
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.1972423,
        31.6306831,
        122.3866572,
        32.8583333
      ]
    },
    {
      "id": 75,
      "name": "Yancheng",
      "names": {
        "zh": "盐城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.349559,
        "lng": 120.1577019
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.4539418,
        32.5756029,
        121.9492419,
        34.7570662
      ]
    },
    {
      "id": 76,
      "name": "Nanning",
      "names": {
        "zh": "南宁",
        "th": "หนานหนิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.81669,
        "lng": 108.316216
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        108.299702,
        22.80302,
        108.339702,
        22.84302
      ]
    },
    {
      "id": 77,
      "name": "Hengyang",
      "names": {
        "zh": "衡阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.8934986,
        "lng": 112.6260051
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.6210051,
        26.8884986,
        112.6310051,
        26.8984986
      ]
    },
    {
      "id": 78,
      "name": "Zhumadian",
      "names": {
        "zh": "驻马店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9810099,
        "lng": 114.0368444
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.0318444,
        32.9760099,
        114.0418444,
        32.9860099
      ]
    },
    {
      "id": 79,
      "name": "Shenyang",
      "names": {
        "zh": "沉阳",
        "ja": "瀋陽",
        "th": "เสิ่นหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8026095,
        "lng": 123.4279105
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        122.4167356,
        41.1976943,
        123.8085417,
        43.0402961
      ]
    },
    {
      "id": 80,
      "name": "Xingtai",
      "names": {
        "zh": "邢台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0692045,
        "lng": 114.4861618
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.4811618,
        37.0642045,
        114.4911618,
        37.0742045
      ]
    },
    {
      "id": 81,
      "name": "Xi'an",
      "names": {
        "zh": "西安",
        "id": "Xian",
        "ja": "西安",
        "th": "ซีอาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.261004,
        "lng": 108.9423363
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        107.655623,
        33.695219,
        109.7914365,
        34.7459524
      ]
    },
    {
      "id": 82,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.4377756,
        "lng": -70.6504502
      },
      "country": "Chile",
      "importance": 0,
      "bbox": [
        -70.6920527,
        -33.4785698,
        -70.6249963,
        -33.4255888
      ]
    },
    {
      "id": 83,
      "name": "Yantai",
      "names": {
        "zh": "烟台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.461928,
        "lng": 121.4425255
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.5488882,
        36.0664488,
        121.9290305,
        38.5583685
      ]
    },
    {
      "id": 84,
      "name": "Riyadh",
      "names": {
        "es": "Riad",
        "fr": "Riyad",
        "zh": "利雅得",
        "de": "Riad",
        "ja": "リヤド",
        "th": "ริยาด"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 24.638916,
        "lng": 46.7160104
      },
      "country": "Saudi Arabia",
      "importance": 0,
      "bbox": [
        46.5560104,
        24.478916,
        46.8760104,
        24.798916
      ]
    },
    {
      "id": 85,
      "name": "Luoyang",
      "names": {
        "zh": "洛阳",
        "ja": "洛陽",
        "th": "ลั่วหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.687322,
        "lng": 112.4299418
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.4249418,
        34.682322,
        112.4349418,
        34.692322
      ]
    },
    {
      "id": 86,
      "name": "Kunming",
      "names": {
        "zh": "昆明",
        "ja": "昆明",
        "th": "คุนหมิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0363006,
        "lng": 102.7086139
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        102.1679807,
        24.388896,
        103.6689663,
        26.548485
      ]
    },
    {
      "id": 87,
      "name": "Shangrao",
      "names": {
        "zh": "上饶"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.4582821,
        "lng": 117.9381305
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.7781305,
        28.2982821,
        118.0981305,
        28.6182821
      ]
    },
    {
      "id": 88,
      "name": "Hangzhou",
      "names": {
        "zh": "杭州",
        "ja": "杭州",
        "th": "หางโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.2459675,
        "lng": 120.1783532
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        120.1733532,
        30.2409675,
        120.1833532,
        30.2509675
      ]
    },
    {
      "id": 89,
      "name": "Bijie",
      "names": {
        "zh": "毕节"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3125,
        "lng": 105.29417
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.27417,
        27.2925,
        105.31417,
        27.3325
      ]
    },
    {
      "id": 90,
      "name": "Quanzhou",
      "names": {
        "zh": "泉州",
        "ja": "泉州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.9759459,
        "lng": 118.5632413
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.5582413,
        24.9709459,
        118.5682413,
        24.9809459
      ]
    },
    {
      "id": 91,
      "name": "Miami",
      "names": {
        "zh": "迈阿密",
        "ja": "マイアミ",
        "th": "ไมอามี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.7741728,
        "lng": -80.19362
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.31976,
        25.7090517,
        -80.139157,
        25.8557827
      ]
    },
    {
      "id": 92,
      "name": "Wuxi",
      "names": {
        "zh": "无锡",
        "ja": "無錫",
        "th": "อู่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.5776626,
        "lng": 120.2952752
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.513939,
        31.1041479,
        120.6005139,
        31.9927112
      ]
    },
    {
      "id": 93,
      "name": "Huanggang",
      "names": {
        "zh": "皇岗口岸"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4560187,
        "lng": 114.8673226
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.3972118,
        29.7237546,
        116.1321804,
        31.608063
      ]
    },
    {
      "id": 94,
      "name": "Maoming",
      "names": {
        "zh": "茂名"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6656961,
        "lng": 110.9209511
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.3210574,
        21.3162436,
        111.6782647,
        22.7070498
      ]
    },
    {
      "id": 95,
      "name": "Nanchong",
      "names": {
        "zh": "南充"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.812636,
        "lng": 106.089599
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.063511,
        30.771838,
        106.103511,
        30.811838
      ]
    },
    {
      "id": 96,
      "name": "Zunyi",
      "names": {
        "zh": "遵义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6912954,
        "lng": 106.9155974
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.6057714,
        27.1436559,
        108.2077178,
        29.2244463
      ]
    },
    {
      "id": 97,
      "name": "Qujing",
      "names": {
        "zh": "曲靖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4936418,
        "lng": 103.7943084
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.6343084,
        25.3336418,
        103.9543084,
        25.6536418
      ]
    },
    {
      "id": 98,
      "name": "Baghdad",
      "names": {
        "es": "Bagdad",
        "fr": "Bagdad",
        "zh": "巴格达",
        "de": "Bagdad",
        "id": "Bagdad",
        "ja": "バグダッド",
        "th": "กรุงแบกแดด",
        "pt": "Bagdá"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.3061701,
        "lng": 44.3872213
      },
      "country": "Iraq",
      "importance": 0,
      "bbox": [
        44.1846152,
        33.1797569,
        44.5702931,
        33.4868251
      ]
    },
    {
      "id": 99,
      "name": "Xinyang",
      "names": {
        "zh": "信阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.130793,
        "lng": 114.0773267
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.0723267,
        32.125793,
        114.0823267,
        32.135793
      ]
    },
    {
      "id": 100,
      "name": "Jieyang",
      "names": {
        "zh": "揭阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.553156,
        "lng": 116.3680354
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.601988,
        22.8128978,
        116.7907194,
        23.7780839
      ]
    },
    {
      "id": 101,
      "name": "Khartoum",
      "names": {
        "es": "Jartum",
        "zh": "喀土穆",
        "ja": "ハルツーム",
        "th": "ซูดาน",
        "pt": "Cartum"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.6030862,
        "lng": 32.5262226
      },
      "country": "Sudan",
      "importance": 0,
      "bbox": [
        31.6230602,
        15.1673603,
        34.4034957,
        16.63134
      ]
    },
    {
      "id": 102,
      "name": "Madrid",
      "names": {
        "zh": "马德里",
        "ja": "マドリード",
        "th": "มาดริด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4167047,
        "lng": -3.7035825
      },
      "country": "Spain",
      "importance": 0,
      "bbox": [
        -3.7229125,
        40.4050021,
        -3.6904647,
        40.430544
      ]
    },
    {
      "id": 103,
      "name": "Allahabad",
      "names": {
        "zh": "阿拉哈巴德",
        "ja": "アラハバード"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4381302,
        "lng": 81.8338005
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.6738005,
        25.2781302,
        81.9938005,
        25.5981302
      ]
    },
    {
      "id": 104,
      "name": "Yulin",
      "names": {
        "zh": "玉林"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6351201,
        "lng": 110.1529333
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.9929333,
        22.4751201,
        110.3129333,
        22.7951201
      ]
    },
    {
      "id": 105,
      "name": "Changde",
      "names": {
        "zh": "常德",
        "th": "ฉางเต๋อ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.034552,
        "lng": 111.6928724
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.4868841,
        28.4085014,
        112.3016023,
        30.1284258
      ]
    },
    {
      "id": 106,
      "name": "Liaocheng",
      "names": {
        "zh": "聊城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4441808,
        "lng": 115.9642007
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.2656364,
        35.7728789,
        116.5441706,
        37.0314272
      ]
    },
    {
      "id": 107,
      "name": "Qingdao",
      "names": {
        "zh": "青岛",
        "ja": "青島",
        "th": "ชิงเต่า"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.080251,
        "lng": 120.363522
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.217785,
        35.982335,
        120.484426,
        36.177929
      ]
    },
    {
      "id": 108,
      "name": "Dallas",
      "names": {
        "zh": "达拉斯",
        "ja": "ダラス",
        "th": "ดัลลัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7762719,
        "lng": -96.7968559
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.000482,
        32.613216,
        -96.4636317,
        33.0239366
      ]
    },
    {
      "id": 109,
      "name": "Nangandao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2992,
        "lng": 113.8851
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 110,
      "name": "Xiangyang",
      "names": {
        "zh": "襄阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.010998,
        "lng": 112.1163785
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.7584301,
        31.2305117,
        113.1155393,
        32.6370468
      ]
    },
    {
      "id": 111,
      "name": "Philadelphia",
      "names": {
        "es": "Filadelfia",
        "fr": "Philadelphie",
        "zh": "费城",
        "id": "Filadelfia",
        "ja": "フィラデルフィア",
        "th": "ฟิลาเดลเฟีย",
        "pt": "Filadélfia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9527237,
        "lng": -75.1635262
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.2802977,
        39.867005,
        -74.9558314,
        40.1379593
      ]
    },
    {
      "id": 112,
      "name": "Giza",
      "names": {
        "fr": "Gizeh",
        "zh": "吉萨",
        "de": "Gizeh",
        "ja": "ギザ",
        "th": "กิซ่า"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.9870753,
        "lng": 31.2118063
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.0518063,
        29.8270753,
        31.3718063,
        30.1470753
      ]
    },
    {
      "id": 113,
      "name": "Lu'an",
      "names": {
        "zh": "潞安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.7383487,
        "lng": 116.5142252
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.3658524,
        31.0106446,
        117.2495082,
        32.6085277
      ]
    },
    {
      "id": 114,
      "name": "Zhaotong",
      "names": {
        "zh": "昭通"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3417508,
        "lng": 103.7146923
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        102.8694614,
        26.5359485,
        105.3125775,
        28.671642
      ]
    },
    {
      "id": 115,
      "name": "Yichun",
      "names": {
        "zh": "宜春",
        "id": "Di Yichun"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.81697,
        "lng": 114.4112498
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.2512498,
        27.65697,
        114.5712498,
        27.97697
      ]
    },
    {
      "id": 116,
      "name": "Dezhou",
      "names": {
        "zh": "德州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.4486663,
        "lng": 116.2826708
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.2776708,
        37.4436663,
        116.2876708,
        37.4536663
      ]
    },
    {
      "id": 117,
      "name": "Nairobi",
      "names": {
        "zh": "内罗毕",
        "ja": "ナイロビ",
        "th": "ไนโรบี"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.2832533,
        "lng": 36.8172449
      },
      "country": "Kenya",
      "importance": 0,
      "bbox": [
        36.6647016,
        -1.4448822,
        37.1048735,
        -1.1606749
      ]
    },
    {
      "id": 118,
      "name": "Nanchang",
      "names": {
        "zh": "南昌",
        "ja": "南昌",
        "th": "หนานฉาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.687547,
        "lng": 115.8540042
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        115.6940042,
        28.527547,
        116.0140042,
        28.847547
      ]
    },
    {
      "id": 119,
      "name": "Tai'an",
      "names": {
        "zh": "泰安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2002968,
        "lng": 117.0809316
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.041489,
        35.6339965,
        117.996664,
        36.477689
      ]
    },
    {
      "id": 120,
      "name": "Dazhou",
      "names": {
        "zh": "达州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2101038,
        "lng": 107.4629791
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.3029791,
        31.0501038,
        107.6229791,
        31.3701038
      ]
    },
    {
      "id": 121,
      "name": "Houston",
      "names": {
        "zh": "休斯顿",
        "ja": "ヒューストン",
        "th": "ฮูสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.7589382,
        "lng": -95.3676974
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.9097419,
        29.5370705,
        -95.0120525,
        30.1103506
      ]
    },
    {
      "id": 122,
      "name": "Guadalajara",
      "names": {
        "zh": "瓜达拉哈拉",
        "ja": "グアダラハラ",
        "th": "กวาดาลาฮารา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.673618,
        "lng": -103.351266
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -103.407065,
        20.603737,
        -103.263762,
        20.743846
      ]
    },
    {
      "id": 123,
      "name": "Yongzhou",
      "names": {
        "zh": "永州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.457732,
        "lng": 111.5648628
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.5598628,
        26.452732,
        111.5698628,
        26.462732
      ]
    },
    {
      "id": 124,
      "name": "Atlanta",
      "names": {
        "zh": "亚特兰大",
        "ja": "アトランタ",
        "th": "แอตแลนตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7489924,
        "lng": -84.3902644
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -84.551068,
        33.647808,
        -84.28956,
        33.886823
      ]
    },
    {
      "id": 125,
      "name": "Rangoon",
      "names": {
        "zh": "仰光",
        "ja": "ラングーン",
        "th": "ย่างกุ้ง"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 16.7967129,
        "lng": 96.1609916
      },
      "country": "Myanmar",
      "importance": 0,
      "bbox": [
        95.9947586,
        16.5919743,
        96.3688068,
        17.0907467
      ]
    },
    {
      "id": 126,
      "name": "Toronto",
      "names": {
        "zh": "多伦多",
        "ja": "トロント",
        "th": "โตรอนโต"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.6534817,
        "lng": -79.3839347
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -79.492821,
        43.610384,
        -79.2785104,
        43.7360196
      ]
    },
    {
      "id": 127,
      "name": "Suihua",
      "names": {
        "zh": "绥化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.64056,
        "lng": 126.99694
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        126.97694,
        46.62056,
        127.01694,
        46.66056
      ]
    },
    {
      "id": 128,
      "name": "Saint Petersburg",
      "names": {
        "es": "San Petersburgo",
        "fr": "Saint-Pétersbourg",
        "zh": "圣彼得堡",
        "it": "San Pietroburgo",
        "ja": "セントピーターズバーグ",
        "th": "เซนต์ปีเตอร์สเบิร์ก",
        "pt": "São Petersburgo"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.938732,
        "lng": 30.316229
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        30.0433427,
        59.7441481,
        30.5671662,
        60.0907368
      ]
    },
    {
      "id": 129,
      "name": "Washington",
      "names": {
        "zh": "华盛顿",
        "ja": "ワシントン",
        "th": "วอชิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8950368,
        "lng": -77.0365427
      },
      "country": "United States",
      "importance": 0,
      "bbox": [
        -77.1197949,
        38.7916303,
        -76.909366,
        38.995968
      ]
    },
    {
      "id": 130,
      "name": "Qiqihar",
      "names": {
        "zh": "齐齐哈尔"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.3392424,
        "lng": 123.9495373
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.7895373,
        47.1792424,
        124.1095373,
        47.4992424
      ]
    },
    {
      "id": 131,
      "name": "Suzhou",
      "names": {
        "zh": "苏州",
        "ja": "蘇州",
        "th": "ซูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.6482366,
        "lng": 116.9588062
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.153512,
        33.2754385,
        118.1808543,
        34.6526326
      ]
    },
    {
      "id": 132,
      "name": "Shantou",
      "names": {
        "zh": "汕头",
        "ja": "汕頭",
        "th": "ซัวเถา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3563921,
        "lng": 116.6775856
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.2400438,
        22.9875721,
        117.4197319,
        23.6484463
      ]
    },
    {
      "id": 133,
      "name": "Weinan",
      "names": {
        "zh": "渭南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5169112,
        "lng": 109.4643291
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.9546583,
        34.2107074,
        110.6059875,
        35.8546965
      ]
    },
    {
      "id": 134,
      "name": "Changzhou",
      "names": {
        "zh": "常州",
        "ja": "常州",
        "th": "ฉางโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8122623,
        "lng": 119.9691539
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.1348516,
        31.1530205,
        120.2011368,
        32.0620891
      ]
    },
    {
      "id": 135,
      "name": "Singapore",
      "names": {
        "es": "Singapur",
        "fr": "Singapour",
        "zh": "新加坡",
        "de": "Singapur",
        "id": "Singapura",
        "ja": "シンガポール",
        "th": "สิงคโปร์",
        "pt": "Singapura"
      },
      "countryCode": "SG",
      "latLng": {
        "lat": 1.357107,
        "lng": 103.8194992
      },
      "country": "Singapore",
      "importance": 0,
      "bbox": [
        103.5666667,
        1.1285402,
        104.5716696,
        1.5143183
      ]
    },
    {
      "id": 136,
      "name": "Fuzhou",
      "names": {
        "zh": "福州",
        "ja": "福州",
        "th": "ฝูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.0774954,
        "lng": 119.2918215
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        118.3759615,
        25.2363116,
        120.190086,
        26.6384422
      ]
    },
    {
      "id": 137,
      "name": "Pudong",
      "names": {
        "zh": "浦东",
        "ja": "浦東",
        "th": "ผู่ตง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2217826,
        "lng": 121.5387401
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.4525228,
        30.7277605,
        122.3283477,
        31.4167763
      ]
    },
    {
      "id": 138,
      "name": "Belo Horizonte",
      "names": {
        "zh": "Belo Horizo nte",
        "ja": "ベロオリゾンテ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9227318,
        "lng": -43.9450948
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -44.0632916,
        -20.0594646,
        -43.8572199,
        -19.7765437
      ]
    },
    {
      "id": 139,
      "name": "Zhangzhou",
      "names": {
        "zh": "漳州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.515694,
        "lng": 117.658114
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.61525,
        24.45312,
        117.722885,
        24.551157
      ]
    },
    {
      "id": 140,
      "name": "Yuncheng",
      "names": {
        "zh": "运城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.027637,
        "lng": 111.002141
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.842141,
        34.867637,
        111.162141,
        35.187637
      ]
    },
    {
      "id": 141,
      "name": "Suzhou",
      "names": {
        "zh": "苏州",
        "ja": "蘇州",
        "th": "ซูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.311123,
        "lng": 120.6212881
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.9162087,
        30.7603342,
        121.382253,
        32.0457785
      ]
    },
    {
      "id": 142,
      "name": "Xianyang",
      "names": {
        "zh": "咸阳",
        "th": "เซียนหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3456993,
        "lng": 108.7301155
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.7251155,
        34.3406993,
        108.7351155,
        34.3506993
      ]
    },
    {
      "id": 143,
      "name": "Guilin",
      "names": {
        "zh": "桂林",
        "ja": "桂林",
        "th": "กุ้ยหลิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.2779894,
        "lng": 110.2910622
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.6116428,
        24.2627535,
        111.4875912,
        26.3878717
      ]
    },
    {
      "id": 144,
      "name": "Taizhou",
      "names": {
        "zh": "台州",
        "th": "ในไถโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.489348,
        "lng": 119.92462
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.83373,
        32.440511,
        120.096692,
        32.514138
      ]
    },
    {
      "id": 145,
      "name": "Abidjan",
      "names": {
        "zh": "阿比让",
        "ja": "アビジャン",
        "th": "อาบิดจาน"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.320357,
        "lng": -4.016107
      },
      "country": "Côte d'Ivoire",
      "importance": 0,
      "bbox": [
        -4.176107,
        5.160357,
        -3.856107,
        5.480357
      ]
    },
    {
      "id": 146,
      "name": "Huaihua",
      "names": {
        "zh": "怀化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.5611227,
        "lng": 109.963365
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.958365,
        27.5561227,
        109.968365,
        27.5661227
      ]
    },
    {
      "id": 147,
      "name": "Ji'an",
      "names": {
        "zh": "吉安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.0937349,
        "lng": 114.961355
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.8290882,
        25.9751756,
        115.930953,
        27.9660198
      ]
    },
    {
      "id": 148,
      "name": "Xiaoganzhan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.95,
        "lng": 113.93333
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.91333,
        30.93,
        113.95333,
        30.97
      ]
    },
    {
      "id": 149,
      "name": "Pingdingshan",
      "names": {
        "zh": "平顶山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.7374744,
        "lng": 113.2981436
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.2416803,
        33.1300202,
        113.6838903,
        34.3465701
      ]
    },
    {
      "id": 150,
      "name": "Jiujiang",
      "names": {
        "zh": "九江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.6653828,
        "lng": 115.9474861
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.7874861,
        29.5053828,
        116.1074861,
        29.8253828
      ]
    },
    {
      "id": 151,
      "name": "Surat",
      "names": {
        "zh": "苏拉特",
        "ja": "スーラト",
        "th": "สุราษฎร์ธานี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.2050293,
        "lng": 72.8407427
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.8357427,
        21.2000293,
        72.8457427,
        21.2100293
      ]
    },
    {
      "id": 152,
      "name": "Guiyang",
      "names": {
        "zh": "贵阳",
        "th": "กุ้ยหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.559901,
        "lng": 106.6987409
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        106.6937409,
        26.554901,
        106.7037409,
        26.564901
      ]
    },
    {
      "id": 153,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1991806,
        "lng": 29.8951716
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        29.7351716,
        31.0391806,
        30.0551716,
        31.3591806
      ]
    },
    {
      "id": 154,
      "name": "Bozhou",
      "names": {
        "zh": "亳州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.8490794,
        "lng": 115.7730968
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.54006,
        32.8556843,
        116.8238685,
        34.0829458
      ]
    },
    {
      "id": 155,
      "name": "Sydney",
      "names": {
        "zh": "雪梨",
        "ja": "シドニー",
        "th": "ซิดนีย์"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -33.867952599999995,
        "lng": 151.2101348240446
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        151.1970047,
        -33.8797755,
        151.223011,
        -33.8561096
      ]
    },
    {
      "id": 156,
      "name": "Huizhou",
      "names": {
        "zh": "惠州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1125153,
        "lng": 114.4127007
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.8080517,
        22.257327,
        115.421554,
        23.9628812
      ]
    },
    {
      "id": 157,
      "name": "Huai'an",
      "names": {
        "zh": "淮安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.5071588,
        "lng": 119.1393095
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.9795976,
        33.2869188,
        119.613451,
        33.7615558
      ]
    },
    {
      "id": 158,
      "name": "Chenzhou",
      "names": {
        "zh": "郴州",
        "th": "เฉินโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.803142,
        "lng": 113.034668
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.935011,
        25.379672,
        113.053793,
        25.823751
      ]
    },
    {
      "id": 159,
      "name": "Barcelona",
      "names": {
        "fr": "Barcelone",
        "zh": "巴塞罗那",
        "it": "Barcellona",
        "ja": "バルセロナ",
        "th": "บาร์เซโลนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3828939,
        "lng": 2.1774322
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        2.0524977,
        41.3170353,
        2.2283555,
        41.4679135
      ]
    },
    {
      "id": 160,
      "name": "Anqing",
      "names": {
        "zh": "安庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5276161,
        "lng": 117.0515956
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.8915956,
        30.3676161,
        117.2115956,
        30.6876161
      ]
    },
    {
      "id": 161,
      "name": "Suqian",
      "names": {
        "zh": "宿迁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9644701,
        "lng": 118.2696994
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.9350138,
        33.1370706,
        119.1710577,
        34.4218634
      ]
    },
    {
      "id": 162,
      "name": "Boston",
      "names": {
        "zh": "波士顿",
        "ja": "ボストン",
        "th": "บอสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3554334,
        "lng": -71.060511
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -71.1912442,
        42.2279112,
        -70.8044881,
        42.3969775
      ]
    },
    {
      "id": 163,
      "name": "Jiangmen",
      "names": {
        "zh": "江门"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5816619,
        "lng": 113.0761073
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9983251,
        21.4504329,
        113.2574658,
        22.8592668
      ]
    },
    {
      "id": 164,
      "name": "Mianyang",
      "names": {
        "zh": "绵阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4602714,
        "lng": 104.7160723
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.7462139,
        30.7081608,
        105.721836,
        33.0443666
      ]
    },
    {
      "id": 165,
      "name": "Harbin",
      "names": {
        "zh": "哈尔滨",
        "ja": "ハルビン",
        "th": "ฮาร์บิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.7593633,
        "lng": 126.6276177
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        125.6782545,
        44.0599824,
        130.2335015,
        46.6718214
      ]
    },
    {
      "id": 166,
      "name": "Huanglongsi",
      "names": {
        "zh": "黄龙寺"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.74417,
        "lng": 114.41861
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.39861,
        34.72417,
        114.43861,
        34.76417
      ]
    },
    {
      "id": 167,
      "name": "Melbourne",
      "names": {
        "zh": "墨尔本",
        "ja": "メルボルン",
        "th": "เมลเบิร์น"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -37.8142454,
        "lng": 144.9631732
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        144.44405,
        -38.49937,
        146.1925,
        -37.40175
      ]
    },
    {
      "id": 168,
      "name": "Zibo",
      "names": {
        "zh": "淄博"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.7880407,
        "lng": 118.0509914
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.0459914,
        36.7830407,
        118.0559914,
        36.7930407
      ]
    },
    {
      "id": 169,
      "name": "Dalian",
      "names": {
        "zh": "大连",
        "ja": "大連",
        "th": "ต้าเหลียน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9181714,
        "lng": 121.6282945
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.4682945,
        38.7581714,
        121.7882945,
        39.0781714
      ]
    },
    {
      "id": 170,
      "name": "Hengshui",
      "names": {
        "zh": "衡水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.743493,
        "lng": 115.685057
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.680057,
        37.738493,
        115.690057,
        37.748493
      ]
    },
    {
      "id": 171,
      "name": "Yibin",
      "names": {
        "zh": "宜宾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.7555245,
        "lng": 104.6406023
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.4806023,
        28.5955245,
        104.8006023,
        28.9155245
      ]
    },
    {
      "id": 172,
      "name": "Yangzhou",
      "names": {
        "zh": "扬州",
        "ja": "揚州",
        "th": "โจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.400831,
        "lng": 119.423274
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.361327869,
        32.3477495798,
        119.481449026,
        32.4399894512
      ]
    },
    {
      "id": 173,
      "name": "Timbio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.352893,
        "lng": -76.6838869
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.8315733,
        2.2845906,
        -76.6185152,
        2.4772839
      ]
    },
    {
      "id": 174,
      "name": "Johannesburg",
      "names": {
        "es": "Johannesburgo",
        "zh": "约翰内斯堡",
        "ja": "ヨハネスブルグ",
        "th": "โจฮันเนสเบิร์ก",
        "pt": "Joanesburgo"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.20652,
        "lng": 28.04415
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        27.928009,
        -26.217669,
        28.138187,
        -26.116484
      ]
    },
    {
      "id": 175,
      "name": "Yiyang",
      "names": {
        "zh": "益阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.5562003,
        "lng": 112.3497606
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.1897606,
        28.3962003,
        112.5097606,
        28.7162003
      ]
    },
    {
      "id": 176,
      "name": "Guigang",
      "names": {
        "zh": "贵港"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.09639,
        "lng": 109.60917
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.58917,
        23.07639,
        109.62917,
        23.11639
      ]
    },
    {
      "id": 177,
      "name": "Xinpu",
      "names": {
        "zh": "新埔"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0234603,
        "lng": 121.4683057
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.4633057,
        25.0184603,
        121.4733057,
        25.0284603
      ]
    },
    {
      "id": 178,
      "name": "Meizhou",
      "names": {
        "zh": "梅州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.2918414,
        "lng": 116.1171428
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.3115968,
        23.3761763,
        116.9349306,
        24.9407118
      ]
    },
    {
      "id": 179,
      "name": "Casablanca",
      "names": {
        "zh": "卡萨布兰卡",
        "ja": "カサブランカ",
        "th": "คาซาบลังกา"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.5945144,
        "lng": -7.6200284
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -7.746445,
        33.4937724,
        -7.4574165,
        33.6409103
      ]
    },
    {
      "id": 180,
      "name": "Langfang",
      "names": {
        "zh": "廊坊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5115603,
        "lng": 116.6998726
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.6948726,
        39.5065603,
        116.7048726,
        39.5165603
      ]
    },
    {
      "id": 181,
      "name": "Zhangjiakou",
      "names": {
        "zh": "张家口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.8113708,
        "lng": 114.8831186
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.8731186,
        40.8013708,
        114.8931186,
        40.8213708
      ]
    },
    {
      "id": 182,
      "name": "Chifeng",
      "names": {
        "zh": "赤峰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2738113,
        "lng": 118.8958844
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.8908844,
        42.2688113,
        118.9008844,
        42.2788113
      ]
    },
    {
      "id": 183,
      "name": "Linfen",
      "names": {
        "zh": "临汾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0885338,
        "lng": 111.512998
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.352998,
        35.9285338,
        111.672998,
        36.2485338
      ]
    },
    {
      "id": 184,
      "name": "Jiangguanchi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.0006,
        "lng": 113.869
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.829,
        33.9606,
        113.909,
        34.0406
      ]
    },
    {
      "id": 185,
      "name": "Kabul",
      "names": {
        "fr": "Kaboul",
        "zh": "喀布尔",
        "ja": "カブール",
        "th": "คาบูล",
        "pt": "Cabul"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.5260109,
        "lng": 69.1776838
      },
      "country": "Afghanistan",
      "importance": 0,
      "bbox": [
        69.0176838,
        34.3660109,
        69.3376838,
        34.6860109
      ]
    },
    {
      "id": 186,
      "name": "Phoenix",
      "names": {
        "zh": "凤凰",
        "it": "Fenice",
        "ja": "フェニックス",
        "th": "ฟินิกซ์",
        "pt": "Fénix"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4484367,
        "lng": -112.074141
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -112.3240289,
        33.2903739,
        -111.9255304,
        33.9183794
      ]
    },
    {
      "id": 187,
      "name": "Luzhou",
      "names": {
        "zh": "泸州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8744722,
        "lng": 105.4385063
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.2785063,
        28.7144722,
        105.5985063,
        29.0344722
      ]
    },
    {
      "id": 188,
      "name": "Taiyuan",
      "names": {
        "zh": "太原",
        "ja": "太原",
        "th": "ไท่หยวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.870009,
        "lng": 112.5452739
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        111.5089168,
        37.4414184,
        113.1477868,
        38.4208541
      ]
    },
    {
      "id": 189,
      "name": "Zhaoqing",
      "names": {
        "zh": "肇庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0501661,
        "lng": 112.4603629
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.3568124,
        22.772409,
        112.8819543,
        24.392215
      ]
    },
    {
      "id": 190,
      "name": "Xiaoxita",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7704342,
        "lng": 111.3142024
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.2942024,
        30.7504342,
        111.3342024,
        30.7904342
      ]
    },
    {
      "id": 191,
      "name": "Xiamen",
      "names": {
        "zh": "厦门",
        "ja": "厦門",
        "th": "เซียะเหมิ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4801069,
        "lng": 118.0853479
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.9253479,
        24.3201069,
        118.2453479,
        24.6401069
      ]
    },
    {
      "id": 192,
      "name": "Fuzhou",
      "names": {
        "zh": "福州",
        "ja": "福州",
        "th": "ฝูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.9511188,
        "lng": 116.3530413
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.1930413,
        27.7911188,
        116.5130413,
        28.1111188
      ]
    },
    {
      "id": 193,
      "name": "Liuzhou",
      "names": {
        "zh": "柳州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.3284746,
        "lng": 109.4239676
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.2639676,
        24.1684746,
        109.5839676,
        24.4884746
      ]
    },
    {
      "id": 194,
      "name": "Zhuzhou",
      "names": {
        "zh": "株洲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.838836,
        "lng": 113.151682
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.146682,
        27.833836,
        113.156682,
        27.843836
      ]
    },
    {
      "id": 195,
      "name": "Amman",
      "names": {
        "zh": "安曼",
        "ja": "アンマン",
        "th": "อัมมาน",
        "pt": "Amã"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.9484006,
        "lng": 35.9322218
      },
      "country": "Jordan",
      "importance": 0,
      "bbox": [
        35.9321718,
        31.9483506,
        35.9322718,
        31.9484506
      ]
    },
    {
      "id": 196,
      "name": "Jeddah",
      "names": {
        "es": "Jedda",
        "zh": "吉达",
        "it": "Jedda",
        "ja": "ジェッダ",
        "th": "เจดดาห์"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 21.5810088,
        "lng": 39.1653612
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        39.0053612,
        21.4210088,
        39.3253612,
        21.7410088
      ]
    },
    {
      "id": 197,
      "name": "Chuzhou",
      "names": {
        "zh": "滁州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3027377,
        "lng": 118.3139455
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.15433,
        31.8479275,
        119.2237776,
        33.2195313
      ]
    },
    {
      "id": 198,
      "name": "Loudi",
      "names": {
        "zh": "娄底"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7430194,
        "lng": 112.0003852
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.9953852,
        27.7380194,
        112.0053852,
        27.7480194
      ]
    },
    {
      "id": 199,
      "name": "Deyang",
      "names": {
        "zh": "德阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1244028,
        "lng": 104.39013
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.7927705,
        30.5268022,
        105.1718396,
        31.6999801
      ]
    },
    {
      "id": 200,
      "name": "Qingyuan",
      "names": {
        "zh": "清远",
        "ja": "清遠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6832984,
        "lng": 113.0505994
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9223003,
        23.4502963,
        113.926165,
        25.1943209
      ]
    },
    {
      "id": 201,
      "name": "Kano",
      "names": {
        "zh": "卡诺",
        "id": "Aminu Kano International",
        "ja": "カノ",
        "th": "โคโน"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.9918671,
        "lng": 8.5303654
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        8.3703654,
        11.8318671,
        8.6903654,
        12.1518671
      ]
    },
    {
      "id": 202,
      "name": "Wuhu",
      "names": {
        "zh": "芜湖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.3536127,
        "lng": 118.4277477
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.2677477,
        31.1936127,
        118.5877477,
        31.5136127
      ]
    },
    {
      "id": 203,
      "name": "Seattle",
      "names": {
        "zh": "西雅图",
        "ja": "シアトル",
        "th": "ซีแอตเทิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6038321,
        "lng": -122.330062
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.459696,
        47.4810022,
        -122.224433,
        47.7341354
      ]
    },
    {
      "id": 204,
      "name": "Yokohama",
      "names": {
        "zh": "横滨",
        "ja": "横浜",
        "th": "โยโกฮาม่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4443947,
        "lng": 139.6367727
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        139.464781,
        35.312501,
        139.7769348,
        35.5928475
      ]
    },
    {
      "id": 205,
      "name": "Binzhou",
      "names": {
        "zh": "滨州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3821206,
        "lng": 117.9647262
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.2598291,
        36.6895122,
        118.4121766,
        38.3976444
      ]
    },
    {
      "id": 206,
      "name": "Baojishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.37246,
        "lng": 107.1645
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.1445,
        34.35246,
        107.1845,
        34.39246
      ]
    },
    {
      "id": 207,
      "name": "Zaozhuang",
      "names": {
        "zh": "枣庄"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.8108822,
        "lng": 117.3178922
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.8049692,
        34.4623742,
        117.830482,
        35.321739
      ]
    },
    {
      "id": 208,
      "name": "Neijiang",
      "names": {
        "zh": "内江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5827261,
        "lng": 105.054491
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.2671835,
        29.1891279,
        105.4403059,
        30.042398
      ]
    },
    {
      "id": 209,
      "name": "Baicheng",
      "names": {
        "zh": "白城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.9048285,
        "lng": 106.621971
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.601971,
        23.8848285,
        106.641971,
        23.9248285
      ]
    },
    {
      "id": 210,
      "name": "Berlin",
      "names": {
        "es": "Berlín",
        "zh": "柏林",
        "it": "Berlino",
        "ja": "ベルリン",
        "th": "เบอร์ลิน",
        "pt": "Berlim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.510885,
        "lng": 13.3989367
      },
      "country": "Germany",
      "importance": 0,
      "bbox": [
        13.088345,
        52.3382448,
        13.7611609,
        52.6755087
      ]
    },
    {
      "id": 211,
      "name": "Anshan",
      "names": {
        "zh": "鞍山",
        "th": "อันชาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1075962,
        "lng": 122.9902291
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        122.8302291,
        40.9475962,
        123.1502291,
        41.2675962
      ]
    },
    {
      "id": 212,
      "name": "Lanzhou",
      "names": {
        "zh": "兰州",
        "ja": "蘭州",
        "th": "หลานโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0615192,
        "lng": 103.8318849
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        103.6718849,
        35.9015192,
        103.9918849,
        36.2215192
      ]
    },
    {
      "id": 213,
      "name": "Puyang",
      "names": {
        "zh": "濮阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7631348,
        "lng": 115.0234071
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.8636406,
        35.3320438,
        116.0971878,
        36.2129689
      ]
    },
    {
      "id": 214,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7792588,
        "lng": -122.4193286
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.173825,
        37.6403143,
        -122.281479,
        37.929811
      ]
    },
    {
      "id": 215,
      "name": "Jiaozuo",
      "names": {
        "zh": "焦作"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2225958,
        "lng": 113.2287096
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.2237096,
        35.2175958,
        113.2337096,
        35.2275958
      ]
    },
    {
      "id": 216,
      "name": "Hechi",
      "names": {
        "zh": "河池"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7169004,
        "lng": 107.8857367
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.5681329,
        23.5569577,
        109.1558455,
        25.6132609
      ]
    },
    {
      "id": 217,
      "name": "Montreal",
      "names": {
        "fr": "Montréal",
        "zh": "蒙特利尔",
        "ja": "モントリオール",
        "th": "มอนทรีออล"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5031824,
        "lng": -73.5698065
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.9741567,
        45.4100756,
        -73.4742952,
        45.7047897
      ]
    },
    {
      "id": 218,
      "name": "Detroit",
      "names": {
        "zh": "底特律",
        "ja": "デトロイト",
        "th": "ดีทรอยต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3677514,
        "lng": -83.072425
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.077425,
        42.3627514,
        -83.067425,
        42.3727514
      ]
    },
    {
      "id": 219,
      "name": "Chengde",
      "names": {
        "zh": "承德",
        "th": "เฉิงเต๋อ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9639743,
        "lng": 117.9483739
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.9433739,
        40.9589743,
        117.9533739,
        40.9689743
      ]
    },
    {
      "id": 220,
      "name": "Busan",
      "names": {
        "zh": "釜山",
        "ja": "釜山",
        "th": "ปูซาน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1155532,
        "lng": 129.0425868
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        129.0375868,
        35.1105532,
        129.0475868,
        35.1205532
      ]
    },
    {
      "id": 221,
      "name": "Algiers",
      "names": {
        "es": "Argel",
        "fr": "Alger",
        "zh": "阿尔及尔",
        "de": "Algier",
        "it": "Algeri",
        "id": "Aljir",
        "ja": "アルジェ",
        "th": "แอลเจียร์",
        "pt": "Argel"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7729309,
        "lng": 3.0588291
      },
      "country": "Algeria",
      "importance": 0,
      "bbox": [
        2.8988291,
        36.6129309,
        3.2188291,
        36.9329309
      ]
    },
    {
      "id": 222,
      "name": "Hanzhong",
      "names": {
        "zh": "汉中",
        "th": "แฮน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.068527,
        "lng": 107.023315
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.009299,
        33.050701,
        107.049299,
        33.090701
      ]
    },
    {
      "id": 223,
      "name": "Shiyan",
      "names": {
        "zh": "十堰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6314877,
        "lng": 110.792752
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.632752,
        32.4714877,
        110.952752,
        32.7914877
      ]
    },
    {
      "id": 224,
      "name": "Lucknow",
      "names": {
        "zh": "勒克瑙",
        "ja": "ラクナウ",
        "th": "ลัคเนา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.8381,
        "lng": 80.9346001
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        80.7746001,
        26.6781,
        81.0946001,
        26.9981
      ]
    },
    {
      "id": 225,
      "name": "Siping",
      "names": {
        "zh": "四平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.1669821,
        "lng": 124.364739
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.2980994,
        42.8200745,
        125.7844692,
        44.1082399
      ]
    },
    {
      "id": 226,
      "name": "Yulinshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.29181,
        "lng": 109.73753
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 227,
      "name": "Changzhi",
      "names": {
        "zh": "长治"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1830167,
        "lng": 113.0992929
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9750573,
        35.8227615,
        113.734571,
        37.1248323
      ]
    },
    {
      "id": 228,
      "name": "Qinzhou",
      "names": {
        "zh": "钦州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.9647835,
        "lng": 108.620787
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.1792997,
        21.5150855,
        109.8667729,
        22.6843938
      ]
    },
    {
      "id": 229,
      "name": "Bazhou",
      "names": {
        "zh": "霸州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8537757,
        "lng": 106.764686
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.604686,
        31.6937757,
        106.924686,
        32.0137757
      ]
    },
    {
      "id": 230,
      "name": "Qincheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5089,
        "lng": 112.692
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.672,
        38.4889,
        112.712,
        38.5289
      ]
    },
    {
      "id": 231,
      "name": "Zhongshan",
      "names": {
        "zh": "中山",
        "ja": "中山",
        "th": "จงซาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5196895,
        "lng": 113.3870834
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.1515284,
        22.2057427,
        113.74938,
        22.7761183
      ]
    },
    {
      "id": 232,
      "name": "Suining",
      "names": {
        "zh": "遂宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5061493,
        "lng": 105.574542
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.0561813,
        30.1762169,
        105.9975821,
        31.163036
      ]
    },
    {
      "id": 233,
      "name": "Leshan",
      "names": {
        "zh": "乐山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5851296,
        "lng": 103.747791
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.587791,
        29.4251296,
        103.907791,
        29.7451296
      ]
    },
    {
      "id": 234,
      "name": "San Diego",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンディエゴ",
        "th": "ซานดิเอโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7174202,
        "lng": -117.162772
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.3098161,
        32.5347979,
        -116.9057417,
        33.114194
      ]
    },
    {
      "id": 235,
      "name": "Faisalabad",
      "names": {
        "zh": "费萨拉巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.4220558,
        "lng": 73.0923253
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.99379,
        31.335641,
        73.193035,
        31.526443
      ]
    },
    {
      "id": 236,
      "name": "Guang'an",
      "names": {
        "zh": "广安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4593051,
        "lng": 106.6286646
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.9437317,
        30.0183455,
        107.3003646,
        30.8430627
      ]
    },
    {
      "id": 237,
      "name": "Tongren",
      "names": {
        "zh": "同仁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.71722,
        "lng": 109.18528
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.16528,
        27.69722,
        109.20528,
        27.73722
      ]
    },
    {
      "id": 238,
      "name": "Bengbu",
      "names": {
        "zh": "蚌埠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.944,
        "lng": 117.3719
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.2719,
        32.844,
        117.4719,
        33.044
      ]
    },
    {
      "id": 239,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.333333,
        "lng": -61.5
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -64.8467799,
        -20.4732736,
        -57.453,
        -13.4778849
      ]
    },
    {
      "id": 240,
      "name": "Qinhuangdao",
      "names": {
        "zh": "秦皇岛"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9395724,
        "lng": 119.5894129
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.5630869,
        39.3629709,
        119.95285,
        40.6155045
      ]
    },
    {
      "id": 241,
      "name": "Tongliao",
      "names": {
        "zh": "通辽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.6151762,
        "lng": 122.2601035
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.2352068,
        42.2402705,
        123.7151825,
        45.681457
      ]
    },
    {
      "id": 242,
      "name": "Jinzhou",
      "names": {
        "zh": "锦州",
        "ja": "錦州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1087113,
        "lng": 121.1186159
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.9586159,
        40.9487113,
        121.2786159,
        41.2687113
      ]
    },
    {
      "id": 243,
      "name": "Zhenjiang",
      "names": {
        "zh": "镇江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.1996564,
        "lng": 119.426989
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.421989,
        32.1946564,
        119.431989,
        32.2046564
      ]
    },
    {
      "id": 244,
      "name": "Urumqi",
      "names": {
        "zh": "乌鲁木齐",
        "ja": "ウルムチ",
        "th": "อุรุมชี"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.8244074,
        "lng": 87.6139038
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        86.7939662,
        42.9220135,
        88.9720528,
        45.0006638
      ]
    },
    {
      "id": 245,
      "name": "Heyuan",
      "names": {
        "zh": "河源",
        "th": "เหอหยวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.746777,
        "lng": 114.6954482
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.5354482,
        23.586777,
        114.8554482,
        23.906777
      ]
    },
    {
      "id": 246,
      "name": "Jaipur",
      "names": {
        "zh": "斋浦尔",
        "ja": "ジャイプール",
        "th": "ชัยปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.9154576,
        "lng": 75.8189817
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        75.6589817,
        26.7554576,
        75.9789817,
        27.0754576
      ]
    },
    {
      "id": 247,
      "name": "Xinzhou",
      "names": {
        "zh": "忻州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4139173,
        "lng": 112.730463
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.570463,
        38.2539173,
        112.890463,
        38.5739173
      ]
    },
    {
      "id": 248,
      "name": "Wuzhou",
      "names": {
        "zh": "梧州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.498515,
        "lng": 111.307983
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.265232,
        23.459252,
        111.328762,
        23.507547
      ]
    },
    {
      "id": 249,
      "name": "Addis Ababa",
      "names": {
        "fr": "Addis-Abeba",
        "it": "Addis Abeba",
        "ja": "アディスアベバ",
        "pt": "Adis ababa"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.0358287,
        "lng": 38.7524127
      },
      "country": "Ethiopia",
      "importance": 0,
      "bbox": [
        38.5924127,
        8.8758287,
        38.9124127,
        9.1958287
      ]
    },
    {
      "id": 250,
      "name": "Chaoyang",
      "names": {
        "zh": "朝阳",
        "ja": "朝陽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5754767,
        "lng": 120.4390738
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.8398779,
        40.5973321,
        121.2962625,
        42.3948944
      ]
    },
    {
      "id": 251,
      "name": "Brasilia",
      "names": {
        "zh": "巴西利亚",
        "ja": "ブラジリア",
        "th": "บราซิเลีย",
        "pt": "Brasília"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.7934036,
        "lng": -47.8823172
      },
      "country": "Brazil",
      "importance": 0,
      "bbox": [
        -48.0423172,
        -15.9534036,
        -47.7223172,
        -15.6334036
      ]
    },
    {
      "id": 252,
      "name": "Mashhad",
      "names": {
        "zh": "马什哈德",
        "ja": "マシュハド"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.2974945,
        "lng": 59.6059232
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        59.4201994,
        36.1845764,
        59.7237396,
        36.4989467
      ]
    },
    {
      "id": 253,
      "name": "Shaoguan",
      "names": {
        "zh": "韶关"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.807888,
        "lng": 113.597372
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.242679,
        24.762748,
        113.61401,
        24.830661
      ]
    },
    {
      "id": 254,
      "name": "Kuwait City",
      "names": {
        "es": "Ciudad de Kuwait",
        "fr": "Koweït",
        "zh": "科威特市",
        "de": "Kuwait-Stadt",
        "id": "Kota Kuwait",
        "ja": "クウェートシティ",
        "th": "เมืองคูเวต",
        "pt": "Cidade do kuwait"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.3796532,
        "lng": 47.9734174
      },
      "country": "Kuwait",
      "importance": 0,
      "bbox": [
        47.8134174,
        29.2196532,
        48.1334174,
        29.5396532
      ]
    },
    {
      "id": 255,
      "name": "Shanwei",
      "names": {
        "zh": "汕尾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7891796,
        "lng": 115.3704551
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.2104551,
        22.6291796,
        115.5304551,
        22.9491796
      ]
    },
    {
      "id": 256,
      "name": "Quezon City",
      "names": {
        "es": "Ciudad de Quezon",
        "zh": "奎松市",
        "de": "Quezon-Stadt",
        "it": "Città di quezon",
        "id": "Kota Quezon",
        "ja": "ケゾンシティ",
        "th": "เมืองเกซอน",
        "pt": "Cidade de Quezon"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.650435,
        "lng": 121.029966
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.009966186,
        14.6304351999,
        121.049966186,
        14.6704351999
      ]
    },
    {
      "id": 257,
      "name": "Minneapolis",
      "names": {
        "zh": "明尼阿波利斯",
        "ja": "ミネアポリス",
        "th": "มินนิอาโปลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.96313,
        "lng": -93.266563
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.329108,
        44.890589,
        -93.194329,
        45.051246
      ]
    },
    {
      "id": 258,
      "name": "Kyiv",
      "names": {
        "es": "Kiev",
        "fr": "Kiev",
        "zh": "基辅",
        "de": "Kiew",
        "it": "Kiev",
        "pt": "Kiev"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.4500336,
        "lng": 30.5241361
      },
      "country": "Ukraine",
      "importance": 0,
      "bbox": [
        30.2361453,
        50.2132422,
        30.8263593,
        50.5908142
      ]
    },
    {
      "id": 259,
      "name": "Sanaa",
      "names": {
        "zh": "萨那",
        "id": "Sana'a",
        "ja": "サヌア"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.35,
        "lng": 44.2
      },
      "country": "Yemen",
      "importance": 0,
      "bbox": [
        44.1206154,
        15.2755329,
        44.3996739,
        15.6117074
      ]
    },
    {
      "id": 260,
      "name": "Meishan",
      "names": {
        "zh": "梅山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.9866838,
        "lng": 103.7790009
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        102.84672,
        29.40291,
        104.5014618,
        30.3644423
      ]
    },
    {
      "id": 261,
      "name": "Guatemala City",
      "names": {
        "es": "Ciudad de Guatemala",
        "zh": "危地马拉市",
        "de": "Guatemala-Stadt",
        "it": "Città del Guatemala",
        "id": "Kota Guatemala",
        "ja": "グアテマラシティ",
        "th": "กัวเตมาลาซิตี้",
        "pt": "Cidade da Guatemala"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.64072,
        "lng": -90.51327
      },
      "country": "Guatemala",
      "importance": 0,
      "bbox": [
        -90.579751,
        14.537459,
        -90.379413,
        14.716374
      ]
    },
    {
      "id": 262,
      "name": "Incheon",
      "names": {
        "zh": "仁川",
        "ja": "仁川",
        "th": "อินชอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4765694,
        "lng": 126.6173655
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        126.6123655,
        37.4715694,
        126.6223655,
        37.4815694
      ]
    },
    {
      "id": 263,
      "name": "Ningde",
      "names": {
        "zh": "宁德"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.66167,
        "lng": 119.52278
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.42278,
        26.56167,
        119.62278,
        26.76167
      ]
    },
    {
      "id": 264,
      "name": "Tampa",
      "names": {
        "zh": "坦帕",
        "ja": "タンパ",
        "th": "แทมปา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.953105,
        "lng": -82.4507037
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.4557037,
        27.948105,
        -82.4457037,
        27.958105
      ]
    },
    {
      "id": 265,
      "name": "Daqing",
      "names": {
        "zh": "大庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.5960545,
        "lng": 125.0052336
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        125.0002336,
        46.5910545,
        125.0102336,
        46.6010545
      ]
    },
    {
      "id": 266,
      "name": "Putian",
      "names": {
        "zh": "莆田"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4557366,
        "lng": 119.0029034
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.8429034,
        25.2957366,
        119.1629034,
        25.6157366
      ]
    },
    {
      "id": 267,
      "name": "Bandung",
      "names": {
        "zh": "万隆",
        "ja": "バンドン",
        "th": "บันดุง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.9143518,
        "lng": 107.6024663
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        107.5974663,
        -6.9193518,
        107.6074663,
        -6.9093518
      ]
    },
    {
      "id": 268,
      "name": "Surabaya",
      "names": {
        "zh": "泗水",
        "ja": "スラバヤ",
        "th": "สุราบายา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.2459717,
        "lng": 112.7378266
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        112.5915698,
        -7.3514405,
        112.8466755,
        -7.1842308
      ]
    },
    {
      "id": 269,
      "name": "Salvador",
      "names": {
        "zh": "萨尔瓦多",
        "ja": "サルバドール",
        "th": "ซัลวาดอร์"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.9822499,
        "lng": -38.4812772
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -38.6994346,
        -13.0173949,
        -38.3034141,
        -12.7335366
      ]
    },
    {
      "id": 270,
      "name": "Denver",
      "names": {
        "zh": "丹佛",
        "ja": "デンバー",
        "th": "เดนเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7392364,
        "lng": -104.984862
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -105.1098845,
        39.6143008,
        -104.5996997,
        39.9142087
      ]
    },
    {
      "id": 271,
      "name": "Rome",
      "names": {
        "es": "Roma",
        "zh": "罗马",
        "de": "Rom",
        "it": "Roma",
        "id": "Roma",
        "ja": "ローマ",
        "th": "โรม",
        "pt": "Roma"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.8933203,
        "lng": 12.4829321
      },
      "country": "Italy",
      "importance": 0,
      "bbox": [
        12.2344669,
        41.6556417,
        12.8557603,
        42.1410285
      ]
    },
    {
      "id": 272,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -16.4955455,
        "lng": -68.1336229
      },
      "country": "Bolivia",
      "importance": 0,
      "bbox": [
        -68.2458469,
        -16.5923149,
        -67.6237089,
        -15.7586421
      ]
    },
    {
      "id": 273,
      "name": "Hohhot",
      "names": {
        "zh": "呼和浩特"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.815902,
        "lng": 111.680538
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        111.644944,
        40.775693,
        111.770286,
        40.860223
      ]
    },
    {
      "id": 274,
      "name": "Xiangtan",
      "names": {
        "zh": "湘潭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7827319,
        "lng": 112.9455981
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.7855981,
        27.6227319,
        113.1055981,
        27.9427319
      ]
    },
    {
      "id": 275,
      "name": "Pyongyang",
      "names": {
        "zh": "平壤",
        "de": "Pjöngjang",
        "ja": "平壌",
        "th": "เปียงยาง"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.0167979,
        "lng": 125.7473609
      },
      "country": "North Korea",
      "importance": 0,
      "bbox": [
        125.2224756,
        38.7916735,
        126.4033269,
        39.9012054
      ]
    },
    {
      "id": 276,
      "name": "Taichung",
      "names": {
        "zh": "台中",
        "ja": "台中",
        "th": "ไตซุง"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.163162,
        "lng": 120.6478282
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.4565596,
        23.99856,
        121.45096,
        24.44151
      ]
    },
    {
      "id": 277,
      "name": "Weihai",
      "names": {
        "zh": "威海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.5120997,
        "lng": 122.1152599
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.1682749,
        36.1112101,
        122.774962,
        37.6718646
      ]
    },
    {
      "id": 278,
      "name": "Rizhao",
      "names": {
        "zh": "日照"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4275,
        "lng": 119.45528
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.35528,
        35.3275,
        119.55528,
        35.5275
      ]
    },
    {
      "id": 279,
      "name": "Mudanjiang",
      "names": {
        "zh": "牡丹江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.58333,
        "lng": 129.6
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.5,
        44.48333,
        129.7,
        44.68333
      ]
    },
    {
      "id": 280,
      "name": "Kaohsiung",
      "names": {
        "zh": "高雄",
        "ja": "高雄",
        "th": "เกาสง"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.6203348,
        "lng": 120.3120375
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        114.3072222,
        10.3447222,
        121.0490355,
        23.4716871
      ]
    },
    {
      "id": 281,
      "name": "Guayaquil",
      "names": {
        "zh": "瓜亚基尔",
        "ja": "グアヤキル",
        "th": "ไกวย์อากิล"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.1900563,
        "lng": -79.8868741
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -80.3123629,
        -2.6170287,
        -79.8563511,
        -1.9622343
      ]
    },
    {
      "id": 282,
      "name": "Tieling",
      "names": {
        "zh": "铁岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2895233,
        "lng": 123.8394899
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.6794899,
        42.1295233,
        123.9994899,
        42.4495233
      ]
    },
    {
      "id": 283,
      "name": "Cawnpore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 54.906344,
        "lng": -1.422099
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        -1.422542,
        54.906327,
        -1.421657,
        54.90636
      ]
    },
    {
      "id": 284,
      "name": "Dingxi",
      "names": {
        "zh": "定西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5810207,
        "lng": 104.6229826
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.4629826,
        35.4210207,
        104.7829826,
        35.7410207
      ]
    },
    {
      "id": 285,
      "name": "Taipei",
      "names": {
        "zh": "台北",
        "ja": "台北",
        "th": "ไทเป"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 25.0488446,
        "lng": 121.5185047
      },
      "country": "Taiwan",
      "importance": 3,
      "bbox": [
        121.5184547,
        25.0487946,
        121.5185547,
        25.0488946
      ]
    },
    {
      "id": 286,
      "name": "Nanping",
      "names": {
        "zh": "南平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3856975,
        "lng": 118.0753196
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.9982813,
        26.2465985,
        119.2707298,
        28.3205229
      ]
    },
    {
      "id": 287,
      "name": "Zigong",
      "names": {
        "zh": "自贡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.347306,
        "lng": 104.775944
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        104.716875,
        29.291745,
        104.814381,
        29.37684
      ]
    },
    {
      "id": 288,
      "name": "Chaozhou",
      "names": {
        "zh": "潮州",
        "th": "แต้จิ๋ว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.656593,
        "lng": 116.6204223
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.36375,
        23.3464354,
        117.4197319,
        24.2357618
      ]
    },
    {
      "id": 289,
      "name": "Baotou",
      "names": {
        "zh": "包头"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.655639,
        "lng": 109.841309
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.780056,
        40.57575,
        109.858629,
        40.668813
      ]
    },
    {
      "id": 290,
      "name": "Gulou",
      "names": {
        "zh": "鼓楼"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.0690104,
        "lng": 118.7647728
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.7144899,
        32.0309981,
        118.8016432,
        32.133624
      ]
    },
    {
      "id": 291,
      "name": "Longyan",
      "names": {
        "zh": "龙岩"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0767425,
        "lng": 117.0123483
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.8473109,
        24.3736139,
        117.7453418,
        26.0362618
      ]
    },
    {
      "id": 292,
      "name": "Ankang",
      "names": {
        "zh": "安康",
        "id": "Nankang"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7064648,
        "lng": 109.0177837
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.0165959,
        31.7066651,
        110.2013016,
        33.8416939
      ]
    },
    {
      "id": 293,
      "name": "Baoshan",
      "names": {
        "zh": "宝山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.1147115,
        "lng": 99.1591622
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        98.0889497,
        24.1249961,
        100.0461798,
        25.859875
      ]
    },
    {
      "id": 294,
      "name": "Huludao",
      "names": {
        "zh": "葫芦岛"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.7515443,
        "lng": 120.8404789
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.2157669,
        39.9892625,
        121.0356478,
        41.2180415
      ]
    },
    {
      "id": 295,
      "name": "Antananarivo",
      "names": {
        "zh": "塔那那利佛",
        "ja": "アンタナナリボ",
        "th": "อันตานานาริโว"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -18.9100122,
        "lng": 47.5255809
      },
      "country": "Madagascar",
      "importance": 0,
      "bbox": [
        47.3655809,
        -19.0700122,
        47.6855809,
        -18.7500122
      ]
    },
    {
      "id": 296,
      "name": "Yanjiang",
      "names": {
        "zh": "沿江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.1248239,
        "lng": 104.6467977
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.4867977,
        29.9648239,
        104.8067977,
        30.2848239
      ]
    },
    {
      "id": 297,
      "name": "Chattogram",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 22.333778,
        "lng": 91.8344348
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        91.6744348,
        22.173778,
        91.9944348,
        22.493778
      ]
    },
    {
      "id": 298,
      "name": "Santo Domingo",
      "names": {
        "ja": "サントドミンゴ",
        "th": "ซานโตโดมิงโก"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4801972,
        "lng": -69.942111
      },
      "country": "Dominican Republic",
      "importance": 0,
      "bbox": [
        -69.9992758,
        18.4227388,
        -69.8745128,
        18.5475893
      ]
    },
    {
      "id": 299,
      "name": "Sanming",
      "names": {
        "zh": "三明"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.266684,
        "lng": 117.62747
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.791171,
        25.805697,
        117.810928,
        26.395977
      ]
    },
    {
      "id": 300,
      "name": "Longba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.6223081,
        "lng": 105.2952448
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.2552448,
        33.5823081,
        105.3352448,
        33.6623081
      ]
    },
    {
      "id": 301,
      "name": "Brooklyn",
      "names": {
        "zh": "布鲁克林",
        "ja": "ブルックリン",
        "th": "บรูคลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6526006,
        "lng": -73.9497211
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.056688,
        40.550339,
        -73.832945,
        40.739434
      ]
    },
    {
      "id": 302,
      "name": "Yangjiang",
      "names": {
        "zh": "阳江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.8603337,
        "lng": 111.9776773
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.8176773,
        21.7003337,
        112.1376773,
        22.0203337
      ]
    },
    {
      "id": 303,
      "name": "Jiamusi",
      "names": {
        "zh": "佳木斯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.8037423,
        "lng": 130.3747185
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.2147185,
        46.6437423,
        130.5347185,
        46.9637423
      ]
    },
    {
      "id": 304,
      "name": "Ibadan",
      "names": {
        "zh": "伊巴丹",
        "th": "อีบาดัน"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.3786064,
        "lng": 3.8969928
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        3.7369928,
        7.2186064,
        4.0569928,
        7.5386064
      ]
    },
    {
      "id": 305,
      "name": "Luohe",
      "names": {
        "zh": "漯河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.571189,
        "lng": 114.0289356
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.8689356,
        33.411189,
        114.1889356,
        33.731189
      ]
    },
    {
      "id": 306,
      "name": "Lincang",
      "names": {
        "zh": "临沧"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.88848,
        "lng": 100.0879344
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        98.673447,
        23.079957,
        100.5464105,
        25.0357178
      ]
    },
    {
      "id": 307,
      "name": "Medellin",
      "names": {
        "es": "Medellín",
        "zh": "麦德林",
        "ja": "メデイン",
        "pt": "Medellín"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2443382,
        "lng": -75.573553
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.6388404,
        6.1753173,
        -75.5215105,
        6.3128196
      ]
    },
    {
      "id": 308,
      "name": "Xuanzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9486697,
        "lng": 118.7514279
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.466014,
        30.5615517,
        119.0729757,
        31.324679
      ]
    },
    {
      "id": 309,
      "name": "Yunfu",
      "names": {
        "zh": "云浮",
        "th": "หยุนฝู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9173703,
        "lng": 112.0396246
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.8796246,
        22.7573703,
        112.1996246,
        23.0773703
      ]
    },
    {
      "id": 310,
      "name": "Dubai",
      "names": {
        "fr": "Dubaï",
        "zh": "迪拜",
        "ja": "ドバイ",
        "th": "ดูไบ"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.074282349999997,
        "lng": 55.18853865430702
      },
      "country": "United Arab Emirates",
      "importance": 1,
      "bbox": [
        54.4564551,
        24.6230661,
        56.2036202,
        25.5250676
      ]
    },
    {
      "id": 311,
      "name": "Mirzapur",
      "names": {
        "zh": "米尔扎布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.1461346,
        "lng": 82.5689952
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.4089952,
        24.9861346,
        82.7289952,
        25.3061346
      ]
    },
    {
      "id": 312,
      "name": "Tashkent",
      "names": {
        "fr": "Tachkent",
        "zh": "塔什干",
        "de": "Taschkent",
        "ja": "タシケント",
        "th": "ทาชเคนต์"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.3123363,
        "lng": 69.2787079
      },
      "country": "Uzbekistan",
      "importance": 0,
      "bbox": [
        69.1217101,
        41.1577334,
        69.525908,
        41.4224955
      ]
    },
    {
      "id": 313,
      "name": "Guangyuan",
      "names": {
        "zh": "广元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.4329637,
        "lng": 105.8563402
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.6963402,
        32.2729637,
        106.0163402,
        32.5929637
      ]
    },
    {
      "id": 314,
      "name": "Cali",
      "names": {
        "zh": "卡利",
        "ja": "カリ",
        "th": "กาลี"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.4519238,
        "lng": -76.5328732
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -76.5928361,
        3.3322025,
        -76.4613277,
        3.5062328
      ]
    },
    {
      "id": 315,
      "name": "Huangshi",
      "names": {
        "zh": "黄石理工"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.2024971,
        "lng": 115.0334144
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.5262242,
        29.5085513,
        115.5051727,
        30.3282643
      ]
    },
    {
      "id": 316,
      "name": "Ouagadougou",
      "names": {
        "es": "Uagadugú",
        "zh": "瓦加杜古",
        "ja": "ワガドゥグー",
        "th": "วากาดูกู"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.3681873,
        "lng": -1.5270944
      },
      "country": "Burkina Faso",
      "importance": 0,
      "bbox": [
        -1.6870944,
        12.2081873,
        -1.3670944,
        12.5281873
      ]
    },
    {
      "id": 317,
      "name": "Daegu",
      "names": {
        "zh": "大邱",
        "ja": "大邱",
        "th": "แทกู"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.8713,
        "lng": 128.6018
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        128.3511837,
        35.6067585,
        128.8994336,
        36.3271116
      ]
    },
    {
      "id": 318,
      "name": "Fortaleza",
      "names": {
        "zh": "福塔莱萨",
        "ja": "フォルタレザ",
        "th": "ฟอร์ตาเลซา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7304512,
        "lng": -38.5217989
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -38.6379078,
        -3.8945441,
        -38.4013259,
        -3.6921047
      ]
    },
    {
      "id": 319,
      "name": "Yaounde",
      "names": {
        "fr": "Yaoundé",
        "zh": "雅温得",
        "it": "Yaoundé",
        "th": "ยาอุนเด"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 3.86667,
        "lng": 11.51667
      },
      "country": "Cameroon",
      "importance": 0,
      "bbox": [
        11.400596,
        3.691259,
        11.64308,
        3.969651
      ]
    },
    {
      "id": 320,
      "name": "Douala",
      "names": {
        "zh": "杜阿拉",
        "ja": "ドゥアラ",
        "th": "ดูอาลา"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.0429389,
        "lng": 9.7062018
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        9.5462018,
        3.8829389,
        9.8662018,
        4.2029389
      ]
    },
    {
      "id": 321,
      "name": "Jilin",
      "names": {
        "zh": "吉林",
        "ja": "吉林省",
        "th": "จี๋หลิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.8563018,
        "lng": 126.5714519
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.5664519,
        43.8513018,
        126.5764519,
        43.8613018
      ]
    },
    {
      "id": 322,
      "name": "Dandong",
      "names": {
        "zh": "丹东"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1237658,
        "lng": 124.3821748
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.2221748,
        39.9637658,
        124.5421748,
        40.2837658
      ]
    },
    {
      "id": 323,
      "name": "Lianshan",
      "names": {
        "zh": "连山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.7683461,
        "lng": 120.8678926
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.8518866,
        40.7583042,
        120.8870069,
        40.7781128
      ]
    },
    {
      "id": 324,
      "name": "Yingkou",
      "names": {
        "zh": "营口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6747155,
        "lng": 122.2357736
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.9440982,
        39.9189943,
        123.0012997,
        40.9384475
      ]
    },
    {
      "id": 325,
      "name": "Nagpur",
      "names": {
        "zh": "那格浦尔",
        "ja": "ナーグプル",
        "th": "นาคปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.1498134,
        "lng": 79.0820556
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.9952182,
        21.0529736,
        79.1788461,
        21.2310022
      ]
    },
    {
      "id": 326,
      "name": "Omdurman",
      "names": {
        "zh": "恩图曼"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.64453,
        "lng": 32.47773
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.45773,
        15.62453,
        32.49773,
        15.66453
      ]
    },
    {
      "id": 327,
      "name": "Bekasi",
      "names": {
        "zh": "勿加泗"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.2362143,
        "lng": 106.9987436
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        106.9937436,
        -6.2412143,
        107.0037436,
        -6.2312143
      ]
    },
    {
      "id": 328,
      "name": "Ghaziabad",
      "names": {
        "zh": "加济阿巴德",
        "ja": "ガジアバード"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6711527,
        "lng": 77.4120356
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.1992428,
        28.6076951,
        77.6164967,
        28.8350392
      ]
    },
    {
      "id": 329,
      "name": "Yuxi",
      "names": {
        "zh": "玉溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.3511487,
        "lng": 102.527044
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.2753184,
        23.3171842,
        103.1516888,
        24.9548578
      ]
    },
    {
      "id": 330,
      "name": "Brisbane",
      "names": {
        "zh": "布里斯班",
        "ja": "ブリスベン",
        "th": "บริสเบน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -27.4689682,
        "lng": 153.0234991
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        152.679693,
        -27.660219,
        153.468275,
        -27.022014
      ]
    },
    {
      "id": 331,
      "name": "Anshun",
      "names": {
        "zh": "安顺"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.254911,
        "lng": 105.9437773
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.2476808,
        25.3570024,
        106.5646936,
        26.62836
      ]
    },
    {
      "id": 332,
      "name": "Depok",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.4018856,
        "lng": 106.8235154
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        106.8144196,
        -6.4133209,
        106.8349171,
        -6.3878068
      ]
    },
    {
      "id": 333,
      "name": "Shangzhou",
      "names": {
        "zh": "商州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.8648033,
        "lng": 109.9358876
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.4973068,
        33.6381215,
        110.2484566,
        34.1865915
      ]
    },
    {
      "id": 334,
      "name": "Huainan",
      "names": {
        "zh": "淮南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.528662,
        "lng": 117.0007508
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.0007008,
        32.528612,
        117.0008008,
        32.528712
      ]
    },
    {
      "id": 335,
      "name": "Kuaidamao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.6770685,
        "lng": 125.8186822
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.533551,
        41.5293011,
        125.8783843,
        41.780929
      ]
    },
    {
      "id": 336,
      "name": "Accra",
      "names": {
        "zh": "阿克拉",
        "ja": "アクラ",
        "th": "อักกรา"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 5.5571096,
        "lng": -0.2012376
      },
      "country": "Ghana",
      "importance": 0,
      "bbox": [
        -0.2841318,
        5.5171908,
        -0.0548565,
        5.6673682
      ]
    },
    {
      "id": 337,
      "name": "Fukuoka",
      "names": {
        "zh": "福冈",
        "ja": "福岡",
        "th": "ฟุกุโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5898988,
        "lng": 130.4017509
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        129.871637,
        33.424959,
        130.4949043,
        34.0626937
      ]
    },
    {
      "id": 338,
      "name": "Jincheng",
      "names": {
        "zh": "金城",
        "ja": "金城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4913132,
        "lng": 112.8454497
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9353103,
        35.189035,
        113.619226,
        36.0664176
      ]
    },
    {
      "id": 339,
      "name": "Vancouver",
      "names": {
        "zh": "温哥华",
        "ja": "バンクーバー",
        "th": "แวนคูเวอร์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2608724,
        "lng": -123.113952
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -123.2249611,
        49.1989306,
        -123.0232419,
        49.3161714
      ]
    },
    {
      "id": 340,
      "name": "Tangerang",
      "names": {
        "zh": "唐格朗"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.1770193,
        "lng": 106.6321179
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        106.6271179,
        -6.1820193,
        106.6371179,
        -6.1720193
      ]
    },
    {
      "id": 341,
      "name": "Sanmenxia",
      "names": {
        "zh": "三门峡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7678842,
        "lng": 111.1908398
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.0308398,
        34.6078842,
        111.3508398,
        34.9278842
      ]
    },
    {
      "id": 342,
      "name": "Laibin",
      "names": {
        "zh": "来宾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7324711,
        "lng": 109.2372489
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.2322489,
        23.7274711,
        109.2422489,
        23.7374711
      ]
    },
    {
      "id": 343,
      "name": "Queens",
      "names": {
        "zh": "皇后",
        "ja": "クイーンズ",
        "th": "ควีนส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7135078,
        "lng": -73.8283132
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.04378,
        40.492121,
        -73.700233,
        40.812135
      ]
    },
    {
      "id": 344,
      "name": "Qinbaling",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.723,
        "lng": 107.647
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.627,
        35.703,
        107.667,
        35.743
      ]
    },
    {
      "id": 345,
      "name": "Xining",
      "names": {
        "zh": "西宁",
        "ja": "西寧",
        "th": "ซีหนิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.617331,
        "lng": 101.7761976
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        101.6161976,
        36.457331,
        101.9361976,
        36.777331
      ]
    },
    {
      "id": 346,
      "name": "Ma'anshan",
      "names": {
        "zh": "马鞍山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.6866105,
        "lng": 118.5048377
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.8945929,
        31.2920656,
        118.8824773,
        32.0612588
      ]
    },
    {
      "id": 347,
      "name": "Yan'an",
      "names": {
        "zh": "延安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.602796,
        "lng": 109.4873978
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.6505142,
        35.3514468,
        110.5464281,
        37.4995781
      ]
    },
    {
      "id": 348,
      "name": "Baku",
      "names": {
        "es": "Bakú",
        "fr": "Bakou",
        "zh": "巴库",
        "ja": "バクー",
        "th": "บากู"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3755885,
        "lng": 49.8328009
      },
      "country": "Azerbaijan",
      "importance": 0,
      "bbox": [
        49.2187514,
        39.5311636,
        51.1765554,
        40.79689
      ]
    },
    {
      "id": 349,
      "name": "Gaoping",
      "names": {
        "zh": "高平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.4828364,
        "lng": 111.1391526
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.0991526,
        27.4428364,
        111.1791526,
        27.5228364
      ]
    },
    {
      "id": 350,
      "name": "Harare",
      "names": {
        "zh": "哈拉雷",
        "ja": "ハラレ",
        "th": "ฮาแรร์"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -17.8350672,
        "lng": 31.0522153
      },
      "country": "Zimbabwe",
      "importance": 0,
      "bbox": [
        31.0472153,
        -17.8400672,
        31.0572153,
        -17.8300672
      ]
    },
    {
      "id": 351,
      "name": "Havana",
      "names": {
        "es": "La Habana",
        "fr": "La Havane",
        "zh": "哈瓦那",
        "de": "Havanna",
        "it": "L'Avana",
        "ja": "ハバナ",
        "th": "คิวบา"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.135305,
        "lng": -82.3589631
      },
      "country": "Cuba",
      "importance": 0,
      "bbox": [
        -82.5189631,
        22.975305,
        -82.1989631,
        23.295305
      ]
    },
    {
      "id": 352,
      "name": "Phnom Penh",
      "names": {
        "ja": "プノンペン",
        "th": "พนมเปญ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.568271,
        "lng": 104.9224426
      },
      "country": "Cambodia",
      "importance": 0,
      "bbox": [
        104.7624426,
        11.408271,
        105.0824426,
        11.728271
      ]
    },
    {
      "id": 353,
      "name": "Ningbo",
      "names": {
        "zh": "宁波",
        "ja": "寧波",
        "th": "หนิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.8646327,
        "lng": 121.5326567
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.5276567,
        29.8596327,
        121.5376567,
        29.8696327
      ]
    },
    {
      "id": 354,
      "name": "Mogadishu",
      "names": {
        "es": "Mogadiscio",
        "fr": "Mogadiscio",
        "zh": "摩加迪沙",
        "de": "Mogadischu",
        "it": "Mogadiscio",
        "ja": "モガディシュ",
        "th": "โมกาได",
        "pt": "Mogadíscio"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 2.03711,
        "lng": 45.34375
      },
      "country": "Somalia",
      "importance": 0,
      "bbox": [
        45.279931,
        1.999021,
        45.608667,
        2.185002
      ]
    },
    {
      "id": 355,
      "name": "Puning",
      "names": {
        "zh": "普宁市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3000453,
        "lng": 116.160937
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.720694,
        23.0987455,
        116.3504863,
        23.5306478
      ]
    },
    {
      "id": 356,
      "name": "Medan",
      "names": {
        "zh": "棉兰",
        "th": "เมดาน"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 3.5896654,
        "lng": 98.6738261
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        98.5924674,
        3.4881173,
        98.7465201,
        3.8019525
      ]
    },
    {
      "id": 357,
      "name": "Huaibei",
      "names": {
        "zh": "淮北"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9566098,
        "lng": 116.7928916
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.3883659,
        33.2813011,
        117.0467592,
        34.2498172
      ]
    },
    {
      "id": 358,
      "name": "Qingyang",
      "names": {
        "zh": "庆阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5656099,
        "lng": 117.8718422
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.6776114,
        30.3230264,
        118.1295394,
        30.84597
      ]
    },
    {
      "id": 359,
      "name": "Riverside",
      "names": {
        "zh": "滨江",
        "ja": "リバーサイド",
        "th": "ริเวอร์ไซด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9824949,
        "lng": -117.374238
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.5237612,
        33.8726114,
        -117.278804,
        34.0194237
      ]
    },
    {
      "id": 360,
      "name": "Baltimore",
      "names": {
        "zh": "巴尔的摩",
        "ja": "ボルチモア",
        "th": "บัลติมอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2908816,
        "lng": -76.610759
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.7112977,
        39.1972328,
        -76.5296757,
        39.3719992
      ]
    },
    {
      "id": 361,
      "name": "Haiphong",
      "names": {
        "zh": "海防",
        "ja": "ハイフォン",
        "th": "ไฮฟอง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.8559088,
        "lng": 106.687641
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.687591,
        20.8558588,
        106.687691,
        20.8559588
      ]
    },
    {
      "id": 362,
      "name": "Las Vegas",
      "names": {
        "zh": "拉斯维加斯",
        "ja": "ラスベガス",
        "th": "ลาสเวกัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1672559,
        "lng": -115.148516
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.4242845,
        36.129554,
        -115.062066,
        36.3808406
      ]
    },
    {
      "id": 363,
      "name": "Chongzuo",
      "names": {
        "zh": "崇左"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.4198775,
        "lng": 107.3549423
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.1583469,
        22.3281965,
        107.4333211,
        22.5756896
      ]
    },
    {
      "id": 364,
      "name": "Rawalpindi",
      "names": {
        "zh": "拉瓦尔品第",
        "ja": "ラワルピンディー",
        "th": "ราวัลปินดี"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.5914237,
        "lng": 73.0535122
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.8935122,
        33.4314237,
        73.2135122,
        33.7514237
      ]
    },
    {
      "id": 365,
      "name": "Kumasi",
      "names": {
        "zh": "库马西"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.6985605,
        "lng": -1.6233086
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -1.7833086,
        6.5385605,
        -1.4633086,
        6.8585605
      ]
    },
    {
      "id": 366,
      "name": "Portland",
      "names": {
        "zh": "波特兰",
        "ja": "ポートランド",
        "th": "พอร์ตแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5202471,
        "lng": -122.674194
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.8367489,
        45.432536,
        -122.4720252,
        45.6528812
      ]
    },
    {
      "id": 367,
      "name": "Vadodara",
      "names": {
        "zh": "瓦多达拉",
        "ja": "ワドダラ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.2973142,
        "lng": 73.1942567
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.0342567,
        22.1373142,
        73.3542567,
        22.4573142
      ]
    },
    {
      "id": 368,
      "name": "Hezhou",
      "names": {
        "zh": "贺州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4073814,
        "lng": 111.5618542
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.4018542,
        24.2473814,
        111.7218542,
        24.5673814
      ]
    },
    {
      "id": 369,
      "name": "Pingliang",
      "names": {
        "zh": "平凉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5432,
        "lng": 106.6608107
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.5008107,
        35.3832,
        106.8208107,
        35.7032
      ]
    },
    {
      "id": 370,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.4246002,
        "lng": -98.4951405
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.8131865,
        29.1862572,
        -98.2230029,
        29.7309623
      ]
    },
    {
      "id": 371,
      "name": "Perth",
      "names": {
        "zh": "珀斯",
        "ja": "パース",
        "th": "เพิร์ธ"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -31.951428,
        "lng": 115.8604156
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        115.8554156,
        -31.956428,
        115.8654156,
        -31.946428
      ]
    },
    {
      "id": 372,
      "name": "Vishakhapatnam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.679603,
        "lng": 83.199149
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.105918,
        17.538894,
        83.400956,
        17.872261
      ]
    },
    {
      "id": 373,
      "name": "Shengli",
      "names": {
        "zh": "胜利"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2332198,
        "lng": 120.0438168
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.9602273,
        41.0951878,
        120.2192562,
        41.3088689
      ]
    },
    {
      "id": 374,
      "name": "Gujranwala",
      "names": {
        "zh": "吉兰瓦拉"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.1525312,
        "lng": 74.1933745
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        74.1266029,
        32.0298111,
        74.2812075,
        32.2445605
      ]
    },
    {
      "id": 375,
      "name": "Baicheng",
      "names": {
        "zh": "白城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.6156275,
        "lng": 122.8360811
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        122.6760811,
        45.4556275,
        122.9960811,
        45.7756275
      ]
    },
    {
      "id": 376,
      "name": "Fushun",
      "names": {
        "zh": "抚顺",
        "ja": "撫順",
        "th": "ฟุชุน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.6958035,
        "lng": 124.1513595
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.7157875,
        41.4499806,
        124.4461051,
        41.9279433
      ]
    },
    {
      "id": 377,
      "name": "St. Louis",
      "names": {
        "es": "San Luis",
        "fr": "Saint-Louis",
        "zh": "圣路易斯",
        "ja": "セントルイス",
        "th": "เซนต์หลุยส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6280278,
        "lng": -90.1910154
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.3206525,
        38.5323215,
        -90.1641941,
        38.7743018
      ]
    },
    {
      "id": 378,
      "name": "Bamako",
      "names": {
        "zh": "巴马科",
        "ja": "バマコ",
        "th": "บามาโก"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.6484353,
        "lng": -7.998926002996441
      },
      "country": "Mali",
      "importance": 0,
      "bbox": [
        -7.9994137,
        12.6481645,
        -7.9986419,
        12.6485547
      ]
    },
    {
      "id": 379,
      "name": "Quito",
      "names": {
        "zh": "基多",
        "ja": "キト",
        "th": "กีโต"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.2201641,
        "lng": -78.5123274
      },
      "country": "Ecuador",
      "importance": 0,
      "bbox": [
        -78.6723274,
        -0.3801641,
        -78.3523274,
        -0.0601641
      ]
    },
    {
      "id": 380,
      "name": "Minsk",
      "names": {
        "zh": "明斯克",
        "ja": "ミンスク",
        "th": "มินสก์"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.9024716,
        "lng": 27.5618225
      },
      "country": "Belarus",
      "importance": 0,
      "bbox": [
        27.3740176,
        53.793847,
        28.0799469,
        53.9717897
      ]
    },
    {
      "id": 381,
      "name": "Indore",
      "names": {
        "zh": "印多尔",
        "ja": "インドール",
        "th": "อินดอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7203616,
        "lng": 75.8681996
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.7081996,
        22.5603616,
        76.0281996,
        22.8803616
      ]
    },
    {
      "id": 382,
      "name": "Yinchuan",
      "names": {
        "zh": "银川",
        "ja": "銀川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4852037,
        "lng": 106.2261926
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        105.8149212,
        37.6413078,
        106.877757,
        38.8768348
      ]
    },
    {
      "id": 383,
      "name": "Kananga",
      "names": {
        "zh": "卡南加"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.897896,
        "lng": 22.409671
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        22.335075,
        -5.955493,
        22.509666,
        -5.850698
      ]
    },
    {
      "id": 384,
      "name": "Peshawar",
      "names": {
        "zh": "白沙瓦",
        "ja": "ペシャワル",
        "th": "เพชาวาร์"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.0123846,
        "lng": 71.5787458
      },
      "country": "Pakistan",
      "importance": 1,
      "bbox": [
        71.3688356,
        33.9154461,
        71.6262872,
        34.077447
      ]
    },
    {
      "id": 385,
      "name": "Sapporo",
      "names": {
        "zh": "札幌",
        "ja": "札幌",
        "th": "ซัปโปโร"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.061936,
        "lng": 141.3542924
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.990503,
        42.780706,
        141.505422,
        43.189994
      ]
    },
    {
      "id": 386,
      "name": "Esfahan",
      "names": {
        "fr": "Ispahan",
        "zh": "伊斯法罕",
        "th": "เอสฟาฮาน"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.6707877,
        "lng": 51.6650002
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        51.5256383,
        32.5046441,
        51.8653689,
        32.8203959
      ]
    },
    {
      "id": 387,
      "name": "Caracas",
      "names": {
        "zh": "加拉加斯",
        "ja": "カラカス",
        "th": "คารากัส"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.505146,
        "lng": -66.917878
      },
      "country": "Venezuela",
      "importance": 0,
      "bbox": [
        -66.945359,
        10.474732,
        -66.799771,
        10.530785
      ]
    },
    {
      "id": 388,
      "name": "Pingxiang",
      "names": {
        "zh": "萍乡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.0840478,
        "lng": 106.8323785
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.669575,
        21.9603105,
        106.9996091,
        22.2746703
      ]
    },
    {
      "id": 389,
      "name": "Aleppo",
      "names": {
        "fr": "Alep",
        "zh": "阿勒颇",
        "ja": "アレッポ",
        "th": "อาเลปโป"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.19924,
        "lng": 37.1637253
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        37.0037253,
        36.03924,
        37.3237253,
        36.35924
      ]
    },
    {
      "id": 390,
      "name": "Tijuana",
      "names": {
        "zh": "蒂华纳",
        "ja": "ティファナ",
        "th": "ตีฮัวนา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.5317397,
        "lng": -117.019529
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -117.179529,
        32.3717397,
        -116.859529,
        32.6917397
      ]
    },
    {
      "id": 391,
      "name": "Almaty",
      "names": {
        "zh": "阿拉木图",
        "ja": "アルマトイ",
        "th": "อัลมาตี"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.2363924,
        "lng": 76.9457275
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        76.7382775,
        43.0328438,
        77.1667539,
        43.4037657
      ]
    },
    {
      "id": 392,
      "name": "Vienna",
      "names": {
        "es": "Viena",
        "fr": "Vienne",
        "zh": "维也纳",
        "de": "Wien",
        "id": "Wina",
        "ja": "ウィーン",
        "th": "เวียนนา",
        "pt": "Viena"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2083537,
        "lng": 16.3725042
      },
      "country": "Austria",
      "importance": 0,
      "bbox": [
        16.181831,
        48.1179069,
        16.5775132,
        48.3226679
      ]
    },
    {
      "id": 393,
      "name": "Thane",
      "names": {
        "zh": "塞恩",
        "ja": "セイン",
        "th": "โขดหิน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.181027999999998,
        "lng": 72.96095093470745
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.960751,
        19.1806953,
        72.9611612,
        19.1813397
      ]
    },
    {
      "id": 394,
      "name": "Sacramento",
      "names": {
        "zh": "萨克拉门托",
        "ja": "サクラメント",
        "th": "แซคราเมนโต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5810606,
        "lng": -121.493895
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -121.56012,
        38.437574,
        -121.36274,
        38.685506
      ]
    },
    {
      "id": 395,
      "name": "Blantyre",
      "names": {
        "zh": "布兰太尔",
        "ja": "ブランタイア",
        "th": "แบลนไทร์"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -15.7862543,
        "lng": 35.0035694
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.8435694,
        -15.9462543,
        35.1635694,
        -15.6262543
      ]
    },
    {
      "id": 396,
      "name": "Tainan",
      "names": {
        "zh": "台南",
        "ja": "台南",
        "th": "ไถหนาน"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.9912348,
        "lng": 120.184982
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.0347671,
        22.8875061,
        120.6562638,
        23.41371
      ]
    },
    {
      "id": 397,
      "name": "Zhuhai",
      "names": {
        "zh": "珠海",
        "ja": "珠海",
        "th": "จูไห่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.273734,
        "lng": 113.5721327
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.0581892,
        21.6606197,
        114.388876,
        22.4858013
      ]
    },
    {
      "id": 398,
      "name": "Bucharest",
      "names": {
        "es": "Bucarest",
        "fr": "Bucarest",
        "zh": "布加勒斯特",
        "de": "Bukarest",
        "it": "Bucarest",
        "ja": "ブカレスト",
        "th": "บูคาเรสต์",
        "pt": "Bucareste"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.4361414,
        "lng": 26.1027202
      },
      "country": "Romania",
      "importance": 0,
      "bbox": [
        25.9666745,
        44.3342466,
        26.2255768,
        44.5413964
      ]
    },
    {
      "id": 399,
      "name": "Curitiba",
      "names": {
        "zh": "库里提巴",
        "ja": "クリティバ",
        "th": "กูรีตีบา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.4295963,
        "lng": -49.2712724
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -49.38914,
        -25.6435043,
        -49.1843182,
        -25.3450768
      ]
    },
    {
      "id": 400,
      "name": "Multan",
      "names": {
        "zh": "木尔坦",
        "de": "Multän"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.197838,
        "lng": 71.4719683
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.3119683,
        30.037838,
        71.6319683,
        30.357838
      ]
    },
    {
      "id": 401,
      "name": "Xiping",
      "names": {
        "zh": "西坪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.390556,
        "lng": 113.9175
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.5983101,
        33.1860601,
        114.1900105,
        33.5333202
      ]
    },
    {
      "id": 402,
      "name": "Ecatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6116301,
        "lng": -99.0593532
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.0594032,
        19.6115801,
        -99.0593032,
        19.6116801
      ]
    },
    {
      "id": 403,
      "name": "Jixi",
      "names": {
        "zh": "鸡西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2928513,
        "lng": 130.9619259
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.8019259,
        45.1328513,
        131.1219259,
        45.4528513
      ]
    },
    {
      "id": 404,
      "name": "Saidu Sharif",
      "names": {
        "zh": "Sharif Sharif",
        "ja": "Sharif",
        "th": "sadau sharif",
        "pt": "Sharif Sharif"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.7447334,
        "lng": 72.3562789
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.1962789,
        34.5847334,
        72.5162789,
        34.9047334
      ]
    },
    {
      "id": 405,
      "name": "Liaoyang",
      "names": {
        "zh": "辽阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.271613,
        "lng": 123.169612
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.069612,
        41.171613,
        123.269612,
        41.371613
      ]
    },
    {
      "id": 406,
      "name": "Hamburg",
      "names": {
        "es": "Hamburgo",
        "fr": "Hambourg",
        "zh": "汉堡",
        "it": "Amburgo",
        "ja": "ハンブルク",
        "th": "ฮัมบูร์ก",
        "pt": "Hamburgo"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.550341,
        "lng": 10.000654
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        8.1044993,
        53.3951118,
        10.3252805,
        54.02765
      ]
    },
    {
      "id": 407,
      "name": "Meru",
      "names": {
        "zh": "梅鲁"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 0.0499786,
        "lng": 37.6500063
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.6276591,
        0.0289908,
        37.6744409,
        0.0685516
      ]
    },
    {
      "id": 408,
      "name": "Brazzaville",
      "names": {
        "zh": "布拉柴维尔",
        "th": "บราซซาวิล"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.2694407,
        "lng": 15.2712256
      },
      "country": "Congo (Brazzaville)",
      "importance": 0,
      "bbox": [
        15.1389502,
        -4.3810505,
        15.3247981,
        -4.0967732
      ]
    },
    {
      "id": 409,
      "name": "Orlando",
      "names": {
        "zh": "奥兰多",
        "ja": "オーランド",
        "th": "ออร์แลนโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.5421109,
        "lng": -81.3790304
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.5075377,
        28.3480634,
        -81.2275862,
        28.614283
      ]
    },
    {
      "id": 410,
      "name": "Fuxin",
      "names": {
        "zh": "阜新"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.0151733,
        "lng": 121.6452868
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.4852868,
        41.8551733,
        121.8052868,
        42.1751733
      ]
    },
    {
      "id": 411,
      "name": "Wuwei",
      "names": {
        "zh": "武威"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.928881,
        "lng": 102.6359886
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.8267819,
        36.7511247,
        104.2036053,
        39.4608487
      ]
    },
    {
      "id": 412,
      "name": "Manaus",
      "names": {
        "zh": "马瑙斯",
        "ja": "マナウス",
        "th": "มาเนาส์"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.1316333,
        "lng": -59.9825041
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -60.801,
        -3.222,
        -59.16,
        -1.924
      ]
    },
    {
      "id": 413,
      "name": "Bhopal",
      "names": {
        "zh": "博帕尔",
        "id": "Di Bhopal",
        "ja": "ボパール",
        "th": "โภปาล"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.235387850000002,
        "lng": 77.41424547065687
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        77.2686445,
        23.1435185,
        77.5293615,
        23.3277153
      ]
    },
    {
      "id": 414,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3361663,
        "lng": -121.890591
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0460405,
        37.1231596,
        -121.5858438,
        37.4691477
      ]
    },
    {
      "id": 415,
      "name": "Warsaw",
      "names": {
        "es": "Varsovia",
        "fr": "Varsovie",
        "zh": "华沙",
        "de": "Warschau",
        "it": "Varsavia",
        "ja": "ワルシャワ",
        "th": "วอร์ซอ",
        "pt": "Varsóvia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2319581,
        "lng": 21.0067249
      },
      "country": "Poland",
      "importance": 0,
      "bbox": [
        20.8516882,
        52.0978497,
        21.2711512,
        52.3681531
      ]
    },
    {
      "id": 416,
      "name": "Lubumbashi",
      "names": {
        "zh": "卢本巴希",
        "ja": "ルブンバシ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -11.6768439,
        "lng": 27.4683949
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        27.4672478,
        -11.6780932,
        27.4692371,
        -11.6751415
      ]
    },
    {
      "id": 417,
      "name": "Davao",
      "names": {
        "zh": "达沃",
        "ja": "ダバオ",
        "th": "ดาเวา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0648306,
        "lng": 125.6080623
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.2175921,
        6.9562097,
        125.7448516,
        7.5864748
      ]
    },
    {
      "id": 418,
      "name": "Haikou",
      "names": {
        "zh": "海口",
        "th": "ไหโข่ว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 20.0462328,
        "lng": 110.1956502
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        110.1030823,
        19.5259404,
        110.7080691,
        20.1321729
      ]
    },
    {
      "id": 419,
      "name": "Damascus",
      "names": {
        "es": "Damasco",
        "fr": "Damas",
        "zh": "大马士革",
        "de": "Damaskus",
        "it": "Damasco",
        "id": "Damaskus",
        "ja": "ダマスカス",
        "th": "ดามัสกัส",
        "pt": "Damasco"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.5130695,
        "lng": 36.3095814
      },
      "country": "Syria",
      "importance": 0,
      "bbox": [
        36.1495814,
        33.3530695,
        36.4695814,
        33.6730695
      ]
    },
    {
      "id": 420,
      "name": "Shuyangzha",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1,
        "lng": 118.73333
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.71333,
        34.08,
        118.75333,
        34.12
      ]
    },
    {
      "id": 421,
      "name": "Brussels",
      "names": {
        "es": "Bruselas",
        "fr": "Bruxelles",
        "zh": "布鲁塞尔",
        "de": "Brüssel",
        "it": "Bruxelles",
        "ja": "ブリュッセル",
        "th": "บรัสเซลส์",
        "pt": "Bruxelas"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8465573,
        "lng": 4.351697
      },
      "country": "Belgium",
      "importance": 0,
      "bbox": [
        4.3140021,
        50.7964079,
        4.4370711,
        50.9139045
      ]
    },
    {
      "id": 422,
      "name": "Lusaka",
      "names": {
        "zh": "卢萨卡",
        "ja": "ルサカ",
        "th": "ลูซากา"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -15.4163395,
        "lng": 28.2818414
      },
      "country": "Zambia",
      "importance": 0,
      "bbox": [
        28.1218414,
        -15.5763395,
        28.4418414,
        -15.2563395
      ]
    },
    {
      "id": 423,
      "name": "Hyderabad City",
      "names": {
        "es": "Ciudad de Hyderabad",
        "zh": "海得拉巴城",
        "de": "Hyderabad-Stadt",
        "it": "Città di Hyderabad",
        "id": "Kota Hyderabad",
        "ja": "ハイデラバードシティ",
        "th": "เมืองไฮเดอราบัด",
        "pt": "Cidade de hyderabad"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.38109305,
        "lng": 68.37347995130193
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.3724848,
        25.3806629,
        68.3743122,
        25.3815369
      ]
    },
    {
      "id": 424,
      "name": "Budapest",
      "names": {
        "zh": "布达佩斯",
        "ja": "ブダペスト",
        "th": "บูดาเปสต์",
        "pt": "Budapeste"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4978789,
        "lng": 19.0402383
      },
      "country": "Hungary",
      "importance": 0,
      "bbox": [
        18.9251057,
        47.3496899,
        19.3349258,
        47.6131468
      ]
    },
    {
      "id": 425,
      "name": "Shuozhou",
      "names": {
        "zh": "朔州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3305654,
        "lng": 112.4262465
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.2662465,
        39.1705654,
        112.5862465,
        39.4905654
      ]
    },
    {
      "id": 426,
      "name": "Cleveland",
      "names": {
        "zh": "克利夫兰",
        "ja": "クリーブランド",
        "th": "คลีฟแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.4996574,
        "lng": -81.6936772
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.8790937,
        41.390628,
        -81.532744,
        41.604436
      ]
    },
    {
      "id": 427,
      "name": "Benxi",
      "names": {
        "zh": "本溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2921601,
        "lng": 123.7593948
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.5993948,
        41.1321601,
        123.9193948,
        41.4521601
      ]
    },
    {
      "id": 428,
      "name": "Baiyin",
      "names": {
        "zh": "白银"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5459049,
        "lng": 104.1683725
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.8900837,
        36.2508851,
        104.4110817,
        36.7855901
      ]
    },
    {
      "id": 429,
      "name": "Pittsburgh",
      "names": {
        "zh": "匹兹堡",
        "ja": "ピッツバーグ",
        "th": "พิตส์เบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4416941,
        "lng": -79.9900861
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.095517,
        40.36152,
        -79.865728,
        40.5012021
      ]
    },
    {
      "id": 430,
      "name": "Patna",
      "names": {
        "zh": "巴特那",
        "ja": "パトナ",
        "th": "ปัฏนา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.6093239,
        "lng": 85.1235252
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        84.9635252,
        25.4493239,
        85.2835252,
        25.7693239
      ]
    },
    {
      "id": 431,
      "name": "Mosul",
      "names": {
        "fr": "Mossoul",
        "zh": "摩苏尔",
        "ja": "モースル",
        "th": "โมซุล"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.3415843,
        "lng": 43.1291386
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        42.9691386,
        36.1815843,
        43.2891386,
        36.5015843
      ]
    },
    {
      "id": 432,
      "name": "Caloocan City",
      "names": {
        "es": "Ciudad de Caloocan",
        "fr": "Ville caloocane",
        "zh": "卡卢坎市",
        "de": "Kalookan-Stadt",
        "it": "Città di Caloocan",
        "id": "Kota caloocan",
        "ja": "カロカンシティ",
        "th": "เมือง Caloocan",
        "pt": "Cidade de Caloocan"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6569248,
        "lng": 120.9738786
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.9688786,
        14.6519248,
        120.9788786,
        14.6619248
      ]
    },
    {
      "id": 433,
      "name": "Austin",
      "names": {
        "zh": "奥斯汀",
        "ja": "オースティン",
        "th": "ออสติน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2711286,
        "lng": -97.7436995
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -97.9367663,
        30.0985133,
        -97.5605288,
        30.5166255
      ]
    },
    {
      "id": 434,
      "name": "Sanzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.545944,
        "lng": 112.9401064
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.9001064,
        29.505944,
        112.9801064,
        29.585944
      ]
    },
    {
      "id": 435,
      "name": "Beihai",
      "names": {
        "zh": "北海",
        "ja": "北海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.4845174,
        "lng": 109.1165772
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.9565772,
        21.3245174,
        109.2765772,
        21.6445174
      ]
    },
    {
      "id": 436,
      "name": "Mecca",
      "names": {
        "es": "La Meca",
        "fr": "La mecque",
        "zh": "麦加",
        "de": "Mekka",
        "it": "La Mecca",
        "id": "Mekkah",
        "ja": "メッカ",
        "th": "เมกกะ",
        "pt": "Meca"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 21.420847,
        "lng": 39.826869
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        39.5058552,
        21.1198192,
        40.47561,
        21.8480401
      ]
    },
    {
      "id": 437,
      "name": "Heihe",
      "names": {
        "zh": "黑河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 50.2322239,
        "lng": 127.4946896
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        127.3346896,
        50.0722239,
        127.6546896,
        50.3922239
      ]
    },
    {
      "id": 438,
      "name": "Jingdezhen",
      "names": {
        "zh": "景德镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2712774,
        "lng": 117.1727822
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.0127822,
        29.1112774,
        117.3327822,
        29.4312774
      ]
    },
    {
      "id": 439,
      "name": "Conakry",
      "names": {
        "zh": "科纳克里",
        "ja": "コナクリ",
        "th": "โกนากรี",
        "pt": "Conacri"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 9.5170602,
        "lng": -13.6998434
      },
      "country": "Guinea",
      "importance": 0,
      "bbox": [
        -13.8402897,
        9.4321917,
        -13.4738187,
        9.7533673
      ]
    },
    {
      "id": 440,
      "name": "Kampala",
      "names": {
        "zh": "坎帕拉",
        "ja": "カンパラ",
        "th": "กัมปาลา"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.31628,
        "lng": 32.58219
      },
      "country": "Uganda",
      "importance": 0,
      "bbox": [
        32.510269,
        0.218872,
        32.670175,
        0.408811
      ]
    },
    {
      "id": 441,
      "name": "Cincinnati",
      "names": {
        "zh": "辛辛那提",
        "ja": "シンシナティ",
        "th": "ซินซินนาติ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1014537,
        "lng": -84.5124602
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.71239,
        39.0520565,
        -84.3695773,
        39.2210368
      ]
    },
    {
      "id": 442,
      "name": "Recife",
      "names": {
        "zh": "累西腓",
        "ja": "レシフェ",
        "th": "เรซีเฟ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0584933,
        "lng": -34.8848193
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -35.0186478,
        -8.1551869,
        -34.8515401,
        -7.928967
      ]
    },
    {
      "id": 443,
      "name": "Yushan",
      "names": {
        "zh": "玉山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4107685,
        "lng": 120.9478692
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.8435798,
        31.3126809,
        121.053704,
        31.4697043
      ]
    },
    {
      "id": 444,
      "name": "Zhongli",
      "names": {
        "zh": "忠丽"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9653531,
        "lng": 121.224926
      },
      "country": "Taiwan",
      "importance": 3,
      "bbox": [
        121.1514437,
        24.9250633,
        121.2668756,
        25.0284853
      ]
    },
    {
      "id": 445,
      "name": "Kansas City",
      "names": {
        "es": "Ciudad de Kansas",
        "zh": "堪萨斯城",
        "id": "Kota Kansas",
        "ja": "カンザスシティ",
        "th": "เมืองแคนซัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.100105,
        "lng": -94.5781416
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.765917,
        38.827176,
        -94.385522,
        39.356662
      ]
    },
    {
      "id": 446,
      "name": "Manhattan",
      "names": {
        "zh": "曼哈顿",
        "ja": "マンハッタン",
        "th": "แมนฮัตตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7814865,
        "lng": -73.95905442771002
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.0458774,
        40.679654,
        -73.906769,
        40.882012
      ]
    },
    {
      "id": 447,
      "name": "Bilaspur",
      "names": {
        "zh": "比拉斯布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.1638486,
        "lng": 82.13659231724193
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.730535,
        21.7124073,
        82.4739039,
        22.6158926
      ]
    },
    {
      "id": 448,
      "name": "Semarang",
      "names": {
        "zh": "三宝垄",
        "th": "เซมารัง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.9903988,
        "lng": 110.4229104
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        110.2672863,
        -7.1144639,
        110.5088836,
        -6.9319917
      ]
    },
    {
      "id": 449,
      "name": "Ludhiana",
      "names": {
        "zh": "卢迪亚纳",
        "ja": "ルディアーナ",
        "th": "ลูเธียนา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.9090157,
        "lng": 75.851601
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.691601,
        30.7490157,
        76.011601,
        31.0690157
      ]
    },
    {
      "id": 450,
      "name": "Novosibirsk",
      "names": {
        "zh": "新西伯利亚",
        "ja": "ノボシビルスク",
        "th": "โนโว"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0288307,
        "lng": 82.9226887
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        82.7511322,
        54.8009038,
        83.1602273,
        55.1994565
      ]
    },
    {
      "id": 451,
      "name": "Chengtangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.08357,
        "lng": 117.19071
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 452,
      "name": "Agra",
      "names": {
        "zh": "阿格拉",
        "ja": "アグラ",
        "th": "ในอักกรา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.1752554,
        "lng": 78.0098161
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.851022,
        27.045464,
        78.2207625,
        27.2963239
      ]
    },
    {
      "id": 453,
      "name": "Karaj",
      "names": {
        "zh": "卡拉季"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.8224892,
        "lng": 50.9904681
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        50.7292235,
        35.6938755,
        51.525278,
        36.1986662
      ]
    },
    {
      "id": 454,
      "name": "Wenzhou",
      "names": {
        "zh": "温州",
        "ja": "温州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.9833239,
        "lng": 120.6810513
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.6760513,
        27.9783239,
        120.6860513,
        27.9883239
      ]
    },
    {
      "id": 455,
      "name": "Indianapolis",
      "names": {
        "zh": "印第安纳波利斯",
        "ja": "インディアナポリス",
        "th": "อินเดียแนโพลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7683331,
        "lng": -86.1583502
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -86.3281207,
        39.6321626,
        -85.9380401,
        39.9275253
      ]
    },
    {
      "id": 456,
      "name": "Leon de los Aldama",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.121965,
        "lng": -101.6829766
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.8429766,
        20.961965,
        -101.5229766,
        21.281965
      ]
    },
    {
      "id": 457,
      "name": "Puebla",
      "names": {
        "zh": "普埃布拉",
        "ja": "プエブラ",
        "th": "ปวยบลา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0437196,
        "lng": -98.1981486
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -98.3581486,
        18.8837196,
        -98.0381486,
        19.2037196
      ]
    },
    {
      "id": 458,
      "name": "Kalyan",
      "names": {
        "zh": "格利扬"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.2396742,
        "lng": 73.1366482
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.1183625,
        19.2159482,
        73.1724625,
        19.2735184
      ]
    },
    {
      "id": 459,
      "name": "Madurai",
      "names": {
        "zh": "马杜赖",
        "ja": "マドゥライ",
        "th": "ซาน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 9.9261153,
        "lng": 78.1140983
      },
      "country": "India",
      "importance": 2,
      "bbox": [
        78.0155927,
        9.8245041,
        78.2030091,
        9.9933722
      ]
    },
    {
      "id": 460,
      "name": "Mbuji-Mayi",
      "names": {
        "zh": "姆布吉马伊"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -6.1156773,
        "lng": 23.596387291857184
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        23.5243609,
        -6.1750993,
        23.6865408,
        -6.0563661
      ]
    },
    {
      "id": 461,
      "name": "Hebi",
      "names": {
        "zh": "鹤壁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7494986,
        "lng": 114.2912899
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.9897302,
        35.4434379,
        114.7463404,
        36.0523538
      ]
    },
    {
      "id": 462,
      "name": "Shiraz",
      "names": {
        "zh": "设拉子",
        "ja": "シーラーズ",
        "th": "ชีราซ"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.6060218,
        "lng": 52.5378041
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        52.3822546,
        29.4987382,
        52.6675129,
        29.8403461
      ]
    },
    {
      "id": 463,
      "name": "Jamshedpur",
      "names": {
        "zh": "贾姆谢德布尔",
        "th": "ชัมเศทปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.8015194,
        "lng": 86.2029579
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.0429579,
        22.6415194,
        86.3629579,
        22.9615194
      ]
    },
    {
      "id": 464,
      "name": "Columbus",
      "names": {
        "zh": "哥伦布",
        "ja": "コロンバス",
        "th": "โคลัมบัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9622601,
        "lng": -83.0007065
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -83.2101797,
        39.8086936,
        -82.7713119,
        40.1573082
      ]
    },
    {
      "id": 465,
      "name": "Tabriz",
      "names": {
        "zh": "大不里士",
        "ja": "タブリーズ"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 38.074188,
        "lng": 46.2964
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        46.203505,
        38.005041,
        46.412694,
        38.152634
      ]
    },
    {
      "id": 466,
      "name": "Maracaibo",
      "names": {
        "zh": "马拉开波",
        "th": "มาราไกโบ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6498095,
        "lng": -71.6443596
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -71.8043596,
        10.4898095,
        -71.4843596,
        10.8098095
      ]
    },
    {
      "id": 467,
      "name": "Kawasaki",
      "names": {
        "zh": "川崎",
        "ja": "川崎",
        "th": "คาวาซากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5305307,
        "lng": 139.7028012
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4488851,
        35.4114149,
        139.8577511,
        35.6428132
      ]
    },
    {
      "id": 468,
      "name": "Aba",
      "names": {
        "zh": "阿坝",
        "ja": "はABA",
        "th": "เอบีเอ"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.1128008,
        "lng": 7.3651376
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.2051376,
        4.9528008,
        7.5251376,
        5.2728008
      ]
    },
    {
      "id": 469,
      "name": "Palembang",
      "names": {
        "zh": "巨港",
        "th": "ปาเลมบัง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.9888243,
        "lng": 104.7568507
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        104.6137224,
        -3.1162084,
        104.8606248,
        -2.8545935
      ]
    },
    {
      "id": 470,
      "name": "Zhangjiajie",
      "names": {
        "zh": "张家界",
        "th": "จางเจียเจี้ย"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.131877,
        "lng": 110.481711
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.462743253,
        27.9028881252,
        112.493679417,
        29.14439461
      ]
    },
    {
      "id": 471,
      "name": "Kobe",
      "names": {
        "zh": "神户",
        "ja": "神戸",
        "th": "โกเบ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6932379,
        "lng": 135.1943764
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        134.910023,
        34.5324108,
        135.3297266,
        34.890578
      ]
    },
    {
      "id": 472,
      "name": "Charlotte",
      "names": {
        "zh": "夏洛特",
        "ja": "シャーロット",
        "th": "ชาร์ล็อตต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2272086,
        "lng": -80.8430827
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.0096282,
        35.0105195,
        -80.6348755,
        35.4002508
      ]
    },
    {
      "id": 473,
      "name": "Changshu",
      "names": {
        "zh": "常熟"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.64615,
        "lng": 120.74221
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.72221,
        31.62615,
        120.76221,
        31.66615
      ]
    },
    {
      "id": 474,
      "name": "Lianjiang",
      "names": {
        "zh": "廉江市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6567913,
        "lng": 110.148109
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.7387426,
        21.4046896,
        110.5085766,
        21.9581212
      ]
    },
    {
      "id": 475,
      "name": "Ximeicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.98773,
        "lng": 118.3858
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 476,
      "name": "Jianguang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.19377,
        "lng": 115.7836
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.7636,
        28.17377,
        115.8036,
        28.21377
      ]
    },
    {
      "id": 477,
      "name": "Yucheng",
      "names": {
        "zh": "禹城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.9883761,
        "lng": 102.9941955
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.85748,
        29.670259,
        103.234943,
        30.240818
      ]
    },
    {
      "id": 478,
      "name": "Belem",
      "names": {
        "zh": "贝伦",
        "ja": "ベレン",
        "th": "เบเลง",
        "pt": "Belém"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.45056,
        "lng": -48.4682453
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -48.6244082,
        -1.5265847,
        -48.2961242,
        -1.0194267
      ]
    },
    {
      "id": 479,
      "name": "Guiping",
      "names": {
        "zh": "桂平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3916357,
        "lng": 110.074064
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.914064,
        23.2316357,
        110.234064,
        23.5516357
      ]
    },
    {
      "id": 480,
      "name": "Leizhou",
      "names": {
        "zh": "雷州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 20.9166877,
        "lng": 110.0917772
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.9317772,
        20.7566877,
        110.2517772,
        21.0766877
      ]
    },
    {
      "id": 481,
      "name": "Gwangju",
      "names": {
        "zh": "光州",
        "ja": "光州",
        "th": "กวางจู"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1670444,
        "lng": 126.9106882
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        126.9106382,
        35.1669944,
        126.9107382,
        35.1670944
      ]
    },
    {
      "id": 482,
      "name": "Munich",
      "names": {
        "es": "Múnich",
        "zh": "慕尼黑",
        "de": "München",
        "it": "Monaco di baviera",
        "ja": "ミュンヘン",
        "th": "มิวนิค",
        "pt": "Munique"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1371079,
        "lng": 11.5753822
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        11.360777,
        48.0616244,
        11.7229099,
        48.2481162
      ]
    },
    {
      "id": 483,
      "name": "Nasik",
      "names": {
        "zh": "纳西克",
        "ja": "ナーシク",
        "th": "ซิก"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.0112475,
        "lng": 73.7902364
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.6302364,
        19.8512475,
        73.9502364,
        20.1712475
      ]
    },
    {
      "id": 484,
      "name": "Porto Alegre",
      "names": {
        "ja": "ポルトアレグレ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.0324999,
        "lng": -51.2303767
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -51.3034404,
        -30.2694499,
        -51.0188522,
        -29.9324744
      ]
    },
    {
      "id": 485,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.170026,
        "lng": -68.0003987
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -68.1603987,
        10.010026,
        -67.8403987,
        10.330026
      ]
    },
    {
      "id": 486,
      "name": "Onitsha",
      "names": {
        "zh": "奥尼查"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.1461619,
        "lng": 6.8019088
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        6.6419088,
        5.9861619,
        6.9619088,
        6.3061619
      ]
    },
    {
      "id": 487,
      "name": "Abu Dhabi",
      "names": {
        "zh": "阿布扎比",
        "ja": "アブダビ",
        "th": "อาบูดาบี"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 24.4538352,
        "lng": 54.3774014
      },
      "country": "United Arab Emirates",
      "importance": 0,
      "bbox": [
        54.2971553,
        24.2810331,
        54.7659108,
        24.601854
      ]
    },
    {
      "id": 488,
      "name": "Virginia Beach",
      "names": {
        "es": "Playa de Virginia",
        "zh": "弗吉尼亚海滩",
        "id": "Pantai Virginia",
        "ja": "バージニアビーチ",
        "th": "เวอร์จิเนียบีช",
        "pt": "Praia de virgínia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.834537,
        "lng": -76.033768
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.22776,
        36.550453,
        -75.797497,
        37.040886
      ]
    },
    {
      "id": 489,
      "name": "Daejeon",
      "names": {
        "zh": "大田",
        "ja": "大田（テジョン）",
        "th": "แทจอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.3497007,
        "lng": 127.3849016
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        127.2463188,
        36.1833683,
        127.5596183,
        36.5002307
      ]
    },
    {
      "id": 490,
      "name": "Zapopan",
      "names": {
        "zh": "萨波潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7211203,
        "lng": -103.3913671
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.5513671,
        20.5611203,
        -103.2313671,
        20.8811203
      ]
    },
    {
      "id": 491,
      "name": "Yekaterinburg",
      "names": {
        "es": "Ekaterimburgo",
        "zh": "叶卡捷琳堡",
        "de": "Jekaterinburg",
        "it": "Ekaterinburg",
        "th": "เยคาเตรินบูร์ก"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.839104,
        "lng": 60.60825
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        60.0070815,
        56.5937449,
        60.9432814,
        56.9826914
      ]
    },
    {
      "id": 492,
      "name": "Huazhou",
      "names": {
        "zh": "化州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6890973,
        "lng": 112.1004752
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.0763828,
        32.6610104,
        112.1247951,
        32.7076895
      ]
    },
    {
      "id": 493,
      "name": "Kyoto",
      "names": {
        "zh": "京都议定书",
        "ja": "京都",
        "th": "เกียวโต",
        "pt": "De Quioto"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.021041,
        "lng": 135.7556075
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        135.559006,
        34.874916,
        135.878442,
        35.3212207
      ]
    },
    {
      "id": 494,
      "name": "Shuangyashan",
      "names": {
        "zh": "双鸭山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.63611,
        "lng": 131.15389
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        131.13389,
        46.61611,
        131.17389,
        46.65611
      ]
    },
    {
      "id": 495,
      "name": "Pizhou",
      "names": {
        "zh": "邳州市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3402702,
        "lng": 118.0068173
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.5975383,
        34.12163,
        118.1839174,
        34.6805091
      ]
    },
    {
      "id": 496,
      "name": "El Kelaa des Srarhna",
      "names": {
        "zh": "El Kelaa des Srarna"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.05121,
        "lng": -7.41079
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.43079,
        32.03121,
        -7.39079,
        32.07121
      ]
    },
    {
      "id": 497,
      "name": "Kharkiv",
      "names": {
        "zh": "哈尔科夫",
        "th": "คาร์คิฟ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.9923181,
        "lng": 36.2310146
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        36.1055541,
        49.8784822,
        36.4554864,
        50.1047533
      ]
    },
    {
      "id": 498,
      "name": "Yangshe",
      "names": {
        "zh": "杨舍"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8641913,
        "lng": 120.5110609
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.4616316,
        31.7885851,
        120.6390881,
        31.9518254
      ]
    },
    {
      "id": 499,
      "name": "Guyuan",
      "names": {
        "zh": "固原"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0148906,
        "lng": 106.2379036
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        106.0779036,
        35.8548906,
        106.3979036,
        36.1748906
      ]
    },
    {
      "id": 500,
      "name": "Khulna",
      "names": {
        "zh": "库尔纳"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 22.8171664,
        "lng": 89.563759
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        89.403759,
        22.6571664,
        89.723759,
        22.9771664
      ]
    },
    {
      "id": 501,
      "name": "Muscat",
      "names": {
        "zh": "马斯喀特",
        "id": "Muskat",
        "ja": "マスカット",
        "th": "มัสกัต"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 23.611705,
        "lng": 58.492641
      },
      "country": "Oman",
      "importance": 0,
      "bbox": [
        58.187564,
        23.369071,
        58.592369,
        23.644556
      ]
    },
    {
      "id": 502,
      "name": "Bronx",
      "names": {
        "zh": "布朗克斯",
        "ja": "ブロンクス",
        "th": "บรองซ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8466508,
        "lng": -73.8785937
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.933907,
        40.785739,
        -73.748374,
        40.91763
      ]
    },
    {
      "id": 503,
      "name": "Gaozhou",
      "names": {
        "zh": "高州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.921311,
        "lng": 110.8489267
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.6889267,
        21.761311,
        111.0089267,
        22.081311
      ]
    },
    {
      "id": 504,
      "name": "Chizhou",
      "names": {
        "zh": "池州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6671088,
        "lng": 117.4859885
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.6440654,
        29.5650702,
        118.1295394,
        30.84597
      ]
    },
    {
      "id": 505,
      "name": "Faridabad",
      "names": {
        "zh": "法里达巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.417987,
        "lng": 77.307538
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.232597,
        28.283286,
        77.381724,
        28.505585
      ]
    },
    {
      "id": 506,
      "name": "Ulaanbaatar",
      "names": {
        "es": "Ulán Bator",
        "fr": "Oulan-Bator",
        "zh": "乌兰巴托",
        "ja": "ウランバートル",
        "th": "อูลานบาตอร์"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 47.9184676,
        "lng": 106.9177016
      },
      "country": "Mongolia",
      "importance": 0,
      "bbox": [
        106.362609,
        47.3019581,
        108.5007801,
        48.271931
      ]
    },
    {
      "id": 507,
      "name": "Goiania",
      "names": {
        "zh": "戈亚尼亚",
        "de": "Goiânia",
        "th": "กัวเนีย",
        "pt": "Goiânia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.680882,
        "lng": -49.2532691
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -49.4469524,
        -16.8318764,
        -49.078,
        -16.4542142
      ]
    },
    {
      "id": 508,
      "name": "Fuqing",
      "names": {
        "zh": "福清"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7237321,
        "lng": 119.3795377
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.0632775,
        25.2698466,
        119.6892642,
        25.8523517
      ]
    },
    {
      "id": 509,
      "name": "Belgrade",
      "names": {
        "es": "Belgrado",
        "zh": "贝尔格莱德",
        "de": "Belgrad",
        "it": "Belgrado",
        "ja": "ベオグラード",
        "th": "เบลเกรด",
        "pt": "Belgrado"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8178131,
        "lng": 20.4568974
      },
      "country": "Serbia",
      "importance": 0,
      "bbox": [
        20.2213764,
        44.6880459,
        20.6232109,
        44.9426876
      ]
    },
    {
      "id": 510,
      "name": "Pingdu",
      "names": {
        "zh": "平度"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.7842974,
        "lng": 119.9493639
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.525266,
        36.4710938,
        120.3205476,
        37.0461153
      ]
    },
    {
      "id": 511,
      "name": "Milan",
      "names": {
        "es": "Milán",
        "zh": "米兰",
        "de": "Mailand",
        "it": "Milano",
        "ja": "ミラノ",
        "th": "มิลาน",
        "pt": "Milão"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4641943,
        "lng": 9.1896346
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        9.0408867,
        45.3867381,
        9.2781103,
        45.5358482
      ]
    },
    {
      "id": 512,
      "name": "Aurangabad",
      "names": {
        "zh": "奥兰加巴德",
        "th": "ออรันกาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.877263,
        "lng": 75.3390241
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.1790241,
        19.717263,
        75.4990241,
        20.037263
      ]
    },
    {
      "id": 513,
      "name": "Yutan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2589576,
        "lng": 112.552011
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.4945615,
        28.2441442,
        112.5659562,
        28.300898
      ]
    },
    {
      "id": 514,
      "name": "Milwaukee",
      "names": {
        "zh": "密尔沃基",
        "ja": "ミルウォーキー",
        "th": "มิลวอกี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.060714,
        "lng": -87.948425
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.070932,
        42.920823,
        -87.863807,
        43.194772
      ]
    },
    {
      "id": 515,
      "name": "Huangshan",
      "names": {
        "zh": "黄山",
        "ja": "黄山市",
        "th": "หวงซาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7172627,
        "lng": 118.3324811
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.1980715,
        29.3952274,
        118.899107,
        30.5193855
      ]
    },
    {
      "id": 516,
      "name": "Auckland",
      "names": {
        "zh": "奥克兰",
        "ja": "オークランド",
        "th": "โอ๊คแลนด์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.852095,
        "lng": 174.7631803
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        174.6031803,
        -37.012095,
        174.9231803,
        -36.692095
      ]
    },
    {
      "id": 517,
      "name": "Makassar",
      "names": {
        "zh": "望加锡",
        "ja": "マカッサル",
        "th": "มากัสซาร์"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.1342962,
        "lng": 119.4124282
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        119.0740943,
        -5.232416,
        119.543383,
        -4.977545
      ]
    },
    {
      "id": 518,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.4503932,
        "lng": -70.6909047
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -70.9085232,
        19.2840621,
        -70.6159764,
        19.6509249
      ]
    },
    {
      "id": 519,
      "name": "Prague",
      "names": {
        "es": "Praga",
        "zh": "布拉格",
        "de": "Prag",
        "it": "Praga",
        "id": "Praha",
        "ja": "プラハ",
        "th": "ปราก",
        "pt": "Praga"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.0874654,
        "lng": 14.4212535
      },
      "country": "Czechia",
      "importance": 0,
      "bbox": [
        14.2244355,
        49.9419006,
        14.7067867,
        50.1774301
      ]
    },
    {
      "id": 520,
      "name": "Rajkot",
      "names": {
        "zh": "拉杰果德",
        "ja": "ラージコット"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3053263,
        "lng": 70.8028377
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        70.6428377,
        22.1453263,
        70.9628377,
        22.4653263
      ]
    },
    {
      "id": 521,
      "name": "Liangshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.24833,
        "lng": 111.73222
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.71222,
        27.22833,
        111.75222,
        27.26833
      ]
    },
    {
      "id": 522,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4166867,
        "lng": -64.1834193
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -64.3100961,
        -31.5251273,
        -64.05725,
        -31.307545
      ]
    },
    {
      "id": 523,
      "name": "Guarulhos",
      "names": {
        "th": "กัวรุลฮอส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4675941,
        "lng": -46.5277704
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.570839,
        -23.510097,
        -46.335728,
        -23.273746
      ]
    },
    {
      "id": 524,
      "name": "Al Basrah",
      "names": {
        "de": "Alte Basrah",
        "th": "อัลบาอาร์ห์",
        "pt": "Al basáh"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 30.4952371,
        "lng": 47.8090981
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        47.6490981,
        30.3352371,
        47.9690981,
        30.6552371
      ]
    },
    {
      "id": 525,
      "name": "Saitama",
      "names": {
        "zh": "埼玉",
        "ja": "埼玉",
        "th": "ไซตามะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8616402,
        "lng": 139.6457957
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        139.540586,
        35.828592,
        139.75734,
        36.002692
      ]
    },
    {
      "id": 526,
      "name": "Juarez",
      "names": {
        "es": "Juárez",
        "zh": "华雷斯",
        "th": "ฮัวเรซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.452227450000002,
        "lng": -106.44972558551015
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -106.9516255,
        31.1199567,
        -106.1808825,
        31.7839157
      ]
    },
    {
      "id": 527,
      "name": "Montevideo",
      "names": {
        "zh": "蒙得维的亚（Montevideo）",
        "ja": "モンテビデオ",
        "th": "มอนเตวิเดโอ",
        "pt": "Montevidéu"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.8652724,
        "lng": -56.1819512
      },
      "country": "Uruguay",
      "importance": 0,
      "bbox": [
        -56.4313997,
        -34.938056,
        -56.0225006,
        -34.7018526
      ]
    },
    {
      "id": 528,
      "name": "Xintai",
      "names": {
        "zh": "新泰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.9094557,
        "lng": 117.7620286
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.2446638,
        35.6339965,
        117.996664,
        36.1117908
      ]
    },
    {
      "id": 529,
      "name": "Wusong",
      "names": {
        "zh": "吴淞"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9605997,
        "lng": 117.7993808
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.7705858,
        30.9421449,
        117.8110697,
        30.969856
      ]
    },
    {
      "id": 530,
      "name": "Meerut",
      "names": {
        "zh": "密拉特",
        "ja": "メーラト",
        "th": "รุท"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.980203,
        "lng": 77.699436
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.629066,
        28.890058,
        77.791235,
        29.061295
      ]
    },
    {
      "id": 531,
      "name": "Yushu",
      "names": {
        "zh": "玉树"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.8381222,
        "lng": 126.5271463
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.0200309,
        44.5100881,
        127.0877885,
        45.251339
      ]
    },
    {
      "id": 532,
      "name": "Rongcheng",
      "names": {
        "zh": "荣成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.818753,
        "lng": 112.8895426
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.8495426,
        29.778753,
        112.9295426,
        29.858753
      ]
    },
    {
      "id": 533,
      "name": "Huazhou",
      "names": {
        "zh": "化州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6669324,
        "lng": 110.6352871
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.4752871,
        21.5069324,
        110.7952871,
        21.8269324
      ]
    },
    {
      "id": 534,
      "name": "Yangquan",
      "names": {
        "zh": "阳泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8568575,
        "lng": 113.5739616
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.4139616,
        37.6968575,
        113.7339616,
        38.0168575
      ]
    },
    {
      "id": 535,
      "name": "Adelaide",
      "names": {
        "zh": "阿德莱德",
        "ja": "アデレード",
        "th": "แอดิเลด"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -34.9281805,
        "lng": 138.5999312
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        138.4281386,
        -35.350296,
        138.8479091,
        -34.572242
      ]
    },
    {
      "id": 536,
      "name": "Baishan",
      "names": {
        "zh": "白山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.9369269,
        "lng": 126.4184919
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        126.1137294,
        41.3606032,
        128.3122417,
        42.8414054
      ]
    },
    {
      "id": 537,
      "name": "Dayan",
      "names": {
        "zh": "达杨",
        "ja": "ダヤン"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5942615,
        "lng": 102.4921548
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        102.4721548,
        29.5742615,
        102.5121548,
        29.6142615
      ]
    },
    {
      "id": 538,
      "name": "Haicheng",
      "names": {
        "zh": "海城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.8522995,
        "lng": 122.7477959
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        122.3118788,
        40.4799334,
        123.1405868,
        41.1800448
      ]
    },
    {
      "id": 539,
      "name": "Jiangyin",
      "names": {
        "zh": "江阴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9215518,
        "lng": 120.2808847
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.9861851,
        31.6842568,
        120.595899,
        31.9927112
      ]
    },
    {
      "id": 540,
      "name": "Huaiyin",
      "names": {
        "zh": "淮阴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.575149,
        "lng": 119.026939
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.930544,
        33.502353,
        119.179732,
        33.697543
      ]
    },
    {
      "id": 541,
      "name": "Wuzhong",
      "names": {
        "zh": "吴忠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9970759,
        "lng": 106.1943085
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.0343085,
        37.8370759,
        106.3543085,
        38.1570759
      ]
    },
    {
      "id": 542,
      "name": "Cacuaco",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.7771098,
        "lng": 13.3701655
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.3301655,
        -8.8171098,
        13.4101655,
        -8.7371098
      ]
    },
    {
      "id": 543,
      "name": "Barranquilla",
      "names": {
        "zh": "巴兰基亚",
        "th": "ในบาร์รันกียา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9938599,
        "lng": -74.7926118
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -74.8507896,
        10.9206892,
        -74.7585149,
        11.0453193
      ]
    },
    {
      "id": 544,
      "name": "Sofia",
      "names": {
        "zh": "索非亚",
        "ja": "ソフィア",
        "th": "โซเฟีย"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6977028,
        "lng": 23.3217359
      },
      "country": "Bulgaria",
      "importance": 0,
      "bbox": [
        23.167997,
        42.5579851,
        23.6215817,
        42.864302
      ]
    },
    {
      "id": 545,
      "name": "Soweto",
      "names": {
        "zh": "索韦托",
        "ja": "ソウェト"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.256697,
        "lng": 27.863482
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.811291,
        -26.295109,
        27.964256,
        -26.202099
      ]
    },
    {
      "id": 546,
      "name": "Jabalpur",
      "names": {
        "zh": "贾巴尔普尔",
        "ja": "ジャバルプル",
        "th": "จา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.1701522,
        "lng": 79.9324505
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.883083,
        23.116068,
        79.9762,
        23.2110608
      ]
    },
    {
      "id": 547,
      "name": "Rucheng",
      "names": {
        "zh": "汝城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3780131,
        "lng": 120.5564839
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.4885743,
        32.3125819,
        120.6758063,
        32.4436873
      ]
    },
    {
      "id": 548,
      "name": "Nizhniy Novgorod",
      "names": {
        "de": "Nischniy Novgorod"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3264816,
        "lng": 44.0051395
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        43.7206829,
        56.1876534,
        44.1119581,
        56.4023796
      ]
    },
    {
      "id": 549,
      "name": "Shaoyang",
      "names": {
        "zh": "邵阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2431609,
        "lng": 111.4624572
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.3024572,
        27.0831609,
        111.6224572,
        27.4031609
      ]
    },
    {
      "id": 550,
      "name": "Dhanbad",
      "names": {
        "zh": "丹巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.7952809,
        "lng": 86.4309638
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.2709638,
        23.6352809,
        86.5909638,
        23.9552809
      ]
    },
    {
      "id": 551,
      "name": "Comayaguela",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 14.08333,
        "lng": -87.21667
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -87.23667,
        14.06333,
        -87.19667,
        14.10333
      ]
    },
    {
      "id": 552,
      "name": "Laiwu",
      "names": {
        "zh": "莱芜"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2150252,
        "lng": 117.6698329
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.3309502,
        36.0464966,
        117.9293328,
        36.5538629
      ]
    },
    {
      "id": 553,
      "name": "Sharjah",
      "names": {
        "zh": "沙迦",
        "ja": "シャルジャ",
        "th": "ชาร์จาห์"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.3461498,
        "lng": 55.4210633
      },
      "country": "United Arab Emirates",
      "importance": 1,
      "bbox": [
        55.3490689,
        25.2071799,
        55.7434648,
        25.432078
      ]
    },
    {
      "id": 554,
      "name": "Jingling",
      "names": {
        "es": "Tintineo",
        "zh": "玎",
        "de": "Geklimper",
        "ja": "ジャラジャラ",
        "th": "การทำเสียงกรุ๊งกริ้ง",
        "pt": "Tinido"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6668342,
        "lng": 113.1564592
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.1202321,
        30.6325326,
        113.2069706,
        30.7153842
      ]
    },
    {
      "id": 555,
      "name": "Kazan",
      "names": {
        "zh": "喀山",
        "ja": "カザン",
        "th": "คาซาน"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7886328,
        "lng": 49.0997021
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        49.0947021,
        55.7836328,
        49.1047021,
        55.7936328
      ]
    },
    {
      "id": 556,
      "name": "Suwon",
      "names": {
        "zh": "水原",
        "ja": "水原",
        "th": "ซูวอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.266659,
        "lng": 127.0013487
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        127.0012987,
        37.266609,
        127.0013987,
        37.266709
      ]
    },
    {
      "id": 557,
      "name": "Yongcheng",
      "names": {
        "zh": "永城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9311614,
        "lng": 116.4440806
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.9715885,
        33.7075219,
        116.6456169,
        34.2968563
      ]
    },
    {
      "id": 558,
      "name": "Calgary",
      "names": {
        "zh": "卡尔加里",
        "ja": "カルガリー",
        "th": "คาลการี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 51.0456064,
        "lng": -114.057541
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -114.3157587,
        50.842526,
        -113.8600018,
        51.2125013
      ]
    },
    {
      "id": 559,
      "name": "Can Tho",
      "names": {
        "es": "Puede",
        "fr": "Peut-être",
        "zh": "可以吗？",
        "de": "Kann das",
        "it": "Posso",
        "id": "Bisa tho",
        "ja": "できます",
        "th": "สรรพสินค้า",
        "pt": "Tho"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.03333,
        "lng": 105.78333
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.76333,
        10.01333,
        105.80333,
        10.05333
      ]
    },
    {
      "id": 560,
      "name": "Abuja",
      "names": {
        "zh": "阿布贾",
        "ja": "アブジャ",
        "th": "อาบูจา"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.0643305,
        "lng": 7.4892974
      },
      "country": "Nigeria",
      "importance": 0,
      "bbox": [
        7.3292974,
        8.9043305,
        7.6492974,
        9.2243305
      ]
    },
    {
      "id": 561,
      "name": "Mandalay",
      "names": {
        "zh": "曼德勒",
        "ja": "マンダレー",
        "th": "มัณฑะเลย์"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 21.9596834,
        "lng": 96.0948743
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        95.9348743,
        21.7996834,
        96.2548743,
        22.1196834
      ]
    },
    {
      "id": 562,
      "name": "Beidao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.56861,
        "lng": 105.89333
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.87333,
        34.54861,
        105.91333,
        34.58861
      ]
    },
    {
      "id": 563,
      "name": "Xiangshui",
      "names": {
        "zh": "响水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2633984,
        "lng": 105.5172896
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.4772896,
        27.2233984,
        105.5572896,
        27.3033984
      ]
    },
    {
      "id": 564,
      "name": "Dadukou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.744691,
        "lng": 105.227287
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.227237,
        28.744641,
        105.227337,
        28.744741
      ]
    },
    {
      "id": 565,
      "name": "Vila Velha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7124689,
        "lng": -38.6044591
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.6225592,
        -3.7255594,
        -38.5870974,
        -3.7014259
      ]
    },
    {
      "id": 566,
      "name": "Chelyabinsk",
      "names": {
        "zh": "车里雅宾斯克",
        "de": "Tscheljabinsk",
        "ja": "チェリャビンスク",
        "th": "เชเลียบินสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.1559312,
        "lng": 61.401272564351316
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        61.1435957,
        54.9909963,
        61.5920596,
        55.3209835
      ]
    },
    {
      "id": 567,
      "name": "Mombasa",
      "names": {
        "zh": "蒙巴萨",
        "ja": "モンバサ",
        "th": "มอมบาซา"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -4.05052,
        "lng": 39.667169
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        39.507169,
        -4.21052,
        39.827169,
        -3.89052
      ]
    },
    {
      "id": 568,
      "name": "Providence",
      "names": {
        "es": "La Providencia",
        "zh": "普罗维登斯",
        "ja": "プロビデンス",
        "th": "พร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8239891,
        "lng": -71.4128343
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -71.472667,
        41.7724345,
        -71.3736135,
        41.8618007
      ]
    },
    {
      "id": 569,
      "name": "Varanasi",
      "names": {
        "zh": "瓦拉纳西",
        "ja": "バラナシ",
        "th": "พาราณสี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.3356491,
        "lng": 83.0076292
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.8476292,
        25.1756491,
        83.1676292,
        25.4956491
      ]
    },
    {
      "id": 570,
      "name": "Qom",
      "names": {
        "zh": "库姆"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.6422939,
        "lng": 50.8801184
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        50.7331665,
        34.5255185,
        50.9502168,
        34.7750046
      ]
    },
    {
      "id": 571,
      "name": "Zhangye",
      "names": {
        "zh": "张掖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9365134,
        "lng": 100.4556326
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.2956326,
        38.7765134,
        100.6156326,
        39.0965134
      ]
    },
    {
      "id": 572,
      "name": "Hiroshima",
      "names": {
        "zh": "广岛",
        "ja": "広島",
        "th": "ฮิโรชิมา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3917241,
        "lng": 132.4517589
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        132.178545,
        34.288802,
        132.696098,
        34.614767
      ]
    },
    {
      "id": 573,
      "name": "Maiduguri",
      "names": {
        "zh": "迈杜古里"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.8395375,
        "lng": 13.1536214
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        13.076242,
        11.7534864,
        13.1840591,
        11.9134314
      ]
    },
    {
      "id": 574,
      "name": "Maputo",
      "names": {
        "zh": "马普托",
        "ja": "マプト",
        "th": "มาปูโต"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.96553,
        "lng": 32.58322
      },
      "country": "Mozambique",
      "importance": 0,
      "bbox": [
        32.440636,
        -26.094553,
        32.995689,
        -25.810024
      ]
    },
    {
      "id": 575,
      "name": "Doha",
      "names": {
        "zh": "多哈",
        "ja": "ドーハ",
        "th": "โดฮา"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.2856329,
        "lng": 51.5264162
      },
      "country": "Qatar",
      "importance": 0,
      "bbox": [
        51.3957733,
        25.1505411,
        52.4171167,
        25.6832009
      ]
    },
    {
      "id": 576,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.9593609,
        "lng": -60.6617024
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.7832623,
        -33.0348662,
        -60.6116695,
        -32.8696461
      ]
    },
    {
      "id": 577,
      "name": "Pikine",
      "names": {},
      "countryCode": "SN",
      "latLng": {
        "lat": 14.751544,
        "lng": -17.396413
      },
      "country": "Senegal",
      "importance": 3,
      "bbox": [
        -17.4245815,
        14.7336688,
        -17.3768521,
        14.7701456
      ]
    },
    {
      "id": 578,
      "name": "Xinyu",
      "names": {
        "zh": "新余",
        "th": "ซินหยู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8129986,
        "lng": 114.9343116
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.9293116,
        27.8079986,
        114.9393116,
        27.8179986
      ]
    },
    {
      "id": 579,
      "name": "Ahvaz",
      "names": {
        "zh": "阿瓦士"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.323432,
        "lng": 48.66507
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        48.530076,
        31.230737,
        48.805663,
        31.458726
      ]
    },
    {
      "id": 580,
      "name": "Jacksonville",
      "names": {
        "zh": "杰克逊维尔",
        "ja": "ジャクソンビル",
        "th": "แจ็กสันวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3321838,
        "lng": -81.655651
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.0493895,
        30.103748,
        -81.316712,
        30.5862095
      ]
    },
    {
      "id": 581,
      "name": "Medina",
      "names": {
        "zh": "麦地那",
        "ja": "メディナ",
        "th": "เมดินา"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 24.471153,
        "lng": 39.6111216
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        39.4511216,
        24.311153,
        39.7711216,
        24.631153
      ]
    },
    {
      "id": 582,
      "name": "Srinagar",
      "names": {
        "zh": "斯利那加",
        "ja": "スリナガル",
        "th": "ศรีนคร"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 34.0747444,
        "lng": 74.8204443
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        74.6604443,
        33.9147444,
        74.9804443,
        34.2347444
      ]
    },
    {
      "id": 583,
      "name": "Omsk",
      "names": {
        "zh": "鄂木斯克",
        "ja": "オムスク",
        "th": "ออมสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.991375,
        "lng": 73.371529
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        73.0975262,
        54.8240406,
        73.6599421,
        55.4265654
      ]
    },
    {
      "id": 584,
      "name": "Liaoyuan",
      "names": {
        "zh": "辽源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.8863846,
        "lng": 125.1374905
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.8344202,
        42.298203,
        125.8387318,
        43.2308705
      ]
    },
    {
      "id": 585,
      "name": "Cilacap",
      "names": {
        "zh": "芝拉扎"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.7271847,
        "lng": 109.009662
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        108.849662,
        -7.8871847,
        109.169662,
        -7.5671847
      ]
    },
    {
      "id": 586,
      "name": "Yingtan",
      "names": {
        "zh": "鹰潭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.23333,
        "lng": 117
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.98,
        28.21333,
        117.02,
        28.25333
      ]
    },
    {
      "id": 587,
      "name": "Bandar Lampung",
      "names": {
        "de": "Bandar-Lampe",
        "ja": "バンダルランプ",
        "th": "ผ้าพันแผล"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.4460713,
        "lng": 105.2643742
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        105.1784361,
        -5.5172236,
        105.3501897,
        -5.327031
      ]
    },
    {
      "id": 588,
      "name": "Samara",
      "names": {
        "zh": "萨马拉",
        "ja": "サマラ",
        "th": "ซามารา"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.2194648,
        "lng": 50.2039316
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        49.7313889,
        53.0919014,
        50.3903893,
        53.5509321
      ]
    },
    {
      "id": 589,
      "name": "Guankou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1721196,
        "lng": 113.6361834
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.5932338,
        28.1501703,
        113.7316607,
        28.3426735
      ]
    },
    {
      "id": 590,
      "name": "Ulsan",
      "names": {
        "zh": "蔚山",
        "ja": "蔚山",
        "th": "อุลซาน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.5391697,
        "lng": 129.3119136
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        128.9707354,
        35.1861705,
        129.720619,
        35.7244899
      ]
    },
    {
      "id": 591,
      "name": "Dingzhou",
      "names": {
        "zh": "定州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5112175,
        "lng": 114.9970044
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.805026,
        38.2322419,
        115.259739,
        38.6717124
      ]
    },
    {
      "id": 592,
      "name": "Campinas",
      "names": {
        "zh": "坎皮纳斯",
        "ja": "カンピナス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9056391,
        "lng": -47.059564
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2463023,
        -23.0612161,
        -46.8152838,
        -22.7290463
      ]
    },
    {
      "id": 593,
      "name": "Lianyuan",
      "names": {
        "zh": "涟源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6958345,
        "lng": 111.6593577
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.4993577,
        27.5358345,
        111.8193577,
        27.8558345
      ]
    },
    {
      "id": 594,
      "name": "Dakar",
      "names": {
        "zh": "达喀尔",
        "ja": "ダカール",
        "th": "ดาการ์"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.713018,
        "lng": -17.454726
      },
      "country": "Senegal",
      "importance": 0,
      "bbox": [
        -17.530916,
        14.646583,
        -17.414058,
        14.776705
      ]
    },
    {
      "id": 595,
      "name": "Rongcheng",
      "names": {
        "zh": "荣成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.818753,
        "lng": 112.8895426
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.8495426,
        29.778753,
        112.9295426,
        29.858753
      ]
    },
    {
      "id": 596,
      "name": "Kaiyuan",
      "names": {
        "zh": "开元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4741791,
        "lng": 114.8516992
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.8116992,
        38.4341791,
        114.8916992,
        38.5141791
      ]
    },
    {
      "id": 597,
      "name": "Nay Pyi Taw",
      "names": {
        "fr": "Taw Nay Pyi",
        "ja": "Pyi Taw"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 19.745,
        "lng": 96.12972
      },
      "country": "Myanmar",
      "importance": 0,
      "bbox": [
        95.996299,
        19.649362,
        96.190165,
        19.786666
      ]
    },
    {
      "id": 598,
      "name": "Kigali",
      "names": {
        "zh": "基加利",
        "ja": "キガリ",
        "th": "คิกาลี"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.950851,
        "lng": 30.061507
      },
      "country": "Rwanda",
      "importance": 0,
      "bbox": [
        29.901507,
        -2.110851,
        30.221507,
        -1.790851
      ]
    },
    {
      "id": 599,
      "name": "Leiyang",
      "names": {
        "zh": "耒阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4255243,
        "lng": 112.8545783
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.6225769,
        26.1173309,
        113.2232943,
        26.7098464
      ]
    },
    {
      "id": 600,
      "name": "Yichun",
      "names": {
        "zh": "宜春",
        "id": "Di Yichun"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.7211352,
        "lng": 128.8798835
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        128.7198835,
        47.5611352,
        129.0398835,
        47.8811352
      ]
    },
    {
      "id": 601,
      "name": "Benin City",
      "names": {
        "es": "Ciudad de Benin",
        "fr": "Bénin City",
        "zh": "贝宁市",
        "de": "Benin-Stadt",
        "id": "Kota Benin",
        "ja": "ベナンシティ",
        "th": "เมืองเบนิน",
        "pt": "Cidade benim"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.3330586,
        "lng": 5.6221058
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        5.4621058,
        6.1730586,
        5.7821058,
        6.4930586
      ]
    },
    {
      "id": 602,
      "name": "Birmingham",
      "names": {
        "zh": "伯明翰",
        "ja": "バーミンガム",
        "th": "เบอร์มิงแฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4796992,
        "lng": -1.9026911
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.0336486,
        52.381053,
        -1.7288417,
        52.6087058
      ]
    },
    {
      "id": 603,
      "name": "Xiantao",
      "names": {
        "zh": "仙桃"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.3692,
        "lng": 113.449026
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.328918,
        30.279911,
        113.582521,
        30.425777
      ]
    },
    {
      "id": 604,
      "name": "Amritsar",
      "names": {
        "zh": "阿姆利则",
        "ja": "アムリトサル",
        "th": "อัมริตซาร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.6343083,
        "lng": 74.8736788
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.7136788,
        31.4743083,
        75.0336788,
        31.7943083
      ]
    },
    {
      "id": 605,
      "name": "Callao",
      "names": {
        "zh": "卡亚俄"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.0522626,
        "lng": -77.1391133
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -77.158377,
        -12.0706738,
        -77.0767239,
        -11.9368145
      ]
    },
    {
      "id": 606,
      "name": "Monterrey",
      "names": {
        "zh": "蒙特雷",
        "ja": "モンテレー",
        "th": "มอนเตร์เรย์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6802019,
        "lng": -100.315258
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -100.475258,
        25.5202019,
        -100.155258,
        25.8402019
      ]
    },
    {
      "id": 607,
      "name": "Aligarh",
      "names": {
        "zh": "阿里格尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.8815412,
        "lng": 78.0690216
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.9090216,
        27.7215412,
        78.2290216,
        28.0415412
      ]
    },
    {
      "id": 608,
      "name": "Yingchuan",
      "names": {
        "zh": "颍川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1446471,
        "lng": 113.487374
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.4628043,
        34.1112895,
        113.5147318,
        34.1698399
      ]
    },
    {
      "id": 609,
      "name": "Ciudad Nezahualcoyotl",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.411619,
        "lng": -99.027694
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -99.067991,
        19.36621,
        -98.965874,
        19.498779
      ]
    },
    {
      "id": 610,
      "name": "Tripoli",
      "names": {
        "es": "Trípoli",
        "zh": "的黎波里",
        "de": "Tripolis",
        "ja": "トリポリ",
        "th": "ตริโปลี",
        "pt": "Trípoli"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.896672,
        "lng": 13.1777923
      },
      "country": "Libya",
      "importance": 0,
      "bbox": [
        13.0657395,
        32.6253078,
        13.59814,
        32.9212205
      ]
    },
    {
      "id": 611,
      "name": "Nampo",
      "names": {
        "zh": "南浦"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.7367448,
        "lng": 125.4115285
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        124.5057405,
        38.4766519,
        125.6358031,
        39.0417102
      ]
    },
    {
      "id": 612,
      "name": "Rostov",
      "names": {
        "zh": "罗斯托夫",
        "ja": "ロストフ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.2216548,
        "lng": 39.7096061
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        39.4709911,
        47.1533773,
        39.8513903,
        47.3555733
      ]
    },
    {
      "id": 613,
      "name": "Tegucigalpa",
      "names": {
        "zh": "特古西加尔巴",
        "ja": "テグシガルパ",
        "th": "เตกูซิกัลปา"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.1057433,
        "lng": -87.2040052
      },
      "country": "Honduras",
      "importance": 0,
      "bbox": [
        -87.2807992,
        14.0068238,
        -87.1327733,
        14.1422032
      ]
    },
    {
      "id": 614,
      "name": "Tbilisi",
      "names": {
        "fr": "Tbilissi",
        "zh": "第比利斯",
        "de": "Tiflis",
        "ja": "トビリシ",
        "th": "ทบิลิซี"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.6934591,
        "lng": 44.8014495
      },
      "country": "Georgia",
      "importance": 0,
      "bbox": [
        44.5962016,
        41.6177901,
        45.0172908,
        41.8438777
      ]
    },
    {
      "id": 615,
      "name": "Guwahati",
      "names": {
        "zh": "古瓦哈提",
        "ja": "グワーハーティー",
        "th": "วาฮาติ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.182018,
        "lng": 91.7501937
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        91.7501437,
        26.181968,
        91.7502437,
        26.182068
      ]
    },
    {
      "id": 616,
      "name": "Ufa",
      "names": {
        "zh": "乌法"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7261409,
        "lng": 55.947499
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        55.778613,
        54.5007524,
        56.2985557,
        54.9600475
      ]
    },
    {
      "id": 617,
      "name": "Fes",
      "names": {
        "fr": "Fès",
        "zh": "非斯",
        "it": "Fès",
        "ja": "フェス",
        "th": "เฟส"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.0346534,
        "lng": -5.0161926
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -5.0781791,
        33.9764285,
        -4.9377101,
        34.0755055
      ]
    },
    {
      "id": 618,
      "name": "Bien Hoa",
      "names": {
        "de": "Bienhau",
        "it": "Bien HAA"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.9509538,
        "lng": 106.8226134
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.7619663,
        10.822501,
        106.9871127,
        11.0057149
      ]
    },
    {
      "id": 619,
      "name": "N'Djamena",
      "names": {
        "zh": "恩贾梅纳",
        "th": "เอ็นจาเมนา"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.1053232,
        "lng": 15.0468872
      },
      "country": "Chad",
      "importance": 0,
      "bbox": [
        15.0368872,
        12.0953232,
        15.0568872,
        12.1153232
      ]
    },
    {
      "id": 620,
      "name": "Ikare",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.523342,
        "lng": 5.754638
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.714638,
        7.483342,
        5.794638,
        7.563342
      ]
    },
    {
      "id": 621,
      "name": "Salt Lake City",
      "names": {
        "zh": "盐湖城",
        "ja": "ソルトレイクシティ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7608472,
        "lng": -111.9087957
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -111.9088457,
        40.7607972,
        -111.9087457,
        40.7608972
      ]
    },
    {
      "id": 622,
      "name": "Bhilai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.2028719,
        "lng": 81.4210875
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.4160875,
        21.1978719,
        81.4260875,
        21.2078719
      ]
    },
    {
      "id": 623,
      "name": "Haora",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.5736296,
        "lng": 88.3251045
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.2690739,
        22.5492832,
        88.3658322,
        22.641051
      ]
    },
    {
      "id": 624,
      "name": "Tamale",
      "names": {
        "zh": "塔马利",
        "ja": "タマレ"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 9.4051992,
        "lng": -0.8423986
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -1.0023986,
        9.2451992,
        -0.6823986,
        9.5651992
      ]
    },
    {
      "id": 625,
      "name": "Xibeijie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7442313,
        "lng": 98.506344
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.4886707,
        39.7438038,
        98.5120429,
        39.7597957
      ]
    },
    {
      "id": 626,
      "name": "Copenhagen",
      "names": {
        "es": "Copenhague",
        "fr": "Copenhague",
        "zh": "哥本哈根",
        "de": "Kopenhagen",
        "ja": "コペンハーゲン",
        "th": "โคเปนเฮเกน",
        "pt": "Copenhaga"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.6867243,
        "lng": 12.5700724
      },
      "country": "Denmark",
      "importance": 0,
      "bbox": [
        12.4100724,
        55.5267243,
        12.7300724,
        55.8467243
      ]
    },
    {
      "id": 627,
      "name": "Nezahualcoyotl",
      "names": {
        "es": "Nezahualcóyotl"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4079028,
        "lng": -99.0181547
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1781547,
        19.2479028,
        -98.8581547,
        19.5679028
      ]
    },
    {
      "id": 628,
      "name": "Hanchuan",
      "names": {
        "zh": "汉川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6562575,
        "lng": 113.8329583
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.3666854,
        30.382404,
        113.9544734,
        30.8570444
      ]
    },
    {
      "id": 629,
      "name": "Gongzhuling",
      "names": {
        "zh": "公主岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.5025009,
        "lng": 124.8127196
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.6527196,
        43.3425009,
        124.9727196,
        43.6625009
      ]
    },
    {
      "id": 630,
      "name": "Krasnoyarsk",
      "names": {
        "zh": "克拉斯诺亚尔斯克",
        "de": "Krasnojarsk",
        "id": "Di Krasnoyarsk",
        "ja": "クラスノヤルスク"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0091173,
        "lng": 92.872586
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        92.6275236,
        55.9118405,
        93.1684409,
        56.1338167
      ]
    },
    {
      "id": 631,
      "name": "Cologne",
      "names": {
        "es": "Colonia",
        "zh": "科隆",
        "de": "Köln",
        "it": "Colonia",
        "ja": "ケルン",
        "th": "โคโลญ",
        "pt": "Colónia"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.938361,
        "lng": 6.959974
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.7725303,
        50.8304399,
        7.162028,
        51.0849743
      ]
    },
    {
      "id": 632,
      "name": "Yicheng",
      "names": {
        "zh": "宜城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9746758,
        "lng": 114.0289174
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.8689174,
        32.8146758,
        114.1889174,
        33.1346758
      ]
    },
    {
      "id": 633,
      "name": "Zhufeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3309,
        "lng": 120.018
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.998,
        37.3109,
        120.038,
        37.3509
      ]
    },
    {
      "id": 634,
      "name": "Sao Goncalo",
      "names": {
        "ja": "サンゴンカロ",
        "th": "เซากอนคลาโก",
        "pt": "São Gonçalo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8219014,
        "lng": -43.0309252
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.081499,
        -22.8562238,
        -42.9915589,
        -22.7340701
      ]
    },
    {
      "id": 635,
      "name": "Nashville",
      "names": {
        "zh": "纳什维尔",
        "ja": "ナッシュビル",
        "th": "แนชวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1622767,
        "lng": -86.7742984
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -87.054766,
        35.967785,
        -86.515588,
        36.405496
      ]
    },
    {
      "id": 636,
      "name": "Yerevan",
      "names": {
        "fr": "Erevan",
        "zh": "埃里温",
        "ja": "エレバン",
        "th": "เยเรวาน"
      },
      "countryCode": "AM",
      "latLng": {
        "lat": 40.1777112,
        "lng": 44.5126233
      },
      "country": "Armenia",
      "importance": 0,
      "bbox": [
        44.362107,
        40.0658518,
        44.6217689,
        40.2417737
      ]
    },
    {
      "id": 637,
      "name": "Nur-Sultan",
      "names": {
        "es": "Nur-sultán",
        "zh": "光明，苏丹",
        "ja": "nur-スルタン",
        "th": "นู-สุลต่าน",
        "pt": "Nur-sultão"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.1282205,
        "lng": 71.4306682
      },
      "country": "Kazakhstan",
      "importance": 0,
      "bbox": [
        71.2179735,
        50.8576076,
        71.7851913,
        51.3511101
      ]
    },
    {
      "id": 638,
      "name": "Nouakchott",
      "names": {
        "zh": "努瓦克肖特",
        "ja": "ヌアクショット",
        "th": "นูแอกชอต"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 18.088014,
        "lng": -15.983744
      },
      "country": "Mauritania",
      "importance": 0,
      "bbox": [
        -16.030538,
        17.976501,
        -15.887573,
        18.140503
      ]
    },
    {
      "id": 639,
      "name": "Vereeniging",
      "names": {
        "zh": "弗里尼欣"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.6741584,
        "lng": 27.9353056
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.9303056,
        -26.6791584,
        27.9403056,
        -26.6691584
      ]
    },
    {
      "id": 640,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5385087,
        "lng": -77.43428
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -77.6011728,
        37.4467305,
        -77.385303,
        37.6028099
      ]
    },
    {
      "id": 641,
      "name": "Sao Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "เซาหลุยส์",
        "pt": "São Luis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5295265,
        "lng": -44.2963942
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -44.4110534,
        -2.8004242,
        -44.1616205,
        -2.473258
      ]
    },
    {
      "id": 642,
      "name": "Ranchi",
      "names": {
        "zh": "兰契",
        "ja": "ラーンチー",
        "th": "แรนชิ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.3493186,
        "lng": 85.3355688
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        85.3305688,
        23.3443186,
        85.3405688,
        23.3543186
      ]
    },
    {
      "id": 643,
      "name": "San Pedro Sula",
      "names": {
        "zh": "圣佩德罗萨拉",
        "ja": "サンペドロスラ"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.493638650000001,
        "lng": -88.00776696107931
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -88.0551532,
        15.4177323,
        -87.917772,
        15.5692928
      ]
    },
    {
      "id": 644,
      "name": "Taixing",
      "names": {
        "zh": "泰兴市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.1684583,
        "lng": 120.0165362
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.8992878,
        31.9709445,
        120.3685767,
        32.3798854
      ]
    },
    {
      "id": 645,
      "name": "Gwalior",
      "names": {
        "zh": "瓜廖尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.22700175,
        "lng": 78.2004169190401
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.0868471,
        26.1631871,
        78.2640767,
        26.2911003
      ]
    },
    {
      "id": 646,
      "name": "Memphis",
      "names": {
        "zh": "孟菲斯",
        "ja": "メンフィス",
        "th": "เมมฟิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1460249,
        "lng": -90.0517638
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.308366,
        34.9941528,
        -89.637089,
        35.263879
      ]
    },
    {
      "id": 647,
      "name": "Goyang",
      "names": {
        "zh": "高阳"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.6581862,
        "lng": 126.8319452
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        126.6703901,
        37.5717872,
        126.9942694,
        37.7485126
      ]
    },
    {
      "id": 648,
      "name": "Bezwada",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.34325,
        "lng": 80.83924
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 649,
      "name": "Edmonton",
      "names": {
        "zh": "埃德蒙顿",
        "ja": "エドモントン",
        "th": "เอดมันตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 53.5462055,
        "lng": -113.491241
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -113.7138411,
        53.3372558,
        -113.2714783,
        53.7162646
      ]
    },
    {
      "id": 650,
      "name": "Bishkek",
      "names": {
        "fr": "Bichkek",
        "zh": "比什凯克",
        "de": "Bischkek",
        "ja": "ビシュケク",
        "th": "บิชเคก"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.87,
        "lng": 74.59
      },
      "country": "Kyrgyzstan",
      "importance": 0,
      "bbox": [
        74.499765,
        42.751884,
        74.701076,
        42.987011
      ]
    },
    {
      "id": 651,
      "name": "Mizhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.99538,
        "lng": 119.44406
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 652,
      "name": "Tunis",
      "names": {
        "es": "Túnez",
        "zh": "突尼斯",
        "it": "Tunisi",
        "id": "Tunisia",
        "ja": "チュニス",
        "th": "ตูนิส"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8002068,
        "lng": 10.1857757
      },
      "country": "Tunisia",
      "importance": 0,
      "bbox": [
        10.0037899,
        36.6925111,
        10.3548094,
        36.9430196
      ]
    },
    {
      "id": 653,
      "name": "Xishan",
      "names": {
        "zh": "西山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.5519761,
        "lng": 115.6274342
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.5489859,
        28.5017313,
        115.6928801,
        28.6636917
      ]
    },
    {
      "id": 654,
      "name": "Ezhou",
      "names": {
        "zh": "鄂州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4032944,
        "lng": 114.8863775
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.6539639,
        30.2057163,
        115.0860501,
        30.434466
      ]
    },
    {
      "id": 655,
      "name": "Barquisimeto",
      "names": {
        "zh": "巴基西梅托"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0774374,
        "lng": -69.3222293
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -69.4822293,
        9.9174374,
        -69.1622293,
        10.2374374
      ]
    },
    {
      "id": 656,
      "name": "Sendai",
      "names": {
        "zh": "仙台",
        "ja": "仙台",
        "th": "เซนได"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.2677554,
        "lng": 140.8691498
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.469472,
        38.0508487,
        141.2603046,
        38.454699
      ]
    },
    {
      "id": 657,
      "name": "Hegang",
      "names": {
        "zh": "鹤岗"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.3272338,
        "lng": 130.2684769
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.6631623,
        47.0436101,
        132.5123453,
        48.351783
      ]
    },
    {
      "id": 658,
      "name": "Kathmandu",
      "names": {
        "es": "Katmandú",
        "fr": "Katmandou",
        "zh": "加德满都",
        "ja": "カトマンズ",
        "th": "กาฐมาณฑุ"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.7317763,
        "lng": 85.35971719693272
      },
      "country": "Nepal",
      "importance": 0,
      "bbox": [
        85.3596526,
        27.7317194,
        85.3597813,
        27.7318357
      ]
    },
    {
      "id": 659,
      "name": "Mexicali",
      "names": {
        "zh": "墨西卡利",
        "ja": "メヒカリ",
        "th": "เม็กซิกาลี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.6405247,
        "lng": -115.474899
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -115.634899,
        32.4805247,
        -115.314899,
        32.8005247
      ]
    },
    {
      "id": 660,
      "name": "Voronezh",
      "names": {
        "zh": "沃罗涅日",
        "th": "โวโรเนซ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.6605982,
        "lng": 39.2005858
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        39.0132338,
        51.4848657,
        39.6589505,
        51.9027698
      ]
    },
    {
      "id": 661,
      "name": "Perm",
      "names": {
        "zh": "彼尔姆",
        "ja": "パーマ",
        "th": "ดัด"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.014965,
        "lng": 56.246723
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        55.7989168,
        57.8676726,
        56.6566443,
        58.1771542
      ]
    },
    {
      "id": 662,
      "name": "Changwon",
      "names": {
        "zh": "昌原",
        "th": "ชางวอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.227956,
        "lng": 128.6818586
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        128.3479474,
        35.0333962,
        128.8433535,
        35.394254
      ]
    },
    {
      "id": 663,
      "name": "Zhongwei",
      "names": {
        "zh": "中卫"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.5217143,
        "lng": 105.1863456
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.1813456,
        37.5167143,
        105.1913456,
        37.5267143
      ]
    },
    {
      "id": 664,
      "name": "Shouguang",
      "names": {
        "zh": "寿光"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8762356,
        "lng": 118.7322549
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.5380403,
        36.7197152,
        119.1252908,
        37.3466832
      ]
    },
    {
      "id": 665,
      "name": "Bogor",
      "names": {
        "zh": "茂物",
        "ja": "ボゴール",
        "th": "โบกอร์"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.5962986,
        "lng": 106.7972421
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        106.734837,
        -6.6813079,
        106.8485641,
        -6.5143294
      ]
    },
    {
      "id": 666,
      "name": "Raleigh",
      "names": {
        "zh": "罗利",
        "ja": "ローリー",
        "th": "ราลี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7803977,
        "lng": -78.6390989
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -78.8189744,
        35.7082575,
        -78.4707917,
        35.970736
      ]
    },
    {
      "id": 667,
      "name": "Cartagena",
      "names": {
        "zh": "卡塔赫纳",
        "ja": "カルタヘナ",
        "th": "คาร์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4265566,
        "lng": -75.5441671
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -76.1904632,
        9.377755,
        -75.2872048,
        10.7037837
      ]
    },
    {
      "id": 668,
      "name": "Chandigarh",
      "names": {
        "zh": "昌迪加尔",
        "ja": "チャンディーガル",
        "th": "ดิการห์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.72984395,
        "lng": 76.78414567016054
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        76.7049857,
        30.664974,
        76.849028,
        30.7949512
      ]
    },
    {
      "id": 669,
      "name": "Matola",
      "names": {
        "zh": "马托拉"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.966917,
        "lng": 32.466956
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        32.4007541,
        -25.9940871,
        32.5861565,
        -25.6936495
      ]
    },
    {
      "id": 670,
      "name": "Jodhpur",
      "names": {
        "zh": "焦特布尔",
        "ja": "ジョードプル",
        "th": "โช ธ ปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.2967719,
        "lng": 73.0351433
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.9485753,
        26.2059842,
        73.0888716,
        26.3536661
      ]
    },
    {
      "id": 671,
      "name": "Ogbomoso",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 8.133,
        "lng": 4.25
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        4.09,
        7.973,
        4.41,
        8.293
      ]
    },
    {
      "id": 672,
      "name": "Shymkent",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 42.3,
        "lng": 69.6
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        69.58,
        42.28,
        69.62,
        42.32
      ]
    },
    {
      "id": 673,
      "name": "Niamey",
      "names": {
        "zh": "尼亚美",
        "ja": "ニアメ",
        "th": "นีอาเม"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.51194,
        "lng": 2.10778
      },
      "country": "Niger",
      "importance": 0,
      "bbox": [
        2.08778,
        13.49194,
        2.12778,
        13.53194
      ]
    },
    {
      "id": 674,
      "name": "Taizhou",
      "names": {
        "zh": "台州",
        "th": "ในไถโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6581723,
        "lng": 121.4163876
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.2854401,
        27.9716306,
        122.1782805,
        29.3470024
      ]
    },
    {
      "id": 675,
      "name": "Managua",
      "names": {
        "zh": "马那瓜",
        "it": "Di Managua",
        "ja": "マナグア",
        "th": "มานากัว",
        "pt": "Manágua"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.1544035,
        "lng": -86.2737642
      },
      "country": "Nicaragua",
      "importance": 0,
      "bbox": [
        -86.3918528,
        12.0211822,
        -86.1257099,
        12.2242728
      ]
    },
    {
      "id": 676,
      "name": "Bagam",
      "names": {
        "zh": "巴加姆"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 6.6509332,
        "lng": 11.0428368
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        11.0228368,
        6.6309332,
        11.0628368,
        6.6709332
      ]
    },
    {
      "id": 677,
      "name": "Shubra al Khaymah",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.1285988,
        "lng": 31.242218
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.082218,
        29.9685988,
        31.402218,
        30.2885988
      ]
    },
    {
      "id": 678,
      "name": "Maceio",
      "names": {
        "fr": "Maceió",
        "zh": "马塞约",
        "de": "Maceió",
        "ja": "マセイオ",
        "th": "มาเซโอ",
        "pt": "Maceió"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.6476843,
        "lng": -35.7339264
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -35.814353,
        -9.7131396,
        -35.5583254,
        -9.371
      ]
    },
    {
      "id": 679,
      "name": "Monrovia",
      "names": {
        "zh": "蒙罗维亚",
        "id": "Monrovia sprigg Payne",
        "ja": "モンロビア",
        "th": "มันโรเวีย"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.328034,
        "lng": -10.797788
      },
      "country": "Liberia",
      "importance": 0,
      "bbox": [
        -10.957788,
        6.168034,
        -10.637788,
        6.488034
      ]
    },
    {
      "id": 680,
      "name": "Wafangdian",
      "names": {
        "zh": "瓦房店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.623735,
        "lng": 122.00313
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.99813,
        39.618735,
        122.00813,
        39.628735
      ]
    },
    {
      "id": 681,
      "name": "Zhongxiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1702322,
        "lng": 112.5823331
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.122344,
        30.7037077,
        113.0021094,
        31.6164074
      ]
    },
    {
      "id": 682,
      "name": "Odesa",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.4843023,
        "lng": 30.7322878
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        30.6114013,
        46.3426662,
        30.8313753,
        46.6291187
      ]
    },
    {
      "id": 683,
      "name": "Port-au-Prince",
      "names": {
        "es": "Port-au-Príncipe",
        "zh": "端口太子港王子",
        "ja": "ポート・オ・プリンス",
        "th": "พอร์ต-AU-เจ้าชาย"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.547327,
        "lng": -72.3395928
      },
      "country": "Haiti",
      "importance": 0,
      "bbox": [
        -72.5697708,
        18.3398979,
        -72.1589343,
        18.6307338
      ]
    },
    {
      "id": 684,
      "name": "New Orleans",
      "names": {
        "es": "Nueva orleans",
        "fr": "Nouvelle orléans",
        "zh": "新奥尔良",
        "ja": "ニューオーリンズ",
        "pt": "Nova orleans"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.9759983,
        "lng": -90.0782127
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.1399307,
        29.8654809,
        -89.6251763,
        30.1994687
      ]
    },
    {
      "id": 685,
      "name": "Villahermosa",
      "names": {
        "zh": "比亚埃尔莫萨"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9884657,
        "lng": -92.9366326
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -92.9875088,
        17.9571789,
        -92.885027,
        18.0463178
      ]
    },
    {
      "id": 686,
      "name": "Thu Duc",
      "names": {
        "es": "Tu duc",
        "id": "Kutu",
        "pt": "Qui duc"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.8298295,
        "lng": 106.7617899
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        106.6978347,
        10.742096,
        106.8817591,
        10.8988461
      ]
    },
    {
      "id": 687,
      "name": "Volgograd",
      "names": {
        "es": "Volgogrado",
        "zh": "伏尔加格勒",
        "de": "Wolgograd",
        "th": "โวลกอกราด"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.7081906,
        "lng": 44.5153353
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        44.1087686,
        48.4070531,
        44.6874279,
        48.8890717
      ]
    },
    {
      "id": 688,
      "name": "Mysore",
      "names": {
        "zh": "迈索尔",
        "ja": "マイソール",
        "th": "ซอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.3051828,
        "lng": 76.6553609
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.4953609,
        12.1451828,
        76.8153609,
        12.4651828
      ]
    },
    {
      "id": 689,
      "name": "Islamabad",
      "names": {
        "zh": "伊斯兰堡",
        "ja": "イスラマバード",
        "th": "กรุงอิสลามาบัด"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.6938118,
        "lng": 73.0651511
      },
      "country": "Pakistan",
      "importance": 0,
      "bbox": [
        72.9051511,
        33.5338118,
        73.2251511,
        33.8538118
      ]
    },
    {
      "id": 690,
      "name": "Xinyi",
      "names": {
        "zh": "信义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.3580316,
        "lng": 110.942291
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.6782439,
        22.1862278,
        111.6782647,
        22.7070498
      ]
    },
    {
      "id": 691,
      "name": "Raipur",
      "names": {
        "zh": "赖布尔",
        "ja": "ライプル"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.2380912,
        "lng": 81.6336993
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        81.4736993,
        21.0780912,
        81.7936993,
        21.3980912
      ]
    },
    {
      "id": 692,
      "name": "Arequipa",
      "names": {
        "zh": "阿雷基帕",
        "ja": "アレキパ",
        "th": "อาเรคิปา"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -16.3988667,
        "lng": -71.5369607
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -71.5650919,
        -16.4328295,
        -71.5133305,
        -16.3740773
      ]
    },
    {
      "id": 693,
      "name": "Port Harcourt",
      "names": {
        "zh": "哈科特港",
        "de": "Port-Harcourt",
        "it": "Porto harcourt",
        "ja": "ポートハーコート",
        "th": "พอร์ตฮาร์คอร์ต"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 4.761451,
        "lng": 7.019004
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.940566,
        4.712471,
        7.078266,
        4.824071
      ]
    },
    {
      "id": 694,
      "name": "Louisville",
      "names": {
        "zh": "路易斯维尔",
        "ja": "ルイビル",
        "th": "หลุยส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2542376,
        "lng": -85.759407
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.9470644,
        37.9971,
        -85.4051,
        38.3801391
      ]
    },
    {
      "id": 695,
      "name": "Zaoyang",
      "names": {
        "zh": "枣阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.1307686,
        "lng": 112.7665716
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.4557714,
        31.6376839,
        113.1155393,
        32.4277103
      ]
    },
    {
      "id": 696,
      "name": "Shuizhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.448462,
        "lng": 114.8989812
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.8589812,
        33.408462,
        114.9389812,
        33.488462
      ]
    },
    {
      "id": 697,
      "name": "Xingyi",
      "names": {
        "zh": "兴义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0947613,
        "lng": 104.8935472
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        104.5268677,
        24.6207172,
        105.18219,
        25.3808229
      ]
    },
    {
      "id": 698,
      "name": "Kota",
      "names": {
        "zh": "哥打京",
        "ja": "コタ",
        "th": "โกตา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.1737019,
        "lng": 75.8574194
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.6974194,
        25.0137019,
        76.0174194,
        25.3337019
      ]
    },
    {
      "id": 699,
      "name": "Quetta",
      "names": {
        "zh": "奎达"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.1916569,
        "lng": 67.0006542
      },
      "country": "Pakistan",
      "importance": 1,
      "bbox": [
        66.9956542,
        30.1866569,
        67.0056542,
        30.1966569
      ]
    },
    {
      "id": 700,
      "name": "Ottawa",
      "names": {
        "zh": "渥太华",
        "ja": "オタワ",
        "th": "ออตตาวา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4208777,
        "lng": -75.6901106
      },
      "country": "Canada",
      "importance": 0,
      "bbox": [
        -76.3555857,
        44.9617738,
        -75.2465783,
        45.5376502
      ]
    },
    {
      "id": 701,
      "name": "Stockholm",
      "names": {
        "es": "De Estocolmo",
        "zh": "斯德哥尔摩",
        "it": "Stoccolma",
        "ja": "ストックホルム",
        "th": "สตอกโฮล์ม",
        "pt": "Estocolmo"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.3251172,
        "lng": 18.0710935
      },
      "country": "Sweden",
      "importance": 0,
      "bbox": [
        17.7606917,
        59.2272559,
        18.2000673,
        59.4402838
      ]
    },
    {
      "id": 702,
      "name": "Asmara",
      "names": {
        "zh": "阿斯马拉",
        "ja": "アスマラ"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.3389667,
        "lng": 38.9326763
      },
      "country": "Eritrea",
      "importance": 0,
      "bbox": [
        38.7726763,
        15.1789667,
        39.0926763,
        15.4989667
      ]
    },
    {
      "id": 703,
      "name": "Freetown",
      "names": {
        "zh": "弗里敦",
        "id": "Freetown lungi International",
        "ja": "フリータウン",
        "th": "ฟรีทาวน์"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.479004,
        "lng": -13.26795
      },
      "country": "Sierra Leone",
      "importance": 0,
      "bbox": [
        -13.42795,
        8.319004,
        -13.10795,
        8.639004
      ]
    },
    {
      "id": 704,
      "name": "Vientiane",
      "names": {
        "zh": "万象",
        "ja": "ビエンチャン",
        "th": "เวียงจันทน์"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 17.96667,
        "lng": 102.6
      },
      "country": "Laos",
      "importance": 0,
      "bbox": [
        102.54407,
        17.870848,
        102.720806,
        18.042931
      ]
    },
    {
      "id": 705,
      "name": "Jerusalem",
      "names": {
        "es": "Jerusalén",
        "fr": "Jérusalem",
        "zh": "耶路撒冷",
        "it": "Gerusalemme",
        "id": "Yerusalem",
        "ja": "エルサレム",
        "th": "กรุงเยรูซาเล็ม",
        "pt": "Jerusalém"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.7788242,
        "lng": 35.2257626
      },
      "country": "Israel",
      "importance": 0,
      "bbox": [
        35.0852011,
        31.7096214,
        35.2650458,
        31.8826655
      ]
    },
    {
      "id": 706,
      "name": "Bangui",
      "names": {
        "zh": "班吉",
        "ja": "バンギ",
        "th": "บังกี"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 4.3635118,
        "lng": 18.5835913
      },
      "country": "Central African Republic",
      "importance": 0,
      "bbox": [
        18.5238866,
        4.3236747,
        18.644089,
        4.4329846
      ]
    },
    {
      "id": 707,
      "name": "Panama City",
      "names": {
        "es": "Ciudad de Panamá",
        "zh": "巴拿马城",
        "de": "Panama-Stadt",
        "id": "Kota Panama",
        "ja": "パナマシティ",
        "th": "ปานามาซิตี้",
        "pt": "Cidade do Panamá"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.9714493,
        "lng": -79.5341802
      },
      "country": "Panama",
      "importance": 0,
      "bbox": [
        -79.6941802,
        8.8114493,
        -79.3741802,
        9.1314493
      ]
    },
    {
      "id": 708,
      "name": "Amsterdam",
      "names": {
        "zh": "阿姆斯特丹",
        "ja": "アムステルダム",
        "th": "อัมสเตอร์ดัม",
        "pt": "Amesterdão"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3730796,
        "lng": 4.8924534
      },
      "country": "Netherlands",
      "importance": 0,
      "bbox": [
        4.7287776,
        52.2781742,
        5.0791622,
        52.4310638
      ]
    },
    {
      "id": 709,
      "name": "Lome",
      "names": {
        "es": "Lomé",
        "fr": "Lomé",
        "zh": "洛美",
        "ja": "ロメ",
        "th": "โลเม",
        "pt": "Lomé"
      },
      "countryCode": "TG",
      "latLng": {
        "lat": 6.130419,
        "lng": 1.215829
      },
      "country": "Togo",
      "importance": 0,
      "bbox": [
        1.1754424,
        6.1123604,
        1.3057378,
        6.2041066
      ]
    },
    {
      "id": 710,
      "name": "Libreville",
      "names": {
        "zh": "利伯维尔",
        "th": "ลีเบรอวิล"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": 0.4086518,
        "lng": 9.4418849
      },
      "country": "Gabon",
      "importance": 0,
      "bbox": [
        9.2818849,
        0.2486518,
        9.6018849,
        0.5686518
      ]
    },
    {
      "id": 711,
      "name": "Zagreb",
      "names": {
        "zh": "萨格勒布",
        "it": "Zagabria",
        "ja": "ザグレブ",
        "th": "ซาเกร็บ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8130967,
        "lng": 15.9772795
      },
      "country": "Croatia",
      "importance": 0,
      "bbox": [
        15.7732052,
        45.6139532,
        16.2395548,
        45.9691466
      ]
    },
    {
      "id": 712,
      "name": "Dushanbe",
      "names": {
        "zh": "杜尚别",
        "de": "Duschanbe",
        "ja": "ドゥシャンベ",
        "th": "ดูชานเบ"
      },
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5762709,
        "lng": 68.7863573
      },
      "country": "Tajikistan",
      "importance": 0,
      "bbox": [
        68.688826,
        38.464479,
        68.887096,
        38.7068383
      ]
    },
    {
      "id": 713,
      "name": "Lilongwe",
      "names": {
        "zh": "利隆圭",
        "ja": "リロングウェ",
        "th": "ลิลองเว"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.9875107,
        "lng": 33.768144
      },
      "country": "Malawi",
      "importance": 0,
      "bbox": [
        33.608144,
        -14.1475107,
        33.928144,
        -13.8275107
      ]
    },
    {
      "id": 714,
      "name": "Cotonou",
      "names": {
        "es": "De Cotonú",
        "zh": "科托努"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.3557928,
        "lng": 2.4353533
      },
      "country": "Benin",
      "importance": 0,
      "bbox": [
        2.4303533,
        6.3507928,
        2.4403533,
        6.3607928
      ]
    },
    {
      "id": 715,
      "name": "Colombo",
      "names": {
        "zh": "科伦坡",
        "ja": "コロンボ",
        "th": "โคลัมโบ"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.9388614,
        "lng": 79.8542005
      },
      "country": "Sri Lanka",
      "importance": 0,
      "bbox": [
        79.8204584,
        6.8626355,
        79.8900031,
        6.9813596
      ]
    },
    {
      "id": 716,
      "name": "Pretoria",
      "names": {
        "zh": "比勒陀利亚",
        "ja": "プレトリア",
        "th": "พริทอเรีย",
        "pt": "Pretória"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.7459277,
        "lng": 28.1879101
      },
      "country": "South Africa",
      "importance": 0,
      "bbox": [
        28.0279101,
        -25.9059277,
        28.3479101,
        -25.5859277
      ]
    },
    {
      "id": 717,
      "name": "Winnipeg",
      "names": {
        "zh": "温尼伯",
        "ja": "ウイニペグ",
        "th": "วินนิเพก"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.8889694,
        "lng": -97.1342592
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -97.1392592,
        49.8839694,
        -97.1292592,
        49.8939694
      ]
    },
    {
      "id": 718,
      "name": "Quebec City",
      "names": {
        "es": "Ciudad de Quebec",
        "fr": "Québec",
        "zh": "魁北克市",
        "de": "Quebec-Stadt",
        "it": "Città di quebec",
        "id": "Kota Quebec",
        "ja": "ケベックシティ",
        "th": "เมืองควิเบก",
        "pt": "Cidade de quebec"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.8137431,
        "lng": -71.2084061
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -71.5492175,
        46.7277099,
        -71.1336606,
        46.9806797
      ]
    },
    {
      "id": 719,
      "name": "Oslo",
      "names": {
        "zh": "奥斯陆（Oslo）",
        "ja": "オスロ",
        "th": "ออสโล"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.9133301,
        "lng": 10.7389701
      },
      "country": "Norway",
      "importance": 0,
      "bbox": [
        10.4891652,
        59.8093113,
        10.9513894,
        60.1351064
      ]
    },
    {
      "id": 720,
      "name": "Athens",
      "names": {
        "es": "Atenas",
        "fr": "Athènes",
        "zh": "雅典",
        "de": "Athen",
        "it": "Atene",
        "id": "Athena",
        "ja": "アテネ",
        "th": "เอเธนส์",
        "pt": "Atenas"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9755648,
        "lng": 23.7348324
      },
      "country": "Greece",
      "importance": 0,
      "bbox": [
        23.5748324,
        37.8155648,
        23.8948324,
        38.1355648
      ]
    },
    {
      "id": 721,
      "name": "Bujumbura",
      "names": {
        "zh": "布琼布拉",
        "ja": "ブジュンブラ",
        "th": "บูจุมบูรา"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.384872,
        "lng": 29.3657457
      },
      "country": "Burundi",
      "importance": 0,
      "bbox": [
        29.3656957,
        -3.384922,
        29.3657957,
        -3.384822
      ]
    },
    {
      "id": 722,
      "name": "Helsinki",
      "names": {
        "zh": "赫尔辛基",
        "ja": "ヘルシンキ",
        "th": "เฮลซิงกิ",
        "pt": "Helsínquia"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.1717794,
        "lng": 24.9413548
      },
      "country": "Finland",
      "importance": 0,
      "bbox": [
        24.9363548,
        60.1667794,
        24.9463548,
        60.1767794
      ]
    },
    {
      "id": 723,
      "name": "Skopje",
      "names": {
        "zh": "斯科普里",
        "ja": "スコピエ",
        "th": "สโกเปีย"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.991046,
        "lng": 21.4456931
      },
      "country": "Macedonia",
      "importance": 0,
      "bbox": [
        21.4406931,
        41.986046,
        21.4506931,
        41.996046
      ]
    },
    {
      "id": 724,
      "name": "Chisinau",
      "names": {
        "zh": "基希讷乌",
        "id": "Kishinev",
        "th": "คีชีเนา"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 47.0245117,
        "lng": 28.8322923
      },
      "country": "Moldova",
      "importance": 0,
      "bbox": [
        28.7451209,
        46.9203488,
        28.9720397,
        47.0796263
      ]
    },
    {
      "id": 725,
      "name": "Riga",
      "names": {
        "zh": "里加",
        "ja": "リガ",
        "th": "ริกา"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9493977,
        "lng": 24.1051846
      },
      "country": "Latvia",
      "importance": 0,
      "bbox": [
        23.9325504,
        56.8573462,
        24.3245042,
        57.0861068
      ]
    },
    {
      "id": 726,
      "name": "Kingston",
      "names": {
        "zh": "金士顿",
        "ja": "キングストン",
        "th": "คิงส์ตัน"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.9712148,
        "lng": -76.7928128
      },
      "country": "Jamaica",
      "importance": 0,
      "bbox": [
        -76.9528128,
        17.8112148,
        -76.6328128,
        18.1312148
      ]
    },
    {
      "id": 727,
      "name": "Rabat",
      "names": {
        "es": "De Rabat",
        "zh": "拉巴特",
        "ja": "ラバト",
        "th": "ราบัต"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.02236,
        "lng": -6.8340222
      },
      "country": "Morocco",
      "importance": 0,
      "bbox": [
        -6.9174353,
        33.8956294,
        -6.7614635,
        34.0378472
      ]
    },
    {
      "id": 728,
      "name": "Vilnius",
      "names": {
        "zh": "维尔纽斯",
        "ja": "ビリニュス",
        "th": "วิลนีอุส"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6870458,
        "lng": 25.2829111
      },
      "country": "Lithuania",
      "importance": 0,
      "bbox": [
        25.0245351,
        54.5689058,
        25.4814574,
        54.83232
      ]
    },
    {
      "id": 729,
      "name": "San Salvador",
      "names": {
        "zh": "圣萨尔瓦多",
        "ja": "サンサルバドール",
        "th": "ซานซัลวาดอร์"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.6989939,
        "lng": -89.1914249
      },
      "country": "El Salvador",
      "importance": 0,
      "bbox": [
        -89.27672,
        13.632618,
        -89.1629308,
        13.7459166
      ]
    },
    {
      "id": 730,
      "name": "Djibouti",
      "names": {
        "es": "Yibuti",
        "zh": "吉布提",
        "de": "Dschibuti",
        "it": "Gibuti",
        "ja": "ジブチ",
        "th": "จิบูตี"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.5936903,
        "lng": 43.1472724
      },
      "country": "Djibouti",
      "importance": 0,
      "bbox": [
        42.9242482,
        11.5291006,
        43.214037,
        11.7314481
      ]
    },
    {
      "id": 731,
      "name": "Dublin",
      "names": {
        "es": "Dublín",
        "zh": "都柏林",
        "it": "Dublino",
        "ja": "ダブリン",
        "th": "ดับลิน"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.3493795,
        "lng": -6.2605593
      },
      "country": "Ireland",
      "importance": 0,
      "bbox": [
        -6.3870259,
        53.2987342,
        -6.1148829,
        53.4105416
      ]
    },
    {
      "id": 732,
      "name": "The Hague",
      "names": {
        "es": "La Haya",
        "fr": "La Haye",
        "zh": "海牙",
        "de": "Den Haag",
        "it": "L'Aia",
        "id": "Den haag",
        "ja": "ハーグ",
        "th": "กรุงเฮก",
        "pt": "A Haia"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0799838,
        "lng": 4.3113461
      },
      "country": "Netherlands",
      "importance": 0,
      "bbox": [
        4.1849984,
        52.0148484,
        4.4224897,
        52.1350362
      ]
    },
    {
      "id": 733,
      "name": "Asuncion",
      "names": {
        "es": "Asunción",
        "zh": "亚松森",
        "ja": "アスンシオン",
        "th": "อะซุนซิออง",
        "pt": "Assunção"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.2800459,
        "lng": -57.6343814
      },
      "country": "Paraguay",
      "importance": 0,
      "bbox": [
        -57.6736249,
        -25.3811854,
        -57.5249286,
        -25.2188299
      ]
    },
    {
      "id": 734,
      "name": "Lisbon",
      "names": {
        "es": "Lisboa",
        "fr": "Lisbonne",
        "zh": "里斯本",
        "de": "Lissabon",
        "it": "Lisbona",
        "ja": "リスボン",
        "th": "ลิสบอน",
        "pt": "Lisboa"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7077507,
        "lng": -9.1365919
      },
      "country": "Portugal",
      "importance": 0,
      "bbox": [
        -9.2298356,
        38.6913994,
        -9.0863328,
        38.7967584
      ]
    },
    {
      "id": 735,
      "name": "Bratislava",
      "names": {
        "zh": "布拉迪斯拉发",
        "ja": "ブラチスラバ",
        "th": "บราติสลาวา"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.1516988,
        "lng": 17.1093063
      },
      "country": "Slovakia",
      "importance": 0,
      "bbox": [
        16.946044,
        48.0066643,
        17.284531,
        48.2650685
      ]
    },
    {
      "id": 736,
      "name": "Kitchener",
      "names": {
        "zh": "基奇纳",
        "ja": "キッチナー",
        "th": "คิช"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.451291,
        "lng": -80.4927815
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.5734577,
        43.3538756,
        -80.3784145,
        43.5068282
      ]
    },
    {
      "id": 737,
      "name": "Tallinn",
      "names": {
        "es": "Tallin",
        "zh": "塔林",
        "ja": "タリン",
        "th": "ทาลลินน์"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 59.4372155,
        "lng": 24.7453688
      },
      "country": "Estonia",
      "importance": 0,
      "bbox": [
        24.55017,
        59.3518076,
        24.9262831,
        59.5915769
      ]
    },
    {
      "id": 738,
      "name": "Beirut",
      "names": {
        "fr": "Beyrouth",
        "zh": "贝鲁特",
        "ja": "ベイルート",
        "th": "เบรุต",
        "pt": "Beirute"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.88922645,
        "lng": 35.50255852895232
      },
      "country": "Lebanon",
      "importance": 0,
      "bbox": [
        35.466807,
        33.8623309,
        35.542581,
        33.9161128
      ]
    },
    {
      "id": 739,
      "name": "Cape Town",
      "names": {
        "es": "Ciudad del Cabo",
        "fr": "Le Cap",
        "zh": "开普敦",
        "de": "Kapstadt",
        "it": "Città del Capo",
        "id": "Kota",
        "ja": "ケープタウン",
        "th": "เมืองเคป",
        "pt": "Cidade do Cabo"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.9224221,
        "lng": 18.4263523
      },
      "country": "South Africa",
      "importance": 0,
      "bbox": [
        18.4213523,
        -33.9274221,
        18.4313523,
        -33.9174221
      ]
    },
    {
      "id": 740,
      "name": "Tirana",
      "names": {
        "zh": "地拉那",
        "ja": "ティラナ",
        "th": "ติรานา"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 41.3281482,
        "lng": 19.8184435
      },
      "country": "Albania",
      "importance": 0,
      "bbox": [
        19.7547343,
        41.2955352,
        19.876438,
        41.3654357
      ]
    },
    {
      "id": 741,
      "name": "Wellington",
      "names": {
        "zh": "惠灵顿",
        "ja": "ウェリントン",
        "th": "เวลลิงตัน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.2887953,
        "lng": 174.7772114
      },
      "country": "New Zealand",
      "importance": 0,
      "bbox": [
        174.6172114,
        -41.4487953,
        174.9372114,
        -41.1287953
      ]
    },
    {
      "id": 742,
      "name": "Dodoma",
      "names": {
        "zh": "多多马"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.1791181,
        "lng": 35.7468174
      },
      "country": "Tanzania",
      "importance": 0,
      "bbox": [
        35.6728673,
        -6.2285189,
        35.8579725,
        -6.0864366
      ]
    },
    {
      "id": 743,
      "name": "Halifax",
      "names": {
        "zh": "哈利法克斯",
        "ja": "ハリファックス",
        "th": "แฮลิแฟกซ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.648618,
        "lng": -63.5859487
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -63.7226567,
        44.581167,
        -63.5427496,
        44.7112114
      ]
    },
    {
      "id": 744,
      "name": "Bissau",
      "names": {
        "zh": "比绍",
        "ja": "ビサウ",
        "th": "บิสเซา"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.86327,
        "lng": -15.584522
      },
      "country": "Guinea-Bissau",
      "importance": 0,
      "bbox": [
        -15.662189,
        11.797055,
        -15.550796,
        11.938732
      ]
    },
    {
      "id": 745,
      "name": "Canberra",
      "names": {
        "zh": "堪培拉",
        "ja": "キャンベラ",
        "th": "แคนเบอร์รา"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -35.2975906,
        "lng": 149.1012676
      },
      "country": "Australia",
      "importance": 0,
      "bbox": [
        148.9412676,
        -35.4575906,
        149.2612676,
        -35.1375906
      ]
    },
    {
      "id": 746,
      "name": "Juba",
      "names": {
        "zh": "朱巴",
        "ja": "ジューバ",
        "th": "จูบา"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 4.8459246,
        "lng": 31.5959173
      },
      "country": "South Sudan",
      "importance": 0,
      "bbox": [
        31.5795942,
        4.8306652,
        31.6232183,
        4.8882052
      ]
    },
    {
      "id": 747,
      "name": "Yamoussoukro",
      "names": {
        "es": "Yamusukro",
        "zh": "亚穆苏克罗"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.82055,
        "lng": -5.27674
      },
      "country": "Côte d'Ivoire",
      "importance": 0,
      "bbox": [
        -5.329647,
        6.778669,
        -5.210208,
        6.88234
      ]
    },
    {
      "id": 748,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.4283182,
        "lng": -123.364953
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -123.398075,
        48.4028109,
        -123.3224223,
        48.4504983
      ]
    },
    {
      "id": 749,
      "name": "Maseru",
      "names": {
        "zh": "马塞卢",
        "ja": "マセル",
        "th": "มาเซรู"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -29.310054,
        "lng": 27.478222
      },
      "country": "Lesotho",
      "importance": 0,
      "bbox": [
        27.318222,
        -29.470054,
        27.638222,
        -29.150054
      ]
    },
    {
      "id": 750,
      "name": "Nicosia",
      "names": {
        "fr": "Nicosie",
        "zh": "尼科西亚",
        "ja": "ニコシア",
        "th": "นิโคเซีย",
        "pt": "Nicósia"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 35.1746503,
        "lng": 33.3638783
      },
      "country": "Cyprus",
      "importance": 0,
      "bbox": [
        33.3358797,
        35.1521717,
        33.4182382,
        35.2017784
      ]
    },
    {
      "id": 751,
      "name": "Windhoek",
      "names": {
        "zh": "温得和克",
        "ja": "ウィントフック",
        "th": "วินด์ฮุก"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -22.5776104,
        "lng": 17.0772739
      },
      "country": "Namibia",
      "importance": 0,
      "bbox": [
        16.9868681,
        -22.638657,
        17.1306205,
        -22.4777549
      ]
    },
    {
      "id": 752,
      "name": "Port Moresby",
      "names": {
        "zh": "港口港口",
        "ja": "ポートモレスビー",
        "th": "พอร์ตมอร์สบี้",
        "pt": "Porto morres"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -9.4743301,
        "lng": 147.1599504
      },
      "country": "Papua New Guinea",
      "importance": 0,
      "bbox": [
        146.9999504,
        -9.6343301,
        147.3199504,
        -9.3143301
      ]
    },
    {
      "id": 753,
      "name": "Porto-Novo",
      "names": {
        "zh": "波多诺伏",
        "ja": "ポルトノボ",
        "th": "ปอร์โต-โนโว"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.4935182000000005,
        "lng": 2.624780144136807
      },
      "country": "Benin",
      "importance": 0,
      "bbox": [
        2.5798893,
        6.4485003,
        2.6699495,
        6.5350056
      ]
    },
    {
      "id": 754,
      "name": "Sucre",
      "names": {
        "zh": "苏克雷",
        "ja": "スクレ",
        "th": "ซูเกร"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -19.0477251,
        "lng": -65.2594306
      },
      "country": "Bolivia",
      "importance": 0,
      "bbox": [
        -65.4194306,
        -19.2077251,
        -65.0994306,
        -18.8877251
      ]
    },
    {
      "id": 755,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.9325427,
        "lng": -84.0795782
      },
      "country": "Costa Rica",
      "importance": 0,
      "bbox": [
        -84.2395782,
        9.7725427,
        -83.9195782,
        10.0925427
      ]
    },
    {
      "id": 756,
      "name": "Ljubljana",
      "names": {
        "zh": "卢布尔雅那",
        "it": "Lubiana",
        "ja": "リュブリャナ",
        "th": "ลูบลิยานา"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0500268,
        "lng": 14.5069289
      },
      "country": "Slovenia",
      "importance": 0,
      "bbox": [
        14.408617,
        45.9740637,
        14.755332,
        46.1459968
      ]
    },
    {
      "id": 757,
      "name": "Sarajevo",
      "names": {
        "zh": "萨拉热窝",
        "ja": "サラエボ",
        "th": "ซาราเยโว"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.8604591,
        "lng": 18.3989497
      },
      "country": "Bosnia And Herzegovina",
      "importance": 0,
      "bbox": [
        18.3939497,
        43.8554591,
        18.4039497,
        43.8654591
      ]
    },
    {
      "id": 758,
      "name": "Nassau",
      "names": {
        "zh": "拿骚",
        "ja": "ナッソー",
        "th": "นัสเซา"
      },
      "countryCode": "BS",
      "latLng": {
        "lat": 25.0782266,
        "lng": -77.3383438
      },
      "country": "The Bahamas",
      "importance": 0,
      "bbox": [
        -77.4983438,
        24.9182266,
        -77.1783438,
        25.2382266
      ]
    },
    {
      "id": 759,
      "name": "Bloemfontein",
      "names": {
        "zh": "布隆方丹",
        "ja": "ブルームフォンテーン",
        "th": "บลูมฟอนเทน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -29.116395,
        "lng": 26.215496
      },
      "country": "South Africa",
      "importance": 0,
      "bbox": [
        26.055496,
        -29.276395,
        26.375496,
        -28.956395
      ]
    },
    {
      "id": 760,
      "name": "Fort-de-France",
      "names": {
        "zh": "法兰西堡",
        "ja": "フォート・ド・フランス",
        "th": "ป้อม-เดอฝรั่งเศส"
      },
      "countryCode": "MQ",
      "latLng": {
        "lat": 14.6027962,
        "lng": -61.0676724
      },
      "country": "Martinique",
      "importance": 0,
      "bbox": [
        -61.1030216,
        14.5900261,
        -61.034337,
        14.7083688
      ]
    },
    {
      "id": 761,
      "name": "New Delhi",
      "names": {
        "es": "Nueva delhi",
        "zh": "新德里",
        "de": "Neu-Delhi",
        "it": "Nuova delhi",
        "ja": "ニューデリー",
        "th": "นิวเดลี",
        "pt": "Nova Delhi"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6138954,
        "lng": 77.2090057
      },
      "country": "India",
      "importance": 0,
      "bbox": [
        77.0503708,
        28.4812209,
        77.2551392,
        28.6456842
      ]
    },
    {
      "id": 762,
      "name": "Gaborone",
      "names": {
        "zh": "哈博罗内",
        "ja": "ガボローネ",
        "th": "กาโบโรเน"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.6581357,
        "lng": 25.9088474
      },
      "country": "Botswana",
      "importance": 0,
      "bbox": [
        25.7709016,
        -24.8159274,
        25.9802817,
        -24.5345393
      ]
    },
    {
      "id": 763,
      "name": "Paramaribo",
      "names": {
        "zh": "帕拉马里博",
        "ja": "パラマリボ",
        "th": "ปารามาริโบ"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.8247628,
        "lng": -55.1703941
      },
      "country": "Suriname",
      "importance": 0,
      "bbox": [
        -55.3303941,
        5.6647628,
        -55.0103941,
        5.9847628
      ]
    },
    {
      "id": 764,
      "name": "Dili",
      "names": {
        "zh": "帝力",
        "ja": "ディリ",
        "th": "ดิลี",
        "pt": "Díli"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -8.5536809,
        "lng": 125.5784093
      },
      "country": "Timor-Leste",
      "importance": 0,
      "bbox": [
        125.4184093,
        -8.7136809,
        125.7384093,
        -8.3936809
      ]
    },
    {
      "id": 765,
      "name": "Male",
      "names": {
        "es": "VARÓN",
        "fr": "Mâle",
        "zh": "雄性",
        "de": "Männlich",
        "it": "Maschio",
        "id": "Laki-laki",
        "ja": "男性",
        "th": "เพศชาย",
        "pt": "Masculino"
      },
      "countryCode": "MV",
      "latLng": {
        "lat": 4.1779879,
        "lng": 73.5107387
      },
      "country": "Maldives",
      "importance": 0,
      "bbox": [
        73.4545947,
        4.1683039,
        73.5519146,
        4.2411711
      ]
    },
    {
      "id": 766,
      "name": "Georgetown",
      "names": {
        "zh": "乔治敦",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.8137426,
        "lng": -58.1624465
      },
      "country": "Guyana",
      "importance": 0,
      "bbox": [
        -58.1747151,
        6.7670936,
        -58.1052699,
        6.8275526
      ]
    },
    {
      "id": 767,
      "name": "Gibraltar",
      "names": {
        "zh": "直布罗陀",
        "it": "Gibilterra",
        "ja": "ジブラルタル",
        "th": "ยิบรอลตาร์"
      },
      "countryCode": "GI",
      "latLng": {
        "lat": 36.136159,
        "lng": -5.348677
      },
      "country": "Gibraltar",
      "importance": 0,
      "bbox": [
        -5.365978,
        36.108735,
        -5.337418,
        36.155037
      ]
    },
    {
      "id": 768,
      "name": "Saint-Denis",
      "names": {
        "zh": "圣 - 丹尼斯",
        "ja": "サン・デニス",
        "th": "เซนต์เดนิส"
      },
      "countryCode": "RE",
      "latLng": {
        "lat": -20.8799889,
        "lng": 55.448137
      },
      "country": "Reunion",
      "importance": 0,
      "bbox": [
        55.3756284,
        -21.0150497,
        55.5126876,
        -20.8717411
      ]
    },
    {
      "id": 769,
      "name": "Malabo",
      "names": {
        "zh": "马拉博",
        "ja": "マラボ",
        "th": "มาลาโบ"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 3.741877,
        "lng": 8.7740663
      },
      "country": "Equatorial Guinea",
      "importance": 0,
      "bbox": [
        8.6140663,
        3.581877,
        8.9340663,
        3.901877
      ]
    },
    {
      "id": 770,
      "name": "Podgorica",
      "names": {
        "zh": "波德戈里察",
        "th": "พอดกอรีตซา"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.4415238,
        "lng": 19.2621081
      },
      "country": "Montenegro",
      "importance": 0,
      "bbox": [
        19.1797304,
        42.3771251,
        19.3140572,
        42.4748799
      ]
    },
    {
      "id": 771,
      "name": "Manama",
      "names": {
        "zh": "麦纳麦",
        "ja": "マナマ",
        "th": "มานามา"
      },
      "countryCode": "BH",
      "latLng": {
        "lat": 26.2235041,
        "lng": 50.5822436
      },
      "country": "Bahrain",
      "importance": 0,
      "bbox": [
        50.4222436,
        26.0635041,
        50.7422436,
        26.3835041
      ]
    },
    {
      "id": 772,
      "name": "Port Louis",
      "names": {
        "zh": "港口路易斯",
        "ja": "ポートルイス",
        "th": "พอร์ตหลุยส์",
        "pt": "Porto louis"
      },
      "countryCode": "MU",
      "latLng": {
        "lat": -20.1624522,
        "lng": 57.5028044
      },
      "country": "Mauritius",
      "importance": 0,
      "bbox": [
        57.3428044,
        -20.3224522,
        57.6628044,
        -20.0024522
      ]
    },
    {
      "id": 773,
      "name": "Willemstad",
      "names": {
        "zh": "威廉斯塔德",
        "ja": "ウィレムスタット",
        "th": "วิลเลมสตัด"
      },
      "countryCode": "CW",
      "latLng": {
        "lat": 12.1066695,
        "lng": -68.9351307
      },
      "country": "Curaçao ",
      "importance": 0,
      "bbox": [
        -68.9508986,
        12.1023801,
        -68.9287526,
        12.1181232
      ]
    },
    {
      "id": 774,
      "name": "Bern",
      "names": {
        "es": "Berna",
        "fr": "Berne",
        "zh": "伯尔尼",
        "ja": "ベルン",
        "th": "เบิร์น",
        "pt": "Berna"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.9484742,
        "lng": 7.4521749
      },
      "country": "Switzerland",
      "importance": 0,
      "bbox": [
        7.2943145,
        46.9190326,
        7.4955563,
        46.9901527
      ]
    },
    {
      "id": 775,
      "name": "Papeete",
      "names": {
        "zh": "帕皮提"
      },
      "countryCode": "PF",
      "latLng": {
        "lat": -17.53333,
        "lng": -149.56667
      },
      "country": "French Polynesia",
      "importance": 0,
      "bbox": [
        -149.58667,
        -17.55333,
        -149.54667,
        -17.51333
      ]
    },
    {
      "id": 776,
      "name": "Luxembourg",
      "names": {
        "es": "Luxemburgo",
        "zh": "卢森堡",
        "de": "Luxemburg",
        "it": "Lussemburgo",
        "id": "Luksemburg",
        "ja": "ルクセンブルク",
        "th": "ลักเซมเบิร์ก",
        "pt": "Luxemburgo"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5999681,
        "lng": 6.1342493
      },
      "country": "Luxembourg",
      "importance": 0,
      "bbox": [
        6.1292493,
        49.5949681,
        6.1392493,
        49.6049681
      ]
    },
    {
      "id": 777,
      "name": "Reykjavik",
      "names": {
        "es": "Reykjavík",
        "zh": "雷克雅未克",
        "ja": "レイキャビク",
        "th": "เรคยาวิก"
      },
      "countryCode": "IS",
      "latLng": {
        "lat": 64.149654,
        "lng": -21.9408418
      },
      "country": "Iceland",
      "importance": 0,
      "bbox": [
        -21.9408918,
        64.149604,
        -21.9407918,
        64.149704
      ]
    },
    {
      "id": 778,
      "name": "Praia",
      "names": {
        "zh": "普拉亚",
        "ja": "プライア"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 14.9162811,
        "lng": -23.5095095
      },
      "country": "Cabo Verde",
      "importance": 0,
      "bbox": [
        -23.6695095,
        14.7562811,
        -23.3495095,
        15.0762811
      ]
    },
    {
      "id": 779,
      "name": "Sri Jayewardenepura Kotte",
      "names": {
        "de": "SRI Jaywardepura Kotte",
        "ja": "スリ・ジエワデネプラコッテ",
        "th": "ศรี Jayewardenepura Kotte"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.8883219,
        "lng": 79.9187415
      },
      "country": "Sri Lanka",
      "importance": 0,
      "bbox": [
        79.7587415,
        6.7283219,
        80.0787415,
        7.0483219
      ]
    },
    {
      "id": 780,
      "name": "Bridgetown",
      "names": {
        "zh": "布里奇敦",
        "th": "บริดจ์ทาวน์"
      },
      "countryCode": "BB",
      "latLng": {
        "lat": 13.0977832,
        "lng": -59.6184184
      },
      "country": "Barbados",
      "importance": 0,
      "bbox": [
        -59.632993,
        13.0766977,
        -59.599462,
        13.1089012
      ]
    },
    {
      "id": 781,
      "name": "Moroni",
      "names": {
        "zh": "莫罗尼",
        "th": "โมโรนี",
        "pt": "Morôni"
      },
      "countryCode": "KM",
      "latLng": {
        "lat": -11.6931255,
        "lng": 43.2543044
      },
      "country": "Comoros",
      "importance": 0,
      "bbox": [
        43.0943044,
        -11.8531255,
        43.4143044,
        -11.5331255
      ]
    },
    {
      "id": 782,
      "name": "Thimphu",
      "names": {
        "zh": "廷布",
        "ja": "ティンプー",
        "th": "ทิมพู"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.481591199999997,
        "lng": 89.63109800000001
      },
      "country": "Bhutan",
      "importance": 0,
      "bbox": [
        89.6309924,
        27.4814803,
        89.6312036,
        27.4817021
      ]
    },
    {
      "id": 783,
      "name": "Mbabane",
      "names": {
        "zh": "姆巴巴纳",
        "th": "อัมบาบาเน"
      },
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.325745,
        "lng": 31.144663
      },
      "country": "Swaziland",
      "importance": 0,
      "bbox": [
        30.984663,
        -26.485745,
        31.304663,
        -26.165745
      ]
    },
    {
      "id": 784,
      "name": "Noumea",
      "names": {
        "fr": "Nouméa",
        "zh": "努美阿",
        "ja": "ヌメア",
        "th": "นูเมอา"
      },
      "countryCode": "NC",
      "latLng": {
        "lat": -22.2745264,
        "lng": 166.442419
      },
      "country": "New Caledonia",
      "importance": 0,
      "bbox": [
        166.3857934,
        -22.3125269,
        166.5040433,
        -22.2167228
      ]
    },
    {
      "id": 785,
      "name": "Honiara",
      "names": {
        "zh": "霍尼亚拉",
        "ja": "ホニアラ"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -9.4310769,
        "lng": 159.9552552
      },
      "country": "Solomon Islands",
      "importance": 0,
      "bbox": [
        159.7952552,
        -9.5910769,
        160.1152552,
        -9.2710769
      ]
    },
    {
      "id": 786,
      "name": "Suva",
      "names": {
        "zh": "苏瓦",
        "ja": "スバ",
        "th": "ซูวา"
      },
      "countryCode": "FJ",
      "latLng": {
        "lat": -18.1415884,
        "lng": 178.4421662
      },
      "country": "Fiji",
      "importance": 0,
      "bbox": [
        178.2821662,
        -18.3015884,
        178.6021662,
        -17.9815884
      ]
    },
    {
      "id": 787,
      "name": "Ankara",
      "names": {
        "zh": "安卡拉",
        "ja": "アンカラ",
        "th": "อังการา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9348256,
        "lng": 32.8444026
      },
      "country": "Turkey",
      "importance": 0,
      "bbox": [
        32.8443526,
        39.9347756,
        32.8444526,
        39.9348756
      ]
    },
    {
      "id": 788,
      "name": "Castries",
      "names": {
        "zh": "卡斯特里",
        "ja": "カストリーズ",
        "th": "แคสตรีส์"
      },
      "countryCode": "LC",
      "latLng": {
        "lat": 14.0095966,
        "lng": -60.9902359
      },
      "country": "Saint Lucia",
      "importance": 0,
      "bbox": [
        -61.0302359,
        13.9695966,
        -60.9502359,
        14.0495966
      ]
    },
    {
      "id": 789,
      "name": "Cayenne",
      "names": {
        "es": "Pimentón",
        "zh": "卡宴",
        "it": "Peperoncino di Cayenna",
        "ja": "カイエンヌ",
        "th": "พริกป่น",
        "pt": "Pimenta de caiena"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 4.9371544,
        "lng": -52.3258736
      },
      "country": "French Guiana",
      "importance": 0,
      "bbox": [
        -52.5937858,
        4.8868967,
        -52.1624969,
        5.2972653
      ]
    },
    {
      "id": 790,
      "name": "Sao Tome",
      "names": {
        "zh": "圣多美",
        "ja": "サントーム",
        "pt": "São Tomé"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.3389242,
        "lng": 6.7313031
      },
      "country": "Sao Tome And Principe",
      "importance": 0,
      "bbox": [
        6.5713031,
        0.1789242,
        6.8913031,
        0.4989242
      ]
    },
    {
      "id": 791,
      "name": "Port-Vila",
      "names": {
        "zh": "端口维拉",
        "it": "Port Vila",
        "ja": "ポートビラ",
        "th": "พอร์ตวิลา",
        "pt": "Port Vila"
      },
      "countryCode": "VU",
      "latLng": {
        "lat": -17.73381,
        "lng": 168.32188
      },
      "country": "Vanuatu",
      "importance": 0,
      "bbox": [
        168.257387,
        -17.806732,
        168.415476,
        -17.672381
      ]
    },
    {
      "id": 792,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "BM",
      "latLng": {
        "lat": 32.2942679,
        "lng": -64.7853167
      },
      "country": "Bermuda",
      "importance": 0,
      "bbox": [
        -64.7889323,
        32.2905093,
        -64.7774884,
        32.2997335
      ]
    },
    {
      "id": 793,
      "name": "Bandar Seri Begawan",
      "names": {
        "fr": "Bandar Seri Begrawan",
        "ja": "バンダルセリブガワン"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.889737,
        "lng": 114.941695
      },
      "country": "Brunei",
      "importance": 0,
      "bbox": [
        114.873982,
        4.856653,
        115.007188,
        4.974231
      ]
    },
    {
      "id": 794,
      "name": "Monaco",
      "names": {
        "es": "Mónaco",
        "zh": "摩纳哥",
        "id": "Monako",
        "ja": "モナコ",
        "th": "โมนาโก"
      },
      "countryCode": "MC",
      "latLng": {
        "lat": 43.7323492,
        "lng": 7.4276832
      },
      "country": "Monaco",
      "importance": 0,
      "bbox": [
        7.4090279,
        43.5165358,
        7.5329917,
        43.7519173
      ]
    },
    {
      "id": 795,
      "name": "Gitega",
      "names": {
        "zh": "基特加"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.4284953,
        "lng": 29.9249718
      },
      "country": "Burundi",
      "importance": 0,
      "bbox": [
        29.7649718,
        -3.5884953,
        30.0849718,
        -3.2684953
      ]
    },
    {
      "id": 796,
      "name": "Port of Spain",
      "names": {
        "es": "Puerto España",
        "fr": "Port d'Espagne",
        "zh": "西班牙港",
        "de": "Port von Spanien",
        "it": "Porto di Spagna",
        "id": "Pelabuhan",
        "ja": "スペインの港",
        "th": "พอร์ตของสเปน",
        "pt": "Porto de Espanha"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6572678,
        "lng": -61.5180173
      },
      "country": "Trinidad And Tobago",
      "importance": 0,
      "bbox": [
        -61.5481359,
        10.635395,
        -61.4941448,
        10.6810521
      ]
    },
    {
      "id": 797,
      "name": "Apia",
      "names": {
        "zh": "阿皮亚",
        "ja": "アピア",
        "th": "เอเปีย"
      },
      "countryCode": "WS",
      "latLng": {
        "lat": -13.8344639,
        "lng": -171.7649144
      },
      "country": "Samoa",
      "importance": 0,
      "bbox": [
        -171.9249144,
        -13.9944639,
        -171.6049144,
        -13.6744639
      ]
    },
    {
      "id": 798,
      "name": "Tarawa",
      "names": {
        "zh": "塔拉瓦",
        "ja": "タラワ"
      },
      "countryCode": "KI",
      "latLng": {
        "lat": 1.4845541999999998,
        "lng": 172.96896482000938
      },
      "country": "Kiribati",
      "importance": 0,
      "bbox": [
        172.8986226,
        1.3237068,
        173.1669194,
        1.6419348
      ]
    },
    {
      "id": 799,
      "name": "Oranjestad",
      "names": {
        "zh": "奥拉涅斯塔德",
        "it": "Libero stato dell'orange",
        "ja": "オラニエスタッド"
      },
      "countryCode": "AW",
      "latLng": {
        "lat": 12.5201024,
        "lng": -70.0371329
      },
      "country": "Aruba",
      "importance": 0,
      "bbox": [
        -70.0771329,
        12.4801024,
        -69.9971329,
        12.5601024
      ]
    },
    {
      "id": 800,
      "name": "Saint Helier",
      "names": {
        "es": "Santa Helier",
        "fr": "Saint hélicole",
        "zh": "圣战者",
        "de": "Heiliger Helier",
        "ja": "セントヘリエ",
        "th": "เซนต์เฮลเอะ",
        "pt": "São Helier"
      },
      "countryCode": "JE",
      "latLng": {
        "lat": 49.1856637,
        "lng": -2.1102277
      },
      "country": "Jersey",
      "importance": 0,
      "bbox": [
        -2.136942,
        49.1678468,
        -2.0849498,
        49.2188866
      ]
    },
    {
      "id": 801,
      "name": "Banjul",
      "names": {
        "zh": "班珠尔",
        "th": "บันจุล"
      },
      "countryCode": "GM",
      "latLng": {
        "lat": 13.45535,
        "lng": -16.575646
      },
      "country": "The Gambia",
      "importance": 0,
      "bbox": [
        -16.5966956,
        13.4196522,
        -16.535462,
        13.4718799
      ]
    },
    {
      "id": 802,
      "name": "Mamoudzou",
      "names": {},
      "countryCode": "YT",
      "latLng": {
        "lat": -12.7804136,
        "lng": 45.2279761
      },
      "country": "Mayotte",
      "importance": 0,
      "bbox": [
        45.1525509,
        -12.8222828,
        45.2511587,
        -12.7527087
      ]
    },
    {
      "id": 803,
      "name": "Majuro",
      "names": {
        "zh": "马朱罗",
        "ja": "マジュロ",
        "th": "มาจูโร"
      },
      "countryCode": "MH",
      "latLng": {
        "lat": 7.0909924,
        "lng": 171.3816354
      },
      "country": "Marshall Islands",
      "importance": 0,
      "bbox": [
        170.8173289,
        6.5245935,
        171.5589588,
        7.7443018
      ]
    },
    {
      "id": 804,
      "name": "Douglas",
      "names": {
        "zh": "道格拉斯",
        "ja": "ダグラス",
        "th": "ดักลาส"
      },
      "countryCode": "IM",
      "latLng": {
        "lat": 54.149774,
        "lng": -4.4779021
      },
      "country": "Isle Of Man",
      "importance": 0,
      "bbox": [
        -4.5294604,
        54.1389676,
        -4.4552287,
        54.1854843
      ]
    },
    {
      "id": 805,
      "name": "George Town",
      "names": {
        "fr": "Ville de George",
        "zh": "乔治镇",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์",
        "pt": "Cidade de george"
      },
      "countryCode": "KY",
      "latLng": {
        "lat": 19.2953549,
        "lng": -81.3807776
      },
      "country": "Cayman Islands",
      "importance": 0,
      "bbox": [
        -81.3930781,
        19.2628998,
        -81.2831993,
        19.3378433
      ]
    },
    {
      "id": 806,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "SC",
      "latLng": {
        "lat": -4.6232085,
        "lng": 55.452359
      },
      "country": "Seychelles",
      "importance": 0,
      "bbox": [
        55.292359,
        -4.7832085,
        55.612359,
        -4.4632085
      ]
    },
    {
      "id": 807,
      "name": "Kingstown",
      "names": {
        "zh": "金斯敦",
        "ja": "キングスタウン",
        "th": "คิงส์ทาวน์"
      },
      "countryCode": "VC",
      "latLng": {
        "lat": 13.1561864,
        "lng": -61.2279621
      },
      "country": "Saint Vincent And The Grenadines",
      "importance": 0,
      "bbox": [
        -61.2679621,
        13.1161864,
        -61.1879621,
        13.1961864
      ]
    },
    {
      "id": 808,
      "name": "Andorra la Vella",
      "names": {
        "fr": "Andorre la vella",
        "zh": "安道尔拉维拉",
        "ja": "アンドララベリャ",
        "th": "อันดอร์ราลาเวลลา"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.49791845,
        "lng": 1.5032263738029337
      },
      "country": "Andorra",
      "importance": 0,
      "bbox": [
        1.4603314,
        42.4735326,
        1.5483185,
        42.5228745
      ]
    },
    {
      "id": 809,
      "name": "Saint John's",
      "names": {
        "es": "San Juan",
        "fr": "Saint John",
        "zh": "圣约翰的",
        "it": "San Giovanni",
        "ja": "セントジョンズ",
        "th": "เซนต์จอห์น",
        "pt": "São João"
      },
      "countryCode": "AG",
      "latLng": {
        "lat": 17.1184569,
        "lng": -61.8448509
      },
      "country": "Antigua And Barbuda",
      "importance": 0,
      "bbox": [
        -61.8848509,
        17.0784569,
        -61.8048509,
        17.1584569
      ]
    },
    {
      "id": 810,
      "name": "Nuku`alofa",
      "names": {},
      "countryCode": "TO",
      "latLng": {
        "lat": -21.13936,
        "lng": -175.204636
      },
      "country": "Tonga",
      "importance": 0,
      "bbox": [
        -175.2054063,
        -21.1401472,
        -175.2039256,
        -21.1385881
      ]
    },
    {
      "id": 811,
      "name": "Ashgabat",
      "names": {
        "zh": "阿什哈巴德",
        "th": "อาชกาบัต"
      },
      "countryCode": "TM",
      "latLng": {
        "lat": 37.95,
        "lng": 58.38333
      },
      "country": "Turkmenistan",
      "importance": 0,
      "bbox": [
        57.902822,
        37.825885,
        58.496228,
        38.187951
      ]
    },
    {
      "id": 812,
      "name": "Nuuk",
      "names": {
        "zh": "努克",
        "ja": "ヌーク",
        "th": "นุก"
      },
      "countryCode": "GL",
      "latLng": {
        "lat": 64.1766835,
        "lng": -51.7359356
      },
      "country": "Greenland",
      "importance": 0,
      "bbox": [
        -51.8959356,
        64.0166835,
        -51.5759356,
        64.3366835
      ]
    },
    {
      "id": 813,
      "name": "Belmopan",
      "names": {
        "zh": "贝尔莫潘"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 17.25,
        "lng": -88.76667
      },
      "country": "Belize",
      "importance": 0,
      "bbox": [
        -88.790067,
        17.221606,
        -88.741343,
        17.276892
      ]
    },
    {
      "id": 814,
      "name": "Roseau",
      "names": {
        "zh": "罗索",
        "ja": "ロゾー",
        "th": "โรโซ"
      },
      "countryCode": "DM",
      "latLng": {
        "lat": 15.2991923,
        "lng": -61.3872868
      },
      "country": "Dominica",
      "importance": 0,
      "bbox": [
        -61.4272868,
        15.2591923,
        -61.3472868,
        15.3391923
      ]
    },
    {
      "id": 815,
      "name": "Basseterre",
      "names": {
        "zh": "巴斯特尔"
      },
      "countryCode": "KN",
      "latLng": {
        "lat": 17.29484,
        "lng": -62.7261
      },
      "country": "Saint Kitts And Nevis",
      "importance": 0,
      "bbox": [
        -62.742969,
        17.288569,
        -62.707089,
        17.318531
      ]
    },
    {
      "id": 816,
      "name": "Torshavn",
      "names": {
        "fr": "Tórshavn",
        "de": "Tórshavn",
        "id": "Tórshavn"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0079024,
        "lng": -6.7666734
      },
      "country": "Faroe Islands",
      "importance": 0,
      "bbox": [
        -6.7667234,
        62.0078524,
        -6.7666234,
        62.0079524
      ]
    },
    {
      "id": 817,
      "name": "Pago Pago",
      "names": {
        "id": "Pago",
        "ja": "パゴパゴ"
      },
      "countryCode": "AS",
      "latLng": {
        "lat": -14.2754786,
        "lng": -170.7048298
      },
      "country": "American Samoa",
      "importance": 0,
      "bbox": [
        -170.747496,
        -14.2973779,
        -170.6860365,
        -14.20498
      ]
    },
    {
      "id": 818,
      "name": "Valletta",
      "names": {
        "es": "La Valeta",
        "fr": "La Valette",
        "zh": "瓦莱塔",
        "ja": "バレッタ",
        "th": "วัลเลตตา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8989818,
        "lng": 14.5136759
      },
      "country": "Malta",
      "importance": 0,
      "bbox": [
        14.5055327,
        35.8931489,
        14.5206512,
        35.903575
      ]
    },
    {
      "id": 819,
      "name": "Gaza",
      "names": {
        "fr": "La bande de Gaza",
        "zh": "加沙",
        "ja": "ガザ",
        "th": "กาซา"
      },
      "countryCode": "XG",
      "latLng": {
        "lat": 31.5128679,
        "lng": 34.4581358
      },
      "country": "Gaza Strip",
      "importance": 0,
      "bbox": [
        34.3961515,
        31.4644954,
        34.5181367,
        31.5487199
      ]
    },
    {
      "id": 820,
      "name": "Grand Turk",
      "names": {
        "zh": "大土耳其人",
        "it": "Grande turco",
        "ja": "グランドトルコ"
      },
      "countryCode": "TC",
      "latLng": {
        "lat": 21.467964000000002,
        "lng": -71.13872175290959
      },
      "country": "Turks And Caicos Islands",
      "importance": 0,
      "bbox": [
        -71.153626,
        21.4224687,
        -71.1261025,
        21.5135006
      ]
    },
    {
      "id": 821,
      "name": "Palikir",
      "names": {
        "zh": "帕利基尔"
      },
      "countryCode": "FM",
      "latLng": {
        "lat": 6.920744,
        "lng": 158.1627143
      },
      "country": "Federated States of Micronesia",
      "importance": 0,
      "bbox": [
        158.1227143,
        6.880744,
        158.2027143,
        6.960744
      ]
    },
    {
      "id": 822,
      "name": "Funafuti",
      "names": {
        "zh": "富纳富提",
        "ja": "フナフティ",
        "th": "ฟูนะฟูตี"
      },
      "countryCode": "TV",
      "latLng": {
        "lat": -8.5245844,
        "lng": 179.1922807
      },
      "country": "Tuvalu",
      "importance": 0,
      "bbox": [
        179.1922307,
        -8.5246344,
        179.1923307,
        -8.5245344
      ]
    },
    {
      "id": 823,
      "name": "Vaduz",
      "names": {
        "zh": "瓦杜兹",
        "th": "วาดุซ"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1392862,
        "lng": 9.5227962
      },
      "country": "Liechtenstein",
      "importance": 0,
      "bbox": [
        9.4950763,
        47.0870567,
        9.6116778,
        47.1940393
      ]
    },
    {
      "id": 824,
      "name": "Lobamba",
      "names": {},
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.4462848,
        "lng": 31.2083781
      },
      "country": "Swaziland",
      "importance": 0,
      "bbox": [
        31.1683781,
        -26.4862848,
        31.2483781,
        -26.4062848
      ]
    },
    {
      "id": 825,
      "name": "Avarua",
      "names": {
        "zh": "阿瓦鲁阿"
      },
      "countryCode": "CK",
      "latLng": {
        "lat": -21.2074736,
        "lng": -159.7708145
      },
      "country": "Cook Islands",
      "importance": 0,
      "bbox": [
        -159.9639602,
        -21.2408551,
        -159.5911393,
        -20.9949899
      ]
    },
    {
      "id": 826,
      "name": "Saint George's",
      "names": {
        "zh": "圣乔治",
        "de": "Saint George",
        "ja": "セントジョージの",
        "th": "เซนต์จอร์จ",
        "pt": "São Jorge"
      },
      "countryCode": "GD",
      "latLng": {
        "lat": 12.0535331,
        "lng": -61.751805
      },
      "country": "Grenada",
      "importance": 0,
      "bbox": [
        -61.791805,
        12.0135331,
        -61.711805,
        12.0935331
      ]
    },
    {
      "id": 827,
      "name": "San Marino",
      "names": {
        "fr": "Saint marin",
        "zh": "圣马力诺",
        "ja": "サンマリノ",
        "th": "ซานมาริโน"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9363996,
        "lng": 12.4466991
      },
      "country": "San Marino",
      "importance": 0,
      "bbox": [
        12.4119606,
        43.9141317,
        12.4567428,
        43.9425882
      ]
    },
    {
      "id": 828,
      "name": "Al Quds",
      "names": {
        "fr": "Alternatif",
        "zh": "alquds",
        "de": "Al-Quds",
        "it": "Albbes",
        "id": "Quds",
        "ja": "アルquds",
        "pt": "Quds al"
      },
      "countryCode": "XW",
      "latLng": {
        "lat": 32.5270289,
        "lng": 35.156628
      },
      "country": "West Bank",
      "importance": 0,
      "bbox": [
        35.1552547,
        32.5240425,
        35.1596863,
        32.5297336
      ]
    },
    {
      "id": 829,
      "name": "Capitol Hill",
      "names": {
        "es": "Capitolio",
        "zh": "国会山",
        "id": "Bukit Capitol",
        "ja": "キャピトルヒル",
        "th": "ศาลากลางเนินเขา",
        "pt": "Capitólio"
      },
      "countryCode": "MP",
      "latLng": {
        "lat": 15.2106609,
        "lng": 145.7524444
      },
      "country": "Northern Mariana Islands",
      "importance": 0,
      "bbox": [
        145.7324444,
        15.1906609,
        145.7724444,
        15.2306609
      ]
    },
    {
      "id": 830,
      "name": "Stanley",
      "names": {
        "zh": "斯坦利",
        "ja": "スタンレー",
        "th": "สแตนลีย์"
      },
      "countryCode": "FK",
      "latLng": {
        "lat": -51.6930616,
        "lng": -57.856525
      },
      "country": "Falkland Islands (Islas Malvinas)",
      "importance": 0,
      "bbox": [
        -57.9134847,
        -51.7018744,
        -57.7913046,
        -51.6861263
      ]
    },
    {
      "id": 831,
      "name": "Vatican City",
      "names": {
        "es": "Ciudad del Vaticano",
        "fr": "Cité du Vatican",
        "zh": "梵蒂冈城",
        "de": "Vatikanische Stadt",
        "it": "Città del Vaticano",
        "id": "Kota Vatikan",
        "ja": "バチカン市",
        "th": "เมืองวาติกัน",
        "pt": "Cidade do vaticano"
      },
      "countryCode": "VA",
      "latLng": {
        "lat": 41.903411,
        "lng": 12.4528527
      },
      "country": "Vatican City",
      "importance": 0,
      "bbox": [
        12.4457878,
        41.9002044,
        12.4583653,
        41.9073829
      ]
    },
    {
      "id": 832,
      "name": "Alofi",
      "names": {
        "zh": "阿洛菲"
      },
      "countryCode": "NU",
      "latLng": {
        "lat": -19.0534159,
        "lng": -169.919199
      },
      "country": "Niue",
      "importance": 0,
      "bbox": [
        -169.9496922,
        -19.0900617,
        -169.8418587,
        -19.016051
      ]
    },
    {
      "id": 833,
      "name": "Basse-Terre",
      "names": {
        "zh": "巴斯 - 大地",
        "ja": "バース・テッレ",
        "th": "บาส-แตร์"
      },
      "countryCode": "GP",
      "latLng": {
        "lat": 16.0000778,
        "lng": -61.7333373
      },
      "country": "Guadeloupe",
      "importance": 0,
      "bbox": [
        -61.7461623,
        15.9855984,
        -61.7124504,
        16.0126467
      ]
    },
    {
      "id": 834,
      "name": "Hagta",
      "names": {},
      "countryCode": "GU",
      "latLng": {
        "lat": 13.4745,
        "lng": 144.7504
      },
      "country": "Guam",
      "importance": 0
    },
    {
      "id": 835,
      "name": "Marigot",
      "names": {
        "zh": "马里戈特"
      },
      "countryCode": "MF",
      "latLng": {
        "lat": 18.0668544,
        "lng": -63.0848869
      },
      "country": "Saint Martin",
      "importance": 0,
      "bbox": [
        -63.1248869,
        18.0268544,
        -63.0448869,
        18.1068544
      ]
    },
    {
      "id": 836,
      "name": "Jamestown",
      "names": {
        "zh": "詹姆斯敦",
        "ja": "ジェームズタウン",
        "th": "เจมส์ทาวน์"
      },
      "countryCode": "SH",
      "latLng": {
        "lat": -15.9277296,
        "lng": -5.7160872
      },
      "country": "Saint Helena, Ascension, And Tristan Da Cunha",
      "importance": 0,
      "bbox": [
        -5.7191582,
        -15.9345064,
        -5.7104649,
        -15.9228553
      ]
    },
    {
      "id": 837,
      "name": "Brades",
      "names": {},
      "countryCode": "MS",
      "latLng": {
        "lat": 16.79183,
        "lng": -62.21058
      },
      "country": "Montserrat",
      "importance": 0,
      "bbox": [
        -62.23058,
        16.77183,
        -62.19058,
        16.81183
      ]
    },
    {
      "id": 838,
      "name": "Philipsburg",
      "names": {
        "zh": "菲利普斯堡",
        "th": "ฟิลิปส์เบิร์ก"
      },
      "countryCode": "SX",
      "latLng": {
        "lat": 18.0250713,
        "lng": -63.0483073
      },
      "country": "Sint Maarten",
      "importance": 0,
      "bbox": [
        -63.0883073,
        17.9850713,
        -63.0083073,
        18.0650713
      ]
    },
    {
      "id": 839,
      "name": "Yaren",
      "names": {},
      "countryCode": "NR",
      "latLng": {
        "lat": -0.5469030500000001,
        "lng": 166.92235277906863
      },
      "country": "Nauru",
      "importance": 0,
      "bbox": [
        166.9143629,
        -0.5541334,
        166.9298206,
        -0.5395176
      ]
    },
    {
      "id": 840,
      "name": "Pristina",
      "names": {
        "zh": "普里什蒂纳",
        "ja": "プリシュティナ"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6638771,
        "lng": 21.1640849
      },
      "country": "Kosovo",
      "importance": 0,
      "bbox": [
        21.1066198,
        42.6290946,
        21.2136267,
        42.696965
      ]
    },
    {
      "id": 841,
      "name": "Gustavia",
      "names": {},
      "countryCode": "BL",
      "latLng": {
        "lat": 17.8957043,
        "lng": -62.8508372
      },
      "country": "Saint Barthelemy",
      "importance": 0,
      "bbox": [
        -62.8908372,
        17.8557043,
        -62.8108372,
        17.9357043
      ]
    },
    {
      "id": 842,
      "name": "Road Town",
      "names": {
        "es": "Ciudad de carretera",
        "fr": "Ville routière",
        "zh": "公路镇",
        "de": "Straßenstadt",
        "it": "Città stradale",
        "id": "Kota jalan",
        "ja": "ロードタウン",
        "th": "เมืองถนน",
        "pt": "Cidade rodoviária"
      },
      "countryCode": "VG",
      "latLng": {
        "lat": 18.41667,
        "lng": -64.61667
      },
      "country": "British Virgin Islands",
      "importance": 0,
      "bbox": [
        -64.63667,
        18.39667,
        -64.59667,
        18.43667
      ]
    },
    {
      "id": 843,
      "name": "Ngerulmud",
      "names": {},
      "countryCode": "PW",
      "latLng": {
        "lat": 7.5006446,
        "lng": 134.6242864
      },
      "country": "Palau",
      "importance": 0,
      "bbox": [
        134.6042864,
        7.4806446,
        134.6442864,
        7.5206446
      ]
    },
    {
      "id": 844,
      "name": "Saint-Pierre",
      "names": {
        "zh": "圣皮埃尔",
        "ja": "サンピエール",
        "th": "เซนต์ปิแอร์"
      },
      "countryCode": "PM",
      "latLng": {
        "lat": 48.9454667,
        "lng": 4.2446454
      },
      "country": "Saint Pierre And Miquelon",
      "importance": 0,
      "bbox": [
        4.2177263,
        48.9145943,
        4.2756175,
        48.9636106
      ]
    },
    {
      "id": 845,
      "name": "The Valley",
      "names": {
        "es": "El valle",
        "fr": "La vallée",
        "zh": "山谷",
        "de": "Das Tal",
        "it": "La valle",
        "id": "Lembah",
        "ja": "谷",
        "th": "หุบเขา",
        "pt": "O vale"
      },
      "countryCode": "AI",
      "latLng": {
        "lat": 18.2145861,
        "lng": -63.0517759
      },
      "country": "Anguilla",
      "importance": 0,
      "bbox": [
        -63.0917759,
        18.1745861,
        -63.0117759,
        18.2545861
      ]
    },
    {
      "id": 846,
      "name": "Mata-Utu",
      "names": {
        "zh": "马塔-乌图",
        "ja": "マタ-utu",
        "th": "มาต้า-utu"
      },
      "countryCode": "WF",
      "latLng": {
        "lat": -13.2820419,
        "lng": -176.1740224
      },
      "country": "Wallis And Futuna",
      "importance": 0,
      "bbox": [
        -176.2140224,
        -13.3220419,
        -176.1340224,
        -13.2420419
      ]
    },
    {
      "id": 847,
      "name": "Kingston",
      "names": {
        "zh": "金士顿",
        "ja": "キングストン",
        "th": "คิงส์ตัน"
      },
      "countryCode": "NF",
      "latLng": {
        "lat": 17.9712148,
        "lng": -76.7928128
      },
      "country": "Norfolk Island",
      "importance": 0,
      "bbox": [
        -76.9528128,
        17.8112148,
        -76.6328128,
        18.1312148
      ]
    },
    {
      "id": 848,
      "name": "Longyearbyen",
      "names": {
        "zh": "朗伊尔城"
      },
      "countryCode": "XR",
      "latLng": {
        "lat": 78.2186,
        "lng": 15.64007
      },
      "country": "Svalbard",
      "importance": 0,
      "bbox": [
        15.62007,
        78.1986,
        15.66007,
        78.2386
      ]
    },
    {
      "id": 849,
      "name": "Tifariti",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 26.150053,
        "lng": -10.549962
      },
      "country": "Morocco",
      "importance": 0
    },
    {
      "id": 850,
      "name": "Adamstown",
      "names": {
        "zh": "亚当斯敦"
      },
      "countryCode": "PN",
      "latLng": {
        "lat": -25.066667,
        "lng": -130.100205
      },
      "country": "Pitcairn Islands",
      "importance": 0,
      "bbox": [
        -130.120205,
        -25.086667,
        -130.080205,
        -25.046667
      ]
    },
    {
      "id": 851,
      "name": "Flying Fish Cove",
      "names": {
        "es": "Pescado volador",
        "fr": "Voling Fish Cove",
        "zh": "飞鱼湾",
        "de": "Fliegende Fischbucht",
        "it": "Baia di pesce volante",
        "id": "Flight Fish Cove",
        "ja": "フライングフィッシュコーブ",
        "pt": "Angra de peixe voador"
      },
      "countryCode": "CX",
      "latLng": {
        "lat": -10.42172,
        "lng": 105.67912
      },
      "country": "Christmas Island",
      "importance": 0,
      "bbox": [
        105.65912,
        -10.44172,
        105.69912,
        -10.40172
      ]
    },
    {
      "id": 852,
      "name": "Bareilly",
      "names": {
        "zh": "巴瑞利"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.355099,
        "lng": 79.417851
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.257851,
        28.195099,
        79.577851,
        28.515099
      ]
    },
    {
      "id": 853,
      "name": "Quang Ha",
      "names": {
        "de": "Quadha",
        "ja": "Quangha"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.5394728,
        "lng": 107.8713158
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        107.8513158,
        12.5194728,
        107.8913158,
        12.5594728
      ]
    },
    {
      "id": 854,
      "name": "Xingcheng",
      "names": {
        "zh": "兴城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7122214,
        "lng": 116.0551386
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.0501386,
        39.7072214,
        116.0601386,
        39.7172214
      ]
    },
    {
      "id": 855,
      "name": "Dongtai",
      "names": {
        "zh": "东泰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.853236,
        "lng": 120.299593
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.259593,
        32.813236,
        120.339593,
        32.893236
      ]
    },
    {
      "id": 856,
      "name": "Al Mansurah",
      "names": {
        "ja": "アルマンサーラ",
        "th": "อัลแมนซุราห์"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.037566,
        "lng": 31.38649
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.22649,
        30.877566,
        31.54649,
        31.197566
      ]
    },
    {
      "id": 857,
      "name": "Yingcheng",
      "names": {
        "zh": "英成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.1858859,
        "lng": 113.3978462
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.3578462,
        24.1458859,
        113.4378462,
        24.2258859
      ]
    },
    {
      "id": 858,
      "name": "Chiba",
      "names": {
        "zh": "千叶",
        "ja": "千葉",
        "th": "ชิบะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6121041,
        "lng": 140.1141578
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.1091578,
        35.6071041,
        140.1191578,
        35.6171041
      ]
    },
    {
      "id": 859,
      "name": "Pekanbaru",
      "names": {
        "zh": "北干巴鲁"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.5262455,
        "lng": 101.4515727
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        101.3240039,
        0.416975,
        101.6054021,
        0.690923
      ]
    },
    {
      "id": 860,
      "name": "Luocheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7809847,
        "lng": 108.901036
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.741036,
        24.6209847,
        109.061036,
        24.9409847
      ]
    },
    {
      "id": 861,
      "name": "Dnipro",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4680221,
        "lng": 35.0417711
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        34.7489459,
        48.3558547,
        35.2421952,
        48.5763901
      ]
    },
    {
      "id": 862,
      "name": "Danyang",
      "names": {
        "zh": "丹阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.0037679,
        "lng": 119.5878609
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.5828609,
        31.9987679,
        119.5928609,
        32.0087679
      ]
    },
    {
      "id": 863,
      "name": "Natal",
      "names": {
        "zh": "纳塔尔",
        "ja": "ナタール",
        "th": "นาตาล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.060415600000001,
        "lng": -41.87421791866078
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.9058408,
        -5.0965565,
        -41.8521613,
        -5.0246434
      ]
    },
    {
      "id": 864,
      "name": "Nada",
      "names": {
        "zh": "纳达",
        "ja": "灘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 19.5167948,
        "lng": 109.5424275
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.5024275,
        19.4767948,
        109.5824275,
        19.5567948
      ]
    },
    {
      "id": 865,
      "name": "Zamboanga City",
      "names": {
        "es": "Ciudad de Zamboanga",
        "fr": "Zambobana City",
        "zh": "Zamboanga市",
        "de": "Zamboanga-Stadt",
        "id": "Kota Zamboanga",
        "ja": "ザンボンガ市",
        "th": "เมือง Zamboanga",
        "pt": "Cidade de zamboanga"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9046909,
        "lng": 122.076462
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.7597044,
        6.7972779,
        122.5739132,
        7.468906
      ]
    },
    {
      "id": 866,
      "name": "Kirkuk",
      "names": {
        "fr": "Kirkouk",
        "zh": "基尔库克"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 35.4719308,
        "lng": 44.3953896
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        44.2353896,
        35.3119308,
        44.5553896,
        35.6319308
      ]
    },
    {
      "id": 867,
      "name": "Naples",
      "names": {
        "es": "Nápoles",
        "zh": "那不勒斯",
        "de": "Neapel",
        "it": "Napoli",
        "ja": "ナポリ",
        "th": "เนเปิลส์",
        "pt": "Nápoles"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8358846,
        "lng": 14.2487679
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        14.1320999,
        40.791193,
        14.3537681,
        40.916123
      ]
    },
    {
      "id": 868,
      "name": "Wuchuan",
      "names": {
        "zh": "武川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.4442923,
        "lng": 110.7736603
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.6136603,
        21.2842923,
        110.9336603,
        21.6042923
      ]
    },
    {
      "id": 869,
      "name": "Huilong",
      "names": {
        "zh": "回龙"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0220786,
        "lng": 121.4119025
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.4069025,
        25.0170786,
        121.4169025,
        25.0270786
      ]
    },
    {
      "id": 870,
      "name": "Oklahoma City",
      "names": {
        "zh": "俄克拉荷马城",
        "id": "Kota Oklahoma",
        "ja": "オクラホマシティ",
        "th": "โอคลาโฮมาซิตี้",
        "pt": "Cidade de oklahoma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4653376,
        "lng": -97.5128162
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -97.5178162,
        35.4603376,
        -97.5078162,
        35.4703376
      ]
    },
    {
      "id": 871,
      "name": "Morelia",
      "names": {
        "zh": "莫雷利亚",
        "th": "มอเรเลีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7027116,
        "lng": -101.1923818
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -101.3523818,
        19.5427116,
        -101.0323818,
        19.8627116
      ]
    },
    {
      "id": 872,
      "name": "Cebu City",
      "names": {
        "es": "Ciudad de Cebu",
        "fr": "City de Cebu",
        "zh": "宿务市",
        "de": "Cebu-Stadt",
        "it": "Città di Cebu",
        "id": "Kota Cebu",
        "ja": "セブシティ",
        "th": "เมืองเซบู",
        "pt": "Cidade de cebu"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2935639,
        "lng": 123.9019209
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.7533688,
        10.2463015,
        123.9302169,
        10.4957531
      ]
    },
    {
      "id": 873,
      "name": "Shaoxing",
      "names": {
        "zh": "绍兴",
        "ja": "紹興"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.9992425,
        "lng": 120.576854
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.8841866,
        29.22651,
        121.2271356,
        30.2994384
      ]
    },
    {
      "id": 874,
      "name": "Coimbatore",
      "names": {
        "zh": "哥印拜陀",
        "ja": "コインバトール",
        "th": "โคอิมบาโตร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.0018115,
        "lng": 76.9628425
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.8028425,
        10.8418115,
        77.1228425,
        11.1618115
      ]
    },
    {
      "id": 875,
      "name": "Santo Domingo Este",
      "names": {
        "fr": "Este de Santo Domingo",
        "ja": "サントドミンゴエステ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4936515,
        "lng": -69.8383703
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -69.8979393,
        18.4546796,
        -69.7026103,
        18.598232
      ]
    },
    {
      "id": 876,
      "name": "Setagaya",
      "names": {
        "zh": "世田谷",
        "ja": "世田谷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.646096,
        "lng": 139.65627
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.582386,
        35.589977,
        139.6866146,
        35.6830054
      ]
    },
    {
      "id": 877,
      "name": "Songnam",
      "names": {
        "zh": "松南"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4201556,
        "lng": 127.1262092
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.0270715,
        37.3333872,
        127.1959614,
        37.4748116
      ]
    },
    {
      "id": 878,
      "name": "Taishan",
      "names": {
        "zh": "泰山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.2541202,
        "lng": 112.7884998
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.6284998,
        22.0941202,
        112.9484998,
        22.4141202
      ]
    },
    {
      "id": 879,
      "name": "Fangchenggang",
      "names": {
        "zh": "防城港"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.621698,
        "lng": 108.352446
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.145262783,
        21.5104929793,
        108.362313359,
        21.6307112706
      ]
    },
    {
      "id": 880,
      "name": "Solapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.6699734,
        "lng": 75.9008118
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.7408118,
        17.5099734,
        76.0608118,
        17.8299734
      ]
    },
    {
      "id": 881,
      "name": "Tangier",
      "names": {
        "es": "Tánger",
        "fr": "Tanger",
        "zh": "丹吉尔",
        "de": "Tanger",
        "it": "Tangeri",
        "ja": "タンジール",
        "th": "แทนเจียร์",
        "pt": "Tânger"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.7696302,
        "lng": -5.8033522
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -5.9484121,
        35.7078797,
        -5.7347596,
        35.8205106
      ]
    },
    {
      "id": 882,
      "name": "Anqiu",
      "names": {
        "zh": "安丘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4794705,
        "lng": 119.2129818
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.7449033,
        36.0843795,
        119.4479562,
        36.5556031
      ]
    },
    {
      "id": 883,
      "name": "Feicheng",
      "names": {
        "zh": "肥城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1841443,
        "lng": 116.7653466
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.4644275,
        35.8883165,
        116.9882213,
        36.3128009
      ]
    },
    {
      "id": 884,
      "name": "Kermanshah",
      "names": {
        "zh": "克尔曼沙阿"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.32394,
        "lng": 47.069952
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        46.996183,
        34.277729,
        47.2108,
        34.428235
      ]
    },
    {
      "id": 885,
      "name": "Kitakyushu",
      "names": {
        "zh": "北九州市",
        "ja": "北九州",
        "th": "คิตะกีวซู"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.8829996,
        "lng": 130.8749015
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4809477,
        33.720997,
        131.1419318,
        34.1688379
      ]
    },
    {
      "id": 886,
      "name": "Meishan",
      "names": {
        "zh": "梅山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.6799437,
        "lng": 115.8761679
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.8361679,
        31.6399437,
        115.9161679,
        31.7199437
      ]
    },
    {
      "id": 887,
      "name": "Khartoum North",
      "names": {
        "es": "Jartum North",
        "fr": "Khartoum nord",
        "zh": "喀土穆北",
        "ja": "カルタムノース",
        "th": "คาร์ทูมเหนือ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.62889,
        "lng": 32.62583
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.60583,
        15.60889,
        32.64583,
        15.64889
      ]
    },
    {
      "id": 888,
      "name": "Kisangani",
      "names": {
        "zh": "基桑加尼"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.51528,
        "lng": 25.19099
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        25.17099,
        0.49528,
        25.21099,
        0.53528
      ]
    },
    {
      "id": 889,
      "name": "Aguascalientes",
      "names": {
        "zh": "阿瓜斯卡连特斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.880487,
        "lng": -102.2967195
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -102.4567195,
        21.720487,
        -102.1367195,
        22.040487
      ]
    },
    {
      "id": 890,
      "name": "Marrakech",
      "names": {
        "zh": "马拉喀什",
        "de": "Marrakesch",
        "id": "Marakesh",
        "ja": "マラケシュ",
        "th": "มาร์ราเกช"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 31.6258257,
        "lng": -7.9891608
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -8.0893315,
        31.5441986,
        -7.8847385,
        31.7104265
      ]
    },
    {
      "id": 891,
      "name": "Donetsk",
      "names": {
        "zh": "顿涅茨克",
        "ja": "ドネツク",
        "th": "โดเนตสค์"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0158753,
        "lng": 37.8013407
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        37.5254345,
        47.8757157,
        37.9987906,
        48.0895015
      ]
    },
    {
      "id": 892,
      "name": "Trujillo",
      "names": {
        "zh": "特鲁希略",
        "ja": "トルヒーヨ",
        "th": "ตรูฮีโย"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -8.1116778,
        "lng": -79.0287742
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -79.0649941,
        -8.1509852,
        -78.9827741,
        -8.0831602
      ]
    },
    {
      "id": 893,
      "name": "Taihe",
      "names": {
        "zh": "泰和"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.1673007,
        "lng": 115.6180556
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.4580556,
        33.0073007,
        115.7780556,
        33.3273007
      ]
    },
    {
      "id": 894,
      "name": "Bridgeport",
      "names": {
        "zh": "布里奇波特",
        "ja": "ブリッジポート",
        "th": "บริดจ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1792695,
        "lng": -73.1887863
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.2440717,
        41.1399013,
        -73.1537198,
        41.2296213
      ]
    },
    {
      "id": 895,
      "name": "Trichinopoly",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.804973,
        "lng": 78.6870296
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.6461234,
        10.7273005,
        78.7426615,
        10.8457612
      ]
    },
    {
      "id": 896,
      "name": "Xin'an",
      "names": {
        "zh": "新安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7274992,
        "lng": 112.1259282
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.9659282,
        34.5674992,
        112.2859282,
        34.8874992
      ]
    },
    {
      "id": 897,
      "name": "Taihecun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7955521,
        "lng": 119.011095
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.991095,
        34.7755521,
        119.031095,
        34.8155521
      ]
    },
    {
      "id": 898,
      "name": "Naucalpan de Juarez",
      "names": {
        "es": "Naucalpan de Juárez",
        "it": "Naucadalpan de juarez",
        "th": "คนไม่มีชัย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4788356,
        "lng": -99.2327986
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.414074,
        19.4120073,
        -99.206733,
        19.5366944
      ]
    },
    {
      "id": 899,
      "name": "Owerri",
      "names": {
        "zh": "奥韦里"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.478455,
        "lng": 7.031681
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.951253,
        5.414341,
        7.114082,
        5.558976
      ]
    },
    {
      "id": 900,
      "name": "Padang",
      "names": {
        "zh": "巴东",
        "ja": "パダン",
        "th": "ปาดัง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.9417775,
        "lng": 100.3738241
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        100.3688241,
        -0.9467775,
        100.3788241,
        -0.9367775
      ]
    },
    {
      "id": 901,
      "name": "Qingzhou",
      "names": {
        "zh": "青州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6843271,
        "lng": 118.4738118
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.1702757,
        36.4076581,
        118.778075,
        36.9564807
      ]
    },
    {
      "id": 902,
      "name": "Buffalo",
      "names": {
        "fr": "Buffle",
        "zh": "布法罗",
        "de": "Büffel",
        "ja": "バッファロー",
        "th": "ควาย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.8867166,
        "lng": -78.8783922
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.9223535,
        42.826006,
        -78.795121,
        42.966449
      ]
    },
    {
      "id": 903,
      "name": "Lichuan",
      "names": {
        "zh": "利川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.2937431,
        "lng": 108.9322926
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.7722926,
        30.1337431,
        109.0922926,
        30.4537431
      ]
    },
    {
      "id": 904,
      "name": "Daye",
      "names": {
        "zh": "大冶"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0985567,
        "lng": 114.9748074
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.5262242,
        29.8537641,
        115.1775461,
        30.3282643
      ]
    },
    {
      "id": 905,
      "name": "Fort Worth",
      "names": {
        "fr": "Fort",
        "zh": "沃斯堡",
        "it": "Forte",
        "id": "Nilainya",
        "ja": "フォートワース",
        "th": "ป้อมปราการ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.753177,
        "lng": -97.3327459
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.592388,
        32.551584,
        -97.0335808,
        33.049529
      ]
    },
    {
      "id": 906,
      "name": "Hengzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6833211,
        "lng": 109.2575901
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.0975901,
        22.5233211,
        109.4175901,
        22.8433211
      ]
    },
    {
      "id": 907,
      "name": "Campo Grande",
      "names": {
        "ja": "カンポグランデ",
        "pt": "Campó"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4640173,
        "lng": -54.6162947
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -54.9252802,
        -21.5845441,
        -53.5997434,
        -20.1659663
      ]
    },
    {
      "id": 908,
      "name": "Zhuanghe",
      "names": {
        "zh": "庄河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6972967,
        "lng": 122.9708389
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        122.4875509,
        39.4259028,
        123.5152509,
        40.2039986
      ]
    },
    {
      "id": 909,
      "name": "Hartford",
      "names": {
        "zh": "哈特福德",
        "ja": "ハートフォード",
        "th": "ฮาร์ตฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.764582,
        "lng": -72.6908547
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -72.7180372,
        41.7236703,
        -72.6422873,
        41.8074191
      ]
    },
    {
      "id": 910,
      "name": "Bobo-Dioulasso",
      "names": {
        "es": "Bobo dioulasso-",
        "zh": "波波-迪乌拉索",
        "it": "-Bobo dioulasso",
        "ja": "ボボ-dioulasso"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.1765796,
        "lng": -4.2961436
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -4.4561436,
        11.0165796,
        -4.1361436,
        11.3365796
      ]
    },
    {
      "id": 911,
      "name": "Ad Dammam",
      "names": {
        "es": "Dammam ad",
        "de": "Ad-Dammam",
        "it": "Dammam ad",
        "ja": "広告ダンマム",
        "th": "โฆษณา Dammam"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 26.4367824,
        "lng": 50.1039991
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        49.9439991,
        26.2767824,
        50.2639991,
        26.5967824
      ]
    },
    {
      "id": 912,
      "name": "Lhasa",
      "names": {
        "fr": "Lhassa",
        "zh": "拉萨",
        "ja": "ラサ市",
        "th": "ลาซา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.648713,
        "lng": 91.13119
      },
      "country": "China",
      "importance": 1,
      "bbox": [
        90.753255,
        29.28217,
        91.663784,
        29.778168
      ]
    },
    {
      "id": 913,
      "name": "Jiaozhou",
      "names": {
        "zh": "胶州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.24806,
        "lng": 119.9625
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.8625,
        36.14806,
        120.0625,
        36.34806
      ]
    },
    {
      "id": 914,
      "name": "Taguig City",
      "names": {
        "zh": "Taguig城市",
        "de": "Taguig-Stadt",
        "it": "Città taguig",
        "id": "Kota Taguig",
        "ja": "タグヒの市",
        "th": "เมือง Taguig",
        "pt": "Cidade taguig"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5290381,
        "lng": 121.0701881
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0700125,
        14.5290312,
        121.0704638,
        14.529487
      ]
    },
    {
      "id": 915,
      "name": "Merida",
      "names": {
        "es": "Mérida",
        "zh": "梅里达",
        "ja": "メリダ",
        "th": "เมริดา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9670759,
        "lng": -89.6237402
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -89.7396666,
        20.8879521,
        -89.5080983,
        21.0920453
      ]
    },
    {
      "id": 916,
      "name": "Yangchun",
      "names": {
        "zh": "阳春"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.1732425,
        "lng": 111.7863769
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.6263769,
        22.0132425,
        111.9463769,
        22.3332425
      ]
    },
    {
      "id": 917,
      "name": "Bucheon",
      "names": {
        "zh": "富川"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.499314,
        "lng": 126.775761
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        126.738281,
        37.452771,
        126.831523,
        37.540517
      ]
    },
    {
      "id": 918,
      "name": "Dengtalu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0819,
        "lng": 114.3481
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 919,
      "name": "Antipolo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5871972,
        "lng": 121.1759246
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0159246,
        14.4271972,
        121.3359246,
        14.7471972
      ]
    },
    {
      "id": 920,
      "name": "Hubli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.348596,
        "lng": 75.130182
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.051821,
        15.316824,
        75.175097,
        15.384158
      ]
    },
    {
      "id": 921,
      "name": "Abeokuta",
      "names": {
        "zh": "阿贝奥库塔"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.161,
        "lng": 3.348
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        3.188,
        7.001,
        3.508,
        7.321
      ]
    },
    {
      "id": 922,
      "name": "Cancun",
      "names": {
        "es": "Cancún",
        "zh": "坎昆",
        "ja": "カンクン",
        "th": "แคนคูน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1527467,
        "lng": -86.8425761
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -86.9956171,
        20.9705855,
        -86.7405259,
        21.2123751
      ]
    },
    {
      "id": 923,
      "name": "Tucson",
      "names": {
        "zh": "图森",
        "ja": "ツーソン",
        "th": "ทูซอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.2231264,
        "lng": -110.9667086
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -110.9717086,
        32.2181264,
        -110.9617086,
        32.2281264
      ]
    },
    {
      "id": 924,
      "name": "Krasnodar",
      "names": {
        "zh": "克拉斯诺达尔",
        "th": "ครัสโนดาร์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.0351532,
        "lng": 38.9772396
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        38.7710935,
        44.9679583,
        39.1863188,
        45.1540056
      ]
    },
    {
      "id": 925,
      "name": "Natal",
      "names": {
        "zh": "纳塔尔",
        "ja": "ナタール",
        "th": "นาตาล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.805398,
        "lng": -35.2080905
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -35.2912366,
        -5.9002112,
        -35.1531034,
        -5.7027379
      ]
    },
    {
      "id": 926,
      "name": "Chihuahua",
      "names": {
        "zh": "奇瓦瓦",
        "ja": "チワワ",
        "th": "ชิวาวา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.6368669,
        "lng": -106.076745
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -106.236745,
        28.4768669,
        -105.916745,
        28.7968669
      ]
    },
    {
      "id": 927,
      "name": "Klang",
      "names": {
        "zh": "巴生",
        "th": "กลาง"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.0430915499999998,
        "lng": 101.44967658184223
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        101.4493672,
        3.0429343,
        101.4499684,
        3.0432521
      ]
    },
    {
      "id": 928,
      "name": "Turin",
      "names": {
        "es": "Turín",
        "zh": "都灵",
        "it": "Torino",
        "ja": "トリノ",
        "th": "ตูริน",
        "pt": "Turim"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0677551,
        "lng": 7.6824892
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        7.5778348,
        45.0067924,
        7.7733388,
        45.1402175
      ]
    },
    {
      "id": 929,
      "name": "Ar Ramadi",
      "names": {
        "fr": "Aramadi",
        "zh": "arramadi"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.000774,
        "lng": 41.7499877
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        40.7499877,
        32.000774,
        42.7499877,
        34.000774
      ]
    },
    {
      "id": 930,
      "name": "Laiyang",
      "names": {
        "zh": "莱阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9786591,
        "lng": 120.7068644
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.5197664,
        36.5738709,
        120.9873085,
        37.1620951
      ]
    },
    {
      "id": 931,
      "name": "Sale",
      "names": {
        "es": "Venta",
        "fr": "Vente",
        "zh": "销售",
        "de": "Verkauf",
        "it": "Vendita",
        "id": "Dijual",
        "ja": "販売",
        "th": "ขาย",
        "pt": "Venda"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.044889,
        "lng": -6.814017
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.8384753,
        33.9341773,
        -6.6907424,
        34.0974873
      ]
    },
    {
      "id": 932,
      "name": "Jalandhar",
      "names": {
        "zh": "贾朗达尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.323165,
        "lng": 75.584922
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.475957,
        31.251349,
        75.666626,
        31.396372
      ]
    },
    {
      "id": 933,
      "name": "Kaifeng Chengguanzhen",
      "names": {
        "zh": "开封成侯镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.53667,
        "lng": 114.55139
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.53139,
        35.51667,
        114.57139,
        35.55667
      ]
    },
    {
      "id": 934,
      "name": "Ikeja",
      "names": {
        "zh": "伊凯贾"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.5960605,
        "lng": 3.340787
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        3.3101247,
        6.5509516,
        3.3831906,
        6.6587736
      ]
    },
    {
      "id": 935,
      "name": "Malang",
      "names": {
        "zh": "玛琅",
        "ja": "マラン"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.9771308,
        "lng": 112.6340265
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        112.5698776,
        -8.0502632,
        112.6947173,
        -7.9108818
      ]
    },
    {
      "id": 936,
      "name": "Marseille",
      "names": {
        "es": "Marsella",
        "zh": "马赛",
        "it": "Marsiglia",
        "ja": "マルセイユ",
        "th": "มาร์เซย์",
        "pt": "Marselha"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.2961743,
        "lng": 5.3699525
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        5.2286312,
        43.1696228,
        5.5324758,
        43.3910329
      ]
    },
    {
      "id": 937,
      "name": "Saltillo",
      "names": {
        "zh": "萨尔蒂约",
        "ja": "サルティヨ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4230425,
        "lng": -100.9927509
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -101.1527509,
        25.2630425,
        -100.8327509,
        25.5830425
      ]
    },
    {
      "id": 938,
      "name": "Gaomi",
      "names": {
        "zh": "高密市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.38333,
        "lng": 119.75278
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.73278,
        36.36333,
        119.77278,
        36.40333
      ]
    },
    {
      "id": 939,
      "name": "Ipoh",
      "names": {
        "zh": "怡保",
        "ja": "イポー",
        "th": "อีโปห์"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.5975474,
        "lng": 101.0730766
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        101.0730266,
        4.5974974,
        101.0731266,
        4.5975974
      ]
    },
    {
      "id": 940,
      "name": "Hai'an",
      "names": {
        "zh": "海安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.5348763,
        "lng": 120.4629239
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.1972423,
        32.3596407,
        121.0429495,
        32.7146663
      ]
    },
    {
      "id": 941,
      "name": "Oran",
      "names": {
        "zh": "奥兰"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7044415,
        "lng": -0.6502981
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -0.7296348,
        35.6452843,
        -0.5513975,
        35.7695075
      ]
    },
    {
      "id": 942,
      "name": "Hermosillo",
      "names": {
        "zh": "埃莫西约",
        "ja": "エルモシヨ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.0948207,
        "lng": -110.9692202
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -111.1292202,
        28.9348207,
        -110.8092202,
        29.2548207
      ]
    },
    {
      "id": 943,
      "name": "Weichanglu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.077093,
        "lng": 119.932251
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.921015324,
        37.0680905789,
        119.943486476,
        37.0860955347
      ]
    },
    {
      "id": 944,
      "name": "Shache",
      "names": {
        "zh": "莎车"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4155,
        "lng": 77.248134
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        77.148134,
        38.3155,
        77.348134,
        38.5155
      ]
    },
    {
      "id": 945,
      "name": "Leping",
      "names": {
        "zh": "乐平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9800529,
        "lng": 117.1482986
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.9882986,
        28.8200529,
        117.3082986,
        29.1400529
      ]
    },
    {
      "id": 946,
      "name": "Hailun",
      "names": {
        "zh": "海伦"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.4583585,
        "lng": 126.9572246
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        126.7972246,
        47.2983585,
        127.1172246,
        47.6183585
      ]
    },
    {
      "id": 947,
      "name": "Macheng",
      "names": {
        "zh": "麻城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1708218,
        "lng": 115.0159244
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.8559244,
        31.0108218,
        115.1759244,
        31.3308218
      ]
    },
    {
      "id": 948,
      "name": "Akure",
      "names": {
        "zh": "阿库雷"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.2525595,
        "lng": 5.1932647
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        5.0332647,
        7.0925595,
        5.3532647,
        7.4125595
      ]
    },
    {
      "id": 949,
      "name": "Ilorin",
      "names": {
        "zh": "伊洛林"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.4963657,
        "lng": 4.5480484
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        4.3880484,
        8.3363657,
        4.7080484,
        8.6563657
      ]
    },
    {
      "id": 950,
      "name": "Teresina",
      "names": {
        "zh": "特雷西纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.0874608,
        "lng": -42.8049571
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -42.9706437,
        -5.586598,
        -42.599,
        -4.787
      ]
    },
    {
      "id": 951,
      "name": "Yuci",
      "names": {
        "zh": "榆次"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.67945,
        "lng": 112.732601
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.632601,
        37.57945,
        112.832601,
        37.77945
      ]
    },
    {
      "id": 952,
      "name": "Omaha",
      "names": {
        "zh": "奥马哈",
        "ja": "オマハ",
        "th": "โอมาฮา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2587459,
        "lng": -95.9383758
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.2682,
        41.190585,
        -95.871141,
        41.3930618
      ]
    },
    {
      "id": 953,
      "name": "Saratov",
      "names": {
        "zh": "萨拉托夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.51825905,
        "lng": 46.00596951440039
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        46.0054461,
        51.5179416,
        46.006493,
        51.5185764
      ]
    },
    {
      "id": 954,
      "name": "Erbil",
      "names": {
        "zh": "伊尔比尔"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.1911744,
        "lng": 44.0094145
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        43.8494145,
        36.0311744,
        44.1694145,
        36.3511744
      ]
    },
    {
      "id": 955,
      "name": "Iguacu",
      "names": {
        "fr": "Iguaçu",
        "zh": "伊瓜苏",
        "pt": "Iguaçu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.4760346,
        "lng": -42.5495463
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.5635077,
        -19.4825178,
        -42.5424772,
        -19.4693006
      ]
    },
    {
      "id": 956,
      "name": "El Paso",
      "names": {
        "ja": "エルパソ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.7601164,
        "lng": -106.4870404
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -106.6357782,
        31.6222161,
        -106.1991528,
        32.001484
      ]
    },
    {
      "id": 957,
      "name": "Pasig City",
      "names": {
        "es": "Ciudad de Pasig",
        "fr": "Ville de Pasig",
        "zh": "帕西格市",
        "de": "Pasig-Stadt",
        "id": "Kota pasig",
        "ja": "パシィグシティ",
        "th": "เมือง Pasig",
        "pt": "Cidade pasig"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.570565,
        "lng": 121.086629
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.064483,
        14.534038,
        121.116208,
        14.615116
      ]
    },
    {
      "id": 958,
      "name": "Denpasar",
      "names": {
        "zh": "登巴萨",
        "ja": "デンパサール"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.6524973,
        "lng": 115.2191175
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        115.173186,
        -9.0374877,
        115.4521505,
        -8.5908402
      ]
    },
    {
      "id": 959,
      "name": "Dehui",
      "names": {
        "zh": "德惠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.5350141,
        "lng": 125.6954363
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        125.2439037,
        44.0343012,
        126.4082281,
        44.8846468
      ]
    },
    {
      "id": 960,
      "name": "Bhubaneshwar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.269622,
        "lng": 85.823183
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        85.751398,
        20.212083,
        85.87898,
        20.321288
      ]
    },
    {
      "id": 961,
      "name": "Tongchuan",
      "names": {
        "zh": "铜川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.8972729,
        "lng": 108.9403845
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.5828881,
        34.8093249,
        109.4937717,
        35.5820444
      ]
    },
    {
      "id": 962,
      "name": "Cheongju",
      "names": {
        "zh": "清州",
        "id": "Cheongju-si"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.6421169,
        "lng": 127.4891742
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        127.2756505,
        36.4072772,
        127.7699975,
        36.7802634
      ]
    },
    {
      "id": 963,
      "name": "Warri",
      "names": {
        "zh": "瓦里"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.5186186,
        "lng": 5.7480196
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.7206154,
        5.5008698,
        5.8184113,
        5.5732696
      ]
    },
    {
      "id": 964,
      "name": "Pointe-Noire",
      "names": {
        "zh": "黑角",
        "ja": "ポイント-ノアレ",
        "th": "ปอยท์นัวร์"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.7975373,
        "lng": 11.8503297
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        11.6903297,
        -4.9575373,
        12.0103297,
        -4.6375373
      ]
    },
    {
      "id": 965,
      "name": "Sakai",
      "names": {
        "zh": "堺",
        "ja": "堺",
        "th": "ซาไก"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5819598,
        "lng": 135.4688679
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.4638679,
        34.5769598,
        135.4738679,
        34.5869598
      ]
    },
    {
      "id": 966,
      "name": "Rongjiawan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.1379371,
        "lng": 113.1172512
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.9980062,
        29.0786849,
        113.1823092,
        29.2999539
      ]
    },
    {
      "id": 967,
      "name": "Butterworth",
      "names": {
        "zh": "北海",
        "ja": "バターワース",
        "th": "บัตเตอร์เวิ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.3933618,
        "lng": 100.366572
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        100.361572,
        5.3883618,
        100.371572,
        5.3983618
      ]
    },
    {
      "id": 968,
      "name": "Renqiu",
      "names": {
        "zh": "任丘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7063767,
        "lng": 116.1062203
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.9384346,
        38.5528266,
        116.4296657,
        38.8742249
      ]
    },
    {
      "id": 969,
      "name": "Honolulu",
      "names": {
        "zh": "火奴鲁鲁",
        "ja": "ホノルル",
        "th": "โฮโนลูลู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 21.304547,
        "lng": -157.855676
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -158.015676,
        21.144547,
        -157.695676,
        21.464547
      ]
    },
    {
      "id": 970,
      "name": "Xindi",
      "names": {
        "zh": "新地"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.811697,
        "lng": 113.4609389
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.4209389,
        29.771697,
        113.5009389,
        29.851697
      ]
    },
    {
      "id": 971,
      "name": "Wu'an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6972113,
        "lng": 114.1949611
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.0349611,
        36.5372113,
        114.3549611,
        36.8572113
      ]
    },
    {
      "id": 972,
      "name": "Sao Bernardo do Campo",
      "names": {
        "zh": "圣贝纳多做坎波",
        "ja": "サンベルナルドドカンポ",
        "pt": "São Bernardo do Campo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7080345,
        "lng": -46.5506747
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.6520805,
        -23.9669798,
        -46.4101207,
        -23.6411371
      ]
    },
    {
      "id": 973,
      "name": "Gaoyou",
      "names": {
        "zh": "高邮市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7861134,
        "lng": 119.4294614
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.1797197,
        32.586235,
        119.8273855,
        33.1056432
      ]
    },
    {
      "id": 974,
      "name": "Culiacan",
      "names": {
        "zh": "库利亚坎"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.7978965,
        "lng": -107.393395
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -107.553395,
        24.6378965,
        -107.233395,
        24.9578965
      ]
    },
    {
      "id": 975,
      "name": "Hejian",
      "names": {
        "zh": "河间"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4425293,
        "lng": 116.0901457
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.9193764,
        38.3088188,
        116.6376091,
        38.6752147
      ]
    },
    {
      "id": 976,
      "name": "Yiyang",
      "names": {
        "zh": "益阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.438707,
        "lng": 112.056694
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.7188873,
        27.9826719,
        112.9393887,
        29.526716
      ]
    },
    {
      "id": 977,
      "name": "Puxi",
      "names": {
        "zh": "浦西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1977754,
        "lng": 114.6338022
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.5776194,
        35.1786938,
        114.6905646,
        35.2190233
      ]
    },
    {
      "id": 978,
      "name": "Zijinglu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.759886,
        "lng": 113.0224664
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.0057411,
        34.734087,
        113.0585433,
        34.7871166
      ]
    },
    {
      "id": 979,
      "name": "Joao Pessoa",
      "names": {
        "pt": "João Pessoa"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.1215981,
        "lng": -34.882028
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -34.9740106,
        -7.2477433,
        -34.7930182,
        -7.0559654
      ]
    },
    {
      "id": 980,
      "name": "McAllen",
      "names": {
        "zh": "麦卡伦",
        "ja": "マッカレン",
        "th": "แมก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.204114,
        "lng": -98.2300605
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.3180607,
        26.1021346,
        -98.195385,
        26.4666626
      ]
    },
    {
      "id": 981,
      "name": "Queretaro",
      "names": {
        "es": "Querétaro",
        "zh": "克雷塔罗",
        "de": "Querétaro",
        "ja": "ケレタロ",
        "th": "เกเรตาโร"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5954708,
        "lng": -100.397059
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -100.4801491,
        20.5403066,
        -100.3119636,
        20.6839674
      ]
    },
    {
      "id": 982,
      "name": "Abaete",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.1566833,
        "lng": -45.4481213
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.6580411,
        -19.319,
        -45.0809974,
        -18.88
      ]
    },
    {
      "id": 983,
      "name": "Palermo",
      "names": {
        "fr": "Palerme",
        "zh": "巴勒莫",
        "ja": "パレルモ",
        "th": "ปาแลร์โม"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 43.365069,
        "lng": -76.282431
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.334357,
        43.303347,
        -76.183572,
        43.423768
      ]
    },
    {
      "id": 984,
      "name": "Qingping",
      "names": {
        "zh": "清平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.976956,
        "lng": 106.520568
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.4813751,
        29.9394749,
        106.5714561,
        30.0502861
      ]
    },
    {
      "id": 985,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.4697065,
        "lng": -0.3763353
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -0.4325512,
        39.2784496,
        -0.2725205,
        39.566609
      ]
    },
    {
      "id": 986,
      "name": "Niigata",
      "names": {
        "zh": "新泻",
        "ja": "新潟",
        "th": "นิอิกาตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.9163757,
        "lng": 139.0365062
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        138.5581962,
        37.678971,
        139.266812,
        38.2361162
      ]
    },
    {
      "id": 987,
      "name": "Leeds",
      "names": {
        "zh": "利兹",
        "ja": "リーズ",
        "th": "ลีดส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7974185,
        "lng": -1.5437941
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8004214,
        53.6989675,
        -1.2903516,
        53.9458715
      ]
    },
    {
      "id": 988,
      "name": "Moradabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.8334982,
        "lng": 78.7732864
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.6132864,
        28.6734982,
        78.9332864,
        28.9934982
      ]
    },
    {
      "id": 989,
      "name": "Hamamatsu",
      "names": {
        "zh": "滨松",
        "ja": "浜松"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7109786,
        "lng": 137.7259431
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.48685,
        34.4064663,
        138.058702,
        35.304395
      ]
    },
    {
      "id": 990,
      "name": "Xiangxiang",
      "names": {
        "zh": "湘乡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7517719,
        "lng": 112.5255933
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.9951324,
        27.4840458,
        112.6498604,
        28.0634422
      ]
    },
    {
      "id": 991,
      "name": "Chaohucun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.48853,
        "lng": 116.41992
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.39992,
        33.46853,
        116.43992,
        33.50853
      ]
    },
    {
      "id": 992,
      "name": "Fuyang",
      "names": {
        "zh": "阜阳",
        "ja": "阜陽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0504847,
        "lng": 119.9559051
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.4307365,
        29.7464277,
        120.1438806,
        30.2000894
      ]
    },
    {
      "id": 993,
      "name": "Homs",
      "names": {
        "zh": "霍姆斯"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.7333334,
        "lng": 36.7166667
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        36.5566667,
        34.5733334,
        36.8766667,
        34.8933334
      ]
    },
    {
      "id": 994,
      "name": "Lubango",
      "names": {
        "zh": "卢班戈"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -14.9195617,
        "lng": 13.4897509
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        13.3583382,
        -15.126628,
        14.3556651,
        -14.3277132
      ]
    },
    {
      "id": 995,
      "name": "San Luis Potosi",
      "names": {
        "zh": "圣路易斯波托西",
        "ja": "サンルイスポトシ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.1516472,
        "lng": -100.9763993
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -101.1363993,
        21.9916472,
        -100.8163993,
        22.3116472
      ]
    },
    {
      "id": 996,
      "name": "Cencheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9191033,
        "lng": 110.9954753
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.9554753,
        22.8791033,
        111.0354753,
        22.9591033
      ]
    },
    {
      "id": 997,
      "name": "Dali",
      "names": {
        "zh": "大理",
        "ja": "ダリ",
        "th": "ต้าหลี่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.6074778,
        "lng": 100.2651597
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        98.8634062,
        24.6645526,
        101.042314,
        26.700982
      ]
    },
    {
      "id": 998,
      "name": "Hamhung",
      "names": {
        "zh": "咸兴"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.9165451,
        "lng": 127.5384272
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        127.101045,
        39.7050742,
        127.7897929,
        40.2214199
      ]
    },
    {
      "id": 999,
      "name": "Krakow",
      "names": {
        "es": "Cracovia",
        "fr": "Cracovie",
        "zh": "克拉科夫",
        "de": "Krakau",
        "it": "Cracovia",
        "ja": "クラクフ",
        "th": "คราคูฟ",
        "pt": "Cracóvia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0619474,
        "lng": 19.9368564
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        19.7922355,
        49.9676668,
        20.2173455,
        50.1261338
      ]
    },
    {
      "id": 1000,
      "name": "Hempstead",
      "names": {
        "zh": "亨普斯特德",
        "ja": "ヘムステッド",
        "th": "สเตด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6736812,
        "lng": -73.6190736
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.76809,
        40.525318,
        -73.464762,
        40.756705
      ]
    },
    {
      "id": 1001,
      "name": "Frankfurt",
      "names": {
        "fr": "Francfort",
        "zh": "法兰克福",
        "it": "Francoforte",
        "ja": "フランクフルト",
        "th": "แฟรงค์เฟิร์ต"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1106444,
        "lng": 8.6820917
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.4727605,
        50.0153529,
        8.8004049,
        50.2271424
      ]
    },
    {
      "id": 1002,
      "name": "Al `Ayn",
      "names": {
        "es": "Al 'Ayn",
        "zh": "al` ayn",
        "de": "Al` Ayn",
        "ja": "Al` Ayn",
        "pt": "Al 'Ayn"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 24.2248697,
        "lng": 55.7452211
      },
      "country": "United Arab Emirates",
      "importance": 3,
      "bbox": [
        55.5852211,
        24.0648697,
        55.9052211,
        24.3848697
      ]
    },
    {
      "id": 1003,
      "name": "Songzi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.17556,
        "lng": 111.77222
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.75222,
        30.15556,
        111.79222,
        30.19556
      ]
    },
    {
      "id": 1004,
      "name": "Laixi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8618279,
        "lng": 120.5158492
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.2014891,
        36.5710123,
        120.6553661,
        37.148318
      ]
    },
    {
      "id": 1005,
      "name": "Bahawalpur",
      "names": {
        "zh": "巴哈瓦尔布尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.395394,
        "lng": 71.680462
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.588555,
        29.32727,
        71.781953,
        29.437811
      ]
    },
    {
      "id": 1006,
      "name": "Zhongba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.778191,
        "lng": 104.739315
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.7234567,
        31.750283,
        104.7623659,
        31.8197787
      ]
    },
    {
      "id": 1007,
      "name": "Kaduna",
      "names": {
        "zh": "卡杜纳"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.5182899,
        "lng": 7.4359863
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        7.2759863,
        10.3582899,
        7.5959863,
        10.6782899
      ]
    },
    {
      "id": 1008,
      "name": "Qingnian",
      "names": {
        "zh": "青年"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.5717968,
        "lng": 114.2523348
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.2123348,
        33.5317968,
        114.2923348,
        33.6117968
      ]
    },
    {
      "id": 1009,
      "name": "Albuquerque",
      "names": {
        "zh": "阿尔伯克基",
        "ja": "アルバカーキ",
        "th": "อัลบูเคอร์คี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0841034,
        "lng": -106.650985
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -106.8821501,
        34.9470871,
        -106.4706258,
        35.218203
      ]
    },
    {
      "id": 1010,
      "name": "Xinhualu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3936125,
        "lng": 113.7417611
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.6976793,
        34.3542365,
        113.7585328,
        34.4199511
      ]
    },
    {
      "id": 1011,
      "name": "Nerima",
      "names": {
        "ja": "練馬区"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7506445,
        "lng": 139.6162651
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5628876,
        35.711714,
        139.6811135,
        35.7799565
      ]
    },
    {
      "id": 1012,
      "name": "Guangshui",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.6190052,
        "lng": 113.8203522
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.6603522,
        31.4590052,
        113.9803522,
        31.7790052
      ]
    },
    {
      "id": 1013,
      "name": "Tlajomulco de Zuniga",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4737273,
        "lng": -103.4469713
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.4869713,
        20.4337273,
        -103.4069713,
        20.5137273
      ]
    },
    {
      "id": 1014,
      "name": "Samarinda",
      "names": {
        "zh": "三马林达"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.5014579,
        "lng": 117.1528133
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        117.1527633,
        -0.5015079,
        117.1528633,
        -0.5014079
      ]
    },
    {
      "id": 1015,
      "name": "Pietermaritzburg",
      "names": {
        "zh": "彼得马里茨堡"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -29.6,
        "lng": 30.3788889
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        30.2188889,
        -29.76,
        30.5388889,
        -29.44
      ]
    },
    {
      "id": 1016,
      "name": "Changhua",
      "names": {
        "zh": "彰化"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.0755667,
        "lng": 120.5444667
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.2203623,
        23.7856132,
        120.6839479,
        24.2071833
      ]
    },
    {
      "id": 1017,
      "name": "Kolhapur",
      "names": {
        "zh": "戈尔哈布尔",
        "ja": "コラープル",
        "th": "คอล"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.7027362,
        "lng": 74.2376798
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.2326798,
        16.6977362,
        74.2426798,
        16.7077362
      ]
    },
    {
      "id": 1018,
      "name": "Sizhan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0097023,
        "lng": 97.9031817
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        97.8831817,
        35.9897023,
        97.9231817,
        36.0297023
      ]
    },
    {
      "id": 1019,
      "name": "Ciudad Guayana",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.370655,
        "lng": -62.637348
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -62.657348,
        8.350655,
        -62.617348,
        8.390655
      ]
    },
    {
      "id": 1020,
      "name": "Cucuta",
      "names": {
        "fr": "Cúcuta",
        "zh": "库库塔",
        "de": "Cúcuta",
        "id": "Kukuta"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8971458,
        "lng": -72.5080387
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -72.556976,
        7.8534613,
        -72.4567115,
        7.9563762
      ]
    },
    {
      "id": 1021,
      "name": "Licheng",
      "names": {
        "zh": "里程"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6802602,
        "lng": 117.0590968
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.9250226,
        36.3304435,
        117.3768246,
        36.8975377
      ]
    },
    {
      "id": 1022,
      "name": "Ota-ku",
      "names": {
        "zh": "太田-ku",
        "it": "-Di OTA ku",
        "ja": "大田-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.561206,
        "lng": 139.715843
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6528251,
        35.4816556,
        139.8578153,
        35.6130909
      ]
    },
    {
      "id": 1023,
      "name": "Thiruvananthapuram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 8.4882267,
        "lng": 76.947551
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        76.787551,
        8.3282267,
        77.107551,
        8.6482267
      ]
    },
    {
      "id": 1024,
      "name": "Tyumen",
      "names": {
        "zh": "秋明"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.15222,
        "lng": 65.52722
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        65.50722,
        57.13222,
        65.54722,
        57.17222
      ]
    },
    {
      "id": 1025,
      "name": "Nampula",
      "names": {
        "zh": "楠普拉"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -15.11646,
        "lng": 39.2666
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        39.2466,
        -15.13646,
        39.2866,
        -15.09646
      ]
    },
    {
      "id": 1026,
      "name": "Zaporizhzhia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8507859,
        "lng": 35.1182867
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        34.9866698,
        47.7526201,
        35.369035,
        47.9544669
      ]
    },
    {
      "id": 1027,
      "name": "Chengguan",
      "names": {
        "zh": "城关"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1902037,
        "lng": 105.1775211
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.0175211,
        34.0302037,
        105.3375211,
        34.3502037
      ]
    },
    {
      "id": 1028,
      "name": "Kumamoto",
      "names": {
        "zh": "熊本",
        "ja": "熊本",
        "th": "คุมาโมโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.7910238,
        "lng": 130.6888733
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        130.6888233,
        32.7909738,
        130.6889233,
        32.7910738
      ]
    },
    {
      "id": 1029,
      "name": "Nehe",
      "names": {
        "zh": "讷河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.4812343,
        "lng": 124.8768238
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.7168238,
        48.3212343,
        125.0368238,
        48.6412343
      ]
    },
    {
      "id": 1030,
      "name": "Birmingham",
      "names": {
        "zh": "伯明翰",
        "ja": "バーミンガム",
        "th": "เบอร์มิงแฮม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5206824,
        "lng": -86.8024326
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.1226449,
        33.3842122,
        -86.5783119,
        33.6785907
      ]
    },
    {
      "id": 1031,
      "name": "Zunhua",
      "names": {
        "zh": "遵化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1882971,
        "lng": 117.9604699
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.56704,
        39.9151627,
        118.2286755,
        40.3601562
      ]
    },
    {
      "id": 1032,
      "name": "Valenzuela",
      "names": {
        "zh": "巴伦苏埃拉"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6916903,
        "lng": 120.9694534
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.9257944,
        14.6687476,
        121.0245216,
        14.7583971
      ]
    },
    {
      "id": 1033,
      "name": "Orumiyeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.548341,
        "lng": 45.0667111
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        44.9788421,
        37.490767,
        45.1287158,
        37.6014276
      ]
    },
    {
      "id": 1034,
      "name": "Wugang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.7296979,
        "lng": 110.6282775
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.4682775,
        26.5696979,
        110.7882775,
        26.8896979
      ]
    },
    {
      "id": 1035,
      "name": "Osogbo",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.75832225,
        "lng": 4.575044734945106
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        4.5245094,
        7.7142925,
        4.6079946,
        7.8053566
      ]
    },
    {
      "id": 1036,
      "name": "Shuangqiao",
      "names": {
        "zh": "双桥"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9088248,
        "lng": 116.2665156
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.2265156,
        33.8688248,
        116.3065156,
        33.9488248
      ]
    },
    {
      "id": 1037,
      "name": "Comodoro Rivadavia",
      "names": {
        "zh": "科迪多罗·里瓦亚维亚",
        "ja": "コモドロリバデビア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -45.8632024,
        "lng": -67.4752615
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -67.7824527,
        -46.0000174,
        -67.3422539,
        -45.7173043
      ]
    },
    {
      "id": 1038,
      "name": "Cagayan de Oro",
      "names": {
        "ja": "カガヤンデオロ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.48222,
        "lng": 124.64722
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.62722,
        8.46222,
        124.66722,
        8.50222
      ]
    },
    {
      "id": 1039,
      "name": "Langzhong",
      "names": {
        "zh": "阆中"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.579591,
        "lng": 105.9694609
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.8094609,
        31.419591,
        106.1294609,
        31.739591
      ]
    },
    {
      "id": 1040,
      "name": "Qian'an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9978416,
        "lng": 118.6949899
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.4410418,
        39.8423885,
        118.919932,
        40.2526101
      ]
    },
    {
      "id": 1041,
      "name": "Lviv",
      "names": {
        "ja": "リヴィウ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.841952,
        "lng": 24.0315921
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        23.8980549,
        49.7681748,
        24.1334136,
        49.9037122
      ]
    },
    {
      "id": 1042,
      "name": "Zouping",
      "names": {
        "zh": "邹平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8629315,
        "lng": 117.736995
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.310742,
        36.6895122,
        117.9562073,
        37.145396
      ]
    },
    {
      "id": 1043,
      "name": "An Najaf",
      "names": {
        "es": "Un najaf",
        "fr": "Un najaf",
        "zh": "一个najaf",
        "de": "Ein najaf",
        "it": "Un najaf",
        "id": "Najaf",
        "ja": "Najaf",
        "th": "najaf",
        "pt": "Um najaf"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.0010227,
        "lng": 44.3299926
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        44.1699926,
        31.8410227,
        44.4899926,
        32.1610227
      ]
    },
    {
      "id": 1044,
      "name": "Sagamihara",
      "names": {
        "zh": "相模原",
        "ja": "相模原市"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5714621,
        "lng": 139.3731052
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.066037,
        35.474549,
        139.458475,
        35.672897
      ]
    },
    {
      "id": 1045,
      "name": "Sarasota",
      "names": {
        "zh": "萨拉索塔",
        "ja": "サラソタ",
        "th": "ซาราโซตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.33771825,
        "lng": -82.54103543741238
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.5413122,
        27.3373186,
        -82.5407593,
        27.3379927
      ]
    },
    {
      "id": 1046,
      "name": "Mississauga",
      "names": {
        "zh": "米西索加",
        "ja": "ミシソーガ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.5896231,
        "lng": -79.6443879
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.8103184,
        43.4789901,
        -79.5385553,
        43.7370604
      ]
    },
    {
      "id": 1047,
      "name": "Okayama",
      "names": {
        "zh": "冈山",
        "ja": "岡山",
        "th": "โอกายา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6553944,
        "lng": 133.9194595
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        133.739662,
        34.518521,
        134.1525792,
        34.9489321
      ]
    },
    {
      "id": 1048,
      "name": "Anlu",
      "names": {
        "zh": "安陆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2578483,
        "lng": 113.683285
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.523285,
        31.0978483,
        113.843285,
        31.4178483
      ]
    },
    {
      "id": 1049,
      "name": "Dayton",
      "names": {
        "zh": "代顿",
        "ja": "デイトン",
        "th": "เดย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7589478,
        "lng": -84.1916069
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.311377,
        39.70185,
        -84.092938,
        39.920823
      ]
    },
    {
      "id": 1050,
      "name": "Changsha",
      "names": {
        "zh": "长沙",
        "ja": "長沙",
        "th": "ฉางชา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1450774,
        "lng": 113.2384362
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.8915023,
        27.8512095,
        114.2560358,
        28.6644154
      ]
    },
    {
      "id": 1051,
      "name": "Torreon",
      "names": {
        "es": "Torreón",
        "zh": "托雷翁"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.542698,
        "lng": -103.410512
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.570512,
        25.382698,
        -103.250512,
        25.702698
      ]
    },
    {
      "id": 1052,
      "name": "Shihezi",
      "names": {
        "zh": "石河子"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.3,
        "lng": 86.03333
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.01333,
        44.28,
        86.05333,
        44.32
      ]
    },
    {
      "id": 1053,
      "name": "George Town",
      "names": {
        "fr": "Ville de George",
        "zh": "乔治镇",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์",
        "pt": "Cidade de george"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.4141619,
        "lng": 100.3287352
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        100.1749755,
        5.2249977,
        100.3466325,
        5.4829097
      ]
    },
    {
      "id": 1054,
      "name": "Enugu",
      "names": {
        "zh": "埃努古",
        "ja": "エヌーグー"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.4424558,
        "lng": 7.4935646
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        7.4885646,
        6.4374558,
        7.4985646,
        6.4474558
      ]
    },
    {
      "id": 1055,
      "name": "Soledad",
      "names": {
        "zh": "索莱达",
        "ja": "ソウルダッド"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9149593,
        "lng": -74.7821054
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8390798,
        10.8751328,
        -74.7282958,
        10.9453794
      ]
    },
    {
      "id": 1056,
      "name": "Jaboatao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.1752476,
        "lng": -34.9468716
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.097,
        -8.2423628,
        -34.9088591,
        -8.0563618
      ]
    },
    {
      "id": 1057,
      "name": "Yanggok",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.716491,
        "lng": 127.192534
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.093868,
        37.557342,
        127.28245,
        37.760181
      ]
    },
    {
      "id": 1058,
      "name": "Yatou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1555048,
        "lng": 122.4409209
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        122.3897552,
        37.0654413,
        122.658704,
        37.2042525
      ]
    },
    {
      "id": 1059,
      "name": "Santo Andre",
      "names": {
        "ja": "サントアンドレ",
        "th": "ซานโตอังเดร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6533509,
        "lng": -46.5279039
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.5673015,
        -23.8158052,
        -46.2786211,
        -23.6056577
      ]
    },
    {
      "id": 1060,
      "name": "Xichang",
      "names": {
        "zh": "西昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.898547,
        "lng": 102.2627456
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.1027456,
        27.738547,
        102.4227456,
        28.058547
      ]
    },
    {
      "id": 1061,
      "name": "Bhiwandi",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.3025562,
        "lng": 73.0588072
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.0396589,
        19.2824,
        73.0838617,
        19.3205
      ]
    },
    {
      "id": 1062,
      "name": "City of Paranaque",
      "names": {
        "es": "Ciudad de paranaque",
        "fr": "Ville de Paranque",
        "zh": "Paranaque市",
        "de": "Stadt Paranaque",
        "it": "Città di Paranaque",
        "id": "Kota paranaque",
        "ja": "パラナク市",
        "th": "เมืองพารานา",
        "pt": "Cidade de paranaque"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4603581,
        "lng": 121.0423247
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0323247,
        14.4503581,
        121.0523247,
        14.4703581
      ]
    },
    {
      "id": 1063,
      "name": "Dasmarinas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3270819,
        "lng": 120.9370871
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9205241,
        14.2482008,
        121.0108972,
        14.3626996
      ]
    },
    {
      "id": 1064,
      "name": "Chengxiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.1629635,
        "lng": 120.2695902
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.2495902,
        30.1429635,
        120.2895902,
        30.1829635
      ]
    },
    {
      "id": 1065,
      "name": "Saharanpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.9637438,
        "lng": 77.5427464
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.3827464,
        29.8037438,
        77.7027464,
        30.1237438
      ]
    },
    {
      "id": 1066,
      "name": "Warangal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.9732956,
        "lng": 79.6056068
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.6006068,
        17.9682956,
        79.6106068,
        17.9782956
      ]
    },
    {
      "id": 1067,
      "name": "Edogawa",
      "names": {
        "zh": "江户川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.683354,
        "lng": 139.875411
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.833206,
        35.633598,
        139.918701,
        35.750801
      ]
    },
    {
      "id": 1068,
      "name": "Osasco",
      "names": {
        "zh": "奥萨斯库"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5324859,
        "lng": -46.7916801
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.821827,
        -23.6090912,
        -46.747045,
        -23.4613543
      ]
    },
    {
      "id": 1069,
      "name": "Dashiqiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6348183,
        "lng": 122.5042794
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        122.0917903,
        40.3182417,
        123.0012997,
        40.9384475
      ]
    },
    {
      "id": 1070,
      "name": "Latakia",
      "names": {
        "zh": "拉塔基亚",
        "ja": "ラタキア"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.53168,
        "lng": 35.79011
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        35.77011,
        35.51168,
        35.81011,
        35.55168
      ]
    },
    {
      "id": 1071,
      "name": "Rochester",
      "names": {
        "zh": "罗切斯特",
        "ja": "ロチェスター",
        "th": "โรเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.157285,
        "lng": -77.615214
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.701505,
        43.10336,
        -77.53251,
        43.2677292
      ]
    },
    {
      "id": 1072,
      "name": "Fresno",
      "names": {
        "zh": "弗雷斯诺",
        "ja": "フレズノ",
        "th": "เฟรสโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7394421,
        "lng": -119.78483
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.934397,
        36.662162,
        -119.654997,
        36.908905
      ]
    },
    {
      "id": 1073,
      "name": "Banjarmasin",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.3187496,
        "lng": 114.5925828
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        114.5222778,
        -3.3822471,
        114.6595891,
        -3.2674083
      ]
    },
    {
      "id": 1074,
      "name": "Salem",
      "names": {
        "zh": "塞勒姆",
        "ja": "セーラム",
        "th": "ซาเลม"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.6551982,
        "lng": 78.1581771
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.9981771,
        11.4951982,
        78.3181771,
        11.8151982
      ]
    },
    {
      "id": 1075,
      "name": "General Santos",
      "names": {
        "fr": "Général Santos",
        "zh": "一般桑托斯",
        "it": "Generale Santos",
        "ja": "一般的なサントス",
        "th": "ซานโตสทั่วไป"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.11278,
        "lng": 125.17167
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.15167,
        6.09278,
        125.19167,
        6.13278
      ]
    },
    {
      "id": 1076,
      "name": "Hamah",
      "names": {
        "zh": "哈马",
        "de": "Hamäh"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.1343368,
        "lng": 36.7496276
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        36.5896276,
        34.9743368,
        36.9096276,
        35.2943368
      ]
    },
    {
      "id": 1077,
      "name": "Bacoor",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.458816,
        "lng": 120.959579
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9209742,
        14.3626159,
        121.0115722,
        14.4939136
      ]
    },
    {
      "id": 1078,
      "name": "Shishi",
      "names": {
        "zh": "石狮"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7337639,
        "lng": 118.6439351
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.5798374,
        24.6098787,
        118.8844296,
        24.8288841
      ]
    },
    {
      "id": 1079,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6751114,
        "lng": -100.2151753
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.3751753,
        25.5151114,
        -100.0551753,
        25.8351114
      ]
    },
    {
      "id": 1080,
      "name": "Adachi",
      "names": {
        "zh": "足立",
        "ja": "足立"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.783703,
        "lng": 139.795319
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7356927,
        35.7386597,
        139.8587006,
        35.8174827
      ]
    },
    {
      "id": 1081,
      "name": "Qianxi",
      "names": {
        "zh": "千禧"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.0123382,
        "lng": 106.0285636
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.8685636,
        26.8523382,
        106.1885636,
        27.1723382
      ]
    },
    {
      "id": 1082,
      "name": "Tolyatti",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.5149884,
        "lng": 49.4073316
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        49.1928151,
        53.4463571,
        49.7765533,
        53.5972581
      ]
    },
    {
      "id": 1083,
      "name": "Macau",
      "names": {
        "es": "Macao",
        "fr": "Macao",
        "zh": "澳门",
        "it": "Macao",
        "ja": "マカオ",
        "th": "มาเก๊า"
      },
      "countryCode": "MO",
      "latLng": {
        "lat": 22.1899448,
        "lng": 113.5380454
      },
      "country": "Macau",
      "importance": 3,
      "bbox": [
        113.3780454,
        22.0299448,
        113.6980454,
        22.3499448
      ]
    },
    {
      "id": 1084,
      "name": "Bauchi",
      "names": {
        "zh": "包奇"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.3147618,
        "lng": 9.8454417
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        9.6095257,
        9.8953012,
        10.2775332,
        10.6115281
      ]
    },
    {
      "id": 1085,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.2560802,
        "lng": -79.8728583
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.2485579,
        43.0505073,
        -79.6221146,
        43.4706805
      ]
    },
    {
      "id": 1086,
      "name": "Lodz",
      "names": {
        "zh": "罗兹",
        "ja": "ウッチ",
        "th": "ลอดซ์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.7687323,
        "lng": 19.4569911
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        19.3208636,
        51.6861438,
        19.6399456,
        51.8598252
      ]
    },
    {
      "id": 1087,
      "name": "Miluo Chengguanzhen",
      "names": {
        "zh": "米洛成川镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8101339,
        "lng": 113.0613336
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.8648428,
        28.4634661,
        113.4424531,
        29.0669733
      ]
    },
    {
      "id": 1088,
      "name": "Gaizhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4068813,
        "lng": 122.3506585
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        122.1906585,
        40.2468813,
        122.5106585,
        40.5668813
      ]
    },
    {
      "id": 1089,
      "name": "Malegaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.5576062,
        "lng": 74.5246514
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.3646514,
        20.3976062,
        74.6846514,
        20.7176062
      ]
    },
    {
      "id": 1090,
      "name": "Karbala'",
      "names": {
        "es": "Karbala '",
        "fr": "Karbala '",
        "zh": "卡尔巴拉'",
        "de": "Karbala '",
        "it": "Karbala '",
        "id": "Karbala '",
        "ja": "karbala '",
        "th": "karbala '",
        "pt": "Karbala '"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.6156423,
        "lng": 44.0348194
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        43.946195,
        32.5227299,
        44.1042555,
        32.6604927
      ]
    },
    {
      "id": 1091,
      "name": "Leling",
      "names": {
        "zh": "乐陵"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7289733,
        "lng": 117.2135729
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.907292,
        37.4355084,
        117.3437158,
        37.8532134
      ]
    },
    {
      "id": 1092,
      "name": "Sao Jose dos Campos",
      "names": {
        "zh": "圣何塞DOS坎波尔",
        "ja": "サンノゼノドスカンポス",
        "th": "เซา Jose Dos Campos",
        "pt": "São José dos Campos"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1738329,
        "lng": -45.88564715355616
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.8863555,
        -23.1739058,
        -45.8849773,
        -23.17376
      ]
    },
    {
      "id": 1093,
      "name": "Sevilla",
      "names": {
        "zh": "塞维利亚",
        "ja": "セビリア",
        "th": "เซบีย่า",
        "pt": "Sevilha"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.3886303,
        "lng": -5.9953403
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -6.0329183,
        37.3002036,
        -5.8191571,
        37.4529579
      ]
    },
    {
      "id": 1094,
      "name": "Jianshe",
      "names": {
        "zh": "建设"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.656486,
        "lng": 121.4509611
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.4109611,
        31.616486,
        121.4909611,
        31.696486
      ]
    },
    {
      "id": 1095,
      "name": "Shizuoka",
      "names": {
        "zh": "静冈",
        "ja": "静岡",
        "th": "ชิซึโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.979149,
        "lng": 138.38299
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        138.082959,
        34.8019562,
        138.6539534,
        35.645957
      ]
    },
    {
      "id": 1096,
      "name": "Jingcheng",
      "names": {
        "zh": "精诚"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.014167,
        "lng": 120.2625
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.2425,
        31.994167,
        120.2825,
        32.034167
      ]
    },
    {
      "id": 1097,
      "name": "Kochi",
      "names": {
        "zh": "高知",
        "ja": "高知",
        "th": "โคชิ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 9.9711453,
        "lng": 76.28463798918082
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.2369445,
        9.8902872,
        76.3401317,
        10.0518321
      ]
    },
    {
      "id": 1098,
      "name": "Allentown",
      "names": {
        "zh": "阿伦敦",
        "ja": "アレンタウン",
        "th": "แอลเลนทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6022059,
        "lng": -75.4712794
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.5480045,
        40.5499752,
        -75.4192495,
        40.6360128
      ]
    },
    {
      "id": 1099,
      "name": "Tasikmalaya",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.3262484,
        "lng": 108.2201154
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        108.1524714,
        -7.4505039,
        108.3094487,
        -7.2693132
      ]
    },
    {
      "id": 1100,
      "name": "Rasht",
      "names": {
        "zh": "拉什特"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.2793607,
        "lng": 49.5846102
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        49.4976957,
        37.1572903,
        49.6728372,
        37.3288905
      ]
    },
    {
      "id": 1101,
      "name": "Xinmin",
      "names": {
        "zh": "新民"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.9939546,
        "lng": 122.8233066
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        122.4167356,
        41.7077841,
        123.3379912,
        42.2863467
      ]
    },
    {
      "id": 1102,
      "name": "Zhongshu",
      "names": {
        "zh": "中枢"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.81972,
        "lng": 106.40528
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        106.38528,
        27.79972,
        106.42528,
        27.83972
      ]
    },
    {
      "id": 1103,
      "name": "Xigaze",
      "names": {
        "zh": "日喀则"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2713756,
        "lng": 88.8806823
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        88.7206823,
        29.1113756,
        89.0406823,
        29.4313756
      ]
    },
    {
      "id": 1104,
      "name": "Gorakhpur",
      "names": {
        "zh": "戈勒克布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.7600217,
        "lng": 83.3668129
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.2068129,
        26.6000217,
        83.5268129,
        26.9200217
      ]
    },
    {
      "id": 1105,
      "name": "As Sulaymaniyah",
      "names": {
        "es": "Como Sulaymaniyah",
        "fr": "Comme Sulaymaniyah",
        "zh": "作为苏莱曼尼亚",
        "de": "Als Sulaymaniyah",
        "it": "Come Sulaymaniyah",
        "id": "Sebagai sulaymaniyah",
        "ja": "SulayManiyahとして",
        "th": "เป็น Sulaymaniyah",
        "pt": "Como Sulaymaniyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 35.5570501,
        "lng": 45.4425963
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        45.2825963,
        35.3970501,
        45.6025963,
        35.7170501
      ]
    },
    {
      "id": 1106,
      "name": "Zaragoza",
      "names": {
        "fr": "Saragosse",
        "zh": "萨拉戈萨",
        "it": "Saragozza",
        "ja": "サラゴサ",
        "th": "ซาราโกซา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6521342,
        "lng": -0.8809428
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -1.1737289,
        41.4516625,
        -0.6803895,
        41.9314873
      ]
    },
    {
      "id": 1107,
      "name": "Acapulco de Juarez",
      "names": {
        "es": "Acapulco de Juárez",
        "ja": "アカプルコデフレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8680495,
        "lng": -99.8940182
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.0540182,
        16.7080495,
        -99.7340182,
        17.0280495
      ]
    },
    {
      "id": 1108,
      "name": "Bahar",
      "names": {
        "zh": "巴哈"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.9078226,
        "lng": 48.4393612
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.3993612,
        34.8678226,
        48.4793612,
        34.9478226
      ]
    },
    {
      "id": 1109,
      "name": "Palermo",
      "names": {
        "fr": "Palerme",
        "zh": "巴勒莫",
        "ja": "パレルモ",
        "th": "ปาแลร์โม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.1112268,
        "lng": 13.3524434
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        13.242219,
        38.0499137,
        13.4520856,
        38.2245866
      ]
    },
    {
      "id": 1110,
      "name": "Guankou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0056373,
        "lng": 103.6247417
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.604405,
        30.9947851,
        103.6546369,
        31.0454154
      ]
    },
    {
      "id": 1111,
      "name": "Tulsa",
      "names": {
        "zh": "塔尔萨",
        "ja": "タルサ",
        "th": "ทัล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1563122,
        "lng": -95.9927516
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.074478,
        35.968297,
        -95.681597,
        36.336505
      ]
    },
    {
      "id": 1112,
      "name": "Tlaquepaque",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6397718,
        "lng": -103.3120428
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.4720428,
        20.4797718,
        -103.1520428,
        20.7997718
      ]
    },
    {
      "id": 1113,
      "name": "Mazatlan",
      "names": {
        "es": "Mazatlán",
        "zh": "马萨特兰",
        "ja": "マサトラン",
        "th": "มาซาต์ลัน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.2035785,
        "lng": -106.4208391
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -106.5808391,
        23.0435785,
        -106.2608391,
        23.3635785
      ]
    },
    {
      "id": 1114,
      "name": "Songyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.4783388,
        "lng": 113.0354977
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.9954977,
        34.4383388,
        113.0754977,
        34.5183388
      ]
    },
    {
      "id": 1115,
      "name": "Cape Coral",
      "names": {
        "de": "Kapszone",
        "ja": "ケープサンゴ",
        "th": "ปะการังเคป"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.5625742,
        "lng": -81.9438802
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.0916584,
        26.525126,
        -81.9003073,
        26.7700251
      ]
    },
    {
      "id": 1116,
      "name": "Ch'ongjin",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 41.79556,
        "lng": 129.77583
      },
      "country": "North Korea",
      "importance": 2,
      "bbox": [
        129.75583,
        41.77556,
        129.79583,
        41.81556
      ]
    },
    {
      "id": 1117,
      "name": "Puyang Chengguanzhen",
      "names": {
        "zh": "濮阳成庄镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.70278,
        "lng": 115.00528
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.98528,
        35.68278,
        115.02528,
        35.72278
      ]
    },
    {
      "id": 1118,
      "name": "Qionghu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.84061,
        "lng": 112.37399
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1119,
      "name": "Ribeirao Preto",
      "names": {
        "ja": "リベイラオプレト",
        "pt": "Ribeirão Preto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1776315,
        "lng": -47.8100983
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.9879926,
        -21.3692461,
        -47.6460937,
        -21.0593676
      ]
    },
    {
      "id": 1120,
      "name": "Zhaodong",
      "names": {
        "zh": "肇东"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.0722331,
        "lng": 125.9863423
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.8263423,
        45.9122331,
        126.1463423,
        46.2322331
      ]
    },
    {
      "id": 1121,
      "name": "Huambo",
      "names": {
        "zh": "万博"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.7772455,
        "lng": 15.7346713
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        15.5746713,
        -12.9372455,
        15.8946713,
        -12.6172455
      ]
    },
    {
      "id": 1122,
      "name": "Wenchang",
      "names": {
        "zh": "文昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5598627,
        "lng": 116.7461293
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.6459942,
        36.4741688,
        116.785313,
        36.612634
      ]
    },
    {
      "id": 1123,
      "name": "Shulan",
      "names": {
        "zh": "舒兰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.4084581,
        "lng": 126.9410597
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.7810597,
        44.2484581,
        127.1010597,
        44.5684581
      ]
    },
    {
      "id": 1124,
      "name": "Tlalnepantla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5370001,
        "lng": -99.1955083
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.3555083,
        19.3770001,
        -99.0355083,
        19.6970001
      ]
    },
    {
      "id": 1125,
      "name": "Catia La Mar",
      "names": {
        "ja": "カチアラマル"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.60383,
        "lng": -67.03034
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.05034,
        10.58383,
        -67.01034,
        10.62383
      ]
    },
    {
      "id": 1126,
      "name": "Sargodha",
      "names": {
        "zh": "萨戈达"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.0836514,
        "lng": 72.676461
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.671461,
        32.0786514,
        72.681461,
        32.0886514
      ]
    },
    {
      "id": 1127,
      "name": "Al Hufuf",
      "names": {
        "th": "อัล hufuf",
        "pt": "Al hufluf"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 25.324261,
        "lng": 49.631852
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        49.456234,
        25.221619,
        49.784063,
        25.395358
      ]
    },
    {
      "id": 1128,
      "name": "Durango",
      "names": {
        "zh": "杜兰戈",
        "ja": "ドゥランゴ",
        "th": "ดูรังโก"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.022008,
        "lng": -104.654741
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -104.7307796,
        23.9456277,
        -104.5179302,
        24.0951372
      ]
    },
    {
      "id": 1129,
      "name": "Jalingo",
      "names": {
        "zh": "贾林戈"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.894985,
        "lng": 11.360042
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        11.2183466,
        8.7879286,
        11.4584961,
        9.0255423
      ]
    },
    {
      "id": 1130,
      "name": "Bouake",
      "names": {
        "fr": "Bouaké",
        "zh": "布瓦凯"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 7.6852414,
        "lng": -5.0305106
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -5.0305606,
        7.6851914,
        -5.0304606,
        7.6852914
      ]
    },
    {
      "id": 1131,
      "name": "Sialkot City",
      "names": {
        "es": "Ciudad de Sialkot",
        "zh": "Sialkot市",
        "de": "Sialkot-Stadt",
        "it": "Città di Sialkot",
        "id": "Kota sialkot",
        "ja": "シアロコットシティ",
        "th": "เมือง Sialkot"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.4983685,
        "lng": 74.5408711
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        74.5358711,
        32.4933685,
        74.5458711,
        32.5033685
      ]
    },
    {
      "id": 1132,
      "name": "San Jose del Monte",
      "names": {
        "zh": "圣何塞德尔蒙特",
        "ja": "サンノゼルモンテ",
        "th": "ซานโฮเซ่เดลมอนเต"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.81026,
        "lng": 121.04739
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0165886,
        14.7673962,
        121.121262,
        14.8653973
      ]
    },
    {
      "id": 1133,
      "name": "Ansan",
      "names": {
        "zh": "安山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3270474,
        "lng": 126.7887966
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.7837966,
        37.3220474,
        126.7937966,
        37.3320474
      ]
    },
    {
      "id": 1134,
      "name": "Lingbao Chengguanzhen",
      "names": {
        "zh": "灵宝成川镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.51972,
        "lng": 110.86444
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.84444,
        34.49972,
        110.88444,
        34.53972
      ]
    },
    {
      "id": 1135,
      "name": "Hwasu-dong",
      "names": {
        "zh": "hwasu栋",
        "ja": "hwasu-ドン",
        "th": "hwasu ดอง"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4824887,
        "lng": 126.6320161
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.6220161,
        37.4724887,
        126.6420161,
        37.4924887
      ]
    },
    {
      "id": 1136,
      "name": "Hue",
      "names": {
        "es": "Matiz",
        "fr": "Teinte",
        "zh": "色调",
        "de": "Farbton",
        "ja": "色相",
        "th": "ฮิว",
        "pt": "Matiz"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.4639321,
        "lng": 107.5863388
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.4682617,
        16.3225251,
        107.6760471,
        16.6036794
      ]
    },
    {
      "id": 1137,
      "name": "Shimoga",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.9326093,
        "lng": 75.574978
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.414978,
        13.7726093,
        75.734978,
        14.0926093
      ]
    },
    {
      "id": 1138,
      "name": "Bulawayo",
      "names": {
        "zh": "布拉瓦约",
        "th": "บูลาวาโย"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -20.1612221,
        "lng": 28.5726983
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        28.5676983,
        -20.1662221,
        28.5776983,
        -20.1562221
      ]
    },
    {
      "id": 1139,
      "name": "Xiping",
      "names": {
        "zh": "西坪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0205953,
        "lng": 104.8561239
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.8161239,
        30.9805953,
        104.8961239,
        31.0605953
      ]
    },
    {
      "id": 1140,
      "name": "Sanhe",
      "names": {
        "zh": "三河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9815552,
        "lng": 117.0714873
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.7511396,
        39.8067822,
        117.2537346,
        40.0836737
      ]
    },
    {
      "id": 1141,
      "name": "Guntur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.302448,
        "lng": 80.444182
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.36937,
        16.267794,
        80.489011,
        16.382386
      ]
    },
    {
      "id": 1142,
      "name": "Concord",
      "names": {
        "zh": "康科德",
        "it": "A Concord",
        "ja": "コンコルド",
        "th": "คองคอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9768525,
        "lng": -122.0335624
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0645819,
        37.9238607,
        -121.9328265,
        38.022717
      ]
    },
    {
      "id": 1143,
      "name": "Contagem",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9132749,
        "lng": -44.0840953
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.1624207,
        -19.983828,
        -44.0059863,
        -19.7980712
      ]
    },
    {
      "id": 1144,
      "name": "Tiruppur",
      "names": {
        "zh": "蒂鲁布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.1085632,
        "lng": 77.339749
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.334749,
        11.1035632,
        77.344749,
        11.1135632
      ]
    },
    {
      "id": 1145,
      "name": "Ch'onan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.39701,
        "lng": 140.23503
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        140.21503,
        35.37701,
        140.25503,
        35.41701
      ]
    },
    {
      "id": 1146,
      "name": "Tripoli",
      "names": {
        "es": "Trípoli",
        "zh": "的黎波里",
        "de": "Tripolis",
        "ja": "トリポリ",
        "th": "ตริโปลี",
        "pt": "Trípoli"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 34.4373616,
        "lng": 35.8348551
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        35.8177578,
        34.3910093,
        35.8727563,
        34.4668863
      ]
    },
    {
      "id": 1147,
      "name": "Honcho",
      "names": {
        "zh": "本町",
        "ja": "班長"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7598345,
        "lng": 139.708538
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7052326,
        35.7567556,
        139.7113893,
        35.7628639
      ]
    },
    {
      "id": 1148,
      "name": "Dusseldorf",
      "names": {
        "es": "Düsseldorf",
        "fr": "Düsseldorf",
        "zh": "杜塞尔多夫",
        "de": "Düsseldorf",
        "it": "Düsseldorf",
        "ja": "デュッセルドルフ",
        "th": "ดึสเซลดอร์ฟ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2254018,
        "lng": 6.7763137
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        6.6888147,
        51.1243747,
        6.9399324,
        51.3524872
      ]
    },
    {
      "id": 1149,
      "name": "Izhevsk",
      "names": {
        "zh": "伊热夫斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.85192,
        "lng": 53.23297
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        53.104958,
        56.784374,
        53.310116,
        56.90271
      ]
    },
    {
      "id": 1150,
      "name": "Guixi",
      "names": {
        "zh": "贵溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2947694,
        "lng": 117.2401238
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.0801238,
        28.1347694,
        117.4001238,
        28.4547694
      ]
    },
    {
      "id": 1151,
      "name": "Sorocaba",
      "names": {
        "zh": "索罗卡巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5003451,
        "lng": -47.4582864
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.5706612,
        -23.5874914,
        -47.3023274,
        -23.349555
      ]
    },
    {
      "id": 1152,
      "name": "Petaling Jaya",
      "names": {
        "fr": "Pétaling jaya",
        "zh": "脾petaling杰阿",
        "ja": "花屋ジャヤ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.1074,
        "lng": 101.61801
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        101.557617,
        3.04761,
        101.661987,
        3.208733
      ]
    },
    {
      "id": 1153,
      "name": "Tengyue",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0262852,
        "lng": 98.4988544
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.4640348,
        24.8167118,
        98.6104572,
        25.0633939
      ]
    },
    {
      "id": 1154,
      "name": "Wuxue",
      "names": {
        "zh": "武穴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.847035,
        "lng": 115.5562861
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.366745,
        29.825157,
        115.8291148,
        30.2188549
      ]
    },
    {
      "id": 1155,
      "name": "Kikwit",
      "names": {
        "zh": "基奎特"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.0383083,
        "lng": 18.8178447
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        18.6578447,
        -5.1983083,
        18.9778447,
        -4.8783083
      ]
    },
    {
      "id": 1156,
      "name": "Colorado Springs",
      "names": {
        "zh": "科罗拉多斯普林斯",
        "ja": "コロラドスプリングス",
        "th": "โคโลราโดสปริงส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8339578,
        "lng": -104.825348
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.9170862,
        38.7154517,
        -104.5999135,
        39.0351247
      ]
    },
    {
      "id": 1157,
      "name": "Qufu",
      "names": {
        "zh": "曲阜"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5956915,
        "lng": 116.987543
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.8396528,
        35.449183,
        117.2462768,
        35.8087539
      ]
    },
    {
      "id": 1158,
      "name": "Gaobeidian",
      "names": {
        "zh": "高碑店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3242969,
        "lng": 115.8702855
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.7102855,
        39.1642969,
        116.0302855,
        39.4842969
      ]
    },
    {
      "id": 1159,
      "name": "Ruiming",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.8936,
        "lng": 116.022
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.002,
        25.8736,
        116.042,
        25.9136
      ]
    },
    {
      "id": 1160,
      "name": "Wroclaw",
      "names": {
        "es": "De Breslavia",
        "zh": "弗罗茨瓦夫",
        "de": "Breslau",
        "ja": "ヴロツワフ",
        "th": "รอกลอว์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1089776,
        "lng": 17.0326689
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        16.8073393,
        51.0426686,
        17.1762192,
        51.2100604
      ]
    },
    {
      "id": 1161,
      "name": "Gold Coast",
      "names": {
        "es": "Costa de oro",
        "fr": "La Côte d'Or",
        "zh": "黄金海岸",
        "de": "Goldküste",
        "it": "Costa d'oro",
        "id": "Pantai emas",
        "ja": "ゴールドコースト",
        "th": "ชายฝั่งทอง",
        "pt": "Costa dourada"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -28.0023731,
        "lng": 153.4145987
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        153.16891,
        -28.26511,
        153.5519459,
        -27.690395
      ]
    },
    {
      "id": 1162,
      "name": "Xinshi",
      "names": {
        "zh": "新市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0237798,
        "lng": 113.1049907
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.9799867,
        30.972147,
        113.2810769,
        31.241888
      ]
    },
    {
      "id": 1163,
      "name": "Ta`izz",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.5752242,
        "lng": 44.0215268
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        43.8615268,
        13.4152242,
        44.1815268,
        13.7352242
      ]
    },
    {
      "id": 1164,
      "name": "Aracaju",
      "names": {
        "zh": "阿拉卡茹",
        "ja": "アラカジュ",
        "th": "อาราคาจู"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.9162061,
        "lng": -37.0774655
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -37.1736913,
        -11.1616028,
        -37.0334708,
        -10.8625026
      ]
    },
    {
      "id": 1165,
      "name": "Cochabamba",
      "names": {
        "zh": "科恰班巴"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.3936114,
        "lng": -66.1568983
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -66.2783356,
        -17.5257851,
        -66.0701785,
        -17.2781245
      ]
    },
    {
      "id": 1166,
      "name": "Jeonju",
      "names": {
        "zh": "全州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.8237631,
        "lng": 127.1472805
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        126.9961917,
        35.7284791,
        127.2343718,
        35.9013223
      ]
    },
    {
      "id": 1167,
      "name": "Jin'e",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.3354208,
        "lng": 105.2904795
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.2704795,
        29.3154208,
        105.3104795,
        29.3554208
      ]
    },
    {
      "id": 1168,
      "name": "Barnaul",
      "names": {
        "zh": "巴尔瑙尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.3475493,
        "lng": 83.7788448
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        83.5328961,
        53.1868709,
        83.9080489,
        53.4818419
      ]
    },
    {
      "id": 1169,
      "name": "Makati City",
      "names": {
        "es": "Ciudad de Makati",
        "fr": "City Makati",
        "zh": "马卡蒂市",
        "de": "Makati-Stadt",
        "id": "Kota Makati",
        "ja": "マカティシティ",
        "th": "เมืองมาคาติ",
        "pt": "Cidade de makati"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5480343,
        "lng": 121.0128136
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0127636,
        14.5479843,
        121.0128636,
        14.5480843
      ]
    },
    {
      "id": 1170,
      "name": "Stuttgart",
      "names": {
        "zh": "斯图加特",
        "it": "Stoccarda",
        "ja": "シュトゥットガルト",
        "th": "ชตุทท์การ์ท",
        "pt": "Estugarda"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7784485,
        "lng": 9.1800132
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        9.0386007,
        48.6920188,
        9.3160228,
        48.8663994
      ]
    },
    {
      "id": 1171,
      "name": "Rotterdam",
      "names": {
        "zh": "鹿特丹",
        "ja": "ロッテルダム",
        "th": "ร็อตเตอร์ดัม",
        "pt": "Roterdão"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9244424,
        "lng": 4.47775
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3793095,
        51.8616672,
        4.6018083,
        51.9942816
      ]
    },
    {
      "id": 1172,
      "name": "Changping",
      "names": {
        "zh": "昌平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.2196456,
        "lng": 116.2250912
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.8382454,
        40.0352977,
        116.4999691,
        40.3915615
      ]
    },
    {
      "id": 1173,
      "name": "Benghazi",
      "names": {
        "es": "Bengasi",
        "zh": "班加西",
        "de": "Banghazi"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.1200168,
        "lng": 20.0812174
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        18.6509231,
        31.0070362,
        21.3190937,
        32.7335712
      ]
    },
    {
      "id": 1174,
      "name": "Kryvyi Rih",
      "names": {
        "ja": "クリボイリ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.90966,
        "lng": 33.38044
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.36044,
        47.88966,
        33.40044,
        47.92966
      ]
    },
    {
      "id": 1175,
      "name": "Raurkela",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.2309255,
        "lng": 84.8678705
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.7078705,
        22.0709255,
        85.0278705,
        22.3909255
      ]
    },
    {
      "id": 1176,
      "name": "Halwan",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 29.84144,
        "lng": 31.30084
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.28084,
        29.82144,
        31.32084,
        29.86144
      ]
    },
    {
      "id": 1177,
      "name": "Charleston",
      "names": {
        "zh": "查尔斯顿",
        "ja": "チャールストン",
        "th": "ชาร์ลสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7884363,
        "lng": -79.9399309
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1438459,
        32.668505,
        -79.797064,
        32.973515
      ]
    },
    {
      "id": 1178,
      "name": "Chimalhuacan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.428924,
        "lng": -98.946177
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.99059,
        19.372824,
        -98.894248,
        19.45115
      ]
    },
    {
      "id": 1179,
      "name": "Xinxing",
      "names": {
        "zh": "新兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.8155808,
        "lng": 130.8677739
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.7077739,
        45.6555808,
        131.0277739,
        45.9755808
      ]
    },
    {
      "id": 1180,
      "name": "Suohe",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7883437,
        "lng": 113.3702814
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.3367976,
        34.7618158,
        113.3971734,
        34.8162331
      ]
    },
    {
      "id": 1181,
      "name": "Mangalore",
      "names": {
        "zh": "芒格洛尔",
        "ja": "マンガロール",
        "th": "สวิงก์โวลล์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.8698101,
        "lng": 74.8430082
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.6830082,
        12.7098101,
        75.0030082,
        13.0298101
      ]
    },
    {
      "id": 1182,
      "name": "Zhuangyuan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.338773,
        "lng": 120.82292284116184
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.7606884,
        37.2975445,
        120.9092616,
        37.3793653
      ]
    },
    {
      "id": 1183,
      "name": "Glasgow",
      "names": {
        "zh": "格拉斯哥",
        "ja": "グラスゴー",
        "th": "กลาสโกว์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.861155,
        "lng": -4.2501687
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -4.4101687,
        55.701155,
        -4.0901687,
        56.021155
      ]
    },
    {
      "id": 1184,
      "name": "Namangan",
      "names": {
        "zh": "纳曼干"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.9996482,
        "lng": 71.6726238
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        71.5197569,
        40.9594166,
        71.7528648,
        41.1501172
      ]
    },
    {
      "id": 1185,
      "name": "Ulyanovsk",
      "names": {
        "zh": "乌里扬诺夫斯克",
        "de": "Uljanowsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.306374,
        "lng": 48.364811
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        48.059537,
        54.136572,
        48.718312,
        54.427661
      ]
    },
    {
      "id": 1186,
      "name": "Irkutsk",
      "names": {
        "fr": "Irkoutsk",
        "zh": "伊尔库茨克",
        "ja": "イルクーツク",
        "th": "อีร์คุตสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.2896496,
        "lng": 104.3051892
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        104.3051392,
        52.2895996,
        104.3052392,
        52.2896996
      ]
    },
    {
      "id": 1187,
      "name": "Nanded",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.17257215,
        "lng": 77.29141237169956
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.1619928,
        19.0688917,
        77.4083376,
        19.2765521
      ]
    },
    {
      "id": 1188,
      "name": "Jos",
      "names": {
        "zh": "乔斯",
        "id": "Yos"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.9175125,
        "lng": 8.8979401
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        8.7379401,
        9.7575125,
        9.0579401,
        10.0775125
      ]
    },
    {
      "id": 1189,
      "name": "Pontianak",
      "names": {
        "zh": "坤甸"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.0226903,
        "lng": 109.3447488
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        109.2741676,
        -0.0981948,
        109.3853475,
        0.0381168
      ]
    },
    {
      "id": 1190,
      "name": "Villa Nueva",
      "names": {
        "zh": "别墅Nueva",
        "ja": "ヴィラヌエバ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5308826,
        "lng": -90.5963325
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.663641,
        14.4758249,
        -90.5512503,
        14.5889122
      ]
    },
    {
      "id": 1191,
      "name": "Bazhou",
      "names": {
        "zh": "霸州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1115217,
        "lng": 116.3835382
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.3435382,
        39.0715217,
        116.4235382,
        39.1515217
      ]
    },
    {
      "id": 1192,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1018764,
        "lng": -72.5886727
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.6221576,
        42.0637364,
        -72.471087,
        42.1622195
      ]
    },
    {
      "id": 1193,
      "name": "Turpan",
      "names": {
        "zh": "吐鲁番"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9386101,
        "lng": 89.17131146443546
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        89.1704048,
        42.9382719,
        89.172216,
        42.9389349
      ]
    },
    {
      "id": 1194,
      "name": "Las Pinas City",
      "names": {
        "es": "La ciudad de las pinas",
        "ja": "ラス・ピナスシティ",
        "pt": "Cidade de las pinas"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 47.60796,
        "lng": -122.334382
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.342798,
        47.602337,
        -122.328063,
        47.616067
      ]
    },
    {
      "id": 1195,
      "name": "Meihekou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.5328318,
        "lng": 125.6777893
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.6727893,
        42.5278318,
        125.6827893,
        42.5378318
      ]
    },
    {
      "id": 1196,
      "name": "Jurong",
      "names": {
        "zh": "裕廊",
        "th": "จูร่ง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9478237,
        "lng": 119.1633971
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.9621166,
        31.6270432,
        119.379687,
        32.2181113
      ]
    },
    {
      "id": 1197,
      "name": "Feira de Santana",
      "names": {
        "th": "เฟริร่าเดอซานตานา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.2578934,
        "lng": -38.9598047
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.2965089,
        -12.4186643,
        -38.8115579,
        -11.9813841
      ]
    },
    {
      "id": 1198,
      "name": "Khabarovsk",
      "names": {
        "zh": "哈巴罗夫斯克",
        "de": "Chabarowsk",
        "id": "Khabarovsk Novy",
        "ja": "ハバロフスク"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.4812568,
        "lng": 135.0762968
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        134.8978908,
        48.3267961,
        135.2211823,
        48.6030133
      ]
    },
    {
      "id": 1199,
      "name": "Xinji",
      "names": {
        "zh": "辛集"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9425431,
        "lng": 115.2126339
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.0526339,
        37.7825431,
        115.3726339,
        38.1025431
      ]
    },
    {
      "id": 1200,
      "name": "Serang",
      "names": {
        "zh": "西冷"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.1123938,
        "lng": 106.1585941
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        106.1535941,
        -6.1173938,
        106.1635941,
        -6.1073938
      ]
    },
    {
      "id": 1201,
      "name": "Kandahar",
      "names": {
        "zh": "坎大哈",
        "ja": "カンダハル",
        "th": "กันดาฮาร์"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 31.6205738,
        "lng": 65.7157573
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        65.5557573,
        31.4605738,
        65.8757573,
        31.7805738
      ]
    },
    {
      "id": 1202,
      "name": "Sanya",
      "names": {
        "zh": "三亚",
        "ja": "三亜",
        "th": "ซานย่า"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 18.2534658,
        "lng": 109.5034392
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.9281747,
        18.1438431,
        109.8069484,
        18.6302034
      ]
    },
    {
      "id": 1203,
      "name": "San Miguel de Tucuman",
      "names": {
        "ja": "サンミゲルデチューマン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.8303703,
        "lng": -65.2038133
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -65.2749039,
        -26.9103413,
        -65.1591609,
        -26.7838099
      ]
    },
    {
      "id": 1204,
      "name": "Yaroslavl",
      "names": {
        "zh": "雅罗斯拉夫尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.625147,
        "lng": 39.839854
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        39.758556,
        57.530418,
        39.972832,
        57.676888
      ]
    },
    {
      "id": 1205,
      "name": "Zhangshu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.0593779,
        "lng": 115.5409818
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.3809818,
        27.8993779,
        115.7009818,
        28.2193779
      ]
    },
    {
      "id": 1206,
      "name": "Bagcilar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.034547,
        "lng": 28.8567558
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.8079497,
        41.0175556,
        28.872744,
        41.0724184
      ]
    },
    {
      "id": 1207,
      "name": "Grand Rapids",
      "names": {
        "fr": "Grands rapides",
        "zh": "大急流",
        "ja": "グランドラピッズ",
        "th": "แกรนด์แรพิดส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9632425,
        "lng": -85.6678639
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.751532,
        42.883648,
        -85.568646,
        43.0290471
      ]
    },
    {
      "id": 1208,
      "name": "Vladivostok",
      "names": {
        "zh": "海参崴",
        "ja": "ウラジオストック",
        "th": "วลา"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.117122,
        "lng": 131.896018
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        131.876018,
        43.097122,
        131.916018,
        43.137122
      ]
    },
    {
      "id": 1209,
      "name": "Kuantan",
      "names": {
        "zh": "关丹",
        "th": "กวนตัน"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.7985637,
        "lng": 103.32199
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        103.2983943,
        3.7827658,
        103.3724273,
        3.8466265
      ]
    },
    {
      "id": 1210,
      "name": "Gothenburg",
      "names": {
        "es": "Gotemburgo",
        "fr": "Göteborg",
        "zh": "哥德堡",
        "de": "Göteborg",
        "it": "Göteborg",
        "ja": "ヨーテボリ",
        "th": "โกเทนเบิร์ก",
        "pt": "Gotemburgo"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.7072326,
        "lng": 11.9670171
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        11.8070171,
        57.5472326,
        12.1270171,
        57.8672326
      ]
    },
    {
      "id": 1211,
      "name": "Cuttack",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.4686,
        "lng": 85.8792
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        85.7192,
        20.3086,
        86.0392,
        20.6286
      ]
    },
    {
      "id": 1212,
      "name": "Jambi",
      "names": {
        "zh": "占碑",
        "ja": "ジャンビ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -1.613857,
        "lng": 103.60047
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        103.525308,
        -1.691873,
        103.680907,
        -1.546381
      ]
    },
    {
      "id": 1213,
      "name": "Cimahi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8858788,
        "lng": 107.5360371
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        107.5310371,
        -6.8908788,
        107.5410371,
        -6.8808788
      ]
    },
    {
      "id": 1214,
      "name": "Bacolod",
      "names": {
        "zh": "巴科洛得"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6762836,
        "lng": 122.9513786
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.8141866,
        10.5849398,
        123.1394029,
        10.7305943
      ]
    },
    {
      "id": 1215,
      "name": "Zhuozhou",
      "names": {
        "zh": "涿州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.479732,
        "lng": 115.978183
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.973183,
        39.474732,
        115.983183,
        39.484732
      ]
    },
    {
      "id": 1216,
      "name": "Uberlandia",
      "names": {
        "zh": "乌贝兰迪亚",
        "pt": "Uberlândia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.9188041,
        "lng": -48.2767837
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.8234391,
        -19.416808,
        -47.9034816,
        -18.5922258
      ]
    },
    {
      "id": 1217,
      "name": "Tianchang",
      "names": {
        "zh": "天长市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.669149,
        "lng": 118.9994478
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.8394478,
        32.509149,
        119.1594478,
        32.829149
      ]
    },
    {
      "id": 1218,
      "name": "Kawaguchi",
      "names": {
        "zh": "川口",
        "ja": "川口"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8078228,
        "lng": 139.7241054
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.675201,
        35.7801769,
        139.788283,
        35.887065
      ]
    },
    {
      "id": 1219,
      "name": "Itabashi",
      "names": {
        "zh": "板桥"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.774143,
        "lng": 139.681209
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6245921,
        35.735779,
        139.7227558,
        35.8008322
      ]
    },
    {
      "id": 1220,
      "name": "Suginami-ku",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6994929,
        "lng": 139.6362876
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5850772,
        35.6658826,
        139.6677991,
        35.7314266
      ]
    },
    {
      "id": 1221,
      "name": "Tuxtla",
      "names": {
        "zh": "图斯特拉 - ",
        "th": "ตุซตลากูตี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.754602,
        "lng": -93.099805
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -93.220327,
        16.684997,
        -93.044944,
        16.806997
      ]
    },
    {
      "id": 1222,
      "name": "Tumkur",
      "names": {
        "zh": "杜姆古尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.3400771,
        "lng": 77.1006208
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.9406208,
        13.1800771,
        77.2606208,
        13.5000771
      ]
    },
    {
      "id": 1223,
      "name": "Leipzig",
      "names": {
        "zh": "莱比锡",
        "it": "Lipsia",
        "ja": "ライプチヒ",
        "th": "ไลพ์ซิก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3406321,
        "lng": 12.3747329
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.2366519,
        51.2381704,
        12.5425373,
        51.4481145
      ]
    },
    {
      "id": 1224,
      "name": "Balikpapan",
      "names": {
        "zh": "巴厘巴板"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -1.163229,
        "lng": 116.883623
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        116.748955,
        -1.28381,
        117.022362,
        -1.039534
      ]
    },
    {
      "id": 1225,
      "name": "Qamdo",
      "names": {
        "zh": "昌都"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1408514,
        "lng": 97.1789501
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        96.6951118,
        30.7008111,
        97.956555,
        32.3066711
      ]
    },
    {
      "id": 1226,
      "name": "Durban",
      "names": {
        "zh": "德班",
        "ja": "ダーバン",
        "th": "เดอร์บัน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -29.855494,
        "lng": 31.017954
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        30.913838,
        -29.95541,
        31.05637,
        -29.780622
      ]
    },
    {
      "id": 1227,
      "name": "Chanda",
      "names": {
        "zh": "尚达"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.7110137,
        "lng": 78.5492937
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.5292937,
        19.6910137,
        78.5692937,
        19.7310137
      ]
    },
    {
      "id": 1228,
      "name": "Kagoshima",
      "names": {
        "zh": "鹿儿岛",
        "ja": "鹿児島",
        "th": "คะโงะชิมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.590578,
        "lng": 130.540904
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        130.479577,
        31.459477,
        130.599541,
        31.751201
      ]
    },
    {
      "id": 1229,
      "name": "Al Hillah",
      "names": {
        "id": "Al Hilah",
        "ja": "アルヒラ",
        "th": "อัลฮิฮ่า"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 18.2183788,
        "lng": 40.7244944
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        40.4044944,
        17.8983788,
        41.0444944,
        18.5383788
      ]
    },
    {
      "id": 1230,
      "name": "Makhachkala",
      "names": {
        "zh": "马哈奇卡拉"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 42.9933088,
        "lng": 47.4953643
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        47.4953143,
        42.9932588,
        47.4954143,
        42.9933588
      ]
    },
    {
      "id": 1231,
      "name": "Sihui",
      "names": {
        "zh": "四会"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.345157,
        "lng": 112.68342
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.58342,
        23.245157,
        112.78342,
        23.445157
      ]
    },
    {
      "id": 1232,
      "name": "Mar del Plata",
      "names": {
        "ja": "マーデルプラタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.9977225,
        "lng": -57.5482703
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -57.6591066,
        -38.1337395,
        -57.5177864,
        -37.9039649
      ]
    },
    {
      "id": 1233,
      "name": "Irapuato",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6758761,
        "lng": -101.3521052
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.5121052,
        20.5158761,
        -101.1921052,
        20.8358761
      ]
    },
    {
      "id": 1234,
      "name": "Brampton",
      "names": {
        "zh": "布兰普顿",
        "ja": "ブランプトン",
        "th": "แบรมพ์ตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.6868917,
        "lng": -79.7646832
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.7696832,
        43.6818917,
        -79.7596832,
        43.6918917
      ]
    },
    {
      "id": 1235,
      "name": "Luocheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.980214,
        "lng": 104.280808
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.2581301,
        30.9632855,
        104.3002536,
        30.9994435
      ]
    },
    {
      "id": 1236,
      "name": "Pereira",
      "names": {
        "zh": "佩雷拉",
        "ja": "ペレイラ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8143159,
        "lng": -75.6946778
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.7699003,
        4.7821777,
        -75.6567821,
        4.8291965
      ]
    },
    {
      "id": 1237,
      "name": "Mission Viejo",
      "names": {
        "zh": "使命viejo",
        "it": "Missione viejo",
        "id": "Misi viejo",
        "ja": "ミッションヴィエホ",
        "pt": "Missão Viejo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5965685,
        "lng": -117.659405
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.6958788,
        33.5338343,
        -117.6196099,
        33.6728031
      ]
    },
    {
      "id": 1238,
      "name": "Dortmund",
      "names": {
        "zh": "多特蒙德",
        "ja": "ドルトムント",
        "th": "ดอร์ทมุนด์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5142273,
        "lng": 7.4652789
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.302387,
        51.4155255,
        7.638157,
        51.6000415
      ]
    },
    {
      "id": 1239,
      "name": "Reynosa",
      "names": {
        "zh": "雷诺萨"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.0907673,
        "lng": -98.2788191
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4388191,
        25.9307673,
        -98.1188191,
        26.2507673
      ]
    },
    {
      "id": 1240,
      "name": "Chuxiong",
      "names": {
        "zh": "楚雄"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.04237,
        "lng": 101.541137
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        101.441137,
        24.94237,
        101.641137,
        25.14237
      ]
    },
    {
      "id": 1241,
      "name": "Zahedan",
      "names": {
        "zh": "扎黑丹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.4963,
        "lng": 60.8629
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        60.8429,
        29.4763,
        60.8829,
        29.5163
      ]
    },
    {
      "id": 1242,
      "name": "Jinhua",
      "names": {
        "zh": "金华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.1080344,
        "lng": 119.6486487
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.2162337,
        28.5162993,
        120.7802853,
        29.6840795
      ]
    },
    {
      "id": 1243,
      "name": "Albany",
      "names": {
        "zh": "奥尔巴尼",
        "ja": "アルバニー",
        "th": "ออลบานี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6511674,
        "lng": -73.754968
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -73.897457,
        42.613528,
        -73.723229,
        42.7221258
      ]
    },
    {
      "id": 1244,
      "name": "Shah Alam",
      "names": {
        "zh": "莎阿拉姆",
        "th": "ชาห์อาลัม"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.0739429,
        "lng": 101.5185278
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        101.4277331,
        2.9496181,
        101.5913878,
        3.2297212
      ]
    },
    {
      "id": 1245,
      "name": "Knoxville",
      "names": {
        "zh": "诺克斯维尔",
        "ja": "ノックスビル",
        "th": "นอกซ์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9603948,
        "lng": -83.9210261
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.161625,
        35.849497,
        -83.688543,
        36.067428
      ]
    },
    {
      "id": 1246,
      "name": "Cuiaba",
      "names": {
        "fr": "Cuiabá",
        "zh": "库亚巴",
        "th": "กุยาบา",
        "pt": "Cuiabá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.5986686,
        "lng": -56.0991301
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -56.3019265,
        -16.5370567,
        -55.1272671,
        -15.0700643
      ]
    },
    {
      "id": 1247,
      "name": "Shangzhi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2133989,
        "lng": 127.9634816
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        127.3718012,
        44.4884949,
        129.2026958,
        45.5904064
      ]
    },
    {
      "id": 1248,
      "name": "Essen",
      "names": {
        "zh": "埃森",
        "ja": "エッセン",
        "th": "เอสเซน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4582235,
        "lng": 7.0158171
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.8943442,
        51.3475714,
        7.13765,
        51.5342269
      ]
    },
    {
      "id": 1249,
      "name": "Botou",
      "names": {
        "zh": "泊头"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0764217,
        "lng": 116.5696393
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.036455,
        37.9100778,
        116.7544316,
        38.2222021
      ]
    },
    {
      "id": 1250,
      "name": "Bucaramanga",
      "names": {
        "zh": "布卡拉曼加"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.1255169,
        "lng": -73.1182624
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -73.1722846,
        7.070357,
        -73.0922848,
        7.1801474
      ]
    },
    {
      "id": 1251,
      "name": "Anyang",
      "names": {
        "zh": "安阳",
        "ja": "安養"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4016302,
        "lng": 126.9228826
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.9178826,
        37.3966302,
        126.9278826,
        37.4066302
      ]
    },
    {
      "id": 1252,
      "name": "Genoa",
      "names": {
        "es": "Génova",
        "fr": "Gênes",
        "zh": "热那亚",
        "de": "Genua",
        "it": "Genova",
        "ja": "ジェノヴァ",
        "th": "เจนัว",
        "pt": "Génova"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.40726,
        "lng": 8.9338624
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        8.6657444,
        44.3784709,
        9.0955805,
        44.5198419
      ]
    },
    {
      "id": 1253,
      "name": "Port Sudan",
      "names": {
        "es": "Port Sudán",
        "fr": "Soudan du port",
        "zh": "苏丹港",
        "de": "Port-Sudan",
        "it": "Porto sudan",
        "ja": "ポートスーダン",
        "th": "พอร์ตซูดาน",
        "pt": "Porto sudão"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 19.61745,
        "lng": 37.21644
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        37.19644,
        19.59745,
        37.23644,
        19.63745
      ]
    },
    {
      "id": 1254,
      "name": "Puente Alto",
      "names": {
        "zh": "普恩特阿尔托"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6095279,
        "lng": -70.5754736
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.6313258,
        -33.6433849,
        -70.4858251,
        -33.5427605
      ]
    },
    {
      "id": 1255,
      "name": "Dehra Dun",
      "names": {
        "th": "เดช",
        "pt": "Dun dehra dun"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.3255646,
        "lng": 78.0436813
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        77.8836813,
        30.1655646,
        78.2036813,
        30.4855646
      ]
    },
    {
      "id": 1256,
      "name": "At Ta'if",
      "names": {
        "es": "En ta'if",
        "fr": "À Ta'if",
        "zh": "在ta'if",
        "de": "Bei ta'if",
        "it": "A ta'if",
        "id": "Di ta'f",
        "ja": "ta'ifで",
        "th": "ที่ Ta'if",
        "pt": "Na ta'if"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 21.2702801,
        "lng": 40.4158308
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        40.2558308,
        21.1102801,
        40.5758308,
        21.4302801
      ]
    },
    {
      "id": 1257,
      "name": "Bakersfield",
      "names": {
        "zh": "贝克斯菲尔德",
        "ja": "ベーカーズフィールド",
        "th": "เบเกอร์สฟีลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3738712,
        "lng": -119.019463
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.265033,
        35.193979,
        -118.772341,
        35.4473018
      ]
    },
    {
      "id": 1258,
      "name": "Wancheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 18.7974862,
        "lng": 110.3966948
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.3566948,
        18.7574862,
        110.4366948,
        18.8374862
      ]
    },
    {
      "id": 1259,
      "name": "Malaga",
      "names": {
        "es": "Málaga",
        "zh": "马拉加",
        "ja": "マラガ",
        "th": "มาลากา",
        "pt": "Málaga"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7213028,
        "lng": -4.4216366
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -4.5878887,
        36.6356619,
        -4.2604897,
        36.8940412
      ]
    },
    {
      "id": 1260,
      "name": "Hachioji",
      "names": {
        "zh": "八王子",
        "ja": "八王子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.655389,
        "lng": 139.3394669
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3344669,
        35.650389,
        139.3444669,
        35.660389
      ]
    },
    {
      "id": 1261,
      "name": "Ogden",
      "names": {
        "zh": "奥格登",
        "ja": "オグデン",
        "th": "อ็อกเดน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2230048,
        "lng": -111.9738429
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.0255709,
        41.160109,
        -111.920381,
        41.285993
      ]
    },
    {
      "id": 1262,
      "name": "Xiulin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7205575,
        "lng": 112.4080217
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.3680217,
        29.6805575,
        112.4480217,
        29.7605575
      ]
    },
    {
      "id": 1263,
      "name": "Fu'an",
      "names": {
        "zh": "福安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.0909995,
        "lng": 119.6433621
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.3850586,
        26.6931585,
        119.863529,
        27.4011412
      ]
    },
    {
      "id": 1264,
      "name": "Tomsk",
      "names": {
        "zh": "托木斯克",
        "ja": "トムスク"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.4887526,
        "lng": 84.9523434
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        84.8275215,
        56.379368,
        85.1495558,
        56.5705856
      ]
    },
    {
      "id": 1265,
      "name": "Tonala",
      "names": {
        "zh": "托纳拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6241367,
        "lng": -103.2421263
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.4021263,
        20.4641367,
        -103.0821263,
        20.7841367
      ]
    },
    {
      "id": 1266,
      "name": "Kumul",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.8254995,
        "lng": 93.5130464
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        93.3530464,
        42.6654995,
        93.6730464,
        42.9854995
      ]
    },
    {
      "id": 1267,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4430523,
        "lng": -2.581638861285822
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.1178159,
        51.3416261,
        -2.5104192,
        51.5444317
      ]
    },
    {
      "id": 1268,
      "name": "Luofeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3662834,
        "lng": 120.394086
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.3186196,
        37.2846101,
        120.4055763,
        37.374738
      ]
    },
    {
      "id": 1269,
      "name": "Lingyuan",
      "names": {
        "zh": "凌源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.240767,
        "lng": 119.3958124
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.2358124,
        41.080767,
        119.5558124,
        41.400767
      ]
    },
    {
      "id": 1270,
      "name": "Irbid",
      "names": {
        "zh": "伊尔比德"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.5556091,
        "lng": 35.8493023
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.6893023,
        32.3956091,
        36.0093023,
        32.7156091
      ]
    },
    {
      "id": 1271,
      "name": "Baton Rouge",
      "names": {
        "zh": "巴顿胭脂",
        "ja": "バトンルージュ",
        "th": "แบตันรูช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.4494155,
        "lng": -91.1869659
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -91.2350363,
        30.3383782,
        -90.9993438,
        30.5589767
      ]
    },
    {
      "id": 1272,
      "name": "Huzhou",
      "names": {
        "zh": "湖州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8942995,
        "lng": 120.0851114
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.2324036,
        30.3756191,
        120.4840793,
        31.1825784
      ]
    },
    {
      "id": 1273,
      "name": "Wencheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6544676,
        "lng": 118.2028461
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.1372416,
        28.603646,
        118.255527,
        28.674935
      ]
    },
    {
      "id": 1274,
      "name": "Bremen",
      "names": {
        "fr": "Brême",
        "zh": "不来梅",
        "it": "Brema",
        "ja": "ブレーメン",
        "th": "เบรเมิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.0758196,
        "lng": 8.8071646
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        8.4815929,
        53.0110367,
        8.9907318,
        53.5984187
      ]
    },
    {
      "id": 1275,
      "name": "Ciudad Bolivar",
      "names": {
        "es": "Ciudad Bolívar",
        "zh": "Ciudad玻利亚尔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.1028532,
        "lng": -63.5469562
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -63.6777288,
        7.9718152,
        -63.4184504,
        8.1762767
      ]
    },
    {
      "id": 1276,
      "name": "Durgapur",
      "names": {
        "zh": "督伽坡"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.5350475,
        "lng": 87.3380425
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.1780425,
        23.3750475,
        87.4980425,
        23.6950475
      ]
    },
    {
      "id": 1277,
      "name": "Orenburg",
      "names": {
        "zh": "奥伦堡"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.7671248,
        "lng": 55.0978517
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        55.0192619,
        51.6838978,
        55.3098936,
        51.8966577
      ]
    },
    {
      "id": 1278,
      "name": "Shenzhou",
      "names": {
        "zh": "神舟"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9995085,
        "lng": 115.5555234
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.3955234,
        37.8395085,
        115.7155234,
        38.1595085
      ]
    },
    {
      "id": 1279,
      "name": "Asansol",
      "names": {
        "zh": "阿散索尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.6871297,
        "lng": 86.9746587
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.9164823,
        23.6464971,
        87.0030641,
        23.7142892
      ]
    },
    {
      "id": 1280,
      "name": "Akron",
      "names": {
        "zh": "阿克伦",
        "ja": "アクロン",
        "th": "แอครอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.083064,
        "lng": -81.518485
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.621014,
        40.9976575,
        -81.404942,
        41.171061
      ]
    },
    {
      "id": 1281,
      "name": "Kuiju",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.85361,
        "lng": 119.39083
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.37083,
        36.83361,
        119.41083,
        36.87361
      ]
    },
    {
      "id": 1282,
      "name": "New Haven",
      "names": {
        "es": "Nuevo refugio",
        "zh": "新天堂",
        "de": "Neuer Hafen",
        "it": "Nuovo rifugio",
        "id": "Surga Baru",
        "ja": "ニューヘブン",
        "th": "ใหม่สวรรค์",
        "pt": "Novo refúgio"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3082138,
        "lng": -72.9250518
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.9980695,
        41.2464253,
        -72.8608112,
        41.3505968
      ]
    },
    {
      "id": 1283,
      "name": "Zhenzhou",
      "names": {
        "zh": "郑州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.28034,
        "lng": 119.16999
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.14999,
        32.26034,
        119.18999,
        32.30034
      ]
    },
    {
      "id": 1284,
      "name": "Surakarta",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.5692489,
        "lng": 110.828448
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        110.7690994,
        -7.5952116,
        110.8689442,
        -7.5232191
      ]
    },
    {
      "id": 1285,
      "name": "Jieshou",
      "names": {
        "zh": "界首"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.2619055,
        "lng": 115.3540362
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.2416012,
        33.0060093,
        115.5289462,
        33.5250063
      ]
    },
    {
      "id": 1286,
      "name": "Benguela",
      "names": {
        "zh": "本格拉"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.5771706,
        "lng": 13.4120614
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        13.4070614,
        -12.5821706,
        13.4170614,
        -12.5721706
      ]
    },
    {
      "id": 1287,
      "name": "Ensenada",
      "names": {
        "zh": "恩塞纳达",
        "ja": "エンセナダ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.8658887,
        "lng": -116.602983
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -116.7568468,
        31.6518752,
        -116.4972675,
        31.9462343
      ]
    },
    {
      "id": 1288,
      "name": "Dangyang",
      "names": {
        "zh": "当阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.82917,
        "lng": 111.78861
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.76861,
        30.80917,
        111.80861,
        30.84917
      ]
    },
    {
      "id": 1289,
      "name": "Columbia",
      "names": {
        "fr": "-Britannique",
        "zh": "哥伦比亚",
        "de": "Kolumbien",
        "ja": "コロンビア",
        "th": "โคลัมเบีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0003117,
        "lng": -81.0331309
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -81.170978,
        33.943248,
        -80.7038161,
        34.131597
      ]
    },
    {
      "id": 1290,
      "name": "Kemerovo",
      "names": {
        "zh": "克麦罗沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.34419845,
        "lng": 86.05996073500756
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        86.059713,
        55.3437304,
        86.0602085,
        55.344668
      ]
    },
    {
      "id": 1291,
      "name": "Herat",
      "names": {
        "zh": "赫拉特",
        "ja": "ヘラート",
        "th": "แรต"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.3508273,
        "lng": 62.2164003
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        62.0564003,
        34.1908273,
        62.3764003,
        34.5108273
      ]
    },
    {
      "id": 1292,
      "name": "Dresden",
      "names": {
        "es": "Dresde",
        "fr": "Dresde",
        "zh": "德累斯顿",
        "it": "Dresda",
        "ja": "ドレスデン",
        "th": "เดรสเดิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0493286,
        "lng": 13.7381437
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        13.5793237,
        50.974937,
        13.9660626,
        51.1777202
      ]
    },
    {
      "id": 1293,
      "name": "Bhavnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.7717445,
        "lng": 72.1391026
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.1390526,
        21.7716945,
        72.1391526,
        21.7717945
      ]
    },
    {
      "id": 1294,
      "name": "Juiz de Fora",
      "names": {
        "it": "Juiz de foork",
        "ja": "ジュエス・デフォーア"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7609533,
        "lng": -43.3501129
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.685934,
        -21.999342,
        -43.146653,
        -21.520535
      ]
    },
    {
      "id": 1295,
      "name": "Luanzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7397547,
        "lng": 118.6974947
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.5374947,
        39.5797547,
        118.8574947,
        39.8997547
      ]
    },
    {
      "id": 1296,
      "name": "Hamadan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.7992888,
        "lng": 48.5152464
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        48.4664536,
        34.7519011,
        48.5916332,
        34.8504338
      ]
    },
    {
      "id": 1297,
      "name": "Novokuznetsk",
      "names": {
        "th": "โนโวคูนซ์เนตสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.74676205,
        "lng": 87.1175506829533
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        87.1168016,
        53.7463843,
        87.1183728,
        53.7471226
      ]
    },
    {
      "id": 1298,
      "name": "Nellore",
      "names": {
        "pt": "Nelore"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 14.424592749999999,
        "lng": 79.98539091930346
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.9839676,
        14.4238843,
        79.986084,
        14.4256889
      ]
    },
    {
      "id": 1299,
      "name": "Chiclayo",
      "names": {
        "zh": "奇克拉约"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.7716146,
        "lng": -79.8387175
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -79.8722949,
        -6.8325976,
        -79.7801182,
        -6.739847
      ]
    },
    {
      "id": 1300,
      "name": "Manchester",
      "names": {
        "zh": "曼彻斯特",
        "ja": "マンチェスター",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4794892,
        "lng": -2.2451148
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.3199185,
        53.3401044,
        -2.1468288,
        53.5445923
      ]
    },
    {
      "id": 1301,
      "name": "Al Hudaydah",
      "names": {
        "ja": "アル・ハイドダ"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.7978677,
        "lng": 42.9544872
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        42.7944872,
        14.6378677,
        43.1144872,
        14.9578677
      ]
    },
    {
      "id": 1302,
      "name": "Amravati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.9307137,
        "lng": 77.7580715
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.7530715,
        20.9257137,
        77.7630715,
        20.9357137
      ]
    },
    {
      "id": 1303,
      "name": "Cabinda",
      "names": {
        "zh": "卡宾达"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -5.5576435,
        "lng": 12.1919468
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        12.0319468,
        -5.7176435,
        12.3519468,
        -5.3976435
      ]
    },
    {
      "id": 1304,
      "name": "Korla",
      "names": {
        "zh": "库尔勒",
        "ja": "コルラ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.738529,
        "lng": 86.19873
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.181212,
        41.725423,
        86.216247,
        41.751633
      ]
    },
    {
      "id": 1305,
      "name": "Huanghua",
      "names": {
        "zh": "黄骅"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.366667,
        "lng": 117.333333
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.0794343,
        38.1541587,
        118.0432888,
        38.6436908
      ]
    },
    {
      "id": 1306,
      "name": "Muntinlupa City",
      "names": {
        "es": "Ciudad de Muntinlupa",
        "zh": "Muntinlupa市",
        "de": "Muntinlupa-Stadt",
        "it": "Città di Muntinlupa",
        "id": "Kota Muntinlupa",
        "th": "เมือง Muntinlupa",
        "pt": "Cidade de muntinlupa"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.389318150000001,
        "lng": 121.04755395656888
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0475299,
        14.3888019,
        121.0475763,
        14.3896902
      ]
    },
    {
      "id": 1307,
      "name": "Londrina",
      "names": {
        "zh": "隆德里纳",
        "ja": "ロンドリーナ",
        "th": "โลนดรีนา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3112878,
        "lng": -51.1595023
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.3213961,
        -23.7799202,
        -50.8760623,
        -23.1721439
      ]
    },
    {
      "id": 1308,
      "name": "Tabuk",
      "names": {
        "zh": "塔布克"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 28.4012536,
        "lng": 36.567049
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        36.407049,
        28.2412536,
        36.727049,
        28.5612536
      ]
    },
    {
      "id": 1309,
      "name": "Heroica Matamoros",
      "names": {
        "fr": "Héroica matamoros",
        "id": "Heroica mataoros",
        "ja": "ヒロイカマタモロス",
        "pt": "Heróica matamoros"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8810158,
        "lng": -97.5064866
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.6664866,
        25.7210158,
        -97.3464866,
        26.0410158
      ]
    },
    {
      "id": 1310,
      "name": "Samarkand",
      "names": {
        "zh": "撒马尔罕",
        "it": "Samarcanda"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.6550017,
        "lng": 66.9756954
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        66.8137707,
        39.6151251,
        67.0683071,
        39.7277007
      ]
    },
    {
      "id": 1311,
      "name": "Xingcheng",
      "names": {
        "zh": "兴城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6203363,
        "lng": 120.7112158
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.5512158,
        40.4603363,
        120.8712158,
        40.7803363
      ]
    },
    {
      "id": 1312,
      "name": "Kaiyuan",
      "names": {
        "zh": "开元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.5434674,
        "lng": 124.0382894
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.8782894,
        42.3834674,
        124.1982894,
        42.7034674
      ]
    },
    {
      "id": 1313,
      "name": "Hannover",
      "names": {
        "fr": "Hanovre",
        "zh": "汉诺威",
        "ja": "ハノーバー",
        "th": "ฮันโนเวอร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3744779,
        "lng": 9.7385532
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        9.60443,
        52.3051373,
        9.9184259,
        52.4543349
      ]
    },
    {
      "id": 1314,
      "name": "Valledupar",
      "names": {
        "zh": "巴耶杜帕尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4626644,
        "lng": -73.25442794394567
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -73.2982458,
        10.422359,
        -73.2233156,
        10.5028168
      ]
    },
    {
      "id": 1315,
      "name": "Fengcheng",
      "names": {
        "zh": "凤城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4500763,
        "lng": 124.0637454
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.5364847,
        40.0341634,
        124.5396422,
        41.0981299
      ]
    },
    {
      "id": 1316,
      "name": "Ajmer",
      "names": {
        "zh": "阿杰梅尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.4691,
        "lng": 74.639
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.591603,
        26.3916605,
        74.6871666,
        26.517713
      ]
    },
    {
      "id": 1317,
      "name": "Ghulja",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9052027,
        "lng": 81.2747797
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        81.0269314,
        43.444772,
        81.4959061,
        44.3010473
      ]
    },
    {
      "id": 1318,
      "name": "Tinnevelly",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 8.7264,
        "lng": 77.70587
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1319,
      "name": "City of Calamba",
      "names": {
        "es": "Ciudad de Calamba",
        "fr": "Ville de Calamba",
        "zh": "卡拉姆邦市",
        "de": "Stadt Calamba",
        "it": "Città di Calamba",
        "id": "Kota Calamba",
        "ja": "カランバ市",
        "th": "เมืองแคลิฟอร์เนีย",
        "pt": "Cidade de Calamba"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2060217,
        "lng": 121.1556496
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0218057,
        14.137703,
        121.2214277,
        14.2662133
      ]
    },
    {
      "id": 1320,
      "name": "Xiangyang",
      "names": {
        "zh": "襄阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.218467,
        "lng": 112.262304
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.8004027,
        31.83874,
        112.5545113,
        32.4764226
      ]
    },
    {
      "id": 1321,
      "name": "Fuding",
      "names": {
        "zh": "福鼎"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2216635,
        "lng": 120.2110277
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.922502,
        26.836785,
        120.8521941,
        27.4432493
      ]
    },
    {
      "id": 1322,
      "name": "An Nasiriyah",
      "names": {
        "es": "Un nasiriyah",
        "fr": "Une nasiriyah",
        "zh": "一个nasiriyah",
        "de": "Ein Nasiriyah",
        "it": "Una nasiriyah",
        "id": "Seorang nasiriyah",
        "ja": "ナシリヤ",
        "th": "nasiriyah",
        "pt": "Uma nasiriyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.0439957,
        "lng": 46.2399386
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        46.0799386,
        30.8839957,
        46.3999386,
        31.2039957
      ]
    },
    {
      "id": 1323,
      "name": "Al Hillah",
      "names": {
        "id": "Al Hilah",
        "ja": "アルヒラ",
        "th": "อัลฮิฮ่า"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.482245,
        "lng": 44.4328131
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        44.2728131,
        32.322245,
        44.5928131,
        32.642245
      ]
    },
    {
      "id": 1324,
      "name": "Ibague",
      "names": {
        "es": "Ibagué",
        "zh": "伊瓦格",
        "id": "Ibagué",
        "pt": "Ibagué"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4386033,
        "lng": -75.2108857
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.5246959,
        4.2578563,
        -74.9667451,
        4.6993257
      ]
    },
    {
      "id": 1325,
      "name": "Ryazan",
      "names": {
        "zh": "梁赞",
        "de": "Rjasan"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.61746,
        "lng": 39.724237
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        39.545796,
        54.524917,
        39.897954,
        54.811439
      ]
    },
    {
      "id": 1326,
      "name": "Kassala",
      "names": {
        "zh": "卡萨拉",
        "ja": "カッサラー"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.436173,
        "lng": 36.40045
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        36.369537,
        15.399256,
        36.427239,
        15.482017
      ]
    },
    {
      "id": 1327,
      "name": "Chang'an",
      "names": {
        "zh": "长安",
        "ja": "長安",
        "th": "ช้าง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1598597,
        "lng": 108.9023547
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.6364654,
        33.7925623,
        109.2246018,
        34.2997487
      ]
    },
    {
      "id": 1328,
      "name": "Kerman",
      "names": {
        "zh": "克尔曼"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.2924085,
        "lng": 57.0645647
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        56.9366357,
        30.1924405,
        57.1853953,
        30.3389783
      ]
    },
    {
      "id": 1329,
      "name": "Koto-ku",
      "names": {
        "zh": "科托-ku",
        "ja": "江東-ku",
        "th": "ขิม-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6727747,
        "lng": 139.8169621
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7667248,
        35.5886489,
        139.8485458,
        35.7080921
      ]
    },
    {
      "id": 1330,
      "name": "Poznan",
      "names": {
        "zh": "波兹南",
        "ja": "ポズナン",
        "th": "พอซนัน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4082663,
        "lng": 16.9335199
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        16.7315878,
        52.2919238,
        17.0717065,
        52.5093282
      ]
    },
    {
      "id": 1331,
      "name": "Aksu",
      "names": {
        "zh": "阿克苏"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1677575,
        "lng": 80.2582136
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        78.0264208,
        39.4637646,
        84.0860294,
        42.6455798
      ]
    },
    {
      "id": 1332,
      "name": "Salta",
      "names": {
        "zh": "萨尔塔",
        "ja": "サルタ",
        "th": "ซัลตา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.7892946,
        "lng": -65.4103194
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -65.4791203,
        -24.8734901,
        -65.3406306,
        -24.7108058
      ]
    },
    {
      "id": 1333,
      "name": "Astrakhan",
      "names": {
        "zh": "阿斯特拉罕",
        "de": "Astrachan",
        "ja": "アストラカン",
        "th": "มณฑลแอซทระแคน"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 46.3498308,
        "lng": 48.0326203
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        47.8711165,
        46.259716,
        48.201888,
        46.4708482
      ]
    },
    {
      "id": 1334,
      "name": "Wuhai",
      "names": {
        "zh": "乌海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.677598,
        "lng": 106.825561
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.813907,
        39.668592,
        106.837216,
        39.686604
      ]
    },
    {
      "id": 1335,
      "name": "Mingguang",
      "names": {
        "zh": "明光"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7799084,
        "lng": 117.986473
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.966473,
        32.7599084,
        118.006473,
        32.7999084
      ]
    },
    {
      "id": 1336,
      "name": "Naberezhnyye Chelny",
      "names": {
        "fr": "Naberezhnnyye Chelny",
        "de": "Naberezhnnyye chelny"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.695275,
        "lng": 52.318596
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        52.283638,
        55.677776,
        52.338196,
        55.714138
      ]
    },
    {
      "id": 1337,
      "name": "Antwerp",
      "names": {
        "es": "Amberes",
        "fr": "D'Anvers",
        "zh": "安特卫普",
        "de": "Antwerpen",
        "it": "Anversa",
        "id": "Antwerpen",
        "ja": "アントワープ",
        "th": "แอนต์เวิร์ป",
        "pt": "Antuérpia"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.2211097,
        "lng": 4.3997081
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.2175769,
        51.1432868,
        4.4979684,
        51.3776356
      ]
    },
    {
      "id": 1338,
      "name": "Touba",
      "names": {
        "zh": "图巴"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.864559,
        "lng": -15.876047
      },
      "country": "Senegal",
      "importance": 3,
      "bbox": [
        -15.9643442,
        14.8015117,
        -15.8123821,
        14.9299971
      ]
    },
    {
      "id": 1339,
      "name": "Ardabil",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.2515581,
        "lng": 48.2972269
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        48.2432302,
        38.188912,
        48.3378281,
        38.2949403
      ]
    },
    {
      "id": 1340,
      "name": "Bikaner",
      "names": {
        "zh": "比卡内尔",
        "th": "พิฆเนร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.0159286,
        "lng": 73.3171367
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.1571367,
        27.8559286,
        73.4771367,
        28.1759286
      ]
    },
    {
      "id": 1341,
      "name": "Lyon",
      "names": {
        "zh": "里昂",
        "it": "Lione",
        "ja": "リヨン",
        "th": "ลียง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7578137,
        "lng": 4.8320114
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        4.7718134,
        45.7073666,
        4.8983774,
        45.8082628
      ]
    },
    {
      "id": 1342,
      "name": "Agartala",
      "names": {
        "zh": "阿加尔塔拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.8312377,
        "lng": 91.2823821
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        91.1223821,
        23.6712377,
        91.4423821,
        23.9912377
      ]
    },
    {
      "id": 1343,
      "name": "Ndola",
      "names": {
        "zh": "恩多拉"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.9693056,
        "lng": 28.6365894
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        28.4765894,
        -13.1293056,
        28.7965894,
        -12.8093056
      ]
    },
    {
      "id": 1344,
      "name": "Himeji",
      "names": {
        "zh": "姬路",
        "ja": "姫路",
        "th": "ฮิเมจิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8153529,
        "lng": 134.6854793
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.3446894,
        34.4691672,
        134.813635,
        35.094363
      ]
    },
    {
      "id": 1345,
      "name": "Tultitlan de Mariano Escobedo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6355042,
        "lng": -99.1803876
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1853876,
        19.6305042,
        -99.1753876,
        19.6405042
      ]
    },
    {
      "id": 1346,
      "name": "Villavicencio",
      "names": {
        "zh": "比亚维森西奥"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.1347644,
        "lng": -73.6201517
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -73.7001836,
        4.0459535,
        -73.5310114,
        4.1693285
      ]
    },
    {
      "id": 1347,
      "name": "Ailan Mubage",
      "names": {
        "fr": "Mubage ailan",
        "zh": "艾兰粉刷",
        "ja": "イランのムーグ",
        "th": "กูว",
        "pt": "Mubagem de Ailan"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.90845,
        "lng": 81.33299
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        81.31299,
        43.88845,
        81.35299,
        43.92845
      ]
    },
    {
      "id": 1348,
      "name": "Bandar `Abbas",
      "names": {
        "zh": "Bandar` ABBAS",
        "ja": "バンダル `ABBAS"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 27.2117283,
        "lng": 56.2571116
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        56.2521116,
        27.2067283,
        56.2621116,
        27.2167283
      ]
    },
    {
      "id": 1349,
      "name": "Bac Ninh",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 21.18608,
        "lng": 106.07632
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.05632,
        21.16608,
        106.09632,
        21.20608
      ]
    },
    {
      "id": 1350,
      "name": "Ciudad Apodaca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7816542,
        "lng": -100.1887501
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.3487501,
        25.6216542,
        -100.0287501,
        25.9416542
      ]
    },
    {
      "id": 1351,
      "name": "Santa Teresa del Tuy",
      "names": {
        "zh": "圣特雷萨del Tuy",
        "ja": "サンタテレサデルチュー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.23409,
        "lng": -66.66308
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.68308,
        10.21409,
        -66.64308,
        10.25409
      ]
    },
    {
      "id": 1352,
      "name": "Maipu",
      "names": {
        "zh": "迈普"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5094409,
        "lng": -70.756182
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.9299813,
        -33.5718464,
        -70.7183638,
        -33.458209
      ]
    },
    {
      "id": 1353,
      "name": "Penza",
      "names": {
        "zh": "奔萨"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.1953477,
        "lng": 45.0190437
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        44.8297585,
        53.0990157,
        45.230836,
        53.2890646
      ]
    },
    {
      "id": 1354,
      "name": "Soacha",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.57937,
        "lng": -74.21682
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.23682,
        4.55937,
        -74.19682,
        4.59937
      ]
    },
    {
      "id": 1355,
      "name": "Port Said",
      "names": {
        "es": "Puerto dijo",
        "fr": "Dit Port",
        "zh": "港口说",
        "de": "Hafen sagte",
        "it": "Porto detto",
        "id": "Kata pelabuhan",
        "ja": "港は言った",
        "th": "พอร์ตกล่าวว่า",
        "pt": "Porto disse"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.259825,
        "lng": 32.3005799
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        32.2955799,
        31.254825,
        32.3055799,
        31.264825
      ]
    },
    {
      "id": 1356,
      "name": "Yucheng",
      "names": {
        "zh": "禹城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.933782,
        "lng": 116.6329789
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.3726743,
        36.6704399,
        116.7436219,
        37.2008331
      ]
    },
    {
      "id": 1357,
      "name": "Meknes",
      "names": {
        "fr": "Meknès",
        "zh": "梅克内斯"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.89352,
        "lng": -5.54727
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.56727,
        33.87352,
        -5.52727,
        33.91352
      ]
    },
    {
      "id": 1358,
      "name": "Arak",
      "names": {
        "zh": "阿拉克"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.0862719,
        "lng": 49.6893884
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        49.6082067,
        34.0429394,
        49.7970771,
        34.131167
      ]
    },
    {
      "id": 1359,
      "name": "Pohang",
      "names": {
        "zh": "浦项",
        "id": "Pohang-si",
        "ja": "浦項"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.0189315,
        "lng": 129.3429384
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        128.991849,
        35.8415079,
        129.6010779,
        36.3336305
      ]
    },
    {
      "id": 1360,
      "name": "Longzhou",
      "names": {
        "zh": "龙州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.0335743,
        "lng": 119.1743874
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.1084711,
        28.9197644,
        119.1883616,
        29.0553243
      ]
    },
    {
      "id": 1361,
      "name": "Anda",
      "names": {
        "zh": "安达",
        "id": "Andari",
        "th": "อันดา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.4114331,
        "lng": 125.3285572
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.1685572,
        46.2514331,
        125.4885572,
        46.5714331
      ]
    },
    {
      "id": 1362,
      "name": "Jinghong",
      "names": {
        "zh": "景洪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.009262,
        "lng": 100.79369
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        100.773397145,
        21.9900515892,
        100.814737445,
        22.018297969
      ]
    },
    {
      "id": 1363,
      "name": "Sheffield",
      "names": {
        "zh": "谢菲尔德",
        "ja": "シェフィールド",
        "th": "เชฟฟิลด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3806626,
        "lng": -1.4702278
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8014715,
        53.3045116,
        -1.3246685,
        53.5031042
      ]
    },
    {
      "id": 1364,
      "name": "Utsunomiya",
      "names": {
        "zh": "宇都宫",
        "ja": "宇都宮"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5549677,
        "lng": 139.8828776
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        139.742867,
        36.463971,
        140.010764,
        36.730081
      ]
    },
    {
      "id": 1365,
      "name": "Suez",
      "names": {
        "zh": "苏伊士",
        "ja": "スエズ",
        "th": "คลองสุเอซ"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.974498,
        "lng": 32.537086
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        32.377086,
        29.814498,
        32.697086,
        30.134498
      ]
    },
    {
      "id": 1366,
      "name": "Nuremberg",
      "names": {
        "zh": "纽伦堡",
        "de": "Nürnberg",
        "it": "Norimberga",
        "ja": "ニュルンベルク",
        "th": "นูเรมเบิร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.453872,
        "lng": 11.077298
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.9887326,
        49.3314339,
        11.2825957,
        49.5407534
      ]
    },
    {
      "id": 1367,
      "name": "Liverpool",
      "names": {
        "zh": "利物浦",
        "ja": "リバプール",
        "th": "ลิเวอร์พูล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4071991,
        "lng": -2.99168
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0191726,
        53.3115427,
        -2.8180003,
        53.4749885
      ]
    },
    {
      "id": 1368,
      "name": "Mesa",
      "names": {
        "zh": "梅萨",
        "ja": "メサ",
        "th": "เมซ่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4151005,
        "lng": -111.831455
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.8944023,
        33.277624,
        -111.5805913,
        33.513376
      ]
    },
    {
      "id": 1369,
      "name": "Surrey",
      "names": {
        "zh": "萨里",
        "ja": "サリー",
        "th": "เซอร์เรย์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.1913033,
        "lng": -122.849143
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.957166,
        49.0020767,
        -122.6791093,
        49.220825
      ]
    },
    {
      "id": 1370,
      "name": "Heshan",
      "names": {
        "zh": "鹤山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7687622,
        "lng": 112.9591941
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.7991941,
        22.6087622,
        113.1191941,
        22.9287622
      ]
    },
    {
      "id": 1371,
      "name": "Zhoushan",
      "names": {
        "zh": "舟山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.9873344,
        "lng": 122.2030363
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.5062423,
        29.5399694,
        123.1682304,
        30.9103907
      ]
    },
    {
      "id": 1372,
      "name": "Ujjain",
      "names": {
        "zh": "乌贾因"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.1885131,
        "lng": 75.7716562
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.6116562,
        23.0285131,
        75.9316562,
        23.3485131
      ]
    },
    {
      "id": 1373,
      "name": "Jiaji",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.242508,
        "lng": 110.4575005
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.4175005,
        19.202508,
        110.4975005,
        19.282508
      ]
    },
    {
      "id": 1374,
      "name": "Santa Marta",
      "names": {
        "zh": "圣玛尔塔",
        "ja": "サンタマルタ",
        "th": "ซานตามาร์ตา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 11.2320944,
        "lng": -74.1950916
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -74.2418012,
        11.0788366,
        -74.1399854,
        11.2592511
      ]
    },
    {
      "id": 1375,
      "name": "Joinvile",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.460358,
        "lng": -49.618027
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.618968,
        -25.462057,
        -49.615975,
        -25.457558
      ]
    },
    {
      "id": 1376,
      "name": "Beining",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.59556,
        "lng": 121.79278
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.77278,
        41.57556,
        121.81278,
        41.61556
      ]
    },
    {
      "id": 1377,
      "name": "Hai Duong",
      "names": {
        "zh": "海都",
        "ja": "ハイ・ドゥーン"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.9474792,
        "lng": 106.3302093
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.3301593,
        20.9474292,
        106.3302593,
        20.9475292
      ]
    },
    {
      "id": 1378,
      "name": "Carrefour",
      "names": {
        "zh": "家乐福",
        "ja": "カルフール",
        "th": "คาร์ฟูร์"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 34.0152177,
        "lng": -6.8461064
      },
      "country": "Haiti",
      "importance": 3,
      "bbox": [
        -6.8461564,
        34.0151677,
        -6.8460564,
        34.0152677
      ]
    },
    {
      "id": 1379,
      "name": "Maturin",
      "names": {
        "zh": "马图林"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.7422774,
        "lng": -63.1888854
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -63.3560085,
        9.6252384,
        -63.0753315,
        9.8562309
      ]
    },
    {
      "id": 1380,
      "name": "Homyel'",
      "names": {
        "es": "Homyel '",
        "fr": "Homyel '",
        "de": "Homyel '",
        "it": "Homyel '",
        "id": "Homyel '",
        "ja": "homyel '",
        "th": "homyel '",
        "pt": "Homyel '"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.4345,
        "lng": 30.9754
      },
      "country": "Belarus",
      "importance": 1,
      "bbox": [
        30.9554,
        52.4145,
        30.9954,
        52.4545
      ]
    },
    {
      "id": 1381,
      "name": "Ananindeua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.374035,
        "lng": -48.4016623
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.4400663,
        -1.465771,
        -48.331434,
        -1.2210259
      ]
    },
    {
      "id": 1382,
      "name": "Yanji",
      "names": {
        "zh": "延吉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9047508,
        "lng": 129.50441
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.34441,
        42.7447508,
        129.66441,
        43.0647508
      ]
    },
    {
      "id": 1383,
      "name": "Yicheng",
      "names": {
        "zh": "宜城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.7210346,
        "lng": 112.2527375
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9421613,
        31.4321757,
        112.7464218,
        31.9083289
      ]
    },
    {
      "id": 1384,
      "name": "Ulhasnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.2178804,
        "lng": 73.1630778
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.1580778,
        19.2128804,
        73.1680778,
        19.2228804
      ]
    },
    {
      "id": 1385,
      "name": "Lipetsk",
      "names": {
        "zh": "利佩茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.607635,
        "lng": 39.590026
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        39.535999,
        52.569637,
        39.710445,
        52.654724
      ]
    },
    {
      "id": 1386,
      "name": "Al `Amarah",
      "names": {
        "zh": "Al`amarah",
        "de": "Al` Amarah",
        "ja": "Al` Amarah",
        "th": "อัล `Amarah",
        "pt": "Al 'Amarah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.1067678,
        "lng": 37.1059552
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        37.0859552,
        36.0867678,
        37.1259552,
        36.1267678
      ]
    },
    {
      "id": 1387,
      "name": "Choloma",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 15.6127426,
        "lng": -87.950694
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -88.0925177,
        15.5349398,
        -87.7708407,
        15.7600175
      ]
    },
    {
      "id": 1388,
      "name": "Sevastopol",
      "names": {
        "es": "Sebastopol",
        "fr": "Sébastopol",
        "zh": "塞瓦斯托波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 44.6054434,
        "lng": 33.5220842
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        33.3779569,
        44.5122525,
        33.6106217,
        44.7014019
      ]
    },
    {
      "id": 1389,
      "name": "Encheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.1834738,
        "lng": 112.3115666
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.2715666,
        22.1434738,
        112.3515666,
        22.2234738
      ]
    },
    {
      "id": 1390,
      "name": "Aden",
      "names": {
        "es": "Adén",
        "zh": "亚丁",
        "ja": "アデン",
        "th": "เอเดน"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 12.789585,
        "lng": 45.028504
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        44.9090399,
        12.7508947,
        45.0981259,
        12.9412176
      ]
    },
    {
      "id": 1391,
      "name": "Kitwe",
      "names": {
        "zh": "基特韦"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.8104186,
        "lng": 28.2068361
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        28.0468361,
        -12.9704186,
        28.3668361,
        -12.6504186
      ]
    },
    {
      "id": 1392,
      "name": "Matsuyama",
      "names": {
        "zh": "松山",
        "ja": "松山",
        "th": "มัตซุยา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.8395188,
        "lng": 132.7653521
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        132.4464404,
        33.687358,
        132.926804,
        34.122797
      ]
    },
    {
      "id": 1393,
      "name": "Jhansi",
      "names": {
        "zh": "占西"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4502464,
        "lng": 78.5800085
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.4964822,
        25.4154092,
        78.627436,
        25.4858199
      ]
    },
    {
      "id": 1394,
      "name": "Kashgar",
      "names": {
        "zh": "喀什",
        "ja": "カシュガル",
        "th": "คัชการ์"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.4665981,
        "lng": 75.9900135
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        75.8193216,
        39.4083564,
        76.5875545,
        39.6261178
      ]
    },
    {
      "id": 1395,
      "name": "Palm Bay",
      "names": {
        "es": "Palmita",
        "fr": "Palmier",
        "zh": "棕榈湾",
        "de": "Palmbucht",
        "it": "Baia di palme",
        "id": "Teluk telapak tangan",
        "ja": "パームベイ",
        "pt": "Baía"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9946969,
        "lng": -80.6366144
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.7535564,
        27.824029,
        -80.5409449,
        28.0638216
      ]
    },
    {
      "id": 1396,
      "name": "Pingtung",
      "names": {
        "zh": "屏东"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.679041,
        "lng": 120.495772
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.460856,
        22.648081,
        120.517386,
        22.699077
      ]
    },
    {
      "id": 1397,
      "name": "Lapu-Lapu City",
      "names": {
        "es": "Ciudad de Lapu-Lapu",
        "fr": "La Lapu-Lapu City",
        "zh": "拉普 - 拉普市",
        "de": "Lapu-Lapu-Stadt",
        "id": "Kota Lapu-Lapu",
        "ja": "ラプラパ都市",
        "th": "เมือง Lapu-Lapu",
        "pt": "Cidade de Lapu-Lapu"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3166846,
        "lng": 123.8909945
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.8909445,
        10.3166346,
        123.8910445,
        10.3167346
      ]
    },
    {
      "id": 1398,
      "name": "Monteria",
      "names": {
        "es": "Montería",
        "zh": "蒙特里亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.75,
        "lng": -75.8833333
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.8833833,
        8.74995,
        -75.8832833,
        8.75005
      ]
    },
    {
      "id": 1399,
      "name": "Ichikawa",
      "names": {
        "zh": "市川",
        "ja": "市川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.729412,
        "lng": 139.928568
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.885527,
        35.6524758,
        139.976841,
        35.775682
      ]
    },
    {
      "id": 1400,
      "name": "Imus",
      "names": {
        "zh": "伊穆斯",
        "th": "มัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.42972,
        "lng": 120.93667
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.91667,
        14.40972,
        120.95667,
        14.44972
      ]
    },
    {
      "id": 1401,
      "name": "Davangere",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4679807,
        "lng": 75.9195682
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.9145682,
        14.4629807,
        75.9245682,
        14.4729807
      ]
    },
    {
      "id": 1402,
      "name": "Jammu",
      "names": {
        "zh": "查谟",
        "ja": "ジャンムー",
        "th": "ชัมมู"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 32.7185614,
        "lng": 74.8580917
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.6980917,
        32.5585614,
        75.0180917,
        32.8785614
      ]
    },
    {
      "id": 1403,
      "name": "Yiwu",
      "names": {
        "zh": "义乌",
        "ja": "義烏",
        "th": "อี้หวู่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.3089226,
        "lng": 120.0702728
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.0702228,
        29.3088726,
        120.0703228,
        29.3089726
      ]
    },
    {
      "id": 1404,
      "name": "Belas",
      "names": {
        "zh": "贝拉什"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.1033375,
        "lng": 13.174545441548965
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        12.9923898,
        -9.3458084,
        13.3364445,
        -8.8607807
      ]
    },
    {
      "id": 1405,
      "name": "Talatona",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.91783,
        "lng": 13.18562
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.16562,
        -8.93783,
        13.20562,
        -8.89783
      ]
    },
    {
      "id": 1406,
      "name": "Ile-Ife",
      "names": {
        "zh": "异亮氨酸-生命",
        "ja": "イル・ife"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.4824,
        "lng": 4.56032
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 1407,
      "name": "Kirov",
      "names": {
        "zh": "基洛夫",
        "th": "คิรอฟ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.6035661,
        "lng": 49.6666241
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        49.3434343,
        58.482039,
        49.8084089,
        58.7022608
      ]
    },
    {
      "id": 1408,
      "name": "Jiaxing",
      "names": {
        "zh": "嘉兴",
        "ja": "嘉興",
        "th": "เจียซิ่ง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7474425,
        "lng": 120.7510971
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.2911826,
        30.2574895,
        121.5062556,
        31.0324329
      ]
    },
    {
      "id": 1409,
      "name": "Mykolaiv",
      "names": {
        "ja": "ムィコラーイウ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.9758615,
        "lng": 31.9939666
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        31.8804797,
        46.818256,
        32.1351928,
        47.0475069
      ]
    },
    {
      "id": 1410,
      "name": "Provo",
      "names": {
        "zh": "普罗沃",
        "ja": "プロボ",
        "th": "โพรโว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2337289,
        "lng": -111.6587085
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.740962,
        40.185799,
        -111.5368724,
        40.328801
      ]
    },
    {
      "id": 1411,
      "name": "Meicheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6362399,
        "lng": 116.5737617
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.5337617,
        30.5962399,
        116.6137617,
        30.6762399
      ]
    },
    {
      "id": 1412,
      "name": "Niteroi",
      "names": {
        "zh": "尼泰罗伊",
        "pt": "Niterói"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8884,
        "lng": -43.1147
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.1390851,
        -22.9896943,
        -42.9430764,
        -22.8537916
      ]
    },
    {
      "id": 1413,
      "name": "Matsudo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7879371,
        "lng": 139.903177
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.879385,
        35.746721,
        140.001359,
        35.849769
      ]
    },
    {
      "id": 1414,
      "name": "Sukkur",
      "names": {
        "zh": "苏库尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 27.7004292,
        "lng": 68.8664623
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.8614623,
        27.6954292,
        68.8714623,
        27.7054292
      ]
    },
    {
      "id": 1415,
      "name": "Oujda-Angad",
      "names": {
        "zh": "乌季达-angad"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.689455,
        "lng": -1.911855
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -1.966294,
        34.640786,
        -1.850833,
        34.745161
      ]
    },
    {
      "id": 1416,
      "name": "Taozhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8872392,
        "lng": 119.4589792
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.4041026,
        30.8365275,
        119.5596801,
        30.9796146
      ]
    },
    {
      "id": 1417,
      "name": "Higashi-osaka",
      "names": {
        "zh": "东大阪",
        "ja": "東大阪",
        "th": "Higashi-โอซาก้า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.678147,
        "lng": 135.597728
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.557036,
        34.6321858,
        135.6790506,
        34.7042466
      ]
    },
    {
      "id": 1418,
      "name": "Johor Bahru",
      "names": {
        "zh": "柔佛州柔佛州",
        "ja": "ジョホールバール",
        "th": "ยะโฮร์บาห์รู"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 1.4526275,
        "lng": 103.7692542
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        103.7692042,
        1.4525775,
        103.7693042,
        1.4526775
      ]
    },
    {
      "id": 1419,
      "name": "Worcester",
      "names": {
        "zh": "伍斯特",
        "ja": "ウスター",
        "th": "เวอร์ซ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2625621,
        "lng": -71.8018877
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.8840431,
        42.2100397,
        -71.7312371,
        42.341187
      ]
    },
    {
      "id": 1420,
      "name": "Hongjiang",
      "names": {
        "zh": "洪江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1152971,
        "lng": 110.0073244
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.8585559,
        27.0621468,
        110.0683737,
        27.188399
      ]
    },
    {
      "id": 1421,
      "name": "Chimbote",
      "names": {
        "zh": "钦博特"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -9.0744966,
        "lng": -78.593609
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -78.6377402,
        -9.1468163,
        -78.1821524,
        -8.65292
      ]
    },
    {
      "id": 1422,
      "name": "Duisburg",
      "names": {
        "zh": "杜伊斯堡",
        "ja": "ジュイスブルク",
        "th": "สบูร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.434999,
        "lng": 6.759562
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.6256311,
        51.3333827,
        6.8302507,
        51.5600691
      ]
    },
    {
      "id": 1423,
      "name": "Qaraghandy",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 49.8161282,
        "lng": 73.1026622
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        72.9752162,
        49.6828225,
        73.2773792,
        49.9942742
      ]
    },
    {
      "id": 1424,
      "name": "Ixtapaluca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3129326,
        "lng": -98.8840481
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.0440481,
        19.1529326,
        -98.7240481,
        19.4729326
      ]
    },
    {
      "id": 1425,
      "name": "Dengtacun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.92989,
        "lng": 125.12288
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1426,
      "name": "Zhijiang",
      "names": {
        "zh": "江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4283835,
        "lng": 111.7546868
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.436218,
        30.2696622,
        112.0439601,
        30.663246
      ]
    },
    {
      "id": 1427,
      "name": "Chengjiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.3993336,
        "lng": 114.0806441
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.0406441,
        35.3593336,
        114.1206441,
        35.4393336
      ]
    },
    {
      "id": 1428,
      "name": "Beipiao",
      "names": {
        "zh": "北票市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8000713,
        "lng": 120.7655332
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.2611831,
        41.3928397,
        121.2962625,
        42.2779104
      ]
    },
    {
      "id": 1429,
      "name": "Suoluntun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2279,
        "lng": 124.84323
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.82323,
        45.2079,
        124.86323,
        45.2479
      ]
    },
    {
      "id": 1430,
      "name": "Murrieta",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.560832,
        "lng": -117.210656
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2800431,
        33.5134337,
        -117.0842009,
        33.6418181
      ]
    },
    {
      "id": 1431,
      "name": "Kota Bharu",
      "names": {
        "zh": "哥打巴鲁",
        "ja": "コタ・バル"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 6.12584,
        "lng": 102.2399785
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        102.2217748,
        6.0622192,
        102.3202657,
        6.1674037
      ]
    },
    {
      "id": 1432,
      "name": "Ciudad Lopez Mateos",
      "names": {
        "es": "Ciudad López Mateos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.565135,
        "lng": -99.249273
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.33615,
        19.517971,
        -99.21158,
        19.612214
      ]
    },
    {
      "id": 1433,
      "name": "Celaya",
      "names": {
        "zh": "塞拉亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.521788,
        "lng": -100.8140458
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.8548759,
        20.4847882,
        -100.7757807,
        20.5542821
      ]
    },
    {
      "id": 1434,
      "name": "Vinh",
      "names": {
        "zh": "永"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 18.6698528,
        "lng": 105.6840371
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.6338632,
        18.6393193,
        105.7689641,
        18.7640168
      ]
    },
    {
      "id": 1435,
      "name": "Duyun",
      "names": {
        "zh": "都匀"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.2631518,
        "lng": 107.5144965
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.3544965,
        26.1031518,
        107.6744965,
        26.4231518
      ]
    },
    {
      "id": 1436,
      "name": "Los Mochis",
      "names": {
        "ja": "ロスモチス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7928058,
        "lng": -108.990188
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.0463376,
        25.7289293,
        -108.9382048,
        25.8395287
      ]
    },
    {
      "id": 1437,
      "name": "Nyala",
      "names": {
        "zh": "尼亚拉"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.0505098,
        "lng": 24.8795115
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        24.7195115,
        11.8905098,
        25.0395115,
        12.2105098
      ]
    },
    {
      "id": 1438,
      "name": "Larkana",
      "names": {
        "zh": "拉卡纳"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 27.560111,
        "lng": 68.210115
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.173375,
        27.524408,
        68.24024,
        27.59216
      ]
    },
    {
      "id": 1439,
      "name": "Nishinomiya-hama",
      "names": {
        "zh": "西宫-哈马",
        "ja": "西宮-hama"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.74275,
        "lng": 135.344537
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.291197,
        34.682927,
        135.384521,
        34.793078
      ]
    },
    {
      "id": 1440,
      "name": "`Ajman",
      "names": {
        "zh": "`阿治曼",
        "th": "`อัจมาน"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.3936557,
        "lng": 55.4451432
      },
      "country": "United Arab Emirates",
      "importance": 1,
      "bbox": [
        55.2851432,
        25.2336557,
        55.6051432,
        25.5536557
      ]
    },
    {
      "id": 1441,
      "name": "Cheboksary",
      "names": {
        "zh": "切伯克萨瑞"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1307195,
        "lng": 47.2449597
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        47.0963028,
        56.0513217,
        47.427214,
        56.1567102
      ]
    },
    {
      "id": 1442,
      "name": "Yuanping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7308094,
        "lng": 112.6994958
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.2790983,
        38.5872419,
        113.0857163,
        39.1522505
      ]
    },
    {
      "id": 1443,
      "name": "Toulouse",
      "names": {
        "zh": "图卢兹",
        "ja": "トゥールーズ",
        "th": "ตูลูส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6044622,
        "lng": 1.4442469
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        1.3503956,
        43.532654,
        1.5153795,
        43.668708
      ]
    },
    {
      "id": 1444,
      "name": "Edinburgh",
      "names": {
        "es": "Edimburgo",
        "zh": "爱丁堡",
        "it": "Edimburgo",
        "ja": "エジンバラ",
        "th": "เอดินบะระ",
        "pt": "Edimburgo"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.9533456,
        "lng": -3.1883749
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.4495326,
        55.8187919,
        -3.0749528,
        56.0040837
      ]
    },
    {
      "id": 1445,
      "name": "Belgaum",
      "names": {
        "zh": "贝尔高姆"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.8572666,
        "lng": 74.5069343
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.3469343,
        15.6972666,
        74.6669343,
        16.0172666
      ]
    },
    {
      "id": 1446,
      "name": "Tula",
      "names": {
        "zh": "图拉",
        "ja": "トゥーラ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.1930321,
        "lng": 37.61754
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        37.4956298,
        54.0618514,
        37.7415669,
        54.2994766
      ]
    },
    {
      "id": 1447,
      "name": "Shahe",
      "names": {
        "zh": "沙河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8616265,
        "lng": 114.5112915
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.8571386,
        36.8295761,
        114.6652528,
        37.0489519
      ]
    },
    {
      "id": 1448,
      "name": "Serra",
      "names": {
        "zh": "塞拉",
        "ja": "セラ",
        "th": "ร่า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.1252961,
        "lng": -40.3064477
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.4361895,
        -20.2606937,
        -40.171946,
        -20.0132338
      ]
    },
    {
      "id": 1449,
      "name": "Gaoping",
      "names": {
        "zh": "高平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7984314,
        "lng": 112.9229854
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.7120208,
        35.6500201,
        113.1563038,
        35.9872819
      ]
    },
    {
      "id": 1450,
      "name": "Greenville",
      "names": {
        "zh": "格林维尔",
        "ja": "グリーンヴィル",
        "th": "กรีนวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.851354,
        "lng": -82.3984882
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.4349118,
        34.7665442,
        -82.2868906,
        34.8925978
      ]
    },
    {
      "id": 1451,
      "name": "Gulbarga",
      "names": {
        "zh": "古尔伯加"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.3310003,
        "lng": 76.8334228
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.6734228,
        17.1710003,
        76.9934228,
        17.4910003
      ]
    },
    {
      "id": 1452,
      "name": "Dunhua",
      "names": {
        "zh": "敦化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.3686205,
        "lng": 128.2267987
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        127.4633713,
        42.7051212,
        129.212003,
        44.2046278
      ]
    },
    {
      "id": 1453,
      "name": "Brookhaven",
      "names": {
        "zh": "布鲁克海文",
        "th": "บรุกเฮเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7792653,
        "lng": -72.9153827
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.938077,
        40.7573481,
        -72.881947,
        40.802309
      ]
    },
    {
      "id": 1454,
      "name": "Az Zarqa'",
      "names": {
        "es": "AZ Zarqa '",
        "fr": "Az zarqa '",
        "de": "Az zarqa '",
        "it": "AZ ZARQA '",
        "id": "Az zarqa '",
        "ja": "zarqa '",
        "th": "AZ Zarqa '",
        "pt": "AZ ZARQA '"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.8578602,
        "lng": 36.85983238787004
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.877491,
        31.5006395,
        37.7975097,
        32.2267759
      ]
    },
    {
      "id": 1455,
      "name": "Sylhet",
      "names": {
        "zh": "锡尔赫特"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.89922,
        "lng": 91.8685271
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        91.7085271,
        24.73922,
        92.0285271,
        25.05922
      ]
    },
    {
      "id": 1456,
      "name": "Wichita",
      "names": {
        "zh": "威奇托",
        "ja": "ウィチタ",
        "th": "วิชิตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.687288,
        "lng": -97.338469
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.734362,
        37.489018,
        -97.152912,
        37.805297
      ]
    },
    {
      "id": 1457,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6529143,
        "lng": -83.5378173
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.694237,
        41.580266,
        -83.4546108,
        41.7328519
      ]
    },
    {
      "id": 1458,
      "name": "Kaihua",
      "names": {
        "zh": "开化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3720079,
        "lng": 104.2477945
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.1215318,
        23.2926348,
        104.2666382,
        23.3881839
      ]
    },
    {
      "id": 1459,
      "name": "Jamnagar",
      "names": {
        "zh": "贾姆讷格尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.4732415,
        "lng": 70.0552102
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        70.0234784,
        22.3998254,
        70.128783,
        22.5064251
      ]
    },
    {
      "id": 1460,
      "name": "Fuyuan",
      "names": {
        "zh": "富源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.3560283,
        "lng": 134.2891391
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        134.1291391,
        48.1960283,
        134.4491391,
        48.5160283
      ]
    },
    {
      "id": 1461,
      "name": "Dhulia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.892892,
        "lng": 74.772323
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.750464,
        20.874691,
        74.797185,
        20.941505
      ]
    },
    {
      "id": 1462,
      "name": "Gaya",
      "names": {
        "zh": "格雅",
        "ja": "ガヤー"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.7964355,
        "lng": 85.0079563
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.8479563,
        24.6364355,
        85.1679563,
        24.9564355
      ]
    },
    {
      "id": 1463,
      "name": "Florianopolis",
      "names": {
        "zh": "佛洛莉亚诺波里斯",
        "ja": "フロリアノーポリス",
        "th": "โฟลเรียนอโปลิส",
        "pt": "Florianópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.5973002,
        "lng": -48.5496098
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -48.613,
        -27.847,
        -48.3585929,
        -27.3782759
      ]
    },
    {
      "id": 1464,
      "name": "Chiniot",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.717716,
        "lng": 72.98343
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.952891,
        31.697795,
        73.014255,
        31.740612
      ]
    },
    {
      "id": 1465,
      "name": "Jiannan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.3413886,
        "lng": 104.1979778
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.0379778,
        31.1813886,
        104.3579778,
        31.5013886
      ]
    },
    {
      "id": 1466,
      "name": "Oita",
      "names": {
        "zh": "大分",
        "ja": "大分",
        "th": "โออิตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.23273305,
        "lng": 131.60603394530006
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        131.6046885,
        33.231971,
        131.6074,
        33.2335196
      ]
    },
    {
      "id": 1467,
      "name": "Kaliningrad",
      "names": {
        "es": "Kaliningrado",
        "zh": "加里宁格勒",
        "ja": "カリーニングラード",
        "th": "คาลินินกราด",
        "pt": "Kaliningrado"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.71204,
        "lng": 20.5093499
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        20.5092999,
        54.71199,
        20.5093999,
        54.71209
      ]
    },
    {
      "id": 1468,
      "name": "Nangong",
      "names": {
        "zh": "南宫"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3560772,
        "lng": 115.3920309
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.149158,
        37.0900222,
        115.7587097,
        37.4467678
      ]
    },
    {
      "id": 1469,
      "name": "Kurashiki",
      "names": {
        "zh": "仓敷",
        "ja": "倉敷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5850791,
        "lng": 133.7719957
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.602433,
        34.4148582,
        133.885506,
        34.6694687
      ]
    },
    {
      "id": 1470,
      "name": "Staten Island",
      "names": {
        "es": "Isla de Staten",
        "zh": "斯塔滕岛",
        "id": "Pulau Staten",
        "ja": "スタテン島",
        "th": "เกาะสเตเทน",
        "pt": "Ilha de staten"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5834557,
        "lng": -74.1496048
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.258843,
        40.476578,
        -74.034613,
        40.651506
      ]
    },
    {
      "id": 1471,
      "name": "Kucukcekmece",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9918737,
        "lng": 28.7711956
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.7397863,
        40.9770526,
        28.8193482,
        41.0641013
      ]
    },
    {
      "id": 1472,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.5370909,
        "lng": -68.5251802
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -68.6851802,
        -31.6970909,
        -68.3651802,
        -31.3770909
      ]
    },
    {
      "id": 1473,
      "name": "Vila Velha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.3297037,
        "lng": -40.2920174
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.4841548,
        -20.5356801,
        -40.2493154,
        -20.3199393
      ]
    },
    {
      "id": 1474,
      "name": "Mazar-e Sharif",
      "names": {
        "fr": "Mazar-eharif"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.708063,
        "lng": 67.107067
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        67.087067,
        36.688063,
        67.127067,
        36.728063
      ]
    },
    {
      "id": 1475,
      "name": "Macapa",
      "names": {
        "fr": "Macapá",
        "zh": "马卡帕",
        "it": "Macapá",
        "id": "Macapá",
        "pt": "Macapá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": 0.041509,
        "lng": -51.060946
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -51.202248,
        -0.097538,
        -50.959473,
        0.111009
      ]
    },
    {
      "id": 1476,
      "name": "Shekhupura",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.7085285,
        "lng": 73.9864014
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.9511902,
        31.6886617,
        74.0281589,
        31.7398813
      ]
    },
    {
      "id": 1477,
      "name": "Des Moines",
      "names": {
        "ja": "デモイン",
        "th": "ด้อม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5868654,
        "lng": -93.6249494
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -93.7091411,
        41.4796389,
        -93.4936911,
        41.6589106
      ]
    },
    {
      "id": 1478,
      "name": "Piura",
      "names": {
        "zh": "皮乌拉"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -5.2003609,
        "lng": -80.6217593
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -80.6237828,
        -5.2006478,
        -80.6203853,
        -5.200112
      ]
    },
    {
      "id": 1479,
      "name": "Mandaluyong City",
      "names": {
        "es": "Ciudad Mandaluyong",
        "zh": "曼努永市",
        "id": "Kota Mandaluyong",
        "ja": "マンダルヨンシティ",
        "th": "เมืองแมนดาลูยอง",
        "pt": "Cidade de mandaluyong"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5774392,
        "lng": 121.0338973
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0170704,
        14.5672083,
        121.0600635,
        14.6019529
      ]
    },
    {
      "id": 1480,
      "name": "Jiaojiangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.57317,
        "lng": 110.92694
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1481,
      "name": "Laohekou",
      "names": {
        "zh": "老河口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3615609,
        "lng": 111.6791987
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.5191987,
        32.2015609,
        111.8391987,
        32.5215609
      ]
    },
    {
      "id": 1482,
      "name": "Kagithane",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0796544,
        "lng": 28.9731198
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.9681198,
        41.0746544,
        28.9781198,
        41.0846544
      ]
    },
    {
      "id": 1483,
      "name": "Angeles City",
      "names": {
        "es": "Ciudad de angeles",
        "zh": "安吉利斯市",
        "de": "Angeles-Stadt",
        "ja": "アンヘレスシティ",
        "th": "เมืองแองเจลิส",
        "pt": "Cidade de angeles"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.15,
        "lng": 120.58333
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.56333,
        15.13,
        120.60333,
        15.17
      ]
    },
    {
      "id": 1484,
      "name": "Pasay City",
      "names": {
        "es": "Ciudad de Pasay",
        "fr": "Passay City",
        "zh": "Pasay城市",
        "de": "Pasay-Stadt",
        "id": "Kota Pasay",
        "ja": "パサイシティ",
        "th": "เมือง Pasay"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.545071,
        "lng": 120.998905
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.98144,
        14.51748,
        121.02144,
        14.55748
      ]
    },
    {
      "id": 1485,
      "name": "Beian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.4398394,
        "lng": 126.2373497
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.1369009,
        46.3849144,
        126.3567049,
        46.5125462
      ]
    },
    {
      "id": 1486,
      "name": "Fujin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.2398155,
        "lng": 132.0251042
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        131.8651042,
        47.0798155,
        132.1851042,
        47.3998155
      ]
    },
    {
      "id": 1487,
      "name": "Leicester",
      "names": {
        "zh": "莱斯特",
        "ja": "レスター",
        "th": "เลสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.6362,
        "lng": -1.1331969
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.2931969,
        52.4762,
        -0.9731969,
        52.7962
      ]
    },
    {
      "id": 1488,
      "name": "Xiaoyi",
      "names": {
        "zh": "孝义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1452884,
        "lng": 111.7724751
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.6124751,
        36.9852884,
        111.9324751,
        37.3052884
      ]
    },
    {
      "id": 1489,
      "name": "Lanus",
      "names": {
        "zh": "拉努斯",
        "de": "Lanús"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7033363,
        "lng": -58.3953235
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.4047217,
        -34.7083029,
        -58.3884705,
        -34.6959359
      ]
    },
    {
      "id": 1490,
      "name": "Qingzhen",
      "names": {
        "zh": "清镇",
        "th": "ชิงเจิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.5765145,
        "lng": 106.4694398
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        106.3094398,
        26.4165145,
        106.6294398,
        26.7365145
      ]
    },
    {
      "id": 1491,
      "name": "Ba`qubah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.7466,
        "lng": 44.64366
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        44.62366,
        33.7266,
        44.66366,
        33.7666
      ]
    },
    {
      "id": 1492,
      "name": "Katsushika-ku",
      "names": {
        "zh": "葛饰-ku",
        "ja": "葛飾区-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.73333,
        "lng": 139.85
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.75,
        35.63333,
        139.95,
        35.83333
      ]
    },
    {
      "id": 1493,
      "name": "Buraydah",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 26.331667,
        "lng": 43.971667
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        43.811667,
        26.171667,
        44.131667,
        26.491667
      ]
    },
    {
      "id": 1494,
      "name": "Gdansk",
      "names": {
        "zh": "格但斯克",
        "de": "Danzig",
        "it": "Danzica",
        "ja": "グダニスク"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.3706858,
        "lng": 18.61298210330077
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        18.4294955,
        54.2749189,
        18.9502437,
        54.4664485
      ]
    },
    {
      "id": 1495,
      "name": "Marikina City",
      "names": {
        "es": "Ciudad de Marikina",
        "zh": "Marikina市",
        "id": "Kota Marikina",
        "ja": "マリキナシティ",
        "th": "เมืองมาริกิน่า",
        "pt": "Cidade de marikina"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6345531,
        "lng": 121.0962692242168
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0949043,
        14.6325784,
        121.0976655,
        14.6357666
      ]
    },
    {
      "id": 1496,
      "name": "Manado",
      "names": {
        "zh": "万鸦老",
        "th": "มานาโด"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.4900578,
        "lng": 124.8408708
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        124.6815473,
        1.4280234,
        124.9341182,
        1.6491886
      ]
    },
    {
      "id": 1497,
      "name": "Campos",
      "names": {
        "zh": "坎波斯",
        "ja": "カンポス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7546,
        "lng": -41.3242
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.8884729,
        -22.1043158,
        -40.9745861,
        -21.1955884
      ]
    },
    {
      "id": 1498,
      "name": "Calabar",
      "names": {
        "zh": "卡拉巴尔",
        "ja": "カラバル",
        "th": "คาลาบาร์"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 4.9795999,
        "lng": 8.3373597
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        8.1773597,
        4.8195999,
        8.4973597,
        5.1395999
      ]
    },
    {
      "id": 1499,
      "name": "Kanazawa",
      "names": {
        "zh": "金泽",
        "ja": "金沢",
        "th": "คานาซาว่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.561627,
        "lng": 136.6568822
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        136.3576149,
        36.338154,
        136.81723,
        36.7811202
      ]
    },
    {
      "id": 1500,
      "name": "Iloilo",
      "names": {
        "zh": "伊洛伊洛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6926985,
        "lng": 122.5736783
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.487965,
        10.639315,
        122.6171611,
        10.7811925
      ]
    },
    {
      "id": 1501,
      "name": "Long Beach",
      "names": {
        "es": "Playa larga",
        "fr": "Longue plage",
        "zh": "长滩",
        "de": "Langer Strand",
        "it": "Lunga spiaggia",
        "id": "Pantai panjang",
        "ja": "長い砂浜",
        "th": "หาดยาว",
        "pt": "Longa praia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7690164,
        "lng": -118.191604
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.248966,
        33.6905847,
        -118.0632373,
        33.8855595
      ]
    },
    {
      "id": 1502,
      "name": "Kuqa",
      "names": {
        "zh": "库车"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7134956,
        "lng": 82.9544345
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        82.7944345,
        41.5534956,
        83.1144345,
        41.8734956
      ]
    },
    {
      "id": 1503,
      "name": "Jalgaon",
      "names": {
        "zh": "贾尔冈"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.0137606,
        "lng": 75.5627048
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.3059148,
        20.7827178,
        75.736109,
        21.1785695
      ]
    },
    {
      "id": 1504,
      "name": "Cranbourne",
      "names": {},
      "countryCode": "AU",
      "latLng": {
        "lat": -38.0998689,
        "lng": 145.28291203837819
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        145.2261104,
        -38.1599077,
        145.33534,
        -38.0362866
      ]
    },
    {
      "id": 1505,
      "name": "Port St. Lucie",
      "names": {
        "fr": "Port Saint-Lucie",
        "zh": "港口圣卢西莉",
        "ja": "ポートセントルーシー",
        "th": "พอร์ตเซนต์ลูซี",
        "pt": "Porto St. Lucie"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.257809,
        "lng": -80.396003
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.48114,
        27.205584,
        -80.244207,
        27.374698
      ]
    },
    {
      "id": 1506,
      "name": "Murcia",
      "names": {
        "zh": "穆尔西亚",
        "ja": "ムルシア",
        "th": "มูร์เซีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.984147,
        "lng": -1.127375
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -1.152169,
        37.967629,
        -1.110854,
        38.006401
      ]
    },
    {
      "id": 1507,
      "name": "Maua",
      "names": {
        "zh": "马乌阿"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6669527,
        "lng": -46.4616922
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.4994776,
        -23.719607,
        -46.3840628,
        -23.6121395
      ]
    },
    {
      "id": 1508,
      "name": "Fukuyama",
      "names": {
        "zh": "福山",
        "ja": "福山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4857039,
        "lng": 133.3623097
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.210609,
        34.2321797,
        133.4973622,
        34.712131
      ]
    },
    {
      "id": 1509,
      "name": "Tel Aviv-Yafo",
      "names": {
        "zh": "特拉维夫 - 亚菲",
        "th": "เทลอาวีฟ - ยูโฟ"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0852997,
        "lng": 34.7818064
      },
      "country": "Israel",
      "importance": 1,
      "bbox": [
        34.739131,
        32.0293437,
        34.8522617,
        32.1469766
      ]
    },
    {
      "id": 1510,
      "name": "Xicheng",
      "names": {
        "zh": "西城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.1450276,
        "lng": 102.6480449
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.5075351,
        24.0795828,
        102.6953308,
        24.2466573
      ]
    },
    {
      "id": 1511,
      "name": "Amagasaki",
      "names": {
        "zh": "尼崎",
        "ja": "尼崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7288995,
        "lng": 135.412989
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.334089,
        34.6639679,
        135.460294,
        34.78123
      ]
    },
    {
      "id": 1512,
      "name": "Pyeongtaek",
      "names": {
        "zh": "平泽"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.9913146,
        "lng": 127.0846714
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.0846214,
        36.9912646,
        127.0847214,
        36.9913646
      ]
    },
    {
      "id": 1513,
      "name": "Kurnool",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.829491,
        "lng": 78.02811
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.000834,
        15.807615,
        78.035723,
        15.848541
      ]
    },
    {
      "id": 1514,
      "name": "Denton",
      "names": {
        "zh": "登顿",
        "ja": "デントン",
        "th": "เด็นทัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1838787,
        "lng": -97.1413417
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.3971631,
        32.9874314,
        -96.8341146,
        33.4304868
      ]
    },
    {
      "id": 1515,
      "name": "Melaka",
      "names": {
        "zh": "马六甲",
        "ja": "マラッカ",
        "th": "มะละกา"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.1877652,
        "lng": 102.2525737
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        102.2525237,
        2.1877152,
        102.2526237,
        2.1878152
      ]
    },
    {
      "id": 1516,
      "name": "Taoyuan District",
      "names": {
        "es": "Distrito taoyuan",
        "fr": "District de Taoyuan",
        "zh": "桃园区",
        "de": "Taoyuaner Bezirk",
        "it": "Distretto di Taoyuan",
        "id": "Distrik Taoyuan",
        "ja": "桃園地区",
        "th": "เขตเถาหยวน",
        "pt": "Distrito de Taoyuan"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.997115,
        "lng": 121.312226
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        121.27696,
        24.97368,
        121.31696,
        25.01368
      ]
    },
    {
      "id": 1517,
      "name": "General Trias",
      "names": {
        "es": "Trios Generales",
        "fr": "Trias généraux",
        "zh": "一般三叠",
        "de": "Allgemeine TRIAS",
        "it": "Trias generale",
        "id": "Trias Umum",
        "ja": "一般的なトライアス",
        "th": "เทียสทั่วไป",
        "pt": "Trias gerais"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.38694,
        "lng": 120.88167
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.86167,
        14.36694,
        120.90167,
        14.40694
      ]
    },
    {
      "id": 1518,
      "name": "Jian'ou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.04694,
        "lng": 118.32528
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.22528,
        26.94694,
        118.42528,
        27.14694
      ]
    },
    {
      "id": 1519,
      "name": "Huadian",
      "names": {
        "zh": "华电"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9715617,
        "lng": 126.74443
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.58443,
        42.8115617,
        126.90443,
        43.1315617
      ]
    },
    {
      "id": 1520,
      "name": "Tanta",
      "names": {
        "zh": "坦塔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.7833666,
        "lng": 30.9982536
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.9452856,
        30.7578027,
        31.0314985,
        30.822833
      ]
    },
    {
      "id": 1521,
      "name": "Kota Kinabalu",
      "names": {
        "ja": "コタキナバル"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.9780066,
        "lng": 116.0728988
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        115.9128988,
        5.8180066,
        116.2328988,
        6.1380066
      ]
    },
    {
      "id": 1522,
      "name": "Minzhu",
      "names": {
        "zh": "民主"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.8558,
        "lng": 126.8
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        126.76,
        45.8158,
        126.84,
        45.8958
      ]
    },
    {
      "id": 1523,
      "name": "Bukavu",
      "names": {
        "zh": "布卡武"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -2.49076,
        "lng": 28.84281
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        28.82281,
        -2.51076,
        28.86281,
        -2.47076
      ]
    },
    {
      "id": 1524,
      "name": "Rajshahi",
      "names": {
        "zh": "拉吉沙伊"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.37443,
        "lng": 88.603999
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        88.500454,
        24.341531,
        88.696245,
        24.450926
      ]
    },
    {
      "id": 1525,
      "name": "Balashikha",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7997662,
        "lng": 37.9373707
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.8227949,
        55.7081887,
        38.1447756,
        55.8619646
      ]
    },
    {
      "id": 1526,
      "name": "Udaipur",
      "names": {
        "zh": "乌代浦",
        "ja": "ウダイプール",
        "th": "อุทัยปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.578721,
        "lng": 73.6862571
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.5262571,
        24.418721,
        73.8462571,
        24.738721
      ]
    },
    {
      "id": 1527,
      "name": "Kursk",
      "names": {
        "fr": "Koursk",
        "zh": "库尔斯克",
        "th": "เคิร์สต์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.7309249,
        "lng": 36.1923123
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        36.0603015,
        51.6205955,
        36.3038645,
        51.8336183
      ]
    },
    {
      "id": 1528,
      "name": "Mariupol",
      "names": {
        "zh": "马里乌波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.138691,
        "lng": 37.558427
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.473852,
        47.02873,
        37.686953,
        47.215903
      ]
    },
    {
      "id": 1529,
      "name": "Eslamshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.55222,
        "lng": 51.23504
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.21504,
        35.53222,
        51.25504,
        35.57222
      ]
    },
    {
      "id": 1530,
      "name": "San Nicolas de los Garza",
      "names": {
        "ja": "サンニコラスデロスガザ",
        "th": "ซานนิโคลัสเดอลอสการ์ซา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7558021,
        "lng": -100.289648
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.449648,
        25.5958021,
        -100.129648,
        25.9158021
      ]
    },
    {
      "id": 1531,
      "name": "Hsinchu",
      "names": {
        "zh": "新竹",
        "ja": "新竹"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.8066333,
        "lng": 120.9686833
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.8794247,
        24.712573,
        121.0335407,
        24.8532113
      ]
    },
    {
      "id": 1532,
      "name": "Barcelona",
      "names": {
        "fr": "Barcelone",
        "zh": "巴塞罗那",
        "it": "Barcellona",
        "ja": "バルセロナ",
        "th": "บาร์เซโลนา"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1325951,
        "lng": -64.6819583
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -64.8149371,
        10.0137778,
        -64.5829046,
        10.178994
      ]
    },
    {
      "id": 1533,
      "name": "Constantine",
      "names": {
        "es": "Constantino",
        "fr": "Constantin",
        "zh": "君士坦丁",
        "it": "Costantino",
        "ja": "コンスタンティン",
        "th": "คอนสแตนติน",
        "pt": "Constantino"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.3641642,
        "lng": 6.6084281
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.5284453,
        36.2589259,
        6.7665523,
        36.4490899
      ]
    },
    {
      "id": 1534,
      "name": "Tanbei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.08806,
        "lng": 112.93944
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.91944,
        35.06806,
        112.95944,
        35.10806
      ]
    },
    {
      "id": 1535,
      "name": "Ado-Ekiti",
      "names": {
        "zh": "的ADO-埃基蒂",
        "ja": "ADOを-ekiti",
        "th": "ความกังวลใจ-ekiti"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.6232482,
        "lng": 5.22274
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        5.1064386,
        7.5372453,
        5.3992829,
        7.6742396
      ]
    },
    {
      "id": 1536,
      "name": "Madison",
      "names": {
        "zh": "麦迪逊",
        "ja": "マディソン",
        "th": "เมดิสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.074761,
        "lng": -89.3837613
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -89.571661,
        42.9981494,
        -89.2320848,
        43.171916
      ]
    },
    {
      "id": 1537,
      "name": "Bellary",
      "names": {
        "zh": "贝拉里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.1433952,
        "lng": 76.9193876
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.7593876,
        14.9833952,
        77.0793876,
        15.3033952
      ]
    },
    {
      "id": 1538,
      "name": "Rodriguez",
      "names": {
        "es": "Rodríguez",
        "zh": "·罗德里格斯",
        "ja": "ロドリゲス",
        "th": "โรด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7324746,
        "lng": 121.1452701
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1052701,
        14.6924746,
        121.1852701,
        14.7724746
      ]
    },
    {
      "id": 1539,
      "name": "Santiago de Cuba",
      "names": {
        "zh": "圣地亚哥德古巴",
        "de": "Santiago de Kuba",
        "th": "ซานติอาโกเดอคิวบา"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0214263,
        "lng": -75.8294928
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -76.0700919,
        19.8829899,
        -75.3778404,
        20.137598
      ]
    },
    {
      "id": 1540,
      "name": "Yongji",
      "names": {
        "zh": "永济"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.8661185,
        "lng": 110.4446202
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.2846202,
        34.7061185,
        110.6046202,
        35.0261185
      ]
    },
    {
      "id": 1541,
      "name": "Reno",
      "names": {
        "zh": "里诺",
        "ja": "リノ",
        "th": "รีโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5261206,
        "lng": -119.8126581
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.0023339,
        39.392426,
        -119.699345,
        39.723436
      ]
    },
    {
      "id": 1542,
      "name": "Danjiangkou",
      "names": {
        "zh": "丹江口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.5429981,
        "lng": 111.5070101
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.3470101,
        32.3829981,
        111.6670101,
        32.7029981
      ]
    },
    {
      "id": 1543,
      "name": "Sao Jose do Rio Preto",
      "names": {
        "zh": "圣何塞做里约普雷托",
        "id": "Sao Jose melakukan Rio Preto",
        "ja": "サンノゼはリオプレトをする",
        "th": "Sao Jose ทำ Rio Preto",
        "pt": "São José do Rio Preto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8125851,
        "lng": -49.3804212
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.463,
        -20.932,
        -49.256,
        -20.6351046
      ]
    },
    {
      "id": 1544,
      "name": "Fujisawa",
      "names": {
        "zh": "藤泽",
        "ja": "藤沢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3371925,
        "lng": 139.4871318
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4821318,
        35.3321925,
        139.4921318,
        35.3421925
      ]
    },
    {
      "id": 1545,
      "name": "Andijon",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.6436891,
        "lng": 72.3641539
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        72.3456869,
        40.6279866,
        72.3819926,
        40.6598352
      ]
    },
    {
      "id": 1546,
      "name": "Harrisburg",
      "names": {
        "zh": "哈里斯堡",
        "ja": "ハリスバーグ",
        "th": "แฮร์ริส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2663107,
        "lng": -76.8861122
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -76.92449,
        40.237571,
        -76.8368632,
        40.3262914
      ]
    },
    {
      "id": 1547,
      "name": "Machida",
      "names": {
        "zh": "町田",
        "ja": "町田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.544056,
        "lng": 139.4451335
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4401335,
        35.539056,
        139.4501335,
        35.549056
      ]
    },
    {
      "id": 1548,
      "name": "Ning'an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.3437943,
        "lng": 129.4606832
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.3006832,
        44.1837943,
        129.6206832,
        44.5037943
      ]
    },
    {
      "id": 1549,
      "name": "Little Rock",
      "names": {
        "es": "Pequeña roca",
        "fr": "Petit rocher",
        "zh": "小石头",
        "de": "Kleiner Felsen",
        "it": "Piccola roccia",
        "id": "Batu kecil",
        "ja": "リトルロック",
        "th": "หินเล็ก ๆ",
        "pt": "Pedra pequena"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7465071,
        "lng": -92.2896267
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -92.5215905,
        34.6256657,
        -92.1506554,
        34.8218226
      ]
    },
    {
      "id": 1550,
      "name": "Zhangjiakou Shi Xuanhua Qu",
      "names": {
        "fr": "Zhangjiakou shi xuanhua",
        "zh": "张家口石宣华曲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6067217,
        "lng": 115.072195
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.6946333,
        40.1538782,
        115.5204282,
        40.8085314
      ]
    },
    {
      "id": 1551,
      "name": "Zurich",
      "names": {
        "es": "Zúrich",
        "zh": "苏黎世",
        "de": "Zürich",
        "it": "Zurigo",
        "ja": "チューリッヒ",
        "th": "ซูริค",
        "pt": "Zurique"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3744489,
        "lng": 8.5410422
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.4480061,
        47.3202187,
        8.6254413,
        47.4346662
      ]
    },
    {
      "id": 1552,
      "name": "Sunch'on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.43167,
        "lng": 125.93278
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.91278,
        39.41167,
        125.95278,
        39.45167
      ]
    },
    {
      "id": 1553,
      "name": "Jinchang",
      "names": {
        "zh": "金昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5206719,
        "lng": 102.185973
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.025973,
        38.3606719,
        102.345973,
        38.6806719
      ]
    },
    {
      "id": 1554,
      "name": "Kashiwa",
      "names": {
        "zh": "柏",
        "ja": "柏"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8616954,
        "lng": 139.9701737462902
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.9700241,
        35.8615762,
        139.9703234,
        35.8617908
      ]
    },
    {
      "id": 1555,
      "name": "Guangming",
      "names": {
        "zh": "光明"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.2682403,
        "lng": 124.3471625
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        124.3071625,
        44.2282403,
        124.3871625,
        44.3082403
      ]
    },
    {
      "id": 1556,
      "name": "Sangli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8584407,
        "lng": 74.5880836
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.5830836,
        16.8534407,
        74.5930836,
        16.8634407
      ]
    },
    {
      "id": 1557,
      "name": "Tuticorin",
      "names": {
        "zh": "杜蒂戈林"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 8.8052602,
        "lng": 78.1452745
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.9852745,
        8.6452602,
        78.3052745,
        8.9652602
      ]
    },
    {
      "id": 1558,
      "name": "Beira",
      "names": {
        "zh": "贝拉",
        "ja": "ベイラ",
        "th": "เบียรา"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -19.828707,
        "lng": 34.841782
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        34.681782,
        -19.988707,
        35.001782,
        -19.668707
      ]
    },
    {
      "id": 1559,
      "name": "Kupang",
      "names": {
        "zh": "古邦"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -10.1632209,
        "lng": 123.6017755
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        123.5270742,
        -10.2927858,
        123.6831699,
        -10.1250952
      ]
    },
    {
      "id": 1560,
      "name": "Jeju",
      "names": {
        "zh": "济州",
        "ja": "済州",
        "th": "เชจู"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 33.4887737,
        "lng": 126.4987083
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        125.7940999,
        32.9104556,
        127.2152291,
        34.139806
      ]
    },
    {
      "id": 1561,
      "name": "Caxias do Sul",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.1685045,
        "lng": -51.1796385
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.3115485,
        -29.3571969,
        -50.7825689,
        -28.7944879
      ]
    },
    {
      "id": 1562,
      "name": "Santos",
      "names": {
        "zh": "·桑托斯",
        "ja": "サントス",
        "th": "โทส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.94285,
        "lng": -46.344175
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.403067,
        -23.993249,
        -46.289122,
        -23.91622
      ]
    },
    {
      "id": 1563,
      "name": "Manizales",
      "names": {
        "zh": "马尼萨莱斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0668907,
        "lng": -75.5066661
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.5517477,
        5.0225297,
        -75.4203189,
        5.1048866
      ]
    },
    {
      "id": 1564,
      "name": "Stavropol",
      "names": {
        "zh": "斯塔夫罗波尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.04077895,
        "lng": 41.984846643710355
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        41.6957414,
        44.9684656,
        42.0920482,
        45.113044
      ]
    },
    {
      "id": 1565,
      "name": "Yogyakarta",
      "names": {
        "zh": "日惹",
        "ja": "ジョグジャカルタ",
        "th": "ยอร์กยาการ์ตา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.8011998,
        "lng": 110.3646608
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        110.3442608,
        -7.8401773,
        110.4067929,
        -7.7664911
      ]
    },
    {
      "id": 1566,
      "name": "Calicut",
      "names": {
        "zh": "卡利卡特"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.2450558,
        "lng": 75.7754716
      },
      "country": "India",
      "importance": 2,
      "bbox": [
        75.6154716,
        11.0850558,
        75.9354716,
        11.4050558
      ]
    },
    {
      "id": 1567,
      "name": "Veracruz",
      "names": {
        "zh": "韦拉克鲁斯",
        "ja": "ベラクルス",
        "th": "เวรากรูซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2003429,
        "lng": -96.1383572
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.2983572,
        19.0403429,
        -95.9783572,
        19.3603429
      ]
    },
    {
      "id": 1568,
      "name": "Zanjan",
      "names": {
        "zh": "赞詹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6700041,
        "lng": 48.4795399
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        48.4304078,
        36.6378428,
        48.602615,
        36.7249328
      ]
    },
    {
      "id": 1569,
      "name": "Cusco",
      "names": {
        "zh": "库斯科",
        "ja": "クスコ",
        "th": "กุสโก"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.5285326,
        "lng": -71.9644288
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -71.9655049,
        -13.5285414,
        -71.9638354,
        -13.5285302
      ]
    },
    {
      "id": 1570,
      "name": "Welkom",
      "names": {
        "zh": "韦尔科姆"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -27.982298,
        "lng": 26.737969
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        26.577969,
        -28.142298,
        26.897969,
        -27.822298
      ]
    },
    {
      "id": 1571,
      "name": "Ulan-Ude",
      "names": {
        "fr": "Oulan-UDE",
        "zh": "乌兰乌德",
        "th": "อูลาน-ude"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.81810935,
        "lng": 107.62430915
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        107.6241413,
        51.8179993,
        107.624477,
        51.8182194
      ]
    },
    {
      "id": 1572,
      "name": "Oakland",
      "names": {
        "zh": "奥克兰",
        "ja": "オークランド",
        "th": "โอกแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.8044557,
        "lng": -122.271356
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.355881,
        37.6336763,
        -122.1144203,
        37.8854257
      ]
    },
    {
      "id": 1573,
      "name": "Shinagawa-ku",
      "names": {
        "zh": "品川-ku",
        "ja": "品川-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.599252,
        "lng": 139.73891
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.695187,
        35.5844906,
        139.7799018,
        35.6415938
      ]
    },
    {
      "id": 1574,
      "name": "Kashan",
      "names": {
        "zh": "卡尚"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 33.9876533,
        "lng": 51.4428856
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.3683048,
        33.9284752,
        51.496771,
        34.0137545
      ]
    },
    {
      "id": 1575,
      "name": "Kenitra",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.2515822,
        "lng": -6.581924
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.586924,
        34.2465822,
        -6.576924,
        34.2565822
      ]
    },
    {
      "id": 1576,
      "name": "Uyo",
      "names": {
        "zh": "乌约"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.0322447,
        "lng": 7.924791
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        7.764791,
        4.8722447,
        8.084791,
        5.1922447
      ]
    },
    {
      "id": 1577,
      "name": "Sokoto",
      "names": {
        "zh": "索科托"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 13.0610588,
        "lng": 5.2373194
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        5.0773194,
        12.9010588,
        5.3973194,
        13.2210588
      ]
    },
    {
      "id": 1578,
      "name": "Masan",
      "names": {
        "zh": "马山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1969737,
        "lng": 128.5673576
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.3479474,
        35.0333962,
        128.6587808,
        35.2224144
      ]
    },
    {
      "id": 1579,
      "name": "Sidi Bouzid",
      "names": {
        "zh": "西德贝德"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.0382386,
        "lng": 9.4865301
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        9.3265301,
        34.8782386,
        9.6465301,
        35.1982386
      ]
    },
    {
      "id": 1580,
      "name": "Porto Velho",
      "names": {
        "ja": "ポルトVelho"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.7494525,
        "lng": -63.8735438
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -66.8058676,
        -10,
        -62.237,
        -7.9692942
      ]
    },
    {
      "id": 1581,
      "name": "Sihung",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.36611,
        "lng": 126.80556
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.78556,
        37.34611,
        126.82556,
        37.38611
      ]
    },
    {
      "id": 1582,
      "name": "Xalapa",
      "names": {
        "zh": "哈拉帕"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5408338,
        "lng": -96.9146374
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -97.0746374,
        19.3808338,
        -96.7546374,
        19.7008338
      ]
    },
    {
      "id": 1583,
      "name": "Florencio Varela",
      "names": {
        "zh": "Florencio瓦雷拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8111398,
        "lng": -58.2740683
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.2790683,
        -34.8161398,
        -58.2690683,
        -34.8061398
      ]
    },
    {
      "id": 1584,
      "name": "Uijeongbu",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.741987,
        "lng": 127.044467
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.018073,
        37.692514,
        127.106683,
        37.778242
      ]
    },
    {
      "id": 1585,
      "name": "Akola",
      "names": {
        "zh": "阿科拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.7227763,
        "lng": 77.0052993
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.0002993,
        20.7177763,
        77.0102993,
        20.7277763
      ]
    },
    {
      "id": 1586,
      "name": "Aomori",
      "names": {
        "zh": "青森",
        "ja": "青森",
        "th": "อาโอโมริ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.8222833,
        "lng": 140.7472027
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.520233,
        40.605927,
        140.980999,
        40.970232
      ]
    },
    {
      "id": 1587,
      "name": "Yan'an Beilu",
      "names": {
        "zh": "延安贝卢",
        "ja": "ヤンのビルー"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.8161878,
        "lng": 87.5879886
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        87.5879386,
        43.8161378,
        87.5880386,
        43.8162378
      ]
    },
    {
      "id": 1588,
      "name": "Agadir",
      "names": {
        "zh": "阿加迪尔",
        "ja": "アガディール",
        "th": "อากาดีร์"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 30.4205162,
        "lng": -9.5838532
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -9.6765894,
        30.3507233,
        -9.4840659,
        30.4933553
      ]
    },
    {
      "id": 1589,
      "name": "Mogi das Cruzes",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5234284,
        "lng": -46.1926671
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.3222754,
        -23.7707615,
        -46.05,
        -23.342
      ]
    },
    {
      "id": 1590,
      "name": "Durham",
      "names": {
        "zh": "达勒姆",
        "ja": "ダーラム",
        "th": "เดอแรม",
        "pt": "County Durham"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.996653,
        "lng": -78.9018053
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.0074981,
        35.8663508,
        -78.7569111,
        36.1370099
      ]
    },
    {
      "id": 1591,
      "name": "Diadema",
      "names": {
        "zh": "迪亚德马"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6866428,
        "lng": -46.6246935
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.6520805,
        -23.7391266,
        -46.5775772,
        -23.657598
      ]
    },
    {
      "id": 1592,
      "name": "Likasi",
      "names": {
        "zh": "利卡西"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -10.98139,
        "lng": 26.73333
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        26.71333,
        -11.00139,
        26.75333,
        -10.96139
      ]
    },
    {
      "id": 1593,
      "name": "Buenaventura",
      "names": {
        "zh": "布埃纳文图拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.8881929,
        "lng": -77.0738324
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -81.6166147,
        3.1079358,
        -76.6875315,
        4.2340529
      ]
    },
    {
      "id": 1594,
      "name": "Yazd",
      "names": {
        "zh": "亚兹德",
        "th": "ยาซด์"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.89722,
        "lng": 54.3675
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        54.3475,
        31.87722,
        54.3875,
        31.91722
      ]
    },
    {
      "id": 1595,
      "name": "Laval",
      "names": {
        "zh": "拉瓦尔",
        "ja": "ラヴァル",
        "th": "ลาวาล"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5757802,
        "lng": -73.7530656
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.8953597,
        45.5109049,
        -73.5235036,
        45.7007485
      ]
    },
    {
      "id": 1596,
      "name": "Toyota",
      "names": {
        "zh": "丰田",
        "ja": "トヨタ",
        "th": "โตโยต้า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.15195,
        "lng": 137.301478
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.0400635,
        34.9905957,
        137.581125,
        35.291156
      ]
    },
    {
      "id": 1597,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3146042,
        "lng": 121.1137466
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0466175,
        14.2160688,
        121.1776541,
        14.3773194
      ]
    },
    {
      "id": 1598,
      "name": "Winston-Salem",
      "names": {
        "zh": "温斯顿塞勒姆",
        "ja": "ウィンストン・セーラム",
        "th": "วินสตัน-ซาเลม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0942506,
        "lng": -80.2738634
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2739134,
        36.0942006,
        -80.2738134,
        36.0943006
      ]
    },
    {
      "id": 1599,
      "name": "Tver",
      "names": {
        "zh": "特维尔",
        "de": "Twer",
        "th": "ตเวียร์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.858675,
        "lng": 35.9208284
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        35.7214173,
        56.7833245,
        36.0682068,
        56.9360617
      ]
    },
    {
      "id": 1600,
      "name": "Elazig",
      "names": {
        "zh": "埃拉泽",
        "th": "เอลาซิก"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6650886,
        "lng": 39.2227805
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        39.2177805,
        38.6600886,
        39.2277805,
        38.6700886
      ]
    },
    {
      "id": 1601,
      "name": "Hpa-An",
      "names": {
        "es": "HPA-un",
        "fr": "HPa-un",
        "zh": "杂多酸-一个",
        "it": "HPa un-",
        "ja": "hpa-た",
        "th": "hpa ข้อ",
        "pt": "HPA-um"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 16.88953,
        "lng": 97.63482
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        97.61482,
        16.86953,
        97.65482,
        16.90953
      ]
    },
    {
      "id": 1602,
      "name": "Palma",
      "names": {
        "zh": "帕尔马",
        "ja": "パルマ",
        "th": "เกาะ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.5695818,
        "lng": 2.6500745
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        2.563847,
        39.1207284,
        2.9798596,
        39.6582617
      ]
    },
    {
      "id": 1603,
      "name": "Bonita Springs",
      "names": {
        "ja": "ボニータスプリングス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.353866,
        "lng": -81.787798
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.886444,
        26.316234,
        -81.6579,
        26.407422
      ]
    },
    {
      "id": 1604,
      "name": "Mishan",
      "names": {
        "zh": "密山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.5445899,
        "lng": 131.8713602
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        131.7113602,
        45.3845899,
        132.0313602,
        45.7045899
      ]
    },
    {
      "id": 1605,
      "name": "Hailin",
      "names": {
        "zh": "海林"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.5733458,
        "lng": 129.3815749
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.2215749,
        44.4133458,
        129.5415749,
        44.7333458
      ]
    },
    {
      "id": 1606,
      "name": "Seremban",
      "names": {
        "zh": "芙蓉"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.723099,
        "lng": 101.9400601
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        101.7800601,
        2.563099,
        102.1000601,
        2.883099
      ]
    },
    {
      "id": 1607,
      "name": "Lecheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.1286954,
        "lng": 113.3435868
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.3035868,
        25.0886954,
        113.3835868,
        25.1686954
      ]
    },
    {
      "id": 1608,
      "name": "Zhengjiatun",
      "names": {
        "zh": "郑家屯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.50639,
        "lng": 123.50639
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.48639,
        43.48639,
        123.52639,
        43.52639
      ]
    },
    {
      "id": 1609,
      "name": "Luhansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.56679,
        "lng": 39.311985
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        39.236381,
        48.477426,
        39.457259,
        48.666204
      ]
    },
    {
      "id": 1610,
      "name": "Pencheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.6738586,
        "lng": 115.658207
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.6389537,
        29.6588055,
        115.6827393,
        29.685704
      ]
    },
    {
      "id": 1611,
      "name": "Magnitogorsk",
      "names": {
        "zh": "马格尼托哥尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.42568665,
        "lng": 59.050732193093054
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        58.8972157,
        53.3000093,
        59.2355561,
        53.5512876
      ]
    },
    {
      "id": 1612,
      "name": "Takamatsu",
      "names": {
        "zh": "高松",
        "ja": "高松"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.35071205,
        "lng": 134.04693836836242
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        134.0465976,
        34.3503076,
        134.0472095,
        34.3510862
      ]
    },
    {
      "id": 1613,
      "name": "El Obeid",
      "names": {
        "fr": "El obéide",
        "zh": "elobeid",
        "it": "El obere",
        "ja": "エルボイド",
        "pt": "Elaborada"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.17996295,
        "lng": 30.2190624398691
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        30.170761,
        13.1320519,
        30.2689805,
        13.2296251
      ]
    },
    {
      "id": 1614,
      "name": "Dalai",
      "names": {
        "es": "Lama",
        "fr": "Lama",
        "zh": "达赖",
        "ja": "ダライ",
        "th": "ดาไลลา",
        "pt": "Lama"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.5014236,
        "lng": 124.2729048
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.2022815,
        45.4703392,
        124.3618804,
        45.5619855
      ]
    },
    {
      "id": 1615,
      "name": "Xingren",
      "names": {
        "zh": "兴仁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4351408,
        "lng": 105.1839527
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.0239527,
        25.2751408,
        105.3439527,
        25.5951408
      ]
    },
    {
      "id": 1616,
      "name": "Kolwezi",
      "names": {
        "zh": "科卢韦齐"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -10.7169952,
        "lng": 25.4669918
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        25.3069918,
        -10.8769952,
        25.6269918,
        -10.5569952
      ]
    },
    {
      "id": 1617,
      "name": "Wenlan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3697072,
        "lng": 103.400176
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.380176,
        23.3497072,
        103.420176,
        23.3897072
      ]
    },
    {
      "id": 1618,
      "name": "Indio",
      "names": {
        "zh": "因迪奥",
        "ja": "インディオ",
        "th": "อินดิโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.74389845,
        "lng": -116.1970542100473
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -116.3012664,
        33.6709991,
        -116.1292897,
        33.8167124
      ]
    },
    {
      "id": 1619,
      "name": "Palm Coast",
      "names": {
        "es": "Costa de palma",
        "fr": "Palmier",
        "zh": "棕榈海岸",
        "de": "Palmküste",
        "it": "Palmea",
        "id": "Pantai Palm",
        "ja": "パームコースト",
        "th": "ฝ่ามือ",
        "pt": "Palmeira"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5541432,
        "lng": -81.2207673
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.3716417,
        29.4141019,
        -81.1487797,
        29.651799
      ]
    },
    {
      "id": 1620,
      "name": "Binan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.331387,
        "lng": 121.081196
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.076196,
        14.326387,
        121.086196,
        14.336387
      ]
    },
    {
      "id": 1621,
      "name": "Arusha",
      "names": {
        "zh": "阿鲁沙",
        "ja": "アルーシャ",
        "th": "อารูชา"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -3.3696827,
        "lng": 36.6880794
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        36.5280794,
        -3.5296827,
        36.8480794,
        -3.2096827
      ]
    },
    {
      "id": 1622,
      "name": "Fenyang",
      "names": {
        "zh": "汾阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.2683004,
        "lng": 111.7830029
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.4340821,
        37.1465129,
        112.0081756,
        37.4835015
      ]
    },
    {
      "id": 1623,
      "name": "Paju",
      "names": {
        "zh": "坡州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.8292632,
        "lng": 126.8164928
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        126.7816043,
        37.7988487,
        126.8594956,
        37.8594046
      ]
    },
    {
      "id": 1624,
      "name": "Mataram",
      "names": {
        "zh": "马塔兰",
        "ja": "マタラム"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.5871166,
        "lng": 116.1209516
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        116.1209016,
        -8.5871666,
        116.1210016,
        -8.5870666
      ]
    },
    {
      "id": 1625,
      "name": "Chattanooga",
      "names": {
        "zh": "查塔努加",
        "ja": "チャタヌーガ",
        "th": "นู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0457219,
        "lng": -85.3094883
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.4255628,
        34.982938,
        -85.051587,
        35.214346
      ]
    },
    {
      "id": 1626,
      "name": "Kisumu",
      "names": {
        "zh": "基苏木"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.1029109,
        "lng": 34.7541761
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.5799207,
        -0.1752245,
        34.882306,
        0.0226311
      ]
    },
    {
      "id": 1627,
      "name": "Jhang City",
      "names": {
        "es": "Ciudad de Jhang",
        "zh": "江市",
        "id": "Kota jhang",
        "th": "เมืองจาง",
        "pt": "Cidade de Jhang"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.3023737,
        "lng": 72.3235259
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.3234759,
        31.3023237,
        72.3235759,
        31.3024237
      ]
    },
    {
      "id": 1628,
      "name": "Nagqu",
      "names": {
        "zh": "那曲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4728755,
        "lng": 92.0561223
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        91.8961223,
        31.3128755,
        92.2161223,
        31.6328755
      ]
    },
    {
      "id": 1629,
      "name": "Jayapura",
      "names": {
        "zh": "查亚普拉",
        "ja": "ジャヤプラ",
        "th": "จายาปุระ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.5387539,
        "lng": 140.7037389
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        140.6027795,
        -2.8392466,
        141,
        -2.4737106
      ]
    },
    {
      "id": 1630,
      "name": "Rio Branco",
      "names": {
        "zh": "里约布兰科",
        "ja": "リオ・ブランコ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.9765362,
        "lng": -67.8220778
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -69.364,
        -10.485,
        -67.482,
        -9.505
      ]
    },
    {
      "id": 1631,
      "name": "Toyama",
      "names": {
        "zh": "富山",
        "ja": "富山",
        "th": "โทะยะมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7016421,
        "lng": 137.2127904
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        137.2077904,
        36.6966421,
        137.2177904,
        36.7066421
      ]
    },
    {
      "id": 1632,
      "name": "Fangting",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.12766,
        "lng": 104.16649
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.14649,
        31.10766,
        104.18649,
        31.14766
      ]
    },
    {
      "id": 1633,
      "name": "Sanandaj",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.3126047,
        "lng": 46.9978639
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        46.9564626,
        35.2539602,
        47.0348525,
        35.3551474
      ]
    },
    {
      "id": 1634,
      "name": "Linghai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1676142,
        "lng": 121.355281
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.7114608,
        40.8328885,
        121.7586945,
        41.4304671
      ]
    },
    {
      "id": 1635,
      "name": "Toyonaka",
      "names": {
        "zh": "丰中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.78244,
        "lng": 135.46932
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.44932,
        34.76244,
        135.48932,
        34.80244
      ]
    },
    {
      "id": 1636,
      "name": "Spokane",
      "names": {
        "zh": "斯波坎",
        "ja": "スポケーン",
        "th": "สโปแคน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6571934,
        "lng": -117.42351
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.6039994,
        47.5867785,
        -117.3038581,
        47.7587975
      ]
    },
    {
      "id": 1637,
      "name": "Sochi",
      "names": {
        "fr": "Sotchi",
        "zh": "索契",
        "de": "Sotschi",
        "ja": "ソチ",
        "th": "โซ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.5854823,
        "lng": 39.723109
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.1493697,
        43.3949798,
        40.0105333,
        44.0309026
      ]
    },
    {
      "id": 1638,
      "name": "Bhagalpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.2494829,
        "lng": 86.9828131
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.8228131,
        25.0894829,
        87.1428131,
        25.4094829
      ]
    },
    {
      "id": 1639,
      "name": "Ivanovo",
      "names": {
        "zh": "伊万诺沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.9984452,
        "lng": 40.9737394
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        40.8678799,
        56.946694,
        41.1266349,
        57.0706995
      ]
    },
    {
      "id": 1640,
      "name": "Turkmenabat",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 39.102635,
        "lng": 63.5701837
      },
      "country": "Turkmenistan",
      "importance": 1,
      "bbox": [
        63.5651837,
        39.097635,
        63.5751837,
        39.107635
      ]
    },
    {
      "id": 1641,
      "name": "Zaria",
      "names": {
        "zh": "扎里亚"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.1134917,
        "lng": 7.7188449
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.5588449,
        10.9534917,
        7.8788449,
        11.2734917
      ]
    },
    {
      "id": 1642,
      "name": "Bryansk",
      "names": {
        "fr": "Briansk",
        "zh": "布良斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.2423778,
        "lng": 34.3668288
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        34.1933032,
        53.1838735,
        34.5398784,
        53.3745668
      ]
    },
    {
      "id": 1643,
      "name": "Asyut",
      "names": {
        "es": "Asiut",
        "zh": "艾斯尤特"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.18096,
        "lng": 31.18368
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.16368,
        27.16096,
        31.20368,
        27.20096
      ]
    },
    {
      "id": 1644,
      "name": "Taiping",
      "names": {
        "zh": "太平",
        "th": "ไทปิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.2841703,
        "lng": 118.1299559
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.8187239,
        29.9906142,
        118.3541036,
        30.5193855
      ]
    },
    {
      "id": 1645,
      "name": "Mbale",
      "names": {
        "zh": "姆巴莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0711896,
        "lng": 34.1749506
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.1084142,
        1.0313985,
        34.2546181,
        1.1745767
      ]
    },
    {
      "id": 1646,
      "name": "Maracay",
      "names": {
        "zh": "马拉凯"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.245679,
        "lng": -67.602258
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -67.676006,
        10.192962,
        -67.541668,
        10.329739
      ]
    },
    {
      "id": 1647,
      "name": "Jiexiu",
      "names": {
        "zh": "介休"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0310036,
        "lng": 111.9154182
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.7554182,
        36.8710036,
        112.0754182,
        37.1910036
      ]
    },
    {
      "id": 1648,
      "name": "Santa Fe",
      "names": {
        "zh": "圣达菲",
        "ja": "サンタフェ",
        "th": "ซานตาเฟ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6186951,
        "lng": -60.7019561
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -60.7572755,
        -31.6737051,
        -60.6624271,
        -31.5568352
      ]
    },
    {
      "id": 1649,
      "name": "Campina Grande",
      "names": {
        "id": "Grandina",
        "ja": "カンパナグランデ",
        "pt": "Camponês"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.2285872,
        "lng": -35.885234
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.890234,
        -7.2335872,
        -35.880234,
        -7.2235872
      ]
    },
    {
      "id": 1650,
      "name": "Nagasaki",
      "names": {
        "zh": "长崎",
        "ja": "長崎",
        "th": "นางาซากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.7525953,
        "lng": 129.8686364
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        129.8636364,
        32.7475953,
        129.8736364,
        32.7575953
      ]
    },
    {
      "id": 1651,
      "name": "Szczecin",
      "names": {
        "zh": "什切青",
        "ja": "シュチェチン",
        "th": "สเกซซีน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4301818,
        "lng": 14.5509623
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        14.4438273,
        53.321716,
        14.805414,
        53.5376831
      ]
    },
    {
      "id": 1652,
      "name": "Syracuse",
      "names": {
        "es": "Siracusa",
        "zh": "锡拉丘兹",
        "de": "Syrakus",
        "it": "Siracusa",
        "ja": "シラキュース",
        "th": "ซีราคิวส์",
        "pt": "Siracusa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0481221,
        "lng": -76.1474244
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.204629,
        42.984165,
        -76.074273,
        43.086121
      ]
    },
    {
      "id": 1653,
      "name": "Maringa",
      "names": {
        "es": "Maringá",
        "fr": "Maringá",
        "zh": "马林加",
        "it": "Maringá",
        "id": "Maringá",
        "pt": "Maringá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.425269,
        "lng": -51.9382078
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.0999488,
        -23.5583071,
        -51.8362012,
        -23.2539878
      ]
    },
    {
      "id": 1654,
      "name": "Carapicuiba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5190698,
        "lng": -46.834561
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.834611,
        -23.5191198,
        -46.834511,
        -23.5190198
      ]
    },
    {
      "id": 1655,
      "name": "Qazvin",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.2797,
        "lng": 50.0049
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        49.9849,
        36.2597,
        50.0249,
        36.2997
      ]
    },
    {
      "id": 1656,
      "name": "Quilon",
      "names": {
        "zh": "奎隆"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 8.885775,
        "lng": 76.587409
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.539643,
        8.851486,
        76.66114,
        8.937622
      ]
    },
    {
      "id": 1657,
      "name": "Jundiai",
      "names": {
        "pt": "Jundiaí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1887668,
        "lng": -46.884506
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0288712,
        -23.326777,
        -46.786066,
        -23.0763504
      ]
    },
    {
      "id": 1658,
      "name": "Hirakata",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.818215,
        "lng": 135.659225
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.614258,
        34.773083,
        135.746603,
        34.881341
      ]
    },
    {
      "id": 1659,
      "name": "Gifu",
      "names": {
        "zh": "岐阜",
        "ja": "岐阜",
        "th": "กิฟุ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4230949,
        "lng": 136.7627526
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        136.678678,
        35.351284,
        136.886272,
        35.543103
      ]
    },
    {
      "id": 1660,
      "name": "Lancaster",
      "names": {
        "zh": "兰开斯特",
        "ja": "ランカスター",
        "th": "แลงคาสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.03813,
        "lng": -76.3056686
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.3460495,
        40.006577,
        -76.2540414,
        40.0729456
      ]
    },
    {
      "id": 1661,
      "name": "Sikar",
      "names": {
        "zh": "锡格尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.6091864,
        "lng": 75.1395503
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.9795503,
        27.4491864,
        75.2995503,
        27.7691864
      ]
    },
    {
      "id": 1662,
      "name": "Jiangjiafan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0178514,
        "lng": 114.4272763
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.4072763,
        29.9978514,
        114.4472763,
        30.0378514
      ]
    },
    {
      "id": 1663,
      "name": "Dera Ghazi Khan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.0422137,
        "lng": 70.6354598
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        70.6354098,
        30.0421637,
        70.6355098,
        30.0422637
      ]
    },
    {
      "id": 1664,
      "name": "Mwanza",
      "names": {
        "zh": "姆万扎"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -2.5196915,
        "lng": 32.9014417
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        32.7414417,
        -2.6796915,
        33.0614417,
        -2.3596915
      ]
    },
    {
      "id": 1665,
      "name": "Arlington",
      "names": {
        "zh": "阿灵顿",
        "ja": "アーリントン",
        "th": "อาร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7355816,
        "lng": -97.1071186
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.233818,
        32.5867753,
        -97.037008,
        32.817121
      ]
    },
    {
      "id": 1666,
      "name": "Xushan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.925,
        "lng": 118.93889
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.91889,
        34.905,
        118.95889,
        34.945
      ]
    },
    {
      "id": 1667,
      "name": "Miyazaki",
      "names": {
        "zh": "宫崎",
        "ja": "宮崎",
        "th": "มิยาซากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.918012,
        "lng": 131.417539
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        131.358449,
        31.845217,
        131.489462,
        32.040376
      ]
    },
    {
      "id": 1668,
      "name": "Yuyao",
      "names": {
        "zh": "余姚市",
        "th": "หยูเหยา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0403094,
        "lng": 121.1500882
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.8912789,
        29.6629941,
        121.4204038,
        30.3412554
      ]
    },
    {
      "id": 1669,
      "name": "Stockton",
      "names": {
        "zh": "斯托克顿",
        "ja": "ストックトン",
        "th": "สต็อกตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9577016,
        "lng": -121.290779
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.419736,
        37.887747,
        -121.1840372,
        38.0583
      ]
    },
    {
      "id": 1670,
      "name": "Bhatpara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.860281,
        "lng": 88.398478
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.38113,
        22.84643,
        88.42113,
        22.88643
      ]
    },
    {
      "id": 1671,
      "name": "Sandakan",
      "names": {
        "zh": "山打根",
        "ja": "サンダカン"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.8391274,
        "lng": 118.1158598
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        117.9558598,
        5.6791274,
        118.2758598,
        5.9991274
      ]
    },
    {
      "id": 1672,
      "name": "Taytay",
      "names": {
        "zh": "泰泰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5706996,
        "lng": 121.13202
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1040832,
        14.4894504,
        121.1689447,
        14.5914955
      ]
    },
    {
      "id": 1673,
      "name": "Hejin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5957768,
        "lng": 110.7057517
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.5457517,
        35.4357768,
        110.8657517,
        35.7557768
      ]
    },
    {
      "id": 1674,
      "name": "Thanh Hoa",
      "names": {
        "it": "Grazie hoa",
        "ja": "タンホア"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 19.8076814,
        "lng": 105.7767437
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.7221436,
        19.7320556,
        105.8624205,
        19.8835119
      ]
    },
    {
      "id": 1675,
      "name": "Minamisuita",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7513544,
        "lng": 135.5061687
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5061187,
        34.7513044,
        135.5062187,
        34.7514044
      ]
    },
    {
      "id": 1676,
      "name": "Poughkeepsie",
      "names": {
        "zh": "波基普西",
        "ja": "ポキプシー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7065539,
        "lng": -73.9283672
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.946377,
        41.670257,
        -73.890811,
        41.716976
      ]
    },
    {
      "id": 1677,
      "name": "Yokosuka",
      "names": {
        "zh": "横须贺",
        "ja": "横須賀",
        "th": "โยโกะสึกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2730564,
        "lng": 139.6653829
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3833329,
        35.1171371,
        139.8017832,
        35.3480463
      ]
    },
    {
      "id": 1678,
      "name": "Nha Trang",
      "names": {
        "ja": "ニャチャン",
        "th": "นาค"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.2431693,
        "lng": 109.1898675
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        109.112878,
        12.1428752,
        109.3714549,
        12.3799085
      ]
    },
    {
      "id": 1679,
      "name": "Belgorod",
      "names": {
        "zh": "别尔哥罗德",
        "th": "เบลโกรอด"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.5999705,
        "lng": 36.5902478
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        36.5901978,
        50.5999205,
        36.5902978,
        50.6000205
      ]
    },
    {
      "id": 1680,
      "name": "Malabon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6578504,
        "lng": 120.9511261
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.9209997,
        14.6450559,
        121.0004165,
        14.7044133
      ]
    },
    {
      "id": 1681,
      "name": "Yola",
      "names": {
        "zh": "约拉"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.2089255,
        "lng": 12.4802485
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        12.3202485,
        9.0489255,
        12.6402485,
        9.3689255
      ]
    },
    {
      "id": 1682,
      "name": "Lobito",
      "names": {
        "zh": "洛比托"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.3486666,
        "lng": 13.54222
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.53722,
        -12.3536666,
        13.54722,
        -12.3436666
      ]
    },
    {
      "id": 1683,
      "name": "Saurimo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -9.66078,
        "lng": 20.39155
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        20.37155,
        -9.68078,
        20.41155,
        -9.64078
      ]
    },
    {
      "id": 1684,
      "name": "Olinda",
      "names": {
        "zh": "奥林达",
        "ja": "オリンダ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.9986401,
        "lng": -34.8459552
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -34.916997,
        -8.0508811,
        -34.8266378,
        -7.9546819
      ]
    },
    {
      "id": 1685,
      "name": "Esenler",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0376175,
        "lng": 28.8824519
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.8337984,
        41.0173263,
        28.9006289,
        41.1079173
      ]
    },
    {
      "id": 1686,
      "name": "Gujrat",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.5686782,
        "lng": 74.071481
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        74.0353632,
        32.4925875,
        74.1403448,
        32.6557799
      ]
    },
    {
      "id": 1687,
      "name": "Ad Diwaniyah",
      "names": {
        "fr": "Ad diangeur",
        "zh": "Adiwaniyah",
        "it": "Annuncio diwaniyah",
        "ja": "Diwaniyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.99289,
        "lng": 44.92552
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        44.90552,
        31.97289,
        44.94552,
        32.01289
      ]
    },
    {
      "id": 1688,
      "name": "Piracicaba",
      "names": {
        "zh": "皮拉西卡巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.725165,
        "lng": -47.6493269
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.0982733,
        -22.9017907,
        -47.490322,
        -22.5208872
      ]
    },
    {
      "id": 1689,
      "name": "Hancheng",
      "names": {
        "zh": "韩城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4762519,
        "lng": 110.4508029
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.2908029,
        35.3162519,
        110.6108029,
        35.6362519
      ]
    },
    {
      "id": 1690,
      "name": "Karamay",
      "names": {
        "zh": "克拉玛依"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.6003911,
        "lng": 84.8660538
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        84.5769352,
        44.7542629,
        85.2746147,
        45.6961695
      ]
    },
    {
      "id": 1691,
      "name": "Kakinada",
      "names": {
        "zh": "卡基纳达"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.9437385,
        "lng": 82.2350607
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.0750607,
        16.7837385,
        82.3950607,
        17.1037385
      ]
    },
    {
      "id": 1692,
      "name": "Augusta",
      "names": {
        "zh": "奥古斯塔",
        "ja": "オーガスタ",
        "th": "ออกัสตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4709714,
        "lng": -81.9748429
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.3538702,
        33.227165,
        -81.8281227,
        33.5450221
      ]
    },
    {
      "id": 1693,
      "name": "Bhilwara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.347615,
        "lng": 74.634188
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.575627,
        25.305106,
        74.675301,
        25.381082
      ]
    },
    {
      "id": 1694,
      "name": "Tieli",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.9842249,
        "lng": 128.0256684
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        127.8656684,
        46.8242249,
        128.1856684,
        47.1442249
      ]
    },
    {
      "id": 1695,
      "name": "Cilegon",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.0197169,
        "lng": 106.0531157
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        106.0481157,
        -6.0247169,
        106.0581157,
        -6.0147169
      ]
    },
    {
      "id": 1696,
      "name": "Nizamabad",
      "names": {
        "zh": "尼扎马巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 18.6732693,
        "lng": 78.0978477
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.9378477,
        18.5132693,
        78.2578477,
        18.8332693
      ]
    },
    {
      "id": 1697,
      "name": "Bologna",
      "names": {
        "es": "Bolonia",
        "fr": "Bologne",
        "zh": "博洛尼亚",
        "ja": "ボローニャ",
        "th": "โบโลญญา",
        "pt": "Bolonha"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.4938203,
        "lng": 11.3426327
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        11.2296206,
        44.4210532,
        11.4336079,
        44.556094
      ]
    },
    {
      "id": 1698,
      "name": "Boise",
      "names": {
        "zh": "博伊西",
        "ja": "ボイジー",
        "th": "บอยซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6166163,
        "lng": -116.200886
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -116.3656818,
        43.509398,
        -116.09419,
        43.6920633
      ]
    },
    {
      "id": 1699,
      "name": "Esenyurt",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0342862,
        "lng": 28.6801113
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.6205441,
        40.999701,
        28.7018986,
        41.0981109
      ]
    },
    {
      "id": 1700,
      "name": "Tetouan",
      "names": {
        "es": "Tetuán",
        "fr": "Tétouan",
        "zh": "得土安"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.570175,
        "lng": -5.3742776
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.425062,
        35.543337,
        -5.261143,
        35.6069422
      ]
    },
    {
      "id": 1701,
      "name": "Aqtobe",
      "names": {
        "ja": "アクトベ"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 50.2836268,
        "lng": 57.2298651
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        56.8591269,
        50.1234775,
        57.7728461,
        50.603272
      ]
    },
    {
      "id": 1702,
      "name": "Oxnard",
      "names": {
        "zh": "奥克斯纳德",
        "th": "ออกซ์นาร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1976308,
        "lng": -119.180381
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.329216,
        34.1193213,
        -119.1196964,
        34.2614652
      ]
    },
    {
      "id": 1703,
      "name": "Oyo",
      "names": {
        "zh": "奥约",
        "th": "โอโย"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.8410537,
        "lng": 3.9315363
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        3.7715363,
        7.6810537,
        4.0915363,
        8.0010537
      ]
    },
    {
      "id": 1704,
      "name": "Tarlac City",
      "names": {
        "es": "Ciudad de Tarlac",
        "zh": "塔拉克市",
        "de": "Tarlac-Stadt",
        "it": "Città di Tarlac",
        "id": "Kota tarlac",
        "ja": "タラックシティ",
        "th": "เมือง Tarlac",
        "pt": "Cidade de tarlac"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4862807,
        "lng": 120.5960627
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.5960127,
        15.4862307,
        120.5961127,
        15.4863307
      ]
    },
    {
      "id": 1705,
      "name": "Okazaki",
      "names": {
        "zh": "冈崎",
        "ja": "岡崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.945253,
        "lng": 137.167013
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.10418,
        34.888184,
        137.249404,
        35.043505
      ]
    },
    {
      "id": 1706,
      "name": "Cainta",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5780161,
        "lng": 121.1163072
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0946307,
        14.547851,
        121.1450774,
        14.636566
      ]
    },
    {
      "id": 1707,
      "name": "Ha'il",
      "names": {
        "zh": "哈伊勒"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 27.5208716,
        "lng": 41.6985992
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        41.5385992,
        27.3608716,
        41.8585992,
        27.6808716
      ]
    },
    {
      "id": 1708,
      "name": "Yidu",
      "names": {
        "zh": "宜都"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.3819696,
        "lng": 111.4436927
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.0991518,
        30.0992089,
        111.6059263,
        30.6029557
      ]
    },
    {
      "id": 1709,
      "name": "Lianzhou",
      "names": {
        "zh": "连州市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7887338,
        "lng": 112.3734276
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.3334276,
        24.7487338,
        112.4134276,
        24.8287338
      ]
    },
    {
      "id": 1710,
      "name": "Florence",
      "names": {
        "es": "Florencia",
        "zh": "佛罗伦萨",
        "de": "Florenz",
        "it": "Firenze",
        "ja": "フィレンツェ",
        "th": "ฟลอเรนซ์",
        "pt": "Florença"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.7697955,
        "lng": 11.2556404
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        11.1497598,
        43.7258716,
        11.3389044,
        43.8354631
      ]
    },
    {
      "id": 1711,
      "name": "Christchurch",
      "names": {
        "zh": "基督城",
        "ja": "クライストチャーチ",
        "th": "ไครสต์เชิร์ช"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -43.530955,
        "lng": 172.6364343
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        172.3930248,
        -43.6292014,
        172.8216267,
        -43.3890866
      ]
    },
    {
      "id": 1712,
      "name": "Nuevo Laredo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 27.4930246,
        "lng": -99.507425
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.667425,
        27.3330246,
        -99.347425,
        27.6530246
      ]
    },
    {
      "id": 1713,
      "name": "Scranton",
      "names": {
        "zh": "斯克兰顿",
        "ja": "スクラントン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.4086874,
        "lng": -75.6621294
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.7200169,
        41.3396271,
        -75.6038714,
        41.4693345
      ]
    },
    {
      "id": 1714,
      "name": "Brno",
      "names": {
        "zh": "布尔诺",
        "ja": "ブルノ",
        "th": "เบอร์โน"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.198531,
        "lng": 16.61041
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        16.49659,
        49.123485,
        16.705808,
        49.275107
      ]
    },
    {
      "id": 1715,
      "name": "London",
      "names": {
        "es": "Londres",
        "fr": "Londres",
        "zh": "伦敦",
        "it": "Londra",
        "ja": "ロンドン",
        "th": "ลอนดอน",
        "pt": "Londres"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.9832406,
        "lng": -81.243372
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -81.403372,
        42.8232406,
        -81.083372,
        43.1432406
      ]
    },
    {
      "id": 1716,
      "name": "Novi Sad",
      "names": {
        "es": "Novi triste",
        "fr": "Novi triste",
        "zh": "诺维悲伤",
        "de": "Novi traurig",
        "it": "Novi triste",
        "id": "Novi sedih",
        "ja": "ノヴィ悲しい",
        "th": "โนวีเศร้า",
        "pt": "Novi triste"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.265572500000005,
        "lng": 19.829502016547877
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.8284925,
        45.2651908,
        19.8310077,
        45.2658905
      ]
    },
    {
      "id": 1717,
      "name": "Gusau",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.1656756,
        "lng": 6.6712088
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.6662088,
        12.1606756,
        6.6762088,
        12.1706756
      ]
    },
    {
      "id": 1718,
      "name": "Modesto",
      "names": {
        "zh": "莫德斯托",
        "ja": "モデスト",
        "th": "โมเดสโต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6393419,
        "lng": -120.9968892
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.096473,
        37.521933,
        -120.901239,
        37.711443
      ]
    },
    {
      "id": 1719,
      "name": "Pasto",
      "names": {
        "zh": "帕斯托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2140275,
        "lng": -77.2785096
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -77.3112363,
        1.1785788,
        -77.2455409,
        1.247606
      ]
    },
    {
      "id": 1720,
      "name": "Kissimmee",
      "names": {
        "zh": "基西米",
        "ja": "キシミー",
        "th": "คิสซิมมี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.2918995,
        "lng": -81.4075838
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.485393,
        28.2507485,
        -81.3147734,
        28.347957
      ]
    },
    {
      "id": 1721,
      "name": "Las Palmas",
      "names": {
        "zh": "拉斯帕尔马斯",
        "ja": "ラスパルマ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.1288694,
        "lng": -15.4349015
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -15.527249,
        28.0244326,
        -15.3948989,
        28.181426
      ]
    },
    {
      "id": 1722,
      "name": "Ichinomiya",
      "names": {
        "zh": "一宫"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.304878,
        "lng": 136.806915
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.704576,
        35.250213,
        136.877254,
        35.369604
      ]
    },
    {
      "id": 1723,
      "name": "Panihati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.708831,
        "lng": 88.37410940000001
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3739282,
        22.708021,
        88.3742906,
        22.709641
      ]
    },
    {
      "id": 1724,
      "name": "Huancayo",
      "names": {
        "zh": "万卡约"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.068098,
        "lng": -75.2100953
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -75.2361575,
        -12.0876546,
        -74.9465617,
        -11.9012676
      ]
    },
    {
      "id": 1725,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7405111,
        "lng": -104.830994
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.8865378,
        39.5509989,
        -104.488906,
        39.826965
      ]
    },
    {
      "id": 1726,
      "name": "Betim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.968056,
        "lng": -44.198333
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.3182977,
        -20.051777,
        -44.067565,
        -19.8317114
      ]
    },
    {
      "id": 1727,
      "name": "Sintra",
      "names": {
        "zh": "辛特拉",
        "ja": "シントラ",
        "th": "ซินตรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.79846,
        "lng": -9.3881
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.5005266,
        38.7385819,
        -9.2206908,
        38.9324322
      ]
    },
    {
      "id": 1728,
      "name": "Parbhani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.262469,
        "lng": 76.7718
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.7318,
        19.222469,
        76.8118,
        19.302469
      ]
    },
    {
      "id": 1729,
      "name": "Usulutan",
      "names": {
        "es": "Usulután"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.35,
        "lng": -88.45
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.47,
        13.33,
        -88.43,
        13.37
      ]
    },
    {
      "id": 1730,
      "name": "Youngstown",
      "names": {
        "zh": "扬斯敦",
        "ja": "ヤングスタウン",
        "th": "ยังส์ทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1035786,
        "lng": -80.6520161
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.7111792,
        41.0499533,
        -80.567932,
        41.139013
      ]
    },
    {
      "id": 1731,
      "name": "Iquitos",
      "names": {
        "zh": "伊基托斯",
        "ja": "イキトス",
        "th": "อีกีโตส"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -3.749365,
        "lng": -73.2444145
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -73.5909642,
        -3.8864536,
        -73.2364277,
        -3.6852526
      ]
    },
    {
      "id": 1732,
      "name": "Helixi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.633182,
        "lng": 118.976384
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.95667,
        30.60528,
        118.99667,
        30.64528
      ]
    },
    {
      "id": 1733,
      "name": "Manukau City",
      "names": {
        "es": "Ciudad de Manukau",
        "zh": "曼府市",
        "it": "Città di Manukau",
        "id": "Kota Manukau",
        "ja": "マヌカウシティ",
        "th": "เมือง Manukau",
        "pt": "Cidade de manukau"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.99282,
        "lng": 174.87986
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        174.85986,
        -37.01282,
        174.89986,
        -36.97282
      ]
    },
    {
      "id": 1734,
      "name": "Cumana",
      "names": {
        "es": "Cumaná",
        "zh": "库马纳"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4495706,
        "lng": -64.1578046
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -64.2475105,
        10.3788682,
        -64.0798724,
        10.4841455
      ]
    },
    {
      "id": 1735,
      "name": "Rohtak",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.9010899,
        "lng": 76.5801935
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.4201935,
        28.7410899,
        76.7401935,
        29.0610899
      ]
    },
    {
      "id": 1736,
      "name": "Vinnytsia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2320162,
        "lng": 28.467975
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        28.3679759,
        49.1873426,
        28.59204,
        49.2865952
      ]
    },
    {
      "id": 1737,
      "name": "Latur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.3982273,
        "lng": 76.5625913
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.4025913,
        18.2382273,
        76.7225913,
        18.5582273
      ]
    },
    {
      "id": 1738,
      "name": "Lipa City",
      "names": {
        "es": "Ciudad de LIPA",
        "fr": "Ville de Lipa",
        "zh": "Lipa市",
        "de": "Lipa-Stadt",
        "it": "Città di Lipa",
        "id": "Kota Lipa",
        "th": "เมือง Lipa",
        "pt": "Cidade lipo"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9411,
        "lng": 121.1631
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1431,
        13.9211,
        121.1831,
        13.9611
      ]
    },
    {
      "id": 1739,
      "name": "Mandaue City",
      "names": {
        "es": "Ciudad de Mandaue",
        "zh": "曼德市",
        "de": "Mandaue-Stadt",
        "id": "Kota Mandaue",
        "ja": "マンダウエ市",
        "th": "เมือง Mandaue",
        "pt": "Cidade de macaue"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.344452,
        "lng": 123.932253
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.90113,
        10.315975,
        123.963753,
        10.375461
      ]
    },
    {
      "id": 1740,
      "name": "Bello",
      "names": {
        "zh": "贝洛",
        "ja": "ベリョ",
        "th": "เบลโล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.334997,
        "lng": -75.5582665
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7182665,
        6.174997,
        -75.3982665,
        6.494997
      ]
    },
    {
      "id": 1741,
      "name": "Khorramabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4842109,
        "lng": 48.3538276
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        48.2571773,
        33.3989693,
        48.4576796,
        33.5588305
      ]
    },
    {
      "id": 1742,
      "name": "Ambon",
      "names": {
        "zh": "安汶",
        "ja": "アンボン"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.6959434,
        "lng": 128.178785
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        128.028397,
        -3.7943491,
        128.3010918,
        -3.5732022
      ]
    },
    {
      "id": 1743,
      "name": "Butuan",
      "names": {
        "zh": "布图安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.9592003,
        "lng": 125.5361922
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.5361422,
        8.9591503,
        125.5362422,
        8.9592503
      ]
    },
    {
      "id": 1744,
      "name": "Keelung",
      "names": {
        "zh": "基隆",
        "ja": "基隆"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 25.1317232,
        "lng": 121.744652
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        121.6267923,
        25.0524727,
        122.10916,
        25.6333576
      ]
    },
    {
      "id": 1745,
      "name": "Toyohashi",
      "names": {
        "zh": "丰桥",
        "ja": "豊橋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.769123,
        "lng": 137.391461
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.2452653,
        34.3450155,
        137.5345541,
        34.861313
      ]
    },
    {
      "id": 1746,
      "name": "Baguio City",
      "names": {
        "es": "Ciudad de Baguio",
        "zh": "碧瑶市",
        "de": "Baguio-Stadt",
        "it": "Baguio città",
        "id": "Kota Baguio",
        "ja": "バギオシティ",
        "th": "เมืองบาเกียว",
        "pt": "Cidade de baguio"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.4119905,
        "lng": 120.5933719
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.5448779,
        16.3606207,
        120.635488,
        16.4343156
      ]
    },
    {
      "id": 1747,
      "name": "La Florida",
      "names": {
        "fr": "La Floride",
        "zh": "La佛罗里达州",
        "th": "ลาฟลอริด้า"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5204181,
        "lng": -70.6006178
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.6148092,
        -33.5703349,
        -70.4369093,
        -33.4915576
      ]
    },
    {
      "id": 1748,
      "name": "Lengshuijiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.68806,
        "lng": 111.42944
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.40944,
        27.66806,
        111.44944,
        27.70806
      ]
    },
    {
      "id": 1749,
      "name": "Anguo",
      "names": {
        "zh": "安国"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4277476,
        "lng": 115.3303798
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.1733117,
        38.2322179,
        115.4968475,
        38.5544431
      ]
    },
    {
      "id": 1750,
      "name": "Kita-ku",
      "names": {
        "zh": "北-ku",
        "ja": "北区-ku",
        "th": "คิตะ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.755838,
        "lng": 139.736687
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.689393,
        35.731578,
        139.7707414,
        35.7989124
      ]
    },
    {
      "id": 1751,
      "name": "Rajapalaiyam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 9.4522179,
        "lng": 77.5607781
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.5557781,
        9.4472179,
        77.5657781,
        9.4572179
      ]
    },
    {
      "id": 1752,
      "name": "Panshi",
      "names": {
        "zh": "磐石"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9409718,
        "lng": 126.052932
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        125.892932,
        42.7809718,
        126.212932,
        43.1009718
      ]
    },
    {
      "id": 1753,
      "name": "Madan",
      "names": {
        "zh": "马丹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.3911761,
        "lng": 50.5805857
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        50.5605857,
        36.3711761,
        50.6005857,
        36.4111761
      ]
    },
    {
      "id": 1754,
      "name": "Plovdiv",
      "names": {
        "zh": "普罗夫迪夫"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.1418541,
        "lng": 24.7499297
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        24.5899297,
        41.9818541,
        24.9099297,
        42.3018541
      ]
    },
    {
      "id": 1755,
      "name": "Az Zubayr",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 30.3917664,
        "lng": 47.7027042
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        47.5427042,
        30.2317664,
        47.8627042,
        30.5517664
      ]
    },
    {
      "id": 1756,
      "name": "Al Qatif",
      "names": {
        "ja": "アルカイチフ",
        "th": "อัลกาติฟิ"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 26.5586004,
        "lng": 50.0087399
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        49.9687399,
        26.5186004,
        50.0487399,
        26.5986004
      ]
    },
    {
      "id": 1757,
      "name": "Takasaki",
      "names": {
        "zh": "高崎",
        "ja": "高崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3220984,
        "lng": 139.0032758
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.642506,
        36.198594,
        139.129659,
        36.483472
      ]
    },
    {
      "id": 1758,
      "name": "Huichang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.90376,
        "lng": 112.78426
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.76426,
        34.88376,
        112.80426,
        34.92376
      ]
    },
    {
      "id": 1759,
      "name": "Vitsyebsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.1904,
        "lng": 30.2049
      },
      "country": "Belarus",
      "importance": 1,
      "bbox": [
        30.1849,
        55.1704,
        30.2249,
        55.2104
      ]
    },
    {
      "id": 1760,
      "name": "Nagano",
      "names": {
        "zh": "长野",
        "ja": "長野",
        "th": "โนะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.6485851,
        "lng": 138.1947664
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        137.910101,
        36.460702,
        138.318899,
        36.8357344
      ]
    },
    {
      "id": 1761,
      "name": "Bauru",
      "names": {
        "zh": "包鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3218102,
        "lng": -49.0705863
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.29,
        -22.4170514,
        -48.9345418,
        -22.061
      ]
    },
    {
      "id": 1762,
      "name": "Yanjiao",
      "names": {
        "zh": "燕郊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.943381,
        "lng": 116.87180159164608
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.7644798,
        39.8768786,
        116.8993183,
        40.0181411
      ]
    },
    {
      "id": 1763,
      "name": "Bochum",
      "names": {
        "zh": "波鸿",
        "ja": "ボーフム",
        "th": "โบคุม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4818111,
        "lng": 7.2196635
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.1020817,
        51.4105043,
        7.3493347,
        51.5313721
      ]
    },
    {
      "id": 1764,
      "name": "Tecamac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.71276,
        "lng": -98.9695
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.9895,
        19.69276,
        -98.9495,
        19.73276
      ]
    },
    {
      "id": 1765,
      "name": "Anapolis",
      "names": {
        "pt": "Anápolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.3332828,
        "lng": -48.9525756
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.194,
        -16.485987,
        -48.75,
        -16.124
      ]
    },
    {
      "id": 1766,
      "name": "Coventry",
      "names": {
        "zh": "考文垂",
        "ja": "コベントリー",
        "th": "โคเวนทรี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4081812,
        "lng": -1.510477
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.6144589,
        52.3639374,
        -1.4239574,
        52.4647822
      ]
    },
    {
      "id": 1767,
      "name": "Shinjuku",
      "names": {
        "zh": "新宿",
        "ja": "新宿",
        "th": "ชินจุกุ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6937632,
        "lng": 139.7036319
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6732748,
        35.6732877,
        139.7451415,
        35.7299039
      ]
    },
    {
      "id": 1768,
      "name": "Wonsan",
      "names": {
        "zh": "元山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.15278,
        "lng": 127.44361
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        127.42361,
        39.13278,
        127.46361,
        39.17278
      ]
    },
    {
      "id": 1769,
      "name": "Caerdydd",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4816546,
        "lng": -3.1791934
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.3391934,
        51.3216546,
        -3.0191934,
        51.6416546
      ]
    },
    {
      "id": 1770,
      "name": "Iligan",
      "names": {
        "zh": "伊利甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.2281556,
        "lng": 124.2411508
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.1077834,
        7.978049,
        124.613615,
        8.3361282
      ]
    },
    {
      "id": 1771,
      "name": "Cabuyao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2795538,
        "lng": 121.1235712
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0137055,
        14.1556988,
        121.2075232,
        14.3167834
      ]
    },
    {
      "id": 1772,
      "name": "Nakano",
      "names": {
        "zh": "中野",
        "ja": "中野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7077741,
        "lng": 139.6638239
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.624272,
        35.6765608,
        139.6942877,
        35.735382
      ]
    },
    {
      "id": 1773,
      "name": "Bengkulu",
      "names": {
        "zh": "明古鲁"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.5186763,
        "lng": 102.5359834
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        100.6204863,
        -5.7189866,
        103.7810669,
        -2.2886537
      ]
    },
    {
      "id": 1774,
      "name": "Orizaba",
      "names": {
        "zh": "欧瑞扎巴"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.850187,
        "lng": -97.103602
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.125553,
        18.832016,
        -97.06798,
        18.886166
      ]
    },
    {
      "id": 1775,
      "name": "Blumenau",
      "names": {
        "zh": "布卢梅瑙"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9195567,
        "lng": -49.0658025
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.2007158,
        -27.132,
        -49.0096552,
        -26.6128012
      ]
    },
    {
      "id": 1776,
      "name": "Montes Claros",
      "names": {
        "zh": "蒙特兰克斯",
        "ja": "モルトクラロス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.7495727,
        "lng": -43.8687268
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.2106567,
        -17.145792,
        -43.6665505,
        -16.0845372
      ]
    },
    {
      "id": 1777,
      "name": "Petion-Ville",
      "names": {
        "es": "Pétion-Ville",
        "fr": "Pélion-ville",
        "zh": "petion-市政厅",
        "ja": "petion-ヴィル",
        "th": "petion-วิลล์"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.5125,
        "lng": -72.28528
      },
      "country": "Haiti",
      "importance": 3,
      "bbox": [
        -72.30528,
        18.4925,
        -72.26528,
        18.5325
      ]
    },
    {
      "id": 1778,
      "name": "Shuanghejiedao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.37448,
        "lng": 106.77266
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.75266,
        30.35448,
        106.79266,
        30.39448
      ]
    },
    {
      "id": 1779,
      "name": "Umuahia",
      "names": {
        "zh": "乌穆阿希亚"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.5324032,
        "lng": 7.4924165
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        7.3324165,
        5.3724032,
        7.6524165,
        5.6924032
      ]
    },
    {
      "id": 1780,
      "name": "Surgut",
      "names": {
        "zh": "苏尔古特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.254032,
        "lng": 73.3964
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        73.218084,
        61.2223522,
        73.783826,
        61.3808714
      ]
    },
    {
      "id": 1781,
      "name": "Gedaref",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 14.044669,
        "lng": 35.378723
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        35.358723,
        14.024669,
        35.398723,
        14.064669
      ]
    },
    {
      "id": 1782,
      "name": "Palu",
      "names": {
        "zh": "帕卢"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.8957793,
        "lng": 119.8679974
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        119.7079974,
        -1.0557793,
        120.0279974,
        -0.7357793
      ]
    },
    {
      "id": 1783,
      "name": "Pokhara",
      "names": {
        "zh": "博卡拉",
        "ja": "ポカラ",
        "th": "โปขระ"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.209538,
        "lng": 83.991402
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        83.7987185,
        28.0777267,
        84.1474462,
        28.3412243
      ]
    },
    {
      "id": 1784,
      "name": "Mahilyow",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.9090245,
        "lng": 30.3429838
      },
      "country": "Belarus",
      "importance": 1,
      "bbox": [
        30.2259627,
        53.792965,
        30.4738304,
        53.9705194
      ]
    },
    {
      "id": 1785,
      "name": "Wudalianchi",
      "names": {
        "zh": "五大连池"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.75,
        "lng": 126.16667
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.14667,
        48.73,
        126.18667,
        48.77
      ]
    },
    {
      "id": 1786,
      "name": "Sungai Petani",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 5.6435195,
        "lng": 100.4869513
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        100.3269513,
        5.4835195,
        100.6469513,
        5.8035195
      ]
    },
    {
      "id": 1787,
      "name": "Nam Dinh",
      "names": {
        "ja": "ナムニ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.4232641,
        "lng": 106.1688515
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.042774,
        20.3758915,
        106.2259475,
        20.4991935
      ]
    },
    {
      "id": 1788,
      "name": "Vitoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビトリア",
        "th": "วีโตเรีย",
        "pt": "Vitória"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.3200917,
        "lng": -40.3376682
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -40.3623322,
        -20.5282613,
        -28.8476399,
        -20.2291637
      ]
    },
    {
      "id": 1789,
      "name": "Hrodna",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.6833095,
        "lng": 23.8346268
      },
      "country": "Belarus",
      "importance": 1,
      "bbox": [
        23.8345768,
        53.6832595,
        23.8346768,
        53.6833595
      ]
    },
    {
      "id": 1790,
      "name": "Vladimir",
      "names": {
        "zh": "弗拉基米尔",
        "ja": "ウラジミール",
        "th": "วลาดิมีร์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1288899,
        "lng": 40.4075203
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        40.1649638,
        56.0518626,
        40.6621252,
        56.1939918
      ]
    },
    {
      "id": 1791,
      "name": "Sao Vicente",
      "names": {
        "zh": "圣维森特",
        "ja": "サンビセンテ",
        "pt": "São Vicente"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.9664177,
        "lng": -46.3861401
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.6501789,
        -24.0109755,
        -46.3549987,
        -23.8994929
      ]
    },
    {
      "id": 1792,
      "name": "Taraz",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 42.8696765,
        "lng": 71.3789159
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        71.3739159,
        42.8646765,
        71.3839159,
        42.8746765
      ]
    },
    {
      "id": 1793,
      "name": "Cubal",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.0398231,
        "lng": 14.2479795
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.2079795,
        -13.0798231,
        14.2879795,
        -12.9998231
      ]
    },
    {
      "id": 1794,
      "name": "Kawagoe",
      "names": {
        "zh": "川越",
        "ja": "川越"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9251145,
        "lng": 139.4856927
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3774483,
        35.837584,
        139.558384,
        35.962266
      ]
    },
    {
      "id": 1795,
      "name": "Ibb",
      "names": {
        "zh": "伊布"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.9701578,
        "lng": 44.1779446
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        44.0179446,
        13.8101578,
        44.3379446,
        14.1301578
      ]
    },
    {
      "id": 1796,
      "name": "Yong'an",
      "names": {
        "zh": "永安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.977723,
        "lng": 117.372977
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.367977,
        25.972723,
        117.377977,
        25.982723
      ]
    },
    {
      "id": 1797,
      "name": "Wuppertal",
      "names": {
        "zh": "伍珀塔尔",
        "ja": "ヴッパータール"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.264018,
        "lng": 7.1780374
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0140725,
        51.1657181,
        7.31343,
        51.3180951
      ]
    },
    {
      "id": 1798,
      "name": "Manisa",
      "names": {
        "zh": "马尼萨"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.620052,
        "lng": 27.397315
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        27.326324,
        38.602902,
        27.465973,
        38.642545
      ]
    },
    {
      "id": 1799,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0283014,
        "lng": 120.6938038
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.5942962,
        14.9932982,
        120.7278114,
        15.1342393
      ]
    },
    {
      "id": 1800,
      "name": "Minatitlan",
      "names": {
        "es": "Minatitlán",
        "it": "Minatitlán",
        "id": "Minatitlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.992773,
        "lng": -94.5567103
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.7167103,
        17.832773,
        -94.3967103,
        18.152773
      ]
    },
    {
      "id": 1801,
      "name": "Nizhniy Tagil",
      "names": {
        "de": "Nischniy Tagil",
        "id": "Tagil nizhniy",
        "ja": "Nizhniyのタギール"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.93166,
        "lng": 60.055186
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60,
        57.847584,
        60.160278,
        57.982498
      ]
    },
    {
      "id": 1802,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3639435,
        "lng": 121.056771
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0054142,
        14.3215296,
        121.1436651,
        14.4114052
      ]
    },
    {
      "id": 1803,
      "name": "Hongzhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.9865523,
        "lng": 104.5469648
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        104.5269648,
        34.9665523,
        104.5669648,
        35.0065523
      ]
    },
    {
      "id": 1804,
      "name": "Wakayama",
      "names": {
        "zh": "和歌山",
        "ja": "和歌山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2342931,
        "lng": 135.1783549
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        134.9787171,
        34.1439722,
        135.314771,
        34.3940376
      ]
    },
    {
      "id": 1805,
      "name": "Bilbao",
      "names": {
        "zh": "毕尔巴鄂",
        "ja": "ビルバオ",
        "th": "บิลเบา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.2630018,
        "lng": -2.9350039
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -2.9859808,
        43.2137176,
        -2.8802639,
        43.2901315
      ]
    },
    {
      "id": 1806,
      "name": "Rahimyar Khan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.420241,
        "lng": 70.295182
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        70.2751818429,
        28.4002407013,
        70.3151818429,
        28.4402407013
      ]
    },
    {
      "id": 1807,
      "name": "Pavlodar",
      "names": {
        "zh": "巴甫洛达尔"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 52.2857573,
        "lng": 76.9455035
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        76.6140354,
        52.1143366,
        77.2222106,
        52.4795411
      ]
    },
    {
      "id": 1808,
      "name": "Gimpo",
      "names": {
        "zh": "金浦",
        "ja": "金浦",
        "th": "กิมโป"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.62215,
        "lng": 126.70785
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 1809,
      "name": "Itaquaquecetuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4860391,
        "lng": -46.3480165
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.3530165,
        -23.4910391,
        -46.3430165,
        -23.4810391
      ]
    },
    {
      "id": 1810,
      "name": "Nara",
      "names": {
        "zh": "奈良",
        "ja": "奈良",
        "th": "นารา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6845445,
        "lng": 135.8048359
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        135.713127,
        34.558047,
        136.071079,
        34.757739
      ]
    },
    {
      "id": 1811,
      "name": "Van",
      "names": {
        "zh": "·范·",
        "ja": "・ヴァン・",
        "th": "รถตู้"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.500875,
        "lng": 43.3946051
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        43.2346051,
        38.340875,
        43.5546051,
        38.660875
      ]
    },
    {
      "id": 1812,
      "name": "Corrientes",
      "names": {
        "zh": "科连特斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.4687004,
        "lng": -58.8311982
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -58.8852972,
        -27.681955,
        -58.6259872,
        -27.371917
      ]
    },
    {
      "id": 1813,
      "name": "Neiva",
      "names": {
        "zh": "内瓦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.9257038,
        "lng": -75.2893937
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.3092532,
        2.8859925,
        -75.2363931,
        2.9865551
      ]
    },
    {
      "id": 1814,
      "name": "Arkhangelsk",
      "names": {
        "zh": "阿尔汉格尔斯克",
        "de": "Archangelsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.543022,
        "lng": 40.537121
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        40.2849384,
        64.3974631,
        40.8365073,
        64.7730598
      ]
    },
    {
      "id": 1815,
      "name": "Batangas",
      "names": {
        "zh": "八打雁",
        "th": "บาทางกัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7520908,
        "lng": 121.0419128
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0418628,
        13.7520408,
        121.0419628,
        13.7521408
      ]
    },
    {
      "id": 1816,
      "name": "Licheng",
      "names": {
        "zh": "里程"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.9105711,
        "lng": 118.5457419
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.4920228,
        24.8346385,
        118.5986138,
        24.947038
      ]
    },
    {
      "id": 1817,
      "name": "Koshigaya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8903993,
        "lng": 139.7908633
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.745233,
        35.854722,
        139.840167,
        35.95857
      ]
    },
    {
      "id": 1818,
      "name": "Sinuiju",
      "names": {
        "zh": "新义州"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.10056,
        "lng": 124.39806
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        124.37806,
        40.08056,
        124.41806,
        40.12056
      ]
    },
    {
      "id": 1819,
      "name": "Cabimas",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.395075,
        "lng": -71.4414049
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -71.6014049,
        10.235075,
        -71.2814049,
        10.555075
      ]
    },
    {
      "id": 1820,
      "name": "Yueqing",
      "names": {
        "zh": "乐清市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1178498,
        "lng": 120.9795761
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.7816744,
        27.9602916,
        121.2537867,
        28.5413783
      ]
    },
    {
      "id": 1821,
      "name": "Yakeshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 49.285437,
        "lng": 120.7283079
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.5683079,
        49.125437,
        120.8883079,
        49.445437
      ]
    },
    {
      "id": 1822,
      "name": "Ahmadnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.074352,
        "lng": 74.7219979
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.7169979,
        19.069352,
        74.7269979,
        19.079352
      ]
    },
    {
      "id": 1823,
      "name": "Fayetteville",
      "names": {
        "zh": "费耶特维尔",
        "ja": "フェイエットヴィル",
        "th": "ฟาเยตต์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0625843,
        "lng": -94.1574328
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.2977782,
        35.9893558,
        -94.0267113,
        36.1489329
      ]
    },
    {
      "id": 1824,
      "name": "Takatsuki",
      "names": {
        "zh": "高月"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8517211,
        "lng": 135.6178493
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6128493,
        34.8467211,
        135.6228493,
        34.8567211
      ]
    },
    {
      "id": 1825,
      "name": "Holguin",
      "names": {
        "es": "Holguín",
        "zh": "霍尔金"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.8869704,
        "lng": -76.2591995
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -76.5158974,
        20.7611887,
        -76.0985677,
        21.0329899
      ]
    },
    {
      "id": 1826,
      "name": "Qoqon",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.5408463,
        "lng": 70.9473889
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        70.8795226,
        40.4575884,
        71.0013665,
        40.5893755
      ]
    },
    {
      "id": 1827,
      "name": "Anaheim",
      "names": {
        "zh": "阿纳海姆",
        "ja": "アナハイム",
        "th": "อนาไฮม์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8347516,
        "lng": -117.911732
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0173585,
        33.7889241,
        -117.6739673,
        33.8790572
      ]
    },
    {
      "id": 1828,
      "name": "Semey",
      "names": {
        "zh": "塞梅伊"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 50.404377,
        "lng": 80.2496637
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        80.1079969,
        50.3329157,
        80.4604285,
        50.4789706
      ]
    },
    {
      "id": 1829,
      "name": "Varna",
      "names": {
        "zh": "瓦尔纳",
        "ja": "ヴァルナ",
        "th": "วาร์"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2073873,
        "lng": 27.9166653
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        27.829908,
        43.100229,
        28.0562129,
        43.310216
      ]
    },
    {
      "id": 1830,
      "name": "Yingmen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7258,
        "lng": 98.5314
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        98.5114,
        39.7058,
        98.5514,
        39.7458
      ]
    },
    {
      "id": 1831,
      "name": "Cariacica",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.263202,
        "lng": -40.416549
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.6052483,
        -20.3997724,
        -40.3289437,
        -20.2148644
      ]
    },
    {
      "id": 1832,
      "name": "Palmira",
      "names": {
        "zh": "帕尔米拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.5308373,
        "lng": -76.2988048
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.4917422,
        3.4516233,
        -75.9496665,
        3.7180679
      ]
    },
    {
      "id": 1833,
      "name": "Tapachula",
      "names": {
        "zh": "塔帕丘拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 14.900547,
        "lng": -92.26818
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.3219073939,
        14.8647687769,
        -92.2340160597,
        14.9579472713
      ]
    },
    {
      "id": 1834,
      "name": "Konak",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4185042,
        "lng": 27.1290835
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.0688039,
        38.3930028,
        27.1896253,
        38.4508181
      ]
    },
    {
      "id": 1835,
      "name": "Bydgoszcz",
      "names": {
        "zh": "比得哥什",
        "ja": "ブィドゴシュチュ",
        "th": "บิดกอชช์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1219648,
        "lng": 18.0002529
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        17.8741716,
        53.0501096,
        18.2025912,
        53.2093439
      ]
    },
    {
      "id": 1836,
      "name": "Hatay",
      "names": {
        "zh": "哈塔伊",
        "ja": "ハタイ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.2025471,
        "lng": 36.1602908
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.0002908,
        36.0425471,
        36.3202908,
        36.3625471
      ]
    },
    {
      "id": 1837,
      "name": "Antofagasta",
      "names": {
        "zh": "安多法加斯大",
        "th": "อานโตฟากัสตา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -23.6463741,
        "lng": -70.3980033
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -70.6286037,
        -25.4019573,
        -68.0670324,
        -23.0583169
      ]
    },
    {
      "id": 1838,
      "name": "Rajahmundry",
      "names": {
        "zh": "拉贾蒙德里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.9840432,
        "lng": 81.7839579
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.7789579,
        16.9790432,
        81.7889579,
        16.9890432
      ]
    },
    {
      "id": 1839,
      "name": "Chita",
      "names": {
        "zh": "赤塔",
        "ja": "チタ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.033409,
        "lng": 113.500893
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        113.1336143,
        51.8849599,
        113.6621271,
        52.1727587
      ]
    },
    {
      "id": 1840,
      "name": "Chitungwiza",
      "names": {},
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.0143462,
        "lng": 31.0726737
      },
      "country": "Zimbabwe",
      "importance": 3,
      "bbox": [
        30.9126737,
        -18.1743462,
        31.2326737,
        -17.8543462
      ]
    },
    {
      "id": 1841,
      "name": "Caruaru",
      "names": {
        "zh": "卡鲁阿鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.2829702,
        "lng": -35.9722852
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.1806799,
        -8.398782,
        -35.8515569,
        -7.9752472
      ]
    },
    {
      "id": 1842,
      "name": "Utrecht",
      "names": {
        "zh": "乌得勒支",
        "ja": "ユトレヒト",
        "th": "อูเทรกต์"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0907006,
        "lng": 5.1215634
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        4.970096,
        52.026282,
        5.195155,
        52.1420506
      ]
    },
    {
      "id": 1843,
      "name": "Pensacola",
      "names": {
        "zh": "彭萨科拉",
        "ja": "ペンサコーラ",
        "th": "เพนซาโคลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.4197011,
        "lng": -87.1973566
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.2023566,
        30.4147011,
        -87.1923566,
        30.4247011
      ]
    },
    {
      "id": 1844,
      "name": "Victorville",
      "names": {
        "zh": "维克托维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5374152,
        "lng": -117.2929103
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2929603,
        34.5373652,
        -117.2928603,
        34.5374652
      ]
    },
    {
      "id": 1845,
      "name": "Tanch'on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 40.4590505,
        "lng": 128.9038646
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        128.4469985,
        40.283193,
        129.2690732,
        41.1851784
      ]
    },
    {
      "id": 1846,
      "name": "Tokorozawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7994271,
        "lng": 139.4687478
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.378941,
        35.763285,
        139.545549,
        35.843601
      ]
    },
    {
      "id": 1847,
      "name": "Nice",
      "names": {
        "es": "Bonito",
        "fr": "Joli",
        "zh": "尼斯",
        "de": "Nett",
        "it": "Simpatico",
        "id": "Bagus",
        "ja": "ニース",
        "th": "ดี",
        "pt": "Legal"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.7009358,
        "lng": 7.2683912
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.1819535,
        43.6454189,
        7.323912,
        43.7607635
      ]
    },
    {
      "id": 1848,
      "name": "Sumqayit",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5876185,
        "lng": 49.6661492
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        49.6660992,
        40.5875685,
        49.6661992,
        40.5876685
      ]
    },
    {
      "id": 1849,
      "name": "Kusti",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 13.1521864,
        "lng": 32.6725113
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.5125113,
        12.9921864,
        32.8325113,
        13.3121864
      ]
    },
    {
      "id": 1850,
      "name": "Cuddapah",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.468942,
        "lng": 78.827644
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.794326,
        14.449262,
        78.848431,
        14.497481
      ]
    },
    {
      "id": 1851,
      "name": "Simferopol",
      "names": {
        "zh": "辛菲罗波尔",
        "ja": "シンフェロポリ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 44.9521459,
        "lng": 34.1024858
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        34.0289229,
        44.8908263,
        34.1989159,
        45.0042505
      ]
    },
    {
      "id": 1852,
      "name": "Otsu",
      "names": {
        "zh": "大津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0030494,
        "lng": 135.8648951
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        135.8598951,
        34.9980494,
        135.8698951,
        35.0080494
      ]
    },
    {
      "id": 1853,
      "name": "Vitoria da Conquista",
      "names": {
        "pt": "Vitória da Conquista"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -14.8661242,
        "lng": -40.8293373
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.8294235,
        -14.8662788,
        -40.8291349,
        -14.8658367
      ]
    },
    {
      "id": 1854,
      "name": "Lancaster",
      "names": {
        "zh": "兰开斯特",
        "ja": "ランカスター",
        "th": "แลงคาสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6981064,
        "lng": -118.1366153
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.325179,
        34.6309639,
        -117.938669,
        34.768855
      ]
    },
    {
      "id": 1855,
      "name": "Makiivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0448144,
        "lng": 37.9635093
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.8139604,
        47.9707648,
        38.1089101,
        48.1426932
      ]
    },
    {
      "id": 1856,
      "name": "East London",
      "names": {
        "es": "Este de Londres",
        "zh": "东伦敦",
        "id": "London Timur",
        "ja": "イーストロンドン",
        "th": "ตะวันออกลอนดอน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.0166531,
        "lng": 27.9077491
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.9027491,
        -33.0216531,
        27.9127491,
        -33.0116531
      ]
    },
    {
      "id": 1857,
      "name": "Greensboro",
      "names": {
        "zh": "格林斯博罗",
        "ja": "グリーンズボロ",
        "th": "กรีนสโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0726355,
        "lng": -79.7919754
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.017884,
        35.9758146,
        -79.621166,
        36.2153577
      ]
    },
    {
      "id": 1858,
      "name": "Uruapan",
      "names": {
        "zh": "乌鲁阿潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4147269,
        "lng": -102.0522647
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.2122647,
        19.2547269,
        -101.8922647,
        19.5747269
      ]
    },
    {
      "id": 1859,
      "name": "Gomez Palacio",
      "names": {
        "zh": "戈麦斯帕拉西奥",
        "ja": "ゴメスパラシオ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.5732473,
        "lng": -103.494779
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.654779,
        25.4132473,
        -103.334779,
        25.7332473
      ]
    },
    {
      "id": 1860,
      "name": "Franca",
      "names": {
        "zh": "纳弗兰卡",
        "ja": "フランカ",
        "th": "กลาง"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.5381768,
        "lng": -47.4009795
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.5525278,
        -20.7735077,
        -47.145582,
        -20.4158594
      ]
    },
    {
      "id": 1861,
      "name": "Brest",
      "names": {
        "zh": "布雷斯特",
        "ja": "ブレスト",
        "th": "เบรสต์"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.093751,
        "lng": 23.6851851
      },
      "country": "Belarus",
      "importance": 1,
      "bbox": [
        23.565437,
        52.023784,
        23.8543868,
        52.1510404
      ]
    },
    {
      "id": 1862,
      "name": "Kaluga",
      "names": {
        "zh": "卡卢加"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5101087,
        "lng": 36.2598115
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        35.9879869,
        54.4585078,
        36.4043066,
        54.6143805
      ]
    },
    {
      "id": 1863,
      "name": "Corpus Christi",
      "names": {
        "zh": "语料库克里斯蒂",
        "de": "Der Leib Christi",
        "ja": "コーパスクリスティ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.7635302,
        "lng": -97.4033191
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.682742,
        27.483569,
        -96.961108,
        27.899638
      ]
    },
    {
      "id": 1864,
      "name": "Muzaffarpur",
      "names": {
        "zh": "穆扎法尔布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.1182898,
        "lng": 85.3857888
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        85.2257888,
        25.9582898,
        85.5457888,
        26.2782898
      ]
    },
    {
      "id": 1865,
      "name": "Yeosu",
      "names": {
        "zh": "丽水"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.7530343,
        "lng": 127.74880403351227
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        127.7481729,
        34.7527905,
        127.7494649,
        34.7533107
      ]
    },
    {
      "id": 1866,
      "name": "Lublin",
      "names": {
        "zh": "卢布林",
        "ja": "ルブリン",
        "th": "ริน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.239457,
        "lng": 22.567676
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        22.454282,
        51.13981,
        22.673543,
        51.29656
      ]
    },
    {
      "id": 1867,
      "name": "Alwar",
      "names": {
        "zh": "阿尔瓦尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5645949,
        "lng": 76.6132248
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.5869153,
        27.5348613,
        76.6270841,
        27.5950488
      ]
    },
    {
      "id": 1868,
      "name": "Brahmapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.3098134,
        "lng": 84.7971562
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.6371562,
        19.1498134,
        84.9571562,
        19.4698134
      ]
    },
    {
      "id": 1869,
      "name": "Lianran",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.9198007,
        "lng": 102.4694198
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.4324961,
        24.8870403,
        102.5451149,
        24.9900543
      ]
    },
    {
      "id": 1870,
      "name": "Buon Ma Thuot",
      "names": {
        "ja": "ブンマサート"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.66667,
        "lng": 108.05
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        108.03,
        12.64667,
        108.07,
        12.68667
      ]
    },
    {
      "id": 1871,
      "name": "Cuernavaca",
      "names": {
        "zh": "库埃纳瓦卡",
        "ja": "クエルナバカ",
        "th": "กูเอร์นาวากา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9218274,
        "lng": -99.2342282
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -99.3942282,
        18.7618274,
        -99.0742282,
        19.0818274
      ]
    },
    {
      "id": 1872,
      "name": "Beni",
      "names": {
        "zh": "贝尼",
        "ja": "ベニ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.3974023,
        "lng": 29.211717843210657
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        28.9994967,
        -0.1591822,
        29.987297,
        0.9541991
      ]
    },
    {
      "id": 1873,
      "name": "Randburg",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.096111,
        "lng": 27.975
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.815,
        -26.256111,
        28.135,
        -25.936111
      ]
    },
    {
      "id": 1874,
      "name": "Alicante",
      "names": {
        "zh": "阿利坎特",
        "ja": "アリカンテ",
        "th": "อาลีกันเต"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.3436365,
        "lng": -0.4881708
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -0.656695,
        38.1603885,
        -0.3750868,
        38.5370661
      ]
    },
    {
      "id": 1875,
      "name": "Jitpur",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6862562,
        "lng": 85.4754065
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        85.4554065,
        27.6662562,
        85.4954065,
        27.7062562
      ]
    },
    {
      "id": 1876,
      "name": "Kaesong",
      "names": {
        "zh": "开城"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 37.9622857,
        "lng": 126.5568175
      },
      "country": "North Korea",
      "importance": 2,
      "bbox": [
        126.5567675,
        37.9622357,
        126.5568675,
        37.9623357
      ]
    },
    {
      "id": 1877,
      "name": "Petrolina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -9.3817334,
        "lng": -40.4968875
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.9590393,
        -9.4830959,
        -40.208,
        -8.6234993
      ]
    },
    {
      "id": 1878,
      "name": "Chinju",
      "names": {
        "zh": "晋州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.188194,
        "lng": 128.080489
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.018197,
        35.124371,
        128.171993,
        35.223226
      ]
    },
    {
      "id": 1879,
      "name": "Tangdong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.9757198,
        "lng": 113.2251493
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.2051493,
        25.9557198,
        113.2451493,
        25.9957198
      ]
    },
    {
      "id": 1880,
      "name": "Hangu",
      "names": {
        "zh": "汉沽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.361093,
        "lng": 117.8748363
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.7962235,
        39.2594618,
        118.0322208,
        39.4102129
      ]
    },
    {
      "id": 1881,
      "name": "Belfast",
      "names": {
        "zh": "贝尔法斯特",
        "ja": "ベルファスト",
        "th": "เบลฟัสต์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.596391,
        "lng": -5.9301829
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -6.0454798,
        54.5306104,
        -5.8076879,
        54.6594247
      ]
    },
    {
      "id": 1882,
      "name": "Iwaki",
      "names": {
        "zh": "岩城",
        "ja": "いわき"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.0504227,
        "lng": 140.8876338
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.565869,
        36.7443355,
        141.2596767,
        37.319544
      ]
    },
    {
      "id": 1883,
      "name": "Blida",
      "names": {
        "zh": "卜利达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.4705658,
        "lng": 2.8274937
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.7327134,
        36.4435374,
        2.8683281,
        36.5172311
      ]
    },
    {
      "id": 1884,
      "name": "Salamanca",
      "names": {
        "zh": "萨拉曼卡",
        "ja": "サラマンカ",
        "th": "ซาลามันกา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.65232455,
        "lng": -101.16684156787477
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.3134206,
        20.4368646,
        -101.0330697,
        20.8668882
      ]
    },
    {
      "id": 1885,
      "name": "Yingzhong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.00328,
        "lng": 119.5102
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.4902,
        32.98328,
        119.5302,
        33.02328
      ]
    },
    {
      "id": 1886,
      "name": "Hirosaki",
      "names": {
        "zh": "弘前",
        "ja": "弘前"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.6030543,
        "lng": 140.4640389
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.154721,
        40.467553,
        140.598703,
        40.761425
      ]
    },
    {
      "id": 1887,
      "name": "Vina del Mar",
      "names": {
        "es": "Viña del mar"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0244535,
        "lng": -71.5517636
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -71.5870644,
        -33.1042507,
        -71.4426332,
        -32.9452779
      ]
    },
    {
      "id": 1888,
      "name": "Nazret",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 8.55,
        "lng": 39.26667
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        39.24667,
        8.53,
        39.28667,
        8.57
      ]
    },
    {
      "id": 1889,
      "name": "Bielefeld",
      "names": {
        "zh": "比勒费尔德",
        "ja": "ビーレフェルト",
        "th": "บีเลเฟลด์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.0191005,
        "lng": 8.531007
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.3778169,
        51.9148708,
        8.6633538,
        52.114773
      ]
    },
    {
      "id": 1890,
      "name": "Cuenca",
      "names": {
        "zh": "昆卡",
        "ja": "クエンカ",
        "th": "เควงคา"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.8974072,
        "lng": -79.0041726
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.0042226,
        -2.8974572,
        -79.0041226,
        -2.8973572
      ]
    },
    {
      "id": 1891,
      "name": "Al Fayyum",
      "names": {
        "ja": "アルファイム",
        "th": "อัลฟียา"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.3074076,
        "lng": 30.8399651
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.6799651,
        29.1474076,
        30.9999651,
        29.4674076
      ]
    },
    {
      "id": 1892,
      "name": "Fort Wayne",
      "names": {
        "fr": "Forte wayne",
        "zh": "韦恩堡",
        "ja": "フォートウェイン",
        "th": "ฟอร์ตเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.084969900000004,
        "lng": -85.13659820603448
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.1371979,
        41.0840265,
        -85.1357845,
        41.0859658
      ]
    },
    {
      "id": 1893,
      "name": "Ciudad Obregon",
      "names": {
        "es": "Ciudad Obregón"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.484654,
        "lng": -109.935961
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.013131,
        27.43182,
        -109.872653,
        27.532744
      ]
    },
    {
      "id": 1894,
      "name": "Wad Medani",
      "names": {
        "de": "Wath medani",
        "ja": "ワットメダニ",
        "th": "ปึกมานาน"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 14.40118,
        "lng": 33.51989
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        33.49989,
        14.38118,
        33.53989,
        14.42118
      ]
    },
    {
      "id": 1895,
      "name": "Ribeirao das Neves",
      "names": {
        "it": "Ribeirao das nefes",
        "pt": "Ribeirão das Neves"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.7672898,
        "lng": -44.0875436
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.164716,
        -19.8457696,
        -43.975,
        -19.709
      ]
    },
    {
      "id": 1896,
      "name": "Soledad de Graciano Sanchez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.1874129,
        "lng": -100.9368949
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.9768949,
        22.1474129,
        -100.8968949,
        22.2274129
      ]
    },
    {
      "id": 1897,
      "name": "Bonn",
      "names": {
        "zh": "波恩",
        "ja": "ボン",
        "th": "บอนน์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7352621,
        "lng": 7.1024635
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0225354,
        50.6326909,
        7.2106794,
        50.7744233
      ]
    },
    {
      "id": 1898,
      "name": "Kamarhati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.6810246,
        "lng": 88.3715343
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3315343,
        22.6410246,
        88.4115343,
        22.7210246
      ]
    },
    {
      "id": 1899,
      "name": "Maebashi",
      "names": {
        "zh": "前桥"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3832125,
        "lng": 139.0726408
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        139.0676408,
        36.3782125,
        139.0776408,
        36.3882125
      ]
    },
    {
      "id": 1900,
      "name": "Thessaloniki",
      "names": {
        "es": "Salónica",
        "fr": "Thessalonique",
        "zh": "塞萨洛尼基",
        "it": "Salonicco",
        "ja": "テッサロニキ",
        "th": "เทสซาโล"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.6403167,
        "lng": 22.9352716
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        22.7752716,
        40.4803167,
        23.0952716,
        40.8003167
      ]
    },
    {
      "id": 1901,
      "name": "Ganca",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.68278,
        "lng": 46.36056
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.34056,
        40.66278,
        46.38056,
        40.70278
      ]
    },
    {
      "id": 1902,
      "name": "Mymensingh",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 24.75636,
        "lng": 90.40646
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        90.38646,
        24.73636,
        90.42646,
        24.77636
      ]
    },
    {
      "id": 1903,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7494951,
        "lng": -117.873221
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.9439459,
        33.6916541,
        -117.8303495,
        33.7840133
      ]
    },
    {
      "id": 1904,
      "name": "Flint",
      "names": {
        "fr": "Silex",
        "zh": "火石",
        "it": "Selce",
        "ja": "フリント",
        "th": "หินเหล็กไฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0161693,
        "lng": -83.6900211
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.7722846,
        42.945636,
        -83.6206489,
        43.0793357
      ]
    },
    {
      "id": 1905,
      "name": "Kendari",
      "names": {
        "zh": "肯达里"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.9918068,
        "lng": 122.5180066
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        122.4338285,
        -4.0869523,
        122.6508095,
        -3.9014259
      ]
    },
    {
      "id": 1906,
      "name": "Thai Nguyen",
      "names": {
        "es": "Tailandés nguyen",
        "zh": "泰国尼文",
        "ja": "タイのNguyen",
        "th": "ไทยเหงียน"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.592477,
        "lng": 105.8435398
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.7021397,
        21.5074696,
        105.9161777,
        21.6676037
      ]
    },
    {
      "id": 1907,
      "name": "Smolensk",
      "names": {
        "zh": "斯摩棱斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7814057,
        "lng": 32.0461261
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        31.7883262,
        54.7075603,
        32.2095639,
        54.850378
      ]
    },
    {
      "id": 1908,
      "name": "Asahikawa",
      "names": {
        "zh": "旭川",
        "ja": "旭川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.770625,
        "lng": 142.3649743
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        142.148252,
        43.544303,
        142.741542,
        43.956223
      ]
    },
    {
      "id": 1909,
      "name": "Islip",
      "names": {
        "zh": "艾斯利普"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7300685,
        "lng": -73.2107266
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.318701,
        40.571789,
        -73.037675,
        40.832845
      ]
    },
    {
      "id": 1910,
      "name": "Dahuk",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.8542598,
        "lng": 42.9924528
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        42.8324528,
        36.6942598,
        43.1524528,
        37.0142598
      ]
    },
    {
      "id": 1911,
      "name": "Wonju",
      "names": {
        "zh": "原州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3420996,
        "lng": 127.9197603
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.7441004,
        37.1434083,
        128.218455,
        37.5069099
      ]
    },
    {
      "id": 1912,
      "name": "Mathura",
      "names": {
        "zh": "马图拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.4955539,
        "lng": 77.6855554
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.5255554,
        27.3355539,
        77.8455554,
        27.6555539
      ]
    },
    {
      "id": 1913,
      "name": "Nakuru",
      "names": {
        "zh": "纳库鲁"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.2802724,
        "lng": 36.0712048
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.0161952,
        -0.355768,
        36.1942638,
        -0.2423128
      ]
    },
    {
      "id": 1914,
      "name": "Lafia",
      "names": {
        "zh": "拉菲亚"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.4960459,
        "lng": 8.5147636
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        8.1740828,
        8.3951979,
        9.0734615,
        9.0270939
      ]
    },
    {
      "id": 1915,
      "name": "Koriyama",
      "names": {
        "zh": "郡山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.404937,
        "lng": 140.333381
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.035984,
        37.266026,
        140.564389,
        37.625978
      ]
    },
    {
      "id": 1916,
      "name": "Bamiantong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.9247158,
        "lng": 130.5349935
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.5299935,
        44.9197158,
        130.5399935,
        44.9297158
      ]
    },
    {
      "id": 1917,
      "name": "Patiala",
      "names": {
        "zh": "帕蒂亚拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.3295605,
        "lng": 76.4127819
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.3342254,
        30.2893,
        76.466984,
        30.3818198
      ]
    },
    {
      "id": 1918,
      "name": "Taourirt",
      "names": {
        "zh": "陶里尔特"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.413438,
        "lng": -2.893825
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -2.9267175,
        34.3830494,
        -2.8693214,
        34.4265357
      ]
    },
    {
      "id": 1919,
      "name": "Izmir",
      "names": {
        "zh": "伊兹密尔",
        "ja": "イズミル",
        "th": "อิซเมียร์"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4237433,
        "lng": 27.1428019
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        26.9828019,
        38.2637433,
        27.3028019,
        38.5837433
      ]
    },
    {
      "id": 1920,
      "name": "Vung Tau",
      "names": {
        "id": "Lawan",
        "ja": "ヴungtau",
        "th": "หวู่เต่า",
        "pt": "Vógina tau"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.393492,
        "lng": 107.13944
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.043674,
        10.317889,
        107.212965,
        10.48604
      ]
    },
    {
      "id": 1921,
      "name": "Cabanatuan City",
      "names": {
        "es": "Ciudad del Cabanatuan",
        "de": "Cabanatuan-Stadt",
        "id": "Kota cabanatuan",
        "ja": "カバナタン市",
        "pt": "Cidade de Cabanatuan"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4881161,
        "lng": 120.9643662
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9543662,
        15.4781161,
        120.9743662,
        15.4981161
      ]
    },
    {
      "id": 1922,
      "name": "Markham",
      "names": {
        "zh": "马克姆",
        "ja": "マーカム",
        "th": "มาร์คัม"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8826569,
        "lng": -79.2624676
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.2674676,
        43.8776569,
        -79.2574676,
        43.8876569
      ]
    },
    {
      "id": 1923,
      "name": "Saugor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.83877,
        "lng": 78.73874
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1924,
      "name": "Pelotas",
      "names": {
        "zh": "佩洛塔斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -31.7699736,
        "lng": -52.3410161
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.61173,
        -31.8008367,
        -52.0097242,
        -31.3223832
      ]
    },
    {
      "id": 1925,
      "name": "Roodepoort",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.1592077,
        "lng": 27.8700823
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.8650823,
        -26.1642077,
        27.8750823,
        -26.1542077
      ]
    },
    {
      "id": 1926,
      "name": "Volzhskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.7862021,
        "lng": 44.7519454
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        44.6284558,
        48.7236665,
        44.8931449,
        48.93766
      ]
    },
    {
      "id": 1927,
      "name": "Bijapur",
      "names": {
        "th": "พิช"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8269911,
        "lng": 75.7175387
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.5575387,
        16.6669911,
        75.8775387,
        16.9869911
      ]
    },
    {
      "id": 1928,
      "name": "Sukabumi",
      "names": {
        "zh": "苏卡布米"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.9199289,
        "lng": 106.9265095
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        106.8716656,
        -6.978919,
        106.96017,
        -6.892417
      ]
    },
    {
      "id": 1929,
      "name": "Fayetteville",
      "names": {
        "zh": "费耶特维尔",
        "ja": "フェイエットヴィル",
        "th": "ฟาเยตต์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0525759,
        "lng": -78.878292
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.091726,
        34.9766114,
        -78.781685,
        35.170186
      ]
    },
    {
      "id": 1930,
      "name": "Ulanhot",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.0698111,
        "lng": 122.0873444
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.8732065,
        45.6987533,
        122.8207416,
        46.2957523
      ]
    },
    {
      "id": 1931,
      "name": "Yunzhong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8268666,
        "lng": 112.4422318
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.4421818,
        38.8268166,
        112.4422818,
        38.8269166
      ]
    },
    {
      "id": 1932,
      "name": "Cotabato",
      "names": {
        "zh": "哥塔巴托"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2237628,
        "lng": 124.2467062
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.0823292,
        7.134787,
        124.3163887,
        7.2894441
      ]
    },
    {
      "id": 1933,
      "name": "Al Fallujah",
      "names": {
        "ja": "アルフォールザー",
        "th": "อัลฟอลลูจาห์"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.34913,
        "lng": 43.78599
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 1934,
      "name": "Kochi",
      "names": {
        "zh": "高知",
        "ja": "高知",
        "th": "โคชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5680384,
        "lng": 133.5394221
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        133.394351,
        33.2394231,
        133.7137678,
        33.682032
      ]
    },
    {
      "id": 1935,
      "name": "Minna",
      "names": {
        "zh": "大众"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.6187093,
        "lng": 6.5475752
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.3875752,
        9.4587093,
        6.7075752,
        9.7787093
      ]
    },
    {
      "id": 1936,
      "name": "Boa Vista",
      "names": {
        "ja": "ボアビスタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": 2.8208478,
        "lng": -60.6719582
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -61.0005115,
        2.4288383,
        -60.2865206,
        3.606
      ]
    },
    {
      "id": 1937,
      "name": "Cluj-Napoca",
      "names": {
        "zh": "克卢日 - 纳波卡",
        "ja": "クルージュ=ナポカ",
        "th": "ลูช-napoca"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.7778105,
        "lng": 23.5713893
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        23.5713393,
        46.7777605,
        23.5714393,
        46.7778605
      ]
    },
    {
      "id": 1938,
      "name": "Canoas",
      "names": {
        "zh": "卡诺阿斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.9216045,
        "lng": -51.1799525
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.2491885,
        -29.9718246,
        -51.1060381,
        -29.8588664
      ]
    },
    {
      "id": 1939,
      "name": "Gwangmyeongni",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.46159,
        "lng": 126.85208
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.83208,
        37.44159,
        126.87208,
        37.48159
      ]
    },
    {
      "id": 1940,
      "name": "Bari",
      "names": {
        "zh": "巴里",
        "ja": "バリ",
        "th": "บารี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1257843,
        "lng": 16.8620293
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        16.7172376,
        41.0160584,
        17.0334109,
        41.1697837
      ]
    },
    {
      "id": 1941,
      "name": "Pucallpa",
      "names": {
        "zh": "普卡尔帕"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -8.37915,
        "lng": -74.55387
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -74.57387,
        -8.39915,
        -74.53387,
        -8.35915
      ]
    },
    {
      "id": 1942,
      "name": "Yildirim",
      "names": {
        "zh": "耶尔德勒姆"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1900988,
        "lng": 29.097653
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.0612405,
        40.0729586,
        29.2250369,
        40.2540391
      ]
    },
    {
      "id": 1943,
      "name": "Kuching",
      "names": {
        "zh": "古晋",
        "ja": "クチン",
        "th": "กูชิง"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 1.540847,
        "lng": 110.353696
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        110.32187,
        1.50143,
        110.36187,
        1.54143
      ]
    },
    {
      "id": 1944,
      "name": "Tepic",
      "names": {
        "zh": "特皮克"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.5119156,
        "lng": -104.8915889
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -105.0515889,
        21.3519156,
        -104.7315889,
        21.6719156
      ]
    },
    {
      "id": 1945,
      "name": "Caucaia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7300563,
        "lng": -38.6593082
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.9994962,
        -3.9948709,
        -38.587313,
        -3.5486434
      ]
    },
    {
      "id": 1946,
      "name": "Gonder",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 12.6,
        "lng": 37.46667
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        37.45667,
        12.59,
        37.47667,
        12.61
      ]
    },
    {
      "id": 1947,
      "name": "Uberaba",
      "names": {
        "zh": "乌贝拉巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.750833,
        "lng": -47.936666
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.3572492,
        -20.1179994,
        -47.582,
        -19.0840706
      ]
    },
    {
      "id": 1948,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.2998686,
        "lng": -90.1830408
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -90.3299278,
        32.1955013,
        -90.0636172,
        32.4124415
      ]
    },
    {
      "id": 1949,
      "name": "Mekele",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 13.4966644,
        "lng": 39.4768259
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        39.443802,
        13.474921,
        39.51051,
        13.53166
      ]
    },
    {
      "id": 1950,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.4404925,
        "lng": -122.7141049
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.8343404,
        38.3643324,
        -122.5699915,
        38.5080485
      ]
    },
    {
      "id": 1951,
      "name": "Gonaives",
      "names": {
        "fr": "Gonaïves",
        "zh": "戈纳伊夫"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.4460597,
        "lng": -72.6884336
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -72.8484336,
        19.2860597,
        -72.5284336,
        19.6060597
      ]
    },
    {
      "id": 1952,
      "name": "Guasave",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.5738,
        "lng": -108.4674697
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.6274697,
        25.4138,
        -108.3074697,
        25.7338
      ]
    },
    {
      "id": 1953,
      "name": "Lansing",
      "names": {
        "zh": "兰辛",
        "ja": "ランシング",
        "th": "แลนซิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7337712,
        "lng": -84.5553805
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -84.701284,
        42.647483,
        -84.417581,
        42.7949754
      ]
    },
    {
      "id": 1954,
      "name": "Naha",
      "names": {
        "zh": "那霸",
        "ja": "那覇",
        "th": "นะฮะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.2122345,
        "lng": 127.6791452
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        127.560305,
        26.176419,
        127.738662,
        26.2552228
      ]
    },
    {
      "id": 1955,
      "name": "Binxian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.03222,
        "lng": 108.0725
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.0525,
        35.01222,
        108.0925,
        35.05222
      ]
    },
    {
      "id": 1956,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.384239049999998,
        "lng": -66.05343997364733
      },
      "country": "Puerto Rico",
      "importance": 0,
      "bbox": [
        -66.1250904,
        18.2965306,
        -65.9914197,
        18.4719861
      ]
    },
    {
      "id": 1957,
      "name": "Lexington",
      "names": {
        "zh": "列克星敦",
        "ja": "レキシントン",
        "th": "เล็กซิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0464066,
        "lng": -84.4970393
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.6601308,
        37.8452952,
        -84.282715,
        38.211404
      ]
    },
    {
      "id": 1958,
      "name": "Hotan",
      "names": {
        "zh": "和田"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1144827,
        "lng": 79.9200157
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        77.3991928,
        34.3340633,
        84.9837541,
        39.6227473
      ]
    },
    {
      "id": 1959,
      "name": "Dongyang",
      "names": {
        "zh": "东阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2924528,
        "lng": 120.2372669
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.0721265,
        28.9688999,
        120.7348303,
        29.498686
      ]
    },
    {
      "id": 1960,
      "name": "Ciudad del Este",
      "names": {
        "ja": "シウダードデルエステ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.5169015,
        "lng": -54.6168645
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -54.7402209,
        -25.6002611,
        -54.5938773,
        -25.386714
      ]
    },
    {
      "id": 1961,
      "name": "Kaiyuan",
      "names": {
        "zh": "开元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7183499,
        "lng": 103.2674592
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.1074592,
        23.5583499,
        103.4274592,
        23.8783499
      ]
    },
    {
      "id": 1962,
      "name": "Temara",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.917166,
        "lng": -6.923804
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.9519104,
        33.8897908,
        -6.8795742,
        33.956559
      ]
    },
    {
      "id": 1963,
      "name": "Uige",
      "names": {
        "zh": "威热",
        "pt": "Uíge"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -7.6117323,
        "lng": 15.0563515
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        14.8963515,
        -7.7717323,
        15.2163515,
        -7.4517323
      ]
    },
    {
      "id": 1964,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.8845813,
        "lng": -4.7760138
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -4.9985994,
        37.6658228,
        -4.3514283,
        38.0315171
      ]
    },
    {
      "id": 1965,
      "name": "Camaguey",
      "names": {
        "es": "Camagüey",
        "zh": "卡马圭"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.3791765,
        "lng": -77.9183681
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -78.1465488,
        21.2031519,
        -77.6600147,
        21.6260322
      ]
    },
    {
      "id": 1966,
      "name": "Ann Arbor",
      "names": {
        "es": "Annico",
        "zh": "安娜堡",
        "ja": "アナーバー",
        "th": "แอนอาร์เบอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2813722,
        "lng": -83.7484616
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.799572,
        42.222668,
        -83.675807,
        42.3238941
      ]
    },
    {
      "id": 1967,
      "name": "San Salvador de Jujuy",
      "names": {
        "zh": "圣萨尔瓦多德枣",
        "ja": "サンサルバドールデュジュエ",
        "th": "ซานซัลวาดอร์เดอสวรรค์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.1852569,
        "lng": -65.2994789
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -65.7250173,
        -24.2962422,
        -65.109099,
        -23.881012
      ]
    },
    {
      "id": 1968,
      "name": "Timisoara",
      "names": {
        "zh": "蒂米什瓦拉",
        "ja": "ティミショアラ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.7538355,
        "lng": 21.2257474
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        21.1116821,
        45.6838246,
        21.3272446,
        45.8341735
      ]
    },
    {
      "id": 1969,
      "name": "Al Kut",
      "names": {
        "de": "Alk",
        "ja": "アルカット",
        "th": "อัลกุด"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.5010274,
        "lng": 45.8296334
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        45.5116962,
        32.3545204,
        46.1503059,
        32.7944209
      ]
    },
    {
      "id": 1970,
      "name": "Shahjanpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.88165,
        "lng": 79.90918
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1971,
      "name": "Cherepovets",
      "names": {
        "zh": "切列波韦茨"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.128696,
        "lng": 37.916389
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.6783822,
        59.0664728,
        38.0446101,
        59.183198
      ]
    },
    {
      "id": 1972,
      "name": "San Miguelito",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.0304347,
        "lng": -79.50533532031017
      },
      "country": "Panama",
      "importance": 2,
      "bbox": [
        -79.5057549,
        9.0303687,
        -79.5049171,
        9.0305799
      ]
    },
    {
      "id": 1973,
      "name": "Menongue",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.6610713,
        "lng": 17.6820336
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        17.5220336,
        -14.8210713,
        17.8420336,
        -14.5010713
      ]
    },
    {
      "id": 1974,
      "name": "Junagadh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.5220416,
        "lng": 70.4582458
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        70.393964,
        21.4882792,
        70.4784169,
        21.5487583
      ]
    },
    {
      "id": 1975,
      "name": "Katsina",
      "names": {
        "zh": "卡齐纳",
        "th": "คัตซินา"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 12.9894283,
        "lng": 7.6006278
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        7.5557609,
        12.9511991,
        7.6894753,
        13.0861224
      ]
    },
    {
      "id": 1976,
      "name": "Henderson",
      "names": {
        "zh": "亨德森",
        "ja": "ヘンダーソン",
        "th": "เฮนเดอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.182598,
        "lng": -95.7893178
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.4548057,
        32.0058463,
        -95.428374,
        32.3590792
      ]
    },
    {
      "id": 1977,
      "name": "Poltava",
      "names": {
        "zh": "波尔塔瓦"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5897423,
        "lng": 34.5507948
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        34.4463883,
        49.5470277,
        34.6603407,
        49.6579108
      ]
    },
    {
      "id": 1978,
      "name": "Maroua",
      "names": {
        "zh": "马鲁阿"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 10.59095,
        "lng": 14.31592
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        14.29592,
        10.57095,
        14.33592,
        10.61095
      ]
    },
    {
      "id": 1979,
      "name": "Kaech'on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.70056,
        "lng": 125.89333
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.87333,
        39.68056,
        125.91333,
        39.72056
      ]
    },
    {
      "id": 1980,
      "name": "Asan",
      "names": {
        "zh": "峨山",
        "ja": "アサン",
        "th": "อาซาน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.7899498,
        "lng": 127.0026769
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.8279606,
        36.6486462,
        127.1196697,
        36.9425445
      ]
    },
    {
      "id": 1981,
      "name": "Tehuacan",
      "names": {
        "es": "Tehuacán",
        "zh": "特瓦坎"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4599877,
        "lng": -97.3903616
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.5503616,
        18.2999877,
        -97.2303616,
        18.6199877
      ]
    },
    {
      "id": 1982,
      "name": "Coatzacoalcos",
      "names": {
        "zh": "夸察夸尔科斯",
        "ja": "コアツァコアルコス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1405019,
        "lng": -94.4344003
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.5944003,
        17.9805019,
        -94.2744003,
        18.3005019
      ]
    },
    {
      "id": 1983,
      "name": "Nukus",
      "names": {
        "zh": "努库斯"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.4600229,
        "lng": 59.6176603
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        59.5427808,
        42.3543993,
        59.6801982,
        42.5585162
      ]
    },
    {
      "id": 1984,
      "name": "Huntsville",
      "names": {
        "zh": "亨茨维尔",
        "ja": "ハンツヴィル",
        "th": "ฮันต์สวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.729847,
        "lng": -86.5859011
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.9567811,
        34.5334323,
        -86.3993424,
        34.8651648
      ]
    },
    {
      "id": 1985,
      "name": "Oskemen",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 49.96466,
        "lng": 82.60898
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        82.58898,
        49.94466,
        82.62898,
        49.98466
      ]
    },
    {
      "id": 1986,
      "name": "Nantes",
      "names": {
        "zh": "南特",
        "ja": "ナント",
        "th": "นองต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2186371,
        "lng": -1.5541362
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        -1.6418115,
        47.1805856,
        -1.4788443,
        47.2958583
      ]
    },
    {
      "id": 1987,
      "name": "Zalantun",
      "names": {
        "zh": "扎兰屯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.9954052,
        "lng": 122.737009
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        122.577009,
        47.8354052,
        122.897009,
        48.1554052
      ]
    },
    {
      "id": 1988,
      "name": "Trichur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.5161402,
        "lng": 76.2084616
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.2034616,
        10.5111402,
        76.2134616,
        10.5211402
      ]
    },
    {
      "id": 1989,
      "name": "Binangonan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4615978,
        "lng": 121.1911853
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1911353,
        14.4615478,
        121.1912353,
        14.4616478
      ]
    },
    {
      "id": 1990,
      "name": "Cirebon",
      "names": {
        "zh": "井里汶"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.7137044,
        "lng": 108.5608483
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        108.5182305,
        -6.7963866,
        108.5909888,
        -6.690998
      ]
    },
    {
      "id": 1991,
      "name": "Munster",
      "names": {
        "zh": "明斯特",
        "de": "Münster",
        "ja": "ミュンスター",
        "th": "มอนสเตอร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9625101,
        "lng": 7.6251879
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.4737853,
        51.8401448,
        7.7743634,
        52.0600251
      ]
    },
    {
      "id": 1992,
      "name": "Az Zaqaziq",
      "names": {
        "ja": "zaqaziq"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.5853431,
        "lng": 31.5035127
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.3435127,
        30.4253431,
        31.6635127,
        30.7453431
      ]
    },
    {
      "id": 1993,
      "name": "Boaco",
      "names": {
        "zh": "博阿科"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.4718043,
        "lng": -85.6598144
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -85.6940123,
        12.3698601,
        -85.2287928,
        12.7430474
      ]
    },
    {
      "id": 1994,
      "name": "Ca Mau",
      "names": {
        "th": "แคลิฟอร์เนีย"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.1782047,
        "lng": 105.1519707
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.096467,
        9.0819409,
        105.2804204,
        9.2673715
      ]
    },
    {
      "id": 1995,
      "name": "Toshima",
      "names": {
        "es": "De toshima"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.725913,
        "lng": 139.7166365
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6775281,
        35.7122938,
        139.7526173,
        35.7459875
      ]
    },
    {
      "id": 1996,
      "name": "Vologda",
      "names": {
        "zh": "沃洛格达"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.2189391,
        "lng": 39.8933913
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        39.7332548,
        59.16892,
        40.0230169,
        59.285022
      ]
    },
    {
      "id": 1997,
      "name": "Saransk",
      "names": {
        "zh": "萨兰斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.1847585,
        "lng": 45.18418925656806
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        45.1836288,
        54.1846688,
        45.1847486,
        54.1848499
      ]
    },
    {
      "id": 1998,
      "name": "Mobile",
      "names": {
        "es": "Móvil",
        "fr": "Portable",
        "zh": "移动",
        "ja": "モバイル",
        "th": "โทรศัพท์มือถือ",
        "pt": "Móvel"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6913462,
        "lng": -88.0437509
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.2761222,
        30.5310263,
        -87.9485532,
        30.8441636
      ]
    },
    {
      "id": 1999,
      "name": "Barddhaman",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.234467,
        "lng": 87.868057
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.810997,
        23.21308,
        87.900284,
        23.273972
      ]
    },
    {
      "id": 2000,
      "name": "Tanza",
      "names": {
        "zh": "坦扎"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4012163,
        "lng": 120.8580466
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7087679,
        14.2812122,
        120.8758281,
        14.4707257
      ]
    },
    {
      "id": 2001,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 6.2108741,
        "lng": 31.5614397
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        31.4014397,
        6.0508741,
        31.7214397,
        6.3708741
      ]
    },
    {
      "id": 2002,
      "name": "Kasur",
      "names": {
        "zh": "卡苏尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.1175143,
        "lng": 74.4478314
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        74.2878314,
        30.9575143,
        74.6078314,
        31.2775143
      ]
    },
    {
      "id": 2003,
      "name": "Yakou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.296486,
        "lng": 113.523514
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.483514,
        33.256486,
        113.563514,
        33.336486
      ]
    },
    {
      "id": 2004,
      "name": "Orel",
      "names": {
        "zh": "奥廖尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.9687747,
        "lng": 36.0694937
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        35.9740202,
        52.8955002,
        36.2190377,
        53.0405811
      ]
    },
    {
      "id": 2005,
      "name": "Shahr-e Qods",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7214,
        "lng": 51.109
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.089,
        35.7014,
        51.129,
        35.7414
      ]
    },
    {
      "id": 2006,
      "name": "Safi",
      "names": {
        "zh": "萨菲",
        "ja": "サフィ",
        "th": "โครก"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.299424,
        "lng": -9.239533
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.274596,
        32.1897782,
        -9.1878758,
        32.3400579
      ]
    },
    {
      "id": 2007,
      "name": "Gungoren",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0252832,
        "lng": 28.8726498
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.8590034,
        41.0016974,
        28.9025818,
        41.0384821
      ]
    },
    {
      "id": 2008,
      "name": "Guaruja",
      "names": {
        "th": "กูอารูจา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.9927768,
        "lng": -46.2558332
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.325478,
        -24.0526842,
        -46.1234222,
        -23.8562109
      ]
    },
    {
      "id": 2009,
      "name": "Playa del Carmen",
      "names": {
        "ja": "プラヤデルカルメン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6308643,
        "lng": -87.0779503
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -87.1251816,
        20.5999428,
        -87.0214215,
        20.7098999
      ]
    },
    {
      "id": 2010,
      "name": "Purnea",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.78,
        "lng": 87.47
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.45,
        25.76,
        87.49,
        25.8
      ]
    },
    {
      "id": 2011,
      "name": "Datang",
      "names": {
        "zh": "大唐",
        "th": "ต้อนรับ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.08051245,
        "lng": 113.31625341973321
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.3161213,
        23.0800983,
        113.3164275,
        23.0812063
      ]
    },
    {
      "id": 2012,
      "name": "Zarzal",
      "names": {
        "zh": "萨尔萨尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.393943,
        "lng": -76.0706472
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1712201,
        4.2477991,
        -75.8752127,
        4.4862208
      ]
    },
    {
      "id": 2013,
      "name": "Fort Collins",
      "names": {
        "es": "Collins",
        "zh": "柯林斯堡",
        "de": "Fortkoffer",
        "ja": "フォートコリンズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5871782,
        "lng": -105.0770113
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.1573564,
        40.4726063,
        -104.9820005,
        40.639352
      ]
    },
    {
      "id": 2014,
      "name": "Port Elizabeth",
      "names": {
        "zh": "伊丽莎白港",
        "it": "Porto Elizabeth",
        "ja": "ポートエリザベス",
        "th": "พอร์ตเอลิซาเบ ธ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.9618359,
        "lng": 25.6186512
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        25.4586512,
        -34.1218359,
        25.7786512,
        -33.8018359
      ]
    },
    {
      "id": 2015,
      "name": "Asheville",
      "names": {
        "zh": "阿什维尔",
        "ja": "アッシュビル",
        "th": "แอชวิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.595363,
        "lng": -82.5508407
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.6703643,
        35.4164642,
        -82.4604691,
        35.6560763
      ]
    },
    {
      "id": 2016,
      "name": "Santa Clarita",
      "names": {
        "zh": "圣克莱丽塔",
        "ja": "サンタクラリタ",
        "th": "ซานตาคลาริต้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4137196,
        "lng": -118.5250277
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.5300277,
        34.4087196,
        -118.5200277,
        34.4187196
      ]
    },
    {
      "id": 2017,
      "name": "Gorgan",
      "names": {
        "zh": "戈尔甘"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.8392776,
        "lng": 54.4320858
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        54.3489338,
        36.7948524,
        54.4856322,
        36.8661927
      ]
    },
    {
      "id": 2018,
      "name": "Quy Nhon",
      "names": {
        "es": "Query nhon"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 13.7696017,
        "lng": 109.2314599
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        109.0894479,
        13.5930221,
        109.3699533,
        13.9042446
      ]
    },
    {
      "id": 2019,
      "name": "Sambalpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.4705391,
        "lng": 83.9761511
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.8161511,
        21.3105391,
        84.1361511,
        21.6305391
      ]
    },
    {
      "id": 2020,
      "name": "Mannheim",
      "names": {
        "zh": "曼海姆",
        "ja": "マンハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4892913,
        "lng": 8.4673098
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.4141602,
        49.4103619,
        8.5899902,
        49.5904701
      ]
    },
    {
      "id": 2021,
      "name": "Yakutsk",
      "names": {
        "zh": "雅库茨克",
        "ja": "ヤクーツク",
        "th": "ยาคุตสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 62.0274078,
        "lng": 129.7319787
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        129.5236158,
        61.907724,
        130.0263262,
        62.3735089
      ]
    },
    {
      "id": 2022,
      "name": "Yokkaichi",
      "names": {
        "zh": "四日市",
        "ja": "四日市"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.966998,
        "lng": 136.620483
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.609533,
        34.953483,
        136.631434,
        34.980514
      ]
    },
    {
      "id": 2023,
      "name": "Ponta Grossa",
      "names": {
        "ja": "ポンタグロッサ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.0891685,
        "lng": -50.1601812
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.4565226,
        -25.4034004,
        -49.6802008,
        -24.8917734
      ]
    },
    {
      "id": 2024,
      "name": "Catania",
      "names": {
        "es": "De Catania",
        "fr": "Catane",
        "zh": "卡塔尼亚",
        "ja": "カターニア",
        "th": "กาตาเนีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.5023612,
        "lng": 15.0873718
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.9358674,
        37.3582971,
        15.125794,
        37.562386
      ]
    },
    {
      "id": 2025,
      "name": "Chalco",
      "names": {
        "zh": "中国铝业"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2382585,
        "lng": -98.81888376395177
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.9696786,
        19.1573825,
        -98.6828896,
        19.3192287
      ]
    },
    {
      "id": 2026,
      "name": "Karlsruhe",
      "names": {
        "zh": "卡尔斯鲁厄",
        "ja": "カールスルーエ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.0068705,
        "lng": 8.4034195
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.2773142,
        48.9404699,
        8.5417299,
        49.0912838
      ]
    },
    {
      "id": 2027,
      "name": "Shahriar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.6589015,
        "lng": 51.0586022
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.9848897,
        35.6166866,
        51.0886939,
        35.6995406
      ]
    },
    {
      "id": 2028,
      "name": "Kurgan",
      "names": {
        "zh": "库尔干"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.4409357,
        "lng": 65.3421169
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        65.1176161,
        55.290749,
        65.6731133,
        55.5334332
      ]
    },
    {
      "id": 2029,
      "name": "Kasugai",
      "names": {
        "zh": "春日井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2432237,
        "lng": 136.985229
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.980229,
        35.2382237,
        136.990229,
        35.2482237
      ]
    },
    {
      "id": 2030,
      "name": "Sariwon",
      "names": {
        "zh": "沙里院"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.50722,
        "lng": 125.75583
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        125.73583,
        38.48722,
        125.77583,
        38.52722
      ]
    },
    {
      "id": 2031,
      "name": "St. Catharines",
      "names": {
        "zh": "圣柳树",
        "de": "St. Katharines",
        "it": "San Catarro",
        "ja": "セントキャサリンズ",
        "th": "เซนต์แคธารี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.1579812,
        "lng": -79.2441003
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.3209212,
        43.106662,
        -79.1778306,
        43.2500098
      ]
    },
    {
      "id": 2032,
      "name": "Matadi",
      "names": {
        "zh": "马塔迪瀑布"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.79740005,
        "lng": 13.494820298074796
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        13.4116898,
        -5.8865235,
        13.587086,
        -5.70628
      ]
    },
    {
      "id": 2033,
      "name": "Niagara Falls",
      "names": {
        "es": "Cataratas del Niágara",
        "fr": "Chutes du Niagara",
        "zh": "尼亚加拉瀑布",
        "de": "Niagarafälle",
        "it": "Cascate del Niagara",
        "id": "Air terjun niagara",
        "ja": "ナイアガラの滝",
        "pt": "Cataratas do Niágara"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.05161635,
        "lng": -79.0855643507602
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.2035768,
        42.8319981,
        -78.9059488,
        43.2660321
      ]
    },
    {
      "id": 2034,
      "name": "Firozabad",
      "names": {
        "zh": "菲罗扎巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.1527986,
        "lng": 78.3991439
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.3591439,
        27.1127986,
        78.4391439,
        27.1927986
      ]
    },
    {
      "id": 2035,
      "name": "St. Paul",
      "names": {
        "es": "San Pablo",
        "fr": "Saint-Paul",
        "zh": "圣保罗",
        "ja": "セントポール",
        "th": "เซนต์พอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9497487,
        "lng": -93.0931028
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -93.2078138,
        44.8872692,
        -93.0043117,
        44.9920237
      ]
    },
    {
      "id": 2036,
      "name": "Vladikavkaz",
      "names": {
        "zh": "弗拉季高加索"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.035327,
        "lng": 44.668581
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        44.63562,
        43.000591,
        44.704674,
        43.077808
      ]
    },
    {
      "id": 2037,
      "name": "Hisar",
      "names": {
        "zh": "希萨尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 29.1562688,
        "lng": 75.7292303
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.5692303,
        28.9962688,
        75.8892303,
        29.3162688
      ]
    },
    {
      "id": 2038,
      "name": "Puerto La Cruz",
      "names": {
        "zh": "波多黎各克鲁兹",
        "ja": "プエルトラルス",
        "th": "เปอร์โตลาครูซ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2028617,
        "lng": -64.6307626
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -64.670306,
        10.1672776,
        -64.5942879,
        10.2452562
      ]
    },
    {
      "id": 2039,
      "name": "Puerto Princesa",
      "names": {
        "zh": "Puerto Printesa",
        "ja": "プエルトプリンセサ",
        "th": "เปอร์โตปรินเซซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7398561,
        "lng": 118.7438187
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        118.2708563,
        9.4553537,
        119.1595263,
        10.4544105
      ]
    },
    {
      "id": 2040,
      "name": "Podolsk",
      "names": {
        "zh": "波多利斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.4308841,
        "lng": 37.5453056
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.4710057,
        55.2980044,
        37.6087053,
        55.4876619
      ]
    },
    {
      "id": 2041,
      "name": "Meguro",
      "names": {
        "zh": "目黑",
        "ja": "目黒"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.62125,
        "lng": 139.688014
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6616008,
        35.6005708,
        139.7177697,
        35.6641448
      ]
    },
    {
      "id": 2042,
      "name": "Ciudad Victoria",
      "names": {
        "ja": "シウダードビクトリア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.7389846,
        "lng": -99.1441352
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -99.3041352,
        23.5789846,
        -98.9841352,
        23.8989846
      ]
    },
    {
      "id": 2043,
      "name": "Ciudad Santa Catarina",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.682028,
        "lng": -100.451971
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.516946,
        25.649192,
        -100.416013,
        25.713354
      ]
    },
    {
      "id": 2044,
      "name": "Vaughan",
      "names": {
        "zh": "沃恩",
        "ja": "ヴォーン",
        "th": "วอฮ์น"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.7941544,
        "lng": -79.5268023
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.7106948,
        43.7498436,
        -79.4200471,
        43.9243511
      ]
    },
    {
      "id": 2045,
      "name": "Awasa",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.0525877,
        "lng": 38.4743613
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        38.4743113,
        7.0525377,
        38.4744113,
        7.0526377
      ]
    },
    {
      "id": 2046,
      "name": "Oakashicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6476987,
        "lng": 134.9909162
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.9908662,
        34.6476487,
        134.9909662,
        34.6477487
      ]
    },
    {
      "id": 2047,
      "name": "Pekalongan",
      "names": {
        "zh": "北加浪岸"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8905065,
        "lng": 109.6761489
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        109.5161489,
        -7.0505065,
        109.8361489,
        -6.7305065
      ]
    },
    {
      "id": 2048,
      "name": "Kurume",
      "names": {
        "zh": "久留米",
        "ja": "久留米"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.313227,
        "lng": 130.518419
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.501344,
        33.29856,
        130.555782,
        33.334955
      ]
    },
    {
      "id": 2049,
      "name": "Vila Nova de Gaia",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1297437,
        "lng": -8.620266
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.620316,
        41.1296937,
        -8.620216,
        41.1297937
      ]
    },
    {
      "id": 2050,
      "name": "Curepipe",
      "names": {
        "zh": "居尔皮普"
      },
      "countryCode": "MU",
      "latLng": {
        "lat": -20.3150516,
        "lng": 57.5211497
      },
      "country": "Mauritius",
      "importance": 3,
      "bbox": [
        57.3611497,
        -20.4750516,
        57.6811497,
        -20.1550516
      ]
    },
    {
      "id": 2051,
      "name": "Paulista",
      "names": {
        "zh": "圣保罗",
        "ja": "パウリスタ",
        "th": "พอลลิส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.941174,
        "lng": -34.876023
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.031899,
        -7.980814,
        -34.819287,
        -7.843143
      ]
    },
    {
      "id": 2052,
      "name": "Oaxaca",
      "names": {
        "zh": "瓦哈卡",
        "ja": "オアハカ",
        "th": "โออาซากา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0604663,
        "lng": -96.7253575
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -96.8853575,
        16.9004663,
        -96.5653575,
        17.2204663
      ]
    },
    {
      "id": 2053,
      "name": "Armenia",
      "names": {
        "fr": "L'Arménie",
        "zh": "亚美尼亚",
        "de": "Armenien",
        "ja": "アルメニア",
        "th": "อาร์เมเนีย",
        "pt": "Armênia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.536307,
        "lng": -75.6723751
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.7195038,
        4.4934618,
        -75.6324993,
        4.5881794
      ]
    },
    {
      "id": 2054,
      "name": "Akita",
      "names": {
        "zh": "秋田",
        "ja": "秋田",
        "th": "อาคิตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.7197629,
        "lng": 140.1034669
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        139.7570444,
        39.448885,
        140.5156,
        39.865034
      ]
    },
    {
      "id": 2055,
      "name": "Awka",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 6.218947,
        "lng": 7.0774372
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.9174372,
        6.058947,
        7.2374372,
        6.378947
      ]
    },
    {
      "id": 2056,
      "name": "San Bernardo",
      "names": {
        "zh": "圣伯纳多",
        "ja": "サンベルナルド",
        "th": "ซานเบอร์นาร์โด"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5922859,
        "lng": -70.7045838
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.8260488,
        -33.7540753,
        -70.6286238,
        -33.5239766
      ]
    },
    {
      "id": 2057,
      "name": "Iksan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.9479291,
        "lng": 126.9578025
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.8512022,
        35.8829307,
        127.1398776,
        36.1568679
      ]
    },
    {
      "id": 2058,
      "name": "Taubate",
      "names": {
        "zh": "陶巴特"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.036001,
        "lng": -45.54607
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.610419,
        -23.091886,
        -45.492736,
        -22.981234
      ]
    },
    {
      "id": 2059,
      "name": "Antioch",
      "names": {
        "es": "Antioquía",
        "fr": "Antioche",
        "zh": "安提阿",
        "de": "Antiochien",
        "it": "Antiochia",
        "id": "Antiokhia",
        "ja": "アンティオキア",
        "th": "ออค",
        "pt": "Antioquia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9957458,
        "lng": -121.780005
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.785005,
        37.9907458,
        -121.775005,
        38.0007458
      ]
    },
    {
      "id": 2060,
      "name": "Lakeland",
      "names": {
        "zh": "莱克兰",
        "ja": "レイクランド",
        "th": "เลกแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0394654,
        "lng": -81.9498042
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.0560959,
        27.9526701,
        -81.8388027,
        28.171967
      ]
    },
    {
      "id": 2061,
      "name": "Mardan",
      "names": {
        "zh": "马尔丹"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1937969,
        "lng": 72.0451467
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.8851467,
        34.0337969,
        72.2051467,
        34.3537969
      ]
    },
    {
      "id": 2062,
      "name": "Soc Trang",
      "names": {
        "id": "Trang SoC",
        "th": "SoC ตรัง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.6026724,
        "lng": 105.9733211
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.9285373,
        9.5594117,
        106.0412997,
        9.6606854
      ]
    },
    {
      "id": 2063,
      "name": "Mbeya",
      "names": {
        "zh": "姆贝亚"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -8.894103,
        "lng": 33.436717
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        33.416717,
        -8.914103,
        33.456717,
        -8.874103
      ]
    },
    {
      "id": 2064,
      "name": "San Juan del Rio",
      "names": {
        "zh": "圣胡安德尔里奥",
        "ja": "サンファンデルリオ",
        "th": "ซานฮวนเดลริโอ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3867547,
        "lng": -99.9931982
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.1531982,
        20.2267547,
        -99.8331982,
        20.5467547
      ]
    },
    {
      "id": 2065,
      "name": "Popayan",
      "names": {
        "es": "Popayán",
        "zh": "波帕扬"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.4422295,
        "lng": -76.6072368
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -76.6485077,
        2.4192676,
        -76.5525726,
        2.4971902
      ]
    },
    {
      "id": 2066,
      "name": "Praia Grande",
      "names": {
        "zh": "普拉亚格兰德",
        "id": "Pujian",
        "ja": "プライアグランデ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.008979,
        "lng": -46.4144939
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.6117794,
        -24.0897305,
        -46.3835567,
        -23.9465511
      ]
    },
    {
      "id": 2067,
      "name": "Qianzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2576833,
        "lng": 109.6935009
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.659497,
        28.2158007,
        109.7604703,
        28.2941264
      ]
    },
    {
      "id": 2068,
      "name": "Newcastle",
      "names": {
        "zh": "纽卡斯尔",
        "ja": "ニューカッスル",
        "th": "นิวคาสเซิล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9738474,
        "lng": -1.6131572
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.7756721,
        54.959032,
        -1.5292003,
        55.0793823
      ]
    },
    {
      "id": 2069,
      "name": "Ciudad General Escobedo",
      "names": {
        "zh": "ciudad一般escobedo",
        "id": "Ciudad Jenderal Escobedo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.808517,
        "lng": -100.3224334
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.4824334,
        25.648517,
        -100.1624334,
        25.968517
      ]
    },
    {
      "id": 2070,
      "name": "Coatepeque",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7,
        "lng": -91.86667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.88667,
        14.68,
        -91.84667,
        14.72
      ]
    },
    {
      "id": 2071,
      "name": "Quzhou",
      "names": {
        "zh": "衢州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.973887,
        "lng": 118.8540924
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.022407,
        28.2414132,
        119.3419587,
        29.5003523
      ]
    },
    {
      "id": 2072,
      "name": "Tampico",
      "names": {
        "zh": "坦皮科",
        "ja": "タンピコ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.27010965,
        "lng": -97.89486735598555
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.000324,
        22.2069658,
        -97.8363806,
        22.3330507
      ]
    },
    {
      "id": 2073,
      "name": "Bialystok",
      "names": {
        "zh": "比亚韦斯托克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.132398,
        "lng": 23.1591679
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        23.0657911,
        53.0665968,
        23.2471983,
        53.188599
      ]
    },
    {
      "id": 2074,
      "name": "Merida",
      "names": {
        "es": "Mérida",
        "zh": "梅里达",
        "ja": "メリダ",
        "th": "เมริดา"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.582577749999999,
        "lng": -71.16073167682168
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -71.1684057,
        8.5779575,
        -71.1540534,
        8.587208
      ]
    },
    {
      "id": 2075,
      "name": "Murmansk",
      "names": {
        "fr": "Mourmansk",
        "zh": "摩尔曼斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 68.970665,
        "lng": 33.07497
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        32.9328749,
        68.8828945,
        33.2941369,
        69.0640502
      ]
    },
    {
      "id": 2076,
      "name": "Ar Raqqah",
      "names": {
        "zh": "arraqqah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.949678,
        "lng": 39.0089212
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        38.8489212,
        35.789678,
        39.1689212,
        36.109678
      ]
    },
    {
      "id": 2077,
      "name": "Linhai",
      "names": {
        "zh": "临海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8616886,
        "lng": 121.1402223
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.8228821,
        28.6081118,
        122.1782805,
        29.0682367
      ]
    },
    {
      "id": 2078,
      "name": "Jember",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.1688563,
        "lng": 113.7021772
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        113.2642462,
        -8.5584451,
        114.0434187,
        -7.9682727
      ]
    },
    {
      "id": 2079,
      "name": "Valladolid",
      "names": {
        "zh": "巴利亚多利德",
        "ja": "バリャドリッド",
        "th": "บายาโดลิด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6521807,
        "lng": -4.728605
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -4.9281803,
        41.5231281,
        -4.6308064,
        41.8155086
      ]
    },
    {
      "id": 2080,
      "name": "Bahia Blanca",
      "names": {
        "ja": "バイアブランカ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.7176522,
        "lng": -62.2654871
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.3528141,
        -38.76468,
        -62.1600409,
        -38.6332067
      ]
    },
    {
      "id": 2081,
      "name": "Central Coast",
      "names": {
        "es": "Costa central",
        "fr": "Côte centrale",
        "zh": "中央海岸",
        "de": "Zentrale Küste",
        "it": "Costa Centrale",
        "id": "Pantai pusat",
        "ja": "中央沿岸",
        "th": "ชายฝั่งตอนกลาง",
        "pt": "Costa central"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -33.3117096,
        "lng": 151.312067495083
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        150.9842689,
        -33.5799713,
        151.6312667,
        -33.0437193
      ]
    },
    {
      "id": 2082,
      "name": "Rampur",
      "names": {
        "zh": "兰布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.8014001,
        "lng": 79.0284337
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.9447722,
        28.6664913,
        79.178295,
        28.9445798
      ]
    },
    {
      "id": 2083,
      "name": "Iskenderun",
      "names": {
        "zh": "伊斯肯德伦"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.58718,
        "lng": 36.17347
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.15347,
        36.56718,
        36.19347,
        36.60718
      ]
    },
    {
      "id": 2084,
      "name": "Al Mubarraz",
      "names": {
        "ja": "アルマラズ",
        "th": "อัล mubarraz"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 25.407682,
        "lng": 49.590284
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        49.570284,
        25.387682,
        49.610284,
        25.427682
      ]
    },
    {
      "id": 2085,
      "name": "Petropolis",
      "names": {
        "es": "Petrópolis",
        "zh": "彼得罗波利斯",
        "pt": "Petrópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5075743,
        "lng": -43.1785356
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.2762533,
        -22.5733326,
        -43.0957369,
        -22.4272035
      ]
    },
    {
      "id": 2086,
      "name": "Al Kharj",
      "names": {
        "ja": "アルカルジ",
        "th": "อัล kharj"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 23.8304401,
        "lng": 47.68401336276168
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        46.9307054,
        23.0729101,
        48.15413,
        24.5840299
      ]
    },
    {
      "id": 2087,
      "name": "Navotas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6571862,
        "lng": 120.9479692
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.8394927,
        14.6337981,
        120.9691005,
        14.7128483
      ]
    },
    {
      "id": 2088,
      "name": "Chernihiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.494099,
        "lng": 31.294332
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        31.2070643,
        51.4366613,
        31.3823733,
        51.5494453
      ]
    },
    {
      "id": 2089,
      "name": "Yangsan",
      "names": {
        "zh": "梁山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.3349979,
        "lng": 129.0370582
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        128.8725397,
        35.2735115,
        129.2193882,
        35.5318251
      ]
    },
    {
      "id": 2090,
      "name": "Comilla",
      "names": {
        "zh": "库米拉"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.4610615,
        "lng": 91.1808748
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        91.0208748,
        23.3010615,
        91.3408748,
        23.6210615
      ]
    },
    {
      "id": 2091,
      "name": "Pachuca",
      "names": {
        "zh": "帕丘卡",
        "th": "ปาชูกา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1165413,
        "lng": -98.7413535
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -98.9013535,
        19.9565413,
        -98.5813535,
        20.2765413
      ]
    },
    {
      "id": 2092,
      "name": "Augsburg",
      "names": {
        "zh": "奥格斯堡",
        "ja": "アウクスブルク",
        "th": "ออกซ์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3690341,
        "lng": 10.8979522
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.7633615,
        48.2581444,
        10.9593328,
        48.4586541
      ]
    },
    {
      "id": 2093,
      "name": "Bradford",
      "names": {
        "zh": "布拉德福德",
        "ja": "ブラッドフォード",
        "th": "แบรดฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7944229,
        "lng": -1.7519186
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.0612483,
        53.7243414,
        -1.6403301,
        53.963151
      ]
    },
    {
      "id": 2094,
      "name": "Oyster Bay",
      "names": {
        "es": "Bahía de ostras",
        "fr": "Huîtrie",
        "zh": "牡蛎湾",
        "id": "Teluk Oyster",
        "ja": "オイスターベイ",
        "th": "อ่าวหอยนางรม",
        "pt": "Baía de ostras"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7696568,
        "lng": -73.49986083708588
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.6616855,
        40.548716,
        -73.421958,
        40.9896322
      ]
    },
    {
      "id": 2095,
      "name": "Tagum",
      "names": {
        "zh": "塔古姆"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.4470784,
        "lng": 125.8094853
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.733172,
        7.2540442,
        125.8873042,
        7.5105774
      ]
    },
    {
      "id": 2096,
      "name": "Valparaiso",
      "names": {
        "es": "Valparaíso",
        "zh": "瓦尔帕莱索",
        "th": "บัลปาราอีโซ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0458456,
        "lng": -71.6196749
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -80.1215134,
        -33.2129142,
        -71.3832191,
        -26.2733307
      ]
    },
    {
      "id": 2097,
      "name": "Silang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.223624,
        "lng": 120.9741497
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9213126,
        14.1329081,
        121.0561232,
        14.2854592
      ]
    },
    {
      "id": 2098,
      "name": "Limeira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5615068,
        "lng": -47.401766
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.511701,
        -22.7383971,
        -47.2115135,
        -22.4456411
      ]
    },
    {
      "id": 2099,
      "name": "Rangpur",
      "names": {
        "zh": "伦格布尔"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 25.7569808,
        "lng": 89.241459
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        89.081459,
        25.5969808,
        89.401459,
        25.9169808
      ]
    },
    {
      "id": 2101,
      "name": "Kocaeli",
      "names": {
        "zh": "科贾埃利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7653892,
        "lng": 29.9407361
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        29.7807361,
        40.6053892,
        30.1007361,
        40.9253892
      ]
    },
    {
      "id": 2102,
      "name": "Montpellier",
      "names": {
        "zh": "蒙彼利埃",
        "ja": "モンペリエ",
        "th": "มอนต์เพเลียร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6112422,
        "lng": 3.8767337
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.8070597,
        43.5667083,
        3.9413208,
        43.653358
      ]
    },
    {
      "id": 2103,
      "name": "Bali",
      "names": {
        "zh": "巴厘岛",
        "ja": "バリ島",
        "th": "บาหลี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.1456917,
        "lng": 121.4035113
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        121.3448746,
        25.0798458,
        121.4609136,
        25.1775096
      ]
    },
    {
      "id": 2104,
      "name": "Panipat",
      "names": {
        "zh": "巴尼伯德",
        "th": "พานิพัท"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 29.387103,
        "lng": 76.973516
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.96869,
        29.341617,
        77.013602,
        29.42758
      ]
    },
    {
      "id": 2105,
      "name": "Ismailia",
      "names": {
        "zh": "伊斯梅利亚"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.590341,
        "lng": 32.259984
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        32.2399840889,
        30.570340799,
        32.2799840889,
        30.610340799
      ]
    },
    {
      "id": 2106,
      "name": "Vila Teixeira da Silva",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.1921556,
        "lng": 15.8609227
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.8209227,
        -12.2321556,
        15.9009227,
        -12.1521556
      ]
    },
    {
      "id": 2107,
      "name": "Delmas",
      "names": {
        "zh": "戴尔马斯"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.5518464,
        "lng": -72.2920071
      },
      "country": "Haiti",
      "importance": 3,
      "bbox": [
        -72.3386018,
        18.5221314,
        -72.2566186,
        18.5803673
      ]
    },
    {
      "id": 2108,
      "name": "Mabalacat",
      "names": {
        "zh": "马巴拉卡特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2250826,
        "lng": 120.5733815
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.461758,
        15.1569472,
        120.6345123,
        15.2674599
      ]
    },
    {
      "id": 2109,
      "name": "Batna",
      "names": {
        "zh": "巴特纳"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.5548235,
        "lng": 6.1768526
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.0766557,
        35.512514,
        6.2754977,
        35.6444609
      ]
    },
    {
      "id": 2110,
      "name": "Aizawl",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.733988,
        "lng": 92.71808
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        92.69504,
        23.70902,
        92.754959,
        23.759325
      ]
    },
    {
      "id": 2111,
      "name": "Kunp'o",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 8.96667,
        "lng": 6.15
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        6.13,
        8.94667,
        6.17,
        8.98667
      ]
    },
    {
      "id": 2112,
      "name": "Tambov",
      "names": {
        "zh": "坦波夫",
        "de": "Tambow"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.72380575,
        "lng": 41.42764656711185
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        41.4275193,
        52.7237494,
        41.4277595,
        52.7238976
      ]
    },
    {
      "id": 2113,
      "name": "Thies",
      "names": {
        "zh": "蒂斯"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.791461,
        "lng": -16.925605
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.9695847,
        14.7678431,
        -16.8909682,
        14.8325629
      ]
    },
    {
      "id": 2114,
      "name": "Iasi",
      "names": {
        "zh": "雅西"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.160306,
        "lng": 27.589244
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.515097,
        47.09714,
        27.696521,
        47.205615
      ]
    },
    {
      "id": 2115,
      "name": "Pendik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.874257,
        "lng": 29.244009
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.243959,
        40.874207,
        29.244059,
        40.874307
      ]
    },
    {
      "id": 2116,
      "name": "Nottingham",
      "names": {
        "zh": "诺丁汉",
        "ja": "ノッティンガム",
        "th": "น็อตติงแฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.9534193,
        "lng": -1.1496461
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.246956,
        52.8890083,
        -1.0861189,
        53.0186721
      ]
    },
    {
      "id": 2117,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 41.8762163,
        "lng": 8.9779271
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        8.9546925,
        41.858524,
        9.009222,
        41.908829
      ]
    },
    {
      "id": 2118,
      "name": "Groznyy",
      "names": {
        "zh": "格罗兹尼"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.362196,
        "lng": 45.670386
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        45.665138,
        43.355305,
        45.679359,
        43.370445
      ]
    },
    {
      "id": 2119,
      "name": "Kherson",
      "names": {
        "zh": "赫尔松",
        "de": "Cherson"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.6401295,
        "lng": 32.6143922
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        32.5447258,
        46.5967989,
        32.7262718,
        46.7049083
      ]
    },
    {
      "id": 2120,
      "name": "Santarem",
      "names": {
        "zh": "圣塔伦",
        "pt": "Santarém"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.438489,
        "lng": -54.699611
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.3446289,
        -3.2665215,
        -54.0452504,
        -2.0714201
      ]
    },
    {
      "id": 2121,
      "name": "Bafoussam",
      "names": {
        "zh": "巴富萨姆"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 5.47366,
        "lng": 10.41786
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        10.39786,
        5.45366,
        10.43786,
        5.49366
      ]
    },
    {
      "id": 2122,
      "name": "Hong'an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.2046072,
        "lng": 123.6394641
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.6199111,
        47.1868056,
        123.6571811,
        47.2129603
      ]
    },
    {
      "id": 2123,
      "name": "Resistencia",
      "names": {
        "zh": "抵抗"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.4511077,
        "lng": -58.9865196
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -59.0743175,
        -27.5137093,
        -58.8989588,
        -27.380097
      ]
    },
    {
      "id": 2124,
      "name": "Brasov",
      "names": {
        "zh": "布拉索夫",
        "ja": "ブラショフ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.6525105,
        "lng": 25.6105654
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.5035642,
        45.499647,
        25.6889236,
        45.752328
      ]
    },
    {
      "id": 2125,
      "name": "Juliaca",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -15.49611,
        "lng": -70.131117
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -70.136117,
        -15.50111,
        -70.126117,
        -15.49111
      ]
    },
    {
      "id": 2126,
      "name": "Graz",
      "names": {
        "zh": "格拉茨",
        "ja": "グラーツ",
        "th": "กราซ"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.0708678,
        "lng": 15.4382786
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        15.3497141,
        47.0118864,
        15.5342006,
        47.1345
      ]
    },
    {
      "id": 2127,
      "name": "Itagui",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1724352,
        "lng": -75.6093254
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6462984,
        6.1536506,
        -75.5808649,
        6.1992822
      ]
    },
    {
      "id": 2128,
      "name": "Karimnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.4348122,
        "lng": 79.1328042
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.9728042,
        18.2748122,
        79.2928042,
        18.5948122
      ]
    },
    {
      "id": 2129,
      "name": "San Lorenzo",
      "names": {
        "zh": "圣洛伦佐",
        "ja": "サンロレンツォ",
        "th": "ซานลอเรนโซ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": 18.1524948,
        "lng": -65.97563436136883
      },
      "country": "Paraguay",
      "importance": 3,
      "bbox": [
        -66.0525457,
        18.0756612,
        -65.9206835,
        18.2294957
      ]
    },
    {
      "id": 2130,
      "name": "Sumida",
      "names": {
        "zh": "墨田",
        "ja": "スミダ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7003789,
        "lng": 139.8058673
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7887254,
        35.6866987,
        139.842841,
        35.743505
      ]
    },
    {
      "id": 2131,
      "name": "Sekondi",
      "names": {
        "zh": "塞康第"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 4.93774135,
        "lng": -1.7102438954829193
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -1.7106239,
        4.9375935,
        -1.7098483,
        4.9378898
      ]
    },
    {
      "id": 2132,
      "name": "Morioka",
      "names": {
        "zh": "盛冈",
        "ja": "盛岡",
        "th": "โมริโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.697747,
        "lng": 141.136314
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        141.058282,
        39.617835,
        141.202469,
        39.779557
      ]
    },
    {
      "id": 2133,
      "name": "Setif",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.1893494,
        "lng": 5.4046876
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.337056,
        36.1335573,
        5.506285,
        36.24869
      ]
    },
    {
      "id": 2134,
      "name": "Trenton",
      "names": {
        "zh": "特伦顿",
        "ja": "トレントン",
        "th": "เทรนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2203074,
        "lng": -74.7659
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -74.8195816,
        40.1838339,
        -74.728904,
        40.248298
      ]
    },
    {
      "id": 2135,
      "name": "Atyrau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.1067183,
        "lng": 51.9138976
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        51.7730712,
        47.0061288,
        52.3227219,
        47.2797361
      ]
    },
    {
      "id": 2136,
      "name": "Ipswich",
      "names": {
        "zh": "伊普斯维奇",
        "ja": "イプスウィッチ",
        "th": "อิปสวิช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.0579324,
        "lng": 1.1528095
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        1.1072451,
        52.0201601,
        1.2236518,
        52.0944549
      ]
    },
    {
      "id": 2137,
      "name": "Kaunas",
      "names": {
        "zh": "考纳斯",
        "ja": "カウナス",
        "th": "เคานาส"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.896296,
        "lng": 23.92186
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.790869,
        54.824875,
        24.084777,
        54.975401
      ]
    },
    {
      "id": 2138,
      "name": "Adana",
      "names": {
        "zh": "阿达纳",
        "ja": "アダナ",
        "th": "อาดานา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0036959,
        "lng": 35.31912610457783
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        35.3188148,
        37.0035359,
        35.3194402,
        37.0038454
      ]
    },
    {
      "id": 2139,
      "name": "Hulin",
      "names": {
        "zh": "胡林"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.7638077,
        "lng": 132.970081
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        132.810081,
        45.6038077,
        133.130081,
        45.9238077
      ]
    },
    {
      "id": 2140,
      "name": "Lincoln",
      "names": {
        "zh": "林肯",
        "ja": "リンカーン",
        "th": "ลิงคอล์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8088861,
        "lng": -96.7077751
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -96.80415,
        40.693767,
        -96.5675211,
        40.915002
      ]
    },
    {
      "id": 2141,
      "name": "Bhuj",
      "names": {
        "zh": "普杰"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.2657948,
        "lng": 69.6783915
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        69.6733915,
        23.2607948,
        69.6833915,
        23.2707948
      ]
    },
    {
      "id": 2142,
      "name": "Farg`ona",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.391984,
        "lng": 71.781182
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        71.7193692,
        40.3339754,
        71.8738649,
        40.4577725
      ]
    },
    {
      "id": 2143,
      "name": "Ichalkaranji",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.704824,
        "lng": 74.460424
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.446231,
        16.675609,
        74.475634,
        16.700476
      ]
    },
    {
      "id": 2144,
      "name": "Strasbourg",
      "names": {
        "es": "Estrasburgo",
        "zh": "斯特拉斯堡",
        "de": "Straßburg",
        "it": "Strasburgo",
        "ja": "ストラスブール",
        "th": "สตราสบูร์ก",
        "pt": "Estrasburgo"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.584614,
        "lng": 7.7507127
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        7.6881371,
        48.491861,
        7.8360646,
        48.6461896
      ]
    },
    {
      "id": 2145,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.2081729,
        "lng": -93.2922715
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.4132228,
        37.0887889,
        -93.1784958,
        37.2769839
      ]
    },
    {
      "id": 2146,
      "name": "Anchorage",
      "names": {
        "zh": "安克雷奇",
        "ja": "アンカレッジ",
        "th": "ทอดสมอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 61.2216643,
        "lng": -149.8904316
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -149.8954316,
        61.2166643,
        -149.8854316,
        61.2266643
      ]
    },
    {
      "id": 2147,
      "name": "Punto Fijo",
      "names": {
        "ja": "プントフィジョー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.6931716,
        "lng": -70.1917178
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.3517178,
        11.5331716,
        -70.0317178,
        11.8531716
      ]
    },
    {
      "id": 2148,
      "name": "Sincelejo",
      "names": {
        "zh": "辛塞莱霍"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2973386,
        "lng": -75.3926601
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.5526601,
        9.1373386,
        -75.2326601,
        9.4573386
      ]
    },
    {
      "id": 2149,
      "name": "Plano",
      "names": {
        "zh": "普莱诺",
        "ja": "プラノ",
        "th": "พลาโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0136764,
        "lng": -96.6925096
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.8599747,
        32.9970517,
        -96.6129244,
        33.1259416
      ]
    },
    {
      "id": 2150,
      "name": "Ibaraki",
      "names": {
        "zh": "茨城",
        "ja": "茨城",
        "th": "จังหวัดอิบารากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8151407,
        "lng": 135.5622482
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5621982,
        34.8150907,
        135.5622982,
        34.8151907
      ]
    },
    {
      "id": 2151,
      "name": "Irvine",
      "names": {
        "zh": "欧文",
        "ja": "アーバイン",
        "th": "เออร์ไวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6567847,
        "lng": -117.733584
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.738584,
        33.6517847,
        -117.728584,
        33.6617847
      ]
    },
    {
      "id": 2152,
      "name": "Camacari",
      "names": {
        "zh": "卡马萨里",
        "pt": "Camaçari"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6998188,
        "lng": -38.3260762
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.3760027,
        -12.8806886,
        -38.0304294,
        -12.452
      ]
    },
    {
      "id": 2153,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.7756663,
        "lng": -72.2214154
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -72.3814154,
        7.6156663,
        -72.0614154,
        7.9356663
      ]
    },
    {
      "id": 2154,
      "name": "Bolton",
      "names": {
        "zh": "博尔顿",
        "ja": "ボルトン",
        "th": "โบลตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5782863,
        "lng": -2.4300367
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.6286157,
        53.5228655,
        -2.3370404,
        53.6460294
      ]
    },
    {
      "id": 2155,
      "name": "San Pablo",
      "names": {
        "zh": "圣巴勃罗",
        "ja": "サンパブロ",
        "th": "ซานปาโบล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0700502,
        "lng": 121.3255974
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2444995,
        13.966778,
        121.4309128,
        14.1466462
      ]
    },
    {
      "id": 2156,
      "name": "Suzano",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5427842,
        "lng": -46.3108391
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.3840842,
        -23.7449516,
        -46.2543243,
        -23.486
      ]
    },
    {
      "id": 2157,
      "name": "Korhogo",
      "names": {
        "zh": "科霍戈"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 9.45803,
        "lng": -5.62961
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -5.64961,
        9.43803,
        -5.60961,
        9.47803
      ]
    },
    {
      "id": 2158,
      "name": "Hospet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.266493,
        "lng": 76.3872297
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.2272297,
        15.106493,
        76.5472297,
        15.426493
      ]
    },
    {
      "id": 2159,
      "name": "Bhatinda",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.208225,
        "lng": 74.953285
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.889819,
        30.159243,
        74.999591,
        30.259733
      ]
    },
    {
      "id": 2160,
      "name": "Cascavel",
      "names": {
        "zh": "卡斯卡韦尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.9554996,
        "lng": -53.4560544
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.7237747,
        -25.3713878,
        -53.0718776,
        -24.743
      ]
    },
    {
      "id": 2161,
      "name": "Ostrava",
      "names": {
        "zh": "奥斯特拉瓦",
        "ja": "オストラヴァ",
        "th": "ออสตราวา"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.8349139,
        "lng": 18.2820084
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        18.098411,
        49.7257571,
        18.3763003,
        49.9137759
      ]
    },
    {
      "id": 2162,
      "name": "Tacna",
      "names": {
        "zh": "塔克纳",
        "ja": "タクナ"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -18.01386,
        "lng": -70.2511032
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -70.6385566,
        -18.322313,
        -69.7546866,
        -17.8898342
      ]
    },
    {
      "id": 2163,
      "name": "Constanta",
      "names": {
        "es": "Constanza",
        "zh": "康斯坦察",
        "ja": "コンスタンツァ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.16924385,
        "lng": 28.631428154990797
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        28.6293121,
        44.1668322,
        28.6328445,
        44.1713638
      ]
    },
    {
      "id": 2164,
      "name": "Haifa",
      "names": {
        "fr": "Haïfa",
        "zh": "海法",
        "ja": "ハイファ",
        "th": "ไฮฟา"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.8191218,
        "lng": 34.9983856
      },
      "country": "Israel",
      "importance": 1,
      "bbox": [
        34.9499666,
        32.7579523,
        35.0797444,
        32.8427003
      ]
    },
    {
      "id": 2165,
      "name": "Davenport",
      "names": {
        "zh": "达文波特",
        "ja": "ダヴェンポート",
        "th": "ดาเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5235808,
        "lng": -90.5770967
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.6879323,
        41.460632,
        -90.4908246,
        41.6202927
      ]
    },
    {
      "id": 2166,
      "name": "Fukushima",
      "names": {
        "zh": "福岛",
        "ja": "福島",
        "th": "ฟุกุชิมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.760777,
        "lng": 140.4745807
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.229261,
        37.624207,
        140.569984,
        37.976647
      ]
    },
    {
      "id": 2167,
      "name": "Barinas",
      "names": {
        "zh": "巴里纳斯"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.6182737,
        "lng": -70.2274065
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -70.3874065,
        8.4582737,
        -70.0674065,
        8.7782737
      ]
    },
    {
      "id": 2168,
      "name": "Coro",
      "names": {
        "zh": "冠状",
        "ja": "コロ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.4055796,
        "lng": -69.6679115
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -69.8279115,
        11.2455796,
        -69.5079115,
        11.5655796
      ]
    },
    {
      "id": 2169,
      "name": "Bago",
      "names": {
        "zh": "巴格"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 17.336667,
        "lng": 96.479722
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        96.319722,
        17.176667,
        96.639722,
        17.496667
      ]
    },
    {
      "id": 2170,
      "name": "Fuquan",
      "names": {
        "zh": "福泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.7046919,
        "lng": 107.5085612
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.3485612,
        26.5446919,
        107.6685612,
        26.8646919
      ]
    },
    {
      "id": 2171,
      "name": "Tongchuanshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.07474,
        "lng": 109.08495
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.06495,
        35.05474,
        109.10495,
        35.09474
      ]
    },
    {
      "id": 2172,
      "name": "Sannai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.153830999999997,
        "lng": 80.77026886254106
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.7378738,
        24.1386804,
        80.7934966,
        24.1690084
      ]
    },
    {
      "id": 2173,
      "name": "Huozhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.57556,
        "lng": 111.71861
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.69861,
        36.55556,
        111.73861,
        36.59556
      ]
    },
    {
      "id": 2174,
      "name": "Temuco",
      "names": {
        "zh": "特木科",
        "th": "เตมูโก"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -38.7358908,
        "lng": -72.590538
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -72.8247432,
        -38.7819719,
        -72.4696463,
        -38.5493226
      ]
    },
    {
      "id": 2175,
      "name": "Sterlitamak",
      "names": {
        "zh": "斯捷尔利塔马克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.6335313,
        "lng": 55.9508572
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        55.9458572,
        53.6285313,
        55.9558572,
        53.6385313
      ]
    },
    {
      "id": 2176,
      "name": "Rockford",
      "names": {
        "zh": "罗克福德",
        "ja": "ロックフォード",
        "th": "ฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2713945,
        "lng": -89.093966
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.1952803,
        42.1432458,
        -88.9408682,
        42.3386478
      ]
    },
    {
      "id": 2177,
      "name": "Tegal",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.867334,
        "lng": 109.1378188
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        108.9778188,
        -7.027334,
        109.2978188,
        -6.707334
      ]
    },
    {
      "id": 2178,
      "name": "Ica",
      "names": {
        "zh": "颈内动脉",
        "ja": "のICA"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -14.0640967,
        "lng": -75.7290915
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -76.0723281,
        -14.4900586,
        -75.7172715,
        -14.0416997
      ]
    },
    {
      "id": 2179,
      "name": "Lucena",
      "names": {
        "zh": "卢塞纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9358509,
        "lng": 121.6130954
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.5554622,
        13.7530024,
        121.6949796,
        13.9886448
      ]
    },
    {
      "id": 2180,
      "name": "Osh",
      "names": {
        "fr": "SST",
        "zh": "奥什",
        "de": "Osch"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.5303613,
        "lng": 72.7910097
      },
      "country": "Kyrgyzstan",
      "importance": 1,
      "bbox": [
        72.6770418,
        40.3989302,
        72.8802756,
        40.5881551
      ]
    },
    {
      "id": 2181,
      "name": "Newark",
      "names": {
        "zh": "纽瓦克",
        "ja": "ニューアーク",
        "th": "นวร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.735657,
        "lng": -74.1723667
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.2513334,
        40.6732502,
        -74.1141786,
        40.7881387
      ]
    },
    {
      "id": 2182,
      "name": "Jining",
      "names": {
        "zh": "济宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.0423136,
        "lng": 113.104421
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.099421,
        41.0373136,
        113.109421,
        41.0473136
      ]
    },
    {
      "id": 2183,
      "name": "Chuncheon",
      "names": {
        "zh": "春川"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.87348,
        "lng": 127.741411
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        127.688218,
        37.826807,
        127.787861,
        37.931545
      ]
    },
    {
      "id": 2184,
      "name": "Malard",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.6659,
        "lng": 50.9767
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.9567,
        35.6459,
        50.9967,
        35.6859
      ]
    },
    {
      "id": 2185,
      "name": "Pematangsiantar",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.9588631,
        "lng": 99.0653646
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        99.0164782,
        2.8905207,
        99.1105094,
        3.0170659
      ]
    },
    {
      "id": 2186,
      "name": "Long Xuyen",
      "names": {
        "es": "Larga xuyen",
        "zh": "龙旭树",
        "it": "Lungo Xuyen",
        "id": "Panjang xuyen",
        "ja": "長いXuyen",
        "th": "ลอง xuyen"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.382472,
        "lng": 105.4402694
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.4402194,
        10.382422,
        105.4403194,
        10.382522
      ]
    },
    {
      "id": 2187,
      "name": "Petrozavodsk",
      "names": {
        "zh": "彼得罗扎沃茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.7900749,
        "lng": 34.3570041
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        34.3569541,
        61.7900249,
        34.3570541,
        61.7901249
      ]
    },
    {
      "id": 2188,
      "name": "South Bend",
      "names": {
        "es": "Curva sur",
        "fr": "Se plier au sud",
        "zh": "南弯",
        "it": "Sudend",
        "id": "Tikungan selatan",
        "ja": "サウスベンド",
        "th": "โค้งใต้",
        "pt": "Curva sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6782549,
        "lng": -86.2874217
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.2924217,
        41.6732549,
        -86.2824217,
        41.6832549
      ]
    },
    {
      "id": 2189,
      "name": "Bukhara",
      "names": {
        "fr": "Boukhara",
        "zh": "布哈拉",
        "ja": "ブハラ"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.7675529,
        "lng": 64.4231326
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        64.3724249,
        39.7137877,
        64.5248047,
        39.847084
      ]
    },
    {
      "id": 2190,
      "name": "Shreveport",
      "names": {
        "zh": "什里夫波特",
        "ja": "シュリーブポート",
        "th": "ช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.4440822,
        "lng": -93.7269207
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.7269707,
        32.4440322,
        -93.7268707,
        32.4441322
      ]
    },
    {
      "id": 2191,
      "name": "Sumbe",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -11.2019365,
        "lng": 13.8396845
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        13.6796845,
        -11.3619365,
        13.9996845,
        -11.0419365
      ]
    },
    {
      "id": 2192,
      "name": "Viet Tri",
      "names": {
        "zh": "越来越",
        "th": "เวียตไตร"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.324231,
        "lng": 105.3881392
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.2920467,
        21.2644046,
        105.4568215,
        21.4003679
      ]
    },
    {
      "id": 2193,
      "name": "Wiesbaden",
      "names": {
        "zh": "威斯巴登",
        "ja": "ウィスバーデン",
        "th": "วีสบาเดิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0820384,
        "lng": 8.2416556
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        8.1106718,
        49.9932374,
        8.3861621,
        50.1518098
      ]
    },
    {
      "id": 2194,
      "name": "Cherkasy",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4447056,
        "lng": 32.0588085
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        31.9589155,
        49.3649955,
        32.1249875,
        49.4976065
      ]
    },
    {
      "id": 2195,
      "name": "Sunderland",
      "names": {
        "zh": "桑德兰",
        "ja": "サンダーランド",
        "th": "ซันเดอร์แลนด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9061115,
        "lng": -1.3824404
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.3874404,
        54.9011115,
        -1.3774404,
        54.9111115
      ]
    },
    {
      "id": 2196,
      "name": "Barasat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7191072,
        "lng": 88.4826229
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3226229,
        22.5591072,
        88.6426229,
        22.8791072
      ]
    },
    {
      "id": 2197,
      "name": "Kostroma",
      "names": {
        "zh": "科斯特罗马"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.7679158,
        "lng": 40.9269141
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        40.7443248,
        57.706723,
        41.0580387,
        57.8857209
      ]
    },
    {
      "id": 2198,
      "name": "Zhuji",
      "names": {
        "zh": "诸暨市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7112121,
        "lng": 120.2419855
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.8841866,
        29.3572551,
        120.5363408,
        29.9849114
      ]
    },
    {
      "id": 2199,
      "name": "Round Lake Beach",
      "names": {
        "fr": "Plage du lac rond",
        "zh": "圆湖海滩",
        "de": "Runder Strander Strand",
        "it": "Beach Round Lake",
        "ja": "ラウンドレイクビーチ",
        "th": "ชายหาดรอบทะเลสาบ",
        "pt": "Praia redonda do lago"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.381168,
        "lng": -88.078511
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.120131,
        42.364269,
        -88.043542,
        42.399252
      ]
    },
    {
      "id": 2200,
      "name": "Gyeongsan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.8193291,
        "lng": 128.7276119
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.7226119,
        35.8143291,
        128.7326119,
        35.8243291
      ]
    },
    {
      "id": 2201,
      "name": "Los Alcarrizos",
      "names": {
        "zh": "洛斯阿尔卡拉斯",
        "ja": "ロスアルカリゾス"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.5194103,
        "lng": -70.01074198175033
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.0110472,
        18.5191261,
        -70.0104996,
        18.520015
      ]
    },
    {
      "id": 2202,
      "name": "Governador Valadares",
      "names": {
        "es": "Gobernador valadares",
        "fr": "Gouvernadeur Valadares",
        "zh": "治理阀门",
        "it": "Governador Valaranes",
        "id": "Valadares Gubernur",
        "ja": "ガバダルバラダール",
        "th": "หน่วยวาจา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.8518863,
        "lng": -41.9511151
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.9561151,
        -18.8568863,
        -41.9461151,
        -18.8468863
      ]
    },
    {
      "id": 2203,
      "name": "Taboao da Serra",
      "names": {
        "it": "Tabùo da serra",
        "pt": "Tabôao da serra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6121912,
        "lng": -46.7790539
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.821625,
        -23.6471775,
        -46.7492444,
        -23.5949266
      ]
    },
    {
      "id": 2204,
      "name": "San Mateo",
      "names": {
        "zh": "圣马特奥",
        "ja": "サンマテオ",
        "th": "ซานมาเทโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6951949,
        "lng": 121.1174024
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0774024,
        14.6551949,
        121.1574024,
        14.7351949
      ]
    },
    {
      "id": 2205,
      "name": "Katowice",
      "names": {
        "zh": "卡托维兹",
        "ja": "カトヴィツェ",
        "th": "คาโตวีตเซ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2573798,
        "lng": 19.017078
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        19.017028,
        50.2573298,
        19.017128,
        50.2574298
      ]
    },
    {
      "id": 2206,
      "name": "Shaowu",
      "names": {
        "zh": "邵武"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3471464,
        "lng": 117.4903849
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.3303849,
        27.1871464,
        117.6503849,
        27.5071464
      ]
    },
    {
      "id": 2207,
      "name": "Sfax",
      "names": {
        "zh": "斯法克斯"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 34.7394361,
        "lng": 10.7604024
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.6004024,
        34.5794361,
        10.9204024,
        34.8994361
      ]
    },
    {
      "id": 2208,
      "name": "Nizhnevartovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 60.9339411,
        "lng": 76.5814274
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        76.3426371,
        60.8323623,
        76.962307,
        61.0677163
      ]
    },
    {
      "id": 2209,
      "name": "Linxia Chengguanzhen",
      "names": {
        "zh": "临夏成庄镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.43583,
        "lng": 104.03056
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.01056,
        34.41583,
        104.05056,
        34.45583
      ]
    },
    {
      "id": 2210,
      "name": "Dire Dawa",
      "names": {
        "de": "Dicke Dawa",
        "id": "Dawa Dawa",
        "th": "ด้วงดาวะ"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.5913193,
        "lng": 41.8566373
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        41.7289531,
        9.4724922,
        42.3407115,
        9.784721
      ]
    },
    {
      "id": 2211,
      "name": "Khmelnytskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.42161,
        "lng": 26.99653
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        26.97653,
        49.40161,
        27.01653,
        49.44161
      ]
    },
    {
      "id": 2212,
      "name": "Owo",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.1962012,
        "lng": 5.5868016
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.388226,
        6.8760233,
        5.7613373,
        7.3161931
      ]
    },
    {
      "id": 2213,
      "name": "Southampton",
      "names": {
        "zh": "南安普敦",
        "ja": "サザンプトン",
        "th": "เซาแธมป์ตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.9025349,
        "lng": -1.404189
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.479005,
        50.8715095,
        -1.3219862,
        50.9561307
      ]
    },
    {
      "id": 2214,
      "name": "Savannah",
      "names": {
        "zh": "萨凡纳",
        "ja": "サバンナ",
        "th": "สะวันนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.0836449,
        "lng": -81.148434
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.153434,
        32.0786449,
        -81.143434,
        32.0886449
      ]
    },
    {
      "id": 2215,
      "name": "Posadas",
      "names": {
        "zh": "波萨达斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.3664824,
        "lng": -55.894295
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -56.0585754,
        -27.5824123,
        -55.8395485,
        -27.3242585
      ]
    },
    {
      "id": 2216,
      "name": "Gatineau",
      "names": {
        "zh": "加蒂诺",
        "th": "แกติโน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4841214,
        "lng": -75.6813728
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -75.7687708,
        45.4526885,
        -75.5261813,
        45.5773085
      ]
    },
    {
      "id": 2217,
      "name": "Windsor",
      "names": {
        "zh": "温莎",
        "ja": "ウィンザー",
        "th": "วินด์เซอร์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.2858536,
        "lng": -82.9780695
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -83.1148496,
        42.2341581,
        -82.8911364,
        42.3567913
      ]
    },
    {
      "id": 2218,
      "name": "Mbandaka",
      "names": {
        "zh": "姆班达卡"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.04865,
        "lng": 18.26034
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        18.24034,
        0.02865,
        18.28034,
        0.06865
      ]
    },
    {
      "id": 2219,
      "name": "Myrtle Beach",
      "names": {
        "es": "Playa Myrtle",
        "zh": "默特尔海滩",
        "id": "Pantai Myrtle",
        "ja": "マートルビーチ",
        "th": "หาดไมร์เทิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6956461,
        "lng": -78.8900409
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.978024,
        33.6473768,
        -78.7854686,
        33.791255
      ]
    },
    {
      "id": 2220,
      "name": "Malmo",
      "names": {
        "fr": "Malmö",
        "zh": "马尔默",
        "de": "Malmö",
        "ja": "マルメ",
        "th": "มัลโม"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 55.59438,
        "lng": 13.029953
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        12.910599,
        55.545553,
        13.117434,
        55.651345
      ]
    },
    {
      "id": 2221,
      "name": "Kunsan",
      "names": {
        "zh": "群山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.970355,
        "lng": 126.71583
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.642055,
        35.928979,
        126.776829,
        36.009238
      ]
    },
    {
      "id": 2222,
      "name": "Qarshi",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 38.86667,
        "lng": 65.8
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        65.78,
        38.84667,
        65.82,
        38.88667
      ]
    },
    {
      "id": 2223,
      "name": "Kafr ad Dawwar",
      "names": {
        "zh": "KAFR广告Dawwar",
        "it": "Kafr addabent dawwar",
        "th": "Kafr โฆษณา Dawwar"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.13385,
        "lng": 30.12843
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.10843,
        31.11385,
        30.14843,
        31.15385
      ]
    },
    {
      "id": 2224,
      "name": "Chula Vista",
      "names": {
        "ja": "チュラビスタ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6400541,
        "lng": -117.084195
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.1240136,
        32.5779745,
        -116.9277349,
        32.6856225
      ]
    },
    {
      "id": 2225,
      "name": "Maradi",
      "names": {
        "zh": "马拉迪"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.501206,
        "lng": 7.102534
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        6.942534,
        13.341206,
        7.262534,
        13.661206
      ]
    },
    {
      "id": 2226,
      "name": "Ratlam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.3232102,
        "lng": 75.0429668149866
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.0092537,
        23.296273,
        75.0687562,
        23.3499037
      ]
    },
    {
      "id": 2227,
      "name": "Yeosu",
      "names": {
        "zh": "丽水"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.76062,
        "lng": 127.66215
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        127.64215,
        34.74062,
        127.68215,
        34.78062
      ]
    },
    {
      "id": 2228,
      "name": "Crato",
      "names": {
        "zh": "克拉图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.2369112,
        "lng": -39.4149236
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.5998016,
        -7.575,
        -39.3368321,
        -7.0016559
      ]
    },
    {
      "id": 2229,
      "name": "Tsu",
      "names": {
        "zh": "富士通"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.733994,
        "lng": 136.5102642
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        136.5052642,
        34.728994,
        136.5152642,
        34.738994
      ]
    },
    {
      "id": 2230,
      "name": "Eugene",
      "names": {
        "zh": "尤金",
        "ja": "ユージーン",
        "th": "ยูจีน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0505054,
        "lng": -123.0950506
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.2087577,
        43.9875292,
        -123.0305855,
        44.1322715
      ]
    },
    {
      "id": 2231,
      "name": "Bijiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.97105,
        "lng": 113.20624
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.18624,
        22.95105,
        113.22624,
        22.99105
      ]
    },
    {
      "id": 2232,
      "name": "Palmas",
      "names": {
        "zh": "帕尔马斯",
        "ja": "パルマス",
        "th": "ปาลมาส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.1837852,
        "lng": -48.3336423
      },
      "country": "Brazil",
      "importance": 1,
      "bbox": [
        -48.4229322,
        -10.46,
        -47.7932712,
        -9.9234352
      ]
    },
    {
      "id": 2233,
      "name": "Craiova",
      "names": {
        "zh": "克拉约瓦"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.3288256,
        "lng": 23.8166865
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        23.8116865,
        44.3238256,
        23.8216865,
        44.3338256
      ]
    },
    {
      "id": 2234,
      "name": "Minato",
      "names": {
        "zh": "凑",
        "ja": "港区",
        "th": "มินาโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6432274,
        "lng": 139.7400553
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7087584,
        35.6230363,
        139.7829301,
        35.6826516
      ]
    },
    {
      "id": 2235,
      "name": "Sorong",
      "names": {
        "zh": "索龙"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.8634105,
        "lng": 131.2544805
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        131.1847052,
        -0.952167,
        131.3965323,
        -0.781799
      ]
    },
    {
      "id": 2236,
      "name": "Majene",
      "names": {
        "zh": "马杰内"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.544854,
        "lng": 118.972971
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        118.901524,
        -3.571807,
        119.04037,
        -3.498897
      ]
    },
    {
      "id": 2237,
      "name": "Thai Binh",
      "names": {
        "es": "Tailandés binh",
        "zh": "泰国宾馆",
        "id": "Binh Thailand",
        "ja": "タイビン",
        "th": "binh ไทย",
        "pt": "Binh tailandês"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.449288,
        "lng": 106.3424893
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.2961205,
        20.4001713,
        106.3966215,
        20.5060772
      ]
    },
    {
      "id": 2238,
      "name": "Binjai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 3.6063964,
        "lng": 98.4899865
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        98.3299865,
        3.4463964,
        98.6499865,
        3.7663964
      ]
    },
    {
      "id": 2239,
      "name": "Fuchu",
      "names": {
        "zh": "府中",
        "ja": "府中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.672219,
        "lng": 139.4800091
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4750091,
        35.667219,
        139.4850091,
        35.677219
      ]
    },
    {
      "id": 2240,
      "name": "Gabela",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -10.85147,
        "lng": 14.379927
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.339927,
        -10.89147,
        14.419927,
        -10.81147
      ]
    },
    {
      "id": 2241,
      "name": "Oral",
      "names": {
        "fr": "Par voie orale",
        "zh": "口服",
        "it": "Orale",
        "id": "Lisan",
        "ja": "オーラル",
        "th": "ช่องปาก"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.228799,
        "lng": 51.3697701
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        51.3647701,
        51.223799,
        51.3747701,
        51.233799
      ]
    },
    {
      "id": 2242,
      "name": "Dayr az Zawr",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3333335,
        "lng": 40.1499999
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        39.9899999,
        35.1733335,
        40.3099999,
        35.4933335
      ]
    },
    {
      "id": 2243,
      "name": "Brahmanbaria",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 23.9691452,
        "lng": 91.1104549
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        90.9504549,
        23.8091452,
        91.2704549,
        24.1291452
      ]
    },
    {
      "id": 2244,
      "name": "Sarta",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.1033904,
        "lng": 35.0924669
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        35.0724669,
        32.0833904,
        35.1124669,
        32.1233904
      ]
    },
    {
      "id": 2245,
      "name": "Plymouth",
      "names": {
        "zh": "普利茅斯",
        "ja": "プリマス",
        "th": "พลีมัธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.3714122,
        "lng": -4.1424451
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -4.2055324,
        50.3327426,
        -4.0196056,
        50.4441737
      ]
    },
    {
      "id": 2246,
      "name": "Santo Domingo de los Colorados",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.25302,
        "lng": -79.17509
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.19509,
        -0.27302,
        -79.15509,
        -0.23302
      ]
    },
    {
      "id": 2247,
      "name": "Novorossiysk",
      "names": {
        "zh": "新罗西斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.712538,
        "lng": 37.771743
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.725193,
        44.690193,
        37.82122,
        44.748806
      ]
    },
    {
      "id": 2248,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.991168049999999,
        "lng": -89.55515973238816
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -89.6144312,
        13.9470034,
        -89.5109447,
        14.0353263
      ]
    },
    {
      "id": 2249,
      "name": "Gombe",
      "names": {
        "zh": "贡贝"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.28221605,
        "lng": 11.16297712124402
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        11.1212473,
        10.2390509,
        11.2011471,
        10.3239946
      ]
    },
    {
      "id": 2250,
      "name": "Gijon",
      "names": {
        "es": "Gijón",
        "zh": "希洪",
        "id": "Gijón",
        "ja": "ヒホン",
        "th": "จิชง"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.5366663,
        "lng": -5.6774748
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.6790196,
        43.5356123,
        -5.675813,
        43.5377933
      ]
    },
    {
      "id": 2251,
      "name": "Espoo",
      "names": {
        "zh": "埃斯波",
        "ja": "エスポー",
        "th": "เอสโป"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.20552375,
        "lng": 24.65874604060196
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.6561645,
        60.2049983,
        24.6597496,
        60.2057382
      ]
    },
    {
      "id": 2252,
      "name": "Drug",
      "names": {
        "es": "Drogas",
        "fr": "Médicament",
        "zh": "药物",
        "it": "La droga",
        "id": "Obat",
        "ja": "薬剤",
        "th": "ยาเสพติด",
        "pt": "Drogas"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.3904766,
        "lng": 78.3471751
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.3271751,
        20.3704766,
        78.3671751,
        20.4104766
      ]
    },
    {
      "id": 2253,
      "name": "Mito",
      "names": {
        "zh": "有丝分裂",
        "ja": "水戸",
        "th": "โตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3659174,
        "lng": 140.4731743
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.32173,
        36.300725,
        140.586801,
        36.464438
      ]
    },
    {
      "id": 2254,
      "name": "Bamenda",
      "names": {
        "zh": "巴门达"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 5.95266,
        "lng": 10.15824
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        10.13824,
        5.93266,
        10.17824,
        5.97266
      ]
    },
    {
      "id": 2255,
      "name": "Floridablanca",
      "names": {
        "zh": "佛罗里达布兰卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0625358,
        "lng": -73.0858228
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.1351018,
        7.0172445,
        -73.0017186,
        7.1483199
      ]
    },
    {
      "id": 2256,
      "name": "Handwara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 34.3993139,
        "lng": 74.2827396
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.2427396,
        34.3593139,
        74.3227396,
        34.4393139
      ]
    },
    {
      "id": 2257,
      "name": "Talisay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2430205,
        "lng": 123.8488245
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6888245,
        10.0830205,
        124.0088245,
        10.4030205
      ]
    },
    {
      "id": 2258,
      "name": "Canton",
      "names": {
        "es": "Cantón",
        "zh": "广州",
        "de": "Kanton",
        "it": "Cantone",
        "ja": "広東",
        "th": "ตำบล",
        "pt": "Cantão"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7985464,
        "lng": -81.3749508
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.4292779,
        40.7436591,
        -81.284736,
        40.865864
      ]
    },
    {
      "id": 2259,
      "name": "Yoshkar-Ola",
      "names": {
        "zh": "约什卡尔奥拉"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.6326996,
        "lng": 47.8975847
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        47.8084019,
        56.5829591,
        48.1240258,
        56.7115487
      ]
    },
    {
      "id": 2260,
      "name": "Nalchik",
      "names": {
        "zh": "纳尔奇克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.4912352,
        "lng": 43.6160498
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        43.6110498,
        43.4862352,
        43.6210498,
        43.4962352
      ]
    },
    {
      "id": 2261,
      "name": "Aswan",
      "names": {
        "es": "Asuán",
        "fr": "Assouan",
        "zh": "阿斯旺",
        "de": "Assuan",
        "it": "Assuan",
        "ja": "アスワン",
        "th": "อัสวาน"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 24.0875,
        "lng": 32.898889
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        32.878889,
        24.0675,
        32.918889,
        24.1075
      ]
    },
    {
      "id": 2262,
      "name": "Ichihara",
      "names": {
        "zh": "市原",
        "ja": "市原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.497775,
        "lng": 140.1156996
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.9499101,
        35.231205,
        140.260244,
        35.5801838
      ]
    },
    {
      "id": 2263,
      "name": "Zhytomyr",
      "names": {
        "fr": "Jitomir"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.257893100000004,
        "lng": 28.66050156170747
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        28.6601549,
        50.2573837,
        28.6609399,
        50.2581079
      ]
    },
    {
      "id": 2264,
      "name": "Imphal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.7991162,
        "lng": 93.9364419
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        93.7764419,
        24.6391162,
        94.0964419,
        24.9591162
      ]
    },
    {
      "id": 2265,
      "name": "Chernivtsi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2864702,
        "lng": 25.9376532
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        25.8065244,
        48.2452221,
        26.0349074,
        48.3974913
      ]
    },
    {
      "id": 2266,
      "name": "Heroica Nogales",
      "names": {
        "fr": "Héroïque nogales",
        "ja": "ヒロイカノガレス",
        "pt": "Heróica nogales"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3285057,
        "lng": -110.9450472
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -111.1050472,
        31.1685057,
        -110.7850472,
        31.4885057
      ]
    },
    {
      "id": 2267,
      "name": "Sumare",
      "names": {
        "es": "Sumaré",
        "pt": "Sumaré"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8217964,
        "lng": -47.267105
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.3706769,
        -22.9083958,
        -47.1532743,
        -22.7870668
      ]
    },
    {
      "id": 2268,
      "name": "Lubbock",
      "names": {
        "zh": "拉伯克",
        "ja": "ラボック",
        "th": "บ็อค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5855677,
        "lng": -101.8470215
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -102.032121,
        33.4478552,
        -101.7490053,
        33.7084347
      ]
    },
    {
      "id": 2269,
      "name": "Yanbu`",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 24.089019,
        "lng": 38.0687076
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        37.9087076,
        23.929019,
        38.2287076,
        24.249019
      ]
    },
    {
      "id": 2270,
      "name": "Sumy",
      "names": {
        "zh": "苏梅"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.9119775,
        "lng": 34.8027723
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        34.6910575,
        50.8368504,
        34.9180782,
        50.963795
      ]
    },
    {
      "id": 2271,
      "name": "Tshikapa",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -6.41621,
        "lng": 20.79995
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        20.77995,
        -6.43621,
        20.81995,
        -6.39621
      ]
    },
    {
      "id": 2272,
      "name": "Anantapur",
      "names": {
        "zh": "阿嫩达布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 14.6862885,
        "lng": 77.5952461
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.5902461,
        14.6812885,
        77.6002461,
        14.6912885
      ]
    },
    {
      "id": 2273,
      "name": "Westminster",
      "names": {
        "zh": "威斯敏斯特",
        "ja": "ウェストミンスター",
        "th": "เวสต์มินสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4973206,
        "lng": -0.137149
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.2160909,
        51.4838061,
        -0.1110827,
        51.5398121
      ]
    },
    {
      "id": 2274,
      "name": "Reading",
      "names": {
        "es": "Leyendo",
        "fr": "En train de lire",
        "zh": "阅读",
        "de": "Lesen",
        "it": "Lettura",
        "id": "Bacaan",
        "ja": "読み取り",
        "th": "การอ่าน",
        "pt": "Leitura"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.335345,
        "lng": -75.9279495
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.9606857,
        40.296502,
        -75.890512,
        40.371251
      ]
    },
    {
      "id": 2275,
      "name": "Winter Haven",
      "names": {
        "fr": "Havre d'hiver",
        "zh": "冬天避风港",
        "de": "Winterhafen",
        "it": "Rifugio invernale",
        "id": "Surga musim dingin",
        "ja": "ウィンターヘイブン",
        "th": "สวรรค์",
        "pt": "Refúgio de inverno"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0222435,
        "lng": -81.7328568
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.7701168,
        27.9006397,
        -81.6276636,
        28.0922184
      ]
    },
    {
      "id": 2276,
      "name": "Myeik",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 12.43954,
        "lng": 98.60028
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        98.58028,
        12.41954,
        98.62028,
        12.45954
      ]
    },
    {
      "id": 2277,
      "name": "Maraba",
      "names": {
        "zh": "马拉巴",
        "pt": "Marabá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.3462821,
        "lng": -49.1007401
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.401645,
        -6.2177981,
        -48.7285472,
        -4.8330735
      ]
    },
    {
      "id": 2278,
      "name": "Salem",
      "names": {
        "zh": "塞勒姆",
        "ja": "セーラム",
        "th": "ซาเลม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9391565,
        "lng": -123.033121
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -123.1229614,
        44.8512459,
        -122.9352099,
        45.0166247
      ]
    },
    {
      "id": 2279,
      "name": "Yao",
      "names": {
        "zh": "姚明",
        "ja": "ヤオ",
        "th": "ยาว"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.626275,
        "lng": 135.605845
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5618608,
        34.583332,
        135.664287,
        34.650776
      ]
    },
    {
      "id": 2280,
      "name": "Barueri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5112184,
        "lng": -46.8764612
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.9663017,
        -23.553651,
        -46.798684,
        -23.4703789
      ]
    },
    {
      "id": 2281,
      "name": "Djelfa",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.6747827,
        "lng": 3.2531091
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        3.2530591,
        34.6747327,
        3.2531591,
        34.6748327
      ]
    },
    {
      "id": 2282,
      "name": "Suncheon",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 34.9505457,
        "lng": 127.4872992
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        127.1763181,
        34.8121123,
        127.5936308,
        35.1880844
      ]
    },
    {
      "id": 2283,
      "name": "Jalalabad",
      "names": {
        "zh": "贾拉拉巴德",
        "ja": "ジャラーラーバード",
        "th": "จาลาลาบัด"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.430195,
        "lng": 70.460128
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        70.4003431,
        34.3948583,
        70.4839298,
        34.4544313
      ]
    },
    {
      "id": 2284,
      "name": "St. Petersburg",
      "names": {
        "es": "San Petersburgo",
        "fr": "Saint-Pétersbourg",
        "zh": "圣彼得堡",
        "it": "San Pietroburgo",
        "ja": "サンクトペテルブルク",
        "th": "เซนต์ปีเตอร์สเบิร์ก",
        "pt": "São Petersburgo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.7700475,
        "lng": -82.6359078
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.769023,
        27.643638,
        -82.540618,
        27.913901
      ]
    },
    {
      "id": 2285,
      "name": "Engels",
      "names": {
        "zh": "恩格斯",
        "de": "Duits",
        "ja": "エンゲルス",
        "th": "เองเงิลส์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.5013775,
        "lng": 46.1233093
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        45.981335,
        51.4236079,
        46.2564701,
        51.5267907
      ]
    },
    {
      "id": 2286,
      "name": "Malolos",
      "names": {
        "zh": "马洛洛斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.843759,
        "lng": 120.8113694
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.7813431,
        14.7265754,
        120.8662967,
        14.890702
      ]
    },
    {
      "id": 2287,
      "name": "Wenling",
      "names": {
        "zh": "温岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.3761941,
        "lng": 121.3808408
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.1656237,
        28.1454129,
        121.8975538,
        28.5333351
      ]
    },
    {
      "id": 2288,
      "name": "Oruro",
      "names": {
        "zh": "奥鲁罗"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.9698363,
        "lng": -67.1148341
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -67.2748341,
        -18.1298363,
        -66.9548341,
        -17.8098363
      ]
    },
    {
      "id": 2289,
      "name": "Nogales",
      "names": {
        "zh": "诺加利斯",
        "ja": "ノガレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3285057,
        "lng": -110.9450472
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -111.1050472,
        31.1685057,
        -110.7850472,
        31.4885057
      ]
    },
    {
      "id": 2290,
      "name": "Dezful",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.38114,
        "lng": 48.40581
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.38581,
        32.36114,
        48.42581,
        32.40114
      ]
    },
    {
      "id": 2291,
      "name": "Sao Jose dos Pinhais",
      "names": {
        "zh": "圣何塞Dos Pinhais",
        "ja": "サンノゼノドスピナハイ",
        "pt": "São José dos Pinhais"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.533816,
        "lng": -49.2072163
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.2727074,
        -25.8320317,
        -48.8425255,
        -25.4622396
      ]
    },
    {
      "id": 2292,
      "name": "Lafayette",
      "names": {
        "zh": "老佛爷",
        "ja": "ラファイエット",
        "th": "ลาฟาแยต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2262187,
        "lng": -92.0178202
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -92.1077963,
        30.1239592,
        -91.9713333,
        30.2964622
      ]
    },
    {
      "id": 2293,
      "name": "Kyongju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.85,
        "lng": 129.2167
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2294,
      "name": "Nagaoka",
      "names": {
        "zh": "长冈",
        "ja": "長岡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.446996,
        "lng": 138.8512199
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.5285141,
        37.176478,
        139.124284,
        37.7270967
      ]
    },
    {
      "id": 2295,
      "name": "Dumai",
      "names": {
        "zh": "杜迈"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.6631967,
        "lng": 101.4470369
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        101.0092922,
        1.4310619,
        101.7281693,
        2.2742801
      ]
    },
    {
      "id": 2296,
      "name": "Taluqan",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.7356491,
        "lng": 69.5350725
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        69.3750725,
        36.5756491,
        69.6950725,
        36.8956491
      ]
    },
    {
      "id": 2297,
      "name": "Gent",
      "names": {
        "zh": "根特",
        "ja": "ゲント",
        "th": "สุภาพบุรุษ"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0538286,
        "lng": 3.7250121
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.5797616,
        50.9795422,
        3.849325,
        51.1888864
      ]
    },
    {
      "id": 2298,
      "name": "Damanhur",
      "names": {
        "zh": "达曼胡尔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.03917,
        "lng": 30.46914
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.44914,
        31.01917,
        30.48914,
        31.05917
      ]
    },
    {
      "id": 2299,
      "name": "Nawabshah",
      "names": {
        "zh": "讷瓦布沙阿"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 26.2459383,
        "lng": 68.4120801
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.4070801,
        26.2409383,
        68.4170801,
        26.2509383
      ]
    },
    {
      "id": 2300,
      "name": "Volta Redonda",
      "names": {
        "ja": "ボルタレドンダ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.521856,
        "lng": -44.1040128
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.1560279,
        -22.6405916,
        -44.0025846,
        -22.4062726
      ]
    },
    {
      "id": 2301,
      "name": "Annaba",
      "names": {
        "zh": "安纳巴",
        "th": "อันนาบะ"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.8967968,
        "lng": 7.7646249
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        7.7645749,
        36.8967468,
        7.7646749,
        36.8968468
      ]
    },
    {
      "id": 2302,
      "name": "Laredo",
      "names": {
        "zh": "拉雷多",
        "th": "ลาเรโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.5056251,
        "lng": -99.5074517
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -99.7503179,
        27.36649,
        -99.3310257,
        27.7444122
      ]
    },
    {
      "id": 2303,
      "name": "Kakogawacho-honmachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.76943,
        "lng": 134.82905
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2304,
      "name": "Wollongong",
      "names": {
        "zh": "卧龙岗",
        "th": "ลองกอง"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -34.4270314,
        "lng": 150.8881858
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        150.8831858,
        -34.4320314,
        150.8931858,
        -34.4220314
      ]
    },
    {
      "id": 2305,
      "name": "Bordeaux",
      "names": {
        "es": "Burdeos",
        "zh": "波尔多",
        "ja": "ボルドー",
        "th": "บอร์โด"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.841225,
        "lng": -0.5800364
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        -0.6386987,
        44.8107826,
        -0.5336838,
        44.9161806
      ]
    },
    {
      "id": 2306,
      "name": "Nonthaburi",
      "names": {
        "zh": "暖武里府",
        "th": "นนทบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.855626,
        "lng": 100.497811
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.436758,
        13.810366,
        100.560772,
        13.89835
      ]
    },
    {
      "id": 2307,
      "name": "Jersey City",
      "names": {
        "es": "Ciudad de Jersey",
        "zh": "泽西市",
        "de": "Jersey-Stadt",
        "ja": "ジャージーシティ",
        "th": "เมืองเจอร์ซีย์",
        "pt": "Cidade de jersey"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7215682,
        "lng": -74.047455
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.1166865,
        40.661622,
        -74.0206386,
        40.7689376
      ]
    },
    {
      "id": 2308,
      "name": "Marilao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.75778,
        "lng": 120.94833
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.92833,
        14.73778,
        120.96833,
        14.77778
      ]
    },
    {
      "id": 2309,
      "name": "Venice",
      "names": {
        "es": "Venecia",
        "fr": "Venise",
        "zh": "威尼斯",
        "de": "Venedig",
        "it": "Venezia",
        "ja": "ヴェネツィア",
        "th": "เวนิส",
        "pt": "Veneza"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4371908,
        "lng": 12.3345898
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        12.1668349,
        45.2312466,
        12.5965709,
        45.5779981
      ]
    },
    {
      "id": 2310,
      "name": "Quang Ngai",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 15.1209627,
        "lng": 108.7813826
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        108.7763826,
        15.1159627,
        108.7863826,
        15.1259627
      ]
    },
    {
      "id": 2311,
      "name": "Concord",
      "names": {
        "zh": "康科德",
        "it": "A Concord",
        "ja": "コンコルド",
        "th": "คองคอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4094178,
        "lng": -80.5800049
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.7650577,
        35.305396,
        -80.492392,
        35.482811
      ]
    },
    {
      "id": 2312,
      "name": "Olongapo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8314675,
        "lng": 120.2835205
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.2331911,
        14.8022329,
        120.3496018,
        14.9117926
      ]
    },
    {
      "id": 2313,
      "name": "Ciudad Benito Juarez",
      "names": {
        "es": "Ciudad Benito Juárez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6467011,
        "lng": -100.0907766
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.1307766,
        25.6067011,
        -100.0507766,
        25.6867011
      ]
    },
    {
      "id": 2314,
      "name": "Gelsenkirchen",
      "names": {
        "zh": "盖尔森基兴",
        "ja": "ゲルゼンキルヒェン",
        "th": "เกลเซนเคียร์เคิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5110321,
        "lng": 7.0960124
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.9874995,
        51.4808012,
        7.152337,
        51.6315932
      ]
    },
    {
      "id": 2315,
      "name": "Hiratsuka",
      "names": {
        "zh": "平冢",
        "ja": "平塚"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3274105,
        "lng": 139.3492322
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3442322,
        35.3224105,
        139.3542322,
        35.3324105
      ]
    },
    {
      "id": 2316,
      "name": "Columbus",
      "names": {
        "zh": "哥伦布",
        "ja": "コロンバス",
        "th": "โคลัมบัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.4610708,
        "lng": -84.9880449
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.079207,
        32.3738385,
        -84.667463,
        32.6081231
      ]
    },
    {
      "id": 2317,
      "name": "Monchengladbach",
      "names": {
        "es": "Mönchengladbach",
        "fr": "Mönchengladbach",
        "zh": "门兴格拉德巴赫",
        "de": "Mönchengladbach",
        "it": "Mönchengladbach",
        "id": "Mönchengladbach",
        "ja": "メンヒェングラットバッハ",
        "pt": "Mönchengladbach"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1947131,
        "lng": 6.4353792
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.2910981,
        51.085784,
        6.5366448,
        51.2478874
      ]
    },
    {
      "id": 2318,
      "name": "Chandler",
      "names": {
        "zh": "钱德勒",
        "ja": "チャンドラー",
        "th": "เทียน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3062031,
        "lng": -111.841185
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.9722324,
        33.2037629,
        -111.7553271,
        33.3613382
      ]
    },
    {
      "id": 2319,
      "name": "Fukui",
      "names": {
        "zh": "福井",
        "ja": "福井",
        "th": "ฟุกุอิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0621411,
        "lng": 136.2221908
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        136.2171908,
        36.0571411,
        136.2271908,
        36.0671411
      ]
    },
    {
      "id": 2320,
      "name": "Soka",
      "names": {
        "zh": "创价",
        "ja": "創価"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8262233,
        "lng": 139.8061784
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7636225,
        35.8052433,
        139.840853,
        35.871571
      ]
    },
    {
      "id": 2321,
      "name": "Kunduz",
      "names": {
        "zh": "昆都士"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.7175566,
        "lng": 68.8672916
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        68.7072916,
        36.5575566,
        69.0272916,
        36.8775566
      ]
    },
    {
      "id": 2322,
      "name": "Kingston upon Hull",
      "names": {
        "es": "Kingston al casco",
        "fr": "Kingston sur Hull",
        "zh": "赫尔的金士顿",
        "de": "Kingston auf Rumpf",
        "it": "Kingston su scafo",
        "id": "Kingston atas Hull",
        "ja": "キングストンアッパーハル",
        "pt": "Kingston no casco"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7435722,
        "lng": -0.3394758
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.4994758,
        53.5835722,
        -0.1794758,
        53.9035722
      ]
    },
    {
      "id": 2323,
      "name": "Mossoro",
      "names": {
        "zh": "莫索罗",
        "pt": "Mossoró"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1904332,
        "lng": -37.3443872
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.5830954,
        -5.49,
        -37.105,
        -4.8800806
      ]
    },
    {
      "id": 2324,
      "name": "Wutong",
      "names": {
        "zh": "梧桐"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4572035,
        "lng": 111.5913962
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.5713962,
        26.4372035,
        111.6113962,
        26.4772035
      ]
    },
    {
      "id": 2325,
      "name": "Misratah",
      "names": {
        "th": "มิสราทาห์"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.37535,
        "lng": 15.09254
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        15.07254,
        32.35535,
        15.11254,
        32.39535
      ]
    },
    {
      "id": 2326,
      "name": "Derby",
      "names": {
        "zh": "德比",
        "ja": "ダービー",
        "th": "ดาร์บี้"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.9212617,
        "lng": -1.4761491
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.6361491,
        52.7612617,
        -1.3161491,
        53.0812617
      ]
    },
    {
      "id": 2327,
      "name": "Verona",
      "names": {
        "fr": "Vérone",
        "zh": "维罗纳",
        "ja": "ヴェローナ",
        "th": "เวโรนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4384958,
        "lng": 10.9924122
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.8768512,
        45.3494402,
        11.1239,
        45.5418375
      ]
    },
    {
      "id": 2328,
      "name": "Etawah",
      "names": {
        "zh": "埃塔瓦"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.783398,
        "lng": 79.022012
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.988791,
        26.752128,
        79.068368,
        26.822852
      ]
    },
    {
      "id": 2329,
      "name": "Huayin",
      "names": {
        "zh": "华银"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5675163,
        "lng": 110.0920915
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.8785665,
        34.3625179,
        110.2099738,
        34.6681101
      ]
    },
    {
      "id": 2330,
      "name": "McKinney",
      "names": {
        "zh": "麦金尼",
        "ja": "マッキニー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1976496,
        "lng": -96.6154471
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.7679041,
        33.1252923,
        -96.5385097,
        33.3060918
      ]
    },
    {
      "id": 2331,
      "name": "Scottsdale",
      "names": {
        "zh": "斯科茨代尔",
        "ja": "スコッツデール",
        "th": "สกอตส์เดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4942189,
        "lng": -111.926018
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.9609326,
        33.4476308,
        -111.7560682,
        33.9005256
      ]
    },
    {
      "id": 2332,
      "name": "Killeen",
      "names": {
        "zh": "基林",
        "th": "คิลลีน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.1171441,
        "lng": -97.727796
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.8244787,
        31.0234573,
        -97.65132,
        31.1410253
      ]
    },
    {
      "id": 2333,
      "name": "Bergen",
      "names": {
        "zh": "卑尔根",
        "ja": "ベルゲン",
        "th": "เบอร์เกน"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.3943055,
        "lng": 5.3259192
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        5.1445788,
        60.1760905,
        5.6867918,
        60.5360925
      ]
    },
    {
      "id": 2334,
      "name": "Tallahassee",
      "names": {
        "zh": "塔拉哈西",
        "ja": "タラハシー",
        "th": "แทลลาแฮสซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.46279785,
        "lng": -84.26973287978753
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -84.2701454,
        30.4625535,
        -84.2694302,
        30.4632379
      ]
    },
    {
      "id": 2335,
      "name": "Horlivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.326649,
        "lng": 38.020217
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        37.920556,
        48.265741,
        38.267363,
        48.401533
      ]
    },
    {
      "id": 2336,
      "name": "Antsirabe",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -19.871403,
        "lng": 47.034667
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        46.874667,
        -20.031403,
        47.194667,
        -19.711403
      ]
    },
    {
      "id": 2337,
      "name": "Ondo",
      "names": {
        "zh": "音头",
        "ja": "オンド"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.1,
        "lng": 4.833333
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        4.793333,
        7.06,
        4.873333,
        7.14
      ]
    },
    {
      "id": 2338,
      "name": "Ap Da Loi",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 22.231151150000002,
        "lng": 114.16040964349827
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        114.158839,
        22.2288198,
        114.1626363,
        22.2335042
      ]
    },
    {
      "id": 2339,
      "name": "Foz do Iguacu",
      "names": {
        "zh": "foz做iguacu",
        "ja": "フォスドイグアス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.5304023,
        "lng": -54.5830692
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.619855,
        -25.6975601,
        -54.2940195,
        -25.211494
      ]
    },
    {
      "id": 2340,
      "name": "Bunkyo-ku",
      "names": {
        "ja": "文京-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.717174,
        "lng": 139.744613
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.717804,
        35.699867,
        139.772308,
        35.735931
      ]
    },
    {
      "id": 2341,
      "name": "Peoria",
      "names": {
        "zh": "皮奥里亚",
        "ja": "ピオリア",
        "th": "พีโอเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6938609,
        "lng": -89.5891008
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.6988676,
        40.651938,
        -89.546494,
        40.8441245
      ]
    },
    {
      "id": 2342,
      "name": "Gravatai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.9440222,
        "lng": -50.9930938
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.100792,
        -30.0158955,
        -50.8111957,
        -29.7656067
      ]
    },
    {
      "id": 2343,
      "name": "Chiayi",
      "names": {
        "zh": "嘉义",
        "ja": "嘉義"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.4811089,
        "lng": 120.4535412
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.3891572,
        23.4395803,
        120.5093696,
        23.5183307
      ]
    },
    {
      "id": 2344,
      "name": "Kediri",
      "names": {
        "zh": "谏义里"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.8111057,
        "lng": 112.0046051
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        111.9544067,
        -7.8728532,
        112.0808181,
        -7.7715701
      ]
    },
    {
      "id": 2345,
      "name": "Damaturu",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.747,
        "lng": 11.9608
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        11.9208,
        11.707,
        12.0008,
        11.787
      ]
    },
    {
      "id": 2346,
      "name": "Tokushima",
      "names": {
        "zh": "德岛",
        "ja": "徳島",
        "th": "โทะกุชิมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0698307,
        "lng": 134.5550353
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        134.422518,
        33.955211,
        134.7666294,
        34.1311299
      ]
    },
    {
      "id": 2347,
      "name": "Arua",
      "names": {
        "zh": "阿鲁阿"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.0225076,
        "lng": 30.9104823
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.8704823,
        2.9825076,
        30.9504823,
        3.0625076
      ]
    },
    {
      "id": 2348,
      "name": "Turmero",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2217183,
        "lng": -67.4865808
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.6465808,
        10.0617183,
        -67.3265808,
        10.3817183
      ]
    },
    {
      "id": 2349,
      "name": "Wilmington",
      "names": {
        "zh": "威尔明顿",
        "ja": "ウィルミントン",
        "th": "วิลมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2257282,
        "lng": -77.9447107
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.956995,
        34.147246,
        -77.786849,
        34.267026
      ]
    },
    {
      "id": 2350,
      "name": "Raichur",
      "names": {
        "zh": "赖久尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.1983225,
        "lng": 77.3527444
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.1927444,
        16.0383225,
        77.5127444,
        16.3583225
      ]
    },
    {
      "id": 2351,
      "name": "Kuala Terengganu",
      "names": {
        "zh": "吉尔瓦·滕甘卢",
        "ja": "クアラ・テレンガー",
        "th": "กัวลาธิการ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.3296461,
        "lng": 103.1383265
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        102.9783265,
        5.1696461,
        103.2983265,
        5.4896461
      ]
    },
    {
      "id": 2352,
      "name": "Daloa",
      "names": {
        "zh": "达洛亚"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.87736,
        "lng": -6.45022
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -6.47022,
        6.85736,
        -6.43022,
        6.89736
      ]
    },
    {
      "id": 2353,
      "name": "San Pedro Carcha",
      "names": {
        "zh": "圣佩德罗卡拉",
        "ja": "サンペドロカルチャ",
        "pt": "San Pedro Cocha"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4770773,
        "lng": -90.3107274
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.3507274,
        15.4370773,
        -90.2707274,
        15.5170773
      ]
    },
    {
      "id": 2354,
      "name": "Mocamedes",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -15.1913515,
        "lng": 12.1496445
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        12.1446445,
        -15.1963515,
        12.1546445,
        -15.1863515
      ]
    },
    {
      "id": 2355,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 33.7923828,
        "lng": -114.0184443
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -114.7314741,
        33.0254058,
        -113.3319554,
        34.31926
      ]
    },
    {
      "id": 2356,
      "name": "Montgomery",
      "names": {
        "zh": "蒙哥马利",
        "ja": "モンゴメリー",
        "th": "มอนต์กอเมอรี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3669656,
        "lng": -86.3006485
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -86.443005,
        32.2505507,
        -86.0691662,
        32.4378726
      ]
    },
    {
      "id": 2357,
      "name": "Wolverhampton",
      "names": {
        "zh": "伍尔弗汉普顿",
        "ja": "ウルヴァーハンプトン",
        "th": "แฮมป์ตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5847651,
        "lng": -2.127567
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.2068817,
        52.5439132,
        -2.0482121,
        52.6378947
      ]
    },
    {
      "id": 2358,
      "name": "Gilbert",
      "names": {
        "zh": "吉尔伯特",
        "ja": "ギルバート",
        "th": "กิลเบิร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3527632,
        "lng": -111.789037
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.8420962,
        33.204638,
        -111.686071,
        33.385792
      ]
    },
    {
      "id": 2359,
      "name": "Rishon LeZiyyon",
      "names": {
        "ja": "Rishon Leziyon"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9693,
        "lng": 34.778173
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.712779,
        31.939585,
        34.84418,
        32.010977
      ]
    },
    {
      "id": 2360,
      "name": "Vitoria-Gasteiz",
      "names": {
        "es": "Vitoria",
        "zh": "维多利亚",
        "ja": "ビトリア・gasteiz",
        "th": "วีโตเรีย-gasteiz"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.8462508,
        "lng": -2.6732683
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -2.6733183,
        42.8462008,
        -2.6732183,
        42.8463008
      ]
    },
    {
      "id": 2361,
      "name": "Kutahya",
      "names": {
        "zh": "屈塔希亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.4199106,
        "lng": 29.9857886
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        29.7225536,
        39.1169911,
        30.4645727,
        39.7115161
      ]
    },
    {
      "id": 2362,
      "name": "Bharatpur",
      "names": {
        "zh": "巴拉普尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.2154447,
        "lng": 77.4986962
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.3386962,
        27.0554447,
        77.6586962,
        27.3754447
      ]
    },
    {
      "id": 2363,
      "name": "Shinozaki",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.86667,
        "lng": 130.86667
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.84667,
        33.84667,
        130.88667,
        33.88667
      ]
    },
    {
      "id": 2364,
      "name": "Goma",
      "names": {
        "zh": "戈马",
        "id": "Coma",
        "ja": "ゴマ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -1.6665685,
        "lng": 29.225652
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        29.1152527,
        -1.7004673,
        29.2600789,
        -1.595112
      ]
    },
    {
      "id": 2365,
      "name": "Tacloban",
      "names": {
        "zh": "塔克洛班"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2436208,
        "lng": 125.0079543
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.8967579,
        11.1807134,
        125.0786439,
        11.3428785
      ]
    },
    {
      "id": 2366,
      "name": "Rach Gia",
      "names": {
        "ja": "Giaをラッキングします"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.0107104,
        "lng": 105.0832623
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.0516879,
        9.9358794,
        105.1628739,
        10.1143278
      ]
    },
    {
      "id": 2367,
      "name": "Kanggye",
      "names": {
        "zh": "江界"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.9659004,
        "lng": 126.5975399
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        126.4154671,
        40.8554513,
        126.7409764,
        41.0957158
      ]
    },
    {
      "id": 2368,
      "name": "Taganrog",
      "names": {
        "zh": "塔甘罗格"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.2335,
        "lng": 38.907032
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.860889,
        47.18861,
        38.951206,
        47.274696
      ]
    },
    {
      "id": 2369,
      "name": "Varzea Grande",
      "names": {
        "zh": "瓦尔齐亚格兰德",
        "ja": "バルジアグランデ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.645816,
        "lng": -56.132218
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.4249161,
        -15.7774891,
        -56.0695256,
        -15.3401208
      ]
    },
    {
      "id": 2370,
      "name": "El Fasher",
      "names": {
        "pt": "El bajeiro"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.62793,
        "lng": 25.34936
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        25.32936,
        13.60793,
        25.36936,
        13.64793
      ]
    },
    {
      "id": 2371,
      "name": "Las Condes",
      "names": {
        "fr": "Las couches",
        "ja": "ラスコンズ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.4084548,
        "lng": -70.5671489
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.6077874,
        -33.4856706,
        -70.4277223,
        -33.3640549
      ]
    },
    {
      "id": 2372,
      "name": "Glendale",
      "names": {
        "zh": "格伦代尔",
        "ja": "グレンデール",
        "th": "เกลนเดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5386858,
        "lng": -112.185994
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.4615632,
        33.5077852,
        -112.1515629,
        33.6979238
      ]
    },
    {
      "id": 2373,
      "name": "Santiago del Estero",
      "names": {
        "id": "Santiago del Esterero",
        "ja": "サンティアゴデルテロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.7877148,
        "lng": -64.2595062
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -64.349632,
        -27.866589,
        -64.1847312,
        -27.7422458
      ]
    },
    {
      "id": 2374,
      "name": "Hakodate",
      "names": {
        "zh": "函馆",
        "ja": "函館",
        "th": "ฮะโกะดะเตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 41.768793,
        "lng": 140.729008
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.6493728,
        41.585065,
        141.4561592,
        42.137512
      ]
    },
    {
      "id": 2375,
      "name": "Begusarai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.417058,
        "lng": 86.135659
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.1139,
        25.39853,
        86.1539,
        25.43853
      ]
    },
    {
      "id": 2376,
      "name": "North Las Vegas",
      "names": {
        "zh": "北拉斯维加斯",
        "de": "Nord-Las Vegas",
        "id": "Las Vegas Utara",
        "ja": "ノースラスベガス",
        "th": "นอร์ทลาสเวกัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.2005843,
        "lng": -115.121584
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.2120179,
        36.1848302,
        -114.954551,
        36.33589
      ]
    },
    {
      "id": 2377,
      "name": "A Coruna",
      "names": {
        "es": "A coruña",
        "fr": "Un corunal",
        "zh": "Coruna",
        "de": "Ein Coruna",
        "it": "Una coruna",
        "ja": "コルーナ",
        "th": "coruna",
        "pt": "Uma coruna"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3709703,
        "lng": -8.3959425
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -8.4691619,
        43.303237,
        -8.3743767,
        43.3900162
      ]
    },
    {
      "id": 2378,
      "name": "Sonipat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.9953758,
        "lng": 77.0233627
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.8633627,
        28.8353758,
        77.1833627,
        29.1553758
      ]
    },
    {
      "id": 2379,
      "name": "Stoke-on-Trent",
      "names": {
        "zh": "斯托克对特伦特",
        "ja": "ストークオントレント",
        "th": "จี้บนเทรนต์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.0079887,
        "lng": -2.1810781
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.1860781,
        53.0029887,
        -2.1760781,
        53.0129887
      ]
    },
    {
      "id": 2380,
      "name": "Los Teques",
      "names": {
        "fr": "Los Tequès",
        "zh": "洛杉矶足球",
        "ja": "ロステケス"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.3437382,
        "lng": -67.0453091
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -67.2053091,
        10.1837382,
        -66.8853091,
        10.5037382
      ]
    },
    {
      "id": 2381,
      "name": "Jinshi",
      "names": {
        "zh": "金石"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.6088759,
        "lng": 111.8717902
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.7117902,
        29.4488759,
        112.0317902,
        29.7688759
      ]
    },
    {
      "id": 2382,
      "name": "Chofugaoka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.654321,
        "lng": 139.5476029
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5475529,
        35.654271,
        139.5476529,
        35.654371
      ]
    },
    {
      "id": 2383,
      "name": "Iwo",
      "names": {
        "zh": "硫磺岛",
        "ja": "硫黄島",
        "th": "อิ"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.633333,
        "lng": 4.183333
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        4.0529289,
        7.5845552,
        4.2519441,
        7.7597523
      ]
    },
    {
      "id": 2384,
      "name": "Higuey",
      "names": {
        "es": "Higüey"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.6127983,
        "lng": -68.7119822
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -68.9376779,
        18.4413908,
        -68.3229138,
        18.9743492
      ]
    },
    {
      "id": 2385,
      "name": "Juazeiro do Norte",
      "names": {
        "zh": "Juazeiro做北部",
        "de": "JuAreiro do Norte",
        "id": "Janairo do norte",
        "ja": "jaazeiro do norte",
        "th": "Juazeiro ทำ Norte"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.2153453,
        "lng": -39.3153336
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.3666996,
        -7.2660133,
        -39.1925844,
        -7.0926443
      ]
    },
    {
      "id": 2386,
      "name": "Bata",
      "names": {
        "zh": "巴塔",
        "ja": "バタ",
        "th": "บาจา"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.869189,
        "lng": 9.7720137
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        9.6616253,
        1.6646666,
        10.0163126,
        1.9831672
      ]
    },
    {
      "id": 2387,
      "name": "Al Minya",
      "names": {
        "zh": "米尼亚",
        "ja": "アルミンヤ",
        "th": "อัลมินายา"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.0893325,
        "lng": 30.7570623
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.5970623,
        27.9293325,
        30.9170623,
        28.2493325
      ]
    },
    {
      "id": 2388,
      "name": "Babol",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.55132,
        "lng": 52.67895
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        52.65895,
        36.53132,
        52.69895,
        36.57132
      ]
    },
    {
      "id": 2389,
      "name": "Komsomol'sk-na-Amure",
      "names": {
        "zh": "komsomol'sk-钠amure",
        "id": "Komsomol'sk-na-amuré",
        "ja": "komsomol'sk-をNA-amure",
        "th": "komsomol'sk-นา-amure"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.5497277,
        "lng": 137.0099189
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        136.8566244,
        50.4199663,
        137.216272,
        50.7006004
      ]
    },
    {
      "id": 2390,
      "name": "Aachen",
      "names": {
        "es": "Aquisgrán",
        "zh": "亚琛",
        "it": "Aquisgrana",
        "ja": "アーヘン",
        "th": "อาเคน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.776351,
        "lng": 6.083862
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        5.9748624,
        50.6621373,
        6.2181081,
        50.8572449
      ]
    },
    {
      "id": 2391,
      "name": "Rivne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.6196175,
        "lng": 26.2513165
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        26.1475993,
        50.5693221,
        26.3409096,
        50.6523081
      ]
    },
    {
      "id": 2392,
      "name": "Galati",
      "names": {
        "zh": "加拉茨",
        "th": "กาลาติ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.4338215,
        "lng": 28.0549395
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.9433558,
        45.3905188,
        28.208689,
        45.6103724
      ]
    },
    {
      "id": 2393,
      "name": "Al Bayda'",
      "names": {
        "es": "Al Bayda '",
        "fr": "Al Bayda '",
        "de": "Al Bayda '",
        "it": "Al bayda '",
        "id": "Al Bayda '",
        "ja": "アルベイダ '",
        "th": "Al Bayda '",
        "pt": "Al bayda '"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.7609529,
        "lng": 21.7577175
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        21.5977175,
        32.6009529,
        21.9177175,
        32.9209529
      ]
    },
    {
      "id": 2394,
      "name": "Kurmuk",
      "names": {
        "zh": "库尔穆克"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 10.556617,
        "lng": 34.2849452
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        33.5745604,
        9.499999,
        34.356944,
        10.6692119
      ]
    },
    {
      "id": 2395,
      "name": "Shibuya-ku",
      "names": {
        "zh": "涩谷-ku",
        "ja": "渋谷区ku",
        "th": "ชิบุยะ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.658573,
        "lng": 139.702082
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.661697,
        35.641602,
        139.723206,
        35.692066
      ]
    },
    {
      "id": 2396,
      "name": "Thu Dau Mot",
      "names": {
        "zh": "星期四",
        "de": "Do dau mot",
        "id": "Kamis Dau Mot",
        "th": "ทู่เดา"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.98671,
        "lng": 106.65497
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.63497,
        10.96671,
        106.67497,
        11.00671
      ]
    },
    {
      "id": 2397,
      "name": "Braunschweig",
      "names": {
        "zh": "不伦瑞克",
        "ja": "ブラウンシュヴァイク",
        "th": "รอนช"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.271656449999995,
        "lng": 10.520313830394242
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.4124522,
        52.1867942,
        10.6433892,
        52.3573831
      ]
    },
    {
      "id": 2398,
      "name": "Manzhouli",
      "names": {
        "zh": "满洲里"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 49.5914042,
        "lng": 117.4464324
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.2106948,
        49.3220526,
        117.8932919,
        49.6308469
      ]
    },
    {
      "id": 2399,
      "name": "Gdynia",
      "names": {
        "zh": "格丁尼亚",
        "ja": "グディニャ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.5164982,
        "lng": 18.5402738
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.3579808,
        54.42275,
        18.9098262,
        54.6241605
      ]
    },
    {
      "id": 2400,
      "name": "Palangkaraya",
      "names": {
        "zh": "帕朗卡拉亚"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.2072919,
        "lng": 113.9164372
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        113.4756897,
        -2.4036174,
        114.1082873,
        -1.5847192
      ]
    },
    {
      "id": 2401,
      "name": "Hafr al Batin",
      "names": {
        "id": "Hapr al batin"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 28.338663699999998,
        "lng": 46.123580021929875
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        46.1041928,
        28.3157375,
        46.1440283,
        28.3638158
      ]
    },
    {
      "id": 2402,
      "name": "Chigasaki",
      "names": {
        "zh": "茅崎",
        "ja": "茅ヶ崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.333722,
        "lng": 139.405938
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.357112,
        35.303871,
        139.465956,
        35.36149
      ]
    },
    {
      "id": 2403,
      "name": "Sahiwal",
      "names": {
        "zh": "萨希瓦尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.6715098,
        "lng": 73.1110572
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.9510572,
        30.5115098,
        73.2710572,
        30.8315098
      ]
    },
    {
      "id": 2404,
      "name": "Kiel",
      "names": {
        "zh": "基尔",
        "ja": "キール",
        "th": "คีล"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.3227085,
        "lng": 10.135555
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        10.0329284,
        54.2507055,
        10.2186177,
        54.4329395
      ]
    },
    {
      "id": 2405,
      "name": "Shrirampur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7549951,
        "lng": 88.3416672
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.1816672,
        22.5949951,
        88.5016672,
        22.9149951
      ]
    },
    {
      "id": 2406,
      "name": "Portsmouth",
      "names": {
        "zh": "朴茨茅斯",
        "ja": "ポーツマス",
        "th": "พอร์ทสมัธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.800031,
        "lng": -1.0906023
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.1749699,
        50.7498477,
        -1.0184909,
        50.859311
      ]
    },
    {
      "id": 2407,
      "name": "Sibu",
      "names": {
        "zh": "诗巫"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.2906434,
        "lng": 111.8256158
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        111.6656158,
        2.1306434,
        111.9856158,
        2.4506434
      ]
    },
    {
      "id": 2408,
      "name": "Yato",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.153758,
        "lng": 133.2607054
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.2507054,
        35.143758,
        133.2707054,
        35.163758
      ]
    },
    {
      "id": 2409,
      "name": "Parana",
      "names": {
        "es": "Paraná",
        "zh": "巴拉那",
        "ja": "パラナ",
        "th": "ปารานา",
        "pt": "Paraná"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.7330145,
        "lng": -60.5298511
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -60.5934841,
        -31.8041253,
        -60.3911504,
        -31.6874361
      ]
    },
    {
      "id": 2410,
      "name": "Santa Clara",
      "names": {
        "zh": "圣克拉拉",
        "ja": "サンタクララ",
        "th": "ซานตาคลาร่า"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.406628,
        "lng": -79.9651013
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -80.0811585,
        22.2635174,
        -79.7639293,
        22.5622827
      ]
    },
    {
      "id": 2411,
      "name": "Yamagata",
      "names": {
        "zh": "山形",
        "ja": "山形",
        "th": "ยะมะงะตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.2484701,
        "lng": 140.3278031
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        140.3228031,
        38.2434701,
        140.3328031,
        38.2534701
      ]
    },
    {
      "id": 2412,
      "name": "Imperatriz",
      "names": {
        "zh": "因佩拉特里斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.5269279,
        "lng": -47.478115
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.8668176,
        -5.6457605,
        -47.364,
        -5.128
      ]
    },
    {
      "id": 2413,
      "name": "Oruro",
      "names": {
        "zh": "奥鲁罗"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.9698363,
        "lng": -67.1148341
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -67.2748341,
        -18.1298363,
        -66.9548341,
        -17.8098363
      ]
    },
    {
      "id": 2414,
      "name": "Merlo",
      "names": {
        "zh": "梅洛"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6644504,
        "lng": -58.7278818
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.7328818,
        -34.6694504,
        -58.7228818,
        -34.6594504
      ]
    },
    {
      "id": 2415,
      "name": "Tsukuba-kenkyugakuen-toshi",
      "names": {
        "zh": "筑波-kenkyugakuen-都市",
        "ja": "筑波-kenkyugakuen-トシ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.06315,
        "lng": 140.121493
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2416,
      "name": "Barnsley",
      "names": {
        "zh": "巴恩斯利",
        "th": "บาร์นสลีย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5527719,
        "lng": -1.4827755
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8225901,
        53.4382974,
        -1.2757101,
        53.6126725
      ]
    },
    {
      "id": 2417,
      "name": "Syktyvkar",
      "names": {
        "zh": "瑟克特夫卡尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.6685237,
        "lng": 50.8352024
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        50.629168,
        61.622879,
        50.9187981,
        61.861527
      ]
    },
    {
      "id": 2418,
      "name": "Khimki",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8917293,
        "lng": 37.4396994
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.2290074,
        55.8742007,
        37.5072164,
        55.9932504
      ]
    },
    {
      "id": 2419,
      "name": "Biratnagar",
      "names": {
        "zh": "比拉特纳加尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 26.4831,
        "lng": 87.28337
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        87.26337,
        26.4631,
        87.30337,
        26.5031
      ]
    },
    {
      "id": 2420,
      "name": "Saskatoon",
      "names": {
        "zh": "萨斯卡通",
        "ja": "サスカトゥーン",
        "th": "ซัสคาทูน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 52.131802,
        "lng": -106.660767
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -106.8249655,
        52.0697966,
        -106.503699,
        52.2311425
      ]
    },
    {
      "id": 2421,
      "name": "Abertawe",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6195955,
        "lng": -3.9459248
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -4.1059248,
        51.4595955,
        -3.7859248,
        51.7795955
      ]
    },
    {
      "id": 2422,
      "name": "Jessore",
      "names": {
        "zh": "杰索尔"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.1665256,
        "lng": 89.2094419
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        89.1035065,
        23.0711491,
        89.3886218,
        23.3209625
      ]
    },
    {
      "id": 2423,
      "name": "Beichengqu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.43944,
        "lng": 113.15361
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.13361,
        40.41944,
        113.17361,
        40.45944
      ]
    },
    {
      "id": 2424,
      "name": "Tuy Hoa",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 13.0877226,
        "lng": 109.2993584
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        109.178909,
        13.0101752,
        109.3482341,
        13.1798669
      ]
    },
    {
      "id": 2425,
      "name": "Hapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.731496,
        "lng": 77.776916
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.750203,
        28.706037,
        77.80504,
        28.746723
      ]
    },
    {
      "id": 2426,
      "name": "Chesapeake",
      "names": {
        "zh": "切萨皮克",
        "ja": "チェサピーク",
        "th": "เชสส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7168315,
        "lng": -76.2494453
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.4913958,
        36.5504382,
        -76.0651989,
        36.8671757
      ]
    },
    {
      "id": 2427,
      "name": "Fuji",
      "names": {
        "zh": "富士",
        "ja": "富士",
        "th": "ฟูจิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.161852,
        "lng": 138.6763863
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.557761,
        35.0167422,
        138.812039,
        35.359117
      ]
    },
    {
      "id": 2428,
      "name": "Chemnitz",
      "names": {
        "zh": "开姆尼茨",
        "ja": "ケムニッツ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8323531,
        "lng": 12.918914
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.7275333,
        50.7413804,
        13.0540394,
        50.9039377
      ]
    },
    {
      "id": 2429,
      "name": "Mokpo",
      "names": {
        "zh": "木浦"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.7921339,
        "lng": 126.3876448
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        126.3826448,
        34.7871339,
        126.3926448,
        34.7971339
      ]
    },
    {
      "id": 2430,
      "name": "Avcilar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9799389,
        "lng": 28.7216689
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.6822602,
        40.968936,
        28.7567678,
        41.1060842
      ]
    },
    {
      "id": 2431,
      "name": "Tanga",
      "names": {
        "zh": "坦加",
        "ja": "タンガ",
        "th": "ทังกา"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.0721976,
        "lng": 39.0993457
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        38.8992746,
        -5.2950707,
        39.1537231,
        -4.9619157
      ]
    },
    {
      "id": 2432,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6860512,
        "lng": -53.8069214
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.1368461,
        -30.0056759,
        -53.5168897,
        -29.5493913
      ]
    },
    {
      "id": 2433,
      "name": "Sabzevar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.2134053,
        "lng": 57.6808799
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        57.6392801,
        36.1803576,
        57.7208244,
        36.2440573
      ]
    },
    {
      "id": 2434,
      "name": "Ramgundam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.80084,
        "lng": 79.45206
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2435,
      "name": "Bahir Dar",
      "names": {
        "es": "Bahir",
        "zh": "巴赫尔达尔",
        "id": "Badir",
        "ja": "バヘルダー"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 11.585324,
        "lng": 37.392436
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        37.377319,
        11.574529,
        37.405266,
        11.603682
      ]
    },
    {
      "id": 2436,
      "name": "Porto",
      "names": {
        "zh": "波尔图",
        "ja": "ポルト",
        "th": "ปอร์โต"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1494512,
        "lng": -8.6107884
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.691289,
        41.1383507,
        -8.5519965,
        41.1859353
      ]
    },
    {
      "id": 2437,
      "name": "Baruta",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4342048,
        "lng": -66.8737185
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.8737685,
        10.4341548,
        -66.8736685,
        10.4342548
      ]
    },
    {
      "id": 2438,
      "name": "Sapele",
      "names": {
        "zh": "沙比利"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.83156395,
        "lng": 5.660127976472991
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.5151148,
        5.7044268,
        5.7794509,
        5.95823
      ]
    },
    {
      "id": 2439,
      "name": "Sasebo",
      "names": {
        "zh": "佐世保"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.1799965,
        "lng": 129.7152872
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        128.8333698,
        33.0369392,
        129.8726781,
        33.5436486
      ]
    },
    {
      "id": 2440,
      "name": "Myitkyina",
      "names": {
        "zh": "密支那"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 25.3833313,
        "lng": 97.3999925
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        96.6733466,
        24.5480128,
        97.5359048,
        26.1876657
      ]
    },
    {
      "id": 2441,
      "name": "Barnstable",
      "names": {
        "zh": "巴恩斯特布尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.674071,
        "lng": -70.359295
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.467605,
        41.586336,
        -70.25964,
        41.740088
      ]
    },
    {
      "id": 2442,
      "name": "Haeju",
      "names": {
        "zh": "海州"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.039421,
        "lng": 125.714383
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        125.694383096,
        38.0194209992,
        125.734383096,
        38.0594209992
      ]
    },
    {
      "id": 2443,
      "name": "Petah Tiqwa",
      "names": {
        "id": "Payah Tiqwa"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.09063765,
        "lng": 34.87657023824613
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.8458773,
        32.0670441,
        34.9203342,
        32.1143913
      ]
    },
    {
      "id": 2444,
      "name": "Norfolk",
      "names": {
        "zh": "诺福克",
        "ja": "ノーフォーク",
        "th": "นอร์ฟอล์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.8493695,
        "lng": -76.2899539
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.3448722,
        36.8207991,
        -76.1767248,
        36.9697231
      ]
    },
    {
      "id": 2445,
      "name": "Gonzalez Catan",
      "names": {
        "ja": "ゴンザレツカターン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.771634,
        "lng": -58.6467472
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.6517472,
        -34.776634,
        -58.6417472,
        -34.766634
      ]
    },
    {
      "id": 2446,
      "name": "Diaobingshancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.46456,
        "lng": 123.54804
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        123.52804,
        42.44456,
        123.56804,
        42.48456
      ]
    },
    {
      "id": 2447,
      "name": "Tarapoto",
      "names": {
        "zh": "塔拉波托"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.486743,
        "lng": -76.362747
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -76.3827475,
        -6.5067425,
        -76.3427475,
        -6.4667425
      ]
    },
    {
      "id": 2448,
      "name": "Zhangping",
      "names": {
        "zh": "漳平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.2933473,
        "lng": 117.4145306
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.1677383,
        24.9111421,
        117.7453418,
        25.7911839
      ]
    },
    {
      "id": 2449,
      "name": "Sao Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "เซาคาร์ลอส",
        "pt": "São Carlos"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.0180395,
        "lng": -47.891154
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.0915459,
        -22.1613425,
        -47.7196115,
        -21.9209342
      ]
    },
    {
      "id": 2450,
      "name": "Aarhus",
      "names": {
        "zh": "奥胡斯",
        "ja": "オーフス",
        "th": "ออฮุส"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 56.1582824,
        "lng": 10.2200793
      },
      "country": "Denmark",
      "importance": 2,
      "bbox": [
        10.2200293,
        56.1582324,
        10.2201293,
        56.1583324
      ]
    },
    {
      "id": 2451,
      "name": "Fremont",
      "names": {
        "zh": "弗里蒙特",
        "ja": "フリーモント",
        "th": "ฟรีมอนต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5482697,
        "lng": -121.988571
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.1489115,
        37.4542627,
        -121.8701128,
        37.6066739
      ]
    },
    {
      "id": 2452,
      "name": "Ipatinga",
      "names": {
        "zh": "伊帕廷加"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.4777807,
        "lng": -42.5270802
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.705993,
        -19.5219548,
        -42.492699,
        -19.3698575
      ]
    },
    {
      "id": 2453,
      "name": "Anju",
      "names": {
        "zh": "安居房",
        "ja": "アンジュ"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.6173686,
        "lng": 125.6611381
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.5188365,
        39.5286737,
        125.8463263,
        39.718773
      ]
    },
    {
      "id": 2454,
      "name": "Shanhu",
      "names": {
        "zh": "珊瑚"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.177406,
        "lng": 120.3199179
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.2678788,
        32.1323356,
        120.3590868,
        32.2120177
      ]
    },
    {
      "id": 2455,
      "name": "Narayanganj",
      "names": {
        "zh": "纳拉仰甘"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.6236732,
        "lng": 90.4988075
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        90.3388075,
        23.4636732,
        90.6588075,
        23.7836732
      ]
    },
    {
      "id": 2456,
      "name": "Monclova",
      "names": {
        "zh": "蒙克洛瓦"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8999988,
        "lng": -101.4171173
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.5771173,
        26.7399988,
        -101.2571173,
        27.0599988
      ]
    },
    {
      "id": 2457,
      "name": "Kennewick",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 46.2087066,
        "lng": -119.119919
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.2668694,
        46.160835,
        -119.087144,
        46.2401577
      ]
    },
    {
      "id": 2458,
      "name": "Atushi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.715261,
        "lng": 76.166016
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        76.006016,
        39.555261,
        76.326016,
        39.875261
      ]
    },
    {
      "id": 2459,
      "name": "Chimoio",
      "names": {
        "zh": "希莫尤"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -19.11639,
        "lng": 33.48333
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        33.46333,
        -19.13639,
        33.50333,
        -19.09639
      ]
    },
    {
      "id": 2460,
      "name": "Limassol",
      "names": {
        "zh": "利马索尔",
        "ja": "リマソル",
        "th": "ลีมาซอล"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 34.6852901,
        "lng": 33.0332657
      },
      "country": "Cyprus",
      "importance": 1,
      "bbox": [
        32.9859758,
        34.6431912,
        33.0708607,
        34.7402647
      ]
    },
    {
      "id": 2461,
      "name": "Novo Hamburgo",
      "names": {
        "zh": "诺瓦汉堡",
        "ja": "ノボハンブルゴ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.68674,
        "lng": -51.1328535
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.1329035,
        -29.68679,
        -51.1328035,
        -29.68669
      ]
    },
    {
      "id": 2462,
      "name": "Garland",
      "names": {
        "es": "Guirnalda",
        "zh": "花环",
        "de": "Girlande",
        "it": "Ghirlanda",
        "ja": "ガーランド",
        "th": "พวงมาลัย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.912624,
        "lng": -96.6388833
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.7057568,
        32.830143,
        -96.505519,
        32.9855417
      ]
    },
    {
      "id": 2463,
      "name": "Irving",
      "names": {
        "zh": "欧文",
        "ja": "アーヴィング",
        "th": "เออร์วิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8295183,
        "lng": -96.9442177
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.0340376,
        32.771786,
        -96.888782,
        32.953966
      ]
    },
    {
      "id": 2464,
      "name": "Lille",
      "names": {
        "zh": "里尔",
        "ja": "リール",
        "th": "ลีล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.632786,
        "lng": 3.059524
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        2.967883,
        50.600569,
        3.125694,
        50.661227
      ]
    },
    {
      "id": 2465,
      "name": "Arakawa",
      "names": {
        "zh": "荒川",
        "ja": "荒川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.737529,
        "lng": 139.78131
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7494564,
        35.7247764,
        139.8147382,
        35.7548462
      ]
    },
    {
      "id": 2466,
      "name": "Longueuil",
      "names": {
        "zh": "隆格伊"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5249263,
        "lng": -73.5219251
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.5269251,
        45.5199263,
        -73.5169251,
        45.5299263
      ]
    },
    {
      "id": 2467,
      "name": "Halle",
      "names": {
        "zh": "哈利",
        "ja": "ハレ",
        "th": "ฮัลลี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4825041,
        "lng": 11.9705452
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.8552541,
        51.4021008,
        12.0892171,
        51.5435116
      ]
    },
    {
      "id": 2468,
      "name": "Kosice",
      "names": {
        "zh": "科希策"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7172272,
        "lng": 21.2496774
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        21.119956,
        48.570039,
        21.358213,
        48.824512
      ]
    },
    {
      "id": 2469,
      "name": "Viamao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -30.0819338,
        "lng": -51.0261927
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.1207442,
        -30.4427494,
        -50.6216057,
        -29.9662397
      ]
    },
    {
      "id": 2470,
      "name": "Matsumoto",
      "names": {
        "zh": "松本",
        "ja": "松本",
        "th": "มัตสึโมโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2382047,
        "lng": 137.9687141
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.550178,
        36.009632,
        138.131235,
        36.379442
      ]
    },
    {
      "id": 2471,
      "name": "Banda Aceh",
      "names": {
        "de": "Banda-Aceh",
        "ja": "バンダアチェ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 5.5528455,
        "lng": 95.3192908
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        95.2750633,
        5.516391,
        95.377772,
        5.6072409
      ]
    },
    {
      "id": 2472,
      "name": "Shenmu",
      "names": {
        "zh": "神木"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8229727,
        "lng": 110.4946864
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.3346864,
        38.6629727,
        110.6546864,
        38.9829727
      ]
    },
    {
      "id": 2473,
      "name": "Springs",
      "names": {
        "es": "Muelles",
        "fr": "Ressorts",
        "zh": "弹簧",
        "ja": "スプリングス",
        "th": "น้ำพุ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.243056,
        "lng": 28.431667
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        28.391667,
        -26.283056,
        28.471667,
        -26.203056
      ]
    },
    {
      "id": 2474,
      "name": "Taiping",
      "names": {
        "zh": "太平",
        "th": "ไทปิง"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.85290365,
        "lng": 100.73222445208293
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        100.731571,
        4.8523692,
        100.7326817,
        4.8535085
      ]
    },
    {
      "id": 2475,
      "name": "Zinder",
      "names": {
        "zh": "津德尔"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.8063421,
        "lng": 8.9891659
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        8.8291659,
        13.6463421,
        9.1491659,
        13.9663421
      ]
    },
    {
      "id": 2476,
      "name": "As Sib",
      "names": {
        "es": "Como sib",
        "fr": "Comme sib",
        "zh": "作为sib",
        "de": "Als Sib",
        "it": "Come sib",
        "id": "Sebagai sib",
        "ja": "SIBとして",
        "th": "เป็น sib",
        "pt": "Como sib"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 23.6821956,
        "lng": 58.1843842
      },
      "country": "Oman",
      "importance": 3,
      "bbox": [
        58.0243842,
        23.5221956,
        58.3443842,
        23.8421956
      ]
    },
    {
      "id": 2477,
      "name": "P'yong-dong",
      "names": {
        "zh": "p'yong栋",
        "ja": "p'yong-ドン",
        "th": "p'yong ดอง"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.18611,
        "lng": 125.76056
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.74056,
        40.16611,
        125.78056,
        40.20611
      ]
    },
    {
      "id": 2478,
      "name": "Ivano-Frankivsk",
      "names": {
        "zh": "伊万诺 -  frankivsk",
        "de": "Iwano-frankiwsk"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9224763,
        "lng": 24.710334
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        24.6417506,
        48.8611451,
        24.7589659,
        48.956705
      ]
    },
    {
      "id": 2479,
      "name": "Lishui",
      "names": {
        "zh": "丽水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.4704064,
        "lng": 119.9184297
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.6903533,
        27.4197953,
        120.4319608,
        28.9532344
      ]
    },
    {
      "id": 2480,
      "name": "Quilmes",
      "names": {
        "zh": "基尔梅斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7246723,
        "lng": -58.2607914
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.2657914,
        -34.7296723,
        -58.2557914,
        -34.7196723
      ]
    },
    {
      "id": 2481,
      "name": "Wuyishan",
      "names": {
        "zh": "武夷山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7590448,
        "lng": 118.0297688
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.6272191,
        27.4582482,
        118.3346624,
        28.0790422
      ]
    },
    {
      "id": 2482,
      "name": "Amol",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.4718947,
        "lng": 52.3499009
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        52.3168249,
        36.4331462,
        52.397197,
        36.5114212
      ]
    },
    {
      "id": 2483,
      "name": "Mirpur Khas",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 25.5262817,
        "lng": 69.0110617
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.9410509,
        25.4852662,
        69.0766527,
        25.5590519
      ]
    },
    {
      "id": 2484,
      "name": "Pathein",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.7759,
        "lng": 94.7415472
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        94.7365472,
        16.7709,
        94.7465472,
        16.7809
      ]
    },
    {
      "id": 2485,
      "name": "Nizhnekamsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.63657,
        "lng": 51.82447
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        51.80447,
        55.61657,
        51.84447,
        55.65657
      ]
    },
    {
      "id": 2486,
      "name": "Visalia",
      "names": {
        "zh": "维塞利亚",
        "th": "วิเซเลีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3302284,
        "lng": -119.292058
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.4747216,
        36.26952,
        -119.2337057,
        36.3709311
      ]
    },
    {
      "id": 2487,
      "name": "Al Jubayl",
      "names": {
        "ja": "アルjubayl",
        "th": "อัล Jubayl"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 27.0006968,
        "lng": 49.6532161
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        49.4932161,
        26.8406968,
        49.8132161,
        27.1606968
      ]
    },
    {
      "id": 2488,
      "name": "Neya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 24.897525,
        "lng": 85.486895
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        85.484832,
        24.895125,
        85.491289,
        24.900614
      ]
    },
    {
      "id": 2489,
      "name": "Chishui",
      "names": {
        "zh": "赤水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.5914382,
        "lng": 105.6956747
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.5356747,
        28.4314382,
        105.8556747,
        28.7514382
      ]
    },
    {
      "id": 2490,
      "name": "Centurion",
      "names": {
        "zh": "百夫长",
        "ja": "センチュリオン",
        "th": "นายก"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.8516721,
        "lng": 28.1895872
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        28.1845872,
        -25.8566721,
        28.1945872,
        -25.8466721
      ]
    },
    {
      "id": 2491,
      "name": "Uluberiya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.4756,
        "lng": 88.09898
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.07898,
        22.4556,
        88.11898,
        22.4956
      ]
    },
    {
      "id": 2492,
      "name": "Atlantic City",
      "names": {
        "fr": "Atlantic",
        "zh": "大西洋城",
        "de": "Atlantische Stadt",
        "ja": "アトランティックシティ",
        "th": "แอตแลนติกซิตี",
        "pt": "Atlântico"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3629978,
        "lng": -74.4404394
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.4454394,
        39.3579978,
        -74.4354394,
        39.3679978
      ]
    },
    {
      "id": 2493,
      "name": "Pakdasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4784573,
        "lng": 51.6824624
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.6379921,
        35.4647069,
        51.7415835,
        35.521723
      ]
    },
    {
      "id": 2494,
      "name": "Shakhty",
      "names": {
        "zh": "沙赫特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.7119167,
        "lng": 40.2115699
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        40.073321,
        47.6591624,
        40.3873096,
        47.8139212
      ]
    },
    {
      "id": 2495,
      "name": "Magdeburg",
      "names": {
        "es": "Magdeburgo",
        "zh": "马格德堡",
        "ja": "マクデブルク",
        "th": "แม็ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1315889,
        "lng": 11.6399609
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        11.5172379,
        52.0237486,
        11.7639936,
        52.2283566
      ]
    },
    {
      "id": 2496,
      "name": "Ibb",
      "names": {
        "zh": "伊布"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.9701578,
        "lng": 44.1779446
      },
      "country": "Yemen",
      "importance": 3,
      "bbox": [
        44.0179446,
        13.8101578,
        44.3379446,
        14.1301578
      ]
    },
    {
      "id": 2497,
      "name": "Abha",
      "names": {
        "zh": "艾卜哈"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 18.2164282,
        "lng": 42.5043596
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        42.3443596,
        18.0564282,
        42.6643596,
        18.3764282
      ]
    },
    {
      "id": 2498,
      "name": "Garoua",
      "names": {
        "zh": "加鲁阿"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 9.3070698,
        "lng": 13.3934527
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        13.2334527,
        9.1470698,
        13.5534527,
        9.4670698
      ]
    },
    {
      "id": 2499,
      "name": "Bole",
      "names": {
        "fr": "Fût",
        "zh": "博乐",
        "ja": "木の幹",
        "th": "ลำต้น"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.904806,
        "lng": 82.0629703
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        79.8819854,
        44.005543,
        83.7958142,
        45.3860937
      ]
    },
    {
      "id": 2500,
      "name": "Ganda",
      "names": {
        "zh": "甘达"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -13.0172603,
        "lng": 14.6358601
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.5958601,
        -13.0572603,
        14.6758601,
        -12.9772603
      ]
    },
    {
      "id": 2501,
      "name": "Najafabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.6350743,
        "lng": 51.3636804
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.2624678,
        32.5859244,
        51.4455654,
        32.7040336
      ]
    },
    {
      "id": 2502,
      "name": "Bayamo",
      "names": {
        "zh": "巴亚莫"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3734145,
        "lng": -76.6494244
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -76.8723307,
        20.2177844,
        -76.5125884,
        20.5910151
      ]
    },
    {
      "id": 2503,
      "name": "Puducherry",
      "names": {
        "it": "Pondicherry"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.9340568,
        "lng": 79.8306447
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        79.6706447,
        11.7740568,
        79.9906447,
        12.0940568
      ]
    },
    {
      "id": 2504,
      "name": "Porbandar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.6409,
        "lng": 69.611
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        69.451,
        21.4809,
        69.771,
        21.8009
      ]
    },
    {
      "id": 2505,
      "name": "Kamianske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5167748,
        "lng": 34.6068797
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.4798366,
        48.4455771,
        34.7220288,
        48.6265737
      ]
    },
    {
      "id": 2506,
      "name": "Borujerd",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.8973,
        "lng": 48.7516
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.7316,
        33.8773,
        48.7716,
        33.9173
      ]
    },
    {
      "id": 2507,
      "name": "Paradise",
      "names": {
        "es": "Paraíso",
        "fr": "Paradis",
        "zh": "天堂",
        "de": "Paradies",
        "it": "Paradiso",
        "ja": "パラダイス",
        "th": "สวรรค์",
        "pt": "Paraíso"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0608658,
        "lng": -115.12510798422218
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.208162,
        35.9845921,
        -115.0593253,
        36.137126
      ]
    },
    {
      "id": 2508,
      "name": "Singaraja",
      "names": {
        "zh": "新加拉惹",
        "th": "การาจา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.1133599,
        "lng": 115.0839326
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        115.0439326,
        -8.1533599,
        115.1239326,
        -8.0733599
      ]
    },
    {
      "id": 2509,
      "name": "Elche",
      "names": {
        "zh": "埃尔切"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.2653307,
        "lng": -0.6988391
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.7819842,
        38.127912,
        -0.5101842,
        38.3447961
      ]
    },
    {
      "id": 2510,
      "name": "Macae",
      "names": {
        "zh": "马卡埃",
        "pt": "Macaé"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3712958,
        "lng": -41.7866922
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.9415311,
        -22.4219889,
        -41.6853844,
        -22.1460124
      ]
    },
    {
      "id": 2511,
      "name": "Miri",
      "names": {
        "zh": "美里",
        "ja": "ミリ",
        "th": "มีรี"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.392739,
        "lng": 113.9880255
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        113.8280255,
        4.232739,
        114.1480255,
        4.552739
      ]
    },
    {
      "id": 2512,
      "name": "Machala",
      "names": {
        "zh": "马查拉"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -3.2588565,
        "lng": -79.9588518
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -80.0286899,
        -3.3547457,
        -79.8423504,
        -3.1909346
      ]
    },
    {
      "id": 2513,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.1734995,
        "lng": -3.5995337
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -3.7003616,
        37.1367032,
        -3.4963241,
        37.2250172
      ]
    },
    {
      "id": 2514,
      "name": "Bo",
      "names": {
        "zh": "博",
        "ja": "ボー",
        "th": "บ่อ"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 7.9564,
        "lng": -11.74
      },
      "country": "Sierra Leone",
      "importance": 1
    },
    {
      "id": 2515,
      "name": "Messina",
      "names": {
        "fr": "Messine",
        "zh": "墨西拿",
        "ja": "メッシーナ",
        "th": "เมสสินา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.1937571,
        "lng": 15.5542082
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        15.4079341,
        38.0555079,
        15.6530796,
        38.3014883
      ]
    },
    {
      "id": 2516,
      "name": "Eindhoven",
      "names": {
        "zh": "埃因霍温",
        "ja": "アイントホーフェン",
        "th": "ไอนด์โฮเฟ่น"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4392648,
        "lng": 5.478633
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3567184,
        51.4000475,
        5.5488528,
        51.4970779
      ]
    },
    {
      "id": 2517,
      "name": "Nashua",
      "names": {
        "zh": "纳舒厄",
        "ja": "ナシュア",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7656251,
        "lng": -71.4677032
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.5613907,
        42.699873,
        -71.4322913,
        42.8058627
      ]
    },
    {
      "id": 2518,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4174531,
        "lng": -70.1103346
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -70.2271332,
        18.2717288,
        -70.0512555,
        18.558292
      ]
    },
    {
      "id": 2519,
      "name": "Arapiraca",
      "names": {
        "zh": "阿拉皮拉卡"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.753674,
        "lng": -36.65535
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.696527,
        -9.807142,
        -36.616284,
        -9.710177
      ]
    },
    {
      "id": 2520,
      "name": "Okara",
      "names": {
        "zh": "奥卡拉"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.809544,
        "lng": 73.454922
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.421338,
        30.784963,
        73.490013,
        30.832254
      ]
    },
    {
      "id": 2521,
      "name": "Badalona",
      "names": {
        "zh": "巴达洛纳"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.443092,
        "lng": 2.231575
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.211943,
        41.425973,
        2.269407,
        41.464646
      ]
    },
    {
      "id": 2522,
      "name": "Arlington",
      "names": {
        "zh": "阿灵顿",
        "ja": "アーリントン",
        "th": "อาร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8903961,
        "lng": -77.0841585
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.1722757,
        38.8274696,
        -77.0309984,
        38.9343511
      ]
    },
    {
      "id": 2523,
      "name": "Saidpur",
      "names": {
        "zh": "赛伊德布尔"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 25.8038041,
        "lng": 88.9002878674934
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        88.8409619,
        25.7393042,
        89.0122365,
        25.8669262
      ]
    },
    {
      "id": 2524,
      "name": "Hialeah",
      "names": {
        "zh": "海厄利亚"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.85304,
        "lng": -80.283439
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.373736,
        25.805959,
        -80.252981,
        25.927919
      ]
    },
    {
      "id": 2525,
      "name": "York",
      "names": {
        "zh": "纽约",
        "ja": "ヨーク",
        "th": "นิวยอร์ก",
        "pt": "Iorque"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.962493,
        "lng": -76.7276989
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.762814,
        39.942756,
        -76.6994567,
        39.992584
      ]
    },
    {
      "id": 2526,
      "name": "Saga",
      "names": {
        "zh": "佐贺",
        "ja": "佐賀",
        "th": "ความกล้าหาญ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2639134,
        "lng": 130.3008378
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        130.1393961,
        33.080272,
        130.3790601,
        33.4815729
      ]
    },
    {
      "id": 2527,
      "name": "Burnaby",
      "names": {
        "zh": "伯纳比"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2433804,
        "lng": -122.972545
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -123.0240678,
        49.1801919,
        -122.8925997,
        49.2996734
      ]
    },
    {
      "id": 2528,
      "name": "Meycauayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7345008,
        "lng": 120.9571635
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8979262,
        14.7198897,
        121.0271224,
        14.7845503
      ]
    },
    {
      "id": 2529,
      "name": "Rancagua",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -34.1702433,
        "lng": -70.740718
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -70.9411671,
        -34.2136351,
        -70.676787,
        -34.028556
      ]
    },
    {
      "id": 2530,
      "name": "Freiburg im Breisgau",
      "names": {
        "it": "Friburgo im breisgau",
        "th": "ไฟรบูร์ก IM Breisgau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.9960901,
        "lng": 7.8494005
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6620055,
        47.9035777,
        7.9308444,
        48.0710579
      ]
    },
    {
      "id": 2531,
      "name": "Kasukabe",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9757957,
        "lng": 139.752019
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.707852,
        35.935377,
        139.833326,
        36.043168
      ]
    },
    {
      "id": 2532,
      "name": "Dzerzhinsk",
      "names": {
        "zh": "捷尔任斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2382157,
        "lng": 43.4617405
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.2506616,
        56.1884037,
        43.7723287,
        56.3760953
      ]
    },
    {
      "id": 2533,
      "name": "Marilia",
      "names": {
        "zh": "马里利亚",
        "it": "Marília"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2172002,
        "lng": -49.9500061
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.1502614,
        -22.4988608,
        -49.768,
        -21.8748193
      ]
    },
    {
      "id": 2534,
      "name": "Bratsk",
      "names": {
        "zh": "布拉茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1517085,
        "lng": 101.6334907
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        101.3530298,
        56.0183634,
        102.003237,
        56.342393
      ]
    },
    {
      "id": 2535,
      "name": "Barishal",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 22.7047521,
        "lng": 90.370729
      },
      "country": "Bangladesh",
      "importance": 1,
      "bbox": [
        90.210729,
        22.5447521,
        90.530729,
        22.8647521
      ]
    },
    {
      "id": 2536,
      "name": "Jiayuguan",
      "names": {
        "zh": "嘉峪关"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.81667,
        "lng": 98.3
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.2,
        39.71667,
        98.4,
        39.91667
      ]
    },
    {
      "id": 2537,
      "name": "Eloy Alfaro",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -2.1688548,
        "lng": -79.8340647
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -79.9940647,
        -2.3288548,
        -79.6740647,
        -2.0088548
      ]
    },
    {
      "id": 2538,
      "name": "Indaiatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0908356,
        "lng": -47.2180677
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.3057419,
        -23.2258028,
        -47.0805305,
        -22.9977877
      ]
    },
    {
      "id": 2539,
      "name": "Envigado",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1673611,
        "lng": -75.5799333
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5799833,
        6.1673111,
        -75.5798833,
        6.1674111
      ]
    },
    {
      "id": 2540,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6751114,
        "lng": -100.2151753
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.3751753,
        25.5151114,
        -100.0551753,
        25.8351114
      ]
    },
    {
      "id": 2541,
      "name": "Ageoshimo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.97145,
        "lng": 139.61382
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2542,
      "name": "Qarchak",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4421824,
        "lng": 51.5697005
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        51.5477413,
        35.389417,
        51.6211516,
        35.4634434
      ]
    },
    {
      "id": 2543,
      "name": "Toluca",
      "names": {
        "zh": "托卢卡",
        "ja": "トルカ",
        "th": "โตลูกา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.292545,
        "lng": -99.6569007
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -99.8169007,
        19.132545,
        -99.4969007,
        19.452545
      ]
    },
    {
      "id": 2544,
      "name": "Ormoc",
      "names": {
        "zh": "奥尔莫克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0052935,
        "lng": 124.6090753
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.47717,
        10.8781395,
        124.7897305,
        11.15655
      ]
    },
    {
      "id": 2545,
      "name": "Neuquen",
      "names": {
        "es": "Neuquén",
        "fr": "Neuquén",
        "zh": "内乌肯",
        "id": "Neuquén",
        "ja": "ネウケン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.9519679,
        "lng": -68.0591741
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -68.2019265,
        -38.9956359,
        -67.9986695,
        -38.8814838
      ]
    },
    {
      "id": 2546,
      "name": "Banfield",
      "names": {
        "zh": "班费尔德"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7435891,
        "lng": -58.3954201
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.4004201,
        -34.7485891,
        -58.3904201,
        -34.7385891
      ]
    },
    {
      "id": 2547,
      "name": "Americana",
      "names": {
        "zh": "美国",
        "ja": "アメリカーナ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7392463,
        "lng": -47.3306032
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.3880554,
        -22.7847861,
        -47.1931633,
        -22.6629879
      ]
    },
    {
      "id": 2548,
      "name": "Fresnillo",
      "names": {
        "zh": "弗雷斯尼洛"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.1811278,
        "lng": -102.8729073
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.0329073,
        23.0211278,
        -102.7129073,
        23.3411278
      ]
    },
    {
      "id": 2549,
      "name": "Singkawang",
      "names": {
        "zh": "山口洋"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.9069861,
        "lng": 108.9889657
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        108.8740424,
        0.7451542,
        109.1760488,
        1.0180185
      ]
    },
    {
      "id": 2550,
      "name": "Orsk",
      "names": {
        "zh": "奥尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.2305015,
        "lng": 58.4738015
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        58.3406826,
        51.0474024,
        58.7604137,
        51.3379804
      ]
    },
    {
      "id": 2551,
      "name": "Vizianagaram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.115091,
        "lng": 83.412074
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.362153,
        18.079232,
        83.441859,
        18.134817
      ]
    },
    {
      "id": 2552,
      "name": "North Hempstead",
      "names": {
        "es": "Hempstead del Norte",
        "fr": "Hempstead nord",
        "zh": "北半部分",
        "de": "Nordhanpstead",
        "id": "Hempstead Utara",
        "ja": "ノースヘムスステッド",
        "th": "ศอกเหนือ",
        "pt": "Hempstead do Norte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.80343,
        "lng": -73.67846
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.69846,
        40.78343,
        -73.65846,
        40.82343
      ]
    },
    {
      "id": 2553,
      "name": "Evansville",
      "names": {
        "zh": "埃文斯维尔",
        "th": "เอวันส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.970495,
        "lng": -87.5715641
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.637548,
        37.935588,
        -87.4498736,
        38.05689
      ]
    },
    {
      "id": 2554,
      "name": "Marka",
      "names": {
        "zh": "马尔卡"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 1.7148358,
        "lng": 44.7699326
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        44.75,
        1.7033624,
        44.7852575,
        1.7300124
      ]
    },
    {
      "id": 2555,
      "name": "Cotia",
      "names": {
        "zh": "科蒂亚"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.603889,
        "lng": -46.918889
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.085,
        -23.802,
        -46.8088063,
        -23.5626571
      ]
    },
    {
      "id": 2556,
      "name": "Pali",
      "names": {
        "zh": "巴利",
        "ja": "パーリ語",
        "th": "ภาษาบาลี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.7682376,
        "lng": 73.3275135
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.2931536,
        25.7455763,
        73.346096,
        25.8196271
      ]
    },
    {
      "id": 2557,
      "name": "Noginsk",
      "names": {
        "zh": "诺金斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.485275,
        "lng": 91.244591
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        91.234591,
        64.475275,
        91.254591,
        64.495275
      ]
    },
    {
      "id": 2558,
      "name": "Kropyvnytskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5105805,
        "lng": 32.2656283
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        32.1039835,
        48.4605266,
        32.3527523,
        48.5813629
      ]
    },
    {
      "id": 2559,
      "name": "Taito",
      "names": {
        "zh": "泰拓",
        "ja": "台東区"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.71745,
        "lng": 139.790859
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7622674,
        35.6948029,
        139.8098035,
        35.731623
      ]
    },
    {
      "id": 2560,
      "name": "Rondonopolis",
      "names": {
        "pt": "Rondonópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.4603533,
        "lng": -54.6397099
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.9946405,
        -17.0994984,
        -54.1900247,
        -16.07
      ]
    },
    {
      "id": 2561,
      "name": "Coban",
      "names": {
        "es": "Cobán",
        "zh": "科万"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4702001,
        "lng": -90.3735065
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.8206943,
        15.335791,
        -90.2817836,
        16.0417642
      ]
    },
    {
      "id": 2562,
      "name": "Guantanamo",
      "names": {
        "es": "Guantánamo",
        "zh": "关塔那摩",
        "ja": "グアンタナモ",
        "th": "กวาน",
        "pt": "Guantánamo"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.1431797,
        "lng": -75.2034783
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -75.2553498,
        20.0124968,
        -75.048422,
        20.2083581
      ]
    },
    {
      "id": 2563,
      "name": "Krefeld",
      "names": {
        "zh": "克雷费尔德",
        "ja": "クレーフェルト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3331205,
        "lng": 6.5623343
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.4779483,
        51.2857809,
        6.7062122,
        51.405491
      ]
    },
    {
      "id": 2564,
      "name": "Huixquilucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.35985,
        "lng": -99.35016
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.37016,
        19.33985,
        -99.33016,
        19.37985
      ]
    },
    {
      "id": 2565,
      "name": "Kolpino",
      "names": {
        "zh": "科尔皮诺"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.7388968,
        "lng": 30.6228076
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        30.6227576,
        59.7388468,
        30.6228576,
        59.7389468
      ]
    },
    {
      "id": 2566,
      "name": "Sidon",
      "names": {
        "es": "Sidón",
        "zh": "西顿",
        "it": "Sidone"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.5618345,
        "lng": 35.3780338
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        35.3595584,
        33.533803,
        35.3944945,
        33.5889928
      ]
    },
    {
      "id": 2567,
      "name": "Mage",
      "names": {
        "es": "Mago",
        "zh": "法师",
        "de": "Magier",
        "ja": "魔法使い",
        "th": "นักเวทย์",
        "pt": "Mago"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6517348,
        "lng": -43.0425022
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.0475022,
        -22.6567348,
        -43.0375022,
        -22.6467348
      ]
    },
    {
      "id": 2568,
      "name": "Apapa",
      "names": {
        "zh": "阿帕帕"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.445187000000001,
        "lng": 3.3683731679424094
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        3.3313413,
        6.4100747,
        3.3998804,
        6.4792528
      ]
    },
    {
      "id": 2569,
      "name": "Nadiad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.697712,
        "lng": 72.859013
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.83552,
        22.666676,
        72.892317,
        22.73019
      ]
    },
    {
      "id": 2570,
      "name": "Avondale",
      "names": {
        "zh": "埃文代尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4355977,
        "lng": -112.349602
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.3611754,
        33.26128,
        -112.2724402,
        33.503995
      ]
    },
    {
      "id": 2571,
      "name": "Brownsville",
      "names": {
        "zh": "布朗斯维尔",
        "ja": "ブラウンズヴィル",
        "th": "บราวน์สวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9024289,
        "lng": -97.4981698
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.6445529,
        25.84019,
        -97.2736409,
        26.2175148
      ]
    },
    {
      "id": 2572,
      "name": "Dasoguz",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 41.83358,
        "lng": 59.9590079
      },
      "country": "Turkmenistan",
      "importance": 1,
      "bbox": [
        59.7922409,
        41.7575987,
        60.0230653,
        41.9155277
      ]
    },
    {
      "id": 2573,
      "name": "Probolinggo",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.7441461,
        "lng": 113.2158401
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        113.1639174,
        -7.8257224,
        113.2424468,
        -7.7221888
      ]
    },
    {
      "id": 2574,
      "name": "Jacarei",
      "names": {
        "pt": "Jacareí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3050682,
        "lng": -45.9723075
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.152,
        -23.4190136,
        -45.7946529,
        -23.1725106
      ]
    },
    {
      "id": 2575,
      "name": "Quetzaltenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.83472,
        "lng": -91.51806
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.53806,
        14.81472,
        -91.49806,
        14.85472
      ]
    },
    {
      "id": 2576,
      "name": "Araraquara",
      "names": {
        "zh": "阿拉拉夸拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7937613,
        "lng": -48.1711392
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.1711892,
        -21.7938113,
        -48.1710892,
        -21.7937113
      ]
    },
    {
      "id": 2577,
      "name": "Angarsk",
      "names": {
        "zh": "安加尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.56247,
        "lng": 103.920462
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        103.871391,
        52.531555,
        103.955528,
        52.633499
      ]
    },
    {
      "id": 2578,
      "name": "Nagercoil",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 8.172915,
        "lng": 77.422549
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.395358,
        8.148947,
        77.453029,
        8.203741
      ]
    },
    {
      "id": 2579,
      "name": "Sousse",
      "names": {
        "zh": "苏斯",
        "th": "ซูสส์"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.8304101,
        "lng": 10.638315
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.633315,
        35.8254101,
        10.643315,
        35.8354101
      ]
    },
    {
      "id": 2580,
      "name": "Nyanza",
      "names": {
        "zh": "尼安扎"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -2.3524553,
        "lng": 29.7504906
      },
      "country": "Rwanda",
      "importance": 1,
      "bbox": [
        29.5904906,
        -2.5124553,
        29.9104906,
        -2.1924553
      ]
    },
    {
      "id": 2581,
      "name": "Takarazuka",
      "names": {
        "zh": "宝冢",
        "ja": "宝塚"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.811665,
        "lng": 135.3402872
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.3352872,
        34.806665,
        135.3452872,
        34.816665
      ]
    },
    {
      "id": 2582,
      "name": "Campeche",
      "names": {
        "zh": "坎佩切",
        "ja": "カンペチェ",
        "th": "กัมเปเช"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8381215,
        "lng": -90.5198253
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -90.6798253,
        19.6781215,
        -90.3598253,
        19.9981215
      ]
    },
    {
      "id": 2583,
      "name": "Atsugicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4423147,
        "lng": 139.3691463
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3491463,
        35.4223147,
        139.3891463,
        35.4623147
      ]
    },
    {
      "id": 2584,
      "name": "Tarrasa",
      "names": {
        "zh": "塔拉萨"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5629623,
        "lng": 2.0100492
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.9435767,
        41.516298,
        2.0688191,
        41.6487234
      ]
    },
    {
      "id": 2585,
      "name": "Bac Lieu",
      "names": {
        "es": "Bacista",
        "fr": "Bac au lieu",
        "zh": "bac代替",
        "ja": "バクレ",
        "pt": "Bacieu"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.2824882,
        "lng": 105.7260685
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.6793302,
        9.1973181,
        105.8270534,
        9.3393061
      ]
    },
    {
      "id": 2586,
      "name": "Itapevi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.54888,
        "lng": -46.933889
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0311779,
        -23.603994,
        -46.9070399,
        -23.4984504
      ]
    },
    {
      "id": 2587,
      "name": "Toamasina",
      "names": {
        "zh": "阿马西纳"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -18.1492,
        "lng": 49.40234
      },
      "country": "Madagascar",
      "importance": 1,
      "bbox": [
        49.38234,
        -18.1692,
        49.42234,
        -18.1292
      ]
    },
    {
      "id": 2588,
      "name": "Karnal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.6951152,
        "lng": 76.9698292
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.9648292,
        29.6901152,
        76.9748292,
        29.7001152
      ]
    },
    {
      "id": 2589,
      "name": "Padangsidempuan",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.372696,
        "lng": 99.2727172
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        99.2489681,
        1.35992,
        99.283236,
        1.402174
      ]
    },
    {
      "id": 2590,
      "name": "Varamin",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.3242,
        "lng": 51.6457
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.6257,
        35.3042,
        51.6657,
        35.3442
      ]
    },
    {
      "id": 2591,
      "name": "Mubi",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 10.267607,
        "lng": 13.264359
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        13.104359,
        10.107607,
        13.424359,
        10.427607
      ]
    },
    {
      "id": 2592,
      "name": "Korolev",
      "names": {
        "zh": "科罗廖夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9190049,
        "lng": 37.8150443
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.7878882,
        55.8660709,
        37.9421167,
        55.9600938
      ]
    },
    {
      "id": 2593,
      "name": "Hunchun",
      "names": {
        "zh": "珲春"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.8653288,
        "lng": 130.3559147
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.9571897,
        42.4172708,
        131.311365,
        43.5057654
      ]
    },
    {
      "id": 2594,
      "name": "Tampere",
      "names": {
        "zh": "坦佩雷",
        "ja": "タンペレ",
        "th": "ตัมเปเร"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4980214,
        "lng": 23.7603118
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        23.5422878,
        61.4273643,
        24.1184947,
        61.836614
      ]
    },
    {
      "id": 2595,
      "name": "Sakarya",
      "names": {
        "zh": "萨卡里亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7755188,
        "lng": 30.4020898
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        30.2331841,
        40.7348787,
        30.5745839,
        40.9534637
      ]
    },
    {
      "id": 2596,
      "name": "Blagoveshchensk",
      "names": {
        "zh": "布拉戈维申斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.2600417,
        "lng": 127.5337378
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        127.3798655,
        50.2069451,
        127.7425064,
        50.4943329
      ]
    },
    {
      "id": 2597,
      "name": "Velikiy Novgorod",
      "names": {
        "zh": "Velikiy诺夫哥罗德"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.5209862,
        "lng": 31.2757862
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        31.1966787,
        58.4703619,
        31.4257956,
        58.651
      ]
    },
    {
      "id": 2598,
      "name": "Longjin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1254374,
        "lng": 113.243283
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.2416976,
        23.1204915,
        113.2491747,
        23.1283805
      ]
    },
    {
      "id": 2599,
      "name": "Ashdod",
      "names": {
        "zh": "阿什多德",
        "de": "Asdod",
        "id": "Asdod",
        "th": "ชาวเมืองอัชโดด"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.7977314,
        "lng": 34.6529922
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.6088045,
        31.7531554,
        34.7102646,
        31.8728243
      ]
    },
    {
      "id": 2600,
      "name": "Czestochowa",
      "names": {
        "zh": "琴斯托霍瓦"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8084279,
        "lng": 19.1209688
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.1159688,
        50.8034279,
        19.1259688,
        50.8134279
      ]
    },
    {
      "id": 2601,
      "name": "Puri",
      "names": {
        "zh": "普里",
        "ja": "プリ",
        "th": "ในปูรี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.8076083,
        "lng": 85.8252538
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        85.7790463,
        19.7815906,
        85.8832582,
        19.8428846
      ]
    },
    {
      "id": 2602,
      "name": "Carlos Manuel de Cespedes",
      "names": {
        "it": "Carlos Manuel de Cansedes",
        "id": "Carlos Manuel de engedes"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.4844848,
        "lng": -77.6169838
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.6209405,
        21.4838992,
        -77.6120031,
        21.4857411
      ]
    },
    {
      "id": 2603,
      "name": "Chapeco",
      "names": {
        "fr": "Chapecó",
        "pt": "Chapecó"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.0922364,
        "lng": -52.6166878
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.7236193,
        -27.2495645,
        -52.4807523,
        -26.9883155
      ]
    },
    {
      "id": 2604,
      "name": "Sandton",
      "names": {
        "zh": "桑顿"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.1045525,
        "lng": 28.0545147
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.8945147,
        -26.2645525,
        28.2145147,
        -25.9445525
      ]
    },
    {
      "id": 2605,
      "name": "Tanjore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.7860267,
        "lng": 79.1381497
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.9781497,
        10.6260267,
        79.2981497,
        10.9460267
      ]
    },
    {
      "id": 2606,
      "name": "Staryy Oskol",
      "names": {
        "es": "Scaryy Oskol",
        "ja": "聖職者Oskol",
        "th": "สเตรีย์โอสถ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.297036,
        "lng": 37.841698
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.790749,
        51.267582,
        37.899834,
        51.337223
      ]
    },
    {
      "id": 2607,
      "name": "Secunderabad",
      "names": {
        "zh": "塞康德拉巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.4359536,
        "lng": 78.5000501
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.5000001,
        17.4359036,
        78.5001001,
        17.4360036
      ]
    },
    {
      "id": 2608,
      "name": "Ji'an Shi",
      "names": {
        "zh": "济安施",
        "ja": "江南市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1241078,
        "lng": 126.1831493
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.571394,
        40.8618347,
        126.555458,
        41.5954321
      ]
    },
    {
      "id": 2609,
      "name": "Bursa",
      "names": {
        "zh": "布尔萨",
        "ja": "ブルサ",
        "th": "บูร์ซา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.182737,
        "lng": 29.0675481
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        28.9075481,
        40.022737,
        29.2275481,
        40.342737
      ]
    },
    {
      "id": 2610,
      "name": "Jose C. Paz",
      "names": {
        "zh": "何塞C.帕兹"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.511906,
        "lng": -58.7776777
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.8238256,
        -34.5571471,
        -58.7210306,
        -34.451898
      ]
    },
    {
      "id": 2611,
      "name": "Rufisque",
      "names": {
        "zh": "吕菲斯克"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.7159649,
        "lng": -17.2699985
      },
      "country": "Senegal",
      "importance": 3,
      "bbox": [
        -17.2749985,
        14.7109649,
        -17.2649985,
        14.7209649
      ]
    },
    {
      "id": 2612,
      "name": "Presidente Prudente",
      "names": {
        "fr": "PREMIÈRE PRUDENTE",
        "zh": "Presencee Prudente",
        "ja": "プレジデンテプルデンテ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.1225167,
        "lng": -51.3882528
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.4839479,
        -22.205,
        -51.2235927,
        -21.7199917
      ]
    },
    {
      "id": 2613,
      "name": "Barra do Dande",
      "names": {
        "zh": "巴拉做丹德",
        "ja": "バラダダンデ"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -8.47175,
        "lng": 13.37011
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.35011,
        -8.49175,
        13.39011,
        -8.45175
      ]
    },
    {
      "id": 2614,
      "name": "Hobart",
      "names": {
        "zh": "霍巴特",
        "ja": "ホバート",
        "th": "โฮบาร์ต"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -42.8825088,
        "lng": 147.3281233
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        147.091688,
        -43.103615,
        147.645588,
        -42.655376
      ]
    },
    {
      "id": 2615,
      "name": "Swindon",
      "names": {
        "zh": "史云顿",
        "ja": "スウィンドン",
        "th": "สวินดอน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5615327,
        "lng": -1.7854322
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8254322,
        51.5215327,
        -1.7454322,
        51.6015327
      ]
    },
    {
      "id": 2616,
      "name": "Ota",
      "names": {
        "zh": "太田",
        "ja": "大田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.294102,
        "lng": 139.3787733
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3737733,
        36.289102,
        139.3837733,
        36.299102
      ]
    },
    {
      "id": 2617,
      "name": "Ternopil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5557908,
        "lng": 25.5923753
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        25.458604,
        49.505361,
        25.6645145,
        49.5968918
      ]
    },
    {
      "id": 2618,
      "name": "Formosa",
      "names": {
        "zh": "福尔摩沙",
        "ja": "台湾",
        "th": "ฟอร์โมซา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.1852983,
        "lng": -58.1744976
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -58.5055654,
        -26.38507,
        -57.8359687,
        -25.7576383
      ]
    },
    {
      "id": 2619,
      "name": "Sambhal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.583857,
        "lng": 78.566555
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.547238,
        28.568966,
        78.579683,
        28.601142
      ]
    },
    {
      "id": 2620,
      "name": "Gueckedou",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 8.56744,
        "lng": -10.1336
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -10.1536,
        8.54744,
        -10.1136,
        8.58744
      ]
    },
    {
      "id": 2621,
      "name": "Rennes",
      "names": {
        "zh": "雷恩",
        "ja": "レンヌ",
        "th": "แรนส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.11136175,
        "lng": -1.680095690915635
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        -1.6802054,
        48.1109838,
        -1.6798291,
        48.111728
      ]
    },
    {
      "id": 2622,
      "name": "Polokwane",
      "names": {
        "th": "โพโลเควน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -23.9058333,
        "lng": 29.4613889
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        29.3013889,
        -24.0658333,
        29.6213889,
        -23.7458333
      ]
    },
    {
      "id": 2623,
      "name": "Sabadell",
      "names": {
        "zh": "萨瓦德尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.544526,
        "lng": 2.104533
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.075828,
        41.517287,
        2.127902,
        41.577337
      ]
    },
    {
      "id": 2624,
      "name": "Neyshabur",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.21329,
        "lng": 58.79576
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.77576,
        36.19329,
        58.81576,
        36.23329
      ]
    },
    {
      "id": 2625,
      "name": "Radom",
      "names": {
        "zh": "拉多姆"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4022557,
        "lng": 21.1541546
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.0531876,
        51.3486201,
        21.2590745,
        51.4858553
      ]
    },
    {
      "id": 2626,
      "name": "Lutsk",
      "names": {
        "de": "Luzk",
        "th": "ลัตสก์"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.7450733,
        "lng": 25.320078
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        25.2619629,
        50.688998,
        25.4058021,
        50.7888828
      ]
    },
    {
      "id": 2627,
      "name": "Hachinohe",
      "names": {
        "zh": "八户",
        "ja": "八戸"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.5122388,
        "lng": 141.4882959
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.354479,
        40.347361,
        141.8579636,
        40.7218137
      ]
    },
    {
      "id": 2628,
      "name": "Gulfport",
      "names": {
        "zh": "格尔夫波特",
        "th": "กัลฟ์พอร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3688997,
        "lng": -89.0951125
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.1001125,
        30.3638997,
        -89.0901125,
        30.3738997
      ]
    },
    {
      "id": 2629,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.3394796,
        "lng": -68.7417033
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -68.9017033,
        10.1794796,
        -68.5817033,
        10.4994796
      ]
    },
    {
      "id": 2630,
      "name": "Saveh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.0213,
        "lng": 50.3566
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.3366,
        35.0013,
        50.3766,
        35.0413
      ]
    },
    {
      "id": 2631,
      "name": "Nishitokyo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.73355,
        "lng": 139.54761
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.52761,
        35.71355,
        139.56761,
        35.75355
      ]
    },
    {
      "id": 2632,
      "name": "Santo Tomas",
      "names": {
        "ja": "サントトマス",
        "th": "ซานโตโทมัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1078443,
        "lng": 121.1453304
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1250236,
        13.9796305,
        121.2319705,
        14.1473362
      ]
    },
    {
      "id": 2633,
      "name": "La Vega",
      "names": {
        "zh": "拉维加",
        "ja": "ラベガ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.2237411,
        "lng": -70.5284071
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -70.6884071,
        19.0637411,
        -70.3684071,
        19.3837411
      ]
    },
    {
      "id": 2634,
      "name": "Appleton",
      "names": {
        "zh": "阿普尔顿",
        "ja": "アップルトン",
        "th": "แอปเปิลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.2613967,
        "lng": -88.4069744
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.4515792,
        44.2086575,
        -88.342596,
        44.3539177
      ]
    },
    {
      "id": 2635,
      "name": "Khomeyni Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.6938201,
        "lng": 51.5254892
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.470687,
        32.6413252,
        51.5921542,
        32.7328731
      ]
    },
    {
      "id": 2636,
      "name": "Damietta",
      "names": {
        "zh": "达米埃塔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.4167427,
        "lng": 31.8213657
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.6613657,
        31.2567427,
        31.9813657,
        31.5767427
      ]
    },
    {
      "id": 2637,
      "name": "Juazeiro",
      "names": {
        "zh": "茹阿泽鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4336959,
        "lng": -40.5068201
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.8137561,
        -9.9731124,
        -39.862,
        -9.0546047
      ]
    },
    {
      "id": 2638,
      "name": "Kremenchuk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.078092,
        "lng": 33.416839
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.376996,
        49.009861,
        33.491208,
        49.149719
      ]
    },
    {
      "id": 2639,
      "name": "Itabuna",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.793173,
        "lng": -39.2750341
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.418926,
        -15.0332295,
        -39.1901422,
        -14.6905672
      ]
    },
    {
      "id": 2640,
      "name": "Al Khubar",
      "names": {
        "de": "Alkhubar",
        "ja": "アルクバル",
        "th": "อัล khubar"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 26.290894,
        "lng": 50.213846
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        50.205983,
        26.279357,
        50.22247,
        26.302628
      ]
    },
    {
      "id": 2641,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8944704,
        "lng": -96.9352891
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0952891,
        18.7344704,
        -96.7752891,
        19.0544704
      ]
    },
    {
      "id": 2642,
      "name": "Shiliguri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.7164127,
        "lng": 88.4309916
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3981,
        26.6597686,
        88.4687573,
        26.768434
      ]
    },
    {
      "id": 2643,
      "name": "Zakhu",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 37.1333226,
        "lng": 42.6834458
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        42.6833958,
        37.1332726,
        42.6834958,
        37.1333726
      ]
    },
    {
      "id": 2644,
      "name": "Tulua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.0856667,
        "lng": -76.1972779
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3139105,
        3.8888852,
        -75.8201554,
        4.1855609
      ]
    },
    {
      "id": 2645,
      "name": "Mainz",
      "names": {
        "fr": "Mayence",
        "zh": "美因茨",
        "ja": "マインツ",
        "th": "ไมนซ์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0012314,
        "lng": 8.2762513
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        8.1430883,
        49.8955342,
        8.3431435,
        50.0353045
      ]
    },
    {
      "id": 2646,
      "name": "Hortolandia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8602953,
        "lng": -47.219312
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.219362,
        -22.8603453,
        -47.219262,
        -22.8602453
      ]
    },
    {
      "id": 2647,
      "name": "Bitung",
      "names": {
        "zh": "比通"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.44344,
        "lng": 125.1940836
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        125.0324936,
        1.3871218,
        125.2961765,
        1.599962
      ]
    },
    {
      "id": 2648,
      "name": "Naihati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.889546,
        "lng": 88.422048
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3952,
        22.87396,
        88.4352,
        22.91396
      ]
    },
    {
      "id": 2649,
      "name": "Tilburg",
      "names": {
        "zh": "蒂尔堡"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.564458,
        "lng": 5.074477
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.947846,
        51.529502,
        5.129711,
        51.617855
      ]
    },
    {
      "id": 2650,
      "name": "Manta",
      "names": {
        "zh": "曼塔",
        "ja": "マンタ",
        "th": "ราหู"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.03267095,
        "lng": -80.82303642667135
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -80.9127732,
        -1.1356909,
        -80.658406,
        -0.9295941
      ]
    },
    {
      "id": 2651,
      "name": "Osan",
      "names": {
        "zh": "乌山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.145558,
        "lng": 127.0667523
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.0617523,
        37.140558,
        127.0717523,
        37.150558
      ]
    },
    {
      "id": 2652,
      "name": "Butembo",
      "names": {
        "zh": "布滕博"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.1249694,
        "lng": 29.2919545
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        29.1319545,
        -0.0350306,
        29.4519545,
        0.2849694
      ]
    },
    {
      "id": 2653,
      "name": "San Miguel",
      "names": {
        "zh": "圣米格尔",
        "ja": "サンミゲル",
        "th": "ซานมิเกล"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.4803899,
        "lng": -88.17722
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.33722,
        13.3203899,
        -88.01722,
        13.6403899
      ]
    },
    {
      "id": 2654,
      "name": "Oviedo",
      "names": {
        "zh": "奥维多",
        "ja": "オビエド",
        "th": "โอเบียโด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3618625,
        "lng": -5.8483581
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -6.0169359,
        43.2791441,
        -5.742394,
        43.4273246
      ]
    },
    {
      "id": 2655,
      "name": "Netanya",
      "names": {
        "th": "นาตาเนีย"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.3286181,
        "lng": 34.8566246
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.8247416,
        32.2549044,
        34.89601,
        32.3534834
      ]
    },
    {
      "id": 2656,
      "name": "Concepcion",
      "names": {
        "es": "Concepción",
        "zh": "康塞普西翁",
        "ja": "コンセプシオン",
        "th": "กอนเซปซิยอน"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -36.8270698,
        "lng": -73.0502064
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -73.0915891,
        -36.9268099,
        -72.8446184,
        -36.7644238
      ]
    },
    {
      "id": 2657,
      "name": "Itaborai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7496231,
        "lng": -42.8557428
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.8687612,
        -22.7509416,
        -42.8520184,
        -22.7392704
      ]
    },
    {
      "id": 2658,
      "name": "Zamora",
      "names": {
        "zh": "萨莫拉",
        "ja": "サモラ",
        "th": "ซาโมร่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9828948,
        "lng": -102.28363
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -102.44363,
        19.8228948,
        -102.12363,
        20.1428948
      ]
    },
    {
      "id": 2659,
      "name": "Bremerton",
      "names": {
        "zh": "布雷默顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.5619495,
        "lng": -122.6244828
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.6245328,
        47.5618995,
        -122.6244328,
        47.5619995
      ]
    },
    {
      "id": 2660,
      "name": "Alor Setar",
      "names": {
        "zh": "阿洛斯·森林",
        "de": "Alor-Setar",
        "id": "Seterar"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 6.11343375,
        "lng": 100.36974180185359
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        100.3686234,
        6.1119411,
        100.3705783,
        6.1149356
      ]
    },
    {
      "id": 2661,
      "name": "Hickory",
      "names": {
        "zh": "山核桃",
        "ja": "ヒッコリー",
        "th": "ชนิดหนึ่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7333312,
        "lng": -81.3442915
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.4035581,
        35.6612385,
        -81.2353699,
        35.8073706
      ]
    },
    {
      "id": 2662,
      "name": "Luton",
      "names": {
        "zh": "卢顿",
        "ja": "ルートン",
        "th": "ลูตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8784385,
        "lng": -0.4152837
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.4552837,
        51.8384385,
        -0.3752837,
        51.9184385
      ]
    },
    {
      "id": 2663,
      "name": "Tacoma",
      "names": {
        "zh": "塔科马",
        "ja": "タコマ",
        "th": "ทาโคมา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.2455013,
        "lng": -122.438329
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.562265,
        47.1668108,
        -122.3481499,
        47.31898
      ]
    },
    {
      "id": 2664,
      "name": "Denov",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 38.2753782,
        "lng": 67.8951071
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        67.8691416,
        38.2484085,
        67.9550267,
        38.3007667
      ]
    },
    {
      "id": 2665,
      "name": "Matrah",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 23.62,
        "lng": 58.57
      },
      "country": "Oman",
      "importance": 3,
      "bbox": [
        58.55,
        23.6,
        58.59,
        23.64
      ]
    },
    {
      "id": 2666,
      "name": "Qostanay",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 53.21435,
        "lng": 63.62463
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        63.60463,
        53.19435,
        63.64463,
        53.23435
      ]
    },
    {
      "id": 2667,
      "name": "Banjarbaru",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.4397517,
        "lng": 114.8309351
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        114.6751982,
        -3.6063725,
        114.9088776,
        -3.3725501
      ]
    },
    {
      "id": 2668,
      "name": "Sa Dec",
      "names": {
        "es": "Sa dic",
        "de": "SA",
        "it": "Sambre",
        "id": "Sa Desember",
        "pt": "Sa de dezembro"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.2943716,
        "lng": 105.7588147
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        105.7038823,
        10.2613288,
        105.7834189,
        10.3831644
      ]
    },
    {
      "id": 2669,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9028844,
        "lng": 125.0898033
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8026895,
        7.7916263,
        125.2733154,
        8.0358586
      ]
    },
    {
      "id": 2670,
      "name": "Gujiao",
      "names": {
        "zh": "古交"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9066291,
        "lng": 112.1685309
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.0085309,
        37.7466291,
        112.3285309,
        38.0666291
      ]
    },
    {
      "id": 2671,
      "name": "Lubeck",
      "names": {
        "es": "Lübeck",
        "fr": "Lübeck",
        "zh": "吕贝克",
        "de": "Lübeck",
        "it": "Lubecca",
        "id": "Lübeck",
        "ja": "リューベック",
        "th": "ลือเบกค์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.866444,
        "lng": 10.684738
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5540402,
        53.7680103,
        10.9686302,
        53.9938868
      ]
    },
    {
      "id": 2672,
      "name": "Cartagena",
      "names": {
        "zh": "卡塔赫纳",
        "ja": "カルタヘナ",
        "th": "คาร์"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.6050294,
        "lng": -0.9749724
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.9799724,
        37.6000294,
        -0.9699724,
        37.6100294
      ]
    },
    {
      "id": 2673,
      "name": "Petropavl",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 54.8668104,
        "lng": 69.1334984
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        68.9966249,
        54.7956311,
        69.303329,
        54.9873341
      ]
    },
    {
      "id": 2674,
      "name": "Sao Leopoldo",
      "names": {
        "zh": "圣莱奥波戈",
        "ja": "サンレオポルド",
        "pt": "São Leopoldo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.7544405,
        "lng": -51.1516497
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.2100287,
        -29.8122567,
        -51.068,
        -29.6719664
      ]
    },
    {
      "id": 2675,
      "name": "Marg`ilon",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.4694865,
        "lng": 71.719491
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        71.6875112,
        40.4368942,
        71.7895317,
        40.4953039
      ]
    },
    {
      "id": 2676,
      "name": "Trece Martires City",
      "names": {
        "es": "Trece Martirires City",
        "de": "Trece Martes City",
        "ja": "Trece Martirs City",
        "pt": "Cidade de Trécte Martires"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2822581,
        "lng": 120.8658924
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.8658924,
        14.2822192,
        120.8660069,
        14.2822581
      ]
    },
    {
      "id": 2677,
      "name": "Gangneung",
      "names": {
        "zh": "江陵"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.7595,
        "lng": 128.891634
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        128.852777,
        37.713922,
        128.96338,
        37.827476
      ]
    },
    {
      "id": 2678,
      "name": "San Bernardino",
      "names": {
        "zh": "圣伯纳迪诺",
        "ja": "サンバーナリディノ",
        "th": "ซานเบอร์นาดิโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1081621,
        "lng": -117.28946
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.442823,
        34.0502734,
        -117.164972,
        34.2424938
      ]
    },
    {
      "id": 2679,
      "name": "Cua",
      "names": {
        "zh": "河口"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 13.3681557,
        "lng": -85.672828
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -85.8255976,
        13.246431,
        -85.43729,
        13.5854536
      ]
    },
    {
      "id": 2680,
      "name": "As Samawah",
      "names": {
        "es": "Como Samawah",
        "fr": "Comme Samawah",
        "zh": "作为撒瓦",
        "de": "Als Samawah",
        "it": "Come Samawah",
        "id": "Sebagai samawah",
        "ja": "サマワとして",
        "th": "เช่นเดียวกับซาวาห์",
        "pt": "Como samawah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.3078736,
        "lng": 45.2826616
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        45.1226616,
        31.1478736,
        45.4426616,
        31.4678736
      ]
    },
    {
      "id": 2681,
      "name": "Vantaa",
      "names": {
        "zh": "万塔机场",
        "ja": "ヴァンター"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.2933337,
        "lng": 25.0407809
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.7462404,
        60.2372174,
        25.1930674,
        60.4012466
      ]
    },
    {
      "id": 2682,
      "name": "College Station",
      "names": {
        "es": "Estación universitaria",
        "fr": "Station de collège",
        "zh": "大学站",
        "de": "College",
        "it": "Stazione del college",
        "id": "Stasiun perguruan tinggi",
        "ja": "カレッジステーション",
        "th": "สถานีวิทยาลัย",
        "pt": "Estação de faculdade"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6183939,
        "lng": -96.3455991
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.3876547,
        30.5164801,
        -96.1965627,
        30.6584084
      ]
    },
    {
      "id": 2683,
      "name": "Golmud",
      "names": {
        "zh": "格尔木"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4011402,
        "lng": 94.9026289
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        94.7426289,
        36.2411402,
        95.0626289,
        36.5611402
      ]
    },
    {
      "id": 2684,
      "name": "Kalamazoo",
      "names": {
        "zh": "卡拉马祖",
        "ja": "カラマズー",
        "th": "คาลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.274924,
        "lng": -85.587168
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.663515,
        42.215564,
        -85.530782,
        42.332752
      ]
    },
    {
      "id": 2685,
      "name": "Geneva",
      "names": {
        "es": "Ginebra",
        "fr": "Genève",
        "zh": "日内瓦",
        "de": "Genf",
        "it": "Ginevra",
        "id": "Jenewa",
        "ja": "ジュネーブ",
        "th": "เจนีวา",
        "pt": "Genebra"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.2017559,
        "lng": 6.1466014
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        6.1102411,
        46.1777724,
        6.1758527,
        46.231885
      ]
    },
    {
      "id": 2686,
      "name": "Regina",
      "names": {
        "zh": "里贾纳",
        "ja": "レジーナ",
        "th": "ริไจนา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 50.4479733,
        "lng": -104.6158764
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -104.7781055,
        50.3964112,
        -104.492066,
        50.5207302
      ]
    },
    {
      "id": 2687,
      "name": "Thousand Oaks",
      "names": {
        "es": "Mil robles",
        "fr": "Mille chênes",
        "zh": "千橡木",
        "de": "Tausend Eichen",
        "it": "Mille querce",
        "id": "Ribu pohon ek",
        "ja": "千貝",
        "th": "พันโอ๊ก",
        "pt": "Milhares de carvalhos"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1705609,
        "lng": -118.8375937
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.0016818,
        34.1246869,
        -118.7765123,
        34.2508707
      ]
    },
    {
      "id": 2688,
      "name": "La Victoria",
      "names": {
        "th": "ลาวิคตอเรีย"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2276838,
        "lng": -67.3246357
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.4846357,
        10.0676838,
        -67.1646357,
        10.3876838
      ]
    },
    {
      "id": 2689,
      "name": "Shimla",
      "names": {
        "zh": "西姆拉",
        "ja": "シムラー",
        "th": "ชิมลา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.1041526,
        "lng": 77.1709729
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        77.1172964,
        31.0641835,
        77.229917,
        31.1603379
      ]
    },
    {
      "id": 2690,
      "name": "Babruysk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.1449683,
        "lng": 29.2281209
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.1373233,
        53.0703434,
        29.3218259,
        53.2183508
      ]
    },
    {
      "id": 2691,
      "name": "Northampton",
      "names": {
        "zh": "北安普顿",
        "ja": "ノースアンプトン",
        "th": "นอร์ท"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.23433665,
        "lng": -0.9028072768185829
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.971041,
        52.1860899,
        -0.7913699,
        52.2826966
      ]
    },
    {
      "id": 2692,
      "name": "Mohammedia",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.6956681,
        "lng": -7.3876759
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.3926759,
        33.6906681,
        -7.3826759,
        33.7006681
      ]
    },
    {
      "id": 2693,
      "name": "Roanoke",
      "names": {
        "zh": "罗阿诺克",
        "ja": "ロアノーク",
        "th": "โน๊ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.273187,
        "lng": -79.9414252
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.9464252,
        37.268187,
        -79.9364252,
        37.278187
      ]
    },
    {
      "id": 2694,
      "name": "Rui'an",
      "names": {
        "zh": "瑞安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7809047,
        "lng": 120.6512652
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.1760446,
        27.5443497,
        121.2923239,
        27.9855879
      ]
    },
    {
      "id": 2695,
      "name": "Erfurt",
      "names": {
        "zh": "爱尔福特",
        "ja": "エルフルト",
        "th": "แอร์ฟูร์ท"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9777974,
        "lng": 11.0287364
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        10.8546679,
        50.8904895,
        11.1751358,
        51.079877
      ]
    },
    {
      "id": 2696,
      "name": "Sete Lagoas",
      "names": {
        "es": "Sede lagoas",
        "ja": "セットラゴアス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.466111,
        "lng": -44.246944
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.4598898,
        -19.6,
        -44.127747,
        -19.292
      ]
    },
    {
      "id": 2697,
      "name": "Fontana",
      "names": {
        "zh": "丰塔纳",
        "ja": "フォンタナ",
        "th": "ตานา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0922947,
        "lng": -117.43433
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.5243184,
        34.0334282,
        -117.4010489,
        34.1858204
      ]
    },
    {
      "id": 2698,
      "name": "Sikasso",
      "names": {
        "zh": "锡卡索"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 11.31755,
        "lng": -5.66654
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -5.68654,
        11.29755,
        -5.64654,
        11.33755
      ]
    },
    {
      "id": 2699,
      "name": "Warnes",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -17.5100818,
        "lng": -63.1647184
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -63.2680579,
        -17.6848074,
        -62.9213656,
        -17.0356063
      ]
    },
    {
      "id": 2700,
      "name": "Mostoles",
      "names": {
        "zh": "莫斯托莱斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.32857505,
        "lng": -3.8637878808635415
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.8653039,
        40.3274801,
        -3.862562,
        40.3296501
      ]
    },
    {
      "id": 2701,
      "name": "El Tigre",
      "names": {
        "fr": "El tagre",
        "zh": "埃尔蒂格雷",
        "ja": "エルティグレ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.8862119,
        "lng": -64.2488754
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -64.3613219,
        8.8251975,
        -64.1770113,
        8.9469545
      ]
    },
    {
      "id": 2702,
      "name": "Divinopolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.1597921,
        "lng": -44.8739341
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.122,
        -20.309,
        -44.7504926,
        -19.91818
      ]
    },
    {
      "id": 2703,
      "name": "Colombo",
      "names": {
        "zh": "科伦坡",
        "ja": "コロンボ",
        "th": "โคลัมโบ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.2925439,
        "lng": -49.2243472
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.2683237,
        -25.3936056,
        -49.0921625,
        -25.2257098
      ]
    },
    {
      "id": 2704,
      "name": "Moreno Valley",
      "names": {
        "es": "Valle de Moreno",
        "fr": "Vallée de Moreno",
        "zh": "莫雷诺山谷",
        "de": "Morteno-Tal",
        "id": "Lembah Moreno",
        "ja": "モレノ渓谷"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.937517,
        "lng": -117.2305944
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.296524,
        33.8586803,
        -117.088203,
        33.9878573
      ]
    },
    {
      "id": 2705,
      "name": "Jerez de la Frontera",
      "names": {
        "zh": "赫雷斯德拉弗隆特拉"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6816936,
        "lng": -6.1377402
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.3061225,
        36.5162096,
        -5.4557904,
        36.8637895
      ]
    },
    {
      "id": 2706,
      "name": "Sidi Bel Abbes",
      "names": {
        "zh": "斯蒂贝布斯"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.1991755,
        "lng": -0.6383134
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -0.6433134,
        35.1941755,
        -0.6333134,
        35.2041755
      ]
    },
    {
      "id": 2707,
      "name": "Limbe",
      "names": {
        "zh": "林贝"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.010289,
        "lng": 9.207058
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        9.047058,
        3.850289,
        9.367058,
        4.170289
      ]
    },
    {
      "id": 2708,
      "name": "Kure",
      "names": {
        "zh": "吴"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2484488,
        "lng": 132.5652498
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.3957386,
        34.0076247,
        132.8806801,
        34.333242
      ]
    },
    {
      "id": 2709,
      "name": "Padova",
      "names": {
        "zh": "帕多瓦",
        "ja": "パドバ",
        "th": "ปาโดวา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4077172,
        "lng": 11.8734455
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.8054118,
        45.3395667,
        11.976435,
        45.4573727
      ]
    },
    {
      "id": 2710,
      "name": "Arroyo Naranjo",
      "names": {
        "ja": "南城アロヨ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.0193426,
        "lng": -82.33557567038837
      },
      "country": "Cuba",
      "importance": 3,
      "bbox": [
        -82.3800634,
        22.9529235,
        -82.2462042,
        23.0857997
      ]
    },
    {
      "id": 2711,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.3553005,
        "lng": -79.8974085
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -80.0574085,
        9.1953005,
        -79.7374085,
        9.5153005
      ]
    },
    {
      "id": 2712,
      "name": "Ploiesti",
      "names": {
        "zh": "普洛耶什蒂"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.939893,
        "lng": 26.025409
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.923913,
        44.898299,
        26.102618,
        44.968257
      ]
    },
    {
      "id": 2713,
      "name": "Oberhausen",
      "names": {
        "zh": "奥伯豪森",
        "ja": "オーバーハウゼン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4696137,
        "lng": 6.8514435
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.7773297,
        51.4486973,
        6.9304578,
        51.5799944
      ]
    },
    {
      "id": 2714,
      "name": "Criciuma",
      "names": {
        "zh": "克里西乌马"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.6789941,
        "lng": -49.3695627
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.4774695,
        -28.8552272,
        -49.2436423,
        -28.6333615
      ]
    },
    {
      "id": 2715,
      "name": "Pskov",
      "names": {
        "zh": "普斯科夫",
        "de": "Pskow",
        "ja": "プスコフ",
        "th": "ปัสคอฟ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.8173923,
        "lng": 28.3343465
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        28.2545258,
        57.7442228,
        28.4635696,
        57.8789375
      ]
    },
    {
      "id": 2716,
      "name": "Bila Tserkva",
      "names": {
        "ja": "ビラ・ツェルクバ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7969703,
        "lng": 30.1158069
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.020939,
        49.7568671,
        30.2224658,
        49.8543319
      ]
    },
    {
      "id": 2717,
      "name": "Isesaki",
      "names": {
        "zh": "伊势崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3111734,
        "lng": 139.1968083
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.136173,
        36.2425871,
        139.2829949,
        36.408374
      ]
    },
    {
      "id": 2718,
      "name": "Pamplona",
      "names": {
        "fr": "Pampelune",
        "zh": "潘普洛纳",
        "ja": "パンプローナ",
        "th": "ปัมโปลนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.81568595,
        "lng": -1.6522353378580308
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -1.6953841,
        42.7887432,
        -1.6044942,
        42.8424401
      ]
    },
    {
      "id": 2719,
      "name": "Naga City",
      "names": {
        "es": "Ciudad de Naga",
        "fr": "Ville de Naga",
        "zh": "纳卡市",
        "de": "Naga-Stadt",
        "it": "Città naga",
        "id": "Kota naga",
        "ja": "長永市",
        "th": "เมืองนาค",
        "pt": "Cidade de naga"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.61917,
        "lng": 123.18139
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.16139,
        13.59917,
        123.20139,
        13.63917
      ]
    },
    {
      "id": 2720,
      "name": "Burgas",
      "names": {
        "zh": "布尔加斯",
        "th": "บูร์กาส"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.4936616,
        "lng": 27.4721276
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        27.3121276,
        42.3336616,
        27.6321276,
        42.6536616
      ]
    },
    {
      "id": 2721,
      "name": "Babylon",
      "names": {
        "es": "Babilonia",
        "fr": "Babylone",
        "zh": "巴比伦",
        "it": "Babilonia",
        "id": "Babel",
        "ja": "バビロン",
        "th": "บาบิโลน",
        "pt": "Babilônia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6956552,
        "lng": -73.3256753
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.342979,
        40.673249,
        -73.312133,
        40.712021
      ]
    },
    {
      "id": 2722,
      "name": "Chungju",
      "names": {
        "zh": "忠州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.97666,
        "lng": 127.9287
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.9087,
        36.95666,
        127.9487,
        36.99666
      ]
    },
    {
      "id": 2723,
      "name": "Maracanau",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.8776539,
        "lng": -38.6254616
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.6255116,
        -3.8777039,
        -38.6254116,
        -3.8776039
      ]
    },
    {
      "id": 2724,
      "name": "Tunja",
      "names": {
        "zh": "通哈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5324313,
        "lng": -73.3616014
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -73.5216014,
        5.3724313,
        -73.2016014,
        5.6924313
      ]
    },
    {
      "id": 2725,
      "name": "Pagadian",
      "names": {
        "zh": "帕加迪安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.8257,
        "lng": 123.437
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.417,
        7.8057,
        123.457,
        7.8457
      ]
    },
    {
      "id": 2726,
      "name": "Legazpi City",
      "names": {
        "es": "Ciudad de Legazpi",
        "fr": "La ville de Legazpi",
        "zh": "Legazpi市",
        "de": "Legazpi-Stadt",
        "id": "Kota Legazpi",
        "ja": "レガツィシティ",
        "th": "เมือง Legazpi",
        "pt": "Cidade de Legazpi"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.13722,
        "lng": 123.73444
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.71444,
        13.11722,
        123.75444,
        13.15722
      ]
    },
    {
      "id": 2727,
      "name": "Barrancabermeja",
      "names": {
        "zh": "巴兰卡韦梅哈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0650318,
        "lng": -73.8476988
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.8526988,
        7.0600318,
        -73.8426988,
        7.0700318
      ]
    },
    {
      "id": 2728,
      "name": "San-Pedro",
      "names": {
        "es": "San Pedro,",
        "fr": "-San Pedro",
        "zh": "的SAN-佩德罗",
        "it": "-San Pedro",
        "ja": "サン・ペドロ",
        "th": "ซาน-เปโดร"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 38.8267126,
        "lng": -2.1860141
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -2.2401453,
        38.7282147,
        -2.1350067,
        38.8539193
      ]
    },
    {
      "id": 2729,
      "name": "Bukit Mertajam",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 5.3642519,
        "lng": 100.4609867
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        100.3009867,
        5.2042519,
        100.6209867,
        5.5242519
      ]
    },
    {
      "id": 2730,
      "name": "Puerto Vallarta",
      "names": {
        "zh": "波多黎各港",
        "ja": "プエルトバジャルタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.647833,
        "lng": -105.217206
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.270869,
        20.581607,
        -105.178178,
        20.700114
      ]
    },
    {
      "id": 2731,
      "name": "Ijebu-Ode",
      "names": {
        "zh": "ijebu-常微分方程",
        "ja": "ijebu-オード",
        "th": "ijebu-บทกวี"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.8140077,
        "lng": 3.9151668
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        3.8985653,
        6.679123,
        4.0785592,
        6.8916478
      ]
    },
    {
      "id": 2732,
      "name": "Taisheng",
      "names": {
        "zh": "泰盛"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.35385,
        "lng": 113.26751
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.24751,
        23.33385,
        113.28751,
        23.37385
      ]
    },
    {
      "id": 2733,
      "name": "Rostock",
      "names": {
        "zh": "罗斯托克",
        "ja": "ロストック"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.0886707,
        "lng": 12.1400211
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        11.998369,
        54.050805,
        12.2954016,
        54.2445018
      ]
    },
    {
      "id": 2734,
      "name": "Cork",
      "names": {
        "es": "Corcho",
        "zh": "科克",
        "de": "Kork",
        "it": "Sughero",
        "id": "Sumbat",
        "ja": "コルク",
        "th": "ก๊อก",
        "pt": "Cortiça"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 51.899076,
        "lng": -8.467932
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -8.541806,
        51.870394,
        -8.389479,
        51.929299
      ]
    },
    {
      "id": 2735,
      "name": "Guarenas",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.46736,
        "lng": -66.60663
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.62663,
        10.44736,
        -66.58663,
        10.48736
      ]
    },
    {
      "id": 2736,
      "name": "Panabo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.29987,
        "lng": 125.6807086
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.5464363,
        7.2166699,
        125.7861532,
        7.4508997
      ]
    },
    {
      "id": 2737,
      "name": "Dongning",
      "names": {
        "zh": "东宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.0583668,
        "lng": 131.1190619
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.9590619,
        43.8983668,
        131.2790619,
        44.2183668
      ]
    },
    {
      "id": 2738,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.467178,
        "lng": -16.2507843
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -16.3458288,
        28.4097307,
        -16.1193833,
        28.6053951
      ]
    },
    {
      "id": 2739,
      "name": "Solihull",
      "names": {
        "zh": "索利赫尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4130189,
        "lng": -1.7768935
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8720419,
        52.3477247,
        -1.5928548,
        52.5144804
      ]
    },
    {
      "id": 2740,
      "name": "Burutu",
      "names": {
        "zh": "布鲁图"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.3522824,
        "lng": 5.5079208
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.2057605,
        5.0111964,
        5.9154377,
        5.4676676
      ]
    },
    {
      "id": 2741,
      "name": "Nasim Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.649,
        "lng": 51.496
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.476,
        36.629,
        51.516,
        36.669
      ]
    },
    {
      "id": 2742,
      "name": "Moratuwa",
      "names": {
        "zh": "莫勒图沃"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.7746821,
        "lng": 79.8826095
      },
      "country": "Sri Lanka",
      "importance": 3,
      "bbox": [
        79.7226095,
        6.6146821,
        80.0426095,
        6.9346821
      ]
    },
    {
      "id": 2743,
      "name": "Pinghu",
      "names": {
        "zh": "平湖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6782402,
        "lng": 121.0116196
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.946126,
        30.283623,
        121.5062556,
        30.8589861
      ]
    },
    {
      "id": 2744,
      "name": "Ich'on",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.249644,
        "lng": 127.490845
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.42189,
        37.234437,
        127.504863,
        37.293991
      ]
    },
    {
      "id": 2745,
      "name": "Milton Keynes",
      "names": {
        "zh": "米尔顿凯恩斯",
        "ja": "ミルトンケインズ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.0406502,
        "lng": -0.7594092
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.8630339,
        51.969853,
        -0.6402224,
        52.1025671
      ]
    },
    {
      "id": 2746,
      "name": "Marawi City",
      "names": {
        "es": "Ciudad de Marawi",
        "fr": "Ville de Marawi",
        "zh": "马拉维市",
        "de": "Marawi-Stadt",
        "id": "Kota marawi",
        "ja": "マラウイシティ",
        "th": "เมืองมาร์วี่",
        "pt": "Cidade de marawi"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0034,
        "lng": 124.28395
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.26395,
        7.9834,
        124.30395,
        8.0234
      ]
    },
    {
      "id": 2747,
      "name": "Puerto Cabello",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトカベロ",
        "th": "เปอร์โตคาแชลโล"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4702934,
        "lng": -68.0287661
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -68.0288161,
        10.4702434,
        -68.0287161,
        10.4703434
      ]
    },
    {
      "id": 2748,
      "name": "Beersheba",
      "names": {
        "zh": "别是巴",
        "id": "Bersyeba",
        "pt": "Berseba"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.25088285,
        "lng": 34.791280348955596
      },
      "country": "Israel",
      "importance": 1,
      "bbox": [
        34.7474702,
        31.2118516,
        34.8302828,
        31.2891959
      ]
    },
    {
      "id": 2749,
      "name": "Huangyan",
      "names": {
        "zh": "黄岩"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.65319,
        "lng": 121.2576325
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.7929359,
        28.4756651,
        121.3608233,
        28.7297614
      ]
    },
    {
      "id": 2750,
      "name": "North Port",
      "names": {
        "es": "Puerto norte",
        "fr": "Port nord",
        "zh": "北口",
        "de": "Nordhafen",
        "it": "Porto nord",
        "id": "Pelabuhan utara",
        "ja": "ノースポート",
        "th": "พอร์ตเหนือ",
        "pt": "Porto norte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.044224,
        "lng": -82.2359254
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.3525706,
        26.9453584,
        -82.0573218,
        27.1218738
      ]
    },
    {
      "id": 2751,
      "name": "Almere",
      "names": {
        "zh": "阿尔默勒"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3705038,
        "lng": 5.2185859
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.0604282,
        52.3023922,
        5.3792111,
        52.5836665
      ]
    },
    {
      "id": 2752,
      "name": "Lubuklinggau",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.2934427499999996,
        "lng": 102.86422127402622
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        102.8641371,
        -3.2937121,
        102.8643055,
        -3.2931733
      ]
    },
    {
      "id": 2753,
      "name": "Mary",
      "names": {
        "es": "María",
        "zh": "玛丽",
        "de": "Maria",
        "ja": "メアリー",
        "th": "แมรี่",
        "pt": "Maria"
      },
      "countryCode": "TM",
      "latLng": {
        "lat": 37.5940358,
        "lng": 61.8397935
      },
      "country": "Turkmenistan",
      "importance": 1,
      "bbox": [
        61.7689019,
        37.4735289,
        61.9198054,
        37.654362
      ]
    },
    {
      "id": 2754,
      "name": "Fargo",
      "names": {
        "zh": "法戈",
        "ja": "ファーゴ",
        "th": "ฟาร์โก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.877229,
        "lng": -96.789821
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.903771,
        46.73143,
        -96.7528369,
        46.9629491
      ]
    },
    {
      "id": 2755,
      "name": "Cascais",
      "names": {
        "zh": "卡斯卡伊斯",
        "ja": "カスカイス",
        "th": "กาส์เซส์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6968919,
        "lng": -9.4204495
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.4865543,
        38.6758859,
        -9.3080732,
        38.7689928
      ]
    },
    {
      "id": 2756,
      "name": "Northcote",
      "names": {
        "zh": "诺斯科特"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.807775,
        "lng": 174.7440606
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        174.7240606,
        -36.827775,
        174.7640606,
        -36.787775
      ]
    },
    {
      "id": 2757,
      "name": "Qina",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.01055795,
        "lng": 33.12992108817383
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.929943,
        25.2101383,
        33.5661748,
        26.8002707
      ]
    },
    {
      "id": 2758,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 39.8560679,
        "lng": -4.0239568
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -4.1790592,
        39.8120405,
        -3.8147929,
        39.9254339
      ]
    },
    {
      "id": 2759,
      "name": "Kharagpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.34309,
        "lng": 87.3012875
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.1412875,
        22.18309,
        87.4612875,
        22.50309
      ]
    },
    {
      "id": 2760,
      "name": "Waitakere",
      "names": {
        "zh": "怀塔尔基市"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.848987,
        "lng": 174.5442266
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        174.5242266,
        -36.868987,
        174.5642266,
        -36.828987
      ]
    },
    {
      "id": 2761,
      "name": "Monywa",
      "names": {
        "zh": "蒙育瓦"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 22.10856,
        "lng": 95.13583
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.11583,
        22.08856,
        95.15583,
        22.12856
      ]
    },
    {
      "id": 2762,
      "name": "Dindigul",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.367768,
        "lng": 77.97524
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.953934,
        10.338784,
        78.006942,
        10.38486
      ]
    },
    {
      "id": 2763,
      "name": "Morogoro",
      "names": {
        "zh": "莫罗戈罗"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.8223524,
        "lng": 37.6724324
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        37.6674324,
        -6.8273524,
        37.6774324,
        -6.8173524
      ]
    },
    {
      "id": 2764,
      "name": "Green Bay",
      "names": {
        "es": "Bahía verde",
        "fr": "Verte",
        "zh": "绿湾",
        "de": "Grüne Bucht",
        "it": "Baia verde",
        "ja": "グリーンベイ",
        "th": "กรีนเบย์",
        "pt": "Baía verde"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.5126379,
        "lng": -88.0125794
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.139816,
        44.471793,
        -87.841173,
        44.574693
      ]
    },
    {
      "id": 2765,
      "name": "Pingzhen",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9457522,
        "lng": 121.2183594
      },
      "country": "Taiwan",
      "importance": 3,
      "bbox": [
        121.1769721,
        24.8724181,
        121.2582144,
        24.9713045
      ]
    },
    {
      "id": 2766,
      "name": "Bani Suwayf",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 29.0729812,
        "lng": 31.0982562
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.9382562,
        28.9129812,
        31.2582562,
        29.2329812
      ]
    },
    {
      "id": 2767,
      "name": "Cidade de Nacala",
      "names": {
        "ja": "De Nacalaを準備します"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -14.5583059,
        "lng": 40.6863816
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        40.6106033,
        -14.6828691,
        40.8300735,
        -14.4095377
      ]
    },
    {
      "id": 2768,
      "name": "Talca",
      "names": {
        "zh": "塔尔卡"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -35.4265343,
        "lng": -71.6660322
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -71.7213757,
        -35.5311054,
        -71.492511,
        -35.3115678
      ]
    },
    {
      "id": 2769,
      "name": "Portoviejo",
      "names": {
        "zh": "波托维耶霍"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.05282,
        "lng": -80.4534134
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -80.5625003,
        -1.2029866,
        -80.316762,
        -0.9207091
      ]
    },
    {
      "id": 2770,
      "name": "Spring Valley",
      "names": {
        "es": "Valle de primavera",
        "fr": "Vallée de printemps",
        "zh": "春谷",
        "de": "Frühlingsvalley",
        "it": "Primavera",
        "id": "Lembah musim semi",
        "ja": "スプリングバレー",
        "th": "หุบเขาฤดูใบไม้ผลิ",
        "pt": "Vale de primavera"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0997835,
        "lng": -115.26166675899091
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.315484,
        36.0555336,
        -115.2078707,
        36.1445327
      ]
    },
    {
      "id": 2771,
      "name": "Linz",
      "names": {
        "zh": "林茨",
        "ja": "リンツ",
        "th": "ลินซ์"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3059078,
        "lng": 14.286198
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        14.24572,
        48.2113712,
        14.4092169,
        48.3786926
      ]
    },
    {
      "id": 2772,
      "name": "Trieste",
      "names": {
        "zh": "里雅斯特",
        "de": "Triest",
        "ja": "トリエステ",
        "th": "เอสเต"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6496485,
        "lng": 13.7772781
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        13.6683192,
        45.6067952,
        13.8983086,
        45.7466197
      ]
    },
    {
      "id": 2773,
      "name": "San Carlos City",
      "names": {
        "es": "Ciudad de san carlos",
        "zh": "圣卡洛斯市",
        "id": "Kota San Carlos",
        "ja": "サンカルロスシティ",
        "th": "ซานคาร์ลอสซิตี้",
        "pt": "Cidade de san carlos"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 22.1914704,
        "lng": -101.0140013
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -101.0142553,
        22.1910242,
        -101.0136548,
        22.1916543
      ]
    },
    {
      "id": 2774,
      "name": "Kodaira",
      "names": {
        "zh": "小平"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.72522,
        "lng": 139.476606
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.422845,
        35.7081785,
        139.5247185,
        35.745858
      ]
    },
    {
      "id": 2775,
      "name": "Itami",
      "names": {
        "zh": "伊丹",
        "ja": "伊丹",
        "th": "อิตามิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.786159,
        "lng": 135.407994
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.369766,
        34.756784,
        135.446222,
        34.815534
      ]
    },
    {
      "id": 2776,
      "name": "Phan Thiet",
      "names": {
        "zh": "藩切",
        "ja": "ファンティエット",
        "th": "พานเทียต"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.9186848,
        "lng": 108.1058482
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        108.1057982,
        10.9186348,
        108.1058982,
        10.9187348
      ]
    },
    {
      "id": 2777,
      "name": "Kalemie",
      "names": {
        "zh": "卡莱米"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.94749,
        "lng": 29.19471
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        29.17471,
        -5.96749,
        29.21471,
        -5.92749
      ]
    },
    {
      "id": 2778,
      "name": "Kamirenjaku",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.68333,
        "lng": 139.55
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.53,
        35.66333,
        139.57,
        35.70333
      ]
    },
    {
      "id": 2779,
      "name": "Loures",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8308741,
        "lng": -9.1684512
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.2460011,
        38.7748054,
        -9.0687556,
        38.9409138
      ]
    },
    {
      "id": 2780,
      "name": "Biskra",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.859638,
        "lng": 5.7252196
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.7202196,
        34.854638,
        5.7302196,
        34.864638
      ]
    },
    {
      "id": 2781,
      "name": "Ingraj Bazar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.0057449,
        "lng": 88.1398483
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.9798483,
        24.8457449,
        88.2998483,
        25.1657449
      ]
    },
    {
      "id": 2782,
      "name": "Bene Beraq",
      "names": {
        "id": "Benu",
        "ja": "ベネベラック"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.089868,
        "lng": 34.83468
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.821393,
        32.068998,
        34.849276,
        32.108372
      ]
    },
    {
      "id": 2783,
      "name": "Mytishchi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9094928,
        "lng": 37.7339358
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.6652465,
        55.8784053,
        37.8130949,
        55.9851894
      ]
    },
    {
      "id": 2784,
      "name": "Nagareyama",
      "names": {
        "zh": "流山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8562314,
        "lng": 139.9026259
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.87475,
        35.8274933,
        139.957543,
        35.91976
      ]
    },
    {
      "id": 2785,
      "name": "Ongole",
      "names": {
        "zh": "翁戈尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.5058714,
        "lng": 80.0499454
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.8899454,
        15.3458714,
        80.2099454,
        15.6658714
      ]
    },
    {
      "id": 2786,
      "name": "Zanzibar",
      "names": {
        "zh": "桑给巴尔",
        "ja": "ザンジバル",
        "th": "แซนซิบาร์"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.1664908,
        "lng": 39.2074312
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        39.0474312,
        -6.3264908,
        39.3674312,
        -6.0064908
      ]
    },
    {
      "id": 2787,
      "name": "Jiutai",
      "names": {
        "zh": "九台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1523236,
        "lng": 125.8387384
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        125.4051146,
        43.8468122,
        126.498772,
        44.5261481
      ]
    },
    {
      "id": 2788,
      "name": "Qa'em Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.48144,
        "lng": 52.89109
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        52.87109,
        36.46144,
        52.91109,
        36.50144
      ]
    },
    {
      "id": 2789,
      "name": "Amarillo",
      "names": {
        "zh": "阿马里洛",
        "ja": "アマリロ",
        "th": "อามาริลโล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.20729,
        "lng": -101.8371192
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -101.956244,
        35.1020007,
        -101.653777,
        35.2944807
      ]
    },
    {
      "id": 2790,
      "name": "Ellore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.71311,
        "lng": 81.10437
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2791,
      "name": "Ziguinchor",
      "names": {
        "zh": "济金绍尔"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.58333,
        "lng": -16.27194
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.29194,
        12.56333,
        -16.25194,
        12.60333
      ]
    },
    {
      "id": 2792,
      "name": "Ternate",
      "names": {
        "zh": "特尔纳特"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.7852338,
        "lng": 127.3831752
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        126.127274,
        0.432722,
        127.4398988,
        1.3516821
      ]
    },
    {
      "id": 2793,
      "name": "Puqi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7211667,
        "lng": 113.8799709
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.7908742,
        29.6386825,
        113.8916082,
        29.7517482
      ]
    },
    {
      "id": 2794,
      "name": "La Ceiba",
      "names": {
        "zh": "洛杉矶",
        "ja": "ラセイバ"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.7833743,
        "lng": -86.7917749
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -86.89225,
        15.542353,
        -86.5981052,
        15.801448
      ]
    },
    {
      "id": 2795,
      "name": "Yachiyo",
      "names": {
        "zh": "八千代"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.722537,
        "lng": 140.0995131
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.06292,
        35.6922576,
        140.151877,
        35.784003
      ]
    },
    {
      "id": 2796,
      "name": "Mandi Burewala",
      "names": {
        "pt": "Mandi Busewala"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.586162,
        "lng": 73.494796
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.456072,
        32.555834,
        73.522843,
        32.608987
      ]
    },
    {
      "id": 2797,
      "name": "Coquimbo",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -29.9531851,
        "lng": -71.3379503
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.6709477,
        -30.5097728,
        -71.1100422,
        -29.9326236
      ]
    },
    {
      "id": 2798,
      "name": "Tekirdag",
      "names": {
        "zh": "泰基尔达"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9781214,
        "lng": 27.5107799
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        27.3507799,
        40.8181214,
        27.6707799,
        41.1381214
      ]
    },
    {
      "id": 2799,
      "name": "Parnamirim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.91593955,
        "lng": -35.25783829856957
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.2579,
        -5.9162662,
        -35.2578008,
        -5.9157104
      ]
    },
    {
      "id": 2800,
      "name": "Portland",
      "names": {
        "zh": "波特兰",
        "ja": "ポートランド",
        "th": "พอร์ตแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6573605,
        "lng": -70.2586618
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.3473997,
        43.5443477,
        -69.9758509,
        43.7276965
      ]
    },
    {
      "id": 2801,
      "name": "Groningen",
      "names": {
        "zh": "格罗宁根",
        "ja": "グローニンゲン",
        "th": "โกร"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2190652,
        "lng": 6.5680077
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        6.4627456,
        53.1786103,
        6.6668727,
        53.2649469
      ]
    },
    {
      "id": 2802,
      "name": "Biysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.52431,
        "lng": 85.155709
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        85.110306,
        52.484177,
        85.250847,
        52.567074
      ]
    },
    {
      "id": 2803,
      "name": "Charleroi",
      "names": {
        "zh": "沙勒罗瓦",
        "ja": "シャルルロワ",
        "th": "ชาร์เลอรัว"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.412742,
        "lng": 4.447697
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.348189,
        50.345568,
        4.56629,
        50.481647
      ]
    },
    {
      "id": 2804,
      "name": "Aberdeen",
      "names": {
        "zh": "阿伯丁",
        "ja": "アバディーン",
        "th": "อเบอร์ดีน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 57.1482429,
        "lng": -2.0928095
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.3609399,
        57.0761867,
        -2.0161507,
        57.2353072
      ]
    },
    {
      "id": 2805,
      "name": "Lanxi",
      "names": {
        "zh": "兰溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2161839,
        "lng": 119.4750576
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.4700576,
        29.2111839,
        119.4800576,
        29.2211839
      ]
    },
    {
      "id": 2806,
      "name": "La Guaira",
      "names": {
        "ja": "ラガライラ",
        "th": "คนใหญ่"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6000384,
        "lng": -66.9296405
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -67.4122489,
        10.3880442,
        -66.3064599,
        10.690441
      ]
    },
    {
      "id": 2807,
      "name": "Mazabuka",
      "names": {
        "zh": "马扎布卡"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -15.8584807,
        "lng": 27.7613139
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        27.7213139,
        -15.8984807,
        27.8013139,
        -15.8184807
      ]
    },
    {
      "id": 2808,
      "name": "Zhubei",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 24.839682,
        "lng": 121.0040779
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.9295886,
        24.7838768,
        121.0666352,
        24.8815208
      ]
    },
    {
      "id": 2809,
      "name": "Poza Rica de Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5320852,
        "lng": -97.4536355
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.6136355,
        20.3720852,
        -97.2936355,
        20.6920852
      ]
    },
    {
      "id": 2810,
      "name": "Torun",
      "names": {
        "zh": "托伦",
        "ja": "トルン",
        "th": "โตรัน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.0102721,
        "lng": 18.6048094
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        18.4606656,
        52.9630691,
        18.7422299,
        53.0658981
      ]
    },
    {
      "id": 2811,
      "name": "Tanjungpinang",
      "names": {
        "zh": "丹戎槟榔"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.9236915,
        "lng": 104.446094
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        104.3363241,
        0.8385882,
        104.5518343,
        0.9875593
      ]
    },
    {
      "id": 2812,
      "name": "Kassel",
      "names": {
        "zh": "卡塞尔",
        "ja": "カッセル",
        "th": "คาสเซิล"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3154546,
        "lng": 9.4924096
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.3510229,
        51.2603806,
        9.5700842,
        51.369403
      ]
    },
    {
      "id": 2813,
      "name": "Djougou",
      "names": {
        "zh": "朱古"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 9.7072633,
        "lng": 1.6650293
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        1.6649793,
        9.7072133,
        1.6650793,
        9.7073133
      ]
    },
    {
      "id": 2814,
      "name": "Haldia",
      "names": {
        "zh": "霍尔迪亚"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.028124,
        "lng": 88.0632655
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.9032655,
        21.868124,
        88.2232655,
        22.188124
      ]
    },
    {
      "id": 2815,
      "name": "Luxor",
      "names": {
        "zh": "卢克索",
        "ja": "ルクソール",
        "th": "ลุกซอร์"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.702096,
        "lng": 32.647186
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        32.487186,
        25.542096,
        32.807186,
        25.862096
      ]
    },
    {
      "id": 2816,
      "name": "Tarakan",
      "names": {
        "zh": "塔拉坎"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 3.3000169,
        "lng": 117.6330159
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        117.5144881,
        3.2395184,
        117.6690888,
        3.4434266
      ]
    },
    {
      "id": 2817,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4221319,
        "lng": -119.702667
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.859791,
        34.336029,
        -119.6399059,
        34.463922
      ]
    },
    {
      "id": 2818,
      "name": "Gainesville",
      "names": {
        "zh": "盖恩斯维尔",
        "ja": "ゲインズビル",
        "th": "เกนส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.6519684,
        "lng": -82.3249846
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.4222531,
        29.5978621,
        -82.2223771,
        29.7783723
      ]
    },
    {
      "id": 2819,
      "name": "Las Tunas",
      "names": {
        "es": "Las atunes",
        "fr": "Tunas Las",
        "zh": "拉斯金枪鱼",
        "de": "Las Thuns",
        "ja": "ラスマグロ",
        "pt": "Atunas las"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.08870445,
        "lng": -76.81216477200348
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -77.8340858,
        20.4751578,
        -76.2046924,
        21.7018421
      ]
    },
    {
      "id": 2820,
      "name": "Lyubertsy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6783142,
        "lng": 37.89377
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.8561665,
        55.5664614,
        37.9972799,
        55.7113411
      ]
    },
    {
      "id": 2821,
      "name": "Matsue",
      "names": {
        "zh": "松江",
        "ja": "松江"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.468115,
        "lng": 133.048768
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        132.8287487,
        35.329702,
        133.5282837,
        35.8017066
      ]
    },
    {
      "id": 2822,
      "name": "Sosnowiec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2780834,
        "lng": 19.1342944
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0713127,
        50.2255221,
        19.3179524,
        50.316944
      ]
    },
    {
      "id": 2823,
      "name": "Arica",
      "names": {
        "zh": "阿里卡",
        "th": "อาริคา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -18.4785288,
        "lng": -70.3211394
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -70.3778842,
        -18.9040283,
        -69.4889923,
        -18.0457729
      ]
    },
    {
      "id": 2824,
      "name": "Al Khums",
      "names": {
        "de": "Alkhums",
        "ja": "アルコム",
        "th": "อัล khums"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.652183,
        "lng": 14.26801
      },
      "country": "Libya",
      "importance": 3,
      "bbox": [
        14.10801,
        32.492183,
        14.42801,
        32.812183
      ]
    },
    {
      "id": 2825,
      "name": "Huacho",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -11.108524,
        "lng": -77.6103295
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -77.6542266,
        -11.4471797,
        -77.3199,
        -11.0999044
      ]
    },
    {
      "id": 2826,
      "name": "Gurgaon",
      "names": {
        "zh": "古尔冈",
        "ja": "グルガオン",
        "th": "คุร์เคาน์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.4646148,
        "lng": 77.0299194
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.6510198,
        28.2005681,
        77.2418683,
        28.5409048
      ]
    },
    {
      "id": 2827,
      "name": "El Jadid",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 31.72418,
        "lng": -7.00786
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.02786,
        31.70418,
        -6.98786,
        31.74418
      ]
    },
    {
      "id": 2828,
      "name": "Cajamarca",
      "names": {
        "zh": "卡哈马卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -7.1566682,
        "lng": -78.517306
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -78.6627579,
        -7.2462869,
        -78.4573235,
        -6.8865097
      ]
    },
    {
      "id": 2829,
      "name": "Huntington",
      "names": {
        "zh": "亨廷顿",
        "ja": "ハンティントン",
        "th": "ฮันติงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.868154,
        "lng": -73.425676
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.497017,
        40.751373,
        -73.286277,
        41.011974
      ]
    },
    {
      "id": 2830,
      "name": "Ciudad Madero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.2474012,
        "lng": -97.8373079
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.9973079,
        22.0874012,
        -97.6773079,
        22.4074012
      ]
    },
    {
      "id": 2831,
      "name": "Lashkar Gah",
      "names": {
        "zh": "拉什卡尔"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 31.59382,
        "lng": 64.37161
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        64.35161,
        31.57382,
        64.39161,
        31.61382
      ]
    },
    {
      "id": 2832,
      "name": "Mai'Adua",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 13.161061,
        "lng": 8.212968220192417
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        8.0620196,
        13.0079804,
        8.430432,
        13.313778
      ]
    },
    {
      "id": 2833,
      "name": "Jacobabad",
      "names": {
        "zh": "雅各布阿巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 28.277449,
        "lng": 68.439814
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.414364,
        28.255984,
        68.461989,
        28.299669
      ]
    },
    {
      "id": 2834,
      "name": "Almeria",
      "names": {
        "es": "Almería",
        "zh": "阿尔梅里亚",
        "ja": "アルメリア",
        "th": "อัลเมเรีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.8414197,
        "lng": -2.4628135
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -3.0372135,
        35.9376398,
        -2.2022024,
        37.0000125
      ]
    },
    {
      "id": 2835,
      "name": "Debrecen",
      "names": {
        "zh": "德布勒森",
        "ja": "デブレツェン",
        "th": "บรี"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.533282,
        "lng": 21.628133
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        21.556684,
        47.485775,
        21.688764,
        47.56583
      ]
    },
    {
      "id": 2836,
      "name": "Tokat",
      "names": {
        "zh": "托卡特",
        "ja": "トカト"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3233534,
        "lng": 36.552162
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.3269805,
        39.939883,
        36.9061405,
        40.5584498
      ]
    },
    {
      "id": 2837,
      "name": "Nema",
      "names": {
        "zh": "的NEMA"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.6173344,
        "lng": -7.2663798
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -7.4263798,
        16.4573344,
        -7.1063798,
        16.7773344
      ]
    },
    {
      "id": 2838,
      "name": "Qyzylorda",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 44.8426778,
        "lng": 65.5036151
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        65.4211271,
        44.7632633,
        65.579496,
        44.8963213
      ]
    },
    {
      "id": 2839,
      "name": "Olympia",
      "names": {
        "zh": "奥林匹亚",
        "ja": "オリンピア",
        "th": "โอลิมเปีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.0451022,
        "lng": -122.8950075
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -122.971077,
        47.0008627,
        -122.823247,
        47.0782592
      ]
    },
    {
      "id": 2840,
      "name": "Guacara",
      "names": {
        "zh": "瓜卡拉"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2327902,
        "lng": -67.8822916
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.8823416,
        10.2327402,
        -67.8822416,
        10.2328402
      ]
    },
    {
      "id": 2841,
      "name": "Oulu",
      "names": {
        "zh": "奥卢",
        "ja": "オウル",
        "th": "อูลู"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.0118734,
        "lng": 25.4716809
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        24.1149326,
        64.844412,
        26.7715283,
        65.5643522
      ]
    },
    {
      "id": 2842,
      "name": "Maimanah",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.92139,
        "lng": 64.78361
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        64.76361,
        35.90139,
        64.80361,
        35.94139
      ]
    },
    {
      "id": 2843,
      "name": "Frisco",
      "names": {
        "zh": "弗里斯科",
        "ja": "フリスコ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1505998,
        "lng": -96.8238183
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.9227866,
        33.0814979,
        -96.7325669,
        33.219578
      ]
    },
    {
      "id": 2844,
      "name": "Kabankalan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.9889153,
        "lng": 122.8134802
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7175207,
        9.5766892,
        122.9809463,
        10.1219676
      ]
    },
    {
      "id": 2845,
      "name": "Isidro Casanova",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7036939,
        "lng": -58.586236
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.6162037,
        -34.7593019,
        -58.5356834,
        -34.6825308
      ]
    },
    {
      "id": 2846,
      "name": "Yonkers",
      "names": {
        "zh": "扬克斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9377457,
        "lng": -73.9009483
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.9009983,
        40.9376957,
        -73.9008983,
        40.9377957
      ]
    },
    {
      "id": 2847,
      "name": "Rio Claro",
      "names": {
        "zh": "里奥克洛",
        "ja": "リオクラロ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4100108,
        "lng": -47.5603933
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.7653111,
        -22.552,
        -47.4603689,
        -22.2436902
      ]
    },
    {
      "id": 2848,
      "name": "Norwich",
      "names": {
        "zh": "诺里奇",
        "ja": "ノーリッチ",
        "th": "นอริช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5243537,
        "lng": -72.0759008
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.140656,
        41.490684,
        -72.040317,
        41.6080203
      ]
    },
    {
      "id": 2849,
      "name": "Bulandshahr",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.4026,
        "lng": 77.847965
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.815501,
        28.38062,
        77.894138,
        28.430095
      ]
    },
    {
      "id": 2850,
      "name": "Az Zawiyah",
      "names": {
        "ja": "Zawiyah az"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.7596665,
        "lng": 12.7355967
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        12.5755967,
        32.5996665,
        12.8955967,
        32.9196665
      ]
    },
    {
      "id": 2851,
      "name": "Kasama",
      "names": {
        "zh": "笠间"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -10.2044875,
        "lng": 31.1877186
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        31.0277186,
        -10.3644875,
        31.3477186,
        -10.0444875
      ]
    },
    {
      "id": 2852,
      "name": "Puerto Cortes",
      "names": {
        "id": "Cortes Puerto",
        "ja": "プエルトコルテス",
        "th": "เปอร์โตคอร์เทส"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.851465,
        "lng": -87.9426681
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -88.2134425,
        15.5922282,
        -87.7005235,
        16.1637764
      ]
    },
    {
      "id": 2853,
      "name": "Kouribga",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.88,
        "lng": -6.9
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 2854,
      "name": "Lahad Datu",
      "names": {
        "zh": "拉哈德Datu",
        "ja": "ラハードのデータ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.0215726,
        "lng": 118.3275533
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        118.2875533,
        4.9815726,
        118.3675533,
        5.0615726
      ]
    },
    {
      "id": 2855,
      "name": "Bojnurd",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.47473,
        "lng": 57.32903
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        57.30903,
        37.45473,
        57.34903,
        37.49473
      ]
    },
    {
      "id": 2856,
      "name": "Norwich",
      "names": {
        "zh": "诺里奇",
        "ja": "ノーリッチ",
        "th": "นอริช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.6285576,
        "lng": 1.2923954
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        1.2038691,
        52.555435,
        1.5407848,
        52.6849096
      ]
    },
    {
      "id": 2857,
      "name": "Baharampur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.1044927,
        "lng": 88.2510635
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.0910635,
        23.9444927,
        88.4110635,
        24.2644927
      ]
    },
    {
      "id": 2858,
      "name": "Liege",
      "names": {
        "es": "Lieja",
        "fr": "Liège",
        "zh": "列日",
        "de": "Lüttich",
        "it": "Liegi",
        "id": "Liège",
        "ja": "リエージュ",
        "th": "ลีแอช"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6450944,
        "lng": 5.5736112
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.5233883,
        50.5610182,
        5.675257,
        50.6881981
      ]
    },
    {
      "id": 2859,
      "name": "Banja Luka",
      "names": {
        "ja": "バンジャルカ"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.767274,
        "lng": 17.1861296
      },
      "country": "Bosnia And Herzegovina",
      "importance": 1,
      "bbox": [
        17.1860796,
        44.767224,
        17.1861796,
        44.767324
      ]
    },
    {
      "id": 2860,
      "name": "Glendale",
      "names": {
        "zh": "格伦代尔",
        "ja": "グレンデール",
        "th": "เกลนเดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1469416,
        "lng": -118.2478471
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.3078696,
        34.118761,
        -118.1816954,
        34.2672349
      ]
    },
    {
      "id": 2861,
      "name": "Huntington Beach",
      "names": {
        "es": "Playa Huntington",
        "fr": "Plage de Huntington",
        "zh": "亨廷顿海滩",
        "id": "Pantai Huntington",
        "ja": "ハンティントンビーチ",
        "th": "ฮันติงตันบีช",
        "pt": "Praia de huntington"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6783336,
        "lng": -118.000016
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1184237,
        33.5885246,
        -117.9403791,
        33.7562073
      ]
    },
    {
      "id": 2862,
      "name": "Taranto",
      "names": {
        "es": "Tarento",
        "zh": "塔兰托",
        "ja": "ターラント",
        "th": "ชาติตารันโต"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.4712427,
        "lng": 17.2432278
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        17.1165464,
        40.3301566,
        17.4727156,
        40.5915167
      ]
    },
    {
      "id": 2863,
      "name": "Zhugang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8282248,
        "lng": 117.2978208
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.2878208,
        31.8182248,
        117.3078208,
        31.8382248
      ]
    },
    {
      "id": 2864,
      "name": "Martapura",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.4086465,
        "lng": 114.848837
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        114.808837,
        -3.4486465,
        114.888837,
        -3.3686465
      ]
    },
    {
      "id": 2865,
      "name": "Chakradharpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.701019350000003,
        "lng": 85.63891761805871
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        85.5044874,
        22.5511262,
        85.7177996,
        22.8515396
      ]
    },
    {
      "id": 2866,
      "name": "Tocuyito",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1062443,
        "lng": -68.0942185
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -68.2542185,
        9.9462443,
        -67.9342185,
        10.2662443
      ]
    },
    {
      "id": 2867,
      "name": "Alvorada",
      "names": {
        "zh": "阿尔沃拉达"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.9981184,
        "lng": -51.0772509
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.0943466,
        -30.0514904,
        -50.9482111,
        -29.9564871
      ]
    },
    {
      "id": 2868,
      "name": "Deltona",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 28.904843,
        "lng": -81.2273119
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.2824255,
        28.8210738,
        -81.1500403,
        28.9636527
      ]
    },
    {
      "id": 2869,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 37.9357576,
        "lng": -122.347748
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.441484,
        37.88364,
        -122.2434491,
        38.0285668
      ]
    },
    {
      "id": 2870,
      "name": "Holon",
      "names": {
        "zh": "弄"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0193121,
        "lng": 34.7804076
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.7552863,
        31.9887223,
        34.8147651,
        32.0386707
      ]
    },
    {
      "id": 2871,
      "name": "Alcala de Henares",
      "names": {
        "es": "Alcalá de Henares"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.481954,
        "lng": -3.363981
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.4473982,
        40.4458937,
        -3.2837755,
        40.5451001
      ]
    },
    {
      "id": 2872,
      "name": "Gemena",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": 3.25651,
        "lng": 19.77234
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        19.75234,
        3.23651,
        19.79234,
        3.27651
      ]
    },
    {
      "id": 2873,
      "name": "Oradea",
      "names": {
        "zh": "奥拉迪亚",
        "ja": "オラデア",
        "th": "ออราเดีย"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.0700561,
        "lng": 21.9361822
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        21.9311822,
        47.0650561,
        21.9411822,
        47.0750561
      ]
    },
    {
      "id": 2874,
      "name": "Helong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.5437508,
        "lng": 129.0043408
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        128.3813783,
        41.9969654,
        129.4056853,
        42.9543156
      ]
    },
    {
      "id": 2875,
      "name": "Beni Mellal",
      "names": {
        "id": "Melel",
        "ja": "ベニメ細胞",
        "th": "เบนิเมน"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.334193,
        "lng": -6.353335
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -6.4209096,
        32.2991047,
        -6.3134217,
        32.3622376
      ]
    },
    {
      "id": 2876,
      "name": "Madhyamgram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.6947839,
        "lng": 88.4530183
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.4480183,
        22.6897839,
        88.4580183,
        22.6997839
      ]
    },
    {
      "id": 2877,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7571701,
        "lng": -88.3147539
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.4083712,
        41.679869,
        -88.204992,
        41.8221793
      ]
    },
    {
      "id": 2878,
      "name": "Burhanpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.3118839,
        "lng": 76.2291992
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.0691992,
        21.1518839,
        76.3891992,
        21.4718839
      ]
    },
    {
      "id": 2879,
      "name": "Khammam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.2465351,
        "lng": 80.1500326
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.9900326,
        17.0865351,
        80.3100326,
        17.4065351
      ]
    },
    {
      "id": 2880,
      "name": "Bhiwani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.7931703,
        "lng": 76.1391283
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.9791283,
        28.6331703,
        76.2991283,
        28.9531703
      ]
    },
    {
      "id": 2881,
      "name": "Higashi-Hiroshima",
      "names": {
        "zh": "东-广岛",
        "ja": "東・広島",
        "th": "Higashi-ฮิโรชิมา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.425555,
        "lng": 132.733693
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.720449,
        34.40243,
        132.755932,
        34.440844
      ]
    },
    {
      "id": 2882,
      "name": "Huanuco",
      "names": {
        "es": "Huánuco",
        "zh": "瓦努科"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -9.9293255,
        "lng": -76.2394845
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -76.3439297,
        -9.9616232,
        -76.179832,
        -9.859795
      ]
    },
    {
      "id": 2883,
      "name": "Mbanza-Ngungu",
      "names": {
        "zh": "姆班扎-ngungu"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.2500086,
        "lng": 14.8666614
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        14.7066614,
        -5.4100086,
        15.0266614,
        -5.0900086
      ]
    },
    {
      "id": 2884,
      "name": "Eldoret",
      "names": {
        "zh": "埃尔多雷特"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 0.5235099,
        "lng": 35.2734974
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.2684974,
        0.5185099,
        35.2784974,
        0.5285099
      ]
    },
    {
      "id": 2885,
      "name": "Hino",
      "names": {
        "zh": "日野",
        "ja": "日野",
        "th": "ฮีโน่"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6793418,
        "lng": 139.3940441
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3890441,
        35.6743418,
        139.3990441,
        35.6843418
      ]
    },
    {
      "id": 2886,
      "name": "Zipaquira",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0234748,
        "lng": -74.0039818
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.094489,
        4.9610666,
        -73.9056229,
        5.1624564
      ]
    },
    {
      "id": 2887,
      "name": "Rio Grande",
      "names": {
        "zh": "里奥格兰德",
        "ja": "リオグランデ",
        "th": "ริโอแกรนด์",
        "pt": "Rude"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -53.7856766,
        "lng": -67.7016369
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -67.7921196,
        -53.8249298,
        -67.6372254,
        -53.7341525
      ]
    },
    {
      "id": 2888,
      "name": "Prokopyevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.8879117,
        "lng": 86.7492072
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        86.5726872,
        53.7512794,
        86.8715505,
        53.9702334
      ]
    },
    {
      "id": 2889,
      "name": "Rajin",
      "names": {
        "zh": "罗津"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 42.2534855,
        "lng": 130.3005306
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        130.04222,
        42.1222002,
        130.4387982,
        42.4111288
      ]
    },
    {
      "id": 2890,
      "name": "Fuenlabrada",
      "names": {
        "zh": "丰拉夫拉达"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.282476,
        "lng": -3.7923422
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.8440421,
        40.2495857,
        -3.7393001,
        40.32774
      ]
    },
    {
      "id": 2891,
      "name": "Ghandinagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.3217294,
        "lng": 79.8283858
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        79.8283358,
        18.3216794,
        79.8284358,
        18.3217794
      ]
    },
    {
      "id": 2892,
      "name": "Brescia",
      "names": {
        "zh": "布雷西亚",
        "ja": "ブレーシア",
        "th": "เบรสชา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5398022,
        "lng": 10.2200214
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.1470684,
        45.4807171,
        10.3002186,
        45.5899752
      ]
    },
    {
      "id": 2893,
      "name": "Kusong",
      "names": {
        "zh": "龟城"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.98111,
        "lng": 125.24472
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.22472,
        39.96111,
        125.26472,
        40.00111
      ]
    },
    {
      "id": 2894,
      "name": "Suhaj",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.547748,
        "lng": 31.699263
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.539263,
        26.387748,
        31.859263,
        26.707748
      ]
    },
    {
      "id": 2895,
      "name": "Acarigua",
      "names": {
        "zh": "阿卡里瓜"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.55451,
        "lng": -69.19564
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.21564,
        9.53451,
        -69.17564,
        9.57451
      ]
    },
    {
      "id": 2896,
      "name": "Hugli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.872939,
        "lng": 88.17086
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.507521,
        22.597527,
        88.508034,
        23.227683
      ]
    },
    {
      "id": 2897,
      "name": "San Pedro de Macoris",
      "names": {
        "ja": "サンペドロデマコリス",
        "th": "ซานเปโดรเดอมาโกเรส"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4617093,
        "lng": -69.3031847
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -69.3480315,
        18.4249933,
        -69.1632758,
        18.5398517
      ]
    },
    {
      "id": 2898,
      "name": "Parma",
      "names": {
        "fr": "Parme",
        "zh": "帕尔马",
        "ja": "パルマ",
        "th": "ปาร์มา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.8013678,
        "lng": 10.3280833
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.214813,
        44.662402,
        10.4436365,
        44.9007443
      ]
    },
    {
      "id": 2899,
      "name": "Kenema",
      "names": {
        "zh": "凯内马"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 7.8859723,
        "lng": -11.1863832
      },
      "country": "Sierra Leone",
      "importance": 1,
      "bbox": [
        -11.3463832,
        7.7259723,
        -11.0263832,
        8.0459723
      ]
    },
    {
      "id": 2900,
      "name": "Brikama",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.27136,
        "lng": -16.64944
      },
      "country": "The Gambia",
      "importance": 1,
      "bbox": [
        -16.66944,
        13.25136,
        -16.62944,
        13.29136
      ]
    },
    {
      "id": 2901,
      "name": "Suzuka",
      "names": {
        "zh": "铃鹿",
        "ja": "鈴鹿"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8817102,
        "lng": 136.5836516
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.395192,
        34.733732,
        136.715465,
        34.988105
      ]
    },
    {
      "id": 2902,
      "name": "Koronadal",
      "names": {
        "zh": "科罗纳达尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.50306,
        "lng": 124.84694
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.82694,
        6.48306,
        124.86694,
        6.52306
      ]
    },
    {
      "id": 2903,
      "name": "Cuautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8290118,
        "lng": -98.9432244
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1032244,
        18.6690118,
        -98.7832244,
        18.9890118
      ]
    },
    {
      "id": 2904,
      "name": "Iskandar",
      "names": {
        "zh": "干达"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5574298,
        "lng": 69.7063727
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        69.6915823,
        41.5471296,
        69.7213247,
        41.5671666
      ]
    },
    {
      "id": 2905,
      "name": "Karakopru",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.204652,
        "lng": 38.7958511
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.4198395,
        37.0367646,
        38.9838746,
        37.5566633
      ]
    },
    {
      "id": 2906,
      "name": "Tempe",
      "names": {
        "zh": "坦佩",
        "ja": "テンペ",
        "th": "เทมพี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4255117,
        "lng": -111.940016
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.9784941,
        33.3200407,
        -111.877425,
        33.4653292
      ]
    },
    {
      "id": 2907,
      "name": "Dese",
      "names": {
        "th": "เหล่านี้"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 11.122604,
        "lng": 39.634982
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        39.474982,
        10.962604,
        39.794982,
        11.282604
      ]
    },
    {
      "id": 2908,
      "name": "Tanauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0853283,
        "lng": 121.1528222
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0427285,
        14.0123158,
        121.1660207,
        14.1569545
      ]
    },
    {
      "id": 2909,
      "name": "La Serena",
      "names": {
        "th": "ลาเซเรน่า"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -29.902631,
        "lng": -71.2520253
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -71.3453838,
        -30.1371849,
        -70.6850264,
        -29.5297219
      ]
    },
    {
      "id": 2910,
      "name": "Overland Park",
      "names": {
        "es": "Parque Overland",
        "fr": "Parc terrestre",
        "zh": "欧弗兰帕克",
        "it": "Parco Overland",
        "id": "Taman Overland",
        "ja": "オーバーランドパーク",
        "th": "สวนสาธารณะ",
        "pt": "Parque Overland"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.868963,
        "lng": -94.6732307
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.6732807,
        38.868913,
        -94.6731807,
        38.869013
      ]
    },
    {
      "id": 2911,
      "name": "Bandar-e Bushehr",
      "names": {
        "ja": "バンダルeブシェー",
        "pt": "Bandar-e busiahr"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 28.9684,
        "lng": 50.8385
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        50.8185,
        28.9484,
        50.8585,
        28.9884
      ]
    },
    {
      "id": 2912,
      "name": "Prato",
      "names": {
        "zh": "普拉托",
        "ja": "プラート"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.880598,
        "lng": 11.0969262
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.0131705,
        43.8157607,
        11.1696149,
        43.9494765
      ]
    },
    {
      "id": 2913,
      "name": "Tchitato",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -7.34469,
        "lng": 20.820081
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        20.800081,
        -7.36469,
        20.840081,
        -7.32469
      ]
    },
    {
      "id": 2914,
      "name": "Anseong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.007773,
        "lng": 127.279971
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.1027132,
        36.8933927,
        127.518128,
        37.155522
      ]
    },
    {
      "id": 2915,
      "name": "Yuzhno-Sakhalinsk",
      "names": {
        "zh": "南萨哈林斯克",
        "de": "Juschno-sachalinsk",
        "th": "yuzhno-ซาคาลินสก์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 46.954441,
        "lng": 142.727637
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        142.687057,
        46.930168,
        142.754913,
        46.989307
      ]
    },
    {
      "id": 2916,
      "name": "Richmond Hill",
      "names": {
        "zh": "里士满山",
        "ja": "リッチモンドヒル",
        "th": "ริชมอนด์ฮิลล์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8747978,
        "lng": -79.4266122
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.4316122,
        43.8697978,
        -79.4216122,
        43.8797978
      ]
    },
    {
      "id": 2917,
      "name": "Chilpancingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5542149,
        "lng": -99.5048416
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -99.6648416,
        17.3942149,
        -99.3448416,
        17.7142149
      ]
    },
    {
      "id": 2918,
      "name": "Gorontalo",
      "names": {
        "zh": "哥伦打洛"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.7186174,
        "lng": 122.4555927
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        121.1612292,
        -0.0665628,
        123.5519226,
        1.3647141
      ]
    },
    {
      "id": 2919,
      "name": "Guanajuato",
      "names": {
        "zh": "瓜纳华托",
        "th": "กวานาวาโต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0158848,
        "lng": -101.25284
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -101.41284,
        20.8558848,
        -101.09284,
        21.1758848
      ]
    },
    {
      "id": 2920,
      "name": "Peterborough",
      "names": {
        "zh": "彼得伯勒",
        "ja": "ピーターバラ",
        "th": "ปีเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5725769,
        "lng": -0.2427336
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.4027336,
        52.4125769,
        -0.0827336,
        52.7325769
      ]
    },
    {
      "id": 2921,
      "name": "Grand Prairie",
      "names": {
        "es": "Pradera",
        "zh": "大草原",
        "de": "Grand Prärie",
        "id": "Padang rumput",
        "ja": "グランドプレーリー",
        "th": "แกรนด์ทุ่งหญ้า",
        "pt": "Grand pradaria"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7459645,
        "lng": -96.9977846
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.0811697,
        32.498644,
        -96.924017,
        32.8165316
      ]
    },
    {
      "id": 2922,
      "name": "Quelimane",
      "names": {
        "zh": "克利马内"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -17.87861,
        "lng": 36.88833
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        36.86833,
        -17.89861,
        36.90833,
        -17.85861
      ]
    },
    {
      "id": 2923,
      "name": "Tebessa",
      "names": {
        "fr": "Tébessa",
        "zh": "特贝萨"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4035956,
        "lng": 8.1212322
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        7.9594469,
        35.3194033,
        8.1951709,
        35.477636
      ]
    },
    {
      "id": 2924,
      "name": "Kumagaya",
      "names": {
        "zh": "熊谷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1472472,
        "lng": 139.3886141
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.289606,
        36.069633,
        139.4466167,
        36.254374
      ]
    },
    {
      "id": 2925,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2807069,
        "lng": -61.4645896
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -61.5018095,
        10.245889,
        -61.4328029,
        10.3146523
      ]
    },
    {
      "id": 2926,
      "name": "Al Jahra'",
      "names": {
        "es": "Al Jahra '",
        "fr": "Al Jahra '",
        "de": "Al jahrra '",
        "it": "Al Jahra '",
        "id": "Al Jahra '",
        "ja": "アルハーラ '",
        "th": "อัลจารา '",
        "pt": "Al Jahra '"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.3474636,
        "lng": 47.6722054
      },
      "country": "Kuwait",
      "importance": 3,
      "bbox": [
        47.6598047,
        29.335352,
        47.6867491,
        29.3609486
      ]
    },
    {
      "id": 2927,
      "name": "Mahbubnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.7434538,
        "lng": 77.9923191
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.8323191,
        16.5834538,
        78.1523191,
        16.9034538
      ]
    },
    {
      "id": 2928,
      "name": "La Plata",
      "names": {
        "zh": "拉普拉塔",
        "de": "LA-Plata",
        "ja": "ラプラタ",
        "th": "ลาพลาตา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.9206797,
        "lng": -57.9537638
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -57.9940921,
        -34.9539303,
        -57.9131672,
        -34.8875541
      ]
    },
    {
      "id": 2929,
      "name": "San Juan Sacatepequez",
      "names": {
        "fr": "San Juan Sacatepequezez",
        "zh": "圣胡安sacatepequez",
        "id": "San Juan Sacatepequezz",
        "th": "ซานฮวน Sacatepequez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.71889,
        "lng": -90.64417
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.66417,
        14.69889,
        -90.62417,
        14.73889
      ]
    },
    {
      "id": 2930,
      "name": "Yamaguchi",
      "names": {
        "zh": "山口",
        "ja": "山口",
        "th": "ยามากูชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1781317,
        "lng": 131.4737077
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        131.292017,
        33.8126851,
        131.795511,
        34.504507
      ]
    },
    {
      "id": 2931,
      "name": "Muar",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 2.0425046,
        "lng": 102.5658852
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        102.4058852,
        1.8825046,
        102.7258852,
        2.2025046
      ]
    },
    {
      "id": 2932,
      "name": "Cap-Haitien",
      "names": {
        "fr": "Cap-haïtien",
        "zh": "海地角",
        "ja": "キャップ-haitien",
        "th": "หมวก-haitien"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.7595236,
        "lng": -72.2008068
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -72.3608068,
        19.5995236,
        -72.0408068,
        19.9195236
      ]
    },
    {
      "id": 2933,
      "name": "Oakville",
      "names": {
        "zh": "奥克维尔",
        "ja": "オークヴィル",
        "th": "โอก"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.455214,
        "lng": -79.6820931
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.6870931,
        43.450214,
        -79.6770931,
        43.460214
      ]
    },
    {
      "id": 2934,
      "name": "Sunrise Manor",
      "names": {
        "zh": "日出庄园",
        "it": "Padrone di alba",
        "ja": "日の出のマナー",
        "th": "คฤหาสน์พระอาทิตย์ขึ้น",
        "pt": "Mansão do nascer do sol"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1830875,
        "lng": -115.02796403836633
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.1161404,
        36.107579,
        -114.984796,
        36.258311
      ]
    },
    {
      "id": 2935,
      "name": "Godoy Cruz",
      "names": {
        "ja": "ゴドイクルーズ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.9214836,
        "lng": -68.8398063
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -68.8398563,
        -32.9215336,
        -68.8397563,
        -32.9214336
      ]
    },
    {
      "id": 2936,
      "name": "Bukan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.521,
        "lng": 46.2089
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        46.1889,
        36.501,
        46.2289,
        36.541
      ]
    },
    {
      "id": 2937,
      "name": "Hyesan",
      "names": {
        "zh": "惠山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 41.40167,
        "lng": 128.17778
      },
      "country": "North Korea",
      "importance": 2,
      "bbox": [
        128.15778,
        41.38167,
        128.19778,
        41.42167
      ]
    },
    {
      "id": 2938,
      "name": "Kielce",
      "names": {
        "zh": "凯尔采",
        "ja": "キェルツェ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8719903,
        "lng": 20.6310488
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        20.5105231,
        50.7932894,
        20.7206279,
        50.9150826
      ]
    },
    {
      "id": 2939,
      "name": "Aracatuba",
      "names": {
        "zh": "阿拉萨图巴",
        "pt": "Araçatuba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.207992,
        "lng": -50.4390225
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.899,
        -21.454,
        -50.342,
        -20.83
      ]
    },
    {
      "id": 2940,
      "name": "Castanhal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.2927031,
        "lng": -47.9223903
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.0360432,
        -1.4925035,
        -47.6384284,
        -1.0381901
      ]
    },
    {
      "id": 2941,
      "name": "Guanare",
      "names": {
        "zh": "瓜纳雷"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.0407855,
        "lng": -69.7412593
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -69.9012593,
        8.8807855,
        -69.5812593,
        9.2007855
      ]
    },
    {
      "id": 2942,
      "name": "Waco",
      "names": {
        "zh": "韦科",
        "th": "เวโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.5491899,
        "lng": -97.1474628
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.329176,
        31.446727,
        -97.0447115,
        31.6603867
      ]
    },
    {
      "id": 2943,
      "name": "Basildon",
      "names": {
        "zh": "巴塞尔顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5754602,
        "lng": 0.4757363
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.3750985,
        51.5298243,
        0.5687684,
        51.6512393
      ]
    },
    {
      "id": 2944,
      "name": "Beylikduzu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.988316,
        "lng": 28.644556
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.59475,
        40.957142,
        28.700127,
        41.018586
      ]
    },
    {
      "id": 2945,
      "name": "Leganes",
      "names": {
        "es": "Leganés",
        "fr": "Leganés",
        "zh": "莱冈斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3281942,
        "lng": -3.76527
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.818441,
        40.2924944,
        -3.7208913,
        40.3655479
      ]
    },
    {
      "id": 2946,
      "name": "Madiun",
      "names": {
        "zh": "茉莉芬"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.6290837,
        "lng": 111.5168819
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        111.498768,
        -7.6649093,
        111.5664368,
        -7.59485
      ]
    },
    {
      "id": 2947,
      "name": "General Mariano Alvarez",
      "names": {
        "fr": "Général Mariano Alvarez",
        "zh": "玛利亚诺·阿尔瓦雷斯",
        "it": "Generale Mariano Alvarez",
        "ja": "一般的なマリアーノアルバレス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2969269,
        "lng": 121.00649507466174
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.006319,
        14.2967111,
        121.0067077,
        14.2971271
      ]
    },
    {
      "id": 2948,
      "name": "Bago",
      "names": {
        "zh": "巴格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5376299,
        "lng": 122.8352802
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7902123,
        10.4113894,
        123.1558602,
        10.6489398
      ]
    },
    {
      "id": 2949,
      "name": "Valera",
      "names": {
        "zh": "瓦莱拉",
        "ja": "バレラ",
        "th": "วาเลร่า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3148173,
        "lng": -70.6081655
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.7681655,
        9.1548173,
        -70.4481655,
        9.4748173
      ]
    },
    {
      "id": 2950,
      "name": "Bournemouth",
      "names": {
        "zh": "伯恩茅斯",
        "ja": "ボーンマス",
        "th": "มัธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7201514,
        "lng": -1.8799118
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.9199118,
        50.6801514,
        -1.8399118,
        50.7601514
      ]
    },
    {
      "id": 2951,
      "name": "Pasuruan",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.6399593,
        "lng": 112.9070632
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        112.8682708,
        -7.6862722,
        112.9527569,
        -7.6158289
      ]
    },
    {
      "id": 2952,
      "name": "Armavir",
      "names": {
        "zh": "阿尔马维尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.9993585,
        "lng": 41.1294061
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        41.0379628,
        44.950355,
        41.1938669,
        45.0320915
      ]
    },
    {
      "id": 2953,
      "name": "Mwene-Ditu",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -7,
        "lng": 23.45
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        23.43,
        -7.02,
        23.47,
        -6.98
      ]
    },
    {
      "id": 2954,
      "name": "Balakovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.021619,
        "lng": 47.7905757
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        47.7905257,
        52.021569,
        47.7906257,
        52.021669
      ]
    },
    {
      "id": 2955,
      "name": "Donostia",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3224219,
        "lng": -1.9838889
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -2.0868082,
        43.2178373,
        -1.8878839,
        43.3381594
      ]
    },
    {
      "id": 2956,
      "name": "Aydin",
      "names": {
        "zh": "艾登"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8483767,
        "lng": 27.8435878
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        27.6835878,
        37.6883767,
        28.0035878,
        38.0083767
      ]
    },
    {
      "id": 2957,
      "name": "Batu",
      "names": {
        "zh": "巴图",
        "ja": "バトゥ",
        "th": "บาตู"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.8711667,
        "lng": 112.5269482
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        112.4739303,
        -7.9410699,
        112.5949935,
        -7.7238778
      ]
    },
    {
      "id": 2958,
      "name": "Son Tay",
      "names": {
        "es": "Hijo tay",
        "fr": "Fils tay",
        "zh": "儿子tay",
        "de": "Sohn Tay",
        "it": "Figlio tay",
        "ja": "息子のティー",
        "th": "ลูกชายเทย์",
        "pt": "Filho Tay"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.13778,
        "lng": 105.505
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        105.485,
        21.11778,
        105.525,
        21.15778
      ]
    },
    {
      "id": 2959,
      "name": "Anjomachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9667,
        "lng": 137.0833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2960,
      "name": "Salinas",
      "names": {
        "zh": "萨利纳斯",
        "ja": "サリナス",
        "th": "ซาลินาส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.6744117,
        "lng": -121.655037
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.691914,
        36.639017,
        -121.5739908,
        36.7342482
      ]
    },
    {
      "id": 2961,
      "name": "Malaybalay",
      "names": {
        "zh": "马来巴来"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.1550421,
        "lng": 125.1305726
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.9222279,
        7.9443801,
        125.4132356,
        8.3361357
      ]
    },
    {
      "id": 2962,
      "name": "Ferraz de Vasconcelos",
      "names": {
        "ja": "フェラズデブサンセロス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5410723,
        "lng": -46.368605
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.368655,
        -23.5411223,
        -46.368555,
        -23.5410223
      ]
    },
    {
      "id": 2963,
      "name": "Santa Barbara d'Oeste",
      "names": {
        "es": "Santa Bárbara d'Oeste",
        "zh": "圣巴巴拉d'oeste",
        "it": "Santa Barbara D'Eeste",
        "ja": "サンタバーバラデのエステ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7542539,
        "lng": -47.4137884
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.5309297,
        -22.9068053,
        -47.340118,
        -22.699372
      ]
    },
    {
      "id": 2964,
      "name": "Chibia",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -15.1853322,
        "lng": 13.689893
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.3992688,
        -15.7266212,
        14.2856654,
        -14.6779204
      ]
    },
    {
      "id": 2965,
      "name": "Odawara",
      "names": {
        "zh": "小田原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.263676,
        "lng": 139.150229
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.058912,
        35.177906,
        139.2940787,
        35.32992
      ]
    },
    {
      "id": 2966,
      "name": "Kishiwada",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4603225,
        "lng": 135.3781003
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.3731003,
        34.4553225,
        135.3831003,
        34.4653225
      ]
    },
    {
      "id": 2967,
      "name": "Saddiqabad",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.3048519,
        "lng": 70.135849
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        70.1352592,
        28.3042633,
        70.1364109,
        28.3054831
      ]
    },
    {
      "id": 2968,
      "name": "Rybinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.050373,
        "lng": 38.834637
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.6489319,
        58.0019681,
        38.9750495,
        58.1210869
      ]
    },
    {
      "id": 2969,
      "name": "Chongju",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.6929225,
        "lng": 125.2101297
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.0934756,
        39.3518746,
        125.378068,
        39.8498985
      ]
    },
    {
      "id": 2970,
      "name": "Cachoeiro de Itapemirim",
      "names": {
        "es": "Cachoeiro de itapemirimim",
        "ja": "カチョーイ・デ・イタペミリム"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.848084,
        "lng": -41.11129
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.3889889,
        -20.9534712,
        -41.0061849,
        -20.606018
      ]
    },
    {
      "id": 2971,
      "name": "Nandyal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.475632,
        "lng": 78.480557
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.470912,
        15.463591,
        78.484219,
        15.48231
      ]
    },
    {
      "id": 2972,
      "name": "Hagen",
      "names": {
        "zh": "哈根",
        "ja": "ハーゲン",
        "th": "ฮาเก็น"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3582945,
        "lng": 7.473296
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.3759137,
        51.2648764,
        7.5989002,
        51.4185677
      ]
    },
    {
      "id": 2973,
      "name": "Potosi",
      "names": {
        "es": "Potosí",
        "fr": "Potosí",
        "zh": "波托西",
        "de": "Potosí",
        "ja": "ポトシ州",
        "th": "โปโตซี"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -19.5892896,
        "lng": -65.7534713
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -65.9635081,
        -19.8052453,
        -65.5474049,
        -19.2626351
      ]
    },
    {
      "id": 2974,
      "name": "Ngaoundere",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 7.3211536,
        "lng": 13.5878214
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        13.4278214,
        7.1611536,
        13.7478214,
        7.4811536
      ]
    },
    {
      "id": 2975,
      "name": "Pak Kret",
      "names": {
        "ja": "パックケット",
        "th": "ปากเกร็ด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9120701,
        "lng": 100.4974327
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.488825,
        13.877657,
        100.567605,
        13.9557869
      ]
    },
    {
      "id": 2976,
      "name": "Waterbury",
      "names": {
        "zh": "沃特伯里",
        "th": "วอเตอร์เบอรี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5538091,
        "lng": -73.0438362
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.0938539,
        41.51422,
        -72.956789,
        41.615197
      ]
    },
    {
      "id": 2977,
      "name": "Parakou",
      "names": {
        "zh": "帕拉库"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 9.3400159,
        "lng": 2.6278258
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        2.4808043,
        9.2328678,
        2.7717943,
        9.4419358
      ]
    },
    {
      "id": 2978,
      "name": "Donghua",
      "names": {
        "zh": "东华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2182869,
        "lng": 106.6492871
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.4892871,
        35.0582869,
        106.8092871,
        35.3782869
      ]
    },
    {
      "id": 2979,
      "name": "Mutare",
      "names": {
        "zh": "穆塔雷"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.974656,
        "lng": 32.670473
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        32.510473,
        -19.134656,
        32.830473,
        -18.814656
      ]
    },
    {
      "id": 2980,
      "name": "Narsingdi",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 23.9197152,
        "lng": 90.7152981
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        90.5552981,
        23.7597152,
        90.8752981,
        24.0797152
      ]
    },
    {
      "id": 2981,
      "name": "Braga",
      "names": {
        "zh": "布拉加",
        "ja": "ブラガ",
        "th": "บรากา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5452457,
        "lng": -8.4202993
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.4402993,
        41.5252457,
        -8.4002993,
        41.5652457
      ]
    },
    {
      "id": 2982,
      "name": "Araure",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.56009,
        "lng": -69.21475
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.23475,
        9.54009,
        -69.19475,
        9.58009
      ]
    },
    {
      "id": 2983,
      "name": "Digos",
      "names": {
        "zh": "迪戈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.74972,
        "lng": 125.35722
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.33722,
        6.72972,
        125.37722,
        6.76972
      ]
    },
    {
      "id": 2984,
      "name": "Pinar del Rio",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4167422,
        "lng": -83.7002582
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -83.8265312,
        22.1697381,
        -83.4441287,
        22.5373434
      ]
    },
    {
      "id": 2985,
      "name": "Clarksville",
      "names": {
        "zh": "克拉克斯维尔",
        "ja": "クラークスヴィル",
        "th": "คลาร์กสวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.5277607,
        "lng": -87.3588703
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.482366,
        36.4786409,
        -87.2055556,
        36.6417648
      ]
    },
    {
      "id": 2986,
      "name": "Numazu",
      "names": {
        "ja": "沼津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.094699,
        "lng": 138.866742
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.6350207,
        34.9231333,
        138.924342,
        35.219243
      ]
    },
    {
      "id": 2987,
      "name": "Raiganj",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.619286,
        "lng": 88.128463
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.10449,
        25.59281,
        88.14449,
        25.63281
      ]
    },
    {
      "id": 2988,
      "name": "Selcuklu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8768663,
        "lng": 32.4873945
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.0703052,
        37.8701904,
        32.9932376,
        38.3266919
      ]
    },
    {
      "id": 2989,
      "name": "Tachikawa",
      "names": {
        "zh": "立川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.724463,
        "lng": 139.404766
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.352326,
        35.682761,
        139.446119,
        35.745135
      ]
    },
    {
      "id": 2990,
      "name": "Prabumulih",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.4382688,
        "lng": 104.2310181
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        104.0948863,
        -3.6292112,
        104.3488789,
        -3.3163732
      ]
    },
    {
      "id": 2991,
      "name": "Angra dos Reis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0063966,
        "lng": -44.316326
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.4212296,
        -23.0998954,
        -44.1629799,
        -22.9282585
      ]
    },
    {
      "id": 2992,
      "name": "San Francisco de Macoris",
      "names": {
        "zh": "旧金山de Macoris",
        "ja": "サンフランシスコデマコリス",
        "th": "ซานฟรานซิสโกเดอแมคโอริส",
        "pt": "São Francisco de Macoris"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.2972812,
        "lng": -70.2533206
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -70.3710363,
        19.1553406,
        -70.0144629,
        19.5226644
      ]
    },
    {
      "id": 2993,
      "name": "Sobral",
      "names": {
        "zh": "索布拉尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.6879135,
        "lng": -40.3456372
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.7236252,
        -4.1428044,
        -39.840626,
        -3.5702547
      ]
    },
    {
      "id": 2994,
      "name": "Xiashi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.1999387,
        "lng": 120.0089254
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.9889254,
        29.1799387,
        120.0289254,
        29.2199387
      ]
    },
    {
      "id": 2995,
      "name": "Turku",
      "names": {
        "zh": "图尔库",
        "ja": "トゥルク"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4517531,
        "lng": 22.2670522
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        22.0661599,
        60.3335222,
        22.4581649,
        60.7372962
      ]
    },
    {
      "id": 2996,
      "name": "Guatire",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4684588,
        "lng": -66.5431115
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.6036648,
        10.3329264,
        -66.4553946,
        10.55567
      ]
    },
    {
      "id": 2997,
      "name": "Iquique",
      "names": {
        "zh": "伊基克",
        "th": "อีกีเก"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -20.2140657,
        "lng": -70.1524646
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -70.2108791,
        -21.4389984,
        -69.8188876,
        -20.060522
      ]
    },
    {
      "id": 2998,
      "name": "Laayoune",
      "names": {
        "fr": "Laâyoune",
        "id": "Laayoune Hassan I"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 27.154512,
        "lng": -13.1953921
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -13.2497249,
        27.0982188,
        -13.1126104,
        27.1851764
      ]
    },
    {
      "id": 2999,
      "name": "Cedar Rapids",
      "names": {
        "fr": "Cèdre rapides",
        "zh": "雪松急流",
        "de": "CEDAR-Rapids",
        "it": "Rapids di cedro",
        "ja": "杉の急流"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9758872,
        "lng": -91.6704053
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -91.7745806,
        41.8615994,
        -91.5510589,
        42.0689041
      ]
    },
    {
      "id": 3000,
      "name": "Kofu",
      "names": {
        "zh": "甲府"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6672064,
        "lng": 138.5690088
      },
      "country": "Japan",
      "importance": 1,
      "bbox": [
        138.5689588,
        35.6671564,
        138.5690588,
        35.6672564
      ]
    },
    {
      "id": 3001,
      "name": "San Diego",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンディエゴ",
        "th": "ซานดิเอโก"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.3326328,
        "lng": -73.1796691
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -73.5932257,
        9.8652725,
        -73.1185101,
        10.3575075
      ]
    },
    {
      "id": 3002,
      "name": "Gharyan",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.1715873,
        "lng": 13.0211451
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        12.8611451,
        32.0115873,
        13.1811451,
        32.3315873
      ]
    },
    {
      "id": 3003,
      "name": "Barakpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.766842,
        "lng": 88.379924
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.333179,
        22.74941,
        88.413354,
        22.811672
      ]
    },
    {
      "id": 3004,
      "name": "Tottori",
      "names": {
        "zh": "鸟取",
        "ja": "鳥取",
        "th": "ทตโตะริ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4938792,
        "lng": 134.2258625
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.2258125,
        35.4938292,
        134.2259125,
        35.4939292
      ]
    },
    {
      "id": 3005,
      "name": "Chaedok",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 40.79881,
        "lng": 129.36023
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 3006,
      "name": "Joetsu",
      "names": {
        "ja": "上越"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.1478816,
        "lng": 138.2359501
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.7845494,
        36.940891,
        138.568896,
        37.5260318
      ]
    },
    {
      "id": 3007,
      "name": "Sioux Falls",
      "names": {
        "fr": "Sioux tombe",
        "zh": "Sioux瀑布",
        "de": "Sioux fällt",
        "it": "Sioux cade",
        "ja": "スーフォールズ",
        "th": "น้ำตก Sioux"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.539387,
        "lng": -96.728952
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.84889,
        43.464296,
        -96.601274,
        43.638098
      ]
    },
    {
      "id": 3008,
      "name": "Calbayog City",
      "names": {
        "es": "Ciudad de Calbayog",
        "zh": "卡巴亚城市",
        "id": "Kota Calbayog",
        "ja": "カルバヨグシティ",
        "th": "เมืองคาร์บาโย่",
        "pt": "Cidade de Calbayog"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.0668,
        "lng": 124.5962
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5762,
        12.0468,
        124.6162,
        12.0868
      ]
    },
    {
      "id": 3009,
      "name": "Sultanbeyli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9670242,
        "lng": 29.2671314
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        29.2447211,
        40.9317408,
        29.3119449,
        41.0048762
      ]
    },
    {
      "id": 3010,
      "name": "Kairouan",
      "names": {
        "zh": "凯鲁万"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.6714485,
        "lng": 10.101039200183855
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.1008318,
        35.6712571,
        10.1012647,
        35.6716073
      ]
    },
    {
      "id": 3011,
      "name": "Cabo Frio",
      "names": {
        "de": "Cabo-Frio"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8804369,
        "lng": -42.0189227
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.1221906,
        -22.9331308,
        -41.8890111,
        -22.7942753
      ]
    },
    {
      "id": 3012,
      "name": "Shendi",
      "names": {
        "zh": "圣地"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 16.6884963,
        "lng": 33.432286
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.748357,
        16.0329851,
        35.3035358,
        17.327201
      ]
    },
    {
      "id": 3013,
      "name": "Kayapinar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3118235,
        "lng": 39.2041584
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.1841584,
        38.2918235,
        39.2241584,
        38.3318235
      ]
    },
    {
      "id": 3014,
      "name": "Shibin al Kawm",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.5545106,
        "lng": 31.0097923
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.8497923,
        30.3945106,
        31.1697923,
        30.7145106
      ]
    },
    {
      "id": 3015,
      "name": "Ed Damazin",
      "names": {
        "zh": "德达纳顿",
        "ja": "エドダマジン",
        "th": "เอ็ด Damazin"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.8073636,
        "lng": 34.3656298
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        33.1383225,
        10.827894,
        34.5784466,
        12.3121264
      ]
    },
    {
      "id": 3016,
      "name": "Khujand",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 40.2844189,
        "lng": 69.6191669
      },
      "country": "Tajikistan",
      "importance": 1,
      "bbox": [
        69.5612523,
        40.2623896,
        69.6740681,
        40.3316825
      ]
    },
    {
      "id": 3017,
      "name": "Reims",
      "names": {
        "zh": "兰斯",
        "ja": "ランス",
        "th": "ไรมส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2577886,
        "lng": 4.031926
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.9858192,
        49.2039352,
        4.1296955,
        49.303187
      ]
    },
    {
      "id": 3018,
      "name": "Dunhuang",
      "names": {
        "zh": "敦煌",
        "ja": "敦煌",
        "th": "ตุนหวง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.141091,
        "lng": 94.669189
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        94.6420149322,
        40.1226776317,
        94.6820149322,
        40.1626776317
      ]
    },
    {
      "id": 3019,
      "name": "Huntington",
      "names": {
        "zh": "亨廷顿",
        "ja": "ハンティントン",
        "th": "ฮันติงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.4192496,
        "lng": -82.445154
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.5317857,
        38.3759913,
        -82.353193,
        38.4396988
      ]
    },
    {
      "id": 3020,
      "name": "Mahesana",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.6015557,
        "lng": 72.3867981
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.2267981,
        23.4415557,
        72.5467981,
        23.7615557
      ]
    },
    {
      "id": 3021,
      "name": "Modena",
      "names": {
        "fr": "Modène",
        "zh": "摩德纳",
        "ja": "モデナ",
        "th": "โมเดนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.6458885,
        "lng": 10.9255707
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.7820845,
        44.5629531,
        11.0122721,
        44.7424432
      ]
    },
    {
      "id": 3022,
      "name": "Guri",
      "names": {
        "zh": "古里"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.6032517,
        "lng": 127.1433747
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.1383747,
        37.5982517,
        127.1483747,
        37.6082517
      ]
    },
    {
      "id": 3023,
      "name": "Getafe",
      "names": {
        "zh": "赫塔菲",
        "ja": "ヘタフェ",
        "th": "เกตาเฟ่"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3070639,
        "lng": -3.7331808
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.7617372,
        40.2663607,
        -3.5700587,
        40.3346954
      ]
    },
    {
      "id": 3024,
      "name": "Jamaame",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 0.07222,
        "lng": 42.75056
      },
      "country": "Somalia",
      "importance": 3,
      "bbox": [
        42.59056,
        -0.08778,
        42.91056,
        0.23222
      ]
    },
    {
      "id": 3025,
      "name": "Breda",
      "names": {
        "zh": "布雷达",
        "ja": "ブレダ",
        "th": "เบรดา"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5887845,
        "lng": 4.7760237
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6860063,
        51.4855482,
        4.8457301,
        51.643198
      ]
    },
    {
      "id": 3026,
      "name": "Basel",
      "names": {
        "es": "Basilea",
        "fr": "Bâle",
        "zh": "巴塞尔",
        "it": "Basilea",
        "ja": "バーゼル",
        "th": "บาเซิล",
        "pt": "Basileia"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5581077,
        "lng": 7.5878261
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        7.5546608,
        47.519294,
        7.6341406,
        47.5898969
      ]
    },
    {
      "id": 3027,
      "name": "Semnan",
      "names": {
        "zh": "塞姆南"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.5834074,
        "lng": 53.3882906
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        53.3340284,
        35.5385292,
        53.46076,
        35.6202563
      ]
    },
    {
      "id": 3028,
      "name": "Temirtau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 50.0587825,
        "lng": 72.9535681
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        72.8358689,
        49.9973711,
        73.2310455,
        50.1908617
      ]
    },
    {
      "id": 3029,
      "name": "Toyokawa",
      "names": {
        "zh": "丰川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.827644,
        "lng": 137.378586
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.256148,
        34.7781665,
        137.461359,
        34.909746
      ]
    },
    {
      "id": 3030,
      "name": "Passo Fundo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.2550598,
        "lng": -52.3966606
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.6756482,
        -28.4118702,
        -52.2134322,
        -28.1201264
      ]
    },
    {
      "id": 3031,
      "name": "Yei",
      "names": {
        "zh": "耶伊"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 4.0941656,
        "lng": 30.6756882
      },
      "country": "South Sudan",
      "importance": 3,
      "bbox": [
        30.5156882,
        3.9341656,
        30.8356882,
        4.2541656
      ]
    },
    {
      "id": 3032,
      "name": "Ontario",
      "names": {
        "fr": "L'Ontario",
        "zh": "安大略省",
        "ja": "オンタリオ州",
        "th": "ออนแทรีโอ",
        "pt": "Ontário"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.065846,
        "lng": -117.64843
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.6829386,
        33.9753136,
        -117.5240678,
        34.0933563
      ]
    },
    {
      "id": 3033,
      "name": "Luzhang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.9809335,
        "lng": 98.8162815
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.6603325,
        25.8361853,
        98.8516594,
        26.105176
      ]
    },
    {
      "id": 3034,
      "name": "Al Qamishli",
      "names": {
        "ja": "アルカミリ",
        "th": "อัล Qamishli"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 37.05215,
        "lng": 41.23142
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        41.21142,
        37.03215,
        41.25142,
        37.07215
      ]
    },
    {
      "id": 3035,
      "name": "Hagerstown",
      "names": {
        "zh": "黑格斯敦"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6430455,
        "lng": -77.7191081
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.7730904,
        39.6000289,
        -77.6722751,
        39.6737328
      ]
    },
    {
      "id": 3036,
      "name": "Fardis",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.72928,
        "lng": 50.98589
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        50.96589,
        35.70928,
        51.00589,
        35.74928
      ]
    },
    {
      "id": 3037,
      "name": "Chuo-ku",
      "names": {
        "zh": "中央-ku",
        "ja": "中央区-ku",
        "th": "ชูโอ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.666255,
        "lng": 139.775565
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.758555,
        35.6402011,
        139.7931533,
        35.6966147
      ]
    },
    {
      "id": 3038,
      "name": "Erie",
      "names": {
        "fr": "Érié",
        "zh": "伊利",
        "ja": "エリー",
        "th": "อีรี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1294712,
        "lng": -80.0852695
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1386671,
        42.0772638,
        -80.0035087,
        42.1536477
      ]
    },
    {
      "id": 3039,
      "name": "Vancouver",
      "names": {
        "zh": "温哥华",
        "ja": "バンクーバー",
        "th": "แวนคูเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.6306954,
        "lng": -122.6744557
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.7745635,
        45.5773887,
        -122.46456,
        45.698004
      ]
    },
    {
      "id": 3040,
      "name": "Nampa",
      "names": {
        "zh": "楠帕",
        "th": "นัมปา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.5737361,
        "lng": -116.559631
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -116.6441456,
        43.52346,
        -116.473523,
        43.655884
      ]
    },
    {
      "id": 3041,
      "name": "Geelong",
      "names": {
        "zh": "吉朗",
        "ja": "ジーロング",
        "th": "จี"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -38.1442691,
        "lng": 144.355077
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        144.350077,
        -38.1492691,
        144.360077,
        -38.1392691
      ]
    },
    {
      "id": 3042,
      "name": "Fianarantsoa",
      "names": {
        "zh": "菲亚纳兰楚阿"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -21.4478254,
        "lng": 47.0904116
      },
      "country": "Madagascar",
      "importance": 1,
      "bbox": [
        47.0854116,
        -21.4528254,
        47.0954116,
        -21.4428254
      ]
    },
    {
      "id": 3043,
      "name": "Banjar",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.3695648,
        "lng": 108.5415225
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        108.469541,
        -7.444532,
        108.6647909,
        -7.3320124
      ]
    },
    {
      "id": 3044,
      "name": "Siguiri",
      "names": {
        "zh": "锡吉里"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.4195265,
        "lng": -9.1751513
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -9.3351513,
        11.2595265,
        -9.0151513,
        11.5795265
      ]
    },
    {
      "id": 3045,
      "name": "Severodvinsk",
      "names": {
        "zh": "北德文斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.5733363,
        "lng": 39.850579805009076
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.8500053,
        64.5730543,
        39.8512339,
        64.5736065
      ]
    },
    {
      "id": 3046,
      "name": "Batala",
      "names": {
        "zh": "巴塔拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.8193025,
        "lng": 75.1999941
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.0399941,
        31.6593025,
        75.3599941,
        31.9793025
      ]
    },
    {
      "id": 3047,
      "name": "Rzeszow",
      "names": {
        "zh": "热舒夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.04016,
        "lng": 21.995315
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        21.858684,
        49.932583,
        22.094148,
        50.081668
      ]
    },
    {
      "id": 3048,
      "name": "Bhusaval",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.04728,
        "lng": 75.78867
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3049,
      "name": "Itajai",
      "names": {
        "es": "Itajaí",
        "zh": "伊塔雅伊",
        "pt": "Itajaí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9046787,
        "lng": -48.6552979
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.869,
        -27.096,
        -48.6213454,
        -26.8378087
      ]
    },
    {
      "id": 3050,
      "name": "Trondheim",
      "names": {
        "zh": "特隆赫姆",
        "ja": "トロンハイム",
        "th": "ทรอนไฮม์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 63.4364504,
        "lng": 10.3980667
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.3930667,
        63.4314504,
        10.4030667,
        63.4414504
      ]
    },
    {
      "id": 3051,
      "name": "Cuango",
      "names": {
        "zh": "宽果"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.14583,
        "lng": 18.04453
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        18.02453,
        -9.16583,
        18.06453,
        -9.12583
      ]
    },
    {
      "id": 3052,
      "name": "Aqtau",
      "names": {
        "zh": "阿克套",
        "th": "อัคตาอู"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.6353364,
        "lng": 51.168222
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        51.1230388,
        43.6229977,
        51.2201111,
        43.7041684
      ]
    },
    {
      "id": 3053,
      "name": "Nis",
      "names": {
        "zh": "新谢克尔",
        "ja": "NISの"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.3211301,
        "lng": 21.8959232
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7087987,
        43.208945,
        22.1756915,
        43.4825933
      ]
    },
    {
      "id": 3054,
      "name": "Bahraigh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.57429,
        "lng": 81.59474
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3055,
      "name": "Narashino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.673573,
        "lng": 140.015474
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.02152,
        35.66184,
        140.06152,
        35.70184
      ]
    },
    {
      "id": 3056,
      "name": "Abakan",
      "names": {
        "zh": "阿巴甘"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.717011,
        "lng": 91.436999
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        91.386528,
        53.688972,
        91.48642,
        53.757526
      ]
    },
    {
      "id": 3057,
      "name": "Burlington",
      "names": {
        "zh": "伯灵顿",
        "ja": "バーリントン",
        "th": "เบอร์ลิงตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.3248924,
        "lng": -79.7966835
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.9592898,
        43.2862845,
        -79.713939,
        43.4765533
      ]
    },
    {
      "id": 3058,
      "name": "Potsdam",
      "names": {
        "zh": "波茨坦",
        "ja": "ポツダム",
        "th": "พอทสดัม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4009309,
        "lng": 13.0591397
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        12.8867757,
        52.3420411,
        13.1682033,
        52.5146876
      ]
    },
    {
      "id": 3059,
      "name": "Pinrang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.7932568,
        "lng": 119.6528692
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        119.4314228,
        -4.0437676,
        119.7860687,
        -3.2703163
      ]
    },
    {
      "id": 3060,
      "name": "Sorsogon",
      "names": {
        "zh": "索索贡"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9707848,
        "lng": 124.0052543
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.8452543,
        12.8107848,
        124.1652543,
        13.1307848
      ]
    },
    {
      "id": 3061,
      "name": "Dourados",
      "names": {
        "zh": "朵拉多"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2206145,
        "lng": -54.812208
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -55.52,
        -22.4414779,
        -54.200886,
        -21.8792714
      ]
    },
    {
      "id": 3062,
      "name": "Spartanburg",
      "names": {
        "zh": "斯巴达堡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.9498007,
        "lng": -81.9320157
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.998107,
        34.90146,
        -81.846736,
        34.982502
      ]
    },
    {
      "id": 3063,
      "name": "Meram",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8679527,
        "lng": 32.4946514
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.9705087,
        37.4556843,
        32.6169094,
        37.9502886
      ]
    },
    {
      "id": 3064,
      "name": "Braila",
      "names": {
        "zh": "布勒伊拉"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.2716092,
        "lng": 27.9742932
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.908531,
        45.2310041,
        28.022869,
        45.3134918
      ]
    },
    {
      "id": 3065,
      "name": "Gastonia",
      "names": {
        "th": "แกสโตเนีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2622654,
        "lng": -81.1838186
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.3212551,
        35.161407,
        -81.0828777,
        35.3120312
      ]
    },
    {
      "id": 3066,
      "name": "Amadora",
      "names": {
        "zh": "阿马多拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.755541,
        "lng": -9.224299
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.250302,
        38.728546,
        -9.199905,
        38.785251
      ]
    },
    {
      "id": 3067,
      "name": "Fort Lauderdale",
      "names": {
        "zh": "劳德代尔堡",
        "ja": "フォートローダーデール"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1250245,
        "lng": -80.1455889
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1456389,
        26.1249745,
        -80.1455389,
        26.1250745
      ]
    },
    {
      "id": 3068,
      "name": "Uji",
      "names": {
        "zh": "宇治",
        "ja": "宇治"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8904111,
        "lng": 135.8007079
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.7957079,
        34.8854111,
        135.8057079,
        34.8954111
      ]
    },
    {
      "id": 3069,
      "name": "Nova Friburgo",
      "names": {
        "ja": "ノヴァフリブルゴ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2800004,
        "lng": -42.5325303
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.6224836,
        -22.3615325,
        -42.4691139,
        -22.1765746
      ]
    },
    {
      "id": 3070,
      "name": "Tonk",
      "names": {
        "zh": "坦克",
        "ja": "トンク",
        "th": "โสเภณี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.1612436,
        "lng": 75.7858103
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.6258103,
        26.0012436,
        75.9458103,
        26.3212436
      ]
    },
    {
      "id": 3071,
      "name": "Reggio di Calabria",
      "names": {
        "de": "Reggio di Kalabrien"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.1216519,
        "lng": 15.6488424
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        15.6487924,
        38.1216019,
        15.6488924,
        38.1217019
      ]
    },
    {
      "id": 3072,
      "name": "Berazategui",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7632677,
        "lng": -58.2116604
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.2674063,
        -34.8005097,
        -58.1552902,
        -34.7344894
      ]
    },
    {
      "id": 3073,
      "name": "Khowy",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.5503,
        "lng": 44.9521
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3074,
      "name": "Sirsa",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.53721,
        "lng": 75.036134
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.99702,
        29.500666,
        75.079617,
        29.562828
      ]
    },
    {
      "id": 3075,
      "name": "Tangail",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 24.24984,
        "lng": 89.91655
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        89.89655,
        24.22984,
        89.93655,
        24.26984
      ]
    },
    {
      "id": 3076,
      "name": "Jaunpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.7472,
        "lng": 82.689
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.529,
        25.5872,
        82.849,
        25.9072
      ]
    },
    {
      "id": 3077,
      "name": "Dosquebradas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8339551,
        "lng": -75.6713013
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7186372,
        4.7918206,
        -75.623481,
        4.8892592
      ]
    },
    {
      "id": 3078,
      "name": "York",
      "names": {
        "zh": "纽约",
        "ja": "ヨーク",
        "th": "นิวยอร์ก",
        "pt": "Iorque"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.9590555,
        "lng": -1.0815361
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.2415361,
        53.7990555,
        -0.9215361,
        54.1190555
      ]
    },
    {
      "id": 3079,
      "name": "Petropavlovsk-Kamchatskiy",
      "names": {
        "zh": "彼得罗巴甫洛夫斯克罗巴甫洛夫斯克",
        "ja": "ペトロパブロフスクカムチャツキー",
        "th": "เปโตรปัฟลอฟสค์-คัมชัตสกี"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.0199838,
        "lng": 158.6471356
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        158.5219917,
        52.8850294,
        158.985402,
        53.1267573
      ]
    },
    {
      "id": 3080,
      "name": "Phan Rang-Thap Cham",
      "names": {
        "th": "พานรังรัง - ทับ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.5769829,
        "lng": 108.9865391
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        108.9203258,
        11.5300925,
        109.0351591,
        11.6748733
      ]
    },
    {
      "id": 3081,
      "name": "Epe",
      "names": {
        "zh": "珍珠棉"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.5858219,
        "lng": 3.9753453
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        3.6186401,
        6.366684,
        4.3509056,
        6.6838142
      ]
    },
    {
      "id": 3082,
      "name": "Sittwe",
      "names": {
        "zh": "实兑"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.139197,
        "lng": 92.8970859
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        92.7598628,
        20.1139679,
        92.9278853,
        20.3009048
      ]
    },
    {
      "id": 3083,
      "name": "Dam Dam",
      "names": {
        "es": "Presa de presa",
        "fr": "Barrage",
        "zh": "大坝",
        "de": "Dammdamm",
        "it": "Diga",
        "id": "Bendungan",
        "ja": "ダムダム",
        "th": "เขื่อน",
        "pt": "Barragem"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.627373,
        "lng": 88.403868
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.40286,
        22.61341,
        88.44286,
        22.65341
      ]
    },
    {
      "id": 3084,
      "name": "Jima",
      "names": {
        "zh": "岛",
        "ja": "岛"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 7.6756152,
        "lng": 36.8478779
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        36.6878779,
        7.5156152,
        37.0078779,
        7.8356152
      ]
    },
    {
      "id": 3085,
      "name": "Lhokseumawe",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 5.1789659,
        "lng": 97.1480544
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        97.0260901,
        5.0837903,
        97.1827626,
        5.241721
      ]
    },
    {
      "id": 3086,
      "name": "Roxas City",
      "names": {
        "es": "Ciudad de Roxas",
        "zh": "罗克斯市",
        "it": "Città di Roxas",
        "id": "Kota roxas",
        "ja": "ロキサスシティ",
        "th": "เมือง Roxas",
        "pt": "Cidade de roxas"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.58528,
        "lng": 122.75111
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.73111,
        11.56528,
        122.77111,
        11.60528
      ]
    },
    {
      "id": 3087,
      "name": "Funtua",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.525544,
        "lng": 7.314924
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.287996,
        11.489765,
        7.345111,
        11.548537
      ]
    },
    {
      "id": 3088,
      "name": "Turbo",
      "names": {
        "zh": "涡轮增压",
        "ja": "ターボ",
        "th": "เทอร์โบ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.09368,
        "lng": -76.72691
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.74691,
        8.07368,
        -76.70691,
        8.11368
      ]
    },
    {
      "id": 3089,
      "name": "Hinthada",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 17.6457472,
        "lng": 95.4609517
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.3009517,
        17.4857472,
        95.6209517,
        17.8057472
      ]
    },
    {
      "id": 3090,
      "name": "La Rioja",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -29.4127411,
        "lng": -66.8558795
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -66.9320473,
        -29.4803328,
        -66.7766151,
        -29.3795581
      ]
    },
    {
      "id": 3091,
      "name": "Madanapalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.5557979,
        "lng": 78.500872
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.340872,
        13.3957979,
        78.660872,
        13.7157979
      ]
    },
    {
      "id": 3092,
      "name": "Palopo",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.9996306,
        "lng": 120.1920679
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        120.0474357,
        -3.0848964,
        120.2330061,
        -2.8863444
      ]
    },
    {
      "id": 3093,
      "name": "Ayacucho",
      "names": {
        "zh": "阿亚库乔"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.1604269,
        "lng": -74.2256973
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -74.2893918,
        -13.20596,
        -74.2117533,
        -13.0674629
      ]
    },
    {
      "id": 3094,
      "name": "Obuase",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.20228,
        "lng": -1.66796
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 3095,
      "name": "Nijmegen",
      "names": {
        "zh": "奈梅亨",
        "ja": "ナイメーヘン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8474946,
        "lng": 5.8637771
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.7576206,
        51.7905891,
        5.9083469,
        51.8946209
      ]
    },
    {
      "id": 3096,
      "name": "San Luis Rio Colorado",
      "names": {
        "zh": "圣路易斯里奥科罗拉多",
        "ja": "サンルイスリオコロラド州",
        "th": "ซานหลุยส์ริโอโคโลราโด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.4822164,
        "lng": -114.7816893
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -114.9416893,
        32.3222164,
        -114.6216893,
        32.6422164
      ]
    },
    {
      "id": 3097,
      "name": "Alleppey",
      "names": {
        "zh": "阿勒皮"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 9.5029729,
        "lng": 76.3518694
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.3518194,
        9.5029229,
        76.3519194,
        9.5030229
      ]
    },
    {
      "id": 3098,
      "name": "Boma",
      "names": {
        "zh": "博马",
        "ja": "ボマ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.85,
        "lng": 13.05
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        12.6170608,
        -6.0117002,
        13.4813,
        -5.5145645
      ]
    },
    {
      "id": 3099,
      "name": "Tiaret",
      "names": {
        "zh": "提亚雷特"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.0588663,
        "lng": 1.0525435
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        1.0524935,
        35.0588163,
        1.0525935,
        35.0589163
      ]
    },
    {
      "id": 3100,
      "name": "Tyre",
      "names": {
        "es": "Neumáticos",
        "fr": "Pneus",
        "zh": "轮胎",
        "de": "Reifen",
        "it": "Pneumatico",
        "id": "Ban",
        "ja": "タイヤ",
        "th": "ยาง",
        "pt": "Pneu"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.2721211,
        "lng": 35.1964023
      },
      "country": "Lebanon",
      "importance": 2,
      "bbox": [
        35.1905419,
        33.2199129,
        35.2298069,
        33.2771935
      ]
    },
    {
      "id": 3101,
      "name": "Saarbrucken",
      "names": {
        "es": "Saarbrücken",
        "fr": "Sarrebruck",
        "zh": "萨尔布吕肯",
        "de": "Saarbrücken",
        "it": "Saarbrücken",
        "id": "Saarbrücken",
        "ja": "ザールブリュッケン",
        "pt": "Saarbrücken"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.234362,
        "lng": 6.996379
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        6.8276387,
        49.1721922,
        7.1380203,
        49.3220676
      ]
    },
    {
      "id": 3102,
      "name": "Purwakarta",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.5527796,
        "lng": 107.4465323
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        107.4415323,
        -6.5577796,
        107.4515323,
        -6.5477796
      ]
    },
    {
      "id": 3103,
      "name": "Longjing",
      "names": {
        "zh": "龙井"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.76411,
        "lng": 129.4205702
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        128.9309138,
        42.3604888,
        129.8038527,
        43.0590486
      ]
    },
    {
      "id": 3104,
      "name": "Split",
      "names": {
        "zh": "分裂",
        "ja": "分割",
        "th": "แยก"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.5078665,
        "lng": 16.4422312
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        16.4421016,
        43.5068287,
        16.4424026,
        43.5084138
      ]
    },
    {
      "id": 3105,
      "name": "Ilheus",
      "names": {
        "es": "Ilhéus",
        "pt": "Ilhéus"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -14.792599,
        "lng": -39.0453843
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.5505925,
        -15.1122917,
        -38.9948291,
        -14.4196422
      ]
    },
    {
      "id": 3106,
      "name": "Toulon",
      "names": {
        "zh": "土伦",
        "it": "Tolone",
        "ja": "ツーロン",
        "th": "ตูลง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1257311,
        "lng": 5.9304919
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.8795028,
        43.1010435,
        5.9873841,
        43.171494
      ]
    },
    {
      "id": 3107,
      "name": "Klerksdorp",
      "names": {
        "zh": "克莱克斯多普"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.8625383,
        "lng": 26.6656814
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        26.5056814,
        -27.0225383,
        26.8256814,
        -26.7025383
      ]
    },
    {
      "id": 3108,
      "name": "Lorain",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.444665,
        "lng": -82.185388
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.292137,
        41.404719,
        -82.102004,
        41.485224
      ]
    },
    {
      "id": 3109,
      "name": "Barra Mansa",
      "names": {
        "zh": "巴拉曼萨",
        "ja": "バラマンサ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5427739,
        "lng": -44.1691871
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.2790288,
        -22.6308383,
        -44.084693,
        -22.4769682
      ]
    },
    {
      "id": 3110,
      "name": "Tongjiang",
      "names": {
        "zh": "通江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.6504656,
        "lng": 132.5018508
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        132.3418508,
        47.4904656,
        132.6618508,
        47.8104656
      ]
    },
    {
      "id": 3111,
      "name": "Murfreesboro",
      "names": {
        "th": "เมอร์ฟรีสโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.8460396,
        "lng": -86.3921096
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.570986,
        35.762729,
        -86.325279,
        35.960638
      ]
    },
    {
      "id": 3112,
      "name": "High Point",
      "names": {
        "es": "Punto álgido",
        "fr": "Point fort",
        "zh": "高点",
        "de": "Hoher Punkt",
        "it": "Punto alto",
        "id": "Pokok",
        "ja": "ハイポイント",
        "th": "จุดสูงสุด",
        "pt": "Ponto alto"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9556924,
        "lng": -80.0053176
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.0722241,
        35.9119964,
        -79.8963731,
        36.0969655
      ]
    },
    {
      "id": 3113,
      "name": "Hamm",
      "names": {
        "zh": "哈姆",
        "ja": "ハム",
        "th": "แฮมม์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6785747,
        "lng": 7.807713
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.802713,
        51.6735747,
        7.812713,
        51.6835747
      ]
    },
    {
      "id": 3114,
      "name": "Divo",
      "names": {
        "ja": "ディーヴォ"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.8294045,
        "lng": -5.3592251
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -5.5192251,
        5.6694045,
        -5.1992251,
        5.9894045
      ]
    },
    {
      "id": 3115,
      "name": "Tarija",
      "names": {
        "zh": "塔里哈"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -21.5338794,
        "lng": -64.7343559
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -64.8943559,
        -21.6938794,
        -64.5743559,
        -21.3738794
      ]
    },
    {
      "id": 3116,
      "name": "Al `Arish",
      "names": {
        "es": "Al 'arish",
        "zh": "Al` arish",
        "de": "Al` Arish",
        "id": "Al `wasst",
        "ja": "Al`アニャシュ",
        "th": "อัล `arish",
        "pt": "Arish ã"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1515017,
        "lng": 33.8310891
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        33.8310391,
        31.1514517,
        33.8311391,
        31.1515517
      ]
    },
    {
      "id": 3117,
      "name": "Guli",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8637898,
        "lng": 118.6897361
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.6265359,
        31.7849795,
        118.7411514,
        31.9366774
      ]
    },
    {
      "id": 3118,
      "name": "Urayasu",
      "names": {
        "zh": "浦安",
        "ja": "浦安"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6530518,
        "lng": 139.9018495
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.8575735,
        35.5212514,
        139.9858814,
        35.672741
      ]
    },
    {
      "id": 3119,
      "name": "Bida",
      "names": {
        "zh": "比达",
        "th": "บิดา"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.083333,
        "lng": 6.016667
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.972456,
        9.0626327,
        6.0588622,
        9.1375284
      ]
    },
    {
      "id": 3120,
      "name": "Gliwice",
      "names": {
        "zh": "格利维采"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.294113,
        "lng": 18.6657306
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.5429915,
        50.2272112,
        18.7563234,
        50.3751413
      ]
    },
    {
      "id": 3121,
      "name": "Paita",
      "names": {
        "zh": "帕伊塔"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -5.0851637,
        "lng": -81.1136793
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -81.1958052,
        -5.388461,
        -80.9610136,
        -4.924504
      ]
    },
    {
      "id": 3122,
      "name": "Bade",
      "names": {
        "zh": "巴德",
        "ja": "バーデ",
        "th": "รับสั่ง"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9286511,
        "lng": 121.2846555
      },
      "country": "Taiwan",
      "importance": 3,
      "bbox": [
        121.2517965,
        24.9088764,
        121.3316103,
        24.9870489
      ]
    },
    {
      "id": 3123,
      "name": "Newport News",
      "names": {
        "es": "Newsport noticias",
        "zh": "纽波特新闻",
        "id": "Berita Newport",
        "ja": "ニューポートニュース",
        "th": "ข่าวนิวพอร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0914429,
        "lng": -76.5213892
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.646093,
        36.930691,
        -76.36161,
        37.2206658
      ]
    },
    {
      "id": 3124,
      "name": "Townsville",
      "names": {
        "zh": "汤斯维尔",
        "ja": "タウンズビル",
        "th": "ทาวน์สวิลล์"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -19.2569391,
        "lng": 146.8239537
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        146.6639537,
        -19.4169391,
        146.9839537,
        -19.0969391
      ]
    },
    {
      "id": 3125,
      "name": "Langsa",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 4.4730892,
        "lng": 97.9681841
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        97.897542,
        4.406532,
        98.083722,
        4.5627632
      ]
    },
    {
      "id": 3126,
      "name": "Dagupan City",
      "names": {
        "zh": "达格普兰市",
        "de": "Dagupan-Stadt",
        "id": "Kota Dagupan",
        "ja": "ダグパン市",
        "th": "เมืองดากมูล",
        "pt": "Cidade dagupã"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.04333,
        "lng": 120.334793
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.29875,
        16.008933,
        120.377899,
        16.108713
      ]
    },
    {
      "id": 3127,
      "name": "Teziutlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8170336,
        "lng": -97.3601226
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -97.4001226,
        19.7770336,
        -97.3201226,
        19.8570336
      ]
    },
    {
      "id": 3128,
      "name": "Salalah",
      "names": {
        "zh": "塞拉莱"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 17.0174945,
        "lng": 54.1010923
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        53.9410923,
        16.8574945,
        54.2610923,
        17.1774945
      ]
    },
    {
      "id": 3129,
      "name": "Salatiga",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.3302642,
        "lng": 110.4995353
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        110.4687583,
        -7.3888161,
        110.5344291,
        -7.2859635
      ]
    },
    {
      "id": 3130,
      "name": "Matosinhos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1835856,
        "lng": -8.686684806233082
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7058012,
        41.1714901,
        -8.6644609,
        41.1957376
      ]
    },
    {
      "id": 3131,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.7878809,
        "lng": 175.281788
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        175.1843007,
        -37.846039,
        175.3448766,
        -37.6993298
      ]
    },
    {
      "id": 3132,
      "name": "Birjand",
      "names": {
        "zh": "比尔詹德"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.86628,
        "lng": 59.22114
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        59.20114,
        32.84628,
        59.24114,
        32.88628
      ]
    },
    {
      "id": 3133,
      "name": "Lin'an",
      "names": {
        "zh": "临安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6155009,
        "lng": 102.8233972
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.7833972,
        23.5755009,
        102.8633972,
        23.6555009
      ]
    },
    {
      "id": 3134,
      "name": "Chicoloapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4162929,
        "lng": -98.9014367
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.9385806,
        19.3571789,
        -98.7992124,
        19.4360033
      ]
    },
    {
      "id": 3135,
      "name": "Vellore",
      "names": {
        "zh": "韦洛尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.9212026,
        "lng": 79.1330617
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.1329538,
        12.9190771,
        79.1333485,
        12.9235207
      ]
    },
    {
      "id": 3136,
      "name": "Bejaia",
      "names": {
        "zh": "贝贾亚"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7511783,
        "lng": 5.0643687
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        4.908666,
        36.7056237,
        5.1074723,
        36.833653
      ]
    },
    {
      "id": 3137,
      "name": "Rancho Cucamonga",
      "names": {
        "ja": "Cucamonga Rancho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1033192,
        "lng": -117.575173
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.6368438,
        34.0771981,
        -117.4789146,
        34.1795363
      ]
    },
    {
      "id": 3138,
      "name": "Norilsk",
      "names": {
        "zh": "诺里尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 69.3444054,
        "lng": 88.2096151
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        87.7406774,
        69.3155633,
        88.4197915,
        69.5007702
      ]
    },
    {
      "id": 3139,
      "name": "Titagarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.741396,
        "lng": 88.372727
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.35326,
        22.72252,
        88.39326,
        22.76252
      ]
    },
    {
      "id": 3140,
      "name": "Nossa Senhora do Socorro",
      "names": {
        "ja": "Nossa SenhoraはSocorroです"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.8553115,
        "lng": -37.1264863
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.318,
        -10.9285452,
        -37.0288701,
        -10.8177719
      ]
    },
    {
      "id": 3141,
      "name": "Baranavichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.1327,
        "lng": 26.0139
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        25.9939,
        53.1127,
        26.0339,
        53.1527
      ]
    },
    {
      "id": 3142,
      "name": "Santa Maria Texmelucan",
      "names": {
        "fr": "Santa Maria Texmeucan",
        "zh": "圣玛丽亚Texmelucan",
        "de": "Santa Maria Texmelcan",
        "ja": "サンタマリアテキサスメルカン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.294167,
        "lng": -98.537778
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -98.557778,
        19.274167,
        -98.517778,
        19.314167
      ]
    },
    {
      "id": 3143,
      "name": "Kamakurayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3182667,
        "lng": 139.5214089
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5114089,
        35.3082667,
        139.5314089,
        35.3282667
      ]
    },
    {
      "id": 3144,
      "name": "Hemet",
      "names": {
        "zh": "赫米特",
        "th": "เฮเมต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7475203,
        "lng": -116.9719684
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.071575,
        33.671235,
        -116.908716,
        33.780394
      ]
    },
    {
      "id": 3145,
      "name": "Yangmei",
      "names": {
        "zh": "杨梅"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9143522,
        "lng": 121.1465072
      },
      "country": "Taiwan",
      "importance": 3,
      "bbox": [
        121.1415072,
        24.9093522,
        121.1515072,
        24.9193522
      ]
    },
    {
      "id": 3146,
      "name": "Les Cayes",
      "names": {},
      "countryCode": "HT",
      "latLng": {
        "lat": 18.2,
        "lng": -73.75
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -73.77,
        18.18,
        -73.73,
        18.22
      ]
    },
    {
      "id": 3147,
      "name": "Ciudad Valles",
      "names": {
        "ja": "シウダードバリュー"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.9916551,
        "lng": -99.0102967
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1702967,
        21.8316551,
        -98.8502967,
        22.1516551
      ]
    },
    {
      "id": 3148,
      "name": "Santander",
      "names": {
        "zh": "桑坦德",
        "ja": "サンタンデール",
        "th": "ซานทานแดร์"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.4620412,
        "lng": -3.8099719
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -3.8890298,
        43.434424,
        -3.7632476,
        43.4948754
      ]
    },
    {
      "id": 3149,
      "name": "Kadugli",
      "names": {
        "zh": "卡杜格利"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.0085934,
        "lng": 29.7155751
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        29.5555751,
        10.8485934,
        29.8755751,
        11.1685934
      ]
    },
    {
      "id": 3150,
      "name": "Piraeus",
      "names": {
        "es": "El Pireo",
        "fr": "Le Pirée",
        "zh": "比雷埃夫斯",
        "de": "Piräus",
        "it": "Pireo",
        "ja": "ピレウス"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9431594,
        "lng": 23.6470593
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.4870593,
        37.7831594,
        23.8070593,
        38.1031594
      ]
    },
    {
      "id": 3151,
      "name": "Linhares",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.3800908,
        "lng": -40.060306
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.5305862,
        -19.6871169,
        -39.6887863,
        -18.9950484
      ]
    },
    {
      "id": 3152,
      "name": "Ciudad del Carmen",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.651739,
        "lng": -91.81492
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.97492,
        18.491739,
        -91.65492,
        18.811739
      ]
    },
    {
      "id": 3153,
      "name": "Letpandan",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 17.78664,
        "lng": 95.75076
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.73076,
        17.76664,
        95.77076,
        17.80664
      ]
    },
    {
      "id": 3154,
      "name": "Zabrze",
      "names": {
        "zh": "扎布热",
        "ja": "ザブジェ",
        "th": "เมานต์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3052986,
        "lng": 18.7871281
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.7821281,
        50.3002986,
        18.7921281,
        50.3102986
      ]
    },
    {
      "id": 3155,
      "name": "Almada",
      "names": {
        "zh": "阿尔马达"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.680621,
        "lng": -9.159596
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.18011,
        38.65965,
        -9.14011,
        38.69965
      ]
    },
    {
      "id": 3156,
      "name": "Plzen",
      "names": {
        "zh": "皮尔森"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.74633155,
        "lng": 13.378013301889663
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        13.3327373,
        49.7171633,
        13.4182487,
        49.7754621
      ]
    },
    {
      "id": 3157,
      "name": "Saint-Louis",
      "names": {
        "zh": "圣路易",
        "ja": "サンルイ",
        "th": "เซนต์หลุยส์"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 16.09154125,
        "lng": -14.73377864216372
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.5284371,
        15.4924564,
        -13.7021979,
        16.6919712
      ]
    },
    {
      "id": 3158,
      "name": "Rio Verde",
      "names": {
        "zh": "里约佛得角",
        "ja": "リオヴェルデ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.7921255,
        "lng": -50.9191219
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.7307464,
        -18.347,
        -50.355,
        -17.0898436
      ]
    },
    {
      "id": 3159,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.050096,
        "lng": -121.99059
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.3220529,
        36.8488209,
        -121.5823066,
        37.2876789
      ]
    },
    {
      "id": 3160,
      "name": "Ait Melloul",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.3387947,
        "lng": -9.5044701
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.5487693,
        30.3080613,
        -9.4475127,
        30.3704413
      ]
    },
    {
      "id": 3161,
      "name": "Comitan",
      "names": {
        "es": "Comitán",
        "zh": "科米坦"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2441097,
        "lng": -92.1348514
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.2948514,
        16.0841097,
        -91.9748514,
        16.4041097
      ]
    },
    {
      "id": 3162,
      "name": "Danbury",
      "names": {
        "zh": "丹伯里",
        "ja": "ダンベリー",
        "th": "แดนบิวรี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.394817,
        "lng": -73.4540111
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.54229,
        41.3269554,
        -73.400825,
        41.4638388
      ]
    },
    {
      "id": 3163,
      "name": "Peoria",
      "names": {
        "zh": "皮奥里亚",
        "ja": "ピオリア",
        "th": "พีโอเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5806115,
        "lng": -112.237294
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.447299,
        33.550699,
        -112.2025617,
        33.9553
      ]
    },
    {
      "id": 3164,
      "name": "Orekhovo-Borisovo Yuzhnoye",
      "names": {
        "zh": "奥雷库沃博士湾玉津福"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.60288,
        "lng": 37.73276
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3165,
      "name": "Oeiras",
      "names": {
        "zh": "奥埃拉什"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6925777,
        "lng": -9.3123076
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.329675,
        38.6731469,
        -9.2112112,
        38.7519505
      ]
    },
    {
      "id": 3166,
      "name": "Catumbela",
      "names": {
        "zh": "卡通贝拉"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.4316255,
        "lng": 13.5396044
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.5346044,
        -12.4366255,
        13.5446044,
        -12.4266255
      ]
    },
    {
      "id": 3167,
      "name": "Eregli",
      "names": {
        "zh": "埃雷利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2795516,
        "lng": 31.4229672
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.3916223,
        41.0866345,
        31.8631919,
        41.4024516
      ]
    },
    {
      "id": 3168,
      "name": "Oceanside",
      "names": {
        "zh": "欧申赛德",
        "ja": "オーシャンサイド",
        "th": "โอเชียนไซด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1958696,
        "lng": -117.379483
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.4398002,
        33.1325655,
        -117.237891,
        33.3000031
      ]
    },
    {
      "id": 3169,
      "name": "Harar",
      "names": {
        "zh": "哈勒尔"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.3118397,
        "lng": 42.1284445
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        41.9684445,
        9.1518397,
        42.2884445,
        9.4718397
      ]
    },
    {
      "id": 3170,
      "name": "Burgos",
      "names": {
        "zh": "布尔戈斯",
        "ja": "ブルゴス",
        "th": "บูร์โกส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.343926,
        "lng": -3.696977
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -3.7943724,
        42.2852754,
        -3.5853379,
        42.4042841
      ]
    },
    {
      "id": 3171,
      "name": "Shibirghan",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.66757,
        "lng": 65.7529
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        65.7329,
        36.64757,
        65.7729,
        36.68757
      ]
    },
    {
      "id": 3172,
      "name": "Chandannagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.8639237,
        "lng": 88.3703496
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.2103496,
        22.7039237,
        88.5303496,
        23.0239237
      ]
    },
    {
      "id": 3173,
      "name": "Olsztyn",
      "names": {
        "zh": "奥尔什丁"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.771028,
        "lng": 20.494758
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        20.36649,
        53.724125,
        20.566645,
        53.828985
      ]
    },
    {
      "id": 3174,
      "name": "Cuddalore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.7462161,
        "lng": 79.75552388831885
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.7549025,
        11.7457019,
        79.7564875,
        11.7469126
      ]
    },
    {
      "id": 3175,
      "name": "Lagos de Moreno",
      "names": {
        "zh": "拉各斯德莫雷诺",
        "ja": "ラゴスデモエノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3567286,
        "lng": -101.935294
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.095294,
        21.1967286,
        -101.775294,
        21.5167286
      ]
    },
    {
      "id": 3176,
      "name": "Maragheh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.3913128,
        "lng": 46.2435751
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        46.1877818,
        37.3410309,
        46.3044948,
        37.4169576
      ]
    },
    {
      "id": 3177,
      "name": "Tebingtinggi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 3.3269801,
        "lng": 99.1685796
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        99.1685296,
        3.3269301,
        99.1686296,
        3.3270301
      ]
    },
    {
      "id": 3178,
      "name": "Alcorcon",
      "names": {
        "es": "Alcorcón",
        "fr": "Alcorcón",
        "de": "Alcorcón"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3492998,
        "lng": -3.8284295
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.878787,
        40.3239373,
        -3.7879224,
        40.4030979
      ]
    },
    {
      "id": 3179,
      "name": "Saint-Etienne",
      "names": {
        "zh": "圣艾蒂安",
        "ja": "サン・etienne",
        "th": "เซนต์ etienne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.4401467,
        "lng": 4.3873058
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.2442969,
        45.3715387,
        4.4888583,
        45.4767081
      ]
    },
    {
      "id": 3180,
      "name": "Chetumal",
      "names": {
        "zh": "切图马尔"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5081738,
        "lng": -88.297144
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -88.3577228,
        18.4902097,
        -88.2611386,
        18.5635056
      ]
    },
    {
      "id": 3181,
      "name": "Sirjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.43704,
        "lng": 55.68023
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        55.66023,
        29.41704,
        55.70023,
        29.45704
      ]
    },
    {
      "id": 3182,
      "name": "Mexico",
      "names": {
        "fr": "Mexique",
        "zh": "墨西哥",
        "de": "Mexiko",
        "it": "Messico",
        "id": "Meksiko",
        "ja": "メキシコ",
        "th": "เม็กซิโก",
        "pt": "México"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 39.1697626,
        "lng": -91.8829484
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -91.923513,
        39.111625,
        -91.801454,
        39.207804
      ]
    },
    {
      "id": 3183,
      "name": "Ludwigshafen",
      "names": {
        "zh": "路德维希港",
        "ja": "ルートヴィヒスハーフェン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4775595,
        "lng": 8.4341483
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.4291483,
        49.4725595,
        8.4391483,
        49.4825595
      ]
    },
    {
      "id": 3184,
      "name": "Elk Grove",
      "names": {
        "zh": "麋鹿树林",
        "de": "Elchhain",
        "it": "Grove alci",
        "id": "Rusa rusa",
        "ja": "エルクグローブ",
        "pt": "Grove de alces"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.4087993,
        "lng": -121.3716178
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.5316178,
        38.2487993,
        -121.2116178,
        38.5687993
      ]
    },
    {
      "id": 3185,
      "name": "Syzran",
      "names": {
        "zh": "塞兹兰"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.165269,
        "lng": 48.457362
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        48.336674,
        53.138035,
        48.581558,
        53.213097
      ]
    },
    {
      "id": 3186,
      "name": "Carupano",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6759963,
        "lng": -63.2452154
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -63.2452654,
        10.6759463,
        -63.2451654,
        10.6760463
      ]
    },
    {
      "id": 3187,
      "name": "Lubao",
      "names": {
        "zh": "卢巴奥"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9378136,
        "lng": 120.600497
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4903258,
        14.7926988,
        120.6239229,
        14.9865813
      ]
    },
    {
      "id": 3188,
      "name": "Luziania",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -16.2535456,
        "lng": -47.9500276
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.402,
        -17.0063291,
        -47.64,
        -16.1044448
      ]
    },
    {
      "id": 3189,
      "name": "Leon",
      "names": {
        "es": "León",
        "zh": "莱昂",
        "ja": "レオン",
        "th": "ลีออง"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 21.121965,
        "lng": -101.6829766
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -101.8429766,
        20.961965,
        -101.5229766,
        21.281965
      ]
    },
    {
      "id": 3190,
      "name": "Chirala",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.827912,
        "lng": 80.356279
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.322737,
        15.802138,
        80.381733,
        15.849355
      ]
    },
    {
      "id": 3191,
      "name": "San Andres Tuxtla",
      "names": {
        "es": "San Andrés Tuxtla",
        "zh": "圣安德里斯Tuxtla",
        "ja": "サンアンドレスTUXTLA"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4464,
        "lng": -95.213825
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.236772,
        18.418759,
        -95.190572,
        18.464866
      ]
    },
    {
      "id": 3192,
      "name": "Eskisehir",
      "names": {
        "zh": "埃斯基谢希尔",
        "ja": "エスキシェヒル"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7743941,
        "lng": 30.519116
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        30.359116,
        39.6143941,
        30.679116,
        39.9343941
      ]
    },
    {
      "id": 3193,
      "name": "Castellon de la Plana",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 39.9860347,
        "lng": -0.0377354
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -0.1644067,
        39.850294,
        0.6903174,
        40.0644134
      ]
    },
    {
      "id": 3194,
      "name": "Tiantoujiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.993801,
        "lng": 114.06492
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.044821747,
        22.9746595856,
        114.075296361,
        23.0028164236
      ]
    },
    {
      "id": 3195,
      "name": "Bielsko-Biala",
      "names": {
        "zh": "别尔斯克 - 比亚瓦"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.822118,
        "lng": 19.0448936
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.9453771,
        49.7366826,
        19.1321826,
        49.8874404
      ]
    },
    {
      "id": 3196,
      "name": "Piedras Negras",
      "names": {
        "ja": "ピエラスヌグラス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.704596,
        "lng": -100.5167137
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.5900415,
        28.6397011,
        -100.5004128,
        28.7424114
      ]
    },
    {
      "id": 3197,
      "name": "Banha",
      "names": {
        "pt": "O banha"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.4624974,
        "lng": 31.184063
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        31.024063,
        30.3024974,
        31.344063,
        30.6224974
      ]
    },
    {
      "id": 3198,
      "name": "Osmaniye",
      "names": {
        "zh": "奥斯曼尼耶"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0733588,
        "lng": 36.2507673
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.0907673,
        36.9133588,
        36.4107673,
        37.2333588
      ]
    },
    {
      "id": 3199,
      "name": "Bytom",
      "names": {
        "zh": "比托姆"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3470387,
        "lng": 18.9231857
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.7885838,
        50.3177441,
        18.964097,
        50.4137092
      ]
    },
    {
      "id": 3200,
      "name": "Linjiang",
      "names": {
        "zh": "临江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8092486,
        "lng": 126.9128334
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        126.5524363,
        41.5230856,
        127.8289747,
        42.0636084
      ]
    },
    {
      "id": 3201,
      "name": "Ha Long",
      "names": {
        "es": "Ha largo",
        "fr": "Ha longue",
        "zh": "哈哈",
        "de": "Ha lang",
        "it": "A lungo",
        "id": "Ha panjang",
        "th": "ยาว",
        "pt": "Há muito"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.9528365,
        "lng": 107.0800003
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.8255601,
        20.7350218,
        107.2989672,
        21.238988
      ]
    },
    {
      "id": 3202,
      "name": "Deo",
      "names": {
        "zh": "上帝",
        "th": "โอ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.657634,
        "lng": 84.436188
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.425125,
        24.646283,
        84.44942,
        24.672825
      ]
    },
    {
      "id": 3203,
      "name": "Pembroke Pines",
      "names": {
        "es": "Pines Pembroke",
        "fr": "Pemembroke Pines",
        "zh": "Pembroke松树",
        "de": "Pembroke-Kiefern",
        "it": "Pini di Pembroke",
        "th": "เพมโบรกไพน์",
        "pt": "Pinheiros de Pembroke"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.011546,
        "lng": -80.337207
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.24394,
        25.98315,
        -80.20394,
        26.02315
      ]
    },
    {
      "id": 3204,
      "name": "Tlemcen",
      "names": {
        "zh": "特莱姆森"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.881789,
        "lng": -1.316699
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -1.3706815,
        34.8575021,
        -1.2594593,
        34.9352287
      ]
    },
    {
      "id": 3205,
      "name": "Hitachi",
      "names": {
        "zh": "日立",
        "ja": "日立",
        "th": "ฮิตาชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5991225,
        "lng": 140.6504604
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.516685,
        36.4635996,
        140.9559653,
        36.714615
      ]
    },
    {
      "id": 3206,
      "name": "Kindu",
      "names": {
        "zh": "金杜"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -2.9493766,
        "lng": 25.9184499
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        24.4707881,
        -3.3502032,
        26.9405949,
        -1.4037492
      ]
    },
    {
      "id": 3207,
      "name": "Shahin Shahr",
      "names": {
        "zh": "沙林莎拉"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.85788,
        "lng": 51.5529
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.5329,
        32.83788,
        51.5729,
        32.87788
      ]
    },
    {
      "id": 3208,
      "name": "Le Havre",
      "names": {
        "zh": "勒阿弗尔",
        "ja": "ルアーブル",
        "th": "เลออาฟวร์",
        "pt": "Leigo"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4938975,
        "lng": 0.1079732
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.0667992,
        49.4516697,
        0.1955556,
        49.5401463
      ]
    },
    {
      "id": 3209,
      "name": "Vallejo",
      "names": {
        "zh": "瓦列霍",
        "th": "เลโค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1040864,
        "lng": -122.2566367
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.3875532,
        38.0593812,
        -122.16615,
        38.173496
      ]
    },
    {
      "id": 3210,
      "name": "Polomolok",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.2202374,
        "lng": 125.0628101
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.9731993,
        6.1414868,
        125.1896247,
        6.3600441
      ]
    },
    {
      "id": 3211,
      "name": "Banyuwangi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.2094973,
        "lng": 114.3737201
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        114.2137201,
        -8.3694973,
        114.5337201,
        -8.0494973
      ]
    },
    {
      "id": 3212,
      "name": "Albacete",
      "names": {
        "zh": "阿尔巴塞特",
        "ja": "アルバセテ",
        "th": "อัลบาเซเต้"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9950921,
        "lng": -1.8559154
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -2.1803886,
        38.6721754,
        -1.6227933,
        39.1862627
      ]
    },
    {
      "id": 3213,
      "name": "Bidar",
      "names": {
        "zh": "比德尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.9143767,
        "lng": 77.5263568
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.3663568,
        17.7543767,
        77.6863568,
        18.0743767
      ]
    },
    {
      "id": 3214,
      "name": "San Miguel",
      "names": {
        "zh": "圣米格尔",
        "ja": "サンミゲル",
        "th": "ซานมิเกล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 38.0292476,
        "lng": -108.4712533
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -109.0429406,
        37.772072,
        -107.73111,
        38.153099
      ]
    },
    {
      "id": 3215,
      "name": "Moca",
      "names": {
        "zh": "当代艺术馆"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.394647,
        "lng": -70.5261566
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.6106827,
        19.3440775,
        -70.4015695,
        19.6118491
      ]
    },
    {
      "id": 3216,
      "name": "Mulheim",
      "names": {
        "fr": "Mülheim",
        "zh": "米尔海姆",
        "de": "Mülheim",
        "it": "Mülheim",
        "pt": "Mülheim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4272925,
        "lng": 6.8829192
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.8066678,
        51.3507415,
        6.9595443,
        51.47145
      ]
    },
    {
      "id": 3217,
      "name": "Sacaba",
      "names": {
        "zh": "萨卡瓦"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.403834,
        "lng": -66.0402928
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -66.1897115,
        -17.5080102,
        -65.792969,
        -17.1329421
      ]
    },
    {
      "id": 3218,
      "name": "Reggio Emilia",
      "names": {
        "zh": "雷焦艾米利亚",
        "ja": "レジオエミリア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.6978389,
        "lng": 10.6304971
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.4910178,
        44.6244638,
        10.7733414,
        44.7744479
      ]
    },
    {
      "id": 3219,
      "name": "Barrie",
      "names": {
        "zh": "巴里",
        "ja": "バリー",
        "th": "แบร์รี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.3893208,
        "lng": -79.6901302
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.7456508,
        44.2937481,
        -79.5858974,
        44.4245913
      ]
    },
    {
      "id": 3220,
      "name": "Krasnogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.82036,
        "lng": 37.33017
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.31017,
        55.80036,
        37.35017,
        55.84036
      ]
    },
    {
      "id": 3221,
      "name": "Kaolack",
      "names": {},
      "countryCode": "SN",
      "latLng": {
        "lat": 14.138815,
        "lng": -16.076391
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.1641201,
        14.1108487,
        -16.0328748,
        14.2058134
      ]
    },
    {
      "id": 3222,
      "name": "Izumo",
      "names": {
        "zh": "出云",
        "ja": "出雲"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3668891,
        "lng": 132.7548827
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.3645902,
        35.160026,
        132.934414,
        35.7114771
      ]
    },
    {
      "id": 3223,
      "name": "Baliuag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9542259,
        "lng": 120.9009172
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8499294,
        14.9067539,
        120.9222323,
        15.0167684
      ]
    },
    {
      "id": 3224,
      "name": "Niiza",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7931194,
        "lng": 139.5657258
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.516371,
        35.753384,
        139.59414,
        35.825815
      ]
    },
    {
      "id": 3225,
      "name": "Surigao",
      "names": {
        "zh": "苏里高"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.78389,
        "lng": 125.48889
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.46889,
        9.76389,
        125.50889,
        9.80389
      ]
    },
    {
      "id": 3226,
      "name": "Francisco Morato",
      "names": {
        "zh": "弗朗西斯科莫托",
        "ja": "フランシスコモラト",
        "th": "ฟรานซิสโก Morato"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2820771,
        "lng": -46.7424456
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.7474456,
        -23.2870771,
        -46.7374456,
        -23.2770771
      ]
    },
    {
      "id": 3227,
      "name": "Garden Grove",
      "names": {
        "es": "Grove",
        "fr": "Jardin grove",
        "zh": "花园树林",
        "de": "Gartenhain",
        "it": "Giardino Grove",
        "id": "Kebun",
        "ja": "ガーデングローブ",
        "th": "สวนป่า",
        "pt": "Grove de jardim"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7746292,
        "lng": -117.9463717
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0427903,
        33.7324811,
        -117.8942913,
        33.8056927
      ]
    },
    {
      "id": 3228,
      "name": "Volgodonsk",
      "names": {
        "zh": "伏尔加顿斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.5182668,
        "lng": 42.1525935
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.0282451,
        47.4630806,
        42.3914275,
        47.619417
      ]
    },
    {
      "id": 3229,
      "name": "Cuauhtemoc",
      "names": {
        "fr": "Cuauhtémoc",
        "zh": "夸特莫克"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.4009579,
        "lng": -106.8665305
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -107.0265305,
        28.2409579,
        -106.7065305,
        28.5609579
      ]
    },
    {
      "id": 3230,
      "name": "Kohat",
      "names": {
        "zh": "科哈特"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.5967144,
        "lng": 71.4399944
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.2799944,
        33.4367144,
        71.5999944,
        33.7567144
      ]
    },
    {
      "id": 3231,
      "name": "Makurdi",
      "names": {
        "zh": "马库尔迪"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.7312634,
        "lng": 8.538425
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        8.3696613,
        7.5875511,
        8.6659861,
        7.8866663
      ]
    },
    {
      "id": 3232,
      "name": "Kamensk-Ural'skiy",
      "names": {
        "zh": "卡缅斯克-ural'skiy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.40142,
        "lng": 61.93132
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3233,
      "name": "Enterprise",
      "names": {
        "es": "La empresa",
        "fr": "Entreprise",
        "zh": "企业",
        "de": "Unternehmen",
        "id": "Perusahaan",
        "ja": "エンタープライズ",
        "th": "องค์กร",
        "pt": "Empresa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0405373,
        "lng": -115.1899298
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.345114,
        35.9289615,
        -115.1543963,
        36.076892
      ]
    },
    {
      "id": 3234,
      "name": "Loja",
      "names": {
        "zh": "洛哈"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -3.996845,
        "lng": -79.2016661
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.2699455,
        -4.1328625,
        -79.1373786,
        -3.8501062
      ]
    },
    {
      "id": 3235,
      "name": "Medford",
      "names": {
        "zh": "梅德福",
        "ja": "メドフォード",
        "th": "เมดฟอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3264181,
        "lng": -122.8718605
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.9112333,
        42.2887597,
        -122.7771953,
        42.3983852
      ]
    },
    {
      "id": 3236,
      "name": "N'Zerekore",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 7.75624,
        "lng": -8.8179
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -8.8379,
        7.73624,
        -8.7979,
        7.77624
      ]
    },
    {
      "id": 3237,
      "name": "Ussuriysk",
      "names": {
        "zh": "乌苏里斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.797866,
        "lng": 131.949522
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        131.928909,
        43.754833,
        132.017029,
        43.843498
      ]
    },
    {
      "id": 3238,
      "name": "Sakura",
      "names": {
        "zh": "樱花",
        "ja": "さくら",
        "th": "ซากุระ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7234619,
        "lng": 140.2240158
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.126249,
        35.624382,
        140.301303,
        35.766246
      ]
    },
    {
      "id": 3239,
      "name": "Concepcion",
      "names": {
        "es": "Concepción",
        "zh": "康塞普西翁",
        "ja": "コンセプシオン",
        "th": "กอนเซปซิยอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3248528,
        "lng": 120.6553857
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6013217,
        15.2597385,
        120.7559265,
        15.4164438
      ]
    },
    {
      "id": 3240,
      "name": "Uvira",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -3.39534,
        "lng": 29.13779
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        29.11779,
        -3.41534,
        29.15779,
        -3.37534
      ]
    },
    {
      "id": 3241,
      "name": "Tomakomai",
      "names": {
        "zh": "苫小牧",
        "ja": "苫小牧"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.6340602,
        "lng": 141.6055453
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.33905,
        42.401845,
        141.82997,
        42.787717
      ]
    },
    {
      "id": 3242,
      "name": "Portmore",
      "names": {},
      "countryCode": "JM",
      "latLng": {
        "lat": 17.9717917,
        "lng": -76.8738048
      },
      "country": "Jamaica",
      "importance": 3,
      "bbox": [
        -76.9234613,
        17.9292955,
        -76.8587545,
        18.003842
      ]
    },
    {
      "id": 3243,
      "name": "Machilipatnam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.189668,
        "lng": 81.140037
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.099547,
        16.153853,
        81.16211,
        16.216946
      ]
    },
    {
      "id": 3244,
      "name": "Bordj Bou Arreridj",
      "names": {
        "fr": "BORDJ BOU ARERIDJ",
        "de": "Bordj Bou AreRIDJ"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.0624911,
        "lng": 4.7468729
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        4.7418729,
        36.0574911,
        4.7518729,
        36.0674911
      ]
    },
    {
      "id": 3245,
      "name": "Malayer",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.2922623,
        "lng": 48.8215749
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.7801823,
        34.2641609,
        48.8514821,
        34.3317541
      ]
    },
    {
      "id": 3246,
      "name": "Cary",
      "names": {
        "zh": "卡里",
        "ja": "ケアリー",
        "th": "แครี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7882893,
        "lng": -78.7812081
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.9537968,
        35.6443755,
        -78.7294596,
        35.8968752
      ]
    },
    {
      "id": 3247,
      "name": "Kluang",
      "names": {
        "zh": "居銮"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.0323472,
        "lng": 103.3190766
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        103.1065176,
        1.70962,
        103.6417843,
        2.3892842
      ]
    },
    {
      "id": 3248,
      "name": "Novocherkassk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.422727,
        "lng": 40.092668
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.9695615676,
        47.3326592541,
        40.2709416895,
        47.5116441725
      ]
    },
    {
      "id": 3249,
      "name": "Metouia",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.9589064,
        "lng": 10.0014342
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        9.9814342,
        33.9389064,
        10.0214342,
        33.9789064
      ]
    },
    {
      "id": 3250,
      "name": "Nishio",
      "names": {
        "zh": "西尾"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8638775,
        "lng": 137.0578183
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.0577683,
        34.8638275,
        137.0578683,
        34.8639275
      ]
    },
    {
      "id": 3251,
      "name": "Marysville",
      "names": {
        "zh": "马里斯维尔",
        "th": "แมรีส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.0517429,
        "lng": -122.1768209
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.2865099,
        48.0198434,
        -122.1096063,
        48.1632874
      ]
    },
    {
      "id": 3252,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.3020736,
        "lng": -66.3369577
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -66.4184476,
        -33.3714845,
        -66.2567425,
        -33.235789
      ]
    },
    {
      "id": 3253,
      "name": "Puerto Montt",
      "names": {
        "ja": "プエルトモンテ",
        "th": "เปอร์โตมอนต์"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -41.471798,
        "lng": -72.9395915
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -73.3402414,
        -41.7128191,
        -72.3742285,
        -41.3298025
      ]
    },
    {
      "id": 3254,
      "name": "Corona",
      "names": {
        "zh": "电晕",
        "ja": "コロナ",
        "th": "โคโรนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8752945,
        "lng": -117.566444
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.673177,
        33.8000147,
        -117.4838074,
        33.9161643
      ]
    },
    {
      "id": 3255,
      "name": "Medinipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.4207025,
        "lng": 87.3269963
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.1669963,
        22.2607025,
        87.4869963,
        22.5807025
      ]
    },
    {
      "id": 3256,
      "name": "Oldenburg",
      "names": {
        "zh": "奥尔登堡",
        "ja": "オルデンブルク",
        "th": "โอลเดน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.1389753,
        "lng": 8.2146017
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1295153,
        53.0833161,
        8.3122117,
        53.2047617
      ]
    },
    {
      "id": 3257,
      "name": "Fenglu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.676522,
        "lng": 102.911293
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.8952258,
        24.6609245,
        102.945044,
        24.7089652
      ]
    },
    {
      "id": 3258,
      "name": "Al Marj",
      "names": {
        "de": "Alte Marj",
        "it": "Al marmo",
        "ja": "アルマージュ"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 31.9108728,
        "lng": 21.1477877
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        20.4858394,
        31.0328407,
        21.717186,
        32.9895723
      ]
    },
    {
      "id": 3259,
      "name": "Sosan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.784499,
        "lng": 126.450317
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.3086987,
        36.5767832,
        126.6535811,
        37.0707339
      ]
    },
    {
      "id": 3260,
      "name": "Ocala",
      "names": {
        "zh": "奥卡拉",
        "ja": "オカラ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.1871986,
        "lng": -82.1400923
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.2517933,
        29.1209142,
        -82.0619973,
        29.233731
      ]
    },
    {
      "id": 3261,
      "name": "Uppsala",
      "names": {
        "zh": "乌普萨拉",
        "ja": "ウプサラ",
        "th": "อุปซอลา"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.85131,
        "lng": 17.632732
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        17.549541,
        59.782033,
        17.716841,
        59.909445
      ]
    },
    {
      "id": 3262,
      "name": "Gondomar",
      "names": {
        "zh": "贡多马尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1398199,
        "lng": -8.5326269
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5841105,
        41.0013802,
        -8.3725707,
        41.203318
      ]
    },
    {
      "id": 3263,
      "name": "Jamalpur",
      "names": {
        "zh": "加木尔普"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.9255866,
        "lng": 89.9436684
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        89.7836684,
        24.7655866,
        90.1036684,
        25.0855866
      ]
    },
    {
      "id": 3264,
      "name": "Santa Rita",
      "names": {
        "zh": "圣丽塔",
        "ja": "サンタリタ",
        "th": "ซานตาริต้า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": -3.1411146,
        "lng": -44.3270285
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -44.5774513,
        -3.30847,
        -44.0915781,
        -3.0260144
      ]
    },
    {
      "id": 3265,
      "name": "Warrington",
      "names": {
        "zh": "沃灵顿",
        "ja": "ウォリントン",
        "th": "วอร์ริงตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3899497,
        "lng": -2.5943178
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.6395477,
        53.3675639,
        -2.5353098,
        53.420841
      ]
    },
    {
      "id": 3266,
      "name": "Baramula",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 34.2086903,
        "lng": 74.343498
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.183498,
        34.0486903,
        74.503498,
        34.3686903
      ]
    },
    {
      "id": 3267,
      "name": "Fredericksburg",
      "names": {
        "zh": "弗雷德里克斯堡",
        "ja": "フレデリックスバーグ",
        "th": "เฟรเดอริ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.3031837,
        "lng": -77.4605399
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.5325902,
        38.2701509,
        -77.4467933,
        38.3266378
      ]
    },
    {
      "id": 3268,
      "name": "Oyama",
      "names": {
        "zh": "大山",
        "ja": "大山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.315537,
        "lng": 139.8006952
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.687462,
        36.223315,
        139.91505,
        36.388757
      ]
    },
    {
      "id": 3269,
      "name": "Ambato",
      "names": {
        "zh": "安巴托"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.2422413,
        "lng": -78.6287594
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.6513648,
        -1.3016279,
        -78.5683237,
        -1.2160243
      ]
    },
    {
      "id": 3270,
      "name": "Huich'on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 40.171279,
        "lng": 126.2760271
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.9778845,
        39.9903817,
        126.5704185,
        40.3521096
      ]
    },
    {
      "id": 3271,
      "name": "Mahabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.7651739,
        "lng": 45.7218136
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        45.6972579,
        36.7292332,
        45.7721688,
        36.8073106
      ]
    },
    {
      "id": 3272,
      "name": "Itapecerica da Serra",
      "names": {
        "fr": "Itapeperica da serra",
        "th": "Itapecericaa da Serra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7176303,
        "lng": -46.8492435
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.9378927,
        -23.8047211,
        -46.7687398,
        -23.6679263
      ]
    },
    {
      "id": 3273,
      "name": "Sao Caetano do Sul",
      "names": {
        "fr": "Sao caeetano do sul",
        "th": "เซา caetano do sul",
        "pt": "São Caetano do Sul"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.626304,
        "lng": -46.565675
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.584958,
        -23.65057,
        -46.544646,
        -23.599524
      ]
    },
    {
      "id": 3274,
      "name": "Rustenburg",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.665655,
        "lng": 27.241448
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.081448,
        -25.825655,
        27.401448,
        -25.505655
      ]
    },
    {
      "id": 3275,
      "name": "Basuo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.1052704,
        "lng": 108.6537122
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.6137122,
        19.0652704,
        108.6937122,
        19.1452704
      ]
    },
    {
      "id": 3276,
      "name": "Zlatoust",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.1674213,
        "lng": 59.6792625
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        59.5488923,
        55.0660193,
        59.7914095,
        55.2316403
      ]
    },
    {
      "id": 3277,
      "name": "Haarlem",
      "names": {
        "zh": "哈勒姆",
        "th": "ฮาร์เล็ม"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.379653,
        "lng": 4.64272
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        4.599516,
        52.338906,
        4.686921,
        52.42857
      ]
    },
    {
      "id": 3278,
      "name": "Patra",
      "names": {
        "zh": "帕特雷",
        "ja": "パトラ",
        "th": "ภัทรา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.2498476,
        "lng": 21.7351217
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        21.7301217,
        38.2448476,
        21.7401217,
        38.2548476
      ]
    },
    {
      "id": 3279,
      "name": "Riohacha",
      "names": {
        "zh": "里奥阿查"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 11.5454608,
        "lng": -72.905442
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -72.905492,
        11.5454108,
        -72.905392,
        11.5455108
      ]
    },
    {
      "id": 3280,
      "name": "Catape",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.76667,
        "lng": 15.08333
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.06333,
        -13.78667,
        15.10333,
        -13.74667
      ]
    },
    {
      "id": 3281,
      "name": "Tuguegarao",
      "names": {
        "zh": "土格加劳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.6118858,
        "lng": 121.7300377
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.6786829,
        17.5127757,
        121.8219038,
        17.6948901
      ]
    },
    {
      "id": 3282,
      "name": "Guarapuava",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.3950986,
        "lng": -51.4622016
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.0233681,
        -25.6819783,
        -51.0896787,
        -24.9697331
      ]
    },
    {
      "id": 3283,
      "name": "Gainesville",
      "names": {
        "zh": "盖恩斯维尔",
        "ja": "ゲインズビル",
        "th": "เกนส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2978794,
        "lng": -83.8240663
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.899588,
        34.221172,
        -83.755687,
        34.362897
      ]
    },
    {
      "id": 3284,
      "name": "Itu",
      "names": {
        "es": "UIT",
        "fr": "UIT",
        "zh": "国际电联",
        "ja": "のITU",
        "th": "ไอทียู",
        "pt": "UIT"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2637798,
        "lng": -47.2998462
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.4387655,
        -23.4404243,
        -47.1157532,
        -23.154297
      ]
    },
    {
      "id": 3285,
      "name": "Fatehpur",
      "names": {
        "zh": "法地",
        "pt": "Sikri"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.9167524,
        "lng": 80.8016651
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.7966651,
        25.9117524,
        80.8066651,
        25.9217524
      ]
    },
    {
      "id": 3286,
      "name": "Al Kufah",
      "names": {
        "ja": "アルクフー",
        "th": "อัลคูฟาห์"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.03444,
        "lng": 44.4031125
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        44.2431125,
        31.87444,
        44.5631125,
        32.19444
      ]
    },
    {
      "id": 3287,
      "name": "Kandy",
      "names": {
        "zh": "康提",
        "th": "แคน"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 7.2955,
        "lng": 80.6356
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        80.6156,
        7.2755,
        80.6556,
        7.3155
      ]
    },
    {
      "id": 3288,
      "name": "Ha Tinh",
      "names": {
        "zh": "哈蒂",
        "ja": "ハハチン",
        "th": "ฮ่าฮ่า"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 18.3452745,
        "lng": 105.9019482
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.8599995,
        18.308026,
        105.9433405,
        18.400809
      ]
    },
    {
      "id": 3289,
      "name": "Kimberley",
      "names": {
        "zh": "金伯利",
        "ja": "キンバリー",
        "th": "คิมเบอร์ลีย์"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -28.7383012,
        "lng": 24.7642251
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        24.6733419,
        -28.7844279,
        24.8097763,
        -28.6878933
      ]
    },
    {
      "id": 3290,
      "name": "Tenali",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.239207,
        "lng": 80.645203
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.60021,
        16.204749,
        80.669939,
        16.273878
      ]
    },
    {
      "id": 3291,
      "name": "Iwata",
      "names": {
        "zh": "岩田",
        "ja": "岩田",
        "th": "อิวะตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7101215,
        "lng": 137.8517978
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.8467978,
        34.7051215,
        137.8567978,
        34.7151215
      ]
    },
    {
      "id": 3292,
      "name": "Kismaayo",
      "names": {
        "zh": "基斯麦犹"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": -0.35817,
        "lng": 42.54536
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        42.52536,
        -0.37817,
        42.56536,
        -0.33817
      ]
    },
    {
      "id": 3293,
      "name": "Edirne",
      "names": {
        "zh": "埃迪尔内"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.6759327,
        "lng": 26.5587225
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        26.328995,
        41.4348424,
        26.8299758,
        41.8729163
      ]
    },
    {
      "id": 3294,
      "name": "Takaoka",
      "names": {
        "zh": "高冈",
        "ja": "高岡",
        "th": "ทากาโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.75,
        "lng": 137.01667
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.99667,
        36.73,
        137.03667,
        36.77
      ]
    },
    {
      "id": 3295,
      "name": "Nador",
      "names": {
        "zh": "纳多尔"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.16364845,
        "lng": -2.9350351653953455
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -2.9732824,
        35.1399793,
        -2.9067424,
        35.1879438
      ]
    },
    {
      "id": 3296,
      "name": "Skikda",
      "names": {
        "zh": "斯基克达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.8837956,
        "lng": 6.9086114
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.9036114,
        36.8787956,
        6.9136114,
        36.8887956
      ]
    },
    {
      "id": 3297,
      "name": "Kuytun",
      "names": {
        "zh": "奎屯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.4245113,
        "lng": 84.9006885
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        84.7406885,
        44.2645113,
        85.0606885,
        44.5845113
      ]
    },
    {
      "id": 3298,
      "name": "Grenoble",
      "names": {
        "zh": "格勒诺布尔",
        "ja": "グルノーブル",
        "th": "เกรอน็อบล์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.177879,
        "lng": 5.718545
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.678053,
        45.154166,
        5.753036,
        45.21426
      ]
    },
    {
      "id": 3299,
      "name": "Osnabruck",
      "names": {
        "es": "Osnabrück",
        "fr": "Osnabrück",
        "zh": "奥斯纳布吕克",
        "de": "Osnabrück",
        "id": "Osnabrück",
        "th": "ออสนาบรุ๊ค",
        "pt": "Osnabrück"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.274158,
        "lng": 8.047347
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.930163,
        52.220549,
        8.180654,
        52.338766
      ]
    },
    {
      "id": 3300,
      "name": "Perugia",
      "names": {
        "fr": "Pérouse",
        "zh": "佩鲁贾",
        "ja": "ペルージャ",
        "th": "เปรู"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.1119613,
        "lng": 12.3890104
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        12.1731207,
        42.9934486,
        12.5650226,
        43.2860719
      ]
    },
    {
      "id": 3301,
      "name": "Jiutepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.88638935,
        "lng": -99.17012240115315
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2171382,
        18.835966,
        -99.1193803,
        18.9367008
      ]
    },
    {
      "id": 3302,
      "name": "Udipi",
      "names": {
        "zh": "乌迪比"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.342642,
        "lng": 74.747315
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.723192,
        13.314136,
        74.770461,
        13.358347
      ]
    },
    {
      "id": 3303,
      "name": "Oshawa",
      "names": {
        "zh": "奥沙瓦",
        "ja": "オシャワ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8975558,
        "lng": -78.8635324
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -78.9587724,
        43.8320653,
        -78.7879444,
        44.0474286
      ]
    },
    {
      "id": 3304,
      "name": "Klaipeda",
      "names": {
        "zh": "克莱佩达",
        "th": "ไคลเปดา"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.7127529,
        "lng": 21.1350469
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.0750828,
        55.6127871,
        21.2403273,
        55.7965694
      ]
    },
    {
      "id": 3305,
      "name": "Tanjungbalai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.96850185,
        "lng": 99.80797132896316
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        99.8074021,
        2.9677246,
        99.8085276,
        2.9692361
      ]
    },
    {
      "id": 3306,
      "name": "Obihiro",
      "names": {
        "zh": "带广",
        "ja": "帯広"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.923809,
        "lng": 143.1966324
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        142.686846,
        42.614835,
        143.268298,
        42.952846
      ]
    },
    {
      "id": 3307,
      "name": "Midsayap",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1900605,
        "lng": 124.5333614
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.4933614,
        7.1500605,
        124.5733614,
        7.2300605
      ]
    },
    {
      "id": 3308,
      "name": "Leverkusen",
      "names": {
        "zh": "勒沃库森",
        "ja": "レバークーゼン",
        "th": "เลเวอร์คูเซ่น"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0324743,
        "lng": 6.9881194
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.8978324,
        51.0111245,
        7.1160825,
        51.0971925
      ]
    },
    {
      "id": 3309,
      "name": "Hadano",
      "names": {
        "zh": "秦野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.375609,
        "lng": 139.220838
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.158304,
        35.339723,
        139.300901,
        35.411496
      ]
    },
    {
      "id": 3310,
      "name": "Kushiro",
      "names": {
        "zh": "钏路",
        "ja": "釧路"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.9906837,
        "lng": 144.3820381
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        144.3770381,
        42.9856837,
        144.3870381,
        42.9956837
      ]
    },
    {
      "id": 3311,
      "name": "Idlib",
      "names": {
        "zh": "伊德利卜"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.93062,
        "lng": 36.63393
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        36.61393,
        35.91062,
        36.65393,
        35.95062
      ]
    },
    {
      "id": 3312,
      "name": "Sitalpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.7639605,
        "lng": 85.0296514
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        85.0096514,
        25.7439605,
        85.0496514,
        25.7839605
      ]
    },
    {
      "id": 3313,
      "name": "Bechar",
      "names": {
        "zh": "贝沙尔"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 31.6236899,
        "lng": -2.2196679
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -2.2246679,
        31.6186899,
        -2.2146679,
        31.6286899
      ]
    },
    {
      "id": 3314,
      "name": "Conjeeveram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 12.8308,
        "lng": 79.7078
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3315,
      "name": "Proddatur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.75128535,
        "lng": 78.54954045369432
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.461523,
        14.6659714,
        78.6415744,
        14.8361847
      ]
    },
    {
      "id": 3316,
      "name": "Cienfuegos",
      "names": {
        "zh": "圣菲哥斯",
        "ja": "シエンフエーゴス"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1459864,
        "lng": -80.4530775
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -80.5646629,
        22.0204133,
        -80.2702139,
        22.2272613
      ]
    },
    {
      "id": 3317,
      "name": "Saqqez",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.24992,
        "lng": 46.2735
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        46.2535,
        36.22992,
        46.2935,
        36.26992
      ]
    },
    {
      "id": 3318,
      "name": "Zhanlicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.64447,
        "lng": 118.00269
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.98269,
        29.62447,
        118.02269,
        29.66447
      ]
    },
    {
      "id": 3319,
      "name": "Fukang",
      "names": {
        "zh": "阜康"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1497919,
        "lng": 87.9684506
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        87.8084506,
        43.9897919,
        88.1284506,
        44.3097919
      ]
    },
    {
      "id": 3320,
      "name": "Pikit",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0537358,
        "lng": 124.6722475
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.6322475,
        7.0137358,
        124.7122475,
        7.0937358
      ]
    },
    {
      "id": 3321,
      "name": "Ramat Gan",
      "names": {
        "fr": "Ramate gan",
        "zh": "拉马特甘",
        "ja": "ラマトガン",
        "th": "รามัตกาน"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0686867,
        "lng": 34.8246812
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.7991223,
        32.036093,
        34.8545946,
        32.1055761
      ]
    },
    {
      "id": 3322,
      "name": "Bhisho",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -32.8494444,
        "lng": 27.4463889
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        27.4063889,
        -32.8894444,
        27.4863889,
        -32.8094444
      ]
    },
    {
      "id": 3323,
      "name": "Turkistan",
      "names": {
        "zh": "土耳其斯坦"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.3019457,
        "lng": 68.2703698
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        68.1629476,
        43.251107,
        68.3446861,
        43.3277689
      ]
    },
    {
      "id": 3324,
      "name": "Huddersfield",
      "names": {
        "zh": "哈德斯菲尔德",
        "ja": "ハッダーズフィールド",
        "th": "กริฟฟิธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6466645,
        "lng": -1.7822482
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8222482,
        53.6066645,
        -1.7422482,
        53.6866645
      ]
    },
    {
      "id": 3325,
      "name": "Bocoio",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.46667,
        "lng": 14.13333
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.11333,
        -12.48667,
        14.15333,
        -12.44667
      ]
    },
    {
      "id": 3326,
      "name": "Chillan",
      "names": {
        "fr": "Chillán",
        "zh": "奇廉"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -36.6066616,
        "lng": -72.1033194
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -72.4729782,
        -36.7504178,
        -71.8558675,
        -36.5057077
      ]
    },
    {
      "id": 3327,
      "name": "Abu Hulayfah",
      "names": {
        "zh": "阿布·赫拉伊法"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.12944,
        "lng": 48.11
      },
      "country": "Kuwait",
      "importance": 3
    },
    {
      "id": 3328,
      "name": "Odense",
      "names": {
        "zh": "欧登塞",
        "ja": "オーデンセ",
        "th": "โอเดนเซ"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.3997225,
        "lng": 10.3852104
      },
      "country": "Denmark",
      "importance": 2,
      "bbox": [
        10.2252104,
        55.2397225,
        10.5452104,
        55.5597225
      ]
    },
    {
      "id": 3329,
      "name": "Muridke",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.8010863,
        "lng": 74.2540999
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        74.0940999,
        31.6410863,
        74.4140999,
        31.9610863
      ]
    },
    {
      "id": 3330,
      "name": "Tema",
      "names": {
        "zh": "特马",
        "ja": "テマ",
        "th": "ธีม"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 5.6596441,
        "lng": -0.0096592
      },
      "country": "Ghana",
      "importance": 3,
      "bbox": [
        -0.1696592,
        5.4996441,
        0.1503408,
        5.8196441
      ]
    },
    {
      "id": 3331,
      "name": "Metro",
      "names": {
        "fr": "Métro",
        "zh": "地铁",
        "de": "U-Bahn",
        "it": "La metropolitana",
        "ja": "メトロ",
        "th": "รถไฟใต้ดิน"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 44.56916285,
        "lng": 33.45894664250601
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        33.4584417,
        44.5689056,
        33.4596054,
        44.5698151
      ]
    },
    {
      "id": 3332,
      "name": "Ipswich",
      "names": {
        "zh": "伊普斯维奇",
        "ja": "イプスウィッチ",
        "th": "อิปสวิช"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -27.6160323,
        "lng": 152.7608348
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        152.382721,
        -27.865469,
        152.940883,
        -27.490327
      ]
    },
    {
      "id": 3333,
      "name": "Itapetininga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.588607,
        "lng": -48.048326
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.4087611,
        -23.8610978,
        -47.8071448,
        -23.4080079
      ]
    },
    {
      "id": 3334,
      "name": "Myingyan",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 21.4587531,
        "lng": 95.3924129
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.2478175,
        21.3379165,
        95.6307842,
        21.7707994
      ]
    },
    {
      "id": 3335,
      "name": "Quibala",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -10.7325439,
        "lng": 14.9805493
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.9405493,
        -10.7725439,
        15.0205493,
        -10.6925439
      ]
    },
    {
      "id": 3336,
      "name": "Teresopolis",
      "names": {
        "pt": "Teresópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4164578,
        "lng": -42.975194
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.0462553,
        -22.4711724,
        -42.8095373,
        -22.3028692
      ]
    },
    {
      "id": 3337,
      "name": "Ciudad Acuna",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 29.3248796,
        "lng": -100.9322287
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.0922287,
        29.1648796,
        -100.7722287,
        29.4848796
      ]
    },
    {
      "id": 3338,
      "name": "Pocheon",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.8948267,
        "lng": 127.2007295
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        127.0902998,
        37.7469676,
        127.4470757,
        38.1883212
      ]
    },
    {
      "id": 3339,
      "name": "Navsari",
      "names": {
        "zh": "瑙萨里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.946393,
        "lng": 72.92762
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.912377,
        20.918362,
        72.96966,
        20.964984
      ]
    },
    {
      "id": 3340,
      "name": "Sao Jose de Ribamar",
      "names": {
        "zh": "圣何塞德里亚马尔",
        "ja": "サンホセdeリバマール",
        "pt": "São José de Ribamar"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5609115,
        "lng": -44.0559509
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.2208497,
        -2.7021236,
        -44.0237854,
        -2.4614305
      ]
    },
    {
      "id": 3341,
      "name": "Wau",
      "names": {
        "zh": "墙"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 7.7001398,
        "lng": 27.9909335
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        27.8309335,
        7.5401398,
        28.1509335,
        7.8601398
      ]
    },
    {
      "id": 3342,
      "name": "Ube",
      "names": {
        "zh": "宇部",
        "ja": "宇部",
        "th": "อุเบะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9518498,
        "lng": 131.2472243
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.183532,
        33.7661299,
        131.4182075,
        34.166989
      ]
    },
    {
      "id": 3343,
      "name": "Muzaffargarh",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.0743097,
        "lng": 71.1906224
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.0306224,
        29.9143097,
        71.3506224,
        30.2343097
      ]
    },
    {
      "id": 3344,
      "name": "Kebili",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.7061148,
        "lng": 8.9698376
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        8.9298376,
        33.6661148,
        9.0098376,
        33.7461148
      ]
    },
    {
      "id": 3345,
      "name": "Jizzax",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.11583,
        "lng": 67.84222
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        67.82222,
        40.09583,
        67.86222,
        40.13583
      ]
    },
    {
      "id": 3346,
      "name": "Simao",
      "names": {
        "zh": "思茅",
        "de": "Simão",
        "it": "Simão",
        "pt": "Simão"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.788627,
        "lng": 100.974807
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.8847699,
        22.7723177,
        101.1428548,
        22.9557312
      ]
    },
    {
      "id": 3347,
      "name": "San Martin Texmelucan de Labastida",
      "names": {
        "fr": "San Martin Texmeucan de Labastida",
        "zh": "圣马丁Texmelucan de Labastida",
        "ja": "サンマーティンテキサスメルカンデラバスティダ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.283085,
        "lng": -98.432403
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.458531,
        19.265668,
        -98.411003,
        19.311242
      ]
    },
    {
      "id": 3348,
      "name": "Mostar",
      "names": {
        "zh": "莫斯塔尔",
        "ja": "モスタル",
        "th": "ตาร์"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.3489955,
        "lng": 17.813885
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.808885,
        43.3439955,
        17.818885,
        43.3539955
      ]
    },
    {
      "id": 3349,
      "name": "Naic",
      "names": {
        "fr": "Scian"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.31812,
        "lng": 120.76609
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.74609,
        14.29812,
        120.78609,
        14.33812
      ]
    },
    {
      "id": 3350,
      "name": "El Geneina",
      "names": {
        "ja": "エルジェネーナ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.45262,
        "lng": 22.44725
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        22.42725,
        13.43262,
        22.46725,
        13.47262
      ]
    },
    {
      "id": 3351,
      "name": "Jinggang",
      "names": {
        "zh": "靖港"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1718863,
        "lng": 114.6513595
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.6313595,
        27.1518863,
        114.6713595,
        27.1918863
      ]
    },
    {
      "id": 3352,
      "name": "Bandar-e Mahshahr",
      "names": {
        "de": "Bandar-E Mahshaahr",
        "ja": "バンダルeマヒンシャール"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.5588,
        "lng": 49.1981
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.1781,
        30.5388,
        49.2181,
        30.5788
      ]
    },
    {
      "id": 3353,
      "name": "Manchester",
      "names": {
        "zh": "曼彻斯特",
        "ja": "マンチェスター",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9956397,
        "lng": -71.4547891
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.5127468,
        42.890351,
        -71.3754311,
        43.0517714
      ]
    },
    {
      "id": 3354,
      "name": "Godhra",
      "names": {
        "zh": "戈特拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.779084,
        "lng": 73.621761
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.594404,
        22.755232,
        73.659072,
        22.798951
      ]
    },
    {
      "id": 3355,
      "name": "Anaco",
      "names": {
        "zh": "阿纳科"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.432191,
        "lng": -64.466834
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -64.5542693,
        9.3606591,
        -64.4035828,
        9.4943129
      ]
    },
    {
      "id": 3356,
      "name": "Sullana",
      "names": {
        "zh": "苏亚纳"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -4.8900643,
        "lng": -80.6873604
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -80.7371289,
        -5.0235555,
        -80.3471013,
        -4.6655507
      ]
    },
    {
      "id": 3357,
      "name": "Musashino",
      "names": {
        "zh": "武藏野",
        "ja": "武蔵野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.712898,
        "lng": 139.563534
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5246277,
        35.6941284,
        139.5954924,
        35.7218945
      ]
    },
    {
      "id": 3358,
      "name": "Reading",
      "names": {
        "es": "Leyendo",
        "fr": "En train de lire",
        "zh": "阅读",
        "de": "Lesen",
        "it": "Lettura",
        "id": "Bacaan",
        "ja": "読み取り",
        "th": "การอ่าน",
        "pt": "Leitura"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4564242,
        "lng": -0.9700664
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.0529948,
        51.4097528,
        -0.9284943,
        51.493134
      ]
    },
    {
      "id": 3359,
      "name": "Zemun",
      "names": {
        "zh": "泽蒙"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8444,
        "lng": 20.4123996
      },
      "country": "Serbia",
      "importance": 2,
      "bbox": [
        20.2213764,
        44.8225298,
        20.4520869,
        44.9426876
      ]
    },
    {
      "id": 3360,
      "name": "Sariaya",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9641605,
        "lng": 121.5280379
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.5279879,
        13.9641105,
        121.5280879,
        13.9642105
      ]
    },
    {
      "id": 3361,
      "name": "Tuxtepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.0874632,
        "lng": -96.1227767
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -96.2827767,
        17.9274632,
        -95.9627767,
        18.2474632
      ]
    },
    {
      "id": 3362,
      "name": "Jutiapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2925976,
        "lng": -89.896705
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.0614626,
        14.1589638,
        -89.7685466,
        14.4630933
      ]
    },
    {
      "id": 3363,
      "name": "Budaun",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.0170407,
        "lng": 79.11839502041272
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.8030185,
        27.829905,
        79.3245642,
        28.2039503
      ]
    },
    {
      "id": 3364,
      "name": "Chittoor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.2193663,
        "lng": 79.1033126
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.0983126,
        13.2143663,
        79.1083126,
        13.2243663
      ]
    },
    {
      "id": 3365,
      "name": "Benoni",
      "names": {
        "zh": "伯诺尼"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.1930356,
        "lng": 28.3082376
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        28.2682376,
        -26.2330356,
        28.3482376,
        -26.1530356
      ]
    },
    {
      "id": 3366,
      "name": "Andong",
      "names": {
        "zh": "安东"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.5634886,
        "lng": 128.7260798
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        128.4291939,
        36.2950662,
        129.0009927,
        36.8230788
      ]
    },
    {
      "id": 3367,
      "name": "Ash Shatrah",
      "names": {
        "zh": "灰沙拉",
        "ja": "灰Shatrah",
        "pt": "Cinza shatrah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.41171,
        "lng": 46.171393
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        46.13048,
        31.383238,
        46.203152,
        31.464361
      ]
    },
    {
      "id": 3368,
      "name": "Rafsanjan",
      "names": {
        "zh": "拉夫桑詹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.402184,
        "lng": 55.9941776
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        55.9044455,
        30.3271013,
        56.0690687,
        30.4452701
      ]
    },
    {
      "id": 3369,
      "name": "Al Ghardaqah",
      "names": {
        "de": "Algardaqah",
        "ja": "アルハルダカ",
        "th": "อัล ghardaqah",
        "pt": "Al ghedaqah"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.25738,
        "lng": 33.81291
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        33.79291,
        27.23738,
        33.83291,
        27.27738
      ]
    },
    {
      "id": 3370,
      "name": "Bontang",
      "names": {
        "zh": "邦坦"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.1147198,
        "lng": 117.4925219
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        117.4924719,
        0.1146698,
        117.4925719,
        0.1147698
      ]
    },
    {
      "id": 3371,
      "name": "Tulancingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0839585,
        "lng": -98.3635381
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.5021638,
        20.0521737,
        -98.2465569,
        20.2122984
      ]
    },
    {
      "id": 3372,
      "name": "Ndalatando",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -9.2984831,
        "lng": 14.9144981
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        14.7544981,
        -9.4584831,
        15.0744981,
        -9.1384831
      ]
    },
    {
      "id": 3373,
      "name": "Apeldoorn",
      "names": {
        "zh": "阿珀尔多伦",
        "ja": "アペルドールン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2092784,
        "lng": 5.9689735
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9639735,
        52.2042784,
        5.9739735,
        52.2142784
      ]
    },
    {
      "id": 3374,
      "name": "Elektrostal",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7903785,
        "lng": 38.4405711
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.3741256,
        55.7489346,
        38.5295538,
        55.8256195
      ]
    },
    {
      "id": 3375,
      "name": "Jequie",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -13.863163,
        "lng": -40.081125
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.141658,
        -13.917169,
        -40.02383,
        -13.82261
      ]
    },
    {
      "id": 3376,
      "name": "Dong Hoi",
      "names": {
        "zh": "董河",
        "ja": "ドンホイ",
        "th": "ดงฮอย"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 17.4689738,
        "lng": 106.5998415
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.5948415,
        17.4639738,
        106.6048415,
        17.4739738
      ]
    },
    {
      "id": 3377,
      "name": "Krishnanagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.4058481,
        "lng": 88.4958935
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3358935,
        23.2458481,
        88.6558935,
        23.5658481
      ]
    },
    {
      "id": 3378,
      "name": "Sherbrooke",
      "names": {
        "zh": "舍布鲁克"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.403271,
        "lng": -71.889038
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.1087893,
        45.2999048,
        -71.8025422,
        45.5240502
      ]
    },
    {
      "id": 3379,
      "name": "Kidapawan",
      "names": {
        "zh": "基达帕万"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.00833,
        "lng": 125.08944
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.06944,
        6.98833,
        125.10944,
        7.02833
      ]
    },
    {
      "id": 3380,
      "name": "San Miguel",
      "names": {
        "zh": "圣米格尔",
        "ja": "サンミゲル",
        "th": "ซานมิเกล"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -6.9768275,
        "lng": -78.9409485
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -79.3316186,
        -7.2527579,
        -78.6115215,
        -6.6922468
      ]
    },
    {
      "id": 3381,
      "name": "Papantla de Olarte",
      "names": {
        "es": "Papantla de olario",
        "it": "Papantla de Ollare"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.447084,
        "lng": -97.320029
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.346671,
        20.432394,
        -97.302128,
        20.465768
      ]
    },
    {
      "id": 3382,
      "name": "Dhamar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.542997,
        "lng": 44.4000607
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        44.2400607,
        14.382997,
        44.5600607,
        14.702997
      ]
    },
    {
      "id": 3383,
      "name": "Jose Maria Ezeiza",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8560442,
        "lng": -58.5248051
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.5248581,
        -34.856079,
        -58.5247638,
        -34.8559995
      ]
    },
    {
      "id": 3384,
      "name": "Southend",
      "names": {
        "zh": "绍森德",
        "id": "London Southend",
        "ja": "サウスエンド",
        "th": "เอนด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5388241,
        "lng": 0.7128137
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.6229179,
        51.5044707,
        0.8489542,
        51.5767962
      ]
    },
    {
      "id": 3385,
      "name": "Ibirite",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.0214251,
        "lng": -44.0621506
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.1286181,
        -20.079,
        -44.0092799,
        -19.9666213
      ]
    },
    {
      "id": 3386,
      "name": "Solingen",
      "names": {
        "zh": "索林根",
        "ja": "ゾーリンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.169228,
        "lng": 7.0853661
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0853161,
        51.169178,
        7.0854161,
        51.169278
      ]
    },
    {
      "id": 3387,
      "name": "Tacheng",
      "names": {
        "zh": "塔城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.7907169,
        "lng": 83.2079658
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        82.7253761,
        46.1945671,
        85.2484988,
        47.2346519
      ]
    },
    {
      "id": 3388,
      "name": "Villeurbanne",
      "names": {
        "zh": "维勒班"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.771418,
        "lng": 4.88534
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        4.858329,
        45.748294,
        4.921251,
        45.795319
      ]
    },
    {
      "id": 3389,
      "name": "Bayamon",
      "names": {},
      "countryCode": "PR",
      "latLng": {
        "lat": 18.3990551,
        "lng": -66.1560326
      },
      "country": "Puerto Rico",
      "importance": 3,
      "bbox": [
        -66.3160326,
        18.2390551,
        -65.9960326,
        18.5590551
      ]
    },
    {
      "id": 3390,
      "name": "Braganca Paulista",
      "names": {
        "ja": "ブラガンカパウリスタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9520235,
        "lng": -46.5418586
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.72,
        -23.051,
        -46.4046822,
        -22.8215405
      ]
    },
    {
      "id": 3391,
      "name": "Zhengding",
      "names": {
        "zh": "正定"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1533054,
        "lng": 114.576652
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.536652,
        38.1133054,
        114.616652,
        38.1933054
      ]
    },
    {
      "id": 3392,
      "name": "Pindamonhangaba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.928497,
        "lng": -45.464586393078406
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.4657424,
        -22.9289828,
        -45.463341,
        -22.9279715
      ]
    },
    {
      "id": 3393,
      "name": "Enschede",
      "names": {
        "zh": "恩斯赫德",
        "th": "เอนสเกเด"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2209855,
        "lng": 6.8940537
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.7558928,
        52.1612059,
        6.9811001,
        52.2855057
      ]
    },
    {
      "id": 3394,
      "name": "Giron",
      "names": {
        "th": "เร่"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0732333,
        "lng": -73.1689196
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.545352,
        6.8842284,
        -73.0893527,
        7.2151617
      ]
    },
    {
      "id": 3395,
      "name": "Dijon",
      "names": {
        "zh": "第戎",
        "ja": "ディジョン",
        "th": "ในดีชง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.3150999,
        "lng": 4.995466547988704
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        4.9624434,
        47.2996005,
        5.0314638,
        47.3307186
      ]
    },
    {
      "id": 3396,
      "name": "Khost",
      "names": {
        "zh": "霍斯特"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.3391402,
        "lng": 69.9206359
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        69.7606359,
        33.1791402,
        70.0806359,
        33.4991402
      ]
    },
    {
      "id": 3397,
      "name": "Koudougou",
      "names": {
        "zh": "库杜古"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.2514911,
        "lng": -2.3652267
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -2.4121796,
        12.2188812,
        -2.3007179,
        12.2805537
      ]
    },
    {
      "id": 3398,
      "name": "Darmstadt",
      "names": {
        "zh": "达姆施塔特",
        "ja": "ダルムシュタット",
        "th": "มสตัด"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.872775,
        "lng": 8.651177
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.491177,
        49.712775,
        8.811177,
        50.032775
      ]
    },
    {
      "id": 3399,
      "name": "Saint-Marc",
      "names": {
        "zh": "圣 - 马克",
        "ja": "サンマルク",
        "th": "เซนต์มาร์ค"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.1020269,
        "lng": -72.699511
      },
      "country": "Haiti",
      "importance": 3,
      "bbox": [
        -72.859511,
        18.9420269,
        -72.539511,
        19.2620269
      ]
    },
    {
      "id": 3400,
      "name": "Taunggyi",
      "names": {
        "zh": "东枝"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.784723149999998,
        "lng": 97.01084985427684
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        96.2036073,
        19.299318,
        97.4734322,
        22.2700165
      ]
    },
    {
      "id": 3401,
      "name": "Arad",
      "names": {
        "zh": "阿拉德",
        "ja": "アラド",
        "th": "อาราด"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.1753793,
        "lng": 21.3196342
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        21.1513602,
        46.0447539,
        21.4146784,
        46.2618206
      ]
    },
    {
      "id": 3402,
      "name": "Gaziantep",
      "names": {
        "zh": "加济安泰普"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0628317,
        "lng": 37.3792617
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        37.2192617,
        36.9028317,
        37.5392617,
        37.2228317
      ]
    },
    {
      "id": 3403,
      "name": "Khanpur",
      "names": {
        "zh": "汗布尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 28.6477403,
        "lng": 70.656008
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        70.496008,
        28.4877403,
        70.816008,
        28.8077403
      ]
    },
    {
      "id": 3404,
      "name": "Huaycan",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -12.0130378,
        "lng": -76.8263767
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -76.8463767,
        -12.0330378,
        -76.8063767,
        -11.9930378
      ]
    },
    {
      "id": 3405,
      "name": "San Nicolas de los Arroyos",
      "names": {
        "zh": "圣尼古拉斯德洛斯阿罗罗斯",
        "ja": "サンニコラスデロスアロヨス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.3277089,
        "lng": -60.2165909
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.2776695,
        -33.4100768,
        -60.1659877,
        -33.2858771
      ]
    },
    {
      "id": 3406,
      "name": "Heidelberg",
      "names": {
        "zh": "海德堡",
        "ja": "ハイデルベルク",
        "th": "ไฮเดลเบิร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4093582,
        "lng": 8.694724
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.5731788,
        49.3520029,
        8.7940496,
        49.4596927
      ]
    },
    {
      "id": 3407,
      "name": "Miyakonojo",
      "names": {
        "zh": "都城"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.7196106,
        "lng": 131.0612029
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.875022,
        31.619908,
        131.254379,
        31.94483
      ]
    },
    {
      "id": 3408,
      "name": "Pandi",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8654061,
        "lng": 120.9572464
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9036613,
        14.8290324,
        121.0003528,
        14.9202397
      ]
    },
    {
      "id": 3409,
      "name": "Kramatorsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7257049,
        "lng": 37.5436075
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.5386075,
        48.7207049,
        37.5486075,
        48.7307049
      ]
    },
    {
      "id": 3410,
      "name": "Seixal",
      "names": {
        "zh": "塞沙尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6460299,
        "lng": -9.089757660657
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.1119271,
        38.636815,
        -9.0718872,
        38.6549118
      ]
    },
    {
      "id": 3411,
      "name": "Livorno",
      "names": {
        "fr": "Livourne",
        "zh": "里窝那",
        "ja": "リボルノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.5507317,
        "lng": 10.3091256
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.8881379,
        43.4197864,
        10.4195014,
        43.5954875
      ]
    },
    {
      "id": 3412,
      "name": "Shahr-e Kord",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.32556,
        "lng": 50.86444
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        50.84444,
        32.30556,
        50.88444,
        32.34556
      ]
    },
    {
      "id": 3413,
      "name": "Ekibastuz",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.7275817,
        "lng": 75.3288511
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        75.2676954,
        51.6922165,
        75.3621106,
        51.7624971
      ]
    },
    {
      "id": 3414,
      "name": "Hat Yai",
      "names": {
        "es": "Sombrero yai",
        "fr": "Chapeau yai",
        "zh": "帽子亚",
        "de": "Hut yai",
        "it": "Cappello yai",
        "id": "Topi yai",
        "ja": "ハットヤイ",
        "th": "หาดใหญ่",
        "pt": "Chapéu yai"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.010259,
        "lng": 100.473036
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.378523,
        6.835581,
        100.539447,
        7.115387
      ]
    },
    {
      "id": 3415,
      "name": "Szeged",
      "names": {
        "zh": "塞格德",
        "th": "ซีเกด"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2521377,
        "lng": 20.1513952
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        20.1513452,
        46.2520877,
        20.1514452,
        46.2521877
      ]
    },
    {
      "id": 3416,
      "name": "Catamarca",
      "names": {
        "zh": "卡塔马卡"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -28.4688806,
        "lng": -65.7790642
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -65.9521763,
        -28.5563041,
        -65.7249535,
        -28.2622876
      ]
    },
    {
      "id": 3417,
      "name": "Chirchiq",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.4726157,
        "lng": 69.5811915
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        69.5245764,
        41.42216,
        69.6427302,
        41.5288348
      ]
    },
    {
      "id": 3418,
      "name": "La Laguna",
      "names": {
        "ja": "ララグナ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.4857715,
        "lng": -16.3159422
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -16.4109946,
        28.4318088,
        -16.2672631,
        28.5793751
      ]
    },
    {
      "id": 3419,
      "name": "Jijiga",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 9.35,
        "lng": 42.8
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        42.78,
        9.33,
        42.82,
        9.37
      ]
    },
    {
      "id": 3420,
      "name": "Champaign",
      "names": {
        "zh": "香槟分校",
        "ja": "シャンペーン",
        "th": "ที่ราบ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.104535,
        "lng": -88.272157
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.333345,
        40.061887,
        -88.221223,
        40.16398
      ]
    },
    {
      "id": 3421,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8051095,
        "lng": -77.0470229
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.1443577,
        38.7851837,
        -77.0370392,
        38.8449229
      ]
    },
    {
      "id": 3422,
      "name": "George",
      "names": {
        "es": "Jorge",
        "zh": "乔治·",
        "it": "Giorgio",
        "ja": "ジョージ",
        "th": "จอร์จ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.9597212,
        "lng": 22.4587148
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        20.50315,
        -34.43906,
        23.7033,
        -33.31496
      ]
    },
    {
      "id": 3423,
      "name": "Herne",
      "names": {
        "zh": "赫恩",
        "ja": "ヘルネ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5435523,
        "lng": 7.2176455
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.2126455,
        51.5385523,
        7.2226455,
        51.5485523
      ]
    },
    {
      "id": 3424,
      "name": "Ravenna",
      "names": {
        "es": "Rávena",
        "fr": "Ravenne",
        "zh": "拉文纳",
        "ja": "ラヴェンナ",
        "th": "ราเวนนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.4161414,
        "lng": 12.2017617
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.0290023,
        44.2131439,
        12.3481021,
        44.6283408
      ]
    },
    {
      "id": 3425,
      "name": "Higashimurayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.755426,
        "lng": 139.472602
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.432979,
        35.728677,
        139.511923,
        35.782171
      ]
    },
    {
      "id": 3426,
      "name": "Hayward",
      "names": {
        "zh": "海沃德",
        "ja": "ヘイワード",
        "th": "เฮย์เวิร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6688205,
        "lng": -122.080796
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.2190692,
        37.5478185,
        -121.9203973,
        37.6899445
      ]
    },
    {
      "id": 3427,
      "name": "Bharuch",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.7080427,
        "lng": 72.9956936
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.9626173,
        21.6851873,
        73.0377334,
        21.7329852
      ]
    },
    {
      "id": 3428,
      "name": "Ogaki",
      "names": {
        "zh": "大垣",
        "ja": "大垣"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.36666,
        "lng": 136.6169214
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.6119214,
        35.36166,
        136.6219214,
        35.37166
      ]
    },
    {
      "id": 3429,
      "name": "Saharsa",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.883353,
        "lng": 86.602123
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.571017,
        25.861063,
        86.625953,
        25.903401
      ]
    },
    {
      "id": 3430,
      "name": "Puerto Plata",
      "names": {
        "ja": "プエルトプラタ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.7891616,
        "lng": -70.6926181
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.8891205,
        19.5120852,
        -70.5073184,
        19.9084053
      ]
    },
    {
      "id": 3431,
      "name": "Nan Zhuang",
      "names": {
        "zh": "南庄",
        "th": "น่านจ้วง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9847777,
        "lng": 113.0054353
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.9748941,
        22.9245956,
        113.0930033,
        23.0348439
      ]
    },
    {
      "id": 3432,
      "name": "Quipungo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.8244372,
        "lng": 14.5432243
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.5382243,
        -14.8294372,
        14.5482243,
        -14.8194372
      ]
    },
    {
      "id": 3433,
      "name": "Cadiz",
      "names": {
        "es": "Cádiz",
        "fr": "Cadix",
        "zh": "加的斯",
        "it": "Cadice",
        "ja": "カディス",
        "th": "กาดิซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.956652,
        "lng": 123.3057034
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.124286,
        10.6867349,
        123.3748101,
        11.1378412
      ]
    },
    {
      "id": 3434,
      "name": "Chau Doc",
      "names": {
        "id": "Dokter"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.7,
        "lng": 105.11667
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        105.09667,
        10.68,
        105.13667,
        10.72
      ]
    },
    {
      "id": 3435,
      "name": "Guimaraes",
      "names": {
        "zh": "吉马良斯",
        "de": "Guimarães",
        "id": "Guimarães",
        "pt": "Guimarães"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.4471587,
        "lng": -8.288836286546829
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2951958,
        41.4414706,
        -8.2808918,
        41.4525472
      ]
    },
    {
      "id": 3436,
      "name": "Amersfoort",
      "names": {
        "zh": "阿默斯福特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.153898,
        "lng": 5.3740976
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3690976,
        52.148898,
        5.3790976,
        52.158898
      ]
    },
    {
      "id": 3437,
      "name": "Matsuzaka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.567412,
        "lng": 136.540309
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.488736,
        34.520172,
        136.598418,
        34.628662
      ]
    },
    {
      "id": 3438,
      "name": "Haripur",
      "names": {
        "zh": "赫里布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 33.9944889,
        "lng": 72.9331737
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.7731737,
        33.8344889,
        73.0931737,
        34.1544889
      ]
    },
    {
      "id": 3439,
      "name": "Samarra'",
      "names": {
        "es": "Samarra '",
        "fr": "Samarra '",
        "zh": "萨马拉'",
        "de": "Samarra '",
        "it": "Samarra '",
        "id": "Samarra '",
        "ja": "samarra '",
        "th": "samarra '",
        "pt": "Samarra '"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.198232,
        "lng": 43.890436
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        43.848594,
        34.172687,
        43.923289,
        34.222924
      ]
    },
    {
      "id": 3440,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.735377,
        "lng": -92.63873
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.79873,
        16.575377,
        -92.47873,
        16.895377
      ]
    },
    {
      "id": 3441,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7990175,
        "lng": -89.6439575
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -89.773182,
        39.653656,
        -89.56851,
        39.87417
      ]
    },
    {
      "id": 3442,
      "name": "Ilagan",
      "names": {
        "zh": "伊拉甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.1486341,
        "lng": 121.8886466
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.7286466,
        16.9886341,
        122.0486466,
        17.3086341
      ]
    },
    {
      "id": 3443,
      "name": "Rafael Castillo",
      "names": {
        "zh": "拉斐尔卡斯蒂略",
        "th": "ราฟาเอลคาร์ติลเลีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7002383,
        "lng": -58.6273452
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.6273952,
        -34.7002883,
        -58.6272952,
        -34.7001883
      ]
    },
    {
      "id": 3444,
      "name": "Konya",
      "names": {
        "zh": "科尼亚",
        "ja": "コンヤ",
        "th": "คอนยา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.872734,
        "lng": 32.4924376
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        32.3324376,
        37.712734,
        32.6524376,
        38.032734
      ]
    },
    {
      "id": 3445,
      "name": "Rio Cuarto",
      "names": {
        "zh": "里约Cuarto",
        "ja": "リオカートロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.1237585,
        "lng": -64.3489782
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.4169203,
        -33.1667311,
        -64.3110187,
        -33.0776007
      ]
    },
    {
      "id": 3446,
      "name": "Middelburg",
      "names": {
        "zh": "米德尔堡"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.7678012,
        "lng": 29.4555234
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        29.4155234,
        -25.8078012,
        29.4955234,
        -25.7278012
      ]
    },
    {
      "id": 3447,
      "name": "Angers",
      "names": {
        "zh": "昂热",
        "ja": "アンジェ",
        "th": "อองเชส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.4739884,
        "lng": -0.5515588
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.6176931,
        47.4373546,
        -0.5082737,
        47.5262993
      ]
    },
    {
      "id": 3448,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 10.4444032,
        "lng": -75.2730468
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -75.3346953,
        10.3581539,
        -75.1761562,
        10.5264203
      ]
    },
    {
      "id": 3449,
      "name": "Gojra",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.1482371,
        "lng": 72.6859082
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.5259082,
        30.9882371,
        72.8459082,
        31.3082371
      ]
    },
    {
      "id": 3450,
      "name": "Teixeira de Freitas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.5384774,
        "lng": -39.7451701
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.069,
        -17.626,
        -39.6046309,
        -17.233
      ]
    },
    {
      "id": 3451,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7085736,
        "lng": -105.0846694
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.2004371,
        39.6184394,
        -105.0532141,
        39.761955
      ]
    },
    {
      "id": 3452,
      "name": "Camarajibe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.594468,
        "lng": -35.151081
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.1546138,
        -8.6001111,
        -35.1475577,
        -8.5911365
      ]
    },
    {
      "id": 3453,
      "name": "Lafayette",
      "names": {
        "zh": "老佛爷",
        "ja": "ラファイエット",
        "th": "ลาฟาแยต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6632391,
        "lng": -90.1317754
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.4269641,
        42.5056715,
        -89.8371361,
        42.8148315
      ]
    },
    {
      "id": 3454,
      "name": "Uiwang",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3449082,
        "lng": 126.9689861
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.9292353,
        37.300367,
        127.0468449,
        37.415619
      ]
    },
    {
      "id": 3455,
      "name": "Danao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5194879,
        "lng": 124.0271297
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.8671297,
        10.3594879,
        124.1871297,
        10.6794879
      ]
    },
    {
      "id": 3456,
      "name": "Calama",
      "names": {
        "zh": "卡拉马"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -22.4623917,
        "lng": -68.9272181
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -69.1941303,
        -23.1567687,
        -67.9155493,
        -21.0650383
      ]
    },
    {
      "id": 3457,
      "name": "Chaoshan",
      "names": {
        "zh": "潮汕"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5424042,
        "lng": 116.5828304
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.5778304,
        23.5374042,
        116.5878304,
        23.5474042
      ]
    },
    {
      "id": 3458,
      "name": "Salzburg",
      "names": {
        "es": "Salzburgo",
        "fr": "Salzbourg",
        "zh": "萨尔茨堡",
        "it": "Salisburgo",
        "ja": "ザルツブルク",
        "th": "ซาลซ์บูร์ก",
        "pt": "Salzburgo"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.7981346,
        "lng": 13.0464806
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        12.9856478,
        47.7512115,
        13.1275256,
        47.8543925
      ]
    },
    {
      "id": 3459,
      "name": "Mandi Bahauddin",
      "names": {
        "de": "Mandi Bahaudin",
        "ja": "マンディバハウジン"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.5846351,
        "lng": 73.4914169
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.3314169,
        32.4246351,
        73.6514169,
        32.7446351
      ]
    },
    {
      "id": 3460,
      "name": "Isiro",
      "names": {
        "zh": "伊西罗"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.77391,
        "lng": 27.61603
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        27.59603,
        2.75391,
        27.63603,
        2.79391
      ]
    },
    {
      "id": 3461,
      "name": "El Eulma",
      "names": {
        "ja": "エルマ"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.1529057,
        "lng": 5.6911588
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        5.596329,
        36.1256771,
        5.7841,
        36.204978
      ]
    },
    {
      "id": 3462,
      "name": "Xiaping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.7238559,
        "lng": 114.2642155
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.1932312,
        26.6776712,
        114.2869764,
        26.7753497
      ]
    },
    {
      "id": 3463,
      "name": "Batumi",
      "names": {
        "fr": "Batoumi",
        "zh": "巴统"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.6475326,
        "lng": 41.6453603
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        41.6453103,
        41.6474826,
        41.6454103,
        41.6475826
      ]
    },
    {
      "id": 3464,
      "name": "Chlef",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.164592,
        "lng": 1.331499
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        1.2595003,
        36.0902947,
        1.4487204,
        36.2488481
      ]
    },
    {
      "id": 3465,
      "name": "Guaymas",
      "names": {
        "zh": "瓜伊马斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.9216441,
        "lng": -110.8994059
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -111.0594059,
        27.7616441,
        -110.7394059,
        28.0816441
      ]
    },
    {
      "id": 3466,
      "name": "Pathankot",
      "names": {
        "zh": "伯坦果德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 32.266509,
        "lng": 75.649474
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.627815,
        32.249272,
        75.676267,
        32.287095
      ]
    },
    {
      "id": 3467,
      "name": "Arnavutkoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.18355,
        "lng": 28.7402
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        28.7202,
        41.16355,
        28.7602,
        41.20355
      ]
    },
    {
      "id": 3468,
      "name": "Frederick",
      "names": {
        "zh": "弗雷德里克",
        "de": "Friedrich",
        "it": "Federico",
        "ja": "フレデリック",
        "th": "เฟรดเดอริก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.415779,
        "lng": -77.4127562
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.4781744,
        39.3924799,
        -77.3453065,
        39.4885388
      ]
    },
    {
      "id": 3469,
      "name": "Lajes",
      "names": {
        "zh": "拉日斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.8165664,
        "lng": -50.325883
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.6964052,
        -28.4333434,
        -49.9982107,
        -27.569081
      ]
    },
    {
      "id": 3470,
      "name": "Pitesti",
      "names": {
        "zh": "皮特什蒂",
        "ja": "ピテシュチ",
        "th": "ปีเตสตี"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.8572343,
        "lng": 24.8719422
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        24.780386,
        44.8174784,
        24.9426712,
        44.8958233
      ]
    },
    {
      "id": 3471,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0641992,
        "lng": 125.6093294
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.5890459,
        7.0579401,
        125.6279118,
        7.105258
      ]
    },
    {
      "id": 3472,
      "name": "Hanam",
      "names": {
        "zh": "哈纳姆"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.5392918,
        "lng": 127.214948
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.139952,
        37.468751,
        127.2856353,
        37.5918889
      ]
    },
    {
      "id": 3473,
      "name": "Capas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3360344,
        "lng": 120.589583
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.2458796,
        15.245075,
        120.6178761,
        15.4260165
      ]
    },
    {
      "id": 3474,
      "name": "Ghorahi",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.0393605,
        "lng": 82.4866861
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        82.3266861,
        27.8793605,
        82.6466861,
        28.1993605
      ]
    },
    {
      "id": 3475,
      "name": "Vidisha",
      "names": {
        "zh": "毗底沙"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.5208128,
        "lng": 77.8063184
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.6463184,
        23.3608128,
        77.9663184,
        23.6808128
      ]
    },
    {
      "id": 3476,
      "name": "Cam Pha",
      "names": {
        "es": "Cámara PHA",
        "zh": "凸轮pha",
        "it": "Campha",
        "ja": "カムパ",
        "th": "แคมพ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.01004,
        "lng": 107.27345
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        107.25345,
        20.99004,
        107.29345,
        21.03004
      ]
    },
    {
      "id": 3477,
      "name": "Esmeraldas",
      "names": {
        "zh": "埃斯梅拉达斯"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": 0.9668153,
        "lng": -79.6523847
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.7443772,
        0.8890515,
        -79.632465,
        0.9976251
      ]
    },
    {
      "id": 3478,
      "name": "Ar Ramtha",
      "names": {
        "id": "Ar ramuan"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.55917,
        "lng": 36.00694
      },
      "country": "Jordan",
      "importance": 2,
      "bbox": [
        35.98694,
        32.53917,
        36.02694,
        32.57917
      ]
    },
    {
      "id": 3479,
      "name": "Kariya",
      "names": {
        "zh": "刈谷",
        "ja": "刈谷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9908201,
        "lng": 137.0087202
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.0037202,
        34.9858201,
        137.0137202,
        34.9958201
      ]
    },
    {
      "id": 3480,
      "name": "Lake Charles",
      "names": {
        "es": "Lago Charles",
        "fr": "Lac Charles",
        "zh": "查尔斯湖",
        "it": "Lago Charles",
        "ja": "チャールズ湖",
        "th": "ทะเลสาบชาร์ลส์",
        "pt": "Lago charles"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2305095,
        "lng": -93.2169807
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.316857,
        30.124501,
        -93.1288414,
        30.272203
      ]
    },
    {
      "id": 3481,
      "name": "Odessa",
      "names": {
        "zh": "敖德萨",
        "ja": "オデッサ",
        "th": "โอเดสซา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.8457149,
        "lng": -102.367687
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -102.4420576,
        31.79747,
        -102.2481799,
        31.9631525
      ]
    },
    {
      "id": 3482,
      "name": "Cagliari",
      "names": {
        "zh": "卡利亚里",
        "ja": "カリアリ",
        "th": "กาลยารี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.2171994,
        "lng": 9.113311
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        9.0063456,
        39.1580302,
        9.1818367,
        39.282764
      ]
    },
    {
      "id": 3483,
      "name": "Zaanstad",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4689235,
        "lng": 4.773783522451712
      },
      "country": "Netherlands",
      "importance": 3,
      "bbox": [
        4.6748481,
        52.4166622,
        4.8628051,
        52.5212536
      ]
    },
    {
      "id": 3484,
      "name": "Hitachi-Naka",
      "names": {
        "zh": "日立-那珂",
        "ja": "日立那珂",
        "th": "ฮิตาชิ-Naka"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.384134,
        "lng": 140.484499
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.479499,
        36.379134,
        140.489499,
        36.389134
      ]
    },
    {
      "id": 3485,
      "name": "Ordu",
      "names": {
        "zh": "奥尔杜"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9838362,
        "lng": 37.8858758
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        37.8858258,
        40.9837862,
        37.8859258,
        40.9838862
      ]
    },
    {
      "id": 3486,
      "name": "Nalgonda",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.047436,
        "lng": 79.274691
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.238345,
        17.027,
        79.299727,
        17.086265
      ]
    },
    {
      "id": 3487,
      "name": "Timon",
      "names": {
        "zh": "蒂蒙",
        "th": "ทิมอน"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1004341,
        "lng": -42.8312018
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.2059565,
        -5.4535238,
        -42.7983396,
        -4.894712
      ]
    },
    {
      "id": 3488,
      "name": "Tuscaloosa",
      "names": {
        "zh": "塔斯卡卢萨",
        "ja": "タスカルーサ",
        "th": "ทัสคาลูซา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2095614,
        "lng": -87.5675258
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.6640016,
        33.1114497,
        -87.2462346,
        33.4578849
      ]
    },
    {
      "id": 3489,
      "name": "Palo Negro",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1630257,
        "lng": -67.5534347
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.7134347,
        10.0030257,
        -67.3934347,
        10.3230257
      ]
    },
    {
      "id": 3490,
      "name": "Souk Ahras",
      "names": {
        "es": "Souk ahros"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.282865,
        "lng": 7.9537896
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        7.8997843,
        36.2448661,
        8.00739,
        36.320684
      ]
    },
    {
      "id": 3491,
      "name": "Horad Barysaw",
      "names": {
        "es": "Horad Baranda",
        "id": "Barysaw horad"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 54.2279,
        "lng": 28.505
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        28.485,
        54.2079,
        28.525,
        54.2479
      ]
    },
    {
      "id": 3492,
      "name": "Colima",
      "names": {
        "zh": "科利马",
        "ja": "コリマ州"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2433991,
        "lng": -103.7280738
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -103.8880738,
        19.0833991,
        -103.5680738,
        19.4033991
      ]
    },
    {
      "id": 3493,
      "name": "Neuss",
      "names": {
        "zh": "诺伊斯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1981778,
        "lng": 6.6916476
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.6148191,
        51.1167616,
        6.7983618,
        51.2356076
      ]
    },
    {
      "id": 3494,
      "name": "Oxford",
      "names": {
        "zh": "牛津",
        "ja": "オックスフォード",
        "th": "ออกซ์ฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7520131,
        "lng": -1.2578499
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.3042472,
        51.7109788,
        -1.1758134,
        51.7962658
      ]
    },
    {
      "id": 3495,
      "name": "Caxias",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.8654201,
        "lng": -43.353664
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.841,
        -5.3116532,
        -42.8496078,
        -4.3816073
      ]
    },
    {
      "id": 3496,
      "name": "Warner Robins",
      "names": {
        "zh": "华纳罗宾斯",
        "de": "Warner-Robins",
        "ja": "ワーナーロビン",
        "th": "วอร์เนอร์โรบิน",
        "pt": "Robins da Warner"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6200973,
        "lng": -83.606568
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.747754,
        32.522715,
        -83.575866,
        32.666389
      ]
    },
    {
      "id": 3497,
      "name": "Palmdale",
      "names": {
        "zh": "帕姆代尔",
        "th": "ปาล์มเดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5908349,
        "lng": -118.1196007
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1246007,
        34.5858349,
        -118.1146007,
        34.5958349
      ]
    },
    {
      "id": 3498,
      "name": "Hoofddorp",
      "names": {
        "zh": "霍夫多普",
        "th": "ฮุฟด์ดอร์ป"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.307842,
        "lng": 4.669865
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.61862,
        52.257201,
        4.737791,
        52.344058
      ]
    },
    {
      "id": 3499,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 48.5443087,
        "lng": -122.9919497
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        -123.2678889,
        48.2800656,
        -122.7015395,
        48.8312222
      ]
    },
    {
      "id": 3500,
      "name": "Melitopol",
      "names": {
        "zh": "梅利托波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.84159675,
        "lng": 35.37469400037561
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.3744027,
        46.8414268,
        35.375371,
        46.8418433
      ]
    },
    {
      "id": 3501,
      "name": "Zango",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.95365,
        "lng": 8.544623641156464
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        8.4134483,
        12.8318193,
        8.7216866,
        13.075316
      ]
    },
    {
      "id": 3502,
      "name": "Kafr ash Shaykh",
      "names": {
        "es": "Kafr ceniza shaykh",
        "ja": "カフェ灰シェイク"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1143,
        "lng": 30.94012
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        30.92012,
        31.0943,
        30.96012,
        31.1343
      ]
    },
    {
      "id": 3503,
      "name": "Balurghat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.2234352,
        "lng": 88.7780654
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.6180654,
        25.0634352,
        88.9380654,
        25.3834352
      ]
    },
    {
      "id": 3504,
      "name": "Hollywood",
      "names": {
        "zh": "好莱坞",
        "ja": "ハリウッド",
        "th": "ฮอลลีวู้ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.01282545,
        "lng": -80.16766973149706
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1678566,
        26.0111023,
        -80.1674709,
        26.0140478
      ]
    },
    {
      "id": 3505,
      "name": "Madinat as Sadis min Uktubar",
      "names": {
        "es": "Madinat como sadis min uktubar",
        "fr": "Madinat en tant que SADIS MIN UKTUBAR",
        "zh": "Madinat担任Sadis Min Uktubar",
        "de": "Madinat als Sadis min Uktubar",
        "it": "Madinat come Sadis Min Uktubar",
        "id": "Madinat sebagai Sadis Min Ukubar",
        "ja": "Sadis Min UktubarとしてのMadinat",
        "th": "Madinat เป็น Sadis Min Uktubar",
        "pt": "Madinat como Sadis Min Uktubar"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.94644,
        "lng": 30.91687
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.89687,
        29.92644,
        30.93687,
        29.96644
      ]
    },
    {
      "id": 3506,
      "name": "Midland",
      "names": {
        "zh": "米德兰",
        "ja": "ミッドランド",
        "th": "มิดแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.9973662,
        "lng": -102.077948
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -102.2697973,
        31.9184063,
        -102.0176654,
        32.1293873
      ]
    },
    {
      "id": 3507,
      "name": "Dibrugarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5,
        "lng": 95
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        94.3,
        26.8,
        95.7,
        28.2
      ]
    },
    {
      "id": 3508,
      "name": "Veraval",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.9101099,
        "lng": 70.365279
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        70.205279,
        20.7501099,
        70.525279,
        21.0701099
      ]
    },
    {
      "id": 3509,
      "name": "Alagoinhas",
      "names": {
        "zh": "阿拉戈伊尼亚斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.133526,
        "lng": -38.420772
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.5866603,
        -12.285182,
        -38.2730441,
        -11.9274241
      ]
    },
    {
      "id": 3510,
      "name": "Mahajanga",
      "names": {
        "zh": "马任加"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -15.71667,
        "lng": 46.31667
      },
      "country": "Madagascar",
      "importance": 1,
      "bbox": [
        46.29667,
        -15.73667,
        46.33667,
        -15.69667
      ]
    },
    {
      "id": 3511,
      "name": "Salavat",
      "names": {
        "zh": "萨拉瓦特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.361687,
        "lng": 55.924641
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        55.8153691,
        53.3111804,
        55.9913884,
        53.4510935
      ]
    },
    {
      "id": 3512,
      "name": "Gandajika",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -6.75,
        "lng": 23.95
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        23.93,
        -6.77,
        23.97,
        -6.73
      ]
    },
    {
      "id": 3513,
      "name": "Leesburg",
      "names": {
        "ja": "リーズバーグ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.810823,
        "lng": -81.8778582
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.954618,
        28.6585302,
        -81.7718258,
        28.8461615
      ]
    },
    {
      "id": 3514,
      "name": "Regensburg",
      "names": {
        "es": "Ratisbona",
        "zh": "雷根斯堡",
        "ja": "レーゲンスブルク",
        "th": "เรเกนสบูร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.0195333,
        "lng": 12.0974869
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.0290745,
        48.9667457,
        12.1916078,
        49.0764158
      ]
    },
    {
      "id": 3515,
      "name": "Port Arthur",
      "names": {
        "zh": "亚瑟港",
        "it": "Porto Arthur",
        "ja": "ポートアーサー",
        "th": "พอร์ตอาร์เธอร์",
        "pt": "Porto arthur"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.8716577,
        "lng": -93.9332302
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.106597,
        29.6331171,
        -93.8113504,
        30.032348
      ]
    },
    {
      "id": 3516,
      "name": "Almetyevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9005008,
        "lng": 52.2963777
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        52.2035972,
        54.8500741,
        52.4624232,
        54.9508817
      ]
    },
    {
      "id": 3517,
      "name": "Lucapa",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.419604,
        "lng": 20.740015
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        20.7200154202,
        -8.4396036592,
        20.7600154202,
        -8.3996036592
      ]
    },
    {
      "id": 3518,
      "name": "Tama",
      "names": {
        "zh": "多摩",
        "ja": "多摩",
        "th": "มะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.637188,
        "lng": 139.443503
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.393498,
        35.604698,
        139.4739365,
        35.657961
      ]
    },
    {
      "id": 3519,
      "name": "Seogwipo",
      "names": {
        "zh": "西归浦",
        "ja": "西帰浦"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 33.252845,
        "lng": 126.5610278
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.1549045,
        33.0558675,
        127.0588484,
        33.4831165
      ]
    },
    {
      "id": 3520,
      "name": "Tochigi",
      "names": {
        "zh": "栃木县",
        "ja": "栃木",
        "th": "จังหวัดโทะชิงิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3818177,
        "lng": 139.733591
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.563841,
        36.199682,
        139.812442,
        36.497744
      ]
    },
    {
      "id": 3521,
      "name": "Moanda",
      "names": {
        "zh": "莫安达"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.9282219,
        "lng": 12.378028
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        12.039074,
        -6.0782192,
        12.7130252,
        -5.5920407
      ]
    },
    {
      "id": 3522,
      "name": "Barreiras",
      "names": {
        "zh": "巴雷拉斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.1440031,
        "lng": -44.9967406
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.4797997,
        -12.427156,
        -44.5797248,
        -11.5995704
      ]
    },
    {
      "id": 3523,
      "name": "Tete",
      "names": {
        "zh": "太特",
        "th": "หนิง"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.1603989,
        "lng": 33.5871474
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        33.4271474,
        -16.3203989,
        33.7471474,
        -16.0003989
      ]
    },
    {
      "id": 3524,
      "name": "Fyzabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.77155,
        "lng": 82.149511
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.095026,
        26.7555,
        82.143353,
        26.777693
      ]
    },
    {
      "id": 3525,
      "name": "Noda",
      "names": {
        "zh": "野田",
        "ja": "野田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9549076,
        "lng": 139.8748667
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.775949,
        35.912941,
        139.951894,
        36.104101
      ]
    },
    {
      "id": 3526,
      "name": "Kanasin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9350466,
        "lng": -89.5577239
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.5987585,
        20.9046444,
        -89.5344401,
        20.9810657
      ]
    },
    {
      "id": 3527,
      "name": "Ixtlahuaca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.569814,
        "lng": -99.76534
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.773861,
        19.560779,
        -99.751679,
        19.580718
      ]
    },
    {
      "id": 3528,
      "name": "Hoeryong",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 42.4342802,
        "lng": 129.7542469
      },
      "country": "North Korea",
      "importance": 2,
      "bbox": [
        129.3037604,
        42.1281478,
        130.0289654,
        42.6797007
      ]
    },
    {
      "id": 3529,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.3361663,
        "lng": -121.890591
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.0460405,
        37.1231596,
        -121.5858438,
        37.4691477
      ]
    },
    {
      "id": 3530,
      "name": "Newcastle",
      "names": {
        "zh": "纽卡斯尔",
        "ja": "ニューカッスル",
        "th": "นิวคาสเซิล"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -32.9192953,
        "lng": 151.7795348
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        151.7596675,
        -32.937805,
        151.803932,
        -32.901482
      ]
    },
    {
      "id": 3531,
      "name": "Muskegon",
      "names": {
        "zh": "马斯基根",
        "th": "มัสกี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.2341813,
        "lng": -86.2483921
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.3481957,
        43.2023602,
        -86.1693721,
        43.269701
      ]
    },
    {
      "id": 3532,
      "name": "Sievierodonetsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.948478,
        "lng": 38.474814
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.428258,
        48.925722,
        38.538036,
        48.987043
      ]
    },
    {
      "id": 3533,
      "name": "Sinpo",
      "names": {
        "zh": "新报"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.0381785,
        "lng": 128.2068407
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        128.2018407,
        40.0331785,
        128.2118407,
        40.0431785
      ]
    },
    {
      "id": 3534,
      "name": "Dutse",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.7594,
        "lng": 9.33921
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        9.31921,
        11.7394,
        9.35921,
        11.7794
      ]
    },
    {
      "id": 3535,
      "name": "Silchar",
      "names": {
        "zh": "锡尔杰尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.80948,
        "lng": 92.792654
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        92.761174,
        24.780254,
        92.862151,
        24.851242
      ]
    },
    {
      "id": 3536,
      "name": "Minglanilla",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.24498,
        "lng": 123.7964
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7764,
        10.22498,
        123.8164,
        10.26498
      ]
    },
    {
      "id": 3537,
      "name": "Ueda",
      "names": {
        "zh": "上田",
        "ja": "上田",
        "th": "อุเอดะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4021192,
        "lng": 138.2490506
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.069068,
        36.217194,
        138.413973,
        36.554671
      ]
    },
    {
      "id": 3538,
      "name": "Macon",
      "names": {
        "zh": "梅肯",
        "ja": "メーコン",
        "th": "แมคอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8406946,
        "lng": -83.6324022
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.8927214,
        32.660643,
        -83.48943,
        32.9528742
      ]
    },
    {
      "id": 3539,
      "name": "Dahuaishu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.257563,
        "lng": 111.670532
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.659401287,
        36.2485477573,
        111.681663132,
        36.2665776304
      ]
    },
    {
      "id": 3540,
      "name": "Shantipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.2464,
        "lng": 88.4437
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.2837,
        23.0864,
        88.6037,
        23.4064
      ]
    },
    {
      "id": 3541,
      "name": "Moriguchi",
      "names": {
        "zh": "守口",
        "ja": "守口"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7384473,
        "lng": 135.5644017
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5643517,
        34.7383973,
        135.5644517,
        34.7384973
      ]
    },
    {
      "id": 3542,
      "name": "Kansas City",
      "names": {
        "es": "Ciudad de Kansas",
        "zh": "堪萨斯城",
        "id": "Kota Kansas",
        "ja": "カンザスシティ",
        "th": "เมืองแคนซัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1134562,
        "lng": -94.626497
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.9084171,
        39.0435716,
        -94.5882049,
        39.202911
      ]
    },
    {
      "id": 3543,
      "name": "Yilan",
      "names": {
        "zh": "宜兰"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.7519538,
        "lng": 121.7533344
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        121.7270872,
        24.7132671,
        121.7891496,
        24.7844915
      ]
    },
    {
      "id": 3544,
      "name": "Siem Reap",
      "names": {
        "fr": "Siem récolter",
        "zh": "暹粒市",
        "de": "Siem-Reap",
        "ja": "シェムリアップ",
        "th": "เสียมเรียบ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.3617562,
        "lng": 103.8590321
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        103.6990321,
        13.2017562,
        104.0190321,
        13.5217562
      ]
    },
    {
      "id": 3545,
      "name": "Golmeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.7841,
        "lng": 46.5951
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        46.5751,
        33.7641,
        46.6151,
        33.8041
      ]
    },
    {
      "id": 3546,
      "name": "Fengyicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.34841,
        "lng": 114.91957
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.89957,
        24.32841,
        114.93957,
        24.36841
      ]
    },
    {
      "id": 3547,
      "name": "Hindupur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.8306127,
        "lng": 77.492635
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.452635,
        13.7906127,
        77.532635,
        13.8706127
      ]
    },
    {
      "id": 3548,
      "name": "Kawashiri",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9138823,
        "lng": 135.4837281
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.4737281,
        34.9038823,
        135.4937281,
        34.9238823
      ]
    },
    {
      "id": 3549,
      "name": "Pocos de Caldas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7900318,
        "lng": -46.5647928
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.69,
        -21.9945556,
        -46.3985725,
        -21.6450347
      ]
    },
    {
      "id": 3550,
      "name": "Logrono",
      "names": {
        "es": "Logroño",
        "fr": "Logroño",
        "zh": "洛格罗尼奥",
        "ja": "ログローニョ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.4661196,
        "lng": -2.4396676
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -2.5422727,
        42.4288461,
        -2.341968,
        42.5199157
      ]
    },
    {
      "id": 3551,
      "name": "Sunnyvale",
      "names": {
        "zh": "桑尼维尔",
        "ja": "サニーベール",
        "th": "ซันนีเวล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3784195,
        "lng": -122.0307759
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0357759,
        37.3734195,
        -122.0257759,
        37.3834195
      ]
    },
    {
      "id": 3552,
      "name": "Peristeri",
      "names": {
        "zh": "佩里斯特里"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0156425,
        "lng": 23.69124689566985
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.6658899,
        37.9962245,
        23.7183025,
        38.0350868
      ]
    },
    {
      "id": 3553,
      "name": "Baubau",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -5.452479,
        "lng": 122.603579
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        122.603529,
        -5.452529,
        122.603629,
        -5.452429
      ]
    },
    {
      "id": 3554,
      "name": "Mudon",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.2558972,
        "lng": 97.7292529
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        97.4949988,
        15.9606112,
        97.8675214,
        16.412935
      ]
    },
    {
      "id": 3555,
      "name": "Taza",
      "names": {
        "zh": "塔扎"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.230155,
        "lng": -4.010104
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -4.0435258,
        34.1930894,
        -3.9682952,
        34.2451054
      ]
    },
    {
      "id": 3556,
      "name": "Erode",
      "names": {
        "es": "Erosionar",
        "fr": "Éroder",
        "zh": "侵蚀",
        "de": "Erodieren",
        "it": "Erodere",
        "id": "Mengikis",
        "ja": "浸食",
        "th": "กัดกร่อน",
        "pt": "Corroer"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.3306483,
        "lng": 77.7276519
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.5676519,
        11.1706483,
        77.8876519,
        11.4906483
      ]
    },
    {
      "id": 3557,
      "name": "Gwangyang",
      "names": {
        "zh": "光阳"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.9406452,
        "lng": 127.6959512
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        127.5251984,
        34.8672008,
        127.7972121,
        35.1837804
      ]
    },
    {
      "id": 3558,
      "name": "Beawar",
      "names": {
        "zh": "贝阿沃尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.096984,
        "lng": 74.3176
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.292521,
        26.070885,
        74.346079,
        26.132678
      ]
    },
    {
      "id": 3559,
      "name": "Abaetetuba",
      "names": {
        "zh": "阿巴埃特图巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.7217787,
        "lng": -48.8788106
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.1545324,
        -1.9566124,
        -48.5304107,
        -1.5214693
      ]
    },
    {
      "id": 3560,
      "name": "Kukichuo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.066099,
        "lng": 139.6733093
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6633093,
        36.056099,
        139.6833093,
        36.076099
      ]
    },
    {
      "id": 3561,
      "name": "Consolacion",
      "names": {
        "de": "Consolación"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3760149,
        "lng": 123.9568892
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.9168892,
        10.3360149,
        123.9968892,
        10.4160149
      ]
    },
    {
      "id": 3562,
      "name": "Gonbad-e Kavus",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.25004,
        "lng": 55.16721
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        55.14721,
        37.23004,
        55.18721,
        37.27004
      ]
    },
    {
      "id": 3563,
      "name": "Al Manaqil",
      "names": {
        "es": "Al manáqil",
        "ja": "アルマンキル"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 14.2467437,
        "lng": 32.9858537
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.8258537,
        14.0867437,
        33.1458537,
        14.4067437
      ]
    },
    {
      "id": 3564,
      "name": "Tororo",
      "names": {
        "zh": "托罗罗"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6917855,
        "lng": 34.1790712
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.1390712,
        0.6517855,
        34.2190712,
        0.7317855
      ]
    },
    {
      "id": 3565,
      "name": "Miass",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0505685,
        "lng": 60.1087125
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.034298,
        54.950821,
        60.1883841,
        55.1774822
      ]
    },
    {
      "id": 3566,
      "name": "Mzuzu",
      "names": {
        "zh": "姆祖祖"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -11.4607518,
        "lng": 34.0226422
      },
      "country": "Malawi",
      "importance": 3,
      "bbox": [
        33.8626422,
        -11.6207518,
        34.1826422,
        -11.3007518
      ]
    },
    {
      "id": 3567,
      "name": "Delicias",
      "names": {
        "zh": "德利西亚斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.1909814,
        "lng": -105.469426
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.629426,
        28.0309814,
        -105.309426,
        28.3509814
      ]
    },
    {
      "id": 3568,
      "name": "Nakhodka",
      "names": {
        "zh": "纳霍德卡"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 42.814715,
        "lng": 132.88689
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        132.853302,
        42.776974,
        132.963135,
        42.852196
      ]
    },
    {
      "id": 3569,
      "name": "Quevedo",
      "names": {
        "zh": "克韦多"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.02863,
        "lng": -79.46352
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -79.56352,
        -1.12863,
        -79.36352,
        -0.92863
      ]
    },
    {
      "id": 3570,
      "name": "Asaka",
      "names": {
        "zh": "朝霞"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7970861,
        "lng": 139.593733
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.572775,
        35.778366,
        139.623366,
        35.835246
      ]
    },
    {
      "id": 3571,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.3361663,
        "lng": -121.890591
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.0460405,
        37.1231596,
        -121.5858438,
        37.4691477
      ]
    },
    {
      "id": 3572,
      "name": "Pomona",
      "names": {
        "zh": "波莫纳",
        "th": "โพโมนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0553813,
        "lng": -117.7517496
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8286722,
        34.0186653,
        -117.7112333,
        34.1126225
      ]
    },
    {
      "id": 3573,
      "name": "Cao Lanh",
      "names": {
        "ja": "カオラニ",
        "th": "เฉา lanh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.4620322,
        "lng": 105.6357948
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.5577666,
        10.4068922,
        105.6918834,
        10.5132664
      ]
    },
    {
      "id": 3574,
      "name": "Foggia",
      "names": {
        "zh": "福贾",
        "ja": "フォッジャ",
        "th": "ฟอกเกีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.460194,
        "lng": 15.552676
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        15.519408,
        41.437153,
        15.581754,
        41.47948
      ]
    },
    {
      "id": 3575,
      "name": "Escondido",
      "names": {
        "th": "เอสคอนดิโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1216751,
        "lng": -117.0814849
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.1461224,
        33.0576631,
        -116.9938833,
        33.2116171
      ]
    },
    {
      "id": 3576,
      "name": "Vryheid",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -27.768207,
        "lng": 30.790671
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        30.773067,
        -27.786232,
        30.814167,
        -27.758253
      ]
    },
    {
      "id": 3577,
      "name": "Riobamba",
      "names": {
        "zh": "里奥班巴"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.6732765,
        "lng": -78.6482479
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.7235915,
        -1.7105575,
        -78.5954866,
        -1.6328761
      ]
    },
    {
      "id": 3578,
      "name": "As Suwayhirah as Sahil",
      "names": {
        "es": "Como suwayhirah como sahil",
        "fr": "Comme suwayhirah comme Sahil",
        "zh": "作为Suwayhirah作为撒希尔",
        "de": "Als suwayhirah als sahil",
        "it": "Come Suwayhirah come Sahil",
        "id": "Sebagai suwayhirah sebagai sahil",
        "ja": "SuwayhirahとしてSahilとして",
        "th": "เช่นเดียวกับ Suwayhirah ในฐานะ Sahil",
        "pt": "Como Suayhirah como Sahil"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 24.31642,
        "lng": 56.78289
      },
      "country": "Oman",
      "importance": 3
    },
    {
      "id": 3579,
      "name": "Paderborn",
      "names": {
        "zh": "帕德博恩",
        "ja": "パーダーボルン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7177044,
        "lng": 8.752653
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6362871,
        51.6542346,
        8.9005872,
        51.8008385
      ]
    },
    {
      "id": 3580,
      "name": "Sayama",
      "names": {
        "zh": "狭山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8528971,
        "lng": 139.4122999
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.360885,
        35.817707,
        139.478305,
        35.9009486
      ]
    },
    {
      "id": 3581,
      "name": "Miskolc",
      "names": {
        "zh": "米什科尔茨",
        "ja": "ミシュコルツ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1030643,
        "lng": 20.7900429
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        20.4816773,
        48.0235629,
        20.8698736,
        48.1549192
      ]
    },
    {
      "id": 3582,
      "name": "Florencio Varela",
      "names": {
        "zh": "Florencio瓦雷拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8044384,
        "lng": -58.2783224
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.3025334,
        -34.8223489,
        -58.238823,
        -34.7688248
      ]
    },
    {
      "id": 3583,
      "name": "Manzanillo",
      "names": {
        "zh": "马惹尼诺",
        "de": "In manzanillo"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3452734,
        "lng": -77.1199723
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.2468747,
        20.0574875,
        -76.9647626,
        20.4172097
      ]
    },
    {
      "id": 3584,
      "name": "Pasadena",
      "names": {
        "zh": "帕萨迪纳",
        "ja": "パサデナ",
        "th": "พาซาดีน่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.6910753,
        "lng": -95.2092076
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.234677,
        29.55792,
        -94.9826401,
        29.7402417
      ]
    },
    {
      "id": 3585,
      "name": "Talcahuano",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -36.7144863,
        "lng": -73.1141087
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -73.1656442,
        -36.7955348,
        -73.0156812,
        -36.6019384
      ]
    },
    {
      "id": 3586,
      "name": "Kerch",
      "names": {
        "fr": "Kertch",
        "zh": "刻赤"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 45.3534002,
        "lng": 36.4538645
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        36.3495724,
        45.2130436,
        36.6379401,
        45.3950007
      ]
    },
    {
      "id": 3587,
      "name": "M'Sila",
      "names": {
        "zh": "姆西拉"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7087553,
        "lng": 4.5371552
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        4.4314788,
        35.5705599,
        4.60533,
        35.874381
      ]
    },
    {
      "id": 3588,
      "name": "Patos de Minas",
      "names": {
        "ja": "パトス・デ・ミナス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.580727,
        "lng": -46.511488
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.549631,
        -18.646321,
        -46.460359,
        -18.545488
      ]
    },
    {
      "id": 3589,
      "name": "Mariveles",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4340599,
        "lng": 120.4862073
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.2545663,
        14.2853,
        120.7529456,
        14.5398421
      ]
    },
    {
      "id": 3590,
      "name": "Copiapo",
      "names": {
        "es": "Copiapó",
        "zh": "科皮亚波"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -27.3664685,
        "lng": -70.3322753
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -71.14235,
        -28.183388,
        -68.2632053,
        -26.6643281
      ]
    },
    {
      "id": 3591,
      "name": "Kragujevac",
      "names": {
        "zh": "克拉古耶瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0101302,
        "lng": 20.9284269
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.9234269,
        44.0051302,
        20.9334269,
        44.0151302
      ]
    },
    {
      "id": 3592,
      "name": "Badajoz",
      "names": {
        "zh": "巴达霍斯",
        "th": "บาดาโฮซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.878187,
        "lng": -6.9701115
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -7.1592952,
        38.5548501,
        -6.5595629,
        39.1940413
      ]
    },
    {
      "id": 3593,
      "name": "Nimes",
      "names": {
        "es": "De Nimes",
        "fr": "Nîmes",
        "zh": "尼姆",
        "ja": "ニーム",
        "th": "นีม"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.8374249,
        "lng": 4.3600687
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.2356708,
        43.7412659,
        4.4496605,
        43.9230177
      ]
    },
    {
      "id": 3594,
      "name": "Sumbawanga",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -7.96667,
        "lng": 31.61667
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        31.59667,
        -7.98667,
        31.63667,
        -7.94667
      ]
    },
    {
      "id": 3595,
      "name": "Araguaina",
      "names": {
        "zh": "阿拉瓜伊纳",
        "th": "อารากวัยนา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.193241,
        "lng": -48.2018597
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.2657926,
        -7.562,
        -48.052,
        -6.942
      ]
    },
    {
      "id": 3596,
      "name": "Rimini",
      "names": {
        "zh": "里米尼",
        "ja": "リミニ",
        "th": "ริมินี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.0593886,
        "lng": 12.5684371
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.4384859,
        43.9696762,
        12.6328853,
        44.1157894
      ]
    },
    {
      "id": 3597,
      "name": "Ocumare del Tuy",
      "names": {
        "es": "Ocumare del tiy",
        "fr": "OCUMARE DEL TYY",
        "it": "Ocubare del tuy",
        "ja": "オブマーレデルチュー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1136963,
        "lng": -66.7757458
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.892823,
        9.9980984,
        -66.6522288,
        10.1535197
      ]
    },
    {
      "id": 3598,
      "name": "Mallawi",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 27.7314,
        "lng": 30.84165
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.82165,
        27.7114,
        30.86165,
        27.7514
      ]
    },
    {
      "id": 3599,
      "name": "Komaki",
      "names": {
        "zh": "小牧"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2889284,
        "lng": 136.9279765
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.9229765,
        35.2839284,
        136.9329765,
        35.2939284
      ]
    },
    {
      "id": 3600,
      "name": "Valdivia",
      "names": {
        "zh": "瓦尔迪维亚"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -39.8141262,
        "lng": -73.2459859
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -73.4139412,
        -40.0320178,
        -72.9208535,
        -39.6370796
      ]
    },
    {
      "id": 3601,
      "name": "Valle de Santiago",
      "names": {
        "ja": "ヴァッレデサンティアゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3916556,
        "lng": -101.193665
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.460127,
        20.2464152,
        -101.0785289,
        20.5471635
      ]
    },
    {
      "id": 3602,
      "name": "Toda",
      "names": {
        "zh": "户田",
        "ja": "戸田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8175874,
        "lng": 139.6778944
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6186797,
        35.7958344,
        139.6992887,
        35.835324
      ]
    },
    {
      "id": 3603,
      "name": "Clermont-Ferrand",
      "names": {
        "zh": "克莱蒙费朗",
        "ja": "クレルモンフェランフランス",
        "th": "มอนต์-ferrand"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7774551,
        "lng": 3.0819427
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.0532561,
        45.7556941,
        3.1721761,
        45.8183838
      ]
    },
    {
      "id": 3604,
      "name": "Gulu",
      "names": {
        "zh": "古卢"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.0199023,
        "lng": 32.38825103502008
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.1855675,
        2.7365897,
        32.6597075,
        3.3035837
      ]
    },
    {
      "id": 3605,
      "name": "Pointe-a-Pitre",
      "names": {
        "fr": "Pointe-à-Pitre",
        "zh": "普安特-一个-pitre",
        "ja": "ポイント・ア・pitre",
        "th": "ปวงรูปแบบ pitre"
      },
      "countryCode": "GP",
      "latLng": {
        "lat": 16.2408636,
        "lng": -61.5334077
      },
      "country": "Guadeloupe",
      "importance": 3,
      "bbox": [
        -61.5499533,
        16.2131887,
        -61.5255942,
        16.2529224
      ]
    },
    {
      "id": 3606,
      "name": "Shahrud",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.41819,
        "lng": 54.97628
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        54.95628,
        36.39819,
        54.99628,
        36.43819
      ]
    },
    {
      "id": 3607,
      "name": "Kutaisi",
      "names": {
        "zh": "库塔伊西"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 42.2716078,
        "lng": 42.7054475
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        42.5981083,
        42.1971209,
        42.741879,
        42.3209444
      ]
    },
    {
      "id": 3608,
      "name": "Kashikishi",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -9.30838,
        "lng": 28.73596
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        28.71596,
        -9.32838,
        28.75596,
        -9.28838
      ]
    },
    {
      "id": 3609,
      "name": "Penjamo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4313763,
        "lng": -101.724118
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.764118,
        20.3913763,
        -101.684118,
        20.4713763
      ]
    },
    {
      "id": 3610,
      "name": "Odivelas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7904338,
        "lng": -9.1792617
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.24112,
        38.7602212,
        -9.1498877,
        38.8305301
      ]
    },
    {
      "id": 3611,
      "name": "New Bedford",
      "names": {
        "fr": "Nouveau Bedford",
        "zh": "新贝德福德",
        "de": "Neues Bedford",
        "it": "Nuovo Bedford",
        "id": "Bedford baru",
        "ja": "ニューベッドフォード",
        "th": "ใหม่ Bedford"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6362152,
        "lng": -70.934205
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.9775124,
        41.591389,
        -70.8993923,
        41.7446715
      ]
    },
    {
      "id": 3612,
      "name": "Saumlaki",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.9741711,
        "lng": 131.3048761
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        131.2648761,
        -8.0141711,
        131.3448761,
        -7.9341711
      ]
    },
    {
      "id": 3613,
      "name": "Gloucester",
      "names": {
        "zh": "告士打道",
        "ja": "グロスター",
        "th": "กลอสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8653705,
        "lng": -2.2458192
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.3013858,
        51.8075743,
        -2.1772639,
        51.8852166
      ]
    },
    {
      "id": 3614,
      "name": "Potchefstroom",
      "names": {
        "zh": "波切夫斯特鲁姆"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.7150922,
        "lng": 27.1013887
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        26.9413887,
        -26.8750922,
        27.2613887,
        -26.5550922
      ]
    },
    {
      "id": 3615,
      "name": "Imabari",
      "names": {
        "zh": "今治",
        "ja": "今治"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0658182,
        "lng": 132.9976758
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.784119,
        33.901847,
        133.2921312,
        34.3096519
      ]
    },
    {
      "id": 3616,
      "name": "Arnhem",
      "names": {
        "zh": "阿纳姆",
        "ja": "アーネム"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.984257,
        "lng": 5.9108573
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        5.8029606,
        51.9334642,
        5.9903401,
        52.0778905
      ]
    },
    {
      "id": 3617,
      "name": "Abbottabad",
      "names": {
        "zh": "阿伯塔巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1436136,
        "lng": 73.2132747
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.0532747,
        33.9836136,
        73.3732747,
        34.3036136
      ]
    },
    {
      "id": 3618,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.3972539,
        "lng": -58.0173305
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.0568691,
        -31.4188807,
        -57.9832783,
        -31.3356591
      ]
    },
    {
      "id": 3619,
      "name": "West Bromwich",
      "names": {
        "id": "Bromwich Barat",
        "th": "เวสต์บรอมวิช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.51868,
        "lng": -1.9945
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.048763,
        52.505778,
        -1.947238,
        52.552951
      ]
    },
    {
      "id": 3620,
      "name": "Centro Habana",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 23.138589,
        "lng": -82.374466
      },
      "country": "Cuba",
      "importance": 3,
      "bbox": [
        -82.374466,
        23.138589,
        -82.374466,
        23.138589
      ]
    },
    {
      "id": 3621,
      "name": "Sagay",
      "names": {
        "zh": "萨盖"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8960681,
        "lng": 123.4154617
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.2588101,
        10.6601822,
        123.7348474,
        11.115721
      ]
    },
    {
      "id": 3622,
      "name": "Marbella",
      "names": {
        "zh": "马贝拉",
        "ja": "マルベージャ",
        "th": "มาร์เบลลา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.508976,
        "lng": -4.88562
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.0146669,
        36.4600069,
        -4.7305677,
        36.5549709
      ]
    },
    {
      "id": 3623,
      "name": "Lang Son",
      "names": {
        "es": "Hijo de lang",
        "fr": "Fils de Lang",
        "zh": "郎儿子",
        "de": "Lang Sohn",
        "it": "Lang figlio",
        "id": "Langsing",
        "th": "พระบุตร",
        "pt": "Filho de lang"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.8528759,
        "lng": 106.7597458
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.696762,
        21.781578,
        106.8002233,
        21.9275983
      ]
    },
    {
      "id": 3624,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.1200758,
        "lng": -23.634818134026133
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        -23.7816217,
        14.8998532,
        -23.4283476,
        15.340184
      ]
    },
    {
      "id": 3625,
      "name": "Iruma",
      "names": {
        "zh": "入间"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8358142,
        "lng": 139.3909293
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.314997,
        35.774001,
        139.4194417,
        35.861961
      ]
    },
    {
      "id": 3626,
      "name": "Dongsheng",
      "names": {
        "zh": "东升"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.716825,
        "lng": 112.522853
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.482853,
        29.676825,
        112.562853,
        29.756825
      ]
    },
    {
      "id": 3627,
      "name": "Marvdasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.8742,
        "lng": 52.8025
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        52.7825,
        29.8542,
        52.8225,
        29.8942
      ]
    },
    {
      "id": 3628,
      "name": "`Ajlun",
      "names": {},
      "countryCode": "JO",
      "latLng": {
        "lat": 32.33385,
        "lng": 35.75238
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.73238,
        32.31385,
        35.77238,
        32.35385
      ]
    },
    {
      "id": 3629,
      "name": "Chingola",
      "names": {
        "zh": "钦戈拉"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.5474926,
        "lng": 27.8600422
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        27.8200422,
        -12.5874926,
        27.9000422,
        -12.5074926
      ]
    },
    {
      "id": 3630,
      "name": "Mauli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 31.1779361,
        "lng": 75.7578456
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.7378456,
        31.1579361,
        75.7778456,
        31.1979361
      ]
    },
    {
      "id": 3631,
      "name": "Fairfield",
      "names": {
        "zh": "费尔菲尔德",
        "ja": "フェアフィールド",
        "th": "แฟร์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2493581,
        "lng": -122.039966
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.174914,
        38.158498,
        -121.8919794,
        38.3132053
      ]
    },
    {
      "id": 3632,
      "name": "Habra",
      "names": {
        "zh": "哈布拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.837257,
        "lng": 88.657766
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.624391,
        22.81623,
        88.68578,
        22.869265
      ]
    },
    {
      "id": 3633,
      "name": "Mostaganem",
      "names": {
        "zh": "穆斯塔加奈姆"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.9302672,
        "lng": 0.094365
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        0.089365,
        35.9252672,
        0.099365,
        35.9352672
      ]
    },
    {
      "id": 3634,
      "name": "Sibiu",
      "names": {
        "zh": "锡比乌",
        "ja": "シビウ",
        "th": "ซีบีอู"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.7973912,
        "lng": 24.1519202
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        23.9219067,
        45.6338743,
        24.2445941,
        45.8570932
      ]
    },
    {
      "id": 3635,
      "name": "Pemba",
      "names": {
        "zh": "奔巴岛",
        "ja": "ペンバ"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -12.97395,
        "lng": 40.51775
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        40.49775,
        -12.99395,
        40.53775,
        -12.95395
      ]
    },
    {
      "id": 3636,
      "name": "Naperville",
      "names": {
        "zh": "内珀维尔",
        "ja": "ネーパーヴィル"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7489477,
        "lng": -88.2001945
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.2002445,
        41.7488977,
        -88.2001445,
        41.7489977
      ]
    },
    {
      "id": 3637,
      "name": "Quilpue",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0498135,
        "lng": -71.4415282
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.4939299,
        -33.2465302,
        -70.9923466,
        -33.0003926
      ]
    },
    {
      "id": 3638,
      "name": "Yonago",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4276408,
        "lng": 133.331459
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.226853,
        35.374067,
        133.48055,
        35.519628
      ]
    },
    {
      "id": 3639,
      "name": "Dundee",
      "names": {
        "zh": "邓迪",
        "ja": "ダンディー",
        "th": "ดันดี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 56.4605938,
        "lng": -2.97019
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.13019,
        56.3005938,
        -2.81019,
        56.6205938
      ]
    },
    {
      "id": 3640,
      "name": "Disuq",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 31.13305,
        "lng": 30.64649
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.62649,
        31.11305,
        30.66649,
        31.15305
      ]
    },
    {
      "id": 3641,
      "name": "Kopeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.105752,
        "lng": 61.61374
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        61.568863,
        55.070751,
        61.721638,
        55.190445
      ]
    },
    {
      "id": 3642,
      "name": "Bellevue",
      "names": {
        "zh": "贝尔维尤",
        "ja": "ベルビュー",
        "th": "แบล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6144219,
        "lng": -122.192337
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.2228062,
        47.535013,
        -122.0872197,
        47.660788
      ]
    },
    {
      "id": 3643,
      "name": "Binghamton",
      "names": {
        "zh": "宾厄姆顿",
        "ja": "ビンガムトン",
        "th": "บิงแฮมตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.098698,
        "lng": -75.9125187
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.950133,
        42.071984,
        -75.856933,
        42.131497
      ]
    },
    {
      "id": 3644,
      "name": "Salamanca",
      "names": {
        "zh": "萨拉曼卡",
        "ja": "サラマンカ",
        "th": "ซาลามันกา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.9651572,
        "lng": -5.6640182
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -5.735802,
        40.9146276,
        -5.6226215,
        40.9931932
      ]
    },
    {
      "id": 3645,
      "name": "Mbanza Kongo",
      "names": {
        "es": "Mbbanz kongo"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -6.2678204,
        "lng": 14.2417957
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        14.0817957,
        -6.4278204,
        14.4017957,
        -6.1078204
      ]
    },
    {
      "id": 3646,
      "name": "Nchelenge",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -9.3476333,
        "lng": 28.7352607
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        28.6952607,
        -9.3876333,
        28.7752607,
        -9.3076333
      ]
    },
    {
      "id": 3647,
      "name": "Ambala",
      "names": {
        "zh": "安巴拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.3843674,
        "lng": 76.770421
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.610421,
        30.2243674,
        76.930421,
        30.5443674
      ]
    },
    {
      "id": 3648,
      "name": "Turbat",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 26.0027894,
        "lng": 63.0505609
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        62.9741585,
        25.9741146,
        63.1029817,
        26.0151124
      ]
    },
    {
      "id": 3649,
      "name": "Mati",
      "names": {
        "zh": "马蒂"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9514062,
        "lng": 126.2167274
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        126.09071,
        6.1332718,
        126.4917508,
        7.08391
      ]
    },
    {
      "id": 3650,
      "name": "Mangghystau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.7362452,
        "lng": 53.1681252
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        49.8082944,
        41.246392,
        56.7441058,
        46.5201311
      ]
    },
    {
      "id": 3651,
      "name": "Malakal",
      "names": {
        "zh": "马拉卡尔"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 9.5388487,
        "lng": 31.6584168
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        31.4984168,
        9.3788487,
        31.8184168,
        9.6988487
      ]
    },
    {
      "id": 3652,
      "name": "Bacau",
      "names": {
        "zh": "巴克乌"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.562206,
        "lng": 26.909449
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        26.862469,
        46.507577,
        26.954658,
        46.619563
      ]
    },
    {
      "id": 3653,
      "name": "Osorno",
      "names": {
        "zh": "奥索尔诺"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -40.5748316,
        "lng": -73.1410745
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -73.1460745,
        -40.5798316,
        -73.1360745,
        -40.5698316
      ]
    },
    {
      "id": 3654,
      "name": "Elkhart",
      "names": {
        "zh": "埃尔克哈特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.680648,
        "lng": -85.9717925
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.9767925,
        41.675648,
        -85.9667925,
        41.685648
      ]
    },
    {
      "id": 3655,
      "name": "Topeka",
      "names": {
        "zh": "托皮卡",
        "ja": "トピーカ",
        "th": "โทพีกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.042021,
        "lng": -95.698306
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -95.812638,
        38.917194,
        -95.603557,
        39.1094
      ]
    },
    {
      "id": 3656,
      "name": "Man",
      "names": {
        "es": "Hombre",
        "fr": "L'homme",
        "zh": "男子",
        "de": "Mann",
        "it": "Uomo",
        "id": "Pria",
        "ja": "おとこのひと",
        "th": "ชาย",
        "pt": "Cara"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 54.1936805,
        "lng": -4.5591148
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -5.1707285,
        53.845,
        -3.9654061,
        54.5534288
      ]
    },
    {
      "id": 3657,
      "name": "Mogi Guacu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.372222,
        "lng": -46.9425
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2426446,
        -22.4014845,
        -46.7905566,
        -22.066
      ]
    },
    {
      "id": 3658,
      "name": "Joliet",
      "names": {
        "zh": "乔利埃特",
        "ja": "ジョリエット"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5263603,
        "lng": -88.0840212
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.3621969,
        41.4304041,
        -87.9903608,
        41.5948417
      ]
    },
    {
      "id": 3659,
      "name": "Pyatigorsk",
      "names": {
        "de": "Pjatigorsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.042691,
        "lng": 43.05134
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.961803,
        43.997589,
        43.126386,
        44.080229
      ]
    },
    {
      "id": 3660,
      "name": "Moshi",
      "names": {
        "zh": "莫希"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -3.3486456,
        "lng": 37.3435249
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        37.1835249,
        -3.5086456,
        37.5035249,
        -3.1886456
      ]
    },
    {
      "id": 3661,
      "name": "Bizerte",
      "names": {
        "zh": "比塞大"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 37.272017649999995,
        "lng": 9.873614437009381
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        9.8734265,
        37.2718139,
        9.8738199,
        37.2722072
      ]
    },
    {
      "id": 3662,
      "name": "Dar`a",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.6224342,
        "lng": 36.1109696
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        35.9509696,
        32.4624342,
        36.2709696,
        32.7824342
      ]
    },
    {
      "id": 3663,
      "name": "Cairns",
      "names": {
        "zh": "凯恩斯",
        "ja": "ケアンズ",
        "th": "เมืองแครนส์"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -16.9206657,
        "lng": 145.7721854
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        145.6121854,
        -17.0806657,
        145.9321854,
        -16.7606657
      ]
    },
    {
      "id": 3664,
      "name": "Rubtsovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.523222,
        "lng": 81.194385
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        81.155861,
        51.480045,
        81.240234,
        51.56501
      ]
    },
    {
      "id": 3665,
      "name": "Poole",
      "names": {
        "zh": "普尔",
        "ja": "プール",
        "th": "พูล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7179472,
        "lng": -1.981521
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.021521,
        50.6779472,
        -1.941521,
        50.7579472
      ]
    },
    {
      "id": 3666,
      "name": "Negombo",
      "names": {
        "zh": "尼甘布",
        "th": "เนกอมโบ"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 7.21020605,
        "lng": 79.84183638154965
      },
      "country": "Sri Lanka",
      "importance": 3,
      "bbox": [
        79.8417137,
        7.2099975,
        79.8420385,
        7.21062
      ]
    },
    {
      "id": 3667,
      "name": "Gisenyi",
      "names": {
        "zh": "吉塞尼"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.693545,
        "lng": 29.259855
      },
      "country": "Rwanda",
      "importance": 3,
      "bbox": [
        29.2437453,
        -1.7148138,
        29.2763246,
        -1.6659759
      ]
    },
    {
      "id": 3668,
      "name": "Cam Ranh",
      "names": {
        "zh": "凸轮ranh",
        "it": "Camramo",
        "ja": "カムラン",
        "th": "ลูกเบี้ยว ranh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.92144,
        "lng": 109.15913
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        109.13913,
        11.90144,
        109.17913,
        11.94144
      ]
    },
    {
      "id": 3669,
      "name": "Wamba",
      "names": {
        "zh": "万巴"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.1435115,
        "lng": 27.9938589
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        27.9538589,
        2.1035115,
        28.0338589,
        2.1835115
      ]
    },
    {
      "id": 3670,
      "name": "Odintsovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6719892,
        "lng": 37.2818162
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.2768162,
        55.6669892,
        37.2868162,
        55.6769892
      ]
    },
    {
      "id": 3671,
      "name": "Dadu",
      "names": {
        "zh": "大渡河"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 26.7274836,
        "lng": 67.7804974
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        67.6204974,
        26.5674836,
        67.9404974,
        26.8874836
      ]
    },
    {
      "id": 3672,
      "name": "Franco da Rocha",
      "names": {
        "ja": "フランコダロチャ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3297902,
        "lng": -46.7264054
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.7314054,
        -23.3347902,
        -46.7214054,
        -23.3247902
      ]
    },
    {
      "id": 3673,
      "name": "Misato",
      "names": {
        "zh": "美里",
        "ja": "ミサト"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8289993,
        "lng": 139.8726811
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.8397438,
        35.7816503,
        139.900333,
        35.868061
      ]
    },
    {
      "id": 3674,
      "name": "Pleiku",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 13.9715982,
        "lng": 108.0143597
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.838725,
        13.8335157,
        108.0993829,
        14.0871053
      ]
    },
    {
      "id": 3675,
      "name": "Etah",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5721659,
        "lng": 78.6539605
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.6489605,
        27.5671659,
        78.6589605,
        27.5771659
      ]
    },
    {
      "id": 3676,
      "name": "Keren",
      "names": {
        "zh": "凯伦"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.780477,
        "lng": 38.453606
      },
      "country": "Eritrea",
      "importance": 1,
      "bbox": [
        38.293606,
        15.620477,
        38.613606,
        15.940477
      ]
    },
    {
      "id": 3677,
      "name": "Newport",
      "names": {
        "zh": "纽波特",
        "ja": "ニューポート",
        "th": "นิวพอร์ต"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5882332,
        "lng": -2.9974967
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.1241845,
        51.4943403,
        -2.8030054,
        51.6494687
      ]
    },
    {
      "id": 3678,
      "name": "Calapan",
      "names": {
        "zh": "卡拉潘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.348817,
        "lng": 121.189434
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.096809,
        13.274538,
        121.275097,
        13.439555
      ]
    },
    {
      "id": 3679,
      "name": "Colina",
      "names": {
        "zh": "科利纳"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.202472,
        "lng": -70.6749147
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.7920402,
        -33.34253,
        -70.3725286,
        -32.9421947
      ]
    },
    {
      "id": 3680,
      "name": "Kolomna",
      "names": {
        "zh": "科洛姆纳"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0938743,
        "lng": 38.7670121
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.6914899,
        55.0334451,
        38.9049259,
        55.132053
      ]
    },
    {
      "id": 3681,
      "name": "Mejicanos",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.74028,
        "lng": -89.21306
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.23306,
        13.72028,
        -89.19306,
        13.76028
      ]
    },
    {
      "id": 3682,
      "name": "Larisa",
      "names": {
        "zh": "拉里莎",
        "id": "Lárisa"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.63862,
        "lng": 22.412977
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        22.387815,
        39.61808,
        22.435306,
        39.653187
      ]
    },
    {
      "id": 3683,
      "name": "Yima",
      "names": {
        "zh": "义马"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7454924,
        "lng": 111.8661916
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.8192902,
        34.6929511,
        111.9919366,
        34.8038803
      ]
    },
    {
      "id": 3684,
      "name": "Santo Agostinho",
      "names": {
        "ja": "サントアゴスチンホ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.29479245,
        "lng": -34.985162914367436
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.0191917,
        -8.3663857,
        -34.9210115,
        -8.224
      ]
    },
    {
      "id": 3685,
      "name": "Beaumont",
      "names": {
        "zh": "博蒙特",
        "ja": "ボーモント",
        "th": "โบมอนต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0860459,
        "lng": -94.1018461
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.2249569,
        29.9729432,
        -94.0369338,
        30.1890213
      ]
    },
    {
      "id": 3686,
      "name": "Parnaiba",
      "names": {
        "zh": "巴纳伊巴",
        "pt": "Parnaíba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.9147515,
        "lng": -41.7661953
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.869079,
        -3.1206669,
        -41.6472709,
        -2.7583058
      ]
    },
    {
      "id": 3687,
      "name": "Garcia",
      "names": {
        "es": "García",
        "zh": "·加西亚",
        "ja": "ガルシア",
        "th": "การ์เซีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8054661,
        "lng": -100.63964675280096
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.8619014,
        25.6269803,
        -100.4153323,
        25.9834779
      ]
    },
    {
      "id": 3688,
      "name": "Urdaneta",
      "names": {
        "zh": "乌达内塔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9759995,
        "lng": 120.5668992
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4068992,
        15.8159995,
        120.7268992,
        16.1359995
      ]
    },
    {
      "id": 3689,
      "name": "Le Mans",
      "names": {
        "zh": "勒芒",
        "ja": "ルマン",
        "th": "เลอม็อง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0073849,
        "lng": 0.1967849
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.1362825,
        47.927977,
        0.2550938,
        48.0358733
      ]
    },
    {
      "id": 3690,
      "name": "Barrechid",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.27,
        "lng": -7.5872
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3691,
      "name": "Arayat",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.1505,
        "lng": 120.7697
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7497,
        15.1305,
        120.7897,
        15.1705
      ]
    },
    {
      "id": 3692,
      "name": "Settat",
      "names": {
        "zh": "塞塔特"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.002397,
        "lng": -7.619867
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.6794462,
        32.95985,
        -7.5765481,
        33.0360105
      ]
    },
    {
      "id": 3693,
      "name": "Khairpur Mir's",
      "names": {
        "de": "Khairpur Mir ist",
        "id": "Khairpur Mir",
        "ja": "Khairpur Mir",
        "pt": "Khairpur Mir"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 27.524021,
        "lng": 68.748758
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.723164,
        27.508981,
        68.77654,
        27.555902
      ]
    },
    {
      "id": 3694,
      "name": "Bocaue",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.79864075,
        "lng": 120.93234912378952
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9318129,
        14.7977075,
        120.9328853,
        14.799574
      ]
    },
    {
      "id": 3695,
      "name": "Bani",
      "names": {
        "zh": "巴尼",
        "ja": "バニ",
        "th": "ซุ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.2777204,
        "lng": -70.3320746
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.5879856,
        18.1928361,
        -70.2262183,
        18.5093389
      ]
    },
    {
      "id": 3696,
      "name": "Xintang",
      "names": {
        "zh": "新塘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1369312,
        "lng": 113.600681
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.595681,
        23.1319312,
        113.605681,
        23.1419312
      ]
    },
    {
      "id": 3697,
      "name": "Matanzas",
      "names": {
        "zh": "马坦萨斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.0469236,
        "lng": -81.5783032
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -81.7508273,
        22.95191,
        -81.4064287,
        23.1535688
      ]
    },
    {
      "id": 3698,
      "name": "David",
      "names": {
        "zh": "大卫",
        "ja": "デビッド",
        "th": "เดวิด"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.4371469,
        "lng": -82.4286281
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -82.4514281,
        8.4229845,
        -82.4072383,
        8.4825721
      ]
    },
    {
      "id": 3699,
      "name": "San Justo",
      "names": {
        "it": "San jousto"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6774186,
        "lng": -58.5607837
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.5888431,
        -34.7141365,
        -58.5380469,
        -34.6615964
      ]
    },
    {
      "id": 3700,
      "name": "Quchan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.1060904,
        "lng": 58.5086657
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.475933,
        37.0785313,
        58.5411643,
        37.1226858
      ]
    },
    {
      "id": 3701,
      "name": "Berezniki",
      "names": {
        "zh": "别列兹尼基"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.4084171,
        "lng": 56.8036958
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        56.5912984,
        59.270839,
        57.0610564,
        59.5654224
      ]
    },
    {
      "id": 3702,
      "name": "Porto Seguro",
      "names": {
        "zh": "波尔图赛尔罗",
        "ja": "ポルトセグロ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.443473,
        "lng": -39.064251
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.519095,
        -16.929,
        -39.0073781,
        -16.3431345
      ]
    },
    {
      "id": 3703,
      "name": "Shillong",
      "names": {
        "zh": "西隆",
        "ja": "シロン",
        "th": "ซิลลอง"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.577511,
        "lng": 91.879421
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        91.835937,
        25.5483,
        91.951722,
        25.627153
      ]
    },
    {
      "id": 3704,
      "name": "Cape Coast",
      "names": {
        "es": "Costa del cabo",
        "fr": "Cale",
        "zh": "海岸",
        "de": "Kap-Küste",
        "ja": "ケープコースト",
        "th": "ชายฝั่งเคป",
        "pt": "Costa do Cabo"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 5.10535,
        "lng": -1.2466
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -1.2666,
        5.08535,
        -1.2266,
        5.12535
      ]
    },
    {
      "id": 3705,
      "name": "Villa de Cura",
      "names": {
        "ja": "ヴィラデーラ",
        "th": "วิลล่าเดอคาร่า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.03757,
        "lng": -67.485408
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.505408,
        10.01757,
        -67.465408,
        10.05757
      ]
    },
    {
      "id": 3706,
      "name": "Kusatsu",
      "names": {
        "zh": "草津",
        "ja": "草津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.01667,
        "lng": 135.96667
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.94667,
        34.99667,
        135.98667,
        35.03667
      ]
    },
    {
      "id": 3707,
      "name": "Kakamigahara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3995831,
        "lng": 136.8485648
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.79202,
        35.355923,
        136.963092,
        35.44673
      ]
    },
    {
      "id": 3708,
      "name": "Paterson",
      "names": {
        "zh": "帕特森",
        "ja": "パターソン",
        "th": "ถนนแพเตอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9151595,
        "lng": -74.1673176
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.1723176,
        40.9101595,
        -74.1623176,
        40.9201595
      ]
    },
    {
      "id": 3709,
      "name": "Merced",
      "names": {
        "zh": "梅塞德",
        "ja": "マーセド",
        "th": "เมอร์เซด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3073266,
        "lng": -120.476768
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.481768,
        37.3023266,
        -120.471768,
        37.3123266
      ]
    },
    {
      "id": 3710,
      "name": "Kolar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.1367201,
        "lng": 78.1337246
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.9737246,
        12.9767201,
        78.2937246,
        13.2967201
      ]
    },
    {
      "id": 3711,
      "name": "Al Mukalla",
      "names": {
        "ja": "アルムカラ"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.54248,
        "lng": 49.12424
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        49.10424,
        14.52248,
        49.14424,
        14.56248
      ]
    },
    {
      "id": 3712,
      "name": "Flores",
      "names": {
        "zh": "弗洛雷斯",
        "ja": "フローレス",
        "th": "ฟลอเรส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.9298524,
        "lng": -89.8914817
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -89.9209502,
        16.8568365,
        -89.3236332,
        17.8154997
      ]
    },
    {
      "id": 3713,
      "name": "Comayagua",
      "names": {
        "zh": "科马亚瓜"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.4527976,
        "lng": -87.6379227
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -87.8974603,
        14.355282,
        -87.3842585,
        14.5956117
      ]
    },
    {
      "id": 3714,
      "name": "Dongxing",
      "names": {
        "zh": "东兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6058074,
        "lng": 108.0698483
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.882384,
        21.2532481,
        108.2227992,
        21.7261883
      ]
    },
    {
      "id": 3715,
      "name": "Okinawa",
      "names": {
        "zh": "冲绳",
        "ja": "沖縄",
        "th": "โอกินาวา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.3343738,
        "lng": 127.8056597
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.772165,
        26.2743154,
        127.8678214,
        26.421747
      ]
    },
    {
      "id": 3716,
      "name": "Barranca",
      "names": {
        "zh": "巴兰卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -10.7541212,
        "lng": -77.7609383
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -77.7941895,
        -10.7827106,
        -77.5727594,
        -10.66266
      ]
    },
    {
      "id": 3717,
      "name": "Khasavyurt",
      "names": {
        "zh": "哈萨维尤尔特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.246916,
        "lng": 46.5855184
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        46.5477113,
        43.2054495,
        46.632817,
        43.2856377
      ]
    },
    {
      "id": 3718,
      "name": "Saguenay",
      "names": {
        "zh": "萨古恩河"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.405959,
        "lng": -71.069183
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -71.5637733,
        48.1877264,
        -70.6873399,
        48.5662729
      ]
    },
    {
      "id": 3719,
      "name": "Cuautitlan Izcalli",
      "names": {
        "id": "Cuaiitlan izcalli"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6577015,
        "lng": -99.23228295286131
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2907631,
        19.5856759,
        -99.1750284,
        19.7297568
      ]
    },
    {
      "id": 3720,
      "name": "Kumba",
      "names": {
        "zh": "昆巴"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.6363,
        "lng": 9.4469
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        9.4269,
        4.6163,
        9.4669,
        4.6563
      ]
    },
    {
      "id": 3721,
      "name": "Ruse",
      "names": {
        "zh": "鲁塞",
        "ja": "虚勢",
        "th": "อุบาย"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.8480413,
        "lng": 25.9542057
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        25.8636442,
        43.7419965,
        26.0824089,
        43.9018697
      ]
    },
    {
      "id": 3722,
      "name": "Cekme",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.34858,
        "lng": 34.77931
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        34.75931,
        38.32858,
        34.79931,
        38.36858
      ]
    },
    {
      "id": 3723,
      "name": "Malasiqui",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9199144,
        "lng": 120.4138181
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3965183,
        15.853332,
        120.5311696,
        15.9761749
      ]
    },
    {
      "id": 3724,
      "name": "Bharatpur",
      "names": {
        "zh": "巴拉普尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6806167,
        "lng": 84.4308773
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        84.2708773,
        27.5206167,
        84.5908773,
        27.8406167
      ]
    },
    {
      "id": 3725,
      "name": "Florencia",
      "names": {
        "zh": "弗洛伦西亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.6158666,
        "lng": -75.6143045
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -75.6487334,
        1.5929357,
        -75.5756486,
        1.6453305
      ]
    },
    {
      "id": 3726,
      "name": "Pueblo",
      "names": {
        "zh": "普韦布洛",
        "ja": "プエブロ",
        "th": "ปวย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.263995,
        "lng": -104.6141867
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.7222744,
        38.189106,
        -104.4600366,
        38.3453683
      ]
    },
    {
      "id": 3727,
      "name": "Aix-en-Provence",
      "names": {
        "zh": "普罗旺斯地区艾克斯",
        "ja": "エックスアンプロヴァンス",
        "th": "เมืองอายซ์ออง-โปรวองซ์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.526046,
        "lng": 5.44467
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.269552,
        43.446115,
        5.506303,
        43.625991
      ]
    },
    {
      "id": 3728,
      "name": "Coimbra",
      "names": {
        "zh": "科英布拉",
        "ja": "コインブラ",
        "th": "โกอินบรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2111931,
        "lng": -8.4294632
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.5916759,
        40.0989228,
        -8.3129036,
        40.3520146
      ]
    },
    {
      "id": 3729,
      "name": "Ajdabiya",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 30.7550035,
        "lng": 20.2233257
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        20.0633257,
        30.5950035,
        20.3833257,
        30.9150035
      ]
    },
    {
      "id": 3730,
      "name": "Calabayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.75,
        "lng": 121.75
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.73,
        16.73,
        121.77,
        16.77
      ]
    },
    {
      "id": 3731,
      "name": "Tyler",
      "names": {
        "zh": "泰勒",
        "ja": "タイラー",
        "th": "ไทเลอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3512601,
        "lng": -95.3010624
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.4188842,
        32.2306715,
        -95.1932894,
        32.4678793
      ]
    },
    {
      "id": 3732,
      "name": "Nawabganj",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 24.5954428,
        "lng": 88.2707613
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        88.1107613,
        24.4354428,
        88.4307613,
        24.7554428
      ]
    },
    {
      "id": 3733,
      "name": "Ciego de Avila",
      "names": {
        "ja": "Cieego de Avila"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.8420439,
        "lng": -78.7602038
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -78.896751,
        21.7617871,
        -78.6250045,
        21.9947411
      ]
    },
    {
      "id": 3734,
      "name": "Birkenhead",
      "names": {
        "zh": "伯肯黑德"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3895177,
        "lng": -3.0248007
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0648007,
        53.3495177,
        -2.9848007,
        53.4295177
      ]
    },
    {
      "id": 3735,
      "name": "Altay",
      "names": {
        "zh": "阿勒泰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.8257562,
        "lng": 88.1291701
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.8872592,
        47.2350724,
        88.6376095,
        48.6463705
      ]
    },
    {
      "id": 3736,
      "name": "Ashikaga",
      "names": {
        "zh": "足利"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3401914,
        "lng": 139.4497731
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.361032,
        36.2706,
        139.548605,
        36.445711
      ]
    },
    {
      "id": 3737,
      "name": "Ghazni",
      "names": {
        "zh": "加兹尼",
        "th": "ซนิ"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.5448968,
        "lng": 68.4132108
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        68.2532108,
        33.3848968,
        68.5732108,
        33.7048968
      ]
    },
    {
      "id": 3738,
      "name": "El Progreso",
      "names": {
        "pt": "El progresso"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.4009458,
        "lng": -87.8120187
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -87.8520187,
        15.3609458,
        -87.7720187,
        15.4409458
      ]
    },
    {
      "id": 3739,
      "name": "Torrance",
      "names": {
        "zh": "托兰斯",
        "ja": "トーランス",
        "th": "เทอร์เรซ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8371392,
        "lng": -118.3413606
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.4844862,
        33.7802812,
        -118.308127,
        33.8871325
      ]
    },
    {
      "id": 3740,
      "name": "Bilbays",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.42039,
        "lng": 31.56223
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.54223,
        30.40039,
        31.58223,
        30.44039
      ]
    },
    {
      "id": 3741,
      "name": "Huelva",
      "names": {
        "zh": "韦尔瓦",
        "ja": "ウエルバ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.2526187,
        "lng": -6.944851
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -6.949851,
        37.2476187,
        -6.939851,
        37.2576187
      ]
    },
    {
      "id": 3742,
      "name": "Jau",
      "names": {
        "zh": "雅乌"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.293585,
        "lng": -48.559193
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.7316528,
        -22.478775,
        -48.3419846,
        -22.1213028
      ]
    },
    {
      "id": 3743,
      "name": "Levis",
      "names": {
        "zh": "李维斯",
        "ja": "リーバイス",
        "th": "เลวิส"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.8033114,
        "lng": -71.1778792
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -71.5189217,
        46.5721827,
        -70.9937811,
        46.8422475
      ]
    },
    {
      "id": 3744,
      "name": "Bhimavaram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5427689,
        "lng": 81.527344
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.367344,
        16.3827689,
        81.687344,
        16.7027689
      ]
    },
    {
      "id": 3745,
      "name": "Jaragua do Sul",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.4897432,
        "lng": -49.0778063
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.2974342,
        -26.6488649,
        -49.014743,
        -26.2166129
      ]
    },
    {
      "id": 3746,
      "name": "Jinjiang",
      "names": {
        "zh": "锦江之星",
        "th": "จิ่นเจียง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4218,
        "lng": 112.712
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.672,
        25.3818,
        112.752,
        25.4618
      ]
    },
    {
      "id": 3747,
      "name": "Blackpool",
      "names": {
        "zh": "布莱克浦",
        "ja": "ブラックプール",
        "th": "แบล็คพูล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.8179442,
        "lng": -3.0509812
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0909812,
        53.7779442,
        -3.0109812,
        53.8579442
      ]
    },
    {
      "id": 3748,
      "name": "Los Angeles",
      "names": {
        "zh": "洛杉矶",
        "ja": "ロサンゼルス",
        "th": "ลอสแองเจลิส"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -37.4707455,
        "lng": -72.351686
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -72.6836424,
        -37.6629358,
        -72.03901,
        -37.1785281
      ]
    },
    {
      "id": 3749,
      "name": "Boca Chica",
      "names": {
        "zh": "博卡奇卡",
        "ja": "ボカチカ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.45369,
        "lng": -69.6111219
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -69.6511219,
        18.41369,
        -69.5711219,
        18.49369
      ]
    },
    {
      "id": 3750,
      "name": "Telford",
      "names": {
        "zh": "德福",
        "ja": "テルフォード",
        "th": "เทลฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.6780419,
        "lng": -2.4514273
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4914273,
        52.6380419,
        -2.4114273,
        52.7180419
      ]
    },
    {
      "id": 3751,
      "name": "Yuma",
      "names": {
        "zh": "尤马",
        "ja": "ユマ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.723268,
        "lng": -114.6179954
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -114.6849861,
        32.4790669,
        -114.4591301,
        32.7322939
      ]
    },
    {
      "id": 3752,
      "name": "Preston",
      "names": {
        "zh": "普雷斯顿",
        "ja": "プレストン",
        "th": "เพรสตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7593363,
        "lng": -2.6992717
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.825998,
        53.7480139,
        -2.5954368,
        53.896153
      ]
    },
    {
      "id": 3753,
      "name": "Lichinga",
      "names": {
        "zh": "利欣加"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -13.2999118,
        "lng": 35.2458642
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        35.0858642,
        -13.4599118,
        35.4058642,
        -13.1399118
      ]
    },
    {
      "id": 3754,
      "name": "Moundou",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 8.5654637,
        "lng": 16.073831
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        15.913831,
        8.4054637,
        16.233831,
        8.7254637
      ]
    },
    {
      "id": 3755,
      "name": "Tetovo",
      "names": {
        "zh": "泰托沃"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0068297,
        "lng": 20.9728556
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.9434202,
        41.9743756,
        21.0377723,
        42.0311414
      ]
    },
    {
      "id": 3756,
      "name": "Bahawalnagar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.9935922,
        "lng": 73.2527621
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.0927621,
        29.8335922,
        73.4127621,
        30.1535922
      ]
    },
    {
      "id": 3757,
      "name": "Maykop",
      "names": {
        "zh": "迈科普"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.6062079,
        "lng": 40.104053
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        40.0384895,
        44.5601858,
        40.1648791,
        44.6494756
      ]
    },
    {
      "id": 3758,
      "name": "Brest",
      "names": {
        "zh": "布雷斯特",
        "ja": "ブレスト",
        "th": "เบรสต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3905283,
        "lng": -4.4860088
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -4.5689169,
        48.3572972,
        -4.4278311,
        48.4595521
      ]
    },
    {
      "id": 3759,
      "name": "Kamalshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.86187,
        "lng": 50.87424
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        50.85424,
        35.84187,
        50.89424,
        35.88187
      ]
    },
    {
      "id": 3760,
      "name": "Fukayacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.18223,
        "lng": 139.293708
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.279044,
        36.167219,
        139.311353,
        36.19567
      ]
    },
    {
      "id": 3761,
      "name": "Lausanne",
      "names": {
        "zh": "洛桑",
        "ja": "ローザンヌ",
        "th": "โลซาน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.5218269,
        "lng": 6.6327025
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        6.5838681,
        46.5043006,
        6.7208137,
        46.6025773
      ]
    },
    {
      "id": 3762,
      "name": "Gweru",
      "names": {
        "zh": "圭鲁"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -19.4616316,
        "lng": 29.8205953
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        29.6605953,
        -19.6216316,
        29.9805953,
        -19.3016316
      ]
    },
    {
      "id": 3763,
      "name": "Tabaco",
      "names": {
        "zh": "塔瓦科"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.3593077,
        "lng": 123.7302007
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6048405,
        13.2555075,
        123.9569403,
        13.5286023
      ]
    },
    {
      "id": 3764,
      "name": "Kelowna",
      "names": {
        "zh": "基洛纳",
        "ja": "ケロウナ",
        "th": "คีโลว์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.8879177,
        "lng": -119.495902
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -119.5890596,
        49.772822,
        -119.3197826,
        50.0254575
      ]
    },
    {
      "id": 3765,
      "name": "Metairie",
      "names": {
        "zh": "梅泰里"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.00185465,
        "lng": -90.1767012249077
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.2376169,
        29.9688401,
        -90.1211162,
        30.034366
      ]
    },
    {
      "id": 3766,
      "name": "Namacunde",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -17.3,
        "lng": 15.85
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.83,
        -17.32,
        15.87,
        -17.28
      ]
    },
    {
      "id": 3767,
      "name": "Irakleio",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0462663,
        "lng": 23.766037
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        23.761037,
        38.0412663,
        23.771037,
        38.0512663
      ]
    },
    {
      "id": 3768,
      "name": "Mandsaur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.0682898,
        "lng": 75.0742388
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.9142388,
        23.9082898,
        75.2342388,
        24.2282898
      ]
    },
    {
      "id": 3769,
      "name": "Rybnik",
      "names": {
        "zh": "雷布尼克",
        "th": "ริบนิค"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0955793,
        "lng": 18.5419933
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.441684,
        50.0353678,
        18.6568396,
        50.1856708
      ]
    },
    {
      "id": 3770,
      "name": "Nong'an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.4310587,
        "lng": 125.1662116
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        124.5391751,
        43.8986276,
        125.7580067,
        44.937189
      ]
    },
    {
      "id": 3771,
      "name": "Inezgane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.3562929,
        "lng": -9.5459347
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.5794023,
        30.3388059,
        -9.5040071,
        30.3753444
      ]
    },
    {
      "id": 3772,
      "name": "Rio das Ostras",
      "names": {
        "id": "Rio das Östra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5269448,
        "lng": -41.944972
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.0635168,
        -22.5624592,
        -41.8177902,
        -22.3767508
      ]
    },
    {
      "id": 3773,
      "name": "Vlore",
      "names": {
        "fr": "Vlorë"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 40.458128,
        "lng": 19.492896
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        19.469719,
        40.43623,
        19.509719,
        40.47623
      ]
    },
    {
      "id": 3774,
      "name": "Boulogne-Billancourt",
      "names": {
        "zh": "布洛涅 - 比扬古",
        "ja": "ブローニュビヤンクール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8356649,
        "lng": 2.240206
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.222799,
        48.8214969,
        2.2627757,
        48.853442
      ]
    },
    {
      "id": 3775,
      "name": "Fujita",
      "names": {
        "zh": "藤田",
        "ja": "藤田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2704329,
        "lng": 135.2642033
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.2542033,
        34.2604329,
        135.2742033,
        34.2804329
      ]
    },
    {
      "id": 3776,
      "name": "Jahrom",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 28.5014092,
        "lng": 53.5695867
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.5251069,
        28.4678405,
        53.6540917,
        28.5954556
      ]
    },
    {
      "id": 3777,
      "name": "Surprise",
      "names": {
        "es": "Sorpresa",
        "zh": "惊喜",
        "de": "Überraschung",
        "it": "Sorpresa",
        "id": "Kejutan",
        "ja": "驚き",
        "th": "แปลกใจ",
        "pt": "Surpresa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.629247,
        "lng": -112.3681428
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.6083345,
        33.5800859,
        -112.2985398,
        33.8258157
      ]
    },
    {
      "id": 3778,
      "name": "Columbia",
      "names": {
        "fr": "-Britannique",
        "zh": "哥伦比亚",
        "de": "Kolumbien",
        "ja": "コロンビア",
        "th": "โคลัมเบีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5313102,
        "lng": -82.2563979
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.442383,
        33.355019,
        -82.0286601,
        33.700787
      ]
    },
    {
      "id": 3779,
      "name": "Athens",
      "names": {
        "es": "Atenas",
        "fr": "Athènes",
        "zh": "雅典",
        "de": "Athen",
        "it": "Atene",
        "id": "Athena",
        "ja": "アテネ",
        "th": "เอเธนส์",
        "pt": "Atenas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9597677,
        "lng": -83.376398
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.5374696,
        33.8480209,
        -83.2408342,
        34.039466
      ]
    },
    {
      "id": 3780,
      "name": "Roseville",
      "names": {
        "zh": "罗斯维尔",
        "ja": "ローズビル",
        "th": "โรสวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7521235,
        "lng": -121.2880059
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.400248,
        38.7163523,
        -121.1841613,
        38.8101558
      ]
    },
    {
      "id": 3781,
      "name": "Thornton",
      "names": {
        "zh": "桑顿",
        "ja": "ソーントン",
        "th": "ธอร์นตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8695516,
        "lng": -104.985181
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.0155315,
        39.8310116,
        -104.8778931,
        40.0009278
      ]
    },
    {
      "id": 3782,
      "name": "Middlesbrough",
      "names": {
        "zh": "米德尔斯堡",
        "ja": "ミドルスブラ",
        "th": "มิดเดิ้ลสโบรช์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.5760419,
        "lng": -1.2344047
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.2824988,
        54.51076,
        -1.1606661,
        54.5914007
      ]
    },
    {
      "id": 3783,
      "name": "Khuzdar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 27.8000135,
        "lng": 66.6166537
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        66.4566537,
        27.6400135,
        66.7766537,
        27.9600135
      ]
    },
    {
      "id": 3784,
      "name": "Tepatitlan de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8151477,
        "lng": -102.758809
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.9433751,
        20.5919562,
        -102.4831999,
        21.0425342
      ]
    },
    {
      "id": 3785,
      "name": "Abbotsford",
      "names": {
        "zh": "阿伯茨福德",
        "ja": "アボッツフォード",
        "th": "แอบบอท"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.0521162,
        "lng": -122.329479
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.4624889,
        49.0022886,
        -122.0729186,
        49.1719397
      ]
    },
    {
      "id": 3786,
      "name": "Batu Pahat",
      "names": {
        "es": "Batuida",
        "zh": "巴图娃娃",
        "ja": "バトゥパハット"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 1.933333,
        "lng": 103
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        102.7741691,
        1.6222193,
        103.2540536,
        2.2005014
      ]
    },
    {
      "id": 3787,
      "name": "Ruda Slaska",
      "names": {
        "ja": "RUDAスラスカ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.282576,
        "lng": 18.862575
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.792339,
        50.208422,
        18.956164,
        50.331703
      ]
    },
    {
      "id": 3788,
      "name": "Porac",
      "names": {
        "zh": "波拉克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0726482,
        "lng": 120.5430211
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.358513,
        15.0096982,
        120.6091149,
        15.222035
      ]
    },
    {
      "id": 3789,
      "name": "Miramar",
      "names": {
        "zh": "米拉马",
        "ja": "ミラマー",
        "th": "มิรามาร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9873137,
        "lng": -80.2322706
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.4406327,
        25.9568442,
        -80.2061193,
        25.9954102
      ]
    },
    {
      "id": 3790,
      "name": "Ozamiz City",
      "names": {
        "es": "Ciudad de Ozamiz",
        "zh": "奥兹米兹市",
        "de": "Ozamiz-Stadt",
        "id": "Kota Ozamiz",
        "ja": "オザミスシティ",
        "th": "เมือง Ozamiz",
        "pt": "Cidade de ozamiz"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.1481,
        "lng": 123.8405
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.8205,
        8.1281,
        123.8605,
        8.1681
      ]
    },
    {
      "id": 3791,
      "name": "Pecs",
      "names": {
        "zh": "佩奇",
        "th": "เพซ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.0765092,
        "lng": 18.2280317
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        18.1358056,
        45.9941673,
        18.3660004,
        46.1616082
      ]
    },
    {
      "id": 3792,
      "name": "Pasadena",
      "names": {
        "zh": "帕萨迪纳",
        "ja": "パサデナ",
        "th": "พาซาดีน่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1476507,
        "lng": -118.144155
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1982432,
        34.1172023,
        -118.0655987,
        34.2515593
      ]
    },
    {
      "id": 3793,
      "name": "Kumbakonam",
      "names": {
        "zh": "贡伯戈讷姆"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 10.963625,
        "lng": 79.38897
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.355232,
        10.946185,
        79.426818,
        10.986763
      ]
    },
    {
      "id": 3794,
      "name": "Kovrov",
      "names": {
        "zh": "科夫罗夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.355386100000004,
        "lng": 41.3169929828466
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        41.2236079,
        56.2974807,
        41.3957177,
        56.4133815
      ]
    },
    {
      "id": 3795,
      "name": "Shizhaobi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.95194,
        "lng": 115.65611
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.63611,
        23.93194,
        115.67611,
        23.97194
      ]
    },
    {
      "id": 3796,
      "name": "Mesquite",
      "names": {
        "zh": "梅斯基特",
        "ja": "メスキート",
        "th": "ซีซั่น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7666103,
        "lng": -96.599472
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.6848683,
        32.6815167,
        -96.428882,
        32.8493147
      ]
    },
    {
      "id": 3797,
      "name": "Kokubunji",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.709674,
        "lng": 139.454224
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.428262,
        35.688328,
        139.4915779,
        35.722875
      ]
    },
    {
      "id": 3798,
      "name": "Lleida",
      "names": {
        "zh": "莱里达",
        "ja": "リェイダ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6206514,
        "lng": 0.632317
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        0.632267,
        41.6206014,
        0.632367,
        41.6207014
      ]
    },
    {
      "id": 3799,
      "name": "Ebina",
      "names": {
        "zh": "海老名"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4527491,
        "lng": 139.3909319
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3859319,
        35.4477491,
        139.3959319,
        35.4577491
      ]
    },
    {
      "id": 3800,
      "name": "Paranagua",
      "names": {
        "zh": "巴拉那瓜",
        "pt": "Paranaguá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.5148822,
        "lng": -48.5226695
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.7041148,
        -25.7341226,
        -48.276,
        -25.365
      ]
    },
    {
      "id": 3801,
      "name": "Iguala de la Independencia",
      "names": {
        "zh": "Iguala de la Indoolencia",
        "ja": "イグアラデランタンシア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3466358,
        "lng": -99.5376944
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.6976944,
        18.1866358,
        -99.3776944,
        18.5066358
      ]
    },
    {
      "id": 3802,
      "name": "Olathe",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 38.8838856,
        "lng": -94.81887
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.928023,
        38.8054833,
        -94.7143984,
        38.9889258
      ]
    },
    {
      "id": 3803,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.9531295,
        "lng": -120.435857
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.5084085,
        34.8755275,
        -120.3545968,
        34.9892014
      ]
    },
    {
      "id": 3804,
      "name": "Masaya",
      "names": {
        "zh": "马萨亚"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9736474,
        "lng": -86.0960547
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.116331,
        11.9161185,
        -85.9868207,
        12.1413717
      ]
    },
    {
      "id": 3805,
      "name": "Medea",
      "names": {
        "zh": "美狄亚",
        "ja": "メデア"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.26417,
        "lng": 2.75393
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.73393,
        36.24417,
        2.77393,
        36.28417
      ]
    },
    {
      "id": 3806,
      "name": "Yaritagua",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0766304,
        "lng": -69.1316916
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.1366916,
        10.0716304,
        -69.1266916,
        10.0816304
      ]
    },
    {
      "id": 3807,
      "name": "Houma",
      "names": {
        "zh": "侯马"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5957696,
        "lng": -90.7195348
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.7670332,
        29.544925,
        -90.635876,
        29.6114193
      ]
    },
    {
      "id": 3808,
      "name": "Tours",
      "names": {
        "zh": "旅游",
        "de": "Touren",
        "ja": "ツアー",
        "th": "ทัวร์",
        "pt": "Passeios"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.3900474,
        "lng": 0.6889268
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.6528317,
        47.3489171,
        0.7373427,
        47.4395937
      ]
    },
    {
      "id": 3809,
      "name": "Fusagasuga",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.339237,
        "lng": -74.3637532
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4171341,
        4.3216619,
        -74.3474932,
        4.3557246
      ]
    },
    {
      "id": 3810,
      "name": "La Romana",
      "names": {
        "zh": "拉罗马纳"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4272786,
        "lng": -68.9734597
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -69.0262659,
        18.3387192,
        -68.8435936,
        18.5219139
      ]
    },
    {
      "id": 3811,
      "name": "Tanay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4985359,
        "lng": 121.2856331
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2456331,
        14.4585359,
        121.3256331,
        14.5385359
      ]
    },
    {
      "id": 3812,
      "name": "Ituzaingo",
      "names": {
        "zh": "伊图萨因戈"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6583293,
        "lng": -58.6671441
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.717722,
        -34.6858904,
        -58.6535667,
        -34.6281676
      ]
    },
    {
      "id": 3813,
      "name": "Al `Aqabah",
      "names": {
        "zh": "Al`aqabah",
        "ja": "Al`Aカババ",
        "th": "อัล `Aqabah",
        "pt": "Al 'Aqabah"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 29.5266483,
        "lng": 35.0075433
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        34.9787395,
        29.5142099,
        35.0442834,
        29.5747185
      ]
    },
    {
      "id": 3814,
      "name": "Torbat-e Heydariyeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.27401,
        "lng": 59.21949
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        59.19949,
        35.25401,
        59.23949,
        35.29401
      ]
    },
    {
      "id": 3815,
      "name": "Momostenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0446901,
        "lng": -91.4080006
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.4480006,
        15.0046901,
        -91.3680006,
        15.0846901
      ]
    },
    {
      "id": 3816,
      "name": "Dawei",
      "names": {
        "zh": "大为"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 14.0797232,
        "lng": 98.1990191
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        98.0390191,
        13.9197232,
        98.3590191,
        14.2397232
      ]
    },
    {
      "id": 3817,
      "name": "Zielona Gora",
      "names": {
        "ja": "ジエロナゴーラ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9383777,
        "lng": 15.5050408
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        15.4059484,
        51.8201431,
        15.6524239,
        52.0351035
      ]
    },
    {
      "id": 3818,
      "name": "Kuwana",
      "names": {
        "zh": "桑名"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0666099,
        "lng": 136.6843004
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.573366,
        34.9888016,
        136.755169,
        35.180931
      ]
    },
    {
      "id": 3819,
      "name": "Atbara",
      "names": {
        "zh": "阿特巴拉"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 17.690719,
        "lng": 33.98073
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        33.9127901,
        16.4308607,
        35.7097549,
        17.9031782
      ]
    },
    {
      "id": 3820,
      "name": "Pakpattan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.349832,
        "lng": 73.390487
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.365355,
        30.328058,
        73.409637,
        30.368177
      ]
    },
    {
      "id": 3821,
      "name": "Chicacole",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.28481,
        "lng": 83.89702
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3822,
      "name": "Pabna",
      "names": {
        "zh": "巴布纳"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.0068082,
        "lng": 89.2448341
      },
      "country": "Bangladesh",
      "importance": 3,
      "bbox": [
        89.0848341,
        23.8468082,
        89.4048341,
        24.1668082
      ]
    },
    {
      "id": 3823,
      "name": "Botucatu",
      "names": {
        "zh": "博图卡图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8879628,
        "lng": -48.4410712
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.8524737,
        -23.0816465,
        -48.2400549,
        -22.5910162
      ]
    },
    {
      "id": 3824,
      "name": "Mino",
      "names": {
        "fr": "Miño",
        "zh": "美浓",
        "ja": "美濃"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.862898,
        "lng": 135.475719
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.436997,
        34.8071041,
        135.526222,
        34.910866
      ]
    },
    {
      "id": 3825,
      "name": "Shizuishan",
      "names": {
        "zh": "石嘴山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.23333,
        "lng": 106.76944
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        106.74944,
        39.21333,
        106.78944,
        39.25333
      ]
    },
    {
      "id": 3826,
      "name": "La Trinidad",
      "names": {
        "zh": "拉特立尼达",
        "ja": "ラトリニダード",
        "th": "ลาตรินิแดด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.4617181,
        "lng": 120.5885189
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.533874,
        16.4281447,
        120.6657383,
        16.508657
      ]
    },
    {
      "id": 3827,
      "name": "Koga",
      "names": {
        "zh": "古贺",
        "ja": "古賀"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1945874,
        "lng": 139.7095071
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7045071,
        36.1895874,
        139.7145071,
        36.1995874
      ]
    },
    {
      "id": 3828,
      "name": "Tan An",
      "names": {
        "es": "Broncearse",
        "fr": "Bronzer",
        "zh": "晒黑",
        "de": "Bräunen",
        "it": "Abbronzatura",
        "id": "Tan sebuah",
        "ja": "タンan",
        "th": "ตาล",
        "pt": "Tan um"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.5364717,
        "lng": 106.4099039
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.347095,
        10.4756617,
        106.4607103,
        10.5764685
      ]
    },
    {
      "id": 3829,
      "name": "Sale",
      "names": {
        "es": "Venta",
        "fr": "Vente",
        "zh": "销售",
        "de": "Verkauf",
        "it": "Vendita",
        "id": "Dijual",
        "ja": "販売",
        "th": "ขาย",
        "pt": "Venda"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4244939,
        "lng": -2.318415
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.358415,
        53.3844939,
        -2.278415,
        53.4644939
      ]
    },
    {
      "id": 3830,
      "name": "Coquitlam",
      "names": {
        "zh": "高贵林",
        "th": "ควิท"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2842958,
        "lng": -122.793281
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.8937644,
        49.2198547,
        -122.6199095,
        49.3513789
      ]
    },
    {
      "id": 3831,
      "name": "Carrollton",
      "names": {
        "zh": "卡罗尔顿",
        "ja": "キャロルトン",
        "th": "แคร์รอลล์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9537349,
        "lng": -96.8902816
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.9586136,
        32.9180972,
        -96.8352699,
        33.0551786
      ]
    },
    {
      "id": 3832,
      "name": "San Jose del Cabo",
      "names": {
        "zh": "圣何塞德德布诺",
        "ja": "サンノゼデルカボ",
        "th": "ซานโฮเซ่เดลคาโบ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.0598364,
        "lng": -109.7025148
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.8625148,
        22.8998364,
        -109.5425148,
        23.2198364
      ]
    },
    {
      "id": 3833,
      "name": "Ishizaki",
      "names": {
        "zh": "石崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8619718,
        "lng": 139.55935
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5593,
        35.8619218,
        139.5594,
        35.8620218
      ]
    },
    {
      "id": 3834,
      "name": "Grand Junction",
      "names": {
        "es": "Gran cruce",
        "fr": "Grand jonction",
        "zh": "大交界处",
        "de": "Großankömmling",
        "it": "Grande incrocio",
        "id": "Persimpangan",
        "ja": "グランドジャンクション",
        "th": "ชุมทางแกรนด์",
        "pt": "Junção grande"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0672568,
        "lng": -108.56448
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -108.675724,
        39.018628,
        -108.463718,
        39.15274
      ]
    },
    {
      "id": 3835,
      "name": "Kokshetau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 53.28244,
        "lng": 69.39692
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        69.37692,
        53.26244,
        69.41692,
        53.30244
      ]
    },
    {
      "id": 3836,
      "name": "Al Khmissat",
      "names": {
        "ja": "アルKmissat"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 40.172188,
        "lng": -74.698643
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -74.698659,
        40.172004,
        -74.698626,
        40.172372
      ]
    },
    {
      "id": 3837,
      "name": "Bat Yam",
      "names": {
        "es": "Ñame",
        "fr": "Battez-vous",
        "zh": "蝙蝠山药",
        "de": "Bat-Yams",
        "it": "Battito",
        "id": "Kelelawar",
        "ja": "バットヤム",
        "th": "ค้างคาวมันเทศ",
        "pt": "Inhame do morcego"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0154565,
        "lng": 34.7505283
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.7287471,
        31.9924905,
        34.7619431,
        32.0337564
      ]
    },
    {
      "id": 3838,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9289015,
        "lng": 121.4246794
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.3699446,
        13.8373558,
        121.4875201,
        14.0618587
      ]
    },
    {
      "id": 3839,
      "name": "Tsuchiura",
      "names": {
        "ja": "土浦"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0779952,
        "lng": 140.2060155
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.2010155,
        36.0729952,
        140.2110155,
        36.0829952
      ]
    },
    {
      "id": 3840,
      "name": "Tiruvannamalai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 12.2384579,
        "lng": 79.0778617
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.0728617,
        12.2334579,
        79.0828617,
        12.2434579
      ]
    },
    {
      "id": 3841,
      "name": "Piranshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6960098,
        "lng": 45.14096817135113
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.1252263,
        36.6729984,
        45.1669169,
        36.719005
      ]
    },
    {
      "id": 3842,
      "name": "Umtata",
      "names": {
        "zh": "乌姆塔塔"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -31.589075,
        "lng": 28.789145
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        28.629145,
        -31.749075,
        28.949145,
        -31.429075
      ]
    },
    {
      "id": 3843,
      "name": "Dipolog",
      "names": {
        "zh": "第波罗"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5604242,
        "lng": 123.3269956
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.3269456,
        8.5603742,
        123.3270456,
        8.5604742
      ]
    },
    {
      "id": 3844,
      "name": "Charleston",
      "names": {
        "zh": "查尔斯顿",
        "ja": "チャールストン",
        "th": "ชาร์ลสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.3505995,
        "lng": -81.6332812
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -81.727777,
        38.281139,
        -81.559457,
        38.4042658
      ]
    },
    {
      "id": 3845,
      "name": "Orange",
      "names": {
        "es": "Naranja",
        "zh": "橙色",
        "it": "Arancia",
        "id": "Jeruk",
        "ja": "オレンジ",
        "th": "สีส้ม",
        "pt": "Laranja"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.750631,
        "lng": -117.8722311
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1527575,
        33.3336696,
        -117.4136102,
        33.9476346
      ]
    },
    {
      "id": 3846,
      "name": "Fullerton",
      "names": {
        "zh": "馥敦",
        "ja": "フラートン",
        "th": "ฟุลเลอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8708215,
        "lng": -117.929416
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.9854168,
        33.8541649,
        -117.8637071,
        33.9244572
      ]
    },
    {
      "id": 3847,
      "name": "Sancti Spiritus",
      "names": {
        "id": "Sancti spiritu"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.92972,
        "lng": -79.4425
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -79.4425,
        21.92972,
        -79.4425,
        21.92972
      ]
    },
    {
      "id": 3848,
      "name": "Koganei",
      "names": {
        "zh": "小金井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.698404,
        "lng": 139.500254
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.466649,
        35.668672,
        139.537354,
        35.723717
      ]
    },
    {
      "id": 3849,
      "name": "Tultepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6868252,
        "lng": -99.128342
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.168342,
        19.6468252,
        -99.088342,
        19.7268252
      ]
    },
    {
      "id": 3850,
      "name": "Jolo",
      "names": {
        "zh": "霍洛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.05222,
        "lng": 121.00222
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.98222,
        6.03222,
        121.02222,
        6.07222
      ]
    },
    {
      "id": 3851,
      "name": "Zama",
      "names": {
        "zh": "座间"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4807147,
        "lng": 139.3999256
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3949256,
        35.4757147,
        139.4049256,
        35.4857147
      ]
    },
    {
      "id": 3852,
      "name": "Shunan",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0550595,
        "lng": 131.8064092
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.634212,
        33.9569228,
        132.030767,
        34.356186
      ]
    },
    {
      "id": 3853,
      "name": "Dumaguete City",
      "names": {
        "es": "Ciudad Dumaguete",
        "zh": "杜马格特市",
        "de": "Dumaguete-Stadt",
        "it": "Città di Dumaguete",
        "id": "Kota Dumaguete",
        "ja": "ダムゲテシティ",
        "th": "เมือง Dumaguete",
        "pt": "Cidade de dumaguete"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.307,
        "lng": 123.3074
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.2874,
        9.287,
        123.3274,
        9.327
      ]
    },
    {
      "id": 3854,
      "name": "Mojokerto",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.4725876,
        "lng": 112.4340515
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        112.4290515,
        -7.4775876,
        112.4390515,
        -7.4675876
      ]
    },
    {
      "id": 3855,
      "name": "Darwin",
      "names": {
        "zh": "达尔文",
        "ja": "ダーウィン",
        "th": "ดาร์วิน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -12.46044,
        "lng": 130.8410469
      },
      "country": "Australia",
      "importance": 1,
      "bbox": [
        130.6810469,
        -12.62044,
        131.0010469,
        -12.30044
      ]
    },
    {
      "id": 3856,
      "name": "Ingolstadt",
      "names": {
        "zh": "因戈尔施塔特",
        "ja": "インゴルシュタット"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7630165,
        "lng": 11.4250395
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.2559076,
        48.6838773,
        11.5034223,
        48.8228907
      ]
    },
    {
      "id": 3857,
      "name": "Mandya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 12.5238888,
        "lng": 76.8961961
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.7361961,
        12.3638888,
        77.0561961,
        12.6838888
      ]
    },
    {
      "id": 3858,
      "name": "Greeley",
      "names": {
        "zh": "格里利",
        "ja": "グリーリー",
        "th": "ฮิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4233142,
        "lng": -104.7091322
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.907386,
        40.377566,
        -104.619143,
        40.481218
      ]
    },
    {
      "id": 3859,
      "name": "Ch'ungmu",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 34.735684,
        "lng": 126.386267
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.286267,
        34.6356835,
        126.486267,
        34.8356835
      ]
    },
    {
      "id": 3860,
      "name": "Tarragona",
      "names": {
        "fr": "Tarragone",
        "zh": "塔拉戈纳",
        "ja": "タラゴナ",
        "th": "ตาราโกนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.1172364,
        "lng": 1.2546057
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        1.1870606,
        41.0786393,
        1.3752004,
        41.1668803
      ]
    },
    {
      "id": 3861,
      "name": "Birganj",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 27.01043,
        "lng": 84.87735
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        84.85735,
        26.99043,
        84.89735,
        27.03043
      ]
    },
    {
      "id": 3862,
      "name": "Palhoca",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -27.6447774,
        "lng": -48.6646323
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.7485245,
        -27.917059,
        -48.5731101,
        -27.6066727
      ]
    },
    {
      "id": 3863,
      "name": "Lira",
      "names": {
        "zh": "里拉",
        "ja": "リラ",
        "th": "ลีร่า"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.2456918,
        "lng": 32.896244
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.736244,
        2.0856918,
        33.056244,
        2.4056918
      ]
    },
    {
      "id": 3864,
      "name": "Carcar",
      "names": {
        "zh": "卡尔卡尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1061,
        "lng": 123.6402
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6202,
        10.0861,
        123.6602,
        10.1261
      ]
    },
    {
      "id": 3865,
      "name": "Negage",
      "names": {
        "zh": "加吉"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -7.7661309,
        "lng": 15.2685532
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.2285532,
        -7.8061309,
        15.3085532,
        -7.7261309
      ]
    },
    {
      "id": 3866,
      "name": "Gunungsitoli",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.25329,
        "lng": 97.641213
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        97.58806,
        1.18014,
        97.685683,
        1.324542
      ]
    },
    {
      "id": 3867,
      "name": "Yunxian Chengguanzhen",
      "names": {
        "zh": "云县成庄镇",
        "ja": "雲母Chengguanzhen"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.89139,
        "lng": 108.50472
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3868,
      "name": "Atibaia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1177393,
        "lng": -46.5547861
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.7179741,
        -23.261,
        -46.432,
        -22.999
      ]
    },
    {
      "id": 3869,
      "name": "Jyvaskyla",
      "names": {
        "fr": "Jyväskylä",
        "zh": "于韦斯屈莱",
        "de": "Jyväskylä"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.232328,
        "lng": 25.718094
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        25.634178,
        62.171161,
        25.827915,
        62.315485
      ]
    },
    {
      "id": 3870,
      "name": "Bankura",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.235601,
        "lng": 87.0660229
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.9060229,
        23.075601,
        87.2260229,
        23.395601
      ]
    },
    {
      "id": 3871,
      "name": "Kigoma",
      "names": {
        "zh": "基戈马"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -4.5126571,
        "lng": 30.0050533
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        29.5946708,
        -6.572822,
        31.4932584,
        -2.8304548
      ]
    },
    {
      "id": 3872,
      "name": "Vila Franca de Xira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.955467,
        "lng": -8.9861266
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9861766,
        38.955417,
        -8.9860766,
        38.955517
      ]
    },
    {
      "id": 3873,
      "name": "Quillacollo",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -17.3976617,
        "lng": -66.2814763
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -66.4607199,
        -17.4633835,
        -66.2229166,
        -17.0450015
      ]
    },
    {
      "id": 3874,
      "name": "Garanhuns",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.8905889,
        "lng": -36.4930896
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.6370877,
        -9.089,
        -36.3664774,
        -8.7834252
      ]
    },
    {
      "id": 3875,
      "name": "Livingstone",
      "names": {
        "zh": "利文斯通",
        "ja": "リビングストン",
        "th": "ลิฟวิงสโตน"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -17.853135,
        "lng": 25.861429
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        25.701429,
        -18.013135,
        26.021429,
        -17.693135
      ]
    },
    {
      "id": 3876,
      "name": "Bima",
      "names": {
        "zh": "玛"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.4534091,
        "lng": 118.727689
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        118.6978716,
        -8.5323601,
        118.8959996,
        -8.3402228
      ]
    },
    {
      "id": 3877,
      "name": "Dos Hermanas",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 37.283689,
        "lng": -5.9226718
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.0620156,
        37.1500854,
        -5.8832419,
        37.362076
      ]
    },
    {
      "id": 3878,
      "name": "Kisarazu",
      "names": {
        "zh": "木更津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3810808,
        "lng": 139.9247334
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.8058862,
        35.312686,
        140.129743,
        35.5212459
      ]
    },
    {
      "id": 3879,
      "name": "Parla",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 40.2373952,
        "lng": -3.7718902
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.8074244,
        40.2012402,
        -3.7338044,
        40.256532
      ]
    },
    {
      "id": 3880,
      "name": "Curico",
      "names": {
        "es": "Curicó",
        "zh": "库里科"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -34.985368,
        "lng": -71.2393705
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.3835919,
        -35.5595278,
        -70.367893,
        -34.8881267
      ]
    },
    {
      "id": 3881,
      "name": "Porto Amboim",
      "names": {
        "fr": "Porto Amboime",
        "th": "ปอร์โตแอมโบม"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -10.7279102,
        "lng": 13.7578988
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.7178988,
        -10.7679102,
        13.7978988,
        -10.6879102
      ]
    },
    {
      "id": 3882,
      "name": "Nasugbu",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0722,
        "lng": 120.6332
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6132,
        14.0522,
        120.6532,
        14.0922
      ]
    },
    {
      "id": 3883,
      "name": "Gingoog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.8232748,
        "lng": 125.1012978
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.9631308,
        8.6739424,
        125.245792,
        8.9364576
      ]
    },
    {
      "id": 3884,
      "name": "Maia",
      "names": {
        "zh": "玛雅",
        "ja": "マイア"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.237345649999995,
        "lng": -8.629998173364326
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6939838,
        41.1796321,
        -8.5152643,
        41.2953689
      ]
    },
    {
      "id": 3885,
      "name": "Uppsala",
      "names": {
        "zh": "乌普萨拉",
        "ja": "ウプサラ",
        "th": "อุปซอลา"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.8586126,
        "lng": 17.6387436
      },
      "country": "Sweden",
      "importance": 3,
      "bbox": [
        17.4787436,
        59.6986126,
        17.7987436,
        60.0186126
      ]
    },
    {
      "id": 3886,
      "name": "Yaizu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.87226,
        "lng": 138.318201
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.318151,
        34.87221,
        138.318251,
        34.87231
      ]
    },
    {
      "id": 3887,
      "name": "Carolina",
      "names": {
        "fr": "Caroline",
        "zh": "南卡罗来纳州",
        "ja": "カロライナ州",
        "th": "แคโรไลนา"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.364027749999998,
        "lng": -65.95264686081545
      },
      "country": "Puerto Rico",
      "importance": 3,
      "bbox": [
        -66.0430973,
        18.270047,
        -65.9017922,
        18.4580066
      ]
    },
    {
      "id": 3888,
      "name": "Marivan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.5219063,
        "lng": 46.1758889
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        46.1406469,
        35.4997404,
        46.2315881,
        35.5424781
      ]
    },
    {
      "id": 3889,
      "name": "Santa Tecla",
      "names": {
        "zh": "圣塔雷加",
        "ja": "サンタテクラ",
        "th": "ซานตาเทคล่า"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.6742985,
        "lng": -89.2880412
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -89.3727932,
        13.5366902,
        -89.2624956,
        13.7470591
      ]
    },
    {
      "id": 3890,
      "name": "Inazawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.25,
        "lng": 136.78333
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.76333,
        35.23,
        136.80333,
        35.27
      ]
    },
    {
      "id": 3891,
      "name": "Stara Zagora",
      "names": {
        "id": "Sara Zagora"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.4248111,
        "lng": 25.6257479
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        25.4657479,
        42.2648111,
        25.7857479,
        42.5848111
      ]
    },
    {
      "id": 3892,
      "name": "Amiens",
      "names": {
        "zh": "亚眠",
        "ja": "アミアン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.8941708,
        "lng": 2.2956951
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2235574,
        49.846837,
        2.3457767,
        49.9505487
      ]
    },
    {
      "id": 3893,
      "name": "Chech'on",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.80537005,
        "lng": -122.29556894999999
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        -122.2959437,
        37.8052481,
        -122.2951942,
        37.805492
      ]
    },
    {
      "id": 3894,
      "name": "Norzagaray",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9109,
        "lng": 121.0493
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0293,
        14.8909,
        121.0693,
        14.9309
      ]
    },
    {
      "id": 3895,
      "name": "Jinotega",
      "names": {
        "zh": "希诺特加"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.082553,
        "lng": -85.9928072
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -85.9928572,
        13.082503,
        -85.9927572,
        13.082603
      ]
    },
    {
      "id": 3896,
      "name": "Tiraspol",
      "names": {
        "zh": "蒂拉斯波尔",
        "ja": "ティラスポリ"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.8476247,
        "lng": 29.629596
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.624596,
        46.8426247,
        29.634596,
        46.8526247
      ]
    },
    {
      "id": 3897,
      "name": "Pageralam",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.0209526,
        "lng": 103.2501603
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        103.1300238,
        -4.2671728,
        103.4154074,
        -3.9847772
      ]
    },
    {
      "id": 3898,
      "name": "Tizi Ouzou",
      "names": {
        "ja": "尾塚"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.713796,
        "lng": 4.0493851
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        4.0493351,
        36.713746,
        4.0494351,
        36.713846
      ]
    },
    {
      "id": 3899,
      "name": "Ksar El Kebir",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.999218,
        "lng": -5.898724
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.9361798,
        34.9820152,
        -5.8791882,
        35.0298285
      ]
    },
    {
      "id": 3900,
      "name": "Termiz",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 37.22417,
        "lng": 67.27833
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        67.25833,
        37.20417,
        67.29833,
        37.24417
      ]
    },
    {
      "id": 3901,
      "name": "Floridablanca",
      "names": {
        "zh": "佛罗里达布兰卡"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9745982,
        "lng": 120.5277236
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.401476,
        14.8944879,
        120.5570358,
        15.051325
      ]
    },
    {
      "id": 3902,
      "name": "Targu-Mures",
      "names": {
        "es": "Targu Mures-",
        "fr": "-Targu mures",
        "de": "Târgu-mures",
        "it": "Targu Mures-",
        "pt": "Târgu-Mures"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.5446253,
        "lng": 24.561196
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        24.4918674,
        46.500017,
        24.6268485,
        46.5961738
      ]
    },
    {
      "id": 3903,
      "name": "Las Cruces",
      "names": {
        "fr": "Cruces Las",
        "zh": "拉斯克拉斯",
        "de": "Laskürze",
        "it": "Las si croga",
        "ja": "ラスクルーセス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3140354,
        "lng": -106.779807
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -106.973286,
        32.242285,
        -106.665671,
        32.431869
      ]
    },
    {
      "id": 3904,
      "name": "'s-Hertogenbosch",
      "names": {
        "zh": "'s-斯海尔托亨博斯",
        "th": "'เอส-hertogenbosch"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6889387,
        "lng": 5.303116
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        5.2064942,
        51.6639966,
        5.3822104,
        51.7557341
      ]
    },
    {
      "id": 3905,
      "name": "Salerno",
      "names": {
        "zh": "萨勒诺",
        "ja": "サレルノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6803601,
        "lng": 14.7594542
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.7339986,
        40.625686,
        14.8742551,
        40.7269886
      ]
    },
    {
      "id": 3906,
      "name": "Rionegro",
      "names": {
        "zh": "里奥内格罗"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1536166,
        "lng": -75.3741691
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5341691,
        5.9936166,
        -75.2141691,
        6.3136166
      ]
    },
    {
      "id": 3907,
      "name": "Panama City",
      "names": {
        "es": "Ciudad de Panamá",
        "zh": "巴拿马城",
        "de": "Panama-Stadt",
        "id": "Kota Panama",
        "ja": "パナマシティ",
        "th": "ปานามาซิตี้",
        "pt": "Cidade do Panamá"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.1586518,
        "lng": -85.6602936
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.7373396,
        30.1340692,
        -85.4606971,
        30.2776031
      ]
    },
    {
      "id": 3908,
      "name": "Blitar",
      "names": {
        "zh": "勿里达"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.1010441,
        "lng": 112.1614014
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        112.1613514,
        -8.1010941,
        112.1614514,
        -8.1009941
      ]
    },
    {
      "id": 3909,
      "name": "Harlingen",
      "names": {
        "zh": "哈林根",
        "th": "ฮาร์ลิงเจน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1907543,
        "lng": -97.6960599
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.796356,
        26.057123,
        -97.615781,
        26.279894
      ]
    },
    {
      "id": 3910,
      "name": "Toowoomba",
      "names": {
        "zh": "图文巴"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -27.5610193,
        "lng": 151.953351
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        151.793351,
        -27.7210193,
        152.113351,
        -27.4010193
      ]
    },
    {
      "id": 3911,
      "name": "Chiang Mai",
      "names": {
        "zh": "清迈",
        "ja": "チェンマイ",
        "th": "เชียงใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.7882778,
        "lng": 98.9858802
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        98.8258802,
        18.6282778,
        99.1458802,
        18.9482778
      ]
    },
    {
      "id": 3912,
      "name": "Brighton",
      "names": {
        "zh": "布赖顿",
        "ja": "ブライトン",
        "th": "ไบรตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8288602,
        "lng": -0.1407393
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.1457393,
        50.8238602,
        -0.1357393,
        50.8338602
      ]
    },
    {
      "id": 3913,
      "name": "Tobruk",
      "names": {
        "fr": "Tobrouk",
        "zh": "托布鲁克",
        "th": "บรุค"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.0773764,
        "lng": 23.9599988
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        23.7999988,
        31.9173764,
        24.1199988,
        32.2373764
      ]
    },
    {
      "id": 3914,
      "name": "Tauranga",
      "names": {
        "zh": "陶兰加",
        "ja": "タウランガ",
        "th": "เทารางงา"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.6859006,
        "lng": 176.167505
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        176.007505,
        -37.8459006,
        176.327505,
        -37.5259006
      ]
    },
    {
      "id": 3915,
      "name": "Pyay",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 18.816667,
        "lng": 95.216667
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.1496767,
        18.7272335,
        95.4781384,
        19.1004428
      ]
    },
    {
      "id": 3916,
      "name": "Ramapo",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.1410876,
        "lng": -74.1685968
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.1885968,
        41.1210876,
        -74.1485968,
        41.1610876
      ]
    },
    {
      "id": 3917,
      "name": "Shuangcheng",
      "names": {
        "zh": "双城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.3662519,
        "lng": 126.3046846
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        125.6782545,
        45.1312119,
        126.7153927,
        45.7122614
      ]
    },
    {
      "id": 3918,
      "name": "Angono",
      "names": {
        "zh": "安戈诺"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5258481,
        "lng": 121.1529683
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1183452,
        14.4894504,
        121.195671,
        14.5612702
      ]
    },
    {
      "id": 3919,
      "name": "Cartago",
      "names": {
        "zh": "卡塔戈",
        "ja": "カルタゴ",
        "th": "คาร์ตาโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7465494,
        "lng": -75.9121234
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9578406,
        4.7223476,
        -75.8961906,
        4.7733712
      ]
    },
    {
      "id": 3920,
      "name": "Urganch",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.55,
        "lng": 60.63333
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        60.61333,
        41.53,
        60.65333,
        41.57
      ]
    },
    {
      "id": 3921,
      "name": "May Pen",
      "names": {
        "es": "Pluma",
        "fr": "Pen",
        "zh": "可以笔",
        "de": "Mai Pen",
        "it": "Penna",
        "id": "Dapat pena",
        "th": "อาจปากกา",
        "pt": "Pode caneta"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.966615,
        "lng": -77.233281
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -77.2532808855,
        17.946615213,
        -77.2132808855,
        17.986615213
      ]
    },
    {
      "id": 3922,
      "name": "Zafarwal",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.343177,
        "lng": 74.902088
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        74.888489,
        32.324693,
        74.918194,
        32.354918
      ]
    },
    {
      "id": 3923,
      "name": "West Valley City",
      "names": {
        "es": "Ciudad de West Valley",
        "zh": "西山谷市",
        "de": "Westtalstadt",
        "id": "Kota Lembah Barat",
        "ja": "ウェストバレーシティ",
        "pt": "Cidade do Vale do Oeste"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6967254,
        "lng": -111.986821
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.097261,
        40.6312174,
        -111.9202207,
        40.72683
      ]
    },
    {
      "id": 3924,
      "name": "Andimeshk",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.4557063,
        "lng": 48.3514068
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.3464068,
        32.4507063,
        48.3564068,
        32.4607063
      ]
    },
    {
      "id": 3925,
      "name": "Santa Rita",
      "names": {
        "zh": "圣丽塔",
        "ja": "サンタリタ",
        "th": "ซานตาริต้า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.128465,
        "lng": -34.9846186
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.1578667,
        -7.282,
        -34.8589552,
        -6.9124918
      ]
    },
    {
      "id": 3926,
      "name": "Daraga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1479697,
        "lng": 123.7120775
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6280185,
        13.0108038,
        123.7288776,
        13.2555075
      ]
    },
    {
      "id": 3927,
      "name": "Milagro",
      "names": {
        "zh": "米拉格罗",
        "ja": "ミラグロ"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.1289136,
        "lng": -79.5935005
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -79.6450313,
        -2.2344226,
        -79.5120517,
        -1.9878067
      ]
    },
    {
      "id": 3928,
      "name": "Nakhon Ratchasima",
      "names": {
        "ja": "ナコーンラーチャシーマ",
        "th": "นครราชสีมา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.9722695,
        "lng": 102.0788985
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.0738985,
        14.9672695,
        102.0838985,
        14.9772695
      ]
    },
    {
      "id": 3929,
      "name": "El Oued",
      "names": {
        "fr": "Eluge",
        "zh": "eloued",
        "ja": "エルウォー",
        "pt": "El ured"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.57593405,
        "lng": 7.08552876295442
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        7.0853457,
        33.5757682,
        7.0857011,
        33.5760934
      ]
    },
    {
      "id": 3930,
      "name": "Ashqelon",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 31.66926,
        "lng": 34.57149
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.55149,
        31.64926,
        34.59149,
        31.68926
      ]
    },
    {
      "id": 3931,
      "name": "Mataro",
      "names": {
        "es": "Mataró",
        "fr": "Mataró",
        "zh": "马塔罗",
        "it": "Mataró"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5330483,
        "lng": 2.4455242
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.4405242,
        41.5280483,
        2.4505242,
        41.5380483
      ]
    },
    {
      "id": 3932,
      "name": "Teofilo Otoni",
      "names": {
        "ja": "テオフィロオトニ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.8579267,
        "lng": -41.5081533
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.672369,
        -18.041814,
        -41.040052,
        -17.330054
      ]
    },
    {
      "id": 3933,
      "name": "Hagonoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8355035,
        "lng": 120.7335206
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6767048,
        14.6099818,
        120.7763515,
        14.8823626
      ]
    },
    {
      "id": 3934,
      "name": "Laghouat",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.7986637,
        "lng": 2.8197607
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.8147607,
        33.7936637,
        2.8247607,
        33.8036637
      ]
    },
    {
      "id": 3935,
      "name": "Moron",
      "names": {
        "es": "Morón",
        "fr": "Idiot",
        "zh": "笨蛋",
        "de": "Trottel",
        "id": "Bodoh",
        "ja": "バカ",
        "th": "คนโง่",
        "pt": "Imbecil"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 49.6284811,
        "lng": 100.1745571
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        100.0787532,
        49.5915044,
        100.3389907,
        49.7330366
      ]
    },
    {
      "id": 3936,
      "name": "Shahreza",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.0116032,
        "lng": 51.8651719
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.8094607,
        31.9327246,
        51.910536,
        32.0543503
      ]
    },
    {
      "id": 3937,
      "name": "Zabol",
      "names": {
        "id": "Zabul",
        "th": "ซาโบล"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.0277779,
        "lng": 61.4948211
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        61.4727545,
        30.9968506,
        61.536556,
        31.0562631
      ]
    },
    {
      "id": 3938,
      "name": "Apatzingan de la Constitucion",
      "names": {
        "es": "Apatzingan de la Constitución",
        "de": "Apatzingan de la konkurrieren",
        "id": "Apatzingan de la konstituus",
        "ja": "Apatzingan de la構成"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0913792,
        "lng": -102.3537356
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -102.5137356,
        18.9313792,
        -102.1937356,
        19.2513792
      ]
    },
    {
      "id": 3939,
      "name": "Hampton",
      "names": {
        "zh": "汉普顿",
        "ja": "ハンプトン",
        "th": "แฮมป์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0264414,
        "lng": -76.3442846
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.4513394,
        36.919408,
        -76.0843199,
        37.1513676
      ]
    },
    {
      "id": 3940,
      "name": "Famalicao",
      "names": {
        "es": "Famalicão",
        "fr": "Famalicão",
        "pt": "Famalicão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.414376399999995,
        "lng": -8.522835161985839
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5304667,
        41.4031605,
        -8.5138503,
        41.4256435
      ]
    },
    {
      "id": 3941,
      "name": "Naga",
      "names": {
        "zh": "那伽",
        "ja": "ナーガ",
        "th": "นาค"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2084724,
        "lng": 123.7590963
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.5990963,
        10.0484724,
        123.9190963,
        10.3684724
      ]
    },
    {
      "id": 3942,
      "name": "Trois-Rivieres",
      "names": {
        "zh": "三河-rivieres",
        "ja": "トロワ-rivieres",
        "th": "ทรัวส์-rivieres"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.3432325,
        "lng": -72.5428485
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.7794177,
        46.2528233,
        -72.4087824,
        46.4691512
      ]
    },
    {
      "id": 3943,
      "name": "Torrejon de Ardoz",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4587767,
        "lng": -3.4793343
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.4980069,
        40.4306868,
        -3.4252328,
        40.5109451
      ]
    },
    {
      "id": 3944,
      "name": "Zhangmu Touwei",
      "names": {
        "zh": "张谟·托威",
        "ja": "Zhangmu Touii"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.89558,
        "lng": 114.07498
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.05498,
        22.87558,
        114.09498,
        22.91558
      ]
    },
    {
      "id": 3945,
      "name": "Londuimbali",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.2415575,
        "lng": 15.312229
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.292229,
        -12.2615575,
        15.332229,
        -12.2215575
      ]
    },
    {
      "id": 3946,
      "name": "Rehovot",
      "names": {
        "zh": "雷霍沃特"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.8952532,
        "lng": 34.8105616
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.7621869,
        31.8663637,
        34.8511431,
        31.9205222
      ]
    },
    {
      "id": 3947,
      "name": "Batticaloa",
      "names": {
        "zh": "拜蒂克洛"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 7.7244732,
        "lng": 81.69871739948186
      },
      "country": "Sri Lanka",
      "importance": 3,
      "bbox": [
        81.6984126,
        7.7241754,
        81.6990307,
        7.7247644
      ]
    },
    {
      "id": 3948,
      "name": "Tabora",
      "names": {
        "zh": "塔博拉"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.0202431,
        "lng": 32.8097724
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        32.6497724,
        -5.1802431,
        32.9697724,
        -4.8602431
      ]
    },
    {
      "id": 3949,
      "name": "Navoiy",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.1034582,
        "lng": 65.3734221
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        65.2573093,
        40.0688488,
        65.4345714,
        40.1303829
      ]
    },
    {
      "id": 3950,
      "name": "Tando Allahyar",
      "names": {
        "it": "Tando alahyar",
        "ja": "タンドアアレラル"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.4619907,
        "lng": 68.7198264
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.5598264,
        25.3019907,
        68.8798264,
        25.6219907
      ]
    },
    {
      "id": 3951,
      "name": "Idfu",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 24.980278,
        "lng": 32.8747219
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        32.8587486,
        24.9556506,
        32.8947637,
        25.0271082
      ]
    },
    {
      "id": 3952,
      "name": "Segou",
      "names": {
        "fr": "Ségou",
        "zh": "塞古",
        "de": "Ségou"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.441518,
        "lng": -6.268147
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -6.428147,
        13.281518,
        -6.108147,
        13.601518
      ]
    },
    {
      "id": 3953,
      "name": "Warren",
      "names": {
        "zh": "沃伦",
        "ja": "ウォーレン",
        "th": "วอร์เรน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8119602,
        "lng": -79.2654452
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.6129293,
        41.623863,
        -78.915674,
        41.9997165
      ]
    },
    {
      "id": 3954,
      "name": "Isahaya",
      "names": {
        "zh": "谏早"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.843426,
        "lng": 130.0530537
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        129.8854361,
        32.6719785,
        130.2514075,
        32.975379
      ]
    },
    {
      "id": 3955,
      "name": "Mauldin",
      "names": {
        "zh": "莫尔丁"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.781418,
        "lng": -82.307852
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.333596,
        34.745726,
        -82.262548,
        34.821887
      ]
    },
    {
      "id": 3956,
      "name": "Bloomington",
      "names": {
        "zh": "布卢明顿",
        "ja": "ブルーミントン",
        "th": "ในบลูมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4797828,
        "lng": -88.9939147
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.067715,
        40.429435,
        -88.863539,
        40.518352
      ]
    },
    {
      "id": 3957,
      "name": "Jiangshan",
      "names": {
        "zh": "江山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.7417596,
        "lng": 118.6226466
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.3767468,
        28.2414132,
        118.8138229,
        28.8916886
      ]
    },
    {
      "id": 3958,
      "name": "Apopa",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.8013036,
        "lng": -89.1790784
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.2564139,
        13.746923,
        -89.1500174,
        13.8518462
      ]
    },
    {
      "id": 3959,
      "name": "Coral Springs",
      "names": {
        "fr": "Ressorts coralliens",
        "zh": "珊瑚泉",
        "de": "Korallenfedern",
        "it": "Sorgenti di corallo",
        "id": "Pegas karang",
        "ja": "サンゴスプリングス",
        "th": "ปะการังสปริงส์",
        "pt": "Corais"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.271192,
        "lng": -80.2706044
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2974021,
        26.2296745,
        -80.2016082,
        26.3109974
      ]
    },
    {
      "id": 3960,
      "name": "Innsbruck",
      "names": {
        "zh": "因斯布鲁克",
        "ja": "インスブルック",
        "th": "อินส์บรุค"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2654296,
        "lng": 11.3927685
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        11.3016374,
        47.2107727,
        11.4559615,
        47.3601261
      ]
    },
    {
      "id": 3961,
      "name": "Battambang",
      "names": {
        "zh": "马德望",
        "th": "พระตะบอง"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.10271,
        "lng": 103.19822
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        103.17822,
        13.08271,
        103.21822,
        13.12271
      ]
    },
    {
      "id": 3962,
      "name": "Ome",
      "names": {
        "zh": "甲酯"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7880531,
        "lng": 139.2753553
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.137157,
        35.754545,
        139.330362,
        35.850868
      ]
    },
    {
      "id": 3963,
      "name": "Jijel",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.817429,
        "lng": 5.7751623
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.7751123,
        36.817379,
        5.7752123,
        36.817479
      ]
    },
    {
      "id": 3964,
      "name": "Matagalpa",
      "names": {
        "zh": "马塔加尔帕"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.9285563,
        "lng": -85.9174331
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.0647379,
        12.7593354,
        -85.6779418,
        13.1005605
      ]
    },
    {
      "id": 3965,
      "name": "Gondia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.455228,
        "lng": 80.1962729
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.1819401,
        21.4418121,
        80.2279866,
        21.4733572
      ]
    },
    {
      "id": 3966,
      "name": "Gyor",
      "names": {
        "fr": "Györ",
        "zh": "杰尔",
        "th": "เจอร์"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6822145,
        "lng": 17.635402
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        17.635352,
        47.6821645,
        17.635452,
        47.6822645
      ]
    },
    {
      "id": 3967,
      "name": "Hassan",
      "names": {
        "zh": "·哈桑",
        "ja": "ハッサン",
        "th": "ฮัสซัน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.0070817,
        "lng": 76.0992703
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.9392703,
        12.8470817,
        76.2592703,
        13.1670817
      ]
    },
    {
      "id": 3968,
      "name": "Pitalito",
      "names": {
        "zh": "皮塔利托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.84966,
        "lng": -76.04785
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.06785,
        1.82966,
        -76.02785,
        1.86966
      ]
    },
    {
      "id": 3969,
      "name": "Round Rock",
      "names": {
        "es": "Roca redonda",
        "fr": "Rock rond",
        "zh": "圆形岩石",
        "de": "Runde Felsen",
        "it": "Roccia rotonda",
        "id": "Batu bundar",
        "ja": "ラウンドロック",
        "th": "หินกลม",
        "pt": "Rodada"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5085915,
        "lng": -97.6788056
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.7648422,
        30.4682901,
        -97.5900541,
        30.5937774
      ]
    },
    {
      "id": 3970,
      "name": "Abiko",
      "names": {
        "zh": "我孙子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.863999,
        "lng": 140.0280653
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.988665,
        35.840754,
        140.146464,
        35.900613
      ]
    },
    {
      "id": 3971,
      "name": "Talavera",
      "names": {
        "zh": "塔拉韦拉",
        "ja": "タラベラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5843049,
        "lng": 120.917903
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.877903,
        15.5443049,
        120.957903,
        15.6243049
      ]
    },
    {
      "id": 3972,
      "name": "Yakima",
      "names": {
        "zh": "亚基马",
        "ja": "ヤキマ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.601557,
        "lng": -120.510842
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.6444942,
        46.557292,
        -120.460335,
        46.630777
      ]
    },
    {
      "id": 3973,
      "name": "Khorramshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.4333105,
        "lng": 48.1779858
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.1357176,
        30.3629037,
        48.2465029,
        30.4690937
      ]
    },
    {
      "id": 3974,
      "name": "Limoges",
      "names": {
        "zh": "利摩日",
        "ja": "リモージュ",
        "th": "ลิโมจส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.8354243,
        "lng": 1.2644847
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.1462757,
        45.7887394,
        1.3175337,
        45.9282898
      ]
    },
    {
      "id": 3975,
      "name": "Crato",
      "names": {
        "zh": "克拉图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.232498550000001,
        "lng": -39.40751772932623
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.4076209,
        -7.2326012,
        -39.4072858,
        -7.2322682
      ]
    },
    {
      "id": 3976,
      "name": "Tra Vinh",
      "names": {
        "ja": "トラヴィン",
        "pt": "Trapaceiro"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.9355829,
        "lng": 106.3397849
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.3003005,
        9.8866329,
        106.3901478,
        10.0163593
      ]
    },
    {
      "id": 3977,
      "name": "Ouargla",
      "names": {
        "zh": "瓦尔格拉"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 30.9980145,
        "lng": 6.766453639443059
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.996532,
        28.498373,
        9.473817,
        33.49884
      ]
    },
    {
      "id": 3978,
      "name": "Sinop",
      "names": {
        "zh": "锡诺普"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.857701,
        "lng": -55.4967824
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -55.831,
        -12.1871417,
        -55.067,
        -11.298
      ]
    },
    {
      "id": 3979,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.926773,
        "lng": 120.3475584
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.2448171,
        15.8499817,
        120.4045838,
        15.972036
      ]
    },
    {
      "id": 3980,
      "name": "Ninh Binh",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 20.2545421,
        "lng": 105.9764854
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.9270586,
        20.199756,
        106.0240844,
        20.2901354
      ]
    },
    {
      "id": 3981,
      "name": "Kamez",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 41.3835259,
        "lng": 19.7604224
      },
      "country": "Albania",
      "importance": 3,
      "bbox": [
        19.6960884,
        41.3479178,
        19.8550494,
        41.4134068
      ]
    },
    {
      "id": 3982,
      "name": "Cameta",
      "names": {
        "pt": "Cametá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.242949,
        "lng": -49.497885
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.8308837,
        -2.6382862,
        -49.1895393,
        -1.9237678
      ]
    },
    {
      "id": 3983,
      "name": "Ibarra",
      "names": {
        "zh": "伊巴拉",
        "th": "โลเม"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": 0.3520459,
        "lng": -78.1199716
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.1758599,
        0.2601626,
        -78.0250159,
        0.4998944
      ]
    },
    {
      "id": 3984,
      "name": "Jaramana",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4862,
        "lng": 36.34614
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.32614,
        33.4662,
        36.36614,
        33.5062
      ]
    },
    {
      "id": 3985,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 6.0428128,
        "lng": -74.9944061
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -75.0872344,
        5.9129741,
        -74.7653598,
        6.129142
      ]
    },
    {
      "id": 3986,
      "name": "Sterling Heights",
      "names": {
        "es": "Alturas de la Esterlina",
        "fr": "Hauteurs sterling",
        "zh": "英镑高度",
        "de": "Sterlinghöhen",
        "it": "Altezze di sterline",
        "ja": "スターリングハイツ",
        "th": "สเตอร์ลิงไฮทส์",
        "pt": "Alturas esterlinas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5803122,
        "lng": -83.0302033
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.0915053,
        42.534792,
        -82.9696437,
        42.6275327
      ]
    },
    {
      "id": 3987,
      "name": "Stavanger",
      "names": {
        "zh": "斯塔万格",
        "ja": "スタヴァンゲル",
        "th": "สตา"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.9699675,
        "lng": 5.7318101
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        5.499034,
        58.8846585,
        6.1313107,
        59.3121017
      ]
    },
    {
      "id": 3988,
      "name": "Kent",
      "names": {
        "zh": "肯特",
        "ja": "ケント",
        "th": "เคนท์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.3826903,
        "lng": -122.2270272
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.3094097,
        47.3435108,
        -121.995964,
        47.4412193
      ]
    },
    {
      "id": 3989,
      "name": "Ferrara",
      "names": {
        "zh": "费拉拉",
        "ja": "フェラーラ",
        "th": "เฟอร์รารา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.8372737,
        "lng": 11.6186451
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.4630611,
        44.6734331,
        11.8770223,
        44.937711
      ]
    },
    {
      "id": 3990,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.384239049999998,
        "lng": -66.05343997364733
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -66.1250904,
        18.2965306,
        -65.9914197,
        18.4719861
      ]
    },
    {
      "id": 3991,
      "name": "Calabozo",
      "names": {
        "zh": "卡拉沃索"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.9179829,
        "lng": -67.4173328
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.513513,
        8.7729269,
        -67.3064786,
        8.9981374
      ]
    },
    {
      "id": 3992,
      "name": "Pilibhit",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6294996,
        "lng": 79.8054072
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.6454072,
        28.4694996,
        79.9654072,
        28.7894996
      ]
    },
    {
      "id": 3993,
      "name": "Chicomba",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.1384141,
        "lng": 14.9124173
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.6370534,
        -14.6902258,
        15.4198322,
        -13.9313138
      ]
    },
    {
      "id": 3994,
      "name": "Palghat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.778913,
        "lng": 76.657245
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.650359,
        10.759919,
        76.65889,
        10.773141
      ]
    },
    {
      "id": 3995,
      "name": "Guelph",
      "names": {
        "zh": "圭尔夫",
        "ja": "グエルフ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.5460516,
        "lng": -80.2493276
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.3269091,
        43.4738217,
        -80.1535013,
        43.594353
      ]
    },
    {
      "id": 3996,
      "name": "Buea",
      "names": {
        "zh": "布埃亚"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.1567895,
        "lng": 9.2315915
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        9.0268122,
        4.0442089,
        9.4132641,
        4.3192825
      ]
    },
    {
      "id": 3997,
      "name": "Los Guayos",
      "names": {
        "ja": "ロスグアヨス"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1904187,
        "lng": -67.9428295
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.9428795,
        10.1903687,
        -67.9427795,
        10.1904687
      ]
    },
    {
      "id": 3998,
      "name": "Nanqiaotou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.65185,
        "lng": 113.31141
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.29141,
        22.63185,
        113.33141,
        22.67185
      ]
    },
    {
      "id": 3999,
      "name": "Ji-Parana",
      "names": {
        "es": "Ji-Paraná",
        "zh": "ji-巴拉那",
        "ja": "ji-パラナ",
        "th": "ji-ปารานา",
        "pt": "Ji-Paraná"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.8778148,
        "lng": -61.9277854
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -62.164,
        -11.2133269,
        -61.461,
        -9.684
      ]
    },
    {
      "id": 4000,
      "name": "Rijeka",
      "names": {
        "zh": "里耶卡"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3267976,
        "lng": 14.442208
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        14.3319285,
        45.307442,
        14.5309683,
        45.3840477
      ]
    },
    {
      "id": 4001,
      "name": "Guagua",
      "names": {
        "zh": "瓜瓜"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9654074,
        "lng": 120.6336297
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5210779,
        14.9296734,
        120.6626392,
        15.0241369
      ]
    },
    {
      "id": 4002,
      "name": "Palakollu",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5179946,
        "lng": 81.7253267
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.6853267,
        16.4779946,
        81.7653267,
        16.5579946
      ]
    },
    {
      "id": 4003,
      "name": "Spanish Town",
      "names": {
        "es": "Pueblo español",
        "fr": "Ville d'espagnol",
        "zh": "西班牙小镇",
        "de": "Spanische Stadt",
        "it": "Città spagnola",
        "id": "Kota Spanyol",
        "ja": "スペインの町",
        "th": "เมืองสเปน",
        "pt": "Cidade espanhola"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.9956926,
        "lng": -76.9540831
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -77.1140831,
        17.8356926,
        -76.7940831,
        18.1556926
      ]
    },
    {
      "id": 4004,
      "name": "Parepare",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.0057055,
        "lng": 119.6236101
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        119.6167789,
        -4.0799832,
        119.7214445,
        -3.9605426
      ]
    },
    {
      "id": 4005,
      "name": "Surat Thani",
      "names": {
        "zh": "苏叻他尼",
        "ja": "スラットターニー",
        "th": "สุราษฎร์ธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.1404136,
        "lng": 99.3241347
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.1641347,
        8.9804136,
        99.4841347,
        9.3004136
      ]
    },
    {
      "id": 4006,
      "name": "Narita",
      "names": {
        "zh": "成田",
        "ja": "成田",
        "th": "นาริตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7779163,
        "lng": 140.3140452
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.3090452,
        35.7729163,
        140.3190452,
        35.7829163
      ]
    },
    {
      "id": 4007,
      "name": "Relizane",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7475817,
        "lng": 0.5553933
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        0.5503933,
        35.7425817,
        0.5603933,
        35.7525817
      ]
    },
    {
      "id": 4008,
      "name": "Kayseri",
      "names": {
        "zh": "开塞利",
        "ja": "カイセリ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7219011,
        "lng": 35.4873214
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        35.3273214,
        38.5619011,
        35.6473214,
        38.8819011
      ]
    },
    {
      "id": 4009,
      "name": "Silay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7994126,
        "lng": 122.9756149
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.8156149,
        10.6394126,
        123.1356149,
        10.9594126
      ]
    },
    {
      "id": 4010,
      "name": "Burlington",
      "names": {
        "zh": "伯灵顿",
        "ja": "バーリントン",
        "th": "เบอร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0956918,
        "lng": -79.4377991
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.6214106,
        36.0097098,
        -79.3719276,
        36.1277562
      ]
    },
    {
      "id": 4011,
      "name": "Nueva Concepcion",
      "names": {
        "es": "Nueva concepción",
        "id": "Concepcion nueva",
        "ja": "ヌエバ協議"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.1850389,
        "lng": -91.299368
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.4356809,
        13.9481896,
        -91.1710681,
        14.306501
      ]
    },
    {
      "id": 4012,
      "name": "City of Isabela",
      "names": {
        "es": "Ciudad de Isabela",
        "fr": "Ville d'Isabela",
        "zh": "伊莎贝拉市",
        "de": "Stadt Isabela",
        "it": "Città di Isabela",
        "id": "Kota Isabela",
        "ja": "イザベラ市",
        "th": "เมืองอิซาเบลา",
        "pt": "Cidade de Isabela"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.69973,
        "lng": 121.96781
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4013,
      "name": "Abohar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.139809,
        "lng": 74.201134
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.161608,
        30.114866,
        74.222687,
        30.182247
      ]
    },
    {
      "id": 4014,
      "name": "Tychy",
      "names": {
        "zh": "蒂黑"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1357681,
        "lng": 18.9641781
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.9591781,
        50.1307681,
        18.9691781,
        50.1407681
      ]
    },
    {
      "id": 4015,
      "name": "Marand",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.4306358,
        "lng": 45.7741
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.7341,
        38.3906358,
        45.8141,
        38.4706358
      ]
    },
    {
      "id": 4016,
      "name": "Kanchrapara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.9496431,
        "lng": 88.4378838
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3978838,
        22.9096431,
        88.4778838,
        22.9896431
      ]
    },
    {
      "id": 4017,
      "name": "Quibdo",
      "names": {
        "zh": "基布多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6923407,
        "lng": -76.6583801
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -77.1427282,
        5.6192719,
        -76.1601892,
        6.345917
      ]
    },
    {
      "id": 4018,
      "name": "Girardot",
      "names": {
        "zh": "希拉多特"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.306643,
        "lng": -74.8015665
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8905158,
        4.265352,
        -74.739114,
        4.4342407
      ]
    },
    {
      "id": 4019,
      "name": "Pouso Alegre",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2343858,
        "lng": -45.9327241
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.064232,
        -22.414578,
        -45.8170207,
        -22.1105839
      ]
    },
    {
      "id": 4020,
      "name": "Rustavi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.54309,
        "lng": 45.0112804
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        44.8903022,
        41.4910595,
        45.0862639,
        41.601172
      ]
    },
    {
      "id": 4021,
      "name": "Cholula de Rivadabia",
      "names": {
        "fr": "Cholla de rivadiabia"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.064059,
        "lng": -98.307359
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4022,
      "name": "Bellingham",
      "names": {
        "zh": "贝灵汉",
        "ja": "ベリンガム",
        "th": "เบลลิงแฮม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.7544012,
        "lng": -122.478836
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.531455,
        48.6880058,
        -122.3987106,
        48.8174119
      ]
    },
    {
      "id": 4023,
      "name": "Onomichi",
      "names": {
        "zh": "尾道"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4088519,
        "lng": 133.2051549
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.0300969,
        34.2443572,
        133.296987,
        34.573099
      ]
    },
    {
      "id": 4024,
      "name": "Kislovodsk",
      "names": {
        "zh": "基斯洛沃茨克",
        "de": "Kislowodsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.9128743,
        "lng": 42.7192186
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.659837,
        43.8472332,
        42.7922094,
        43.9599627
      ]
    },
    {
      "id": 4025,
      "name": "Udon Thani",
      "names": {
        "zh": "乌冬掌",
        "ja": "うどん",
        "th": "อุดรธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4082448,
        "lng": 102.7868105
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.6268105,
        17.2482448,
        102.9468105,
        17.5682448
      ]
    },
    {
      "id": 4026,
      "name": "Jiroft",
      "names": {
        "zh": "吉罗夫特"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 28.67806,
        "lng": 57.74056
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        57.72056,
        28.65806,
        57.76056,
        28.69806
      ]
    },
    {
      "id": 4027,
      "name": "Santa Clara",
      "names": {
        "zh": "圣克拉拉",
        "ja": "サンタクララ",
        "th": "ซานตาคลาร่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3541132,
        "lng": -121.955174
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0048518,
        37.3230027,
        -121.929697,
        37.4185274
      ]
    },
    {
      "id": 4028,
      "name": "Vitoria de Santo Antao",
      "names": {
        "pt": "Vitória de Santo Antao"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.12021595,
        "lng": -35.2969071149367
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.2971395,
        -8.1204461,
        -35.2966747,
        -8.1199859
      ]
    },
    {
      "id": 4029,
      "name": "Leiden",
      "names": {
        "zh": "莱顿",
        "ja": "ライデン",
        "th": "ไลเดน"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1594747,
        "lng": 4.4908843
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4388652,
        52.1189516,
        4.5240721,
        52.1846262
      ]
    },
    {
      "id": 4030,
      "name": "Huejutla de Reyes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1401103,
        "lng": -98.4152209
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -98.4552209,
        21.1001103,
        -98.3752209,
        21.1801103
      ]
    },
    {
      "id": 4031,
      "name": "Zhaozhou",
      "names": {
        "zh": "肇州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7529692,
        "lng": 114.7775736
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.6966519,
        37.6802748,
        114.8388536,
        37.7836095
      ]
    },
    {
      "id": 4032,
      "name": "Sannar",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 13.5424054,
        "lng": 33.6103555
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.9432602,
        13.0016203,
        34.3024025,
        14.0697794
      ]
    },
    {
      "id": 4033,
      "name": "Lucheng",
      "names": {
        "zh": "鹿城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0359139,
        "lng": 101.5446219
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.5445719,
        25.0358639,
        101.5446719,
        25.0359639
      ]
    },
    {
      "id": 4034,
      "name": "Racine",
      "names": {
        "zh": "拉辛",
        "ja": "ラシーン",
        "th": "ไซน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7260523,
        "lng": -87.7825242
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.87555,
        42.6779473,
        -87.759946,
        42.78147
      ]
    },
    {
      "id": 4035,
      "name": "Greenville",
      "names": {
        "zh": "格林维尔",
        "ja": "グリーンヴィル",
        "th": "กรีนวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.613224,
        "lng": -77.3724593
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.475265,
        35.529776,
        -77.279305,
        35.676051
      ]
    },
    {
      "id": 4036,
      "name": "Hanumangarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.5760254,
        "lng": 74.3342203
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.1742203,
        29.4160254,
        74.4942203,
        29.7360254
      ]
    },
    {
      "id": 4037,
      "name": "Annecy",
      "names": {
        "zh": "阿纳西",
        "ja": "アヌシー",
        "th": "อานเนอซี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.90204355,
        "lng": 6.12179444611825
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.1208685,
        45.9017276,
        6.1224292,
        45.9023555
      ]
    },
    {
      "id": 4038,
      "name": "Saida",
      "names": {
        "zh": "赛达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.743349,
        "lng": 0.2440763627808458
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -0.348472,
        34.319696,
        0.933411,
        35.1670632
      ]
    },
    {
      "id": 4039,
      "name": "Esteli",
      "names": {
        "es": "Estelí",
        "zh": "埃斯特利"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.0931241,
        "lng": -86.3554436
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.4920019,
        12.9728681,
        -86.1647228,
        13.3000447
      ]
    },
    {
      "id": 4040,
      "name": "Taldyqorghan",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 45.0164138,
        "lng": 78.3791841
      },
      "country": "Kazakhstan",
      "importance": 1,
      "bbox": [
        78.1145816,
        44.932466,
        78.5327999,
        45.1593153
      ]
    },
    {
      "id": 4041,
      "name": "Cambridge",
      "names": {
        "zh": "剑桥",
        "ja": "ケンブリッジ",
        "th": "เคมบริดจ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.3600536,
        "lng": -80.3123023
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.413078,
        43.3323902,
        -80.2514908,
        43.4723418
      ]
    },
    {
      "id": 4042,
      "name": "Fengcheng",
      "names": {
        "zh": "凤城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4829753,
        "lng": 112.4190039
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.2804833,
        35.217879,
        112.5079762,
        35.5473815
      ]
    },
    {
      "id": 4043,
      "name": "Baidoa",
      "names": {
        "zh": "拜多阿"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 3.119951,
        "lng": 43.644462
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        43.484462,
        2.959951,
        43.804462,
        3.279951
      ]
    },
    {
      "id": 4044,
      "name": "Furth",
      "names": {
        "es": "Fürth",
        "fr": "Fürth",
        "zh": "菲尔特",
        "de": "Fürth",
        "ja": "フュルト",
        "th": "เฟือธ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4772475,
        "lng": 10.9893626
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8931571,
        49.4359893,
        11.0286601,
        49.5412538
      ]
    },
    {
      "id": 4045,
      "name": "Serpukhov",
      "names": {
        "zh": "谢尔普霍夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9155247,
        "lng": 37.4195533
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.3493023,
        54.8921607,
        37.4697052,
        54.9530337
      ]
    },
    {
      "id": 4046,
      "name": "Mit Ghamr",
      "names": {
        "es": "Mith ghamr"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.7191272,
        "lng": 31.2604983
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.2554983,
        30.7141272,
        31.2654983,
        30.7241272
      ]
    },
    {
      "id": 4047,
      "name": "Stamford",
      "names": {
        "zh": "斯坦福",
        "ja": "スタンフォード",
        "th": "สแตมฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0534302,
        "lng": -73.5387341
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.6339806,
        40.9645138,
        -73.4954009,
        41.179929
      ]
    },
    {
      "id": 4048,
      "name": "Villa Alemana",
      "names": {
        "zh": "别墅alemana",
        "ja": "ヴィラアレマナ",
        "th": "วิลล่า alemana"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0424859,
        "lng": -71.3735501
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -71.3785501,
        -33.0474859,
        -71.3685501,
        -33.0374859
      ]
    },
    {
      "id": 4049,
      "name": "Cienaga",
      "names": {
        "es": "Ciénaga",
        "zh": "沼泽"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 11.0062433,
        "lng": -74.241022
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2847588,
        10.6080786,
        -73.7695057,
        11.1053516
      ]
    },
    {
      "id": 4050,
      "name": "Tariba",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 7.8208151,
        "lng": -72.2228003
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -72.2628003,
        7.7808151,
        -72.1828003,
        7.8608151
      ]
    },
    {
      "id": 4051,
      "name": "Chinguar",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.55,
        "lng": 16.33333
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        16.31333,
        -12.57,
        16.35333,
        -12.53
      ]
    },
    {
      "id": 4052,
      "name": "Wurzburg",
      "names": {
        "es": "Würzburg",
        "zh": "维尔茨堡",
        "de": "Würzburg",
        "it": "Würzburg",
        "id": "Würzburg",
        "ja": "ヴュルツブルク",
        "pt": "Würzburg"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7933723,
        "lng": 9.9309779
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.8716285,
        49.7106843,
        10.0144286,
        49.8454619
      ]
    },
    {
      "id": 4053,
      "name": "Mansa",
      "names": {
        "zh": "曼萨",
        "ja": "マンサ"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -11.2006743,
        "lng": 28.8893922
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        28.8493922,
        -11.2406743,
        28.9293922,
        -11.1606743
      ]
    },
    {
      "id": 4054,
      "name": "Songnim",
      "names": {
        "zh": "松林"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.7535598,
        "lng": 125.6460599
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.5780148,
        38.7012786,
        125.6979463,
        38.8291439
      ]
    },
    {
      "id": 4055,
      "name": "Santo Tomas",
      "names": {
        "ja": "サントトマス",
        "th": "ซานโตโทมัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1078443,
        "lng": 121.1453304
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1250236,
        13.9796305,
        121.2319705,
        14.1473362
      ]
    },
    {
      "id": 4056,
      "name": "Vasteras",
      "names": {
        "zh": "韦斯特罗斯",
        "id": "Västerås"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.6110992,
        "lng": 16.5463679
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        16.3863679,
        59.4510992,
        16.7063679,
        59.7710992
      ]
    },
    {
      "id": 4057,
      "name": "Elizabeth",
      "names": {
        "zh": "伊丽莎白",
        "it": "Elisabetta",
        "ja": "エリザベス",
        "th": "เอลิซาเบธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6639916,
        "lng": -74.2107006
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.2542157,
        40.6331168,
        -74.1377655,
        40.690428
      ]
    },
    {
      "id": 4058,
      "name": "Opole",
      "names": {
        "zh": "奥波莱"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.6668184,
        "lng": 17.9236408
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        17.7795907,
        50.5876174,
        18.0326905,
        50.7699743
      ]
    },
    {
      "id": 4059,
      "name": "Novocheboksarsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.120142,
        "lng": 47.500376
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        47.466194,
        56.102165,
        47.528278,
        56.130222
      ]
    },
    {
      "id": 4060,
      "name": "Araras",
      "names": {
        "zh": "阿拉拉斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3569192,
        "lng": -47.3838766
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.488638,
        -22.476,
        -47.175,
        -22.1933707
      ]
    },
    {
      "id": 4061,
      "name": "Jaranwala",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.3330181,
        "lng": 73.4183387
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.2583387,
        31.1730181,
        73.5783387,
        31.4930181
      ]
    },
    {
      "id": 4062,
      "name": "Puno",
      "names": {
        "zh": "普诺",
        "th": "ปูโน"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -15.840646,
        "lng": -70.027778
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -70.047778,
        -15.860646,
        -70.007778,
        -15.820646
      ]
    },
    {
      "id": 4063,
      "name": "Koforidua",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.100334,
        "lng": -0.2614572
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -0.4214572,
        5.940334,
        -0.1014572,
        6.260334
      ]
    },
    {
      "id": 4064,
      "name": "Ahmadpur East",
      "names": {
        "es": "Ahmadpur este",
        "fr": "Ahmadpur Est",
        "zh": "Ahmadpur东方",
        "id": "Ahmadpur timur",
        "th": "Ahmadpur ตะวันออก"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.1437273,
        "lng": 71.2625165
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        71.1025165,
        28.9837273,
        71.4225165,
        29.3037273
      ]
    },
    {
      "id": 4065,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8454343,
        "lng": 121.2063502
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1638677,
        13.6852949,
        121.3710798,
        13.8700758
      ]
    },
    {
      "id": 4066,
      "name": "Salto",
      "names": {
        "zh": "萨尔图",
        "ja": "サルト"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -31.38889,
        "lng": -57.9608876
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -57.996522,
        -31.4161413,
        -57.8788461,
        -31.3629022
      ]
    },
    {
      "id": 4067,
      "name": "Vihari",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.0445,
        "lng": 72.3556
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4068,
      "name": "Kamina",
      "names": {
        "zh": "卡米纳"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -8.7351826,
        "lng": 25.0032201
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        24.8432201,
        -8.8951826,
        25.1632201,
        -8.5751826
      ]
    },
    {
      "id": 4069,
      "name": "Temperley",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7760956,
        "lng": -58.3961826
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.4011826,
        -34.7810956,
        -58.3911826,
        -34.7710956
      ]
    },
    {
      "id": 4070,
      "name": "Baigou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1195593,
        "lng": 116.0175538
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.9775538,
        39.0795593,
        116.0575538,
        39.1595593
      ]
    },
    {
      "id": 4071,
      "name": "Tukuyu",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.2558985,
        "lng": 33.6458601
      },
      "country": "Tanzania",
      "importance": 3,
      "bbox": [
        33.6058601,
        -9.2958985,
        33.6858601,
        -9.2158985
      ]
    },
    {
      "id": 4072,
      "name": "Shiyan",
      "names": {
        "zh": "十堰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6857611,
        "lng": 113.9316316
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.9116316,
        22.6657611,
        113.9516316,
        22.7057611
      ]
    },
    {
      "id": 4073,
      "name": "Ban Bang Pu Mai",
      "names": {
        "es": "BAN BANT PU MAI",
        "zh": "禁止Bang Pu Mai",
        "it": "Bang Bang PU Mai",
        "th": "บ้านบางปู่ใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.51309365,
        "lng": 100.70783769949156
      },
      "country": "Thailand",
      "importance": 3,
      "bbox": [
        100.7061655,
        13.5111554,
        100.7094479,
        13.5150852
      ]
    },
    {
      "id": 4074,
      "name": "Iwakuni",
      "names": {
        "zh": "岩国",
        "ja": "岩国"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1664995,
        "lng": 132.2191163
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.878434,
        33.9680465,
        132.4760549,
        34.46949
      ]
    },
    {
      "id": 4075,
      "name": "Seto",
      "names": {
        "zh": "濑户",
        "ja": "瀬戸"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.240984,
        "lng": 137.116187
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.048432,
        35.178801,
        137.189488,
        35.301647
      ]
    },
    {
      "id": 4076,
      "name": "Drohobych",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.34991,
        "lng": 23.50561
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.48561,
        49.32991,
        23.52561,
        49.36991
      ]
    },
    {
      "id": 4077,
      "name": "Bataysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.128019,
        "lng": 39.749451
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.719139,
        47.100582,
        39.788166,
        47.156166
      ]
    },
    {
      "id": 4078,
      "name": "Whitby",
      "names": {
        "zh": "惠特比",
        "ja": "ウィットビー",
        "th": "วิตบี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.87982,
        "lng": -78.9421751
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.0473097,
        43.8173744,
        -78.874655,
        44.0288194
      ]
    },
    {
      "id": 4079,
      "name": "Zoetermeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.06228915,
        "lng": 4.487754536970652
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4152309,
        52.0312806,
        4.5576864,
        52.0932903
      ]
    },
    {
      "id": 4080,
      "name": "Pinsk",
      "names": {
        "zh": "平斯克"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1113862,
        "lng": 26.1025274
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        25.9862923,
        52.0895578,
        26.153297,
        52.1644121
      ]
    },
    {
      "id": 4081,
      "name": "Sakaka",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 29.9784008,
        "lng": 40.2047701
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        39.481883,
        28.9136299,
        41.99684,
        30.9633369
      ]
    },
    {
      "id": 4082,
      "name": "My Tho",
      "names": {
        "es": "Mi tho",
        "fr": "Mon tho",
        "zh": "我的",
        "de": "Mein tho",
        "it": "Il mio tho",
        "id": "Tho saya",
        "ja": "私の",
        "th": "ของฉัน",
        "pt": "Meu tho"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.3606197,
        "lng": 106.3658439
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.2875451,
        10.3192782,
        106.4136628,
        10.4049673
      ]
    },
    {
      "id": 4083,
      "name": "Tumen",
      "names": {
        "zh": "图们"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9653277,
        "lng": 129.8365626
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        129.5342588,
        42.7764915,
        130.1249445,
        43.1709172
      ]
    },
    {
      "id": 4084,
      "name": "Ramos Mejia",
      "names": {
        "zh": "拉莫斯梅嘉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6481661,
        "lng": -58.5622869
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.5830787,
        -34.6730298,
        -58.5330159,
        -34.6346117
      ]
    },
    {
      "id": 4085,
      "name": "Al Hasakah",
      "names": {
        "zh": "alakah",
        "it": "Al hakakah",
        "ja": "アルハサカ",
        "th": "อัลฮาวากาห์"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.499527,
        "lng": 40.758408
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        39.506216,
        35.49767,
        42.376309,
        37.320569
      ]
    },
    {
      "id": 4086,
      "name": "Hathras",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5975888,
        "lng": 78.0545477
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.027112,
        27.5742162,
        78.0712614,
        27.622462
      ]
    },
    {
      "id": 4087,
      "name": "Guimba",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6605,
        "lng": 120.7683
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7483,
        15.6405,
        120.7883,
        15.6805
      ]
    },
    {
      "id": 4088,
      "name": "Miramar",
      "names": {
        "zh": "米拉马",
        "ja": "ミラマー",
        "th": "มิรามาร์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.1176342,
        "lng": -82.4311878
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -82.4511878,
        23.0976342,
        -82.4111878,
        23.1376342
      ]
    },
    {
      "id": 4089,
      "name": "La Granja",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5431234,
        "lng": -70.6332426
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.636605,
        -33.559786,
        -70.6097531,
        -33.5130507
      ]
    },
    {
      "id": 4090,
      "name": "Al Hajar al Aswad",
      "names": {
        "it": "Al Hajar Al Asswad"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.45759,
        "lng": 36.30419
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.28419,
        33.43759,
        36.32419,
        33.47759
      ]
    },
    {
      "id": 4091,
      "name": "Guasdualito",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 7.24241,
        "lng": -70.73236
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.75236,
        7.22241,
        -70.71236,
        7.26241
      ]
    },
    {
      "id": 4092,
      "name": "Domodedovo",
      "names": {
        "zh": "多莫杰多沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.4328887,
        "lng": 37.7655253
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.7654753,
        55.4328387,
        37.7655753,
        55.4329387
      ]
    },
    {
      "id": 4093,
      "name": "Shashemene",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.2007039,
        "lng": 38.5950783
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        38.4350783,
        7.0407039,
        38.7550783,
        7.3607039
      ]
    },
    {
      "id": 4094,
      "name": "Darnah",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.76704,
        "lng": 22.63669
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        22.61669,
        32.74704,
        22.65669,
        32.78704
      ]
    },
    {
      "id": 4095,
      "name": "Kadoma",
      "names": {
        "zh": "卡多马"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.728801,
        "lng": 135.59691
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5708576,
        34.710754,
        135.623929,
        34.7497829
      ]
    },
    {
      "id": 4096,
      "name": "Salmas",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.1969035,
        "lng": 44.7685015
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        44.7451009,
        38.1818245,
        44.7904475,
        38.2232397
      ]
    },
    {
      "id": 4097,
      "name": "Cubatao",
      "names": {
        "pt": "Cubatão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.8857982,
        "lng": -46.4241869
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.4960107,
        -23.9421757,
        -46.3186267,
        -23.7807501
      ]
    },
    {
      "id": 4098,
      "name": "Jazan",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 16.894719,
        "lng": 42.5579758
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        42.3979758,
        16.734719,
        42.7179758,
        17.054719
      ]
    },
    {
      "id": 4099,
      "name": "Marica",
      "names": {
        "zh": "马里卡",
        "th": "มาริก้า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9059188,
        "lng": -42.7926326
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.7983728,
        -22.912434,
        -42.781532,
        -22.8984639
      ]
    },
    {
      "id": 4100,
      "name": "Orebro",
      "names": {
        "zh": "厄勒布鲁",
        "th": "โอเรโบร"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.2747287,
        "lng": 15.2151181
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        15.0551181,
        59.1147287,
        15.3751181,
        59.4347287
      ]
    },
    {
      "id": 4101,
      "name": "Kaspiysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 42.8881414,
        "lng": 47.6424676
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        47.5561311,
        42.8436034,
        47.69768,
        42.9271683
      ]
    },
    {
      "id": 4102,
      "name": "Heilbronn",
      "names": {
        "zh": "海尔布隆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.142291,
        "lng": 9.218655
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.0446088,
        49.0928882,
        9.3019672,
        49.2097276
      ]
    },
    {
      "id": 4103,
      "name": "Neftekamsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0810005,
        "lng": 54.2571679
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        54.2571179,
        56.0809505,
        54.2572179,
        56.0810505
      ]
    },
    {
      "id": 4104,
      "name": "Omiyacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5299976,
        "lng": 139.6934161
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6917645,
        35.5271271,
        139.6958824,
        35.5317165
      ]
    },
    {
      "id": 4105,
      "name": "Johnson City",
      "names": {
        "zh": "约翰逊市",
        "ja": "ジョンソンシティ",
        "th": "จอห์นสันซิตี้",
        "pt": "Cidade de johnson"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3134398,
        "lng": -82.3534728
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.52183,
        36.259144,
        -82.292453,
        36.434556
      ]
    },
    {
      "id": 4106,
      "name": "Mediouna",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.456251,
        "lng": -7.51944
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.5265719,
        33.4396352,
        -7.5032903,
        33.479375
      ]
    },
    {
      "id": 4107,
      "name": "Ulm",
      "names": {
        "zh": "乌尔姆",
        "ja": "ウルム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3984968,
        "lng": 9.9912458
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.8424917,
        48.3066627,
        10.0428303,
        48.4685153
      ]
    },
    {
      "id": 4108,
      "name": "Bajos de Haina",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.41538,
        "lng": -70.03317
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.05317,
        18.39538,
        -70.01317,
        18.43538
      ]
    },
    {
      "id": 4109,
      "name": "Bam",
      "names": {
        "zh": "的BAM",
        "ja": "バム",
        "th": "แบม"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.1059864,
        "lng": 58.3565663
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.28783,
        29.0258786,
        58.3939767,
        29.1232462
      ]
    },
    {
      "id": 4110,
      "name": "Tay Ninh",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 11.3138286,
        "lng": 106.0964769
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.0715255,
        11.2922289,
        106.1915296,
        11.4388937
      ]
    },
    {
      "id": 4111,
      "name": "High Wycombe",
      "names": {
        "es": "Wycombe alto",
        "zh": "高威力",
        "it": "Alto wycombe",
        "id": "Wycombe tinggi",
        "ja": "高wycombe",
        "th": "wycombe สูง",
        "pt": "Alto Wycombe"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6293727,
        "lng": -0.7445773
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7495773,
        51.6243727,
        -0.7395773,
        51.6343727
      ]
    },
    {
      "id": 4112,
      "name": "Monza",
      "names": {
        "zh": "蒙扎",
        "ja": "モンツァ",
        "th": "มอนซา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5834418,
        "lng": 9.2735257
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.2270251,
        45.5532615,
        9.3243874,
        45.6321966
      ]
    },
    {
      "id": 4113,
      "name": "Pforzheim",
      "names": {
        "zh": "普福尔茨海姆",
        "ja": "プフォルツハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8937057,
        "lng": 8.7030769
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.7030269,
        48.8936557,
        8.7031269,
        48.8937557
      ]
    },
    {
      "id": 4114,
      "name": "Osaki",
      "names": {
        "zh": "大崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.5770889,
        "lng": 140.955454
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.538592,
        38.441376,
        141.161845,
        38.923841
      ]
    },
    {
      "id": 4115,
      "name": "Balneario de Camboriu",
      "names": {
        "it": "Balneario de Camboriuu",
        "pt": "Balneário de camboriu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9924395,
        "lng": -48.6339782
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.682923,
        -27.0611135,
        -48.5689884,
        -26.9541917
      ]
    },
    {
      "id": 4116,
      "name": "Santana de Parnaiba",
      "names": {
        "fr": "Santana de Parnaïba",
        "ja": "サンタナデパルニバ",
        "pt": "Santana de Parnaíba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.444168,
        "lng": -46.918616
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.037523,
        -23.508167,
        -46.800322,
        -23.384982
      ]
    },
    {
      "id": 4117,
      "name": "Nefteyugansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 61.097602,
        "lng": 72.598598
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        72.572639,
        61.082779,
        72.622002,
        61.108639
      ]
    },
    {
      "id": 4118,
      "name": "Payakumbuh",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.2242687,
        "lng": 100.6319419
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        100.5824073,
        -0.2784412,
        100.6856803,
        -0.1862389
      ]
    },
    {
      "id": 4119,
      "name": "Pakokku",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 21.3364176,
        "lng": 95.0896415
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.0895915,
        21.3363676,
        95.0896915,
        21.3364676
      ]
    },
    {
      "id": 4120,
      "name": "Angren",
      "names": {
        "zh": "安格连"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.01829945,
        "lng": 70.09787922207363
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        70.0048228,
        40.9754651,
        70.1661014,
        41.0601888
      ]
    },
    {
      "id": 4121,
      "name": "Basirhat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.6501854,
        "lng": 88.869347
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.864347,
        22.6451854,
        88.874347,
        22.6551854
      ]
    },
    {
      "id": 4122,
      "name": "Duitama",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8240613,
        "lng": -73.0322422
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0372422,
        5.8190613,
        -73.0272422,
        5.8290613
      ]
    },
    {
      "id": 4123,
      "name": "Leiria",
      "names": {
        "zh": "莱里亚",
        "th": "เลเรีย"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.7437902,
        "lng": -8.8071119
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.9681659,
        39.6368745,
        -8.6233353,
        39.9654264
      ]
    },
    {
      "id": 4124,
      "name": "Larache",
      "names": {
        "zh": "拉腊什"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.1952327,
        "lng": -6.152913
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.1873305,
        35.1385202,
        -6.0884005,
        35.2449225
      ]
    },
    {
      "id": 4125,
      "name": "Navadwip",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.405057,
        "lng": 88.360754
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.354664,
        23.387984,
        88.378002,
        23.429128
      ]
    },
    {
      "id": 4126,
      "name": "Latina",
      "names": {
        "zh": "拉丁美洲",
        "ja": "ラティーナ",
        "th": "ลาติ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.467299,
        "lng": 12.9037518
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.7196194,
        41.3837791,
        13.0246972,
        41.5617359
      ]
    },
    {
      "id": 4127,
      "name": "Cambridge",
      "names": {
        "zh": "剑桥",
        "ja": "ケンブリッジ",
        "th": "เคมบริดจ์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.2055314,
        "lng": 0.1186637
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.0686389,
        52.1579417,
        0.184552,
        52.2372296
      ]
    },
    {
      "id": 4128,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0283014,
        "lng": 120.6938038
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.5942962,
        14.9932982,
        120.7278114,
        15.1342393
      ]
    },
    {
      "id": 4129,
      "name": "Exeter",
      "names": {
        "zh": "埃克塞特",
        "ja": "エクセター",
        "th": "เอ็กซีเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7256138,
        "lng": -3.5269209
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.5702031,
        50.6731771,
        -3.451159,
        50.761465
      ]
    },
    {
      "id": 4130,
      "name": "Lynchburg",
      "names": {
        "zh": "林奇堡",
        "ja": "リンチバーグ",
        "th": "ลินช์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4064274,
        "lng": -79.1570872
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.1620872,
        37.4014274,
        -79.1520872,
        37.4114274
      ]
    },
    {
      "id": 4131,
      "name": "Pangkalpinang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.1206733,
        "lng": 106.1134604
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        106.0394394,
        -2.1609975,
        106.1822176,
        -2.0575008
      ]
    },
    {
      "id": 4132,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.050096,
        "lng": -121.99059
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.3220529,
        36.8488209,
        -121.5823066,
        37.2876789
      ]
    },
    {
      "id": 4133,
      "name": "Guntakal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.163811,
        "lng": 77.3761354
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.3361354,
        15.123811,
        77.4161354,
        15.203811
      ]
    },
    {
      "id": 4134,
      "name": "Iizuka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.646594,
        "lng": 130.6911579
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.5581383,
        33.503817,
        130.7761463,
        33.707015
      ]
    },
    {
      "id": 4135,
      "name": "Sabara",
      "names": {
        "zh": "萨巴拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.8900372,
        "lng": -43.8107916
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.9110667,
        -19.967,
        -43.6740407,
        -19.725
      ]
    },
    {
      "id": 4136,
      "name": "Catabola",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.142243,
        "lng": 17.2856051
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        17.2456051,
        -12.182243,
        17.3256051,
        -12.102243
      ]
    },
    {
      "id": 4137,
      "name": "Simi Valley",
      "names": {
        "fr": "Simi Vallée",
        "zh": "西米山谷",
        "de": "Simi-Tal",
        "ja": "シミ渓谷",
        "pt": "SIMI VALEY"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2677404,
        "lng": -118.7538071
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.8310988,
        34.2084152,
        -118.632558,
        34.3226657
      ]
    },
    {
      "id": 4138,
      "name": "Barbacena",
      "names": {
        "zh": "巴巴塞纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2254524,
        "lng": -43.7735087
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.028745,
        -21.376,
        -43.580504,
        -21.118272
      ]
    },
    {
      "id": 4139,
      "name": "Colchester",
      "names": {
        "zh": "科尔切斯特",
        "ja": "コルチェスター",
        "th": "โคลเชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8896903,
        "lng": 0.8994651
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.6993788,
        51.7657055,
        1.0268034,
        51.9771532
      ]
    },
    {
      "id": 4140,
      "name": "Halisahar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.93452,
        "lng": 88.419179
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.39859,
        22.91218,
        88.43859,
        22.95218
      ]
    },
    {
      "id": 4141,
      "name": "Matamoros",
      "names": {
        "zh": "马塔莫罗斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.529353,
        "lng": -103.233853
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.314651,
        25.501881,
        -103.211407,
        25.549714
      ]
    },
    {
      "id": 4142,
      "name": "Rishra",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.723937,
        "lng": 88.345633
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.345633,
        22.723937,
        88.345633,
        22.723937
      ]
    },
    {
      "id": 4143,
      "name": "Magway",
      "names": {
        "th": "มาเกว"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.1491458,
        "lng": 94.9213518
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        94.8884869,
        19.7791731,
        95.4141432,
        20.3616547
      ]
    },
    {
      "id": 4144,
      "name": "Malanje",
      "names": {
        "zh": "马兰热"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.54015,
        "lng": 16.34096
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        16.32096,
        -9.56015,
        16.36096,
        -9.52015
      ]
    },
    {
      "id": 4145,
      "name": "Magelang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.4704907,
        "lng": 110.2145487
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        110.1945487,
        -7.4904907,
        110.2345487,
        -7.4504907
      ]
    },
    {
      "id": 4146,
      "name": "Jizhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.5265857,
        "lng": 115.51462947673595
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.1685182,
        37.3103306,
        115.6858328,
        37.7413129
      ]
    },
    {
      "id": 4147,
      "name": "Kampong Cham",
      "names": {
        "ja": "カンポンチャム",
        "th": "กม่งจาม"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.9921855,
        "lng": 105.4628346
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        105.3028346,
        11.8321855,
        105.6228346,
        12.1521855
      ]
    },
    {
      "id": 4148,
      "name": "Gashua",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.8728546,
        "lng": 11.0387809
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        10.8787809,
        12.7128546,
        11.1987809,
        13.0328546
      ]
    },
    {
      "id": 4149,
      "name": "Zwolle",
      "names": {
        "zh": "兹沃勒"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.514565250000004,
        "lng": 6.097720359677693
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        6.0036191,
        52.4405307,
        6.2108108,
        52.5879404
      ]
    },
    {
      "id": 4150,
      "name": "Shchelkovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.90226,
        "lng": 38.016078
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.958019,
        55.863747,
        38.094498,
        55.942013
      ]
    },
    {
      "id": 4151,
      "name": "Novomoskovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.011013,
        "lng": 38.290943
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.1325773,
        53.9751505,
        38.4938216,
        54.1167868
      ]
    },
    {
      "id": 4152,
      "name": "Santa Lucia Cotzumalguapa",
      "names": {
        "es": "Santa Lucía Cotzumalguapa",
        "fr": "Santa Lucia Cotzumalalguapa",
        "zh": "Santa Lucia Cotzumal Guapa",
        "it": "Santa Lucia Cotzumalguapapapapa",
        "ja": "サンタルシアコッツマルグパ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.33333,
        "lng": -91.01667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.03667,
        14.31333,
        -90.99667,
        14.35333
      ]
    },
    {
      "id": 4153,
      "name": "Cam Ranh",
      "names": {
        "zh": "凸轮ranh",
        "it": "Camramo",
        "ja": "カムラン",
        "th": "ลูกเบี้ยว ranh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.9166696,
        "lng": 109.14861
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        109.0001893,
        11.7689549,
        109.2840537,
        12.030957
      ]
    },
    {
      "id": 4154,
      "name": "Kumbo",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 6.2108889,
        "lng": 10.6867788
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        10.6135887,
        6.1356305,
        10.8148254,
        6.2789451
      ]
    },
    {
      "id": 4155,
      "name": "Giugliano in Campania",
      "names": {
        "es": "Giugliano en Campania",
        "fr": "Giugliano en Campanie",
        "zh": "朱格利亚诺在坎帕尼亚",
        "de": "Giugliano in Kampanien",
        "id": "Giugliano di Campania",
        "ja": "カンパニアのGiugliano",
        "th": "Giugliano ใน Campania",
        "pt": "Giugliano em Campania"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.931049,
        "lng": 14.201541
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.160187,
        40.918182,
        14.222091,
        40.951283
      ]
    },
    {
      "id": 4156,
      "name": "Cagua",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.18634,
        "lng": -67.45935
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.47935,
        10.16634,
        -67.43935,
        10.20634
      ]
    },
    {
      "id": 4157,
      "name": "Jandira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5276045,
        "lng": -46.9023728
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.9073728,
        -23.5326045,
        -46.8973728,
        -23.5226045
      ]
    },
    {
      "id": 4158,
      "name": "Amatitlan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4792381,
        "lng": -90.6195248
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.6736036,
        14.3895769,
        -90.5474325,
        14.5299959
      ]
    },
    {
      "id": 4159,
      "name": "Gateshead",
      "names": {
        "zh": "盖茨黑德",
        "th": "เกทส์เฮด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9617336,
        "lng": -1.6030322
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.6030822,
        54.9616836,
        -1.6029822,
        54.9617836
      ]
    },
    {
      "id": 4160,
      "name": "Pakxe",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 15.12022,
        "lng": 105.79898
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        105.77898,
        15.10022,
        105.81898,
        15.14022
      ]
    },
    {
      "id": 4161,
      "name": "Aalborg",
      "names": {
        "zh": "奥尔堡",
        "ja": "オールボー"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 57.034773,
        "lng": 9.902536
      },
      "country": "Denmark",
      "importance": 1,
      "bbox": [
        9.853209,
        56.988589,
        10.038585,
        57.07041
      ]
    },
    {
      "id": 4162,
      "name": "Bonao",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.93687,
        "lng": -70.40923
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.42923,
        18.91687,
        -70.38923,
        18.95687
      ]
    },
    {
      "id": 4163,
      "name": "Honcho",
      "names": {
        "zh": "本町",
        "ja": "班長"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7002737,
        "lng": 139.5064494
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4964494,
        35.6902737,
        139.5164494,
        35.7102737
      ]
    },
    {
      "id": 4164,
      "name": "Amherst",
      "names": {
        "zh": "阿默斯特",
        "ja": "アマースト",
        "th": "แอมเฮิร์สต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9783924,
        "lng": -78.7997616
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.832498,
        42.945844,
        -78.696839,
        43.087077
      ]
    },
    {
      "id": 4165,
      "name": "Magalang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2140936,
        "lng": 120.6616332
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        120.6064081,
        15.1794119,
        120.7413255,
        15.2789229
      ]
    },
    {
      "id": 4166,
      "name": "Uruguaiana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.7560726,
        "lng": -57.0867546
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -57.329,
        -30.2050473,
        -56.1502443,
        -29.4001025
      ]
    },
    {
      "id": 4167,
      "name": "Porlamar",
      "names": {
        "zh": "波拉马尔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.9556,
        "lng": -63.8478
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -63.8678,
        10.9356,
        -63.8278,
        10.9756
      ]
    },
    {
      "id": 4168,
      "name": "Fort Smith",
      "names": {
        "zh": "史密斯堡",
        "ja": "フォートスミス",
        "th": "ฟอร์ตสมิ ธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3880338,
        "lng": -94.4265011
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.4355398,
        35.2588549,
        -94.2528595,
        35.4498153
      ]
    },
    {
      "id": 4169,
      "name": "Resende",
      "names": {
        "zh": "雷森迪"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4683626,
        "lng": -44.4463494
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.6788444,
        -22.640947,
        -44.3187588,
        -22.4600298
      ]
    },
    {
      "id": 4170,
      "name": "Daitocho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7154592,
        "lng": 135.6211563
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6211063,
        34.7154092,
        135.6212063,
        34.7155092
      ]
    },
    {
      "id": 4171,
      "name": "Leon",
      "names": {
        "es": "León",
        "zh": "莱昂",
        "ja": "レオン",
        "th": "ลีออง"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.5989995,
        "lng": -5.5682413
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -5.6308026,
        42.5469341,
        -5.5337802,
        42.6403532
      ]
    },
    {
      "id": 4172,
      "name": "Jirja",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.33826,
        "lng": 31.89161
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.87161,
        26.31826,
        31.91161,
        26.35826
      ]
    },
    {
      "id": 4173,
      "name": "Fugu",
      "names": {
        "zh": "东方",
        "ja": "ふぐ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.031227,
        "lng": 111.0581068
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.8981068,
        38.871227,
        111.2181068,
        39.191227
      ]
    },
    {
      "id": 4174,
      "name": "Kenosha",
      "names": {
        "zh": "基诺沙"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.58362,
        "lng": -87.844117
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.996963,
        42.538867,
        -87.807407,
        42.639433
      ]
    },
    {
      "id": 4175,
      "name": "Ouahigouya",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.580982,
        "lng": -2.417699
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -2.577699,
        13.420982,
        -2.257699,
        13.740982
      ]
    },
    {
      "id": 4176,
      "name": "Duma",
      "names": {
        "fr": "Douma",
        "zh": "杜马",
        "ja": "下院",
        "th": "ดูมา"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.5696629,
        "lng": 36.4012933
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.3612933,
        33.5296629,
        36.4412933,
        33.6096629
      ]
    },
    {
      "id": 4177,
      "name": "Norman",
      "names": {
        "zh": "诺曼",
        "ja": "ノーマン",
        "th": "นอร์แมน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2225717,
        "lng": -97.4394816
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.5474454,
        35.145318,
        -97.176918,
        35.348324
      ]
    },
    {
      "id": 4178,
      "name": "Zhaxi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.85081,
        "lng": 104.96874
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.94874,
        27.83081,
        104.98874,
        27.87081
      ]
    },
    {
      "id": 4179,
      "name": "Villa de Alvarez",
      "names": {
        "ja": "ヴィラデアルバレス",
        "th": "วิลลาเดออัลวาเรซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.25,
        "lng": -103.73333
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.75333,
        19.23,
        -103.71333,
        19.27
      ]
    },
    {
      "id": 4180,
      "name": "Setubal",
      "names": {
        "es": "Setúbal",
        "zh": "塞图巴尔",
        "th": "เซตูบัล",
        "pt": "Setúbal"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.5241783,
        "lng": -8.8932341
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -9.0556241,
        38.4539604,
        -8.7313913,
        38.5807211
      ]
    },
    {
      "id": 4181,
      "name": "Zahle",
      "names": {
        "zh": "查赫勒"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.8477069,
        "lng": 35.9024955
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        35.7424955,
        33.6877069,
        36.0624955,
        34.0077069
      ]
    },
    {
      "id": 4182,
      "name": "Gexianzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.06936,
        "lng": 115.6618
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4183,
      "name": "Gorzow Wielkopolski",
      "names": {
        "th": "กอริโทาวส์วิลล์สกี้"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7309926,
        "lng": 15.2400451
      },
      "country": "Poland",
      "importance": 1,
      "bbox": [
        15.1233937,
        52.6841629,
        15.3109143,
        52.7769494
      ]
    },
    {
      "id": 4184,
      "name": "Wolfsburg",
      "names": {
        "zh": "沃尔夫斯堡",
        "ja": "ヴォルフスブルク",
        "th": "โวล์ฟสบวร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4205588,
        "lng": 10.7861682
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.6471143,
        52.3159344,
        10.9068413,
        52.495086
      ]
    },
    {
      "id": 4185,
      "name": "Savannakhet",
      "names": {
        "zh": "沙湾拿吉"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 16.5636044,
        "lng": 104.7509856
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        104.5909856,
        16.4036044,
        104.9109856,
        16.7236044
      ]
    },
    {
      "id": 4186,
      "name": "South Lyon",
      "names": {
        "fr": "Lyon du Sud",
        "zh": "南里昂",
        "de": "Südlyon",
        "it": "South Lione",
        "id": "Lyon Selatan",
        "ja": "サウスリヨン",
        "pt": "Lyon sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.464679,
        "lng": -83.649747
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.667685,
        42.431179,
        -83.635775,
        42.48421
      ]
    },
    {
      "id": 4187,
      "name": "Gyumri",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.7852085,
        "lng": 43.8416095
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        43.7833714,
        40.7283418,
        43.8836695,
        40.8414926
      ]
    },
    {
      "id": 4188,
      "name": "Pervouralsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.90528,
        "lng": 59.94361
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        59.92361,
        56.88528,
        59.96361,
        56.92528
      ]
    },
    {
      "id": 4189,
      "name": "New Mirpur",
      "names": {
        "es": "Nuevo Mirpur",
        "fr": "Nouveau mirpur",
        "zh": "新的mirpur",
        "de": "Neue Mirpur",
        "it": "Nuova Mirpur",
        "id": "Mirpur Baru",
        "ja": "新しいミルプル",
        "th": "ใหม่ Mirpur",
        "pt": "Novo mirpur"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.1486351,
        "lng": 73.7482102
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.5882102,
        32.9886351,
        73.9082102,
        33.3086351
      ]
    },
    {
      "id": 4190,
      "name": "Brits",
      "names": {
        "es": "Los británicos",
        "zh": "布里茨",
        "de": "Briten",
        "it": "Inglesi"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.6297222,
        "lng": 27.7841667
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.7441667,
        -25.6697222,
        27.8241667,
        -25.5897222
      ]
    },
    {
      "id": 4191,
      "name": "Noksan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.16921,
        "lng": 127.23687
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.21687,
        36.14921,
        127.25687,
        36.18921
      ]
    },
    {
      "id": 4192,
      "name": "San Pedro Garza Garcia",
      "names": {
        "zh": "圣佩德罗加拉加加西亚",
        "ja": "サンペドロガルザガルシア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.65271255,
        "lng": -100.37223790945575
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.4274519,
        25.5897925,
        -100.312568,
        25.7156789
      ]
    },
    {
      "id": 4193,
      "name": "Algeciras",
      "names": {
        "zh": "阿尔赫西拉斯",
        "ja": "アルヘシラス"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.1266399,
        "lng": -5.4501752
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.4551752,
        36.1216399,
        -5.4451752,
        36.1316399
      ]
    },
    {
      "id": 4194,
      "name": "Boulder",
      "names": {
        "zh": "博尔德",
        "ja": "ボルダー",
        "th": "โบลเดอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0149856,
        "lng": -105.270545
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.3014509,
        39.9569362,
        -105.1780988,
        40.0944658
      ]
    },
    {
      "id": 4195,
      "name": "Bimbo",
      "names": {
        "zh": "宾博",
        "ja": "やりまん",
        "th": "สมอง"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 4.3228138,
        "lng": 18.5248788
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        18.3648788,
        4.1628138,
        18.6848788,
        4.4828138
      ]
    },
    {
      "id": 4196,
      "name": "Baia Mare",
      "names": {
        "fr": "Baie mare",
        "ja": "バイアマーレ",
        "pt": "Baia égua"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.654209,
        "lng": 23.575996
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        23.49816,
        47.629618,
        23.642877,
        47.719945
      ]
    },
    {
      "id": 4197,
      "name": "Matsubara",
      "names": {
        "zh": "松原",
        "ja": "松原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.580779,
        "lng": 135.546552
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.521,
        34.5538126,
        135.585756,
        34.599448
      ]
    },
    {
      "id": 4198,
      "name": "Khrustalnyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1351471,
        "lng": 38.9198948
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        38.8648657,
        48.0720574,
        38.9750702,
        48.191142
      ]
    },
    {
      "id": 4199,
      "name": "Cherkessk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.22538305,
        "lng": 42.06819662981461
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        42.0092645,
        44.1608956,
        42.1232616,
        44.2895068
      ]
    },
    {
      "id": 4200,
      "name": "Indramayu",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.326433,
        "lng": 108.3229169
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        108.2829169,
        -6.366433,
        108.3629169,
        -6.286433
      ]
    },
    {
      "id": 4201,
      "name": "Rudnyy",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 52.964458,
        "lng": 63.133488
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        62.8103939,
        52.8572252,
        63.3314379,
        53.4249295
      ]
    },
    {
      "id": 4202,
      "name": "Anderlecht",
      "names": {
        "zh": "安德莱赫特",
        "th": "อันเดอร์เลชท์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8390983,
        "lng": 4.3296526
      },
      "country": "Belgium",
      "importance": 3,
      "bbox": [
        4.2437658,
        50.8070598,
        4.3401128,
        50.850549
      ]
    },
    {
      "id": 4203,
      "name": "Bergamo",
      "names": {
        "es": "Bérgamo",
        "fr": "Bergame",
        "zh": "贝加莫",
        "ja": "ベルガモ",
        "th": "แบร์กาโม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6944947,
        "lng": 9.6698727
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.6193564,
        45.6554235,
        9.7137063,
        45.7314001
      ]
    },
    {
      "id": 4204,
      "name": "Kirishima",
      "names": {
        "zh": "雾岛",
        "ja": "霧島"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.7410148,
        "lng": 130.7632406
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.5894591,
        31.60476,
        130.914085,
        31.942754
      ]
    },
    {
      "id": 4205,
      "name": "Gapan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3074471,
        "lng": 120.945596
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.945546,
        15.3073971,
        120.945646,
        15.3074971
      ]
    },
    {
      "id": 4206,
      "name": "Magangue",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2412097,
        "lng": -74.7567413
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9476687,
        8.8192442,
        -74.4591522,
        9.4656588
      ]
    },
    {
      "id": 4207,
      "name": "Maicao",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.3779233,
        "lng": -72.2388947
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.2788947,
        11.3379233,
        -72.1988947,
        11.4179233
      ]
    },
    {
      "id": 4208,
      "name": "Delgado",
      "names": {
        "zh": "德尔加多",
        "th": "เดลกาโด"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7126863,
        "lng": -89.17374
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.19374,
        13.6926863,
        -89.15374,
        13.7326863
      ]
    },
    {
      "id": 4209,
      "name": "Uruma",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.374953,
        "lng": 127.851118
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.821395,
        26.337623,
        127.863416,
        26.425498
      ]
    },
    {
      "id": 4210,
      "name": "Hoi An",
      "names": {
        "fr": "Hoi un",
        "zh": "海安",
        "ja": "ホイアン",
        "th": "ฮอยอัน"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 15.8795863,
        "lng": 108.3319406
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        108.2895156,
        15.85538,
        108.5430872,
        15.9802721
      ]
    },
    {
      "id": 4211,
      "name": "Derbent",
      "names": {
        "zh": "杰尔宾特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 42.057858,
        "lng": 48.2887648
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        48.2238688,
        42.0082233,
        48.337239,
        42.1362917
      ]
    },
    {
      "id": 4212,
      "name": "Melipilla",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.7129672,
        "lng": -71.2129712
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.2129712,
        -33.7157193,
        -71.2109716,
        -33.7129672
      ]
    },
    {
      "id": 4213,
      "name": "Cadiz",
      "names": {
        "es": "Cádiz",
        "fr": "Cadix",
        "zh": "加的斯",
        "it": "Cadice",
        "ja": "カディス",
        "th": "กาดิซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.5297438,
        "lng": -6.2928976
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -6.3175626,
        36.4439926,
        -6.2250607,
        36.5454594
      ]
    },
    {
      "id": 4214,
      "name": "Pati",
      "names": {
        "zh": "帕蒂",
        "ja": "パチ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.7534144,
        "lng": 111.0399982
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        110.8799982,
        -6.9134144,
        111.1999982,
        -6.5934144
      ]
    },
    {
      "id": 4215,
      "name": "Maastricht",
      "names": {
        "zh": "马斯特里赫特",
        "ja": "マーストリヒト",
        "th": "มาสทริชต์"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8512438,
        "lng": 5.6909768
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        5.6388661,
        50.8038053,
        5.7629249,
        50.9120028
      ]
    },
    {
      "id": 4216,
      "name": "Longtian",
      "names": {
        "zh": "龙田"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.219481,
        "lng": 115.707099
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.667099,
        24.179481,
        115.747099,
        24.259481
      ]
    },
    {
      "id": 4217,
      "name": "Munuf",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.46597,
        "lng": 30.93199
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4218,
      "name": "Gagnoa",
      "names": {
        "zh": "加尼奥阿"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.13193,
        "lng": -5.9506
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -5.9706,
        6.11193,
        -5.9306,
        6.15193
      ]
    },
    {
      "id": 4219,
      "name": "Abilene",
      "names": {
        "zh": "阿比林",
        "ja": "アビリーン",
        "th": "ลีน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.44645,
        "lng": -99.7475905
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -99.8675851,
        32.3431879,
        -99.623084,
        32.624481
      ]
    },
    {
      "id": 4220,
      "name": "Punta Arenas",
      "names": {
        "fr": "Arènes de punta",
        "zh": "蓬塔阿雷纳斯",
        "id": "Arena Punta",
        "ja": "プンタアリーナ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -53.1625688,
        "lng": -70.907822
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -74.7493216,
        -54.6577429,
        -70.1469755,
        -52.6154963
      ]
    },
    {
      "id": 4221,
      "name": "Varginha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.5565914,
        "lng": -45.4340674
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.5497069,
        -21.685,
        -45.250304,
        -21.4593162
      ]
    },
    {
      "id": 4222,
      "name": "Lehigh Acres",
      "names": {
        "fr": "Lehigh hecres",
        "zh": "Lehigh亩",
        "ja": "エーカーをリーフします"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.6091509,
        "lng": -81.652407
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.692407,
        26.5691509,
        -81.612407,
        26.6491509
      ]
    },
    {
      "id": 4223,
      "name": "Dabrowa Gornicza",
      "names": {
        "pt": "Dabrwa gornicza"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.330374,
        "lng": 19.1848234
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.1798234,
        50.325374,
        19.1898234,
        50.335374
      ]
    },
    {
      "id": 4224,
      "name": "Munch'on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.2370094,
        "lng": 127.3598714
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        127.1379089,
        39.1524443,
        127.493528,
        39.3829072
      ]
    },
    {
      "id": 4225,
      "name": "Zacatecas",
      "names": {
        "zh": "萨卡特卡斯",
        "th": "ซากาเตกัส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.7753891,
        "lng": -102.5722706
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -102.7322706,
        22.6153891,
        -102.4122706,
        22.9353891
      ]
    },
    {
      "id": 4226,
      "name": "Kaya",
      "names": {
        "zh": "卡亚",
        "ja": "カヤ",
        "th": "ยะ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 13.085603,
        "lng": -1.0839593
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -1.2439593,
        12.925603,
        -0.9239593,
        13.245603
      ]
    },
    {
      "id": 4227,
      "name": "Alberton",
      "names": {
        "zh": "艾伯顿"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.2529561,
        "lng": 28.1270777
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        28.0870777,
        -26.2929561,
        28.1670777,
        -26.2129561
      ]
    },
    {
      "id": 4228,
      "name": "Montreuil",
      "names": {
        "zh": "蒙特勒伊"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8623357,
        "lng": 2.4412184
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.4152827,
        48.8487433,
        2.4828111,
        48.8787474
      ]
    },
    {
      "id": 4229,
      "name": "Ise",
      "names": {
        "zh": "伊势",
        "ja": "伊勢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4921628,
        "lng": 136.7109887
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.7109387,
        34.4921128,
        136.7110387,
        34.4922128
      ]
    },
    {
      "id": 4230,
      "name": "Lianhe",
      "names": {
        "zh": "联合"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.4869002,
        "lng": 124.1765066
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        124.1365066,
        45.4469002,
        124.2165066,
        45.5269002
      ]
    },
    {
      "id": 4231,
      "name": "Braganca",
      "names": {
        "es": "Bragança",
        "fr": "Bragança",
        "zh": "布拉干萨",
        "de": "Bragança",
        "pt": "Bragança"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.0574255,
        "lng": -46.7635232
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0262292,
        -1.5803202,
        -46.5484646,
        -0.782301
      ]
    },
    {
      "id": 4232,
      "name": "Bayawan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.3649,
        "lng": 122.804
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.784,
        9.3449,
        122.824,
        9.3849
      ]
    },
    {
      "id": 4233,
      "name": "Barretos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.5531437,
        "lng": -48.5697509
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.894,
        -20.7424672,
        -48.3447262,
        -20.293
      ]
    },
    {
      "id": 4234,
      "name": "Kahta",
      "names": {
        "zh": "卡赫塔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7860716,
        "lng": 38.6217178
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.4512663,
        37.5969314,
        38.9645243,
        37.9927807
      ]
    },
    {
      "id": 4235,
      "name": "Las Margaritas",
      "names": {
        "zh": "拉斯玛尔加里塔斯",
        "id": "Las Margarita",
        "ja": "ラスマーガリータス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3117811,
        "lng": -91.9817243
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.1827202,
        16.0738467,
        -91.2964973,
        16.6740331
      ]
    },
    {
      "id": 4236,
      "name": "Blackburn",
      "names": {
        "zh": "布莱克本",
        "ja": "ブラックバーン",
        "th": "แบล็คเบิร์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.749326,
        "lng": -2.4840566
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.5240566,
        53.709326,
        -2.4440566,
        53.789326
      ]
    },
    {
      "id": 4237,
      "name": "Machiques",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0596326,
        "lng": -72.5463146
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -72.7063146,
        9.8996326,
        -72.3863146,
        10.2196326
      ]
    },
    {
      "id": 4238,
      "name": "Ciudad Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6919174,
        "lng": -100.555689
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.595689,
        19.6519174,
        -100.515689,
        19.7319174
      ]
    },
    {
      "id": 4239,
      "name": "Slough",
      "names": {
        "zh": "斯劳",
        "ja": "レディング",
        "th": "คราบ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.512103,
        "lng": -0.591276
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.596276,
        51.507103,
        -0.586276,
        51.517103
      ]
    },
    {
      "id": 4240,
      "name": "Jalapa",
      "names": {
        "zh": "哈拉帕",
        "th": "ฮลาปา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6344164,
        "lng": -89.9897996
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.1934488,
        14.4298783,
        -89.9213256,
        14.7981783
      ]
    },
    {
      "id": 4241,
      "name": "Pescara",
      "names": {
        "zh": "佩斯卡拉",
        "ja": "ペスカーラ",
        "th": "เปสการา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.469613,
        "lng": 14.2059269
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.1527721,
        42.4164624,
        14.2538298,
        42.4945604
      ]
    },
    {
      "id": 4242,
      "name": "Guelma",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.3491635,
        "lng": 7.409498952760461
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.939499,
        36.0244092,
        7.959225,
        36.673596
      ]
    },
    {
      "id": 4243,
      "name": "Behbahan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.5959,
        "lng": 50.2417
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.2217,
        30.5759,
        50.2617,
        30.6159
      ]
    },
    {
      "id": 4244,
      "name": "Baidyabati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.787632,
        "lng": 88.322982
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.30586,
        22.76498,
        88.34586,
        22.80498
      ]
    },
    {
      "id": 4245,
      "name": "Kotamobagu",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 0.7352231,
        "lng": 124.3154057
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        124.2524028,
        0.6479282,
        124.3795278,
        0.7745633
      ]
    },
    {
      "id": 4246,
      "name": "Xiangcheng",
      "names": {
        "zh": "襄城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9333862,
        "lng": 99.801197
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.641197,
        28.7733862,
        99.961197,
        29.0933862
      ]
    },
    {
      "id": 4247,
      "name": "Pearland",
      "names": {
        "zh": "皮尔兰",
        "th": "เพียร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5639758,
        "lng": -95.2864299
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.4444682,
        29.4979389,
        -95.2114282,
        29.598335
      ]
    },
    {
      "id": 4248,
      "name": "Mufulira",
      "names": {
        "zh": "穆富利拉"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.5394953,
        "lng": 28.2572655
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        28.2522655,
        -12.5444953,
        28.2622655,
        -12.5344953
      ]
    },
    {
      "id": 4249,
      "name": "Maina",
      "names": {
        "zh": "马伊纳"
      },
      "countryCode": "GU",
      "latLng": {
        "lat": 13.4673973,
        "lng": 144.73625731203856
      },
      "country": "Guam",
      "importance": 3,
      "bbox": [
        144.721289,
        13.455682,
        144.7411621,
        13.479136
      ]
    },
    {
      "id": 4250,
      "name": "Dharmavaram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4138405,
        "lng": 77.7208432
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.5608432,
        14.2538405,
        77.8808432,
        14.5738405
      ]
    },
    {
      "id": 4251,
      "name": "Edea",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 3.8,
        "lng": 10.13333
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        10.11333,
        3.78,
        10.15333,
        3.82
      ]
    },
    {
      "id": 4252,
      "name": "Honmachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6827731,
        "lng": 135.4968993
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.4918993,
        34.6777731,
        135.5018993,
        34.6877731
      ]
    },
    {
      "id": 4253,
      "name": "Khenifra",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.9357718,
        "lng": -5.6696504
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.6885456,
        32.9085379,
        -5.6401671,
        32.9633633
      ]
    },
    {
      "id": 4254,
      "name": "Ciudad Ojeda",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2004123,
        "lng": -71.3100065
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -71.4700065,
        10.0404123,
        -71.1500065,
        10.3604123
      ]
    },
    {
      "id": 4255,
      "name": "Fier",
      "names": {
        "ja": "フィエル"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 40.72389,
        "lng": 19.55611
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        19.53611,
        40.70389,
        19.57611,
        40.74389
      ]
    },
    {
      "id": 4256,
      "name": "Kamalia",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.7269883,
        "lng": 72.6450218
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.4850218,
        30.5669883,
        72.8050218,
        30.8869883
      ]
    },
    {
      "id": 4257,
      "name": "Ghazipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.5874162,
        "lng": 83.5903956
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.4303956,
        25.4274162,
        83.7503956,
        25.7474162
      ]
    },
    {
      "id": 4258,
      "name": "Orekhovo-Zuyevo",
      "names": {
        "zh": "奥列霍沃-祖耶沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.79543185,
        "lng": 38.97520500864094
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.9747622,
        55.7950534,
        38.9757147,
        55.7958159
      ]
    },
    {
      "id": 4259,
      "name": "Hoa Binh",
      "names": {
        "ja": "ホアビン"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.6763365,
        "lng": 105.3759952
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        104.8364751,
        20.3053594,
        105.8571482,
        21.1127685
      ]
    },
    {
      "id": 4260,
      "name": "Chinandega",
      "names": {
        "zh": "奇南德加"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.624183550000001,
        "lng": -87.12700655554353
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -87.1486442,
        12.6006859,
        -87.1108036,
        12.6469037
      ]
    },
    {
      "id": 4261,
      "name": "Puruliya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.328721,
        "lng": 86.367221
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.344701,
        23.305496,
        86.384177,
        23.349952
      ]
    },
    {
      "id": 4262,
      "name": "Kashiwara",
      "names": {
        "zh": "柏原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.59077,
        "lng": 135.635035
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6075122,
        34.54699,
        135.681985,
        34.60681
      ]
    },
    {
      "id": 4263,
      "name": "Urasoe",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.251335,
        "lng": 127.718897
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.698897,
        26.231335,
        127.738897,
        26.271335
      ]
    },
    {
      "id": 4264,
      "name": "Dorud",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.49218,
        "lng": 49.0616
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.0416,
        33.47218,
        49.0816,
        33.51218
      ]
    },
    {
      "id": 4265,
      "name": "Guarapari",
      "names": {
        "zh": "瓜拉帕里"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.6718755,
        "lng": -40.4984617
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.7023027,
        -20.7665925,
        -40.3637621,
        -20.458993
      ]
    },
    {
      "id": 4266,
      "name": "Ribeirao Pires",
      "names": {
        "es": "PIRES RIBEIRAO",
        "fr": "Preirao pre",
        "id": "Pires Ribeirao",
        "th": "ริเบเลรา pires",
        "pt": "Ribeirão Pires"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7129388,
        "lng": -46.4150871
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.476202,
        -23.7707404,
        -46.3307055,
        -23.6180718
      ]
    },
    {
      "id": 4267,
      "name": "Ondjiva",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -17.06667,
        "lng": 15.73333
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        15.71333,
        -17.08667,
        15.75333,
        -17.04667
      ]
    },
    {
      "id": 4268,
      "name": "Port-de-Paix",
      "names": {
        "zh": "和平港",
        "id": "Port de paix",
        "ja": "ポート・ド・ペ",
        "th": "พอร์ตเดอ Paix"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.9389,
        "lng": -72.83257
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -72.85257,
        19.9189,
        -72.81257,
        19.9589
      ]
    },
    {
      "id": 4269,
      "name": "Shuixi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.5859904,
        "lng": 110.585096
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.565096,
        24.5659904,
        110.605096,
        24.6059904
      ]
    },
    {
      "id": 4270,
      "name": "Wuling",
      "names": {
        "zh": "五菱"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.4421046,
        "lng": 114.2300814
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.1900814,
        39.4021046,
        114.2700814,
        39.4821046
      ]
    },
    {
      "id": 4271,
      "name": "Tsuruoka",
      "names": {
        "zh": "鹤冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.7272187,
        "lng": 139.8266292
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.1801276,
        38.316778,
        140.030097,
        38.9278277
      ]
    },
    {
      "id": 4272,
      "name": "Upington",
      "names": {
        "zh": "阿平顿"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -28.456325,
        "lng": 21.241867
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        21.081867,
        -28.616325,
        21.401867,
        -28.296325
      ]
    },
    {
      "id": 4273,
      "name": "Berkeley",
      "names": {
        "zh": "大学伯克利分校",
        "ja": "バークレー",
        "th": "เบิร์กลีย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.8708393,
        "lng": -122.272863
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.3686793,
        37.835727,
        -122.2341962,
        37.9066896
      ]
    },
    {
      "id": 4274,
      "name": "Plock",
      "names": {
        "zh": "普沃茨克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.5464521,
        "lng": 19.7008606
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.6240821,
        52.4737428,
        19.8058573,
        52.5970876
      ]
    },
    {
      "id": 4275,
      "name": "Richardson",
      "names": {
        "zh": "·理查森",
        "ja": "リチャードソン",
        "th": "ริชาร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9481789,
        "lng": -96.7297206
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.7691418,
        32.9232349,
        -96.6130209,
        33.005046
      ]
    },
    {
      "id": 4276,
      "name": "Redding",
      "names": {
        "zh": "雷丁",
        "ja": "レディング",
        "th": "เรดดิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5863563,
        "lng": -122.391675
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.453864,
        40.468919,
        -122.273591,
        40.679498
      ]
    },
    {
      "id": 4277,
      "name": "Arvada",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 39.8005505,
        "lng": -105.0811573
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.3019196,
        39.7843843,
        -105.042313,
        39.8930153
      ]
    },
    {
      "id": 4278,
      "name": "Tabuk",
      "names": {
        "zh": "塔布克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.4136195,
        "lng": 121.4440137
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2840137,
        17.2536195,
        121.6040137,
        17.5736195
      ]
    },
    {
      "id": 4279,
      "name": "Apartado",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.886982,
        "lng": -76.6357
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6557,
        7.866982,
        -76.6157,
        7.906982
      ]
    },
    {
      "id": 4280,
      "name": "East Los Angeles",
      "names": {
        "es": "Este de los angeles",
        "fr": "Est Los Angeles",
        "zh": "东洛杉矶",
        "id": "Los Angeles Timur",
        "ja": "イーストロサンゼルス",
        "th": "ตะวันออกลอสแองเจลิส",
        "pt": "Leste los angeles"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.02963,
        "lng": -118.168648
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.192625,
        34.008047,
        -118.126646,
        34.062299
      ]
    },
    {
      "id": 4281,
      "name": "Siracusa",
      "names": {
        "zh": "锡拉库扎",
        "ja": "シラクーサ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.0646139,
        "lng": 15.2907196
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        15.0829726,
        36.9477054,
        15.3367251,
        37.1156703
      ]
    },
    {
      "id": 4282,
      "name": "Saint-Denis",
      "names": {
        "zh": "圣 - 丹尼斯",
        "ja": "サン・デニス",
        "th": "เซนต์เดนิส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.935773,
        "lng": 2.3580232
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.3332192,
        48.9014932,
        2.3981181,
        48.9521346
      ]
    },
    {
      "id": 4283,
      "name": "Apucarana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5525327,
        "lng": -51.4610764
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.6328967,
        -23.7371954,
        -51.21407,
        -23.4179633
      ]
    },
    {
      "id": 4284,
      "name": "Valinhos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.97056,
        "lng": -46.99583
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0752895,
        -23.0564554,
        -46.8745272,
        -22.9030226
      ]
    },
    {
      "id": 4285,
      "name": "Chilapa de Alvarez",
      "names": {
        "ja": "チラパデアルバレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5939304,
        "lng": -99.17766
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.21766,
        17.5539304,
        -99.13766,
        17.6339304
      ]
    },
    {
      "id": 4286,
      "name": "Cachoeirinha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.9493353,
        "lng": -51.0898927
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.1273519,
        -29.9723794,
        -51.0629717,
        -29.8652416
      ]
    },
    {
      "id": 4287,
      "name": "Guelmim",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 28.9863852,
        "lng": -10.0574351
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -10.1136012,
        28.9493493,
        -10.0247911,
        29.0414134
      ]
    },
    {
      "id": 4288,
      "name": "Navojoa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 27.0721872,
        "lng": -109.4438227
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.6038227,
        26.9121872,
        -109.2838227,
        27.2321872
      ]
    },
    {
      "id": 4289,
      "name": "Perpignan",
      "names": {
        "zh": "佩皮尼昂",
        "ja": "ペルピニャン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.6985304,
        "lng": 2.8953121
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.8265889,
        42.6492606,
        2.9825668,
        42.7488722
      ]
    },
    {
      "id": 4290,
      "name": "Kot Addu",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.4692602,
        "lng": 70.9678776
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        70.8078776,
        30.3092602,
        71.1278776,
        30.6292602
      ]
    },
    {
      "id": 4291,
      "name": "St. George",
      "names": {
        "es": "San Jorge",
        "zh": "圣乔治",
        "ja": "セントジョージ",
        "th": "เซนต์จอร์จ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.104153,
        "lng": -113.584131
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -113.6497557,
        36.999974,
        -113.4768071,
        37.2095922
      ]
    },
    {
      "id": 4292,
      "name": "Gottingen",
      "names": {
        "es": "Göttingen",
        "fr": "Göttingen",
        "zh": "哥廷根",
        "de": "Göttingen",
        "it": "Gottinga",
        "id": "Göttingen",
        "ja": "ゲッティンゲン",
        "pt": "Göttingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5328328,
        "lng": 9.9351811
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.8001329,
        51.4830052,
        10.0536032,
        51.5908201
      ]
    },
    {
      "id": 4293,
      "name": "Billings",
      "names": {
        "zh": "比林斯",
        "ja": "ビリングズ",
        "th": "บิลลิงส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.7874957,
        "lng": -108.49607
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -108.691519,
        45.710673,
        -108.435916,
        45.852653
      ]
    },
    {
      "id": 4294,
      "name": "Catchiungo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.56667,
        "lng": 16.23333
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        16.21333,
        -12.58667,
        16.25333,
        -12.54667
      ]
    },
    {
      "id": 4295,
      "name": "Barcelos",
      "names": {
        "zh": "巴塞罗斯",
        "id": "Barbelos"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5324679,
        "lng": -8.618254567692027
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6257683,
        41.5261747,
        -8.6061159,
        41.5387611
      ]
    },
    {
      "id": 4296,
      "name": "Kallithea",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9560111,
        "lng": 23.7033792
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.6833792,
        37.9360111,
        23.7233792,
        37.9760111
      ]
    },
    {
      "id": 4297,
      "name": "Tizayuca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.83478,
        "lng": -98.975928
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.997661,
        19.813048,
        -98.954638,
        19.861391
      ]
    },
    {
      "id": 4298,
      "name": "Darjeeling",
      "names": {
        "zh": "大吉岭",
        "ja": "ダージリン",
        "th": "ดาร์จีลิง"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.0377554,
        "lng": 88.263176
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.103176,
        26.8777554,
        88.423176,
        27.1977554
      ]
    },
    {
      "id": 4299,
      "name": "Mohammad Shahr",
      "names": {
        "zh": "穆罕默德沙河",
        "th": "โมฮัมหมัดชาห์ร์",
        "pt": "Mahammad shahr"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7527246,
        "lng": 50.9039864
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        50.8712573,
        35.7228485,
        50.9387443,
        35.7733112
      ]
    },
    {
      "id": 4300,
      "name": "Calumpit",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.91639,
        "lng": 120.76583
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.74583,
        14.89639,
        120.78583,
        14.93639
      ]
    },
    {
      "id": 4301,
      "name": "Cuautitlan",
      "names": {
        "es": "Cuautitlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6730302,
        "lng": -99.1693207
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2093207,
        19.6330302,
        -99.1293207,
        19.7130302
      ]
    },
    {
      "id": 4302,
      "name": "Baisha",
      "names": {
        "zh": "白沙"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.166165,
        "lng": 112.6742068
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.6342068,
        26.126165,
        112.7142068,
        26.206165
      ]
    },
    {
      "id": 4303,
      "name": "Orleans",
      "names": {
        "fr": "-Orléans",
        "zh": "新奥尔良",
        "ja": "オーリンズ",
        "th": "ออร์ลีนส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.9027336,
        "lng": 1.9086066
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        1.8757578,
        47.8132802,
        1.9487114,
        47.9335581
      ]
    },
    {
      "id": 4304,
      "name": "Ebetsu",
      "names": {
        "ja": "江別市"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.1037358,
        "lng": 141.535894
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.444392,
        43.016069,
        141.668804,
        43.172715
      ]
    },
    {
      "id": 4305,
      "name": "Yuba City",
      "names": {
        "es": "Ciudad de Yuba",
        "zh": "yuba城",
        "de": "Yuba-Stadt",
        "id": "Kota Yuba",
        "ja": "ユバ市",
        "th": "เมืองยูบะ",
        "pt": "Cidade de yuba"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1404477,
        "lng": -121.6169108
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.688874,
        39.087183,
        -121.597591,
        39.175451
      ]
    },
    {
      "id": 4306,
      "name": "Fujimino",
      "names": {
        "th": "ฟูยิมิโนะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8794749,
        "lng": 139.5195441
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.468331,
        35.842034,
        139.551073,
        35.892285
      ]
    },
    {
      "id": 4307,
      "name": "Sertaozinho",
      "names": {
        "pt": "Sertãozinho"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1375782,
        "lng": -47.9913741
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.1868711,
        -21.2189008,
        -47.8709758,
        -20.9918344
      ]
    },
    {
      "id": 4308,
      "name": "Kuopio",
      "names": {
        "zh": "库奥皮奥",
        "ja": "クオピオ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.8924601,
        "lng": 27.6781839
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        26.890138,
        62.5922177,
        28.7624182,
        63.4033023
      ]
    },
    {
      "id": 4309,
      "name": "Poa",
      "names": {
        "fr": "Programme d'action",
        "zh": "早熟禾",
        "th": "ไม่แสดงราคา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5249082,
        "lng": -46.3445663
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.3685187,
        -23.5740373,
        -46.3244034,
        -23.5056394
      ]
    },
    {
      "id": 4310,
      "name": "Ghardaia",
      "names": {
        "zh": "盖尔达耶",
        "id": "Ghardaïa"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 32.440827,
        "lng": 3.5618208521628776
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.2399407,
        31.7983751,
        5.025157,
        33.071012
      ]
    },
    {
      "id": 4311,
      "name": "Candaba",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0923632,
        "lng": 120.8272514
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7812358,
        15.0082762,
        120.952567,
        15.2096972
      ]
    },
    {
      "id": 4312,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 6.1908026,
        "lng": -74.943787890907
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -75.1217374,
        6.0427797,
        -74.7133773,
        6.3386303
      ]
    },
    {
      "id": 4313,
      "name": "Ciudad Choluteca",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 13.30028,
        "lng": -87.19083
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -87.21083,
        13.28028,
        -87.17083,
        13.32028
      ]
    },
    {
      "id": 4314,
      "name": "Handa",
      "names": {
        "zh": "汉达",
        "ja": "がHanda"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.895502,
        "lng": 136.924924
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.884205,
        34.861146,
        136.972002,
        34.944494
      ]
    },
    {
      "id": 4315,
      "name": "Varzea Paulista",
      "names": {
        "zh": "瓦尔西亚保利斯塔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2113889,
        "lng": -46.8283333
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.855571,
        -23.2612563,
        -46.7964578,
        -23.181417
      ]
    },
    {
      "id": 4316,
      "name": "Rochester",
      "names": {
        "zh": "罗切斯特",
        "ja": "ロチェスター",
        "th": "โรเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0234387,
        "lng": -92.4630182
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -92.5731822,
        43.884547,
        -92.3890009,
        44.1082534
      ]
    },
    {
      "id": 4317,
      "name": "Catanduva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1331035,
        "lng": -48.9712817
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.091,
        -21.254,
        -48.8168047,
        -21.0058637
      ]
    },
    {
      "id": 4318,
      "name": "Nouadhibou",
      "names": {
        "zh": "努瓦迪布"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 20.9127045,
        "lng": -17.0503094
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -17.0593356,
        20.9058748,
        -17.0155598,
        21.0037799
      ]
    },
    {
      "id": 4319,
      "name": "Chelmsford",
      "names": {
        "zh": "切姆斯福德",
        "ja": "チェルムスフォード",
        "th": "เชล์มส"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7345329,
        "lng": 0.4730532
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.3321643,
        51.6166234,
        0.6489798,
        51.8574836
      ]
    },
    {
      "id": 4320,
      "name": "Dordrecht",
      "names": {
        "zh": "多德雷赫特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8075316,
        "lng": 4.6677478
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6627478,
        51.8025316,
        4.6727478,
        51.8125316
      ]
    },
    {
      "id": 4321,
      "name": "Gudivada",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.4329976,
        "lng": 80.9937151
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.8337151,
        16.2729976,
        81.1537151,
        16.5929976
      ]
    },
    {
      "id": 4322,
      "name": "Lomas de Zamora",
      "names": {
        "ja": "ローマデザモラ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7611381,
        "lng": -58.397339
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.402339,
        -34.7661381,
        -58.392339,
        -34.7561381
      ]
    },
    {
      "id": 4323,
      "name": "Nazarabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.9558897,
        "lng": 50.6097443
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.5810149,
        35.931713,
        50.6561891,
        36.002291
      ]
    },
    {
      "id": 4324,
      "name": "Besancon",
      "names": {
        "es": "Besançon",
        "zh": "贝桑松",
        "it": "Besançon",
        "ja": "ブザンソン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2380222,
        "lng": 6.0243622
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.9409668,
        47.2006872,
        6.0835059,
        47.3200746
      ]
    },
    {
      "id": 4325,
      "name": "Leominster",
      "names": {
        "zh": "莱姆斯特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5267443,
        "lng": -71.7614891
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.8423261,
        42.4751335,
        -71.7022367,
        42.5738598
      ]
    },
    {
      "id": 4326,
      "name": "Ajax",
      "names": {
        "zh": "Ajax的",
        "ja": "のAjax",
        "th": "อาแจ็กซ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.847988,
        "lng": -79.0427908
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.0477908,
        43.842988,
        -79.0377908,
        43.852988
      ]
    },
    {
      "id": 4327,
      "name": "Kingsport",
      "names": {
        "zh": "金斯波特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.550238,
        "lng": -82.5594293
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.673952,
        36.434314,
        -82.381367,
        36.594605
      ]
    },
    {
      "id": 4328,
      "name": "Xuqiaocun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8882928,
        "lng": 114.5355759
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.5155759,
        30.8682928,
        114.5555759,
        30.9082928
      ]
    },
    {
      "id": 4329,
      "name": "Dinalupihan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.87314,
        "lng": 120.46416
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.44416,
        14.85314,
        120.48416,
        14.89314
      ]
    },
    {
      "id": 4330,
      "name": "Duluth",
      "names": {
        "zh": "德卢斯",
        "ja": "ダルース",
        "th": "ลู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.7729322,
        "lng": -92.1251218
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -92.3012374,
        46.6491584,
        -91.920178,
        46.880571
      ]
    },
    {
      "id": 4331,
      "name": "Dongsheng",
      "names": {
        "zh": "东升"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.716825,
        "lng": 112.522853
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.482853,
        29.676825,
        112.562853,
        29.756825
      ]
    },
    {
      "id": 4332,
      "name": "Sopur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 34.2869124,
        "lng": 74.4625673
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.4225673,
        34.2469124,
        74.5025673,
        34.3269124
      ]
    },
    {
      "id": 4333,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.7222438,
        "lng": -53.7402476
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.0434966,
        -24.9416564,
        -53.534,
        -24.453
      ]
    },
    {
      "id": 4334,
      "name": "Elblag",
      "names": {
        "ja": "エルブロンク"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.167069,
        "lng": 19.411545
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.359052,
        54.134417,
        19.509831,
        54.263257
      ]
    },
    {
      "id": 4335,
      "name": "Nazran",
      "names": {
        "zh": "纳兹兰"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.220332,
        "lng": 44.771894
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        44.748676,
        43.198936,
        44.791199,
        43.236282
      ]
    },
    {
      "id": 4336,
      "name": "Araucaria",
      "names": {
        "zh": "南洋杉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.5861107,
        "lng": -49.4051209
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.5868441,
        -25.788,
        -49.3378886,
        -25.4789845
      ]
    },
    {
      "id": 4337,
      "name": "Metz",
      "names": {
        "zh": "梅茨",
        "ja": "メス",
        "th": "เมตซ์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1196964,
        "lng": 6.1763552
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.1360042,
        49.0608244,
        6.256451,
        49.1487955
      ]
    },
    {
      "id": 4338,
      "name": "Totonicapan",
      "names": {
        "es": "Totonicapán",
        "pt": "Totonicapán"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.911838,
        "lng": -91.3599703
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.4377512,
        14.8234812,
        -91.1753156,
        14.9611053
      ]
    },
    {
      "id": 4339,
      "name": "Paulo Afonso",
      "names": {
        "zh": "保罗Afonso",
        "th": "เปาโล Afonso"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.404532,
        "lng": -38.227359
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.277118,
        -9.458578,
        -38.190669,
        -9.352937
      ]
    },
    {
      "id": 4340,
      "name": "Guaratingueta",
      "names": {
        "zh": "瓜拉廷格塔",
        "de": "Guaratinguetá",
        "pt": "Guaratinguetá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8131263,
        "lng": -45.257553978975636
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.4398608,
        -23.015,
        -45.0292809,
        -22.611337
      ]
    },
    {
      "id": 4341,
      "name": "Alcobendas",
      "names": {
        "zh": "阿尔科文达斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.5400082,
        "lng": -3.6358494
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.7009167,
        40.5011783,
        -3.5494995,
        40.5888135
      ]
    },
    {
      "id": 4342,
      "name": "Huaraz",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -9.5298511,
        "lng": -77.5289981
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -77.6472011,
        -9.7378664,
        -77.3005746,
        -9.4271114
      ]
    },
    {
      "id": 4343,
      "name": "Bruges",
      "names": {
        "es": "Brujas",
        "zh": "布鲁日",
        "de": "Brügge",
        "ja": "ブルージュ",
        "th": "บรูจส์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.2085526,
        "lng": 3.226772
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.1341748,
        51.1582193,
        3.3098982,
        51.363347
      ]
    },
    {
      "id": 4344,
      "name": "Rock Hill",
      "names": {
        "es": "Colina de rock",
        "fr": "Rock colline",
        "zh": "摇滚山",
        "de": "Rockhügel",
        "id": "Bukit rock",
        "ja": "ロックヒル",
        "th": "หิน",
        "pt": "Colina"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.9248667,
        "lng": -81.0250784
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.125219,
        34.870838,
        -80.928101,
        35.014812
      ]
    },
    {
      "id": 4345,
      "name": "Kasuga",
      "names": {
        "zh": "春日",
        "ja": "春日"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5326446,
        "lng": 130.4713013
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4340464,
        33.498694,
        130.4793683,
        33.549122
      ]
    },
    {
      "id": 4346,
      "name": "Apalit",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9494832,
        "lng": 120.7588083
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7280465,
        14.9232143,
        120.8802191,
        14.9861677
      ]
    },
    {
      "id": 4347,
      "name": "Gabes",
      "names": {
        "fr": "Gabès",
        "zh": "加贝斯"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.8878082,
        "lng": 10.10044
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        9.94044,
        33.7278082,
        10.26044,
        34.0478082
      ]
    },
    {
      "id": 4348,
      "name": "Gilroy",
      "names": {
        "zh": "吉尔罗伊"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0044863,
        "lng": -121.5667505
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.5717505,
        36.9994863,
        -121.5617505,
        37.0094863
      ]
    },
    {
      "id": 4349,
      "name": "Charallave",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2456598,
        "lng": -66.8586608
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.8996063,
        10.1923574,
        -66.7702031,
        10.2882762
      ]
    },
    {
      "id": 4350,
      "name": "Bandundu",
      "names": {
        "zh": "班顿杜"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -3.3201862,
        "lng": 17.3774958
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        17.2174958,
        -3.4801862,
        17.5374958,
        -3.1601862
      ]
    },
    {
      "id": 4351,
      "name": "Cheltenham",
      "names": {
        "zh": "切尔滕纳姆",
        "it": "Di Cheltenham",
        "ja": "チェルトナム",
        "th": "บี้แบน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8995685,
        "lng": -2.0711559
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.1430399,
        51.8583342,
        -2.0102235,
        51.9388732
      ]
    },
    {
      "id": 4352,
      "name": "Cambridge",
      "names": {
        "zh": "剑桥",
        "ja": "ケンブリッジ",
        "th": "เคมบริดจ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3656347,
        "lng": -71.1040018
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.1603989,
        42.3524025,
        -71.0639844,
        42.4042593
      ]
    },
    {
      "id": 4353,
      "name": "Nevinnomyssk",
      "names": {
        "zh": "涅温诺梅斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.640525,
        "lng": 41.941602
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        41.874658,
        44.577223,
        42.005747,
        44.707807
      ]
    },
    {
      "id": 4354,
      "name": "Lahti",
      "names": {
        "zh": "拉赫蒂",
        "ja": "ラハティ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.9826014,
        "lng": 25.6613764
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        25.5403066,
        60.8866528,
        26.1689723,
        61.1026648
      ]
    },
    {
      "id": 4355,
      "name": "Reutov",
      "names": {
        "zh": "列乌托夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7637361,
        "lng": 37.862538689546184
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.8425433,
        55.7444999,
        37.8908803,
        55.7829362
      ]
    },
    {
      "id": 4356,
      "name": "Dimitrovgrad",
      "names": {
        "zh": "季米特洛夫格勒"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.2188995,
        "lng": 49.6038982
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        49.5212115,
        54.1815339,
        49.6955232,
        54.2981603
      ]
    },
    {
      "id": 4357,
      "name": "Giyon",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 8.53333,
        "lng": 37.98333
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        37.96333,
        8.51333,
        38.00333,
        8.55333
      ]
    },
    {
      "id": 4358,
      "name": "Trento",
      "names": {
        "zh": "特伦托",
        "de": "Trient",
        "ja": "トレント",
        "th": "เทรนโต"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.067733,
        "lng": 11.109132
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        11.021495,
        45.975888,
        11.193857,
        46.152101
      ]
    },
    {
      "id": 4359,
      "name": "Yongqing",
      "names": {
        "zh": "永庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.2960359,
        "lng": 116.4895526
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.4495526,
        39.2560359,
        116.5295526,
        39.3360359
      ]
    },
    {
      "id": 4360,
      "name": "Nowshera",
      "names": {
        "zh": "瑙谢拉",
        "th": "นาว"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.0155606,
        "lng": 71.981283
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.821283,
        33.8555606,
        72.141283,
        34.1755606
      ]
    },
    {
      "id": 4361,
      "name": "Butwal",
      "names": {
        "zh": "布特瓦尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.70055,
        "lng": 83.44836
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        83.42836,
        27.68055,
        83.46836,
        27.72055
      ]
    },
    {
      "id": 4362,
      "name": "Barcarena Nova",
      "names": {
        "ja": "バルカレナノバ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.507552,
        "lng": -48.618416
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.63176,
        -1.530687,
        -48.594661,
        -1.490102
      ]
    },
    {
      "id": 4363,
      "name": "Martinez de la Torre",
      "names": {
        "es": "Martínez de la Torre"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.071791,
        "lng": -97.054357
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0880129588,
        20.0516454775,
        -97.0194294832,
        20.0908850594
      ]
    },
    {
      "id": 4364,
      "name": "Akishima",
      "names": {
        "zh": "昭岛"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.70248,
        "lng": 139.350065
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.33153,
        35.691282,
        139.398528,
        35.726292
      ]
    },
    {
      "id": 4365,
      "name": "Birigui",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2908491,
        "lng": -50.34144
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.466397,
        -21.4680261,
        -50.239,
        -21.06
      ]
    },
    {
      "id": 4366,
      "name": "Ligao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2402789,
        "lng": 123.5365227
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3765227,
        13.0802789,
        123.6965227,
        13.4002789
      ]
    },
    {
      "id": 4367,
      "name": "Bottrop",
      "names": {
        "zh": "博特罗普",
        "ja": "ボトロップ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.521581,
        "lng": 6.929204
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.8328951,
        51.4970292,
        6.9991075,
        51.6448359
      ]
    },
    {
      "id": 4368,
      "name": "Santa Cruz do Sul",
      "names": {
        "zh": "圣克鲁斯Do Sul",
        "ja": "サンタクルーズはスルをします",
        "th": "ซานตาครูซทำซ้า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.714209,
        "lng": -52.4285807
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.5639566,
        -29.9251306,
        -52.2997778,
        -29.3744577
      ]
    },
    {
      "id": 4369,
      "name": "Konosu",
      "names": {
        "zh": "鸿巢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0657583,
        "lng": 139.5221055
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.430359,
        36.030788,
        139.570465,
        36.124874
      ]
    },
    {
      "id": 4370,
      "name": "Sugar Land",
      "names": {
        "es": "Tierra de azúcar",
        "fr": "Sucre",
        "zh": "糖陆",
        "de": "Zuckerland",
        "it": "Zucchero",
        "id": "Tanah gula",
        "ja": "シュガーランド",
        "th": "ที่ดินน้ำตาล",
        "pt": "Terra de açúcar"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.595307,
        "lng": -95.6217379
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.6217879,
        29.595257,
        -95.6216879,
        29.595357
      ]
    },
    {
      "id": 4371,
      "name": "Linkoping",
      "names": {
        "fr": "Linköping",
        "zh": "林克平"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.4098135,
        "lng": 15.6245252
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        15.4645252,
        58.2498135,
        15.7845252,
        58.5698135
      ]
    },
    {
      "id": 4372,
      "name": "Votorantim",
      "names": {
        "zh": "巴西工业集团"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.540886,
        "lng": -47.44296
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.481002,
        -23.587108,
        -47.404578,
        -23.522482
      ]
    },
    {
      "id": 4373,
      "name": "Yulu",
      "names": {
        "zh": "玉露"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.3710459,
        "lng": 103.582974
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.542974,
        26.3310459,
        103.622974,
        26.4110459
      ]
    },
    {
      "id": 4374,
      "name": "Forli",
      "names": {
        "zh": "弗利",
        "ja": "フォルリ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.2227278,
        "lng": 12.041273
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.9224861,
        44.1270564,
        12.2118671,
        44.3306793
      ]
    },
    {
      "id": 4375,
      "name": "Ikoma",
      "names": {
        "zh": "生驹",
        "ja": "生駒"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.69299,
        "lng": 135.6981415
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6980915,
        34.69294,
        135.6981915,
        34.69304
      ]
    },
    {
      "id": 4376,
      "name": "Malita",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.4143745,
        "lng": 125.6109051
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.4116499,
        6.0985706,
        125.788157,
        6.5972637
      ]
    },
    {
      "id": 4377,
      "name": "La Asuncion",
      "names": {
        "es": "LA Asunción",
        "ja": "ラ・アスンシオン",
        "th": "ลา",
        "pt": "La assunção"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.0339965,
        "lng": -63.8631527
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -63.8866568,
        11.0043879,
        -63.8462842,
        11.0626849
      ]
    },
    {
      "id": 4378,
      "name": "Aizuwakamatsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 37.494609,
        "lng": 139.932861
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.912861,
        37.474609,
        139.952861,
        37.514609
      ]
    },
    {
      "id": 4379,
      "name": "Ain Beida",
      "names": {
        "zh": "艾琳贝达",
        "ja": "北田"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7952839,
        "lng": 7.3894346
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        7.3031073,
        35.7590114,
        7.4330912,
        35.83057
      ]
    },
    {
      "id": 4380,
      "name": "Texas City",
      "names": {
        "es": "Ciudad de Texas",
        "fr": "Ville de Texas",
        "zh": "德克萨斯城",
        "it": "Città del Texas",
        "id": "Kota texas",
        "ja": "テキサスシティ",
        "th": "เมืองเท็กซัส",
        "pt": "Cidade do Texas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.396013,
        "lng": -94.9175485
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.0788828,
        29.300965,
        -94.714285,
        29.566376
      ]
    },
    {
      "id": 4381,
      "name": "Bama",
      "names": {
        "zh": "巴马"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.54544,
        "lng": 14.0064277
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        13.5730669,
        11.239776,
        14.480973,
        11.7864923
      ]
    },
    {
      "id": 4382,
      "name": "Codo",
      "names": {
        "zh": "科多"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.45765,
        "lng": -43.88783
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.906819,
        -4.489879,
        -43.867338,
        -4.424236
      ]
    },
    {
      "id": 4383,
      "name": "Iowa City",
      "names": {
        "es": "Ciudad de Iowa",
        "zh": "爱荷华州市",
        "de": "Iowa-Stadt",
        "it": "Città di Iowa",
        "id": "Kota Iowa",
        "ja": "アイオワシティ",
        "th": "เมืองไอโอวา",
        "pt": "Cidade de iowa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6612561,
        "lng": -91.5299106
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -91.6157854,
        41.5985476,
        -91.4630645,
        41.696634
      ]
    },
    {
      "id": 4384,
      "name": "Petrzalka",
      "names": {
        "de": "Petržalka",
        "it": "Petržalka"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.110972,
        "lng": 17.1112897
      },
      "country": "Slovakia",
      "importance": 3,
      "bbox": [
        17.057356,
        48.0810059,
        17.146363,
        48.142414
      ]
    },
    {
      "id": 4385,
      "name": "Plaridel",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.886116,
        "lng": 120.859909
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8026653,
        14.8602797,
        120.9147657,
        14.9235918
      ]
    },
    {
      "id": 4386,
      "name": "Saginaw",
      "names": {
        "zh": "萨吉诺",
        "ja": "サギノー",
        "th": "นอว์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4200387,
        "lng": -83.9490365
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.9957638,
        43.379884,
        -83.902285,
        43.469802
      ]
    },
    {
      "id": 4387,
      "name": "Kabwe",
      "names": {
        "zh": "卡布韦"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -14.4434056,
        "lng": 28.4465166
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        28.2865166,
        -14.6034056,
        28.6065166,
        -14.2834056
      ]
    },
    {
      "id": 4388,
      "name": "Nobeoka",
      "names": {
        "zh": "延冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.5823063,
        "lng": 131.6649034
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.428779,
        32.4494853,
        132.0823437,
        32.83898
      ]
    },
    {
      "id": 4389,
      "name": "Luanshya",
      "names": {
        "zh": "卢安夏"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -13.13667,
        "lng": 28.41661
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        28.39661,
        -13.15667,
        28.43661,
        -13.11667
      ]
    },
    {
      "id": 4390,
      "name": "Agadez",
      "names": {
        "zh": "阿加德兹",
        "th": "อะกาเดซ"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 16.97333,
        "lng": 7.99111
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        7.97111,
        16.95333,
        8.01111,
        16.99333
      ]
    },
    {
      "id": 4391,
      "name": "Uzhhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6223731,
        "lng": 22.3022572
      },
      "country": "Ukraine",
      "importance": 1,
      "bbox": [
        22.2097741,
        48.576325,
        22.3419136,
        48.661601
      ]
    },
    {
      "id": 4392,
      "name": "Adilabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.670848,
        "lng": 78.52906
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.523256,
        19.658907,
        78.552303,
        19.687681
      ]
    },
    {
      "id": 4393,
      "name": "Obninsk",
      "names": {
        "zh": "奥布宁斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.09992175,
        "lng": 36.609273347238314
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        36.5372175,
        55.0530512,
        36.666559,
        55.1464251
      ]
    },
    {
      "id": 4394,
      "name": "Uribia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.7139,
        "lng": -72.26604
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.28604,
        11.6939,
        -72.24604,
        11.7339
      ]
    },
    {
      "id": 4395,
      "name": "Piedecuesta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.98789,
        "lng": -73.04953
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.06953,
        6.96789,
        -73.02953,
        7.00789
      ]
    },
    {
      "id": 4396,
      "name": "Chico",
      "names": {
        "zh": "奇科",
        "ja": "チコ",
        "th": "ชิโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7284945,
        "lng": -121.8374777
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.8987215,
        39.6986722,
        -121.7123992,
        39.8168492
      ]
    },
    {
      "id": 4397,
      "name": "Guiguinto",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.83333,
        "lng": 120.88333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.86333,
        14.81333,
        120.90333,
        14.85333
      ]
    },
    {
      "id": 4398,
      "name": "Huanren",
      "names": {
        "zh": "桓仁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2681066,
        "lng": 125.3487306
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        124.7212218,
        40.9062856,
        125.7852121,
        41.5657127
      ]
    },
    {
      "id": 4399,
      "name": "Machakos",
      "names": {
        "zh": "马查科斯"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.51667,
        "lng": 37.26667
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.24667,
        -1.53667,
        37.28667,
        -1.49667
      ]
    },
    {
      "id": 4400,
      "name": "San Martin",
      "names": {
        "es": "San Martín",
        "zh": "圣马丁",
        "ja": "サンマーティン",
        "th": "ซานมาร์ติน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 18.0423736,
        "lng": -63.0549948
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.2688117,
        17.806209,
        -62.949445,
        18.0639862
      ]
    },
    {
      "id": 4401,
      "name": "Los Banos",
      "names": {
        "ja": "ロスバノス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1775648,
        "lng": 121.2214303
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1713354,
        14.1113276,
        121.2661189,
        14.2568965
      ]
    },
    {
      "id": 4402,
      "name": "Walbrzych",
      "names": {
        "zh": "瓦乌布日赫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7659054,
        "lng": 16.2825424
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.2271787,
        50.7160927,
        16.35527,
        50.8541402
      ]
    },
    {
      "id": 4403,
      "name": "Xai-Xai",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.05194,
        "lng": 33.64417
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        33.62417,
        -25.07194,
        33.66417,
        -25.03194
      ]
    },
    {
      "id": 4404,
      "name": "Rochdale",
      "names": {
        "zh": "罗奇代尔",
        "th": "โรชเดล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6153659,
        "lng": -2.1557561
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.2825852,
        53.5290265,
        -2.02687,
        53.6857188
      ]
    },
    {
      "id": 4405,
      "name": "Banfora",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 10.63333,
        "lng": -4.76667
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -4.78667,
        10.61333,
        -4.74667,
        10.65333
      ]
    },
    {
      "id": 4406,
      "name": "Reutlingen",
      "names": {
        "zh": "罗伊特林根",
        "ja": "ロイトリンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4889961,
        "lng": 9.209810150364323
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.1343034,
        48.4496033,
        9.2478576,
        48.5283721
      ]
    },
    {
      "id": 4407,
      "name": "Langley",
      "names": {
        "zh": "兰利",
        "ja": "ラングレー",
        "th": "แลงลีย์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.1039289,
        "lng": -122.657047
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.6799898,
        49.0819686,
        -122.6378728,
        49.1154304
      ]
    },
    {
      "id": 4408,
      "name": "Dover",
      "names": {
        "zh": "多佛",
        "ja": "ドーバー",
        "th": "โดเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.158168,
        "lng": -75.5243682
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -75.586203,
        39.102195,
        -75.440731,
        39.2102529
      ]
    },
    {
      "id": 4409,
      "name": "Narasaraopet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.23411,
        "lng": 80.047297
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.025363,
        16.220585,
        80.064492,
        16.259398
      ]
    },
    {
      "id": 4410,
      "name": "Kindia",
      "names": {
        "zh": "金迪亚",
        "th": "คินเดีย"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.0492476,
        "lng": -12.8577997
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -13.0177997,
        9.8892476,
        -12.6977997,
        10.2092476
      ]
    },
    {
      "id": 4411,
      "name": "Heroica Guaymas",
      "names": {
        "fr": "Heroiica Guaymas",
        "it": "Guaymas Heroica",
        "id": "Guaymas Heroica",
        "ja": "ヒロイカグアイマス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.9216441,
        "lng": -110.8994059
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -111.0594059,
        27.7616441,
        -110.7394059,
        28.0816441
      ]
    },
    {
      "id": 4412,
      "name": "Kyzyl",
      "names": {
        "zh": "克孜勒"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.719079,
        "lng": 94.4300679
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        94.2714722,
        51.5504648,
        94.6412647,
        51.854595
      ]
    },
    {
      "id": 4413,
      "name": "Nkongsamba",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 4.9525115,
        "lng": 9.9391669
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        9.7791669,
        4.7925115,
        10.0991669,
        5.1125115
      ]
    },
    {
      "id": 4414,
      "name": "Samal",
      "names": {
        "zh": "萨马勒"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0780265,
        "lng": 125.7120437
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.5764656,
        6.7587654,
        125.8822576,
        7.2540442
      ]
    },
    {
      "id": 4415,
      "name": "Facatativa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8097329,
        "lng": -74.3541284
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4186269,
        4.7627188,
        -74.2605148,
        4.8966116
      ]
    },
    {
      "id": 4416,
      "name": "Guadalajara de Buga",
      "names": {
        "zh": "瓜达拉哈拉德怪",
        "id": "Guadalajara de Buba",
        "ja": "グアダラハラデガ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.9000578,
        "lng": -76.302013
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3837016,
        3.7135657,
        -75.8504892,
        3.9986402
      ]
    },
    {
      "id": 4417,
      "name": "Cassongue",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -11.85,
        "lng": 15.05
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.03,
        -11.87,
        15.07,
        -11.83
      ]
    },
    {
      "id": 4418,
      "name": "Nyiregyhaza",
      "names": {
        "zh": "尼赖吉哈佐",
        "it": "Nyíregyháza"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.953171,
        "lng": 21.721436
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        21.671561,
        47.912442,
        21.774439,
        48.000725
      ]
    },
    {
      "id": 4419,
      "name": "Manpo",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 41.1547179,
        "lng": 126.2876899
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        126.062622,
        40.9538548,
        126.6639407,
        41.3718592
      ]
    },
    {
      "id": 4420,
      "name": "Mendoza",
      "names": {
        "zh": "门多萨",
        "ja": "メンドーサ",
        "th": "เมนโดซา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.8894155,
        "lng": -68.8446177
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -69.0902178,
        -32.9046319,
        -68.8172546,
        -32.8620161
      ]
    },
    {
      "id": 4421,
      "name": "Tandil",
      "names": {
        "zh": "坦迪尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.3282887,
        "lng": -59.1356957
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.2221009,
        -37.3978505,
        -59.0483205,
        -37.2593905
      ]
    },
    {
      "id": 4422,
      "name": "Clearwater",
      "names": {
        "zh": "克利尔沃特",
        "ja": "クリアウォーター",
        "th": "เคลียร์วอเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9658533,
        "lng": -82.8001026
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.8434471,
        27.9351239,
        -82.6792432,
        28.0499611
      ]
    },
    {
      "id": 4423,
      "name": "Yavatmal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.390344,
        "lng": 78.1328518
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.9728518,
        20.230344,
        78.2928518,
        20.550344
      ]
    },
    {
      "id": 4424,
      "name": "Beppu",
      "names": {
        "zh": "别府",
        "ja": "別府"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2845752,
        "lng": 131.4913063
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.378195,
        33.223892,
        131.5636408,
        33.354344
      ]
    },
    {
      "id": 4425,
      "name": "Buzau",
      "names": {
        "zh": "布泽乌"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.149943,
        "lng": 26.818518
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        26.759055,
        45.11852,
        26.88813,
        45.175632
      ]
    },
    {
      "id": 4426,
      "name": "Araguari",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.6511256,
        "lng": -48.1933764
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.6664225,
        -18.8914555,
        -47.872,
        -18.3229102
      ]
    },
    {
      "id": 4427,
      "name": "Tatui",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3489848,
        "lng": -47.8490165
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.0825494,
        -23.4885215,
        -47.7241597,
        -23.2218851
      ]
    },
    {
      "id": 4428,
      "name": "Port-Gentil",
      "names": {
        "zh": "端口让蒂尔",
        "it": "Port gentil",
        "ja": "ポートgentil",
        "th": "พอร์ต gentil"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -0.7151966,
        "lng": 8.7787919
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        8.6187919,
        -0.8751966,
        8.9387919,
        -0.5551966
      ]
    },
    {
      "id": 4429,
      "name": "Shibuya",
      "names": {
        "zh": "涩谷",
        "ja": "渋谷区",
        "th": "ชิบุย่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6580785,
        "lng": 139.7017546
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6967546,
        35.6530785,
        139.7067546,
        35.6630785
      ]
    },
    {
      "id": 4430,
      "name": "Sassari",
      "names": {
        "zh": "萨萨里",
        "ja": "サッサリ",
        "th": "ซาสซารี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7234746,
        "lng": 8.5612674
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.1308446,
        40.6517507,
        8.6435988,
        40.869301
      ]
    },
    {
      "id": 4431,
      "name": "Coeur d'Alene",
      "names": {
        "it": "COEUR D'ALEENE",
        "ja": "コーデアレーン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6742994,
        "lng": -116.7811531
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -116.8528995,
        47.6587689,
        -116.7314535,
        47.7448395
      ]
    },
    {
      "id": 4432,
      "name": "Conselheiro Lafaiete",
      "names": {
        "zh": "Concelheiro Lafaiete"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.6596107,
        "lng": -43.785743
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.9307987,
        -20.801,
        -43.679,
        -20.538071
      ]
    },
    {
      "id": 4433,
      "name": "Bage",
      "names": {
        "zh": "巴热"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -31.3314264,
        "lng": -54.1062808
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.7016472,
        -31.6528443,
        -53.4289036,
        -30.8377789
      ]
    },
    {
      "id": 4434,
      "name": "Chittaurgarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.879634,
        "lng": 74.622423
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.610436,
        24.867131,
        74.632327,
        24.905492
      ]
    },
    {
      "id": 4435,
      "name": "Berkane",
      "names": {
        "zh": "贝尔卡内"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.926624,
        "lng": -2.321284
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -2.377527,
        34.89355,
        -2.285157,
        34.957835
      ]
    },
    {
      "id": 4436,
      "name": "Santander de Quilichao",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.00945,
        "lng": -76.48494
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.50494,
        2.98945,
        -76.46494,
        3.02945
      ]
    },
    {
      "id": 4437,
      "name": "Seaside",
      "names": {
        "es": "Playa",
        "fr": "Mer",
        "zh": "海边",
        "de": "Strand",
        "it": "Mare",
        "ja": "海辺",
        "th": "ริมทะเล",
        "pt": "Mar"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.62593,
        "lng": -121.8169260491048
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.8799085,
        36.5968095,
        -121.7898816,
        36.6547441
      ]
    },
    {
      "id": 4438,
      "name": "Helsingborg",
      "names": {
        "zh": "赫尔辛堡",
        "ja": "ヘルシグボリ",
        "th": "เฮลซิงบอร์ก"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.0442098,
        "lng": 12.703706
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.543706,
        55.8842098,
        12.863706,
        56.2042098
      ]
    },
    {
      "id": 4439,
      "name": "Coronel",
      "names": {
        "zh": "科罗内尔",
        "ja": "コロネル",
        "th": "เนล"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -37.0245655,
        "lng": -73.1507961
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -73.1557961,
        -37.0295655,
        -73.1457961,
        -37.0195655
      ]
    },
    {
      "id": 4440,
      "name": "Independence",
      "names": {
        "es": "Independencia",
        "fr": "L'indépendance",
        "zh": "独立性",
        "de": "Unabhängigkeit",
        "it": "Indipendenza",
        "id": "Kemerdekaan",
        "ja": "独立",
        "th": "เป็นอิสระ",
        "pt": "Independência"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0924792,
        "lng": -94.4137923
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.4815,
        39.017679,
        -94.2160092,
        39.1398712
      ]
    },
    {
      "id": 4441,
      "name": "Smithtown",
      "names": {
        "zh": "史密斯敦"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8559314,
        "lng": -73.2006687
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.31907,
        40.803834,
        -73.121417,
        41.036489
      ]
    },
    {
      "id": 4442,
      "name": "Argenteuil",
      "names": {
        "zh": "阿根特尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9479069,
        "lng": 2.2481797
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2033804,
        48.9278264,
        2.2922314,
        48.9724609
      ]
    },
    {
      "id": 4443,
      "name": "Dharan",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 26.8128974,
        "lng": 87.2839978
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        87.1981146,
        26.7120528,
        87.3538064,
        26.8788928
      ]
    },
    {
      "id": 4444,
      "name": "Boke",
      "names": {
        "zh": "博凯"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.952242,
        "lng": -14.2760635
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -14.4360635,
        10.792242,
        -14.1160635,
        11.112242
      ]
    },
    {
      "id": 4445,
      "name": "Rouen",
      "names": {
        "zh": "鲁昂",
        "ja": "ルーアン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4404591,
        "lng": 1.0939658
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        1.0300648,
        49.4172001,
        1.1521157,
        49.4652601
      ]
    },
    {
      "id": 4446,
      "name": "Himamaylan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0992841,
        "lng": 122.870539
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7918605,
        9.916046,
        123.0280103,
        10.2024958
      ]
    },
    {
      "id": 4447,
      "name": "San Antonio Enchisi",
      "names": {
        "ja": "サンアントニオエンチジ",
        "th": "ซานอันโตนิโอเอนชิซี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.751339,
        "lng": -99.816572
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -99.84367,
        19.73429,
        -99.80367,
        19.77429
      ]
    },
    {
      "id": 4448,
      "name": "Jounie",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 33.9741506,
        "lng": 35.6200633
      },
      "country": "Lebanon",
      "importance": 2,
      "bbox": [
        35.4600633,
        33.8141506,
        35.7800633,
        34.1341506
      ]
    },
    {
      "id": 4449,
      "name": "West Jordan",
      "names": {
        "es": "JORDAN OESTE",
        "fr": "Jordanie occidentale",
        "zh": "西约旦",
        "de": "West Jordanien",
        "it": "Giordania occidentale",
        "id": "Jordan Barat",
        "ja": "ウェストジョーダン",
        "th": "จอร์แดนตะวันตก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6061337,
        "lng": -111.9395211
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.0825853,
        40.5659421,
        -111.911742,
        40.6401398
      ]
    },
    {
      "id": 4450,
      "name": "Bongao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.02917,
        "lng": 119.77306
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        119.75306,
        5.00917,
        119.79306,
        5.04917
      ]
    },
    {
      "id": 4451,
      "name": "Phatthaya",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 12.93465,
        "lng": 100.87403
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 4452,
      "name": "Swabi",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1262144,
        "lng": 72.4743164
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.3143164,
        33.9662144,
        72.6343164,
        34.2862144
      ]
    },
    {
      "id": 4453,
      "name": "Qiaotou",
      "names": {
        "zh": "桥头"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9277499,
        "lng": 101.6816907
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.6416907,
        36.8877499,
        101.7216907,
        36.9677499
      ]
    },
    {
      "id": 4454,
      "name": "Khon Kaen",
      "names": {
        "th": "ขอนแก่น"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4303916,
        "lng": 102.8355904
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.6755904,
        16.2703916,
        102.9955904,
        16.5903916
      ]
    },
    {
      "id": 4455,
      "name": "Karatsu",
      "names": {
        "zh": "唐津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4503405,
        "lng": 129.9679345
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        129.6400147,
        33.277587,
        130.1705301,
        33.7048579
      ]
    },
    {
      "id": 4456,
      "name": "Ramenskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.5651537,
        "lng": 38.2262173
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.2212173,
        55.5601537,
        38.2312173,
        55.5701537
      ]
    },
    {
      "id": 4457,
      "name": "Ra's al Khaymah",
      "names": {
        "fr": "Ra Al Khaymah",
        "zh": "Ra的Al Khaymah",
        "ja": "RAのAl Khaymah",
        "pt": "Adora al khaymah"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.7737705,
        "lng": 55.938232
      },
      "country": "United Arab Emirates",
      "importance": 1,
      "bbox": [
        55.778232,
        25.6137705,
        56.098232,
        25.9337705
      ]
    },
    {
      "id": 4458,
      "name": "Itapipoca",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.4960586,
        "lng": -39.5819197
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.7872216,
        -3.6549893,
        -39.3785653,
        -3.0792463
      ]
    },
    {
      "id": 4459,
      "name": "Guanabacoa",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 23.1255172,
        "lng": -82.3048707
      },
      "country": "Cuba",
      "importance": 3,
      "bbox": [
        -82.3305615,
        23.0496644,
        -82.1492223,
        23.1592886
      ]
    },
    {
      "id": 4460,
      "name": "Sabha",
      "names": {
        "zh": "塞卜哈",
        "it": "Sebha",
        "th": "บา"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 26.9956212,
        "lng": 15.1065417
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        13.932375,
        26.2469215,
        16.2807236,
        27.7411938
      ]
    },
    {
      "id": 4461,
      "name": "Tahoua",
      "names": {
        "zh": "塔瓦"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 14.889922,
        "lng": 5.262149
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        5.2239607,
        14.8592541,
        5.3103768,
        14.9158955
      ]
    },
    {
      "id": 4462,
      "name": "Shacheng",
      "names": {
        "zh": "沙城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.3960155,
        "lng": 115.5105176
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.5055176,
        40.3910155,
        115.5155176,
        40.4010155
      ]
    },
    {
      "id": 4463,
      "name": "Fujimi",
      "names": {
        "ja": "フジミ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.698128800000006,
        "lng": 139.74524555240183
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7391957,
        35.6946605,
        139.7499341,
        35.7014976
      ]
    },
    {
      "id": 4464,
      "name": "Valle de La Pascua",
      "names": {
        "ja": "バルデラパスカ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.212625,
        "lng": -66.010391
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.030391,
        9.192625,
        -65.990391,
        9.232625
      ]
    },
    {
      "id": 4465,
      "name": "Brandon",
      "names": {
        "zh": "布兰登",
        "ja": "ブランドン",
        "th": "แบรนดอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.2731475,
        "lng": -89.9868058
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.0445978,
        32.2377117,
        -89.932679,
        32.313019
      ]
    },
    {
      "id": 4466,
      "name": "San Ildefonso",
      "names": {
        "zh": "San Indrefonso",
        "de": "San Ildefono",
        "ja": "サンィルデフィンソ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0781219,
        "lng": 120.9398068
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8979862,
        14.9937331,
        121.0771942,
        15.1194386
      ]
    },
    {
      "id": 4467,
      "name": "Bloomington",
      "names": {
        "zh": "布卢明顿",
        "ja": "ブルーミントン",
        "th": "ในบลูมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1670396,
        "lng": -86.5342881
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.5918944,
        39.1213291,
        -86.4711305,
        39.2213618
      ]
    },
    {
      "id": 4468,
      "name": "Toliara",
      "names": {
        "zh": "图利亚拉"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -23.35,
        "lng": 43.66667
      },
      "country": "Madagascar",
      "importance": 1,
      "bbox": [
        43.64667,
        -23.37,
        43.68667,
        -23.33
      ]
    },
    {
      "id": 4469,
      "name": "Nasushiobara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.9621788,
        "lng": 140.0467207
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.732959,
        36.836907,
        140.14125,
        37.155063
      ]
    },
    {
      "id": 4470,
      "name": "Tokai",
      "names": {
        "zh": "东海",
        "ja": "東海",
        "th": "โตไก"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5861635,
        "lng": 139.752987
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7484886,
        35.5761983,
        139.7830959,
        35.5990246
      ]
    },
    {
      "id": 4471,
      "name": "Durres",
      "names": {
        "it": "Durazzo"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 41.33141865,
        "lng": 19.456506474481557
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        19.4185109,
        41.2853616,
        19.5137048,
        41.3774233
      ]
    },
    {
      "id": 4472,
      "name": "Berdiansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.75858,
        "lng": 36.78448
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.76448,
        46.73858,
        36.80448,
        46.77858
      ]
    },
    {
      "id": 4473,
      "name": "Pedro Juan Caballero",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -22.5497781,
        "lng": -55.7384312
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -56.3146699,
        -23.3470131,
        -55.6556443,
        -22.2703227
      ]
    },
    {
      "id": 4474,
      "name": "Tres Lagoas",
      "names": {
        "zh": "训练拉古拉斯",
        "ja": "ラゴアストレス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.7866799,
        "lng": -51.7061247
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.7927744,
        -21.1978403,
        -51.566,
        -19.6110059
      ]
    },
    {
      "id": 4475,
      "name": "Koencho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3785731,
        "lng": 136.2618989
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.2518989,
        35.3685731,
        136.2718989,
        35.3885731
      ]
    },
    {
      "id": 4476,
      "name": "Mangaldan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.07,
        "lng": 120.4025
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3825,
        16.05,
        120.4225,
        16.09
      ]
    },
    {
      "id": 4477,
      "name": "El Monte",
      "names": {
        "ja": "エルモンテ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0767365,
        "lng": -118.0356503
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0406503,
        34.0717365,
        -118.0306503,
        34.0817365
      ]
    },
    {
      "id": 4478,
      "name": "Niihama",
      "names": {
        "zh": "新居滨"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9603497,
        "lng": 133.2835899
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.1814311,
        33.814684,
        133.455453,
        34.0846956
      ]
    },
    {
      "id": 4479,
      "name": "North Charleston",
      "names": {
        "es": "Charleston del Norte",
        "zh": "北查尔斯顿",
        "de": "Nord-Charleston",
        "it": "Charleston del Nord",
        "id": "Charleston Utara",
        "ja": "ノースチャールストン",
        "th": "ชาร์ลสตันตอนเหนือ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8699773,
        "lng": -79.9957976
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.9958476,
        32.8699273,
        -79.9957476,
        32.8700273
      ]
    },
    {
      "id": 4480,
      "name": "Carlsbad",
      "names": {
        "zh": "卡尔斯巴德",
        "ja": "カールスバッド",
        "th": "คาร์ลสแบด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1580933,
        "lng": -117.3505966
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.4095657,
        33.060109,
        -117.2159253,
        33.1825772
      ]
    },
    {
      "id": 4481,
      "name": "Bansbaria",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.409066,
        "lng": 88.172582
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.152582,
        22.389066,
        88.192582,
        22.429066
      ]
    },
    {
      "id": 4482,
      "name": "Puerto Madryn",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトマドリン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -42.7729674,
        "lng": -65.0366104
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.208718,
        -42.8768922,
        -64.786157,
        -42.5730253
      ]
    },
    {
      "id": 4483,
      "name": "Iriga City",
      "names": {
        "es": "Ciudad de Iriga",
        "zh": "Iriga市",
        "de": "Iriga-Stadt",
        "it": "Città di Iriga",
        "id": "Kota iriga",
        "ja": "イリガシティ",
        "th": "เมืองอิริก้า",
        "pt": "Cidade iriga"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.419514,
        "lng": 123.40963
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4095722,
        13.4194205,
        123.4096871,
        13.4196081
      ]
    },
    {
      "id": 4484,
      "name": "Sano",
      "names": {
        "zh": "佐野",
        "ja": "佐野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3144119,
        "lng": 139.578429
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.406497,
        36.26674,
        139.646392,
        36.5781642
      ]
    },
    {
      "id": 4485,
      "name": "Ariana",
      "names": {
        "zh": "阿里亚纳"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8587351,
        "lng": 10.188232
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.028232,
        36.6987351,
        10.348232,
        37.0187351
      ]
    },
    {
      "id": 4486,
      "name": "Sloviansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8522691,
        "lng": 37.6058241
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.5088959,
        48.8056676,
        37.7192716,
        48.8955154
      ]
    },
    {
      "id": 4487,
      "name": "Koblenz",
      "names": {
        "zh": "科布伦茨",
        "it": "Coblenza",
        "ja": "コブレンツ",
        "th": "โคเบลนซ์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3533278,
        "lng": 7.5943951
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.4782507,
        50.283001,
        7.6964354,
        50.4096941
      ]
    },
    {
      "id": 4488,
      "name": "Oktyabr'skiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.8183646,
        "lng": 59.0668266
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        59.0600053,
        52.8151624,
        59.075957,
        52.8222081
      ]
    },
    {
      "id": 4489,
      "name": "Sogamoso",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7148307,
        "lng": -72.9279328
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0879328,
        5.5548307,
        -72.7679328,
        5.8748307
      ]
    },
    {
      "id": 4490,
      "name": "St. Cloud",
      "names": {
        "es": "Nube",
        "fr": "St. nuage",
        "zh": "圣云",
        "de": "St. Wolke",
        "ja": "聖雲",
        "th": "เซนต์คลาวด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5616075,
        "lng": -94.1642004
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.261421,
        45.4524756,
        -94.0357978,
        45.5903481
      ]
    },
    {
      "id": 4491,
      "name": "Salto",
      "names": {
        "zh": "萨尔图",
        "ja": "サルト"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1989983,
        "lng": -47.2913682
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.3894471,
        -23.2374606,
        -47.1951891,
        -23.1105328
      ]
    },
    {
      "id": 4492,
      "name": "Zhijiang",
      "names": {
        "zh": "江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.4371082,
        "lng": 109.677888
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.637888,
        27.3971082,
        109.717888,
        27.4771082
      ]
    },
    {
      "id": 4493,
      "name": "Hatsukaichi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.35,
        "lng": 132.33333
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.31333,
        34.33,
        132.35333,
        34.37
      ]
    },
    {
      "id": 4494,
      "name": "Las Delicias",
      "names": {
        "de": "Las-Delicias",
        "it": "Las Delilias"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 9.5274406,
        "lng": -82.81960268685825
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -82.8850516,
        9.433216,
        -82.7395892,
        9.6220898
      ]
    },
    {
      "id": 4495,
      "name": "Bijeljina",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.7568464,
        "lng": 19.2156628
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        19.1594374,
        44.7259501,
        19.2502624,
        44.7952135
      ]
    },
    {
      "id": 4496,
      "name": "Heshan",
      "names": {
        "zh": "鹤山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.8144989,
        "lng": 108.8855377
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.7255377,
        23.6544989,
        109.0455377,
        23.9744989
      ]
    },
    {
      "id": 4497,
      "name": "Siddipet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.1017739,
        "lng": 78.8520128
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.6920128,
        17.9417739,
        79.0120128,
        18.2617739
      ]
    },
    {
      "id": 4498,
      "name": "Kankan",
      "names": {
        "zh": "康康"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.3835169,
        "lng": -9.3069354
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -9.4669354,
        10.2235169,
        -9.1469354,
        10.5435169
      ]
    },
    {
      "id": 4499,
      "name": "Temecula",
      "names": {
        "ja": "テメキュラ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4946353,
        "lng": -117.147366
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2066945,
        33.4321253,
        -117.0548703,
        33.554625
      ]
    },
    {
      "id": 4500,
      "name": "Bremerhaven",
      "names": {
        "zh": "不来梅港",
        "ja": "ブレーマーハーフェン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5505392,
        "lng": 8.5851945
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        8.48301,
        53.4711654,
        8.6591773,
        53.6101863
      ]
    },
    {
      "id": 4501,
      "name": "Bet Shemesh",
      "names": {
        "es": "Apuesta shemesh",
        "zh": "打赌骗子",
        "de": "Säugetieren",
        "it": "Scommessa Shemesh",
        "ja": "賭けシミシュ",
        "th": "เดิมพันเซมช",
        "pt": "Apostar shemah"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.74959,
        "lng": 34.98877
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.96877,
        31.72959,
        35.00877,
        31.76959
      ]
    },
    {
      "id": 4502,
      "name": "Ciudad de la Costa",
      "names": {},
      "countryCode": "UY",
      "latLng": {
        "lat": -34.807852,
        "lng": -55.950704
      },
      "country": "Uruguay",
      "importance": 3,
      "bbox": [
        -56.032641,
        -34.865035,
        -55.863581,
        -34.768311
      ]
    },
    {
      "id": 4503,
      "name": "Clovis",
      "names": {
        "zh": "克洛维斯",
        "ja": "クローヴィス",
        "th": "โคลวิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.8252277,
        "lng": -119.7029194
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.732556,
        36.782965,
        -119.6220934,
        36.946598
      ]
    },
    {
      "id": 4504,
      "name": "Bernal",
      "names": {
        "zh": "伯纳尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7093889,
        "lng": -58.2803779
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.2853779,
        -34.7143889,
        -58.2753779,
        -34.7043889
      ]
    },
    {
      "id": 4505,
      "name": "Kamagaya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7766455,
        "lng": 140.0007147
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.968374,
        35.738147,
        140.028973,
        35.803929
      ]
    },
    {
      "id": 4506,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 48.5443087,
        "lng": -122.9919497
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        -123.2678889,
        48.2800656,
        -122.7015395,
        48.8312222
      ]
    },
    {
      "id": 4507,
      "name": "Valparai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.326354,
        "lng": 76.9549984
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.9549484,
        10.326304,
        76.9550484,
        10.326404
      ]
    },
    {
      "id": 4508,
      "name": "Rotherham",
      "names": {
        "zh": "罗瑟勒姆",
        "ja": "ロザラム",
        "th": "ร็อตเธอร์แฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4310417,
        "lng": -1.355187
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.4567893,
        53.3015483,
        -1.1151369,
        53.5153079
      ]
    },
    {
      "id": 4509,
      "name": "Kakegawa",
      "names": {
        "zh": "挂川",
        "ja": "掛川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7694714,
        "lng": 138.0140036
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.0139536,
        34.7694214,
        138.0140536,
        34.7695214
      ]
    },
    {
      "id": 4510,
      "name": "Bintulu",
      "names": {
        "zh": "民都鲁"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.1874261,
        "lng": 113.0472803
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        112.8872803,
        3.0274261,
        113.2072803,
        3.3474261
      ]
    },
    {
      "id": 4511,
      "name": "Meridian",
      "names": {
        "fr": "Méridien",
        "zh": "子午线",
        "ja": "メリディアン",
        "th": "เที่ยงวันสำคัญ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6086295,
        "lng": -116.392326
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -116.4835189,
        43.5318427,
        -116.3343751,
        43.6714285
      ]
    },
    {
      "id": 4512,
      "name": "Saanich",
      "names": {
        "zh": "桑列治"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.4594315,
        "lng": -123.376494
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -123.477976,
        48.4333823,
        -123.2606575,
        48.5580058
      ]
    },
    {
      "id": 4513,
      "name": "Daet",
      "names": {
        "zh": "达特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.112333,
        "lng": 122.9556967
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.8966859,
        14.0736241,
        123.0785884,
        14.2610689
      ]
    },
    {
      "id": 4514,
      "name": "Iringa",
      "names": {
        "zh": "伊林加"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -7.770695,
        "lng": 35.691833
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        35.671833,
        -7.790695,
        35.711833,
        -7.750695
      ]
    },
    {
      "id": 4515,
      "name": "Cuito",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.3831319,
        "lng": 16.9425484
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        16.7825484,
        -12.5431319,
        17.1025484,
        -12.2231319
      ]
    },
    {
      "id": 4516,
      "name": "Recklinghausen",
      "names": {
        "zh": "雷克林豪森"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6143815,
        "lng": 7.1978546
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.1347922,
        51.5526931,
        7.2962721,
        51.6514493
      ]
    },
    {
      "id": 4517,
      "name": "Pul-e Khumri",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.94458,
        "lng": 68.71512
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        68.69512,
        35.92458,
        68.73512,
        35.96458
      ]
    },
    {
      "id": 4518,
      "name": "Nagahama",
      "names": {
        "zh": "长滨"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.384572,
        "lng": 136.26995
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.249178,
        35.361212,
        136.289178,
        35.401212
      ]
    },
    {
      "id": 4519,
      "name": "Tawau",
      "names": {
        "zh": "斗湖"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.2435206,
        "lng": 117.885331
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        117.725331,
        4.0835206,
        118.045331,
        4.4035206
      ]
    },
    {
      "id": 4520,
      "name": "Mulhouse",
      "names": {
        "zh": "米卢斯",
        "ja": "ミュルーズ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.750509050000005,
        "lng": 7.335975378368131
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.3356362,
        47.7498608,
        7.3364313,
        47.7511249
      ]
    },
    {
      "id": 4521,
      "name": "Quilengues",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.076276,
        "lng": 14.0733602
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.5651177,
        -14.5171637,
        14.3667764,
        -13.8039226
      ]
    },
    {
      "id": 4522,
      "name": "The Woodlands",
      "names": {
        "es": "Los bosques",
        "fr": "Les bois",
        "zh": "林地",
        "de": "Die Wälder",
        "it": "I boschi",
        "id": "Hutan",
        "ja": "森林地帯",
        "th": "ป่าไม้",
        "pt": "As florestas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.1734194,
        "lng": -95.504686
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.601198,
        30.1030423,
        -95.4342991,
        30.2305685
      ]
    },
    {
      "id": 4523,
      "name": "Mazhang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 21.27574,
        "lng": 110.32212
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.30212,
        21.25574,
        110.34212,
        21.29574
      ]
    },
    {
      "id": 4524,
      "name": "Paarl",
      "names": {
        "zh": "帕尔"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.7310282,
        "lng": 18.9642117
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        18.8042117,
        -33.8910282,
        19.1242117,
        -33.5710282
      ]
    },
    {
      "id": 4525,
      "name": "Hofu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0517226,
        "lng": 131.5629141
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.455242,
        33.8234221,
        131.7540743,
        34.160481
      ]
    },
    {
      "id": 4526,
      "name": "Hikone",
      "names": {
        "zh": "彦根"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.254673,
        "lng": 136.237229
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.148069,
        35.187241,
        136.2909,
        35.303967
      ]
    },
    {
      "id": 4527,
      "name": "Maranguape",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.8922967,
        "lng": -38.6835427
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.0035461,
        -4.120495,
        -38.6401157,
        -3.8567127
      ]
    },
    {
      "id": 4528,
      "name": "Hualien",
      "names": {
        "zh": "花莲",
        "ja": "花蓮",
        "th": "ฮัวเหลียน"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.978881,
        "lng": 121.607614
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        121.593073,
        23.962576,
        121.633073,
        24.002576
      ]
    },
    {
      "id": 4529,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4167644,
        "lng": 120.8553541
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7308371,
        14.3990718,
        120.8783481,
        14.5157481
      ]
    },
    {
      "id": 4530,
      "name": "Itatiba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0055542,
        "lng": -46.8397726
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.927,
        -23.1071037,
        -46.67,
        -22.922
      ]
    },
    {
      "id": 4531,
      "name": "Uba",
      "names": {
        "zh": "乌巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.12,
        "lng": -42.942777
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.106,
        -21.268,
        -42.846,
        -20.956
      ]
    },
    {
      "id": 4532,
      "name": "Bukittinggi",
      "names": {
        "zh": "武吉丁宜"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.3051954,
        "lng": 100.3694921
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        100.3331444,
        -0.3261709,
        100.4056763,
        -0.2710317
      ]
    },
    {
      "id": 4533,
      "name": "Caraguatatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.62028,
        "lng": -45.41306
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.7196583,
        -23.7600041,
        -45.2589529,
        -23.4830469
      ]
    },
    {
      "id": 4534,
      "name": "Manolo Fortich",
      "names": {
        "ja": "マノロフォーチッヒ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.36972,
        "lng": 124.86444
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.84444,
        8.34972,
        124.88444,
        8.38972
      ]
    },
    {
      "id": 4535,
      "name": "Mungo",
      "names": {
        "zh": "蒙哥",
        "ja": "マンゴー",
        "th": "มังโก"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -11.8373101,
        "lng": 16.2209165
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        16.1809165,
        -11.8773101,
        16.2609165,
        -11.7973101
      ]
    },
    {
      "id": 4536,
      "name": "Kipushi",
      "names": {
        "zh": "基普希"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -11.7602295,
        "lng": 27.2365303
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        27.1965303,
        -11.8002295,
        27.2765303,
        -11.7202295
      ]
    },
    {
      "id": 4537,
      "name": "Jendouba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.5008853,
        "lng": 8.777269764720078
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        8.7771853,
        36.5008122,
        8.7773542,
        36.5009584
      ]
    },
    {
      "id": 4538,
      "name": "Erlangen",
      "names": {
        "zh": "爱尔兰根",
        "ja": "エアランゲン",
        "th": "แอร์ลังเงิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.5960006,
        "lng": 11.0017075
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.0016575,
        49.5959506,
        11.0017575,
        49.5960506
      ]
    },
    {
      "id": 4539,
      "name": "Sa-ch'on",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.23698,
        "lng": 128.36212
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.34212,
        35.21698,
        128.38212,
        35.25698
      ]
    },
    {
      "id": 4540,
      "name": "Ponce",
      "names": {
        "zh": "庞塞",
        "ja": "ポンセ",
        "th": "เซ"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.011564,
        "lng": -66.613958
      },
      "country": "Puerto Rico",
      "importance": 3,
      "bbox": [
        -66.773958,
        17.851564,
        -66.453958,
        18.171564
      ]
    },
    {
      "id": 4541,
      "name": "Doncaster",
      "names": {
        "zh": "唐卡斯特",
        "ja": "ドンカスター",
        "th": "ดอนคาสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5227681,
        "lng": -1.1335312
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.3487326,
        53.4057426,
        -0.8653331,
        53.6612035
      ]
    },
    {
      "id": 4542,
      "name": "Libmanan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.6960365,
        "lng": 123.0612123
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0212123,
        13.6560365,
        123.1012123,
        13.7360365
      ]
    },
    {
      "id": 4543,
      "name": "Bergisch Gladbach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9929303,
        "lng": 7.1277379
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0617856,
        50.9198994,
        7.2545737,
        51.0269476
      ]
    },
    {
      "id": 4544,
      "name": "Euriapolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -16.3778,
        "lng": -39.58
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4545,
      "name": "Osmanabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.1853567,
        "lng": 76.0420253
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.8820253,
        18.0253567,
        76.2020253,
        18.3453567
      ]
    },
    {
      "id": 4546,
      "name": "Novyy Urengoy",
      "names": {
        "de": "Novyy-Urengoy",
        "it": "Noveyy Urengoy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 66.08333,
        "lng": 76.63333
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        76.61333,
        66.06333,
        76.65333,
        66.10333
      ]
    },
    {
      "id": 4547,
      "name": "Montero",
      "names": {
        "zh": "蒙特罗"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.390267,
        "lng": -63.2912675
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -63.3112675,
        -17.410267,
        -63.2712675,
        -17.370267
      ]
    },
    {
      "id": 4548,
      "name": "Madrid",
      "names": {
        "zh": "马德里",
        "ja": "マドリード",
        "th": "มาดริด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.736726,
        "lng": -74.2654685
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.328708,
        4.6801375,
        -74.1727599,
        4.859611
      ]
    },
    {
      "id": 4549,
      "name": "Westminster",
      "names": {
        "zh": "威斯敏斯特",
        "ja": "ウェストミンスター",
        "th": "เวสต์มินสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8366528,
        "lng": -105.0372046
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.1655583,
        39.8180676,
        -104.9876821,
        39.9685399
      ]
    },
    {
      "id": 4550,
      "name": "Tayabas",
      "names": {
        "zh": "塔亚巴斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0263471,
        "lng": 121.5918364
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4824302,
        13.9449254,
        121.7459211,
        14.094207
      ]
    },
    {
      "id": 4551,
      "name": "Mopti",
      "names": {
        "zh": "莫普提"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 14.4981814,
        "lng": -4.2003626
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -4.2004126,
        14.4981314,
        -4.2003126,
        14.4982314
      ]
    },
    {
      "id": 4552,
      "name": "Sokode",
      "names": {
        "zh": "索科德"
      },
      "countryCode": "TG",
      "latLng": {
        "lat": 8.98741755,
        "lng": 1.1357851606872318
      },
      "country": "Togo",
      "importance": 1,
      "bbox": [
        1.0937321,
        8.941342,
        1.1759257,
        9.0324678
      ]
    },
    {
      "id": 4553,
      "name": "Castelar",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6520635,
        "lng": -58.6419978
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.6469978,
        -34.6570635,
        -58.6369978,
        -34.6470635
      ]
    },
    {
      "id": 4554,
      "name": "Khenchela",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4748413,
        "lng": 7.0805524
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        7.0755524,
        35.4698413,
        7.0855524,
        35.4798413
      ]
    },
    {
      "id": 4555,
      "name": "Vicenza",
      "names": {
        "zh": "维琴察",
        "ja": "ヴィチェンツァ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5488306,
        "lng": 11.5478825
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.4856434,
        45.4834324,
        11.6149846,
        45.6091106
      ]
    },
    {
      "id": 4556,
      "name": "Costa Mesa",
      "names": {
        "ja": "コスタメサ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6633386,
        "lng": -117.903317
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.9547143,
        33.625123,
        -117.8639744,
        33.7020933
      ]
    },
    {
      "id": 4557,
      "name": "San Carlos de Bariloche",
      "names": {
        "zh": "圣卡洛斯德巴里洛切",
        "ja": "サンカルロスデバリローチェ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -41.1334421,
        "lng": -71.3098425
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -71.5881237,
        -41.206243,
        -71.1373764,
        -41.0370632
      ]
    },
    {
      "id": 4558,
      "name": "Otaru",
      "names": {
        "zh": "小樽",
        "ja": "小樽"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.189054,
        "lng": 140.996744
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.892841,
        43.152111,
        141.071264,
        43.226191
      ]
    },
    {
      "id": 4559,
      "name": "Kristiansand",
      "names": {
        "zh": "克里斯蒂安桑"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.14615,
        "lng": 7.9957333
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.8357333,
        57.98615,
        8.1557333,
        58.30615
      ]
    },
    {
      "id": 4560,
      "name": "Alphen aan den Rijn",
      "names": {
        "es": "Alfen aan den rijn",
        "fr": "Alphan aan den rijn"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.129542,
        "lng": 4.658169
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.617918,
        52.094637,
        4.724518,
        52.167504
      ]
    },
    {
      "id": 4561,
      "name": "Monroe",
      "names": {
        "zh": "梦露",
        "ja": "モンロー",
        "th": "มอนโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2722313,
        "lng": -90.1792484
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.3730499,
        38.0879113,
        -89.896736,
        38.521759
      ]
    },
    {
      "id": 4562,
      "name": "Esteban Echeverria",
      "names": {
        "ja": "エスベンエハビリア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.5881344,
        "lng": -57.9859004
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -57.9978267,
        -35.5932611,
        -57.9801538,
        -35.5812315
      ]
    },
    {
      "id": 4563,
      "name": "Higashiomi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.117661,
        "lng": 136.195492
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.175492,
        35.097661,
        136.215492,
        35.137661
      ]
    },
    {
      "id": 4564,
      "name": "Santa Lucia",
      "names": {
        "es": "Santa Lucía",
        "zh": "圣卢西亚",
        "ja": "サンタルシア",
        "th": "ซานตาลูเซีย"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 13.8250489,
        "lng": -60.975036
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -61.2855618,
        13.5060753,
        -60.6640451,
        14.2725
      ]
    },
    {
      "id": 4565,
      "name": "Utica",
      "names": {
        "zh": "尤蒂卡",
        "ja": "ユーティカ",
        "th": "ยูทิกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.1009031,
        "lng": -75.2326641
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.295407,
        43.064424,
        -75.161034,
        43.132204
      ]
    },
    {
      "id": 4566,
      "name": "Carora",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.169534,
        "lng": -70.0728483
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.2328483,
        10.009534,
        -69.9128483,
        10.329534
      ]
    },
    {
      "id": 4567,
      "name": "Laoag",
      "names": {
        "zh": "拉瓦格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 18.1973235,
        "lng": 120.5935433
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.4335433,
        18.0373235,
        120.7535433,
        18.3573235
      ]
    },
    {
      "id": 4568,
      "name": "Tangjia",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 20.842247,
        "lng": 109.844299
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.804299,
        20.802247,
        109.884299,
        20.882247
      ]
    },
    {
      "id": 4569,
      "name": "Champdani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.79778,
        "lng": 88.36583
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.34583,
        22.77778,
        88.38583,
        22.81778
      ]
    },
    {
      "id": 4570,
      "name": "Oshu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.144275,
        "lng": 141.1392382
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.748919,
        38.985296,
        141.406686,
        39.318698
      ]
    },
    {
      "id": 4571,
      "name": "Remscheid",
      "names": {
        "zh": "雷姆沙伊德",
        "ja": "レムシャイト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.180907,
        "lng": 7.180644
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.131224,
        51.143277,
        7.309181,
        51.225376
      ]
    },
    {
      "id": 4572,
      "name": "Habikino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.55276,
        "lng": 135.59097
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.57097,
        34.53276,
        135.61097,
        34.57276
      ]
    },
    {
      "id": 4573,
      "name": "Kamyshin",
      "names": {
        "zh": "卡梅申"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.0808422,
        "lng": 45.4048003
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        45.2876632,
        49.993251,
        45.5028237,
        50.1380275
      ]
    },
    {
      "id": 4574,
      "name": "Campo Largo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.4597726,
        "lng": -49.5270851
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.8534181,
        -25.5541083,
        -49.3707355,
        -24.9781472
      ]
    },
    {
      "id": 4575,
      "name": "Dolgoprudnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.921689,
        "lng": 37.516969
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.504444,
        55.911388,
        37.533222,
        55.930916
      ]
    },
    {
      "id": 4576,
      "name": "Shkoder",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 42.0681371,
        "lng": 19.5121437
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        19.3767731,
        42.0333498,
        19.5379692,
        42.0939209
      ]
    },
    {
      "id": 4577,
      "name": "Jena",
      "names": {
        "zh": "耶拿",
        "ja": "イエナ",
        "th": "เจ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9281717,
        "lng": 11.5879359
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.4989589,
        50.856077,
        11.6729913,
        50.988898
      ]
    },
    {
      "id": 4578,
      "name": "Subic",
      "names": {
        "zh": "苏比克",
        "ja": "スービック"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8774796,
        "lng": 120.2344695
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.1484236,
        14.7150598,
        120.4491617,
        14.996692
      ]
    },
    {
      "id": 4579,
      "name": "Santana",
      "names": {
        "zh": "桑塔纳",
        "ja": "サンタナ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.979217,
        "lng": -44.05064
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.2630277,
        -13.3194945,
        -43.6319445,
        -12.8414239
      ]
    },
    {
      "id": 4580,
      "name": "Olmaliq",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.84472,
        "lng": 69.59833
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        69.57833,
        40.82472,
        69.61833,
        40.86472
      ]
    },
    {
      "id": 4581,
      "name": "Kawit",
      "names": {
        "zh": "卡维特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.44426,
        "lng": 120.90164
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.88164,
        14.42426,
        120.92164,
        14.46426
      ]
    },
    {
      "id": 4582,
      "name": "Bou Saada",
      "names": {
        "zh": "布苏萨达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.2133123,
        "lng": 4.1809702
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        4.0709154,
        35.1022415,
        4.2937976,
        35.3242521
      ]
    },
    {
      "id": 4583,
      "name": "Jaen",
      "names": {
        "es": "Jaén",
        "zh": "哈恩"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.7728858,
        "lng": -3.7883289
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -3.8731178,
        37.6281116,
        -3.6114971,
        37.9541475
      ]
    },
    {
      "id": 4584,
      "name": "Araruama",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8733203,
        "lng": -42.3428897
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.3824911,
        -22.9100596,
        -42.2826298,
        -22.7933025
      ]
    },
    {
      "id": 4585,
      "name": "Pompano Beach",
      "names": {
        "es": "Peach Pompano",
        "zh": "庞帕诺海滩",
        "id": "Pantai Pompano",
        "ja": "ポンパノビーチ",
        "th": "หาดปอมทาโน",
        "pt": "Praia de pompano"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2378597,
        "lng": -80.1247667
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1956115,
        26.205976,
        -80.081335,
        26.2976628
      ]
    },
    {
      "id": 4586,
      "name": "Baybay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6778023,
        "lng": 124.7977531
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.6466476,
        10.55081,
        124.93188,
        10.8692466
      ]
    },
    {
      "id": 4587,
      "name": "Corumba",
      "names": {
        "es": "Corumbá",
        "zh": "科伦巴",
        "it": "Corumbá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.0016365,
        "lng": -57.6534316
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -58.1696873,
        -20.9498332,
        -55.1370875,
        -17.1663486
      ]
    },
    {
      "id": 4588,
      "name": "Zhukovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.630257,
        "lng": 38.391671
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.361252,
        55.616402,
        38.424805,
        55.647797
      ]
    },
    {
      "id": 4589,
      "name": "West Palm Beach",
      "names": {
        "zh": "西棕榈滩",
        "id": "Pantai Palm Barat",
        "ja": "ウェストパームビーチ",
        "th": "หาดฝ่ามือตะวันตก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.7125151,
        "lng": -80.0624571
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.0674571,
        26.7075151,
        -80.0574571,
        26.7175151
      ]
    },
    {
      "id": 4590,
      "name": "Nsukka",
      "names": {
        "zh": "恩苏卡"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.8561,
        "lng": 7.3927
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.209939,
        6.7211737,
        7.5883222,
        7.0039837
      ]
    },
    {
      "id": 4591,
      "name": "Ichinoseki",
      "names": {
        "zh": "一关"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.9346961,
        "lng": 141.126605
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.763801,
        38.747651,
        141.493921,
        39.162137
      ]
    },
    {
      "id": 4592,
      "name": "Funchal",
      "names": {
        "zh": "丰沙尔",
        "ja": "フンシャル",
        "th": "ฟุงชาล"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 32.6496497,
        "lng": -16.9086783
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -16.9696837,
        30.0287836,
        -15.8536718,
        32.7356523
      ]
    },
    {
      "id": 4593,
      "name": "Nancy",
      "names": {
        "zh": "南希",
        "ja": "ナンシー",
        "th": "แนนซี่"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6937223,
        "lng": 6.1834097
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.134292,
        48.666906,
        6.2126188,
        48.7092349
      ]
    },
    {
      "id": 4594,
      "name": "Colatina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5346732,
        "lng": -40.6287836
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.8834596,
        -19.7156003,
        -40.3682069,
        -19.2284505
      ]
    },
    {
      "id": 4595,
      "name": "Maidstone",
      "names": {
        "zh": "梅德斯通",
        "th": "เมดสโตน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2748258,
        "lng": 0.5231646
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.3781041,
        51.133946,
        0.7964382,
        51.3387431
      ]
    },
    {
      "id": 4596,
      "name": "Asela",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.95,
        "lng": 39.13333
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        39.1159193,
        7.9177613,
        39.1523332,
        7.9897085
      ]
    },
    {
      "id": 4597,
      "name": "Parral",
      "names": {
        "zh": "帕拉尔"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 40.5611743,
        "lng": -81.4970623
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -81.499641,
        40.556822,
        -81.490353,
        40.564653
      ]
    },
    {
      "id": 4598,
      "name": "Terni",
      "names": {
        "zh": "特尔尼",
        "ja": "テルニ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.5641417,
        "lng": 12.6405466
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.5421496,
        42.5003273,
        12.7871883,
        42.676012
      ]
    },
    {
      "id": 4599,
      "name": "Tuzla",
      "names": {
        "zh": "图兹拉",
        "ja": "トゥズラ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8161732,
        "lng": 29.3034194
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        29.2545791,
        40.8027042,
        29.456816,
        40.9806318
      ]
    },
    {
      "id": 4600,
      "name": "Waterloo",
      "names": {
        "zh": "滑铁卢",
        "ja": "ワーテルロー",
        "th": "วอเตอร์ลู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4652699,
        "lng": -80.5222961
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.6260904,
        43.4322663,
        -80.4674214,
        43.5312209
      ]
    },
    {
      "id": 4601,
      "name": "Parintins",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.6344567,
        "lng": -56.7319324
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -57.4290116,
        -3.333,
        -56.389,
        -2.242
      ]
    },
    {
      "id": 4602,
      "name": "Trier",
      "names": {
        "zh": "特里尔",
        "ja": "トリーア",
        "th": "เทรียร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7596208,
        "lng": 6.6441878
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.551792,
        49.6986005,
        6.7471503,
        49.8571154
      ]
    },
    {
      "id": 4603,
      "name": "Terrebonne",
      "names": {
        "zh": "泰勒博恩"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.7081005,
        "lng": -73.6515157
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.8447776,
        45.6687165,
        -73.4870796,
        45.8271638
      ]
    },
    {
      "id": 4604,
      "name": "Namur",
      "names": {
        "zh": "那慕尔",
        "ja": "ナミュール",
        "th": "มูร์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.4665284,
        "lng": 4.8661892
      },
      "country": "Belgium",
      "importance": 1,
      "bbox": [
        4.723053,
        50.3872825,
        4.9843576,
        50.5311017
      ]
    },
    {
      "id": 4605,
      "name": "Changbang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.114633,
        "lng": 121.3217557
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        121.3172142,
        31.1132277,
        121.3267399,
        31.1165622
      ]
    },
    {
      "id": 4606,
      "name": "Pulilan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9022688,
        "lng": 120.8487904
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7980128,
        14.8878277,
        120.8903945,
        14.957009
      ]
    },
    {
      "id": 4607,
      "name": "Murom",
      "names": {
        "zh": "穆罗姆"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.5738,
        "lng": 42.0447
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        41.9628041,
        55.5267882,
        42.0929009,
        55.6003294
      ]
    },
    {
      "id": 4608,
      "name": "Manzini",
      "names": {
        "zh": "曼齐尼"
      },
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.48333,
        "lng": 31.36667
      },
      "country": "Swaziland",
      "importance": 1,
      "bbox": [
        31.34667,
        -26.50333,
        31.38667,
        -26.46333
      ]
    },
    {
      "id": 4609,
      "name": "Tondabayashicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4997399,
        "lng": 135.6020457
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5920457,
        34.4897399,
        135.6120457,
        34.5097399
      ]
    },
    {
      "id": 4610,
      "name": "Everett",
      "names": {
        "zh": "埃弗雷特",
        "ja": "エベレット",
        "th": "เอเวอเร็ตต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.9793437,
        "lng": -122.2127011
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.29397,
        47.878176,
        -121.793978,
        48.0360295
      ]
    },
    {
      "id": 4611,
      "name": "Pingquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.997672,
        "lng": 118.6942488
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        118.6892488,
        40.992672,
        118.6992488,
        41.002672
      ]
    },
    {
      "id": 4612,
      "name": "Kazo",
      "names": {
        "zh": "嘉藏"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1308572,
        "lng": 139.603225
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.52378,
        36.071105,
        139.703025,
        36.2146419
      ]
    },
    {
      "id": 4613,
      "name": "El Centro",
      "names": {
        "ja": "エルセンロ",
        "pt": "El cidro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.792,
        "lng": -115.563051
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -115.595001,
        32.752757,
        -115.518119,
        32.81833
      ]
    },
    {
      "id": 4614,
      "name": "Khardah",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.71861,
        "lng": 88.37806
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4615,
      "name": "Omuta",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.0297805,
        "lng": 130.4432796
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4382796,
        33.0247805,
        130.4482796,
        33.0347805
      ]
    },
    {
      "id": 4616,
      "name": "Villa Mercedes",
      "names": {
        "zh": "梅赛德斯别墅",
        "ja": "ヴィラメルセデス",
        "th": "วิลล่าเมอร์เซเดส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.6745795,
        "lng": -65.4617562
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.5075631,
        -33.7109646,
        -65.4195884,
        -33.6455486
      ]
    },
    {
      "id": 4617,
      "name": "Jawhar",
      "names": {
        "zh": "乔哈尔",
        "de": "Gauhars"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 2.7797296,
        "lng": 45.5042168
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        45.3442168,
        2.6197296,
        45.6642168,
        2.9397296
      ]
    },
    {
      "id": 4618,
      "name": "Winterthur",
      "names": {
        "zh": "温特图尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4991723,
        "lng": 8.7291498
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.6566073,
        47.4497183,
        8.8099397,
        47.5480032
      ]
    },
    {
      "id": 4619,
      "name": "Tuzla",
      "names": {
        "zh": "图兹拉",
        "ja": "トゥズラ"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.5391105,
        "lng": 18.675193
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.515193,
        44.3791105,
        18.835193,
        44.6991105
      ]
    },
    {
      "id": 4620,
      "name": "Tarnow",
      "names": {
        "es": "Tarnów",
        "fr": "Tarnów",
        "zh": "塔尔努夫",
        "it": "Tarnów",
        "th": "ทานูฟ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0123784,
        "lng": 20.9880739
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.8936142,
        49.9855419,
        21.062345,
        50.066589
      ]
    },
    {
      "id": 4621,
      "name": "Gafsa",
      "names": {
        "zh": "加夫萨"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 34.4224374,
        "lng": 8.7843862
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        8.6243862,
        34.2624374,
        8.9443862,
        34.5824374
      ]
    },
    {
      "id": 4622,
      "name": "Ash Shaykh `Uthman",
      "names": {
        "es": "Ash Shaykh 'Uthman",
        "zh": "Ash shaykh`uthman",
        "de": "Ash shaykh `athman",
        "ja": "灰Shaykh `Uthman",
        "pt": "Cinza Shaykh `Uthman"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 12.878377,
        "lng": 45.015554
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.9794573,
        12.838706,
        45.0505637,
        12.9242026
      ]
    },
    {
      "id": 4623,
      "name": "Alkmaar",
      "names": {
        "zh": "阿尔克马尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6381293,
        "lng": 4.7406979
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7356979,
        52.6331293,
        4.7456979,
        52.6431293
      ]
    },
    {
      "id": 4624,
      "name": "Nguru",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.875615,
        "lng": 10.4540091
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        10.2391478,
        12.8152099,
        10.6078982,
        13.1544353
      ]
    },
    {
      "id": 4625,
      "name": "Santa Fe",
      "names": {
        "zh": "圣达菲",
        "ja": "サンタフェ",
        "th": "ซานตาเฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.6876096,
        "lng": -105.938456
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -106.1121583,
        35.5880434,
        -105.8941768,
        35.7546236
      ]
    },
    {
      "id": 4626,
      "name": "Bandar-e Anzali",
      "names": {
        "ja": "バンダル=アンザリ"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.4716356,
        "lng": 49.4685904
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.3873844,
        37.4380932,
        49.5540705,
        37.4950911
      ]
    },
    {
      "id": 4627,
      "name": "Khushab",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2903666,
        "lng": 72.3515792
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.1915792,
        32.1303666,
        72.5115792,
        32.4503666
      ]
    },
    {
      "id": 4628,
      "name": "Downey",
      "names": {
        "zh": "唐尼",
        "ja": "ダウニー",
        "th": "ดาวนีย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9404441,
        "lng": -118.1292846
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1704328,
        33.9023817,
        -118.090258,
        33.9729911
      ]
    },
    {
      "id": 4629,
      "name": "Spring Hill",
      "names": {
        "es": "Cuesta",
        "fr": "Colline de printemps",
        "zh": "春山",
        "de": "Springhügel",
        "it": "Collina",
        "id": "Pegas",
        "ja": "スプリングヒル",
        "th": "ฤดูใบไม้ผลิ",
        "pt": "Colina de mola"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.483576,
        "lng": -82.5338633376176
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.63986,
        28.4335662,
        -82.4585906,
        28.533947
      ]
    },
    {
      "id": 4630,
      "name": "Basingstoke",
      "names": {
        "zh": "贝辛斯托克"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2684238,
        "lng": -1.0885594
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.0935594,
        51.2634238,
        -1.0835594,
        51.2734238
      ]
    },
    {
      "id": 4631,
      "name": "Catalao",
      "names": {
        "de": "Catalão",
        "pt": "Catalão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.1702546,
        "lng": -47.9447082
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.1724204,
        -18.5,
        -47.2642553,
        -17.4568031
      ]
    },
    {
      "id": 4632,
      "name": "Lowell",
      "names": {
        "zh": "洛厄尔",
        "ja": "ローウェル",
        "th": "โลเวลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6414437,
        "lng": -71.3085329
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.3824786,
        42.6064942,
        -71.271188,
        42.6665157
      ]
    },
    {
      "id": 4633,
      "name": "Sonsonate",
      "names": {
        "zh": "松索纳特"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.71889,
        "lng": -89.72417
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -89.74417,
        13.69889,
        -89.70417,
        13.73889
      ]
    },
    {
      "id": 4634,
      "name": "Ahuachapan",
      "names": {
        "zh": "阿瓦查潘"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.9234192,
        "lng": -89.8462907
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -89.9715227,
        13.8799288,
        -89.755759,
        14.0496034
      ]
    },
    {
      "id": 4635,
      "name": "Centennial",
      "names": {
        "es": "Centenario",
        "fr": "Centenaire",
        "zh": "百年纪念",
        "ja": "センテニアル",
        "th": "หนึ่งร้อยปี",
        "pt": "Centenário"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.579155,
        "lng": -104.8769227
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.988394,
        39.565994,
        -104.726199,
        39.6384232
      ]
    },
    {
      "id": 4636,
      "name": "Puerto Barrios",
      "names": {
        "zh": "波多巴斯巴黎斯",
        "de": "Puerto-Barrios",
        "ja": "プエルトバリオス"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.7275154,
        "lng": -88.5952506
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -88.7459226,
        15.5017651,
        -88.2134425,
        16.0723558
      ]
    },
    {
      "id": 4637,
      "name": "Fasa",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 28.9366395,
        "lng": 53.6510677
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.5841846,
        28.9177436,
        53.6696769,
        28.9874209
      ]
    },
    {
      "id": 4638,
      "name": "As Salamiyah",
      "names": {
        "es": "Como salamiyah",
        "fr": "Comme Salamiyah",
        "zh": "作为萨拉梅耶",
        "de": "Als Salamiyah",
        "it": "Come Salamiyah",
        "id": "Sebagai salamiyah",
        "ja": "サラミヤとして",
        "th": "เป็นซาลามิยาห์",
        "pt": "Como salamiyah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.01127,
        "lng": 37.05324
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.03324,
        34.99127,
        37.07324,
        35.03127
      ]
    },
    {
      "id": 4639,
      "name": "Yessentuki",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.0470042,
        "lng": 42.8576575
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.7954434,
        44.0039487,
        42.9583984,
        44.0713051
      ]
    },
    {
      "id": 4640,
      "name": "Elgin",
      "names": {
        "zh": "埃尔金",
        "ja": "エルジン",
        "th": "เอลกิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.03726,
        "lng": -88.2810994
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.4545654,
        41.971373,
        -88.222773,
        42.103581
      ]
    },
    {
      "id": 4641,
      "name": "Shinyanga",
      "names": {
        "zh": "希尼安加"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -3.6562049,
        "lng": 33.4271153
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        33.2671153,
        -3.8162049,
        33.5871153,
        -3.4962049
      ]
    },
    {
      "id": 4642,
      "name": "Kiffa",
      "names": {
        "zh": "基法"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.61659,
        "lng": -11.40453
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -11.42453,
        16.59659,
        -11.38453,
        16.63659
      ]
    },
    {
      "id": 4643,
      "name": "Manzanillo",
      "names": {
        "zh": "马惹尼诺",
        "de": "In manzanillo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0506256,
        "lng": -104.3159957
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.4759957,
        18.8906256,
        -104.1559957,
        19.2106256
      ]
    },
    {
      "id": 4644,
      "name": "Nikopol",
      "names": {
        "zh": "尼科波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.5692061,
        "lng": 34.3917272
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.2845609,
        47.5467412,
        34.4657153,
        47.6057135
      ]
    },
    {
      "id": 4645,
      "name": "Dali",
      "names": {
        "zh": "大理",
        "ja": "ダリ",
        "th": "ต้าหลี่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7983603,
        "lng": 109.9366142
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.7766142,
        34.6383603,
        110.0966142,
        34.9583603
      ]
    },
    {
      "id": 4646,
      "name": "Yenangyaung",
      "names": {
        "zh": "仁安羌"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.4608216,
        "lng": 94.872961
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        94.7778214,
        20.2935636,
        95.2062849,
        20.6317578
      ]
    },
    {
      "id": 4647,
      "name": "Quibor",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.9299814,
        "lng": -69.6195814
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.7795814,
        9.7699814,
        -69.4595814,
        10.0899814
      ]
    },
    {
      "id": 4648,
      "name": "Ourinhos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9777918,
        "lng": -49.868204
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.9730582,
        -23.074138,
        -49.7630765,
        -22.8370421
      ]
    },
    {
      "id": 4649,
      "name": "Borazjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.2666,
        "lng": 51.2159
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.1959,
        29.2466,
        51.2359,
        29.2866
      ]
    },
    {
      "id": 4650,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9357576,
        "lng": -122.347748
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.441484,
        37.88364,
        -122.2434491,
        38.0285668
      ]
    },
    {
      "id": 4651,
      "name": "Socopo",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.23062,
        "lng": -70.82198
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.84198,
        8.21062,
        -70.80198,
        8.25062
      ]
    },
    {
      "id": 4652,
      "name": "Mascara",
      "names": {
        "es": "Rímel",
        "zh": "睫毛膏",
        "de": "Wimperntusche",
        "id": "Maskara",
        "ja": "マスカラ",
        "th": "มาสคาร่า",
        "pt": "Rímel"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.397838500000006,
        "lng": 0.24301949927219488
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -0.521862,
        35.011227,
        0.902122,
        35.784794
      ]
    },
    {
      "id": 4653,
      "name": "Genhe",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 50.7811626,
        "lng": 121.5251639
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.3651639,
        50.6211626,
        121.6851639,
        50.9411626
      ]
    },
    {
      "id": 4654,
      "name": "Montego Bay",
      "names": {
        "zh": "蒙特哥湾",
        "id": "Teluk Montego",
        "ja": "モンテゴベイ",
        "th": "อ่าวมอนเตโก",
        "pt": "Baía de Montego"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.4724603,
        "lng": -77.9217357
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -77.9274209,
        18.4630457,
        -77.9119878,
        18.4790101
      ]
    },
    {
      "id": 4655,
      "name": "Jeonghae",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.51083,
        "lng": 126.8432
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4656,
      "name": "Msaken",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 35.72917,
        "lng": 10.58082
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 4657,
      "name": "Baneh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.9975,
        "lng": 45.8853
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.8653,
        35.9775,
        45.9053,
        36.0175
      ]
    },
    {
      "id": 4658,
      "name": "Shirayamamachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.43333,
        "lng": 136.63333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4659,
      "name": "Bukoba",
      "names": {
        "zh": "布科巴"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -1.3311508,
        "lng": 31.8125605
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        31.6525605,
        -1.4911508,
        31.9725605,
        -1.1711508
      ]
    },
    {
      "id": 4660,
      "name": "Tinaquillo",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.91861,
        "lng": -68.30472
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -68.32472,
        9.89861,
        -68.28472,
        9.93861
      ]
    },
    {
      "id": 4661,
      "name": "Broken Arrow",
      "names": {
        "es": "Flecha rota",
        "fr": "Flèche cassée",
        "zh": "破碎的箭头",
        "de": "Gebrochener Pfeil",
        "it": "Freccia spezzata",
        "id": "Panah",
        "ja": "折れた矢",
        "th": "ลูกศรแตก",
        "pt": "Flecha quebrada"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0525993,
        "lng": -95.7908195
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.8683568,
        35.918,
        -95.546358,
        36.1191379
      ]
    },
    {
      "id": 4662,
      "name": "Xishancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.20417,
        "lng": 115.63338
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.61338,
        24.18417,
        115.65338,
        24.22417
      ]
    },
    {
      "id": 4663,
      "name": "Marugame",
      "names": {
        "zh": "丸龟"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2888128,
        "lng": 133.7982421
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.6010204,
        34.2011176,
        133.9052536,
        34.4489831
      ]
    },
    {
      "id": 4664,
      "name": "Bangaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0434655,
        "lng": 88.8317765
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.6717765,
        22.8834655,
        88.9917765,
        23.2034655
      ]
    },
    {
      "id": 4665,
      "name": "Milton",
      "names": {
        "zh": "米尔顿",
        "ja": "ミルトン",
        "th": "มิลตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.513671,
        "lng": -79.882817
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.1611331,
        43.4172723,
        -79.7248295,
        43.628836
      ]
    },
    {
      "id": 4666,
      "name": "Yoju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.2982901,
        "lng": 127.637033
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.3982262,
        37.1350555,
        127.7684117,
        37.4395356
      ]
    },
    {
      "id": 4667,
      "name": "Chorzow",
      "names": {
        "es": "Chorzów",
        "zh": "霍茹夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2978791,
        "lng": 18.9544466
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.9189645,
        50.2390968,
        19.0068304,
        50.3378618
      ]
    },
    {
      "id": 4668,
      "name": "Tacurong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6884368,
        "lng": 124.6786576
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5186576,
        6.5284368,
        124.8386576,
        6.8484368
      ]
    },
    {
      "id": 4669,
      "name": "Miami Gardens",
      "names": {
        "es": "Jardines de Miami",
        "fr": "Jardins de Miami",
        "zh": "迈阿密花园",
        "ja": "マイアミガーデンズ",
        "th": "สวนไมอามี",
        "pt": "Jardins de Miami"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9420377,
        "lng": -80.2456045
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2932493,
        25.9112169,
        -80.1975216,
        25.9729666
      ]
    },
    {
      "id": 4670,
      "name": "Pisco",
      "names": {
        "zh": "皮斯科",
        "ja": "ピスコ"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.7099813,
        "lng": -76.2029793
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -76.2218945,
        -13.7282325,
        -76.1511445,
        -13.6793634
      ]
    },
    {
      "id": 4671,
      "name": "Kecskemet",
      "names": {
        "zh": "凯奇凯梅特",
        "de": "Kecskemét"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9073883,
        "lng": 19.6917206
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        19.5068013,
        46.7699196,
        19.8517741,
        47.0012539
      ]
    },
    {
      "id": 4672,
      "name": "Dera Ismail Khan",
      "names": {
        "de": "Era Ismail Khan"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.827527,
        "lng": 70.9090919
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        70.7490919,
        31.667527,
        71.0690919,
        31.987527
      ]
    },
    {
      "id": 4673,
      "name": "Labe",
      "names": {
        "zh": "拉贝"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.3230033,
        "lng": -12.282253
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -12.442253,
        11.1630033,
        -12.122253,
        11.4830033
      ]
    },
    {
      "id": 4674,
      "name": "Altamira",
      "names": {
        "zh": "阿尔塔米拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.204065,
        "lng": -52.209961
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -55.623871,
        -9.645,
        -51.6475378,
        -2.9887169
      ]
    },
    {
      "id": 4675,
      "name": "Cavite City",
      "names": {
        "fr": "Ville cavite",
        "de": "Kavitstadt",
        "it": "Città Cavite",
        "ja": "キャビテシティ",
        "pt": "Cidade Cavita"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.484834,
        "lng": 120.895002
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.86487,
        14.419826,
        120.919586,
        14.501278
      ]
    },
    {
      "id": 4676,
      "name": "Drammen",
      "names": {
        "zh": "德拉门",
        "th": "ดรัมเมน"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7436802,
        "lng": 10.2043644
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.0443644,
        59.5836802,
        10.3643644,
        59.9036802
      ]
    },
    {
      "id": 4677,
      "name": "Taitung",
      "names": {
        "zh": "台东",
        "ja": "台東"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.7560447,
        "lng": 121.1456538
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        121.0259889,
        22.6838885,
        121.2005083,
        22.8196443
      ]
    },
    {
      "id": 4678,
      "name": "Itabira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6190169,
        "lng": -43.226248
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.5431341,
        -19.787995,
        -43.0551341,
        -19.4189949
      ]
    },
    {
      "id": 4679,
      "name": "Al Fqih Ben Calah",
      "names": {
        "id": "Al fqih ben cala",
        "th": "อัล Fqih Ben Calah",
        "pt": "Al fqih ben oclah"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.578392,
        "lng": -7.605072
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.605243,
        33.578128,
        -7.604902,
        33.578656
      ]
    },
    {
      "id": 4680,
      "name": "Naujan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.32328,
        "lng": 121.30282
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.28282,
        13.30328,
        121.32282,
        13.34328
      ]
    },
    {
      "id": 4681,
      "name": "Bend",
      "names": {
        "es": "Curva",
        "fr": "Pliez",
        "zh": "弯曲",
        "de": "Kurve",
        "it": "Piegare",
        "ja": "ベンド",
        "th": "โค้ง",
        "pt": "Dobrar"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0581728,
        "lng": -121.3153096
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.3824328,
        43.999063,
        -121.2433494,
        44.1237516
      ]
    },
    {
      "id": 4682,
      "name": "Quezon",
      "names": {
        "zh": "奎松",
        "ja": "ケソン",
        "th": "เควซอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7295754,
        "lng": 125.1018275
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.0198583,
        7.5831035,
        125.29477,
        7.8451792
      ]
    },
    {
      "id": 4683,
      "name": "Kandi",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 11.1311029,
        "lng": 2.9322321
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        2.7722321,
        10.9711029,
        3.0922321,
        11.2911029
      ]
    },
    {
      "id": 4684,
      "name": "Burlington",
      "names": {
        "zh": "伯灵顿",
        "ja": "バーリントン",
        "th": "เบอร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.4761601,
        "lng": -73.212906
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.2770437,
        44.4459066,
        -73.176125,
        44.5398847
      ]
    },
    {
      "id": 4685,
      "name": "Sandacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8846874,
        "lng": 135.2256314
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.2156314,
        34.8746874,
        135.2356314,
        34.8946874
      ]
    },
    {
      "id": 4686,
      "name": "Uitenhage",
      "names": {
        "zh": "埃滕哈赫"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.762129,
        "lng": 25.405954
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        25.370985,
        -33.817215,
        25.499922,
        -33.730904
      ]
    },
    {
      "id": 4687,
      "name": "Aguachica",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.309416,
        "lng": -73.603699
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7756738,
        7.9906399,
        -73.4568336,
        8.5058841
      ]
    },
    {
      "id": 4688,
      "name": "Glan",
      "names": {
        "zh": "格兰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 5.8225657,
        "lng": 125.2047332
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.1647332,
        5.7825657,
        125.2447332,
        5.8625657
      ]
    },
    {
      "id": 4689,
      "name": "Carmona",
      "names": {
        "zh": "卡莫纳",
        "ja": "カルモナ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3134763,
        "lng": 121.0573969
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0025807,
        14.257145,
        121.0776824,
        14.3291295
      ]
    },
    {
      "id": 4690,
      "name": "Bayugan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.7145642,
        "lng": 125.7481892
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.649616,
        8.6121505,
        125.886062,
        8.95139
      ]
    },
    {
      "id": 4691,
      "name": "Caen",
      "names": {
        "zh": "卡昂",
        "ja": "カーン",
        "th": "แคน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1813403,
        "lng": -0.3635615
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.413777,
        49.1530145,
        -0.3307285,
        49.2162655
      ]
    },
    {
      "id": 4692,
      "name": "Medenine",
      "names": {
        "zh": "梅德宁"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.3434014,
        "lng": 10.4910703
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.4510703,
        33.3034014,
        10.5310703,
        33.3834014
      ]
    },
    {
      "id": 4693,
      "name": "Jurupa Valley",
      "names": {
        "es": "Valle de jurupa",
        "fr": "Vallée de Jurupa",
        "zh": "Jurupa山谷",
        "de": "Jurupa-Tal",
        "it": "Valle di Jurupa",
        "id": "Lembah Jurupa",
        "th": "หุบเขาจูราปะ",
        "pt": "Vale do Jurupa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.999163,
        "lng": -117.470532
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.552335,
        33.949606,
        -117.367212,
        34.034313
      ]
    },
    {
      "id": 4694,
      "name": "Kasserine",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 35.1673086,
        "lng": 8.8346884
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        8.8346384,
        35.1672586,
        8.8347384,
        35.1673586
      ]
    },
    {
      "id": 4695,
      "name": "Taoyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.3747999,
        "lng": 103.8619108
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.7580721,
        35.3026139,
        103.9007293,
        35.4966393
      ]
    },
    {
      "id": 4696,
      "name": "Gualeguaychu",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0078585,
        "lng": -58.5103466
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.6243513,
        -33.074417,
        -58.4316043,
        -32.9738618
      ]
    },
    {
      "id": 4697,
      "name": "Beja",
      "names": {
        "zh": "贝雅"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.7236755,
        "lng": 9.185382
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        9.025382,
        36.5636755,
        9.345382,
        36.8836755
      ]
    },
    {
      "id": 4698,
      "name": "Espejo",
      "names": {
        "zh": "埃斯佩霍"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -0.305301,
        "lng": -78.5715139
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -78.5815139,
        -0.315301,
        -78.5615139,
        -0.295301
      ]
    },
    {
      "id": 4699,
      "name": "Botosani",
      "names": {
        "zh": "博托沙尼"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.7450706,
        "lng": 26.6599858
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        26.6105684,
        47.7037494,
        26.741471,
        47.802869
      ]
    },
    {
      "id": 4700,
      "name": "Sandy Springs",
      "names": {
        "zh": "桑迪泉",
        "de": "Sandstrings",
        "it": "Sorgenti sabbiose",
        "id": "Pegas berpasir",
        "ja": "サンディスプリングス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9242688,
        "lng": -84.3785379
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.447543,
        33.876946,
        -84.2582129,
        34.010137
      ]
    },
    {
      "id": 4701,
      "name": "Talisay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7372649,
        "lng": 122.9673325
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.837746,
        10.6371665,
        123.2588101,
        10.7838488
      ]
    },
    {
      "id": 4702,
      "name": "Lingayen",
      "names": {
        "zh": "林加延"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0206363,
        "lng": 120.2307093
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.1907093,
        15.9806363,
        120.2707093,
        16.0606363
      ]
    },
    {
      "id": 4703,
      "name": "Yongju",
      "names": {
        "zh": "荣州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.5406,
        "lng": 128.11304
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.09304,
        35.5206,
        128.13304,
        35.5606
      ]
    },
    {
      "id": 4704,
      "name": "Gresham",
      "names": {
        "zh": "格雷沙姆",
        "ja": "グレシャム",
        "th": "เกรชัม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.4978623,
        "lng": -122.4329883
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.4980699,
        45.46094,
        -122.3675829,
        45.5608795
      ]
    },
    {
      "id": 4705,
      "name": "Adigrat",
      "names": {
        "zh": "阿迪格拉特"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 14.277,
        "lng": 39.462
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        39.442,
        14.257,
        39.482,
        14.297
      ]
    },
    {
      "id": 4706,
      "name": "Maxixe",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -23.85972,
        "lng": 35.34722
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        35.32722,
        -23.87972,
        35.36722,
        -23.83972
      ]
    },
    {
      "id": 4707,
      "name": "Ban Mangkon",
      "names": {
        "es": "Prohibir mangkon",
        "fr": "Bangkon",
        "zh": "禁止漫步",
        "ja": "禁止マンゴン",
        "th": "บ้านมังกร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6067768,
        "lng": 100.6561909
      },
      "country": "Thailand",
      "importance": 3,
      "bbox": [
        100.6461909,
        13.5967768,
        100.6661909,
        13.6167768
      ]
    },
    {
      "id": 4708,
      "name": "Soubre",
      "names": {
        "zh": "苏布雷"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.7852673,
        "lng": -6.5928329
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -6.7528329,
        5.6252673,
        -6.4328329,
        5.9452673
      ]
    },
    {
      "id": 4709,
      "name": "Lewisville",
      "names": {
        "th": "ลูอิสวิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.046233,
        "lng": -96.994174
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.045663,
        32.985124,
        -96.865866,
        33.1003548
      ]
    },
    {
      "id": 4710,
      "name": "Tuxpam de Rodriguez Cano",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9518378,
        "lng": -97.4045371
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.5645371,
        20.7918378,
        -97.2445371,
        21.1118378
      ]
    },
    {
      "id": 4711,
      "name": "Ipiales",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 0.8236934,
        "lng": -77.6349296
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.6978979,
        0.3609371,
        -77.087996,
        0.903588
      ]
    },
    {
      "id": 4712,
      "name": "Mineshita",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.17188,
        "lng": 138.92768
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.90768,
        35.15188,
        138.94768,
        35.19188
      ]
    },
    {
      "id": 4713,
      "name": "Hillsboro",
      "names": {
        "zh": "希尔斯伯勒",
        "ja": "ヒルズボロ",
        "th": "โบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5228939,
        "lng": -122.989827
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.0118142,
        45.4763623,
        -122.8595375,
        45.5758021
      ]
    },
    {
      "id": 4714,
      "name": "Worthing",
      "names": {
        "zh": "沃辛",
        "ja": "ワージング",
        "th": "เวอร์ทิง"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8115402,
        "lng": -0.3699697
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.4465567,
        50.8017364,
        -0.3302629,
        50.8632589
      ]
    },
    {
      "id": 4715,
      "name": "San Buenaventura",
      "names": {
        "zh": "圣布纳留村",
        "ja": "サンブナベントゥーラ",
        "th": "ซานบัวเวนทูรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.3435092,
        "lng": -119.29560423163717
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.2972095,
        34.3424966,
        -119.2941097,
        34.3446773
      ]
    },
    {
      "id": 4716,
      "name": "Novoshakhtinsk",
      "names": {
        "th": "โนโวชาคทินสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.753771,
        "lng": 39.926448
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.871056,
        47.719833,
        40.005806,
        47.769695
      ]
    },
    {
      "id": 4717,
      "name": "Sao Mateus",
      "names": {
        "zh": "圣马特斯",
        "ja": "サンマテウス",
        "pt": "São Mateus"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.7146049,
        "lng": -39.8539499
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.4056406,
        -19.1488575,
        -39.7190224,
        -18.4441081
      ]
    },
    {
      "id": 4718,
      "name": "Balombo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.354158,
        "lng": 14.7722982
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.7594994,
        -12.3629177,
        14.7942885,
        -12.344679
      ]
    },
    {
      "id": 4719,
      "name": "Crawley",
      "names": {
        "zh": "克劳利",
        "ja": "クローリー",
        "th": "คราว"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.1120247,
        "lng": -0.1865249
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.1915249,
        51.1070247,
        -0.1815249,
        51.1170247
      ]
    },
    {
      "id": 4720,
      "name": "Ikeda",
      "names": {
        "zh": "池田",
        "ja": "池田",
        "th": "อิเคดะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.819371,
        "lng": 135.433332
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.415991,
        34.791774,
        135.460808,
        34.877125
      ]
    },
    {
      "id": 4721,
      "name": "Matruh",
      "names": {
        "zh": "马特鲁"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.9387816,
        "lng": 26.4894728
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        23.8894728,
        27.3387816,
        29.0894728,
        32.5387816
      ]
    },
    {
      "id": 4722,
      "name": "St. John's",
      "names": {
        "zh": "圣约翰的",
        "id": "St. John",
        "ja": "セントジョンズ",
        "th": "เซนต์จอห์น"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 47.5614705,
        "lng": -52.7126162
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -53.0378593,
        47.3273733,
        -52.6127655,
        47.6338515
      ]
    },
    {
      "id": 4723,
      "name": "Jacksonville",
      "names": {
        "zh": "杰克逊维尔",
        "ja": "ジャクソンビル",
        "th": "แจ็กสันวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7494749,
        "lng": -77.4208221
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.479674,
        34.638319,
        -77.307864,
        34.815329
      ]
    },
    {
      "id": 4724,
      "name": "Salford",
      "names": {
        "zh": "索尔福德",
        "ja": "ソルフォード",
        "th": "ฟอบส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4877463,
        "lng": -2.2891921
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4897303,
        53.4158915,
        -2.2451065,
        53.5421391
      ]
    },
    {
      "id": 4725,
      "name": "Samsun",
      "names": {
        "zh": "萨姆松"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2946149,
        "lng": 36.3320596
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.1720596,
        41.1346149,
        36.4920596,
        41.4546149
      ]
    },
    {
      "id": 4726,
      "name": "Pottstown",
      "names": {
        "zh": "波茨敦"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.250388,
        "lng": -75.644481
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.678463,
        40.232924,
        -75.610458,
        40.267808
      ]
    },
    {
      "id": 4727,
      "name": "Cawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.5285471,
        "lng": 123.1024152
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0824152,
        11.5085471,
        123.1224152,
        11.5485471
      ]
    },
    {
      "id": 4728,
      "name": "Tadpatri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.906044,
        "lng": 78.012147
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.96547,
        14.892194,
        78.03239,
        14.923318
      ]
    },
    {
      "id": 4729,
      "name": "Moncton",
      "names": {
        "zh": "蒙克顿",
        "th": "มองก์ตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.0985679,
        "lng": -64.8004265
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -64.9153724,
        46.0492095,
        -64.6858223,
        46.1774541
      ]
    },
    {
      "id": 4730,
      "name": "Yasuj",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.6681785,
        "lng": 51.581916
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        51.5351902,
        30.6442355,
        51.6162315,
        30.6947261
      ]
    },
    {
      "id": 4731,
      "name": "Tipitapa",
      "names": {
        "zh": "蒂皮塔帕"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.19732,
        "lng": -86.09706
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.11706,
        12.17732,
        -86.07706,
        12.21732
      ]
    },
    {
      "id": 4732,
      "name": "Alto Hospicio",
      "names": {
        "ja": "アルトホスピオ",
        "th": "อัลโตโฮสเตส"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -20.2527756,
        "lng": -70.0917245
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.0917745,
        -20.2528256,
        -70.0916745,
        -20.2527256
      ]
    },
    {
      "id": 4733,
      "name": "Islamabad",
      "names": {
        "zh": "伊斯兰堡",
        "ja": "イスラマバード",
        "th": "กรุงอิสลามาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 33.7368773,
        "lng": 75.1455138
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.9855138,
        33.5768773,
        75.3055138,
        33.8968773
      ]
    },
    {
      "id": 4734,
      "name": "Maramag",
      "names": {
        "zh": "马拉马格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7611218,
        "lng": 125.0047932
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8404885,
        7.6970476,
        125.0920272,
        7.9564526
      ]
    },
    {
      "id": 4735,
      "name": "Jalpaiguri",
      "names": {
        "zh": "杰尔拜古里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.5186255,
        "lng": 88.7240129
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.7190129,
        26.5136255,
        88.7290129,
        26.5236255
      ]
    },
    {
      "id": 4736,
      "name": "Birnin Kebbi",
      "names": {
        "ja": "ビルニンケブビ"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 12.450808,
        "lng": 4.203131
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        4.152213,
        12.418225,
        4.246114,
        12.477225
      ]
    },
    {
      "id": 4737,
      "name": "Sivas",
      "names": {
        "zh": "锡瓦斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7503572,
        "lng": 37.0145185
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.5171219,
        39.3171619,
        37.4956511,
        40.1771684
      ]
    },
    {
      "id": 4738,
      "name": "Siauliai",
      "names": {
        "zh": "希奥利艾"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9285553,
        "lng": 23.32069765
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.3204022,
        55.9283907,
        23.3209931,
        55.9287199
      ]
    },
    {
      "id": 4739,
      "name": "Seversk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.603161,
        "lng": 84.881012
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        84.7653642,
        56.5602307,
        84.9740367,
        56.7367038
      ]
    },
    {
      "id": 4740,
      "name": "El Limon",
      "names": {
        "de": "El-Limoner"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2925851,
        "lng": -67.6298607
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.7898607,
        10.1325851,
        -67.4698607,
        10.4525851
      ]
    },
    {
      "id": 4741,
      "name": "Inglewood",
      "names": {
        "zh": "英格伍德",
        "ja": "イングルウッド",
        "th": "อิงเกิลวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9562003,
        "lng": -118.353132
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.3788417,
        33.925177,
        -118.317747,
        33.98297
      ]
    },
    {
      "id": 4742,
      "name": "Reus",
      "names": {
        "zh": "雷乌斯",
        "ja": "レウス",
        "th": "เรอุส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.1555564,
        "lng": 1.1076133
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.0575335,
        41.1023847,
        1.1913489,
        41.190213
      ]
    },
    {
      "id": 4743,
      "name": "Sarh",
      "names": {
        "zh": "萨尔"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.1382,
        "lng": 18.3823833
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        18.3723833,
        9.1282,
        18.3923833,
        9.1482
      ]
    },
    {
      "id": 4744,
      "name": "An Nuhud",
      "names": {
        "es": "Un nuhud",
        "fr": "Un nuhud",
        "zh": "nuhud",
        "de": "Ein Nuhud",
        "it": "Un nuhud",
        "id": "Sebuah nuhud",
        "ja": "ヌムド",
        "th": "nuhud",
        "pt": "Um nuhud"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.692159,
        "lng": 28.422103
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        28.262103,
        12.532159,
        28.582103,
        12.852159
      ]
    },
    {
      "id": 4745,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6126025,
        "lng": -68.330514
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -68.3970319,
        -34.6728808,
        -68.2796831,
        -34.5897974
      ]
    },
    {
      "id": 4746,
      "name": "Koszalin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.190015,
        "lng": 16.182495
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.109905,
        54.137368,
        16.323309,
        54.276991
      ]
    },
    {
      "id": 4747,
      "name": "Escuintla",
      "names": {
        "zh": "埃斯昆特拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3022857,
        "lng": -90.7866243
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.9612425,
        14.1553783,
        -90.6671985,
        14.4746199
      ]
    },
    {
      "id": 4748,
      "name": "Tajimi",
      "names": {
        "zh": "多治见"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3329961,
        "lng": 137.1319459
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.045241,
        35.270383,
        137.186456,
        35.403109
      ]
    },
    {
      "id": 4749,
      "name": "Thunder Bay",
      "names": {
        "es": "Bahía de truenos",
        "fr": "Tonnerre",
        "zh": "雷湾",
        "de": "Donnerbucht",
        "id": "Teluk",
        "ja": "サンダーベイ",
        "th": "อ่าวทันเดอร์",
        "pt": "Baía"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.406414,
        "lng": -89.259796
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -89.4275716,
        48.2829818,
        -89.0607679,
        48.5149964
      ]
    },
    {
      "id": 4750,
      "name": "Pavlohrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.526983,
        "lng": 35.880789
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.804493,
        48.478264,
        35.973756,
        48.575583
      ]
    },
    {
      "id": 4751,
      "name": "Baia Farta",
      "names": {
        "ja": "バイアファルタ"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.6119549,
        "lng": 13.1987084
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.1787084,
        -12.6319549,
        13.2187084,
        -12.5919549
      ]
    },
    {
      "id": 4752,
      "name": "Tagbilaran City",
      "names": {
        "zh": "Tagbilaran城市",
        "de": "Tagbilaran-Stadt",
        "it": "Città tagbilaran",
        "id": "Kota Tagbilaran",
        "ja": "タグビララン市",
        "pt": "Cidade tagbilaran"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.65556,
        "lng": 123.85219
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.83219,
        9.63556,
        123.87219,
        9.67556
      ]
    },
    {
      "id": 4753,
      "name": "Courbevoic",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8978,
        "lng": 2.2531
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 4754,
      "name": "Yevpatoriia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.20036,
        "lng": 33.35479
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 4755,
      "name": "Emmen",
      "names": {
        "zh": "埃门"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.788937,
        "lng": 6.8939001
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.8308505,
        52.7256047,
        6.9794729,
        52.8378978
      ]
    },
    {
      "id": 4756,
      "name": "Moortebeek",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8476835,
        "lng": 4.293521
      },
      "country": "Belgium",
      "importance": 3,
      "bbox": [
        4.2934059,
        50.8476489,
        4.293521,
        50.8476835
      ]
    },
    {
      "id": 4757,
      "name": "Douliu",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 23.7118077,
        "lng": 120.5411553
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.5361553,
        23.7068077,
        120.5461553,
        23.7168077
      ]
    },
    {
      "id": 4758,
      "name": "Queenstown",
      "names": {
        "zh": "皇后镇",
        "ja": "クイーンズタウン",
        "th": "ควีนส์ทาวน์"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": 1.29233,
        "lng": 103.786852
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        103.750225,
        1.255012,
        103.816598,
        1.318733
      ]
    },
    {
      "id": 4759,
      "name": "Carmen",
      "names": {
        "zh": "卡门",
        "ja": "カルメン",
        "th": "การ์เมน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2045108,
        "lng": 124.795371
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.755371,
        7.1645108,
        124.835371,
        7.2445108
      ]
    },
    {
      "id": 4760,
      "name": "Chaman",
      "names": {
        "zh": "查曼"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.915948,
        "lng": 66.4631051
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        66.3822196,
        30.8680689,
        66.5117862,
        30.9634339
      ]
    },
    {
      "id": 4761,
      "name": "Pateros",
      "names": {
        "zh": "帕特罗斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5447555,
        "lng": 121.067134
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0609024,
        14.5376932,
        121.0797063,
        14.5537639
      ]
    },
    {
      "id": 4762,
      "name": "Umm Qasr",
      "names": {
        "zh": "嗯qasr",
        "de": "Ähm qasr"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 30.0323351,
        "lng": 47.9214551
      },
      "country": "Iraq",
      "importance": 3,
      "bbox": [
        47.7614551,
        29.8723351,
        48.0814551,
        30.1923351
      ]
    },
    {
      "id": 4763,
      "name": "Musoma",
      "names": {
        "zh": "穆索马"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -1.4998186,
        "lng": 33.8083018
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        33.6483018,
        -1.6598186,
        33.9683018,
        -1.3398186
      ]
    },
    {
      "id": 4764,
      "name": "Bento Goncalves",
      "names": {
        "ja": "ベントゴンカルブ",
        "pt": "Bento Gonçalves"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.1736892,
        "lng": -51.5175753
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.5225753,
        -29.1786892,
        -51.5125753,
        -29.1686892
      ]
    },
    {
      "id": 4765,
      "name": "Suriapet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.146817,
        "lng": 79.622616
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.600264,
        17.114733,
        79.643142,
        17.170131
      ]
    },
    {
      "id": 4766,
      "name": "Charsadda",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1461506,
        "lng": 71.7337464
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.5737464,
        33.9861506,
        71.8937464,
        34.3061506
      ]
    },
    {
      "id": 4767,
      "name": "Kogon Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.7244996,
        "lng": 64.5451844
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        64.5154886,
        39.7029193,
        64.5934319,
        39.7458041
      ]
    },
    {
      "id": 4768,
      "name": "League City",
      "names": {
        "es": "Ciudad de la liga",
        "fr": "Ville de la Ligue",
        "zh": "联盟城",
        "de": "Liga-Stadt",
        "it": "Città della lega",
        "id": "Kota liga",
        "ja": "リーグシティ",
        "th": "ลีกซิตี้",
        "pt": "Cidade da liga"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.47614,
        "lng": -95.118527
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.22547,
        29.427749,
        -94.997634,
        29.559094
      ]
    },
    {
      "id": 4769,
      "name": "Kefar Sava",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.175,
        "lng": 34.90694
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.88694,
        32.155,
        34.92694,
        32.195
      ]
    },
    {
      "id": 4770,
      "name": "Alchevsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4701987,
        "lng": 38.8010178
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.7396672,
        48.4403599,
        38.8694501,
        48.5209762
      ]
    },
    {
      "id": 4771,
      "name": "Morales",
      "names": {
        "zh": "莫拉莱斯",
        "ja": "モラレス",
        "th": "โมราเลส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.47116,
        "lng": -88.8426325
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.0707875,
        15.222919,
        -88.4872117,
        15.6210445
      ]
    },
    {
      "id": 4772,
      "name": "Chichicastenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9428673,
        "lng": -91.1116613
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.1516613,
        14.9028673,
        -91.0716613,
        14.9828673
      ]
    },
    {
      "id": 4773,
      "name": "Eau Claire",
      "names": {
        "id": "Orang bodoh",
        "ja": "オークレア"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.811349,
        "lng": -91.4984941
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -91.5946859,
        44.7414076,
        -91.367879,
        44.879206
      ]
    },
    {
      "id": 4774,
      "name": "Bolzano",
      "names": {
        "zh": "波尔查诺",
        "de": "Bozen",
        "ja": "ボルツァーノ",
        "th": "โบลซาโน"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.4984781,
        "lng": 11.3547399
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.2753898,
        46.4449131,
        11.4325769,
        46.5303845
      ]
    },
    {
      "id": 4775,
      "name": "Turlock",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 37.4946568,
        "lng": -120.8465941
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.9036717,
        37.466093,
        -120.812143,
        37.5375347
      ]
    },
    {
      "id": 4776,
      "name": "Launceston",
      "names": {
        "zh": "朗塞斯顿",
        "ja": "ローンセストン",
        "th": "ลอนเซสตัน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -41.4340813,
        "lng": 147.1373496
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        146.972181,
        -41.526872,
        147.305245,
        -41.334565
      ]
    },
    {
      "id": 4777,
      "name": "Temple",
      "names": {
        "es": "Templo",
        "zh": "寺",
        "de": "Tempel",
        "it": "Tempio",
        "id": "Kuil",
        "ja": "寺",
        "th": "วัด",
        "pt": "Templo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.098207,
        "lng": -97.3427847
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.558038,
        31.008152,
        -97.2913672,
        31.2090642
      ]
    },
    {
      "id": 4778,
      "name": "Chongshan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.98639,
        "lng": 111.40889
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.38889,
        27.96639,
        111.42889,
        28.00639
      ]
    },
    {
      "id": 4779,
      "name": "Ilebo",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.3316446,
        "lng": 20.5882508
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        20.4282508,
        -4.4916446,
        20.7482508,
        -4.1716446
      ]
    },
    {
      "id": 4780,
      "name": "Taungoo",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 18.9402101,
        "lng": 96.4332782
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.8350005,
        18.8569284,
        96.6746849,
        19.1595089
      ]
    },
    {
      "id": 4781,
      "name": "Dieppe",
      "names": {
        "zh": "迪耶普"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.0966797,
        "lng": -64.7357347
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -64.7661146,
        46.0259907,
        -64.6067688,
        46.1302059
      ]
    },
    {
      "id": 4782,
      "name": "Arzamas",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3942197,
        "lng": 43.817163910692216
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.816902,
        55.3940121,
        43.8173954,
        55.3944366
      ]
    },
    {
      "id": 4783,
      "name": "Moron",
      "names": {
        "es": "Morón",
        "fr": "Idiot",
        "zh": "笨蛋",
        "de": "Trottel",
        "id": "Bodoh",
        "ja": "バカ",
        "th": "คนโง่",
        "pt": "Imbecil"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 18.5602014,
        "lng": -74.2581337
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -74.2981337,
        18.5202014,
        -74.2181337,
        18.6002014
      ]
    },
    {
      "id": 4784,
      "name": "Longjiang",
      "names": {
        "zh": "龙江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.3368513,
        "lng": 123.1987884
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.0387884,
        47.1768513,
        123.3587884,
        47.4968513
      ]
    },
    {
      "id": 4785,
      "name": "Artem",
      "names": {
        "zh": "阿尔乔姆",
        "ja": "アルチョーム"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.3570377,
        "lng": 132.1853629
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        132.0369579,
        43.2727115,
        132.3500654,
        43.4881352
      ]
    },
    {
      "id": 4786,
      "name": "Tiaong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9596673,
        "lng": 121.3222989
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.2568171,
        13.8378297,
        121.4271559,
        14.0059235
      ]
    },
    {
      "id": 4787,
      "name": "Noyabrsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 63.2002917,
        "lng": 75.4475807
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        75.1460466,
        62.9663547,
        77.0765989,
        63.2717858
      ]
    },
    {
      "id": 4788,
      "name": "Yopal",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3356662,
        "lng": -72.3936931
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -72.426951,
        5.2872417,
        -72.3647574,
        5.3565694
      ]
    },
    {
      "id": 4789,
      "name": "Monastir",
      "names": {
        "zh": "莫纳斯提尔",
        "ja": "モナスティール",
        "th": "สตีร์"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.7707582,
        "lng": 10.8280511
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.6680511,
        35.6107582,
        10.9880511,
        35.9307582
      ]
    },
    {
      "id": 4790,
      "name": "Catbalogan",
      "names": {
        "zh": "卡巴洛甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.7753199,
        "lng": 124.8829549
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.550529,
        11.6700127,
        124.9690602,
        11.9191616
      ]
    },
    {
      "id": 4791,
      "name": "As Safirah",
      "names": {
        "es": "Como safirah",
        "fr": "Comme Safirah",
        "zh": "作为Safirah",
        "de": "Als Safirah",
        "it": "Come Safirah",
        "id": "Sebagai safirah",
        "ja": "Safirahとして",
        "th": "เป็น Safirah",
        "pt": "Como Safirah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.07696,
        "lng": 37.37251
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.35251,
        36.05696,
        37.39251,
        36.09696
      ]
    },
    {
      "id": 4792,
      "name": "Chabahar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 25.2919,
        "lng": 60.643
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        60.623,
        25.2719,
        60.663,
        25.3119
      ]
    },
    {
      "id": 4793,
      "name": "Nakhon Si Thammarat",
      "names": {
        "ja": "ナコンSi Thammarat",
        "th": "นครศรีธรรมราช",
        "pt": "Nakhon Si Tamammarat"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.4327951,
        "lng": 99.9627967
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.8027967,
        8.2727951,
        100.1227967,
        8.5927951
      ]
    },
    {
      "id": 4794,
      "name": "Jincheng",
      "names": {
        "zh": "金城",
        "ja": "金城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4913132,
        "lng": 112.8454497
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9353103,
        35.189035,
        113.619226,
        36.0664176
      ]
    },
    {
      "id": 4795,
      "name": "Orsha",
      "names": {
        "zh": "奥尔沙"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 54.5119008,
        "lng": 30.4254486
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        30.3458009,
        54.4621783,
        30.4639506,
        54.5615063
      ]
    },
    {
      "id": 4796,
      "name": "Raba",
      "names": {
        "zh": "拉巴"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 32.388666,
        "lng": 35.377827
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        35.357827,
        32.368666,
        35.397827,
        32.408666
      ]
    },
    {
      "id": 4797,
      "name": "Patos",
      "names": {
        "zh": "帕托斯",
        "ja": "パトス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.0258285,
        "lng": -37.2766817
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.5245364,
        -7.1537596,
        -37.1726811,
        -6.9051942
      ]
    },
    {
      "id": 4798,
      "name": "La Banda",
      "names": {
        "ja": "ラバンダ",
        "pt": "Laja"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.7357077,
        "lng": -64.2434402
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.277192,
        -27.7641889,
        -64.21148,
        -27.706811
      ]
    },
    {
      "id": 4799,
      "name": "Sioux City",
      "names": {
        "es": "La ciudad de Sioux",
        "id": "Kota sioux",
        "ja": "スーシティ",
        "pt": "Cidade de sioux"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4966815,
        "lng": -96.4058782
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.4989872,
        42.3700699,
        -96.2995598,
        42.5654171
      ]
    },
    {
      "id": 4800,
      "name": "Salisbury",
      "names": {
        "zh": "索尔兹伯里",
        "ja": "ソールズベリー",
        "th": "ซูเปอร์มาร์เก็ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.366027,
        "lng": -75.6009964
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.642922,
        38.3269515,
        -75.5035719,
        38.4271092
      ]
    },
    {
      "id": 4801,
      "name": "Vinh Long",
      "names": {
        "es": "Vinh largo",
        "de": "Vinh lang",
        "ja": "長いvinh",
        "th": "vinh ยาว",
        "pt": "Vinh longo"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.252039,
        "lng": 105.9735715
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.877492,
        10.2207006,
        105.9962225,
        10.2846726
      ]
    },
    {
      "id": 4802,
      "name": "Passos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.7167803,
        "lng": -46.6156864
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.862,
        -20.99267,
        -46.3794593,
        -20.462
      ]
    },
    {
      "id": 4803,
      "name": "Akhmim",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.56217,
        "lng": 31.74503
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.72503,
        26.54217,
        31.76503,
        26.58217
      ]
    },
    {
      "id": 4804,
      "name": "Komatsu",
      "names": {
        "zh": "小松",
        "ja": "コマツ",
        "th": "รถ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4032931,
        "lng": 136.4495465
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.2176183,
        36.146106,
        136.612386,
        36.5559033
      ]
    },
    {
      "id": 4805,
      "name": "Davie",
      "names": {
        "zh": "大卫"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.087573,
        "lng": -80.296011
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.369512,
        26.029645,
        -80.184049,
        26.123304
      ]
    },
    {
      "id": 4806,
      "name": "Aihua",
      "names": {
        "zh": "爱华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4403736,
        "lng": 100.1368736
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.0968736,
        24.4003736,
        100.1768736,
        24.4803736
      ]
    },
    {
      "id": 4807,
      "name": "Temoaya",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.468316,
        "lng": -99.592728
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.601841,
        19.459828,
        -99.588164,
        19.477206
      ]
    },
    {
      "id": 4808,
      "name": "Achinsk",
      "names": {
        "zh": "阿钦斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2694846,
        "lng": 90.4953964
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        90.35995,
        56.2026195,
        90.5977537,
        56.333616
      ]
    },
    {
      "id": 4809,
      "name": "Daly City",
      "names": {
        "zh": "戴利市",
        "id": "Kota Daly",
        "ja": "ダリシティ",
        "th": "เมืองดาลี่",
        "pt": "Cidade de daly"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6904826,
        "lng": -122.47267
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.5008215,
        37.6485198,
        -122.4051391,
        37.708269
      ]
    },
    {
      "id": 4810,
      "name": "Contramaestre",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.2996013,
        "lng": -76.2441035
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -76.39459,
        20.1652502,
        -76.0758823,
        20.493743
      ]
    },
    {
      "id": 4811,
      "name": "Ciudad Rio Bravo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.988981,
        "lng": -98.0945766
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.2545766,
        25.828981,
        -97.9345766,
        26.148981
      ]
    },
    {
      "id": 4812,
      "name": "Vespasiano",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6940958,
        "lng": -43.9186521
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.0077193,
        -19.7916445,
        -43.885,
        -19.6700237
      ]
    },
    {
      "id": 4813,
      "name": "Mang La",
      "names": {
        "fr": "Manghe",
        "zh": "莽洛",
        "it": "Manging",
        "id": "Mang",
        "ja": "マンラ",
        "th": "มังกา"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 14.38333,
        "lng": 107.95
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        107.93,
        14.36333,
        107.97,
        14.40333
      ]
    },
    {
      "id": 4814,
      "name": "Trincomalee",
      "names": {
        "zh": "亭可马里",
        "ja": "トリンコマリー"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 8.576425,
        "lng": 81.2344952
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        81.0744952,
        8.416425,
        81.3944952,
        8.736425
      ]
    },
    {
      "id": 4815,
      "name": "Lo Barnechea",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.352802,
        "lng": -70.5185751
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.5186251,
        -33.352852,
        -70.5185251,
        -33.352752
      ]
    },
    {
      "id": 4816,
      "name": "Azare",
      "names": {
        "zh": "阿扎雷"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.6765,
        "lng": 10.1948
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        10.1748,
        11.6565,
        10.2148,
        11.6965
      ]
    },
    {
      "id": 4817,
      "name": "Ariquemes",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -9.9076522,
        "lng": -63.033069
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -63.617,
        -10.2878158,
        -62.422,
        -9.6691601
      ]
    },
    {
      "id": 4818,
      "name": "Paco do Lumiar",
      "names": {
        "zh": "Paco做邪恶",
        "de": "Paco lumiar",
        "id": "Paco melakukan Lumiar",
        "th": "Paco ทำ lumiar"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5329055,
        "lng": -44.1591965
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.1928398,
        -2.5547014,
        -44.0523062,
        -2.454652
      ]
    },
    {
      "id": 4819,
      "name": "Koutiala",
      "names": {
        "zh": "库佳拉"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.3854826,
        "lng": -5.4689427
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -5.4689927,
        12.3854326,
        -5.4688927,
        12.3855326
      ]
    },
    {
      "id": 4820,
      "name": "Brovary",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5111168,
        "lng": 30.7900482
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.7376052,
        50.4823077,
        30.8648736,
        50.5370872
      ]
    },
    {
      "id": 4821,
      "name": "Kiryu",
      "names": {
        "ja": "桐生"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4057374,
        "lng": 139.3308366
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.188678,
        36.358527,
        139.451094,
        36.5956619
      ]
    },
    {
      "id": 4822,
      "name": "Pushkino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0104274,
        "lng": 37.8461892
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.7804853,
        55.9615419,
        37.9411461,
        56.0605359
      ]
    },
    {
      "id": 4823,
      "name": "Yelets",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.60489,
        "lng": 38.494925
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.451637,
        52.564167,
        38.59761,
        52.64225
      ]
    },
    {
      "id": 4824,
      "name": "Ourense",
      "names": {
        "th": "อูเรนเซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.34093,
        "lng": -7.865741
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -7.924313,
        42.283912,
        -7.801892,
        42.37071
      ]
    },
    {
      "id": 4825,
      "name": "Tikrit",
      "names": {
        "zh": "提克里特"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.598454,
        "lng": 43.694343
      },
      "country": "Iraq",
      "importance": 1,
      "bbox": [
        43.674343,
        34.578454,
        43.714343,
        34.618454
      ]
    },
    {
      "id": 4826,
      "name": "Bulan",
      "names": {
        "zh": "都婆兰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.6689078,
        "lng": 123.8769586
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.8369586,
        12.6289078,
        123.9169586,
        12.7089078
      ]
    },
    {
      "id": 4827,
      "name": "Malacatan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.910776,
        "lng": -92.0584743
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.0984743,
        14.870776,
        -92.0184743,
        14.950776
      ]
    },
    {
      "id": 4828,
      "name": "Playas de Rosarito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 32.365166,
        "lng": -117.0557923
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -117.2157923,
        32.205166,
        -116.8957923,
        32.525166
      ]
    },
    {
      "id": 4829,
      "name": "Highlands Ranch",
      "names": {
        "es": "Rancho de tierras altas",
        "zh": "高地牧场",
        "id": "Peternakan dataran tinggi",
        "ja": "ハイランド牧場",
        "th": "ฟาร์มไฮแลนด์",
        "pt": "Rancho de Highlands"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5497243,
        "lng": -104.969565
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.989565,
        39.5297243,
        -104.949565,
        39.5697243
      ]
    },
    {
      "id": 4830,
      "name": "Allen",
      "names": {
        "zh": "艾伦",
        "ja": "アレン",
        "th": "อัลเลน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1031744,
        "lng": -96.6705503
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.7366668,
        33.0707933,
        -96.6089949,
        33.1580078
      ]
    },
    {
      "id": 4831,
      "name": "Tarim",
      "names": {
        "zh": "塔里木"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 16.0563965,
        "lng": 48.9967237
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        48.9567237,
        16.0163965,
        49.0367237,
        16.0963965
      ]
    },
    {
      "id": 4832,
      "name": "Malungun",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.27917,
        "lng": 125.28167
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.26167,
        6.25917,
        125.30167,
        6.29917
      ]
    },
    {
      "id": 4833,
      "name": "Mazyr",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.0475482,
        "lng": 29.2667179
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.17626,
        52.0075755,
        29.3166272,
        52.0977621
      ]
    },
    {
      "id": 4834,
      "name": "Roubaix",
      "names": {
        "zh": "鲁贝"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.689335,
        "lng": 3.182393
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        3.15116,
        50.668756,
        3.217454,
        50.70875
      ]
    },
    {
      "id": 4835,
      "name": "Robat Karim",
      "names": {
        "ja": "ロバットカリム"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4846,
        "lng": 51.0829
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.0629,
        35.4646,
        51.1029,
        35.5046
      ]
    },
    {
      "id": 4836,
      "name": "Kroonstad",
      "names": {
        "zh": "克龙斯塔德"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -27.6532701,
        "lng": 27.235515
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.195515,
        -27.6932701,
        27.275515,
        -27.6132701
      ]
    },
    {
      "id": 4837,
      "name": "Kandhkot",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.2517122,
        "lng": 69.1854987
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        69.1804987,
        28.2467122,
        69.1904987,
        28.2567122
      ]
    },
    {
      "id": 4838,
      "name": "Saint Helens",
      "names": {
        "zh": "圣海伦",
        "ja": "セントヘレンズ",
        "th": "นักบุญเฮเลนส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.65935655,
        "lng": -1.4817745220095317
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.4821333,
        53.6592015,
        -1.4814157,
        53.6595291
      ]
    },
    {
      "id": 4839,
      "name": "Toride",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9112034,
        "lng": 140.0500352
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.995197,
        35.870857,
        140.154239,
        35.954207
      ]
    },
    {
      "id": 4840,
      "name": "Onojo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.527267,
        "lng": 130.476807
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.436062,
        33.482,
        130.511868,
        33.566134
      ]
    },
    {
      "id": 4841,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9132391,
        "lng": -85.88301185234297
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.1324583,
        11.6021731,
        -85.6703712,
        12.2145784
      ]
    },
    {
      "id": 4842,
      "name": "Erzurum",
      "names": {
        "zh": "埃尔祖鲁姆"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.90632,
        "lng": 41.2727715
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        41.1127715,
        39.74632,
        41.4327715,
        40.06632
      ]
    },
    {
      "id": 4843,
      "name": "Kongolo",
      "names": {
        "zh": "孔戈洛"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -3.8137138,
        "lng": 33.1719328
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        33.1519328,
        -3.8337138,
        33.1919328,
        -3.7937138
      ]
    },
    {
      "id": 4844,
      "name": "Sultan Kudarat",
      "names": {
        "zh": "苏丹·库拉特",
        "ja": "スルタンクダラ",
        "th": "สุลต่าน kudarat",
        "pt": "Sultão kudarat"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.271093,
        "lng": 124.2919201
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.2519201,
        7.231093,
        124.3319201,
        7.311093
      ]
    },
    {
      "id": 4845,
      "name": "Moers",
      "names": {
        "zh": "莫尔斯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.451283,
        "lng": 6.62843
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.5583684,
        51.3907999,
        6.6787416,
        51.5237804
      ]
    },
    {
      "id": 4846,
      "name": "Liberec",
      "names": {
        "zh": "利贝雷茨",
        "ja": "リベレツ"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.7702648,
        "lng": 15.0583947
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        14.9529697,
        50.7079791,
        15.1468816,
        50.8243417
      ]
    },
    {
      "id": 4847,
      "name": "Texcoco",
      "names": {
        "zh": "特斯科科"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.47559065,
        "lng": -98.85539643391266
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.0289004,
        19.3948366,
        -98.6346758,
        19.5569546
      ]
    },
    {
      "id": 4848,
      "name": "Novara",
      "names": {
        "zh": "诺瓦拉",
        "ja": "ノバラ",
        "th": "โนวารา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4464036,
        "lng": 8.619655
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.52906,
        45.3815208,
        8.7034022,
        45.5050719
      ]
    },
    {
      "id": 4849,
      "name": "Mmabatho",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.85,
        "lng": 25.63333
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        25.61333,
        -25.87,
        25.65333,
        -25.83
      ]
    },
    {
      "id": 4850,
      "name": "Al Faw",
      "names": {
        "es": "Alfombra",
        "fr": "Alternance",
        "zh": "一汽",
        "de": "Alten",
        "it": "Al ferro",
        "ja": "アルフォー",
        "th": "อัลฟอว์"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 29.9743957,
        "lng": 48.471826
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        48.431826,
        29.9343957,
        48.511826,
        30.0143957
      ]
    },
    {
      "id": 4851,
      "name": "Balanga",
      "names": {
        "zh": "巴兰加"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6795669,
        "lng": 120.5409693
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.4404116,
        14.6187985,
        120.622449,
        14.7242782
      ]
    },
    {
      "id": 4852,
      "name": "Ndjamba",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.6878709,
        "lng": 16.0340743
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        16.0240743,
        -14.6978709,
        16.0440743,
        -14.6778709
      ]
    },
    {
      "id": 4853,
      "name": "West Covina",
      "names": {
        "zh": "西科维纳",
        "de": "West-Covina",
        "it": "Covina ovest",
        "id": "Covina Barat",
        "ja": "ウェストコヴィナ",
        "pt": "Covina Ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0686208,
        "lng": -117.9389526
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.967138,
        34.001855,
        -117.858261,
        34.0924779
      ]
    },
    {
      "id": 4854,
      "name": "Masbate",
      "names": {
        "zh": "马斯巴特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.36667,
        "lng": 123.61667
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.59667,
        12.34667,
        123.63667,
        12.38667
      ]
    },
    {
      "id": 4855,
      "name": "Salzgitter",
      "names": {
        "zh": "萨尔茨基特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1503721,
        "lng": 10.3593147
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2308608,
        52.0130327,
        10.507726,
        52.2227865
      ]
    },
    {
      "id": 4856,
      "name": "Berdsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.75795,
        "lng": 83.1068095
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        83.01355,
        54.678522,
        83.1798024,
        54.8089386
      ]
    },
    {
      "id": 4857,
      "name": "Sparks",
      "names": {
        "es": "Chispas",
        "fr": "Étincelles",
        "zh": "火花",
        "de": "Funken",
        "it": "Scintille",
        "ja": "火花",
        "th": "ประกายไฟ",
        "pt": "Faíscas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5404679,
        "lng": -119.7487235
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.781139,
        39.5119369,
        -119.6497513,
        39.638347
      ]
    },
    {
      "id": 4858,
      "name": "Waterloo",
      "names": {
        "zh": "滑铁卢",
        "ja": "ワーテルロー",
        "th": "วอเตอร์ลู"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.4652699,
        "lng": -80.5222961
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.6260904,
        43.4322663,
        -80.4674214,
        43.5312209
      ]
    },
    {
      "id": 4859,
      "name": "Worcester",
      "names": {
        "zh": "伍斯特",
        "ja": "ウスター",
        "th": "เวอร์ซ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.1911849,
        "lng": -2.2206585
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.2632087,
        52.1616361,
        -2.1573039,
        52.231392
      ]
    },
    {
      "id": 4860,
      "name": "Tadepallegudem",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8165294,
        "lng": 81.5296984
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.3696984,
        16.6565294,
        81.6896984,
        16.9765294
      ]
    },
    {
      "id": 4861,
      "name": "Malatya",
      "names": {
        "zh": "马拉蒂亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3487153,
        "lng": 38.3190674
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        38.1590674,
        38.1887153,
        38.4790674,
        38.5087153
      ]
    },
    {
      "id": 4862,
      "name": "Nanterre",
      "names": {
        "zh": "楠泰尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8996114,
        "lng": 2.2014788694049447
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.201298,
        48.8991315,
        2.2026813,
        48.9000682
      ]
    },
    {
      "id": 4863,
      "name": "Sergiyev Posad",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3,
        "lng": 38.13333
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.11333,
        56.28,
        38.15333,
        56.32
      ]
    },
    {
      "id": 4864,
      "name": "Wichita Falls",
      "names": {
        "fr": "Wichita tombe",
        "zh": "威奇塔瀑布",
        "ja": "ウィチタが落ちます"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9132701,
        "lng": -98.5066534
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.5067034,
        33.9132201,
        -98.5066034,
        33.9133201
      ]
    },
    {
      "id": 4865,
      "name": "Fugangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.19823,
        "lng": 114.03727
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.01727,
        23.17823,
        114.05727,
        23.21823
      ]
    },
    {
      "id": 4866,
      "name": "Konotop",
      "names": {
        "zh": "科诺托普"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.2397495,
        "lng": 33.206675
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.1353694,
        51.1974386,
        33.2765597,
        51.2743154
      ]
    },
    {
      "id": 4867,
      "name": "Trindade",
      "names": {
        "zh": "特林达迪"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.6549924,
        "lng": -49.4876203
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.695789,
        -16.8128705,
        -49.379,
        -16.489418
      ]
    },
    {
      "id": 4868,
      "name": "Girona",
      "names": {
        "zh": "赫罗纳",
        "ja": "ジローナ",
        "th": "เจโรนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.97946505,
        "lng": 2.8167744669175754
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        2.816326,
        41.9788826,
        2.8171828,
        41.9800899
      ]
    },
    {
      "id": 4869,
      "name": "Inzai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8322582,
        "lng": 140.1452981
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.087056,
        35.739554,
        140.265615,
        35.851082
      ]
    },
    {
      "id": 4870,
      "name": "Maumere",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.6256456,
        "lng": 122.2192885
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        122.1792885,
        -8.6656456,
        122.2592885,
        -8.5856456
      ]
    },
    {
      "id": 4871,
      "name": "Khak-e `Ali",
      "names": {
        "zh": "Khak-e`Ali"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1284,
        "lng": 50.1765
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        50.1565,
        36.1084,
        50.1965,
        36.1484
      ]
    },
    {
      "id": 4872,
      "name": "Suruc",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9751849,
        "lng": 38.424295
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.1604201,
        36.8240596,
        38.6126427,
        37.1465766
      ]
    },
    {
      "id": 4873,
      "name": "San Mateo",
      "names": {
        "zh": "圣马特奥",
        "ja": "サンマテオ",
        "th": "ซานมาเทโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.496904,
        "lng": -122.3330573
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.588177,
        37.0479139,
        -122.0801029,
        37.7082836
      ]
    },
    {
      "id": 4874,
      "name": "Arapongas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4150598,
        "lng": -51.4291075
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.4291575,
        -23.4151098,
        -51.4290575,
        -23.4150098
      ]
    },
    {
      "id": 4875,
      "name": "Villa Luzuriaga",
      "names": {
        "id": "Villa Luzuriga",
        "ja": "ヴィラルズリアガ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6730997,
        "lng": -58.5939502
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.6193993,
        -34.6954931,
        -58.56845,
        -34.6505133
      ]
    },
    {
      "id": 4876,
      "name": "Chikushino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4906026,
        "lng": 130.520329
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4536583,
        33.4279241,
        130.6217003,
        33.554682
      ]
    },
    {
      "id": 4877,
      "name": "San Vicente de Baracaldo",
      "names": {
        "ja": "サンビセンテデバラカルド"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.2975233,
        "lng": -2.9894621
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -2.9895121,
        43.2974733,
        -2.9894121,
        43.2975733
      ]
    },
    {
      "id": 4878,
      "name": "Caseros",
      "names": {
        "zh": "卡塞罗斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6045905,
        "lng": -58.5645637
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.5695637,
        -34.6095905,
        -58.5595637,
        -34.5995905
      ]
    },
    {
      "id": 4879,
      "name": "Gillingham",
      "names": {
        "zh": "吉灵厄姆",
        "th": "จิลลิ่งแฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3865507,
        "lng": 0.5498339
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.5448339,
        51.3815507,
        0.5548339,
        51.3915507
      ]
    },
    {
      "id": 4880,
      "name": "Ginowan",
      "names": {
        "zh": "宜野湾"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.2814968,
        "lng": 127.7784916
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.7012663,
        26.249855,
        127.787146,
        26.3143212
      ]
    },
    {
      "id": 4881,
      "name": "Abengourou",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.72972,
        "lng": -3.49639
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -3.51639,
        6.70972,
        -3.47639,
        6.74972
      ]
    },
    {
      "id": 4882,
      "name": "Ciudad Guzman",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7001632,
        "lng": -103.463935
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.623935,
        19.5401632,
        -103.303935,
        19.8601632
      ]
    },
    {
      "id": 4883,
      "name": "Sindangan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.2373562,
        "lng": 122.9986125
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9586125,
        8.1973562,
        123.0386125,
        8.2773562
      ]
    },
    {
      "id": 4884,
      "name": "Lower Hutt",
      "names": {
        "es": "Hutt inferior",
        "fr": "Basse Hutt",
        "zh": "下赫特",
        "de": "Niedrigeres Hutt",
        "it": "Hutt più basso",
        "id": "Hutt bawah",
        "ja": "下のHUTT",
        "th": "ต่ำสุดฮัทท์",
        "pt": "Hutt inferior"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.2126118,
        "lng": 174.9208409
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        174.9207909,
        -41.2126618,
        174.9208909,
        -41.2125618
      ]
    },
    {
      "id": 4885,
      "name": "Delft",
      "names": {
        "zh": "代尔夫特",
        "ja": "デルフト",
        "th": "เดลฟ์"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0114017,
        "lng": 4.35839
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3202178,
        51.9663163,
        4.4079109,
        52.0325988
      ]
    },
    {
      "id": 4886,
      "name": "Coronel Fabriciano",
      "names": {
        "es": "CORONEL FABRICANO",
        "it": "Coronel Fabricaiano",
        "ja": "Coronel Fraviano",
        "th": "ผ้าโคโรนา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5200067,
        "lng": -42.6282686
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.792145,
        -19.5374393,
        -42.568433,
        -19.343449
      ]
    },
    {
      "id": 4887,
      "name": "Bouskoura",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.456443,
        "lng": -7.650666
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.7571169,
        33.4252325,
        -7.5429527,
        33.5286114
      ]
    },
    {
      "id": 4888,
      "name": "Kamianets-Podilskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6781294,
        "lng": 26.5854027
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        26.5433037,
        48.6478475,
        26.6163251,
        48.7343847
      ]
    },
    {
      "id": 4889,
      "name": "Acailandia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.9515026,
        "lng": -47.500732
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.0253655,
        -5.168,
        -46.841,
        -4.236
      ]
    },
    {
      "id": 4890,
      "name": "Kalyani",
      "names": {
        "zh": "卡利亚尼"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.9749723,
        "lng": 88.4345915
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.4068394,
        22.948894,
        88.506542,
        23.0127412
      ]
    },
    {
      "id": 4891,
      "name": "Kilinochchi",
      "names": {
        "zh": "基利诺奇"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 9.3961,
        "lng": 80.3982
      },
      "country": "Sri Lanka",
      "importance": 3,
      "bbox": [
        80.3782,
        9.3761,
        80.4182,
        9.4161
      ]
    },
    {
      "id": 4892,
      "name": "Columbia",
      "names": {
        "fr": "-Britannique",
        "zh": "哥伦比亚",
        "de": "Kolumbien",
        "ja": "コロンビア",
        "th": "โคลัมเบีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0389186,
        "lng": -76.4191791
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.6407787,
        40.7754277,
        -76.2078051,
        41.308993
      ]
    },
    {
      "id": 4893,
      "name": "Paniqui",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6686544,
        "lng": 120.5858177
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4703248,
        15.6316542,
        120.6201037,
        15.7192313
      ]
    },
    {
      "id": 4894,
      "name": "Saijo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9194466,
        "lng": 133.1813268
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.956711,
        33.750295,
        133.277357,
        34.0089483
      ]
    },
    {
      "id": 4895,
      "name": "EdDamer",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 17.59,
        "lng": 33.96
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 4896,
      "name": "Norwalk",
      "names": {
        "zh": "诺瓦克",
        "th": "วอล์ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9092802,
        "lng": -118.0849169
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1116455,
        33.880291,
        -118.0422182,
        33.9334683
      ]
    },
    {
      "id": 4897,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.8643546,
        "lng": -54.4779287
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -54.6855091,
        -27.9843875,
        -54.357,
        -27.696605
      ]
    },
    {
      "id": 4898,
      "name": "Isehara",
      "names": {
        "zh": "伊势原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3960934,
        "lng": 139.3134259
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3133759,
        35.3960434,
        139.3134759,
        35.3961434
      ]
    },
    {
      "id": 4899,
      "name": "Zomba",
      "names": {
        "zh": "松巴"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -15.3863208,
        "lng": 35.3268273
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.1668273,
        -15.5463208,
        35.4868273,
        -15.2263208
      ]
    },
    {
      "id": 4900,
      "name": "Dolisie",
      "names": {
        "zh": "多利西"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.1981977,
        "lng": 12.6761305
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        12.6711305,
        -4.2031977,
        12.6811305,
        -4.1931977
      ]
    },
    {
      "id": 4901,
      "name": "Negapatam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.76377,
        "lng": 79.84313
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4902,
      "name": "Tourcoing",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 50.7235038,
        "lng": 3.1605714
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        3.1186236,
        50.6930654,
        3.1967341,
        50.7490318
      ]
    },
    {
      "id": 4903,
      "name": "Eastbourne",
      "names": {
        "zh": "伊斯特本",
        "ja": "イーストボーン",
        "th": "อีสต์บอร์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7696435,
        "lng": 0.2815355
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.2765355,
        50.7646435,
        0.2865355,
        50.7746435
      ]
    },
    {
      "id": 4904,
      "name": "Wigan",
      "names": {
        "zh": "维冈竞技",
        "ja": "ウィガン",
        "th": "วีแกน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5457188,
        "lng": -2.6264624
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.7305207,
        53.4460001,
        -2.4145817,
        53.6083107
      ]
    },
    {
      "id": 4905,
      "name": "Libertad",
      "names": {
        "zh": "伯塔德",
        "ja": "リベルタ",
        "th": "เสรีภาพ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6921599,
        "lng": -58.6864782
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.6914782,
        -34.6971599,
        -58.6814782,
        -34.6871599
      ]
    },
    {
      "id": 4906,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1801338,
        "lng": 122.8288492
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7888492,
        11.1401338,
        122.8688492,
        11.2201338
      ]
    },
    {
      "id": 4907,
      "name": "Telde",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 27.9984538,
        "lng": -15.4166761
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -15.5319489,
        27.9283414,
        -15.358281,
        28.0388375
      ]
    },
    {
      "id": 4908,
      "name": "Thika",
      "names": {
        "zh": "锡卡"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.0506083,
        "lng": 37.0905534
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        37.0855534,
        -1.0556083,
        37.0955534,
        -1.0456083
      ]
    },
    {
      "id": 4909,
      "name": "Balti",
      "names": {
        "zh": "巴蒂",
        "ja": "バルティー語",
        "th": "บัลติ"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7623952,
        "lng": 27.9286022
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.8375297,
        47.728628,
        27.9733272,
        47.8006542
      ]
    },
    {
      "id": 4910,
      "name": "Yunfu",
      "names": {
        "zh": "云浮",
        "th": "หยุนฝู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6337751,
        "lng": 104.4128973
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        104.2497023,
        28.5885677,
        104.4252205,
        28.6527485
      ]
    },
    {
      "id": 4911,
      "name": "Dongguazhen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.09111,
        "lng": 101.5075
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        101.4875,
        25.07111,
        101.5275,
        25.11111
      ]
    },
    {
      "id": 4912,
      "name": "Siegen",
      "names": {
        "zh": "锡根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8759626,
        "lng": 8.0164616
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.0114616,
        50.8709626,
        8.0214616,
        50.8809626
      ]
    },
    {
      "id": 4913,
      "name": "Tantoyuca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.352185,
        "lng": -98.227347
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.2464264928,
        21.3335227543,
        -98.2086770154,
        21.3690435609
      ]
    },
    {
      "id": 4914,
      "name": "Biak",
      "names": {
        "zh": "比亚克"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -1.1836086,
        "lng": 136.0816321
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        134.7826087,
        -1.3404511,
        136.70791,
        -0.6851588
      ]
    },
    {
      "id": 4915,
      "name": "Elista",
      "names": {
        "zh": "埃利斯塔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 46.306999,
        "lng": 44.270187
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        44.1804027,
        46.281575,
        44.3398689,
        46.3775799
      ]
    },
    {
      "id": 4916,
      "name": "Yishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1434015,
        "lng": 110.7748513
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.7348513,
        35.1034015,
        110.8148513,
        35.1834015
      ]
    },
    {
      "id": 4917,
      "name": "Songea",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -10.6787282,
        "lng": 35.6483576
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        35.4883576,
        -10.8387282,
        35.8083576,
        -10.5187282
      ]
    },
    {
      "id": 4918,
      "name": "Mazatenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.53417,
        "lng": -91.50333
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.52333,
        14.51417,
        -91.48333,
        14.55417
      ]
    },
    {
      "id": 4919,
      "name": "Bundi",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4416137,
        "lng": 75.6375815
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.4775815,
        25.2816137,
        75.7975815,
        25.6016137
      ]
    },
    {
      "id": 4920,
      "name": "Rialto",
      "names": {
        "zh": "里亚尔托",
        "ja": "リアルト"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1064001,
        "lng": -117.3703235
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.4367879,
        34.0245863,
        -117.3410426,
        34.1871532
      ]
    },
    {
      "id": 4921,
      "name": "Diyarbakir",
      "names": {
        "zh": "迪亚巴克尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9162222,
        "lng": 40.2363542
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        40.0763542,
        37.7562222,
        40.3963542,
        38.0762222
      ]
    },
    {
      "id": 4922,
      "name": "Hove",
      "names": {
        "zh": "利物浦",
        "ja": "ホーブ",
        "th": "ทำให้พองขึ้น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8352056,
        "lng": -0.17018
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.17518,
        50.8302056,
        -0.16518,
        50.8402056
      ]
    },
    {
      "id": 4923,
      "name": "Nyeri",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.4192962,
        "lng": 36.9517005
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.9077432,
        -0.4452637,
        36.9861603,
        -0.4056324
      ]
    },
    {
      "id": 4924,
      "name": "Burzaco",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8237067,
        "lng": -58.3907352
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.3907852,
        -34.8237567,
        -58.3906852,
        -34.8236567
      ]
    },
    {
      "id": 4925,
      "name": "Lida",
      "names": {
        "zh": "利达"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.8811516,
        "lng": 25.2951169
      },
      "country": "Belarus",
      "importance": 3,
      "bbox": [
        25.2901169,
        53.8761516,
        25.3001169,
        53.8861516
      ]
    },
    {
      "id": 4926,
      "name": "Manteca",
      "names": {
        "zh": "曼特卡",
        "ja": "マンテカ",
        "th": "แมนเทกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7986184,
        "lng": -121.2117148
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.3717148,
        37.6386184,
        -121.0517148,
        37.9586184
      ]
    },
    {
      "id": 4927,
      "name": "Messaad",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.1540811,
        "lng": 3.492231
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        3.3965944,
        34.0797539,
        3.5674804,
        34.2401775
      ]
    },
    {
      "id": 4928,
      "name": "Bumba",
      "names": {
        "zh": "本巴"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.1837441,
        "lng": 22.466526
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        21.6431741,
        2.0142711,
        23.5796067,
        3.3333298
      ]
    },
    {
      "id": 4929,
      "name": "Araxa",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6009149,
        "lng": -46.940923
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.234212,
        -19.8377764,
        -46.739869,
        -19.4329461
      ]
    },
    {
      "id": 4930,
      "name": "Almirante Tamandare",
      "names": {
        "ja": "アルミランテタマンダー"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.3133048,
        "lng": -49.2997838
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.4506338,
        -25.3869522,
        -49.2294187,
        -25.2243602
      ]
    },
    {
      "id": 4931,
      "name": "Sakado",
      "names": {
        "zh": "坂户"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9572312,
        "lng": 139.4029048
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.32365,
        35.919462,
        139.468287,
        35.998096
      ]
    },
    {
      "id": 4932,
      "name": "Sao Lourenco da Mata",
      "names": {
        "zh": "圣卢森诺达马塔",
        "ja": "サンルレンコダマタ",
        "pt": "São Lourenço da Mata"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.994158,
        "lng": -35.032829
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.084105,
        -8.046092,
        -34.993258,
        -7.956723
      ]
    },
    {
      "id": 4933,
      "name": "Yilong",
      "names": {
        "zh": "亿泷"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7157988,
        "lng": 102.4905832
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.4505832,
        23.6757988,
        102.5305832,
        23.7557988
      ]
    },
    {
      "id": 4934,
      "name": "Novokuybyshevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.0959,
        "lng": 49.9462
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        49.9262,
        53.0759,
        49.9662,
        53.1159
      ]
    },
    {
      "id": 4935,
      "name": "Soasio",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 0.6747880500000001,
        "lng": 127.45115100379823
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        127.4509966,
        0.6746731,
        127.4513054,
        0.674903
      ]
    },
    {
      "id": 4936,
      "name": "Piacenza",
      "names": {
        "zh": "皮亚琴察",
        "ja": "ピアチェンツァ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0521039,
        "lng": 9.7063011
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.7013011,
        45.0471039,
        9.7113011,
        45.0571039
      ]
    },
    {
      "id": 4937,
      "name": "Miryang",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.49333,
        "lng": 128.74889
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.72889,
        35.47333,
        128.76889,
        35.51333
      ]
    },
    {
      "id": 4938,
      "name": "Houzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3534987,
        "lng": 111.0598788
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.0398788,
        39.3334987,
        111.0798788,
        39.3734987
      ]
    },
    {
      "id": 4939,
      "name": "Mengdingjie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.55,
        "lng": 99.08333
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        99.06333,
        23.53,
        99.10333,
        23.57
      ]
    },
    {
      "id": 4940,
      "name": "Satu Mare",
      "names": {
        "es": "Mare satu",
        "fr": "Satu",
        "de": "Satu-Mare",
        "ja": "サトゥヤレ",
        "th": "แม่ม้า satu",
        "pt": "Satur Mare"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.7891763,
        "lng": 22.8725598
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        22.7560152,
        47.6978334,
        22.9768441,
        47.8945772
      ]
    },
    {
      "id": 4941,
      "name": "Hildesheim",
      "names": {
        "zh": "希尔德斯海姆",
        "ja": "ヒルデスハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1521636,
        "lng": 9.9513046
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.8465506,
        52.0935785,
        10.0428454,
        52.1939078
      ]
    },
    {
      "id": 4942,
      "name": "Parang",
      "names": {
        "es": "Machete malayo",
        "zh": "帕朗",
        "ja": "パラン",
        "th": "ฮอกกี้",
        "pt": "Parão"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3755802,
        "lng": 124.267482
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.227482,
        7.3355802,
        124.307482,
        7.4155802
      ]
    },
    {
      "id": 4943,
      "name": "Nantou",
      "names": {
        "zh": "南投",
        "th": "หนานโถว"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.9099561,
        "lng": 120.6837059
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.6269414,
        23.8785889,
        120.7348682,
        23.9654565
      ]
    },
    {
      "id": 4944,
      "name": "Coatepec",
      "names": {
        "zh": "有科特佩"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4543134,
        "lng": -96.9596375
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1062296,
        19.3572737,
        -96.7970344,
        19.5323907
      ]
    },
    {
      "id": 4945,
      "name": "Sangju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.415381,
        "lng": 128.15573854579728
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        128.1556476,
        36.4153473,
        128.1558295,
        36.4154322
      ]
    },
    {
      "id": 4946,
      "name": "Mumias",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.3351446,
        "lng": 34.4833781
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        34.4433781,
        0.2951446,
        34.5233781,
        0.3751446
      ]
    },
    {
      "id": 4947,
      "name": "Bhadreswar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.822929,
        "lng": 88.34956
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.31842,
        22.80449,
        88.35842,
        22.84449
      ]
    },
    {
      "id": 4948,
      "name": "Guihulngan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1195972,
        "lng": 123.273871
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.113871,
        9.9595972,
        123.433871,
        10.2795972
      ]
    },
    {
      "id": 4949,
      "name": "Arden-Arcade",
      "names": {
        "zh": "雅顿-街机",
        "ja": "アーデン-アーケード",
        "th": "อาร์เดน-อาเขต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.602265,
        "lng": -121.381746
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.420597,
        38.562605,
        -121.34494,
        38.645505
      ]
    },
    {
      "id": 4950,
      "name": "Noginsk",
      "names": {
        "zh": "诺金斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.85089085,
        "lng": 38.443474007913665
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.4431909,
        55.8507393,
        38.4438232,
        55.8510487
      ]
    },
    {
      "id": 4951,
      "name": "Bethal",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.4569444,
        "lng": 29.4708333
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        29.4308333,
        -26.4969444,
        29.5108333,
        -26.4169444
      ]
    },
    {
      "id": 4952,
      "name": "Vitry-sur-Seine",
      "names": {
        "es": "Vitry-sur-Sena",
        "zh": "维特里塞纳河畔",
        "ja": "vitryシュルセーヌ",
        "th": "vitry-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.787507,
        "lng": 2.393792
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.367271,
        48.770025,
        2.422677,
        48.80846
      ]
    },
    {
      "id": 4953,
      "name": "Hengnan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.50852,
        "lng": 113.25332
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.23332,
        22.48852,
        113.27332,
        22.52852
      ]
    },
    {
      "id": 4954,
      "name": "El Cajon",
      "names": {
        "ja": "エルカヨン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7947731,
        "lng": -116.962526
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.0112205,
        32.7675175,
        -116.8957585,
        32.8309755
      ]
    },
    {
      "id": 4955,
      "name": "Nikaia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9657507,
        "lng": 23.6473298
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.6423298,
        37.9607507,
        23.6523298,
        37.9707507
      ]
    },
    {
      "id": 4956,
      "name": "Calasiao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.010505,
        "lng": 120.361061
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.341061,
        15.990505,
        120.381061,
        16.030505
      ]
    },
    {
      "id": 4957,
      "name": "Burbank",
      "names": {
        "zh": "伯班克",
        "ja": "バーバンク",
        "th": "เบอร์แบงก์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1812089,
        "lng": -118.307201
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.3701672,
        34.1426634,
        -118.2799902,
        34.2216629
      ]
    },
    {
      "id": 4958,
      "name": "Longmont",
      "names": {
        "zh": "朗蒙特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1672117,
        "lng": -105.101928
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.1783773,
        40.1263883,
        -105.017322,
        40.2099583
      ]
    },
    {
      "id": 4959,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0978929,
        "lng": -74.2176435
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.2586431,
        40.0239227,
        -74.151111,
        40.1203643
      ]
    },
    {
      "id": 4960,
      "name": "Mayari",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.65525,
        "lng": -75.6763378
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -75.9355366,
        20.400818,
        -75.4402205,
        20.7955161
      ]
    },
    {
      "id": 4961,
      "name": "Mositai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.5300063,
        "lng": 119.6573523
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.6373523,
        45.5100063,
        119.6773523,
        45.5500063
      ]
    },
    {
      "id": 4962,
      "name": "Dhangadhi",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.703304,
        "lng": 80.567017
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        80.407017,
        28.543304,
        80.727017,
        28.863304
      ]
    },
    {
      "id": 4963,
      "name": "Igarassu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8354423,
        "lng": -34.9061125
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.099194,
        -7.9,
        -34.8320161,
        -7.690612
      ]
    },
    {
      "id": 4964,
      "name": "Kashmar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.23831,
        "lng": 58.46558
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.44558,
        35.21831,
        58.48558,
        35.25831
      ]
    },
    {
      "id": 4965,
      "name": "Klagenfurt",
      "names": {
        "zh": "克拉根福",
        "ja": "クラーゲンフルト",
        "th": "คลาเกนฟูร์ท"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.623943,
        "lng": 14.3075976
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        14.2102806,
        46.5743838,
        14.416258,
        46.7087065
      ]
    },
    {
      "id": 4966,
      "name": "Delta",
      "names": {
        "zh": "三角洲",
        "ja": "デルタ",
        "th": "เดลต้า"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 38.8368777,
        "lng": -107.8568294
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -108.3789717,
        38.6679712,
        -107.499275,
        39.218127
      ]
    },
    {
      "id": 4967,
      "name": "Chilakalurupet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.090385,
        "lng": 80.165721
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.152045,
        16.079041,
        80.181913,
        16.104261
      ]
    },
    {
      "id": 4968,
      "name": "Chishtian",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.7945611,
        "lng": 72.857779
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.697779,
        29.6345611,
        73.017779,
        29.9545611
      ]
    },
    {
      "id": 4969,
      "name": "Sabratah",
      "names": {
        "zh": "塞卜拉泰"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.79335,
        "lng": 12.48845
      },
      "country": "Libya",
      "importance": 3,
      "bbox": [
        12.46845,
        32.77335,
        12.50845,
        32.81335
      ]
    },
    {
      "id": 4970,
      "name": "Leuven",
      "names": {
        "es": "Lovaina",
        "fr": "Louvain",
        "zh": "比利时鲁汶",
        "ja": "ルーヴェン"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.879202,
        "lng": 4.7011675
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.640295,
        50.8242096,
        4.7705305,
        50.9440707
      ]
    },
    {
      "id": 4971,
      "name": "Boryeong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.33401,
        "lng": 126.613165
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        125.5233697,
        36.1475742,
        126.7390966,
        36.5275526
      ]
    },
    {
      "id": 4972,
      "name": "Jingping",
      "names": {
        "zh": "净瓶灌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5178343,
        "lng": 112.2841385
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.2441385,
        39.4778343,
        112.3241385,
        39.5578343
      ]
    },
    {
      "id": 4973,
      "name": "Shushtar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.04447,
        "lng": 48.85793
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4974,
      "name": "Teluk Intan",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 4.023176,
        "lng": 101.026184
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        100.866184,
        3.863176,
        101.186184,
        4.183176
      ]
    },
    {
      "id": 4975,
      "name": "Santo Antonio de Jesus",
      "names": {
        "es": "Santo Antonio de Jesús",
        "fr": "Santo Antonio de Jésus",
        "zh": "Santo Antonio de耶稣",
        "ja": "サントアントニオデイエス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.9689361,
        "lng": -39.260914
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.3262582,
        -13.0980847,
        -39.1039812,
        -12.9342051
      ]
    },
    {
      "id": 4976,
      "name": "Assis",
      "names": {
        "zh": "阿西斯",
        "ja": "アシス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.657392,
        "lng": -50.41637
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.451263,
        -22.685617,
        -50.362567,
        -22.618808
      ]
    },
    {
      "id": 4977,
      "name": "Oued Zem",
      "names": {
        "fr": "Zem Oued Zem",
        "zh": "zem",
        "id": "Zem oued",
        "ja": "ZEM",
        "pt": "Zem oued"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.8650224,
        "lng": -6.5633494
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.5683494,
        32.8600224,
        -6.5583494,
        32.8700224
      ]
    },
    {
      "id": 4978,
      "name": "Renton",
      "names": {
        "zh": "伦顿",
        "ja": "レントン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.4799078,
        "lng": -122.2034496
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.254219,
        47.422984,
        -122.120286,
        47.539366
      ]
    },
    {
      "id": 4979,
      "name": "Erdenet",
      "names": {
        "zh": "额尔登特"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 49.0276045,
        "lng": 104.0443867
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        103.8843867,
        48.8676045,
        104.2043867,
        49.1876045
      ]
    },
    {
      "id": 4980,
      "name": "Chatham",
      "names": {
        "zh": "查塔姆",
        "ja": "チャタム",
        "th": "ชาตัม"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.4057219,
        "lng": -82.1853837
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -82.3453837,
        42.2457219,
        -82.0253837,
        42.5657219
      ]
    },
    {
      "id": 4981,
      "name": "`Ibri",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 23.23,
        "lng": 56.5
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        56.48,
        23.21,
        56.52,
        23.25
      ]
    },
    {
      "id": 4982,
      "name": "Vista",
      "names": {
        "zh": "Vista中",
        "ja": "ビスタ",
        "th": "วิสต้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2021284,
        "lng": -117.2424787
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2882718,
        33.1312176,
        -117.1922728,
        33.2384892
      ]
    },
    {
      "id": 4983,
      "name": "Zheleznogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.331,
        "lng": 35.3711
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        35.3511,
        52.311,
        35.3911,
        52.351
      ]
    },
    {
      "id": 4984,
      "name": "Logan",
      "names": {
        "zh": "洛根",
        "ja": "ローガン",
        "th": "โลแกน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7250975,
        "lng": -103.1221379
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -103.5814751,
        40.433372,
        -102.650208,
        41.0021219
      ]
    },
    {
      "id": 4985,
      "name": "Sungai Penuh",
      "names": {
        "es": "Penuh Sungai"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.0611606,
        "lng": 101.3986249
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        101.3786249,
        -2.0811606,
        101.4186249,
        -2.0411606
      ]
    },
    {
      "id": 4986,
      "name": "Lae",
      "names": {
        "zh": "抗癫痫联盟",
        "th": "แล"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.7301976,
        "lng": 147.0008109
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        146.8408109,
        -6.8901976,
        147.1608109,
        -6.5701976
      ]
    },
    {
      "id": 4987,
      "name": "Prescott Valley",
      "names": {
        "es": "Valle de Prescott",
        "fr": "Vallée de Prescott",
        "zh": "Prescott山谷",
        "id": "Lembah prescott",
        "ja": "プレスコットバレー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5950776,
        "lng": -112.3338634
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.386236,
        34.52784,
        -112.201681,
        34.672624
      ]
    },
    {
      "id": 4988,
      "name": "Trinidad",
      "names": {
        "fr": "Trinité",
        "zh": "特立尼达和多巴哥",
        "ja": "トリニダード",
        "th": "ตรินิแดด"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -14.8349438,
        "lng": -64.9044936
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -65.0623916,
        -15.0216025,
        -64.4435955,
        -14.7500589
      ]
    },
    {
      "id": 4989,
      "name": "Olomouc",
      "names": {
        "zh": "奥洛穆茨",
        "ja": "オロモウツ"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.5940567,
        "lng": 17.251143
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        17.1621222,
        49.5346988,
        17.396068,
        49.6619655
      ]
    },
    {
      "id": 4990,
      "name": "Balsas",
      "names": {
        "zh": "巴尔萨斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.53214,
        "lng": -46.037163
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.068,
        -9.414,
        -45.6709483,
        -7.1599018
      ]
    },
    {
      "id": 4991,
      "name": "Pleven",
      "names": {
        "zh": "普列文"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.409022,
        "lng": 24.6180123
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        24.4580123,
        43.249022,
        24.7780123,
        43.569022
      ]
    },
    {
      "id": 4992,
      "name": "Vacaville",
      "names": {
        "zh": "瓦卡维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.3565773,
        "lng": -121.9877444
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.032454,
        38.3083067,
        -121.8949061,
        38.418291
      ]
    },
    {
      "id": 4993,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.050096,
        "lng": -121.99059
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.3220529,
        36.8488209,
        -121.5823066,
        37.2876789
      ]
    },
    {
      "id": 4994,
      "name": "San Luis de la Paz",
      "names": {
        "zh": "圣路易斯德拉巴斯",
        "ja": "サンルイスデラパズ",
        "th": "ซานหลุยส์เดอลาปาซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.29787,
        "lng": -100.51676
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.52176,
        21.29287,
        -100.51176,
        21.30287
      ]
    },
    {
      "id": 4995,
      "name": "Ain Oussera",
      "names": {
        "es": "Ain ossera",
        "ja": "アニスゼラ",
        "pt": "Ain Ossera"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4487268,
        "lng": 2.9073269
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        2.7194249,
        35.1648624,
        3.0084715,
        35.8280789
      ]
    },
    {
      "id": 4996,
      "name": "Liancheng",
      "names": {
        "zh": "连城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.0506895,
        "lng": 105.0628917
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.0228917,
        24.0106895,
        105.1028917,
        24.0906895
      ]
    },
    {
      "id": 4997,
      "name": "Kawachinagano",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4575979,
        "lng": 135.5643131
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.485376,
        34.336238,
        135.663382,
        34.4798406
      ]
    },
    {
      "id": 4998,
      "name": "Kousseri",
      "names": {
        "zh": "库塞里"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 12.0831175,
        "lng": 15.0191262
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        14.8764223,
        11.9935743,
        15.0606502,
        12.1286
      ]
    },
    {
      "id": 4999,
      "name": "Mestre",
      "names": {
        "zh": "梅斯特雷",
        "ja": "メストレ",
        "th": "เมสเตร"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4943063,
        "lng": 12.2418053
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.2018053,
        45.4543063,
        12.2818053,
        45.5343063
      ]
    },
    {
      "id": 5000,
      "name": "Rades",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.76806,
        "lng": 10.27528
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.25528,
        36.74806,
        10.29528,
        36.78806
      ]
    },
    {
      "id": 5001,
      "name": "Weifen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4644051,
        "lng": 111.1210546
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.0810546,
        38.4244051,
        111.1610546,
        38.5044051
      ]
    },
    {
      "id": 5002,
      "name": "Edinburg",
      "names": {
        "zh": "爱丁堡",
        "th": "เอดินเบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.3013982,
        "lng": -98.1624501
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.2261723,
        26.2448246,
        -98.0956362,
        26.4509177
      ]
    },
    {
      "id": 5003,
      "name": "Kanoya",
      "names": {
        "zh": "鹿屋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.3780472,
        "lng": 130.8525167
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.6624816,
        31.227785,
        130.972205,
        31.616962
      ]
    },
    {
      "id": 5004,
      "name": "Gutersloh",
      "names": {
        "es": "Gütersloh",
        "fr": "Gütersloh",
        "zh": "居特斯洛",
        "de": "Gütersloh",
        "pt": "Gütersloh"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.903311,
        "lng": 8.390601
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.285622,
        51.856014,
        8.505988,
        51.977252
      ]
    },
    {
      "id": 5005,
      "name": "Oum el Bouaghi",
      "names": {
        "id": "Oum el boughi"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.8687758,
        "lng": 7.1150645
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.9286717,
        35.7147836,
        7.2609986,
        35.9606268
      ]
    },
    {
      "id": 5006,
      "name": "Carmel",
      "names": {
        "zh": "卡梅尔",
        "ja": "カーメル",
        "th": "คาร์เมล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9784186,
        "lng": -86.1283681
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.2410714,
        39.9260355,
        -86.023519,
        40.0031206
      ]
    },
    {
      "id": 5007,
      "name": "Spokane Valley",
      "names": {
        "es": "Valle de Spokane",
        "fr": "Vallée de Spokane",
        "zh": "斯波坎山谷",
        "de": "Spokane-Tal",
        "id": "Lembah Spokane",
        "ja": "スポケーン渓谷",
        "th": "สโปเคนหุบเขา",
        "pt": "Vale de Spokane"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.659214,
        "lng": -117.241632
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.347048,
        47.600211,
        -117.142866,
        47.709968
      ]
    },
    {
      "id": 5008,
      "name": "Ancona",
      "names": {
        "zh": "安科纳",
        "ja": "アンコーナ",
        "th": "โคนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.6170137,
        "lng": 13.5170982
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        13.3761772,
        43.5085486,
        13.6090649,
        43.6314568
      ]
    },
    {
      "id": 5009,
      "name": "San Angelo",
      "names": {
        "zh": "圣安吉洛",
        "ja": "サンアンジェロ",
        "th": "ซานแองเจโล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.4649685,
        "lng": -100.4405094
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -100.5292906,
        31.3355045,
        -100.3599256,
        31.5264855
      ]
    },
    {
      "id": 5010,
      "name": "Port Blair",
      "names": {
        "zh": "港口布莱尔",
        "de": "Blair",
        "ja": "ポートブレア",
        "th": "พอร์ตแบลร์",
        "pt": "Porto blair"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.6645348,
        "lng": 92.7390448
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        92.5790448,
        11.5045348,
        92.8990448,
        11.8245348
      ]
    },
    {
      "id": 5011,
      "name": "Karabuk",
      "names": {
        "zh": "卡拉比克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1955402,
        "lng": 32.6231154
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        32.4377524,
        40.9966223,
        32.8163477,
        41.3601099
      ]
    },
    {
      "id": 5012,
      "name": "Muriae",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1313485,
        "lng": -42.3628918
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.566,
        -21.307,
        -42.253,
        -20.866
      ]
    },
    {
      "id": 5013,
      "name": "Salihorsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.7876,
        "lng": 27.5415
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.5215,
        52.7676,
        27.5615,
        52.8076
      ]
    },
    {
      "id": 5014,
      "name": "Kpalime",
      "names": {},
      "countryCode": "TG",
      "latLng": {
        "lat": 6.9106565,
        "lng": 0.6323982
      },
      "country": "Togo",
      "importance": 3,
      "bbox": [
        0.4723982,
        6.7506565,
        0.7923982,
        7.0706565
      ]
    },
    {
      "id": 5015,
      "name": "Umuarama",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7621152,
        "lng": -53.3116192
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.6630223,
        -23.965,
        -53.1760064,
        -23.4488245
      ]
    },
    {
      "id": 5016,
      "name": "Kaiserslautern",
      "names": {
        "zh": "凯泽斯劳滕",
        "th": "เลาเทิร์น"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4432174,
        "lng": 7.7689951
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6271007,
        49.3587066,
        7.8706819,
        49.4951266
      ]
    },
    {
      "id": 5017,
      "name": "Gangtok",
      "names": {
        "zh": "甘托克",
        "ja": "ガントク",
        "th": "กังต็อก"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.332196,
        "lng": 88.614194
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        88.601949,
        27.320584,
        88.616887,
        27.3447
      ]
    },
    {
      "id": 5018,
      "name": "Diourbel",
      "names": {},
      "countryCode": "SN",
      "latLng": {
        "lat": 14.654562,
        "lng": -16.227822
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.2740793,
        14.6259181,
        -16.1904202,
        14.6835634
      ]
    },
    {
      "id": 5019,
      "name": "La Crosse",
      "names": {
        "zh": "La Croosse",
        "it": "La Cross",
        "ja": "ラクロス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.8333134,
        "lng": -91.2471919
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -91.2521919,
        43.8283134,
        -91.2421919,
        43.8383134
      ]
    },
    {
      "id": 5020,
      "name": "Ahar",
      "names": {
        "zh": "阿哈尔"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 38.4774934,
        "lng": 47.0662215
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        47.0433229,
        38.4633027,
        47.0921606,
        38.4976138
      ]
    },
    {
      "id": 5021,
      "name": "Konan",
      "names": {
        "zh": "湖南",
        "ja": "甲南"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3349527,
        "lng": 136.8721818
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.8671818,
        35.3299527,
        136.8771818,
        35.3399527
      ]
    },
    {
      "id": 5022,
      "name": "Masjed Soleyman",
      "names": {
        "es": "Masjed Solyman",
        "it": "Maschio Soleyman",
        "pt": "Soleyman masjed"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.9364,
        "lng": 49.3039
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.2839,
        31.9164,
        49.3239,
        31.9564
      ]
    },
    {
      "id": 5023,
      "name": "Mairipora",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3190348,
        "lng": -46.5876377
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.6705173,
        -23.430946,
        -46.4167269,
        -23.2254685
      ]
    },
    {
      "id": 5024,
      "name": "Torbat-e Jam",
      "names": {
        "es": "Atasco torbat-e",
        "zh": "torbat-e果酱",
        "de": "Torbat-e Marmelade",
        "ja": "Torbat-eジャム",
        "th": "Torbat-e แยม"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.244,
        "lng": 60.6225
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        60.6025,
        35.224,
        60.6425,
        35.264
      ]
    },
    {
      "id": 5025,
      "name": "Edison",
      "names": {
        "zh": "爱迪生",
        "ja": "エジソン",
        "th": "เอดิสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.51877,
        "lng": -74.4117555
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.4167555,
        40.51377,
        -74.4067555,
        40.52377
      ]
    },
    {
      "id": 5026,
      "name": "Idaho Falls",
      "names": {
        "fr": "Idaho tombe",
        "zh": "爱达荷瀑布",
        "de": "Idaho fällt",
        "ja": "アイダホが落ちる",
        "th": "ไอดาโฮฟอลส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4887907,
        "lng": -112.03628
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.097687,
        43.431806,
        -111.974251,
        43.558954
      ]
    },
    {
      "id": 5027,
      "name": "Lamitan",
      "names": {
        "zh": "拉米坦"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6579664,
        "lng": 122.1371052
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.0284117,
        6.5572335,
        122.202746,
        6.8083688
      ]
    },
    {
      "id": 5028,
      "name": "Holland",
      "names": {
        "es": "Holanda",
        "zh": "荷兰",
        "it": "Olanda",
        "id": "Belanda",
        "ja": "オランダ",
        "th": "ฮอลแลนด์",
        "pt": "HOLANDA"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7876022,
        "lng": -86.1090828
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.166987,
        42.729343,
        -86.047901,
        42.8028369
      ]
    },
    {
      "id": 5029,
      "name": "Red Deer",
      "names": {
        "es": "Ciervo rojo",
        "fr": "Cerf rouge",
        "zh": "红鹿",
        "de": "Rotwild",
        "it": "Cervo",
        "id": "Rusa merah",
        "ja": "赤い鹿",
        "th": "กวางแดง",
        "pt": "Veado vermelho"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 52.2690628,
        "lng": -113.8141464
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -113.8977285,
        52.215729,
        -113.718502,
        52.3429183
      ]
    },
    {
      "id": 5030,
      "name": "Charlottesville",
      "names": {
        "zh": "夏洛茨维尔",
        "th": "ชาร์ลอตส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0314369,
        "lng": -78.4917906
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.4967906,
        38.0264369,
        -78.4867906,
        38.0364369
      ]
    },
    {
      "id": 5031,
      "name": "Shahrisabz",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.0525821,
        "lng": 66.8278643
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        66.7896433,
        39.018656,
        66.8811761,
        39.1058604
      ]
    },
    {
      "id": 5032,
      "name": "Talipao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9762265,
        "lng": 121.1146071
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.0746071,
        5.9362265,
        121.1546071,
        6.0162265
      ]
    },
    {
      "id": 5033,
      "name": "Longview",
      "names": {
        "zh": "朗维尤",
        "ja": "ロングビュー",
        "th": "วิว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5007031,
        "lng": -94.74049
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.84434,
        32.4315294,
        -94.6633492,
        32.5895599
      ]
    },
    {
      "id": 5034,
      "name": "Creteil",
      "names": {
        "es": "Créteil",
        "fr": "Créteil",
        "zh": "克雷泰伊"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7864661,
        "lng": 2.4579131717540204
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.4566315,
        48.7857733,
        2.4588374,
        48.7870157
      ]
    },
    {
      "id": 5035,
      "name": "Lincoln",
      "names": {
        "zh": "林肯",
        "ja": "リンカーン",
        "th": "ลิงคอล์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.2293545,
        "lng": -0.5404819
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.6226181,
        53.1863011,
        -0.493915,
        53.2545498
      ]
    },
    {
      "id": 5036,
      "name": "Navapolatsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.5348855,
        "lng": 28.64519
      },
      "country": "Belarus",
      "importance": 3,
      "bbox": [
        28.4940663,
        55.4857674,
        28.7205646,
        55.6045307
      ]
    },
    {
      "id": 5037,
      "name": "Formosa",
      "names": {
        "zh": "福尔摩沙",
        "ja": "台湾",
        "th": "ฟอร์โมซา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.5492443,
        "lng": -47.3301212
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.576,
        -16.0377051,
        -46.835,
        -14.5360026
      ]
    },
    {
      "id": 5038,
      "name": "Nanxicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6185045,
        "lng": 108.805333
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.785333,
        32.5985045,
        108.825333,
        32.6385045
      ]
    },
    {
      "id": 5039,
      "name": "Woodbridge",
      "names": {
        "zh": "伍德布里奇",
        "ja": "ウッドブリッジ",
        "th": "วูดบริดจ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.55418,
        "lng": -74.28600076944446
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.308582,
        40.536091,
        -74.2600257,
        40.572547
      ]
    },
    {
      "id": 5040,
      "name": "Bacabal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2318132,
        "lng": -44.781167
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.083,
        -4.3842791,
        -44.479,
        -3.8547935
      ]
    },
    {
      "id": 5041,
      "name": "Tracy",
      "names": {
        "zh": "特雷西",
        "ja": "トレイシー",
        "th": "เทรซี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7385507,
        "lng": -121.420139
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.5487965,
        37.6617844,
        -121.3801268,
        37.779658
      ]
    },
    {
      "id": 5042,
      "name": "Kamloops",
      "names": {
        "zh": "坎卢普斯",
        "ja": "カムループス",
        "th": "ลูปส์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 50.6758269,
        "lng": -120.339415
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -120.5424391,
        50.6155084,
        -120.041753,
        50.8664283
      ]
    },
    {
      "id": 5043,
      "name": "Rayleigh",
      "names": {
        "zh": "瑞利",
        "ja": "レイリー",
        "th": "เรย์ลี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.58571,
        "lng": 0.60459
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.562305,
        51.57047,
        0.649293,
        51.617757
      ]
    },
    {
      "id": 5044,
      "name": "Dunedin",
      "names": {
        "zh": "但尼丁",
        "ja": "ダニーデン",
        "th": "ดะนีดิน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -45.8740984,
        "lng": 170.5035755
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        170.3435755,
        -46.0340984,
        170.6635755,
        -45.7140984
      ]
    },
    {
      "id": 5045,
      "name": "Ramnicu Valcea",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 45.1032547,
        "lng": 24.3726801
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        24.3676801,
        45.0982547,
        24.3776801,
        45.1082547
      ]
    },
    {
      "id": 5046,
      "name": "Viseu",
      "names": {
        "zh": "维塞乌"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6574713,
        "lng": -7.9138664
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.0915889,
        40.5357325,
        -7.7589842,
        40.8425998
      ]
    },
    {
      "id": 5047,
      "name": "Ashiya",
      "names": {
        "zh": "芦屋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7279281,
        "lng": 135.3038373
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.2988373,
        34.7229281,
        135.3088373,
        34.7329281
      ]
    },
    {
      "id": 5048,
      "name": "Dhulian",
      "names": {
        "zh": "图利扬"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.6786543,
        "lng": 87.954654
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.914654,
        24.6386543,
        87.994654,
        24.7186543
      ]
    },
    {
      "id": 5049,
      "name": "Bayeux",
      "names": {
        "zh": "巴约",
        "ja": "バイユー"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.1301631,
        "lng": -34.936532
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -34.9570692,
        -7.1710534,
        -34.8912264,
        -7.0796241
      ]
    },
    {
      "id": 5050,
      "name": "Kohima",
      "names": {
        "zh": "科希马"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.6618755,
        "lng": 94.1019156
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        93.9419156,
        25.5018755,
        94.2619156,
        25.8218755
      ]
    },
    {
      "id": 5051,
      "name": "Hemel Hempstead",
      "names": {
        "ja": "ヘメルヘムステッド"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.75414515,
        "lng": -0.46855869219108826
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.5068146,
        51.7274952,
        -0.427904,
        51.780073
      ]
    },
    {
      "id": 5052,
      "name": "Aubervilliers",
      "names": {
        "zh": "欧贝维利耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9146078,
        "lng": 2.3821895
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.3656078,
        48.9012193,
        2.41198,
        48.9244528
      ]
    },
    {
      "id": 5053,
      "name": "Mingacevir",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.768751,
        "lng": 47.04761
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 5054,
      "name": "Chipata",
      "names": {
        "zh": "奇帕塔"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -13.6391314,
        "lng": 32.6458437
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        32.4858437,
        -13.7991314,
        32.8058437,
        -13.4791314
      ]
    },
    {
      "id": 5055,
      "name": "Khanty-Mansiysk",
      "names": {
        "zh": "汉特 -  mansiysk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 60.996432,
        "lng": 69.007536
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        68.976059,
        60.969307,
        69.06028,
        61.023888
      ]
    },
    {
      "id": 5056,
      "name": "Ciudad General Belgrano",
      "names": {
        "id": "Ciudad Jenderal Belgrano"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.72296,
        "lng": -58.52979
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.54979,
        -34.74296,
        -58.50979,
        -34.70296
      ]
    },
    {
      "id": 5057,
      "name": "Maribor",
      "names": {
        "zh": "马里博尔",
        "ja": "マリボル",
        "th": "มาริบอร์"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5576439,
        "lng": 15.6455854
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.5169925,
        46.4994068,
        15.7722677,
        46.6342916
      ]
    },
    {
      "id": 5058,
      "name": "Galle",
      "names": {
        "zh": "加勒",
        "th": "กอลล์"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.0328139,
        "lng": 80.214955
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        80.1719297,
        6.0236163,
        80.2475684,
        6.0939686
      ]
    },
    {
      "id": 5059,
      "name": "Yashio",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8226404,
        "lng": 139.8386867
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.811417,
        35.7908206,
        139.869348,
        35.858086
      ]
    },
    {
      "id": 5060,
      "name": "Bismarck",
      "names": {
        "zh": "俾斯麦",
        "ja": "ビスマルク",
        "th": "บิสมาร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.808327,
        "lng": -100.783739
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -100.845596,
        46.751104,
        -100.688513,
        46.867048
      ]
    },
    {
      "id": 5061,
      "name": "Herzliyya",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.171739,
        "lng": 34.825672
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.791321,
        32.140741,
        34.864668,
        32.202197
      ]
    },
    {
      "id": 5062,
      "name": "Yen Bai",
      "names": {
        "zh": "日元白",
        "ja": "円のバイ",
        "th": "เยนใบ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.7024241,
        "lng": 104.880732
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        104.875732,
        21.6974241,
        104.885732,
        21.7074241
      ]
    },
    {
      "id": 5063,
      "name": "Santiago de Compostela",
      "names": {
        "fr": "Santiago de Compostelle"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.8804219,
        "lng": -8.5458608
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -8.6320116,
        42.8241442,
        -8.3900878,
        42.989631
      ]
    },
    {
      "id": 5064,
      "name": "Malindi",
      "names": {
        "zh": "马林迪"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -3.2165987,
        "lng": 40.1165933
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        40.090085,
        -3.2686296,
        40.1325311,
        -3.1683651
      ]
    },
    {
      "id": 5065,
      "name": "Catarman",
      "names": {
        "zh": "卡塔曼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.499648,
        "lng": 124.6364767
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.493812,
        12.3042618,
        124.7354786,
        12.6916824
      ]
    },
    {
      "id": 5066,
      "name": "Bath",
      "names": {
        "es": "Baño",
        "fr": "Une baignoire",
        "zh": "浴",
        "de": "Bad",
        "it": "Vasca da bagno",
        "id": "Mandi",
        "ja": "バスタブ",
        "th": "อาบน้ำ",
        "pt": "Banho de banheira"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3813864,
        "lng": -2.3596963
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4141955,
        51.3505346,
        -2.3157642,
        51.4112023
      ]
    },
    {
      "id": 5067,
      "name": "Janakpur",
      "names": {
        "zh": "贾纳克布尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 26.71828,
        "lng": 85.90646
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        85.88646,
        26.69828,
        85.92646,
        26.73828
      ]
    },
    {
      "id": 5068,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.4646672,
        "lng": -6.1983492
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.241792,
        36.3786126,
        -6.1682303,
        36.5099605
      ]
    },
    {
      "id": 5069,
      "name": "Half Way Tree",
      "names": {
        "es": "Árbol de mitad de camino",
        "fr": "Arbre à mi-chemin",
        "zh": "一半的树",
        "de": "Halber Wechselbaum",
        "it": "Albero a metà strada",
        "id": "Pohon setengah jalan",
        "ja": "半道ツリー",
        "th": "ต้นไม้ครึ่งทาง",
        "pt": "Metade da árvore"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.0118757,
        "lng": -76.7968643
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -76.8168643,
        17.9918757,
        -76.7768643,
        18.0318757
      ]
    },
    {
      "id": 5070,
      "name": "Orem",
      "names": {
        "zh": "奥勒姆",
        "ja": "オレム",
        "th": "โอเรม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2981599,
        "lng": -111.6944313
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.7529994,
        40.2568745,
        -111.6345531,
        40.3338323
      ]
    },
    {
      "id": 5071,
      "name": "Inagi",
      "names": {
        "zh": "稻城"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.638229,
        "lng": 139.507776
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4639654,
        35.6044799,
        139.525333,
        35.6526286
      ]
    },
    {
      "id": 5072,
      "name": "Vi Thanh",
      "names": {
        "it": "Vi di thanh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.78449,
        "lng": 105.47012
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.45012,
        9.76449,
        105.49012,
        9.80449
      ]
    },
    {
      "id": 5073,
      "name": "San Joaquin",
      "names": {
        "es": "San Joaquín",
        "zh": "圣乔金",
        "ja": "サンジョアキン",
        "th": "ซานโจควิน"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.3042517,
        "lng": -70.7028623
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.7228623,
        -33.3242517,
        -70.6828623,
        -33.2842517
      ]
    },
    {
      "id": 5074,
      "name": "Tumbes",
      "names": {
        "zh": "通贝斯"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -3.5707875,
        "lng": -80.4596454
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -80.6196454,
        -3.7307875,
        -80.2996454,
        -3.4107875
      ]
    },
    {
      "id": 5075,
      "name": "Isulan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6340869,
        "lng": 124.5971379
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.5571379,
        6.5940869,
        124.6371379,
        6.6740869
      ]
    },
    {
      "id": 5076,
      "name": "Tecoman",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9109158,
        "lng": -103.873409
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.033409,
        18.7509158,
        -103.713409,
        19.0709158
      ]
    },
    {
      "id": 5077,
      "name": "Petapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.50278,
        "lng": -90.55167
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.57167,
        14.48278,
        -90.53167,
        14.52278
      ]
    },
    {
      "id": 5078,
      "name": "Sidi Slimane",
      "names": {
        "it": "Sidi slipane"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.259878,
        "lng": -5.927253
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.087253,
        34.099878,
        -5.767253,
        34.419878
      ]
    },
    {
      "id": 5079,
      "name": "Xirdalan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.44808,
        "lng": 49.75502
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        49.73502,
        40.42808,
        49.77502,
        40.46808
      ]
    },
    {
      "id": 5080,
      "name": "Wangqing",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.3129168,
        "lng": 129.7608195
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        129.0900321,
        43.1088908,
        130.8850912,
        44.0491203
      ]
    },
    {
      "id": 5081,
      "name": "Komae",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.634023,
        "lng": 139.575977
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5617642,
        35.6183569,
        139.5941365,
        35.650744
      ]
    },
    {
      "id": 5082,
      "name": "Colombes",
      "names": {
        "ja": "コロンブ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.922788,
        "lng": 2.2543577
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.2204263,
        48.9060291,
        2.2733245,
        48.9376156
      ]
    },
    {
      "id": 5083,
      "name": "Darlington",
      "names": {
        "zh": "达林顿",
        "ja": "ダーリントン",
        "th": "ดาร์ลิงตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.5242081,
        "lng": -1.5555812
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.5955812,
        54.4842081,
        -1.5155812,
        54.5642081
      ]
    },
    {
      "id": 5084,
      "name": "Sibolga",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.741088,
        "lng": 98.772323
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        98.719647,
        1.697139,
        98.858478,
        1.805018
      ]
    },
    {
      "id": 5085,
      "name": "Bunia",
      "names": {
        "zh": "布尼亚"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 1.5620529,
        "lng": 30.2478844
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        30.2019808,
        1.5009829,
        30.3171688,
        1.6070549
      ]
    },
    {
      "id": 5086,
      "name": "Arauca",
      "names": {
        "zh": "阿劳卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0851994,
        "lng": -70.7577762
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -70.9177762,
        6.9251994,
        -70.5977762,
        7.2451994
      ]
    },
    {
      "id": 5087,
      "name": "La Reina",
      "names": {
        "zh": "拉雷纳",
        "ja": "ラリーナ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.44737295,
        "lng": -70.53341181233888
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.5844385,
        -33.4654591,
        -70.4859715,
        -33.4288418
      ]
    },
    {
      "id": 5088,
      "name": "La Marsa",
      "names": {
        "ja": "ラマルサ"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.87818,
        "lng": 10.32466
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.30466,
        36.85818,
        10.34466,
        36.89818
      ]
    },
    {
      "id": 5089,
      "name": "Yishui",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2666751,
        "lng": 110.089043
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.049043,
        32.2266751,
        110.129043,
        32.3066751
      ]
    },
    {
      "id": 5090,
      "name": "Marmagao",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.406914,
        "lng": 73.795225
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.783113,
        15.394276,
        73.807417,
        15.41583
      ]
    },
    {
      "id": 5091,
      "name": "Levallois-Perret",
      "names": {
        "zh": "勒瓦卢瓦 - 佩雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.89519,
        "lng": 2.286671
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.271065,
        48.885638,
        2.303777,
        48.903642
      ]
    },
    {
      "id": 5092,
      "name": "San Francisco Solano",
      "names": {
        "zh": "旧金山索拉诺",
        "ja": "サンフランシスコソラノ",
        "th": "ซานฟรานซิสโก Solano",
        "pt": "São Francisco Solano"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7779347,
        "lng": -58.3078958
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.3267962,
        -34.8013593,
        -58.2984942,
        -34.7582639
      ]
    },
    {
      "id": 5093,
      "name": "Zifta",
      "names": {
        "zh": "济夫塔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.7142,
        "lng": 31.24425
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.22425,
        30.6942,
        31.26425,
        30.7342
      ]
    },
    {
      "id": 5094,
      "name": "Kakamega",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.2833,
        "lng": 34.75
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.7336565,
        0.2646762,
        34.7683363,
        0.2989458
      ]
    },
    {
      "id": 5095,
      "name": "Schwerin",
      "names": {
        "zh": "什未林",
        "it": "Di Schwerin",
        "ja": "シュヴェリーン",
        "th": "ชเวริน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.6288297,
        "lng": 11.4148038
      },
      "country": "Germany",
      "importance": 1,
      "bbox": [
        11.2960272,
        53.5443597,
        11.505544,
        53.6873387
      ]
    },
    {
      "id": 5096,
      "name": "Guercif",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.225576,
        "lng": -3.352345
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -3.3859132,
        34.20885,
        -3.3161958,
        34.2614687
      ]
    },
    {
      "id": 5097,
      "name": "Szekesfehervar",
      "names": {
        "fr": "Székesfehérvár",
        "de": "Székesfehérvár",
        "pt": "Székesfehérvár"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1910169,
        "lng": 18.410811
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        18.3288534,
        47.1080255,
        18.5537518,
        47.2814844
      ]
    },
    {
      "id": 5098,
      "name": "Errachidia",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 31.929089,
        "lng": -4.4340807
      },
      "country": "Morocco",
      "importance": 1,
      "bbox": [
        -4.4739562,
        31.8995639,
        -4.3969254,
        31.9607528
      ]
    },
    {
      "id": 5099,
      "name": "Lingtang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4277037,
        "lng": 113.6349848
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.6149848,
        25.4077037,
        113.6549848,
        25.4477037
      ]
    },
    {
      "id": 5100,
      "name": "Yotsukaido",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6696551,
        "lng": 140.1679445
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.136495,
        35.625475,
        140.214926,
        35.707319
      ]
    },
    {
      "id": 5101,
      "name": "Ceske Budejovice",
      "names": {},
      "countryCode": "CZ",
      "latLng": {
        "lat": 48.9747357,
        "lng": 14.474285
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        14.3869152,
        48.9334343,
        14.5951911,
        49.0198566
      ]
    },
    {
      "id": 5102,
      "name": "Rio Gallegos",
      "names": {
        "zh": "里约加里戈斯",
        "it": "Rio galegos",
        "id": "Rio gurlegos",
        "ja": "リオガレゴス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -51.629526,
        "lng": -69.22966
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -69.24966,
        -51.649526,
        -69.20966,
        -51.609526
      ]
    },
    {
      "id": 5103,
      "name": "Fenggang",
      "names": {
        "zh": "凤岗"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7499783,
        "lng": 114.1318053
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.1003726,
        22.6564773,
        114.2106876,
        22.7821658
      ]
    },
    {
      "id": 5104,
      "name": "Tataouine",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 32.9316261,
        "lng": 10.45043
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.45038,
        32.9315761,
        10.45048,
        32.9316761
      ]
    },
    {
      "id": 5105,
      "name": "Ludwigsburg",
      "names": {
        "zh": "路德维希堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8953937,
        "lng": 9.1895147
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.1374273,
        48.8734228,
        9.3124752,
        48.932556
      ]
    },
    {
      "id": 5106,
      "name": "Compton",
      "names": {
        "zh": "康普顿",
        "ja": "コンプトン",
        "th": "คอมป์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.894927,
        "lng": -118.226624
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.263659,
        33.8630465,
        -118.179946,
        33.9231979
      ]
    },
    {
      "id": 5107,
      "name": "Esslingen",
      "names": {
        "zh": "埃斯林根",
        "ja": "エスリンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7427584,
        "lng": 9.3071685
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.2562418,
        48.7065634,
        9.4165802,
        48.7757682
      ]
    },
    {
      "id": 5108,
      "name": "Nisshin",
      "names": {
        "zh": "日进",
        "ja": "日清"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.123151,
        "lng": 137.047866
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.027866,
        35.103151,
        137.067866,
        35.143151
      ]
    },
    {
      "id": 5109,
      "name": "Xiancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1845377,
        "lng": 113.7099341
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.6746815,
        23.1295995,
        113.7520947,
        23.2396009
      ]
    },
    {
      "id": 5110,
      "name": "Obu",
      "names": {
        "zh": "车载单元"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.008769,
        "lng": 136.96176
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.95676,
        35.003769,
        136.96676,
        35.013769
      ]
    },
    {
      "id": 5111,
      "name": "Sunrise",
      "names": {
        "es": "Amanecer",
        "fr": "Lever du soleil",
        "zh": "日出",
        "de": "Sonnenaufgang",
        "it": "Alba",
        "ja": "サンライズ",
        "th": "พระอาทิตย์ขึ้น",
        "pt": "Nascer do sol"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1666888,
        "lng": -80.2787573
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.36649,
        26.1022942,
        -80.226671,
        26.1937614
      ]
    },
    {
      "id": 5112,
      "name": "Wimbledon",
      "names": {
        "zh": "温布尔登",
        "ja": "ウィンブルドン",
        "th": "วิมเบิลดัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4214787,
        "lng": -0.2064027
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.2464027,
        51.3814787,
        -0.1664027,
        51.4614787
      ]
    },
    {
      "id": 5113,
      "name": "Ciudad Lazaro Cardenas",
      "names": {
        "es": "Ciudad Lázaro Cárdenas"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9594769,
        "lng": -102.198659
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -102.358659,
        17.7994769,
        -102.038659,
        18.1194769
      ]
    },
    {
      "id": 5114,
      "name": "Mtwara",
      "names": {
        "zh": "姆特瓦拉"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -10.266782,
        "lng": 40.185842
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        40.1658425,
        -10.286782,
        40.2058425,
        -10.246782
      ]
    },
    {
      "id": 5115,
      "name": "Ben Arous",
      "names": {
        "es": "Ben aus",
        "zh": "本志",
        "de": "Ben erregend",
        "ja": "ベン根",
        "th": "เบ็นอาร์ออส"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.7520963,
        "lng": 10.223339336788035
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.2229411,
        36.7517789,
        10.2236996,
        36.7524155
      ]
    },
    {
      "id": 5116,
      "name": "Watford",
      "names": {
        "zh": "沃特福德",
        "ja": "ワトフォード",
        "th": "เฟิร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6553875,
        "lng": -0.3957425
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.439565,
        51.6370946,
        -0.3688395,
        51.7020019
      ]
    },
    {
      "id": 5117,
      "name": "Quincy",
      "names": {
        "zh": "昆西",
        "ja": "クインシー",
        "th": "ควินซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2509914,
        "lng": -71.0037374
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.093189,
        42.2041797,
        -70.9377406,
        42.3138078
      ]
    },
    {
      "id": 5118,
      "name": "Monte Chingolo",
      "names": {
        "zh": "蒙特Chingolo",
        "ja": "モンテチンノーロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7334493,
        "lng": -58.3518877
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.36034,
        -34.739991,
        -58.3441585,
        -34.7200071
      ]
    },
    {
      "id": 5119,
      "name": "Lynn",
      "names": {
        "zh": "琳",
        "th": "ลินน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.466763,
        "lng": -70.9494939
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.0116206,
        42.4074144,
        -70.8154905,
        42.5122672
      ]
    },
    {
      "id": 5120,
      "name": "Drobeta-Turnu Severin",
      "names": {
        "es": "DrobaTa-Turnu severin",
        "fr": "Drobeta-thermu Severin",
        "de": "DroboTa-Turnu Severin"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.633429,
        "lng": 22.649747
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        22.607883,
        44.617465,
        22.697971,
        44.656022
      ]
    },
    {
      "id": 5121,
      "name": "Ben Guerir",
      "names": {
        "zh": "本格雷尔",
        "ja": "ベンギアル",
        "th": "เบ็นโกโรเรี"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.239034,
        "lng": -7.958131
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.971871,
        32.2047434,
        -7.9150736,
        32.2640592
      ]
    },
    {
      "id": 5122,
      "name": "Vanadzor",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.8112384,
        "lng": 44.4851821
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        44.4199857,
        40.7783561,
        44.5538541,
        40.8487868
      ]
    },
    {
      "id": 5123,
      "name": "Hastings",
      "names": {
        "zh": "黑斯廷斯",
        "ja": "ヘイスティングス",
        "th": "เฮสติ้งส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8583028,
        "lng": 0.5768255
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.5718255,
        50.8533028,
        0.5818255,
        50.8633028
      ]
    },
    {
      "id": 5124,
      "name": "Suceava",
      "names": {
        "zh": "苏恰瓦"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.647001,
        "lng": 26.2522599
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        26.2051783,
        47.6086406,
        26.3188346,
        47.7193598
      ]
    },
    {
      "id": 5125,
      "name": "Al Fujayrah",
      "names": {
        "ja": "アルフジャイラ"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.12614,
        "lng": 56.3336
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 5126,
      "name": "Brusque",
      "names": {
        "es": "Brusco",
        "fr": "Bourru",
        "zh": "粗暴",
        "id": "Kasar",
        "ja": "ぶっきらぼう",
        "th": "รีบ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.0964628,
        "lng": -48.9136323
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.0233318,
        -27.256,
        -48.79,
        -26.998907
      ]
    },
    {
      "id": 5127,
      "name": "Amstelveen",
      "names": {
        "zh": "阿姆斯特尔芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3028209,
        "lng": 4.8596844
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7945456,
        52.2418678,
        4.9091513,
        52.3305699
      ]
    },
    {
      "id": 5128,
      "name": "Tartu",
      "names": {
        "zh": "塔尔图",
        "ja": "タルトゥ"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.3801207,
        "lng": 26.72245
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        26.6670755,
        58.3390114,
        26.7980072,
        58.4108848
      ]
    },
    {
      "id": 5129,
      "name": "Makeni",
      "names": {
        "zh": "马克尼"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.8881938,
        "lng": -12.0441862
      },
      "country": "Sierra Leone",
      "importance": 1,
      "bbox": [
        -12.2041862,
        8.7281938,
        -11.8841862,
        9.0481938
      ]
    },
    {
      "id": 5130,
      "name": "Itanhaem",
      "names": {
        "pt": "Itanhaém"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.1848847,
        "lng": -46.7921572
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.984,
        -24.253,
        -46.6450912,
        -23.9383948
      ]
    },
    {
      "id": 5131,
      "name": "Hradec Kralove",
      "names": {},
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.2092113,
        "lng": 15.8327512
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        15.7467677,
        50.1599871,
        15.9350339,
        50.2673626
      ]
    },
    {
      "id": 5132,
      "name": "Nuneaton",
      "names": {
        "zh": "纳尼顿",
        "ja": "ナニートン",
        "th": "อีตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5246182,
        "lng": -1.4684488
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.5084488,
        52.4846182,
        -1.4284488,
        52.5646182
      ]
    },
    {
      "id": 5133,
      "name": "South Gate",
      "names": {
        "es": "Puerta sur",
        "fr": "Porte sud",
        "zh": "南门",
        "de": "Südtor",
        "it": "Cancello del sud",
        "id": "Gerbang selatan",
        "ja": "南門",
        "th": "ประตูใต้",
        "pt": "Portão sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9463456,
        "lng": -118.200981
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.2313999,
        33.9099149,
        -118.1562677,
        33.9662648
      ]
    },
    {
      "id": 5134,
      "name": "Kargilik",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.884258,
        "lng": 77.413873
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        77.393873,
        37.864258,
        77.433873,
        37.904258
      ]
    },
    {
      "id": 5135,
      "name": "Miaoli",
      "names": {
        "zh": "苗栗",
        "ja": "苗栗"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.5692318,
        "lng": 120.8222649
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.8172649,
        24.5642318,
        120.8272649,
        24.5742318
      ]
    },
    {
      "id": 5136,
      "name": "Ciudad de Melilla",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 35.289775,
        "lng": -2.951129
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -2.951573,
        35.28948,
        -2.950857,
        35.290154
      ]
    },
    {
      "id": 5137,
      "name": "Stevenage",
      "names": {
        "zh": "斯蒂夫尼奇",
        "th": "สตีเวนิจ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.9016663,
        "lng": -0.2027155
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.2343821,
        51.8693191,
        -0.1467965,
        51.9324315
      ]
    },
    {
      "id": 5138,
      "name": "Kirkland",
      "names": {
        "zh": "柯克兰",
        "ja": "カークランド",
        "th": "เคิร์กแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6765382,
        "lng": -122.2070775
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.261879,
        47.642425,
        -122.149346,
        47.740778
      ]
    },
    {
      "id": 5139,
      "name": "Usti nad Labem",
      "names": {
        "th": "outi nad labem"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.6603327,
        "lng": 14.0381357
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        13.9415599,
        50.5805554,
        14.1410494,
        50.7217996
      ]
    },
    {
      "id": 5140,
      "name": "Pardubice",
      "names": {
        "zh": "帕尔杜比采"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.0385812,
        "lng": 15.7791356
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        15.6249984,
        49.9924553,
        15.8917673,
        50.0774041
      ]
    },
    {
      "id": 5141,
      "name": "Magadan",
      "names": {
        "zh": "马加丹",
        "ja": "マガダン",
        "th": "มากาดาน"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.5604768,
        "lng": 150.7988617
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        150.6039647,
        59.4725487,
        151.0357211,
        59.7431772
      ]
    },
    {
      "id": 5142,
      "name": "Bender",
      "names": {
        "zh": "本德",
        "ja": "ベンダー",
        "th": "ดัด"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.8218177,
        "lng": 29.4819196
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.3932838,
        46.7567512,
        29.5063168,
        46.9041123
      ]
    },
    {
      "id": 5143,
      "name": "Panevezys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.728893,
        "lng": 24.348249
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.235609,
        55.689099,
        24.432438,
        55.771947
      ]
    },
    {
      "id": 5144,
      "name": "Oulad Teima",
      "names": {
        "id": "Oulad Tima",
        "ja": "オールド帝人"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 30.39547065,
        "lng": -9.21053347914265
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.2106761,
        30.3953457,
        -9.2103971,
        30.3955632
      ]
    },
    {
      "id": 5145,
      "name": "Jaffna",
      "names": {
        "zh": "贾夫纳",
        "th": "จาฟ"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 9.6656214,
        "lng": 80.0207926
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        80.0157926,
        9.6606214,
        80.0257926,
        9.6706214
      ]
    },
    {
      "id": 5146,
      "name": "Queluz",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.75481445,
        "lng": -9.25728260154883
      },
      "country": "Portugal",
      "importance": 3,
      "bbox": [
        -9.2712425,
        38.7394952,
        -9.2470061,
        38.7703745
      ]
    },
    {
      "id": 5147,
      "name": "Settsu",
      "names": {
        "zh": "摄津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7801513,
        "lng": 135.5614065
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.5564065,
        34.7751513,
        135.5664065,
        34.7851513
      ]
    },
    {
      "id": 5148,
      "name": "Hartlepool",
      "names": {
        "th": "เปรสตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.6868261,
        "lng": -1.207832
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.212832,
        54.6818261,
        -1.202832,
        54.6918261
      ]
    },
    {
      "id": 5149,
      "name": "Wako",
      "names": {
        "zh": "和光",
        "ja": "和光"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7817053,
        "lng": 139.6058692
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.596906,
        35.7666064,
        139.646307,
        35.813206
      ]
    },
    {
      "id": 5150,
      "name": "Aulnay-sous-Bois",
      "names": {
        "zh": "欧奈-苏布瓦",
        "ja": "aulnay-スー・ボワ",
        "th": "aulnay-Sous-บัว"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9321256,
        "lng": 2.4952047
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.4902047,
        48.9271256,
        2.5002047,
        48.9371256
      ]
    },
    {
      "id": 5151,
      "name": "Francistown",
      "names": {
        "zh": "佛朗西斯顿"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -21.16995,
        "lng": 27.50788
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        27.48788,
        -21.18995,
        27.52788,
        -21.14995
      ]
    },
    {
      "id": 5152,
      "name": "Chester",
      "names": {
        "zh": "切斯特",
        "ja": "チェスター",
        "th": "เชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.1908873,
        "lng": -2.8908955
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0508955,
        53.0308873,
        -2.7308955,
        53.3508873
      ]
    },
    {
      "id": 5153,
      "name": "Lobnya",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0135144,
        "lng": 37.4848762
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.4798762,
        56.0085144,
        37.4898762,
        56.0185144
      ]
    },
    {
      "id": 5154,
      "name": "Kalibo",
      "names": {
        "zh": "卡利博"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.7084748,
        "lng": 122.3699618
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.3699118,
        11.7084248,
        122.3700118,
        11.7085248
      ]
    },
    {
      "id": 5155,
      "name": "Jonkoping",
      "names": {
        "fr": "Jönköping",
        "de": "Jönköping",
        "th": "เยินเชอปิง",
        "pt": "Jönköping"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.7825634,
        "lng": 14.165719
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        14.005719,
        57.6225634,
        14.325719,
        57.9425634
      ]
    },
    {
      "id": 5156,
      "name": "Ama",
      "names": {
        "zh": "美国医学会",
        "th": "อะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 67.1471631,
        "lng": 74.3415488
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        62.0004438,
        62.1956567,
        86.007285,
        73.7217556
      ]
    },
    {
      "id": 5157,
      "name": "Umea",
      "names": {
        "es": "Umeå",
        "zh": "于默奥",
        "ja": "ウメオ",
        "pt": "Umeå"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 63.8256568,
        "lng": 20.2630745
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        20.1338355,
        63.7855589,
        20.3853529,
        63.8820322
      ]
    },
    {
      "id": 5158,
      "name": "Poitiers",
      "names": {
        "zh": "普瓦捷",
        "ja": "ポワティエ",
        "th": "ติเยร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.5802596,
        "lng": 0.340196
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.2910777,
        46.5422279,
        0.4516769,
        46.6270136
      ]
    },
    {
      "id": 5159,
      "name": "Glyfada",
      "names": {
        "zh": "格利法达",
        "th": "กลีฟาดา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.8615966,
        "lng": 23.7545902
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.7345902,
        37.8415966,
        23.7745902,
        37.8815966
      ]
    },
    {
      "id": 5160,
      "name": "Kitanagoya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.244369,
        "lng": 136.879032
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.83919,
        35.225065,
        136.904794,
        35.266664
      ]
    },
    {
      "id": 5161,
      "name": "Remedios de Escalada",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7275895,
        "lng": -58.3953738
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.4280262,
        -34.7388462,
        -58.3669294,
        -34.705358
      ]
    },
    {
      "id": 5162,
      "name": "Babahoyo",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.80278,
        "lng": -79.52917
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.54917,
        -1.82278,
        -79.50917,
        -1.78278
      ]
    },
    {
      "id": 5163,
      "name": "Jangipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.4561534,
        "lng": 88.1073968
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.0673968,
        24.4161534,
        88.1473968,
        24.4961534
      ]
    },
    {
      "id": 5164,
      "name": "Acayucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9487862,
        "lng": -94.9145752
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.9545752,
        17.9087862,
        -94.8745752,
        17.9887862
      ]
    },
    {
      "id": 5165,
      "name": "Westminster",
      "names": {
        "zh": "威斯敏斯特",
        "ja": "ウェストミンスター",
        "th": "เวสต์มินสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8366528,
        "lng": -105.0372046
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.1655583,
        39.8180676,
        -104.9876821,
        39.9685399
      ]
    },
    {
      "id": 5166,
      "name": "Dobrich",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.5688397,
        "lng": 27.8297415
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        27.7134261,
        43.5171795,
        27.8916112,
        43.6296131
      ]
    },
    {
      "id": 5167,
      "name": "Xicotepec de Juarez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2767053,
        "lng": -97.9606968
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -98.0446866,
        20.2291903,
        -97.7500388,
        20.434076
      ]
    },
    {
      "id": 5168,
      "name": "Germantown",
      "names": {
        "zh": "杰曼镇",
        "ja": "ジャーマンタウン",
        "th": "เจอร์แมนทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.17130815,
        "lng": -77.26557848822242
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.317176,
        39.1277869,
        -77.211502,
        39.214792
      ]
    },
    {
      "id": 5169,
      "name": "Tartus",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.88902,
        "lng": 35.88659
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        35.86659,
        34.86902,
        35.90659,
        34.90902
      ]
    },
    {
      "id": 5170,
      "name": "Fuengirola",
      "names": {
        "zh": "福恩吉罗拉",
        "ja": "フエンヒローラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.5418555,
        "lng": -4.624217463622452
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.6244753,
        36.5416524,
        -4.6239633,
        36.5421136
      ]
    },
    {
      "id": 5171,
      "name": "Higashiyamato",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.740869,
        "lng": 139.428831
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.391624,
        35.730012,
        139.451621,
        35.769589
      ]
    },
    {
      "id": 5172,
      "name": "Catanzaro",
      "names": {
        "zh": "卡坦扎罗",
        "ja": "カタンザーロ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.9076353,
        "lng": 16.5960465
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        16.5390259,
        38.81484,
        16.6868561,
        38.9685834
      ]
    },
    {
      "id": 5173,
      "name": "Valjevo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2708719,
        "lng": 19.8863297
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.8419577,
        44.244223,
        19.950119,
        44.2961949
      ]
    },
    {
      "id": 5174,
      "name": "Santa Monica",
      "names": {
        "es": "Santa Mónica",
        "zh": "圣莫尼卡",
        "ja": "サンタモニカ",
        "th": "ซานตาโมนิกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0194704,
        "lng": -118.491227
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.5534678,
        33.9666583,
        -118.4435336,
        34.0505825
      ]
    },
    {
      "id": 5175,
      "name": "Jalal-Abad",
      "names": {
        "zh": "贾拉尔 - 阿瓦德",
        "ja": "jalal-アバド"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.93780525,
        "lng": 72.99291522607287
      },
      "country": "Kyrgyzstan",
      "importance": 1,
      "bbox": [
        72.9468855,
        40.9042289,
        73.0365891,
        40.9708475
      ]
    },
    {
      "id": 5176,
      "name": "Tala",
      "names": {
        "zh": "塔拉",
        "ja": "ターラ",
        "th": "แอด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.610062499999998,
        "lng": -103.70750330271915
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.8483189,
        20.4822553,
        -103.5328318,
        20.7379914
      ]
    },
    {
      "id": 5177,
      "name": "Presov",
      "names": {
        "zh": "普雷绍夫",
        "ja": "プレショフ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0000074,
        "lng": 21.2392122
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        20.870461,
        48.810739,
        21.485864,
        49.185185
      ]
    },
    {
      "id": 5178,
      "name": "Balkanabat",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 39.51075,
        "lng": 54.36713
      },
      "country": "Turkmenistan",
      "importance": 1,
      "bbox": [
        54.34713,
        39.49075,
        54.38713,
        39.53075
      ]
    },
    {
      "id": 5179,
      "name": "Ipil",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7825104,
        "lng": 122.5869172
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.5469172,
        7.7425104,
        122.6269172,
        7.8225104
      ]
    },
    {
      "id": 5180,
      "name": "Takasagocho-takasemachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.74834,
        "lng": 134.80383
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.78383,
        34.72834,
        134.82383,
        34.76834
      ]
    },
    {
      "id": 5181,
      "name": "Sliven",
      "names": {
        "zh": "斯利文"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6817633,
        "lng": 26.3153528
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        26.1553528,
        42.5217633,
        26.4753528,
        42.8417633
      ]
    },
    {
      "id": 5182,
      "name": "Mechelen",
      "names": {
        "es": "Malinas",
        "fr": "Malines",
        "zh": "梅赫伦",
        "ja": "メッヘレン",
        "th": "เมเคอเลิน"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0281381,
        "lng": 4.4803453
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.3700927,
        50.9909709,
        4.549039,
        51.0785768
      ]
    },
    {
      "id": 5183,
      "name": "As Salt",
      "names": {
        "es": "Como sal",
        "fr": "Comme sel",
        "zh": "作为盐",
        "de": "Als Salz",
        "it": "Come sale",
        "id": "Sebagai garam",
        "ja": "塩として",
        "th": "เป็นเกลือ",
        "pt": "Como sal"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.03917,
        "lng": 35.72722
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.70722,
        32.01917,
        35.74722,
        32.05917
      ]
    },
    {
      "id": 5184,
      "name": "Mukacheve",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.439978,
        "lng": 22.721264
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        22.674133,
        48.420629,
        22.763787,
        48.470295
      ]
    },
    {
      "id": 5185,
      "name": "Tangxing",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7392095,
        "lng": 111.7056292
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.6656292,
        35.6992095,
        111.7456292,
        35.7792095
      ]
    },
    {
      "id": 5186,
      "name": "Nabatiye",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 33.38424,
        "lng": 35.460258
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        35.313549,
        33.293332,
        35.550359,
        33.512688
      ]
    },
    {
      "id": 5187,
      "name": "Daxincun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1310792,
        "lng": 114.5191991
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.4991991,
        35.1110792,
        114.5391991,
        35.1510792
      ]
    },
    {
      "id": 5188,
      "name": "Volos",
      "names": {
        "zh": "沃洛斯",
        "th": "โวลอส"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.36047655,
        "lng": 22.942391041634203
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.9412225,
        39.3598633,
        22.9439411,
        39.3611103
      ]
    },
    {
      "id": 5189,
      "name": "Al Wakrah",
      "names": {
        "ja": "アル・ワクラ",
        "th": "อัล Wakrah"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.1566623,
        "lng": 51.6013212
      },
      "country": "Qatar",
      "importance": 1,
      "bbox": [
        51.0873499,
        24.4748195,
        52.2364879,
        25.2198203
      ]
    },
    {
      "id": 5190,
      "name": "Viana do Castelo",
      "names": {
        "zh": "Viana做Castelo",
        "ja": "ヴィアナドカステロ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.6968768,
        "lng": -8.8329607
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.8330107,
        41.6968268,
        -8.8329107,
        41.6969268
      ]
    },
    {
      "id": 5191,
      "name": "Miami Beach",
      "names": {
        "es": "Playa de Miami",
        "zh": "迈阿密滩",
        "id": "Pantai Miami",
        "ja": "マイアミビーチ",
        "th": "ชายหาดไมอามี่",
        "pt": "Praia de Miami"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.7929198,
        "lng": -80.1353006
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1699989,
        25.747677,
        -80.110192,
        25.8728377
      ]
    },
    {
      "id": 5192,
      "name": "Apizaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.415556,
        "lng": -98.14
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.1577039,
        19.3929733,
        -98.1217407,
        19.432343
      ]
    },
    {
      "id": 5193,
      "name": "San Leandro",
      "names": {
        "zh": "圣莱德罗",
        "ja": "サンリーンドロ",
        "th": "ซานไลน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7215601,
        "lng": -122.160584
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.165584,
        37.7165601,
        -122.155584,
        37.7265601
      ]
    },
    {
      "id": 5194,
      "name": "Aylesbury",
      "names": {
        "zh": "艾尔斯伯里",
        "ja": "アリスバーリー"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8161412,
        "lng": -0.8130383
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.8606549,
        51.7947987,
        -0.7726868,
        51.8326055
      ]
    },
    {
      "id": 5195,
      "name": "Sesto San Giovanni",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5357218,
        "lng": 9.2376549
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        9.20694,
        45.5173444,
        9.2717314,
        45.556763
      ]
    },
    {
      "id": 5196,
      "name": "Warabi",
      "names": {
        "zh": "蕨"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8263705,
        "lng": 139.6791302
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.662985,
        35.8136522,
        139.709844,
        35.835677
      ]
    },
    {
      "id": 5197,
      "name": "Prosperidad",
      "names": {
        "zh": "普罗斯佩里达"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6023961,
        "lng": 125.9116338
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.8060669,
        8.5033303,
        126.043176,
        8.875125
      ]
    },
    {
      "id": 5198,
      "name": "Bertoua",
      "names": {
        "zh": "贝尔图阿"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.5776155,
        "lng": 13.6843792
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        13.6022347,
        4.4032154,
        13.7401922,
        4.6569483
      ]
    },
    {
      "id": 5199,
      "name": "Alabel",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.10179,
        "lng": 125.29048
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.27048,
        6.08179,
        125.31048,
        6.12179
      ]
    },
    {
      "id": 5200,
      "name": "Versailles",
      "names": {
        "es": "Versalles",
        "zh": "凡尔赛",
        "ja": "ヴェルサイユ",
        "th": "แวร์ซาย",
        "pt": "Versalhes"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8035403,
        "lng": 2.1266886
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.0699384,
        48.7792385,
        2.1683504,
        48.8286153
      ]
    },
    {
      "id": 5201,
      "name": "Coronel Oviedo",
      "names": {
        "ja": "コロネル・オビエド"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.450055,
        "lng": -56.4406994
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -56.6006994,
        -25.610055,
        -56.2806994,
        -25.290055
      ]
    },
    {
      "id": 5202,
      "name": "Darayya",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4592565,
        "lng": 36.2394466
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.2194466,
        33.4392565,
        36.2594466,
        33.4792565
      ]
    },
    {
      "id": 5203,
      "name": "Ayase",
      "names": {
        "zh": "绫濑"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4460475,
        "lng": 139.430823
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4023124,
        35.407088,
        139.457722,
        35.4679904
      ]
    },
    {
      "id": 5204,
      "name": "Mersin",
      "names": {
        "zh": "梅尔辛",
        "th": "เมอร์ซิน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.7978381,
        "lng": 34.6298391
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        34.4698391,
        36.6378381,
        34.7898391,
        36.9578381
      ]
    },
    {
      "id": 5205,
      "name": "Ciudad de Ceuta",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 35.887196,
        "lng": -5.323416
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.3240359,
        35.8868833,
        -5.3228906,
        35.8878873
      ]
    },
    {
      "id": 5206,
      "name": "Ebolowa",
      "names": {
        "zh": "埃博洛瓦"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 2.9,
        "lng": 11.15
      },
      "country": "Cameroon",
      "importance": 1,
      "bbox": [
        11.13,
        2.88,
        11.17,
        2.92
      ]
    },
    {
      "id": 5207,
      "name": "Sabaneta",
      "names": {
        "zh": "萨瓦内塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1515344,
        "lng": -75.6153715
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6553715,
        6.1115344,
        -75.5753715,
        6.1915344
      ]
    },
    {
      "id": 5208,
      "name": "Torre del Greco",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7860662,
        "lng": 14.385579
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.385529,
        40.7860162,
        14.385629,
        40.7861162
      ]
    },
    {
      "id": 5209,
      "name": "Maasin",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1325061,
        "lng": 124.8385147
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.6785147,
        9.9725061,
        124.9985147,
        10.2925061
      ]
    },
    {
      "id": 5210,
      "name": "San Juan de los Morros",
      "names": {
        "zh": "圣胡安de los Morros",
        "ja": "サンファンデロスモロス",
        "th": "ซานฮวนเดอลอสมอร์ส"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.901485,
        "lng": -67.356533
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -67.4565335,
        9.8014845,
        -67.2565335,
        10.0014845
      ]
    },
    {
      "id": 5211,
      "name": "Citrus Heights",
      "names": {
        "fr": "Hauteur d'agrumes",
        "zh": "柑橘高度",
        "de": "Zitrushöhen",
        "it": "Altezze di agrumi",
        "id": "Heights Citrus",
        "ja": "柑橘類の高さ",
        "th": "ส้มสูง",
        "pt": "Alturas cítricas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7071247,
        "lng": -121.2810611
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.331964,
        38.663536,
        -121.2427253,
        38.7227958
      ]
    },
    {
      "id": 5212,
      "name": "Salina Cruz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.19777885,
        "lng": -95.21319355861957
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -95.2889881,
        16.1156728,
        -95.136851,
        16.2643049
      ]
    },
    {
      "id": 5213,
      "name": "State College",
      "names": {
        "es": "Colegio estatal",
        "fr": "Poste d'État",
        "zh": "州立学院",
        "de": "Staatshelfer",
        "id": "Perguruan tinggi",
        "ja": "州立大学",
        "th": "วิทยาลัยรัฐ",
        "pt": "Faculdade estadual"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.793225,
        "lng": -77.857341
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.886169,
        40.768492,
        -77.819037,
        40.807175
      ]
    },
    {
      "id": 5214,
      "name": "San Baudilio de Llobregat",
      "names": {
        "th": "ซาน Baudilio de llobregat"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3459033,
        "lng": 2.0413673
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.99879,
        41.285852,
        2.0784854,
        41.3622983
      ]
    },
    {
      "id": 5215,
      "name": "Lokossa",
      "names": {
        "zh": "洛科萨"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.6458524,
        "lng": 1.7171404
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        1.5750553,
        6.5853201,
        1.9157188,
        6.7823321
      ]
    },
    {
      "id": 5216,
      "name": "Clichy",
      "names": {
        "zh": "克利希",
        "th": "กลิชี่"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.903092,
        "lng": 2.306271
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.287389,
        48.894152,
        2.32088,
        48.914013
      ]
    },
    {
      "id": 5217,
      "name": "Maldonado",
      "names": {
        "zh": "马尔多纳多"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.9087162,
        "lng": -54.9582718
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -54.9733371,
        -34.9151756,
        -54.9212538,
        -34.8732721
      ]
    },
    {
      "id": 5218,
      "name": "Burnley",
      "names": {
        "zh": "伯恩利",
        "ja": "バーンリー",
        "th": "เบิร์นลี่ย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7907262,
        "lng": -2.2439196
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.3423051,
        53.722997,
        -2.1122772,
        53.8252624
      ]
    },
    {
      "id": 5219,
      "name": "Mardin",
      "names": {
        "zh": "马尔丁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3132581,
        "lng": 40.7354383
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        40.5754383,
        37.1532581,
        40.8954383,
        37.4732581
      ]
    },
    {
      "id": 5220,
      "name": "Xishancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.20417,
        "lng": 115.63338
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.61338,
        24.18417,
        115.65338,
        24.22417
      ]
    },
    {
      "id": 5221,
      "name": "Ezpeleta",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7518471,
        "lng": -58.2342068
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.2392068,
        -34.7568471,
        -58.2292068,
        -34.7468471
      ]
    },
    {
      "id": 5222,
      "name": "Mingxing",
      "names": {
        "zh": "明星"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.4280622,
        "lng": 112.5231703
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.4856572,
        37.3977891,
        112.5376747,
        37.4585431
      ]
    },
    {
      "id": 5223,
      "name": "Nakhon Sawan",
      "names": {
        "ja": "ナコンサワン",
        "th": "นครสวรรค์",
        "pt": "Sawan nakhon"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.705783,
        "lng": 100.1330046
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.9730046,
        15.545783,
        100.2930046,
        15.865783
      ]
    },
    {
      "id": 5224,
      "name": "Saint Albans",
      "names": {
        "es": "San Albans",
        "zh": "圣奥尔本",
        "ja": "セントアルバンス",
        "th": "นักบุญอัลบันส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.753051,
        "lng": -0.3379675
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.3752915,
        51.7291298,
        -0.2910176,
        51.7800675
      ]
    },
    {
      "id": 5225,
      "name": "Villa Celina",
      "names": {
        "zh": "米利纳别墅",
        "ja": "ヴィラセリーナ",
        "th": "วิลล่าเซลินา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7012224,
        "lng": -58.4854196
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.4985042,
        -34.7265781,
        -58.4614155,
        -34.6887865
      ]
    },
    {
      "id": 5226,
      "name": "Issy-les-Moulineaux",
      "names": {
        "zh": "伊西莱穆利诺",
        "ja": "issy・レ・moulineaux",
        "th": "issy-เลส์-moulineaux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8239595,
        "lng": 2.2685004082375357
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.2679811,
        48.823665,
        2.269229,
        48.8243018
      ]
    },
    {
      "id": 5227,
      "name": "Loznica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.5309221,
        "lng": 19.2237478
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.1748786,
        44.5128976,
        19.2398538,
        44.546705
      ]
    },
    {
      "id": 5228,
      "name": "Tucupita",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.06222,
        "lng": -62.05102
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -62.07102,
        9.04222,
        -62.03102,
        9.08222
      ]
    },
    {
      "id": 5229,
      "name": "Ath Thawrah",
      "names": {
        "de": "Athtau thawrah",
        "th": "ทธาวาราห์"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.8323648,
        "lng": 38.5414697
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        38.5331373,
        35.8144462,
        38.5596712,
        35.8426126
      ]
    },
    {
      "id": 5230,
      "name": "Tacambaro de Codallos",
      "names": {
        "it": "Tacambaro de codaallos",
        "id": "TACAMMAMLARO DE CODALLOS",
        "ja": "タカムバロデ・コーダロス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2356879,
        "lng": -101.4585063
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.4985063,
        19.1956879,
        -101.4185063,
        19.2756879
      ]
    },
    {
      "id": 5231,
      "name": "Ciudad Lerdo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.545131,
        "lng": -103.523712
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.554462,
        25.512069,
        -103.497653,
        25.579818
      ]
    },
    {
      "id": 5232,
      "name": "Owariasahi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.208,
        "lng": 137.03
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.01,
        35.188,
        137.05,
        35.228
      ]
    },
    {
      "id": 5233,
      "name": "Piatra Neamt",
      "names": {
        "ja": "ピトラニアム"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.9319894,
        "lng": 26.3695046
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        26.2984149,
        46.8617864,
        26.4477315,
        46.9796531
      ]
    },
    {
      "id": 5234,
      "name": "Hawthorne",
      "names": {
        "zh": "霍桑",
        "ja": "ホーソン",
        "th": "ฮอว์ธอร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9128272,
        "lng": -118.3426122
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.3787556,
        33.893485,
        -118.3133933,
        33.9345041
      ]
    },
    {
      "id": 5235,
      "name": "Kouvola",
      "names": {
        "zh": "科沃拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8680928,
        "lng": 26.7038517
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        26.2353773,
        60.5750065,
        27.4283735,
        61.2925945
      ]
    },
    {
      "id": 5236,
      "name": "Kuznetsk",
      "names": {
        "zh": "库兹涅茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.11405,
        "lng": 46.597421
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        46.554779,
        53.090694,
        46.634972,
        53.136749
      ]
    },
    {
      "id": 5237,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.8560679,
        "lng": -4.0239568
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -4.1790592,
        39.8120405,
        -3.8147929,
        39.9254339
      ]
    },
    {
      "id": 5238,
      "name": "Busto Arsizio",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.611932,
        "lng": 8.8518269
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        8.8047879,
        45.5681334,
        8.8819729,
        45.6426265
      ]
    },
    {
      "id": 5239,
      "name": "El Bayadh",
      "names": {
        "zh": "埃尔贝纳德"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.689189,
        "lng": 1.0165419
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        1.0164919,
        33.689139,
        1.0165919,
        33.689239
      ]
    },
    {
      "id": 5240,
      "name": "Como",
      "names": {
        "fr": "Côme",
        "zh": "科莫",
        "ja": "コモ",
        "th": "โคโม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.8115623,
        "lng": 9.0830353
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.0310791,
        45.7587544,
        9.1368711,
        45.8419781
      ]
    },
    {
      "id": 5241,
      "name": "Pori",
      "names": {
        "zh": "波里",
        "ja": "ポリ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4866126,
        "lng": 21.7972071
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        20.8747853,
        61.3840243,
        22.7249099,
        61.7497009
      ]
    },
    {
      "id": 5242,
      "name": "Manfalut",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 27.3114314,
        "lng": 30.9722043
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.9322043,
        27.2714314,
        31.0122043,
        27.3514314
      ]
    },
    {
      "id": 5243,
      "name": "Baj Baj",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.047678,
        "lng": 75.062759
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.058138,
        17.044641,
        75.064904,
        17.049885
      ]
    },
    {
      "id": 5244,
      "name": "Cozumel",
      "names": {
        "zh": "科苏梅尔",
        "ja": "コズメル島"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5014305,
        "lng": -86.9435851
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -86.9761966,
        20.4665951,
        -86.908325,
        20.5555066
      ]
    },
    {
      "id": 5245,
      "name": "Al Muharraq",
      "names": {
        "ja": "アルムハラ文字"
      },
      "countryCode": "BH",
      "latLng": {
        "lat": 26.2465946,
        "lng": 50.6164127
      },
      "country": "Bahrain",
      "importance": 3,
      "bbox": [
        50.4564127,
        26.0865946,
        50.7764127,
        26.4065946
      ]
    },
    {
      "id": 5246,
      "name": "Louga",
      "names": {
        "zh": "卢加"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 15.6118551,
        "lng": -16.2233275
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.252939,
        15.5993328,
        -16.2002046,
        15.6465232
      ]
    },
    {
      "id": 5247,
      "name": "Mbarara",
      "names": {
        "zh": "巴拉拉"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.6107896,
        "lng": 30.6533181
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.5255357,
        -0.7662984,
        30.8201768,
        -0.4800611
      ]
    },
    {
      "id": 5248,
      "name": "Whittier",
      "names": {
        "zh": "惠蒂尔",
        "ja": "ホイッティア",
        "th": "วิตทิเออร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9749136,
        "lng": -118.0338664
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0723168,
        33.9280277,
        -117.9651556,
        34.0300822
      ]
    },
    {
      "id": 5249,
      "name": "Clifton",
      "names": {
        "zh": "克利夫顿",
        "ja": "クリフトン",
        "th": "คลิฟตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8584328,
        "lng": -74.1637553
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.197734,
        40.8199335,
        -74.117804,
        40.899398
      ]
    },
    {
      "id": 5250,
      "name": "Puerto Maldonado",
      "names": {
        "zh": "波多黎各多人",
        "ja": "プエルトマルドナド",
        "th": "เปอร์โตมัลโดเนโด"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.5938633,
        "lng": -69.1866773
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -69.2266773,
        -12.6338633,
        -69.1466773,
        -12.5538633
      ]
    },
    {
      "id": 5251,
      "name": "Hetauda",
      "names": {
        "zh": "赫托达"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.42839,
        "lng": 85.03219
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        85.01219,
        27.40839,
        85.05219,
        27.44839
      ]
    },
    {
      "id": 5252,
      "name": "Chaguanas",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.5147394,
        "lng": -61.4076757
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.4682953,
        10.5031318,
        -61.3644917,
        10.5662804
      ]
    },
    {
      "id": 5253,
      "name": "Nazareth",
      "names": {
        "es": "Nazaret",
        "zh": "拿撒勒",
        "id": "Nazaret",
        "ja": "ナザレス",
        "th": "นาซาเร็ ธ",
        "pt": "Nazaré"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.7066301,
        "lng": 35.3048161
      },
      "country": "Israel",
      "importance": 1,
      "bbox": [
        35.2660515,
        32.6727661,
        35.313535,
        32.7363002
      ]
    },
    {
      "id": 5254,
      "name": "Uman",
      "names": {
        "zh": "人力"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7497621,
        "lng": 30.2203052
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.1670878,
        48.721224,
        30.2985442,
        48.822831
      ]
    },
    {
      "id": 5255,
      "name": "Nabunturan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.60778,
        "lng": 125.96639
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.94639,
        7.58778,
        125.98639,
        7.62778
      ]
    },
    {
      "id": 5256,
      "name": "Ouidah",
      "names": {
        "zh": "维达"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.3537344,
        "lng": 2.0863107
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        2.0813107,
        6.3487344,
        2.0913107,
        6.3587344
      ]
    },
    {
      "id": 5257,
      "name": "Nagaoka",
      "names": {
        "zh": "长冈",
        "ja": "長岡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6819867,
        "lng": 133.986279
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.976279,
        34.6719867,
        133.996279,
        34.6919867
      ]
    },
    {
      "id": 5258,
      "name": "Osijek",
      "names": {
        "zh": "奥西耶克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.552735,
        "lng": 18.6834775
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        18.6784775,
        45.547735,
        18.6884775,
        45.557735
      ]
    },
    {
      "id": 5259,
      "name": "Chervonohrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.385948,
        "lng": 24.227074
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.204384,
        50.363211,
        24.256594,
        50.410453
      ]
    },
    {
      "id": 5260,
      "name": "Lucerne",
      "names": {
        "es": "Lucerna",
        "zh": "卢塞恩",
        "de": "Luzern",
        "it": "Lucerna",
        "ja": "ルツェルン",
        "th": "ลูเซิร์น"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0505452,
        "lng": 8.3054682
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.20651,
        46.9980063,
        8.427975,
        47.0835227
      ]
    },
    {
      "id": 5261,
      "name": "Bumahen",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7285207,
        "lng": 51.864313
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        51.8443494,
        35.7133701,
        51.8947449,
        35.751904
      ]
    },
    {
      "id": 5262,
      "name": "Kunitachi",
      "names": {
        "zh": "国立"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.681991,
        "lng": 139.43624
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.417566,
        35.670637,
        139.459608,
        35.704415
      ]
    },
    {
      "id": 5263,
      "name": "Tecate",
      "names": {
        "zh": "塔咖提"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.5653831,
        "lng": -116.6298787
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -116.6617402,
        32.5472999,
        -116.5916173,
        32.5792081
      ]
    },
    {
      "id": 5264,
      "name": "Newmarket",
      "names": {
        "zh": "纽马基特",
        "ja": "ニューマーケット",
        "th": "นิวมาร์เก็ต"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.056258,
        "lng": -79.461708
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.5093678,
        44.0160565,
        -79.4117446,
        44.0834542
      ]
    },
    {
      "id": 5265,
      "name": "Milpitas",
      "names": {
        "zh": "米尔皮塔斯",
        "ja": "ミルピタス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4332273,
        "lng": -121.8989248
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.9314616,
        37.3963528,
        -121.8377079,
        37.466792
      ]
    },
    {
      "id": 5266,
      "name": "Barahona",
      "names": {
        "zh": "巴拉奥纳"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.2101889,
        "lng": -71.1012325
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -71.2119772,
        18.1154375,
        -71.0551844,
        18.2584091
      ]
    },
    {
      "id": 5267,
      "name": "Ho",
      "names": {
        "zh": "何",
        "ja": "ホー",
        "th": "โฮ"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.6114,
        "lng": 0.4703
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 5268,
      "name": "Phuket",
      "names": {
        "zh": "普吉岛",
        "ja": "プーケット",
        "th": "ภูเก็ต"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.8847901,
        "lng": 98.3891503
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        98.2291503,
        7.7247901,
        98.5491503,
        8.0447901
      ]
    },
    {
      "id": 5269,
      "name": "Le Bardo",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8072404,
        "lng": 10.1357648
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.1357148,
        36.8071904,
        10.1358148,
        36.8072904
      ]
    },
    {
      "id": 5270,
      "name": "Kayes",
      "names": {
        "zh": "卡耶斯"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 14.58641635,
        "lng": -11.468678305870574
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -12.2402835,
        13.529,
        -10.764,
        15.643786
      ]
    },
    {
      "id": 5271,
      "name": "Champigny-sur-Marne",
      "names": {
        "zh": "尚皮尼马恩河畔",
        "ja": "champignyシュルマルヌ",
        "th": "champigny-ซูร์-Marne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.81905,
        "lng": 2.513678
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.475806,
        48.803161,
        2.565525,
        48.831549
      ]
    },
    {
      "id": 5272,
      "name": "Chatham",
      "names": {
        "zh": "查塔姆",
        "ja": "チャタム",
        "th": "ชาตัม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3803092,
        "lng": 0.5212081
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.5162081,
        51.3753092,
        0.5262081,
        51.3853092
      ]
    },
    {
      "id": 5273,
      "name": "Alhambra",
      "names": {
        "zh": "阿罕布拉",
        "ja": "アルハンブラ",
        "th": "บรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.093042,
        "lng": -118.12706
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.164835,
        34.0599364,
        -118.1077791,
        34.1111486
      ]
    },
    {
      "id": 5274,
      "name": "Zilina",
      "names": {
        "zh": "日利纳"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.214957,
        "lng": 18.734884
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        18.686487,
        49.179508,
        18.782951,
        49.249127
      ]
    },
    {
      "id": 5275,
      "name": "Batley",
      "names": {
        "zh": "巴特利"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.716564,
        "lng": -1.6363856
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.6763856,
        53.676564,
        -1.5963856,
        53.756564
      ]
    },
    {
      "id": 5276,
      "name": "Esteio",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.8520199,
        "lng": -51.1793504
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.1843504,
        -29.8570199,
        -51.1743504,
        -29.8470199
      ]
    },
    {
      "id": 5277,
      "name": "Resita",
      "names": {
        "zh": "雷希察"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.297164,
        "lng": 21.888067
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        21.883067,
        45.292164,
        21.893067,
        45.302164
      ]
    },
    {
      "id": 5278,
      "name": "Shinkai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7023861,
        "lng": 139.6363815
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6363315,
        35.7023361,
        139.6364315,
        35.7024361
      ]
    },
    {
      "id": 5279,
      "name": "Antsiranana",
      "names": {
        "zh": "安齐拉纳纳"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -12.27436305,
        "lng": 49.29257326369566
      },
      "country": "Madagascar",
      "importance": 1,
      "bbox": [
        49.2924071,
        -12.274488,
        49.2927395,
        -12.2742427
      ]
    },
    {
      "id": 5280,
      "name": "Kirtipur",
      "names": {
        "zh": "吉尔蒂布尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6740962,
        "lng": 85.280068
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        85.280018,
        27.6740462,
        85.280118,
        27.6741462
      ]
    },
    {
      "id": 5281,
      "name": "Casoria",
      "names": {
        "zh": "卡索里亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9045572,
        "lng": 14.2901223
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.2797905,
        40.8752044,
        14.3324585,
        40.9285017
      ]
    },
    {
      "id": 5282,
      "name": "Purmerend",
      "names": {
        "zh": "皮尔默伦德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5024789,
        "lng": 4.9614827
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.9206509,
        52.4703485,
        5.0247001,
        52.5291104
      ]
    },
    {
      "id": 5283,
      "name": "Rueil-Malmaison",
      "names": {
        "zh": "吕埃-马尔迈松",
        "ja": "rueil-マルメゾン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.87778,
        "lng": 2.1802832
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.1480137,
        48.8476724,
        2.213279,
        48.8958295
      ]
    },
    {
      "id": 5284,
      "name": "Scunthorpe",
      "names": {
        "zh": "斯肯索普",
        "th": "สคันธอร์ป"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5861008,
        "lng": -0.6515681
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.6565681,
        53.5811008,
        -0.6465681,
        53.5911008
      ]
    },
    {
      "id": 5285,
      "name": "Dudley",
      "names": {
        "zh": "达德利",
        "ja": "ダドリー",
        "th": "ดัดลีย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5110832,
        "lng": -2.0816813
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.1919459,
        52.426,
        -2.011411,
        52.5582139
      ]
    },
    {
      "id": 5286,
      "name": "Ban Talat Rangsit",
      "names": {
        "zh": "班塔拉特朗格斯特",
        "ja": "バンタラットRangsit",
        "th": "บ้านตลาดรังสิต",
        "pt": "Ban rangsit Talat"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.00681,
        "lng": 100.71439
      },
      "country": "Thailand",
      "importance": 3,
      "bbox": [
        100.601806641,
        13.9623468008,
        100.744628906,
        14.030014548
      ]
    },
    {
      "id": 5287,
      "name": "Targu Jiu",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 45.0317866,
        "lng": 23.28044301995768
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        23.2802059,
        45.0315545,
        23.2806802,
        45.0320263
      ]
    },
    {
      "id": 5288,
      "name": "Silao",
      "names": {
        "zh": "锡劳"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.943727,
        "lng": -101.427043
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.587043,
        20.783727,
        -101.267043,
        21.103727
      ]
    },
    {
      "id": 5289,
      "name": "Upper Darby",
      "names": {
        "es": "Darby superior",
        "fr": "Haut Darby",
        "zh": "上达比",
        "de": "Oberer Darby",
        "it": "Superiore darby",
        "id": "Darby atas",
        "ja": "アッパーダービー",
        "th": "ดาร์บี้บน",
        "pt": "Alta Darby"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.95817,
        "lng": -75.26685
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.28685,
        39.93817,
        -75.24685,
        39.97817
      ]
    },
    {
      "id": 5290,
      "name": "Abomey",
      "names": {
        "zh": "阿博梅"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 7.1820012,
        "lng": 1.993632
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        1.833632,
        7.0220012,
        2.153632,
        7.3420012
      ]
    },
    {
      "id": 5291,
      "name": "Kiyose",
      "names": {
        "zh": "清濑"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.785483,
        "lng": 139.531253
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.4963983,
        35.761102,
        139.5475266,
        35.807302
      ]
    },
    {
      "id": 5292,
      "name": "Mountain View",
      "names": {
        "es": "Vista de la montaña",
        "zh": "山顶风光",
        "de": "Bergblick",
        "it": "Vista delle montagne",
        "id": "Pemandangan gunung",
        "ja": "マウンテンビュー",
        "th": "วิวภูเขา",
        "pt": "Vista para a montanha"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3951093,
        "lng": -122.077198
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.082198,
        37.3901093,
        -122.072198,
        37.4001093
      ]
    },
    {
      "id": 5293,
      "name": "Shumen",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.27145685,
        "lng": 26.943361941240433
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        26.9429058,
        43.2710384,
        26.9440175,
        43.2717231
      ]
    },
    {
      "id": 5294,
      "name": "Tanuku",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.75414,
        "lng": 81.685839
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.645839,
        16.71414,
        81.725839,
        16.79414
      ]
    },
    {
      "id": 5295,
      "name": "Juchitan de Zaragoza",
      "names": {
        "es": "Juchtan de Zaragoza",
        "fr": "JUCHITAN DE SARAGOZA"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4333347,
        "lng": -95.019444
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.179444,
        16.2733347,
        -94.859444,
        16.5933347
      ]
    },
    {
      "id": 5296,
      "name": "White Rock",
      "names": {
        "es": "Roca blanca",
        "fr": "Rock blanc",
        "zh": "白色岩石",
        "de": "Weißer Felsen",
        "it": "Roccia bianca",
        "id": "Batu bakar",
        "ja": "ホワイトロック",
        "th": "หินสีขาว",
        "pt": "Rocha branca"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.0235357,
        "lng": -122.797924
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.845581,
        49.0020846,
        -122.779193,
        49.031225
      ]
    },
    {
      "id": 5297,
      "name": "Elbasan",
      "names": {
        "zh": "爱尔巴桑"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 41.1124939,
        "lng": 20.0823265
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        20.0438119,
        41.0868751,
        20.120065,
        41.1345736
      ]
    },
    {
      "id": 5298,
      "name": "Campo Formoso",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.51055,
        "lng": -40.320031
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.2783405,
        -10.683096,
        -40.228,
        -9.8363474
      ]
    },
    {
      "id": 5299,
      "name": "Weston-super-Mare",
      "names": {
        "es": "Weston Super Mare",
        "fr": "Weston super-mare",
        "zh": "滨海韦斯顿",
        "de": "Weston Super Mare",
        "id": "Weston Super Mare",
        "ja": "ウェストン-超マーレ",
        "th": "เวสตัน-ซุปเปอร์ม้า",
        "pt": "Weston-Super Mare"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3471927,
        "lng": -2.9778916
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.1153312,
        51.3117505,
        -2.894657,
        51.3749692
      ]
    },
    {
      "id": 5300,
      "name": "Nea Ionia",
      "names": {
        "fr": "Néa Ionia",
        "ja": "ネアイオニア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.03937445,
        "lng": 23.757569821329625
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.7414402,
        38.0279411,
        23.7823094,
        38.0508174
      ]
    },
    {
      "id": 5301,
      "name": "South Shields",
      "names": {
        "es": "Escudos del sur",
        "fr": "Boucliers du sud",
        "zh": "南盾",
        "de": "Südschirme",
        "it": "Scudi del sud",
        "id": "Perisai selatan",
        "ja": "サウスシールド",
        "pt": "Escudos sul"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9973681,
        "lng": -1.4329872
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.4379872,
        54.9923681,
        -1.4279872,
        55.0023681
      ]
    },
    {
      "id": 5302,
      "name": "Saint-Maur-des-Fosses",
      "names": {
        "es": "Saint-Maur-des-fossés",
        "zh": "圣 -  maur-沙漠-fosses",
        "ja": "サン・maur・デ・fosses",
        "th": "เซนต์ maur-เดส์-fosses"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.797462,
        "lng": 2.494142
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.460632,
        48.784111,
        2.523475,
        48.815923
      ]
    },
    {
      "id": 5303,
      "name": "Schiedam",
      "names": {
        "zh": "斯希丹"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.91718,
        "lng": 4.4019149
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3506714,
        51.8966787,
        4.417713,
        51.9634066
      ]
    },
    {
      "id": 5304,
      "name": "Huolu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0878408,
        "lng": 114.3170571
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.2770571,
        38.0478408,
        114.3570571,
        38.1278408
      ]
    },
    {
      "id": 5305,
      "name": "Buena Park",
      "names": {
        "zh": "布埃纳公园",
        "id": "Taman Buena",
        "ja": "ブエナパーク",
        "th": "สวนบัว",
        "pt": "Parque de buena"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.870413,
        "lng": -117.9962165
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0373233,
        33.8099217,
        -117.9762782,
        33.8955565
      ]
    },
    {
      "id": 5306,
      "name": "Silver Spring",
      "names": {
        "es": "Primavera de plata",
        "fr": "Printemps argenté",
        "zh": "银泉",
        "de": "Silberfeder",
        "it": "Molla d'argento",
        "id": "Pegas perak",
        "ja": "シルバースプリング",
        "th": "สปริงเงิน",
        "pt": "Primavera prata"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9997621,
        "lng": -77.02403709829767
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.0637082,
        38.9796581,
        -76.9716469,
        39.0197747
      ]
    },
    {
      "id": 5307,
      "name": "Bani Mazar",
      "names": {
        "zh": "巴尼马扎尔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.5049997,
        "lng": 30.8045169
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.8044669,
        28.5049497,
        30.8045669,
        28.5050497
      ]
    },
    {
      "id": 5308,
      "name": "Daugavpils",
      "names": {
        "zh": "陶格夫匹尔斯"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 55.8712267,
        "lng": 26.5159337
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        26.4379987,
        55.8347517,
        26.6323405,
        55.9575853
      ]
    },
    {
      "id": 5309,
      "name": "Ivanteyevka",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.97111,
        "lng": 37.92083
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.90083,
        55.95111,
        37.94083,
        55.99111
      ]
    },
    {
      "id": 5310,
      "name": "Los Reyes de Salgado",
      "names": {
        "ja": "ロスリーレスデサルガード",
        "th": "ลอสเรเยสเดอ Salgado"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.58793,
        "lng": -102.470051
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -102.485927,
        19.572345,
        -102.451497,
        19.606443
      ]
    },
    {
      "id": 5311,
      "name": "Cinisello Balsamo",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5586454,
        "lng": 9.2141955
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        9.1918055,
        45.5364514,
        9.2555441,
        45.5794167
      ]
    },
    {
      "id": 5312,
      "name": "Kashiba",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5415175,
        "lng": 135.6988328
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.650285,
        34.51923,
        135.729572,
        34.575609
      ]
    },
    {
      "id": 5313,
      "name": "Hakkari",
      "names": {
        "zh": "哈卡里"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5766959,
        "lng": 43.7377862
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        43.3071205,
        37.3491437,
        44.090369,
        37.8105835
      ]
    },
    {
      "id": 5314,
      "name": "Drancy",
      "names": {
        "zh": "德朗西"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.932705,
        "lng": 2.4544348
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.4494348,
        48.927705,
        2.4594348,
        48.937705
      ]
    },
    {
      "id": 5315,
      "name": "Somerville",
      "names": {
        "zh": "萨默维尔",
        "ja": "サマービル",
        "th": "วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3875968,
        "lng": -71.0994968
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.134635,
        42.3725268,
        -71.0726884,
        42.4181555
      ]
    },
    {
      "id": 5316,
      "name": "Bury",
      "names": {
        "es": "Enterrar",
        "fr": "L'enterrer",
        "zh": "埋葬",
        "de": "Begraben",
        "ja": "ベリー",
        "th": "ฝังศพ",
        "pt": "Enterrar"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5927543,
        "lng": -2.2972827
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.3834089,
        53.5120212,
        -2.2341016,
        53.6670647
      ]
    },
    {
      "id": 5317,
      "name": "Chalandri",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0215202,
        "lng": 23.7984139
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.7784139,
        38.0015202,
        23.8184139,
        38.0415202
      ]
    },
    {
      "id": 5318,
      "name": "Le Kram",
      "names": {
        "th": "เลอกรัม"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.83333,
        "lng": 10.31667
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.29667,
        36.81333,
        10.33667,
        36.85333
      ]
    },
    {
      "id": 5319,
      "name": "Paisley",
      "names": {
        "es": "Cachemir",
        "zh": "佩斯利",
        "ja": "ペイズリー",
        "th": "ลาย"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.8455828,
        "lng": -4.4239646
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -4.4639646,
        55.8055828,
        -4.3839646,
        55.8855828
      ]
    },
    {
      "id": 5320,
      "name": "Deerfield Beach",
      "names": {
        "es": "Playa Deerfield",
        "zh": "迪尔菲尔德海滩",
        "id": "Pantai Deerfield",
        "ja": "ディアフィールドビーチ",
        "th": "หาด Deerfield",
        "pt": "Praia deerfield"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.3164644,
        "lng": -80.1225593
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1226093,
        26.3164144,
        -80.1225093,
        26.3165144
      ]
    },
    {
      "id": 5321,
      "name": "Targoviste",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 44.923439,
        "lng": 25.453082
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.402397,
        44.894998,
        25.52321,
        44.948842
      ]
    },
    {
      "id": 5322,
      "name": "Galway",
      "names": {
        "zh": "戈尔韦",
        "ja": "ゴールウェイ",
        "th": "กัลเวย์"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2744122,
        "lng": -9.0490601
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -9.1426901,
        53.2485189,
        -8.9548381,
        53.3197423
      ]
    },
    {
      "id": 5323,
      "name": "Natitingou",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 10.3051511,
        "lng": 1.3808554
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        1.2208554,
        10.1451511,
        1.5408554,
        10.4651511
      ]
    },
    {
      "id": 5324,
      "name": "Rubi",
      "names": {
        "zh": "鲁比",
        "ja": "ルビ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.4936194,
        "lng": 2.0319476
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.9784857,
        41.4615176,
        2.0558777,
        41.5433676
      ]
    },
    {
      "id": 5325,
      "name": "Barreiro",
      "names": {
        "zh": "巴雷鲁"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.658723,
        "lng": -9.07405
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.093059,
        38.650643,
        -9.065158,
        38.6723
      ]
    },
    {
      "id": 5326,
      "name": "Paysandu",
      "names": {
        "es": "Paysandú",
        "zh": "派桑杜"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -32.317192,
        "lng": -58.083944
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -58.103944,
        -32.337192,
        -58.063944,
        -32.297192
      ]
    },
    {
      "id": 5327,
      "name": "Cicero",
      "names": {
        "fr": "Cicéron",
        "zh": "西塞罗",
        "it": "Cicerone",
        "ja": "シセロ",
        "th": "ซิเซโร",
        "pt": "Cícero"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8455398,
        "lng": -87.7540199
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.779041,
        41.8215433,
        -87.7384854,
        41.8659668
      ]
    },
    {
      "id": 5328,
      "name": "Atakpame",
      "names": {},
      "countryCode": "TG",
      "latLng": {
        "lat": 7.5320603,
        "lng": 1.1219479
      },
      "country": "Togo",
      "importance": 1,
      "bbox": [
        0.9619479,
        7.3720603,
        1.2819479,
        7.6920603
      ]
    },
    {
      "id": 5329,
      "name": "Focsani",
      "names": {
        "zh": "佛克山尼"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.697274,
        "lng": 27.1856921
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.1467221,
        45.6512035,
        27.2669794,
        45.7477646
      ]
    },
    {
      "id": 5330,
      "name": "Cabadbaran",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.12361,
        "lng": 125.53444
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.51444,
        9.10361,
        125.55444,
        9.14361
      ]
    },
    {
      "id": 5331,
      "name": "Tejupilco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.905709,
        "lng": -100.151543
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.162825656,
        18.8866339166,
        -100.135428103,
        18.9251075148
      ]
    },
    {
      "id": 5332,
      "name": "Ragusa",
      "names": {
        "zh": "拉古萨",
        "ja": "ラグーザ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 36.9256833,
        "lng": 14.7307456
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.4390285,
        36.7726568,
        14.8764202,
        37.0432408
      ]
    },
    {
      "id": 5333,
      "name": "Zuwarah",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.9327858,
        "lng": 12.0806069
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        11.9206069,
        32.7727858,
        12.2406069,
        33.0927858
      ]
    },
    {
      "id": 5334,
      "name": "Shancheng",
      "names": {
        "zh": "山城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0868608,
        "lng": 117.454942
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.3674476,
        34.991512,
        117.518515,
        35.1499377
      ]
    },
    {
      "id": 5335,
      "name": "Fnidq",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.84259,
        "lng": -5.36339
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.46339,
        35.74259,
        -5.26339,
        35.94259
      ]
    },
    {
      "id": 5336,
      "name": "Puerto Ayacucho",
      "names": {
        "ja": "プエルトアヤクチョ",
        "th": "เปอร์โต Ayacucho"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 5.664074,
        "lng": -67.631835
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -67.651835,
        5.644074,
        -67.611835,
        5.684074
      ]
    },
    {
      "id": 5337,
      "name": "Chiquimula",
      "names": {
        "zh": "奇基穆拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8005709,
        "lng": -89.5459173
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -89.7204002,
        14.7032743,
        -89.4376757,
        14.8780865
      ]
    },
    {
      "id": 5338,
      "name": "Lawrence",
      "names": {
        "fr": "Laurent",
        "zh": "劳伦斯",
        "ja": "ローレンス",
        "th": "ลอเรนซ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.001415,
        "lng": -80.350071
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.5193352,
        40.8512916,
        -80.0967154,
        41.127964
      ]
    },
    {
      "id": 5339,
      "name": "Sidi Qacem",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.226412,
        "lng": -5.711434
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.7441968,
        34.1909548,
        -5.6862789,
        34.2535966
      ]
    },
    {
      "id": 5340,
      "name": "Izumiotsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.506612,
        "lng": 135.408793
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.2624498,
        34.4814976,
        135.435067,
        34.5845184
      ]
    },
    {
      "id": 5341,
      "name": "Mansfield",
      "names": {
        "zh": "曼斯菲尔德",
        "ja": "マンスフィールド",
        "th": "แมนส์ฟิลด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.1443785,
        "lng": -1.1964165
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.2600703,
        53.1147917,
        -1.0947652,
        53.2356135
      ]
    },
    {
      "id": 5342,
      "name": "Bracknell",
      "names": {
        "zh": "布拉克内尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.41788625,
        "lng": -0.7479045499999999
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7481163,
        51.4178043,
        -0.7476928,
        51.4179682
      ]
    },
    {
      "id": 5343,
      "name": "Aviles",
      "names": {
        "es": "Avilés",
        "zh": "阿维莱斯",
        "id": "Avilés",
        "th": "อาวิเล"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.5554436,
        "lng": -5.9222466
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.9537906,
        43.5305354,
        -5.8767474,
        43.5959952
      ]
    },
    {
      "id": 5344,
      "name": "Balagtas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8177664,
        "lng": 120.9083864
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.890422,
        14.7836558,
        120.928874,
        14.8685303
      ]
    },
    {
      "id": 5345,
      "name": "Nakhon Pathom",
      "names": {
        "de": "Nakhon-Pathom",
        "id": "Pathom Nakhon",
        "ja": "ナコンのパトム",
        "th": "นครปฐม",
        "pt": "Patom de Nakhon"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.82019,
        "lng": 100.053354
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.960842,
        13.663635,
        100.338135,
        13.948443
      ]
    },
    {
      "id": 5346,
      "name": "Mahdia",
      "names": {
        "zh": "马赫迪耶",
        "th": "มาห์"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.503642,
        "lng": 11.0682429
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.9082429,
        35.343642,
        11.2282429,
        35.663642
      ]
    },
    {
      "id": 5347,
      "name": "Cheyenne",
      "names": {
        "zh": "夏延",
        "ja": "シャイアン",
        "th": "ไชเอนน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.139981,
        "lng": -104.820246
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -104.8611236,
        41.0730957,
        -104.6749256,
        41.1930263
      ]
    },
    {
      "id": 5348,
      "name": "Ubon Ratchathani",
      "names": {
        "ja": "ウボンラッチタニ",
        "th": "อุบลราชธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2274235,
        "lng": 104.8589462
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        104.6989462,
        15.0674235,
        105.0189462,
        15.3874235
      ]
    },
    {
      "id": 5349,
      "name": "Lomas del Mirador",
      "names": {
        "ja": "ローマデルミラドール"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.663145,
        "lng": -58.5288492
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.5500575,
        -34.6796194,
        -58.5130528,
        -34.6516404
      ]
    },
    {
      "id": 5350,
      "name": "Carlisle",
      "names": {
        "zh": "卡莱尔",
        "ja": "カーライル",
        "th": "คาร์ไลเซิล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.8948478,
        "lng": -2.9362311
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.9893998,
        54.855646,
        -2.8774928,
        54.9320717
      ]
    },
    {
      "id": 5351,
      "name": "Garissa",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.45275,
        "lng": 39.64601
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        39.62601,
        -0.47275,
        39.66601,
        -0.43275
      ]
    },
    {
      "id": 5352,
      "name": "Tustin",
      "names": {
        "zh": "塔斯廷"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7458511,
        "lng": -117.826166
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8446576,
        33.6934663,
        -117.7585594,
        33.7778834
      ]
    },
    {
      "id": 5353,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8503463,
        "lng": -118.117191
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1678102,
        33.8200618,
        -118.0589841,
        33.8692261
      ]
    },
    {
      "id": 5354,
      "name": "Lisala",
      "names": {
        "zh": "利萨拉"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.1473937,
        "lng": 21.5156742
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        19.7739051,
        1.8501499,
        21.8149034,
        3.2495455
      ]
    },
    {
      "id": 5355,
      "name": "East Kilbride",
      "names": {
        "es": "Este kilbride",
        "fr": "Kilbride de l'Est",
        "zh": "东千德",
        "de": "Osten Kilbride",
        "it": "Kilbride orientale",
        "id": "Kilbrida timur",
        "ja": "イーストキルブライド",
        "pt": "Quilômetro leste"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.7660899,
        "lng": -4.1799497
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -4.1799997,
        55.7660399,
        -4.1798997,
        55.7661399
      ]
    },
    {
      "id": 5356,
      "name": "Szombathely",
      "names": {
        "zh": "松博特海伊"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2373509,
        "lng": 16.6327619
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        16.6277619,
        47.2323509,
        16.6377619,
        47.2423509
      ]
    },
    {
      "id": 5357,
      "name": "Banska Bystrica",
      "names": {
        "ja": "バンスカバイシュリカ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.735538,
        "lng": 19.144535
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        19.044535,
        48.635538,
        19.244535,
        48.835538
      ]
    },
    {
      "id": 5358,
      "name": "Frontera",
      "names": {
        "zh": "Frontera的",
        "ja": "フロンテーラ",
        "th": "ฟรงเตอรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.925554,
        "lng": -101.4522926
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.4922926,
        26.885554,
        -101.4122926,
        26.965554
      ]
    },
    {
      "id": 5359,
      "name": "Pine Hills",
      "names": {
        "es": "Pino",
        "fr": "Pintumes",
        "zh": "松山",
        "de": "Kiefernhügel",
        "id": "Bukit pinus",
        "ja": "パインヒルズ",
        "th": "ไพน์เนิน",
        "pt": "Colinas de pinheiros"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.5825505,
        "lng": -81.46183230278461
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.4884847,
        28.5522295,
        -81.4381081,
        28.6135859
      ]
    },
    {
      "id": 5360,
      "name": "Katano",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.792144,
        "lng": 135.678333
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.657991,
        34.74205,
        135.716769,
        34.80342
      ]
    },
    {
      "id": 5361,
      "name": "Ra`ananna",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.17718,
        "lng": 34.86925
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5362,
      "name": "Perote",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5612169,
        "lng": -97.2428392
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.2828392,
        19.5212169,
        -97.2028392,
        19.6012169
      ]
    },
    {
      "id": 5363,
      "name": "Aveiro",
      "names": {
        "zh": "阿维罗",
        "th": "อาวีโร"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.640496,
        "lng": -8.6537841
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.7655529,
        40.5284837,
        -8.5209649,
        40.7275536
      ]
    },
    {
      "id": 5364,
      "name": "Tambacounda",
      "names": {
        "zh": "坦巴昆达"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 13.7692585,
        "lng": -13.6682901
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -13.7171112,
        13.7199787,
        -13.6299072,
        13.8072346
      ]
    },
    {
      "id": 5365,
      "name": "La Rochelle",
      "names": {
        "ja": "ラロシェル"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1591126,
        "lng": -1.1520434
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.2419231,
        46.1331804,
        -1.111097,
        46.1908971
      ]
    },
    {
      "id": 5366,
      "name": "Mpanda",
      "names": {
        "zh": "姆潘达"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.3429524,
        "lng": 31.0729871
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        30.9129871,
        -6.5029524,
        31.2329871,
        -6.1829524
      ]
    },
    {
      "id": 5367,
      "name": "Burton upon Trent",
      "names": {
        "es": "Burton sobre Trent",
        "fr": "Burton sur Trent",
        "zh": "伯顿在特伦特",
        "de": "Burton auf Trent",
        "it": "Burton su Trento",
        "id": "Burton demi Trent",
        "ja": "トレントにバートン",
        "th": "เบอร์ตันกับเทรนต์",
        "pt": "Burton em Trent"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.802742,
        "lng": -1.629917
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.669917,
        52.762742,
        -1.589917,
        52.842742
      ]
    },
    {
      "id": 5368,
      "name": "Pau",
      "names": {
        "zh": "波城",
        "ja": "ポー",
        "th": "โป"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.2957547,
        "lng": -0.3685668
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.3925513,
        43.2857792,
        -0.2943049,
        43.3580393
      ]
    },
    {
      "id": 5369,
      "name": "New Rochelle",
      "names": {
        "fr": "Nouvelle rochelle",
        "zh": "新罗谢尔",
        "de": "Neuer Rochelle",
        "it": "Nuova Rochelle",
        "id": "Rochelle baru",
        "ja": "新しいRochelle",
        "th": "ใหม่ rochelle",
        "pt": "Nova rochelle"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9124285,
        "lng": -73.7829398
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.7829898,
        40.9123785,
        -73.7828898,
        40.9124785
      ]
    },
    {
      "id": 5370,
      "name": "Bokhtar",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.8357217,
        "lng": 68.7820957
      },
      "country": "Tajikistan",
      "importance": 1,
      "bbox": [
        68.740763,
        37.8183373,
        68.8104771,
        37.8597353
      ]
    },
    {
      "id": 5371,
      "name": "Moulay Abdallah",
      "names": {
        "zh": "莫尔亚阿卜杜拉",
        "de": "Mulay Abdallah",
        "ja": "マカレアブダラ"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.149011650000006,
        "lng": -8.548746686997989
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -8.6480843,
        33.0541888,
        -8.4778481,
        33.2444305
      ]
    },
    {
      "id": 5372,
      "name": "Lebanon",
      "names": {
        "es": "Líbano",
        "fr": "Le liban",
        "zh": "黎巴嫩",
        "de": "Libanon",
        "it": "Del Libano",
        "id": "Libanon",
        "ja": "レバノン",
        "th": "เลบานอน",
        "pt": "Líbano"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.3429683,
        "lng": -76.4233816
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.4508668,
        40.3199193,
        -76.391965,
        40.355802
      ]
    },
    {
      "id": 5373,
      "name": "Godawari",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.86242995,
        "lng": 80.58163917908928
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        80.4680771,
        28.7262126,
        80.6757391,
        28.9990855
      ]
    },
    {
      "id": 5374,
      "name": "Maghaghah",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 28.6484,
        "lng": 30.84234
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.82234,
        28.6284,
        30.86234,
        28.6684
      ]
    },
    {
      "id": 5375,
      "name": "Wa",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 10.0667,
        "lng": -2.5
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 5376,
      "name": "Nitra",
      "names": {
        "zh": "尼特拉",
        "ja": "ニトラ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.31295,
        "lng": 18.0894593
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        17.832953,
        48.141295,
        18.438786,
        48.464726
      ]
    },
    {
      "id": 5377,
      "name": "Lelystad",
      "names": {
        "zh": "莱利斯塔德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5150949,
        "lng": 5.4768915
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        5.0745865,
        52.3843774,
        5.6058706,
        52.6928447
      ]
    },
    {
      "id": 5378,
      "name": "Crewe",
      "names": {
        "zh": "克鲁",
        "ja": "クルー",
        "th": "ครูว์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.0996153,
        "lng": -2.4414627
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4826251,
        53.0785601,
        -2.4069854,
        53.1258824
      ]
    },
    {
      "id": 5379,
      "name": "Gladbeck",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5718665,
        "lng": 6.9877343
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.9208602,
        51.532666,
        7.0321195,
        51.6235979
      ]
    },
    {
      "id": 5380,
      "name": "San Miguel de Allende",
      "names": {
        "ja": "サンミゲルデアジェンデ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9142238,
        "lng": -100.743784
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.7730586,
        20.8883248,
        -100.7138495,
        20.934945
      ]
    },
    {
      "id": 5381,
      "name": "Sankt Gallen",
      "names": {
        "ja": "サンクトガレン"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.425618,
        "lng": 9.3762397
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        9.2915068,
        47.3951582,
        9.4352403,
        47.4530776
      ]
    },
    {
      "id": 5382,
      "name": "Cannes",
      "names": {
        "zh": "戛纳",
        "ja": "カンヌ",
        "th": "เมืองคานส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5515198,
        "lng": 7.0134418
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        6.9447513,
        43.4994248,
        7.074185,
        43.574726
      ]
    },
    {
      "id": 5383,
      "name": "Gavle",
      "names": {
        "es": "Gävle",
        "fr": "Gävle",
        "zh": "耶夫勒",
        "de": "Gävle",
        "th": "ใกล้กับพิพิธภัณฑ์"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 60.6750132,
        "lng": 17.1467019
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        17.0535868,
        60.6386513,
        17.2900969,
        60.737118
      ]
    },
    {
      "id": 5384,
      "name": "Alameda",
      "names": {
        "zh": "阿拉米达",
        "ja": "アラメダ",
        "th": "เดินเล่น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7652076,
        "lng": -122.241635
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.340281,
        37.707621,
        -122.223859,
        37.800628
      ]
    },
    {
      "id": 5386,
      "name": "Newcastle under Lyme",
      "names": {
        "es": "Newcastle bajo Lyme",
        "fr": "Newcastle Sous Lyme",
        "zh": "莱姆下的纽卡斯尔",
        "de": "Newcastle unter Lyme",
        "it": "Newcastle sotto Lyme",
        "id": "Newcastle Di Bawah Lyme",
        "ja": "ライムの下のニューカッスル",
        "th": "นิวคาสเซิลภายใต้ Lyme",
        "pt": "Newcastle sob Lyme"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.0117627,
        "lng": -2.2273919
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4707898,
        52.8739842,
        -2.1810863,
        53.1158073
      ]
    },
    {
      "id": 5387,
      "name": "Harrogate",
      "names": {
        "zh": "哈罗盖特",
        "ja": "ハロゲート",
        "th": "ฮาร์โรเกท"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.9921491,
        "lng": -1.5391039
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.5791039,
        53.9521491,
        -1.4991039,
        54.0321491
      ]
    },
    {
      "id": 5388,
      "name": "Watsonville",
      "names": {
        "th": "วัตสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.9092773,
        "lng": -121.7529071
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.8273244,
        36.8840632,
        -121.7323371,
        37.019109
      ]
    },
    {
      "id": 5389,
      "name": "Caxito",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.5827504,
        "lng": 13.6600788
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        13.6200788,
        -8.6227504,
        13.7000788,
        -8.5427504
      ]
    },
    {
      "id": 5390,
      "name": "Esbjerg",
      "names": {
        "zh": "埃斯比约"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.4664892,
        "lng": 8.4520751
      },
      "country": "Denmark",
      "importance": 2,
      "bbox": [
        8.2920751,
        55.3064892,
        8.6120751,
        55.6264892
      ]
    },
    {
      "id": 5391,
      "name": "Mao",
      "names": {
        "zh": "毛泽东",
        "ja": "真央",
        "th": "เหมา"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.5556795,
        "lng": -71.0759018
      },
      "country": "Dominican Republic",
      "importance": 1,
      "bbox": [
        -71.2359018,
        19.3956795,
        -70.9159018,
        19.7156795
      ]
    },
    {
      "id": 5392,
      "name": "Okegawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0028937,
        "lng": 139.5583422
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.506146,
        35.960715,
        139.596065,
        36.040532
      ]
    },
    {
      "id": 5393,
      "name": "Virac",
      "names": {
        "zh": "比拉克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5803594,
        "lng": 124.2312028
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.0481086,
        13.3817662,
        124.3026986,
        13.7547703
      ]
    },
    {
      "id": 5394,
      "name": "Davis",
      "names": {
        "zh": "戴维斯",
        "ja": "デイビス",
        "th": "เดวิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5435526,
        "lng": -121.739005
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.793977,
        38.5349054,
        -121.6756663,
        38.5755508
      ]
    },
    {
      "id": 5395,
      "name": "Las Piedras",
      "names": {
        "zh": "拉斯佩兹拉斯",
        "ja": "ラスピエドラ"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.7274904,
        "lng": -56.2164982
      },
      "country": "Uruguay",
      "importance": 3,
      "bbox": [
        -56.249489,
        -34.7497549,
        -56.1815801,
        -34.7043473
      ]
    },
    {
      "id": 5396,
      "name": "Rugby",
      "names": {
        "zh": "橄榄球",
        "id": "Ragbi",
        "ja": "ラグビー",
        "th": "รักบี้"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.370331,
        "lng": -1.261423
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.305683,
        52.338034,
        -1.183297,
        52.402879
      ]
    },
    {
      "id": 5397,
      "name": "Berberati",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 4.2593379,
        "lng": 15.7877826
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        15.6277826,
        4.0993379,
        15.9477826,
        4.4193379
      ]
    },
    {
      "id": 5398,
      "name": "Tamazunchale",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2618403,
        "lng": -98.7907425
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8307425,
        21.2218403,
        -98.7507425,
        21.3018403
      ]
    },
    {
      "id": 5399,
      "name": "Zrenjanin",
      "names": {
        "zh": "兹雷尼亚宁"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3802683,
        "lng": 20.3907614
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.2288844,
        45.2589427,
        20.524432,
        45.4621568
      ]
    },
    {
      "id": 5400,
      "name": "Fouchana",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.6987224,
        "lng": 10.1693582
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.1293582,
        36.6587224,
        10.2093582,
        36.7387224
      ]
    },
    {
      "id": 5401,
      "name": "Surt",
      "names": {
        "zh": "苏尔特"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 29.9713958,
        "lng": 16.6770887
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        14.5625408,
        28.4846725,
        19.3800697,
        32.7005389
      ]
    },
    {
      "id": 5402,
      "name": "Nek'emte",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 9.088673,
        "lng": 36.539206
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 5403,
      "name": "Pancevo",
      "names": {
        "zh": "潘切沃"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.868287,
        "lng": 20.656639
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.626797,
        44.853457,
        20.666797,
        44.893457
      ]
    },
    {
      "id": 5404,
      "name": "Karaman",
      "names": {
        "zh": "卡拉曼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1808502,
        "lng": 33.2194554
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        32.7691076,
        36.8109393,
        33.7334347,
        37.5657304
      ]
    },
    {
      "id": 5405,
      "name": "Dolores Hidalgo Cuna de la Independencia Nacional",
      "names": {
        "zh": "Dolores Hidalgo Cuna de la Indoolencia Nacional",
        "ja": "Dolores Hidalgo Cuna de La Pendencia Nacional"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1574,
        "lng": -100.934617
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.974617,
        21.1174,
        -100.894617,
        21.1974
      ]
    },
    {
      "id": 5406,
      "name": "Abeche",
      "names": {
        "fr": "Abéché",
        "zh": "阿贝歇"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 13.82916,
        "lng": 20.8324
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        20.8124,
        13.80916,
        20.8524,
        13.84916
      ]
    },
    {
      "id": 5407,
      "name": "Palmerston North",
      "names": {
        "zh": "北帕默斯顿",
        "de": "Palmerston Nord",
        "ja": "パーマストンノース",
        "pt": "Palmerston norte"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -40.356317,
        "lng": 175.6112388
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        175.4973346,
        -40.5296853,
        175.7905923,
        -40.2680773
      ]
    },
    {
      "id": 5408,
      "name": "Joyo",
      "names": {
        "zh": "卓越网"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8560656,
        "lng": 135.7808145
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.7758145,
        34.8510656,
        135.7858145,
        34.8610656
      ]
    },
    {
      "id": 5409,
      "name": "Bellflower",
      "names": {
        "fr": "Campanule",
        "zh": "桔梗",
        "de": "Glockenblume",
        "it": "Campanula",
        "ja": "竜胆",
        "th": "ดอกไม้ชนิดหนึ่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8825705,
        "lng": -118.1167679
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1514023,
        33.865574,
        -118.1067018,
        33.910453
      ]
    },
    {
      "id": 5410,
      "name": "Palaio Faliro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9302446,
        "lng": 23.700239535059794
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.6785538,
        37.9172443,
        23.7241038,
        37.9433494
      ]
    },
    {
      "id": 5411,
      "name": "Tamworth",
      "names": {
        "zh": "塔姆沃思",
        "ja": "タムワース",
        "th": "แทมเวิร์ธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.6345819,
        "lng": -1.6948438
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.7358155,
        52.5886703,
        -1.632929,
        52.6574998
      ]
    },
    {
      "id": 5412,
      "name": "Chiryu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0014831,
        "lng": 137.0516484
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.016407,
        34.979126,
        137.0860563,
        35.0217665
      ]
    },
    {
      "id": 5413,
      "name": "Un'goofaaru",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 17.338805,
        "lng": 102.911309
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        102.023623,
        16.827604,
        103.657167,
        18.088405
      ]
    },
    {
      "id": 5414,
      "name": "Ramla",
      "names": {
        "zh": "拉姆拉",
        "ja": "ラムラ"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9279988,
        "lng": 34.8623473
      },
      "country": "Israel",
      "importance": 1,
      "bbox": [
        34.8375039,
        31.9091243,
        34.8985482,
        31.9423594
      ]
    },
    {
      "id": 5415,
      "name": "Jinja",
      "names": {
        "zh": "金贾",
        "de": "Jinjas",
        "ja": "ジンジャ"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.57515665,
        "lng": 33.28052985775423
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.0425488,
        0.449083,
        33.3788155,
        0.7011445
      ]
    },
    {
      "id": 5416,
      "name": "Tamanrasset",
      "names": {
        "id": "Tamanghasset"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 22.785003,
        "lng": 5.522805
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.5028047268,
        22.7650032745,
        5.5428047268,
        22.8050032745
      ]
    },
    {
      "id": 5417,
      "name": "Zadar",
      "names": {
        "zh": "扎达尔",
        "it": "Zara",
        "ja": "ザダル",
        "th": "ซาดาร์"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.1134354,
        "lng": 15.2248257
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        15.2247757,
        44.1133854,
        15.2248757,
        44.1134854
      ]
    },
    {
      "id": 5418,
      "name": "Ixtaczoquitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8509737,
        "lng": -97.0625392
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1025392,
        18.8109737,
        -97.0225392,
        18.8909737
      ]
    },
    {
      "id": 5419,
      "name": "Musashimurayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.756509,
        "lng": 139.385637
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.354662,
        35.732632,
        139.414413,
        35.774407
      ]
    },
    {
      "id": 5420,
      "name": "Bella Vista",
      "names": {
        "zh": "贝拉维斯塔",
        "ja": "ベラビスタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5684085,
        "lng": -58.6894883
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.7354174,
        -34.6055034,
        -58.655577,
        -34.5434716
      ]
    },
    {
      "id": 5421,
      "name": "Antibes",
      "names": {
        "zh": "昂蒂布",
        "ja": "アンティーブ",
        "th": "ออง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5812868,
        "lng": 7.1262071
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        7.0644214,
        43.5418452,
        7.1451067,
        43.6227847
      ]
    },
    {
      "id": 5422,
      "name": "Tissemsilt",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.604813,
        "lng": 1.810476
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        1.704635,
        35.539595,
        1.9541619,
        35.6862368
      ]
    },
    {
      "id": 5423,
      "name": "Bistrita",
      "names": {
        "zh": "比斯特里察"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.1327012,
        "lng": 24.4963949
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        24.3865066,
        47.0505464,
        24.6517864,
        47.2285103
      ]
    },
    {
      "id": 5424,
      "name": "Joensuu",
      "names": {
        "zh": "约恩苏",
        "ja": "ヨエンスー",
        "th": "โจเอนซู"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6005753,
        "lng": 29.7622128
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        29.6130951,
        62.2508037,
        31.0935058,
        63.0475887
      ]
    },
    {
      "id": 5426,
      "name": "Rioverde",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.930755,
        "lng": -99.989875
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.015026,
        21.891185,
        -99.949781,
        21.9501
      ]
    },
    {
      "id": 5427,
      "name": "Matehuala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.64749,
        "lng": -100.644689
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.665756,
        23.609643,
        -100.610993,
        23.700957
      ]
    },
    {
      "id": 5428,
      "name": "Darhan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 49.4922428,
        "lng": 105.9361613
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        105.8908677,
        49.3788585,
        106.020576,
        49.5265898
      ]
    },
    {
      "id": 5429,
      "name": "Karlstad",
      "names": {
        "zh": "卡尔斯塔德",
        "ja": "カールスタード",
        "th": "คาร์ลสตัด"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.3809146,
        "lng": 13.5027631
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        13.3427631,
        59.2209146,
        13.6627631,
        59.5409146
      ]
    },
    {
      "id": 5430,
      "name": "Teyateyaneng",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -29.1519737,
        "lng": 27.7427554
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        27.7027554,
        -29.1919737,
        27.7827554,
        -29.1119737
      ]
    },
    {
      "id": 5431,
      "name": "Gouda",
      "names": {
        "zh": "豪达",
        "ja": "グーダ",
        "th": "เกาดา"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0174245,
        "lng": 4.7061636
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7011636,
        52.0124245,
        4.7111636,
        52.0224245
      ]
    },
    {
      "id": 5432,
      "name": "Zacatecoluca",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.5,
        "lng": -88.86667
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.88667,
        13.48,
        -88.84667,
        13.52
      ]
    },
    {
      "id": 5433,
      "name": "Baldwin Park",
      "names": {
        "zh": "鲍德温公园",
        "id": "Taman Baldwin",
        "ja": "ボールドウィンパーク",
        "th": "สวนบอลด์วิน",
        "pt": "Parque de baldwin"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0854739,
        "lng": -117.9611761
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0076321,
        34.0551493,
        -117.942953,
        34.107064
      ]
    },
    {
      "id": 5434,
      "name": "Zlin",
      "names": {
        "es": "Zlín",
        "zh": "兹林"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.232414,
        "lng": 17.661495
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        17.603972,
        49.210168,
        17.734107,
        49.253156
      ]
    },
    {
      "id": 5435,
      "name": "Spijkenisse",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.844285,
        "lng": 4.328584
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.283782,
        51.822497,
        4.432482,
        51.864476
      ]
    },
    {
      "id": 5436,
      "name": "Calais",
      "names": {
        "zh": "加来",
        "ja": "カレー",
        "th": "กาเลส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.9524769,
        "lng": 1.8538446
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.8057835,
        50.9218944,
        1.9320613,
        50.9826611
      ]
    },
    {
      "id": 5437,
      "name": "Jinsha",
      "names": {
        "zh": "金沙"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1573476,
        "lng": 113.1994665
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.1811361,
        23.1438443,
        113.2127058,
        23.179247
      ]
    },
    {
      "id": 5438,
      "name": "Kiyosu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.21595,
        "lng": 136.845107
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.822707,
        35.180558,
        136.87928,
        35.251847
      ]
    },
    {
      "id": 5439,
      "name": "Siuri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.9080538,
        "lng": 87.5277255
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.3677255,
        23.7480538,
        87.6877255,
        24.0680538
      ]
    },
    {
      "id": 5440,
      "name": "Vlaardingen",
      "names": {
        "zh": "弗拉尔丁恩"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9208108,
        "lng": 4.3426818
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3426318,
        51.9207608,
        4.3427318,
        51.9208608
      ]
    },
    {
      "id": 5441,
      "name": "Prey Veng",
      "names": {
        "es": "Presa veng",
        "fr": "Proie Veng",
        "zh": "猎物veng",
        "it": "Preda vend",
        "id": "Mangsa veng",
        "ja": "獲物ヴェン",
        "th": "เหยื่อ veng"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.522391,
        "lng": 105.469434
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        105.339878,
        11.355769,
        105.591746,
        11.650927
      ]
    },
    {
      "id": 5442,
      "name": "Inowroclaw",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7952408,
        "lng": 18.2595624
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.2072436,
        52.7436767,
        18.2932282,
        52.8196556
      ]
    },
    {
      "id": 5443,
      "name": "Pazardzhik",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.1884549,
        "lng": 24.3318993
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        24.1718993,
        42.0284549,
        24.4918993,
        42.3484549
      ]
    },
    {
      "id": 5444,
      "name": "Dazaifu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5129189,
        "lng": 130.5242217
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4830923,
        33.470468,
        130.5721563,
        33.556441
      ]
    },
    {
      "id": 5445,
      "name": "Bitola",
      "names": {
        "zh": "比托拉"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.0316005,
        "lng": 21.3302511
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.2847767,
        41.0027837,
        21.3864646,
        41.0784758
      ]
    },
    {
      "id": 5446,
      "name": "Pernik",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6092185,
        "lng": 23.0309457
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        22.8709457,
        42.4492185,
        23.1909457,
        42.7692185
      ]
    },
    {
      "id": 5447,
      "name": "San Andres",
      "names": {
        "es": "San Andrés",
        "zh": "圣安德烈斯",
        "ja": "サンアンドレス",
        "th": "ซานแอนเดรส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 12.5830104,
        "lng": -81.6975682
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -81.8575682,
        12.4230104,
        -81.5375682,
        12.7430104
      ]
    },
    {
      "id": 5448,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0641992,
        "lng": 125.6093294
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.5890459,
        7.0579401,
        125.6279118,
        7.105258
      ]
    },
    {
      "id": 5449,
      "name": "Lowestoft",
      "names": {
        "zh": "洛斯托夫特"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4766308,
        "lng": 1.7537638
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        1.6921201,
        52.4447414,
        1.7689121,
        52.5094805
      ]
    },
    {
      "id": 5450,
      "name": "Naxcivan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.207888350000005,
        "lng": 45.41169458880367
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.387474,
        39.1800734,
        45.4347547,
        39.235715
      ]
    },
    {
      "id": 5451,
      "name": "Longkoucun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6174532,
        "lng": 114.5962964
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.5762964,
        30.5974532,
        114.6162964,
        30.6374532
      ]
    },
    {
      "id": 5452,
      "name": "Manokwari",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.8654093,
        "lng": 134.070721
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        134.070671,
        -0.8654593,
        134.070771,
        -0.8653593
      ]
    },
    {
      "id": 5453,
      "name": "Hekinan",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.878834,
        "lng": 136.991549
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.948502,
        34.820679,
        137.040497,
        34.936867
      ]
    },
    {
      "id": 5454,
      "name": "Masvingo",
      "names": {
        "zh": "马斯温戈"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -20.0720142,
        "lng": 30.8341936
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        30.6741936,
        -20.2320142,
        30.9941936,
        -19.9120142
      ]
    },
    {
      "id": 5455,
      "name": "Yoshikawa",
      "names": {
        "zh": "吉川",
        "ja": "吉川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8962831,
        "lng": 139.854504
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.828306,
        35.8594745,
        139.899204,
        35.94015
      ]
    },
    {
      "id": 5456,
      "name": "Assab",
      "names": {
        "zh": "阿萨布"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 13.00917,
        "lng": 42.73944
      },
      "country": "Eritrea",
      "importance": 1,
      "bbox": [
        42.71944,
        12.98917,
        42.75944,
        13.02917
      ]
    },
    {
      "id": 5457,
      "name": "Gosport",
      "names": {
        "zh": "戈斯波特"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7952074,
        "lng": -1.1210853
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.2142996,
        50.7732911,
        -1.1117738,
        50.840256
      ]
    },
    {
      "id": 5458,
      "name": "Nabeul",
      "names": {
        "zh": "纳布勒"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4512897,
        "lng": 10.7355915
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.5755915,
        36.2912897,
        10.8955915,
        36.6112897
      ]
    },
    {
      "id": 5459,
      "name": "Centreville",
      "names": {
        "es": "Centerville",
        "zh": "森特维尔",
        "de": "Centerville",
        "it": "Centerville",
        "id": "Centerville",
        "ja": "centerville",
        "th": "เซ็นเตอร์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.84487,
        "lng": -77.43540260778344
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.479588,
        38.809625,
        -77.401989,
        38.880144
      ]
    },
    {
      "id": 5460,
      "name": "Tulcea",
      "names": {
        "zh": "图尔恰"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.192175500000005,
        "lng": 28.795467205486503
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        28.79264,
        45.182772,
        28.813289,
        45.20169
      ]
    },
    {
      "id": 5461,
      "name": "Birobidzhan",
      "names": {
        "zh": "比罗比詹"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.7881668,
        "lng": 132.9282082
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        132.7782136,
        48.6981107,
        133.0826082,
        48.8673168
      ]
    },
    {
      "id": 5462,
      "name": "Grays",
      "names": {
        "es": "Grises",
        "fr": "Gris",
        "zh": "灰色",
        "de": "Grau",
        "it": "Grigi",
        "ja": "グレイ",
        "th": "สีเทา",
        "pt": "Cinzas"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4780898,
        "lng": 0.3233131
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.2833131,
        51.4380898,
        0.3633131,
        51.5180898
      ]
    },
    {
      "id": 5463,
      "name": "Warzat",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.9167,
        "lng": -6.9167
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5464,
      "name": "Sanliurfa",
      "names": {
        "zh": "桑尼乌法",
        "id": "Sanliurfa guney anadoulu projesi"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1596239,
        "lng": 38.791929
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        38.631929,
        36.9996239,
        38.951929,
        37.3196239
      ]
    },
    {
      "id": 5465,
      "name": "Tsurugashima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9346812,
        "lng": 139.3929735
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3524328,
        35.9140584,
        139.4288725,
        35.9585433
      ]
    },
    {
      "id": 5466,
      "name": "El Kef",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.17424,
        "lng": 8.70486
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        8.68486,
        36.15424,
        8.72486,
        36.19424
      ]
    },
    {
      "id": 5467,
      "name": "Salima",
      "names": {
        "zh": "萨利马"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.7766347,
        "lng": 34.4482507
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.4082507,
        -13.8166347,
        34.4882507,
        -13.7366347
      ]
    },
    {
      "id": 5468,
      "name": "Farah",
      "names": {
        "zh": "法拉",
        "th": "เราะห์"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 32.3740907,
        "lng": 62.1146266
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        61.9546266,
        32.2140907,
        62.2746266,
        32.5340907
      ]
    },
    {
      "id": 5469,
      "name": "Ivry-sur-Seine",
      "names": {
        "es": "Ivry-sur-Sena",
        "zh": "伊夫里塞纳河畔",
        "ja": "ivryシュルセーヌ",
        "th": "ivry-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8122302,
        "lng": 2.3872525
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.3642489,
        48.7993545,
        2.4102418,
        48.8257262
      ]
    },
    {
      "id": 5470,
      "name": "Blagoevgrad",
      "names": {
        "zh": "布拉戈耶夫"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.0111077,
        "lng": 23.0906457
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        22.9306457,
        41.8511077,
        23.2506457,
        42.1711077
      ]
    },
    {
      "id": 5471,
      "name": "Camden",
      "names": {
        "zh": "卡姆登",
        "ja": "カムデン",
        "th": "แคมเดน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9448402,
        "lng": -75.1198911
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.1363868,
        39.898664,
        -75.066546,
        39.969478
      ]
    },
    {
      "id": 5472,
      "name": "Antalya",
      "names": {
        "zh": "安塔利亚",
        "ja": "アンタルヤ",
        "th": "อันตัลยา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.8864752,
        "lng": 30.7029585
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        30.5429585,
        36.7264752,
        30.8629585,
        37.0464752
      ]
    },
    {
      "id": 5473,
      "name": "Sa`dah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 16.9409324,
        "lng": 43.7630261
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        43.6030261,
        16.7809324,
        43.9230261,
        17.1009324
      ]
    },
    {
      "id": 5474,
      "name": "Evanston",
      "names": {
        "zh": "埃文斯顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0470043,
        "lng": -87.6846053
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.732456,
        42.0191465,
        -87.6650214,
        42.0717698
      ]
    },
    {
      "id": 5475,
      "name": "Walton upon Thames",
      "names": {
        "es": "Walton sobre Thames",
        "fr": "Walton sur la Thames",
        "zh": "瓦尔顿在泰晤士河上",
        "de": "Walton auf der Themse",
        "it": "Walton su Tamigi",
        "ja": "Walton on Thames",
        "th": "วอลตันกับเทมส์",
        "pt": "Walton no Thames"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.429006799999996,
        "lng": -0.36358215000000466
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.3637542,
        51.4288858,
        -0.3634101,
        51.4291278
      ]
    },
    {
      "id": 5476,
      "name": "Neuilly-sur-Seine",
      "names": {
        "es": "Neuilly-sur-Sena",
        "zh": "讷塞纳河畔",
        "ja": "neuillyシュルセーヌ",
        "th": "neuilly-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.887261,
        "lng": 2.26982
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.245698,
        48.874081,
        2.284513,
        48.8984
      ]
    },
    {
      "id": 5477,
      "name": "Debre Mark'os",
      "names": {
        "es": "Debe Mark'os",
        "zh": "德布雷克"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 10.350506,
        "lng": 37.717964
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        37.717914,
        10.350456,
        37.718014,
        10.350556
      ]
    },
    {
      "id": 5478,
      "name": "Noisy-le-Grand",
      "names": {
        "es": "Ruidoso-le-Grand",
        "fr": "Bruyant-le-grand",
        "zh": "噪声了-大",
        "de": "Laut-Le-Grand",
        "ja": "ノイズの多い・ル・グランド",
        "th": "มีเสียงดัง-เลอ-แกรนด์",
        "pt": "Barulhento-Le-Grand"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8492238,
        "lng": 2.5524906
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.5155144,
        48.8072509,
        2.5962879,
        48.8580729
      ]
    },
    {
      "id": 5479,
      "name": "Gravesend",
      "names": {
        "zh": "格雷夫森德"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4413627,
        "lng": 0.3668306
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.3618306,
        51.4363627,
        0.3718306,
        51.4463627
      ]
    },
    {
      "id": 5480,
      "name": "Sirvan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.93361245,
        "lng": 48.93422932366694
      },
      "country": "Azerbaijan",
      "importance": 3,
      "bbox": [
        48.8792001,
        39.8555063,
        48.9807482,
        40.0042979
      ]
    },
    {
      "id": 5481,
      "name": "Caguas",
      "names": {},
      "countryCode": "PR",
      "latLng": {
        "lat": 18.2345399,
        "lng": -66.0351316
      },
      "country": "Puerto Rico",
      "importance": 3,
      "bbox": [
        -66.1951316,
        18.0745399,
        -65.8751316,
        18.3945399
      ]
    },
    {
      "id": 5482,
      "name": "Cabo San Lucas",
      "names": {
        "ja": "カボサンルーカス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.8916541,
        "lng": -109.9208169
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -109.9213945,
        22.8916256,
        -109.9208002,
        22.8925222
      ]
    },
    {
      "id": 5483,
      "name": "Fada Ngourma",
      "names": {
        "pt": "Ngourma fada"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.06157,
        "lng": 0.35843
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        0.33843,
        12.04157,
        0.37843,
        12.08157
      ]
    },
    {
      "id": 5484,
      "name": "Yawata-shimizui",
      "names": {
        "zh": "八幡-shimizui"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.87009,
        "lng": 135.7027
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6827,
        34.85009,
        135.7227,
        34.89009
      ]
    },
    {
      "id": 5485,
      "name": "San Jose de las Lajas",
      "names": {
        "zh": "圣何塞德拉斯拉哈斯",
        "id": "San Jose de laJas",
        "th": "ซานโฮเซ่เดอลาจาส"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9613562,
        "lng": -82.1518012
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -82.3072049,
        22.8059007,
        -81.9654459,
        23.0808643
      ]
    },
    {
      "id": 5486,
      "name": "Thakhek",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 17.3946183,
        "lng": 104.8064526
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        104.6464526,
        17.2346183,
        104.9664526,
        17.5546183
      ]
    },
    {
      "id": 5487,
      "name": "Dabou",
      "names": {
        "zh": "达布"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.322596,
        "lng": -4.3742639
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -4.4142639,
        5.282596,
        -4.3342639,
        5.362596
      ]
    },
    {
      "id": 5488,
      "name": "Cuscatancingo",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.73611,
        "lng": -89.18139
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.20139,
        13.71611,
        -89.16139,
        13.75611
      ]
    },
    {
      "id": 5489,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -33.5808615,
        "lng": -71.6132377
      },
      "country": "Paraguay",
      "importance": 3,
      "bbox": [
        -71.6302022,
        -33.7880182,
        -71.3290346,
        -33.5517292
      ]
    },
    {
      "id": 5490,
      "name": "Pavia",
      "names": {
        "zh": "帕维亚",
        "ja": "パヴィア",
        "th": "ปาเวีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7748057,
        "lng": 122.5419293
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.5022382,
        10.7373054,
        122.5712575,
        10.7987219
      ]
    },
    {
      "id": 5491,
      "name": "Lappeenranta",
      "names": {
        "zh": "拉彭兰塔"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.0583713,
        "lng": 28.1862742
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        27.7104527,
        60.6666743,
        28.7771684,
        61.2846964
      ]
    },
    {
      "id": 5492,
      "name": "Arba Minch'",
      "names": {
        "es": "Arba Minch '",
        "fr": "Arba Minch '",
        "de": "Arba Minch '",
        "it": "Arba Minch '",
        "id": "Arba Minth '",
        "ja": "アーバミンチ '",
        "th": "Arba Minch '",
        "pt": "Arba minch '"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 6.03333,
        "lng": 37.55
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        37.53,
        6.01333,
        37.57,
        6.05333
      ]
    },
    {
      "id": 5493,
      "name": "Thohoyandou",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -22.927749,
        "lng": 30.487814
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        30.450951,
        -22.961685,
        30.511608,
        -22.92112
      ]
    },
    {
      "id": 5494,
      "name": "Chiyoda-ku",
      "names": {
        "zh": "千代田ku",
        "ja": "千代田区ku",
        "th": "ชิโยดะ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6938097,
        "lng": 139.7532163
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7301347,
        35.6686296,
        139.7828445,
        35.7052513
      ]
    },
    {
      "id": 5495,
      "name": "Benalmadena",
      "names": {
        "es": "Benalmádena",
        "ja": "ベナルマデナ",
        "th": "เบนัลมาเดนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.596025,
        "lng": -4.568878
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.579847,
        36.58603,
        -4.561981,
        36.603831
      ]
    },
    {
      "id": 5496,
      "name": "Oroquieta",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.4859,
        "lng": 123.8048
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.7848,
        8.4659,
        123.8248,
        8.5059
      ]
    },
    {
      "id": 5497,
      "name": "New Britain",
      "names": {
        "es": "Nueva Gran Bretaña",
        "fr": "Nouvelle-Bretagne",
        "zh": "新英国",
        "de": "Neue Großbritannien",
        "it": "Nuova Gran Bretagna",
        "id": "Inggris baru",
        "ja": "ニューイギリス",
        "th": "ใหม่อังกฤษ",
        "pt": "Bretanha nova"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6612104,
        "lng": -72.7795419
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.8256258,
        41.6450168,
        -72.7503613,
        41.7158149
      ]
    },
    {
      "id": 5498,
      "name": "Karakax",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.27225,
        "lng": 79.73728
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        79.71728,
        37.25225,
        79.75728,
        37.29225
      ]
    },
    {
      "id": 5499,
      "name": "Abancay",
      "names": {
        "zh": "阿班凯"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.6373482,
        "lng": -72.8788744
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -73.01295,
        -13.7175982,
        -72.738998,
        -13.5480827
      ]
    },
    {
      "id": 5500,
      "name": "Castelldefels",
      "names": {
        "ja": "カステルデフェルス",
        "th": "กัสเตล"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.2787125,
        "lng": 1.9793953
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.9743953,
        41.2737125,
        1.9843953,
        41.2837125
      ]
    },
    {
      "id": 5501,
      "name": "Toyoake",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.05936,
        "lng": 137.013714
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.9723598,
        35.027041,
        137.043048,
        35.0954271
      ]
    },
    {
      "id": 5502,
      "name": "Havirov",
      "names": {},
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.7804711,
        "lng": 18.4308604
      },
      "country": "Czechia",
      "importance": 3,
      "bbox": [
        18.4308104,
        49.7804211,
        18.4309104,
        49.7805211
      ]
    },
    {
      "id": 5503,
      "name": "Wilde",
      "names": {
        "zh": "王尔德",
        "ja": "ワイルド",
        "th": "ไวลด์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6984264,
        "lng": -58.314592
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.3475167,
        -34.7269943,
        -58.2798455,
        -34.6701967
      ]
    },
    {
      "id": 5504,
      "name": "Nizwa",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 22.9323884,
        "lng": 57.5311
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        57.3711,
        22.7723884,
        57.6911,
        23.0923884
      ]
    },
    {
      "id": 5505,
      "name": "Haskovo",
      "names": {
        "zh": "哈斯科沃",
        "ja": "ハスコヴォ"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 41.9345727,
        "lng": 25.5556639
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        25.4942584,
        41.9160924,
        25.6063349,
        41.950748
      ]
    },
    {
      "id": 5506,
      "name": "Pawtucket",
      "names": {
        "th": "พอว์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8789531,
        "lng": -71.3821203
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.4221273,
        41.8566542,
        -71.3340402,
        41.8985926
      ]
    },
    {
      "id": 5507,
      "name": "Borongan",
      "names": {
        "zh": "博龙岸"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.60806,
        "lng": 125.43194
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        125.41194,
        11.58806,
        125.45194,
        11.62806
      ]
    },
    {
      "id": 5508,
      "name": "Matara",
      "names": {
        "zh": "马塔拉"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 5.95188335,
        "lng": 80.54306730068762
      },
      "country": "Sri Lanka",
      "importance": 3,
      "bbox": [
        80.5428735,
        5.9518524,
        80.5432611,
        5.9519144
      ]
    },
    {
      "id": 5509,
      "name": "Najran",
      "names": {
        "zh": "奈季兰"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 17.5439809,
        "lng": 44.2246817
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        44.0646817,
        17.3839809,
        44.3846817,
        17.7039809
      ]
    },
    {
      "id": 5510,
      "name": "Cergy",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 49.03942,
        "lng": 2.066126
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.999972,
        49.017996,
        2.091191,
        49.056503
      ]
    },
    {
      "id": 5511,
      "name": "Cacak",
      "names": {
        "zh": "查查克"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8914332,
        "lng": 20.3491624
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.3056768,
        43.8554463,
        20.4133735,
        43.9229938
      ]
    },
    {
      "id": 5512,
      "name": "Walsall",
      "names": {
        "zh": "沃尔索尔",
        "th": "วอลซอลล์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5847949,
        "lng": -1.9822687
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.0778155,
        52.547291,
        -1.8725712,
        52.6627172
      ]
    },
    {
      "id": 5513,
      "name": "La Chorrera",
      "names": {
        "fr": "La choréra",
        "ja": "ラコレラ"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.8835312,
        "lng": -79.7799505
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -79.9399505,
        8.7235312,
        -79.6199505,
        9.0435312
      ]
    },
    {
      "id": 5514,
      "name": "Al Qusiyah",
      "names": {
        "ja": "アルQusiyah",
        "th": "อัล qusiyah"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.4402,
        "lng": 30.81841
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.79841,
        27.4202,
        30.83841,
        27.4602
      ]
    },
    {
      "id": 5515,
      "name": "Lauderhill",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 26.1403635,
        "lng": -80.2133808
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2681874,
        26.1210749,
        -80.1860569,
        26.1938167
      ]
    },
    {
      "id": 5516,
      "name": "Zaandam",
      "names": {
        "zh": "赞丹"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4424926,
        "lng": 4.8298607
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7834418,
        52.4166622,
        4.8628051,
        52.4839676
      ]
    },
    {
      "id": 5517,
      "name": "Ajaccio",
      "names": {
        "zh": "阿雅克肖",
        "ja": "アジャクシオ",
        "th": "ในอายาชชอ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 41.9263991,
        "lng": 8.7376029
      },
      "country": "France",
      "importance": 1,
      "bbox": [
        8.5855655,
        41.8738984,
        8.8261006,
        41.9771822
      ]
    },
    {
      "id": 5518,
      "name": "Givatayim",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.071106,
        "lng": 34.809852
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.799608,
        32.058172,
        34.821083,
        32.080485
      ]
    },
    {
      "id": 5519,
      "name": "Mamou",
      "names": {
        "zh": "马木"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.374074,
        "lng": -12.08356
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -12.24356,
        10.214074,
        -11.92356,
        10.534074
      ]
    },
    {
      "id": 5520,
      "name": "Youssoufia",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.247564,
        "lng": -8.5347116
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -8.5397116,
        32.242564,
        -8.5297116,
        32.252564
      ]
    },
    {
      "id": 5521,
      "name": "Slatina",
      "names": {
        "zh": "斯拉蒂纳"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.4299971,
        "lng": 24.3631837
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        24.320581,
        44.3896292,
        24.4334868,
        44.4779601
      ]
    },
    {
      "id": 5522,
      "name": "Misantla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.934663,
        "lng": -96.850598
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.870301,
        19.910914,
        -96.83665,
        19.951945
      ]
    },
    {
      "id": 5523,
      "name": "Salama",
      "names": {
        "zh": "萨拉马"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1021016,
        "lng": -90.3145505
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.4507977,
        14.8715708,
        -89.9982672,
        15.2408237
      ]
    },
    {
      "id": 5524,
      "name": "Yambol",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.483935,
        "lng": 26.5105553
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        26.3505553,
        42.323935,
        26.6705553,
        42.643935
      ]
    },
    {
      "id": 5525,
      "name": "Venissieux",
      "names": {
        "fr": "Vénissieux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6977109,
        "lng": 4.8855966
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        4.849741,
        45.6716156,
        4.9113245,
        45.7307286
      ]
    },
    {
      "id": 5526,
      "name": "Torremolinos",
      "names": {
        "zh": "多列毛利诺斯",
        "ja": "トレモリーノス",
        "th": "ตอร์เรโมลิโนส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6242841,
        "lng": -4.4995448
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.5615237,
        36.5993789,
        -4.4752189,
        36.6523956
      ]
    },
    {
      "id": 5527,
      "name": "Ramos Arizpe",
      "names": {
        "zh": "拉莫斯亚利普"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.540787,
        "lng": -100.9466688
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.9866688,
        25.500787,
        -100.9066688,
        25.580787
      ]
    },
    {
      "id": 5528,
      "name": "Phitsanulok",
      "names": {
        "zh": "彭世洛",
        "th": "พิษณุโลก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8150258,
        "lng": 100.2647898
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.1047898,
        16.6550258,
        100.4247898,
        16.9750258
      ]
    },
    {
      "id": 5529,
      "name": "Zinjibar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.12871,
        "lng": 45.38073
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        45.36073,
        13.10871,
        45.40073,
        13.14871
      ]
    },
    {
      "id": 5530,
      "name": "Hatogaya-honcho",
      "names": {
        "zh": "鸠谷-本町",
        "ja": "hatogaya-班長"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.82694,
        "lng": 139.74111
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5531,
      "name": "New Westminster",
      "names": {
        "es": "Nueva Westminster",
        "fr": "Nouveau Westminster",
        "zh": "新威斯敏斯特",
        "de": "Neue Westminster",
        "it": "Nuovo Westminster",
        "ja": "新しいウェストミンスター",
        "th": "ใหม่ Westminster"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2067726,
        "lng": -122.9108818
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -123.0708818,
        49.0467726,
        -122.7508818,
        49.3667726
      ]
    },
    {
      "id": 5532,
      "name": "Kumanovo",
      "names": {
        "zh": "库马诺沃"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.135913,
        "lng": 21.7185474
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.6686499,
        42.092939,
        21.7739441,
        42.174059
      ]
    },
    {
      "id": 5533,
      "name": "Valle Hermoso",
      "names": {
        "ja": "ヴァッレエルモソ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6696972,
        "lng": -97.813557
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.973557,
        25.5096972,
        -97.653557,
        25.8296972
      ]
    },
    {
      "id": 5534,
      "name": "Novi Pazar",
      "names": {
        "zh": "诺维加帕",
        "ja": "ノヴィパザール"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1363535,
        "lng": 20.5112273
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.4843919,
        43.1212835,
        20.5454253,
        43.1635488
      ]
    },
    {
      "id": 5535,
      "name": "Pantin",
      "names": {
        "zh": "庞坦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8965023,
        "lng": 2.4019804
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.3893896,
        48.8830793,
        2.4276678,
        48.9179838
      ]
    },
    {
      "id": 5536,
      "name": "Mindelo",
      "names": {
        "zh": "明德洛"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.8873057,
        "lng": -24.9864064
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -24.99814,
        16.8592338,
        -24.9623181,
        16.9062536
      ]
    },
    {
      "id": 5537,
      "name": "Capelle aan den IJssel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9312552,
        "lng": 4.5883596
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5371604,
        51.9062046,
        4.6194887,
        51.9655038
      ]
    },
    {
      "id": 5538,
      "name": "Chiang Rai",
      "names": {
        "zh": "清莱",
        "ja": "チェンライ",
        "th": "เชียงราย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.9068867,
        "lng": 99.8332739
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.6732739,
        19.7468867,
        99.9932739,
        20.0668867
      ]
    },
    {
      "id": 5539,
      "name": "Tynemouth",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 55.0177847,
        "lng": -1.4256042
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.4656042,
        54.9777847,
        -1.3856042,
        55.0577847
      ]
    },
    {
      "id": 5540,
      "name": "Abnub",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 27.269181,
        "lng": 31.151736
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.111736,
        27.229181,
        31.191736,
        27.309181
      ]
    },
    {
      "id": 5541,
      "name": "Paignton",
      "names": {
        "zh": "佩恩顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.434598,
        "lng": -3.5649134
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.5699134,
        50.429598,
        -3.5599134,
        50.439598
      ]
    },
    {
      "id": 5542,
      "name": "Aksum",
      "names": {
        "fr": "Axoum",
        "zh": "阿克苏姆"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 14.1228,
        "lng": 38.717124
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        38.7167242,
        14.1214869,
        38.7178682,
        14.1246342
      ]
    },
    {
      "id": 5543,
      "name": "Jinxing",
      "names": {
        "zh": "金星"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6867305,
        "lng": 103.4807554
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.4607554,
        30.6667305,
        103.5007554,
        30.7067305
      ]
    },
    {
      "id": 5544,
      "name": "Huatusco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.147965,
        "lng": -96.96765
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.982202,
        19.133809,
        -96.95242,
        19.165721
      ]
    },
    {
      "id": 5545,
      "name": "Sunyani",
      "names": {
        "zh": "苏尼亚尼"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 7.3384389,
        "lng": -2.3309226
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -2.4909226,
        7.1784389,
        -2.1709226,
        7.4984389
      ]
    },
    {
      "id": 5546,
      "name": "Fujiidera",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5715838,
        "lng": 135.594395
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.589395,
        34.5665838,
        135.599395,
        34.5765838
      ]
    },
    {
      "id": 5547,
      "name": "Ain Harrouda",
      "names": {
        "es": "Ain Harudauda",
        "it": "Ain Harrorouda"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.637,
        "lng": -7.45
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.45005,
        33.63695,
        -7.44995,
        33.63705
      ]
    },
    {
      "id": 5548,
      "name": "Aksaray",
      "names": {
        "zh": "阿克萨赖"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3705416,
        "lng": 34.026907
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        33.4197336,
        38.061363,
        34.4285844,
        38.7184168
      ]
    },
    {
      "id": 5549,
      "name": "Kashiwara",
      "names": {
        "zh": "柏原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5861073,
        "lng": 135.6235439
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6185439,
        34.5811073,
        135.6285439,
        34.5911073
      ]
    },
    {
      "id": 5550,
      "name": "Saldanha",
      "names": {
        "zh": "萨尔达尼亚"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -32.9977778,
        "lng": 17.9455556
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        17.9055556,
        -33.0377778,
        17.9855556,
        -32.9577778
      ]
    },
    {
      "id": 5551,
      "name": "Wilmington",
      "names": {
        "zh": "威尔明顿",
        "ja": "ウィルミントン",
        "th": "วิลมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7459468,
        "lng": -75.546589
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.588459,
        39.6880352,
        -75.4707753,
        39.7730389
      ]
    },
    {
      "id": 5552,
      "name": "Sagaing",
      "names": {
        "zh": "实皆"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 21.8774215,
        "lng": 95.9839956
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        95.620795,
        21.8514849,
        96.0246102,
        22.2506627
      ]
    },
    {
      "id": 5553,
      "name": "Gardez",
      "names": {
        "zh": "加德兹"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.59744,
        "lng": 69.22592
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        69.20592,
        33.57744,
        69.24592,
        33.61744
      ]
    },
    {
      "id": 5554,
      "name": "Szolnok",
      "names": {
        "zh": "索尔诺克"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1792403,
        "lng": 20.1753294
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        20.1703294,
        47.1742403,
        20.1803294,
        47.1842403
      ]
    },
    {
      "id": 5555,
      "name": "Encarnacion",
      "names": {
        "zh": "恩卡纳西翁",
        "de": "Encarnación"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -27.3376114,
        "lng": -55.8669492
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -56.0204452,
        -27.4167463,
        -55.7956997,
        -27.1347372
      ]
    },
    {
      "id": 5556,
      "name": "Debre Birhan",
      "names": {
        "es": "Debe Birhan",
        "zh": "德布勒比尔",
        "de": "Dekre Birhan"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.68333,
        "lng": 39.53333
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        39.51333,
        9.66333,
        39.55333,
        9.70333
      ]
    },
    {
      "id": 5557,
      "name": "Viladecans",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3163083,
        "lng": 2.0156034
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.9881082,
        41.2703107,
        2.0748143,
        41.338098
      ]
    },
    {
      "id": 5558,
      "name": "Rochester",
      "names": {
        "zh": "罗切斯特",
        "ja": "ロチェスター",
        "th": "โรเชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3883811,
        "lng": 0.504925
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.4562046,
        51.3526282,
        0.5176398,
        51.3952904
      ]
    },
    {
      "id": 5559,
      "name": "Moquegua",
      "names": {
        "zh": "莫克瓜"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -17.1938025,
        "lng": -70.9345636
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -71.4409561,
        -17.649455,
        -70.5725079,
        -16.7533551
      ]
    },
    {
      "id": 5560,
      "name": "Lynwood",
      "names": {
        "zh": "林伍德"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.924831,
        "lng": -118.2024154
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.2302669,
        33.9048408,
        -118.1768703,
        33.945441
      ]
    },
    {
      "id": 5561,
      "name": "Heroica Caborca",
      "names": {
        "pt": "Heróica caborca"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.7166014,
        "lng": -112.1562598
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -112.3162598,
        30.5566014,
        -111.9962598,
        30.8766014
      ]
    },
    {
      "id": 5562,
      "name": "Washington",
      "names": {
        "zh": "华盛顿",
        "ja": "ワシントン",
        "th": "วอชิงตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 38.8950368,
        "lng": -77.0365427
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -77.1197949,
        38.7916303,
        -76.909366,
        38.995968
      ]
    },
    {
      "id": 5563,
      "name": "Rivera",
      "names": {
        "zh": "里维拉",
        "ja": "リベラ",
        "th": "ริเวร่า"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -30.900058,
        "lng": -55.5408151
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -55.5994839,
        -30.9268826,
        -55.5079869,
        -30.8692159
      ]
    },
    {
      "id": 5564,
      "name": "Ashford",
      "names": {
        "zh": "阿什福德",
        "ja": "アシュフォード"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.148555,
        "lng": 0.8722566
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.5902997,
        50.9894944,
        1.0292101,
        51.2702434
      ]
    },
    {
      "id": 5565,
      "name": "Georgiyevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.15098,
        "lng": 43.472871
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.434677,
        44.129128,
        43.50959,
        44.183006
      ]
    },
    {
      "id": 5566,
      "name": "Passaic",
      "names": {
        "zh": "帕塞伊克"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8567663,
        "lng": -74.1284764
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.146704,
        40.834098,
        -74.106786,
        40.876812
      ]
    },
    {
      "id": 5567,
      "name": "Castellammare di Stabia",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6943046,
        "lng": 14.4804813
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.4417743,
        40.6654115,
        14.5024337,
        40.741754
      ]
    },
    {
      "id": 5568,
      "name": "Balikesir",
      "names": {
        "zh": "巴勒克埃西尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6473917,
        "lng": 27.8879787
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        27.7279787,
        39.4873917,
        28.0479787,
        39.8073917
      ]
    },
    {
      "id": 5569,
      "name": "L'Aquila",
      "names": {
        "zh": "拉奎拉",
        "ja": "ラクイラ",
        "th": "ลากวิลา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.3510918,
        "lng": 13.384921
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        13.379921,
        42.3460918,
        13.389921,
        42.3560918
      ]
    },
    {
      "id": 5570,
      "name": "Cortazar",
      "names": {
        "zh": "科塔萨尔"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4801781,
        "lng": -100.9605769
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.0005769,
        20.4401781,
        -100.9205769,
        20.5201781
      ]
    },
    {
      "id": 5571,
      "name": "Antony",
      "names": {
        "zh": "安东尼",
        "ja": "アントニー",
        "th": "แอนโทนี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.753554,
        "lng": 2.2959423
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.274543,
        48.7293024,
        2.3206998,
        48.7721397
      ]
    },
    {
      "id": 5572,
      "name": "Putrajaya",
      "names": {
        "zh": "普特拉贾亚",
        "th": "ปุตราจายา"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.9384381,
        "lng": 101.69223
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        101.6596875,
        2.8768326,
        101.7326821,
        2.9831441
      ]
    },
    {
      "id": 5573,
      "name": "Liepaja",
      "names": {
        "zh": "利耶帕亚"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.5048435,
        "lng": 21.0070903
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        20.9621193,
        56.4653111,
        21.1057404,
        56.6090517
      ]
    },
    {
      "id": 5574,
      "name": "Kati",
      "names": {
        "zh": "卡蒂",
        "ja": "カティ"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.7389091,
        "lng": -8.0684052
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -8.2284052,
        12.5789091,
        -7.9084052,
        12.8989091
      ]
    },
    {
      "id": 5575,
      "name": "Bouira",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.3779015,
        "lng": 3.8964059
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        3.8914059,
        36.3729015,
        3.9014059,
        36.3829015
      ]
    },
    {
      "id": 5576,
      "name": "Portici",
      "names": {
        "zh": "波蒂奇"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.818385,
        "lng": 14.341919
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.320913,
        40.804587,
        14.360279,
        40.830216
      ]
    },
    {
      "id": 5577,
      "name": "Tamluk",
      "names": {
        "zh": "塔姆鲁克"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.300680999999997,
        "lng": 87.92589275987058
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.92526,
        22.3001817,
        87.9261899,
        22.3012
      ]
    },
    {
      "id": 5578,
      "name": "Veenendaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.02320415,
        "lng": 5.551800233162295
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.5125922,
        51.9969584,
        5.5902422,
        52.0493733
      ]
    },
    {
      "id": 5579,
      "name": "San Jose del Guaviare",
      "names": {
        "zh": "圣何塞德尔瓜亚雷",
        "ja": "サンホセルグアビアレ",
        "th": "ซานโฮเซ่เดลฝรั่ง",
        "pt": "San Jose Del Guaviáre"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5716141,
        "lng": -72.6426515
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -73.6570937,
        2.0118692,
        -70.5067608,
        2.9248366
      ]
    },
    {
      "id": 5580,
      "name": "Ponta Delgada",
      "names": {
        "ja": "ポンタデルガダ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.7393398,
        "lng": -25.6689503
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -25.854812,
        37.732642,
        -25.5867089,
        37.9106405
      ]
    },
    {
      "id": 5581,
      "name": "Hihya",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.6702546,
        "lng": 31.5889562
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.5689562,
        30.6502546,
        31.6089562,
        30.6902546
      ]
    },
    {
      "id": 5582,
      "name": "Al Minshah",
      "names": {
        "ja": "アルミンシャ",
        "th": "อัลมินดาห์"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 14.278416,
        "lng": 43.594804
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        43.574804,
        14.258416,
        43.614804,
        14.298416
      ]
    },
    {
      "id": 5583,
      "name": "Villa Dominico",
      "names": {
        "ja": "ヴィラドミニコ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6938734,
        "lng": -58.3324019
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.3596676,
        -34.7148325,
        -58.295359,
        -34.6616604
      ]
    },
    {
      "id": 5584,
      "name": "Merthyr Tudful",
      "names": {
        "es": "Merthyr tachul",
        "fr": "Merthyr arroussé",
        "zh": "Merthyr cudful",
        "it": "Merthyr Buckful",
        "ja": "MERTHYR"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7444793,
        "lng": -3.3773565
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.3823565,
        51.7394793,
        -3.3723565,
        51.7494793
      ]
    },
    {
      "id": 5585,
      "name": "Kasese",
      "names": {
        "zh": "卡塞塞"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.1310412,
        "lng": 30.00479684525631
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        29.7115112,
        -0.2200935,
        30.2976168,
        0.4822287
      ]
    },
    {
      "id": 5586,
      "name": "Kitamoto",
      "names": {
        "zh": "北本"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0268711,
        "lng": 139.5301389
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.494292,
        35.99587,
        139.565049,
        36.057607
      ]
    },
    {
      "id": 5587,
      "name": "Saki",
      "names": {
        "zh": "萨基",
        "ja": "サキ"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.2033609,
        "lng": 47.1802623
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.1342067,
        41.1223715,
        47.2162014,
        41.2424288
      ]
    },
    {
      "id": 5588,
      "name": "Afragola",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.924697,
        "lng": 14.310909
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.294289,
        40.90707,
        14.339067,
        40.94086
      ]
    },
    {
      "id": 5589,
      "name": "Zamosc",
      "names": {
        "zh": "扎莫希奇",
        "th": "ซามอชช์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7170854,
        "lng": 23.2525711
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.1902384,
        50.6924154,
        23.3067944,
        50.7503786
      ]
    },
    {
      "id": 5590,
      "name": "Assen",
      "names": {
        "zh": "阿森",
        "ja": "アッセン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.9952273,
        "lng": 6.560498
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        6.4839477,
        52.9325148,
        6.6109719,
        53.0484182
      ]
    },
    {
      "id": 5591,
      "name": "Adrar",
      "names": {
        "zh": "阿德拉尔"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 27.9324975,
        "lng": -1.4230110226505033
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -2.921222,
        27.69953,
        -0.04751,
        28.16506
      ]
    },
    {
      "id": 5592,
      "name": "Tomigusuku",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.1768412,
        "lng": 127.6820924
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.6820424,
        26.1767912,
        127.6821424,
        26.1768912
      ]
    },
    {
      "id": 5593,
      "name": "Bayombong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.4829252,
        "lng": 121.1505219
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.1105219,
        16.4429252,
        121.1905219,
        16.5229252
      ]
    },
    {
      "id": 5594,
      "name": "Buynaksk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 42.825744,
        "lng": 47.112981
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        47.091976,
        42.808304,
        47.138016,
        42.839485
      ]
    },
    {
      "id": 5595,
      "name": "Bankra",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.60291,
        "lng": 88.27751
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5596,
      "name": "Ban Suan",
      "names": {
        "es": "Ban Suean",
        "zh": "班苏南",
        "de": "Ban-Suan",
        "ja": "スアンを禁止する",
        "th": "บ้านสวน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3474462,
        "lng": 100.9952236
      },
      "country": "Thailand",
      "importance": 3,
      "bbox": [
        100.9552236,
        13.3074462,
        101.0352236,
        13.3874462
      ]
    },
    {
      "id": 5597,
      "name": "Aregua",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -25.3125,
        "lng": -57.38472
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -57.40472,
        -25.3325,
        -57.36472,
        -25.2925
      ]
    },
    {
      "id": 5598,
      "name": "Hameenlinna",
      "names": {
        "zh": "海门林纳",
        "it": "Hämeenlinna"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.996779,
        "lng": 24.446749
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        24.362458,
        60.949441,
        24.577133,
        61.023765
      ]
    },
    {
      "id": 5599,
      "name": "Guaynabo",
      "names": {},
      "countryCode": "PR",
      "latLng": {
        "lat": 18.368211,
        "lng": -66.109321
      },
      "country": "Puerto Rico",
      "importance": 3,
      "bbox": [
        -66.142021,
        18.332759,
        -66.088351,
        18.440542
      ]
    },
    {
      "id": 5600,
      "name": "Vaasa",
      "names": {
        "zh": "瓦萨"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.097777,
        "lng": 21.6219678
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        21.6169678,
        63.092777,
        21.6269678,
        63.102777
      ]
    },
    {
      "id": 5601,
      "name": "Gaithersburg",
      "names": {
        "zh": "盖瑟斯堡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1399187,
        "lng": -77.1929215
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.2582727,
        39.1027533,
        -77.1711755,
        39.1683086
      ]
    },
    {
      "id": 5602,
      "name": "Union City",
      "names": {
        "zh": "联盟城",
        "de": "Union",
        "id": "Kota serikat",
        "ja": "ユニオンシティ",
        "th": "เมืองยูเนียน",
        "pt": "Cidade da União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7795455,
        "lng": -74.0237512
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.04586,
        40.750614,
        -74.014798,
        40.782873
      ]
    },
    {
      "id": 5603,
      "name": "Vyronas",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9564565,
        "lng": 23.7554813
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.7354813,
        37.9364565,
        23.7754813,
        37.9764565
      ]
    },
    {
      "id": 5604,
      "name": "Scarborough",
      "names": {
        "zh": "斯卡伯勒",
        "ja": "スカーバラ",
        "th": "สการ์เบอโร"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.2799474,
        "lng": -0.4056223
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.4106223,
        54.2749474,
        -0.4006223,
        54.2849474
      ]
    },
    {
      "id": 5605,
      "name": "South San Francisco",
      "names": {
        "fr": "Sud San Francisco",
        "zh": "南旧金山",
        "it": "Sud San Francisco",
        "id": "San Francisco Selatan",
        "ja": "サウスサンフランシスコ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6535403,
        "lng": -122.4168664
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.471735,
        37.6343795,
        -122.2205448,
        37.673334
      ]
    },
    {
      "id": 5606,
      "name": "Farnborough",
      "names": {
        "zh": "法恩伯勒",
        "th": "เมา"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2918693,
        "lng": -0.7539836
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7939836,
        51.2518693,
        -0.7139836,
        51.3318693
      ]
    },
    {
      "id": 5607,
      "name": "Cabedelo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.9741177,
        "lng": -34.8359576
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -34.8360076,
        -6.9741677,
        -34.8359076,
        -6.9740677
      ]
    },
    {
      "id": 5608,
      "name": "Lao Cai",
      "names": {
        "zh": "老蔡",
        "ja": "ラオカイ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.48556,
        "lng": 103.97066
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        103.95066,
        22.46556,
        103.99066,
        22.50556
      ]
    },
    {
      "id": 5609,
      "name": "Molepolole",
      "names": {
        "zh": "莫莱波洛莱"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.40659,
        "lng": 25.49508
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        25.47508,
        -24.42659,
        25.51508,
        -24.38659
      ]
    },
    {
      "id": 5610,
      "name": "Potenza",
      "names": {
        "zh": "波滕扎",
        "ja": "ポテンザ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6384046,
        "lng": 15.8022077
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        15.7062791,
        40.5802541,
        15.9030173,
        40.7417424
      ]
    },
    {
      "id": 5611,
      "name": "El Prat de Llobregat",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3246333,
        "lng": 2.0952568
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.0573318,
        41.276588,
        2.1676165,
        41.3483406
      ]
    },
    {
      "id": 5612,
      "name": "Teresa",
      "names": {
        "zh": "特里萨",
        "ja": "テレサ",
        "th": "เทเรซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5588946,
        "lng": 121.2079458
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1679458,
        14.5188946,
        121.2479458,
        14.5988946
      ]
    },
    {
      "id": 5613,
      "name": "Dimbokro",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.6494254,
        "lng": -4.7040555
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -4.7440555,
        6.6094254,
        -4.6640555,
        6.6894254
      ]
    },
    {
      "id": 5614,
      "name": "Katwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2005078,
        "lng": 4.4146372
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3600018,
        52.1683432,
        4.4423383,
        52.2252437
      ]
    },
    {
      "id": 5615,
      "name": "Mount Vernon",
      "names": {
        "fr": "Mont Vernon",
        "zh": "弗农山",
        "de": "Büschel",
        "ja": "マウントバーノン",
        "pt": "Monte Vernon"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9125085,
        "lng": -73.8386504
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.853278,
        40.889477,
        -73.805062,
        40.935289
      ]
    },
    {
      "id": 5616,
      "name": "Arrecife",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 28.9639681,
        "lng": -13.5499014
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -13.5876942,
        28.9524412,
        -13.5157317,
        29.0062302
      ]
    },
    {
      "id": 5617,
      "name": "Altamira",
      "names": {
        "zh": "阿尔塔米拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.650998350000002,
        "lng": -70.79298720485
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -70.9005488,
        19.5803437,
        -70.7052484,
        19.721719
      ]
    },
    {
      "id": 5618,
      "name": "Guamuchil",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4575492,
        "lng": -108.0755437
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.1065174,
        25.4283327,
        -108.0439152,
        25.4898226
      ]
    },
    {
      "id": 5619,
      "name": "Stourbridge",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4577615,
        "lng": -2.1474345
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.1874345,
        52.4177615,
        -2.1074345,
        52.4977615
      ]
    },
    {
      "id": 5620,
      "name": "Fryazino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.949503,
        "lng": 38.055252
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.019665,
        55.916992,
        38.117348,
        55.964249
      ]
    },
    {
      "id": 5621,
      "name": "Acambaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.030466,
        "lng": -100.723038
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.738171,
        19.990556,
        -100.679766,
        20.057218
      ]
    },
    {
      "id": 5622,
      "name": "Lechang",
      "names": {
        "zh": "乐昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6402213,
        "lng": 111.4649915
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.4249915,
        35.6002213,
        111.5049915,
        35.6802213
      ]
    },
    {
      "id": 5623,
      "name": "Bolgatanga",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 10.78556,
        "lng": -0.85139
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -0.87139,
        10.76556,
        -0.83139,
        10.80556
      ]
    },
    {
      "id": 5624,
      "name": "Martil",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.617441,
        "lng": -5.274154
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.3347633,
        35.5963353,
        -5.2660275,
        35.6447507
      ]
    },
    {
      "id": 5625,
      "name": "Epinay-sur-Seine",
      "names": {
        "es": "Epinay-sur-Sena",
        "zh": "epinay塞纳河畔",
        "it": "Epinay sur-Seine",
        "ja": "epinayシュルセーヌ",
        "th": "epinay-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9525181,
        "lng": 2.3145039
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.2882783,
        48.9426269,
        2.3460231,
        48.9663105
      ]
    },
    {
      "id": 5626,
      "name": "Redondo Beach",
      "names": {
        "es": "Playa de redondo",
        "fr": "Plage de Redondo",
        "zh": "雷东多海滩",
        "it": "Spiaggia di Redondo",
        "id": "Pantai Redondo",
        "ja": "レドンドビーチ",
        "th": "ชายหาด Redondo",
        "pt": "Praia Redondo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.867435,
        "lng": -118.372492
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.401951,
        33.814615,
        -118.352347,
        33.894653
      ]
    },
    {
      "id": 5627,
      "name": "Kraljevo",
      "names": {
        "zh": "克拉列沃"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7234519,
        "lng": 20.6870792
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.636906,
        43.6852614,
        20.7503206,
        43.7444567
      ]
    },
    {
      "id": 5628,
      "name": "Tamarac",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 26.2128609,
        "lng": -80.2497707
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2976277,
        26.1793239,
        -80.1629854,
        26.2298201
      ]
    },
    {
      "id": 5629,
      "name": "Hereford",
      "names": {
        "zh": "赫里福德",
        "ja": "ヘレフォード",
        "th": "เฮียร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.0553813,
        "lng": -2.7151735
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.7747454,
        52.0283758,
        -2.6725099,
        52.0776615
      ]
    },
    {
      "id": 5630,
      "name": "Agia Paraskevi",
      "names": {
        "ja": "アギアパラスク"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0066759,
        "lng": 23.8226942
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.8026942,
        37.9866759,
        23.8426942,
        38.0266759
      ]
    },
    {
      "id": 5631,
      "name": "Troyes",
      "names": {
        "zh": "特鲁瓦",
        "ja": "トロワ",
        "th": "ทรัวส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.2971626,
        "lng": 4.0746257
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.0411689,
        48.2663185,
        4.1111417,
        48.3185905
      ]
    },
    {
      "id": 5632,
      "name": "Granollers",
      "names": {
        "zh": "格拉诺列尔斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6079555,
        "lng": 2.2876008
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.2564393,
        41.5642729,
        2.3040077,
        41.6225765
      ]
    },
    {
      "id": 5633,
      "name": "Veliko Tarnovo",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.0820584,
        "lng": 25.6321312
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        25.5672069,
        43.0549369,
        25.6759633,
        43.12069
      ]
    },
    {
      "id": 5634,
      "name": "Nalut",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 31.86848,
        "lng": 10.9812
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        10.9612,
        31.84848,
        11.0012,
        31.88848
      ]
    },
    {
      "id": 5635,
      "name": "Zacapa",
      "names": {
        "zh": "萨卡帕"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9707219,
        "lng": -89.5297411
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -89.6493968,
        14.8573369,
        -89.3168865,
        15.0842692
      ]
    },
    {
      "id": 5636,
      "name": "Los Polvorines",
      "names": {
        "ja": "ロスポルボリン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4987808,
        "lng": -58.6950361
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.7000361,
        -34.5037808,
        -58.6900361,
        -34.4937808
      ]
    },
    {
      "id": 5637,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.3361663,
        "lng": -121.890591
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        -122.0460405,
        37.1231596,
        -121.5858438,
        37.4691477
      ]
    },
    {
      "id": 5638,
      "name": "Maisons-Alfort",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.805989,
        "lng": 2.436561
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.415905,
        48.78673,
        2.464016,
        48.816928
      ]
    },
    {
      "id": 5639,
      "name": "Prilep",
      "names": {
        "zh": "普里莱普"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3444984,
        "lng": 21.5527115
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.5107847,
        41.3230496,
        21.5840974,
        41.3710095
      ]
    },
    {
      "id": 5640,
      "name": "Trnava",
      "names": {
        "zh": "特尔纳瓦"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3767652,
        "lng": 17.5858175
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        17.4874416,
        48.3373658,
        17.659514,
        48.4278428
      ]
    },
    {
      "id": 5641,
      "name": "Masaka",
      "names": {
        "zh": "马萨卡"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3390264,
        "lng": 31.7465839
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.5832895,
        -0.456909,
        31.8741021,
        -0.2297547
      ]
    },
    {
      "id": 5642,
      "name": "Collado-Villalba",
      "names": {
        "es": "Collado Villalba,",
        "fr": "-Collado Villalba",
        "zh": "科利亚多-维拉尔巴",
        "it": "-Collado Villalba"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.636196,
        "lng": -4.002027
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.011963,
        40.619344,
        -3.977194,
        40.654052
      ]
    },
    {
      "id": 5643,
      "name": "Dewsbury",
      "names": {
        "zh": "迪斯伯里",
        "th": "ดิวสเบอรี่"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6912847,
        "lng": -1.6343049
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.6343549,
        53.6912347,
        -1.6342549,
        53.6913347
      ]
    },
    {
      "id": 5644,
      "name": "Goz-Beida",
      "names": {
        "zh": "goz-贝达"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.225592,
        "lng": 21.4101617
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        21.2501617,
        12.065592,
        21.5701617,
        12.385592
      ]
    },
    {
      "id": 5645,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9643813,
        "lng": -91.7943267
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.9029766,
        14.8988066,
        -91.7649203,
        15.0759693
      ]
    },
    {
      "id": 5646,
      "name": "Loughborough",
      "names": {
        "es": "De Loughborough",
        "zh": "拉夫堡"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.7723859,
        "lng": -1.2077985
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.2477985,
        52.7323859,
        -1.1677985,
        52.8123859
      ]
    },
    {
      "id": 5647,
      "name": "Wrecsam",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 53.0465084,
        "lng": -2.9937869
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.1537869,
        52.8865084,
        -2.8337869,
        53.2065084
      ]
    },
    {
      "id": 5648,
      "name": "Samut Sakhon",
      "names": {
        "ja": "サムッツサコン",
        "th": "สมุทรสาคร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5497782,
        "lng": 100.2742813
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.1142813,
        13.3897782,
        100.4342813,
        13.7097782
      ]
    },
    {
      "id": 5649,
      "name": "Tatabanya",
      "names": {
        "zh": "陶陶巴尼奥",
        "de": "Tatabánya"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5854431,
        "lng": 18.3928956
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        18.3878956,
        47.5804431,
        18.3978956,
        47.5904431
      ]
    },
    {
      "id": 5650,
      "name": "La Linea de la Concepcion",
      "names": {
        "es": "La Linea de la Concepción",
        "ja": "Lainea de la Concepcion"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.166485,
        "lng": -5.349048
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.36826,
        36.153154,
        -5.332095,
        36.183319
      ]
    },
    {
      "id": 5651,
      "name": "Sarcelles",
      "names": {
        "zh": "萨塞勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9960813,
        "lng": 2.3796245
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.3633947,
        48.9712414,
        2.4081806,
        49.0137855
      ]
    },
    {
      "id": 5652,
      "name": "Ioannina",
      "names": {
        "zh": "约阿尼纳",
        "id": "Ioánnina"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.6639818,
        "lng": 20.8522784
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        20.6922784,
        39.5039818,
        21.0122784,
        39.8239818
      ]
    },
    {
      "id": 5653,
      "name": "La Seyne-sur-Mer",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1007714,
        "lng": 5.8788948
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        5.8463532,
        43.045838,
        5.9112679,
        43.1216422
      ]
    },
    {
      "id": 5654,
      "name": "Florence-Graham",
      "names": {
        "es": "Florencia, Graham",
        "zh": "佛罗伦萨-·格雷厄姆",
        "de": "Florenz-Graham",
        "it": "Firenze-Graham",
        "ja": "フィレンツェ・グラハム",
        "th": "ฟลอเรนซ์-เกรแฮม",
        "pt": "Florença-Graham"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.966071,
        "lng": -118.244657
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.256439,
        33.943089,
        -118.227476,
        33.989498
      ]
    },
    {
      "id": 5655,
      "name": "Trabzon",
      "names": {
        "zh": "特拉布宗"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0054605,
        "lng": 39.7301463
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        39.5701463,
        40.8454605,
        39.8901463,
        41.1654605
      ]
    },
    {
      "id": 5656,
      "name": "Kettering",
      "names": {
        "zh": "凯特林",
        "th": "เค็ตเตอริง"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.3934872,
        "lng": -0.7320452
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7370452,
        52.3884872,
        -0.7270452,
        52.3984872
      ]
    },
    {
      "id": 5657,
      "name": "Calarasi",
      "names": {
        "zh": "克勒拉希"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.1961646,
        "lng": 27.3313233
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.268679,
        44.1278997,
        27.4034176,
        44.3174075
      ]
    },
    {
      "id": 5658,
      "name": "Denizli",
      "names": {
        "zh": "代尼兹利",
        "ja": "デニズリ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7827875,
        "lng": 29.0966476
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        28.9366476,
        37.6227875,
        29.2566476,
        37.9427875
      ]
    },
    {
      "id": 5659,
      "name": "Nieuwegein",
      "names": {
        "zh": "尼沃海恩"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0367152,
        "lng": 5.0836117
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.0625115,
        51.9974518,
        5.1309933,
        52.0622556
      ]
    },
    {
      "id": 5660,
      "name": "Rundu",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.9174,
        "lng": 19.766666
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        19.746666,
        -17.9374,
        19.786666,
        -17.8974
      ]
    },
    {
      "id": 5661,
      "name": "Kitale",
      "names": {
        "zh": "基塔莱"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 1.018076,
        "lng": 35.000236
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.9556335,
        0.9780823,
        35.0509056,
        1.0559185
      ]
    },
    {
      "id": 5662,
      "name": "Nagakute",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.18335,
        "lng": 137.04895
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.94895,
        35.08335,
        137.14895,
        35.28335
      ]
    },
    {
      "id": 5663,
      "name": "Schenectady",
      "names": {
        "zh": "斯克内克塔迪",
        "th": "สเกเนกทาดี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.810078,
        "lng": -73.923794
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.983245,
        42.763432,
        -73.893042,
        42.843697
      ]
    },
    {
      "id": 5664,
      "name": "Villejuif",
      "names": {
        "zh": "维勒瑞夫"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7921098,
        "lng": 2.3633048
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.3437618,
        48.7786895,
        2.3768637,
        48.8080858
      ]
    },
    {
      "id": 5665,
      "name": "Songkhla",
      "names": {
        "zh": "宋卡",
        "th": "สงขลา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.1973429,
        "lng": 100.593205
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.433205,
        7.0373429,
        100.753205,
        7.3573429
      ]
    },
    {
      "id": 5666,
      "name": "Ouezzane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.7997,
        "lng": -5.57505
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.67505,
        34.6997,
        -5.47505,
        34.8997
      ]
    },
    {
      "id": 5667,
      "name": "Tagajo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.2938132,
        "lng": 141.0042642
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.9560711,
        38.276412,
        141.045089,
        38.314239
      ]
    },
    {
      "id": 5668,
      "name": "Singida",
      "names": {
        "zh": "辛吉达"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -4.8195746,
        "lng": 34.7498283
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        34.5898283,
        -4.9795746,
        34.9098283,
        -4.6595746
      ]
    },
    {
      "id": 5669,
      "name": "Solwezi",
      "names": {
        "zh": "索卢韦齐"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.1820553,
        "lng": 26.3990713
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        26.3590713,
        -12.2220553,
        26.4390713,
        -12.1420553
      ]
    },
    {
      "id": 5670,
      "name": "As Suwayda'",
      "names": {
        "es": "Como suwayda '",
        "fr": "Comme suwayda '",
        "zh": "苏韦达'",
        "de": "Als suwayda '",
        "it": "Come suwayda '",
        "id": "Sebagai suwayda '",
        "ja": "Suwayda 'として",
        "th": "เช่นเดียวกับ Suwayda '",
        "pt": "Como Suayda '"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.7093878,
        "lng": 36.5687496
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        36.4087496,
        32.5493878,
        36.7287496,
        32.8693878
      ]
    },
    {
      "id": 5671,
      "name": "Euclides da Cunha",
      "names": {
        "fr": "Euclide da cunha",
        "ja": "ユキクレッドダ・チェンジ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.5060835,
        "lng": -39.0129875
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.2614045,
        -10.6784512,
        -38.5769265,
        -10.2562007
      ]
    },
    {
      "id": 5672,
      "name": "Forest",
      "names": {
        "es": "Bosque",
        "fr": "Forêt",
        "zh": "森林",
        "de": "Wald",
        "it": "Foresta",
        "id": "Hutan",
        "ja": "森林",
        "th": "ป่า",
        "pt": "Floresta"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.811795,
        "lng": 4.3181187
      },
      "country": "Belgium",
      "importance": 3,
      "bbox": [
        4.3020229,
        50.7976094,
        4.353168,
        50.8308493
      ]
    },
    {
      "id": 5673,
      "name": "Elk",
      "names": {
        "zh": "麋鹿",
        "ja": "エルク",
        "th": "กวาง",
        "pt": "Alces"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.82395835,
        "lng": 22.361916488932224
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.3129569,
        53.7901443,
        22.407004,
        53.8577161
      ]
    },
    {
      "id": 5674,
      "name": "Bayonne",
      "names": {
        "es": "Bayona",
        "zh": "巴约讷",
        "ja": "バヨンヌ",
        "th": "บาย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6687141,
        "lng": -74.1143091
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.1614661,
        40.641989,
        -74.049594,
        40.701072
      ]
    },
    {
      "id": 5675,
      "name": "Faro",
      "names": {
        "zh": "法鲁",
        "ja": "ファロ",
        "th": "แฟโร"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0162727,
        "lng": -7.9351771
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.0064895,
        36.9599679,
        -7.8059453,
        37.1457772
      ]
    },
    {
      "id": 5676,
      "name": "Faizabad",
      "names": {
        "zh": "法扎巴德"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 37.1187512,
        "lng": 70.5784966
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        70.4184966,
        36.9587512,
        70.7384966,
        37.2787512
      ]
    },
    {
      "id": 5677,
      "name": "Cosamaloapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.364414,
        "lng": -95.7965793
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.8365793,
        18.324414,
        -95.7565793,
        18.404414
      ]
    },
    {
      "id": 5678,
      "name": "Sokhumi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 43.0033629,
        "lng": 41.0192741
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        40.9599227,
        42.9804327,
        41.0520223,
        43.0266147
      ]
    },
    {
      "id": 5679,
      "name": "Ellesmere Port",
      "names": {
        "es": "Puerto de Ellesmere",
        "fr": "Port de ellesmere",
        "zh": "ellesmere港口",
        "de": "Ellesmere Hafen",
        "it": "Porto di Ellesmere",
        "id": "Pelabuhan Ellesmere",
        "ja": "ellesmereポート",
        "th": "พอร์ต Ellesmere",
        "pt": "Porto Ellesmere"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.2789347,
        "lng": -2.9022507
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.9422507,
        53.2389347,
        -2.8622507,
        53.3189347
      ]
    },
    {
      "id": 5680,
      "name": "Solola",
      "names": {
        "es": "Sololá",
        "zh": "索洛拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7722369,
        "lng": -91.1833175
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.3433175,
        14.6122369,
        -91.0233175,
        14.9322369
      ]
    },
    {
      "id": 5682,
      "name": "Yamatotakada",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5150901,
        "lng": 135.7364961
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.714351,
        34.476706,
        135.766022,
        34.532297
      ]
    },
    {
      "id": 5683,
      "name": "Yoro",
      "names": {
        "zh": "养老"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.1379438,
        "lng": -87.124948
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -87.5625194,
        15.0151271,
        -86.855189,
        15.545764
      ]
    },
    {
      "id": 5684,
      "name": "Pruszkow",
      "names": {
        "de": "Pruszków"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1682227,
        "lng": 20.7989255
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.7939255,
        52.1632227,
        20.8039255,
        52.1732227
      ]
    },
    {
      "id": 5685,
      "name": "Manouba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8020612,
        "lng": 10.1025957
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.1025457,
        36.8020112,
        10.1026457,
        36.8021112
      ]
    },
    {
      "id": 5686,
      "name": "East Orange",
      "names": {
        "es": "Orange Oriente",
        "fr": "Orange est",
        "zh": "东橙",
        "de": "Östlich",
        "id": "Oranye timur",
        "ja": "イーストオレンジ",
        "th": "สีส้มตะวันออก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.765519,
        "lng": -74.20724
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.238256,
        40.745083,
        -74.189759,
        40.788213
      ]
    },
    {
      "id": 5687,
      "name": "Nagari",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.3208103,
        "lng": 79.585197
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.545197,
        13.2808103,
        79.625197,
        13.3608103
      ]
    },
    {
      "id": 5688,
      "name": "Le Blanc-Mesnil",
      "names": {
        "th": "เลอแบลแคลส์เมสมันล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9385489,
        "lng": 2.4631476
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.4308723,
        48.9184007,
        2.4842425,
        48.9559444
      ]
    },
    {
      "id": 5689,
      "name": "Bangor",
      "names": {
        "zh": "班戈",
        "ja": "バンゴー",
        "th": "บันกอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.662595,
        "lng": -5.6679127
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -5.8279127,
        54.502595,
        -5.5079127,
        54.822595
      ]
    },
    {
      "id": 5690,
      "name": "Inhambane",
      "names": {
        "zh": "伊尼扬巴内"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -23.865,
        "lng": 35.38333
      },
      "country": "Mozambique",
      "importance": 1,
      "bbox": [
        35.36333,
        -23.885,
        35.40333,
        -23.845
      ]
    },
    {
      "id": 5691,
      "name": "Runcorn",
      "names": {
        "zh": "朗科恩",
        "ja": "ランコーン",
        "th": "รันคอร์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3421509,
        "lng": -2.7334941
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.7710981,
        53.3057996,
        -2.6460248,
        53.3612601
      ]
    },
    {
      "id": 5692,
      "name": "Taunton",
      "names": {
        "zh": "汤顿",
        "ja": "トーントン",
        "th": "ทอน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.01778965,
        "lng": -3.0958395414658035
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.1493285,
        50.99286,
        -3.0558014,
        51.042716
      ]
    },
    {
      "id": 5693,
      "name": "Littlehampton",
      "names": {
        "zh": "利特尔汉普顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8095801,
        "lng": -0.5424424
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.5761231,
        50.7978044,
        -0.5143318,
        50.8308853
      ]
    },
    {
      "id": 5694,
      "name": "Alba Iulia",
      "names": {
        "ja": "アルバイーリア"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.077003,
        "lng": 23.580001
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        23.545138,
        46.039318,
        23.593983,
        46.085421
      ]
    },
    {
      "id": 5695,
      "name": "Latacunga",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.9340311,
        "lng": -78.6145758
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.6671817,
        -1.0086747,
        -78.4015199,
        -0.8674417
      ]
    },
    {
      "id": 5696,
      "name": "Bondy",
      "names": {
        "zh": "邦迪"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.901433,
        "lng": 2.48352
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.469303,
        48.887027,
        2.499851,
        48.920178
      ]
    },
    {
      "id": 5697,
      "name": "Gorno-Altaysk",
      "names": {
        "zh": "戈尔诺-altaysk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.957775,
        "lng": 85.963653
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        85.8820023,
        51.8702622,
        86.0653842,
        51.9936497
      ]
    },
    {
      "id": 5698,
      "name": "Hasuda",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.994092,
        "lng": 139.6632547
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.588186,
        35.961864,
        139.695633,
        36.037113
      ]
    },
    {
      "id": 5699,
      "name": "Marano di Napoli",
      "names": {
        "ja": "マラノディナポリ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.898345,
        "lng": 14.185348
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.170729,
        40.880036,
        14.205305,
        40.906643
      ]
    },
    {
      "id": 5700,
      "name": "Dedougou",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.4629189,
        "lng": -3.4600757
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -3.4901675,
        12.4386479,
        -3.4406128,
        12.4871234
      ]
    },
    {
      "id": 5701,
      "name": "Legnano",
      "names": {
        "zh": "莱尼亚诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5935927,
        "lng": 8.9106745
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        8.9106245,
        45.5935427,
        8.9107245,
        45.5936427
      ]
    },
    {
      "id": 5702,
      "name": "Osakasayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.503879,
        "lng": 135.549966
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.532637,
        34.466504,
        135.5667733,
        34.529422
      ]
    },
    {
      "id": 5703,
      "name": "Taal",
      "names": {
        "es": "Idioma",
        "zh": "塔尔",
        "ja": "タール",
        "th": "ภาษา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8801817,
        "lng": 120.9232723
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7980331,
        13.8303931,
        120.9725833,
        13.9210337
      ]
    },
    {
      "id": 5704,
      "name": "Kahramanmaras",
      "names": {
        "zh": "卡赫拉曼马拉什",
        "th": "คาห์รามานมารัส"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5812744,
        "lng": 36.927509
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.767509,
        37.4212744,
        37.087509,
        37.7412744
      ]
    },
    {
      "id": 5705,
      "name": "Mukocho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9440217,
        "lng": 135.698273
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.688273,
        34.9340217,
        135.708273,
        34.9540217
      ]
    },
    {
      "id": 5706,
      "name": "Nkhotakota",
      "names": {
        "zh": "恩科塔科塔"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -12.9269733,
        "lng": 34.2967127
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.2567127,
        -12.9669733,
        34.3367127,
        -12.8869733
      ]
    },
    {
      "id": 5707,
      "name": "Brentwood",
      "names": {
        "zh": "布伦特伍德",
        "ja": "ブレントウッド",
        "th": "แนชวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7812093,
        "lng": -73.2462273
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.2953478,
        40.754761,
        -73.209234,
        40.8084299
      ]
    },
    {
      "id": 5708,
      "name": "Karakol",
      "names": {
        "zh": "喀拉口尔"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.4916808,
        "lng": 78.3901729
      },
      "country": "Kyrgyzstan",
      "importance": 1,
      "bbox": [
        78.2801226,
        42.4340174,
        78.436759,
        42.5891887
      ]
    },
    {
      "id": 5709,
      "name": "Tsushima",
      "names": {
        "zh": "对马岛",
        "ja": "対馬"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1782248,
        "lng": 136.7310838
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.7260838,
        35.1732248,
        136.7360838,
        35.1832248
      ]
    },
    {
      "id": 5710,
      "name": "Sopron",
      "names": {
        "zh": "肖普朗",
        "ja": "ショプロン"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6776491,
        "lng": 16.5868912
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        16.5818912,
        47.6726491,
        16.5918912,
        47.6826491
      ]
    },
    {
      "id": 5711,
      "name": "Anuradhapura",
      "names": {
        "zh": "阿努拉德普勒"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 8.334985,
        "lng": 80.4106096
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        80.2506096,
        8.174985,
        80.5706096,
        8.494985
      ]
    },
    {
      "id": 5712,
      "name": "Cherbourg",
      "names": {
        "zh": "瑟堡"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.633345649999995,
        "lng": -1.6207140741972381
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.6216496,
        49.6330514,
        -1.6204847,
        49.6336421
      ]
    },
    {
      "id": 5713,
      "name": "Kratie",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 12.48811,
        "lng": 106.01879
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        105.99879,
        12.46811,
        106.03879,
        12.50811
      ]
    },
    {
      "id": 5714,
      "name": "Sabinas",
      "names": {
        "zh": "萨维纳斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.8524011,
        "lng": -101.1183777
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.2783777,
        27.6924011,
        -100.9583777,
        28.0124011
      ]
    },
    {
      "id": 5715,
      "name": "Ban Rangsit",
      "names": {
        "fr": "Interdiction",
        "zh": "禁止rangsit",
        "it": "Banksit di Ban",
        "ja": "禁止rangsit",
        "th": "บ้านรังสิต"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.97281,
        "lng": 100.597
      },
      "country": "Thailand",
      "importance": 3,
      "bbox": [
        100.577,
        13.95281,
        100.617,
        13.99281
      ]
    },
    {
      "id": 5716,
      "name": "Beledweyne",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 4.7367735,
        "lng": 45.2038881
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        45.0438881,
        4.5767735,
        45.3638881,
        4.8967735
      ]
    },
    {
      "id": 5717,
      "name": "Wallasey",
      "names": {
        "zh": "沃拉西"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4241208,
        "lng": -3.0394101
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0794101,
        53.3841208,
        -2.9994101,
        53.4641208
      ]
    },
    {
      "id": 5718,
      "name": "Tandag",
      "names": {
        "zh": "丹达"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.07833,
        "lng": 126.19861
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        126.17861,
        9.05833,
        126.21861,
        9.09833
      ]
    },
    {
      "id": 5719,
      "name": "Khartsyzk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0339524,
        "lng": 38.149804
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        38.0989353,
        48.0187427,
        38.1850398,
        48.0620824
      ]
    },
    {
      "id": 5721,
      "name": "Stryi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.255875,
        "lng": 23.8530863
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.7971299,
        49.2339605,
        23.8874383,
        49.2799187
      ]
    },
    {
      "id": 5722,
      "name": "North Miami",
      "names": {
        "es": "Norte de Miami",
        "zh": "北迈阿密",
        "de": "Nordmiami",
        "it": "Miami del Nord",
        "id": "Miami utara",
        "ja": "ノースマイアミ",
        "th": "ไมอามีเหนือ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.897224,
        "lng": -80.172766
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.227543,
        25.881003,
        -80.126668,
        25.930359
      ]
    },
    {
      "id": 5723,
      "name": "Skokie",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 42.038024,
        "lng": -87.740676
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.780882,
        42.003325,
        -87.708661,
        42.06504
      ]
    },
    {
      "id": 5724,
      "name": "Leskovac",
      "names": {
        "zh": "莱斯科瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9951304,
        "lng": 21.9464271
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.9126669,
        42.9617989,
        21.9833752,
        43.0342427
      ]
    },
    {
      "id": 5725,
      "name": "Saraburi",
      "names": {
        "zh": "沙拉武里",
        "th": "สระบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5273879,
        "lng": 100.916216
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.911216,
        14.5223879,
        100.921216,
        14.5323879
      ]
    },
    {
      "id": 5726,
      "name": "Rayong",
      "names": {
        "zh": "罗勇",
        "ja": "ラヨーン",
        "th": "ระยอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6817905,
        "lng": 101.277765
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.117765,
        12.5217905,
        101.437765,
        12.8417905
      ]
    },
    {
      "id": 5727,
      "name": "Saint-Ouen",
      "names": {
        "zh": "圣 -  ouen",
        "ja": "サン・ouen",
        "th": "เซนต์-ouen"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.911729,
        "lng": 2.334267
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.3137753,
        48.9004581,
        2.3520291,
        48.9231734
      ]
    },
    {
      "id": 5728,
      "name": "Kolda",
      "names": {
        "zh": "科尔达"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.8921154,
        "lng": -14.9400971
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -14.9739233,
        12.8653657,
        -14.8970487,
        12.9311426
      ]
    },
    {
      "id": 5729,
      "name": "Santarem",
      "names": {
        "zh": "圣塔伦",
        "pt": "Santarém"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2363637,
        "lng": -8.6867081
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.8994474,
        39.1687465,
        -8.5568969,
        39.4830095
      ]
    },
    {
      "id": 5730,
      "name": "Fussa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7423308,
        "lng": 139.3278791
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3228791,
        35.7373308,
        139.3328791,
        35.7473308
      ]
    },
    {
      "id": 5731,
      "name": "Napier",
      "names": {
        "zh": "纳皮尔",
        "ja": "ネイピア",
        "th": "เนเปียร์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -39.4902099,
        "lng": 176.917839
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        176.8112659,
        -39.5704655,
        176.9259305,
        -39.3881932
      ]
    },
    {
      "id": 5732,
      "name": "Bobigny",
      "names": {
        "zh": "博比尼"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9071563,
        "lng": 2.4420392022799193
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.4100426,
        48.8952245,
        2.4742854,
        48.9194431
      ]
    },
    {
      "id": 5733,
      "name": "Linares",
      "names": {
        "zh": "利纳雷斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8596282,
        "lng": -99.5684539
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.6084539,
        24.8196282,
        -99.5284539,
        24.8996282
      ]
    },
    {
      "id": 5734,
      "name": "Chornomorsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.3020943,
        "lng": 30.653466
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        30.6309121,
        46.2826631,
        30.6713815,
        46.3201712
      ]
    },
    {
      "id": 5735,
      "name": "Madang",
      "names": {
        "zh": "马当"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.212038,
        "lng": 145.8054231
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        145.6454231,
        -5.372038,
        145.9654231,
        -5.052038
      ]
    },
    {
      "id": 5736,
      "name": "Lytkarino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.577569600000004,
        "lng": 37.900055036788835
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.8997051,
        55.5774651,
        37.9004046,
        55.5776198
      ]
    },
    {
      "id": 5737,
      "name": "Bebington",
      "names": {
        "zh": "贝宾顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3503775,
        "lng": -3.005598
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.045598,
        53.3103775,
        -2.965598,
        53.3903775
      ]
    },
    {
      "id": 5738,
      "name": "Pico Rivera",
      "names": {
        "ja": "ピコリバーラ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.987396,
        "lng": -118.092774
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.122811,
        33.950362,
        -118.040716,
        34.029302
      ]
    },
    {
      "id": 5739,
      "name": "Montebello",
      "names": {
        "zh": "蒙特韦约"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0159398,
        "lng": -118.111975
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1441509,
        33.9744777,
        -118.073043,
        34.0461161
      ]
    },
    {
      "id": 5740,
      "name": "Bamyan",
      "names": {
        "zh": "巴米扬"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.8223804,
        "lng": 67.8368338
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        67.6768338,
        34.6623804,
        67.9968338,
        34.9823804
      ]
    },
    {
      "id": 5741,
      "name": "Ruhengeri",
      "names": {
        "zh": "鲁亨盖里"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.49776895,
        "lng": 29.632260159922858
      },
      "country": "Rwanda",
      "importance": 3,
      "bbox": [
        29.627276,
        -1.5028005,
        29.6366502,
        -1.4925301
      ]
    },
    {
      "id": 5742,
      "name": "Macclesfield",
      "names": {
        "zh": "麦克尔斯菲尔德",
        "th": "แฟรงก์ส"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.2606635,
        "lng": -2.1255158
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.1823849,
        53.2367373,
        -2.0696729,
        53.285859
      ]
    },
    {
      "id": 5743,
      "name": "Yala",
      "names": {
        "zh": "亚拉",
        "th": "ยะลา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.5567245,
        "lng": 101.2902517
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.1302517,
        6.3967245,
        101.4502517,
        6.7167245
      ]
    },
    {
      "id": 5744,
      "name": "Rovaniemi",
      "names": {
        "zh": "罗瓦涅米"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 66.5025657,
        "lng": 25.73122
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        24.73658,
        66.1555137,
        27.3266437,
        67.1794991
      ]
    },
    {
      "id": 5745,
      "name": "Giurgiu",
      "names": {
        "zh": "久尔久"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 43.8959058,
        "lng": 25.9658111
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.8811189,
        43.8593409,
        26.0328176,
        43.9360638
      ]
    },
    {
      "id": 5746,
      "name": "Puerto Limon",
      "names": {
        "zh": "波多黎隆",
        "de": "Puerto-Limon",
        "ja": "プエルトリモン",
        "th": "เปอร์โตลิมอน"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 7.4879185,
        "lng": -73.4998818
      },
      "country": "Costa Rica",
      "importance": 1,
      "bbox": [
        -73.5198818,
        7.4679185,
        -73.4798818,
        7.5079185
      ]
    },
    {
      "id": 5747,
      "name": "Chambery",
      "names": {
        "es": "Chambéry",
        "fr": "Chambéry",
        "zh": "尚贝里",
        "id": "Chambéry"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5662672,
        "lng": 5.9203636
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.8712335,
        45.5480625,
        5.9414391,
        45.616434
      ]
    },
    {
      "id": 5748,
      "name": "Fontenay-sous-Bois",
      "names": {
        "zh": "丰特内-苏布瓦",
        "ja": "fontenay-スー・ボワ",
        "th": "fontenay-Sous-บัว"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.850624,
        "lng": 2.474347
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.447103,
        48.83907,
        2.499718,
        48.86141
      ]
    },
    {
      "id": 5749,
      "name": "North Bergen",
      "names": {
        "es": "Bergen del Norte",
        "fr": "Nord bergen",
        "zh": "北卑尔根",
        "de": "Nordbergen",
        "id": "Bergen Utara",
        "ja": "ノースベルゲン",
        "pt": "Norte Bergen"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8042674,
        "lng": -74.012084
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.0568815,
        40.75737,
        -73.9848673,
        40.823569
      ]
    },
    {
      "id": 5750,
      "name": "Seinajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.792281,
        "lng": 22.8438297
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        22.8388297,
        62.787281,
        22.8488297,
        62.797281
      ]
    },
    {
      "id": 5752,
      "name": "Suileng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.25,
        "lng": 127.08333
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        127.06333,
        47.23,
        127.10333,
        47.27
      ]
    },
    {
      "id": 5753,
      "name": "The Hammocks",
      "names": {
        "es": "Las hamacas",
        "fr": "Les hamacs",
        "zh": "吊床",
        "de": "Die Hängematten",
        "it": "Le amache",
        "id": "Tempat tidur gantung",
        "ja": "ハンモック",
        "th": "เปลญวน",
        "pt": "As redes"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.6718443,
        "lng": -80.44780132742443
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.479546,
        25.6537064,
        -80.4157669,
        25.688651
      ]
    },
    {
      "id": 5754,
      "name": "National City",
      "names": {
        "es": "Ciudad nacional",
        "fr": "Ville nationale",
        "zh": "国家城市",
        "de": "Nationalstadt",
        "it": "Città nazionale",
        "id": "Kota nasional",
        "ja": "国立都市",
        "th": "เมืองแห่งชาติ",
        "pt": "Cidade nacional"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6781085,
        "lng": -117.0991967
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.1354414,
        32.6031038,
        -117.0492996,
        32.6933017
      ]
    },
    {
      "id": 5755,
      "name": "Koja",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": -1.6553803,
        "lng": 119.981349
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        119.961349,
        -1.6753803,
        120.001349,
        -1.6353803
      ]
    },
    {
      "id": 5756,
      "name": "Nonoichi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5197206,
        "lng": 136.6098107
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.5803932,
        36.495496,
        136.630826,
        36.554984
      ]
    },
    {
      "id": 5757,
      "name": "Coconut Creek",
      "names": {
        "fr": "Creek de coco",
        "zh": "椰子溪",
        "de": "Kokosnuss-Creek",
        "ja": "ココナッツクリーク",
        "pt": "Coco riacho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.289139,
        "lng": -80.185671
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.20297,
        26.214281,
        -80.166502,
        26.32784
      ]
    },
    {
      "id": 5758,
      "name": "Coyhaique",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -45.5711804,
        "lng": -72.0684863
      },
      "country": "Chile",
      "importance": 1,
      "bbox": [
        -72.9315093,
        -46.1397235,
        -71.3198465,
        -44.8307134
      ]
    },
    {
      "id": 5759,
      "name": "Gaalkacyo",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 6.769726,
        "lng": 47.430826
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        47.270826,
        6.609726,
        47.590826,
        6.929726
      ]
    },
    {
      "id": 5760,
      "name": "Ben Tre",
      "names": {
        "zh": "本特雷",
        "ja": "ベントレ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.23333,
        "lng": 106.38333
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.36333,
        10.21333,
        106.40333,
        10.25333
      ]
    },
    {
      "id": 5761,
      "name": "Hunedoara",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 45.761217,
        "lng": 22.904879
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        22.874092,
        45.728715,
        22.929097,
        45.796904
      ]
    },
    {
      "id": 5762,
      "name": "Limerick",
      "names": {
        "zh": "利默里克",
        "ja": "リメリック",
        "th": "โคลง"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.661252,
        "lng": -8.6301239
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -8.8070765,
        52.5721036,
        -8.4425444,
        52.757379
      ]
    },
    {
      "id": 5764,
      "name": "Sidi Bennour",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.6507792,
        "lng": -8.4242087
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -8.4523899,
        32.634607,
        -8.4060879,
        32.6753096
      ]
    },
    {
      "id": 5765,
      "name": "Longxing",
      "names": {
        "zh": "龙兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6250478,
        "lng": 111.2209922
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.0295868,
        35.4545364,
        111.3417348,
        35.8196869
      ]
    },
    {
      "id": 5766,
      "name": "Larnaca",
      "names": {
        "zh": "拉纳卡",
        "ja": "ラルナカ",
        "th": "ลาร์นาคา"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 34.91644,
        "lng": 33.626736
      },
      "country": "Cyprus",
      "importance": 1,
      "bbox": [
        33.579093,
        34.85596,
        33.645488,
        34.944948
      ]
    },
    {
      "id": 5767,
      "name": "Rafael Calzada",
      "names": {
        "zh": "拉斐尔卡尔茨达"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.795233,
        "lng": -58.3650739
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.3701711,
        -34.7954838,
        -58.3639221,
        -34.7941229
      ]
    },
    {
      "id": 5768,
      "name": "Royal Tunbridge Wells",
      "names": {
        "zh": "皇家Tunbridge Wells",
        "ja": "ロイヤルタンブリッジウェルズ",
        "th": "รอยัลทูนบริดจ์เวลส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.1371483,
        "lng": 0.2673446
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.2273446,
        51.0971483,
        0.3073446,
        51.1771483
      ]
    },
    {
      "id": 5769,
      "name": "Trang",
      "names": {
        "zh": "芽庄",
        "ja": "トラン",
        "th": "ตรัง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.5557778,
        "lng": 99.6076502
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.4476502,
        7.3957778,
        99.7676502,
        7.7157778
      ]
    },
    {
      "id": 5770,
      "name": "Ban Bang Kaeo",
      "names": {
        "es": "BANG BAG KAEO",
        "zh": "禁止砰砰声",
        "it": "Bang Bang Kaeo",
        "th": "บ้านบางแก้ว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6171833,
        "lng": 100.6699079
      },
      "country": "Thailand",
      "importance": 3,
      "bbox": [
        100.6499079,
        13.5971833,
        100.6899079,
        13.6371833
      ]
    },
    {
      "id": 5771,
      "name": "Takaishi",
      "names": {
        "zh": "高石"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5216444,
        "lng": 135.4330348
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.4329848,
        34.5215944,
        135.4330848,
        34.5216944
      ]
    },
    {
      "id": 5772,
      "name": "Oyem",
      "names": {
        "zh": "奥耶姆"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": 1.5997904,
        "lng": 11.5751003
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        11.4151003,
        1.4397904,
        11.7351003,
        1.7597904
      ]
    },
    {
      "id": 5773,
      "name": "Lokoja",
      "names": {
        "zh": "洛科贾"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.802355,
        "lng": 6.7430327
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.1131248,
        7.7302985,
        6.7921481,
        8.7387915
      ]
    },
    {
      "id": 5774,
      "name": "Juigalpa",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.0753073,
        "lng": -85.3985119
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -85.4135119,
        12.0603073,
        -85.3835119,
        12.0903073
      ]
    },
    {
      "id": 5775,
      "name": "Vaxjo",
      "names": {
        "es": "Växjö",
        "fr": "Växjö",
        "zh": "韦克舍",
        "de": "Växjö",
        "pt": "Växjö"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.8787183,
        "lng": 14.8094385
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        14.6494385,
        56.7187183,
        14.9694385,
        57.0387183
      ]
    },
    {
      "id": 5776,
      "name": "Fountainebleau",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 25.771613,
        "lng": -80.345551
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.375873,
        25.761549,
        -80.320358,
        25.781582
      ]
    },
    {
      "id": 5778,
      "name": "La Habra",
      "names": {
        "pt": "Labra"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.926283,
        "lng": -117.950761
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.976692,
        33.906225,
        -117.91892,
        33.946087
      ]
    },
    {
      "id": 5779,
      "name": "Malden",
      "names": {
        "zh": "马尔登"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4268484,
        "lng": -71.0683741
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.0894461,
        42.4125463,
        -71.0203778,
        42.444709
      ]
    },
    {
      "id": 5781,
      "name": "Swidnica",
      "names": {
        "ja": "シュフィドニツァ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8424835,
        "lng": 16.4870549
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.4553697,
        50.8080233,
        16.5296756,
        50.8732111
      ]
    },
    {
      "id": 5782,
      "name": "South Whittier",
      "names": {
        "es": "Whittier del sur",
        "zh": "南惠蒂尔",
        "de": "Südwhittier",
        "id": "Selatan whittier",
        "ja": "サウスウィッティー",
        "pt": "Whittier sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9366769,
        "lng": -118.028805
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.048805,
        33.9166769,
        -118.008805,
        33.9566769
      ]
    },
    {
      "id": 5783,
      "name": "Tezonapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.606685,
        "lng": -96.687327
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.695169,
        18.593648,
        -96.679189,
        18.618276
      ]
    },
    {
      "id": 5784,
      "name": "Lorient",
      "names": {
        "zh": "洛里昂",
        "ja": "ロリアン",
        "th": "ลอเรียง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.7477336,
        "lng": -3.3660907
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -3.4104322,
        47.7241953,
        -3.3479771,
        47.774515
      ]
    },
    {
      "id": 5785,
      "name": "Kaposvar",
      "names": {
        "fr": "Kaposvár"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3564692,
        "lng": 17.7886886
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        17.741783,
        46.2905448,
        17.8912373,
        46.4507258
      ]
    },
    {
      "id": 5786,
      "name": "Zushi",
      "names": {
        "zh": "逗子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2976319,
        "lng": 139.5788659
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.5738659,
        35.2926319,
        139.5838659,
        35.3026319
      ]
    },
    {
      "id": 5787,
      "name": "Bungoma",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.5666,
        "lng": 34.5666
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.56655,
        0.56655,
        34.56665,
        0.56665
      ]
    },
    {
      "id": 5788,
      "name": "Pinotepa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.337858,
        "lng": -97.626534
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.674978,
        17.274594,
        -97.60581,
        17.394281
      ]
    },
    {
      "id": 5789,
      "name": "Sidi Yahya Zaer",
      "names": {
        "zh": "西迪yahya zaer"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.825442100000004,
        "lng": -6.903437762309454
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.9123391,
        33.8204364,
        -6.8965924,
        33.830604
      ]
    },
    {
      "id": 5790,
      "name": "Evry",
      "names": {
        "zh": "埃夫里"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6241665,
        "lng": 2.4289667
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.4130316,
        48.6109217,
        2.4705092,
        48.6485333
      ]
    },
    {
      "id": 5791,
      "name": "Midalt",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.68,
        "lng": -4.74
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5792,
      "name": "West Allis",
      "names": {
        "zh": "西Allis",
        "id": "Allis Barat",
        "ja": "西アリス",
        "th": "เวสต์อัลลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.006595,
        "lng": -88.030236
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.06862,
        42.977233,
        -87.975703,
        43.031014
      ]
    },
    {
      "id": 5793,
      "name": "Itanagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.0979659,
        "lng": 93.6237291
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        93.4637291,
        26.9379659,
        93.7837291,
        27.2579659
      ]
    },
    {
      "id": 5794,
      "name": "Uzice",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.85644,
        "lng": 19.844372
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.821737,
        43.834861,
        19.861737,
        43.874861
      ]
    },
    {
      "id": 5795,
      "name": "Samut Prakan",
      "names": {
        "zh": "萨默库尔帕金",
        "ja": "サムットプラカン",
        "th": "สมุทรปราการ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5949486,
        "lng": 100.5966707
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.4366707,
        13.4349486,
        100.7566707,
        13.7549486
      ]
    },
    {
      "id": 5796,
      "name": "Taylorsville",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.6676347,
        "lng": -111.9388027
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.9915465,
        40.629851,
        -111.907828,
        40.686329
      ]
    },
    {
      "id": 5797,
      "name": "Antigua Guatemala",
      "names": {
        "zh": "安提瓜危地马拉",
        "ja": "アンティグアグアテマラ",
        "pt": "Guatemala Antigua"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5567814,
        "lng": -90.7337346
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.8937346,
        14.3967814,
        -90.5737346,
        14.7167814
      ]
    },
    {
      "id": 5798,
      "name": "Azrou",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.436117,
        "lng": -5.221913
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.2413328,
        33.4232762,
        -5.2039965,
        33.459388
      ]
    },
    {
      "id": 5799,
      "name": "Klimovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.365749,
        "lng": 37.539568
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.50536,
        55.350498,
        37.567612,
        55.381474
      ]
    },
    {
      "id": 5800,
      "name": "Monterey Park",
      "names": {
        "fr": "Parc de Monterey",
        "zh": "蒙特雷公园",
        "id": "Taman Monterey",
        "ja": "モントレー公園",
        "th": "มอนเทอเรย์พาร์ค",
        "pt": "Parque monterey"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.051522,
        "lng": -118.129807
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1695489,
        34.0271194,
        -118.0929539,
        34.0716868
      ]
    },
    {
      "id": 5801,
      "name": "Samannud",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.96033,
        "lng": 31.24332
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        31.22332,
        30.94033,
        31.26332,
        30.98033
      ]
    },
    {
      "id": 5802,
      "name": "Hod HaSharon",
      "names": {
        "it": "HADHARON HOD",
        "th": "ฮอดฮารอน"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.14994955,
        "lng": 34.88512467444009
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.8711452,
        32.1286479,
        34.9257269,
        32.171671
      ]
    },
    {
      "id": 5803,
      "name": "Rodos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 36.4437233,
        "lng": 28.227369
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        28.067369,
        36.2837233,
        28.387369,
        36.6037233
      ]
    },
    {
      "id": 5805,
      "name": "Tacuarembo",
      "names": {
        "es": "Tacuarembó"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -31.7110183,
        "lng": -55.9788762
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -56.0320771,
        -31.7510536,
        -55.9323324,
        -31.6822809
      ]
    },
    {
      "id": 5806,
      "name": "Folkestone",
      "names": {
        "zh": "福克斯通"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.0794635,
        "lng": 1.1767401
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        1.1766901,
        51.0794135,
        1.1767901,
        51.0795135
      ]
    },
    {
      "id": 5807,
      "name": "Merida",
      "names": {
        "es": "Mérida",
        "zh": "梅里达",
        "ja": "メリダ",
        "th": "เมริดา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9174665,
        "lng": -6.3443977
      },
      "country": "Spain",
      "importance": 1,
      "bbox": [
        -6.3959958,
        38.8934849,
        -6.3211801,
        38.9403228
      ]
    },
    {
      "id": 5808,
      "name": "Al Hoceima",
      "names": {
        "it": "Al hocceima",
        "ja": "アルホセイマ",
        "th": "อัลโฮเซิม่า",
        "pt": "Al hocceima"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.245114,
        "lng": -3.930186
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -3.9648201,
        35.2107207,
        -3.914212,
        35.2619673
      ]
    },
    {
      "id": 5809,
      "name": "Hamura",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.764833,
        "lng": 139.307862
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.292129,
        35.747124,
        139.343076,
        35.7813585
      ]
    },
    {
      "id": 5810,
      "name": "Gardena",
      "names": {
        "zh": "加迪纳"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8885028,
        "lng": -118.299243
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.299293,
        33.8884528,
        -118.299193,
        33.8885528
      ]
    },
    {
      "id": 5811,
      "name": "Zumpango",
      "names": {
        "zh": "孙潘戈"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7972881,
        "lng": -99.1007274
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1407274,
        19.7572881,
        -99.0607274,
        19.8372881
      ]
    },
    {
      "id": 5812,
      "name": "Cupertino",
      "names": {
        "zh": "诺",
        "ja": "クパチーノ",
        "th": "คูเปอร์ติโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.313867,
        "lng": -122.049804
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.092176,
        37.27846,
        -121.995833,
        37.34052
      ]
    },
    {
      "id": 5813,
      "name": "Royal Leamington Spa",
      "names": {
        "zh": "皇家leamington spa",
        "ja": "ロイヤルリーディントンスパ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.2913394,
        "lng": -1.536404
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.5614571,
        52.271929,
        -1.4907626,
        52.3142858
      ]
    },
    {
      "id": 5814,
      "name": "La Mesa",
      "names": {
        "zh": "拉梅萨"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7678288,
        "lng": -117.0230839
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.0535956,
        32.7431597,
        -116.9818566,
        32.7960856
      ]
    },
    {
      "id": 5815,
      "name": "Dzerzhinskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.62945,
        "lng": 37.85654
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.83654,
        55.60945,
        37.87654,
        55.64945
      ]
    },
    {
      "id": 5816,
      "name": "Artemisa",
      "names": {
        "zh": "阿特米萨"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8272763,
        "lng": -82.92592448088564
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -83.4491558,
        22.3454716,
        -82.3952743,
        23.3079812
      ]
    },
    {
      "id": 5817,
      "name": "Crosby",
      "names": {
        "zh": "克罗斯比",
        "ja": "クロスビー",
        "th": "แพนด้า"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4844386,
        "lng": -3.0291758
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0691758,
        53.4444386,
        -2.9891758,
        53.5244386
      ]
    },
    {
      "id": 5818,
      "name": "Brookline",
      "names": {
        "zh": "布鲁克林"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3329021,
        "lng": -71.1187421
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.1789351,
        42.2945881,
        -71.1055164,
        42.3517551
      ]
    },
    {
      "id": 5819,
      "name": "Yevlax",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.6111615,
        "lng": 47.1479948
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.1074643,
        40.5853025,
        47.1840691,
        40.6440474
      ]
    },
    {
      "id": 5820,
      "name": "Viedma",
      "names": {
        "zh": "别德马"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.8084274,
        "lng": -62.994722
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -64.0093504,
        -41.1617964,
        -62.7895558,
        -40.7663976
      ]
    },
    {
      "id": 5821,
      "name": "Mundka",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6803941,
        "lng": 77.0304255
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.0235972,
        28.6760871,
        77.0381897,
        28.6824714
      ]
    },
    {
      "id": 5822,
      "name": "Cerro de Pasco",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -10.6835926,
        "lng": -76.2561123
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -76.4161123,
        -10.8435926,
        -76.0961123,
        -10.5235926
      ]
    },
    {
      "id": 5823,
      "name": "Meaux",
      "names": {
        "zh": "莫"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9582708,
        "lng": 2.8773541
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.8627113,
        48.9355398,
        2.9441071,
        48.9789845
      ]
    },
    {
      "id": 5825,
      "name": "Kabinda",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -6.1368376,
        "lng": 24.4862256
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        24.4462256,
        -6.1768376,
        24.5262256,
        -6.0968376
      ]
    },
    {
      "id": 5826,
      "name": "Clamart",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8003835,
        "lng": 2.2630238
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.2235812,
        48.7744568,
        2.2799362,
        48.817031
      ]
    },
    {
      "id": 5827,
      "name": "Stratford",
      "names": {
        "zh": "斯特拉特福",
        "ja": "ストラットフォード",
        "th": "สแตรทฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.541289,
        "lng": -0.0035472
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.0085472,
        51.536289,
        0.0014528,
        51.546289
      ]
    },
    {
      "id": 5828,
      "name": "Huamantla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.313427,
        "lng": -97.9230236
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.9630236,
        19.273427,
        -97.8830236,
        19.353427
      ]
    },
    {
      "id": 5829,
      "name": "Krusevac",
      "names": {
        "zh": "克鲁舍瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.58264,
        "lng": 21.3264811
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.2958685,
        43.5508246,
        21.3535082,
        43.5965621
      ]
    },
    {
      "id": 5830,
      "name": "Margate",
      "names": {
        "zh": "马盖特",
        "ja": "マーゲート"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2445263,
        "lng": -80.206436
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2340259,
        26.2107408,
        -80.1905378,
        26.2741585
      ]
    },
    {
      "id": 5831,
      "name": "Mineiros",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.5653879,
        "lng": -52.5536721
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.2485624,
        -18.646,
        -52.098,
        -16.765994
      ]
    },
    {
      "id": 5832,
      "name": "Qiryat Ata",
      "names": {
        "ja": "カイヤットATA"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.8115853,
        "lng": 35.1163747
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        35.0560233,
        32.7739914,
        35.156007,
        32.829047
      ]
    },
    {
      "id": 5833,
      "name": "Aversa",
      "names": {
        "zh": "阿韦尔萨"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.973212,
        "lng": 14.204928
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.1824115,
        40.9492002,
        14.2268775,
        40.9921094
      ]
    },
    {
      "id": 5834,
      "name": "Carson City",
      "names": {
        "es": "Carrera de Carson",
        "zh": "卡森市",
        "ja": "カーソンシティ",
        "th": "เมืองคาร์สัน",
        "pt": "Cidade de carson"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1698022,
        "lng": -119.7575628
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -120.004493,
        39.084796,
        -119.548308,
        39.250871
      ]
    },
    {
      "id": 5835,
      "name": "Tenkodogo",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 11.7828079,
        "lng": -0.3651416
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -0.4064887,
        11.7464829,
        -0.3426165,
        11.8193599
      ]
    },
    {
      "id": 5836,
      "name": "Dosso",
      "names": {
        "zh": "多索"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.0496369,
        "lng": 3.1944647
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        3.0344647,
        12.8896369,
        3.3544647,
        13.2096369
      ]
    },
    {
      "id": 5837,
      "name": "Naval",
      "names": {
        "fr": "Navale",
        "zh": "海军",
        "it": "Navale",
        "ja": "海軍",
        "th": "กองทัพเรือ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.562023,
        "lng": 124.39888
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.37888,
        11.542023,
        124.41888,
        11.582023
      ]
    },
    {
      "id": 5838,
      "name": "Baruipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3606792,
        "lng": 88.4322502
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.424564,
        22.3380184,
        88.4543554,
        22.3763919
      ]
    },
    {
      "id": 5839,
      "name": "Mandapeta",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8634704,
        "lng": 81.9300945
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.8900945,
        16.8234704,
        81.9700945,
        16.9034704
      ]
    },
    {
      "id": 5840,
      "name": "Barlad",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 46.2150343,
        "lng": 27.6732486
      },
      "country": "Romania",
      "importance": 3,
      "bbox": [
        27.6682486,
        46.2100343,
        27.6782486,
        46.2200343
      ]
    },
    {
      "id": 5841,
      "name": "Chelles",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8783804,
        "lng": 2.590549
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.559204,
        48.8611774,
        2.6315231,
        48.9077267
      ]
    },
    {
      "id": 5842,
      "name": "Port Coquitlam",
      "names": {
        "es": "Puerto coquitlam",
        "zh": "港口崇拜",
        "de": "Port-Zusammenarbeit",
        "it": "Porto Coquitlam",
        "ja": "ポートコキトラム",
        "th": "พอร์ตโคควิทแลม",
        "pt": "Coquitlam"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2621643,
        "lng": -122.780542
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.80737,
        49.213467,
        -122.6984785,
        49.2892694
      ]
    },
    {
      "id": 5843,
      "name": "Veszprem",
      "names": {
        "fr": "Veszprém",
        "zh": "维斯普林",
        "de": "Veszprém"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0933824,
        "lng": 17.9080406
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        17.8211069,
        47.0462952,
        17.994351,
        47.2057893
      ]
    },
    {
      "id": 5844,
      "name": "Rosh Ha`Ayin",
      "names": {
        "it": "Rosh ha`in"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0837846,
        "lng": 34.952233
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.952183,
        32.0837346,
        34.952283,
        32.0838346
      ]
    },
    {
      "id": 5845,
      "name": "Middletown",
      "names": {
        "zh": "米德尔敦",
        "ja": "ミドルタウン",
        "th": "มิดเดิลทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.45011,
        "lng": -74.4164807
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.452882,
        41.417722,
        -74.393057,
        41.474393
      ]
    },
    {
      "id": 5846,
      "name": "Kitgum",
      "names": {
        "zh": "基特古姆"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.296481,
        "lng": 32.881839
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.841839,
        3.256481,
        32.921839,
        3.336481
      ]
    },
    {
      "id": 5847,
      "name": "Bondoukou",
      "names": {
        "zh": "邦杜库"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 8.0397992,
        "lng": -2.7984258
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -2.8384258,
        7.9997992,
        -2.7584258,
        8.0797992
      ]
    },
    {
      "id": 5848,
      "name": "Sartrouville",
      "names": {
        "zh": "萨特鲁维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9372147,
        "lng": 2.1581498
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.1580998,
        48.9371647,
        2.1581998,
        48.9372647
      ]
    },
    {
      "id": 5849,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.2807069,
        "lng": -61.4645896
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.5018095,
        10.245889,
        -61.4328029,
        10.3146523
      ]
    },
    {
      "id": 5850,
      "name": "Novohrad-Volynskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.58853,
        "lng": 27.625041
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.57598,
        50.542984,
        27.667883,
        50.624016
      ]
    },
    {
      "id": 5852,
      "name": "Bekescsaba",
      "names": {
        "de": "Békéscsaba",
        "it": "Békéscsaba"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.68042,
        "lng": 21.082999
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        21.043741,
        46.645443,
        21.137019,
        46.700106
      ]
    },
    {
      "id": 5853,
      "name": "Union",
      "names": {
        "es": "Unión",
        "zh": "联盟",
        "it": "Unione",
        "id": "Persatuan",
        "ja": "連合",
        "th": "สหภาพ",
        "pt": "União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9745493,
        "lng": -77.0785945
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.3641712,
        40.807468,
        -76.7992827,
        41.1488012
      ]
    },
    {
      "id": 5854,
      "name": "Sevran",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.937634,
        "lng": 2.5298164
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.5052065,
        48.9239566,
        2.5569119,
        48.9516446
      ]
    },
    {
      "id": 5855,
      "name": "Chania",
      "names": {
        "fr": "La Canée",
        "zh": "干尼亚",
        "ja": "ハニア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 35.51192,
        "lng": 24.01714
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.91714,
        35.41192,
        24.11714,
        35.61192
      ]
    },
    {
      "id": 5856,
      "name": "White Plains",
      "names": {
        "es": "Llanuras blancas",
        "fr": "Plaines blanches",
        "zh": "白皮",
        "de": "Weiße Ebenen",
        "it": "Pianure bianche",
        "id": "Dataran putih",
        "ja": "白い平野",
        "th": "ที่ราบสีขาว",
        "pt": "Planícies brancas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0339862,
        "lng": -73.7629097
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.789955,
        40.982444,
        -73.719524,
        41.07003
      ]
    },
    {
      "id": 5857,
      "name": "Huehuetenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3184301,
        "lng": -91.4723295
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.6323295,
        15.1584301,
        -91.3123295,
        15.4784301
      ]
    },
    {
      "id": 5858,
      "name": "Shijonawate",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7317472,
        "lng": 135.6394116
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6393616,
        34.7316972,
        135.6394616,
        34.7317972
      ]
    },
    {
      "id": 5859,
      "name": "Jefferson City",
      "names": {
        "es": "Ciudad de Jefferson",
        "zh": "杰斐逊城",
        "id": "Kota Jefferson",
        "ja": "ジェファーソンシティ",
        "th": "เจฟเฟอร์สันซิตี้"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.577359,
        "lng": -92.1724265
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -92.287547,
        38.524693,
        -92.037263,
        38.6176948
      ]
    },
    {
      "id": 5860,
      "name": "Legionowo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.404094,
        "lng": 20.916252
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.898604,
        52.384834,
        20.980025,
        52.424962
      ]
    },
    {
      "id": 5861,
      "name": "Bhimunipatnam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.885255,
        "lng": 83.448229
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.435067,
        17.883754,
        83.455685,
        17.897319
      ]
    },
    {
      "id": 5862,
      "name": "Arcadia",
      "names": {
        "zh": "阿卡迪亚",
        "ja": "アルカディア",
        "th": "อาร์เคเดีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1362075,
        "lng": -118.0401497
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.069388,
        34.087269,
        -117.99145,
        34.1794586
      ]
    },
    {
      "id": 5863,
      "name": "Hilden",
      "names": {
        "zh": "希尔登"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1674417,
        "lng": 6.9307271
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.8907598,
        51.1405004,
        6.9863518,
        51.1979687
      ]
    },
    {
      "id": 5864,
      "name": "Slavonski Brod",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.1635443,
        "lng": 18.0111849
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        18.0061849,
        45.1585443,
        18.0161849,
        45.1685443
      ]
    },
    {
      "id": 5865,
      "name": "Magong",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 23.5663766,
        "lng": 119.5784885
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        119.5088144,
        23.4830136,
        119.635047,
        23.5944037
      ]
    },
    {
      "id": 5866,
      "name": "Nuevo Casas Grandes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.4154532,
        "lng": -107.9058804
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.0658804,
        30.2554532,
        -107.7458804,
        30.5754532
      ]
    },
    {
      "id": 5867,
      "name": "Acatzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.980018,
        "lng": -97.78416
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.807135,
        18.965733,
        -97.763456,
        18.999889
      ]
    },
    {
      "id": 5868,
      "name": "Umm el Fahm",
      "names": {
        "zh": "嗯el fahm"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.5158385,
        "lng": 35.152491
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        35.1084261,
        32.5010527,
        35.1940476,
        32.5638157
      ]
    },
    {
      "id": 5869,
      "name": "Kidderminster",
      "names": {
        "zh": "基德明斯特",
        "th": "คิดเดอร์มินส์เตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.3880787,
        "lng": -2.2478582
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.2844782,
        52.3571733,
        -2.2129877,
        52.404895
      ]
    },
    {
      "id": 5870,
      "name": "Al Mafraq",
      "names": {
        "ja": "アルマフラック"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.3423619,
        "lng": 36.2075282
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        36.1657881,
        32.3015755,
        36.2545749,
        32.3805749
      ]
    },
    {
      "id": 5871,
      "name": "Boac",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4479146,
        "lng": 121.8400274
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.6728479,
        13.2992276,
        122.0032241,
        13.5450356
      ]
    },
    {
      "id": 5872,
      "name": "Altrincham",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3839662,
        "lng": -2.3525463
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.3925463,
        53.3439662,
        -2.3125463,
        53.4239662
      ]
    },
    {
      "id": 5873,
      "name": "Huntington Park",
      "names": {
        "fr": "Parc de Huntington",
        "zh": "亨廷顿公园",
        "id": "Taman Huntington",
        "ja": "ハンチントン公園",
        "th": "ฮันติงตันพาร์ค",
        "pt": "Parque Huntington"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9805731,
        "lng": -118.2252073
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.239021,
        33.9615964,
        -118.189009,
        33.9962649
      ]
    },
    {
      "id": 5874,
      "name": "Weymouth",
      "names": {
        "zh": "韦茅斯",
        "ja": "ウェイマス"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.6159266,
        "lng": -2.4549096
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4599096,
        50.6109266,
        -2.4499096,
        50.6209266
      ]
    },
    {
      "id": 5875,
      "name": "Mafeteng",
      "names": {
        "zh": "马费滕"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -29.8224468,
        "lng": 27.2388281
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        27.1988281,
        -29.8624468,
        27.2788281,
        -29.7824468
      ]
    },
    {
      "id": 5876,
      "name": "Chinhoyi",
      "names": {},
      "countryCode": "ZW",
      "latLng": {
        "lat": -17.3615308,
        "lng": 30.1929353
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        30.1529353,
        -17.4015308,
        30.2329353,
        -17.3215308
      ]
    },
    {
      "id": 5877,
      "name": "Medford",
      "names": {
        "zh": "梅德福",
        "ja": "メドフォード",
        "th": "เมดฟอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4184296,
        "lng": -71.1061639
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.1498864,
        42.3954973,
        -71.0712966,
        42.4534451
      ]
    },
    {
      "id": 5878,
      "name": "Pithapuram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.1108821,
        "lng": 82.2570259
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.2520259,
        17.1058821,
        82.2620259,
        17.1158821
      ]
    },
    {
      "id": 5879,
      "name": "Melo",
      "names": {
        "zh": "梅洛",
        "ja": "メロ"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -32.3680849,
        "lng": -54.1697146
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -54.201019,
        -32.3935153,
        -54.1359772,
        -32.3415815
      ]
    },
    {
      "id": 5880,
      "name": "Barri",
      "names": {
        "zh": "巴里"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.39979,
        "lng": -3.2838
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.318509,
        51.38477,
        -3.230936,
        51.431377
      ]
    },
    {
      "id": 5881,
      "name": "Belize City",
      "names": {
        "es": "Ciudad de Belice",
        "zh": "伯利兹城市",
        "de": "Belize-Stadt",
        "id": "Kota",
        "ja": "ベリーズ市",
        "th": "เมืองเบลีซ",
        "pt": "Cidade de beleza"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 17.498711,
        "lng": -88.188374
      },
      "country": "Belize",
      "importance": 1,
      "bbox": [
        -88.2083744728,
        17.4787109568,
        -88.1683744728,
        17.5187109568
      ]
    },
    {
      "id": 5883,
      "name": "Vratsa",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2028209,
        "lng": 23.548027
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        23.388027,
        43.0428209,
        23.708027,
        43.3628209
      ]
    },
    {
      "id": 5884,
      "name": "Maun",
      "names": {
        "zh": "马翁",
        "ja": "マウン"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -19.9860951,
        "lng": 23.4224352
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        23.2624352,
        -20.1460951,
        23.5824352,
        -19.8260951
      ]
    },
    {
      "id": 5885,
      "name": "Bac Giang",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 21.27307,
        "lng": 106.1946
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.1746,
        21.25307,
        106.2146,
        21.29307
      ]
    },
    {
      "id": 5886,
      "name": "Biel/Bienne",
      "names": {
        "es": "Biel / bienne",
        "fr": "Biel / Bienne",
        "zh": "比尔/比安",
        "de": "Biel / bienne",
        "it": "Biel / Bienne",
        "id": "Biel / bienne",
        "ja": "ビール/ bienne",
        "th": "บีล / bienne",
        "pt": "Biel / bienne"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1402077,
        "lng": 7.2439029
      },
      "country": "Switzerland",
      "importance": 3,
      "bbox": [
        7.2011031,
        47.1221797,
        7.3127106,
        47.1770418
      ]
    },
    {
      "id": 5887,
      "name": "Chiapa de Corzo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7075202,
        "lng": -93.0115045
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.0515045,
        16.6675202,
        -92.9715045,
        16.7475202
      ]
    },
    {
      "id": 5888,
      "name": "Duzce",
      "names": {
        "zh": "迪兹杰"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8391531,
        "lng": 31.1595361
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        31.0059717,
        40.6229263,
        31.4070392,
        40.9964724
      ]
    },
    {
      "id": 5889,
      "name": "Ushuaia",
      "names": {
        "zh": "乌斯怀亚",
        "ja": "ウシュアイア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -54.8069332,
        "lng": -68.3073246
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -68.4596663,
        -54.8587219,
        -68.1951857,
        -54.7503269
      ]
    },
    {
      "id": 5891,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.1249273,
        "lng": -102.77456283182991
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.235104,
        25.6006109,
        -101.8689873,
        26.6484006
      ]
    },
    {
      "id": 5892,
      "name": "Zalau",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 47.190732,
        "lng": 23.057164
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        22.998847,
        47.154314,
        23.11936,
        47.230921
      ]
    },
    {
      "id": 5893,
      "name": "Mugla",
      "names": {
        "es": "De mugla",
        "zh": "穆拉",
        "ja": "ムーラ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2151202,
        "lng": 28.3636912
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        28.2036912,
        37.0551202,
        28.5236912,
        37.3751202
      ]
    },
    {
      "id": 5894,
      "name": "Sfantu-Gheorghe",
      "names": {
        "zh": "sfantu-格奥尔基"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.8659935,
        "lng": 25.7865367
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.6748388,
        45.797227,
        25.8586112,
        45.9031095
      ]
    },
    {
      "id": 5896,
      "name": "Evora",
      "names": {
        "zh": "埃武拉",
        "ja": "エヴォラ",
        "th": "เอโวรา",
        "pt": "Évora"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.5721347,
        "lng": -7.908222415737051
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -7.9154816,
        38.566228,
        -7.9008509,
        38.5779771
      ]
    },
    {
      "id": 5897,
      "name": "Phra Nakhon Si Ayutthaya",
      "names": {
        "th": "พระนครศรีอยุธยา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3535427,
        "lng": 100.5645684
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.5430696,
        14.3320045,
        100.5885023,
        14.3782033
      ]
    },
    {
      "id": 5898,
      "name": "Zouerate",
      "names": {
        "zh": "祖埃拉特"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 22.7374428,
        "lng": -12.4728954
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -12.6328954,
        22.5774428,
        -12.3128954,
        22.8974428
      ]
    },
    {
      "id": 5899,
      "name": "Trencin",
      "names": {
        "zh": "特伦钦"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8922719,
        "lng": 18.0387465
      },
      "country": "Slovakia",
      "importance": 1,
      "bbox": [
        17.830719,
        48.73593,
        18.332517,
        49.0692751
      ]
    },
    {
      "id": 5900,
      "name": "Santa Rosa de Copan",
      "names": {
        "zh": "圣罗莎de Copan",
        "ja": "サンタロサデパン"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.7675556,
        "lng": -88.7780977
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -88.9392385,
        14.7068671,
        -88.6412419,
        14.9106565
      ]
    },
    {
      "id": 5901,
      "name": "Jelgava",
      "names": {
        "zh": "叶尔加瓦"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6514394,
        "lng": 23.7339143
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        23.6234995,
        56.5968687,
        23.7995715,
        56.6918352
      ]
    },
    {
      "id": 5902,
      "name": "Saint-Quentin",
      "names": {
        "zh": "圣 - 昆汀",
        "ja": "サン・クエンティン",
        "th": "เซนต์เควนติน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.8465253,
        "lng": 3.2876843
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.2280502,
        49.8201747,
        3.3298948,
        49.8748622
      ]
    },
    {
      "id": 5903,
      "name": "Tamiami",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 25.7557987,
        "lng": -80.40093418250305
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.4203988,
        25.7287906,
        -80.384103,
        25.783066
      ]
    },
    {
      "id": 5904,
      "name": "Vaslui",
      "names": {
        "zh": "瓦斯卢伊",
        "th": "วาสลุย"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.641804,
        "lng": 27.72833
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.707435,
        46.605508,
        27.751983,
        46.671995
      ]
    },
    {
      "id": 5905,
      "name": "Kendale Lakes",
      "names": {
        "es": "Lagos de Kendale",
        "fr": "Lacs de Kendale",
        "zh": "肯德莱湖",
        "de": "Kendale-Seen",
        "ja": "ケンダールの湖",
        "pt": "Lagos de kendale"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.707538,
        "lng": -80.407759
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.427,
        25.68816,
        -80.387,
        25.72816
      ]
    },
    {
      "id": 5906,
      "name": "Francisco I. Madero",
      "names": {
        "ja": "フランシスコI.マデロ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7733468,
        "lng": -103.2713874
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.3113874,
        25.7333468,
        -103.2313874,
        25.8133468
      ]
    },
    {
      "id": 5907,
      "name": "Castelo Branco",
      "names": {
        "ja": "カステロブランコ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.8266322,
        "lng": -7.4919318
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -7.8507551,
        39.6408465,
        -7.1791535,
        40.0836479
      ]
    },
    {
      "id": 5908,
      "name": "Rio Tinto",
      "names": {
        "zh": "里奥蒂托",
        "ja": "リオチント"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.17953765,
        "lng": -8.560247215572568
      },
      "country": "Portugal",
      "importance": 3,
      "bbox": [
        -8.5603444,
        41.1789878,
        -8.560098,
        41.1797471
      ]
    },
    {
      "id": 5910,
      "name": "Vigan",
      "names": {
        "zh": "维甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.5755487,
        "lng": 120.3872632
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.1958515,
        17.3984043,
        120.4323144,
        17.5913377
      ]
    },
    {
      "id": 5911,
      "name": "Sankt Polten",
      "names": {
        "es": "Sankt polen"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2043985,
        "lng": 15.6229118
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        15.5559037,
        48.1184796,
        15.739183,
        48.2682972
      ]
    },
    {
      "id": 5912,
      "name": "Zalaegerszeg",
      "names": {
        "zh": "佐洛埃格塞格"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8415803,
        "lng": 16.8456316
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        16.7694368,
        46.7803888,
        16.9069325,
        46.9002981
      ]
    },
    {
      "id": 5913,
      "name": "Fuchu",
      "names": {
        "zh": "府中",
        "ja": "府中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.406835,
        "lng": 132.524231
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.495528,
        34.374776,
        132.540717,
        34.421491
      ]
    },
    {
      "id": 5914,
      "name": "Massy",
      "names": {
        "zh": "马西",
        "ja": "マッシー",
        "th": "หนาแน่น"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.730158,
        "lng": 2.2766735
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.235012,
        48.7119556,
        2.3042127,
        48.7408066
      ]
    },
    {
      "id": 5915,
      "name": "Dunfermline",
      "names": {
        "zh": "邓弗姆林",
        "ja": "ダンファームリン",
        "th": "ดันเฟิร์มลิน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 56.0713724,
        "lng": -3.4616183
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.5166835,
        56.0421401,
        -3.3871773,
        56.1229173
      ]
    },
    {
      "id": 5916,
      "name": "Gallarate",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6598951,
        "lng": 8.7932013
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        8.7536764,
        45.6311911,
        8.8324362,
        45.6904347
      ]
    },
    {
      "id": 5917,
      "name": "Iba",
      "names": {
        "zh": "伊巴",
        "th": "ไอบีเอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3264614,
        "lng": 119.9801043
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        119.9401043,
        15.2864614,
        120.0201043,
        15.3664614
      ]
    },
    {
      "id": 5918,
      "name": "Halmstad",
      "names": {
        "zh": "哈尔姆斯塔德"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.6739826,
        "lng": 12.8574827
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        12.6974827,
        56.5139826,
        13.0174827,
        56.8339826
      ]
    },
    {
      "id": 5919,
      "name": "Ohrid",
      "names": {
        "zh": "奥赫里德",
        "ja": "オフリド",
        "th": "โอริด"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1170203,
        "lng": 20.8017715
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.6748028,
        41.0791672,
        20.8303644,
        41.1486339
      ]
    },
    {
      "id": 5920,
      "name": "Mollet",
      "names": {
        "zh": "莫列特"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5393484,
        "lng": 2.2130934
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.1874839,
        41.5238933,
        2.2337129,
        41.5757548
      ]
    },
    {
      "id": 5921,
      "name": "Guliston",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.522099049999994,
        "lng": 68.78032001983541
      },
      "country": "Uzbekistan",
      "importance": 1,
      "bbox": [
        68.7202012,
        40.4738908,
        68.8248383,
        40.5709266
      ]
    },
    {
      "id": 5922,
      "name": "Madinat Hamad",
      "names": {},
      "countryCode": "BH",
      "latLng": {
        "lat": 26.11528,
        "lng": 50.50694
      },
      "country": "Bahrain",
      "importance": 3,
      "bbox": [
        50.48694,
        26.09528,
        50.52694,
        26.13528
      ]
    },
    {
      "id": 5923,
      "name": "New Brunswick",
      "names": {
        "es": "Nuevo Brunswick",
        "fr": "Nouveau-Brunswick",
        "zh": "新的不伦瑞克",
        "de": "Neues Brunswick",
        "it": "Nuovo Brunswick",
        "id": "Brunswick baru",
        "ja": "ニューブランズウィック",
        "th": "ใหม่บรันสวิก",
        "pt": "Novo brunswick"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4862174,
        "lng": -74.4518173
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.4895,
        40.467952,
        -74.392412,
        40.509533
      ]
    },
    {
      "id": 5924,
      "name": "M.A. Rasulzada",
      "names": {
        "ja": "rasulzada"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 12.45167,
        "lng": 7.49805
      },
      "country": "Azerbaijan",
      "importance": 3,
      "bbox": [
        7.47805,
        12.43167,
        7.51805,
        12.47167
      ]
    },
    {
      "id": 5925,
      "name": "Bootle",
      "names": {
        "zh": "布特尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4506931,
        "lng": -2.9948834
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0348834,
        53.4106931,
        -2.9548834,
        53.4906931
      ]
    },
    {
      "id": 5926,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 32.2942679,
        "lng": -64.7853167
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -64.7889323,
        32.2905093,
        -64.7774884,
        32.2997335
      ]
    },
    {
      "id": 5927,
      "name": "Corbeil-Essonnes",
      "names": {
        "zh": "花篮装饰-埃索讷",
        "it": "Corbeil essonnes-"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6137734,
        "lng": 2.4818087
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        2.4366347,
        48.569074,
        2.4922688,
        48.6253571
      ]
    },
    {
      "id": 5928,
      "name": "Busia",
      "names": {
        "zh": "布西亚"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4675812,
        "lng": 34.0904896
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.0504896,
        0.4275812,
        34.1304896,
        0.5075812
      ]
    },
    {
      "id": 5929,
      "name": "Lautoka",
      "names": {
        "zh": "劳托卡"
      },
      "countryCode": "FJ",
      "latLng": {
        "lat": -17.6043956,
        "lng": 177.4530138
      },
      "country": "Fiji",
      "importance": 3,
      "bbox": [
        177.2930138,
        -17.7643956,
        177.6130138,
        -17.4443956
      ]
    },
    {
      "id": 5930,
      "name": "Ercolano",
      "names": {
        "zh": "埃尔科拉诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8053613,
        "lng": 14.347103624843179
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.3448804,
        40.8038005,
        14.3495804,
        40.8069293
      ]
    },
    {
      "id": 5931,
      "name": "Fountain Valley",
      "names": {
        "es": "Valle de la fuente",
        "fr": "Vallée de la fontaine",
        "zh": "喷泉山谷",
        "de": "Brunnen-Tal",
        "it": "Valle della Fontana",
        "id": "Lembah air mancur",
        "ja": "噴水渓谷",
        "th": "หุบเขาน้ำพุ",
        "pt": "Vale da fonte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7038145,
        "lng": -117.9627349
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.9804874,
        33.6867385,
        -117.9151871,
        33.7335089
      ]
    },
    {
      "id": 5932,
      "name": "Rijswijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0446265,
        "lng": 4.3188215
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.2909517,
        52.0127786,
        4.3578533,
        52.0619468
      ]
    },
    {
      "id": 5933,
      "name": "Amalapuram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5777179,
        "lng": 82.0032621
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.9632621,
        16.5377179,
        82.0432621,
        16.6177179
      ]
    },
    {
      "id": 5934,
      "name": "Lancaster",
      "names": {
        "zh": "兰开斯特",
        "ja": "ランカスター",
        "th": "แลงคาสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.0484068,
        "lng": -2.7990345
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.9836471,
        53.9180664,
        -2.4587349,
        54.2395575
      ]
    },
    {
      "id": 5935,
      "name": "Skien",
      "names": {
        "zh": "希恩"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2091003,
        "lng": 9.607544
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        9.447544,
        59.0491003,
        9.767544,
        59.3691003
      ]
    },
    {
      "id": 5936,
      "name": "Lampang",
      "names": {
        "zh": "南邦府",
        "th": "ลำปาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.2899489,
        "lng": 99.4958526
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.3358526,
        18.1299489,
        99.6558526,
        18.4499489
      ]
    },
    {
      "id": 5937,
      "name": "Vranje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.5558337,
        "lng": 21.8976673
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7533299,
        42.40043,
        22.1134656,
        42.7965662
      ]
    },
    {
      "id": 5938,
      "name": "Ibiza",
      "names": {
        "zh": "伊维萨",
        "ja": "イビサ島",
        "th": "อิบิซา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.910072,
        "lng": 1.430454
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.3845938,
        38.8746234,
        1.4634214,
        38.9299968
      ]
    },
    {
      "id": 5939,
      "name": "Ithaca",
      "names": {
        "zh": "伊萨卡",
        "ja": "イサカ",
        "th": "อิธาก้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4396039,
        "lng": -76.4968019
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.5270029,
        42.4181672,
        -76.4699893,
        42.4669379
      ]
    },
    {
      "id": 5940,
      "name": "Oak Lawn",
      "names": {
        "es": "Césped de roble",
        "fr": "Pelouse de chêne",
        "zh": "橡木草坪",
        "de": "Eichenrasen",
        "it": "Prato in quercia",
        "id": "Lapangan kayu ek",
        "ja": "オークの芝生",
        "th": "สนามหญ้าโอ๊ค",
        "pt": "Gramado de carvalho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7108662,
        "lng": -87.7581081
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.7986951,
        41.6832398,
        -87.7203311,
        41.7345316
      ]
    },
    {
      "id": 5941,
      "name": "Shiogama",
      "names": {
        "zh": "盐釜"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.3143384,
        "lng": 141.0218221
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.993858,
        38.2928366,
        141.1413755,
        38.3648809
      ]
    },
    {
      "id": 5943,
      "name": "Malisheve",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4838388,
        "lng": 20.7430294
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.7030294,
        42.4438388,
        20.7830294,
        42.5238388
      ]
    },
    {
      "id": 5944,
      "name": "Gutao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.2025,
        "lng": 112.17806
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.15806,
        37.1825,
        112.19806,
        37.2225
      ]
    },
    {
      "id": 5945,
      "name": "Padangpanjang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.469778,
        "lng": 100.400891
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        100.365822,
        -0.492043,
        100.434021,
        -0.447526
      ]
    },
    {
      "id": 5946,
      "name": "Mikkeli",
      "names": {
        "zh": "米凯利"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.6872422,
        "lng": 27.2739424
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        27.2738924,
        61.6871922,
        27.2739924,
        61.6872922
      ]
    },
    {
      "id": 5947,
      "name": "Chake Chake",
      "names": {
        "es": "Chance",
        "fr": "Chakke chake",
        "de": "Kake",
        "it": "Chake Bosta",
        "ja": "チャクチェイク",
        "pt": "Chake"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.2440346,
        "lng": 39.7685075
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        39.7285075,
        -5.2840346,
        39.8085075,
        -5.2040346
      ]
    },
    {
      "id": 5948,
      "name": "Cumbernauld",
      "names": {
        "zh": "坎伯诺尔德"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.9477764,
        "lng": -3.9882568
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -4.0716935,
        55.9207931,
        -3.9339272,
        55.9697085
      ]
    },
    {
      "id": 5950,
      "name": "Brentwood",
      "names": {
        "zh": "布伦特伍德",
        "ja": "ブレントウッド",
        "th": "แนชวิลล์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6201654,
        "lng": 0.3018662
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.1756759,
        51.5642976,
        0.4125337,
        51.7170079
      ]
    },
    {
      "id": 5951,
      "name": "Muban Saeng Bua Thong",
      "names": {
        "es": "MUBAN SAENG BUA TANGA",
        "zh": "穆坎萨恩布瓦丁字裤",
        "ja": "ムバン・ソネ・ブア・トーン",
        "th": "Muban แสงบัวทอง",
        "pt": "Muban saeng bua tanga"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.94558,
        "lng": 100.39506
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 5952,
      "name": "Berwyn",
      "names": {
        "zh": "伯温"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8505874,
        "lng": -87.7936685
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.8042199,
        41.821153,
        -87.77778,
        41.8653966
      ]
    },
    {
      "id": 5953,
      "name": "Charikar",
      "names": {
        "zh": "恰里卡尔"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 35.017485,
        "lng": 69.168376
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        69.148376,
        34.997485,
        69.188376,
        35.037485
      ]
    },
    {
      "id": 5954,
      "name": "Aloha",
      "names": {
        "zh": "阿罗哈",
        "ja": "アロハ",
        "th": "อโลฮา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.4942838,
        "lng": -122.867045
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.887045,
        45.4742838,
        -122.847045,
        45.5142838
      ]
    },
    {
      "id": 5955,
      "name": "Cagnes-sur-Mer",
      "names": {
        "zh": "cagnes-滨海",
        "ja": "cagnes =シュル=メール",
        "th": "cagnes-ซูร์ mer"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6612012,
        "lng": 7.1513834
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        7.1206463,
        43.6418815,
        7.1798328,
        43.7005068
      ]
    },
    {
      "id": 5956,
      "name": "Willenhall",
      "names": {
        "zh": "威伦霍尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.583837,
        "lng": -2.053586
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.05858581323,
        52.5788373901,
        -2.04858581323,
        52.5888373901
      ]
    },
    {
      "id": 5957,
      "name": "Louangphabang",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 19.88601,
        "lng": 102.13503
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        102.11503,
        19.86601,
        102.15503,
        19.90601
      ]
    },
    {
      "id": 5958,
      "name": "Shiraoka",
      "names": {
        "zh": "白冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0186181,
        "lng": 139.677099
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.603961,
        35.995306,
        139.7137982,
        36.049547
      ]
    },
    {
      "id": 5959,
      "name": "Prachuap Khiri Khan",
      "names": {
        "th": "ประจวบคีรีขันธ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 11.808875,
        "lng": 99.7981206
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.7980706,
        11.808825,
        99.7981706,
        11.808925
      ]
    },
    {
      "id": 5960,
      "name": "Gao",
      "names": {
        "zh": "高",
        "ja": "ガオ",
        "th": "เก่า"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 17.00244,
        "lng": -0.09911544803817574
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -1.707,
        14.94407,
        1.792,
        19.039
      ]
    },
    {
      "id": 5961,
      "name": "Irvington",
      "names": {
        "zh": "欧文顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7323235,
        "lng": -74.2348684
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.25629,
        40.70829,
        -74.2094059,
        40.7432364
      ]
    },
    {
      "id": 5962,
      "name": "Tulcan",
      "names": {
        "es": "Tulcán",
        "zh": "图尔坎"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": 0.8114572,
        "lng": -77.7172256
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -77.8235321,
        0.6780171,
        -77.6638301,
        0.8509049
      ]
    },
    {
      "id": 5963,
      "name": "Rosemead",
      "names": {
        "zh": "罗斯米德",
        "ja": "ローズミード"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0676169,
        "lng": -118.0879763
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1279763,
        34.0276169,
        -118.0479763,
        34.1076169
      ]
    },
    {
      "id": 5964,
      "name": "Korce",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 40.6158967,
        "lng": 20.7772187
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        20.7615638,
        40.5987274,
        20.7977055,
        40.6370098
      ]
    },
    {
      "id": 5965,
      "name": "Paramount",
      "names": {
        "fr": "Primordiale",
        "zh": "派拉蒙",
        "id": "Terpenting",
        "ja": "パラマウント",
        "th": "สิ่งสำคัญยิ่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.898917,
        "lng": -118.171005
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.1889012,
        33.87981,
        -118.1428072,
        33.9187859
      ]
    },
    {
      "id": 5966,
      "name": "Iganga",
      "names": {
        "zh": "伊甘加"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6199972,
        "lng": 33.4729991
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.4679991,
        0.6149972,
        33.4779991,
        0.6249972
      ]
    },
    {
      "id": 5968,
      "name": "Bayonne",
      "names": {
        "es": "Bayona",
        "zh": "巴约讷",
        "ja": "バヨンヌ",
        "th": "บาย"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.493728,
        "lng": -1.475394
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.504816,
        43.450182,
        -1.417658,
        43.51902
      ]
    },
    {
      "id": 5969,
      "name": "Sakon Nakhon",
      "names": {
        "zh": "佐仓纳霍恩",
        "ja": "サコンナション",
        "th": "สกลนคร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.153527,
        "lng": 104.140142
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        103.926755,
        17.105504,
        104.220226,
        17.343946
      ]
    },
    {
      "id": 5970,
      "name": "Alajuela",
      "names": {
        "zh": "阿拉胡埃拉"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 10.0165183,
        "lng": -84.2138789
      },
      "country": "Costa Rica",
      "importance": 1,
      "bbox": [
        -84.2290687,
        10.002424,
        -84.1788929,
        10.0597271
      ]
    },
    {
      "id": 5971,
      "name": "Ocosingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9057,
        "lng": -92.092776
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.132776,
        16.8657,
        -92.052776,
        16.9457
      ]
    },
    {
      "id": 5972,
      "name": "Pursat",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 12.53878,
        "lng": 103.9192
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        103.8992,
        12.51878,
        103.9392,
        12.55878
      ]
    },
    {
      "id": 5973,
      "name": "Marondera",
      "names": {
        "zh": "马龙德拉"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.18527,
        "lng": 31.55193
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        31.53193,
        -18.20527,
        31.57193,
        -18.16527
      ]
    },
    {
      "id": 5974,
      "name": "Moskovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6042415,
        "lng": 37.3544987
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.3544487,
        55.6041915,
        37.3545487,
        55.6042915
      ]
    },
    {
      "id": 5975,
      "name": "Nes Ziyyona",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 31.92933,
        "lng": 34.79868
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.77868,
        31.90933,
        34.81868,
        31.94933
      ]
    },
    {
      "id": 5976,
      "name": "San Vicente",
      "names": {
        "zh": "圣维森特",
        "ja": "サンビセンテ"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.6433169,
        "lng": -88.7836392
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.9436392,
        13.4833169,
        -88.6236392,
        13.8033169
      ]
    },
    {
      "id": 5978,
      "name": "Vaulx-en-Velin",
      "names": {
        "zh": "vaulx-连接velin",
        "ja": "vaulx-専用velin",
        "th": "vaulx-ห้อง velin"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7784255,
        "lng": 4.9206153
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        4.8793802,
        45.7442244,
        4.9703538,
        45.8111651
      ]
    },
    {
      "id": 5979,
      "name": "Scafati",
      "names": {
        "zh": "斯卡法蒂"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7536474,
        "lng": 14.5241766
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.5191766,
        40.7486474,
        14.5291766,
        40.7586474
      ]
    },
    {
      "id": 5980,
      "name": "Gabrovo",
      "names": {
        "zh": "加布罗沃"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.8710344,
        "lng": 25.315798
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        25.160806,
        42.733672,
        25.438775,
        42.9199302
      ]
    },
    {
      "id": 5981,
      "name": "Rho",
      "names": {
        "zh": "罗镇",
        "ja": "ロー",
        "th": "โร"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.528878,
        "lng": 9.04156
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        9.0064843,
        45.5023371,
        9.0963721,
        45.5597497
      ]
    },
    {
      "id": 5982,
      "name": "Hrazdan",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.5160287,
        "lng": 44.7571021
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        44.7521021,
        40.5110287,
        44.7621021,
        40.5210287
      ]
    },
    {
      "id": 5984,
      "name": "Revere",
      "names": {
        "zh": "里维尔",
        "ja": "リビア",
        "th": "เคารพนับถือบูชา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4084302,
        "lng": -71.0119948
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.0331809,
        42.3881648,
        -70.9491485,
        42.4501176
      ]
    },
    {
      "id": 5985,
      "name": "Maha Sarakham",
      "names": {
        "zh": "马哈萨拉克",
        "ja": "マハサラカム",
        "th": "มหาสารคาม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.1880948,
        "lng": 103.2987346
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        103.2587346,
        16.1480948,
        103.3387346,
        16.2280948
      ]
    },
    {
      "id": 5987,
      "name": "Jimenez",
      "names": {
        "es": "Jiménez",
        "zh": "希门尼斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.965662350000002,
        "lng": -104.36731073193901
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.103945,
        26.3404682,
        -103.6990941,
        27.5991422
      ]
    },
    {
      "id": 5988,
      "name": "Aspen Hill",
      "names": {
        "zh": "阿斯彭山",
        "de": "Aspenhügel",
        "ja": "アスペンヒル",
        "th": "แอสเพนเนิน",
        "pt": "Colina de aspen"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0903281,
        "lng": -77.08659678126821
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.125434,
        39.0598678,
        -77.0340509,
        39.1207924
      ]
    },
    {
      "id": 5992,
      "name": "Takeo",
      "names": {
        "zh": "武雄",
        "ja": "タケオ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.9875805,
        "lng": 104.7830181
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.6230181,
        10.8275805,
        104.9430181,
        11.1475805
      ]
    },
    {
      "id": 5993,
      "name": "Helena",
      "names": {
        "fr": "Hélène",
        "zh": "海伦娜",
        "ja": "ヘレナ",
        "th": "เฮเลนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.5927425,
        "lng": -112.036277
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -112.083282,
        46.559223,
        -111.951567,
        46.6294806
      ]
    },
    {
      "id": 5994,
      "name": "Vejle",
      "names": {},
      "countryCode": "DK",
      "latLng": {
        "lat": 55.7066917,
        "lng": 9.5365649
      },
      "country": "Denmark",
      "importance": 1,
      "bbox": [
        9.5315649,
        55.7016917,
        9.5415649,
        55.7116917
      ]
    },
    {
      "id": 5996,
      "name": "Bang Bua Thong",
      "names": {
        "es": "Bug bua tanga",
        "fr": "Bang bua strong",
        "zh": "邦布瓦丁字裤",
        "ja": "バングバトン",
        "th": "บางบัวทอง",
        "pt": "Bang bua tanga"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9158658,
        "lng": 100.4220767
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.401658,
        13.8726053,
        100.4446767,
        13.9320743
      ]
    },
    {
      "id": 5997,
      "name": "West New York",
      "names": {
        "es": "West Nueva York",
        "fr": "Ouest de New York",
        "zh": "西纽约",
        "id": "New York Barat",
        "ja": "ウェストニューヨーク",
        "th": "เวสต์นิวยอร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7856117,
        "lng": -74.0093129
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.023614,
        40.7745491,
        -73.9930017,
        40.7965842
      ]
    },
    {
      "id": 5998,
      "name": "Hoboken",
      "names": {
        "zh": "霍博肯",
        "ja": "ホーボーケン",
        "th": "นิวเจอร์ซี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.745164,
        "lng": -74.028227
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.043872,
        40.730321,
        -74.013784,
        40.758984
      ]
    },
    {
      "id": 5999,
      "name": "Bodo",
      "names": {
        "zh": "博多"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 67.3094783,
        "lng": 13.915441997137794
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        12.7299708,
        66.9205542,
        15.364907,
        67.6996192
      ]
    },
    {
      "id": 6000,
      "name": "Mongu",
      "names": {
        "zh": "芒古"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -15.2668751,
        "lng": 23.1365365
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        23.0965365,
        -15.3068751,
        23.1765365,
        -15.2268751
      ]
    },
    {
      "id": 6001,
      "name": "Myrnohrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.30791,
        "lng": 37.2591
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.2349056,
        48.2583885,
        37.3033633,
        48.3350453
      ]
    },
    {
      "id": 6005,
      "name": "Oak Park",
      "names": {
        "es": "Parque de roble",
        "fr": "Parc de chêne",
        "zh": "橡树园",
        "de": "Eichenpark",
        "id": "Pohon ek",
        "ja": "オークパーク",
        "th": "สวนโอ๊ค",
        "pt": "Parque de carvalho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8878145,
        "lng": -87.7887615
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.8057685,
        41.8649937,
        -87.7741322,
        41.9092533
      ]
    },
    {
      "id": 6006,
      "name": "Jinotepe",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.851055,
        "lng": -86.200618
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.220618,
        11.831055,
        -86.180618,
        11.871055
      ]
    },
    {
      "id": 6011,
      "name": "Lamia",
      "names": {
        "zh": "拉米亚",
        "ja": "鬼女",
        "th": "นครราชสีมา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.8993832,
        "lng": 22.433582
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        22.273582,
        38.7393832,
        22.593582,
        39.0593832
      ]
    },
    {
      "id": 6012,
      "name": "Mangochi",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -14.4805084,
        "lng": 35.2606129
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.2206129,
        -14.5205084,
        35.3006129,
        -14.4405084
      ]
    },
    {
      "id": 6013,
      "name": "Lankaran",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.75428,
        "lng": 48.85062
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.83062,
        38.73428,
        48.87062,
        38.77428
      ]
    },
    {
      "id": 6014,
      "name": "Vila Real",
      "names": {
        "fr": "Vila réel",
        "zh": "Vila真实",
        "it": "Vila reale",
        "id": "Vila nyata",
        "ja": "ビラリアル",
        "th": "vila จริง"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2976935,
        "lng": -7.7457614
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -7.9213669,
        41.1796852,
        -7.6003342,
        41.4215252
      ]
    },
    {
      "id": 6017,
      "name": "Loreto",
      "names": {
        "zh": "洛雷托",
        "th": "เรโต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": -4,
        "lng": -75.166667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -76.5332611,
        -5.73926,
        -73.378232,
        -1.7937684
      ]
    },
    {
      "id": 6019,
      "name": "Ratnapura",
      "names": {
        "zh": "拉特纳普勒"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.68278,
        "lng": 80.39917
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        80.37917,
        6.66278,
        80.41917,
        6.70278
      ]
    },
    {
      "id": 6020,
      "name": "Barendrecht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.85193795,
        "lng": 4.52938352520747
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4798717,
        51.8298542,
        4.5714124,
        51.8740272
      ]
    },
    {
      "id": 6024,
      "name": "Eger",
      "names": {
        "zh": "埃格尔",
        "th": "เอเกอร์"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8989887,
        "lng": 20.3743665
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        20.3112396,
        47.8569745,
        20.4716539,
        48.0013568
      ]
    },
    {
      "id": 6025,
      "name": "Jihlava",
      "names": {
        "zh": "伊赫拉瓦"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.400043,
        "lng": 15.590803
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        15.546397,
        49.379128,
        15.620386,
        49.447665
      ]
    },
    {
      "id": 6026,
      "name": "Harasta",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.55869,
        "lng": 36.36515
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.34515,
        33.53869,
        36.38515,
        33.57869
      ]
    },
    {
      "id": 6027,
      "name": "Whangarei",
      "names": {
        "zh": "旺格雷",
        "ja": "ファンガレイ"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -35.7275268,
        "lng": 174.3194202
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        174.1594202,
        -35.8875268,
        174.4794202,
        -35.5675268
      ]
    },
    {
      "id": 6028,
      "name": "Sombor",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.7859981,
        "lng": 19.1140701
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.1090701,
        45.7809981,
        19.1190701,
        45.7909981
      ]
    },
    {
      "id": 6030,
      "name": "Levittown",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.7259621,
        "lng": -73.5144766
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.5456886,
        40.700016,
        -73.4846383,
        40.7435777
      ]
    },
    {
      "id": 6031,
      "name": "Tizimin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1428919,
        "lng": -88.1524717
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -88.1751945,
        21.1069696,
        -88.1163968,
        21.1657726
      ]
    },
    {
      "id": 6032,
      "name": "Perth Amboy",
      "names": {
        "zh": "珀斯安博",
        "it": "Amboy di Perth",
        "id": "Amboy Perth",
        "ja": "パースの雰囲気",
        "th": "แอมบอยเพิร์ท"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.521475,
        "lng": -74.271221
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.291697,
        40.494507,
        -74.246069,
        40.543517
      ]
    },
    {
      "id": 6033,
      "name": "Tlapa de Comonfort",
      "names": {
        "de": "Tlaapa de comonfort",
        "ja": "Tlapa de Comport"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.544262,
        "lng": -98.577992
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.5983204511,
        17.5225918381,
        -98.5503254732,
        17.5662368901
      ]
    },
    {
      "id": 6035,
      "name": "Kardzhali",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.638576,
        "lng": 25.373482
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        25.349369,
        41.616948,
        25.413235,
        41.657657
      ]
    },
    {
      "id": 6036,
      "name": "Wajir",
      "names": {
        "zh": "瓦吉尔"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 1.7471,
        "lng": 40.05732
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        40.03732,
        1.7271,
        40.07732,
        1.7671
      ]
    },
    {
      "id": 6037,
      "name": "Guozhen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.365067,
        "lng": 107.140099
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.089351502,
        34.3389591581,
        107.1879916,
        34.3932578656
      ]
    },
    {
      "id": 6038,
      "name": "Seguela",
      "names": {
        "zh": "塞盖拉"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 7.9662547,
        "lng": -6.675572
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -6.715572,
        7.9262547,
        -6.635572,
        8.0062547
      ]
    },
    {
      "id": 6040,
      "name": "Placentia",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.8714814,
        "lng": -117.8617337
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8850228,
        33.8536039,
        -117.8164366,
        33.9101951
      ]
    },
    {
      "id": 6042,
      "name": "Cuetzalan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.018611,
        "lng": -97.521111
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.561111,
        19.978611,
        -97.481111,
        20.058611
      ]
    },
    {
      "id": 6043,
      "name": "Sar-e Pul",
      "names": {
        "es": "Sar-e pulg"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.29894,
        "lng": 66.007414
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        65.7638296,
        35.9101405,
        66.4331406,
        36.564877
      ]
    },
    {
      "id": 6044,
      "name": "Komotini",
      "names": {
        "id": "Komotiní"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.1182,
        "lng": 25.4035708
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        25.2435708,
        40.9582,
        25.5635708,
        41.2782
      ]
    },
    {
      "id": 6047,
      "name": "Gori",
      "names": {
        "zh": "哥里",
        "ja": "ゴーリ"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9818469,
        "lng": 44.1117811
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        44.0824621,
        41.9639448,
        44.1634244,
        42.0160041
      ]
    },
    {
      "id": 6050,
      "name": "Alytus",
      "names": {
        "zh": "阿利图斯"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.3961344,
        "lng": 24.0459268
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.9586565,
        54.3635823,
        24.1023708,
        54.4427206
      ]
    },
    {
      "id": 6051,
      "name": "Sisophon",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 13.58588,
        "lng": 102.97369
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        102.95369,
        13.56588,
        102.99369,
        13.60588
      ]
    },
    {
      "id": 6053,
      "name": "Aliso Viejo",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.579889,
        "lng": -117.729533
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.754731,
        33.544606,
        -117.701273,
        33.608847
      ]
    },
    {
      "id": 6054,
      "name": "Jarash",
      "names": {},
      "countryCode": "JO",
      "latLng": {
        "lat": 32.261194200000006,
        "lng": 35.87252632749171
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.676542,
        32.1188118,
        36.0006186,
        32.4036128
      ]
    },
    {
      "id": 6055,
      "name": "Bangued",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.5965664,
        "lng": 120.6179987
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.5779987,
        17.5565664,
        120.6579987,
        17.6365664
      ]
    },
    {
      "id": 6060,
      "name": "Jurmala",
      "names": {
        "zh": "尤尔马拉",
        "th": "เจอร์มาลา"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9727164,
        "lng": 23.7886979
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        23.4733924,
        56.9238081,
        23.970957,
        57.0087886
      ]
    },
    {
      "id": 6063,
      "name": "Gbadolite",
      "names": {
        "zh": "巴多利特"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 4.279,
        "lng": 21.00284
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        20.98284,
        4.259,
        21.02284,
        4.299
      ]
    },
    {
      "id": 6067,
      "name": "Country Club",
      "names": {
        "es": "Club de campo",
        "zh": "乡村俱乐部",
        "de": "Landclub",
        "it": "Campagna",
        "id": "Klub pedesaan",
        "ja": "カントリークラブ",
        "th": "ประเทศสโมสร",
        "pt": "Clube de campo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.94096615,
        "lng": -80.30999085833386
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.326703,
        25.9234864,
        -80.2936362,
        25.957133
      ]
    },
    {
      "id": 6068,
      "name": "Cojutepeque",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7217396,
        "lng": -88.9345406
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -89.0448454,
        13.6708744,
        -88.899795,
        13.7553155
      ]
    },
    {
      "id": 6070,
      "name": "Gracias",
      "names": {
        "zh": "格拉西亚斯"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.5889861,
        "lng": -88.5813531
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -88.7774753,
        14.5108472,
        -88.4950397,
        14.7481238
      ]
    },
    {
      "id": 6072,
      "name": "Ma`an",
      "names": {},
      "countryCode": "JO",
      "latLng": {
        "lat": 30.225249750000003,
        "lng": 36.52246845379944
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.3151422,
        29.183401,
        37.9971461,
        31.261127
      ]
    },
    {
      "id": 6073,
      "name": "Selibe Phikwe",
      "names": {
        "it": "Selebe Phikwe",
        "ja": "セレピフ"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -21.974605,
        "lng": 27.842536
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 6075,
      "name": "Plainfield",
      "names": {
        "zh": "普兰菲尔德",
        "ja": "プレーンフィールド",
        "th": "เพลนฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6337136,
        "lng": -74.4073737
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.463348,
        40.594502,
        -74.382791,
        40.644191
      ]
    },
    {
      "id": 6076,
      "name": "Tubod",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0486582,
        "lng": 123.7961839
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.7561839,
        8.0086582,
        123.8361839,
        8.0886582
      ]
    },
    {
      "id": 6077,
      "name": "Wheaton",
      "names": {
        "zh": "惠顿",
        "ja": "ウィートン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.04979325,
        "lng": -77.05228508396917
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.1092452,
        39.0220077,
        -77.0234148,
        39.0775125
      ]
    },
    {
      "id": 6078,
      "name": "Campobasso",
      "names": {
        "zh": "坎波巴索",
        "ja": "カンポバッソ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.5581451,
        "lng": 14.6639788
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        14.6589788,
        41.5531451,
        14.6689788,
        41.5631451
      ]
    },
    {
      "id": 6079,
      "name": "Moyobamba",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -6.0347302,
        "lng": -76.9746773
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -77.7448277,
        -6.1127807,
        -76.7454386,
        -5.4064668
      ]
    },
    {
      "id": 6080,
      "name": "Al Kharjah",
      "names": {
        "ja": "アルカルジャ",
        "th": "อัล karjah"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 21.08278,
        "lng": 40.71889
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        40.69889,
        21.06278,
        40.73889,
        21.10278
      ]
    },
    {
      "id": 6087,
      "name": "Zaranj",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 30.954754,
        "lng": 61.859475
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        61.819475,
        30.914754,
        61.899475,
        30.994754
      ]
    },
    {
      "id": 6088,
      "name": "Odienne",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 9.50511,
        "lng": -7.56433
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -7.58433,
        9.48511,
        -7.54433,
        9.52511
      ]
    },
    {
      "id": 6091,
      "name": "Kangar",
      "names": {
        "zh": "加央"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 6.4389312,
        "lng": 100.1944838
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        100.0344838,
        6.2789312,
        100.3544838,
        6.5989312
      ]
    },
    {
      "id": 6093,
      "name": "Belfort",
      "names": {
        "zh": "贝尔福",
        "ja": "ベルフォート",
        "th": "เบลฟอร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6335286,
        "lng": 6.8537846
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.8487846,
        47.6285286,
        6.8587846,
        47.6385286
      ]
    },
    {
      "id": 6095,
      "name": "Huancavelica",
      "names": {
        "zh": "万卡韦利卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.78728,
        "lng": -74.9730951
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -75.2138184,
        -12.9796041,
        -74.8755721,
        -12.7035359
      ]
    },
    {
      "id": 6098,
      "name": "Lulea",
      "names": {
        "zh": "律勒欧",
        "de": "Luleå"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 65.57904,
        "lng": 22.190653
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        22.064033,
        65.544321,
        22.305125,
        65.633024
      ]
    },
    {
      "id": 6100,
      "name": "Couva",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.4223291,
        "lng": -61.458708
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.5028033,
        10.3646928,
        -61.3905894,
        10.4799418
      ]
    },
    {
      "id": 6102,
      "name": "Waterford",
      "names": {
        "zh": "沃特福德",
        "ja": "ウォーターフォード",
        "th": "วอเตอร์ฟอร์ด"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.2609997,
        "lng": -7.1119081
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.1869522,
        52.2102427,
        -7.0338797,
        52.2798229
      ]
    },
    {
      "id": 6106,
      "name": "Nelson",
      "names": {
        "zh": "尼尔森",
        "ja": "ネルソン",
        "th": "เนลสัน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.2710849,
        "lng": 173.2836756
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        173.1898574,
        -41.3934269,
        173.5987618,
        -41.0517529
      ]
    },
    {
      "id": 6110,
      "name": "Meoqui",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 28.272806,
        "lng": -105.482571
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.49718,
        28.258871,
        -105.460894,
        28.289944
      ]
    },
    {
      "id": 6112,
      "name": "San Isidro",
      "names": {
        "zh": "圣伊斯丹",
        "ja": "サンアイズロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4739792,
        "lng": -58.5264866
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.5644076,
        -34.4989542,
        -58.4958356,
        -34.4565417
      ]
    },
    {
      "id": 6115,
      "name": "Karlovy Vary",
      "names": {
        "es": "Karlovy varía",
        "fr": "Karlovy varie",
        "zh": "卡洛维各不相同",
        "de": "Karlsbad variiert",
        "it": "Karlovy varia",
        "id": "Karlovy bervariasi",
        "ja": "カルロビーはさまざまです",
        "th": "Karlovy แตกต่างกันไป",
        "pt": "Karlovy varia"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.2306216,
        "lng": 12.8701437
      },
      "country": "Czechia",
      "importance": 1,
      "bbox": [
        12.784855,
        50.1669377,
        12.9439763,
        50.266775
      ]
    },
    {
      "id": 6121,
      "name": "Mantes-la-Jolie",
      "names": {
        "zh": "芒特拉若利",
        "ja": "mantes・ラ・ジョリー",
        "th": "mantes-ลาโจลี่"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9891971,
        "lng": 1.7140683
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.6562269,
        48.9816543,
        1.7271676,
        49.0098616
      ]
    },
    {
      "id": 6123,
      "name": "Asadabad",
      "names": {
        "zh": "阿萨达巴德"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.8727951,
        "lng": 71.1508321
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        70.9908321,
        34.7127951,
        71.3108321,
        35.0327951
      ]
    },
    {
      "id": 6124,
      "name": "Sariyer",
      "names": {
        "zh": "萨勒耶尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1685803,
        "lng": 29.0572623
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.9325702,
        41.0810946,
        29.1162566,
        41.2621786
      ]
    },
    {
      "id": 6125,
      "name": "Middelburg",
      "names": {
        "zh": "米德尔堡"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4996784,
        "lng": 3.6137371
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        3.5752964,
        51.4752946,
        3.660337,
        51.5353033
      ]
    },
    {
      "id": 6126,
      "name": "Tulum",
      "names": {
        "zh": "图伦",
        "ja": "トゥルム"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2145734,
        "lng": -87.42945996161191
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -87.4308701,
        20.2126666,
        -87.4278788,
        20.2166882
      ]
    },
    {
      "id": 6130,
      "name": "Nueva Loja",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": 0.0882047,
        "lng": -76.8852475
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -76.8852975,
        0.0881547,
        -76.8851975,
        0.0882547
      ]
    },
    {
      "id": 6132,
      "name": "Kita",
      "names": {
        "zh": "北",
        "ja": "北区",
        "th": "คิตะ"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": -0.2201641,
        "lng": -78.5123274
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -78.6723274,
        -0.3801641,
        -78.3523274,
        -0.0601641
      ]
    },
    {
      "id": 6133,
      "name": "Serowe",
      "names": {
        "zh": "塞罗韦"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -22.38754,
        "lng": 26.71077
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        26.69077,
        -22.40754,
        26.73077,
        -22.36754
      ]
    },
    {
      "id": 6134,
      "name": "Mandeville",
      "names": {
        "zh": "曼德维尔"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 30.3582305,
        "lng": -90.0657477
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -90.1255542,
        30.3483481,
        -90.0352452,
        30.4042551
      ]
    },
    {
      "id": 6135,
      "name": "Birendranagar",
      "names": {
        "zh": "比兰德拉纳加尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.60194,
        "lng": 81.63389
      },
      "country": "Nepal",
      "importance": 1,
      "bbox": [
        81.61389,
        28.58194,
        81.65389,
        28.62194
      ]
    },
    {
      "id": 6136,
      "name": "Nong Khai",
      "names": {
        "zh": "龚凯海",
        "th": "หนองคาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.8825057,
        "lng": 102.7482277
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.7082277,
        17.8425057,
        102.7882277,
        17.9225057
      ]
    },
    {
      "id": 6137,
      "name": "Salekhard",
      "names": {
        "id": "Salehard"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 66.5375387,
        "lng": 66.6157469
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        66.5225601,
        66.508964,
        66.7856312,
        66.6247547
      ]
    },
    {
      "id": 6139,
      "name": "Bindura",
      "names": {
        "zh": "宾杜拉"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -17.30192,
        "lng": 31.33056
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        31.31056,
        -17.32192,
        31.35056,
        -17.28192
      ]
    },
    {
      "id": 6140,
      "name": "Isiolo",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.35,
        "lng": 37.583303
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.5569354,
        0.3261076,
        37.6043526,
        0.4007321
      ]
    },
    {
      "id": 6141,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -33.2484204,
        "lng": -58.0298671
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -58.0585878,
        -33.2724146,
        -58.0024153,
        -33.2378756
      ]
    },
    {
      "id": 6145,
      "name": "Villa de Zaachila",
      "names": {
        "ja": "ヴィラデザチラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.950833,
        "lng": -96.749167
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.789167,
        16.910833,
        -96.709167,
        16.990833
      ]
    },
    {
      "id": 6149,
      "name": "Leribe",
      "names": {
        "zh": "莱里贝"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -28.866891,
        "lng": 28.0579408
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        28.0179408,
        -28.906891,
        28.0979408,
        -28.826891
      ]
    },
    {
      "id": 6151,
      "name": "Njombe",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.33333,
        "lng": 34.76667
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        34.74667,
        -9.35333,
        34.78667,
        -9.31333
      ]
    },
    {
      "id": 6155,
      "name": "Mamburao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2233,
        "lng": 120.596
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.576,
        13.2033,
        120.616,
        13.2433
      ]
    },
    {
      "id": 6157,
      "name": "Chalon-sur-Saone",
      "names": {
        "fr": "Chalon-sur-Saône",
        "zh": "夏龙索恩河畔",
        "ja": "chalonシュルsaone",
        "th": "chalon-ซูร์-saone"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.7831001,
        "lng": 4.8534326
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.8195749,
        46.760333,
        4.8822126,
        46.819055
      ]
    },
    {
      "id": 6158,
      "name": "Invercargill",
      "names": {
        "zh": "因弗卡吉尔",
        "ja": "インバーカーギル"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -46.4118465,
        "lng": 168.3470632
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        168.1954027,
        -46.6255852,
        168.542778,
        -46.3221671
      ]
    },
    {
      "id": 6159,
      "name": "Kerkrade",
      "names": {
        "zh": "凯尔克拉德",
        "ja": "ケルクラーデ"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.86596125,
        "lng": 6.066479114795257
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.0660595,
        50.8657795,
        6.0669185,
        50.8661271
      ]
    },
    {
      "id": 6162,
      "name": "New Amsterdam",
      "names": {
        "fr": "Nouveau Amsterdam",
        "zh": "新阿姆斯特丹",
        "de": "Neue Amsterdam",
        "it": "Nuova Amsterdam",
        "id": "Amsterdam baru",
        "ja": "ニューアムステルダム",
        "th": "ใหม่อัมสเตอร์ดัม",
        "pt": "Novo Amsterdão"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.2426407,
        "lng": -57.5168359
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -57.5324593,
        6.1847469,
        -57.5046193,
        6.2605677
      ]
    },
    {
      "id": 6163,
      "name": "Hajjah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.700042,
        "lng": 43.602277
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        43.592277,
        15.690042,
        43.612277,
        15.710042
      ]
    },
    {
      "id": 6165,
      "name": "Kokkola",
      "names": {
        "zh": "科科拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.8391421,
        "lng": 23.1336845
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        22.287872,
        63.4991135,
        24.4927302,
        64.1789752
      ]
    },
    {
      "id": 6169,
      "name": "Embu",
      "names": {
        "zh": "恩布"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.5359479,
        "lng": 37.6652884382665
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.2678886,
        -0.9181648,
        37.9365072,
        -0.1520515
      ]
    },
    {
      "id": 6170,
      "name": "Bang Kruai",
      "names": {
        "zh": "邦克瑞",
        "ja": "バングクルイ",
        "th": "บางกรวย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8027856,
        "lng": 100.4773195
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.4653263,
        13.789068,
        100.5150455,
        13.8193203
      ]
    },
    {
      "id": 6171,
      "name": "Rosso",
      "names": {
        "zh": "罗索",
        "ja": "ロッソ"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.5132493,
        "lng": -15.8099665
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -15.9699665,
        16.3532493,
        -15.6499665,
        16.6732493
      ]
    },
    {
      "id": 6172,
      "name": "Chitre",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 7.96667,
        "lng": -80.43333
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -80.53333,
        7.86667,
        -80.33333,
        8.06667
      ]
    },
    {
      "id": 6173,
      "name": "Badulla",
      "names": {},
      "countryCode": "LK",
      "latLng": {
        "lat": 6.9895,
        "lng": 81.0557
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        81.0357,
        6.9695,
        81.0757,
        7.0095
      ]
    },
    {
      "id": 6174,
      "name": "Nagykanizsa",
      "names": {
        "zh": "瑙吉考尼饶"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4548261,
        "lng": 16.9923923
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        16.9202725,
        46.3591273,
        17.0910568,
        46.5436884
      ]
    },
    {
      "id": 6176,
      "name": "Ciudad Constitucion",
      "names": {
        "es": "CIUDAD CONSTRUCCION",
        "id": "Ciudad konstituus",
        "ja": "Ciudad構成",
        "pt": "Ciudad constituição"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.034361,
        "lng": -111.665063
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -111.695392,
        25.016157,
        -111.624624,
        25.080371
      ]
    },
    {
      "id": 6178,
      "name": "Boosaaso",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 11.27848,
        "lng": 49.179952
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        49.019952,
        11.11848,
        49.339952,
        11.43848
      ]
    },
    {
      "id": 6179,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.8694792,
        "lng": -83.7980749
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -83.8462579,
        9.8313543,
        -83.7664152,
        9.9078768
      ]
    },
    {
      "id": 6183,
      "name": "Lindi",
      "names": {
        "zh": "林迪"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.9989181,
        "lng": 39.7137313
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        39.6737313,
        -10.0389181,
        39.7537313,
        -9.9589181
      ]
    },
    {
      "id": 6184,
      "name": "Kanye",
      "names": {
        "zh": "坎耶·",
        "ja": "カニエ"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.9766112,
        "lng": 25.3358859
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        25.1758859,
        -25.1366112,
        25.4958859,
        -24.8166112
      ]
    },
    {
      "id": 6185,
      "name": "Choma",
      "names": {
        "zh": "乔马"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -16.8141943,
        "lng": 26.9778589
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        26.9378589,
        -16.8541943,
        27.0178589,
        -16.7741943
      ]
    },
    {
      "id": 6188,
      "name": "Trollhattan",
      "names": {
        "fr": "Trollhättan",
        "zh": "特罗尔海坦",
        "de": "Trollhättan",
        "pt": "Trollhättan"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.2827931,
        "lng": 12.2908612
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.2508612,
        58.2427931,
        12.3308612,
        58.3227931
      ]
    },
    {
      "id": 6189,
      "name": "Aweil",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 8.76816,
        "lng": 27.40019
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        27.38019,
        8.74816,
        27.42019,
        8.78816
      ]
    },
    {
      "id": 6190,
      "name": "Ostersund",
      "names": {
        "zh": "厄斯特松德",
        "id": "Östersund"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 63.1793655,
        "lng": 14.6357061
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        14.4757061,
        63.0193655,
        14.7957061,
        63.3393655
      ]
    },
    {
      "id": 6192,
      "name": "Valenciennes",
      "names": {
        "zh": "瓦朗谢讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.3579317,
        "lng": 3.5234846
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.4762503,
        50.3366638,
        3.552275,
        50.3874585
      ]
    },
    {
      "id": 6193,
      "name": "Dori",
      "names": {
        "zh": "多利",
        "ja": "ドリ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 14.0327651,
        "lng": -0.0348286
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -0.0748286,
        13.9927651,
        0.0051714,
        14.0727651
      ]
    },
    {
      "id": 6196,
      "name": "Artigas",
      "names": {
        "zh": "阿蒂加斯"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -30.3984136,
        "lng": -56.4638078
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -56.4984245,
        -30.4304385,
        -56.4447498,
        -30.3852536
      ]
    },
    {
      "id": 6199,
      "name": "Sibenik",
      "names": {
        "zh": "希贝尼克",
        "ja": "シベニク",
        "th": "ซีเบนิก"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.7340647,
        "lng": 15.8944767
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        15.5565517,
        43.6059317,
        16.1538193,
        43.8465445
      ]
    },
    {
      "id": 6201,
      "name": "Cobija",
      "names": {
        "zh": "科维哈"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -11.0184614,
        "lng": -68.7538149
      },
      "country": "Bolivia",
      "importance": 1,
      "bbox": [
        -69.0002445,
        -11.1447621,
        -68.711544,
        -10.9873842
      ]
    },
    {
      "id": 6205,
      "name": "Farim",
      "names": {},
      "countryCode": "GW",
      "latLng": {
        "lat": 12.4823646,
        "lng": -15.2196439
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -15.2596439,
        12.4423646,
        -15.1796439,
        12.5223646
      ]
    },
    {
      "id": 6207,
      "name": "Slobozia",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 44.5635999,
        "lng": 27.3618477
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        27.302711,
        44.4962336,
        27.4513728,
        44.720604
      ]
    },
    {
      "id": 6209,
      "name": "Saint-Brieuc",
      "names": {
        "zh": "圣布里厄",
        "ja": "サン・brieuc",
        "th": "เซนต์-brieuc"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5141134,
        "lng": -2.7603283
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.8215945,
        48.486177,
        -2.7097323,
        48.5355296
      ]
    },
    {
      "id": 6214,
      "name": "Vilvoorde",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.928711,
        "lng": 4.423126
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.360463,
        50.896987,
        4.4870828,
        50.9592857
      ]
    },
    {
      "id": 6216,
      "name": "Vidin",
      "names": {
        "zh": "维丁"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.9858809,
        "lng": 22.8773117
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        22.7173117,
        43.8258809,
        23.0373117,
        44.1458809
      ]
    },
    {
      "id": 6219,
      "name": "Gbarnga",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 6.99556,
        "lng": -9.47222
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -9.49222,
        6.97556,
        -9.45222,
        7.01556
      ]
    },
    {
      "id": 6222,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 43.9702236,
        "lng": 25.3288462
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.2653764,
        43.9186709,
        25.4525676,
        44.0451247
      ]
    },
    {
      "id": 6225,
      "name": "Jaltipan de Morelos",
      "names": {
        "zh": "朱莉丹德莫雷斯",
        "pt": "Jalctipan de Morelos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.968341,
        "lng": -94.716555
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.736977,
        17.948393,
        -94.693579,
        17.992223
      ]
    },
    {
      "id": 6226,
      "name": "Palayan City",
      "names": {
        "es": "Ciudad palayan",
        "fr": "Ville de Palayan",
        "zh": "Palayan市",
        "de": "Palay-Stadt",
        "it": "Città palayana",
        "id": "Kota Palayan",
        "ja": "パラヤンシティ",
        "th": "เมือง Palayan",
        "pt": "Cidade de palayan"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5408666,
        "lng": 121.0842988
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.0037237,
        15.3876015,
        121.2069534,
        15.6082609
      ]
    },
    {
      "id": 6227,
      "name": "Thun",
      "names": {
        "zh": "图恩",
        "ja": "トゥーン",
        "th": "เทียน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.7582827,
        "lng": 7.628086
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        7.5862023,
        46.7094833,
        7.6902638,
        46.7714864
      ]
    },
    {
      "id": 6229,
      "name": "Buta",
      "names": {
        "zh": "布塔",
        "ja": "ブータ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.7932581,
        "lng": 24.7287054
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        24.5687054,
        2.6332581,
        24.8887054,
        2.9532581
      ]
    },
    {
      "id": 6230,
      "name": "Nueva Gerona",
      "names": {
        "fr": "Nueva gérone"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.884703,
        "lng": -82.8038514
      },
      "country": "Cuba",
      "importance": 1,
      "bbox": [
        -82.9638514,
        21.724703,
        -82.6438514,
        22.044703
      ]
    },
    {
      "id": 6231,
      "name": "Tindouf",
      "names": {
        "es": "Tinduf",
        "zh": "廷杜夫"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 27.6718402,
        "lng": -8.1397297
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -8.668908,
        25.513483,
        -3.9861,
        28.921015
      ]
    },
    {
      "id": 6232,
      "name": "Bluefields",
      "names": {
        "zh": "布卢菲尔兹"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.0131543,
        "lng": -83.764907
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -84.4369591,
        11.0431017,
        -82.9989303,
        12.1568148
      ]
    },
    {
      "id": 6233,
      "name": "Liberia",
      "names": {
        "fr": "Libéria",
        "zh": "利比里亚",
        "ja": "リベリア",
        "th": "ประเทศไลบีเรีย",
        "pt": "Libéria"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 10.6333336,
        "lng": -85.4362722
      },
      "country": "Costa Rica",
      "importance": 1,
      "bbox": [
        -85.5827667,
        10.4182972,
        -85.2920206,
        10.8127253
      ]
    },
    {
      "id": 6234,
      "name": "Boulogne-sur-Mer",
      "names": {
        "zh": "布洛涅 - 滨海",
        "ja": "ブローニュ=シュル=メール",
        "th": "boulogne-ซูร์ mer"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.7259985,
        "lng": 1.6118771
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.5789037,
        50.7100271,
        1.6333873,
        50.7505279
      ]
    },
    {
      "id": 6236,
      "name": "Pattani",
      "names": {
        "zh": "北大年",
        "th": "ปัตตานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.8680798,
        "lng": 101.253623
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.213623,
        6.8280798,
        101.293623,
        6.9080798
      ]
    },
    {
      "id": 6237,
      "name": "Lecherias",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.196676,
        "lng": -64.698657
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -64.718657,
        10.176676,
        -64.678657,
        10.216676
      ]
    },
    {
      "id": 6239,
      "name": "Actopan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5038293,
        "lng": -96.6166114
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.6566114,
        19.4638293,
        -96.5766114,
        19.5438293
      ]
    },
    {
      "id": 6241,
      "name": "Igdir",
      "names": {
        "zh": "伊迪尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9218784,
        "lng": 44.0467957
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        43.7188786,
        39.6672114,
        44.3424998,
        40.0621448
      ]
    },
    {
      "id": 6243,
      "name": "Pinneberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.6558152,
        "lng": 9.8016369
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.7636444,
        53.6086307,
        9.8285585,
        53.6838731
      ]
    },
    {
      "id": 6245,
      "name": "Arendal",
      "names": {
        "zh": "阿伦达尔",
        "ja": "アーレンダール"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.4600196,
        "lng": 8.765288
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        8.605288,
        58.3000196,
        8.925288,
        58.6200196
      ]
    },
    {
      "id": 6247,
      "name": "Coyotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.778026,
        "lng": -99.20472
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.223073,
        19.749821,
        -99.189143,
        19.799538
      ]
    },
    {
      "id": 6249,
      "name": "Tarbes",
      "names": {
        "zh": "塔布"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.234675,
        "lng": 0.069354
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.037761,
        43.212517,
        0.09316,
        43.264765
      ]
    },
    {
      "id": 6250,
      "name": "Arras",
      "names": {
        "zh": "阿拉斯",
        "ja": "アラス",
        "th": "อาร์ราส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.291048,
        "lng": 2.7772211
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.7251442,
        50.2680554,
        2.8106849,
        50.3104992
      ]
    },
    {
      "id": 6251,
      "name": "Teoloyucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.745672,
        "lng": -99.1823329
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2223329,
        19.705672,
        -99.1423329,
        19.785672
      ]
    },
    {
      "id": 6252,
      "name": "Concepcion",
      "names": {
        "es": "Concepción",
        "zh": "康塞普西翁",
        "ja": "コンセプシオン",
        "th": "กอนเซปซิยอน"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -36.8270698,
        "lng": -73.0502064
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -73.0915891,
        -36.9268099,
        -72.8446184,
        -36.7644238
      ]
    },
    {
      "id": 6253,
      "name": "Dunaujvaros",
      "names": {
        "zh": "多瑙新城"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9669545,
        "lng": 18.9393976
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        18.8604576,
        46.8898333,
        18.9657273,
        47.0019885
      ]
    },
    {
      "id": 6254,
      "name": "Zugdidi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.5088,
        "lng": 41.87088
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        41.85088,
        42.4888,
        41.89088,
        42.5288
      ]
    },
    {
      "id": 6255,
      "name": "Kabale",
      "names": {
        "zh": "卡巴莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -1.2560641,
        "lng": 29.9905385
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        29.9505385,
        -1.2960641,
        30.0305385,
        -1.2160641
      ]
    },
    {
      "id": 6257,
      "name": "Salvatierra",
      "names": {
        "zh": "萨尔瓦铁拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2125562,
        "lng": -100.877415
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.917415,
        20.1725562,
        -100.837415,
        20.2525562
      ]
    },
    {
      "id": 6258,
      "name": "Melun",
      "names": {
        "zh": "默伦",
        "th": "เมลัน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.539927,
        "lng": 2.6608169
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.6284669,
        48.5235259,
        2.6816841,
        48.5607616
      ]
    },
    {
      "id": 6259,
      "name": "Swidnik",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.221858,
        "lng": 22.698382
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.638796,
        51.189456,
        22.731077,
        51.243969
      ]
    },
    {
      "id": 6261,
      "name": "Kyustendil",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.2809956,
        "lng": 22.6890196
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        22.6469627,
        42.2537127,
        22.7337449,
        42.3135679
      ]
    },
    {
      "id": 6264,
      "name": "Nogent-sur-Marne",
      "names": {
        "zh": "诺让马恩河畔",
        "ja": "nogentシュルマルヌ",
        "th": "nogent-ซูร์-Marne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.84123175,
        "lng": 2.4806111963766315
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.48024,
        48.84086,
        2.4811743,
        48.8415411
      ]
    },
    {
      "id": 6265,
      "name": "Boumerdes",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7590403,
        "lng": 3.473102
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        3.4498593,
        36.7334834,
        3.5337397,
        36.7786267
      ]
    },
    {
      "id": 6266,
      "name": "Umm al Qaywayn",
      "names": {
        "de": "Ähm al qaywayn",
        "ja": "うーんアルカイウェイン"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.57371,
        "lng": 55.56032
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 6268,
      "name": "Chimaltenango",
      "names": {
        "zh": "奇马尔特南戈"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6613043,
        "lng": -90.8193493
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.8660174,
        14.6214846,
        -90.7501051,
        14.7485408
      ]
    },
    {
      "id": 6269,
      "name": "Kapan",
      "names": {
        "zh": "卡潘"
      },
      "countryCode": "AM",
      "latLng": {
        "lat": 39.2075659,
        "lng": 46.4049281
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        46.3279983,
        39.1766565,
        46.467206,
        39.2487205
      ]
    },
    {
      "id": 6271,
      "name": "Empalme",
      "names": {
        "zh": "恩帕尔梅"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.9577956,
        "lng": -110.8152703
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.8552703,
        27.9177956,
        -110.7752703,
        27.9977956
      ]
    },
    {
      "id": 6272,
      "name": "Fort Portal",
      "names": {
        "es": "Portal de fort",
        "fr": "Portail fort",
        "zh": "堡垒门户",
        "de": "Fortportal",
        "it": "Portale di Fort",
        "id": "Portal",
        "ja": "フォートポータル",
        "th": "พอร์ทัลป้อม"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6561907,
        "lng": 30.2779432
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.1660748,
        0.5758831,
        30.3259626,
        0.7001926
      ]
    },
    {
      "id": 6273,
      "name": "Afyonkarahisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7568597,
        "lng": 30.5387044
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        30.187985,
        38.5594725,
        30.8572953,
        38.9992005
      ]
    },
    {
      "id": 6277,
      "name": "Baler",
      "names": {
        "es": "Enfardadora",
        "fr": "Presse à balles",
        "zh": "打包机",
        "de": "Ballenpresse",
        "it": "Rotopressa",
        "ja": "ベーラー",
        "th": "วิดน้ำ",
        "pt": "Atadeira de fardos"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.759086,
        "lng": 121.5627247
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.4904816,
        15.6712734,
        121.6415799,
        15.7970056
      ]
    },
    {
      "id": 6285,
      "name": "Playa Vicente",
      "names": {
        "id": "Orang tua",
        "ja": "プラヤビセンテ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8301143,
        "lng": -95.8131614
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.8531614,
        17.7901143,
        -95.7731614,
        17.8701143
      ]
    },
    {
      "id": 6288,
      "name": "Veles",
      "names": {
        "zh": "韦莱斯"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.71556,
        "lng": 21.77556
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.75556,
        41.69556,
        21.79556,
        41.73556
      ]
    },
    {
      "id": 6291,
      "name": "Stip",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7374724,
        "lng": 22.1935856
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.1218085,
        41.6942286,
        22.2580004,
        41.7773607
      ]
    },
    {
      "id": 6293,
      "name": "Murzuq",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 24.5351182,
        "lng": 15.2592288
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        11.3326406,
        21.9902324,
        19.1857653,
        27.0752277
      ]
    },
    {
      "id": 6298,
      "name": "Concord",
      "names": {
        "zh": "康科德",
        "it": "A Concord",
        "ja": "コンコルド",
        "th": "คองคอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.207178,
        "lng": -71.537476
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -71.66871,
        43.151787,
        -71.4567655,
        43.3094297
      ]
    },
    {
      "id": 6299,
      "name": "Kalmar",
      "names": {
        "zh": "卡尔马",
        "it": "Di Kalmar",
        "ja": "カルマル",
        "th": "คาลมาร์"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.666667,
        "lng": 16.333056
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        15.741359,
        56.4255722,
        16.5563002,
        56.931681
      ]
    },
    {
      "id": 6302,
      "name": "Mityana",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4187016,
        "lng": 32.0481001
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.0431001,
        0.4137016,
        32.0531001,
        0.4237016
      ]
    },
    {
      "id": 6304,
      "name": "Hodmezovasarhely",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.422786,
        "lng": 20.325999
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        20.295129,
        46.399762,
        20.37646,
        46.441089
      ]
    },
    {
      "id": 6305,
      "name": "Kampong Chhnang",
      "names": {
        "th": "กม. ชนัง"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.1382025,
        "lng": 104.3354098
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.1710946,
        11.7344035,
        104.9019904,
        12.6074435
      ]
    },
    {
      "id": 6309,
      "name": "Juan Rodriguez Clara",
      "names": {
        "ja": "Juan Rodriguezクララ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.00414425,
        "lng": -95.40131348392295
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.4013742,
        18.0040837,
        -95.4012468,
        18.0042074
      ]
    },
    {
      "id": 6313,
      "name": "Beverwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4857561,
        "lng": 4.6578632
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6263411,
        52.4555204,
        4.7079824,
        52.501744
      ]
    },
    {
      "id": 6314,
      "name": "Armavir",
      "names": {
        "zh": "阿尔马维尔"
      },
      "countryCode": "AM",
      "latLng": {
        "lat": 40.1555485,
        "lng": 44.0387565
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        44.0126118,
        40.1383488,
        44.0796074,
        40.1737129
      ]
    },
    {
      "id": 6315,
      "name": "Minas",
      "names": {
        "zh": "矿业",
        "ja": "ミナス",
        "th": "มินัส"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.3773987,
        "lng": -55.2382144
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -55.2859497,
        -34.4035131,
        -55.2047995,
        -34.3495183
      ]
    },
    {
      "id": 6316,
      "name": "Franceville",
      "names": {
        "zh": "弗朗斯维尔"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -1.6343979,
        "lng": 13.5894808
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        13.4294808,
        -1.7943979,
        13.7494808,
        -1.4743979
      ]
    },
    {
      "id": 6319,
      "name": "Garbahaarrey",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 3.334635,
        "lng": 42.2232
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        42.22315,
        3.334585,
        42.22325,
        3.334685
      ]
    },
    {
      "id": 6325,
      "name": "Dubrovnik",
      "names": {
        "zh": "杜布罗夫尼克",
        "ja": "ドブロブニク",
        "th": "ดูบรอฟนิก"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 42.6403879,
        "lng": 18.1095471
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        18.1094971,
        42.6403379,
        18.1095971,
        42.6404379
      ]
    },
    {
      "id": 6326,
      "name": "Ciudad Melchor Muzquiz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 27.8787547,
        "lng": -101.5159425
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.5559425,
        27.8387547,
        -101.4759425,
        27.9187547
      ]
    },
    {
      "id": 6328,
      "name": "Quinhamel",
      "names": {},
      "countryCode": "GW",
      "latLng": {
        "lat": 11.88333,
        "lng": -15.85
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -15.87,
        11.86333,
        -15.83,
        11.90333
      ]
    },
    {
      "id": 6330,
      "name": "Daman",
      "names": {
        "zh": "达曼",
        "ja": "ダマン",
        "th": "ดามัน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.4169701,
        "lng": 72.833173
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        72.673173,
        20.2569701,
        72.993173,
        20.5769701
      ]
    },
    {
      "id": 6333,
      "name": "Tonala",
      "names": {
        "zh": "托纳拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.0894476,
        "lng": -93.7546502
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.7946502,
        16.0494476,
        -93.7146502,
        16.1294476
      ]
    },
    {
      "id": 6335,
      "name": "Guarda",
      "names": {
        "zh": "瓜达",
        "th": "กวาร์ดา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5378408,
        "lng": -7.2662964
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -7.467313,
        40.3806077,
        -7.0327437,
        40.7009638
      ]
    },
    {
      "id": 6336,
      "name": "Si Sa Ket",
      "names": {
        "fr": "Si SA SA KET",
        "ja": "SAケット",
        "th": "ศรีสะเกษ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1144087,
        "lng": 104.328868
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        104.288868,
        15.0744087,
        104.368868,
        15.1544087
      ]
    },
    {
      "id": 6339,
      "name": "Fribourg",
      "names": {
        "zh": "弗里堡",
        "de": "Freiburg",
        "ja": "friborg",
        "th": "ฟรีบูร์ก"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.802961,
        "lng": 7.1510169
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        7.1460169,
        46.797961,
        7.1560169,
        46.807961
      ]
    },
    {
      "id": 6340,
      "name": "Kampot",
      "names": {
        "zh": "贡布"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.6270116,
        "lng": 104.1891122
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.1841122,
        10.6220116,
        104.1941122,
        10.6320116
      ]
    },
    {
      "id": 6341,
      "name": "Miercurea-Ciuc",
      "names": {
        "fr": "-Miercurea ciuc"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.3712525,
        "lng": 25.7725144
      },
      "country": "Romania",
      "importance": 1,
      "bbox": [
        25.7724644,
        46.3712025,
        25.7725644,
        46.3713025
      ]
    },
    {
      "id": 6344,
      "name": "Berat",
      "names": {
        "zh": "贝拉"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 40.7018636,
        "lng": 19.9580758
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        19.9335263,
        40.6810176,
        20.0183877,
        40.7288895
      ]
    },
    {
      "id": 6346,
      "name": "Myrhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.9658333,
        "lng": 33.6113889
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.5537072,
        49.9151624,
        33.6646411,
        49.9985444
      ]
    },
    {
      "id": 6347,
      "name": "Bambari",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 5.7680014,
        "lng": 20.6755705
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        20.5155705,
        5.6080014,
        20.8355705,
        5.9280014
      ]
    },
    {
      "id": 6352,
      "name": "Cao Bang",
      "names": {
        "fr": "Caro bang",
        "ja": "カオバン",
        "th": "เฉาปัง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.66667,
        "lng": 106.25
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.23,
        22.64667,
        106.27,
        22.68667
      ]
    },
    {
      "id": 6353,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": 9.631341800000001,
        "lng": -84.06729890556566
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -84.5926798,
        9.074894,
        -83.429378,
        10.1878506
      ]
    },
    {
      "id": 6356,
      "name": "Xico",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4227072,
        "lng": -97.0084032
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0484032,
        19.3827072,
        -96.9684032,
        19.4627072
      ]
    },
    {
      "id": 6362,
      "name": "Douai",
      "names": {
        "zh": "杜埃"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.373162,
        "lng": 3.08258
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.051561,
        50.349426,
        3.149442,
        50.410532
      ]
    },
    {
      "id": 6368,
      "name": "Puntarenas",
      "names": {
        "zh": "蓬塔雷纳斯",
        "th": "ปันตาเรนาส"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.9773343,
        "lng": -84.8346402
      },
      "country": "Costa Rica",
      "importance": 1,
      "bbox": [
        -85.050391,
        9.9225249,
        -84.7359817,
        10.0481638
      ]
    },
    {
      "id": 6370,
      "name": "Narathiwat",
      "names": {
        "zh": "那拉提瓦",
        "th": "นราธิวาส"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.422195,
        "lng": 101.819384
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.71887,
        6.300803,
        101.857787,
        6.569968
      ]
    },
    {
      "id": 6374,
      "name": "Mambajao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.2504,
        "lng": 124.7156
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.6956,
        9.2304,
        124.7356,
        9.2704
      ]
    },
    {
      "id": 6376,
      "name": "Zacatelco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.215833,
        "lng": -98.239722
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.2608258,
        19.174356,
        -98.230237,
        19.2327011
      ]
    },
    {
      "id": 6377,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.1284089,
        "lng": -87.96152764673405
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -88.2368566,
        13.8301656,
        -87.5911386,
        14.4267732
      ]
    },
    {
      "id": 6382,
      "name": "Lusambo",
      "names": {
        "zh": "卢桑博"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -4.9736384,
        "lng": 23.4432792
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        23.4032792,
        -5.0136384,
        23.4832792,
        -4.9336384
      ]
    },
    {
      "id": 6383,
      "name": "Kars",
      "names": {
        "zh": "卡尔斯",
        "ja": "カルス"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6076749,
        "lng": 43.0948497
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        42.8001659,
        40.3115848,
        43.7065459,
        40.8180681
      ]
    },
    {
      "id": 6384,
      "name": "Sayula de Aleman",
      "names": {
        "es": "Sayula de Alemán",
        "zh": "Sayula de Alman"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8813234,
        "lng": -94.9601055
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.0001055,
        17.8413234,
        -94.9201055,
        17.9213234
      ]
    },
    {
      "id": 6385,
      "name": "Villarrica",
      "names": {
        "zh": "比亚里卡",
        "th": "บียาร์รีกา"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.7809839,
        "lng": -56.4478765
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -56.4707414,
        -25.8030232,
        -56.4241352,
        -25.7621993
      ]
    },
    {
      "id": 6386,
      "name": "Ngozi",
      "names": {
        "zh": "恩戈齐"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -2.909091,
        "lng": 29.8230442
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.6630442,
        -3.069091,
        29.9830442,
        -2.749091
      ]
    },
    {
      "id": 6387,
      "name": "Xam Nua",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 20.4151514,
        "lng": 104.047364
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        104.007364,
        20.3751514,
        104.087364,
        20.4551514
      ]
    },
    {
      "id": 6389,
      "name": "Ameca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5486695,
        "lng": -104.0470901
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.0870901,
        20.5086695,
        -104.0070901,
        20.5886695
      ]
    },
    {
      "id": 6390,
      "name": "Kisii",
      "names": {
        "zh": "基西"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.6694119,
        "lng": 34.7677944
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.7296491,
        -0.698912,
        34.7937924,
        -0.6537472
      ]
    },
    {
      "id": 6394,
      "name": "Kozani",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.3007259,
        "lng": 21.7883119
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        21.6283119,
        40.1407259,
        21.9483119,
        40.4607259
      ]
    },
    {
      "id": 6397,
      "name": "Malbork",
      "names": {
        "zh": "马尔堡"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0350506,
        "lng": 19.0453215
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0452715,
        54.0350006,
        19.0453715,
        54.0351006
      ]
    },
    {
      "id": 6399,
      "name": "Oshakati",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.78333,
        "lng": 15.68333
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        15.66333,
        -17.80333,
        15.70333,
        -17.76333
      ]
    },
    {
      "id": 6400,
      "name": "Panaji",
      "names": {
        "zh": "帕纳吉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.47706,
        "lng": 73.818561
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        73.817134,
        15.490774,
        73.83523,
        15.501922
      ]
    },
    {
      "id": 6402,
      "name": "Famagusta",
      "names": {
        "fr": "Famagouste",
        "zh": "法马古斯塔",
        "it": "Famagosta"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 35.1205261,
        "lng": 33.9387919
      },
      "country": "Cyprus",
      "importance": 1,
      "bbox": [
        33.8821485,
        35.0782109,
        33.9829997,
        35.1604793
      ]
    },
    {
      "id": 6403,
      "name": "Romblon",
      "names": {
        "zh": "朗布隆"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5758425,
        "lng": 122.2699465
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.1801484,
        12.3430869,
        122.4409919,
        12.8063068
      ]
    },
    {
      "id": 6404,
      "name": "Puerto Francisco de Orellana",
      "names": {
        "ja": "プエルトフランシスコデオレリーナ",
        "th": "เปอร์โตฟรานซิสโกเดอออเรเซลน่า"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.4721804,
        "lng": -76.9837216
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -77.0828666,
        -0.4996428,
        -76.8955406,
        -0.3749199
      ]
    },
    {
      "id": 6405,
      "name": "Zacatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.934396,
        "lng": -97.9604026
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.0004026,
        19.894396,
        -97.9204026,
        19.974396
      ]
    },
    {
      "id": 6409,
      "name": "Shengli",
      "names": {
        "zh": "胜利"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2246179,
        "lng": 107.5307262
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.4907262,
        32.1846179,
        107.5707262,
        32.2646179
      ]
    },
    {
      "id": 6410,
      "name": "Cananea",
      "names": {
        "zh": "卡纳内阿"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.985789,
        "lng": -110.289184
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.317982,
        30.963981,
        -110.251507,
        31.012881
      ]
    },
    {
      "id": 6411,
      "name": "Al Bayda'",
      "names": {
        "es": "Al Bayda '",
        "fr": "Al Bayda '",
        "de": "Al Bayda '",
        "it": "Al bayda '",
        "id": "Al Bayda '",
        "ja": "アルベイダ '",
        "th": "Al Bayda '",
        "pt": "Al bayda '"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.9880512,
        "lng": 45.5729268
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        45.5329268,
        13.9480512,
        45.6129268,
        14.0280512
      ]
    },
    {
      "id": 6413,
      "name": "Samkir",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.82972,
        "lng": 46.01889
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.99889,
        40.80972,
        46.03889,
        40.84972
      ]
    },
    {
      "id": 6414,
      "name": "Chartres",
      "names": {
        "zh": "沙特尔",
        "ja": "シャルトル",
        "th": "ชาตร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4484362,
        "lng": 1.480857
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.475857,
        48.4434362,
        1.485857,
        48.4534362
      ]
    },
    {
      "id": 6417,
      "name": "Parnu",
      "names": {
        "zh": "派尔努",
        "de": "Pärnu"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.3835136,
        "lng": 24.5081751
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        24.4512342,
        58.3416113,
        24.6044385,
        58.4163889
      ]
    },
    {
      "id": 6419,
      "name": "Dun Dealgan",
      "names": {
        "zh": "DUN DENAGAN",
        "it": "Dun deangan",
        "ja": "Dun Deargan",
        "pt": "Dealdade dun"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 54.0046033,
        "lng": -6.4008879
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.4443775,
        53.9505541,
        -6.3415418,
        54.0279323
      ]
    },
    {
      "id": 6420,
      "name": "Mariano Escobedo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9127909,
        "lng": -97.1301041
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.2552655,
        18.8713439,
        -97.0982955,
        18.9808009
      ]
    },
    {
      "id": 6422,
      "name": "Bjelovar",
      "names": {
        "zh": "别洛瓦尔"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8986869,
        "lng": 16.8421977
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        16.6925455,
        45.8184997,
        16.9640904,
        45.9638007
      ]
    },
    {
      "id": 6425,
      "name": "Yambio",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 4.5680716,
        "lng": 28.39767
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        28.23767,
        4.4080716,
        28.55767,
        4.7280716
      ]
    },
    {
      "id": 6426,
      "name": "Bouar",
      "names": {
        "zh": "布阿尔"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 5.9354018,
        "lng": 15.5921894
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        15.4321894,
        5.7754018,
        15.7521894,
        6.0954018
      ]
    },
    {
      "id": 6427,
      "name": "Montana",
      "names": {
        "zh": "蒙大拿",
        "ja": "モンタナ",
        "th": "มอนแทนา"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.4088984,
        "lng": 23.2203193
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        23.0603193,
        43.2488984,
        23.3803193,
        43.5688984
      ]
    },
    {
      "id": 6430,
      "name": "El Golea",
      "names": {
        "ja": "エルゴレア"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 30.5836683,
        "lng": 2.8830889
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.0236368,
        30.5400881,
        4.256356,
        32.19268
      ]
    },
    {
      "id": 6431,
      "name": "Swords",
      "names": {
        "es": "Espadas",
        "fr": "Épées",
        "zh": "剑",
        "de": "Schwerter",
        "it": "Spade",
        "id": "Pedang",
        "ja": "剣",
        "th": "ดาบ",
        "pt": "Espadas"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.4594799,
        "lng": -6.219175
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.2691866,
        53.4409749,
        -6.1951061,
        53.4779747
      ]
    },
    {
      "id": 6432,
      "name": "Inongo",
      "names": {
        "zh": "伊农戈"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -1.9275,
        "lng": 18.2881
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        18.2681,
        -1.9475,
        18.3081,
        -1.9075
      ]
    },
    {
      "id": 6433,
      "name": "Villefranche-sur-Saone",
      "names": {
        "fr": "Villefranche-sur-Saône",
        "zh": "自由城索恩河畔",
        "ja": "villefrancheシュルsaone",
        "th": "villefranche-ซูร์-saone"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.984526,
        "lng": 4.7211003
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.7161003,
        45.979526,
        4.7261003,
        45.989526
      ]
    },
    {
      "id": 6436,
      "name": "Retalhuleu",
      "names": {
        "zh": "雷塔卢莱乌"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5359621,
        "lng": -91.6780651
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -92.1629897,
        14.1902457,
        -91.6548348,
        14.5894113
      ]
    },
    {
      "id": 6437,
      "name": "Chachoengsao",
      "names": {
        "th": "ฉะเชิงเทรา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6891784,
        "lng": 101.0708268
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.0308268,
        13.6491784,
        101.1108268,
        13.7291784
      ]
    },
    {
      "id": 6438,
      "name": "Phonsavan",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 19.45189,
        "lng": 103.206607
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        103.046607,
        19.29189,
        103.366607,
        19.61189
      ]
    },
    {
      "id": 6439,
      "name": "Xacmaz",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.4655339,
        "lng": 48.8011599
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.773278,
        41.4399425,
        48.8350279,
        41.4767727
      ]
    },
    {
      "id": 6440,
      "name": "Brcko",
      "names": {
        "zh": "布尔奇科"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.8771222,
        "lng": 18.8095022
      },
      "country": "Bosnia And Herzegovina",
      "importance": 1,
      "bbox": [
        18.7559581,
        44.8410818,
        18.8609461,
        44.8984735
      ]
    },
    {
      "id": 6441,
      "name": "Targovishte",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.251196,
        "lng": 26.5727944
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        26.4816567,
        43.1845676,
        26.6497037,
        43.3053623
      ]
    },
    {
      "id": 6443,
      "name": "Koumra",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 8.91256,
        "lng": 17.55392
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        17.53392,
        8.89256,
        17.57392,
        8.93256
      ]
    },
    {
      "id": 6446,
      "name": "Surin",
      "names": {
        "zh": "苏林海滩",
        "ja": "スリン",
        "th": "สุรินทร์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8841804,
        "lng": 103.4906232
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        103.4506232,
        14.8441804,
        103.5306232,
        14.9241804
      ]
    },
    {
      "id": 6447,
      "name": "Jordan",
      "names": {
        "es": "Jordania",
        "fr": "Jordanie",
        "zh": "约旦",
        "de": "Jordanien",
        "it": "Giordania",
        "id": "Yordania",
        "ja": "ヨルダン",
        "th": "จอร์แดน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6611675,
        "lng": 122.5909592
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        122.4078085,
        10.5378127,
        122.6356296,
        10.6910595
      ]
    },
    {
      "id": 6450,
      "name": "Massawa",
      "names": {
        "zh": "马萨瓦"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.61260265,
        "lng": 39.44477849256274
      },
      "country": "Eritrea",
      "importance": 1,
      "bbox": [
        39.4176208,
        15.5953465,
        39.4560159,
        15.6298444
      ]
    },
    {
      "id": 6454,
      "name": "Santa Elena",
      "names": {
        "zh": "圣艾琳娜",
        "ja": "サンタエレナ",
        "th": "ซานตาเอเลน่า"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.2270098,
        "lng": -80.8576945
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -80.8866416,
        -2.3254796,
        -80.5520031,
        -2.1081389
      ]
    },
    {
      "id": 6456,
      "name": "Mochudi",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -24.41667,
        "lng": 26.15
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        26.13,
        -24.43667,
        26.17,
        -24.39667
      ]
    },
    {
      "id": 6462,
      "name": "Sopot",
      "names": {
        "zh": "索波特",
        "ja": "ソポト",
        "th": "โซพอต"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.4400543,
        "lng": 18.5623213
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.5573213,
        54.4350543,
        18.5673213,
        54.4450543
      ]
    },
    {
      "id": 6465,
      "name": "Touggourt",
      "names": {
        "zh": "图古尔特"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.1048574,
        "lng": 6.0667276
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.7568191,
        33.0782995,
        6.0797409,
        33.1770796
      ]
    },
    {
      "id": 6466,
      "name": "Miahuatlan de Porfirio Diaz",
      "names": {
        "es": "Miahuatlan de Porfirio Díaz",
        "id": "MAHAATLAN DE PORFIRIO DIAZ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.329099,
        "lng": -96.595688
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.6248336599,
        16.3090622942,
        -96.5709009987,
        16.353436448
      ]
    },
    {
      "id": 6467,
      "name": "Florida",
      "names": {
        "fr": "Floride",
        "zh": "佛罗里达",
        "ja": "フロリダ州",
        "th": "ฟลอริดา",
        "pt": "Flórida"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.101363,
        "lng": -56.214752
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -56.2431392,
        -34.1154515,
        -56.2027493,
        -34.0763711
      ]
    },
    {
      "id": 6468,
      "name": "Celje",
      "names": {
        "zh": "采列"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2293889,
        "lng": 15.2616828
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2093146,
        46.1885089,
        15.3529809,
        46.321041
      ]
    },
    {
      "id": 6470,
      "name": "L'Hay-les-Roses",
      "names": {
        "es": "L'hay-les-Rosas",
        "zh": "欧莱雅干草-莱斯-玫瑰",
        "de": "L'Heu-Les-Rosen",
        "ja": "のl'ヘイ・レ・バラ",
        "th": "แมงหญ้าแห้ง-เลส์-กุหลาบ",
        "pt": "L'feno-Les-Rosas"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7790514,
        "lng": 2.3373021
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.3158833,
        48.7643412,
        2.3673481,
        48.7911022
      ]
    },
    {
      "id": 6472,
      "name": "Panuco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.0563024,
        "lng": -98.18282
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.22282,
        22.0163024,
        -98.14282,
        22.0963024
      ]
    },
    {
      "id": 6475,
      "name": "Agcabadi",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.050544,
        "lng": 47.4607462
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.4056786,
        40.0211087,
        47.4903746,
        40.0754854
      ]
    },
    {
      "id": 6479,
      "name": "Annapolis",
      "names": {
        "zh": "安纳波利斯",
        "ja": "アナポリス",
        "th": "แอนนาโปลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9786401,
        "lng": -76.492786
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -76.5395832,
        38.9424993,
        -76.4686762,
        39.0025548
      ]
    },
    {
      "id": 6481,
      "name": "Durazno",
      "names": {
        "zh": "杜拉斯诺",
        "th": "ดูราซโน"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -33.3804517,
        "lng": -56.5224396
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -56.5519094,
        -33.4038226,
        -56.4919533,
        -33.3583978
      ]
    },
    {
      "id": 6487,
      "name": "Tromso",
      "names": {
        "es": "Tromsø",
        "zh": "特罗姆瑟",
        "th": "ทรอมโซ",
        "pt": "Tromsø"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 69.6516345,
        "lng": 18.9558585
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        17.1600465,
        69.2820889,
        20.0436387,
        70.1684167
      ]
    },
    {
      "id": 6488,
      "name": "Zinacantan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7604118,
        "lng": -92.7217949
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.7617949,
        16.7204118,
        -92.6817949,
        16.8004118
      ]
    },
    {
      "id": 6489,
      "name": "Frankfort",
      "names": {
        "fr": "Francfort",
        "zh": "法兰克福",
        "de": "Frankfurt",
        "th": "แฟรงก์เฟิร์ต",
        "pt": "Frankfurt"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2009055,
        "lng": -84.8732836
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -84.9214539,
        38.157841,
        -84.809606,
        38.23105
      ]
    },
    {
      "id": 6492,
      "name": "Esch-sur-Alzette",
      "names": {
        "zh": "埃施畔埃施",
        "ja": "eschシュルalzette",
        "th": "esch-ซูร์-alzette"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.4959628,
        "lng": 5.9850306
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        5.9432149,
        49.4609064,
        6.005144,
        49.5186183
      ]
    },
    {
      "id": 6493,
      "name": "Tuyen Quang",
      "names": {
        "th": "ตุ๋นคาง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.82333,
        "lng": 105.21806
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.19806,
        21.80333,
        105.23806,
        21.84333
      ]
    },
    {
      "id": 6494,
      "name": "Vrilissia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.03916,
        "lng": 23.83736
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.73736,
        37.93916,
        23.93736,
        38.13916
      ]
    },
    {
      "id": 6495,
      "name": "Palaiseau",
      "names": {
        "zh": "帕莱索"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7144587,
        "lng": 2.2453842
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.19174,
        48.700804,
        2.2668688,
        48.7300913
      ]
    },
    {
      "id": 6496,
      "name": "Ha Giang",
      "names": {
        "zh": "哈广亮",
        "ja": "ハハガン",
        "th": "ฮ่า ๆ ๆ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.8264592,
        "lng": 104.9846342
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        104.8643472,
        22.7549203,
        105.0750551,
        22.8789506
      ]
    },
    {
      "id": 6497,
      "name": "Barda",
      "names": {
        "zh": "巴尔达"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3812457,
        "lng": 47.1246287
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.1107932,
        40.3445047,
        47.147833,
        40.3976682
      ]
    },
    {
      "id": 6498,
      "name": "Kline",
      "names": {
        "zh": "克莱恩"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6179925,
        "lng": 20.57611
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.53611,
        42.5779925,
        20.61611,
        42.6579925
      ]
    },
    {
      "id": 6505,
      "name": "Bucha",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5394389,
        "lng": 30.2372064
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.2318529,
        50.5378632,
        30.242803,
        50.5395297
      ]
    },
    {
      "id": 6509,
      "name": "Tomatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9429848,
        "lng": -105.2465108
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.2865108,
        19.9029848,
        -105.2065108,
        19.9829848
      ]
    },
    {
      "id": 6510,
      "name": "Escuinapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.834373,
        "lng": -105.777778
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.797607,
        22.816141,
        -105.76164,
        22.851384
      ]
    },
    {
      "id": 6511,
      "name": "Choybalsan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.0688183,
        "lng": 114.5241352
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        114.4117856,
        47.9812368,
        114.7421164,
        48.1337751
      ]
    },
    {
      "id": 6513,
      "name": "Tenosique",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4707129,
        "lng": -91.4220212
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.4390908,
        17.451285,
        -91.406845,
        17.4835806
      ]
    },
    {
      "id": 6515,
      "name": "Am-Timan",
      "names": {
        "fr": "Suis-timan",
        "zh": "上午timan",
        "ja": "午前-timan",
        "th": "กำลัง-timan"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 11.0368396,
        "lng": 20.2841498
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        20.2741498,
        11.0268396,
        20.2941498,
        11.0468396
      ]
    },
    {
      "id": 6519,
      "name": "Trujillo",
      "names": {
        "zh": "特鲁希略",
        "ja": "トルヒーヨ",
        "th": "ตรูฮีโย"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.49581285,
        "lng": -70.76889069529457
      },
      "country": "Venezuela",
      "importance": 1,
      "bbox": [
        -71.4567087,
        8.9519415,
        -70.0344569,
        10.0397465
      ]
    },
    {
      "id": 6520,
      "name": "Kranj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.240372,
        "lng": 14.358032
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.328355,
        46.203868,
        14.398582,
        46.253524
      ]
    },
    {
      "id": 6522,
      "name": "Brzeg",
      "names": {
        "zh": "布热格"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8614882,
        "lng": 17.470497
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.4181447,
        50.8388529,
        17.5075463,
        50.8789005
      ]
    },
    {
      "id": 6523,
      "name": "Kikinda",
      "names": {
        "zh": "基金达"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.8254872,
        "lng": 20.4573527
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.4573027,
        45.8254372,
        20.4574027,
        45.8255372
      ]
    },
    {
      "id": 6526,
      "name": "La Huacana",
      "names": {
        "ja": "ラ・ハカナ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.962757,
        "lng": -101.80471
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.81812,
        18.951119,
        -101.792081,
        18.977499
      ]
    },
    {
      "id": 6527,
      "name": "Ali Sabieh",
      "names": {
        "zh": "阿里·萨比海",
        "ja": "アリ・サビエ",
        "th": "อาลี sabieh"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.1560462,
        "lng": 42.7045067
      },
      "country": "Djibouti",
      "importance": 1,
      "bbox": [
        42.6873187,
        11.1259283,
        42.7307887,
        11.1661685
      ]
    },
    {
      "id": 6531,
      "name": "Paphos",
      "names": {
        "zh": "帕福斯",
        "ja": "パフォス",
        "th": "ปาฟอส"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 34.773856,
        "lng": 32.427452
      },
      "country": "Cyprus",
      "importance": 1,
      "bbox": [
        32.400425,
        34.734686,
        32.4546,
        34.803954
      ]
    },
    {
      "id": 6532,
      "name": "Karonga",
      "names": {
        "zh": "卡龙加"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -9.9393499,
        "lng": 33.9271368
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.8871368,
        -9.9793499,
        33.9671368,
        -9.8993499
      ]
    },
    {
      "id": 6535,
      "name": "Reforma",
      "names": {
        "zh": "改革",
        "ja": "レフォルマ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8581003,
        "lng": -93.155565
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.195565,
        17.8181003,
        -93.115565,
        17.8981003
      ]
    },
    {
      "id": 6538,
      "name": "Kajaani",
      "names": {
        "zh": "卡亚尼"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.2240872,
        "lng": 27.7334227
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        26.7483823,
        63.8447118,
        28.1580346,
        64.365181
      ]
    },
    {
      "id": 6539,
      "name": "Salto del Guaira",
      "names": {
        "zh": "萨尔托德尔·雷拉"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -24.056639,
        "lng": -54.33420666103485
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -54.3950601,
        -24.1121141,
        -54.2946032,
        -24.0016113
      ]
    },
    {
      "id": 6540,
      "name": "Villagran",
      "names": {
        "pt": "Villagrán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.515028,
        "lng": -100.997062
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.011258,
        20.508321,
        -100.98146,
        20.524559
      ]
    },
    {
      "id": 6541,
      "name": "Soteapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2325,
        "lng": -94.872778
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.912778,
        18.1925,
        -94.832778,
        18.2725
      ]
    },
    {
      "id": 6545,
      "name": "Schaffhausen",
      "names": {
        "zh": "沙夫豪森",
        "ja": "シャフハウゼン",
        "th": "ชาฟฟ์เฮาเซิน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.6960491,
        "lng": 8.634513
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.5410039,
        47.6853033,
        8.7059374,
        47.7530848
      ]
    },
    {
      "id": 6546,
      "name": "Nanchital de Lazaro Cardenas del Rio",
      "names": {
        "es": "Nanchital de Lázaro Cardenas del Rio",
        "fr": "Nancital de Lazaro Cardenas Del Rio",
        "zh": "南北德拉扎罗卡诺斯德尔里奥",
        "ja": "ナンチャイタルデラザロカルデナスデルリオ",
        "pt": "Nancital de Lazaro Cardenas del Rio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.0702678,
        "lng": -94.4069757
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.4469757,
        18.0302678,
        -94.3669757,
        18.1102678
      ]
    },
    {
      "id": 6550,
      "name": "Tozeur",
      "names": {
        "zh": "托泽尔"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.9239001,
        "lng": 8.1370639
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        8.0970639,
        33.8839001,
        8.1770639,
        33.9639001
      ]
    },
    {
      "id": 6552,
      "name": "Thonon-les-Bains",
      "names": {
        "zh": "托农-莱斯班",
        "ja": "トノン・レ・バン",
        "th": "thonon-เลสแบงส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3688376,
        "lng": 6.4816606
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.4816106,
        46.3687876,
        6.4817106,
        46.3688876
      ]
    },
    {
      "id": 6554,
      "name": "`Ataq",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.53767,
        "lng": 46.83187
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        46.81187,
        14.51767,
        46.85187,
        14.55767
      ]
    },
    {
      "id": 6557,
      "name": "Bossangoa",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 6.4924441,
        "lng": 17.4463162
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        17.2863162,
        6.3324441,
        17.6063162,
        6.6524441
      ]
    },
    {
      "id": 6558,
      "name": "Abasolo",
      "names": {
        "zh": "阿瓦索洛"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4492554,
        "lng": -101.529147
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.569147,
        20.4092554,
        -101.489147,
        20.4892554
      ]
    },
    {
      "id": 6561,
      "name": "Putla Villa de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.028529,
        "lng": -97.92782
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.936714,
        17.011533,
        -97.911803,
        17.042798
      ]
    },
    {
      "id": 6565,
      "name": "Prijepolje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.392907,
        "lng": 19.64031865
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.6401457,
        43.392707,
        19.6404916,
        43.393107
      ]
    },
    {
      "id": 6566,
      "name": "Al Qunaytirah",
      "names": {
        "ja": "アルキーティラ",
        "th": "อัล qunaytirah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.1246885,
        "lng": 35.824942
      },
      "country": "Syria",
      "importance": 1,
      "bbox": [
        35.804942,
        33.1046885,
        35.844942,
        33.1446885
      ]
    },
    {
      "id": 6568,
      "name": "Chaiyaphum",
      "names": {
        "th": "ชัยภูมิ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8068082,
        "lng": 102.0315003
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.9915003,
        15.7668082,
        102.0715003,
        15.8468082
      ]
    },
    {
      "id": 6573,
      "name": "Falun",
      "names": {
        "es": "Los practicantes de Falun",
        "zh": "法轮",
        "ja": "ファールン",
        "th": "ฝ่าหลุน"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 60.6070068,
        "lng": 15.6323059
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        15.4723059,
        60.4470068,
        15.7923059,
        60.7670068
      ]
    },
    {
      "id": 6574,
      "name": "Ometepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.690457,
        "lng": -98.411536
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.43862,
        16.672215,
        -98.390055,
        16.70755
      ]
    },
    {
      "id": 6577,
      "name": "Parras de la Fuente",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4436602,
        "lng": -102.1782179
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.2182179,
        25.4036602,
        -102.1382179,
        25.4836602
      ]
    },
    {
      "id": 6579,
      "name": "Ratchaburi",
      "names": {
        "th": "ราชบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.539067,
        "lng": 99.8197035
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.7797035,
        13.499067,
        99.8597035,
        13.579067
      ]
    },
    {
      "id": 6580,
      "name": "Paso del Macho",
      "names": {
        "ja": "パソ・デルマッチョ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9705745,
        "lng": -96.7229408
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.7629408,
        18.9305745,
        -96.6829408,
        19.0105745
      ]
    },
    {
      "id": 6581,
      "name": "Paso de Ovejas",
      "names": {
        "ja": "Paso deovejas"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2841816,
        "lng": -96.4406657
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.4806657,
        19.2441816,
        -96.4006657,
        19.3241816
      ]
    },
    {
      "id": 6583,
      "name": "Karlskrona",
      "names": {
        "zh": "卡尔斯克鲁纳"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.1621073,
        "lng": 15.5866422
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        15.4266422,
        56.0021073,
        15.7466422,
        56.3221073
      ]
    },
    {
      "id": 6585,
      "name": "Zapotiltic",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.626903,
        "lng": -103.415928
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.415978,
        19.626853,
        -103.415878,
        19.626953
      ]
    },
    {
      "id": 6588,
      "name": "Ebebiyin",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 2.15106,
        "lng": 11.33528
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        11.31528,
        2.13106,
        11.35528,
        2.17106
      ]
    },
    {
      "id": 6589,
      "name": "Calpulalpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.587778,
        "lng": -98.569167
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.5880854,
        19.5644507,
        -98.5396921,
        19.6004169
      ]
    },
    {
      "id": 6594,
      "name": "Goycay",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.6494415,
        "lng": 47.7412208
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.7171854,
        40.6329995,
        47.7603471,
        40.6695076
      ]
    },
    {
      "id": 6601,
      "name": "Chur",
      "names": {
        "zh": "库尔",
        "th": "คูร์"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8535563,
        "lng": 9.5296459
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        9.5246459,
        46.8485563,
        9.5346459,
        46.8585563
      ]
    },
    {
      "id": 6602,
      "name": "Adjumani",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.3769442,
        "lng": 31.7905912
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.7505912,
        3.3369442,
        31.8305912,
        3.4169442
      ]
    },
    {
      "id": 6604,
      "name": "San Juan Evangelista",
      "names": {
        "zh": "圣胡安Evangelista",
        "ja": "サンファンエヴァンエリスタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8859417,
        "lng": -95.1395853
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.1795853,
        17.8459417,
        -95.0995853,
        17.9259417
      ]
    },
    {
      "id": 6605,
      "name": "Amecameca de Juarez",
      "names": {
        "ja": "アメカメカデュレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1249264,
        "lng": -98.7664606
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8064606,
        19.0849264,
        -98.7264606,
        19.1649264
      ]
    },
    {
      "id": 6609,
      "name": "Krong Kep",
      "names": {
        "id": "Krong kepe",
        "ja": "クローゴケプ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.4829,
        "lng": 104.31672
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.29672,
        10.4629,
        104.33672,
        10.5029
      ]
    },
    {
      "id": 6610,
      "name": "Kamenice",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.5783818,
        "lng": 21.5796877
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.5396877,
        42.5383818,
        21.6196877,
        42.6183818
      ]
    },
    {
      "id": 6614,
      "name": "Byumba",
      "names": {
        "zh": "温巴"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.5792052,
        "lng": 30.0677684
      },
      "country": "Rwanda",
      "importance": 1,
      "bbox": [
        29.9422333,
        -1.8461507,
        30.2799648,
        -1.3824844
      ]
    },
    {
      "id": 6615,
      "name": "Roanne",
      "names": {
        "zh": "罗阿讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.0345572,
        "lng": 4.0729178
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.0467298,
        46.0180789,
        4.1138002,
        46.0714333
      ]
    },
    {
      "id": 6616,
      "name": "Bartin",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.6346684,
        "lng": 32.3366082
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        32.0949082,
        41.3029432,
        32.867821,
        41.789361
      ]
    },
    {
      "id": 6617,
      "name": "Viborg",
      "names": {
        "zh": "维堡"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 56.4471382,
        "lng": 9.3984137
      },
      "country": "Denmark",
      "importance": 1,
      "bbox": [
        9.3934137,
        56.4421382,
        9.4034137,
        56.4521382
      ]
    },
    {
      "id": 6619,
      "name": "Gostivar",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7964482,
        "lng": 20.9172791
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.9122791,
        41.7914482,
        20.9222791,
        41.8014482
      ]
    },
    {
      "id": 6622,
      "name": "Beja",
      "names": {
        "zh": "贝雅"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.0154479,
        "lng": -7.8650368
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -8.083151,
        37.7764606,
        -7.6326306,
        38.1625868
      ]
    },
    {
      "id": 6623,
      "name": "Agen",
      "names": {
        "zh": "阿根",
        "ja": "アジャン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.20824115,
        "lng": 0.6208197347026301
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.6202254,
        44.2080942,
        0.6215719,
        44.2083667
      ]
    },
    {
      "id": 6625,
      "name": "Vrsac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.1211263,
        "lng": 21.2960485
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.2335895,
        44.9987604,
        21.3966003,
        45.2196452
      ]
    },
    {
      "id": 6628,
      "name": "Maassluis",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9160869,
        "lng": 4.245702
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.245652,
        51.9160369,
        4.245752,
        51.9161369
      ]
    },
    {
      "id": 6634,
      "name": "Chuhuiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.83588,
        "lng": 36.68803
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.66803,
        49.81588,
        36.70803,
        49.85588
      ]
    },
    {
      "id": 6635,
      "name": "Usak",
      "names": {
        "zh": "乌沙克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6740401,
        "lng": 29.4058419
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        28.822334,
        38.4568682,
        29.6393665,
        38.9046768
      ]
    },
    {
      "id": 6636,
      "name": "Aosta",
      "names": {
        "fr": "Aoste",
        "zh": "奥斯塔",
        "ja": "アオスタ",
        "th": "ออสตา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.7370885,
        "lng": 7.3196649
      },
      "country": "Italy",
      "importance": 1,
      "bbox": [
        7.2386238,
        45.7262577,
        7.347951,
        45.7726487
      ]
    },
    {
      "id": 6639,
      "name": "Timbuktu",
      "names": {
        "es": "Tombuctú",
        "fr": "Tombouctou",
        "zh": "廷巴克图",
        "ja": "トンブクトゥ"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 16.209717050000002,
        "lng": -3.0281184216332506
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -5.6334771,
        15.0659532,
        -0.4559903,
        17.3580935
      ]
    },
    {
      "id": 6641,
      "name": "Pabellon de Arteaga",
      "names": {
        "id": "Pabellon de artega"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.146604,
        "lng": -102.277847
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.293027435,
        22.1366061251,
        -102.268796225,
        22.1575110192
      ]
    },
    {
      "id": 6643,
      "name": "Talas",
      "names": {
        "zh": "塔拉斯"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.5233807,
        "lng": 72.2373853
      },
      "country": "Kyrgyzstan",
      "importance": 1,
      "bbox": [
        72.1790689,
        42.5052062,
        72.2824515,
        42.5402739
      ]
    },
    {
      "id": 6644,
      "name": "Roi Et",
      "names": {
        "zh": "ROI等",
        "ja": "Roiet",
        "th": "ร้อยเอ็ด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0568089,
        "lng": 103.6514286
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        103.6114286,
        16.0168089,
        103.6914286,
        16.0968089
      ]
    },
    {
      "id": 6645,
      "name": "Taibao",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 23.459647,
        "lng": 120.332876
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        120.2895182,
        23.4288504,
        120.4091032,
        23.5166367
      ]
    },
    {
      "id": 6646,
      "name": "Lahij",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.05667,
        "lng": 44.88194
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        44.86194,
        13.03667,
        44.90194,
        13.07667
      ]
    },
    {
      "id": 6648,
      "name": "Gisborne",
      "names": {
        "zh": "吉斯伯恩"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -38.661326,
        "lng": 178.0206487
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        177.8606487,
        -38.821326,
        178.1806487,
        -38.501326
      ]
    },
    {
      "id": 6650,
      "name": "Braganca",
      "names": {
        "es": "Bragança",
        "fr": "Bragança",
        "zh": "布拉干萨",
        "de": "Bragança",
        "pt": "Bragança"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.8071182,
        "lng": -6.7589839
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -6.9544841,
        41.5345768,
        -6.5155141,
        41.9925213
      ]
    },
    {
      "id": 6652,
      "name": "Catemaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4213938,
        "lng": -95.1117783
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.1517783,
        18.3813938,
        -95.0717783,
        18.4613938
      ]
    },
    {
      "id": 6653,
      "name": "Phatthalung",
      "names": {
        "de": "Phattalung",
        "th": "พัทลุง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.617689,
        "lng": 100.0809426
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.0409426,
        7.577689,
        100.1209426,
        7.657689
      ]
    },
    {
      "id": 6655,
      "name": "Neuchatel",
      "names": {
        "fr": "Neuchâtel",
        "zh": "纳沙泰尔",
        "de": "Neuenburg",
        "id": "Neuchâtel"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.991046,
        "lng": 6.931647
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        6.892926,
        46.953398,
        7.002286,
        47.02351
      ]
    },
    {
      "id": 6656,
      "name": "Papendrecht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8348695,
        "lng": 4.6937293
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6691594,
        51.8203943,
        4.7435144,
        51.8490933
      ]
    },
    {
      "id": 6658,
      "name": "Santa Cruz del Quiche",
      "names": {
        "zh": "圣克鲁斯德尔乳蛋饼",
        "ja": "サンタクルスデルキッシュ",
        "th": "ซานตาครูซเดลคูชี"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0295098,
        "lng": -91.1496179
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -91.3096179,
        14.8695098,
        -90.9896179,
        15.1895098
      ]
    },
    {
      "id": 6660,
      "name": "Luebo",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -5.3535845,
        "lng": 21.4197459
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        21.3797459,
        -5.3935845,
        21.4597459,
        -5.3135845
      ]
    },
    {
      "id": 6664,
      "name": "Bria",
      "names": {
        "zh": "布里亚"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 6.5392451,
        "lng": 21.98698
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        21.82698,
        6.3792451,
        22.14698,
        6.6992451
      ]
    },
    {
      "id": 6670,
      "name": "Magdalena de Kino",
      "names": {
        "ja": "マグダレナデキノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.6292495,
        "lng": -110.9643532
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.9644032,
        30.6291995,
        -110.9643032,
        30.6292995
      ]
    },
    {
      "id": 6671,
      "name": "Bussum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2773656,
        "lng": 5.1630646
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.1353586,
        52.2557958,
        5.2015803,
        52.2850294
      ]
    },
    {
      "id": 6673,
      "name": "Maidan Shahr",
      "names": {
        "ja": "マーダンシェール"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.4378512,
        "lng": 68.8318959
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        68.7340534,
        34.3263948,
        68.9474986,
        34.5601368
      ]
    },
    {
      "id": 6675,
      "name": "Nea Filadelfeia",
      "names": {
        "de": "Nea Filadeleia",
        "pt": "Nea Filadelia"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.03333,
        "lng": 23.73333
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.71333,
        38.01333,
        23.75333,
        38.05333
      ]
    },
    {
      "id": 6678,
      "name": "Marijampole",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.5562536,
        "lng": 23.3503665
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.2912235,
        54.5182937,
        23.3955832,
        54.5893414
      ]
    },
    {
      "id": 6679,
      "name": "San Pedro de Ycuamandiyu",
      "names": {
        "ja": "サンペドロデ・ユヤマンドイ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -24.0914383,
        "lng": -57.0771224
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -57.1171224,
        -24.1314383,
        -57.0371224,
        -24.0514383
      ]
    },
    {
      "id": 6681,
      "name": "Dikhil",
      "names": {
        "zh": "迪基勒"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.1071149,
        "lng": 42.3687433
      },
      "country": "Djibouti",
      "importance": 1,
      "bbox": [
        42.3528534,
        11.0981762,
        42.3854269,
        11.1128943
      ]
    },
    {
      "id": 6682,
      "name": "Ocotal",
      "names": {
        "zh": "奥科塔尔"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.6333171,
        "lng": -86.476387
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.5519206,
        13.5836587,
        -86.4494676,
        13.7013987
      ]
    },
    {
      "id": 6687,
      "name": "Kampong Thom",
      "names": {
        "ja": "カンポントム",
        "th": "กม."
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.71112,
        "lng": 104.88873
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.86873,
        12.69112,
        104.90873,
        12.73112
      ]
    },
    {
      "id": 6690,
      "name": "Ciudad Sabinas Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8023162,
        "lng": -100.3712433
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.3731366,
        25.802073,
        -100.3697982,
        25.8027091
      ]
    },
    {
      "id": 6691,
      "name": "Naryn",
      "names": {
        "zh": "纳里恩",
        "ja": "ナルイン"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 41.42550955,
        "lng": 75.9943481795658
      },
      "country": "Kyrgyzstan",
      "importance": 1,
      "bbox": [
        75.8996917,
        41.4140364,
        76.0620383,
        41.4365776
      ]
    },
    {
      "id": 6694,
      "name": "Jacmel",
      "names": {
        "zh": "雅克梅勒"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.2352587,
        "lng": -72.5375127
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -72.6975127,
        18.0752587,
        -72.3775127,
        18.3952587
      ]
    },
    {
      "id": 6695,
      "name": "Escarcega",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.606934,
        "lng": -90.7385823
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.7713735,
        18.5901201,
        -90.7200234,
        18.6253076
      ]
    },
    {
      "id": 6696,
      "name": "Busia",
      "names": {
        "zh": "布西亚"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 0.4643498,
        "lng": 34.1110943
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.0968132,
        0.41529,
        34.1551405,
        0.4705205
      ]
    },
    {
      "id": 6697,
      "name": "Kalasin",
      "names": {
        "zh": "加拉信",
        "th": "กาฬสินธุ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.434034,
        "lng": 103.5059403
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        103.4659403,
        16.394034,
        103.5459403,
        16.474034
      ]
    },
    {
      "id": 6698,
      "name": "Huatabampo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8248431,
        "lng": -109.6420085
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.6820085,
        26.7848431,
        -109.6020085,
        26.8648431
      ]
    },
    {
      "id": 6700,
      "name": "Silistra",
      "names": {
        "zh": "锡利斯特拉"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 44.118308,
        "lng": 27.2600031
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        27.1977001,
        44.0538238,
        27.3333455,
        44.1270807
      ]
    },
    {
      "id": 6702,
      "name": "Swakopmund",
      "names": {
        "zh": "斯瓦科普蒙德",
        "ja": "スワコプムンド",
        "th": "สวากอปมุนด์"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -22.671078,
        "lng": 14.5405783
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        14.5355783,
        -22.676078,
        14.5455783,
        -22.666078
      ]
    },
    {
      "id": 6703,
      "name": "Homa Bay",
      "names": {
        "es": "Bahía de casa",
        "zh": "homa湾",
        "it": "Baia di Homa",
        "id": "Teluk Homa",
        "th": "อ่าวฮา",
        "pt": "Baía de homa"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.52731,
        "lng": 34.45714
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.43714,
        -0.54731,
        34.47714,
        -0.50731
      ]
    },
    {
      "id": 6706,
      "name": "Azogues",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -2.7380492,
        "lng": -78.8467191
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.8712099,
        -2.7865585,
        -78.7674838,
        -2.6440357
      ]
    },
    {
      "id": 6710,
      "name": "Lens",
      "names": {
        "es": "Lente",
        "fr": "Lentille",
        "zh": "镜片",
        "de": "Linse",
        "it": "Lente",
        "id": "Lensa",
        "ja": "レンズ",
        "th": "เลนส์",
        "pt": "Lente"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.4291723,
        "lng": 2.8319805
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.7867191,
        50.4194096,
        2.8516736,
        50.4594009
      ]
    },
    {
      "id": 6711,
      "name": "Fushe Kosove",
      "names": {
        "de": "Fushe kopf",
        "it": "Kosove Fushe",
        "id": "Fushe koshove",
        "ja": "ケージを埋めます"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.637647,
        "lng": 21.093902
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 6713,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0778456,
        "lng": 22.0991779
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.9914196,
        44.0401771,
        22.1433029,
        44.1451152
      ]
    },
    {
      "id": 6717,
      "name": "Uttaradit",
      "names": {
        "zh": "程逸",
        "th": "อุตรดิตถ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.6254377,
        "lng": 100.0994587
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.0994087,
        17.6253877,
        100.0995087,
        17.6254877
      ]
    },
    {
      "id": 6718,
      "name": "Nykoping",
      "names": {
        "es": "Nyköping",
        "zh": "尼雪平",
        "id": "Nyköping"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.757769,
        "lng": 17.019816
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        16.969689,
        58.739973,
        17.079174,
        58.783214
      ]
    },
    {
      "id": 6719,
      "name": "Zumpango del Rio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.6503988,
        "lng": -99.5263596
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.5663596,
        17.6103988,
        -99.4863596,
        17.6903988
      ]
    },
    {
      "id": 6721,
      "name": "Shariff Aguak",
      "names": {
        "es": "Skaliff aguak",
        "de": "Harngänger aguak",
        "it": "Tariff Aguak",
        "ja": "aguak shariff"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.8610172,
        "lng": 124.4444871
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.4190706,
        6.830292,
        124.4646891,
        6.882588
      ]
    },
    {
      "id": 6723,
      "name": "Zhaoyu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3572401,
        "lng": 112.3188663
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.1588663,
        37.1972401,
        112.4788663,
        37.5172401
      ]
    },
    {
      "id": 6724,
      "name": "Kampong Speu",
      "names": {
        "th": "กำปงปเว่"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.45332,
        "lng": 104.52085
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.50085,
        11.43332,
        104.54085,
        11.47332
      ]
    },
    {
      "id": 6725,
      "name": "Kakata",
      "names": {
        "zh": "卡卡塔"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.532326,
        "lng": -10.3488074
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -10.4367321,
        6.4844318,
        -10.0580163,
        6.9006952
      ]
    },
    {
      "id": 6727,
      "name": "Ventspils",
      "names": {
        "zh": "文茨皮尔斯"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.3903918,
        "lng": 21.5635991
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        21.5172711,
        57.3479878,
        21.6734738,
        57.4705813
      ]
    },
    {
      "id": 6733,
      "name": "Hendrik-Ido-Ambacht",
      "names": {
        "zh": "德里克 - 伊多文-ambacht",
        "ja": "ヘンドリック-イド語-ambacht"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.856716,
        "lng": 4.6331197
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6330697,
        51.856666,
        4.6331697,
        51.856766
      ]
    },
    {
      "id": 6737,
      "name": "Koh Kong",
      "names": {
        "zh": "象孔",
        "th": "เกาะกง"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.61531,
        "lng": 102.9838
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        102.9638,
        11.59531,
        103.0038,
        11.63531
      ]
    },
    {
      "id": 6738,
      "name": "Chumphon",
      "names": {
        "zh": "春蓬",
        "th": "ชุมพร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 10.4961355,
        "lng": 99.1778731
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.1378731,
        10.4561355,
        99.2178731,
        10.5361355
      ]
    },
    {
      "id": 6739,
      "name": "Cabarroguis",
      "names": {
        "zh": "卡巴罗吉斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.58333,
        "lng": 121.5
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.48,
        16.56333,
        121.52,
        16.60333
      ]
    },
    {
      "id": 6741,
      "name": "Bilwi",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 14.0294451,
        "lng": -83.3831224
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -83.4263446,
        14.0147593,
        -83.3700884,
        14.0670066
      ]
    },
    {
      "id": 6742,
      "name": "Kericho",
      "names": {
        "zh": "凯里乔"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.36774,
        "lng": 35.28314
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.26314,
        -0.38774,
        35.30314,
        -0.34774
      ]
    },
    {
      "id": 6743,
      "name": "Mandera",
      "names": {
        "zh": "曼德拉"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 3.9384208,
        "lng": 41.8573239
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        41.8439784,
        3.9215556,
        41.8732781,
        3.9520604
      ]
    },
    {
      "id": 6744,
      "name": "Masindi",
      "names": {
        "zh": "马辛迪"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.6917534,
        "lng": 31.773752436319583
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.4780061,
        1.3258563,
        32.090593,
        2.0576718
      ]
    },
    {
      "id": 6747,
      "name": "Sisak",
      "names": {
        "zh": "锡萨克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4891806,
        "lng": 16.374592
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        16.2429764,
        45.3358007,
        16.781324,
        45.5759136
      ]
    },
    {
      "id": 6749,
      "name": "Birkirkara",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8994547,
        "lng": 14.4649578
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4523542,
        35.8869075,
        14.478421,
        35.9046046
      ]
    },
    {
      "id": 6750,
      "name": "Kyrenia",
      "names": {
        "zh": "凯里尼亚"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 35.3396291,
        "lng": 33.3205287
      },
      "country": "Cyprus",
      "importance": 1,
      "bbox": [
        33.2876262,
        35.3089108,
        33.3387472,
        35.3505616
      ]
    },
    {
      "id": 6752,
      "name": "Juticalpa",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 14.66667,
        "lng": -86.21944
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -86.23944,
        14.64667,
        -86.19944,
        14.68667
      ]
    },
    {
      "id": 6753,
      "name": "Lovech",
      "names": {
        "zh": "洛维奇",
        "th": "โลเว็ตช์"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.1349971,
        "lng": 24.715431
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        24.555431,
        42.9749971,
        24.875431,
        43.2949971
      ]
    },
    {
      "id": 6754,
      "name": "Mukdahan",
      "names": {
        "zh": "穆达汉",
        "th": "มุกดาหาร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5414065,
        "lng": 104.7290699
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        104.6890699,
        16.5014065,
        104.7690699,
        16.5814065
      ]
    },
    {
      "id": 6760,
      "name": "Perigueux",
      "names": {
        "fr": "Périgueux",
        "zh": "佩里格",
        "id": "Périgueux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1909365,
        "lng": 0.7184407
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.6742078,
        45.1738735,
        0.7467487,
        45.2137737
      ]
    },
    {
      "id": 6761,
      "name": "Razgrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.5258211,
        "lng": 26.5230603
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        26.4499083,
        43.4452796,
        26.5903022,
        43.6078508
      ]
    },
    {
      "id": 6762,
      "name": "Timimoun",
      "names": {
        "zh": "提米蒙"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 29.2605117,
        "lng": 0.2285923
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        0.102216,
        28.982325,
        2.0225716,
        30.3803204
      ]
    },
    {
      "id": 6763,
      "name": "Amasya",
      "names": {
        "zh": "阿马西亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6503248,
        "lng": 35.8329148
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        35.6729148,
        40.4903248,
        35.9929148,
        40.8103248
      ]
    },
    {
      "id": 6767,
      "name": "Pilar",
      "names": {
        "zh": "皮拉尔",
        "ja": "ピラル",
        "th": "ปีลาร์"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -26.8582578,
        "lng": -58.3034318
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -58.324391,
        -26.8964494,
        -58.2738332,
        -26.8291422
      ]
    },
    {
      "id": 6773,
      "name": "Harper",
      "names": {
        "zh": "哈珀",
        "ja": "ハーパー",
        "th": "ฮาร์เปอร์"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 4.3789381,
        "lng": -7.7060988
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -7.8333822,
        4.3539078,
        -7.5297198,
        4.4995901
      ]
    },
    {
      "id": 6774,
      "name": "Santiago Sacatepequez",
      "names": {
        "fr": "Santiago sacatepequezez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.63528,
        "lng": -90.67639
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.69639,
        14.61528,
        -90.65639,
        14.65528
      ]
    },
    {
      "id": 6779,
      "name": "Krimpen aan den IJssel",
      "names": {
        "id": "KRimpen Aan den IJSEL",
        "ja": "Kripemen Aan Den Ijssel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.914403,
        "lng": 4.5887889
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5492385,
        51.9023675,
        4.6429763,
        51.9310501
      ]
    },
    {
      "id": 6781,
      "name": "Kitui",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.3666,
        "lng": 38.0166
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.8566,
        -1.5266,
        38.1766,
        -1.2066
      ]
    },
    {
      "id": 6782,
      "name": "Salgotarjan",
      "names": {
        "zh": "绍尔戈陶尔扬"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.0960676,
        "lng": 19.8005642
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        19.7270813,
        48.0413746,
        19.9223525,
        48.1784432
      ]
    },
    {
      "id": 6788,
      "name": "I-n-Salah",
      "names": {
        "zh": "我-n-·萨拉赫",
        "it": "Ho-n-Salah",
        "id": "Saya-n-shalat",
        "ja": "私は、n-サラーレフ",
        "th": "ฉัน n-เชลาห์"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 27.1950331,
        "lng": 2.4826132
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.004578,
        25.35869,
        3.931872,
        29.080792
      ]
    },
    {
      "id": 6789,
      "name": "Mazeikiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.3110483,
        "lng": 22.3484725
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.2851734,
        56.2822244,
        22.3746841,
        56.3447926
      ]
    },
    {
      "id": 6791,
      "name": "Soledad de Doblado",
      "names": {
        "id": "Soledad de dublado"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0452067,
        "lng": -96.4233756
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.4633756,
        19.0052067,
        -96.3833756,
        19.0852067
      ]
    },
    {
      "id": 6792,
      "name": "Leticia",
      "names": {
        "zh": "莱蒂西亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -4.2129211,
        "lng": -69.9425963
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -70.3664017,
        -4.2294028,
        -69.7140846,
        -3.0023611
      ]
    },
    {
      "id": 6796,
      "name": "Yverdon-les-Bains",
      "names": {
        "zh": "伊韦尔东-莱斯班",
        "ja": "yverdon・レ・バン",
        "th": "yverdon-เลสแบงส์"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.7785711,
        "lng": 6.6409158
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        6.6043426,
        46.7386478,
        6.6720527,
        46.7953799
      ]
    },
    {
      "id": 6800,
      "name": "Bangassou",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 4.743526,
        "lng": 22.8232963
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        22.6632963,
        4.583526,
        22.9832963,
        4.903526
      ]
    },
    {
      "id": 6805,
      "name": "Chachapoyas",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -6.2293182,
        "lng": -77.8714631
      },
      "country": "Peru",
      "importance": 1,
      "bbox": [
        -77.9420527,
        -6.2990563,
        -77.7616604,
        -6.1773028
      ]
    },
    {
      "id": 6811,
      "name": "Rumbek",
      "names": {
        "zh": "伦拜克"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 6.8063901,
        "lng": 29.6767488
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        29.5167488,
        6.6463901,
        29.8367488,
        6.9663901
      ]
    },
    {
      "id": 6812,
      "name": "El Salto",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5196964,
        "lng": -103.1813141
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.3334263,
        20.4931244,
        -103.1688281,
        20.585155
      ]
    },
    {
      "id": 6814,
      "name": "Boende",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -0.28163,
        "lng": 20.88053
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        20.86053,
        -0.30163,
        20.90053,
        -0.26163
      ]
    },
    {
      "id": 6815,
      "name": "Kartal",
      "names": {
        "zh": "卡尔塔尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.88858,
        "lng": 29.1856536
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.138277,
        40.8781452,
        29.2551653,
        40.9529633
      ]
    },
    {
      "id": 6820,
      "name": "Chon Buri",
      "names": {
        "ja": "チョンブリ",
        "th": "ชลบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3662087,
        "lng": 100.9872185
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.9472185,
        13.3262087,
        101.0272185,
        13.4062087
      ]
    },
    {
      "id": 6824,
      "name": "Juneau",
      "names": {
        "zh": "朱诺",
        "ja": "ジュノー",
        "th": "จูโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 58.3019496,
        "lng": -134.419734
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -135.219128,
        57.795049,
        -133.172311,
        58.974972
      ]
    },
    {
      "id": 6827,
      "name": "Zug",
      "names": {
        "zh": "楚格",
        "ja": "ツーク",
        "th": "ซัก"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1679898,
        "lng": 8.5173652
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.4738273,
        47.0810323,
        8.5775926,
        47.1898484
      ]
    },
    {
      "id": 6831,
      "name": "Molde",
      "names": {
        "zh": "莫尔德",
        "ja": "モルデ",
        "th": "โมลด์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 62.7389441,
        "lng": 7.1561255
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        7.1161255,
        62.6989441,
        7.1961255,
        62.7789441
      ]
    },
    {
      "id": 6835,
      "name": "Pallisa",
      "names": {
        "zh": "帕利萨"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1676921,
        "lng": 33.7097019
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.6697019,
        1.1276921,
        33.7497019,
        1.2076921
      ]
    },
    {
      "id": 6836,
      "name": "Jeremie",
      "names": {
        "zh": "热雷米"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.6445835,
        "lng": -74.1139182
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -74.2533154,
        18.3919185,
        -74.070344,
        18.6758635
      ]
    },
    {
      "id": 6837,
      "name": "Gizycko",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.044475,
        "lng": 21.765289
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.729137,
        54.016305,
        21.809621,
        54.055614
      ]
    },
    {
      "id": 6839,
      "name": "Babati",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -4.2100638,
        "lng": 35.7491485
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        35.7091485,
        -4.2500638,
        35.7891485,
        -4.1700638
      ]
    },
    {
      "id": 6843,
      "name": "Contla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.333333,
        "lng": -98.167778
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.191815,
        19.3090676,
        -98.1413982,
        19.3438229
      ]
    },
    {
      "id": 6845,
      "name": "Champoton",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3439655,
        "lng": -90.7242742
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.7642742,
        19.3039655,
        -90.6842742,
        19.3839655
      ]
    },
    {
      "id": 6852,
      "name": "Zaqatala",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.63361,
        "lng": 46.64333
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.62333,
        41.61361,
        46.66333,
        41.65361
      ]
    },
    {
      "id": 6857,
      "name": "Imisli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.8716243,
        "lng": 48.0594743
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.0255075,
        39.8422117,
        48.1130694,
        39.8993424
      ]
    },
    {
      "id": 6858,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 34.28497,
        "lng": -118.437652
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -118.4560419,
        34.2733896,
        -118.4158015,
        34.304687
      ]
    },
    {
      "id": 6860,
      "name": "Szekszard",
      "names": {
        "zh": "塞克萨德",
        "de": "Szekszárd"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3506959,
        "lng": 18.7012356
      },
      "country": "Hungary",
      "importance": 1,
      "bbox": [
        18.7011856,
        46.3506459,
        18.7012856,
        46.3507459
      ]
    },
    {
      "id": 6862,
      "name": "Castanos",
      "names": {
        "es": "Castaños",
        "fr": "Castaños"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.79433,
        "lng": -101.428704
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.468704,
        26.75433,
        -101.388704,
        26.83433
      ]
    },
    {
      "id": 6863,
      "name": "Wete",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.056425,
        "lng": 39.7279904
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        39.6879904,
        -5.096425,
        39.7679904,
        -5.016425
      ]
    },
    {
      "id": 6864,
      "name": "Diemen",
      "names": {
        "zh": "迪门"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3421489,
        "lng": 4.960825
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.9426936,
        52.3139789,
        5.0399692,
        52.3561017
      ]
    },
    {
      "id": 6865,
      "name": "Pefki",
      "names": {
        "th": "เพฟกี"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0599892,
        "lng": 23.7924013
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.7724013,
        38.0399892,
        23.8124013,
        38.0799892
      ]
    },
    {
      "id": 6867,
      "name": "Tuxpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5676298,
        "lng": -100.4627519
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.5027519,
        19.5276298,
        -100.4227519,
        19.6076298
      ]
    },
    {
      "id": 6868,
      "name": "Nebbi",
      "names": {
        "zh": "内比"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.4785503,
        "lng": 31.0899897
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.0499897,
        2.4385503,
        31.1299897,
        2.5185503
      ]
    },
    {
      "id": 6871,
      "name": "Cuilapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.276824,
        "lng": -90.2989113
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.3913955,
        14.1660852,
        -90.1986086,
        14.3309473
      ]
    },
    {
      "id": 6873,
      "name": "Diffa",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 13.3130749,
        "lng": 12.6136611
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        12.4536611,
        13.1530749,
        12.7736611,
        13.4730749
      ]
    },
    {
      "id": 6875,
      "name": "Dieppe",
      "names": {
        "zh": "迪耶普"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.921492,
        "lng": 1.0811365491113354
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.080756,
        49.9211988,
        1.0815687,
        49.9217829
      ]
    },
    {
      "id": 6876,
      "name": "Ixhuatlancillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8997323,
        "lng": -97.14974833251878
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1528234,
        18.8902284,
        -97.1462463,
        18.9039879
      ]
    },
    {
      "id": 6882,
      "name": "Ungheni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.2059965,
        "lng": 27.798917
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.7602952,
        47.168638,
        27.8481865,
        47.2433764
      ]
    },
    {
      "id": 6884,
      "name": "Tripoli",
      "names": {
        "es": "Trípoli",
        "zh": "的黎波里",
        "de": "Tripolis",
        "ja": "トリポリ",
        "th": "ตริโปลี",
        "pt": "Trípoli"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.5102911,
        "lng": 22.3724241
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        22.2124241,
        37.3502911,
        22.5324241,
        37.6702911
      ]
    },
    {
      "id": 6885,
      "name": "Cakovec",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.3892305,
        "lng": 16.4368593
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        16.3801643,
        46.3125895,
        16.5172362,
        46.4626829
      ]
    },
    {
      "id": 6887,
      "name": "Soissons",
      "names": {
        "zh": "苏瓦松"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.3837621,
        "lng": 3.327613
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.2895235,
        49.3550418,
        3.3576844,
        49.3979533
      ]
    },
    {
      "id": 6889,
      "name": "Bregenz",
      "names": {
        "zh": "布雷根茨",
        "ja": "ブレゲンツ"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.5025779,
        "lng": 9.7472924
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        9.6515804,
        47.480016,
        9.8076723,
        47.5346568
      ]
    },
    {
      "id": 6892,
      "name": "Avellaneda",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6648394,
        "lng": -58.3628061
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.3783203,
        -34.6786875,
        -58.3441456,
        -34.6482654
      ]
    },
    {
      "id": 6895,
      "name": "Jamay",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2935132,
        "lng": -102.7086678
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.7486678,
        20.2535132,
        -102.6686678,
        20.3335132
      ]
    },
    {
      "id": 6896,
      "name": "Tuxpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5563523,
        "lng": -103.3759867
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.4159867,
        19.5163523,
        -103.3359867,
        19.5963523
      ]
    },
    {
      "id": 6897,
      "name": "Krabi",
      "names": {
        "zh": "甲米",
        "ja": "クラビ",
        "th": "กระบี่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.0634637,
        "lng": 98.9162345
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        98.8762345,
        8.0234637,
        98.9562345,
        8.1034637
      ]
    },
    {
      "id": 6903,
      "name": "Asosa",
      "names": {
        "zh": "阿索萨"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 10.06667,
        "lng": 34.53333
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        34.51333,
        10.04667,
        34.55333,
        10.08667
      ]
    },
    {
      "id": 6904,
      "name": "Ketrzyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.076868,
        "lng": 21.385583
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.344869,
        54.061233,
        21.405275,
        54.103405
      ]
    },
    {
      "id": 6906,
      "name": "Salyan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.5990891,
        "lng": 48.9831723
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.9466061,
        39.5347865,
        48.9993533,
        39.6136603
      ]
    },
    {
      "id": 6914,
      "name": "Frontera",
      "names": {
        "zh": "Frontera的",
        "ja": "フロンテーラ",
        "th": "ฟรงเตอรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5321825,
        "lng": -92.6461428
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.6861428,
        18.4921825,
        -92.6061428,
        18.5721825
      ]
    },
    {
      "id": 6916,
      "name": "Mount Hagen",
      "names": {
        "es": "Monte Hagen",
        "zh": "哈格山",
        "de": "Hagen",
        "it": "Monte Hagen",
        "ja": "ハーゲン山",
        "th": "เมาฮะเกน",
        "pt": "Monte Hagen"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.858784,
        "lng": 144.2316365
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        144.0716365,
        -6.018784,
        144.3916365,
        -5.698784
      ]
    },
    {
      "id": 6917,
      "name": "Los Reyes de Juarez",
      "names": {
        "es": "Los Reyes de Juárez",
        "ja": "ロスレイレスデュレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.945063,
        "lng": -97.812586
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.8337295295,
        18.9267323993,
        -97.7894909017,
        18.9757563284
      ]
    },
    {
      "id": 6919,
      "name": "Nogales",
      "names": {
        "zh": "诺加利斯",
        "ja": "ノガレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8346283,
        "lng": -97.20172780131207
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.236721,
        18.7570398,
        -97.1522761,
        18.9123037
      ]
    },
    {
      "id": 6924,
      "name": "Mutsamudu",
      "names": {},
      "countryCode": "KM",
      "latLng": {
        "lat": -12.168846,
        "lng": 44.3942831
      },
      "country": "Comoros",
      "importance": 1,
      "bbox": [
        44.2342831,
        -12.328846,
        44.5542831,
        -12.008846
      ]
    },
    {
      "id": 6925,
      "name": "Cahul",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 45.9044087,
        "lng": 28.1946583
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.0346583,
        45.7444087,
        28.3546583,
        46.0644087
      ]
    },
    {
      "id": 6926,
      "name": "Heemstede",
      "names": {
        "zh": "海姆斯泰德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3528406,
        "lng": 4.6217323
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5901862,
        52.3222046,
        4.6410337,
        52.3634049
      ]
    },
    {
      "id": 6931,
      "name": "Otjiwarongo",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -20.4609337,
        "lng": 16.651205
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        16.6267779,
        -20.4862859,
        16.7064619,
        -20.443237
      ]
    },
    {
      "id": 6934,
      "name": "Trujillo",
      "names": {
        "zh": "特鲁希略",
        "ja": "トルヒーヨ",
        "th": "ตรูฮีโย"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.9179668,
        "lng": -85.9529579
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -86.2134563,
        15.6172614,
        -85.698575,
        16.0288608
      ]
    },
    {
      "id": 6935,
      "name": "Sai Mai",
      "names": {
        "zh": "赛马",
        "ja": "サイマイ",
        "th": "สายไหม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.92124,
        "lng": 100.65284
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 6936,
      "name": "Subotica",
      "names": {
        "zh": "苏博蒂察"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 46.10190745,
        "lng": 19.671009683182056
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.6705806,
        46.1011054,
        19.6714677,
        46.1027078
      ]
    },
    {
      "id": 6937,
      "name": "Treinta y Tres",
      "names": {},
      "countryCode": "UY",
      "latLng": {
        "lat": -33.2315713,
        "lng": -54.3862595
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -54.4091147,
        -33.2653902,
        -54.3356548,
        -33.1964781
      ]
    },
    {
      "id": 6938,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.53263185,
        "lng": -87.90258201724367
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.0918679,
        13.1439882,
        -87.6837976,
        13.9222573
      ]
    },
    {
      "id": 6939,
      "name": "Stung Treng",
      "names": {
        "es": "Picado",
        "fr": "Piquant",
        "it": "Stug Treng",
        "ja": "スズメチェング",
        "th": "กิ่งตัน"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.529891,
        "lng": 105.968284
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        105.948284,
        13.509891,
        105.988284,
        13.549891
      ]
    },
    {
      "id": 6941,
      "name": "Tralee",
      "names": {
        "ja": "トラリー",
        "th": "ทราลี"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.2692116,
        "lng": -9.7057047
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -9.7506499,
        52.2484373,
        -9.6623183,
        52.2941556
      ]
    },
    {
      "id": 6946,
      "name": "Miahuatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.708879,
        "lng": -96.8710397
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9110397,
        19.668879,
        -96.8310397,
        19.748879
      ]
    },
    {
      "id": 6951,
      "name": "Colonia del Sacramento",
      "names": {
        "ja": "コロニアデルサクラメント"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.4698592,
        "lng": -57.8433679
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -57.8893007,
        -34.4762335,
        -57.7885295,
        -34.4233325
      ]
    },
    {
      "id": 6954,
      "name": "Lamu",
      "names": {
        "zh": "拉穆"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -2.2675396,
        "lng": 40.9010641
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        40.8967828,
        -2.2739715,
        40.9042844,
        -2.2618569
      ]
    },
    {
      "id": 6956,
      "name": "Doba",
      "names": {
        "zh": "多巴"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 44.4867876,
        "lng": 24.2439736
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        24.235095,
        44.483155,
        24.252771,
        44.491293
      ]
    },
    {
      "id": 6957,
      "name": "Agdas",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.65,
        "lng": 47.47611
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.45611,
        40.63,
        47.49611,
        40.67
      ]
    },
    {
      "id": 6966,
      "name": "Katima Mulilo",
      "names": {
        "ja": "カイマムリロ"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -17.5,
        "lng": 24.26667
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        24.24667,
        -17.52,
        24.28667,
        -17.48
      ]
    },
    {
      "id": 6970,
      "name": "Bac Kan",
      "names": {
        "es": "Bac"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.1494915,
        "lng": 105.837248
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.7752014,
        22.0551804,
        105.9304889,
        22.212465
      ]
    },
    {
      "id": 6972,
      "name": "Backa Palanka",
      "names": {
        "es": "Palanka backa",
        "id": "Baca palanka",
        "ja": "バックパパンカ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2500976,
        "lng": 19.3897302
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.3016489,
        45.2313446,
        19.5177457,
        45.3316689
      ]
    },
    {
      "id": 6973,
      "name": "Chalatenango",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 14.03333,
        "lng": -88.93333
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.95333,
        14.01333,
        -88.91333,
        14.05333
      ]
    },
    {
      "id": 6975,
      "name": "Linden",
      "names": {
        "zh": "菩提树",
        "ja": "リンデン",
        "th": "ลินเด็น"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 32.3062535,
        "lng": -87.7980665
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -87.8162831,
        32.2786309,
        -87.7639491,
        32.3254108
      ]
    },
    {
      "id": 6977,
      "name": "Khorugh",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.48974,
        "lng": 71.55304
      },
      "country": "Tajikistan",
      "importance": 1,
      "bbox": [
        71.53304,
        37.46974,
        71.57304,
        37.50974
      ]
    },
    {
      "id": 6978,
      "name": "Rocha",
      "names": {
        "zh": "罗沙"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.4810095,
        "lng": -54.3365608
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -54.3576862,
        -34.5040776,
        -54.2942306,
        -34.4550738
      ]
    },
    {
      "id": 6979,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 34.4221319,
        "lng": -119.702667
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -119.859791,
        34.336029,
        -119.6399059,
        34.463922
      ]
    },
    {
      "id": 6980,
      "name": "Kamphaeng Phet",
      "names": {
        "es": "Phet kamphaeng",
        "fr": "Phott-Phot",
        "id": "Phet kamphaeng",
        "ja": "カンファエンペケット",
        "th": "กำแพงเพชร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.477779,
        "lng": 99.52241
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.466342,
        16.316371,
        99.616907,
        16.526772
      ]
    },
    {
      "id": 6982,
      "name": "Hillerod",
      "names": {
        "fr": "Hillerød",
        "zh": "希勒罗德",
        "de": "Hillerød"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.9280691,
        "lng": 12.311324
      },
      "country": "Denmark",
      "importance": 1,
      "bbox": [
        12.311274,
        55.9280191,
        12.311374,
        55.9281191
      ]
    },
    {
      "id": 6983,
      "name": "Rafael Delgado",
      "names": {
        "zh": "拉斐尔德尔加多",
        "id": "Delgado rafael"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.810362,
        "lng": -97.069617
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.083276,
        18.801354,
        -97.061873,
        18.824589
      ]
    },
    {
      "id": 6986,
      "name": "Leiderdorp",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1617952,
        "lng": 4.5340923
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5146916,
        52.1319033,
        4.5714809,
        52.1821163
      ]
    },
    {
      "id": 6987,
      "name": "Siquijor",
      "names": {
        "zh": "锡基霍尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.2155864,
        "lng": 123.5138212
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.3638827,
        9.1350339,
        123.6021553,
        9.3559028
      ]
    },
    {
      "id": 6988,
      "name": "Penonome",
      "names": {
        "es": "Penonomé",
        "zh": "佩诺诺梅"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.5204989,
        "lng": -80.359579
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -80.4020694,
        8.4657209,
        -80.3252078,
        8.5881503
      ]
    },
    {
      "id": 6989,
      "name": "Hunucma",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0160881,
        "lng": -89.8759279
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.8759779,
        21.0160381,
        -89.8758779,
        21.0161381
      ]
    },
    {
      "id": 6997,
      "name": "Samut Songkhram",
      "names": {
        "th": "สมุทรสงคราม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.4086617,
        "lng": 100.000494
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.960494,
        13.3686617,
        100.040494,
        13.4486617
      ]
    },
    {
      "id": 6999,
      "name": "Vichy",
      "names": {
        "zh": "维希",
        "ja": "ヴィシー",
        "th": "วิชี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1269027,
        "lng": 3.4308218
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.4258218,
        46.1219027,
        3.4358218,
        46.1319027
      ]
    },
    {
      "id": 7001,
      "name": "Corum",
      "names": {
        "zh": "科隆",
        "ja": "コルム"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.5498762,
        "lng": 34.9537362
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        34.4769786,
        40.259365,
        35.2936726,
        40.7520542
      ]
    },
    {
      "id": 7004,
      "name": "Manga",
      "names": {
        "zh": "漫画",
        "ja": "漫画",
        "th": "มังงะ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.6673837,
        "lng": -1.0723972
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -1.1123972,
        11.6273837,
        -1.0323972,
        11.7073837
      ]
    },
    {
      "id": 7006,
      "name": "Altepexi",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3675,
        "lng": -97.298611
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.338611,
        18.3275,
        -97.258611,
        18.4075
      ]
    },
    {
      "id": 7007,
      "name": "Buri Ram",
      "names": {
        "es": "Birmante",
        "fr": "Buri",
        "ja": "ブリムラ",
        "th": "บุรีรัมย์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.990092,
        "lng": 103.103034
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.982042,
        14.887978,
        103.165426,
        15.044027
      ]
    },
    {
      "id": 7009,
      "name": "Olgiy",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.96833,
        "lng": 89.9625
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        89.9425,
        48.94833,
        89.9825,
        48.98833
      ]
    },
    {
      "id": 7012,
      "name": "Tixtla de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5677746,
        "lng": -99.3995548
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.4395548,
        17.5277746,
        -99.3595548,
        17.6077746
      ]
    },
    {
      "id": 7015,
      "name": "Mendefera",
      "names": {},
      "countryCode": "ER",
      "latLng": {
        "lat": 14.8841153,
        "lng": 38.8129433
      },
      "country": "Eritrea",
      "importance": 1,
      "bbox": [
        38.7729433,
        14.8441153,
        38.8529433,
        14.9241153
      ]
    },
    {
      "id": 7016,
      "name": "Smolyan",
      "names": {
        "zh": "斯莫梁"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 41.5768238,
        "lng": 24.7010351
      },
      "country": "Bulgaria",
      "importance": 1,
      "bbox": [
        24.5410351,
        41.4168238,
        24.8610351,
        41.7368238
      ]
    },
    {
      "id": 7018,
      "name": "Dunleary",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.29395,
        "lng": -6.13586
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.15586,
        53.27395,
        -6.11586,
        53.31395
      ]
    },
    {
      "id": 7021,
      "name": "Oegstgeest",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1759646,
        "lng": 4.4565546
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4565046,
        52.1759146,
        4.4566046,
        52.1760146
      ]
    },
    {
      "id": 7024,
      "name": "Koulikoro",
      "names": {
        "zh": "库利科罗"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.8796642,
        "lng": -7.5498683
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -7.5499183,
        12.8796142,
        -7.5498183,
        12.8797142
      ]
    },
    {
      "id": 7028,
      "name": "Kocani",
      "names": {
        "zh": "科查尼"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9211332,
        "lng": 22.4114182
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.3792791,
        41.881416,
        22.4413347,
        41.9600326
      ]
    },
    {
      "id": 7029,
      "name": "Cartago",
      "names": {
        "zh": "卡塔戈",
        "ja": "カルタゴ",
        "th": "คาร์ตาโก"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.8642435,
        "lng": -83.9204377
      },
      "country": "Costa Rica",
      "importance": 1,
      "bbox": [
        -84.0804377,
        9.7042435,
        -83.7604377,
        10.0242435
      ]
    },
    {
      "id": 7030,
      "name": "Rezekne",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.506281,
        "lng": 27.3306623
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        27.3032227,
        56.4777295,
        27.3795433,
        56.538088
      ]
    },
    {
      "id": 7034,
      "name": "Mitu",
      "names": {
        "zh": "米图"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2523268,
        "lng": -70.2308883
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -71.1186139,
        0.1515983,
        -69.8426578,
        1.7910093
      ]
    },
    {
      "id": 7041,
      "name": "Popondetta",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -8.7667424,
        "lng": 148.2375057
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        148.0775057,
        -8.9267424,
        148.3975057,
        -8.6067424
      ]
    },
    {
      "id": 7043,
      "name": "Alencon",
      "names": {
        "zh": "阿朗松"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4312059,
        "lng": 0.0911374
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.0618333,
        48.4103098,
        0.1227639,
        48.4518299
      ]
    },
    {
      "id": 7044,
      "name": "Kathu",
      "names": {
        "th": "อำเภอกะทู้"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.9083375,
        "lng": 98.34232741383948
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.3114575,
        7.8713575,
        98.3645868,
        7.9470154
      ]
    },
    {
      "id": 7045,
      "name": "Ouesso",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": 1.6105452,
        "lng": 16.0513456
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        15.8913456,
        1.4505452,
        16.2113456,
        1.7705452
      ]
    },
    {
      "id": 7049,
      "name": "Mytilini",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.1037355,
        "lng": 26.554788
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        26.394788,
        38.9437355,
        26.714788,
        39.2637355
      ]
    },
    {
      "id": 7052,
      "name": "Hamar",
      "names": {
        "zh": "哈马尔",
        "th": "ฮามาร์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.7947205,
        "lng": 11.0680555
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        10.9080555,
        60.6347205,
        11.2280555,
        60.9547205
      ]
    },
    {
      "id": 7055,
      "name": "Renens",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 46.5349093,
        "lng": 6.5928442
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        6.5769723,
        46.5233179,
        6.6021125,
        46.5465679
      ]
    },
    {
      "id": 7058,
      "name": "Lobatse",
      "names": {
        "zh": "洛巴策"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -25.2280532,
        "lng": 25.6713228
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        25.6663228,
        -25.2330532,
        25.6763228,
        -25.2230532
      ]
    },
    {
      "id": 7059,
      "name": "Cheran",
      "names": {
        "zh": "切兰"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6843528,
        "lng": -101.9532968
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.9932968,
        19.6443528,
        -101.9132968,
        19.7243528
      ]
    },
    {
      "id": 7060,
      "name": "Sombrerete",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.6354312,
        "lng": -103.6410119
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.6810119,
        23.5954312,
        -103.6010119,
        23.6754312
      ]
    },
    {
      "id": 7064,
      "name": "Bethune",
      "names": {
        "zh": "白求恩"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.5309351,
        "lng": 2.6389115
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.6158272,
        50.5092876,
        2.6717096,
        50.5506425
      ]
    },
    {
      "id": 7065,
      "name": "Bongor",
      "names": {
        "zh": "邦戈尔"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 10.2816341,
        "lng": 15.3713139
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        15.2113139,
        10.1216341,
        15.5313139,
        10.4416341
      ]
    },
    {
      "id": 7067,
      "name": "Mongo",
      "names": {
        "zh": "蒙戈",
        "ja": "モンゴ",
        "th": "มองโก"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.1842561,
        "lng": 18.6842092
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        18.5242092,
        12.0242561,
        18.8442092,
        12.3442561
      ]
    },
    {
      "id": 7068,
      "name": "Koper",
      "names": {
        "zh": "科佩尔港"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5479864,
        "lng": 13.7304781
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.5931973,
        45.4250858,
        14.0019178,
        45.6281268
      ]
    },
    {
      "id": 7069,
      "name": "Batman",
      "names": {
        "zh": "蝙蝠侠",
        "it": "Uomo Pipistrello",
        "ja": "バットマン",
        "th": "แบทแมน",
        "pt": "Homem Morcego"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8835738,
        "lng": 41.1277565
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        40.9864447,
        37.7133281,
        41.4804861,
        38.0824247
      ]
    },
    {
      "id": 7073,
      "name": "Chapala",
      "names": {
        "zh": "查帕拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2954814,
        "lng": -103.1898601
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.2298601,
        20.2554814,
        -103.1498601,
        20.3354814
      ]
    },
    {
      "id": 7074,
      "name": "Voorschoten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.12572195,
        "lng": 4.441016864583206
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4011591,
        52.1014702,
        4.4692999,
        52.1498805
      ]
    },
    {
      "id": 7075,
      "name": "Velenje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3592869,
        "lng": 15.1150301
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.063432,
        46.3146223,
        15.205832,
        46.4345905
      ]
    },
    {
      "id": 7076,
      "name": "Rize",
      "names": {
        "zh": "里泽"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0248249,
        "lng": 40.5199142
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        40.3599142,
        40.8648249,
        40.6799142,
        41.1848249
      ]
    },
    {
      "id": 7079,
      "name": "Shtime",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4341803,
        "lng": 21.0363448
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.9963448,
        42.3941803,
        21.0763448,
        42.4741803
      ]
    },
    {
      "id": 7081,
      "name": "Ciudad Altamirano",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3584543,
        "lng": -100.6681865
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.7081865,
        18.3184543,
        -100.6281865,
        18.3984543
      ]
    },
    {
      "id": 7082,
      "name": "Arar",
      "names": {
        "zh": "阿拉尔"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 30.9815531,
        "lng": 41.0164788
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        40.8564788,
        30.8215531,
        41.1764788,
        31.1415531
      ]
    },
    {
      "id": 7086,
      "name": "Al Ghayzah",
      "names": {
        "de": "Alghayzah",
        "ja": "アル・ガイツァ",
        "th": "อัล Ghayzah"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 16.20787,
        "lng": 52.17605
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        52.15605,
        16.18787,
        52.19605,
        16.22787
      ]
    },
    {
      "id": 7087,
      "name": "Cerro Azul",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1938619,
        "lng": -97.7443599
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.7843599,
        21.1538619,
        -97.7043599,
        21.2338619
      ]
    },
    {
      "id": 7091,
      "name": "Sliema",
      "names": {
        "zh": "斯利马",
        "ja": "スリーマ",
        "th": "สลีมา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9127609,
        "lng": 14.5020708
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4929966,
        35.9052839,
        14.5152091,
        35.9188998
      ]
    },
    {
      "id": 7098,
      "name": "San Buenaventura",
      "names": {
        "zh": "圣布纳留村",
        "ja": "サンブナベントゥーラ",
        "th": "ซานบัวเวนทูรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.0598015,
        "lng": -101.5466831
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.5866831,
        27.0198015,
        -101.5066831,
        27.0998015
      ]
    },
    {
      "id": 7100,
      "name": "Sensuntepeque",
      "names": {
        "zh": "森孙特佩克"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.8761841,
        "lng": -88.6284918
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -88.6392428,
        13.864832,
        -88.6189214,
        13.8906299
      ]
    },
    {
      "id": 7103,
      "name": "Siliana",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.0846651,
        "lng": 9.3743733
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        9.3343733,
        36.0446651,
        9.4143733,
        36.1246651
      ]
    },
    {
      "id": 7105,
      "name": "Catio",
      "names": {
        "zh": "钙钛矿"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.28333,
        "lng": -15.25
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -15.27,
        11.26333,
        -15.23,
        11.30333
      ]
    },
    {
      "id": 7114,
      "name": "Carlos A. Carrillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3717192,
        "lng": -95.7447825
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.7847825,
        18.3317192,
        -95.7047825,
        18.4117192
      ]
    },
    {
      "id": 7116,
      "name": "Texistepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.896548,
        "lng": -94.813053
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.82744,
        17.881403,
        -94.795356,
        17.912925
      ]
    },
    {
      "id": 7117,
      "name": "Koscian",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.086325,
        "lng": 16.6449995
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.6186463,
        52.0676725,
        16.6802604,
        52.0992476
      ]
    },
    {
      "id": 7118,
      "name": "Nola",
      "names": {
        "zh": "诺拉"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 3.5224201,
        "lng": 16.0535833
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        15.8935833,
        3.3624201,
        16.2135833,
        3.6824201
      ]
    },
    {
      "id": 7123,
      "name": "Farafangana",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -22.8217524,
        "lng": 47.8307127
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        47.7907127,
        -22.8617524,
        47.8707127,
        -22.7817524
      ]
    },
    {
      "id": 7126,
      "name": "Bayanhongor",
      "names": {
        "zh": "巴彦洪戈尔"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 46.19444,
        "lng": 100.71806
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        100.69806,
        46.17444,
        100.73806,
        46.21444
      ]
    },
    {
      "id": 7127,
      "name": "Cazones de Herrera",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7040761,
        "lng": -97.3094376
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.3494376,
        20.6640761,
        -97.2694376,
        20.7440761
      ]
    },
    {
      "id": 7128,
      "name": "Blenheim",
      "names": {
        "zh": "布莱尼姆",
        "ja": "ブレナム",
        "th": "เบลนไฮม์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.5118691,
        "lng": 173.9545856
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        173.9145856,
        -41.5518691,
        173.9945856,
        -41.4718691
      ]
    },
    {
      "id": 7132,
      "name": "Voinjama",
      "names": {
        "zh": "沃因贾马"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 8.42194,
        "lng": -9.74778
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -9.76778,
        8.40194,
        -9.72778,
        8.44194
      ]
    },
    {
      "id": 7135,
      "name": "Nakhon Phanom",
      "names": {
        "ja": "ナコーン・ファノム",
        "th": "นครพนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4032326,
        "lng": 104.7853098
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        104.7453098,
        17.3632326,
        104.8253098,
        17.4432326
      ]
    },
    {
      "id": 7137,
      "name": "Heroica Ciudad de Tlaxiaco",
      "names": {
        "zh": "Heroica Ciudad de Tlaxiamco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.248822,
        "lng": -97.683084
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.821477,
        17.1227,
        -97.576718,
        17.347501
      ]
    },
    {
      "id": 7139,
      "name": "Utena",
      "names": {
        "zh": "乌田纳"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.4984381,
        "lng": 25.6025772
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        25.5502674,
        55.4787462,
        25.6564289,
        55.5246035
      ]
    },
    {
      "id": 7142,
      "name": "Rodez",
      "names": {
        "zh": "罗德兹"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.3511408,
        "lng": 2.5728493
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.5333486,
        44.340533,
        2.6064197,
        44.3776341
      ]
    },
    {
      "id": 7143,
      "name": "Jonava",
      "names": {
        "zh": "约纳瓦"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.0737086,
        "lng": 24.2756961
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.2374256,
        55.0586254,
        24.3246831,
        55.0998961
      ]
    },
    {
      "id": 7144,
      "name": "Suphan Buri",
      "names": {
        "ja": "スーパーブリ",
        "th": "สุพรรณบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4699051,
        "lng": 100.1164984
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.0764984,
        14.4299051,
        100.1564984,
        14.5099051
      ]
    },
    {
      "id": 7145,
      "name": "Mendi",
      "names": {
        "zh": "门迪"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.1393582,
        "lng": 143.6578597
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        143.6178597,
        -6.1793582,
        143.6978597,
        -6.0993582
      ]
    },
    {
      "id": 7146,
      "name": "Dongola",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 19.1728506,
        "lng": 30.4731589
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        30.3131589,
        19.0128506,
        30.6331589,
        19.3328506
      ]
    },
    {
      "id": 7149,
      "name": "Ulaangom",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 49.9800327,
        "lng": 92.068851
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        91.2682372,
        49.5662815,
        92.4782074,
        50.58723
      ]
    },
    {
      "id": 7156,
      "name": "Rivas",
      "names": {
        "zh": "里瓦斯",
        "th": "วาส"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.4416741,
        "lng": -85.75915012688887
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.3317621,
        11.0447316,
        -85.1496873,
        11.7096008
      ]
    },
    {
      "id": 7160,
      "name": "Bang Sao Thong",
      "names": {
        "fr": "Bang sao strong",
        "de": "Bang Sao Tanga",
        "ja": "バンサコトン",
        "th": "บางแส้ทอง",
        "pt": "BANG SAO TANG"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5821686,
        "lng": 100.7965806
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.7623111,
        13.5484994,
        100.8070997,
        13.6003155
      ]
    },
    {
      "id": 7161,
      "name": "Torcy",
      "names": {
        "zh": "托尔西"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8515,
        "lng": 2.65265
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.6290701,
        48.8323077,
        2.6660584,
        48.874354
      ]
    },
    {
      "id": 7163,
      "name": "Atoyac de Alvarez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.203489,
        "lng": -100.43697
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.44756,
        17.184783,
        -100.417923,
        17.228735
      ]
    },
    {
      "id": 7164,
      "name": "Bajina Basta",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9712226,
        "lng": 19.5660149
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5489817,
        43.9598079,
        19.5777443,
        43.9828738
      ]
    },
    {
      "id": 7166,
      "name": "Ennis",
      "names": {
        "zh": "恩尼斯",
        "th": "เอนนิส"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.8435152,
        "lng": -8.983747
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -9.0368188,
        52.8064071,
        -8.9371629,
        52.8906184
      ]
    },
    {
      "id": 7168,
      "name": "Hovd",
      "names": {
        "zh": "科布多",
        "ja": "ホブド",
        "th": "ฮอฟด์"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 48.0006353,
        "lng": 91.6452415
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        91.4852415,
        47.8406353,
        91.8052415,
        48.1606353
      ]
    },
    {
      "id": 7169,
      "name": "Krathum Baen",
      "names": {
        "th": "กระทุ่มแบน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6553127,
        "lng": 100.2670884
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.2270884,
        13.6153127,
        100.3070884,
        13.6953127
      ]
    },
    {
      "id": 7170,
      "name": "Calilabad",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.20963,
        "lng": 48.49186
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.47186,
        39.18963,
        48.51186,
        39.22963
      ]
    },
    {
      "id": 7171,
      "name": "Amnat Charoen",
      "names": {
        "th": "อำนาจเจริญ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.866238,
        "lng": 104.628333
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        104.542052,
        15.83078,
        104.692568,
        15.912266
      ]
    },
    {
      "id": 7174,
      "name": "El Tarf",
      "names": {
        "zh": "eltf"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7667186,
        "lng": 8.3169418
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        8.220942,
        36.676291,
        8.379973,
        36.832188
      ]
    },
    {
      "id": 7179,
      "name": "Nar'yan-Mar",
      "names": {
        "zh": "nar'yan-3月",
        "ja": "nar'yan-03月",
        "th": "nar'yan มี.ค."
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 67.6380175,
        "lng": 53.0071044
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        52.9031215,
        67.6061387,
        53.1536929,
        67.6695853
      ]
    },
    {
      "id": 7180,
      "name": "Kerkyra",
      "names": {
        "es": "Corfú"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.6217347,
        "lng": 19.9180908
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        19.7580908,
        39.4617347,
        20.0780908,
        39.7817347
      ]
    },
    {
      "id": 7181,
      "name": "Kanchanaburi",
      "names": {
        "zh": "北碧府",
        "ja": "カンチャナブリー",
        "th": "กาญจนบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0227073,
        "lng": 99.5323173
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.4923173,
        13.9827073,
        99.5723173,
        14.0627073
      ]
    },
    {
      "id": 7182,
      "name": "Santiago Ixcuintla",
      "names": {
        "fr": "Santiago ixcutintla",
        "it": "Santiago Ixcuinta"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.8127335,
        "lng": -105.2076472
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.2476472,
        21.7727335,
        -105.1676472,
        21.8527335
      ]
    },
    {
      "id": 7183,
      "name": "Svay Rieng",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 11.19500815,
        "lng": 105.79679668507217
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        105.5796471,
        10.7900301,
        106.2058419,
        11.5996195
      ]
    },
    {
      "id": 7186,
      "name": "Zwedru",
      "names": {
        "zh": "绥德鲁"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.06667,
        "lng": -8.12806
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -8.14806,
        6.04667,
        -8.10806,
        6.08667
      ]
    },
    {
      "id": 7187,
      "name": "Tipasa",
      "names": {
        "zh": "提帕萨"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.5918179,
        "lng": 2.4482509
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        2.3003536,
        36.5483322,
        2.5177575,
        36.6454792
      ]
    },
    {
      "id": 7191,
      "name": "Fray Bentos",
      "names": {
        "fr": "Bough Bentos",
        "it": "Bentos sfaccia",
        "id": "Bentos fray",
        "ja": "争いベントス",
        "pt": "BENTOS DE FRASOS"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -33.1165167,
        "lng": -58.3130977
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -58.3220475,
        -33.1455061,
        -58.2759503,
        -33.1080437
      ]
    },
    {
      "id": 7196,
      "name": "Siirt",
      "names": {
        "zh": "锡尔特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9273623,
        "lng": 41.94218
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        41.7726461,
        37.6870143,
        42.1506784,
        38.0517644
      ]
    },
    {
      "id": 7198,
      "name": "Visby",
      "names": {
        "zh": "维斯比"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.6379361,
        "lng": 18.297982
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        18.137982,
        57.4779361,
        18.457982,
        57.7979361
      ]
    },
    {
      "id": 7200,
      "name": "Puyo",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.4854777,
        "lng": -77.9969666
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.0568669,
        -1.5289015,
        -77.9193208,
        -1.4208261
      ]
    },
    {
      "id": 7201,
      "name": "Chocaman",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0108696,
        "lng": -97.0332255
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0732255,
        18.9708696,
        -96.9932255,
        19.0508696
      ]
    },
    {
      "id": 7202,
      "name": "Heredia",
      "names": {
        "zh": "埃雷迪亚",
        "ja": "エレディア"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.9981515,
        "lng": -84.1170163
      },
      "country": "Costa Rica",
      "importance": 1,
      "bbox": [
        -84.1248379,
        9.9909397,
        -84.108772,
        10.006123
      ]
    },
    {
      "id": 7207,
      "name": "Chiconcuac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.559426,
        "lng": -98.899576
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.911304,
        19.547625,
        -98.879115,
        19.570719
      ]
    },
    {
      "id": 7208,
      "name": "Tlacolula de Matamoros",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.953546,
        "lng": -96.47575
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.490244,
        16.937339,
        -96.459206,
        16.972743
      ]
    },
    {
      "id": 7210,
      "name": "Lambarene",
      "names": {
        "zh": "兰巴雷内"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -0.6942603,
        "lng": 10.2236482
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        10.0636482,
        -0.8542603,
        10.3836482,
        -0.5342603
      ]
    },
    {
      "id": 7213,
      "name": "Ojinaga",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 29.5654282,
        "lng": -104.4128523
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.5728523,
        29.4054282,
        -104.2528523,
        29.7254282
      ]
    },
    {
      "id": 7218,
      "name": "Mohale's Hoek",
      "names": {
        "es": "Hoek de Mohale",
        "fr": "Hoek de Mohale",
        "zh": "Mohale的Hoek",
        "it": "Hoek di Mohale",
        "id": "Hoek Mohale",
        "ja": "モハレの鍬",
        "th": "hoek ของ Mohale",
        "pt": "Heek de Mohale"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -30.1516305,
        "lng": 27.4770113
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        27.4370113,
        -30.1916305,
        27.5170113,
        -30.1116305
      ]
    },
    {
      "id": 7220,
      "name": "Vukovar",
      "names": {
        "zh": "武科瓦尔"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.346749,
        "lng": 19.000626
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        18.974268,
        45.326156,
        19.033071,
        45.371511
      ]
    },
    {
      "id": 7222,
      "name": "Wewak",
      "names": {
        "zh": "韦瓦克"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -3.55342,
        "lng": 143.62678
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        143.60678,
        -3.57342,
        143.64678,
        -3.53342
      ]
    },
    {
      "id": 7224,
      "name": "Tbeng Meanchey",
      "names": {
        "es": "Tbeng meandchey",
        "zh": "tbeng vectchey",
        "de": "Tbeng bedeutete",
        "it": "Meanchey Tbeng",
        "id": "TBENG MeAlchey",
        "pt": "Tbeng meachey"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.80732,
        "lng": 104.98046
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.96046,
        13.78732,
        105.00046,
        13.82732
      ]
    },
    {
      "id": 7225,
      "name": "Szczytno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.565489,
        "lng": 20.991589
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.9597888,
        53.5466922,
        21.0359416,
        53.5805883
      ]
    },
    {
      "id": 7226,
      "name": "Sirnak",
      "names": {
        "zh": "舍尔纳克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5219577,
        "lng": 42.4570311
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        42.2970311,
        37.3619577,
        42.6170311,
        37.6819577
      ]
    },
    {
      "id": 7229,
      "name": "Bartoszyce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.252916,
        "lng": 20.811096
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.768664,
        54.234772,
        20.852106,
        54.276841
      ]
    },
    {
      "id": 7231,
      "name": "Kaga Bandoro",
      "names": {
        "ja": "カガバンドロ"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 6.9993145,
        "lng": 19.182305
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        19.022305,
        6.8393145,
        19.342305,
        7.1593145
      ]
    },
    {
      "id": 7234,
      "name": "Coquimatlan",
      "names": {
        "es": "Coquimatlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2045414,
        "lng": -103.8096132
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.8496132,
        19.1645414,
        -103.7696132,
        19.2445414
      ]
    },
    {
      "id": 7236,
      "name": "Portalegre",
      "names": {
        "zh": "波塔莱格雷"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.1989181,
        "lng": -7.4611204
      },
      "country": "Portugal",
      "importance": 1,
      "bbox": [
        -7.4661204,
        39.1939181,
        -7.4561204,
        39.2039181
      ]
    },
    {
      "id": 7240,
      "name": "Degollado",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.447027,
        "lng": -102.134087
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.1501509,
        20.4383149,
        -102.1163336,
        20.4593106
      ]
    },
    {
      "id": 7241,
      "name": "Valle Nacional",
      "names": {
        "zh": "豪华",
        "id": "Nacional",
        "ja": "けいれん弁"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.8809509,
        "lng": -102.3190431
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.3190726,
        21.8808613,
        -102.3190216,
        21.8814313
      ]
    },
    {
      "id": 7242,
      "name": "Novo Mesto",
      "names": {
        "zh": "诺莫梅斯托",
        "ja": "ノボメスト"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8040475,
        "lng": 15.1696684
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0928463,
        45.684516,
        15.3164172,
        45.8768854
      ]
    },
    {
      "id": 7243,
      "name": "Cotija de la Paz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8084734,
        "lng": -102.7041756
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.7441756,
        19.7684734,
        -102.6641756,
        19.8484734
      ]
    },
    {
      "id": 7247,
      "name": "Fortin de las Flores",
      "names": {
        "zh": "福蒂宁德兰弗洛雷斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8993309,
        "lng": -96.9982014
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0382014,
        18.8593309,
        -96.9582014,
        18.9393309
      ]
    },
    {
      "id": 7254,
      "name": "Dongta",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0829877,
        "lng": 106.3391651
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.3020801,
        38.0447453,
        106.4549875,
        38.1676634
      ]
    },
    {
      "id": 7256,
      "name": "Suhbaatar",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 50.2358909,
        "lng": 106.2094944
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        106.1728318,
        50.2233403,
        106.2464343,
        50.2533362
      ]
    },
    {
      "id": 7258,
      "name": "Rawson",
      "names": {
        "zh": "罗森"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -43.2991348,
        "lng": -65.1056655
      },
      "country": "Argentina",
      "importance": 1,
      "bbox": [
        -65.1456655,
        -43.3391348,
        -65.0656655,
        -43.2591348
      ]
    },
    {
      "id": 7259,
      "name": "Baalbek",
      "names": {
        "zh": "巴勒贝克"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 34.009653,
        "lng": 36.2117096
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        36.1505556,
        33.9848075,
        36.2435551,
        34.0336336
      ]
    },
    {
      "id": 7268,
      "name": "Lop Buri",
      "names": {
        "zh": "罗普布里",
        "it": "Lop buli",
        "ja": "ロップビリ",
        "th": "ลพบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.7985255,
        "lng": 100.6153786
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.6103786,
        14.7935255,
        100.6203786,
        14.8035255
      ]
    },
    {
      "id": 7269,
      "name": "Allende",
      "names": {
        "zh": "阿连德",
        "ja": "アジェンデ",
        "th": "อัลเลน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.3420816,
        "lng": -100.8517302
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.8917302,
        28.3020816,
        -100.8117302,
        28.3820816
      ]
    },
    {
      "id": 7275,
      "name": "Carlow",
      "names": {
        "zh": "卡洛",
        "th": "โลว์"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.8408344,
        "lng": -6.9261131
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.957792,
        52.8176276,
        -6.8772491,
        52.8511515
      ]
    },
    {
      "id": 7279,
      "name": "Sao Joao da Madeira",
      "names": {
        "fr": "Sao Joao da Madère",
        "ja": "サンジョアーダマデイラ",
        "pt": "São João da Madeira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.896823,
        "lng": -8.4995308
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4995808,
        40.896773,
        -8.4994808,
        40.896873
      ]
    },
    {
      "id": 7281,
      "name": "Fatick",
      "names": {
        "zh": "法蒂克"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.333965,
        "lng": -16.404219
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -16.4421182,
        14.3126954,
        -16.3885421,
        14.3614174
      ]
    },
    {
      "id": 7290,
      "name": "Chanthaburi",
      "names": {
        "zh": "庄他武里",
        "th": "จันทบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6064515,
        "lng": 102.0996672
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.0996172,
        12.6064015,
        102.0997172,
        12.6065015
      ]
    },
    {
      "id": 7293,
      "name": "Baabda",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 33.8335501,
        "lng": 35.5445655
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        35.5045655,
        33.7935501,
        35.5845655,
        33.8735501
      ]
    },
    {
      "id": 7294,
      "name": "Gjirokaster",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 40.0770871,
        "lng": 20.1393359
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        20.0574681,
        40.0335536,
        20.165556,
        40.1078058
      ]
    },
    {
      "id": 7295,
      "name": "Agri",
      "names": {
        "fr": "Agro",
        "zh": "农业",
        "th": "เกษตร",
        "pt": "Agro"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.719125,
        "lng": 43.0504894
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        42.8904894,
        39.559125,
        43.2104894,
        39.879125
      ]
    },
    {
      "id": 7298,
      "name": "Valmiera",
      "names": {
        "zh": "瓦尔米耶拉"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.5389148,
        "lng": 25.4261688
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        25.3746416,
        57.4974929,
        25.467758,
        57.5551153
      ]
    },
    {
      "id": 7301,
      "name": "Vrbas",
      "names": {
        "zh": "弗尔巴斯"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.568028,
        "lng": 19.6470089
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5720763,
        45.5249932,
        19.7245146,
        45.6480991
      ]
    },
    {
      "id": 7302,
      "name": "Bontoc",
      "names": {
        "zh": "邦都"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.0891276,
        "lng": 120.977394
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        120.937394,
        17.0491276,
        121.017394,
        17.1291276
      ]
    },
    {
      "id": 7303,
      "name": "Atar",
      "names": {
        "zh": "阿塔尔"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 20.5181943,
        "lng": -13.0543928
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -13.0662547,
        20.4901783,
        -13.019713,
        20.5367431
      ]
    },
    {
      "id": 7305,
      "name": "Al Jawf",
      "names": {
        "zh": "jawf",
        "it": "Al maia",
        "ja": "アルジャーフ"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 24.1985492,
        "lng": 23.2938969
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        23.2538969,
        24.1585492,
        23.3338969,
        24.2385492
      ]
    },
    {
      "id": 7307,
      "name": "Kirklareli",
      "names": {
        "zh": "克尔克拉雷利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.7370223,
        "lng": 27.2235523
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        26.8999708,
        41.5445613,
        27.5827861,
        42.071744
      ]
    },
    {
      "id": 7308,
      "name": "Kibaha",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.76667,
        "lng": 38.91667
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        38.89667,
        -6.78667,
        38.93667,
        -6.74667
      ]
    },
    {
      "id": 7313,
      "name": "Mecayapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.220991,
        "lng": -94.83706
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.844257,
        18.212576,
        -94.830573,
        18.228749
      ]
    },
    {
      "id": 7315,
      "name": "Owando",
      "names": {
        "zh": "奥旺多"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -0.48193,
        "lng": 15.89988
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        15.87988,
        -0.50193,
        15.91988,
        -0.46193
      ]
    },
    {
      "id": 7317,
      "name": "Guaranda",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.59444,
        "lng": -79
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.02,
        -1.61444,
        -78.98,
        -1.57444
      ]
    },
    {
      "id": 7322,
      "name": "Nkhata Bay",
      "names": {
        "es": "Bahía de Nkhata",
        "zh": "Nkhata湾",
        "th": "อ่าว Nkhata",
        "pt": "Baía de nkhata"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -11.606995,
        "lng": 34.2983907
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.2583907,
        -11.646995,
        34.3383907,
        -11.566995
      ]
    },
    {
      "id": 7324,
      "name": "Satun",
      "names": {
        "zh": "沙敦",
        "th": "สตูล"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.6151578,
        "lng": 100.0664441
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.0264441,
        6.5751578,
        100.1064441,
        6.6551578
      ]
    },
    {
      "id": 7327,
      "name": "Phetchaburi",
      "names": {
        "th": "เพชรบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.1165279,
        "lng": 99.9409835
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.9359835,
        13.1115279,
        99.9459835,
        13.1215279
      ]
    },
    {
      "id": 7330,
      "name": "Bayburt",
      "names": {
        "zh": "巴伊布尔特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.2551608,
        "lng": 40.2205036
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        39.802039,
        39.9944819,
        40.7078413,
        40.5556457
      ]
    },
    {
      "id": 7334,
      "name": "Ciudad Miguel Aleman",
      "names": {
        "es": "Ciudad Miguel Alemán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.403218,
        "lng": -99.02033
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.020392,
        26.403184,
        -99.020268,
        26.403252
      ]
    },
    {
      "id": 7338,
      "name": "Kastamonu",
      "names": {
        "zh": "卡斯塔莫努"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.3789564,
        "lng": 33.7768796
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        33.7768296,
        41.3789064,
        33.7769296,
        41.3790064
      ]
    },
    {
      "id": 7340,
      "name": "At Tafilah",
      "names": {
        "es": "En Tafilah",
        "fr": "À Tafilah",
        "zh": "在Tafilah",
        "de": "Bei Tafilah",
        "it": "A TAFILAH",
        "id": "Di tafilah",
        "ja": "タフィラで",
        "th": "ที่ทาฟลาห์",
        "pt": "Em Tafilah"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 30.83752,
        "lng": 35.60442
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.58442,
        30.81752,
        35.62442,
        30.85752
      ]
    },
    {
      "id": 7353,
      "name": "Tena",
      "names": {
        "zh": "泰纳"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.9916185,
        "lng": -77.8163379
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.0384614,
        -1.0346965,
        -77.7533483,
        -0.8681618
      ]
    },
    {
      "id": 7354,
      "name": "Naas",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2214677,
        "lng": -6.6574093
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.7000013,
        53.1965236,
        -6.627793,
        53.2398862
      ]
    },
    {
      "id": 7359,
      "name": "Hidalgotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.772533,
        "lng": -94.6464496
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.6864496,
        17.732533,
        -94.6064496,
        17.812533
      ]
    },
    {
      "id": 7362,
      "name": "Mosta",
      "names": {
        "zh": "莫斯塔"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9079178,
        "lng": 14.4241508
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3930066,
        35.8973738,
        14.4390803,
        35.9292416
      ]
    },
    {
      "id": 7368,
      "name": "Kayanza",
      "names": {
        "zh": "卡扬扎"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -2.9297133,
        "lng": 29.6321188
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.4721188,
        -3.0897133,
        29.7921188,
        -2.7697133
      ]
    },
    {
      "id": 7373,
      "name": "Ogre",
      "names": {
        "es": "Ogro",
        "zh": "食人魔",
        "de": "Menschenfresser",
        "ja": "鬼",
        "th": "ผีปอบ"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8157685,
        "lng": 24.6020435
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.5970435,
        56.8107685,
        24.6070435,
        56.8207685
      ]
    },
    {
      "id": 7375,
      "name": "Aarau",
      "names": {
        "zh": "阿劳",
        "th": "อาเรา"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3927146,
        "lng": 8.0444448
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.0264433,
        47.3697199,
        8.1134556,
        47.4123518
      ]
    },
    {
      "id": 7377,
      "name": "Canakkale",
      "names": {
        "zh": "恰纳卡莱",
        "de": "Çanakkale"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.146271,
        "lng": 26.4028892
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        26.166488,
        39.9118083,
        26.8398115,
        40.2369752
      ]
    },
    {
      "id": 7379,
      "name": "Zimatlan de Alvarez",
      "names": {
        "ja": "ジマートランデアルバレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8672761,
        "lng": -96.7849352
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.8249352,
        16.8272761,
        -96.7449352,
        16.9072761
      ]
    },
    {
      "id": 7381,
      "name": "Tepatlaxco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.314211,
        "lng": -98.4454176
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4454676,
        19.314161,
        -98.4453676,
        19.314261
      ]
    },
    {
      "id": 7384,
      "name": "Moyo",
      "names": {
        "zh": "莫约"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.6537619,
        "lng": 31.7245222
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.6845222,
        3.6137619,
        31.7645222,
        3.6937619
      ]
    },
    {
      "id": 7385,
      "name": "Bilasuvar",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.4561281,
        "lng": 48.5465607
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.5242251,
        39.4353326,
        48.5661784,
        39.4785364
      ]
    },
    {
      "id": 7387,
      "name": "Phra Pradaeng",
      "names": {
        "es": "Phra pradeng",
        "de": "Phra Pradag",
        "th": "พระประแดง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6591779,
        "lng": 100.5338323
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.5287098,
        13.6483336,
        100.5392792,
        13.6649227
      ]
    },
    {
      "id": 7388,
      "name": "Mzimba",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -11.8989563,
        "lng": 33.5951593
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.5551593,
        -11.9389563,
        33.6351593,
        -11.8589563
      ]
    },
    {
      "id": 7391,
      "name": "Sibiti",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": -3.68192,
        "lng": 13.34985
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        13.32985,
        -3.70192,
        13.36985,
        -3.66192
      ]
    },
    {
      "id": 7395,
      "name": "Sibut",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 5.7350828,
        "lng": 19.0850538
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        18.9250538,
        5.5750828,
        19.2450538,
        5.8950828
      ]
    },
    {
      "id": 7397,
      "name": "Savanna-la-Mar",
      "names": {
        "es": "Sabana-la-mar",
        "fr": "Savane-la-Mar",
        "zh": "稀树草原-的La-3月",
        "de": "Savanne-La-Mar",
        "ja": "サバンナ・ラ・マール",
        "th": "เซวันนาลา มี.ค."
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.2193686,
        "lng": -78.1326673
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -78.1432107,
        18.2085592,
        -78.1201911,
        18.2321107
      ]
    },
    {
      "id": 7398,
      "name": "Madingou",
      "names": {
        "zh": "马丁古"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.15361,
        "lng": 13.55
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        13.53,
        -4.17361,
        13.57,
        -4.13361
      ]
    },
    {
      "id": 7399,
      "name": "Mulanje",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -16.0261834,
        "lng": 35.509645064028284
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.4968836,
        -16.0330264,
        35.5196543,
        -16.0194686
      ]
    },
    {
      "id": 7400,
      "name": "Jose Cardel",
      "names": {
        "zh": "何塞心皮"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3699,
        "lng": -96.371739
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.384793,
        19.352626,
        -96.355696,
        19.384071
      ]
    },
    {
      "id": 7401,
      "name": "Kedainiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2887322,
        "lng": 23.9758359
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.9362692,
        55.2503475,
        24.0308425,
        55.3477019
      ]
    },
    {
      "id": 7403,
      "name": "Kayunga",
      "names": {
        "zh": "卡永加"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7012322,
        "lng": 32.9035171
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.8635171,
        0.6612322,
        32.9435171,
        0.7412322
      ]
    },
    {
      "id": 7405,
      "name": "Jekabpils",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.4958638,
        "lng": 25.8687747
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        25.8239773,
        56.4699907,
        25.9339146,
        56.53132
      ]
    },
    {
      "id": 7406,
      "name": "Modling",
      "names": {
        "de": "Mödling"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0855922,
        "lng": 16.2833526
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.2481399,
        48.0624213,
        16.3093211,
        48.0939745
      ]
    },
    {
      "id": 7407,
      "name": "Burdur",
      "names": {
        "zh": "布尔杜尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7248394,
        "lng": 30.2887286
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        29.9642844,
        37.3619714,
        30.4885217,
        37.8326588
      ]
    },
    {
      "id": 7411,
      "name": "Bafata",
      "names": {
        "zh": "巴法塔"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 12.1723403,
        "lng": -14.6555027
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -14.8155027,
        12.0123403,
        -14.4955027,
        12.3323403
      ]
    },
    {
      "id": 7412,
      "name": "Mapastepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.43981,
        "lng": -92.898367
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.938367,
        15.39981,
        -92.858367,
        15.47981
      ]
    },
    {
      "id": 7414,
      "name": "Saint Paul's Bay",
      "names": {
        "es": "Bahía de San Pablo",
        "zh": "圣保罗海湾",
        "it": "Baia di San Paolo",
        "id": "Teluk Saint Paul",
        "ja": "セントポール湾",
        "th": "อ่าวเซนต์พอล",
        "pt": "Baía de Saint Paul"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9480742,
        "lng": 14.3973929
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3684504,
        35.910314,
        14.4301625,
        35.9608954
      ]
    },
    {
      "id": 7415,
      "name": "Phichit",
      "names": {
        "th": "พิจิตร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4428085,
        "lng": 100.3438228
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.3038228,
        16.4028085,
        100.3838228,
        16.4828085
      ]
    },
    {
      "id": 7416,
      "name": "Mouila",
      "names": {
        "zh": "穆伊拉"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -1.86846,
        "lng": 11.05594
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        11.03594,
        -1.88846,
        11.07594,
        -1.84846
      ]
    },
    {
      "id": 7417,
      "name": "Artashat",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 39.9566633,
        "lng": 44.5471007
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        44.5018534,
        39.9197209,
        44.581741,
        39.9931056
      ]
    },
    {
      "id": 7420,
      "name": "Aldama",
      "names": {
        "zh": "阿尔达马"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.8377727,
        "lng": -105.9160119
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.9560119,
        28.7977727,
        -105.8760119,
        28.8777727
      ]
    },
    {
      "id": 7423,
      "name": "Loei",
      "names": {
        "zh": "黎",
        "ja": "ルーイ",
        "th": "เลย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.490375,
        "lng": 101.7286456
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.6886456,
        17.450375,
        101.7686456,
        17.530375
      ]
    },
    {
      "id": 7424,
      "name": "Al Bahah",
      "names": {
        "ja": "アルバハー",
        "th": "อัลบาห์"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 20.012246,
        "lng": 41.466782
      },
      "country": "Saudi Arabia",
      "importance": 1,
      "bbox": [
        41.441006,
        19.992439,
        41.490865,
        20.034206
      ]
    },
    {
      "id": 7427,
      "name": "Nsanje",
      "names": {
        "zh": "恩桑杰"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -16.9232359,
        "lng": 35.261946
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.221946,
        -16.9632359,
        35.301946,
        -16.8832359
      ]
    },
    {
      "id": 7428,
      "name": "Adiyaman",
      "names": {
        "zh": "阿德亚曼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.766139,
        "lng": 38.273099
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        38.237587,
        37.74773,
        38.304256,
        37.782835
      ]
    },
    {
      "id": 7429,
      "name": "Mbaiki",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 3.8728278,
        "lng": 17.9860847
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        17.8260847,
        3.7128278,
        18.1460847,
        4.0328278
      ]
    },
    {
      "id": 7430,
      "name": "Tatahuicapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.245921,
        "lng": -94.760572
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.7678421418,
        18.2392395104,
        -94.7539595248,
        18.2535939543
      ]
    },
    {
      "id": 7431,
      "name": "Soroca",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.1571286,
        "lng": 28.2996052
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.2527147,
        48.1231629,
        28.3279801,
        48.1827166
      ]
    },
    {
      "id": 7433,
      "name": "Fgura",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.872022,
        "lng": 14.521401
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.512625,
        35.866569,
        14.526434,
        35.875953
      ]
    },
    {
      "id": 7434,
      "name": "Ayutla de los Libres",
      "names": {
        "de": "Ayutla de los libre",
        "ja": "Ayutla de Losのライブラリ",
        "pt": "Ayutla de los lianges"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9658527,
        "lng": -99.0963025
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1363025,
        16.9258527,
        -99.0563025,
        17.0058527
      ]
    },
    {
      "id": 7438,
      "name": "Wexford",
      "names": {
        "zh": "韦克斯福德",
        "ja": "ウェクスフォード",
        "th": "เว็กซ์ฟอร์ด"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.3367671,
        "lng": -6.4601397
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.5157537,
        52.3025954,
        -6.4454931,
        52.3615404
      ]
    },
    {
      "id": 7439,
      "name": "Sao Filipe",
      "names": {
        "fr": "Sao filpe",
        "zh": "撒底菲利普",
        "ja": "サンフィラ",
        "pt": "São Filipe"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 14.8952963,
        "lng": -24.4969361
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -24.5369361,
        14.8552963,
        -24.4569361,
        14.9352963
      ]
    },
    {
      "id": 7440,
      "name": "Uliastay",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 47.7415554,
        "lng": 96.8474808
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        96.6874808,
        47.5815554,
        97.0074808,
        47.9015554
      ]
    },
    {
      "id": 7441,
      "name": "Phetchabun",
      "names": {
        "zh": "碧差汶",
        "th": "เพชรบูรณ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.418027,
        "lng": 101.1556825
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.1156825,
        16.378027,
        101.1956825,
        16.458027
      ]
    },
    {
      "id": 7443,
      "name": "Caacupe",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -25.38611,
        "lng": -57.14028
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -57.16028,
        -25.40611,
        -57.12028,
        -25.36611
      ]
    },
    {
      "id": 7445,
      "name": "Kilkenny",
      "names": {
        "zh": "基尔肯尼",
        "ja": "キルケニー",
        "th": "คิลเคนนี่"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.6506255,
        "lng": -7.2514438
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.27535,
        52.6313878,
        -7.216882,
        52.677211
      ]
    },
    {
      "id": 7449,
      "name": "Acala",
      "names": {
        "zh": "阿卡拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5551435,
        "lng": -92.8066491
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.8466491,
        16.5151435,
        -92.7666491,
        16.5951435
      ]
    },
    {
      "id": 7451,
      "name": "Mocoa",
      "names": {
        "zh": "莫科阿"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.1466295,
        "lng": -76.6482327
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -76.9224595,
        0.8646692,
        -76.4094212,
        1.4678152
      ]
    },
    {
      "id": 7453,
      "name": "Telsiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9846135,
        "lng": 22.2490753
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.1810042,
        55.9517984,
        22.2915427,
        56.005334
      ]
    },
    {
      "id": 7456,
      "name": "Ashtarak",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.29126895,
        "lng": 44.357948321386274
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        44.3359926,
        40.2652106,
        44.385527,
        40.3172976
      ]
    },
    {
      "id": 7460,
      "name": "Mchinji",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -13.7971726,
        "lng": 32.8874963
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        32.8474963,
        -13.8371726,
        32.9274963,
        -13.7571726
      ]
    },
    {
      "id": 7461,
      "name": "Ghat",
      "names": {
        "zh": "迦特",
        "ja": "ガート",
        "th": "แม่น้ำ"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 26.0198133,
        "lng": 10.4339911
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        9.398983,
        24.200501,
        11.4809904,
        27.8121177
      ]
    },
    {
      "id": 7465,
      "name": "Isparta",
      "names": {
        "zh": "伊斯帕尔塔",
        "th": "อิสปาร์ตา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7709545,
        "lng": 30.5512931
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        30.4086431,
        37.5626999,
        30.8230035,
        37.900621
      ]
    },
    {
      "id": 7466,
      "name": "Al Karak",
      "names": {
        "ja": "アルカラック",
        "th": "อัลคารัค"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.18248,
        "lng": 35.69999
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.67999,
        31.16248,
        35.71999,
        31.20248
      ]
    },
    {
      "id": 7469,
      "name": "Coalcoman de Vazquez Pallares",
      "names": {
        "es": "Coalcoman de Vázquez Pallares",
        "fr": "COALCOMAN DE VAZQUEZ PALALARES",
        "zh": "煤炭德·维萨克斯普拉雷斯",
        "ja": "コールマンデバズケスパラエス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7759687,
        "lng": -103.16035
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.20035,
        18.7359687,
        -103.12035,
        18.8159687
      ]
    },
    {
      "id": 7470,
      "name": "Goroka",
      "names": {
        "zh": "戈罗卡"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.0749616,
        "lng": 145.39292380334467
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        145.3927491,
        -6.0754229,
        145.3931156,
        -6.0747134
      ]
    },
    {
      "id": 7476,
      "name": "Yalova",
      "names": {
        "zh": "亚洛瓦"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6582529,
        "lng": 29.2699916
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        29.1854487,
        40.5205916,
        29.3503941,
        40.6676713
      ]
    },
    {
      "id": 7478,
      "name": "Moulins",
      "names": {
        "zh": "穆兰"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.5660526,
        "lng": 3.3331703
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.3033697,
        46.5367525,
        3.3473585,
        46.5814014
      ]
    },
    {
      "id": 7479,
      "name": "Bolu",
      "names": {
        "zh": "玻鲁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7332953,
        "lng": 31.6110479
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        31.3027852,
        40.5238481,
        31.9966609,
        40.9878651
      ]
    },
    {
      "id": 7485,
      "name": "Ijevan",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.87832,
        "lng": 45.14922
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        45.12922,
        40.85832,
        45.16922,
        40.89832
      ]
    },
    {
      "id": 7489,
      "name": "Nigde",
      "names": {
        "zh": "尼代"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.967673,
        "lng": 34.678728
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        34.657561,
        37.937401,
        34.722884,
        37.999874
      ]
    },
    {
      "id": 7495,
      "name": "Nong Bua Lamphu",
      "names": {
        "th": "หนองบัวลำภู"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.2022252,
        "lng": 102.440702
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.400702,
        17.1622252,
        102.480702,
        17.2422252
      ]
    },
    {
      "id": 7501,
      "name": "Bozoum",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 6.31933,
        "lng": 16.37992
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        16.35992,
        6.29933,
        16.39992,
        6.33933
      ]
    },
    {
      "id": 7503,
      "name": "Taurage",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2533635,
        "lng": 22.2903395
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.2571288,
        55.2256584,
        22.3233941,
        55.2760348
      ]
    },
    {
      "id": 7507,
      "name": "Maralal",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 1.1,
        "lng": 36.7
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.687063,
        1.0839862,
        36.7087565,
        1.1077927
      ]
    },
    {
      "id": 7509,
      "name": "Virovitica",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8331834,
        "lng": 17.3853058
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        17.2768163,
        45.7013964,
        17.473551,
        45.8783798
      ]
    },
    {
      "id": 7510,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 8.2999481,
        "lng": -81.01066403679657
      },
      "country": "El Salvador",
      "importance": 1,
      "bbox": [
        -81.1259434,
        8.1773735,
        -80.8226072,
        8.4226871
      ]
    },
    {
      "id": 7511,
      "name": "Nevsehir",
      "names": {
        "zh": "内夫谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.626204,
        "lng": 34.710857
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        34.679218,
        38.596846,
        34.751503,
        38.68401
      ]
    },
    {
      "id": 7513,
      "name": "Giresun",
      "names": {
        "zh": "吉雷松",
        "th": "กิเรซุน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9174453,
        "lng": 38.3847864
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        38.238633,
        40.6669378,
        38.5249457,
        40.9315838
      ]
    },
    {
      "id": 7516,
      "name": "Senguio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.746455599999997,
        "lng": -100.34736001879881
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.4485582,
        19.6553128,
        -100.2553323,
        19.8374545
      ]
    },
    {
      "id": 7518,
      "name": "Le Raincy",
      "names": {
        "es": "Le lluvia",
        "fr": "Le plaincy",
        "it": "Le piove",
        "ja": "ルレイン",
        "th": "le indcy",
        "pt": "Le Rouccy"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.897335,
        "lng": 2.519367
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.506658,
        48.88776,
        2.532376,
        48.903889
      ]
    },
    {
      "id": 7519,
      "name": "Ukmerge",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2477293,
        "lng": 24.7599714
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.7239697,
        55.2082255,
        24.8160231,
        55.2696625
      ]
    },
    {
      "id": 7520,
      "name": "Tecpan de Galeana",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2171425,
        "lng": -100.6301726
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.6701726,
        17.1771425,
        -100.5901726,
        17.2571425
      ]
    },
    {
      "id": 7523,
      "name": "Obock",
      "names": {},
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.9640149,
        "lng": 43.292228
      },
      "country": "Djibouti",
      "importance": 1,
      "bbox": [
        43.279833,
        11.9591841,
        43.2949394,
        11.9723408
      ]
    },
    {
      "id": 7524,
      "name": "Pajapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2620645,
        "lng": -94.6915979
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.7315979,
        18.2220645,
        -94.6515979,
        18.3020645
      ]
    },
    {
      "id": 7525,
      "name": "Orhei",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.3782288,
        "lng": 28.8246754
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.7955162,
        47.3333339,
        28.8618437,
        47.4034053
      ]
    },
    {
      "id": 7527,
      "name": "La Esperanza",
      "names": {
        "fr": "La espéranza"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.3081342,
        "lng": -88.1767846
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -88.2183832,
        14.1598381,
        -88.0557477,
        14.314001
      ]
    },
    {
      "id": 7528,
      "name": "Luena",
      "names": {
        "zh": "卢恩阿"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -11.7790355,
        "lng": 19.9122676
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        19.7522676,
        -11.9390355,
        20.0722676,
        -11.6190355
      ]
    },
    {
      "id": 7534,
      "name": "Dispur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.1409355,
        "lng": 91.7910265
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        91.7510265,
        26.1009355,
        91.8310265,
        26.1809355
      ]
    },
    {
      "id": 7536,
      "name": "El Rosario",
      "names": {
        "ja": "エルロザリオ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.9920525,
        "lng": -105.8572752
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.8972752,
        22.9520525,
        -105.8172752,
        23.0320525
      ]
    },
    {
      "id": 7543,
      "name": "Impfondo",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": 1.63806,
        "lng": 18.06667
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        18.04667,
        1.61806,
        18.08667,
        1.65806
      ]
    },
    {
      "id": 7544,
      "name": "Gavarr",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.35398,
        "lng": 45.12386
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        45.10386,
        40.33398,
        45.14386,
        40.37398
      ]
    },
    {
      "id": 7545,
      "name": "Quba",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.3615456,
        "lng": 48.5142385
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.4563468,
        41.3406227,
        48.5572368,
        41.3755164
      ]
    },
    {
      "id": 7549,
      "name": "Canelones",
      "names": {
        "zh": "卡内洛内斯"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.5242373,
        "lng": -56.2817519
      },
      "country": "Uruguay",
      "importance": 1,
      "bbox": [
        -56.2988564,
        -34.5531722,
        -56.2636899,
        -34.4964244
      ]
    },
    {
      "id": 7552,
      "name": "Ismayilli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.7856797,
        "lng": 48.14740530844996
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.1126177,
        40.7633486,
        48.1894183,
        40.8086528
      ]
    },
    {
      "id": 7553,
      "name": "Ati",
      "names": {
        "zh": "ATI的",
        "ja": "ATIの"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 13.2125691,
        "lng": 18.3396857
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        18.1796857,
        13.0525691,
        18.4996857,
        13.3725691
      ]
    },
    {
      "id": 7554,
      "name": "Nan",
      "names": {
        "zh": "南",
        "ja": "ナン",
        "th": "น่าน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.7544594,
        "lng": 100.624804
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.3363358,
        18.012896,
        101.357205,
        19.63407
      ]
    },
    {
      "id": 7555,
      "name": "Cuprija",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9162921,
        "lng": 21.3653629
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.3603629,
        43.9112921,
        21.3703629,
        43.9212921
      ]
    },
    {
      "id": 7560,
      "name": "Yasothon",
      "names": {
        "th": "ยโสธร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7926751,
        "lng": 104.1452903
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        104.1052903,
        15.7526751,
        104.1852903,
        15.8326751
      ]
    },
    {
      "id": 7563,
      "name": "Zaghouan",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.3994751,
        "lng": 10.1446349
      },
      "country": "Tunisia",
      "importance": 1,
      "bbox": [
        10.1046349,
        36.3594751,
        10.1846349,
        36.4394751
      ]
    },
    {
      "id": 7568,
      "name": "Bubanza",
      "names": {
        "zh": "布班扎"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.0845915,
        "lng": 29.3948368
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.2348368,
        -3.2445915,
        29.5548368,
        -2.9245915
      ]
    },
    {
      "id": 7573,
      "name": "Somoto",
      "names": {
        "zh": "索莫托"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.4837096,
        "lng": -86.5826225
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -86.7698964,
        13.4248725,
        -86.506867,
        13.6630509
      ]
    },
    {
      "id": 7574,
      "name": "Senta",
      "names": {
        "zh": "森塔"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.9312407,
        "lng": 20.0901597
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0144341,
        45.8430625,
        20.1372911,
        45.988077
      ]
    },
    {
      "id": 7579,
      "name": "Agsu",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5680452,
        "lng": 48.3944412
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.3720715,
        40.5533149,
        48.4171289,
        40.5874046
      ]
    },
    {
      "id": 7580,
      "name": "Agstafa",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.11889,
        "lng": 45.45389
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.43389,
        41.09889,
        45.47389,
        41.13889
      ]
    },
    {
      "id": 7586,
      "name": "Comrat",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.301119,
        "lng": 28.6558805
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.6163305,
        46.2606818,
        28.6861817,
        46.35656
      ]
    },
    {
      "id": 7587,
      "name": "Guastatoya",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.854668,
        "lng": -90.068724
      },
      "country": "Guatemala",
      "importance": 1,
      "bbox": [
        -90.228724,
        14.694668,
        -89.908724,
        15.014668
      ]
    },
    {
      "id": 7588,
      "name": "Tecuala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.3978621,
        "lng": -105.457236
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.7603585,
        22.123955,
        -105.254364,
        22.572121
      ]
    },
    {
      "id": 7591,
      "name": "Qormi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.881104,
        "lng": 14.470623
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.456372,
        35.862176,
        14.486403,
        35.888743
      ]
    },
    {
      "id": 7592,
      "name": "Chitipa",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -9.7026075,
        "lng": 33.274819
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.234819,
        -9.7426075,
        33.314819,
        -9.6626075
      ]
    },
    {
      "id": 7593,
      "name": "Arvayheer",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 46.249979,
        "lng": 102.766585
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        102.746584838,
        46.2299792675,
        102.786584838,
        46.2699792675
      ]
    },
    {
      "id": 7594,
      "name": "Ciudad de Huitzuco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.30668,
        "lng": -99.33541
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.35541,
        18.28668,
        -99.31541,
        18.32668
      ]
    },
    {
      "id": 7596,
      "name": "Tepoztlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9861456,
        "lng": -99.0997958
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1397958,
        18.9461456,
        -99.0597958,
        19.0261456
      ]
    },
    {
      "id": 7597,
      "name": "Tlaxcala",
      "names": {
        "zh": "特拉斯卡拉",
        "ja": "トラスカラ",
        "th": "ตลัซกาลา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3167101,
        "lng": -98.2386138
      },
      "country": "Mexico",
      "importance": 1,
      "bbox": [
        -98.3986138,
        19.1567101,
        -98.0786138,
        19.4767101
      ]
    },
    {
      "id": 7599,
      "name": "Santiago Tulantepec",
      "names": {
        "zh": "圣地亚哥Tulantepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0363291,
        "lng": -98.3568451
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.3968451,
        19.9963291,
        -98.3168451,
        20.0763291
      ]
    },
    {
      "id": 7606,
      "name": "Hakha",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 22.650003,
        "lng": 93.616705
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        93.5967045958,
        22.6300031019,
        93.6367045958,
        22.6700031019
      ]
    },
    {
      "id": 7609,
      "name": "Ulcinj",
      "names": {
        "zh": "乌尔齐尼"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 41.926012,
        "lng": 19.2055563
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.1909223,
        41.9058433,
        19.2486262,
        41.9394778
      ]
    },
    {
      "id": 7610,
      "name": "Tidjikja",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 18.55644,
        "lng": -11.42715
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -11.44715,
        18.53644,
        -11.40715,
        18.57644
      ]
    },
    {
      "id": 7612,
      "name": "Ciudad Guadalupe Victoria",
      "names": {
        "zh": "Ciudad Guadalupe维多利亚",
        "th": "Ciudad Guadalupe วิคตอเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.0072015,
        "lng": -99.7078867
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.7078867,
        23.0072015,
        -99.7078626,
        23.0074694
      ]
    },
    {
      "id": 7617,
      "name": "Attapu",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 14.807657,
        "lng": 106.833887
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        106.813887,
        14.7876575,
        106.853887,
        14.8276575
      ]
    },
    {
      "id": 7618,
      "name": "Saynshand",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 44.8883913,
        "lng": 110.1431263
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        109.4688812,
        44.290983,
        110.234972,
        45.0693763
      ]
    },
    {
      "id": 7619,
      "name": "Kapsabet",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.2004978,
        "lng": 35.1011501
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.0905688,
        0.1965308,
        35.1077124,
        0.2065075
      ]
    },
    {
      "id": 7620,
      "name": "Kavieng",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -2.573474,
        "lng": 150.795224
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        150.755224,
        -2.613474,
        150.835224,
        -2.533474
      ]
    },
    {
      "id": 7624,
      "name": "Sligo",
      "names": {
        "zh": "斯莱戈",
        "ja": "スライゴ",
        "th": "สลิโก"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 54.2720696,
        "lng": -8.4751357
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -8.5441688,
        54.249064,
        -8.4420222,
        54.2889857
      ]
    },
    {
      "id": 7625,
      "name": "Inirida",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.86528,
        "lng": -67.92389
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -67.94389,
        3.84528,
        -67.90389,
        3.88528
      ]
    },
    {
      "id": 7627,
      "name": "Ciudad Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 14.6796753,
        "lng": -92.15239
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.19239,
        14.6396753,
        -92.11239,
        14.7196753
      ]
    },
    {
      "id": 7628,
      "name": "Cardenas",
      "names": {
        "es": "Cárdenas",
        "zh": "卡德纳斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.169369500000002,
        "lng": -93.70522924168885
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.1299658,
        17.9303862,
        -93.2808898,
        18.4081206
      ]
    },
    {
      "id": 7631,
      "name": "Faranah",
      "names": {
        "zh": "法拉纳"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.04036,
        "lng": -10.74343
      },
      "country": "Guinea",
      "importance": 1,
      "bbox": [
        -10.76343,
        10.02036,
        -10.72343,
        10.06036
      ]
    },
    {
      "id": 7633,
      "name": "Lezhe",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 41.7811313,
        "lng": 19.642414
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        19.642364,
        41.7810813,
        19.642464,
        41.7811813
      ]
    },
    {
      "id": 7638,
      "name": "Telavi",
      "names": {
        "zh": "泰拉维"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9158538,
        "lng": 45.4917915
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        45.4917415,
        41.9158038,
        45.4918415,
        41.9159038
      ]
    },
    {
      "id": 7642,
      "name": "Lons-le-Saunier",
      "names": {
        "zh": "隆勒索涅",
        "ja": "lons・ル・saunier",
        "th": "lons-เลอ-saunier"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.673817,
        "lng": 5.555609
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.532416,
        46.656531,
        5.582355,
        46.694966
      ]
    },
    {
      "id": 7645,
      "name": "La Cruz",
      "names": {
        "zh": "拉克鲁斯",
        "ja": "ラクルーズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 11.0088428,
        "lng": -85.55184913182646
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -85.9788778,
        10.7982049,
        -85.2300116,
        11.2195684
      ]
    },
    {
      "id": 7646,
      "name": "Mubende",
      "names": {
        "zh": "穆本德"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5678034,
        "lng": 31.3928822
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.3928322,
        0.5677534,
        31.3929322,
        0.5678534
      ]
    },
    {
      "id": 7650,
      "name": "Solothurn",
      "names": {
        "zh": "索洛图恩",
        "th": "โซโลทูร์น"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.2081355,
        "lng": 7.5384045
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        7.5093082,
        47.1959413,
        7.5523051,
        47.2202206
      ]
    },
    {
      "id": 7652,
      "name": "Grajales",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": -33.4529428,
        "lng": -70.6707792
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -70.6780827,
        -33.454539,
        -70.6648128,
        -33.4516083
      ]
    },
    {
      "id": 7653,
      "name": "Lai",
      "names": {
        "zh": "叶面积指数",
        "ja": "ライ",
        "th": "ลาย"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.3956566,
        "lng": 16.3018822
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        16.1418822,
        9.2356566,
        16.4618822,
        9.5556566
      ]
    },
    {
      "id": 7654,
      "name": "Son La",
      "names": {
        "es": "Hijo la",
        "fr": "Fils la",
        "zh": "儿子洛杉矶",
        "de": "Sohn la",
        "it": "Figlio la",
        "id": "Putra la",
        "ja": "息子のLa",
        "th": "ลูกชายลา",
        "pt": "Filho la"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.3274048,
        "lng": 103.9151701
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        103.802982,
        21.244577,
        104.029031,
        21.441667
      ]
    },
    {
      "id": 7656,
      "name": "Budva",
      "names": {
        "ja": "ブドヴァ"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.28639,
        "lng": 18.84
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.82,
        42.26639,
        18.86,
        42.30639
      ]
    },
    {
      "id": 7658,
      "name": "Tchibanga",
      "names": {
        "zh": "奇班加"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -2.9170995,
        "lng": 10.9955955
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        10.8355955,
        -3.0770995,
        11.1555955,
        -2.7570995
      ]
    },
    {
      "id": 7659,
      "name": "Macas",
      "names": {
        "zh": "马卡斯",
        "pt": "Maçãs"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.3046252,
        "lng": -78.1175466
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -78.2193969,
        -2.3435781,
        -78.1050463,
        -2.2661974
      ]
    },
    {
      "id": 7661,
      "name": "Tillaberi",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 14.2090125,
        "lng": 1.4546006
      },
      "country": "Niger",
      "importance": 1,
      "bbox": [
        1.2946006,
        14.0490125,
        1.6146006,
        14.3690125
      ]
    },
    {
      "id": 7663,
      "name": "Ban Houayxay",
      "names": {
        "zh": "禁止houayxay",
        "ja": "houayxayを禁止します",
        "th": "บ้านห้วยเซย์",
        "pt": "Ban Houayxoy"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 20.27652,
        "lng": 100.41302
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        100.39302,
        20.25652,
        100.43302,
        20.29652
      ]
    },
    {
      "id": 7675,
      "name": "Pljevlja",
      "names": {
        "zh": "普列夫利亚"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 43.3567,
        "lng": 19.35843
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.33843,
        43.3367,
        19.37843,
        43.3767
      ]
    },
    {
      "id": 7679,
      "name": "Pakwach",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.4608979,
        "lng": 31.498716
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.478716,
        2.4408979,
        31.518716,
        2.4808979
      ]
    },
    {
      "id": 7682,
      "name": "Tlaltetela",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.314647,
        "lng": -96.9016393
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9416393,
        19.274647,
        -96.8616393,
        19.354647
      ]
    },
    {
      "id": 7684,
      "name": "Ribeira Grande",
      "names": {
        "ja": "リベイラグランデ"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 17.1833547,
        "lng": -25.0643795
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -25.1043795,
        17.1433547,
        -25.0243795,
        17.2233547
      ]
    },
    {
      "id": 7685,
      "name": "Mao",
      "names": {
        "zh": "毛泽东",
        "ja": "真央",
        "th": "เหมา"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 39.8894922,
        "lng": 4.2662071
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        4.1564704,
        39.8308943,
        4.328026,
        40.0215358
      ]
    },
    {
      "id": 7689,
      "name": "Lagawe",
      "names": {
        "zh": "拉加韦"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.79996,
        "lng": 121.11924
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.09924,
        16.77996,
        121.13924,
        16.81996
      ]
    },
    {
      "id": 7690,
      "name": "Paraguari",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -25.6203507,
        "lng": -57.1505142
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -57.1905142,
        -25.6603507,
        -57.1105142,
        -25.5803507
      ]
    },
    {
      "id": 7693,
      "name": "Loreto",
      "names": {
        "zh": "洛雷托",
        "th": "เรโต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": -4,
        "lng": -75.166667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -76.5332611,
        -5.73926,
        -73.378232,
        -1.7937684
      ]
    },
    {
      "id": 7694,
      "name": "Hun",
      "names": {
        "zh": "浑",
        "de": "Hunne",
        "ja": "フン族",
        "th": "หุ่น"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 29.1245649,
        "lng": 15.9402605
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        15.7802605,
        28.9645649,
        16.1002605,
        29.2845649
      ]
    },
    {
      "id": 7695,
      "name": "Kimbe",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -5.5562921,
        "lng": 150.1529513
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        149.9929513,
        -5.7162921,
        150.3129513,
        -5.3962921
      ]
    },
    {
      "id": 7696,
      "name": "Dong Ha",
      "names": {
        "zh": "董哈",
        "ja": "ドンハ",
        "th": "ดงฮ่า"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.8172845,
        "lng": 107.1010378
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.028132,
        16.743651,
        107.1488365,
        16.8620592
      ]
    },
    {
      "id": 7697,
      "name": "Oteapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.999938,
        "lng": -94.6651694
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.7051694,
        17.959938,
        -94.6251694,
        18.039938
      ]
    },
    {
      "id": 7701,
      "name": "Visaginas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.59966,
        "lng": 26.4370071
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        26.4012999,
        55.568702,
        26.490773,
        55.6180662
      ]
    },
    {
      "id": 7702,
      "name": "Huixcolotla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.917386,
        "lng": -97.775203
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.788837,
        18.899667,
        -97.760428,
        18.932688
      ]
    },
    {
      "id": 7704,
      "name": "Mansa Konko",
      "names": {
        "ja": "マンサコンコ"
      },
      "countryCode": "GM",
      "latLng": {
        "lat": 13.46667,
        "lng": -15.55
      },
      "country": "The Gambia",
      "importance": 1,
      "bbox": [
        -15.57,
        13.44667,
        -15.53,
        13.48667
      ]
    },
    {
      "id": 7706,
      "name": "Rwamagana",
      "names": {},
      "countryCode": "RW",
      "latLng": {
        "lat": -1.951239,
        "lng": 30.434885
      },
      "country": "Rwanda",
      "importance": 1,
      "bbox": [
        30.274885,
        -2.111239,
        30.594885,
        -1.791239
      ]
    },
    {
      "id": 7707,
      "name": "Harnosand",
      "names": {
        "id": "Härnösand"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 62.6323084,
        "lng": 17.9379914
      },
      "country": "Sweden",
      "importance": 1,
      "bbox": [
        17.7779914,
        62.4723084,
        18.0979914,
        62.7923084
      ]
    },
    {
      "id": 7711,
      "name": "Hinche",
      "names": {
        "zh": "安什"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.1445541,
        "lng": -72.0087527
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -72.1687527,
        18.9845541,
        -71.8487527,
        19.3045541
      ]
    },
    {
      "id": 7713,
      "name": "Bitlis",
      "names": {
        "zh": "比特利斯",
        "ja": "ビトリス"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4020539,
        "lng": 42.1084568
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        41.79767,
        38.1577338,
        42.4212912,
        38.5029508
      ]
    },
    {
      "id": 7715,
      "name": "Vicente Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.7327632,
        "lng": -103.983692
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.023692,
        23.6927632,
        -103.943692,
        23.7727632
      ]
    },
    {
      "id": 7717,
      "name": "Cerritos",
      "names": {
        "zh": "塞里托斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.4300518,
        "lng": -100.2842442
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.3242442,
        22.3900518,
        -100.2442442,
        22.4700518
      ]
    },
    {
      "id": 7718,
      "name": "Juchique de Ferrer",
      "names": {
        "it": "JUCHIQQUE DE FERRER"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.839444,
        "lng": -96.6955464
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.7355464,
        19.799444,
        -96.6555464,
        19.879444
      ]
    },
    {
      "id": 7719,
      "name": "Siyazan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.074379,
        "lng": 49.110627
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 7723,
      "name": "Ptuj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4198096,
        "lng": 15.8717378
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.7751467,
        46.3856256,
        15.9461061,
        46.4974511
      ]
    },
    {
      "id": 7724,
      "name": "Sabirabad",
      "names": {
        "zh": "萨比拉巴德"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.008006,
        "lng": 48.475032
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.4497443,
        39.9739965,
        48.5052949,
        40.0237744
      ]
    },
    {
      "id": 7732,
      "name": "Tarrafal",
      "names": {
        "zh": "塔拉法尔"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.2784159,
        "lng": -23.752294
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.792294,
        15.2384159,
        -23.712294,
        15.3184159
      ]
    },
    {
      "id": 7733,
      "name": "Tsetserleg",
      "names": {
        "zh": "车车尔勒格"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 47.476602,
        "lng": 101.4532887
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        101.2636597,
        47.2880303,
        101.6596371,
        47.5602379
      ]
    },
    {
      "id": 7741,
      "name": "Straseni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.1474401,
        "lng": 28.6150196
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.4550196,
        46.9874401,
        28.7750196,
        47.3074401
      ]
    },
    {
      "id": 7745,
      "name": "Santa Rosalia",
      "names": {
        "zh": "圣塔洛韦亚洲",
        "ja": "サンタロザリア",
        "th": "ซานตาโรซาเลีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.3364279,
        "lng": -112.2680462
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -112.3080462,
        27.2964279,
        -112.2280462,
        27.3764279
      ]
    },
    {
      "id": 7748,
      "name": "Zonguldak",
      "names": {
        "zh": "宗古尔达克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.449425,
        "lng": 31.7783396
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        31.7782896,
        41.449375,
        31.7783896,
        41.449475
      ]
    },
    {
      "id": 7750,
      "name": "Zabbar",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.87790755,
        "lng": 14.536940384410409
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5317251,
        35.8703352,
        14.5426037,
        35.8853165
      ]
    },
    {
      "id": 7758,
      "name": "Kamuli",
      "names": {
        "zh": "卡穆利"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9445873,
        "lng": 33.1236818
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.0836818,
        0.9045873,
        33.1636818,
        0.9845873
      ]
    },
    {
      "id": 7759,
      "name": "Kedougou",
      "names": {
        "fr": "Kédougou",
        "zh": "凯杜古"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.5570752,
        "lng": -12.1855655
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -12.2397657,
        12.5322524,
        -12.1662537,
        12.5872949
      ]
    },
    {
      "id": 7761,
      "name": "Xaignabouli",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 19.25756,
        "lng": 101.71032
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        101.69032,
        19.23756,
        101.73032,
        19.27756
      ]
    },
    {
      "id": 7762,
      "name": "Filadelfia",
      "names": {
        "zh": "菲拉德尔菲亚"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -22.3525402,
        "lng": -60.0351707
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -60.580172,
        -22.5187171,
        -59.715013,
        -20.4565462
      ]
    },
    {
      "id": 7766,
      "name": "Prachin Buri",
      "names": {
        "th": "ปราจีนบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.054788,
        "lng": 101.376027
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.180323,
        13.866378,
        101.616302,
        14.193546
      ]
    },
    {
      "id": 7767,
      "name": "Erongaricuaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5888082,
        "lng": -101.7213149
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.8103032,
        19.521839,
        -101.6422725,
        19.702925
      ]
    },
    {
      "id": 7768,
      "name": "Salaspils",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8604988,
        "lng": 24.3531225
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.3481225,
        56.8554988,
        24.3581225,
        56.8654988
      ]
    },
    {
      "id": 7771,
      "name": "Sing Buri",
      "names": {
        "es": "Cantar buri",
        "fr": "Chanter Buri",
        "zh": "唱伯里",
        "it": "Cantare Buri",
        "id": "Bernyanyi buri",
        "ja": "ブリを歌う",
        "th": "สิงห์บุรี",
        "pt": "Cantar buri"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8935284,
        "lng": 100.3984227
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.3584227,
        14.8535284,
        100.4384227,
        14.9335284
      ]
    },
    {
      "id": 7773,
      "name": "Montargis",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.9978628,
        "lng": 2.7310072
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.717978,
        47.9872536,
        2.7596633,
        48.0122795
      ]
    },
    {
      "id": 7775,
      "name": "Torit",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 4.4134473,
        "lng": 32.5682244
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        32.4082244,
        4.2534473,
        32.7282244,
        4.5734473
      ]
    },
    {
      "id": 7777,
      "name": "Madera",
      "names": {
        "zh": "马德拉",
        "ja": "マデラ",
        "th": "มาเดรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.34672835,
        "lng": -108.2943956723704
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.761902,
        28.6876468,
        -107.7786874,
        30.0052151
      ]
    },
    {
      "id": 7778,
      "name": "Tunapuna",
      "names": {
        "zh": "图纳普纳"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6436181,
        "lng": -61.3907206
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.4307206,
        10.6036181,
        -61.3507206,
        10.6836181
      ]
    },
    {
      "id": 7780,
      "name": "Porto Novo",
      "names": {
        "zh": "波尔图诺沃",
        "ja": "ポルトノノ"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 17.0189775,
        "lng": -25.065316
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -25.105316,
        16.9789775,
        -25.025316,
        17.0589775
      ]
    },
    {
      "id": 7782,
      "name": "Viljandi",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.36454,
        "lng": 25.5878074
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        25.5498219,
        58.3301602,
        25.637545,
        58.3824667
      ]
    },
    {
      "id": 7783,
      "name": "Orange Walk",
      "names": {
        "es": "Paseo naranja",
        "fr": "Marche orange",
        "zh": "橙色步行",
        "de": "Orangefarben",
        "it": "Arancia",
        "id": "Berjalan-jalan",
        "ja": "オレンジウォーク",
        "th": "สีส้มเดิน",
        "pt": "Caminhada laranja"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 18.0804488,
        "lng": -88.5613615
      },
      "country": "Belize",
      "importance": 1,
      "bbox": [
        -88.5813176,
        18.0653671,
        -88.5541115,
        18.1003929
      ]
    },
    {
      "id": 7786,
      "name": "Saatli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.93214,
        "lng": 48.36892
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.34892,
        39.91214,
        48.38892,
        39.95214
      ]
    },
    {
      "id": 7792,
      "name": "Sa Kaeo",
      "names": {
        "th": "สระแก้ว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8194901,
        "lng": 102.0702537
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.0302537,
        13.7794901,
        102.1102537,
        13.8594901
      ]
    },
    {
      "id": 7795,
      "name": "Huitzilan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.971389,
        "lng": -97.695278
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.735278,
        19.931389,
        -97.655278,
        20.011389
      ]
    },
    {
      "id": 7798,
      "name": "Valparaiso",
      "names": {
        "es": "Valparaíso",
        "zh": "瓦尔帕莱索",
        "th": "บัลปาราอีโซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.5085309,
        "lng": -86.5027282
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -86.52922,
        30.455702,
        -86.478426,
        30.5353635
      ]
    },
    {
      "id": 7800,
      "name": "Dedza",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -14.3807275,
        "lng": 34.3282385
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.2882385,
        -14.4207275,
        34.3682385,
        -14.3407275
      ]
    },
    {
      "id": 7803,
      "name": "Khasab",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 26.1806084,
        "lng": 56.250884164303066
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        56.2358613,
        26.1578151,
        56.2599823,
        26.2037306
      ]
    },
    {
      "id": 7806,
      "name": "Juan Aldama",
      "names": {
        "zh": "胡安阿尔多玛",
        "ja": "フアンアルダマ",
        "th": "ฮวนอัลดามา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.2908382,
        "lng": -103.391896
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.431896,
        24.2508382,
        -103.351896,
        24.3308382
      ]
    },
    {
      "id": 7807,
      "name": "Gobabis",
      "names": {
        "zh": "戈巴比斯"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -22.45,
        "lng": 18.96667
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        18.94667,
        -22.47,
        18.98667,
        -22.43
      ]
    },
    {
      "id": 7811,
      "name": "Balancan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.806405,
        "lng": -91.537484
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.5466097,
        17.7888846,
        -91.5255661,
        17.8221909
      ]
    },
    {
      "id": 7812,
      "name": "Kukes",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 42.07271955,
        "lng": 20.42116318132266
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        20.3961602,
        42.043549,
        20.443448,
        42.101905
      ]
    },
    {
      "id": 7813,
      "name": "Pathum Thani",
      "names": {
        "th": "ปทุมธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.990079,
        "lng": 100.53753
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.452209,
        13.935211,
        100.618096,
        14.050074
      ]
    },
    {
      "id": 7815,
      "name": "Ezequiel Montes",
      "names": {
        "zh": "Ezequiel蒙特斯",
        "de": "Ezequiel monten"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6658195,
        "lng": -99.8979199
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.9103184,
        20.6452828,
        -99.8879121,
        20.6829704
      ]
    },
    {
      "id": 7822,
      "name": "Guachochi",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8193237,
        "lng": -107.0747317
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -107.1147317,
        26.7793237,
        -107.0347317,
        26.8593237
      ]
    },
    {
      "id": 7824,
      "name": "Tak",
      "names": {
        "zh": "德",
        "th": "ตาก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8722576,
        "lng": 99.1248355
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.0848355,
        16.8322576,
        99.1648355,
        16.9122576
      ]
    },
    {
      "id": 7827,
      "name": "Mehtar Lam",
      "names": {
        "ja": "メヒタールラム"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.67139,
        "lng": 70.20944
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        70.18944,
        34.65139,
        70.22944,
        34.69139
      ]
    },
    {
      "id": 7828,
      "name": "Fuzuli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.6007384,
        "lng": 47.1485708
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.1241974,
        39.5796133,
        47.1806186,
        39.6131975
      ]
    },
    {
      "id": 7829,
      "name": "Phayao",
      "names": {
        "zh": "拍尧",
        "th": "พะเยา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1666313,
        "lng": 99.9019423
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.8619423,
        19.1266313,
        99.9419423,
        19.2066313
      ]
    },
    {
      "id": 7833,
      "name": "Miahuatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.708879,
        "lng": -96.8710397
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9110397,
        19.668879,
        -96.8310397,
        19.748879
      ]
    },
    {
      "id": 7848,
      "name": "San Gwann",
      "names": {
        "zh": "圣金公司",
        "ja": "サングワン"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9093052,
        "lng": 14.4788042
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4620718,
        35.9023579,
        14.4903772,
        35.9170329
      ]
    },
    {
      "id": 7850,
      "name": "Marsabit",
      "names": {
        "zh": "马萨比特"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 2.33203105,
        "lng": 37.98590467109338
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.9849663,
        2.3311018,
        37.9868588,
        2.3328905
      ]
    },
    {
      "id": 7852,
      "name": "San Nicolas de los Ranchos",
      "names": {
        "zh": "圣尼古拉斯德洛斯·兰乔斯",
        "ja": "サンニコラスデロスランチョス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.070009,
        "lng": -98.487347
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4980182818,
        19.0624194497,
        -98.4769278922,
        19.0765665411
      ]
    },
    {
      "id": 7853,
      "name": "Illizi",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 26.504191,
        "lng": 8.479926
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.139639,
        25.147062,
        10.035767,
        27.829475
      ]
    },
    {
      "id": 7855,
      "name": "Trstenik",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.620214,
        "lng": 20.9984448
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.9598847,
        43.6032024,
        21.0244224,
        43.6350047
      ]
    },
    {
      "id": 7857,
      "name": "Calkini",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.368963,
        "lng": -90.049372
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.066992,
        20.343008,
        -90.031195,
        20.390231
      ]
    },
    {
      "id": 7859,
      "name": "Ma'rib",
      "names": {
        "zh": "马里卜"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.4578788,
        "lng": 45.3230242
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        45.1630242,
        15.2978788,
        45.4830242,
        15.6178788
      ]
    },
    {
      "id": 7862,
      "name": "Leeuwarden",
      "names": {
        "zh": "吕伐登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2005936,
        "lng": 5.7918548
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        5.7297688,
        53.1583048,
        5.9070999,
        53.2410981
      ]
    },
    {
      "id": 7865,
      "name": "Tempoal de Sanchez",
      "names": {
        "de": "Tempo de Sanchez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.521376,
        "lng": -98.38636
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4050890275,
        21.5109697094,
        -98.3713295064,
        21.533902024
      ]
    },
    {
      "id": 7866,
      "name": "Nacozari de Garcia",
      "names": {
        "ja": "ナコザリデガルシア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.37489,
        "lng": -109.68898
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.70898,
        30.35489,
        -109.66898,
        30.39489
      ]
    },
    {
      "id": 7869,
      "name": "Makokou",
      "names": {
        "zh": "马科库"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": 0.57381,
        "lng": 12.86419
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        12.84419,
        0.55381,
        12.88419,
        0.59381
      ]
    },
    {
      "id": 7873,
      "name": "Mexicaltzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2108625,
        "lng": -99.5867811
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.6267811,
        19.1708625,
        -99.5467811,
        19.2508625
      ]
    },
    {
      "id": 7875,
      "name": "Lodwar",
      "names": {
        "zh": "洛德瓦尔"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 3.1165858,
        "lng": 35.6022943
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.5622943,
        3.0765858,
        35.6422943,
        3.1565858
      ]
    },
    {
      "id": 7878,
      "name": "Marupe",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.90544,
        "lng": 24.05113
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.03113,
        56.88544,
        24.07113,
        56.92544
      ]
    },
    {
      "id": 7879,
      "name": "San Ignacio",
      "names": {
        "th": "ซานอิกนาซิโอ"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 17.1527588,
        "lng": -89.0761718
      },
      "country": "Belize",
      "importance": 1,
      "bbox": [
        -89.1085629,
        17.1235632,
        -89.0482262,
        17.17824
      ]
    },
    {
      "id": 7883,
      "name": "Ntungamo",
      "names": {
        "zh": "恩通加莫"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8715424,
        "lng": 30.2675905
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.2275905,
        -0.9115424,
        30.3075905,
        -0.8315424
      ]
    },
    {
      "id": 7889,
      "name": "Phrae",
      "names": {
        "th": "แพร่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.14177,
        "lng": 100.1425639
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.1025639,
        18.10177,
        100.1825639,
        18.18177
      ]
    },
    {
      "id": 7891,
      "name": "Keetmanshoop",
      "names": {
        "zh": "基特曼斯胡普"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -26.5790901,
        "lng": 18.1341909
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        18.1161742,
        -26.602294,
        18.1629245,
        -26.5588901
      ]
    },
    {
      "id": 7893,
      "name": "Astara",
      "names": {
        "zh": "阿斯塔拉"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.4445793,
        "lng": 48.8726356
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.8726356,
        38.4445793,
        48.8730226,
        38.4450884
      ]
    },
    {
      "id": 7894,
      "name": "Plunge",
      "names": {
        "es": "Hundir",
        "fr": "Plonger",
        "zh": "跳水",
        "de": "Tauchen",
        "it": "Tuffo",
        "id": "Terjun",
        "ja": "プランジ",
        "th": "กระโดด",
        "pt": "Mergulho"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9191417,
        "lng": 21.851950516512495
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.8517015,
        55.9190291,
        21.8522412,
        55.9192605
      ]
    },
    {
      "id": 7898,
      "name": "Puerto Carreno",
      "names": {
        "es": "Puerto Carreño",
        "fr": "Puerto Carréno",
        "zh": "波雷托克罗诺",
        "ja": "プエルトカロノー",
        "th": "เปอร์โตริโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1909225,
        "lng": -67.4841891
      },
      "country": "Colombia",
      "importance": 1,
      "bbox": [
        -68.8248316,
        5.3017926,
        -67.4084433,
        6.3287773
      ]
    },
    {
      "id": 7901,
      "name": "Tukums",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9669816,
        "lng": 23.1524528
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        23.1124528,
        56.9269816,
        23.1924528,
        57.0069816
      ]
    },
    {
      "id": 7903,
      "name": "Struga",
      "names": {
        "zh": "斯特鲁加"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1770278,
        "lng": 20.6790586
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.6343102,
        41.0800567,
        20.7068989,
        41.1981621
      ]
    },
    {
      "id": 7908,
      "name": "Kretinga",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.8900579,
        "lng": 21.2424112
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.1904438,
        55.8571112,
        21.2915928,
        55.9027962
      ]
    },
    {
      "id": 7915,
      "name": "Herceg Novi",
      "names": {
        "ja": "ヘルシュニューノヴィ"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.45306,
        "lng": 18.5375
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.5175,
        42.43306,
        18.5575,
        42.47306
      ]
    },
    {
      "id": 7922,
      "name": "Villa Hayes",
      "names": {
        "zh": "别墅海耶斯",
        "ja": "ヴィラヘイズ",
        "th": "วิลล่าเฮย์ส"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.0928654,
        "lng": -57.5246478
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -59.4172116,
        -25.3720309,
        -57.1513075,
        -23.0624618
      ]
    },
    {
      "id": 7925,
      "name": "Bordj Mokhtar",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 21.3292,
        "lng": 0.94791
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        0.92791,
        21.3092,
        0.96791,
        21.3492
      ]
    },
    {
      "id": 7935,
      "name": "Ranong",
      "names": {
        "zh": "拉廊",
        "th": "ระนอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.964128,
        "lng": 98.6340038
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        98.5940038,
        9.924128,
        98.6740038,
        10.004128
      ]
    },
    {
      "id": 7939,
      "name": "Rakvere",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3482229,
        "lng": 26.3617173
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        26.3256339,
        59.3224876,
        26.3975051,
        59.3670073
      ]
    },
    {
      "id": 7941,
      "name": "Mahikeng",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.863549,
        "lng": 25.6389346
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        25.6339346,
        -25.868549,
        25.6439346,
        -25.858549
      ]
    },
    {
      "id": 7942,
      "name": "Moussoro",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 13.64084,
        "lng": 16.49007
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        16.47007,
        13.62084,
        16.51007,
        13.66084
      ]
    },
    {
      "id": 7943,
      "name": "Aldama",
      "names": {
        "zh": "阿尔达马"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.959179300000002,
        "lng": -98.04968098713654
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.3677007,
        22.5490016,
        -97.7609664,
        23.3701407
      ]
    },
    {
      "id": 7948,
      "name": "Nakhon Nayok",
      "names": {
        "it": "Nayok nakhon",
        "ja": "ナコン・ナヨク",
        "th": "นครนายก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.203647,
        "lng": 101.2147087
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        101.1747087,
        14.163647,
        101.2547087,
        14.243647
      ]
    },
    {
      "id": 7950,
      "name": "Ardahan",
      "names": {
        "zh": "阿尔达汉"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.11269325,
        "lng": 42.70501630734768
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        42.6834839,
        41.099283,
        42.7221753,
        41.1261181
      ]
    },
    {
      "id": 7951,
      "name": "Naama",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.2647342,
        "lng": -0.3110393
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -0.6708877,
        33.002642,
        0.025158,
        33.5455022
      ]
    },
    {
      "id": 7954,
      "name": "Kabugao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.022963,
        "lng": 121.1841242
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.1441242,
        17.982963,
        121.2241242,
        18.062963
      ]
    },
    {
      "id": 7955,
      "name": "Koulamoutou",
      "names": {
        "zh": "库拉穆图"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -1.13032,
        "lng": 12.47359
      },
      "country": "Gabon",
      "importance": 1,
      "bbox": [
        12.45359,
        -1.15032,
        12.49359,
        -1.11032
      ]
    },
    {
      "id": 7958,
      "name": "Backa Topola",
      "names": {
        "id": "Baca topola",
        "ja": "バックアトポーラ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.8121657,
        "lng": 19.6304705
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5874786,
        45.7373093,
        19.7428589,
        45.859265
      ]
    },
    {
      "id": 7962,
      "name": "Kinkala",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": -4.3565973,
        "lng": 14.7587752
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        14.7187752,
        -4.3965973,
        14.7987752,
        -4.3165973
      ]
    },
    {
      "id": 7969,
      "name": "Ascension",
      "names": {
        "es": "Ascensión",
        "zh": "升天",
        "de": "Himmelfahrt",
        "it": "Ascensione",
        "id": "Kenaikan",
        "ja": "アセンション",
        "th": "เสด็จขึ้นสู่สวรรค์",
        "pt": "Ascensão"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.2116442,
        "lng": -90.9186104
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.1066033,
        30.0626034,
        -90.632332,
        30.3470644
      ]
    },
    {
      "id": 7970,
      "name": "Angel R. Cabada",
      "names": {
        "zh": "天使r. cabada",
        "ja": "天使R.カバダ",
        "th": "แองเจิลอาร์ Cabada"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.594444,
        "lng": -95.445833
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.485833,
        18.554444,
        -95.405833,
        18.634444
      ]
    },
    {
      "id": 7971,
      "name": "Causeni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.6395913,
        "lng": 29.4056631
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.3656631,
        46.5995913,
        29.4456631,
        46.6795913
      ]
    },
    {
      "id": 7977,
      "name": "Silute",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.3471647,
        "lng": 21.4843234
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.4842734,
        55.3471147,
        21.4843734,
        55.3472147
      ]
    },
    {
      "id": 7978,
      "name": "Villa Corzo",
      "names": {
        "ja": "ヴィラコルゾ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.1827659,
        "lng": -93.2674551
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.3074551,
        16.1427659,
        -93.2274551,
        16.2227659
      ]
    },
    {
      "id": 7979,
      "name": "Bijelo Polje",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 43.0341595,
        "lng": 19.7473559
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.7257439,
        42.9878714,
        19.7789513,
        43.0525384
      ]
    },
    {
      "id": 7980,
      "name": "Anadyr",
      "names": {
        "zh": "阿纳德尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.73424,
        "lng": 177.5103
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        177.4903,
        64.71424,
        177.5303,
        64.75424
      ]
    },
    {
      "id": 7983,
      "name": "Maliana",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.99167,
        "lng": 125.21972
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.19972,
        -9.01167,
        125.23972,
        -8.97167
      ]
    },
    {
      "id": 7984,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": 37.9357576,
        "lng": -122.347748
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        -122.441484,
        37.88364,
        -122.2434491,
        38.0285668
      ]
    },
    {
      "id": 7986,
      "name": "Isla Mujeres",
      "names": {
        "ja": "イスラミューエス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.222949,
        "lng": -86.727351
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -86.753072,
        21.203936,
        -86.713241,
        21.265224
      ]
    },
    {
      "id": 7990,
      "name": "Altay",
      "names": {
        "zh": "阿勒泰"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 46.3643389,
        "lng": 96.2532498
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        96.0932498,
        46.2043389,
        96.4132498,
        46.5243389
      ]
    },
    {
      "id": 7991,
      "name": "Bartica",
      "names": {
        "zh": "巴蒂卡"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.4053312,
        "lng": -58.6214256
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -58.7814256,
        6.2453312,
        -58.4614256,
        6.5653312
      ]
    },
    {
      "id": 7993,
      "name": "Whakatane",
      "names": {
        "zh": "瓦卡塔尼"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.958777,
        "lng": 176.978597
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        176.906839,
        -38.033935,
        177.068761,
        -37.920827
      ]
    },
    {
      "id": 7996,
      "name": "Mus",
      "names": {
        "zh": "亩"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7322221,
        "lng": 41.4898925
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        41.1044966,
        38.4829853,
        41.8822888,
        39.0693914
      ]
    },
    {
      "id": 7997,
      "name": "Sukhothai",
      "names": {
        "zh": "素可泰",
        "th": "สุโขทัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.010918,
        "lng": 99.8203682
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.7803682,
        16.970918,
        99.8603682,
        17.050918
      ]
    },
    {
      "id": 7998,
      "name": "Villa Juarez",
      "names": {
        "es": "Villa Juárez",
        "zh": "别墅Juarez",
        "ja": "ヴィラフアレス",
        "th": "วิลล่า Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.1290417,
        "lng": -109.8387252
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.8787252,
        27.0890417,
        -109.7987252,
        27.1690417
      ]
    },
    {
      "id": 8003,
      "name": "Jaltenango",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.87314,
        "lng": -92.72437
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8006,
      "name": "Peshkopi",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 41.6848768,
        "lng": 20.4287139
      },
      "country": "Albania",
      "importance": 1,
      "bbox": [
        20.4201987,
        41.6765824,
        20.4422323,
        41.6904764
      ]
    },
    {
      "id": 8008,
      "name": "Erzincan",
      "names": {
        "zh": "埃尔津詹",
        "ja": "エルジンジャン"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7467552,
        "lng": 39.49103
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        39.130223,
        39.4412085,
        39.8080337,
        39.9523813
      ]
    },
    {
      "id": 8009,
      "name": "Kiboga",
      "names": {
        "zh": "基博加"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9155667,
        "lng": 31.7668345
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.7268345,
        0.8755667,
        31.8068345,
        0.9555667
      ]
    },
    {
      "id": 8012,
      "name": "Uthai Thani",
      "names": {
        "de": "Athai danke",
        "ja": "UTHAI THENI",
        "th": "อุทัยธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.3824774,
        "lng": 100.0277754
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        99.9877754,
        15.3424774,
        100.0677754,
        15.4224774
      ]
    },
    {
      "id": 8015,
      "name": "Edinet",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.168874,
        "lng": 27.304445
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.284445,
        48.148874,
        27.324445,
        48.188874
      ]
    },
    {
      "id": 8017,
      "name": "Nuevo Ideal",
      "names": {
        "fr": "Nuevo Idéal",
        "zh": "Nuevo理想",
        "it": "Nuevo ideale",
        "ja": "Nuevoは理想的です",
        "th": "Nuevo เหมาะอย่างยิ่ง"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8878603,
        "lng": -105.072073
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.112073,
        24.8478603,
        -105.032073,
        24.9278603
      ]
    },
    {
      "id": 8018,
      "name": "Palanga",
      "names": {
        "zh": "帕兰加",
        "th": "ปาลังกา"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9229818,
        "lng": 21.0682657
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.0489306,
        55.855176,
        21.1389207,
        56.0771676
      ]
    },
    {
      "id": 8021,
      "name": "Saucillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 28.0291667,
        "lng": -105.2923449
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.3323449,
        27.9891667,
        -105.2523449,
        28.0691667
      ]
    },
    {
      "id": 8024,
      "name": "Tarxien",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8648857,
        "lng": 14.5114877
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.50585,
        35.8615018,
        14.5206456,
        35.8715597
      ]
    },
    {
      "id": 8027,
      "name": "Santa Maria Jalapa del Marques",
      "names": {
        "es": "Santa María Jalapa del Marques",
        "zh": "圣玛丽亚Jalapa del Marques",
        "ja": "サンタマリアジャラパデルマルケス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4403557,
        "lng": -95.4448148
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.4848148,
        16.4003557,
        -95.4048148,
        16.4803557
      ]
    },
    {
      "id": 8028,
      "name": "Phra Samut Chedi",
      "names": {
        "th": "พระสมุทรเจดีย์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5964634,
        "lng": 100.582382
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.540994,
        13.586464,
        100.5926227,
        13.6185647
      ]
    },
    {
      "id": 8030,
      "name": "Schwyz",
      "names": {
        "zh": "施维茨"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.019993,
        "lng": 8.656108
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.638355,
        46.990723,
        8.695486,
        47.065838
      ]
    },
    {
      "id": 8031,
      "name": "Kurdamir",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.34532,
        "lng": 48.15085
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.13085,
        40.32532,
        48.17085,
        40.36532
      ]
    },
    {
      "id": 8034,
      "name": "Rayon",
      "names": {
        "es": "Rayón",
        "fr": "Rayonne",
        "zh": "人造丝",
        "ja": "レーヨン",
        "th": "เรยอน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.13907585,
        "lng": -99.56755510454704
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.6084756,
        19.1204375,
        -99.5302163,
        19.1578084
      ]
    },
    {
      "id": 8040,
      "name": "Dzuunmod",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 47.70694,
        "lng": 106.95278
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        106.93278,
        47.68694,
        106.97278,
        47.72694
      ]
    },
    {
      "id": 8041,
      "name": "El Fuerte",
      "names": {
        "zh": "el fererte"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.4155546,
        "lng": -108.6172584
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.6317277,
        26.4048631,
        -108.6027705,
        26.4331288
      ]
    },
    {
      "id": 8044,
      "name": "Kamnik",
      "names": {
        "zh": "卡姆尼克"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2257358,
        "lng": 14.6118936
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.5353419,
        46.1822956,
        14.9282181,
        46.3676972
      ]
    },
    {
      "id": 8046,
      "name": "Cadereyta",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3843863,
        "lng": -99.2163369
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2164139,
        19.3840177,
        -99.2159547,
        19.384763
      ]
    },
    {
      "id": 8050,
      "name": "Santa Maria Huazolotitlan",
      "names": {
        "fr": "Santa Maria HuazoloTlan",
        "ja": "サンタマリアHuazolotitlan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.301389,
        "lng": -97.9125
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.9525,
        16.261389,
        -97.8725,
        16.341389
      ]
    },
    {
      "id": 8056,
      "name": "Radviliskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.8054522,
        "lng": 23.5370177
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.5369677,
        55.8054022,
        23.5370677,
        55.8055022
      ]
    },
    {
      "id": 8058,
      "name": "Yozgat",
      "names": {
        "zh": "约兹加特",
        "ja": "ヨズガト"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8221974,
        "lng": 34.8080972
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        34.6480972,
        39.6621974,
        34.9680972,
        39.9821974
      ]
    },
    {
      "id": 8061,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 33.7494951,
        "lng": -117.873221
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -117.9439459,
        33.6916541,
        -117.8303495,
        33.7840133
      ]
    },
    {
      "id": 8064,
      "name": "Phongsali",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 21.68372,
        "lng": 102.10536
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        102.08536,
        21.66372,
        102.12536,
        21.70372
      ]
    },
    {
      "id": 8068,
      "name": "Liestal",
      "names": {
        "zh": "利斯塔尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4839723,
        "lng": 7.7347783
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        7.669882,
        47.4629599,
        7.7843616,
        47.5034239
      ]
    },
    {
      "id": 8069,
      "name": "Escuintla",
      "names": {
        "zh": "埃斯昆特拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.3198033,
        "lng": -92.6594468
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.6994468,
        15.2798033,
        -92.6194468,
        15.3598033
      ]
    },
    {
      "id": 8071,
      "name": "Paro",
      "names": {
        "es": "En paro",
        "zh": "帕罗",
        "th": "พาโร"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.4307674,
        "lng": 89.412987
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.252987,
        27.2707674,
        89.572987,
        27.5907674
      ]
    },
    {
      "id": 8076,
      "name": "Quthing",
      "names": {
        "zh": "古廷"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -30.40001,
        "lng": 27.70027
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        27.68027,
        -30.42001,
        27.72027,
        -30.38001
      ]
    },
    {
      "id": 8078,
      "name": "Kidal",
      "names": {
        "zh": "基达尔"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 19.802089000000002,
        "lng": 0.7253124568557916
      },
      "country": "Mali",
      "importance": 1,
      "bbox": [
        -2.056,
        17.72672,
        4.2670949,
        21.8852937
      ]
    },
    {
      "id": 8081,
      "name": "Gwanda",
      "names": {
        "zh": "关达"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -20.9414292,
        "lng": 29.0036855
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        28.9636855,
        -20.9814292,
        29.0436855,
        -20.9014292
      ]
    },
    {
      "id": 8082,
      "name": "San Pedro Jicayan",
      "names": {
        "zh": "圣佩德罗·贾亚扬",
        "ja": "サンペドロジカヤン",
        "th": "ซานเปโดรเจวิคยัน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.450278,
        "lng": -98.015
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.035,
        16.430278,
        -97.995,
        16.470278
      ]
    },
    {
      "id": 8083,
      "name": "Masalli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.0322111,
        "lng": 48.6721093
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.649802,
        39.0166096,
        48.6995664,
        39.0515544
      ]
    },
    {
      "id": 8085,
      "name": "Fayroz Koh",
      "names": {
        "ja": "フェイロツKOH",
        "th": "Fayroz เกาะ"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.51952,
        "lng": 65.25093
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        65.23093,
        34.49952,
        65.27093,
        34.53952
      ]
    },
    {
      "id": 8089,
      "name": "Escaldes-Engordany",
      "names": {
        "zh": "escaldes-恩戈尔达"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5090019,
        "lng": 1.5404067
      },
      "country": "Andorra",
      "importance": 1,
      "bbox": [
        1.5004067,
        42.4690019,
        1.5804067,
        42.5490019
      ]
    },
    {
      "id": 8090,
      "name": "Baucau",
      "names": {
        "zh": "包考"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -8.4650462,
        "lng": 126.4585621
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        126.4185621,
        -8.5050462,
        126.4985621,
        -8.4250462
      ]
    },
    {
      "id": 8091,
      "name": "Sironko",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.2315295,
        "lng": 34.2457702
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.2057702,
        1.1915295,
        34.2857702,
        1.2715295
      ]
    },
    {
      "id": 8093,
      "name": "Forecariah",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 9.4300088,
        "lng": -13.0833859
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -13.1233859,
        9.3900088,
        -13.0433859,
        9.4700088
      ]
    },
    {
      "id": 8099,
      "name": "Cesis",
      "names": {
        "zh": "采西斯"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.3131712,
        "lng": 25.2748551
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        25.2133947,
        57.2880236,
        25.3196875,
        57.3383039
      ]
    },
    {
      "id": 8101,
      "name": "Altamirano",
      "names": {
        "zh": "阿尔塔米拉诺"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7338611,
        "lng": -92.0381288
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.0781288,
        16.6938611,
        -91.9981288,
        16.7738611
      ]
    },
    {
      "id": 8104,
      "name": "Jesenice",
      "names": {
        "zh": "耶塞尼采"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4361827,
        "lng": 14.0548215
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0498215,
        46.4311827,
        14.0598215,
        46.4411827
      ]
    },
    {
      "id": 8108,
      "name": "Ozurgeti",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9280349,
        "lng": 42.0052732
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        42.0002732,
        41.9230349,
        42.0102732,
        41.9330349
      ]
    },
    {
      "id": 8112,
      "name": "Tadjourah",
      "names": {},
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.783199,
        "lng": 42.8813227
      },
      "country": "Djibouti",
      "importance": 1,
      "bbox": [
        42.8812727,
        11.783149,
        42.8813727,
        11.783249
      ]
    },
    {
      "id": 8113,
      "name": "Tierra Colorada",
      "names": {
        "ja": "ティエラコロラダ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.166111,
        "lng": -99.529444
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.569444,
        17.126111,
        -99.489444,
        17.206111
      ]
    },
    {
      "id": 8115,
      "name": "Eisenstadt",
      "names": {
        "zh": "艾森斯塔特"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.846682,
        "lng": 16.522199
      },
      "country": "Austria",
      "importance": 1,
      "bbox": [
        16.511801,
        47.823286,
        16.541947,
        47.862545
      ]
    },
    {
      "id": 8117,
      "name": "Villa Union",
      "names": {
        "fr": "Villa",
        "zh": "别墅联盟",
        "it": "Villaggio",
        "id": "Uni Villa",
        "ja": "ヴィラユニオン",
        "pt": "União da Villa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.0963857,
        "lng": -100.79781255580832
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.0330334,
        27.8214386,
        -100.5381234,
        28.3774648
      ]
    },
    {
      "id": 8118,
      "name": "Luganville",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -15.5121107,
        "lng": 167.1784414
      },
      "country": "Vanuatu",
      "importance": 1,
      "bbox": [
        167.0184414,
        -15.6721107,
        167.3384414,
        -15.3521107
      ]
    },
    {
      "id": 8119,
      "name": "Rorschach",
      "names": {
        "zh": "罗夏"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.477906,
        "lng": 9.495147
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.478914,
        47.469892,
        9.524314,
        47.54711
      ]
    },
    {
      "id": 8123,
      "name": "Ondorhaan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 47.31944,
        "lng": 110.65556
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        110.63556,
        47.29944,
        110.67556,
        47.33944
      ]
    },
    {
      "id": 8124,
      "name": "Debar",
      "names": {
        "es": "Vedar",
        "fr": "Interdire",
        "zh": "禁止",
        "de": "Verhindern",
        "it": "Precludere",
        "id": "Mencegah",
        "ja": "禁ずる",
        "th": "ห้าม",
        "pt": "Excluir"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.523466,
        "lng": 20.5269779
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.4869779,
        41.483466,
        20.5669779,
        41.563466
      ]
    },
    {
      "id": 8125,
      "name": "Sonoita",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 31.862287,
        "lng": -112.85025
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -112.874381,
        31.849814,
        -112.812679,
        31.883633
      ]
    },
    {
      "id": 8130,
      "name": "Pierre",
      "names": {
        "zh": "皮埃尔·",
        "ja": "ピエール",
        "th": "ปิแอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.3683644,
        "lng": -100.351136
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -100.3717427,
        44.3406559,
        -100.269211,
        44.400319
      ]
    },
    {
      "id": 8131,
      "name": "Djanet",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 24.5538847,
        "lng": 9.484194
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        7.65723,
        22.496408,
        11.997337,
        25.1534172
      ]
    },
    {
      "id": 8133,
      "name": "Maxcanu",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5845152,
        "lng": -90.0005727
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.0208821,
        20.5719223,
        -89.9823951,
        20.5982409
      ]
    },
    {
      "id": 8135,
      "name": "Kisoro",
      "names": {
        "zh": "基索罗"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -1.2822138,
        "lng": 29.6926665
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        29.6526665,
        -1.3222138,
        29.7326665,
        -1.2422138
      ]
    },
    {
      "id": 8137,
      "name": "Coacoatzintla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.653131,
        "lng": -96.940968
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.948478,
        19.645194,
        -96.932965,
        19.660163
      ]
    },
    {
      "id": 8142,
      "name": "Naxxar",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9133396,
        "lng": 14.4432851
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4188688,
        35.9045999,
        14.4639711,
        35.954861
      ]
    },
    {
      "id": 8144,
      "name": "Basse Santa Su",
      "names": {
        "ja": "サンタスース"
      },
      "countryCode": "GM",
      "latLng": {
        "lat": 13.31004,
        "lng": -14.2150033
      },
      "country": "The Gambia",
      "importance": 1,
      "bbox": [
        -14.2550033,
        13.27004,
        -14.1750033,
        13.35004
      ]
    },
    {
      "id": 8147,
      "name": "Baruun-Urt",
      "names": {
        "zh": "baruun-法院"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 46.68056,
        "lng": 113.27917
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        113.25917,
        46.66056,
        113.29917,
        46.70056
      ]
    },
    {
      "id": 8150,
      "name": "Port Antonio",
      "names": {
        "es": "Antonio",
        "zh": "港口安东尼奥",
        "it": "Porto Antonio",
        "ja": "ポートアントニオ",
        "th": "พอร์ตอันโตนิโอ"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.175168,
        "lng": -76.4474201
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -76.4738171,
        18.1607732,
        -76.418113,
        18.1894141
      ]
    },
    {
      "id": 8154,
      "name": "Gabu",
      "names": {
        "zh": "加布"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 12.2819517,
        "lng": -14.2260818
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -14.3860818,
        12.1219517,
        -14.0660818,
        12.4419517
      ]
    },
    {
      "id": 8157,
      "name": "Cuencame de Ceniceros",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.868598,
        "lng": -103.697663
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.710967,
        24.851462,
        -103.685917,
        24.883942
      ]
    },
    {
      "id": 8158,
      "name": "Sigulda",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.1531642,
        "lng": 24.8555149
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.8505149,
        57.1481642,
        24.8605149,
        57.1581642
      ]
    },
    {
      "id": 8161,
      "name": "Ucar",
      "names": {
        "zh": "炭素公司"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5185386,
        "lng": 47.6487809
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.6098645,
        40.4888557,
        47.6743158,
        40.5310689
      ]
    },
    {
      "id": 8165,
      "name": "Marsaskala",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.866889,
        "lng": 14.566907
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.546951,
        35.855464,
        14.576023,
        35.879051
      ]
    },
    {
      "id": 8168,
      "name": "Kilis",
      "names": {
        "zh": "基利斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.7165552,
        "lng": 37.1146069
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        36.7141413,
        36.6341999,
        37.3685,
        36.8802029
      ]
    },
    {
      "id": 8169,
      "name": "Neftcala",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.3790532,
        "lng": 49.248607
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        49.2271838,
        39.3441374,
        49.2566467,
        39.4126999
      ]
    },
    {
      "id": 8170,
      "name": "Qusar",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.42728,
        "lng": 48.42893
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.40893,
        41.40728,
        48.44893,
        41.44728
      ]
    },
    {
      "id": 8179,
      "name": "Canatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.523132,
        "lng": -104.774146
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.791628,
        24.51165,
        -104.751909,
        24.538453
      ]
    },
    {
      "id": 8185,
      "name": "Sjenica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2729348,
        "lng": 19.9991727
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.9550055,
        43.2457362,
        20.0623412,
        43.2955567
      ]
    },
    {
      "id": 8190,
      "name": "Akhaltsikhe",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.6395128,
        "lng": 42.9859225
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        42.9580303,
        41.6089469,
        43.0417192,
        41.6623977
      ]
    },
    {
      "id": 8191,
      "name": "Chinsali",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -10.549972,
        "lng": 32.0660837
      },
      "country": "Zambia",
      "importance": 1,
      "bbox": [
        32.0260837,
        -10.589972,
        32.1060837,
        -10.509972
      ]
    },
    {
      "id": 8194,
      "name": "Cetinje",
      "names": {
        "zh": "采蒂涅"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.392165,
        "lng": 18.923124
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.884887,
        42.371932,
        18.943821,
        42.40686
      ]
    },
    {
      "id": 8203,
      "name": "Nacaome",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 13.5325499,
        "lng": -87.4881391
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -87.650508,
        13.3149435,
        -87.3672706,
        13.6696227
      ]
    },
    {
      "id": 8206,
      "name": "Kuressaare",
      "names": {
        "zh": "库雷斯撒雷"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.2527693,
        "lng": 22.4849207
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        22.440487,
        58.2128334,
        22.5273701,
        58.2732539
      ]
    },
    {
      "id": 8207,
      "name": "Etchojoa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.9106032,
        "lng": -109.6273305
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.6673305,
        26.8706032,
        -109.5873305,
        26.9506032
      ]
    },
    {
      "id": 8211,
      "name": "Gonzalez",
      "names": {
        "es": "González",
        "zh": "·冈萨雷斯",
        "ja": "ゴンザレス",
        "th": "กอนซาเลซ",
        "pt": "Gonzales"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.790387350000003,
        "lng": -98.57631895542931
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.907307,
        22.3880068,
        -98.2892494,
        23.1925646
      ]
    },
    {
      "id": 8214,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 10.4444032,
        "lng": -75.2730468
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -75.3346953,
        10.3581539,
        -75.1761562,
        10.5264203
      ]
    },
    {
      "id": 8215,
      "name": "Plav",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.5968969,
        "lng": 19.9441846
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.927268,
        42.5906971,
        19.9496967,
        42.6104169
      ]
    },
    {
      "id": 8217,
      "name": "Saint Ann's Bay",
      "names": {
        "es": "Bahía de San Ann",
        "zh": "圣安的湾",
        "ja": "セントアンズベイ",
        "th": "อ่าวเซนต์แอน",
        "pt": "Baía de Saint Ann"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 10.685377,
        "lng": -61.505992
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -61.506414,
        10.681578,
        -61.505159,
        10.689451
      ]
    },
    {
      "id": 8224,
      "name": "Bar",
      "names": {
        "zh": "杠",
        "ja": "バー",
        "th": "บาร์",
        "pt": "Barra"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.0979745,
        "lng": 19.0954528
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.067977,
        42.0769512,
        19.1083042,
        42.1224791
      ]
    },
    {
      "id": 8226,
      "name": "Kumi",
      "names": {
        "zh": "来未",
        "ja": "クミ",
        "th": "สิบ"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.4565401,
        "lng": 33.92191269014211
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.7276033,
        1.2592849,
        34.2287646,
        1.6537668
      ]
    },
    {
      "id": 8229,
      "name": "Tovuz",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.992505,
        "lng": 45.6159428
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.5933744,
        40.9739689,
        45.635874,
        41.0077974
      ]
    },
    {
      "id": 8232,
      "name": "Ang Thong",
      "names": {
        "fr": "Lanières",
        "zh": "ang丁字裤",
        "de": "Ang Tanga",
        "ja": "アンクトン",
        "th": "อ่างทอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5924644,
        "lng": 100.4513754
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.4113754,
        14.5524644,
        100.4913754,
        14.6324644
      ]
    },
    {
      "id": 8234,
      "name": "Chichihualco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.65396,
        "lng": -99.675702
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.686201,
        17.644026,
        -99.665046,
        17.669552
      ]
    },
    {
      "id": 8235,
      "name": "Naifaru",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.44438,
        "lng": 73.36571
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.34571,
        5.42438,
        73.38571,
        5.46438
      ]
    },
    {
      "id": 8240,
      "name": "Imsida",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8956943,
        "lng": 14.4891829
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4735485,
        35.8910364,
        14.4941632,
        35.9044328
      ]
    },
    {
      "id": 8242,
      "name": "Da Nang",
      "names": {
        "zh": "岘港",
        "ja": "ダナン",
        "th": "ดานัง",
        "pt": "Deitar-se"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.068,
        "lng": 108.212
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.8185394,
        15.9179902,
        108.6563747,
        16.3340091
      ]
    },
    {
      "id": 8246,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 10.62017945,
        "lng": -84.42581794981697
      },
      "country": "Nicaragua",
      "importance": 1,
      "bbox": [
        -84.8631759,
        10.2449385,
        -84.1611432,
        10.9958256
      ]
    },
    {
      "id": 8247,
      "name": "Sinop",
      "names": {
        "zh": "锡诺普"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 42.0265798,
        "lng": 35.1511512
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        34.810107,
        41.6638385,
        35.2113694,
        42.0980451
      ]
    },
    {
      "id": 8255,
      "name": "Tetela del Volcan",
      "names": {
        "es": "Tetela del Volcán",
        "de": "Tetela del vulkan",
        "it": "Tetela del vulcan",
        "ja": "テテラデル火山"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8934217,
        "lng": -98.7291162
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.7691162,
        18.8534217,
        -98.6891162,
        18.9334217
      ]
    },
    {
      "id": 8257,
      "name": "Platon Sanchez",
      "names": {
        "zh": "柏拉图桑切斯",
        "ja": "プラトンサンチェス",
        "th": "พลาตันซานเชซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.276173,
        "lng": -98.375196
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.388399,
        21.262165,
        -98.359433,
        21.286482
      ]
    },
    {
      "id": 8260,
      "name": "Faya",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 17.9209323,
        "lng": 19.0953202
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        18.9353202,
        17.7609323,
        19.2553202,
        18.0809323
      ]
    },
    {
      "id": 8261,
      "name": "Mwanza",
      "names": {
        "zh": "姆万扎"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -15.61360315,
        "lng": 34.51465953401936
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.3525186,
        -15.8157185,
        34.7267166,
        -15.4113208
      ]
    },
    {
      "id": 8262,
      "name": "Domzale",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.139881,
        "lng": 14.598007
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.5788,
        46.122402,
        14.612263,
        46.168263
      ]
    },
    {
      "id": 8264,
      "name": "Gzira",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.905426,
        "lng": 14.492684
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.487072,
        35.8990148062,
        14.5109070924,
        35.909942
      ]
    },
    {
      "id": 8269,
      "name": "Kirsehir",
      "names": {
        "zh": "克尔谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1461142,
        "lng": 34.1605587
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        33.6816376,
        38.8199336,
        34.3167,
        39.5194319
      ]
    },
    {
      "id": 8270,
      "name": "Acanceh",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8132499,
        "lng": -89.4529359
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.4700836,
        20.7994467,
        -89.4395186,
        20.8266148
      ]
    },
    {
      "id": 8272,
      "name": "Swieqi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9236256,
        "lng": 14.469548
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4549474,
        35.912615,
        14.4869733,
        35.9335462
      ]
    },
    {
      "id": 8273,
      "name": "Mariental",
      "names": {
        "zh": "马林塔尔"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -24.6246442,
        "lng": 17.9626696
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        17.9480136,
        -24.6453812,
        17.9939047,
        -24.605137
      ]
    },
    {
      "id": 8274,
      "name": "Drochia",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.0308608,
        "lng": 27.8098753
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.8048753,
        48.0258608,
        27.8148753,
        48.0358608
      ]
    },
    {
      "id": 8275,
      "name": "Zebbug",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.872964499999995,
        "lng": 14.441708119820028
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4333655,
        35.8669517,
        14.4518444,
        35.8787137
      ]
    },
    {
      "id": 8277,
      "name": "Delemont",
      "names": {
        "fr": "Delémont",
        "de": "Delémont",
        "it": "Delémont",
        "id": "Delémont",
        "pt": "Delémont"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3620492,
        "lng": 7.3501557
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        7.3451557,
        47.3570492,
        7.3551557,
        47.3670492
      ]
    },
    {
      "id": 8280,
      "name": "Nieuw Nickerie",
      "names": {
        "fr": "Nickerie Nieuw",
        "id": "Nieuw naleri"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.91784215,
        "lng": -56.9848325455339
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -57.0099092,
        5.8825109,
        -56.9571683,
        5.952959
      ]
    },
    {
      "id": 8281,
      "name": "Pajacuaran",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.118341,
        "lng": -102.5659129
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.6059129,
        20.078341,
        -102.5259129,
        20.158341
      ]
    },
    {
      "id": 8282,
      "name": "Kibuye",
      "names": {
        "zh": "基布耶"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -2.0603776,
        "lng": 29.348604
      },
      "country": "Rwanda",
      "importance": 1,
      "bbox": [
        29.308604,
        -2.1003776,
        29.388604,
        -2.0203776
      ]
    },
    {
      "id": 8283,
      "name": "Tacotalpa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5952197,
        "lng": -92.8253388
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.8653388,
        17.5552197,
        -92.7853388,
        17.6352197
      ]
    },
    {
      "id": 8285,
      "name": "Bocas del Toro",
      "names": {
        "ja": "ボカスデルトロ"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.3402964,
        "lng": -82.2418169
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -82.3321652,
        9.3296223,
        -82.2271673,
        9.4410833
      ]
    },
    {
      "id": 8291,
      "name": "Cuatro Cienegas de Carranza",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.986653,
        "lng": -102.065312
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8294,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.16029045,
        "lng": -90.74447495056829
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.3284181,
        17.8124246,
        -90.2355445,
        18.5076902
      ]
    },
    {
      "id": 8295,
      "name": "Nova Gorica",
      "names": {
        "ja": "ノヴァゴリカ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9580549,
        "lng": 13.6474706
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.6085938,
        45.8338724,
        13.8683609,
        46.1080607
      ]
    },
    {
      "id": 8297,
      "name": "Zejtun",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.857471,
        "lng": 14.532266
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.515515,
        35.844993,
        14.552034,
        35.869446
      ]
    },
    {
      "id": 8304,
      "name": "Steinkjer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 64.0151848,
        "lng": 11.4956649
      },
      "country": "Norway",
      "importance": 1,
      "bbox": [
        11.3356649,
        63.8551848,
        11.6556649,
        64.1751848
      ]
    },
    {
      "id": 8305,
      "name": "Tlahualilo de Zaragoza",
      "names": {
        "it": "Tlahualilo de Saragoza",
        "id": "Tidaualilo de zaragoza"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.1116297,
        "lng": -103.442506
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.482506,
        26.0716297,
        -103.402506,
        26.1516297
      ]
    },
    {
      "id": 8309,
      "name": "Psychiko",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0130854,
        "lng": 23.772085
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.752085,
        37.9930854,
        23.792085,
        38.0330854
      ]
    },
    {
      "id": 8312,
      "name": "Valga",
      "names": {
        "zh": "巴尔加"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 57.777034,
        "lng": 26.0315628
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        26.0204236,
        57.7559165,
        26.123527,
        57.8039174
      ]
    },
    {
      "id": 8313,
      "name": "Ialoveni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.948146449999996,
        "lng": 28.77996513789418
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.7462445,
        46.9225516,
        28.7983554,
        46.973796
      ]
    },
    {
      "id": 8315,
      "name": "Obo",
      "names": {
        "zh": "奥博"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 5.3940869,
        "lng": 26.4927242
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        26.3327242,
        5.2340869,
        26.6527242,
        5.5540869
      ]
    },
    {
      "id": 8316,
      "name": "Daru",
      "names": {
        "zh": "达鲁"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -9.069901,
        "lng": 143.210029
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        143.050029,
        -9.229901,
        143.370029,
        -8.909901
      ]
    },
    {
      "id": 8317,
      "name": "Lamphun",
      "names": {
        "zh": "南奔",
        "th": "ลำพูน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.5776383,
        "lng": 99.0069034
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        98.9669034,
        18.5376383,
        99.0469034,
        18.6176383
      ]
    },
    {
      "id": 8318,
      "name": "Ziniare",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.58186,
        "lng": -1.2971
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -1.3171,
        12.56186,
        -1.2771,
        12.60186
      ]
    },
    {
      "id": 8323,
      "name": "Santa Venera",
      "names": {
        "zh": "圣景观",
        "ja": "サンタヴェネラ",
        "th": "ซานตาเวเนรา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8897878,
        "lng": 14.4802386
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4703366,
        35.8839945,
        14.48483,
        35.8951206
      ]
    },
    {
      "id": 8330,
      "name": "Cuapiaxtla de Madero",
      "names": {
        "es": "Cuapiasxta de Madero",
        "fr": "Cuapiastla de Madero"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9175,
        "lng": -97.8225
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.8625,
        18.8775,
        -97.7825,
        18.9575
      ]
    },
    {
      "id": 8333,
      "name": "Rokiskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.96371215,
        "lng": 25.5869162839341
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        25.5866537,
        55.9635723,
        25.5871009,
        55.9640173
      ]
    },
    {
      "id": 8336,
      "name": "At Tur",
      "names": {
        "es": "En tur",
        "fr": "À Tur",
        "zh": "在tur",
        "de": "In Tur",
        "it": "A Tur",
        "id": "Di tur",
        "ja": "TURで",
        "th": "ตึง",
        "pt": "Na tur"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.232733,
        "lng": 33.608019
      },
      "country": "Egypt",
      "importance": 1,
      "bbox": [
        33.588019,
        28.212733,
        33.628019,
        28.252733
      ]
    },
    {
      "id": 8337,
      "name": "Chai Nat",
      "names": {
        "zh": "柴NAT",
        "th": "ชัยนาท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.186901,
        "lng": 100.131137
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        100.023952,
        15.10021,
        100.210113,
        15.277327
      ]
    },
    {
      "id": 8344,
      "name": "Tartar",
      "names": {
        "es": "El sarro",
        "fr": "Tartre",
        "zh": "鞑靼人",
        "de": "Zahnstein",
        "it": "Tartaro",
        "ja": "タルタル",
        "th": "การเคลือบฟัน",
        "pt": "Tártaro"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3429739,
        "lng": 46.9321023
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.9076226,
        40.326281,
        46.9606392,
        40.3604834
      ]
    },
    {
      "id": 8345,
      "name": "Ciudad Tula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.000391,
        "lng": -99.719996
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.7399961769,
        22.9803906443,
        -99.6999961769,
        23.0203906443
      ]
    },
    {
      "id": 8349,
      "name": "San Giljan",
      "names": {
        "es": "San Gilján",
        "zh": "圣吉朗",
        "ja": "サンギルジャン",
        "th": "ซานกิลจัน"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9187397,
        "lng": 14.4901931
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4901431,
        35.9186897,
        14.4902431,
        35.9187897
      ]
    },
    {
      "id": 8350,
      "name": "Hincesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.829311,
        "lng": 28.587829
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.567829,
        46.809311,
        28.607829,
        46.849311
      ]
    },
    {
      "id": 8351,
      "name": "Attard",
      "names": {
        "zh": "阿塔尔德"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8909172,
        "lng": 14.4430093
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4088527,
        35.8810509,
        14.4581924,
        35.9019839
      ]
    },
    {
      "id": 8356,
      "name": "Singerei",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.63686835,
        "lng": 28.137945866130295
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.103733,
        47.6243752,
        28.1771874,
        47.6486593
      ]
    },
    {
      "id": 8357,
      "name": "Culfa",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.9558,
        "lng": 45.6308
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.6108,
        38.9358,
        45.6508,
        38.9758
      ]
    },
    {
      "id": 8359,
      "name": "San Ignacio Rio Muerto",
      "names": {
        "th": "ซานอิกนาซิโอริโอมิเรโต้"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.415409,
        "lng": -110.2448016
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.2848016,
        27.375409,
        -110.2048016,
        27.455409
      ]
    },
    {
      "id": 8361,
      "name": "Tlalixtac de Cabrera",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0709537,
        "lng": -96.6477591
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.6877591,
        17.0309537,
        -96.6077591,
        17.1109537
      ]
    },
    {
      "id": 8362,
      "name": "Avranches",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6849546,
        "lng": -1.3587506
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.3588006,
        48.6849046,
        -1.3587006,
        48.6850046
      ]
    },
    {
      "id": 8366,
      "name": "Encamp",
      "names": {
        "zh": "恩坎普",
        "ja": "野営",
        "th": "ตั้งค่าย"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5360425,
        "lng": 1.5836096
      },
      "country": "Andorra",
      "importance": 1,
      "bbox": [
        1.5460477,
        42.4895387,
        1.7390717,
        42.5608038
      ]
    },
    {
      "id": 8370,
      "name": "Bulgan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.85636005,
        "lng": 103.47449744974568
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        103.4608219,
        48.8473601,
        103.4899931,
        48.8633103
      ]
    },
    {
      "id": 8373,
      "name": "San Gregorio Atzompa",
      "names": {
        "zh": "圣格雷戈里奥atzompa",
        "ja": "サングレゴリオアトツマ",
        "th": "ซานเกรโกริโอ Atzompa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.022754,
        "lng": -98.345776
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.35628,
        19.014793,
        -98.337822,
        19.033127
      ]
    },
    {
      "id": 8374,
      "name": "Taraclia",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 45.8957779,
        "lng": 28.667035
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.6303868,
        45.8712085,
        28.6968032,
        45.9323909
      ]
    },
    {
      "id": 8375,
      "name": "Zamora",
      "names": {
        "zh": "萨莫拉",
        "ja": "サモラ",
        "th": "ซาโมร่า"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -4.0669323,
        "lng": -78.9565614
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -79.1390879,
        -4.3051135,
        -78.8505109,
        -3.9869593
      ]
    },
    {
      "id": 8380,
      "name": "Voru",
      "names": {
        "de": "Võru"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 57.8385759,
        "lng": 27.0086505
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        26.9620289,
        57.8066057,
        27.0522288,
        57.8645588
      ]
    },
    {
      "id": 8381,
      "name": "Beylaqan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.7448389,
        "lng": 47.9999191
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.9917292,
        39.7403449,
        48.0061662,
        39.7507009
      ]
    },
    {
      "id": 8386,
      "name": "Celestun",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8608268,
        "lng": -90.397698
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.4054389,
        20.8468008,
        -90.3821437,
        20.873235
      ]
    },
    {
      "id": 8387,
      "name": "Las Tablas",
      "names": {
        "fr": "Tablas Las",
        "de": "Las-Tablas",
        "ja": "ラスタブラス"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 7.7677917,
        "lng": -80.2764151
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -80.4364151,
        7.6077917,
        -80.1164151,
        7.9277917
      ]
    },
    {
      "id": 8391,
      "name": "Ermoupoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.450413,
        "lng": 24.9333
      },
      "country": "Greece",
      "importance": 1,
      "bbox": [
        24.9132995185,
        37.4304130239,
        24.9532995185,
        37.4704130239
      ]
    },
    {
      "id": 8395,
      "name": "Amatlan de los Reyes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8449985,
        "lng": -96.915405
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.955405,
        18.8049985,
        -96.875405,
        18.8849985
      ]
    },
    {
      "id": 8396,
      "name": "Xicotencatl",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.996156,
        "lng": -98.944689
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.9583470479,
        22.9804656827,
        -98.9260801177,
        23.0066943486
      ]
    },
    {
      "id": 8397,
      "name": "Rafael Lucio",
      "names": {
        "zh": "拉斐尔卢西奥",
        "th": "ราฟาเอลลูซิโอ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.593835,
        "lng": -96.988246
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.994767,
        19.586326,
        -96.98382,
        19.601321
      ]
    },
    {
      "id": 8398,
      "name": "Qalat",
      "names": {
        "zh": "卡拉特"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 32.1033645,
        "lng": 66.9065768
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        66.7465768,
        31.9433645,
        67.0665768,
        32.2633645
      ]
    },
    {
      "id": 8400,
      "name": "Batken",
      "names": {
        "zh": "巴特肯"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.0616615,
        "lng": 70.8215877
      },
      "country": "Kyrgyzstan",
      "importance": 1,
      "bbox": [
        70.7836766,
        40.0265853,
        70.8731155,
        40.1065271
      ]
    },
    {
      "id": 8401,
      "name": "Assomada",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 15.097635,
        "lng": -23.6668745
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.7068745,
        15.057635,
        -23.6268745,
        15.137635
      ]
    },
    {
      "id": 8402,
      "name": "Choix",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.7089186,
        "lng": -108.325282
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.6636405,
        26.2838187,
        -107.9557478,
        27.0422491
      ]
    },
    {
      "id": 8408,
      "name": "Falesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.5735141,
        "lng": 27.7068016
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.6736466,
        47.5381641,
        27.7680935,
        47.6255847
      ]
    },
    {
      "id": 8412,
      "name": "Ntcheu",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -14.8128051,
        "lng": 34.6315702
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.6315202,
        -14.8128551,
        34.6316202,
        -14.8127551
      ]
    },
    {
      "id": 8419,
      "name": "Druskininkai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.01822165,
        "lng": 23.976190846865634
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.9759959,
        54.0179404,
        23.9765474,
        54.0183627
      ]
    },
    {
      "id": 8420,
      "name": "Floresti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.8938318,
        "lng": 28.2996474
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.2635492,
        47.8780836,
        28.3315697,
        47.912485
      ]
    },
    {
      "id": 8421,
      "name": "Izola",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5398936,
        "lng": 13.65939
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.5388958,
        45.4727147,
        13.6940798,
        45.6220073
      ]
    },
    {
      "id": 8423,
      "name": "Cimislia",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.52114,
        "lng": 28.783159
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.763159,
        46.50114,
        28.803159,
        46.54114
      ]
    },
    {
      "id": 8427,
      "name": "Robertsport",
      "names": {
        "zh": "罗伯茨"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.75333,
        "lng": -11.36861
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -11.38861,
        6.73333,
        -11.34861,
        6.77333
      ]
    },
    {
      "id": 8428,
      "name": "Dundo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -7.381086,
        "lng": 20.833799
      },
      "country": "Angola",
      "importance": 1,
      "bbox": [
        20.793799,
        -7.421086,
        20.873799,
        -7.341086
      ]
    },
    {
      "id": 8438,
      "name": "Rabat",
      "names": {
        "es": "De Rabat",
        "zh": "拉巴特",
        "ja": "ラバト",
        "th": "ราบัต"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8818906,
        "lng": 14.3988213
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.328144,
        35.8614815,
        14.4169898,
        35.9076687
      ]
    },
    {
      "id": 8440,
      "name": "Murska Sobota",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6577266,
        "lng": 16.1591935
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.1591435,
        46.6576766,
        16.1592435,
        46.6577766
      ]
    },
    {
      "id": 8444,
      "name": "Zurrieq",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8294676,
        "lng": 14.4745172
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4064182,
        35.7863396,
        14.516703,
        35.8410846
      ]
    },
    {
      "id": 8447,
      "name": "Tlacotalpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6121189,
        "lng": -95.6611673
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.6697406,
        18.6104632,
        -95.6455643,
        18.6202245
      ]
    },
    {
      "id": 8449,
      "name": "Bingol",
      "names": {
        "zh": "宾格尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8847,
        "lng": 40.5007
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        40.50065,
        38.88465,
        40.50075,
        38.88475
      ]
    },
    {
      "id": 8452,
      "name": "Berane",
      "names": {
        "ja": "ベラネ"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.846826,
        "lng": 19.8740451
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.8535381,
        42.8184475,
        19.8858118,
        42.8690448
      ]
    },
    {
      "id": 8453,
      "name": "Fort Liberte",
      "names": {
        "es": "Fuerte liberte",
        "zh": "堡垒自由",
        "it": "Forte liberte",
        "id": "Kaum benteng",
        "ja": "リバルト砦"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.66778,
        "lng": -71.83972
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -71.85972,
        19.64778,
        -71.81972,
        19.68778
      ]
    },
    {
      "id": 8458,
      "name": "Ndele",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 8.4111477,
        "lng": 20.648473
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        20.488473,
        8.2511477,
        20.808473,
        8.5711477
      ]
    },
    {
      "id": 8460,
      "name": "Skofja Loka",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.16551,
        "lng": 14.30626
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.28626,
        46.14551,
        14.32626,
        46.18551
      ]
    },
    {
      "id": 8461,
      "name": "Cuauhtemoc",
      "names": {
        "fr": "Cuauhtémoc",
        "zh": "夸特莫克"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4256821,
        "lng": -99.1546107
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1596107,
        19.4206821,
        -99.1496107,
        19.4306821
      ]
    },
    {
      "id": 8462,
      "name": "Bol",
      "names": {
        "zh": "平原",
        "ja": "ボル"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 13.4676491,
        "lng": 14.714412
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        14.554412,
        13.3076491,
        14.874412,
        13.6276491
      ]
    },
    {
      "id": 8469,
      "name": "Elektrenai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.7885377,
        "lng": 24.6588975
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.6588475,
        54.7884877,
        24.6589475,
        54.7885877
      ]
    },
    {
      "id": 8472,
      "name": "Alotau",
      "names": {
        "zh": "阿洛陶"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -10.3060931,
        "lng": 150.4464005
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        150.2864005,
        -10.4660931,
        150.6064005,
        -10.1460931
      ]
    },
    {
      "id": 8476,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3941887,
        "lng": -91.6714379
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.867607,
        30.9969969,
        -91.3785942,
        31.762226
      ]
    },
    {
      "id": 8482,
      "name": "Halacho",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4781478,
        "lng": -90.0812243
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.0984363,
        20.45969,
        -90.0629088,
        20.5039249
      ]
    },
    {
      "id": 8488,
      "name": "Sanniquellie",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 7.36222,
        "lng": -8.70611
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -8.72611,
        7.34222,
        -8.68611,
        7.38222
      ]
    },
    {
      "id": 8492,
      "name": "Mariehamn",
      "names": {
        "zh": "玛丽港"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.102423,
        "lng": 19.94126
      },
      "country": "Finland",
      "importance": 1,
      "bbox": [
        19.9131089,
        60.046672,
        19.9732835,
        60.1289862
      ]
    },
    {
      "id": 8494,
      "name": "Qax",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.4185515,
        "lng": 46.9192097
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.892074,
        41.4089551,
        46.9397133,
        41.4358657
      ]
    },
    {
      "id": 8500,
      "name": "Yuscaran",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 13.9413071,
        "lng": -86.8522133
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -86.9397893,
        13.8642758,
        -86.7086515,
        14.0731069
      ]
    },
    {
      "id": 8502,
      "name": "Mpigi",
      "names": {
        "zh": "姆皮吉"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.2256619,
        "lng": 32.3219924
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.3219424,
        0.2256119,
        32.3220424,
        0.2257119
      ]
    },
    {
      "id": 8503,
      "name": "Hadibu",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 12.651236,
        "lng": 54.0236598
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        53.9836598,
        12.611236,
        54.0636598,
        12.691236
      ]
    },
    {
      "id": 8504,
      "name": "Acacoyagua",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.3411691,
        "lng": -92.6752401
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.7152401,
        15.3011691,
        -92.6352401,
        15.3811691
      ]
    },
    {
      "id": 8509,
      "name": "San Diego de la Union",
      "names": {
        "es": "San Diego de la Unión",
        "zh": "圣地亚哥德拉联盟",
        "ja": "サンディエゴデラユニオン",
        "th": "ซานดิเอโกเดอลายูเนี่ยน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.4674705,
        "lng": -100.872382
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.912382,
        21.4274705,
        -100.832382,
        21.5074705
      ]
    },
    {
      "id": 8519,
      "name": "Ciudad Ayala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7663043,
        "lng": -98.9853284
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.0253284,
        18.7263043,
        -98.9453284,
        18.8063043
      ]
    },
    {
      "id": 8520,
      "name": "Vanimo",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -2.6847646,
        "lng": 141.3009131
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        141.1409131,
        -2.8447646,
        141.4609131,
        -2.5247646
      ]
    },
    {
      "id": 8522,
      "name": "Tepeojuma",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.724722,
        "lng": -98.450556
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.490556,
        18.684722,
        -98.410556,
        18.764722
      ]
    },
    {
      "id": 8525,
      "name": "Magas",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1666497,
        "lng": 44.8048191
      },
      "country": "Russia",
      "importance": 1,
      "bbox": [
        44.7960212,
        43.1502138,
        44.8386212,
        43.194069
      ]
    },
    {
      "id": 8531,
      "name": "Tataltepec de Valdes",
      "names": {
        "es": "Tataltepec de valdees"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.304718,
        "lng": -97.544925
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.551238063,
        16.2984445086,
        -97.5404731551,
        16.3096314999
      ]
    },
    {
      "id": 8535,
      "name": "Rezina",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7478918,
        "lng": 28.9681221
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.9321575,
        47.7264362,
        28.979961,
        47.7613816
      ]
    },
    {
      "id": 8536,
      "name": "Loikaw",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 19.674167,
        "lng": 97.2094441
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        97.1160993,
        19.2399803,
        97.5290825,
        19.9966873
      ]
    },
    {
      "id": 8539,
      "name": "Kantunilkin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1016621,
        "lng": -87.4869165
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -87.4978061,
        21.0870632,
        -87.4767955,
        21.1143287
      ]
    },
    {
      "id": 8541,
      "name": "Biltine",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 14.525174,
        "lng": 20.928701
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        20.768701,
        14.365174,
        21.088701,
        14.685174
      ]
    },
    {
      "id": 8545,
      "name": "Paola",
      "names": {
        "zh": "保拉",
        "ja": "パオラ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8717994,
        "lng": 14.5073748
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4954883,
        35.861904,
        14.5156875,
        35.886521
      ]
    },
    {
      "id": 8548,
      "name": "Anenii Noi",
      "names": {
        "fr": "Anenii no"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.880818,
        "lng": 29.231743
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.211743,
        46.860818,
        29.251743,
        46.900818
      ]
    },
    {
      "id": 8551,
      "name": "Strumica",
      "names": {
        "zh": "斯特鲁米察"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4375,
        "lng": 22.64333
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.62333,
        41.4175,
        22.66333,
        41.4575
      ]
    },
    {
      "id": 8553,
      "name": "Karuzi",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.1011687,
        "lng": 30.1647524
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        30.0047524,
        -3.2611687,
        30.3247524,
        -2.9411687
      ]
    },
    {
      "id": 8558,
      "name": "Calarasi",
      "names": {
        "zh": "克勒拉希"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 47.254201,
        "lng": 28.313812
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.295366,
        47.239654,
        28.332313,
        47.268595
      ]
    },
    {
      "id": 8559,
      "name": "Vinica",
      "names": {
        "zh": "维尼察"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8847356,
        "lng": 22.5044468
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.4518061,
        41.868698,
        22.5672884,
        41.9042893
      ]
    },
    {
      "id": 8560,
      "name": "Beni Abbes",
      "names": {
        "it": "Abbes Beni"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 30.1317426,
        "lng": -2.169031
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -3.2286846,
        29.3524362,
        -1.3164267,
        30.8187184
      ]
    },
    {
      "id": 8563,
      "name": "Probistip",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9998386,
        "lng": 22.1784767
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.1435881,
        41.9759271,
        22.1939921,
        42.0208962
      ]
    },
    {
      "id": 8568,
      "name": "Johvi",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3579433,
        "lng": 27.4135654
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        27.3758502,
        59.3464394,
        27.4717903,
        59.3683889
      ]
    },
    {
      "id": 8570,
      "name": "Ahumada",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.6128489,
        "lng": -106.512403
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -106.552403,
        30.5728489,
        -106.472403,
        30.6528489
      ]
    },
    {
      "id": 8571,
      "name": "Birzebbuga",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8269478,
        "lng": 14.5274189
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5004929,
        35.8061415,
        14.5496936,
        35.8438468
      ]
    },
    {
      "id": 8572,
      "name": "El Higo",
      "names": {
        "ja": "エルヒゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.7673571,
        "lng": -98.4525793
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4925793,
        21.7273571,
        -98.4125793,
        21.8073571
      ]
    },
    {
      "id": 8575,
      "name": "Daskasan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.465357,
        "lng": 46.017121
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.831525,
        40.296672,
        46.258511,
        40.681797
      ]
    },
    {
      "id": 8577,
      "name": "Altar",
      "names": {
        "fr": "Autel",
        "zh": "祭坛",
        "it": "Altare",
        "id": "Mezbah",
        "ja": "祭壇",
        "th": "แท่นบูชา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.7170323,
        "lng": -111.8380038
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -111.8780038,
        30.6770323,
        -111.7980038,
        30.7570323
      ]
    },
    {
      "id": 8578,
      "name": "Bela Crkva",
      "names": {
        "zh": "贝拉克罗卡"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9001235,
        "lng": 21.4230044
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.3752173,
        44.8681332,
        21.4591339,
        44.9799568
      ]
    },
    {
      "id": 8580,
      "name": "Roatan",
      "names": {
        "es": "Roatán",
        "zh": "罗阿坦",
        "ja": "ロアタン島"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 16.330065,
        "lng": -86.523342
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -86.543342,
        16.310065,
        -86.503342,
        16.350065
      ]
    },
    {
      "id": 8582,
      "name": "Kavaratti",
      "names": {
        "zh": "卡瓦拉蒂"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 10.55905,
        "lng": 72.63656879807692
      },
      "country": "India",
      "importance": 1,
      "bbox": [
        72.2810322,
        10.037285,
        72.6497913,
        10.5774744
      ]
    },
    {
      "id": 8591,
      "name": "Ayotoxco de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.097403,
        "lng": -97.410262
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.4174570047,
        20.0925901527,
        -97.4030876136,
        20.1026212765
      ]
    },
    {
      "id": 8596,
      "name": "Kirundo",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -2.5853872,
        "lng": 30.0933449
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        30.0284864,
        -2.6670688,
        30.1793296,
        -2.4248823
      ]
    },
    {
      "id": 8597,
      "name": "Greenville",
      "names": {
        "zh": "格林维尔",
        "ja": "グリーンヴィル",
        "th": "กรีนวิลล์"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 35.613224,
        "lng": -77.3724593
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -77.475265,
        35.529776,
        -77.279305,
        35.676051
      ]
    },
    {
      "id": 8598,
      "name": "Al Mahwit",
      "names": {
        "th": "อัลมาห์วิทย์"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.470072,
        "lng": 43.544812
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        43.504812,
        15.430072,
        43.584812,
        15.510072
      ]
    },
    {
      "id": 8600,
      "name": "Ada",
      "names": {
        "zh": "阿达",
        "ja": "ADAの"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.7969129,
        "lng": 20.133407
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0737309,
        45.7771413,
        20.1709884,
        45.8535916
      ]
    },
    {
      "id": 8604,
      "name": "Haapsalu",
      "names": {
        "zh": "哈普萨卢"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.9399019,
        "lng": 23.5414798
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        23.4857897,
        58.9045263,
        23.5743169,
        58.9670643
      ]
    },
    {
      "id": 8605,
      "name": "Trat",
      "names": {
        "th": "ตราด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.2440564,
        "lng": 102.5122822
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        102.4722822,
        12.2040564,
        102.5522822,
        12.2840564
      ]
    },
    {
      "id": 8606,
      "name": "Mellieha",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9599618,
        "lng": 14.368367
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3189084,
        35.9306415,
        14.4077389,
        35.9982964
      ]
    },
    {
      "id": 8610,
      "name": "Sarnen",
      "names": {
        "zh": "萨尔嫩"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8956729,
        "lng": 8.2461492
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.0669969,
        46.8542203,
        8.2776631,
        46.9334629
      ]
    },
    {
      "id": 8611,
      "name": "Phangnga",
      "names": {
        "zh": "攀牙",
        "th": "พังงา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.4612037,
        "lng": 98.5313023
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        98.4913023,
        8.4212037,
        98.5713023,
        8.5012037
      ]
    },
    {
      "id": 8617,
      "name": "Siparia",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.13333,
        "lng": -61.5
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.52,
        10.11333,
        -61.48,
        10.15333
      ]
    },
    {
      "id": 8618,
      "name": "Ordubad",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.90595,
        "lng": 46.02341
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.00341,
        38.88595,
        46.04341,
        38.92595
      ]
    },
    {
      "id": 8620,
      "name": "Olaine",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.7861403,
        "lng": 23.946393021209254
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        23.946188,
        56.78603,
        23.9465981,
        56.7862507
      ]
    },
    {
      "id": 8625,
      "name": "Butha-Buthe",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -28.76659,
        "lng": 28.24936
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        28.22936,
        -28.78659,
        28.26936,
        -28.74659
      ]
    },
    {
      "id": 8626,
      "name": "Naftalan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.50769885,
        "lng": 46.81954107112918
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.8099457,
        40.4970081,
        46.8435265,
        40.519694
      ]
    },
    {
      "id": 8629,
      "name": "Altdorf",
      "names": {
        "zh": "阿尔特多夫"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8820732,
        "lng": 8.6413922
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.6155136,
        46.8636387,
        8.6673169,
        46.9097592
      ]
    },
    {
      "id": 8632,
      "name": "Samux",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.76485,
        "lng": 46.40868
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.38868,
        40.74485,
        46.42868,
        40.78485
      ]
    },
    {
      "id": 8638,
      "name": "La Massana",
      "names": {
        "ja": "ラマサナ"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5442014,
        "lng": 1.5163754
      },
      "country": "Andorra",
      "importance": 1,
      "bbox": [
        1.4135781,
        42.509996,
        1.562015,
        42.6069182
      ]
    },
    {
      "id": 8647,
      "name": "Birao",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 10.2961965,
        "lng": 22.7809907
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        22.6209907,
        10.1361965,
        22.9409907,
        10.4561965
      ]
    },
    {
      "id": 8649,
      "name": "Birzai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.1999121,
        "lng": 24.7571107
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.7256135,
        56.1701302,
        24.8027853,
        56.2261094
      ]
    },
    {
      "id": 8650,
      "name": "Muyinga",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -2.8453256,
        "lng": 30.3393883
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        30.1793883,
        -3.0053256,
        30.4993883,
        -2.6853256
      ]
    },
    {
      "id": 8655,
      "name": "Nisporeni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.07758,
        "lng": 28.177842
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.137842,
        47.03758,
        28.217842,
        47.11758
      ]
    },
    {
      "id": 8656,
      "name": "Kuldiga",
      "names": {
        "zh": "库尔迪加"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9685757,
        "lng": 21.960778
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        21.9185837,
        56.9453373,
        22.0066472,
        56.9960577
      ]
    },
    {
      "id": 8659,
      "name": "Vrnjacka Banja",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6180907,
        "lng": 20.8930413
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.8929913,
        43.6180407,
        20.8930913,
        43.6181407
      ]
    },
    {
      "id": 8661,
      "name": "Mojkovac",
      "names": {
        "zh": "莫伊科瓦茨"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.96044,
        "lng": 19.5833
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.5633,
        42.94044,
        19.6033,
        42.98044
      ]
    },
    {
      "id": 8662,
      "name": "Huiloapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8166304,
        "lng": -97.1522807
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1922807,
        18.7766304,
        -97.1122807,
        18.8566304
      ]
    },
    {
      "id": 8664,
      "name": "Hamrun",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.887362,
        "lng": 14.487117
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.479766,
        35.881418,
        14.498943,
        35.892439
      ]
    },
    {
      "id": 8665,
      "name": "Hopelchen",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7463074,
        "lng": -89.8442155
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.8842155,
        19.7063074,
        -89.8042155,
        19.7863074
      ]
    },
    {
      "id": 8667,
      "name": "Tarin Kot",
      "names": {
        "zh": "塔林kot",
        "ja": "タルリン箱"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 32.62998,
        "lng": 65.87806
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        65.85806,
        32.60998,
        65.89806,
        32.64998
      ]
    },
    {
      "id": 8671,
      "name": "Kanjiza",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 46.0644447,
        "lng": 20.0516482
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.9873368,
        46.0156761,
        20.0894801,
        46.0816605
      ]
    },
    {
      "id": 8676,
      "name": "Nueva Ocotepeque",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 14.4355133,
        "lng": -89.1844797
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -89.3444797,
        14.2755133,
        -89.0244797,
        14.5955133
      ]
    },
    {
      "id": 8685,
      "name": "Bauska",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.4082863,
        "lng": 24.1876674
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.1568568,
        56.3904968,
        24.2442462,
        56.4241513
      ]
    },
    {
      "id": 8690,
      "name": "Bilecik",
      "names": {
        "zh": "比莱吉克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1413208,
        "lng": 29.9773162
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        29.9772662,
        40.1412708,
        29.9773662,
        40.1413708
      ]
    },
    {
      "id": 8706,
      "name": "Jurbarkas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.0782585,
        "lng": 22.7761346
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.7760846,
        55.0782085,
        22.7761846,
        55.0783085
      ]
    },
    {
      "id": 8709,
      "name": "Santa Eulalia",
      "names": {
        "zh": "圣雅利亚",
        "ja": "サンタエゥラリア",
        "th": "ซานตาเอโลเลีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 9.7819377,
        "lng": -73.3813503
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -73.3913503,
        9.7719377,
        -73.3713503,
        9.7919377
      ]
    },
    {
      "id": 8710,
      "name": "Makamba",
      "names": {
        "zh": "马坎巴"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -4.1347916,
        "lng": 29.8046322
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.6446322,
        -4.2947916,
        29.9646322,
        -3.9747916
      ]
    },
    {
      "id": 8711,
      "name": "Basco",
      "names": {
        "zh": "巴斯科"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 20.4490442,
        "lng": 121.9683455
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.8164077,
        20.353472,
        122.1802437,
        20.6155602
      ]
    },
    {
      "id": 8714,
      "name": "Saldus",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6659281,
        "lng": 22.4936222
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        22.4596491,
        56.6456901,
        22.5250571,
        56.685716
      ]
    },
    {
      "id": 8715,
      "name": "Tshabong",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -26.03403,
        "lng": 22.40164
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 8718,
      "name": "Djambala",
      "names": {
        "zh": "兼巴拉"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -2.54472,
        "lng": 14.75333
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        14.73333,
        -2.56472,
        14.77333,
        -2.52472
      ]
    },
    {
      "id": 8721,
      "name": "Postojna",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7752847,
        "lng": 14.2139937
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.04237,
        45.7014423,
        14.3260129,
        45.879101
      ]
    },
    {
      "id": 8724,
      "name": "Kundiawa",
      "names": {
        "zh": "孔迪亚瓦"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.01667,
        "lng": 144.96667
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        144.94667,
        -6.03667,
        144.98667,
        -5.99667
      ]
    },
    {
      "id": 8726,
      "name": "Pedra Badejo",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 15.1371918,
        "lng": -23.531565
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.571565,
        15.0971918,
        -23.491565,
        15.1771918
      ]
    },
    {
      "id": 8727,
      "name": "Pieta",
      "names": {
        "fr": "Pietà",
        "zh": "圣母怜子图",
        "ja": "ビエタ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.892353,
        "lng": 14.4934881
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4899338,
        35.8885413,
        14.4985153,
        35.8965616
      ]
    },
    {
      "id": 8730,
      "name": "Tenabo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0406607,
        "lng": -90.2254956
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.2654956,
        20.0006607,
        -90.1854956,
        20.0806607
      ]
    },
    {
      "id": 8731,
      "name": "Hani i Elezit",
      "names": {
        "fr": "Hani je elezit",
        "zh": "哈尼我elezit",
        "pt": "Hani eu elezit"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.1497,
        "lng": 21.296525
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.2962398,
        42.1495139,
        21.2967476,
        42.1499484
      ]
    },
    {
      "id": 8738,
      "name": "Vilkaviskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.650735,
        "lng": 23.036371
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.997456,
        54.632501,
        23.06285,
        54.692415
      ]
    },
    {
      "id": 8741,
      "name": "Rozaje",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.8416663,
        "lng": 20.1659665
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        20.1430451,
        42.8228249,
        20.1859253,
        42.8555727
      ]
    },
    {
      "id": 8745,
      "name": "Kolonia",
      "names": {},
      "countryCode": "FM",
      "latLng": {
        "lat": 6.9643412,
        "lng": 158.2091324
      },
      "country": "Federated States of Micronesia",
      "importance": 1,
      "bbox": [
        158.1998698,
        6.9539934,
        158.2168178,
        6.972203
      ]
    },
    {
      "id": 8746,
      "name": "Vrhnika",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.962437,
        "lng": 14.293903
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.275176,
        45.949965,
        14.315642,
        45.985512
      ]
    },
    {
      "id": 8748,
      "name": "Gizo",
      "names": {
        "zh": "吉佐"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -8.1054656,
        "lng": 156.8413618
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        156.8013618,
        -8.1454656,
        156.8813618,
        -8.0654656
      ]
    },
    {
      "id": 8749,
      "name": "Riscani",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.958924,
        "lng": 27.553167
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.535894,
        47.939714,
        27.572833,
        47.975261
      ]
    },
    {
      "id": 8750,
      "name": "Sant Julia de Loria",
      "names": {
        "ja": "サンジュリアデロリア"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.4668541,
        "lng": 1.4923277
      },
      "country": "Andorra",
      "importance": 1,
      "bbox": [
        1.4523277,
        42.4268541,
        1.5323277,
        42.5068541
      ]
    },
    {
      "id": 8753,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 51.495112649999996,
        "lng": -0.1441233992952701
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        -0.1454728,
        51.4941333,
        -0.1428439,
        51.4959641
      ]
    },
    {
      "id": 8757,
      "name": "Kasane",
      "names": {
        "zh": "卡萨内"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -17.799239,
        "lng": 25.156546
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        25.116546,
        -17.839239,
        25.196546,
        -17.759239
      ]
    },
    {
      "id": 8761,
      "name": "Zabalj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3728199,
        "lng": 20.0637917
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0123516,
        45.3325396,
        20.2460609,
        45.4381895
      ]
    },
    {
      "id": 8765,
      "name": "Evinayong",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.4388356,
        "lng": 10.5516564
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        10.0429201,
        1.1571321,
        10.7477188,
        1.7530758
      ]
    },
    {
      "id": 8766,
      "name": "Senglea",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.887928,
        "lng": 14.516732
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.511516,
        35.881507,
        14.520645,
        35.892887
      ]
    },
    {
      "id": 8768,
      "name": "Grosuplje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.956295,
        "lng": 14.658186
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.642976,
        45.947483,
        14.666954,
        45.9672
      ]
    },
    {
      "id": 8769,
      "name": "Stans",
      "names": {
        "zh": "施坦斯",
        "ja": "シュタンス",
        "th": "ภูเขา"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.9584591,
        "lng": 8.366564
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        8.361564,
        46.9534591,
        8.371564,
        46.9634591
      ]
    },
    {
      "id": 8770,
      "name": "Cankiri",
      "names": {
        "zh": "坎开莱"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.59995,
        "lng": 33.6153
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        33.5953,
        40.57995,
        33.6353,
        40.61995
      ]
    },
    {
      "id": 8771,
      "name": "Dangriga",
      "names": {},
      "countryCode": "BZ",
      "latLng": {
        "lat": 16.9666599,
        "lng": -88.2247368
      },
      "country": "Belize",
      "importance": 1,
      "bbox": [
        -88.2572528,
        16.9327992,
        -88.2183279,
        16.9841131
      ]
    },
    {
      "id": 8773,
      "name": "Thyolo",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -16.0668788,
        "lng": 35.1485842
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.1085842,
        -16.1068788,
        35.1885842,
        -16.0268788
      ]
    },
    {
      "id": 8774,
      "name": "Katakwi",
      "names": {
        "zh": "卡塔奎"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.891111,
        "lng": 33.966111
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.9461111,
        1.8711111,
        33.9861111,
        1.9111111
      ]
    },
    {
      "id": 8778,
      "name": "Guingamp",
      "names": {
        "zh": "甘冈"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5618483,
        "lng": -3.1502007
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -3.1714381,
        48.5483356,
        -3.1350073,
        48.5730688
      ]
    },
    {
      "id": 8781,
      "name": "Ewo",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": -0.8725,
        "lng": 14.82056
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        14.80056,
        -0.8925,
        14.84056,
        -0.8525
      ]
    },
    {
      "id": 8783,
      "name": "Manatuto",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.51139,
        "lng": 126.01306
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.99306,
        -8.53139,
        126.03306,
        -8.49139
      ]
    },
    {
      "id": 8784,
      "name": "Valle de Guadalupe",
      "names": {
        "ja": "ヴァッレデグアダルーペ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0113273,
        "lng": -102.6147189
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.6547189,
        20.9713273,
        -102.5747189,
        21.0513273
      ]
    },
    {
      "id": 8785,
      "name": "Odzaci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.5058662,
        "lng": 19.2597368
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.2070987,
        45.4673092,
        19.3534441,
        45.5382354
      ]
    },
    {
      "id": 8794,
      "name": "San Antonino Castillo Velasco",
      "names": {
        "ja": "サンアントニーノカスティーヨヴェラスコ",
        "th": "ซานอันโตนิโน Castillo Velasco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8029431,
        "lng": -96.6836227
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.7236227,
        16.7629431,
        -96.6436227,
        16.8429431
      ]
    },
    {
      "id": 8799,
      "name": "Sremski Karlovci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2030463,
        "lng": 19.9338336
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.8907992,
        45.1456497,
        20.0190922,
        45.2263608
      ]
    },
    {
      "id": 8802,
      "name": "Gleno",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.721148,
        "lng": 125.4358593
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.2758593,
        -8.881148,
        125.5958593,
        -8.561148
      ]
    },
    {
      "id": 8805,
      "name": "Talsi",
      "names": {
        "zh": "塔尔西"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.2451926,
        "lng": 22.5903003
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        22.5537504,
        57.2279235,
        22.621799,
        57.2648714
      ]
    },
    {
      "id": 8806,
      "name": "Mokhotlong",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -29.2883324,
        "lng": 29.0643807
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        29.0643307,
        -29.2883824,
        29.0644307,
        -29.2882824
      ]
    },
    {
      "id": 8807,
      "name": "Corozal",
      "names": {
        "zh": "科罗萨尔"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 18.394332,
        "lng": -88.3886854
      },
      "country": "Belize",
      "importance": 1,
      "bbox": [
        -88.4252381,
        18.3705634,
        -88.3626053,
        18.4103959
      ]
    },
    {
      "id": 8811,
      "name": "Siggiewi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8539354,
        "lng": 14.438063
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3895613,
        35.8260166,
        14.4724751,
        35.8685553
      ]
    },
    {
      "id": 8813,
      "name": "Joniskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.2412052,
        "lng": 23.6151344
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.5800804,
        56.214847,
        23.647132,
        56.2539825
      ]
    },
    {
      "id": 8816,
      "name": "Dobele",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6257485,
        "lng": 23.277275
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        23.2536552,
        56.6007687,
        23.3121823,
        56.6452888
      ]
    },
    {
      "id": 8817,
      "name": "Glodeni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7744001,
        "lng": 27.50441
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.4725743,
        47.7246643,
        27.5594285,
        47.8200397
      ]
    },
    {
      "id": 8818,
      "name": "Artvin",
      "names": {
        "zh": "阿尔特温",
        "th": "อาร์ตวิน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1830811,
        "lng": 41.8287448
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        41.5150624,
        40.9290102,
        42.1795948,
        41.3617705
      ]
    },
    {
      "id": 8819,
      "name": "Gadabay",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.56556,
        "lng": 45.81611
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.79611,
        40.54556,
        45.83611,
        40.58556
      ]
    },
    {
      "id": 8821,
      "name": "Kocevje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.644317,
        "lng": 14.858098
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.849758,
        45.629192,
        14.881333,
        45.659735
      ]
    },
    {
      "id": 8822,
      "name": "Aleg",
      "names": {
        "zh": "阿莱格"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 17.0527177,
        "lng": -13.9152949
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -13.9232404,
        17.0411235,
        -13.8970919,
        17.0629592
      ]
    },
    {
      "id": 8825,
      "name": "Paide",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.8869266,
        "lng": 25.5699121
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        25.5176502,
        58.8637072,
        25.602537,
        58.9063416
      ]
    },
    {
      "id": 8838,
      "name": "Basarabeasca",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.3336041,
        "lng": 28.9615656
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.9471519,
        46.3157916,
        28.997975,
        46.3513819
      ]
    },
    {
      "id": 8839,
      "name": "Zardab",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.21833,
        "lng": 47.70833
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.68833,
        40.19833,
        47.72833,
        40.23833
      ]
    },
    {
      "id": 8841,
      "name": "Anyksciai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.5325841,
        "lng": 25.1085855
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        25.1035855,
        55.5275841,
        25.1135855,
        55.5375841
      ]
    },
    {
      "id": 8843,
      "name": "Varena",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.2093718,
        "lng": 24.5747343
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.5697343,
        54.2043718,
        24.5797343,
        54.2143718
      ]
    },
    {
      "id": 8845,
      "name": "Prienai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6338459,
        "lng": 23.9428415
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.9238077,
        54.612426,
        23.9886017,
        54.6593758
      ]
    },
    {
      "id": 8849,
      "name": "Krsko",
      "names": {
        "zh": "克尔什科"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9576446,
        "lng": 15.4946767
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3342331,
        45.8208225,
        15.5703462,
        46.0717249
      ]
    },
    {
      "id": 8853,
      "name": "Brokopondo",
      "names": {
        "zh": "布罗科蓬多"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.040376,
        "lng": -55.020011
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -55.0400114356,
        5.02037579235,
        -55.0000114356,
        5.06037579235
      ]
    },
    {
      "id": 8860,
      "name": "Tayoltita",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.106596,
        "lng": -105.927097
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.942222,
        24.093108,
        -105.918605,
        24.114433
      ]
    },
    {
      "id": 8862,
      "name": "Tevragh Zeina",
      "names": {
        "ja": "テヴラギゼーナ"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 18.09894,
        "lng": -15.98848
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -16.00848,
        18.07894,
        -15.96848,
        18.11894
      ]
    },
    {
      "id": 8867,
      "name": "Beocin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.189611049999996,
        "lng": 19.72457875522479
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.6864629,
        45.1480087,
        19.7569233,
        45.2312125
      ]
    },
    {
      "id": 8868,
      "name": "Soro",
      "names": {
        "zh": "索罗"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.4365822,
        "lng": 11.5629363
      },
      "country": "Denmark",
      "importance": 1,
      "bbox": [
        11.5229363,
        55.3965822,
        11.6029363,
        55.4765822
      ]
    },
    {
      "id": 8870,
      "name": "Balakan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.7246212,
        "lng": 46.4089371
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.391015,
        41.6926223,
        46.4358294,
        41.7402377
      ]
    },
    {
      "id": 8872,
      "name": "Slovenska Bistrica",
      "names": {
        "ja": "スロベンスカビストリサ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.391944,
        "lng": 15.5727757
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3336329,
        46.316986,
        15.6867115,
        46.4977836
      ]
    },
    {
      "id": 8879,
      "name": "Guadalupe y Calvo",
      "names": {
        "zh": "瓜达卢佩y calvo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.0915222,
        "lng": -106.9616536
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -107.0016536,
        26.0515222,
        -106.9216536,
        26.1315222
      ]
    },
    {
      "id": 8884,
      "name": "Gumushane",
      "names": {
        "zh": "居米什哈内"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.460452,
        "lng": 39.480013
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        39.460115,
        40.453225,
        39.495892,
        40.466085
      ]
    },
    {
      "id": 8887,
      "name": "Port Maria",
      "names": {
        "es": "Puerto maria",
        "zh": "玛丽亚港",
        "de": "Port-Maria",
        "ja": "ポートマリア",
        "th": "พอร์ตมาเรีย",
        "pt": "Porto maria"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.3732372,
        "lng": -76.8938277
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -76.9338277,
        18.3332372,
        -76.8538277,
        18.4132372
      ]
    },
    {
      "id": 8889,
      "name": "Nangan",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 26.15655405,
        "lng": 119.9491498843183
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        119.9089398,
        26.1377685,
        119.968206,
        26.1753501
      ]
    },
    {
      "id": 8891,
      "name": "Panindicuaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9839755,
        "lng": -101.7612192
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.8012192,
        19.9439755,
        -101.7212192,
        20.0239755
      ]
    },
    {
      "id": 8893,
      "name": "Palo Alto",
      "names": {
        "zh": "帕洛阿尔托",
        "ja": "パロアルト"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 43.0762829,
        "lng": -94.6625047
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.915352,
        42.9080761,
        -94.4427699,
        43.2552973
      ]
    },
    {
      "id": 8898,
      "name": "Mtskheta",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.846295,
        "lng": 44.7195591
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        44.632532,
        41.8023027,
        44.7302368,
        41.8945508
      ]
    },
    {
      "id": 8899,
      "name": "Sokobanja",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6440513,
        "lng": 21.8730724
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.8264908,
        43.5941764,
        21.9055544,
        43.6761906
      ]
    },
    {
      "id": 8901,
      "name": "Panaba",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2962899,
        "lng": -88.2707801
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -88.2787517,
        21.2862556,
        -88.2626482,
        21.3025291
      ]
    },
    {
      "id": 8902,
      "name": "Greymouth",
      "names": {
        "zh": "格雷茅斯"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -42.464817,
        "lng": 171.200371
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        171.092617,
        -42.730128,
        171.426092,
        -42.312388
      ]
    },
    {
      "id": 8903,
      "name": "Santa Maria del Oro",
      "names": {
        "zh": "圣玛丽亚del Oro",
        "ja": "サンタマリアデルオーロ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.9540852,
        "lng": -105.3669215
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.4069215,
        25.9140852,
        -105.3269215,
        25.9940852
      ]
    },
    {
      "id": 8905,
      "name": "Litija",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0575677,
        "lng": 14.8314192
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.7116337,
        45.9630322,
        15.0995699,
        46.1363037
      ]
    },
    {
      "id": 8907,
      "name": "Yeghegnadzor",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 39.7601008,
        "lng": 45.3302273
      },
      "country": "Armenia",
      "importance": 1,
      "bbox": [
        45.2895163,
        39.7081077,
        45.3762783,
        39.7782035
      ]
    },
    {
      "id": 8909,
      "name": "San Juan Bautista",
      "names": {
        "zh": "圣胡安Bautista"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -26.6690194,
        "lng": -57.1455794
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -57.1596396,
        -26.682486,
        -57.1233683,
        -26.6535998
      ]
    },
    {
      "id": 8910,
      "name": "Mahmud-e Raqi",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.02298,
        "lng": 69.35112
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        69.33112,
        35.00298,
        69.37112,
        35.04298
      ]
    },
    {
      "id": 8911,
      "name": "Falmouth",
      "names": {
        "zh": "法尔茅斯",
        "ja": "ファルマス",
        "th": "ฟัลเมาท์"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 38.6767366,
        "lng": -84.3304592
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -84.3467187,
        38.654218,
        -84.32125,
        38.6828463
      ]
    },
    {
      "id": 8915,
      "name": "Bentiu",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 9.2480864,
        "lng": 29.8003988
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        29.6403988,
        9.0880864,
        29.9603988,
        9.4080864
      ]
    },
    {
      "id": 8916,
      "name": "Ajdovscina",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8885557,
        "lng": 13.9048561
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.7331432,
        45.8239833,
        14.129576,
        46.0005907
      ]
    },
    {
      "id": 8923,
      "name": "Angostura",
      "names": {
        "zh": "安戈斯图拉",
        "ja": "アンゴスチュラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.3657471,
        "lng": -108.16152
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.20152,
        25.3257471,
        -108.12152,
        25.4057471
      ]
    },
    {
      "id": 8924,
      "name": "Luba",
      "names": {
        "zh": "卢巴",
        "th": "ลูบา"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 3.459841,
        "lng": 8.5544476
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        8.5144476,
        3.419841,
        8.5944476,
        3.499841
      ]
    },
    {
      "id": 8926,
      "name": "Aracinovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.02639,
        "lng": 21.56194
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.54194,
        42.00639,
        21.58194,
        42.04639
      ]
    },
    {
      "id": 8930,
      "name": "Cospicua",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8825817,
        "lng": 14.522721
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5123582,
        35.8756848,
        14.5296314,
        35.8894673
      ]
    },
    {
      "id": 8931,
      "name": "Yardimli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.90771,
        "lng": 48.24052
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.22052,
        38.88771,
        48.26052,
        38.92771
      ]
    },
    {
      "id": 8934,
      "name": "Nombre de Dios",
      "names": {
        "zh": "Nombrede dios"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.8483984,
        "lng": -104.243564
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.283564,
        23.8083984,
        -104.203564,
        23.8883984
      ]
    },
    {
      "id": 8935,
      "name": "Pitiquito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.6775289,
        "lng": -112.0546569
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -112.0946569,
        30.6375289,
        -112.0146569,
        30.7175289
      ]
    },
    {
      "id": 8944,
      "name": "Diekirch",
      "names": {
        "zh": "迪基希"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.8690898,
        "lng": 6.1600549
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        6.117529,
        49.83496,
        6.1806583,
        49.89867
      ]
    },
    {
      "id": 8946,
      "name": "Sen Monorom",
      "names": {
        "zh": "森莫诺林",
        "ja": "モノロムを求めてください",
        "th": "เซน"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.45583,
        "lng": 107.18811
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        107.16811,
        12.43583,
        107.20811,
        12.47583
      ]
    },
    {
      "id": 8947,
      "name": "Balzan",
      "names": {
        "zh": "巴尔赞"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.897185,
        "lng": 14.452656
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.445908,
        35.892926,
        14.457187,
        35.902323
      ]
    },
    {
      "id": 8948,
      "name": "Kelme",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.6294679,
        "lng": 22.9313095
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.9140171,
        55.614942,
        22.9738183,
        55.6503341
      ]
    },
    {
      "id": 8950,
      "name": "Leova",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.4780285,
        "lng": 28.2539655
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.2092938,
        46.4452404,
        28.3161631,
        46.5207766
      ]
    },
    {
      "id": 8956,
      "name": "Ludza",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.5477177,
        "lng": 27.7242092
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        27.6808827,
        56.5198249,
        27.7461224,
        56.5659256
      ]
    },
    {
      "id": 8958,
      "name": "Ravne na Koroskem",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5446516,
        "lng": 14.9622863
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.8836043,
        46.4839804,
        15.0149687,
        46.6127706
      ]
    },
    {
      "id": 8959,
      "name": "Goranboy",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.6084847,
        "lng": 46.7882013
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.7697912,
        40.5917252,
        46.8135117,
        40.6269822
      ]
    },
    {
      "id": 8963,
      "name": "Kicevo",
      "names": {
        "zh": "基切沃"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.5089037,
        "lng": 20.9585155
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.9179436,
        41.4780898,
        20.9946018,
        41.5407713
      ]
    },
    {
      "id": 8965,
      "name": "Kirikkale",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8432824,
        "lng": 33.5182452
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        33.5181952,
        39.8432324,
        33.5182952,
        39.8433324
      ]
    },
    {
      "id": 8968,
      "name": "Ruyigi",
      "names": {
        "zh": "鲁伊吉"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.4733471,
        "lng": 30.2501278
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        30.0901278,
        -3.6333471,
        30.4101278,
        -3.3133471
      ]
    },
    {
      "id": 8969,
      "name": "Briceni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.3613276,
        "lng": 27.0849008
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.0440264,
        48.3384926,
        27.1110535,
        48.3766032
      ]
    },
    {
      "id": 8970,
      "name": "Lerik",
      "names": {
        "zh": "莱里克"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.77388,
        "lng": 48.41497
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.39497,
        38.75388,
        48.43497,
        38.79388
      ]
    },
    {
      "id": 8971,
      "name": "Montpelier",
      "names": {
        "zh": "蒙彼利埃",
        "th": "มอนต์เพเลียร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.2602244,
        "lng": -72.5750364
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -72.6124477,
        44.2349232,
        -72.5250871,
        44.3133148
      ]
    },
    {
      "id": 8972,
      "name": "Ta' Xbiex",
      "names": {
        "es": "Ta 'xbiex",
        "fr": "Ta 'xbiex",
        "zh": "ta'xbiex",
        "de": "Ta 'xbiex",
        "it": "Ta 'XBIEX",
        "id": "Ta 'xbiex",
        "ja": "Ta 'Xbiex",
        "th": "ta 'xbiex",
        "pt": "Ta 'xbiex"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8991087,
        "lng": 14.4948986
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4905893,
        35.8966196,
        14.500893,
        35.9017958
      ]
    },
    {
      "id": 8974,
      "name": "Ocnita",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.4103263,
        "lng": 27.4788168
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.4388168,
        48.3703263,
        27.5188168,
        48.4503263
      ]
    },
    {
      "id": 8976,
      "name": "Kraslava",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 55.895206,
        "lng": 27.1619304
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        27.1265415,
        55.8795239,
        27.1990566,
        55.9176195
      ]
    },
    {
      "id": 8978,
      "name": "Slovenj Gradec",
      "names": {
        "de": "Slowenj-Gradec",
        "ja": "スロベンジャグレード"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.509144,
        "lng": 15.0790677
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.9533625,
        46.4188573,
        15.1954199,
        46.5661534
      ]
    },
    {
      "id": 8979,
      "name": "Mobaye",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 4.3254367,
        "lng": 21.1778122
      },
      "country": "Central African Republic",
      "importance": 1,
      "bbox": [
        21.0178122,
        4.1654367,
        21.3378122,
        4.4854367
      ]
    },
    {
      "id": 8980,
      "name": "Mongomo",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.6282551,
        "lng": 11.3184671
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        10.9032632,
        1.4711047,
        11.3815439,
        1.8162165
      ]
    },
    {
      "id": 8981,
      "name": "Lapovo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1854337,
        "lng": 21.1047905
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.0729205,
        44.1601832,
        21.1101768,
        44.2222884
      ]
    },
    {
      "id": 8982,
      "name": "Hola",
      "names": {
        "zh": "霍拉"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.4976808,
        "lng": 40.034911
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        39.994911,
        -1.5376808,
        40.074911,
        -1.4576808
      ]
    },
    {
      "id": 8984,
      "name": "Telenesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.49972,
        "lng": 28.36556
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.34556,
        47.47972,
        28.38556,
        47.51972
      ]
    },
    {
      "id": 8985,
      "name": "Adazi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.0762416,
        "lng": 24.3213427
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.3212927,
        57.0761916,
        24.3213927,
        57.0762916
      ]
    },
    {
      "id": 8986,
      "name": "Naujoji Akmene",
      "names": {
        "fr": "Naujoji Akmène",
        "id": "Naujoji akmenene",
        "ja": "ナウジョジア青江"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 56.3253508,
        "lng": 22.8797889
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.8571719,
        56.3024852,
        22.929385,
        56.3303111
      ]
    },
    {
      "id": 8990,
      "name": "Nakasongola",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.3164753,
        "lng": 32.4651618
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.4251618,
        1.2764753,
        32.5051618,
        1.3564753
      ]
    },
    {
      "id": 8993,
      "name": "Aizkraukle",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6010778,
        "lng": 25.2542544
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        25.2142544,
        56.5610778,
        25.2942544,
        56.6410778
      ]
    },
    {
      "id": 8995,
      "name": "Donduseni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.226494,
        "lng": 27.584965
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        27.564965,
        48.206494,
        27.604965,
        48.246494
      ]
    },
    {
      "id": 8997,
      "name": "Mahibadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 3.7572355,
        "lng": 72.9689431887705
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        72.9645522,
        3.7556417,
        72.9732506,
        3.7588722
      ]
    },
    {
      "id": 8999,
      "name": "Monaghan",
      "names": {
        "zh": "莫内根",
        "ja": "モナハン",
        "th": "โมนาฮัน"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 54.2485576,
        "lng": -6.9688855
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.9983496,
        54.2293517,
        -6.9415493,
        54.2660634
      ]
    },
    {
      "id": 9001,
      "name": "Trindade",
      "names": {
        "zh": "特林达迪"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.29691,
        "lng": 6.6811074
      },
      "country": "Sao Tome And Principe",
      "importance": 1,
      "bbox": [
        6.6411074,
        0.25691,
        6.7211074,
        0.33691
      ]
    },
    {
      "id": 9004,
      "name": "Livani",
      "names": {
        "zh": "利瓦尼"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.3531859,
        "lng": 26.177657
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        26.1541878,
        56.332739,
        26.2155682,
        56.3772568
      ]
    },
    {
      "id": 9006,
      "name": "In Guezzam",
      "names": {
        "es": "En guezzam",
        "fr": "À Guezzam",
        "zh": "在格瓦姆",
        "id": "Di guezzam",
        "ja": "Guezzamで",
        "th": "ใน Guzzam",
        "pt": "Em guezzam"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 19.56861,
        "lng": 5.77222
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.75222,
        19.54861,
        5.79222,
        19.58861
      ]
    },
    {
      "id": 9009,
      "name": "Brezice",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9038696,
        "lng": 15.5930859
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.4907324,
        45.8204166,
        15.7324872,
        46.0548687
      ]
    },
    {
      "id": 9010,
      "name": "Gulbene",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.1759721,
        "lng": 26.7514713
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        26.6912967,
        57.1552616,
        26.7947277,
        57.190713
      ]
    },
    {
      "id": 9011,
      "name": "Oguz",
      "names": {
        "zh": "奥乌兹"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.0677148,
        "lng": 47.4646904
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.435714,
        41.0548579,
        47.478801,
        41.0930576
      ]
    },
    {
      "id": 9012,
      "name": "Limbazi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.5146586,
        "lng": 24.7131536
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.6841809,
        57.4866404,
        24.7612566,
        57.5336085
      ]
    },
    {
      "id": 9013,
      "name": "Luqa",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.859653,
        "lng": 14.4892913
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4576263,
        35.8280428,
        14.513972,
        35.8756565
      ]
    },
    {
      "id": 9014,
      "name": "Salavan",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 15.71677,
        "lng": 106.42172
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        106.40172,
        15.69677,
        106.44172,
        15.73677
      ]
    },
    {
      "id": 9017,
      "name": "Madona",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8517467,
        "lng": 26.2184769
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        26.1936626,
        56.8363717,
        26.2567743,
        56.8706012
      ]
    },
    {
      "id": 9019,
      "name": "Eydhafushi",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.1033363,
        "lng": 73.0703652
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.0303652,
        5.0633363,
        73.1103652,
        5.1433363
      ]
    },
    {
      "id": 9020,
      "name": "Criuleni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.214447,
        "lng": 29.163244
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.153989,
        47.205189,
        29.172503,
        47.223705
      ]
    },
    {
      "id": 9021,
      "name": "Kovacica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.111874,
        "lng": 20.6201585
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.5955591,
        45.0768781,
        20.7190994,
        45.1554905
      ]
    },
    {
      "id": 9023,
      "name": "Salcininkai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.3089991,
        "lng": 25.3821472
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        25.3594876,
        54.2928195,
        25.4077557,
        54.3249869
      ]
    },
    {
      "id": 9024,
      "name": "Opuwo",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -18.0550384,
        "lng": 13.8387366
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        13.8235856,
        -18.0723882,
        13.8616149,
        -18.0409527
      ]
    },
    {
      "id": 9027,
      "name": "Stratford",
      "names": {
        "zh": "斯特拉特福",
        "ja": "ストラットフォード",
        "th": "สแตรทฟอร์ด"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": 36.3361403,
        "lng": -102.072119
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        -102.08959,
        36.3281914,
        -102.0571166,
        36.3464094
      ]
    },
    {
      "id": 9038,
      "name": "Aluksne",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.4204759,
        "lng": 27.0505075
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        27.0105075,
        57.3804759,
        27.0905075,
        57.4604759
      ]
    },
    {
      "id": 9039,
      "name": "Mae Hong Son",
      "names": {
        "es": "Mae hong hijo",
        "fr": "Mae hong fils",
        "zh": "毛洪子",
        "de": "Mae Hong Sohn",
        "ja": "メーホンソン",
        "th": "แม่ฮ่องสอน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.3015068,
        "lng": 97.9666073
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        97.9266073,
        19.2615068,
        98.0066073,
        19.3415068
      ]
    },
    {
      "id": 9043,
      "name": "Bogatic",
      "names": {
        "zh": "博加蒂奇"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8343025,
        "lng": 19.4795213
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.4425951,
        44.7971314,
        19.5282045,
        44.9024954
      ]
    },
    {
      "id": 9049,
      "name": "Sal Rei",
      "names": {
        "de": "Sals reif",
        "ja": "サルリーイ"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.1759748,
        "lng": -22.9169771
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -22.9569771,
        16.1359748,
        -22.8769771,
        16.2159748
      ]
    },
    {
      "id": 9050,
      "name": "Pasvalys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.0622706,
        "lng": 24.3965741
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.3598706,
        56.0443085,
        24.4325584,
        56.0752214
      ]
    },
    {
      "id": 9051,
      "name": "Jogeva",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.747052,
        "lng": 26.397098
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        26.361025,
        58.732073,
        26.419837,
        58.754344
      ]
    },
    {
      "id": 9052,
      "name": "Ghanzi",
      "names": {
        "zh": "杭济"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -21.6968123,
        "lng": 21.6457062
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        21.4857062,
        -21.8568123,
        21.8057062,
        -21.5368123
      ]
    },
    {
      "id": 9053,
      "name": "Polva",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.0536163,
        "lng": 27.0582568
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        27.0411151,
        58.040101,
        27.1058134,
        58.0756149
      ]
    },
    {
      "id": 9054,
      "name": "Espargos",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 16.7532615,
        "lng": -22.9454446
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -22.9454946,
        16.7532115,
        -22.9453946,
        16.7533115
      ]
    },
    {
      "id": 9055,
      "name": "Funadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 6.1488286,
        "lng": 73.2903689
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.2503689,
        6.1088286,
        73.3303689,
        6.1888286
      ]
    },
    {
      "id": 9056,
      "name": "Kavadarci",
      "names": {
        "zh": "卡瓦达尔齐"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.43306,
        "lng": 22.01194
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.99194,
        41.41306,
        22.03194,
        41.45306
      ]
    },
    {
      "id": 9057,
      "name": "Backi Petrovac",
      "names": {
        "fr": "BASDI PETROVAC",
        "ja": "バックティペトロバック"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3600646,
        "lng": 19.5914331
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5575969,
        45.2948477,
        19.6761781,
        45.4036388
      ]
    },
    {
      "id": 9060,
      "name": "Schaan",
      "names": {
        "zh": "沙恩"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1663397,
        "lng": 9.510312
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.4858412,
        47.0607574,
        9.6247232,
        47.1942267
      ]
    },
    {
      "id": 9062,
      "name": "Preili",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.2937366,
        "lng": 26.7266734
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        26.7001661,
        56.275245,
        26.7436787,
        56.3071328
      ]
    },
    {
      "id": 9063,
      "name": "Mamushe",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3315142,
        "lng": 20.7259633
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.7071498,
        42.3031755,
        20.7547632,
        42.3476545
      ]
    },
    {
      "id": 9064,
      "name": "Viqueque",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.854292,
        "lng": 126.3660546
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        126.3660046,
        -8.854342,
        126.3661046,
        -8.854242
      ]
    },
    {
      "id": 9065,
      "name": "Junik",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4761209,
        "lng": 20.2773737
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.2373737,
        42.4361209,
        20.3173737,
        42.5161209
      ]
    },
    {
      "id": 9066,
      "name": "Siteki",
      "names": {},
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.4536442,
        "lng": 31.9463149
      },
      "country": "Swaziland",
      "importance": 1,
      "bbox": [
        31.9063149,
        -26.4936442,
        31.9863149,
        -26.4136442
      ]
    },
    {
      "id": 9067,
      "name": "Lucea",
      "names": {},
      "countryCode": "JM",
      "latLng": {
        "lat": 18.45095,
        "lng": -78.17356
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -78.19356,
        18.43095,
        -78.15356,
        18.47095
      ]
    },
    {
      "id": 9068,
      "name": "Rapla",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.0056905,
        "lng": 24.794397
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        24.777686,
        58.9874355,
        24.8414225,
        59.0116658
      ]
    },
    {
      "id": 9069,
      "name": "Rutana",
      "names": {
        "zh": "鲁塔纳"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.9279,
        "lng": 29.992
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.972,
        -3.9479,
        30.012,
        -3.9079
      ]
    },
    {
      "id": 9070,
      "name": "Puerto Baquerizo Moreno",
      "names": {
        "ja": "プエルトバキェリゾヨーエノ"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.902599,
        "lng": -89.610191
      },
      "country": "Ecuador",
      "importance": 1,
      "bbox": [
        -89.627955,
        -0.9216281,
        -89.5988214,
        -0.889055
      ]
    },
    {
      "id": 9074,
      "name": "Glarus",
      "names": {
        "es": "Glaris",
        "zh": "格拉鲁斯",
        "ja": "グラールス",
        "th": "กลารุส"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0400002,
        "lng": 9.0715056
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        9.0665056,
        47.0350002,
        9.0765056,
        47.0450002
      ]
    },
    {
      "id": 9075,
      "name": "Cabrayil",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.39917,
        "lng": 47.02834
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.00834,
        39.37917,
        47.04834,
        39.41917
      ]
    },
    {
      "id": 9077,
      "name": "Bac",
      "names": {
        "zh": "杆状",
        "ja": "のBAC",
        "th": "บัค"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3916793,
        "lng": 19.2368045
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.1501132,
        45.3024683,
        19.3119052,
        45.4501643
      ]
    },
    {
      "id": 9078,
      "name": "Appenzell",
      "names": {
        "zh": "阿彭策尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3299506,
        "lng": 9.4099311
      },
      "country": "Switzerland",
      "importance": 1,
      "bbox": [
        9.3719032,
        47.3042735,
        9.4792318,
        47.3612688
      ]
    },
    {
      "id": 9082,
      "name": "Vittoriosa",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8881119,
        "lng": 14.5238445
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5166388,
        35.8802052,
        14.5312147,
        35.8931184
      ]
    },
    {
      "id": 9083,
      "name": "Sezana",
      "names": {
        "zh": "塞扎纳"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.706141,
        "lng": 13.859093
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.847756,
        45.700138,
        13.880871,
        45.713527
      ]
    },
    {
      "id": 9084,
      "name": "Zarasai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.7304035,
        "lng": 26.2471471
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        26.2089533,
        55.7093658,
        26.2810104,
        55.7485409
      ]
    },
    {
      "id": 9085,
      "name": "Zagorje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1206083,
        "lng": 14.991071
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.986071,
        46.1156083,
        14.996071,
        46.1256083
      ]
    },
    {
      "id": 9086,
      "name": "Muramvya",
      "names": {
        "zh": "穆拉姆维亚"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.2663248,
        "lng": 29.6167289
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.4567289,
        -3.4263248,
        29.7767289,
        -3.1063248
      ]
    },
    {
      "id": 9088,
      "name": "Marsa",
      "names": {
        "zh": "马尔萨",
        "ja": "マルサ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.882892,
        "lng": 14.4944898
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4806053,
        35.8652865,
        14.505665,
        35.8880924
      ]
    },
    {
      "id": 9091,
      "name": "Outapi",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.5046361,
        "lng": 14.9865165
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        14.9721951,
        -17.5186394,
        15.0002497,
        -17.4861644
      ]
    },
    {
      "id": 9092,
      "name": "Ghaxaq",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8484527,
        "lng": 14.5173587
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5044951,
        35.8314414,
        14.528096,
        35.8618098
      ]
    },
    {
      "id": 9094,
      "name": "Echternach",
      "names": {
        "zh": "埃希特纳赫"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.8120961,
        "lng": 6.4214859
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        6.366396,
        49.7687119,
        6.470854,
        49.8326435
      ]
    },
    {
      "id": 9095,
      "name": "Kupiskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.8388909,
        "lng": 24.9760431
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.9442862,
        55.8228579,
        25.0169042,
        55.8498704
      ]
    },
    {
      "id": 9096,
      "name": "Crnomelj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5700965,
        "lng": 15.1923324
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0532765,
        45.4214242,
        15.3858421,
        45.6288388
      ]
    },
    {
      "id": 9097,
      "name": "Idrija",
      "names": {
        "zh": "伊德里亚"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0023983,
        "lng": 14.0273014
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.8551004,
        45.8785493,
        14.1459529,
        46.0890611
      ]
    },
    {
      "id": 9098,
      "name": "Prevalje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5460617,
        "lng": 14.9169796
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.8276738,
        46.4980984,
        14.9533657,
        46.611785
      ]
    },
    {
      "id": 9101,
      "name": "Ranillug",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.481302,
        "lng": 21.592655
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.592544,
        42.4812392,
        21.592767,
        42.4813648
      ]
    },
    {
      "id": 9104,
      "name": "Lija",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.901766,
        "lng": 14.4476136
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4340072,
        35.8967175,
        14.4523707,
        35.9054474
      ]
    },
    {
      "id": 9105,
      "name": "Balvi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.128272,
        "lng": 27.267746
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        27.245112,
        57.118058,
        27.290203,
        57.136957
      ]
    },
    {
      "id": 9110,
      "name": "Lorengau",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -2.02309,
        "lng": 147.268852
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        147.263862,
        -2.028717,
        147.271309,
        -2.019577
      ]
    },
    {
      "id": 9111,
      "name": "Hrastnik",
      "names": {
        "zh": "赫拉斯特尼克"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1456196,
        "lng": 15.0817358
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0577751,
        46.0860701,
        15.1889292,
        46.1839515
      ]
    },
    {
      "id": 9120,
      "name": "Gospic",
      "names": {
        "zh": "戈斯皮奇"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.5464046,
        "lng": 15.375421
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        15.0630028,
        44.3395151,
        15.6512069,
        44.7097105
      ]
    },
    {
      "id": 9121,
      "name": "Sisimiut",
      "names": {},
      "countryCode": "GL",
      "latLng": {
        "lat": 66.9371906,
        "lng": -53.6664816
      },
      "country": "Greenland",
      "importance": 1,
      "bbox": [
        -53.8264816,
        66.7771906,
        -53.5064816,
        67.0971906
      ]
    },
    {
      "id": 9122,
      "name": "Wiltz",
      "names": {
        "zh": "维尔茨"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.9666914,
        "lng": 5.9321021
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        5.8778744,
        49.934536,
        5.980192,
        50.032234
      ]
    },
    {
      "id": 9123,
      "name": "Cacheu",
      "names": {},
      "countryCode": "GW",
      "latLng": {
        "lat": 12.274246,
        "lng": -16.1648911
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -16.2048911,
        12.234246,
        -16.1248911,
        12.314246
      ]
    },
    {
      "id": 9125,
      "name": "Babak",
      "names": {
        "zh": "巴巴克"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.4737018,
        "lng": 48.2332473
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.2132473,
        39.4537018,
        48.2532473,
        39.4937018
      ]
    },
    {
      "id": 9128,
      "name": "Kerema",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -7.9596229,
        "lng": 145.7724355
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        145.6124355,
        -8.1196229,
        145.9324355,
        -7.7996229
      ]
    },
    {
      "id": 9129,
      "name": "Koboko",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.4097256,
        "lng": 30.9603105
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.9203105,
        3.3697256,
        31.0003105,
        3.4497256
      ]
    },
    {
      "id": 9132,
      "name": "Partesh",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.40194,
        "lng": 21.43361
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9134,
      "name": "Kazlu Ruda",
      "names": {
        "ja": "ラーダkazlu ruda"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.749494,
        "lng": 23.49556
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.470761,
        54.736714,
        23.518091,
        54.76044
      ]
    },
    {
      "id": 9136,
      "name": "Caazapa",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -26.166667,
        "lng": -56
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -56.8490816,
        -26.794376,
        -55.2481922,
        -25.5235248
      ]
    },
    {
      "id": 9139,
      "name": "Moletai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2319273,
        "lng": 25.419316
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        25.389759,
        55.2141398,
        25.44721,
        55.2372034
      ]
    },
    {
      "id": 9140,
      "name": "Thaba-Tseka",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -29.5151832,
        "lng": 28.6036179
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        28.5636179,
        -29.5551832,
        28.6436179,
        -29.4751832
      ]
    },
    {
      "id": 9141,
      "name": "Qal`ah-ye Now",
      "names": {
        "es": "Qal`ah-ye ahora",
        "fr": "Qal`ah-ye maintenant",
        "zh": "Qal`ah-ye现在",
        "de": "Qal`ah-ye jetzt",
        "it": "QAL B`ah-ye ora",
        "id": "Qal`ah-ye sekarang",
        "ja": "Qal`ah-Ye今",
        "th": "qal`ah-ye ตอนนี้",
        "pt": "Qal`ah-ye agora"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.810696,
        "lng": 63.123529
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        62.9983871,
        34.6236313,
        63.2993535,
        35.0674645
      ]
    },
    {
      "id": 9143,
      "name": "Kanifing",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.44639,
        "lng": -16.67444
      },
      "country": "The Gambia",
      "importance": 1,
      "bbox": [
        -16.69444,
        13.42639,
        -16.65444,
        13.46639
      ]
    },
    {
      "id": 9145,
      "name": "Triesen",
      "names": {
        "zh": "特里森"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.106994,
        "lng": 9.5274876
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.5127623,
        47.0484291,
        9.603472,
        47.1244719
      ]
    },
    {
      "id": 9146,
      "name": "Xaghra",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0502996,
        "lng": 14.2648446
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.249259,
        36.0382522,
        14.2862888,
        36.0732476
      ]
    },
    {
      "id": 9148,
      "name": "Ros Comain",
      "names": {
        "es": "Rosada",
        "fr": "Ros Comité",
        "zh": "罗斯蒙太此",
        "it": "Ros comein",
        "id": "Comain",
        "pt": "Ros comin"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.6312446,
        "lng": -8.19068
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -8.23306,
        53.6077591,
        -8.1609294,
        53.650816
      ]
    },
    {
      "id": 9150,
      "name": "Kiambu",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.16667,
        "lng": 36.83333
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.81333,
        -1.18667,
        36.85333,
        -1.14667
      ]
    },
    {
      "id": 9155,
      "name": "Kalangala",
      "names": {
        "zh": "卡兰加拉"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3201069,
        "lng": 32.2924243
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.2524243,
        -0.3601069,
        32.3324243,
        -0.2801069
      ]
    },
    {
      "id": 9157,
      "name": "Tarrafal",
      "names": {
        "zh": "塔拉法尔"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.566483,
        "lng": -24.3567967
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -24.3967967,
        16.526483,
        -24.3167967,
        16.606483
      ]
    },
    {
      "id": 9158,
      "name": "Wangdue Phodrang",
      "names": {
        "zh": "王德菲堡",
        "id": "Phodrang wangdue"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.48615,
        "lng": 89.89915
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.87915,
        27.46615,
        89.91915,
        27.50615
      ]
    },
    {
      "id": 9160,
      "name": "Albina",
      "names": {
        "zh": "阿尔宾娜",
        "ja": "アルビナ"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.498483,
        "lng": -54.0574537
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -54.230845,
        5.3610294,
        -54.005067,
        5.6503405
      ]
    },
    {
      "id": 9161,
      "name": "Soroti",
      "names": {
        "zh": "索罗提"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.7139501,
        "lng": 33.6112948
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.5712948,
        1.6739501,
        33.6512948,
        1.7539501
      ]
    },
    {
      "id": 9162,
      "name": "Pembroke",
      "names": {
        "zh": "彭布罗克",
        "ja": "ペンブローク",
        "th": "เพมโบรก"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9281313,
        "lng": 14.4795722
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4622686,
        35.9231393,
        14.4898184,
        35.9380008
      ]
    },
    {
      "id": 9165,
      "name": "Kekava",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8261827,
        "lng": 24.233653
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.193653,
        56.7861827,
        24.273653,
        56.8661827
      ]
    },
    {
      "id": 9168,
      "name": "Slovenske Konjice",
      "names": {
        "ja": "スロベンスキーコニス"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3381954,
        "lng": 15.4230172
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3560676,
        46.278095,
        15.5537236,
        46.371414
      ]
    },
    {
      "id": 9169,
      "name": "Grevenmacher",
      "names": {
        "zh": "格雷文马赫"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.6751818,
        "lng": 6.4413093
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        6.4412593,
        49.6751318,
        6.4413593,
        49.6752318
      ]
    },
    {
      "id": 9171,
      "name": "Pale",
      "names": {
        "es": "Pálido",
        "fr": "Pâle",
        "zh": "苍白",
        "de": "Blass",
        "it": "Pallido",
        "id": "Pucat",
        "ja": "淡い",
        "th": "ซีด",
        "pt": "Pálido"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 42.9831528,
        "lng": 12.7751732
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        12.7551732,
        42.9631528,
        12.7951732,
        43.0031528
      ]
    },
    {
      "id": 9173,
      "name": "Smiltene",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.4243197,
        "lng": 25.9045413
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        25.866882,
        57.4080214,
        25.9358331,
        57.4384411
      ]
    },
    {
      "id": 9174,
      "name": "Liquica",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.5875,
        "lng": 125.34194
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.32194,
        -8.6075,
        125.36194,
        -8.5675
      ]
    },
    {
      "id": 9179,
      "name": "Nadur",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0389956,
        "lng": 14.2934514
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.2772582,
        36.0338874,
        14.3180488,
        36.0657207
      ]
    },
    {
      "id": 9183,
      "name": "Skuodas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.269129,
        "lng": 21.533751
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.485442,
        56.256549,
        21.549467,
        56.2766
      ]
    },
    {
      "id": 9185,
      "name": "Ilulissat",
      "names": {
        "zh": "伊卢利萨特"
      },
      "countryCode": "GL",
      "latLng": {
        "lat": 69.2197127,
        "lng": -51.0986385
      },
      "country": "Greenland",
      "importance": 1,
      "bbox": [
        -51.2586385,
        69.0597127,
        -50.9386385,
        69.3797127
      ]
    },
    {
      "id": 9187,
      "name": "Vrapciste",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.83389,
        "lng": 20.88528
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.86528,
        41.81389,
        20.90528,
        41.85389
      ]
    },
    {
      "id": 9189,
      "name": "Zalec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2495022,
        "lng": 15.1662108
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.1612108,
        46.2445022,
        15.1712108,
        46.2545022
      ]
    },
    {
      "id": 9191,
      "name": "Punta Gorda",
      "names": {
        "ja": "プンタゴルダ",
        "th": "ปุนตากอร์ดา"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 16.0990897,
        "lng": -88.8078855
      },
      "country": "Belize",
      "importance": 1,
      "bbox": [
        -88.8208236,
        16.0909594,
        -88.7999464,
        16.107977
      ]
    },
    {
      "id": 9192,
      "name": "Punakha",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.5842857,
        "lng": 89.8593094
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.8193094,
        27.5442857,
        89.8993094,
        27.6242857
      ]
    },
    {
      "id": 9193,
      "name": "Samtse",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 26.8993207,
        "lng": 89.0913048
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.0513048,
        26.8593207,
        89.1313048,
        26.9393207
      ]
    },
    {
      "id": 9194,
      "name": "Gevgelija",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1388664,
        "lng": 22.5033192
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.4689722,
        41.1197059,
        22.5360625,
        41.1523236
      ]
    },
    {
      "id": 9195,
      "name": "Sakiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.9559617,
        "lng": 23.0395432
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.0241262,
        54.9347965,
        23.0615708,
        54.9637867
      ]
    },
    {
      "id": 9196,
      "name": "Aibak",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.2674938,
        "lng": 68.0215374
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        67.8615374,
        36.1074938,
        68.1815374,
        36.4274938
      ]
    },
    {
      "id": 9197,
      "name": "Mauren",
      "names": {
        "zh": "毛伦"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2196512,
        "lng": 9.5418065
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.5293256,
        47.1952079,
        9.5848953,
        47.2314552
      ]
    },
    {
      "id": 9198,
      "name": "Iklin",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.907655,
        "lng": 14.453691
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.445514,
        35.902323,
        14.462331,
        35.918176
      ]
    },
    {
      "id": 9199,
      "name": "Sentjur",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2168375,
        "lng": 15.3937905
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3301526,
        46.0655549,
        15.5358711,
        46.3118567
      ]
    },
    {
      "id": 9200,
      "name": "Bled",
      "names": {
        "zh": "布莱德",
        "ja": "ブレッド",
        "th": "เบลด"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.37027285,
        "lng": 14.118569061909003
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.1180512,
        46.3699018,
        14.1189685,
        46.3707661
      ]
    },
    {
      "id": 9201,
      "name": "Nieuw Amsterdam",
      "names": {
        "zh": "nieuw阿姆斯特丹",
        "ja": "Nieuwアムステルダム",
        "th": "Nieuw อัมสเตอร์ดัม"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 40.7127281,
        "lng": -74.0060152
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -74.258843,
        40.476578,
        -73.700233,
        40.91763
      ]
    },
    {
      "id": 9202,
      "name": "Puerto Lempira",
      "names": {
        "ja": "プエルトレンピラ",
        "th": "เปอร์โตลมพิศาล"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.2664413,
        "lng": -83.7725272
      },
      "country": "Honduras",
      "importance": 1,
      "bbox": [
        -84.7918617,
        14.615845,
        -83.385694,
        15.7330694
      ]
    },
    {
      "id": 9203,
      "name": "Bolama",
      "names": {
        "zh": "博拉马"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.57763,
        "lng": -15.475261
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -15.515261,
        11.53763,
        -15.435261,
        11.61763
      ]
    },
    {
      "id": 9204,
      "name": "Eschen",
      "names": {
        "zh": "埃申"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2126274,
        "lng": 9.5233202
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.4878765,
        47.1868278,
        9.579003,
        47.2292154
      ]
    },
    {
      "id": 9205,
      "name": "Irig",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0995183,
        "lng": 19.861045
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.821045,
        45.0595183,
        19.901045,
        45.1395183
      ]
    },
    {
      "id": 9206,
      "name": "Balzers",
      "names": {
        "zh": "巴尔查斯"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.065887,
        "lng": 9.5004
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.48703,
        47.058635,
        9.515866,
        47.079455
      ]
    },
    {
      "id": 9207,
      "name": "Klaksvik",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.226349,
        "lng": -6.580684
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.580684,
        62.226349,
        -6.580684,
        62.226349
      ]
    },
    {
      "id": 9208,
      "name": "Kuala Belait",
      "names": {
        "fr": "Kuala Belaïl",
        "zh": "吉隆坡贝泰",
        "id": "Kuala biji",
        "ja": "クアラレイト",
        "th": "กัวลาลัย"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.5845101,
        "lng": 114.1932373
      },
      "country": "Brunei",
      "importance": 1,
      "bbox": [
        114.182254,
        4.5525113,
        114.2568287,
        4.5986534
      ]
    },
    {
      "id": 9209,
      "name": "Hung Yen",
      "names": {
        "es": "Yen",
        "fr": "Yen accroché",
        "zh": "挂日元",
        "de": "Aufgehängt",
        "it": "Schiatoio",
        "id": "Menggantung yen",
        "ja": "ハングした円",
        "th": "แขวนเยน",
        "pt": "Pendurado iene"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.6568406,
        "lng": 106.054036
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.0043205,
        20.6030956,
        106.1292523,
        20.7164123
      ]
    },
    {
      "id": 9210,
      "name": "Bururi",
      "names": {
        "zh": "布鲁里"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.9453227,
        "lng": 29.6192469
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.4592469,
        -4.1053227,
        29.7792469,
        -3.7853227
      ]
    },
    {
      "id": 9211,
      "name": "Gambela",
      "names": {
        "zh": "甘贝拉"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 8.2503656,
        "lng": 34.5877344
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        34.4277344,
        8.0903656,
        34.7477344,
        8.4103656
      ]
    },
    {
      "id": 9212,
      "name": "Floriana",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8929838,
        "lng": 14.5052716
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4970107,
        35.8860052,
        14.5124394,
        35.8992159
      ]
    },
    {
      "id": 9213,
      "name": "Coka",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.9411228,
        "lng": 20.1399443
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0924126,
        45.9058214,
        20.2578937,
        45.9719051
      ]
    },
    {
      "id": 9214,
      "name": "Radovis",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.63833,
        "lng": 22.46472
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.44472,
        41.61833,
        22.48472,
        41.65833
      ]
    },
    {
      "id": 9215,
      "name": "Kalkara",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8898771,
        "lng": 14.5290867
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5226709,
        35.8843133,
        14.5453199,
        35.8989514
      ]
    },
    {
      "id": 9216,
      "name": "Silale",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.491355,
        "lng": 22.186284
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        22.16616,
        55.465474,
        22.209402,
        55.500899
      ]
    },
    {
      "id": 9217,
      "name": "Ordino",
      "names": {
        "zh": "奥尔迪诺"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.555938,
        "lng": 1.53315
      },
      "country": "Andorra",
      "importance": 1,
      "bbox": [
        1.51315,
        42.535938,
        1.55315,
        42.575938
      ]
    },
    {
      "id": 9218,
      "name": "Imqabba",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.844978,
        "lng": 14.4661443
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4660943,
        35.844928,
        14.4661943,
        35.845028
      ]
    },
    {
      "id": 9219,
      "name": "Bueng Kan",
      "names": {
        "zh": "布恩侃",
        "de": "Bueng Kane",
        "ja": "ブエンカン",
        "th": "บึงกาฬ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.3673678,
        "lng": 103.6515916
      },
      "country": "Thailand",
      "importance": 1,
      "bbox": [
        103.6115916,
        18.3273678,
        103.6915916,
        18.4073678
      ]
    },
    {
      "id": 9220,
      "name": "Sevnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0097431,
        "lng": 15.3037138
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0878057,
        45.9282175,
        15.4243244,
        46.0889772
      ]
    },
    {
      "id": 9221,
      "name": "Valka",
      "names": {
        "zh": "瓦尔卡"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.7729106,
        "lng": 26.0160341
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        25.9642221,
        57.7543988,
        26.0505286,
        57.7984621
      ]
    },
    {
      "id": 9222,
      "name": "Remich",
      "names": {
        "zh": "雷米希"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5442267,
        "lng": 6.3676062
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        6.3391705,
        49.5342426,
        6.3817654,
        49.5614567
      ]
    },
    {
      "id": 9223,
      "name": "Susa",
      "names": {
        "zh": "苏萨",
        "ja": "スーサ",
        "th": "ซูซา"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.7632567,
        "lng": 46.7511501
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.7347348,
        39.7513372,
        46.7608917,
        39.7669637
      ]
    },
    {
      "id": 9224,
      "name": "Trakai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6449911,
        "lng": 24.9353981
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.8830783,
        54.6123364,
        24.9587005,
        54.6726212
      ]
    },
    {
      "id": 9225,
      "name": "Black River",
      "names": {
        "es": "Río negro",
        "fr": "Rivière noire",
        "zh": "黑河",
        "de": "Schwarzen Fluss",
        "it": "Fiume nero",
        "ja": "ブラックリバー",
        "th": "แม่น้ำดำ",
        "pt": "Rio preto"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": -0.2931886,
        "lng": -65.5059548
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -69.9077949,
        -3.1813522,
        -59.8927349,
        2.8378071
      ]
    },
    {
      "id": 9226,
      "name": "Dravograd",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5896276,
        "lng": 15.0235456
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.920418,
        46.5305174,
        15.1277999,
        46.6521565
      ]
    },
    {
      "id": 9227,
      "name": "Pakruojis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9777084,
        "lng": 23.8451797
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.8360574,
        55.9694141,
        23.8831564,
        55.9933834
      ]
    },
    {
      "id": 9228,
      "name": "Joao Teves",
      "names": {
        "id": "Teves Joao"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.0670615,
        "lng": -23.5891819
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.6291819,
        15.0270615,
        -23.5491819,
        15.1070615
      ]
    },
    {
      "id": 9229,
      "name": "Ilirska Bistrica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5636618,
        "lng": 14.2453318
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0905407,
        45.4692716,
        14.5025759,
        45.685261
      ]
    },
    {
      "id": 9230,
      "name": "Gudja",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.84828935,
        "lng": 14.502220712697785
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5020286,
        35.8480799,
        14.5026356,
        35.8484776
      ]
    },
    {
      "id": 9231,
      "name": "Cerknica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7936578,
        "lng": 14.361883
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.251895,
        45.702519,
        14.5085423,
        45.8942313
      ]
    },
    {
      "id": 9232,
      "name": "Marsaxlokk",
      "names": {
        "zh": "马尔萨什洛克"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8417662,
        "lng": 14.5448353
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5267385,
        35.8190123,
        14.5727358,
        35.8495268
      ]
    },
    {
      "id": 9233,
      "name": "Lasko",
      "names": {
        "fr": "Laško",
        "zh": "拉什科",
        "it": "Laško"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.154475,
        "lng": 15.23472
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.207427,
        46.147017,
        15.244507,
        46.172546
      ]
    },
    {
      "id": 9234,
      "name": "Ruse",
      "names": {
        "zh": "鲁塞",
        "ja": "虚勢",
        "th": "อุบาย"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5386688,
        "lng": 15.5139415
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3975995,
        46.4764986,
        15.5615077,
        46.5622274
      ]
    },
    {
      "id": 9235,
      "name": "Sempeter pri Gorici",
      "names": {
        "ja": "セッピターPRI GORICI"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.928059,
        "lng": 13.638766
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.62553,
        45.917301,
        13.656749,
        45.94412
      ]
    },
    {
      "id": 9236,
      "name": "Dingli",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8604202,
        "lng": 14.3816818
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.360156,
        35.8460074,
        14.3980812,
        35.8726895
      ]
    },
    {
      "id": 9237,
      "name": "Bensonville",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 6.44611,
        "lng": -10.6125
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -10.6325,
        6.42611,
        -10.5925,
        6.46611
      ]
    },
    {
      "id": 9238,
      "name": "Calheta de Sao Miguel",
      "names": {
        "pt": "Calheta de São Miguel"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.1842036,
        "lng": -23.5914998
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.6314998,
        15.1442036,
        -23.5514998,
        15.2242036
      ]
    },
    {
      "id": 9239,
      "name": "Tunceli",
      "names": {
        "zh": "通杰利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1060641,
        "lng": 39.5482693
      },
      "country": "Turkey",
      "importance": 1,
      "bbox": [
        39.3882693,
        38.9460641,
        39.7082693,
        39.2660641
      ]
    },
    {
      "id": 9240,
      "name": "Kirkop",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8419845,
        "lng": 14.4844481
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4770963,
        35.8360935,
        14.4920293,
        35.8511503
      ]
    },
    {
      "id": 9241,
      "name": "Gharghur",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9240307,
        "lng": 14.4531676
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.4473453,
        35.9124231,
        14.465713,
        35.9333191
      ]
    },
    {
      "id": 9242,
      "name": "Canillo",
      "names": {
        "zh": "卡尼略"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.566528,
        "lng": 1.599367
      },
      "country": "Andorra",
      "importance": 1,
      "bbox": [
        1.579367,
        42.546528,
        1.619367,
        42.586528
      ]
    },
    {
      "id": 9243,
      "name": "Ziri",
      "names": {
        "de": "Zlri"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0510703,
        "lng": 14.1106226
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0280369,
        46.0034237,
        14.1863761,
        46.0849943
      ]
    },
    {
      "id": 9244,
      "name": "Gornja Radgona",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6769963,
        "lng": 15.9913176
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.8856514,
        46.5854349,
        16.0334416,
        46.687001
      ]
    },
    {
      "id": 9245,
      "name": "Kaberamaido",
      "names": {
        "zh": "卡贝拉马伊多"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.7638442,
        "lng": 33.1572065
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.1172065,
        1.7238442,
        33.1972065,
        1.8038442
      ]
    },
    {
      "id": 9246,
      "name": "Svencionys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.131155,
        "lng": 26.157838
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        26.143925,
        55.109851,
        26.195154,
        55.152183
      ]
    },
    {
      "id": 9247,
      "name": "Wabag",
      "names": {
        "zh": "瓦巴格"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.491944,
        "lng": 143.721667
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        143.561667,
        -5.651944,
        143.881667,
        -5.331944
      ]
    },
    {
      "id": 9248,
      "name": "Xewkija",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.033365,
        "lng": 14.256669
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.248389,
        36.028755,
        14.270099,
        36.041586
      ]
    },
    {
      "id": 9249,
      "name": "Agdam",
      "names": {
        "zh": "阿格达姆"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.99096,
        "lng": 46.92736
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.90736,
        39.97096,
        46.94736,
        40.01096
      ]
    },
    {
      "id": 9250,
      "name": "Bugiri",
      "names": {
        "zh": "布吉里"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5651366,
        "lng": 33.7518189
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.7118189,
        0.5251366,
        33.7918189,
        0.6051366
      ]
    },
    {
      "id": 9251,
      "name": "Trebnje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9079394,
        "lng": 15.0071462
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.8604365,
        45.8380016,
        15.1078526,
        45.9786105
      ]
    },
    {
      "id": 9252,
      "name": "Decan",
      "names": {
        "pt": "Decano"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.54018,
        "lng": 20.28793
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.26793,
        42.52018,
        20.30793,
        42.56018
      ]
    },
    {
      "id": 9253,
      "name": "Kardla",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.0012422,
        "lng": 22.7411446
      },
      "country": "Estonia",
      "importance": 1,
      "bbox": [
        22.7192591,
        58.9796173,
        22.7643258,
        59.0103756
      ]
    },
    {
      "id": 9254,
      "name": "Piran",
      "names": {
        "zh": "皮兰",
        "it": "Pirano"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5284919,
        "lng": 13.5684485
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.3844445,
        45.4455848,
        13.71236,
        45.6074294
      ]
    },
    {
      "id": 9255,
      "name": "Kalvarija",
      "names": {
        "zh": "卡尔瓦里亚"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.4143869,
        "lng": 23.2273639
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.2008092,
        54.3977433,
        23.2499295,
        54.4301825
      ]
    },
    {
      "id": 9256,
      "name": "Janjanbureh",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.54102925,
        "lng": -14.77544996875641
      },
      "country": "The Gambia",
      "importance": 1,
      "bbox": [
        -14.8058796,
        13.5234916,
        -14.7111973,
        13.5575946
      ]
    },
    {
      "id": 9257,
      "name": "Cankuzo",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.2186,
        "lng": 30.5528
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        30.5328,
        -3.2386,
        30.5728,
        -3.1986
      ]
    },
    {
      "id": 9258,
      "name": "Kudahuvadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 2.6712087,
        "lng": 72.8938459
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        72.8538459,
        2.6312087,
        72.9338459,
        2.7112087
      ]
    },
    {
      "id": 9259,
      "name": "Moroto",
      "names": {
        "zh": "莫罗托"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.5286192,
        "lng": 34.658727
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.618727,
        2.4886192,
        34.698727,
        2.5686192
      ]
    },
    {
      "id": 9260,
      "name": "Massenya",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 11.4039,
        "lng": 16.1705
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        16.1505,
        11.3839,
        16.1905,
        11.4239
      ]
    },
    {
      "id": 9261,
      "name": "Imgarr",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9194409,
        "lng": 14.3665109
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3325167,
        35.8967644,
        14.4048029,
        35.9406793
      ]
    },
    {
      "id": 9262,
      "name": "Lazdijai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.23013,
        "lng": 23.515831
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.487772,
        54.214784,
        23.541257,
        54.247404
      ]
    },
    {
      "id": 9263,
      "name": "Berovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.706668,
        "lng": 22.8535559
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.8165559,
        41.6070036,
        23.01346,
        41.7368953
      ]
    },
    {
      "id": 9264,
      "name": "Ankaran",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5789629,
        "lng": 13.7364985
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.6159959,
        45.5572173,
        13.7706004,
        45.6315433
      ]
    },
    {
      "id": 9265,
      "name": "Krusevo",
      "names": {
        "th": "ครูเซโว"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3680991,
        "lng": 21.2481273
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.1837124,
        41.3232637,
        21.3080497,
        41.4121088
      ]
    },
    {
      "id": 9266,
      "name": "Imtarfa",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8906789,
        "lng": 14.397779916218129
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3973979,
        35.8904466,
        14.3982501,
        35.8909671
      ]
    },
    {
      "id": 9267,
      "name": "Louang Namtha",
      "names": {
        "de": "Louang Namha"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 20.9486,
        "lng": 101.40188
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        101.38188,
        20.9286,
        101.42188,
        20.9686
      ]
    },
    {
      "id": 9268,
      "name": "Xai",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 20.6930134,
        "lng": 101.9853678
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        101.8253678,
        20.5330134,
        102.1453678,
        20.8530134
      ]
    },
    {
      "id": 9269,
      "name": "Sveti Nikole",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8647339,
        "lng": 21.9420506
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.9102788,
        41.8398014,
        22.0017602,
        41.9069084
      ]
    },
    {
      "id": 9270,
      "name": "Tutin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9903848,
        "lng": 20.3366918
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.3180672,
        42.980453,
        20.3502069,
        43.0010919
      ]
    },
    {
      "id": 9271,
      "name": "Cantemir",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.2797499,
        "lng": 28.1988444
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.1588444,
        46.2397499,
        28.2388444,
        46.3197499
      ]
    },
    {
      "id": 9272,
      "name": "Xghajra",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.88556,
        "lng": 14.5475
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.5275,
        35.86556,
        14.5675,
        35.90556
      ]
    },
    {
      "id": 9273,
      "name": "Alibunar",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0800389,
        "lng": 20.966524
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.8770595,
        44.995785,
        21.0431405,
        45.1395195
      ]
    },
    {
      "id": 9274,
      "name": "Hoima",
      "names": {
        "zh": "霍伊马"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.56619265,
        "lng": 31.148096770216505
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.7994797,
        1.2716957,
        31.587658,
        1.8589575
      ]
    },
    {
      "id": 9275,
      "name": "Ainaro",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.989560149999999,
        "lng": 125.50933803419193
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.424057,
        -9.2233638,
        125.7104111,
        -8.7557602
      ]
    },
    {
      "id": 9276,
      "name": "Kulhudhuffushi",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 6.62207,
        "lng": 73.06998
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.04998,
        6.60207,
        73.08998,
        6.64207
      ]
    },
    {
      "id": 9277,
      "name": "Qrendi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.834801,
        "lng": 14.45917
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.454692,
        35.831478,
        14.463588,
        35.838952
      ]
    },
    {
      "id": 9278,
      "name": "Lenart v Slovenskih Goricah",
      "names": {
        "fr": "Lenart v Slovenski Goricah",
        "id": "Lenart v slovenskih goriicah",
        "ja": "Lenart vスロベンスkih Goricah"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5763667,
        "lng": 15.830971
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.7503133,
        46.4944762,
        15.8697027,
        46.6138456
      ]
    },
    {
      "id": 9279,
      "name": "Ulbroka",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9397548,
        "lng": 24.287296860998932
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.2684555,
        56.922068,
        24.3074521,
        56.9574816
      ]
    },
    {
      "id": 9280,
      "name": "Mezica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.520438,
        "lng": 14.853039
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.842975,
        46.511216,
        14.864413,
        46.527018
      ]
    },
    {
      "id": 9281,
      "name": "Demir Kapija",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4087136,
        "lng": 22.2418571
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.1899366,
        41.3848248,
        22.2584242,
        41.4253844
      ]
    },
    {
      "id": 9282,
      "name": "Saulkrasti",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.263391,
        "lng": 24.415245
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.3878551,
        57.2284834,
        24.4429062,
        57.2898813
      ]
    },
    {
      "id": 9283,
      "name": "Thinadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 0.530351,
        "lng": 72.9965866
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        72.9565866,
        0.490351,
        73.0365866,
        0.570351
      ]
    },
    {
      "id": 9284,
      "name": "Groningen",
      "names": {
        "zh": "格罗宁根",
        "ja": "グローニンゲン",
        "th": "โกร"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.7934347,
        "lng": -55.4763243
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -55.5277102,
        5.7046412,
        -55.4308774,
        5.850626
      ]
    },
    {
      "id": 9285,
      "name": "Metlika",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6501029,
        "lng": 15.3174483
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2055803,
        45.5713591,
        15.4064618,
        45.7269606
      ]
    },
    {
      "id": 9286,
      "name": "Qaqortoq",
      "names": {},
      "countryCode": "GL",
      "latLng": {
        "lat": 60.718202,
        "lng": -46.0385214
      },
      "country": "Greenland",
      "importance": 1,
      "bbox": [
        -46.1985214,
        60.558202,
        -45.8785214,
        60.878202
      ]
    },
    {
      "id": 9287,
      "name": "Ghajnsielem",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.027342,
        "lng": 14.28808
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.28088,
        36.022851,
        14.297933,
        36.03074
      ]
    },
    {
      "id": 9288,
      "name": "Balaka",
      "names": {
        "zh": "巴拉卡",
        "th": "บาลากา"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -14.9858678,
        "lng": 34.9602966
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.9202966,
        -15.0258678,
        35.0002966,
        -14.9458678
      ]
    },
    {
      "id": 9289,
      "name": "Banlung",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 13.73939,
        "lng": 106.98727
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        106.96727,
        13.71939,
        107.00727,
        13.75939
      ]
    },
    {
      "id": 9290,
      "name": "Rietavas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.7229408,
        "lng": 21.9270804
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.9061547,
        55.7174545,
        21.9554951,
        55.7374674
      ]
    },
    {
      "id": 9291,
      "name": "Sahbuz",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.40722,
        "lng": 45.57389
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.55389,
        39.38722,
        45.59389,
        39.42722
      ]
    },
    {
      "id": 9292,
      "name": "Borovnica",
      "names": {
        "zh": "博罗夫尼察"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9194745,
        "lng": 14.3637139
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.3335114,
        45.8571061,
        14.420565,
        45.9653599
      ]
    },
    {
      "id": 9293,
      "name": "Porto Ingles",
      "names": {
        "zh": "波尔图锭",
        "ja": "ポルトイン",
        "th": "ปอร์โตเก่ง",
        "pt": "Ingles do porto"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.1417748,
        "lng": -23.2110329
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.2110829,
        15.1417248,
        -23.2109829,
        15.1418248
      ]
    },
    {
      "id": 9294,
      "name": "Delcevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9668344,
        "lng": 22.7767722
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.7207136,
        41.9085492,
        22.8006274,
        42.0119009
      ]
    },
    {
      "id": 9295,
      "name": "Aasiaat",
      "names": {},
      "countryCode": "GL",
      "latLng": {
        "lat": 68.708721,
        "lng": -52.880648
      },
      "country": "Greenland",
      "importance": 1,
      "bbox": [
        -52.900648,
        68.688721,
        -52.860648,
        68.728721
      ]
    },
    {
      "id": 9296,
      "name": "Rumphi",
      "names": {
        "zh": "龙皮"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -11.0159927,
        "lng": 33.8587703
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.8187703,
        -11.0559927,
        33.8987703,
        -10.9759927
      ]
    },
    {
      "id": 9297,
      "name": "Safi",
      "names": {
        "zh": "萨菲",
        "ja": "サフィ",
        "th": "โครก"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.833064,
        "lng": 14.484958
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.48057,
        35.829746,
        14.488701,
        35.836319
      ]
    },
    {
      "id": 9298,
      "name": "Tallaght",
      "names": {
        "zh": "塔拉特"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.28906645,
        "lng": -6.356678263329492
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.3848992,
        53.2789345,
        -6.3324289,
        53.2991392
      ]
    },
    {
      "id": 9299,
      "name": "Lethem",
      "names": {},
      "countryCode": "GY",
      "latLng": {
        "lat": 3.3749987,
        "lng": -59.7958674
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -59.9558674,
        3.2149987,
        -59.6358674,
        3.5349987
      ]
    },
    {
      "id": 9300,
      "name": "Mabaruma",
      "names": {
        "zh": "马巴鲁马"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 8.2037266,
        "lng": -59.784924
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -59.9968457,
        8.1270385,
        -59.6863521,
        8.5501326
      ]
    },
    {
      "id": 9301,
      "name": "Mandalgovi",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 45.7630435,
        "lng": 106.2704145
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        106.2291332,
        45.745343,
        106.3075596,
        45.786381
      ]
    },
    {
      "id": 9302,
      "name": "Lendava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5639639,
        "lng": 16.4526468
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.3124723,
        46.4713753,
        16.5968135,
        46.6382449
      ]
    },
    {
      "id": 9303,
      "name": "Bogdanci",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.201607,
        "lng": 22.577652
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.5301695,
        41.1706752,
        22.670846,
        41.2587772
      ]
    },
    {
      "id": 9304,
      "name": "Vojnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2906413,
        "lng": 15.3088458
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2353213,
        46.2608686,
        15.3788535,
        46.3673529
      ]
    },
    {
      "id": 9305,
      "name": "Wicklow",
      "names": {
        "zh": "威克洛",
        "th": "วิกโคลว์"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.9802376,
        "lng": -6.0414184
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.0701827,
        52.9587485,
        -6.0152242,
        52.9902273
      ]
    },
    {
      "id": 9306,
      "name": "Thulusdhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 4.3748502,
        "lng": 73.6519073
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.6119073,
        4.3348502,
        73.6919073,
        4.4148502
      ]
    },
    {
      "id": 9307,
      "name": "Arima",
      "names": {
        "zh": "阿里马",
        "ja": "有馬"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6372057,
        "lng": -61.2829762
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.297543,
        10.6051547,
        -61.2626105,
        10.6552555
      ]
    },
    {
      "id": 9308,
      "name": "Fontana",
      "names": {
        "zh": "丰塔纳",
        "ja": "フォンタナ",
        "th": "ตานา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.038744,
        "lng": 14.2370014
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.2322169,
        36.032366,
        14.2399021,
        36.0423139
      ]
    },
    {
      "id": 9309,
      "name": "Ta Khmau",
      "names": {
        "th": "ตาคาโม"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.48333,
        "lng": 104.95
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        104.93,
        11.46333,
        104.97,
        11.50333
      ]
    },
    {
      "id": 9310,
      "name": "Sao Domingos",
      "names": {
        "es": "Sao Domingo",
        "zh": "圣多明斯",
        "th": "เซาโดมิงโก",
        "pt": "São Domingos"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.03053405,
        "lng": -23.530882369293895
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -23.6253428,
        14.9727793,
        -23.4283476,
        15.0881273
      ]
    },
    {
      "id": 9311,
      "name": "Radlje ob Dravi",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6149451,
        "lng": 15.2228247
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.1601496,
        46.5228299,
        15.3354802,
        46.6577783
      ]
    },
    {
      "id": 9312,
      "name": "Kerewan",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.493636,
        "lng": -16.089092
      },
      "country": "The Gambia",
      "importance": 1,
      "bbox": [
        -16.109092,
        13.473636,
        -16.069092,
        13.513636
      ]
    },
    {
      "id": 9313,
      "name": "Arandelovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3072663,
        "lng": 20.5570451
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.5170451,
        44.2672663,
        20.5970451,
        44.3472663
      ]
    },
    {
      "id": 9314,
      "name": "Rasdhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 4.262992,
        "lng": 72.9917455314299
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        72.9894482,
        4.2603224,
        72.9939349,
        4.2659716
      ]
    },
    {
      "id": 9315,
      "name": "Dhihdhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 6.88744,
        "lng": 73.11402
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9316,
      "name": "Barclayville",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 4.71307565,
        "lng": -8.201171432451691
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -8.3941931,
        4.5782805,
        -7.9940418,
        4.849048
      ]
    },
    {
      "id": 9317,
      "name": "Triesenberg",
      "names": {
        "zh": "特里森贝格"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.11815,
        "lng": 9.54197
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.52197,
        47.09815,
        9.56197,
        47.13815
      ]
    },
    {
      "id": 9318,
      "name": "Ar Rayyan",
      "names": {
        "es": "Rayyan"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 24.9304741,
        "lng": 50.9776887766882
      },
      "country": "Qatar",
      "importance": 1,
      "bbox": [
        50.7954892,
        24.4707534,
        51.540987,
        25.3901818
      ]
    },
    {
      "id": 9319,
      "name": "Negotino",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4832929,
        "lng": 22.0879051
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.0484018,
        41.4733772,
        22.1365882,
        41.5186998
      ]
    },
    {
      "id": 9320,
      "name": "Sannat",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0267175,
        "lng": 14.242927
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.242877,
        36.0266675,
        14.242977,
        36.0267675
      ]
    },
    {
      "id": 9321,
      "name": "Qala",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.036291,
        "lng": 14.3103502
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.295239,
        36.0256782,
        14.3364252,
        36.0501911
      ]
    },
    {
      "id": 9322,
      "name": "Bariadi",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -2.8,
        "lng": 33.98333
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        33.96333,
        -2.82,
        34.00333,
        -2.78
      ]
    },
    {
      "id": 9323,
      "name": "Cestos City",
      "names": {
        "es": "CESTOS CIUDAD",
        "fr": "CESTIR CESTOS",
        "zh": "卡斯托斯市",
        "it": "Città di Cesos",
        "id": "Kota cestos",
        "ja": "セストスシティ",
        "th": "เมือง Cestos",
        "pt": "Cidade de cestos"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 5.464998,
        "lng": -9.577997
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -9.59799744238,
        5.44499804554,
        -9.55799744238,
        5.48499804554
      ]
    },
    {
      "id": 9324,
      "name": "Tofol",
      "names": {},
      "countryCode": "FM",
      "latLng": {
        "lat": 5.326022,
        "lng": 163.0087193
      },
      "country": "Federated States of Micronesia",
      "importance": 1,
      "bbox": [
        162.9887193,
        5.306022,
        163.0287193,
        5.346022
      ]
    },
    {
      "id": 9325,
      "name": "Ruggell",
      "names": {},
      "countryCode": "LI",
      "latLng": {
        "lat": 47.249354,
        "lng": 9.534301
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.51034045436,
        47.2258093408,
        9.55790005475,
        47.2705752441
      ]
    },
    {
      "id": 9326,
      "name": "Garoowe",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 8.406143,
        "lng": 48.483195
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        48.323195,
        8.246143,
        48.643195,
        8.566143
      ]
    },
    {
      "id": 9327,
      "name": "Wakiso",
      "names": {
        "zh": "瓦基索"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.40444,
        "lng": 32.45944
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.43944,
        0.38444,
        32.47944,
        0.42444
      ]
    },
    {
      "id": 9328,
      "name": "Kapchorwa",
      "names": {
        "zh": "卡普乔鲁瓦"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.3967784,
        "lng": 34.4508063
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.4308063,
        1.3767784,
        34.4708063,
        1.4167784
      ]
    },
    {
      "id": 9329,
      "name": "Ivanjica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5816675,
        "lng": 20.2304548
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.2149346,
        43.5637161,
        20.256388,
        43.6029185
      ]
    },
    {
      "id": 9330,
      "name": "Vevcani",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.2402702,
        "lng": 20.592266
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.5155626,
        41.2247997,
        20.6484598,
        41.264269
      ]
    },
    {
      "id": 9331,
      "name": "Fuerte Olimpo",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -21.0385406,
        "lng": -57.8748123
      },
      "country": "Paraguay",
      "importance": 1,
      "bbox": [
        -59.887103,
        -21.2993258,
        -57.816504,
        -20.1303213
      ]
    },
    {
      "id": 9332,
      "name": "Beltinci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.606936,
        "lng": 16.2350217
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.1444752,
        46.5539083,
        16.302577,
        46.6617938
      ]
    },
    {
      "id": 9333,
      "name": "Kabarnet",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.49194,
        "lng": 35.74303
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.72303,
        0.47194,
        35.76303,
        0.51194
      ]
    },
    {
      "id": 9334,
      "name": "Nida",
      "names": {
        "zh": "奈达"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.3036064,
        "lng": 21.0038475
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        20.9537744,
        55.2775197,
        21.0190061,
        55.3266603
      ]
    },
    {
      "id": 9335,
      "name": "Phu Ly",
      "names": {
        "fr": "Phu",
        "th": "ภูสด",
        "pt": "Phu"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.5447207,
        "lng": 105.915517
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.8768375,
        20.4844736,
        105.99947,
        20.6030567
      ]
    },
    {
      "id": 9336,
      "name": "Birstonas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.605,
        "lng": 24.029262
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.029212,
        54.60495,
        24.029312,
        54.60505
      ]
    },
    {
      "id": 9337,
      "name": "Vianden",
      "names": {
        "zh": "菲安登"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.934015,
        "lng": 6.207806
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        6.148959,
        49.922839,
        6.227331,
        49.955183
      ]
    },
    {
      "id": 9338,
      "name": "Bundibugyo",
      "names": {
        "zh": "本迪布焦"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7074204,
        "lng": 30.0638456
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.0238456,
        0.6674204,
        30.1038456,
        0.7474204
      ]
    },
    {
      "id": 9339,
      "name": "Yumbe",
      "names": {
        "zh": "永贝"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.4690623,
        "lng": 31.2462343
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.2461843,
        3.4690123,
        31.2462843,
        3.4691123
      ]
    },
    {
      "id": 9340,
      "name": "Lacin",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.6401825,
        "lng": 46.5488378
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.5348368,
        39.6297843,
        46.5612277,
        39.6559439
      ]
    },
    {
      "id": 9341,
      "name": "Kercem",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0416854,
        "lng": 14.228056
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.1932677,
        36.0306185,
        14.2337633,
        36.0555133
      ]
    },
    {
      "id": 9342,
      "name": "Capellen",
      "names": {
        "zh": "卡佩伦"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.6453476,
        "lng": 5.9876235
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        5.9676235,
        49.6253476,
        6.0076235,
        49.6653476
      ]
    },
    {
      "id": 9343,
      "name": "Mukono",
      "names": {
        "zh": "穆科诺"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.360848,
        "lng": 32.7460874
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.7060874,
        0.320848,
        32.7860874,
        0.400848
      ]
    },
    {
      "id": 9344,
      "name": "Sihanoukville",
      "names": {
        "th": "สีหนุวิลล์"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.6436701,
        "lng": 103.5154199
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        103.5104199,
        10.6386701,
        103.5204199,
        10.6486701
      ]
    },
    {
      "id": 9345,
      "name": "Geita",
      "names": {
        "zh": "盖塔"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -2.8707573,
        "lng": 32.2340831
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        32.0191262,
        -3.0403933,
        32.5840298,
        -2.7349963
      ]
    },
    {
      "id": 9346,
      "name": "Naklo",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2727921,
        "lng": 14.3150815
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.2598677,
        46.2496802,
        14.3384191,
        46.3250522
      ]
    },
    {
      "id": 9347,
      "name": "Gradsko",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.5794465,
        "lng": 21.9448996
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.9398996,
        41.5744465,
        21.9498996,
        41.5844465
      ]
    },
    {
      "id": 9348,
      "name": "Cidade Velha",
      "names": {
        "id": "Velha Cidade",
        "ja": "ベルハを準備します"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": -0.7296146,
        "lng": -47.8486394
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -47.8586394,
        -0.7396146,
        -47.8386394,
        -0.7196146
      ]
    },
    {
      "id": 9349,
      "name": "Priboj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5828497,
        "lng": 19.5258167
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5045975,
        43.5551078,
        19.5449076,
        43.5960002
      ]
    },
    {
      "id": 9350,
      "name": "Clonmel",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 52.355,
        "lng": -7.703889
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.7509356,
        52.3440567,
        -7.6562234,
        52.3737368
      ]
    },
    {
      "id": 9351,
      "name": "Migori",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.0666,
        "lng": 34.4666
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.4266,
        -1.1066,
        34.5066,
        -1.0266
      ]
    },
    {
      "id": 9352,
      "name": "Phalombe",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -15.80635,
        "lng": 35.65067
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.63067,
        -15.82635,
        35.67067,
        -15.78635
      ]
    },
    {
      "id": 9353,
      "name": "Iten",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.6730702,
        "lng": 35.5102633
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.4988022,
        0.6640581,
        35.5166808,
        0.6773588
      ]
    },
    {
      "id": 9354,
      "name": "Onverwacht",
      "names": {},
      "countryCode": "SR",
      "latLng": {
        "lat": 5.5900807,
        "lng": -55.1936338
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -55.2336338,
        5.5500807,
        -55.1536338,
        5.6300807
      ]
    },
    {
      "id": 9355,
      "name": "Chiradzulu",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -15.7046189,
        "lng": 35.182269
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.162269,
        -15.7246189,
        35.202269,
        -15.6846189
      ]
    },
    {
      "id": 9356,
      "name": "Same",
      "names": {
        "es": "Mismo",
        "fr": "Même",
        "zh": "一样",
        "de": "Gleich",
        "it": "Stesso",
        "id": "Yang sama",
        "ja": "同じ",
        "th": "เหมือนกัน",
        "pt": "Mesmo"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -9.00362,
        "lng": 125.64777
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.60777,
        -9.04362,
        125.68777,
        -8.96362
      ]
    },
    {
      "id": 9357,
      "name": "Jagodina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9783052,
        "lng": 21.2605238
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.2604738,
        43.9782552,
        21.2605738,
        43.9783552
      ]
    },
    {
      "id": 9358,
      "name": "Kerugoya",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.49886,
        "lng": 37.28031
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.26031,
        -0.51886,
        37.30031,
        -0.47886
      ]
    },
    {
      "id": 9359,
      "name": "Veymandoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 2.188013,
        "lng": 73.0950817
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.0550817,
        2.148013,
        73.1350817,
        2.228013
      ]
    },
    {
      "id": 9360,
      "name": "Sofifi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 0.734776,
        "lng": 127.5760518
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        127.5481153,
        0.7150241,
        127.5835505,
        0.7543099
      ]
    },
    {
      "id": 9361,
      "name": "Sentjernej",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8432951,
        "lng": 15.3384729
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2539533,
        45.7596868,
        15.4048907,
        45.8901417
      ]
    },
    {
      "id": 9362,
      "name": "Vipava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8466286,
        "lng": 13.961222
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.8910039,
        45.7613301,
        14.0702788,
        45.8811856
      ]
    },
    {
      "id": 9363,
      "name": "Ambrolauri",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.5203411,
        "lng": 43.1491764
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        43.1491264,
        42.5202911,
        43.1492264,
        42.5203911
      ]
    },
    {
      "id": 9364,
      "name": "Radece",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.065524,
        "lng": 15.180518
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.15786,
        46.060049,
        15.195137,
        46.07319
      ]
    },
    {
      "id": 9365,
      "name": "Valandovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.31694,
        "lng": 22.56111
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.54111,
        41.29694,
        22.58111,
        41.33694
      ]
    },
    {
      "id": 9366,
      "name": "Buchanan",
      "names": {
        "zh": "布坎南",
        "ja": "ブキャナン",
        "th": "ทอมบูแคนาน"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 41.2620383,
        "lng": -73.9381943
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -73.962575,
        41.249889,
        -73.930016,
        41.27487
      ]
    },
    {
      "id": 9367,
      "name": "Ormoz",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4072568,
        "lng": 16.1489979
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.016651,
        46.3778133,
        16.2707107,
        46.5006597
      ]
    },
    {
      "id": 9368,
      "name": "Vuzenica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5978842,
        "lng": 15.1657776
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.100117,
        46.5134722,
        15.217916,
        46.6161411
      ]
    },
    {
      "id": 9369,
      "name": "Munxar",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0306654,
        "lng": 14.2347898
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.2121662,
        36.0202526,
        14.2391253,
        36.0375788
      ]
    },
    {
      "id": 9370,
      "name": "Kriva Palanka",
      "names": {
        "ja": "クリバパロンカ"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.20194,
        "lng": 22.33194
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.31194,
        42.18194,
        22.35194,
        42.22194
      ]
    },
    {
      "id": 9371,
      "name": "Viligili",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 0.75906,
        "lng": 73.43296
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.41296,
        0.73906,
        73.45296,
        0.77906
      ]
    },
    {
      "id": 9372,
      "name": "Kratovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.079261,
        "lng": 22.1802182
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.1523575,
        42.0519092,
        22.2253847,
        42.0937324
      ]
    },
    {
      "id": 9373,
      "name": "Kaabong",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.520391,
        "lng": 34.120026
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.1000255928,
        3.50039105114,
        34.1400255928,
        3.54039105114
      ]
    },
    {
      "id": 9374,
      "name": "Zabljak",
      "names": {
        "zh": "扎布利亚克"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 43.1550532,
        "lng": 19.1208669
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.1099779,
        43.1489472,
        19.1369404,
        43.1644877
      ]
    },
    {
      "id": 9375,
      "name": "Gamprin",
      "names": {
        "zh": "甘普林"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.22038,
        "lng": 9.50935
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.48935,
        47.20038,
        9.52935,
        47.24038
      ]
    },
    {
      "id": 9376,
      "name": "Krivogastani",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3364397,
        "lng": 21.3332133
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.3039471,
        41.3223672,
        21.3711557,
        41.360025
      ]
    },
    {
      "id": 9377,
      "name": "Zrnovci",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.85417,
        "lng": 22.44444
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.42444,
        41.83417,
        22.46444,
        41.87417
      ]
    },
    {
      "id": 9378,
      "name": "Nyamira",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.566595,
        "lng": 34.9357117
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.915056,
        -0.5855063,
        34.9428652,
        -0.5401899
      ]
    },
    {
      "id": 9379,
      "name": "Sopiste",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9548849,
        "lng": 21.4259789
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.4013243,
        41.9328804,
        21.463514,
        41.9661081
      ]
    },
    {
      "id": 9380,
      "name": "Trim",
      "names": {
        "es": "Recorte",
        "fr": "Garniture",
        "zh": "修剪",
        "id": "Memangkas",
        "ja": "トリム",
        "th": "ตัด",
        "pt": "Guarnição"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.5556419,
        "lng": -6.7925109
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -6.8083795,
        53.5452674,
        -6.7602461,
        53.5676073
      ]
    },
    {
      "id": 9381,
      "name": "Gharb",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0598182,
        "lng": 14.209081
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.1834654,
        36.0543721,
        14.2181964,
        36.0798011
      ]
    },
    {
      "id": 9382,
      "name": "Lyantonde",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.40306,
        "lng": 31.15722
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.13722,
        -0.42306,
        31.17722,
        -0.38306
      ]
    },
    {
      "id": 9383,
      "name": "Odranci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5854957,
        "lng": 16.2809075
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.2808575,
        46.5854457,
        16.2809575,
        46.5855457
      ]
    },
    {
      "id": 9384,
      "name": "La Palma",
      "names": {
        "zh": "拉帕尔马",
        "ja": "ラパルマ"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 5.3606208,
        "lng": -74.3905123
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -74.4925311,
        5.252151,
        -74.3356829,
        5.4439481
      ]
    },
    {
      "id": 9385,
      "name": "Hithadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": -0.6,
        "lng": 73.08333
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.06333,
        -0.62,
        73.10333,
        -0.58
      ]
    },
    {
      "id": 9386,
      "name": "Kirakira",
      "names": {
        "zh": "基拉基拉"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -10.45442,
        "lng": 161.92045
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        161.90045,
        -10.47442,
        161.94045,
        -10.43442
      ]
    },
    {
      "id": 9387,
      "name": "Sabac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.7571535,
        "lng": 19.6953972
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.6155021,
        44.7291063,
        19.7407018,
        44.8084081
      ]
    },
    {
      "id": 9388,
      "name": "Auki",
      "names": {
        "zh": "奥基"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -8.7706074,
        "lng": 160.6992655
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        160.6592655,
        -8.8106074,
        160.7392655,
        -8.7306074
      ]
    },
    {
      "id": 9389,
      "name": "Dar Naim",
      "names": {
        "zh": "达纳姆",
        "de": "Darnaim",
        "ja": "ダルナイム",
        "th": "ดาร์"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 18.1013451,
        "lng": -15.927625
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -15.927675,
        18.1012951,
        -15.927575,
        18.1013951
      ]
    },
    {
      "id": 9390,
      "name": "Machinga",
      "names": {
        "zh": "马钦加"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -14.902749700000001,
        "lng": 35.60258156267952
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        35.1619319,
        -15.25451,
        35.9185731,
        -14.5541683
      ]
    },
    {
      "id": 9391,
      "name": "Carrick on Shannon",
      "names": {
        "es": "Carrick en Shannon",
        "fr": "Carrick sur Shannon",
        "zh": "香农卡里克",
        "de": "Carrick auf Shannon",
        "it": "Carrick su Shannon",
        "id": "Carrick di Shannon",
        "ja": "シャノンのキャリック",
        "th": "carrick บนแชนนอน"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.9441461,
        "lng": -8.0952772
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -8.1184784,
        53.9318316,
        -8.063054,
        53.9607298
      ]
    },
    {
      "id": 9392,
      "name": "Varaklani",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.608005,
        "lng": 26.753698
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        26.737316,
        56.602828,
        26.773704,
        56.619119
      ]
    },
    {
      "id": 9393,
      "name": "Aioun",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 16.6597742,
        "lng": -9.6178808
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -9.7778808,
        16.4997742,
        -9.4578808,
        16.8197742
      ]
    },
    {
      "id": 9394,
      "name": "Vinh Yen",
      "names": {
        "zh": "vinh日元",
        "ja": "ヴィン円",
        "th": "vinh เยน"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.31,
        "lng": 105.59667
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        105.57667,
        21.29,
        105.61667,
        21.33
      ]
    },
    {
      "id": 9395,
      "name": "Pehcevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.75944,
        "lng": 22.89056
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.87056,
        41.73944,
        22.91056,
        41.77944
      ]
    },
    {
      "id": 9396,
      "name": "Totness",
      "names": {},
      "countryCode": "SR",
      "latLng": {
        "lat": 5.8771314,
        "lng": -56.3307762
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -56.3663838,
        5.373308,
        -56.3145086,
        5.8980513
      ]
    },
    {
      "id": 9397,
      "name": "Ropazi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9713771,
        "lng": 24.6307233
      },
      "country": "Latvia",
      "importance": 1,
      "bbox": [
        24.6107233,
        56.9513771,
        24.6507233,
        56.9913771
      ]
    },
    {
      "id": 9398,
      "name": "Mawlamyine",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.4908026,
        "lng": 97.6284825
      },
      "country": "Myanmar",
      "importance": 1,
      "bbox": [
        97.5900277,
        16.362956,
        97.705538,
        16.6107225
      ]
    },
    {
      "id": 9399,
      "name": "Xuddur",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 4.1237042,
        "lng": 43.8915563
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        43.7315563,
        3.9637042,
        44.0515563,
        4.2837042
      ]
    },
    {
      "id": 9400,
      "name": "Plasnica",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4672237,
        "lng": 21.1236869
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.1026208,
        41.4315444,
        21.1886013,
        41.4908464
      ]
    },
    {
      "id": 9401,
      "name": "Smederevo",
      "names": {
        "zh": "斯梅代雷沃"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6651048,
        "lng": 20.9271115
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.8590606,
        44.6119895,
        20.9936684,
        44.714675
      ]
    },
    {
      "id": 9402,
      "name": "Fomboni",
      "names": {},
      "countryCode": "KM",
      "latLng": {
        "lat": -12.284054,
        "lng": 43.742727
      },
      "country": "Comoros",
      "importance": 1,
      "bbox": [
        43.582727,
        -12.444054,
        43.902727,
        -12.124054
      ]
    },
    {
      "id": 9403,
      "name": "Bududa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0036619,
        "lng": 34.33440698740599
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.3343408,
        1.0035968,
        34.334473,
        1.0037292
      ]
    },
    {
      "id": 9404,
      "name": "Arilje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7505484,
        "lng": 20.1003115
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0832508,
        43.7418001,
        20.1120965,
        43.7667478
      ]
    },
    {
      "id": 9405,
      "name": "Tearce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0781929,
        "lng": 21.0538531
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.9279358,
        42.0413492,
        21.0938573,
        42.1461496
      ]
    },
    {
      "id": 9406,
      "name": "Vladicin Han",
      "names": {
        "zh": "汉语汉族"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.7059259,
        "lng": 22.0734664
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.0734164,
        42.7058759,
        22.0735164,
        42.7059759
      ]
    },
    {
      "id": 9407,
      "name": "Sotik Post",
      "names": {
        "es": "Publicación de Sotik",
        "pt": "Post"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.6916393,
        "lng": 35.1101768
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.1101268,
        -0.6916893,
        35.1102268,
        -0.6915893
      ]
    },
    {
      "id": 9408,
      "name": "Felidhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 3.4719776,
        "lng": 73.5473728
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.5073728,
        3.4319776,
        73.5873728,
        3.5119776
      ]
    },
    {
      "id": 9409,
      "name": "Bovec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.338245,
        "lng": 13.551455
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.531635,
        46.316439,
        13.571635,
        46.356439
      ]
    },
    {
      "id": 9410,
      "name": "Nova Varos",
      "names": {
        "ja": "ノヴァヴァルス"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.45903,
        "lng": 19.821791
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.7926405,
        43.4407065,
        19.8326405,
        43.4807065
      ]
    },
    {
      "id": 9411,
      "name": "Clervaux",
      "names": {
        "zh": "克莱沃"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 50.0547912,
        "lng": 6.0275511
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        5.956915,
        49.998863,
        6.1379604,
        50.1365735
      ]
    },
    {
      "id": 9412,
      "name": "Tullamore",
      "names": {
        "zh": "塔拉莫尔"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2705805,
        "lng": -7.5003389
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.5053389,
        53.2655805,
        -7.4953389,
        53.2755805
      ]
    },
    {
      "id": 9413,
      "name": "Manafwa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9608333,
        "lng": 34.2917674
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.2717674,
        0.9408333,
        34.3117674,
        0.9808333
      ]
    },
    {
      "id": 9414,
      "name": "Murang'a",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.71667,
        "lng": 37.15
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.13,
        -0.73667,
        37.17,
        -0.69667
      ]
    },
    {
      "id": 9415,
      "name": "Foammulah",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": -0.3,
        "lng": 73.4256
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9416,
      "name": "Bu'aale",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 1.083303,
        "lng": 42.583303
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        42.5633025257,
        1.0633030174,
        42.6033025257,
        1.1033030174
      ]
    },
    {
      "id": 9417,
      "name": "Mogila",
      "names": {
        "zh": "莫吉拉"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.3412265,
        "lng": 21.3928048
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.3709578,
        42.3328843,
        21.4104427,
        42.3714475
      ]
    },
    {
      "id": 9418,
      "name": "Kursumlija",
      "names": {
        "zh": "库尔舒姆利亚"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1380502,
        "lng": 21.2717791
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.2495031,
        43.1204521,
        21.3189993,
        43.155436
      ]
    },
    {
      "id": 9419,
      "name": "Aleksandrovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.4586105,
        "lng": 21.0465876
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.0366099,
        43.4489908,
        21.0678026,
        43.4720219
      ]
    },
    {
      "id": 9420,
      "name": "Xekong",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 15.3484551,
        "lng": 106.7220009
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        106.5620009,
        15.1884551,
        106.8820009,
        15.5084551
      ]
    },
    {
      "id": 9421,
      "name": "Castlebar",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.8568418,
        "lng": -9.297967
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -9.3298087,
        53.8384701,
        -9.2593219,
        53.8706762
      ]
    },
    {
      "id": 9422,
      "name": "Xocali",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.9104684,
        "lng": 46.7972401
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.7505699,
        39.898381,
        46.8230863,
        39.9208557
      ]
    },
    {
      "id": 9423,
      "name": "Nilandhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 3.0561219,
        "lng": 72.889895
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        72.849895,
        3.0161219,
        72.929895,
        3.0961219
      ]
    },
    {
      "id": 9424,
      "name": "Pakxan",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 18.38261,
        "lng": 103.65857
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        103.63857,
        18.36261,
        103.67857,
        18.40261
      ]
    },
    {
      "id": 9425,
      "name": "Taro",
      "names": {
        "zh": "芋头",
        "ja": "太郎",
        "th": "เผือก"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -32.8091861,
        "lng": 151.6698016
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        151.6648016,
        -32.8141861,
        151.6748016,
        -32.8041861
      ]
    },
    {
      "id": 9426,
      "name": "Schellenberg",
      "names": {},
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2312022,
        "lng": 9.5458021
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.5284564,
        47.2240972,
        9.5674105,
        47.25247
      ]
    },
    {
      "id": 9427,
      "name": "Kurunegala",
      "names": {},
      "countryCode": "LK",
      "latLng": {
        "lat": 7.4870464,
        "lng": 80.364908
      },
      "country": "Sri Lanka",
      "importance": 1,
      "bbox": [
        80.204908,
        7.3270464,
        80.524908,
        7.6470464
      ]
    },
    {
      "id": 9428,
      "name": "Vreed-en-Hoop",
      "names": {
        "es": "Vreed-en-aro",
        "zh": "vreed-连接箍",
        "ja": "vreed-専用フープ",
        "th": "vreed-ห้องห่วง"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.8091616,
        "lng": -58.1848349
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -58.3448349,
        6.6491616,
        -58.0248349,
        6.9691616
      ]
    },
    {
      "id": 9429,
      "name": "Santo Antonio",
      "names": {
        "ja": "サントアントニオ"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 1.6381528,
        "lng": 7.4197765
      },
      "country": "Sao Tome And Principe",
      "importance": 1,
      "bbox": [
        7.3797765,
        1.5981528,
        7.4597765,
        1.6781528
      ]
    },
    {
      "id": 9430,
      "name": "Gornji Milanovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0243159,
        "lng": 20.4600377
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.4308027,
        43.9957658,
        20.4971664,
        44.0499244
      ]
    },
    {
      "id": 9431,
      "name": "Svrljig",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.4142983,
        "lng": 22.1181103
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.1131103,
        43.4092983,
        22.1231103,
        43.4192983
      ]
    },
    {
      "id": 9432,
      "name": "Novo Selo",
      "names": {
        "ja": "ノボセロ"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.596544,
        "lng": 21.814596
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.794596,
        42.576544,
        21.834596,
        42.616544
      ]
    },
    {
      "id": 9433,
      "name": "Tam Ky",
      "names": {
        "zh": "谭凯",
        "ja": "タムキー",
        "pt": "Tambor"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 15.56667,
        "lng": 108.48333
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        108.46333,
        15.54667,
        108.50333,
        15.58667
      ]
    },
    {
      "id": 9434,
      "name": "Novaci",
      "names": {
        "zh": "诺瓦奇"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.0417702,
        "lng": 21.4578033
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.433951,
        41.0273941,
        21.4997721,
        41.0618477
      ]
    },
    {
      "id": 9435,
      "name": "Luwero",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.8409356,
        "lng": 32.4935273
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.4535273,
        0.8009356,
        32.5335273,
        0.8809356
      ]
    },
    {
      "id": 9436,
      "name": "Radovljica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.343662,
        "lng": 14.175559
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.164418,
        46.33617,
        14.184243,
        46.353858
      ]
    },
    {
      "id": 9437,
      "name": "Rosoman",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.51611,
        "lng": 21.94972
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.92972,
        41.49611,
        21.96972,
        41.53611
      ]
    },
    {
      "id": 9438,
      "name": "Lata",
      "names": {
        "zh": "拉塔"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -10.7241726,
        "lng": 165.7977641
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        165.7577641,
        -10.7641726,
        165.8377641,
        -10.6841726
      ]
    },
    {
      "id": 9439,
      "name": "Oranjestad",
      "names": {
        "zh": "奥拉涅斯塔德",
        "it": "Libero stato dell'orange",
        "ja": "オラニエスタッド"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 17.48268,
        "lng": -62.98324
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        -63.00324,
        17.46268,
        -62.96324,
        17.50268
      ]
    },
    {
      "id": 9440,
      "name": "Mullingar",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.525822,
        "lng": -7.341304
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.3882595,
        53.5035842,
        -7.2976708,
        53.547589
      ]
    },
    {
      "id": 9441,
      "name": "Mayuge",
      "names": {
        "zh": "马尤盖"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4,
        "lng": 33.8
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.78,
        0.38,
        33.82,
        0.42
      ]
    },
    {
      "id": 9442,
      "name": "`Amran",
      "names": {
        "zh": "`阿姆兰"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.6628336,
        "lng": 43.942995
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        43.782995,
        15.5028336,
        44.102995,
        15.8228336
      ]
    },
    {
      "id": 9443,
      "name": "Ain Temouchent",
      "names": {
        "es": "Ain Temauchent",
        "fr": "Ain Temoucher",
        "id": "Ain Temouchl",
        "ja": "AINテーマ？",
        "th": "temouchent ain",
        "pt": "TEMOUTHENT AIN"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.3048421,
        "lng": -1.1457771
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        -1.2152032,
        35.2102245,
        -1.0701311,
        35.3234362
      ]
    },
    {
      "id": 9444,
      "name": "Raska",
      "names": {
        "zh": "拉什卡"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2869592,
        "lng": 20.6141348
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.5962556,
        43.2719444,
        20.6403546,
        43.3043956
      ]
    },
    {
      "id": 9445,
      "name": "Longford",
      "names": {
        "zh": "朗福德",
        "th": "ลองฟอร์ด"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.7278,
        "lng": -7.7963816
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.8416688,
        53.6977179,
        -7.7523513,
        53.7621252
      ]
    },
    {
      "id": 9446,
      "name": "Pirot",
      "names": {
        "zh": "皮罗特"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1547342,
        "lng": 22.5865039
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.543469,
        43.1036529,
        22.610864,
        43.2048773
      ]
    },
    {
      "id": 9447,
      "name": "Serravalle",
      "names": {
        "zh": "塞拉瓦莱"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9699026,
        "lng": 12.4802946
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.4488121,
        43.956043,
        12.5159305,
        43.992042
      ]
    },
    {
      "id": 9448,
      "name": "Prokuplje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2343484,
        "lng": 21.5884422
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.4284422,
        43.0743484,
        21.7484422,
        43.3943484
      ]
    },
    {
      "id": 9449,
      "name": "Brus",
      "names": {
        "zh": "布鲁斯"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.3843467,
        "lng": 21.0313889
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.0174396,
        43.3718374,
        21.0479912,
        43.3908527
      ]
    },
    {
      "id": 9450,
      "name": "Surdulica",
      "names": {
        "zh": "苏尔杜利察"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.6919329,
        "lng": 22.1701473
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.1301473,
        42.6519329,
        22.2101473,
        42.7319329
      ]
    },
    {
      "id": 9451,
      "name": "Bosilovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.44056,
        "lng": 22.72778
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.70778,
        41.42056,
        22.74778,
        41.46056
      ]
    },
    {
      "id": 9452,
      "name": "Trbovlje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1558964,
        "lng": 15.0540204
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.9804946,
        46.0768851,
        15.1093702,
        46.1974405
      ]
    },
    {
      "id": 9453,
      "name": "Ivancna Gorica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9389896,
        "lng": 14.8050459
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.7049436,
        45.7954115,
        14.9249361,
        46.0130575
      ]
    },
    {
      "id": 9454,
      "name": "Nenagh",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 52.861944,
        "lng": -8.196667
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -8.227919,
        52.8480247,
        -8.1827034,
        52.8773506
      ]
    },
    {
      "id": 9455,
      "name": "Ub",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.45,
        "lng": 20.0667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9456,
      "name": "Lucani",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8602275,
        "lng": 20.1365353
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.1245412,
        43.8499124,
        20.1460278,
        43.8690541
      ]
    },
    {
      "id": 9457,
      "name": "Muli",
      "names": {
        "zh": "穆利"
      },
      "countryCode": "MV",
      "latLng": {
        "lat": 2.919296,
        "lng": 73.5809790410809
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.5775187,
        2.9117665,
        73.5865777,
        2.9268623
      ]
    },
    {
      "id": 9458,
      "name": "Kobarid",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2474675,
        "lng": 13.579201
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.579151,
        46.2474175,
        13.579251,
        46.2475175
      ]
    },
    {
      "id": 9459,
      "name": "Labuan",
      "names": {
        "zh": "纳闽",
        "ja": "ラブアン",
        "th": "ลาบวน"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.296109,
        "lng": 115.235421
      },
      "country": "Malaysia",
      "importance": 1,
      "bbox": [
        115.163404,
        5.240555,
        115.316028,
        5.433659
      ]
    },
    {
      "id": 9460,
      "name": "Andrijevica",
      "names": {
        "zh": "安德里耶维察"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.73389,
        "lng": 19.79194
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.77194,
        42.71389,
        19.81194,
        42.75389
      ]
    },
    {
      "id": 9461,
      "name": "Halba",
      "names": {
        "zh": "哈勒巴"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 34.54278,
        "lng": 36.07972
      },
      "country": "Lebanon",
      "importance": 1,
      "bbox": [
        36.05972,
        34.52278,
        36.09972,
        34.56278
      ]
    },
    {
      "id": 9462,
      "name": "Pozega",
      "names": {
        "zh": "波热加"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.845981,
        "lng": 20.0356852
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0079422,
        43.822067,
        20.0702989,
        43.861722
      ]
    },
    {
      "id": 9463,
      "name": "Neno",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -15.386571100000001,
        "lng": 34.6520992004797
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.6381327,
        -15.4040043,
        34.6658745,
        -15.3691512
      ]
    },
    {
      "id": 9464,
      "name": "Isale",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.3440762,
        "lng": 29.483924
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.443924,
        -3.3840762,
        29.523924,
        -3.3040762
      ]
    },
    {
      "id": 9465,
      "name": "Smarje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8276746,
        "lng": 15.34181
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.32181,
        45.8076746,
        15.36181,
        45.8476746
      ]
    },
    {
      "id": 9466,
      "name": "Siaya",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.06116,
        "lng": 34.28823
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        34.26823,
        0.04116,
        34.30823,
        0.08116
      ]
    },
    {
      "id": 9467,
      "name": "Dien Bien Phu",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 21.3874893,
        "lng": 103.0185
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        102.9929836,
        21.3664602,
        103.1868401,
        21.6148871
      ]
    },
    {
      "id": 9468,
      "name": "Mahonda",
      "names": {
        "zh": "马洪达"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.9883421,
        "lng": 39.2499504
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        39.2099504,
        -6.0283421,
        39.2899504,
        -5.9483421
      ]
    },
    {
      "id": 9469,
      "name": "Store",
      "names": {
        "es": "Tienda",
        "fr": "Boutique",
        "zh": "店",
        "de": "Geschäft",
        "id": "Toko",
        "ja": "店",
        "th": "ร้านค้า",
        "pt": "Loja"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.221034,
        "lng": 15.3134867
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2789975,
        46.163108,
        15.3605461,
        46.2332034
      ]
    },
    {
      "id": 9470,
      "name": "Konce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4956527,
        "lng": 22.3854735
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.3669195,
        41.4529235,
        22.4480939,
        41.547338
      ]
    },
    {
      "id": 9471,
      "name": "Kostanjevica na Krki",
      "names": {
        "it": "Kostanjevica na kri"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8456674,
        "lng": 15.4202287
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3664286,
        45.7911916,
        15.4789019,
        45.8918585
      ]
    },
    {
      "id": 9472,
      "name": "Smederevska Palanka",
      "names": {
        "fr": "Smedevska Palanka"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3658207,
        "lng": 20.9579781
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.9321261,
        44.3428518,
        21.0028962,
        44.4076893
      ]
    },
    {
      "id": 9473,
      "name": "Cerklje na Gorenjskem",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.253003,
        "lng": 14.487784
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.482617,
        46.246267,
        14.496531,
        46.269472
      ]
    },
    {
      "id": 9474,
      "name": "Kasungu",
      "names": {
        "zh": "卡松古"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.0395452,
        "lng": 33.4784085
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.4384085,
        -13.0795452,
        33.5184085,
        -12.9995452
      ]
    },
    {
      "id": 9475,
      "name": "Rogaska Slatina",
      "names": {
        "ja": "ログカスカスラティナ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.234249750000004,
        "lng": 15.63770494381491
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.6375548,
        46.234156,
        15.6378549,
        46.2343629
      ]
    },
    {
      "id": 9476,
      "name": "Chikwawa",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -16.0271313,
        "lng": 34.7912153
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        34.7512153,
        -16.0671313,
        34.8312153,
        -15.9871313
      ]
    },
    {
      "id": 9477,
      "name": "San Lawrenz",
      "names": {
        "es": "San Crudo",
        "zh": "圣劳伦茨",
        "ja": "サンローレンツ",
        "th": "ซาน Lawrenz"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0553005,
        "lng": 14.2039503
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.1859549,
        36.033059,
        14.2095844,
        36.062875
      ]
    },
    {
      "id": 9478,
      "name": "Sostanj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.37719,
        "lng": 15.049817
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.036325,
        46.366951,
        15.077868,
        46.386883
      ]
    },
    {
      "id": 9479,
      "name": "Namutumba",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.83611,
        "lng": 33.68611
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.66611,
        0.81611,
        33.70611,
        0.85611
      ]
    },
    {
      "id": 9480,
      "name": "Fonadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 1.8333203,
        "lng": 73.5025558
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.4625558,
        1.7933203,
        73.5425558,
        1.8733203
      ]
    },
    {
      "id": 9481,
      "name": "Port Laoise",
      "names": {
        "fr": "Port de port",
        "zh": "洛伊斯港",
        "de": "Anschlusshaken",
        "ja": "ポートラオアス",
        "th": "พอร์ตลาว",
        "pt": "Porto laoise"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.0327792,
        "lng": -7.296331
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.296381,
        53.0327292,
        -7.296281,
        53.0328292
      ]
    },
    {
      "id": 9482,
      "name": "Rumonge",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.9736,
        "lng": 29.4386
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        29.4186,
        -3.9936,
        29.4586,
        -3.9536
      ]
    },
    {
      "id": 9483,
      "name": "Babusnica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.067962,
        "lng": 22.411508
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.371508,
        43.027962,
        22.451508,
        43.107962
      ]
    },
    {
      "id": 9484,
      "name": "Vlasotince",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9652346,
        "lng": 22.1281077
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.0881077,
        42.9252346,
        22.1681077,
        43.0052346
      ]
    },
    {
      "id": 9485,
      "name": "Ljubovija",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1880511,
        "lng": 19.3761034
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.3566403,
        44.1760392,
        19.3974892,
        44.1976556
      ]
    },
    {
      "id": 9486,
      "name": "Manadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.7666661,
        "lng": 73.4123826
      },
      "country": "Maldives",
      "importance": 1,
      "bbox": [
        73.3723826,
        5.7266661,
        73.4523826,
        5.8066661
      ]
    },
    {
      "id": 9487,
      "name": "Lajkovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.367859,
        "lng": 20.1642257
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.1592257,
        44.362859,
        20.1692257,
        44.372859
      ]
    },
    {
      "id": 9488,
      "name": "Rostusa",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.61,
        "lng": 20.6
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.58,
        41.59,
        20.62,
        41.63
      ]
    },
    {
      "id": 9489,
      "name": "Scarborough",
      "names": {
        "zh": "斯卡伯勒",
        "ja": "スカーバラ",
        "th": "สการ์เบอโร"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 43.7169869,
        "lng": -79.2546806
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -79.2596806,
        43.7119869,
        -79.2496806,
        43.7219869
      ]
    },
    {
      "id": 9490,
      "name": "An Cabhan",
      "names": {
        "es": "Un cabañal",
        "fr": "Un cabhan",
        "zh": "卡哈山",
        "de": "Ein Cabhan",
        "it": "Un cabhan",
        "id": "Seorang cabang",
        "ja": "カバン",
        "th": "รถกะหล่ำปลี",
        "pt": "Um cabrão"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.9911042,
        "lng": -7.3603119
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.3740009,
        53.982185,
        -7.3508266,
        53.9996786
      ]
    },
    {
      "id": 9491,
      "name": "Kosjeric",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0004081,
        "lng": 19.9189554
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.9139554,
        43.9954081,
        19.9239554,
        44.0054081
      ]
    },
    {
      "id": 9492,
      "name": "Mionica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.252921,
        "lng": 20.084082
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.044082,
        44.212921,
        20.124082,
        44.292921
      ]
    },
    {
      "id": 9493,
      "name": "Al Khawr",
      "names": {
        "ja": "アルカウル",
        "th": "อัล khawr"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.6837333,
        "lng": 51.5031212
      },
      "country": "Qatar",
      "importance": 1,
      "bbox": [
        50.9554629,
        25.5583149,
        51.660742,
        25.9682057
      ]
    },
    {
      "id": 9494,
      "name": "Kaliro",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.08336765,
        "lng": 33.482217107339594
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.3342928,
        0.8708697,
        33.6358167,
        1.2946934
      ]
    },
    {
      "id": 9495,
      "name": "Bela Palanka",
      "names": {
        "es": "Palanka",
        "ja": "ベラパルランカ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2179542,
        "lng": 22.3112654
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.2712654,
        43.1779542,
        22.3512654,
        43.2579542
      ]
    },
    {
      "id": 9496,
      "name": "Asaba",
      "names": {
        "zh": "阿萨巴"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.1858825,
        "lng": 6.7297071
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.5697071,
        6.0258825,
        6.8897071,
        6.3458825
      ]
    },
    {
      "id": 9497,
      "name": "Eenhana",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.4757612,
        "lng": 16.3368249
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        16.3033388,
        -17.5010335,
        16.3464315,
        -17.4556042
      ]
    },
    {
      "id": 9498,
      "name": "Amudat",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.953842,
        "lng": 34.9457755
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.9057755,
        1.913842,
        34.9857755,
        1.993842
      ]
    },
    {
      "id": 9499,
      "name": "Ain Defla",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.2657178,
        "lng": 1.9701347
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        1.8713893,
        36.2102412,
        2.0955024,
        36.2975638
      ]
    },
    {
      "id": 9500,
      "name": "Bukwo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.25917,
        "lng": 34.75389
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.73389,
        1.23917,
        34.77389,
        1.27917
      ]
    },
    {
      "id": 9501,
      "name": "Diego Martin",
      "names": {
        "zh": "迭戈马丁",
        "de": "Wütende",
        "th": "ดิเอโกมาร์ติน"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.72081,
        "lng": -61.56616
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.58616,
        10.70081,
        -61.54616,
        10.74081
      ]
    },
    {
      "id": 9502,
      "name": "Prebold",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.236866,
        "lng": 15.090544
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.080662,
        46.23152,
        15.097636,
        46.240934
      ]
    },
    {
      "id": 9503,
      "name": "Selibaby",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 15.15846,
        "lng": -12.1843
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -12.2043,
        15.13846,
        -12.1643,
        15.17846
      ]
    },
    {
      "id": 9504,
      "name": "Point Fortin",
      "names": {
        "es": "Fortina de punto",
        "fr": "Point fort",
        "zh": "指向福特印度",
        "de": "Punktieren",
        "it": "Punto fortin",
        "id": "Fortin",
        "ja": "ポイントフォーチン",
        "th": "จุด fortin",
        "pt": "Ponto fortin"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.1759283,
        "lng": -61.6827286
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.7076352,
        10.1410015,
        -61.6327949,
        10.2065537
      ]
    },
    {
      "id": 9505,
      "name": "Madaba",
      "names": {
        "zh": "马达巴"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.7165941,
        "lng": 35.7943856
      },
      "country": "Jordan",
      "importance": 1,
      "bbox": [
        35.7678499,
        31.6951454,
        35.8293799,
        31.7527866
      ]
    },
    {
      "id": 9506,
      "name": "Oblesevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.88333,
        "lng": 22.33389
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.31389,
        41.86333,
        22.35389,
        41.90333
      ]
    },
    {
      "id": 9507,
      "name": "Tulagi",
      "names": {},
      "countryCode": "SB",
      "latLng": {
        "lat": -9.1015203,
        "lng": 160.1501398
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        160.1101398,
        -9.1415203,
        160.1901398,
        -9.0615203
      ]
    },
    {
      "id": 9508,
      "name": "Imdina",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8859549,
        "lng": 14.4025684
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.3972796,
        35.8825482,
        14.4156786,
        35.8917965
      ]
    },
    {
      "id": 9509,
      "name": "Kapenguria",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 1.2583041,
        "lng": 35.0966551
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.0966051,
        1.2582541,
        35.0967051,
        1.2583541
      ]
    },
    {
      "id": 9510,
      "name": "Sirvintos",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.049942,
        "lng": 24.949134
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.920317,
        55.027157,
        24.989881,
        55.05988
      ]
    },
    {
      "id": 9511,
      "name": "Kibiito",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.47722,
        "lng": 30.1925
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.1725,
        0.45722,
        30.2125,
        0.49722
      ]
    },
    {
      "id": 9512,
      "name": "Rankovce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.16964,
        "lng": 22.11617
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.09617,
        42.14964,
        22.13617,
        42.18964
      ]
    },
    {
      "id": 9513,
      "name": "Petrovec",
      "names": {
        "zh": "彼得罗韦茨"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9386289,
        "lng": 21.6160528
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.5890415,
        41.914697,
        21.6308436,
        41.9461812
      ]
    },
    {
      "id": 9514,
      "name": "Yenagoa",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 4.9334651,
        "lng": 6.2747786
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        6.1473551,
        4.8460321,
        6.5666361,
        5.2845378
      ]
    },
    {
      "id": 9515,
      "name": "Varazdin",
      "names": {
        "it": "Varaždin"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 46.305979,
        "lng": 16.335594
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        16.307932,
        46.286018,
        16.388641,
        46.323078
      ]
    },
    {
      "id": 9516,
      "name": "Umm Salal `Ali",
      "names": {
        "zh": "嗯salal`阿里",
        "de": "Ähm Salal `ali",
        "th": "อืมนัล `อาลี"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.46972,
        "lng": 51.3975
      },
      "country": "Qatar",
      "importance": 1,
      "bbox": [
        51.3775,
        25.44972,
        51.4175,
        25.48972
      ]
    },
    {
      "id": 9517,
      "name": "Lakatoro",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -16.1068174,
        "lng": 167.418485
      },
      "country": "Vanuatu",
      "importance": 1,
      "bbox": [
        167.398485,
        -16.1268174,
        167.438485,
        -16.0868174
      ]
    },
    {
      "id": 9518,
      "name": "Kibuku",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0461879,
        "lng": 33.7998306
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.7598306,
        1.0061879,
        33.8398306,
        1.0861879
      ]
    },
    {
      "id": 9519,
      "name": "Sremska Mitrovica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9701654,
        "lng": 19.6124132
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5680206,
        44.9271576,
        19.6924235,
        45.0271047
      ]
    },
    {
      "id": 9520,
      "name": "Hvalba",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.6003139,
        "lng": -6.9585472
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -7.0028909,
        61.5605709,
        -6.6977363,
        61.6377033
      ]
    },
    {
      "id": 9521,
      "name": "Miklavz na Dravskem Polju",
      "names": {
        "pt": "Miklavz na drravskem polju"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5073791,
        "lng": 15.6973717
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.6806602,
        46.4599026,
        15.7331351,
        46.5171169
      ]
    },
    {
      "id": 9522,
      "name": "Qubadli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.3436425,
        "lng": 46.5809277
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.5745209,
        39.3339552,
        46.5922702,
        39.3537087
      ]
    },
    {
      "id": 9523,
      "name": "Aranguez",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.642822,
        "lng": -61.444497
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.449644,
        10.632942,
        -61.443158,
        10.649553
      ]
    },
    {
      "id": 9524,
      "name": "Maracha",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.2478417,
        "lng": 30.927914822762393
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.8018586,
        3.1225733,
        31.0739556,
        3.3719487
      ]
    },
    {
      "id": 9525,
      "name": "Mozirje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3396381,
        "lng": 14.9634205
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.8880781,
        46.2991453,
        15.0084654,
        46.4034831
      ]
    },
    {
      "id": 9526,
      "name": "Pozarevac",
      "names": {
        "zh": "波扎雷瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6199926,
        "lng": 21.1854
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.0863025,
        44.5744942,
        21.2392339,
        44.6601968
      ]
    },
    {
      "id": 9527,
      "name": "Krupanj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.365632,
        "lng": 19.3628418
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.3329331,
        44.3580828,
        19.3899744,
        44.3751461
      ]
    },
    {
      "id": 9528,
      "name": "Lai Chau",
      "names": {
        "zh": "赖洲",
        "th": "สายพันธุ์"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.3996612,
        "lng": 103.4516882
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        103.353957,
        22.3417273,
        103.525003,
        22.4650407
      ]
    },
    {
      "id": 9529,
      "name": "Karbinci",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.81667,
        "lng": 22.2375
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.2175,
        41.79667,
        22.2575,
        41.83667
      ]
    },
    {
      "id": 9530,
      "name": "Vasilevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4742136,
        "lng": 22.6439393
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.6340246,
        41.465968,
        22.6615012,
        41.5137502
      ]
    },
    {
      "id": 9531,
      "name": "Polzela",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2826896,
        "lng": 15.065902
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.060902,
        46.2776896,
        15.070902,
        46.2876896
      ]
    },
    {
      "id": 9532,
      "name": "Bangar",
      "names": {
        "zh": "邦阿"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 16.2295389,
        "lng": 120.5068829
      },
      "country": "Brunei",
      "importance": 1,
      "bbox": [
        120.4868829,
        16.2095389,
        120.5268829,
        16.2495389
      ]
    },
    {
      "id": 9533,
      "name": "Knjazevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5675155,
        "lng": 22.2556547
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.2156547,
        43.5275155,
        22.2956547,
        43.6075155
      ]
    },
    {
      "id": 9534,
      "name": "Dong Xoai",
      "names": {
        "zh": "董泽",
        "th": "ดง xoai"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.5376338,
        "lng": 106.8998884
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        106.755153,
        11.4611345,
        106.9330416,
        11.5771801
      ]
    },
    {
      "id": 9535,
      "name": "Picos",
      "names": {
        "zh": "皮库斯"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": -6.3624283,
        "lng": -37.657139334822205
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -37.7233409,
        -6.4396119,
        -37.5776517,
        -6.2853086
      ]
    },
    {
      "id": 9536,
      "name": "Zajecar",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9030925,
        "lng": 22.2785536
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.1845784,
        43.8639576,
        22.3108535,
        43.973924
      ]
    },
    {
      "id": 9537,
      "name": "Paracin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8636301,
        "lng": 21.4099615
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.3699615,
        43.8236301,
        21.4499615,
        43.9036301
      ]
    },
    {
      "id": 9538,
      "name": "The Bottom",
      "names": {
        "es": "La parte inferior",
        "fr": "Le fond",
        "zh": "底部",
        "de": "Der Boden",
        "it": "Il fondo",
        "id": "Bagian bawah",
        "ja": "ボトム",
        "th": "ด้านล่าง",
        "pt": "O fundo"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 17.6263487,
        "lng": -63.2490017
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        -63.2690017,
        17.6063487,
        -63.2290017,
        17.6463487
      ]
    },
    {
      "id": 9539,
      "name": "Lebane",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9159455,
        "lng": 21.7370376
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7369876,
        42.9158955,
        21.7370876,
        42.9159955
      ]
    },
    {
      "id": 9540,
      "name": "Dowa",
      "names": {
        "zh": "多瓦"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.6576733,
        "lng": 33.9302091
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.8902091,
        -13.6976733,
        33.9702091,
        -13.6176733
      ]
    },
    {
      "id": 9541,
      "name": "Cibitoke",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -2.8919231,
        "lng": 29.1244074
      },
      "country": "Burundi",
      "importance": 1,
      "bbox": [
        28.9644074,
        -3.0519231,
        29.2844074,
        -2.7319231
      ]
    },
    {
      "id": 9542,
      "name": "Buikwe",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.3375,
        "lng": 33.01056
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.99056,
        0.3175,
        33.03056,
        0.3575
      ]
    },
    {
      "id": 9543,
      "name": "Kagadi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9420293,
        "lng": 30.8095689
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.8095189,
        0.9419793,
        30.8096189,
        0.9420793
      ]
    },
    {
      "id": 9544,
      "name": "Borgo Maggiore",
      "names": {
        "ja": "ボルゴマッジョーレ"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9411865,
        "lng": 12.447422
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.4325246,
        43.9185541,
        12.480976,
        43.9681911
      ]
    },
    {
      "id": 9545,
      "name": "Da Lat",
      "names": {
        "zh": "大拉特",
        "th": "ดาลาด",
        "pt": "Do lat"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.9402416,
        "lng": 108.4375758
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        108.3152058,
        11.8079789,
        108.5946125,
        12.0085517
      ]
    },
    {
      "id": 9546,
      "name": "Binyin",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.41667,
        "lng": 34.53333
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.51333,
        1.39667,
        34.55333,
        1.43667
      ]
    },
    {
      "id": 9547,
      "name": "Ghasri",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0655404,
        "lng": 14.22173
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.209208,
        36.0502855,
        14.2375096,
        36.0817867
      ]
    },
    {
      "id": 9548,
      "name": "Raca",
      "names": {
        "zh": "拉查"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2253438,
        "lng": 20.9801511
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.9401511,
        44.1853438,
        21.0201511,
        44.2653438
      ]
    },
    {
      "id": 9549,
      "name": "Mila",
      "names": {
        "zh": "米拉",
        "de": "Mlla",
        "ja": "ミラ",
        "th": "มิลา"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.451941,
        "lng": 6.262331
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        6.162196,
        36.334977,
        6.357197,
        36.501991
      ]
    },
    {
      "id": 9550,
      "name": "Bulambuli",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1599242,
        "lng": 34.396403
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.376403,
        1.1399242,
        34.416403,
        1.1799242
      ]
    },
    {
      "id": 9551,
      "name": "Suong",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 11.91178,
        "lng": 105.65821
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        105.63821,
        11.89178,
        105.67821,
        11.93178
      ]
    },
    {
      "id": 9552,
      "name": "Aileu",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.72806,
        "lng": 125.56639
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.54639,
        -8.74806,
        125.58639,
        -8.70806
      ]
    },
    {
      "id": 9553,
      "name": "Makedonski Brod",
      "names": {
        "ja": "マケドンスキーBROD"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.51361,
        "lng": 21.21528
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.19528,
        41.49361,
        21.23528,
        41.53361
      ]
    },
    {
      "id": 9554,
      "name": "Aleksinac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5405235,
        "lng": 21.70884
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.6573741,
        43.5217637,
        21.7689152,
        43.5842948
      ]
    },
    {
      "id": 9555,
      "name": "Vwawa",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.11667,
        "lng": 32.93333
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        32.91333,
        -9.13667,
        32.95333,
        -9.09667
      ]
    },
    {
      "id": 9556,
      "name": "Planken",
      "names": {},
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1858848,
        "lng": 9.5452211
      },
      "country": "Liechtenstein",
      "importance": 1,
      "bbox": [
        9.524455,
        47.1560628,
        9.5914268,
        47.1925027
      ]
    },
    {
      "id": 9557,
      "name": "Al Buraymi",
      "names": {
        "fr": "Al Burayme",
        "ja": "アルバイミ",
        "th": "อัลบูรณ"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 24.25919,
        "lng": 55.783763
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        55.623763,
        24.09919,
        55.943763,
        24.41919
      ]
    },
    {
      "id": 9558,
      "name": "Medvode",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1379153,
        "lng": 14.4121788
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.4071788,
        46.1329153,
        14.4171788,
        46.1429153
      ]
    },
    {
      "id": 9559,
      "name": "Staro Nagoricane",
      "names": {
        "de": "Staro Nagorican"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.1990188,
        "lng": 21.8294161
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.8016386,
        42.1822005,
        21.8566561,
        42.2215701
      ]
    },
    {
      "id": 9560,
      "name": "Osecina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.372776,
        "lng": 19.6007285
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5781512,
        44.3594092,
        19.6200326,
        44.3848897
      ]
    },
    {
      "id": 9561,
      "name": "Demir Hisar",
      "names": {
        "es": "Demir Herar",
        "zh": "Demir Misar",
        "id": "Hisar",
        "ja": "デミールハール",
        "pt": "Demir Har"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.2198967,
        "lng": 21.2020872
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.1698394,
        41.2092396,
        21.2395661,
        41.2358988
      ]
    },
    {
      "id": 9562,
      "name": "Weno",
      "names": {},
      "countryCode": "FM",
      "latLng": {
        "lat": 7.45154,
        "lng": 151.8468
      },
      "country": "Federated States of Micronesia",
      "importance": 1,
      "bbox": [
        151.8268,
        7.43154,
        151.8668,
        7.47154
      ]
    },
    {
      "id": 9563,
      "name": "Komenda",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2054037,
        "lng": 14.5394407
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.5005587,
        46.1719742,
        14.5826001,
        46.2424842
      ]
    },
    {
      "id": 9564,
      "name": "Jincheng",
      "names": {
        "zh": "金城",
        "ja": "金城"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.4328,
        "lng": 118.3205667
      },
      "country": "Taiwan",
      "importance": 1,
      "bbox": [
        118.2781774,
        24.38555,
        118.3429435,
        24.4449365
      ]
    },
    {
      "id": 9565,
      "name": "Brezovica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9480324,
        "lng": 14.418033635052497
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.3636869,
        45.8592481,
        14.4907231,
        46.0367644
      ]
    },
    {
      "id": 9566,
      "name": "Smartno",
      "names": {
        "it": "Šmartno"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1250909,
        "lng": 14.4760651
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.4560651,
        46.1050909,
        14.4960651,
        46.1450909
      ]
    },
    {
      "id": 9567,
      "name": "Moravce",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.135869,
        "lng": 14.745386
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.742109,
        46.126508,
        14.753169,
        46.139434
      ]
    },
    {
      "id": 9568,
      "name": "Despotovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0954702,
        "lng": 21.4366052
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.3966052,
        44.0554702,
        21.4766052,
        44.1354702
      ]
    },
    {
      "id": 9569,
      "name": "Cajetina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7504753,
        "lng": 19.7165358
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.6919698,
        43.732123,
        19.7368061,
        43.7604902
      ]
    },
    {
      "id": 9570,
      "name": "Namayingo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.23983,
        "lng": 33.88491
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.86491,
        0.21983,
        33.90491,
        0.25983
      ]
    },
    {
      "id": 9571,
      "name": "Lozovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.78389,
        "lng": 21.90556
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.88556,
        41.76389,
        21.92556,
        41.80389
      ]
    },
    {
      "id": 9572,
      "name": "Apac",
      "names": {
        "fr": "Asie-Pacifique",
        "zh": "亚太地区",
        "ja": "アジア太平洋地域",
        "th": "ในภูมิภาคเอเชียแปซิฟิก"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.9847397,
        "lng": 32.5342155
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.4942155,
        1.9447397,
        32.5742155,
        2.0247397
      ]
    },
    {
      "id": 9573,
      "name": "Skofljica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9843722,
        "lng": 14.5727108
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.5677108,
        45.9793722,
        14.5777108,
        45.9893722
      ]
    },
    {
      "id": 9574,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.050096,
        "lng": -121.99059
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        -122.3220529,
        36.8488209,
        -121.5823066,
        37.2876789
      ]
    },
    {
      "id": 9575,
      "name": "Centar Zupa",
      "names": {
        "es": "Valles zupa",
        "fr": "Zupa Centatr",
        "ja": "中心ズパ",
        "th": "เซ็นาร์ซู่ซู่",
        "pt": "Zupa cento"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 45.7056668,
        "lng": 16.3920083
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        16.3720083,
        45.6856668,
        16.4120083,
        45.7256668
      ]
    },
    {
      "id": 9576,
      "name": "Domagnano",
      "names": {
        "th": "โดมัญญาโน"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9474906,
        "lng": 12.4684442
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.4683942,
        43.9474406,
        12.4684942,
        43.9475406
      ]
    },
    {
      "id": 9577,
      "name": "Ljig",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2235216,
        "lng": 20.2340871187044
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.2147516,
        44.2093537,
        20.2583275,
        44.2376669
      ]
    },
    {
      "id": 9578,
      "name": "Indija",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0478559,
        "lng": 20.0807338
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0407338,
        45.0078559,
        20.1207338,
        45.0878559
      ]
    },
    {
      "id": 9579,
      "name": "Poljcane",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.31255,
        "lng": 15.577735
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.568614,
        46.298431,
        15.596239,
        46.32155
      ]
    },
    {
      "id": 9580,
      "name": "Jegunovce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.07361,
        "lng": 21.12361
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.10361,
        42.05361,
        21.14361,
        42.09361
      ]
    },
    {
      "id": 9581,
      "name": "Menges",
      "names": {
        "zh": "门杰斯"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.16694,
        "lng": 14.575
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.555,
        46.14694,
        14.595,
        46.18694
      ]
    },
    {
      "id": 9582,
      "name": "Butebo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.19417,
        "lng": 33.92222
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.90222,
        1.17417,
        33.94222,
        1.21417
      ]
    },
    {
      "id": 9583,
      "name": "Koani",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.1376418,
        "lng": 39.2863264
      },
      "country": "Tanzania",
      "importance": 1,
      "bbox": [
        39.2663264,
        -6.1576418,
        39.3063264,
        -6.1176418
      ]
    },
    {
      "id": 9584,
      "name": "Tolmin",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1829212,
        "lng": 13.7333382
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.6188013,
        46.0050295,
        14.0086358,
        46.2828119
      ]
    },
    {
      "id": 9585,
      "name": "Varvarin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7150966,
        "lng": 21.3725491
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.3525491,
        43.6950966,
        21.3925491,
        43.7350966
      ]
    },
    {
      "id": 9586,
      "name": "Skopun",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.90285,
        "lng": -6.88234
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.90234,
        61.88285,
        -6.86234,
        61.92285
      ]
    },
    {
      "id": 9587,
      "name": "Buala",
      "names": {},
      "countryCode": "SB",
      "latLng": {
        "lat": -8.145546,
        "lng": 159.5930366
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        159.5530366,
        -8.185546,
        159.6330366,
        -8.105546
      ]
    },
    {
      "id": 9588,
      "name": "Ogujin",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 38.80278,
        "lng": 125.5925
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 9589,
      "name": "Samga-ri",
      "names": {
        "it": "Samga ri-"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 37.5092134,
        "lng": 127.6777338
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        127.6577338,
        37.4892134,
        127.6977338,
        37.5292134
      ]
    },
    {
      "id": 9590,
      "name": "Gornji Petrovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8036921,
        "lng": 16.218644
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.1248362,
        46.7384422,
        16.2714048,
        46.8600911
      ]
    },
    {
      "id": 9591,
      "name": "Preddvor",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3021436,
        "lng": 14.4216606
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.363565,
        46.2815985,
        14.5548948,
        46.3666589
      ]
    },
    {
      "id": 9592,
      "name": "Ash Shihaniyah",
      "names": {
        "id": "Abu shihaniyah",
        "ja": "灰Shihaniyah",
        "pt": "Cinza shihaniyah"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.31763,
        "lng": 55.44057
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9593,
      "name": "Kiryandongo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.877935,
        "lng": 32.0623888
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.0223888,
        1.837935,
        32.1023888,
        1.917935
      ]
    },
    {
      "id": 9594,
      "name": "Nazarje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.320168,
        "lng": 14.946308
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.937484,
        46.313498,
        14.955145,
        46.325236
      ]
    },
    {
      "id": 9595,
      "name": "Kon Tum",
      "names": {
        "fr": "Top kon",
        "de": "Konzentrieren",
        "id": "Kon",
        "ja": "コーントン"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 14.3615256,
        "lng": 108.0039102
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.849996,
        14.2282157,
        108.0884065,
        14.45365
      ]
    },
    {
      "id": 9596,
      "name": "Zrece",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.373368,
        "lng": 15.387364
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.378042,
        46.366691,
        15.399434,
        46.383767
      ]
    },
    {
      "id": 9597,
      "name": "Acquaviva",
      "names": {
        "zh": "阿夸维瓦"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9464246,
        "lng": 12.4191909
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.402433,
        43.9367062,
        12.4410654,
        43.9578868
      ]
    },
    {
      "id": 9598,
      "name": "Busesa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.62631,
        "lng": 33.60027
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.58027,
        0.60631,
        33.62027,
        0.64631
      ]
    },
    {
      "id": 9599,
      "name": "Butaleja",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9167711,
        "lng": 33.9601383
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.9201383,
        0.8767711,
        34.0001383,
        0.9567711
      ]
    },
    {
      "id": 9600,
      "name": "Dragomer",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0187941,
        "lng": 14.3837236
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.3637236,
        45.9987941,
        14.4037236,
        46.0387941
      ]
    },
    {
      "id": 9601,
      "name": "Dhuusamarreeb",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 5.535,
        "lng": 46.38611
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        46.36611,
        5.515,
        46.40611,
        5.555
      ]
    },
    {
      "id": 9602,
      "name": "Miren",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.894339,
        "lng": 13.6089235
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.5889235,
        45.874339,
        13.6289235,
        45.914339
      ]
    },
    {
      "id": 9603,
      "name": "Brvenica",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9661724,
        "lng": 20.9804941
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.9716773,
        41.9525009,
        21.0109502,
        41.981489
      ]
    },
    {
      "id": 9604,
      "name": "Fada",
      "names": {
        "zh": "法达"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 17.1930577,
        "lng": 21.5813259
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        21.4213259,
        17.0330577,
        21.7413259,
        17.3530577
      ]
    },
    {
      "id": 9605,
      "name": "Belcista",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.30278,
        "lng": 20.83028
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.81028,
        41.28278,
        20.85028,
        41.32278
      ]
    },
    {
      "id": 9606,
      "name": "Radenci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6415016,
        "lng": 16.0400064
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0399564,
        46.6414516,
        16.0400564,
        46.6415516
      ]
    },
    {
      "id": 9607,
      "name": "Spodnji Duplek",
      "names": {
        "pt": "Duplek de spodnji"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5033387,
        "lng": 15.7479471
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.7279471,
        46.4833387,
        15.7679471,
        46.5233387
      ]
    },
    {
      "id": 9608,
      "name": "Crna Trava",
      "names": {
        "th": "เกรียงไกรทราฟา",
        "pt": "CRNA TRAV"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.8095195,
        "lng": 22.2990322
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.2790322,
        42.7895195,
        22.3190322,
        42.8295195
      ]
    },
    {
      "id": 9609,
      "name": "Mali Zvornik",
      "names": {
        "zh": "马里Zvornik",
        "ja": "マリズボルン",
        "th": "มาลี zvornik"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3736328,
        "lng": 19.1069253
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.1025923,
        44.3666548,
        19.1468247,
        44.4127525
      ]
    },
    {
      "id": 9610,
      "name": "Kanal",
      "names": {
        "zh": "运河",
        "th": "ช่อง"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.090605,
        "lng": 13.645892
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.625892,
        46.070605,
        13.665892,
        46.110605
      ]
    },
    {
      "id": 9611,
      "name": "Hvannasund",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.29727,
        "lng": -6.5196605
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.5370112,
        62.2769656,
        -6.4050651,
        62.3233864
      ]
    },
    {
      "id": 9612,
      "name": "Afega",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.79726,
        "lng": -171.85308
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -171.87308,
        -13.81726,
        -171.83308,
        -13.77726
      ]
    },
    {
      "id": 9613,
      "name": "Tisina",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6544782,
        "lng": 16.0854902
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0357254,
        46.6151691,
        16.1280286,
        46.7009641
      ]
    },
    {
      "id": 9614,
      "name": "Muta",
      "names": {
        "zh": "变异",
        "ja": "牟田"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6093522,
        "lng": 15.1655099
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0824241,
        46.5915295,
        15.1898566,
        46.6598387
      ]
    },
    {
      "id": 9615,
      "name": "Bukomansimbi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.15778,
        "lng": 31.60417
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.58417,
        -0.17778,
        31.62417,
        -0.13778
      ]
    },
    {
      "id": 9616,
      "name": "Dogbo",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.801846,
        "lng": 1.7815205
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        1.6081209,
        6.7148458,
        1.9383739,
        6.8517452
      ]
    },
    {
      "id": 9617,
      "name": "Selnica ob Dravi",
      "names": {
        "de": "Selnica Obs Dravi",
        "id": "Selnica obravi"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5508936,
        "lng": 15.4925414
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.4020216,
        46.5327723,
        15.5531175,
        46.655544
      ]
    },
    {
      "id": 9618,
      "name": "Fiorentino",
      "names": {
        "zh": "奥伦蒂诺",
        "ja": "フィオレンティーノ",
        "th": "เรนติโน"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9103157,
        "lng": 12.4575556
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.431309,
        43.893632,
        12.4763914,
        43.9211686
      ]
    },
    {
      "id": 9619,
      "name": "Luuka Town",
      "names": {
        "es": "Ciudad de luka",
        "fr": "Ville de Luuka",
        "zh": "鲁瓦卡镇",
        "it": "Luca Città",
        "id": "Kota Luuka",
        "ja": "ルカの町",
        "th": "เมือง luuka",
        "pt": "Cidade de luuka"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.70076,
        "lng": 33.3002
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.2802,
        0.68076,
        33.3202,
        0.72076
      ]
    },
    {
      "id": 9620,
      "name": "Spodnje Hoce",
      "names": {
        "es": "Hoche Spodnje",
        "id": "SPOTNJE HOCE",
        "th": "สปิปนเจ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.499612,
        "lng": 15.649497
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.649447,
        46.499562,
        15.649547,
        46.499662
      ]
    },
    {
      "id": 9621,
      "name": "Sentrupert",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9771289,
        "lng": 15.0914051
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0218102,
        45.9471856,
        15.1661732,
        46.0223614
      ]
    },
    {
      "id": 9622,
      "name": "Dolneni",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.42528,
        "lng": 21.45472
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.43472,
        41.40528,
        21.47472,
        41.44528
      ]
    },
    {
      "id": 9623,
      "name": "Abakaliki",
      "names": {
        "zh": "阿巴卡利基"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.3208897,
        "lng": 8.1133202
      },
      "country": "Nigeria",
      "importance": 1,
      "bbox": [
        8.0374708,
        6.1702991,
        8.4180002,
        6.3524184
      ]
    },
    {
      "id": 9624,
      "name": "Ljutomer",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5187046,
        "lng": 16.1967404
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0116125,
        46.4662456,
        16.2682629,
        46.5780699
      ]
    },
    {
      "id": 9625,
      "name": "Namsan",
      "names": {
        "zh": "南山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 37.5524374,
        "lng": 126.988433
      },
      "country": "North Korea",
      "importance": 1,
      "bbox": [
        126.988383,
        37.5523874,
        126.988483,
        37.5524874
      ]
    },
    {
      "id": 9626,
      "name": "Blace",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2955155,
        "lng": 21.2865967
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.2465967,
        43.2555155,
        21.3265967,
        43.3355155
      ]
    },
    {
      "id": 9627,
      "name": "Stari Trg",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.957749,
        "lng": 14.7564169
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.7364169,
        45.937749,
        14.7764169,
        45.977749
      ]
    },
    {
      "id": 9628,
      "name": "Ruma",
      "names": {
        "zh": "鲁马"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0078322,
        "lng": 19.8187834
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.7787834,
        44.9678322,
        19.8587834,
        45.0478322
      ]
    },
    {
      "id": 9629,
      "name": "Doljevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1980961,
        "lng": 21.8332818
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.8132818,
        43.1780961,
        21.8532818,
        43.2180961
      ]
    },
    {
      "id": 9630,
      "name": "Pombas",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": -4.1826076,
        "lng": -38.9444008
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -38.9644008,
        -4.2026076,
        -38.9244008,
        -4.1626076
      ]
    },
    {
      "id": 9631,
      "name": "Az Za`ayin",
      "names": {
        "pt": "Az Za`yin"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 52.088476,
        "lng": 5.088216
      },
      "country": "Qatar",
      "importance": 1,
      "bbox": [
        5.088057,
        52.087627,
        5.088375,
        52.089325
      ]
    },
    {
      "id": 9632,
      "name": "Batocina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.15361,
        "lng": 21.08167
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.06167,
        44.13361,
        21.10167,
        44.17361
      ]
    },
    {
      "id": 9633,
      "name": "Mwatate",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -3.5028625,
        "lng": 38.3795406
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        38.3395406,
        -3.5428625,
        38.4195406,
        -3.4628625
      ]
    },
    {
      "id": 9634,
      "name": "Colonia",
      "names": {
        "zh": "科洛尼亚",
        "ja": "コロニア",
        "th": "โคโลเนีย"
      },
      "countryCode": "FM",
      "latLng": {
        "lat": 46.5972293,
        "lng": 23.9722314
      },
      "country": "Federated States of Micronesia",
      "importance": 1,
      "bbox": [
        23.9522314,
        46.5772293,
        23.9922314,
        46.6172293
      ]
    },
    {
      "id": 9635,
      "name": "Miragoane",
      "names": {},
      "countryCode": "HT",
      "latLng": {
        "lat": 18.4448425,
        "lng": -73.0886392
      },
      "country": "Haiti",
      "importance": 1,
      "bbox": [
        -73.2486392,
        18.2848425,
        -72.9286392,
        18.6048425
      ]
    },
    {
      "id": 9636,
      "name": "Ferizaj",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.37056,
        "lng": 21.15528
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.13528,
        42.35056,
        21.17528,
        42.39056
      ]
    },
    {
      "id": 9637,
      "name": "Race",
      "names": {
        "es": "Raza",
        "fr": "Course",
        "zh": "竞赛",
        "de": "Rennen",
        "it": "Gara",
        "id": "Ras",
        "ja": "レース",
        "th": "การแข่งขัน",
        "pt": "Raça"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4533193,
        "lng": 15.679145
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.659145,
        46.4333193,
        15.699145,
        46.4733193
      ]
    },
    {
      "id": 9638,
      "name": "Sencur",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2431499,
        "lng": 14.4191043
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.385905,
        46.188265,
        14.4692995,
        46.2906825
      ]
    },
    {
      "id": 9639,
      "name": "Oplotnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3866934,
        "lng": 15.4486315
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.396701,
        46.3529213,
        15.496389,
        46.4249751
      ]
    },
    {
      "id": 9640,
      "name": "Debe",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.2107386,
        "lng": -61.4515249
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.4915249,
        10.1707386,
        -61.4115249,
        10.2507386
      ]
    },
    {
      "id": 9641,
      "name": "Wote",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.78079,
        "lng": 37.62882
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        37.60882,
        -1.80079,
        37.64882,
        -1.76079
      ]
    },
    {
      "id": 9642,
      "name": "Serere",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.5004066,
        "lng": 33.5469939
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.5069939,
        1.4604066,
        33.5869939,
        1.5404066
      ]
    },
    {
      "id": 9643,
      "name": "Zombo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.5135255,
        "lng": 30.9089608
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.8689608,
        2.4735255,
        30.9489608,
        2.5535255
      ]
    },
    {
      "id": 9644,
      "name": "Nsiika",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3859811,
        "lng": 30.4659826
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.4459826,
        -0.4059811,
        30.4859826,
        -0.3659811
      ]
    },
    {
      "id": 9645,
      "name": "Akjoujt",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 19.746999,
        "lng": -14.390999
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -14.4109994818,
        19.7269990599,
        -14.3709994818,
        19.7669990599
      ]
    },
    {
      "id": 9646,
      "name": "Smartno",
      "names": {
        "it": "Šmartno"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4930859,
        "lng": 15.1066259
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0866259,
        46.4730859,
        15.1266259,
        46.5130859
      ]
    },
    {
      "id": 9647,
      "name": "Negotin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.22639,
        "lng": 22.53083
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.51083,
        44.20639,
        22.55083,
        44.24639
      ]
    },
    {
      "id": 9648,
      "name": "Lifford",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 54.8326995,
        "lng": -7.4834739
      },
      "country": "Ireland",
      "importance": 1,
      "bbox": [
        -7.5018756,
        54.8209545,
        -7.4748197,
        54.8426059
      ]
    },
    {
      "id": 9649,
      "name": "Rubanda",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -1.2121583,
        "lng": 29.8626265
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        29.8426265,
        -1.2321583,
        29.8826265,
        -1.1921583
      ]
    },
    {
      "id": 9650,
      "name": "Ad Dali`",
      "names": {
        "zh": "广告dali`",
        "ja": "広告Dali`",
        "th": "โฆษณา Dali`"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.7017374,
        "lng": 44.7314344
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        44.6914344,
        13.6617374,
        44.7714344,
        13.7417374
      ]
    },
    {
      "id": 9651,
      "name": "Kajiado",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.85238,
        "lng": 36.77683
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.75683,
        -1.87238,
        36.79683,
        -1.83238
      ]
    },
    {
      "id": 9652,
      "name": "Ntara",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.005264,
        "lng": 30.3638047
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.3438047,
        -0.014736,
        30.3838047,
        0.025264
      ]
    },
    {
      "id": 9653,
      "name": "Sakete",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.7377567,
        "lng": 2.6551566
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        2.4951566,
        6.5777567,
        2.8151566,
        6.8977567
      ]
    },
    {
      "id": 9654,
      "name": "Mirna",
      "names": {
        "zh": "的miRNA",
        "ja": "のmiRNA"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9531788,
        "lng": 15.0641436
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.9962037,
        45.913001,
        15.1179338,
        45.9795437
      ]
    },
    {
      "id": 9655,
      "name": "Prizren",
      "names": {
        "zh": "普里兹伦"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.2130151,
        "lng": 20.7363339
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.5763339,
        42.0530151,
        20.8963339,
        42.3730151
      ]
    },
    {
      "id": 9656,
      "name": "Isangel",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -19.5410863,
        "lng": 169.2810885
      },
      "country": "Vanuatu",
      "importance": 1,
      "bbox": [
        169.2410885,
        -19.5810863,
        169.3210885,
        -19.5010863
      ]
    },
    {
      "id": 9657,
      "name": "Bistrica ob Sotli",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0571033,
        "lng": 15.6618232
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.5976501,
        46.0303346,
        15.7217004,
        46.0881818
      ]
    },
    {
      "id": 9658,
      "name": "Montegiardino",
      "names": {},
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9093574,
        "lng": 12.4847155
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.4679717,
        43.8954036,
        12.4955687,
        43.9209571
      ]
    },
    {
      "id": 9659,
      "name": "Dapaong",
      "names": {},
      "countryCode": "TG",
      "latLng": {
        "lat": 10.8595454,
        "lng": 0.2043122
      },
      "country": "Togo",
      "importance": 1,
      "bbox": [
        0.0443122,
        10.6995454,
        0.3643122,
        11.0195454
      ]
    },
    {
      "id": 9660,
      "name": "Toftir",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0916048,
        "lng": -6.7378886
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.7441597,
        62.0854169,
        -6.7111416,
        62.110441
      ]
    },
    {
      "id": 9661,
      "name": "Kalungu",
      "names": {
        "zh": "卡隆古"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.09131305,
        "lng": 31.810646451084416
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.6456895,
        -0.2600649,
        32.0002049,
        0.0791704
      ]
    },
    {
      "id": 9662,
      "name": "Rukungiri",
      "names": {
        "zh": "鲁昆吉里"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.7911133,
        "lng": 29.9248663
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        29.8848663,
        -0.8311133,
        29.9648663,
        -0.7511133
      ]
    },
    {
      "id": 9663,
      "name": "Nelspruit",
      "names": {
        "zh": "内尔斯普雷特",
        "th": "สปรุต"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.469351,
        "lng": 30.974546
      },
      "country": "South Africa",
      "importance": 1,
      "bbox": [
        30.940037,
        -25.497635,
        30.997215,
        -25.453793
      ]
    },
    {
      "id": 9664,
      "name": "Vitanje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3822927,
        "lng": 15.2957926
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.227701,
        46.3616364,
        15.3564157,
        46.4493681
      ]
    },
    {
      "id": 9665,
      "name": "Bushenyi",
      "names": {
        "zh": "布谢尼"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.5389407,
        "lng": 30.1894467
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.1893967,
        -0.5389907,
        30.1894967,
        -0.5388907
      ]
    },
    {
      "id": 9666,
      "name": "Samraong",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 14.18175,
        "lng": 103.51761
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        103.49761,
        14.16175,
        103.53761,
        14.20175
      ]
    },
    {
      "id": 9667,
      "name": "Koprivnica",
      "names": {
        "zh": "科普里夫尼察"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 46.1623512,
        "lng": 16.8307893
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        16.7430781,
        46.0852284,
        16.8969083,
        46.1978939
      ]
    },
    {
      "id": 9668,
      "name": "Gombe",
      "names": {
        "zh": "贡贝"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -4.31204305,
        "lng": 15.288254926425992
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        15.265186,
        -4.3277956,
        15.3445113,
        -4.2962669
      ]
    },
    {
      "id": 9669,
      "name": "Kanungu",
      "names": {
        "zh": "卡农古"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8969671,
        "lng": 29.7755034
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        29.7355034,
        -0.9369671,
        29.8155034,
        -0.8569671
      ]
    },
    {
      "id": 9670,
      "name": "Bupoto",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.90611,
        "lng": 34.35778
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.33778,
        0.88611,
        34.37778,
        0.92611
      ]
    },
    {
      "id": 9671,
      "name": "Cucer-Sandevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0994869,
        "lng": 21.3868611
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.3141067,
        42.0772981,
        21.3919809,
        42.1214613
      ]
    },
    {
      "id": 9672,
      "name": "Veliko Gradiste",
      "names": {
        "fr": "Veliko Gradiate",
        "id": "Gradiste Veliko",
        "ja": "卒業生の卒業生"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.7629166,
        "lng": 21.5161946
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.4560636,
        44.7360663,
        21.5419281,
        44.7733901
      ]
    },
    {
      "id": 9673,
      "name": "Porkeri",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.4817399,
        "lng": -6.7454998
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.8451267,
        61.475821,
        -6.702287,
        61.5069602
      ]
    },
    {
      "id": 9674,
      "name": "Rogatec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2273681,
        "lng": 15.6995714
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.6731527,
        46.2071041,
        15.8015796,
        46.2680273
      ]
    },
    {
      "id": 9675,
      "name": "Horjul",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0223241,
        "lng": 14.2988997
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.2345106,
        45.991763,
        14.3426032,
        46.0476786
      ]
    },
    {
      "id": 9676,
      "name": "Santa Lucija",
      "names": {
        "zh": "圣卢卡",
        "ja": "サンタルシャ",
        "th": "ซานตาลูจิ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0432761,
        "lng": 14.217684
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.197684,
        36.0232761,
        14.237684,
        36.0632761
      ]
    },
    {
      "id": 9677,
      "name": "Dimitrovgrad",
      "names": {
        "zh": "季米特洛夫格勒"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.0157433,
        "lng": 22.7781993
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.7420553,
        43.0002333,
        22.8167212,
        43.0433823
      ]
    },
    {
      "id": 9678,
      "name": "Al Jabin",
      "names": {
        "ja": "アルハビン",
        "th": "อัลจิบิน"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.704007,
        "lng": 43.599043
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        43.559043,
        14.664007,
        43.639043,
        14.744007
      ]
    },
    {
      "id": 9679,
      "name": "Pivka",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6825064,
        "lng": 14.1953518
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0664515,
        45.6208105,
        14.4365555,
        45.7566895
      ]
    },
    {
      "id": 9680,
      "name": "Rubirizi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.2768867,
        "lng": 30.1088372
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.0688372,
        -0.3168867,
        30.1488372,
        -0.2368867
      ]
    },
    {
      "id": 9681,
      "name": "Velika Plana",
      "names": {
        "id": "Plana velika",
        "ja": "ベリカプラナ",
        "th": "เวลิกา"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3324574,
        "lng": 21.075786
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.9730813,
        44.3004455,
        21.1328915,
        44.3552384
      ]
    },
    {
      "id": 9682,
      "name": "Petrovac na Mlavi",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.378261,
        "lng": 21.4170749
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.3770749,
        44.338261,
        21.4570749,
        44.418261
      ]
    },
    {
      "id": 9683,
      "name": "Svilajnac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2333085,
        "lng": 21.1947272
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.1547272,
        44.1933085,
        21.2347272,
        44.2733085
      ]
    },
    {
      "id": 9684,
      "name": "Boljevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8316871,
        "lng": 21.9522166
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.9521666,
        43.8316371,
        21.9522666,
        43.8317371
      ]
    },
    {
      "id": 9685,
      "name": "Kyenjojo",
      "names": {
        "zh": "基恩乔乔"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6113332,
        "lng": 30.6433854
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.6033854,
        0.5713332,
        30.6833854,
        0.6513332
      ]
    },
    {
      "id": 9686,
      "name": "Zelenikovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8860408,
        "lng": 21.5869671
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.5703249,
        41.8699124,
        21.6083424,
        41.8984759
      ]
    },
    {
      "id": 9687,
      "name": "Kyegegwa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4840586,
        "lng": 31.0534551
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.0534051,
        0.4840086,
        31.0535051,
        0.4841086
      ]
    },
    {
      "id": 9688,
      "name": "Kaedi",
      "names": {
        "zh": "卡埃迪"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.1523684,
        "lng": -13.5039868
      },
      "country": "Mauritania",
      "importance": 1,
      "bbox": [
        -13.6639868,
        15.9923684,
        -13.3439868,
        16.3123684
      ]
    },
    {
      "id": 9689,
      "name": "Braslovce",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2875826,
        "lng": 15.0376935
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0376345,
        46.2875603,
        15.0376935,
        46.2875826
      ]
    },
    {
      "id": 9690,
      "name": "Kole",
      "names": {
        "zh": "科莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.29881575,
        "lng": 32.73595222337399
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.5760359,
        2.0782723,
        32.9248089,
        2.5194546
      ]
    },
    {
      "id": 9691,
      "name": "Ibanda",
      "names": {
        "zh": "伊班达"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.1328418,
        "lng": 30.4956961
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.4556961,
        -0.1728418,
        30.5356961,
        -0.0928418
      ]
    },
    {
      "id": 9692,
      "name": "Narok",
      "names": {
        "zh": "纳罗克"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.0886931,
        "lng": 35.8714916
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        35.8314916,
        -1.1286931,
        35.9114916,
        -1.0486931
      ]
    },
    {
      "id": 9693,
      "name": "Bulisa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.1180255,
        "lng": 31.4115209
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.3715209,
        2.0780255,
        31.4515209,
        2.1580255
      ]
    },
    {
      "id": 9694,
      "name": "Ouled Djellal",
      "names": {
        "fr": "Djellal Ouled",
        "zh": "oulled djellal",
        "it": "Djellal inottato",
        "ja": "WLED Djellal",
        "pt": "Djellal Ouled"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.42011,
        "lng": 5.06235
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.04235,
        34.40011,
        5.08235,
        34.44011
      ]
    },
    {
      "id": 9695,
      "name": "Waitangi",
      "names": {
        "zh": "怀唐伊"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -43.9511999,
        "lng": -176.561027
      },
      "country": "New Zealand",
      "importance": 1,
      "bbox": [
        -176.581027,
        -43.9711999,
        -176.541027,
        -43.9311999
      ]
    },
    {
      "id": 9696,
      "name": "Tivat",
      "names": {
        "zh": "蒂瓦特"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.4303762,
        "lng": 18.6988104
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.6843677,
        42.4175175,
        18.7237014,
        42.4462072
      ]
    },
    {
      "id": 9697,
      "name": "Kladovo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6093983,
        "lng": 22.6136517
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.5330782,
        44.5751486,
        22.6457282,
        44.627912
      ]
    },
    {
      "id": 9698,
      "name": "Dobrna",
      "names": {
        "zh": "多布尔纳"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3351752,
        "lng": 15.2264908
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.186646,
        46.3155787,
        15.2670845,
        46.3900312
      ]
    },
    {
      "id": 9699,
      "name": "Pesnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6094387,
        "lng": 15.6805717
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.6755717,
        46.6044387,
        15.6855717,
        46.6144387
      ]
    },
    {
      "id": 9700,
      "name": "Gorisnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.413208,
        "lng": 16.013527
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.003725,
        46.409552,
        16.017677,
        46.416414
      ]
    },
    {
      "id": 9701,
      "name": "Luce",
      "names": {
        "zh": "卢斯",
        "ja": "ルーチェ",
        "th": "ลูซ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3553576,
        "lng": 14.7451413
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.6374437,
        46.2816342,
        14.8129998,
        46.4319587
      ]
    },
    {
      "id": 9702,
      "name": "Videm pri Ptuju",
      "names": {
        "fr": "Videm Pri, Ptuju"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3696408,
        "lng": 15.9013608
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.8813608,
        46.3496408,
        15.9213608,
        46.3896408
      ]
    },
    {
      "id": 9703,
      "name": "Mparo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -1.164722,
        "lng": 30.037778
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.017778,
        -1.184722,
        30.057778,
        -1.144722
      ]
    },
    {
      "id": 9704,
      "name": "Stara Pazova",
      "names": {
        "id": "Sara Pazova"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9846077,
        "lng": 20.1588763
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.1188763,
        44.9446077,
        20.1988763,
        45.0246077
      ]
    },
    {
      "id": 9705,
      "name": "Kenge",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.8059503,
        "lng": 17.038692
      },
      "country": "Congo (Kinshasa)",
      "importance": 1,
      "bbox": [
        16.878692,
        -4.9659503,
        17.198692,
        -4.6459503
      ]
    },
    {
      "id": 9706,
      "name": "Mitoma",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8820431,
        "lng": 30.8185049
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.811058,
        -0.889365,
        30.8256783,
        -0.8771792
      ]
    },
    {
      "id": 9707,
      "name": "Sid",
      "names": {
        "zh": "的SID",
        "ja": "SIDを",
        "th": "ซิด"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.126371,
        "lng": 19.2291509
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.1419574,
        45.0967912,
        19.2799984,
        45.2025389
      ]
    },
    {
      "id": 9708,
      "name": "Lovrenc na Pohorju",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5413597,
        "lng": 15.3885739
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3110237,
        46.4634449,
        15.4572129,
        46.5851564
      ]
    },
    {
      "id": 9709,
      "name": "Logatec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9178067,
        "lng": 14.2233802
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0979413,
        45.846998,
        14.3020609,
        46.0377404
      ]
    },
    {
      "id": 9710,
      "name": "Crna na Koroskem",
      "names": {
        "ja": "CRNA NAコロスキム"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.469632,
        "lng": 14.8502645
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.6747946,
        46.4173743,
        14.9661104,
        46.5120562
      ]
    },
    {
      "id": 9711,
      "name": "Bukedea",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.31694,
        "lng": 34.05056
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.03056,
        1.29694,
        34.07056,
        1.33694
      ]
    },
    {
      "id": 9712,
      "name": "Amolatar",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.638383,
        "lng": 32.84737
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.82737,
        1.618383,
        32.86737,
        1.658383
      ]
    },
    {
      "id": 9713,
      "name": "Ribnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7399923,
        "lng": 14.7261067
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.5831979,
        45.6363337,
        14.8192173,
        45.8210329
      ]
    },
    {
      "id": 9714,
      "name": "Ol Kalou",
      "names": {
        "zh": "洛杉矶",
        "de": "Olkelnd",
        "ja": "オルカロス"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.2672966,
        "lng": 36.378065
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.338065,
        -0.3072966,
        36.418065,
        -0.2272966
      ]
    },
    {
      "id": 9715,
      "name": "Kasanda",
      "names": {
        "zh": "卡桑达"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.556667,
        "lng": 31.802222
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.782222,
        0.536667,
        31.822222,
        0.576667
      ]
    },
    {
      "id": 9716,
      "name": "Majsperk",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3504028,
        "lng": 15.7346942
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.6665916,
        46.261969,
        15.814166,
        46.3694372
      ]
    },
    {
      "id": 9717,
      "name": "Kasaali",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.6201298,
        "lng": 31.5575706
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.5375706,
        -0.6401298,
        31.5775706,
        -0.6001298
      ]
    },
    {
      "id": 9718,
      "name": "Vransko",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2432712,
        "lng": 14.9502677
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.8839376,
        46.185803,
        15.0106368,
        46.2818561
      ]
    },
    {
      "id": 9719,
      "name": "Sentilj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6807352,
        "lng": 15.6511365
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.65084,
        46.6800748,
        15.6513208,
        46.6814116
      ]
    },
    {
      "id": 9720,
      "name": "Bojnik",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.012738,
        "lng": 21.7213107
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7013107,
        42.992738,
        21.7413107,
        43.032738
      ]
    },
    {
      "id": 9721,
      "name": "Dokolo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.9174324,
        "lng": 33.1717366
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.1317366,
        1.8774324,
        33.2117366,
        1.9574324
      ]
    },
    {
      "id": 9722,
      "name": "Zebbug",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0732721,
        "lng": 14.2370559
      },
      "country": "Malta",
      "importance": 1,
      "bbox": [
        14.2268139,
        36.0494994,
        14.2653548,
        36.0821531
      ]
    },
    {
      "id": 9723,
      "name": "Kakumiro",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.78056,
        "lng": 31.32361
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.30361,
        0.76056,
        31.34361,
        0.80056
      ]
    },
    {
      "id": 9724,
      "name": "Zelino",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.98028,
        "lng": 21.06417
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.04417,
        41.96028,
        21.08417,
        42.00028
      ]
    },
    {
      "id": 9725,
      "name": "Trzin",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1327518,
        "lng": 14.5676375
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.5675875,
        46.1327018,
        14.5676875,
        46.1328018
      ]
    },
    {
      "id": 9726,
      "name": "Sembabule",
      "names": {
        "zh": "森巴布莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.0809318,
        "lng": 31.460307
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.420307,
        -0.1209318,
        31.500307,
        -0.0409318
      ]
    },
    {
      "id": 9727,
      "name": "Dobrovo",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.99639,
        "lng": 13.52639
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.50639,
        45.97639,
        13.54639,
        46.01639
      ]
    },
    {
      "id": 9728,
      "name": "Rakai",
      "names": {
        "zh": "拉卡伊"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.7079013,
        "lng": 31.4044819
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.3644819,
        -0.7479013,
        31.4444819,
        -0.6679013
      ]
    },
    {
      "id": 9729,
      "name": "Gllogovc",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.614037,
        "lng": 20.880605
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.760856,
        42.510015,
        20.986478,
        42.723488
      ]
    },
    {
      "id": 9730,
      "name": "Gjilan",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.463515,
        "lng": 21.4693599
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.4373902,
        42.4351285,
        21.4964796,
        42.4861846
      ]
    },
    {
      "id": 9731,
      "name": "Mitrovice",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.882416250000006,
        "lng": 20.861062016383134
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.8462553,
        42.8603844,
        20.8949027,
        42.9039809
      ]
    },
    {
      "id": 9732,
      "name": "Heydarabad",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.6916449,
        "lng": 44.9408385
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        44.9208385,
        38.6716449,
        44.9608385,
        38.7116449
      ]
    },
    {
      "id": 9733,
      "name": "Bogovinje",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.92361,
        "lng": 20.91361
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.89361,
        41.90361,
        20.93361,
        41.94361
      ]
    },
    {
      "id": 9734,
      "name": "Ig",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9603,
        "lng": 14.5289
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9735,
      "name": "Kinoni",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8768707,
        "lng": 30.1864193
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.1664193,
        -0.8968707,
        30.2064193,
        -0.8568707
      ]
    },
    {
      "id": 9736,
      "name": "Sari",
      "names": {
        "zh": "纱丽",
        "ja": "サリ",
        "th": "ส่าหรี"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.5659626,
        "lng": 53.0586327
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        53.0122445,
        36.5034516,
        53.1292896,
        36.5974309
      ]
    },
    {
      "id": 9737,
      "name": "Obiliq",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.68694,
        "lng": 21.07028
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.05028,
        42.66694,
        21.09028,
        42.70694
      ]
    },
    {
      "id": 9738,
      "name": "Rahovec",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3998736,
        "lng": 20.6527389
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.6127389,
        42.3598736,
        20.6927389,
        42.4398736
      ]
    },
    {
      "id": 9739,
      "name": "Trzic",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3636679,
        "lng": 14.3095397
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.2332991,
        46.3138282,
        14.4422648,
        46.4470427
      ]
    },
    {
      "id": 9740,
      "name": "Koronadal",
      "names": {
        "zh": "科罗纳达尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.5004067,
        "lng": 124.8435427
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        124.7888517,
        6.3517672,
        125.0004244,
        6.5697958
      ]
    },
    {
      "id": 9741,
      "name": "Kibingo",
      "names": {
        "zh": "基宾戈"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.5960241,
        "lng": 30.358148
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.318148,
        -0.6360241,
        30.398148,
        -0.5560241
      ]
    },
    {
      "id": 9742,
      "name": "Recica",
      "names": {
        "zh": "雷契察"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 44.7727009,
        "lng": 21.3080362
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        21.2880362,
        44.7527009,
        21.3280362,
        44.7927009
      ]
    },
    {
      "id": 9743,
      "name": "Omuthiya",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -18.3579195,
        "lng": 16.581516
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        16.541516,
        -18.3979195,
        16.621516,
        -18.3179195
      ]
    },
    {
      "id": 9744,
      "name": "Dol",
      "names": {
        "zh": "杜比"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8528715,
        "lng": 15.4777361
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.4577361,
        45.8328715,
        15.4977361,
        45.8728715
      ]
    },
    {
      "id": 9745,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 39.4520332,
        "lng": -5.3273801
      },
      "country": "Sao Tome And Principe",
      "importance": 1,
      "bbox": [
        -5.3828479,
        39.3909371,
        -5.26272,
        39.5036608
      ]
    },
    {
      "id": 9746,
      "name": "Pailin",
      "names": {
        "th": "ไพลิน"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.8551384,
        "lng": 102.6051776
      },
      "country": "Cambodia",
      "importance": 1,
      "bbox": [
        102.497282,
        12.663855,
        102.7533088,
        12.8923275
      ]
    },
    {
      "id": 9747,
      "name": "Vushtrri",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.82306,
        "lng": 20.9675
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.9475,
        42.80306,
        20.9875,
        42.84306
      ]
    },
    {
      "id": 9748,
      "name": "Studenicani",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.91583,
        "lng": 21.53056
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.51056,
        41.89583,
        21.55056,
        41.93583
      ]
    },
    {
      "id": 9749,
      "name": "Leulumoega",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.82298,
        "lng": -171.96127
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -171.98127,
        -13.84298,
        -171.94127,
        -13.80298
      ]
    },
    {
      "id": 9750,
      "name": "Lwengo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.44411805000000004,
        "lng": 31.39901865809899
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.1668585,
        -0.5975251,
        31.6690598,
        -0.2907366
      ]
    },
    {
      "id": 9751,
      "name": "Butalangu",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.8226456,
        "lng": 32.2427659
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.2427159,
        0.8225956,
        32.2428159,
        0.8226956
      ]
    },
    {
      "id": 9752,
      "name": "Techiman",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 7.58616,
        "lng": -1.94137
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -1.96137,
        7.56616,
        -1.92137,
        7.60616
      ]
    },
    {
      "id": 9753,
      "name": "Kara",
      "names": {
        "zh": "卡拉",
        "ja": "カラ",
        "th": "คาร่า"
      },
      "countryCode": "TG",
      "latLng": {
        "lat": 9.5488754,
        "lng": 1.1977453
      },
      "country": "Togo",
      "importance": 1,
      "bbox": [
        1.0377453,
        9.3888754,
        1.3577453,
        9.7088754
      ]
    },
    {
      "id": 9754,
      "name": "Kanoni",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.1726457,
        "lng": 31.9064746
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.7464746,
        0.0126457,
        32.0664746,
        0.3326457
      ]
    },
    {
      "id": 9755,
      "name": "Gia Nghia",
      "names": {
        "id": "Gia ngia"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.00423,
        "lng": 107.69074
      },
      "country": "Vietnam",
      "importance": 1,
      "bbox": [
        107.67074,
        11.98423,
        107.71074,
        12.02423
      ]
    },
    {
      "id": 9756,
      "name": "Alebtong",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.24472,
        "lng": 33.25472
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.23472,
        2.22472,
        33.27472,
        2.26472
      ]
    },
    {
      "id": 9757,
      "name": "Topola",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2544752,
        "lng": 20.6797215
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.6397215,
        44.2144752,
        20.7197215,
        44.2944752
      ]
    },
    {
      "id": 9758,
      "name": "Suai",
      "names": {
        "zh": "苏艾",
        "th": "สรวย"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -9.31286,
        "lng": 125.25648
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        125.23648,
        -9.33286,
        125.27648,
        -9.29286
      ]
    },
    {
      "id": 9759,
      "name": "Gorenja Vas",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1051249,
        "lng": 14.1447603
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.1247603,
        46.0851249,
        14.1647603,
        46.1251249
      ]
    },
    {
      "id": 9760,
      "name": "Dornava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.436012,
        "lng": 15.951996
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.944111,
        46.428487,
        15.981806,
        46.44038
      ]
    },
    {
      "id": 9761,
      "name": "Pante Macassar",
      "names": {
        "ja": "パンテマッサー"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -9.2017507,
        "lng": 124.3702378
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        124.2102378,
        -9.3617507,
        124.5302378,
        -9.0417507
      ]
    },
    {
      "id": 9762,
      "name": "Kokopo",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -4.3432175,
        "lng": 152.2686741
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        152.2286741,
        -4.3832175,
        152.3086741,
        -4.3032175
      ]
    },
    {
      "id": 9763,
      "name": "Zelezniki",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2257913,
        "lng": 14.1693085
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.985558,
        46.1535853,
        14.2477252,
        46.2829934
      ]
    },
    {
      "id": 9764,
      "name": "Ramotswa",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -24.8476205,
        "lng": 25.832371
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        25.827371,
        -24.8526205,
        25.837371,
        -24.8426205
      ]
    },
    {
      "id": 9765,
      "name": "Amuria",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.9819076500000001,
        "lng": 33.65747185804606
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.3467997,
        1.7152469,
        33.9009474,
        2.2486034
      ]
    },
    {
      "id": 9766,
      "name": "Abim",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.70167,
        "lng": 33.67611
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.65611,
        2.68167,
        33.69611,
        2.72167
      ]
    },
    {
      "id": 9767,
      "name": "Ngora",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.4566794,
        "lng": 33.7766823
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.7366823,
        1.4166794,
        33.8166823,
        1.4966794
      ]
    },
    {
      "id": 9768,
      "name": "Lukovica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1697683,
        "lng": 14.6913078
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.6426781,
        46.1416817,
        14.9029142,
        46.20838
      ]
    },
    {
      "id": 9769,
      "name": "Kalaki",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.81602,
        "lng": 33.33695
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.31695,
        1.79602,
        33.35695,
        1.83602
      ]
    },
    {
      "id": 9770,
      "name": "Lipjan",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.5221067,
        "lng": 21.1242318
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.1241818,
        42.5220567,
        21.1242818,
        42.5221567
      ]
    },
    {
      "id": 9771,
      "name": "Turnisce",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.626445,
        "lng": 16.316463
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.305674,
        46.614077,
        16.325523,
        46.631136
      ]
    },
    {
      "id": 9772,
      "name": "Al `Aziziyah",
      "names": {
        "zh": "Al`aziziyah",
        "ja": "Aziziyah",
        "th": "อัล `Aziziyah"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.5330923,
        "lng": 13.0176242
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        12.8576242,
        32.3730923,
        13.1776242,
        32.6930923
      ]
    },
    {
      "id": 9773,
      "name": "Suhareke",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3636116,
        "lng": 20.8102534
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.8102034,
        42.3635616,
        20.8103034,
        42.3636616
      ]
    },
    {
      "id": 9774,
      "name": "Cerkno",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.126898,
        "lng": 13.9856171
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.9855671,
        46.126848,
        13.9856671,
        46.126948
      ]
    },
    {
      "id": 9775,
      "name": "Smarjeske Toplice",
      "names": {
        "id": "Toplice Smarjeske"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8624792,
        "lng": 15.2218929
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2210212,
        45.8623273,
        15.2228601,
        45.8630115
      ]
    },
    {
      "id": 9776,
      "name": "Chiesanuova",
      "names": {},
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9053382,
        "lng": 12.4220187
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.4075711,
        43.9002402,
        12.4382886,
        43.9331385
      ]
    },
    {
      "id": 9777,
      "name": "Saltangara",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.1169179,
        "lng": -6.7188176
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.7286746,
        62.1099171,
        -6.6294577,
        62.1323065
      ]
    },
    {
      "id": 9778,
      "name": "Kamwenge",
      "names": {
        "zh": "卡姆文盖"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.1889951,
        "lng": 30.4457201
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.4407201,
        0.1839951,
        30.4507201,
        0.1939951
      ]
    },
    {
      "id": 9779,
      "name": "Mulifanua",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.83183,
        "lng": -172.03602
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.05602,
        -13.85183,
        -172.01602,
        -13.81183
      ]
    },
    {
      "id": 9780,
      "name": "Ljubno",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.344265,
        "lng": 14.833867
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.829536,
        46.336208,
        14.847428,
        46.349372
      ]
    },
    {
      "id": 9781,
      "name": "Lospalos",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.51277095,
        "lng": 127.04640363917606
      },
      "country": "Timor-Leste",
      "importance": 1,
      "bbox": [
        126.870203,
        -8.6297846,
        127.2322803,
        -8.3958197
      ]
    },
    {
      "id": 9782,
      "name": "Bohinjska Bistrica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.273124,
        "lng": 13.955616
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.94765,
        46.26713,
        13.964741,
        46.277069
      ]
    },
    {
      "id": 9783,
      "name": "Starse",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.466578,
        "lng": 15.767739
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.757284,
        46.463631,
        15.770976,
        46.477721
      ]
    },
    {
      "id": 9784,
      "name": "Isingiro",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.795591,
        "lng": 30.8171008
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.7771008,
        -0.835591,
        30.8571008,
        -0.755591
      ]
    },
    {
      "id": 9785,
      "name": "Ilinden",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.993768,
        "lng": 21.5775059
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.5293944,
        41.975576,
        21.6007157,
        42.0192085
      ]
    },
    {
      "id": 9786,
      "name": "Tabor",
      "names": {
        "zh": "塔博尔",
        "ja": "テイバー",
        "th": "ทาโบร์"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7683941,
        "lng": 13.9581803
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.9381803,
        45.7483941,
        13.9781803,
        45.7883941
      ]
    },
    {
      "id": 9787,
      "name": "Rabak",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 13.1844655,
        "lng": 32.7398062
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        32.5798062,
        13.0244655,
        32.8998062,
        13.3444655
      ]
    },
    {
      "id": 9788,
      "name": "Kidricevo",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.404253,
        "lng": 15.796229
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.778689,
        46.382725,
        15.818689,
        46.422725
      ]
    },
    {
      "id": 9789,
      "name": "Tsirang",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.0219,
        "lng": 90.12291
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        90.10291,
        27.0019,
        90.14291,
        27.0419
      ]
    },
    {
      "id": 9790,
      "name": "Gjakove",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.38028,
        "lng": 20.43083
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.41083,
        42.36028,
        20.45083,
        42.40028
      ]
    },
    {
      "id": 9791,
      "name": "Tutong",
      "names": {
        "zh": "都东"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.806672,
        "lng": 114.6594998
      },
      "country": "Brunei",
      "importance": 1,
      "bbox": [
        114.6497154,
        4.7996721,
        114.6735546,
        4.8271372
      ]
    },
    {
      "id": 9792,
      "name": "Divaca",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.682792,
        "lng": 13.969269
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.962691,
        45.677975,
        13.973457,
        45.687966
      ]
    },
    {
      "id": 9793,
      "name": "Kwale",
      "names": {
        "zh": "夸莱"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -4.1746877,
        "lng": 39.45267
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        39.41267,
        -4.2146877,
        39.49267,
        -4.1346877
      ]
    },
    {
      "id": 9794,
      "name": "Sveti Jurij",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.7977137,
        "lng": 16.0359983
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0344598,
        46.793512,
        16.0369033,
        46.8025326
      ]
    },
    {
      "id": 9795,
      "name": "Santana",
      "names": {
        "zh": "桑塔纳",
        "ja": "サンタナ"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 32.804907,
        "lng": -16.8813015
      },
      "country": "Sao Tome And Principe",
      "importance": 1,
      "bbox": [
        -16.9713116,
        32.7178935,
        -16.8399169,
        32.8376112
      ]
    },
    {
      "id": 9796,
      "name": "Faetano",
      "names": {},
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9269122,
        "lng": 12.4974633
      },
      "country": "San Marino",
      "importance": 1,
      "bbox": [
        12.469888,
        43.9145653,
        12.5169591,
        43.9479585
      ]
    },
    {
      "id": 9797,
      "name": "Pozega",
      "names": {
        "zh": "波热加"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3310723,
        "lng": 17.6765332
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        17.5677654,
        45.2677747,
        17.7529792,
        45.4058684
      ]
    },
    {
      "id": 9798,
      "name": "Straza",
      "names": {
        "de": "Straža"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7836855,
        "lng": 15.0730399
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0524699,
        45.7310375,
        15.1246206,
        45.8184013
      ]
    },
    {
      "id": 9799,
      "name": "Sejong",
      "names": {
        "zh": "世宗"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.59245,
        "lng": 127.29223
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        127.27223,
        36.57245,
        127.31223,
        36.61245
      ]
    },
    {
      "id": 9800,
      "name": "Merosina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2828198,
        "lng": 21.7203782
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7003782,
        43.2628198,
        21.7403782,
        43.3028198
      ]
    },
    {
      "id": 9801,
      "name": "Princes Town",
      "names": {
        "es": "Ciudad de Princes",
        "fr": "Princes",
        "zh": "王子镇",
        "id": "Kota pangeran",
        "th": "เมืองเจ้าชาย",
        "pt": "Cidade príncipes"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.26667,
        "lng": -61.38333
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.40333,
        10.24667,
        -61.36333,
        10.28667
      ]
    },
    {
      "id": 9802,
      "name": "Peje",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6593986,
        "lng": 20.2885757
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.1285757,
        42.4993986,
        20.4485757,
        42.8193986
      ]
    },
    {
      "id": 9803,
      "name": "Viti",
      "names": {
        "es": "Levu"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3187997,
        "lng": 21.3571197
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.3171197,
        42.2787997,
        21.3971197,
        42.3587997
      ]
    },
    {
      "id": 9804,
      "name": "Temerin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.4073868,
        "lng": 19.8876546
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.8151105,
        45.3741687,
        19.9772093,
        45.4818837
      ]
    },
    {
      "id": 9805,
      "name": "Benedikt",
      "names": {
        "zh": "贝内"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.608412,
        "lng": 15.890581
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.886952,
        46.604933,
        15.893556,
        46.611571
      ]
    },
    {
      "id": 9806,
      "name": "Semic",
      "names": {
        "it": "Semico"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6517513,
        "lng": 15.1835241
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.0271017,
        45.5969117,
        15.2760869,
        45.707695
      ]
    },
    {
      "id": 9807,
      "name": "Cirkulane",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3444001,
        "lng": 15.994837
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.9947173,
        46.3443305,
        15.9950194,
        46.3446039
      ]
    },
    {
      "id": 9808,
      "name": "Vodice",
      "names": {
        "ja": "ヴォディツェ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.4840561,
        "lng": 14.0534955
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0334955,
        45.4640561,
        14.0734955,
        45.5040561
      ]
    },
    {
      "id": 9809,
      "name": "Nkurenkuru",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.61667,
        "lng": 18.6
      },
      "country": "Namibia",
      "importance": 1,
      "bbox": [
        18.58,
        -17.63667,
        18.62,
        -17.59667
      ]
    },
    {
      "id": 9810,
      "name": "Sefwi Wiawso",
      "names": {
        "de": "Sefwi Wiwso"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.2052869,
        "lng": -2.4882333
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -2.5282333,
        6.1652869,
        -2.4482333,
        6.2452869
      ]
    },
    {
      "id": 9811,
      "name": "Sveta Ana",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6492264,
        "lng": 15.8434687
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.7877608,
        46.6069543,
        15.8923795,
        46.7012014
      ]
    },
    {
      "id": 9812,
      "name": "Rumuruti",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.2709167,
        "lng": 36.53779995178557
      },
      "country": "Kenya",
      "importance": 1,
      "bbox": [
        36.5262466,
        0.2569277,
        36.5487127,
        0.2848853
      ]
    },
    {
      "id": 9813,
      "name": "Hongseong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.5993365,
        "lng": 126.6812157
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        126.6762157,
        36.5943365,
        126.6862157,
        36.6043365
      ]
    },
    {
      "id": 9814,
      "name": "Mersch",
      "names": {
        "zh": "梅尔施"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.7506746,
        "lng": 6.1066591
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        6.037321,
        49.6930621,
        6.158968,
        49.7912716
      ]
    },
    {
      "id": 9815,
      "name": "Muan",
      "names": {
        "zh": "务安",
        "id": "Gwangju Muan International",
        "th": "ม่วน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.9905794,
        "lng": 126.4812377
      },
      "country": "South Korea",
      "importance": 1,
      "bbox": [
        126.222665,
        34.7669994,
        126.5534019,
        35.1706161
      ]
    },
    {
      "id": 9816,
      "name": "Nova Vas",
      "names": {
        "ja": "ノババス"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7730725,
        "lng": 14.5068868
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.4868868,
        45.7530725,
        14.5268868,
        45.7930725
      ]
    },
    {
      "id": 9817,
      "name": "Zgornja Hajdina",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.403352,
        "lng": 15.8346743
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.8310609,
        46.4013211,
        15.8385617,
        46.4057188
      ]
    },
    {
      "id": 9818,
      "name": "Kacanik",
      "names": {
        "zh": "卡查尼克"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.233109,
        "lng": 21.2553
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.25521,
        42.2330096,
        21.2553894,
        42.233209
      ]
    },
    {
      "id": 9819,
      "name": "Mirna Pec",
      "names": {
        "th": "Mira Pec"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.860402,
        "lng": 15.083954
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.063954,
        45.840402,
        15.103954,
        45.880402
      ]
    },
    {
      "id": 9820,
      "name": "Buyende",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.168977,
        "lng": 33.1579126
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.1179126,
        1.128977,
        33.1979126,
        1.208977
      ]
    },
    {
      "id": 9821,
      "name": "Tuzi",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.36556,
        "lng": 19.33139
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.31139,
        42.34556,
        19.35139,
        42.38556
      ]
    },
    {
      "id": 9822,
      "name": "Bangolo",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 7.01232,
        "lng": -7.4864
      },
      "country": "Côte d'Ivoire",
      "importance": 1,
      "bbox": [
        -7.5064,
        6.99232,
        -7.4664,
        7.03232
      ]
    },
    {
      "id": 9823,
      "name": "Saratamata",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -15.287186,
        "lng": 167.9906309
      },
      "country": "Vanuatu",
      "importance": 1,
      "bbox": [
        167.9706309,
        -15.307186,
        168.0106309,
        -15.267186
      ]
    },
    {
      "id": 9824,
      "name": "Skenderaj",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.74667,
        "lng": 20.78861
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.76861,
        42.72667,
        20.80861,
        42.76667
      ]
    },
    {
      "id": 9825,
      "name": "Strendur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.109726,
        "lng": -6.759497
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.759497,
        62.109726,
        -6.759497,
        62.109726
      ]
    },
    {
      "id": 9826,
      "name": "Kurumul",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -5.87564,
        "lng": 144.63697
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        144.61697,
        -5.89564,
        144.65697,
        -5.85564
      ]
    },
    {
      "id": 9827,
      "name": "Palenga",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.613021,
        "lng": 32.337026
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.317026,
        2.593021,
        32.357026,
        2.633021
      ]
    },
    {
      "id": 9828,
      "name": "Sveta Trojica v Slovenskih Goricah",
      "names": {
        "es": "Sveta Trojica V Eslovenskih Goricah",
        "ja": "Sveta Trojica vスロベンシハGoricah"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.57667,
        "lng": 15.87694
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.85694,
        46.55667,
        15.89694,
        46.59667
      ]
    },
    {
      "id": 9829,
      "name": "Zgornja Kungota",
      "names": {
        "es": "ZGORNJA KUNDOTA"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.638953,
        "lng": 15.615503
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.610214,
        46.633941,
        15.62136,
        46.643529
      ]
    },
    {
      "id": 9830,
      "name": "Lamwo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.5248125,
        "lng": 32.657627334186714
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.1949034,
        3.1548996,
        33.3293746,
        3.8936137
      ]
    },
    {
      "id": 9831,
      "name": "Oyam",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.214069,
        "lng": 32.3703233
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        32.3303233,
        2.174069,
        32.4103233,
        2.254069
      ]
    },
    {
      "id": 9832,
      "name": "Kula",
      "names": {
        "zh": "库拉",
        "ja": "クラ",
        "th": "กุลา"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6101491,
        "lng": 19.5301174
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.4584022,
        45.5244739,
        19.627704,
        45.6907671
      ]
    },
    {
      "id": 9833,
      "name": "Destrnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.492774,
        "lng": 15.875915
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.855915,
        46.472774,
        15.895915,
        46.512774
      ]
    },
    {
      "id": 9834,
      "name": "Ponta do Sol",
      "names": {
        "zh": "Ponta做溶胶",
        "th": "Ponta ทำโซล"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 17.2013338,
        "lng": -25.0910546
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -25.1310546,
        17.1613338,
        -25.0510546,
        17.2413338
      ]
    },
    {
      "id": 9835,
      "name": "Podujeve",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.911552,
        "lng": 21.191986
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.1918542,
        42.9114221,
        21.1921373,
        42.9116956
      ]
    },
    {
      "id": 9836,
      "name": "Lelydorp",
      "names": {},
      "countryCode": "SR",
      "latLng": {
        "lat": 5.6962534,
        "lng": -55.2175832
      },
      "country": "Suriname",
      "importance": 1,
      "bbox": [
        -55.2176332,
        5.6962034,
        -55.2175332,
        5.6963034
      ]
    },
    {
      "id": 9837,
      "name": "Apatin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6712544,
        "lng": 18.9817862
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        18.8880289,
        45.5411004,
        19.0656455,
        45.7151684
      ]
    },
    {
      "id": 9838,
      "name": "Dobrova",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0545038,
        "lng": 14.4156797
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.3956797,
        46.0345038,
        14.4356797,
        46.0745038
      ]
    },
    {
      "id": 9839,
      "name": "Makole",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3169639,
        "lng": 15.6668625
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.634324,
        46.2738172,
        15.7164503,
        46.3515084
      ]
    },
    {
      "id": 9840,
      "name": "Zitorada",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.18604705,
        "lng": 21.721789890209124
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.6446186,
        43.1535206,
        21.7522497,
        43.2186528
      ]
    },
    {
      "id": 9841,
      "name": "Markovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3947708,
        "lng": 15.9298496
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.8946972,
        46.3613066,
        15.9927513,
        46.420885
      ]
    },
    {
      "id": 9842,
      "name": "Crensovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5742446,
        "lng": 16.2911504
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.2893443,
        46.5735492,
        16.2929113,
        46.5749176
      ]
    },
    {
      "id": 9843,
      "name": "Zvecan",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.9091868,
        "lng": 20.8389308
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.7989308,
        42.8691868,
        20.8789308,
        42.9491868
      ]
    },
    {
      "id": 9844,
      "name": "Kovin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.7475,
        "lng": 20.97611
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.95611,
        44.7275,
        20.99611,
        44.7675
      ]
    },
    {
      "id": 9845,
      "name": "Koceljeva",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.4679915,
        "lng": 19.8203527
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.7879589,
        44.4367965,
        19.8724287,
        44.4909484
      ]
    },
    {
      "id": 9846,
      "name": "Verzej",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.583255,
        "lng": 16.162776
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.155997,
        46.578081,
        16.170399,
        46.588919
      ]
    },
    {
      "id": 9847,
      "name": "Velike Lasce",
      "names": {
        "es": "Lascerce",
        "zh": "柔软骆驼",
        "it": "Lasce velike",
        "id": "Lasce velike",
        "ja": "バイクラスク",
        "pt": "Lasce velrikle"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.83286,
        "lng": 14.636997
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.62814,
        45.830238,
        14.641051,
        45.836642
      ]
    },
    {
      "id": 9848,
      "name": "Krizevci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5668456,
        "lng": 16.11762163876399
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0681794,
        46.5259675,
        16.1745645,
        46.6076987
      ]
    },
    {
      "id": 9849,
      "name": "Agago",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.833056,
        "lng": 33.34
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.3,
        2.793056,
        33.38,
        2.873056
      ]
    },
    {
      "id": 9850,
      "name": "Razkrizje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5213749,
        "lng": 16.2785902
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.2373629,
        46.498652,
        16.3148106,
        46.5386737
      ]
    },
    {
      "id": 9851,
      "name": "Skocjan",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.907782,
        "lng": 15.2906185
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2225462,
        45.8671058,
        15.3512261,
        45.9607912
      ]
    },
    {
      "id": 9852,
      "name": "Morant Bay",
      "names": {
        "es": "Moreno",
        "fr": "Baie",
        "it": "Baia moranta",
        "id": "Teluk",
        "ja": "モラントベイ",
        "th": "อ่าวอ่านต่อ",
        "pt": "Baía moral"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.88145,
        "lng": -76.40927
      },
      "country": "Jamaica",
      "importance": 1,
      "bbox": [
        -76.42927,
        17.86145,
        -76.38927,
        17.90145
      ]
    },
    {
      "id": 9853,
      "name": "Star Dojran",
      "names": {
        "es": "Estrella doyran",
        "zh": "明星莫德兰",
        "it": "Stella dojran",
        "id": "Bintang dojran",
        "ja": "スタードジュラン",
        "th": "ดาว Dojran",
        "pt": "Estrela dojran"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1827308,
        "lng": 22.7215952
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.6667463,
        41.1649729,
        22.7243292,
        41.2005865
      ]
    },
    {
      "id": 9854,
      "name": "Zuzemberk",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.832829,
        "lng": 14.930952
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.90829,
        45.81208,
        14.94829,
        45.85208
      ]
    },
    {
      "id": 9855,
      "name": "Puconci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.704899,
        "lng": 16.154885
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.138711,
        46.699015,
        16.16336,
        46.711644
      ]
    },
    {
      "id": 9856,
      "name": "Madinat ash Shamal",
      "names": {
        "zh": "麦丽尼特灰血统",
        "de": "Madinat Esche Shamal",
        "id": "Shamal Ash Madinat",
        "pt": "Shamal de Ash Madinat"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 26.12933,
        "lng": 51.2009
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9857,
      "name": "Gadzin Han",
      "names": {
        "zh": "加辛汉",
        "ja": "ガジンハン"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2235312,
        "lng": 22.0324274
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.0124274,
        43.2035312,
        22.0524274,
        43.2435312
      ]
    },
    {
      "id": 9858,
      "name": "Videm",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8486284,
        "lng": 14.694642
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.674642,
        45.8286284,
        14.714642,
        45.8686284
      ]
    },
    {
      "id": 9859,
      "name": "Cicevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7169645,
        "lng": 21.45144489724585
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.369642,
        43.6886,
        21.5054496,
        43.7452047
      ]
    },
    {
      "id": 9860,
      "name": "Pecinci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9090756,
        "lng": 19.9672065
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.9472065,
        44.8890756,
        19.9872065,
        44.9290756
      ]
    },
    {
      "id": 9861,
      "name": "Zavrc",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3894946,
        "lng": 16.0521577
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0501736,
        46.3884901,
        16.0530197,
        46.390431
      ]
    },
    {
      "id": 9862,
      "name": "Mongar",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.27471,
        "lng": 91.23963
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        91.21963,
        27.25471,
        91.25963,
        27.29471
      ]
    },
    {
      "id": 9863,
      "name": "Ilam",
      "names": {
        "zh": "伊拉姆"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 33.6379365,
        "lng": 46.4202743
      },
      "country": "Iran",
      "importance": 1,
      "bbox": [
        46.3696002,
        33.6019668,
        46.452341,
        33.6696399
      ]
    },
    {
      "id": 9864,
      "name": "Qazax",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.09333,
        "lng": 45.36611
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.34611,
        41.07333,
        45.38611,
        41.11333
      ]
    },
    {
      "id": 9865,
      "name": "Lipkovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.15639,
        "lng": 21.58528
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        21.56528,
        42.13639,
        21.60528,
        42.17639
      ]
    },
    {
      "id": 9866,
      "name": "Bosilegrad",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.4996561,
        "lng": 22.4732689
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.4325567,
        42.473715,
        22.4985055,
        42.5321113
      ]
    },
    {
      "id": 9867,
      "name": "Tari",
      "names": {
        "zh": "塔里",
        "ja": "タリ"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.84524,
        "lng": 142.946622
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        142.786622,
        -6.00524,
        143.106622,
        -5.68524
      ]
    },
    {
      "id": 9868,
      "name": "Velika Polana",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.573354,
        "lng": 16.343868
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.332531,
        46.569038,
        16.355156,
        46.576673
      ]
    },
    {
      "id": 9869,
      "name": "Cankova",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.7201171,
        "lng": 16.0235636
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0224084,
        46.7200524,
        16.0245502,
        46.7202458
      ]
    },
    {
      "id": 9870,
      "name": "Vladimirci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6158293,
        "lng": 19.7851041
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.7651041,
        44.5958293,
        19.8051041,
        44.6358293
      ]
    },
    {
      "id": 9871,
      "name": "Haa",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.3700583,
        "lng": 89.2917476
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.2517476,
        27.3300583,
        89.3317476,
        27.4100583
      ]
    },
    {
      "id": 9872,
      "name": "Singa",
      "names": {
        "zh": "新加坡"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.1440522,
        "lng": 33.9283527
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        33.0820389,
        11.674535,
        34.3423312,
        13.3611034
      ]
    },
    {
      "id": 9873,
      "name": "Razanj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6724063,
        "lng": 21.5489925
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.5089925,
        43.6324063,
        21.5889925,
        43.7124063
      ]
    },
    {
      "id": 9874,
      "name": "Tvoroyri",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.556663,
        "lng": -6.805997
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.805997,
        61.556663,
        -6.805997,
        61.556663
      ]
    },
    {
      "id": 9875,
      "name": "Port Loko",
      "names": {
        "zh": "洛科港",
        "de": "Hafenloko",
        "it": "Porto loko",
        "id": "Pelabuhan loko",
        "ja": "ポートLOKO",
        "th": "พอร์ตโลโกะ",
        "pt": "Porto loko"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.76667,
        "lng": -12.78333
      },
      "country": "Sierra Leone",
      "importance": 1,
      "bbox": [
        -12.80333,
        8.74667,
        -12.76333,
        8.78667
      ]
    },
    {
      "id": 9876,
      "name": "Pul-e `Alam",
      "names": {
        "zh": "pul-e`Alam",
        "ja": "PUL-E 'alam"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.0057712,
        "lng": 69.0112806
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        68.8512806,
        33.8457712,
        69.1712806,
        34.1657712
      ]
    },
    {
      "id": 9877,
      "name": "Kllokot",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3694126,
        "lng": 21.3748004
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.355281,
        42.3482151,
        21.3913519,
        42.3836272
      ]
    },
    {
      "id": 9878,
      "name": "Kucevo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.4782165,
        "lng": 21.6693022
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.5712253,
        44.4440729,
        21.7145054,
        44.5209576
      ]
    },
    {
      "id": 9879,
      "name": "Boorama",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 9.9386691,
        "lng": 43.184119
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        43.024119,
        9.7786691,
        43.344119,
        10.0986691
      ]
    },
    {
      "id": 9880,
      "name": "Komen",
      "names": {
        "zh": "科门",
        "th": "โกเมน"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8167138,
        "lng": 13.7450363
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.5958728,
        45.7750254,
        13.9079188,
        45.8635138
      ]
    },
    {
      "id": 9881,
      "name": "Apace",
      "names": {
        "es": "A buen ritmo",
        "fr": "Rapidement",
        "zh": "快速地",
        "de": "Schnell",
        "it": "Buon passo",
        "id": "Dgn cepat",
        "ja": "たちまち",
        "th": "ด้วยความเร็ว",
        "pt": "Rapidamente"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6972894,
        "lng": 15.9096217
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.7864246,
        46.6596711,
        15.960084,
        46.7223886
      ]
    },
    {
      "id": 9882,
      "name": "Kuzma",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8348526,
        "lng": 16.0808665
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0543447,
        46.8105928,
        16.1374301,
        46.8693021
      ]
    },
    {
      "id": 9883,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.3361663,
        "lng": -121.890591
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        -122.0460405,
        37.1231596,
        -121.5858438,
        37.4691477
      ]
    },
    {
      "id": 9884,
      "name": "Napak",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.1167734,
        "lng": 34.2269432
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.1869432,
        2.0767734,
        34.2669432,
        2.1567734
      ]
    },
    {
      "id": 9885,
      "name": "Kaffrine",
      "names": {
        "zh": "卡夫林"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.10197,
        "lng": -15.548433
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -15.5737781,
        14.0779984,
        -15.5219094,
        14.1247939
      ]
    },
    {
      "id": 9886,
      "name": "Sharan",
      "names": {
        "zh": "沙然"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.200177,
        "lng": 68.728497
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        68.5009194,
        33.1004292,
        68.893991,
        33.3938866
      ]
    },
    {
      "id": 9887,
      "name": "Istog",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.7811109,
        "lng": 20.4830091
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.482988,
        42.7810408,
        20.4830342,
        42.7811813
      ]
    },
    {
      "id": 9888,
      "name": "Gornji Grad",
      "names": {
        "es": "Gornji graduado",
        "id": "Grad Gornji",
        "ja": "ゴーンジ卒業生",
        "pt": "Graduado de Gornji"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2960951,
        "lng": 14.8057772
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.695603,
        46.2499383,
        14.8788028,
        46.3422712
      ]
    },
    {
      "id": 9889,
      "name": "Krapina",
      "names": {
        "zh": "克拉皮纳"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 46.157344,
        "lng": 15.87435
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        15.859123,
        46.142369,
        15.882766,
        46.172369
      ]
    },
    {
      "id": 9890,
      "name": "Plandiste",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.22965,
        "lng": 21.1222784
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.0822784,
        45.18965,
        21.1622784,
        45.26965
      ]
    },
    {
      "id": 9891,
      "name": "Dambai",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 8.067609,
        "lng": 0.179472
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        0.139472,
        8.027609,
        0.219472,
        8.107609
      ]
    },
    {
      "id": 9892,
      "name": "Cerkvenjak",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5685539,
        "lng": 15.9453545
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.9438177,
        46.5679489,
        15.9467745,
        46.5686918
      ]
    },
    {
      "id": 9893,
      "name": "Medveda",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.83086795,
        "lng": 21.57668111028515
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.5419236,
        42.801437,
        21.6128433,
        42.8603281
      ]
    },
    {
      "id": 9894,
      "name": "Goaso",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.8035466,
        "lng": -2.5172391
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -2.5572391,
        6.7635466,
        -2.4772391,
        6.8435466
      ]
    },
    {
      "id": 9895,
      "name": "Suhar",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 24.3445577,
        "lng": 56.7424766
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        56.5824766,
        24.1845577,
        56.9024766,
        24.5045577
      ]
    },
    {
      "id": 9896,
      "name": "Sarur",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.5559048,
        "lng": 44.9850947
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        44.9661851,
        39.5404443,
        44.9973744,
        39.5673142
      ]
    },
    {
      "id": 9897,
      "name": "Sredisce ob Dravi",
      "names": {
        "de": "Sadisce ob dravi",
        "it": "Sardisce ob dravi"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3947867,
        "lng": 16.2702138
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.2068859,
        46.3763791,
        16.3079438,
        46.4414985
      ]
    },
    {
      "id": 9898,
      "name": "Redange-sur-Attert",
      "names": {
        "zh": "redange畔attert",
        "ja": "redangeシュルattert",
        "th": "redange-ซูร์ attert"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.7650714,
        "lng": 5.8914033
      },
      "country": "Luxembourg",
      "importance": 1,
      "bbox": [
        5.862734,
        49.74355,
        5.9130089,
        49.7880467
      ]
    },
    {
      "id": 9899,
      "name": "Jursinci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4847358,
        "lng": 15.9716669
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.9716184,
        46.4845476,
        15.9717426,
        46.4850755
      ]
    },
    {
      "id": 9900,
      "name": "Gaoua",
      "names": {
        "zh": "加瓦"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 10.3303342,
        "lng": -3.1795681
      },
      "country": "Burkina Faso",
      "importance": 1,
      "bbox": [
        -3.3852268,
        10.1624509,
        -2.9623818,
        10.5255877
      ]
    },
    {
      "id": 9901,
      "name": "Dolenjske Toplice",
      "names": {
        "id": "Toplice Dolenjske"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7572692,
        "lng": 15.0583791
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.9607572,
        45.6356031,
        15.1208841,
        45.7941953
      ]
    },
    {
      "id": 9902,
      "name": "Kozje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0745652,
        "lng": 15.5606095
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.454303,
        45.9960331,
        15.6560213,
        46.1336789
      ]
    },
    {
      "id": 9903,
      "name": "Cocieri",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.3039291,
        "lng": 29.1143421
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.0969244,
        47.2898289,
        29.1245189,
        47.3183554
      ]
    },
    {
      "id": 9904,
      "name": "Podcetrtek",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1518082,
        "lng": 15.597496
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.5248164,
        46.0654558,
        15.6382718,
        46.2068791
      ]
    },
    {
      "id": 9905,
      "name": "Mokronog",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.942664,
        "lng": 15.143093
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.138588,
        45.938791,
        15.152644,
        45.946164
      ]
    },
    {
      "id": 9906,
      "name": "Fuglafjordhur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2448,
        "lng": -6.815
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9907,
      "name": "Becej",
      "names": {
        "zh": "贝切伊"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6241769,
        "lng": 20.0350024
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0349524,
        45.6241269,
        20.0350524,
        45.6242269
      ]
    },
    {
      "id": 9908,
      "name": "Lufilufi",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.87449,
        "lng": -171.59857
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -171.61857,
        -13.89449,
        -171.57857,
        -13.85449
      ]
    },
    {
      "id": 9909,
      "name": "Nalerigu",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 10.52767,
        "lng": -0.36925
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -0.38925,
        10.50767,
        -0.34925,
        10.54767
      ]
    },
    {
      "id": 9910,
      "name": "Sangre Grande",
      "names": {
        "id": "Orang bodoh",
        "ja": "サングレグランデ"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.5869196,
        "lng": -61.1284394
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.1684394,
        10.5469196,
        -61.0884394,
        10.6269196
      ]
    },
    {
      "id": 9911,
      "name": "Famjin",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.524861,
        "lng": -6.8786368
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.9199509,
        61.4988143,
        -6.8292355,
        61.5484148
      ]
    },
    {
      "id": 9912,
      "name": "Kralendijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 12.1558213,
        "lng": -68.2750987
      },
      "country": "Netherlands",
      "importance": 1,
      "bbox": [
        -68.2950987,
        12.1358213,
        -68.2550987,
        12.1758213
      ]
    },
    {
      "id": 9913,
      "name": "Zgornje Gorje",
      "names": {
        "es": "ZGORTJE GORJE"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3801448,
        "lng": 14.0681444
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.0680944,
        46.3800948,
        14.0681944,
        46.3801948
      ]
    },
    {
      "id": 9914,
      "name": "Rogasovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8051661,
        "lng": 16.034675
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0337878,
        46.8025326,
        16.0364724,
        46.808109
      ]
    },
    {
      "id": 9915,
      "name": "Opovo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0487194,
        "lng": 20.4331827
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.4159427,
        45.0226994,
        20.5862749,
        45.0747078
      ]
    },
    {
      "id": 9916,
      "name": "Ribnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.866323,
        "lng": 15.6668074
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.6468074,
        45.846323,
        15.6868074,
        45.886323
      ]
    },
    {
      "id": 9917,
      "name": "Kotor",
      "names": {
        "zh": "科托尔"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.4248515,
        "lng": 18.7710437
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.7572635,
        42.4083969,
        18.7749787,
        42.4324671
      ]
    },
    {
      "id": 9918,
      "name": "Rekovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8625569,
        "lng": 21.0945991
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.0745991,
        43.8425569,
        21.1145991,
        43.8825569
      ]
    },
    {
      "id": 9919,
      "name": "Knic",
      "names": {
        "zh": "克尼奇"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9265587,
        "lng": 20.7198412
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.5431046,
        43.7968982,
        20.9093482,
        44.0737385
      ]
    },
    {
      "id": 9920,
      "name": "Podlehnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3350483,
        "lng": 15.879597
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.8151577,
        46.2754481,
        15.9143222,
        46.3550864
      ]
    },
    {
      "id": 9921,
      "name": "Sodrazica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.76204,
        "lng": 14.635158
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.615696,
        45.758942,
        14.640126,
        45.767257
      ]
    },
    {
      "id": 9922,
      "name": "Nhlangano",
      "names": {
        "zh": "纳兰佳诺"
      },
      "countryCode": "SZ",
      "latLng": {
        "lat": -27.112694,
        "lng": 31.200679
      },
      "country": "Swaziland",
      "importance": 1,
      "bbox": [
        31.160679,
        -27.152694,
        31.240679,
        -27.072694
      ]
    },
    {
      "id": 9923,
      "name": "Nili",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 33.7228439,
        "lng": 66.1445515
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        66.1045515,
        33.6828439,
        66.1845515,
        33.7628439
      ]
    },
    {
      "id": 9924,
      "name": "Vitomarci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.525942,
        "lng": 15.933753
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.913753,
        46.505942,
        15.953753,
        46.545942
      ]
    },
    {
      "id": 9925,
      "name": "Fort Wellington",
      "names": {
        "zh": "惠灵顿堡",
        "ja": "フォートウェリントン",
        "th": "ป้อมเวลลิงตัน",
        "pt": "Forte Wellington"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.39087,
        "lng": -57.60381
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -57.62381,
        6.37087,
        -57.58381,
        6.41087
      ]
    },
    {
      "id": 9926,
      "name": "Samdrup Jongkhar",
      "names": {
        "de": "Samdrupp Jongkhar"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 26.80069,
        "lng": 91.50519
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        91.48519,
        26.78069,
        91.52519,
        26.82069
      ]
    },
    {
      "id": 9927,
      "name": "Osilnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5292418,
        "lng": 14.6988672
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.6850323,
        45.5008894,
        14.7954769,
        45.600738
      ]
    },
    {
      "id": 9928,
      "name": "Tabuk",
      "names": {
        "zh": "塔布克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.4136195,
        "lng": 121.4440137
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        121.2840137,
        17.2536195,
        121.6040137,
        17.5736195
      ]
    },
    {
      "id": 9929,
      "name": "Sarpang",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 26.86395,
        "lng": 90.26745
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        90.24745,
        26.84395,
        90.28745,
        26.88395
      ]
    },
    {
      "id": 9930,
      "name": "Nabilatuk",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.05253,
        "lng": 34.57341
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.55341,
        2.03253,
        34.59341,
        2.07253
      ]
    },
    {
      "id": 9931,
      "name": "Neves",
      "names": {
        "zh": "内维斯"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.3587812,
        "lng": 6.5524561
      },
      "country": "Sao Tome And Principe",
      "importance": 1,
      "bbox": [
        6.5124561,
        0.3187812,
        6.5924561,
        0.3987812
      ]
    },
    {
      "id": 9932,
      "name": "Titel",
      "names": {
        "it": "Titolo"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2043941,
        "lng": 20.3060414
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.2660414,
        45.1643941,
        20.3460414,
        45.2443941
      ]
    },
    {
      "id": 9933,
      "name": "Nova Sintra",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 14.871066,
        "lng": -24.6956479
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -24.7356479,
        14.831066,
        -24.6556479,
        14.911066
      ]
    },
    {
      "id": 9934,
      "name": "Dragash",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.0615806,
        "lng": 20.6533669
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.6200651,
        42.0505251,
        20.6594245,
        42.0743224
      ]
    },
    {
      "id": 9935,
      "name": "Jurovski Dol",
      "names": {
        "pt": "Dol jurovski"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6062793,
        "lng": 15.784106
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.764106,
        46.5862793,
        15.804106,
        46.6262793
      ]
    },
    {
      "id": 9936,
      "name": "Ed Daein",
      "names": {
        "zh": "艾德丹",
        "it": "Ed daae",
        "ja": "エドデアイン"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.4611731,
        "lng": 26.1310939
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        25.9710939,
        11.3011731,
        26.2910939,
        11.6211731
      ]
    },
    {
      "id": 9937,
      "name": "Trashigang",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.3312843,
        "lng": 91.5520668
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        91.5120668,
        27.2912843,
        91.5920668,
        27.3712843
      ]
    },
    {
      "id": 9938,
      "name": "Sedhiou",
      "names": {
        "zh": "塞久"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.70806,
        "lng": -15.55694
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -15.57694,
        12.68806,
        -15.53694,
        12.72806
      ]
    },
    {
      "id": 9939,
      "name": "Pagegiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.1298814,
        "lng": 21.8773628
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        21.8573628,
        55.1098814,
        21.8973628,
        55.1498814
      ]
    },
    {
      "id": 9940,
      "name": "Trnovska Vas",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5182556,
        "lng": 15.8892764
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.8867361,
        46.515714,
        15.8912101,
        46.5199982
      ]
    },
    {
      "id": 9941,
      "name": "Qabala",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.98139,
        "lng": 47.84583
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        47.82583,
        40.96139,
        47.86583,
        41.00139
      ]
    },
    {
      "id": 9942,
      "name": "Soldanesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.8135624,
        "lng": 28.793231
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        28.7648678,
        47.793784,
        28.8146818,
        47.8284484
      ]
    },
    {
      "id": 9943,
      "name": "Pemagatshel",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.03795,
        "lng": 91.40305
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        91.38305,
        27.01795,
        91.42305,
        27.05795
      ]
    },
    {
      "id": 9944,
      "name": "Moravske-Toplice",
      "names": {
        "es": "-Moravske toplice",
        "fr": "Moravske toplice-",
        "zh": "moravske-凯托普利采",
        "it": "Moravske toplice-"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6855002,
        "lng": 16.2234869
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.2234369,
        46.6854502,
        16.2235369,
        46.6855502
      ]
    },
    {
      "id": 9945,
      "name": "Mamuju",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.6756302,
        "lng": 118.8847947
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        118.7247947,
        -2.8356302,
        119.0447947,
        -2.5156302
      ]
    },
    {
      "id": 9946,
      "name": "Igreja",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 40.9806179,
        "lng": -8.4416508
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -8.4616508,
        40.9606179,
        -8.4216508,
        41.0006179
      ]
    },
    {
      "id": 9947,
      "name": "Podvelka",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5889858,
        "lng": 15.3280675
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.2709898,
        46.5112213,
        15.4480753,
        46.6549981
      ]
    },
    {
      "id": 9948,
      "name": "Savalou",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 7.9297324,
        "lng": 1.9780951
      },
      "country": "Benin",
      "importance": 1,
      "bbox": [
        1.6360419,
        7.5411082,
        2.140801,
        8.1922113
      ]
    },
    {
      "id": 9949,
      "name": "Kiruhura",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3250908,
        "lng": 30.873459933568064
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.6075254,
        -0.7122736,
        31.1540101,
        0.0626451
      ]
    },
    {
      "id": 9950,
      "name": "Kuacjok",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 8.30278,
        "lng": 27.98
      },
      "country": "South Sudan",
      "importance": 1,
      "bbox": [
        27.96,
        8.28278,
        28,
        8.32278
      ]
    },
    {
      "id": 9951,
      "name": "Secanj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.366417,
        "lng": 20.7733699
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.7733199,
        45.366367,
        20.7734199,
        45.366467
      ]
    },
    {
      "id": 9952,
      "name": "Vagur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.474201,
        "lng": -6.824869
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.824869,
        61.474201,
        -6.824869,
        61.474201
      ]
    },
    {
      "id": 9953,
      "name": "Tubmanburg",
      "names": {
        "zh": "杜伯曼堡"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.87083,
        "lng": -10.81722
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -10.83722,
        6.85083,
        -10.79722,
        6.89083
      ]
    },
    {
      "id": 9954,
      "name": "Goygol",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5837549,
        "lng": 46.3169375
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.3027327,
        40.5650592,
        46.3810721,
        40.6057852
      ]
    },
    {
      "id": 9955,
      "name": "Hargeysa",
      "names": {
        "zh": "哈尔格萨"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 9.56,
        "lng": 44.065
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        44.006668,
        9.500043,
        44.121448,
        9.606658
      ]
    },
    {
      "id": 9956,
      "name": "Safotulafai",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.67147,
        "lng": -172.17768
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.19768,
        -13.69147,
        -172.15768,
        -13.65147
      ]
    },
    {
      "id": 9957,
      "name": "Qacha's Nek",
      "names": {
        "es": "Nek de Qacha",
        "fr": "Nek de Qacha",
        "zh": "Qacha的Nek",
        "de": "Qachas Nek",
        "id": "Orangku yang nek",
        "ja": "Qachaのネック",
        "pt": "Nek de Qacha"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -30.113327,
        "lng": 28.6810971
      },
      "country": "Lesotho",
      "importance": 1,
      "bbox": [
        28.6410971,
        -30.153327,
        28.7210971,
        -30.073327
      ]
    },
    {
      "id": 9958,
      "name": "Tomaz pri Ormozu",
      "names": {
        "fr": "Tomaz Prix Ormozu",
        "ja": "トマズPri Ormozu",
        "th": "Tomaz Prior Ormozu"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4772893,
        "lng": 16.0845798
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0829431,
        46.4768501,
        16.0863317,
        46.4773003
      ]
    },
    {
      "id": 9959,
      "name": "Stefan Voda",
      "names": {
        "ja": "ステファンボーダ",
        "th": "สเตฟานวอดะ"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.5152681,
        "lng": 29.66432111741821
      },
      "country": "Moldova",
      "importance": 1,
      "bbox": [
        29.6448685,
        46.5005183,
        29.6756173,
        46.5300657
      ]
    },
    {
      "id": 9960,
      "name": "Grad",
      "names": {
        "es": "De posgrado",
        "zh": "毕业生",
        "ja": "卒業生",
        "th": "สำเร็จการศึกษา",
        "pt": "Graduado"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.801894,
        "lng": 16.0919216
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.0502878,
        46.758341,
        16.1395452,
        46.8309751
      ]
    },
    {
      "id": 9961,
      "name": "Matam",
      "names": {
        "zh": "马塔姆"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 15.656563,
        "lng": -13.255916
      },
      "country": "Senegal",
      "importance": 1,
      "bbox": [
        -13.2700515,
        15.6345953,
        -13.2389503,
        15.6749174
      ]
    },
    {
      "id": 9962,
      "name": "Zabari",
      "names": {
        "zh": "扎巴里"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3565446,
        "lng": 21.2148809
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.1295934,
        44.3332335,
        21.2344122,
        44.3820043
      ]
    },
    {
      "id": 9963,
      "name": "Malo Crnice",
      "names": {
        "fr": "Chrnice malo",
        "id": "Crnice malo",
        "ja": "マロのCrnice"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.560257,
        "lng": 21.290565
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.270565,
        44.540257,
        21.310565,
        44.580257
      ]
    },
    {
      "id": 9964,
      "name": "Srbobran",
      "names": {
        "zh": "斯尔博布兰"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.5484758,
        "lng": 19.7911219
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.7052355,
        45.4957866,
        19.8963501,
        45.6944404
      ]
    },
    {
      "id": 9965,
      "name": "Kotido",
      "names": {
        "zh": "科蒂多"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.008143,
        "lng": 34.115341
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.075341,
        2.968143,
        34.155341,
        3.048143
      ]
    },
    {
      "id": 9966,
      "name": "Tsimasham",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.0989,
        "lng": 89.53604
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.51604,
        27.0789,
        89.55604,
        27.1189
      ]
    },
    {
      "id": 9967,
      "name": "Pazin",
      "names": {
        "zh": "巴辛"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2391995,
        "lng": 13.9363313
      },
      "country": "Croatia",
      "importance": 1,
      "bbox": [
        13.8963313,
        45.1991995,
        13.9763313,
        45.2791995
      ]
    },
    {
      "id": 9968,
      "name": "Pala",
      "names": {
        "zh": "波罗",
        "ja": "パーラ",
        "th": "พละ"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.3662802,
        "lng": 14.9097028
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        14.7497028,
        9.2062802,
        15.0697028,
        9.5262802
      ]
    },
    {
      "id": 9969,
      "name": "Shterpce",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.2401249,
        "lng": 21.0253337
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.9853337,
        42.2001249,
        21.0653337,
        42.2801249
      ]
    },
    {
      "id": 9970,
      "name": "Dobje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1348232,
        "lng": 15.400062252912782
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.3630794,
        46.1093635,
        15.437128,
        46.1601973
      ]
    },
    {
      "id": 9971,
      "name": "Nwoya",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.6336998,
        "lng": 32.0004589
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.9804589,
        2.6136998,
        32.0204589,
        2.6536998
      ]
    },
    {
      "id": 9972,
      "name": "Dobrovnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6525292,
        "lng": 16.3478962
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.3453257,
        46.6516082,
        16.3503626,
        46.6535483
      ]
    },
    {
      "id": 9973,
      "name": "Mali Idos",
      "names": {
        "zh": "马里Idos",
        "id": "Idos Mali",
        "ja": "マリダ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.7067012,
        "lng": 19.666121
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        19.5716562,
        45.6635416,
        19.766882,
        45.7646211
      ]
    },
    {
      "id": 9974,
      "name": "Dalandzadgad",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 43.57083,
        "lng": 104.425
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        104.405,
        43.55083,
        104.445,
        43.59083
      ]
    },
    {
      "id": 9975,
      "name": "Bazarak",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.31292,
        "lng": 69.51519
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        69.49519,
        35.29292,
        69.53519,
        35.33292
      ]
    },
    {
      "id": 9976,
      "name": "Zitiste",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.4833996,
        "lng": 20.5519957
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.469895,
        45.4438833,
        20.5965352,
        45.539012
      ]
    },
    {
      "id": 9977,
      "name": "Pader",
      "names": {
        "zh": "帕德尔"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.8792604,
        "lng": 33.085819
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.045819,
        2.8392604,
        33.125819,
        2.9192604
      ]
    },
    {
      "id": 9978,
      "name": "Novi Knezevac",
      "names": {
        "zh": "诺维·克纳扎夫"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 46.0427093,
        "lng": 20.0933532
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0319258,
        45.9828171,
        20.2611686,
        46.1076384
      ]
    },
    {
      "id": 9979,
      "name": "Otuke",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.50038,
        "lng": 33.50065
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.48065,
        2.48038,
        33.52065,
        2.52038
      ]
    },
    {
      "id": 9980,
      "name": "Rio Claro",
      "names": {
        "zh": "里奥克洛",
        "ja": "リオクラロ"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.3063646,
        "lng": -61.1750475
      },
      "country": "Trinidad And Tobago",
      "importance": 1,
      "bbox": [
        -61.2150475,
        10.2663646,
        -61.1350475,
        10.3463646
      ]
    },
    {
      "id": 9981,
      "name": "Davaci",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.2373779,
        "lng": 48.9375291
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.928462,
        41.2313367,
        48.9459789,
        41.2431025
      ]
    },
    {
      "id": 9982,
      "name": "El Meghaier",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.95304,
        "lng": 5.92927
      },
      "country": "Algeria",
      "importance": 1,
      "bbox": [
        5.90927,
        33.93304,
        5.94927,
        33.97304
      ]
    },
    {
      "id": 9983,
      "name": "Qivraq",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.39939,
        "lng": 45.11513
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.09513,
        39.37939,
        45.13513,
        39.41939
      ]
    },
    {
      "id": 9984,
      "name": "Nordhragota",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.199,
        "lng": -6.7432
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9985,
      "name": "Zubin Potok",
      "names": {
        "it": "Zubin polok"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.9147673,
        "lng": 20.6897381
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.6497381,
        42.8747673,
        20.7297381,
        42.9547673
      ]
    },
    {
      "id": 9986,
      "name": "Petnjica",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.90889,
        "lng": 19.96444
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.94444,
        42.88889,
        19.98444,
        42.92889
      ]
    },
    {
      "id": 9987,
      "name": "Leava",
      "names": {},
      "countryCode": "WF",
      "latLng": {
        "lat": -14.29333,
        "lng": -178.15833
      },
      "country": "Wallis And Futuna",
      "importance": 1
    },
    {
      "id": 9988,
      "name": "Buka",
      "names": {
        "zh": "布卡"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.4324184,
        "lng": 154.6701836
      },
      "country": "Papua New Guinea",
      "importance": 1,
      "bbox": [
        154.5101836,
        -5.5924184,
        154.8301836,
        -5.2724184
      ]
    },
    {
      "id": 9989,
      "name": "Anouvong",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 18.8976936,
        "lng": 103.0924793
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        102.5801669,
        18.6528001,
        103.5180221,
        19.1271218
      ]
    },
    {
      "id": 9990,
      "name": "Mislinja",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.443694,
        "lng": 15.196209
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.189061,
        46.439684,
        15.207401,
        46.448081
      ]
    },
    {
      "id": 9991,
      "name": "Novi Becej",
      "names": {
        "it": "Novi Bechej",
        "pt": "Novi Bechej"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.5952571,
        "lng": 20.1343581
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.0290267,
        45.4754875,
        20.3476704,
        45.7132334
      ]
    },
    {
      "id": 9992,
      "name": "Danilovgrad",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.5591576,
        "lng": 19.1136307
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.1086307,
        42.5541576,
        19.1186307,
        42.5641576
      ]
    },
    {
      "id": 9993,
      "name": "Ar Rustaq",
      "names": {
        "es": "Ar ridtaq",
        "zh": "arrustaq",
        "ja": "rustaq",
        "pt": "Ar rustraq"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 23.39083,
        "lng": 57.42444
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        57.40444,
        23.37083,
        57.44444,
        23.41083
      ]
    },
    {
      "id": 9994,
      "name": "Amuru",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.814198,
        "lng": 31.9371868
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.8971868,
        2.774198,
        31.9771868,
        2.854198
      ]
    },
    {
      "id": 9995,
      "name": "Samamea",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.93375,
        "lng": -171.53122
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -171.55122,
        -13.95375,
        -171.51122,
        -13.91375
      ]
    },
    {
      "id": 9996,
      "name": "Nakapiripirit",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.8529835,
        "lng": 34.7211421
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        34.7011421,
        1.8329835,
        34.7411421,
        1.8729835
      ]
    },
    {
      "id": 9997,
      "name": "Satupa`itea",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.76593,
        "lng": -172.32686
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.34686,
        -13.78593,
        -172.30686,
        -13.74593
      ]
    },
    {
      "id": 9998,
      "name": "Haciqabul",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.04084,
        "lng": 48.94451
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.92451,
        40.02084,
        48.96451,
        40.06084
      ]
    },
    {
      "id": 9999,
      "name": "Makedonska Kamenica",
      "names": {
        "ja": "マケドンカカメニカ"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0219096,
        "lng": 22.588719
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        22.5499369,
        41.9978694,
        22.6020098,
        42.0472849
      ]
    },
    {
      "id": 10000,
      "name": "Golubac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6531108,
        "lng": 21.6305205
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.6061798,
        44.6055109,
        21.7494093,
        44.6677683
      ]
    },
    {
      "id": 10001,
      "name": "Buabidi",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 8.47457,
        "lng": -81.69827
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -81.71827,
        8.45457,
        -81.67827,
        8.49457
      ]
    },
    {
      "id": 10002,
      "name": "Kalbacar",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.104741,
        "lng": 46.200753
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        45.601652,
        39.791715,
        46.735946,
        40.31851
      ]
    },
    {
      "id": 10003,
      "name": "Niksic",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.7739388,
        "lng": 18.9488097
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.8923637,
        42.7282677,
        19.0167017,
        42.8226746
      ]
    },
    {
      "id": 10004,
      "name": "Hrib-Loski Potok",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.701705,
        "lng": 14.591737
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.571737,
        45.681705,
        14.611737,
        45.721705
      ]
    },
    {
      "id": 10005,
      "name": "Nova Crnja",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6678337,
        "lng": 20.6070532
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        20.5148611,
        45.6138951,
        20.6317413,
        45.7040255
      ]
    },
    {
      "id": 10006,
      "name": "Majdanpek",
      "names": {
        "zh": "马伊丹佩克"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3819166,
        "lng": 21.8892784
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.8842784,
        44.3769166,
        21.8942784,
        44.3869166
      ]
    },
    {
      "id": 10007,
      "name": "Asau",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.5191306,
        "lng": -172.637108
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.6478197,
        -13.5250371,
        -172.6310126,
        -13.51448
      ]
    },
    {
      "id": 10008,
      "name": "Leposaviq",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 43.1019523,
        "lng": 20.8010532
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        20.7610532,
        43.0619523,
        20.8410532,
        43.1419523
      ]
    },
    {
      "id": 10009,
      "name": "Djibloho",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.5981375500000001,
        "lng": 10.797066715346432
      },
      "country": "Equatorial Guinea",
      "importance": 1,
      "bbox": [
        10.7025718,
        1.4833748,
        10.8826017,
        1.7130969
      ]
    },
    {
      "id": 10010,
      "name": "Gracanice",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.5999713,
        "lng": 21.1922853
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.1578157,
        42.5804294,
        21.2302119,
        42.6239414
      ]
    },
    {
      "id": 10011,
      "name": "Sur",
      "names": {
        "zh": "畔",
        "ja": "シュール",
        "th": "ซูร์"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 22.5694263,
        "lng": 59.5297203
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        59.3697203,
        22.4094263,
        59.6897203,
        22.7294263
      ]
    },
    {
      "id": 10012,
      "name": "Safotu",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.45132,
        "lng": -172.40176
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.42176,
        -13.47132,
        -172.38176,
        -13.43132
      ]
    },
    {
      "id": 10013,
      "name": "Vailoa",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.7547732,
        "lng": -172.3078785
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.3278785,
        -13.7747732,
        -172.2878785,
        -13.7347732
      ]
    },
    {
      "id": 10014,
      "name": "Ribeira Brava",
      "names": {
        "id": "Brava ribeira"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.615728,
        "lng": -24.2982543
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -24.3382543,
        16.575728,
        -24.2582543,
        16.655728
      ]
    },
    {
      "id": 10015,
      "name": "Novoberde",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.607858,
        "lng": 21.4385214
      },
      "country": "Kosovo",
      "importance": 1,
      "bbox": [
        21.4185214,
        42.587858,
        21.4585214,
        42.627858
      ]
    },
    {
      "id": 10016,
      "name": "Kobilje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6849477,
        "lng": 16.3922898
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.3528206,
        46.6584771,
        16.4286336,
        46.70423
      ]
    },
    {
      "id": 10017,
      "name": "Qobustan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.512314,
        "lng": 48.961432
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.706056,
        40.309305,
        49.308841,
        40.780275
      ]
    },
    {
      "id": 10018,
      "name": "Ibra'",
      "names": {
        "es": "Ibra '",
        "fr": "Ibra '",
        "de": "Ibra '",
        "it": "Ibra \"",
        "id": "BPPN '",
        "ja": "ibra '",
        "th": "ibra '",
        "pt": "Ibra '"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 22.69947565,
        "lng": 58.54121825366164
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        58.5156217,
        22.6721506,
        58.5576809,
        22.7270714
      ]
    },
    {
      "id": 10019,
      "name": "Kranjska Gora",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.484396,
        "lng": 13.785001
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        13.767927,
        46.466598,
        13.807927,
        46.506598
      ]
    },
    {
      "id": 10020,
      "name": "Massakory",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 12.996486,
        "lng": 15.7349101
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        15.5749101,
        12.836486,
        15.8949101,
        13.156486
      ]
    },
    {
      "id": 10021,
      "name": "Zetale",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2754033,
        "lng": 15.7932593
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        15.7493182,
        46.2543958,
        15.8683718,
        46.3220975
      ]
    },
    {
      "id": 10022,
      "name": "Rustavi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.3517488,
        "lng": 43.8215837
      },
      "country": "Georgia",
      "importance": 1,
      "bbox": [
        43.8215337,
        42.3516988,
        43.8216337,
        42.3517988
      ]
    },
    {
      "id": 10023,
      "name": "Sorvagur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0716758,
        "lng": -7.309821
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -7.4136432,
        62.0234411,
        -7.2227471,
        62.1034896
      ]
    },
    {
      "id": 10024,
      "name": "Buba",
      "names": {
        "zh": "布巴"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.591979,
        "lng": -14.994788
      },
      "country": "Guinea-Bissau",
      "importance": 1,
      "bbox": [
        -15.1272945,
        11.4055109,
        -14.8236115,
        11.7956231
      ]
    },
    {
      "id": 10025,
      "name": "Gusinje",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.56194,
        "lng": 19.83389
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.81389,
        42.54194,
        19.85389,
        42.58194
      ]
    },
    {
      "id": 10026,
      "name": "Al Hazm",
      "names": {
        "ja": "アルハーズ",
        "th": "อัลเฮซ",
        "pt": "Alcova"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 16.163372,
        "lng": 44.776557
      },
      "country": "Yemen",
      "importance": 1,
      "bbox": [
        44.736557,
        16.123372,
        44.816557,
        16.203372
      ]
    },
    {
      "id": 10027,
      "name": "Xocavand",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.766109,
        "lng": 47.032574
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        46.684992,
        39.395641,
        47.349896,
        39.897906
      ]
    },
    {
      "id": 10028,
      "name": "Resen",
      "names": {
        "zh": "雷森"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.0900531,
        "lng": 21.0130599
      },
      "country": "Macedonia",
      "importance": 1,
      "bbox": [
        20.9880119,
        41.0611926,
        21.0596962,
        41.1020851
      ]
    },
    {
      "id": 10029,
      "name": "Burco",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 9.527771,
        "lng": 45.5379777
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        45.3779777,
        9.367771,
        45.6979777,
        9.687771
      ]
    },
    {
      "id": 10030,
      "name": "Cova Figueira",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 14.886741,
        "lng": -24.294713
      },
      "country": "Cabo Verde",
      "importance": 1,
      "bbox": [
        -24.2971115,
        14.8809066,
        -24.2922433,
        14.8917054
      ]
    },
    {
      "id": 10031,
      "name": "Barentu",
      "names": {
        "zh": "巴伦图"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.1042101,
        "lng": 37.59094124511921
      },
      "country": "Eritrea",
      "importance": 1,
      "bbox": [
        37.5674003,
        15.0906391,
        37.614928,
        15.1181367
      ]
    },
    {
      "id": 10032,
      "name": "Kaisiadorys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.8630614,
        "lng": 24.4622719
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        24.4622219,
        54.8630114,
        24.4623219,
        54.8631114
      ]
    },
    {
      "id": 10033,
      "name": "Arta",
      "names": {
        "zh": "阿尔塔",
        "ja": "アルタ"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.5219459,
        "lng": 42.8393629
      },
      "country": "Djibouti",
      "importance": 1,
      "bbox": [
        42.7993629,
        11.4819459,
        42.8793629,
        11.5619459
      ]
    },
    {
      "id": 10034,
      "name": "Oyrarbakki",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2070435,
        "lng": -6.9985408
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.9985908,
        62.2069935,
        -6.9984908,
        62.2070935
      ]
    },
    {
      "id": 10035,
      "name": "Eidhi",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 33.6354707,
        "lng": 73.0661308
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        73.0660808,
        33.6354207,
        73.0661808,
        33.6355207
      ]
    },
    {
      "id": 10036,
      "name": "Salovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.823902,
        "lng": 16.290714
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.277379,
        46.821221,
        16.298696,
        46.825324
      ]
    },
    {
      "id": 10037,
      "name": "Pili",
      "names": {
        "zh": "菌毛",
        "ja": "線毛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5561041,
        "lng": 123.2741192
      },
      "country": "Philippines",
      "importance": 1,
      "bbox": [
        123.2341192,
        13.5161041,
        123.3141192,
        13.5961041
      ]
    },
    {
      "id": 10038,
      "name": "Sao Joao dos Angolares",
      "names": {
        "zh": "圣若浩Dos Angolares",
        "id": "Sao Joao dos Anglares",
        "ja": "SAO JOAO DOSアンポラリー",
        "pt": "São João dos Angolaares"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.13333,
        "lng": 6.65
      },
      "country": "Sao Tome And Principe",
      "importance": 1,
      "bbox": [
        6.63,
        0.11333,
        6.67,
        0.15333
      ]
    },
    {
      "id": 10039,
      "name": "Vestmanna",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.154979,
        "lng": -7.1659
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -7.1659,
        62.154979,
        -7.1659,
        62.154979
      ]
    },
    {
      "id": 10040,
      "name": "Gaigirgordub",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.5589024,
        "lng": -78.9486169
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -78.9486669,
        9.5588524,
        -78.9485669,
        9.5589524
      ]
    },
    {
      "id": 10041,
      "name": "Kyankwanzi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1989434,
        "lng": 31.8053351
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        31.7853351,
        1.1789434,
        31.8253351,
        1.2189434
      ]
    },
    {
      "id": 10042,
      "name": "Raseiniai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.381146,
        "lng": 23.127711
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        23.099829,
        55.363985,
        23.144384,
        55.395254
      ]
    },
    {
      "id": 10043,
      "name": "Trashi Yangtse",
      "names": {
        "de": "Trashi Ytttse"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.6116,
        "lng": 91.498
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        91.478,
        27.5916,
        91.518,
        27.6316
      ]
    },
    {
      "id": 10044,
      "name": "Kone",
      "names": {
        "zh": "通力",
        "th": "โคน"
      },
      "countryCode": "NC",
      "latLng": {
        "lat": -21.0608439,
        "lng": 164.8619054
      },
      "country": "New Caledonia",
      "importance": 1,
      "bbox": [
        164.7644265,
        -21.1153853,
        165.073857,
        -20.9413864
      ]
    },
    {
      "id": 10045,
      "name": "Kvivik",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.11865,
        "lng": -7.0737
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -7.0937,
        62.09865,
        -7.0537,
        62.13865
      ]
    },
    {
      "id": 10046,
      "name": "Phon-Hong",
      "names": {
        "zh": "响度-香港",
        "ja": "ホン・香港",
        "th": "พล-ฮ่องกง",
        "pt": "Telefone-Hong"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 18.49565,
        "lng": 102.41421
      },
      "country": "Laos",
      "importance": 1,
      "bbox": [
        102.39421,
        18.47565,
        102.43421,
        18.51565
      ]
    },
    {
      "id": 10047,
      "name": "Sumba",
      "names": {
        "zh": "尊巴"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 61.4039284,
        "lng": -6.7133445
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.7675718,
        61.3379074,
        -6.6619059,
        61.4408972
      ]
    },
    {
      "id": 10048,
      "name": "Sandavagur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0539859,
        "lng": -7.1525739
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -7.3128184,
        62.0429958,
        -7.0601505,
        62.1597144
      ]
    },
    {
      "id": 10049,
      "name": "Trgoviste",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.364,
        "lng": 22.0831315
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        22.0631315,
        42.344,
        22.1031315,
        42.384
      ]
    },
    {
      "id": 10050,
      "name": "Solcava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4188417,
        "lng": 14.6940771
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.6933731,
        46.4187498,
        14.6952358,
        46.4195587
      ]
    },
    {
      "id": 10051,
      "name": "Zalingei",
      "names": {
        "zh": "扎林盖"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.9096,
        "lng": 23.47406
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        23.45406,
        12.8896,
        23.49406,
        12.9296
      ]
    },
    {
      "id": 10052,
      "name": "Sola",
      "names": {
        "zh": "索拉",
        "ja": "クサネム",
        "th": "รัชทายาท"
      },
      "countryCode": "VU",
      "latLng": {
        "lat": 37.5545547,
        "lng": 126.9707793
      },
      "country": "Vanuatu",
      "importance": 1,
      "bbox": [
        126.9657793,
        37.5495547,
        126.9757793,
        37.5595547
      ]
    },
    {
      "id": 10053,
      "name": "Hodos",
      "names": {
        "zh": "霍多什"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8201655,
        "lng": 16.3291302
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        16.3241302,
        46.8151655,
        16.3341302,
        46.8251655
      ]
    },
    {
      "id": 10054,
      "name": "Hov",
      "names": {
        "zh": "霍夫"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 61.5067716,
        "lng": -6.7623644
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.8451267,
        61.5023428,
        -6.7241156,
        61.5334206
      ]
    },
    {
      "id": 10055,
      "name": "Semera",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 11.7923098,
        "lng": 41.0089155
      },
      "country": "Ethiopia",
      "importance": 1,
      "bbox": [
        40.9689155,
        11.7523098,
        41.0489155,
        11.8323098
      ]
    },
    {
      "id": 10056,
      "name": "Anew",
      "names": {
        "es": "De nuevo",
        "fr": "Nouveau",
        "zh": "重新",
        "de": "Neu",
        "it": "Di nuovo",
        "id": "Lagi",
        "ja": "新たに",
        "th": "อีกครั้ง",
        "pt": "De novo"
      },
      "countryCode": "TM",
      "latLng": {
        "lat": 37.88757,
        "lng": 58.516
      },
      "country": "Turkmenistan",
      "importance": 1,
      "bbox": [
        58.496,
        37.86757,
        58.536,
        37.90757
      ]
    },
    {
      "id": 10057,
      "name": "Masunga",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -20.62455,
        "lng": 27.44875
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        27.42875,
        -20.64455,
        27.46875,
        -20.60455
      ]
    },
    {
      "id": 10058,
      "name": "Kostel",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5081362,
        "lng": 14.9104871
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.7871789,
        45.4593223,
        14.9623963,
        45.5400587
      ]
    },
    {
      "id": 10059,
      "name": "Tanjung Selor",
      "names": {
        "ja": "タンジュンセロール"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 2.8375,
        "lng": 117.36528
      },
      "country": "Indonesia",
      "importance": 1,
      "bbox": [
        117.36528,
        2.8375,
        117.36528,
        2.8375
      ]
    },
    {
      "id": 10060,
      "name": "Vidhareidhi",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.36252,
        "lng": -6.54106
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.56106,
        62.34252,
        -6.52106,
        62.38252
      ]
    },
    {
      "id": 10061,
      "name": "Saleaula",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.44894,
        "lng": -172.33521
      },
      "country": "Samoa",
      "importance": 1,
      "bbox": [
        -172.35521,
        -13.46894,
        -172.31521,
        -13.42894
      ]
    },
    {
      "id": 10062,
      "name": "Xizi",
      "names": {
        "zh": "西子"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.76469015,
        "lng": 49.2458431116153
      },
      "country": "Azerbaijan",
      "importance": 1,
      "bbox": [
        48.7913481,
        40.5142512,
        49.7912404,
        41.0223642
      ]
    },
    {
      "id": 10063,
      "name": "Trongsa",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.507662,
        "lng": 90.494728
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        90.474728,
        27.487662,
        90.514728,
        27.527662
      ]
    },
    {
      "id": 10064,
      "name": "Damongo",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 9.08337,
        "lng": -1.81819
      },
      "country": "Ghana",
      "importance": 1,
      "bbox": [
        -1.83819,
        9.06337,
        -1.79819,
        9.10337
      ]
    },
    {
      "id": 10065,
      "name": "Zhemgang",
      "names": {
        "th": "เซมกัง"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.21689,
        "lng": 90.65794
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        90.63794,
        27.19689,
        90.67794,
        27.23689
      ]
    },
    {
      "id": 10066,
      "name": "Tigoa",
      "names": {},
      "countryCode": "SB",
      "latLng": {
        "lat": -11.5521,
        "lng": 160.06514
      },
      "country": "Solomon Islands",
      "importance": 1,
      "bbox": [
        160.04514,
        -11.5721,
        160.08514,
        -11.5321
      ]
    },
    {
      "id": 10067,
      "name": "Zagubica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1973536,
        "lng": 21.7856065
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7423514,
        44.0824986,
        21.9665844,
        44.2282864
      ]
    },
    {
      "id": 10068,
      "name": "Sandur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.8348155,
        "lng": -6.8175826
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.9222703,
        61.8152851,
        -6.7483232,
        61.8988302
      ]
    },
    {
      "id": 10069,
      "name": "El Fula",
      "names": {
        "zh": "埃尔福拉",
        "it": "El foro",
        "ja": "エルフラ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.7104852,
        "lng": 28.3448982
      },
      "country": "Sudan",
      "importance": 1,
      "bbox": [
        28.3271757,
        11.695886,
        28.3631495,
        11.7327494
      ]
    },
    {
      "id": 10070,
      "name": "Ignalina",
      "names": {
        "zh": "伊格纳利纳"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.3398975,
        "lng": 26.1614043
      },
      "country": "Lithuania",
      "importance": 1,
      "bbox": [
        26.1424687,
        55.323172,
        26.190993,
        55.3485174
      ]
    },
    {
      "id": 10071,
      "name": "Parun",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.420644,
        "lng": 70.922612
      },
      "country": "Afghanistan",
      "importance": 1,
      "bbox": [
        70.882612,
        35.380644,
        70.962612,
        35.460644
      ]
    },
    {
      "id": 10072,
      "name": "Lupane",
      "names": {},
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.93149,
        "lng": 27.80696
      },
      "country": "Zimbabwe",
      "importance": 1,
      "bbox": [
        27.78696,
        -18.95149,
        27.82696,
        -18.91149
      ]
    },
    {
      "id": 10073,
      "name": "Fish Town",
      "names": {
        "es": "Pueblo de peces",
        "fr": "Ville de pêche",
        "zh": "鱼镇",
        "de": "Fischstadt",
        "it": "Città di pesci",
        "id": "Kota ikan",
        "ja": "魚の町",
        "th": "เมืองปลา",
        "pt": "Cidade de peixe"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 5.197873,
        "lng": -7.8759933
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -7.9159933,
        5.157873,
        -7.8359933,
        5.237873
      ]
    },
    {
      "id": 10074,
      "name": "Loango",
      "names": {
        "zh": "卢安果"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.656168,
        "lng": 11.8115049
      },
      "country": "Congo (Brazzaville)",
      "importance": 1,
      "bbox": [
        11.7715049,
        -4.696168,
        11.8515049,
        -4.616168
      ]
    },
    {
      "id": 10075,
      "name": "Bopolu",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 7.06667,
        "lng": -10.4875
      },
      "country": "Liberia",
      "importance": 1,
      "bbox": [
        -10.5075,
        7.04667,
        -10.4675,
        7.08667
      ]
    },
    {
      "id": 10076,
      "name": "Amdjarass",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 16.0662879,
        "lng": 22.8350448
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        22.6750448,
        15.9062879,
        22.9950448,
        16.2262879
      ]
    },
    {
      "id": 10077,
      "name": "Laascaanood",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 8.474261,
        "lng": 47.355194
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        47.315194,
        8.434261,
        47.395194,
        8.514261
      ]
    },
    {
      "id": 10078,
      "name": "Ntoroko",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.04111,
        "lng": 30.48111
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        30.46111,
        1.02111,
        30.50111,
        1.06111
      ]
    },
    {
      "id": 10079,
      "name": "Kolasin",
      "names": {
        "zh": "库拉辛",
        "th": "โกลาสิน"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.82229,
        "lng": 19.51653
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.49653,
        42.80229,
        19.53653,
        42.84229
      ]
    },
    {
      "id": 10080,
      "name": "Husavik",
      "names": {
        "zh": "胡萨维克"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 61.8096495,
        "lng": -6.6794956
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.744687,
        61.7918543,
        -6.6547925,
        61.821309
      ]
    },
    {
      "id": 10081,
      "name": "Jakar",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.5496387,
        "lng": 90.7527351
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        90.7127351,
        27.5096387,
        90.7927351,
        27.5896387
      ]
    },
    {
      "id": 10082,
      "name": "Georgetown",
      "names": {
        "zh": "乔治敦",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์"
      },
      "countryCode": "SH",
      "latLng": {
        "lat": 6.8137426,
        "lng": -58.1624465
      },
      "country": "Saint Helena, Ascension, And Tristan Da Cunha",
      "importance": 1,
      "bbox": [
        -58.1747151,
        6.7670936,
        -58.1052699,
        6.8275526
      ]
    },
    {
      "id": 10083,
      "name": "Lhuentse",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.66787,
        "lng": 91.18393
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        91.16393,
        27.64787,
        91.20393,
        27.68787
      ]
    },
    {
      "id": 10084,
      "name": "Ceerigaabo",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 10.61616,
        "lng": 47.36795
      },
      "country": "Somalia",
      "importance": 1,
      "bbox": [
        47.34795,
        10.59616,
        47.38795,
        10.63616
      ]
    },
    {
      "id": 10085,
      "name": "Kunoy",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2917787,
        "lng": -6.6687901
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.7089477,
        62.2503126,
        -6.6104048,
        62.3679699
      ]
    },
    {
      "id": 10086,
      "name": "Skalavik",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.8297213,
        "lng": -6.6658461
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.7893706,
        61.8132924,
        -6.6334698,
        61.8779063
      ]
    },
    {
      "id": 10087,
      "name": "Daga",
      "names": {
        "zh": "达加"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.0035943,
        "lng": 89.9092752
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.2092752,
        26.3035943,
        90.6092752,
        27.7035943
      ]
    },
    {
      "id": 10088,
      "name": "We",
      "names": {
        "es": "NOSOTROS",
        "fr": "Nous",
        "zh": "我们",
        "de": "Wir",
        "it": "Noi",
        "id": "Kita",
        "ja": "私達",
        "th": "เรา",
        "pt": "NÓS"
      },
      "countryCode": "NC",
      "latLng": {
        "lat": -20.9,
        "lng": 167.2667
      },
      "country": "New Caledonia",
      "importance": 1
    },
    {
      "id": 10089,
      "name": "Kirkja",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.3182463,
        "lng": -6.316458
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.3348712,
        62.3163835,
        -6.2882791,
        62.3545347
      ]
    },
    {
      "id": 10090,
      "name": "Sowa Town",
      "names": {
        "es": "Ciudad de Sowa",
        "fr": "Ville de Sowa",
        "zh": "Sowa镇",
        "it": "Città di sowa",
        "id": "Kota Sowa",
        "ja": "ソウラの町",
        "th": "เมืองโซวา",
        "pt": "Cidade de sowa"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -20.563596,
        "lng": 26.221489
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        26.1487235,
        -20.5886869,
        26.261352,
        -20.454348
      ]
    },
    {
      "id": 10091,
      "name": "Sieyik",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.3832,
        "lng": -82.6521
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 10092,
      "name": "Zgornje Jezersko",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3935401,
        "lng": 14.4978789
      },
      "country": "Slovenia",
      "importance": 1,
      "bbox": [
        14.4778789,
        46.3735401,
        14.5178789,
        46.4135401
      ]
    },
    {
      "id": 10093,
      "name": "Awbari",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 26.5909391,
        "lng": 12.7695658
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        12.6095658,
        26.4309391,
        12.9295658,
        26.7509391
      ]
    },
    {
      "id": 10094,
      "name": "Choyr",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 46.36111,
        "lng": 108.36111
      },
      "country": "Mongolia",
      "importance": 1,
      "bbox": [
        108.34111,
        46.34111,
        108.38111,
        46.38111
      ]
    },
    {
      "id": 10095,
      "name": "Savnik",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.95639,
        "lng": 19.09667
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        19.07667,
        42.93639,
        19.11667,
        42.97639
      ]
    },
    {
      "id": 10096,
      "name": "Pluzine",
      "names": {
        "zh": "普卢日内"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 43.1562282,
        "lng": 18.8452248
      },
      "country": "Montenegro",
      "importance": 1,
      "bbox": [
        18.8052248,
        43.1162282,
        18.8852248,
        43.1962282
      ]
    },
    {
      "id": 10097,
      "name": "Edinburgh of the Seven Seas",
      "names": {
        "es": "Edimburgo de los siete mares",
        "fr": "Édimbourg des Seven Seas",
        "zh": "七海的爱丁堡",
        "de": "Edinburgh der sieben Meere",
        "it": "Edimburgo dei sette mari",
        "id": "Edinburgh dari Seven Seas",
        "ja": "セブンの海のエジンバラ",
        "th": "เอดินบะระของทะเลทั้งเจ็ด",
        "pt": "Edimburgo dos sete mares"
      },
      "countryCode": "SH",
      "latLng": {
        "lat": -37.0675894,
        "lng": -12.3108303
      },
      "country": "Saint Helena, Ascension, And Tristan Da Cunha",
      "importance": 1,
      "bbox": [
        -12.3308303,
        -37.0875894,
        -12.2908303,
        -37.0475894
      ]
    },
    {
      "id": 10098,
      "name": "Skuvoy",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.7706823,
        "lng": -6.8066251
      },
      "country": "Faroe Islands",
      "importance": 1,
      "bbox": [
        -6.8592155,
        61.7464771,
        -6.789708,
        61.7873901
      ]
    },
    {
      "id": 10099,
      "name": "Ntchisi",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -13.3576182,
        "lng": 33.9193063
      },
      "country": "Malawi",
      "importance": 1,
      "bbox": [
        33.8793063,
        -13.3976182,
        33.9593063,
        -13.3176182
      ]
    },
    {
      "id": 10100,
      "name": "Gasa",
      "names": {
        "zh": "加萨"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.9190875,
        "lng": 89.7875318
      },
      "country": "Bhutan",
      "importance": 1,
      "bbox": [
        89.4511541,
        27.7226715,
        90.455395,
        28.2474194
      ]
    },
    {
      "id": 10101,
      "name": "Jwaneng",
      "names": {
        "zh": "吉瓦宁"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.60166,
        "lng": 24.7281
      },
      "country": "Botswana",
      "importance": 1,
      "bbox": [
        24.7081,
        -24.62166,
        24.7481,
        -24.58166
      ]
    },
    {
      "id": 10102,
      "name": "Hayma'",
      "names": {
        "es": "Hayma '",
        "fr": "Hayma '",
        "de": "Hayma '",
        "it": "Hayma '",
        "id": "Hayma '",
        "ja": "hayma '",
        "th": "hayma '",
        "pt": "Hayma '"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 19.95931,
        "lng": 56.27575
      },
      "country": "Oman",
      "importance": 1,
      "bbox": [
        56.25575,
        19.93931,
        56.29575,
        19.97931
      ]
    },
    {
      "id": 10103,
      "name": "Idri",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 27.4457392,
        "lng": 13.0491001
      },
      "country": "Libya",
      "importance": 1,
      "bbox": [
        13.0091001,
        27.4057392,
        13.0891001,
        27.4857392
      ]
    },
    {
      "id": 10104,
      "name": "Mahdia",
      "names": {
        "zh": "马赫迪耶",
        "th": "มาห์"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 5.2820588,
        "lng": -59.1495337
      },
      "country": "Guyana",
      "importance": 1,
      "bbox": [
        -59.3095337,
        5.1220588,
        -58.9895337,
        5.4420588
      ]
    },
    {
      "id": 10105,
      "name": "Union Choco",
      "names": {
        "zh": "联盟Choco",
        "it": "Unione Choco",
        "ja": "ユニオンチョコ",
        "th": "ยูเนียนช็อคโก"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.08404,
        "lng": -77.53507
      },
      "country": "Panama",
      "importance": 1,
      "bbox": [
        -77.55507,
        8.06404,
        -77.51507,
        8.10404
      ]
    },
    {
      "id": 10106,
      "name": "Bardai",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 21.35494,
        "lng": 17.00121
      },
      "country": "Chad",
      "importance": 1,
      "bbox": [
        16.98121,
        21.33494,
        17.02121,
        21.37494
      ]
    },
    {
      "id": 10107,
      "name": "Presevo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.3095217,
        "lng": 21.6490102
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.6048017,
        42.2893141,
        21.7035262,
        42.3423175
      ]
    },
    {
      "id": 10108,
      "name": "Bujanovac",
      "names": {
        "zh": "布亚诺瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.4598455,
        "lng": 21.7671276
      },
      "country": "Serbia",
      "importance": 1,
      "bbox": [
        21.7271276,
        42.4198455,
        21.8071276,
        42.4998455
      ]
    },
    {
      "id": 10109,
      "name": "Kitamilo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.22215,
        "lng": 33.20608
      },
      "country": "Uganda",
      "importance": 1,
      "bbox": [
        33.18608,
        0.20215,
        33.22608,
        0.24215
      ]
    },
    {
      "id": 10110,
      "name": "Xiongzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9786266,
        "lng": 116.0727682
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.0327682,
        38.9386266,
        116.1127682,
        39.0186266
      ]
    },
    {
      "id": 10111,
      "name": "Udine",
      "names": {
        "zh": "乌迪内",
        "ja": "ウディネ",
        "th": "อูดีเน"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.0634632,
        "lng": 13.2358377
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.1844828,
        46.0017067,
        13.2944831,
        46.1171089
      ]
    },
    {
      "id": 10112,
      "name": "Kalisz",
      "names": {
        "zh": "卡利什"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.759849,
        "lng": 18.084073
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.995035,
        51.700778,
        18.157081,
        51.793859
      ]
    },
    {
      "id": 10113,
      "name": "Legnica",
      "names": {
        "zh": "莱格尼察"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.2141585,
        "lng": 16.1693534
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.1693034,
        51.2141085,
        16.1694034,
        51.2142085
      ]
    },
    {
      "id": 10114,
      "name": "Izumisano",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4111901,
        "lng": 135.3169821
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.3119821,
        34.4061901,
        135.3219821,
        34.4161901
      ]
    },
    {
      "id": 10115,
      "name": "Wakefield",
      "names": {
        "zh": "韦克菲尔德",
        "ja": "ウェークフィールド",
        "th": "เวก"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6829541,
        "lng": -1.4967286
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.6248982,
        53.5753489,
        -1.1988145,
        53.7418108
      ]
    },
    {
      "id": 10116,
      "name": "Pouytenga",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.25162,
        "lng": -0.42565
      },
      "country": "Burkina Faso",
      "importance": 3,
      "bbox": [
        -0.44565,
        12.23162,
        -0.40565,
        12.27162
      ]
    },
    {
      "id": 10117,
      "name": "Xiegang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9641276,
        "lng": 114.1436994
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.0768407,
        22.8833161,
        114.2552753,
        23.0043508
      ]
    },
    {
      "id": 10118,
      "name": "Kani",
      "names": {
        "zh": "卡尼",
        "ja": "可児"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4261093,
        "lng": 137.0613166
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.97819,
        35.3647298,
        137.160383,
        35.465144
      ]
    },
    {
      "id": 10119,
      "name": "Debre Zeyit",
      "names": {
        "es": "Debe Zeyit",
        "de": "Dekre Zeyit"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 8.75,
        "lng": 38.98333
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        38.96333,
        8.73,
        39.00333,
        8.77
      ]
    },
    {
      "id": 10120,
      "name": "Roquetas de Mar",
      "names": {
        "ja": "ロケータスデマー"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7630203,
        "lng": -2.6115886
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -2.6645061,
        36.697857,
        -2.5518514,
        36.8566415
      ]
    },
    {
      "id": 10121,
      "name": "Chikusei",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3051944,
        "lng": 139.9790903
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.888211,
        36.198378,
        140.068052,
        36.378549
      ]
    },
    {
      "id": 10122,
      "name": "Andria",
      "names": {
        "zh": "安德里亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2275731,
        "lng": 16.2954806
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        16.0840701,
        41.0085914,
        16.3635109,
        41.2714549
      ]
    },
    {
      "id": 10123,
      "name": "Tinsukia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.4879722,
        "lng": 95.3601855
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        95.3201855,
        27.4479722,
        95.4001855,
        27.5279722
      ]
    },
    {
      "id": 10124,
      "name": "Ra's Gharib",
      "names": {
        "es": "Gharib de ra",
        "fr": "Ra's Ghareib",
        "zh": "ra的gharib",
        "it": "La Gharib di Ra",
        "ja": "RAのガリブ",
        "th": "เกียริม",
        "pt": "Gharib ra"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.3508477,
        "lng": 33.0521362
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        33.0502586,
        28.3500471,
        33.0537191,
        28.3515217
      ]
    },
    {
      "id": 10125,
      "name": "Alaminos",
      "names": {
        "zh": "阿拉米诺斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1553857,
        "lng": 119.9792201
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        119.9009945,
        16.0387917,
        120.1879506,
        16.2914234
      ]
    },
    {
      "id": 10126,
      "name": "Pili",
      "names": {
        "zh": "菌毛",
        "ja": "線毛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5561041,
        "lng": 123.2741192
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.2341192,
        13.5161041,
        123.3141192,
        13.5961041
      ]
    },
    {
      "id": 10127,
      "name": "Fengning",
      "names": {
        "zh": "丰宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2194964,
        "lng": 116.6340777
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.9038748,
        40.8944508,
        117.3929978,
        42.0076942
      ]
    },
    {
      "id": 10128,
      "name": "Urgut Shahri",
      "names": {
        "id": "Shahri urgut",
        "ja": "ウルガッツシャーリ"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.4092386,
        "lng": 67.2494167
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        67.2144929,
        39.3745494,
        67.2841017,
        39.4403395
      ]
    },
    {
      "id": 10129,
      "name": "Deventer",
      "names": {
        "zh": "德芬特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2348759,
        "lng": 6.1906162
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.1905662,
        52.2348259,
        6.1906662,
        52.2349259
      ]
    },
    {
      "id": 10130,
      "name": "Boca Raton",
      "names": {
        "zh": "博卡拉顿",
        "ja": "ボカラトン",
        "pt": "Raton Boca"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.3923841,
        "lng": -80.09933004999999
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.0999173,
        26.3918516,
        -80.0987428,
        26.3929166
      ]
    },
    {
      "id": 10131,
      "name": "Oton",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.693053,
        "lng": 122.4782709
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4199091,
        10.6064902,
        122.5241565,
        10.7712099
      ]
    },
    {
      "id": 10132,
      "name": "Hasilpur",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.6889811,
        "lng": 72.5525446
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.3925446,
        29.5289811,
        72.7125446,
        29.8489811
      ]
    },
    {
      "id": 10133,
      "name": "Lafayette",
      "names": {
        "zh": "老佛爷",
        "ja": "ラファイエット",
        "th": "ลาฟาแยต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0603689,
        "lng": -93.8151868
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.1147198,
        38.909119,
        -93.4772055,
        39.2927987
      ]
    },
    {
      "id": 10134,
      "name": "Leme",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.187598,
        "lng": -47.386969
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.421656,
        -22.230468,
        -47.34692,
        -22.144401
      ]
    },
    {
      "id": 10135,
      "name": "Tsuyama",
      "names": {
        "zh": "津山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0691284,
        "lng": 134.0043355
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.821078,
        34.9835532,
        134.168894,
        35.307412
      ]
    },
    {
      "id": 10136,
      "name": "Zelenodol'sk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8477605,
        "lng": 48.5097031
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        48.428345,
        55.8207043,
        48.5986314,
        55.8966132
      ]
    },
    {
      "id": 10137,
      "name": "Bislig",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.2130815,
        "lng": 126.3156173
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        126.1556173,
        8.0530815,
        126.4756173,
        8.3730815
      ]
    },
    {
      "id": 10138,
      "name": "Wloclawek",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.6532538,
        "lng": 19.0587525
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0537525,
        52.6482538,
        19.0637525,
        52.6582538
      ]
    },
    {
      "id": 10139,
      "name": "Arezzo",
      "names": {
        "zh": "阿雷佐",
        "ja": "アレッツォ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.4628957,
        "lng": 11.8781675
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.7407684,
        43.3276178,
        12.0778341,
        43.557018
      ]
    },
    {
      "id": 10140,
      "name": "Ballarat",
      "names": {
        "zh": "巴拉瑞特",
        "th": "บัลลารัต"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -37.5587442,
        "lng": 143.8593693
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        143.8543693,
        -37.5637442,
        143.8643693,
        -37.5537442
      ]
    },
    {
      "id": 10141,
      "name": "Sakata",
      "names": {
        "zh": "酒田",
        "ja": "酒田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.9147229,
        "lng": 139.8364101
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.1382156,
        38.774047,
        140.150791,
        39.5928744
      ]
    },
    {
      "id": 10142,
      "name": "Lee's Summit",
      "names": {
        "es": "Cumbre de Lee",
        "fr": "Sommet de Lee's",
        "zh": "李的峰会",
        "de": "Lee's Gipfel",
        "it": "Summit di Lee",
        "id": "KTT Lee",
        "ja": "リーのサミット",
        "th": "การประชุมสุดยอดของลี",
        "pt": "Cúpula de Lee"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9126717,
        "lng": -94.3782427
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.3782927,
        38.9126217,
        -94.3781927,
        38.9127217
      ]
    },
    {
      "id": 10143,
      "name": "Abhar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1468,
        "lng": 49.218
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.198,
        36.1268,
        49.238,
        36.1668
      ]
    },
    {
      "id": 10144,
      "name": "Tobolsk",
      "names": {
        "zh": "托博尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.1998048,
        "lng": 68.2512924
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        68.1457442,
        58.147605,
        68.5381558,
        58.3187626
      ]
    },
    {
      "id": 10145,
      "name": "Khamis Mushayt",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 18.3,
        "lng": 42.733333
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        42.573333,
        18.14,
        42.893333,
        18.46
      ]
    },
    {
      "id": 10146,
      "name": "Cottbus",
      "names": {
        "zh": "科特布斯",
        "ja": "コットブス",
        "th": "คอตต์บุส"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7567447,
        "lng": 14.3357307
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.2732889,
        51.692574,
        14.5012877,
        51.8642212
      ]
    },
    {
      "id": 10147,
      "name": "Rio Rancho",
      "names": {
        "zh": "里约兰乔",
        "ja": "リオラチョ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2702448,
        "lng": -106.6329806
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -106.865206,
        35.179918,
        -106.567003,
        35.39438
      ]
    },
    {
      "id": 10148,
      "name": "South Fulton",
      "names": {
        "fr": "Fuldat",
        "zh": "南富尔顿",
        "de": "Südfutteron",
        "id": "Fulton Selatan",
        "ja": "サウスフルトン",
        "th": "เซาท์ฟุลตัน",
        "pt": "Fultão sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5902659,
        "lng": -84.6712292
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.7112292,
        33.5502659,
        -84.6312292,
        33.6302659
      ]
    },
    {
      "id": 10149,
      "name": "Chia",
      "names": {
        "zh": "预订网",
        "ja": "チーア",
        "th": "เจีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8613482,
        "lng": -74.0599698
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.0686475,
        4.8456145,
        -74.0379221,
        4.8722966
      ]
    },
    {
      "id": 10150,
      "name": "Breves",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.68036,
        "lng": -50.479085
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.2168374,
        -1.8063993,
        -49.9300565,
        -0.5071401
      ]
    },
    {
      "id": 10151,
      "name": "Beaverton",
      "names": {
        "zh": "比弗顿",
        "ja": "ビーバートン",
        "th": "บีเวอร์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.4871723,
        "lng": -122.80378
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.8777172,
        45.4245861,
        -122.7436553,
        45.5429639
      ]
    },
    {
      "id": 10152,
      "name": "Sarapul",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.475969,
        "lng": 53.808394
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        53.727222,
        56.439251,
        53.835751,
        56.498779
      ]
    },
    {
      "id": 10153,
      "name": "Senahu",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4159451,
        "lng": -89.8204888
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.8604888,
        15.3759451,
        -89.7804888,
        15.4559451
      ]
    },
    {
      "id": 10154,
      "name": "Itoshima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5571816,
        "lng": 130.196231
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        129.9325366,
        33.465917,
        130.2922664,
        33.7264503
      ]
    },
    {
      "id": 10155,
      "name": "Es Senia",
      "names": {
        "th": "เอสเซเนีย"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.6500999,
        "lng": -0.6241804
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        -0.7031131,
        35.6036891,
        -0.5895737,
        35.6828705
      ]
    },
    {
      "id": 10156,
      "name": "Kontagora",
      "names": {
        "zh": "孔塔戈拉"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.399893,
        "lng": 5.4694905
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        5.1676965,
        10.1942816,
        5.7283397,
        10.7031193
      ]
    },
    {
      "id": 10157,
      "name": "Khambhat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3166806,
        "lng": 72.6243126
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.5843126,
        22.2766806,
        72.6643126,
        22.3566806
      ]
    },
    {
      "id": 10158,
      "name": "Lawrence",
      "names": {
        "fr": "Laurent",
        "zh": "劳伦斯",
        "ja": "ローレンス",
        "th": "ลอเรนซ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9719137,
        "lng": -95.2359403
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.3445398,
        38.9044669,
        -95.166617,
        39.0335024
      ]
    },
    {
      "id": 10159,
      "name": "Zarate",
      "names": {
        "zh": "萨拉特"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.0954713,
        "lng": -59.0245097
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.1845097,
        -34.2554713,
        -58.8645097,
        -33.9354713
      ]
    },
    {
      "id": 10160,
      "name": "Saku",
      "names": {
        "zh": "佐久",
        "ja": "佐久"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2488014,
        "lng": 138.4767695
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.280106,
        36.108157,
        138.637101,
        36.315981
      ]
    },
    {
      "id": 10161,
      "name": "M'lang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.94667,
        "lng": 124.87833
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.85833,
        6.92667,
        124.89833,
        6.96667
      ]
    },
    {
      "id": 10162,
      "name": "Itaituba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2740766,
        "lng": -55.9797324
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -55.9797824,
        -4.2741266,
        -55.9796824,
        -4.2740266
      ]
    },
    {
      "id": 10163,
      "name": "Dongducheon",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.9030856,
        "lng": 127.0605175
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        127.0077461,
        37.8597227,
        127.1552958,
        37.9806495
      ]
    },
    {
      "id": 10164,
      "name": "Worcester",
      "names": {
        "zh": "伍斯特",
        "ja": "ウスター",
        "th": "เวอร์ซ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.646238,
        "lng": 19.445927
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        19.405927,
        -33.686238,
        19.485927,
        -33.606238
      ]
    },
    {
      "id": 10165,
      "name": "Votkinsk",
      "names": {
        "zh": "沃特金斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.052505,
        "lng": 53.990612
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        53.8140803,
        56.9662959,
        54.0997349,
        57.1277209
      ]
    },
    {
      "id": 10166,
      "name": "Paulinia",
      "names": {
        "zh": "保利尼亚",
        "pt": "Paulínia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7630391,
        "lng": -47.1532213
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2181778,
        -22.8165561,
        -47.0590354,
        -22.6814978
      ]
    },
    {
      "id": 10167,
      "name": "Iseyin",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.9710106,
        "lng": 3.5956141
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        3.2470257,
        7.6520891,
        3.7534628,
        8.0478172
      ]
    },
    {
      "id": 10168,
      "name": "Fanyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0988405,
        "lng": 118.1888956
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.093157,
        31.0177316,
        118.2240003,
        31.2077225
      ]
    },
    {
      "id": 10169,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 15.714955150000002,
        "lng": -85.7023583920831
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -86.4583567,
        15.0709781,
        -84.9917661,
        16.3587985
      ]
    },
    {
      "id": 10170,
      "name": "Chitose",
      "names": {
        "zh": "千岁",
        "ja": "千歳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.8209335,
        "lng": 141.6509612
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.172303,
        42.681188,
        141.869981,
        42.953175
      ]
    },
    {
      "id": 10171,
      "name": "Attock Khurd",
      "names": {
        "zh": "khurd attock",
        "it": "Atock Khurd",
        "ja": "攻撃クルド"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.8736629,
        "lng": 72.2409449
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.2359449,
        33.8686629,
        72.2459449,
        33.8786629
      ]
    },
    {
      "id": 10172,
      "name": "Oldham",
      "names": {
        "zh": "奥尔德姆",
        "ja": "オールダム",
        "th": "ดัม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5415797,
        "lng": -2.1147831
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.185894,
        53.4911391,
        -1.9096224,
        53.6242294
      ]
    },
    {
      "id": 10173,
      "name": "Lugo",
      "names": {
        "zh": "卢戈",
        "ja": "ルーゴ",
        "th": "ลูโก"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.015095,
        "lng": -7.552157119779293
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -7.5524467,
        43.014917,
        -7.5518675,
        43.015273
      ]
    },
    {
      "id": 10174,
      "name": "Witten",
      "names": {
        "zh": "维滕",
        "ja": "ウィッテン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4370171,
        "lng": 7.335124
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.2460717,
        51.3700726,
        7.423475,
        51.4843402
      ]
    },
    {
      "id": 10175,
      "name": "Kambar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 27.5892431,
        "lng": 67.9996747
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        67.8396747,
        27.4292431,
        68.1596747,
        27.7492431
      ]
    },
    {
      "id": 10176,
      "name": "Hammamet",
      "names": {
        "zh": "哈马马特",
        "ja": "ハマメット"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4006557,
        "lng": 10.6190626
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.4590626,
        36.2406557,
        10.7790626,
        36.5606557
      ]
    },
    {
      "id": 10177,
      "name": "Munakata",
      "names": {
        "zh": "宗像"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.8055642,
        "lng": 130.5406875
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        129.8133106,
        33.750158,
        130.6398163,
        34.6474905
      ]
    },
    {
      "id": 10178,
      "name": "Serov",
      "names": {
        "zh": "谢罗夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.5944138,
        "lng": 60.561710785323925
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.5612911,
        59.5942627,
        60.5620664,
        59.5945682
      ]
    },
    {
      "id": 10179,
      "name": "Middletown",
      "names": {
        "zh": "米德尔敦",
        "ja": "ミドルタウン",
        "th": "มิดเดิลทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.516644,
        "lng": -84.4041725
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.4222428,
        39.4598073,
        -84.292515,
        39.5490332
      ]
    },
    {
      "id": 10180,
      "name": "Quilenda",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -10.641448,
        "lng": 14.335822
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.295822,
        -10.681448,
        14.375822,
        -10.601448
      ]
    },
    {
      "id": 10181,
      "name": "Tubarao",
      "names": {
        "zh": "图巴朗",
        "pt": "Tubarão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.471488,
        "lng": -49.014132
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.1846016,
        -28.5947301,
        -48.9021151,
        -28.3626373
      ]
    },
    {
      "id": 10182,
      "name": "Bafra",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.5665954,
        "lng": 35.9024777
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.4960993,
        41.2263347,
        36.1117144,
        41.7379389
      ]
    },
    {
      "id": 10183,
      "name": "Brantford",
      "names": {
        "zh": "布兰特福德",
        "ja": "ブラントフォード"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.1466286,
        "lng": -80.264877
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.264927,
        43.1465786,
        -80.264827,
        43.1466786
      ]
    },
    {
      "id": 10184,
      "name": "Valenca",
      "names": {
        "es": "Valença",
        "fr": "Valença",
        "zh": "瓦伦萨",
        "pt": "Valença"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -13.3723582,
        "lng": -39.0694243
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.4799949,
        -13.5272851,
        -38.9194777,
        -13.1989328
      ]
    },
    {
      "id": 10185,
      "name": "Yacuiba",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -22.0136831,
        "lng": -63.6781103
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -63.8381103,
        -22.1736831,
        -63.5181103,
        -21.8536831
      ]
    },
    {
      "id": 10186,
      "name": "Hanau",
      "names": {
        "zh": "哈瑙",
        "ja": "ハーナウ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.132881,
        "lng": 8.9169797
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.8619784,
        50.0753293,
        9.0302379,
        50.1955068
      ]
    },
    {
      "id": 10187,
      "name": "Ituiutaba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.9691887,
        "lng": -49.4639335
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.8720011,
        -19.3614967,
        -49.1814181,
        -18.6087466
      ]
    },
    {
      "id": 10188,
      "name": "Tucurui",
      "names": {
        "zh": "图库鲁伊",
        "pt": "Tucuruí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.766334,
        "lng": -49.6663654
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.1308045,
        -4.0821951,
        -49.5957842,
        -3.5131132
      ]
    },
    {
      "id": 10189,
      "name": "Lysychansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.917267,
        "lng": 38.4285981
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        38.3359645,
        48.8422269,
        38.5021522,
        48.9718195
      ]
    },
    {
      "id": 10190,
      "name": "Jamundi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2618357,
        "lng": -76.5407975
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.783479,
        3.0907597,
        -76.4593095,
        3.3584786
      ]
    },
    {
      "id": 10191,
      "name": "Cesena",
      "names": {
        "zh": "切塞纳",
        "ja": "チェゼーナ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.136352,
        "lng": 12.2422442
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.0945045,
        44.0202841,
        12.3621971,
        44.2332044
      ]
    },
    {
      "id": 10192,
      "name": "Itacoatiara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.1477898,
        "lng": -58.4461041
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -59.5,
        -3.753,
        -58.0648169,
        -2.517
      ]
    },
    {
      "id": 10193,
      "name": "Ukhta",
      "names": {
        "zh": "乌赫塔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 63.5623797,
        "lng": 53.6842376
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        53.6183621,
        63.5323748,
        53.8511576,
        63.6334501
      ]
    },
    {
      "id": 10194,
      "name": "Tomohon",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.3255914,
        "lng": 124.838605
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        124.731238,
        1.2425428,
        124.9094975,
        1.4051332
      ]
    },
    {
      "id": 10195,
      "name": "Buin",
      "names": {
        "zh": "布因"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.7319741,
        "lng": -70.7419619
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.8951189,
        -33.8337061,
        -70.6133475,
        -33.6430469
      ]
    },
    {
      "id": 10196,
      "name": "Barra do Pirai",
      "names": {
        "zh": "巴拉做Pirai",
        "ja": "バラはピライをします"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4703403,
        "lng": -43.8263256
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.9127424,
        -22.5544077,
        -43.7487488,
        -22.3518682
      ]
    },
    {
      "id": 10197,
      "name": "Balayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.93714,
        "lng": 120.73224
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.71224,
        13.91714,
        120.75224,
        13.95714
      ]
    },
    {
      "id": 10198,
      "name": "Iida",
      "names": {
        "zh": "饭田",
        "ja": "飯田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5147101,
        "lng": 137.8219519
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.689221,
        35.253329,
        138.153136,
        35.655681
      ]
    },
    {
      "id": 10199,
      "name": "Caieiras",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3644621,
        "lng": -46.7484765
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.8237433,
        -23.4269212,
        -46.655,
        -23.3265101
      ]
    },
    {
      "id": 10200,
      "name": "Cambe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2762638,
        "lng": -51.2792806
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.2842806,
        -23.2812638,
        -51.2742806,
        -23.2712638
      ]
    },
    {
      "id": 10201,
      "name": "Kazerun",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.6203251,
        "lng": 51.6520826
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.6129551,
        29.5926109,
        51.694292,
        29.6415886
      ]
    },
    {
      "id": 10202,
      "name": "San Tan Valley",
      "names": {
        "fr": "San Tan Vallée",
        "zh": "圣吨谷",
        "de": "San Tan-Tal",
        "id": "Lembah San Tan",
        "ja": "サンタン渓谷",
        "th": "หุบเขาซานตาล",
        "pt": "Vale San Tan"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1775233,
        "lng": -111.547892
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.587892,
        33.1375233,
        -111.507892,
        33.2175233
      ]
    },
    {
      "id": 10203,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1352995,
        "lng": -117.1743395
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2298815,
        33.0805728,
        -117.1101841,
        33.1882832
      ]
    },
    {
      "id": 10204,
      "name": "Escalante",
      "names": {
        "zh": "埃斯卡兰蒂"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8412679,
        "lng": 123.4992627
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3504939,
        10.6916418,
        123.7116846,
        10.9184523
      ]
    },
    {
      "id": 10205,
      "name": "Japeri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6427642,
        "lng": -43.6533831
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.6707825,
        -22.7225753,
        -43.5339869,
        -22.6093542
      ]
    },
    {
      "id": 10206,
      "name": "Iguatu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.361934,
        "lng": -39.2972233
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.4732116,
        -6.5349605,
        -39.053,
        -6.1707856
      ]
    },
    {
      "id": 10207,
      "name": "Wujiaqu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.16963,
        "lng": 87.53497
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        87.51497,
        44.14963,
        87.55497,
        44.18963
      ]
    },
    {
      "id": 10208,
      "name": "Aurangabad",
      "names": {
        "zh": "奥兰加巴德",
        "th": "ออรันกาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.7536696,
        "lng": 84.3746725
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.3558427,
        24.7341916,
        84.4083784,
        24.7766669
      ]
    },
    {
      "id": 10209,
      "name": "Chakdaha",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0787191,
        "lng": 88.5298004
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.5248004,
        23.0737191,
        88.5348004,
        23.0837191
      ]
    },
    {
      "id": 10210,
      "name": "Las Rozas de Madrid",
      "names": {
        "ja": "ラスロザスデマドリード",
        "th": "las rozas de มาดริด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4933095,
        "lng": -3.8758396
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.9498884,
        40.4750246,
        -3.8331637,
        40.5746026
      ]
    },
    {
      "id": 10211,
      "name": "Arifwala",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.291624,
        "lng": 73.067235
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.04357,
        30.271988,
        73.083991,
        30.311064
      ]
    },
    {
      "id": 10212,
      "name": "Sandy",
      "names": {
        "es": "Arena",
        "zh": "沙滩",
        "it": "Sabbioso",
        "ja": "砂の",
        "th": "ทราย",
        "pt": "Areia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5710454,
        "lng": -111.8953815
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.921621,
        40.527662,
        -111.7768578,
        40.618005
      ]
    },
    {
      "id": 10213,
      "name": "Vanderbijlpark",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.706891,
        "lng": 27.836271
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        27.796271,
        -26.746891,
        27.876271,
        -26.666891
      ]
    },
    {
      "id": 10214,
      "name": "Omura",
      "names": {
        "zh": "大村"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9107294,
        "lng": 129.9623676
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        129.9573676,
        32.9057294,
        129.9673676,
        32.9157294
      ]
    },
    {
      "id": 10215,
      "name": "Longquan",
      "names": {
        "zh": "龙泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.7255632,
        "lng": 108.3975964
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.3575964,
        31.6855632,
        108.4375964,
        31.7655632
      ]
    },
    {
      "id": 10216,
      "name": "Leninsk-Kuznetskiy",
      "names": {
        "zh": "列宁斯克-kuznetskiy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.65643,
        "lng": 86.15748
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        86.123024,
        54.615112,
        86.238892,
        54.700863
      ]
    },
    {
      "id": 10217,
      "name": "Kelo",
      "names": {
        "zh": "凯洛"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.30859,
        "lng": 15.80658
      },
      "country": "Chad",
      "importance": 3,
      "bbox": [
        15.78658,
        9.28859,
        15.82658,
        9.32859
      ]
    },
    {
      "id": 10218,
      "name": "Mezhdurechensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.6863763,
        "lng": 88.0703443
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        87.8463626,
        53.6059603,
        88.3221579,
        53.8039726
      ]
    },
    {
      "id": 10219,
      "name": "Erechim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -27.6350709,
        "lng": -52.2738654
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.3487216,
        -27.785,
        -52.1479402,
        -27.4824249
      ]
    },
    {
      "id": 10220,
      "name": "Lorca",
      "names": {
        "zh": "洛尔卡",
        "ja": "ロルカ",
        "th": "ลอร์"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.6711989,
        "lng": -1.6988592
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -2.0126512,
        37.4210243,
        -1.3966831,
        37.9701817
      ]
    },
    {
      "id": 10221,
      "name": "Federal Way",
      "names": {
        "es": "Manera federal",
        "fr": "Voie fédérale",
        "zh": "联邦方式",
        "de": "Föderal",
        "it": "Modo federale",
        "id": "Cara federal",
        "ja": "連邦の方法",
        "th": "วิธีการของรัฐบาลกลาง",
        "pt": "Federal"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.313494,
        "lng": -122.33931
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.417193,
        47.2574028,
        -122.273472,
        47.3579478
      ]
    },
    {
      "id": 10222,
      "name": "Dovzhansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.08964,
        "lng": 39.65243
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 10223,
      "name": "Bendigo",
      "names": {
        "zh": "本迪戈"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -36.75540025,
        "lng": 144.28084504434702
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        144.262136,
        -36.770576,
        144.298167,
        -36.741784
      ]
    },
    {
      "id": 10224,
      "name": "Kamisu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8898999,
        "lng": 140.6645754
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.598853,
        35.739138,
        140.9960809,
        35.9689854
      ]
    },
    {
      "id": 10225,
      "name": "Longkeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.982393,
        "lng": 112.214947
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.194947,
        23.962393,
        112.234947,
        24.002393
      ]
    },
    {
      "id": 10226,
      "name": "Muzaffarabad",
      "names": {
        "zh": "穆扎法拉巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.3734751,
        "lng": 73.4702407
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.3102407,
        34.2134751,
        73.6302407,
        34.5334751
      ]
    },
    {
      "id": 10227,
      "name": "Greece",
      "names": {
        "es": "Grecia",
        "fr": "Grèce",
        "zh": "希腊",
        "de": "Griechenland",
        "it": "Grecia",
        "id": "Yunani",
        "ja": "ギリシャ",
        "th": "กรีซ",
        "pt": "Grécia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.2581805,
        "lng": -77.6970101
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.753536,
        43.17927,
        -77.615591,
        43.3347444
      ]
    },
    {
      "id": 10228,
      "name": "Mandeville",
      "names": {
        "zh": "曼德维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3582305,
        "lng": -90.0657477
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.1255542,
        30.3483481,
        -90.0352452,
        30.4042551
      ]
    },
    {
      "id": 10229,
      "name": "San Cugat del Valles",
      "names": {
        "zh": "圣Cugat del Valles",
        "ja": "サンクカットデルバール"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.4728432,
        "lng": 2.0817809
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.999661,
        41.415045,
        2.129856,
        41.5196078
      ]
    },
    {
      "id": 10230,
      "name": "Mons",
      "names": {
        "zh": "蒙斯",
        "ja": "モンス",
        "th": "มอนส์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.4549568,
        "lng": 3.951958
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.854493,
        50.3777076,
        4.0851725,
        50.5138183
      ]
    },
    {
      "id": 10231,
      "name": "Itaperuna",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2069948,
        "lng": -41.8893455
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.0228119,
        -21.3307561,
        -41.7412494,
        -21.0816211
      ]
    },
    {
      "id": 10232,
      "name": "Jinbi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7247294,
        "lng": 101.322128
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.162128,
        25.5647294,
        101.482128,
        25.8847294
      ]
    },
    {
      "id": 10233,
      "name": "Xiluodu",
      "names": {
        "zh": "溪洛渡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2350623,
        "lng": 103.6349942
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.5949942,
        28.1950623,
        103.6749942,
        28.2750623
      ]
    },
    {
      "id": 10234,
      "name": "Hesperia",
      "names": {
        "zh": "hesperia酒店",
        "ja": "エスペリア"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4263886,
        "lng": -117.3008784
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.4398699,
        34.3042373,
        -117.2377603,
        34.4712227
      ]
    },
    {
      "id": 10235,
      "name": "Brockton",
      "names": {
        "zh": "布罗克顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0834335,
        "lng": -71.0183787
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.0804832,
        42.0421788,
        -70.9750729,
        42.1265268
      ]
    },
    {
      "id": 10236,
      "name": "Pesaro",
      "names": {
        "zh": "佩萨罗",
        "ja": "ペーザロ",
        "th": "เปซาโร"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.9098114,
        "lng": 12.9131228
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.7709571,
        43.7691609,
        12.9628349,
        43.9654685
      ]
    },
    {
      "id": 10237,
      "name": "Boli",
      "names": {
        "zh": "玻璃"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.7512375,
        "lng": 130.5678432
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.4078432,
        45.5912375,
        130.7278432,
        45.9112375
      ]
    },
    {
      "id": 10238,
      "name": "Yi Xian",
      "names": {
        "zh": "易贤",
        "ja": "西安",
        "th": "ยี่เซียน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3484747,
        "lng": 115.4911989
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.3311989,
        39.1884747,
        115.6511989,
        39.5084747
      ]
    },
    {
      "id": 10239,
      "name": "Shimada",
      "names": {
        "zh": "岛田",
        "ja": "島田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.835866,
        "lng": 138.171853
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.158632,
        34.818325,
        138.186035,
        34.845357
      ]
    },
    {
      "id": 10240,
      "name": "Lecce",
      "names": {
        "zh": "莱切",
        "ja": "レッチェ",
        "th": "เลกเซ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.352504,
        "lng": 18.169729
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        18.115151,
        40.318392,
        18.217423,
        40.388208
      ]
    },
    {
      "id": 10241,
      "name": "Caceres",
      "names": {
        "es": "Cáceres",
        "zh": "卡塞雷斯",
        "id": "Cáceres",
        "ja": "カセレス",
        "th": "คาเซเรส",
        "pt": "Cáceres"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.4745175,
        "lng": -6.3716761
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -6.8020698,
        39.1552618,
        -6.0787446,
        39.6488278
      ]
    },
    {
      "id": 10242,
      "name": "Guaiba",
      "names": {
        "zh": "瓜伊巴",
        "pt": "Guaíba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.1130731,
        "lng": -51.329442
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.5828247,
        -30.2771271,
        -51.3015085,
        -30.0521685
      ]
    },
    {
      "id": 10243,
      "name": "Bogo",
      "names": {
        "zh": "博戈",
        "de": "Bogø"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 10.811051599999999,
        "lng": 14.682430976022122
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        14.4918829,
        10.5808817,
        14.8071026,
        11.0398148
      ]
    },
    {
      "id": 10244,
      "name": "Balamban",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5039,
        "lng": 123.7156
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6956,
        10.4839,
        123.7356,
        10.5239
      ]
    },
    {
      "id": 10245,
      "name": "Kulob",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.9107206,
        "lng": 69.77766718768882
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        69.7773761,
        37.9105302,
        69.7779583,
        37.9109109
      ]
    },
    {
      "id": 10246,
      "name": "Sarov",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9391673,
        "lng": 43.3268812
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.2306672,
        54.8436813,
        43.4818221,
        54.9789689
      ]
    },
    {
      "id": 10247,
      "name": "Rubio",
      "names": {
        "zh": "卢比奥"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.6951055,
        "lng": -72.3646525
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -72.5246525,
        7.5351055,
        -72.2046525,
        7.8551055
      ]
    },
    {
      "id": 10248,
      "name": "Riverview",
      "names": {
        "zh": "江景",
        "th": "ริเวอร์วิว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.871197,
        "lng": -82.3269883
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.3669883,
        27.831197,
        -82.2869883,
        27.911197
      ]
    },
    {
      "id": 10249,
      "name": "Mianwali",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.58168,
        "lng": 71.550395
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.508698,
        32.549269,
        71.599701,
        32.611875
      ]
    },
    {
      "id": 10250,
      "name": "Fishers",
      "names": {
        "es": "Los pescadores",
        "fr": "Les pêcheurs",
        "zh": "渔民",
        "de": "Fischer",
        "id": "Nelayan",
        "ja": "フィッシャーズ",
        "th": "ชาวประมง",
        "pt": "Pescadores"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9555928,
        "lng": -86.0138729
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.076552,
        39.9269563,
        -85.8620984,
        40.0010787
      ]
    },
    {
      "id": 10251,
      "name": "Jinshan",
      "names": {
        "zh": "金山",
        "ja": "金山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9075497,
        "lng": 105.9518695
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.9118695,
        30.8675497,
        105.9918695,
        30.9475497
      ]
    },
    {
      "id": 10252,
      "name": "Gerona",
      "names": {
        "fr": "Gérone",
        "zh": "赫罗纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6069315,
        "lng": 120.5985733
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4820571,
        15.5446504,
        120.63398,
        15.6575579
      ]
    },
    {
      "id": 10253,
      "name": "Solikamsk",
      "names": {
        "zh": "索利卡姆斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.627811,
        "lng": 56.764231
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        56.653046,
        59.58284,
        56.807571,
        59.739716
      ]
    },
    {
      "id": 10254,
      "name": "Nahiyat al Karmah",
      "names": {
        "id": "Nahiyat al karma"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.40438,
        "lng": 43.91437
      },
      "country": "Iraq",
      "importance": 3,
      "bbox": [
        43.89437,
        33.38438,
        43.93437,
        33.42438
      ]
    },
    {
      "id": 10255,
      "name": "Bowling Green",
      "names": {
        "es": "De boliche",
        "fr": "Vert bowling",
        "zh": "保龄球绿色",
        "de": "Barmherzig",
        "it": "Bowling verde",
        "id": "Hijau bowling",
        "ja": "ボウリンググリーン",
        "th": "โบว์ลิ่งสีเขียว",
        "pt": "Boliche verde"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.9903199,
        "lng": -86.4436018
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.533207,
        36.898847,
        -86.306719,
        37.046652
      ]
    },
    {
      "id": 10256,
      "name": "Barletta",
      "names": {
        "zh": "巴列塔"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.3152639,
        "lng": 16.2785891
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        16.2785391,
        41.3152139,
        16.2786391,
        41.3153139
      ]
    },
    {
      "id": 10257,
      "name": "Valongo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1909794,
        "lng": -8.4980511
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5707273,
        41.1407645,
        -8.4399952,
        41.2690246
      ]
    },
    {
      "id": 10258,
      "name": "Biu",
      "names": {
        "zh": "比乌"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.6146098,
        "lng": 12.1909612
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        11.632145,
        10.5633193,
        12.4647382,
        11.1195376
      ]
    },
    {
      "id": 10259,
      "name": "Lagarto",
      "names": {
        "zh": "拉加尔图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.91434,
        "lng": -37.669498
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.700894,
        -10.949312,
        -37.644918,
        -10.886815
      ]
    },
    {
      "id": 10260,
      "name": "Lopez",
      "names": {
        "es": "López",
        "zh": "·洛佩兹",
        "ja": "ロペス",
        "th": "โลเปซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8825115,
        "lng": 122.2611114
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.2211114,
        13.8425115,
        122.3011114,
        13.9225115
      ]
    },
    {
      "id": 10261,
      "name": "Jaworzno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.199679,
        "lng": 19.275912
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.160692,
        50.116934,
        19.441976,
        50.261896
      ]
    },
    {
      "id": 10262,
      "name": "Kanuma",
      "names": {
        "zh": "鹿沼"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5682281,
        "lng": 139.7457781
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.464063,
        36.461181,
        139.8323,
        36.715035
      ]
    },
    {
      "id": 10263,
      "name": "Gera",
      "names": {
        "zh": "格拉",
        "ja": "ゲーラ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8765537,
        "lng": 12.0832666
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.9981564,
        50.7986158,
        12.1695244,
        50.9765857
      ]
    },
    {
      "id": 10264,
      "name": "Roswell",
      "names": {
        "zh": "罗斯韦尔",
        "ja": "ロズウェル",
        "th": "รอสเวล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.02332,
        "lng": -84.360022
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.419134,
        33.9717368,
        -84.2615877,
        34.118239
      ]
    },
    {
      "id": 10265,
      "name": "Menifee",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.6864432,
        "lng": -117.1770437
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2581407,
        33.6116391,
        -117.1188354,
        33.7577487
      ]
    },
    {
      "id": 10266,
      "name": "Grudziadz",
      "names": {
        "zh": "格鲁琼兹"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4876477,
        "lng": 18.7557746
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.6860729,
        53.4207664,
        18.8709127,
        53.5241487
      ]
    },
    {
      "id": 10267,
      "name": "Plantation",
      "names": {
        "es": "Plantación",
        "zh": "人工林",
        "it": "Piantagione",
        "id": "Perkebunan",
        "ja": "プランテーション",
        "th": "สวน",
        "pt": "Da plantação"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1275862,
        "lng": -80.2331036
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.3301597,
        26.0925707,
        -80.1972388,
        26.1608418
      ]
    },
    {
      "id": 10268,
      "name": "Dover",
      "names": {
        "zh": "多佛",
        "ja": "ドーバー",
        "th": "โดเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.1981117,
        "lng": -70.8739761
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.961606,
        43.1176375,
        -70.819023,
        43.254982
      ]
    },
    {
      "id": 10269,
      "name": "Gatchina",
      "names": {
        "zh": "加特契纳"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.5706273,
        "lng": 30.1305083
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        30.0417316,
        59.5319945,
        30.1663108,
        59.6062301
      ]
    },
    {
      "id": 10270,
      "name": "Michurinsk",
      "names": {
        "zh": "米丘林斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.897048,
        "lng": 40.496472
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        40.433433,
        52.871508,
        40.547852,
        52.920986
      ]
    },
    {
      "id": 10271,
      "name": "Daanbantayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2505,
        "lng": 124
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.98,
        11.2305,
        124.02,
        11.2705
      ]
    },
    {
      "id": 10272,
      "name": "Bayan Hot",
      "names": {
        "es": "Bayan caliente",
        "fr": "Bayan chaud",
        "zh": "巴兰热",
        "de": "Bayan heiß",
        "it": "Bayan caldo",
        "ja": "バヤンホット",
        "th": "เพศร้อน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8376394,
        "lng": 105.6951134
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.6551134,
        38.7976394,
        105.7351134,
        38.8776394
      ]
    },
    {
      "id": 10273,
      "name": "Alessandria",
      "names": {
        "zh": "亚历山德里亚",
        "ja": "アレッサンドリア",
        "pt": "Alexandria"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.9130438,
        "lng": 8.6154872
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.5028989,
        44.8429027,
        8.7900078,
        44.9907246
      ]
    },
    {
      "id": 10274,
      "name": "Shibata",
      "names": {
        "zh": "柴田",
        "ja": "柴田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.95,
        "lng": 139.33333
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.31333,
        37.93,
        139.35333,
        37.97
      ]
    },
    {
      "id": 10275,
      "name": "Santa Cruz Xoxocotlan",
      "names": {
        "zh": "圣克鲁斯Xoxocotlan",
        "ja": "サンタクルーズXoxocotlan",
        "th": "ซานตาครูซ xoxocotlan",
        "pt": "Santa Cruz xoxocótcia"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.026389,
        "lng": -96.733333
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -96.773333,
        16.986389,
        -96.693333,
        17.066389
      ]
    },
    {
      "id": 10276,
      "name": "Glazov",
      "names": {
        "zh": "格拉佐夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.1401196,
        "lng": 52.6464388
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        52.5364848,
        58.1035564,
        52.7559321,
        58.1929875
      ]
    },
    {
      "id": 10277,
      "name": "Portsmouth",
      "names": {
        "zh": "朴茨茅斯",
        "ja": "ポーツマス",
        "th": "พอร์ทสมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.8320081,
        "lng": -76.297699
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.4203142,
        36.7861422,
        -76.2883469,
        36.9493051
      ]
    },
    {
      "id": 10278,
      "name": "Chino",
      "names": {
        "zh": "奇诺",
        "ja": "チノ",
        "th": "สีกากี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0133561,
        "lng": -117.690075
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.7354985,
        33.923896,
        -117.5993209,
        34.0483444
      ]
    },
    {
      "id": 10279,
      "name": "Kwekwe",
      "names": {
        "zh": "奎奎"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.9311996,
        "lng": 29.8320837
      },
      "country": "Zimbabwe",
      "importance": 3,
      "bbox": [
        29.8270837,
        -18.9361996,
        29.8370837,
        -18.9261996
      ]
    },
    {
      "id": 10280,
      "name": "Cape Breton",
      "names": {
        "zh": "披风布列顿",
        "de": "Kaps",
        "id": "Jago",
        "ja": "ケープブルトン",
        "th": "เคปเบรน",
        "pt": "Capa bretão"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.0137444,
        "lng": -60.2140205
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -60.9142001,
        45.6438767,
        -59.3743806,
        46.5480965
      ]
    },
    {
      "id": 10281,
      "name": "Azumino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3044083,
        "lng": 137.9054972
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.70101,
        36.237358,
        137.991499,
        36.425351
      ]
    },
    {
      "id": 10282,
      "name": "Manacapuru",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.2996768,
        "lng": -60.6213528
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -61.483,
        -3.821,
        -60.312,
        -2.732
      ]
    },
    {
      "id": 10283,
      "name": "Wangjia",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.345986,
        "lng": 108.312075
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.2653511,
        30.29435,
        108.3576392,
        30.3922006
      ]
    },
    {
      "id": 10284,
      "name": "Monkayo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.81528,
        "lng": 126.05444
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        126.03444,
        7.79528,
        126.07444,
        7.83528
      ]
    },
    {
      "id": 10285,
      "name": "Edmond",
      "names": {
        "zh": "爱德蒙",
        "ja": "エドモンド",
        "th": "เอดมองด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.6571367,
        "lng": -97.4649038
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.5496476,
        35.608839,
        -97.264878,
        35.725564
      ]
    },
    {
      "id": 10286,
      "name": "Lemery",
      "names": {
        "zh": "莱梅里"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8845702,
        "lng": 120.914016
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7838674,
        13.847115,
        120.9188391,
        14.0257716
      ]
    },
    {
      "id": 10287,
      "name": "Kabacan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1092306,
        "lng": 124.8244964
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.7742416,
        7.0338487,
        124.9287625,
        7.3419959
      ]
    },
    {
      "id": 10288,
      "name": "Hanford",
      "names": {
        "zh": "汉福德"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3260774,
        "lng": -119.652007
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.657007,
        36.3210774,
        -119.647007,
        36.3310774
      ]
    },
    {
      "id": 10289,
      "name": "Itauna",
      "names": {
        "pt": "Itaúna"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.075556,
        "lng": -44.576389
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.718046,
        -20.224406,
        -44.445,
        -19.936
      ]
    },
    {
      "id": 10290,
      "name": "Dearborn",
      "names": {
        "zh": "迪尔伯恩",
        "ja": "ディアボーン",
        "th": "เดียร์บอร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3070364,
        "lng": -83.23521
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.24021,
        42.3020364,
        -83.23021,
        42.3120364
      ]
    },
    {
      "id": 10291,
      "name": "Sanjo",
      "names": {
        "zh": "三条"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.6361174,
        "lng": 138.9613971
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.872385,
        37.397493,
        139.298362,
        37.682199
      ]
    },
    {
      "id": 10292,
      "name": "Higashi-Matsuyama",
      "names": {
        "zh": "东-松山",
        "ja": "東-松山",
        "th": "Higashi-มัตซุยา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0347621,
        "lng": 139.4016056
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.3966056,
        36.0297621,
        139.4066056,
        36.0397621
      ]
    },
    {
      "id": 10293,
      "name": "Yunnanyi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.3904851,
        "lng": 100.6851497
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        100.582745,
        25.251766,
        100.7827932,
        25.5070835
      ]
    },
    {
      "id": 10294,
      "name": "Voskresensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.322518,
        "lng": 38.6816824
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.6392132,
        55.2369914,
        38.7860653,
        55.3771807
      ]
    },
    {
      "id": 10295,
      "name": "Vilhena",
      "names": {
        "zh": "维列纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.7368536,
        "lng": -60.1465555
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -60.8100653,
        -13.2578501,
        -59.773677,
        -10.9889109
      ]
    },
    {
      "id": 10296,
      "name": "Indanan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.99429,
        "lng": 120.96028
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.86028,
        5.89429,
        121.06028,
        6.09429
      ]
    },
    {
      "id": 10297,
      "name": "Mikhaylovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.1324913,
        "lng": 42.0263304
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        41.9697435,
        45.0760842,
        42.0956437,
        45.1704716
      ]
    },
    {
      "id": 10298,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9999235,
        "lng": 120.4051274
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3651274,
        15.9599235,
        120.4451274,
        16.0399235
      ]
    },
    {
      "id": 10299,
      "name": "Livonia",
      "names": {
        "zh": "利沃尼亚",
        "ja": "リボニア",
        "th": "ลิโวเนีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.36837,
        "lng": -83.3527097
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.4336713,
        42.352465,
        -83.312825,
        42.442033
      ]
    },
    {
      "id": 10300,
      "name": "Piraquara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.44171055,
        "lng": -49.060701525815155
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.0609854,
        -25.4419639,
        -49.0604163,
        -25.4414554
      ]
    },
    {
      "id": 10301,
      "name": "Hadera",
      "names": {
        "zh": "哈代拉"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.43699,
        "lng": 34.9198258
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.8623456,
        32.4033988,
        34.9786073,
        32.4781612
      ]
    },
    {
      "id": 10302,
      "name": "La Spezia",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 44.10703,
        "lng": 9.82819
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.82814,
        44.10698,
        9.82824,
        44.10708
      ]
    },
    {
      "id": 10303,
      "name": "Trelew",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -43.2531242,
        "lng": -65.3094407
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -65.4694407,
        -43.4131242,
        -65.1494407,
        -43.0931242
      ]
    },
    {
      "id": 10304,
      "name": "Bedford",
      "names": {
        "zh": "贝德福德",
        "ja": "ベッドフォード",
        "th": "เบดฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.1363806,
        "lng": -0.4675041
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.5075041,
        52.0963806,
        -0.4275041,
        52.1763806
      ]
    },
    {
      "id": 10305,
      "name": "Linquan",
      "names": {
        "zh": "临泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9513305,
        "lng": 110.9876828
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.9476828,
        37.9113305,
        111.0276828,
        37.9913305
      ]
    },
    {
      "id": 10306,
      "name": "Iserlohn",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.383848,
        "lng": 7.687591
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.584788,
        51.30173,
        7.744362,
        51.473559
      ]
    },
    {
      "id": 10307,
      "name": "Contai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.778317,
        "lng": 87.746092
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.707014,
        21.764895,
        87.784149,
        21.793719
      ]
    },
    {
      "id": 10308,
      "name": "Bafang",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 5.158664,
        "lng": 10.179748
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        10.1040019,
        5.063168,
        10.2569238,
        5.1655079
      ]
    },
    {
      "id": 10309,
      "name": "Vineland",
      "names": {
        "zh": "上传时间："
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.4862777,
        "lng": -75.0254256
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.0865994,
        39.377715,
        -74.896626,
        39.5686048
      ]
    },
    {
      "id": 10310,
      "name": "Helmond",
      "names": {
        "zh": "海尔蒙德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4790956,
        "lng": 5.6557686
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.570156,
        51.43803,
        5.7360936,
        51.5092173
      ]
    },
    {
      "id": 10311,
      "name": "Avignon",
      "names": {
        "es": "Aviñón",
        "zh": "阿维尼翁",
        "it": "Avignone",
        "ja": "アビニョン",
        "th": "อาวิญง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.9492493,
        "lng": 4.8059012
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.7396309,
        43.8873819,
        4.9271468,
        43.9967419
      ]
    },
    {
      "id": 10312,
      "name": "Samalut",
      "names": {
        "zh": "塞马卢特"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.31214,
        "lng": 30.71007
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.69007,
        28.29214,
        30.73007,
        28.33214
      ]
    },
    {
      "id": 10313,
      "name": "Florence",
      "names": {
        "es": "Florencia",
        "zh": "佛罗伦萨",
        "de": "Florenz",
        "it": "Firenze",
        "ja": "フィレンツェ",
        "th": "ฟลอเรนซ์",
        "pt": "Florença"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9989499,
        "lng": -84.6266111
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.706664,
        38.93167,
        -84.6106196,
        39.030914
      ]
    },
    {
      "id": 10314,
      "name": "Portsmouth",
      "names": {
        "zh": "朴茨茅斯",
        "ja": "ポーツマス",
        "th": "พอร์ทสมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0702223,
        "lng": -70.7548621
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.8229994,
        43.0135509,
        -70.7279298,
        43.0996118
      ]
    },
    {
      "id": 10315,
      "name": "Slidell",
      "names": {
        "zh": "斯莱德尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2784551,
        "lng": -89.782521
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.782571,
        30.2784051,
        -89.782471,
        30.2785051
      ]
    },
    {
      "id": 10316,
      "name": "Wukari",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.8705196,
        "lng": 9.7775523
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        9.1157169,
        7.614686,
        10.3326406,
        8.2960873
      ]
    },
    {
      "id": 10317,
      "name": "Lawton",
      "names": {
        "zh": "劳顿",
        "th": "ลอว์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6086854,
        "lng": -98.3903305
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.5364913,
        34.5114967,
        -98.314426,
        34.694351
      ]
    },
    {
      "id": 10318,
      "name": "Palimbang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.21056,
        "lng": 124.18944
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.16944,
        6.19056,
        124.20944,
        6.23056
      ]
    },
    {
      "id": 10319,
      "name": "Rafaela",
      "names": {
        "zh": "拉斐拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.2526923,
        "lng": -61.4916758
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.5359903,
        -31.2895989,
        -61.4484584,
        -31.2229844
      ]
    },
    {
      "id": 10320,
      "name": "Velikiye Luki",
      "names": {
        "ja": "ヴェリキヤルキ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.342193,
        "lng": 30.51888
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        30.487944,
        56.315304,
        30.614721,
        56.362446
      ]
    },
    {
      "id": 10321,
      "name": "Kilosa",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.8363175,
        "lng": 36.985732
      },
      "country": "Tanzania",
      "importance": 3,
      "bbox": [
        36.945732,
        -6.8763175,
        37.025732,
        -6.7963175
      ]
    },
    {
      "id": 10322,
      "name": "Itumbiara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.4135862,
        "lng": -49.2172596
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.9792958,
        -18.646,
        -48.959,
        -18.104
      ]
    },
    {
      "id": 10323,
      "name": "Hanamaki Onsen",
      "names": {
        "id": "Onsen hanamaki",
        "ja": "花咲温泉"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.395997,
        "lng": 141.124791
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.069322,
        39.353414,
        141.191433,
        39.517289
      ]
    },
    {
      "id": 10324,
      "name": "Missoula",
      "names": {
        "zh": "米苏拉",
        "ja": "ミズーラ",
        "th": "มิสซูลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.8701049,
        "lng": -113.995267
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -114.1272915,
        46.7915386,
        -113.8973161,
        46.949951
      ]
    },
    {
      "id": 10325,
      "name": "Laiyuan",
      "names": {
        "zh": "涞源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3580862,
        "lng": 114.687235
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.527235,
        39.1980862,
        114.847235,
        39.5180862
      ]
    },
    {
      "id": 10326,
      "name": "Auburn",
      "names": {
        "zh": "奥本",
        "ja": "オーバーン",
        "th": "สีน้ำตาลแดง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6098566,
        "lng": -85.4807825
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.584254,
        32.5250408,
        -85.404472,
        32.7306338
      ]
    },
    {
      "id": 10327,
      "name": "Itapeva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.984882,
        "lng": -48.879604
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.921783,
        -24.010504,
        -48.845639,
        -23.946004
      ]
    },
    {
      "id": 10328,
      "name": "Foumban",
      "names": {
        "zh": "丰班"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 5.7291,
        "lng": 10.90011
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        10.88011,
        5.7091,
        10.92011,
        5.7491
      ]
    },
    {
      "id": 10329,
      "name": "Rayachoti",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.053371,
        "lng": 78.749016
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.736222,
        14.041028,
        78.766785,
        14.066263
      ]
    },
    {
      "id": 10330,
      "name": "Sampit",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.537122,
        "lng": 112.946147
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        112.870038,
        -2.595443,
        112.989642,
        -2.486812
      ]
    },
    {
      "id": 10331,
      "name": "Naju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.0158859,
        "lng": 126.7108313
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        126.5129327,
        34.8642267,
        126.8987846,
        35.1147188
      ]
    },
    {
      "id": 10332,
      "name": "Lethbridge",
      "names": {
        "zh": "莱斯布里奇",
        "th": "เลทบริดจ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.6945782,
        "lng": -112.8331033
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -112.9496675,
        49.6188534,
        -112.7566288,
        49.7565104
      ]
    },
    {
      "id": 10333,
      "name": "Rapid City",
      "names": {
        "es": "Ciudad rápida",
        "fr": "Ville rapide",
        "zh": "快速城市",
        "de": "Schnelle Stadt",
        "it": "Città rapida",
        "id": "Kota cepat",
        "ja": "ラピッドシティ",
        "th": "เมืองที่รวดเร็ว",
        "pt": "Cidade rápida"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0806041,
        "lng": -103.228023
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -103.3263526,
        43.9949218,
        -103.0379132,
        44.1367886
      ]
    },
    {
      "id": 10334,
      "name": "Sablayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.8470382,
        "lng": 120.7799198
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.2730257,
        12.521317,
        121.243987,
        13.1781581
      ]
    },
    {
      "id": 10335,
      "name": "Grahamstown",
      "names": {
        "zh": "格雷厄姆斯敦"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.3070589,
        "lng": 26.5272648
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        26.3672648,
        -33.4670589,
        26.6872648,
        -33.1470589
      ]
    },
    {
      "id": 10336,
      "name": "Yumbo",
      "names": {
        "zh": "云博",
        "ja": "ユンボ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.58234,
        "lng": -76.49146
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.51146,
        3.56234,
        -76.47146,
        3.60234
      ]
    },
    {
      "id": 10337,
      "name": "Kitakami",
      "names": {
        "zh": "北上"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.2866832,
        "lng": 141.1135121
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.816988,
        39.174416,
        141.254983,
        39.481226
      ]
    },
    {
      "id": 10338,
      "name": "Acajutla",
      "names": {
        "zh": "阿卡胡特拉"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.5897967,
        "lng": -89.8297601
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.847137,
        13.5676482,
        -89.8089444,
        13.6019893
      ]
    },
    {
      "id": 10339,
      "name": "Bauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7917,
        "lng": 121.0085
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9885,
        13.7717,
        121.0285,
        13.8117
      ]
    },
    {
      "id": 10340,
      "name": "San Sebastian de los Reyes",
      "names": {
        "zh": "圣塞巴斯蒂安德洛斯雷耶斯",
        "ja": "サンセバスチャンデロスレイ",
        "th": "ซานเซบาสเตียนเดอลอสเรเยส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.5473698,
        "lng": -3.6260586
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.6740915,
        40.5332037,
        -3.5378754,
        40.6310109
      ]
    },
    {
      "id": 10341,
      "name": "Terre Haute",
      "names": {
        "ja": "テレホート"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.4667025,
        "lng": -87.4139119
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.469721,
        39.401528,
        -87.276923,
        39.528696
      ]
    },
    {
      "id": 10342,
      "name": "Saint-Jean-sur-Richelieu",
      "names": {
        "zh": "圣让畔黎塞留",
        "ja": "サン・ジャン・シュルリシュリュー",
        "th": "เซนต์ฌองซูร์ Richelieu"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.3056879,
        "lng": -73.2533401
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.4121803,
        45.2336197,
        -73.1692225,
        45.4130868
      ]
    },
    {
      "id": 10343,
      "name": "Lavras",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2425512,
        "lng": -44.9991978
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.1818054,
        -21.401,
        -44.8645644,
        -21.1260307
      ]
    },
    {
      "id": 10344,
      "name": "Toms River",
      "names": {
        "fr": "Rivière Toms",
        "zh": "汤姆斯河",
        "id": "Sungai Toms",
        "ja": "トムズ川",
        "th": "แม่น้ำทอมส์",
        "pt": "Rio de toms"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.989522,
        "lng": -74.186982
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.27141,
        39.940635,
        -74.106461,
        40.059597
      ]
    },
    {
      "id": 10345,
      "name": "Ocozocoautla de Espinosa",
      "names": {
        "de": "OCOCOCOAUTLA DE Espinosa",
        "th": "ocozocoaautla de espinosa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7595989,
        "lng": -93.3738765
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.4138765,
        16.7195989,
        -93.3338765,
        16.7995989
      ]
    },
    {
      "id": 10346,
      "name": "Mosquera",
      "names": {
        "zh": "莫斯克拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7002001,
        "lng": -74.2385058
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3007671,
        4.6186349,
        -74.1568357,
        4.7370333
      ]
    },
    {
      "id": 10347,
      "name": "Suffolk",
      "names": {
        "zh": "萨福克",
        "ja": "サフォーク",
        "th": "ซัฟโฟล์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7427673,
        "lng": -76.6241825
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.3241825,
        36.0427673,
        -75.9241825,
        37.4427673
      ]
    },
    {
      "id": 10348,
      "name": "Kendu Bay",
      "names": {
        "fr": "Baie de Kendu",
        "zh": "肯德湾",
        "it": "Baia di Kendu",
        "id": "Teluk Kendu",
        "ja": "ケンド湾",
        "pt": "Baía de kendu"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.359579,
        "lng": 34.639994
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        34.6199938545,
        -0.379578838201,
        34.6599938545,
        -0.339578838201
      ]
    },
    {
      "id": 10349,
      "name": "Oss",
      "names": {
        "zh": "操作系统",
        "ja": "OSSの",
        "th": "โอเอส"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7652868,
        "lng": 5.531271
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.526271,
        51.7602868,
        5.536271,
        51.7702868
      ]
    },
    {
      "id": 10350,
      "name": "Clarington",
      "names": {
        "zh": "克拉灵顿"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.96868585,
        "lng": -78.65135738273909
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -78.8720279,
        43.8462309,
        -78.4319496,
        44.0987774
      ]
    },
    {
      "id": 10351,
      "name": "Satsumasendai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 31.813421,
        "lng": 130.3039789
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        129.2100012,
        31.4696203,
        130.5900481,
        32.1753186
      ]
    },
    {
      "id": 10352,
      "name": "Blacksburg",
      "names": {
        "zh": "布莱克斯堡",
        "th": "แบล็กส์เบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.2296566,
        "lng": -80.4136767
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.4806781,
        37.1838918,
        -80.3791226,
        37.2769328
      ]
    },
    {
      "id": 10353,
      "name": "Modi`in Makkabbim Re`ut",
      "names": {
        "fr": "Modi`in makkabbim re",
        "zh": "modi` makkabbim重新",
        "it": "Modi`in Makkabbim Re`t",
        "ja": "Mokkabim re`ut",
        "pt": "Modi`in makkabbim re`"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.89385,
        "lng": 35.01504
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.99504,
        31.87385,
        35.03504,
        31.91385
      ]
    },
    {
      "id": 10354,
      "name": "Duren",
      "names": {
        "es": "Düren",
        "fr": "Düren",
        "zh": "杜伦",
        "de": "Düren",
        "it": "Düren"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8031684,
        "lng": 6.4820806
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.3856331,
        50.7409797,
        6.5384131,
        50.8576045
      ]
    },
    {
      "id": 10355,
      "name": "Pickering",
      "names": {
        "zh": "皮克林",
        "ja": "ピカリング",
        "th": "พิกเคอริง"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.835765,
        "lng": -79.090576
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.2200381,
        43.7930746,
        -79.0085864,
        44.0107582
      ]
    },
    {
      "id": 10356,
      "name": "Southport",
      "names": {
        "zh": "绍斯波特",
        "ja": "サウスポート",
        "th": "เซาท์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6475988,
        "lng": -3.0041751
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.0441751,
        53.6075988,
        -2.9641751,
        53.6875988
      ]
    },
    {
      "id": 10357,
      "name": "Mount Pleasant",
      "names": {
        "es": "Agradable",
        "fr": "Monture agréable",
        "zh": "坐着愉快",
        "de": "Montieren",
        "it": "Montare piacevole",
        "id": "Mount menyenangkan",
        "ja": "マウントプレザント",
        "th": "เมาท์ที่น่าพอใจ",
        "pt": "Monte agradável"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7940651,
        "lng": -79.8625851
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.917163,
        32.76592,
        -79.714916,
        32.9309269
      ]
    },
    {
      "id": 10358,
      "name": "Mogi Mirim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.43194,
        "lng": -46.95778
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.97778,
        -22.45194,
        -46.93778,
        -22.41194
      ]
    },
    {
      "id": 10359,
      "name": "Weiyuan",
      "names": {
        "zh": "威远"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5303563,
        "lng": 104.6659458
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.5059458,
        29.3703563,
        104.8259458,
        29.6903563
      ]
    },
    {
      "id": 10360,
      "name": "Julu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.2170455,
        "lng": 115.0337736
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.8737736,
        37.0570455,
        115.1937736,
        37.3770455
      ]
    },
    {
      "id": 10361,
      "name": "Kavali",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.9135846,
        "lng": 79.9926902
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.8326902,
        14.7535846,
        80.1526902,
        15.0735846
      ]
    },
    {
      "id": 10362,
      "name": "Azua",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4532594,
        "lng": -70.7324011
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.8924011,
        18.2932594,
        -70.5724011,
        18.6132594
      ]
    },
    {
      "id": 10363,
      "name": "Salaman",
      "names": {
        "zh": "萨拉曼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6125193,
        "lng": 124.0648484
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.0448484,
        6.5925193,
        124.0848484,
        6.6325193
      ]
    },
    {
      "id": 10364,
      "name": "Flensburg",
      "names": {
        "zh": "弗伦斯堡",
        "ja": "フレンスブルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.781286,
        "lng": 9.433995
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.433945,
        54.781236,
        9.434045,
        54.781336
      ]
    },
    {
      "id": 10365,
      "name": "Talara",
      "names": {
        "zh": "塔拉拉"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -4.5796872,
        "lng": -81.2718335
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -81.4318335,
        -4.7396872,
        -81.1118335,
        -4.4196872
      ]
    },
    {
      "id": 10366,
      "name": "Yao",
      "names": {
        "zh": "姚明",
        "ja": "ヤオ",
        "th": "ยาว"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.851009,
        "lng": 17.559304
      },
      "country": "Chad",
      "importance": 3,
      "bbox": [
        17.399304,
        12.691009,
        17.719304,
        13.011009
      ]
    },
    {
      "id": 10367,
      "name": "Penaflor",
      "names": {
        "zh": "佩尼亚弗洛尔"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6058638,
        "lng": -70.8785306
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -70.9678545,
        -33.6463859,
        -70.81572,
        -33.5752889
      ]
    },
    {
      "id": 10368,
      "name": "Votuporanga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4252908,
        "lng": -49.972028
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.1556876,
        -20.5759435,
        -49.8519981,
        -20.3151698
      ]
    },
    {
      "id": 10369,
      "name": "Jalalpur Jattan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.64045215,
        "lng": 74.20851907318577
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        74.1914711,
        32.6275571,
        74.2288571,
        32.6534318
      ]
    },
    {
      "id": 10370,
      "name": "Cacapava",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.099204,
        "lng": -45.707645
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.8424821,
        -23.239241,
        -45.5937774,
        -22.969
      ]
    },
    {
      "id": 10371,
      "name": "Carson",
      "names": {
        "zh": "卡森",
        "ja": "カーソン",
        "th": "คาร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8322043,
        "lng": -118.2517547
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.2887735,
        33.7925855,
        -118.2059253,
        33.8861886
      ]
    },
    {
      "id": 10372,
      "name": "Tubingen",
      "names": {
        "es": "De Tubinga",
        "fr": "Tübingen",
        "zh": "蒂宾根",
        "de": "Tübingen",
        "id": "Tübingen",
        "ja": "テュービンゲン",
        "pt": "Tübingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5203263,
        "lng": 9.053596
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.9645157,
        48.4505844,
        9.1310745,
        48.5937129
      ]
    },
    {
      "id": 10373,
      "name": "Greenburgh",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.011132,
        "lng": -73.866885
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.893579,
        40.997989,
        -73.845334,
        41.024792
      ]
    },
    {
      "id": 10374,
      "name": "Sao Felix do Xingu",
      "names": {
        "es": "SAO FELIX DO XINGUS",
        "zh": "Sao Felix做Xingu",
        "it": "Sao Felix fa Xingu",
        "th": "เซาเฟลิกซ์ทำซิงู"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.6408469,
        "lng": -51.9944631
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.9945131,
        -6.6408969,
        -51.9944131,
        -6.6407969
      ]
    },
    {
      "id": 10375,
      "name": "Caceres",
      "names": {
        "es": "Cáceres",
        "zh": "卡塞雷斯",
        "id": "Cáceres",
        "ja": "カセレス",
        "th": "คาเซเรส",
        "pt": "Cáceres"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.0644068,
        "lng": -57.6878972
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -58.796,
        -17.6270095,
        -57.007454,
        -15.4545028
      ]
    },
    {
      "id": 10376,
      "name": "Maladzyechna",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.3167,
        "lng": 26.854
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.834,
        54.2967,
        26.874,
        54.3367
      ]
    },
    {
      "id": 10377,
      "name": "Ad Dakhla",
      "names": {
        "es": "Dakhla ad",
        "zh": "广告达克拉",
        "ja": "Dakhla",
        "th": "โฆษณา Dakhla"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 23.6940663,
        "lng": -15.9431274
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -16.0069916,
        23.6271591,
        -15.8838879,
        23.8195717
      ]
    },
    {
      "id": 10378,
      "name": "Conroe",
      "names": {
        "zh": "康罗",
        "ja": "コンロー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3118769,
        "lng": -95.4560512
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.6567012,
        30.2026589,
        -95.3909429,
        30.4199903
      ]
    },
    {
      "id": 10379,
      "name": "Alafaya",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 28.53057,
        "lng": -81.178021
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.244462,
        28.451472,
        -81.108195,
        28.568668
      ]
    },
    {
      "id": 10380,
      "name": "Agua Prieta",
      "names": {
        "fr": "Agua Preieta",
        "ja": "アグアプリベ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3305591,
        "lng": -109.5564331
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.7164331,
        31.1705591,
        -109.3964331,
        31.4905591
      ]
    },
    {
      "id": 10381,
      "name": "Chauk",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 20.8947519,
        "lng": 94.8198211
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        94.6845543,
        20.5267199,
        95.0421884,
        20.9405164
      ]
    },
    {
      "id": 10382,
      "name": "Imizucho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.76835,
        "lng": 137.12572
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.10572,
        36.74835,
        137.14572,
        36.78835
      ]
    },
    {
      "id": 10383,
      "name": "Itajuba",
      "names": {
        "pt": "Itajubá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4238234,
        "lng": -45.4524156
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.5313157,
        -22.539945,
        -45.2623815,
        -22.3386797
      ]
    },
    {
      "id": 10384,
      "name": "Pongotan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.15,
        "lng": 125.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10385,
      "name": "Livermore",
      "names": {
        "zh": "利弗莫尔",
        "ja": "リバモア",
        "th": "ลิเวอร์มอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6849808,
        "lng": -121.7670842
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.7720842,
        37.6799808,
        -121.7620842,
        37.6899808
      ]
    },
    {
      "id": 10386,
      "name": "Caimbambo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.01667,
        "lng": 14
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.98,
        -13.03667,
        14.02,
        -12.99667
      ]
    },
    {
      "id": 10387,
      "name": "Cambambe",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -9.74269,
        "lng": 14.48821
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.46821,
        -9.76269,
        14.50821,
        -9.72269
      ]
    },
    {
      "id": 10388,
      "name": "Victorias",
      "names": {
        "zh": "维多利亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9013412,
        "lng": 123.0714943
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0306232,
        10.729508,
        123.2222753,
        11.0047328
      ]
    },
    {
      "id": 10389,
      "name": "Pinamalayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13,
        "lng": 121.41667
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.31667,
        12.9,
        121.51667,
        13.1
      ]
    },
    {
      "id": 10390,
      "name": "Chililabombwe",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.3681241,
        "lng": 27.8357281
      },
      "country": "Zambia",
      "importance": 3,
      "bbox": [
        27.7957281,
        -12.4081241,
        27.8757281,
        -12.3281241
      ]
    },
    {
      "id": 10391,
      "name": "Pisa",
      "names": {
        "fr": "Pise",
        "zh": "比萨",
        "ja": "ピサ",
        "th": "ปิซา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.7159395,
        "lng": 10.4018624
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.2684553,
        43.5807231,
        10.4562831,
        43.7509347
      ]
    },
    {
      "id": 10392,
      "name": "Fundacion",
      "names": {
        "es": "La Fundación",
        "fr": "La Fondation",
        "zh": "基金会",
        "de": "Fundación",
        "pt": "Fundación"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.5155669,
        "lng": -74.1901088
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3501088,
        10.3555669,
        -74.0301088,
        10.6755669
      ]
    },
    {
      "id": 10393,
      "name": "Sao Joao del Rei",
      "names": {
        "ja": "サンジョアオデルレイ",
        "pt": "São João del Rei"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1335755,
        "lng": -44.2588086
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.574829,
        -21.435375,
        -43.98824,
        -21.040523
      ]
    },
    {
      "id": 10394,
      "name": "Ma`arrat an Nu`man",
      "names": {
        "es": "Ma`arrat un nu'man",
        "fr": "Ma`arrat un nu`man",
        "zh": "Ma`arrat a nu`man",
        "de": "Ma`arrrat ein nu`sman",
        "it": "Ma è un nuzono",
        "ja": "ええとヌーマン",
        "pt": "Ma`rat um nu`"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.6483,
        "lng": 36.67693
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.65693,
        35.6283,
        36.69693,
        35.6683
      ]
    },
    {
      "id": 10395,
      "name": "Nanaimo",
      "names": {
        "zh": "纳奈莫",
        "ja": "ナナイモ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.163877,
        "lng": -123.938122
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -124.065062,
        49.0972854,
        -123.8007369,
        49.2521065
      ]
    },
    {
      "id": 10396,
      "name": "Mancheral",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.873179,
        "lng": 79.448464
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.431808,
        18.854096,
        79.468297,
        18.903015
      ]
    },
    {
      "id": 10397,
      "name": "Chalchuapa",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.9853216,
        "lng": -89.6802434
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.746032,
        13.8503597,
        -89.6250206,
        14.1573963
      ]
    },
    {
      "id": 10398,
      "name": "Kansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.205997,
        "lng": 95.706787
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        95.6057985,
        56.1554827,
        95.7994786,
        56.2750947
      ]
    },
    {
      "id": 10399,
      "name": "Kiselevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.99,
        "lng": 86.6621
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        86.6421,
        53.97,
        86.6821,
        54.01
      ]
    },
    {
      "id": 10400,
      "name": "Zwickau",
      "names": {
        "zh": "茨维考",
        "ja": "ツヴィッカウ",
        "th": "ซฟิคเคา"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7185043,
        "lng": 12.4939267
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.4083761,
        50.6417164,
        12.5659556,
        50.8128393
      ]
    },
    {
      "id": 10401,
      "name": "Hezuo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0013957,
        "lng": 102.90827
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.74827,
        34.8413957,
        103.06827,
        35.1613957
      ]
    },
    {
      "id": 10402,
      "name": "Pistoia",
      "names": {
        "zh": "皮斯托亚",
        "ja": "ピストイア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.9336213,
        "lng": 10.9174238
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.8052852,
        43.8724545,
        11.0153344,
        44.100492
      ]
    },
    {
      "id": 10403,
      "name": "New Braunfels",
      "names": {
        "es": "Nuevos Braunfels",
        "fr": "Nouveaux Braunfels",
        "zh": "新的Braunfels",
        "de": "Neue Braunfels",
        "it": "Nuovi Braunfels",
        "id": "Braunfels baru",
        "ja": "新しいブラウンフェルズ",
        "th": "ใหม่ Braunfels",
        "pt": "Braunfels novos"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.7028266,
        "lng": -98.1257348
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.248179,
        29.6305241,
        -98.0276573,
        29.7769818
      ]
    },
    {
      "id": 10404,
      "name": "Compostela",
      "names": {
        "fr": "Compostelle",
        "zh": "波斯特拉",
        "ja": "コンポステラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6675008,
        "lng": 126.0856272
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        126.0456272,
        7.6275008,
        126.1256272,
        7.7075008
      ]
    },
    {
      "id": 10405,
      "name": "Ocana",
      "names": {
        "es": "Ocaña",
        "zh": "奥卡尼亚",
        "de": "Ocaña",
        "pt": "Ocaña"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2351887,
        "lng": -73.3538441
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.526818,
        8.0124574,
        -73.2500289,
        8.4271532
      ]
    },
    {
      "id": 10406,
      "name": "Mihara",
      "names": {
        "zh": "三原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3974407,
        "lng": 133.0785046
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.847402,
        34.3038389,
        133.162492,
        34.59367
      ]
    },
    {
      "id": 10407,
      "name": "Kairana",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.394447,
        "lng": 77.2052786
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.1652786,
        29.354447,
        77.2452786,
        29.434447
      ]
    },
    {
      "id": 10408,
      "name": "Uacu Cungo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -11.35669,
        "lng": 15.11719
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10409,
      "name": "Luau",
      "names": {
        "zh": "卢奥",
        "ja": "ルアウ"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -10.7025518,
        "lng": 22.2275381
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        22.1875381,
        -10.7425518,
        22.2675381,
        -10.6625518
      ]
    },
    {
      "id": 10410,
      "name": "Kadiri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.1146149,
        "lng": 78.1653971
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.1603971,
        14.1096149,
        78.1703971,
        14.1196149
      ]
    },
    {
      "id": 10411,
      "name": "Norrkoping",
      "names": {
        "fr": "Norrköping",
        "zh": "诺尔雪平",
        "th": "นอร์เชอปิง"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.5909124,
        "lng": 16.1903511
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        16.0303511,
        58.4309124,
        16.3503511,
        58.7509124
      ]
    },
    {
      "id": 10412,
      "name": "Polangui",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2923,
        "lng": 123.4855
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4655,
        13.2723,
        123.5055,
        13.3123
      ]
    },
    {
      "id": 10413,
      "name": "Hosaina",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.54978,
        "lng": 37.85374
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        37.83374,
        7.52978,
        37.87374,
        7.56978
      ]
    },
    {
      "id": 10414,
      "name": "Giessen",
      "names": {
        "zh": "吉森",
        "de": "Gießen",
        "th": "กีสเซิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5798685,
        "lng": 8.6622116
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6572116,
        50.5748685,
        8.6672116,
        50.5848685
      ]
    },
    {
      "id": 10415,
      "name": "Bogo",
      "names": {
        "zh": "博戈",
        "de": "Bogø"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0512735,
        "lng": 124.0035064
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.8435064,
        10.8912735,
        124.1635064,
        11.2112735
      ]
    },
    {
      "id": 10416,
      "name": "Lucca",
      "names": {
        "fr": "Lucques",
        "zh": "卢卡",
        "ja": "ルッカ",
        "th": "ลูกา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8428381,
        "lng": 10.502876
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.3627442,
        43.7582935,
        10.5737641,
        43.9600946
      ]
    },
    {
      "id": 10417,
      "name": "Hilversum",
      "names": {
        "zh": "希尔沃叙姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2265342,
        "lng": 5.1812974
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.1762974,
        52.2215342,
        5.1862974,
        52.2315342
      ]
    },
    {
      "id": 10418,
      "name": "Chosica",
      "names": {
        "zh": "乔西卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -11.9367735,
        "lng": -76.6969111
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -76.7369111,
        -11.9767735,
        -76.6569111,
        -11.8967735
      ]
    },
    {
      "id": 10419,
      "name": "Leping",
      "names": {
        "zh": "乐平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.6067422,
        "lng": 113.6920334
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.6520334,
        37.5667422,
        113.7320334,
        37.6467422
      ]
    },
    {
      "id": 10420,
      "name": "El Puerto de Santa Maria",
      "names": {
        "es": "El Puerto de Santa María"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6041356,
        "lng": -6.216813
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.216863,
        36.6040856,
        -6.216763,
        36.6041856
      ]
    },
    {
      "id": 10421,
      "name": "Serdar",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 38.9748448,
        "lng": 56.2765852
      },
      "country": "Turkmenistan",
      "importance": 3,
      "bbox": [
        56.2468614,
        38.9400626,
        56.3129878,
        39.0200323
      ]
    },
    {
      "id": 10422,
      "name": "Cantaura",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.300007,
        "lng": -64.3521226
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -64.5121226,
        9.140007,
        -64.1921226,
        9.460007
      ]
    },
    {
      "id": 10423,
      "name": "Kamensk-Shakhtinskiy",
      "names": {
        "zh": "卡缅斯克-shakhtinskiy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.3181,
        "lng": 40.25828
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 10424,
      "name": "Fall River",
      "names": {
        "es": "Río de otoño",
        "fr": "Fleuve automne",
        "zh": "秋天河",
        "de": "Herbstriss",
        "it": "Caduta del fiume",
        "id": "Jadghir",
        "ja": "秋川",
        "th": "ฝั่งแม่น้ำ",
        "pt": "Rio de outono"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7010642,
        "lng": -71.1546367
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.2013109,
        41.6036774,
        -71.0095415,
        41.7689808
      ]
    },
    {
      "id": 10425,
      "name": "Banga",
      "names": {
        "zh": "邦加"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.4232286,
        "lng": 124.7761628
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.7361628,
        6.3832286,
        124.8161628,
        6.4632286
      ]
    },
    {
      "id": 10426,
      "name": "Conda",
      "names": {
        "zh": "康达"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -11.108618,
        "lng": 14.33621
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.31621,
        -11.128618,
        14.35621,
        -11.088618
      ]
    },
    {
      "id": 10427,
      "name": "Surallah",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.36667,
        "lng": 124.73333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.71333,
        6.34667,
        124.75333,
        6.38667
      ]
    },
    {
      "id": 10428,
      "name": "Gitarama",
      "names": {},
      "countryCode": "RW",
      "latLng": {
        "lat": -2.07444,
        "lng": 29.75667
      },
      "country": "Rwanda",
      "importance": 3,
      "bbox": [
        29.73667,
        -2.09444,
        29.77667,
        -2.05444
      ]
    },
    {
      "id": 10429,
      "name": "Hinigaran",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2713832,
        "lng": 122.8519909
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7039889,
        10.2022438,
        122.9949872,
        10.361926
      ]
    },
    {
      "id": 10430,
      "name": "Calabanga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.708545,
        "lng": 123.2157561
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.1757561,
        13.668545,
        123.2557561,
        13.748545
      ]
    },
    {
      "id": 10431,
      "name": "Prijedor",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.97991,
        "lng": 16.71401
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        16.69401,
        44.95991,
        16.73401,
        44.99991
      ]
    },
    {
      "id": 10432,
      "name": "Lere",
      "names": {
        "zh": "莱雷"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 10.36175115,
        "lng": 8.572399288003599
      },
      "country": "Chad",
      "importance": 3,
      "bbox": [
        8.3198502,
        9.983943,
        8.8235216,
        10.7365803
      ]
    },
    {
      "id": 10433,
      "name": "Passi",
      "names": {
        "zh": "帕西"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1173503,
        "lng": 122.6468706
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4868706,
        10.9573503,
        122.8068706,
        11.2773503
      ]
    },
    {
      "id": 10434,
      "name": "Albany",
      "names": {
        "zh": "奥尔巴尼",
        "ja": "アルバニー",
        "th": "ออลบานี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.5782062,
        "lng": -84.1556809
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.2729886,
        31.5223841,
        -84.058641,
        31.6230413
      ]
    },
    {
      "id": 10435,
      "name": "Shwebo",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 22.5834446,
        "lng": 95.6944906
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.6894906,
        22.5784446,
        95.6994906,
        22.5884446
      ]
    },
    {
      "id": 10436,
      "name": "Humpata",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -15.0103622,
        "lng": 13.3766075
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.2092846,
        -15.3396764,
        13.5275706,
        -14.84829
      ]
    },
    {
      "id": 10437,
      "name": "Sao Joao da Boa Vista",
      "names": {
        "ja": "サンヨーオダボアビスタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.9687099,
        "lng": -46.7969216
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.9466992,
        -22.0953109,
        -46.6495472,
        -21.8376988
      ]
    },
    {
      "id": 10438,
      "name": "Murcia",
      "names": {
        "zh": "穆尔西亚",
        "ja": "ムルシア",
        "th": "มูร์เซีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6066483,
        "lng": 123.0404676
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9769706,
        10.4584392,
        123.2357928,
        10.6568414
      ]
    },
    {
      "id": 10439,
      "name": "Qiantangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.74221,
        "lng": 112.928
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.908,
        22.72221,
        112.948,
        22.76221
      ]
    },
    {
      "id": 10440,
      "name": "Candeias",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6693806,
        "lng": -38.5406462
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.5610147,
        -12.7913225,
        -38.3920455,
        -12.5887035
      ]
    },
    {
      "id": 10441,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 37.7792588,
        "lng": -122.4193286
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -123.173825,
        37.6403143,
        -122.281479,
        37.929811
      ]
    },
    {
      "id": 10442,
      "name": "Calauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.15,
        "lng": 121.31667
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.21667,
        14.05,
        121.41667,
        14.25
      ]
    },
    {
      "id": 10443,
      "name": "Bhakkar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.6303103,
        "lng": 71.0676914
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        70.9076914,
        31.4703103,
        71.2276914,
        31.7903103
      ]
    },
    {
      "id": 10444,
      "name": "Dunkerque",
      "names": {
        "zh": "敦刻尔克"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 51.0347708,
        "lng": 2.3772525
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2396964,
        50.9759776,
        2.4464717,
        51.0581006
      ]
    },
    {
      "id": 10445,
      "name": "Solana",
      "names": {
        "zh": "索拉纳",
        "ja": "ソラナ",
        "th": "โซลานา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.6509356,
        "lng": 121.6909171
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6509171,
        17.6109356,
        121.7309171,
        17.6909356
      ]
    },
    {
      "id": 10446,
      "name": "Dongchuan",
      "names": {
        "zh": "东川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.5089895,
        "lng": 101.2400685
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.2000685,
        25.4689895,
        101.2800685,
        25.5489895
      ]
    },
    {
      "id": 10447,
      "name": "Norwalk",
      "names": {
        "zh": "诺瓦克",
        "th": "วอล์ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1175966,
        "lng": -73.4078968
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.4745657,
        41.020449,
        -73.3802856,
        41.1712683
      ]
    },
    {
      "id": 10448,
      "name": "Madgaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.276433,
        "lng": 73.970103
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.952348,
        15.267769,
        73.997777,
        15.288564
      ]
    },
    {
      "id": 10449,
      "name": "Halifax",
      "names": {
        "zh": "哈利法克斯",
        "ja": "ハリファックス",
        "th": "แฮลิแฟกซ์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7229229,
        "lng": -1.8604874
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.9004874,
        53.6829229,
        -1.8204874,
        53.7629229
      ]
    },
    {
      "id": 10450,
      "name": "Heerlen",
      "names": {
        "zh": "海尔伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8775239,
        "lng": 5.981506585454879
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8954092,
        50.8184032,
        6.0263794,
        50.9366122
      ]
    },
    {
      "id": 10451,
      "name": "Montelibano",
      "names": {
        "zh": "蒙特利瓦诺"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9800534,
        "lng": -75.4167198
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0574758,
        7.357218,
        -75.321569,
        8.1656915
      ]
    },
    {
      "id": 10452,
      "name": "Koka",
      "names": {
        "zh": "•科卡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9625992,
        "lng": 136.1655415
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.940896,
        34.790571,
        136.421364,
        35.030673
      ]
    },
    {
      "id": 10453,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 6.0428128,
        "lng": -74.9944061
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -75.0872344,
        5.9129741,
        -74.7653598,
        6.129142
      ]
    },
    {
      "id": 10454,
      "name": "O'Fallon",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 38.7902503,
        "lng": -90.7033292
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.82098,
        38.7127263,
        -90.649074,
        38.8733246
      ]
    },
    {
      "id": 10455,
      "name": "Baras",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5218512,
        "lng": 121.2658068
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2258068,
        14.4818512,
        121.3058068,
        14.5618512
      ]
    },
    {
      "id": 10456,
      "name": "Kadirli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3739591,
        "lng": 36.0974518
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.8764549,
        37.174211,
        36.285112,
        37.7497797
      ]
    },
    {
      "id": 10457,
      "name": "Maravatio de Ocampo",
      "names": {
        "ja": "マラティオデオカンポ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8942123,
        "lng": -100.4423505
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.4823505,
        19.8542123,
        -100.4023505,
        19.9342123
      ]
    },
    {
      "id": 10458,
      "name": "Ootacamund",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.408076,
        "lng": 76.702019
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.672916,
        11.396087,
        76.727358,
        11.426395
      ]
    },
    {
      "id": 10459,
      "name": "Sakiet ez Zit",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 34.80194,
        "lng": 10.76361
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.74361,
        34.78194,
        10.78361,
        34.82194
      ]
    },
    {
      "id": 10460,
      "name": "Aroroy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5118501,
        "lng": 123.3975552
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3575552,
        12.4718501,
        123.4375552,
        12.5518501
      ]
    },
    {
      "id": 10461,
      "name": "Grimsby",
      "names": {
        "zh": "格里姆斯比",
        "th": "กริมสบี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5671407,
        "lng": -0.0788045
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.1188045,
        53.5271407,
        -0.0388045,
        53.6071407
      ]
    },
    {
      "id": 10462,
      "name": "Echague",
      "names": {
        "es": "Echagüe"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.70536,
        "lng": 121.6749455
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6349455,
        16.66536,
        121.7149455,
        16.74536
      ]
    },
    {
      "id": 10463,
      "name": "La Grita",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.13219,
        "lng": -71.98337
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -72.00337,
        8.11219,
        -71.96337,
        8.15219
      ]
    },
    {
      "id": 10464,
      "name": "San Fabian",
      "names": {
        "zh": "圣法比安",
        "ja": "サンファビアン",
        "th": "ซานเฟเฟอร์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1221224,
        "lng": 120.4032449
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3632449,
        16.0821224,
        120.4432449,
        16.1621224
      ]
    },
    {
      "id": 10465,
      "name": "Avare",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.095925,
        "lng": -48.925221
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.986602,
        -23.147193,
        -48.872985,
        -23.058681
      ]
    },
    {
      "id": 10466,
      "name": "Newton",
      "names": {
        "zh": "牛顿",
        "ja": "ニュートン",
        "th": "นิวตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3370414,
        "lng": -71.2092214
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.2703252,
        42.2829427,
        -71.1568867,
        42.3678252
      ]
    },
    {
      "id": 10467,
      "name": "Zhob",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.3397058,
        "lng": 69.4476997
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        69.2876997,
        31.1797058,
        69.6076997,
        31.4997058
      ]
    },
    {
      "id": 10468,
      "name": "Sakai",
      "names": {
        "zh": "堺",
        "ja": "堺",
        "th": "ซาไก"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1677146,
        "lng": 136.2262832
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.762275,
        36.102257,
        136.440387,
        36.451457
      ]
    },
    {
      "id": 10469,
      "name": "Pingyuanjie",
      "names": {
        "zh": "平远街"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7475,
        "lng": 103.7525
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.7325,
        23.7275,
        103.7725,
        23.7675
      ]
    },
    {
      "id": 10470,
      "name": "Maiquetia",
      "names": {
        "es": "Maiquetía",
        "zh": "迈克蒂亚",
        "pt": "Maiquetía"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.598104,
        "lng": -66.968364
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.068364,
        10.498104,
        -66.868364,
        10.698104
      ]
    },
    {
      "id": 10471,
      "name": "Qal`at Bishah",
      "names": {
        "zh": "QAL毕马",
        "de": "Qal`at Bischah",
        "it": "Qal`t Bishah",
        "th": "qal`at bishaah",
        "pt": "QAL`T BISHAH"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 20.008687,
        "lng": 42.598681
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        42.5786811918,
        19.9886869496,
        42.6186811918,
        20.0286869496
      ]
    },
    {
      "id": 10472,
      "name": "Tacana",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.23333,
        "lng": -92.08333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.10333,
        15.21333,
        -92.06333,
        15.25333
      ]
    },
    {
      "id": 10473,
      "name": "Ratingen",
      "names": {
        "zh": "拉廷根",
        "ja": "ラティンゲン",
        "th": "เรติงเกน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2973261,
        "lng": 6.8493503
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.8012365,
        51.2645253,
        6.9830942,
        51.3717943
      ]
    },
    {
      "id": 10474,
      "name": "Makilala",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9591335,
        "lng": 125.089577
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.98917,
        6.8394786,
        125.2707835,
        7.0061021
      ]
    },
    {
      "id": 10475,
      "name": "Ponta Pora",
      "names": {
        "it": "Ponta Pora Pora",
        "ja": "ポンタポラ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5286917,
        "lng": -55.723426
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.111294,
        -22.7642905,
        -54.9764267,
        -21.633989
      ]
    },
    {
      "id": 10476,
      "name": "Changting",
      "names": {
        "zh": "长汀"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.8318013,
        "lng": 116.3393861
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        116.3343861,
        25.8268013,
        116.3443861,
        25.8368013
      ]
    },
    {
      "id": 10477,
      "name": "Faridkot",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.674322,
        "lng": 74.758927
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.730317,
        30.652906,
        74.778929,
        30.695172
      ]
    },
    {
      "id": 10478,
      "name": "Sao Pedro da Aldeia",
      "names": {
        "ja": "サンペドロダアルデイア",
        "pt": "São Pedro da Aldeia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8384835,
        "lng": -42.1031604
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.2538856,
        -22.9093126,
        -42.0249981,
        -22.6533646
      ]
    },
    {
      "id": 10479,
      "name": "Calaca",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.93244,
        "lng": 120.81327
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.79327,
        13.91244,
        120.83327,
        13.95244
      ]
    },
    {
      "id": 10480,
      "name": "Leshou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.18966,
        "lng": 116.12394
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10481,
      "name": "Sinjar",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.318391,
        "lng": 41.86124
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        41.838128,
        36.304347,
        41.889689,
        36.33241
      ]
    },
    {
      "id": 10482,
      "name": "Jilotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0169568,
        "lng": -99.6281796339068
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.7364591,
        19.8528732,
        -99.4326922,
        20.1808205
      ]
    },
    {
      "id": 10483,
      "name": "Sudbury",
      "names": {
        "zh": "萨德伯里",
        "ja": "サドバリー",
        "th": "เบอรี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.49272,
        "lng": -80.991211
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -81.5984276,
        46.1958356,
        -80.5488232,
        46.973056
      ]
    },
    {
      "id": 10484,
      "name": "Sao Goncalo do Amarante",
      "names": {
        "zh": "圣冈卡罗做苋菜",
        "id": "Sao Goncalo melakukan amarahe",
        "ja": "サンゴンカロはアマランテをします",
        "th": "Sao Goncalo ทำ Amarante",
        "pt": "São Gonçalo do Amarante"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.7932331,
        "lng": -35.3286312
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.4715851,
        -5.8490885,
        -35.2475056,
        -5.7000653
      ]
    },
    {
      "id": 10485,
      "name": "Jatai",
      "names": {
        "pt": "Jataí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.8856178,
        "lng": -51.7202967
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.306,
        -18.5384271,
        -51.1552087,
        -17.269
      ]
    },
    {
      "id": 10486,
      "name": "Camiling",
      "names": {
        "zh": "卡米灵"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6864622,
        "lng": 120.414656
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3053471,
        15.6179872,
        120.4933927,
        15.7587454
      ]
    },
    {
      "id": 10487,
      "name": "Wislane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 50.05517425,
        "lng": 19.961608589908906
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        19.9600852,
        50.0540157,
        19.9632502,
        50.0565148
      ]
    },
    {
      "id": 10488,
      "name": "Buzuluk",
      "names": {
        "zh": "布祖卢克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.7586837,
        "lng": 52.28831670221607
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        52.1934457,
        52.6914299,
        52.3722574,
        52.8272091
      ]
    },
    {
      "id": 10489,
      "name": "Muncie",
      "names": {
        "zh": "曼西",
        "th": "มันซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1931049,
        "lng": -85.3873646
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.3874146,
        40.1930549,
        -85.3873146,
        40.1931549
      ]
    },
    {
      "id": 10490,
      "name": "Jaguey Grande",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5268519,
        "lng": -81.1287426
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -81.3521676,
        22.4254584,
        -80.9397127,
        22.7926488
      ]
    },
    {
      "id": 10491,
      "name": "Dipalpur",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.670634,
        "lng": 73.653367
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.633871,
        30.659783,
        73.668477,
        30.68112
      ]
    },
    {
      "id": 10492,
      "name": "Baggao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.91667,
        "lng": 121.96667
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.86667,
        17.81667,
        122.06667,
        18.01667
      ]
    },
    {
      "id": 10493,
      "name": "Santa Cruz do Capibaribe",
      "names": {
        "fr": "Santa Cruz Do CapliBaribe",
        "zh": "圣克鲁斯做Capibaribe",
        "ja": "サンタクルーズはカプリバリビです",
        "th": "Santa Cruz ทำ Capibaribe"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.948019,
        "lng": -36.206092
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.4462964,
        -7.9684769,
        -36.1649433,
        -7.8057182
      ]
    },
    {
      "id": 10494,
      "name": "Lunen",
      "names": {
        "es": "Lünen",
        "de": "Lünen",
        "pt": "Lünen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6142482,
        "lng": 7.5228088
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.4178843,
        51.578018,
        7.5923255,
        51.6526727
      ]
    },
    {
      "id": 10495,
      "name": "Anakapalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.69135,
        "lng": 83.005204
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.996609,
        17.67857,
        83.020602,
        17.705992
      ]
    },
    {
      "id": 10496,
      "name": "Pergamino",
      "names": {
        "zh": "佩尔加米诺"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.897495,
        "lng": -60.575046
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.6385438,
        -33.9312902,
        -60.5057342,
        -33.8528896
      ]
    },
    {
      "id": 10497,
      "name": "Fukuroi",
      "names": {
        "zh": "袋井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7500522,
        "lng": 137.9258871
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.851249,
        34.5190909,
        138.009671,
        34.822359
      ]
    },
    {
      "id": 10498,
      "name": "Binmaley",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.03232,
        "lng": 120.26904
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.24904,
        16.01232,
        120.28904,
        16.05232
      ]
    },
    {
      "id": 10499,
      "name": "Consolacion del Sur",
      "names": {
        "id": "Konsolasion del sur",
        "ja": "コンソリシオンデルサー",
        "pt": "Consolação del sur"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5040475,
        "lng": -83.5154619
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -83.6873021,
        22.1832853,
        -83.2674674,
        22.6438186
      ]
    },
    {
      "id": 10500,
      "name": "Koidu-Bulma",
      "names": {
        "zh": "koidu-布尔玛"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.41667,
        "lng": -10.83333
      },
      "country": "Sierra Leone",
      "importance": 3,
      "bbox": [
        -10.85333,
        8.39667,
        -10.81333,
        8.43667
      ]
    },
    {
      "id": 10501,
      "name": "Guadalajara",
      "names": {
        "zh": "瓜达拉哈拉",
        "ja": "グアダラハラ",
        "th": "กวาดาลาฮารา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.6326979,
        "lng": -3.1646067
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -3.3141493,
        40.4608341,
        -2.98119,
        40.7707222
      ]
    },
    {
      "id": 10502,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.2560802,
        "lng": -79.8728583
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.2485579,
        43.0505073,
        -79.6221146,
        43.4706805
      ]
    },
    {
      "id": 10503,
      "name": "Paredes",
      "names": {
        "zh": "帕雷德斯"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2078862,
        "lng": -8.3332321
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.473972,
        41.0682928,
        -8.2961957,
        41.2597715
      ]
    },
    {
      "id": 10504,
      "name": "Brindisi",
      "names": {
        "zh": "布林迪西",
        "ja": "ブリンディジ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6375408,
        "lng": 17.9454957
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        17.731663,
        40.4784222,
        18.0452809,
        40.708478
      ]
    },
    {
      "id": 10505,
      "name": "Nabua",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4075246,
        "lng": 123.3730644
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.2905119,
        13.3637245,
        123.4229039,
        13.4293837
      ]
    },
    {
      "id": 10506,
      "name": "Town 'n' Country",
      "names": {
        "es": "País de la ciudad",
        "fr": "Ville 'n' pays",
        "zh": "镇'n'国家",
        "de": "Stadt 'n' Land",
        "it": "Paese 'n' country",
        "id": "Negara 'n'",
        "ja": "町の国",
        "th": "ประเทศ 'n' ประเทศ",
        "pt": "Cidade 'n' país"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0105745,
        "lng": -82.5773193
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.5973193,
        27.9905745,
        -82.5573193,
        28.0305745
      ]
    },
    {
      "id": 10507,
      "name": "Mijas",
      "names": {
        "zh": "米哈斯",
        "ja": "ミハス"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.595649,
        "lng": -4.6357
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.648691,
        36.591937,
        -4.626824,
        36.604347
      ]
    },
    {
      "id": 10508,
      "name": "Tiflet",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.894009,
        "lng": -6.31673
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -6.3506317,
        33.8797804,
        -6.2957465,
        33.9125668
      ]
    },
    {
      "id": 10509,
      "name": "Mobara",
      "names": {
        "zh": "茂原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4285094,
        "lng": 140.2880753
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.244446,
        35.379403,
        140.381357,
        35.502819
      ]
    },
    {
      "id": 10510,
      "name": "Bantayan",
      "names": {
        "zh": "班塔延岛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1683,
        "lng": 123.7223
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7023,
        11.1483,
        123.7423,
        11.1883
      ]
    },
    {
      "id": 10511,
      "name": "Chongoroi",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.56667,
        "lng": 13.95
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        13.93,
        -13.58667,
        13.97,
        -13.54667
      ]
    },
    {
      "id": 10512,
      "name": "Hounde",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 11.5,
        "lng": -3.51667
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -3.53667,
        11.48,
        -3.49667,
        11.52
      ]
    },
    {
      "id": 10513,
      "name": "Decatur",
      "names": {
        "zh": "迪凯特",
        "ja": "ディケーター",
        "th": "ดีเคเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8454163,
        "lng": -88.9524151
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.0323691,
        39.7867965,
        -88.8032028,
        39.9249642
      ]
    },
    {
      "id": 10514,
      "name": "Itabaiana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.6856197,
        "lng": -37.4270517
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.53,
        -10.831039,
        -37.3200403,
        -10.566
      ]
    },
    {
      "id": 10515,
      "name": "Heyunkeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.95333,
        "lng": 112.98083
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        112.96083,
        23.93333,
        113.00083,
        23.97333
      ]
    },
    {
      "id": 10516,
      "name": "Nova Lima",
      "names": {
        "ja": "ノヴァリマ",
        "th": "โนวาลิมา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9854089,
        "lng": -43.8470691
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.0023324,
        -20.225,
        -43.783,
        -19.927
      ]
    },
    {
      "id": 10517,
      "name": "Wulan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5596305,
        "lng": 104.6822413
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.5222413,
        36.3996305,
        104.8422413,
        36.7196305
      ]
    },
    {
      "id": 10518,
      "name": "Sao Cristovao",
      "names": {
        "zh": "圣克里娃娃",
        "ja": "サンクリスバオ",
        "th": "เซาคริสโตว้า",
        "pt": "São Cristovao"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.0136465,
        "lng": -37.2071134
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.3366882,
        -11.1053588,
        -37.0913904,
        -10.832
      ]
    },
    {
      "id": 10519,
      "name": "Menglang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5544371,
        "lng": 99.933538
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.893538,
        22.5144371,
        99.973538,
        22.5944371
      ]
    },
    {
      "id": 10520,
      "name": "Qaracuxur",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.39667,
        "lng": 49.97361
      },
      "country": "Azerbaijan",
      "importance": 3,
      "bbox": [
        49.95361,
        40.37667,
        49.99361,
        40.41667
      ]
    },
    {
      "id": 10521,
      "name": "Ad Diwem",
      "names": {
        "es": "Dijem",
        "zh": "广告Diwem",
        "de": "Adi",
        "it": "D -wem",
        "ja": "アドディウム",
        "pt": "Adivinhar"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.997187,
        "lng": 32.30946
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        32.14946,
        13.837187,
        32.46946,
        14.157187
      ]
    },
    {
      "id": 10522,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7668943,
        "lng": -89.3563845
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.67955,
        37.5618627,
        -89.1506157,
        37.9552
      ]
    },
    {
      "id": 10523,
      "name": "Fort Myers",
      "names": {
        "zh": "迈尔斯堡",
        "ja": "フォートマイヤーズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.640628,
        "lng": -81.8723084
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.9126085,
        26.5476424,
        -81.7511414,
        26.689176
      ]
    },
    {
      "id": 10524,
      "name": "Ciudad de Atlixco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.254767,
        "lng": -99.142535
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.364924,
        19.048237,
        -98.940303,
        19.592757
      ]
    },
    {
      "id": 10525,
      "name": "Chiguayante",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -36.9153573,
        "lng": -73.0288408
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -73.0338408,
        -36.9203573,
        -73.0238408,
        -36.9103573
      ]
    },
    {
      "id": 10526,
      "name": "Campana",
      "names": {
        "zh": "坎帕纳",
        "ja": "カンパーナ",
        "th": "คัมพานา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.1636631,
        "lng": -58.9586837
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.9986837,
        -34.2036631,
        -58.9186837,
        -34.1236631
      ]
    },
    {
      "id": 10527,
      "name": "Dingcheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.6997478,
        "lng": 110.323769
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.283769,
        19.6597478,
        110.363769,
        19.7397478
      ]
    },
    {
      "id": 10528,
      "name": "Tuncheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.36333,
        "lng": 110.09778
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.07778,
        19.34333,
        110.11778,
        19.38333
      ]
    },
    {
      "id": 10529,
      "name": "Gubkin",
      "names": {
        "zh": "古布金"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.283714,
        "lng": 37.53466
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.4543171,
        51.2378852,
        37.6892981,
        51.3166891
      ]
    },
    {
      "id": 10530,
      "name": "Cardenas",
      "names": {
        "es": "Cárdenas",
        "zh": "卡德纳斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.03714,
        "lng": -81.2025816
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -81.4416276,
        22.8339642,
        -81.0560569,
        23.205366
      ]
    },
    {
      "id": 10531,
      "name": "Yaofeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1395826,
        "lng": 111.2160577
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.1590886,
        35.018891,
        111.3786006,
        35.2043216
      ]
    },
    {
      "id": 10532,
      "name": "Kharian",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.8100237,
        "lng": 73.8663245
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.8263245,
        32.7700237,
        73.9063245,
        32.8500237
      ]
    },
    {
      "id": 10533,
      "name": "Goodyear",
      "names": {
        "zh": "固特异",
        "ja": "グッドイヤー",
        "th": "กู๊ดเยียร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4674695,
        "lng": -112.3842224
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.49592,
        33.0143357,
        -112.2020282,
        33.5154551
      ]
    },
    {
      "id": 10534,
      "name": "Aalst",
      "names": {
        "zh": "阿尔斯特",
        "ja": "アールスト"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9383226,
        "lng": 4.0392147
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.9824469,
        50.8993828,
        4.1634322,
        50.9948309
      ]
    },
    {
      "id": 10535,
      "name": "Ducheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2450355,
        "lng": 111.5346699
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.4946699,
        23.2050355,
        111.5746699,
        23.2850355
      ]
    },
    {
      "id": 10536,
      "name": "Kattagan",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.5,
        "lng": 64.51667
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        64.49667,
        40.48,
        64.53667,
        40.52
      ]
    },
    {
      "id": 10537,
      "name": "Garulia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.82111,
        "lng": 88.37444
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.35444,
        22.80111,
        88.39444,
        22.84111
      ]
    },
    {
      "id": 10538,
      "name": "Tumaco",
      "names": {
        "zh": "图马科"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.8061434,
        "lng": -78.7635572
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -79.007853,
        1.1875043,
        -78.2556647,
        2.4486091
      ]
    },
    {
      "id": 10539,
      "name": "Keffi",
      "names": {
        "zh": "凯菲"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.84861,
        "lng": 7.87361
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.85361,
        8.82861,
        7.89361,
        8.86861
      ]
    },
    {
      "id": 10540,
      "name": "Gotenba",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.303843,
        "lng": 138.939031
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.828724,
        35.223148,
        138.971564,
        35.357746
      ]
    },
    {
      "id": 10541,
      "name": "Novotroitsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.196369,
        "lng": 58.298527
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        58.2500373,
        51.1712688,
        58.4057198,
        51.2446872
      ]
    },
    {
      "id": 10542,
      "name": "Kapakli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.834938,
        "lng": 32.4253797
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.4053797,
        40.814938,
        32.4453797,
        40.854938
      ]
    },
    {
      "id": 10543,
      "name": "Chiclana de la Frontera",
      "names": {
        "ja": "チクラナデラフロンテラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.4191096,
        "lng": -6.1460683
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.2170286,
        36.3309434,
        -5.9941265,
        36.472152
      ]
    },
    {
      "id": 10544,
      "name": "Pariaman",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.6263889,
        "lng": 100.1177778
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        100.0745246,
        -0.6999572,
        100.2187701,
        -0.5478317
      ]
    },
    {
      "id": 10545,
      "name": "Tagaytay",
      "names": {
        "zh": "大雅台"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1032297,
        "lng": 120.9317903
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8838547,
        14.0791106,
        121.0514021,
        14.157653
      ]
    },
    {
      "id": 10546,
      "name": "Cabiao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2488,
        "lng": 120.8548
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8348,
        15.2288,
        120.8748,
        15.2688
      ]
    },
    {
      "id": 10547,
      "name": "Dalton",
      "names": {
        "zh": "道尔顿",
        "ja": "ダルトン",
        "th": "ดาลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7691867,
        "lng": -84.9702475
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.0396314,
        34.699867,
        -84.859483,
        34.820262
      ]
    },
    {
      "id": 10548,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5808615,
        "lng": -71.6132377
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.6302022,
        -33.7880182,
        -71.3290346,
        -33.5517292
      ]
    },
    {
      "id": 10549,
      "name": "Ubatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.433162,
        "lng": -45.083415
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.2801133,
        -23.5968918,
        -44.7237025,
        -23.1985705
      ]
    },
    {
      "id": 10550,
      "name": "Kameoka",
      "names": {
        "zh": "龟冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0134403,
        "lng": 135.5733728
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.37765,
        34.915167,
        135.646745,
        35.10129
      ]
    },
    {
      "id": 10551,
      "name": "Clarkstown",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.1326,
        "lng": -73.97042
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.99042,
        41.1126,
        -73.95042,
        41.1526
      ]
    },
    {
      "id": 10552,
      "name": "Guasavito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.55298,
        "lng": -108.43501
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -108.45501,
        25.53298,
        -108.41501,
        25.57298
      ]
    },
    {
      "id": 10553,
      "name": "Treviso",
      "names": {
        "fr": "Trévise",
        "zh": "特雷维索",
        "ja": "トレビソ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6658505,
        "lng": 12.2455414
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.1696757,
        45.6136502,
        12.2882487,
        45.7078497
      ]
    },
    {
      "id": 10554,
      "name": "Cheektowaga",
      "names": {
        "zh": "奇克托瓦加",
        "th": "ชีกโทวากา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9033917,
        "lng": -78.7547536
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.799896,
        42.863512,
        -78.696874,
        42.953478
      ]
    },
    {
      "id": 10555,
      "name": "Shaoshanzhan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.91,
        "lng": 112.48
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10556,
      "name": "Olavarria",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -36.8938752,
        "lng": -60.3231676
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.3631676,
        -36.9338752,
        -60.2831676,
        -36.8538752
      ]
    },
    {
      "id": 10557,
      "name": "Konstanz",
      "names": {
        "fr": "Constance",
        "zh": "康斯坦茨",
        "ja": "コンスタンツ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.659216,
        "lng": 9.1750718
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        9.0853854,
        47.6539842,
        9.2180439,
        47.7627374
      ]
    },
    {
      "id": 10558,
      "name": "Longchuan",
      "names": {
        "zh": "龙川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.3411057,
        "lng": 97.9457869
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        97.6589039,
        24.137695,
        98.2858164,
        24.6658441
      ]
    },
    {
      "id": 10559,
      "name": "Bryan",
      "names": {
        "zh": "布莱恩",
        "ja": "ブライアン",
        "th": "ไบรอัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6743643,
        "lng": -96.3699632
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.4592491,
        30.5846018,
        -96.2458901,
        30.7315157
      ]
    },
    {
      "id": 10560,
      "name": "Kaizuka",
      "names": {
        "zh": "贝冢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.432856,
        "lng": 135.360043
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.2829331,
        34.339764,
        135.43429,
        34.5120237
      ]
    },
    {
      "id": 10561,
      "name": "Zhezqazghan",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.78333,
        "lng": 67.76667
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        67.74667,
        47.76333,
        67.78667,
        47.80333
      ]
    },
    {
      "id": 10562,
      "name": "Khowrasgan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.65384,
        "lng": 51.75522
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 10563,
      "name": "Bugulma",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5358384,
        "lng": 52.7902064
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        52.7902064,
        54.5358384,
        52.7905947,
        54.5360384
      ]
    },
    {
      "id": 10564,
      "name": "Shchekino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.00513,
        "lng": 37.52194
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.50194,
        53.98513,
        37.54194,
        54.02513
      ]
    },
    {
      "id": 10565,
      "name": "Potiskum",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.7122553,
        "lng": 11.0762744
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        11.0174214,
        11.5700157,
        11.2492424,
        11.7642069
      ]
    },
    {
      "id": 10566,
      "name": "Guinobatan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1904597,
        "lng": 123.5999458
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.5599458,
        13.1504597,
        123.6399458,
        13.2304597
      ]
    },
    {
      "id": 10567,
      "name": "Waukegan",
      "names": {
        "zh": "沃基根"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3636331,
        "lng": -87.8447938
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.964419,
        42.309377,
        -87.804207,
        42.432657
      ]
    },
    {
      "id": 10568,
      "name": "Iga",
      "names": {
        "zh": "IGA抗体",
        "ja": "伊賀",
        "th": "จีเอ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7497761,
        "lng": 136.1423355
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.019949,
        34.557545,
        136.342337,
        34.902024
      ]
    },
    {
      "id": 10569,
      "name": "Longhua",
      "names": {
        "zh": "龙华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.3172878,
        "lng": 117.7256651
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.6856651,
        41.2772878,
        117.7656651,
        41.3572878
      ]
    },
    {
      "id": 10570,
      "name": "Anderson",
      "names": {
        "zh": "安德森",
        "ja": "アンダーソン",
        "th": "แอนเดอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0096811,
        "lng": -84.9880147
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.170062,
        37.886017,
        -84.796486,
        38.129607
      ]
    },
    {
      "id": 10571,
      "name": "Kitakoriyamacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.65122,
        "lng": 135.78373
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.76373,
        34.63122,
        135.80373,
        34.67122
      ]
    },
    {
      "id": 10572,
      "name": "Ushiku",
      "names": {
        "zh": "牛久"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.984832,
        "lng": 140.14589
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.125017,
        35.955654,
        140.171923,
        36.027019
      ]
    },
    {
      "id": 10573,
      "name": "North Vancouver",
      "names": {
        "zh": "北温哥华",
        "de": "Nordvancouver",
        "id": "Vancouver Utara",
        "ja": "ノースバンクーバー",
        "th": "นอร์ทแวนคูเวอร์",
        "pt": "Vancouver norte"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.42122645,
        "lng": -122.99560429681809
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -123.1403698,
        49.295636,
        -122.8589571,
        49.5466481
      ]
    },
    {
      "id": 10574,
      "name": "Redwood City",
      "names": {
        "fr": "Ville de Redwood",
        "zh": "红木市",
        "de": "Redwood-Stadt",
        "id": "Kota redwood",
        "ja": "レッドウッドシティ",
        "th": "เมืองเรดวูด",
        "pt": "Cidade de sequóias"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4863239,
        "lng": -122.232523
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.2908234,
        37.4471717,
        -122.1374961,
        37.5703773
      ]
    },
    {
      "id": 10575,
      "name": "Cacoal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.4333,
        "lng": -61.4420502
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -61.7965011,
        -11.6193094,
        -60.9999705,
        -10.987
      ]
    },
    {
      "id": 10576,
      "name": "Chiquinquira",
      "names": {
        "es": "Chiquinquirá"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6182733,
        "lng": -73.8167476
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.89978,
        5.5461729,
        -73.686417,
        5.7133878
      ]
    },
    {
      "id": 10577,
      "name": "Guanambi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.223066,
        "lng": -42.779943
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.0710413,
        -14.3961483,
        -42.581,
        -13.9568083
      ]
    },
    {
      "id": 10578,
      "name": "Yeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.7112094,
        "lng": 38.2747987
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.2153513,
        46.6448096,
        38.3537897,
        46.7428461
      ]
    },
    {
      "id": 10579,
      "name": "Sekimachi",
      "names": {
        "zh": "关町"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7382715,
        "lng": 137.0099241
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.9999241,
        36.7282715,
        137.0199241,
        36.7482715
      ]
    },
    {
      "id": 10580,
      "name": "Hoover",
      "names": {
        "zh": "胡佛",
        "ja": "フーバー",
        "th": "ฮูเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.35029299999999,
        "lng": -86.85112299766301
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.9261567,
        33.2672894,
        -86.6088458,
        33.4687323
      ]
    },
    {
      "id": 10581,
      "name": "Cachoeira do Sul",
      "names": {
        "it": "Cachoeira do sol"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.0482234,
        "lng": -52.8901686
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.3510041,
        -30.6783959,
        -52.6058926,
        -29.7933823
      ]
    },
    {
      "id": 10582,
      "name": "Brossard",
      "names": {
        "zh": "布罗萨尔"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4554579,
        "lng": -73.4678695
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.5237193,
        45.4130868,
        -73.376367,
        45.4856123
      ]
    },
    {
      "id": 10583,
      "name": "Chita",
      "names": {
        "zh": "赤塔",
        "ja": "チタ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.970089,
        "lng": 136.860738
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.7384677,
        34.9219672,
        136.918752,
        35.0179482
      ]
    },
    {
      "id": 10584,
      "name": "Villingen-Schwenningen",
      "names": {
        "zh": "菲林根-施文宁根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.064962,
        "lng": 8.45318
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.308109,
        47.981037,
        8.636617,
        48.125141
      ]
    },
    {
      "id": 10585,
      "name": "Batarasa",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.66167,
        "lng": 117.62222
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        117.60222,
        8.64167,
        117.64222,
        8.68167
      ]
    },
    {
      "id": 10586,
      "name": "Kineshma",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.441310400000006,
        "lng": 42.157962718212524
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.0643652,
        57.3994898,
        42.253303,
        57.4862382
      ]
    },
    {
      "id": 10587,
      "name": "Lake Forest",
      "names": {
        "es": "Bosque del lago",
        "fr": "Forêt lac",
        "zh": "湖森林",
        "de": "Seewald",
        "it": "Lago Foresta",
        "id": "Hutan danau",
        "ja": "湖の森",
        "th": "ป่าทะเลสาบ",
        "pt": "Floresta do lago"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6469261,
        "lng": -117.6859213
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.7270009,
        33.606831,
        -117.6201691,
        33.7068757
      ]
    },
    {
      "id": 10588,
      "name": "Dapitan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6549449,
        "lng": 123.4243754
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.2643754,
        8.4949449,
        123.5843754,
        8.8149449
      ]
    },
    {
      "id": 10589,
      "name": "Caratinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.7900887,
        "lng": -42.140533
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.5222736,
        -19.939533,
        -41.7779965,
        -19.4396358
      ]
    },
    {
      "id": 10590,
      "name": "Napa",
      "names": {
        "zh": "纳帕",
        "ja": "ナパ",
        "th": "ปา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2971367,
        "lng": -122.2855293
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.357721,
        38.2209836,
        -122.222196,
        38.350834
      ]
    },
    {
      "id": 10591,
      "name": "Torrente",
      "names": {
        "zh": "多浪迪警官"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.436668,
        "lng": -0.4656213
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.617137,
        39.386985,
        -0.4353969,
        39.4496586
      ]
    },
    {
      "id": 10592,
      "name": "Takayama",
      "names": {
        "zh": "高山",
        "ja": "高山",
        "th": "ทาคายามะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.142306,
        "lng": 137.254126
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.235597,
        36.131084,
        137.271544,
        36.153538
      ]
    },
    {
      "id": 10593,
      "name": "Derry",
      "names": {
        "zh": "德里",
        "ja": "デリー",
        "th": "เดอร์รี่"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9978678,
        "lng": -7.3213056
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -7.4813056,
        54.8378678,
        -7.1613056,
        55.1578678
      ]
    },
    {
      "id": 10594,
      "name": "Luancheng",
      "names": {
        "zh": "栾城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8834011,
        "lng": 114.6519245
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.4919245,
        37.7234011,
        114.8119245,
        38.0434011
      ]
    },
    {
      "id": 10595,
      "name": "Sumenep",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.006745,
        "lng": 113.8598666
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        113.5791929,
        -7.2519901,
        116.2699413,
        -5.0428744
      ]
    },
    {
      "id": 10596,
      "name": "Walvisbaai",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -22.9557607,
        "lng": 14.5071125
      },
      "country": "Namibia",
      "importance": 3,
      "bbox": [
        14.3471125,
        -23.1157607,
        14.6671125,
        -22.7957607
      ]
    },
    {
      "id": 10597,
      "name": "Moriyama",
      "names": {
        "zh": "森山",
        "ja": "森山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0504054,
        "lng": 135.9956883
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.9906883,
        35.0454054,
        136.0006883,
        35.0554054
      ]
    },
    {
      "id": 10598,
      "name": "Junin",
      "names": {
        "zh": "朱宁"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5934412,
        "lng": -60.9461678
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.0114952,
        -34.6486936,
        -60.9056081,
        -34.5547583
      ]
    },
    {
      "id": 10599,
      "name": "Korgas",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1561927,
        "lng": 80.454297
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        80.449297,
        44.1511927,
        80.459297,
        44.1611927
      ]
    },
    {
      "id": 10600,
      "name": "Repentigny",
      "names": {},
      "countryCode": "CA",
      "latLng": {
        "lat": 45.7326055,
        "lng": -73.4528307
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.5280034,
        45.7015151,
        -73.3935212,
        45.8191477
      ]
    },
    {
      "id": 10601,
      "name": "Largo",
      "names": {
        "zh": "缓慢地",
        "ja": "ラルゴ",
        "th": "เนิบ ๆ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9094665,
        "lng": -82.7873244
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.845414,
        27.875044,
        -82.696279,
        27.942621
      ]
    },
    {
      "id": 10602,
      "name": "Bloomington",
      "names": {
        "zh": "布卢明顿",
        "ja": "ブルーミントン",
        "th": "ในบลูมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8322405,
        "lng": -93.3204872
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.3988956,
        44.7851309,
        -93.197413,
        44.8631417
      ]
    },
    {
      "id": 10603,
      "name": "Marl",
      "names": {
        "fr": "Marne",
        "zh": "马尔",
        "ja": "マール",
        "th": "ดินเหนียว"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6485843,
        "lng": 7.0829054
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.022661,
        51.6149455,
        7.2056326,
        51.7203314
      ]
    },
    {
      "id": 10604,
      "name": "Ciudad Mante",
      "names": {
        "zh": "Ciudad宫廷"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.7411278,
        "lng": -98.9714093
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1314093,
        22.5811278,
        -98.8114093,
        22.9011278
      ]
    },
    {
      "id": 10605,
      "name": "Jacobina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.1851561,
        "lng": -40.5112434
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.9311962,
        -11.3891449,
        -40.0833709,
        -10.7750795
      ]
    },
    {
      "id": 10606,
      "name": "Science City of Munoz",
      "names": {
        "zh": "慕尼黑科学城",
        "de": "Wissenschaftsstadt von Munoz",
        "it": "Science City di Munoz",
        "id": "Sains Kota Munoz",
        "ja": "Munozの科学都市",
        "th": "วิทยาศาสตร์เมือง Munoz",
        "pt": "Cidade da Ciência de Munoz"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7135413,
        "lng": 120.9039451
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8318905,
        15.6659904,
        120.9661037,
        15.8060444
      ]
    },
    {
      "id": 10607,
      "name": "Aruja",
      "names": {
        "pt": "Arujá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.396266,
        "lng": -46.3175449
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.377728,
        -23.4367369,
        -46.247,
        -23.322
      ]
    },
    {
      "id": 10608,
      "name": "Guider",
      "names": {
        "de": "Pfadfinderinnenführerin"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 9.93417,
        "lng": 13.94861
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        13.92861,
        9.91417,
        13.96861,
        9.95417
      ]
    },
    {
      "id": 10609,
      "name": "Senador Canedo",
      "names": {
        "de": "Senador Caneedo",
        "it": "Senador canado",
        "th": "Senador Caneto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.7013233,
        "lng": -49.0914921
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.1968628,
        -16.8312733,
        -49.0105601,
        -16.593
      ]
    },
    {
      "id": 10610,
      "name": "Paracatu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.223529,
        "lng": -46.879421
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.90737,
        -17.246332,
        -46.827227,
        -17.201081
      ]
    },
    {
      "id": 10611,
      "name": "Yokotemachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 32.51662,
        "lng": 130.60189
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.58189,
        32.49662,
        130.62189,
        32.53662
      ]
    },
    {
      "id": 10612,
      "name": "Sabanalarga",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.6307159,
        "lng": -74.9209378
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0633665,
        10.5171357,
        -74.8390651,
        10.7259352
      ]
    },
    {
      "id": 10613,
      "name": "Bais",
      "names": {
        "zh": "拜斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.5914759,
        "lng": 123.121303
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.8796906,
        9.5313475,
        123.2450574,
        9.6515867
      ]
    },
    {
      "id": 10614,
      "name": "Johns Creek",
      "names": {
        "zh": "约翰溪",
        "ja": "ジョンズクリーク"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0181557,
        "lng": -84.190196
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.2858775,
        33.9853859,
        -84.0976947,
        34.090679
      ]
    },
    {
      "id": 10615,
      "name": "Newport Beach",
      "names": {
        "es": "Playa de newport",
        "zh": "纽波特海滩",
        "id": "Pantai Newport",
        "ja": "ニューポートビーチ",
        "th": "นิวพอร์ตบีช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6044294,
        "lng": -117.9160285
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.9160785,
        33.6043794,
        -117.9159785,
        33.6044794
      ]
    },
    {
      "id": 10616,
      "name": "Villa Altagracia",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.671553,
        "lng": -70.1741865
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.3296023,
        18.5044937,
        -70.1123909,
        18.8076306
      ]
    },
    {
      "id": 10617,
      "name": "Dmitrov",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3450695,
        "lng": 37.5200723
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.4860175,
        56.3047573,
        37.5809664,
        56.4019894
      ]
    },
    {
      "id": 10618,
      "name": "Bolinao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.33333,
        "lng": 119.88333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        119.78333,
        16.23333,
        119.98333,
        16.43333
      ]
    },
    {
      "id": 10619,
      "name": "Serra Talhada",
      "names": {
        "zh": "塞拉塔哈达",
        "pt": "Serra Talha"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.9867805,
        "lng": -38.2920294
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.627,
        -8.4426679,
        -38.0268321,
        -7.6771619
      ]
    },
    {
      "id": 10620,
      "name": "El Ejido",
      "names": {
        "ja": "エル・ユジド"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7753425,
        "lng": -2.8168228
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -2.9069102,
        36.6800865,
        -2.6572369,
        36.8285271
      ]
    },
    {
      "id": 10621,
      "name": "Para de Minas",
      "names": {
        "ja": "パラ・デ・マイナー",
        "th": "พาราเดอมินส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.853601,
        "lng": -44.60198
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.651621,
        -19.905298,
        -44.560239,
        -19.820371
      ]
    },
    {
      "id": 10622,
      "name": "Mission",
      "names": {
        "es": "Misión",
        "zh": "使命",
        "it": "Missione",
        "id": "Misi",
        "ja": "ミッション",
        "th": "ภารกิจ",
        "pt": "Missão"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2159066,
        "lng": -98.3252932
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.4078327,
        26.1374712,
        -98.2720905,
        26.2658352
      ]
    },
    {
      "id": 10623,
      "name": "Chigorodo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.6730556,
        "lng": -76.6839167
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6839667,
        7.6730056,
        -76.6838667,
        7.6731056
      ]
    },
    {
      "id": 10624,
      "name": "Cukai",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 4.231443,
        "lng": 103.419721
      },
      "country": "Malaysia",
      "importance": 3,
      "bbox": [
        103.319721,
        4.131443,
        103.519721,
        4.331443
      ]
    },
    {
      "id": 10625,
      "name": "El Milia",
      "names": {
        "zh": "埃尔米亚"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7514104,
        "lng": 6.2744614
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        6.1759112,
        36.6692081,
        6.369759,
        36.9397419
      ]
    },
    {
      "id": 10626,
      "name": "Lqoliaa",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.29759,
        "lng": -9.46597
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.48597,
        30.27759,
        -9.44597,
        30.31759
      ]
    },
    {
      "id": 10627,
      "name": "Cerete",
      "names": {
        "zh": "塞雷特"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8852282,
        "lng": -75.7922421
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0358073,
        8.797183,
        -75.7181877,
        8.978062
      ]
    },
    {
      "id": 10628,
      "name": "Worms",
      "names": {
        "es": "Gusanos",
        "fr": "Vers",
        "zh": "蠕虫",
        "de": "Würmer",
        "it": "Vermi",
        "id": "Cacing",
        "ja": "ワーム",
        "th": "หนอน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6302618,
        "lng": 8.3620898
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.2379714,
        49.5863138,
        8.4464077,
        49.7363444
      ]
    },
    {
      "id": 10629,
      "name": "Troy",
      "names": {
        "zh": "特洛伊",
        "ja": "トロイ",
        "th": "ทรอย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6055893,
        "lng": -83.1499304
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.209216,
        42.5338119,
        -83.086882,
        42.6242061
      ]
    },
    {
      "id": 10630,
      "name": "Madera",
      "names": {
        "zh": "马德拉",
        "ja": "マデラ",
        "th": "มาเดรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0226069,
        "lng": -120.0751
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.0801,
        37.0176069,
        -120.0701,
        37.0276069
      ]
    },
    {
      "id": 10631,
      "name": "Joplin",
      "names": {
        "zh": "乔普林",
        "ja": "ジョプリン",
        "th": "จอปลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.077971,
        "lng": -94.511359
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.618062,
        36.994711,
        -94.386821,
        37.173653
      ]
    },
    {
      "id": 10632,
      "name": "Zheleznogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.250938,
        "lng": 93.53286
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        93.3971403,
        56.1824502,
        93.5886686,
        56.3070925
      ]
    },
    {
      "id": 10633,
      "name": "Talavera de la Reina",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 39.9603798,
        "lng": -4.8311717
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.0600347,
        39.8854204,
        -4.6431723,
        40.0188217
      ]
    },
    {
      "id": 10634,
      "name": "Torrevieja",
      "names": {
        "zh": "托雷维耶哈"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.9775416,
        "lng": -0.6828446
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.7618588,
        37.9408287,
        -0.6473027,
        38.0485705
      ]
    },
    {
      "id": 10635,
      "name": "Chino Hills",
      "names": {
        "es": "Colinas chino",
        "zh": "奇诺山",
        "id": "Bukit chino",
        "ja": "チノヒルズ",
        "pt": "Colinas chino"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9926803,
        "lng": -117.760056
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8025491,
        33.8709844,
        -117.654201,
        34.0235121
      ]
    },
    {
      "id": 10636,
      "name": "Nantingcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.13353,
        "lng": 121.44852
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10637,
      "name": "Sirsilla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.3897573,
        "lng": 78.8085973
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.7685973,
        18.3497573,
        78.8485973,
        18.4297573
      ]
    },
    {
      "id": 10638,
      "name": "Pontevedra",
      "names": {
        "zh": "庞特维德拉",
        "ja": "ポンテベドラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.4318876,
        "lng": -8.6431592
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -8.6991584,
        42.3432344,
        -8.5551062,
        42.513055
      ]
    },
    {
      "id": 10639,
      "name": "Chilliwack",
      "names": {
        "zh": "奇利瓦克"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.164534,
        "lng": -121.949122
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -121.954122,
        49.159534,
        -121.944122,
        49.169534
      ]
    },
    {
      "id": 10640,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3067057,
        "lng": 120.8500112
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8100112,
        15.2667057,
        120.8900112,
        15.3467057
      ]
    },
    {
      "id": 10641,
      "name": "Yurga",
      "names": {
        "zh": "尤尔加"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.724172,
        "lng": 84.913751
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        84.884636,
        55.706944,
        84.939888,
        55.73914
      ]
    },
    {
      "id": 10642,
      "name": "Arona",
      "names": {
        "zh": "阿罗纳",
        "ja": "アロナ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.0996248,
        "lng": -16.6809103
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -16.7373045,
        27.9980751,
        -16.6378524,
        28.118749
      ]
    },
    {
      "id": 10643,
      "name": "Velez-Malaga",
      "names": {
        "es": "Vélez-Málaga",
        "fr": "Velez Malaga-",
        "zh": "贝莱斯-马拉加",
        "de": "Vélez-Málaga",
        "it": "-Velez Malaga",
        "ja": "velez-マラガ",
        "th": "velez-มาลากา",
        "pt": "Velez-Málaga"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7818354,
        "lng": -4.0988099
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.2384467,
        36.7127522,
        -3.992685,
        36.8571057
      ]
    },
    {
      "id": 10644,
      "name": "Fredrikstad",
      "names": {
        "zh": "弗雷德里克斯塔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2089926,
        "lng": 10.950307
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.945307,
        59.2039926,
        10.955307,
        59.2139926
      ]
    },
    {
      "id": 10645,
      "name": "Maco",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.36194,
        "lng": 125.85528
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.83528,
        7.34194,
        125.87528,
        7.38194
      ]
    },
    {
      "id": 10646,
      "name": "Mian Channun",
      "names": {
        "es": "MIAN CANNANUN",
        "fr": "Mian cannun",
        "id": "Channun Mian",
        "ja": "ミアンチャンチン",
        "pt": "Mian Channon"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.443076,
        "lng": 72.35327
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.33079,
        30.429049,
        72.37627,
        30.459266
      ]
    },
    {
      "id": 10647,
      "name": "Redditch",
      "names": {
        "zh": "雷迪奇"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.3057655,
        "lng": -1.9417444
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.0189364,
        52.2352477,
        -1.8752155,
        52.3241976
      ]
    },
    {
      "id": 10648,
      "name": "Taytay",
      "names": {
        "zh": "泰泰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8264031,
        "lng": 119.5159199
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        119.4759199,
        10.7864031,
        119.5559199,
        10.8664031
      ]
    },
    {
      "id": 10649,
      "name": "Serrinha",
      "names": {
        "zh": "塞里尼亚"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.66199,
        "lng": -39.0034021
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.1593051,
        -11.8296865,
        -38.8534871,
        -11.524
      ]
    },
    {
      "id": 10650,
      "name": "Wutiancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.11798,
        "lng": 116.6072
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10651,
      "name": "Santana do Livramento",
      "names": {
        "zh": "桑塔纳做Livramento",
        "id": "Santana do livramamo",
        "ja": "サンタナはリブラメントを行います"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.8894097,
        "lng": -55.5317694
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.2891315,
        -31.332349,
        -54.9717681,
        -30.2750303
      ]
    },
    {
      "id": 10652,
      "name": "Siaton",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.0648,
        "lng": 123.0339
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0139,
        9.0448,
        123.0539,
        9.0848
      ]
    },
    {
      "id": 10653,
      "name": "Tual",
      "names": {
        "zh": "虚拟"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.6389933,
        "lng": 132.7429249
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        131.9260597,
        -5.7676642,
        132.8167248,
        -5.1145983
      ]
    },
    {
      "id": 10654,
      "name": "Sao Sebastiao",
      "names": {
        "zh": "圣塞巴斯蒂亚",
        "ja": "サンセバスチオ",
        "pt": "São Sebastião"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.8027866,
        "lng": -45.4070527
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.8415676,
        -23.8709579,
        -45.3960989,
        -23.6489117
      ]
    },
    {
      "id": 10655,
      "name": "Bhalwal",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2647903,
        "lng": 72.90625
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.90125,
        32.2597903,
        72.91125,
        32.2697903
      ]
    },
    {
      "id": 10656,
      "name": "Seropedica",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.745484949999998,
        "lng": -43.70185196931654
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.7020802,
        -22.7457047,
        -43.7016445,
        -22.7453225
      ]
    },
    {
      "id": 10657,
      "name": "Wuyi",
      "names": {
        "zh": "五邑",
        "ja": "武夷"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8001681,
        "lng": 115.8794436
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.7194436,
        37.6401681,
        116.0394436,
        37.9601681
      ]
    },
    {
      "id": 10658,
      "name": "Marsala",
      "names": {
        "zh": "马尔萨拉",
        "ja": "マルサラ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.7979221,
        "lng": 12.4342091
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.4242472,
        37.7139075,
        12.6946055,
        37.9344542
      ]
    },
    {
      "id": 10659,
      "name": "Franklin",
      "names": {
        "zh": "富兰克林",
        "ja": "フランクリン",
        "th": "แฟรงคลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9765409,
        "lng": -88.9335327
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.1793937,
        37.862227,
        -88.7037087,
        38.127242
      ]
    },
    {
      "id": 10660,
      "name": "Coari",
      "names": {
        "zh": "科阿里"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.0885957,
        "lng": -63.1431166
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -65.9005917,
        -5.8065161,
        -62.2622972,
        -2.265
      ]
    },
    {
      "id": 10661,
      "name": "Velbert",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3406713,
        "lng": 7.0439912
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.0012456,
        51.2931831,
        7.1697795,
        51.3814164
      ]
    },
    {
      "id": 10662,
      "name": "Melbourne",
      "names": {
        "zh": "墨尔本",
        "ja": "メルボルン",
        "th": "เมลเบิร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0785034,
        "lng": -80.6077908
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.7482559,
        28.0350148,
        -80.5749441,
        28.200533
      ]
    },
    {
      "id": 10663,
      "name": "Pozzuoli",
      "names": {
        "zh": "波佐利"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.822643,
        "lng": 14.1219109
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.0405287,
        40.816803,
        14.1613455,
        40.8792403
      ]
    },
    {
      "id": 10664,
      "name": "Al Hayy",
      "names": {
        "es": "Al hueco",
        "ja": "アルハイイ",
        "th": "อัลเฮย์",
        "pt": "Al heady"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.171264,
        "lng": 46.046858
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        46.022694,
        32.132021,
        46.054214,
        32.194843
      ]
    },
    {
      "id": 10665,
      "name": "Mabinay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7272927,
        "lng": 122.9277885
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9277385,
        9.7272427,
        122.9278385,
        9.7273427
      ]
    },
    {
      "id": 10666,
      "name": "Port Huron",
      "names": {
        "es": "Puerto huron",
        "zh": "侯尔港",
        "de": "Huron Port",
        "it": "Porto Huron",
        "ja": "ポートヒューロン",
        "th": "พอร์ตฮูรอน",
        "pt": "Porto huron"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9760854,
        "lng": -82.4333742
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.46653,
        42.9368453,
        -82.39903,
        43.041248
      ]
    },
    {
      "id": 10667,
      "name": "Tanjay",
      "names": {
        "zh": "坦哈伊"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.5162,
        "lng": 123.158
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.138,
        9.4962,
        123.178,
        9.5362
      ]
    },
    {
      "id": 10668,
      "name": "Cayirova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8185087,
        "lng": 29.3723299
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.3664865,
        40.8116231,
        29.3751265,
        40.8271536
      ]
    },
    {
      "id": 10669,
      "name": "Karatepe",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.0202938,
        "lng": 34.9546886
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        34.9346886,
        40.0002938,
        34.9746886,
        40.0402938
      ]
    },
    {
      "id": 10670,
      "name": "Xiedian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4145881,
        "lng": 110.8394183
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.7994183,
        35.3745881,
        110.8794183,
        35.4545881
      ]
    },
    {
      "id": 10671,
      "name": "Nakatsu",
      "names": {
        "zh": "中津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5982794,
        "lng": 131.1883225
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.9262861,
        33.348389,
        131.3553181,
        33.7681572
      ]
    },
    {
      "id": 10672,
      "name": "Bhaktapur",
      "names": {
        "zh": "巴克塔普尔",
        "ja": "バクタプル"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6711133,
        "lng": 85.4261675
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        85.2661675,
        27.5111133,
        85.5861675,
        27.8311133
      ]
    },
    {
      "id": 10673,
      "name": "Colonie",
      "names": {
        "zh": "科勒尼",
        "ja": "コロニー",
        "th": "โคโลนี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7178558,
        "lng": -73.8334554
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.857559,
        42.702115,
        -73.807986,
        42.736623
      ]
    },
    {
      "id": 10674,
      "name": "Barra do Corda",
      "names": {
        "es": "Barra do corña",
        "zh": "巴拉做电池",
        "pt": "Barra do Cata"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.506711,
        "lng": -45.249828
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.2799611832,
        -5.52960040398,
        -45.2399611832,
        -5.48960040398
      ]
    },
    {
      "id": 10675,
      "name": "Shirvan",
      "names": {
        "zh": "希尔凡"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.39669,
        "lng": 57.92952
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        57.90952,
        37.37669,
        57.94952,
        37.41669
      ]
    },
    {
      "id": 10676,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9234046,
        "lng": -83.810138
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.869662,
        39.887855,
        -83.703424,
        39.982857
      ]
    },
    {
      "id": 10677,
      "name": "Sorriso",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.5444887,
        "lng": -55.7233972
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.1164039,
        -13.3943639,
        -55.339,
        -11.721617
      ]
    },
    {
      "id": 10678,
      "name": "Stockton-on-Tees",
      "names": {
        "es": "Stockton-on-T-shirt",
        "fr": "Stockton-sur-t-shirts",
        "zh": "斯托克顿对三通",
        "de": "Stockton-on-T-Shirts",
        "ja": "ストックトン・オン・ティー",
        "th": "สต็อกตันบนประเดิม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.564094,
        "lng": -1.3129164
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.3903151,
        54.5321868,
        -1.2613135,
        54.604598
      ]
    },
    {
      "id": 10679,
      "name": "Kongjiazhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.7541506,
        "lng": 114.7256489
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.6856489,
        40.7141506,
        114.7656489,
        40.7941506
      ]
    },
    {
      "id": 10680,
      "name": "Calatrava",
      "names": {
        "zh": "卡拉特拉瓦"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5940442,
        "lng": 123.4763844
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.2357928,
        10.4681742,
        123.6153412,
        10.7230151
      ]
    },
    {
      "id": 10681,
      "name": "Cruzeiro do Sul",
      "names": {
        "it": "Cruzeiro dole",
        "ja": "クルゼイロドスル"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.6362478,
        "lng": -72.6691649
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -73.6259237,
        -8.4753151,
        -71.9442208,
        -7.4376812
      ]
    },
    {
      "id": 10682,
      "name": "Katoya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.644278,
        "lng": 88.1287333
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.9687333,
        23.484278,
        88.2887333,
        23.804278
      ]
    },
    {
      "id": 10683,
      "name": "Omihachiman",
      "names": {
        "ja": "近江八幡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1228067,
        "lng": 136.1028439
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.0978439,
        35.1178067,
        136.1078439,
        35.1278067
      ]
    },
    {
      "id": 10684,
      "name": "Muroran",
      "names": {
        "zh": "室兰",
        "ja": "室蘭"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.3152461,
        "lng": 140.9740731
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.6560097,
        42.1063203,
        141.3487231,
        42.437262
      ]
    },
    {
      "id": 10685,
      "name": "El Estor",
      "names": {
        "it": "El estore",
        "ja": "エスコール"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.53333,
        "lng": -89.35
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.37,
        15.51333,
        -89.33,
        15.55333
      ]
    },
    {
      "id": 10686,
      "name": "Shikokuchuo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.980744,
        "lng": 133.5499338
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.350025,
        33.829071,
        133.692797,
        34.0873777
      ]
    },
    {
      "id": 10687,
      "name": "Carmen",
      "names": {
        "zh": "卡门",
        "ja": "カルメン",
        "th": "การ์เมน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2045108,
        "lng": 124.795371
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.755371,
        7.1645108,
        124.835371,
        7.2445108
      ]
    },
    {
      "id": 10688,
      "name": "Patrocinio",
      "names": {
        "zh": "帕特罗西尼乌",
        "pt": "Patrocínio"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.9408294,
        "lng": -46.9926624
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.3899352,
        -19.2771581,
        -46.751,
        -18.693
      ]
    },
    {
      "id": 10689,
      "name": "Minden",
      "names": {
        "zh": "明登",
        "ja": "ミンデン",
        "th": "มินเดิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2881045,
        "lng": 8.9168852
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.7920612,
        52.2470645,
        8.9983147,
        52.364871
      ]
    },
    {
      "id": 10690,
      "name": "Zephyrhills",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 28.237341,
        "lng": -82.180235
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.229124,
        28.196959,
        -82.144733,
        28.285603
      ]
    },
    {
      "id": 10691,
      "name": "Campo Mourao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -24.046329,
        "lng": -52.37802
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.5429724,
        -24.2972867,
        -52.187,
        -23.9536461
      ]
    },
    {
      "id": 10692,
      "name": "Chaykovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 40.3838247,
        "lng": 49.8145194
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        49.8144694,
        40.3837747,
        49.8145694,
        40.3838747
      ]
    },
    {
      "id": 10693,
      "name": "Oleksandriia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.670609,
        "lng": 33.1169742
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.005765,
        48.6155892,
        33.2059067,
        48.7160118
      ]
    },
    {
      "id": 10694,
      "name": "Bulacan",
      "names": {
        "zh": "布拉干",
        "th": "คัน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15,
        "lng": 121.083333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6767048,
        14.6011485,
        121.3483961,
        15.2720075
      ]
    },
    {
      "id": 10695,
      "name": "Bekobod",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.2253892,
        "lng": 69.2293609
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        69.2288749,
        40.2227547,
        69.2297231,
        40.2275264
      ]
    },
    {
      "id": 10696,
      "name": "Maple Ridge",
      "names": {
        "es": "Mapa de arce",
        "fr": "Mape crête",
        "zh": "枫树山",
        "de": "Ahornkamm",
        "it": "Acero",
        "ja": "メープルリッジ",
        "th": "สันเขาเมเปิ้ล",
        "pt": "Ridge Maple"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2197345,
        "lng": -122.599707
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.6716928,
        49.1668788,
        -122.4080109,
        49.3532551
      ]
    },
    {
      "id": 10697,
      "name": "La Louviere",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.4793576,
        "lng": 4.18563
      },
      "country": "Belgium",
      "importance": 3,
      "bbox": [
        4.0699406,
        50.4332952,
        4.229974,
        50.5249522
      ]
    },
    {
      "id": 10698,
      "name": "Grosseto",
      "names": {
        "zh": "格罗塞托",
        "ja": "グロッセート"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.7673549,
        "lng": 11.1067465
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.1017465,
        42.7623549,
        11.1117465,
        42.7723549
      ]
    },
    {
      "id": 10699,
      "name": "St. Augustine",
      "names": {
        "es": "San Agustín",
        "fr": "Saint Augustin",
        "zh": "圣奥古斯丁",
        "de": "St. Augustinus",
        "it": "Sant'Agostino",
        "ja": "セントオーガスティン",
        "th": "เซนต์ออกัสติน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.8946952,
        "lng": -81.3145395
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.3445902,
        29.8463226,
        -81.2657081,
        29.9555444
      ]
    },
    {
      "id": 10700,
      "name": "Pilar",
      "names": {
        "zh": "皮拉尔",
        "ja": "ピラル",
        "th": "ปีลาร์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4570918,
        "lng": -58.9141609
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.9615166,
        -34.5013473,
        -58.8657063,
        -34.4007355
      ]
    },
    {
      "id": 10701,
      "name": "Siasi",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.5462,
        "lng": 120.8145
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7945,
        5.5262,
        120.8345,
        5.5662
      ]
    },
    {
      "id": 10702,
      "name": "Harlow",
      "names": {
        "zh": "哈罗",
        "ja": "ハーロー",
        "th": "ฮาร์โลว์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.770356199999995,
        "lng": 0.09550698812154929
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.0948602,
        51.7695624,
        0.0961262,
        51.7711774
      ]
    },
    {
      "id": 10703,
      "name": "Peterborough",
      "names": {
        "zh": "彼得伯勒",
        "ja": "ピーターバラ",
        "th": "ปีเตอร์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.3048009,
        "lng": -78.3199496
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -78.3879706,
        44.2526297,
        -78.2683318,
        44.3784864
      ]
    },
    {
      "id": 10704,
      "name": "Funza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7177471,
        "lng": -74.2031547
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2461321,
        4.6961688,
        -74.1484681,
        4.797587
      ]
    },
    {
      "id": 10705,
      "name": "Ubay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0540129,
        "lng": 124.4740811
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.4340811,
        10.0140129,
        124.5140811,
        10.0940129
      ]
    },
    {
      "id": 10706,
      "name": "Hengkou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7399406,
        "lng": 108.7638182
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.7238182,
        32.6999406,
        108.8038182,
        32.7799406
      ]
    },
    {
      "id": 10707,
      "name": "Varese",
      "names": {
        "zh": "瓦雷泽",
        "ja": "ヴァレーズ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.8161996,
        "lng": 8.8329476
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.8279476,
        45.8111996,
        8.8379476,
        45.8211996
      ]
    },
    {
      "id": 10708,
      "name": "Hengelo",
      "names": {
        "zh": "亨厄洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2658726,
        "lng": 6.7924049
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.7020392,
        52.1910649,
        6.8525273,
        52.3135861
      ]
    },
    {
      "id": 10709,
      "name": "Caldas",
      "names": {
        "zh": "卡尔达斯",
        "ja": "カルダス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0918609,
        "lng": -75.6356917
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6756917,
        6.0518609,
        -75.5956917,
        6.1318609
      ]
    },
    {
      "id": 10710,
      "name": "Ust'-Ilimsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.959473,
        "lng": 102.734192
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        102.5204147,
        57.9158262,
        102.8877828,
        58.0985026
      ]
    },
    {
      "id": 10711,
      "name": "Flagstaff",
      "names": {
        "zh": "弗拉格斯塔夫",
        "ja": "フラッグスタッフ",
        "th": "เสาธง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1972763,
        "lng": -111.6490505
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.6540505,
        35.1922763,
        -111.6440505,
        35.2022763
      ]
    },
    {
      "id": 10712,
      "name": "Presidencia Roque Saenz Pena",
      "names": {
        "zh": "Primidencia Roque Saenz Pena",
        "de": "Pressidencia Roque Saenz Pena"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.78057365,
        "lng": -60.45350604860061
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.5273717,
        -26.8494885,
        -60.3681191,
        -26.7114247
      ]
    },
    {
      "id": 10713,
      "name": "Azov",
      "names": {
        "zh": "亚速",
        "de": "Asowschen"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.0884477,
        "lng": 39.42407455913039
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.3623517,
        47.0356034,
        39.5033999,
        47.1406301
      ]
    },
    {
      "id": 10714,
      "name": "Lujan",
      "names": {
        "zh": "卢汉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5776271,
        "lng": -59.1051729
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.1101729,
        -34.5826271,
        -59.1001729,
        -34.5726271
      ]
    },
    {
      "id": 10715,
      "name": "Aracruz",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.819704,
        "lng": -40.273941
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.287566,
        -19.848261,
        -40.246288,
        -19.786828
      ]
    },
    {
      "id": 10716,
      "name": "Agboville",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 5.9288749,
        "lng": -4.2172238
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -4.2172738,
        5.9288249,
        -4.2171738,
        5.9289249
      ]
    },
    {
      "id": 10717,
      "name": "Campo Limpo",
      "names": {
        "id": "Limpo campo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6361999,
        "lng": -46.7627968
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.7928097,
        -23.6564676,
        -46.7414171,
        -23.6087189
      ]
    },
    {
      "id": 10718,
      "name": "Timoteo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5771395,
        "lng": -42.6436108
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.709448,
        -19.615617,
        -42.5010297,
        -19.4919924
      ]
    },
    {
      "id": 10719,
      "name": "Watampone",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.5386807,
        "lng": 120.3276891
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        120.2876891,
        -4.5786807,
        120.3676891,
        -4.4986807
      ]
    },
    {
      "id": 10720,
      "name": "Pleasanton",
      "names": {
        "zh": "普莱森顿",
        "ja": "プレザントン",
        "th": "เพลเซนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6624312,
        "lng": -121.8746789
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.954974,
        37.6200582,
        -121.7989177,
        37.7030362
      ]
    },
    {
      "id": 10721,
      "name": "Xindian",
      "names": {
        "zh": "新店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1595667,
        "lng": 106.6330433
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        106.5930433,
        31.1195667,
        106.6730433,
        31.1995667
      ]
    },
    {
      "id": 10722,
      "name": "Felipe Carrillo Puerto",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5776465,
        "lng": -88.0463096
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -88.0658172,
        19.5616388,
        -88.0270476,
        19.5940578
      ]
    },
    {
      "id": 10723,
      "name": "Planaltina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.4571536,
        "lng": -47.6089024
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.1074993,
        -15.5006228,
        -47.466,
        -15.0165051
      ]
    },
    {
      "id": 10724,
      "name": "Paranavai",
      "names": {
        "pt": "Paranavaí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.08165,
        "lng": -52.461724
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.7492774,
        -23.1410469,
        -52.3471663,
        -22.6104563
      ]
    },
    {
      "id": 10725,
      "name": "Shuibian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.0926674,
        "lng": 113.1690852
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.1290852,
        24.0526674,
        113.2090852,
        24.1326674
      ]
    },
    {
      "id": 10726,
      "name": "Buhi",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4342347,
        "lng": 123.5171171
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4771171,
        13.3942347,
        123.5571171,
        13.4742347
      ]
    },
    {
      "id": 10727,
      "name": "Anapa",
      "names": {
        "zh": "阿纳帕"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.894272,
        "lng": 37.316887
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        37.2529638,
        44.8573533,
        37.3752308,
        44.9851906
      ]
    },
    {
      "id": 10728,
      "name": "Kimitsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3302375,
        "lng": 139.902551
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.7679022,
        35.153842,
        140.162161,
        35.4306628
      ]
    },
    {
      "id": 10729,
      "name": "Matao",
      "names": {
        "pt": "Matão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.6033611,
        "lng": -48.3660603
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.629,
        -21.723,
        -48.2595975,
        -21.512
      ]
    },
    {
      "id": 10730,
      "name": "Dessau-Rosslau",
      "names": {
        "zh": "德绍-罗斯劳",
        "ja": "デッサウ-rosslau",
        "th": "เดสเซา-rosslau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.829379,
        "lng": 12.2236
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.112072,
        51.733814,
        12.351498,
        51.970737
      ]
    },
    {
      "id": 10731,
      "name": "General Roca",
      "names": {
        "fr": "Général Roca",
        "zh": "一般罗卡",
        "it": "Generale Roca",
        "ja": "一般的なロカ",
        "th": "นายพล roca"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -39.0266276,
        "lng": -67.5754863
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -67.6127225,
        -39.0479063,
        -67.5230391,
        -38.9938471
      ]
    },
    {
      "id": 10732,
      "name": "Atascocita",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 29.968815,
        "lng": -95.202145
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.249647,
        29.932322,
        -95.135343,
        30.030962
      ]
    },
    {
      "id": 10733,
      "name": "Kentau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.5165919,
        "lng": 68.4996044
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        68.1784915,
        43.4090694,
        68.9772008,
        43.6982263
      ]
    },
    {
      "id": 10734,
      "name": "Ita",
      "names": {
        "th": "ก็"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.5074747,
        "lng": -57.3631906
      },
      "country": "Paraguay",
      "importance": 3,
      "bbox": [
        -57.4313371,
        -25.640248,
        -57.3120273,
        -25.444545
      ]
    },
    {
      "id": 10735,
      "name": "Matalam",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.08639,
        "lng": 124.90056
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.88056,
        7.06639,
        124.92056,
        7.10639
      ]
    },
    {
      "id": 10736,
      "name": "Westland",
      "names": {
        "zh": "西部地区",
        "ja": "ウェストランド",
        "th": "เวสต์แลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3238056,
        "lng": -83.4005321
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.430038,
        42.267463,
        -83.3090035,
        42.35518
      ]
    },
    {
      "id": 10737,
      "name": "Kamareddipet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.3167,
        "lng": 78.35
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10738,
      "name": "Gobernador Galvez",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0657756,
        "lng": -60.5773711
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -60.5775933,
        -33.067283,
        -60.5771474,
        -33.0642684
      ]
    },
    {
      "id": 10739,
      "name": "Auburn",
      "names": {
        "zh": "奥本",
        "ja": "オーバーン",
        "th": "สีน้ำตาลแดง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.3075369,
        "lng": -122.2301808
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.2807014,
        47.237142,
        -122.143636,
        47.353874
      ]
    },
    {
      "id": 10740,
      "name": "Cranston",
      "names": {
        "zh": "克兰斯顿",
        "ja": "クランストン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.779588,
        "lng": -71.4366813
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.55929,
        41.730066,
        -71.3851681,
        41.80702
      ]
    },
    {
      "id": 10741,
      "name": "Yanggao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9650803,
        "lng": 105.4262198
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.4062198,
        28.9450803,
        105.4462198,
        28.9850803
      ]
    },
    {
      "id": 10742,
      "name": "Lambunao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.05563,
        "lng": 122.4759
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4559,
        11.03563,
        122.4959,
        11.07563
      ]
    },
    {
      "id": 10743,
      "name": "Shiji",
      "names": {
        "zh": "世纪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2825162,
        "lng": 118.1151716
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.9972304,
        32.1961184,
        118.2984656,
        32.3283252
      ]
    },
    {
      "id": 10744,
      "name": "Senhor do Bonfim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.4602509,
        "lng": -40.189151
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.2705457,
        -10.638181,
        -39.947956,
        -10.3231267
      ]
    },
    {
      "id": 10745,
      "name": "Athi River",
      "names": {
        "es": "Río athi",
        "fr": "Rivière Athri",
        "zh": "萨尼河",
        "it": "Fiume Athi",
        "id": "Sungai Athi",
        "ja": "アティ川",
        "th": "แม่น้ำ Athi",
        "pt": "Rio athi"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.4490116,
        "lng": 36.97549950798134
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        36.9754224,
        -1.4490736,
        36.9755766,
        -1.4489495
      ]
    },
    {
      "id": 10746,
      "name": "Novouralsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.24389,
        "lng": 60.08389
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.06389,
        57.22389,
        60.10389,
        57.26389
      ]
    },
    {
      "id": 10747,
      "name": "Barili",
      "names": {
        "zh": "巴里利"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.115,
        "lng": 123.5103
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4903,
        10.095,
        123.5303,
        10.135
      ]
    },
    {
      "id": 10748,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.3361663,
        "lng": -121.890591
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.0460405,
        37.1231596,
        -121.5858438,
        37.4691477
      ]
    },
    {
      "id": 10749,
      "name": "Folsom",
      "names": {
        "zh": "福尔瑟姆"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6779591,
        "lng": -121.176058
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.2100996,
        38.6319719,
        -121.0855089,
        38.717357
      ]
    },
    {
      "id": 10750,
      "name": "Sefrou",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.827819,
        "lng": -4.831472
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -4.853352,
        33.809375,
        -4.804161,
        33.844543
      ]
    },
    {
      "id": 10751,
      "name": "Panzos",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3985948,
        "lng": -89.6419899
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.6819899,
        15.3585948,
        -89.6019899,
        15.4385948
      ]
    },
    {
      "id": 10752,
      "name": "Taroudannt",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.4719787,
        "lng": -8.8669712
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -8.8670212,
        30.4719287,
        -8.8669212,
        30.4720287
      ]
    },
    {
      "id": 10753,
      "name": "Kapalong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5855037,
        "lng": 125.7076466
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.374968,
        7.5516547,
        125.7197146,
        8.0645996
      ]
    },
    {
      "id": 10754,
      "name": "Springdale",
      "names": {
        "zh": "斯普林代尔",
        "ja": "スプリングデール"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1867442,
        "lng": -94.1288142
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.2574347,
        36.1333275,
        -94.0679613,
        36.2483969
      ]
    },
    {
      "id": 10755,
      "name": "San Cristobal Verapaz",
      "names": {
        "es": "San Cristóbal Verapaz",
        "ja": "サンクリストバルベラパズ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.365843,
        "lng": -90.4777485
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.5177485,
        15.325843,
        -90.4377485,
        15.405843
      ]
    },
    {
      "id": 10756,
      "name": "Warwick",
      "names": {
        "zh": "华威",
        "ja": "ワーウィック",
        "th": "วอร์วิก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6992717,
        "lng": -71.4586114
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.518913,
        41.629397,
        -71.3566566,
        41.768333
      ]
    },
    {
      "id": 10757,
      "name": "Tagoloan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5379732,
        "lng": 124.7540174
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.7539674,
        8.5379232,
        124.7540674,
        8.5380232
      ]
    },
    {
      "id": 10758,
      "name": "Kashiwazaki",
      "names": {
        "zh": "柏崎",
        "ja": "柏崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.3719095,
        "lng": 138.5591406
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.1288891,
        37.159853,
        138.721764,
        37.6605588
      ]
    },
    {
      "id": 10759,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.7792588,
        "lng": -122.4193286
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -123.173825,
        37.6403143,
        -122.281479,
        37.929811
      ]
    },
    {
      "id": 10760,
      "name": "Yonezawa",
      "names": {
        "zh": "米泽",
        "ja": "米澤"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.9222426,
        "lng": 140.1166276
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.934921,
        37.733829,
        140.2988,
        37.987674
      ]
    },
    {
      "id": 10761,
      "name": "Bolpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.661154,
        "lng": 87.6962424
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.663871,
        23.6418313,
        87.709629,
        23.7030263
      ]
    },
    {
      "id": 10762,
      "name": "Malapatan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9695506,
        "lng": 125.2856321
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.2756321,
        5.9595506,
        125.2956321,
        5.9795506
      ]
    },
    {
      "id": 10763,
      "name": "Los Patios",
      "names": {
        "zh": "洛杉矶庭院",
        "it": "Los Patio",
        "id": "Los Patio",
        "ja": "ロスパティオ",
        "pt": "Los pátios"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8342202,
        "lng": -72.5059128
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.5876034,
        7.6423044,
        -72.4795627,
        7.869374
      ]
    },
    {
      "id": 10764,
      "name": "Polatsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.4879,
        "lng": 28.7856
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        28.7656,
        55.4679,
        28.8056,
        55.5079
      ]
    },
    {
      "id": 10765,
      "name": "Meybod",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.25014,
        "lng": 54.01658
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.99658,
        32.23014,
        54.03658,
        32.27014
      ]
    },
    {
      "id": 10766,
      "name": "Ipojuca",
      "names": {
        "th": "อีโปะจูกา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.3980773,
        "lng": -35.0611068
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.2380528,
        -8.6077602,
        -34.9464072,
        -8.3099329
      ]
    },
    {
      "id": 10767,
      "name": "Kilifi",
      "names": {
        "zh": "基利菲"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -3.6333,
        "lng": 39.85
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        39.8364905,
        -3.637455,
        39.8945335,
        -3.5791912
      ]
    },
    {
      "id": 10768,
      "name": "Gamagori",
      "names": {
        "zh": "蒲郡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8229991,
        "lng": 137.2260391
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.2210391,
        34.8179991,
        137.2310391,
        34.8279991
      ]
    },
    {
      "id": 10769,
      "name": "Akiruno",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7289031,
        "lng": 139.2941912
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.136092,
        35.687594,
        139.329421,
        35.778938
      ]
    },
    {
      "id": 10770,
      "name": "Farmington Hills",
      "names": {
        "fr": "Collines de Farmington",
        "id": "Bukit Farmington",
        "ja": "ファーミントンヒルズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4853125,
        "lng": -83.3771553
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.43754,
        42.4389415,
        -83.316825,
        42.52937
      ]
    },
    {
      "id": 10771,
      "name": "Echizen",
      "names": {
        "zh": "越前",
        "ja": "越前"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9034571,
        "lng": 136.1689317
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.018727,
        35.807962,
        136.334066,
        35.9485288
      ]
    },
    {
      "id": 10772,
      "name": "Sokcho",
      "names": {
        "zh": "束草"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 38.2069574,
        "lng": 128.5913424
      },
      "country": "South Korea",
      "importance": 2,
      "bbox": [
        128.4211401,
        38.1189598,
        128.8595719,
        38.3237617
      ]
    },
    {
      "id": 10773,
      "name": "Neumunster",
      "names": {
        "es": "Neumünster",
        "fr": "Neumünster",
        "de": "Neumünster",
        "it": "Neumünster",
        "id": "Neumünster",
        "pt": "Neumünster"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.0757442,
        "lng": 9.9815377
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.9256406,
        54.0176295,
        10.0584699,
        54.1496806
      ]
    },
    {
      "id": 10774,
      "name": "Gumla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0426077,
        "lng": 84.5422286
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.5022286,
        23.0026077,
        84.5822286,
        23.0826077
      ]
    },
    {
      "id": 10775,
      "name": "Norderstedt",
      "names": {
        "zh": "诺德施泰特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.712313,
        "lng": 9.995697
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        9.934876,
        53.647312,
        10.073566,
        53.759989
      ]
    },
    {
      "id": 10776,
      "name": "Necochea",
      "names": {
        "zh": "内科切阿"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.5545494,
        "lng": -58.7392624
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.826799,
        -38.6131281,
        -58.6967488,
        -38.5183453
      ]
    },
    {
      "id": 10777,
      "name": "Arsuz",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.4125694,
        "lng": 35.8865358
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.8465358,
        36.3725694,
        35.9265358,
        36.4525694
      ]
    },
    {
      "id": 10778,
      "name": "Newark",
      "names": {
        "zh": "纽瓦克",
        "ja": "ニューアーク",
        "th": "นวร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6828358,
        "lng": -75.7515682
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.789011,
        39.6432533,
        -75.7231658,
        39.714928
      ]
    },
    {
      "id": 10779,
      "name": "Williamsburg",
      "names": {
        "zh": "威廉斯堡",
        "ja": "ウィリアムズバーグ",
        "th": "วิลเลียม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.2708788,
        "lng": -76.7074042
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.7429843,
        37.2474666,
        -76.6643134,
        37.3101256
      ]
    },
    {
      "id": 10780,
      "name": "Simdega",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.611621,
        "lng": 84.5006635
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        84.4806635,
        22.591621,
        84.5206635,
        22.631621
      ]
    },
    {
      "id": 10781,
      "name": "Brooklyn Park",
      "names": {
        "es": "Parque de Brooklyn",
        "fr": "Parc de Brooklyn",
        "zh": "布鲁克林公园",
        "id": "Taman Brooklyn",
        "ja": "ブルックリンパーク",
        "th": "บรูคลินพาร์ค",
        "pt": "Parque Brooklyn"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1004807,
        "lng": -93.3443585
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.4023859,
        45.0655977,
        -93.278327,
        45.1520363
      ]
    },
    {
      "id": 10782,
      "name": "Uvinza",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.10361,
        "lng": 30.39111
      },
      "country": "Tanzania",
      "importance": 3,
      "bbox": [
        30.37111,
        -5.12361,
        30.41111,
        -5.08361
      ]
    },
    {
      "id": 10783,
      "name": "Takestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.0696,
        "lng": 49.6959
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.6759,
        36.0496,
        49.7159,
        36.0896
      ]
    },
    {
      "id": 10784,
      "name": "Kottagudem",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.6090521,
        "lng": 81.2708044
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.2508044,
        17.5890521,
        81.2908044,
        17.6290521
      ]
    },
    {
      "id": 10785,
      "name": "Livingston",
      "names": {
        "fr": "De Livingston",
        "zh": "利文斯顿",
        "ja": "リビングストン",
        "th": "ลิฟวิงสตัน"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 39.7639036,
        "lng": -93.5573919
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -93.762756,
        39.611866,
        -93.25946,
        39.967762
      ]
    },
    {
      "id": 10786,
      "name": "Mbalmayo",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 3.515197,
        "lng": 11.503114
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        11.4286131,
        3.3449441,
        11.6557438,
        3.6281016
      ]
    },
    {
      "id": 10787,
      "name": "Namsan",
      "names": {
        "zh": "南山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.0189704,
        "lng": 127.4635395
      },
      "country": "North Korea",
      "importance": 2,
      "bbox": [
        127.4585395,
        39.0139704,
        127.4685395,
        39.0239704
      ]
    },
    {
      "id": 10788,
      "name": "Zarzis",
      "names": {
        "zh": "扎尔纪斯"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.50398,
        "lng": 11.11216
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        11.09216,
        33.48398,
        11.13216,
        33.52398
      ]
    },
    {
      "id": 10789,
      "name": "Andahuaylas",
      "names": {
        "zh": "安达韦拉斯"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.6564057,
        "lng": -73.3899459
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -73.4335602,
        -13.9265555,
        -73.3137907,
        -13.6055871
      ]
    },
    {
      "id": 10790,
      "name": "Jamshoro",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 25.4297172,
        "lng": 68.2817288
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        68.2417288,
        25.3897172,
        68.3217288,
        25.4697172
      ]
    },
    {
      "id": 10791,
      "name": "Hattiesburg",
      "names": {
        "zh": "哈蒂斯堡",
        "th": "แฮตติสบูร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.3271189,
        "lng": -89.2903392
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.416602,
        31.185828,
        -89.224266,
        31.405134
      ]
    },
    {
      "id": 10792,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8051095,
        "lng": -77.0470229
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.1443577,
        38.7851837,
        -77.0370392,
        38.8449229
      ]
    },
    {
      "id": 10793,
      "name": "Tall `Afar",
      "names": {
        "es": "Alto 'lejos",
        "fr": "Grand `Afar",
        "zh": "高大的人",
        "de": "Groß `fern",
        "it": "Alto `lontano",
        "id": "Tinggi `jauh",
        "ja": "背の高い `遠く",
        "th": "สูง `ไกล",
        "pt": "Alto 'longe"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.3740434,
        "lng": 42.4434793
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        42.2834793,
        36.2140434,
        42.6034793,
        36.5340434
      ]
    },
    {
      "id": 10794,
      "name": "Meiganga",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 6.5172123,
        "lng": 14.2947879
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        13.9226543,
        6.0000002,
        14.9492709,
        6.8743984
      ]
    },
    {
      "id": 10795,
      "name": "Jaen",
      "names": {
        "es": "Jaén",
        "zh": "哈恩"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3371423,
        "lng": 120.9059513
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8659513,
        15.2971423,
        120.9459513,
        15.3771423
      ]
    },
    {
      "id": 10796,
      "name": "Balad",
      "names": {
        "zh": "拜莱德"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.0118301,
        "lng": 44.1449143
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        44.127999,
        33.97866,
        44.1728607,
        34.0238428
      ]
    },
    {
      "id": 10797,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0641992,
        "lng": 125.6093294
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        125.5890459,
        7.0579401,
        125.6279118,
        7.105258
      ]
    },
    {
      "id": 10798,
      "name": "Manresa",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.7288939,
        "lng": 1.8286765
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.7691535,
        41.6880602,
        1.8744358,
        41.7581574
      ]
    },
    {
      "id": 10799,
      "name": "Vila do Conde",
      "names": {
        "it": "Vila do cond",
        "ja": "ヴィラドーコン"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3540398,
        "lng": -8.7435403
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7680529,
        41.2554949,
        -8.6106236,
        41.4257091
      ]
    },
    {
      "id": 10800,
      "name": "Natori-shi",
      "names": {
        "zh": "名取市",
        "ja": "natori市",
        "th": "natori-ชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.1733422,
        "lng": 140.8827769
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.8777769,
        38.1683422,
        140.8877769,
        38.1783422
      ]
    },
    {
      "id": 10801,
      "name": "San Carlos del Zulia",
      "names": {
        "zh": "圣卡洛斯德尔朱莉娅",
        "ja": "サンカルロスデルズリア"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.000518,
        "lng": -71.9259356
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -71.9659356,
        8.960518,
        -71.8859356,
        9.040518
      ]
    },
    {
      "id": 10802,
      "name": "Koktokay",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.000373,
        "lng": 89.466621
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        89.4466214589,
        46.9803727408,
        89.4866214589,
        47.0203727408
      ]
    },
    {
      "id": 10803,
      "name": "Capenda Camulemba",
      "names": {
        "de": "CADENDA CAMUEMEMBA",
        "pt": "Capendá Camulemba"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.4194492,
        "lng": 18.433128
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        18.413128,
        -9.4394492,
        18.453128,
        -9.3994492
      ]
    },
    {
      "id": 10804,
      "name": "Fiumicino",
      "names": {
        "zh": "菲乌米奇诺",
        "ja": "フィウミチーノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.7712145,
        "lng": 12.2278855
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.1272417,
        41.740906,
        12.3168049,
        42.0202102
      ]
    },
    {
      "id": 10805,
      "name": "Florence",
      "names": {
        "es": "Florencia",
        "zh": "佛罗伦萨",
        "de": "Florenz",
        "it": "Firenze",
        "ja": "フィレンツェ",
        "th": "ฟลอเรนซ์",
        "pt": "Florença"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.79981,
        "lng": -87.677251
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.7224877,
        34.7789662,
        -87.5701016,
        34.891877
      ]
    },
    {
      "id": 10806,
      "name": "Mabai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0131241,
        "lng": 104.3976934
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.3576934,
        22.9731241,
        104.4376934,
        23.0531241
      ]
    },
    {
      "id": 10807,
      "name": "Jinhe",
      "names": {
        "zh": "金河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.1313009,
        "lng": 111.4642479
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.4242479,
        33.0913009,
        111.5042479,
        33.1713009
      ]
    },
    {
      "id": 10808,
      "name": "Ben Gardane",
      "names": {
        "es": "Ben Garnene",
        "zh": "本加德纳",
        "ja": "ベンガラネ"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.1403223,
        "lng": 11.2170109
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        11.1770109,
        33.1003223,
        11.2570109,
        33.1803223
      ]
    },
    {
      "id": 10809,
      "name": "Idah",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.10651,
        "lng": 6.73415
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        6.71415,
        7.08651,
        6.75415,
        7.12651
      ]
    },
    {
      "id": 10810,
      "name": "Robles",
      "names": {
        "zh": "罗布莱斯",
        "th": "โรเบิลส์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.330308800000001,
        "lng": 123.0147536493775
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.986641,
        10.3020477,
        123.0463772,
        10.3691353
      ]
    },
    {
      "id": 10811,
      "name": "Baracoa",
      "names": {
        "zh": "巴拉科阿"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3477869,
        "lng": -74.4968647
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -74.791832,
        20.1657771,
        -74.2863635,
        20.5540443
      ]
    },
    {
      "id": 10812,
      "name": "Valdosta",
      "names": {
        "zh": "瓦尔多斯塔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.8327022,
        "lng": -83.2784851
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.3445096,
        30.7617071,
        -83.2219276,
        30.928853
      ]
    },
    {
      "id": 10813,
      "name": "Patikul",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.0886509,
        "lng": 121.1070509
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9966833,
        6.0310978,
        121.2567666,
        6.2000337
      ]
    },
    {
      "id": 10814,
      "name": "Plymouth",
      "names": {
        "zh": "普利茅斯",
        "ja": "プリマス",
        "th": "พลีมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.0065149,
        "lng": -93.46651
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.522652,
        44.9782824,
        -93.4003723,
        45.0664218
      ]
    },
    {
      "id": 10815,
      "name": "Yalta",
      "names": {
        "zh": "雅尔塔",
        "de": "Jalta",
        "th": "ยัลตา"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 44.4970713,
        "lng": 34.1586871
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        34.1209488,
        44.4777612,
        34.1940719,
        44.5271162
      ]
    },
    {
      "id": 10816,
      "name": "Jiangna",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6115817,
        "lng": 104.3369289
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.2969289,
        23.5715817,
        104.3769289,
        23.6515817
      ]
    },
    {
      "id": 10817,
      "name": "Manhuacu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.2574434,
        "lng": -42.0340769
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.217614,
        -20.376929,
        -41.954,
        -19.98985
      ]
    },
    {
      "id": 10818,
      "name": "Torres Vedras",
      "names": {
        "zh": "托雷斯·瓦德拉斯",
        "id": "Vedras torres"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0898907,
        "lng": -9.2593083
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.2593583,
        39.0898407,
        -9.2592583,
        39.0899407
      ]
    },
    {
      "id": 10819,
      "name": "Gyoda",
      "names": {
        "zh": "行田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1386052,
        "lng": 139.4559001
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.416702,
        36.090122,
        139.53556,
        36.198315
      ]
    },
    {
      "id": 10820,
      "name": "Buckeye",
      "names": {
        "zh": "橡树",
        "ja": "トチノキ",
        "th": "โอไฮโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3703197,
        "lng": -112.583776
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.8591058,
        33.130526,
        -112.4582784,
        33.8112594
      ]
    },
    {
      "id": 10821,
      "name": "Georgetown",
      "names": {
        "zh": "乔治敦",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6370152,
        "lng": -97.6775634
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.8327917,
        30.5708044,
        -97.5898805,
        30.7525362
      ]
    },
    {
      "id": 10822,
      "name": "Hanno",
      "names": {
        "zh": "汉诺",
        "ja": "飯能市",
        "th": "ฮันโน"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.85194,
        "lng": 139.31806
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.29806,
        35.83194,
        139.33806,
        35.87194
      ]
    },
    {
      "id": 10823,
      "name": "Alfenas",
      "names": {
        "ja": "アルフェナス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.4297405,
        "lng": -45.9479419
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.189,
        -21.586518,
        -45.819326,
        -21.139826
      ]
    },
    {
      "id": 10824,
      "name": "Mangatarem",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7874,
        "lng": 120.2921
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.2721,
        15.7674,
        120.3121,
        15.8074
      ]
    },
    {
      "id": 10825,
      "name": "Kadoma",
      "names": {
        "zh": "卡多马"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.3374033,
        "lng": 29.9135021
      },
      "country": "Zimbabwe",
      "importance": 3,
      "bbox": [
        29.7535021,
        -18.4974033,
        30.0735021,
        -18.1774033
      ]
    },
    {
      "id": 10826,
      "name": "Pingyi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5177818,
        "lng": 117.6097303
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        117.4111247,
        35.1293555,
        117.947423,
        35.7230518
      ]
    },
    {
      "id": 10827,
      "name": "Yenakiieve",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.233498,
        "lng": 38.20288
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        38.155275,
        48.187039,
        38.258231,
        48.27547
      ]
    },
    {
      "id": 10828,
      "name": "Iwamizawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 43.1960638,
        "lng": 141.7753595
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.610258,
        43.075355,
        142.043858,
        43.337722
      ]
    },
    {
      "id": 10829,
      "name": "Bauang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.53083,
        "lng": 120.33306
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.31306,
        16.51083,
        120.35306,
        16.55083
      ]
    },
    {
      "id": 10830,
      "name": "Cedar Park",
      "names": {
        "es": "Cedro",
        "fr": "Cèdre",
        "zh": "雪松公园",
        "it": "Parco di cedro",
        "id": "Taman Cedar",
        "ja": "シーダーパーク",
        "th": "ซีดาร์พาร์ค",
        "pt": "Parque de cedro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5217116,
        "lng": -97.827833
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.888639,
        30.4466648,
        -97.745417,
        30.5570365
      ]
    },
    {
      "id": 10831,
      "name": "Juventino Rosas",
      "names": {
        "zh": "尤文图罗莎"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6436576,
        "lng": -100.992674
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.032674,
        20.6036576,
        -100.952674,
        20.6836576
      ]
    },
    {
      "id": 10832,
      "name": "Villa Maria",
      "names": {
        "zh": "玛丽亚别墅",
        "ja": "ヴィラマリア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.4106245,
        "lng": -63.2435809
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.2943936,
        -32.4555261,
        -63.1783273,
        -32.3603011
      ]
    },
    {
      "id": 10833,
      "name": "The Villages",
      "names": {
        "es": "Los pueblos",
        "fr": "Les villages",
        "zh": "村庄",
        "de": "Die Dörfer",
        "it": "I villaggi",
        "id": "Desa-desa",
        "ja": "村",
        "th": "หมู่บ้าน",
        "pt": "As aldeias"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.8759024,
        "lng": -81.98457902636167
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.0459349,
        28.769866,
        -81.9287817,
        28.9818969
      ]
    },
    {
      "id": 10834,
      "name": "Kokomo",
      "names": {
        "zh": "科科莫"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4864444,
        "lng": -86.1336351
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.184256,
        40.4102681,
        -86.069902,
        40.5346033
      ]
    },
    {
      "id": 10835,
      "name": "Perris",
      "names": {
        "zh": "佩里斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7818729,
        "lng": -117.2298477
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.262542,
        33.7138654,
        -117.179494,
        33.8701367
      ]
    },
    {
      "id": 10836,
      "name": "Eastleigh",
      "names": {
        "zh": "伊斯特利"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.9687923,
        "lng": -1.3499611
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.3549611,
        50.9637923,
        -1.3449611,
        50.9737923
      ]
    },
    {
      "id": 10837,
      "name": "Klin",
      "names": {
        "zh": "克林"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3355601,
        "lng": 36.7351318
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        36.6642251,
        56.3068917,
        36.8171526,
        56.3780556
      ]
    },
    {
      "id": 10838,
      "name": "Ozersk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.76046,
        "lng": 60.705101
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.6325729,
        55.7237784,
        60.7765048,
        55.7941821
      ]
    },
    {
      "id": 10839,
      "name": "Baharestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.4858819,
        "lng": 51.7785054
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        51.7225193,
        32.4641171,
        51.8367021,
        32.5030659
      ]
    },
    {
      "id": 10840,
      "name": "Huaral",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -11.4954906,
        "lng": -77.207044
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -77.3329877,
        -11.56355,
        -76.95181,
        -11.2454646
      ]
    },
    {
      "id": 10841,
      "name": "St. Joseph",
      "names": {
        "es": "San José",
        "fr": "Saint-Joseph",
        "zh": "圣约瑟夫",
        "ja": "セントジョセフ",
        "th": "เซนต์โจเซฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7686055,
        "lng": -94.8466322
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.899029,
        39.697215,
        -94.743339,
        39.8212591
      ]
    },
    {
      "id": 10842,
      "name": "Pagbilao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9716151,
        "lng": 121.6864374
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6329512,
        13.7492233,
        121.8248883,
        14.0662858
      ]
    },
    {
      "id": 10843,
      "name": "Pato Branco",
      "names": {
        "ja": "パトブランコ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.2295984,
        "lng": -52.6712474
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.813,
        -26.307804,
        -52.529887,
        -25.9958796
      ]
    },
    {
      "id": 10844,
      "name": "Orihuela",
      "names": {
        "zh": "奥里韦拉"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.0856891,
        "lng": -0.9448805
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -1.0367447,
        37.8883108,
        -0.7069437,
        38.3296105
      ]
    },
    {
      "id": 10845,
      "name": "Mooka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4390901,
        "lng": 140.0128896
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.907234,
        36.334985,
        140.072713,
        36.509983
      ]
    },
    {
      "id": 10846,
      "name": "Flower Mound",
      "names": {
        "es": "Montículo de flores",
        "fr": "Monticule de fleur",
        "zh": "花丘",
        "de": "Blumenhügel",
        "it": "Tumulo di fiori",
        "id": "Gundukan bunga",
        "ja": "フラワーマウンド",
        "th": "เนินดอกไม้",
        "pt": "Monte de flores"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0283854,
        "lng": -97.0867203
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.2345118,
        32.981429,
        -97.0276786,
        33.0914484
      ]
    },
    {
      "id": 10847,
      "name": "Pharr",
      "names": {
        "zh": "法尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.180408,
        "lng": -98.188264
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.221521,
        26.064099,
        -98.159317,
        26.250407
      ]
    },
    {
      "id": 10848,
      "name": "Pototan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9437,
        "lng": 122.6352
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.6152,
        10.9237,
        122.6552,
        10.9637
      ]
    },
    {
      "id": 10849,
      "name": "Sahuayo de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0571249,
        "lng": -102.7223504
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -102.7623504,
        20.0171249,
        -102.6823504,
        20.0971249
      ]
    },
    {
      "id": 10850,
      "name": "Jelenia Gora",
      "names": {
        "zh": "乔利诺戈拉",
        "ja": "ゼレニアゴーラ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9031028,
        "lng": 15.7344306
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.5778718,
        50.7677738,
        15.8771624,
        50.9325552
      ]
    },
    {
      "id": 10851,
      "name": "Francisco Beltrao",
      "names": {
        "de": "Francisco Belttrao",
        "th": "ฟรานซิสโก BELTRARAO"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.0790979,
        "lng": -53.0533527
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.3780757,
        -26.1968505,
        -52.8845229,
        -25.9372509
      ]
    },
    {
      "id": 10852,
      "name": "Telemaco Borba",
      "names": {
        "id": "Telemako Borba",
        "ja": "テレマコボルバ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.32649,
        "lng": -50.629164
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.665597,
        -24.349596,
        -50.60052,
        -24.303878
      ]
    },
    {
      "id": 10853,
      "name": "Ijui",
      "names": {
        "zh": "伊茹伊"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.3878734,
        "lng": -53.9201692
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.0764058,
        -28.5386227,
        -53.7717523,
        -28.0552023
      ]
    },
    {
      "id": 10854,
      "name": "Limay",
      "names": {
        "zh": "利迈河"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5625242,
        "lng": 120.5949559
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4757851,
        14.5014893,
        120.7566942,
        14.631513
      ]
    },
    {
      "id": 10855,
      "name": "Koch Bihar",
      "names": {
        "ja": "コッホビハール"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.3223696,
        "lng": 89.4592445
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        89.2692374,
        26.175005,
        89.5648383,
        26.3351148
      ]
    },
    {
      "id": 10856,
      "name": "Maizuru",
      "names": {
        "zh": "舞鹤",
        "ja": "舞鶴"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4704853,
        "lng": 135.34844
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.159256,
        35.380033,
        135.679535,
        36.1031939
      ]
    },
    {
      "id": 10857,
      "name": "Kizugawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.72553,
        "lng": 135.806121
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.786121,
        34.70553,
        135.826121,
        34.74553
      ]
    },
    {
      "id": 10858,
      "name": "Alton",
      "names": {
        "zh": "奥尔顿",
        "ja": "アルトン",
        "th": "แอลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8908583,
        "lng": -90.1843091
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.20901,
        38.863383,
        -90.0881533,
        38.937463
      ]
    },
    {
      "id": 10859,
      "name": "Bamberg",
      "names": {
        "zh": "班贝格",
        "ja": "バンベルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8916044,
        "lng": 10.8868478
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8265043,
        49.8426463,
        10.9607049,
        49.9286327
      ]
    },
    {
      "id": 10860,
      "name": "Hengbei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.8778932,
        "lng": 115.7306892
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.6906892,
        23.8378932,
        115.7706892,
        23.9178932
      ]
    },
    {
      "id": 10861,
      "name": "Macabebe",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9081836,
        "lng": 120.7149803
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5933726,
        14.6700123,
        120.7320642,
        14.9463714
      ]
    },
    {
      "id": 10862,
      "name": "Loveland",
      "names": {
        "zh": "拉夫兰",
        "ja": "ラブランド"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.385549,
        "lng": -105.04436132094955
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.0732348,
        40.3783738,
        -105.0282463,
        40.3927134
      ]
    },
    {
      "id": 10863,
      "name": "Subulussalam",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.6403146,
        "lng": 98.0052459
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        97.7665349,
        2.4986201,
        98.116913,
        3.009527
      ]
    },
    {
      "id": 10864,
      "name": "Delmenhorst",
      "names": {
        "zh": "代尔门霍斯特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.0527492,
        "lng": 8.6297197
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6296697,
        53.0526992,
        8.6297697,
        53.0527992
      ]
    },
    {
      "id": 10865,
      "name": "Essaouira",
      "names": {
        "zh": "索维拉",
        "ja": "エッサウィラ",
        "th": "เอสเซาอิรา"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 31.5116133,
        "lng": -9.7709453
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.7709953,
        31.5115633,
        -9.7708953,
        31.5116633
      ]
    },
    {
      "id": 10866,
      "name": "Valdemoro",
      "names": {
        "zh": "巴尔德莫罗"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.1888604,
        "lng": -3.67143
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.7181753,
        40.1324632,
        -3.591571,
        40.2296324
      ]
    },
    {
      "id": 10867,
      "name": "Tierralta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.17361,
        "lng": -76.05917
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.07917,
        8.15361,
        -76.03917,
        8.19361
      ]
    },
    {
      "id": 10868,
      "name": "Dondo",
      "names": {
        "zh": "栋多"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -19.616463,
        "lng": 34.7435141
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        34.7035141,
        -19.656463,
        34.7835141,
        -19.576463
      ]
    },
    {
      "id": 10869,
      "name": "Aligudarz",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.40063,
        "lng": 49.6949
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.6749,
        33.38063,
        49.7149,
        33.42063
      ]
    },
    {
      "id": 10870,
      "name": "Boynton Beach",
      "names": {
        "es": "Playa de boynton",
        "id": "Pantai Boynton",
        "ja": "ボイントンビーチ",
        "pt": "Praia de boynton"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.526863,
        "lng": -80.076173
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.114947,
        26.483703,
        -80.048994,
        26.572502
      ]
    },
    {
      "id": 10871,
      "name": "Arlit",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 18.739079,
        "lng": 7.393331
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        7.233331,
        18.579079,
        7.553331,
        18.899079
      ]
    },
    {
      "id": 10872,
      "name": "Moju",
      "names": {
        "zh": "莫茹"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.889926,
        "lng": -48.766765
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.6293893,
        -3.4152522,
        -48.4362223,
        -1.6532717
      ]
    },
    {
      "id": 10873,
      "name": "Tela",
      "names": {
        "zh": "特拉"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.7824826,
        "lng": -87.4514026
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -87.4914026,
        15.7424826,
        -87.4114026,
        15.8224826
      ]
    },
    {
      "id": 10874,
      "name": "Argao",
      "names": {
        "zh": "阿尔高"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.8813917,
        "lng": 123.6085598
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.5685598,
        9.8413917,
        123.6485598,
        9.9213917
      ]
    },
    {
      "id": 10875,
      "name": "Bamban",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6790466,
        "lng": 120.3343222
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3143222,
        15.6590466,
        120.3543222,
        15.6990466
      ]
    },
    {
      "id": 10876,
      "name": "Kuvango",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.4659375,
        "lng": 16.2910795
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.795844,
        -15.2047802,
        16.755521,
        -13.332222
      ]
    },
    {
      "id": 10877,
      "name": "Manbij",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.5269242,
        "lng": 37.9559288
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.7959288,
        36.3669242,
        38.1159288,
        36.6869242
      ]
    },
    {
      "id": 10878,
      "name": "Anderson",
      "names": {
        "zh": "安德森",
        "ja": "アンダーソン",
        "th": "แอนเดอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5258335,
        "lng": -82.652962
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.9920694,
        34.2087505,
        -82.3138711,
        34.8195278
      ]
    },
    {
      "id": 10879,
      "name": "Pattoki",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.0210614,
        "lng": 73.852906
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.812906,
        30.9810614,
        73.892906,
        31.0610614
      ]
    },
    {
      "id": 10880,
      "name": "Vyborg",
      "names": {
        "zh": "维堡",
        "de": "Wyborg"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 60.709217,
        "lng": 28.744051
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        28.5800471,
        60.6532198,
        28.911189,
        60.8113221
      ]
    },
    {
      "id": 10881,
      "name": "Dias d'Avila",
      "names": {
        "zh": "DIAAS D'Avila"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6107233,
        "lng": -38.3028518
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.407,
        -12.6740001,
        -38.1315816,
        -12.534
      ]
    },
    {
      "id": 10882,
      "name": "Usol'ye-Sibirskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.754791,
        "lng": 103.650973
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        103.615387,
        52.726139,
        103.678444,
        52.785473
      ]
    },
    {
      "id": 10883,
      "name": "Beziers",
      "names": {
        "zh": "贝济耶",
        "ja": "ベジエ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.347287,
        "lng": 3.227421
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.127818,
        43.297118,
        3.340654,
        43.399631
      ]
    },
    {
      "id": 10884,
      "name": "Hasselt",
      "names": {
        "zh": "哈瑟尔特",
        "ja": "ハッセルト",
        "th": "ฮัสเซลท์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9303735,
        "lng": 5.3378043
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.2121258,
        50.8675553,
        5.4149391,
        50.9815946
      ]
    },
    {
      "id": 10885,
      "name": "Jonesboro",
      "names": {
        "zh": "琼斯伯勒",
        "th": "โจเนสโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.8272257,
        "lng": -90.6949871
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.7870357,
        35.7485919,
        -90.5518956,
        35.8933002
      ]
    },
    {
      "id": 10886,
      "name": "Bustos",
      "names": {
        "zh": "布斯托斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.954736,
        "lng": 120.919111
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8881822,
        14.8926831,
        120.9598376,
        14.9646969
      ]
    },
    {
      "id": 10887,
      "name": "Kropotkin",
      "names": {
        "fr": "Kropotkine",
        "zh": "克鲁泡特金"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.428379,
        "lng": 40.567054
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        40.534584,
        45.412472,
        40.603249,
        45.445999
      ]
    },
    {
      "id": 10888,
      "name": "Pinheiro",
      "names": {
        "zh": "皮涅罗"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5270459,
        "lng": -45.0866463
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.357,
        -2.819,
        -44.8982477,
        -2.170878
      ]
    },
    {
      "id": 10889,
      "name": "Arjona",
      "names": {
        "zh": "阿尔霍纳"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.2534632,
        "lng": -75.3427213
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5447731,
        10.0174568,
        -75.2336528,
        10.3391899
      ]
    },
    {
      "id": 10890,
      "name": "Chengbin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.94069,
        "lng": 110.34489
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.32489,
        19.92069,
        110.36489,
        19.96069
      ]
    },
    {
      "id": 10891,
      "name": "Chitembo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.51667,
        "lng": 16.76667
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        16.74667,
        -13.53667,
        16.78667,
        -13.49667
      ]
    },
    {
      "id": 10892,
      "name": "Parma",
      "names": {
        "fr": "Parme",
        "zh": "帕尔马",
        "ja": "パルマ",
        "th": "ปาร์มา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3822398,
        "lng": -81.7355692
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.785057,
        41.350382,
        -81.6845137,
        41.419412
      ]
    },
    {
      "id": 10893,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3598289,
        "lng": 44.0542641
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.9619311,
        56.2554582,
        44.2221701,
        56.4184788
      ]
    },
    {
      "id": 10894,
      "name": "Bodhan",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.658316,
        "lng": 77.894117
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.874908,
        18.637859,
        77.914303,
        18.683371
      ]
    },
    {
      "id": 10895,
      "name": "Narra",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.2694766,
        "lng": 118.4039018
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        118.355746,
        9.2431634,
        118.4963834,
        9.402766
      ]
    },
    {
      "id": 10896,
      "name": "Chinautla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7029187,
        "lng": -90.5000643
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.5400643,
        14.6629187,
        -90.4600643,
        14.7429187
      ]
    },
    {
      "id": 10897,
      "name": "Layton",
      "names": {
        "zh": "莱顿",
        "ja": "レイトン",
        "th": "เลย์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0751048,
        "lng": -111.9741925
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.0358598,
        41.0366833,
        -111.9011915,
        41.1165661
      ]
    },
    {
      "id": 10898,
      "name": "Balqash",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 46.84806,
        "lng": 74.995
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        74.975,
        46.82806,
        75.015,
        46.86806
      ]
    },
    {
      "id": 10899,
      "name": "Villa Victoria",
      "names": {
        "zh": "维多利亚别墅",
        "ja": "ヴィラビクトリア",
        "th": "วิคตอเรียวิลล่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4381543,
        "lng": -99.995998
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.035998,
        19.3981543,
        -99.955998,
        19.4781543
      ]
    },
    {
      "id": 10900,
      "name": "Elizabethtown",
      "names": {
        "ja": "エリザベスタウン",
        "th": "เอลิซาเบททาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.693952,
        "lng": -85.8591285
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.947658,
        37.649929,
        -85.820203,
        37.767598
      ]
    },
    {
      "id": 10901,
      "name": "Texarkana",
      "names": {
        "zh": "特克萨卡纳",
        "th": "แทกซาร์แคนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.44667445,
        "lng": -94.07714830522015
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.1709726,
        33.3813921,
        -94.0430416,
        33.5119622
      ]
    },
    {
      "id": 10902,
      "name": "Fray Bartolome de Las Casas",
      "names": {
        "zh": "磨损Bartolome de las Casas",
        "it": "Mray Bartolome de las Casas",
        "ja": "Bartolome de Las Casasをお楽しみください"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.805683,
        "lng": -89.8609851
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.9009851,
        15.765683,
        -89.8209851,
        15.845683
      ]
    },
    {
      "id": 10903,
      "name": "Linkou",
      "names": {
        "zh": "林口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2908402,
        "lng": 130.2619436
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.2569436,
        45.2858402,
        130.2669436,
        45.2958402
      ]
    },
    {
      "id": 10904,
      "name": "Hermosa",
      "names": {
        "zh": "埃尔莫萨",
        "ja": "エルモサ",
        "th": "เฮอร์โมซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8302478,
        "lng": 120.5086406
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3527074,
        14.7783711,
        120.5724412,
        14.9050266
      ]
    },
    {
      "id": 10905,
      "name": "Palencia",
      "names": {
        "zh": "帕伦西亚",
        "th": "ปาเลนเซีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.015826,
        "lng": -4.5337104
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -4.5387104,
        42.010826,
        -4.5287104,
        42.020826
      ]
    },
    {
      "id": 10906,
      "name": "Aquiraz",
      "names": {
        "th": "ในอากิราซ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.9050805,
        "lng": -38.3896098
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.4096098,
        -3.9250805,
        -38.3696098,
        -3.8850805
      ]
    },
    {
      "id": 10907,
      "name": "Villa Canales",
      "names": {
        "id": "Calies Villa",
        "ja": "ヴィラカナレ",
        "th": "วิลล่า canales"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.483333,
        "lng": -90.533333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.6105196,
        14.240037,
        -90.4604391,
        14.5606699
      ]
    },
    {
      "id": 10908,
      "name": "Numan",
      "names": {
        "zh": "努曼"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.4598443,
        "lng": 12.0359283
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        11.5345287,
        9.2095957,
        12.0743895,
        9.6500435
      ]
    },
    {
      "id": 10909,
      "name": "Roosendaal",
      "names": {
        "zh": "罗森达尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5331484,
        "lng": 4.4561276
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4069429,
        51.4912704,
        4.5231916,
        51.5692348
      ]
    },
    {
      "id": 10910,
      "name": "Viersen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2562118,
        "lng": 6.3905476
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.2642195,
        51.2165296,
        6.4578125,
        51.3223372
      ]
    },
    {
      "id": 10911,
      "name": "Bebedouro",
      "names": {
        "zh": "贝贝多鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.949077,
        "lng": -48.479083
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.6849441,
        -21.0913171,
        -48.367,
        -20.7855464
      ]
    },
    {
      "id": 10912,
      "name": "Huebampo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.7080617,
        "lng": -109.2634849
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -109.2834849,
        26.6880617,
        -109.2434849,
        26.7280617
      ]
    },
    {
      "id": 10913,
      "name": "Guildford",
      "names": {
        "zh": "吉尔福德",
        "ja": "ギルフォード",
        "th": "กิล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2356068,
        "lng": -0.5732063
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7483397,
        51.1731853,
        -0.3871653,
        51.3315157
      ]
    },
    {
      "id": 10914,
      "name": "Karur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.9596041,
        "lng": 78.0807797
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.028842,
        10.9223462,
        78.1168914,
        11.0001121
      ]
    },
    {
      "id": 10915,
      "name": "Sint-Niklaas",
      "names": {
        "zh": "圣彼-niklaas",
        "ja": "シント・niklaas",
        "th": "ซิงต์-niklaas"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.1646685,
        "lng": 4.1395124
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.9853092,
        51.1295911,
        4.2216963,
        51.2078059
      ]
    },
    {
      "id": 10916,
      "name": "Tres Rios",
      "names": {
        "ja": "RIOS TRES"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.1167884,
        "lng": -43.2087226
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.2588207,
        -22.200747,
        -43.1275495,
        -22.0350191
      ]
    },
    {
      "id": 10917,
      "name": "Tila",
      "names": {
        "zh": "那"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3001861,
        "lng": -92.4263651
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.4663651,
        17.2601861,
        -92.3863651,
        17.3401861
      ]
    },
    {
      "id": 10918,
      "name": "Santa Catalina",
      "names": {
        "zh": "圣卡塔利娜",
        "ja": "サンタカタリナ",
        "th": "ซานตาแคเทลนา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.3330937,
        "lng": 122.8631365
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7347016,
        9.1127459,
        123.0816644,
        9.4335802
      ]
    },
    {
      "id": 10919,
      "name": "Bakhmut",
      "names": {
        "zh": "巴赫穆特"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.59559,
        "lng": 37.99987
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        37.97987,
        48.57559,
        38.01987,
        48.61559
      ]
    },
    {
      "id": 10920,
      "name": "Honjo",
      "names": {
        "zh": "本庄",
        "ja": "本庄"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2435937,
        "lng": 139.1916278
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.056839,
        36.118713,
        139.2476,
        36.274753
      ]
    },
    {
      "id": 10921,
      "name": "Armant",
      "names": {
        "zh": "艾尔曼特"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.63434,
        "lng": 32.498181
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        32.434914,
        25.526976,
        32.590052,
        25.686952
      ]
    },
    {
      "id": 10922,
      "name": "Unai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -16.3628767,
        "lng": -46.892413
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.4602096,
        -16.9840763,
        -46.2044435,
        -15.787
      ]
    },
    {
      "id": 10923,
      "name": "Marburg",
      "names": {
        "zh": "马尔堡",
        "ja": "マールブルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8192169,
        "lng": 8.7750878
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.7700878,
        50.8142169,
        8.7800878,
        50.8242169
      ]
    },
    {
      "id": 10924,
      "name": "Kankakee",
      "names": {
        "zh": "坎卡基"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1192079,
        "lng": -87.865777
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.870777,
        41.1142079,
        -87.860777,
        41.1242079
      ]
    },
    {
      "id": 10925,
      "name": "Tecamachalco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8640574,
        "lng": -97.72823338555017
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.8037226,
        18.768672,
        -97.6424999,
        18.9578902
      ]
    },
    {
      "id": 10926,
      "name": "Santa Ines",
      "names": {
        "zh": "圣诞老人",
        "it": "Babbo Natale",
        "ja": "サンタインイン",
        "th": "ซานตาอินส์"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.6647821,
        "lng": -45.3826869
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.598,
        -4.0751917,
        -45.317863,
        -3.6298674
      ]
    },
    {
      "id": 10927,
      "name": "Funing",
      "names": {
        "zh": "阜宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.8927132,
        "lng": 119.2431914
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.2381914,
        39.8877132,
        119.2481914,
        39.8977132
      ]
    },
    {
      "id": 10928,
      "name": "Manaoag",
      "names": {
        "zh": "马纳瓦格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0438,
        "lng": 120.4861
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4661,
        16.0238,
        120.5061,
        16.0638
      ]
    },
    {
      "id": 10929,
      "name": "Daying",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3105441,
        "lng": 115.7239678
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.6839678,
        37.2705441,
        115.7639678,
        37.3505441
      ]
    },
    {
      "id": 10930,
      "name": "Umingan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9,
        "lng": 120.8
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7,
        15.8,
        120.9,
        16
      ]
    },
    {
      "id": 10931,
      "name": "Araripina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.5768582,
        "lng": -40.5038509
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.7155365,
        -7.9972901,
        -40.2726769,
        -7.3184558
      ]
    },
    {
      "id": 10932,
      "name": "Ende",
      "names": {
        "zh": "终结",
        "ja": "エンデ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.8439147,
        "lng": 121.647964
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        121.607964,
        -8.8839147,
        121.687964,
        -8.8039147
      ]
    },
    {
      "id": 10933,
      "name": "Porterville",
      "names": {
        "zh": "波特维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.06523,
        "lng": -119.0167679
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.0981676,
        36.0077387,
        -118.9638799,
        36.1163649
      ]
    },
    {
      "id": 10934,
      "name": "Vorkuta",
      "names": {
        "zh": "沃尔库塔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 67.5023557,
        "lng": 64.0605109
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        64.0604609,
        67.5023057,
        64.0605609,
        67.5024057
      ]
    },
    {
      "id": 10935,
      "name": "Harunabad",
      "names": {
        "zh": "哈鲁纳巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.6104977,
        "lng": 73.1388889
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.9788889,
        29.4504977,
        73.2988889,
        29.7704977
      ]
    },
    {
      "id": 10936,
      "name": "Gelendzhik",
      "names": {
        "fr": "Guelendjik"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.5609447,
        "lng": 38.0766832
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.9666898,
        44.5237076,
        38.1367219,
        44.6188233
      ]
    },
    {
      "id": 10937,
      "name": "Tsubame",
      "names": {
        "zh": "燕"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.6675996,
        "lng": 138.928302
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.923302,
        37.6625996,
        138.933302,
        37.6725996
      ]
    },
    {
      "id": 10938,
      "name": "Balkh",
      "names": {
        "zh": "巴剌黑",
        "ja": "バルフ"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.7581256,
        "lng": 66.8980826
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        66.8580826,
        36.7181256,
        66.9380826,
        36.7981256
      ]
    },
    {
      "id": 10939,
      "name": "Gandia",
      "names": {
        "zh": "甘迪亚"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9675925,
        "lng": -0.1803423
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.2971497,
        38.94981,
        -0.1442421,
        39.0412816
      ]
    },
    {
      "id": 10940,
      "name": "Kortrijk",
      "names": {
        "fr": "Courtrai",
        "zh": "科特赖克"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8276429,
        "lng": 3.2659884
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.2007699,
        50.7310311,
        3.3590759,
        50.8746384
      ]
    },
    {
      "id": 10941,
      "name": "Baytown",
      "names": {
        "zh": "贝敦",
        "ja": "ベイタウン",
        "th": "เบย์ทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.7355047,
        "lng": -94.9774274
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.0578092,
        29.662131,
        -94.848974,
        29.829269
      ]
    },
    {
      "id": 10942,
      "name": "Siedlce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1663751,
        "lng": 22.2765138
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.220546,
        52.1275542,
        22.3309567,
        52.1959468
      ]
    },
    {
      "id": 10943,
      "name": "Upland",
      "names": {
        "es": "Tierras altas",
        "zh": "高地",
        "ja": "高地",
        "th": "ไร่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.09751,
        "lng": -117.6483876
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.704409,
        34.0869375,
        -117.6195905,
        34.1523314
      ]
    },
    {
      "id": 10944,
      "name": "Bongabong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.7465309,
        "lng": 121.4867005
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4866505,
        12.7464809,
        121.4867505,
        12.7465809
      ]
    },
    {
      "id": 10945,
      "name": "Talakag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.2323589,
        "lng": 124.6031485
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5524437,
        7.8562584,
        124.8649332,
        8.2949331
      ]
    },
    {
      "id": 10946,
      "name": "Toyooka",
      "names": {
        "zh": "丰冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.536696,
        "lng": 134.824219
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.807694,
        35.52768,
        134.840743,
        35.545713
      ]
    },
    {
      "id": 10947,
      "name": "Nikko",
      "names": {
        "zh": "日光",
        "ja": "日光"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7197576,
        "lng": 139.698139
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.326559,
        36.600973,
        139.852775,
        37.091903
      ]
    },
    {
      "id": 10948,
      "name": "Dongguan",
      "names": {
        "zh": "东莞",
        "ja": "東莞",
        "th": "ตงกวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.0171235,
        "lng": 111.0678807
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.0278807,
        38.9771235,
        111.1078807,
        39.0571235
      ]
    },
    {
      "id": 10949,
      "name": "Nagua",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 19.374794,
        "lng": -69.8530503
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.0130503,
        19.214794,
        -69.6930503,
        19.534794
      ]
    },
    {
      "id": 10950,
      "name": "Al Musayyib",
      "names": {
        "de": "Al MusaNyib"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.7797122,
        "lng": 44.290267
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        44.130267,
        32.6197122,
        44.450267,
        32.9397122
      ]
    },
    {
      "id": 10951,
      "name": "Ryugasaki",
      "names": {
        "zh": "龙崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9113158,
        "lng": 140.181878
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.087509,
        35.872355,
        140.261023,
        36.008355
      ]
    },
    {
      "id": 10952,
      "name": "Pyapon",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.2890879,
        "lng": 95.6800743
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.6400743,
        16.2490879,
        95.7200743,
        16.3290879
      ]
    },
    {
      "id": 10953,
      "name": "Kahror Pakka",
      "names": {
        "ja": "カオトパクカ"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.6241693,
        "lng": 71.9168037
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        71.8768037,
        29.5841693,
        71.9568037,
        29.6641693
      ]
    },
    {
      "id": 10954,
      "name": "La Trinidad",
      "names": {
        "zh": "拉特立尼达",
        "ja": "ラトリニダード",
        "th": "ลาตรินิแดด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 14.7009942,
        "lng": -87.66913719728814
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -87.753405,
        14.662601,
        -87.6008214,
        14.738676
      ]
    },
    {
      "id": 10955,
      "name": "Caserta",
      "names": {
        "zh": "卡塞塔",
        "ja": "カゼルタ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.0685258,
        "lng": 14.3282362
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.3232362,
        41.0635258,
        14.3332362,
        41.0735258
      ]
    },
    {
      "id": 10956,
      "name": "Mafra",
      "names": {
        "zh": "马夫拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9369782,
        "lng": -9.3282374
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.4277828,
        38.8624324,
        -9.1594325,
        39.0647184
      ]
    },
    {
      "id": 10957,
      "name": "Aira",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 31.7282816,
        "lng": 130.6276569
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4723631,
        31.672866,
        130.723231,
        31.879885
      ]
    },
    {
      "id": 10958,
      "name": "Camarillo",
      "names": {
        "zh": "卡马里奥"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.216792,
        "lng": -119.0332605
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.0333105,
        34.216742,
        -119.0332105,
        34.216842
      ]
    },
    {
      "id": 10959,
      "name": "Sarqan",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 45.417065,
        "lng": 79.908879
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        79.908666,
        45.4169249,
        79.9090914,
        45.4172044
      ]
    },
    {
      "id": 10960,
      "name": "Ait Ali",
      "names": {
        "ja": "アリ",
        "th": "AIT อาลี"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 30.6890651,
        "lng": -8.3131658
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -8.3331658,
        30.6690651,
        -8.2931658,
        30.7090651
      ]
    },
    {
      "id": 10961,
      "name": "Chernogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.828236,
        "lng": 91.31321
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        91.1932675,
        53.7966351,
        91.3669575,
        53.8522394
      ]
    },
    {
      "id": 10962,
      "name": "Dschang",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 5.4469923,
        "lng": 10.053309
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        9.9357704,
        5.3202213,
        10.0942854,
        5.5092097
      ]
    },
    {
      "id": 10963,
      "name": "Tuban",
      "names": {
        "zh": "图班",
        "ja": "トゥバン"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8931644,
        "lng": 112.0448547
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        112.0048547,
        -6.9331644,
        112.0848547,
        -6.8531644
      ]
    },
    {
      "id": 10964,
      "name": "Jablah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3592457,
        "lng": 35.9252848
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        35.8852848,
        35.3192457,
        35.9652848,
        35.3992457
      ]
    },
    {
      "id": 10965,
      "name": "Balashov",
      "names": {
        "zh": "巴拉绍夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.547946,
        "lng": 43.175909
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.128918,
        51.514599,
        43.229309,
        51.563362
      ]
    },
    {
      "id": 10966,
      "name": "Shostka",
      "names": {
        "zh": "绍斯特卡"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.864355,
        "lng": 33.4728542
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.4169877,
        51.8335061,
        33.5484022,
        51.919596
      ]
    },
    {
      "id": 10967,
      "name": "Gurupi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.72794,
        "lng": -49.068046
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.18,
        -11.962,
        -48.568,
        -11.354
      ]
    },
    {
      "id": 10968,
      "name": "Asti",
      "names": {
        "zh": "阿斯蒂",
        "ja": "アスティ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.900542,
        "lng": 8.2068876
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.0879792,
        44.8410199,
        8.3167098,
        44.994549
      ]
    },
    {
      "id": 10969,
      "name": "Cotui",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 19.058468,
        "lng": -70.1533738
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.371346,
        18.8412179,
        -70.0054355,
        19.1546998
      ]
    },
    {
      "id": 10970,
      "name": "E'erguna",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 50.2402341,
        "lng": 120.1726756
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.0126756,
        50.0802341,
        120.3326756,
        50.4002341
      ]
    },
    {
      "id": 10971,
      "name": "Rheine",
      "names": {
        "zh": "赖讷"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2797707,
        "lng": 7.4373615
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.3655076,
        52.1918785,
        7.5860974,
        52.3419237
      ]
    },
    {
      "id": 10972,
      "name": "Fancheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1897094,
        "lng": 113.2662346
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.1062346,
        39.0297094,
        113.4262346,
        39.3497094
      ]
    },
    {
      "id": 10973,
      "name": "Gravata",
      "names": {
        "zh": "格拉瓦塔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.1993049,
        "lng": -35.5631137
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.6577784,
        -8.3737698,
        -35.4388564,
        -8.059
      ]
    },
    {
      "id": 10974,
      "name": "South Jordan",
      "names": {
        "fr": "Jordanie du Sud",
        "zh": "南约旦",
        "de": "Südjordanien",
        "it": "South Giordania",
        "id": "Jordan",
        "ja": "サウスジョーダン",
        "th": "เซาท์จอร์แดน",
        "pt": "Jordão do Sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.558746,
        "lng": -111.977961
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.070606,
        40.53681,
        -111.894573,
        40.582059
      ]
    },
    {
      "id": 10975,
      "name": "Palma Soriano",
      "names": {
        "zh": "帕尔马索里亚诺",
        "ja": "パルマソリアーノ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.2126686,
        "lng": -75.9973941
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -76.2150725,
        20.0242432,
        -75.8917422,
        20.449917
      ]
    },
    {
      "id": 10976,
      "name": "Stakhanov",
      "names": {
        "zh": "斯达汉诺夫"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5595971,
        "lng": 38.6499847
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        38.5624452,
        48.5181801,
        38.7349964,
        48.6058458
      ]
    },
    {
      "id": 10977,
      "name": "Palo",
      "names": {
        "zh": "帕洛",
        "ja": "パロ",
        "th": "พาโล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.159448,
        "lng": 124.9908138
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.913,
        11.09735,
        125.0765103,
        11.1933491
      ]
    },
    {
      "id": 10978,
      "name": "Nabari",
      "names": {
        "zh": "名张"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6279243,
        "lng": 136.1086582
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.03954,
        34.545893,
        136.195486,
        34.688259
      ]
    },
    {
      "id": 10979,
      "name": "Battle Creek",
      "names": {
        "fr": "Ruisseau de bataille",
        "zh": "巴特溪",
        "it": "Battaglia",
        "ja": "バトルクリーク",
        "th": "การต่อสู้ครีก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3183391,
        "lng": -85.1876785
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.1926785,
        42.3133391,
        -85.1826785,
        42.3233391
      ]
    },
    {
      "id": 10980,
      "name": "Ibiuna",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6544825,
        "lng": -47.220856
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.260856,
        -23.6944825,
        -47.180856,
        -23.6144825
      ]
    },
    {
      "id": 10981,
      "name": "Venado Tuerto",
      "names": {
        "es": "Venado morto",
        "id": "Veno Tuerto",
        "ja": "ヴェニド・トルト"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.7455871,
        "lng": -61.9686068
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -62.0086068,
        -33.7855871,
        -61.9286068,
        -33.7055871
      ]
    },
    {
      "id": 10982,
      "name": "Sangolqui",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.3288822,
        "lng": -78.4483476
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -78.479594,
        -0.4125893,
        -78.3937645,
        -0.2907607
      ]
    },
    {
      "id": 10983,
      "name": "Anzhero-Sudzhensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.076975,
        "lng": 86.029142
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        85.950638,
        56.041168,
        86.070915,
        56.141361
      ]
    },
    {
      "id": 10984,
      "name": "Infanta",
      "names": {
        "fr": "Infante",
        "zh": "公主",
        "ja": "インファンタ",
        "th": "ราชธิดาของพระเจ้าแผ่นดินสเปน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7469297,
        "lng": 121.6495987
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4376126,
        14.5342567,
        121.8355141,
        14.8746302
      ]
    },
    {
      "id": 10985,
      "name": "Houmt Souk",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.87576,
        "lng": 10.85745
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.83745,
        33.85576,
        10.87745,
        33.89576
      ]
    },
    {
      "id": 10986,
      "name": "Santo Angelo",
      "names": {
        "ja": "サントアンジェロ",
        "th": "ซานโตแองเจโล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.3000481,
        "lng": -54.2669971
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.5416333,
        -28.3654004,
        -54.1017831,
        -28.1349719
      ]
    },
    {
      "id": 10987,
      "name": "Kai",
      "names": {
        "zh": "凯",
        "ja": "カイ",
        "th": "ไก่"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.668167,
        "lng": 138.515327
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.465421,
        35.628857,
        138.565481,
        35.80717
      ]
    },
    {
      "id": 10988,
      "name": "Myslowice",
      "names": {
        "zh": "梅斯沃维采"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2414715,
        "lng": 19.1414528
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0674313,
        50.152375,
        19.2417332,
        50.2668606
      ]
    },
    {
      "id": 10989,
      "name": "Toyomamachi-teraike",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.6537,
        "lng": 141.27497
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.25497,
        38.6337,
        141.29497,
        38.6737
      ]
    },
    {
      "id": 10990,
      "name": "Daisen",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.461644,
        "lng": 140.476684
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.46506,
        39.448392,
        140.488309,
        39.474895
      ]
    },
    {
      "id": 10991,
      "name": "Berdychiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8895725,
        "lng": 28.6131692
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.6081692,
        49.8845725,
        28.6181692,
        49.8945725
      ]
    },
    {
      "id": 10992,
      "name": "Lod",
      "names": {
        "zh": "检出限",
        "th": "ล็อด"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9489012,
        "lng": 34.8884857
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.8612328,
        31.9324316,
        34.9210653,
        31.9747654
      ]
    },
    {
      "id": 10993,
      "name": "Itapetinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.2454478,
        "lng": -40.2460053
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.3785836,
        -15.5366617,
        -39.765824,
        -15.0964627
      ]
    },
    {
      "id": 10994,
      "name": "Lins",
      "names": {
        "zh": "林斯",
        "ja": "リンス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.68119,
        "lng": -49.747922
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.793279,
        -21.7343,
        -49.698086,
        -21.634336
      ]
    },
    {
      "id": 10995,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.8826436,
        "lng": -97.9405828
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.01213,
        29.7765832,
        -97.841958,
        29.960591
      ]
    },
    {
      "id": 10996,
      "name": "Espinal",
      "names": {
        "zh": "埃斯皮纳尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.1503044,
        "lng": -74.8845895
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0086702,
        4.0890207,
        -74.8048353,
        4.2499412
      ]
    },
    {
      "id": 10997,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3146042,
        "lng": 121.1137466
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0466175,
        14.2160688,
        121.1776541,
        14.3773194
      ]
    },
    {
      "id": 10998,
      "name": "Quixada",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.9706021,
        "lng": -39.0158403
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.2341117,
        -5.1681703,
        -38.6153479,
        -4.6641065
      ]
    },
    {
      "id": 10999,
      "name": "Troisdorf",
      "names": {
        "zh": "特罗斯多夫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8153071,
        "lng": 7.1593271
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.0771987,
        50.761555,
        7.2148996,
        50.8745289
      ]
    },
    {
      "id": 11000,
      "name": "Pilar",
      "names": {
        "zh": "皮拉尔",
        "ja": "ピラル",
        "th": "ปีลาร์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.836587,
        "lng": 124.3336883
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.2936883,
        9.796587,
        124.3736883,
        9.876587
      ]
    },
    {
      "id": 11001,
      "name": "Fukuchiyama",
      "names": {
        "zh": "福知山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2966996,
        "lng": 135.126643
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.917092,
        35.160614,
        135.325138,
        35.469725
      ]
    },
    {
      "id": 11002,
      "name": "Piotrkow Trybunalski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4104653,
        "lng": 19.6846413
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.6796413,
        51.4054653,
        19.6896413,
        51.4154653
      ]
    },
    {
      "id": 11003,
      "name": "Tailai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.3907951,
        "lng": 123.409346
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.249346,
        46.2307951,
        123.569346,
        46.5507951
      ]
    },
    {
      "id": 11004,
      "name": "San Ramon",
      "names": {
        "es": "San Ramón",
        "zh": "圣拉蒙",
        "ja": "サンラモン",
        "th": "ซานรามอร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7648021,
        "lng": -121.9544387
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0044837,
        37.7212399,
        -121.8769321,
        37.7951234
      ]
    },
    {
      "id": 11005,
      "name": "Longquan",
      "names": {
        "zh": "龙泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.0760972,
        "lng": 119.1370006
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.7114382,
        27.7397919,
        119.4221028,
        28.3356209
      ]
    },
    {
      "id": 11006,
      "name": "Toba Tek Singh",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.975048,
        "lng": 72.480615
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        72.454781,
        30.95441,
        72.505312,
        30.989713
      ]
    },
    {
      "id": 11007,
      "name": "Jabuticabal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.52153,
        "lng": -49.9128
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11008,
      "name": "Ovalle",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -30.6030819,
        "lng": -71.2029894
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.7156279,
        -31.2165015,
        -70.8525496,
        -30.3104674
      ]
    },
    {
      "id": 11009,
      "name": "Rio Largo",
      "names": {
        "zh": "里约拉戈",
        "ja": "リオラルゴ",
        "th": "ริโอลาร์โก"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4818831,
        "lng": -35.860331853227905
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.95975,
        -9.5888386,
        -35.7694638,
        -9.3755663
      ]
    },
    {
      "id": 11010,
      "name": "Wilhelmshaven",
      "names": {
        "zh": "威廉港"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5187847,
        "lng": 8.1153355
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1103355,
        53.5137847,
        8.1203355,
        53.5237847
      ]
    },
    {
      "id": 11011,
      "name": "Kengtung",
      "names": {
        "zh": "景栋",
        "id": "Keng Tung"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 21.29149,
        "lng": 99.60409
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        99.58409,
        21.27149,
        99.62409,
        21.31149
      ]
    },
    {
      "id": 11012,
      "name": "Xinglong",
      "names": {
        "zh": "兴隆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4659616,
        "lng": 117.7921122
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.2018195,
        40.1900706,
        118.2534353,
        40.7122608
      ]
    },
    {
      "id": 11013,
      "name": "Lake Jackson",
      "names": {
        "es": "Lago Jackson",
        "fr": "Lac Jackson",
        "zh": "杰克逊湖",
        "de": "See Jackson",
        "it": "Lago Jackson",
        "id": "Jackson",
        "ja": "湖のジャクソン",
        "th": "ทะเลสาบแจ็คสัน",
        "pt": "Lago jackson"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.0338575,
        "lng": -95.4343859
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.5143148,
        28.9746977,
        -95.3676568,
        29.1217785
      ]
    },
    {
      "id": 11014,
      "name": "Pinamungahan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2699726,
        "lng": 123.5840189
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.5440189,
        10.2299726,
        123.6240189,
        10.3099726
      ]
    },
    {
      "id": 11015,
      "name": "Bethlehem",
      "names": {
        "es": "Belén",
        "fr": "Bethléem",
        "zh": "伯利恒",
        "it": "Betlemme",
        "ja": "ベツレヘム",
        "th": "เบธเลเฮม",
        "pt": "Belém"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6178915,
        "lng": -75.3786521
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.4312032,
        40.5780539,
        -75.3029925,
        40.6725749
      ]
    },
    {
      "id": 11016,
      "name": "Alcala de Guadaira",
      "names": {
        "es": "Alcalá de Guadaira",
        "ja": "アルカラデグアディーラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.3397709,
        "lng": -5.8408396
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.9484204,
        37.186823,
        -5.6495452,
        37.3903553
      ]
    },
    {
      "id": 11017,
      "name": "Tiznit",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 29.698624,
        "lng": -9.7312815
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -9.7683916,
        29.6436086,
        -9.6542367,
        29.7456727
      ]
    },
    {
      "id": 11018,
      "name": "Goiana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.560603,
        "lng": -34.99591
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.077,
        -7.7146245,
        -34.8061842,
        -7.4619974
      ]
    },
    {
      "id": 11019,
      "name": "Shadrinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.087364,
        "lng": 63.629482
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        63.5320647,
        56.0514586,
        63.784182,
        56.1429126
      ]
    },
    {
      "id": 11020,
      "name": "Zhongcheng",
      "names": {
        "zh": "众城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.5968529,
        "lng": 103.9717185
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.9317185,
        28.5568529,
        104.0117185,
        28.6368529
      ]
    },
    {
      "id": 11021,
      "name": "Bayreuth",
      "names": {
        "zh": "拜罗伊特",
        "ja": "バイロイト",
        "th": "ไบ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.945412,
        "lng": 11.584207
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.474773,
        49.888256,
        11.672539,
        49.976952
      ]
    },
    {
      "id": 11022,
      "name": "Puqiancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.29909,
        "lng": 116.993
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11023,
      "name": "Tosu",
      "names": {
        "zh": "鸟栖"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3778536,
        "lng": 130.5061966
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4370364,
        33.3255761,
        130.5421271,
        33.428281
      ]
    },
    {
      "id": 11024,
      "name": "Kyotanabe",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8208212,
        "lng": 135.7687043
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.7637043,
        34.8158212,
        135.7737043,
        34.8258212
      ]
    },
    {
      "id": 11025,
      "name": "Wyoming",
      "names": {
        "zh": "怀俄明",
        "ja": "ワイオミング州",
        "th": "ไวโอมิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9132581,
        "lng": -85.7057035
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.7823373,
        42.850533,
        -85.641131,
        42.942207
      ]
    },
    {
      "id": 11026,
      "name": "Dubna",
      "names": {
        "zh": "杜伯纳"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.7362705,
        "lng": 37.1623696
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.0668314,
        56.6964249,
        37.2586068,
        56.7869936
      ]
    },
    {
      "id": 11027,
      "name": "Nakatsugawa",
      "names": {
        "zh": "中津川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4876463,
        "lng": 137.5005402
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.324791,
        35.35943,
        137.635032,
        35.801313
      ]
    },
    {
      "id": 11028,
      "name": "Redencao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0299327,
        "lng": -50.0309543
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.6125358,
        -8.3985782,
        -49.8267806,
        -7.7149408
      ]
    },
    {
      "id": 11029,
      "name": "Oshkosh",
      "names": {
        "zh": "奥什科什",
        "ja": "オシュコシュ",
        "th": "ออชคอช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.017678,
        "lng": -88.561028
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.643964,
        43.945039,
        -88.50785,
        44.090344
      ]
    },
    {
      "id": 11030,
      "name": "Bonab",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.3409239,
        "lng": 46.050471
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        46.0371231,
        37.3135786,
        46.1004939,
        37.3804845
      ]
    },
    {
      "id": 11031,
      "name": "Sapiranga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6392382,
        "lng": -51.0068586
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.0558533,
        -29.7064404,
        -50.9415651,
        -29.541334
      ]
    },
    {
      "id": 11032,
      "name": "Hammond",
      "names": {
        "zh": "哈蒙德",
        "ja": "ハモンド",
        "th": "แฮมมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6173959,
        "lng": -87.5220108
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.5253944,
        41.566373,
        -87.4323597,
        41.7100956
      ]
    },
    {
      "id": 11033,
      "name": "Waldorf",
      "names": {
        "zh": "华尔道夫",
        "ja": "ウォルドーフ",
        "th": "วอลดอร์ฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.602393500000005,
        "lng": -76.927407203125
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.9984531,
        38.543513,
        -76.8419873,
        38.6612911
      ]
    },
    {
      "id": 11034,
      "name": "Missouri City",
      "names": {
        "es": "Ciudad de Missouri",
        "fr": "Ville de Missouri",
        "zh": "密苏里州市",
        "it": "Città del Missouri",
        "id": "Kota Missouri",
        "ja": "ミズーリ州",
        "th": "เมืองมิสซูรี",
        "pt": "Cidade de missouri"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.6178787,
        "lng": -95.5417198
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.5417698,
        29.6178287,
        -95.5416698,
        29.6179287
      ]
    },
    {
      "id": 11035,
      "name": "Luneburg",
      "names": {
        "es": "Lüneburg",
        "fr": "Lüneburg",
        "zh": "吕讷堡",
        "de": "Lüneburg",
        "it": "Lüneburg",
        "id": "Lüneburg",
        "pt": "Lüneburg"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.250306,
        "lng": 10.414613
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.330693,
        53.191438,
        10.51427,
        53.286064
      ]
    },
    {
      "id": 11036,
      "name": "Pasco",
      "names": {
        "zh": "帕斯科",
        "ja": "パスコ",
        "th": "พัสโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.2369251,
        "lng": -119.087496
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.092496,
        46.2319251,
        -119.082496,
        46.2419251
      ]
    },
    {
      "id": 11037,
      "name": "Mangalagiri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.4355398,
        "lng": 80.5467928085435
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.4901636,
        16.3653182,
        80.6996809,
        16.5055949
      ]
    },
    {
      "id": 11038,
      "name": "Kawartha Lakes",
      "names": {
        "es": "Lagos de Kawartha",
        "fr": "Kawartha Lacs",
        "zh": "Kawartha湖",
        "de": "Kawartha-Seen",
        "ja": "カワルタ湖",
        "pt": "Lagos kawartha"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.3596825,
        "lng": -78.7421729
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.2085137,
        44.0583614,
        -78.4255208,
        44.9855012
      ]
    },
    {
      "id": 11039,
      "name": "Bombo",
      "names": {
        "zh": "邦博河"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5770175,
        "lng": 32.5370142
      },
      "country": "Uganda",
      "importance": 3,
      "bbox": [
        32.4970142,
        0.5370175,
        32.5770142,
        0.6170175
      ]
    },
    {
      "id": 11040,
      "name": "Libon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2988701,
        "lng": 123.4360839
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3960839,
        13.2588701,
        123.4760839,
        13.3388701
      ]
    },
    {
      "id": 11041,
      "name": "Sasagawa",
      "names": {
        "zh": "笹川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.38524,
        "lng": 139.45937
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.43937,
        38.36524,
        139.47937,
        38.40524
      ]
    },
    {
      "id": 11042,
      "name": "Wheeling",
      "names": {
        "zh": "威灵",
        "ja": "ホイール",
        "th": "ล้อ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0639616,
        "lng": -80.7209149
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.7390431,
        40.016087,
        -80.642957,
        40.152488
      ]
    },
    {
      "id": 11043,
      "name": "Carpina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8459105,
        "lng": -35.2538861
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.406,
        -7.892483,
        -35.2148091,
        -7.761
      ]
    },
    {
      "id": 11044,
      "name": "Ciudad Real",
      "names": {
        "fr": "Ciudad réel",
        "zh": "ciudad真实",
        "de": "Ciudad echt",
        "it": "Ciudad reale",
        "id": "Ciudad nyata",
        "th": "Ciudad จริง"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9854201,
        "lng": -3.9285138
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -4.0920592,
        38.841962,
        -3.8653845,
        39.0811263
      ]
    },
    {
      "id": 11045,
      "name": "Bugallon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9042882,
        "lng": 120.17371265549481
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.0980544,
        15.8071581,
        120.2496113,
        16.0010793
      ]
    },
    {
      "id": 11046,
      "name": "Brick",
      "names": {
        "es": "Ladrillo",
        "fr": "Brique",
        "zh": "砖",
        "de": "Ziegel",
        "it": "Mattoni",
        "id": "Bata",
        "ja": "レンガ",
        "th": "อิฐ",
        "pt": "Tijolo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 52.443165,
        "lng": -9.6326147
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -9.6366803,
        52.4343554,
        -9.6246919,
        52.4450629
      ]
    },
    {
      "id": 11047,
      "name": "Acacias",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.9887317,
        "lng": -73.767432
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.0370369,
        3.841891,
        -73.417662,
        4.2028901
      ]
    },
    {
      "id": 11048,
      "name": "Kendall",
      "names": {
        "zh": "肯德尔",
        "ja": "ケンダル",
        "th": "เคนดัลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.6718125,
        "lng": -80.35486075316194
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.389061,
        25.6286983,
        -80.30508,
        25.7150658
      ]
    },
    {
      "id": 11049,
      "name": "Standerton",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.9433333,
        "lng": 29.2436111
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        29.2036111,
        -26.9833333,
        29.2836111,
        -26.9033333
      ]
    },
    {
      "id": 11050,
      "name": "Itahari",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 26.66667,
        "lng": 87.28333
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        87.26333,
        26.64667,
        87.30333,
        26.68667
      ]
    },
    {
      "id": 11051,
      "name": "Pozorrubio",
      "names": {
        "zh": "波索鲁维奥"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1163272,
        "lng": 120.5434429
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5433929,
        16.1162772,
        120.5434929,
        16.1163772
      ]
    },
    {
      "id": 11052,
      "name": "Ardakan",
      "names": {
        "zh": "阿尔达坎"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3063333,
        "lng": 54.0159678
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.9686351,
        32.2750654,
        54.048401,
        32.3377866
      ]
    },
    {
      "id": 11053,
      "name": "Tatebayashi",
      "names": {
        "zh": "馆林"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2454338,
        "lng": 139.5421576
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.459106,
        36.2100146,
        139.6256404,
        36.284714
      ]
    },
    {
      "id": 11054,
      "name": "Dorsten",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6604071,
        "lng": 6.9647431
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.8938575,
        51.6227637,
        7.0882141,
        51.801026
      ]
    },
    {
      "id": 11055,
      "name": "Gela",
      "names": {
        "zh": "格拉",
        "th": "เกล่า"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.0664363,
        "lng": 14.2502445
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.1149748,
        37.0020778,
        14.4441006,
        37.1720544
      ]
    },
    {
      "id": 11056,
      "name": "Di An",
      "names": {
        "es": "Di un",
        "fr": "Di un",
        "zh": "迪安",
        "de": "DI a",
        "th": "di",
        "pt": "Di um"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.892242,
        "lng": 106.7631404
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        106.6031404,
        10.732242,
        106.9231404,
        11.052242
      ]
    },
    {
      "id": 11057,
      "name": "Rancho Cordova",
      "names": {
        "es": "Rancho Córdova"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5890723,
        "lng": -121.302728
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.3364865,
        38.501909,
        -121.184938,
        38.633388
      ]
    },
    {
      "id": 11058,
      "name": "Dalaguete",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7612,
        "lng": 123.5349
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.5149,
        9.7412,
        123.5549,
        9.7812
      ]
    },
    {
      "id": 11059,
      "name": "Tongye",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9676135,
        "lng": 114.3790054
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.3390054,
        37.9276135,
        114.4190054,
        38.0076135
      ]
    },
    {
      "id": 11060,
      "name": "Gode",
      "names": {
        "zh": "戈德"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 5.9526975,
        "lng": 43.5522312
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        43.3922312,
        5.7926975,
        43.7122312,
        6.1126975
      ]
    },
    {
      "id": 11061,
      "name": "Amakusa",
      "names": {
        "zh": "天草",
        "ja": "天草"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.45861,
        "lng": 130.19306
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.17306,
        32.43861,
        130.21306,
        32.47861
      ]
    },
    {
      "id": 11062,
      "name": "Sao Bento do Sul",
      "names": {
        "ja": "サンベントドスル",
        "pt": "São Bento do Sul"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.2483669,
        "lng": -49.3820672
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.4907315,
        -26.426,
        -49.1693923,
        -26.1619719
      ]
    },
    {
      "id": 11063,
      "name": "Conway",
      "names": {
        "zh": "康威",
        "ja": "コンウエー",
        "th": "คอนเวย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2521996,
        "lng": -92.6888936
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.040823,
        35.0714269,
        -92.479469,
        35.463596
      ]
    },
    {
      "id": 11064,
      "name": "Wiwili",
      "names": {
        "zh": "维维利"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.6200373,
        "lng": -85.8181812
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -85.8310545,
        13.4538539,
        -85.0598176,
        14.5859504
      ]
    },
    {
      "id": 11065,
      "name": "Samundri",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.065143,
        "lng": 72.9588416
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.7988416,
        30.905143,
        73.1188416,
        31.225143
      ]
    },
    {
      "id": 11066,
      "name": "Esperanza",
      "names": {
        "zh": "埃斯佩兰萨",
        "ja": "エスペランサ",
        "th": "เอสเปอรัน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 40.4592436,
        "lng": -3.6455644
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -3.6505644,
        40.4542436,
        -3.6405644,
        40.4642436
      ]
    },
    {
      "id": 11067,
      "name": "Balingasag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.7428634,
        "lng": 124.7755689
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.7355689,
        8.7028634,
        124.8155689,
        8.7828634
      ]
    },
    {
      "id": 11068,
      "name": "Gary",
      "names": {
        "zh": "加里·",
        "ja": "ゲイリー",
        "th": "แกรี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6020962,
        "lng": -87.3370646
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.4331146,
        41.52197,
        -87.2220507,
        41.6500588
      ]
    },
    {
      "id": 11069,
      "name": "Castrop-Rauxel",
      "names": {
        "zh": "卡斯特罗普-rauxel"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.54777,
        "lng": 7.317609
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.241687,
        51.520733,
        7.371871,
        51.627103
      ]
    },
    {
      "id": 11070,
      "name": "Altoona",
      "names": {
        "zh": "阿尔图纳",
        "th": "แอลทูนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5143892,
        "lng": -78.4012778
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.4062778,
        40.5093892,
        -78.3962778,
        40.5193892
      ]
    },
    {
      "id": 11071,
      "name": "Lodi",
      "names": {
        "zh": "洛迪",
        "ja": "ロディ",
        "th": "โลดิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1343744,
        "lng": -121.2735432
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.40527,
        38.082454,
        -121.241311,
        38.154942
      ]
    },
    {
      "id": 11072,
      "name": "Arlington Heights",
      "names": {
        "fr": "Heights Arlington",
        "zh": "阿灵顿高地",
        "ja": "アーリントンの高さ",
        "th": "อาร์ลิงตันไฮทส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0841656,
        "lng": -87.9838319
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.9888319,
        42.0791656,
        -87.9788319,
        42.0891656
      ]
    },
    {
      "id": 11073,
      "name": "Darhan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 49.4922428,
        "lng": 105.9361613
      },
      "country": "Mongolia",
      "importance": 3,
      "bbox": [
        105.8908677,
        49.3788585,
        106.020576,
        49.5265898
      ]
    },
    {
      "id": 11074,
      "name": "Nizhyn",
      "names": {
        "th": "นีชิน"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.0464747,
        "lng": 31.8806289
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.8027603,
        50.986932,
        31.9443167,
        51.0963273
      ]
    },
    {
      "id": 11075,
      "name": "Piracununga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8890727,
        "lng": -47.0411162
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0419939,
        -22.8896428,
        -47.0407882,
        -22.8887341
      ]
    },
    {
      "id": 11076,
      "name": "Miki",
      "names": {
        "zh": "三木",
        "ja": "三木",
        "th": "มิกิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7997742,
        "lng": 134.9817843
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.9767843,
        34.7947742,
        134.9867843,
        34.8047742
      ]
    },
    {
      "id": 11077,
      "name": "Grand-Bassam",
      "names": {
        "zh": "大巴萨姆",
        "ja": "グランド-bassam",
        "th": "แกรนด์ bassam"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.192685,
        "lng": -3.742769
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -3.762769,
        5.172685,
        -3.722769,
        5.212685
      ]
    },
    {
      "id": 11078,
      "name": "Hoorn",
      "names": {
        "zh": "霍伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.653272,
        "lng": 5.0735802
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.0135367,
        52.5789361,
        5.1353057,
        52.6843655
      ]
    },
    {
      "id": 11079,
      "name": "Copacabana",
      "names": {
        "zh": "科帕卡巴纳",
        "ja": "コパカバーナ",
        "th": "โคปาคาบานา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3461047,
        "lng": -75.5080262
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5360162,
        6.2897007,
        -75.4523573,
        6.4306731
      ]
    },
    {
      "id": 11080,
      "name": "Bolingbrook",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.691344,
        "lng": -88.090619
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.179663,
        41.630767,
        -88.028444,
        41.735937
      ]
    },
    {
      "id": 11081,
      "name": "Rochester Hills",
      "names": {
        "zh": "罗切斯特山",
        "id": "Bukit Rochester",
        "ja": "ロチェスターヒルズ",
        "th": "โรเชสเตอร์ฮิลส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6583661,
        "lng": -83.1499322
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.21378,
        42.6210029,
        -83.0915053,
        42.712491
      ]
    },
    {
      "id": 11082,
      "name": "Caninde",
      "names": {
        "zh": "卡宁德"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.3547946,
        "lng": -39.3108516
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.7900547,
        -4.7386519,
        -39.0283557,
        -4.068058
      ]
    },
    {
      "id": 11083,
      "name": "Dumangas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.825,
        "lng": 122.713
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.693,
        10.805,
        122.733,
        10.845
      ]
    },
    {
      "id": 11084,
      "name": "Novoaltaysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.403775,
        "lng": 83.932261
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        83.857768,
        53.356038,
        83.994981,
        53.474001
      ]
    },
    {
      "id": 11085,
      "name": "Winchester",
      "names": {
        "zh": "温彻斯特",
        "ja": "ウィンチェスター",
        "th": "วินเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1852184,
        "lng": -78.1652404
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.2005131,
        39.1310274,
        -78.136725,
        39.2048701
      ]
    },
    {
      "id": 11086,
      "name": "Framingham",
      "names": {
        "zh": "弗雷明汉",
        "ja": "フレーミングハム",
        "th": "รามิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.298577449999996,
        "lng": -71.42090039383868
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.4213459,
        42.298181,
        -71.4205186,
        42.2989197
      ]
    },
    {
      "id": 11087,
      "name": "Detmold",
      "names": {
        "zh": "代特莫尔德",
        "ja": "デトモルト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.936284,
        "lng": 8.8791526
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.7630753,
        51.8587332,
        8.9895842,
        51.9982701
      ]
    },
    {
      "id": 11088,
      "name": "Palmeira dos Indios",
      "names": {
        "th": "ปาล์มซีดอส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4073815,
        "lng": -36.6272741707995
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.6274456,
        -9.4075889,
        -36.627088,
        -9.4071622
      ]
    },
    {
      "id": 11089,
      "name": "Alicia",
      "names": {
        "zh": "艾丽西亚",
        "ja": "アリシア",
        "th": "อลิเซีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.7789338,
        "lng": 121.6992173
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6592173,
        16.7389338,
        121.7392173,
        16.8189338
      ]
    },
    {
      "id": 11090,
      "name": "Joao Monlevade",
      "names": {
        "es": "Joao Montlevade",
        "fr": "Joao Monastavade",
        "pt": "João Monlevade"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.809444,
        "lng": -43.173334
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.2233623,
        -19.896,
        -43.0901341,
        -19.7870631
      ]
    },
    {
      "id": 11091,
      "name": "Pedro Brand",
      "names": {
        "es": "Marca Pedro",
        "fr": "Marque Pedro",
        "zh": "佩德罗品牌",
        "de": "Pedro-Marke",
        "it": "Marchio pedro",
        "id": "Merek Pedro",
        "ja": "ペドロブランド",
        "th": "แบรนด์เปโดร",
        "pt": "Marca Pedro"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.5675493,
        "lng": -70.0921191
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.1754535,
        18.523109,
        -70.0020599,
        18.7291633
      ]
    },
    {
      "id": 11092,
      "name": "Tatsunocho-tominaga",
      "names": {
        "zh": "tatsunocho-富永"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8584412,
        "lng": 134.5465785
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.5365785,
        34.8484412,
        134.5565785,
        34.8684412
      ]
    },
    {
      "id": 11093,
      "name": "Curvelo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.7571926,
        "lng": -44.4305993
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.7981262,
        -19.232,
        -44.074,
        -18.276
      ]
    },
    {
      "id": 11094,
      "name": "Mineral'nyye Vody",
      "names": {
        "fr": "Minéral'nyye Vody",
        "ja": "ミネラル・ニーティーヴェーリー"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.212123,
        "lng": 43.128994
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.084888,
        44.182335,
        43.158222,
        44.237667
      ]
    },
    {
      "id": 11095,
      "name": "Kara-Balta",
      "names": {
        "zh": "卡拉-巴尔塔",
        "ja": "カラ-balta",
        "th": "คาร่า-บอลต้า"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.82638395,
        "lng": 73.87341881625608
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        73.8731428,
        42.8262867,
        73.8736948,
        42.8264812
      ]
    },
    {
      "id": 11096,
      "name": "Sundsvall",
      "names": {
        "zh": "松兹瓦尔"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 62.3907552,
        "lng": 17.3071024
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        17.196125,
        62.3619984,
        17.3993176,
        62.4723192
      ]
    },
    {
      "id": 11097,
      "name": "Hilton Head Island",
      "names": {
        "zh": "希尔顿头岛",
        "ja": "ヒルトンヘッドアイランド",
        "th": "เกาะฮิลตันหัว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.193817,
        "lng": -80.7381637
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.0581637,
        31.873817,
        -80.4181637,
        32.513817
      ]
    },
    {
      "id": 11098,
      "name": "Union City",
      "names": {
        "zh": "联盟城",
        "de": "Union",
        "id": "Kota serikat",
        "ja": "ユニオンシティ",
        "th": "เมืองยูเนียน",
        "pt": "Cidade da União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5872576,
        "lng": -122.0215737
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0967521,
        37.5613645,
        -121.93978,
        37.6366008
      ]
    },
    {
      "id": 11099,
      "name": "Ouro Preto",
      "names": {
        "zh": "uto preto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.385527,
        "lng": -43.5035214
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.932038,
        -20.632,
        -43.403,
        -20.161
      ]
    },
    {
      "id": 11100,
      "name": "Yelabuga",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7577131,
        "lng": 52.0539938
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        51.9437903,
        55.7243835,
        52.1310389,
        55.7926561
      ]
    },
    {
      "id": 11101,
      "name": "San Ramon de la Nueva Oran",
      "names": {
        "es": "San Ramón de la Nueva Oran",
        "ja": "サンラモンデラヌエバオラン",
        "th": "ซานรามอนเดอลานูวาออรน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -23.136084,
        "lng": -64.3230702
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.3375072,
        -23.1633629,
        -64.2972636,
        -23.1115352
      ]
    },
    {
      "id": 11102,
      "name": "Konin",
      "names": {
        "zh": "科宁"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2312372,
        "lng": 18.2532655
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.2482655,
        52.2262372,
        18.2582655,
        52.2362372
      ]
    },
    {
      "id": 11103,
      "name": "Owensboro",
      "names": {
        "zh": "欧文斯伯勒",
        "th": "โอเวนส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7742152,
        "lng": -87.1133304
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.2049659,
        37.710659,
        -87.052911,
        37.810033
      ]
    },
    {
      "id": 11104,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7668943,
        "lng": -89.3563845
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.67955,
        37.5618627,
        -89.1506157,
        37.9552
      ]
    },
    {
      "id": 11105,
      "name": "Brookes Point",
      "names": {
        "zh": "布鲁克斯点",
        "de": "Brookes-Punkt",
        "it": "Punto di Brookes",
        "th": "จุดบรูคส์",
        "pt": "Ponto de brookes"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.77201,
        "lng": 117.8371
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        117.8171,
        8.75201,
        117.8571,
        8.79201
      ]
    },
    {
      "id": 11106,
      "name": "Riberalta",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -10.997342,
        "lng": -66.0751504
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -66.4915855,
        -12.0037815,
        -65.4651136,
        -10.6883143
      ]
    },
    {
      "id": 11107,
      "name": "Arnsberg",
      "names": {
        "zh": "阿恩斯贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4002384,
        "lng": 8.0605908
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.8640301,
        51.3547476,
        8.2102601,
        51.4913301
      ]
    },
    {
      "id": 11108,
      "name": "Prince George",
      "names": {
        "es": "Príncipe george",
        "zh": "乔治王子",
        "de": "Prinz George",
        "id": "Pangeran George",
        "ja": "ジョージプリンスジョージ",
        "th": "เจ้าชายจอร์จ",
        "pt": "Príncipe George"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 53.919124,
        "lng": -122.7440449
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -122.7490449,
        53.914124,
        -122.7390449,
        53.924124
      ]
    },
    {
      "id": 11109,
      "name": "Kostiantynivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.534859,
        "lng": 37.6923705
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.6269408,
        48.48678,
        37.7961953,
        48.5740175
      ]
    },
    {
      "id": 11110,
      "name": "Nova Serrana",
      "names": {
        "ja": "ノヴァ・セラナ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.875834,
        "lng": -44.984166
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.1257664,
        -19.943,
        -44.8696604,
        -19.775
      ]
    },
    {
      "id": 11111,
      "name": "Lala",
      "names": {
        "zh": "拉拉",
        "ja": "ララ",
        "th": "ลาลา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9733694,
        "lng": 123.7476509
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7076509,
        7.9333694,
        123.7876509,
        8.0133694
      ]
    },
    {
      "id": 11112,
      "name": "Molina de Segura",
      "names": {
        "ja": "モリナデセシュラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.056942,
        "lng": -1.207652
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -1.239542,
        38.038913,
        -1.187981,
        38.094559
      ]
    },
    {
      "id": 11113,
      "name": "Majadahonda",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 40.473055,
        "lng": -3.8724138
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.9454001,
        40.4419125,
        -3.8343462,
        40.5015034
      ]
    },
    {
      "id": 11114,
      "name": "Yurihonjo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.3858771,
        "lng": 140.0487402
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6179325,
        39.0135963,
        140.355916,
        39.62133
      ]
    },
    {
      "id": 11115,
      "name": "Inuyama",
      "names": {
        "zh": "犬山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3802772,
        "lng": 136.9457636
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.9407636,
        35.3752772,
        136.9507636,
        35.3852772
      ]
    },
    {
      "id": 11116,
      "name": "Bawku",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 11.0607602,
        "lng": -0.2422876
      },
      "country": "Ghana",
      "importance": 3,
      "bbox": [
        -0.2822876,
        11.0207602,
        -0.2022876,
        11.1007602
      ]
    },
    {
      "id": 11117,
      "name": "Yegoryevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.373466,
        "lng": 39.044783
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.007721,
        55.352139,
        39.08633,
        55.402084
      ]
    },
    {
      "id": 11118,
      "name": "Kasama",
      "names": {
        "zh": "笠间"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3452244,
        "lng": 140.3042261
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.162317,
        36.265584,
        140.370071,
        36.449186
      ]
    },
    {
      "id": 11119,
      "name": "Chaigoubu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.66778,
        "lng": 114.41139
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.39139,
        40.64778,
        114.43139,
        40.68778
      ]
    },
    {
      "id": 11120,
      "name": "Tupi",
      "names": {
        "es": "Tupí",
        "zh": "图皮",
        "ja": "トゥピ族"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.3313349,
        "lng": 124.9511647
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8680091,
        6.2510189,
        125.1052794,
        6.4263637
      ]
    },
    {
      "id": 11121,
      "name": "Siuna",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.73321,
        "lng": -84.77725
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -84.79725,
        13.71321,
        -84.75725,
        13.75321
      ]
    },
    {
      "id": 11122,
      "name": "Linares",
      "names": {
        "zh": "利纳雷斯"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -35.8452905,
        "lng": -71.5977173
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.7970973,
        -36.2259063,
        -71.0062783,
        -35.7033336
      ]
    },
    {
      "id": 11123,
      "name": "Jingzhou",
      "names": {
        "zh": "荆州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.372574,
        "lng": 112.2074419
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.2024419,
        30.367574,
        112.2124419,
        30.377574
      ]
    },
    {
      "id": 11124,
      "name": "Irece",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.3034965,
        "lng": -41.8557929
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.9713451,
        -11.3921426,
        -41.7296926,
        -11.2232823
      ]
    },
    {
      "id": 11125,
      "name": "Shrewsbury",
      "names": {
        "zh": "什鲁斯伯里",
        "ja": "シュルーズベリー",
        "th": "ชรูว์สบิวรี่"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.707755,
        "lng": -2.7540658
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.8143238,
        52.6801902,
        -2.7012225,
        52.7549838
      ]
    },
    {
      "id": 11126,
      "name": "Quillota",
      "names": {
        "th": "กิโยตา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.879997,
        "lng": -71.2473555
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.4525538,
        -32.9734686,
        -71.1198428,
        -32.8300714
      ]
    },
    {
      "id": 11127,
      "name": "Cleveland",
      "names": {
        "zh": "克利夫兰",
        "ja": "クリーブランド",
        "th": "คลีฟแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1595182,
        "lng": -84.8766115
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.959019,
        35.118939,
        -84.804226,
        35.242473
      ]
    },
    {
      "id": 11128,
      "name": "Wausau",
      "names": {
        "zh": "沃索"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.963489,
        "lng": -89.636599
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.76804,
        44.91905,
        -89.57311,
        45.006473
      ]
    },
    {
      "id": 11129,
      "name": "Troitsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.0847722,
        "lng": 61.5514047
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        61.4193337,
        54.0180722,
        61.6940911,
        54.1506268
      ]
    },
    {
      "id": 11130,
      "name": "Bula",
      "names": {
        "zh": "布拉"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4683491,
        "lng": 123.2778401
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.2378401,
        13.4283491,
        123.3178401,
        13.5083491
      ]
    },
    {
      "id": 11131,
      "name": "Ostrowiec Swietokrzyski",
      "names": {
        "ja": "SwietokrzyskiOstrowiec"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9398768,
        "lng": 21.38743
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.3366406,
        50.9064472,
        21.4762243,
        50.9939611
      ]
    },
    {
      "id": 11132,
      "name": "Picos",
      "names": {
        "zh": "皮库斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.0823544,
        "lng": -41.4685053
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.6347061,
        -7.2284684,
        -41.3319303,
        -6.8703146
      ]
    },
    {
      "id": 11133,
      "name": "Almelo",
      "names": {
        "zh": "阿尔默洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3563585,
        "lng": 6.6636219
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.5977049,
        52.289974,
        6.721168,
        52.3991228
      ]
    },
    {
      "id": 11134,
      "name": "El Hamma",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.8894378,
        "lng": 9.7997111
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        9.7597111,
        33.8494378,
        9.8397111,
        33.9294378
      ]
    },
    {
      "id": 11135,
      "name": "Quang Tri",
      "names": {
        "id": "Qual tri"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.7546907,
        "lng": 107.1895518
      },
      "country": "Vietnam",
      "importance": 3,
      "bbox": [
        107.0671628,
        16.6279525,
        107.209228,
        16.7685317
      ]
    },
    {
      "id": 11136,
      "name": "Lanxi",
      "names": {
        "zh": "兰溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.2536252,
        "lng": 126.2814338
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        126.1214338,
        46.0936252,
        126.4414338,
        46.4136252
      ]
    },
    {
      "id": 11137,
      "name": "Jose Abad Santos",
      "names": {
        "es": "José Abad Santos",
        "zh": "何塞阿巴德桑托斯",
        "pt": "José Abad Santos"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9124655,
        "lng": 125.6445932
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.3297446,
        5.4888374,
        125.8269564,
        6.0481548
      ]
    },
    {
      "id": 11138,
      "name": "Anniston",
      "names": {
        "zh": "安尼斯顿",
        "ja": "アニストン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6624824,
        "lng": -85.8283224
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.9312419,
        33.5999705,
        -85.7553747,
        33.7509219
      ]
    },
    {
      "id": 11139,
      "name": "Brakpan",
      "names": {
        "zh": "柏莱克潘"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.235278,
        "lng": 28.37
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        28.33,
        -26.275278,
        28.41,
        -26.195278
      ]
    },
    {
      "id": 11140,
      "name": "Zhuolu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.3773961,
        "lng": 115.2013659
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.1613659,
        40.3373961,
        115.2413659,
        40.4173961
      ]
    },
    {
      "id": 11141,
      "name": "Kirovo-Chepetsk",
      "names": {
        "zh": "基洛沃-chepetsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.555069,
        "lng": 50.0313048
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        49.9231146,
        58.49499,
        50.2904532,
        58.6218173
      ]
    },
    {
      "id": 11142,
      "name": "Apple Valley",
      "names": {
        "es": "Valle de manzana",
        "fr": "Vallée de pomme",
        "zh": "苹果谷",
        "de": "Apfeltal",
        "id": "Lembah Apple",
        "ja": "アップルバレー",
        "pt": "Vale da Apple"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5008311,
        "lng": -117.1858759
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2869142,
        34.4281401,
        -117.1507566,
        34.6476734
      ]
    },
    {
      "id": 11143,
      "name": "Ludenscheid",
      "names": {
        "de": "Lüdenscheid",
        "it": "Lüdenscheid"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2205538,
        "lng": 7.6288574
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6288074,
        51.2205038,
        7.6289074,
        51.2206038
      ]
    },
    {
      "id": 11144,
      "name": "Landshut",
      "names": {
        "zh": "兰茨胡特",
        "ja": "ランツフート"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.536217,
        "lng": 12.1516551
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.0182158,
        48.5070022,
        12.2844885,
        48.5955945
      ]
    },
    {
      "id": 11145,
      "name": "Vinhedo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0298535,
        "lng": -46.9749847
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0410644,
        -23.1154243,
        -46.903,
        -23.0067258
      ]
    },
    {
      "id": 11146,
      "name": "Chapadinha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7416803,
        "lng": -43.3548006
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.729,
        -4.228,
        -43.197,
        -3.316
      ]
    },
    {
      "id": 11147,
      "name": "Shibukawa",
      "names": {
        "zh": "涩川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4894606,
        "lng": 139.0001287
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.880646,
        36.448566,
        139.169338,
        36.5920145
      ]
    },
    {
      "id": 11148,
      "name": "Sault Ste. Marie",
      "names": {
        "zh": "sault ste。 玛丽",
        "ja": "Sault Ste。 マリー",
        "th": "Sault Ste มารี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.5126554,
        "lng": -84.3330301
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -84.6273183,
        46.4528611,
        -84.2258889,
        46.6122858
      ]
    },
    {
      "id": 11149,
      "name": "Nepalganj",
      "names": {
        "zh": "尼泊尔根杰"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.05,
        "lng": 81.61667
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        81.59667,
        28.03,
        81.63667,
        28.07
      ]
    },
    {
      "id": 11150,
      "name": "Carles",
      "names": {
        "zh": "卡莱斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.5735531,
        "lng": 123.133556
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0399787,
        11.336434,
        123.5104667,
        11.7739128
      ]
    },
    {
      "id": 11151,
      "name": "Mansfield",
      "names": {
        "zh": "曼斯菲尔德",
        "ja": "マンスフィールド",
        "th": "แมนส์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.75839,
        "lng": -82.5154471
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.5900433,
        40.6886407,
        -82.4560819,
        40.8436307
      ]
    },
    {
      "id": 11152,
      "name": "Cuamba",
      "names": {
        "zh": "库安巴"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -14.80306,
        "lng": 36.53722
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        36.51722,
        -14.82306,
        36.55722,
        -14.78306
      ]
    },
    {
      "id": 11153,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0283014,
        "lng": 120.6938038
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5942962,
        14.9932982,
        120.7278114,
        15.1342393
      ]
    },
    {
      "id": 11154,
      "name": "Wenping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3538143,
        "lng": 102.5208783
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.4808783,
        27.3138143,
        102.5608783,
        27.3938143
      ]
    },
    {
      "id": 11155,
      "name": "Tokar",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 18.4303582,
        "lng": 37.7339322
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        37.026111,
        17.015278,
        39.0576252,
        19.5846113
      ]
    },
    {
      "id": 11156,
      "name": "Ostend",
      "names": {
        "fr": "Ostende",
        "zh": "奥斯坦德",
        "de": "Ostende",
        "th": "ออสเตนด์",
        "pt": "Oostende"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.2258565,
        "lng": 2.919496
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        2.8386276,
        51.1846337,
        3.004349,
        51.2460739
      ]
    },
    {
      "id": 11157,
      "name": "Shakargarh",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2631481,
        "lng": 75.1604548
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        75.0004548,
        32.1031481,
        75.3204548,
        32.4231481
      ]
    },
    {
      "id": 11158,
      "name": "Tan-Tan",
      "names": {
        "zh": "谭-谭",
        "ja": "タン・タン",
        "th": "ตาล-ตาล"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 28.437553,
        "lng": -11.098664
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -11.1289782,
        28.4089927,
        -11.0704951,
        28.4601504
      ]
    },
    {
      "id": 11159,
      "name": "Sambrial",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.4756481,
        "lng": 74.3529135
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        74.1929135,
        32.3156481,
        74.5129135,
        32.6356481
      ]
    },
    {
      "id": 11160,
      "name": "Chapayevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.982905,
        "lng": 49.709362
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        49.7313,
        52.9917,
        49.7713,
        53.0317
      ]
    },
    {
      "id": 11161,
      "name": "Santa Rosa de Cabal",
      "names": {
        "zh": "圣罗莎de Cabal",
        "ja": "サンタローザデマル",
        "th": "ซานตาโรซ่าเดอคาล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.86806,
        "lng": -75.62139
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.64139,
        4.84806,
        -75.60139,
        4.88806
      ]
    },
    {
      "id": 11162,
      "name": "Moa",
      "names": {
        "zh": "金属氧化物避雷器",
        "th": "ซื้อขายเรือ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.6559593,
        "lng": -74.9430494
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -75.0967588,
        20.4051939,
        -74.7138655,
        20.7002027
      ]
    },
    {
      "id": 11163,
      "name": "Otawara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.87103,
        "lng": 140.0154048
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.921674,
        36.768403,
        140.267388,
        36.948351
      ]
    },
    {
      "id": 11164,
      "name": "Itapira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.436111,
        "lng": -46.821667
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.8841177,
        -22.5861679,
        -46.6167927,
        -22.2856892
      ]
    },
    {
      "id": 11165,
      "name": "Naqadeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.9540619,
        "lng": 45.3853875
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.3853375,
        36.9540119,
        45.3854375,
        36.9541119
      ]
    },
    {
      "id": 11166,
      "name": "Montepuez",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -13.1253048,
        "lng": 39.0016963
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        38.8416963,
        -13.2853048,
        39.1616963,
        -12.9653048
      ]
    },
    {
      "id": 11167,
      "name": "Bacacay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2926072,
        "lng": 123.7912494
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6861783,
        13.1894553,
        124.0267598,
        13.4700181
      ]
    },
    {
      "id": 11168,
      "name": "La Dorada",
      "names": {
        "ja": "ラドラダ",
        "th": "ลาโดราดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4457655,
        "lng": -74.6618458
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8145849,
        5.2832082,
        -74.6245868,
        5.7828835
      ]
    },
    {
      "id": 11169,
      "name": "Morong",
      "names": {
        "zh": "莫龙"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5151374,
        "lng": 121.2380858
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1980858,
        14.4751374,
        121.2780858,
        14.5551374
      ]
    },
    {
      "id": 11170,
      "name": "Catanauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5929805,
        "lng": 122.3223125
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.1131453,
        13.4708233,
        122.4118746,
        13.7386926
      ]
    },
    {
      "id": 11171,
      "name": "Alegrete",
      "names": {
        "zh": "阿莱格雷蒂"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.790166,
        "lng": -55.794862
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.6646665,
        -30.2325212,
        -55.111869,
        -29.295353
      ]
    },
    {
      "id": 11172,
      "name": "Vsevolozhsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 60.019175,
        "lng": 30.681844
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        30.605499,
        60.006611,
        30.717861,
        60.032585
      ]
    },
    {
      "id": 11173,
      "name": "Sotik",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.687912,
        "lng": 35.1133229
      },
      "country": "Kenya",
      "importance": 3,
      "bbox": [
        35.0933229,
        -0.707912,
        35.1333229,
        -0.667912
      ]
    },
    {
      "id": 11174,
      "name": "Paterna",
      "names": {
        "zh": "帕特纳"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.5039227,
        "lng": -0.4421014
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.5186903,
        39.4863688,
        -0.4185376,
        39.5653832
      ]
    },
    {
      "id": 11175,
      "name": "San Vicente del Caguan",
      "names": {
        "zh": "圣维森特德德·德尔奇銮",
        "ja": "サンビセンテデルカガン"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1131213,
        "lng": -74.7697347
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.15494,
        0.5362295,
        -73.2414255,
        2.9331921
      ]
    },
    {
      "id": 11176,
      "name": "Belovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.419323,
        "lng": 86.303429
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        86.2307391,
        54.295581,
        86.379041,
        54.4635021
      ]
    },
    {
      "id": 11177,
      "name": "Xiangjiaba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.63266,
        "lng": 104.40416
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.38416,
        28.61266,
        104.42416,
        28.65266
      ]
    },
    {
      "id": 11178,
      "name": "Schaumburg",
      "names": {
        "zh": "绍姆堡",
        "th": "ชัม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0333608,
        "lng": -88.083406
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.1543639,
        41.9868545,
        -88.028023,
        42.078112
      ]
    },
    {
      "id": 11179,
      "name": "Pocatello",
      "names": {
        "zh": "波卡特洛"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.8620287,
        "lng": -112.450627
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.631862,
        42.802147,
        -112.360259,
        42.931488
      ]
    },
    {
      "id": 11180,
      "name": "Bakixanov",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.4228474,
        "lng": 49.961604
      },
      "country": "Azerbaijan",
      "importance": 3,
      "bbox": [
        49.941604,
        40.4028474,
        49.981604,
        40.4428474
      ]
    },
    {
      "id": 11181,
      "name": "Tres Coracoes",
      "names": {
        "es": "Tres Coraces",
        "fr": "Tres coraces",
        "it": "Coracoi di tres",
        "id": "Corako",
        "ja": "Coracoes tres"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.695625,
        "lng": -45.2543675
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.4181825,
        -21.8664276,
        -45.030324,
        -21.52827
      ]
    },
    {
      "id": 11182,
      "name": "Pacatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.9841,
        "lng": -38.6180383
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.6700567,
        -4.0541566,
        -38.5423215,
        -3.8508596
      ]
    },
    {
      "id": 11183,
      "name": "Crateus",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1782451,
        "lng": -40.6696545
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.1320047,
        -5.4962439,
        -40.3728193,
        -4.9247461
      ]
    },
    {
      "id": 11184,
      "name": "Pavia",
      "names": {
        "zh": "帕维亚",
        "ja": "パヴィア",
        "th": "ปาเวีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.1860043,
        "lng": 9.1546375
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.09038,
        45.1557408,
        9.2405513,
        45.2333058
      ]
    },
    {
      "id": 11185,
      "name": "Woodbury",
      "names": {
        "zh": "伍德伯里",
        "ja": "ウッドベリー",
        "th": "วูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.919896,
        "lng": -92.9339449
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -92.984658,
        44.8619377,
        -92.8624401,
        44.9492586
      ]
    },
    {
      "id": 11186,
      "name": "Ede",
      "names": {
        "zh": "埃德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.07168255,
        "lng": 5.745510631034939
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.5467449,
        51.9909515,
        5.9514658,
        52.1522285
      ]
    },
    {
      "id": 11187,
      "name": "Aracati",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.558259,
        "lng": -37.767894
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.9944324,
        -4.9264046,
        -37.3557179,
        -4.4251552
      ]
    },
    {
      "id": 11188,
      "name": "Southfield",
      "names": {
        "th": "เซาท์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4733689,
        "lng": -83.2218731
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.319938,
        42.442033,
        -83.2005217,
        42.5174709
      ]
    },
    {
      "id": 11189,
      "name": "Keshan",
      "names": {
        "zh": "克山病"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.0330461,
        "lng": 125.8645353
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        125.7045353,
        47.8730461,
        126.0245353,
        48.1930461
      ]
    },
    {
      "id": 11190,
      "name": "Yafran",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.0633249,
        "lng": 12.5271413
      },
      "country": "Libya",
      "importance": 3,
      "bbox": [
        12.4871413,
        32.0233249,
        12.5671413,
        32.1033249
      ]
    },
    {
      "id": 11191,
      "name": "Ellicott City",
      "names": {
        "es": "Ciudad de Ellicott",
        "zh": "艾利科特市",
        "ja": "エリコット市",
        "th": "เมือง Ellicott",
        "pt": "Cidade ellicott"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.275859,
        "lng": -76.835556
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.909815,
        39.224645,
        -76.763664,
        39.327325
      ]
    },
    {
      "id": 11192,
      "name": "Sipalay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7491266,
        "lng": 122.4040602
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.2261812,
        9.5508575,
        122.5891734,
        9.8333446
      ]
    },
    {
      "id": 11193,
      "name": "Dale City",
      "names": {
        "es": "Ciudad de Dale",
        "zh": "戴尔城",
        "de": "Dalestadt",
        "it": "Città di Dale",
        "id": "Kota dale",
        "ja": "デールシティ",
        "th": "เมืองเดล",
        "pt": "Cidade dale"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.646283,
        "lng": -77.34348425252524
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.402553,
        38.611531,
        -77.289736,
        38.681165
      ]
    },
    {
      "id": 11194,
      "name": "Maple Grove",
      "names": {
        "es": "Arboleda",
        "fr": "Érable",
        "zh": "枫树林",
        "de": "Ahornhain",
        "it": "Acero",
        "ja": "メープルグローブ",
        "th": "เมเปิ้ลโกรฟ",
        "pt": "Bordo de bordo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.0984983,
        "lng": -93.4419222
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.5284457,
        45.0647098,
        -93.4007766,
        45.1529061
      ]
    },
    {
      "id": 11195,
      "name": "Penafiel",
      "names": {
        "zh": "德佩尼亚菲耶尔",
        "th": "พีนาฟีล"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2061892,
        "lng": -8.2855653
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.392569,
        41.0401033,
        -8.1689588,
        41.248018
      ]
    },
    {
      "id": 11196,
      "name": "Camalig",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1815738,
        "lng": 123.6552668
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6152668,
        13.1415738,
        123.6952668,
        13.2215738
      ]
    },
    {
      "id": 11197,
      "name": "Pittsburg",
      "names": {
        "zh": "匹兹堡",
        "ja": "ピッツバーグ",
        "th": "พิตส์เบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0181745,
        "lng": -121.8901232
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.9860796,
        37.9829991,
        -121.8332126,
        38.0545269
      ]
    },
    {
      "id": 11198,
      "name": "Pigcawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.279091,
        "lng": 124.424291
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.424007,
        7.278899,
        124.4245779,
        7.2793005
      ]
    },
    {
      "id": 11199,
      "name": "Pililla",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4854,
        "lng": 121.3062
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2862,
        14.4654,
        121.3262,
        14.5054
      ]
    },
    {
      "id": 11200,
      "name": "Concepcion del Uruguay",
      "names": {
        "es": "Concepción del uruguay",
        "ja": "コンセプシオンデルウルグアイ",
        "pt": "Concepcion del Uruguai"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.4851849,
        "lng": -58.2320232
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.4105153,
        -32.5702733,
        -58.1884867,
        -32.3369491
      ]
    },
    {
      "id": 11201,
      "name": "Belo Jardim",
      "names": {
        "ja": "ベロジャルディム"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.3376736,
        "lng": -36.425351
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.430351,
        -8.3426736,
        -36.420351,
        -8.3326736
      ]
    },
    {
      "id": 11202,
      "name": "Morgantown",
      "names": {
        "zh": "摩根镇",
        "ja": "モーガンタウン",
        "th": "มอร์แกนทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6296809,
        "lng": -79.9559437
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.98816,
        39.574307,
        -79.898771,
        39.675097
      ]
    },
    {
      "id": 11203,
      "name": "Estepona",
      "names": {
        "ja": "エステポナ",
        "th": "เอสเตโปนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.4268068,
        "lng": -5.1468484
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.2196719,
        36.3861294,
        -5.0049214,
        36.5357829
      ]
    },
    {
      "id": 11204,
      "name": "Mansfield",
      "names": {
        "zh": "曼斯菲尔德",
        "ja": "マンスフィールド",
        "th": "แมนส์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.563487,
        "lng": -97.1420308
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.1871158,
        32.512091,
        -97.0467793,
        32.6161233
      ]
    },
    {
      "id": 11205,
      "name": "Hammond",
      "names": {
        "zh": "哈蒙德",
        "ja": "ハモンド",
        "th": "แฮมมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5043583,
        "lng": -90.4611995
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.5167356,
        30.4629184,
        -90.3982856,
        30.5462894
      ]
    },
    {
      "id": 11206,
      "name": "Dothan",
      "names": {
        "zh": "多森"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.2237285,
        "lng": -85.3934375
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.5196651,
        31.1262219,
        -85.2940921,
        31.3339659
      ]
    },
    {
      "id": 11207,
      "name": "Harrisonburg",
      "names": {
        "zh": "哈里森堡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.437631,
        "lng": -78.870307
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.918112,
        38.389363,
        -78.824253,
        38.489258
      ]
    },
    {
      "id": 11208,
      "name": "Brandenburg",
      "names": {
        "fr": "Brandebourg",
        "zh": "勃兰登堡",
        "it": "Brandeburgo",
        "ja": "ブランデンブルク",
        "th": "บรันเดนบูร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8455492,
        "lng": 13.2461296
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.2662278,
        51.359064,
        14.7658159,
        53.5590907
      ]
    },
    {
      "id": 11209,
      "name": "Cajamar",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3561111,
        "lng": -46.8769444
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.9419956,
        -23.4306005,
        -46.8196597,
        -23.277862
      ]
    },
    {
      "id": 11210,
      "name": "Wenatchee",
      "names": {
        "zh": "韦纳奇"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.42363,
        "lng": -120.326871
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.367621,
        47.387491,
        -120.29258,
        47.45987
      ]
    },
    {
      "id": 11211,
      "name": "Goya",
      "names": {
        "zh": "戈雅",
        "ja": "ゴヤ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.1444046,
        "lng": -59.2649825
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.6056366,
        -29.6808945,
        -58.7880538,
        -29.0508315
      ]
    },
    {
      "id": 11212,
      "name": "Gumaca",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.921,
        "lng": 122.1002
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.0802,
        13.901,
        122.1202,
        13.941
      ]
    },
    {
      "id": 11213,
      "name": "Waukesha",
      "names": {
        "zh": "沃基肖"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0116784,
        "lng": -88.2314813
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.305059,
        42.951016,
        -88.1737392,
        43.059607
      ]
    },
    {
      "id": 11214,
      "name": "Merignac",
      "names": {
        "fr": "Mérignac",
        "zh": "梅里尼亚克"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.842168,
        "lng": -0.6469022
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        -0.7569779,
        44.7998378,
        -0.6069396,
        44.8627846
      ]
    },
    {
      "id": 11215,
      "name": "Lajeado",
      "names": {
        "zh": "拉热阿杜"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.4671522,
        "lng": -51.9624046
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.1284256,
        -29.4977854,
        -51.9190032,
        -29.4010186
      ]
    },
    {
      "id": 11216,
      "name": "Ishioka",
      "names": {
        "zh": "石冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1910839,
        "lng": 140.2800498
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.2750498,
        36.1860839,
        140.2850498,
        36.1960839
      ]
    },
    {
      "id": 11217,
      "name": "Casa Nova",
      "names": {
        "ja": "カーサノバ",
        "th": "คาซ่าโนวา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.1736775,
        "lng": -40.9734475
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.9031447,
        -9.7701498,
        -40.667,
        -8.7037972
      ]
    },
    {
      "id": 11218,
      "name": "Saint-Nazaire",
      "names": {
        "zh": "圣纳泽尔",
        "ja": "サン・nazaire",
        "th": "เซนต์-nazaire"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2733517,
        "lng": -2.2138905
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.3106603,
        47.2332011,
        -2.1800264,
        47.3205858
      ]
    },
    {
      "id": 11219,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 15.714955150000002,
        "lng": -85.7023583920831
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -86.4583567,
        15.0709781,
        -84.9917661,
        16.3587985
      ]
    },
    {
      "id": 11220,
      "name": "Tibati",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 6.4658575,
        "lng": 12.6251249
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        11.9910508,
        6.0000584,
        13.1574022,
        7.0609047
      ]
    },
    {
      "id": 11221,
      "name": "Cremona",
      "names": {
        "zh": "克雷莫纳",
        "ja": "クレモナ",
        "th": "เครโมนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.1334796,
        "lng": 10.0248716
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.9422949,
        45.0893198,
        10.109841,
        45.1836356
      ]
    },
    {
      "id": 11222,
      "name": "Santo Tirso",
      "names": {
        "ja": "サントタルソ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3440057,
        "lng": -8.4772021
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5198504,
        41.2380705,
        -8.3164497,
        41.3842886
      ]
    },
    {
      "id": 11223,
      "name": "Calauag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9572058,
        "lng": 122.287787
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.0422534,
        13.9040724,
        122.3905104,
        14.3880989
      ]
    },
    {
      "id": 11224,
      "name": "Bouafle",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.99041,
        "lng": -5.7442
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -5.7642,
        6.97041,
        -5.7242,
        7.01041
      ]
    },
    {
      "id": 11225,
      "name": "Kawm Umbu",
      "names": {
        "es": "Umbu kawm"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 24.46667,
        "lng": 32.95
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11226,
      "name": "Mun'gyong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.5939,
        "lng": 128.2014
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 11227,
      "name": "Yukuhashi",
      "names": {
        "zh": "行桥"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7292049,
        "lng": 130.9831626
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.8943382,
        33.671846,
        131.1511778,
        33.7669534
      ]
    },
    {
      "id": 11228,
      "name": "Redmond",
      "names": {
        "zh": "雷德蒙德",
        "it": "Di Redmond",
        "ja": "レドモンド",
        "th": "เรดมันด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6694141,
        "lng": -122.1238767
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.164981,
        47.6271405,
        -122.034617,
        47.717018
      ]
    },
    {
      "id": 11229,
      "name": "Quixeramobim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1983553,
        "lng": -39.2955573
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.6652373,
        -5.5924689,
        -39.0286288,
        -4.7921693
      ]
    },
    {
      "id": 11230,
      "name": "Valenca",
      "names": {
        "es": "Valença",
        "fr": "Valença",
        "zh": "瓦伦萨",
        "pt": "Valença"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2461028,
        "lng": -43.6996917
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.8585195,
        -22.3862556,
        -43.5864565,
        -22.1471829
      ]
    },
    {
      "id": 11231,
      "name": "Binalbagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1940763,
        "lng": 122.8644542
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7039889,
        10.0204524,
        123.1058261,
        10.2516968
      ]
    },
    {
      "id": 11232,
      "name": "Talibon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.11667,
        "lng": 124.28333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.18333,
        10.01667,
        124.38333,
        10.21667
      ]
    },
    {
      "id": 11233,
      "name": "Parang",
      "names": {
        "es": "Machete malayo",
        "zh": "帕朗",
        "ja": "パラン",
        "th": "ฮอกกี้",
        "pt": "Parão"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9138656,
        "lng": 120.9069555
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8669555,
        5.8738656,
        120.9469555,
        5.9538656
      ]
    },
    {
      "id": 11234,
      "name": "Florida",
      "names": {
        "fr": "Floride",
        "zh": "佛罗里达",
        "ja": "フロリダ州",
        "th": "ฟลอริดา",
        "pt": "Flórida"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 27.7567667,
        "lng": -81.4639835
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -87.634896,
        24.396308,
        -79.974306,
        31.000762
      ]
    },
    {
      "id": 11235,
      "name": "Goa",
      "names": {
        "zh": "果阿",
        "ja": "ゴア",
        "th": "กัว"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.6973443,
        "lng": 123.4885073
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4485073,
        13.6573443,
        123.5285073,
        13.7373443
      ]
    },
    {
      "id": 11236,
      "name": "Bocholt",
      "names": {
        "zh": "博霍尔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8340575,
        "lng": 6.6203238
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.6153238,
        51.8290575,
        6.6253238,
        51.8390575
      ]
    },
    {
      "id": 11237,
      "name": "Carpi",
      "names": {
        "zh": "卡尔皮",
        "ja": "カルピ",
        "th": "คาร์"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.7835699,
        "lng": 10.8854523
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        10.8148889,
        44.7134007,
        11.0080881,
        44.891333
      ]
    },
    {
      "id": 11238,
      "name": "Katori",
      "names": {
        "zh": "香取"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8978273,
        "lng": 140.4992787
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.424844,
        35.753467,
        140.651504,
        35.958737
      ]
    },
    {
      "id": 11239,
      "name": "Aschaffenburg",
      "names": {
        "zh": "阿沙芬堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.976929,
        "lng": 9.147466
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.080084,
        49.920614,
        9.237795,
        50.021305
      ]
    },
    {
      "id": 11240,
      "name": "Lisburn",
      "names": {
        "zh": "利斯本"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.5139104,
        "lng": -6.0453218
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -6.0503218,
        54.5089104,
        -6.0403218,
        54.5189104
      ]
    },
    {
      "id": 11241,
      "name": "Quartu Sant'Elena",
      "names": {
        "id": "Kuartu sant'elena"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.22935,
        "lng": 9.25004
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        9.23004,
        39.20935,
        9.27004,
        39.24935
      ]
    },
    {
      "id": 11242,
      "name": "Bunbury",
      "names": {
        "zh": "班伯里"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -33.3267797,
        "lng": 115.636698
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        115.476698,
        -33.4867797,
        115.796698,
        -33.1667797
      ]
    },
    {
      "id": 11243,
      "name": "Sarnia",
      "names": {
        "zh": "萨尼亚"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.9572496,
        "lng": -82.3889155
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -82.3939155,
        42.9522496,
        -82.3839155,
        42.9622496
      ]
    },
    {
      "id": 11244,
      "name": "Wood Buffalo",
      "names": {
        "es": "Búfalo de madera",
        "fr": "Buffalo bois",
        "zh": "木屑",
        "de": "Holzbüffel",
        "it": "Bufalo di legno",
        "id": "Kerbau",
        "ja": "ウッドバッファロー",
        "th": "ควายไม้",
        "pt": "Búfalo de madeira"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 57.6527825,
        "lng": -111.4667
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -114.0002845,
        55.546294,
        -110.0054067,
        59.9996518
      ]
    },
    {
      "id": 11245,
      "name": "Bapatla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.9052608,
        "lng": 80.4680501
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.4280501,
        15.8652608,
        80.5080501,
        15.9452608
      ]
    },
    {
      "id": 11246,
      "name": "Sumter",
      "names": {
        "zh": "萨姆特",
        "ja": "サムター"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9204354,
        "lng": -80.3414693
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.493671,
        33.863312,
        -80.306068,
        33.995403
      ]
    },
    {
      "id": 11247,
      "name": "Redlands",
      "names": {
        "zh": "雷德兰兹",
        "th": "เรดแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0550328,
        "lng": -117.1827445
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2490539,
        34.0039989,
        -117.051457,
        34.101629
      ]
    },
    {
      "id": 11248,
      "name": "Izmail",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.3511307,
        "lng": 28.8361514
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.7946511,
        45.3021905,
        28.9013289,
        45.3942459
      ]
    },
    {
      "id": 11249,
      "name": "Markapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.7406698,
        "lng": 79.2698502
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.2298502,
        15.7006698,
        79.3098502,
        15.7806698
      ]
    },
    {
      "id": 11250,
      "name": "Shujaabad",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.8809972,
        "lng": 71.2931695
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        71.1331695,
        29.7209972,
        71.4531695,
        30.0409972
      ]
    },
    {
      "id": 11251,
      "name": "Daphne",
      "names": {
        "zh": "达芙妮",
        "ja": "ダフネ",
        "th": "ต้นแดฟนิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6035255,
        "lng": -87.9036047
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.9252246,
        30.5673092,
        -87.8056429,
        30.6767681
      ]
    },
    {
      "id": 11252,
      "name": "Orani",
      "names": {
        "zh": "奥拉尼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8012181,
        "lng": 120.5362038
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3819219,
        14.7348698,
        120.5770111,
        14.8234392
      ]
    },
    {
      "id": 11253,
      "name": "Mount Vernon",
      "names": {
        "fr": "Mont Vernon",
        "zh": "弗农山",
        "de": "Büschel",
        "ja": "マウントバーノン",
        "pt": "Monte Vernon"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.4200462,
        "lng": -122.32642
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.357223,
        48.38255,
        -122.2643375,
        48.463689
      ]
    },
    {
      "id": 11254,
      "name": "San Francisco del Rincon",
      "names": {
        "zh": "旧金山德尔林康",
        "ja": "サンフランシスコデルリンコン",
        "th": "ซานฟรานซิสโกเดลรินคอน",
        "pt": "São Francisco del Rincon"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.017563,
        "lng": -101.855403
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.874467,
        20.994629,
        -101.825639,
        21.04549
      ]
    },
    {
      "id": 11255,
      "name": "Villa Tunari",
      "names": {
        "de": "Villa-Tunari"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -16.9728088,
        "lng": -65.4201829
      },
      "country": "Bolivia",
      "importance": 3,
      "bbox": [
        -66.332973,
        -17.3120895,
        -64.7224442,
        -15.7892066
      ]
    },
    {
      "id": 11256,
      "name": "Malaut",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.186752,
        "lng": 74.4955711
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        74.4955211,
        30.186702,
        74.4956211,
        30.186802
      ]
    },
    {
      "id": 11257,
      "name": "Placetas",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.3140717,
        "lng": -79.653392
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.8737811,
        22.1413418,
        -79.480498,
        22.4147747
      ]
    },
    {
      "id": 11258,
      "name": "Verkhnyaya Pyshma",
      "names": {
        "id": "Pyshma verkhnyaya",
        "th": "Verkhnyaya pyshama"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.971807,
        "lng": 60.585066
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.541054,
        56.94978,
        60.626446,
        56.992168
      ]
    },
    {
      "id": 11259,
      "name": "Cabudare",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0327744,
        "lng": -69.2569197
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.2569697,
        10.0327244,
        -69.2568697,
        10.0328244
      ]
    },
    {
      "id": 11260,
      "name": "Yinying",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9408369,
        "lng": 113.5649942
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.5249942,
        37.9008369,
        113.6049942,
        37.9808369
      ]
    },
    {
      "id": 11261,
      "name": "Mauban",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.191,
        "lng": 121.7309
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.7109,
        14.171,
        121.7509,
        14.211
      ]
    },
    {
      "id": 11262,
      "name": "Gibara",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.113845750000003,
        "lng": -76.1332432811102
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -76.1392075,
        21.1005186,
        -76.1246646,
        21.1272175
      ]
    },
    {
      "id": 11263,
      "name": "Erd",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3772495,
        "lng": 18.9213833
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.8338707,
        47.3145272,
        18.9623767,
        47.4302138
      ]
    },
    {
      "id": 11264,
      "name": "Entebbe",
      "names": {
        "zh": "恩德培"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.0611715,
        "lng": 32.4698564
      },
      "country": "Uganda",
      "importance": 3,
      "bbox": [
        32.2119828,
        -0.1530358,
        32.6193644,
        0.2419655
      ]
    },
    {
      "id": 11265,
      "name": "Baiquan",
      "names": {
        "zh": "百泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.6059986,
        "lng": 126.085015
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.925015,
        47.4459986,
        126.245015,
        47.7659986
      ]
    },
    {
      "id": 11266,
      "name": "Weston",
      "names": {
        "zh": "韦斯顿",
        "ja": "ウェストン",
        "th": "เวสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1003392,
        "lng": -80.399513
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.450049,
        26.0617552,
        -80.3621365,
        26.146019
      ]
    },
    {
      "id": 11267,
      "name": "Corozal",
      "names": {
        "zh": "科罗萨尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.3192389,
        "lng": -75.2947262
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4547262,
        9.1592389,
        -75.1347262,
        9.4792389
      ]
    },
    {
      "id": 11268,
      "name": "Bundaberg",
      "names": {
        "zh": "班达伯格"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -24.8653115,
        "lng": 152.348487
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        152.348437,
        -24.8653615,
        152.348537,
        -24.8652615
      ]
    },
    {
      "id": 11269,
      "name": "Djemmal",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 35.62231,
        "lng": 10.75696
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11270,
      "name": "Pan'an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7559088,
        "lng": 105.115326
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.075326,
        34.7159088,
        105.155326,
        34.7959088
      ]
    },
    {
      "id": 11271,
      "name": "St. Charles",
      "names": {
        "zh": "圣查尔斯",
        "ja": "セントチャールズ",
        "th": "เซนต์ชาร์ลส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.783855,
        "lng": -90.481677
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.570679,
        38.744963,
        -90.4596895,
        38.846861
      ]
    },
    {
      "id": 11272,
      "name": "Cherry Hill",
      "names": {
        "es": "Cereza",
        "fr": "Colline de cerise",
        "zh": "樱桃山",
        "de": "Kirschhügel",
        "it": "Ciliegio collina",
        "id": "Bukit ceri",
        "ja": "チェリーヒル",
        "pt": "Colina de cereja"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9348351,
        "lng": -75.0307264
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.066171,
        39.85935,
        -74.926935,
        39.954188
      ]
    },
    {
      "id": 11273,
      "name": "Moriya",
      "names": {
        "zh": "守屋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9510096,
        "lng": 139.9754981
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.931649,
        35.909567,
        140.022426,
        35.978739
      ]
    },
    {
      "id": 11274,
      "name": "Ritto",
      "names": {
        "zh": "栗东"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0257669,
        "lng": 135.9836707
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.958471,
        34.939724,
        136.061239,
        35.050685
      ]
    },
    {
      "id": 11275,
      "name": "Benidorm",
      "names": {
        "zh": "贝尼多姆",
        "ja": "ベニドーム",
        "th": "เบนิดอร์ม"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.5406255,
        "lng": -0.1290929
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.1869109,
        38.5011769,
        -0.066758,
        38.6101333
      ]
    },
    {
      "id": 11276,
      "name": "Hujra Shah Muqim",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.739375,
        "lng": 73.822336
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.811161,
        30.728514,
        73.837222,
        30.749939
      ]
    },
    {
      "id": 11277,
      "name": "Tumauini",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.2740459,
        "lng": 121.809876
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.769876,
        17.2340459,
        121.849876,
        17.3140459
      ]
    },
    {
      "id": 11278,
      "name": "Badvel",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.7433614,
        "lng": 79.0610394
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.0210394,
        14.7033614,
        79.1010394,
        14.7833614
      ]
    },
    {
      "id": 11279,
      "name": "Cacador",
      "names": {
        "zh": "卡萨多尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.7737784,
        "lng": -51.0134844
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.4118233,
        -26.9030676,
        -50.7961035,
        -26.6252641
      ]
    },
    {
      "id": 11280,
      "name": "Kabirwala",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.4033711,
        "lng": 71.8692824
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        71.8292824,
        30.3633711,
        71.9092824,
        30.4433711
      ]
    },
    {
      "id": 11281,
      "name": "Chateauguay",
      "names": {
        "zh": "沙托盖"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.3602416,
        "lng": -73.749364
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.8265105,
        45.3035919,
        -73.6753009,
        45.4217849
      ]
    },
    {
      "id": 11282,
      "name": "Novomoskovsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.63245,
        "lng": 35.200265
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.164215,
        48.598389,
        35.296363,
        48.677583
      ]
    },
    {
      "id": 11283,
      "name": "Rizal",
      "names": {
        "zh": "黎刹"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.68054405,
        "lng": 121.10642287398827
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0267317,
        15.6280816,
        121.2293073,
        15.7403312
      ]
    },
    {
      "id": 11284,
      "name": "Kladno",
      "names": {
        "zh": "克拉德诺"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.1478497,
        "lng": 14.1025379
      },
      "country": "Czechia",
      "importance": 3,
      "bbox": [
        14.0411096,
        50.1165914,
        14.1767235,
        50.1824441
      ]
    },
    {
      "id": 11285,
      "name": "Janesville",
      "names": {
        "zh": "简斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6829765,
        "lng": -89.0226793
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.08051,
        42.6051404,
        -88.938878,
        42.7589666
      ]
    },
    {
      "id": 11286,
      "name": "Zenica",
      "names": {
        "zh": "泽尼察"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.199828,
        "lng": 17.9093012
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.7493012,
        44.039828,
        18.0693012,
        44.359828
      ]
    },
    {
      "id": 11287,
      "name": "Loule",
      "names": {
        "es": "Loulé",
        "fr": "Loulé",
        "pt": "Loulé"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1398973,
        "lng": -8.0233126
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2320079,
        37.010481,
        -7.8782844,
        37.4182583
      ]
    },
    {
      "id": 11288,
      "name": "Stafford",
      "names": {
        "zh": "斯塔福德",
        "ja": "スタッフォード",
        "th": "คัสติญิโอเน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.8063157,
        "lng": -2.1163818
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.4163476,
        52.7150325,
        -1.9333344,
        52.9805622
      ]
    },
    {
      "id": 11289,
      "name": "Sherman",
      "names": {
        "zh": "谢尔曼",
        "ja": "シャーマン",
        "th": "เชอร์แมน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.2452294,
        "lng": -101.8858689
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -102.1629574,
        36.0548791,
        -101.6234759,
        36.5002776
      ]
    },
    {
      "id": 11290,
      "name": "Altamura",
      "names": {
        "zh": "阿尔塔穆拉"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.826679,
        "lng": 16.552546
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        16.516676,
        40.814739,
        16.569071,
        40.840297
      ]
    },
    {
      "id": 11291,
      "name": "North Richland Hills",
      "names": {
        "zh": "北富豪山",
        "it": "Hills del Nord Richland",
        "id": "Bukit Richland Utara",
        "ja": "ノースリッチランドヒルズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8342952,
        "lng": -97.2289029
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.2645211,
        32.8087415,
        -97.185923,
        32.9118121
      ]
    },
    {
      "id": 11292,
      "name": "Chekhov",
      "names": {
        "es": "Chéjov",
        "fr": "Tchekhov",
        "zh": "契诃夫",
        "de": "Tschechow",
        "ja": "チェーホフ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.141263,
        "lng": 37.477635
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.459862,
        55.125832,
        37.49828,
        55.159443
      ]
    },
    {
      "id": 11293,
      "name": "Reconquista",
      "names": {
        "zh": "雷孔基斯塔",
        "ja": "レコンキスタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.1443478,
        "lng": -59.6437728
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.6939528,
        -29.2005884,
        -59.6280146,
        -29.134716
      ]
    },
    {
      "id": 11294,
      "name": "Bur Fu'ad",
      "names": {
        "ja": "バーフアド"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 48.5234989,
        "lng": 32.2551787
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        32.2538091,
        48.5220636,
        32.2560417,
        48.5243559
      ]
    },
    {
      "id": 11295,
      "name": "Sao Sebastiao do Paraiso",
      "names": {
        "zh": "Sao Sebastiao做Paraiso",
        "id": "Sao Sebastiao melakukan paraiso",
        "ja": "サンセバステアオパライソ",
        "th": "Sao Sebastiao ทำ Paraiso",
        "pt": "São Sebastião do Paraiso"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.914179,
        "lng": -46.983045
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0112686166,
        -20.9338640059,
        -46.9427497271,
        -20.8867690928
      ]
    },
    {
      "id": 11296,
      "name": "Caldas Novas",
      "names": {
        "ja": "カルダスノバス",
        "th": "คัลดาสน์โนวา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.745,
        "lng": -48.625
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.9285383,
        -18.0672965,
        -48.4509349,
        -17.4747354
      ]
    },
    {
      "id": 11297,
      "name": "Kovel",
      "names": {
        "zh": "科韦利",
        "th": "โคเวล"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.21526,
        "lng": 24.70867
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.68867,
        51.19526,
        24.72867,
        51.23526
      ]
    },
    {
      "id": 11298,
      "name": "Broomfield",
      "names": {
        "zh": "布鲁姆菲尔德",
        "ja": "ブルームフィールド"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9403995,
        "lng": -105.05208
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -105.1672564,
        39.8891952,
        -104.960925,
        40.044227
      ]
    },
    {
      "id": 11299,
      "name": "Santa Maria La Pila",
      "names": {
        "zh": "圣玛丽亚洛杉矶皮拉",
        "ja": "サンタマリアラピラ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 21.821095,
        "lng": -72.151752
      },
      "country": "Guatemala",
      "importance": 3,
      "bbox": [
        -72.152714,
        21.820588,
        -72.150751,
        21.821485
      ]
    },
    {
      "id": 11300,
      "name": "Tinambac",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8164,
        "lng": 123.3261
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3061,
        13.7964,
        123.3461,
        13.8364
      ]
    },
    {
      "id": 11301,
      "name": "San Pascual",
      "names": {
        "fr": "San pascule",
        "zh": "圣人士",
        "it": "San pascuale",
        "ja": "サンパスカール",
        "th": "ซานพังสว"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7844912,
        "lng": 121.0302229
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9855825,
        13.6944499,
        121.0623783,
        13.8629182
      ]
    },
    {
      "id": 11302,
      "name": "Rosetta",
      "names": {
        "zh": "罗塞塔",
        "ja": "ロゼッタ"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.404444,
        "lng": 30.416389
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.376389,
        31.364444,
        30.456389,
        31.444444
      ]
    },
    {
      "id": 11303,
      "name": "Guzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.9356793,
        "lng": 108.5175229
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.3575229,
        25.7756793,
        108.6775229,
        26.0956793
      ]
    },
    {
      "id": 11304,
      "name": "Eniwa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 42.8827386,
        "lng": 141.5775731
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.235293,
        42.795711,
        141.652667,
        42.987857
      ]
    },
    {
      "id": 11305,
      "name": "Rongcheng",
      "names": {
        "zh": "荣成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.0451873,
        "lng": 115.8572763
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.757854,
        38.9523385,
        116.0690988,
        39.1420853
      ]
    },
    {
      "id": 11306,
      "name": "Zhanggu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8787058,
        "lng": 101.8764696
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.8364696,
        30.8387058,
        101.9164696,
        30.9187058
      ]
    },
    {
      "id": 11307,
      "name": "Imola",
      "names": {
        "zh": "伊莫拉"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.3535145,
        "lng": 11.7141233
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        11.5849797,
        44.2837558,
        11.8109244,
        44.5685492
      ]
    },
    {
      "id": 11308,
      "name": "Smila",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.21208265,
        "lng": 31.889824655794214
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.8371434,
        49.1669624,
        31.9502867,
        49.2603239
      ]
    },
    {
      "id": 11309,
      "name": "El Paso de Robles",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 35.6262618,
        "lng": -120.6901319
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.7076039,
        35.5767154,
        -120.5294537,
        35.6922701
      ]
    },
    {
      "id": 11310,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.5959685,
        "lng": -82.1885009
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.3485009,
        36.4359685,
        -82.0285009,
        36.7559685
      ]
    },
    {
      "id": 11311,
      "name": "Casas Adobes",
      "names": {
        "id": "Adobes Casas",
        "ja": "カーサスアディス",
        "th": "คาซัส adobes"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.340932,
        "lng": -111.010201
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.086748,
        32.291276,
        -110.956687,
        32.395358
      ]
    },
    {
      "id": 11312,
      "name": "Walnut Creek",
      "names": {
        "fr": "Ruisseau walnut",
        "zh": "核桃溪",
        "ja": "クルミクリーク",
        "th": "วอลนุทครีก",
        "pt": "Nogueira riacho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9020731,
        "lng": -122.0618702
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.1001494,
        37.8424681,
        -121.9806066,
        37.9463371
      ]
    },
    {
      "id": 11313,
      "name": "Villa del Rosario",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8318933,
        "lng": -72.4717181
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.5225994,
        7.6433785,
        -72.4446631,
        7.9007161
      ]
    },
    {
      "id": 11314,
      "name": "Jorhat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.7577925,
        "lng": 94.2079645
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        94.0479645,
        26.5977925,
        94.3679645,
        26.9177925
      ]
    },
    {
      "id": 11315,
      "name": "Az Zubaydiyah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.758844,
        "lng": 45.177268
      },
      "country": "Iraq",
      "importance": 3,
      "bbox": [
        45.137268,
        32.718844,
        45.217268,
        32.798844
      ]
    },
    {
      "id": 11316,
      "name": "Phu Quoc",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 10.2162807,
        "lng": 103.9875395
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        103.4504313,
        9.2542964,
        104.0984858,
        10.4525966
      ]
    },
    {
      "id": 11317,
      "name": "Qo`ng`irot Shahri",
      "names": {
        "de": "Qo`ng`nngirot shahri",
        "pt": "Qo`ng`ng`irart shahri"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.40881,
        "lng": 59.44544
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 11318,
      "name": "Oshnaviyeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.0397,
        "lng": 45.0983
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.0783,
        37.0197,
        45.1183,
        37.0597
      ]
    },
    {
      "id": 11319,
      "name": "Fanzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7739137,
        "lng": 114.9632792
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.9232792,
        37.7339137,
        115.0032792,
        37.8139137
      ]
    },
    {
      "id": 11320,
      "name": "Cahama",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -16.28333,
        "lng": 14.31667
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.29667,
        -16.30333,
        14.33667,
        -16.26333
      ]
    },
    {
      "id": 11321,
      "name": "Basavakalyan",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.871446,
        "lng": 76.9517496
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.9117496,
        17.831446,
        76.9917496,
        17.911446
      ]
    },
    {
      "id": 11322,
      "name": "Cianorte",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6632103,
        "lng": -52.6101759
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.6102259,
        -23.6632603,
        -52.6101259,
        -23.6631603
      ]
    },
    {
      "id": 11323,
      "name": "Shangchuankou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.33583,
        "lng": 102.81972
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        102.79972,
        36.31583,
        102.83972,
        36.35583
      ]
    },
    {
      "id": 11324,
      "name": "Celle",
      "names": {
        "zh": "策勒",
        "ja": "ツェレ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.624056,
        "lng": 10.081052
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.9828941,
        52.5627418,
        10.2128957,
        52.7283124
      ]
    },
    {
      "id": 11325,
      "name": "Kempten",
      "names": {
        "zh": "肯普滕"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7267063,
        "lng": 10.3168835
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2326564,
        47.694585,
        10.378058,
        47.7779516
      ]
    },
    {
      "id": 11326,
      "name": "Qingquan",
      "names": {
        "zh": "清泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7823407,
        "lng": 101.0818791
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.0418791,
        38.7423407,
        101.1218791,
        38.8223407
      ]
    },
    {
      "id": 11327,
      "name": "Poinciana",
      "names": {
        "zh": "凤凰木",
        "ja": "ホウオウボク"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.1431314,
        "lng": -81.4700737
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.5100737,
        28.1031314,
        -81.4300737,
        28.1831314
      ]
    },
    {
      "id": 11328,
      "name": "Garzon",
      "names": {
        "es": "Garzón",
        "zh": "加尔松"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.19593,
        "lng": -75.62777
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.64777,
        2.17593,
        -75.60777,
        2.21593
      ]
    },
    {
      "id": 11329,
      "name": "Isna",
      "names": {
        "zh": "伊斯纳"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.29336,
        "lng": 32.55402
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        32.53402,
        25.27336,
        32.57402,
        25.31336
      ]
    },
    {
      "id": 11330,
      "name": "Sanlucar de Barrameda",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7761393,
        "lng": -6.3534794
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.396634,
        36.7026526,
        -6.2036815,
        36.9051867
      ]
    },
    {
      "id": 11331,
      "name": "Minami-Alps",
      "names": {
        "es": "Minami-Alpes",
        "fr": "Minami-Alpes",
        "zh": "南-阿尔卑斯山",
        "de": "Minami-Alpen",
        "it": "Minami-Alpi",
        "ja": "南・アルプス",
        "th": "มินามิ-เทือกเขาแอลป์",
        "pt": "Minami-Alpes"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6083617,
        "lng": 138.4649893
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.180087,
        35.563797,
        138.510767,
        35.753699
      ]
    },
    {
      "id": 11332,
      "name": "Cataguases",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.387426,
        "lng": -42.6957219
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.802,
        -21.461,
        -42.503,
        -21.204
      ]
    },
    {
      "id": 11333,
      "name": "Tournai",
      "names": {
        "zh": "图尔奈",
        "ja": "トゥルネー"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6056458,
        "lng": 3.3878179
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.2440216,
        50.5524719,
        3.5768853,
        50.6887794
      ]
    },
    {
      "id": 11334,
      "name": "Russas",
      "names": {
        "zh": "鲁萨斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.935484,
        "lng": -37.977681
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.003556,
        -4.960419,
        -37.944497,
        -4.913487
      ]
    },
    {
      "id": 11335,
      "name": "Glen Burnie",
      "names": {
        "zh": "格伦伯恩",
        "it": "Burie di Glen",
        "ja": "グレンバーニー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1595439,
        "lng": -76.61515136556432
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.6454862,
        39.1109723,
        -76.5631855,
        39.2079353
      ]
    },
    {
      "id": 11336,
      "name": "Mansehra",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.3286855,
        "lng": 73.1993125
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.1688331,
        34.2981531,
        73.2357905,
        34.3732443
      ]
    },
    {
      "id": 11337,
      "name": "San Juan de los Lagos",
      "names": {
        "zh": "圣胡安de los拉各斯",
        "ja": "サンファンデロスラゴス",
        "th": "ซานฮวนเดอลอสลากอส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2451843,
        "lng": -102.3348587
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.3748587,
        21.2051843,
        -102.2948587,
        21.2851843
      ]
    },
    {
      "id": 11338,
      "name": "Solok",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.7871465,
        "lng": 100.6552932
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        100.6502932,
        -0.7921465,
        100.6602932,
        -0.7821465
      ]
    },
    {
      "id": 11339,
      "name": "Uwajima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2232315,
        "lng": 132.5606514
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.1205929,
        33.0202164,
        132.673419,
        33.337791
      ]
    },
    {
      "id": 11340,
      "name": "Massa",
      "names": {
        "zh": "马萨",
        "ja": "マッサ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.0359323,
        "lng": 10.1395519
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.0597856,
        43.9801299,
        10.2429649,
        44.1218419
      ]
    },
    {
      "id": 11341,
      "name": "Suwalki",
      "names": {
        "zh": "苏瓦乌基"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0990636,
        "lng": 22.9279363
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.8637582,
        54.0404163,
        22.9946708,
        54.1650175
      ]
    },
    {
      "id": 11342,
      "name": "Colmar",
      "names": {
        "zh": "科尔马",
        "ja": "コルマール",
        "th": "โคลมาร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0777517,
        "lng": 7.3579641
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.3154803,
        48.0407053,
        7.469167,
        48.182062
      ]
    },
    {
      "id": 11343,
      "name": "Lehi",
      "names": {
        "th": "เลหิ",
        "pt": "Leí"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.419084,
        "lng": -111.896434
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.945446,
        40.354988,
        -111.818818,
        40.471736
      ]
    },
    {
      "id": 11344,
      "name": "Yitiaoshan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1810377,
        "lng": 104.0599948
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.8999948,
        37.0210377,
        104.2199948,
        37.3410377
      ]
    },
    {
      "id": 11345,
      "name": "Roxas",
      "names": {
        "zh": "罗哈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3196268,
        "lng": 119.3430042
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        118.8416615,
        9.8927501,
        120.4685963,
        10.8543141
      ]
    },
    {
      "id": 11346,
      "name": "Indang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1958306,
        "lng": 120.8784148
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8169037,
        14.1211487,
        120.9299649,
        14.2464276
      ]
    },
    {
      "id": 11347,
      "name": "Paragominas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.993658,
        "lng": -47.356839
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.37278,
        -3.01583,
        -47.33278,
        -2.97583
      ]
    },
    {
      "id": 11348,
      "name": "Gniezno",
      "names": {
        "zh": "格涅兹诺",
        "ja": "グニェズノ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.532689,
        "lng": 17.599834
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.547735,
        52.483004,
        17.658837,
        52.56677
      ]
    },
    {
      "id": 11349,
      "name": "Macaiba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.8561372,
        "lng": -35.3523774
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.572,
        -6.0540398,
        -35.2809884,
        -5.8294725
      ]
    },
    {
      "id": 11350,
      "name": "Saint-Jerome",
      "names": {
        "fr": "Saint-Jérôme",
        "zh": "圣 - 杰罗姆",
        "ja": "サン・ジェローム",
        "th": "เซนต์เจอโรม"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.7780402,
        "lng": -74.0033272
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -74.143879,
        45.7433599,
        -73.9476576,
        45.850396
      ]
    },
    {
      "id": 11351,
      "name": "Don Carlos",
      "names": {
        "zh": "唐卡洛斯",
        "ja": "ドンカルロス",
        "th": "ดอนคาร์ลอส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6803647,
        "lng": 124.9990723
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8178303,
        7.6283746,
        125.0547177,
        7.7748141
      ]
    },
    {
      "id": 11352,
      "name": "Samch'ok",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4406,
        "lng": 129.1708
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 11353,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 33.7923828,
        "lng": -114.0184443
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -114.7314741,
        33.0254058,
        -113.3319554,
        34.31926
      ]
    },
    {
      "id": 11354,
      "name": "Bom Jesus da Lapa",
      "names": {
        "zh": "BOM耶稣达拉帕",
        "it": "BOM GESÙ DA LAPA",
        "th": "Bom พระเยซูดาลาปา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -13.2498754,
        "lng": -43.4101019
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.7059866,
        -13.8759828,
        -42.9093589,
        -12.6890762
      ]
    },
    {
      "id": 11355,
      "name": "Cawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.9283971,
        "lng": 123.7692131
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7292131,
        11.8883971,
        123.8092131,
        11.9683971
      ]
    },
    {
      "id": 11356,
      "name": "Homestead",
      "names": {
        "zh": "宅基地",
        "ja": "ホームステッド",
        "th": "ไร่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.4718946,
        "lng": -80.4759905
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.5079416,
        25.4405595,
        -80.4037655,
        25.4996034
      ]
    },
    {
      "id": 11357,
      "name": "Anan",
      "names": {
        "zh": "阿南",
        "ja": "アナン",
        "th": "อนันต์"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9220102,
        "lng": 134.6596974
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.467165,
        33.4514349,
        134.9868537,
        34.0472717
      ]
    },
    {
      "id": 11358,
      "name": "West Bend",
      "names": {
        "fr": "Bend de West",
        "zh": "西弯",
        "de": "Westniede",
        "id": "Tikungan barat",
        "ja": "ウェストベンド",
        "th": "เวสต์โค้ง",
        "pt": "Curva Ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4252776,
        "lng": -88.1834277
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.2465094,
        43.370285,
        -88.110128,
        43.45555
      ]
    },
    {
      "id": 11359,
      "name": "Sabae",
      "names": {
        "zh": "鲭江"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9565096,
        "lng": 136.1843593
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.124088,
        35.926179,
        136.336487,
        36.001381
      ]
    },
    {
      "id": 11360,
      "name": "Delray Beach",
      "names": {
        "es": "Playa de della",
        "fr": "Plage de Delray",
        "zh": "德国海滩",
        "de": "Delay-Strand",
        "id": "Pantai Delray",
        "ja": "デルレイビーチ",
        "th": "เดลเรย์บีช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.45403225,
        "lng": -80.09097075
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.0911784,
        26.453351,
        -80.0907631,
        26.4547135
      ]
    },
    {
      "id": 11361,
      "name": "Villanueva y Geltru",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.2241992,
        "lng": 1.7256327
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        1.6681779,
        41.2017248,
        1.751551,
        41.2716922
      ]
    },
    {
      "id": 11362,
      "name": "Ongjang",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 37.96833,
        "lng": 125.38944
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        125.36944,
        37.94833,
        125.40944,
        37.98833
      ]
    },
    {
      "id": 11363,
      "name": "Velsen-Zuid",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4626581,
        "lng": 4.6323097
      },
      "country": "Netherlands",
      "importance": 3,
      "bbox": [
        4.6322597,
        52.4626081,
        4.6323597,
        52.4627081
      ]
    },
    {
      "id": 11364,
      "name": "Sheboygan",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 43.7508284,
        "lng": -87.71453
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.765425,
        43.691237,
        -87.695532,
        43.797622
      ]
    },
    {
      "id": 11365,
      "name": "Chisec",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.813015,
        "lng": -90.2896971
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.3296971,
        15.773015,
        -90.2496971,
        15.853015
      ]
    },
    {
      "id": 11366,
      "name": "Aqsu",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 52.0354244,
        "lng": 76.9341133
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        75.5536152,
        51.4969627,
        77.3445077,
        52.6579411
      ]
    },
    {
      "id": 11367,
      "name": "Planeta Rica",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4076739,
        "lng": -75.5840456
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6240456,
        8.3676739,
        -75.5440456,
        8.4476739
      ]
    },
    {
      "id": 11368,
      "name": "Yanghe",
      "names": {
        "zh": "洋河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9345015,
        "lng": 107.746585
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.706585,
        32.8945015,
        107.786585,
        32.9745015
      ]
    },
    {
      "id": 11369,
      "name": "Korba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.5728862,
        "lng": 10.857624
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.8352663,
        36.5577587,
        10.8733434,
        36.5983043
      ]
    },
    {
      "id": 11370,
      "name": "Bafia",
      "names": {
        "zh": "巴菲亚"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.7399331,
        "lng": 11.2205928
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        11.117386,
        4.6663378,
        11.3277697,
        4.8117934
      ]
    },
    {
      "id": 11371,
      "name": "Ko Samui",
      "names": {
        "id": "Koamui",
        "ja": "桜のサムイ",
        "th": "เกาะสมุย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.5071545,
        "lng": 99.9957562
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8357562,
        9.3471545,
        100.1557562,
        9.6671545
      ]
    },
    {
      "id": 11372,
      "name": "Brumado",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.2950215,
        "lng": -41.659239732941316
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.9094833,
        -14.5091999,
        -41.4430127,
        -14.0804117
      ]
    },
    {
      "id": 11373,
      "name": "Lake Elsinore",
      "names": {
        "es": "Lago Elsinore",
        "fr": "Lac Elsinore",
        "zh": "埃尔斯林湖",
        "de": "See Elsinore",
        "it": "Lago Elsinore",
        "id": "Danau Elsinore",
        "ja": "エルシノア湖",
        "th": "ทะเลสาบเอลซีน",
        "pt": "Lago Elsinore"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6680772,
        "lng": -117.3272615
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.4200034,
        33.6184816,
        -117.2167828,
        33.7567838
      ]
    },
    {
      "id": 11374,
      "name": "Liuhe",
      "names": {
        "zh": "六合"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2779439,
        "lng": 125.7349239
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.5749239,
        42.1179439,
        125.8949239,
        42.4379439
      ]
    },
    {
      "id": 11375,
      "name": "Kamsar",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 10.66667,
        "lng": -14.6
      },
      "country": "Guinea",
      "importance": 3,
      "bbox": [
        -14.62,
        10.64667,
        -14.58,
        10.68667
      ]
    },
    {
      "id": 11376,
      "name": "Fulda",
      "names": {
        "zh": "富尔达",
        "ja": "フルダ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5542328,
        "lng": 9.6770448
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.5661411,
        50.4995434,
        9.7329825,
        50.6294052
      ]
    },
    {
      "id": 11377,
      "name": "Mamungan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.11722,
        "lng": 124.21861
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.19861,
        8.09722,
        124.23861,
        8.13722
      ]
    },
    {
      "id": 11378,
      "name": "Huishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6920189,
        "lng": 105.0559042
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.9793253,
        35.5753054,
        105.1554737,
        35.7503342
      ]
    },
    {
      "id": 11379,
      "name": "Daytona Beach",
      "names": {
        "es": "Playa Daytona",
        "fr": "Plage de Daytona",
        "zh": "代托纳海滩",
        "id": "Pantai Daytona",
        "ja": "デイトナビーチ",
        "th": "เดย์โทนาบีช",
        "pt": "Praia Daytona"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.2108147,
        "lng": -81.0228331
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.2115407,
        29.1288338,
        -80.9830946,
        29.2687678
      ]
    },
    {
      "id": 11380,
      "name": "Necocli",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4242821,
        "lng": -76.784019
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.9380991,
        8.2620142,
        -76.4186999,
        8.7211846
      ]
    },
    {
      "id": 11381,
      "name": "Mocuba",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.839215,
        "lng": 36.985642
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        36.945642,
        -16.879215,
        37.025642,
        -16.799215
      ]
    },
    {
      "id": 11382,
      "name": "Minusinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.7004076,
        "lng": 91.6848003
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        91.6201355,
        53.6652564,
        91.7788323,
        53.76797
      ]
    },
    {
      "id": 11383,
      "name": "Aparri",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.3566,
        "lng": 121.6406
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6206,
        18.3366,
        121.6606,
        18.3766
      ]
    },
    {
      "id": 11384,
      "name": "Cosenza",
      "names": {
        "zh": "科森扎",
        "ja": "コゼンツァ",
        "th": "โคเซนซา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.3099931,
        "lng": 16.2501929
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        16.2186531,
        39.2307063,
        16.324287,
        39.3336225
      ]
    },
    {
      "id": 11385,
      "name": "Tanabe",
      "names": {
        "zh": "田边",
        "ja": "田辺"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7278991,
        "lng": 135.3777917
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.2350234,
        33.644254,
        135.830477,
        34.068486
      ]
    },
    {
      "id": 11386,
      "name": "Bagumbayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.5334125,
        "lng": 124.5637805
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5237805,
        6.4934125,
        124.6037805,
        6.5734125
      ]
    },
    {
      "id": 11387,
      "name": "Oliveira de Azemeis",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.84015785,
        "lng": -8.481839976294033
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4818717,
        40.8399665,
        -8.4818083,
        40.8403491
      ]
    },
    {
      "id": 11388,
      "name": "Buenavista",
      "names": {
        "zh": "布埃纳维斯塔",
        "ja": "ブエナビスタ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0808818,
        "lng": 124.1117182
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.0717182,
        10.0408818,
        124.1517182,
        10.1208818
      ]
    },
    {
      "id": 11389,
      "name": "Purisima de Bustos",
      "names": {
        "it": "Purisima de Busto",
        "ja": "プリシマデブストス",
        "th": "Puriisima de Bustos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0316892,
        "lng": -101.878116
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.918116,
        20.9916892,
        -101.838116,
        21.0716892
      ]
    },
    {
      "id": 11390,
      "name": "Los Lunas",
      "names": {
        "zh": "洛斯伦纳斯",
        "ja": "ロスルナ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.814959900000005,
        "lng": -106.76153816053446
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -106.7615679,
        34.8149328,
        -106.7615085,
        34.8149815
      ]
    },
    {
      "id": 11391,
      "name": "Mocuba",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.839215,
        "lng": 36.985642
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        36.945642,
        -16.879215,
        37.025642,
        -16.799215
      ]
    },
    {
      "id": 11392,
      "name": "Arcoverde",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.419871,
        "lng": -37.048861
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.0490355,
        -8.4199526,
        -37.0486865,
        -8.4197894
      ]
    },
    {
      "id": 11393,
      "name": "San Leonardo",
      "names": {
        "zh": "圣莱昂纳多",
        "ja": "サンレオナルド",
        "th": "ซานลีโอนาโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.361846,
        "lng": 120.9580253
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9074163,
        15.3075368,
        120.9885413,
        15.404638
      ]
    },
    {
      "id": 11394,
      "name": "Stargard Szczecinski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.3379935,
        "lng": 15.0350493
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.9385024,
        53.2553794,
        15.0847382,
        53.362684
      ]
    },
    {
      "id": 11395,
      "name": "Wagga Wagga",
      "names": {
        "ja": "ワグガワグガ"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -35.12031945,
        "lng": 147.36854830627036
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        147.3682875,
        -35.1204,
        147.3688091,
        -35.1202389
      ]
    },
    {
      "id": 11396,
      "name": "Tiangua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.733961,
        "lng": -40.998573
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.02406,
        -3.771976,
        -40.974722,
        -3.698489
      ]
    },
    {
      "id": 11397,
      "name": "Say'un",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.9440811,
        "lng": 48.7925786
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        48.6325786,
        15.7840811,
        48.9525786,
        16.1040811
      ]
    },
    {
      "id": 11398,
      "name": "Dinslaken",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.567365,
        "lng": 6.7382029
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.7332029,
        51.562365,
        6.7432029,
        51.572365
      ]
    },
    {
      "id": 11399,
      "name": "Chulucanas",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -5.0965065,
        "lng": -80.1609229
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -80.3650176,
        -5.3303535,
        -79.9779785,
        -4.9278301
      ]
    },
    {
      "id": 11400,
      "name": "Sousa",
      "names": {
        "zh": "索萨"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.7662029,
        "lng": -38.2319607
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.3592797,
        -6.9100535,
        -38.0900913,
        -6.5941939
      ]
    },
    {
      "id": 11401,
      "name": "Al Ahmadi",
      "names": {
        "ja": "アル・アフマディ",
        "th": "อัลอามาดิ"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.0890687,
        "lng": 48.0610236
      },
      "country": "Kuwait",
      "importance": 3,
      "bbox": [
        47.9010236,
        28.9290687,
        48.2210236,
        29.2490687
      ]
    },
    {
      "id": 11402,
      "name": "Glogow",
      "names": {
        "zh": "格沃古夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.659976,
        "lng": 16.079386
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.934742,
        51.632066,
        16.152271,
        51.708305
      ]
    },
    {
      "id": 11403,
      "name": "Wum",
      "names": {
        "zh": "武姆"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 6.38333,
        "lng": 10.06667
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        10.04667,
        6.36333,
        10.08667,
        6.40333
      ]
    },
    {
      "id": 11404,
      "name": "Lima",
      "names": {
        "zh": "利马",
        "ja": "リマ",
        "th": "ลิมา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7399785,
        "lng": -84.105006
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.1650988,
        40.687659,
        -84.0708798,
        40.7956561
      ]
    },
    {
      "id": 11405,
      "name": "Mococa",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.464731,
        "lng": -47.002405
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2481886,
        -21.6339667,
        -46.773,
        -21.2635554
      ]
    },
    {
      "id": 11406,
      "name": "Soja",
      "names": {
        "zh": "大豆"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6728162,
        "lng": 133.7466763
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.581816,
        34.622298,
        133.8049414,
        34.7816
      ]
    },
    {
      "id": 11407,
      "name": "Decatur",
      "names": {
        "zh": "迪凯特",
        "ja": "ディケーター",
        "th": "ดีเคเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6060203,
        "lng": -86.9838165
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.1055103,
        34.4862989,
        -86.8972929,
        34.6708687
      ]
    },
    {
      "id": 11408,
      "name": "Aalen",
      "names": {
        "zh": "阿伦",
        "ja": "アーレン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8362705,
        "lng": 10.0931765
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.971357,
        48.7481716,
        10.2779161,
        48.8987785
      ]
    },
    {
      "id": 11409,
      "name": "Rocklin",
      "names": {
        "th": "ร็อกลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7911233,
        "lng": -121.2376117
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.2376617,
        38.7910733,
        -121.2375617,
        38.7911733
      ]
    },
    {
      "id": 11410,
      "name": "Tangub",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0618,
        "lng": 123.7477
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7277,
        8.0418,
        123.7677,
        8.0818
      ]
    },
    {
      "id": 11411,
      "name": "Bay",
      "names": {
        "es": "Bahía",
        "fr": "Baie",
        "zh": "海湾",
        "de": "Küste",
        "ja": "ベイ",
        "th": "อ่าว",
        "pt": "Baía"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 2.9999946,
        "lng": 43.500177
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        42.4000015,
        1.46,
        44.6899986,
        3.9200001
      ]
    },
    {
      "id": 11412,
      "name": "Alamada",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3797893,
        "lng": 124.5565375
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5165375,
        7.3397893,
        124.5965375,
        7.4197893
      ]
    },
    {
      "id": 11413,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.8643546,
        "lng": -54.4779287
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.6855091,
        -27.9843875,
        -54.357,
        -27.696605
      ]
    },
    {
      "id": 11414,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -31.3972539,
        "lng": -58.0173305
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -58.0568691,
        -31.4188807,
        -57.9832783,
        -31.3356591
      ]
    },
    {
      "id": 11415,
      "name": "Cannock",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 52.688227,
        "lng": -2.0324197
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.0724197,
        52.648227,
        -1.9924197,
        52.728227
      ]
    },
    {
      "id": 11416,
      "name": "Beypore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.1790668,
        "lng": 75.8101768
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.7701768,
        11.1390668,
        75.8501768,
        11.2190668
      ]
    },
    {
      "id": 11417,
      "name": "Rogers",
      "names": {
        "zh": "罗杰斯",
        "ja": "ロジャーズ",
        "th": "โรเจอร์ส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3330154,
        "lng": -94.1166283
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.2507421,
        36.2627878,
        -94.0806236,
        36.4061009
      ]
    },
    {
      "id": 11418,
      "name": "Odate",
      "names": {
        "id": "Noshiro"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.2716953,
        "lng": 140.5652404
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.332674,
        40.04838,
        140.744189,
        40.487107
      ]
    },
    {
      "id": 11419,
      "name": "Zapotlanejo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6214366,
        "lng": -103.0668511
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.1068511,
        20.5814366,
        -103.0268511,
        20.6614366
      ]
    },
    {
      "id": 11420,
      "name": "Doboj",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.724642,
        "lng": 18.09670852292718
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.0964632,
        44.724239,
        18.0970476,
        44.7251149
      ]
    },
    {
      "id": 11421,
      "name": "Castillejos",
      "names": {
        "zh": "卡斯蒂列霍斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9320671,
        "lng": 120.2005402
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.1605402,
        14.8920671,
        120.2405402,
        14.9720671
      ]
    },
    {
      "id": 11422,
      "name": "Drummondville",
      "names": {},
      "countryCode": "CA",
      "latLng": {
        "lat": 45.8821118,
        "lng": -72.4883923
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.4933923,
        45.8771118,
        -72.4833923,
        45.8871118
      ]
    },
    {
      "id": 11423,
      "name": "Miagao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6410895,
        "lng": 122.2355455
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.0992005,
        10.4990293,
        122.3189128,
        10.761033
      ]
    },
    {
      "id": 11424,
      "name": "Otukpo",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.1912599,
        "lng": 8.1328576
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.8604507,
        7.0125799,
        8.2747631,
        7.6446705
      ]
    },
    {
      "id": 11425,
      "name": "Lippstadt",
      "names": {
        "zh": "利普施塔特",
        "th": "ลิพพ์ชตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.67062665,
        "lng": 8.348088598357709
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        8.3464405,
        51.6705317,
        8.3501733,
        51.6707237
      ]
    },
    {
      "id": 11426,
      "name": "Castle Rock",
      "names": {
        "es": "Roca del castillo",
        "fr": "Château",
        "zh": "城堡摇滚",
        "de": "Schlossfelsen",
        "it": "Castello rock",
        "id": "Benteng",
        "ja": "キャッスルロック",
        "th": "หินปราสาท",
        "pt": "Castelo rock"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3722552,
        "lng": -104.858682
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.9198012,
        39.3112713,
        -104.7612957,
        39.4349503
      ]
    },
    {
      "id": 11427,
      "name": "Tuburan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7285353,
        "lng": 123.8254215
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7854215,
        10.6885353,
        123.8654215,
        10.7685353
      ]
    },
    {
      "id": 11428,
      "name": "Sipocot",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.766636,
        "lng": 122.9785788
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9385788,
        13.726636,
        123.0185788,
        13.806636
      ]
    },
    {
      "id": 11429,
      "name": "Cuyapo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.78194,
        "lng": 120.66472
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.64472,
        15.76194,
        120.68472,
        15.80194
      ]
    },
    {
      "id": 11430,
      "name": "Lala Musa",
      "names": {
        "zh": "拉拉穆萨",
        "ja": "ララマサ"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.699529,
        "lng": 73.9576582
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.9332248,
        32.6889605,
        73.9734096,
        32.7192405
      ]
    },
    {
      "id": 11431,
      "name": "Ash Shihr",
      "names": {
        "es": "Ceniza shihr",
        "id": "Abu shihr",
        "ja": "灰Shihr",
        "th": "เถ้า shihr",
        "pt": "Cinza shihr"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.76026,
        "lng": 49.60537
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        49.58537,
        14.74026,
        49.62537,
        14.78026
      ]
    },
    {
      "id": 11432,
      "name": "Ixtlahuacan de los Membrillos",
      "names": {
        "fr": "Membrillos iXTLAHUACAN DE LOS",
        "it": "Ixtlahuacan de los membllos",
        "id": "Ixlahuacan de los membrillos",
        "ja": "Ixtlahuacan de losの膜"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3489865,
        "lng": -103.1924742
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.2324742,
        20.3089865,
        -103.1524742,
        20.3889865
      ]
    },
    {
      "id": 11433,
      "name": "Tuymazy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.602188,
        "lng": 53.708112
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        53.673832,
        54.582443,
        53.76511,
        54.619999
      ]
    },
    {
      "id": 11434,
      "name": "Chengjiao Chengguanzhen",
      "names": {
        "zh": "成娇成庄镇",
        "ja": "成都Chengguanzhen"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.43583,
        "lng": 104.03056
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.01056,
        34.41583,
        104.05056,
        34.45583
      ]
    },
    {
      "id": 11435,
      "name": "Turkmenbasy",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 40.02216,
        "lng": 52.95518
      },
      "country": "Turkmenistan",
      "importance": 3,
      "bbox": [
        52.93518,
        40.00216,
        52.97518,
        40.04216
      ]
    },
    {
      "id": 11436,
      "name": "Bishnupur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0757335,
        "lng": 87.3146343
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.3011206,
        23.050604,
        87.3340503,
        23.0910695
      ]
    },
    {
      "id": 11437,
      "name": "Lodja",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -3.5241628,
        "lng": 23.5995098
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        23.4395098,
        -3.6841628,
        23.7595098,
        -3.3641628
      ]
    },
    {
      "id": 11438,
      "name": "Fernandopolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.2825647,
        "lng": -50.2500762
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.388,
        -20.4777693,
        -50.145,
        -20.088
      ]
    },
    {
      "id": 11439,
      "name": "Ceara-Mirim",
      "names": {
        "zh": "塞阿拉-米林",
        "th": "เซอารา-mirim",
        "pt": "Ceará-Mirim"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.63444,
        "lng": -35.42556
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.44556,
        -5.65444,
        -35.40556,
        -5.61444
      ]
    },
    {
      "id": 11440,
      "name": "Conceicao do Coite",
      "names": {
        "es": "Conceicao do coito",
        "fr": "Conceicao faire",
        "zh": "康西岛做糖果",
        "de": "Concecao do coite",
        "it": "Conceicao do Coii",
        "id": "Conceicao melakukan coite",
        "ja": "コンセコオ石鉱",
        "th": "conceicao ทำ coite"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.5629384,
        "lng": -39.2839543
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.4864087,
        -11.7638826,
        -39.1208477,
        -11.2690248
      ]
    },
    {
      "id": 11441,
      "name": "Xinhua",
      "names": {
        "zh": "新华社",
        "ja": "新華",
        "th": "สำนักข่าวซินหัว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7296662,
        "lng": 111.3219045
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.1619045,
        27.5696662,
        111.4819045,
        27.8896662
      ]
    },
    {
      "id": 11442,
      "name": "Hanover",
      "names": {
        "fr": "Hanovre",
        "zh": "汉诺威",
        "de": "Hannover",
        "it": "Hannover",
        "ja": "ハノーバー",
        "th": "ฮันโนเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8005316,
        "lng": -76.9832568
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.9996782,
        39.791146,
        -76.9630516,
        39.831769
      ]
    },
    {
      "id": 11443,
      "name": "Bossier City",
      "names": {
        "es": "Ciudad de Bossier",
        "fr": "City Bossier",
        "zh": "Bossier城市",
        "de": "Bühnerstadt",
        "it": "Città di Bossier",
        "ja": "ボージーシティ",
        "th": "เมืองโบเซอร์",
        "pt": "Cidade Bossier"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.515887,
        "lng": -93.7336536
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.7492794,
        32.4197922,
        -93.5843314,
        32.5908092
      ]
    },
    {
      "id": 11444,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9999235,
        "lng": 120.4051274
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3651274,
        15.9599235,
        120.4451274,
        16.0399235
      ]
    },
    {
      "id": 11445,
      "name": "Dubuque",
      "names": {
        "zh": "迪比克",
        "ja": "ドゥビューク",
        "th": "บิวก์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5006243,
        "lng": -90.6647985
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.8240079,
        42.4092568,
        -90.636727,
        42.5579044
      ]
    },
    {
      "id": 11446,
      "name": "La Barca",
      "names": {
        "ja": "ラ・バルカ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2860699,
        "lng": -102.5459147
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.5859147,
        20.2460699,
        -102.5059147,
        20.3260699
      ]
    },
    {
      "id": 11447,
      "name": "Przemysl",
      "names": {
        "zh": "普热梅希尔"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.784234,
        "lng": 22.775079
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.713051,
        49.746351,
        22.855421,
        49.820646
      ]
    },
    {
      "id": 11448,
      "name": "Rockville",
      "names": {
        "zh": "罗克维尔",
        "ja": "ロックビル",
        "th": "ร็อกวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0817985,
        "lng": -77.1516844
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.200152,
        39.0532865,
        -77.1078112,
        39.1205547
      ]
    },
    {
      "id": 11449,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.4283182,
        "lng": -123.364953
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.398075,
        48.4028109,
        -123.3224223,
        48.4504983
      ]
    },
    {
      "id": 11450,
      "name": "Wanzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5768384,
        "lng": 116.5870026
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.4986982,
        39.5244599,
        116.6524913,
        39.6237679
      ]
    },
    {
      "id": 11451,
      "name": "Saratoga Springs",
      "names": {
        "it": "Sartatoga Springs",
        "ja": "サラトガスプリングス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0821793,
        "lng": -73.7853915
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.848663,
        43.015563,
        -73.694069,
        43.108491
      ]
    },
    {
      "id": 11452,
      "name": "Zanhuang",
      "names": {
        "zh": "赞皇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.659722,
        "lng": 114.383333
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.223333,
        37.499722,
        114.543333,
        37.819722
      ]
    },
    {
      "id": 11453,
      "name": "Kstovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.149551,
        "lng": 44.198032
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        44.14199,
        56.1262402,
        44.228095,
        56.2007043
      ]
    },
    {
      "id": 11454,
      "name": "Viterbo",
      "names": {
        "zh": "维泰博",
        "ja": "ヴィテルボ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.4168441,
        "lng": 12.1051148
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.9045499,
        42.2981485,
        12.2248385,
        42.5882274
      ]
    },
    {
      "id": 11455,
      "name": "Tzaneen",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -23.8319444,
        "lng": 30.1611111
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        30.1211111,
        -23.8719444,
        30.2011111,
        -23.7919444
      ]
    },
    {
      "id": 11456,
      "name": "Trapani",
      "names": {
        "zh": "特拉帕尼",
        "ja": "トラパニ",
        "th": "ตราปานี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.0173961,
        "lng": 12.5160225
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.4384934,
        37.8368058,
        12.800851,
        38.0434892
      ]
    },
    {
      "id": 11457,
      "name": "Ames",
      "names": {
        "zh": "艾姆斯",
        "ja": "エームズ",
        "th": "อาเมส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0267567,
        "lng": -93.6170448
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.6987324,
        41.9749305,
        -93.5214332,
        42.0779466
      ]
    },
    {
      "id": 11458,
      "name": "West Des Moines",
      "names": {
        "fr": "Ouest des Moines",
        "zh": "西德梅因",
        "id": "Des moines",
        "ja": "ウェストデモイン",
        "th": "เวสต์เดสมอนส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5644476,
        "lng": -93.7594059
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.8868692,
        41.4892666,
        -93.6982642,
        41.60046
      ]
    },
    {
      "id": 11459,
      "name": "Longshan",
      "names": {
        "zh": "龙山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.231187,
        "lng": 109.496576
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        109.2253623,
        28.7716773,
        109.8264313,
        29.6369724
      ]
    },
    {
      "id": 11460,
      "name": "Fuefuki",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6485474,
        "lng": 138.6393992
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.584884,
        35.516206,
        138.793252,
        35.711539
      ]
    },
    {
      "id": 11461,
      "name": "Embu-Guacu",
      "names": {
        "zh": "恩布-苏",
        "pt": "Embu-Guaçu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.8317997,
        "lng": -46.8156204
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.912244,
        -23.9419019,
        -46.7625317,
        -23.7646129
      ]
    },
    {
      "id": 11462,
      "name": "Rosales",
      "names": {
        "zh": "罗萨莱斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.8924255,
        "lng": 120.6329883
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5929883,
        15.8524255,
        120.6729883,
        15.9324255
      ]
    },
    {
      "id": 11463,
      "name": "Yuquan",
      "names": {
        "zh": "玉泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4184964,
        "lng": 114.0900081
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.0500081,
        40.3784964,
        114.1300081,
        40.4584964
      ]
    },
    {
      "id": 11464,
      "name": "Yorba Linda",
      "names": {
        "ja": "ヨルバリンダ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8901096,
        "lng": -117.8249705
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8458318,
        33.8633877,
        -117.6834497,
        33.9184395
      ]
    },
    {
      "id": 11465,
      "name": "Kashima",
      "names": {
        "zh": "鹿岛",
        "ja": "鹿島"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9661164,
        "lng": 140.6450292
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.5538249,
        35.927933,
        140.9585606,
        36.1537473
      ]
    },
    {
      "id": 11466,
      "name": "Bergen op Zoom",
      "names": {
        "zh": "卑尔根op zoom",
        "de": "Bergen op zoomen",
        "ja": "ベルゲンopズーム",
        "pt": "Zoom Op Bergen"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4947862,
        "lng": 4.2961899
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.2911899,
        51.4897862,
        4.3011899,
        51.4997862
      ]
    },
    {
      "id": 11467,
      "name": "Santa Catarina Otzolotepec",
      "names": {
        "zh": "圣卡塔琳娜otzolotepec",
        "ja": "サンタカタリーナオートゾロテペック",
        "pt": "Santa Catarina Otzoloteepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5768568,
        "lng": -97.2660353
      },
      "country": "Mexico",
      "importance": 3,
      "bbox": [
        -97.2860353,
        18.5568568,
        -97.2460353,
        18.5968568
      ]
    },
    {
      "id": 11468,
      "name": "Manaure",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.77505,
        "lng": -72.44447
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.46447,
        11.75505,
        -72.42447,
        11.79505
      ]
    },
    {
      "id": 11469,
      "name": "Yuksekova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5717687,
        "lng": 44.2821698
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        43.8881853,
        37.2231192,
        44.6304124,
        37.8065854
      ]
    },
    {
      "id": 11470,
      "name": "Casper",
      "names": {
        "zh": "卡斯帕",
        "ja": "キャスパー",
        "th": "แคสเปอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.8501191,
        "lng": -106.325138
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -106.4348918,
        42.742942,
        -106.215706,
        42.907532
      ]
    },
    {
      "id": 11471,
      "name": "Lushar",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.48416,
        "lng": 101.56328
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.54328,
        36.46416,
        101.58328,
        36.50416
      ]
    },
    {
      "id": 11472,
      "name": "Saint John",
      "names": {
        "es": "San Juan",
        "zh": "圣约翰",
        "it": "San Giovanni",
        "id": "Orang lain",
        "ja": "セントジョン",
        "th": "เซนต์จอห์น",
        "pt": "Santo john"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.2787992,
        "lng": -66.0585188
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -66.2456224,
        45.1332468,
        -65.8388646,
        45.3769267
      ]
    },
    {
      "id": 11473,
      "name": "Tolanaro",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -25.03249,
        "lng": 46.98329
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        46.96329,
        -25.05249,
        47.00329,
        -25.01249
      ]
    },
    {
      "id": 11474,
      "name": "Soio",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -6.1349,
        "lng": 12.36894
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        12.34894,
        -6.1549,
        12.38894,
        -6.1149
      ]
    },
    {
      "id": 11475,
      "name": "Sagunto",
      "names": {
        "zh": "萨贡托"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.6756707,
        "lng": -0.2714149
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.2764149,
        39.6706707,
        -0.2664149,
        39.6806707
      ]
    },
    {
      "id": 11476,
      "name": "Hashima",
      "names": {
        "zh": "羽岛"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3195107,
        "lng": 136.7027348
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.657099,
        35.241058,
        136.753946,
        35.356869
      ]
    },
    {
      "id": 11477,
      "name": "Palatine",
      "names": {
        "fr": "Palatin",
        "zh": "巴拉丁",
        "it": "Palatino",
        "ja": "パラティーノ",
        "th": "เกี่ยวกับเพดานปาก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1129566,
        "lng": -88.0484019
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.0534019,
        42.1079566,
        -88.0434019,
        42.1179566
      ]
    },
    {
      "id": 11478,
      "name": "Sa'ada",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 14.9288,
        "lng": 32.2558
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        32.2358,
        14.9088,
        32.2758,
        14.9488
      ]
    },
    {
      "id": 11479,
      "name": "Corvallis",
      "names": {
        "zh": "科瓦利斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.5645659,
        "lng": -123.2620435
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.3362174,
        44.5199325,
        -123.2310359,
        44.607243
      ]
    },
    {
      "id": 11480,
      "name": "Zihuatanejo",
      "names": {
        "ja": "ジワタネホ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.6467874,
        "lng": -101.551974
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.591974,
        17.6067874,
        -101.511974,
        17.6867874
      ]
    },
    {
      "id": 11481,
      "name": "Herford",
      "names": {
        "zh": "黑尔福德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1152245,
        "lng": 8.6711118
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.5570489,
        52.0578613,
        8.7545999,
        52.1699428
      ]
    },
    {
      "id": 11482,
      "name": "Kissidougou",
      "names": {
        "zh": "基西杜古"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 9.2101274,
        "lng": -10.1215917
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -10.8215917,
        8.5101274,
        -9.4215917,
        9.9101274
      ]
    },
    {
      "id": 11483,
      "name": "Koratla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.8238151,
        "lng": 78.7205172
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.7204672,
        18.8237651,
        78.7205672,
        18.8238651
      ]
    },
    {
      "id": 11484,
      "name": "Guangping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4824087,
        "lng": 114.9390742
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.7790742,
        36.3224087,
        115.0990742,
        36.6424087
      ]
    },
    {
      "id": 11485,
      "name": "Ankeny",
      "names": {
        "zh": "安克尼"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.729926,
        "lng": -93.607701
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.672796,
        41.652082,
        -93.541439,
        41.784093
      ]
    },
    {
      "id": 11486,
      "name": "Weiyuan",
      "names": {
        "zh": "威远"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1515208,
        "lng": 104.1445423
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.7356388,
        34.8870743,
        104.4783258,
        35.4156024
      ]
    },
    {
      "id": 11487,
      "name": "Venustiano Carranza",
      "names": {
        "it": "Venusiano Carranza",
        "ja": "Venutiano Carranza"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.30898425,
        "lng": -92.6379347298267
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.8755462,
        16.1230164,
        -92.3446712,
        16.4952348
      ]
    },
    {
      "id": 11488,
      "name": "San Pedro Sacatepequez",
      "names": {
        "fr": "San Pedro Sacatepequezez",
        "ja": "サンペドロ神聖ペケツ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9675936,
        "lng": -91.7780422
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.8174263,
        14.8904995,
        -91.7062076,
        15.0173385
      ]
    },
    {
      "id": 11489,
      "name": "Rowlett",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 32.9029017,
        "lng": -96.56388
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.593108,
        32.869793,
        -96.501787,
        32.975361
      ]
    },
    {
      "id": 11490,
      "name": "Pelileo",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.3295591,
        "lng": -78.5455803
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -78.5843538,
        -1.4204275,
        -78.4717825,
        -1.2684162
      ]
    },
    {
      "id": 11491,
      "name": "Lakeville",
      "names": {
        "zh": "莱克维尔",
        "th": "เลกวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.650051,
        "lng": -93.2432791
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.318731,
        44.623439,
        -93.1563968,
        44.7175444
      ]
    },
    {
      "id": 11492,
      "name": "Yachimata",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6658607,
        "lng": 140.3178646
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.248531,
        35.572529,
        140.354461,
        35.716061
      ]
    },
    {
      "id": 11493,
      "name": "Caico",
      "names": {
        "zh": "凯科"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.4594544,
        "lng": -37.0971911
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.2308152,
        -6.7339287,
        -36.8608158,
        -6.1907184
      ]
    },
    {
      "id": 11494,
      "name": "Tejen",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 37.3845969,
        "lng": 60.5005729
      },
      "country": "Turkmenistan",
      "importance": 3,
      "bbox": [
        60.4955729,
        37.3795969,
        60.5055729,
        37.3895969
      ]
    },
    {
      "id": 11495,
      "name": "Agoo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3223973,
        "lng": 120.3670036
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.1909116,
        16.2710093,
        120.4064354,
        16.3699492
      ]
    },
    {
      "id": 11496,
      "name": "Itamaraju",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.0340092,
        "lng": -39.5315525
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.08,
        -17.2431884,
        -39.4372331,
        -16.7286652
      ]
    },
    {
      "id": 11497,
      "name": "Rio Negro",
      "names": {
        "fr": "Rio nègre",
        "zh": "里约热内卢",
        "ja": "リオネグロ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.1047668,
        "lng": -49.7982766
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.9406371,
        -26.2373705,
        -49.4854117,
        -25.9423524
      ]
    },
    {
      "id": 11498,
      "name": "Cosmopolis",
      "names": {
        "zh": "国际大都市",
        "ja": "京"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6437398,
        "lng": -47.1972086
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2787641,
        -22.7358787,
        -47.0923533,
        -22.5707238
      ]
    },
    {
      "id": 11499,
      "name": "Moita",
      "names": {
        "zh": "莫伊塔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6523995,
        "lng": -8.9935314
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.0506251,
        38.6012092,
        -8.9448132,
        38.6968672
      ]
    },
    {
      "id": 11500,
      "name": "San Mateo",
      "names": {
        "zh": "圣马特奥",
        "ja": "サンマテオ",
        "th": "ซานมาเทโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6951949,
        "lng": 121.1174024
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0774024,
        14.6551949,
        121.1574024,
        14.7351949
      ]
    },
    {
      "id": 11501,
      "name": "Alpharetta",
      "names": {
        "ja": "アルファレッタ",
        "th": "อัลฟาเรตตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0709576,
        "lng": -84.2747329
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.359435,
        34.0281476,
        -84.201218,
        34.1173451
      ]
    },
    {
      "id": 11502,
      "name": "Aflao",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.1160471,
        "lng": 1.1914021
      },
      "country": "Ghana",
      "importance": 3,
      "bbox": [
        1.1514021,
        6.0760471,
        1.2314021,
        6.1560471
      ]
    },
    {
      "id": 11503,
      "name": "Janiuay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9493,
        "lng": 122.5061
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4861,
        10.9293,
        122.5261,
        10.9693
      ]
    },
    {
      "id": 11504,
      "name": "La Carlota",
      "names": {
        "ja": "ラカルロタ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.425356,
        "lng": 122.9224865
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.8973026,
        10.36815,
        123.1324518,
        10.4663039
      ]
    },
    {
      "id": 11505,
      "name": "Castro",
      "names": {
        "zh": "卡斯特罗",
        "ja": "カストロ",
        "th": "คาสโตร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.794072,
        "lng": -49.9972914
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.244171,
        -25.0759441,
        -49.4984339,
        -24.5407789
      ]
    },
    {
      "id": 11506,
      "name": "Vidnoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.542669,
        "lng": 37.696281
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.666943,
        55.530777,
        37.725693,
        55.558224
      ]
    },
    {
      "id": 11507,
      "name": "Longview",
      "names": {
        "zh": "朗维尤",
        "ja": "ロングビュー",
        "th": "วิว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.1377048,
        "lng": -122.934462
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.039348,
        46.101626,
        -122.891767,
        46.18358
      ]
    },
    {
      "id": 11508,
      "name": "Bongabon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6321,
        "lng": 121.1448
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1248,
        15.6121,
        121.1648,
        15.6521
      ]
    },
    {
      "id": 11509,
      "name": "Lupon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.89814,
        "lng": 126.00961
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        125.98961,
        6.87814,
        126.02961,
        6.91814
      ]
    },
    {
      "id": 11510,
      "name": "Guines",
      "names": {
        "es": "Guînes",
        "fr": "Guînes"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.846359,
        "lng": -82.028646
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.028646,
        22.846359,
        -82.028646,
        22.846359
      ]
    },
    {
      "id": 11511,
      "name": "Fukutsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7668264,
        "lng": 130.4913329
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.3618979,
        33.730612,
        130.5553673,
        33.8513867
      ]
    },
    {
      "id": 11512,
      "name": "Godalming",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 51.184627,
        "lng": -0.610514
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.640927,
        51.169798,
        -0.584877,
        51.207999
      ]
    },
    {
      "id": 11513,
      "name": "DeKalb",
      "names": {
        "zh": "迪卡",
        "ja": "デカルブ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9298644,
        "lng": -88.7501925
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.797425,
        41.883337,
        -88.693666,
        41.97176
      ]
    },
    {
      "id": 11514,
      "name": "Pabianice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6639859,
        "lng": 19.3535024
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.2741875,
        51.632283,
        19.4355206,
        51.6992006
      ]
    },
    {
      "id": 11515,
      "name": "Petaluma",
      "names": {
        "zh": "佩塔卢马",
        "th": "เปตาลูมา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2412911,
        "lng": -122.63463637912987
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.6350266,
        38.2409387,
        -122.6342517,
        38.2415874
      ]
    },
    {
      "id": 11516,
      "name": "El Seibo",
      "names": {
        "ja": "エルシュボ",
        "pt": "El lavibo"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.76559,
        "lng": -69.03886
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -69.05886,
        18.74559,
        -69.01886,
        18.78559
      ]
    },
    {
      "id": 11517,
      "name": "Capanema",
      "names": {
        "zh": "卡帕内马"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.1933054,
        "lng": -47.1828679
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2156222,
        -1.3893926,
        -46.9753641,
        -0.9872627
      ]
    },
    {
      "id": 11518,
      "name": "Genk",
      "names": {
        "zh": "亨克",
        "th": "เกนก์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9368928,
        "lng": 5.5008869
      },
      "country": "Belgium",
      "importance": 3,
      "bbox": [
        5.4905041,
        50.9344855,
        5.5033081,
        50.9431108
      ]
    },
    {
      "id": 11519,
      "name": "Janauba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.8053523,
        "lng": -43.3120234
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.3170234,
        -15.8103523,
        -43.3070234,
        -15.8003523
      ]
    },
    {
      "id": 11520,
      "name": "Botolan",
      "names": {
        "zh": "博托兰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2896,
        "lng": 120.0245
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.0045,
        15.2696,
        120.0445,
        15.3096
      ]
    },
    {
      "id": 11521,
      "name": "Pulivendla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.418288,
        "lng": 78.222937
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.209326,
        14.389385,
        78.245787,
        14.453373
      ]
    },
    {
      "id": 11522,
      "name": "Kerpen",
      "names": {
        "zh": "科彭"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8696345,
        "lng": 6.694734
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.549486,
        50.8219467,
        6.8052384,
        50.9254194
      ]
    },
    {
      "id": 11523,
      "name": "Hit",
      "names": {
        "es": "Golpear",
        "fr": "Frappé",
        "zh": "击中",
        "it": "Colpire",
        "id": "Memukul",
        "ja": "ヒット",
        "th": "ตี",
        "pt": "Bater"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.6387394,
        "lng": 42.8278537
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        42.6678537,
        33.4787394,
        42.9878537,
        33.7987394
      ]
    },
    {
      "id": 11524,
      "name": "Opol",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5212429,
        "lng": 124.5747574
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5347574,
        8.4812429,
        124.6147574,
        8.5612429
      ]
    },
    {
      "id": 11525,
      "name": "Las Heras",
      "names": {
        "fr": "Héras Las",
        "zh": "拉斯牧民",
        "ja": "ラスヘラス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.8521368,
        "lng": -68.834782
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -68.8421089,
        -32.8673961,
        -68.8155417,
        -32.8409451
      ]
    },
    {
      "id": 11526,
      "name": "Ocoyoacac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2727686,
        "lng": -99.4614461
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.4614961,
        19.2727186,
        -99.4613961,
        19.2728186
      ]
    },
    {
      "id": 11527,
      "name": "Belogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.921326,
        "lng": 128.47287
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        128.359305,
        50.8447229,
        128.5723761,
        50.9417374
      ]
    },
    {
      "id": 11528,
      "name": "Qarqan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1353667,
        "lng": 85.5296266
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        85.4896266,
        38.0953667,
        85.5696266,
        38.1753667
      ]
    },
    {
      "id": 11529,
      "name": "Gwacheon",
      "names": {
        "zh": "果川",
        "ja": "果川"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.42889,
        "lng": 126.98917
      },
      "country": "South Korea",
      "importance": 3,
      "bbox": [
        126.96917,
        37.40889,
        127.00917,
        37.44889
      ]
    },
    {
      "id": 11530,
      "name": "Saiki",
      "names": {
        "zh": "才木"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9601732,
        "lng": 131.8996704
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.475145,
        32.3788922,
        132.2760947,
        33.1432252
      ]
    },
    {
      "id": 11531,
      "name": "Huinan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.627932,
        "lng": 126.2561125
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        126.1413717,
        42.5213954,
        126.40028,
        42.7160407
      ]
    },
    {
      "id": 11532,
      "name": "Russelsheim",
      "names": {
        "es": "Rüsselsheim",
        "fr": "Rüsselsheim",
        "de": "Rüsselsheim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.991701,
        "lng": 8.4138251
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        8.4088251,
        49.986701,
        8.4188251,
        49.996701
      ]
    },
    {
      "id": 11533,
      "name": "Ishim",
      "names": {
        "zh": "伊希姆"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1092126,
        "lng": 69.457152
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        69.3710068,
        56.0527397,
        69.5901344,
        56.1375762
      ]
    },
    {
      "id": 11534,
      "name": "Angat",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.927794,
        "lng": 121.02914
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.9555572,
        14.8892153,
        121.067336,
        14.982722
      ]
    },
    {
      "id": 11535,
      "name": "Caledon",
      "names": {
        "zh": "卡利登"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.875427,
        "lng": -79.858285
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.018285,
        43.715427,
        -79.698285,
        44.035427
      ]
    },
    {
      "id": 11536,
      "name": "Gukovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.0499167,
        "lng": 39.9412787
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.9362787,
        48.0449167,
        39.9462787,
        48.0549167
      ]
    },
    {
      "id": 11537,
      "name": "Valence",
      "names": {
        "zh": "价",
        "de": "Valenz",
        "ja": "ヴァランス",
        "th": "ความจุ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.9332277,
        "lng": 4.8920811
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.8533327,
        44.8870479,
        4.9783075,
        44.959755
      ]
    },
    {
      "id": 11538,
      "name": "Xieqiaocun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.4491,
        "lng": 119.71649
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.69649,
        32.4291,
        119.73649,
        32.4691
      ]
    },
    {
      "id": 11539,
      "name": "P'yongsan",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 38.33944,
        "lng": 126.39278
      },
      "country": "North Korea",
      "importance": 3,
      "bbox": [
        126.37278,
        38.31944,
        126.41278,
        38.35944
      ]
    },
    {
      "id": 11540,
      "name": "San Remigio",
      "names": {
        "zh": "圣汇",
        "ja": "サンレミオ",
        "th": "ซานรีโมจิโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0835479,
        "lng": 123.9366262
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.8966262,
        11.0435479,
        123.9766262,
        11.1235479
      ]
    },
    {
      "id": 11541,
      "name": "Huquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7604651,
        "lng": 114.2834563
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.2434563,
        39.7204651,
        114.3234563,
        39.8004651
      ]
    },
    {
      "id": 11542,
      "name": "Kalush",
      "names": {
        "zh": "卡卢什"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.028894,
        "lng": 24.3613198
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.2734422,
        48.9978353,
        24.4485605,
        49.0910879
      ]
    },
    {
      "id": 11543,
      "name": "Shiojiri",
      "names": {
        "zh": "盐尻"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1143838,
        "lng": 137.9477924
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.9427924,
        36.1093838,
        137.9527924,
        36.1193838
      ]
    },
    {
      "id": 11544,
      "name": "Laguna Niguel",
      "names": {
        "ja": "ラグーナニゲル",
        "th": "ลากูน่าไนเกล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5225261,
        "lng": -117.7075526
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.740177,
        33.4870947,
        -117.6725131,
        33.5714131
      ]
    },
    {
      "id": 11545,
      "name": "Eagan",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 44.818173,
        "lng": -93.1659179
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.2278669,
        44.7756309,
        -93.105611,
        44.861977
      ]
    },
    {
      "id": 11546,
      "name": "Slavyansk-na-Kubani",
      "names": {
        "zh": "斯拉维扬斯克-钠kubani",
        "ja": "slavyansk-をNA-kubani",
        "th": "slavyansk-นา-kubani"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.2590875,
        "lng": 38.1244609
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.0496732,
        45.2120097,
        38.176845,
        45.2865823
      ]
    },
    {
      "id": 11547,
      "name": "Ilo",
      "names": {
        "es": "La OIT",
        "fr": "L'OIT",
        "zh": "国际劳工组织",
        "de": "IAO",
        "ja": "ILOの",
        "th": "อิล",
        "pt": "OIT"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -17.6458772,
        "lng": -71.3452005
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -71.3814531,
        -17.8214824,
        -71.0819922,
        -17.62107
      ]
    },
    {
      "id": 11548,
      "name": "Kenner",
      "names": {
        "zh": "肯纳"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.9942265,
        "lng": -90.2417806
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.2802345,
        29.969137,
        -90.2213849,
        30.049979
      ]
    },
    {
      "id": 11549,
      "name": "Itaberaba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.524182,
        "lng": -40.305897
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.574,
        -12.7923722,
        -39.9263076,
        -12.2550451
      ]
    },
    {
      "id": 11550,
      "name": "Korfez",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.773591,
        "lng": 29.7648555
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.7648055,
        40.773541,
        29.7649055,
        40.773641
      ]
    },
    {
      "id": 11551,
      "name": "Oas",
      "names": {
        "es": "OEA",
        "fr": "OEA",
        "zh": "美洲国家组织",
        "pt": "OEA"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2570425,
        "lng": 123.5001669
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4601669,
        13.2170425,
        123.5401669,
        13.2970425
      ]
    },
    {
      "id": 11552,
      "name": "Malvar",
      "names": {
        "zh": "马尔瓦尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.04472,
        "lng": 121.15861
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.13861,
        14.02472,
        121.17861,
        14.06472
      ]
    },
    {
      "id": 11553,
      "name": "Sodo",
      "names": {
        "zh": "索多"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 6.864163,
        "lng": 37.762762
      },
      "country": "Ethiopia",
      "importance": 3,
      "bbox": [
        37.602762,
        6.704163,
        37.922762,
        7.024163
      ]
    },
    {
      "id": 11554,
      "name": "Kungur",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.428844,
        "lng": 56.944328
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        56.8793054,
        57.3177358,
        57.0875825,
        57.4637333
      ]
    },
    {
      "id": 11555,
      "name": "Lencois Paulista",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6029897,
        "lng": -48.8031367
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.963,
        -22.852197,
        -48.661,
        -22.484
      ]
    },
    {
      "id": 11556,
      "name": "Roxas",
      "names": {
        "zh": "罗哈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.121915,
        "lng": 121.6199232
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.5799232,
        17.081915,
        121.6599232,
        17.161915
      ]
    },
    {
      "id": 11557,
      "name": "Obera",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -27.4849798,
        "lng": -55.1202965
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -55.173395,
        -27.5202601,
        -55.080148,
        -27.4461335
      ]
    },
    {
      "id": 11558,
      "name": "Lantapan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9983082,
        "lng": 125.0228905
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8095397,
        7.9802743,
        125.1317019,
        8.1395364
      ]
    },
    {
      "id": 11559,
      "name": "Uniao dos Palmares",
      "names": {
        "de": "Uniao dos palnen",
        "ja": "ユニアオドスパームレス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.1576807,
        "lng": -36.0342251
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.2008278,
        -9.2187583,
        -35.86,
        -9.031
      ]
    },
    {
      "id": 11560,
      "name": "Sindelfingen",
      "names": {
        "zh": "辛德尔芬根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7084162,
        "lng": 9.0035455
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        8.9047675,
        48.6737578,
        9.1085039,
        48.7468285
      ]
    },
    {
      "id": 11561,
      "name": "Bay City",
      "names": {
        "es": "Ciudad",
        "fr": "Ville de la baie",
        "zh": "湾城",
        "de": "Buchtstadt",
        "it": "Baia",
        "ja": "ベイシティ",
        "th": "อ่าวเมือง",
        "pt": "Cidade baía"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.5944677,
        "lng": -83.8888648
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.9161566,
        43.5386677,
        -83.8452803,
        43.6238297
      ]
    },
    {
      "id": 11562,
      "name": "Most",
      "names": {
        "es": "Más",
        "fr": "Plus",
        "zh": "最",
        "de": "Die meisten",
        "it": "Più",
        "id": "Kebanyakan",
        "ja": "最も",
        "th": "มากที่สุด",
        "pt": "Mais"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.5032737,
        "lng": 13.636112
      },
      "country": "Czechia",
      "importance": 3,
      "bbox": [
        13.4926305,
        50.4633952,
        13.7054404,
        50.5646831
      ]
    },
    {
      "id": 11563,
      "name": "Seraing",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6087967,
        "lng": 5.513093
      },
      "country": "Belgium",
      "importance": 3,
      "bbox": [
        5.508093,
        50.6037967,
        5.518093,
        50.6137967
      ]
    },
    {
      "id": 11564,
      "name": "El Carmen de Bolivar",
      "names": {
        "es": "El Carmen de Bolívar"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.7178841,
        "lng": -75.1241376
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3825542,
        9.5300277,
        -74.9386715,
        9.8794868
      ]
    },
    {
      "id": 11565,
      "name": "Stupino",
      "names": {
        "zh": "斯图皮诺"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.886598,
        "lng": 38.0772589
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        37.987605,
        54.8407176,
        38.1991554,
        54.947919
      ]
    },
    {
      "id": 11566,
      "name": "Pessac",
      "names": {
        "zh": "佩萨克",
        "th": "ในเปสแซก"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.805615,
        "lng": -0.6308396
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        -0.7587897,
        44.7495449,
        -0.6029478,
        44.8225797
      ]
    },
    {
      "id": 11567,
      "name": "Venancio Aires",
      "names": {
        "fr": "VeniCio Aires",
        "ja": "ヴェネチョアのお気に入り"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6118797,
        "lng": -52.184978678959084
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.185639,
        -29.6122738,
        -52.1843193,
        -29.6111591
      ]
    },
    {
      "id": 11568,
      "name": "San Francisco El Alto",
      "names": {
        "zh": "旧金山埃尔尔特",
        "ja": "サンフランシスコエルアルト",
        "th": "ซานฟรานซิสโกเอลอัลโต",
        "pt": "São Francisco El Alto"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.945113,
        "lng": -91.443109
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.5436937,
        14.9213183,
        -91.4038006,
        15.0306003
      ]
    },
    {
      "id": 11569,
      "name": "Xibang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.73725,
        "lng": 120.59841
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.57841,
        30.71725,
        120.61841,
        30.75725
      ]
    },
    {
      "id": 11570,
      "name": "Lahat",
      "names": {
        "zh": "拉哈特"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.7894804,
        "lng": 103.5372243
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        103.5322243,
        -3.7944804,
        103.5422243,
        -3.7844804
      ]
    },
    {
      "id": 11571,
      "name": "Kiamba",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9879555,
        "lng": 124.6241575
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5841575,
        5.9479555,
        124.6641575,
        6.0279555
      ]
    },
    {
      "id": 11572,
      "name": "Coron",
      "names": {
        "zh": "科伦",
        "th": "โครอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.0021034,
        "lng": 120.20048865876271
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.1959068,
        11.9954398,
        120.2131252,
        12.0088667
      ]
    },
    {
      "id": 11573,
      "name": "Menzel Temime",
      "names": {
        "de": "Menzel-Tempau",
        "id": "Peringatan menzel",
        "ja": "メンセル鋳造",
        "th": "อารมณ์ Menzel",
        "pt": "Temime de Menzel"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.77458,
        "lng": 10.983116
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.9829856,
        36.7744747,
        10.9832036,
        36.7746359
      ]
    },
    {
      "id": 11574,
      "name": "Villasis",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9003985,
        "lng": 120.588448
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.588398,
        15.9003485,
        120.588498,
        15.9004485
      ]
    },
    {
      "id": 11575,
      "name": "North Little Rock",
      "names": {
        "zh": "北小岩石",
        "it": "Nord piccolo rock",
        "id": "Batu kecil utara",
        "ja": "ノースリトルロック",
        "th": "หินน้อยนอร์ท",
        "pt": "Rocha Norte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.769536,
        "lng": -92.2670941
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -92.400283,
        34.6745288,
        -92.0981885,
        34.8774226
      ]
    },
    {
      "id": 11576,
      "name": "Santo Tome",
      "names": {
        "es": "Santo tomo",
        "ja": "サントトーム",
        "pt": "Santo"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6630458,
        "lng": -60.7618282
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -60.863422,
        -31.70448,
        -60.7303751,
        -31.5896894
      ]
    },
    {
      "id": 11577,
      "name": "Sammamish",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 47.6017554,
        "lng": -122.0416844
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.0920248,
        47.553604,
        -121.964584,
        47.657487
      ]
    },
    {
      "id": 11578,
      "name": "Lutayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.62,
        "lng": 124.88306
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.86306,
        6.6,
        124.90306,
        6.64
      ]
    },
    {
      "id": 11579,
      "name": "Asagicinik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.18994,
        "lng": 36.46976
      },
      "country": "Turkey",
      "importance": 3,
      "bbox": [
        36.44976,
        41.16994,
        36.48976,
        41.20994
      ]
    },
    {
      "id": 11580,
      "name": "Weimar",
      "names": {
        "zh": "魏玛",
        "ja": "ワイマール",
        "th": "ไวมาร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9769686,
        "lng": 11.3275394
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.2349051,
        50.9171123,
        11.4019598,
        51.0367147
      ]
    },
    {
      "id": 11581,
      "name": "Shawnee",
      "names": {
        "zh": "肖尼",
        "ja": "ショーニー",
        "th": "ชอว์นี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0273676,
        "lng": -95.7627535
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.0390871,
        38.868867,
        -95.500254,
        39.2167022
      ]
    },
    {
      "id": 11582,
      "name": "Jupiter",
      "names": {
        "es": "Júpiter",
        "zh": "木星",
        "it": "Giove",
        "ja": "ジュピター",
        "th": "ดาวพฤหัสบดี",
        "pt": "Júpiter"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.9342246,
        "lng": -80.0942087
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.1734724,
        26.8823176,
        -80.0567704,
        26.9708806
      ]
    },
    {
      "id": 11583,
      "name": "Old Bridge",
      "names": {
        "es": "Puente viejo",
        "fr": "Ancien pont",
        "zh": "老桥梁",
        "de": "Alte Brücke",
        "it": "Vecchio ponte",
        "id": "Jembatan tua",
        "ja": "古い橋",
        "th": "สะพานเก่า",
        "pt": "Ponte velha"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.3913195,
        "lng": -74.3328038
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.383145,
        40.376472,
        -74.273848,
        40.4213701
      ]
    },
    {
      "id": 11584,
      "name": "Ina",
      "names": {
        "zh": "INA进口",
        "ja": "INAは"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.830452,
        "lng": 137.954916
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.824096,
        35.57096,
        138.236899,
        35.974854
      ]
    },
    {
      "id": 11585,
      "name": "Tupa",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.9349129,
        "lng": -50.5141255
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.7105116,
        -22.1105318,
        -50.354,
        -21.803964
      ]
    },
    {
      "id": 11586,
      "name": "Solano",
      "names": {
        "zh": "索拉诺"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5180604,
        "lng": 121.1806163
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1406163,
        16.4780604,
        121.2206163,
        16.5580604
      ]
    },
    {
      "id": 11587,
      "name": "Doral",
      "names": {
        "zh": "多柔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.8195424,
        "lng": -80.3553302
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.3871613,
        25.781908,
        -80.321017,
        25.8552646
      ]
    },
    {
      "id": 11588,
      "name": "Daule",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.8641689,
        "lng": -79.9804093
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -80.0644559,
        -1.9783773,
        -79.881587,
        -1.8073897
      ]
    },
    {
      "id": 11589,
      "name": "Nagcarlan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1364,
        "lng": 121.4165
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.3965,
        14.1164,
        121.4365,
        14.1564
      ]
    },
    {
      "id": 11590,
      "name": "Zarechnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.20356,
        "lng": 45.19227
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        45.17227,
        53.18356,
        45.21227,
        53.22356
      ]
    },
    {
      "id": 11591,
      "name": "Carbondale",
      "names": {
        "zh": "卡本代尔",
        "ja": "カーボン",
        "th": "คาร์บอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7274692,
        "lng": -89.216655
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.277175,
        37.679986,
        -89.148624,
        37.767703
      ]
    },
    {
      "id": 11592,
      "name": "Bordj Menaiel",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7432364,
        "lng": 3.7175665
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        3.6660175,
        36.6801405,
        3.8118535,
        36.8177656
      ]
    },
    {
      "id": 11593,
      "name": "Bourges",
      "names": {
        "zh": "布尔日"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.0811658,
        "lng": 2.399125
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.3239701,
        47.0259507,
        2.4719573,
        47.1300959
      ]
    },
    {
      "id": 11594,
      "name": "Blaine",
      "names": {
        "zh": "布莱恩",
        "ja": "ブレイン",
        "th": "เบลน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1607987,
        "lng": -93.2349489
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.266938,
        45.118569,
        -93.142421,
        45.211589
      ]
    },
    {
      "id": 11595,
      "name": "Alipur Duar",
      "names": {
        "zh": "alipur双子",
        "de": "Alipur-Duar"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.4851573,
        "lng": 89.5246926
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        89.3646926,
        26.3251573,
        89.6846926,
        26.6451573
      ]
    },
    {
      "id": 11596,
      "name": "Purulha",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.236334,
        "lng": -90.2356932
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.2756932,
        15.196334,
        -90.1956932,
        15.276334
      ]
    },
    {
      "id": 11597,
      "name": "St. Albert",
      "names": {
        "es": "San Albert",
        "fr": "Saint Albert",
        "zh": "圣艾伯特",
        "ja": "セントアルバート",
        "th": "เซนต์อัลเบิร์ต"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 53.633239,
        "lng": -113.628845
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -113.7149995,
        53.5995676,
        -113.5656265,
        53.6873248
      ]
    },
    {
      "id": 11598,
      "name": "Weirton",
      "names": {
        "zh": "威尔顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4002396,
        "lng": -80.5899788
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.6340732,
        40.365514,
        -80.5189859,
        40.4512288
      ]
    },
    {
      "id": 11599,
      "name": "Ferrol",
      "names": {
        "zh": "费罗尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.4846862,
        "lng": -8.233162
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -8.3481026,
        43.4531949,
        -8.1860636,
        43.569302
      ]
    },
    {
      "id": 11600,
      "name": "Franklin",
      "names": {
        "zh": "富兰克林",
        "ja": "フランクリン",
        "th": "แฟรงคลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9259345,
        "lng": -77.7485516
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.0992332,
        39.7198248,
        -77.454109,
        40.2892068
      ]
    },
    {
      "id": 11601,
      "name": "Jocotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.708854,
        "lng": -99.78856
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.802917,
        19.696424,
        -99.77413,
        19.721873
      ]
    },
    {
      "id": 11602,
      "name": "Los Amates",
      "names": {
        "fr": "Los amate",
        "zh": "洛杉矶申请",
        "de": "Los Amate",
        "ja": "ロスアメートン",
        "th": "ลอสเอะอะ",
        "pt": "Los amados"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2553293,
        "lng": -89.0952899
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.1352899,
        15.2153293,
        -89.0552899,
        15.2953293
      ]
    },
    {
      "id": 11603,
      "name": "Tulare",
      "names": {
        "zh": "图莱里"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.2077351,
        "lng": -119.3473421
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.392846,
        36.123472,
        -119.295803,
        36.2477554
      ]
    },
    {
      "id": 11604,
      "name": "Leszno",
      "names": {
        "zh": "莱什诺"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8436498,
        "lng": 16.5744141
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.5238042,
        51.8064286,
        16.6242533,
        51.8814947
      ]
    },
    {
      "id": 11605,
      "name": "Ico",
      "names": {
        "zh": "意"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.4003992,
        "lng": -38.8575946
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.1448865,
        -6.6123214,
        -38.5179093,
        -6.1076306
      ]
    },
    {
      "id": 11606,
      "name": "Boras",
      "names": {
        "fr": "Borås",
        "zh": "布罗斯",
        "id": "Borås"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.724126,
        "lng": 12.94213
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.809069,
        57.687768,
        13.031739,
        57.76183
      ]
    },
    {
      "id": 11607,
      "name": "Beloretsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.962829,
        "lng": 58.394613
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        58.349945,
        53.944138,
        58.454193,
        54.000889
      ]
    },
    {
      "id": 11608,
      "name": "Jaisalmer",
      "names": {
        "zh": "斋沙默尔",
        "ja": "ジャイサルメール",
        "th": "ไจซาลเมอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.915732,
        "lng": 70.917232
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        70.893669,
        26.894428,
        70.947792,
        26.930379
      ]
    },
    {
      "id": 11609,
      "name": "Januaria",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.4875,
        "lng": -44.361111
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.621,
        -15.8029488,
        -44.1009168,
        -14.8314574
      ]
    },
    {
      "id": 11610,
      "name": "Ishimbay",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.44527,
        "lng": 56.04262
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        56.02375,
        53.427139,
        56.07103,
        53.467445
      ]
    },
    {
      "id": 11611,
      "name": "Peruibe",
      "names": {
        "pt": "Peruíbe"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.3218105,
        "lng": -46.9977452
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.1204686,
        -24.4482433,
        -46.897,
        -24.122
      ]
    },
    {
      "id": 11612,
      "name": "Korosten",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.9491068,
        "lng": 28.6417552
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.5547398,
        50.9127091,
        28.6988831,
        51.0085512
      ]
    },
    {
      "id": 11613,
      "name": "Wellington",
      "names": {
        "zh": "惠灵顿",
        "ja": "ウェリントン",
        "th": "เวลลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.2675076,
        "lng": -97.3999395
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.535238,
        37.200007,
        -97.3643,
        37.338427
      ]
    },
    {
      "id": 11614,
      "name": "Birnin Konni",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 13.79562,
        "lng": 5.2553
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        5.2353,
        13.77562,
        5.2753,
        13.81562
      ]
    },
    {
      "id": 11615,
      "name": "Pflugerville",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 30.43837435,
        "lng": -97.58611597908597
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.5911793,
        30.4290916,
        -97.5741865,
        30.4478389
      ]
    },
    {
      "id": 11616,
      "name": "Palo Alto",
      "names": {
        "zh": "帕洛阿尔托",
        "ja": "パロアルト"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4443293,
        "lng": -122.1598465
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.2026647,
        37.2876789,
        -122.0700195,
        37.4789448
      ]
    },
    {
      "id": 11617,
      "name": "Quezon",
      "names": {
        "zh": "奎松",
        "ja": "ケソン",
        "th": "เควซอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.2377363,
        "lng": 117.9963805
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        117.9563805,
        9.1977363,
        118.0363805,
        9.2777363
      ]
    },
    {
      "id": 11618,
      "name": "Middletown",
      "names": {
        "zh": "米德尔敦",
        "ja": "ミドルタウン",
        "th": "มิดเดิลทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8397222,
        "lng": -73.8316667
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.8416667,
        40.8297222,
        -73.8216667,
        40.8497222
      ]
    },
    {
      "id": 11619,
      "name": "Neuwied",
      "names": {
        "zh": "新维德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.4302393,
        "lng": 7.466302
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.3834047,
        50.4118038,
        7.5832597,
        50.5091018
      ]
    },
    {
      "id": 11620,
      "name": "Zeist",
      "names": {
        "zh": "宰斯特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.088768,
        "lng": 5.234864
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.192752,
        52.058487,
        5.322462,
        52.114543
      ]
    },
    {
      "id": 11621,
      "name": "Chunian",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.9631954,
        "lng": 73.9807183
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.9407183,
        30.9231954,
        74.0207183,
        31.0031954
      ]
    },
    {
      "id": 11622,
      "name": "Halabjah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 35.180174,
        "lng": 45.988877
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        45.953747,
        35.158922,
        46.012524,
        35.20888
      ]
    },
    {
      "id": 11623,
      "name": "Viana",
      "names": {
        "zh": "维亚纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.3914471,
        "lng": -40.4966587
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.6324384,
        -20.5051771,
        -40.3704026,
        -20.2788591
      ]
    },
    {
      "id": 11624,
      "name": "Marinilla",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1736417,
        "lng": -75.3346548
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3746548,
        6.1336417,
        -75.2946548,
        6.2136417
      ]
    },
    {
      "id": 11625,
      "name": "Formiga",
      "names": {
        "zh": "福米加"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4648425,
        "lng": -45.4266753
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.8336438,
        -20.7366022,
        -45.2511922,
        -20.3121761
      ]
    },
    {
      "id": 11626,
      "name": "Mecheria",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.5428415,
        "lng": -0.2750524
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        -0.4202088,
        33.335278,
        0.1105275,
        33.6433808
      ]
    },
    {
      "id": 11627,
      "name": "Great Falls",
      "names": {
        "es": "Grandes caídas",
        "fr": "Grandes chutes",
        "zh": "大瀑布",
        "de": "Tolles Fälle",
        "it": "Grandi cascate",
        "id": "Jatuh besar",
        "ja": "素晴らしい滝",
        "th": "น้ำตกที่ยิ่งใหญ่",
        "pt": "Grandes quedas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.5048851,
        "lng": -111.29189
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.407472,
        47.4579928,
        -111.1988096,
        47.5519561
      ]
    },
    {
      "id": 11628,
      "name": "Esquipulas",
      "names": {
        "zh": "埃斯基普拉斯"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6308869,
        "lng": -89.25525631254732
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.4176486,
        14.46485,
        -89.1311568,
        14.7972929
      ]
    },
    {
      "id": 11629,
      "name": "Dormagen",
      "names": {
        "zh": "多马根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0934389,
        "lng": 6.8416158
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.696122,
        51.0458632,
        6.8834254,
        51.1626153
      ]
    },
    {
      "id": 11630,
      "name": "Caripito",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1123858,
        "lng": -63.103437
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -63.1502509,
        10.073546,
        -63.0545175,
        10.1433469
      ]
    },
    {
      "id": 11631,
      "name": "Maasin",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9711612,
        "lng": 122.0062188
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.9862188,
        6.9511612,
        122.0262188,
        6.9911612
      ]
    },
    {
      "id": 11632,
      "name": "Michigan City",
      "names": {
        "es": "Ciudad de Michigan",
        "fr": "Ville de Michigan",
        "zh": "密歇根州市",
        "de": "Michigan-Stadt",
        "id": "Kota michigan",
        "ja": "ミシガンシティ",
        "th": "เมืองมิชิแกน",
        "pt": "Cidade de michigan"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7075394,
        "lng": -86.8950297
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.9326811,
        41.665809,
        -86.816085,
        41.753454
      ]
    },
    {
      "id": 11633,
      "name": "Pokrovsk",
      "names": {
        "zh": "波克罗夫斯克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2850109,
        "lng": 37.179778
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        37.174778,
        48.2800109,
        37.184778,
        48.2900109
      ]
    },
    {
      "id": 11634,
      "name": "Santa Catarina Pinula",
      "names": {
        "fr": "Pinula Santa Catarina",
        "zh": "圣卡塔琳娜·佩拉",
        "ja": "サンタカタリーナピュニュラ",
        "th": "ซานต้าคาตาร์ดิน่า Pinula"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.56889,
        "lng": -90.49528
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.51528,
        14.54889,
        -90.47528,
        14.58889
      ]
    },
    {
      "id": 11635,
      "name": "Svyetlahorsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.6329,
        "lng": 29.7389
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.7189,
        52.6129,
        29.7589,
        52.6529
      ]
    },
    {
      "id": 11636,
      "name": "Rosenheim",
      "names": {
        "zh": "罗森海姆",
        "ja": "ローゼンハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8539273,
        "lng": 12.127262
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.0538679,
        47.8091223,
        12.1531667,
        47.8870322
      ]
    },
    {
      "id": 11637,
      "name": "Ito",
      "names": {
        "zh": "伊藤",
        "ja": "伊藤",
        "th": "อิโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9755818,
        "lng": 139.0922763
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.0872763,
        34.9705818,
        139.0972763,
        34.9805818
      ]
    },
    {
      "id": 11638,
      "name": "Tadepalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.47083795,
        "lng": 80.61843665916459
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.5599116,
        16.4215752,
        80.6746673,
        16.5201631
      ]
    },
    {
      "id": 11639,
      "name": "Donskoy",
      "names": {
        "zh": "顿斯科伊"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.965651,
        "lng": 38.3183367
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.2440198,
        53.8969888,
        38.4473135,
        54.0491045
      ]
    },
    {
      "id": 11640,
      "name": "Yanagawa",
      "names": {
        "zh": "柳川",
        "ja": "柳川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.1630969,
        "lng": 130.4058091
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.318059,
        33.0674079,
        130.4762793,
        33.196587
      ]
    },
    {
      "id": 11641,
      "name": "Miyoshi",
      "names": {
        "zh": "三芳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0993323,
        "lng": 137.099531
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.0449385,
        35.055044,
        137.130274,
        35.144736
      ]
    },
    {
      "id": 11642,
      "name": "Eden Prairie",
      "names": {
        "zh": "伊甸园草原",
        "pt": "Prairie do Eden"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.849122,
        "lng": -93.462802
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.520931,
        44.798527,
        -93.398358,
        44.892327
      ]
    },
    {
      "id": 11643,
      "name": "Isabela",
      "names": {
        "zh": "伊莎贝拉",
        "ja": "イサベラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2036952,
        "lng": 122.9881449
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9481449,
        10.1636952,
        123.0281449,
        10.2436952
      ]
    },
    {
      "id": 11644,
      "name": "Sandefjord",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.1300112,
        "lng": 10.2255602
      },
      "country": "Norway",
      "importance": 3,
      "bbox": [
        10.0655602,
        58.9700112,
        10.3855602,
        59.2900112
      ]
    },
    {
      "id": 11645,
      "name": "Saravia",
      "names": {
        "zh": "萨拉维亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.87754,
        "lng": 122.9677
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9477,
        10.85754,
        122.9877,
        10.89754
      ]
    },
    {
      "id": 11646,
      "name": "Hilongos",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3733001,
        "lng": 124.7488169
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5611221,
        10.3029547,
        124.9275172,
        10.4613689
      ]
    },
    {
      "id": 11647,
      "name": "Port Orange",
      "names": {
        "es": "Naranja",
        "fr": "Orange",
        "zh": "端口橙色",
        "de": "Portorange",
        "it": "Porta arancione",
        "id": "Oranye",
        "ja": "ポートオレンジ",
        "th": "พอร์ตส้ม",
        "pt": "Laranja"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.11586,
        "lng": -81.010095
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.01561,
        29.11832,
        -80.97561,
        29.15832
      ]
    },
    {
      "id": 11648,
      "name": "Neubrandenburg",
      "names": {
        "zh": "新勃兰登堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5574458,
        "lng": 13.2602781
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.1367108,
        53.4441088,
        13.3441823,
        53.6189803
      ]
    },
    {
      "id": 11649,
      "name": "Dublin",
      "names": {
        "es": "Dublín",
        "zh": "都柏林",
        "it": "Dublino",
        "ja": "ダブリン",
        "th": "ดับลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7021521,
        "lng": -121.9357918
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.9877977,
        37.6977572,
        -121.832439,
        37.7450996
      ]
    },
    {
      "id": 11650,
      "name": "Gadwal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.2347032,
        "lng": 77.7946371
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.7546371,
        16.1947032,
        77.8346371,
        16.2747032
      ]
    },
    {
      "id": 11651,
      "name": "Rafha",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 29.620219,
        "lng": 43.494802
      },
      "country": "Saudi Arabia",
      "importance": 3,
      "bbox": [
        43.4748022042,
        29.6002191356,
        43.5148022042,
        29.6402191356
      ]
    },
    {
      "id": 11652,
      "name": "Grand Forks",
      "names": {
        "fr": "Gros fourchettes",
        "zh": "大叉子",
        "it": "Grandi forcelle",
        "id": "Garpu Grand",
        "ja": "グランドフォーク",
        "th": "ส้อมแกรนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.9174341,
        "lng": -97.1108781
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.1158781,
        47.9124341,
        -97.1058781,
        47.9224341
      ]
    },
    {
      "id": 11653,
      "name": "Sibulan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.3588762,
        "lng": 123.2852364
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0017353,
        9.3193474,
        123.4100847,
        9.3950295
      ]
    },
    {
      "id": 11654,
      "name": "Jatani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.163646,
        "lng": 85.702944
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        85.674294,
        20.148292,
        85.723187,
        20.188947
      ]
    },
    {
      "id": 11655,
      "name": "Chipindo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.8178352,
        "lng": 15.7878658
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.3363122,
        -14.4243835,
        16.1509771,
        -13.3902779
      ]
    },
    {
      "id": 11656,
      "name": "Kodad",
      "names": {
        "zh": "柯达"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.99754,
        "lng": 79.956462
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.941393,
        16.977891,
        79.991069,
        17.010697
      ]
    },
    {
      "id": 11657,
      "name": "Santo Domingo Tehuantepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3172613,
        "lng": -95.2468005
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.2868005,
        16.2772613,
        -95.2068005,
        16.3572613
      ]
    },
    {
      "id": 11658,
      "name": "Meulaboh",
      "names": {
        "zh": "米拉务"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 4.1481951,
        "lng": 96.1339107
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        96.0939107,
        4.1081951,
        96.1739107,
        4.1881951
      ]
    },
    {
      "id": 11659,
      "name": "Chokwe",
      "names": {
        "zh": "绍奎"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -24.524926,
        "lng": 33.003681
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        32.963681,
        -24.564926,
        33.043681,
        -24.484926
      ]
    },
    {
      "id": 11660,
      "name": "Binga",
      "names": {
        "zh": "宾加"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.3744884,
        "lng": 20.5219197
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        20.4819197,
        2.3344884,
        20.5619197,
        2.4144884
      ]
    },
    {
      "id": 11661,
      "name": "Noblesville",
      "names": {
        "th": "โนเบิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.041082,
        "lng": -86.007144
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.090347,
        39.986737,
        -85.862226,
        40.101791
      ]
    },
    {
      "id": 11662,
      "name": "Sodegaura",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4296688,
        "lng": 139.9544661
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.8575734,
        35.34354,
        140.09277,
        35.5507039
      ]
    },
    {
      "id": 11663,
      "name": "Cruz das Almas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6722649,
        "lng": -39.1053665
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.2096257,
        -12.7417621,
        -39.0437365,
        -12.629
      ]
    },
    {
      "id": 11664,
      "name": "Rahat",
      "names": {
        "zh": "拉哈特",
        "ja": "ラハット",
        "th": "ฮัต"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.3933637,
        "lng": 34.7546784
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.7112483,
        31.3497584,
        34.7932793,
        31.4246699
      ]
    },
    {
      "id": 11665,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.750687,
        "lng": -70.7252688
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.8706723,
        -32.8252783,
        -70.6347063,
        -32.6453642
      ]
    },
    {
      "id": 11666,
      "name": "Tamana",
      "names": {
        "zh": "玉名"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9352591,
        "lng": 130.5628137
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4435801,
        32.7818016,
        130.6315881,
        32.99513
      ]
    },
    {
      "id": 11667,
      "name": "Apaseo el Alto",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.456726,
        "lng": -100.619371
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.646245,
        20.44195,
        -100.603201,
        20.472025
      ]
    },
    {
      "id": 11668,
      "name": "Qinggang",
      "names": {
        "zh": "青冈"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.8254338,
        "lng": 125.9744506
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.3131694,
        46.4783922,
        126.3717245,
        47.1198394
      ]
    },
    {
      "id": 11669,
      "name": "Vittoria",
      "names": {
        "zh": "维多利亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 36.9514957,
        "lng": 14.5304867
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.3877218,
        36.8737557,
        14.5973802,
        37.0534457
      ]
    },
    {
      "id": 11670,
      "name": "San Clemente",
      "names": {
        "zh": "圣克莱门特",
        "ja": "サンクレメンテ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4270275,
        "lng": -117.6124179
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.6816859,
        33.3336696,
        -117.5711815,
        33.4908334
      ]
    },
    {
      "id": 11671,
      "name": "Atimonan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0027,
        "lng": 121.92078
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.90078,
        13.9827,
        121.94078,
        14.0227
      ]
    },
    {
      "id": 11672,
      "name": "Lingshou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.307222,
        "lng": 114.368056
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.208056,
        38.147222,
        114.528056,
        38.467222
      ]
    },
    {
      "id": 11673,
      "name": "Nawalgarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.85324,
        "lng": 75.270863
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        75.246736,
        27.834086,
        75.29765,
        27.869719
      ]
    },
    {
      "id": 11674,
      "name": "Estancia",
      "names": {
        "zh": "埃斯坦西亚",
        "ja": "エスタンシア"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.2682255,
        "lng": -37.4378341
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.5562424,
        -11.4586261,
        -37.2590632,
        -11.0530928
      ]
    },
    {
      "id": 11675,
      "name": "Tenri",
      "names": {
        "ja": "天理"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5965738,
        "lng": 135.8373696
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.78657,
        34.548344,
        135.951231,
        34.644802
      ]
    },
    {
      "id": 11676,
      "name": "Brentwood",
      "names": {
        "zh": "布伦特伍德",
        "ja": "ブレントウッド",
        "th": "แนชวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9317766,
        "lng": -121.696026
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.7600321,
        37.8888313,
        -121.6778037,
        37.9712486
      ]
    },
    {
      "id": 11677,
      "name": "Carmichael",
      "names": {
        "zh": "卡迈克尔",
        "ja": "カーマイケル",
        "th": "คาร์ไมเคิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.62709845,
        "lng": -121.32224222755409
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.346074,
        38.590795,
        -121.291433,
        38.6636125
      ]
    },
    {
      "id": 11678,
      "name": "Irun",
      "names": {
        "zh": "伊伦",
        "th": "ไอรัน"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3383176,
        "lng": -1.7888095
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -1.8522586,
        43.2864229,
        -1.7292688,
        43.3532864
      ]
    },
    {
      "id": 11679,
      "name": "Ouricuri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.882974,
        "lng": -40.081425
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.120879,
        -7.915177,
        -40.048526,
        -7.851742
      ]
    },
    {
      "id": 11680,
      "name": "Pomezia",
      "names": {
        "th": "โพเมเซีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.667529,
        "lng": 12.5047863
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.4159448,
        41.5957892,
        12.5954818,
        41.7144597
      ]
    },
    {
      "id": 11681,
      "name": "Plauen",
      "names": {
        "zh": "普劳恩",
        "ja": "プラウエン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.4950632,
        "lng": 12.1346523
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.0594107,
        50.4306692,
        12.2294584,
        50.5748845
      ]
    },
    {
      "id": 11682,
      "name": "Cienaga de Oro",
      "names": {
        "ja": "Cienaga deoro"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8746769,
        "lng": -75.6210036
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7467462,
        8.5944927,
        -75.4984015,
        9.0423397
      ]
    },
    {
      "id": 11683,
      "name": "Crotone",
      "names": {
        "zh": "克罗托内",
        "ja": "クロトーネ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.081512,
        "lng": 17.113831
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        17.093229,
        39.047432,
        17.145532,
        39.112552
      ]
    },
    {
      "id": 11684,
      "name": "Ghardimaou",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4505903,
        "lng": 8.4425342
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        8.4025342,
        36.4105903,
        8.4825342,
        36.4905903
      ]
    },
    {
      "id": 11685,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": -36.5986096,
        "lng": 144.6780052
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        140.9619017,
        -39.1984867,
        150.0332821,
        -33.9807974
      ]
    },
    {
      "id": 11686,
      "name": "Bamei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.2641305,
        "lng": 105.080964
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.040964,
        24.2241305,
        105.120964,
        24.3041305
      ]
    },
    {
      "id": 11687,
      "name": "Tuao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.7341301,
        "lng": 121.4555256
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4155256,
        17.6941301,
        121.4955256,
        17.7741301
      ]
    },
    {
      "id": 11688,
      "name": "Aliaga",
      "names": {
        "zh": "阿利阿"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5032754,
        "lng": 120.8455577
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7894334,
        15.4608562,
        120.932409,
        15.5651447
      ]
    },
    {
      "id": 11689,
      "name": "Grevenbroich",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0905783,
        "lng": 6.5835365
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.5192546,
        51.0183992,
        6.7149336,
        51.1537215
      ]
    },
    {
      "id": 11690,
      "name": "Nawa",
      "names": {
        "zh": "纳瓦",
        "ja": "名和",
        "th": "ยานนาวา"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8909237,
        "lng": 36.0396984
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        35.9996984,
        32.8509237,
        36.0796984,
        32.9309237
      ]
    },
    {
      "id": 11691,
      "name": "At Tall",
      "names": {
        "es": "En alto",
        "fr": "À hauteur",
        "zh": "高高",
        "de": "In großem Zustand",
        "it": "Alto",
        "id": "Dgn tinggi badan",
        "ja": "背の高い",
        "th": "สูง",
        "pt": "Em alto"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.61033,
        "lng": 36.3107
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.2907,
        33.59033,
        36.3307,
        33.63033
      ]
    },
    {
      "id": 11692,
      "name": "Asbest",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.0052746,
        "lng": 61.4580765
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        61.4103689,
        56.9528302,
        61.5760946,
        57.0948088
      ]
    },
    {
      "id": 11693,
      "name": "Povoa de Varzim",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.382198149999994,
        "lng": -8.75696452905552
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7754274,
        41.3703402,
        -8.7401019,
        41.3939518
      ]
    },
    {
      "id": 11694,
      "name": "Bandar-e Genaveh",
      "names": {
        "ja": "バンダルe Genaveh"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.5791,
        "lng": 50.517
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11695,
      "name": "Penedo",
      "names": {
        "zh": "佩内杜"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.2872341,
        "lng": -36.5819262
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.6599385,
        -10.4319467,
        -36.335,
        -10.0322699
      ]
    },
    {
      "id": 11696,
      "name": "Eastvale",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.9747067,
        "lng": -117.566541
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.6154857,
        33.9032322,
        -117.5488261,
        34.0334708
      ]
    },
    {
      "id": 11697,
      "name": "Sibalom",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7889049,
        "lng": 122.017534
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.9703949,
        10.6948077,
        122.20424,
        10.8541237
      ]
    },
    {
      "id": 11698,
      "name": "Kangbao",
      "names": {
        "zh": "康宝"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.84639,
        "lng": 114.61389
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.59389,
        41.82639,
        114.63389,
        41.86639
      ]
    },
    {
      "id": 11699,
      "name": "Mahdasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7282,
        "lng": 50.8134
      },
      "country": "Iran",
      "importance": 3,
      "bbox": [
        50.7934,
        35.7082,
        50.8334,
        35.7482
      ]
    },
    {
      "id": 11700,
      "name": "Maidenhead",
      "names": {
        "zh": "梅登黑德",
        "ja": "メーデンヘッド",
        "th": "สาว"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.522743,
        "lng": -0.7197368
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7597368,
        51.482743,
        -0.6797368,
        51.562743
      ]
    },
    {
      "id": 11701,
      "name": "Chapel Hill",
      "names": {
        "es": "Colina de la capilla",
        "fr": "Chapelle colline",
        "zh": "教堂山",
        "de": "Kapellenhügel",
        "id": "Bukit kapit",
        "ja": "チャペルヒル",
        "pt": "Colina de capela"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9131542,
        "lng": -79.05578
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.0831416,
        35.8694072,
        -78.9953895,
        35.976142
      ]
    },
    {
      "id": 11702,
      "name": "Armur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.787659,
        "lng": 78.292652
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.264507,
        18.771762,
        78.3203,
        18.80638
      ]
    },
    {
      "id": 11703,
      "name": "Rocky Mount",
      "names": {
        "es": "Monte rocoso",
        "fr": "Montage rocheux",
        "zh": "洛基山",
        "de": "Felsiger Berg",
        "it": "Monte roccioso",
        "id": "Mount",
        "ja": "ロッキーマウント",
        "th": "ร็อคกี้เมา",
        "pt": "Montagem rochosa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9423833,
        "lng": -77.7956949
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.9039401,
        35.901563,
        -77.735652,
        36.068373
      ]
    },
    {
      "id": 11704,
      "name": "Lugano",
      "names": {
        "zh": "卢加诺",
        "ja": "ルガノ",
        "th": "ลูกาโน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.0050102,
        "lng": 8.9520281
      },
      "country": "Switzerland",
      "importance": 3,
      "bbox": [
        8.8998475,
        45.9396811,
        9.0895508,
        46.1191527
      ]
    },
    {
      "id": 11705,
      "name": "Haverhill",
      "names": {
        "zh": "黑弗里尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7777829,
        "lng": -71.0767724
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.1823981,
        42.7324632,
        -70.9986019,
        42.8332765
      ]
    },
    {
      "id": 11706,
      "name": "Asahi",
      "names": {
        "zh": "朝日",
        "ja": "朝日",
        "th": "อาซาฮี"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7204126,
        "lng": 140.6464527
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.564718,
        35.485705,
        140.8052753,
        35.805846
      ]
    },
    {
      "id": 11707,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.050096,
        "lng": -121.99059
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.3220529,
        36.8488209,
        -121.5823066,
        37.2876789
      ]
    },
    {
      "id": 11708,
      "name": "Yangqingcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 21.3571,
        "lng": 110.12727
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11709,
      "name": "Tsuruga",
      "names": {
        "zh": "敦贺",
        "ja": "敦賀"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6445135,
        "lng": 136.0734634
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.8282676,
        35.526247,
        136.177517,
        35.8275044
      ]
    },
    {
      "id": 11710,
      "name": "Ponferrada",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 42.5454124,
        "lng": -6.5938719
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.7141113,
        42.3862245,
        -6.4071706,
        42.6127002
      ]
    },
    {
      "id": 11711,
      "name": "Jose Panganiban",
      "names": {
        "zh": "何塞普纳班班"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2906,
        "lng": 122.6917
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.6717,
        14.2706,
        122.7117,
        14.3106
      ]
    },
    {
      "id": 11712,
      "name": "San Jose de Bocay",
      "names": {
        "zh": "圣何塞德博节",
        "ja": "サンノゼ・デ・ボケイ",
        "th": "ซานโฮเซ่เดอโบไซค์"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.54204,
        "lng": -85.53942
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -85.55942,
        13.52204,
        -85.51942,
        13.56204
      ]
    },
    {
      "id": 11713,
      "name": "Vigevano",
      "names": {
        "zh": "维杰瓦诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.3127699,
        "lng": 8.8620595
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        8.8570595,
        45.3077699,
        8.8670595,
        45.3177699
      ]
    },
    {
      "id": 11714,
      "name": "Klintsy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.758511,
        "lng": 32.240097
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        32.1525541,
        52.7243891,
        32.3068287,
        52.7856265
      ]
    },
    {
      "id": 11715,
      "name": "Quimper",
      "names": {
        "zh": "坎佩尔",
        "ja": "カンペール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.9946982,
        "lng": -4.0922114
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -4.0972114,
        47.9896982,
        -4.0872114,
        47.9996982
      ]
    },
    {
      "id": 11716,
      "name": "Bognor Regis",
      "names": {
        "de": "Bissor Regis"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7834973,
        "lng": -0.6730718
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7006815,
        50.7777875,
        -0.6564726,
        50.8004888
      ]
    },
    {
      "id": 11717,
      "name": "Zvornik",
      "names": {
        "zh": "兹沃尔尼克"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.3834521,
        "lng": 19.10287925398021
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        19.1027756,
        44.3833307,
        19.1029829,
        44.3835734
      ]
    },
    {
      "id": 11718,
      "name": "Bhairahawa",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 27.5,
        "lng": 83.45
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        83.43,
        27.48,
        83.47,
        27.52
      ]
    },
    {
      "id": 11719,
      "name": "Farroupilha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.225196,
        "lng": -51.349382
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.381225,
        -29.269478,
        -51.29072,
        -29.193489
      ]
    },
    {
      "id": 11720,
      "name": "Nankana Sahib",
      "names": {
        "zh": "南亚纳萨希布",
        "ja": "南南サヒブ"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.450096,
        "lng": 73.708593
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        73.687707,
        31.435253,
        73.723993,
        31.466138
      ]
    },
    {
      "id": 11721,
      "name": "Turbaco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.3305871,
        "lng": -75.4127345
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4736078,
        10.2740231,
        -75.2758681,
        10.4181638
      ]
    },
    {
      "id": 11722,
      "name": "Woking",
      "names": {
        "zh": "沃金",
        "th": "โวกิงก์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3184492,
        "lng": -0.5573085
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.5623085,
        51.3134492,
        -0.5523085,
        51.3234492
      ]
    },
    {
      "id": 11723,
      "name": "Zarand",
      "names": {
        "zh": "扎兰德"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.8134822,
        "lng": 56.5672432
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        56.5045737,
        30.7739941,
        56.6429327,
        30.8657602
      ]
    },
    {
      "id": 11724,
      "name": "Kurihara",
      "names": {
        "zh": "栗原",
        "ja": "栗原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.7300697,
        "lng": 141.0209417
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.707363,
        38.6272112,
        141.166226,
        38.964599
      ]
    },
    {
      "id": 11725,
      "name": "Beloit",
      "names": {
        "zh": "伯洛伊特",
        "th": "เบลัวต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5083272,
        "lng": -89.031784
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.080945,
        42.4957743,
        -88.945065,
        42.568611
      ]
    },
    {
      "id": 11726,
      "name": "Guiglo",
      "names": {
        "zh": "吉格洛"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.54368,
        "lng": -7.4935
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -7.5135,
        6.52368,
        -7.4735,
        6.56368
      ]
    },
    {
      "id": 11727,
      "name": "Velika Gorica",
      "names": {
        "id": "Gorica velika"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6986438,
        "lng": 16.06963979650489
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.0308026,
        45.6415572,
        16.1014145,
        45.75601
      ]
    },
    {
      "id": 11728,
      "name": "Gloucester",
      "names": {
        "zh": "告士打道",
        "ja": "グロスター",
        "th": "กลอสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7267303,
        "lng": -75.1535378
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.4484527,
        39.514802,
        -74.877189,
        39.8879691
      ]
    },
    {
      "id": 11729,
      "name": "Escada",
      "names": {
        "zh": "埃斯卡达",
        "ja": "エスカーダ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.3623847,
        "lng": -35.2366232
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.3643458,
        -8.4894798,
        -35.1401484,
        -8.242849
      ]
    },
    {
      "id": 11730,
      "name": "Chincha Alta",
      "names": {
        "ja": "チンチャアルタ"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.417488,
        "lng": -76.1325726
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -76.1446072,
        -13.4423437,
        -75.9466329,
        -13.2127285
      ]
    },
    {
      "id": 11731,
      "name": "Graaff-Reinet",
      "names": {
        "zh": "格拉夫-里内特"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -32.248186,
        "lng": 24.537312
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        24.524868,
        -32.259289,
        24.551567,
        -32.234501
      ]
    },
    {
      "id": 11732,
      "name": "Moncada",
      "names": {
        "zh": "蒙卡达"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7336884,
        "lng": 120.5726987
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.481098,
        15.6929141,
        120.6036306,
        15.8191541
      ]
    },
    {
      "id": 11733,
      "name": "Wenxicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.08863,
        "lng": 115.84192
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11734,
      "name": "Budennovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.7860677,
        "lng": 44.1461786
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        44.1411786,
        44.7810677,
        44.1511786,
        44.7910677
      ]
    },
    {
      "id": 11735,
      "name": "Ain Sefra",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 32.7568151,
        "lng": -0.577859
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        -0.7709642,
        32.5687637,
        -0.3873128,
        33.0279642
      ]
    },
    {
      "id": 11736,
      "name": "Glens Falls",
      "names": {
        "es": "Gens Falls",
        "fr": "Les listes tombent",
        "zh": "格伦瀑布",
        "de": "Glens fällt",
        "ja": "グライスが落ちます",
        "th": "เกล็นส์ฟอลส์",
        "pt": "Glens cai"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.309941,
        "lng": -73.644447
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.674084,
        43.28931,
        -73.619442,
        43.327096
      ]
    },
    {
      "id": 11737,
      "name": "Salto de Agua",
      "names": {
        "ja": "サルタアグア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5537838,
        "lng": -92.337577
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.377577,
        17.5137838,
        -92.297577,
        17.5937838
      ]
    },
    {
      "id": 11738,
      "name": "Rolandia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3098652,
        "lng": -51.3701968
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.3702468,
        -23.3099152,
        -51.3701468,
        -23.3098152
      ]
    },
    {
      "id": 11739,
      "name": "Herten",
      "names": {
        "zh": "黑尔滕"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5942009,
        "lng": 7.1368071
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.0666089,
        51.5500387,
        7.1796999,
        51.6341591
      ]
    },
    {
      "id": 11740,
      "name": "Granby",
      "names": {
        "zh": "格兰比",
        "ja": "グランビー"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.3990409,
        "lng": -72.721834
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.8459047,
        45.3375585,
        -72.6633359,
        45.457917
      ]
    },
    {
      "id": 11741,
      "name": "Carrara",
      "names": {
        "zh": "卡拉拉",
        "ja": "カラーラ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.0771026,
        "lng": 10.096726
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        10.0187585,
        44.0266558,
        10.1711145,
        44.1297507
      ]
    },
    {
      "id": 11742,
      "name": "Shiroi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7914538,
        "lng": 140.0560632
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.015181,
        35.765476,
        140.1111,
        35.835735
      ]
    },
    {
      "id": 11743,
      "name": "Balboa Heights",
      "names": {
        "fr": "Hauteur de balba",
        "zh": "Balboa高度",
        "de": "Balboa-Höhen",
        "it": "Altezze Balboa",
        "ja": "バルボハイツ",
        "pt": "Alturas de balboa"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.949917,
        "lng": -79.551436
      },
      "country": "Panama",
      "importance": 3
    },
    {
      "id": 11744,
      "name": "Nago",
      "names": {
        "zh": "名护",
        "ja": "名護"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.5919599,
        "lng": 127.9774759
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.8856334,
        26.322966,
        128.3636681,
        26.6890302
      ]
    },
    {
      "id": 11745,
      "name": "Roeselare",
      "names": {
        "fr": "Roulers",
        "zh": "鲁瑟拉勒"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9444948,
        "lng": 3.124765
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.0619143,
        50.8870139,
        3.1784858,
        50.9940257
      ]
    },
    {
      "id": 11746,
      "name": "Chelm",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1339221,
        "lng": 23.4711548
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.4200375,
        51.0751299,
        23.5723681,
        51.167931
      ]
    },
    {
      "id": 11747,
      "name": "Santo Antonio do Descoberto",
      "names": {
        "de": "Santo Antonio do descaberto",
        "ja": "Santo Antonio descoberto",
        "th": "Santo Antonio Descoberto",
        "pt": "Santo Antonio do DescroBerto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.9465415,
        "lng": -48.2616527
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.4224862,
        -16.324,
        -48.0851373,
        -15.8186809
      ]
    },
    {
      "id": 11748,
      "name": "Abu Qurqas",
      "names": {
        "zh": "阿布古琴",
        "id": "Ajaran Abu Qurqas",
        "th": "abu Quraqas"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.930588,
        "lng": 30.8377108
      },
      "country": "Egypt",
      "importance": 3,
      "bbox": [
        30.7977108,
        27.890588,
        30.8777108,
        27.970588
      ]
    },
    {
      "id": 11749,
      "name": "Medicine Hat",
      "names": {
        "es": "Sombrero de medicina",
        "fr": "Chapeau de médecine",
        "zh": "医学帽",
        "de": "Medizinhut",
        "it": "Cappello di medicina",
        "id": "Topi obat",
        "ja": "薬の帽子",
        "th": "หมวกยา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 50.04303,
        "lng": -110.679016
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -110.8009864,
        49.9895477,
        -110.617509,
        50.1135772
      ]
    },
    {
      "id": 11750,
      "name": "Bannu",
      "names": {
        "zh": "班努"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.9857007,
        "lng": 70.6036017
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        70.4436017,
        32.8257007,
        70.7636017,
        33.1457007
      ]
    },
    {
      "id": 11751,
      "name": "Fairbanks",
      "names": {
        "zh": "费尔班克斯",
        "ja": "フェアバンクス",
        "th": "แฟร์แบงค์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 64.837845,
        "lng": -147.716675
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -147.813844,
        64.779792,
        -147.524419,
        64.878212
      ]
    },
    {
      "id": 11752,
      "name": "Volsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.04541,
        "lng": 47.37993
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        47.35993,
        52.02541,
        47.39993,
        52.06541
      ]
    },
    {
      "id": 11753,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0462362,
        "lng": -123.0220289
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.0501425,
        44.0235364,
        -122.8790537,
        44.094935
      ]
    },
    {
      "id": 11754,
      "name": "Kaga",
      "names": {
        "zh": "加贺",
        "ja": "加賀",
        "th": "กะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3027624,
        "lng": 136.3151159
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.0816502,
        36.136267,
        136.498722,
        36.5417042
      ]
    },
    {
      "id": 11755,
      "name": "Bethesda",
      "names": {
        "zh": "贝塞斯达",
        "ja": "ベテスダ",
        "th": "เบเทสดา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.98127255,
        "lng": -77.12335871396549
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.158561,
        38.9404011,
        -77.0771627,
        39.0224203
      ]
    },
    {
      "id": 11756,
      "name": "Novaya Balakhna",
      "names": {
        "id": "Novaya balakhhna"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.48989,
        "lng": 43.60114
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.58114,
        56.46989,
        43.62114,
        56.50989
      ]
    },
    {
      "id": 11757,
      "name": "Grande Prairie",
      "names": {
        "zh": "格兰德大草原",
        "id": "Pedagang grand",
        "ja": "グランデプレーリー",
        "th": "แกรนด์ทุ่งหญ้า",
        "pt": "Pradaria grande"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 55.17108,
        "lng": -118.7949873
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -118.961756,
        55.11247,
        -118.6795528,
        55.2361839
      ]
    },
    {
      "id": 11758,
      "name": "Koshizuka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 32.89308,
        "lng": 130.72511
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.70511,
        32.87308,
        130.74511,
        32.91308
      ]
    },
    {
      "id": 11759,
      "name": "Oudtshoorn",
      "names": {
        "zh": "奥茨胡恩"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.587167,
        "lng": 22.203189
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        22.183189,
        -33.607167,
        22.223189,
        -33.567167
      ]
    },
    {
      "id": 11760,
      "name": "Johnstown",
      "names": {
        "zh": "约翰斯敦",
        "ja": "ジョンズタウン",
        "th": "จอห์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.3267407,
        "lng": -78.9219698
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.955231,
        40.289821,
        -78.882329,
        40.364153
      ]
    },
    {
      "id": 11761,
      "name": "Bansalan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.78611,
        "lng": 125.21333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.19333,
        6.76611,
        125.23333,
        6.80611
      ]
    },
    {
      "id": 11762,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0283014,
        "lng": 120.6938038
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5942962,
        14.9932982,
        120.7278114,
        15.1342393
      ]
    },
    {
      "id": 11763,
      "name": "Lomza",
      "names": {
        "zh": "沃姆扎"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1750699,
        "lng": 22.0727551
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.0111571,
        53.1428996,
        22.1065965,
        53.2222435
      ]
    },
    {
      "id": 11764,
      "name": "Calinog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1225592,
        "lng": 122.5380376
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4980376,
        11.0825592,
        122.5780376,
        11.1625592
      ]
    },
    {
      "id": 11765,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 33.7494951,
        "lng": -117.873221
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -117.9439459,
        33.6916541,
        -117.8303495,
        33.7840133
      ]
    },
    {
      "id": 11766,
      "name": "Puerto Asis",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトアシス",
        "th": "เปอร์โตซ่าน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.4979751,
        "lng": -76.4951418
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.772313,
        0.2186597,
        -75.7727043,
        0.659915
      ]
    },
    {
      "id": 11767,
      "name": "West Hartford",
      "names": {
        "zh": "西哈特福德",
        "id": "Hartford Barat",
        "ja": "ウェストハートフォード"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7620447,
        "lng": -72.7420399
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.8006054,
        41.7184615,
        -72.7138229,
        41.8066305
      ]
    },
    {
      "id": 11768,
      "name": "Kasongo",
      "names": {
        "zh": "卡松戈"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -4.4283572,
        "lng": 26.6700323
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        26.6300323,
        -4.4683572,
        26.7100323,
        -4.3883572
      ]
    },
    {
      "id": 11769,
      "name": "Dundalk",
      "names": {
        "zh": "邓多克",
        "th": "ดอลก์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2574114,
        "lng": -76.5236745
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.6836745,
        39.0974114,
        -76.3636745,
        39.4174114
      ]
    },
    {
      "id": 11770,
      "name": "Castro Valley",
      "names": {
        "fr": "Vallée de Castro",
        "zh": "卡斯特罗山谷",
        "de": "Castro-Tal",
        "id": "Lembah Castro",
        "ja": "カストロバレー",
        "th": "หุบเขาคาสโตร",
        "pt": "Vale do Castro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.71360595,
        "lng": -122.07156581367964
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.1311307,
        37.6743784,
        -122.002232,
        37.752689
      ]
    },
    {
      "id": 11771,
      "name": "Sao Borja",
      "names": {
        "zh": "圣博尔贾",
        "ja": "サンボルヤ",
        "th": "เซาบอร์จา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.6592237,
        "lng": -56.0044295
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.3249856,
        -29.082499,
        -55.2909708,
        -28.3384988
      ]
    },
    {
      "id": 11772,
      "name": "Coon Rapids",
      "names": {
        "zh": "浣熊急流",
        "de": "COON RAPTS",
        "it": "Rapids Coon",
        "th": "คูน",
        "pt": "Rapids de Coon"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1728923,
        "lng": -93.3023898
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.373556,
        45.1187091,
        -93.265728,
        45.2129219
      ]
    },
    {
      "id": 11773,
      "name": "Pesqueira",
      "names": {
        "zh": "佩斯凯拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.361893,
        "lng": -36.696892
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.72241,
        -8.376527,
        -36.666916,
        -8.342075
      ]
    },
    {
      "id": 11774,
      "name": "Elmira",
      "names": {
        "zh": "埃尔迈拉"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0897965,
        "lng": -76.8077338
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.842136,
        42.064896,
        -76.776066,
        42.120104
      ]
    },
    {
      "id": 11775,
      "name": "Palmela",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.5661641,
        "lng": -8.9002605
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9003105,
        38.5661141,
        -8.9002105,
        38.5662141
      ]
    },
    {
      "id": 11776,
      "name": "Fujioka",
      "names": {
        "zh": "藤冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.258633,
        "lng": 139.0745021
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.810051,
        36.115601,
        139.11119,
        36.291195
      ]
    },
    {
      "id": 11777,
      "name": "Yurimaguas",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -5.9,
        "lng": -76.08333
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -76.10333,
        -5.92,
        -76.06333,
        -5.88
      ]
    },
    {
      "id": 11778,
      "name": "Deva",
      "names": {
        "zh": "天神",
        "ja": "デバ",
        "th": "เทพ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.8817402,
        "lng": 22.9008611
      },
      "country": "Romania",
      "importance": 3,
      "bbox": [
        22.847002,
        45.8013843,
        22.9754565,
        45.9101254
      ]
    },
    {
      "id": 11779,
      "name": "Nkawkaw",
      "names": {
        "zh": "恩考考"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.552795,
        "lng": -0.769811
      },
      "country": "Ghana",
      "importance": 3,
      "bbox": [
        -0.809811,
        6.512795,
        -0.729811,
        6.592795
      ]
    },
    {
      "id": 11780,
      "name": "Mankato",
      "names": {
        "zh": "曼凯托",
        "th": "แมนเคโท"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.161276,
        "lng": -93.987847
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.045978,
        44.126831,
        -93.905642,
        44.241635
      ]
    },
    {
      "id": 11781,
      "name": "Albany",
      "names": {
        "zh": "奥尔巴尼",
        "ja": "アルバニー",
        "th": "ออลบานี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.728072,
        "lng": -99.2946024
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -99.3099962,
        32.7143756,
        -99.2829082,
        32.7409236
      ]
    },
    {
      "id": 11782,
      "name": "Pavlovskiy Posad",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7813357,
        "lng": 38.6507838
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.6042285,
        55.7571614,
        38.7218966,
        55.8026863
      ]
    },
    {
      "id": 11783,
      "name": "Cruz Alta",
      "names": {
        "ja": "クルスアルタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.645056,
        "lng": -53.6058047
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.7977457,
        -28.95436,
        -53.2402789,
        -28.4807268
      ]
    },
    {
      "id": 11784,
      "name": "Rossosh",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.19828,
        "lng": 39.56726
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.54726,
        50.17828,
        39.58726,
        50.21828
      ]
    },
    {
      "id": 11785,
      "name": "Widnes",
      "names": {
        "zh": "威德尼斯",
        "th": "เนส"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3646888,
        "lng": -2.7283328
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.8188069,
        53.3355427,
        -2.6906324,
        53.4025372
      ]
    },
    {
      "id": 11786,
      "name": "Palin",
      "names": {
        "zh": "佩林",
        "ja": "ペイリン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4038394,
        "lng": -90.6971714
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.7371714,
        14.3638394,
        -90.6571714,
        14.4438394
      ]
    },
    {
      "id": 11787,
      "name": "Kapatagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.8959157,
        "lng": 123.7671411
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7271411,
        7.8559157,
        123.8071411,
        7.9359157
      ]
    },
    {
      "id": 11788,
      "name": "Camaqua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -30.85519785,
        "lng": -51.809828886155415
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.8100214,
        -30.8556641,
        -51.8094367,
        -30.8549411
      ]
    },
    {
      "id": 11789,
      "name": "Margate",
      "names": {
        "zh": "马盖特",
        "ja": "マーゲート"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.382062,
        "lng": 1.383178
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        1.319311,
        51.362393,
        1.436603,
        51.398749
      ]
    },
    {
      "id": 11790,
      "name": "Oriximina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.7669992,
        "lng": -55.868285
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -58.8953602,
        -1.998657,
        -55.5875587,
        2.5334471
      ]
    },
    {
      "id": 11791,
      "name": "Kolomyia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5259211,
        "lng": 25.0380538
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.9752793,
        48.5028759,
        25.0943408,
        48.5895777
      ]
    },
    {
      "id": 11792,
      "name": "Revda",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.8000651,
        "lng": 59.9086688
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        59.8689651,
        56.753812,
        60.0099459,
        56.8731324
      ]
    },
    {
      "id": 11793,
      "name": "Borisoglebsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.368015,
        "lng": 42.075169
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.0382421,
        51.3300111,
        42.1768039,
        51.4128151
      ]
    },
    {
      "id": 11794,
      "name": "Kotlas",
      "names": {
        "zh": "科特拉斯"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.252151,
        "lng": 46.647629
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        46.568949,
        61.1634118,
        46.8285225,
        61.2877843
      ]
    },
    {
      "id": 11795,
      "name": "Encinitas",
      "names": {
        "zh": "恩西尼塔斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.046236,
        "lng": -117.2930691
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2931191,
        33.046186,
        -117.2930191,
        33.046286
      ]
    },
    {
      "id": 11796,
      "name": "Zelenogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.11356,
        "lng": 94.589279
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        94.418966,
        56.0359335,
        94.8569894,
        56.1737642
      ]
    },
    {
      "id": 11797,
      "name": "Camboriu",
      "names": {
        "pt": "Camboriú"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.0273601,
        "lng": -48.6535657
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.812,
        -27.155,
        -48.6105708,
        -26.971
      ]
    },
    {
      "id": 11798,
      "name": "Shangtangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.48035,
        "lng": 113.35342
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.33342,
        22.46035,
        113.37342,
        22.50035
      ]
    },
    {
      "id": 11799,
      "name": "Leander",
      "names": {
        "zh": "莱安德",
        "it": "Leandro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5736134,
        "lng": -97.8567339
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.9460446,
        30.5114059,
        -97.7683267,
        30.6409336
      ]
    },
    {
      "id": 11800,
      "name": "Mobarakeh",
      "names": {
        "zh": "穆巴拉克"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3424135,
        "lng": 51.5046633
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.4439749,
        32.3211568,
        51.5540141,
        32.3834938
      ]
    },
    {
      "id": 11801,
      "name": "Greenwich",
      "names": {
        "zh": "格林威治",
        "ja": "グリニッジ",
        "th": "กรีนิช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0264862,
        "lng": -73.6284598
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.727811,
        40.950983,
        -73.5474869,
        41.1440481
      ]
    },
    {
      "id": 11802,
      "name": "Leninogorsk",
      "names": {
        "zh": "列宁诺戈尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5994324,
        "lng": 52.4469099
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        52.4008193,
        54.5629897,
        52.5224867,
        54.6203577
      ]
    },
    {
      "id": 11803,
      "name": "Wels",
      "names": {
        "zh": "韦尔斯",
        "ja": "ウェルス"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.1565472,
        "lng": 14.0243752
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.9635277,
        48.1258189,
        14.090759,
        48.2113139
      ]
    },
    {
      "id": 11804,
      "name": "Parkersburg",
      "names": {
        "zh": "帕克尔斯堡",
        "th": "พาร์เกอร์สบูร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2667309,
        "lng": -81.5620755
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.580832,
        39.204234,
        -81.502729,
        39.309381
      ]
    },
    {
      "id": 11805,
      "name": "Kayes",
      "names": {
        "zh": "卡耶斯"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.1682598,
        "lng": 13.2934684
      },
      "country": "Congo (Brazzaville)",
      "importance": 3,
      "bbox": [
        13.2734684,
        -4.1882598,
        13.3134684,
        -4.1482598
      ]
    },
    {
      "id": 11806,
      "name": "Tendo",
      "names": {
        "zh": "天童",
        "ja": "天童",
        "pt": "Tendão"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.3624343,
        "lng": 140.3772634
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.303665,
        38.310315,
        140.51491,
        38.405096
      ]
    },
    {
      "id": 11807,
      "name": "Menzel Bourguiba",
      "names": {
        "es": "Menzel Bourgguiba",
        "zh": "Menzel Bouruiba"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 37.15368,
        "lng": 9.78594
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        9.76594,
        37.13368,
        9.80594,
        37.17368
      ]
    },
    {
      "id": 11808,
      "name": "Villa Carlos Paz",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4183774,
        "lng": -64.4936985
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -64.5338652,
        -31.4666262,
        -64.4541793,
        -31.3808525
      ]
    },
    {
      "id": 11809,
      "name": "Waltham",
      "names": {
        "zh": "沃尔瑟姆",
        "ja": "ウォルサム",
        "th": "วอลแทม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3756401,
        "lng": -71.2358004
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.2860312,
        42.3536377,
        -71.1941707,
        42.4244463
      ]
    },
    {
      "id": 11810,
      "name": "Tanjungpandan",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.752473,
        "lng": 107.641823
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        107.596603,
        -2.803949,
        107.746414,
        -2.706315
      ]
    },
    {
      "id": 11811,
      "name": "Tefe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3485276,
        "lng": -64.7102278
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -66.973,
        -5.559,
        -64.071599,
        -3.2637231
      ]
    },
    {
      "id": 11812,
      "name": "Riosucio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4209549,
        "lng": -75.7030623
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8674039,
        5.3042218,
        -75.6099782,
        5.5635498
      ]
    },
    {
      "id": 11813,
      "name": "Tarnowskie Gory",
      "names": {
        "de": "Tarnowskie-Gory"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.44558,
        "lng": 18.861373
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.769885,
        50.400142,
        18.914251,
        50.531013
      ]
    },
    {
      "id": 11814,
      "name": "San Juan Chamelco",
      "names": {
        "zh": "圣胡安变色科",
        "ja": "サンファンカメルコ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4239493,
        "lng": -90.3298801
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.3698801,
        15.3839493,
        -90.2898801,
        15.4639493
      ]
    },
    {
      "id": 11815,
      "name": "Boryspil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.3512101,
        "lng": 30.95077
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.8988547,
        50.3179493,
        31.0076319,
        50.4135951
      ]
    },
    {
      "id": 11816,
      "name": "Caltanissetta",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 37.4899412,
        "lng": 14.0631618
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.8615324,
        37.3295285,
        14.1555644,
        37.5985157
      ]
    },
    {
      "id": 11817,
      "name": "Meihua",
      "names": {
        "zh": "梅花"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8882399,
        "lng": 114.8203495
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.7803495,
        37.8482399,
        114.8603495,
        37.9282399
      ]
    },
    {
      "id": 11818,
      "name": "Villach",
      "names": {
        "zh": "菲拉赫"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.6167284,
        "lng": 13.8500268
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.6936945,
        46.5584933,
        13.9792304,
        46.6683641
      ]
    },
    {
      "id": 11819,
      "name": "Port Charlotte",
      "names": {
        "zh": "夏洛特港",
        "ja": "ポートシャーロット",
        "th": "พอร์ตชาร์ลอตต์",
        "pt": "Porto charlotte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.982419999999998,
        "lng": -82.10688734598534
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.156289,
        26.946925,
        -82.057603,
        27.018022
      ]
    },
    {
      "id": 11820,
      "name": "Tuapse",
      "names": {
        "zh": "图阿普谢"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.0984747,
        "lng": 39.0718875
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.0355174,
        44.0752132,
        39.1190746,
        44.1479078
      ]
    },
    {
      "id": 11821,
      "name": "Palm Harbor",
      "names": {
        "es": "Palmero",
        "fr": "Portier",
        "zh": "棕榈港",
        "de": "Palmhafen",
        "it": "Porto di palma",
        "id": "Pelabuhan",
        "ja": "パームハーバー",
        "th": "ปาล์มฮาร์เบอร์",
        "pt": "Porto de palma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0856815,
        "lng": -82.75690699742961
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.84829,
        28.048667,
        -82.70877,
        28.123066
      ]
    },
    {
      "id": 11822,
      "name": "Puerto Penasco",
      "names": {
        "es": "Puerto Peñasco",
        "ja": "プエルトペナスコ",
        "th": "เปอร์โตเพนาสโก"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3170867,
        "lng": -113.531819
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -113.691819,
        31.1570867,
        -113.371819,
        31.4770867
      ]
    },
    {
      "id": 11823,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0474764,
        "lng": 121.6700916
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2666288,
        14.9966843,
        122.3835685,
        16.5023672
      ]
    },
    {
      "id": 11824,
      "name": "San Luis Obispo",
      "names": {
        "zh": "圣路易斯奥比斯波",
        "ja": "サンルイスオビスポ",
        "th": "ซานหลุยส์โอบิสโป"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2754698,
        "lng": -120.6551057
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.6551557,
        35.2754198,
        -120.6550557,
        35.2755198
      ]
    },
    {
      "id": 11825,
      "name": "Figueira da Foz",
      "names": {
        "ja": "figeira da foz"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1485808,
        "lng": -8.855655
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9092437,
        40.0125587,
        -8.6998755,
        40.3268809
      ]
    },
    {
      "id": 11826,
      "name": "Nahuala",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8432341,
        "lng": -91.3179046
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.3579046,
        14.8032341,
        -91.2779046,
        14.8832341
      ]
    },
    {
      "id": 11827,
      "name": "Bergheim",
      "names": {
        "zh": "贝格海姆",
        "th": "เบิร์กไฮม์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9540457,
        "lng": 6.6410004
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.5635471,
        50.9159819,
        6.7670468,
        51.0219049
      ]
    },
    {
      "id": 11828,
      "name": "Sebring",
      "names": {
        "zh": "赛百灵",
        "ja": "セブリング",
        "th": "ซีบริง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.4957453,
        "lng": -81.4410425
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.4992242,
        27.4126598,
        -81.4130568,
        27.5275339
      ]
    },
    {
      "id": 11829,
      "name": "Chistopol",
      "names": {
        "zh": "奇斯托波尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.362057,
        "lng": 50.634508
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        50.586861,
        55.346474,
        50.672279,
        55.386333
      ]
    },
    {
      "id": 11830,
      "name": "Itoman",
      "names": {
        "zh": "伊藤万"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.106017,
        "lng": 127.686066
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        127.4069246,
        25.8747136,
        127.8930823,
        26.1830086
      ]
    },
    {
      "id": 11831,
      "name": "Council Bluffs",
      "names": {
        "es": "Bluffs del consejo",
        "fr": "Le Conseil Bluffs",
        "zh": "理事会虚张声势",
        "de": "Ratsbluffs",
        "it": "Bluffs del Consiglio",
        "id": "Gertakan",
        "ja": "カウンシルブラフ",
        "th": "ป้านสภา",
        "pt": "Bluffs do Conselho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.258841,
        "lng": -95.8519484
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.9272495,
        41.1672536,
        -95.7510892,
        41.2907291
      ]
    },
    {
      "id": 11832,
      "name": "Penapolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.419983,
        "lng": -50.077457
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.108939,
        -21.452964,
        -50.048079,
        -21.390539
      ]
    },
    {
      "id": 11833,
      "name": "Itapecuru Mirim",
      "names": {
        "ja": "イタプセルミリム"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3973569,
        "lng": -44.3560724
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.5838083,
        -3.538,
        -44.038,
        -3.187
      ]
    },
    {
      "id": 11834,
      "name": "Mitoyo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1986856,
        "lng": 133.7179334
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.4698697,
        34.050192,
        133.823577,
        34.2989621
      ]
    },
    {
      "id": 11835,
      "name": "Ipira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.1578337,
        "lng": -39.7416605
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.140482,
        -12.5223852,
        -39.3973004,
        -11.8979189
      ]
    },
    {
      "id": 11836,
      "name": "Asker",
      "names": {
        "zh": "选择帮助提问者",
        "id": "Penanya",
        "ja": "アスカー",
        "th": "ถาม"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8353529,
        "lng": 10.4336047
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.3936047,
        59.7953529,
        10.4736047,
        59.8753529
      ]
    },
    {
      "id": 11837,
      "name": "Cambanugoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5413,
        "lng": 125.7553
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11838,
      "name": "Naro-Fominsk",
      "names": {
        "zh": "纳罗-fominsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.385498,
        "lng": 36.728899
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        36.71326,
        55.37338,
        36.75326,
        55.41338
      ]
    },
    {
      "id": 11839,
      "name": "Grajau",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.8154034,
        "lng": -46.1361454
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.469,
        -6.345,
        -45.3759228,
        -5.2707091
      ]
    },
    {
      "id": 11840,
      "name": "Morada Nova",
      "names": {
        "zh": "莫拉达新星",
        "ja": "モラダノバ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1076699,
        "lng": -38.3727852
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.7152468,
        -5.4425908,
        -38.2133382,
        -4.5006966
      ]
    },
    {
      "id": 11841,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2085354,
        "lng": -84.5501874
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.8203361,
        39.0215766,
        -84.2563973,
        39.3120806
      ]
    },
    {
      "id": 11842,
      "name": "Pacajus",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.171073,
        "lng": -38.464988
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.6194048,
        -4.2454331,
        -38.3818905,
        -4.1282485
      ]
    },
    {
      "id": 11843,
      "name": "Viareggio",
      "names": {
        "zh": "维亚雷焦",
        "ja": "ヴィアレッジョ",
        "th": "วีอาเรจโจ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8672392,
        "lng": 10.2506055
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        10.2273028,
        43.8155348,
        10.311737,
        43.8969069
      ]
    },
    {
      "id": 11844,
      "name": "Ferkessedougou",
      "names": {
        "zh": "费尔凯塞杜古"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 9.5928,
        "lng": -5.19449
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -5.21449,
        9.5728,
        -5.17449,
        9.6128
      ]
    },
    {
      "id": 11845,
      "name": "Baao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4531948,
        "lng": 123.3663867
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3263867,
        13.4131948,
        123.4063867,
        13.4931948
      ]
    },
    {
      "id": 11846,
      "name": "Jhargram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.4538505,
        "lng": 86.9949477
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        86.9549477,
        22.4138505,
        87.0349477,
        22.4938505
      ]
    },
    {
      "id": 11847,
      "name": "Moore",
      "names": {
        "zh": "摩尔",
        "ja": "ムーア",
        "th": "มัวร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3383254,
        "lng": -97.4867045
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.521142,
        35.290831,
        -97.414955,
        35.370604
      ]
    },
    {
      "id": 11848,
      "name": "Zaraza",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.35029,
        "lng": -65.32452
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -65.42452,
        9.25029,
        -65.22452,
        9.45029
      ]
    },
    {
      "id": 11849,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4249815,
        "lng": -62.0840299
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.130136,
        -31.4524976,
        -62.0579662,
        -31.3965343
      ]
    },
    {
      "id": 11850,
      "name": "Casa Grande",
      "names": {
        "ja": "カサグランデ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8772834,
        "lng": -111.7536818
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.91231,
        32.777503,
        -111.6365308,
        32.99662
      ]
    },
    {
      "id": 11851,
      "name": "Monessen",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.152066,
        "lng": -79.882372
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.904954,
        40.133021,
        -79.861391,
        40.165159
      ]
    },
    {
      "id": 11852,
      "name": "Ar Rastan",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.92653,
        "lng": 36.73238
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.71238,
        34.90653,
        36.75238,
        34.94653
      ]
    },
    {
      "id": 11853,
      "name": "San Antonio del Tachira",
      "names": {
        "id": "San Antonio del Takhira",
        "ja": "サンアントニオデルタチラ",
        "th": "ซานอันโตนิโอเดล Tachira"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.809267,
        "lng": -72.4444077
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -72.6044077,
        7.649267,
        -72.2844077,
        7.969267
      ]
    },
    {
      "id": 11854,
      "name": "Mielec",
      "names": {
        "zh": "梅莱茨"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.29170105,
        "lng": 21.430354448044582
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.4301667,
        50.2911225,
        21.4306297,
        50.2922826
      ]
    },
    {
      "id": 11855,
      "name": "Santo Domingo",
      "names": {
        "ja": "サントドミンゴ",
        "th": "ซานโตโดมิงโก"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 18.5646971,
        "lng": -69.85601175553414
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -70.1754535,
        18.4003265,
        -69.5148917,
        18.7291633
      ]
    },
    {
      "id": 11856,
      "name": "Polevskoy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.453733,
        "lng": 60.193024
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.156834,
        56.422195,
        60.223026,
        56.480694
      ]
    },
    {
      "id": 11857,
      "name": "Plato",
      "names": {
        "es": "Platón",
        "fr": "Platon",
        "zh": "柏拉图",
        "it": "Platone",
        "ja": "プラトン",
        "th": "เพลโต",
        "pt": "Platão"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.7904163,
        "lng": -74.7820016
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8368813,
        9.5080854,
        -74.4052131,
        10.0335819
      ]
    },
    {
      "id": 11858,
      "name": "Satbayev",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.90487365,
        "lng": 67.53590632715998
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        67.5040581,
        47.8874644,
        67.5668286,
        47.9210721
      ]
    },
    {
      "id": 11859,
      "name": "Piripiri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2732339,
        "lng": -41.7785173
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.0274176,
        -4.5700153,
        -41.4971514,
        -4.145374
      ]
    },
    {
      "id": 11860,
      "name": "Sarandi",
      "names": {
        "zh": "萨兰迪"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.680758,
        "lng": -58.3463289
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.3681627,
        -34.6964074,
        -58.3094308,
        -34.6581885
      ]
    },
    {
      "id": 11861,
      "name": "Hita",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3211655,
        "lng": 130.9411316
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.8247501,
        33.022923,
        131.09069,
        33.457677
      ]
    },
    {
      "id": 11862,
      "name": "Aisai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.142205,
        "lng": 136.727208
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.671033,
        35.116069,
        136.773497,
        35.235895
      ]
    },
    {
      "id": 11863,
      "name": "Santo Amaro",
      "names": {
        "ja": "サントアマロ",
        "th": "ซานโตอามาโร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.5519686,
        "lng": -38.7060448
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.8811514,
        -12.7271099,
        -38.6443264,
        -12.368
      ]
    },
    {
      "id": 11864,
      "name": "Camabatela",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.188669,
        "lng": 15.3742111
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        15.3342111,
        -8.228669,
        15.4142111,
        -8.148669
      ]
    },
    {
      "id": 11865,
      "name": "Lysva",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.09963345,
        "lng": 57.796450638987736
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        57.6428556,
        57.9529886,
        57.9766052,
        58.1344872
      ]
    },
    {
      "id": 11866,
      "name": "Trikala",
      "names": {
        "zh": "特里卡拉"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.5560869,
        "lng": 21.7678844
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.6078844,
        39.3960869,
        21.9278844,
        39.7160869
      ]
    },
    {
      "id": 11867,
      "name": "Coroata",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.1282283,
        "lng": -44.1360269
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.463,
        -4.494,
        -43.895,
        -3.836
      ]
    },
    {
      "id": 11868,
      "name": "Xinmin",
      "names": {
        "zh": "新民"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.284262,
        "lng": 104.247067
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        104.227067,
        30.264262,
        104.267067,
        30.304262
      ]
    },
    {
      "id": 11869,
      "name": "Fairfield",
      "names": {
        "zh": "费尔菲尔德",
        "ja": "フェアフィールド",
        "th": "แฟร์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7886111,
        "lng": -82.6418883
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.8429374,
        39.5543991,
        -82.3660635,
        39.9410645
      ]
    },
    {
      "id": 11870,
      "name": "Friedrichshafen",
      "names": {
        "zh": "腓特烈港"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6500279,
        "lng": 9.4800858
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        9.3815131,
        47.6378052,
        9.560667,
        47.7378725
      ]
    },
    {
      "id": 11871,
      "name": "Rubizhne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0329045,
        "lng": 38.3725819
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        38.3329985,
        48.9687333,
        38.4372139,
        49.0583629
      ]
    },
    {
      "id": 11872,
      "name": "St. Thomas",
      "names": {
        "es": "Santo Tomás",
        "fr": "Saint-Thomas",
        "zh": "圣托马斯",
        "ja": "セントトーマス",
        "th": "เซนต์โทมัส"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 18.34290815,
        "lng": -64.91889970060265
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -65.0421288,
        18.3024284,
        -64.8317848,
        18.3833342
      ]
    },
    {
      "id": 11873,
      "name": "Orion",
      "names": {
        "es": "Orión",
        "zh": "猎户",
        "ja": "オリオン",
        "th": "นายพราน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.62157,
        "lng": 120.5772317
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.4790659,
        14.5586544,
        120.7129305,
        14.6875617
      ]
    },
    {
      "id": 11874,
      "name": "Schwabisch Gmund",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7999036,
        "lng": 9.7977584
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        9.7135633,
        48.7145541,
        9.9435797,
        48.8444338
      ]
    },
    {
      "id": 11875,
      "name": "Sibay",
      "names": {
        "zh": "锡拜"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.684241,
        "lng": 58.659405
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        58.639137,
        52.678696,
        58.699806,
        52.728474
      ]
    },
    {
      "id": 11876,
      "name": "Sawahlunto",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.6818141,
        "lng": 100.778552
      },
      "country": "Indonesia",
      "importance": 3,
      "bbox": [
        100.687534,
        -0.772782,
        100.8135734,
        -0.5477222
      ]
    },
    {
      "id": 11877,
      "name": "Cabatuan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8798603,
        "lng": 122.4819482
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4419482,
        10.8398603,
        122.5219482,
        10.9198603
      ]
    },
    {
      "id": 11878,
      "name": "Itogon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.36389,
        "lng": 120.67694
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.65694,
        16.34389,
        120.69694,
        16.38389
      ]
    },
    {
      "id": 11879,
      "name": "Wanparti",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.361816,
        "lng": 78.061578
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.043281,
        16.344972,
        78.08291,
        16.371936
      ]
    },
    {
      "id": 11880,
      "name": "Airdrie",
      "names": {
        "zh": "艾尔德里",
        "th": "รีย"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 51.28597,
        "lng": -114.01062
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -114.083782,
        51.2414792,
        -113.9234767,
        51.3293542
      ]
    },
    {
      "id": 11881,
      "name": "Djibo",
      "names": {
        "zh": "吉博"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 14.1023225,
        "lng": -1.6318243
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -1.7993241,
        13.9791751,
        -1.3810775,
        14.3446349
      ]
    },
    {
      "id": 11882,
      "name": "Iztapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 13.93333,
        "lng": -90.7075
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.7275,
        13.91333,
        -90.6875,
        13.95333
      ]
    },
    {
      "id": 11883,
      "name": "Cajazeiras",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.889155,
        "lng": -38.56129
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.588957,
        -6.909405,
        -38.525466,
        -6.866139
      ]
    },
    {
      "id": 11884,
      "name": "Rome",
      "names": {
        "es": "Roma",
        "zh": "罗马",
        "de": "Rom",
        "it": "Roma",
        "id": "Roma",
        "ja": "ローマ",
        "th": "โรม",
        "pt": "Roma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.257038,
        "lng": -85.1646726
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.278941,
        34.193159,
        -85.112246,
        34.3565
      ]
    },
    {
      "id": 11885,
      "name": "Hato Mayor",
      "names": {
        "es": "Hato alcalde",
        "fr": "Chapeau maire",
        "de": "Hato Bürgermeister",
        "it": "Mayor hato",
        "id": "Walikota Hato",
        "ja": "市長鳩",
        "th": "นายกเทศมนตรี Hato"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.76278,
        "lng": -69.25681
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -69.27681,
        18.74278,
        -69.23681,
        18.78278
      ]
    },
    {
      "id": 11886,
      "name": "Garbsen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.41748455,
        "lng": 9.596572902055417
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        9.5960387,
        52.4173928,
        9.5971109,
        52.4175748
      ]
    },
    {
      "id": 11887,
      "name": "Ararangua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.9348832,
        "lng": -49.4858389
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.6233507,
        -29.0583494,
        -49.2901932,
        -28.833
      ]
    },
    {
      "id": 11888,
      "name": "Fano",
      "names": {
        "zh": "法诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8428467,
        "lng": 13.0245099
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        13.0195099,
        43.8378467,
        13.0295099,
        43.8478467
      ]
    },
    {
      "id": 11889,
      "name": "Millcreek",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.6992918,
        "lng": -111.855583
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.921104,
        40.660221,
        -111.7768096,
        40.7143706
      ]
    },
    {
      "id": 11890,
      "name": "Sanford",
      "names": {
        "zh": "桑福德",
        "ja": "サンフォード",
        "th": "แซนฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.8117345,
        "lng": -81.2680223
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.347073,
        28.738969,
        -81.203167,
        28.829248
      ]
    },
    {
      "id": 11891,
      "name": "Slutsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0277354,
        "lng": 27.5519747
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.4730436,
        52.9821905,
        27.6261371,
        53.0580289
      ]
    },
    {
      "id": 11892,
      "name": "Rio do Sul",
      "names": {
        "zh": "里约热内卢",
        "it": "Rio dole",
        "ja": "リオデルスル"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.2162607,
        "lng": -49.643654
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.717734,
        -27.29129,
        -49.5272562,
        -27.0800391
      ]
    },
    {
      "id": 11893,
      "name": "Montauban",
      "names": {
        "zh": "蒙托邦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0175835,
        "lng": 1.3549991
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.2865419,
        43.9512579,
        1.4427914,
        44.0922122
      ]
    },
    {
      "id": 11894,
      "name": "Vacaria",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.5102901,
        "lng": -50.9356044
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.1597161,
        -28.7902095,
        -50.7079956,
        -27.9406345
      ]
    },
    {
      "id": 11895,
      "name": "Hurth",
      "names": {
        "es": "Hürth",
        "zh": "胡尔特",
        "it": "Hürth",
        "id": "Hürth"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8807379,
        "lng": 6.876568
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.7764643,
        50.8368754,
        6.9232481,
        50.9115756
      ]
    },
    {
      "id": 11896,
      "name": "Mayaguez",
      "names": {
        "es": "Mayagüez",
        "zh": "马亚圭斯"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.2011161,
        "lng": -67.1391124
      },
      "country": "Puerto Rico",
      "importance": 3,
      "bbox": [
        -67.2991124,
        18.0411161,
        -66.9791124,
        18.3611161
      ]
    },
    {
      "id": 11897,
      "name": "Tigaon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.6303411,
        "lng": 123.4965413
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4565413,
        13.5903411,
        123.5365413,
        13.6703411
      ]
    },
    {
      "id": 11898,
      "name": "Burnsville",
      "names": {
        "zh": "伯恩斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.7670567,
        "lng": -93.2773887
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.329828,
        44.7174482,
        -93.2207564,
        44.8290621
      ]
    },
    {
      "id": 11899,
      "name": "Abuyog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7467715,
        "lng": 125.0120993
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.91824,
        10.5095127,
        125.2617093,
        10.8086586
      ]
    },
    {
      "id": 11900,
      "name": "Acarau",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.8869514,
        "lng": -40.1194713
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.3701971,
        -3.3157229,
        -39.9076088,
        -2.8051559
      ]
    },
    {
      "id": 11901,
      "name": "Morristown",
      "names": {
        "zh": "莫里斯敦",
        "th": "มอร์ริส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.2139814,
        "lng": -83.2948923
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.4291633,
        36.133351,
        -83.1882241,
        36.2731434
      ]
    },
    {
      "id": 11902,
      "name": "Halton Hills",
      "names": {
        "zh": "哈尔顿山",
        "id": "Bukit Halton",
        "ja": "ハロンヒルズ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.621861,
        "lng": -79.943877
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -80.08578,
        43.524577,
        -79.787918,
        43.734431
      ]
    },
    {
      "id": 11903,
      "name": "Tulunan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.83444,
        "lng": 124.87694
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.85694,
        6.81444,
        124.89694,
        6.85444
      ]
    },
    {
      "id": 11904,
      "name": "Reston",
      "names": {
        "zh": "雷斯顿",
        "ja": "レストン",
        "th": "เรสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.953055899999995,
        "lng": -77.35021396591173
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.393259,
        38.908241,
        -77.308866,
        38.9982314
      ]
    },
    {
      "id": 11905,
      "name": "Nahariyya",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 33.00586,
        "lng": 35.09409
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        35.07409,
        32.98586,
        35.11409,
        33.02586
      ]
    },
    {
      "id": 11906,
      "name": "Obando",
      "names": {
        "zh": "奥班多"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.70989,
        "lng": 120.9365149
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8875279,
        14.687903,
        120.9524492,
        14.7494074
      ]
    },
    {
      "id": 11907,
      "name": "Chamrajnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.9269937,
        "lng": 76.9424311
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        76.9423811,
        11.9269437,
        76.9424811,
        11.9270437
      ]
    },
    {
      "id": 11908,
      "name": "Idiofa",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.9699217,
        "lng": 19.5885243
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        19.4285243,
        -5.1299217,
        19.7485243,
        -4.8099217
      ]
    },
    {
      "id": 11909,
      "name": "Skhirate",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.850672,
        "lng": -7.028026
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.1255337,
        33.8139667,
        -6.9916822,
        33.8954482
      ]
    },
    {
      "id": 11910,
      "name": "Navegantes",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.8898813,
        "lng": -48.6495828
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.8242083,
        -26.9138091,
        -48.619902,
        -26.74
      ]
    },
    {
      "id": 11911,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4167644,
        "lng": 120.8553541
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7308371,
        14.3990718,
        120.8783481,
        14.5157481
      ]
    },
    {
      "id": 11912,
      "name": "Campo Bom",
      "names": {
        "id": "Capo bom",
        "pt": "Mamadeira"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.678418,
        "lng": -51.0572268
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.0952771,
        -29.7152136,
        -50.9961748,
        -29.61581
      ]
    },
    {
      "id": 11913,
      "name": "Tabatinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2327349,
        "lng": -69.9263372
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -69.9500711,
        -4.3811084,
        -69.3928235,
        -3.6866846
      ]
    },
    {
      "id": 11914,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.1717649,
        "lng": -122.518458
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.579423,
        47.118058,
        -122.4644599,
        47.1981421
      ]
    },
    {
      "id": 11915,
      "name": "Yongyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.07,
        "lng": 107.469
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        107.429,
        27.03,
        107.509,
        27.11
      ]
    },
    {
      "id": 11916,
      "name": "Jinoba-an",
      "names": {
        "es": "Jinoba-un",
        "fr": "Jinoba-un",
        "zh": "jinoba-一个",
        "it": "Jinoba un-",
        "ja": "jinoba-た",
        "th": "jinoba ข้อ",
        "pt": "Jinoba-um"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6009,
        "lng": 122.4684
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.4484,
        9.5809,
        122.4884,
        9.6209
      ]
    },
    {
      "id": 11917,
      "name": "Acerra",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.945325,
        "lng": 14.371507
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.350253,
        40.932719,
        14.390342,
        40.966331
      ]
    },
    {
      "id": 11918,
      "name": "Malay",
      "names": {
        "zh": "马来",
        "id": "Melayu",
        "ja": "マレー語",
        "th": "มาเลย์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.9000941,
        "lng": 121.9099
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.7666558,
        11.8272396,
        122.0087396,
        12.0363303
      ]
    },
    {
      "id": 11919,
      "name": "Hamden",
      "names": {
        "zh": "哈姆登"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3959302,
        "lng": -72.8968574
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.9754604,
        41.3267341,
        -72.8513812,
        41.4643531
      ]
    },
    {
      "id": 11920,
      "name": "Spring",
      "names": {
        "es": "Primavera",
        "fr": "Printemps",
        "zh": "春天",
        "de": "Frühling",
        "it": "Primavera",
        "ja": "春",
        "th": "ฤดูใบไม้ผลิ",
        "pt": "PRIMAVERA"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0795707,
        "lng": -95.4187924
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.4587924,
        30.0395707,
        -95.3787924,
        30.1195707
      ]
    },
    {
      "id": 11921,
      "name": "Castilla",
      "names": {
        "zh": "卡斯蒂利亚",
        "ja": "カスティーリャ",
        "th": "กัสตียา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9539515,
        "lng": 123.8751711
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6615444,
        12.7351673,
        123.9118899,
        13.0436104
      ]
    },
    {
      "id": 11922,
      "name": "Tamba",
      "names": {
        "zh": "坦巴",
        "ja": "丹波"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1771828,
        "lng": 135.0363959
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.899326,
        35.046746,
        135.228643,
        35.308746
      ]
    },
    {
      "id": 11923,
      "name": "Gadsden",
      "names": {
        "zh": "加兹登"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0128323,
        "lng": -86.0030251
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.1102998,
        33.9464326,
        -85.9346058,
        34.079336
      ]
    },
    {
      "id": 11924,
      "name": "Taylor",
      "names": {
        "zh": "泰勒",
        "ja": "テイラー",
        "th": "เทย์เลอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.240872,
        "lng": -83.2696509
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.3090035,
        42.1812831,
        -83.2281423,
        42.2698921
      ]
    },
    {
      "id": 11925,
      "name": "Chalkida",
      "names": {
        "it": "Calcide"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.4623664,
        "lng": 23.5946877
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.4346877,
        38.3023664,
        23.7546877,
        38.6223664
      ]
    },
    {
      "id": 11926,
      "name": "Novi",
      "names": {
        "zh": "诺维",
        "ja": "ノビ",
        "th": "โนวี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.48059,
        "lng": -83.4754913
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.5552093,
        42.4351845,
        -83.4336713,
        42.527114
      ]
    },
    {
      "id": 11927,
      "name": "Nambuangongo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.0178739,
        "lng": 14.2009745
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        14.1809745,
        -8.0378739,
        14.2209745,
        -7.9978739
      ]
    },
    {
      "id": 11928,
      "name": "Marietta",
      "names": {
        "zh": "玛丽埃塔",
        "ja": "マリエッタ",
        "th": "มารีเอตตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9528472,
        "lng": -84.5496148
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.616806,
        33.8955874,
        -84.467938,
        34.00117
      ]
    },
    {
      "id": 11929,
      "name": "Sennan",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3657277,
        "lng": 135.2739742
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.0696768,
        34.299244,
        135.340382,
        34.5222709
      ]
    },
    {
      "id": 11930,
      "name": "Villamaria",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0449697,
        "lng": -75.5151258
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5259038,
        5.020896,
        -75.4779155,
        5.0521858
      ]
    },
    {
      "id": 11931,
      "name": "Laoang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5688537,
        "lng": 125.0152917
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.9752917,
        12.5288537,
        125.0552917,
        12.6088537
      ]
    },
    {
      "id": 11932,
      "name": "Wesel",
      "names": {
        "zh": "韦瑟尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6576909,
        "lng": 6.617087
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.4603134,
        51.6162996,
        6.7137325,
        51.7236065
      ]
    },
    {
      "id": 11933,
      "name": "Tartagal",
      "names": {
        "zh": "塔塔加尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.5194493,
        "lng": -63.7986328
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.8206228,
        -22.5481125,
        -63.7484464,
        -22.4940205
      ]
    },
    {
      "id": 11934,
      "name": "Shihe",
      "names": {
        "zh": "石河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9896829,
        "lng": 119.7065029
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        119.616084,
        39.9155049,
        119.8042611,
        40.1111974
      ]
    },
    {
      "id": 11935,
      "name": "Dongcun",
      "names": {
        "zh": "东村"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2766778,
        "lng": 111.6726048
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.6326048,
        38.2366778,
        111.7126048,
        38.3166778
      ]
    },
    {
      "id": 11936,
      "name": "Matanao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.709707,
        "lng": 125.2170514
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.0787018,
        6.5357557,
        125.2938044,
        6.8012825
      ]
    },
    {
      "id": 11937,
      "name": "Hot Springs",
      "names": {
        "es": "Aguas termales",
        "fr": "Sources d'eau chaude",
        "zh": "温泉",
        "de": "Heiße Quellen",
        "it": "Sorgenti calde",
        "id": "Air panas",
        "ja": "熱水泉",
        "th": "น้ำพุร้อน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5038393,
        "lng": -93.0552437
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.1290543,
        34.4264361,
        -92.9850972,
        34.5594048
      ]
    },
    {
      "id": 11938,
      "name": "Koga",
      "names": {
        "zh": "古贺",
        "ja": "古賀"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.73333,
        "lng": 130.46667
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.44667,
        33.71333,
        130.48667,
        33.75333
      ]
    },
    {
      "id": 11939,
      "name": "Gubat",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9176112,
        "lng": 124.1231882
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.0831882,
        12.8776112,
        124.1631882,
        12.9576112
      ]
    },
    {
      "id": 11940,
      "name": "Belchatow",
      "names": {
        "zh": "贝乌哈图夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.3650175,
        "lng": 19.3691833
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.3065472,
        51.3158054,
        19.4188582,
        51.3933533
      ]
    },
    {
      "id": 11941,
      "name": "Stralsund",
      "names": {
        "zh": "斯特拉尔松",
        "ja": "シュトラールズント",
        "th": "เนส"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.3096314,
        "lng": 13.0820846
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.99386,
        54.245042,
        13.1781757,
        54.3475665
      ]
    },
    {
      "id": 11942,
      "name": "Igarape-Miri",
      "names": {
        "zh": "igarape-里",
        "ja": "igarape-ミリ",
        "th": "igarape-มีรี",
        "pt": "Igarapé-Miri"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.976983,
        "lng": -48.956375
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.974104,
        -1.991268,
        -48.94296,
        -1.96205
      ]
    },
    {
      "id": 11943,
      "name": "Zamora",
      "names": {
        "zh": "萨莫拉",
        "ja": "サモラ",
        "th": "ซาโมร่า"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5056332,
        "lng": -5.744755
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -5.9775295,
        41.4584955,
        -5.6353421,
        41.5741598
      ]
    },
    {
      "id": 11944,
      "name": "Druzhkivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6198614,
        "lng": 37.524945
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        37.4654714,
        48.5874117,
        37.6009145,
        48.6647802
      ]
    },
    {
      "id": 11945,
      "name": "Batatais",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.896663,
        "lng": -47.585634
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.617964,
        -20.932829,
        -47.554388,
        -20.872484
      ]
    },
    {
      "id": 11946,
      "name": "Autlan de Navarro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.763944,
        "lng": -104.364803
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.385895,
        19.736656,
        -104.34524,
        19.799655
      ]
    },
    {
      "id": 11947,
      "name": "Savona",
      "names": {
        "zh": "萨沃纳"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.3080251,
        "lng": 8.4810315
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.3426498,
        44.2810853,
        8.5049969,
        44.3881026
      ]
    },
    {
      "id": 11948,
      "name": "Woodland",
      "names": {
        "es": "Bosques",
        "fr": "Bois",
        "zh": "林地",
        "de": "Wald",
        "ja": "森林",
        "th": "ป่าไม้",
        "pt": "Floresta"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6786109,
        "lng": -121.7733285
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.8027558,
        38.6375982,
        -121.6854192,
        38.70934
      ]
    },
    {
      "id": 11949,
      "name": "Matera",
      "names": {
        "zh": "马泰拉",
        "ja": "マテーラ",
        "th": "มาเตรา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.667029,
        "lng": 16.6063265
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        16.4127776,
        40.5674143,
        16.7290836,
        40.764532
      ]
    },
    {
      "id": 11950,
      "name": "Greifswald",
      "names": {
        "zh": "格赖夫斯瓦尔德",
        "ja": "グライフスヴァルト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.0917801,
        "lng": 13.3719087
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.3718587,
        54.0917301,
        13.3719587,
        54.0918301
      ]
    },
    {
      "id": 11951,
      "name": "Sao Miguel dos Campos",
      "names": {
        "zh": "圣米格尔DOS坎波斯",
        "ja": "サンミゲルドスカンポス",
        "th": "เซามิเกล Dos Campos",
        "pt": "São Miguel dos Campos"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.7831115,
        "lng": -36.0967804
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.2471417,
        -9.882,
        -35.9874597,
        -9.6735191
      ]
    },
    {
      "id": 11952,
      "name": "Hashimoto",
      "names": {
        "zh": "桥本",
        "ja": "橋本"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3184138,
        "lng": 135.6146094
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.6096094,
        34.3134138,
        135.6196094,
        34.3234138
      ]
    },
    {
      "id": 11953,
      "name": "Kumertau",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.763345,
        "lng": 55.789385
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        55.771252,
        52.750526,
        55.828388,
        52.787498
      ]
    },
    {
      "id": 11954,
      "name": "Ponnuru",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.0604569,
        "lng": 80.5280283
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.526356,
        16.0587029,
        80.529706,
        16.062412
      ]
    },
    {
      "id": 11955,
      "name": "Paracale",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2793266,
        "lng": 122.7868453
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.6406085,
        14.1991831,
        122.8927627,
        14.6719042
      ]
    },
    {
      "id": 11956,
      "name": "Yongbei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.6889408,
        "lng": 100.7463074
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.7063074,
        26.6489408,
        100.7863074,
        26.7289408
      ]
    },
    {
      "id": 11957,
      "name": "Rzhev",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2565207,
        "lng": 34.328649
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        34.2600829,
        56.227656,
        34.4505861,
        56.3008165
      ]
    },
    {
      "id": 11958,
      "name": "Molfetta",
      "names": {
        "zh": "莫尔费塔"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1992456,
        "lng": 16.5968279
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        16.5086286,
        41.1381323,
        16.6402521,
        41.2294795
      ]
    },
    {
      "id": 11959,
      "name": "Belladere",
      "names": {},
      "countryCode": "HT",
      "latLng": {
        "lat": 18.8660303,
        "lng": -71.7636303
      },
      "country": "Haiti",
      "importance": 3,
      "bbox": [
        -71.8036303,
        18.8260303,
        -71.7236303,
        18.9060303
      ]
    },
    {
      "id": 11960,
      "name": "Sao Gabriel",
      "names": {
        "zh": "圣加布里埃尔",
        "it": "Sao gabriele",
        "ja": "サンガブルエル",
        "th": "เซากาเบรียล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.334214,
        "lng": -54.3227149
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.8783378,
        -30.7768286,
        -53.8692405,
        -29.8927085
      ]
    },
    {
      "id": 11961,
      "name": "Mossel Bay",
      "names": {
        "fr": "Baie de Mossel",
        "zh": "Mossel湾",
        "de": "Mossel-Bucht",
        "it": "Baia di Mossel",
        "id": "Teluk Mossel",
        "ja": "モソ湾",
        "th": "อ่าวมอสเซล",
        "pt": "Bela mossel"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -34.181188,
        "lng": 22.1406853
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        22.1406353,
        -34.181238,
        22.1407353,
        -34.181138
      ]
    },
    {
      "id": 11962,
      "name": "Bensalem",
      "names": {
        "zh": "本萨勒"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1045549,
        "lng": -74.951279
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.9939921,
        40.0498029,
        -74.8983439,
        40.1557786
      ]
    },
    {
      "id": 11963,
      "name": "Medellin",
      "names": {
        "es": "Medellín",
        "zh": "麦德林",
        "ja": "メデイン",
        "pt": "Medellín"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1283811,
        "lng": 123.9615625
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.9215625,
        11.0883811,
        124.0015625,
        11.1683811
      ]
    },
    {
      "id": 11964,
      "name": "Olbia",
      "names": {
        "zh": "奥尔比亚",
        "ja": "オルビア",
        "th": "โอลเบีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9232659,
        "lng": 9.5027442
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.3480368,
        40.7314116,
        9.7793564,
        41.0585348
      ]
    },
    {
      "id": 11965,
      "name": "Xo`jayli Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.40881,
        "lng": 59.44544
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        59.42544,
        42.38881,
        59.46544,
        42.42881
      ]
    },
    {
      "id": 11966,
      "name": "Offenburg",
      "names": {
        "zh": "奥芬堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4716556,
        "lng": 7.944378
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.8616433,
        48.3968238,
        8.0284868,
        48.5526917
      ]
    },
    {
      "id": 11967,
      "name": "Sayanogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.093597,
        "lng": 91.392212
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        91.367889,
        53.079117,
        91.413094,
        53.108482
      ]
    },
    {
      "id": 11968,
      "name": "Lakhdaria",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.56463,
        "lng": 3.5933
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        3.5733,
        36.54463,
        3.6133,
        36.58463
      ]
    },
    {
      "id": 11969,
      "name": "Maimbung",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.93333,
        "lng": 121.03333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.93333,
        5.83333,
        121.13333,
        6.03333
      ]
    },
    {
      "id": 11970,
      "name": "San Vicente del Raspeig",
      "names": {
        "de": "San Vicente del Raspäus",
        "ja": "サンビセンテデルラスペヒ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.3964248,
        "lng": -0.5250339
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.5950592,
        38.3800166,
        -0.4847081,
        38.4809433
      ]
    },
    {
      "id": 11971,
      "name": "Langenfeld",
      "names": {
        "zh": "朗根费尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1027227,
        "lng": 6.9381175
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.9331175,
        51.0977227,
        6.9431175,
        51.1077227
      ]
    },
    {
      "id": 11972,
      "name": "Yame",
      "names": {
        "zh": "八女"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2116721,
        "lng": 130.5579706
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.5169856,
        33.103975,
        130.8874971,
        33.306572
      ]
    },
    {
      "id": 11973,
      "name": "Esmeraldas",
      "names": {
        "zh": "埃斯梅拉达斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.7613111,
        "lng": -44.3145945
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.5106582,
        -19.9459561,
        -44.133,
        -19.577
      ]
    },
    {
      "id": 11974,
      "name": "Commerce City",
      "names": {
        "es": "Ciudad de Comercio",
        "fr": "City Commerce",
        "zh": "商业城市",
        "de": "Handelsstadt",
        "ja": "コマースシティ",
        "th": "เมืองพาณิชย์",
        "pt": "Cidade de comércio"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.90361555,
        "lng": -104.79498697563807
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.7997456,
        39.9000814,
        -104.7902151,
        39.907394
      ]
    },
    {
      "id": 11975,
      "name": "Boadilla del Monte",
      "names": {
        "th": "Baadilla del Monte"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4053485,
        "lng": -3.8764663
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.9525689,
        40.378003,
        -3.8378336,
        40.4563321
      ]
    },
    {
      "id": 11976,
      "name": "Manhica",
      "names": {
        "pt": "Manhiça"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.4025426,
        "lng": 32.8049523
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        32.7999523,
        -25.4075426,
        32.8099523,
        -25.3975426
      ]
    },
    {
      "id": 11977,
      "name": "Cubulco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1037494,
        "lng": -90.6290718
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.6690718,
        15.0637494,
        -90.5890718,
        15.1437494
      ]
    },
    {
      "id": 11978,
      "name": "Benevento",
      "names": {
        "zh": "贝内文托"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1295587,
        "lng": 14.7824639
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.7090168,
        41.0901627,
        14.8696927,
        41.2248072
      ]
    },
    {
      "id": 11979,
      "name": "Belebey",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.0935,
        "lng": 54.1315
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11980,
      "name": "Labinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.634678,
        "lng": 40.72784
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.691307,
        44.585999,
        40.761002,
        44.663612
      ]
    },
    {
      "id": 11981,
      "name": "Nordre Fale",
      "names": {
        "fr": "Fale Nordre",
        "zh": "Nordre Wale",
        "ja": "ノルドレ"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7425028,
        "lng": 10.7435056
      },
      "country": "Norway",
      "importance": 3,
      "bbox": [
        10.7335056,
        59.7325028,
        10.7535056,
        59.7525028
      ]
    },
    {
      "id": 11982,
      "name": "Lianzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1164235,
        "lng": 115.7613516
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.7213516,
        37.0764235,
        115.8013516,
        37.1564235
      ]
    },
    {
      "id": 11983,
      "name": "San Simon",
      "names": {
        "es": "San Simón",
        "zh": "圣西蒙",
        "ja": "サンサイモン",
        "th": "ซานไซมอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9954506,
        "lng": 120.7791636
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7270794,
        14.9768392,
        120.8746194,
        15.0344927
      ]
    },
    {
      "id": 11984,
      "name": "Camocim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.9009918,
        "lng": -40.8427389
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.1195838,
        -3.0938361,
        -40.4950246,
        -2.8412361
      ]
    },
    {
      "id": 11985,
      "name": "Shimotsuke",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3943469,
        "lng": 139.8515863
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.797181,
        36.350169,
        139.925091,
        36.487691
      ]
    },
    {
      "id": 11986,
      "name": "Alfonso",
      "names": {
        "zh": "阿方索·",
        "ja": "アルフォンソ",
        "th": "อัลฟอง"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1380464,
        "lng": 120.8554205
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.7763853,
        14.0598967,
        120.8934075,
        14.1957671
      ]
    },
    {
      "id": 11987,
      "name": "Chandralapadu",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.69364,
        "lng": 80.20241
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11988,
      "name": "Urus-Martan",
      "names": {
        "zh": "乌鲁斯-马尔坦"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.133102,
        "lng": 45.538425
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        45.4984586,
        43.0901869,
        45.5793101,
        43.175568
      ]
    },
    {
      "id": 11989,
      "name": "South Hill",
      "names": {
        "es": "Colina sur",
        "zh": "南山",
        "it": "Collina del sud",
        "id": "Bukit selatan",
        "ja": "サウスヒル",
        "pt": "Colina sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.11986215,
        "lng": -122.29017095173586
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.3375017,
        47.0781116,
        -122.2353281,
        47.1610673
      ]
    },
    {
      "id": 11990,
      "name": "San Mariano",
      "names": {
        "zh": "圣马里亚诺",
        "ja": "サンマリアーノ",
        "th": "ซานมาริโน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.9835964,
        "lng": 122.0127684
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.9727684,
        16.9435964,
        122.0527684,
        17.0235964
      ]
    },
    {
      "id": 11991,
      "name": "Tecpan Guatemala",
      "names": {
        "zh": "Tecpan危地马拉",
        "th": "Tecpan กัวเตมาลา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.76694,
        "lng": -90.99417
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.01417,
        14.74694,
        -90.97417,
        14.78694
      ]
    },
    {
      "id": 11992,
      "name": "Benevides",
      "names": {
        "zh": "贝内维德斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.3616907,
        "lng": -48.24557772208013
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.2931245,
        -1.4689318,
        -48.20741,
        -1.2550452
      ]
    },
    {
      "id": 11993,
      "name": "Towada",
      "names": {
        "zh": "十和"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.6127405,
        "lng": 141.206023
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.851443,
        40.421049,
        141.304522,
        40.701677
      ]
    },
    {
      "id": 11994,
      "name": "Suifenhe",
      "names": {
        "zh": "绥芬河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.3946516,
        "lng": 131.1556208
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        131.1284366,
        44.3643259,
        131.2072672,
        44.4841527
      ]
    },
    {
      "id": 11995,
      "name": "Kapchagay",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.86681,
        "lng": 77.06304
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        77.04304,
        43.84681,
        77.08304,
        43.88681
      ]
    },
    {
      "id": 11996,
      "name": "Aranjuez",
      "names": {
        "zh": "阿兰胡埃斯",
        "ja": "アランフェス"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.03221,
        "lng": -3.6039604
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.8765639,
        39.8846264,
        -3.513587,
        40.1301113
      ]
    },
    {
      "id": 11997,
      "name": "Vinukonda",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.0506568,
        "lng": 79.7477201
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.7427201,
        16.0456568,
        79.7527201,
        16.0556568
      ]
    },
    {
      "id": 11998,
      "name": "Kananya",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1867,
        "lng": 124.5601
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5401,
        11.1667,
        124.5801,
        11.2067
      ]
    },
    {
      "id": 11999,
      "name": "Sanyo-Onoda",
      "names": {
        "zh": "三洋-小野田",
        "ja": "三洋-onoda",
        "th": "ซันโย-onoda"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0030045,
        "lng": 131.1819289
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.0612453,
        33.8029173,
        131.226875,
        34.119607
      ]
    },
    {
      "id": 12000,
      "name": "Ilog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0256251,
        "lng": 122.7682414
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.6112316,
        9.4985712,
        122.8139731,
        10.1882872
      ]
    },
    {
      "id": 12001,
      "name": "Sigaboy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6535259,
        "lng": 126.0720099
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.927504,
        6.1332718,
        126.19861,
        6.7217043
      ]
    },
    {
      "id": 12002,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -7.2634651,
        "lng": -78.0624572
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -78.3255762,
        -7.4805311,
        -77.8289075,
        -7.0647701
      ]
    },
    {
      "id": 12003,
      "name": "Bayji",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.92983,
        "lng": 43.491613
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        43.441578,
        34.889476,
        43.529858,
        35.028471
      ]
    },
    {
      "id": 12004,
      "name": "Lagoa Santa",
      "names": {
        "zh": "Lagoa圣诞老人",
        "ja": "ラゴアサンタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6357376,
        "lng": -43.8966132
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.9743356,
        -19.7170799,
        -43.7922078,
        -19.536
      ]
    },
    {
      "id": 12005,
      "name": "Kribi",
      "names": {
        "zh": "克里比"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 2.95,
        "lng": 9.91667
      },
      "country": "Cameroon",
      "importance": 3,
      "bbox": [
        9.89667,
        2.93,
        9.93667,
        2.97
      ]
    },
    {
      "id": 12006,
      "name": "Plymouth",
      "names": {
        "zh": "普利茅斯",
        "ja": "プリマス",
        "th": "พลีมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9426657,
        "lng": -70.7618592
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.0804832,
        41.5665119,
        -70.4560544,
        42.3536042
      ]
    },
    {
      "id": 12007,
      "name": "Maues",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3863585,
        "lng": -57.7202142
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -57.7202642,
        -3.3864085,
        -57.7201642,
        -3.3863085
      ]
    },
    {
      "id": 12008,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7425538,
        "lng": -71.0856545
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.381463,
        41.4111505,
        -70.7560271,
        42.095539
      ]
    },
    {
      "id": 12009,
      "name": "Comitancillo",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0907805,
        "lng": -91.7489586
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.7889586,
        15.0507805,
        -91.7089586,
        15.1307805
      ]
    },
    {
      "id": 12010,
      "name": "Niort",
      "names": {
        "zh": "尼奥尔",
        "ja": "ニオール",
        "th": "นีออร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3239233,
        "lng": -0.4646064
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.5422203,
        46.2818324,
        -0.3804259,
        46.3731069
      ]
    },
    {
      "id": 12011,
      "name": "Ragay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8210634,
        "lng": 122.78763
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.74763,
        13.7810634,
        122.82763,
        13.8610634
      ]
    },
    {
      "id": 12012,
      "name": "Columbus",
      "names": {
        "zh": "哥伦布",
        "ja": "コロンバス",
        "th": "โคลัมบัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2014405,
        "lng": -85.9213796
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.0029791,
        39.120596,
        -85.850896,
        39.2748477
      ]
    },
    {
      "id": 12013,
      "name": "Hardenberg",
      "names": {
        "zh": "哈登贝格"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5723383,
        "lng": 6.627603
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.622603,
        52.5673383,
        6.632603,
        52.5773383
      ]
    },
    {
      "id": 12014,
      "name": "Shuya",
      "names": {
        "zh": "舒亚"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.8506692,
        "lng": 41.3515447
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        41.3465447,
        56.8456692,
        41.3565447,
        56.8556692
      ]
    },
    {
      "id": 12015,
      "name": "Huajiang",
      "names": {
        "zh": "花江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7497475,
        "lng": 105.6067349
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.5667349,
        25.7097475,
        105.6467349,
        25.7897475
      ]
    },
    {
      "id": 12016,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.7792588,
        "lng": -122.4193286
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -123.173825,
        37.6403143,
        -122.281479,
        37.929811
      ]
    },
    {
      "id": 12017,
      "name": "Bangor",
      "names": {
        "zh": "班戈",
        "ja": "バンゴー",
        "th": "บันกอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8016255,
        "lng": -68.7713289
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -68.8590412,
        44.7775455,
        -68.7187359,
        44.8801777
      ]
    },
    {
      "id": 12018,
      "name": "Lesosibirsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.235202,
        "lng": 92.490501
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        92.433777,
        58.219028,
        92.518219,
        58.263222
      ]
    },
    {
      "id": 12019,
      "name": "Tezpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.6229928,
        "lng": 92.7976082
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        92.7576082,
        26.5829928,
        92.8376082,
        26.6629928
      ]
    },
    {
      "id": 12020,
      "name": "Zhoujiajing",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.50725,
        "lng": 120.46786
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        120.44786,
        31.48725,
        120.48786,
        31.52725
      ]
    },
    {
      "id": 12021,
      "name": "Chibuto",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -24.68667,
        "lng": 33.53056
      },
      "country": "Mozambique",
      "importance": 3,
      "bbox": [
        33.51056,
        -24.70667,
        33.55056,
        -24.66667
      ]
    },
    {
      "id": 12022,
      "name": "Palmares",
      "names": {
        "fr": "Palmarès",
        "zh": "帕尔马雷斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.684226,
        "lng": -35.589032
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.721,
        -8.801,
        -35.537,
        -8.5142837
      ]
    },
    {
      "id": 12023,
      "name": "Joso",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0235685,
        "lng": 139.9938714
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.893725,
        35.9686561,
        140.02933,
        36.147211
      ]
    },
    {
      "id": 12024,
      "name": "Pasrur",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.267947,
        "lng": 74.662039
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        74.653717,
        32.260882,
        74.673012,
        32.27681
      ]
    },
    {
      "id": 12025,
      "name": "Mouscron",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7436672,
        "lng": 3.2142729
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.1785829,
        50.7099086,
        3.329056,
        50.7659099
      ]
    },
    {
      "id": 12026,
      "name": "Irosin",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.7038417,
        "lng": 124.0320966
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.9920966,
        12.6638417,
        124.0720966,
        12.7438417
      ]
    },
    {
      "id": 12027,
      "name": "Narasapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.428875,
        "lng": 81.680822
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        81.674439,
        16.410958,
        81.710048,
        16.452363
      ]
    },
    {
      "id": 12028,
      "name": "Saint-Hyacinthe",
      "names": {
        "zh": "圣 - 亚森特",
        "ja": "サン・hyacinthe",
        "th": "เซนต์ hyacinthe"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.6276056,
        "lng": -72.9488729
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.9538729,
        45.6226056,
        -72.9438729,
        45.6326056
      ]
    },
    {
      "id": 12029,
      "name": "Ogori",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3963946,
        "lng": 130.5554371
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.5351511,
        33.341399,
        130.6101673,
        33.449929
      ]
    },
    {
      "id": 12030,
      "name": "Goianesia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.3118269,
        "lng": -49.1162001
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.36,
        -15.6023915,
        -48.9062606,
        -14.992
      ]
    },
    {
      "id": 12031,
      "name": "Alcoy",
      "names": {
        "zh": "阿尔科伊"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.6982275,
        "lng": -0.4747767
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.6348514,
        38.6202491,
        -0.4275883,
        38.7434439
      ]
    },
    {
      "id": 12032,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 38.6280278,
        "lng": -90.1910154
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -90.3206525,
        38.5323215,
        -90.1641941,
        38.7743018
      ]
    },
    {
      "id": 12033,
      "name": "Agrigento",
      "names": {
        "fr": "Agrigente",
        "zh": "阿格里真托",
        "ja": "アグリジェント"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.3122991,
        "lng": 13.57465
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.3966521,
        37.189677,
        13.7486235,
        37.4406445
      ]
    },
    {
      "id": 12034,
      "name": "Montenegro",
      "names": {
        "fr": "Monténégro",
        "zh": "黑山",
        "ja": "モンテネグロ",
        "th": "มอนเตเนโกร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6826112,
        "lng": -51.4687455
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.662635,
        -29.8446455,
        -51.3498193,
        -29.5743342
      ]
    },
    {
      "id": 12035,
      "name": "Santa Isabel do Para",
      "names": {
        "zh": "圣诞老人isabel做帕拉",
        "ja": "サンタイザベルドパラ",
        "th": "ซานต้าอิซาเบลทำพารา",
        "pt": "Santa Isabel do Pará"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.29861,
        "lng": -48.16056
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.18056,
        -1.31861,
        -48.14056,
        -1.27861
      ]
    },
    {
      "id": 12036,
      "name": "Roxas",
      "names": {
        "zh": "罗哈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5908562,
        "lng": 121.517018
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.477018,
        12.5508562,
        121.557018,
        12.6308562
      ]
    },
    {
      "id": 12037,
      "name": "Prokhladnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.752571,
        "lng": 44.025428
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        43.976223,
        43.728027,
        44.065723,
        43.768749
      ]
    },
    {
      "id": 12038,
      "name": "Kesennuma",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.9080078,
        "lng": 141.5698223
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.39876,
        38.6202659,
        142.0513624,
        39.002779
      ]
    },
    {
      "id": 12039,
      "name": "Yara",
      "names": {
        "zh": "亚拉"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.2718248,
        "lng": -76.9537098
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.0977317,
        20.1555366,
        -76.7879511,
        20.5007784
      ]
    },
    {
      "id": 12040,
      "name": "Esfarayen",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.07645,
        "lng": 57.51009
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        57.49009,
        37.05645,
        57.53009,
        37.09645
      ]
    },
    {
      "id": 12041,
      "name": "Sayaxche",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 16.5242969,
        "lng": -90.1884154
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.5493271,
        15.9638987,
        -89.8974896,
        16.6756826
      ]
    },
    {
      "id": 12042,
      "name": "Moron",
      "names": {
        "es": "Morón",
        "fr": "Idiot",
        "zh": "笨蛋",
        "de": "Trottel",
        "id": "Bodoh",
        "ja": "バカ",
        "th": "คนโง่",
        "pt": "Imbecil"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 18.5602014,
        "lng": -74.2581337
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -74.2981337,
        18.5202014,
        -74.2181337,
        18.6002014
      ]
    },
    {
      "id": 12043,
      "name": "Los Andes",
      "names": {
        "zh": "洛杉矶",
        "ja": "ロスアンデス"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.8336867,
        "lng": -70.5981609
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.6589788,
        -33.1828907,
        -69.9891619,
        -32.7290934
      ]
    },
    {
      "id": 12044,
      "name": "Greenwood",
      "names": {
        "zh": "格林伍德",
        "ja": "グリーンウッド",
        "th": "กรีนวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6136987,
        "lng": -86.1095429
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.1670421,
        39.548372,
        -86.0399985,
        39.6369326
      ]
    },
    {
      "id": 12045,
      "name": "Lucas do Rio Verde",
      "names": {
        "zh": "卢卡斯做RIO VERDE",
        "th": "ลูคัสทำ rio verde"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -13.0664609,
        "lng": -55.9196711
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.659,
        -13.4579068,
        -55.8742123,
        -12.6381181
      ]
    },
    {
      "id": 12046,
      "name": "Hyuga",
      "names": {
        "zh": "日向",
        "ja": "ヒュウガ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.4225483,
        "lng": 131.6244443
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.338318,
        32.1934959,
        131.9314812,
        32.475916
      ]
    },
    {
      "id": 12047,
      "name": "Hua Hin",
      "names": {
        "zh": "华欣",
        "ja": "ホアヒン",
        "th": "หัวหิน",
        "pt": "Hua"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.5699326,
        "lng": 99.9573437
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.9173437,
        12.5299326,
        99.9973437,
        12.6099326
      ]
    },
    {
      "id": 12048,
      "name": "Bartlett",
      "names": {
        "zh": "巴特利特",
        "ja": "バートレット",
        "th": "บาร์ตเลต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2045329,
        "lng": -89.8739753
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.88786,
        35.187295,
        -89.744042,
        35.276037
      ]
    },
    {
      "id": 12049,
      "name": "Bradenton",
      "names": {
        "zh": "布雷登顿",
        "ja": "ブレーデントン",
        "th": "เบรเดนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.4989278,
        "lng": -82.5748194
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.6906865,
        27.4551648,
        -82.4794264,
        27.5229628
      ]
    },
    {
      "id": 12050,
      "name": "Pontiac",
      "names": {
        "zh": "庞蒂亚克",
        "ja": "ポンティアック",
        "th": "รถปอนเตี๊ยก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6389216,
        "lng": -83.2910468
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.335033,
        42.6038449,
        -83.2479027,
        42.699623
      ]
    },
    {
      "id": 12051,
      "name": "Staunton",
      "names": {
        "zh": "斯汤顿",
        "ja": "ストーントン",
        "th": "สทอนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1495947,
        "lng": -79.072557
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -79.1138396,
        38.121176,
        -79.0113808,
        38.1990176
      ]
    },
    {
      "id": 12052,
      "name": "Neu-Ulm",
      "names": {
        "zh": "神经乌尔姆",
        "ja": "ノイ-ウルム",
        "th": "นัก-ulm"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.393098,
        "lng": 10.0050112
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.0049612,
        48.393048,
        10.0050612,
        48.393148
      ]
    },
    {
      "id": 12053,
      "name": "Gannan",
      "names": {
        "zh": "赣南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.91472,
        "lng": 123.50306
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.48306,
        47.89472,
        123.52306,
        47.93472
      ]
    },
    {
      "id": 12054,
      "name": "Carazinho",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.2883099,
        "lng": -52.7906598
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.7907098,
        -28.2883599,
        -52.7906098,
        -28.2882599
      ]
    },
    {
      "id": 12055,
      "name": "El Cua",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.3681557,
        "lng": -85.672828
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -85.8255976,
        13.246431,
        -85.43729,
        13.5854536
      ]
    },
    {
      "id": 12056,
      "name": "Concepcion Tutuapa",
      "names": {
        "es": "Concepción Tutuapa",
        "ja": "コンセプシオンチュタパ",
        "pt": "Tutuapa de concepcion"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2396989,
        "lng": -91.8442667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.9789256,
        15.2139693,
        -91.7495365,
        15.3612673
      ]
    },
    {
      "id": 12057,
      "name": "Esperanza",
      "names": {
        "zh": "埃斯佩兰萨",
        "ja": "エスペランサ",
        "th": "เอสเปอรัน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 40.4592436,
        "lng": -3.6455644
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -3.6505644,
        40.4542436,
        -3.6405644,
        40.4642436
      ]
    },
    {
      "id": 12058,
      "name": "Meriden",
      "names": {
        "zh": "梅里登",
        "th": "เมริเดน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5381535,
        "lng": -72.8070435
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.859025,
        41.495503,
        -72.7447556,
        41.5789025
      ]
    },
    {
      "id": 12059,
      "name": "Aleksandrov",
      "names": {
        "zh": "亚历山德罗夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.39211265,
        "lng": 38.71124026724134
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.6589017,
        56.362759,
        38.7771939,
        56.4210093
      ]
    },
    {
      "id": 12060,
      "name": "Clay",
      "names": {
        "es": "Arcilla",
        "fr": "Argile",
        "zh": "粘土",
        "de": "Ton",
        "it": "Argille",
        "ja": "粘土",
        "th": "ดิน",
        "pt": "Argila"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0572652,
        "lng": -83.7324509
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.0058399,
        34.9866544,
        -83.4829498,
        35.1611752
      ]
    },
    {
      "id": 12061,
      "name": "Ibaan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8176,
        "lng": 121.133
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.113,
        13.7976,
        121.153,
        13.8376
      ]
    },
    {
      "id": 12062,
      "name": "El Salvador",
      "names": {
        "zh": "萨尔瓦多",
        "ja": "エルサルバドール",
        "th": "เอลซัลวาดอร์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5585108,
        "lng": 124.5264381
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.3664381,
        8.3985108,
        124.6864381,
        8.7185108
      ]
    },
    {
      "id": 12063,
      "name": "Tahara",
      "names": {
        "zh": "田原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.639112,
        "lng": 137.183207
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.9560916,
        34.2763034,
        137.4464456,
        34.7479301
      ]
    },
    {
      "id": 12064,
      "name": "Apex",
      "names": {
        "es": "Apéndice",
        "zh": "顶尖",
        "it": "Apice",
        "ja": "頂点",
        "th": "ปลาย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7325352,
        "lng": -78.8505516
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.9689996,
        35.6648279,
        -78.8025752,
        35.7786836
      ]
    },
    {
      "id": 12065,
      "name": "Unna",
      "names": {
        "zh": "翁纳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5348835,
        "lng": 7.689014
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6287533,
        51.4904102,
        7.8429416,
        51.5786349
      ]
    },
    {
      "id": 12066,
      "name": "Royal Oak",
      "names": {
        "es": "Roble real",
        "fr": "Chêne royal",
        "zh": "皇家橡木",
        "de": "Königliche Eiche",
        "it": "Rovere reale",
        "ja": "ロイヤルオーク",
        "th": "ต้นโอ๊ก",
        "pt": "Carvalho real"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4894801,
        "lng": -83.1446485
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.2044015,
        42.4741769,
        -83.110736,
        42.54032
      ]
    },
    {
      "id": 12067,
      "name": "Mansalay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.52044,
        "lng": 121.43851
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.41851,
        12.50044,
        121.45851,
        12.54044
      ]
    },
    {
      "id": 12068,
      "name": "Chikuma",
      "names": {
        "zh": "筑摩"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5336984,
        "lng": 138.120123
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.029626,
        36.436535,
        138.201857,
        36.560082
      ]
    },
    {
      "id": 12069,
      "name": "Calatagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.83224,
        "lng": 120.6322
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6122,
        13.81224,
        120.6522,
        13.85224
      ]
    },
    {
      "id": 12070,
      "name": "Shirakawa",
      "names": {
        "zh": "白川",
        "ja": "白川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.1263397,
        "lng": 140.2107192
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.099185,
        37.000036,
        140.412912,
        37.261704
      ]
    },
    {
      "id": 12071,
      "name": "Halesowen",
      "names": {
        "zh": "黑尔斯欧文"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4492074,
        "lng": -2.0514646
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.0914646,
        52.4092074,
        -2.0114646,
        52.4892074
      ]
    },
    {
      "id": 12072,
      "name": "Benton Harbor",
      "names": {
        "es": "Puerto de Benton",
        "fr": "Benton Harbour",
        "zh": "宾顿港",
        "ja": "ベントンハーバー",
        "th": "Benton Harbour",
        "pt": "Porto de benton"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1167065,
        "lng": -86.4541894
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.4772075,
        42.0925586,
        -86.410927,
        42.141749
      ]
    },
    {
      "id": 12073,
      "name": "Chichibu",
      "names": {
        "zh": "秩父",
        "ja": "秩父"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9914509,
        "lng": 139.0857612
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.711358,
        35.839779,
        139.176197,
        36.125896
      ]
    },
    {
      "id": 12074,
      "name": "Tianningcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.89333,
        "lng": 120.80126
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12075,
      "name": "Santa Maria Chiquimula",
      "names": {
        "zh": "Santa Maria Chiquiula",
        "ja": "サンタマリアチキミュラ",
        "th": "ซานตามาเรีย chiquimula"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0296151,
        "lng": -91.329326
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.369326,
        14.9896151,
        -91.289326,
        15.0696151
      ]
    },
    {
      "id": 12076,
      "name": "Zengcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2416377,
        "lng": 114.7387245
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.6987245,
        38.2016377,
        114.7787245,
        38.2816377
      ]
    },
    {
      "id": 12077,
      "name": "Masantol",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.896,
        "lng": 120.7092
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6892,
        14.876,
        120.7292,
        14.916
      ]
    },
    {
      "id": 12078,
      "name": "Kattaqo'rg'on Shahri",
      "names": {
        "zh": "哈哈里·沙赫里"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.9018122,
        "lng": 66.2684943
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        66.2317508,
        39.8827048,
        66.3068768,
        39.9282588
      ]
    },
    {
      "id": 12079,
      "name": "Canlaon",
      "names": {
        "zh": "坎拉翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.35208,
        "lng": 123.210128
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.138044,
        10.274708,
        123.270067,
        10.420465
      ]
    },
    {
      "id": 12080,
      "name": "Pamplona",
      "names": {
        "fr": "Pampelune",
        "zh": "潘普洛纳",
        "ja": "パンプローナ",
        "th": "ปัมโปลนา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.3763807,
        "lng": -72.6482628
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.8178159,
        7.2633766,
        -72.5370713,
        7.4720205
      ]
    },
    {
      "id": 12081,
      "name": "Florida",
      "names": {
        "fr": "Floride",
        "zh": "佛罗里达",
        "ja": "フロリダ州",
        "th": "ฟลอริดา",
        "pt": "Flórida"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 27.7567667,
        "lng": -81.4639835
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -87.634896,
        24.396308,
        -79.974306,
        31.000762
      ]
    },
    {
      "id": 12082,
      "name": "Constanza",
      "names": {
        "zh": "康斯坦萨"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.9065575,
        "lng": -70.7449293
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.7556996,
        18.8985989,
        -70.7325263,
        18.9186666
      ]
    },
    {
      "id": 12083,
      "name": "Damghan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1683,
        "lng": 54.348
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        54.328,
        36.1483,
        54.368,
        36.1883
      ]
    },
    {
      "id": 12084,
      "name": "Upi",
      "names": {
        "zh": "乌皮"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0103624,
        "lng": 124.160434
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.120434,
        6.9703624,
        124.200434,
        7.0503624
      ]
    },
    {
      "id": 12085,
      "name": "Motril",
      "names": {
        "zh": "莫特里尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.749152,
        "lng": -3.518433
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.530254,
        36.73591,
        -3.50562,
        36.75792
      ]
    },
    {
      "id": 12086,
      "name": "Faenza",
      "names": {
        "zh": "法恩莎"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.2855555,
        "lng": 11.8832055
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        11.7620911,
        44.2106862,
        12.0315839,
        44.3800447
      ]
    },
    {
      "id": 12087,
      "name": "Bilhorod-Dnistrovskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.1848606,
        "lng": 30.3379051
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.3329051,
        46.1798606,
        30.3429051,
        46.1898606
      ]
    },
    {
      "id": 12088,
      "name": "Xikeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.9380894,
        "lng": 113.1942741
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.1742741,
        23.9180894,
        113.2142741,
        23.9580894
      ]
    },
    {
      "id": 12089,
      "name": "Lower Merion",
      "names": {
        "es": "Mérito inferior",
        "fr": "Mérimètre inférieur",
        "zh": "梅里吉利",
        "de": "Niedrigerer Mersion",
        "it": "Minore merita",
        "id": "Merion bawah",
        "ja": "下部メリオン",
        "th": "การถือครองที่ต่ำกว่า",
        "pt": "Mérmino inferior"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.042984,
        "lng": -75.290189
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.349457,
        39.988304,
        -75.205771,
        40.073013
      ]
    },
    {
      "id": 12090,
      "name": "St. Clair Shores",
      "names": {
        "zh": "圣克莱尔海岸",
        "it": "S. Clair Shores",
        "ja": "セントクレアショアーズ",
        "th": "เซนต์แคลร์ชายฝั่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.49124,
        "lng": -82.892675
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.928579,
        42.45062,
        -82.85514,
        42.540918
      ]
    },
    {
      "id": 12091,
      "name": "Kelibia",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.84757,
        "lng": 11.09386
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        11.07386,
        36.82757,
        11.11386,
        36.86757
      ]
    },
    {
      "id": 12092,
      "name": "Barra do Garcas",
      "names": {
        "zh": "巴拉做garcas"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.8916033,
        "lng": -52.2615413
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -53.1491955,
        -15.9118345,
        -51.9707462,
        -14.802046
      ]
    },
    {
      "id": 12093,
      "name": "Rass el Djebel",
      "names": {
        "fr": "Rasse El Djebel"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 37.21572,
        "lng": 10.1253
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.1053,
        37.19572,
        10.1453,
        37.23572
      ]
    },
    {
      "id": 12094,
      "name": "Sandnes",
      "names": {
        "th": "แซนด์เนส"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.8519701,
        "lng": 5.737547
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.577547,
        58.6919701,
        5.897547,
        59.0119701
      ]
    },
    {
      "id": 12095,
      "name": "Gattaran",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.0603315,
        "lng": 121.6414943
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6014943,
        18.0203315,
        121.6814943,
        18.1003315
      ]
    },
    {
      "id": 12096,
      "name": "Panskura",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.40418,
        "lng": 87.71746
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.69746,
        22.38418,
        87.73746,
        22.42418
      ]
    },
    {
      "id": 12097,
      "name": "Pedro Leopoldo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.617778,
        "lng": -44.043055
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.195,
        -19.773,
        -43.9154866,
        -19.4850872
      ]
    },
    {
      "id": 12098,
      "name": "Melchor Ocampo",
      "names": {
        "zh": "梅尔皮奥郡",
        "ja": "メルカーコオカンポ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7063556,
        "lng": -99.1431933
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1831933,
        19.6663556,
        -99.1031933,
        19.7463556
      ]
    },
    {
      "id": 12099,
      "name": "Des Plaines",
      "names": {
        "es": "Des llan",
        "de": "Des Ungläubigen",
        "it": "Des Plaundes",
        "id": "Des darm",
        "ja": "デスプレーン",
        "th": "des ธรรมดา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.032843,
        "lng": -87.900893
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.950074,
        41.995342,
        -87.859967,
        42.079829
      ]
    },
    {
      "id": 12100,
      "name": "Jarabacoa",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 19.1209358,
        "lng": -70.641932
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.681932,
        19.0809358,
        -70.601932,
        19.1609358
      ]
    },
    {
      "id": 12101,
      "name": "Lac-Brome",
      "names": {
        "zh": "拉丁美洲和加勒比-溴",
        "ja": "LACは、ブロム",
        "th": "ครั่ง-บร็อมเม่"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.2177029,
        "lng": -72.5372215
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.6904077,
        45.1575415,
        -72.4782062,
        45.3075147
      ]
    },
    {
      "id": 12102,
      "name": "Timargara",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.82659,
        "lng": 71.84423
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.82423,
        34.80659,
        71.86423,
        34.84659
      ]
    },
    {
      "id": 12103,
      "name": "Boufarik",
      "names": {
        "zh": "布法里克"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.5755523,
        "lng": 2.9128963
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        2.8565721,
        36.5291421,
        2.9794403,
        36.6185726
      ]
    },
    {
      "id": 12104,
      "name": "Wuyang",
      "names": {
        "zh": "五羊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.0522455,
        "lng": 108.4253852
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        108.2653852,
        26.8922455,
        108.5853852,
        27.2122455
      ]
    },
    {
      "id": 12105,
      "name": "Lewiston",
      "names": {
        "zh": "刘易斯顿",
        "ja": "ルイストン",
        "th": "ลูอิสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.1004515,
        "lng": -70.2150294
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.224262,
        44.0053548,
        -70.115252,
        44.1675159
      ]
    },
    {
      "id": 12106,
      "name": "Jovellanos",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8051604,
        "lng": -81.196847
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -81.3782425,
        22.698624,
        -81.0835035,
        23.0039395
      ]
    },
    {
      "id": 12107,
      "name": "Barotac Nuevo",
      "names": {
        "fr": "Barottac nuevo",
        "zh": "巴利克·诺沃"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8951917,
        "lng": 122.7041185
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.6529384,
        10.857522,
        122.8697895,
        10.9586892
      ]
    },
    {
      "id": 12108,
      "name": "Bezerros",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.236898,
        "lng": -35.747351
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.8566759333,
        -8.31132846915,
        -35.7073898428,
        -8.20931589944
      ]
    },
    {
      "id": 12109,
      "name": "Midland",
      "names": {
        "zh": "米德兰",
        "ja": "ミッドランド",
        "th": "มิดแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6155825,
        "lng": -84.2472117
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.318483,
        43.565277,
        -84.1553835,
        43.6836896
      ]
    },
    {
      "id": 12110,
      "name": "Mikhaylovka",
      "names": {
        "zh": "米哈伊洛夫卡"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.0797109,
        "lng": 43.2342365
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.1942365,
        50.0397109,
        43.2742365,
        50.1197109
      ]
    },
    {
      "id": 12111,
      "name": "Baganga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.57389,
        "lng": 126.56
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        126.54,
        7.55389,
        126.58,
        7.59389
      ]
    },
    {
      "id": 12112,
      "name": "Palompon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0508,
        "lng": 124.3843
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.3643,
        11.0308,
        124.4043,
        11.0708
      ]
    },
    {
      "id": 12113,
      "name": "Surubim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8424353,
        "lng": -35.7539722
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.8458525,
        -7.978,
        -35.6498774,
        -7.8034134
      ]
    },
    {
      "id": 12114,
      "name": "Izberbash",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 42.5710804,
        "lng": 47.862999
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        47.8141767,
        42.5225285,
        47.9112457,
        42.6010349
      ]
    },
    {
      "id": 12115,
      "name": "Sucun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.594024,
        "lng": 118.5712261
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.5312261,
        35.554024,
        118.6112261,
        35.634024
      ]
    },
    {
      "id": 12116,
      "name": "Asingan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0037829,
        "lng": 120.6702443
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6302443,
        15.9637829,
        120.7102443,
        16.0437829
      ]
    },
    {
      "id": 12117,
      "name": "Bowie",
      "names": {
        "zh": "鲍伊",
        "ja": "ボウイ",
        "th": "โบวี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9424464,
        "lng": -76.7301718
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.7998736,
        38.8864886,
        -76.6915282,
        39.0140935
      ]
    },
    {
      "id": 12118,
      "name": "Cerignola",
      "names": {
        "zh": "切里尼奥拉"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2648121,
        "lng": 15.8996651
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        15.6942706,
        41.1060819,
        16.0402424,
        41.4774685
      ]
    },
    {
      "id": 12119,
      "name": "Yanguancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4523,
        "lng": 120.55662
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12120,
      "name": "Qaskeleng",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.19839,
        "lng": 76.63099
      },
      "country": "Kazakhstan",
      "importance": 3,
      "bbox": [
        76.61099,
        43.17839,
        76.65099,
        43.21839
      ]
    },
    {
      "id": 12121,
      "name": "Aldershot",
      "names": {
        "zh": "奥尔德肖特",
        "th": "ชอท"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2466999,
        "lng": -0.7588758
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7589258,
        51.2466499,
        -0.7588258,
        51.2467499
      ]
    },
    {
      "id": 12122,
      "name": "Kitahiroshima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 42.9853877,
        "lng": 141.5629536
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.413556,
        42.891458,
        141.597615,
        43.024892
      ]
    },
    {
      "id": 12123,
      "name": "Aketi",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": 2.73877,
        "lng": 23.78326
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        23.76326,
        2.71877,
        23.80326,
        2.75877
      ]
    },
    {
      "id": 12124,
      "name": "Rampur Hat",
      "names": {
        "es": "Sombrero de rampur",
        "fr": "Chapeau de rampeur",
        "zh": "rampur帽子",
        "de": "Rampur-Hut",
        "it": "Cappello di rampura",
        "id": "Topi rampung",
        "ja": "ランペュールハット",
        "th": "หมวก rampur",
        "pt": "Boné"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.177837,
        "lng": 87.785465
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        87.766674,
        24.157976,
        87.805929,
        24.199241
      ]
    },
    {
      "id": 12125,
      "name": "Cogan",
      "names": {
        "zh": "科甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.59306,
        "lng": 124.01778
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.99778,
        10.57306,
        124.03778,
        10.61306
      ]
    },
    {
      "id": 12126,
      "name": "Felgueiras",
      "names": {
        "zh": "费尔盖拉什"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3647716,
        "lng": -8.1989321
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2801154,
        41.2939123,
        -8.1096171,
        41.4118734
      ]
    },
    {
      "id": 12127,
      "name": "Ejmiatsin",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.1666916,
        "lng": 44.29433
      },
      "country": "Armenia",
      "importance": 3,
      "bbox": [
        44.29428,
        40.1666416,
        44.29438,
        40.1667416
      ]
    },
    {
      "id": 12128,
      "name": "Tokoname",
      "names": {
        "zh": "常滑"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.888234,
        "lng": 136.836112
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.79357,
        34.843116,
        136.864882,
        34.917471
      ]
    },
    {
      "id": 12129,
      "name": "Lambayeque",
      "names": {
        "zh": "兰巴耶克"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.703027,
        "lng": -79.9065086
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -80.1117461,
        -6.7574202,
        -79.7814297,
        -6.5896294
      ]
    },
    {
      "id": 12130,
      "name": "Goppingen",
      "names": {
        "es": "Göppingen",
        "fr": "Göppingen",
        "de": "Göppingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7031377,
        "lng": 9.6541116
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.5912214,
        48.6549775,
        9.752372,
        48.7762403
      ]
    },
    {
      "id": 12131,
      "name": "Sunshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7629655,
        "lng": 116.4879627
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.4479627,
        38.7229655,
        116.5279627,
        38.8029655
      ]
    },
    {
      "id": 12132,
      "name": "Dumanjog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0671,
        "lng": 123.4403
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12133,
      "name": "Iskitim",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6426269,
        "lng": 83.3035676
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        83.2167768,
        54.5290537,
        83.367046,
        54.7010659
      ]
    },
    {
      "id": 12134,
      "name": "Guarabira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.8513982,
        "lng": -35.4915582
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.531,
        -6.963473,
        -35.3801578,
        -6.8122654
      ]
    },
    {
      "id": 12135,
      "name": "Caldwell",
      "names": {
        "zh": "考德威尔",
        "ja": "コールドウェル",
        "th": "เวลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6558966,
        "lng": -93.9997281
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.2085002,
        39.523941,
        -93.7590174,
        39.7889945
      ]
    },
    {
      "id": 12136,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.25,
        "lng": -56.5
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -57.4744685,
        -25.0193136,
        -55.8398886,
        -23.3009578
      ]
    },
    {
      "id": 12137,
      "name": "Date",
      "names": {
        "es": "Fecha",
        "zh": "约会",
        "de": "Datum",
        "it": "Data",
        "id": "Tanggal",
        "ja": "日付",
        "th": "วันที่",
        "pt": "Encontro"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.824098,
        "lng": 140.4933532
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.4883532,
        37.819098,
        140.4983532,
        37.829098
      ]
    },
    {
      "id": 12138,
      "name": "Ma'erkang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9078516,
        "lng": 102.2045261
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.0445261,
        31.7478516,
        102.3645261,
        32.0678516
      ]
    },
    {
      "id": 12139,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9747795,
        "lng": -122.5316686
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.589722,
        37.9388466,
        -122.4354202,
        38.0290987
      ]
    },
    {
      "id": 12140,
      "name": "Moncalieri",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0004614,
        "lng": 7.6847543
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        7.6480188,
        44.9279336,
        7.7571117,
        45.0311638
      ]
    },
    {
      "id": 12141,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -34.583791,
        "lng": -70.989122
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.0472964,
        -35.0059157,
        -70.248186,
        -34.4774986
      ]
    },
    {
      "id": 12142,
      "name": "Euskirchen",
      "names": {
        "zh": "奥伊斯基兴"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6612623,
        "lng": 6.7871219
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.3160973,
        50.3226897,
        6.9349233,
        50.7896443
      ]
    },
    {
      "id": 12143,
      "name": "Kokawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2707749,
        "lng": 135.4043711
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.3993711,
        34.2657749,
        135.4093711,
        34.2757749
      ]
    },
    {
      "id": 12144,
      "name": "Serres",
      "names": {
        "zh": "塞尔"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.0910711,
        "lng": 23.5498031
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.3898031,
        40.9310711,
        23.7098031,
        41.2510711
      ]
    },
    {
      "id": 12145,
      "name": "Jiantang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8268959,
        "lng": 99.7130173
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.5225643,
        27.6720831,
        100.0152584,
        28.1927295
      ]
    },
    {
      "id": 12146,
      "name": "Ishikari",
      "names": {
        "zh": "石狩"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.171677,
        "lng": 141.3159605
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.7637544,
        43.1337028,
        141.598225,
        43.9041025
      ]
    },
    {
      "id": 12147,
      "name": "Punta Alta",
      "names": {
        "zh": "蓬塔阿尔塔",
        "ja": "プンタアルタ",
        "th": "ปุนตาอัลตา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.8804693,
        "lng": -62.0744033
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.0947246,
        -38.8997816,
        -62.0302469,
        -38.8388091
      ]
    },
    {
      "id": 12148,
      "name": "Biguacu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -27.4961277,
        "lng": -48.6542432
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.8351739,
        -27.544,
        -48.574,
        -27.32
      ]
    },
    {
      "id": 12149,
      "name": "La Gomera",
      "names": {
        "ja": "ラゴメラ",
        "th": "ลาเกเมอร์"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0856228,
        "lng": -91.0516644
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.0916644,
        14.0456228,
        -91.0116644,
        14.1256228
      ]
    },
    {
      "id": 12150,
      "name": "Kosai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.710542,
        "lng": 137.531599
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.474107,
        34.3950683,
        137.5987222,
        34.786807
      ]
    },
    {
      "id": 12151,
      "name": "Nyagan",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 62.1356189,
        "lng": 65.3865709
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        65.3815709,
        62.1306189,
        65.3915709,
        62.1406189
      ]
    },
    {
      "id": 12152,
      "name": "Beauvais",
      "names": {
        "zh": "博韦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4261995,
        "lng": 2.0886775
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.0836775,
        49.4211995,
        2.0936775,
        49.4311995
      ]
    },
    {
      "id": 12153,
      "name": "Barneveld",
      "names": {
        "zh": "巴讷费尔德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1681176,
        "lng": 5.5723204
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.5722704,
        52.1680676,
        5.5723704,
        52.1681676
      ]
    },
    {
      "id": 12154,
      "name": "Samana",
      "names": {
        "zh": "萨马纳",
        "ja": "サマナ川"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.2036865,
        "lng": -69.3389611
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -69.4908691,
        19.1615665,
        -69.1508188,
        19.3640004
      ]
    },
    {
      "id": 12155,
      "name": "Huaniu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5658674,
        "lng": 105.8230864
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        105.7569104,
        34.4721902,
        105.878629,
        34.633384
      ]
    },
    {
      "id": 12156,
      "name": "Doetinchem",
      "names": {
        "zh": "杜廷赫姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.964727,
        "lng": 6.293615
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.222417,
        51.934655,
        6.416082,
        51.994261
      ]
    },
    {
      "id": 12157,
      "name": "Hamburg",
      "names": {
        "es": "Hamburgo",
        "fr": "Hambourg",
        "zh": "汉堡",
        "it": "Amburgo",
        "ja": "ハンブルク",
        "th": "ฮัมบูร์ก",
        "pt": "Hamburgo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.716293,
        "lng": -78.828717
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.9703478,
        42.696215,
        -78.784354,
        42.80586
      ]
    },
    {
      "id": 12158,
      "name": "Richland",
      "names": {
        "zh": "丽晶",
        "ja": "リッチランド",
        "th": "แลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.2804298,
        "lng": -119.275205
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.4004448,
        46.1944001,
        -119.2114782,
        46.3977181
      ]
    },
    {
      "id": 12159,
      "name": "Fredericton",
      "names": {
        "zh": "弗雷德里克顿",
        "th": "เฟรดริกตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.963233,
        "lng": -66.6432356
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -66.787054,
        45.8470053,
        -66.511993,
        46.0505065
      ]
    },
    {
      "id": 12160,
      "name": "Choshi",
      "names": {
        "ja": "銚子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7345338,
        "lng": 140.8272667
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.689977,
        35.4891295,
        141.1249306,
        35.9138381
      ]
    },
    {
      "id": 12161,
      "name": "St. Peters",
      "names": {
        "es": "San Pedro",
        "zh": "圣彼得斯",
        "it": "San Pietro",
        "ja": "サンクトペター",
        "th": "เซนต์ปีเตอร์ส",
        "pt": "São Peters"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.791612,
        "lng": -90.5958468
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.6764102,
        38.7213352,
        -90.549209,
        38.830797
      ]
    },
    {
      "id": 12162,
      "name": "Wesley Chapel",
      "names": {
        "es": "Capilla de Wesley",
        "fr": "Chapelle Wesley",
        "zh": "韦斯利教堂",
        "de": "Wesley-Kapelle",
        "it": "Cappella di Wesley",
        "id": "Kapel Wesley",
        "ja": "ウェズリーチャペル",
        "pt": "Capela de Wesley"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.2397301,
        "lng": -82.3278658
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.3678658,
        28.1997301,
        -82.2878658,
        28.2797301
      ]
    },
    {
      "id": 12163,
      "name": "Togane",
      "names": {
        "zh": "东金"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5600309,
        "lng": 140.3662589
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.287083,
        35.50012,
        140.435769,
        35.631516
      ]
    },
    {
      "id": 12164,
      "name": "Avila",
      "names": {
        "es": "Ávila",
        "zh": "阿维拉",
        "ja": "アビラ",
        "pt": "Ávila"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.6570906,
        "lng": -4.6830022
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -4.6880022,
        40.6520906,
        -4.6780022,
        40.6620906
      ]
    },
    {
      "id": 12165,
      "name": "Gengzhuangqiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.44222,
        "lng": 114.97639
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.95639,
        37.42222,
        114.99639,
        37.46222
      ]
    },
    {
      "id": 12166,
      "name": "Tanauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1112113,
        "lng": 125.0169194
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.94514,
        11.02979,
        125.1028095,
        11.1633691
      ]
    },
    {
      "id": 12167,
      "name": "Heerhugowaard",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6631484,
        "lng": 4.8326672
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.785279,
        52.6326779,
        4.8998977,
        52.7233454
      ]
    },
    {
      "id": 12168,
      "name": "Pula",
      "names": {
        "zh": "普拉",
        "ja": "プーラ",
        "th": "พูลา"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.8702281,
        "lng": 13.8455311
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        13.7096749,
        44.8261258,
        13.9168194,
        44.945372
      ]
    },
    {
      "id": 12169,
      "name": "Tikhvin",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.644323,
        "lng": 33.5418624
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        33.4502219,
        59.5998244,
        33.5963914,
        59.6790343
      ]
    },
    {
      "id": 12170,
      "name": "Benslimane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.616870899999995,
        "lng": -7.123036042233153
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -7.1603294,
        33.5890446,
        -7.0883282,
        33.6452066
      ]
    },
    {
      "id": 12171,
      "name": "Lonavale",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.7481,
        "lng": 73.4072
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12172,
      "name": "New Corella",
      "names": {
        "fr": "Corella",
        "zh": "新的科特拉",
        "de": "Neue Corella",
        "it": "Nuova Corella",
        "id": "Corella baru",
        "ja": "ニューコロラ",
        "th": "ใหม่ Corella",
        "pt": "Nova corelha"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5866,
        "lng": 125.8237
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.8037,
        7.5666,
        125.8437,
        7.6066
      ]
    },
    {
      "id": 12173,
      "name": "Pradera",
      "names": {
        "zh": "普拉德拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.4196687,
        "lng": -76.2424378
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3334162,
        3.3523222,
        -76.0294569,
        3.4906487
      ]
    },
    {
      "id": 12174,
      "name": "Gaspar",
      "names": {
        "zh": "加斯帕"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9307306,
        "lng": -48.9566824
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.066,
        -27.0611704,
        -48.844,
        -26.776617
      ]
    },
    {
      "id": 12175,
      "name": "Roermond",
      "names": {
        "zh": "鲁尔蒙德",
        "ja": "ルールモント"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.1933903,
        "lng": 5.9882649
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9212473,
        51.1605423,
        6.0731414,
        51.2249911
      ]
    },
    {
      "id": 12176,
      "name": "Hendersonville",
      "names": {
        "ja": "ヘンダーソンヴィル"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3049177,
        "lng": -86.621443
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.679286,
        36.244204,
        -86.516424,
        36.369854
      ]
    },
    {
      "id": 12177,
      "name": "Huntersville",
      "names": {
        "ja": "ハンターズビル",
        "th": "ฮันเตอร์สวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4108278,
        "lng": -80.8429304
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.96122,
        35.3475222,
        -80.7521676,
        35.455075
      ]
    },
    {
      "id": 12178,
      "name": "Mizuho",
      "names": {
        "zh": "瑞穗",
        "ja": "みずほ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7383371,
        "lng": 139.6615041
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.6614541,
        35.7382871,
        139.6615541,
        35.7383871
      ]
    },
    {
      "id": 12179,
      "name": "Mirassol",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8141411,
        "lng": -49.507449
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.5818675,
        -20.976,
        -49.432,
        -20.6815182
      ]
    },
    {
      "id": 12180,
      "name": "Southampton",
      "names": {
        "zh": "南安普敦",
        "ja": "サザンプトン",
        "th": "เซาแธมป์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.894874,
        "lng": -72.3898303
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.3948303,
        40.889874,
        -72.3848303,
        40.899874
      ]
    },
    {
      "id": 12181,
      "name": "Xiezhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.91139,
        "lng": 110.84583
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        110.82583,
        34.89139,
        110.86583,
        34.93139
      ]
    },
    {
      "id": 12182,
      "name": "Santee",
      "names": {
        "zh": "桑蒂",
        "th": "แซนที"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8383828,
        "lng": -116.9739167
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.0384334,
        32.8156346,
        -116.9379995,
        32.9033814
      ]
    },
    {
      "id": 12183,
      "name": "Hameln",
      "names": {
        "zh": "哈默尔恩",
        "ja": "ハーメルン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1039941,
        "lng": 9.3561569
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.2592036,
        52.0429341,
        9.461703,
        52.1675652
      ]
    },
    {
      "id": 12184,
      "name": "Meleuz",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.96328,
        "lng": 55.928612
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        55.8833486,
        52.9291735,
        56.0246999,
        53.0027148
      ]
    },
    {
      "id": 12185,
      "name": "Khlong Luang",
      "names": {
        "th": "คลองหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0650298,
        "lng": 100.6454621
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.5905035,
        13.9955253,
        100.6532765,
        14.0664453
      ]
    },
    {
      "id": 12186,
      "name": "Antonio Enes",
      "names": {
        "th": "อันโตนิโอ"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.11667,
        "lng": 39.76667
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 12187,
      "name": "Allanmyo",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 19.366667,
        "lng": 95.216667
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        95.1108149,
        18.9302608,
        95.8591899,
        19.4829733
      ]
    },
    {
      "id": 12188,
      "name": "Samalkot",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.053932,
        "lng": 82.169607
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.156363,
        17.042181,
        82.180039,
        17.067652
      ]
    },
    {
      "id": 12189,
      "name": "Aleksin",
      "names": {
        "zh": "阿列克辛"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5028103,
        "lng": 37.0667213
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        36.9466132,
        54.4768058,
        37.1370599,
        54.5400141
      ]
    },
    {
      "id": 12190,
      "name": "Berezovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.911406,
        "lng": 60.799179
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.763916,
        56.891861,
        60.832054,
        56.936554
      ]
    },
    {
      "id": 12191,
      "name": "Ping'an",
      "names": {
        "zh": "平安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.399977,
        "lng": 101.9975
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        101.8156804,
        36.2507773,
        102.1864289,
        36.5630523
      ]
    },
    {
      "id": 12192,
      "name": "Kalna",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.2211183,
        "lng": 88.3591889
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.3191889,
        23.1811183,
        88.3991889,
        23.2611183
      ]
    },
    {
      "id": 12193,
      "name": "Beckley",
      "names": {
        "zh": "贝克利",
        "ja": "ベックリー",
        "th": "เบกลีย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7781702,
        "lng": -81.1881557
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.220509,
        37.75765,
        -81.141907,
        37.821444
      ]
    },
    {
      "id": 12194,
      "name": "Qianwu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.1680556,
        "lng": 113.2114328
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.1714328,
        22.1280556,
        113.2514328,
        22.2080556
      ]
    },
    {
      "id": 12195,
      "name": "Alexandroupoli",
      "names": {
        "it": "Alessandropoli"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.8484,
        "lng": 25.872387
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        25.852387,
        40.8284,
        25.892387,
        40.8684
      ]
    },
    {
      "id": 12196,
      "name": "Titusville",
      "names": {
        "zh": "泰特斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.6126236,
        "lng": -80.8079545
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.871651,
        28.4936303,
        -80.7778237,
        28.6517915
      ]
    },
    {
      "id": 12197,
      "name": "Tarsus",
      "names": {
        "es": "Tarso",
        "fr": "Tarse",
        "zh": "睑板",
        "it": "Tarso",
        "ja": "足根",
        "th": "เท้า",
        "pt": "Tarso"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9164834,
        "lng": 34.895149
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.5348138,
        36.728669,
        35.1516494,
        37.4356009
      ]
    },
    {
      "id": 12198,
      "name": "Cartersville",
      "names": {
        "zh": "卡特斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1652304,
        "lng": -84.7997613
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.865878,
        34.082431,
        -84.736495,
        34.293785
      ]
    },
    {
      "id": 12199,
      "name": "Bedzin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3230785,
        "lng": 19.1322201
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0583601,
        50.3011936,
        19.1896174,
        50.3702182
      ]
    },
    {
      "id": 12200,
      "name": "Walla Walla",
      "names": {
        "th": "วาลลาวาลลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.0667277,
        "lng": -118.339345
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.414914,
        46.0294886,
        -118.2420774,
        46.1030027
      ]
    },
    {
      "id": 12201,
      "name": "Orland Park",
      "names": {
        "es": "Parque de orland",
        "fr": "Parc orland",
        "zh": "奥兰多公园",
        "de": "Orland-Park",
        "it": "Parco orland",
        "id": "Taman orland",
        "ja": "オーランドパーク",
        "th": "ออร์แลนด์พาร์ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.612727,
        "lng": -87.856844
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.911636,
        41.552679,
        -87.789731,
        41.652588
      ]
    },
    {
      "id": 12202,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 41.8762163,
        "lng": 8.9779271
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        8.9546925,
        41.858524,
        9.009222,
        41.908829
      ]
    },
    {
      "id": 12203,
      "name": "Kothapet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.1733862,
        "lng": 78.9511213
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.9311213,
        19.1533862,
        78.9711213,
        19.1933862
      ]
    },
    {
      "id": 12204,
      "name": "Ciudad Cuauhtemoc",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.18439,
        "lng": -97.83472
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.85472,
        22.16439,
        -97.81472,
        22.20439
      ]
    },
    {
      "id": 12205,
      "name": "Tikhoretsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.851274,
        "lng": 40.117494
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.085445,
        45.830223,
        40.153778,
        45.875805
      ]
    },
    {
      "id": 12206,
      "name": "Towson",
      "names": {
        "zh": "陶森"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.396459050000004,
        "lng": -76.61481481790992
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.6671394,
        39.3719938,
        -76.5630253,
        39.4210052
      ]
    },
    {
      "id": 12207,
      "name": "Biala Podlaska",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0338399,
        "lng": 23.1193948
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.0650961,
        51.9963897,
        23.2114665,
        52.0616495
      ]
    },
    {
      "id": 12208,
      "name": "Orito",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 0.6686314,
        "lng": -76.8749187
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.1867965,
        0.4144597,
        -76.6526417,
        0.9437305
      ]
    },
    {
      "id": 12209,
      "name": "Manchester",
      "names": {
        "zh": "曼彻斯特",
        "ja": "マンチェスター",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7813345,
        "lng": -72.5304550138903
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.5613677,
        41.7634865,
        -72.4711102,
        41.7991529
      ]
    },
    {
      "id": 12210,
      "name": "Jasaan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6504031,
        "lng": 124.7547085
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.6019456,
        8.5914959,
        124.8139426,
        8.7045981
      ]
    },
    {
      "id": 12211,
      "name": "Qunghirot",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 43.04333,
        "lng": 58.83944
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        58.81944,
        43.02333,
        58.85944,
        43.06333
      ]
    },
    {
      "id": 12212,
      "name": "Minokamo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4406551,
        "lng": 137.0155052
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.959003,
        35.429897,
        137.086812,
        35.553711
      ]
    },
    {
      "id": 12213,
      "name": "Weymouth",
      "names": {
        "zh": "韦茅斯",
        "ja": "ウェイマス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2212188,
        "lng": -70.9391625
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -70.9812676,
        42.1355124,
        -70.9020447,
        42.2895784
      ]
    },
    {
      "id": 12214,
      "name": "Macherla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.4761003,
        "lng": 79.4364575
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.3964575,
        16.4361003,
        79.4764575,
        16.5161003
      ]
    },
    {
      "id": 12215,
      "name": "Campo Alegre",
      "names": {
        "ja": "カンポアレグレ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.7798607,
        "lng": -36.3575793
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.400968,
        -9.934,
        -36.179,
        -9.7043732
      ]
    },
    {
      "id": 12216,
      "name": "Ibitinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.75623,
        "lng": -48.831903
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.086538,
        -21.9275062,
        -48.6427052,
        -21.6511892
      ]
    },
    {
      "id": 12217,
      "name": "Pavlovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.965137,
        "lng": 43.071114
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        43.0119459,
        55.933962,
        43.1610609,
        56.0257721
      ]
    },
    {
      "id": 12218,
      "name": "Kandukur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.214929,
        "lng": 79.900269
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.88818,
        15.203323,
        79.916271,
        15.226902
      ]
    },
    {
      "id": 12219,
      "name": "Floriano",
      "names": {
        "zh": "弗洛里亚努"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.7675739,
        "lng": -43.022261
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.4409749,
        -7.358,
        -42.7936813,
        -6.6458361
      ]
    },
    {
      "id": 12220,
      "name": "Parker",
      "names": {
        "zh": "帕克",
        "ja": "パーカー",
        "th": "ปาร์กเกอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5184514,
        "lng": -104.7612638
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -104.821664,
        39.4530874,
        -104.7084852,
        39.5659609
      ]
    },
    {
      "id": 12221,
      "name": "Palm Beach Gardens",
      "names": {
        "es": "Jardines de Palm Beach",
        "fr": "Jardins de palme",
        "zh": "棕榈滩花园",
        "de": "Palm Beach Gärten",
        "id": "Taman Palm Beach",
        "ja": "パームビーチガーデンズ",
        "th": "สวนปาล์มบีช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.865718,
        "lng": -80.187528
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.269244,
        26.785865,
        -80.057275,
        26.89792
      ]
    },
    {
      "id": 12222,
      "name": "Krasnotur'insk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.769536,
        "lng": 60.204075
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.134666,
        59.751221,
        60.266083,
        59.796944
      ]
    },
    {
      "id": 12223,
      "name": "Bozeman",
      "names": {
        "zh": "博兹曼",
        "ja": "ボーズマン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.6794293,
        "lng": -111.044047
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.1198177,
        45.6347914,
        -110.984237,
        45.7332596
      ]
    },
    {
      "id": 12224,
      "name": "Diriamba",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.8561449,
        "lng": -86.2403037
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.5835772,
        11.492918,
        -86.2150356,
        11.8964845
      ]
    },
    {
      "id": 12225,
      "name": "Salsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.476669,
        "lng": 41.541008
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        41.4513368,
        46.4459666,
        41.6009932,
        46.5241952
      ]
    },
    {
      "id": 12226,
      "name": "Milagros",
      "names": {
        "zh": "米拉格罗斯",
        "ja": "ミラグロス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.218165,
        "lng": 123.5093507
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4693507,
        12.178165,
        123.5493507,
        12.258165
      ]
    },
    {
      "id": 12227,
      "name": "Beni Enzar",
      "names": {
        "it": "Beni Enotar",
        "ja": "ベニエンザル"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.259687,
        "lng": -2.933644
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -3.010162,
        35.1895487,
        -2.8413916,
        35.3563728
      ]
    },
    {
      "id": 12228,
      "name": "East Stroudsburg",
      "names": {
        "zh": "东斯特鲁兹堡",
        "id": "Stroudsburg Timur",
        "ja": "イーストストアバーグ",
        "th": "อีสต์สบูร์ก",
        "pt": "Stroudsburg do leste"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.999441,
        "lng": -75.177608
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.195662,
        40.984155,
        -75.161748,
        41.024624
      ]
    },
    {
      "id": 12229,
      "name": "Binalonan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0582693,
        "lng": 120.60044970733381
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.5671237,
        16.0227998,
        120.6363315,
        16.0921178
      ]
    },
    {
      "id": 12230,
      "name": "Alangalang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.20611,
        "lng": 124.84667
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.82667,
        11.18611,
        124.86667,
        11.22611
      ]
    },
    {
      "id": 12231,
      "name": "Santiago Tuxtla",
      "names": {
        "zh": "圣地亚哥Tuxtla"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4655533,
        "lng": -95.2996679
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.5450002,
        18.237478,
        -95.2326629,
        18.5732454
      ]
    },
    {
      "id": 12232,
      "name": "Nova Odessa",
      "names": {
        "zh": "新敖德萨",
        "ja": "ノヴァオデッサ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7805746,
        "lng": -47.2993805
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.3589052,
        -22.8316526,
        -47.2105564,
        -22.7292379
      ]
    },
    {
      "id": 12233,
      "name": "Uson",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.2251109,
        "lng": 123.78466
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.74466,
        12.1851109,
        123.82466,
        12.2651109
      ]
    },
    {
      "id": 12234,
      "name": "Ponte Nova",
      "names": {
        "ja": "ポンテノーカ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4164353,
        "lng": -42.90878
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.041,
        -20.567,
        -42.786,
        -20.273024
      ]
    },
    {
      "id": 12235,
      "name": "Icara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.7116553,
        "lng": -49.2971441
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.3569468,
        -28.8645734,
        -49.1726418,
        -28.6705349
      ]
    },
    {
      "id": 12236,
      "name": "Buluan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7166195,
        "lng": 124.7865943
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.7465943,
        6.6766195,
        124.8265943,
        6.7566195
      ]
    },
    {
      "id": 12237,
      "name": "Taunton",
      "names": {
        "zh": "汤顿",
        "ja": "トーントン",
        "th": "ทอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9019257,
        "lng": -71.0931485
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.1938999,
        41.838493,
        -70.9737172,
        41.9931781
      ]
    },
    {
      "id": 12238,
      "name": "Istaravshan",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 39.9142,
        "lng": 69.00328
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 12239,
      "name": "Tonsberg",
      "names": {
        "zh": "滕斯贝格"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2677363,
        "lng": 10.4080715
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.2480715,
        59.1077363,
        10.5680715,
        59.4277363
      ]
    },
    {
      "id": 12240,
      "name": "Hagonoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6884647,
        "lng": 125.2972001
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.230067,
        6.6559683,
        125.4946915,
        6.7419937
      ]
    },
    {
      "id": 12241,
      "name": "Midwest City",
      "names": {
        "es": "Ciudad Midwest",
        "fr": "Ville de Midwest",
        "zh": "中西部城市",
        "de": "Midwest-Stadt",
        "id": "Kota Midwest",
        "ja": "中西部の街",
        "th": "เมืองมิดเวสต์",
        "pt": "Cidade midwest"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4495097,
        "lng": -97.3967025
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.424125,
        35.4331883,
        -97.290611,
        35.51516
      ]
    },
    {
      "id": 12242,
      "name": "Frankfurt (Oder)",
      "names": {
        "fr": "Francfort (Oder)",
        "zh": "法兰克福（奥德）",
        "it": "Francoforte (oder)",
        "ja": "フランクフルト（ODER）",
        "th": "แฟรงค์เฟิร์ต (ODER)"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3412273,
        "lng": 14.549452
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.3948254,
        52.2528709,
        14.6013644,
        52.3980721
      ]
    },
    {
      "id": 12243,
      "name": "Uki",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 32.647181,
        "lng": 130.6839693
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.4223724,
        32.558581,
        130.7827141,
        32.706547
      ]
    },
    {
      "id": 12244,
      "name": "Sankt Augustin",
      "names": {
        "ja": "サンクトオーガスティン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7752776,
        "lng": 7.1895507
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.1254004,
        50.7393785,
        7.2456751,
        50.800533
      ]
    },
    {
      "id": 12245,
      "name": "Stolberg",
      "names": {
        "zh": "施托尔贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7702908,
        "lng": 6.2295504
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.1745342,
        50.6846999,
        6.3656141,
        50.8055718
      ]
    },
    {
      "id": 12246,
      "name": "M'diq",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.6833598,
        "lng": -5.3232161
      },
      "country": "Morocco",
      "importance": 3,
      "bbox": [
        -5.3655385,
        35.6423662,
        -5.2733302,
        35.7158588
      ]
    },
    {
      "id": 12247,
      "name": "Coruripe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.1249963,
        "lng": -36.1760623
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.4524122,
        -10.2640577,
        -36.0308309,
        -9.933
      ]
    },
    {
      "id": 12248,
      "name": "Riosucio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.4394444,
        "lng": -77.1152778
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.1153278,
        7.4393944,
        -77.1152278,
        7.4394944
      ]
    },
    {
      "id": 12249,
      "name": "Sattenapalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.396489,
        "lng": 80.150462
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.138826,
        16.385118,
        80.174669,
        16.412339
      ]
    },
    {
      "id": 12250,
      "name": "Bobbili",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.573426,
        "lng": 83.357733
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        83.343841,
        18.565221,
        83.368389,
        18.58026
      ]
    },
    {
      "id": 12251,
      "name": "Irpin",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5215143,
        "lng": 30.24757697942141
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        30.2469612,
        50.5189263,
        30.2485147,
        50.5229529
      ]
    },
    {
      "id": 12252,
      "name": "Krymsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.92914055,
        "lng": 37.99381116593713
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        37.9933957,
        44.9288925,
        37.9942332,
        44.9293767
      ]
    },
    {
      "id": 12253,
      "name": "Andradina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.896505,
        "lng": -51.3742765
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.481,
        -21.085,
        -51.134,
        -20.646
      ]
    },
    {
      "id": 12254,
      "name": "Kudamatsu",
      "names": {
        "zh": "下松"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0149872,
        "lng": 131.8704567
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.7349086,
        33.8157841,
        131.936192,
        34.114896
      ]
    },
    {
      "id": 12255,
      "name": "Goldsboro",
      "names": {
        "zh": "戈尔兹伯勒",
        "th": "โกลด์สโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3848841,
        "lng": -77.9927651
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.065893,
        35.312426,
        -77.89099,
        35.443116
      ]
    },
    {
      "id": 12256,
      "name": "Azzaba",
      "names": {
        "zh": "阿扎巴"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7388133,
        "lng": 7.1062378
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        6.9944382,
        36.6774001,
        7.1883953,
        36.828745
      ]
    },
    {
      "id": 12257,
      "name": "Foligno",
      "names": {
        "zh": "福利尼奥",
        "ja": "フォリーニョ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.9559744,
        "lng": 12.7034782
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.6115981,
        42.9040477,
        12.951439,
        43.0721145
      ]
    },
    {
      "id": 12258,
      "name": "Euless",
      "names": {
        "th": "ยูเลส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8370727,
        "lng": -97.0819541
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.155983,
        32.810594,
        -97.033088,
        32.8816288
      ]
    },
    {
      "id": 12259,
      "name": "Shicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4886496,
        "lng": 116.1820292
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.1420292,
        38.4486496,
        116.2220292,
        38.5286496
      ]
    },
    {
      "id": 12260,
      "name": "Talagante",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6644388,
        "lng": -70.9302778
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.0261502,
        -33.7238767,
        -70.7918115,
        -33.6311135
      ]
    },
    {
      "id": 12261,
      "name": "Vrindavan",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5753726,
        "lng": 77.6938045
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.6538045,
        27.5353726,
        77.7338045,
        27.6153726
      ]
    },
    {
      "id": 12262,
      "name": "Pacos de Ferreira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2781581,
        "lng": -8.3780463
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3780963,
        41.2781081,
        -8.3779963,
        41.2782081
      ]
    },
    {
      "id": 12263,
      "name": "Eschweiler",
      "names": {
        "zh": "埃施韦勒"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8175029,
        "lng": 6.2630894
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.1904859,
        50.7782884,
        6.3409387,
        50.8840433
      ]
    },
    {
      "id": 12264,
      "name": "Kan'onjicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1283265,
        "lng": 133.6499396
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.6399396,
        34.1183265,
        133.6599396,
        34.1383265
      ]
    },
    {
      "id": 12265,
      "name": "Ribnita",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.765946,
        "lng": 29.007048
      },
      "country": "Moldova",
      "importance": 3,
      "bbox": [
        28.983314,
        47.741649,
        29.045336,
        47.788238
      ]
    },
    {
      "id": 12266,
      "name": "Langenhagen",
      "names": {
        "zh": "朗根哈根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.443118,
        "lng": 9.738994
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        9.6288452,
        52.4228424,
        9.7766353,
        52.506383
      ]
    },
    {
      "id": 12267,
      "name": "Manfredonia",
      "names": {
        "zh": "曼弗雷多尼亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6262667,
        "lng": 15.9139646
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        15.9139146,
        41.6262167,
        15.9140146,
        41.6263167
      ]
    },
    {
      "id": 12268,
      "name": "Meerbusch",
      "names": {
        "zh": "梅尔布施"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2652237,
        "lng": 6.6760958
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.5851188,
        51.2336017,
        6.734752,
        51.3367399
      ]
    },
    {
      "id": 12269,
      "name": "General Pico",
      "names": {
        "fr": "Général pico",
        "zh": "普通的普通",
        "de": "Allgemeines Pico",
        "it": "Generale Pico",
        "id": "Pico umum",
        "ja": "一般ピコ",
        "th": "ทั่วไปปิโก"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.6591085,
        "lng": -63.7576103
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.7952109,
        -35.6968636,
        -63.7242317,
        -35.6338538
      ]
    },
    {
      "id": 12270,
      "name": "Manhattan",
      "names": {
        "zh": "曼哈顿",
        "ja": "マンハッタン",
        "th": "แมนฮัตตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1836082,
        "lng": -96.5716694
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.6902756,
        39.1241341,
        -96.4967126,
        39.2287959
      ]
    },
    {
      "id": 12271,
      "name": "Shoreline",
      "names": {
        "es": "Costa",
        "fr": "Littoral",
        "zh": "海岸线",
        "de": "Küste",
        "id": "Garis pantai",
        "ja": "海岸線",
        "th": "ชายฝั่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.7564667,
        "lng": -122.3437497
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.3948511,
        47.7337633,
        -122.2921328,
        47.7780453
      ]
    },
    {
      "id": 12272,
      "name": "Lian",
      "names": {
        "zh": "涟",
        "ja": "リアン",
        "th": "เหลียน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0361178,
        "lng": 120.653454
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3627498,
        13.9192321,
        120.6885052,
        14.0578524
      ]
    },
    {
      "id": 12273,
      "name": "Neryungri",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.6579435,
        "lng": 124.7123042
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        124.5253234,
        56.6367025,
        124.767323,
        56.6946282
      ]
    },
    {
      "id": 12274,
      "name": "Moreno",
      "names": {
        "zh": "莫雷诺",
        "ja": "モレノ",
        "th": "เรโน"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.113669,
        "lng": -35.090904
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.12573,
        -8.135373,
        -35.068515,
        -8.094972
      ]
    },
    {
      "id": 12275,
      "name": "Chini",
      "names": {
        "zh": "奇尼"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3882224,
        "lng": 113.0754844
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.0554844,
        23.3682224,
        113.0954844,
        23.4082224
      ]
    },
    {
      "id": 12276,
      "name": "Tiwi",
      "names": {
        "zh": "提维"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4569196,
        "lng": 123.6796912
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6396912,
        13.4169196,
        123.7196912,
        13.4969196
      ]
    },
    {
      "id": 12277,
      "name": "Tama",
      "names": {
        "zh": "多摩",
        "ja": "多摩",
        "th": "มะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6849947,
        "lng": 139.4042867
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.0493379,
        35.5249864,
        139.7956218,
        35.8151978
      ]
    },
    {
      "id": 12278,
      "name": "Puerto Boyaca",
      "names": {
        "zh": "波多黎各博纳卡",
        "ja": "プエルトボヤカ",
        "th": "เปอร์โตบอยคา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9756376,
        "lng": -74.5882837
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.6593289,
        5.7363454,
        -74.2227822,
        6.2756273
      ]
    },
    {
      "id": 12279,
      "name": "Cuemba",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.15,
        "lng": 18.083333
      },
      "country": "Angola",
      "importance": 3,
      "bbox": [
        18.043333,
        -12.19,
        18.123333,
        -12.11
      ]
    },
    {
      "id": 12280,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1282559,
        "lng": -83.5753386
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.817682,
        33.9657304,
        -83.357051,
        34.295276
      ]
    },
    {
      "id": 12281,
      "name": "Ramon",
      "names": {
        "zh": "拉蒙",
        "ja": "ラモン",
        "th": "รามอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.7815027,
        "lng": 121.5351148
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4951148,
        16.7415027,
        121.5751148,
        16.8215027
      ]
    },
    {
      "id": 12282,
      "name": "Gangarampur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4070884,
        "lng": 88.516124
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.476124,
        25.3670884,
        88.556124,
        25.4470884
      ]
    },
    {
      "id": 12283,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0641992,
        "lng": 125.6093294
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.5890459,
        7.0579401,
        125.6279118,
        7.105258
      ]
    },
    {
      "id": 12284,
      "name": "Paombong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.83111,
        "lng": 120.78917
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.76917,
        14.81111,
        120.80917,
        14.85111
      ]
    },
    {
      "id": 12285,
      "name": "Bagheria",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 38.0780514,
        "lng": 13.50057798401668
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        13.5002639,
        38.0777427,
        13.5009044,
        38.0782786
      ]
    },
    {
      "id": 12286,
      "name": "Piscataway",
      "names": {
        "ja": "ピスカタウェイ",
        "th": "พิสแคต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5462553,
        "lng": -74.4660408
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.519503,
        40.5057156,
        -74.4086806,
        40.597188
      ]
    },
    {
      "id": 12287,
      "name": "Verviers",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.589731,
        "lng": 5.865258
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.815684,
        50.554765,
        5.917693,
        50.637708
      ]
    },
    {
      "id": 12288,
      "name": "Lagonoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7342,
        "lng": 123.5207
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.5007,
        13.7142,
        123.5407,
        13.7542
      ]
    },
    {
      "id": 12289,
      "name": "Alcobaca",
      "names": {
        "es": "Alcobaça",
        "zh": "阿尔科巴萨",
        "pt": "Alcobaça"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.550219,
        "lng": -8.975359035947783
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9898385,
        39.5404321,
        -8.964007,
        39.5600108
      ]
    },
    {
      "id": 12290,
      "name": "Lake Havasu City",
      "names": {
        "es": "Ciudad del lago Havasu",
        "zh": "哈瓦苏湖城",
        "id": "Kota Danau Havasu",
        "ja": "レイクハバスシティ",
        "th": "เมืองทะเลสาบ Havasu",
        "pt": "Cidade do Lago Havasu"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4775296,
        "lng": -114.3379972
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -114.3758094,
        34.4302861,
        -114.2420218,
        34.589601
      ]
    },
    {
      "id": 12291,
      "name": "Linares",
      "names": {
        "zh": "利纳雷斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.0931247,
        "lng": -3.6357207
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.7620536,
        38.0021713,
        -3.5171467,
        38.1733367
      ]
    },
    {
      "id": 12292,
      "name": "Jacunda",
      "names": {
        "zh": "雅昆达"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.446173,
        "lng": -49.115272
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.4967754,
        -4.8662536,
        -49.0004402,
        -4.3176492
      ]
    },
    {
      "id": 12293,
      "name": "Basey",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.28167,
        "lng": 125.06833
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.04833,
        11.26167,
        125.08833,
        11.30167
      ]
    },
    {
      "id": 12294,
      "name": "Murakami",
      "names": {
        "zh": "村上",
        "ja": "村上",
        "th": "มุราคามิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.23333,
        "lng": 139.48333
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.46333,
        38.21333,
        139.50333,
        38.25333
      ]
    },
    {
      "id": 12295,
      "name": "Cuneo",
      "names": {
        "zh": "库内奥",
        "ja": "クーネオ",
        "th": "คูเอโน"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.389633,
        "lng": 7.5479007
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        7.4745897,
        44.3472648,
        7.640694,
        44.4899189
      ]
    },
    {
      "id": 12296,
      "name": "Tilakpur",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 29.76713,
        "lng": 78.1729
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        78.1529,
        29.74713,
        78.1929,
        29.78713
      ]
    },
    {
      "id": 12297,
      "name": "Tivoli",
      "names": {
        "zh": "的Tivoli",
        "ja": "のTivoli"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.960922,
        "lng": 12.798884
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.6899187,
        41.9164454,
        12.8477635,
        42.0060733
      ]
    },
    {
      "id": 12298,
      "name": "Waiblingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8325659,
        "lng": 9.3163822
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.2761629,
        48.8105117,
        9.4429788,
        48.9091159
      ]
    },
    {
      "id": 12299,
      "name": "Kandi",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.957862,
        "lng": 88.0422449
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.0022449,
        23.917862,
        88.0822449,
        23.997862
      ]
    },
    {
      "id": 12300,
      "name": "Viseu",
      "names": {
        "zh": "维塞乌"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.204892,
        "lng": -46.1392244
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.8483339,
        -2.2372361,
        -46.0614248,
        -0.8849127
      ]
    },
    {
      "id": 12301,
      "name": "Pangantocan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.84722,
        "lng": 124.8125
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.7925,
        7.82722,
        124.8325,
        7.86722
      ]
    },
    {
      "id": 12302,
      "name": "Taquaritinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.4064327,
        "lng": -48.5056132
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.725308,
        -21.5670723,
        -48.3119602,
        -21.3163882
      ]
    },
    {
      "id": 12303,
      "name": "Mengla",
      "names": {
        "zh": "勐腊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.477351,
        "lng": 101.572036
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.552036,
        21.457351,
        101.592036,
        21.497351
      ]
    },
    {
      "id": 12304,
      "name": "Bertioga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.848568,
        "lng": -46.139586
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.2423349,
        -23.8886738,
        -45.793,
        -23.643
      ]
    },
    {
      "id": 12305,
      "name": "Smyrna",
      "names": {
        "zh": "士麦那",
        "ja": "スマーナ",
        "th": "ในสเมอร์นา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.883887,
        "lng": -84.5147454
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.561735,
        33.798745,
        -84.470347,
        33.904033
      ]
    },
    {
      "id": 12306,
      "name": "Salgueiro",
      "names": {
        "zh": "萨尔盖鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0726731,
        "lng": -39.1269719
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.3173577,
        -8.3805005,
        -38.8125344,
        -7.7710742
      ]
    },
    {
      "id": 12307,
      "name": "Quezaltepeque",
      "names": {
        "zh": "克萨尔特佩克"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.833727,
        "lng": -89.2708537
      },
      "country": "El Salvador",
      "importance": 3,
      "bbox": [
        -89.3279366,
        13.7372547,
        -89.2184419,
        13.9531335
      ]
    },
    {
      "id": 12308,
      "name": "Cui'erzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2811783,
        "lng": 116.5460908
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        116.5060908,
        38.2411783,
        116.5860908,
        38.3211783
      ]
    },
    {
      "id": 12309,
      "name": "Placer",
      "names": {
        "zh": "砂矿",
        "ja": "砂鉱",
        "th": "จัดวาง"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.8702901,
        "lng": 123.9182252
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.8782252,
        11.8302901,
        123.9582252,
        11.9102901
      ]
    },
    {
      "id": 12310,
      "name": "Opava",
      "names": {
        "zh": "奥帕瓦"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.9389004,
        "lng": 17.9024174
      },
      "country": "Czechia",
      "importance": 3,
      "bbox": [
        17.7909898,
        49.8477811,
        17.996374,
        49.9940115
      ]
    },
    {
      "id": 12311,
      "name": "Nogata",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.743936,
        "lng": 130.7297462
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.6800893,
        33.699956,
        130.8055503,
        33.796054
      ]
    },
    {
      "id": 12312,
      "name": "Libungan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2434829,
        "lng": 124.5164209
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.5163709,
        7.2434329,
        124.5164709,
        7.2435329
      ]
    },
    {
      "id": 12313,
      "name": "Taybad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.74,
        "lng": 60.7756
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        60.7556,
        34.72,
        60.7956,
        34.76
      ]
    },
    {
      "id": 12314,
      "name": "Trani",
      "names": {
        "ja": "トラーニ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2728011,
        "lng": 16.4177766
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        16.4127766,
        41.2678011,
        16.4227766,
        41.2778011
      ]
    },
    {
      "id": 12315,
      "name": "Hohoe",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 7.15181,
        "lng": 0.47362
      },
      "country": "Ghana",
      "importance": 3,
      "bbox": [
        0.45362,
        7.13181,
        0.49362,
        7.17181
      ]
    },
    {
      "id": 12316,
      "name": "Bianyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.6265,
        "lng": 106.63
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        106.59,
        25.5865,
        106.67,
        25.6665
      ]
    },
    {
      "id": 12317,
      "name": "Cape Girardeau",
      "names": {
        "es": "Cabo Girardeau",
        "de": "Kap Girardeau",
        "it": "Capo Girardeau",
        "id": "Jempol",
        "ja": "ケープジラードー",
        "th": "เคปกิเรรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.311679,
        "lng": -89.549764
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.621064,
        37.218245,
        -89.490778,
        37.389696
      ]
    },
    {
      "id": 12318,
      "name": "Bartolome Maso",
      "names": {
        "ja": "バルトロームマソ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.1676753,
        "lng": -76.9449426
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.0819075,
        19.9481304,
        -76.783569,
        20.2672628
      ]
    },
    {
      "id": 12319,
      "name": "Amealco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1878975,
        "lng": -100.144747
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.160983,
        20.1780972,
        -100.134794,
        20.1977026
      ]
    },
    {
      "id": 12320,
      "name": "Tome-Acu",
      "names": {
        "es": "Tomo-ACU",
        "fr": "Tomé-acu",
        "zh": "美-急性",
        "ja": "本-acu",
        "th": "เล่ม-acu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.41889,
        "lng": -48.15222
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.17222,
        -2.43889,
        -48.13222,
        -2.39889
      ]
    },
    {
      "id": 12321,
      "name": "Magsaysay",
      "names": {
        "zh": "麦格赛赛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7517369,
        "lng": 125.1504638
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.0522017,
        6.6717579,
        125.1977062,
        6.869033
      ]
    },
    {
      "id": 12322,
      "name": "Belampalli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.02621,
        "lng": 79.50574
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.48574,
        19.00621,
        79.52574,
        19.04621
      ]
    },
    {
      "id": 12323,
      "name": "Amarante",
      "names": {
        "zh": "阿马兰特"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2699316,
        "lng": -8.0804563
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2163135,
        41.1894414,
        -7.8940469,
        41.3751958
      ]
    },
    {
      "id": 12324,
      "name": "Winneba",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 5.35113,
        "lng": -0.62313
      },
      "country": "Ghana",
      "importance": 3,
      "bbox": [
        -0.64313,
        5.33113,
        -0.60313,
        5.37113
      ]
    },
    {
      "id": 12325,
      "name": "Araci",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.3390214,
        "lng": -38.9586849
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.311226,
        -11.493466,
        -38.7741465,
        -10.9860817
      ]
    },
    {
      "id": 12326,
      "name": "Gorlitz",
      "names": {
        "es": "Görlitz",
        "fr": "Görlitz",
        "de": "Görlitz",
        "it": "Görlitz",
        "pt": "Görlitz"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1563185,
        "lng": 14.991018
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.9116798,
        51.052564,
        15.0179368,
        51.2279599
      ]
    },
    {
      "id": 12327,
      "name": "Gulariya",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.2048538,
        "lng": 81.3455485
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        81.3055485,
        28.1648538,
        81.3855485,
        28.2448538
      ]
    },
    {
      "id": 12328,
      "name": "Monte Alegre",
      "names": {
        "ja": "モンテアレグレ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.9989108,
        "lng": -54.0727694
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.9315525,
        -2.4154129,
        -53.7861824,
        0.372453
      ]
    },
    {
      "id": 12329,
      "name": "Pithoragarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.585871,
        "lng": 80.215167
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.175167,
        29.545871,
        80.255167,
        29.625871
      ]
    },
    {
      "id": 12330,
      "name": "Oosterhout",
      "names": {
        "zh": "奥斯特豪特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.649594,
        "lng": 4.856132
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.787506,
        51.603774,
        4.894892,
        51.686017
      ]
    },
    {
      "id": 12331,
      "name": "Pila",
      "names": {
        "zh": "皮拉"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2347317,
        "lng": 121.3640614
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.3243388,
        14.1946802,
        121.388095,
        14.2938285
      ]
    },
    {
      "id": 12332,
      "name": "Blainville",
      "names": {
        "zh": "布兰维尔"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.67932,
        "lng": -73.87619
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.9254873,
        45.6428786,
        -73.7890055,
        45.7247461
      ]
    },
    {
      "id": 12333,
      "name": "Registro",
      "names": {
        "zh": "登记"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.493907,
        "lng": -47.848112
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.897039,
        -24.54449,
        -47.789538,
        -24.445547
      ]
    },
    {
      "id": 12334,
      "name": "Takizawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.73477,
        "lng": 141.0770901
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.000384,
        39.689931,
        141.168087,
        39.879253
      ]
    },
    {
      "id": 12335,
      "name": "Limoeiro do Norte",
      "names": {
        "zh": "Limoeiro做北部",
        "ja": "リムエイロドノルテ",
        "th": "Limoeiro ทำ Norte"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1455607,
        "lng": -38.0984936
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.2485577,
        -5.2971669,
        -37.7823387,
        -4.9739598
      ]
    },
    {
      "id": 12336,
      "name": "Xinqing",
      "names": {
        "zh": "新青"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.2820553,
        "lng": 129.5145241
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        129.5045241,
        48.2720553,
        129.5245241,
        48.2920553
      ]
    },
    {
      "id": 12337,
      "name": "Antratsyt",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.119404,
        "lng": 39.080854
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        39.05112,
        48.095749,
        39.160425,
        48.164054
      ]
    },
    {
      "id": 12338,
      "name": "Diffun",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5935291,
        "lng": 121.5024247
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.3803866,
        16.4205958,
        121.5716008,
        16.6451327
      ]
    },
    {
      "id": 12339,
      "name": "Irati",
      "names": {
        "zh": "伊拉蒂"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.469663,
        "lng": -50.649287
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.145,
        -25.621,
        -50.5793051,
        -25.354
      ]
    },
    {
      "id": 12340,
      "name": "Urbiztondo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.8227,
        "lng": 120.3295
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3095,
        15.8027,
        120.3495,
        15.8427
      ]
    },
    {
      "id": 12341,
      "name": "Xanthi",
      "names": {
        "zh": "克桑西",
        "id": "Xánthi"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.13805,
        "lng": 24.8864089
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.7264089,
        40.97805,
        25.0464089,
        41.29805
      ]
    },
    {
      "id": 12342,
      "name": "Zarrin Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3897,
        "lng": 51.3766
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.3566,
        32.3697,
        51.3966,
        32.4097
      ]
    },
    {
      "id": 12343,
      "name": "Khan Shaykhun",
      "names": {
        "ja": "カーンシェイフン",
        "th": "ข่าน Shaykhun"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.44208,
        "lng": 36.65095
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.63095,
        35.42208,
        36.67095,
        35.46208
      ]
    },
    {
      "id": 12344,
      "name": "Longmen",
      "names": {
        "zh": "龙门",
        "ja": "龍門"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8037413,
        "lng": 114.0518037
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        113.9500451,
        28.7286037,
        114.149054,
        28.9104
      ]
    },
    {
      "id": 12345,
      "name": "Lozova",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8842428,
        "lng": 36.3160254
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.2681935,
        48.8577711,
        36.3617957,
        48.9128261
      ]
    },
    {
      "id": 12346,
      "name": "Bisceglie",
      "names": {
        "zh": "比谢列"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2402745,
        "lng": 16.5010109
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        16.42867,
        41.1480347,
        16.5422648,
        41.2569295
      ]
    },
    {
      "id": 12347,
      "name": "General Tinio",
      "names": {
        "fr": "Général Tinio",
        "zh": "一般锡奥",
        "de": "Allgemeines Tinio",
        "it": "Generale Tinio",
        "ja": "一般的なティニオ",
        "th": "นายพล tinio"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3501261,
        "lng": 121.0498041
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0098041,
        15.3101261,
        121.0898041,
        15.3901261
      ]
    },
    {
      "id": 12348,
      "name": "Parachinar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 33.899183,
        "lng": 70.100827
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        70.0808267811,
        33.8791827586,
        70.1208267811,
        33.9191827586
      ]
    },
    {
      "id": 12349,
      "name": "Yongqing",
      "names": {
        "zh": "永庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0119097,
        "lng": 105.4416137
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        105.4016137,
        29.9719097,
        105.4816137,
        30.0519097
      ]
    },
    {
      "id": 12350,
      "name": "Katerini",
      "names": {
        "id": "Kateríni"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.270885,
        "lng": 22.502983
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.488748,
        40.260071,
        22.536373,
        40.28067
      ]
    },
    {
      "id": 12351,
      "name": "Frydek-Mistek",
      "names": {
        "es": "Frýdek-místek",
        "de": "Frýdek-místek",
        "it": "Frýdek-místek"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.685635,
        "lng": 18.3483416
      },
      "country": "Czechia",
      "importance": 3,
      "bbox": [
        18.2477746,
        49.6307782,
        18.4356145,
        49.7286892
      ]
    },
    {
      "id": 12352,
      "name": "Bambang",
      "names": {
        "zh": "总统"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3850624,
        "lng": 121.1054715
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.0654715,
        16.3450624,
        121.1454715,
        16.4250624
      ]
    },
    {
      "id": 12353,
      "name": "Boituva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2838893,
        "lng": -47.6738231
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.7797663,
        -23.3787837,
        -47.572947,
        -23.1916154
      ]
    },
    {
      "id": 12354,
      "name": "Portimao",
      "names": {
        "zh": "波尔蒂芒",
        "id": "Portimão",
        "th": "ปอร์ติเมา",
        "pt": "Portimão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1375808,
        "lng": -8.5368426
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6846649,
        37.1084236,
        -8.4852034,
        37.2799169
      ]
    },
    {
      "id": 12355,
      "name": "Den Helder",
      "names": {
        "fr": "Cravate",
        "de": "Helder",
        "id": "Pelan",
        "ja": "デンバー",
        "th": "เดนเฮลเดอร์",
        "pt": "Dendeir"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.957976,
        "lng": 4.760279
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6294507,
        52.8786262,
        4.925838,
        53.0219782
      ]
    },
    {
      "id": 12356,
      "name": "Wangguanzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0215585,
        "lng": 115.5773551
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.5373551,
        36.9815585,
        115.6173551,
        37.0615585
      ]
    },
    {
      "id": 12357,
      "name": "Volzhsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.866657,
        "lng": 48.35204
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        48.313278,
        55.840778,
        48.398193,
        55.879917
      ]
    },
    {
      "id": 12358,
      "name": "Changling",
      "names": {
        "zh": "长岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.2711815,
        "lng": 123.9799267
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.8199267,
        44.1111815,
        124.1399267,
        44.4311815
      ]
    },
    {
      "id": 12359,
      "name": "Hidaka",
      "names": {
        "zh": "日高",
        "ja": "日高"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9077667,
        "lng": 139.3390385
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.275402,
        35.8681685,
        139.4018805,
        35.928259
      ]
    },
    {
      "id": 12360,
      "name": "Toki",
      "names": {
        "zh": "土岐",
        "ja": "トキ",
        "th": "โทกิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3524854,
        "lng": 137.1834191
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.145352,
        35.251464,
        137.282913,
        35.403819
      ]
    },
    {
      "id": 12361,
      "name": "Catu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.351274,
        "lng": -38.379108
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.54,
        -12.432429,
        -38.2721761,
        -12.2186671
      ]
    },
    {
      "id": 12362,
      "name": "Baden-Baden",
      "names": {
        "zh": "巴登 - 巴登",
        "ja": "バーデンバーデン",
        "th": "บาเดน-บาเดน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7610716,
        "lng": 8.239959
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1077765,
        48.6667428,
        8.3209469,
        48.8479125
      ]
    },
    {
      "id": 12363,
      "name": "Xiva",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.3776893,
        "lng": 60.3620006
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        60.3233067,
        41.3694516,
        60.3822243,
        41.4190827
      ]
    },
    {
      "id": 12364,
      "name": "Grants Pass",
      "names": {
        "es": "Pase de subvenciones",
        "fr": "Passe les subventions",
        "zh": "拨款通过",
        "de": "Zuschüsse passieren",
        "it": "Sovvenzionare il passaggio",
        "id": "Hibah lulus",
        "ja": "付与パス",
        "th": "เงินช่วยเหลือ",
        "pt": "Subsídios"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4393707,
        "lng": -123.3272489
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -123.3912853,
        42.3897047,
        -123.2825593,
        42.4730075
      ]
    },
    {
      "id": 12365,
      "name": "Blue Springs",
      "names": {
        "es": "Muelles azules",
        "fr": "Ressorts bleus",
        "zh": "蓝色泉水",
        "de": "Blaue Federn",
        "it": "Molle blu",
        "id": "Mata air biru",
        "ja": "青いばね",
        "th": "น้ำพุสีน้ำเงิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.017316,
        "lng": -94.282265
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.337106,
        38.9381782,
        -94.221198,
        39.066243
      ]
    },
    {
      "id": 12366,
      "name": "Batac",
      "names": {
        "zh": "巴塔克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 18.0554,
        "lng": 120.56489
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.54489,
        18.0354,
        120.58489,
        18.0754
      ]
    },
    {
      "id": 12367,
      "name": "Hashtgerd",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.962,
        "lng": 50.6799
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.6599,
        35.942,
        50.6999,
        35.982
      ]
    },
    {
      "id": 12368,
      "name": "Jaru",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.44413,
        "lng": -62.471139
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -62.501439,
        -10.470682,
        -62.436397,
        -10.414642
      ]
    },
    {
      "id": 12369,
      "name": "Gus'-Khrustal'nyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.606803,
        "lng": 40.662525
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        40.623501,
        55.585918,
        40.702305,
        55.630611
      ]
    },
    {
      "id": 12370,
      "name": "Tucano",
      "names": {
        "zh": "巨嘴鸟"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.963517,
        "lng": -38.785957
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.1144922,
        -11.3563555,
        -38.5561272,
        -10.7180629
      ]
    },
    {
      "id": 12371,
      "name": "Martin",
      "names": {
        "zh": "马丁",
        "ja": "マーティン",
        "th": "มาร์ติน"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0724921,
        "lng": 18.929193
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.80652,
        49.0337062,
        18.9651086,
        49.1270172
      ]
    },
    {
      "id": 12372,
      "name": "Umm Ruwaba",
      "names": {
        "zh": "嗯鲁瓦巴",
        "ja": "UMMルワバ",
        "th": "อืมรูวิบะ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.905825,
        "lng": 31.2147916
      },
      "country": "Sudan",
      "importance": 3,
      "bbox": [
        31.0547916,
        12.745825,
        31.3747916,
        13.065825
      ]
    },
    {
      "id": 12373,
      "name": "Southaven",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 34.9873822,
        "lng": -90.0034862
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.079466,
        34.902816,
        -89.918613,
        34.9950488
      ]
    },
    {
      "id": 12374,
      "name": "Tinley Park",
      "names": {
        "es": "Parque de tinley",
        "fr": "Parc Tinley Park",
        "zh": "tinley公园",
        "id": "Taman Tinley",
        "ja": "ティンリーパーク",
        "pt": "Parque tinley"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5733669,
        "lng": -87.7844944
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.856311,
        41.5252,
        -87.75941,
        41.6038573
      ]
    },
    {
      "id": 12375,
      "name": "Ovar",
      "names": {
        "zh": "奥瓦尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.860766,
        "lng": -8.628466
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.656208,
        40.848723,
        -8.612764,
        40.874577
      ]
    },
    {
      "id": 12376,
      "name": "Apatity",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 67.566965,
        "lng": 33.397442
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        33.366333,
        67.558388,
        33.426998,
        67.574554
      ]
    },
    {
      "id": 12377,
      "name": "Azul",
      "names": {
        "zh": "阿苏尔",
        "ja": "アズール"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -36.7858619,
        "lng": -59.8530999
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.8580999,
        -36.7908619,
        -59.8480999,
        -36.7808619
      ]
    },
    {
      "id": 12378,
      "name": "Taua",
      "names": {
        "pt": "Tauá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.0031372,
        "lng": -40.2947268
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.7466324,
        -6.3584912,
        -39.8316227,
        -5.3971198
      ]
    },
    {
      "id": 12379,
      "name": "Diamond Bar",
      "names": {
        "es": "Bar de diamantes",
        "fr": "Barreau de diamant",
        "zh": "钻石吧",
        "de": "Diamantbar",
        "id": "Bar berlian",
        "ja": "ダイヤモンドバー",
        "th": "เพชรบาร์",
        "pt": "Barro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0286226,
        "lng": -117.8103367
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8638931,
        33.9606657,
        -117.7670557,
        34.0429101
      ]
    },
    {
      "id": 12380,
      "name": "Mulanay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.52222,
        "lng": 122.40417
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.38417,
        13.50222,
        122.42417,
        13.54222
      ]
    },
    {
      "id": 12381,
      "name": "Pittsfield",
      "names": {
        "zh": "皮茨菲尔德",
        "ja": "ピッツフィールド",
        "th": "พิตต์สฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4484778,
        "lng": -73.2541069
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.3389078,
        42.3981309,
        -73.1821975,
        42.5046083
      ]
    },
    {
      "id": 12382,
      "name": "Narbonne",
      "names": {
        "zh": "纳博讷",
        "ja": "ナルボンヌ",
        "th": "นาร์บอนน์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1837757,
        "lng": 3.0041906
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.8823262,
        43.0612232,
        3.1849916,
        43.2376902
      ]
    },
    {
      "id": 12383,
      "name": "Asturias",
      "names": {
        "zh": "阿斯图里亚斯",
        "ja": "アストゥリアス",
        "th": "อัสตูเรียส",
        "pt": "Astúrias"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5677403,
        "lng": 123.7160037
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.6760037,
        10.5277403,
        123.7560037,
        10.6077403
      ]
    },
    {
      "id": 12384,
      "name": "Eagle Pass",
      "names": {
        "fr": "Passe-fort",
        "zh": "鹰通",
        "de": "Adlerpass",
        "it": "Aquila Pass",
        "id": "Lulus",
        "ja": "イーグルパス",
        "pt": "Passagem de águia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.7083999,
        "lng": -100.503941
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -100.5117503,
        28.663328,
        -100.4265893,
        28.7456597
      ]
    },
    {
      "id": 12385,
      "name": "Narutocho-mitsuishi",
      "names": {
        "zh": "narutocho-三石"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.19933,
        "lng": 134.60932
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12386,
      "name": "Aran Bidgol",
      "names": {
        "th": "Aran Bodgol"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.0584233,
        "lng": 51.4835403
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.4542206,
        34.0263041,
        51.5018544,
        34.0863064
      ]
    },
    {
      "id": 12387,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 26.1497977,
        "lng": -97.9136112
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -97.9503643,
        26.1249495,
        -97.8617893,
        26.1974655
      ]
    },
    {
      "id": 12388,
      "name": "Limoeiro",
      "names": {
        "zh": "利穆埃鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.872599,
        "lng": -35.440154
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.5287438,
        -7.9612317,
        -35.353,
        -7.7140159
      ]
    },
    {
      "id": 12389,
      "name": "Monte Mor",
      "names": {
        "zh": "蒙特尔",
        "id": "Mor",
        "ja": "モンテル",
        "th": "หมอมอนเต"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.945043,
        "lng": -47.312182
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.4074113,
        -23.043074,
        -47.2014552,
        -22.8626457
      ]
    },
    {
      "id": 12390,
      "name": "Canterbury",
      "names": {
        "zh": "坎特伯雷",
        "ja": "カンタベリー",
        "th": "อังกฤษ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2800275,
        "lng": 1.0802533
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        0.9372021,
        51.1771875,
        1.2500695,
        51.3825395
      ]
    },
    {
      "id": 12391,
      "name": "Lenexa",
      "names": {
        "th": "เลเนซา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.963446,
        "lng": -94.802073
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.908931,
        38.915532,
        -94.705003,
        38.992858
      ]
    },
    {
      "id": 12392,
      "name": "Corby",
      "names": {
        "zh": "科比",
        "th": "คอร์บี้"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4877341,
        "lng": -0.7032713
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.7415967,
        52.456763,
        -0.6416811,
        52.5151932
      ]
    },
    {
      "id": 12393,
      "name": "Hazleton",
      "names": {
        "zh": "黑兹利顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9548956,
        "lng": -75.9769572
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.9964541,
        40.9280137,
        -75.945795,
        40.9748671
      ]
    },
    {
      "id": 12394,
      "name": "El Banco",
      "names": {
        "ja": "エルバンコ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.0049173,
        "lng": -73.9738472
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.1787112,
        8.9171083,
        -73.7975417,
        9.3511192
      ]
    },
    {
      "id": 12395,
      "name": "Manapla",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9591466,
        "lng": 123.1234936
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0406528,
        10.8197573,
        123.1819546,
        11.0389715
      ]
    },
    {
      "id": 12396,
      "name": "Twin Falls",
      "names": {
        "es": "Caídas gemelas",
        "zh": "双胞胎瀑布",
        "ja": "双子が落ちます",
        "th": "ทวินฟอลส์",
        "pt": "Gêmeo cai"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5558542,
        "lng": -114.4700684
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -114.5159306,
        42.512526,
        -114.379026,
        42.60642
      ]
    },
    {
      "id": 12397,
      "name": "Brookhaven",
      "names": {
        "zh": "布鲁克海文",
        "th": "บรุกเฮเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8584368,
        "lng": -84.340203
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.348524,
        33.824777,
        -84.308164,
        33.920202
      ]
    },
    {
      "id": 12398,
      "name": "Schweinfurt",
      "names": {
        "zh": "施韦因富特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0499945,
        "lng": 10.233302
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        10.193302,
        50.0099945,
        10.273302,
        50.0899945
      ]
    },
    {
      "id": 12399,
      "name": "Horizonte",
      "names": {
        "zh": "奥里藏特",
        "th": "โอรีซอนตี"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.0964618,
        "lng": -38.4965574
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.5703908,
        -4.1451546,
        -38.3725086,
        -4.0413507
      ]
    },
    {
      "id": 12400,
      "name": "Villarrica",
      "names": {
        "zh": "比亚里卡",
        "th": "บียาร์รีกา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -39.2780911,
        "lng": -72.2274364
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -72.43682,
        -39.513956,
        -71.908951,
        -39.0596086
      ]
    },
    {
      "id": 12401,
      "name": "Porirua",
      "names": {
        "zh": "波里鲁瓦"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.13756775,
        "lng": 174.84339017515356
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        174.8433193,
        -41.1378227,
        174.8434612,
        -41.1373721
      ]
    },
    {
      "id": 12402,
      "name": "Novato",
      "names": {
        "zh": "诺瓦托"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1058658,
        "lng": -122.566106
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.566156,
        38.1058158,
        -122.566056,
        38.1059158
      ]
    },
    {
      "id": 12403,
      "name": "Tigard",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 45.4307473,
        "lng": -122.771933
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.8569239,
        45.3932421,
        -122.7434206,
        45.4564334
      ]
    },
    {
      "id": 12404,
      "name": "Hoogeveen",
      "names": {
        "zh": "霍赫芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7206964,
        "lng": 6.4647093
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.4646593,
        52.7206464,
        6.4647593,
        52.7207464
      ]
    },
    {
      "id": 12405,
      "name": "Jaguaquara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -13.5294974,
        "lng": -39.9693081
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.1506378,
        -13.7859791,
        -39.7131971,
        -13.3069519
      ]
    },
    {
      "id": 12406,
      "name": "San Remo",
      "names": {
        "zh": "圣雷莫",
        "ja": "サンレモ",
        "th": "ซานเรโม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8198253,
        "lng": 7.7748827
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        7.708241,
        43.7959567,
        7.8470261,
        43.876459
      ]
    },
    {
      "id": 12407,
      "name": "Hyeres",
      "names": {
        "es": "Hyères",
        "de": "Hyères",
        "it": "Hyères",
        "id": "Hyères",
        "ja": "イエール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1202573,
        "lng": 6.1301614
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        6.079861,
        42.9820137,
        6.5112861,
        43.2070192
      ]
    },
    {
      "id": 12408,
      "name": "Yamasa",
      "names": {
        "ja": "ヤマサ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.7698357,
        "lng": -70.0248777
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.2488597,
        18.6600199,
        -69.9183267,
        18.8752572
      ]
    },
    {
      "id": 12409,
      "name": "Abington",
      "names": {
        "zh": "阿宾顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1048228,
        "lng": -70.9453218
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.002152,
        42.0871965,
        -70.9159085,
        42.1513719
      ]
    },
    {
      "id": 12410,
      "name": "Rolim de Moura",
      "names": {
        "ja": "ロリムデモーラ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.725957,
        "lng": -61.779819
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -61.805705,
        -11.744051,
        -61.759439,
        -11.705089
      ]
    },
    {
      "id": 12411,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 41.7571701,
        "lng": -88.3147539
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -88.4083712,
        41.679869,
        -88.204992,
        41.8221793
      ]
    },
    {
      "id": 12412,
      "name": "Bitonto",
      "names": {
        "zh": "比通托"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1084971,
        "lng": 16.691548
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        16.4721794,
        40.944751,
        16.7560393,
        41.1553248
      ]
    },
    {
      "id": 12413,
      "name": "Barbalha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.3121067,
        "lng": -39.3036427
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.4462942,
        -7.6151737,
        -39.2066933,
        -7.2583261
      ]
    },
    {
      "id": 12414,
      "name": "Highland",
      "names": {
        "zh": "高原",
        "ja": "ハイランド",
        "th": "ไฮแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.11213,
        "lng": -117.1807973
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.2610571,
        34.076909,
        -117.063355,
        34.1427375
      ]
    },
    {
      "id": 12415,
      "name": "La Roche-sur-Yon",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6705431,
        "lng": -1.4269698
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.5094696,
        46.6108632,
        -1.3229062,
        46.7207969
      ]
    },
    {
      "id": 12416,
      "name": "Dearborn Heights",
      "names": {
        "fr": "Charborn hauteurs",
        "zh": "亲爱的高度",
        "de": "Dearborn-Höhen",
        "it": "Earborn Heights",
        "id": "Tinggi Dearborn",
        "ja": "ダイナボーンの高さ",
        "th": "ความสูงที่เดียร์บอร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3369816,
        "lng": -83.2732627
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.312825,
        42.2682452,
        -83.2176918,
        42.357055
      ]
    },
    {
      "id": 12417,
      "name": "Puttur",
      "names": {
        "zh": "布杜尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.4381418,
        "lng": 79.5522138
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.5122138,
        13.3981418,
        79.5922138,
        13.4781418
      ]
    },
    {
      "id": 12418,
      "name": "Songlindian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.4118199,
        "lng": 115.9387019
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.8987019,
        39.3718199,
        115.9787019,
        39.4518199
      ]
    },
    {
      "id": 12419,
      "name": "San Isidro",
      "names": {
        "zh": "圣伊斯丹",
        "ja": "サンアイズロ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.8595624,
        "lng": 123.9562221
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.9162221,
        9.8195624,
        123.9962221,
        9.8995624
      ]
    },
    {
      "id": 12420,
      "name": "Hattingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4007175,
        "lng": 7.1862486
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.1080627,
        51.3099383,
        7.2571612,
        51.4252894
      ]
    },
    {
      "id": 12421,
      "name": "Bad Homburg",
      "names": {
        "zh": "坏鸿斯堡",
        "de": "Schlechte Homburg",
        "it": "Cattivo homburg",
        "ja": "悪いホームブルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.23041,
        "lng": 8.610123
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.496285,
        50.20067,
        8.71238,
        50.274666
      ]
    },
    {
      "id": 12422,
      "name": "Pombal",
      "names": {
        "zh": "蓬巴尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9161851,
        "lng": -8.6279171
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9322325,
        39.7768928,
        -8.4699909,
        40.0455029
      ]
    },
    {
      "id": 12423,
      "name": "Christchurch",
      "names": {
        "zh": "基督城",
        "ja": "クライストチャーチ",
        "th": "ไครสต์เชิร์ช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.734902,
        "lng": -1.7778853
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -1.8156089,
        50.7205956,
        -1.7220783,
        50.7674254
      ]
    },
    {
      "id": 12424,
      "name": "Grapevine",
      "names": {
        "fr": "La vigne",
        "zh": "葡萄",
        "ja": "グレープバイン",
        "th": "เกรปไวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9337381,
        "lng": -97.0788754
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.1298384,
        32.878493,
        -97.01569,
        32.993643
      ]
    },
    {
      "id": 12425,
      "name": "Islampur",
      "names": {
        "zh": "伊斯兰布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.2563651,
        "lng": 88.1860517
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.1460517,
        26.2163651,
        88.2260517,
        26.2963651
      ]
    },
    {
      "id": 12426,
      "name": "Modica",
      "names": {
        "es": "De modica",
        "zh": "莫迪卡"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 36.8589209,
        "lng": 14.7613018
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.7124891,
        36.7036829,
        14.9382812,
        37.038655
      ]
    },
    {
      "id": 12427,
      "name": "Chino",
      "names": {
        "zh": "奇诺",
        "ja": "チノ",
        "th": "สีกากี"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9942674,
        "lng": 138.1521884
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.1471884,
        35.9892674,
        138.1571884,
        35.9992674
      ]
    },
    {
      "id": 12428,
      "name": "Fond du Lac",
      "names": {
        "zh": "喜欢杜拉",
        "it": "Affettuoso du lac",
        "ja": "フォンデュラ",
        "th": "รัก du lac"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.7533414,
        "lng": -88.4493796
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.8860006,
        43.542896,
        -88.159737,
        43.938759
      ]
    },
    {
      "id": 12429,
      "name": "Punganuru",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.36385,
        "lng": 78.5721781
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.5321781,
        13.32385,
        78.6121781,
        13.40385
      ]
    },
    {
      "id": 12430,
      "name": "Hacienda Heights",
      "names": {
        "fr": "Hacienda hauteur",
        "de": "Hacienda-Höhen",
        "it": "Height Heights",
        "ja": "ハシエンダハイツ",
        "pt": "Horas Haciendas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9930677,
        "lng": -117.968675
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -118.0093087,
        33.9725389,
        -117.931186,
        34.0312778
      ]
    },
    {
      "id": 12431,
      "name": "Juruti",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.1538114,
        "lng": -56.0965019
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.0965519,
        -2.1538614,
        -56.0964519,
        -2.1537614
      ]
    },
    {
      "id": 12432,
      "name": "Nandod",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.842921150000002,
        "lng": 73.44954074831094
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        73.2967378,
        21.7033898,
        73.668909,
        21.9799006
      ]
    },
    {
      "id": 12433,
      "name": "Melton",
      "names": {
        "zh": "梅尔顿",
        "ja": "メルトン",
        "th": "ฅัน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -37.6835714,
        "lng": 144.5828446
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        144.567048,
        -37.702408,
        144.647847,
        -37.648496
      ]
    },
    {
      "id": 12434,
      "name": "Konan",
      "names": {
        "zh": "湖南",
        "ja": "甲南"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3349527,
        "lng": 136.8721818
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.8671818,
        35.3299527,
        136.8771818,
        35.3399527
      ]
    },
    {
      "id": 12435,
      "name": "Apple Valley",
      "names": {
        "es": "Valle de manzana",
        "fr": "Vallée de pomme",
        "zh": "苹果谷",
        "de": "Apfeltal",
        "id": "Lembah Apple",
        "ja": "アップルバレー",
        "pt": "Vale da Apple"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.7319094,
        "lng": -93.21772
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.2483505,
        44.7173359,
        -93.151344,
        44.7758872
      ]
    },
    {
      "id": 12436,
      "name": "Tiquisate",
      "names": {
        "zh": "蒂基萨特"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.28333,
        "lng": -91.36667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.38667,
        14.26333,
        -91.34667,
        14.30333
      ]
    },
    {
      "id": 12437,
      "name": "Chicopee",
      "names": {
        "zh": "奇科皮"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1487691,
        "lng": -72.6071119
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.628629,
        42.1237714,
        -72.5139094,
        42.2187322
      ]
    },
    {
      "id": 12438,
      "name": "Carigara",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.3017446,
        "lng": 124.6872516
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.6471084,
        11.15323,
        124.749123,
        11.4042408
      ]
    },
    {
      "id": 12439,
      "name": "Funato",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2489544,
        "lng": 135.3121861
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.3021861,
        34.2389544,
        135.3221861,
        34.2589544
      ]
    },
    {
      "id": 12440,
      "name": "Vannes",
      "names": {
        "zh": "瓦纳"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.65837395,
        "lng": -2.760930218996881
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.761092,
        47.658165,
        -2.760771,
        47.658581
      ]
    },
    {
      "id": 12441,
      "name": "Zhushan",
      "names": {
        "zh": "竹山"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.7579721,
        "lng": 120.6727749
      },
      "country": "Taiwan",
      "importance": 3,
      "bbox": [
        120.62871,
        23.5965353,
        120.8251745,
        23.8340237
      ]
    },
    {
      "id": 12442,
      "name": "Ucuma",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.86383,
        "lng": 15.07124
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 12443,
      "name": "Santa Isabel",
      "names": {
        "zh": "圣徒伊莎贝尔",
        "ja": "サンタアイサベル",
        "th": "ซานตาอิซาเบล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3194222,
        "lng": -46.2271903
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.363197,
        -23.407,
        -46.101,
        -23.183
      ]
    },
    {
      "id": 12444,
      "name": "San Gil",
      "names": {
        "zh": "圣吉尔",
        "ja": "サンギル",
        "th": "ซานกิล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5552856,
        "lng": -73.1312353
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.2112469,
        6.4796807,
        -73.0286053,
        6.65028
      ]
    },
    {
      "id": 12445,
      "name": "Keighley",
      "names": {
        "zh": "基斯利",
        "th": "ไคลีย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.866704,
        "lng": -1.910393
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -2.046132,
        53.828823,
        -1.817754,
        53.906543
      ]
    },
    {
      "id": 12446,
      "name": "Estancia",
      "names": {
        "zh": "埃斯坦西亚",
        "ja": "エスタンシア"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.4560924,
        "lng": 123.1510998
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.1042526,
        11.3949343,
        123.2166081,
        11.4952924
      ]
    },
    {
      "id": 12447,
      "name": "Cathedral City",
      "names": {
        "es": "Ciudad de la Catedral",
        "fr": "Ville de la cathédrale",
        "zh": "大教堂城市",
        "de": "Kathedrale",
        "it": "Città della cattedrale",
        "id": "Kota katedral",
        "ja": "大聖堂の街",
        "th": "เมืองมหาวิหาร",
        "pt": "Cidade da Catedral"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8237241,
        "lng": -116.46687476187614
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -116.5043233,
        33.7559883,
        -116.3294009,
        33.8913441
      ]
    },
    {
      "id": 12448,
      "name": "Huaishu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0840097,
        "lng": 115.0623434
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.0223434,
        38.0440097,
        115.1023434,
        38.1240097
      ]
    },
    {
      "id": 12449,
      "name": "Vicosa do Ceara",
      "names": {
        "zh": "vicosa做ceara"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.5667684,
        "lng": -41.091504
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.3713134,
        -3.7190365,
        -40.8768707,
        -3.2878928
      ]
    },
    {
      "id": 12450,
      "name": "Pontevedra",
      "names": {
        "zh": "庞特维德拉",
        "ja": "ポンテベドラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3678837,
        "lng": 122.8703897
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.7559006,
        10.3124524,
        123.0186942,
        10.3965626
      ]
    },
    {
      "id": 12451,
      "name": "Porto Ferreira",
      "names": {
        "zh": "波尔图弗雷拉",
        "ja": "ポルトフェレイラ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.8529134,
        "lng": -47.4794943
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.5535925,
        -21.9353185,
        -47.3344016,
        -21.755
      ]
    },
    {
      "id": 12452,
      "name": "Indaial",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.8901747,
        "lng": -49.2416736
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.3250446,
        -27.164039,
        -49.128,
        -26.830541
      ]
    },
    {
      "id": 12453,
      "name": "Alenquer",
      "names": {
        "zh": "阿伦克尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.946229,
        "lng": -54.738355
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -55.4135643,
        -2.1669461,
        -54.3598309,
        1.5501996
      ]
    },
    {
      "id": 12454,
      "name": "Sara",
      "names": {
        "zh": "萨拉",
        "ja": "サラ",
        "th": "ซาร่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2566801,
        "lng": 123.0143933
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9743933,
        11.2166801,
        123.0543933,
        11.2966801
      ]
    },
    {
      "id": 12455,
      "name": "Barreirinhas",
      "names": {
        "zh": "巴雷里尼亚斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.7541271,
        "lng": -42.8259693
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.2427527,
        -3.2161368,
        -42.6415021,
        -2.454
      ]
    },
    {
      "id": 12456,
      "name": "Delano",
      "names": {
        "zh": "德拉诺",
        "ja": "デラノ",
        "th": "เดลาโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7688425,
        "lng": -119.247053
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -119.333829,
        35.717896,
        -119.205394,
        35.7904306
      ]
    },
    {
      "id": 12457,
      "name": "Minamiuonuma",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 37.0655723,
        "lng": 138.8760989
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.76461,
        36.887214,
        139.097843,
        37.208736
      ]
    },
    {
      "id": 12458,
      "name": "Bentonville",
      "names": {
        "zh": "本顿维尔",
        "th": "เบนตันวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3728538,
        "lng": -94.2088172
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.326235,
        36.2710581,
        -94.1545877,
        36.4386657
      ]
    },
    {
      "id": 12459,
      "name": "Stonecrest",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.6831296,
        "lng": -84.1358505
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.201754,
        33.614666,
        -84.074332,
        33.758081
      ]
    },
    {
      "id": 12460,
      "name": "Sakurai",
      "names": {
        "zh": "樱井",
        "ja": "桜井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5186316,
        "lng": 135.8434724
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        135.818206,
        34.443277,
        135.947523,
        34.591719
      ]
    },
    {
      "id": 12461,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 37.050096,
        "lng": -121.99059
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -122.3220529,
        36.8488209,
        -121.5823066,
        37.2876789
      ]
    },
    {
      "id": 12462,
      "name": "Kettering",
      "names": {
        "zh": "凯特林",
        "th": "เค็ตเตอริง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6895036,
        "lng": -84.1688274
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -84.214729,
        39.65495,
        -84.0999923,
        39.7365246
      ]
    },
    {
      "id": 12463,
      "name": "Mali",
      "names": {
        "es": "Malí",
        "zh": "马里",
        "ja": "マリ",
        "th": "มาลี"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 4.1779879,
        "lng": 73.5107387
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        73.4545947,
        4.1683039,
        73.5519146,
        4.2411711
      ]
    },
    {
      "id": 12464,
      "name": "Sarpsborg",
      "names": {
        "zh": "萨尔普斯堡"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2838792,
        "lng": 11.1096115
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.9496115,
        59.1238792,
        11.2696115,
        59.4438792
      ]
    },
    {
      "id": 12465,
      "name": "Colton",
      "names": {
        "zh": "科尔顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0739016,
        "lng": -117.3136547
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.3710478,
        34.0187308,
        -117.269514,
        34.095469
      ]
    },
    {
      "id": 12466,
      "name": "Pulheim",
      "names": {
        "zh": "普尔海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9979888,
        "lng": 6.8025409
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.7113808,
        50.9387455,
        6.8447836,
        51.0659186
      ]
    },
    {
      "id": 12467,
      "name": "Cholet",
      "names": {
        "zh": "绍莱"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.0617293,
        "lng": -0.8801359
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.9451224,
        46.9689063,
        -0.8048323,
        47.1028193
      ]
    },
    {
      "id": 12468,
      "name": "Annaka",
      "names": {
        "zh": "安中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3256133,
        "lng": 138.8896338
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.647804,
        36.2762239,
        138.953807,
        36.416012
      ]
    },
    {
      "id": 12469,
      "name": "Xielu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.03472,
        "lng": 115.69278
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.67278,
        37.01472,
        115.71278,
        37.05472
      ]
    },
    {
      "id": 12470,
      "name": "Kingston",
      "names": {
        "zh": "金士顿",
        "ja": "キングストン",
        "th": "คิงส์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9287812,
        "lng": -74.0023702
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.044882,
        41.901894,
        -73.953191,
        41.953084
      ]
    },
    {
      "id": 12471,
      "name": "Ostroleka",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.0843135,
        "lng": 21.5668607
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.5356154,
        53.0433259,
        21.6660711,
        53.1123739
      ]
    },
    {
      "id": 12472,
      "name": "Taquara",
      "names": {
        "zh": "塔夸拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.647179,
        "lng": -50.777192
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.811385,
        -29.688112,
        -50.744575,
        -29.612729
      ]
    },
    {
      "id": 12473,
      "name": "Terneuzen",
      "names": {
        "zh": "泰尔讷曾"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.29884295,
        "lng": 3.8422043956030683
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.6441491,
        51.2001621,
        4.005693,
        51.3976164
      ]
    },
    {
      "id": 12474,
      "name": "Dandarah",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.14208,
        "lng": 32.67015
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 12475,
      "name": "Monte Santo",
      "names": {
        "zh": "蒙特桑托",
        "ja": "モンテサント"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.4382898,
        "lng": -39.3304065
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.7691364,
        -10.6977302,
        -39.1430346,
        -10.064572
      ]
    },
    {
      "id": 12476,
      "name": "Chota",
      "names": {
        "zh": "焦达"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.5614846,
        "lng": -78.6509551
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -78.7394874,
        -6.6807558,
        -78.5317912,
        -6.4598867
      ]
    },
    {
      "id": 12477,
      "name": "Lingen",
      "names": {
        "zh": "林根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.5224659,
        "lng": 7.316584
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.1935481,
        52.4201232,
        7.4539855,
        52.6022057
      ]
    },
    {
      "id": 12478,
      "name": "Jinchang",
      "names": {
        "zh": "金昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4761291,
        "lng": 112.9584765
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.9184765,
        38.4361291,
        112.9984765,
        38.5161291
      ]
    },
    {
      "id": 12479,
      "name": "Masinloc",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5363,
        "lng": 119.9502
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        119.9302,
        15.5163,
        119.9702,
        15.5563
      ]
    },
    {
      "id": 12480,
      "name": "Teramo",
      "names": {
        "zh": "泰拉莫"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.65811795,
        "lng": 13.69787458752063
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.3469322,
        42.4211162,
        14.1463154,
        42.8950814
      ]
    },
    {
      "id": 12481,
      "name": "San Manuel",
      "names": {
        "zh": "圣曼努埃尔",
        "de": "Sanuel",
        "ja": "サンマヌエル",
        "th": "ซานมานูเอล",
        "pt": "Sanguinência"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0643792,
        "lng": 120.6681808
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.6281808,
        16.0243792,
        120.7081808,
        16.1043792
      ]
    },
    {
      "id": 12482,
      "name": "Bad Salzuflen",
      "names": {
        "fr": "Mauvais salzuflen",
        "zh": "坏salzuflen",
        "de": "Schlechte Salzuflen",
        "ja": "悪いザラフレン",
        "th": "salzuflen ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.0815942,
        "lng": 8.740687224836543
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        8.7397597,
        52.0812073,
        8.7416228,
        52.0819756
      ]
    },
    {
      "id": 12483,
      "name": "West Haven",
      "names": {
        "zh": "西避风港",
        "de": "Westhafen",
        "id": "Surga barat",
        "ja": "ウェストヘイブン",
        "th": "เวสต์สวรรค์",
        "pt": "Paraíso ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2709645,
        "lng": -72.9632651
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.9682651,
        41.2659645,
        -72.9582651,
        41.2759645
      ]
    },
    {
      "id": 12484,
      "name": "Chenab Nagar",
      "names": {
        "ja": "チェナブナガル"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.759552,
        "lng": 72.91456
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.878923,
        31.739329,
        72.947697,
        31.775505
      ]
    },
    {
      "id": 12485,
      "name": "Dayin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9356456,
        "lng": 115.7046899
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.6646899,
        38.8956456,
        115.7446899,
        38.9756456
      ]
    },
    {
      "id": 12486,
      "name": "Nihonmatsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 37.5850396,
        "lng": 140.4313889
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.28047,
        37.498517,
        140.686242,
        37.651306
      ]
    },
    {
      "id": 12487,
      "name": "Trento",
      "names": {
        "zh": "特伦托",
        "de": "Trient",
        "ja": "トレント",
        "th": "เทรนโต"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.043508,
        "lng": 126.0633157
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        126.00048,
        7.9378193,
        126.355208,
        8.292381
      ]
    },
    {
      "id": 12488,
      "name": "Cava de' Tirreni",
      "names": {
        "es": "Cava de 'Tirreni",
        "fr": "Cava de 'Tirreni",
        "zh": "Cava de'Tirreni",
        "de": "Cava de 'Tirreni",
        "it": "Cava de 'Tirreni",
        "id": "Cava de 'Tirreni",
        "ja": "カヴァデ 'チラレニ",
        "th": "Cava de 'tirreni",
        "pt": "Cava de 'Tirreni"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7004616,
        "lng": 14.7062633
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.6576811,
        40.6723017,
        14.7479532,
        40.7520394
      ]
    },
    {
      "id": 12489,
      "name": "St. Cloud",
      "names": {
        "es": "Nube",
        "fr": "St. nuage",
        "zh": "圣云",
        "de": "St. Wolke",
        "ja": "聖雲",
        "th": "เซนต์คลาวด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.2498534,
        "lng": -81.2839038
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.339483,
        28.1735615,
        -81.2016928,
        28.2693036
      ]
    },
    {
      "id": 12490,
      "name": "Chivilcoy",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8969591,
        "lng": -60.0190499
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.1103115,
        -35.0081721,
        -59.9578162,
        -34.8423802
      ]
    },
    {
      "id": 12491,
      "name": "Montesilvano",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 42.5136032,
        "lng": 14.1516203
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.099867,
        42.4655981,
        14.1858358,
        42.5268746
      ]
    },
    {
      "id": 12492,
      "name": "Tapas",
      "names": {
        "zh": "小吃",
        "ja": "スペイン風居酒屋料理",
        "th": "ทาปาส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": -2.2107893,
        "lng": 132.3622692
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        132.3422692,
        -2.2307893,
        132.3822692,
        -2.1907893
      ]
    },
    {
      "id": 12493,
      "name": "Sao Jose do Rio Pardo",
      "names": {
        "zh": "圣何塞做Rio Pardo",
        "id": "Sao Jose melakukan Rio Pardo",
        "ja": "サンノゼはリオポルドをしています",
        "th": "Sao Jose ทำ Rio Pardo",
        "pt": "São José do Rio Pardo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.595288,
        "lng": -46.887303
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.0378098,
        -21.708,
        -46.757,
        -21.484
      ]
    },
    {
      "id": 12494,
      "name": "Kotka",
      "names": {
        "zh": "科特卡"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4674228,
        "lng": 26.9450844
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.7411301,
        60.2002026,
        27.3031365,
        60.6755736
      ]
    },
    {
      "id": 12495,
      "name": "Shidong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.619154,
        "lng": 112.067105
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        111.9532641,
        23.4589069,
        112.1642713,
        23.6852191
      ]
    },
    {
      "id": 12496,
      "name": "Kavala",
      "names": {
        "zh": "卡瓦拉",
        "ja": "カバラ"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9369224,
        "lng": 24.4122766
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.2522766,
        40.7769224,
        24.5722766,
        41.0969224
      ]
    },
    {
      "id": 12497,
      "name": "Qingan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.877586,
        "lng": 127.5068482
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        127.3468482,
        46.717586,
        127.6668482,
        47.037586
      ]
    },
    {
      "id": 12498,
      "name": "Huixtla",
      "names": {
        "zh": "维斯特拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.122125,
        "lng": -92.50399707604466
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.732494,
        14.9515784,
        -92.4146574,
        15.2927879
      ]
    },
    {
      "id": 12499,
      "name": "Palmaner",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.2,
        "lng": 78.74725
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12500,
      "name": "Normal",
      "names": {
        "fr": "Ordinaire",
        "zh": "正常",
        "it": "Normale",
        "ja": "通常の",
        "th": "ปกติ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5092961,
        "lng": -88.9843937
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.060454,
        40.493833,
        -88.9028751,
        40.5617532
      ]
    },
    {
      "id": 12501,
      "name": "Minalabac",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5688948,
        "lng": 123.1816453
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.1416453,
        13.5288948,
        123.2216453,
        13.6088948
      ]
    },
    {
      "id": 12502,
      "name": "Tlacotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.389167,
        "lng": -98.288056
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.288106,
        19.389117,
        -98.288006,
        19.389217
      ]
    },
    {
      "id": 12503,
      "name": "Itupeva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1531055,
        "lng": -47.0580347
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.1470934,
        -23.24217,
        -46.9951395,
        -23.0480691
      ]
    },
    {
      "id": 12504,
      "name": "Pinto",
      "names": {
        "zh": "平托",
        "ja": "ピント",
        "th": "ม้าลาย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.2429919,
        "lng": -3.7038365
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.7088365,
        40.2379919,
        -3.6988365,
        40.2479919
      ]
    },
    {
      "id": 12505,
      "name": "Sado",
      "names": {
        "zh": "佐渡",
        "ja": "佐渡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.0182578,
        "lng": 138.3683995
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        137.5316348,
        37.5025647,
        138.9150362,
        38.6063007
      ]
    },
    {
      "id": 12506,
      "name": "Siena",
      "names": {
        "fr": "Sienne",
        "zh": "锡耶纳",
        "ja": "シエナ",
        "th": "เซีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.3185536,
        "lng": 11.3316533
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.2300025,
        43.2498915,
        11.4285563,
        43.3663312
      ]
    },
    {
      "id": 12507,
      "name": "El Rama",
      "names": {
        "ja": "エルラマ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.1676124,
        "lng": -84.2279750819483
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -84.2361263,
        12.1579737,
        -84.2067643,
        12.1773817
      ]
    },
    {
      "id": 12508,
      "name": "Hanyu",
      "names": {
        "zh": "汉语"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1724023,
        "lng": 139.5484797
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.488028,
        36.1367501,
        139.61311,
        36.210375
      ]
    },
    {
      "id": 12509,
      "name": "Jalor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.349917,
        "lng": 72.626061
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.595118,
        25.326616,
        72.653408,
        25.372178
      ]
    },
    {
      "id": 12510,
      "name": "Kaliyaganj",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.632854,
        "lng": 88.310617
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.317125,
        25.623566,
        88.345415,
        25.649317
      ]
    },
    {
      "id": 12511,
      "name": "Cachoeiras de Macacu",
      "names": {
        "id": "Cachoeiras de Macabu",
        "ja": "カチョウィラスマカチ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4626861,
        "lng": -42.6534414
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.9229916,
        -22.6865326,
        -42.550467,
        -22.3552637
      ]
    },
    {
      "id": 12512,
      "name": "Milford",
      "names": {
        "zh": "米尔福德",
        "ja": "ミルフォード",
        "th": "มิลฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2222218,
        "lng": -73.0570603
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.122453,
        41.1693202,
        -72.979047,
        41.2775696
      ]
    },
    {
      "id": 12513,
      "name": "Tarma",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -11.4199882,
        "lng": -75.6877142
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -75.9170592,
        -11.5671296,
        -75.5939589,
        -11.3512704
      ]
    },
    {
      "id": 12514,
      "name": "Frejus",
      "names": {
        "fr": "Fréjus",
        "zh": "弗雷瑞斯",
        "id": "Fréjus"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.4330308,
        "lng": 6.7360182
      },
      "country": "France",
      "importance": 3,
      "bbox": [
        6.6858534,
        43.3683,
        6.8963421,
        43.5336349
      ]
    },
    {
      "id": 12515,
      "name": "Zarafshon Shahri",
      "names": {
        "ja": "ザラフションShahri"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5715707,
        "lng": 64.2053263
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        64.1743724,
        41.5155893,
        64.6087966,
        41.5943214
      ]
    },
    {
      "id": 12516,
      "name": "Xingcheng",
      "names": {
        "zh": "兴城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1396868,
        "lng": 118.302869
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        118.262869,
        40.0996868,
        118.342869,
        40.1796868
      ]
    },
    {
      "id": 12517,
      "name": "Valle del Guamuez",
      "names": {
        "es": "Valle del Guamüez",
        "id": "Valle del Guamunez",
        "ja": "ヴァッレデルグアメス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.4251351,
        "lng": -76.9037138
      },
      "country": "Colombia",
      "importance": 3,
      "bbox": [
        -76.9437138,
        0.3851351,
        -76.8637138,
        0.4651351
      ]
    },
    {
      "id": 12518,
      "name": "Vikarabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.3379216,
        "lng": 77.9039857
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        77.8639857,
        17.2979216,
        77.9439857,
        17.3779216
      ]
    },
    {
      "id": 12519,
      "name": "Hashtpar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.7974981,
        "lng": 48.904184
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.864184,
        37.7574981,
        48.944184,
        37.8374981
      ]
    },
    {
      "id": 12520,
      "name": "Ruteng",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.6110678,
        "lng": 120.4645903
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        120.4245903,
        -8.6510678,
        120.5045903,
        -8.5710678
      ]
    },
    {
      "id": 12521,
      "name": "Rotorua",
      "names": {
        "zh": "罗托鲁瓦",
        "ja": "ロトルア",
        "th": "โรโตรัว"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -38.1381493,
        "lng": 176.252922
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        176.092922,
        -38.2981493,
        176.412922,
        -37.9781493
      ]
    },
    {
      "id": 12522,
      "name": "Ixhuatlan de Madero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.685909,
        "lng": -98.014688
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.0197002134,
        20.6787254156,
        -98.0075759658,
        20.6969022116
      ]
    },
    {
      "id": 12523,
      "name": "Narva",
      "names": {
        "zh": "纳尔瓦"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3766729,
        "lng": 28.1921457
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        28.0112942,
        59.2951969,
        28.2090382,
        59.44386
      ]
    },
    {
      "id": 12524,
      "name": "Barra",
      "names": {
        "zh": "巴拉",
        "ja": "バーラ",
        "th": "รา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.0891794,
        "lng": -43.1414512
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.024,
        -11.932248,
        -42.6200448,
        -10.2367432
      ]
    },
    {
      "id": 12525,
      "name": "Tokmok",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8373462,
        "lng": 75.2953881
      },
      "country": "Kyrgyzstan",
      "importance": 3,
      "bbox": [
        75.2276298,
        42.7749237,
        75.3611109,
        42.860782
      ]
    },
    {
      "id": 12526,
      "name": "Zhigulevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.3941,
        "lng": 49.5146
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12527,
      "name": "Arroyo Grande",
      "names": {
        "ja": "アロヨグランデ",
        "pt": "Arroio grande"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1235374,
        "lng": -120.5785796
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.6095169,
        35.1014482,
        -120.5516643,
        35.1468272
      ]
    },
    {
      "id": 12528,
      "name": "Kampen",
      "names": {
        "zh": "坎彭"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5559484,
        "lng": 5.9033303
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.7777501,
        52.5256147,
        5.9922831,
        52.6283023
      ]
    },
    {
      "id": 12529,
      "name": "Titay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.869181,
        "lng": 122.561214
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12530,
      "name": "Tagkawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9656058,
        "lng": 122.5392058
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.3700462,
        13.8794726,
        122.790005,
        14.1610539
      ]
    },
    {
      "id": 12531,
      "name": "Acara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.961388,
        "lng": -48.1987043
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.8784386,
        -2.6015282,
        -48.0419199,
        -1.4667843
      ]
    },
    {
      "id": 12532,
      "name": "Baduria",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7366796,
        "lng": 88.7873803
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        88.7473803,
        22.6966796,
        88.8273803,
        22.7766796
      ]
    },
    {
      "id": 12533,
      "name": "Avellino",
      "names": {
        "zh": "阿韦利诺",
        "ja": "アヴェッリーノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9141958,
        "lng": 14.7888764
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.7474606,
        40.8799167,
        14.831671,
        40.9460213
      ]
    },
    {
      "id": 12534,
      "name": "Dholka",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.726607,
        "lng": 72.4411863
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        72.4011863,
        22.686607,
        72.4811863,
        22.766607
      ]
    },
    {
      "id": 12535,
      "name": "Minnetonka",
      "names": {
        "ja": "ミネトンカ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9405086,
        "lng": -93.4638936
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.523343,
        44.891396,
        -93.39896,
        44.97884
      ]
    },
    {
      "id": 12536,
      "name": "Cuenca",
      "names": {
        "zh": "昆卡",
        "ja": "クエンカ",
        "th": "เควงคา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.0661031,
        "lng": -2.1313528
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -2.3550224,
        39.8264384,
        -1.6972386,
        40.5230033
      ]
    },
    {
      "id": 12537,
      "name": "Puerto Libertador",
      "names": {
        "ja": "プエルトリベタードール",
        "th": "เปอร์โตลิเบอร์ตตาเทียน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8900299,
        "lng": -75.6695487
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7095487,
        7.8500299,
        -75.6295487,
        7.9300299
      ]
    },
    {
      "id": 12538,
      "name": "Shali",
      "names": {
        "zh": "沙利"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.153942,
        "lng": 45.906578
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        45.8614102,
        43.1086845,
        45.9476447,
        43.1804821
      ]
    },
    {
      "id": 12539,
      "name": "Cabagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.42782,
        "lng": 121.76955
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.74955,
        17.40782,
        121.78955,
        17.44782
      ]
    },
    {
      "id": 12540,
      "name": "New Plymouth",
      "names": {
        "fr": "Nouveau Plymouth",
        "zh": "新普利茅斯",
        "de": "Neue Plymouth",
        "it": "Nuovo Plymouth",
        "id": "Plymouth baru",
        "ja": "新しいプリマス",
        "th": "นิวพลีมั ธ",
        "pt": "Novo plymouth"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -39.0579941,
        "lng": 174.0806474
      },
      "country": "New Zealand",
      "importance": 3,
      "bbox": [
        173.9206474,
        -39.2179941,
        174.2406474,
        -38.8979941
      ]
    },
    {
      "id": 12541,
      "name": "Tajumulco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.083115,
        "lng": -91.9228136
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.9628136,
        15.043115,
        -91.8828136,
        15.123115
      ]
    },
    {
      "id": 12542,
      "name": "Tuni",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.3614675,
        "lng": 82.5467956
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        82.5067956,
        17.3214675,
        82.5867956,
        17.4014675
      ]
    },
    {
      "id": 12543,
      "name": "Wayne",
      "names": {
        "zh": "韦恩",
        "ja": "ウェイン",
        "th": "เวย์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6264414,
        "lng": -75.3045103
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.5060231,
        41.2321896,
        -75.0436133,
        41.9995045
      ]
    },
    {
      "id": 12544,
      "name": "La Lima",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 15.4402726,
        "lng": -87.9277925
      },
      "country": "Honduras",
      "importance": 3,
      "bbox": [
        -87.9677925,
        15.4002726,
        -87.8877925,
        15.4802726
      ]
    },
    {
      "id": 12545,
      "name": "Liski",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.9873813,
        "lng": 39.4972321
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        39.4499647,
        50.9590796,
        39.6198752,
        51.028666
      ]
    },
    {
      "id": 12546,
      "name": "Markala",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 13.6858601,
        "lng": -6.0686133
      },
      "country": "Mali",
      "importance": 3,
      "bbox": [
        -6.1086133,
        13.6458601,
        -6.0286133,
        13.7258601
      ]
    },
    {
      "id": 12547,
      "name": "Tres Pontas",
      "names": {
        "id": "Pontas tres",
        "ja": "トレスポンタス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.3686224,
        "lng": -45.5122937
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.7007724,
        -21.5153495,
        -45.273,
        -21.2955247
      ]
    },
    {
      "id": 12548,
      "name": "Jaggayyapeta",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8930111,
        "lng": 80.098646
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.058646,
        16.8530111,
        80.138646,
        16.9330111
      ]
    },
    {
      "id": 12549,
      "name": "Yucaipa",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 34.033625,
        "lng": -117.0430865
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.126232,
        34.004015,
        -116.962119,
        34.077422
      ]
    },
    {
      "id": 12550,
      "name": "Svobodnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.371808,
        "lng": 128.13982
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        128.090637,
        51.321114,
        128.187515,
        51.417885
      ]
    },
    {
      "id": 12551,
      "name": "Salo",
      "names": {
        "zh": "萨洛"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.3846716,
        "lng": 23.1286377
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.7909881,
        59.9952099,
        23.7962516,
        60.5956771
      ]
    },
    {
      "id": 12552,
      "name": "Impasugong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.3060497,
        "lng": 124.9975379
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.9141752,
        8.1304896,
        125.2971897,
        8.6015805
      ]
    },
    {
      "id": 12553,
      "name": "Brunswick",
      "names": {
        "fr": "-Brunswick",
        "zh": "不伦瑞克省",
        "de": "Braunschweig",
        "ja": "ブランズウィック",
        "th": "สวิก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0718195,
        "lng": -78.1914215
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -78.6509341,
        33.752878,
        -77.897011,
        34.3719761
      ]
    },
    {
      "id": 12554,
      "name": "Bijar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.8721066,
        "lng": 47.600064
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        47.560064,
        35.8321066,
        47.640064,
        35.9121066
      ]
    },
    {
      "id": 12555,
      "name": "Magpet",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.11194,
        "lng": 125.11333
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.09333,
        7.09194,
        125.13333,
        7.13194
      ]
    },
    {
      "id": 12556,
      "name": "Kitaotao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6389703,
        "lng": 125.0089657
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.9172598,
        7.4502732,
        125.39139,
        7.6968843
      ]
    },
    {
      "id": 12557,
      "name": "Williamsport",
      "names": {
        "zh": "威廉斯波特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2411556,
        "lng": -77.0011096
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.095925,
        41.2187165,
        -76.9816828,
        41.2645648
      ]
    },
    {
      "id": 12558,
      "name": "Sao Francisco do Sul",
      "names": {
        "ja": "サンフランシスコドサル",
        "pt": "São Francisco do Sul"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.2495087,
        "lng": -48.6308087
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.7868786,
        -26.4690863,
        -48.4807999,
        -26.1278038
      ]
    },
    {
      "id": 12559,
      "name": "Sao Francisco",
      "names": {
        "zh": "圣弗朗西斯科",
        "ja": "サンフランシスコ",
        "th": "เซาฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.948889,
        "lng": -44.864444
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.257,
        -16.163,
        -44.411,
        -15.5526297
      ]
    },
    {
      "id": 12560,
      "name": "Hamada",
      "names": {
        "zh": "滨田",
        "ja": "浜田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8991982,
        "lng": 132.0799984
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.8051363,
        34.700744,
        132.393183,
        35.1816399
      ]
    },
    {
      "id": 12561,
      "name": "Sibonga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.032174,
        "lng": 123.577087
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.505512,
        9.966406,
        123.632793,
        10.105915
      ]
    },
    {
      "id": 12562,
      "name": "Pasacao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5102072,
        "lng": 123.0411504
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.0011504,
        13.4702072,
        123.0811504,
        13.5502072
      ]
    },
    {
      "id": 12563,
      "name": "Kongoussi",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.32583,
        "lng": -1.53472
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -1.55472,
        13.30583,
        -1.51472,
        13.34583
      ]
    },
    {
      "id": 12564,
      "name": "Union",
      "names": {
        "es": "Unión",
        "zh": "联盟",
        "it": "Unione",
        "id": "Persatuan",
        "ja": "連合",
        "th": "สหภาพ",
        "pt": "União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9745493,
        "lng": -77.0785945
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.3641712,
        40.807468,
        -76.7992827,
        41.1488012
      ]
    },
    {
      "id": 12565,
      "name": "Ajuy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1724,
        "lng": 123.0197
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.9997,
        11.1524,
        123.0397,
        11.1924
      ]
    },
    {
      "id": 12566,
      "name": "Port Shepstone",
      "names": {
        "zh": "塞斯通港",
        "ja": "ポート羊岩",
        "th": "พอร์ต Shepstone",
        "pt": "Porto shepstone"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -30.7434938,
        "lng": 30.4572437
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        30.4522437,
        -30.7484938,
        30.4622437,
        -30.7384938
      ]
    },
    {
      "id": 12567,
      "name": "Huesca",
      "names": {
        "zh": "韦斯卡"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.1327508,
        "lng": -0.4098509
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -0.4148509,
        42.1277508,
        -0.4048509,
        42.1377508
      ]
    },
    {
      "id": 12568,
      "name": "Nordhorn",
      "names": {
        "zh": "诺德霍恩"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4304605,
        "lng": 7.0639415
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0589415,
        52.4254605,
        7.0689415,
        52.4354605
      ]
    },
    {
      "id": 12569,
      "name": "Elyria",
      "names": {
        "zh": "伊利里亚",
        "th": "อิลิเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3673191,
        "lng": -82.1073583
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.179459,
        41.333028,
        -82.043083,
        41.418462
      ]
    },
    {
      "id": 12570,
      "name": "Leesburg",
      "names": {
        "ja": "リーズバーグ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1154506,
        "lng": -77.5645607
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.6024279,
        39.0676224,
        -77.5079266,
        39.1385274
      ]
    },
    {
      "id": 12571,
      "name": "Elda",
      "names": {
        "zh": "埃尔达"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.4789671,
        "lng": -0.7956759
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.8742871,
        38.4307767,
        -0.7802626,
        38.5130293
      ]
    },
    {
      "id": 12572,
      "name": "Mount Prospect",
      "names": {
        "fr": "Perspective",
        "zh": "山峰前景",
        "de": "Bergblick",
        "it": "Monte Prospect",
        "id": "Mount prospek",
        "ja": "見通しを取り付けます",
        "th": "หวังว่าจะมีโอกาส",
        "pt": "Monte Prospecto"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0664167,
        "lng": -87.9372908
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -87.975636,
        42.020712,
        -87.888623,
        42.102391
      ]
    },
    {
      "id": 12573,
      "name": "Marco de Canavezes",
      "names": {
        "es": "Marco de Canavlazes",
        "fr": "Marco de Canaves",
        "zh": "Marco de Canovezes",
        "ja": "マルコデカベズ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1835352,
        "lng": -8.1493798
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.184093,
        41.1412582,
        -8.1268371,
        41.2004456
      ]
    },
    {
      "id": 12574,
      "name": "Dauis",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.62528,
        "lng": 123.86583
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.84583,
        9.60528,
        123.88583,
        9.64528
      ]
    },
    {
      "id": 12575,
      "name": "Krasnokamsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.088516,
        "lng": 55.759048
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        55.658085,
        58.05825,
        55.830833,
        58.100471
      ]
    },
    {
      "id": 12576,
      "name": "Upata",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.00861,
        "lng": -62.39889
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -62.41889,
        7.98861,
        -62.37889,
        8.02861
      ]
    },
    {
      "id": 12577,
      "name": "Guiuan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.03333,
        "lng": 125.72472
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        125.70472,
        11.01333,
        125.74472,
        11.05333
      ]
    },
    {
      "id": 12578,
      "name": "Velletri",
      "names": {
        "zh": "韦莱特里"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6824192,
        "lng": 12.7808716
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.7758716,
        41.6774192,
        12.7858716,
        41.6874192
      ]
    },
    {
      "id": 12579,
      "name": "Wetzlar",
      "names": {
        "zh": "韦茨拉尔",
        "ja": "ヴェッツラー"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.565511799999996,
        "lng": 8.50388327903218
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.5029979,
        50.5654342,
        8.5054118,
        50.5656036
      ]
    },
    {
      "id": 12580,
      "name": "Enerhodar",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.49882,
        "lng": 34.655442
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.644189,
        47.489045,
        34.670623,
        47.509217
      ]
    },
    {
      "id": 12581,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7425538,
        "lng": -71.0856545
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.381463,
        41.4111505,
        -70.7560271,
        42.095539
      ]
    },
    {
      "id": 12582,
      "name": "Pinellas Park",
      "names": {
        "es": "Parque pinellas",
        "zh": "皮内拉斯公园",
        "de": "Pinellas-Park",
        "id": "Taman Pinellas",
        "th": "สวน Pinellas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.846465,
        "lng": -82.70451
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.757269,
        27.824581,
        -82.663891,
        27.895459
      ]
    },
    {
      "id": 12583,
      "name": "Frechen",
      "names": {
        "zh": "弗雷兴"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.909894,
        "lng": 6.814001
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.719763,
        50.876005,
        6.85389,
        50.94944
      ]
    },
    {
      "id": 12584,
      "name": "Zhangjiazhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1537045,
        "lng": 114.7365251
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.6965251,
        38.1137045,
        114.7765251,
        38.1937045
      ]
    },
    {
      "id": 12585,
      "name": "Neustadt",
      "names": {
        "zh": "诺伊施塔特",
        "ja": "ノイシュタット",
        "th": "ตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3539802,
        "lng": 8.1350021
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.0009672,
        49.295981,
        8.3173792,
        49.4009799
      ]
    },
    {
      "id": 12586,
      "name": "Encarnacion de Diaz",
      "names": {
        "es": "Encarnacion de Díaz"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.520955,
        "lng": -102.235074
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.256755,
        21.503934,
        -102.219311,
        21.538757
      ]
    },
    {
      "id": 12587,
      "name": "Anzio",
      "names": {
        "zh": "安齐奥"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.4471008,
        "lng": 12.6285618
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.5525476,
        41.4427573,
        12.6455847,
        41.5446274
      ]
    },
    {
      "id": 12588,
      "name": "Naka",
      "names": {
        "zh": "那珂",
        "ja": "那珂"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.457227,
        "lng": 140.4871772
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.3868092,
        36.399076,
        140.566277,
        36.515782
      ]
    },
    {
      "id": 12589,
      "name": "Acireale",
      "names": {
        "zh": "阿奇雷亚莱"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.6127773,
        "lng": 15.1658804
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        15.1151569,
        37.5735613,
        15.1999767,
        37.6830566
      ]
    },
    {
      "id": 12590,
      "name": "Pijijiapan",
      "names": {
        "zh": "皮希希亚潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.68535,
        "lng": -93.207818
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.231718,
        15.664275,
        -93.194636,
        15.700331
      ]
    },
    {
      "id": 12591,
      "name": "Bellevue",
      "names": {
        "zh": "贝尔维尤",
        "ja": "ベルビュー",
        "th": "แบล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1365122,
        "lng": -95.8937398
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.009261,
        41.0927785,
        -95.870844,
        41.1911
      ]
    },
    {
      "id": 12592,
      "name": "West Sacramento",
      "names": {
        "zh": "西萨克拉门托",
        "id": "Sacramento Barat",
        "ja": "ウェストサクラメント",
        "th": "เวสต์แซคราเมนโต",
        "pt": "Sacramento Ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5804609,
        "lng": -121.530234
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -121.588345,
        38.5019413,
        -121.506301,
        38.6044587
      ]
    },
    {
      "id": 12593,
      "name": "Lucban",
      "names": {
        "zh": "卢克班"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.114401,
        "lng": 121.55486
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4922103,
        14.0621182,
        121.6789912,
        14.178293
      ]
    },
    {
      "id": 12594,
      "name": "Frutal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.0220064,
        "lng": -48.9280076
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.3092652,
        -20.441,
        -48.7289052,
        -19.7764733
      ]
    },
    {
      "id": 12595,
      "name": "Gudermes",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.350192,
        "lng": 46.100068
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        46.064213,
        43.331898,
        46.129848,
        43.377853
      ]
    },
    {
      "id": 12596,
      "name": "Pirapora",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.3493765,
        "lng": -44.9507904
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -44.9886319,
        -17.555132,
        -44.7450185,
        -17.2028087
      ]
    },
    {
      "id": 12597,
      "name": "Yangmei",
      "names": {
        "zh": "杨梅"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6292497,
        "lng": 114.0751147
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.0701147,
        22.6242497,
        114.0801147,
        22.6342497
      ]
    },
    {
      "id": 12598,
      "name": "Mutsu",
      "names": {
        "zh": "陆奥",
        "ja": "むつ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 41.2928444,
        "lng": 141.1831247
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.6955525,
        41.0423719,
        141.338968,
        41.5601114
      ]
    },
    {
      "id": 12599,
      "name": "Barbosa",
      "names": {
        "zh": "巴博萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4370185,
        "lng": -75.3313779
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3713779,
        6.3970185,
        -75.2913779,
        6.4770185
      ]
    },
    {
      "id": 12600,
      "name": "Xincheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.8562105,
        "lng": 111.6588478
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.5883823,
        40.8107892,
        112.0395395,
        41.1273091
      ]
    },
    {
      "id": 12601,
      "name": "Apopka",
      "names": {
        "zh": "阿波普卡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.699158,
        "lng": -81.534592
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.593307,
        28.621708,
        -81.459521,
        28.786089
      ]
    },
    {
      "id": 12602,
      "name": "Olimpia",
      "names": {
        "zh": "奥林匹亚",
        "th": "โอลิมเปีย"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.736634,
        "lng": -48.910625
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.1301132,
        -20.8695534,
        -48.786,
        -20.544
      ]
    },
    {
      "id": 12603,
      "name": "Pryluky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5950864,
        "lng": 32.3866658
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.2939639,
        50.5506179,
        32.4434501,
        50.6261995
      ]
    },
    {
      "id": 12604,
      "name": "Lewiston",
      "names": {
        "zh": "刘易斯顿",
        "ja": "ルイストン",
        "th": "ลูอิสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.4195913,
        "lng": -117.0216144
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.046587,
        46.36165,
        -116.911255,
        46.437107
      ]
    },
    {
      "id": 12605,
      "name": "Barobo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.53528,
        "lng": 126.12139
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        126.10139,
        8.51528,
        126.14139,
        8.55528
      ]
    },
    {
      "id": 12606,
      "name": "Molave",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0927689,
        "lng": 123.4849176
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.4449176,
        8.0527689,
        123.5249176,
        8.1327689
      ]
    },
    {
      "id": 12607,
      "name": "Hervey Bay",
      "names": {
        "zh": "赫维湾",
        "it": "BAY BAY",
        "id": "Teluk Hervey",
        "ja": "ハーヴィーベイ",
        "th": "อ่าว Hervey"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -25.2894338,
        "lng": 152.8349055
      },
      "country": "Australia",
      "importance": 3,
      "bbox": [
        152.8348555,
        -25.2894838,
        152.8349555,
        -25.2893838
      ]
    },
    {
      "id": 12608,
      "name": "Santo Estevao",
      "names": {
        "ja": "サントエステベオ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.4340621,
        "lng": -39.2556115
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.394,
        -12.549,
        -39.1270325,
        -12.3733501
      ]
    },
    {
      "id": 12609,
      "name": "Yaozhuangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8591258,
        "lng": 115.9197472
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.8997472,
        36.8391258,
        115.9397472,
        36.8791258
      ]
    },
    {
      "id": 12610,
      "name": "Capivari",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9999295,
        "lng": -47.5031066
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.624001,
        -23.1249391,
        -47.3819899,
        -22.8451007
      ]
    },
    {
      "id": 12611,
      "name": "Sao Bento do Una",
      "names": {
        "zh": "圣本站做UNA",
        "ja": "サンベントはええです",
        "th": "Sao Bento ทำ UNA",
        "pt": "São Bento do Una"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.523275,
        "lng": -36.441938
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.456,
        -8.54258,
        -36.42249725,
        -8.49198
      ]
    },
    {
      "id": 12612,
      "name": "Buenavista",
      "names": {
        "zh": "布埃纳维斯塔",
        "ja": "ブエナビスタ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0808818,
        "lng": 124.1117182
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.0717182,
        10.0408818,
        124.1517182,
        10.1208818
      ]
    },
    {
      "id": 12613,
      "name": "Millcreek",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 37.5267201,
        "lng": -90.30984
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.32984,
        37.5067201,
        -90.28984,
        37.5467201
      ]
    },
    {
      "id": 12614,
      "name": "Grand Island",
      "names": {
        "es": "Gran isla",
        "fr": "Grande île",
        "zh": "大岛",
        "de": "Große Insel",
        "it": "Grande Isola",
        "id": "Pulau Grand",
        "ja": "グランドアイランド",
        "th": "เกาะแกรนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.924271,
        "lng": -98.338685
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -98.431455,
        40.854992,
        -98.288213,
        40.988881
      ]
    },
    {
      "id": 12615,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0474764,
        "lng": 121.6700916
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2666288,
        14.9966843,
        122.3835685,
        16.5023672
      ]
    },
    {
      "id": 12616,
      "name": "Akbou",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.4597538,
        "lng": 4.5289243
      },
      "country": "Algeria",
      "importance": 3,
      "bbox": [
        4.4619613,
        36.4001889,
        4.6014649,
        36.5154148
      ]
    },
    {
      "id": 12617,
      "name": "Tame",
      "names": {
        "fr": "Apprivoiser",
        "zh": "驯服",
        "de": "Zähmen",
        "it": "Domare",
        "id": "Jinak",
        "ja": "飼いならされた",
        "th": "เชื่อง"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4615077,
        "lng": -71.7245069
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.3683074,
        6.0681564,
        -71.2192804,
        6.6977429
      ]
    },
    {
      "id": 12618,
      "name": "Palm Desert",
      "names": {
        "es": "Desierto de palma",
        "fr": "Palmer",
        "zh": "棕榈沙漠",
        "de": "Palmwüste",
        "it": "Palma deserto",
        "id": "Gurun",
        "ja": "パーム砂漠",
        "th": "ฝ่ามือ",
        "pt": "Deserto de palma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7288179,
        "lng": -116.382571
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -116.425505,
        33.6706749,
        -116.3020543,
        33.8100814
      ]
    },
    {
      "id": 12619,
      "name": "Cangucu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -31.394063,
        "lng": -52.675577
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -52.699752,
        -31.423078,
        -52.657836,
        -31.36969
      ]
    },
    {
      "id": 12620,
      "name": "Acu",
      "names": {
        "zh": "急性"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.5836157,
        "lng": -36.9139541
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.172,
        -5.8910692,
        -36.838,
        -5.294309
      ]
    },
    {
      "id": 12621,
      "name": "Karlovac",
      "names": {
        "zh": "卡尔洛瓦茨"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4892522,
        "lng": 15.5486303
      },
      "country": "Croatia",
      "importance": 3,
      "bbox": [
        15.484224,
        45.4198973,
        15.6320347,
        45.5646661
      ]
    },
    {
      "id": 12622,
      "name": "Vyksa",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.318774,
        "lng": 42.1800408
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        42.0959647,
        55.2835883,
        42.2212797,
        55.3710987
      ]
    },
    {
      "id": 12623,
      "name": "Kalamata",
      "names": {
        "zh": "卡拉马塔",
        "ja": "カラマタ"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.0372714,
        "lng": 22.110707
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.9827859,
        36.9665074,
        22.3025925,
        37.2292075
      ]
    },
    {
      "id": 12624,
      "name": "San Bartolome",
      "names": {
        "es": "San Bartolomé",
        "zh": "圣Bartolome",
        "it": "San Bartolo",
        "ja": "サンバルトローム",
        "th": "ซานบาร์โดลอม"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 29.0017041,
        "lng": -13.6139122
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -13.6602888,
        28.9422494,
        -13.5832309,
        29.0280162
      ]
    },
    {
      "id": 12625,
      "name": "Passau",
      "names": {
        "zh": "帕绍",
        "ja": "パッサウ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5748229,
        "lng": 13.4609744
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.3023403,
        48.5404501,
        13.514393,
        48.6134154
      ]
    },
    {
      "id": 12626,
      "name": "Leopoldina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.5314282,
        "lng": -42.6403534
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.91,
        -21.705,
        -42.41,
        -21.3840094
      ]
    },
    {
      "id": 12627,
      "name": "Milford city",
      "names": {
        "fr": "Ville de Milford",
        "zh": "米尔福德城",
        "ja": "ミルフォードシティ",
        "th": "เมืองมิลฟอร์ด",
        "pt": "Cidade de milford"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2222218,
        "lng": -73.0570603
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -73.122453,
        41.1693202,
        -72.979047,
        41.2775696
      ]
    },
    {
      "id": 12628,
      "name": "San Severo",
      "names": {
        "zh": "圣塞弗洛",
        "th": "ซานเซเวอร์"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6897761,
        "lng": 15.3776211
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        15.2971517,
        41.54509,
        15.6062947,
        41.773361
      ]
    },
    {
      "id": 12629,
      "name": "Watertown",
      "names": {
        "zh": "沃特敦",
        "ja": "ウォータータウン",
        "th": "วอเตอร์ทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.9747838,
        "lng": -75.9107565
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -75.948818,
        43.948861,
        -75.854118,
        43.995932
      ]
    },
    {
      "id": 12630,
      "name": "Acopiara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.0936464,
        "lng": -39.4543204
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.893618,
        -6.3403965,
        -39.0507418,
        -5.9554739
      ]
    },
    {
      "id": 12631,
      "name": "Sagua la Grande",
      "names": {
        "ja": "サグアラグランデ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8059845,
        "lng": -80.0720021
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -80.3329861,
        22.6772665,
        -79.811554,
        23.1460695
      ]
    },
    {
      "id": 12632,
      "name": "Hamtic",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7003591,
        "lng": 121.9808152
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.7967095,
        10.5740616,
        122.0782581,
        10.7464468
      ]
    },
    {
      "id": 12633,
      "name": "Pasaje",
      "names": {
        "zh": "帕萨赫"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -3.3276396,
        "lng": -79.8075467
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -79.8499013,
        -3.4668222,
        -79.700526,
        -3.3008217
      ]
    },
    {
      "id": 12634,
      "name": "Little Elm",
      "names": {
        "es": "Pequeño olmo",
        "fr": "Petit orme",
        "zh": "小榆树",
        "de": "Kleines Ulm",
        "it": "Piccolo olmo",
        "id": "Elm kecil",
        "ja": "リトルエルム",
        "th": "เอลน้อย",
        "pt": "Pequeno olmo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.177363,
        "lng": -96.912585
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.977204,
        33.133877,
        -96.881949,
        33.238229
      ]
    },
    {
      "id": 12635,
      "name": "Padre Garcia",
      "names": {
        "ja": "パドレガルシア"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8774171,
        "lng": 121.213934
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.1864424,
        13.8459095,
        121.3193661,
        13.9022172
      ]
    },
    {
      "id": 12636,
      "name": "Chambersburg",
      "names": {
        "zh": "钱伯斯堡",
        "th": "ชอมเบอร์สบูร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9375112,
        "lng": -77.6612586
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.6856817,
        39.905803,
        -77.62369,
        39.960649
      ]
    },
    {
      "id": 12637,
      "name": "Peabody",
      "names": {
        "zh": "皮博迪",
        "ja": "ピーボディー",
        "th": "พีบอดี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5278731,
        "lng": -70.9286609
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.0348327,
        42.4947529,
        -70.9125934,
        42.5703914
      ]
    },
    {
      "id": 12638,
      "name": "Wylie",
      "names": {
        "zh": "威利",
        "ja": "ワイリー",
        "th": "ไวลี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0151561,
        "lng": -96.5388038
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.5958747,
        32.9682633,
        -96.4234072,
        33.1008636
      ]
    },
    {
      "id": 12639,
      "name": "Manicore",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.804618,
        "lng": -61.289483
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -62.856,
        -8.863,
        -60.513,
        -4.712
      ]
    },
    {
      "id": 12640,
      "name": "Beaufort",
      "names": {
        "zh": "博福特",
        "ja": "ビューフォート",
        "th": "โบฟอร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.4315813,
        "lng": -80.6698286
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.827353,
        32.365894,
        -80.623753,
        32.526005
      ]
    },
    {
      "id": 12641,
      "name": "Arsenyev",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.1644451,
        "lng": 133.2704255
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        133.228618,
        44.0988222,
        133.327318,
        44.1868056
      ]
    },
    {
      "id": 12642,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6506717,
        "lng": -59.4317202
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.4889499,
        -34.6939912,
        -59.3925834,
        -34.629458
      ]
    },
    {
      "id": 12643,
      "name": "Ahlen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7627633,
        "lng": 7.8909086
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.7881961,
        51.7001442,
        7.9916139,
        51.8313092
      ]
    },
    {
      "id": 12644,
      "name": "Venkatagiri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.957582,
        "lng": 79.5809332
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.5409332,
        13.917582,
        79.6209332,
        13.997582
      ]
    },
    {
      "id": 12645,
      "name": "DeSoto",
      "names": {
        "zh": "德索托"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5897831,
        "lng": -96.8570183
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.9084633,
        32.565004,
        -96.8223619,
        32.6476411
      ]
    },
    {
      "id": 12646,
      "name": "Sihor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.720494,
        "lng": 71.9605587
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        71.9205587,
        21.680494,
        72.0005587,
        21.760494
      ]
    },
    {
      "id": 12647,
      "name": "Sison",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.17173,
        "lng": 120.515899
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.495899,
        16.15173,
        120.535899,
        16.19173
      ]
    },
    {
      "id": 12648,
      "name": "Alvarado",
      "names": {
        "zh": "阿尔瓦拉多"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.82874235,
        "lng": -95.9238540059007
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.1208094,
        18.5550353,
        -95.5099327,
        19.104054
      ]
    },
    {
      "id": 12649,
      "name": "Mafra",
      "names": {
        "zh": "马夫拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.1150943,
        "lng": -49.8035982
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.2079552,
        -26.488,
        -49.6029339,
        -26.0083772
      ]
    },
    {
      "id": 12650,
      "name": "Lorica",
      "names": {
        "pt": "Carapaça"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2394583,
        "lng": -75.8139786
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8539786,
        9.1994583,
        -75.7739786,
        9.2794583
      ]
    },
    {
      "id": 12651,
      "name": "Chone",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.6990907,
        "lng": -80.0944512
      },
      "country": "Ecuador",
      "importance": 3,
      "bbox": [
        -80.1478959,
        -0.7952288,
        -79.6227861,
        -0.4788875
      ]
    },
    {
      "id": 12652,
      "name": "Tomiya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.3998594,
        "lng": 140.8953466
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.858609,
        38.332226,
        140.939451,
        38.432381
      ]
    },
    {
      "id": 12653,
      "name": "Imaricho-ko",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.27362,
        "lng": 129.87877
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12654,
      "name": "Bani",
      "names": {
        "zh": "巴尼",
        "ja": "バニ",
        "th": "ซุ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": -41.0523688,
        "lng": 145.9064073
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        145.82192,
        -41.115018,
        145.987117,
        -41.035513
      ]
    },
    {
      "id": 12655,
      "name": "Jangaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.722365,
        "lng": 79.161365
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.145708,
        17.71145,
        79.181281,
        17.733279
      ]
    },
    {
      "id": 12656,
      "name": "Usa",
      "names": {
        "es": "Estados Unidos",
        "fr": "États-Unis",
        "zh": "美国",
        "it": "Stati Uniti d'America",
        "id": "Amerika Serikat",
        "ja": "アメリカ",
        "th": "สหรัฐอเมริกา",
        "pt": "EUA"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.532005,
        "lng": 131.3496745
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        131.215772,
        33.309386,
        131.448478,
        33.696816
      ]
    },
    {
      "id": 12657,
      "name": "Kobryn",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.2127388,
        "lng": 24.3575698
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.3575198,
        52.2126888,
        24.3576198,
        52.2127888
      ]
    },
    {
      "id": 12658,
      "name": "Poprad",
      "names": {
        "zh": "波普拉德",
        "ja": "ポプラト"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0541521,
        "lng": 20.2976401
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.2455635,
        49.0110091,
        20.3231161,
        49.0643501
      ]
    },
    {
      "id": 12659,
      "name": "Mongagua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -24.082605,
        "lng": -46.625618
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -46.642877,
        -24.105795,
        -46.601006,
        -24.060305
      ]
    },
    {
      "id": 12660,
      "name": "Edina",
      "names": {
        "zh": "伊代纳"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8897027,
        "lng": -93.3501222
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -93.4015759,
        44.8598743,
        -93.318756,
        44.9306098
      ]
    },
    {
      "id": 12661,
      "name": "San Joaquin",
      "names": {
        "es": "San Joaquín",
        "zh": "圣乔金",
        "ja": "サンジョアキン",
        "th": "ซานโจควิน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5870845,
        "lng": 122.1417478
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.0115302,
        10.3864187,
        122.2517617,
        10.7069571
      ]
    },
    {
      "id": 12662,
      "name": "Wolfenbuttel",
      "names": {
        "es": "Wolfenbüttel",
        "de": "Wolfenbüttel",
        "it": "Wolfenbüttel"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1625283,
        "lng": 10.5348215
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.431872,
        52.1208596,
        10.6439294,
        52.2235104
      ]
    },
    {
      "id": 12663,
      "name": "Krasnokamensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.1012467,
        "lng": 118.0314938
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        118.0314438,
        50.1011967,
        118.0315438,
        50.1012967
      ]
    },
    {
      "id": 12664,
      "name": "Tutoia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.761413,
        "lng": -42.275531
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.696,
        -3.175,
        -42.116,
        -2.675738
      ]
    },
    {
      "id": 12665,
      "name": "Canoinhas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.1755121,
        "lng": -50.3949592
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.7048516,
        -26.6095739,
        -50.2932414,
        -26.0019006
      ]
    },
    {
      "id": 12666,
      "name": "Uniao da Vitoria",
      "names": {
        "pt": "Uniao da Vitória"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.228596,
        "lng": -51.0869796
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.2839544,
        -26.3017753,
        -50.8974539,
        -25.94
      ]
    },
    {
      "id": 12667,
      "name": "San Jose Villa de Allende",
      "names": {
        "es": "San José Villa de Allende",
        "zh": "圣何塞别墅de Allende",
        "ja": "サンノゼヴィラデアジェンデ",
        "th": "ซานโฮเซ่วิลล่าเดออัลเลน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3749935,
        "lng": -100.1492584
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.1692584,
        19.3549935,
        -100.1292584,
        19.3949935
      ]
    },
    {
      "id": 12668,
      "name": "Capitao Poco",
      "names": {
        "zh": "Cabitao Poco",
        "id": "Kapitao poco"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.7447162,
        "lng": -47.0638495
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.5175887,
        -2.5935675,
        -46.9272188,
        -1.5379207
      ]
    },
    {
      "id": 12669,
      "name": "Colmenar Viejo",
      "names": {
        "zh": "科尔马尔Viejo"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.6587726,
        "lng": -3.7659722
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -3.8650424,
        40.5913638,
        -3.5844375,
        40.7322154
      ]
    },
    {
      "id": 12670,
      "name": "Minami-Soma",
      "names": {
        "zh": "南-索马",
        "ja": "南-相馬",
        "th": "มินามิ-โสม"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.6421923,
        "lng": 140.9572649
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.810231,
        37.507536,
        141.2932529,
        37.7639646
      ]
    },
    {
      "id": 12671,
      "name": "Wheaton",
      "names": {
        "zh": "惠顿",
        "ja": "ウィートン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8646959,
        "lng": -88.1101709
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.1516657,
        41.818561,
        -88.075391,
        41.895328
      ]
    },
    {
      "id": 12672,
      "name": "Thongwa",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.7608437,
        "lng": 96.5237188
      },
      "country": "Myanmar",
      "importance": 3,
      "bbox": [
        96.4837188,
        16.7208437,
        96.5637188,
        16.8008437
      ]
    },
    {
      "id": 12673,
      "name": "Huaiyang",
      "names": {
        "zh": "淮阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7691945,
        "lng": 114.5314008
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        114.4914008,
        37.7291945,
        114.5714008,
        37.8091945
      ]
    },
    {
      "id": 12674,
      "name": "Beykoz",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1343001,
        "lng": 29.0920378
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.0642474,
        41.042288,
        29.3654018,
        41.2353335
      ]
    },
    {
      "id": 12675,
      "name": "Alesund",
      "names": {
        "es": "Ålesund",
        "fr": "Ålesund",
        "zh": "奥勒松",
        "it": "Ålesund",
        "id": "Ålesund",
        "th": "เอลซุนด์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 62.4711412,
        "lng": 6.1551755
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.9951755,
        62.3111412,
        6.3151755,
        62.6311412
      ]
    },
    {
      "id": 12676,
      "name": "Burauen",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.97556,
        "lng": 124.89278
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.87278,
        10.95556,
        124.91278,
        10.99556
      ]
    },
    {
      "id": 12677,
      "name": "Ibajay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.820578,
        "lng": 122.1618962
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.0955052,
        11.6368121,
        122.2652384,
        11.9809101
      ]
    },
    {
      "id": 12678,
      "name": "Naguilian",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5313384,
        "lng": 120.3941216
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.3541216,
        16.4913384,
        120.4341216,
        16.5713384
      ]
    },
    {
      "id": 12679,
      "name": "Jaen",
      "names": {
        "es": "Jaén",
        "zh": "哈恩"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -5.7086087,
        "lng": -78.8075898
      },
      "country": "Peru",
      "importance": 3,
      "bbox": [
        -78.9726952,
        -5.9459502,
        -78.719804,
        -5.6141426
      ]
    },
    {
      "id": 12680,
      "name": "Torrelavega",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3509588,
        "lng": -4.051206191180152
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -4.0513748,
        43.3508523,
        -4.051031,
        43.3510729
      ]
    },
    {
      "id": 12681,
      "name": "Granja",
      "names": {
        "zh": "格兰哈"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.1212057,
        "lng": -40.8284124
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -41.423501,
        -3.5203948,
        -40.4950246,
        -3.0085348
      ]
    },
    {
      "id": 12682,
      "name": "Pacora",
      "names": {
        "zh": "帕科拉"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.0829832,
        "lng": -79.2903667
      },
      "country": "Panama",
      "importance": 2,
      "bbox": [
        -79.4008663,
        9.0187753,
        -79.2740575,
        9.2775382
      ]
    },
    {
      "id": 12683,
      "name": "An Nabk",
      "names": {
        "es": "Un nabk",
        "fr": "Un nabk",
        "zh": "一个nabk",
        "de": "Ein Nabk",
        "it": "Un nabk",
        "id": "Nabk",
        "ja": "ナッキー",
        "th": "nabk",
        "pt": "Um nabk"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.0234186,
        "lng": 36.7335028
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.6935028,
        33.9834186,
        36.7735028,
        34.0634186
      ]
    },
    {
      "id": 12684,
      "name": "Miyakojima",
      "names": {
        "zh": "宫古岛",
        "ja": "宮古島"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 24.8054647,
        "lng": 125.2811296
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        124.9145451,
        24.5117748,
        125.7053739,
        25.180254
      ]
    },
    {
      "id": 12685,
      "name": "Bato",
      "names": {
        "zh": "马头"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.3572515,
        "lng": 123.3677422
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3277422,
        13.3172515,
        123.4077422,
        13.3972515
      ]
    },
    {
      "id": 12686,
      "name": "Horishni Plavni",
      "names": {
        "fr": "Horrishni plavni"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0140647,
        "lng": 33.6330875
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        33.5930875,
        48.9740647,
        33.6730875,
        49.0540647
      ]
    },
    {
      "id": 12687,
      "name": "Mandamari",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.9846195,
        "lng": 79.478423
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        79.438423,
        18.9446195,
        79.518423,
        19.0246195
      ]
    },
    {
      "id": 12688,
      "name": "Sertolovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 60.143707,
        "lng": 30.207806
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        30.1236398,
        60.1151327,
        30.2590333,
        60.1848673
      ]
    },
    {
      "id": 12689,
      "name": "President Roxas",
      "names": {
        "es": "Presidente Roxas",
        "fr": "Président Roxas",
        "zh": "罗克斯总统",
        "de": "Präsident Roxas",
        "it": "Presidente Roxas",
        "id": "Presiden Roxas",
        "ja": "ロキサス大統領",
        "th": "ประธานาธิบดี Roxas",
        "pt": "Presidente Roxas"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1577578,
        "lng": 125.0513245
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8462483,
        7.0819814,
        125.1148072,
        7.453862
      ]
    },
    {
      "id": 12690,
      "name": "Lacey",
      "names": {
        "zh": "莱西",
        "ja": "レーシー",
        "th": "ลาเซย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.0367448,
        "lng": -122.8076236
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.8176236,
        47.0267448,
        -122.7976236,
        47.0467448
      ]
    },
    {
      "id": 12691,
      "name": "Claveria",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6119415,
        "lng": 124.8933887
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.8533887,
        8.5719415,
        124.9333887,
        8.6519415
      ]
    },
    {
      "id": 12692,
      "name": "Qabqa",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2828182,
        "lng": 100.6191998
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.9195545,
        35.7632839,
        101.2173741,
        37.1769238
      ]
    },
    {
      "id": 12693,
      "name": "Caetite",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.0691776,
        "lng": -42.4852428
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.8,
        -14.4096256,
        -42.1268177,
        -13.5627728
      ]
    },
    {
      "id": 12694,
      "name": "Summerville",
      "names": {
        "zh": "萨默维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0186699,
        "lng": -80.1762704
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.235478,
        32.944854,
        -80.124797,
        33.0551744
      ]
    },
    {
      "id": 12695,
      "name": "Vyazma",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.213655,
        "lng": 34.296782
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        34.249805,
        55.189278,
        34.343582,
        55.247723
      ]
    },
    {
      "id": 12696,
      "name": "Alaminos",
      "names": {
        "zh": "阿拉米诺斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0650417,
        "lng": 121.2463809
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2090431,
        13.9819451,
        121.2901153,
        14.0991137
      ]
    },
    {
      "id": 12697,
      "name": "Boa Viagem",
      "names": {
        "fr": "Boa viagème",
        "zh": "蟒蛇威犹书",
        "ja": "ボアビアゲム",
        "th": "VIAGEM BOA"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1261877,
        "lng": -39.7300055
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -40.1600445,
        -5.3991615,
        -39.5362462,
        -4.6959029
      ]
    },
    {
      "id": 12698,
      "name": "Tamboril",
      "names": {
        "zh": "坦博里尔"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.4873105,
        "lng": -70.6125659
      },
      "country": "Dominican Republic",
      "importance": 3,
      "bbox": [
        -70.6511242,
        19.4301524,
        -70.5455854,
        19.5462178
      ]
    },
    {
      "id": 12699,
      "name": "Diu",
      "names": {
        "zh": "第乌",
        "ja": "ディーウ",
        "th": "ดีอู"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.7137783,
        "lng": 70.9853635
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        70.9453635,
        20.6737783,
        71.0253635,
        20.7537783
      ]
    },
    {
      "id": 12700,
      "name": "Pordenone",
      "names": {
        "zh": "波代诺内",
        "ja": "ポルデノーネ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.9564911,
        "lng": 12.6542229
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.6492229,
        45.9514911,
        12.6592229,
        45.9614911
      ]
    },
    {
      "id": 12701,
      "name": "Marechal Deodoro",
      "names": {
        "fr": "MARCHAL DEODORO",
        "de": "Marachales Deodoro",
        "it": "DEODORO MARECHAL",
        "pt": "Deodoro Marechal"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.709715,
        "lng": -35.896731
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -36.0375776,
        -9.8123816,
        -35.7824474,
        -9.6253881
      ]
    },
    {
      "id": 12702,
      "name": "Parsippany-Troy Hills",
      "names": {
        "es": "Parsiptany-Troy Hills",
        "it": "Colline di parrsippany-troy",
        "id": "Bukit Parsippany-Troy",
        "pt": "Parsippany-troy colinas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.85788,
        "lng": -74.42599
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.44599,
        40.83788,
        -74.40599,
        40.87788
      ]
    },
    {
      "id": 12703,
      "name": "Roman",
      "names": {
        "es": "Romano",
        "fr": "Romaine",
        "zh": "罗马",
        "de": "Römische",
        "it": "Romano",
        "ja": "ローマ",
        "th": "โรมัน",
        "pt": "Romana"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.92528,
        "lng": 26.9284165
      },
      "country": "Romania",
      "importance": 3,
      "bbox": [
        26.8894708,
        46.9091361,
        26.9940292,
        46.9627846
      ]
    },
    {
      "id": 12704,
      "name": "Sape",
      "names": {
        "zh": "萨佩"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.093591,
        "lng": -35.227975
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.2886061,
        -7.1775507,
        -35.1181301,
        -6.9411354
      ]
    },
    {
      "id": 12705,
      "name": "Girardota",
      "names": {
        "zh": "希拉多塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3773809,
        "lng": -75.4459762
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4859762,
        6.3373809,
        -75.4059762,
        6.4173809
      ]
    },
    {
      "id": 12706,
      "name": "Ibbenburen",
      "names": {
        "es": "Ibbenbüren",
        "zh": "伊本比伦",
        "de": "Ibbenbüren",
        "it": "Ibbenbüren"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2774772,
        "lng": 7.7151417
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        7.6036447,
        52.1980787,
        7.8429724,
        52.3460605
      ]
    },
    {
      "id": 12707,
      "name": "Kleve",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7895571,
        "lng": 6.1376008
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.0368354,
        51.7318724,
        6.2449076,
        51.8654512
      ]
    },
    {
      "id": 12708,
      "name": "Navirai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.060744,
        "lng": -54.199606
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -54.236249,
        -23.079443,
        -54.163535,
        -23.034824
      ]
    },
    {
      "id": 12709,
      "name": "Taxco de Alarcon",
      "names": {
        "id": "Takco de alarcon",
        "th": "แท็กซี่เดออะลีร์สัน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5565446,
        "lng": -99.6052838
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.6452838,
        18.5165446,
        -99.5652838,
        18.5965446
      ]
    },
    {
      "id": 12710,
      "name": "Leon",
      "names": {
        "es": "León",
        "zh": "莱昂",
        "ja": "レオン",
        "th": "ลีออง"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 42.5989995,
        "lng": -5.5682413
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        -5.6308026,
        42.5469341,
        -5.5337802,
        42.6403532
      ]
    },
    {
      "id": 12711,
      "name": "Delmiro Gouveia",
      "names": {
        "ja": "デルミロGouveia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.3841161,
        "lng": -37.997756
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.2377725,
        -9.5490685,
        -37.8721591,
        -9.2496238
      ]
    },
    {
      "id": 12712,
      "name": "Civitavecchia",
      "names": {
        "zh": "奇维塔韦基亚",
        "ja": "チヴィタヴェッキア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.096553,
        "lng": 11.791985
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        11.750186,
        42.057702,
        11.825123,
        42.13649
      ]
    },
    {
      "id": 12713,
      "name": "Welland",
      "names": {
        "zh": "韦兰"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.9922181,
        "lng": -79.2484192
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.2908147,
        42.9192869,
        -79.167167,
        43.0427134
      ]
    },
    {
      "id": 12714,
      "name": "Jaguariuna",
      "names": {
        "pt": "Jaguariúna"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.688234,
        "lng": -46.997183
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.080056,
        -22.740063,
        -46.945942,
        -22.617214
      ]
    },
    {
      "id": 12715,
      "name": "Basankusu",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": 1.2191683,
        "lng": 19.8013697
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        19.6413697,
        1.0591683,
        19.9613697,
        1.3791683
      ]
    },
    {
      "id": 12716,
      "name": "Novovolynsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.724873,
        "lng": 24.165247
      },
      "country": "Ukraine",
      "importance": 3,
      "bbox": [
        24.151544,
        50.710565,
        24.197831,
        50.761255
      ]
    },
    {
      "id": 12717,
      "name": "Abdul Hakim",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.04652,
        "lng": 63.26218
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        63.24218,
        28.02652,
        63.28218,
        28.06652
      ]
    },
    {
      "id": 12718,
      "name": "Cruz del Eje",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -30.7218737,
        "lng": -64.8086332
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.8466746,
        -30.7576341,
        -64.7655474,
        -30.7012934
      ]
    },
    {
      "id": 12719,
      "name": "Portel",
      "names": {
        "zh": "波特尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.9387118,
        "lng": -50.8240402
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -51.9396251,
        -3.4950388,
        -49.9085802,
        -1.7476356
      ]
    },
    {
      "id": 12720,
      "name": "Granadilla de Abona",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 28.12124,
        "lng": -16.576149
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -16.580822,
        28.111757,
        -16.571075,
        28.129716
      ]
    },
    {
      "id": 12721,
      "name": "Belorechensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.762883,
        "lng": 39.866008
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.833752,
        44.739361,
        39.908112,
        44.792221
      ]
    },
    {
      "id": 12722,
      "name": "Buique",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.6239732,
        "lng": -37.157296
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -37.3018981,
        -8.9714015,
        -36.9704206,
        -8.4034596
      ]
    },
    {
      "id": 12723,
      "name": "Maricopa",
      "names": {
        "zh": "马里科帕"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0561464,
        "lng": -112.0475262
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.0525262,
        33.0511464,
        -112.0425262,
        33.0611464
      ]
    },
    {
      "id": 12724,
      "name": "Stratford",
      "names": {
        "zh": "斯特拉特福",
        "ja": "ストラットフォード",
        "th": "สแตรทฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.3700899,
        "lng": -80.9818016
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.0262802,
        43.3387463,
        -80.9379315,
        43.4007497
      ]
    },
    {
      "id": 12725,
      "name": "Villareal",
      "names": {
        "zh": "比利亚雷亚尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.9372616,
        "lng": -0.1004465
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -0.1752474,
        39.8917906,
        -0.0611357,
        39.9807842
      ]
    },
    {
      "id": 12726,
      "name": "Albu Kamal",
      "names": {
        "ja": "アルブカマール",
        "th": "อัลบูคามาล"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.45,
        "lng": 40.91861
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        40.89861,
        34.43,
        40.93861,
        34.47
      ]
    },
    {
      "id": 12727,
      "name": "Wangjiazhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.6967265,
        "lng": 104.7993906
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        104.7441306,
        26.654758,
        104.848134,
        26.793466
      ]
    },
    {
      "id": 12728,
      "name": "Izumi",
      "names": {
        "zh": "泉",
        "ja": "泉",
        "th": "อิซูมิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.0903005,
        "lng": 130.3531204
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.2244651,
        31.969837,
        130.5135181,
        32.2126162
      ]
    },
    {
      "id": 12729,
      "name": "San Onofre",
      "names": {
        "zh": "圣onofre",
        "ja": "サンオンフレ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.7386264,
        "lng": -75.5234228
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7040657,
        9.6198548,
        -75.3365548,
        10.1484907
      ]
    },
    {
      "id": 12730,
      "name": "Trinidad",
      "names": {
        "fr": "Trinité",
        "zh": "特立尼达和多巴哥",
        "ja": "トリニダード",
        "th": "ตรินิแดด"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 32.1440417,
        "lng": -96.0910814
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -96.175939,
        32.1243514,
        -96.054422,
        32.181975
      ]
    },
    {
      "id": 12731,
      "name": "Porto Feliz",
      "names": {
        "ja": "ポルトフェリス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2110713,
        "lng": -47.5247163
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.6329526,
        -23.385125,
        -47.4039464,
        -23.090829
      ]
    },
    {
      "id": 12732,
      "name": "Lompoc",
      "names": {
        "zh": "隆波克",
        "th": "ลอมพอก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.66749455,
        "lng": -120.44353709356709
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -120.4442918,
        34.6666458,
        -120.4426431,
        34.6678772
      ]
    },
    {
      "id": 12733,
      "name": "Mabini",
      "names": {
        "zh": "马比尼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7479007,
        "lng": 120.9407657
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.8210181,
        13.6697569,
        120.9855825,
        13.7726076
      ]
    },
    {
      "id": 12734,
      "name": "Timashevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.6129685,
        "lng": 38.9357743
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.8966271,
        45.560877,
        39.0775311,
        45.6493997
      ]
    },
    {
      "id": 12735,
      "name": "Calvia",
      "names": {
        "es": "Calvià",
        "zh": "卡尔维亚",
        "th": "คาลเวีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.566048,
        "lng": 2.506248
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        2.497353,
        39.562114,
        2.512345,
        39.568955
      ]
    },
    {
      "id": 12736,
      "name": "Eilat",
      "names": {
        "zh": "埃拉特",
        "th": "ไอแลต"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 29.554030599999997,
        "lng": 34.94527476661743
      },
      "country": "Israel",
      "importance": 3,
      "bbox": [
        34.922523,
        29.5360846,
        34.9575047,
        29.5719603
      ]
    },
    {
      "id": 12737,
      "name": "Parobe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.624257,
        "lng": -50.83118
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.9290836,
        -29.7297021,
        -50.7958309,
        -29.5836718
      ]
    },
    {
      "id": 12738,
      "name": "Metpalli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.8488213,
        "lng": 78.6258901
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        78.5858901,
        18.8088213,
        78.6658901,
        18.8888213
      ]
    },
    {
      "id": 12739,
      "name": "Howell",
      "names": {
        "zh": "豪威尔",
        "ja": "ハウエル",
        "th": "ธรรมด๊าธรรมดา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6072552,
        "lng": -83.9293952
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -83.96553,
        42.584614,
        -83.912418,
        42.629358
      ]
    },
    {
      "id": 12740,
      "name": "Caldas da Rainha",
      "names": {
        "ja": "カルダスダレインハ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4071857,
        "lng": -9.1346004
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.2357817,
        39.2914278,
        -8.9824976,
        39.5097774
      ]
    },
    {
      "id": 12741,
      "name": "Gwadar",
      "names": {
        "zh": "瓜达尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.1479816,
        "lng": 62.3254553
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        62.1654553,
        24.9879816,
        62.4854553,
        25.3079816
      ]
    },
    {
      "id": 12742,
      "name": "Bando",
      "names": {
        "zh": "半岛",
        "ja": "坂東"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0484805,
        "lng": 139.8896616
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.820164,
        35.970238,
        139.959169,
        36.155094
      ]
    },
    {
      "id": 12743,
      "name": "Caramoan",
      "names": {
        "zh": "卡拉莫安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7681398,
        "lng": 123.8624978
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.7063756,
        13.5927207,
        124.0586451,
        14.1108369
      ]
    },
    {
      "id": 12744,
      "name": "El Bagre",
      "names": {
        "ja": "エルバグレ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.5989686,
        "lng": -74.8046507
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8446507,
        7.5589686,
        -74.7646507,
        7.6389686
      ]
    },
    {
      "id": 12745,
      "name": "Battipaglia",
      "names": {
        "zh": "巴蒂帕利亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6200765,
        "lng": 14.9756048
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        14.9755548,
        40.6200265,
        14.9756548,
        40.6201265
      ]
    },
    {
      "id": 12746,
      "name": "Cardona",
      "names": {
        "zh": "卡多纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4849786,
        "lng": 121.2310927
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.2097751,
        14.3115438,
        121.2899987,
        14.5072035
      ]
    },
    {
      "id": 12747,
      "name": "Kentwood",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 42.8694731,
        "lng": -85.6447492
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.664864,
        42.85449,
        -85.5446409,
        42.941188
      ]
    },
    {
      "id": 12748,
      "name": "Berriozabal",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.871689449999998,
        "lng": -93.33707618047063
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.4307056,
        16.7170205,
        -93.204449,
        17.0268456
      ]
    },
    {
      "id": 12749,
      "name": "Covilha",
      "names": {
        "es": "Covilhã",
        "zh": "科维良",
        "de": "Covilhã",
        "id": "Covilhã",
        "pt": "Covilhã"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2804021,
        "lng": -7.504191
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8090449,
        40.1300397,
        -7.3437766,
        40.4018351
      ]
    },
    {
      "id": 12750,
      "name": "Bad Kreuznach",
      "names": {
        "es": "Mal kreuznach",
        "zh": "坏kreuznach",
        "de": "Böse Kreuznach",
        "id": "Kreuznach buruk",
        "th": "Kreuznach ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8490413,
        "lng": 7.8626661
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.3873007,
        49.6355817,
        7.9703931,
        50.0008842
      ]
    },
    {
      "id": 12751,
      "name": "Pola de Siero",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3914146,
        "lng": -5.6608367
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -5.6746425,
        43.3856,
        -5.6477646,
        43.3971526
      ]
    },
    {
      "id": 12752,
      "name": "Livingston",
      "names": {
        "fr": "De Livingston",
        "zh": "利文斯顿",
        "ja": "リビングストン",
        "th": "ลิฟวิงสตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.8829582,
        "lng": -3.5175773
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -3.5575773,
        55.8429582,
        -3.4775773,
        55.9229582
      ]
    },
    {
      "id": 12753,
      "name": "Shimencun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3652443,
        "lng": 115.999589
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        115.979589,
        37.3452443,
        116.019589,
        37.3852443
      ]
    },
    {
      "id": 12754,
      "name": "Levittown",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.154762,
        "lng": -74.8380029
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -74.8780029,
        40.114762,
        -74.7980029,
        40.194762
      ]
    },
    {
      "id": 12755,
      "name": "Itupiranga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1337224,
        "lng": -49.3321988
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -50.5156479,
        -5.4669585,
        -49.3114486,
        -4.6871934
      ]
    },
    {
      "id": 12756,
      "name": "Mulongo",
      "names": {
        "zh": "穆隆戈"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -7.8332591,
        "lng": 26.9975944
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        26.9575944,
        -7.8732591,
        27.0375944,
        -7.7932591
      ]
    },
    {
      "id": 12757,
      "name": "Karvina",
      "names": {
        "fr": "Karviná",
        "zh": "卡尔维纳",
        "it": "Karviná"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.8542719,
        "lng": 18.5416556
      },
      "country": "Czechia",
      "importance": 3,
      "bbox": [
        18.4499182,
        49.7801295,
        18.6057277,
        49.8936883
      ]
    },
    {
      "id": 12758,
      "name": "Huazangsi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9838035,
        "lng": 103.1263443
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.0863443,
        36.9438035,
        103.1663443,
        37.0238035
      ]
    },
    {
      "id": 12759,
      "name": "Tsukubamirai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9627445,
        "lng": 140.0377674
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.9706881,
        35.931402,
        140.114367,
        36.04551
      ]
    },
    {
      "id": 12760,
      "name": "Solnechnogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1853501,
        "lng": 36.9781536
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        36.9358892,
        56.1589414,
        37.0357627,
        56.2231877
      ]
    },
    {
      "id": 12761,
      "name": "Caleta Olivia",
      "names": {
        "ja": "カレタオリビア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -46.43929,
        "lng": -67.52814
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -67.54814,
        -46.45929,
        -67.50814,
        -46.41929
      ]
    },
    {
      "id": 12762,
      "name": "Sierra Vista",
      "names": {
        "zh": "塞拉维斯塔",
        "ja": "シエラビスタ",
        "th": "เซียร์ราวิสต้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.5545973,
        "lng": -110.2997756
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -110.449149,
        31.3477827,
        -110.098056,
        31.6960385
      ]
    },
    {
      "id": 12763,
      "name": "Woerden",
      "names": {
        "zh": "武尔登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0859261,
        "lng": 4.8832939
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7920404,
        52.06495,
        5.0050113,
        52.1620508
      ]
    },
    {
      "id": 12764,
      "name": "Mazara del Vallo",
      "names": {
        "ja": "マザラ・デルバロ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.6537292,
        "lng": 12.5886912
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        12.5162186,
        37.575107,
        12.7610493,
        37.802867
      ]
    },
    {
      "id": 12765,
      "name": "Tulsipur",
      "names": {
        "zh": "杜尔西布尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.13099,
        "lng": 82.29726
      },
      "country": "Nepal",
      "importance": 3,
      "bbox": [
        82.27726,
        28.11099,
        82.31726,
        28.15099
      ]
    },
    {
      "id": 12766,
      "name": "El Talar de Pacheco",
      "names": {
        "id": "El Talas de Pacheco",
        "pt": "El talar de paceco"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4709,
        "lng": -58.65086
      },
      "country": "Argentina",
      "importance": 3,
      "bbox": [
        -58.67086,
        -34.4909,
        -58.63086,
        -34.4509
      ]
    },
    {
      "id": 12767,
      "name": "Campo Belo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8975,
        "lng": -45.277223
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.4318099,
        -21.0680116,
        -45.0715477,
        -20.816
      ]
    },
    {
      "id": 12768,
      "name": "Guapimirim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5266308,
        "lng": -42.9790687
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -43.0626389,
        -22.7316487,
        -42.865466,
        -22.4479991
      ]
    },
    {
      "id": 12769,
      "name": "Chieti",
      "names": {
        "zh": "基耶蒂",
        "ja": "キエーティ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.3446529,
        "lng": 14.1659738
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.0756603,
        42.3167047,
        14.2296115,
        42.4194776
      ]
    },
    {
      "id": 12770,
      "name": "Rovigo",
      "names": {
        "zh": "罗维戈",
        "ja": "ロヴィーゴ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0769506,
        "lng": 11.7812629
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        11.7762629,
        45.0719506,
        11.7862629,
        45.0819506
      ]
    },
    {
      "id": 12771,
      "name": "Sao Miguel do Guama",
      "names": {
        "zh": "圣米格尔做了古马",
        "ja": "サンミゲルドグアマ",
        "pt": "São Miguel do Guama"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.6116804,
        "lng": -47.4757551
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.949558,
        -1.6727663,
        -47.2856365,
        -1.4312326
      ]
    },
    {
      "id": 12773,
      "name": "Guarne",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2800171,
        "lng": -75.4426875
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5060029,
        6.192136,
        -75.3666895,
        6.3530811
      ]
    },
    {
      "id": 12774,
      "name": "Madison",
      "names": {
        "zh": "麦迪逊",
        "ja": "マディソン",
        "th": "เมดิสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.8482034,
        "lng": -82.6931063
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -82.9629043,
        35.6781364,
        -82.4086066,
        36.0659237
      ]
    },
    {
      "id": 12775,
      "name": "Smyrna",
      "names": {
        "zh": "士麦那",
        "ja": "スマーナ",
        "th": "ในสเมอร์นา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9824598,
        "lng": -86.5199492
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.624984,
        35.9057368,
        -86.460903,
        36.02878
      ]
    },
    {
      "id": 12776,
      "name": "Speyer",
      "names": {
        "zh": "施派尔",
        "ja": "シュパイアー"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3165553,
        "lng": 8.433615
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.3768474,
        49.2830644,
        8.4918311,
        49.3760142
      ]
    },
    {
      "id": 12777,
      "name": "Bantacan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.604,
        "lng": 126.1298
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        126.1098,
        7.584,
        126.1498,
        7.624
      ]
    },
    {
      "id": 12778,
      "name": "North Bay",
      "names": {
        "zh": "北湾",
        "de": "Nordbucht",
        "it": "Baia del Nord",
        "ja": "ノースベイ",
        "th": "อ่าวเหนือ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.3092115,
        "lng": -79.4607617
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -79.5,
        46.2271579,
        -79.2569831,
        46.4469659
      ]
    },
    {
      "id": 12779,
      "name": "Knysna",
      "names": {
        "zh": "克尼斯纳"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -34.035734,
        "lng": 23.048485
      },
      "country": "South Africa",
      "importance": 3,
      "bbox": [
        23.008485,
        -34.075734,
        23.088485,
        -33.995734
      ]
    },
    {
      "id": 12780,
      "name": "Glendora",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 34.1361187,
        "lng": -117.865339
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.8900838,
        34.1056249,
        -117.7938926,
        34.1796683
      ]
    },
    {
      "id": 12781,
      "name": "Pau d'Alho",
      "names": {
        "ja": "Pau D'hoo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.9084712,
        "lng": -39.7508959
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.7708959,
        -16.9284712,
        -39.7308959,
        -16.8884712
      ]
    },
    {
      "id": 12782,
      "name": "Zhexiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.2215,
        "lng": 98.1793
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        98.1593,
        24.2015,
        98.1993,
        24.2415
      ]
    },
    {
      "id": 12783,
      "name": "Butare",
      "names": {
        "zh": "布塔雷"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -2.6053426,
        "lng": 29.7401547
      },
      "country": "Rwanda",
      "importance": 3,
      "bbox": [
        29.5801547,
        -2.7653426,
        29.9001547,
        -2.4453426
      ]
    },
    {
      "id": 12784,
      "name": "Ocampo",
      "names": {
        "zh": "奥坎波"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5581721,
        "lng": 123.3729397
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.3329397,
        13.5181721,
        123.4129397,
        13.5981721
      ]
    },
    {
      "id": 12785,
      "name": "Tucuru",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.303515,
        "lng": -90.084107
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.207258,
        15.257473,
        -89.920079,
        15.356265
      ]
    },
    {
      "id": 12786,
      "name": "Burien",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 47.477949,
        "lng": -122.340033
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -122.383136,
        47.431227,
        -122.292255,
        47.507094
      ]
    },
    {
      "id": 12787,
      "name": "Scandicci",
      "names": {
        "th": "สกันดิกซี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.7567104,
        "lng": 11.1847619
      },
      "country": "Italy",
      "importance": 3,
      "bbox": [
        11.0839771,
        43.6853869,
        11.2134763,
        43.7861836
      ]
    },
    {
      "id": 12788,
      "name": "Ribeira do Pombal",
      "names": {
        "fr": "Ribeira faire pombale",
        "zh": "Ribeira做庞巴美",
        "th": "Ribeira ทำ Pombal"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.83692,
        "lng": -38.535631
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -38.560574,
        -10.866135,
        -38.51482,
        -10.812299
      ]
    },
    {
      "id": 12789,
      "name": "Uzlovaya",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.978512,
        "lng": 38.160255
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        38.0424626,
        53.9301983,
        38.2056803,
        54.0236035
      ]
    },
    {
      "id": 12790,
      "name": "Trairi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.2777494,
        "lng": -39.2680677
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -39.5224578,
        -3.5805115,
        -39.1645459,
        -3.173326
      ]
    },
    {
      "id": 12791,
      "name": "Arao",
      "names": {
        "zh": "荒尾"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9867584,
        "lng": 130.4334027
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.3504981,
        32.9297531,
        130.5308061,
        33.01202
      ]
    },
    {
      "id": 12793,
      "name": "Cuyotenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.541995,
        "lng": -91.572307
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.5972831,
        14.3768288,
        -91.5295598,
        14.6117425
      ]
    },
    {
      "id": 12794,
      "name": "Boblingen",
      "names": {
        "fr": "Böblingen",
        "zh": "伯布林根",
        "de": "Böblingen",
        "it": "Böblingen",
        "pt": "Böblingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.688044,
        "lng": 9.0047319
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.9997319,
        48.683044,
        9.0097319,
        48.693044
      ]
    },
    {
      "id": 12795,
      "name": "Montijo",
      "names": {
        "zh": "蒙蒂霍"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7060845,
        "lng": -8.9746372
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.0498408,
        38.6446897,
        -8.4909721,
        38.8454231
      ]
    },
    {
      "id": 12796,
      "name": "Herriman",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.484918,
        "lng": -112.038798
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -112.072142,
        40.442242,
        -111.933352,
        40.536998
      ]
    },
    {
      "id": 12797,
      "name": "Willich",
      "names": {
        "zh": "维利希"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2641433,
        "lng": 6.5446958
      },
      "country": "Germany",
      "importance": 3,
      "bbox": [
        6.4129717,
        51.2226198,
        6.6259906,
        51.299894
      ]
    },
    {
      "id": 12798,
      "name": "Severomorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 69.069168,
        "lng": 33.416656
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        33.3039054,
        69.0487199,
        33.5251366,
        69.0841084
      ]
    },
    {
      "id": 12799,
      "name": "Tadmur",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.5560155,
        "lng": 38.2809746
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        38.2409746,
        34.5160155,
        38.3209746,
        34.5960155
      ]
    },
    {
      "id": 12801,
      "name": "Hassan Abdal",
      "names": {
        "zh": "哈桑ABDAL",
        "ja": "ハッサンのアブダル"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.82296,
        "lng": 72.68725
      },
      "country": "Pakistan",
      "importance": 3,
      "bbox": [
        72.66725,
        33.80296,
        72.70725,
        33.84296
      ]
    },
    {
      "id": 12802,
      "name": "Farmington",
      "names": {
        "zh": "法明顿",
        "ja": "ファーミントン",
        "th": "ฟาร์มิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7291152,
        "lng": -108.205445
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -108.2841038,
        36.7067011,
        -108.0962373,
        36.8206112
      ]
    },
    {
      "id": 12803,
      "name": "New Bern",
      "names": {
        "es": "New Berna",
        "fr": "Nouveau berne",
        "zh": "新伯尔尼",
        "de": "Neuer Bern",
        "it": "New Berna",
        "id": "Belern baru",
        "ja": "新しいベルン",
        "th": "ใหม่เบิร์น",
        "pt": "Novo berna"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1084931,
        "lng": -77.0441143
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.158703,
        34.995732,
        -76.991481,
        35.159448
      ]
    },
    {
      "id": 12804,
      "name": "Humaita",
      "names": {
        "zh": "乌迈塔",
        "pt": "Humaitá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.5099404,
        "lng": -63.0248674
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -63.6355929,
        -8.88,
        -61.636,
        -6.142
      ]
    },
    {
      "id": 12805,
      "name": "Cheremkhovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.148434,
        "lng": 103.070145
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        103.0058259,
        53.0352845,
        103.2210319,
        53.2005712
      ]
    },
    {
      "id": 12806,
      "name": "Segovia",
      "names": {
        "fr": "Ségovie",
        "zh": "塞哥维亚",
        "ja": "セゴビア",
        "th": "เซโกเวีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.9481192,
        "lng": -4.1172101
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -4.3367483,
        40.8347208,
        -4.0524543,
        40.9879858
      ]
    },
    {
      "id": 12807,
      "name": "San Narciso",
      "names": {
        "zh": "圣纳卡多",
        "ja": "サンナルシソ",
        "th": "ซานโนอาร์ซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5656208,
        "lng": 122.566514
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        122.526514,
        13.5256208,
        122.606514,
        13.6056208
      ]
    },
    {
      "id": 12808,
      "name": "Utrera",
      "names": {
        "zh": "乌特雷拉"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.1824646,
        "lng": -5.7817506
      },
      "country": "Spain",
      "importance": 3,
      "bbox": [
        -6.0488734,
        36.9156477,
        -5.5881485,
        37.2539335
      ]
    },
    {
      "id": 12809,
      "name": "Gummersbach",
      "names": {
        "zh": "古默斯巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0345884,
        "lng": 7.557919
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.557919,
        51.0345884,
        7.5579745,
        51.0351809
      ]
    },
    {
      "id": 12811,
      "name": "Repalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.0192816,
        "lng": 80.8314567
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.7914567,
        15.9792816,
        80.8714567,
        16.0592816
      ]
    },
    {
      "id": 12812,
      "name": "Hinesville",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 31.832617,
        "lng": -81.603995
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -81.691291,
        31.782607,
        -81.556451,
        31.873719
      ]
    },
    {
      "id": 12813,
      "name": "Snezhinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.087009,
        "lng": 60.732674
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        60.708518,
        56.0449365,
        60.7874823,
        56.0928471
      ]
    },
    {
      "id": 12815,
      "name": "Zabid",
      "names": {
        "zh": "宰比德"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.199332,
        "lng": 43.316294
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        43.306294,
        14.189332,
        43.326294,
        14.209332
      ]
    },
    {
      "id": 12816,
      "name": "Ravensburg",
      "names": {
        "zh": "拉芬斯堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7811014,
        "lng": 9.612468
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.483661,
        47.701165,
        9.6631962,
        47.8155938
      ]
    },
    {
      "id": 12817,
      "name": "Nanao",
      "names": {
        "zh": "南澳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.0419859,
        "lng": 136.9639832
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.9589832,
        37.0369859,
        136.9689832,
        37.0469859
      ]
    },
    {
      "id": 12819,
      "name": "Asenovgrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.0073633,
        "lng": 24.8762702
      },
      "country": "Bulgaria",
      "importance": 3,
      "bbox": [
        24.8362702,
        41.9673633,
        24.9162702,
        42.0473633
      ]
    },
    {
      "id": 12820,
      "name": "Konibodom",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 40.2908394,
        "lng": 70.4254538
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        70.4017594,
        40.2633228,
        70.4675588,
        40.3162065
      ]
    },
    {
      "id": 12821,
      "name": "Timbauba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.5118822,
        "lng": -35.316076
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -35.4343967,
        -7.6200627,
        -35.2080593,
        -7.4219344
      ]
    },
    {
      "id": 12822,
      "name": "Sakaidecho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.31667,
        "lng": 133.85
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        133.83,
        34.29667,
        133.87,
        34.33667
      ]
    },
    {
      "id": 12823,
      "name": "Arles",
      "names": {
        "zh": "阿尔勒",
        "ja": "アルル",
        "th": "อาร์ลส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6776223,
        "lng": 4.6309653
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.4260793,
        43.3276716,
        4.8763523,
        43.7604071
      ]
    },
    {
      "id": 12824,
      "name": "Esher",
      "names": {
        "zh": "伊舍"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3693938,
        "lng": -0.365415
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        -0.3784382,
        51.3575189,
        -0.3513526,
        51.387937
      ]
    },
    {
      "id": 12825,
      "name": "Beaumont",
      "names": {
        "zh": "博蒙特",
        "ja": "ボーモント",
        "th": "โบมอนต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0860459,
        "lng": -94.1018461
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -94.2249569,
        29.9729432,
        -94.0369338,
        30.1890213
      ]
    },
    {
      "id": 12826,
      "name": "Goslar",
      "names": {
        "zh": "戈斯拉尔",
        "ja": "ゴスラー"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9059936,
        "lng": 10.4266284
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.3159001,
        51.8439848,
        10.6511856,
        52.0059348
      ]
    },
    {
      "id": 12827,
      "name": "Collierville",
      "names": {
        "th": "คอเลียร์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.053141,
        "lng": -89.698109
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -89.759678,
        34.994669,
        -89.640844,
        35.110867
      ]
    },
    {
      "id": 12828,
      "name": "Campos do Jordao",
      "names": {
        "zh": "坎波斯做jordao",
        "it": "Campos fai Jordao",
        "ja": "カンポスヨルダオ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.738299,
        "lng": -45.5903769
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -45.6763136,
        -22.7971684,
        -45.3994351,
        -22.589536
      ]
    },
    {
      "id": 12829,
      "name": "Nazarovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.011593,
        "lng": 90.418503
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        90.2635107,
        55.9522135,
        90.5133036,
        56.0819093
      ]
    },
    {
      "id": 12830,
      "name": "Laval",
      "names": {
        "zh": "拉瓦尔",
        "ja": "ラヴァル",
        "th": "ลาวาล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0706687,
        "lng": -0.7734022
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.8209667,
        48.024571,
        -0.7174409,
        48.0912483
      ]
    },
    {
      "id": 12831,
      "name": "West Lafayette",
      "names": {
        "zh": "西拉斐特",
        "de": "West-Lafayette",
        "id": "Lafayette Barat",
        "ja": "ウェストラファイエット",
        "th": "ลาฟาแยตตะวันตก",
        "pt": "Lafayette ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.436539,
        "lng": -86.920203
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.962388,
        40.40002,
        -86.887203,
        40.489633
      ]
    },
    {
      "id": 12832,
      "name": "Yuki",
      "names": {
        "zh": "雪",
        "ja": "由紀",
        "th": "ยูกิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3052945,
        "lng": 139.8771403
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        139.815689,
        36.200846,
        139.908398,
        36.323165
      ]
    },
    {
      "id": 12833,
      "name": "Aringay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3946672,
        "lng": 120.354351
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        120.1824981,
        16.3443089,
        120.4735932,
        16.4477992
      ]
    },
    {
      "id": 12834,
      "name": "Kalamansig",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.5545839,
        "lng": 124.050599
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.010599,
        6.5145839,
        124.090599,
        6.5945839
      ]
    },
    {
      "id": 12835,
      "name": "Kyotango",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6455135,
        "lng": 135.0434555
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        134.8513426,
        35.511677,
        135.4958042,
        36.1500281
      ]
    },
    {
      "id": 12836,
      "name": "Florissant",
      "names": {
        "zh": "弗洛里森特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7919683,
        "lng": -90.3227803
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -90.3962995,
        38.7667021,
        -90.278312,
        38.831949
      ]
    },
    {
      "id": 12837,
      "name": "Hoffman Estates",
      "names": {
        "fr": "Hoffman domestiques",
        "zh": "霍夫曼庄园",
        "ja": "ホフマンエステート",
        "pt": "Estates Hoffman"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.062708,
        "lng": -88.124588
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -88.243339,
        42.026564,
        -88.060181,
        42.117863
      ]
    },
    {
      "id": 12839,
      "name": "Ye",
      "names": {
        "fr": "Vous",
        "zh": "你们",
        "ja": "あなたがたは",
        "th": "พวกเจ้า"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 15.2533,
        "lng": 97.8679
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 12840,
      "name": "Kirishi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.450325,
        "lng": 32.010578
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        31.9643218,
        59.4054884,
        32.1782087,
        59.5168727
      ]
    },
    {
      "id": 12841,
      "name": "Miyako",
      "names": {
        "zh": "宫古",
        "ja": "都",
        "th": "มิยาโกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.6398721,
        "lng": 141.9475248
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        141.9425248,
        39.6348721,
        141.9525248,
        39.6448721
      ]
    },
    {
      "id": 12842,
      "name": "Queen Creek",
      "names": {
        "fr": "Reine Creek",
        "zh": "女王溪",
        "de": "Queencreek",
        "id": "Ratu",
        "ja": "クイーンクリーク",
        "pt": "Rainha riacho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2483858,
        "lng": -111.634158
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.7032103,
        33.156882,
        -111.572498,
        33.287647
      ]
    },
    {
      "id": 12843,
      "name": "Kizlyar",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.845807,
        "lng": 46.717826
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        46.659387,
        43.815841,
        46.747904,
        43.872
      ]
    },
    {
      "id": 12844,
      "name": "Cataingan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.003,
        "lng": 123.992
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.972,
        11.983,
        124.012,
        12.023
      ]
    },
    {
      "id": 12845,
      "name": "Goshogawara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 40.80444,
        "lng": 140.44139
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        140.42139,
        40.78444,
        140.46139,
        40.82444
      ]
    },
    {
      "id": 12846,
      "name": "Susono",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1716965,
        "lng": 138.9094546
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        138.9044546,
        35.1666965,
        138.9144546,
        35.1766965
      ]
    },
    {
      "id": 12847,
      "name": "Fafe",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.451406,
        "lng": -8.1708502
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2479609,
        41.3728301,
        -8.0551863,
        41.565947
      ]
    },
    {
      "id": 12848,
      "name": "Nakagawa",
      "names": {
        "zh": "中川",
        "ja": "中川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4994449,
        "lng": 130.4216086
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        130.3711814,
        33.39504,
        130.4662583,
        33.53039
      ]
    },
    {
      "id": 12849,
      "name": "Bani Walid",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 31.75662,
        "lng": 13.99422
      },
      "country": "Libya",
      "importance": 3,
      "bbox": [
        13.97422,
        31.73662,
        14.01422,
        31.77662
      ]
    },
    {
      "id": 12850,
      "name": "Kannapolis",
      "names": {
        "zh": "坎纳波利斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4960552,
        "lng": -80.6247212
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -80.6297212,
        35.4910552,
        -80.6197212,
        35.5010552
      ]
    },
    {
      "id": 12852,
      "name": "Pulawy",
      "names": {
        "zh": "普瓦维"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4171713,
        "lng": 21.9720582
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.9248887,
        51.370888,
        22.0295464,
        51.482278
      ]
    },
    {
      "id": 12853,
      "name": "Turtkul",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5544131,
        "lng": 61.0002405
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        60.9520357,
        41.5413509,
        61.0360932,
        41.5836914
      ]
    },
    {
      "id": 12855,
      "name": "Beloeil",
      "names": {
        "zh": "伯勒伊"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5643183,
        "lng": -73.2040066
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -73.2651329,
        45.5455958,
        -73.1847376,
        45.6332337
      ]
    },
    {
      "id": 12856,
      "name": "El Jem",
      "names": {
        "ja": "エルジェム",
        "th": "เอลเจม",
        "pt": "Elixo"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.3,
        "lng": 10.71667
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        10.69667,
        35.28,
        10.73667,
        35.32
      ]
    },
    {
      "id": 12858,
      "name": "Houten",
      "names": {
        "zh": "霍滕",
        "th": "ฮูเต็น"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.029422,
        "lng": 5.171657
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.121774,
        52.00455,
        5.203527,
        52.056757
      ]
    },
    {
      "id": 12859,
      "name": "Mahalapye",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -23.10407,
        "lng": 26.81421
      },
      "country": "Botswana",
      "importance": 3,
      "bbox": [
        26.79421,
        -23.12407,
        26.83421,
        -23.08407
      ]
    },
    {
      "id": 12860,
      "name": "Mweka",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.8450403,
        "lng": 21.5616789
      },
      "country": "Congo (Kinshasa)",
      "importance": 3,
      "bbox": [
        21.5566789,
        -4.8500403,
        21.5666789,
        -4.8400403
      ]
    },
    {
      "id": 12861,
      "name": "Belleville",
      "names": {
        "zh": "贝尔维尔",
        "ja": "ベルビル",
        "th": "แบล"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 38.5135805,
        "lng": -89.9841627
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -90.086874,
        38.446606,
        -89.90517,
        38.590815
      ]
    },
    {
      "id": 12862,
      "name": "Beruniy",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.69111,
        "lng": 60.7525
      },
      "country": "Uzbekistan",
      "importance": 3,
      "bbox": [
        60.7325,
        41.67111,
        60.7725,
        41.71111
      ]
    },
    {
      "id": 12863,
      "name": "Methuen Town",
      "names": {
        "fr": "Town Methuen",
        "zh": "Methuen镇",
        "de": "Methuen-Stadt",
        "it": "Città di Methuen",
        "id": "Kota methuen",
        "ja": "メスレンの町",
        "th": "เมืองเมธูน",
        "pt": "Cidade methuen"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.750523,
        "lng": -71.158709
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -71.255147,
        42.669491,
        -71.115556,
        42.79433
      ]
    },
    {
      "id": 12864,
      "name": "Gloria",
      "names": {
        "zh": "凯莱",
        "ja": "グロリア",
        "th": "กลอเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9688453,
        "lng": 121.4785283
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.4385283,
        12.9288453,
        121.5185283,
        13.0088453
      ]
    },
    {
      "id": 12866,
      "name": "Yasu",
      "names": {
        "zh": "夜须"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0686932,
        "lng": 136.0225922
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        136.0175922,
        35.0636932,
        136.0275922,
        35.0736932
      ]
    },
    {
      "id": 12867,
      "name": "Baranoa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.79408,
        "lng": -74.9164
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9364,
        10.77408,
        -74.8964,
        10.81408
      ]
    },
    {
      "id": 12868,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.189675,
        "lng": -54.866624
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -55.1323989,
        -29.3959801,
        -54.3666311,
        -28.8078949
      ]
    },
    {
      "id": 12869,
      "name": "Shakhtarsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0550423,
        "lng": 38.4459474
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.377722,
        48.0274453,
        38.5429931,
        48.1026034
      ]
    },
    {
      "id": 12870,
      "name": "La Calera",
      "names": {
        "zh": "拉卡拉",
        "ja": "ラカレラ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.788981,
        "lng": -71.203546
      },
      "country": "Chile",
      "importance": 3,
      "bbox": [
        -71.2384535,
        -32.8667049,
        -71.0845454,
        -32.7490323
      ]
    },
    {
      "id": 12871,
      "name": "Rastatt",
      "names": {
        "zh": "拉施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8574209,
        "lng": 8.2088088
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.115802,
        48.8141378,
        8.2646818,
        48.9197337
      ]
    },
    {
      "id": 12872,
      "name": "Starogard Gdanski",
      "names": {
        "es": "Starrogard gdanski",
        "de": "Starbard gdanski"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9671805,
        "lng": 18.532113
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.4810732,
        53.9342257,
        18.5718254,
        53.9966956
      ]
    },
    {
      "id": 12873,
      "name": "Sahuarita",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 31.9575305,
        "lng": -110.9556645
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -111.004292,
        31.856392,
        -110.925961,
        32.002
      ]
    },
    {
      "id": 12874,
      "name": "Sittard",
      "names": {
        "zh": "锡塔德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9974235,
        "lng": 5.8666627
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8247834,
        50.978005,
        5.9055175,
        51.03204
      ]
    },
    {
      "id": 12875,
      "name": "Donsol",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.912381,
        "lng": 123.594131
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        123.574131,
        12.892381,
        123.614131,
        12.932381
      ]
    },
    {
      "id": 12876,
      "name": "Lorrach",
      "names": {
        "fr": "Lörrach",
        "de": "Lörrach"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6140177,
        "lng": 7.6651124
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6601124,
        47.6090177,
        7.6701124,
        47.6190177
      ]
    },
    {
      "id": 12877,
      "name": "Tebourba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8302496,
        "lng": 9.8413482
      },
      "country": "Tunisia",
      "importance": 3,
      "bbox": [
        9.8013482,
        36.7902496,
        9.8813482,
        36.8702496
      ]
    },
    {
      "id": 12878,
      "name": "Qinhe",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4980079,
        "lng": 112.3335897
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.2732876,
        36.3798538,
        112.4406199,
        36.5993828
      ]
    },
    {
      "id": 12879,
      "name": "El Nido",
      "names": {
        "ja": "エルニド"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1800395,
        "lng": 119.390496
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        119.0849819,
        10.9840848,
        119.6130674,
        11.5816286
      ]
    },
    {
      "id": 12880,
      "name": "Clacton-on-Sea",
      "names": {
        "fr": "Clacton-sur-mer",
        "zh": "clacton对海",
        "ja": "clacton・オン・海",
        "th": "clacton บนทะเล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7938167,
        "lng": 1.1536960906290359
      },
      "country": "United Kingdom",
      "importance": 3,
      "bbox": [
        1.1534472,
        51.7937203,
        1.1545192,
        51.7939262
      ]
    },
    {
      "id": 12882,
      "name": "Amulung",
      "names": {
        "zh": "阿穆隆"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.8363234,
        "lng": 121.7231165
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.6831165,
        17.7963234,
        121.7631165,
        17.8763234
      ]
    },
    {
      "id": 12883,
      "name": "Artur Nogueira",
      "names": {
        "de": "Artur Noggira"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.572737,
        "lng": -47.172679
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -47.2363433,
        -22.6389616,
        -47.0427939,
        -22.473689
      ]
    },
    {
      "id": 12884,
      "name": "Traverse City",
      "names": {
        "fr": "Ville de traverse",
        "zh": "遍历城市",
        "de": "Traverse-Stadt",
        "it": "Città attraversata",
        "id": "Kota lintasan",
        "ja": "トラバースシティ",
        "th": "ทราเวิร์สซิตี้",
        "pt": "Cidade Traverse"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.755631,
        "lng": -85.590344
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.659181,
        44.715142,
        -85.551588,
        44.782513
      ]
    },
    {
      "id": 12885,
      "name": "Mirabel",
      "names": {
        "zh": "米拉贝尔"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.6452452,
        "lng": -74.0731738
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -74.2676054,
        45.5147829,
        -73.8505244,
        45.7894917
      ]
    },
    {
      "id": 12886,
      "name": "San Ignacio",
      "names": {
        "th": "ซานอิกนาซิโอ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -27.2583992,
        "lng": -55.5392216
      },
      "country": "Paraguay",
      "importance": 3,
      "bbox": [
        -55.6196919,
        -27.4093817,
        -55.2772428,
        -27.1591351
      ]
    },
    {
      "id": 12887,
      "name": "Severn",
      "names": {
        "zh": "塞文",
        "ja": "セヴァーン",
        "th": "เวิร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1354665,
        "lng": -76.70062377708201
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -76.7594492,
        39.1003987,
        -76.6412147,
        39.1703725
      ]
    },
    {
      "id": 12888,
      "name": "Wao",
      "names": {
        "zh": "氧化法"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6382215,
        "lng": 124.7241605
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        124.6841605,
        7.5982215,
        124.7641605,
        7.6782215
      ]
    },
    {
      "id": 12889,
      "name": "Catalina Foothills",
      "names": {
        "es": "Foothills de Catalina",
        "fr": "Catalina contreforts",
        "zh": "Catalina山麓",
        "de": "Catalina-Ausläufer",
        "it": "Foothills Catalina",
        "id": "Kaki katalina",
        "ja": "カタリナフットヒル",
        "th": "เชิงเขา Catalina",
        "pt": "Foothills Catalina"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.303178,
        "lng": -110.877133
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -110.961202,
        32.250625,
        -110.805702,
        32.355173
      ]
    },
    {
      "id": 12890,
      "name": "Galveston",
      "names": {
        "zh": "加尔维斯顿",
        "ja": "ガルベストン",
        "th": "กัลเวสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.3056996,
        "lng": -94.7933252
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -95.129935,
        28.9963439,
        -94.5256644,
        29.399399
      ]
    },
    {
      "id": 12891,
      "name": "Chinu",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1063553,
        "lng": -75.3989007
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5841449,
        8.8941021,
        -75.1748401,
        9.161182
      ]
    },
    {
      "id": 12893,
      "name": "Cookeville",
      "names": {
        "zh": "库克维尔",
        "th": "คุกวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.156923,
        "lng": -85.500647
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -85.590039,
        36.08048,
        -85.441323,
        36.20021
      ]
    },
    {
      "id": 12896,
      "name": "Peine",
      "names": {
        "zh": "派纳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3188994,
        "lng": 10.2305288
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2282026,
        52.318841,
        10.2333541,
        52.3188994
      ]
    },
    {
      "id": 12897,
      "name": "Mishawaka",
      "names": {
        "zh": "米沙沃卡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.663863,
        "lng": -86.175551
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -86.215806,
        41.624245,
        -86.098017,
        41.735524
      ]
    },
    {
      "id": 12898,
      "name": "Bhadrachalam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.66186555,
        "lng": 80.88997720055127
      },
      "country": "India",
      "importance": 3,
      "bbox": [
        80.8753714,
        17.6427097,
        80.91092,
        17.6837168
      ]
    },
    {
      "id": 12899,
      "name": "Emden",
      "names": {
        "zh": "埃姆登",
        "th": "แอ็ม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3670541,
        "lng": 7.2058304
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.9996421,
        53.3202047,
        7.3124236,
        53.4085445
      ]
    },
    {
      "id": 12901,
      "name": "Rowland Heights",
      "names": {
        "zh": "罗兰高地",
        "it": "Altezze di Rowland",
        "ja": "ローランドの高さ",
        "pt": "Alturas de rowland"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9761238,
        "lng": -117.905339
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -117.9332719,
        33.9457818,
        -117.8549682,
        34.0017184
      ]
    },
    {
      "id": 12902,
      "name": "Penablanca",
      "names": {
        "zh": "佩尼亚布兰卡"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.6248562,
        "lng": 121.7844749
      },
      "country": "Philippines",
      "importance": 3,
      "bbox": [
        121.7444749,
        17.5848562,
        121.8244749,
        17.6648562
      ]
    },
    {
      "id": 12903,
      "name": "Baicheng",
      "names": {
        "zh": "白城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7984236,
        "lng": 81.8663943
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        81.8263943,
        41.7584236,
        81.9063943,
        41.8384236
      ]
    },
    {
      "id": 12904,
      "name": "Weert",
      "names": {
        "zh": "韦尔特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.2486962,
        "lng": 5.7041339
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6991339,
        51.2436962,
        5.7091339,
        51.2536962
      ]
    },
    {
      "id": 12907,
      "name": "Bullhead City",
      "names": {
        "es": "Ciudad de Bullhead",
        "fr": "Bullhead",
        "zh": "斗牛头市",
        "de": "Bullhead-Stadt",
        "id": "Kota Bullhead",
        "ja": "ブルヘッドシティ",
        "th": "เมือง Bullhead",
        "pt": "Cidade de bullhead"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1477774,
        "lng": -114.568298
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -114.646318,
        35.0402938,
        -114.457199,
        35.20577
      ]
    },
    {
      "id": 12908,
      "name": "Irondequoit",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 43.2133955,
        "lng": -77.5797226
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -77.623191,
        43.166669,
        -77.521843,
        43.25704
      ]
    },
    {
      "id": 12909,
      "name": "Stillwater",
      "names": {
        "zh": "斯蒂尔沃特",
        "th": "สติลวอเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1156306,
        "lng": -97.0585717
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -97.1495688,
        36.0790554,
        -97.0153924,
        36.2045006
      ]
    },
    {
      "id": 12910,
      "name": "Huanghuajie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.9945,
        "lng": 103.552
      },
      "country": "China",
      "importance": 3,
      "bbox": [
        103.532,
        27.9745,
        103.572,
        28.0145
      ]
    },
    {
      "id": 12911,
      "name": "Macaubas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -13.0174846,
        "lng": -42.6882221
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -42.9808288,
        -13.4786035,
        -42.370491,
        -12.8678006
      ]
    },
    {
      "id": 12914,
      "name": "East Hartford",
      "names": {
        "zh": "东哈特福德",
        "id": "Hartford Timur",
        "ja": "イーストハートフォード",
        "th": "ตะวันออกฮาร์ตฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.767914,
        "lng": -72.644512
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -72.6653991,
        41.7247347,
        -72.5712783,
        41.8006518
      ]
    },
    {
      "id": 12915,
      "name": "Nanjian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0471291,
        "lng": 100.5209713
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.4809713,
        25.0071291,
        100.5609713,
        25.0871291
      ]
    },
    {
      "id": 12916,
      "name": "Forney",
      "names": {
        "zh": "福尼"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.752528,
        "lng": -96.456598
      },
      "country": "United States",
      "importance": 3,
      "bbox": [
        -96.494725,
        32.705706,
        -96.392656,
        32.779848
      ]
    },
    {
      "id": 12917,
      "name": "Moss",
      "names": {
        "es": "Musgo",
        "zh": "苔藓",
        "it": "Muschio",
        "ja": "モス",
        "th": "มอส"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.431954,
        "lng": 10.6573418
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.6523418,
        59.426954,
        10.6623418,
        59.436954
      ]
    },
    {
      "id": 12920,
      "name": "Sao Joaquim da Barra",
      "names": {
        "ja": "サンジョアキムダバララ",
        "pt": "São Joaquim da Barra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.5814993,
        "lng": -47.8608238
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -48.1338989,
        -20.648,
        -47.7785068,
        -20.4324017
      ]
    },
    {
      "id": 12922,
      "name": "Alta Floresta",
      "names": {
        "ja": "アルタフロレスト"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.8698547,
        "lng": -56.0834993
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -56.9887422,
        -10.682695,
        -55.8229446,
        -9.3998306
      ]
    },
    {
      "id": 12923,
      "name": "Mantova",
      "names": {
        "zh": "曼托瓦",
        "ja": "マントヴァ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.1566683,
        "lng": 10.7917191
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        10.7229025,
        45.1019574,
        10.9042759,
        45.1868312
      ]
    },
    {
      "id": 12925,
      "name": "Lindong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9573695,
        "lng": 119.3566633
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        119.1222105,
        43.8533632,
        119.7382307,
        44.1221072
      ]
    },
    {
      "id": 12927,
      "name": "Borovichi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.388511,
        "lng": 33.91677
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        33.865223,
        58.35836,
        34.010944,
        58.420639
      ]
    },
    {
      "id": 12928,
      "name": "Itarare",
      "names": {
        "zh": "伊塔拉雷"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.110137,
        "lng": -49.33135
      },
      "country": "Brazil",
      "importance": 3,
      "bbox": [
        -49.36184,
        -24.133035,
        -49.30208,
        -24.084121
      ]
    },
    {
      "id": 12929,
      "name": "Cizre",
      "names": {
        "zh": "吉兹雷"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3324374,
        "lng": 42.1854699
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.9299902,
        37.1570824,
        42.3945369,
        37.4126124
      ]
    },
    {
      "id": 12930,
      "name": "Roslavl",
      "names": {
        "zh": "罗斯拉夫尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.9483965,
        "lng": 32.8606696
      },
      "country": "Russia",
      "importance": 3,
      "bbox": [
        32.7721833,
        53.9078404,
        32.9216963,
        53.9819059
      ]
    },
    {
      "id": 12931,
      "name": "Gogrial",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 8.53324,
        "lng": 28.10037
      },
      "country": "South Sudan",
      "importance": 3,
      "bbox": [
        28.08037,
        8.51324,
        28.12037,
        8.55324
      ]
    },
    {
      "id": 12933,
      "name": "Miyoshi",
      "names": {
        "zh": "三芳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.81024,
        "lng": 132.851691
      },
      "country": "Japan",
      "importance": 3,
      "bbox": [
        132.633361,
        34.605216,
        133.10966,
        35.004601
      ]
    },
    {
      "id": 12935,
      "name": "Albi",
      "names": {
        "zh": "阿尔比",
        "ja": "アルビ",
        "th": "อัลบี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.9277552,
        "lng": 2.147899
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.0537606,
        43.8884707,
        2.2118386,
        43.9696037
      ]
    },
    {
      "id": 12937,
      "name": "Sardasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1553184,
        "lng": 45.4799637
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.4399637,
        36.1153184,
        45.5199637,
        36.1953184
      ]
    },
    {
      "id": 12942,
      "name": "Shawinigan",
      "names": {
        "th": "แชวินิกัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.5498509,
        "lng": -72.7452425
      },
      "country": "Canada",
      "importance": 3,
      "bbox": [
        -72.7452925,
        46.5498009,
        -72.7451925,
        46.5499009
      ]
    },
    {
      "id": 12943,
      "name": "Tepeapulco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.784766,
        "lng": -98.552187
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.565484,
        19.771589,
        -98.533155,
        19.799776
      ]
    },
    {
      "id": 12945,
      "name": "Porvoo",
      "names": {
        "zh": "波尔沃"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.39317045,
        "lng": 25.66456409447306
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.6633817,
        60.392729,
        25.6652655,
        60.39352
      ]
    },
    {
      "id": 12947,
      "name": "Nenjiang",
      "names": {
        "zh": "嫩江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 49.18333,
        "lng": 125.21667
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        125.19667,
        49.16333,
        125.23667,
        49.20333
      ]
    },
    {
      "id": 12951,
      "name": "Heidenheim",
      "names": {
        "zh": "海登海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.6767637,
        "lng": 10.152923
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.1023345,
        48.643614,
        10.2792248,
        48.7832977
      ]
    },
    {
      "id": 12954,
      "name": "Grasse",
      "names": {
        "zh": "格拉斯",
        "ja": "グラース",
        "th": "แกรสส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6589011,
        "lng": 6.9239103
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.885914,
        43.6133123,
        6.9884846,
        43.6986453
      ]
    },
    {
      "id": 12959,
      "name": "Slonim",
      "names": {
        "zh": "斯洛宁"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0927071,
        "lng": 25.3193209
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        25.2599284,
        53.0496216,
        25.4121371,
        53.138356
      ]
    },
    {
      "id": 12960,
      "name": "Ramhormoz",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.27997,
        "lng": 49.60351
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.58351,
        31.25997,
        49.62351,
        31.29997
      ]
    },
    {
      "id": 12966,
      "name": "Gualan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.13333,
        "lng": -89.36667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.38667,
        15.11333,
        -89.34667,
        15.15333
      ]
    },
    {
      "id": 12969,
      "name": "Sesimbra",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.4436932,
        "lng": -9.0996273
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.222922,
        38.4090745,
        -9.0176526,
        38.583319
      ]
    },
    {
      "id": 12973,
      "name": "IJmuiden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4574236,
        "lng": 4.6111981
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5175498,
        52.4348425,
        4.6332173,
        52.4737268
      ]
    },
    {
      "id": 12985,
      "name": "Cimitarra",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3145415,
        "lng": -73.9496809
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5056448,
        6.0649537,
        -73.806249,
        6.7837923
      ]
    },
    {
      "id": 12987,
      "name": "Liujiaxia",
      "names": {
        "zh": "刘家峡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.934,
        "lng": 103.31831
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.29831,
        35.914,
        103.33831,
        35.954
      ]
    },
    {
      "id": 12990,
      "name": "Maria la Baja",
      "names": {
        "ja": "マリアラ・バハ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9833333,
        "lng": -75.3
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.30005,
        9.9832833,
        -75.29995,
        9.9833833
      ]
    },
    {
      "id": 13006,
      "name": "Villaguay",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.8676271,
        "lng": -59.0270174
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.0746826,
        -31.929601,
        -58.9607481,
        -31.7728234
      ]
    },
    {
      "id": 13019,
      "name": "Samaniego",
      "names": {
        "zh": "萨马涅戈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3385009,
        "lng": -77.5929381
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.8865255,
        1.1895899,
        -77.5381592,
        1.5957719
      ]
    },
    {
      "id": 13023,
      "name": "Yong'an",
      "names": {
        "zh": "永安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9104389,
        "lng": 104.85114
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.81114,
        30.8704389,
        104.89114,
        30.9504389
      ]
    },
    {
      "id": 13030,
      "name": "Panay",
      "names": {
        "zh": "班乃"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.555244,
        "lng": 122.7933288
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        122.7647576,
        11.4807553,
        122.9874021,
        11.6338901
      ]
    },
    {
      "id": 13031,
      "name": "Libertador General San Martin",
      "names": {
        "fr": "Libertador général San Martin",
        "zh": "Libertador San Martin",
        "pt": "Libertador Geral San Martin"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -23.8093358,
        "lng": -64.7921741
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.809808,
        -23.8373803,
        -64.776068,
        -23.7927088
      ]
    },
    {
      "id": 13035,
      "name": "Agustin Codazzi",
      "names": {
        "es": "Agustin CODZZI",
        "zh": "阿古斯丁代佐",
        "id": "Agustin codezzi"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.0374894,
        "lng": -73.2387011
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6312604,
        9.7192142,
        -72.8972762,
        10.2666205
      ]
    },
    {
      "id": 13037,
      "name": "Lecco",
      "names": {
        "zh": "莱科",
        "ja": "レッコ",
        "th": "เลกโก"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.8563651,
        "lng": 9.3933781
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.3883781,
        45.8513651,
        9.3983781,
        45.8613651
      ]
    },
    {
      "id": 13042,
      "name": "Freising",
      "names": {
        "zh": "弗赖辛",
        "th": "ไฟรซิง"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4008273,
        "lng": 11.7439565
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.6404507,
        48.330606,
        11.7925143,
        48.4490313
      ]
    },
    {
      "id": 13043,
      "name": "Palencia",
      "names": {
        "zh": "帕伦西亚",
        "th": "ปาเลนเซีย"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6680948,
        "lng": -90.3580422
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.3980422,
        14.6280948,
        -90.3180422,
        14.7080948
      ]
    },
    {
      "id": 13047,
      "name": "Starachowice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.0509549,
        "lng": 21.0601809
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.0601309,
        51.0509049,
        21.0602309,
        51.0510049
      ]
    },
    {
      "id": 13053,
      "name": "Ascoli Piceno",
      "names": {
        "id": "Askoli piceno",
        "ja": "アスコリピチェノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.8546185,
        "lng": 13.5755038
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.4190748,
        42.7718412,
        13.7416745,
        42.9303306
      ]
    },
    {
      "id": 13063,
      "name": "Al Mayadin",
      "names": {
        "ja": "アルマヤディン",
        "th": "อัลมาเดน"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.02089,
        "lng": 40.45346
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        40.43346,
        35.00089,
        40.47346,
        35.04089
      ]
    },
    {
      "id": 13065,
      "name": "Waalwijk",
      "names": {
        "zh": "瓦尔韦克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.682315,
        "lng": 5.073786
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.027654,
        51.670763,
        5.111506,
        51.722344
      ]
    },
    {
      "id": 13074,
      "name": "Waingapu",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -9.654932,
        "lng": 120.255927
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        120.228017,
        -9.680188,
        120.303905,
        -9.626919
      ]
    },
    {
      "id": 13077,
      "name": "Sanare",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.7532604,
        "lng": -69.6513271
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.8113271,
        9.5932604,
        -69.4913271,
        9.9132604
      ]
    },
    {
      "id": 13084,
      "name": "Dachau",
      "names": {
        "zh": "达豪",
        "th": "ดาเชา"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2592477,
        "lng": 11.4354419
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.3843887,
        48.227495,
        11.5025338,
        48.3117077
      ]
    },
    {
      "id": 13089,
      "name": "Kozlu",
      "names": {
        "zh": "科兹卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4338739,
        "lng": 31.7472847
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.6472768,
        41.2599592,
        31.8322694,
        41.4545649
      ]
    },
    {
      "id": 13091,
      "name": "Garmsar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.21824,
        "lng": 52.34085
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        52.32085,
        35.19824,
        52.36085,
        35.23824
      ]
    },
    {
      "id": 13098,
      "name": "Cuxhaven",
      "names": {
        "zh": "库克斯港"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.86878,
        "lng": 8.698286
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.5520184,
        53.7588756,
        8.863906,
        53.892285
      ]
    },
    {
      "id": 13099,
      "name": "Angol",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -37.798787,
        "lng": -72.7086097
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -73.1244347,
        -38.0239907,
        -72.4821436,
        -37.5817959
      ]
    },
    {
      "id": 13107,
      "name": "Harderwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3490693,
        "lng": 5.618933
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.5658636,
        52.3015381,
        5.7128476,
        52.3788413
      ]
    },
    {
      "id": 13111,
      "name": "Kolobrzeg",
      "names": {
        "zh": "科沃布热格"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.174117,
        "lng": 15.568466
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.513214,
        54.149935,
        15.686112,
        54.200946
      ]
    },
    {
      "id": 13112,
      "name": "Zutphen",
      "names": {
        "zh": "聚特芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1396933,
        "lng": 6.194772
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.140387,
        52.088733,
        6.2749853,
        52.1698013
      ]
    },
    {
      "id": 13116,
      "name": "Kurganinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.8851635,
        "lng": 40.5894674
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.5403596,
        44.8376051,
        40.640729,
        44.9247644
      ]
    },
    {
      "id": 13119,
      "name": "Straubing",
      "names": {
        "zh": "施特劳宾"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8768797,
        "lng": 12.5749261
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.5699261,
        48.8718797,
        12.5799261,
        48.8818797
      ]
    },
    {
      "id": 13120,
      "name": "San Pedro Mixtepec",
      "names": {
        "it": "San pedro mixttepec",
        "ja": "サンペドロミックステープ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.9827344,
        "lng": -97.079701
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.119701,
        15.9427344,
        -97.039701,
        16.0227344
      ]
    },
    {
      "id": 13123,
      "name": "Evreux",
      "names": {
        "zh": "埃夫勒",
        "de": "Évreux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.02385,
        "lng": 1.152357
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.089631,
        48.989227,
        1.191506,
        49.046889
      ]
    },
    {
      "id": 13127,
      "name": "Dornbirn",
      "names": {
        "zh": "多恩比恩",
        "ja": "ドルンビルン"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4136351,
        "lng": 9.7423762
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        9.6919423,
        47.2843492,
        9.8486222,
        47.4496667
      ]
    },
    {
      "id": 13130,
      "name": "Damavand",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7179802,
        "lng": 52.0681882
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.9997057,
        35.6550026,
        52.0845415,
        35.7507063
      ]
    },
    {
      "id": 13134,
      "name": "Longtoushan Jiezi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.58364,
        "lng": 104.84431
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.82431,
        31.56364,
        104.86431,
        31.60364
      ]
    },
    {
      "id": 13135,
      "name": "Tarnobrzeg",
      "names": {
        "zh": "塔尔诺布热格",
        "th": "ทาร์นอบเซก"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.573907,
        "lng": 21.675421
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.601196,
        50.51227,
        21.796277,
        50.655249
      ]
    },
    {
      "id": 13142,
      "name": "Santa Catarina Ixtahuacan",
      "names": {
        "ja": "サンタカタリーナイスタフアカン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8,
        "lng": -91.36667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.38667,
        14.78,
        -91.34667,
        14.82
      ]
    },
    {
      "id": 13145,
      "name": "Zhujiacun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1178049,
        "lng": 115.3542137
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.3342137,
        36.0978049,
        115.3742137,
        36.1378049
      ]
    },
    {
      "id": 13147,
      "name": "Titao",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.7670609,
        "lng": -2.0734607
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -2.1134607,
        13.7270609,
        -2.0334607,
        13.8070609
      ]
    },
    {
      "id": 13154,
      "name": "Alta Gracia",
      "names": {
        "ja": "アルタグラシア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6578916,
        "lng": -64.4338563
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.5453644,
        -31.7135565,
        -64.2893684,
        -31.6015474
      ]
    },
    {
      "id": 13168,
      "name": "Vila Verde",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.6488247,
        "lng": -8.4362569
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.54736,
        41.5688871,
        -8.3061918,
        41.770626
      ]
    },
    {
      "id": 13170,
      "name": "Mengmeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4785089,
        "lng": 99.8265037
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.7865037,
        23.4385089,
        99.8665037,
        23.5185089
      ]
    },
    {
      "id": 13172,
      "name": "Carepa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.7585419,
        "lng": -76.6531687
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6931687,
        7.7185419,
        -76.6131687,
        7.7985419
      ]
    },
    {
      "id": 13175,
      "name": "Skierniewice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9568581,
        "lng": 20.1430044
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.1049039,
        51.9224737,
        20.2333377,
        51.9936716
      ]
    },
    {
      "id": 13176,
      "name": "Ridderkerk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8714812,
        "lng": 4.5999778
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5492534,
        51.8387301,
        4.6473308,
        51.9030317
      ]
    },
    {
      "id": 13177,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 8.2999481,
        "lng": -81.01066403679657
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -81.1259434,
        8.1773735,
        -80.8226072,
        8.4226871
      ]
    },
    {
      "id": 13183,
      "name": "Soest",
      "names": {
        "zh": "索斯特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5725501,
        "lng": 8.1061259
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.9831444,
        51.5214743,
        8.1762011,
        51.6275581
      ]
    },
    {
      "id": 13187,
      "name": "Stade",
      "names": {
        "zh": "体育场",
        "ja": "スタッド",
        "th": "สนามกีฬา"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.599794,
        "lng": 9.475438
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.3740618,
        53.5386218,
        9.5506295,
        53.7012013
      ]
    },
    {
      "id": 13195,
      "name": "Golpayegan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4537,
        "lng": 50.28836
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.26836,
        33.4337,
        50.30836,
        33.4737
      ]
    },
    {
      "id": 13201,
      "name": "Lousada",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2773789,
        "lng": -8.2826293
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3397527,
        41.2308109,
        -8.1915073,
        41.3512597
      ]
    },
    {
      "id": 13203,
      "name": "Agueda",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5785006,
        "lng": -8.4438017
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.558152,
        40.4959084,
        -8.2453833,
        40.6942812
      ]
    },
    {
      "id": 13204,
      "name": "Charleville-Mezieres",
      "names": {
        "es": "Charleville-mézières",
        "zh": "沙勒维尔 - 梅济耶尔",
        "de": "Charleville-mézières"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.7735712,
        "lng": 4.7206939
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.6757739,
        49.732827,
        4.784978,
        49.8274329
      ]
    },
    {
      "id": 13211,
      "name": "Luwuk",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.9528231,
        "lng": 122.7880198
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        122.7480198,
        -0.9928231,
        122.8280198,
        -0.9128231
      ]
    },
    {
      "id": 13219,
      "name": "Saint-Malo",
      "names": {
        "zh": "圣马洛",
        "ja": "サン・マロ",
        "th": "เซนต์มาโล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6466038,
        "lng": -2.0039808
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.0089808,
        48.6416038,
        -1.9989808,
        48.6516038
      ]
    },
    {
      "id": 13224,
      "name": "Amahai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.33333,
        "lng": 128.91667
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        128.91667,
        -3.33333,
        128.91667,
        -3.33333
      ]
    },
    {
      "id": 13231,
      "name": "Rieti",
      "names": {
        "zh": "列蒂"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.4026569,
        "lng": 12.860813
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.7113607,
        42.3090846,
        12.9950532,
        42.5089285
      ]
    },
    {
      "id": 13232,
      "name": "`Amuda",
      "names": {
        "zh": "`阿穆达"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 37.1033727,
        "lng": 40.9296228
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        40.8896228,
        37.0633727,
        40.9696228,
        37.1433727
      ]
    },
    {
      "id": 13235,
      "name": "Chaidari",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.009669,
        "lng": 23.652673
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.632673,
        37.989669,
        23.672673,
        38.029669
      ]
    },
    {
      "id": 13238,
      "name": "Schagen",
      "names": {
        "zh": "斯哈亨"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7852715,
        "lng": 4.8053789
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8003789,
        52.7802715,
        4.8103789,
        52.7902715
      ]
    },
    {
      "id": 13239,
      "name": "Brive-la-Gaillarde",
      "names": {
        "zh": "brive-的La-gaillarde",
        "ja": "・ラ-gaillarde brive",
        "th": "brive-ลา-gaillarde"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1584982,
        "lng": 1.5332389
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.4548563,
        45.1044737,
        1.5737793,
        45.1856136
      ]
    },
    {
      "id": 13241,
      "name": "Kutno",
      "names": {
        "zh": "库特诺"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.23064,
        "lng": 19.36409
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.28940637,
        52.19096589,
        19.452854566,
        52.253597314
      ]
    },
    {
      "id": 13242,
      "name": "La Vega",
      "names": {
        "zh": "拉维加",
        "ja": "ラベガ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.05257275,
        "lng": -76.77593835691735
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.8966069,
        1.939067,
        -76.5726804,
        2.167201
      ]
    },
    {
      "id": 13244,
      "name": "Azna",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4541525,
        "lng": 49.4516797
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.4466797,
        33.4491525,
        49.4566797,
        33.4591525
      ]
    },
    {
      "id": 13249,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.25,
        "lng": -56.5
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -57.4744685,
        -25.0193136,
        -55.8398886,
        -23.3009578
      ]
    },
    {
      "id": 13250,
      "name": "San Jose Pinula",
      "names": {
        "es": "San José Pinula",
        "zh": "圣何塞·佩拉",
        "ja": "サンノゼピュニュラ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.54611,
        "lng": -90.41139
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.43139,
        14.52611,
        -90.39139,
        14.56611
      ]
    },
    {
      "id": 13252,
      "name": "Santa Ana Chiautempan",
      "names": {
        "zh": "圣安娜·奇亚普潘",
        "de": "Santa Ana Chiauutempan",
        "id": "Santa Ana Chiautemban",
        "ja": "サンタアナチアテンパン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.313489,
        "lng": -98.189183
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.218654,
        19.296074,
        -98.160369,
        19.329164
      ]
    },
    {
      "id": 13256,
      "name": "Qingyuan",
      "names": {
        "zh": "清远",
        "ja": "清遠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6832984,
        "lng": 113.0505994
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.9223003,
        23.4502963,
        113.926165,
        25.1943209
      ]
    },
    {
      "id": 13257,
      "name": "San Andres de Sotavento",
      "names": {
        "es": "San Andrés de Sotavento",
        "zh": "圣安德烈斯德萨萨托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1457678,
        "lng": -75.5090819
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5969575,
        9.0194723,
        -75.4473898,
        9.2482168
      ]
    },
    {
      "id": 13259,
      "name": "Sanski Most",
      "names": {
        "es": "Sanski mas",
        "fr": "Sanski le plus",
        "zh": "Sanski最多",
        "de": "Sanski am meisten",
        "it": "Sanski di più",
        "id": "Sanski sebagian besar",
        "ja": "サンスキはほとんど",
        "th": "Sanski มากที่สุด",
        "pt": "Sanski mais"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.7667,
        "lng": 16.667
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        16.647,
        44.7467,
        16.687,
        44.7867
      ]
    },
    {
      "id": 13260,
      "name": "Chaparral",
      "names": {
        "zh": "丛林",
        "ja": "茂み",
        "th": "โอ๊ก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7245851,
        "lng": -75.485156
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9173552,
        3.4622907,
        -75.2735019,
        4.0430532
      ]
    },
    {
      "id": 13263,
      "name": "Jobabo",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.9040227,
        "lng": -77.28399012924322
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.284691,
        20.9038176,
        -77.2836697,
        20.9045113
      ]
    },
    {
      "id": 13264,
      "name": "Villa Constitucion",
      "names": {
        "zh": "别墅成立",
        "de": "Villa-Grundkurs",
        "id": "Konstituus villa",
        "ja": "ヴィラ構成",
        "th": "วิลล่าเซสซีน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.22778,
        "lng": -60.3297
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.3497,
        -33.24778,
        -60.3097,
        -33.20778
      ]
    },
    {
      "id": 13267,
      "name": "Tursunzoda",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5138769,
        "lng": 68.2317099
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        68.1917099,
        38.4738769,
        68.2717099,
        38.5538769
      ]
    },
    {
      "id": 13268,
      "name": "Tangdukou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.995057,
        "lng": 111.2707785
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.2307785,
        26.955057,
        111.3107785,
        27.035057
      ]
    },
    {
      "id": 13274,
      "name": "Kingisepp",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.3684013,
        "lng": 28.6010334
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        28.6009834,
        59.3683513,
        28.6010834,
        59.3684513
      ]
    },
    {
      "id": 13275,
      "name": "Yabrud",
      "names": {
        "zh": "耶卜鲁德"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.9665336,
        "lng": 36.6609885
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.6209885,
        33.9265336,
        36.7009885,
        34.0065336
      ]
    },
    {
      "id": 13282,
      "name": "Ayapel",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.3116711,
        "lng": -75.1445022
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3399414,
        8.0492396,
        -74.7851374,
        8.4945446
      ]
    },
    {
      "id": 13287,
      "name": "Landau",
      "names": {
        "zh": "兰道",
        "ja": "ランダウ",
        "th": "รถม้าสี่ล้อ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.1979317,
        "lng": 8.1261523
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1211523,
        49.1929317,
        8.1311523,
        49.2029317
      ]
    },
    {
      "id": 13288,
      "name": "Carcassonne",
      "names": {
        "es": "Carcasona",
        "zh": "卡尔卡松",
        "ja": "カルカソンヌ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.2130358,
        "lng": 2.3491069
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2615684,
        43.1713069,
        2.4362676,
        43.2443707
      ]
    },
    {
      "id": 13291,
      "name": "Soest",
      "names": {
        "zh": "索斯特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.174595,
        "lng": 5.2909674
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2431726,
        52.1102715,
        5.3499255,
        52.2045612
      ]
    },
    {
      "id": 13294,
      "name": "Marhanets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6547439,
        "lng": 34.6197079
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.6147079,
        47.6497439,
        34.6247079,
        47.6597439
      ]
    },
    {
      "id": 13296,
      "name": "Poso",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -1.3948289,
        "lng": 120.7539369
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        120.7203107,
        -1.4122278,
        120.7663751,
        -1.3687148
      ]
    },
    {
      "id": 13297,
      "name": "Urrao",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3168685,
        "lng": -76.1344983
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.5820937,
        5.9976312,
        -75.9905027,
        6.593918
      ]
    },
    {
      "id": 13298,
      "name": "Tres Arroyos",
      "names": {
        "ja": "トレスアロヨス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.3770014,
        "lng": -60.2755685
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.3541031,
        -38.4248413,
        -60.2323264,
        -38.3291701
      ]
    },
    {
      "id": 13299,
      "name": "Prievidza",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7718361,
        "lng": 18.6234916
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.5776204,
        48.721785,
        18.6989609,
        48.7993362
      ]
    },
    {
      "id": 13300,
      "name": "Nysa",
      "names": {
        "zh": "尼斯"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.473485,
        "lng": 17.335455
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.287295,
        50.441234,
        17.386627,
        50.505918
      ]
    },
    {
      "id": 13302,
      "name": "Blois",
      "names": {
        "zh": "布卢瓦",
        "ja": "ブロワ",
        "th": "บลัว"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.59800439999999,
        "lng": 1.3244244705776174
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.3242846,
        47.5978713,
        1.3245665,
        47.5980996
      ]
    },
    {
      "id": 13303,
      "name": "Ha Tien",
      "names": {
        "zh": "哈蒂恩"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.3828019,
        "lng": 104.4859503
      },
      "country": "Vietnam",
      "importance": 2,
      "bbox": [
        104.2362701,
        10.205358,
        104.5717174,
        10.4468747
      ]
    },
    {
      "id": 13304,
      "name": "Neunkirchen",
      "names": {
        "zh": "诺因基兴"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.34987,
        "lng": 7.1841564
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.1058824,
        49.2855938,
        7.2689474,
        49.4074009
      ]
    },
    {
      "id": 13309,
      "name": "Al Qusayr",
      "names": {
        "th": "อัล qusayr"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.5107184,
        "lng": 36.576892
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.536892,
        34.4707184,
        36.616892,
        34.5507184
      ]
    },
    {
      "id": 13311,
      "name": "Frosinone",
      "names": {
        "zh": "弗罗西诺内"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6419807,
        "lng": 13.3469287
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.2637959,
        41.6035229,
        13.3927655,
        41.6655777
      ]
    },
    {
      "id": 13312,
      "name": "Kabasalan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7974574,
        "lng": 122.7619459
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        122.7219459,
        7.7574574,
        122.8019459,
        7.8374574
      ]
    },
    {
      "id": 13320,
      "name": "Roldanillo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.41256,
        "lng": -76.15457
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.17457,
        4.39256,
        -76.13457,
        4.43256
      ]
    },
    {
      "id": 13322,
      "name": "Fastiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.078484,
        "lng": 29.9124
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.848354,
        50.045536,
        29.958056,
        50.108824
      ]
    },
    {
      "id": 13327,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.170026,
        "lng": -68.0003987
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -68.1603987,
        10.010026,
        -67.8403987,
        10.330026
      ]
    },
    {
      "id": 13328,
      "name": "Agrinio",
      "names": {
        "th": "อ่างทอง"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.6248275,
        "lng": 21.4094206
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.2494206,
        38.4648275,
        21.5694206,
        38.7848275
      ]
    },
    {
      "id": 13331,
      "name": "Lohja",
      "names": {
        "zh": "洛赫亚"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.2479871,
        "lng": 24.063309
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.063259,
        60.2479371,
        24.063359,
        60.2480371
      ]
    },
    {
      "id": 13333,
      "name": "Lukavac",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.5344136,
        "lng": 18.5325309
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.4925309,
        44.4944136,
        18.5725309,
        44.5744136
      ]
    },
    {
      "id": 13336,
      "name": "Ciudad Serdan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.989267,
        "lng": -97.450094
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.4645931288,
        18.9754903159,
        -97.4364797332,
        19.0091361714
      ]
    },
    {
      "id": 13342,
      "name": "Lubny",
      "names": {
        "zh": "卢布内"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.014326,
        "lng": 32.993671
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.938958,
        49.976794,
        33.04161,
        50.055799
      ]
    },
    {
      "id": 13347,
      "name": "Vercelli",
      "names": {
        "zh": "韦尔切利",
        "ja": "ヴェルチェッリ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.3251557,
        "lng": 8.4227666
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.3161725,
        45.2777348,
        8.5136793,
        45.3723426
      ]
    },
    {
      "id": 13349,
      "name": "Veldhoven",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4190691,
        "lng": 5.4045572
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.315808,
        51.3764601,
        5.4298562,
        51.4389088
      ]
    },
    {
      "id": 13350,
      "name": "Hof",
      "names": {
        "zh": "HOF酒店",
        "ja": "ホフ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3219015,
        "lng": 11.9178807
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.8066265,
        50.2673889,
        11.971851,
        50.3551141
      ]
    },
    {
      "id": 13362,
      "name": "Jocotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2861698,
        "lng": -103.4300345
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.4700345,
        20.2461698,
        -103.3900345,
        20.3261698
      ]
    },
    {
      "id": 13364,
      "name": "Puerto Tejada",
      "names": {
        "zh": "波多黎雅达",
        "ja": "プエルトテヤダ",
        "th": "เปอร์โตเตจาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2288972,
        "lng": -76.4192267
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.4945424,
        3.199361,
        -76.3517524,
        3.3290895
      ]
    },
    {
      "id": 13367,
      "name": "Hyvinkaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.6312926,
        "lng": 24.8579079
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.8529079,
        60.6262926,
        24.8629079,
        60.6362926
      ]
    },
    {
      "id": 13379,
      "name": "Dendermonde",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0228312,
        "lng": 4.1008679
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.0958679,
        51.0178312,
        4.1058679,
        51.0278312
      ]
    },
    {
      "id": 13388,
      "name": "Tallbisah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.84072,
        "lng": 36.73092
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 13389,
      "name": "Zwijndrecht",
      "names": {
        "zh": "兹韦恩德雷赫特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8162227,
        "lng": 4.6405166
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5536088,
        51.80076,
        4.6717088,
        51.8471168
      ]
    },
    {
      "id": 13391,
      "name": "Chichigalpa",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.5720493,
        "lng": -87.0262652
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -87.1374798,
        12.4647793,
        -86.9438616,
        12.6937381
      ]
    },
    {
      "id": 13392,
      "name": "Gotha",
      "names": {
        "zh": "哥达",
        "ja": "ゴータ",
        "th": "ก็อด"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.939047,
        "lng": 10.712378
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.707378,
        50.934047,
        10.717378,
        50.944047
      ]
    },
    {
      "id": 13398,
      "name": "Lodi",
      "names": {
        "zh": "洛迪",
        "ja": "ロディ",
        "th": "โลดิ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.3138959,
        "lng": 9.503263
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.4422641,
        45.2783423,
        9.5522576,
        45.3519476
      ]
    },
    {
      "id": 13401,
      "name": "Santiago Atitlan",
      "names": {
        "zh": "圣地亚哥阿特特兰",
        "ja": "サンティアゴアテトラン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6388934,
        "lng": -91.2295526
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.2854006,
        14.5309273,
        -91.1500078,
        14.67625
      ]
    },
    {
      "id": 13407,
      "name": "Akhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.9240528,
        "lng": 27.8401895
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.4679191,
        38.7451136,
        28.172242,
        39.245231
      ]
    },
    {
      "id": 13415,
      "name": "Wittenberg",
      "names": {
        "zh": "维滕贝格",
        "ja": "ウィッテンベルク",
        "th": "วิต"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8666527,
        "lng": 12.646761
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.484923,
        51.8186838,
        12.7767377,
        52.013327
      ]
    },
    {
      "id": 13418,
      "name": "Roskilde",
      "names": {
        "zh": "罗斯基勒",
        "ja": "ロスキレ",
        "th": "กิลด์"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.6433478,
        "lng": 12.0819247
      },
      "country": "Denmark",
      "importance": 2,
      "bbox": [
        11.9219247,
        55.4833478,
        12.2419247,
        55.8033478
      ]
    },
    {
      "id": 13420,
      "name": "Koupela",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.17864,
        "lng": -0.35103
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -0.37103,
        12.15864,
        -0.33103,
        12.19864
      ]
    },
    {
      "id": 13421,
      "name": "Bajil",
      "names": {
        "zh": "巴吉勒"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.058353,
        "lng": 43.28506
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        43.24506,
        15.018353,
        43.32506,
        15.098353
      ]
    },
    {
      "id": 13437,
      "name": "Ourem",
      "names": {
        "pt": "Ourém"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.65679,
        "lng": -8.57963
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7022515,
        39.5358211,
        -8.4230914,
        39.8387292
      ]
    },
    {
      "id": 13446,
      "name": "Chalons-en-Champagne",
      "names": {
        "es": "Chalons-en-champán",
        "fr": "Châlons-en-Champagne",
        "zh": "沙隆-连接香槟",
        "it": "Chalons en-champagne",
        "ja": "chalons-専用シャンパン",
        "th": "chalons-ห้องแชมเปญ",
        "pt": "Châlons-en-champagne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9566218,
        "lng": 4.3628851
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.3295725,
        48.9300597,
        4.4304706,
        49.0011453
      ]
    },
    {
      "id": 13457,
      "name": "Xisa",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4367768,
        "lng": 104.6701268
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.6301268,
        23.3967768,
        104.7101268,
        23.4767768
      ]
    },
    {
      "id": 13468,
      "name": "Oranienburg",
      "names": {
        "zh": "奥拉宁堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.7529379,
        "lng": 13.2457591
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.1309636,
        52.7093903,
        13.4306044,
        52.8464685
      ]
    },
    {
      "id": 13469,
      "name": "Santa Maria Huatulco",
      "names": {
        "zh": "圣玛丽亚Huatulco",
        "ja": "サンタマリアウアトゥルコ",
        "th": "ซานตามาเรีย Huatulco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.8337711,
        "lng": -96.3214401
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.3614401,
        15.7937711,
        -96.2814401,
        15.8737711
      ]
    },
    {
      "id": 13471,
      "name": "Olhao",
      "names": {
        "pt": "Olhão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0270369,
        "lng": -7.8411287
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8897655,
        36.9980807,
        -7.7236411,
        37.1311612
      ]
    },
    {
      "id": 13473,
      "name": "Remedios",
      "names": {
        "zh": "雷梅迪奥斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4950028,
        "lng": -79.5448149
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.6389485,
        22.2241761,
        -79.3075268,
        22.5892357
      ]
    },
    {
      "id": 13479,
      "name": "Cuautepec de Hinojosa",
      "names": {
        "id": "Cuaiopec de hinjosa",
        "ja": "羽尾デフノヨーサ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.037296,
        "lng": -98.317103
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.3495082795,
        20.0085032359,
        -98.2948938495,
        20.0656092386
      ]
    },
    {
      "id": 13484,
      "name": "Rada`",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.4145221,
        "lng": 44.8403277
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.8003277,
        14.3745221,
        44.8803277,
        14.4545221
      ]
    },
    {
      "id": 13485,
      "name": "San Pedro Ayampuc",
      "names": {
        "fr": "San Pedro Ayamduc",
        "ja": "サンペドロアヤンプー"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7784345,
        "lng": -90.4529066
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.4929066,
        14.7384345,
        -90.4129066,
        14.8184345
      ]
    },
    {
      "id": 13486,
      "name": "Cajica",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9172215,
        "lng": -74.0255474
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.0655474,
        4.8772215,
        -73.9855474,
        4.9572215
      ]
    },
    {
      "id": 13487,
      "name": "Gaya",
      "names": {
        "zh": "格雅",
        "ja": "ガヤー"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 11.884754,
        "lng": 3.459885
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        3.299885,
        11.724754,
        3.619885,
        12.044754
      ]
    },
    {
      "id": 13498,
      "name": "San Pelayo",
      "names": {
        "zh": "圣佩莱奥",
        "ja": "サンペラヨ",
        "th": "ซานเปลีโย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9920713,
        "lng": -75.90462715586233
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1104353,
        8.9012338,
        -75.6989107,
        9.0833982
      ]
    },
    {
      "id": 13500,
      "name": "Pinamar",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.1099492,
        "lng": -56.8539007
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -56.8939007,
        -37.1499492,
        -56.8139007,
        -37.0699492
      ]
    },
    {
      "id": 13503,
      "name": "Shazhou",
      "names": {
        "zh": "沙洲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1379901,
        "lng": 94.6634006
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        94.6357774,
        40.1270027,
        94.6793853,
        40.15438
      ]
    },
    {
      "id": 13504,
      "name": "Bafq",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.5997065,
        "lng": 55.3966892
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        55.3566892,
        31.5597065,
        55.4366892,
        31.6397065
      ]
    },
    {
      "id": 13506,
      "name": "Ciudad de Huajuapam de Leon",
      "names": {
        "es": "Ciudad de Huajuapam de León"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.80967,
        "lng": -97.77643
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.79643,
        17.78967,
        -97.75643,
        17.82967
      ]
    },
    {
      "id": 13510,
      "name": "Dunakeszi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6259102,
        "lng": 19.1359673
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.1309673,
        47.6209102,
        19.1409673,
        47.6309102
      ]
    },
    {
      "id": 13518,
      "name": "Svitlovodsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0459956,
        "lng": 33.2546693
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.2496693,
        49.0409956,
        33.2596693,
        49.0509956
      ]
    },
    {
      "id": 13522,
      "name": "Vallenar",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -28.5750438,
        "lng": -70.7616398
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.1993057,
        -29.3560379,
        -70.0034696,
        -28.0098642
      ]
    },
    {
      "id": 13524,
      "name": "Debica",
      "names": {
        "zh": "登比察"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0514601,
        "lng": 21.4104804
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.3664564,
        50.0068853,
        21.4655505,
        50.0850255
      ]
    },
    {
      "id": 13526,
      "name": "Biella",
      "names": {
        "zh": "比耶拉",
        "ja": "ビエッラ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5664109,
        "lng": 8.0542758
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        7.9351286,
        45.5368345,
        8.0962365,
        45.6456342
      ]
    },
    {
      "id": 13530,
      "name": "Tank",
      "names": {
        "es": "Tanque",
        "fr": "Réservoir",
        "zh": "坦克",
        "de": "Panzer",
        "it": "Serbatoio",
        "id": "Tangki",
        "ja": "タンク",
        "th": "ถัง",
        "pt": "Tanque de guerra"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2096916,
        "lng": 70.3881146
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        70.3481146,
        32.1696916,
        70.4281146,
        32.2496916
      ]
    },
    {
      "id": 13540,
      "name": "Wiener Neustadt",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.8131847,
        "lng": 16.2441166
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.1465067,
        47.7426825,
        16.2858954,
        47.8715706
      ]
    },
    {
      "id": 13545,
      "name": "Aksay",
      "names": {
        "zh": "阿克赛河"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.268696,
        "lng": 39.861362
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.8161926,
        47.2354981,
        39.9101329,
        47.3073794
      ]
    },
    {
      "id": 13550,
      "name": "Chateauroux",
      "names": {
        "zh": "沙托鲁"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.8094504,
        "lng": 1.6994314
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.6944314,
        46.8044504,
        1.7044314,
        46.8144504
      ]
    },
    {
      "id": 13552,
      "name": "Montemorelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.1886604,
        "lng": -99.8293539
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.8693539,
        25.1486604,
        -99.7893539,
        25.2286604
      ]
    },
    {
      "id": 13558,
      "name": "Tres Valles",
      "names": {
        "ja": "トレスバリュ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.240556,
        "lng": -96.135
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.175,
        18.200556,
        -96.095,
        18.280556
      ]
    },
    {
      "id": 13559,
      "name": "Rabinal",
      "names": {
        "zh": "拉维纳尔"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0845459,
        "lng": -90.491712
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.531712,
        15.0445459,
        -90.451712,
        15.1245459
      ]
    },
    {
      "id": 13571,
      "name": "Kaufbeuren",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8803788,
        "lng": 10.622246
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5510979,
        47.8409314,
        10.6612118,
        47.9217911
      ]
    },
    {
      "id": 13582,
      "name": "Turnhout",
      "names": {
        "zh": "蒂伦豪特"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.3227241,
        "lng": 4.9378811
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.9328811,
        51.3177241,
        4.9428811,
        51.3277241
      ]
    },
    {
      "id": 13584,
      "name": "Drama",
      "names": {
        "fr": "Drame",
        "zh": "戏剧",
        "it": "Dramma",
        "ja": "ドラマ",
        "th": "ละคร"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.1499443,
        "lng": 24.1468286
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.9868286,
        40.9899443,
        24.3068286,
        41.3099443
      ]
    },
    {
      "id": 13596,
      "name": "Jisr ash Shughur",
      "names": {
        "fr": "JISR Ash Shugghur",
        "de": "JISR Asche Shughur",
        "ja": "JISR灰のシュウグリ",
        "pt": "Jisr cinza shughur"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.81426,
        "lng": 36.32062
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.30062,
        35.79426,
        36.34062,
        35.83426
      ]
    },
    {
      "id": 13611,
      "name": "Arboletes",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.85051,
        "lng": -76.42694
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.44694,
        8.83051,
        -76.40694,
        8.87051
      ]
    },
    {
      "id": 13619,
      "name": "Etten-Leur",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5692065,
        "lng": 4.6360813
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5904205,
        51.5172052,
        4.6946341,
        51.6347547
      ]
    },
    {
      "id": 13622,
      "name": "Banaybanay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9671369,
        "lng": 126.0110165
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        125.8699058,
        6.8714063,
        126.29654,
        7.27071
      ]
    },
    {
      "id": 13623,
      "name": "Vlissingen",
      "names": {
        "zh": "弗利辛恩"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4480929,
        "lng": 3.5697992
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.3079354,
        51.3729692,
        3.738035,
        51.6164078
      ]
    },
    {
      "id": 13636,
      "name": "Memmingen",
      "names": {
        "zh": "梅明根",
        "ja": "メミンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.9867696,
        "lng": 10.181319
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.084774,
        47.915513,
        10.2339473,
        48.0474893
      ]
    },
    {
      "id": 13637,
      "name": "San Juan y Martinez",
      "names": {
        "es": "San Juan y Martínez",
        "zh": "圣胡安Y Martinez",
        "ja": "サンファンyマルチネス",
        "th": "ซานฮวน Y มาร์ติเนซ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.2729149,
        "lng": -83.8330385
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -83.8380385,
        22.2679149,
        -83.8280385,
        22.2779149
      ]
    },
    {
      "id": 13642,
      "name": "Simojovel de Allende",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1389768,
        "lng": -92.7104833
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.7504833,
        17.0989768,
        -92.6704833,
        17.1789768
      ]
    },
    {
      "id": 13647,
      "name": "Tuodian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.6913425,
        "lng": 101.6364585
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.5964585,
        24.6513425,
        101.6764585,
        24.7313425
      ]
    },
    {
      "id": 13667,
      "name": "Zevenaar",
      "names": {
        "zh": "泽弗纳尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9231973,
        "lng": 6.0719818
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.0669818,
        51.9181973,
        6.0769818,
        51.9281973
      ]
    },
    {
      "id": 13681,
      "name": "Badian",
      "names": {
        "zh": "巴迪安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.870125,
        "lng": 123.3989791
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.3589791,
        9.830125,
        123.4389791,
        9.910125
      ]
    },
    {
      "id": 13682,
      "name": "San Cristobal Totonicapan",
      "names": {
        "es": "San Cristóbal Totononicapan",
        "fr": "San Cristobal Totononicapan",
        "it": "San Cristobal Toonicapan",
        "ja": "サンクリストバールトトコパン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9180515,
        "lng": -91.442554
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.482554,
        14.8780515,
        -91.402554,
        14.9580515
      ]
    },
    {
      "id": 13691,
      "name": "Comendador",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.8766978,
        "lng": -71.7050752
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -71.8867729,
        18.8192773,
        -71.6162735,
        19.0179648
      ]
    },
    {
      "id": 13697,
      "name": "Zongolica",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.665725,
        "lng": -97.000284
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0085506766,
        18.6566389007,
        -96.9934110889,
        18.6717763155
      ]
    },
    {
      "id": 13699,
      "name": "Hinatuan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.3656147,
        "lng": 126.3358067
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        126.267842,
        8.2675621,
        126.5567145,
        8.678346
      ]
    },
    {
      "id": 13703,
      "name": "Oxchuc",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7845242,
        "lng": -92.3436869
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.3836869,
        16.7445242,
        -92.3036869,
        16.8245242
      ]
    },
    {
      "id": 13704,
      "name": "Khutubi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.19035,
        "lng": 86.88521
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.86521,
        44.17035,
        86.90521,
        44.21035
      ]
    },
    {
      "id": 13711,
      "name": "Nabire",
      "names": {
        "zh": "纳比雷"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.360285,
        "lng": 135.5023653
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        135.3423653,
        -3.520285,
        135.6623653,
        -3.200285
      ]
    },
    {
      "id": 13714,
      "name": "Baniyas",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.1820319,
        "lng": 35.9449068
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        35.9049068,
        35.1420319,
        35.9849068,
        35.2220319
      ]
    },
    {
      "id": 13717,
      "name": "Villeta",
      "names": {
        "zh": "比耶塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0112575,
        "lng": -74.4702304
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5438773,
        4.9414487,
        -74.4123298,
        5.0742202
      ]
    },
    {
      "id": 13727,
      "name": "Istres",
      "names": {
        "zh": "伊斯特尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5139051,
        "lng": 4.9884323
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.879767,
        43.46802,
        5.015509,
        43.6230213
      ]
    },
    {
      "id": 13729,
      "name": "Sambava",
      "names": {
        "zh": "桑巴瓦"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -14.26667,
        "lng": 50.16667
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        50.14667,
        -14.28667,
        50.18667,
        -14.24667
      ]
    },
    {
      "id": 13734,
      "name": "Macenta",
      "names": {
        "zh": "马森塔"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 8.54351,
        "lng": -9.47099
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -9.49099,
        8.52351,
        -9.45099,
        8.56351
      ]
    },
    {
      "id": 13735,
      "name": "Takab",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.4009,
        "lng": 47.1133
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        47.0933,
        36.3809,
        47.1333,
        36.4209
      ]
    },
    {
      "id": 13741,
      "name": "Roseller Lim",
      "names": {
        "zh": "罗塞尔林",
        "id": "Lim",
        "ja": "ロゼラーLIM",
        "pt": "Rosner lim"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6563848,
        "lng": 122.4631545
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        122.4231545,
        7.6163848,
        122.5031545,
        7.6963848
      ]
    },
    {
      "id": 13747,
      "name": "Xihuachi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.8193188,
        "lng": 108.0148264
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.8548264,
        35.6593188,
        108.1748264,
        35.9793188
      ]
    },
    {
      "id": 13748,
      "name": "Otwock",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1095585,
        "lng": 21.2630951
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.2580951,
        52.1045585,
        21.2680951,
        52.1145585
      ]
    },
    {
      "id": 13754,
      "name": "Ponte de Lima",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.7675021,
        "lng": -8.5830992
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6997148,
        41.6236707,
        -8.4614797,
        41.8702878
      ]
    },
    {
      "id": 13755,
      "name": "Venray",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.528662,
        "lng": 5.987487
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.941241,
        51.512492,
        6.002294,
        51.561063
      ]
    },
    {
      "id": 13756,
      "name": "Angouleme",
      "names": {
        "fr": "Angoulême",
        "zh": "昂古莱姆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6484505,
        "lng": 0.1561947
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.0996131,
        45.6200489,
        0.1904234,
        45.6715827
      ]
    },
    {
      "id": 13758,
      "name": "Noordwijk",
      "names": {
        "zh": "诺德韦克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2410856,
        "lng": 4.4461969
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3909757,
        52.2073556,
        4.5637074,
        52.3325109
      ]
    },
    {
      "id": 13761,
      "name": "Wismar",
      "names": {
        "zh": "维斯马",
        "ja": "ヴィスマール"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.892712,
        "lng": 11.463271
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.390866,
        53.86479,
        11.533539,
        53.943391
      ]
    },
    {
      "id": 13765,
      "name": "Nijkerk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.214853649999995,
        "lng": 5.478146323965094
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3932098,
        52.1657618,
        5.6348621,
        52.2639241
      ]
    },
    {
      "id": 13766,
      "name": "Bahia Honda",
      "names": {
        "es": "Bahía Honda",
        "zh": "巴伊亚本田",
        "ja": "バイアホンダ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.903451,
        "lng": -83.1618214
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -83.2018214,
        22.863451,
        -83.1218214,
        22.943451
      ]
    },
    {
      "id": 13780,
      "name": "Alenquer",
      "names": {
        "zh": "阿伦克尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0490112,
        "lng": -9.0145622
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.0146122,
        39.0489612,
        -9.0145122,
        39.0490612
      ]
    },
    {
      "id": 13783,
      "name": "Hueyapan de Ocampo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1511904,
        "lng": -95.1462708998541
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.1579478,
        18.1428609,
        -95.1315334,
        18.1593767
      ]
    },
    {
      "id": 13785,
      "name": "Esperanza",
      "names": {
        "zh": "埃斯佩兰萨",
        "ja": "エスペランサ",
        "th": "เอสเปอรัน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.448994,
        "lng": -60.9304048
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.9550099,
        -31.4730166,
        -60.8756548,
        -31.4075356
      ]
    },
    {
      "id": 13788,
      "name": "Lorenskog",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8970034,
        "lng": 10.966914823844895
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.9071585,
        59.8363839,
        11.0306255,
        59.957957
      ]
    },
    {
      "id": 13790,
      "name": "Rantepao",
      "names": {
        "zh": "兰特包"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.9696864,
        "lng": 119.898331
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        119.898281,
        -2.9697364,
        119.898381,
        -2.9696364
      ]
    },
    {
      "id": 13794,
      "name": "Andes",
      "names": {
        "zh": "安第斯山脉",
        "de": "Anden",
        "it": "Ande",
        "ja": "アンデス",
        "th": "เทือกเขาแอนดีส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6555942,
        "lng": -75.8778448
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9178448,
        5.6155942,
        -75.8378448,
        5.6955942
      ]
    },
    {
      "id": 13801,
      "name": "Aguazul",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.17282,
        "lng": -72.54706
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.56706,
        5.15282,
        -72.52706,
        5.19282
      ]
    },
    {
      "id": 13803,
      "name": "Ambatondrazaka",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -17.83333,
        "lng": 48.41667
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        48.39667,
        -17.85333,
        48.43667,
        -17.81333
      ]
    },
    {
      "id": 13805,
      "name": "Siegburg",
      "names": {
        "zh": "锡格堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8017001,
        "lng": 7.198282870715509
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.1741992,
        50.7797872,
        7.226931,
        50.8236627
      ]
    },
    {
      "id": 13808,
      "name": "Veroia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.5215342,
        "lng": 22.2036834
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.9279687,
        40.3001832,
        22.4049198,
        40.655142
      ]
    },
    {
      "id": 13809,
      "name": "Ciechanow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.8819798,
        "lng": 20.6191479
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.5606669,
        52.836115,
        20.6691427,
        52.906055
      ]
    },
    {
      "id": 13813,
      "name": "Nansan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7738508,
        "lng": 98.8255333
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.7855333,
        23.7338508,
        98.8655333,
        23.8138508
      ]
    },
    {
      "id": 13814,
      "name": "Tiel",
      "names": {
        "zh": "蒂尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8874333,
        "lng": 5.4372681
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3280301,
        51.851801,
        5.4724511,
        51.9227614
      ]
    },
    {
      "id": 13818,
      "name": "Cumaribo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4471495,
        "lng": -69.7975845
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -71.0777998,
        2.736754,
        -67.7101181,
        5.5644547
      ]
    },
    {
      "id": 13819,
      "name": "Singhanakhon",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 7.2285147,
        "lng": 100.5629515
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.4029515,
        7.0685147,
        100.7229515,
        7.3885147
      ]
    },
    {
      "id": 13824,
      "name": "Jesus Maria",
      "names": {
        "fr": "Jésus Maria",
        "zh": "耶稣玛丽亚",
        "it": "Gesù Maria",
        "id": "Yesus Maria",
        "ja": "イエスマリア",
        "th": "พระเยซูมาเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.64794705,
        "lng": -102.10757376228773
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.2860187,
        20.5101895,
        -101.9511437,
        20.7868365
      ]
    },
    {
      "id": 13828,
      "name": "Bihac",
      "names": {
        "zh": "比哈奇"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.8146563,
        "lng": 15.86896
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        15.8067803,
        44.7494161,
        15.9602741,
        44.8701451
      ]
    },
    {
      "id": 13834,
      "name": "Poptun",
      "names": {
        "es": "Poptún"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.3301185,
        "lng": -89.4194214
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.9402246,
        16.1653512,
        -89.1908371,
        16.4659114
      ]
    },
    {
      "id": 13835,
      "name": "Gualeguay",
      "names": {
        "zh": "瓜莱瓜伊"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.1504259,
        "lng": -59.3106789
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.3810958,
        -33.2310173,
        -59.2512998,
        -33.0956456
      ]
    },
    {
      "id": 13836,
      "name": "Jatibonico",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.9421689,
        "lng": -79.17144
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.2674555,
        21.720196,
        -78.9586864,
        22.1541168
      ]
    },
    {
      "id": 13839,
      "name": "Miranda",
      "names": {
        "zh": "米兰达",
        "ja": "ミランダ",
        "th": "มิแรนดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.322868750000001,
        "lng": -66.49588213785867
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -67.2458982,
        9.9371485,
        -65.420903,
        10.87641
      ]
    },
    {
      "id": 13842,
      "name": "Bourg-en-Bresse",
      "names": {
        "zh": "布尔-连接伯黑斯",
        "ja": "城下町-専用bresse",
        "th": "Bourg-ห้อง bresse"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.2051192,
        "lng": 5.2250324
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.206605,
        46.175288,
        5.2871108,
        46.2303199
      ]
    },
    {
      "id": 13844,
      "name": "Sieradz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.5942475,
        "lng": 18.7378774
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.6518309,
        51.5621282,
        18.8249305,
        51.6413184
      ]
    },
    {
      "id": 13846,
      "name": "Gifhorn",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4882194,
        "lng": 10.545304
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.4242053,
        52.4472279,
        10.6270957,
        52.5696038
      ]
    },
    {
      "id": 13848,
      "name": "Loufan",
      "names": {
        "zh": "娄烦"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0691073,
        "lng": 111.7981024
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.6381024,
        37.9091073,
        111.9581024,
        38.2291073
      ]
    },
    {
      "id": 13852,
      "name": "Zvolen",
      "names": {
        "zh": "兹沃伦"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5782517,
        "lng": 19.1247135
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.0804019,
        48.5243401,
        19.1651756,
        48.5996727
      ]
    },
    {
      "id": 13857,
      "name": "Nurmijarvi",
      "names": {
        "de": "Nurmijärvi"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.464898,
        "lng": 24.813311
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.782455,
        60.450775,
        24.837515,
        60.478956
      ]
    },
    {
      "id": 13860,
      "name": "Borken",
      "names": {
        "zh": "博尔肯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8444777,
        "lng": 6.8583277
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.7323128,
        51.7737275,
        6.9236203,
        51.9328675
      ]
    },
    {
      "id": 13862,
      "name": "Vawkavysk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.1647286,
        "lng": 24.458169
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.3859109,
        53.1109791,
        24.5054126,
        53.1791322
      ]
    },
    {
      "id": 13867,
      "name": "Eisenach",
      "names": {
        "zh": "爱森纳赫",
        "ja": "アイゼナハ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9747134,
        "lng": 10.3193565
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.1752359,
        50.9308125,
        10.3915234,
        51.0523906
      ]
    },
    {
      "id": 13871,
      "name": "Amberg",
      "names": {
        "zh": "安贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4454211,
        "lng": 11.858728
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.818728,
        49.4054211,
        11.898728,
        49.4854211
      ]
    },
    {
      "id": 13882,
      "name": "Mariel",
      "names": {
        "zh": "马列尔"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9890956,
        "lng": -82.7543784
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -83.0067533,
        22.9085842,
        -82.6661022,
        23.0282172
      ]
    },
    {
      "id": 13884,
      "name": "Kotabumi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.833742,
        "lng": 104.892713
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        104.839004,
        -4.886568,
        104.999864,
        -4.791844
      ]
    },
    {
      "id": 13888,
      "name": "Imperia",
      "names": {
        "zh": "因佩里亚",
        "ja": "インペリア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8862958,
        "lng": 8.0267106
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        7.946359,
        43.8606081,
        8.0720529,
        43.9454698
      ]
    },
    {
      "id": 13889,
      "name": "Charlottetown",
      "names": {
        "zh": "夏洛特敦",
        "ja": "シャーロットタウン",
        "th": "ชาร์ลอตต์ทาวน์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.234953,
        "lng": -63.132935
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -63.1969587,
        46.2171435,
        -63.0734013,
        46.3065672
      ]
    },
    {
      "id": 13891,
      "name": "Mikolow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.19600335,
        "lng": 18.853851637416344
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.7756293,
        50.1437017,
        18.9481443,
        50.248432
      ]
    },
    {
      "id": 13894,
      "name": "Ales",
      "names": {
        "zh": "啤酒",
        "ja": "エール",
        "th": "เบียร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.1278635,
        "lng": 4.0849332
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.0799332,
        44.1228635,
        4.0899332,
        44.1328635
      ]
    },
    {
      "id": 13899,
      "name": "El Charco",
      "names": {
        "fr": "El charbon",
        "zh": "埃尔科斯",
        "ja": "エルチャコ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.4798209,
        "lng": -78.110793
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -78.2261038,
        1.8952402,
        -77.3054051,
        2.6836948
      ]
    },
    {
      "id": 13906,
      "name": "Cuihua",
      "names": {
        "zh": "催化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7529382,
        "lng": 103.8876155
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.8476155,
        27.7129382,
        103.9276155,
        27.7929382
      ]
    },
    {
      "id": 13916,
      "name": "Shepetivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1809437,
        "lng": 27.0651783
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        26.9984708,
        50.149645,
        27.1242785,
        50.2115886
      ]
    },
    {
      "id": 13918,
      "name": "President Quirino",
      "names": {
        "es": "Presidente Quirino",
        "fr": "Président Quirino",
        "zh": "Quirino总统",
        "de": "Präsident Quirino",
        "it": "Presidente Quirino",
        "id": "Presiden Quirino",
        "ja": "Quiriino大統領",
        "th": "ประธาน Quiriino",
        "pt": "Presidente Quirino"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6992459,
        "lng": 124.7309252
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        124.7308752,
        6.6991959,
        124.7309752,
        6.6992959
      ]
    },
    {
      "id": 13922,
      "name": "Tactic",
      "names": {
        "es": "Táctica",
        "fr": "Tactique",
        "zh": "策略",
        "de": "Taktik",
        "it": "Tattica",
        "id": "Taktik",
        "ja": "戦術",
        "th": "กลยุทธ์",
        "pt": "Tática"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3189504,
        "lng": -90.3513913
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.3913913,
        15.2789504,
        -90.3113913,
        15.3589504
      ]
    },
    {
      "id": 13928,
      "name": "Heinsberg",
      "names": {
        "zh": "海因斯贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0645183,
        "lng": 6.0996221
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.0946221,
        51.0595183,
        6.1046221,
        51.0695183
      ]
    },
    {
      "id": 13929,
      "name": "Uden",
      "names": {
        "zh": "于登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.660481,
        "lng": 5.622621
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.561966,
        51.623176,
        5.672541,
        51.703527
      ]
    },
    {
      "id": 13936,
      "name": "Claveria",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9034794,
        "lng": 123.2457124
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        122.9527879,
        12.6177368,
        123.4859176,
        13.0347734
      ]
    },
    {
      "id": 13950,
      "name": "Homburg",
      "names": {
        "zh": "毡帽",
        "ja": "ホンブルク",
        "th": "ฮอม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3181673,
        "lng": 7.3340336
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.2737168,
        49.2474202,
        7.4048307,
        49.3863745
      ]
    },
    {
      "id": 13954,
      "name": "Ansbach",
      "names": {
        "zh": "安斯巴赫",
        "ja": "アンスバッハ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2979431,
        "lng": 10.5777328
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5727328,
        49.2929431,
        10.5827328,
        49.3029431
      ]
    },
    {
      "id": 13959,
      "name": "Macerata",
      "names": {
        "zh": "马切拉塔",
        "ja": "マチェラータ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.3007275,
        "lng": 13.4535897
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.3781402,
        43.2506665,
        13.5346588,
        43.3602296
      ]
    },
    {
      "id": 13961,
      "name": "Castres",
      "names": {
        "zh": "卡斯特尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6053809,
        "lng": 2.2411289
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.1563192,
        43.5559404,
        2.3334324,
        43.6703306
      ]
    },
    {
      "id": 13964,
      "name": "Aurich",
      "names": {
        "zh": "奥里希"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.4695604,
        "lng": 7.4823808
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.4018508,
        53.4054472,
        7.6791409,
        53.5617576
      ]
    },
    {
      "id": 13965,
      "name": "Nordhausen",
      "names": {
        "zh": "诺德豪森"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5051574,
        "lng": 10.7925317
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.6761482,
        51.4404771,
        10.9479028,
        51.5555504
      ]
    },
    {
      "id": 13974,
      "name": "Montenegro",
      "names": {
        "fr": "Monténégro",
        "zh": "黑山",
        "ja": "モンテネグロ",
        "th": "มอนเตเนโกร"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5664176,
        "lng": -75.7507081
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8813882,
        4.4506291,
        -75.7181919,
        4.5814096
      ]
    },
    {
      "id": 13977,
      "name": "Caceres",
      "names": {
        "es": "Cáceres",
        "zh": "卡塞雷斯",
        "id": "Cáceres",
        "ja": "カセレス",
        "th": "คาเซเรส",
        "pt": "Cáceres"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.577089,
        "lng": -75.3514383
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4844433,
        7.3509272,
        -74.9933437,
        7.9618434
      ]
    },
    {
      "id": 13979,
      "name": "Lingquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9997355,
        "lng": 110.8329434
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        110.7929434,
        36.9597355,
        110.8729434,
        37.0397355
      ]
    },
    {
      "id": 13983,
      "name": "Coburg",
      "names": {
        "zh": "科堡",
        "ja": "コーブルク",
        "th": "โคเบิร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.2582226,
        "lng": 10.964561
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8868702,
        50.2217501,
        11.0637628,
        50.2989443
      ]
    },
    {
      "id": 13986,
      "name": "Poti",
      "names": {
        "zh": "波季"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 42.14616,
        "lng": 41.67197
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        41.65197,
        42.12616,
        41.69197,
        42.16616
      ]
    },
    {
      "id": 13988,
      "name": "Sarab",
      "names": {
        "zh": "萨拉卜"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.9407851,
        "lng": 47.5374867
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        47.4974867,
        37.9007851,
        47.5774867,
        37.9807851
      ]
    },
    {
      "id": 13989,
      "name": "Su-ngai Kolok",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.0259786,
        "lng": 101.9648719
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.9598719,
        6.0209786,
        101.9698719,
        6.0309786
      ]
    },
    {
      "id": 13992,
      "name": "Coyuca de Catalan",
      "names": {
        "es": "COYUCA DE CATALANO",
        "fr": "Coyeuca de Catalan",
        "zh": "Coyuca de加泰罗尼亚州",
        "it": "Coyuca de catalano",
        "id": "Ciyuca de catalan",
        "pt": "Coyuca de Catalão"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3253222,
        "lng": -100.6995289
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.7395289,
        18.2853222,
        -100.6595289,
        18.3653222
      ]
    },
    {
      "id": 13993,
      "name": "Korenovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.4635863,
        "lng": 39.4487565
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.3918775,
        45.4327568,
        39.5145015,
        45.4881846
      ]
    },
    {
      "id": 13999,
      "name": "Tequila",
      "names": {
        "zh": "龙舌兰酒",
        "ja": "テキーラ",
        "th": "เตกีล่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.879626,
        "lng": -103.836252
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.848339,
        20.864494,
        -103.802356,
        20.898349
      ]
    },
    {
      "id": 14002,
      "name": "Santa Rosa del Sur",
      "names": {
        "zh": "圣罗莎del sur",
        "ja": "サンタローザデルシュール"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9635222,
        "lng": -74.0506379
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2106379,
        7.8035222,
        -73.8906379,
        8.1235222
      ]
    },
    {
      "id": 14005,
      "name": "Yinggen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.0370568,
        "lng": 109.8361654
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.7961654,
        18.9970568,
        109.8761654,
        19.0770568
      ]
    },
    {
      "id": 14009,
      "name": "Tadif",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.34814,
        "lng": 37.5309
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.5109,
        36.32814,
        37.5509,
        36.36814
      ]
    },
    {
      "id": 14015,
      "name": "San",
      "names": {
        "zh": "圣",
        "ja": "サン",
        "th": "ซาน"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 48.1978559,
        "lng": 3.282606
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        3.2597586,
        48.159611,
        3.3478838,
        48.2299226
      ]
    },
    {
      "id": 14017,
      "name": "Uspantan",
      "names": {
        "es": "Uspantán"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3469675,
        "lng": -90.8703939
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.9103939,
        15.3069675,
        -90.8303939,
        15.3869675
      ]
    },
    {
      "id": 14025,
      "name": "Bayt al Faqih",
      "names": {
        "ja": "ベイアルFAQIH"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.516353,
        "lng": 43.324459
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        43.284459,
        14.476353,
        43.364459,
        14.556353
      ]
    },
    {
      "id": 14036,
      "name": "Kwidzyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.7335842,
        "lng": 18.9319222
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.9015146,
        53.6847913,
        18.9890535,
        53.75614
      ]
    },
    {
      "id": 14038,
      "name": "Chojnice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.6952912,
        "lng": 17.5614973
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.5091254,
        53.6761176,
        17.6111911,
        53.723822
      ]
    },
    {
      "id": 14045,
      "name": "Amuntai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.4196178,
        "lng": 115.2539707
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        115.2139707,
        -2.4596178,
        115.2939707,
        -2.3796178
      ]
    },
    {
      "id": 14046,
      "name": "Schwabach",
      "names": {
        "zh": "施瓦巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3288674,
        "lng": 11.0212824
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.9704142,
        49.2933656,
        11.0873297,
        49.3760278
      ]
    },
    {
      "id": 14056,
      "name": "Santa Cruz de Los Taques",
      "names": {
        "fr": "Santa Cruz de los Tatques",
        "ja": "サンタクルスデロスタクス"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.8245826,
        "lng": -70.2517878
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.2917878,
        11.7845826,
        -70.2117878,
        11.8645826
      ]
    },
    {
      "id": 14060,
      "name": "Pueblo Nuevo",
      "names": {
        "zh": "普韦布洛·努沃"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.01173565,
        "lng": -79.51194121530838
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -79.5252866,
        8.9938805,
        -79.5045663,
        9.0292775
      ]
    },
    {
      "id": 14061,
      "name": "Podilsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.7497422,
        "lng": 29.5304957
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.4868837,
        47.7043847,
        29.5726543,
        47.7811709
      ]
    },
    {
      "id": 14065,
      "name": "Swinoujscie",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9097477,
        "lng": 14.2510703
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.185329,
        53.7685197,
        14.4409576,
        53.9375889
      ]
    },
    {
      "id": 14068,
      "name": "Eberswalde",
      "names": {
        "zh": "埃伯斯瓦尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.838209,
        "lng": 13.82194
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.657944,
        52.776502,
        13.910206,
        52.883239
      ]
    },
    {
      "id": 14069,
      "name": "Tuchin",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1865401,
        "lng": -75.5551384
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6017679,
        9.1527539,
        -75.4786386,
        9.2863767
      ]
    },
    {
      "id": 14073,
      "name": "Saint-Germain-en-Laye",
      "names": {
        "zh": "圣日耳曼-连接图层",
        "ja": "・サンジェルマン・専用laye",
        "th": "แซงต์แชร์กแมงออง-laye"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8990413,
        "lng": 2.0942792
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.0372132,
        48.8746786,
        2.1731173,
        48.9872176
      ]
    },
    {
      "id": 14083,
      "name": "Yarumal",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.96321,
        "lng": -75.41738
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.43738,
        6.94321,
        -75.39738,
        6.98321
      ]
    },
    {
      "id": 14091,
      "name": "Nowa Sol",
      "names": {
        "zh": "现在是索尔",
        "id": "Nona Sol",
        "th": "ตอนนี้โซล"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8019595,
        "lng": 15.7175608
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.6773003,
        51.7654818,
        15.7464029,
        51.8413252
      ]
    },
    {
      "id": 14095,
      "name": "Wijchen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.806807,
        "lng": 5.723267
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.675515,
        51.788131,
        5.774715,
        51.839709
      ]
    },
    {
      "id": 14112,
      "name": "Nazilli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9097512,
        "lng": 28.3242062
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.1965162,
        37.7389272,
        28.5281671,
        38.1292952
      ]
    },
    {
      "id": 14113,
      "name": "Szigetszentmiklos",
      "names": {
        "de": "Szigetszentmiklós"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3442674,
        "lng": 19.0503201
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.0502701,
        47.3442174,
        19.0503701,
        47.3443174
      ]
    },
    {
      "id": 14115,
      "name": "Sovetsk",
      "names": {
        "zh": "苏维埃茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0829327,
        "lng": 21.8796642
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        21.8746642,
        55.0779327,
        21.8846642,
        55.0879327
      ]
    },
    {
      "id": 14121,
      "name": "El Tocuyo",
      "names": {
        "fr": "El ttucuyo",
        "ja": "エルツクイオ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.78734,
        "lng": -69.7937
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.8137,
        9.76734,
        -69.7737,
        9.80734
      ]
    },
    {
      "id": 14123,
      "name": "Niquero",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0477343,
        "lng": -77.5843332
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.7411032,
        19.8264289,
        -77.435346,
        20.1015165
      ]
    },
    {
      "id": 14124,
      "name": "Arauquita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.02917,
        "lng": -71.42806
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -71.44806,
        7.00917,
        -71.40806,
        7.04917
      ]
    },
    {
      "id": 14127,
      "name": "Chaves",
      "names": {
        "zh": "查韦斯"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.7399003,
        "lng": -7.4715213
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.6205559,
        41.5812737,
        -7.1929479,
        41.8800101
      ]
    },
    {
      "id": 14128,
      "name": "Compiegne",
      "names": {
        "fr": "Compiègne",
        "zh": "贡比涅",
        "ja": "コンピエーニュ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4179497,
        "lng": 2.8263171
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.77918,
        49.3668384,
        2.9302756,
        49.4344057
      ]
    },
    {
      "id": 14129,
      "name": "Hellevoetsluis",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.83145,
        "lng": 4.14323
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.048596,
        51.819233,
        4.198257,
        51.881603
      ]
    },
    {
      "id": 14130,
      "name": "Thionville",
      "names": {
        "zh": "蒂翁维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.3579272,
        "lng": 6.1675872
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.0554356,
        49.3281869,
        6.2333027,
        49.4154624
      ]
    },
    {
      "id": 14134,
      "name": "Jarvenpaa",
      "names": {
        "fr": "Järvenpää",
        "zh": "耶尔文佩"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4737479,
        "lng": 25.090678
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.085678,
        60.4687479,
        25.095678,
        60.4787479
      ]
    },
    {
      "id": 14137,
      "name": "Ceyhan",
      "names": {
        "zh": "杰伊汗"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0288825,
        "lng": 35.8124428
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.5670601,
        36.8409602,
        36.099166,
        37.2764454
      ]
    },
    {
      "id": 14141,
      "name": "Temryuk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.270754,
        "lng": 37.371037
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        37.348473,
        45.25528,
        37.405251,
        45.282444
      ]
    },
    {
      "id": 14142,
      "name": "Guaduas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0674669,
        "lng": -74.5950774
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7429407,
        4.9652075,
        -74.5010882,
        5.4435646
      ]
    },
    {
      "id": 14149,
      "name": "Shanhe",
      "names": {
        "zh": "山河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4922133,
        "lng": 108.3550158
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.1950158,
        35.3322133,
        108.5150158,
        35.6522133
      ]
    },
    {
      "id": 14150,
      "name": "Boleslawiec",
      "names": {
        "zh": "博莱斯瓦维茨"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.2660812,
        "lng": 15.5695267
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.5645267,
        51.2610812,
        15.5745267,
        51.2710812
      ]
    },
    {
      "id": 14164,
      "name": "Ciudad Dario",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.73143,
        "lng": -86.12402
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.14402,
        12.71143,
        -86.10402,
        12.75143
      ]
    },
    {
      "id": 14169,
      "name": "Pirmasens",
      "names": {
        "zh": "皮尔马森斯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.203633,
        "lng": 7.601581
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.514249,
        49.141338,
        7.673351,
        49.234742
      ]
    },
    {
      "id": 14171,
      "name": "Chambas",
      "names": {
        "zh": "钱巴斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1939851,
        "lng": -78.9113042
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.0326199,
        22.092435,
        -78.6730099,
        22.4015199
      ]
    },
    {
      "id": 14175,
      "name": "Maarssen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1384846,
        "lng": 5.040082
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.0025672,
        52.1167159,
        5.1055763,
        52.1659908
      ]
    },
    {
      "id": 14177,
      "name": "Segovia",
      "names": {
        "fr": "Ségovie",
        "zh": "塞哥维亚",
        "ja": "セゴビア",
        "th": "เซโกเวีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.081679,
        "lng": -74.7009089
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7409089,
        7.041679,
        -74.6609089,
        7.121679
      ]
    },
    {
      "id": 14181,
      "name": "El Tumbador",
      "names": {
        "fr": "El tambadeur",
        "pt": "El tumpador"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8635059,
        "lng": -91.9341368
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.0490186,
        14.7656364,
        -91.8254064,
        14.9111264
      ]
    },
    {
      "id": 14185,
      "name": "Novozybkov",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.537086,
        "lng": 31.93367
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        31.8752046,
        52.4940898,
        31.9936037,
        52.5802634
      ]
    },
    {
      "id": 14186,
      "name": "Taicheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7235224,
        "lng": 113.2492982
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.2092982,
        38.6835224,
        113.2892982,
        38.7635224
      ]
    },
    {
      "id": 14193,
      "name": "Romny",
      "names": {
        "zh": "罗姆内"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.74207,
        "lng": 33.4877
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.4108444,
        50.7009499,
        33.5357122,
        50.7787019
      ]
    },
    {
      "id": 14195,
      "name": "Gap",
      "names": {
        "es": "Brecha",
        "fr": "Écart",
        "zh": "差距",
        "de": "Lücke",
        "ja": "ギャップ",
        "th": "ช่องว่าง",
        "pt": "Lacuna"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5612032,
        "lng": 6.0820639
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.9805869,
        44.4947938,
        6.1411293,
        44.6650138
      ]
    },
    {
      "id": 14200,
      "name": "Dronten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.525906,
        "lng": 5.713653
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.588056,
        52.456361,
        5.862625,
        52.6049
      ]
    },
    {
      "id": 14207,
      "name": "Schwabisch Hall",
      "names": {
        "es": "Hall de Schwabisch",
        "zh": "施瓦巴斯霍尔",
        "de": "Schwäbige Halle",
        "id": "Aula schwabisch",
        "ja": "シュヴァイビスホール",
        "th": "ห้องโถง Schwabisch",
        "pt": "Salão de schwabisch"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.1123963,
        "lng": 9.7369047
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.6132541,
        49.0459744,
        9.8754428,
        49.1668978
      ]
    },
    {
      "id": 14218,
      "name": "Tomar",
      "names": {
        "zh": "托马尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6036596,
        "lng": -8.4150492
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5125327,
        39.4766061,
        -8.236406,
        39.7077751
      ]
    },
    {
      "id": 14219,
      "name": "Gonabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.35286,
        "lng": 58.68365
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.66365,
        34.33286,
        58.70365,
        34.37286
      ]
    },
    {
      "id": 14221,
      "name": "Pie de Pato",
      "names": {
        "it": "Torta de pato",
        "ja": "パイパト",
        "th": "พายเดอพาโต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5160157,
        "lng": -76.9742895
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.9942895,
        5.4960157,
        -76.9542895,
        5.5360157
      ]
    },
    {
      "id": 14226,
      "name": "Heemskerk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4946348,
        "lng": 4.6861262
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6811262,
        52.4896348,
        4.6911262,
        52.4996348
      ]
    },
    {
      "id": 14228,
      "name": "Vahdat",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5614133,
        "lng": 69.0172675
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        68.9811126,
        38.5297032,
        69.0398025,
        38.5939314
      ]
    },
    {
      "id": 14230,
      "name": "Freiberg",
      "names": {
        "zh": "弗莱贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9086704,
        "lng": 13.3445784
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.3395784,
        50.9036704,
        13.3495784,
        50.9136704
      ]
    },
    {
      "id": 14237,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9737744,
        "lng": -75.3613093
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4275839,
        5.8576303,
        -75.2697132,
        6.0453384
      ]
    },
    {
      "id": 14261,
      "name": "Cumbal",
      "names": {
        "zh": "昆巴尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.9092082,
        "lng": -77.788906
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -78.2091755,
        0.8012896,
        -77.7560999,
        1.0779614
      ]
    },
    {
      "id": 14264,
      "name": "Halberstadt",
      "names": {
        "zh": "哈尔伯施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8953514,
        "lng": 11.0520563
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8813728,
        51.83097,
        11.174838,
        51.9571759
      ]
    },
    {
      "id": 14267,
      "name": "Yian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.99998155,
        "lng": 120.8701546012779
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        120.8678737,
        31.9992054,
        120.8719151,
        32.0009081
      ]
    },
    {
      "id": 14270,
      "name": "Neumarkt",
      "names": {
        "zh": "诺伊马克特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.279624,
        "lng": 11.4594662
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.3746936,
        49.2359512,
        11.5864273,
        49.3198223
      ]
    },
    {
      "id": 14277,
      "name": "Asuncion Mita",
      "names": {
        "es": "Asunción mita",
        "zh": "亚松森mita",
        "ja": "アスンシオンMITA",
        "pt": "Assunção Mita"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3332059,
        "lng": -89.7110973
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.7510973,
        14.2932059,
        -89.6710973,
        14.3732059
      ]
    },
    {
      "id": 14279,
      "name": "Hofheim",
      "names": {
        "zh": "霍夫海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0841921,
        "lng": 8.4445447
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.4395447,
        50.0791921,
        8.4495447,
        50.0891921
      ]
    },
    {
      "id": 14281,
      "name": "Aracataca",
      "names": {
        "zh": "阿拉卡塔卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.5906011,
        "lng": -74.1876803
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2574484,
        10.4248825,
        -73.5419834,
        10.8798888
      ]
    },
    {
      "id": 14292,
      "name": "Tuquerres",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.0877024,
        "lng": -77.6188242
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.7023467,
        1.0369255,
        -77.5590662,
        1.2892966
      ]
    },
    {
      "id": 14297,
      "name": "Oswiecim",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0382443,
        "lng": 19.2214053
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.1778529,
        50.0095664,
        19.3344515,
        50.0570688
      ]
    },
    {
      "id": 14304,
      "name": "Belaya Kalitva",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.175342,
        "lng": 40.783285
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.771778,
        48.155945,
        40.812637,
        48.200222
      ]
    },
    {
      "id": 14309,
      "name": "Gourcy",
      "names": {
        "zh": "古尔西"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 13.20776,
        "lng": -2.35893
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -2.37893,
        13.18776,
        -2.33893,
        13.22776
      ]
    },
    {
      "id": 14313,
      "name": "Povazska Bystrica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.1159316,
        "lng": 18.4469936
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.382723,
        49.0813737,
        18.5003489,
        49.130415
      ]
    },
    {
      "id": 14318,
      "name": "Saraqib",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.86447,
        "lng": 36.80591
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.78591,
        35.84447,
        36.82591,
        35.88447
      ]
    },
    {
      "id": 14320,
      "name": "San Agustin Acasaguastlan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.95,
        "lng": -89.96667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.98667,
        14.93,
        -89.94667,
        14.97
      ]
    },
    {
      "id": 14323,
      "name": "Camargo",
      "names": {
        "zh": "卡马戈"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.18044285,
        "lng": -98.8630369930155
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.0267002,
        25.9683227,
        -98.67771,
        26.39446
      ]
    },
    {
      "id": 14329,
      "name": "Hangu",
      "names": {
        "zh": "汉沽"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.527996,
        "lng": 71.0575407
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.0175407,
        33.487996,
        71.0975407,
        33.567996
      ]
    },
    {
      "id": 14333,
      "name": "Raxruha",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.8638933,
        "lng": -90.0416426
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.0816426,
        15.8238933,
        -90.0016426,
        15.9038933
      ]
    },
    {
      "id": 14334,
      "name": "Oroqen Zizhiqi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 50.566667,
        "lng": 123.716676
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        123.696675577,
        50.5466666861,
        123.736675577,
        50.5866666861
      ]
    },
    {
      "id": 14342,
      "name": "Skovde",
      "names": {
        "zh": "舍夫德",
        "de": "Skövde",
        "id": "Skövde"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.392566,
        "lng": 13.854159
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        13.800269,
        58.352616,
        13.905142,
        58.4356
      ]
    },
    {
      "id": 14344,
      "name": "Altagracia de Orituco",
      "names": {
        "fr": "Altagracia de Orruco"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.86005,
        "lng": -66.38139
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.40139,
        9.84005,
        -66.36139,
        9.88005
      ]
    },
    {
      "id": 14347,
      "name": "Isfara",
      "names": {
        "zh": "伊斯法拉"
      },
      "countryCode": "TJ",
      "latLng": {
        "lat": 40.12648,
        "lng": 70.62526
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        70.60526,
        40.10648,
        70.64526,
        40.14648
      ]
    },
    {
      "id": 14348,
      "name": "Jayrud",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.8074,
        "lng": 36.74036
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.72036,
        33.7874,
        36.76036,
        33.8274
      ]
    },
    {
      "id": 14351,
      "name": "Alashankou",
      "names": {
        "zh": "阿拉山口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.1702695,
        "lng": 82.5704564
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        82.5654564,
        45.1652695,
        82.5754564,
        45.1752695
      ]
    },
    {
      "id": 14365,
      "name": "Wageningen",
      "names": {
        "zh": "瓦赫宁根"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9663016,
        "lng": 5.6662814
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6058239,
        51.9364055,
        5.7243627,
        52.0007083
      ]
    },
    {
      "id": 14366,
      "name": "Rauma",
      "names": {
        "zh": "劳马"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1289148,
        "lng": 21.5039401
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        20.7731398,
        60.9963249,
        22.0707173,
        61.2848724
      ]
    },
    {
      "id": 14369,
      "name": "Acevedo",
      "names": {
        "zh": "阿塞韦多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.8070452,
        "lng": -75.888501
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1682587,
        1.5554725,
        -75.8354409,
        1.870224
      ]
    },
    {
      "id": 14370,
      "name": "Villa de San Diego de Ubate",
      "names": {
        "ja": "ヴィラデサンディエゴデウベイト"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.30933,
        "lng": -73.81575
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14379,
      "name": "Michalovce",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7514383,
        "lng": 21.9211949
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.714767,
        48.446822,
        22.166361,
        48.901985
      ]
    },
    {
      "id": 14386,
      "name": "Soria",
      "names": {
        "zh": "索里亚",
        "ja": "ソリア",
        "th": "โซเรีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.7633842,
        "lng": -2.4642041
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -3.0167705,
        41.676331,
        -1.9133408,
        41.9328525
      ]
    },
    {
      "id": 14388,
      "name": "Draguignan",
      "names": {
        "zh": "德拉吉尼昂"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5374662,
        "lng": 6.4627333
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.3966057,
        43.4964671,
        6.5331702,
        43.5727083
      ]
    },
    {
      "id": 14398,
      "name": "Apsheronsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.4618046,
        "lng": 39.741048
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.6712089,
        44.4195861,
        39.7812244,
        44.4934839
      ]
    },
    {
      "id": 14399,
      "name": "Libano",
      "names": {
        "zh": "利巴诺"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9229399,
        "lng": -75.0647738
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1169526,
        4.7529331,
        -74.9645682,
        4.9987077
      ]
    },
    {
      "id": 14401,
      "name": "Dolores",
      "names": {
        "zh": "多洛雷斯",
        "ja": "ドロレス",
        "th": "โดโลเรส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 37.7524811,
        "lng": -108.4596087
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -109.043475,
        37.4807215,
        -107.861549,
        37.896397
      ]
    },
    {
      "id": 14402,
      "name": "Bosconia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9730446,
        "lng": -73.8860998
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.0460998,
        9.8130446,
        -73.7260998,
        10.1330446
      ]
    },
    {
      "id": 14409,
      "name": "Steyr",
      "names": {
        "zh": "斯太尔"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0390046,
        "lng": 14.4191276
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.3762242,
        48.0235758,
        14.4699257,
        48.0889081
      ]
    },
    {
      "id": 14411,
      "name": "Salcedo",
      "names": {
        "zh": "萨尔塞多"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.4467462,
        "lng": -70.38906313451662
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.4575394,
        19.3360136,
        -70.3331318,
        19.5575015
      ]
    },
    {
      "id": 14415,
      "name": "Mettmann",
      "names": {
        "zh": "梅特曼"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.252811,
        "lng": 6.970846
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.92697,
        51.217245,
        7.04244,
        51.29482
      ]
    },
    {
      "id": 14425,
      "name": "La Chaux-de-Fonds",
      "names": {
        "ja": "La Chaux-de-Fongs",
        "pt": "La chaux-de-afligornos"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0983285,
        "lng": 6.8255759
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        6.8255259,
        47.0982785,
        6.8256259,
        47.0983785
      ]
    },
    {
      "id": 14439,
      "name": "Stendal",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.604319,
        "lng": 11.85727
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.568959,
        52.484712,
        11.978903,
        52.701089
      ]
    },
    {
      "id": 14442,
      "name": "Sanjiang",
      "names": {
        "zh": "三江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.4316137,
        "lng": 113.1027216
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.0627216,
        22.3916137,
        113.1427216,
        22.4716137
      ]
    },
    {
      "id": 14446,
      "name": "Trofa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3363471,
        "lng": -8.5585061
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6344883,
        41.2572502,
        -8.5061754,
        41.347858
      ]
    },
    {
      "id": 14448,
      "name": "San Jose de Ocoa",
      "names": {
        "zh": "圣何塞德ocoa",
        "it": "San Jose de ocaa",
        "ja": "サンノゼ斗",
        "th": "ซานโฮเซ่เดอโอโค"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.546405,
        "lng": -70.5056703
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -70.6866356,
        18.4199476,
        -70.3481851,
        18.6946557
      ]
    },
    {
      "id": 14451,
      "name": "Borlange",
      "names": {
        "de": "Borlänge",
        "pt": "Borlänge"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 60.4856426,
        "lng": 15.4234561
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        15.3834561,
        60.4456426,
        15.4634561,
        60.5256426
      ]
    },
    {
      "id": 14454,
      "name": "San Juan del Cesar",
      "names": {
        "zh": "圣胡安del Cesar",
        "ja": "サンファンデルセザー",
        "th": "ซานฮวนเดลซีซาร์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.77108,
        "lng": -73.00314
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.02314,
        10.75108,
        -72.98314,
        10.79108
      ]
    },
    {
      "id": 14460,
      "name": "Tlalixcoyan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.803963,
        "lng": -96.063784
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.08056,
        18.78222,
        -96.04056,
        18.82222
      ]
    },
    {
      "id": 14475,
      "name": "Abrantes",
      "names": {
        "zh": "阿夫兰特斯"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4631905,
        "lng": -8.1973618
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3555669,
        39.2333137,
        -7.9394578,
        39.6464938
      ]
    },
    {
      "id": 14476,
      "name": "Apan",
      "names": {
        "zh": "阿潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7098559,
        "lng": -98.4502318
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4902318,
        19.6698559,
        -98.4102318,
        19.7498559
      ]
    },
    {
      "id": 14477,
      "name": "Puerto Berrio",
      "names": {
        "ja": "プエルトベルリオ",
        "th": "เปอร์โตริโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.49156,
        "lng": -74.40326
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.42326,
        6.47156,
        -74.38326,
        6.51156
      ]
    },
    {
      "id": 14486,
      "name": "Baghlan",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.13068,
        "lng": 68.70829
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        68.68829,
        36.11068,
        68.72829,
        36.15068
      ]
    },
    {
      "id": 14490,
      "name": "Taraza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.5806833,
        "lng": -75.4006111
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4006611,
        7.5806333,
        -75.4005611,
        7.5807333
      ]
    },
    {
      "id": 14495,
      "name": "Yakacik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.7559322,
        "lng": 36.2151957
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.1864605,
        36.6847601,
        36.3980404,
        36.7958893
      ]
    },
    {
      "id": 14505,
      "name": "El Viejo",
      "names": {
        "ja": "エルビエホ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.6632331,
        "lng": -87.1702626
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -87.8958171,
        12.4026616,
        -87.1193537,
        13.1780817
      ]
    },
    {
      "id": 14519,
      "name": "Xiwanzi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9705151,
        "lng": 115.2767385
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        115.1167385,
        40.8105151,
        115.4367385,
        41.1305151
      ]
    },
    {
      "id": 14526,
      "name": "Jiquipilas",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.6686254,
        "lng": -93.6461169
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.6861169,
        16.6286254,
        -93.6061169,
        16.7086254
      ]
    },
    {
      "id": 14530,
      "name": "Al Malikiyah",
      "names": {
        "id": "Al Mangiyah",
        "ja": "アルマリキヤ"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 37.1749374,
        "lng": 42.1413363
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        42.1013363,
        37.1349374,
        42.1813363,
        37.2149374
      ]
    },
    {
      "id": 14536,
      "name": "Landgraaf",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9080845,
        "lng": 6.0264604
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9906272,
        50.8702199,
        6.094184,
        50.9347014
      ]
    },
    {
      "id": 14537,
      "name": "Ilhavo",
      "names": {
        "pt": "Ílhavo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6001108,
        "lng": -8.6666667
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7660889,
        40.562442,
        -8.6180954,
        40.6598551
      ]
    },
    {
      "id": 14542,
      "name": "Toumodi",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.55799,
        "lng": -5.01769
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -5.03769,
        6.53799,
        -4.99769,
        6.57799
      ]
    },
    {
      "id": 14554,
      "name": "Pirna",
      "names": {
        "zh": "皮尔纳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9616923,
        "lng": 13.9386643
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.8821859,
        50.9027979,
        14.0147439,
        51.017922
      ]
    },
    {
      "id": 14565,
      "name": "Galeana",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8250572,
        "lng": -100.076812
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.116812,
        24.7850572,
        -100.036812,
        24.8650572
      ]
    },
    {
      "id": 14566,
      "name": "Dubno",
      "names": {
        "zh": "杜布诺",
        "th": "ดับโน"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.4187918,
        "lng": 25.7455972
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.6905521,
        50.3560273,
        25.7885704,
        50.4391742
      ]
    },
    {
      "id": 14571,
      "name": "Marinha Grande",
      "names": {
        "ja": "マリンハグランデ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.7495952,
        "lng": -8.9298895
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.0398814,
        39.6884735,
        -8.8680207,
        39.8906563
      ]
    },
    {
      "id": 14578,
      "name": "Furstenfeldbruck",
      "names": {
        "fr": "Fürstenfeldbruck",
        "zh": "福斯滕费尔德布鲁克",
        "de": "Fürstenfeldbruck",
        "it": "Fürstenfeldbruck"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1794705,
        "lng": 11.2547202
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.1722731,
        48.1460919,
        11.2851981,
        48.2035763
      ]
    },
    {
      "id": 14579,
      "name": "Balcarce",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.8464435,
        "lng": -58.2555452
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.2806761,
        -37.8757705,
        -58.223412,
        -37.8278788
      ]
    },
    {
      "id": 14586,
      "name": "Dabola",
      "names": {
        "zh": "达博拉"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.75,
        "lng": -11.11667
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -11.13667,
        10.73,
        -11.09667,
        10.77
      ]
    },
    {
      "id": 14587,
      "name": "Tuusula",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4017496,
        "lng": 25.0280695
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.8662072,
        60.3324477,
        25.1535811,
        60.5806498
      ]
    },
    {
      "id": 14588,
      "name": "Yako",
      "names": {
        "zh": "矢向"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.9612908,
        "lng": -2.2634317
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -2.3034317,
        12.9212908,
        -2.2234317,
        13.0012908
      ]
    },
    {
      "id": 14590,
      "name": "Cajibio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6231693,
        "lng": -76.5693504
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.8796941,
        2.5486525,
        -76.4931581,
        2.7591817
      ]
    },
    {
      "id": 14593,
      "name": "Los Palacios",
      "names": {
        "zh": "洛杉矶帕拉西奥斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5854043,
        "lng": -83.248131
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -83.4762814,
        22.3256373,
        -83.0806297,
        22.7274569
      ]
    },
    {
      "id": 14598,
      "name": "Kirkkonummi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.11968105,
        "lng": 24.43530879569006
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.4335681,
        60.119512,
        24.4393143,
        60.11976
      ]
    },
    {
      "id": 14603,
      "name": "Dzhankoi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.70939,
        "lng": 34.38721
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 14604,
      "name": "Sibate",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4916256,
        "lng": -74.2594306
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2706967,
        4.4734375,
        -74.2496732,
        4.5027181
      ]
    },
    {
      "id": 14607,
      "name": "Nove Zamky",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 47.9861843,
        "lng": 18.1631415
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.961021,
        47.7551703,
        18.856137,
        48.206851
      ]
    },
    {
      "id": 14612,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 13.53263185,
        "lng": -87.90258201724367
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -88.0918679,
        13.1439882,
        -87.6837976,
        13.9222573
      ]
    },
    {
      "id": 14621,
      "name": "Zary",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6344652,
        "lng": 15.1377011
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.1327011,
        51.6294652,
        15.1427011,
        51.6394652
      ]
    },
    {
      "id": 14625,
      "name": "Abinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.8649528,
        "lng": 38.1578189
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.1053278,
        44.8364049,
        38.2075314,
        44.904013
      ]
    },
    {
      "id": 14627,
      "name": "Lanyi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7008074,
        "lng": 111.5661142
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.5261142,
        38.6608074,
        111.6061142,
        38.7408074
      ]
    },
    {
      "id": 14630,
      "name": "Veghel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.615017,
        "lng": 5.545228
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.497202,
        51.587407,
        5.587545,
        51.651434
      ]
    },
    {
      "id": 14631,
      "name": "Jaroslaw",
      "names": {
        "zh": "雅罗斯拉夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0159029,
        "lng": 22.6796573
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.6168689,
        49.9932087,
        22.7242008,
        50.0868093
      ]
    },
    {
      "id": 14633,
      "name": "Falavarjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.5553,
        "lng": 51.50974
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.48974,
        32.5353,
        51.52974,
        32.5753
      ]
    },
    {
      "id": 14636,
      "name": "Karditsa",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.364637,
        "lng": 21.92032
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.902696,
        39.348626,
        21.939028,
        39.382134
      ]
    },
    {
      "id": 14637,
      "name": "Khalkhal",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.6189,
        "lng": 48.5258
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.5058,
        37.5989,
        48.5458,
        37.6389
      ]
    },
    {
      "id": 14639,
      "name": "Bautzen",
      "names": {
        "zh": "包岑"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1813907,
        "lng": 14.4275735
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.3240672,
        51.137272,
        14.4926393,
        51.2320745
      ]
    },
    {
      "id": 14640,
      "name": "Caibarien",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5243036,
        "lng": -79.4719441
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.742497,
        22.4030936,
        -78.9576351,
        22.9061394
      ]
    },
    {
      "id": 14641,
      "name": "Sassandra",
      "names": {
        "zh": "萨桑德拉"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 4.9490007,
        "lng": -6.0921667
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -6.1321667,
        4.9090007,
        -6.0521667,
        4.9890007
      ]
    },
    {
      "id": 14643,
      "name": "Siyang",
      "names": {
        "zh": "泗阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2144932,
        "lng": 108.7497431
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        108.7097431,
        27.1744932,
        108.7897431,
        27.2544932
      ]
    },
    {
      "id": 14652,
      "name": "Yanqi",
      "names": {
        "zh": "焉耆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.06204,
        "lng": 86.56632
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.54632,
        42.04204,
        86.58632,
        42.08204
      ]
    },
    {
      "id": 14656,
      "name": "Eregli",
      "names": {
        "zh": "埃雷利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5140718,
        "lng": 34.0473423
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.6809921,
        37.3620746,
        34.3800032,
        37.9963938
      ]
    },
    {
      "id": 14659,
      "name": "Venlo",
      "names": {
        "zh": "芬洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3702109,
        "lng": 6.1688954
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.0717807,
        51.3335723,
        6.2267694,
        51.4213181
      ]
    },
    {
      "id": 14679,
      "name": "Krasnyy Sulin",
      "names": {
        "de": "Krasny-Sulin"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.881481,
        "lng": 40.061078
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.015915,
        47.85701,
        40.104374,
        47.901943
      ]
    },
    {
      "id": 14703,
      "name": "Goes",
      "names": {
        "es": "Va",
        "fr": "Va",
        "zh": "去",
        "de": "Geht",
        "it": "Va",
        "id": "Pergi",
        "ja": "行く",
        "th": "ไป",
        "pt": "Vai"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4971093,
        "lng": 3.8828193
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.8778193,
        51.4921093,
        3.8878193,
        51.5021093
      ]
    },
    {
      "id": 14705,
      "name": "Sampues",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1845583,
        "lng": -75.3802368
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4670489,
        9.0671905,
        -75.2485267,
        9.247709
      ]
    },
    {
      "id": 14713,
      "name": "Yajalon",
      "names": {
        "es": "Yajalón"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1724317,
        "lng": -92.3332966
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.3732966,
        17.1324317,
        -92.2932966,
        17.2124317
      ]
    },
    {
      "id": 14714,
      "name": "Guira de Melena",
      "names": {
        "it": "Guiva de Melena"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7990976,
        "lng": -82.5070413
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.5120413,
        22.7940976,
        -82.5020413,
        22.8040976
      ]
    },
    {
      "id": 14718,
      "name": "Hajin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.6897222,
        "lng": 40.83
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        40.79,
        34.6497222,
        40.87,
        34.7297222
      ]
    },
    {
      "id": 14720,
      "name": "Andkhoy",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.95293,
        "lng": 65.12376
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        65.10376,
        36.93293,
        65.14376,
        36.97293
      ]
    },
    {
      "id": 14739,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 9.8694792,
        "lng": -83.7980749
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -83.8462579,
        9.8313543,
        -83.7664152,
        9.9078768
      ]
    },
    {
      "id": 14746,
      "name": "Spisska Nova Ves",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.9500868,
        "lng": 20.5638005
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.5552107,
        48.9500868,
        20.5638005,
        48.9508882
      ]
    },
    {
      "id": 14747,
      "name": "Dibulla",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.2716722,
        "lng": -73.3078999
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.664636,
        10.8352608,
        -73.1461514,
        11.3307091
      ]
    },
    {
      "id": 14749,
      "name": "Ma`arratmisrin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.01152,
        "lng": 36.67183
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14753,
      "name": "Al Qaryatayn",
      "names": {
        "fr": "Al QaryAyn",
        "th": "อัล Qaryatayn"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.22956,
        "lng": 37.24066
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.22066,
        34.20956,
        37.26066,
        34.24956
      ]
    },
    {
      "id": 14764,
      "name": "Aboisso",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 5.470526,
        "lng": -3.203634
      },
      "country": "Côte d'Ivoire",
      "importance": 2
    },
    {
      "id": 14768,
      "name": "Daiyue",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5258762,
        "lng": 112.8097934
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.6497934,
        39.3658762,
        112.9697934,
        39.6858762
      ]
    },
    {
      "id": 14769,
      "name": "Haugesund",
      "names": {
        "zh": "海于格松",
        "th": "เฮาเกสซุนด์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.413807,
        "lng": 5.2681249
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.1081249,
        59.253807,
        5.4281249,
        59.573807
      ]
    },
    {
      "id": 14770,
      "name": "Raalte",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3914177,
        "lng": 6.27823
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.27323,
        52.3864177,
        6.28323,
        52.3964177
      ]
    },
    {
      "id": 14781,
      "name": "Kalinkavichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1323,
        "lng": 29.3257
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.3057,
        52.1123,
        29.3457,
        52.1523
      ]
    },
    {
      "id": 14803,
      "name": "Progreso",
      "names": {
        "zh": "普罗格雷索"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2822136,
        "lng": -89.6636637
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.4000972,
        21.2640731,
        -89.6244971,
        22.528955
      ]
    },
    {
      "id": 14806,
      "name": "El Bordo",
      "names": {
        "ja": "エルボルド",
        "pt": "El bicodo"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1122852,
        "lng": -76.9862559
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.0262559,
        2.0722852,
        -76.9462559,
        2.1522852
      ]
    },
    {
      "id": 14813,
      "name": "Chrzanow",
      "names": {
        "zh": "赫扎努夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1332834,
        "lng": 19.3982012
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.3932012,
        50.1282834,
        19.4032012,
        50.1382834
      ]
    },
    {
      "id": 14814,
      "name": "Fermo",
      "names": {
        "zh": "费尔莫",
        "ja": "フェルモ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.160995,
        "lng": 13.717803
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.691126,
        43.151239,
        13.744775,
        43.174058
      ]
    },
    {
      "id": 14821,
      "name": "Nandaime",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.756423,
        "lng": -86.0530799
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.1324583,
        11.6021731,
        -85.8910911,
        11.823168
      ]
    },
    {
      "id": 14827,
      "name": "Santa Rosa de Osos",
      "names": {
        "zh": "圣罗莎de Osos",
        "id": "Santa Rosa de Oso",
        "ja": "サンタローザデオス",
        "th": "ซานตาโรซ่าเดอโอส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.64738,
        "lng": -75.4603
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4803,
        6.62738,
        -75.4403,
        6.66738
      ]
    },
    {
      "id": 14829,
      "name": "Molndal",
      "names": {
        "es": "Mölndal",
        "zh": "默恩达尔"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.6554,
        "lng": 12.01378
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        11.99378,
        57.6354,
        12.03378,
        57.6754
      ]
    },
    {
      "id": 14834,
      "name": "Warendorf",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9545428,
        "lng": 7.992093
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.992043,
        51.9544928,
        7.992143,
        51.9545928
      ]
    },
    {
      "id": 14840,
      "name": "Melgar",
      "names": {
        "zh": "梅尔加"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2039758,
        "lng": -74.6429479
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7521407,
        4.0956931,
        -74.5557547,
        4.2726053
      ]
    },
    {
      "id": 14841,
      "name": "Morondava",
      "names": {
        "zh": "莫鸿达瓦"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -20.2951102,
        "lng": 44.2786121
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        44.2386121,
        -20.3351102,
        44.3186121,
        -20.2551102
      ]
    },
    {
      "id": 14846,
      "name": "Lebork",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.53921,
        "lng": 17.75012
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.709749828,
        54.512132072,
        17.805626891,
        54.559037366
      ]
    },
    {
      "id": 14849,
      "name": "Masyaf",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.0653,
        "lng": 36.3406
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.3206,
        35.0453,
        36.3606,
        35.0853
      ]
    },
    {
      "id": 14855,
      "name": "Kizilyurt",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1999049,
        "lng": 46.8713343
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        46.837566,
        43.1840194,
        46.9115603,
        43.2188547
      ]
    },
    {
      "id": 14856,
      "name": "Tibu",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.63896,
        "lng": -72.73583
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.75583,
        8.61896,
        -72.71583,
        8.65896
      ]
    },
    {
      "id": 14860,
      "name": "Coatepec Harinas",
      "names": {
        "ja": "コアテピーハリナス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.925824,
        "lng": -99.767575
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.7765196118,
        18.9105766366,
        -99.7588668707,
        18.9355793241
      ]
    },
    {
      "id": 14876,
      "name": "Suhl",
      "names": {
        "zh": "苏尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6086518,
        "lng": 10.6926437
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5521537,
        50.5607522,
        10.8426214,
        50.6889455
      ]
    },
    {
      "id": 14878,
      "name": "Ciudad Manuel Doblado",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.728225,
        "lng": -101.952056
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.967478,
        20.715039,
        -101.933348,
        20.746716
      ]
    },
    {
      "id": 14882,
      "name": "Silves",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1848526,
        "lng": -8.4272449
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4272949,
        37.1848026,
        -8.4271949,
        37.1849026
      ]
    },
    {
      "id": 14893,
      "name": "Erding",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.30318715,
        "lng": 11.911509323257528
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.9110079,
        48.3027749,
        11.9119825,
        48.303578
      ]
    },
    {
      "id": 14922,
      "name": "San Miguel Chicaj",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1,
        "lng": -90.4
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.42,
        15.08,
        -90.38,
        15.12
      ]
    },
    {
      "id": 14923,
      "name": "Caimito",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9516398,
        "lng": -82.5937751
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.6843102,
        22.8076217,
        -82.5610587,
        23.0505637
      ]
    },
    {
      "id": 14936,
      "name": "Dagua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.658161,
        "lng": -76.6893973
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.7293973,
        3.618161,
        -76.6493973,
        3.698161
      ]
    },
    {
      "id": 14939,
      "name": "Chimboy Shahri",
      "names": {
        "ja": "キムボーイシャーリ"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.92952,
        "lng": 59.78199
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        59.76199,
        42.90952,
        59.80199,
        42.94952
      ]
    },
    {
      "id": 14945,
      "name": "Sayula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8821441,
        "lng": -103.5988944
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.6388944,
        19.8421441,
        -103.5588944,
        19.9221441
      ]
    },
    {
      "id": 14949,
      "name": "Gorinchem",
      "names": {
        "zh": "霍林赫姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8296133,
        "lng": 4.9738743
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.9216881,
        51.8188567,
        5.0314151,
        51.8636294
      ]
    },
    {
      "id": 14951,
      "name": "Torres Novas",
      "names": {
        "zh": "托雷斯诺瓦斯",
        "id": "Novas torres"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.47962,
        "lng": -8.53955
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6733224,
        39.3971376,
        -8.4507875,
        39.6321069
      ]
    },
    {
      "id": 14952,
      "name": "Qaladizay",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.1811,
        "lng": 45.1286
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 14961,
      "name": "Jaral del Progreso",
      "names": {
        "pt": "Jaral del progresso"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.371514,
        "lng": -101.066767
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.084743,
        20.358537,
        -101.053485,
        20.382933
      ]
    },
    {
      "id": 14962,
      "name": "`Afrin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.51346865,
        "lng": 36.86973327793315
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.8256697,
        36.4960026,
        36.8913248,
        36.5309865
      ]
    },
    {
      "id": 14964,
      "name": "La Macarena",
      "names": {
        "zh": "洛马纳",
        "it": "Lacarena"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1833828,
        "lng": -73.7857879
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7334483,
        1.6012501,
        -73.4419859,
        2.6398889
      ]
    },
    {
      "id": 14972,
      "name": "Porsgrunn",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.140759,
        "lng": 9.6562495
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.4962495,
        58.980759,
        9.8162495,
        59.300759
      ]
    },
    {
      "id": 14975,
      "name": "Esquel",
      "names": {
        "zh": "埃斯克尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -42.900031,
        "lng": -71.316614
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -71.3366136085,
        -42.9200313123,
        -71.2966136085,
        -42.8800313123
      ]
    },
    {
      "id": 14976,
      "name": "Cantanhede",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3461879,
        "lng": -8.5940128
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8463265,
        40.2452987,
        -8.4809921,
        40.4855828
      ]
    },
    {
      "id": 14982,
      "name": "Belalcazar",
      "names": {
        "zh": "贝拉尔卡萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6545029,
        "lng": -75.9512429
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9686918,
        2.6441473,
        -75.9324551,
        2.6604948
      ]
    },
    {
      "id": 14983,
      "name": "Neiba",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.48138,
        "lng": -71.41965
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -71.43965,
        18.46138,
        -71.39965,
        18.50138
      ]
    },
    {
      "id": 14987,
      "name": "Montlucon",
      "names": {
        "es": "Montluçon",
        "fr": "Montluçon",
        "zh": "蒙吕松",
        "it": "Montluçon"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3400296,
        "lng": 2.6073963
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.5700394,
        46.3131334,
        2.6454923,
        46.3724373
      ]
    },
    {
      "id": 14988,
      "name": "Santa Cruz Verapaz",
      "names": {
        "ja": "サンタクルスベラパズ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3742073,
        "lng": -90.4294643
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.4694643,
        15.3342073,
        -90.3894643,
        15.4142073
      ]
    },
    {
      "id": 14989,
      "name": "Muhlhausen",
      "names": {
        "es": "Mühlhausen",
        "de": "Mühlhausen",
        "id": "Mühlhausen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2094255,
        "lng": 10.4589044
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2992513,
        51.1455265,
        10.5739465,
        51.3034737
      ]
    },
    {
      "id": 14990,
      "name": "Haomen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.37575,
        "lng": 101.62199
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.60199,
        37.35575,
        101.64199,
        37.39575
      ]
    },
    {
      "id": 14993,
      "name": "Tocancipa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.96531,
        "lng": -73.91301
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.93301,
        4.94531,
        -73.89301,
        4.98531
      ]
    },
    {
      "id": 14994,
      "name": "Nuoro",
      "names": {
        "zh": "努奥罗"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.3210601,
        "lng": 9.3297339
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.183022,
        40.2538807,
        9.4260809,
        40.4110051
      ]
    },
    {
      "id": 15013,
      "name": "Nueve de Julio",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.444476550000005,
        "lng": -60.88417256938149
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.9028061,
        -35.4594352,
        -60.8655734,
        -35.4295536
      ]
    },
    {
      "id": 15019,
      "name": "Coesfeld",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9458943,
        "lng": 7.1691108
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0557333,
        51.8462349,
        7.2611726,
        51.9879097
      ]
    },
    {
      "id": 15020,
      "name": "Sonson",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7106659,
        "lng": -75.3103532
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3503532,
        5.6706659,
        -75.2703532,
        5.7506659
      ]
    },
    {
      "id": 15022,
      "name": "Castricum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5453379,
        "lng": 4.6591148
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6541148,
        52.5403379,
        4.6641148,
        52.5503379
      ]
    },
    {
      "id": 15030,
      "name": "Jaslo",
      "names": {
        "zh": "亚斯沃"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.7374629,
        "lng": 21.4703978
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.4653978,
        49.7324629,
        21.4753978,
        49.7424629
      ]
    },
    {
      "id": 15035,
      "name": "Kerava",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4034398,
        "lng": 25.1041013
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.0728805,
        60.3590779,
        25.1635911,
        60.4429902
      ]
    },
    {
      "id": 15045,
      "name": "San Pablo",
      "names": {
        "zh": "圣巴勃罗",
        "ja": "サンパブロ",
        "th": "ซานปาโบล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -7.0918959,
        "lng": -78.740251
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -78.919543,
        -7.228789,
        -78.5986066,
        -6.8516116
      ]
    },
    {
      "id": 15053,
      "name": "San Bernardo del Viento",
      "names": {
        "ja": "サンベルナルドデルビエンヨ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.353219,
        "lng": -75.9536761
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9936761,
        9.313219,
        -75.9136761,
        9.393219
      ]
    },
    {
      "id": 15065,
      "name": "Smarhon'",
      "names": {
        "es": "Smarhon '",
        "fr": "Smarhon '",
        "de": "Smarhon '",
        "it": "Smarhon '",
        "id": "Smarhon '",
        "ja": "smarhon '",
        "th": "smarhon '",
        "pt": "Smarhon '"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4817816,
        "lng": 26.4012822
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.3456272,
        54.4532999,
        26.4422641,
        54.5441836
      ]
    },
    {
      "id": 15066,
      "name": "Zhmerynka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0345312,
        "lng": 28.1115461
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.1065461,
        49.0295312,
        28.1165461,
        49.0395312
      ]
    },
    {
      "id": 15072,
      "name": "Jose Maria Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7488925,
        "lng": -88.709813
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -88.7237142,
        19.7339778,
        -88.6952484,
        19.7662221
      ]
    },
    {
      "id": 15074,
      "name": "Zacatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.65059575,
        "lng": -99.20307754840212
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2413292,
        18.6153579,
        -99.1769984,
        18.6855961
      ]
    },
    {
      "id": 15083,
      "name": "Tuttlingen",
      "names": {
        "zh": "图特林根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.9844315,
        "lng": 8.8186606
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6920779,
        47.9262364,
        8.9087753,
        48.0322457
      ]
    },
    {
      "id": 15089,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8962195,
        "lng": 121.2931059
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.2396708,
        13.8628519,
        121.3743262,
        13.943418
      ]
    },
    {
      "id": 15098,
      "name": "Turgutlu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5000458,
        "lng": 27.7084176
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.6130146,
        38.3053031,
        27.9141022,
        38.641009
      ]
    },
    {
      "id": 15100,
      "name": "Ramsar",
      "names": {
        "fr": "La convention de Ramsar",
        "zh": "拉姆萨尔"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.9225518,
        "lng": 50.6431897
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.6329038,
        36.8886661,
        50.6951238,
        36.9525199
      ]
    },
    {
      "id": 15108,
      "name": "Limburg",
      "names": {
        "es": "Limburgo",
        "fr": "Limbourg",
        "zh": "林堡",
        "ja": "リンブルフ",
        "th": "ลิมเบอร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3844765,
        "lng": 8.0621441
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.0571441,
        50.3794765,
        8.0671441,
        50.3894765
      ]
    },
    {
      "id": 15109,
      "name": "Rongwo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5190124,
        "lng": 102.0169959
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.9769959,
        35.4790124,
        102.0569959,
        35.5590124
      ]
    },
    {
      "id": 15119,
      "name": "Chortoq",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.0714194,
        "lng": 71.8189789
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        71.7831032,
        41.0444555,
        71.8668739,
        41.1066997
      ]
    },
    {
      "id": 15123,
      "name": "Praya",
      "names": {
        "th": "พระยา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.7157359,
        "lng": 116.2706282
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        116.2306282,
        -8.7557359,
        116.3106282,
        -8.6757359
      ]
    },
    {
      "id": 15127,
      "name": "Belluno",
      "names": {
        "zh": "贝卢诺",
        "ja": "ベッルーノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.1375185,
        "lng": 12.2181711
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        12.1183605,
        46.0362704,
        12.3067003,
        46.2335995
      ]
    },
    {
      "id": 15130,
      "name": "Kakhovka",
      "names": {
        "zh": "卡霍夫卡"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.800318,
        "lng": 33.475379
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.455055,
        46.779455,
        33.492413,
        46.816911
      ]
    },
    {
      "id": 15132,
      "name": "Chernyakhovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.635533,
        "lng": 21.8099822
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        21.7043279,
        54.5784372,
        21.9019308,
        54.6704952
      ]
    },
    {
      "id": 15139,
      "name": "Kumo",
      "names": {
        "zh": "云"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 61.2513342,
        "lng": 22.3492421
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        22.1140878,
        61.170631,
        22.6099554,
        61.4889232
      ]
    },
    {
      "id": 15140,
      "name": "Dayr Hafir",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.15694,
        "lng": 37.70778
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.68778,
        36.13694,
        37.72778,
        36.17694
      ]
    },
    {
      "id": 15141,
      "name": "Sambir",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5162839,
        "lng": 23.1991999
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.1991499,
        49.5162339,
        23.1992499,
        49.5163339
      ]
    },
    {
      "id": 15143,
      "name": "Mengdong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1503138,
        "lng": 99.2445157
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.2045157,
        23.1103138,
        99.2845157,
        23.1903138
      ]
    },
    {
      "id": 15144,
      "name": "Tallkalakh",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.66842,
        "lng": 36.25995
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 15147,
      "name": "Karak",
      "names": {
        "zh": "加叻"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.1160464,
        "lng": 71.0950863
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        70.9350863,
        32.9560464,
        71.2550863,
        33.2760464
      ]
    },
    {
      "id": 15150,
      "name": "Ingelheim",
      "names": {
        "zh": "殷格翰"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.972939,
        "lng": 8.055959
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.976378,
        49.92961,
        8.137503,
        50.009159
      ]
    },
    {
      "id": 15153,
      "name": "Guacari",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7630235,
        "lng": -76.3344854
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.4010044,
        3.7073095,
        -76.1777455,
        3.8479482
      ]
    },
    {
      "id": 15155,
      "name": "Pak Chong",
      "names": {
        "es": "Chong de pak",
        "ja": "パックチョン",
        "th": "ปากช่อง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6976699,
        "lng": 101.4072813
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.4072313,
        14.6976199,
        101.4073313,
        14.6977199
      ]
    },
    {
      "id": 15165,
      "name": "Teruel",
      "names": {
        "zh": "特鲁埃尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.341653,
        "lng": -1.106535
      },
      "country": "Spain",
      "importance": 2,
      "bbox": [
        -1.121455,
        40.319144,
        -1.080405,
        40.3613
      ]
    },
    {
      "id": 15166,
      "name": "Al Hisn",
      "names": {
        "de": "Al-Hisn",
        "th": "อัลเขา"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 24.48250165,
        "lng": 54.35084717450985
      },
      "country": "Jordan",
      "importance": 2,
      "bbox": [
        54.3419332,
        24.4714723,
        54.3620473,
        24.4938576
      ]
    },
    {
      "id": 15168,
      "name": "Santiago de Tolu",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.5241138,
        "lng": -75.5841794
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6185338,
        9.3938604,
        -75.4426905,
        9.6669594
      ]
    },
    {
      "id": 15178,
      "name": "Cieszyn",
      "names": {
        "zh": "切申"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.7488623,
        "lng": 18.6334308
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.5970661,
        49.7185972,
        18.7058145,
        49.7929643
      ]
    },
    {
      "id": 15196,
      "name": "Bougouni",
      "names": {
        "zh": "布古尼"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 11.4194313,
        "lng": -7.4833878
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -7.6433878,
        11.2594313,
        -7.3233878,
        11.5794313
      ]
    },
    {
      "id": 15197,
      "name": "Kuala Kapuas",
      "names": {
        "zh": "吉隆卡瓦斯",
        "ja": "クアラカプアス",
        "th": "กัวลากะพภา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.022067,
        "lng": 114.388472
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        114.3883167,
        -3.0221655,
        114.3886279,
        -3.0219692
      ]
    },
    {
      "id": 15198,
      "name": "Uster",
      "names": {
        "zh": "乌斯特"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3508125,
        "lng": 8.7179334
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.7129334,
        47.3458125,
        8.7229334,
        47.3558125
      ]
    },
    {
      "id": 15203,
      "name": "San Sebastian de Yali",
      "names": {
        "es": "San Sebastián de Yali",
        "zh": "圣塞巴斯蒂安德亚利",
        "th": "ซานเซบาสเตียนเดอยูลี"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.304715,
        "lng": -86.18027316618168
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.1812646,
        13.3038758,
        -86.1797673,
        13.3055019
      ]
    },
    {
      "id": 15206,
      "name": "Cha-am",
      "names": {
        "zh": "茶上午",
        "ja": "チャアム",
        "th": "ชะอำ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.7998336,
        "lng": 99.9662102
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.9612102,
        12.7948336,
        99.9712102,
        12.8048336
      ]
    },
    {
      "id": 15212,
      "name": "Coevorden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6609569,
        "lng": 6.7597785
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.7597285,
        52.6609069,
        6.7598285,
        52.6610069
      ]
    },
    {
      "id": 15216,
      "name": "Gigante",
      "names": {
        "zh": "希甘特",
        "ja": "ヒガンテ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.3881745,
        "lng": -75.5468663
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5510259,
        2.383225,
        -75.5366574,
        2.39089
      ]
    },
    {
      "id": 15218,
      "name": "Krasnik",
      "names": {
        "zh": "克拉斯尼克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9249236,
        "lng": 22.229206
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.1368097,
        50.910611,
        22.2901486,
        50.9762868
      ]
    },
    {
      "id": 15224,
      "name": "Meppen",
      "names": {
        "zh": "梅彭"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6907616,
        "lng": 7.2919908
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.2919408,
        52.6907116,
        7.2920408,
        52.6908116
      ]
    },
    {
      "id": 15225,
      "name": "Zorgo",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.250684,
        "lng": -0.617139
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 15228,
      "name": "Quimbaya",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.62306,
        "lng": -75.76278
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.78278,
        4.60306,
        -75.74278,
        4.64306
      ]
    },
    {
      "id": 15230,
      "name": "Ikot Abasi",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 4.56851,
        "lng": 7.55262
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        7.53262,
        4.54851,
        7.57262,
        4.58851
      ]
    },
    {
      "id": 15233,
      "name": "Savonlinna",
      "names": {
        "zh": "萨翁林纳"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8689933,
        "lng": 28.8797178
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        28.370193,
        61.533701,
        29.6968164,
        62.4177573
      ]
    },
    {
      "id": 15246,
      "name": "Cegled",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1716447,
        "lng": 19.7977516
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.6623205,
        47.1217095,
        19.9465528,
        47.2830861
      ]
    },
    {
      "id": 15248,
      "name": "Cartagena del Chaira",
      "names": {
        "es": "Cartagena del Presa",
        "fr": "Carthagène du président au président",
        "zh": "Cartagena del主席",
        "de": "Cartagena del Cotting",
        "it": "Cartagena del Senda",
        "id": "Cartagena del camar",
        "ja": "カルタヘナデルレジャー",
        "pt": "Cartagena del Preso"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3345179,
        "lng": -74.8414067
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0814051,
        0.0262759,
        -73.1384438,
        1.5690197
      ]
    },
    {
      "id": 15253,
      "name": "San Miguel Ixtahuacan",
      "names": {
        "zh": "圣米格尔·伊斯塔豪萨州"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2720559,
        "lng": -91.7485981
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.8354726,
        15.1861094,
        -91.6294965,
        15.3482581
      ]
    },
    {
      "id": 15257,
      "name": "Auxerre",
      "names": {
        "zh": "欧塞尔",
        "th": "โอแซร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.7961287,
        "lng": 3.570579
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.5261079,
        47.7348564,
        3.6380173,
        47.852381
      ]
    },
    {
      "id": 15258,
      "name": "Leer",
      "names": {
        "zh": "莱尔",
        "ja": "うすら笑い",
        "th": "มองค้อน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.2332168,
        "lng": 7.4567555
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.3668948,
        53.2029408,
        7.5431513,
        53.29229
      ]
    },
    {
      "id": 15267,
      "name": "Cloppenburg",
      "names": {
        "zh": "克洛彭堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8461339,
        "lng": 8.0438783
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.9642823,
        52.7958853,
        8.1032214,
        52.9232308
      ]
    },
    {
      "id": 15268,
      "name": "Millerovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.9196264,
        "lng": 40.391854
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.3112584,
        48.8810635,
        40.4526901,
        48.9710978
      ]
    },
    {
      "id": 15270,
      "name": "La Palma",
      "names": {
        "zh": "拉帕尔马",
        "ja": "ラパルマ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 5.3606208,
        "lng": -74.3905123
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -74.4925311,
        5.252151,
        -74.3356829,
        5.4439481
      ]
    },
    {
      "id": 15277,
      "name": "Qadsayya",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.54832,
        "lng": 36.21563
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.19563,
        33.52832,
        36.23563,
        33.56832
      ]
    },
    {
      "id": 15278,
      "name": "Saarlouis",
      "names": {
        "zh": "萨尔路易斯",
        "ja": "ザールルイ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3164661,
        "lng": 6.749846
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.6751543,
        49.26028,
        6.815681,
        49.36206
      ]
    },
    {
      "id": 15292,
      "name": "Yarim",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.29804,
        "lng": 44.37795
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.35795,
        14.27804,
        44.39795,
        14.31804
      ]
    },
    {
      "id": 15297,
      "name": "Tall Salhab",
      "names": {
        "es": "Salón alto",
        "fr": "Grand salhab",
        "zh": "高大的沙拉",
        "de": "Hoher Salhen",
        "it": "Salhab alto",
        "id": "Salhab tinggi",
        "ja": "背の高いサラブ",
        "th": "ซัลฮาบสูง",
        "pt": "Salcalha alta"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.25884,
        "lng": 36.38461
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.36461,
        35.23884,
        36.40461,
        35.27884
      ]
    },
    {
      "id": 15301,
      "name": "Voznesensk",
      "names": {
        "zh": "沃兹涅先斯克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.5679582,
        "lng": 31.3338636
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.2518227,
        47.5413329,
        31.3732881,
        47.6296008
      ]
    },
    {
      "id": 15302,
      "name": "Fonseca",
      "names": {
        "zh": "丰塞卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.8861805,
        "lng": -72.8380064
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.8780064,
        10.8461805,
        -72.7980064,
        10.9261805
      ]
    },
    {
      "id": 15305,
      "name": "Gorizia",
      "names": {
        "zh": "戈里齐亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.938071,
        "lng": 13.612028
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        13.584985,
        45.903476,
        13.632196,
        45.959887
      ]
    },
    {
      "id": 15310,
      "name": "Ieper",
      "names": {
        "es": "Ypres",
        "fr": "Ypres",
        "zh": "伊珀尔"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8521947,
        "lng": 2.8846047
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        2.7690581,
        50.7936084,
        2.9764987,
        50.9387074
      ]
    },
    {
      "id": 15314,
      "name": "Winsen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3636474,
        "lng": 10.2059206
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.0911121,
        53.2867397,
        10.3271242,
        53.4154401
      ]
    },
    {
      "id": 15319,
      "name": "Umarkot",
      "names": {
        "zh": "欧迈尔果德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.3655302,
        "lng": 69.7401257
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        69.5801257,
        25.2055302,
        69.9001257,
        25.5255302
      ]
    },
    {
      "id": 15320,
      "name": "Nevers",
      "names": {
        "zh": "讷韦尔",
        "th": "เนเวิร์ส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9897067,
        "lng": 3.158358
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.158308,
        46.9896567,
        3.158408,
        46.9897567
      ]
    },
    {
      "id": 15332,
      "name": "San Vicente de Chucuri",
      "names": {
        "ja": "サンビセンテデチュクリー"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.881,
        "lng": -73.40977
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.42977,
        6.861,
        -73.38977,
        6.901
      ]
    },
    {
      "id": 15336,
      "name": "Champerico",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.293651,
        "lng": -91.911813
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.0490642,
        14.22707,
        -91.7767107,
        14.4361835
      ]
    },
    {
      "id": 15337,
      "name": "Huehuetan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.01924,
        "lng": -92.385369
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.402938,
        15.001738,
        -92.371837,
        15.026626
      ]
    },
    {
      "id": 15339,
      "name": "Wavre",
      "names": {
        "zh": "瓦夫尔"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7161154,
        "lng": 4.6053217
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.6003217,
        50.7111154,
        4.6103217,
        50.7211154
      ]
    },
    {
      "id": 15342,
      "name": "San Luis de Since",
      "names": {
        "es": "San Luis de desde entonces",
        "fr": "San Luis de depuis",
        "zh": "San Luis de",
        "de": "San Luis de seitdem",
        "it": "San Luis de",
        "id": "San Luis De sejak itu",
        "ja": "それ以来サンルイスデ",
        "th": "ซานหลุยส์เดอตั้งแต่",
        "pt": "San Luis de desde então"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.24391,
        "lng": -75.14675
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15345,
      "name": "Puerto Lopez",
      "names": {
        "es": "Puerto lópez",
        "ja": "プエルトロペス",
        "th": "เปอร์โตโลเปซ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.0891674,
        "lng": -72.96059024775572
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.9747437,
        4.0781555,
        -72.9458392,
        4.1003666
      ]
    },
    {
      "id": 15349,
      "name": "Bochil",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9955388,
        "lng": -92.8935374
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.0941161,
        16.9040458,
        -92.8138565,
        17.1412982
      ]
    },
    {
      "id": 15350,
      "name": "Casilda",
      "names": {
        "zh": "卡西尔达"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0443368,
        "lng": -61.1641779
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.2144437,
        -33.0781448,
        -61.1180177,
        -33.0290096
      ]
    },
    {
      "id": 15354,
      "name": "Macon",
      "names": {
        "zh": "梅肯",
        "ja": "メーコン",
        "th": "แมคอน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3036683,
        "lng": 4.8322266
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.7579669,
        46.2735289,
        4.8586299,
        46.3795653
      ]
    },
    {
      "id": 15355,
      "name": "Samandag",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.0851597,
        "lng": 35.9799375
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.8200523,
        36.0098,
        36.090931,
        36.3110552
      ]
    },
    {
      "id": 15358,
      "name": "Campoalegre",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6851032,
        "lng": -75.3263227
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4389151,
        2.5102724,
        -75.1701394,
        2.7810898
      ]
    },
    {
      "id": 15360,
      "name": "Baja",
      "names": {
        "zh": "下加",
        "ja": "バハ",
        "th": "รองเท้าบาจา"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.1785471,
        "lng": 18.9517255
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.8220751,
        46.1254222,
        19.1400779,
        46.2407577
      ]
    },
    {
      "id": 15361,
      "name": "Haguenau",
      "names": {
        "zh": "阿格诺"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8172236,
        "lng": 7.7885978
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.6336988,
        48.7741739,
        8.0087394,
        48.903364
      ]
    },
    {
      "id": 15379,
      "name": "Balingen",
      "names": {
        "zh": "巴林根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2737512,
        "lng": 8.8557862
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.7679069,
        48.2136696,
        8.9472737,
        48.3264811
      ]
    },
    {
      "id": 15390,
      "name": "Ilawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.5976095,
        "lng": 19.5612175
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.5081134,
        53.5636959,
        19.6106905,
        53.6283055
      ]
    },
    {
      "id": 15393,
      "name": "Mosonmagyarovar",
      "names": {
        "zh": "莫雄马扎尔古堡"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8731793,
        "lng": 17.2722342
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.2014076,
        47.7966073,
        17.3783148,
        47.9424806
      ]
    },
    {
      "id": 15405,
      "name": "Dzierzoniow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7281896,
        "lng": 16.6514455
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.6044547,
        50.7002255,
        16.6901662,
        50.7581322
      ]
    },
    {
      "id": 15417,
      "name": "Phra Phutthabat",
      "names": {
        "it": "Phrra Phutthabat",
        "th": "พระพุทธบาท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.7249566,
        "lng": 100.792102
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.752102,
        14.6849566,
        100.832102,
        14.7649566
      ]
    },
    {
      "id": 15420,
      "name": "Sarpol-e Zahab",
      "names": {
        "ja": "サルポル= Zahab"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.4607687,
        "lng": 45.863763
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.823763,
        34.4207687,
        45.903763,
        34.5007687
      ]
    },
    {
      "id": 15423,
      "name": "Buenos Aires",
      "names": {
        "zh": "布宜诺斯艾利斯",
        "ja": "ブエノスアイレス",
        "th": "บัวโนสไอเรส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -34.6073387,
        "lng": -58.4432852
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -58.5314494,
        -34.705637,
        -58.3351423,
        -34.5265535
      ]
    },
    {
      "id": 15428,
      "name": "Merseburg",
      "names": {
        "zh": "梅泽堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3568506,
        "lng": 11.9904345
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.9854345,
        51.3518506,
        11.9954345,
        51.3618506
      ]
    },
    {
      "id": 15430,
      "name": "Zweibrucken",
      "names": {
        "fr": "Zweibrücken",
        "de": "Zweibrücken",
        "pt": "Zweibrücken"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.248655,
        "lng": 7.3641981
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.288695,
        49.1899493,
        7.4486507,
        49.3169125
      ]
    },
    {
      "id": 15431,
      "name": "IJsselstein",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0246381,
        "lng": 5.0458792
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.9783792,
        51.9912331,
        5.0777176,
        52.0611429
      ]
    },
    {
      "id": 15436,
      "name": "Bugojno",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.05722,
        "lng": 17.45083
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.43083,
        44.03722,
        17.47083,
        44.07722
      ]
    },
    {
      "id": 15442,
      "name": "Esposende",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5333306,
        "lng": -8.780710679483596
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7892851,
        41.5245472,
        -8.7690836,
        41.5419225
      ]
    },
    {
      "id": 15443,
      "name": "Chacabuco",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6423582,
        "lng": -60.4708815
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.530612,
        -34.6844584,
        -60.4199387,
        -34.5849963
      ]
    },
    {
      "id": 15444,
      "name": "Humenne",
      "names": {
        "it": "Humenné"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.9300234,
        "lng": 21.9008924
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.8958924,
        48.9250234,
        21.9058924,
        48.9350234
      ]
    },
    {
      "id": 15449,
      "name": "Ocuilan de Arteaga",
      "names": {
        "id": "Ocuilan de artega"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9791348,
        "lng": -99.4175833
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.4575833,
        18.9391348,
        -99.3775833,
        19.0191348
      ]
    },
    {
      "id": 15451,
      "name": "Sabaneta",
      "names": {
        "zh": "萨瓦内塔"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 10.1817238,
        "lng": -66.9640517
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -67.0040517,
        10.1417238,
        -66.9240517,
        10.2217238
      ]
    },
    {
      "id": 15463,
      "name": "Baft",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.2331,
        "lng": 56.6022
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        56.5822,
        29.2131,
        56.6222,
        29.2531
      ]
    },
    {
      "id": 15468,
      "name": "Banes",
      "names": {
        "zh": "巴内斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.9617915,
        "lng": -75.722894
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -75.9159434,
        20.7516099,
        -75.5732412,
        21.1344172
      ]
    },
    {
      "id": 15469,
      "name": "Gerash",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 27.6652,
        "lng": 54.1371
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        54.1171,
        27.6452,
        54.1571,
        27.6852
      ]
    },
    {
      "id": 15482,
      "name": "Merauke",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.4902766,
        "lng": 140.395582
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        140.235582,
        -8.6502766,
        140.555582,
        -8.3302766
      ]
    },
    {
      "id": 15492,
      "name": "Steinfurt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.138683,
        "lng": 7.3678356
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.2609328,
        52.0811251,
        7.4556055,
        52.1945632
      ]
    },
    {
      "id": 15496,
      "name": "Santa Ana Nextlalpan",
      "names": {
        "es": "Santa Ana Newlalpan",
        "pt": "Santa Ana Nextlpan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7116403,
        "lng": -99.0704669
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.0743762,
        19.7082394,
        -99.0658387,
        19.7157592
      ]
    },
    {
      "id": 15498,
      "name": "Vibo Valentia",
      "names": {
        "ja": "バイブバレンティア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.6748544,
        "lng": 16.0985276
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        16.0308211,
        38.633929,
        16.1538647,
        38.7246269
      ]
    },
    {
      "id": 15502,
      "name": "Qingyang",
      "names": {
        "zh": "庆阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.182368,
        "lng": 107.6216357
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.3571155,
        35.24203,
        108.7105309,
        37.1580849
      ]
    },
    {
      "id": 15508,
      "name": "Olawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.934351,
        "lng": 17.303287
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.245943,
        50.919127,
        17.342461,
        50.995776
      ]
    },
    {
      "id": 15514,
      "name": "Gulkevichi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.3565119,
        "lng": 40.6962256
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.6454464,
        45.3402215,
        40.7401785,
        45.3817734
      ]
    },
    {
      "id": 15515,
      "name": "Cambrai",
      "names": {
        "zh": "康布雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.1757546,
        "lng": 3.2346145
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.201464,
        50.1410069,
        3.2808727,
        50.2036584
      ]
    },
    {
      "id": 15521,
      "name": "Caldono",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.796326,
        "lng": -76.4836526
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6305616,
        2.704404,
        -76.3611133,
        2.9260616
      ]
    },
    {
      "id": 15525,
      "name": "Shahin Dezh",
      "names": {
        "zh": "沙林德扎"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6793,
        "lng": 46.5669
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        46.5469,
        36.6593,
        46.5869,
        36.6993
      ]
    },
    {
      "id": 15528,
      "name": "Quinchia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.33957,
        "lng": -75.73018
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.75018,
        5.31957,
        -75.71018,
        5.35957
      ]
    },
    {
      "id": 15541,
      "name": "Peyziwat",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.49554,
        "lng": 76.74282
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        76.72282,
        39.47554,
        76.76282,
        39.51554
      ]
    },
    {
      "id": 15544,
      "name": "Nowy Targ",
      "names": {
        "id": "TARG SEGERA",
        "ja": "今日のターク",
        "th": "ตอนนี้ targ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.4818229,
        "lng": 20.030541
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.9825087,
        49.4440379,
        20.1034276,
        49.5344802
      ]
    },
    {
      "id": 15551,
      "name": "Meppel",
      "names": {
        "zh": "梅珀尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.697249,
        "lng": 6.195237
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.156495,
        52.675063,
        6.244376,
        52.7483
      ]
    },
    {
      "id": 15561,
      "name": "San Agustin",
      "names": {
        "es": "San Agustín",
        "zh": "圣阿古斯丁",
        "th": "ซานอาคัลติน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -21.15467155,
        "lng": -67.6801082650128
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -67.6817409,
        -21.158801,
        -67.6769016,
        -21.1503232
      ]
    },
    {
      "id": 15576,
      "name": "Lianga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6330237,
        "lng": 126.0934758
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        126.0534758,
        8.5930237,
        126.1334758,
        8.6730237
      ]
    },
    {
      "id": 15577,
      "name": "San Antonio de los Banos",
      "names": {
        "ja": "サンアントニオデロスバノス"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.89111,
        "lng": -82.49917
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.49917,
        22.89111,
        -82.49917,
        22.89111
      ]
    },
    {
      "id": 15579,
      "name": "Reo",
      "names": {
        "zh": "选举事务处"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.3186862,
        "lng": -2.4696282
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -2.5096282,
        12.2786862,
        -2.4296282,
        12.3586862
      ]
    },
    {
      "id": 15583,
      "name": "San Juan Nepomuceno",
      "names": {
        "zh": "圣胡安Nepomuceno",
        "ja": "サンファンネポムチェノ",
        "th": "ซานฮวนเนปึมิโซโน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9512143,
        "lng": -75.0794539
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2549472,
        9.8556302,
        -74.861093,
        10.1245173
      ]
    },
    {
      "id": 15586,
      "name": "Majagual",
      "names": {
        "zh": "马哈瓜尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5416242,
        "lng": -74.6284175
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7884175,
        8.3816242,
        -74.4684175,
        8.7016242
      ]
    },
    {
      "id": 15588,
      "name": "Sneek",
      "names": {
        "zh": "斯内克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.033548,
        "lng": 5.6611029
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6146842,
        53.0035627,
        5.7246007,
        53.0546835
      ]
    },
    {
      "id": 15593,
      "name": "Deggendorf",
      "names": {
        "ja": "デッゲンドルフ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8333535,
        "lng": 12.96205
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.873559,
        48.7868595,
        13.0741545,
        48.9086147
      ]
    },
    {
      "id": 15599,
      "name": "Baltiysk",
      "names": {
        "zh": "波罗的斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6472092,
        "lng": 19.9110754
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        19.9110254,
        54.6471592,
        19.9111254,
        54.6472592
      ]
    },
    {
      "id": 15605,
      "name": "Biberach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0984413,
        "lng": 9.7899938
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.671518,
        48.0472189,
        9.9062784,
        48.1302278
      ]
    },
    {
      "id": 15631,
      "name": "Uelzen",
      "names": {
        "zh": "于尔岑"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.9666845,
        "lng": 10.5584863
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.4662676,
        52.8791557,
        10.6970508,
        53.0413613
      ]
    },
    {
      "id": 15632,
      "name": "Tekes",
      "names": {
        "zh": "特克斯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.216904,
        "lng": 81.833002
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        81.673002,
        43.056904,
        81.993002,
        43.376904
      ]
    },
    {
      "id": 15634,
      "name": "Bell Ville",
      "names": {
        "es": "Ville",
        "zh": "贝尔维尔",
        "it": "Campana ville",
        "id": "Lonceng",
        "ja": "ベルビル",
        "th": "เบลล์วิลล์",
        "pt": "Villa"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.6300707,
        "lng": -62.6888782
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.7332727,
        -32.6691236,
        -62.6261473,
        -32.568239
      ]
    },
    {
      "id": 15644,
      "name": "Leo",
      "names": {
        "zh": "狮子座",
        "ja": "レオ",
        "th": "ราศีสิงห์"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.0971341,
        "lng": -2.1047639
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -2.1447639,
        11.0571341,
        -2.0647639,
        11.1371341
      ]
    },
    {
      "id": 15652,
      "name": "Jishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.8469301,
        "lng": 102.4791136
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.3191136,
        35.6869301,
        102.6391136,
        36.0069301
      ]
    },
    {
      "id": 15654,
      "name": "Lochem",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1583881,
        "lng": 6.4138498
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.4137998,
        52.1583381,
        6.4138998,
        52.1584381
      ]
    },
    {
      "id": 15656,
      "name": "Todos Santos Cuchumatan",
      "names": {
        "es": "Todos los santos cuchumatan",
        "pt": "Todos Santos Cucumatan"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.5092795,
        "lng": -91.6038654
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.6438654,
        15.4692795,
        -91.5638654,
        15.5492795
      ]
    },
    {
      "id": 15657,
      "name": "Chilecito",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -29.165001,
        "lng": -67.4953596
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -67.5425603,
        -29.1879003,
        -67.4830806,
        -29.1351681
      ]
    },
    {
      "id": 15664,
      "name": "Puerto Rico",
      "names": {
        "es": "Puertón rico",
        "fr": "Porto Rico",
        "zh": "波多黎各",
        "it": "Porto Rico",
        "ja": "プエルトリコ",
        "th": "เปอร์โตริโก",
        "pt": "Porto Rico"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 18.228855,
        "lng": -66.476673
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -67.490673,
        17.881229,
        -65.221027,
        18.516091
      ]
    },
    {
      "id": 15671,
      "name": "San Antero",
      "names": {
        "zh": "圣黑子",
        "ja": "サンアノテオ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.373016,
        "lng": -75.7595056
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9195056,
        9.213016,
        -75.5995056,
        9.533016
      ]
    },
    {
      "id": 15672,
      "name": "Cantel",
      "names": {
        "es": "Partito",
        "id": "Potongan",
        "pt": "Cantil"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.81667,
        "lng": -91.45
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.47,
        14.79667,
        -91.43,
        14.83667
      ]
    },
    {
      "id": 15680,
      "name": "Corinto",
      "names": {
        "zh": "科林托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.1439306499999997,
        "lng": -76.20024889711436
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3335377,
        3.0597272,
        -76.0724259,
        3.2288421
      ]
    },
    {
      "id": 15692,
      "name": "Ciudad Tecun Uman",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6778855,
        "lng": -92.1405697
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.1805697,
        14.6378855,
        -92.1005697,
        14.7178855
      ]
    },
    {
      "id": 15695,
      "name": "Castro",
      "names": {
        "zh": "卡斯特罗",
        "ja": "カストロ",
        "th": "คาสโตร"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -42.482375,
        "lng": -73.7642918
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -74.1929001,
        -42.6905171,
        -73.3791796,
        -42.3518803
      ]
    },
    {
      "id": 15702,
      "name": "Panjakent",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 39.4961693,
        "lng": 67.6141456
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        67.5794401,
        39.4661651,
        67.6506651,
        39.5051188
      ]
    },
    {
      "id": 15713,
      "name": "Kos",
      "names": {
        "zh": "科斯",
        "ja": "コス",
        "th": "คอส"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 36.793693250000004,
        "lng": 27.084826562878217
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        26.9171931,
        36.6716869,
        27.3559917,
        36.9156672
      ]
    },
    {
      "id": 15722,
      "name": "Vac",
      "names": {
        "fr": "Acc",
        "zh": "疫苗",
        "id": "Liburan",
        "ja": "VACの",
        "th": "ชาตินิยม"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7782873,
        "lng": 19.1278294
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.0685833,
        47.7323476,
        19.2248038,
        47.8505663
      ]
    },
    {
      "id": 15725,
      "name": "Nokia",
      "names": {
        "zh": "诺基亚",
        "ja": "ノキア",
        "th": "โนเกีย"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4781666,
        "lng": 23.5096158
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.1367028,
        61.3276054,
        23.6131343,
        61.5722326
      ]
    },
    {
      "id": 15731,
      "name": "Kohtla-Jarve",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3970372,
        "lng": 27.286518
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        27.286468,
        59.3969872,
        27.286568,
        59.3970872
      ]
    },
    {
      "id": 15732,
      "name": "La Virginia",
      "names": {
        "fr": "La Virginie",
        "ja": "ラ・バージニア",
        "th": "ลาเวอร์จิเนีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8995993,
        "lng": -75.8825661
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8896971,
        4.871073,
        -75.814628,
        4.9348425
      ]
    },
    {
      "id": 15738,
      "name": "Bandirma",
      "names": {
        "zh": "班德尔玛"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3554705,
        "lng": 27.9697603
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.6218421,
        40.100893,
        28.2356783,
        40.4002225
      ]
    },
    {
      "id": 15747,
      "name": "Epe",
      "names": {
        "zh": "珍珠棉"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3270371,
        "lng": 5.9631945020748915
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.840831,
        52.2558071,
        6.0948575,
        52.3984371
      ]
    },
    {
      "id": 15748,
      "name": "Oldenzaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3116616,
        "lng": 6.9241615
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.8597814,
        52.2821681,
        6.9503893,
        52.3334602
      ]
    },
    {
      "id": 15750,
      "name": "Police",
      "names": {
        "es": "La Policía",
        "zh": "警察",
        "de": "Polizei",
        "it": "Polizia",
        "id": "Polisi",
        "ja": "警察",
        "th": "ตำรวจ",
        "pt": "Polícia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.5486435,
        "lng": 14.5657399
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.5231263,
        53.5308041,
        14.6359799,
        53.6046968
      ]
    },
    {
      "id": 15752,
      "name": "Zgorzelec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1563185,
        "lng": 14.991018
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.9116798,
        51.052564,
        15.0179368,
        51.2279599
      ]
    },
    {
      "id": 15757,
      "name": "Obukhiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1101633,
        "lng": 30.6269696
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.5879748,
        50.0804194,
        30.7062614,
        50.1616146
      ]
    },
    {
      "id": 15763,
      "name": "Temascalapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.81630165,
        "lng": -98.87609516012185
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.9512864,
        19.727196,
        -98.809942,
        19.9042802
      ]
    },
    {
      "id": 15764,
      "name": "Pivijay",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.46168,
        "lng": -74.61621
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.63621,
        10.44168,
        -74.59621,
        10.48168
      ]
    },
    {
      "id": 15768,
      "name": "Djenne",
      "names": {
        "fr": "Djenné"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.9051631,
        "lng": -4.5548581
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -4.7148581,
        13.7451631,
        -4.3948581,
        14.0651631
      ]
    },
    {
      "id": 15769,
      "name": "Bragado",
      "names": {
        "zh": "布拉加多"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.1155766,
        "lng": -60.489771
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.5335462,
        -35.1611328,
        -60.4131987,
        -35.0829786
      ]
    },
    {
      "id": 15772,
      "name": "Fraijanes",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4621012,
        "lng": -90.4388234
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.4908615,
        14.3637507,
        -90.3908014,
        14.5445638
      ]
    },
    {
      "id": 15775,
      "name": "Fucheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4567526,
        "lng": 104.7547784
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.5947784,
        31.2967526,
        104.9147784,
        31.6167526
      ]
    },
    {
      "id": 15779,
      "name": "Khvaf",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.5763,
        "lng": 60.14093
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        60.12093,
        34.5563,
        60.16093,
        34.5963
      ]
    },
    {
      "id": 15790,
      "name": "Sanarate",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.795,
        "lng": -90.19222
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.21222,
        14.775,
        -90.17222,
        14.815
      ]
    },
    {
      "id": 15794,
      "name": "Laojiezi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.67734,
        "lng": 101.72206
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.70206,
        26.65734,
        101.74206,
        26.69734
      ]
    },
    {
      "id": 15804,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 38.6280278,
        "lng": -90.1910154
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -90.3206525,
        38.5323215,
        -90.1641941,
        38.7743018
      ]
    },
    {
      "id": 15806,
      "name": "Puebloviejo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.833052949999999,
        "lng": -74.3679709249293
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4847976,
        10.6588468,
        -74.2196241,
        11.0071824
      ]
    },
    {
      "id": 15808,
      "name": "Liptovsky Mikulas",
      "names": {
        "pt": "Mikulas liptovsky"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0835785,
        "lng": 19.612785
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.356088,
        48.896834,
        20.060438,
        49.242819
      ]
    },
    {
      "id": 15812,
      "name": "Bardejov",
      "names": {
        "zh": "巴尔代约夫"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2926871,
        "lng": 21.2756031
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.2164763,
        49.2495134,
        21.3297124,
        49.3597046
      ]
    },
    {
      "id": 15816,
      "name": "Itzehoe",
      "names": {
        "zh": "伊策霍"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.9232313,
        "lng": 9.5159731
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.5159231,
        53.9231813,
        9.5160231,
        53.9232813
      ]
    },
    {
      "id": 15824,
      "name": "Mataquescuintla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.52917,
        "lng": -90.18417
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.20417,
        14.50917,
        -90.16417,
        14.54917
      ]
    },
    {
      "id": 15825,
      "name": "Bernburg",
      "names": {
        "zh": "贝恩堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7930788,
        "lng": 11.7391606
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.647061,
        51.7173428,
        11.904427,
        51.8477697
      ]
    },
    {
      "id": 15838,
      "name": "Forchheim",
      "names": {
        "zh": "福希海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7187319,
        "lng": 11.0595749
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.9995766,
        49.6738471,
        11.1266282,
        49.7508956
      ]
    },
    {
      "id": 15843,
      "name": "Safita",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8204716,
        "lng": 36.115325
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.075325,
        34.7804716,
        36.155325,
        34.8604716
      ]
    },
    {
      "id": 15844,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.484687299999997,
        "lng": -101.17107352368737
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.6797627,
        21.1362388,
        -100.8343215,
        21.8394167
      ]
    },
    {
      "id": 15845,
      "name": "Puerto Escondido",
      "names": {
        "ja": "プエルトエスコンディド",
        "th": "เปอร์โตเอสโซโดโด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.0142629,
        "lng": -76.2625983
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3209718,
        8.8802769,
        -76.0625885,
        9.1105794
      ]
    },
    {
      "id": 15853,
      "name": "Kaarina",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4076039,
        "lng": 22.3715084
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.3714584,
        60.4075539,
        22.3715584,
        60.4076539
      ]
    },
    {
      "id": 15857,
      "name": "Ylojarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.55342815,
        "lng": 23.59204034999999
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.5919186,
        61.5533703,
        23.5921621,
        61.553486
      ]
    },
    {
      "id": 15858,
      "name": "Vechta",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.7310691,
        "lng": 8.2873162
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1946843,
        52.6868118,
        8.3839665,
        52.8005782
      ]
    },
    {
      "id": 15859,
      "name": "Payshamba Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.10223,
        "lng": 64.68226
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 15861,
      "name": "Bocas de Satinga",
      "names": {
        "fr": "Bocas de Satinera",
        "it": "Bocas de Satingsa",
        "ja": "ボカス・デ・セティーズ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.34814,
        "lng": -78.32571
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -78.34571,
        2.32814,
        -78.30571,
        2.36814
      ]
    },
    {
      "id": 15863,
      "name": "Ansongo",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 15.6639257,
        "lng": 0.5067841
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        0.3467841,
        15.5039257,
        0.6667841,
        15.8239257
      ]
    },
    {
      "id": 15864,
      "name": "Altenburg",
      "names": {
        "zh": "阿尔滕堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9852411,
        "lng": 12.4340988
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.3721597,
        50.9221977,
        12.4956935,
        51.0346991
      ]
    },
    {
      "id": 15866,
      "name": "Epinal",
      "names": {
        "zh": "埃皮纳勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1747684,
        "lng": 6.4503643
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.3937579,
        48.1194832,
        6.5796938,
        48.2080514
      ]
    },
    {
      "id": 15871,
      "name": "Silvia",
      "names": {
        "zh": "西尔维娅",
        "ja": "シルビア",
        "th": "ซิลเวีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6115436,
        "lng": -76.378424
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.5162681,
        2.5347889,
        -76.1742629,
        2.7895522
      ]
    },
    {
      "id": 15884,
      "name": "El Dificil",
      "names": {
        "fr": "Elsif",
        "zh": "el diicil",
        "de": "Elficil",
        "it": "El dannil",
        "ja": "エルフィシル",
        "pt": "Eliminente"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.848305,
        "lng": -74.2357501
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2757501,
        9.808305,
        -74.1957501,
        9.888305
      ]
    },
    {
      "id": 15891,
      "name": "Naumburg",
      "names": {
        "zh": "瑙姆堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1525648,
        "lng": 11.8099186
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.6476118,
        51.0727766,
        11.8732095,
        51.2020157
      ]
    },
    {
      "id": 15892,
      "name": "Aalsmeer",
      "names": {
        "zh": "阿尔斯梅尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2669442,
        "lng": 4.7493384
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6702935,
        52.2163539,
        4.8238605,
        52.3057761
      ]
    },
    {
      "id": 15900,
      "name": "Inza",
      "names": {
        "zh": "因扎"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5500211,
        "lng": -76.0643978
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3284635,
        2.3169115,
        -75.9579457,
        2.679156
      ]
    },
    {
      "id": 15902,
      "name": "Deurne",
      "names": {
        "zh": "德尔讷"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.46694,
        "lng": 5.78229
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.78224,
        51.46689,
        5.78234,
        51.46699
      ]
    },
    {
      "id": 15909,
      "name": "Grodzisk Mazowiecki",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1103954,
        "lng": 20.6234643
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.6184643,
        52.1053954,
        20.6284643,
        52.1153954
      ]
    },
    {
      "id": 15915,
      "name": "Rethymno",
      "names": {
        "fr": "Réthymnon",
        "zh": "雷斯蒙"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 35.3676472,
        "lng": 24.4736079
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.3136079,
        35.2076472,
        24.6336079,
        35.5276472
      ]
    },
    {
      "id": 15925,
      "name": "Espinho",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.0083643,
        "lng": -8.6394111
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6531193,
        40.9647783,
        -8.5936229,
        41.0257665
      ]
    },
    {
      "id": 15926,
      "name": "Levice",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.2163194,
        "lng": 18.6000869
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.5610277,
        48.1724087,
        18.6548183,
        48.2417758
      ]
    },
    {
      "id": 15942,
      "name": "Olintepeque",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8851427,
        "lng": -91.514789
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.5793997,
        14.8629413,
        -91.4934766,
        14.9238851
      ]
    },
    {
      "id": 15956,
      "name": "San Pedro de Uraba",
      "names": {
        "ja": "サンペドロデウルバ",
        "th": "ซานเปโดรเดอปรา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2778883,
        "lng": -76.3830793
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.4251079,
        8.0824623,
        -76.1902045,
        8.5811622
      ]
    },
    {
      "id": 15968,
      "name": "Kristianstad",
      "names": {
        "zh": "克里斯蒂安斯塔德",
        "th": "คริสเตียนสตัด"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.0317702,
        "lng": 14.1510572
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        14.1510072,
        56.0317202,
        14.1511072,
        56.0318202
      ]
    },
    {
      "id": 15972,
      "name": "Menglie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5874652,
        "lng": 101.8592742
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.8192742,
        22.5474652,
        101.8992742,
        22.6274652
      ]
    },
    {
      "id": 15975,
      "name": "Chatellerault",
      "names": {
        "es": "Châtellerault"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.8180047,
        "lng": 0.545812
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.4946659,
        46.7729353,
        0.6220448,
        46.8565335
      ]
    },
    {
      "id": 15976,
      "name": "Buguey",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.2882302,
        "lng": 121.832126
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.792126,
        18.2482302,
        121.872126,
        18.3282302
      ]
    },
    {
      "id": 15977,
      "name": "San Andres Itzapa",
      "names": {
        "es": "San Andrés Itzapa",
        "zh": "圣安德烈斯伊茨拉帕"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.620779,
        "lng": -90.8441322
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.9039915,
        14.5400524,
        -90.8005009,
        14.6467867
      ]
    },
    {
      "id": 15980,
      "name": "Santo Domingo Suchitepequez",
      "names": {
        "es": "Santo Domingo Suchitequez",
        "fr": "Santo Domingo SomptPequez",
        "zh": "Santo domingo umitepeozz",
        "de": "Santo domingo suchepequezz",
        "pt": "Santo Domingo Suchitequez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.46667,
        "lng": -91.48333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.50333,
        14.44667,
        -91.46333,
        14.48667
      ]
    },
    {
      "id": 15988,
      "name": "Godollo",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5933379,
        "lng": 19.3579443
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.3529443,
        47.5883379,
        19.3629443,
        47.5983379
      ]
    },
    {
      "id": 15995,
      "name": "Capulhuac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.19313,
        "lng": -99.466245
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.481082,
        19.184222,
        -99.453479,
        19.206216
      ]
    },
    {
      "id": 15998,
      "name": "Kangasala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.462581,
        "lng": 24.0730841
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.0232915,
        61.4420784,
        24.0984249,
        61.4791604
      ]
    },
    {
      "id": 16004,
      "name": "Tuzantan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.146662,
        "lng": -92.422644
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.430963,
        15.140716,
        -92.417205,
        15.154287
      ]
    },
    {
      "id": 16011,
      "name": "Primorsko-Akhtarsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.045412,
        "lng": 38.166712
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.147057,
        46.027637,
        38.188194,
        46.062443
      ]
    },
    {
      "id": 16012,
      "name": "Xangda",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2039872,
        "lng": 96.4784921
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        96.3184921,
        32.0439872,
        96.6384921,
        32.3639872
      ]
    },
    {
      "id": 16017,
      "name": "Jasim",
      "names": {
        "zh": "贾希姆"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.9907325,
        "lng": 36.0617509
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.0217509,
        32.9507325,
        36.1017509,
        33.0307325
      ]
    },
    {
      "id": 16043,
      "name": "Zapala",
      "names": {
        "zh": "萨帕拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.9006215,
        "lng": -70.0668573
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -70.0916363,
        -38.913473,
        -69.9999268,
        -38.8760797
      ]
    },
    {
      "id": 16050,
      "name": "Remedios",
      "names": {
        "zh": "雷梅迪奥斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0285327,
        "lng": -74.695191
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.735191,
        6.9885327,
        -74.655191,
        7.0685327
      ]
    },
    {
      "id": 16053,
      "name": "Cabaiguan",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.0795797,
        "lng": -79.4996157
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.6536862,
        21.9933875,
        -79.2982102,
        22.2574317
      ]
    },
    {
      "id": 16055,
      "name": "Oristano",
      "names": {
        "zh": "奥里斯塔诺",
        "ja": "オリスターノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.9059497,
        "lng": 8.5916112
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.4873709,
        39.8591534,
        8.7297367,
        39.9709154
      ]
    },
    {
      "id": 16066,
      "name": "Nilka",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.7884301,
        "lng": 82.4994908
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        82.3394908,
        43.6284301,
        82.6594908,
        43.9484301
      ]
    },
    {
      "id": 16067,
      "name": "Mohyliv-Podilskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4577387,
        "lng": 27.7749534
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.7699534,
        48.4527387,
        27.7799534,
        48.4627387
      ]
    },
    {
      "id": 16068,
      "name": "Stadskanaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.9830512,
        "lng": 6.9586783
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.9583404,
        52.9830512,
        6.9586783,
        52.9834734
      ]
    },
    {
      "id": 16077,
      "name": "Paipa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7812701,
        "lng": -73.1176532
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.2409904,
        5.6585844,
        -73.0519292,
        6.0041516
      ]
    },
    {
      "id": 16079,
      "name": "Santa Rita",
      "names": {
        "zh": "圣丽塔",
        "ja": "サンタリタ",
        "th": "ซานตาริต้า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": -3.1411146,
        "lng": -44.3270285
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -44.5774513,
        -3.30847,
        -44.0915781,
        -3.0260144
      ]
    },
    {
      "id": 16087,
      "name": "I`zaz",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.58662,
        "lng": 37.04628
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.02628,
        36.56662,
        37.06628,
        36.60662
      ]
    },
    {
      "id": 16091,
      "name": "Dreux",
      "names": {
        "zh": "德勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7371595,
        "lng": 1.36553
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.36548,
        48.7371095,
        1.36558,
        48.7372095
      ]
    },
    {
      "id": 16093,
      "name": "Petatlan",
      "names": {
        "es": "Petatlán",
        "zh": "佩塔特兰"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5383929,
        "lng": -101.2685281
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.3085281,
        17.4983929,
        -101.2285281,
        17.5783929
      ]
    },
    {
      "id": 16094,
      "name": "Galapa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.8968665,
        "lng": -74.8859608
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9259608,
        10.8568665,
        -74.8459608,
        10.9368665
      ]
    },
    {
      "id": 16096,
      "name": "Guachaves",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2223458,
        "lng": -77.6766369
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.7166369,
        1.1823458,
        -77.6366369,
        1.2623458
      ]
    },
    {
      "id": 16099,
      "name": "Temascaltepec de Gonzalez",
      "names": {
        "es": "Temascaltepec de González",
        "de": "TemaSaStepec de Gonzalez",
        "it": "Teascaltepec de Gonzalez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0433733,
        "lng": -100.0414301
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.0614301,
        19.0233733,
        -100.0214301,
        19.0633733
      ]
    },
    {
      "id": 16101,
      "name": "Turicato",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0541715,
        "lng": -101.4194971
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.4394971,
        19.0341715,
        -101.3994971,
        19.0741715
      ]
    },
    {
      "id": 16107,
      "name": "Touba",
      "names": {
        "zh": "图巴"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 8.2840981,
        "lng": -7.6818105
      },
      "country": "Côte d'Ivoire",
      "importance": 2,
      "bbox": [
        -7.7218105,
        8.2440981,
        -7.6418105,
        8.3240981
      ]
    },
    {
      "id": 16122,
      "name": "Chajul",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.48528,
        "lng": -91.03417
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.05417,
        15.46528,
        -91.01417,
        15.50528
      ]
    },
    {
      "id": 16130,
      "name": "Chum Phae",
      "names": {
        "de": "Chum-Phae",
        "ja": "チャム・ファエ",
        "th": "ชุมแพ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5400365,
        "lng": 102.102223
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.062223,
        16.5000365,
        102.142223,
        16.5800365
      ]
    },
    {
      "id": 16138,
      "name": "Sitionuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7770014,
        "lng": -74.7206038
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8530672,
        10.7164553,
        -74.4273914,
        11.1066606
      ]
    },
    {
      "id": 16141,
      "name": "Jesus Maria",
      "names": {
        "fr": "Jésus Maria",
        "zh": "耶稣玛丽亚",
        "it": "Gesù Maria",
        "id": "Yesus Maria",
        "ja": "イエスマリア",
        "th": "พระเยซูมาเรีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.9764132,
        "lng": -64.0957082
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.1357082,
        -31.0164132,
        -64.0557082,
        -30.9364132
      ]
    },
    {
      "id": 16147,
      "name": "San Pedro Sacatepequez",
      "names": {
        "fr": "San Pedro Sacatepequezez",
        "ja": "サンペドロ神聖ペケツ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6860255,
        "lng": -90.6423944
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.6823944,
        14.6460255,
        -90.6023944,
        14.7260255
      ]
    },
    {
      "id": 16150,
      "name": "Zepce",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.42667,
        "lng": 18.03778
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.01778,
        44.40667,
        18.05778,
        44.44667
      ]
    },
    {
      "id": 16152,
      "name": "Lowicz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.107683,
        "lng": 19.9447873
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.9010667,
        52.064872,
        19.9883534,
        52.1373956
      ]
    },
    {
      "id": 16169,
      "name": "San Andres Xecul",
      "names": {
        "es": "San Andrés Xecul",
        "zh": "圣安德里斯Xecul",
        "ja": "サンアンドレスXecul"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9,
        "lng": -91.48333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.50333,
        14.88,
        -91.46333,
        14.92
      ]
    },
    {
      "id": 16170,
      "name": "Mae Sot",
      "names": {
        "fr": "Mae",
        "ja": "メーソット",
        "th": "แม่สอด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.714992,
        "lng": 98.5695528
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.4095528,
        16.554992,
        98.7295528,
        16.874992
      ]
    },
    {
      "id": 16171,
      "name": "Yawatahama-shi",
      "names": {
        "zh": "yawatahama市",
        "ja": "yawatahama市",
        "th": "yawatahama-ชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4627983,
        "lng": 132.4235208
      },
      "country": "Japan",
      "importance": 2,
      "bbox": [
        132.2495874,
        33.3215896,
        132.502246,
        33.6449241
      ]
    },
    {
      "id": 16173,
      "name": "Kosonsoy",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.2528658,
        "lng": 71.5446327
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        71.5088427,
        41.1942075,
        71.5670908,
        41.2696828
      ]
    },
    {
      "id": 16174,
      "name": "Oudenaarde",
      "names": {
        "zh": "奥德纳德"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8513926,
        "lng": 3.6015721
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.5965721,
        50.8463926,
        3.6065721,
        50.8563926
      ]
    },
    {
      "id": 16177,
      "name": "Ozd",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2239095,
        "lng": 20.2895983
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.2012296,
        48.1680063,
        20.388103,
        48.280493
      ]
    },
    {
      "id": 16187,
      "name": "Puerto Wilches",
      "names": {
        "ja": "プエルトウィルチス",
        "th": "เปอร์โตวิลช์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.3480175,
        "lng": -73.8979783
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.9250787,
        7.1780564,
        -73.5674572,
        8.1434194
      ]
    },
    {
      "id": 16188,
      "name": "Verbania",
      "names": {
        "zh": "韦尔巴尼亚",
        "ja": "ヴェルバニア",
        "th": "เวอร์เบเนีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.92136,
        "lng": 8.55183
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.53183,
        45.90136,
        8.57183,
        45.94136
      ]
    },
    {
      "id": 16198,
      "name": "Tongeren",
      "names": {
        "zh": "通厄伦"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7806267,
        "lng": 5.4639172
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.3889662,
        50.7201495,
        5.552954,
        50.8223647
      ]
    },
    {
      "id": 16200,
      "name": "Neira",
      "names": {
        "zh": "内拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1665,
        "lng": -75.52001
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.54001,
        5.1465,
        -75.50001,
        5.1865
      ]
    },
    {
      "id": 16213,
      "name": "Bielsk Podlaski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7615085,
        "lng": 23.1882917
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.1882417,
        52.7614585,
        23.1883417,
        52.7615585
      ]
    },
    {
      "id": 16216,
      "name": "Halden",
      "names": {
        "zh": "哈尔登"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.1245738,
        "lng": 11.3877319
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.3477319,
        59.0845738,
        11.4277319,
        59.1645738
      ]
    },
    {
      "id": 16218,
      "name": "Trebinje",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 42.71735515,
        "lng": 18.339469115931553
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.2917213,
        42.6918754,
        18.3862853,
        42.7431987
      ]
    },
    {
      "id": 16222,
      "name": "Valkenswaard",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.351001,
        "lng": 5.463902
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.422712,
        51.314403,
        5.527181,
        51.378336
      ]
    },
    {
      "id": 16224,
      "name": "Wenxian Chengguanzhen",
      "names": {
        "zh": "文贤成庄镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.94712,
        "lng": 104.68139
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.66139,
        32.92712,
        104.70139,
        32.96712
      ]
    },
    {
      "id": 16226,
      "name": "Skelleftea",
      "names": {
        "zh": "谢莱夫特奥",
        "pt": "Skellefteå"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 64.746236,
        "lng": 20.96403
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        20.820069,
        64.727981,
        21.071287,
        64.77328
      ]
    },
    {
      "id": 16229,
      "name": "Guamo",
      "names": {
        "zh": "瓜莫"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.0303906,
        "lng": -74.9679007
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0851614,
        3.9412559,
        -74.8285085,
        4.2614892
      ]
    },
    {
      "id": 16233,
      "name": "Cauquenes",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -35.9671286,
        "lng": -72.315427
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -72.6487953,
        -36.2614739,
        -71.9781976,
        -35.6739789
      ]
    },
    {
      "id": 16236,
      "name": "Kudymkar",
      "names": {
        "zh": "库德姆卡尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.014606,
        "lng": 54.664135
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        54.5302061,
        58.9633687,
        54.6944854,
        59.0622732
      ]
    },
    {
      "id": 16259,
      "name": "Buurhakaba",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 2.794785,
        "lng": 44.0784549
      },
      "country": "Somalia",
      "importance": 2,
      "bbox": [
        43.9184549,
        2.634785,
        44.2384549,
        2.954785
      ]
    },
    {
      "id": 16271,
      "name": "Boxtel",
      "names": {
        "zh": "博克斯特尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5890603,
        "lng": 5.315795445406877
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2461777,
        51.5434886,
        5.4052013,
        51.6346339
      ]
    },
    {
      "id": 16272,
      "name": "Lukow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.93008,
        "lng": 22.3790669
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.3269699,
        51.8919954,
        22.4440145,
        51.9585921
      ]
    },
    {
      "id": 16276,
      "name": "Yingshouyingzi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.5488174,
        "lng": 117.6558999
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.6508999,
        40.5438174,
        117.6608999,
        40.5538174
      ]
    },
    {
      "id": 16281,
      "name": "Eshtehard",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7255,
        "lng": 50.3662
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.3462,
        35.7055,
        50.3862,
        35.7455
      ]
    },
    {
      "id": 16282,
      "name": "Lagos",
      "names": {
        "zh": "拉戈斯",
        "ja": "ラゴス",
        "th": "ลากอส"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1027964,
        "lng": -8.6728689
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.853213,
        37.0712233,
        -8.619155,
        37.2350231
      ]
    },
    {
      "id": 16292,
      "name": "Ciudad Vieja",
      "names": {
        "ja": "シウダードビエハ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5236132,
        "lng": -90.7618554
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.8018554,
        14.4836132,
        -90.7218554,
        14.5636132
      ]
    },
    {
      "id": 16293,
      "name": "Chitral",
      "names": {
        "zh": "吉德拉尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 35.850889,
        "lng": 71.7901897
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        71.6301897,
        35.690889,
        71.9501897,
        36.010889
      ]
    },
    {
      "id": 16295,
      "name": "Tokmak",
      "names": {
        "zh": "托克马克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.2550191,
        "lng": 35.7092206
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.6385163,
        47.2234412,
        35.773212,
        47.300314
      ]
    },
    {
      "id": 16298,
      "name": "Pichucalco",
      "names": {
        "zh": "皮丘卡尔科"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.510999,
        "lng": -93.1186727
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.4163082,
        17.3316239,
        -93.0023983,
        17.6873324
      ]
    },
    {
      "id": 16310,
      "name": "Rio Grande",
      "names": {
        "zh": "里奥格兰德",
        "ja": "リオグランデ",
        "th": "ริโอแกรนด์",
        "pt": "Rude"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -53.7856766,
        "lng": -67.7016369
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -67.7921196,
        -53.8249298,
        -67.6372254,
        -53.7341525
      ]
    },
    {
      "id": 16316,
      "name": "Bochnia",
      "names": {
        "zh": "博赫尼亚"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.9773387,
        "lng": 20.4309729
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.4259729,
        49.9723387,
        20.4359729,
        49.9823387
      ]
    },
    {
      "id": 16327,
      "name": "Ski",
      "names": {
        "es": "Esquí",
        "zh": "滑雪",
        "it": "Sciare",
        "ja": "スキー",
        "th": "เล่นสกี"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7199326,
        "lng": 10.8382736
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.7982736,
        59.6799326,
        10.8782736,
        59.7599326
      ]
    },
    {
      "id": 16359,
      "name": "Amatenango de la Frontera",
      "names": {
        "fr": "Amaenango de la Frontera",
        "de": "Amateango de la frontera",
        "it": "Amatengo de la Frontera"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.4347171,
        "lng": -92.1156124
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.1356124,
        15.4147171,
        -92.0956124,
        15.4547171
      ]
    },
    {
      "id": 16371,
      "name": "Vienne",
      "names": {
        "zh": "维埃纳",
        "ja": "ビエンヌ",
        "th": "เวียน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.52524,
        "lng": 4.87477
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.8358868,
        45.4867243,
        4.9233427,
        45.5574883
      ]
    },
    {
      "id": 16379,
      "name": "Neuruppin",
      "names": {
        "zh": "新鲁平"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.920545,
        "lng": 12.798047
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.658854,
        52.792218,
        12.986864,
        53.099179
      ]
    },
    {
      "id": 16383,
      "name": "Tepetlaoxtoc",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.574992,
        "lng": -98.818754
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8320978951,
        19.5632343524,
        -98.8049133842,
        19.584480829
      ]
    },
    {
      "id": 16398,
      "name": "Hajduboszormeny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6686016,
        "lng": 21.5240462
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.5190462,
        47.6636016,
        21.5290462,
        47.6736016
      ]
    },
    {
      "id": 16406,
      "name": "Synelnykove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.32044,
        "lng": 35.51792
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.49792,
        48.30044,
        35.53792,
        48.34044
      ]
    },
    {
      "id": 16410,
      "name": "Amaga",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0406977,
        "lng": -75.7030694
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7430694,
        6.0006977,
        -75.6630694,
        6.0806977
      ]
    },
    {
      "id": 16415,
      "name": "Best",
      "names": {
        "es": "Mejor",
        "fr": "Meilleur",
        "zh": "最好",
        "it": "Migliori",
        "id": "Terbaik",
        "ja": "ベスト",
        "th": "ดีที่สุด",
        "pt": "O melhor"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5098165,
        "lng": 5.3892206
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3842206,
        51.5048165,
        5.3942206,
        51.5148165
      ]
    },
    {
      "id": 16416,
      "name": "Papa",
      "names": {
        "zh": "爸爸",
        "ja": "パパ",
        "th": "พ่อ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3261087,
        "lng": 17.4705293
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.3943923,
        47.2499831,
        17.5744867,
        47.3897753
      ]
    },
    {
      "id": 16417,
      "name": "Odemis",
      "names": {
        "zh": "厄代米什"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.227747,
        "lng": 27.9687635
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.7826925,
        37.9657834,
        28.1964772,
        38.4185745
      ]
    },
    {
      "id": 16419,
      "name": "Madruga",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9099082,
        "lng": -81.8563731
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -81.9841689,
        22.8385616,
        -81.6778032,
        23.0549966
      ]
    },
    {
      "id": 16426,
      "name": "Mlawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.116632,
        "lng": 20.376187
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.324506,
        53.090946,
        20.437986,
        53.177535
      ]
    },
    {
      "id": 16427,
      "name": "Banamba",
      "names": {
        "zh": "巴南巴"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.5485166,
        "lng": -7.446532
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -7.606532,
        13.3885166,
        -7.286532,
        13.7085166
      ]
    },
    {
      "id": 16429,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.2484204,
        "lng": -58.0298671
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.0585878,
        -33.2724146,
        -58.0024153,
        -33.2378756
      ]
    },
    {
      "id": 16431,
      "name": "Thung Song",
      "names": {
        "es": "Canción thung",
        "fr": "Chanson de Thung",
        "zh": "龙宋",
        "de": "Thung-Song",
        "it": "Canzone del Thung",
        "id": "Lagu thung",
        "ja": "猫の歌",
        "th": "เพลงทุ่ง",
        "pt": "Canção de thung"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.166657,
        "lng": 99.6774848
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.6374848,
        8.126657,
        99.7174848,
        8.206657
      ]
    },
    {
      "id": 16433,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 48.4283182,
        "lng": -123.364953
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -123.398075,
        48.4028109,
        -123.3224223,
        48.4504983
      ]
    },
    {
      "id": 16448,
      "name": "Leusden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.135797,
        "lng": 5.428444
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.400082,
        52.1004,
        5.477175,
        52.148027
      ]
    },
    {
      "id": 16449,
      "name": "G'ijduvon Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.1029573,
        "lng": 64.6828621
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        64.639505,
        40.0785154,
        64.7169055,
        40.1211263
      ]
    },
    {
      "id": 16457,
      "name": "Osterholz-Scharmbeck",
      "names": {
        "zh": "奥斯特霍尔茨-沙姆贝克"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.2281763,
        "lng": 8.7948239
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6598246,
        53.1831181,
        8.9503817,
        53.3268615
      ]
    },
    {
      "id": 16463,
      "name": "Mont-de-Marsan",
      "names": {
        "zh": "勃朗峰 - 去马尔桑",
        "ja": "モン=ド=マルサン",
        "th": "มองต์เดอ-marsan"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.8911318,
        "lng": -0.500972
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.5559748,
        43.8544441,
        -0.4461954,
        43.9313151
      ]
    },
    {
      "id": 16471,
      "name": "Korinthos",
      "names": {
        "zh": "科林斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9385413,
        "lng": 22.9273768
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.9031873,
        37.9248502,
        22.95348,
        37.9465575
      ]
    },
    {
      "id": 16484,
      "name": "Horki",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.289463,
        "lng": 30.9810782
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        30.9467114,
        54.2382063,
        31.0286025,
        54.318377
      ]
    },
    {
      "id": 16492,
      "name": "Telimele",
      "names": {
        "zh": "泰利梅莱"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.9020333,
        "lng": -13.0326711
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -13.0726711,
        10.8620333,
        -12.9926711,
        10.9420333
      ]
    },
    {
      "id": 16493,
      "name": "Uithoorn",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2411412,
        "lng": 4.8251096
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7236552,
        52.2065164,
        4.8521873,
        52.2610156
      ]
    },
    {
      "id": 16509,
      "name": "Chimichagua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2576662,
        "lng": -73.8126883
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.9920083,
        8.9830202,
        -73.4148611,
        9.4930655
      ]
    },
    {
      "id": 16524,
      "name": "Kombissiri",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.0666555,
        "lng": -1.3355778
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -1.3755778,
        12.0266555,
        -1.2955778,
        12.1066555
      ]
    },
    {
      "id": 16527,
      "name": "Augustow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.845199,
        "lng": 22.976711
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.866589,
        53.805099,
        23.153197,
        53.884927
      ]
    },
    {
      "id": 16546,
      "name": "Shangpa",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.9090847,
        "lng": 98.8667122
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.730998,
        26.7796642,
        98.9828475,
        27.0079361
      ]
    },
    {
      "id": 16547,
      "name": "Bad Hersfeld",
      "names": {
        "es": "Mal Hersfeld",
        "fr": "Mauvais hersfeld",
        "zh": "糟糕的hersfeld",
        "de": "Schlechtes Hersfeld",
        "id": "Hersfeld buruk",
        "ja": "悪いハースフェルト",
        "th": "hersfeld ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8681341,
        "lng": 9.7068481
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.6668481,
        50.8281341,
        9.7468481,
        50.9081341
      ]
    },
    {
      "id": 16562,
      "name": "Planadas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.1973487,
        "lng": -75.6446464
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6846464,
        3.1573487,
        -75.6046464,
        3.2373487
      ]
    },
    {
      "id": 16563,
      "name": "Brodnica",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.258092,
        "lng": 19.3993626
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.3568436,
        53.2221202,
        19.4561094,
        53.2938012
      ]
    },
    {
      "id": 16564,
      "name": "Tabio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9163961,
        "lng": -74.0990945
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.1390945,
        4.8763961,
        -74.0590945,
        4.9563961
      ]
    },
    {
      "id": 16571,
      "name": "Barbosa",
      "names": {
        "zh": "巴博萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9326035,
        "lng": -73.6124368
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6634699,
        5.9109513,
        -73.586926,
        5.9985992
      ]
    },
    {
      "id": 16578,
      "name": "Condega",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.36502,
        "lng": -86.39846
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.41846,
        13.34502,
        -86.37846,
        13.38502
      ]
    },
    {
      "id": 16584,
      "name": "Heesch",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.73519,
        "lng": 5.5266331
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.4742443,
        51.6832878,
        5.5699187,
        51.7406068
      ]
    },
    {
      "id": 16586,
      "name": "Villa Angela",
      "names": {
        "ja": "ヴィラアンジェラ",
        "th": "วิลล่าแองเจล่า"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.5768225,
        "lng": -60.7112067
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.7542717,
        -27.6157768,
        -60.6868564,
        -27.536587
      ]
    },
    {
      "id": 16588,
      "name": "Saalfeld",
      "names": {
        "zh": "萨尔费尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.648587,
        "lng": 11.36458
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.210477,
        50.571158,
        11.424095,
        50.680388
      ]
    },
    {
      "id": 16589,
      "name": "Meschede",
      "names": {
        "zh": "梅舍德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3494571,
        "lng": 8.2797103
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.2796603,
        51.3494071,
        8.2797603,
        51.3495071
      ]
    },
    {
      "id": 16595,
      "name": "Neuburg",
      "names": {
        "zh": "诺伊堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.988922,
        "lng": 8.2475747
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.220398,
        48.9660845,
        8.2876592,
        48.9995579
      ]
    },
    {
      "id": 16603,
      "name": "Merzig",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4449991,
        "lng": 6.6233869
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.6233369,
        49.4449491,
        6.6234369,
        49.4450491
      ]
    },
    {
      "id": 16606,
      "name": "Asipovichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.3011,
        "lng": 28.6386
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        28.6186,
        53.2811,
        28.6586,
        53.3211
      ]
    },
    {
      "id": 16614,
      "name": "Arlon",
      "names": {
        "zh": "阿尔隆"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 49.6834601,
        "lng": 5.8167711
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.6784616,
        49.621875,
        5.9106752,
        49.727234
      ]
    },
    {
      "id": 16621,
      "name": "Giannitsa",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.79304,
        "lng": 22.411477
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.251477,
        40.63304,
        22.571477,
        40.95304
      ]
    },
    {
      "id": 16624,
      "name": "Svendborg",
      "names": {
        "zh": "斯文堡"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.065579,
        "lng": 10.60726
      },
      "country": "Denmark",
      "importance": 2,
      "bbox": [
        10.566024,
        55.040587,
        10.654074,
        55.089819
      ]
    },
    {
      "id": 16627,
      "name": "Fresno",
      "names": {
        "zh": "弗雷斯诺",
        "ja": "フレズノ",
        "th": "เฟรสโน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1516594,
        "lng": -75.0366354
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1394469,
        5.091127,
        -74.9646019,
        5.2935281
      ]
    },
    {
      "id": 16628,
      "name": "Schwelm",
      "names": {
        "zh": "施韦尔姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2905428,
        "lng": 7.2896552
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.2846552,
        51.2855428,
        7.2946552,
        51.2955428
      ]
    },
    {
      "id": 16635,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.21396265,
        "lng": -96.89929175353117
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0213741,
        20.1305707,
        -96.7762636,
        20.2978634
      ]
    },
    {
      "id": 16641,
      "name": "Xalatlaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.18036,
        "lng": -99.416717
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.432718,
        19.171577,
        -99.406566,
        19.190798
      ]
    },
    {
      "id": 16645,
      "name": "Tequisquiapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5226152,
        "lng": -99.8914752
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.9119608,
        20.5019065,
        -99.8739276,
        20.5670358
      ]
    },
    {
      "id": 16646,
      "name": "Villa Gesell",
      "names": {
        "ja": "ヴィラ・ジェセル"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.2556627,
        "lng": -56.9679411
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -57.0079411,
        -37.2956627,
        -56.9279411,
        -37.2156627
      ]
    },
    {
      "id": 16655,
      "name": "Purificacion",
      "names": {
        "es": "Purificación"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.8582108,
        "lng": -74.9308216
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0352934,
        3.7430577,
        -74.7066688,
        4.0083466
      ]
    },
    {
      "id": 16670,
      "name": "Flandes",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2864999,
        "lng": -74.813697
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9083084,
        4.1864588,
        -74.7763566,
        4.2943951
      ]
    },
    {
      "id": 16674,
      "name": "Santiago do Cacem",
      "names": {
        "zh": "圣地亚哥做CACEM",
        "th": "Santiago ทำ Cacem"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.0186975,
        "lng": -8.6930776
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6931276,
        38.0186475,
        -8.6930276,
        38.0187475
      ]
    },
    {
      "id": 16676,
      "name": "Alquizar",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.80583,
        "lng": -82.58417
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.58417,
        22.80583,
        -82.58417,
        22.80583
      ]
    },
    {
      "id": 16677,
      "name": "Friedberg",
      "names": {
        "zh": "弗里德伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3352682,
        "lng": 8.7539306
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6365275,
        50.2897934,
        8.8211877,
        50.3644799
      ]
    },
    {
      "id": 16685,
      "name": "Atbasar",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.80652,
        "lng": 68.35996
      },
      "country": "Kazakhstan",
      "importance": 2,
      "bbox": [
        68.33996,
        51.78652,
        68.37996,
        51.82652
      ]
    },
    {
      "id": 16688,
      "name": "Brunssum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9431108,
        "lng": 5.9705675
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9284701,
        50.9264395,
        6.0182017,
        50.9625296
      ]
    },
    {
      "id": 16692,
      "name": "Bosanska Krupa",
      "names": {
        "ja": "ボサンスカクルパ"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.8825,
        "lng": 16.15139
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        16.13139,
        44.8625,
        16.17139,
        44.9025
      ]
    },
    {
      "id": 16693,
      "name": "Ozumba",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.038266,
        "lng": -98.793088
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.811169,
        19.025798,
        -98.780363,
        19.053454
      ]
    },
    {
      "id": 16701,
      "name": "Toksun",
      "names": {
        "zh": "托克逊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.7909817,
        "lng": 88.6530355
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        88.4930355,
        42.6309817,
        88.8130355,
        42.9509817
      ]
    },
    {
      "id": 16709,
      "name": "Rendsburg",
      "names": {
        "zh": "伦茨堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.300292,
        "lng": 9.6650076
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.6250076,
        54.260292,
        9.7050076,
        54.340292
      ]
    },
    {
      "id": 16715,
      "name": "Gustrow",
      "names": {
        "de": "Güstrow",
        "it": "Güstrow",
        "id": "Güstrow"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.793587,
        "lng": 12.1764906
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.119024,
        53.750922,
        12.294451,
        53.837105
      ]
    },
    {
      "id": 16719,
      "name": "Landsberg",
      "names": {
        "zh": "兰茨伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0476163,
        "lng": 10.8713977
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8663977,
        48.0426163,
        10.8763977,
        48.0526163
      ]
    },
    {
      "id": 16720,
      "name": "Juchitepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.100827,
        "lng": -98.8788452
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.9188452,
        19.060827,
        -98.8388452,
        19.140827
      ]
    },
    {
      "id": 16738,
      "name": "Carpentras",
      "names": {
        "zh": "卡庞特拉",
        "ja": "カルパントラ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0554043,
        "lng": 5.0487681
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.0149369,
        44.0223396,
        5.1017856,
        44.1034231
      ]
    },
    {
      "id": 16742,
      "name": "Ja`ar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.2231254,
        "lng": 45.305762
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        45.265762,
        13.1831254,
        45.345762,
        13.2631254
      ]
    },
    {
      "id": 16746,
      "name": "Riihimaki",
      "names": {
        "zh": "里希迈基"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.7390089,
        "lng": 24.7728148
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.6056254,
        60.6477392,
        24.8447251,
        60.8003074
      ]
    },
    {
      "id": 16757,
      "name": "Sint-Michielsgestel",
      "names": {
        "zh": "圣彼-michielsgestel",
        "ja": "シント・michielsgestel",
        "th": "ซิงต์-michielsgestel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.644456,
        "lng": 5.350857
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.30379722156,
        51.6221270559,
        5.38326152234,
        51.673367158
      ]
    },
    {
      "id": 16759,
      "name": "Culemborg",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9470421,
        "lng": 5.2267808
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2217808,
        51.9420421,
        5.2317808,
        51.9520421
      ]
    },
    {
      "id": 16769,
      "name": "Gyula",
      "names": {
        "zh": "久洛"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6453632,
        "lng": 21.2739334
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.2738834,
        46.6453132,
        21.2739834,
        46.6454132
      ]
    },
    {
      "id": 16770,
      "name": "Kiskunfelegyhaza",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.7114381,
        "lng": 19.8501648
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.7552065,
        46.6032341,
        19.9825688,
        46.8341151
      ]
    },
    {
      "id": 16773,
      "name": "Ath",
      "names": {
        "zh": "阿特"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6311349,
        "lng": 3.7769485
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.655107,
        50.5624779,
        3.9133999,
        50.6974464
      ]
    },
    {
      "id": 16776,
      "name": "Ra's al `Ayn",
      "names": {
        "es": "Ra's al 'Ayn",
        "fr": "Ra al `ayn",
        "zh": "ra's al` ayn",
        "de": "Ra's Al` Ayn",
        "ja": "RAのAL `Ayn",
        "th": "ra's `ayn",
        "pt": "Ra's Al 'Ayn"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.8000487,
        "lng": 36.9617336
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.9417336,
        35.7800487,
        36.9817336,
        35.8200487
      ]
    },
    {
      "id": 16777,
      "name": "Sopo",
      "names": {
        "zh": "索普"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8931442,
        "lng": -73.95341365530066
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.011175,
        4.8122164,
        -73.9152698,
        4.9738646
      ]
    },
    {
      "id": 16778,
      "name": "Suran",
      "names": {
        "zh": "苏兰"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.2906196,
        "lng": 36.7492737
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.7092737,
        35.2506196,
        36.7892737,
        35.3306196
      ]
    },
    {
      "id": 16784,
      "name": "Quivican",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8232042,
        "lng": -82.3552634
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.4690367,
        22.7321396,
        -82.2793386,
        22.9147109
      ]
    },
    {
      "id": 16794,
      "name": "Boxmeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6486483,
        "lng": 5.9450972
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8964771,
        51.6313489,
        5.9721279,
        51.6683361
      ]
    },
    {
      "id": 16801,
      "name": "Northeim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.705401,
        "lng": 9.9972782
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.8898016,
        51.6418355,
        10.1190576,
        51.7897952
      ]
    },
    {
      "id": 16805,
      "name": "Khmilnyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.55865675,
        "lng": 27.973224450000004
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.9729578,
        49.5584798,
        27.9734911,
        49.5588337
      ]
    },
    {
      "id": 16811,
      "name": "Anadia",
      "names": {
        "zh": "阿纳迪亚"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4426833,
        "lng": -8.4359581
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.57982,
        40.3953599,
        -8.3114947,
        40.5125262
      ]
    },
    {
      "id": 16814,
      "name": "Monitos",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2460394,
        "lng": -76.1315965
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1929316,
        9.0852542,
        -76.0437948,
        9.3132931
      ]
    },
    {
      "id": 16819,
      "name": "Canada de Gomez",
      "names": {
        "es": "Canadá de Gómez",
        "zh": "加拿大德梅兹",
        "de": "Kanada de Gomez",
        "id": "Kanada de Gomez",
        "ja": "カナダデゴメス",
        "th": "แคนาดาเดอโกเมซ",
        "pt": "Canadá de gomez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.8223926,
        "lng": -61.3949014
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.3999014,
        -32.8273926,
        -61.3899014,
        -32.8173926
      ]
    },
    {
      "id": 16821,
      "name": "Fundao",
      "names": {
        "zh": "丰当",
        "pt": "Fundão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.138889,
        "lng": -7.500182
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.50779522179,
        40.1321437562,
        -7.49175815369,
        40.1540480863
      ]
    },
    {
      "id": 16829,
      "name": "Aipe",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2223559,
        "lng": -75.239962
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5884818,
        3.0577972,
        -75.175932,
        3.4195656
      ]
    },
    {
      "id": 16835,
      "name": "Ornskoldsvik",
      "names": {
        "de": "Örnsköldsvik"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 63.291386700000004,
        "lng": 18.722719424506295
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        18.6889028,
        63.2716382,
        18.7663435,
        63.3123414
      ]
    },
    {
      "id": 16845,
      "name": "Primero de Enero",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.945378,
        "lng": -78.4293776
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -78.5681517,
        21.7574855,
        -78.1518554,
        22.038797
      ]
    },
    {
      "id": 16849,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 16.735377,
        "lng": -92.63873
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -92.79873,
        16.575377,
        -92.47873,
        16.895377
      ]
    },
    {
      "id": 16857,
      "name": "Waddinxveen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0440583,
        "lng": 4.6499035
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6449035,
        52.0390583,
        4.6549035,
        52.0490583
      ]
    },
    {
      "id": 16861,
      "name": "Ban Phai",
      "names": {
        "es": "Prohibir",
        "zh": "班菲",
        "de": "Phai Ban",
        "ja": "バンパイ",
        "th": "บ้านไผ่",
        "pt": "Pro proai"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0588988,
        "lng": 102.7279679
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.6879679,
        16.0188988,
        102.7679679,
        16.0988988
      ]
    },
    {
      "id": 16868,
      "name": "Winterswijk",
      "names": {
        "zh": "温特斯韦克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.967587,
        "lng": 6.7155146
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.7154646,
        51.967537,
        6.7155646,
        51.967637
      ]
    },
    {
      "id": 16871,
      "name": "Nouna",
      "names": {
        "zh": "努纳"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 13.8537394,
        "lng": -4.3929239
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -4.4129239,
        13.8337394,
        -4.3729239,
        13.8737394
      ]
    },
    {
      "id": 16874,
      "name": "Benavente",
      "names": {
        "zh": "贝纳文特"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9826508,
        "lng": -8.8095788
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9684661,
        38.7314904,
        -8.6527944,
        39.0319529
      ]
    },
    {
      "id": 16879,
      "name": "Meissen",
      "names": {
        "zh": "迈森",
        "de": "Meißen",
        "ja": "マイセン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1630024,
        "lng": 13.4829412
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.4779412,
        51.1580024,
        13.4879412,
        51.1680024
      ]
    },
    {
      "id": 16880,
      "name": "La Dorada",
      "names": {
        "ja": "ラドラダ",
        "th": "ลาโดราดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.34308,
        "lng": -76.9122291
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.0495189,
        0.2389608,
        -76.6411,
        0.3837391
      ]
    },
    {
      "id": 16882,
      "name": "Schwandorf",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3263177,
        "lng": 12.1038045
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.0988045,
        49.3213177,
        12.1088045,
        49.3313177
      ]
    },
    {
      "id": 16892,
      "name": "Salihli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4860588,
        "lng": 28.1390032
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.9098941,
        38.311705,
        28.4851597,
        38.7651043
      ]
    },
    {
      "id": 16896,
      "name": "Villa Comaltitlan",
      "names": {
        "it": "Villa Comalitlan",
        "ja": "ヴィラコマルティン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.217329,
        "lng": -92.57178
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.597253,
        15.202263,
        -92.55509,
        15.236739
      ]
    },
    {
      "id": 16898,
      "name": "Tondela",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.51642,
        "lng": -8.08053
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3084199,
        40.4479648,
        -7.9591535,
        40.6406705
      ]
    },
    {
      "id": 16899,
      "name": "Nenton",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.8008963,
        "lng": -91.7549218
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.7949218,
        15.7608963,
        -91.7149218,
        15.8408963
      ]
    },
    {
      "id": 16900,
      "name": "La Calera",
      "names": {
        "zh": "拉卡拉",
        "ja": "ラカレラ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7236078,
        "lng": -73.9689034
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.9752281,
        4.7116082,
        -73.9532822,
        4.7285825
      ]
    },
    {
      "id": 16901,
      "name": "Hoxter",
      "names": {
        "es": "Höxter",
        "de": "Höxter"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.77365065,
        "lng": 9.376049229144627
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.3356,
        51.7397692,
        9.4238136,
        51.8073196
      ]
    },
    {
      "id": 16904,
      "name": "Nivelles",
      "names": {
        "zh": "尼韦勒"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5976885,
        "lng": 4.3235854
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.2458068,
        50.5432244,
        4.4066525,
        50.6352372
      ]
    },
    {
      "id": 16911,
      "name": "Toqsu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5467212,
        "lng": 82.6046991
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        82.5646991,
        41.5067212,
        82.6446991,
        41.5867212
      ]
    },
    {
      "id": 16915,
      "name": "Krotoszyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.695926,
        "lng": 17.4374158
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.4049836,
        51.6641495,
        17.4828222,
        51.7260813
      ]
    },
    {
      "id": 16917,
      "name": "Caicedonia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3354479,
        "lng": -75.826652
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8378379,
        4.3255454,
        -75.8198569,
        4.3422436
      ]
    },
    {
      "id": 16921,
      "name": "Nava",
      "names": {
        "zh": "纳瓦",
        "th": "นว"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.493132000000003,
        "lng": -100.64294376573363
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.8393036,
        28.3330984,
        -100.39677,
        28.6510149
      ]
    },
    {
      "id": 16923,
      "name": "Hirado",
      "names": {
        "zh": "平户"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3680705,
        "lng": 129.5539153
      },
      "country": "Japan",
      "importance": 2,
      "bbox": [
        129.1691824,
        33.0778577,
        129.6666222,
        33.7567437
      ]
    },
    {
      "id": 16966,
      "name": "Heerenveen",
      "names": {
        "zh": "海伦芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.959035,
        "lng": 5.92267
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.867215,
        52.91174,
        5.972824,
        52.989353
      ]
    },
    {
      "id": 16979,
      "name": "San Salvador",
      "names": {
        "zh": "圣萨尔瓦多",
        "ja": "サンサルバドール",
        "th": "ซานซัลวาดอร์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 13.7372142,
        "lng": -89.2861135
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.2861635,
        13.7371642,
        -89.2860635,
        13.7372642
      ]
    },
    {
      "id": 16986,
      "name": "Hatonuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.0675576,
        "lng": -72.7600808
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.8000808,
        11.0275576,
        -72.7200808,
        11.1075576
      ]
    },
    {
      "id": 17004,
      "name": "Emmendingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1206565,
        "lng": 7.850993
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.8259589,
        48.0987141,
        7.9097335,
        48.1471994
      ]
    },
    {
      "id": 17006,
      "name": "Gyongyos",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7840773,
        "lng": 19.9334611
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.9284611,
        47.7790773,
        19.9384611,
        47.7890773
      ]
    },
    {
      "id": 17015,
      "name": "Dalfsen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4982281,
        "lng": 6.2594892
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.2544892,
        52.4932281,
        6.2644892,
        52.5032281
      ]
    },
    {
      "id": 17018,
      "name": "Byaroza",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.5314,
        "lng": 24.9786
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.9586,
        52.5114,
        24.9986,
        52.5514
      ]
    },
    {
      "id": 17021,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1908026,
        "lng": -74.943787890907
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1217374,
        6.0427797,
        -74.7133773,
        6.3386303
      ]
    },
    {
      "id": 17027,
      "name": "Isnos",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9301058,
        "lng": -76.2150064
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.2550064,
        1.8901058,
        -76.1750064,
        1.9701058
      ]
    },
    {
      "id": 17036,
      "name": "Bilgoraj",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.5515661,
        "lng": 22.7328489
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.7278489,
        50.5465661,
        22.7378489,
        50.5565661
      ]
    },
    {
      "id": 17040,
      "name": "Lucenec",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3303886,
        "lng": 19.6634149
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.6106425,
        48.2967926,
        19.7257674,
        48.3567734
      ]
    },
    {
      "id": 17045,
      "name": "Baradero",
      "names": {
        "zh": "巴拉德罗"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.8120471,
        "lng": -59.5051759
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.5361124,
        -33.8281748,
        -59.4840522,
        -33.7917582
      ]
    },
    {
      "id": 17046,
      "name": "Lillehammer",
      "names": {
        "zh": "利勒哈默尔",
        "ja": "リレハンメル"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.1145451,
        "lng": 10.4670073
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.4270073,
        61.0745451,
        10.5070073,
        61.1545451
      ]
    },
    {
      "id": 17047,
      "name": "Tha Yang",
      "names": {
        "zh": "泰文",
        "th": "ท่าหยาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.9721544,
        "lng": 99.8896248
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8496248,
        12.9321544,
        99.9296248,
        13.0121544
      ]
    },
    {
      "id": 17056,
      "name": "Gorlice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 49.65829,
        "lng": 21.1593832
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.1189554,
        49.634831,
        21.2129286,
        49.6978014
      ]
    },
    {
      "id": 17061,
      "name": "Venezuela",
      "names": {
        "zh": "委内瑞拉",
        "ja": "ベネズエラ",
        "th": "เวเนซุเอลา"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.7394241,
        "lng": -78.7955599
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.0222552,
        21.5281815,
        -78.6499216,
        21.8720405
      ]
    },
    {
      "id": 17071,
      "name": "Coyaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7993906,
        "lng": -75.1945858
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3308021,
        3.5914142,
        -74.9729323,
        3.9111192
      ]
    },
    {
      "id": 17075,
      "name": "Chortkiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0160555,
        "lng": 25.7925191
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.755727,
        48.9795556,
        25.8167798,
        49.0302444
      ]
    },
    {
      "id": 17079,
      "name": "Circasia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6075667,
        "lng": -75.66670665407705
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.740697,
        4.5384901,
        -75.5955966,
        4.6766537
      ]
    },
    {
      "id": 17084,
      "name": "Carbonia",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 39.166319,
        "lng": 8.526454
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.497616,
        39.136321,
        8.544021,
        39.182652
      ]
    },
    {
      "id": 17094,
      "name": "Raseborg",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 59.97735,
        "lng": 23.43967
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.41967,
        59.95735,
        23.45967,
        59.99735
      ]
    },
    {
      "id": 17098,
      "name": "Ciro Redondo",
      "names": {
        "pt": "Cirão redondo"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.0140937,
        "lng": -78.705045
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -78.8540385,
        21.8085787,
        -78.5076142,
        22.1614142
      ]
    },
    {
      "id": 17100,
      "name": "Warin Chamrap",
      "names": {
        "es": "Camarón warin",
        "pt": "Chanfrap warin"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1984454,
        "lng": 104.8628696
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.8228696,
        15.1584454,
        104.9028696,
        15.2384454
      ]
    },
    {
      "id": 17109,
      "name": "Las Rosas",
      "names": {
        "zh": "拉斯罗莎",
        "ja": "ラスロサ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.364468,
        "lng": -92.3707419
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.4107419,
        16.324468,
        -92.3307419,
        16.404468
      ]
    },
    {
      "id": 17110,
      "name": "Ban Chang",
      "names": {
        "es": "Prohibir",
        "fr": "Interdire",
        "zh": "班昌",
        "it": "Bandito",
        "ja": "バンチャン",
        "th": "บ้านช้าง",
        "pt": "Proibir chang"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6785677,
        "lng": 101.0671375
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.9071375,
        12.5185677,
        101.2271375,
        12.8385677
      ]
    },
    {
      "id": 17112,
      "name": "Toli",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.9332143,
        "lng": 83.6034035
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        83.5381509,
        45.8662335,
        83.6806554,
        46.0109715
      ]
    },
    {
      "id": 17120,
      "name": "Guamal",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.144026,
        "lng": -74.2236605
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3063183,
        9.08579,
        -73.9792136,
        9.4020548
      ]
    },
    {
      "id": 17121,
      "name": "Khust",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1764917,
        "lng": 23.291166
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.2618338,
        48.1482258,
        23.3292839,
        48.2024336
      ]
    },
    {
      "id": 17125,
      "name": "Gusev",
      "names": {
        "zh": "古瑟夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5921685,
        "lng": 22.2014752
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        22.1529984,
        54.5674378,
        22.2388098,
        54.6156237
      ]
    },
    {
      "id": 17128,
      "name": "Piest'any",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.59064,
        "lng": 17.82679
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 17129,
      "name": "Esztergom",
      "names": {
        "zh": "埃斯泰尔戈姆"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7856939,
        "lng": 18.7403377
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.655735,
        47.6926083,
        18.8827727,
        47.8227792
      ]
    },
    {
      "id": 17134,
      "name": "Ajka",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1056579,
        "lng": 17.5587276
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.4942785,
        47.0196223,
        17.6405335,
        47.1579913
      ]
    },
    {
      "id": 17135,
      "name": "Klodzko",
      "names": {
        "th": "คลอดซ์โก"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.4374,
        "lng": 16.652285
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.603974,
        50.400977,
        16.694095,
        50.466514
      ]
    },
    {
      "id": 17140,
      "name": "Alga",
      "names": {
        "fr": "Algue",
        "zh": "藻类",
        "ja": "藻類",
        "th": "สาหร่าย"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 49.896259,
        "lng": 57.330296
      },
      "country": "Kazakhstan",
      "importance": 2,
      "bbox": [
        57.2994216,
        49.873125,
        57.3472138,
        49.9117084
      ]
    },
    {
      "id": 17145,
      "name": "Zacoalco de Torres",
      "names": {
        "ja": "ザコアルコデトーレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2299884,
        "lng": -103.5716875
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.6116875,
        20.1899884,
        -103.5316875,
        20.2699884
      ]
    },
    {
      "id": 17148,
      "name": "Bad Neuenahr-Ahrweiler",
      "names": {
        "es": "Mal neuenhr-ahweiler",
        "zh": "坏Neuenahr-Ahrweiler",
        "id": "Neuenahr-ahrweiler yang buruk"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5444551,
        "lng": 7.1186671
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.0542018,
        50.4767535,
        7.2086622,
        50.5824706
      ]
    },
    {
      "id": 17151,
      "name": "Bardaskan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.26218,
        "lng": 57.97075
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        57.95075,
        35.24218,
        57.99075,
        35.28218
      ]
    },
    {
      "id": 17156,
      "name": "Capljina",
      "names": {
        "zh": "查普利纳"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.109791900000005,
        "lng": 17.69810302222527
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.6979093,
        43.1093713,
        17.698252,
        43.1102468
      ]
    },
    {
      "id": 17164,
      "name": "Rapperswil-Jona",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.227574,
        "lng": 8.843525
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.795612,
        47.208165,
        8.919177,
        47.251512
      ]
    },
    {
      "id": 17166,
      "name": "Guma",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.6072047,
        "lng": 78.280699
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        78.120699,
        37.4472047,
        78.440699,
        37.7672047
      ]
    },
    {
      "id": 17170,
      "name": "Dajabon",
      "names": {
        "zh": "达哈翁"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.5495314,
        "lng": -71.7080706
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -71.7081206,
        19.5494814,
        -71.7080206,
        19.5495814
      ]
    },
    {
      "id": 17178,
      "name": "Szentendre",
      "names": {
        "zh": "圣安德烈"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6677606,
        "lng": 19.0760467
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.974938,
        47.6257336,
        19.0909889,
        47.7285618
      ]
    },
    {
      "id": 17188,
      "name": "Peniche",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3568749,
        "lng": -9.3786838
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.5498113,
        39.2834588,
        -9.2564314,
        39.4802385
      ]
    },
    {
      "id": 17191,
      "name": "Khulm",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.6945727,
        "lng": 67.6980831
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        67.6580831,
        36.6545727,
        67.7380831,
        36.7345727
      ]
    },
    {
      "id": 17196,
      "name": "Tehuipango",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5175,
        "lng": -97.055278
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.095278,
        18.4775,
        -97.015278,
        18.5575
      ]
    },
    {
      "id": 17200,
      "name": "Inegol",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.0800359,
        "lng": 29.5096528
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.2423081,
        39.8269099,
        29.7980751,
        40.2139153
      ]
    },
    {
      "id": 17212,
      "name": "Uchturpan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2181307,
        "lng": 79.2314076
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        79.0714076,
        41.0581307,
        79.3914076,
        41.3781307
      ]
    },
    {
      "id": 17216,
      "name": "Quiroga",
      "names": {
        "zh": "基罗加"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6638149,
        "lng": -101.5240271
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.5640271,
        19.6238149,
        -101.4840271,
        19.7038149
      ]
    },
    {
      "id": 17221,
      "name": "Chios",
      "names": {
        "zh": "希俄斯",
        "ja": "キオス",
        "th": "ชิโอ"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.3722387,
        "lng": 26.132979
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        26.092979,
        38.3322387,
        26.172979,
        38.4122387
      ]
    },
    {
      "id": 17222,
      "name": "Imatra",
      "names": {
        "zh": "伊马特拉",
        "ja": "イマトラ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1923342,
        "lng": 28.7716996
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        28.668577,
        61.0795492,
        29.0230848,
        61.284363
      ]
    },
    {
      "id": 17230,
      "name": "San Martin de los Andes",
      "names": {
        "zh": "圣马丁德洛斯斯",
        "ja": "サンマーティンデロスアンデス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.1568306,
        "lng": -71.352631
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -71.3861394,
        -40.2306076,
        -71.1928087,
        -40.0373993
      ]
    },
    {
      "id": 17236,
      "name": "Nochistlan de Mejia",
      "names": {
        "id": "Nolchistlan de mejia"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3640239,
        "lng": -102.8468061
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.8868061,
        21.3240239,
        -102.8068061,
        21.4040239
      ]
    },
    {
      "id": 17242,
      "name": "Sabaneta",
      "names": {
        "zh": "萨瓦内塔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.7529286,
        "lng": -69.9345447
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.0945447,
        8.5929286,
        -69.7745447,
        8.9129286
      ]
    },
    {
      "id": 17247,
      "name": "Tlalpujahua de Rayon",
      "names": {
        "es": "Tlalpujahua de rayón",
        "zh": "Tlalpujahua deyon"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8055298,
        "lng": -100.1737104
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.2137104,
        19.7655298,
        -100.1337104,
        19.8455298
      ]
    },
    {
      "id": 17268,
      "name": "Villa Luvianos",
      "names": {
        "zh": "别墅卢瓦亚州",
        "it": "Villa Luvinos",
        "pt": "Villa Luvolsos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9200102,
        "lng": -100.2983687
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.3383687,
        18.8800102,
        -100.2583687,
        18.9600102
      ]
    },
    {
      "id": 17269,
      "name": "Vyshhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.58476,
        "lng": 30.4898
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.4698,
        50.56476,
        30.5098,
        50.60476
      ]
    },
    {
      "id": 17270,
      "name": "Salinas Victoria",
      "names": {
        "zh": "萨利纳斯维多利亚",
        "th": "ซาลินาสวิคตอเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.964591,
        "lng": -100.29239
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.309075,
        25.950704,
        -100.276793,
        25.980892
      ]
    },
    {
      "id": 17271,
      "name": "Atlautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.01618335,
        "lng": -98.70553666625293
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8249987,
        18.9402925,
        -98.6198746,
        19.0923191
      ]
    },
    {
      "id": 17274,
      "name": "Zolotonosha",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.6689719,
        "lng": 32.0427439
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.0060703,
        49.6437746,
        32.0867814,
        49.6946725
      ]
    },
    {
      "id": 17276,
      "name": "Zaltbommel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7987735,
        "lng": 5.2583321
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2582821,
        51.7987235,
        5.2583821,
        51.7988235
      ]
    },
    {
      "id": 17277,
      "name": "Soignies",
      "names": {
        "zh": "苏瓦尼"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5732355,
        "lng": 4.0691915
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.0641915,
        50.5682355,
        4.0741915,
        50.5782355
      ]
    },
    {
      "id": 17279,
      "name": "Fuman",
      "names": {
        "zh": "富曼"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.224,
        "lng": 49.3125
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.2925,
        37.204,
        49.3325,
        37.244
      ]
    },
    {
      "id": 17281,
      "name": "Veendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.105363,
        "lng": 6.874823
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.815806,
        53.04363,
        6.961914,
        53.12884
      ]
    },
    {
      "id": 17296,
      "name": "Zakopane",
      "names": {
        "zh": "扎科帕内"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.2969114,
        "lng": 19.9504753
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.9003873,
        49.2183468,
        20.0431573,
        49.3331728
      ]
    },
    {
      "id": 17298,
      "name": "Vinales",
      "names": {
        "es": "Viñales",
        "zh": "比尼亚莱斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.616537,
        "lng": -83.709023
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -83.8919999,
        22.4623522,
        -83.5798381,
        22.8173087
      ]
    },
    {
      "id": 17299,
      "name": "Sola",
      "names": {
        "zh": "索拉",
        "ja": "クサネム",
        "th": "รัชทายาท"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.882082249999996,
        "lng": 5.356254245388384
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.0076644,
        58.7821535,
        5.7029903,
        58.9820906
      ]
    },
    {
      "id": 17305,
      "name": "Sens",
      "names": {
        "zh": "意义",
        "th": "ซองส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1978559,
        "lng": 3.282606
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.2597586,
        48.159611,
        3.3478838,
        48.2299226
      ]
    },
    {
      "id": 17307,
      "name": "Arnstadt",
      "names": {
        "zh": "阿恩施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8349097,
        "lng": 10.9461479
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8704343,
        50.7270316,
        11.051983,
        50.8757225
      ]
    },
    {
      "id": 17308,
      "name": "Gabasumdo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2550067,
        "lng": 100.5765877
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.4165877,
        35.0950067,
        100.7365877,
        35.4150067
      ]
    },
    {
      "id": 17322,
      "name": "Kongsberg",
      "names": {
        "zh": "康斯堡"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6658116,
        "lng": 9.646297530145794
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.6460066,
        59.665617,
        9.6465875,
        59.6660065
      ]
    },
    {
      "id": 17335,
      "name": "Supia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4533321,
        "lng": -75.651965
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7063299,
        5.3737678,
        -75.5846063,
        5.5381879
      ]
    },
    {
      "id": 17336,
      "name": "Argelia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.32689015,
        "lng": -77.26328131893001
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.4033161,
        2.1456839,
        -77.1328863,
        2.5094319
      ]
    },
    {
      "id": 17340,
      "name": "Rambouillet",
      "names": {
        "zh": "朗布依埃",
        "ja": "ランブイエ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6437498,
        "lng": 1.8321042
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.8271042,
        48.6387498,
        1.8371042,
        48.6487498
      ]
    },
    {
      "id": 17342,
      "name": "Nunspeet",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3372909,
        "lng": 5.795636429005031
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6789216,
        52.2671459,
        5.8809514,
        52.4074265
      ]
    },
    {
      "id": 17347,
      "name": "Soke",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7519762,
        "lng": 27.4056294
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.0178378,
        37.5008689,
        27.6654575,
        37.8474976
      ]
    },
    {
      "id": 17355,
      "name": "Hulst",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.331593600000005,
        "lng": 4.087732758731072
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.9243624,
        51.2411134,
        4.2368053,
        51.4220101
      ]
    },
    {
      "id": 17367,
      "name": "Oroshaza",
      "names": {
        "de": "Orosháza"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.567512,
        "lng": 20.646532
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.626532,
        46.547512,
        20.666532,
        46.587512
      ]
    },
    {
      "id": 17373,
      "name": "Siverek",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7540905,
        "lng": 39.3177426
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.8716212,
        37.2212308,
        39.8558939,
        38.0205169
      ]
    },
    {
      "id": 17377,
      "name": "Tongyangdao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.77031,
        "lng": 109.96987
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        109.94987,
        41.75031,
        109.98987,
        41.79031
      ]
    },
    {
      "id": 17388,
      "name": "Ciudad Bolivar",
      "names": {
        "es": "Ciudad Bolívar",
        "zh": "Ciudad玻利亚尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8500342,
        "lng": -76.0208666
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0992104,
        5.753418,
        -75.9012294,
        5.932078
      ]
    },
    {
      "id": 17391,
      "name": "Corlu",
      "names": {
        "zh": "乔尔卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1590875,
        "lng": 27.8041066
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.6127303,
        41.0026102,
        28.02334,
        41.2258017
      ]
    },
    {
      "id": 17396,
      "name": "Abarkuh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.1324814,
        "lng": 53.2803103
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.2046894,
        31.1049558,
        53.3159262,
        31.1473799
      ]
    },
    {
      "id": 17398,
      "name": "Montbeliard",
      "names": {
        "fr": "Montbéliard",
        "zh": "蒙贝利亚尔",
        "de": "Montbéliard",
        "pt": "Montbéliard"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.5106029,
        "lng": 6.8014501
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.7964501,
        47.5056029,
        6.8064501,
        47.5156029
      ]
    },
    {
      "id": 17428,
      "name": "Zempoala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9155478,
        "lng": -98.6685621
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.7085621,
        19.8755478,
        -98.6285621,
        19.9555478
      ]
    },
    {
      "id": 17434,
      "name": "Klosterneuburg",
      "names": {
        "zh": "克洛斯特新堡"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.30499,
        "lng": 16.323756
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.2014817,
        48.2383172,
        16.3631817,
        48.3595434
      ]
    },
    {
      "id": 17435,
      "name": "Cueramaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.626161,
        "lng": -101.672937
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.690135,
        20.610014,
        -101.661505,
        20.634404
      ]
    },
    {
      "id": 17436,
      "name": "Tall Rif`at",
      "names": {
        "es": "Rif tall`at",
        "fr": "Grand rif`at",
        "zh": "高大的rif`at",
        "it": "Alto rif`",
        "id": "Rif`at tinggi",
        "ja": "背の高いrif`at",
        "th": "rif`at สูง",
        "pt": "Alto rif`"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.470267,
        "lng": 37.0954454
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.0554454,
        36.430267,
        37.1354454,
        36.510267
      ]
    },
    {
      "id": 17439,
      "name": "Bakhchysarai",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 44.75525,
        "lng": 33.85782
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 17451,
      "name": "Tire",
      "names": {
        "es": "Neumáticos",
        "fr": "Pneus",
        "zh": "轮胎",
        "de": "Reifen",
        "it": "Pneumatico",
        "id": "Ban",
        "ja": "タイヤ",
        "th": "ยาง",
        "pt": "Pneu"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.086822,
        "lng": 27.7338358
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.4643126,
        37.9491681,
        27.9640721,
        38.2264455
      ]
    },
    {
      "id": 17460,
      "name": "Garmisch-Partenkirchen",
      "names": {
        "zh": "加米施 - 帕腾基兴",
        "ja": "ガルミッシュ-partenkirchen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.4923741,
        "lng": 11.0962815
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8693855,
        47.3933482,
        11.2230507,
        47.5489456
      ]
    },
    {
      "id": 17461,
      "name": "Sibinal",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.13333,
        "lng": -92.05
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.07,
        15.11333,
        -92.03,
        15.15333
      ]
    },
    {
      "id": 17463,
      "name": "Ixchiguan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.163847,
        "lng": -91.9329948
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.9529948,
        15.143847,
        -91.9129948,
        15.183847
      ]
    },
    {
      "id": 17468,
      "name": "Enna",
      "names": {
        "zh": "恩纳",
        "ja": "エンナ",
        "th": "เอนนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.5667573,
        "lng": 14.2807473
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        14.086833,
        37.4242661,
        14.4993267,
        37.6789433
      ]
    },
    {
      "id": 17474,
      "name": "Bergerac",
      "names": {
        "zh": "贝格拉克",
        "ja": "ベルジュラック"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.8531287,
        "lng": 0.4854621
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.4854121,
        44.8530787,
        0.4855121,
        44.8531787
      ]
    },
    {
      "id": 17478,
      "name": "Aalten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.923874,
        "lng": 6.57899
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.553703,
        51.907469,
        6.600368,
        51.932774
      ]
    },
    {
      "id": 17480,
      "name": "Ixtapa",
      "names": {
        "zh": "伊斯塔帕",
        "ja": "イスタパ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8032586,
        "lng": -92.9053697
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.9453697,
        16.7632586,
        -92.8653697,
        16.8432586
      ]
    },
    {
      "id": 17486,
      "name": "San Juan de Uraba",
      "names": {
        "zh": "圣胡安de乌拉巴",
        "ja": "サンファンデウルバ",
        "th": "ซานฮวนเดอปุ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7609887,
        "lng": -76.5283566
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6298628,
        8.6029697,
        -76.4282351,
        8.8193141
      ]
    },
    {
      "id": 17490,
      "name": "Estarreja",
      "names": {
        "zh": "埃斯塔雷雅"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7523494,
        "lng": -8.5703455
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6676153,
        40.6888039,
        -8.5113895,
        40.8337026
      ]
    },
    {
      "id": 17503,
      "name": "Lier",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.84531345,
        "lng": 10.211552530640098
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.0619953,
        59.716095,
        10.3773009,
        59.9745623
      ]
    },
    {
      "id": 17507,
      "name": "Tauramena",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.01831,
        "lng": -72.74919
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.76919,
        4.99831,
        -72.72919,
        5.03831
      ]
    },
    {
      "id": 17508,
      "name": "Vilyeyka",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4914,
        "lng": 26.9111
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.8911,
        54.4714,
        26.9311,
        54.5114
      ]
    },
    {
      "id": 17517,
      "name": "Mayskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.6282481,
        "lng": 44.0518741
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        44.0312505,
        43.5959815,
        44.0926141,
        43.6612979
      ]
    },
    {
      "id": 17520,
      "name": "Ermelo",
      "names": {
        "zh": "埃尔默洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3011045,
        "lng": 5.6144134
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6094134,
        52.2961045,
        5.6194134,
        52.3061045
      ]
    },
    {
      "id": 17521,
      "name": "Vught",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6461639,
        "lng": 5.3082208
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3081708,
        51.6461139,
        5.3082708,
        51.6462139
      ]
    },
    {
      "id": 17522,
      "name": "Werkendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8079227,
        "lng": 4.8987346
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6763043,
        51.7201103,
        4.9384336,
        51.8253774
      ]
    },
    {
      "id": 17524,
      "name": "Szentes",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6524049,
        "lng": 20.2566408
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.1696103,
        46.5756157,
        20.5240106,
        46.8121242
      ]
    },
    {
      "id": 17551,
      "name": "Geldermalsen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.879931,
        "lng": 5.2876361
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2401268,
        51.8580529,
        5.3395515,
        51.8966282
      ]
    },
    {
      "id": 17553,
      "name": "Sabanilla",
      "names": {
        "zh": "萨瓦尼亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2832835,
        "lng": -92.5528522
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.5728522,
        17.2632835,
        -92.5328522,
        17.3032835
      ]
    },
    {
      "id": 17555,
      "name": "Amatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6863348,
        "lng": -100.1877497
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.2277497,
        18.6463348,
        -100.1477497,
        18.7263348
      ]
    },
    {
      "id": 17557,
      "name": "Ruzomberok",
      "names": {
        "fr": "Ružomberok",
        "de": "Ružomberok"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0791601,
        "lng": 19.3047196
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.3046696,
        49.0791101,
        19.3047696,
        49.0792101
      ]
    },
    {
      "id": 17564,
      "name": "Pueblo Bello",
      "names": {
        "zh": "普埃布洛贝洛"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4119057,
        "lng": -73.5909603
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7906249,
        10.3298413,
        -73.4378592,
        10.7416966
      ]
    },
    {
      "id": 17568,
      "name": "Zuitou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.0580579,
        "lng": 107.3182771
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        107.1582771,
        33.8980579,
        107.4782771,
        34.2180579
      ]
    },
    {
      "id": 17573,
      "name": "Marcos Juarez",
      "names": {
        "es": "Marcos Juárez",
        "ja": "マルコスフアレス",
        "th": "มาร์กอส Juarez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.6988228,
        "lng": -62.1066678
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.1116678,
        -32.7038228,
        -62.1016678,
        -32.6938228
      ]
    },
    {
      "id": 17578,
      "name": "Saumur",
      "names": {
        "zh": "索米尔",
        "ja": "ソミュール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2596224,
        "lng": -0.0785177
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.160127,
        47.2122969,
        -0.0083359,
        47.3167396
      ]
    },
    {
      "id": 17588,
      "name": "Chuimatan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7166918,
        "lng": 102.8773176
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.8373176,
        35.6766918,
        102.9173176,
        35.7566918
      ]
    },
    {
      "id": 17591,
      "name": "Puerto Colombia",
      "names": {
        "fr": "Puerto Colombie",
        "zh": "波多哥伦比亚",
        "de": "Puerto Kolumbien",
        "id": "Puerto Kolombia",
        "ja": "プエルトコロンビア",
        "th": "เปอร์โตโคลัมเบีย",
        "pt": "Puerto Colômbia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9891292,
        "lng": -74.955414
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.995414,
        10.9491292,
        -74.915414,
        11.0291292
      ]
    },
    {
      "id": 17593,
      "name": "Lauf",
      "names": {
        "zh": "劳夫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7274356,
        "lng": 10.9232543
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.9032543,
        49.7074356,
        10.9432543,
        49.7474356
      ]
    },
    {
      "id": 17603,
      "name": "Khanabad",
      "names": {
        "zh": "汗阿巴德"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.603113,
        "lng": 69.104049
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        68.9348358,
        36.3009853,
        69.2697008,
        36.8572959
      ]
    },
    {
      "id": 17609,
      "name": "Jaltenco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7527826,
        "lng": -99.0938034
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1138034,
        19.7327826,
        -99.0738034,
        19.7727826
      ]
    },
    {
      "id": 17613,
      "name": "Iglesias",
      "names": {
        "zh": "伊格莱西亚斯",
        "ja": "イグレシアス"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.3105415,
        "lng": 8.5399287
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.5349287,
        39.3055415,
        8.5449287,
        39.3155415
      ]
    },
    {
      "id": 17614,
      "name": "Dongen",
      "names": {
        "zh": "东让"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.623294,
        "lng": 4.935674
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.904279,
        51.607093,
        5.004497,
        51.654609
      ]
    },
    {
      "id": 17620,
      "name": "Brzozow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 49.6950395,
        "lng": 22.0196537
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.9776915,
        49.677049,
        22.0612222,
        49.7184381
      ]
    },
    {
      "id": 17623,
      "name": "Majagua",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.9265102,
        "lng": -78.9984541
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -78.9999604,
        21.9251208,
        -78.9984031,
        21.9270914
      ]
    },
    {
      "id": 17632,
      "name": "Lamego",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.097309249999995,
        "lng": -7.811406723763531
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8465008,
        41.0733743,
        -7.7694377,
        41.1209493
      ]
    },
    {
      "id": 17636,
      "name": "Bafoulabe",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 13.8065,
        "lng": -10.8321
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -10.9321,
        13.7065,
        -10.7321,
        13.9065
      ]
    },
    {
      "id": 17638,
      "name": "Fortul",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.7928481,
        "lng": -71.7743907
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.1264299,
        6.5148958,
        -71.455397,
        6.8743694
      ]
    },
    {
      "id": 17647,
      "name": "Qarah Zia' od Din",
      "names": {
        "es": "Qarah Zia 'od DIN",
        "fr": "Qarah Zia 'Od Din",
        "zh": "Qarah Zia'od Din",
        "de": "QARAH ZIA 'OD DIN",
        "it": "Qarah Zia 'Da Din",
        "id": "Qarah Zia 'Od Din",
        "ja": "カラーズイアの喧騒",
        "th": "Qarah Zia 'OD DIN",
        "pt": "Qarah Zia 'OD DIN"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 38.8915,
        "lng": 45.0255
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.0055,
        38.8715,
        45.0455,
        38.9115
      ]
    },
    {
      "id": 17649,
      "name": "Sliedrecht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8295924,
        "lng": 4.7781059
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7780559,
        51.8295424,
        4.7781559,
        51.8296424
      ]
    },
    {
      "id": 17651,
      "name": "Los Cordobas",
      "names": {
        "fr": "Los Cordoues",
        "ja": "ロスコルドバス",
        "pt": "Los Cordovas"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8922529,
        "lng": -76.3549184
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.4164495,
        8.7185166,
        -76.126378,
        8.9528038
      ]
    },
    {
      "id": 17664,
      "name": "Morales",
      "names": {
        "zh": "莫拉莱斯",
        "ja": "モラレス",
        "th": "โมราเลส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.8438980999999997,
        "lng": -76.84888196874124
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.9167457,
        2.7082825,
        -76.5757335,
        2.9795745
      ]
    },
    {
      "id": 17684,
      "name": "Kiruna",
      "names": {
        "zh": "基律纳",
        "ja": "キルナ"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 67.8677152,
        "lng": 20.1999937
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        20.1949937,
        67.8627152,
        20.2049937,
        67.8727152
      ]
    },
    {
      "id": 17686,
      "name": "Kiskunhalas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.432895,
        "lng": 19.484501
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.452809,
        46.412084,
        19.51268,
        46.454094
      ]
    },
    {
      "id": 17691,
      "name": "Betong",
      "names": {
        "zh": "勿洞",
        "th": "เบตง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 5.7718215,
        "lng": 101.0715158
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.0315158,
        5.7318215,
        101.1115158,
        5.8118215
      ]
    },
    {
      "id": 17693,
      "name": "Lanquin",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.5749771,
        "lng": -89.9807578
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.0695257,
        15.4893572,
        -89.9063226,
        15.6632459
      ]
    },
    {
      "id": 17694,
      "name": "Az Zabadani",
      "names": {
        "pt": "Zabadani az"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.72488,
        "lng": 36.1005
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.0805,
        33.70488,
        36.1205,
        33.74488
      ]
    },
    {
      "id": 17702,
      "name": "Jaszbereny",
      "names": {
        "zh": "亚斯贝雷尼"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5030505,
        "lng": 19.9117887
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.7893786,
        47.3770065,
        20.0263693,
        47.583259
      ]
    },
    {
      "id": 17712,
      "name": "Wassenaar",
      "names": {
        "zh": "瓦森纳"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1425779,
        "lng": 4.4012224
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.2900632,
        52.0947726,
        4.4439394,
        52.1926774
      ]
    },
    {
      "id": 17714,
      "name": "As Sanamayn",
      "names": {
        "es": "Como sanamayn",
        "fr": "Comme Sanamayn",
        "zh": "作为Sanamayn",
        "de": "Als Sanamayn",
        "it": "Come Sanamayn",
        "id": "Sebagai Sanamayn",
        "ja": "サナマインとして",
        "th": "ในฐานะ Sanamayn",
        "pt": "Como Sanamayn"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.07227,
        "lng": 36.18382
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.16382,
        33.05227,
        36.20382,
        33.09227
      ]
    },
    {
      "id": 17718,
      "name": "San Benito Abad",
      "names": {
        "zh": "圣贝尼托阿巴德",
        "id": "Abad san benito",
        "ja": "サンベニートアバド",
        "th": "ซานเบนิโตะบ้า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.92901,
        "lng": -75.02709
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.04709,
        8.90901,
        -75.00709,
        8.94901
      ]
    },
    {
      "id": 17726,
      "name": "San Alberto",
      "names": {
        "zh": "圣阿尔贝托",
        "ja": "サンアルベルト",
        "th": "ซานอัลเบอร์โต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.7611095,
        "lng": -73.3932831
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6693883,
        7.6763931,
        -73.2755306,
        7.9933631
      ]
    },
    {
      "id": 17734,
      "name": "San Antonio del Sur",
      "names": {
        "ja": "サンアントニオデルシュル",
        "th": "ซานอันโตนิโอเดลซูร์"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0573212,
        "lng": -74.8090782
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -75.035332,
        19.9698296,
        -74.6817113,
        20.3457083
      ]
    },
    {
      "id": 17738,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -15.0378018,
        "lng": -72.8031206
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -73.2938004,
        -15.5720044,
        -72.3019332,
        -14.6438741
      ]
    },
    {
      "id": 17747,
      "name": "Heppenheim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6416143,
        "lng": 8.6333327
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.6283327,
        49.6366143,
        8.6383327,
        49.6466143
      ]
    },
    {
      "id": 17772,
      "name": "Oisterwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5823209,
        "lng": 5.1945875
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.1945375,
        51.5822709,
        5.1946375,
        51.5823709
      ]
    },
    {
      "id": 17773,
      "name": "Balboa",
      "names": {
        "zh": "巴波亚",
        "ja": "バルボア"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.042096,
        "lng": -77.2157842
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.3352045,
        1.951473,
        -77.0835813,
        2.2019275
      ]
    },
    {
      "id": 17784,
      "name": "Beuningen",
      "names": {
        "zh": "布宁根"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.859936,
        "lng": 5.761255
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.717626,
        51.832272,
        5.810314,
        51.881119
      ]
    },
    {
      "id": 17788,
      "name": "Sangerhausen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4729797,
        "lng": 11.2981851
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.0710041,
        51.4143535,
        11.3870626,
        51.5986567
      ]
    },
    {
      "id": 17789,
      "name": "Ondokuzmayis",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4936554,
        "lng": 36.0810492
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.9325144,
        41.332979,
        36.1500084,
        41.6287368
      ]
    },
    {
      "id": 17792,
      "name": "Aurillac",
      "names": {
        "ja": "オーリヤック"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.9285441,
        "lng": 2.4433101
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.390278,
        44.8900578,
        2.4928191,
        44.9659968
      ]
    },
    {
      "id": 17793,
      "name": "Topol'cany",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.553433,
        "lng": 18.177085
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 17803,
      "name": "Pensilvania",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3833488,
        "lng": -75.1609683
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.318871,
        5.2669209,
        -75.0350005,
        5.5463275
      ]
    },
    {
      "id": 17806,
      "name": "Jarocin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9683099,
        "lng": 17.4947192
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.4897192,
        51.9633099,
        17.4997192,
        51.9733099
      ]
    },
    {
      "id": 17813,
      "name": "Schleswig",
      "names": {
        "zh": "石勒苏益格",
        "ja": "シュレスヴィヒ",
        "th": "สวิก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.51851,
        "lng": 9.5653284
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.5106085,
        54.4915915,
        9.616047,
        54.5460562
      ]
    },
    {
      "id": 17829,
      "name": "Kluczbork",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.975277,
        "lng": 18.212213
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.061414125,
        50.893621052,
        18.381459408,
        51.060793814
      ]
    },
    {
      "id": 17841,
      "name": "Perevalsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.432572,
        "lng": 38.825311
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.805311,
        48.412572,
        38.845311,
        48.452572
      ]
    },
    {
      "id": 17842,
      "name": "Buesaco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.384101,
        "lng": -77.156619
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.2534351,
        1.1824649,
        -76.9328772,
        1.452826
      ]
    },
    {
      "id": 17843,
      "name": "Montemor-o-Velho",
      "names": {
        "zh": "montemor-o-旧",
        "th": "montemor-o-เวลโย"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.172664,
        "lng": -8.6845418
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7750537,
        40.1121062,
        -8.5475108,
        40.3110445
      ]
    },
    {
      "id": 17847,
      "name": "Helmstedt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2277037,
        "lng": 11.0104702
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.9389195,
        52.1753702,
        11.0451818,
        52.2474791
      ]
    },
    {
      "id": 17850,
      "name": "Semirom",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.4136588,
        "lng": 51.5708254
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.5480308,
        31.3903437,
        51.6057863,
        31.4243018
      ]
    },
    {
      "id": 17851,
      "name": "Colotenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.40533,
        "lng": -91.713994
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.753994,
        15.36533,
        -91.673994,
        15.44533
      ]
    },
    {
      "id": 17855,
      "name": "Elefsina",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.04135,
        "lng": 23.54295
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.52295,
        38.02135,
        23.56295,
        38.06135
      ]
    },
    {
      "id": 17860,
      "name": "Kothen",
      "names": {
        "de": "Köthen",
        "it": "Köthen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.751033,
        "lng": 11.973698
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.8467055,
        51.6887912,
        12.0714042,
        51.7924741
      ]
    },
    {
      "id": 17861,
      "name": "Paz de Ariporo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8815393,
        "lng": -71.892938
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.0481726,
        5.4163667,
        -69.8264866,
        6.1264863
      ]
    },
    {
      "id": 17863,
      "name": "Swiecie",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4119909,
        "lng": 18.4428353
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.4427853,
        53.4119409,
        18.4428853,
        53.4120409
      ]
    },
    {
      "id": 17869,
      "name": "San Lucas Sacatepequez",
      "names": {
        "fr": "San Lucas Sacatepequezez",
        "ja": "サンルーカス神聖ペケツケ",
        "th": "ซานลูคัส SCATATEPEQUEZ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.60972,
        "lng": -90.65694
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.67694,
        14.58972,
        -90.63694,
        14.62972
      ]
    },
    {
      "id": 17874,
      "name": "Khashuri",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9972652,
        "lng": 43.5989395
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        43.5693967,
        41.9769467,
        43.6215228,
        42.0113297
      ]
    },
    {
      "id": 17876,
      "name": "Tavira",
      "names": {
        "zh": "塔维拉",
        "ja": "タヴィラ",
        "th": "ตาวีรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1262493,
        "lng": -7.6499121
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.9151786,
        37.054394,
        -7.5688164,
        37.3869706
      ]
    },
    {
      "id": 17883,
      "name": "Pfaffenhofen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3934171,
        "lng": 11.2058665
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.1858665,
        48.3734171,
        11.2258665,
        48.4134171
      ]
    },
    {
      "id": 17885,
      "name": "Kulmbach",
      "names": {
        "zh": "库尔姆巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1008448,
        "lng": 11.4479149
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.3605048,
        50.0545551,
        11.5047981,
        50.1927917
      ]
    },
    {
      "id": 17898,
      "name": "Mahates",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.234262,
        "lng": -75.186894
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2860172,
        10.0360786,
        -75.0658849,
        10.2998327
      ]
    },
    {
      "id": 17904,
      "name": "Toktogul",
      "names": {
        "zh": "托克托古尔"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 41.8734702,
        "lng": 72.9451052
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        72.9278664,
        41.8553439,
        72.9678301,
        41.892328
      ]
    },
    {
      "id": 17909,
      "name": "Eidsvoll",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.33409,
        "lng": 11.257202
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.227248,
        60.319735,
        11.277943,
        60.345156
      ]
    },
    {
      "id": 17913,
      "name": "Sankt Wendel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.466972,
        "lng": 7.1653135
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.1603135,
        49.461972,
        7.1703135,
        49.471972
      ]
    },
    {
      "id": 17915,
      "name": "Odemira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.59648,
        "lng": -8.638675
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.650284,
        37.592679,
        -8.629365,
        37.601675
      ]
    },
    {
      "id": 17917,
      "name": "Vierzon",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2217489,
        "lng": 2.0691421
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.0009939,
        47.1842193,
        2.1542124,
        47.2858112
      ]
    },
    {
      "id": 17922,
      "name": "Stein",
      "names": {
        "zh": "斯坦",
        "ja": "シュタイン",
        "th": "สไตน์",
        "pt": "Caneca de cerveja"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9732145,
        "lng": 5.760681940061414
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.7196304,
        50.9279279,
        5.7892472,
        51.0191665
      ]
    },
    {
      "id": 17924,
      "name": "Puerto Piritu",
      "names": {
        "ja": "プエルトピリツ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0567223,
        "lng": -65.0382607
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -65.0719786,
        10.0179515,
        -64.9685526,
        10.0906067
      ]
    },
    {
      "id": 17928,
      "name": "Duiven",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9470218,
        "lng": 6.02303247329986
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9657755,
        51.912334,
        6.0688526,
        51.9818593
      ]
    },
    {
      "id": 17938,
      "name": "Diapaga",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.061092,
        "lng": 1.785247
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        1.7806523,
        12.0557592,
        1.7894795,
        12.0652298
      ]
    },
    {
      "id": 17949,
      "name": "Salamina",
      "names": {
        "zh": "萨拉米纳"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9654391,
        "lng": 23.493022
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.453022,
        37.9254391,
        23.533022,
        38.0054391
      ]
    },
    {
      "id": 17951,
      "name": "Istmina",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.16054,
        "lng": -76.68397
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.70397,
        5.14054,
        -76.66397,
        5.18054
      ]
    },
    {
      "id": 17957,
      "name": "Marmara Ereglisi",
      "names": {
        "zh": "马尔马拉ereglisi",
        "ja": "マルマラエレグリジ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.97003,
        "lng": 27.95528
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.93528,
        40.95003,
        27.97528,
        40.99003
      ]
    },
    {
      "id": 17963,
      "name": "San Juan Ixcoy",
      "names": {
        "zh": "圣胡安IXCOY",
        "ja": "サンファンIXCOY",
        "th": "ซานฮวน ixcoy"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.5999144,
        "lng": -91.4467941
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.4867941,
        15.5599144,
        -91.4067941,
        15.6399144
      ]
    },
    {
      "id": 17964,
      "name": "Tholen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.57085015,
        "lng": 4.076142821673509
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.9247606,
        51.4795553,
        4.2427862,
        51.6607643
      ]
    },
    {
      "id": 17968,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.918082250000001,
        "lng": -84.1915710101147
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -84.2437089,
        9.8633314,
        -84.1469518,
        9.9732167
      ]
    },
    {
      "id": 17969,
      "name": "Dolores",
      "names": {
        "zh": "多洛雷斯",
        "ja": "ドロレス",
        "th": "โดโลเรส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.5300696,
        "lng": -58.2170674
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.2344099,
        -33.5494994,
        -58.1991635,
        -33.5168661
      ]
    },
    {
      "id": 17985,
      "name": "Lourinha",
      "names": {
        "zh": "洛里尼扬",
        "pt": "Lourinhã"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2433932,
        "lng": -9.3119326
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.3516073,
        39.1744987,
        -9.1758703,
        39.3177287
      ]
    },
    {
      "id": 17988,
      "name": "San Martin",
      "names": {
        "es": "San Martín",
        "zh": "圣马丁",
        "ja": "サンマーティン",
        "th": "ซานมาร์ติน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 18.0423736,
        "lng": -63.0549948
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -63.2688117,
        17.806209,
        -62.949445,
        18.0639862
      ]
    },
    {
      "id": 17989,
      "name": "Etampes",
      "names": {
        "zh": "埃唐普"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4344621,
        "lng": 2.1614464
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.071411,
        48.3823175,
        2.233903,
        48.4632904
      ]
    },
    {
      "id": 17990,
      "name": "Wagrowiec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.802705,
        "lng": 17.201283
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.1583,
        52.786908,
        17.236927,
        52.8376
      ]
    },
    {
      "id": 17994,
      "name": "Santa Maria Colotepec",
      "names": {
        "zh": "圣玛丽亚·柯敦普克",
        "ja": "サンタマリアコロテペック"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.897195,
        "lng": -96.937385
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.943924,
        15.894364,
        -96.930612,
        15.899568
      ]
    },
    {
      "id": 18004,
      "name": "San Lucas Toliman",
      "names": {
        "zh": "圣卢卡斯托里曼",
        "ja": "サンルーカストリマー",
        "th": "ซานลูคัสโทลิมาน"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.63333,
        "lng": -91.13333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.15333,
        14.61333,
        -91.11333,
        14.65333
      ]
    },
    {
      "id": 18008,
      "name": "Santa Lucia La Reforma",
      "names": {
        "es": "Santa Lucía La Reforma",
        "zh": "圣卢西亚洛杉矶改革机",
        "ja": "サンタルシアラLe Reforma"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.12833,
        "lng": -91.23556
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.25556,
        15.10833,
        -91.21556,
        15.14833
      ]
    },
    {
      "id": 18014,
      "name": "Yany Kapu",
      "names": {
        "ja": "yach kapu"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 45.95547,
        "lng": 33.79261
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 18018,
      "name": "Luleburgaz",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4041358,
        "lng": 27.3554651
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.1838997,
        41.2199858,
        27.6511931,
        41.5753657
      ]
    },
    {
      "id": 18021,
      "name": "Nechi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.0938077,
        "lng": -74.7751988
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.952473,
        7.7679272,
        -74.5692839,
        8.1997608
      ]
    },
    {
      "id": 18022,
      "name": "Huejotzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.159237,
        "lng": -98.4069843
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4469843,
        19.119237,
        -98.3669843,
        19.199237
      ]
    },
    {
      "id": 18023,
      "name": "Ceylanpinar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.84722,
        "lng": 40.05
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.03,
        36.82722,
        40.07,
        36.86722
      ]
    },
    {
      "id": 18028,
      "name": "Batabano",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7177587,
        "lng": -82.2879905
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.4094708,
        22.6752592,
        -82.195536,
        22.8150619
      ]
    },
    {
      "id": 18040,
      "name": "Gross-Gerau",
      "names": {
        "es": "Bruto-gerau",
        "fr": "Brut-gerau",
        "zh": "总-gerau",
        "de": "Groß-Gerau",
        "ja": "総-gerau",
        "th": "รวม-gerau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.918733,
        "lng": 8.484752
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.404588,
        49.862883,
        8.55084,
        49.965787
      ]
    },
    {
      "id": 18054,
      "name": "Haisyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.81143,
        "lng": 29.38977
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.36977,
        48.79143,
        29.40977,
        48.83143
      ]
    },
    {
      "id": 18060,
      "name": "Pinillos",
      "names": {
        "zh": "皮尼略斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9153047,
        "lng": -74.46215
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.50215,
        8.8753047,
        -74.42215,
        8.9553047
      ]
    },
    {
      "id": 18066,
      "name": "Saintes",
      "names": {
        "zh": "桑特",
        "th": "แซงต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.74724365,
        "lng": -0.6310324673063297
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.6311939,
        45.7471392,
        -0.6308627,
        45.7473426
      ]
    },
    {
      "id": 18067,
      "name": "Libourne",
      "names": {
        "ja": "リブルヌ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.9158339,
        "lng": -0.2359325
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.2359825,
        44.9157839,
        -0.2358825,
        44.9158839
      ]
    },
    {
      "id": 18076,
      "name": "Yolombo",
      "names": {
        "zh": "约隆博"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5981185,
        "lng": -75.0108088
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0508088,
        6.5581185,
        -74.9708088,
        6.6381185
      ]
    },
    {
      "id": 18077,
      "name": "Siofok",
      "names": {
        "zh": "希欧福克",
        "th": "ซิโลฟอก"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9071694,
        "lng": 18.0541598
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.9707043,
        46.8208235,
        18.1740343,
        46.9700702
      ]
    },
    {
      "id": 18087,
      "name": "Rottweil",
      "names": {
        "zh": "罗特韦尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.165531,
        "lng": 8.6251283
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.545795,
        48.1177086,
        8.744275,
        48.2226334
      ]
    },
    {
      "id": 18095,
      "name": "Walcz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.269745,
        "lng": 16.4670655
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.3721973,
        53.2385211,
        16.5267977,
        53.3067159
      ]
    },
    {
      "id": 18097,
      "name": "Vynohradiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.140417,
        "lng": 23.0360182
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        22.9864785,
        48.1094801,
        23.1077242,
        48.1903355
      ]
    },
    {
      "id": 18099,
      "name": "Kazincbarcika",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.252733,
        "lng": 20.622719
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.593506,
        48.229457,
        20.643791,
        48.267158
      ]
    },
    {
      "id": 18109,
      "name": "Jalpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4807308,
        "lng": -97.9431398
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.9631398,
        20.4607308,
        -97.9231398,
        20.5007308
      ]
    },
    {
      "id": 18110,
      "name": "Tougue",
      "names": {
        "zh": "图盖"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.4394566,
        "lng": -11.6629484
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -11.7029484,
        11.3994566,
        -11.6229484,
        11.4794566
      ]
    },
    {
      "id": 18117,
      "name": "Oud-Beijerland",
      "names": {
        "zh": "旧拜耶兰",
        "ja": "ウード-beijerland",
        "th": "อู๊ด-beijerland"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8214436,
        "lng": 4.4164728
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.3787771,
        51.7789865,
        4.4521636,
        51.8370461
      ]
    },
    {
      "id": 18124,
      "name": "Solano",
      "names": {
        "zh": "索拉诺"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.2407577,
        "lng": -72.52169776875364
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5163138,
        -0.7065597,
        -71.2530971,
        1.1882977
      ]
    },
    {
      "id": 18133,
      "name": "Maaseik",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.099954,
        "lng": 5.780866
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.742271,
        51.087899,
        5.815235,
        51.11697
      ]
    },
    {
      "id": 18137,
      "name": "Baarn",
      "names": {
        "zh": "巴伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2080875,
        "lng": 5.2817232
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2767232,
        52.2030875,
        5.2867232,
        52.2130875
      ]
    },
    {
      "id": 18149,
      "name": "Limonar",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9533539,
        "lng": -81.4109306
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -81.7066366,
        22.8248515,
        -81.3002937,
        23.0267727
      ]
    },
    {
      "id": 18150,
      "name": "Sandona",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2863843,
        "lng": -77.4710722
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.5146577,
        1.2277932,
        -77.3748824,
        1.37032
      ]
    },
    {
      "id": 18151,
      "name": "Vistahermosa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 19.49,
        "lng": -97.89
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -97.89005,
        19.48995,
        -97.88995,
        19.49005
      ]
    },
    {
      "id": 18153,
      "name": "Sabanagrande",
      "names": {
        "zh": "萨瓦纳格兰德"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7895725,
        "lng": -74.7559172
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7959172,
        10.7495725,
        -74.7159172,
        10.8295725
      ]
    },
    {
      "id": 18154,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.631341800000001,
        "lng": -84.06729890556566
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -84.5926798,
        9.074894,
        -83.429378,
        10.1878506
      ]
    },
    {
      "id": 18155,
      "name": "Samtredia",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.1645985,
        "lng": 42.3460344
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        42.2890155,
        42.1190173,
        42.3991032,
        42.1919721
      ]
    },
    {
      "id": 18161,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9737744,
        "lng": -75.3613093
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4275839,
        5.8576303,
        -75.2697132,
        6.0453384
      ]
    },
    {
      "id": 18164,
      "name": "Saydnaya",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.699493,
        "lng": 36.374702
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.354702,
        33.679493,
        36.394702,
        33.719493
      ]
    },
    {
      "id": 18168,
      "name": "Albergaria-a-Velha",
      "names": {
        "zh": "阿尔贝加里亚-一个-旧",
        "ja": "albergaria・ア・velha",
        "th": "albergaria เป็น-velha"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6906888,
        "lng": -8.4792205
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6324333,
        40.608067,
        -8.4078926,
        40.7808392
      ]
    },
    {
      "id": 18169,
      "name": "Cuijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.729939,
        "lng": 5.8800458
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8415809,
        51.7055733,
        5.8975988,
        51.7498691
      ]
    },
    {
      "id": 18174,
      "name": "La Blanca",
      "names": {
        "ja": "ラブランカ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5764398,
        "lng": -92.1420532
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.1820532,
        14.5364398,
        -92.1020532,
        14.6164398
      ]
    },
    {
      "id": 18178,
      "name": "Aksehir",
      "names": {
        "zh": "阿克谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3588503,
        "lng": 31.4201959
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.2376994,
        38.1879515,
        31.7118567,
        38.5682699
      ]
    },
    {
      "id": 18188,
      "name": "Choconta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1459409,
        "lng": -73.6839512
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7726583,
        4.9890283,
        -73.5359319,
        5.2426715
      ]
    },
    {
      "id": 18207,
      "name": "Laon",
      "names": {
        "zh": "拉昂"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.564665,
        "lng": 3.620686
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.5556941,
        49.5285706,
        3.6698302,
        49.6137883
      ]
    },
    {
      "id": 18228,
      "name": "Koscierzyna",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.12076125,
        "lng": 17.984842880318006
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.9432614,
        54.0981235,
        18.0260036,
        54.1434377
      ]
    },
    {
      "id": 18229,
      "name": "Pezinok",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.2824621,
        "lng": 17.2703826
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.2653826,
        48.2774621,
        17.2753826,
        48.2874621
      ]
    },
    {
      "id": 18230,
      "name": "Muret",
      "names": {
        "zh": "米雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.4599858,
        "lng": 1.3262332
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.2227043,
        43.3892686,
        1.3748077,
        43.4922004
      ]
    },
    {
      "id": 18232,
      "name": "Sastamala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.34018035,
        "lng": 22.90925209730254
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.9083518,
        61.339695,
        22.9101524,
        61.3406802
      ]
    },
    {
      "id": 18234,
      "name": "Frederikshavn",
      "names": {},
      "countryCode": "DK",
      "latLng": {
        "lat": 57.4352528,
        "lng": 10.5402375
      },
      "country": "Denmark",
      "importance": 2,
      "bbox": [
        10.5401875,
        57.4352028,
        10.5402875,
        57.4353028
      ]
    },
    {
      "id": 18237,
      "name": "Solin",
      "names": {
        "zh": "索林"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.5442913,
        "lng": 16.4853016
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.4134992,
        43.520148,
        16.5546558,
        43.5906256
      ]
    },
    {
      "id": 18243,
      "name": "Shaqlawah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.41134,
        "lng": 44.35008
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        44.33008,
        36.39134,
        44.37008,
        36.43134
      ]
    },
    {
      "id": 18244,
      "name": "Bad Oldesloe",
      "names": {
        "es": "Malo oledesloe",
        "fr": "Mauvais ansLoe",
        "zh": "坏老人",
        "id": "Oldesloe buruk",
        "ja": "悪い昔ながら",
        "pt": "Mau oldesloe"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.8096562,
        "lng": 10.3733692
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2959666,
        53.7692381,
        10.4365432,
        53.8652166
      ]
    },
    {
      "id": 18245,
      "name": "Morozovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.3497895,
        "lng": 41.8388504
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        41.8388004,
        48.3497395,
        41.8389004,
        48.3498395
      ]
    },
    {
      "id": 18249,
      "name": "Steenbergen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.603292100000004,
        "lng": 4.309251716161476
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.1901242,
        51.5437214,
        4.4439993,
        51.6629791
      ]
    },
    {
      "id": 18251,
      "name": "Socorro",
      "names": {
        "zh": "索科罗"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.470518,
        "lng": -73.2623187
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.3165618,
        6.4059887,
        -73.1757343,
        6.525785
      ]
    },
    {
      "id": 18257,
      "name": "Santa Maria Tonameca",
      "names": {
        "ja": "サンタマリア砺波市"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.745833,
        "lng": -96.547222
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.587222,
        15.705833,
        -96.507222,
        15.785833
      ]
    },
    {
      "id": 18258,
      "name": "Raahe",
      "names": {
        "zh": "拉赫"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.6832343,
        "lng": 24.4898153
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.4897653,
        64.6831843,
        24.4898653,
        64.6832843
      ]
    },
    {
      "id": 18262,
      "name": "Gyal",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3845453,
        "lng": 19.2173066
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.179723,
        47.3354977,
        19.2612609,
        47.4031241
      ]
    },
    {
      "id": 18278,
      "name": "Wolfsberg",
      "names": {
        "zh": "沃尔夫斯贝格"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.8390583,
        "lng": 14.8452061
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.6407414,
        46.7754049,
        15.0156057,
        46.9515427
      ]
    },
    {
      "id": 18279,
      "name": "Saky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.1319466,
        "lng": 33.6001281
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.5015973,
        45.0993202,
        33.63291,
        45.1471083
      ]
    },
    {
      "id": 18290,
      "name": "Krems an der Donau",
      "names": {
        "ja": "クレムan der Donau"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.421542,
        "lng": 15.601208
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.525033,
        48.368312,
        15.728625,
        48.451238
      ]
    },
    {
      "id": 18291,
      "name": "Whitehorse",
      "names": {
        "zh": "白马",
        "ja": "ホワイトホース",
        "th": "ไวท์ฮอร์ส"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 60.721571,
        "lng": -135.054932
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -135.2787244,
        60.5536407,
        -134.8567287,
        60.8461886
      ]
    },
    {
      "id": 18292,
      "name": "Bialogard",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0064367,
        "lng": 15.9870974
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.9160514,
        53.9740984,
        16.0352058,
        54.0540045
      ]
    },
    {
      "id": 18297,
      "name": "Ma`bar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.7939702,
        "lng": 44.2949866
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.2756835,
        14.7796958,
        44.3157499,
        14.8092107
      ]
    },
    {
      "id": 18312,
      "name": "Sultepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.86043,
        "lng": -99.967669
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.9771010881,
        18.8530432543,
        -99.9554253042,
        18.8725395172
      ]
    },
    {
      "id": 18314,
      "name": "Sandomierz",
      "names": {
        "zh": "桑多梅日"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.6793066,
        "lng": 21.7495055
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.7003733,
        50.6415935,
        21.7938202,
        50.717569
      ]
    },
    {
      "id": 18333,
      "name": "Madaoua",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 14.073,
        "lng": 5.96
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        5.94,
        14.053,
        5.98,
        14.093
      ]
    },
    {
      "id": 18337,
      "name": "Olpe",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.029482,
        "lng": 7.8435278
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.8040829,
        50.98689,
        7.9741967,
        51.0878047
      ]
    },
    {
      "id": 18346,
      "name": "Rishton",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.35667,
        "lng": 71.28472
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        71.26472,
        40.33667,
        71.30472,
        40.37667
      ]
    },
    {
      "id": 18350,
      "name": "San Martin Sacatepequez",
      "names": {
        "fr": "San Martin Sacatepequezez",
        "zh": "圣马丁萨豆豆",
        "id": "San Martin Sacatepequezz"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.823616,
        "lng": -91.6426288
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.7335506,
        14.7195163,
        -91.5824357,
        14.8479813
      ]
    },
    {
      "id": 18356,
      "name": "Trebisov",
      "names": {
        "it": "Trebišov"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6326185,
        "lng": 21.711088
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.706088,
        48.6276185,
        21.716088,
        48.6376185
      ]
    },
    {
      "id": 18365,
      "name": "Wil",
      "names": {
        "zh": "维尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.465266,
        "lng": 9.0415478
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.0035809,
        47.446234,
        9.0977203,
        47.4957928
      ]
    },
    {
      "id": 18373,
      "name": "Pavlovsk",
      "names": {
        "zh": "巴甫洛夫斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.4520214,
        "lng": 40.1399663
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.076693,
        50.4202482,
        40.1680813,
        50.4708859
      ]
    },
    {
      "id": 18375,
      "name": "Cabrican",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.074704,
        "lng": -91.648445
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.7020482,
        15.0562063,
        -91.5859066,
        15.1532584
      ]
    },
    {
      "id": 18377,
      "name": "Leoben",
      "names": {
        "zh": "莱奥本"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.3805128,
        "lng": 15.0947756
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.0271821,
        47.280428,
        15.232296,
        47.424606
      ]
    },
    {
      "id": 18387,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 9.3553005,
        "lng": -79.8974085
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -80.0574085,
        9.1953005,
        -79.7374085,
        9.5153005
      ]
    },
    {
      "id": 18390,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 14.150152349999999,
        "lng": -90.35088183533747
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -90.6739793,
        13.795392,
        -90.0469206,
        14.5051469
      ]
    },
    {
      "id": 18405,
      "name": "Delfzijl",
      "names": {
        "zh": "代尔夫宰尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3325644,
        "lng": 6.9135144
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.8816589,
        53.306106,
        7.1012489,
        53.4594283
      ]
    },
    {
      "id": 18408,
      "name": "Lincoln",
      "names": {
        "zh": "林肯",
        "ja": "リンカーン",
        "th": "ลิงคอล์น"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 33.7876673,
        "lng": -82.4508165
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -82.645606,
        33.637012,
        -82.2183661,
        33.984653
      ]
    },
    {
      "id": 18409,
      "name": "Harstad",
      "names": {
        "zh": "哈尔斯塔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 68.799943,
        "lng": 16.5440322
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        16.0523138,
        68.5503647,
        16.9458139,
        69.3125978
      ]
    },
    {
      "id": 18414,
      "name": "Raisio",
      "names": {
        "zh": "赖西奥"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.485513,
        "lng": 22.1692664
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.0672009,
        60.4478627,
        22.2493955,
        60.5308431
      ]
    },
    {
      "id": 18421,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6148734,
        "lng": -72.9252395
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0194861,
        4.2859966,
        -72.612917,
        4.7185076
      ]
    },
    {
      "id": 18424,
      "name": "Ziketan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5867677,
        "lng": 99.9883962
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.8283962,
        35.4267677,
        100.1483962,
        35.7467677
      ]
    },
    {
      "id": 18428,
      "name": "Seia",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4200998,
        "lng": -7.7028054
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8492267,
        40.2299187,
        -7.5834325,
        40.5355169
      ]
    },
    {
      "id": 18433,
      "name": "Algeciras",
      "names": {
        "zh": "阿尔赫西拉斯",
        "ja": "アルヘシラス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5246661,
        "lng": -75.316688
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.325613,
        2.5099269,
        -75.3071487,
        2.5292523
      ]
    },
    {
      "id": 18435,
      "name": "Tamahu",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3074637,
        "lng": -90.2341268
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.2541268,
        15.2874637,
        -90.2141268,
        15.3274637
      ]
    },
    {
      "id": 18440,
      "name": "Cadca",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.4375146,
        "lng": 18.7896041
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.322605,
        49.298141,
        19.160534,
        49.519946
      ]
    },
    {
      "id": 18445,
      "name": "Monte Cristi",
      "names": {
        "zh": "蒙特克里斯蒂",
        "ja": "モンテクリスティ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.848379,
        "lng": -71.6456653
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -71.8056653,
        19.688379,
        -71.4856653,
        20.008379
      ]
    },
    {
      "id": 18452,
      "name": "Calamar",
      "names": {
        "zh": "卡拉马尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.2526122,
        "lng": -74.9146691
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0926681,
        10.1015032,
        -74.9083933,
        10.3657713
      ]
    },
    {
      "id": 18461,
      "name": "Takhli",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.263154,
        "lng": 100.347874
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.298611275,
        15.185046495,
        100.396880606,
        15.3303124163
      ]
    },
    {
      "id": 18463,
      "name": "Shangtianba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.03123,
        "lng": 103.86183
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.84183,
        28.01123,
        103.88183,
        28.05123
      ]
    },
    {
      "id": 18466,
      "name": "Heiloo",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6000225,
        "lng": 4.7011338
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6961338,
        52.5950225,
        4.7061338,
        52.6050225
      ]
    },
    {
      "id": 18477,
      "name": "Rochefort",
      "names": {
        "zh": "罗什福尔",
        "th": "เช"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.9366592,
        "lng": -0.9616399
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.007735,
        45.915287,
        -0.9399516,
        45.9761549
      ]
    },
    {
      "id": 18483,
      "name": "Meiningen",
      "names": {
        "zh": "迈宁根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.56761,
        "lng": 10.4153029
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2640022,
        50.4609743,
        10.4685784,
        50.6495249
      ]
    },
    {
      "id": 18491,
      "name": "Cartaxo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.1619462,
        "lng": -8.7863222
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9131247,
        39.0582864,
        -8.682849,
        39.2117474
      ]
    },
    {
      "id": 18495,
      "name": "Wierden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3509555,
        "lng": 6.5843459
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.5842959,
        52.3509055,
        6.5843959,
        52.3510055
      ]
    },
    {
      "id": 18508,
      "name": "Nittedal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.08045425,
        "lng": 10.856610881659574
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.6751916,
        59.9728646,
        10.9959521,
        60.1880187
      ]
    },
    {
      "id": 18509,
      "name": "Zernograd",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.844845,
        "lng": 40.304813
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.2714813,
        46.8288427,
        40.342093,
        46.8696255
      ]
    },
    {
      "id": 18510,
      "name": "Valdivia",
      "names": {
        "zh": "瓦尔迪维亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.165519,
        "lng": -75.438614
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5301551,
        7.0502079,
        -75.274466,
        7.4389114
      ]
    },
    {
      "id": 18514,
      "name": "La Huerta",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4822921,
        "lng": -104.6408128
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.6808128,
        19.4422921,
        -104.6008128,
        19.5222921
      ]
    },
    {
      "id": 18517,
      "name": "El Retorno",
      "names": {
        "fr": "El revenir",
        "it": "El Retorner",
        "ja": "エルレトルノ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.3305016,
        "lng": -72.6264183
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.9948163,
        1.6678219,
        -69.9945271,
        2.4257087
      ]
    },
    {
      "id": 18531,
      "name": "Waldshut-Tiengen",
      "names": {
        "zh": "瓦尔茨胡特-tiengen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6203838,
        "lng": 8.2160637
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.2160137,
        47.6203338,
        8.2161137,
        47.6204338
      ]
    },
    {
      "id": 18538,
      "name": "Mahdishahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.71071,
        "lng": 53.35394
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.33394,
        35.69071,
        53.37394,
        35.73071
      ]
    },
    {
      "id": 18543,
      "name": "Haaksbergen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1564581,
        "lng": 6.740239
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.6243474,
        52.1180695,
        6.8897503,
        52.2063314
      ]
    },
    {
      "id": 18547,
      "name": "Pyrgos",
      "names": {
        "zh": "皮尔戈斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.6722103,
        "lng": 21.4392124
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.2986726,
        37.5407459,
        21.696119,
        37.8351584
      ]
    },
    {
      "id": 18549,
      "name": "Xinxing",
      "names": {
        "zh": "新兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.8155808,
        "lng": 130.8677739
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        130.7077739,
        45.6555808,
        131.0277739,
        45.9755808
      ]
    },
    {
      "id": 18554,
      "name": "Putten",
      "names": {
        "zh": "普当"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.257435,
        "lng": 5.609079
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.58651,
        52.249533,
        5.629336,
        52.275655
      ]
    },
    {
      "id": 18557,
      "name": "Senftenberg",
      "names": {
        "zh": "森夫滕贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5191742,
        "lng": 14.0046541
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.9197499,
        51.4279905,
        14.1422984,
        51.5754284
      ]
    },
    {
      "id": 18558,
      "name": "Tocopilla",
      "names": {
        "zh": "托科皮利亚"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -22.0887189,
        "lng": -70.1960657
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.2849841,
        -22.636893,
        -69.7417924,
        -21.4229694
      ]
    },
    {
      "id": 18572,
      "name": "Porto de Mos",
      "names": {
        "ja": "ポルトデモ",
        "th": "ปอร์โตเดอมอส"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6023941,
        "lng": -8.8185271
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9335624,
        39.465251,
        -8.6873804,
        39.6515124
      ]
    },
    {
      "id": 18589,
      "name": "Rathenow",
      "names": {
        "zh": "拉特诺"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6063703,
        "lng": 12.3382259
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.2310775,
        52.5466338,
        12.4309085,
        52.6717786
      ]
    },
    {
      "id": 18590,
      "name": "Dole",
      "names": {
        "zh": "尔",
        "ja": "ドール",
        "th": "เงินที่รัฐบาลแจก"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.0921047,
        "lng": 5.4925463
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.4529405,
        47.02203,
        5.5519576,
        47.1258353
      ]
    },
    {
      "id": 18600,
      "name": "Puerto Guzman",
      "names": {
        "zh": "波多国普国邦",
        "ja": "プエルトグッサン",
        "th": "เปอร์โตสุขสันต์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.96454,
        "lng": -76.40795
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.42795,
        0.94454,
        -76.38795,
        0.98454
      ]
    },
    {
      "id": 18606,
      "name": "La Montanita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4817139,
        "lng": -75.4356404
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4850274,
        0.9764022,
        -74.8938066,
        1.7713756
      ]
    },
    {
      "id": 18607,
      "name": "Goirle",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.520987,
        "lng": 5.05845
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.999165,
        51.471108,
        5.105061,
        51.537869
      ]
    },
    {
      "id": 18612,
      "name": "Nueva Paz",
      "names": {
        "ja": "ヌエバPaz"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7623115,
        "lng": -81.7563238
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -81.8737499,
        22.5705825,
        -81.580862,
        22.8579701
      ]
    },
    {
      "id": 18613,
      "name": "Donmatias",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4861528,
        "lng": -75.3950389
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3950889,
        6.4861028,
        -75.3949889,
        6.4862028
      ]
    },
    {
      "id": 18615,
      "name": "Rimavska Sobota",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3833603,
        "lng": 20.0180584
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.728005,
        48.124127,
        20.4205802,
        48.769584
      ]
    },
    {
      "id": 18618,
      "name": "Stjordal",
      "names": {
        "zh": "斯彻达尔",
        "de": "Stjørdal"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 63.4627028,
        "lng": 11.134472299094192
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.6539288,
        63.3262337,
        11.7281708,
        63.600286
      ]
    },
    {
      "id": 18619,
      "name": "Saint-Laurent-du-Maroni",
      "names": {
        "zh": "圣-·罗兰-du-马罗尼",
        "ja": "サン・ローラン-du-maroni",
        "th": "เซนต์โลรองต์-du-maroni"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 5.5,
        "lng": -54.03333
      },
      "country": "French Guiana",
      "importance": 2,
      "bbox": [
        -54.05333,
        5.48,
        -54.01333,
        5.52
      ]
    },
    {
      "id": 18620,
      "name": "Cogua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.06052,
        "lng": -73.97925
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.99925,
        5.04052,
        -73.95925,
        5.08052
      ]
    },
    {
      "id": 18621,
      "name": "Marsella",
      "names": {
        "zh": "马尔塞亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9367579,
        "lng": -75.7390439
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7446653,
        4.9276855,
        -75.7318835,
        4.9455286
      ]
    },
    {
      "id": 18623,
      "name": "Edremit",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.597846,
        "lng": 27.0181836
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.6493438,
        39.5518861,
        27.1130326,
        39.7866502
      ]
    },
    {
      "id": 18632,
      "name": "Sattahip",
      "names": {
        "zh": "梭桃邑",
        "th": "สัตหีบ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.663982,
        "lng": 100.9003752
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.8603752,
        12.623982,
        100.9403752,
        12.703982
      ]
    },
    {
      "id": 18652,
      "name": "Salqin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.13951,
        "lng": 36.45413
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.43413,
        36.11951,
        36.47413,
        36.15951
      ]
    },
    {
      "id": 18657,
      "name": "Wijk bij Duurstede",
      "names": {
        "ja": "Wijk Bij Duustede"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9751858,
        "lng": 5.337435
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2423552,
        51.9549192,
        5.4152773,
        52.0270015
      ]
    },
    {
      "id": 18667,
      "name": "Lisse",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2576098,
        "lng": 4.5630329
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.513523,
        52.2222054,
        4.572749,
        52.2866442
      ]
    },
    {
      "id": 18673,
      "name": "Krychaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.7125,
        "lng": 31.717
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        31.697,
        53.6925,
        31.737,
        53.7325
      ]
    },
    {
      "id": 18674,
      "name": "Uchquduq Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5831694,
        "lng": 64.1942406
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        64.1893145,
        41.5785225,
        64.2012641,
        41.5886343
      ]
    },
    {
      "id": 18676,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 14.9643813,
        "lng": -91.7943267
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -91.9029766,
        14.8988066,
        -91.7649203,
        15.0759693
      ]
    },
    {
      "id": 18681,
      "name": "Velingrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.0283589,
        "lng": 23.9944408
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        23.9544408,
        41.9883589,
        24.0344408,
        42.0683589
      ]
    },
    {
      "id": 18683,
      "name": "Bergama",
      "names": {
        "zh": "贝尔加马"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.12074,
        "lng": 27.18052
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.16052,
        39.10074,
        27.20052,
        39.14074
      ]
    },
    {
      "id": 18696,
      "name": "Suonan",
      "names": {
        "zh": "索南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6650108,
        "lng": 103.3926558
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.3526558,
        35.6250108,
        103.4326558,
        35.7050108
      ]
    },
    {
      "id": 18698,
      "name": "Rioblanco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.5310723,
        "lng": -75.6452086
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0849805,
        3.1696589,
        -75.5774822,
        3.7773352
      ]
    },
    {
      "id": 18711,
      "name": "Sonneberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3554468,
        "lng": 11.1685439
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.1635439,
        50.3504468,
        11.1735439,
        50.3604468
      ]
    },
    {
      "id": 18712,
      "name": "Amstetten",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.1203262,
        "lng": 14.8752424
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.775579,
        48.0541634,
        14.9309674,
        48.1456952
      ]
    },
    {
      "id": 18719,
      "name": "Borne",
      "names": {
        "es": "Transmitidas",
        "fr": "La charge",
        "zh": "承担",
        "de": "Getragen",
        "it": "A carico",
        "id": "Ditanggung",
        "ja": "負担",
        "th": "ที่เกิด",
        "pt": "Suportados"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2986128,
        "lng": 6.7484512
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.7434512,
        52.2936128,
        6.7534512,
        52.3036128
      ]
    },
    {
      "id": 18725,
      "name": "Husum",
      "names": {
        "zh": "胡苏姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.4720689,
        "lng": 9.0560508
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.0510508,
        54.4670689,
        9.0610508,
        54.4770689
      ]
    },
    {
      "id": 18740,
      "name": "Zolochiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8056522,
        "lng": 24.8988858
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.8603129,
        49.7854063,
        24.9239628,
        49.8297389
      ]
    },
    {
      "id": 18744,
      "name": "Kourou",
      "names": {
        "zh": "库鲁"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 5.1579834,
        "lng": -52.6424204
      },
      "country": "French Guiana",
      "importance": 2,
      "bbox": [
        -52.981479,
        4.5060049,
        -52.5612882,
        5.3158628
      ]
    },
    {
      "id": 18747,
      "name": "Hoh Ereg",
      "names": {
        "es": "Hoh enereg",
        "ja": "hoh / erg",
        "th": "hoh eereg"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.09556,
        "lng": 111.44083
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        111.42083,
        41.07556,
        111.46083,
        41.11556
      ]
    },
    {
      "id": 18753,
      "name": "Si Racha",
      "names": {
        "th": "ศรีราชา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.1655011,
        "lng": 100.9256709
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.8856709,
        13.1255011,
        100.9656709,
        13.2055011
      ]
    },
    {
      "id": 18763,
      "name": "Calw",
      "names": {
        "zh": "卡尔夫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7142691,
        "lng": 8.7397624
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.658392,
        48.6479779,
        8.8053722,
        48.7614935
      ]
    },
    {
      "id": 18765,
      "name": "Paraiso",
      "names": {
        "zh": "帕拉伊索",
        "ja": "パライソ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 9.731299199999999,
        "lng": -83.77370719370901
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -83.8947151,
        9.551114,
        -83.6270181,
        9.9078768
      ]
    },
    {
      "id": 18769,
      "name": "El Zulia",
      "names": {
        "ja": "エルズリア"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.93248,
        "lng": -72.60125
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.62125,
        7.91248,
        -72.58125,
        7.95248
      ]
    },
    {
      "id": 18770,
      "name": "Hajduszoboszlo",
      "names": {
        "es": "Hajdúszoboszló",
        "de": "Hajdúszoboszló"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4431291,
        "lng": 21.3902995
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.1066859,
        47.3710206,
        21.5584367,
        47.5489008
      ]
    },
    {
      "id": 18771,
      "name": "Jawor",
      "names": {
        "zh": "亚沃尔"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.0504653,
        "lng": 16.1932008
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.141115,
        51.0359738,
        16.229067,
        51.099396
      ]
    },
    {
      "id": 18774,
      "name": "Hollola",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.9882542,
        "lng": 25.5152983
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.0075258,
        60.8543534,
        25.822195,
        61.1507016
      ]
    },
    {
      "id": 18806,
      "name": "Honda",
      "names": {
        "zh": "本田",
        "ja": "ホンダ",
        "th": "ฮอนด้า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.210419,
        "lng": -74.7361921
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8864386,
        5.0398285,
        -74.7144127,
        5.3194554
      ]
    },
    {
      "id": 18812,
      "name": "Comapa",
      "names": {
        "zh": "科马帕"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.12009655,
        "lng": -89.89741099689365
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.0142215,
        14.0324616,
        -89.8415458,
        14.2077794
      ]
    },
    {
      "id": 18815,
      "name": "Mirandela",
      "names": {
        "zh": "米兰德拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.482063,
        "lng": -7.180471
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.201295,
        41.472495,
        -7.166606,
        41.49768
      ]
    },
    {
      "id": 18818,
      "name": "Starnberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.9986851,
        "lng": 11.3410791
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.2236355,
        47.9729337,
        11.4334957,
        48.039201
      ]
    },
    {
      "id": 18822,
      "name": "Tecolutla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4774371,
        "lng": -97.0084718
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0263803,
        20.4742248,
        -97.0017087,
        20.4943007
      ]
    },
    {
      "id": 18824,
      "name": "Tepalcatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.18658,
        "lng": -102.848165
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.868775,
        19.169686,
        -102.828072,
        19.198418
      ]
    },
    {
      "id": 18825,
      "name": "Saint-Dizier",
      "names": {
        "zh": "圣•狄斯尔",
        "ja": "サン・dizier",
        "th": "เซนต์-dizier"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6371125,
        "lng": 4.9473513
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.873672,
        48.5831616,
        5.0063886,
        48.6805498
      ]
    },
    {
      "id": 18832,
      "name": "Svishtov",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.6158817,
        "lng": 25.3509536
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        25.2042061,
        43.5450131,
        25.4758621,
        43.6556733
      ]
    },
    {
      "id": 18837,
      "name": "Berehove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2050985,
        "lng": 22.6453246
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        22.6103874,
        48.1775032,
        22.6802885,
        48.2401667
      ]
    },
    {
      "id": 18842,
      "name": "Oldebroek",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4507589,
        "lng": 5.9074726
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9074226,
        52.4507089,
        5.9075226,
        52.4508089
      ]
    },
    {
      "id": 18843,
      "name": "Ostrow Mazowiecka",
      "names": {
        "ja": "オストロウマゾウィエッカ",
        "th": "attrow mazowiecka"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.8000429,
        "lng": 21.897638
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.8447535,
        52.7783736,
        21.93743,
        52.8358037
      ]
    },
    {
      "id": 18847,
      "name": "Padre Burgos",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.915937,
        "lng": 121.8149517
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.7749517,
        13.875937,
        121.8549517,
        13.955937
      ]
    },
    {
      "id": 18853,
      "name": "Olopa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.68333,
        "lng": -89.35
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.37,
        14.66333,
        -89.33,
        14.70333
      ]
    },
    {
      "id": 18855,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.631341800000001,
        "lng": -84.06729890556566
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -84.5926798,
        9.074894,
        -83.429378,
        10.1878506
      ]
    },
    {
      "id": 18863,
      "name": "Santa Lucia Utatlan",
      "names": {
        "es": "Santa Lucía Utatlan",
        "zh": "圣卢西亚Utatlan",
        "ja": "サンタルシアユタトラン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.76667,
        "lng": -91.26667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.28667,
        14.74667,
        -91.24667,
        14.78667
      ]
    },
    {
      "id": 18866,
      "name": "Tata",
      "names": {
        "zh": "塔塔",
        "ja": "タタ",
        "th": "ทาทา"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6516214,
        "lng": 18.3282083
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.2306874,
        47.5771006,
        18.4220287,
        47.682889
      ]
    },
    {
      "id": 18870,
      "name": "Mosbach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3522567,
        "lng": 9.1435455
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.1385455,
        49.3472567,
        9.1485455,
        49.3572567
      ]
    },
    {
      "id": 18871,
      "name": "Korbach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.272377,
        "lng": 8.8738
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.727598,
        51.208874,
        8.965325,
        51.333998
      ]
    },
    {
      "id": 18877,
      "name": "Fria",
      "names": {
        "es": "Fría",
        "zh": "弗里亚"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.363036,
        "lng": -13.581185
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -13.741185,
        10.203036,
        -13.421185,
        10.523036
      ]
    },
    {
      "id": 18880,
      "name": "Luninyets",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.25028,
        "lng": 26.79944
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.77944,
        52.23028,
        26.81944,
        52.27028
      ]
    },
    {
      "id": 18888,
      "name": "Salzwedel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8528456,
        "lng": 11.1539699
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.9055358,
        52.7604805,
        11.3447173,
        52.9134005
      ]
    },
    {
      "id": 18891,
      "name": "Lubartow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4660473,
        "lng": 22.6052895
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.569573,
        51.4339267,
        22.6422125,
        51.4828881
      ]
    },
    {
      "id": 18892,
      "name": "Soledad Atzompa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7553352,
        "lng": -97.1514771
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1914771,
        18.7153352,
        -97.1114771,
        18.7953352
      ]
    },
    {
      "id": 18896,
      "name": "Freudenstadt",
      "names": {
        "zh": "弗罗伊登施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4637727,
        "lng": 8.4111727
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.2747709,
        48.4203185,
        8.4911419,
        48.560767
      ]
    },
    {
      "id": 18897,
      "name": "Bauta",
      "names": {
        "zh": "包塔"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9802415,
        "lng": -82.549536
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.5956587,
        22.9222419,
        -82.4359845,
        23.0749706
      ]
    },
    {
      "id": 18898,
      "name": "Silopi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2491577,
        "lng": 42.4707504
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.3111482,
        37.107651,
        42.7366103,
        37.3978463
      ]
    },
    {
      "id": 18900,
      "name": "Abbeville",
      "names": {
        "zh": "阿布维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.1060835,
        "lng": 1.8337029
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.7896024,
        50.0770664,
        1.8742041,
        50.14317
      ]
    },
    {
      "id": 18901,
      "name": "Kreuzlingen",
      "names": {
        "zh": "克罗伊茨林根"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.6464362,
        "lng": 9.1725621
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.140104,
        47.6208412,
        9.2059587,
        47.6603084
      ]
    },
    {
      "id": 18913,
      "name": "Partizanske",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6265223,
        "lng": 18.371762
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.3516812,
        48.6081455,
        18.41184,
        48.6509229
      ]
    },
    {
      "id": 18914,
      "name": "Koziatyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7195867,
        "lng": 28.8354279
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.7963992,
        49.6899922,
        28.8806439,
        49.737918
      ]
    },
    {
      "id": 18918,
      "name": "Buyukcekmece",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.021654,
        "lng": 28.579757
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.3909885,
        40.984499,
        28.6340208,
        41.1296274
      ]
    },
    {
      "id": 18931,
      "name": "Coronel Suarez",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.460599,
        "lng": -61.932056
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.937056,
        -37.465599,
        -61.927056,
        -37.455599
      ]
    },
    {
      "id": 18957,
      "name": "Canalete",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7858918,
        "lng": -76.2399593
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.2799593,
        8.7458918,
        -76.1999593,
        8.8258918
      ]
    },
    {
      "id": 18958,
      "name": "Alcala",
      "names": {
        "es": "Alcalá",
        "zh": "阿尔卡拉",
        "ja": "アルカラ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6748944,
        "lng": -75.781669
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8573878,
        4.6507247,
        -75.707239,
        4.717302
      ]
    },
    {
      "id": 18966,
      "name": "El Paso",
      "names": {
        "ja": "エルパソ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 40.7392018,
        "lng": -89.0164626
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -89.0442311,
        40.7296326,
        -88.972536,
        40.7585386
      ]
    },
    {
      "id": 18968,
      "name": "Hisor",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.532441399999996,
        "lng": 68.55183115059191
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        68.5354326,
        38.5031905,
        68.5826107,
        38.5616142
      ]
    },
    {
      "id": 18977,
      "name": "Ahualulco de Mercado",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.700743,
        "lng": -103.9741457
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.0141457,
        20.660743,
        -103.9341457,
        20.740743
      ]
    },
    {
      "id": 18978,
      "name": "Santo Tomas",
      "names": {
        "ja": "サントトマス",
        "th": "ซานโตโทมัส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.39732875,
        "lng": -82.63909495150969
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -82.6938022,
        8.3295204,
        -82.6221069,
        8.4653854
      ]
    },
    {
      "id": 18982,
      "name": "Megara",
      "names": {
        "zh": "麦加拉",
        "ja": "メガラ",
        "th": "กา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9965887,
        "lng": 23.3445017
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.3045017,
        37.9565887,
        23.3845017,
        38.0365887
      ]
    },
    {
      "id": 18984,
      "name": "Nagykoros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0374257,
        "lng": 19.7863141
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.6243092,
        46.9441495,
        19.9166238,
        47.0943817
      ]
    },
    {
      "id": 18999,
      "name": "San Rafael del Sur",
      "names": {
        "ja": "サンラファエルデルシュール"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.846482,
        "lng": -86.4388639
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.756253,
        11.5745271,
        -86.3259266,
        11.952666
      ]
    },
    {
      "id": 19000,
      "name": "Almeirim",
      "names": {
        "zh": "阿尔梅林"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2091925,
        "lng": -8.6291035
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7117893,
        39.0920108,
        -8.4389221,
        39.243982
      ]
    },
    {
      "id": 19006,
      "name": "Elburg",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4436932,
        "lng": 5.8632947
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8632447,
        52.4436432,
        5.8633447,
        52.4437432
      ]
    },
    {
      "id": 19017,
      "name": "Doctor Mora",
      "names": {
        "es": "Mora mora",
        "fr": "Docteur mora",
        "zh": "莫拉医生",
        "de": "Arzt Mora",
        "id": "Dokter Mora",
        "ja": "モーラドクター",
        "th": "หมอโมรา",
        "pt": "Mora Mora"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.142245,
        "lng": -100.319625
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.33366,
        21.131204,
        -100.306398,
        21.151408
      ]
    },
    {
      "id": 19020,
      "name": "Veinticinco de Mayo",
      "names": {
        "fr": "Veeticinco de Mayo",
        "de": "VEINTICININCO de Mayo"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.4324152,
        "lng": -60.171624
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.2123305,
        -35.4720507,
        -60.0813948,
        -35.4029554
      ]
    },
    {
      "id": 19023,
      "name": "Teorama",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4362865,
        "lng": -73.2876907
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.3276907,
        8.3962865,
        -73.2476907,
        8.4762865
      ]
    },
    {
      "id": 19029,
      "name": "Cuilapan de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.6753256,
        "lng": -99.296519
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2966786,
        17.6749583,
        -99.2963275,
        17.675654
      ]
    },
    {
      "id": 19036,
      "name": "Achkhoy-Martan",
      "names": {
        "zh": "achkhoy-马尔坦"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1926252,
        "lng": 45.2879291
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        45.2507649,
        43.1662959,
        45.3131662,
        43.2240235
      ]
    },
    {
      "id": 19044,
      "name": "Qatana",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4323107,
        "lng": 36.0853964
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.0453964,
        33.3923107,
        36.1253964,
        33.4723107
      ]
    },
    {
      "id": 19045,
      "name": "Dunajska Streda",
      "names": {
        "pt": "Dunajska strede"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 47.9956165,
        "lng": 17.6162934
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.24743,
        47.758985,
        17.888923,
        48.174105
      ]
    },
    {
      "id": 19053,
      "name": "Huitiupan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1712839,
        "lng": -92.6851738
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.7051738,
        17.1512839,
        -92.6651738,
        17.1912839
      ]
    },
    {
      "id": 19057,
      "name": "Epernay",
      "names": {
        "zh": "艾培涅"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.042561,
        "lng": 3.9528655
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.8627709,
        49.0179757,
        3.9936504,
        49.0602842
      ]
    },
    {
      "id": 19059,
      "name": "Hillegom",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.29482605,
        "lng": 4.572971256401671
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5422822,
        52.27124,
        4.6117013,
        52.3183951
      ]
    },
    {
      "id": 19065,
      "name": "Oliveira do Bairro",
      "names": {
        "zh": "Oliveira做贝雷罗",
        "ja": "オリビラはベイロを行います"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.515796,
        "lng": -8.496674
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.509012,
        40.503313,
        -8.471644,
        40.52604
      ]
    },
    {
      "id": 19080,
      "name": "El Tejar",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6474205,
        "lng": -90.7909935
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.8309935,
        14.6074205,
        -90.7509935,
        14.6874205
      ]
    },
    {
      "id": 19083,
      "name": "Palmar de Varela",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.74055,
        "lng": -74.75443
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.77443,
        10.72055,
        -74.73443,
        10.76055
      ]
    },
    {
      "id": 19085,
      "name": "Ozuluama de Mascarenas",
      "names": {
        "th": "Ozuluama de Maskarenas"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.661111,
        "lng": -97.850556
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.890556,
        21.621111,
        -97.810556,
        21.701111
      ]
    },
    {
      "id": 19086,
      "name": "Kolo",
      "names": {
        "zh": "科洛",
        "ja": "コロ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4276417,
        "lng": 19.8296447
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.8096447,
        51.4076417,
        19.8496447,
        51.4476417
      ]
    },
    {
      "id": 19088,
      "name": "Tototlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.540253,
        "lng": -102.792624
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.805751,
        20.528443,
        -102.781408,
        20.551843
      ]
    },
    {
      "id": 19089,
      "name": "Vranov nad Topl'ou",
      "names": {
        "pt": "Vranov nad topl'o"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.890145,
        "lng": 21.674362
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.6738995,
        48.8899085,
        21.674668,
        48.890437
      ]
    },
    {
      "id": 19120,
      "name": "Puerto Concordia",
      "names": {
        "zh": "波多科迪亚",
        "ja": "プエルトコンコルディア",
        "th": "เปอร์โตริโกคอนคอร์เดีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6236384,
        "lng": -72.7585944
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.7985944,
        2.5836384,
        -72.7185944,
        2.6636384
      ]
    },
    {
      "id": 19124,
      "name": "Tamiahua",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.278056,
        "lng": -97.445556
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.485556,
        21.238056,
        -97.405556,
        21.318056
      ]
    },
    {
      "id": 19127,
      "name": "Kilkis",
      "names": {
        "zh": "基尔基斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9935874,
        "lng": 22.8741455
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.7141455,
        40.8335874,
        23.0341455,
        41.1535874
      ]
    },
    {
      "id": 19132,
      "name": "Komlo",
      "names": {
        "zh": "科姆洛"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.1911673,
        "lng": 18.2613778
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.2058676,
        46.1576452,
        18.3233118,
        46.2407199
      ]
    },
    {
      "id": 19134,
      "name": "Baclayon",
      "names": {
        "zh": "巴克拉永"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6224415,
        "lng": 123.9137227
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.9136727,
        9.6223915,
        123.9137727,
        9.6224915
      ]
    },
    {
      "id": 19148,
      "name": "Yuli",
      "names": {
        "zh": "尤利"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.3426164,
        "lng": 86.2577315
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.2339425,
        41.3247884,
        86.2910521,
        41.3791455
      ]
    },
    {
      "id": 19159,
      "name": "Wichian Buri",
      "names": {
        "zh": "威安伯利",
        "id": "Wichan buri",
        "ja": "ウィチアンブリ",
        "th": "วิเชียรบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.6646505,
        "lng": 101.1114493
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.0714493,
        15.6246505,
        101.1514493,
        15.7046505
      ]
    },
    {
      "id": 19160,
      "name": "Achi",
      "names": {
        "zh": "阿智"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5689588,
        "lng": -74.5557643
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5957643,
        8.5289588,
        -74.5157643,
        8.6089588
      ]
    },
    {
      "id": 19163,
      "name": "Gyegu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9495684,
        "lng": 96.6457229
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        95.6811332,
        32.0275264,
        97.7305787,
        33.7636563
      ]
    },
    {
      "id": 19165,
      "name": "Grajewo",
      "names": {
        "zh": "格拉耶沃"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.650103,
        "lng": 22.448183
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.443183,
        53.645103,
        22.453183,
        53.655103
      ]
    },
    {
      "id": 19168,
      "name": "Curumani",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1995316,
        "lng": -73.5440609
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7019588,
        9.0702613,
        -73.2698045,
        9.4113695
      ]
    },
    {
      "id": 19170,
      "name": "Guanajay",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9266328,
        "lng": -82.6887471
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.8240987,
        22.8688579,
        -82.6559139,
        22.9764802
      ]
    },
    {
      "id": 19176,
      "name": "Vale de Cambra",
      "names": {
        "ja": "ヴァレ・ド・カンブラ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.8498115,
        "lng": -8.3939142
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4347459,
        40.7631735,
        -8.2367635,
        40.9036296
      ]
    },
    {
      "id": 19188,
      "name": "Taxisco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.07083,
        "lng": -90.46306
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.48306,
        14.05083,
        -90.44306,
        14.09083
      ]
    },
    {
      "id": 19192,
      "name": "Vagos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.54993,
        "lng": -8.681483
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.690337,
        40.543674,
        -8.673895,
        40.560732
      ]
    },
    {
      "id": 19194,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 16.2528827,
        "lng": -61.5686855
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -61.809764,
        15.8319758,
        -61.0013039,
        16.5144801
      ]
    },
    {
      "id": 19195,
      "name": "Forbach",
      "names": {
        "zh": "福尔巴赫"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1894859,
        "lng": 6.9010985
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.8960985,
        49.1844859,
        6.9060985,
        49.1944859
      ]
    },
    {
      "id": 19197,
      "name": "Luruaco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.60921,
        "lng": -75.14309
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.16309,
        10.58921,
        -75.12309,
        10.62921
      ]
    },
    {
      "id": 19198,
      "name": "Dabeiba",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0015209,
        "lng": -76.2666243
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6182265,
        6.6948074,
        -75.9637295,
        7.1914119
      ]
    },
    {
      "id": 19204,
      "name": "Albufeira",
      "names": {
        "zh": "阿尔布费拉",
        "ja": "アルブフェイラ",
        "th": "อัลบูเฟรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.088241,
        "lng": -8.2526339
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3297222,
        37.0718203,
        -8.1269317,
        37.2172319
      ]
    },
    {
      "id": 19206,
      "name": "La Democracia",
      "names": {
        "fr": "La Democacia",
        "zh": "洛桑民主党",
        "ja": "ラ民主党"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2296204,
        "lng": -90.947895
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.987895,
        14.1896204,
        -90.907895,
        14.2696204
      ]
    },
    {
      "id": 19208,
      "name": "Znamianka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7199609,
        "lng": 32.6659476
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.6124966,
        48.6838809,
        32.7118349,
        48.7400806
      ]
    },
    {
      "id": 19222,
      "name": "San Raimundo",
      "names": {
        "zh": "圣雷姆蒙多",
        "ja": "サンラムンド",
        "th": "ซานไรมรันโด"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3870675,
        "lng": -90.7452625
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.7652625,
        14.3670675,
        -90.7252625,
        14.4070675
      ]
    },
    {
      "id": 19226,
      "name": "Rucphen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5330913,
        "lng": 4.5589604
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4965725,
        51.5018846,
        4.5836196,
        51.5559553
      ]
    },
    {
      "id": 19234,
      "name": "Zacualpa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0266233,
        "lng": -90.8770542
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.9170542,
        14.9866233,
        -90.8370542,
        15.0666233
      ]
    },
    {
      "id": 19236,
      "name": "Thebes",
      "names": {
        "es": "Tebas",
        "fr": "Thèbes",
        "zh": "底比斯",
        "de": "Theben",
        "it": "Tebe",
        "ja": "テーベ",
        "th": "ธีบส์",
        "pt": "Tebas"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.325,
        "lng": 23.31889
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.29889,
        38.305,
        23.33889,
        38.345
      ]
    },
    {
      "id": 19237,
      "name": "Westerstede",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.2575197,
        "lng": 7.9273367
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.7950033,
        53.1652699,
        8.0605045,
        53.3518989
      ]
    },
    {
      "id": 19242,
      "name": "Arcos de Valdevez",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.8471981,
        "lng": -8.417882
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5310085,
        41.7908148,
        -8.1917158,
        42.018683
      ]
    },
    {
      "id": 19243,
      "name": "Kalat",
      "names": {
        "zh": "卡拉特"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.209783,
        "lng": 66.999194
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        66.6593192,
        31.9682498,
        67.3193898,
        32.4732158
      ]
    },
    {
      "id": 19244,
      "name": "Banovici",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.4088812,
        "lng": 18.5292365
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.4892365,
        44.3688812,
        18.5692365,
        44.4488812
      ]
    },
    {
      "id": 19253,
      "name": "Weilheim",
      "names": {
        "zh": "魏尔海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8452213,
        "lng": 11.1430287
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.1380287,
        47.8402213,
        11.1480287,
        47.8502213
      ]
    },
    {
      "id": 19258,
      "name": "Dzialdowo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.2346517,
        "lng": 20.1817841
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.1460548,
        53.21569,
        20.2153446,
        53.2647533
      ]
    },
    {
      "id": 19262,
      "name": "Tunuyan",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -33.5812925,
        "lng": -69.0154258
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -69.0470865,
        -33.590969,
        -69.0008329,
        -33.5511635
      ]
    },
    {
      "id": 19264,
      "name": "Losser",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2620369,
        "lng": 7.0046452
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.9492822,
        52.2357693,
        7.0392437,
        52.2879343
      ]
    },
    {
      "id": 19275,
      "name": "Royken",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.747147,
        "lng": 10.394542
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.377865,
        59.736295,
        10.425807,
        59.750738
      ]
    },
    {
      "id": 19284,
      "name": "Santa Fe de Antioquia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5564008,
        "lng": -75.8277746
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8677746,
        6.5164008,
        -75.7877746,
        6.5964008
      ]
    },
    {
      "id": 19286,
      "name": "Nizip",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0101095,
        "lng": 37.7971978
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.5944102,
        36.8106763,
        38.0080604,
        37.2743986
      ]
    },
    {
      "id": 19305,
      "name": "Svetlyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.67501,
        "lng": 20.13473
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        20.11473,
        54.65501,
        20.15473,
        54.69501
      ]
    },
    {
      "id": 19308,
      "name": "Bad Kissingen",
      "names": {
        "es": "Mal besos",
        "fr": "Mal embrassant",
        "zh": "糟糕的吻",
        "de": "Schlechtes Kissingen",
        "id": "Kissingen Buruk",
        "ja": "悪いキスインゲン",
        "th": "kissingen ที่ไม่ดี",
        "pt": "Beijo ruim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1985698,
        "lng": 10.0746833
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.9673905,
        50.1436082,
        10.1426302,
        50.2514405
      ]
    },
    {
      "id": 19323,
      "name": "Buenavista",
      "names": {
        "zh": "布埃纳维斯塔",
        "ja": "ブエナビスタ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4875315,
        "lng": -73.95630761620356
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.0186203,
        5.4254232,
        -73.9014388,
        5.5498347
      ]
    },
    {
      "id": 19325,
      "name": "Mragowo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.8660707,
        "lng": 21.3045796
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.2637701,
        53.8419865,
        21.3338998,
        53.8948733
      ]
    },
    {
      "id": 19330,
      "name": "Amalfi",
      "names": {
        "zh": "阿马尔菲",
        "ja": "アマルフィ",
        "th": "อมาลฟี"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.9070097,
        "lng": -75.0735908
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1135908,
        6.8670097,
        -75.0335908,
        6.9470097
      ]
    },
    {
      "id": 19333,
      "name": "Manzanares",
      "names": {
        "zh": "曼萨纳雷斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.253198,
        "lng": -75.1544544
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2441982,
        5.1559533,
        -75.0615227,
        5.3095226
      ]
    },
    {
      "id": 19336,
      "name": "Beek en Donk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.533279,
        "lng": 5.630086
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.587108,
        51.518481,
        5.666856,
        51.569679
      ]
    },
    {
      "id": 19337,
      "name": "Lefkada",
      "names": {
        "zh": "莱夫卡达",
        "id": "Lefkas"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.706573399999996,
        "lng": 20.64167791041791
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        20.5409845,
        38.5607257,
        20.7331874,
        38.8524129
      ]
    },
    {
      "id": 19338,
      "name": "Sal'a",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 43.0284702,
        "lng": -0.1195972
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        -0.22392,
        43.0071714,
        -0.1106453,
        43.0525507
      ]
    },
    {
      "id": 19344,
      "name": "El Bosque",
      "names": {
        "ja": "エルボスケ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0247264,
        "lng": -92.74723009318356
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.8254985,
        16.9538253,
        -92.6462781,
        17.0961943
      ]
    },
    {
      "id": 19348,
      "name": "Magdalena",
      "names": {
        "zh": "马格达莱纳",
        "ja": "マグダレナ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.91062025,
        "lng": -104.11585399809384
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.2594927,
        20.8132992,
        -103.8778089,
        21.0079324
      ]
    },
    {
      "id": 19349,
      "name": "Tecali",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4172262,
        "lng": -99.23701272016369
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2370502,
        19.4171861,
        -99.2369752,
        19.4172663
      ]
    },
    {
      "id": 19354,
      "name": "Sondrio",
      "names": {
        "zh": "桑得里奥",
        "ja": "ソンドリオ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.1713553,
        "lng": 9.8734274
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.82409,
        46.1580067,
        9.8987397,
        46.2112926
      ]
    },
    {
      "id": 19355,
      "name": "Baraki Barak",
      "names": {
        "zh": "巴拉克基巴拉克",
        "ja": "バラキバラック",
        "th": "บารากิบาราว"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.967443,
        "lng": 68.949199
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        68.909199,
        33.927443,
        68.989199,
        34.007443
      ]
    },
    {
      "id": 19369,
      "name": "Huangzhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0639624,
        "lng": 112.6814732
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        112.6414732,
        38.0239624,
        112.7214732,
        38.1039624
      ]
    },
    {
      "id": 19371,
      "name": "Lajas",
      "names": {
        "zh": "拉哈斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4157061,
        "lng": -80.2943145
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -80.447346,
        22.320247,
        -80.2198184,
        22.5812136
      ]
    },
    {
      "id": 19379,
      "name": "Goleniow",
      "names": {
        "it": "Goleniów"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.5594624,
        "lng": 14.839589
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.834589,
        53.5544624,
        14.844589,
        53.5644624
      ]
    },
    {
      "id": 19382,
      "name": "Lempaala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3144095,
        "lng": 23.7550695
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.7500695,
        61.3094095,
        23.7600695,
        61.3194095
      ]
    },
    {
      "id": 19384,
      "name": "Yahualica de Gonzalez Gallo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1797736,
        "lng": -102.8858472
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.9258472,
        21.1397736,
        -102.8458472,
        21.2197736
      ]
    },
    {
      "id": 19389,
      "name": "Stadthagen",
      "names": {
        "zh": "斯塔特哈根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3289688,
        "lng": 9.2053496
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.1281476,
        52.2599725,
        9.2879218,
        52.3507354
      ]
    },
    {
      "id": 19390,
      "name": "Burg",
      "names": {
        "zh": "伯格",
        "id": "Kota",
        "ja": "ブルク",
        "th": "เมือง"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2705632,
        "lng": 11.8588198
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.7249361,
        52.2120614,
        11.9868403,
        52.3721757
      ]
    },
    {
      "id": 19394,
      "name": "Mako",
      "names": {
        "zh": "马可",
        "ja": "真子"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2150438,
        "lng": 20.4751022
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.3531841,
        46.1877237,
        20.6823538,
        46.3915691
      ]
    },
    {
      "id": 19396,
      "name": "Erzin",
      "names": {
        "zh": "埃尔津"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9558796,
        "lng": 36.199108
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.9843527,
        36.8790785,
        36.3349941,
        37.0340084
      ]
    },
    {
      "id": 19398,
      "name": "Puente Nacional",
      "names": {
        "ja": "プエンテネシャル"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3275937,
        "lng": -96.4815167
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.5215167,
        19.2875937,
        -96.4415167,
        19.3675937
      ]
    },
    {
      "id": 19401,
      "name": "Sardinata",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.084097,
        "lng": -72.8000665
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.9950185,
        7.9941421,
        -72.5961751,
        8.5234267
      ]
    },
    {
      "id": 19405,
      "name": "Polatli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5852226,
        "lng": 32.1436995
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.7646519,
        38.9472361,
        32.4336146,
        39.9574741
      ]
    },
    {
      "id": 19427,
      "name": "El Progreso",
      "names": {
        "pt": "El progresso"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.910043649999999,
        "lng": -90.06827279213266
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.4051786,
        14.6673431,
        -89.7929818,
        15.1527898
      ]
    },
    {
      "id": 19434,
      "name": "Heide",
      "names": {
        "zh": "海德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.1948848,
        "lng": 9.0928251
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.0526128,
        54.161027,
        9.213009,
        54.2182805
      ]
    },
    {
      "id": 19440,
      "name": "Mutata",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.2440678,
        "lng": -76.436542
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6467512,
        7.132796,
        -76.2180893,
        7.5556495
      ]
    },
    {
      "id": 19444,
      "name": "Nadvirna",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.62422025,
        "lng": 24.584747030749252
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.5846081,
        48.6240089,
        24.5849126,
        48.6243862
      ]
    },
    {
      "id": 19450,
      "name": "Auch",
      "names": {
        "zh": "奥赫",
        "ja": "オーシュ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6463558,
        "lng": 0.5850507
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.4860406,
        43.6171942,
        0.6470905,
        43.7077242
      ]
    },
    {
      "id": 19451,
      "name": "Maribojoc",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7409309,
        "lng": 123.8429701
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.6556913,
        9.6799853,
        123.9063556,
        9.8060403
      ]
    },
    {
      "id": 19453,
      "name": "Akcakoca",
      "names": {
        "zh": "阿克恰科贾"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0882278,
        "lng": 31.1239833
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.9381018,
        40.9271851,
        31.3274608,
        41.1157622
      ]
    },
    {
      "id": 19454,
      "name": "Fougeres",
      "names": {
        "zh": "富热尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3503362,
        "lng": -1.1958526
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.218549,
        48.3281543,
        -1.1719984,
        48.3742509
      ]
    },
    {
      "id": 19455,
      "name": "Apolda",
      "names": {
        "zh": "阿波尔达"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0241204,
        "lng": 11.5131086
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.5130586,
        51.0240704,
        11.5131586,
        51.0241704
      ]
    },
    {
      "id": 19456,
      "name": "Zhedao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.8118431,
        "lng": 98.2974244
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.2310677,
        24.7339604,
        98.336018,
        24.8233632
      ]
    },
    {
      "id": 19459,
      "name": "El Colegio",
      "names": {
        "ja": "エルコレゴ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5802618,
        "lng": -74.442374
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4991432,
        4.4957001,
        -74.3638677,
        4.6247809
      ]
    },
    {
      "id": 19474,
      "name": "La Jagua de Ibirico",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.56228,
        "lng": -73.33405
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.35405,
        9.54228,
        -73.31405,
        9.58228
      ]
    },
    {
      "id": 19476,
      "name": "Arouca",
      "names": {
        "zh": "阿罗卡"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.93356265,
        "lng": -8.242678479864527
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2636948,
        40.9156417,
        -8.2143894,
        40.9505398
      ]
    },
    {
      "id": 19492,
      "name": "Dudelange",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.479383,
        "lng": 6.085883
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        6.047498,
        49.452889,
        6.128036,
        49.507049
      ]
    },
    {
      "id": 19494,
      "name": "Acatlan",
      "names": {
        "es": "Acatlán",
        "zh": "阿卡特兰"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6064604,
        "lng": -103.3534768
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.3535586,
        20.6064265,
        -103.3533345,
        20.606816
      ]
    },
    {
      "id": 19495,
      "name": "Lebu",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -37.6102279,
        "lng": -73.656055
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -73.9608944,
        -38.4469908,
        -73.4188841,
        -37.4215502
      ]
    },
    {
      "id": 19496,
      "name": "Zeewolde",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.331111,
        "lng": 5.5430312
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3012446,
        52.2495271,
        5.6230213,
        52.4426563
      ]
    },
    {
      "id": 19501,
      "name": "Chaumont",
      "names": {
        "zh": "肖蒙"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1111324,
        "lng": 5.1395849
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.0832429,
        48.0354519,
        5.2621381,
        48.1407065
      ]
    },
    {
      "id": 19508,
      "name": "Corinto",
      "names": {
        "zh": "科林托"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.4816978,
        "lng": -87.1742553
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -87.3849376,
        12.2385797,
        -87.0717449,
        12.5557362
      ]
    },
    {
      "id": 19510,
      "name": "Binnish",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.95664,
        "lng": 36.7138
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.6938,
        35.93664,
        36.7338,
        35.97664
      ]
    },
    {
      "id": 19511,
      "name": "Ar Rutbah",
      "names": {
        "fr": "Ar rubah",
        "ja": "rutbah",
        "th": "arrutbah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.036132,
        "lng": 40.284398
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        40.259434,
        33.020186,
        40.312451,
        33.052122
      ]
    },
    {
      "id": 19515,
      "name": "Chapa de Mota",
      "names": {
        "id": "Capa de mota",
        "ja": "チャパデモ",
        "pt": "Capa de mota"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8137926,
        "lng": -99.5261131
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.5461131,
        19.7937926,
        -99.5061131,
        19.8337926
      ]
    },
    {
      "id": 19522,
      "name": "Harim",
      "names": {
        "zh": "闺房",
        "th": "ฮาริม"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.2095065,
        "lng": 36.5206804
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.5006804,
        36.1895065,
        36.5406804,
        36.2295065
      ]
    },
    {
      "id": 19527,
      "name": "Natagaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.6241727,
        "lng": -75.0930613
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3011876,
        3.3656596,
        -74.9303223,
        3.6961691
      ]
    },
    {
      "id": 19537,
      "name": "San Andres Villa Seca",
      "names": {
        "es": "San Andrés Villa Seca",
        "zh": "圣安德烈斯别墅Seca",
        "ja": "サンアンドレスヴィラセコ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.56667,
        "lng": -91.58333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.60333,
        14.54667,
        -91.56333,
        14.58667
      ]
    },
    {
      "id": 19538,
      "name": "El Doncello",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.6815919,
        "lng": -75.2844565
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.385755,
        1.4268296,
        -74.9161061,
        2.3233094
      ]
    },
    {
      "id": 19541,
      "name": "Golcuk",
      "names": {
        "zh": "格尔居克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7169247,
        "lng": 29.819588
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.6828782,
        40.5724105,
        29.9139341,
        40.7303666
      ]
    },
    {
      "id": 19578,
      "name": "Repelon",
      "names": {
        "zh": "雷佩隆"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4950081,
        "lng": -75.1244823
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1644823,
        10.4550081,
        -75.0844823,
        10.5350081
      ]
    },
    {
      "id": 19582,
      "name": "Salvaterra de Magos",
      "names": {
        "id": "Salvaterra de mago"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.02301325,
        "lng": -8.788491849300108
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7885502,
        39.0229689,
        -8.7884335,
        39.0230577
      ]
    },
    {
      "id": 19587,
      "name": "San Rafael del Norte",
      "names": {
        "zh": "圣拉斐尔del Norte",
        "ja": "サンラファエルデルノルテ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.21248,
        "lng": -86.11059
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.13059,
        13.19248,
        -86.09059,
        13.23248
      ]
    },
    {
      "id": 19606,
      "name": "Villeneuve-sur-Lot",
      "names": {
        "es": "Villeneuve-sur-mucho",
        "zh": "新城畔很多",
        "de": "Villeneuve-sur-Menge",
        "ja": "ヴィルヌーヴシュルたくさん",
        "th": "เนิฟ-ซูร์มาก"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.4056615,
        "lng": 0.7046455
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.6741197,
        44.3617708,
        0.822344,
        44.4748943
      ]
    },
    {
      "id": 19618,
      "name": "Kitzingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7380667,
        "lng": 10.1601218
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.0908741,
        49.7039008,
        10.2310234,
        49.7908763
      ]
    },
    {
      "id": 19620,
      "name": "Qapqal",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.8388081,
        "lng": 81.1492782
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        80.9892782,
        43.6788081,
        81.3092782,
        43.9988081
      ]
    },
    {
      "id": 19627,
      "name": "Silvania",
      "names": {
        "zh": "席尔瓦尼亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4034202,
        "lng": -74.387702
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4502113,
        4.358101,
        -74.3000368,
        4.5060114
      ]
    },
    {
      "id": 19629,
      "name": "Lom",
      "names": {
        "zh": "洛美",
        "th": "ลม"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.8201697,
        "lng": 23.2386638
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        23.1986638,
        43.7801697,
        23.2786638,
        43.8601697
      ]
    },
    {
      "id": 19641,
      "name": "Millau",
      "names": {
        "zh": "米洛"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.1006693,
        "lng": 3.0777594
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.9906445,
        44.0166725,
        3.2448582,
        44.1798501
      ]
    },
    {
      "id": 19644,
      "name": "Swiedbodzin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.25,
        "lng": 15.5333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 19646,
      "name": "Pailitas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9589902,
        "lng": -73.6216938
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7075426,
        8.828031,
        -73.4262175,
        9.0702086
      ]
    },
    {
      "id": 19647,
      "name": "Villa de Leyva",
      "names": {
        "ja": "ヴィラデレイヴァ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.633825,
        "lng": -73.5239684
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.5639684,
        5.593825,
        -73.4839684,
        5.673825
      ]
    },
    {
      "id": 19649,
      "name": "Vecses",
      "names": {
        "de": "Vecsés"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4095417,
        "lng": 19.2650378
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.2174223,
        47.358512,
        19.316134,
        47.4382332
      ]
    },
    {
      "id": 19650,
      "name": "Leczna",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.3002941,
        "lng": 22.886766
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.8284567,
        51.2768119,
        22.9419014,
        51.3283742
      ]
    },
    {
      "id": 19655,
      "name": "Augusta",
      "names": {
        "zh": "奥古斯塔",
        "ja": "オーガスタ",
        "th": "ออกัสตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.3169922,
        "lng": -69.7734278
      },
      "country": "United States",
      "importance": 1,
      "bbox": [
        -69.8342345,
        44.2750636,
        -69.6257007,
        44.3917554
      ]
    },
    {
      "id": 19666,
      "name": "Timbiqui",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.7717,
        "lng": -77.66536
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.68536,
        2.7517,
        -77.64536,
        2.7917
      ]
    },
    {
      "id": 19672,
      "name": "Vanersborg",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.372065,
        "lng": 12.329067
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.270204,
        58.335433,
        12.375108,
        58.388327
      ]
    },
    {
      "id": 19673,
      "name": "Tiquisio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5560603,
        "lng": -74.2631684
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3031684,
        8.5160603,
        -74.2231684,
        8.5960603
      ]
    },
    {
      "id": 19674,
      "name": "Semikarakorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.514732,
        "lng": 40.801348
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.770248,
        47.505028,
        40.831528,
        47.522221
      ]
    },
    {
      "id": 19677,
      "name": "Alblasserdam",
      "names": {
        "zh": "阿尔布拉瑟丹"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.862968,
        "lng": 4.661494
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.637076,
        51.83865,
        4.70024,
        51.8802
      ]
    },
    {
      "id": 19678,
      "name": "Taminango",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.5696216,
        "lng": -77.2772963
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.4024809,
        1.5006005,
        -77.2564854,
        1.6820197
      ]
    },
    {
      "id": 19679,
      "name": "Hlohovec",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4333547,
        "lng": 17.8070191
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.8020191,
        48.4283547,
        17.8120191,
        48.4383547
      ]
    },
    {
      "id": 19680,
      "name": "Povoa de Lanhoso",
      "names": {
        "ja": "ポボアデラニョソ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5759516,
        "lng": -8.2699521
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3443099,
        41.5185709,
        -8.1469238,
        41.6559113
      ]
    },
    {
      "id": 19692,
      "name": "Kotli",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 33.5216412,
        "lng": 73.9076096
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        73.8676096,
        33.4816412,
        73.9476096,
        33.5616412
      ]
    },
    {
      "id": 19699,
      "name": "Sonthofen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.5135363,
        "lng": 10.281931
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.241931,
        47.4735363,
        10.321931,
        47.5535363
      ]
    },
    {
      "id": 19703,
      "name": "Axapusco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7255555,
        "lng": -98.7596882
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.7996882,
        19.6855555,
        -98.7196882,
        19.7655555
      ]
    },
    {
      "id": 19713,
      "name": "Kemi",
      "names": {
        "zh": "凯米"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.7333404,
        "lng": 24.5666495
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.1327528,
        65.337276,
        24.7474105,
        65.7952916
      ]
    },
    {
      "id": 19720,
      "name": "Iisalmi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.5572224,
        "lng": 27.190374068767756
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.1900045,
        63.556903,
        27.1907397,
        63.5574688
      ]
    },
    {
      "id": 19721,
      "name": "Opoczno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.377071,
        "lng": 20.279404
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.219382,
        51.342238,
        20.320612,
        51.412672
      ]
    },
    {
      "id": 19726,
      "name": "Wieliczka",
      "names": {
        "zh": "维利奇卡"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.987509,
        "lng": 20.05598
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.002744,
        49.970896,
        20.088941,
        50.004597
      ]
    },
    {
      "id": 19730,
      "name": "Tornio",
      "names": {
        "zh": "托尔尼奥"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.8478443,
        "lng": 24.145583983462295
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.1452894,
        65.8477157,
        24.1459377,
        65.8480054
      ]
    },
    {
      "id": 19733,
      "name": "Osterode",
      "names": {
        "zh": "奥斯特罗德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7170057,
        "lng": 10.2729905
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2729405,
        51.7169557,
        10.2730405,
        51.7170557
      ]
    },
    {
      "id": 19740,
      "name": "Chelmno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.3493915,
        "lng": 18.4235473
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.3779625,
        53.330063,
        18.4730287,
        53.3747408
      ]
    },
    {
      "id": 19741,
      "name": "Hallein",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.6792794,
        "lng": 13.097440264206341
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.0763705,
        47.6656415,
        13.1233821,
        47.6929999
      ]
    },
    {
      "id": 19748,
      "name": "Azambuja",
      "names": {
        "zh": "阿赞布雅"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0684428,
        "lng": -8.8663753
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8713753,
        39.0634428,
        -8.8613753,
        39.0734428
      ]
    },
    {
      "id": 19757,
      "name": "Ginebra",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7237049,
        "lng": -76.2687988
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.3238446,
        3.6624951,
        -76.081446,
        3.8323907
      ]
    },
    {
      "id": 19771,
      "name": "Ocotlan de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.791389,
        "lng": -96.675
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.715,
        16.751389,
        -96.635,
        16.831389
      ]
    },
    {
      "id": 19777,
      "name": "Siilinjarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.0743109,
        "lng": 27.6623456
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.3378904,
        62.951871,
        27.949165,
        63.2518233
      ]
    },
    {
      "id": 19781,
      "name": "San Martin de las Piramides",
      "names": {
        "zh": "圣马丁德拉斯吡米德",
        "ja": "サンマーティンデラスピラミド"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.703383,
        "lng": -98.831744
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8468552533,
        19.6933120315,
        -98.8188321244,
        19.7134386755
      ]
    },
    {
      "id": 19783,
      "name": "Dax",
      "names": {
        "zh": "达克斯",
        "ja": "ダックス"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.7084065,
        "lng": -1.0518771
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.1020338,
        43.675449,
        -1.0250954,
        43.7297539
      ]
    },
    {
      "id": 19784,
      "name": "Puerto Triunfo",
      "names": {
        "ja": "プエルトトリウォンフォ",
        "th": "เปอร์โตไตรปืน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8689335,
        "lng": -74.6407965
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8469988,
        5.8589762,
        -74.5610535,
        6.0531564
      ]
    },
    {
      "id": 19788,
      "name": "San Bernardo",
      "names": {
        "zh": "圣伯纳多",
        "ja": "サンベルナルド",
        "th": "ซานเบอร์นาร์โด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.5158752,
        "lng": -77.0457718
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.0857718,
        1.4758752,
        -77.0057718,
        1.5558752
      ]
    },
    {
      "id": 19792,
      "name": "Zundert",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.469484,
        "lng": 4.657725
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.607339,
        51.444237,
        4.724746,
        51.492508
      ]
    },
    {
      "id": 19806,
      "name": "Kurikka",
      "names": {
        "zh": "库里卡"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6171778,
        "lng": 22.3992088
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.6568315,
        62.2690048,
        23.14451,
        62.865511
      ]
    },
    {
      "id": 19809,
      "name": "Huy",
      "names": {
        "zh": "于伊",
        "th": "ฮุย"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5180856,
        "lng": 5.2408138
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.1291778,
        50.467667,
        5.3237528,
        50.5388709
      ]
    },
    {
      "id": 19811,
      "name": "Germersheim",
      "names": {
        "zh": "格尔斯海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2222749,
        "lng": 8.36659
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.3415033,
        49.1762499,
        8.4053451,
        49.2563391
      ]
    },
    {
      "id": 19823,
      "name": "Lisieux",
      "names": {
        "zh": "里济厄",
        "th": "ลิซิเออซ์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.145906350000004,
        "lng": 0.2277323591686238
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.227644,
        49.145726,
        0.228114,
        49.146087
      ]
    },
    {
      "id": 19833,
      "name": "San Jacinto",
      "names": {
        "zh": "圣雅加托",
        "th": "ซานจาคินโต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.8296174,
        "lng": -75.1218628
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3177246,
        9.7588387,
        -74.917089,
        9.9260262
      ]
    },
    {
      "id": 19842,
      "name": "Entroncamento",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4652196,
        "lng": -8.4682137
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4996324,
        39.4395083,
        -8.4556575,
        39.4924417
      ]
    },
    {
      "id": 19845,
      "name": "Kant",
      "names": {
        "zh": "康德",
        "ja": "カント",
        "th": "คานท์"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8888241,
        "lng": 74.8457492
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        74.8057492,
        42.8488241,
        74.8857492,
        42.9288241
      ]
    },
    {
      "id": 19848,
      "name": "Aichach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.45895795,
        "lng": 11.135827176034823
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.119337,
        48.4392254,
        11.1528544,
        48.4787604
      ]
    },
    {
      "id": 19851,
      "name": "Senaki",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.27042,
        "lng": 42.0675
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        42.0475,
        42.25042,
        42.0875,
        42.29042
      ]
    },
    {
      "id": 19855,
      "name": "San Nicolas",
      "names": {
        "es": "San Nicolás",
        "zh": "圣尼古拉斯",
        "ja": "サンニコラス",
        "th": "ซานนิโคลัส"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": -33.3277089,
        "lng": -60.2165909
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -60.2776695,
        -33.4100768,
        -60.1659877,
        -33.2858771
      ]
    },
    {
      "id": 19859,
      "name": "Nueva Granada",
      "names": {
        "fr": "Nueva Grenade",
        "zh": "Nueva格拉纳达",
        "th": "นูวากรานาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.8018198,
        "lng": -74.3923786
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4890593,
        9.5658871,
        -74.1671142,
        9.9581491
      ]
    },
    {
      "id": 19861,
      "name": "La Resolana",
      "names": {
        "zh": "拉索纳",
        "it": "La Rimolana",
        "ja": "La Resirana"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6041765,
        "lng": -104.436364
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.476364,
        19.5641765,
        -104.396364,
        19.6441765
      ]
    },
    {
      "id": 19862,
      "name": "San Justo",
      "names": {
        "it": "San jousto"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.7890881,
        "lng": -60.5933274
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.6114162,
        -30.8082339,
        -60.5720247,
        -30.7727987
      ]
    },
    {
      "id": 19866,
      "name": "Leerdam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8937356,
        "lng": 5.0924352
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.0550055,
        51.8783608,
        5.1365718,
        51.9298484
      ]
    },
    {
      "id": 19869,
      "name": "Sondershausen",
      "names": {
        "zh": "松德斯豪森"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3666041,
        "lng": 10.8668419
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.6202585,
        51.2835175,
        10.9494875,
        51.4216635
      ]
    },
    {
      "id": 19872,
      "name": "Galeras",
      "names": {
        "zh": "加勒拉斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.160911,
        "lng": -75.0479142
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0879142,
        9.120911,
        -75.0079142,
        9.200911
      ]
    },
    {
      "id": 19875,
      "name": "Kara-Suu",
      "names": {
        "zh": "卡拉-suu",
        "ja": "カラ-のSuu",
        "th": "คาร่า-ซู"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.7079547,
        "lng": 72.8801867
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        72.8574899,
        40.6933266,
        72.9025213,
        40.7207582
      ]
    },
    {
      "id": 19878,
      "name": "Nang Rong",
      "names": {
        "zh": "南戎",
        "ja": "ナンロン",
        "th": "นางรอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.632902,
        "lng": 102.79308
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.762328,
        14.601128,
        102.846804,
        14.661718
      ]
    },
    {
      "id": 19881,
      "name": "Acatic",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7805923,
        "lng": -102.9101603
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.9102103,
        20.7805423,
        -102.9101103,
        20.7806423
      ]
    },
    {
      "id": 19882,
      "name": "Eeklo",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.1910245,
        "lng": 3.566534200312325
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.508273,
        51.1623117,
        3.609127,
        51.2188333
      ]
    },
    {
      "id": 19883,
      "name": "Hajnowka",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.73355,
        "lng": 23.5830712
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.5780712,
        52.72855,
        23.5880712,
        52.73855
      ]
    },
    {
      "id": 19896,
      "name": "Jamsa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8639814,
        "lng": 25.1845044
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.5246546,
        61.63064,
        25.5962253,
        62.2107917
      ]
    },
    {
      "id": 19900,
      "name": "Ano Syros",
      "names": {
        "zh": "Ano锡罗斯",
        "ja": "アノシロス",
        "pt": "Xo syros"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.448734,
        "lng": 24.923696
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.914902,
        37.430546,
        24.937785,
        37.460472
      ]
    },
    {
      "id": 19908,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -11.9543757,
        "lng": -38.9736385
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -39.0656491,
        -12.0593193,
        -38.897,
        -11.8060277
      ]
    },
    {
      "id": 19909,
      "name": "Dean Funes",
      "names": {
        "es": "Decan Funes",
        "ja": "ディーンのファン",
        "th": "คณบดีสนุก",
        "pt": "Diversos Dean"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.4249487,
        "lng": -64.3527199
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.3927199,
        -30.4649487,
        -64.3127199,
        -30.3849487
      ]
    },
    {
      "id": 19910,
      "name": "Villagarzon",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.03043,
        "lng": -76.61633
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.63633,
        1.01043,
        -76.59633,
        1.05043
      ]
    },
    {
      "id": 19911,
      "name": "Sarreguemines",
      "names": {
        "zh": "萨尔格米纳"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1094836,
        "lng": 7.0708865
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.0057108,
        49.07965,
        7.1361698,
        49.1323781
      ]
    },
    {
      "id": 19916,
      "name": "Wang Nam Yen",
      "names": {
        "zh": "王南日元",
        "ja": "王のナム円",
        "th": "วังน้ำเยน",
        "pt": "Wang nam iene"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5168467,
        "lng": 102.1744609
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1344609,
        13.4768467,
        102.2144609,
        13.5568467
      ]
    },
    {
      "id": 19917,
      "name": "Livadeia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.43616,
        "lng": 22.87665
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.85665,
        38.41616,
        22.89665,
        38.45616
      ]
    },
    {
      "id": 19922,
      "name": "Moniquira",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8763096,
        "lng": -73.5732077
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6316306,
        5.777149,
        -73.4620989,
        5.9599789
      ]
    },
    {
      "id": 19925,
      "name": "Zile",
      "names": {
        "zh": "济莱"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3005345,
        "lng": 35.8823475
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.4395771,
        40.0592202,
        36.1190672,
        40.4493638
      ]
    },
    {
      "id": 19928,
      "name": "Almaguer",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9134696,
        "lng": -76.85654735774719
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.8568143,
        1.9132786,
        -76.8562812,
        1.9136759
      ]
    },
    {
      "id": 19930,
      "name": "El Paujil",
      "names": {
        "ja": "エルパウジル"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.5692837499999999,
        "lng": -75.32616046802045
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.332943,
        1.5611958,
        -75.3188914,
        1.5782834
      ]
    },
    {
      "id": 19934,
      "name": "Puerto Natales",
      "names": {
        "zh": "波多特·纳塔尔斯",
        "ja": "プエルトナタレス",
        "th": "เปอร์โตนาเลส"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -51.7262003,
        "lng": -72.5059982
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -75.722521,
        -52.8425946,
        -71.645888,
        -48.5822288
      ]
    },
    {
      "id": 19939,
      "name": "Antsohihy",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -14.8775272,
        "lng": 47.98715
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        47.94715,
        -14.9175272,
        48.02715,
        -14.8375272
      ]
    },
    {
      "id": 19945,
      "name": "Prudnik",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3217212,
        "lng": 17.5801041
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.5291937,
        50.2699518,
        17.6084845,
        50.3417721
      ]
    },
    {
      "id": 19950,
      "name": "Sanhe",
      "names": {
        "zh": "三河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.769037,
        "lng": 104.7582541
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.7312073,
        31.7135336,
        104.8514937,
        31.8432055
      ]
    },
    {
      "id": 19960,
      "name": "Kozluk",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1933398,
        "lng": 41.4886025
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.175092,
        38.0098202,
        41.6473018,
        38.37944
      ]
    },
    {
      "id": 19966,
      "name": "Tubbergen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.403962699999994,
        "lng": 6.774441654037701
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.6696718,
        52.3296654,
        6.949224,
        52.4781316
      ]
    },
    {
      "id": 19969,
      "name": "Aigio",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.2505699,
        "lng": 22.0852909
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.9252909,
        38.0905699,
        22.2452909,
        38.4105699
      ]
    },
    {
      "id": 19986,
      "name": "Beaune",
      "names": {
        "zh": "博纳",
        "ja": "ボーヌ",
        "th": "โบน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.0239064,
        "lng": 4.8380426
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.7870356,
        46.9955925,
        4.8973109,
        47.0588586
      ]
    },
    {
      "id": 19991,
      "name": "Gryfino",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.253051,
        "lng": 14.490608
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.477228,
        53.225842,
        14.517453,
        53.286248
      ]
    },
    {
      "id": 20002,
      "name": "Youganning",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7362124,
        "lng": 101.6145551
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.8928218,
        34.0814197,
        102.2577229,
        34.9141604
      ]
    },
    {
      "id": 20003,
      "name": "Belovodskoe",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8345801,
        "lng": 74.1218123
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        74.0833289,
        42.8332303,
        74.15826,
        42.8633458
      ]
    },
    {
      "id": 20014,
      "name": "Elverum",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.8814492,
        "lng": 11.5622121
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.5222121,
        60.8414492,
        11.6022121,
        60.9214492
      ]
    },
    {
      "id": 20034,
      "name": "Santa Maria Chilchotla",
      "names": {
        "ja": "サンタマリアチルコトラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2333865,
        "lng": -96.8302136
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.8702136,
        18.1933865,
        -96.7902136,
        18.2733865
      ]
    },
    {
      "id": 20044,
      "name": "Rio Maior",
      "names": {
        "zh": "里奥迈阿里",
        "it": "Rio maor",
        "id": "Rio maya",
        "ja": "リオメーバー",
        "pt": "Rústico"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3365387,
        "lng": -8.9364098
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.001574,
        39.2349128,
        -8.7830674,
        39.4660075
      ]
    },
    {
      "id": 20049,
      "name": "Aguadas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.613113,
        "lng": -75.4575902
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.5999953,
        5.4204191,
        -75.3105319,
        5.7378922
      ]
    },
    {
      "id": 20051,
      "name": "Brezno",
      "names": {
        "zh": "布雷兹诺"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8053349,
        "lng": 19.6409612
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.350434,
        48.619159,
        20.267459,
        48.947248
      ]
    },
    {
      "id": 20059,
      "name": "Montecristo",
      "names": {
        "zh": "蒙特克里斯托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2956227,
        "lng": -74.4738996
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5138996,
        8.2556227,
        -74.4338996,
        8.3356227
      ]
    },
    {
      "id": 20064,
      "name": "Sadao",
      "names": {
        "zh": "贞雄",
        "th": "สะเดา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.638362,
        "lng": 100.420819
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.389515,
        6.606457,
        100.440563,
        6.679107
      ]
    },
    {
      "id": 20083,
      "name": "Varkaus",
      "names": {
        "zh": "瓦尔考斯"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.3124324,
        "lng": 27.874058054805815
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.8735565,
        62.312253,
        27.8745321,
        62.3125933
      ]
    },
    {
      "id": 20099,
      "name": "Florencia",
      "names": {
        "zh": "弗洛伦西亚"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1464404,
        "lng": -78.9740543
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -79.0541129,
        21.9891686,
        -78.832228,
        22.1742578
      ]
    },
    {
      "id": 20100,
      "name": "Suaza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9772874,
        "lng": -75.7946272
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9418551,
        1.7394854,
        -75.6885155,
        1.999808
      ]
    },
    {
      "id": 20101,
      "name": "Bologoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.86697,
        "lng": 34.07498
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 20118,
      "name": "El Calafate",
      "names": {
        "ja": "elカラファテ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -50.3388712,
        "lng": -72.2720843
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -72.3368128,
        -50.3585985,
        -72.2157636,
        -50.3014569
      ]
    },
    {
      "id": 20119,
      "name": "Villapinzon",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.2176911,
        "lng": -73.5976394
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6026394,
        5.2126911,
        -73.5926394,
        5.2226911
      ]
    },
    {
      "id": 20124,
      "name": "Fredonia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9270644,
        "lng": -75.6718221
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7118221,
        5.8870644,
        -75.6318221,
        5.9670644
      ]
    },
    {
      "id": 20134,
      "name": "Stange",
      "names": {
        "zh": "施坦格"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.6244351,
        "lng": 11.383120762784838
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.0277772,
        60.4587985,
        11.6543534,
        60.7922559
      ]
    },
    {
      "id": 20139,
      "name": "La Colonia Tovar",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4093341,
        "lng": -67.2762394
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -67.2962394,
        10.3893341,
        -67.2562394,
        10.4293341
      ]
    },
    {
      "id": 20143,
      "name": "Gunzburg",
      "names": {
        "fr": "Günzburg",
        "de": "Günzburg",
        "it": "Günzburg"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4555244,
        "lng": 10.2766213
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2252001,
        48.4179515,
        10.3372926,
        48.5201051
      ]
    },
    {
      "id": 20151,
      "name": "Chascomus",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.5775186,
        "lng": -58.0109507
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.0110007,
        -35.5775686,
        -58.0109007,
        -35.5774686
      ]
    },
    {
      "id": 20154,
      "name": "El Reten",
      "names": {
        "es": "El retorno",
        "zh": "el请重新调整",
        "de": "El Retmen",
        "it": "El ritenzione",
        "ja": "el sten"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.612076,
        "lng": -74.2695268
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4506704,
        10.535784,
        -74.1876684,
        10.7681397
      ]
    },
    {
      "id": 20178,
      "name": "Luckenwalde",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.0904426,
        "lng": 13.1601561
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.1551561,
        52.0854426,
        13.1651561,
        52.0954426
      ]
    },
    {
      "id": 20179,
      "name": "Bugalagrande",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2138883,
        "lng": -76.1571129
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.2375534,
        4.0838814,
        -75.9549359,
        4.3179788
      ]
    },
    {
      "id": 20185,
      "name": "Muhldorf",
      "names": {
        "zh": "米尔多夫",
        "de": "Mühldorf",
        "it": "Mühldorf"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2482939,
        "lng": 12.5196179
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.5146179,
        48.2432939,
        12.5246179,
        48.2532939
      ]
    },
    {
      "id": 20204,
      "name": "Mae Sai",
      "names": {
        "ja": "メーサイ",
        "th": "แม่สาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 20.421794,
        "lng": 99.896469
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.861637,
        20.28923,
        99.990659,
        20.45149
      ]
    },
    {
      "id": 20210,
      "name": "Urk",
      "names": {
        "zh": "乌尔克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.66387375,
        "lng": 5.609367191396951
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6091593,
        52.6637464,
        5.6095751,
        52.6640011
      ]
    },
    {
      "id": 20216,
      "name": "Siquinala",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3075675,
        "lng": -90.9660625
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.0172144,
        14.2614994,
        -90.8732146,
        14.4006587
      ]
    },
    {
      "id": 20223,
      "name": "Oliveira do Hospital",
      "names": {
        "zh": "oliveira做医院",
        "de": "Oliveira do Krankenhaus",
        "id": "Oliveira do Rumah Sakit",
        "ja": "オリビラは病院です",
        "th": "โรงพยาบาล Oliveira do"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3595402,
        "lng": -7.8617453
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.0217453,
        40.1995402,
        -7.7017453,
        40.5195402
      ]
    },
    {
      "id": 20224,
      "name": "Traunstein",
      "names": {
        "zh": "特劳恩施泰因"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8697417,
        "lng": 12.6388146
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.6338146,
        47.8647417,
        12.6438146,
        47.8747417
      ]
    },
    {
      "id": 20225,
      "name": "Brummen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.091633,
        "lng": 6.155134
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.105359,
        52.081086,
        6.16581,
        52.097436
      ]
    },
    {
      "id": 20237,
      "name": "Kremenets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.0960966,
        "lng": 25.7260783
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.6787645,
        50.0722723,
        25.766636,
        50.1367154
      ]
    },
    {
      "id": 20246,
      "name": "Samaca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4917892,
        "lng": -73.48529
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6167517,
        5.3823766,
        -73.4323898,
        5.5615998
      ]
    },
    {
      "id": 20249,
      "name": "Ovejas",
      "names": {
        "zh": "奥韦哈斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.5267021,
        "lng": -75.2293968
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2693968,
        9.4867021,
        -75.1893968,
        9.5667021
      ]
    },
    {
      "id": 20253,
      "name": "Sotomayor",
      "names": {
        "zh": "索托马约尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4932789,
        "lng": -77.5210552
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.5610552,
        1.4532789,
        -77.4810552,
        1.5332789
      ]
    },
    {
      "id": 20265,
      "name": "Valkeakoski",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2637921,
        "lng": 24.0301278
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.7976553,
        61.1019078,
        24.2971442,
        61.3438213
      ]
    },
    {
      "id": 20292,
      "name": "Pita",
      "names": {
        "es": "Agave",
        "zh": "皮塔",
        "de": "Pita-Brot",
        "ja": "ピタ",
        "th": "ไฟลนก้น",
        "pt": "Pão Pita"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.0564638,
        "lng": -12.3950499
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -12.5550499,
        10.8964638,
        -12.2350499,
        11.2164638
      ]
    },
    {
      "id": 20293,
      "name": "Forst (Lausitz)",
      "names": {
        "zh": "福尔斯特（洛乌斯）",
        "ja": "フォースト（Lausitz）"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7387746,
        "lng": 14.6373104
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.6372604,
        51.7387246,
        14.6373604,
        51.7388246
      ]
    },
    {
      "id": 20295,
      "name": "Lebedinovka",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 42.88454,
        "lng": 74.67819
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        74.65819,
        42.86454,
        74.69819,
        42.90454
      ]
    },
    {
      "id": 20311,
      "name": "Illapel",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -31.6326932,
        "lng": -71.1683039
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.3965365,
        -31.9011756,
        -70.527581,
        -31.2785467
      ]
    },
    {
      "id": 20312,
      "name": "Bad Salzungen",
      "names": {
        "zh": "糟糕的萨尔岑",
        "de": "Schlechte Salzenungen",
        "ja": "悪いザルサンゲン",
        "pt": "Salzungen ruim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8119513,
        "lng": 10.2326843
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.2326343,
        50.8119013,
        10.2327343,
        50.8120013
      ]
    },
    {
      "id": 20319,
      "name": "Dolyna",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9782308,
        "lng": 23.9789588
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.9402631,
        48.9577388,
        24.0167034,
        49.0070697
      ]
    },
    {
      "id": 20320,
      "name": "Na Klang",
      "names": {
        "th": "นากลาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.3010975,
        "lng": 102.2059153
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1659153,
        17.2610975,
        102.2459153,
        17.3410975
      ]
    },
    {
      "id": 20322,
      "name": "Senica",
      "names": {
        "zh": "塞尼察"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6787557,
        "lng": 17.3661529
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.2986934,
        48.6244177,
        17.4269252,
        48.7124048
      ]
    },
    {
      "id": 20325,
      "name": "Elvas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8806123,
        "lng": -7.1637237
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.4242923,
        38.7514974,
        -7.0328581,
        39.0571869
      ]
    },
    {
      "id": 20326,
      "name": "Melena del Sur",
      "names": {
        "ja": "メレナデルール"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7879029,
        "lng": -82.1514362
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -82.2384144,
        22.6700852,
        -82.0598954,
        22.8602056
      ]
    },
    {
      "id": 20328,
      "name": "Mantsala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.63184,
        "lng": 25.316504
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.267879,
        60.609539,
        25.357762,
        60.655365
      ]
    },
    {
      "id": 20332,
      "name": "Pueblo Nuevo",
      "names": {
        "zh": "普韦布洛·努沃"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 9.01173565,
        "lng": -79.51194121530838
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -79.5252866,
        8.9938805,
        -79.5045663,
        9.0292775
      ]
    },
    {
      "id": 20334,
      "name": "Tielt",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0002939,
        "lng": 3.3273362
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.28629,
        50.9610745,
        3.4525562,
        51.0538674
      ]
    },
    {
      "id": 20339,
      "name": "Jitotol",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0672358,
        "lng": -92.8599514
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.8999514,
        17.0272358,
        -92.8199514,
        17.1072358
      ]
    },
    {
      "id": 20342,
      "name": "Menaka",
      "names": {
        "zh": "梅纳卡"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 16.6516783,
        "lng": 2.826313558679702
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        1.311499,
        15.278128,
        4.2673828,
        18.063
      ]
    },
    {
      "id": 20354,
      "name": "Nove Mesto nad Vahom",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7633248,
        "lng": 17.8303857
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.774267,
        48.7193969,
        17.837923,
        48.7846557
      ]
    },
    {
      "id": 20374,
      "name": "Baiao",
      "names": {
        "zh": "拜昂",
        "pt": "Baião"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1616306,
        "lng": -8.0355439
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1154664,
        41.089585,
        -7.8755793,
        41.2489776
      ]
    },
    {
      "id": 20376,
      "name": "Senec",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.2199473,
        "lng": 17.3969901
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.3347667,
        48.1906719,
        17.4438846,
        48.278545
      ]
    },
    {
      "id": 20387,
      "name": "As",
      "names": {
        "es": "Como",
        "fr": "Comme",
        "zh": "作为",
        "de": "Wie",
        "it": "Come",
        "id": "Sebagai",
        "ja": "のように",
        "th": "เช่น",
        "pt": "Como"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6603,
        "lng": 10.7836
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 20393,
      "name": "Pak Phanang",
      "names": {
        "ja": "パックフィナン",
        "th": "ปากพระหาน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.3476389,
        "lng": 100.1996122
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.1596122,
        8.3076389,
        100.2396122,
        8.3876389
      ]
    },
    {
      "id": 20398,
      "name": "Mealhada",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3792003,
        "lng": -8.4517733
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5299536,
        40.2788096,
        -8.3537632,
        40.4163801
      ]
    },
    {
      "id": 20409,
      "name": "Chodziez",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.9886824,
        "lng": 16.9159506
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.8851288,
        52.9649584,
        16.9463021,
        53.0249822
      ]
    },
    {
      "id": 20413,
      "name": "Gjovik",
      "names": {
        "de": "Gjøvik"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.796633,
        "lng": 10.6934904
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.5334904,
        60.636633,
        10.8534904,
        60.956633
      ]
    },
    {
      "id": 20428,
      "name": "Qagan Us",
      "names": {
        "zh": "QAGAN我们",
        "it": "Qagan noi",
        "id": "Qagan kita",
        "pt": "Qagan EUA"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.69972,
        "lng": 114.74917
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        114.72917,
        42.67972,
        114.76917,
        42.71972
      ]
    },
    {
      "id": 20432,
      "name": "Timana",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.95016885,
        "lng": -75.92657973750607
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0025268,
        1.8535545,
        -75.8255686,
        2.0461956
      ]
    },
    {
      "id": 20442,
      "name": "Laboulaye",
      "names": {
        "zh": "拉波拉耶"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.1286369,
        "lng": -63.3905826
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.413676,
        -34.1538104,
        -63.3610082,
        -34.1065673
      ]
    },
    {
      "id": 20444,
      "name": "Tyrnyauz",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.3958986,
        "lng": 42.9230751
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        42.8212432,
        43.3287893,
        42.991476,
        43.4403367
      ]
    },
    {
      "id": 20445,
      "name": "Pleszew",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8960985,
        "lng": 17.7865673
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.7506111,
        51.8748552,
        17.8222643,
        51.9131595
      ]
    },
    {
      "id": 20448,
      "name": "Khvansar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.22052,
        "lng": 50.31497
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.29497,
        33.20052,
        50.33497,
        33.24052
      ]
    },
    {
      "id": 20454,
      "name": "Cinfaes",
      "names": {
        "pt": "Cinfães"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.072832,
        "lng": -8.089674
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.102441,
        41.0701,
        -8.085795,
        41.077777
      ]
    },
    {
      "id": 20463,
      "name": "Gostyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8778633,
        "lng": 17.0167637
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.9812183,
        51.8492862,
        17.0372462,
        51.9138394
      ]
    },
    {
      "id": 20465,
      "name": "Lannion",
      "names": {
        "zh": "拉尼永"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7322183,
        "lng": -3.4587994
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -3.5516884,
        48.7043701,
        -3.3876646,
        48.7866771
      ]
    },
    {
      "id": 20473,
      "name": "San Lorenzo",
      "names": {
        "zh": "圣洛伦佐",
        "ja": "サンロレンツォ",
        "th": "ซานลอเรนโซ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 18.1524948,
        "lng": -65.97563436136883
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -66.0525457,
        18.0756612,
        -65.9206835,
        18.2294957
      ]
    },
    {
      "id": 20474,
      "name": "Ocos",
      "names": {
        "zh": "奥科斯"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5103644,
        "lng": -92.1942559
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.2342559,
        14.4703644,
        -92.1542559,
        14.5503644
      ]
    },
    {
      "id": 20484,
      "name": "Annaberg-Buchholz",
      "names": {
        "zh": "安娜贝格-布赫霍尔茨"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5788781,
        "lng": 13.0106108
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.9703518,
        50.5381203,
        13.0543227,
        50.606617
      ]
    },
    {
      "id": 20489,
      "name": "Rhenen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9608761,
        "lng": 5.5722196
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.4776645,
        51.9431248,
        5.6273145,
        52.0185161
      ]
    },
    {
      "id": 20491,
      "name": "Tenjo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8719992,
        "lng": -74.1449702
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.1849702,
        4.8319992,
        -74.1049702,
        4.9119992
      ]
    },
    {
      "id": 20499,
      "name": "Bladel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.369092,
        "lng": 5.21823
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.183203,
        51.305665,
        5.251969,
        51.393978
      ]
    },
    {
      "id": 20500,
      "name": "Vianen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.983046,
        "lng": 5.094526
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.040402,
        51.946053,
        5.131802,
        52.003035
      ]
    },
    {
      "id": 20513,
      "name": "Wittmund",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.575725,
        "lng": 7.776822
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.644406,
        53.484778,
        7.86037,
        53.710953
      ]
    },
    {
      "id": 20519,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0457595,
        "lng": -75.9074199
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9474199,
        6.0057595,
        -75.8674199,
        6.0857595
      ]
    },
    {
      "id": 20530,
      "name": "Teculutan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9901884,
        "lng": -89.7180951
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.7580951,
        14.9501884,
        -89.6780951,
        15.0301884
      ]
    },
    {
      "id": 20552,
      "name": "Donauworth",
      "names": {
        "de": "Donauwörth",
        "it": "Donauwörth"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7180364,
        "lng": 10.7807299
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.6688373,
        48.675551,
        10.8678827,
        48.763405
      ]
    },
    {
      "id": 20553,
      "name": "Greiz",
      "names": {
        "zh": "格赖茨"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6562556,
        "lng": 12.2008694
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.1608694,
        50.6162556,
        12.2408694,
        50.6962556
      ]
    },
    {
      "id": 20559,
      "name": "Hatvan",
      "names": {
        "zh": "豪特万"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6683971,
        "lng": 19.6743874
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.6122069,
        47.6134562,
        19.7686598,
        47.7294515
      ]
    },
    {
      "id": 20567,
      "name": "Bella Vista",
      "names": {
        "zh": "贝拉维斯塔",
        "ja": "ベラビスタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.6230496,
        "lng": -92.23229560652278
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.3233264,
        15.548392,
        -92.1458588,
        15.6981835
      ]
    },
    {
      "id": 20569,
      "name": "Marneuli",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.4878344,
        "lng": 44.806764
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        44.801764,
        41.4828344,
        44.811764,
        41.4928344
      ]
    },
    {
      "id": 20571,
      "name": "Albertville",
      "names": {
        "zh": "阿尔贝维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6754622,
        "lng": 6.3925417
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.3595871,
        45.6411143,
        6.4604269,
        45.688242
      ]
    },
    {
      "id": 20574,
      "name": "Kozan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4477712,
        "lng": 35.8167812
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.4611035,
        37.2333592,
        36.2035898,
        37.8072387
      ]
    },
    {
      "id": 20576,
      "name": "Villa Hidalgo",
      "names": {
        "zh": "别墅Hidalgo",
        "ja": "ヴィラヒダルゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.6756811,
        "lng": -102.5887538
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.6287538,
        21.6356811,
        -102.5487538,
        21.7156811
      ]
    },
    {
      "id": 20577,
      "name": "Lichtenfels",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1462694,
        "lng": 11.0589522
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.0539522,
        50.1412694,
        11.0639522,
        50.1512694
      ]
    },
    {
      "id": 20582,
      "name": "Uzunkopru",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.26598,
        "lng": 26.6885
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.6685,
        41.24598,
        26.7085,
        41.28598
      ]
    },
    {
      "id": 20583,
      "name": "Dingolfing",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.6300801,
        "lng": 12.4977434
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.4302539,
        48.5641239,
        12.5249002,
        48.662146
      ]
    },
    {
      "id": 20586,
      "name": "Simiti",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9551827,
        "lng": -73.9449234
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2348413,
        7.5749731,
        -73.7988965,
        8.1789767
      ]
    },
    {
      "id": 20589,
      "name": "Ciudad Melchor de Mencos",
      "names": {
        "pt": "Ciudad Melchor de Menco"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 19.423081,
        "lng": -99.041199
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -99.067991,
        19.36621,
        -98.965874,
        19.498779
      ]
    },
    {
      "id": 20590,
      "name": "Rovira",
      "names": {
        "zh": "罗维拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2393393,
        "lng": -75.2400005
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2800005,
        4.1993393,
        -75.2000005,
        4.2793393
      ]
    },
    {
      "id": 20598,
      "name": "Huanimaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.367895,
        "lng": -101.496899
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.536899,
        20.327895,
        -101.456899,
        20.407895
      ]
    },
    {
      "id": 20603,
      "name": "Tepetlixpa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.013928999999997,
        "lng": -98.84230655375005
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8740592,
        18.9551434,
        -98.8041062,
        19.0727418
      ]
    },
    {
      "id": 20608,
      "name": "Torgau",
      "names": {
        "zh": "托尔高"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5581272,
        "lng": 13.0046538
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.9057789,
        51.4758384,
        13.1058704,
        51.6018938
      ]
    },
    {
      "id": 20613,
      "name": "Haren",
      "names": {
        "zh": "哈伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1709841,
        "lng": 6.6061413
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.5483776,
        53.1384036,
        6.6472513,
        53.1965916
      ]
    },
    {
      "id": 20624,
      "name": "Nesoddtangen",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.843523,
        "lng": 10.684791
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.636695,
        59.851708,
        10.676695,
        59.891708
      ]
    },
    {
      "id": 20625,
      "name": "Cruz Grande",
      "names": {
        "zh": "克鲁兹格兰德",
        "de": "Kruz Grande",
        "ja": "クルスグランデ",
        "th": "ครูซแกรนด์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.723333,
        "lng": -99.131111
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.171111,
        16.683333,
        -99.091111,
        16.763333
      ]
    },
    {
      "id": 20629,
      "name": "Holzminden",
      "names": {
        "zh": "霍尔茨明登"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.828835,
        "lng": 9.4466591
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.4133127,
        51.7201298,
        9.581272,
        51.8544967
      ]
    },
    {
      "id": 20633,
      "name": "Cahors",
      "names": {
        "zh": "卡奥尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.4495,
        "lng": 1.4364999
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.3725267,
        44.4010268,
        1.5055415,
        44.5121244
      ]
    },
    {
      "id": 20634,
      "name": "Benemerito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5156347,
        "lng": -90.6538633
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.6938633,
        16.4756347,
        -90.6138633,
        16.5556347
      ]
    },
    {
      "id": 20638,
      "name": "Celorico de Basto",
      "names": {
        "fr": "Célorico de Basto",
        "zh": "Celoricoo de Basto"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3879363,
        "lng": -8.0002089
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1466129,
        41.3187983,
        -7.9369426,
        41.4879365
      ]
    },
    {
      "id": 20643,
      "name": "Le Puy-en-Velay",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.0459739,
        "lng": 3.8855537
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.8610658,
        44.998906,
        3.9336399,
        45.0575972
      ]
    },
    {
      "id": 20646,
      "name": "Snina",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.9900623,
        "lng": 22.1556248
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        22.016122,
        48.862108,
        22.5657103,
        49.1886156
      ]
    },
    {
      "id": 20659,
      "name": "Bolvadin",
      "names": {
        "zh": "博尔瓦丁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7115866,
        "lng": 31.0454865
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.8699108,
        38.6402047,
        31.3977923,
        38.93364
      ]
    },
    {
      "id": 20664,
      "name": "Weesp",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3072593,
        "lng": 5.0420822
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.9961169,
        52.2779766,
        5.1076933,
        52.3308719
      ]
    },
    {
      "id": 20669,
      "name": "Saint-Die-des-Vosges",
      "names": {
        "es": "Saint-morir des vosges",
        "fr": "Saint-mourir-des-Vosges",
        "zh": "圣 - 模具之-孚日",
        "id": "Saint-mati-des-vosges",
        "ja": "サン・ダイのdes-vosges",
        "th": "เซนต์ตายเดส์-vosges"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.2818181,
        "lng": 6.9481225
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.9431225,
        48.2768181,
        6.9531225,
        48.2868181
      ]
    },
    {
      "id": 20693,
      "name": "Naranjos",
      "names": {
        "zh": "纳兰霍斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.349722,
        "lng": -97.686667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.726667,
        21.309722,
        -97.646667,
        21.389722
      ]
    },
    {
      "id": 20699,
      "name": "Peshtera",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.032814,
        "lng": 24.301878
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        24.291429,
        42.023582,
        24.33358,
        42.044623
      ]
    },
    {
      "id": 20712,
      "name": "Kuysinjaq",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.0828,
        "lng": 44.6286
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 20722,
      "name": "Castillo de Teayo",
      "names": {
        "es": "Castillo de Teyeo",
        "fr": "Castillo de Teyo",
        "de": "Castillo de Teeyo",
        "id": "Castillo de Teyo",
        "pt": "Castillo de Teeyo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7495957,
        "lng": -97.6332986
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.6732986,
        20.7095957,
        -97.5932986,
        20.7895957
      ]
    },
    {
      "id": 20735,
      "name": "Alagir",
      "names": {
        "zh": "阿拉吉尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.043545,
        "lng": 44.220116
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        44.203842,
        43.023392,
        44.236984,
        43.067333
      ]
    },
    {
      "id": 20753,
      "name": "Kleppe",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.77469,
        "lng": 5.627214
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.598519,
        58.765546,
        5.646082,
        58.792758
      ]
    },
    {
      "id": 20761,
      "name": "El Arenal",
      "names": {
        "ja": "エルアレナル"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7766043,
        "lng": -103.6947997
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.7347997,
        20.7366043,
        -103.6547997,
        20.8166043
      ]
    },
    {
      "id": 20767,
      "name": "Torokszentmiklos",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1793568,
        "lng": 20.409153
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.3266634,
        47.0965085,
        20.541575,
        47.2664989
      ]
    },
    {
      "id": 20780,
      "name": "Leek",
      "names": {
        "es": "Puerro",
        "fr": "Poireau",
        "zh": "韭菜",
        "de": "Lauch",
        "it": "Porro",
        "ja": "リーキ",
        "th": "กระเทียมหอม",
        "pt": "Alho frances"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1632327,
        "lng": 6.377119
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.3595219,
        53.1378794,
        6.4057233,
        53.1900439
      ]
    },
    {
      "id": 20782,
      "name": "Mangualde",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.60354,
        "lng": -7.7656
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8969086,
        40.5259243,
        -7.5617512,
        40.6515353
      ]
    },
    {
      "id": 20788,
      "name": "Xiaqiaotou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.18534,
        "lng": 100.04337
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.02337,
        27.16534,
        100.06337,
        27.20534
      ]
    },
    {
      "id": 20790,
      "name": "Coruche",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9502389,
        "lng": -8.5408638
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5409138,
        38.9501889,
        -8.5408138,
        38.9502889
      ]
    },
    {
      "id": 20803,
      "name": "Aanekoski",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6032186,
        "lng": 25.7301361
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.4739885,
        62.4804376,
        26.2337548,
        62.9207719
      ]
    },
    {
      "id": 20805,
      "name": "Komarom",
      "names": {
        "de": "Komárom",
        "it": "Komárom"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7417349,
        "lng": 18.1218256
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.017241,
        47.6858482,
        18.2305145,
        47.7566754
      ]
    },
    {
      "id": 20807,
      "name": "Os",
      "names": {
        "zh": "操作系统",
        "ja": "OSは",
        "th": "ระบบปฏิบัติการ"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.2269,
        "lng": 5.4758
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 20814,
      "name": "Comapa",
      "names": {
        "zh": "科马帕"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.163889,
        "lng": -96.886944
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.906944,
        19.143889,
        -96.866944,
        19.183889
      ]
    },
    {
      "id": 20816,
      "name": "Ban Na San",
      "names": {
        "zh": "禁止纳圣",
        "ja": "禁止さん",
        "th": "บ้านนาซาน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.8018125,
        "lng": 99.3640806
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.3240806,
        8.7618125,
        99.4040806,
        8.8418125
      ]
    },
    {
      "id": 20818,
      "name": "Eschwege",
      "names": {
        "zh": "埃施韦格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1918201,
        "lng": 10.0427456
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.0377456,
        51.1868201,
        10.0477456,
        51.1968201
      ]
    },
    {
      "id": 20825,
      "name": "Brandsen",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.1679404,
        "lng": -58.2373799
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.2560283,
        -35.1999132,
        -58.2076627,
        -35.124008
      ]
    },
    {
      "id": 20830,
      "name": "Hamina",
      "names": {
        "zh": "哈米纳"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.567714,
        "lng": 27.1901633
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.1901133,
        60.567664,
        27.1902133,
        60.567764
      ]
    },
    {
      "id": 20855,
      "name": "Puerto Armuelles",
      "names": {
        "de": "Puerto-Armaturen",
        "it": "Puerto Arguells",
        "ja": "プエルトアルムセル"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.2725603,
        "lng": -82.8604458
      },
      "country": "Panama",
      "importance": 2,
      "bbox": [
        -82.9975677,
        8.1683482,
        -82.8459715,
        8.326964
      ]
    },
    {
      "id": 20857,
      "name": "General Martin Miguel de Guemes",
      "names": {
        "fr": "Général Martin Miguel de Guemes",
        "zh": "一般马丁米格尔德·普雷斯",
        "it": "Generale Martin Miguel de Guemes",
        "id": "Jenderal Martin Miguel de Guemes"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.6744733,
        "lng": -65.0467556
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.0867556,
        -24.7144733,
        -65.0067556,
        -24.6344733
      ]
    },
    {
      "id": 20860,
      "name": "Saint-Lo",
      "names": {
        "zh": "圣 - 罗",
        "ja": "サン・LOの",
        "th": "นักบุญทองหล่อ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1157004,
        "lng": -1.0906637
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.1267218,
        49.0854528,
        -1.0357279,
        49.1390605
      ]
    },
    {
      "id": 20862,
      "name": "Bressuire",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.84019205,
        "lng": -0.49221660128968314
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.4922863,
        46.8401098,
        -0.4921409,
        46.8402553
      ]
    },
    {
      "id": 20865,
      "name": "Busra ash Sham",
      "names": {
        "zh": "布什阿什假",
        "ja": "ブスラの灰の偽",
        "pt": "Shamsh Sham"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.51468,
        "lng": 36.4819
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 20873,
      "name": "Cognac",
      "names": {
        "es": "Coñac",
        "zh": "干邑",
        "ja": "コニャック",
        "th": "คอนยัค",
        "pt": "Conhaque"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6931647,
        "lng": -0.3250175
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.3735718,
        45.674706,
        -0.3016747,
        45.7173828
      ]
    },
    {
      "id": 20881,
      "name": "Enkhuizen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.69956,
        "lng": 5.287993
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.282993,
        52.69456,
        5.292993,
        52.70456
      ]
    },
    {
      "id": 20890,
      "name": "Tingloy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.6619736,
        "lng": 120.8695102
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        120.6961149,
        13.5666104,
        121.0033124,
        13.7945838
      ]
    },
    {
      "id": 20892,
      "name": "Selestat",
      "names": {
        "zh": "赛勒斯塔特",
        "de": "Sélestat"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.2594396,
        "lng": 7.454217
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.4064339,
        48.2035904,
        7.5117476,
        48.2975632
      ]
    },
    {
      "id": 20898,
      "name": "Karcag",
      "names": {
        "zh": "考尔曹格"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.314646,
        "lng": 20.9240941
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.7487362,
        47.1896346,
        21.0305648,
        47.4309078
      ]
    },
    {
      "id": 20924,
      "name": "Varpalota",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.198695,
        "lng": 18.138157
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.0786029,
        47.1639152,
        18.2277974,
        47.2647432
      ]
    },
    {
      "id": 20928,
      "name": "Hongshui",
      "names": {
        "zh": "洪水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5050225,
        "lng": 100.7636526
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.3831733,
        38.1477136,
        101.0114665,
        38.8058522
      ]
    },
    {
      "id": 20934,
      "name": "Polonuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.77697,
        "lng": -74.85344
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.87344,
        10.75697,
        -74.83344,
        10.79697
      ]
    },
    {
      "id": 20938,
      "name": "San Jose Ojetenam",
      "names": {
        "zh": "圣何塞ojetenam",
        "ja": "サンノゼオヘテナム"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.21667,
        "lng": -91.96667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.98667,
        15.19667,
        -91.94667,
        15.23667
      ]
    },
    {
      "id": 20940,
      "name": "Kufstein",
      "names": {
        "zh": "库夫施泰因"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.5829718,
        "lng": 12.165727
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        12.160727,
        47.5779718,
        12.170727,
        47.5879718
      ]
    },
    {
      "id": 20946,
      "name": "Ban Bueng",
      "names": {
        "fr": "Banancier",
        "ja": "バンブンジェン",
        "th": "บ้านบึง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3102264,
        "lng": 101.1156016
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.0756016,
        13.2702264,
        101.1556016,
        13.3502264
      ]
    },
    {
      "id": 20950,
      "name": "Urumita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.5611198,
        "lng": -73.0149173
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0549173,
        10.5211198,
        -72.9749173,
        10.6011198
      ]
    },
    {
      "id": 20952,
      "name": "Naklo nad Notecia",
      "names": {
        "fr": "NAKLO NAD NOCIA",
        "it": "Naklo nad notacia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.140895,
        "lng": 17.598648
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.562106,
        53.127419,
        17.640181,
        53.157478
      ]
    },
    {
      "id": 20957,
      "name": "Ziar nad Hronom",
      "names": {
        "zh": "ziar nad hromen"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5910183,
        "lng": 18.8500892
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.645409,
        48.477197,
        19.033438,
        48.769768
      ]
    },
    {
      "id": 20959,
      "name": "Jakobstad",
      "names": {
        "zh": "雅各布斯塔德"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.6744703,
        "lng": 22.7055385
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.9787569,
        63.610415,
        22.8234406,
        63.8329791
      ]
    },
    {
      "id": 20977,
      "name": "Keszthely",
      "names": {
        "zh": "凯斯特海伊",
        "ja": "ケストヘイ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.7675081,
        "lng": 17.2462801
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.1872595,
        46.6695618,
        17.2975336,
        46.8283197
      ]
    },
    {
      "id": 20981,
      "name": "Malacky",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4362992,
        "lng": 17.0200447
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        16.9776094,
        48.3982258,
        17.0587824,
        48.4792552
      ]
    },
    {
      "id": 20982,
      "name": "Rawah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.483682,
        "lng": 41.915727
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        41.896021,
        34.460629,
        41.937077,
        34.497877
      ]
    },
    {
      "id": 20990,
      "name": "Norak",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.38917,
        "lng": 69.32272
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        69.30272,
        38.36917,
        69.34272,
        38.40917
      ]
    },
    {
      "id": 20996,
      "name": "Roznava",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6602188,
        "lng": 20.5345137
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.5344637,
        48.6601688,
        20.5345637,
        48.6602688
      ]
    },
    {
      "id": 20999,
      "name": "Meerssen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.886156,
        "lng": 5.75284
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.725146,
        50.865972,
        5.799842,
        50.899499
      ]
    },
    {
      "id": 21000,
      "name": "Riom",
      "names": {
        "zh": "里永"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.893068,
        "lng": 3.1136667
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.0712281,
        45.8646726,
        3.1841558,
        45.9227866
      ]
    },
    {
      "id": 21007,
      "name": "Heinola",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2027188,
        "lng": 26.031371
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.8010615,
        61.0709221,
        26.5136645,
        61.425806
      ]
    },
    {
      "id": 21014,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 18.16029045,
        "lng": -90.74447495056829
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -91.3284181,
        17.8124246,
        -90.2355445,
        18.5076902
      ]
    },
    {
      "id": 21024,
      "name": "Hoxut",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.25861,
        "lng": 86.85778
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        86.83778,
        42.23861,
        86.87778,
        42.27861
      ]
    },
    {
      "id": 21026,
      "name": "Yellowknife",
      "names": {
        "zh": "耶洛奈夫",
        "ja": "イエローナイフ",
        "th": "เยลโลว์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 62.4540807,
        "lng": -114.377385
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -114.518176,
        62.4081428,
        -114.299997,
        62.5416714
      ]
    },
    {
      "id": 21040,
      "name": "Tapalpa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9448978,
        "lng": -103.7576028
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.7976028,
        19.9048978,
        -103.7176028,
        19.9848978
      ]
    },
    {
      "id": 21042,
      "name": "Someren",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.385222,
        "lng": 5.714045
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.656778,
        51.364892,
        5.73937,
        51.418548
      ]
    },
    {
      "id": 21054,
      "name": "Amatan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3732264,
        "lng": -92.8180813
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.8580813,
        17.3332264,
        -92.7780813,
        17.4132264
      ]
    },
    {
      "id": 21065,
      "name": "Jesus Maria",
      "names": {
        "fr": "Jésus Maria",
        "zh": "耶稣玛丽亚",
        "it": "Gesù Maria",
        "id": "Yesus Maria",
        "ja": "イエスマリア",
        "th": "พระเยซูมาเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.64794705,
        "lng": -102.10757376228773
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.2860187,
        20.5101895,
        -101.9511437,
        20.7868365
      ]
    },
    {
      "id": 21078,
      "name": "Magitang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.9399327,
        "lng": 102.0285599
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.6304396,
        35.6586244,
        102.1219191,
        36.1644313
      ]
    },
    {
      "id": 21082,
      "name": "Kantharalak",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6449974,
        "lng": 104.6479952
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.6079952,
        14.6049974,
        104.6879952,
        14.6849974
      ]
    },
    {
      "id": 21089,
      "name": "Vila Real de Santo Antonio",
      "names": {
        "pt": "Vila Real de Santo Antônio"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1972585,
        "lng": -7.4151114
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.4151614,
        37.1972085,
        -7.4150614,
        37.1973085
      ]
    },
    {
      "id": 21096,
      "name": "Starodub",
      "names": {
        "zh": "斯塔罗杜布"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.58444,
        "lng": 32.76333
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        32.74333,
        52.56444,
        32.78333,
        52.60444
      ]
    },
    {
      "id": 21098,
      "name": "Sipoo",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.37752,
        "lng": 25.26906
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.24906,
        60.35752,
        25.28906,
        60.39752
      ]
    },
    {
      "id": 21102,
      "name": "Lancut",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.068709,
        "lng": 22.231693
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.186768429,
        50.041178024,
        22.262287449,
        50.097950972
      ]
    },
    {
      "id": 21114,
      "name": "Krasnystaw",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.98656,
        "lng": 23.174045
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.095072229,
        50.953603933,
        23.244508506,
        51.030174462
      ]
    },
    {
      "id": 21116,
      "name": "Dalanzadgad",
      "names": {
        "zh": "达兰扎达嘎德"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 43.57083,
        "lng": 104.425
      },
      "country": "Mongolia",
      "importance": 2,
      "bbox": [
        104.405,
        43.55083,
        104.445,
        43.59083
      ]
    },
    {
      "id": 21128,
      "name": "Bad Tolz",
      "names": {
        "es": "Mal tolz",
        "fr": "Mauvais tolz",
        "zh": "糟糕的totz",
        "de": "Böse Tölz",
        "it": "Cattivo tolz",
        "id": "Tolz buruk",
        "ja": "悪いトルツ",
        "pt": "Mau tolz"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7610641,
        "lng": 11.5582933
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.529361,
        47.7402752,
        11.6127155,
        47.8295446
      ]
    },
    {
      "id": 21129,
      "name": "Pultusk",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.701282,
        "lng": 21.081135
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.040387,
        52.673263,
        21.130073,
        52.729901
      ]
    },
    {
      "id": 21131,
      "name": "Izra",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8546282,
        "lng": 36.2420324
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.2020324,
        32.8146282,
        36.2820324,
        32.8946282
      ]
    },
    {
      "id": 21145,
      "name": "Balta",
      "names": {
        "zh": "巴尔塔",
        "th": "บอลต้า"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9363162,
        "lng": 29.6225979
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.5876391,
        47.8966127,
        29.6633,
        47.9673691
      ]
    },
    {
      "id": 21148,
      "name": "Wittlich",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9850353,
        "lng": 6.88844
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.8310723,
        49.9555546,
        6.9833582,
        50.0307388
      ]
    },
    {
      "id": 21160,
      "name": "Haldensleben",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2812243,
        "lng": 11.414817787840292
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.4147321,
        52.2811802,
        11.4148993,
        52.2812688
      ]
    },
    {
      "id": 21164,
      "name": "Lieto",
      "names": {
        "zh": "列托"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.502502,
        "lng": 22.4532745
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.4532245,
        60.502452,
        22.4533245,
        60.502552
      ]
    },
    {
      "id": 21171,
      "name": "San Antonio Sacatepequez",
      "names": {
        "fr": "San Antonio SacatePequezez",
        "de": "San Antonio Sacatpequez",
        "th": "ซานอันโตนิโอ Sacatepequez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.96667,
        "lng": -91.73333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.75333,
        14.94667,
        -91.71333,
        14.98667
      ]
    },
    {
      "id": 21177,
      "name": "Texcaltitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.930788,
        "lng": -99.941383
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.950111,
        18.919149,
        -99.933299,
        18.937351
      ]
    },
    {
      "id": 21185,
      "name": "Eersel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.361387,
        "lng": 5.320895
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.263411,
        51.318237,
        5.35255,
        51.382295
      ]
    },
    {
      "id": 21186,
      "name": "Korsholm",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.1247621,
        "lng": 21.6941345
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        20.3057175,
        62.8939893,
        22.0588536,
        63.6309317
      ]
    },
    {
      "id": 21188,
      "name": "Moncao",
      "names": {
        "zh": "蒙桑",
        "pt": "Monção"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 42.0702329,
        "lng": -8.477799657722652
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.496235,
        42.0588063,
        -8.4691321,
        42.0819065
      ]
    },
    {
      "id": 21192,
      "name": "Soure",
      "names": {
        "zh": "索里"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.055016,
        "lng": -8.6394861
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6444861,
        40.050016,
        -8.6344861,
        40.060016
      ]
    },
    {
      "id": 21202,
      "name": "Henichesk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.17592,
        "lng": 34.8034
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.7834,
        46.15592,
        34.8234,
        46.19592
      ]
    },
    {
      "id": 21203,
      "name": "Dolynska",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1152632,
        "lng": 32.7601379
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.7551379,
        48.1102632,
        32.7651379,
        48.1202632
      ]
    },
    {
      "id": 21206,
      "name": "Groesbeek",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7741022,
        "lng": 5.9305385
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8793975,
        51.7410286,
        5.992067,
        51.8105602
      ]
    },
    {
      "id": 21217,
      "name": "Sommerda",
      "names": {
        "de": "Sömmerda"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1618258,
        "lng": 11.1174874
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.0414293,
        51.1217144,
        11.2379628,
        51.2461817
      ]
    },
    {
      "id": 21225,
      "name": "Kabudarahang",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.20924,
        "lng": 48.72341
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.70341,
        35.18924,
        48.74341,
        35.22924
      ]
    },
    {
      "id": 21228,
      "name": "Dolny Kubin",
      "names": {
        "pt": "Golny kubin"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2145239,
        "lng": 19.2953168
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.077564,
        49.136553,
        19.557881,
        49.332973
      ]
    },
    {
      "id": 21231,
      "name": "Druten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8885167,
        "lng": 5.6048981
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.5309813,
        51.8362738,
        5.6850138,
        51.9018036
      ]
    },
    {
      "id": 21237,
      "name": "Jamiltepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2822329,
        "lng": -97.8223827
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.8623827,
        16.2422329,
        -97.7823827,
        16.3222329
      ]
    },
    {
      "id": 21240,
      "name": "Dillingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5812791,
        "lng": 10.4951026
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.4414699,
        48.5350752,
        10.627284,
        48.6198944
      ]
    },
    {
      "id": 21247,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9737744,
        "lng": -75.3613093
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4275839,
        5.8576303,
        -75.2697132,
        6.0453384
      ]
    },
    {
      "id": 21248,
      "name": "Lumphat",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 13.4998503,
        "lng": 106.9736284
      },
      "country": "Cambodia",
      "importance": 2,
      "bbox": [
        106.9336284,
        13.4598503,
        107.0136284,
        13.5398503
      ]
    },
    {
      "id": 21258,
      "name": "Huehuetla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1075,
        "lng": -97.623333
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.663333,
        20.0675,
        -97.583333,
        20.1475
      ]
    },
    {
      "id": 21259,
      "name": "El Quetzal",
      "names": {
        "ja": "エルケツァル"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7687385,
        "lng": -91.8172973
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.8372973,
        14.7487385,
        -91.7972973,
        14.7887385
      ]
    },
    {
      "id": 21264,
      "name": "Vera",
      "names": {
        "zh": "维拉",
        "ja": "ヴェラ",
        "th": "หางจระเข้"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.4577916,
        "lng": -60.2158054
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.9407863,
        -29.6492513,
        -60.0630853,
        -29.2197249
      ]
    },
    {
      "id": 21269,
      "name": "Cuquio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9286245,
        "lng": -103.0225269
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.0625269,
        20.8886245,
        -102.9825269,
        20.9686245
      ]
    },
    {
      "id": 21277,
      "name": "Tak Bai",
      "names": {
        "zh": "塔布",
        "ja": "タクバイ",
        "th": "ตากใบ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.2597802,
        "lng": 102.0533967
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.0133967,
        6.2197802,
        102.0933967,
        6.2997802
      ]
    },
    {
      "id": 21278,
      "name": "Pastavy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.1129217,
        "lng": 26.83992
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.7483693,
        55.087747,
        26.8928656,
        55.1366692
      ]
    },
    {
      "id": 21280,
      "name": "La Ligua",
      "names": {
        "ja": "ラリグア"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.4496375,
        "lng": -71.2316695
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -71.5420566,
        -32.6209331,
        -71.0455504,
        -32.127327
      ]
    },
    {
      "id": 21288,
      "name": "Tlalnelhuayocan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.564324,
        "lng": -96.973686
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.975978,
        19.56071,
        -96.971714,
        19.568153
      ]
    },
    {
      "id": 21306,
      "name": "Luneville",
      "names": {
        "fr": "Lunéville",
        "de": "Lunéville",
        "it": "Lunéville"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5877774,
        "lng": 6.4971142
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.4921142,
        48.5827774,
        6.5021142,
        48.5927774
      ]
    },
    {
      "id": 21307,
      "name": "Ashkezar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.000526,
        "lng": 54.2071804
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        54.1671804,
        31.960526,
        54.2471804,
        32.040526
      ]
    },
    {
      "id": 21309,
      "name": "Vista Hermosa de Negrete",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.271989,
        "lng": -102.476397
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.491776,
        20.257927,
        -102.463969,
        20.284482
      ]
    },
    {
      "id": 21312,
      "name": "Amares",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.63119345,
        "lng": -8.34888765257488
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3577734,
        41.6242721,
        -8.3400219,
        41.6382348
      ]
    },
    {
      "id": 21317,
      "name": "Ixtlahuacan del Rio",
      "names": {
        "id": "Ixtahuacan del rio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.863708,
        "lng": -103.243554
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.253326,
        20.854317,
        -103.229357,
        20.872617
      ]
    },
    {
      "id": 21321,
      "name": "Gostynin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4287607,
        "lng": 19.4612956
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.4054452,
        52.386006,
        19.5197145,
        52.4624554
      ]
    },
    {
      "id": 21337,
      "name": "Preveza",
      "names": {
        "zh": "普雷韦扎"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.9571673,
        "lng": 20.7516209
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        20.5916209,
        38.7971673,
        20.9116209,
        39.1171673
      ]
    },
    {
      "id": 21340,
      "name": "Zuidhorn",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2481468,
        "lng": 6.4067456
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.4017456,
        53.2431468,
        6.4117456,
        53.2531468
      ]
    },
    {
      "id": 21343,
      "name": "Rawa Mazowiecka",
      "names": {
        "fr": "Raw mazowiecka",
        "th": "raawa mazowiecka"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.766738,
        "lng": 20.2457314
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.2407314,
        51.761738,
        20.2507314,
        51.771738
      ]
    },
    {
      "id": 21344,
      "name": "Alzey",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7503435,
        "lng": 8.1097474
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1047474,
        49.7453435,
        8.1147474,
        49.7553435
      ]
    },
    {
      "id": 21352,
      "name": "Pruzhany",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.556,
        "lng": 24.4573
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.4373,
        52.536,
        24.4773,
        52.576
      ]
    },
    {
      "id": 21355,
      "name": "Proletarsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.702586,
        "lng": 41.718077
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        41.687,
        46.687389,
        41.737888,
        46.714279
      ]
    },
    {
      "id": 21357,
      "name": "Banovce nad Bebravou",
      "names": {
        "es": "Banove nad bebravou",
        "de": "Banovce nad Braun"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.720282,
        "lng": 18.257796
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.236433,
        48.709818,
        18.289736,
        48.734848
      ]
    },
    {
      "id": 21379,
      "name": "Salcaja",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.88333,
        "lng": -91.45
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.47,
        14.86333,
        -91.43,
        14.90333
      ]
    },
    {
      "id": 21392,
      "name": "Prenzlau",
      "names": {
        "zh": "普伦茨劳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3167,
        "lng": 13.866575
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.7553867,
        53.2414002,
        13.9625551,
        53.4014367
      ]
    },
    {
      "id": 21394,
      "name": "Qitai",
      "names": {
        "zh": "奇台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5416105,
        "lng": 113.5793431
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.4432831,
        41.4444095,
        113.7176665,
        41.6662594
      ]
    },
    {
      "id": 21395,
      "name": "Jiaoxiyakou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.12829,
        "lng": 102.45003
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.43003,
        26.10829,
        102.47003,
        26.14829
      ]
    },
    {
      "id": 21402,
      "name": "Kalynivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4583688,
        "lng": 28.5262638
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.4973825,
        49.4055563,
        28.5519603,
        49.4801644
      ]
    },
    {
      "id": 21407,
      "name": "Stord",
      "names": {
        "zh": "斯图尔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.82502055,
        "lng": 5.488370639966453
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.3206214,
        59.7235147,
        5.6508179,
        59.9235592
      ]
    },
    {
      "id": 21408,
      "name": "Naantali",
      "names": {
        "zh": "纳坦利"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4689438,
        "lng": 22.027658
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.027608,
        60.4688938,
        22.027708,
        60.4689938
      ]
    },
    {
      "id": 21422,
      "name": "Naousa",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.62944,
        "lng": 22.06806
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.96806,
        40.52944,
        22.16806,
        40.72944
      ]
    },
    {
      "id": 21425,
      "name": "Tado",
      "names": {
        "zh": "多度"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.2639656,
        "lng": -76.5587372
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6211925,
        5.1198583,
        -76.1306331,
        5.4145842
      ]
    },
    {
      "id": 21428,
      "name": "Contamana",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -7.3504619,
        "lng": -75.0090611
      },
      "country": "Peru",
      "importance": 2,
      "bbox": [
        -75.9855566,
        -8.7161387,
        -74.4995016,
        -7.139272
      ]
    },
    {
      "id": 21431,
      "name": "Polohy",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.4784803,
        "lng": 36.2531412
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.165225,
        47.445417,
        36.361227,
        47.4973214
      ]
    },
    {
      "id": 21432,
      "name": "Pirkkala",
      "names": {
        "zh": "皮尔卡拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4655292,
        "lng": 23.645289
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.4771834,
        61.3569611,
        23.7411182,
        61.4874693
      ]
    },
    {
      "id": 21435,
      "name": "Paks",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6229468,
        "lng": 18.8589364
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.7261346,
        46.5244058,
        18.917569,
        46.7151667
      ]
    },
    {
      "id": 21439,
      "name": "Mo i Rana",
      "names": {
        "zh": "莫伊尔朗纳",
        "de": "Mo ich rana",
        "ja": "Mo Irana",
        "pt": "Mo eu rana"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 66.322298,
        "lng": 14.157211
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        14.089676,
        66.299816,
        14.268634,
        66.35124
      ]
    },
    {
      "id": 21442,
      "name": "Laukaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.416667,
        "lng": 25.95
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.579132,
        62.1806728,
        26.3309722,
        62.583589
      ]
    },
    {
      "id": 21447,
      "name": "Hardinxveld-Giessendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8247452,
        "lng": 4.8503697
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8503197,
        51.8246952,
        4.8504197,
        51.8247952
      ]
    },
    {
      "id": 21451,
      "name": "Velez",
      "names": {
        "es": "Vélez",
        "zh": "贝莱斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2425777,
        "lng": -73.70512852391471
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7882479,
        5.9273953,
        -73.5950023,
        6.5574971
      ]
    },
    {
      "id": 21459,
      "name": "Piatykhatky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4124572,
        "lng": 33.7056731
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.6232667,
        48.387623,
        33.7387091,
        48.4317856
      ]
    },
    {
      "id": 21464,
      "name": "Tocaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4579274,
        "lng": -74.6342234
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.753856,
        4.3613776,
        -74.5463964,
        4.6160578
      ]
    },
    {
      "id": 21472,
      "name": "Monor",
      "names": {
        "zh": "莫诺尔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3527433,
        "lng": 19.4468404
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.382582,
        47.2902251,
        19.51029,
        47.3935299
      ]
    },
    {
      "id": 21490,
      "name": "Starobilsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.27832,
        "lng": 38.91069
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.89069,
        49.25832,
        38.93069,
        49.29832
      ]
    },
    {
      "id": 21495,
      "name": "Sortavala",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 61.70274,
        "lng": 30.691231
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        30.6580082,
        61.6843315,
        30.7381809,
        61.7232274
      ]
    },
    {
      "id": 21500,
      "name": "Oirschot",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.506796,
        "lng": 5.316709
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.273054,
        51.462315,
        5.391187,
        51.551352
      ]
    },
    {
      "id": 21515,
      "name": "Heerde",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4056303,
        "lng": 6.0413984003658525
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9510049,
        52.3565124,
        6.1305529,
        52.4548064
      ]
    },
    {
      "id": 21519,
      "name": "Soma",
      "names": {
        "zh": "索马",
        "ja": "相馬",
        "th": "โสม"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.185172,
        "lng": 27.6088792
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.4000021,
        39.0145157,
        27.806964,
        39.407116
      ]
    },
    {
      "id": 21527,
      "name": "Orodara",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 10.9821014,
        "lng": -4.9148962
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -4.9548962,
        10.9421014,
        -4.8748962,
        11.0221014
      ]
    },
    {
      "id": 21533,
      "name": "Marktoberdorf",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7780871,
        "lng": 10.6188796
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5298134,
        47.6943116,
        10.7126756,
        47.8056518
      ]
    },
    {
      "id": 21545,
      "name": "Bad Reichenhall",
      "names": {
        "es": "Mal reichenhall",
        "zh": "坏乐园",
        "id": "Reichenhall buruk",
        "ja": "悪いレイチェンボール",
        "th": "Reichenhall ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7222676,
        "lng": 12.8760923
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.7972223,
        47.6809196,
        12.9448157,
        47.7722781
      ]
    },
    {
      "id": 21565,
      "name": "Bergeijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3211501,
        "lng": 5.3569427
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.225972,
        51.2621854,
        5.4174102,
        51.3476575
      ]
    },
    {
      "id": 21567,
      "name": "Vovchansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.29078,
        "lng": 36.94108
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.92108,
        50.27078,
        36.96108,
        50.31078
      ]
    },
    {
      "id": 21576,
      "name": "Filomeno Mata",
      "names": {
        "id": "Filomeno tata"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1991098,
        "lng": -97.7032375
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.7432375,
        20.1591098,
        -97.6632375,
        20.2391098
      ]
    },
    {
      "id": 21579,
      "name": "Bad Durkheim",
      "names": {
        "es": "Mal durkheim",
        "zh": "糟糕的durkheim",
        "de": "Bad Dürkheim",
        "it": "Cattivo durkheim",
        "ja": "悪いDurkheim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.460881549999996,
        "lng": 8.170990642657948
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.1703369,
        49.4606319,
        8.1717552,
        49.4611428
      ]
    },
    {
      "id": 21584,
      "name": "Weissenburg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.0305748,
        "lng": 10.9718974
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.8878275,
        48.9536045,
        11.087902,
        49.0644829
      ]
    },
    {
      "id": 21614,
      "name": "Orestiada",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 41.5030836,
        "lng": 26.5329437
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        26.4929437,
        41.4630836,
        26.5729437,
        41.5430836
      ]
    },
    {
      "id": 21619,
      "name": "Sam Phran",
      "names": {
        "zh": "萨姆·普尔",
        "ja": "サムプラーン",
        "th": "สามพราน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.7258154,
        "lng": 100.2138628
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.1738628,
        13.6858154,
        100.2538628,
        13.7658154
      ]
    },
    {
      "id": 21638,
      "name": "Moloacan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9855445,
        "lng": -94.347295
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.367295,
        17.9655445,
        -94.327295,
        18.0055445
      ]
    },
    {
      "id": 21639,
      "name": "Kristiansund",
      "names": {
        "zh": "克里斯蒂安松"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 63.1118483,
        "lng": 7.7323889
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.6923889,
        63.0718483,
        7.7723889,
        63.1518483
      ]
    },
    {
      "id": 21652,
      "name": "Asuncion Nochixtlan",
      "names": {
        "es": "Asunción nochixtlan",
        "zh": "亚松森诺奇克兰",
        "it": "Asuncion Nchixtlan",
        "pt": "Assunção Nochixtlan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.457222,
        "lng": -97.225
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.265,
        17.417222,
        -97.185,
        17.497222
      ]
    },
    {
      "id": 21657,
      "name": "Miedzyrzecz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4448261,
        "lng": 15.578637
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.5317002,
        52.4238908,
        15.6275559,
        52.4683725
      ]
    },
    {
      "id": 21663,
      "name": "San Blas Atempa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3261518,
        "lng": -95.2230634
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.2630634,
        16.2861518,
        -95.1830634,
        16.3661518
      ]
    },
    {
      "id": 21664,
      "name": "Tha Bo",
      "names": {
        "zh": "博博",
        "th": "ท่าบ่อ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.850062,
        "lng": 102.581861
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.561675124,
        17.7553502123,
        102.597015642,
        17.8884619098
      ]
    },
    {
      "id": 21665,
      "name": "Campo de la Cruz",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.3801633,
        "lng": -74.8814116
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9214116,
        10.3401633,
        -74.8414116,
        10.4201633
      ]
    },
    {
      "id": 21667,
      "name": "Kherameh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.49896,
        "lng": 53.31199
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.29199,
        29.47896,
        53.33199,
        29.51896
      ]
    },
    {
      "id": 21673,
      "name": "Ponedera",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.64104,
        "lng": -74.75026
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.77026,
        10.62104,
        -74.73026,
        10.66104
      ]
    },
    {
      "id": 21690,
      "name": "Nara",
      "names": {
        "zh": "奈良",
        "ja": "奈良",
        "th": "นารา"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 38.8927368,
        "lng": -77.02292005112255
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -77.0235591,
        38.8924185,
        -77.0222789,
        38.8931971
      ]
    },
    {
      "id": 21700,
      "name": "Taft",
      "names": {
        "zh": "塔夫脱",
        "th": "เทฟท์"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.752049,
        "lng": 54.217422
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        54.1458358,
        31.7285157,
        54.23221,
        31.7741881
      ]
    },
    {
      "id": 21704,
      "name": "Bekes",
      "names": {
        "de": "Békés"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.772556,
        "lng": 21.128945
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.100201,
        46.75435,
        21.161436,
        46.797527
      ]
    },
    {
      "id": 21718,
      "name": "San Jose Tenango",
      "names": {
        "zh": "圣何塞Tenango",
        "id": "San Jose Tenaango",
        "ja": "サンノゼテナンゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.150398,
        "lng": -96.717866
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.724399,
        18.146161,
        -96.711728,
        18.155581
      ]
    },
    {
      "id": 21722,
      "name": "Svatove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4089441,
        "lng": 38.1650529
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.1600529,
        49.4039441,
        38.1700529,
        49.4139441
      ]
    },
    {
      "id": 21736,
      "name": "Bodegraven",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0814538,
        "lng": 4.7462503
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7412503,
        52.0764538,
        4.7512503,
        52.0864538
      ]
    },
    {
      "id": 21748,
      "name": "Garwolin",
      "names": {
        "zh": "加尔沃林"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8973315,
        "lng": 21.6149645
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.5716479,
        51.8681511,
        21.6747403,
        51.9237516
      ]
    },
    {
      "id": 21751,
      "name": "Nytva",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.9341934,
        "lng": 55.3242997
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        55.3242497,
        57.9341434,
        55.3243497,
        57.9342434
      ]
    },
    {
      "id": 21761,
      "name": "Braniewo",
      "names": {
        "zh": "布拉涅沃"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.3825966,
        "lng": 19.827344
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.7914739,
        54.3548587,
        19.8502056,
        54.4040387
      ]
    },
    {
      "id": 21766,
      "name": "Middelharnis",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7576172,
        "lng": 4.1656476
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.1439014,
        51.71793,
        4.2672695,
        51.8023785
      ]
    },
    {
      "id": 21780,
      "name": "Dobrush",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.4089,
        "lng": 31.3237
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        31.3037,
        52.3889,
        31.3437,
        52.4289
      ]
    },
    {
      "id": 21791,
      "name": "Charata",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -27.2149802,
        "lng": -61.1918776
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.1968776,
        -27.2199802,
        -61.1868776,
        -27.2099802
      ]
    },
    {
      "id": 21795,
      "name": "Sikhio",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.892691,
        "lng": 101.7241481
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.6841481,
        14.852691,
        101.7641481,
        14.932691
      ]
    },
    {
      "id": 21802,
      "name": "Lobos",
      "names": {
        "zh": "洛沃斯",
        "ja": "ロボス",
        "th": "บอส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.1861976,
        "lng": -59.09508533562332
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.1085003,
        -35.1949801,
        -59.0816914,
        -35.1772152
      ]
    },
    {
      "id": 21810,
      "name": "Huitan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.049498,
        "lng": -91.638009
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.6659166,
        15.0063183,
        -91.5732956,
        15.0747753
      ]
    },
    {
      "id": 21818,
      "name": "Isfana",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 39.83895,
        "lng": 69.5276
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        69.5076,
        39.81895,
        69.5476,
        39.85895
      ]
    },
    {
      "id": 21822,
      "name": "San Kamphaeng",
      "names": {
        "zh": "圣坎皮",
        "it": "San Kamamphaeng",
        "ja": "サンカンファエン",
        "th": "สันกำแพง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.7450907,
        "lng": 99.1195292
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.0795292,
        18.7050907,
        99.1595292,
        18.7850907
      ]
    },
    {
      "id": 21843,
      "name": "Puchov",
      "names": {
        "zh": "普霍夫"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.115343,
        "lng": 18.327856
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.227856,
        49.015343,
        18.427856,
        49.215343
      ]
    },
    {
      "id": 21851,
      "name": "Pieksamaki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.300466,
        "lng": 27.1639512
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.6619645,
        62.006444,
        27.6931003,
        62.5300191
      ]
    },
    {
      "id": 21855,
      "name": "Lask",
      "names": {
        "zh": "拉斯克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.5929947,
        "lng": 19.1334784
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0973875,
        51.5807891,
        19.2191946,
        51.6228247
      ]
    },
    {
      "id": 21856,
      "name": "Dombovar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3778989,
        "lng": 18.1332865
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.0899547,
        46.333582,
        18.229557,
        46.4701282
      ]
    },
    {
      "id": 21861,
      "name": "Carsamba",
      "names": {
        "zh": "恰尔尚巴"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1982911,
        "lng": 36.7270196
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.3968623,
        40.930257,
        36.8704564,
        41.3806132
      ]
    },
    {
      "id": 21871,
      "name": "Klaeng",
      "names": {
        "th": "แกลง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.7792276,
        "lng": 101.650471
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.610471,
        12.7392276,
        101.690471,
        12.8192276
      ]
    },
    {
      "id": 21880,
      "name": "Bad Segeberg",
      "names": {
        "fr": "Mauvais segeberg",
        "zh": "坏地贝格",
        "it": "Cattivo Segeberg",
        "id": "Segeberg Bad",
        "ja": "悪いSegeberg",
        "th": "Seegeberg ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.9339402,
        "lng": 10.3078883
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.3028883,
        53.9289402,
        10.3128883,
        53.9389402
      ]
    },
    {
      "id": 21881,
      "name": "Parchim",
      "names": {
        "zh": "帕尔希姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.425275,
        "lng": 11.831089
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.638506,
        53.368331,
        11.938968,
        53.503615
      ]
    },
    {
      "id": 21882,
      "name": "Sint-Oedenrode",
      "names": {
        "zh": "圣彼-oedenrode",
        "ja": "シント・oedenrode",
        "th": "ซิงต์-oedenrode"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5646434,
        "lng": 5.4610004
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3805288,
        51.5212962,
        5.5506935,
        51.6054236
      ]
    },
    {
      "id": 21895,
      "name": "Juanacatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.50972,
        "lng": -103.168679
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.176093,
        20.491947,
        -103.159417,
        20.522944
      ]
    },
    {
      "id": 21899,
      "name": "Cuautitlan",
      "names": {
        "es": "Cuautitlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5568607,
        "lng": -99.1719194
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.1719194,
        19.556789,
        -99.1709845,
        19.5568607
      ]
    },
    {
      "id": 21911,
      "name": "Feyzabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.0222084,
        "lng": 58.7859967
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.7459967,
        34.9822084,
        58.8259967,
        35.0622084
      ]
    },
    {
      "id": 21917,
      "name": "Coronda",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.9744568,
        "lng": -60.915983
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.0351392,
        -32.0784683,
        -60.6641203,
        -31.8526625
      ]
    },
    {
      "id": 21940,
      "name": "General Juan Madariaga",
      "names": {
        "fr": "Général Juan Madariaga",
        "zh": "祝娟麦拉亚省",
        "it": "Generale Juan Madariaga",
        "id": "Jenderal Juan Madariaga",
        "ja": "ジェネラルファンマダリアガ",
        "th": "นายพลฮวนมาดาร์เลย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -36.9952343,
        "lng": -57.1369265
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -57.1769265,
        -37.0352343,
        -57.0969265,
        -36.9552343
      ]
    },
    {
      "id": 21942,
      "name": "Muhradah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.2479,
        "lng": 36.5725
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.5525,
        35.2279,
        36.5925,
        35.2679
      ]
    },
    {
      "id": 21955,
      "name": "Rypin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.0685895,
        "lng": 19.4276249
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.4226249,
        53.0635895,
        19.4326249,
        53.0735895
      ]
    },
    {
      "id": 21959,
      "name": "Anda",
      "names": {
        "zh": "安达",
        "id": "Andari",
        "th": "อันดา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7438762,
        "lng": 124.5756585
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        124.4812364,
        9.5867254,
        124.7339101,
        9.8863631
      ]
    },
    {
      "id": 21962,
      "name": "Yupiltepeque",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.1971168,
        "lng": -89.7926852
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.8126852,
        14.1771168,
        -89.7726852,
        14.2171168
      ]
    },
    {
      "id": 21973,
      "name": "Almolonga",
      "names": {
        "zh": "阿尔莫隆加"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.814975,
        "lng": -91.494748
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.5074235,
        14.787651,
        -91.4613795,
        14.8248573
      ]
    },
    {
      "id": 21980,
      "name": "Oroszlany",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4897011,
        "lng": 18.3148401
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.2456244,
        47.3990614,
        18.3815559,
        47.5229952
      ]
    },
    {
      "id": 21995,
      "name": "Waalre",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.38803,
        "lng": 5.443801
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.417439,
        51.366013,
        5.461801,
        51.405293
      ]
    },
    {
      "id": 22003,
      "name": "Oborniki",
      "names": {
        "zh": "奥博尔尼基"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.64739,
        "lng": 16.81406
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.576233394,
        52.544503436,
        16.9719568,
        52.751460763
      ]
    },
    {
      "id": 22012,
      "name": "Sabana de Torres",
      "names": {
        "ja": "サバナデトーレス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.3939416,
        "lng": -73.4998859
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7767446,
        7.1598183,
        -73.3555645,
        7.6950543
      ]
    },
    {
      "id": 22016,
      "name": "Po",
      "names": {
        "zh": "采购订单",
        "ja": "POを",
        "th": "ปณ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.1667,
        "lng": -1.15
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 22025,
      "name": "Pelaya",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.6857187,
        "lng": -73.6690054
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.8085849,
        8.6480893,
        -73.4283374,
        8.8918831
      ]
    },
    {
      "id": 22027,
      "name": "Naarden",
      "names": {
        "zh": "纳尔登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2957628,
        "lng": 5.1633636
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.095771,
        52.2778164,
        5.2303567,
        52.3287089
      ]
    },
    {
      "id": 22035,
      "name": "Zapotlan del Rey",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4665532,
        "lng": -102.9242866
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.9442866,
        20.4465532,
        -102.9042866,
        20.4865532
      ]
    },
    {
      "id": 22042,
      "name": "Union de San Antonio",
      "names": {
        "es": "Unión de san antonio",
        "zh": "联盟de San Antonio",
        "ja": "ユニオンデサンアントニオ",
        "th": "ยูเนี่ยนเดอซานอันโตนิโอ",
        "pt": "União de San Antonio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1279666,
        "lng": -102.006232
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.046232,
        21.0879666,
        -101.966232,
        21.1679666
      ]
    },
    {
      "id": 22051,
      "name": "Marmande",
      "names": {
        "zh": "马尔芒德"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5007939,
        "lng": 0.164274
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.1087119,
        44.4597141,
        0.2291461,
        44.5430753
      ]
    },
    {
      "id": 22056,
      "name": "Nguigmi",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 14.24953,
        "lng": 13.10921
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        13.08921,
        14.22953,
        13.12921,
        14.26953
      ]
    },
    {
      "id": 22062,
      "name": "Zlotoryja",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1247624,
        "lng": 15.9169833
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.8866197,
        51.1026942,
        15.9504957,
        51.142074
      ]
    },
    {
      "id": 22067,
      "name": "Ommen",
      "names": {
        "zh": "奥门"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5097013,
        "lng": 6.4172206
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.4122206,
        52.5047013,
        6.4222206,
        52.5147013
      ]
    },
    {
      "id": 22071,
      "name": "Yuanquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.5123612,
        "lng": 95.7818298
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        95.7418298,
        40.4723612,
        95.8218298,
        40.5523612
      ]
    },
    {
      "id": 22076,
      "name": "San Bartolo Tutotepec",
      "names": {
        "zh": "圣巴托洛图德普克",
        "ja": "サンバルトロTutotepec",
        "pt": "San Bartolo TutôEpec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.399389,
        "lng": -98.201263
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.2208,
        20.37841,
        -98.1808,
        20.41841
      ]
    },
    {
      "id": 22087,
      "name": "Kongsvinger",
      "names": {
        "zh": "孔斯温厄尔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.1909329,
        "lng": 11.9986784
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.9586784,
        60.1509329,
        12.0386784,
        60.2309329
      ]
    },
    {
      "id": 22107,
      "name": "Salgar",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9631358,
        "lng": -75.9791386
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0928071,
        5.8630835,
        -75.8486196,
        6.0702966
      ]
    },
    {
      "id": 22126,
      "name": "Aliaga",
      "names": {
        "zh": "阿利阿"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8017371,
        "lng": 26.9734041
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.8914534,
        38.6638436,
        27.1885643,
        38.9505067
      ]
    },
    {
      "id": 22137,
      "name": "Pontarlier",
      "names": {
        "zh": "蓬塔利耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9040348,
        "lng": 6.3546345
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.3114632,
        46.8756734,
        6.4478336,
        46.9576321
      ]
    },
    {
      "id": 22141,
      "name": "Hlybokaye",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.139235,
        "lng": 27.6845787
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.6433232,
        55.115446,
        27.722927,
        55.1757361
      ]
    },
    {
      "id": 22143,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 21.484687299999997,
        "lng": -101.17107352368737
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -101.6797627,
        21.1362388,
        -100.8343215,
        21.8394167
      ]
    },
    {
      "id": 22175,
      "name": "Ban Pong",
      "names": {
        "fr": "Interdiction",
        "zh": "禁止乒乓球",
        "de": "Ban-Pong",
        "it": "Divieto",
        "ja": "禁止ポン",
        "th": "บ้านพงษ์",
        "pt": "Proibir pong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8147199,
        "lng": 99.8728201
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8328201,
        13.7747199,
        99.9128201,
        13.8547199
      ]
    },
    {
      "id": 22178,
      "name": "Brielle",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.901662,
        "lng": 4.1626292
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.1352938,
        51.8835002,
        4.2292222,
        51.9314095
      ]
    },
    {
      "id": 22179,
      "name": "Lousa",
      "names": {
        "es": "Lousã",
        "fr": "Lousã",
        "de": "Lousã",
        "pt": "Lousã"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1111532,
        "lng": -8.2461629
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3114688,
        40.0520808,
        -8.1515281,
        40.2055482
      ]
    },
    {
      "id": 22189,
      "name": "Verdun",
      "names": {
        "es": "Verdún",
        "zh": "凡尔登",
        "ja": "ヴェルダン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1589278,
        "lng": 5.3867277
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.2809679,
        49.1126206,
        5.4408527,
        49.1753296
      ]
    },
    {
      "id": 22198,
      "name": "Alcochete",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7560499,
        "lng": -8.9608437
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.0310599,
        38.6713711,
        -8.8102958,
        38.826142
      ]
    },
    {
      "id": 22205,
      "name": "Benito Juarez",
      "names": {
        "es": "Benito Juárez",
        "ja": "ベニートフアレス",
        "th": "เบนิโต Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8169666,
        "lng": -98.17826805337302
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.265503,
        20.7273863,
        -98.0869727,
        20.9066216
      ]
    },
    {
      "id": 22217,
      "name": "Guidan Roumdji",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 13.6576,
        "lng": 6.69567
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        6.67567,
        13.6376,
        6.71567,
        13.6776
      ]
    },
    {
      "id": 22219,
      "name": "Arsanjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.9124,
        "lng": 53.3085
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.2885,
        29.8924,
        53.3285,
        29.9324
      ]
    },
    {
      "id": 22223,
      "name": "Totoro",
      "names": {
        "zh": "龙猫"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5109533,
        "lng": -76.4011501
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.5883856,
        2.3873044,
        -76.2071854,
        2.6060001
      ]
    },
    {
      "id": 22227,
      "name": "Sipacapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2122918,
        "lng": -91.634079
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.654079,
        15.1922918,
        -91.614079,
        15.2322918
      ]
    },
    {
      "id": 22232,
      "name": "Schijndel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6176954,
        "lng": 5.4362541
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.3709476,
        51.5869553,
        5.5021094,
        51.6557026
      ]
    },
    {
      "id": 22242,
      "name": "Lam Luk Ka",
      "names": {
        "zh": "林禄川",
        "th": "ลำลูกกา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9349159,
        "lng": 100.7483626
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.7257381,
        13.9175496,
        100.7723243,
        13.9548488
      ]
    },
    {
      "id": 22248,
      "name": "Totutla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.211209,
        "lng": -96.960601
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.976193,
        19.2009,
        -96.950236,
        19.219301
      ]
    },
    {
      "id": 22256,
      "name": "Tougan",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.0690536,
        "lng": -3.0695369
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -3.1095369,
        13.0290536,
        -3.0295369,
        13.1090536
      ]
    },
    {
      "id": 22258,
      "name": "As Suqaylibiyah",
      "names": {
        "es": "Como suqaylibiyah",
        "fr": "Comme Suqaylibiyah",
        "zh": "作为Suqaylibiyah",
        "de": "Als suqaylibiyah",
        "it": "Come suqaylibiyah",
        "id": "Sebagai suqaylibiyah",
        "ja": "Suqaylibiyahとして",
        "th": "เช่นเดียวกับ suqaylibiyah",
        "pt": "Como suqaylibiyah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.756027,
        "lng": 36.939181
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.359195,
        32.541221,
        37.456689,
        33.004731
      ]
    },
    {
      "id": 22264,
      "name": "La Primavera",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4904575,
        "lng": -70.4097672
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -70.4497672,
        5.4504575,
        -70.3697672,
        5.5304575
      ]
    },
    {
      "id": 22276,
      "name": "Brignoles",
      "names": {
        "th": "บริกโนลส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.4064401,
        "lng": 6.0616452
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.9816928,
        43.3427725,
        6.1753074,
        43.4330863
      ]
    },
    {
      "id": 22285,
      "name": "Marche-en-Famenne",
      "names": {
        "zh": "马尔什昂法梅讷",
        "ja": "マルシェ-専用famenne",
        "th": "มาร์เก-ห้อง famenne"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.2265147,
        "lng": 5.3407365
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.2065524,
        50.1573272,
        5.4731502,
        50.2789463
      ]
    },
    {
      "id": 22295,
      "name": "Dabas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.194044,
        "lng": 19.3135183
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.3134683,
        47.193994,
        19.3135683,
        47.194094
      ]
    },
    {
      "id": 22296,
      "name": "Boulsa",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.66664,
        "lng": -0.57469
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 22298,
      "name": "Compostela",
      "names": {
        "fr": "Compostelle",
        "zh": "波斯特拉",
        "ja": "コンポステラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2375206,
        "lng": -104.8993726
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.3723148,
        20.8541356,
        -104.7894424,
        21.3733555
      ]
    },
    {
      "id": 22326,
      "name": "Alotenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4862632,
        "lng": -90.8045297
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.8445297,
        14.4462632,
        -90.7645297,
        14.5262632
      ]
    },
    {
      "id": 22333,
      "name": "Hoganas",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 56.2,
        "lng": 12.566667
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.1174567,
        56.1158837,
        12.7724365,
        56.4773191
      ]
    },
    {
      "id": 22344,
      "name": "Karukh",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 34.491346,
        "lng": 62.5918615
      },
      "country": "Afghanistan",
      "importance": 2,
      "bbox": [
        62.5518615,
        34.451346,
        62.6318615,
        34.531346
      ]
    },
    {
      "id": 22366,
      "name": "Forssa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.815452,
        "lng": 23.62749
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.567828,
        60.789493,
        23.736982,
        60.841301
      ]
    },
    {
      "id": 22379,
      "name": "Echt",
      "names": {
        "zh": "化学疗法"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.1073016,
        "lng": 5.8719765
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8323916,
        51.0639528,
        5.9285512,
        51.1351563
      ]
    },
    {
      "id": 22385,
      "name": "Guaranda",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4674975,
        "lng": -74.535799
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.575799,
        8.4274975,
        -74.495799,
        8.5074975
      ]
    },
    {
      "id": 22389,
      "name": "Skadovsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.1203932,
        "lng": 32.910339
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.8735111,
        46.1044667,
        32.9474719,
        46.1376766
      ]
    },
    {
      "id": 22390,
      "name": "Sigmaringen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0855844,
        "lng": 9.2178879
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.0841246,
        48.0279343,
        9.272465,
        48.154227
      ]
    },
    {
      "id": 22398,
      "name": "El Rosal",
      "names": {
        "it": "El rosale",
        "ja": "エルロロサ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8518464,
        "lng": -74.2620596
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3020596,
        4.8118464,
        -74.2220596,
        4.8918464
      ]
    },
    {
      "id": 22399,
      "name": "Bolhrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.68262,
        "lng": 28.61487
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.59487,
        45.66262,
        28.63487,
        45.70262
      ]
    },
    {
      "id": 22400,
      "name": "Montemor-o-Novo",
      "names": {
        "zh": "montemor-o-诺和",
        "ja": "montemor-o-ノボ",
        "th": "montemor-o-โนโว"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6497062,
        "lng": -8.2177938
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2178438,
        38.6496562,
        -8.2177438,
        38.6497562
      ]
    },
    {
      "id": 22408,
      "name": "Puerto Aysen",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトアイザン",
        "th": "เปอร์โต aysen"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -45.4068167,
        "lng": -72.6976787
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -72.8576787,
        -45.5668167,
        -72.5376787,
        -45.2468167
      ]
    },
    {
      "id": 22409,
      "name": "Kafr Zayta",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3723918,
        "lng": 36.6012977
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.5612977,
        35.3323918,
        36.6412977,
        35.4123918
      ]
    },
    {
      "id": 22415,
      "name": "Veinticinco de Mayo",
      "names": {
        "fr": "Veeticinco de Mayo",
        "de": "VEINTICININCO de Mayo"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5837675,
        "lng": -68.5446503
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -69.0532926,
        -34.6086998,
        -68.4726819,
        -34.2408674
      ]
    },
    {
      "id": 22423,
      "name": "Suesca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1033413,
        "lng": -73.7990059
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.8709479,
        5.0350563,
        -73.6857678,
        5.2312648
      ]
    },
    {
      "id": 22424,
      "name": "Eldorado",
      "names": {
        "zh": "埃尔多拉多",
        "ja": "エルドラド",
        "th": "เอลโดราโด"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.4048555,
        "lng": -54.6262079
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -54.7085484,
        -26.5129042,
        -54.530824,
        -26.3449846
      ]
    },
    {
      "id": 22428,
      "name": "Gennep",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.693686,
        "lng": 5.974166
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.946754,
        51.68371,
        5.99309,
        51.722158
      ]
    },
    {
      "id": 22429,
      "name": "Son en Breugel",
      "names": {
        "es": "Hijo en braugel",
        "fr": "Fils en breugel",
        "zh": "儿子en breugel",
        "de": "Sohn en bheugel",
        "it": "Figlio en breugel",
        "id": "Son en breuugel",
        "ja": "息子en Breugel",
        "pt": "Filho en breugel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5173136,
        "lng": 5.500838
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.4237962,
        51.4939272,
        5.5462728,
        51.5418484
      ]
    },
    {
      "id": 22441,
      "name": "Zandvoort",
      "names": {
        "zh": "赞德福特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3719838,
        "lng": 4.5302209
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4776495,
        52.3139966,
        4.591659,
        52.4051674
      ]
    },
    {
      "id": 22442,
      "name": "Rattaphum",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 7.1342513,
        "lng": 100.2571778
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.2171778,
        7.0942513,
        100.2971778,
        7.1742513
      ]
    },
    {
      "id": 22443,
      "name": "Alcantara",
      "names": {
        "zh": "阿尔坎塔拉",
        "ja": "アルカンタラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.970227,
        "lng": 123.4029243
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.3629243,
        9.930227,
        123.4429243,
        10.010227
      ]
    },
    {
      "id": 22444,
      "name": "Betulia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1134294,
        "lng": -75.9845433
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0488234,
        6.0783383,
        -75.8416057,
        6.2858141
      ]
    },
    {
      "id": 22447,
      "name": "Eutin",
      "names": {
        "zh": "奥伊廷"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.1364642,
        "lng": 10.6159077
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5435324,
        54.1096357,
        10.6896059,
        54.1932158
      ]
    },
    {
      "id": 22450,
      "name": "Heilbad Heiligenstadt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.378411,
        "lng": 10.137341
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.072117,
        51.300845,
        10.218113,
        51.432733
      ]
    },
    {
      "id": 22451,
      "name": "Berezhany",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4450296,
        "lng": 24.938854
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.9070151,
        49.4182487,
        24.9816807,
        49.4716279
      ]
    },
    {
      "id": 22459,
      "name": "Cosautlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.332033,
        "lng": -96.986327
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0108319743,
        19.3249172002,
        -96.9708532975,
        19.342621498
      ]
    },
    {
      "id": 22460,
      "name": "Ayacucho",
      "names": {
        "zh": "阿亚库乔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.1527482,
        "lng": -58.4884187
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.5341725,
        -37.1848278,
        -58.4220737,
        -37.1232509
      ]
    },
    {
      "id": 22470,
      "name": "Guryevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7745571,
        "lng": 20.6037041
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        20.5797939,
        54.7608347,
        20.6416967,
        54.7959061
      ]
    },
    {
      "id": 22488,
      "name": "Turhal",
      "names": {
        "zh": "图尔哈尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3868287,
        "lng": 36.0838319
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.8260383,
        40.2005433,
        36.4476585,
        40.5932951
      ]
    },
    {
      "id": 22491,
      "name": "Leczyca",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0593858,
        "lng": 19.2016434
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.1737149,
        52.0376313,
        19.2245058,
        52.0711518
      ]
    },
    {
      "id": 22498,
      "name": "Noordwijkerhout",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.261511,
        "lng": 4.494781
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.452682,
        52.23245,
        4.532872,
        52.292906
      ]
    },
    {
      "id": 22501,
      "name": "Sawankhalok",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.309955,
        "lng": 99.832535
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.7958882879,
        17.2629349939,
        99.8696367675,
        17.3439462634
      ]
    },
    {
      "id": 22503,
      "name": "Peso da Regua",
      "names": {
        "ja": "ペソダレグア",
        "th": "เงินเปโซดา Regua",
        "pt": "Peso da Rugua"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.162506,
        "lng": -7.789125
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.807542,
        41.151216,
        -7.769073,
        41.172604
      ]
    },
    {
      "id": 22509,
      "name": "Braunau am Inn",
      "names": {
        "zh": "Braunau am旅馆",
        "ja": "ブラウナウアムイン"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2578088,
        "lng": 13.0354126
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.0353626,
        48.2577588,
        13.0354626,
        48.2578588
      ]
    },
    {
      "id": 22518,
      "name": "Kangaba",
      "names": {
        "zh": "康加巴"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 11.9415411,
        "lng": -8.4138589
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -8.5738589,
        11.7815411,
        -8.2538589,
        12.1015411
      ]
    },
    {
      "id": 22525,
      "name": "Tecolotlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2026993,
        "lng": -104.0501972
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.0901972,
        20.1626993,
        -104.0101972,
        20.2426993
      ]
    },
    {
      "id": 22552,
      "name": "Mohacs",
      "names": {
        "zh": "莫哈奇"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 45.9933836,
        "lng": 18.6821079
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.6201889,
        45.9530311,
        18.8687464,
        46.0634149
      ]
    },
    {
      "id": 22563,
      "name": "Kempele",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.91245,
        "lng": 25.5108223
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.4256887,
        64.8424666,
        25.7292671,
        64.9482709
      ]
    },
    {
      "id": 22568,
      "name": "Cajola",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9227109,
        "lng": -91.6149478
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.6394887,
        14.9108214,
        -91.5712816,
        14.9510854
      ]
    },
    {
      "id": 22575,
      "name": "Kozienice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.5849678,
        "lng": 21.549883
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.4998364,
        51.5673247,
        21.5729828,
        51.6047599
      ]
    },
    {
      "id": 22577,
      "name": "Condeixa-a-Nova",
      "names": {
        "fr": "Condeixa-a-nouvelle-",
        "zh": "孔代沙-一个-的Nova",
        "ja": "condeixa・ア・ノヴァ",
        "th": "condeixa รูปแบบโนวา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.11832575,
        "lng": -8.498568763527336
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5105153,
        40.1072439,
        -8.4875518,
        40.1288761
      ]
    },
    {
      "id": 22582,
      "name": "Nallihan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.188822,
        "lng": 31.3503453
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.8343831,
        40.0068857,
        31.7671199,
        40.3716589
      ]
    },
    {
      "id": 22584,
      "name": "Balmazujvaros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.613865,
        "lng": 21.3480385
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.3430385,
        47.608865,
        21.3530385,
        47.618865
      ]
    },
    {
      "id": 22589,
      "name": "Sedan",
      "names": {
        "es": "Sedán",
        "fr": "Berline",
        "zh": "轿车",
        "de": "Limousine",
        "it": "Berlina",
        "ja": "セダン",
        "th": "ซีดาน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.7033759,
        "lng": 4.9433409
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.8771797,
        49.6723166,
        4.9764494,
        49.7262938
      ]
    },
    {
      "id": 22593,
      "name": "Brzesko",
      "names": {
        "zh": "布热斯科"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.968961,
        "lng": 20.608915
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.582368,
        49.952041,
        20.639216,
        49.997023
      ]
    },
    {
      "id": 22605,
      "name": "Konstantinovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.582352,
        "lng": 41.097084
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        41.0626191,
        47.558337,
        41.1328456,
        47.6059044
      ]
    },
    {
      "id": 22609,
      "name": "Nederweert",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.284211,
        "lng": 5.745545
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.643997,
        51.236925,
        5.811703,
        51.329362
      ]
    },
    {
      "id": 22628,
      "name": "Ignacio de la Llave",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.65609715,
        "lng": -95.972654266512
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.0578128,
        18.5176583,
        -95.8655031,
        18.7952578
      ]
    },
    {
      "id": 22633,
      "name": "San Juan del Sur",
      "names": {
        "zh": "圣胡安del sur",
        "ja": "サンファンデルシュル",
        "th": "ซานฮวนเดลซูร์"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.25292,
        "lng": -85.87049
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -85.89049,
        11.23292,
        -85.85049,
        11.27292
      ]
    },
    {
      "id": 22635,
      "name": "Kezmarok",
      "names": {
        "es": "Kežmarok"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.1362088,
        "lng": 20.4308701
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.182251,
        49.031746,
        20.6423521,
        49.408
      ]
    },
    {
      "id": 22639,
      "name": "Zvenyhorodka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0767158,
        "lng": 30.96138
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.9319817,
        49.0400582,
        31.0067405,
        49.1050045
      ]
    },
    {
      "id": 22644,
      "name": "Palestina de los Altos",
      "names": {
        "zh": "巴勒斯蒂娜de los Altos",
        "de": "Palästina de Los Altos",
        "th": "ปาเลสไตน์เดอลอสอัลโตส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.93333,
        "lng": -91.7
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.72,
        14.91333,
        -91.68,
        14.95333
      ]
    },
    {
      "id": 22645,
      "name": "Staphorst",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6372216,
        "lng": 6.2038643
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.2038143,
        52.6371716,
        6.2039143,
        52.6372716
      ]
    },
    {
      "id": 22647,
      "name": "Kronach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.2397846,
        "lng": 11.3277669
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.2647047,
        50.1934125,
        11.4176765,
        50.3032399
      ]
    },
    {
      "id": 22657,
      "name": "Cham",
      "names": {
        "zh": "湛",
        "ja": "チャム",
        "th": "จาม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2178194,
        "lng": 12.6663832
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.5575069,
        49.1356869,
        12.7550058,
        49.2687886
      ]
    },
    {
      "id": 22709,
      "name": "Diepholz",
      "names": {
        "zh": "迪普霍尔茨"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.606262,
        "lng": 8.368818
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.293795,
        52.531413,
        8.447983,
        52.689335
      ]
    },
    {
      "id": 22715,
      "name": "Retiro",
      "names": {
        "zh": "雷蒂罗"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -34.5911503,
        "lng": -58.3746803
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -58.3796803,
        -34.5961503,
        -58.3696803,
        -34.5861503
      ]
    },
    {
      "id": 22718,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.6860512,
        "lng": -53.8069214
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -54.1368461,
        -30.0056759,
        -53.5168897,
        -29.5493913
      ]
    },
    {
      "id": 22725,
      "name": "Bytow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.1694592,
        "lng": 17.4890263
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.4606356,
        54.1557034,
        17.5405976,
        54.203995
      ]
    },
    {
      "id": 22726,
      "name": "Ihosy",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -22.3917992,
        "lng": 46.1142037
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        46.1141537,
        -22.3918492,
        46.1142537,
        -22.3917492
      ]
    },
    {
      "id": 22749,
      "name": "Ashmyany",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4252347,
        "lng": 25.9374195
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        25.9044971,
        54.3997102,
        25.9846519,
        54.4388861
      ]
    },
    {
      "id": 22751,
      "name": "Grojec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8655126,
        "lng": 20.8675017
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.8487159,
        51.8483721,
        20.8962093,
        51.8879572
      ]
    },
    {
      "id": 22757,
      "name": "Jilotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6111499,
        "lng": -96.9052559880419
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9878188,
        19.5786204,
        -96.8414504,
        19.6436674
      ]
    },
    {
      "id": 22759,
      "name": "Saint-Julien-en-Genevois",
      "names": {
        "zh": "圣 - 朱利安-连接genevois",
        "ja": "サンジュリアン・専用genevois",
        "th": "เซนต์มั๊ย-ห้อง genevois"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1444789,
        "lng": 6.0812467
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.0469079,
        46.1229655,
        6.1284651,
        46.1519451
      ]
    },
    {
      "id": 22763,
      "name": "Mateszalka",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9508519,
        "lng": 22.3135893
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        22.3085893,
        47.9458519,
        22.3185893,
        47.9558519
      ]
    },
    {
      "id": 22772,
      "name": "Pran Buri",
      "names": {
        "zh": "普兰伯里",
        "ja": "プランブリー",
        "th": "ปราณบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.384525,
        "lng": 99.923504
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8620161847,
        12.3041272875,
        100.012249139,
        12.4509826278
      ]
    },
    {
      "id": 22773,
      "name": "Krapkowice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.4747406,
        "lng": 17.9670987
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.9080682,
        50.4490016,
        18.0067024,
        50.5082224
      ]
    },
    {
      "id": 22774,
      "name": "Asten",
      "names": {
        "zh": "阿斯滕"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3869026,
        "lng": 5.791446653224481
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6939835,
        51.3338046,
        5.8703596,
        51.4400669
      ]
    },
    {
      "id": 22787,
      "name": "Kelheim",
      "names": {
        "zh": "凯尔海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.91849,
        "lng": 11.8722777
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.7168172,
        48.8537583,
        12.0135427,
        48.9646351
      ]
    },
    {
      "id": 22795,
      "name": "Dehaqan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.94004,
        "lng": 51.64786
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.62786,
        31.92004,
        51.66786,
        31.96004
      ]
    },
    {
      "id": 22796,
      "name": "Shahba",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8529319,
        "lng": 36.6298883
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.5898883,
        32.8129319,
        36.6698883,
        32.8929319
      ]
    },
    {
      "id": 22807,
      "name": "Sao Pedro do Sul",
      "names": {
        "ja": "サンペドロドスル",
        "pt": "São Pedro do Sul"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7610407,
        "lng": -8.0657325
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2550878,
        40.7292205,
        -7.9275126,
        40.9365362
      ]
    },
    {
      "id": 22813,
      "name": "Hajdunanas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8461949,
        "lng": 21.4309148
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.4308648,
        47.8461449,
        21.4309648,
        47.8462449
      ]
    },
    {
      "id": 22814,
      "name": "Menemen",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.60754,
        "lng": 27.06938
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.04938,
        38.58754,
        27.08938,
        38.62754
      ]
    },
    {
      "id": 22823,
      "name": "Stara L'ubovna",
      "names": {
        "es": "Stara l'ubobna",
        "id": "Sara l'Ubovna"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.3083333,
        "lng": 20.6801999
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.6751999,
        49.3033333,
        20.6851999,
        49.3133333
      ]
    },
    {
      "id": 22842,
      "name": "Diksmuide",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0333408,
        "lng": 2.8647185
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        2.7431073,
        50.9746794,
        2.9513418,
        51.1161831
      ]
    },
    {
      "id": 22843,
      "name": "Chinampa de Gorostiza",
      "names": {
        "ja": "チャンパデゴロスザ",
        "th": "ไชน่าเดอกอร์สซอซะ",
        "pt": "Chinama de Gorostiza"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.359781,
        "lng": -97.735959
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.7453129189,
        21.3524287676,
        -97.7273023274,
        21.3672492087
      ]
    },
    {
      "id": 22844,
      "name": "Westervoort",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9629903,
        "lng": 5.9692518
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9642518,
        51.9579903,
        5.9742518,
        51.9679903
      ]
    },
    {
      "id": 22864,
      "name": "Jiblah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.92162,
        "lng": 44.14668
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.12668,
        13.90162,
        44.16668,
        13.94162
      ]
    },
    {
      "id": 22874,
      "name": "Vendome",
      "names": {
        "zh": "旺多姆",
        "ja": "vendôme"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.7934453,
        "lng": 1.0662256
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.0196433,
        47.7588464,
        1.1098969,
        47.8404135
      ]
    },
    {
      "id": 22882,
      "name": "Pajapita",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.723556,
        "lng": -92.0347671
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -92.0747671,
        14.683556,
        -91.9947671,
        14.763556
      ]
    },
    {
      "id": 22883,
      "name": "Kauhava",
      "names": {
        "zh": "考哈瓦"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.0993728,
        "lng": 23.0569547
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.5528421,
        63.021907,
        23.4717957,
        63.3989614
      ]
    },
    {
      "id": 22890,
      "name": "El Pinon",
      "names": {
        "ja": "エルピノン",
        "pt": "Elixo"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4042864,
        "lng": -74.8243075
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8488879,
        10.206301,
        -74.4939918,
        10.4691842
      ]
    },
    {
      "id": 22892,
      "name": "Cabeceiras de Basto",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.512526,
        "lng": -7.992691
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.00942,
        41.49431,
        -7.96942,
        41.53431
      ]
    },
    {
      "id": 22898,
      "name": "Kaiwen",
      "names": {
        "zh": "开文"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1561,
        "lng": 99.83483
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.81483,
        27.1361,
        99.85483,
        27.1761
      ]
    },
    {
      "id": 22916,
      "name": "Ponte de Sor",
      "names": {
        "ja": "ポンテデソル"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2519303,
        "lng": -8.008997
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3438699,
        38.978216,
        -7.8243609,
        39.384634
      ]
    },
    {
      "id": 22954,
      "name": "Zequ",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.03709,
        "lng": 101.46904
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.44904,
        35.01709,
        101.48904,
        35.05709
      ]
    },
    {
      "id": 22956,
      "name": "Guroymak",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.574172,
        "lng": 42.0253272
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.89479,
        38.4556,
        42.28313,
        38.75072
      ]
    },
    {
      "id": 22959,
      "name": "Beek",
      "names": {
        "zh": "贝克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.926876899999996,
        "lng": 5.810685055558121
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.7548455,
        50.8985534,
        5.8569297,
        50.9551433
      ]
    },
    {
      "id": 22964,
      "name": "Federacion",
      "names": {
        "fr": "Fédération",
        "zh": "联合会"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.9856574,
        "lng": -57.9194395
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.0384875,
        -31.2167791,
        -57.875188,
        -30.9566617
      ]
    },
    {
      "id": 22969,
      "name": "Santa Cruz Naranjo",
      "names": {
        "zh": "圣克鲁斯纳兰茹",
        "ja": "サンタクルスナランジョ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3857722,
        "lng": -90.3701016
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.3701516,
        14.3857222,
        -90.3700516,
        14.3858222
      ]
    },
    {
      "id": 22990,
      "name": "Panagyurishte",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.5043065,
        "lng": 24.1888411
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        24.1488411,
        42.4643065,
        24.2288411,
        42.5443065
      ]
    },
    {
      "id": 22994,
      "name": "Hannut",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6724649,
        "lng": 5.0779916
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.9871404,
        50.5984832,
        5.162459,
        50.7161479
      ]
    },
    {
      "id": 22995,
      "name": "Vesoul",
      "names": {
        "zh": "沃苏勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6197395,
        "lng": 6.1544694
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.1302351,
        47.6128103,
        6.1742336,
        47.6519198
      ]
    },
    {
      "id": 22999,
      "name": "Zapotlan de Juarez",
      "names": {
        "es": "Zapotlán de Juárez",
        "th": "คนซ่งฉลอง",
        "pt": "Zapotel de Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.971389,
        "lng": -98.859722
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.899722,
        19.931389,
        -98.819722,
        20.011389
      ]
    },
    {
      "id": 23001,
      "name": "Gryfice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.915826,
        "lng": 15.200738
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.166809,
        53.897403,
        15.238738,
        53.93694
      ]
    },
    {
      "id": 23003,
      "name": "Montbrison",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6072875,
        "lng": 4.0627318
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.0284272,
        45.5762958,
        4.1154284,
        45.626899
      ]
    },
    {
      "id": 23008,
      "name": "Xinyuan",
      "names": {
        "zh": "鑫源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9076908,
        "lng": 106.3914475
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        106.3514475,
        30.8676908,
        106.4314475,
        30.9476908
      ]
    },
    {
      "id": 23036,
      "name": "Yaypan",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.37583,
        "lng": 70.81556
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        70.79556,
        40.35583,
        70.83556,
        40.39583
      ]
    },
    {
      "id": 23051,
      "name": "Kisvarda",
      "names": {
        "zh": "小瓦尔道"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2257593,
        "lng": 22.0784247
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        22.0218609,
        48.1867776,
        22.1279628,
        48.2571638
      ]
    },
    {
      "id": 23056,
      "name": "Differdange",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.52253,
        "lng": 5.8915895
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        5.8865895,
        49.51753,
        5.8965895,
        49.52753
      ]
    },
    {
      "id": 23058,
      "name": "Ivanava",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1459395,
        "lng": 25.5326538
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        25.4864788,
        52.122025,
        25.5737896,
        52.1654051
      ]
    },
    {
      "id": 23067,
      "name": "Reyhanli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.2684483,
        "lng": 36.5672311
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.3128836,
        36.1966493,
        36.7048834,
        36.3631363
      ]
    },
    {
      "id": 23084,
      "name": "Loimaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8475184,
        "lng": 23.060396
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.060346,
        60.8474684,
        23.060446,
        60.8475684
      ]
    },
    {
      "id": 23096,
      "name": "Digne-les-Bains",
      "names": {
        "zh": "迪涅-莱斯班",
        "ja": "digne・レ・バン",
        "th": "digne-เลสแบงส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0918144,
        "lng": 6.2351431
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.1576849,
        44.0321591,
        6.3410434,
        44.1756138
      ]
    },
    {
      "id": 23102,
      "name": "Ayvalik",
      "names": {
        "zh": "艾瓦勒克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.31905,
        "lng": 26.6954
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.6754,
        39.29905,
        26.7154,
        39.33905
      ]
    },
    {
      "id": 23103,
      "name": "Bilohirsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.0584385,
        "lng": 34.594959
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.5205241,
        45.028339,
        34.6240239,
        45.0687395
      ]
    },
    {
      "id": 23106,
      "name": "Tasil",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.83395,
        "lng": 35.96973
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        35.94973,
        32.81395,
        35.98973,
        32.85395
      ]
    },
    {
      "id": 23108,
      "name": "Anori",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0736127,
        "lng": -75.1466433
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.3185553,
        6.9042429,
        -74.8901663,
        7.4503722
      ]
    },
    {
      "id": 23112,
      "name": "Harlingen",
      "names": {
        "zh": "哈林根",
        "th": "ฮาร์ลิงเจน"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1703923,
        "lng": 5.4252094
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.4202094,
        53.1653923,
        5.4302094,
        53.1753923
      ]
    },
    {
      "id": 23114,
      "name": "Olecko",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.03443,
        "lng": 22.50124
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.460235,
        54.010836,
        22.535974,
        54.061562
      ]
    },
    {
      "id": 23126,
      "name": "San Cristobal Cucho",
      "names": {
        "es": "San Cristóbal Cucho",
        "fr": "Cucho de San Cristobal",
        "ja": "サンクリストバルチェチョ",
        "th": "ซาน Cristobal Cucho"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9,
        "lng": -91.78333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.80333,
        14.88,
        -91.76333,
        14.92
      ]
    },
    {
      "id": 23132,
      "name": "Sparti",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.07446,
        "lng": 22.43009
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.41009,
        37.05446,
        22.45009,
        37.09446
      ]
    },
    {
      "id": 23136,
      "name": "Przeworsk",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0670608,
        "lng": 22.505303
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.500303,
        50.0620608,
        22.510303,
        50.0720608
      ]
    },
    {
      "id": 23142,
      "name": "Chai Prakan",
      "names": {
        "zh": "柴普山",
        "ja": "チャイプラカン",
        "th": "ชัยปราพัน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.7345188,
        "lng": 99.1418137
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.1018137,
        19.6945188,
        99.1818137,
        19.7745188
      ]
    },
    {
      "id": 23174,
      "name": "Rygge",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.3779677,
        "lng": 10.7480223
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.7430223,
        59.3729677,
        10.7530223,
        59.3829677
      ]
    },
    {
      "id": 23175,
      "name": "Bang Phae",
      "names": {
        "zh": "邦Phae",
        "de": "Bang-Phae",
        "id": "Orang bodoh",
        "ja": "バーンパ",
        "th": "บางแพ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6940797,
        "lng": 99.927898
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.887898,
        13.6540797,
        99.967898,
        13.7340797
      ]
    },
    {
      "id": 23188,
      "name": "Orimattila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.805137,
        "lng": 25.7333558
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.3949771,
        60.6714701,
        26.2238532,
        60.934555
      ]
    },
    {
      "id": 23194,
      "name": "Aranyaprathet",
      "names": {
        "th": "อรัญประเทศ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6942569,
        "lng": 102.5017325
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.5016825,
        13.6942069,
        102.5017825,
        13.6943069
      ]
    },
    {
      "id": 23201,
      "name": "Shahriston",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 39.7745788,
        "lng": 68.8121753
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        68.7718898,
        39.7618057,
        68.8315462,
        39.7977784
      ]
    },
    {
      "id": 23233,
      "name": "Thermi",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.54712,
        "lng": 23.01967
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.91967,
        40.44712,
        23.11967,
        40.64712
      ]
    },
    {
      "id": 23239,
      "name": "Chateau-Thierry",
      "names": {
        "fr": "Château-Thierry",
        "zh": "城堡-蒂埃里",
        "ja": "シャトー-thierry",
        "th": "ชาโตว์-ธีรี่ร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0457362,
        "lng": 3.4027111
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.3396173,
        49.0303562,
        3.4159109,
        49.0808138
      ]
    },
    {
      "id": 23249,
      "name": "San Antonio Oeste",
      "names": {
        "zh": "圣安东尼奥oeste",
        "ja": "サンアントニオエステ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.7334053,
        "lng": -64.9438696
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.9488696,
        -40.7384053,
        -64.9388696,
        -40.7284053
      ]
    },
    {
      "id": 23255,
      "name": "Guanagazapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2266002,
        "lng": -90.6434927
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.6634927,
        14.2066002,
        -90.6234927,
        14.2466002
      ]
    },
    {
      "id": 23256,
      "name": "Mezokovesd",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.806624,
        "lng": 20.568402
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.542302,
        47.789833,
        20.595546,
        47.823125
      ]
    },
    {
      "id": 23264,
      "name": "Bruck an der Mur",
      "names": {
        "zh": "布鲁克疯狂",
        "ja": "マルを囲みました",
        "th": "Bruck Aner Mur"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4121987,
        "lng": 15.2721668
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.15063,
        47.336785,
        15.360381,
        47.442955
      ]
    },
    {
      "id": 23303,
      "name": "Les Sables-d'Olonne",
      "names": {
        "zh": "les stables-d'olonne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.4999425,
        "lng": -1.7816047
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.7816547,
        46.4998925,
        -1.7815547,
        46.4999925
      ]
    },
    {
      "id": 23305,
      "name": "Okhtyrka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.31036,
        "lng": 34.89879
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.87879,
        50.29036,
        34.91879,
        50.33036
      ]
    },
    {
      "id": 23308,
      "name": "Bar",
      "names": {
        "zh": "杠",
        "ja": "バー",
        "th": "บาร์",
        "pt": "Barra"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.077621,
        "lng": 27.6813781
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.6491382,
        49.0635855,
        27.7091771,
        49.0963093
      ]
    },
    {
      "id": 23315,
      "name": "As Sukhnah",
      "names": {
        "es": "Como sukhnah",
        "fr": "Comme Sukhnah",
        "zh": "作为Sukhnah",
        "de": "Als Sukhnah",
        "it": "Come Sukhnah",
        "id": "Sebagai suknah",
        "ja": "スクナとして",
        "th": "เช่นเดียวกับ Sukhnah",
        "pt": "Como Sukhnah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8826632,
        "lng": 38.8714412
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        38.8314412,
        34.8426632,
        38.9114412,
        34.9226632
      ]
    },
    {
      "id": 23341,
      "name": "Mezotur",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.006279,
        "lng": 20.618907
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.583257,
        46.990387,
        20.648276,
        47.021203
      ]
    },
    {
      "id": 23346,
      "name": "Sassenheim",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2246356,
        "lng": 4.5199218
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4786653,
        52.2012976,
        4.5532258,
        52.2359861
      ]
    },
    {
      "id": 23354,
      "name": "Chinggil",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.666,
        "lng": 90.3794
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 23356,
      "name": "Csongrad",
      "names": {
        "de": "Csongrád"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.711059,
        "lng": 20.143208
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.116747,
        46.693597,
        20.195516,
        46.726493
      ]
    },
    {
      "id": 23361,
      "name": "Alasehir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3507471,
        "lng": 28.516575
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.2798382,
        38.1040957,
        28.787521,
        38.541385
      ]
    },
    {
      "id": 23369,
      "name": "Incirliova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8537655,
        "lng": 27.7235028
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.6467207,
        37.7659658,
        27.8561329,
        37.9824256
      ]
    },
    {
      "id": 23378,
      "name": "Aquitania",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5175808,
        "lng": -72.88315
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0459046,
        5.1972126,
        -72.684916,
        5.644776
      ]
    },
    {
      "id": 23409,
      "name": "Atescatempa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.17444,
        "lng": -89.7425
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.7625,
        14.15444,
        -89.7225,
        14.19444
      ]
    },
    {
      "id": 23419,
      "name": "Dianga",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.0588816,
        "lng": 103.2075404
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.1188392,
        33.9480502,
        103.4633832,
        34.2788652
      ]
    },
    {
      "id": 23422,
      "name": "Swidwin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.774596,
        "lng": 15.7767962
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.7342821,
        53.7403459,
        15.8601925,
        53.8080341
      ]
    },
    {
      "id": 23439,
      "name": "Ban Dung",
      "names": {
        "es": "Prohibir estiércol",
        "fr": "Buêle",
        "zh": "禁止粪便",
        "de": "Ban-Mist",
        "it": "Divieto",
        "ja": "糞を禁止する",
        "th": "บ้านมูล",
        "pt": "Esterco"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.702001,
        "lng": 103.260438
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.234592,
        17.640222,
        103.31936,
        17.721096
      ]
    },
    {
      "id": 23447,
      "name": "Chiconquiaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.742605,
        "lng": -96.816524
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.825716,
        19.737985,
        -96.81217,
        19.747416
      ]
    },
    {
      "id": 23453,
      "name": "Issoire",
      "names": {
        "zh": "伊苏瓦尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5432973,
        "lng": 3.2502213
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.2107931,
        45.5216981,
        3.2781449,
        45.5669748
      ]
    },
    {
      "id": 23454,
      "name": "Bastogne",
      "names": {
        "zh": "巴斯托涅"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.004699,
        "lng": 5.720645
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.683088,
        49.977846,
        5.750025,
        50.019597
      ]
    },
    {
      "id": 23455,
      "name": "Chivolo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.0269357,
        "lng": -74.6208028
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.6726913,
        9.9432832,
        -74.3879134,
        10.2343887
      ]
    },
    {
      "id": 23456,
      "name": "Santa Maria de Jesus",
      "names": {
        "es": "Santa María de Jesús",
        "fr": "Santa Maria de Jésus",
        "zh": "圣玛丽亚德耶斯",
        "it": "Santa Maria de Gesù",
        "id": "Santa Maria de Yesus",
        "ja": "サンタマリアデ・イエス",
        "th": "ซานตามาเรียเดอพระเยซู"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4939503,
        "lng": -90.7096377
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.7417921,
        14.4282992,
        -90.659355,
        14.5282417
      ]
    },
    {
      "id": 23463,
      "name": "Toul",
      "names": {
        "zh": "图勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6762624,
        "lng": 5.8941759
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.8547394,
        48.6302619,
        5.9412285,
        48.7285562
      ]
    },
    {
      "id": 23474,
      "name": "Monte Plata",
      "names": {
        "zh": "蒙特普拉塔",
        "ja": "モンテプラタ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.8101455,
        "lng": -69.7845099
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -69.9445099,
        18.6501455,
        -69.6245099,
        18.9701455
      ]
    },
    {
      "id": 23479,
      "name": "Batalha",
      "names": {
        "zh": "巴塔利亚"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6578998,
        "lng": -8.8243713
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8737993,
        39.5508681,
        -8.6711459,
        39.6955292
      ]
    },
    {
      "id": 23486,
      "name": "San Julian",
      "names": {
        "zh": "圣朱利安",
        "it": "San Giuliano",
        "ja": "サンジュリアン",
        "th": "ซานจูเลียน",
        "pt": "San juliano"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.993872,
        "lng": -102.15835703145814
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.2526855,
        20.8720408,
        -102.0853538,
        21.1155468
      ]
    },
    {
      "id": 23491,
      "name": "Pamiers",
      "names": {
        "zh": "帕米耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.116213,
        "lng": 1.6096533
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.6096033,
        43.116163,
        1.6097033,
        43.116263
      ]
    },
    {
      "id": 23494,
      "name": "Maardu",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.468192,
        "lng": 24.9896751
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        24.9146237,
        59.4349289,
        25.0323692,
        59.4955953
      ]
    },
    {
      "id": 23498,
      "name": "Boskoop",
      "names": {
        "zh": "博斯科普"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0770639,
        "lng": 4.6466297
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.6416297,
        52.0720639,
        4.6516297,
        52.0820639
      ]
    },
    {
      "id": 23506,
      "name": "Dinan",
      "names": {
        "zh": "迪南",
        "ja": "ディナン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4539775,
        "lng": -2.047687
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.0742787,
        48.4256222,
        -2.0322581,
        48.4684331
      ]
    },
    {
      "id": 23514,
      "name": "Serta",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.802108,
        "lng": -8.09599
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.103117,
        39.793957,
        -8.083029,
        39.813029
      ]
    },
    {
      "id": 23534,
      "name": "Skvyra",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7337685,
        "lng": 29.6624138
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.6205452,
        49.7057067,
        29.7036196,
        49.7524142
      ]
    },
    {
      "id": 23539,
      "name": "Nuth",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9171887,
        "lng": 5.8838254
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8357054,
        50.9040013,
        5.9202104,
        50.9349915
      ]
    },
    {
      "id": 23561,
      "name": "Tenango de Doria",
      "names": {
        "it": "Tenano de Doria",
        "id": "Tauno de doria"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3373893,
        "lng": -98.2246172
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.2646172,
        20.2973893,
        -98.1846172,
        20.3773893
      ]
    },
    {
      "id": 23570,
      "name": "Ixhuatlan del Sureste",
      "names": {
        "fr": "Ixhuatlan del Surette",
        "zh": "Ixhuatlan del Sureeste",
        "ja": "Ixhuatlan del Sureeste",
        "th": "ixhuatlan del sueste"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.021111,
        "lng": -94.3875
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -94.4275,
        17.981111,
        -94.3475,
        18.061111
      ]
    },
    {
      "id": 23595,
      "name": "Belen",
      "names": {
        "zh": "贝伦"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.4916742,
        "lng": 36.1944459
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.0662558,
        36.3929433,
        36.3017588,
        36.5479802
      ]
    },
    {
      "id": 23598,
      "name": "Bad Neustadt",
      "names": {
        "es": "Mala neustadt",
        "zh": "坏Neustadt",
        "de": "Schlechtes Neustadt",
        "id": "Neustadt yang buruk",
        "ja": "悪いニュスタット",
        "th": "Neustadt ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.322637,
        "lng": 10.2164873
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.1481467,
        50.2900829,
        10.2745704,
        50.3782203
      ]
    },
    {
      "id": 23608,
      "name": "Kafr Nubl",
      "names": {
        "fr": "Kafr nud",
        "id": "Kafr nobr",
        "th": "kafr nuli"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.6141381,
        "lng": 36.5606273
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.5206273,
        35.5741381,
        36.6006273,
        35.6541381
      ]
    },
    {
      "id": 23618,
      "name": "Juma Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.71611,
        "lng": 66.66417
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        66.64417,
        39.69611,
        66.68417,
        39.73611
      ]
    },
    {
      "id": 23623,
      "name": "Macedo de Cavaleiros",
      "names": {
        "fr": "Macédo de Cavaleiros",
        "ja": "マイシオデカカレロス"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5362124,
        "lng": -6.9560267
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.1295567,
        41.4036895,
        -6.6585613,
        41.7113064
      ]
    },
    {
      "id": 23625,
      "name": "Voss",
      "names": {
        "zh": "福斯",
        "th": "โวส"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.6837067,
        "lng": 6.407905054203272
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.842746,
        60.414507,
        6.985868,
        60.9595517
      ]
    },
    {
      "id": 23639,
      "name": "Kalocsa",
      "names": {
        "zh": "考洛乔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.5348238,
        "lng": 18.9947391
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.9897391,
        46.5298238,
        18.9997391,
        46.5398238
      ]
    },
    {
      "id": 23644,
      "name": "Guasca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.86601,
        "lng": -73.87748
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.89748,
        4.84601,
        -73.85748,
        4.88601
      ]
    },
    {
      "id": 23670,
      "name": "Si Satchanalai",
      "names": {
        "fr": "Si Satchatanalai",
        "ja": "SATATHANALAI",
        "th": "ศรีสัชนาลัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4015682,
        "lng": 99.8023796
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.7623796,
        17.3615682,
        99.8423796,
        17.4415682
      ]
    },
    {
      "id": 23675,
      "name": "Spittal an der Drau",
      "names": {
        "zh": "Spittal A der Drau",
        "id": "Spittal an der drum",
        "ja": "スピタールアンデルドラウ",
        "th": "spittal an der dru"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.793952,
        "lng": 13.495206
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.477914,
        46.782569,
        13.526228,
        46.808897
      ]
    },
    {
      "id": 23685,
      "name": "Tiran",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.7039199,
        "lng": 51.1530853
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.1130853,
        32.6639199,
        51.1930853,
        32.7439199
      ]
    },
    {
      "id": 23690,
      "name": "Kysucke Nove Mesto",
      "names": {
        "ja": "Kusucke Nove Mesto"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2999361,
        "lng": 18.7835438
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.7834938,
        49.2998861,
        18.7835938,
        49.2999861
      ]
    },
    {
      "id": 23700,
      "name": "Einsiedeln",
      "names": {
        "zh": "艾因西德伦"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1280804,
        "lng": 8.7441515
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.7391515,
        47.1230804,
        8.7491515,
        47.1330804
      ]
    },
    {
      "id": 23701,
      "name": "Kuusamo",
      "names": {
        "zh": "库萨莫"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.96891735,
        "lng": 29.174382594009444
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.1741325,
        65.9687885,
        29.1746265,
        65.969055
      ]
    },
    {
      "id": 23720,
      "name": "Rakhiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0546455,
        "lng": 24.2087462
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.2037462,
        48.0496455,
        24.2137462,
        48.0596455
      ]
    },
    {
      "id": 23725,
      "name": "Szarvas",
      "names": {
        "zh": "索尔沃什"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8605652,
        "lng": 20.5667942
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.5617942,
        46.8555652,
        20.5717942,
        46.8655652
      ]
    },
    {
      "id": 23728,
      "name": "Waremme",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6976875,
        "lng": 5.2546191
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.2137617,
        50.6554924,
        5.3132778,
        50.729035
      ]
    },
    {
      "id": 23736,
      "name": "Bozyazi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.1048224,
        "lng": 32.9742864
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.9001918,
        36.0698527,
        33.2158161,
        36.4615459
      ]
    },
    {
      "id": 23740,
      "name": "Kinna",
      "names": {
        "zh": "金纳"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.5080076,
        "lng": 12.6970568
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.6570568,
        57.4680076,
        12.7370568,
        57.5480076
      ]
    },
    {
      "id": 23745,
      "name": "Serpa",
      "names": {
        "zh": "塞尔帕"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.9393619,
        "lng": -7.5982985
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.5983485,
        37.9393119,
        -7.5982485,
        37.9394119
      ]
    },
    {
      "id": 23749,
      "name": "Saint-Omer",
      "names": {
        "zh": "圣 - 奥马尔",
        "ja": "サン・オメル",
        "th": "เซนต์เอโอแมร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.7515771,
        "lng": 2.2534183
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2286473,
        50.7380459,
        2.3009189,
        50.7965298
      ]
    },
    {
      "id": 23755,
      "name": "Mangqu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5699612,
        "lng": 100.7728091
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        100.7328091,
        35.5299612,
        100.8128091,
        35.6099612
      ]
    },
    {
      "id": 23759,
      "name": "Jedrzejow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.6394435,
        "lng": 20.3046116
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.2474834,
        50.6222867,
        20.3313165,
        50.6567951
      ]
    },
    {
      "id": 23763,
      "name": "Bohodukhiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1601711,
        "lng": 35.5220539
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.4712486,
        50.1369443,
        35.5783136,
        50.1798848
      ]
    },
    {
      "id": 23767,
      "name": "At-Bashy",
      "names": {
        "es": "En-bashy",
        "fr": "À-bashy",
        "zh": "在-bashy",
        "it": "A-bashy",
        "id": "Di-bashy",
        "ja": "アット・bashy",
        "th": "ที่ bashy"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 41.1703178,
        "lng": 75.8092334
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        75.7748408,
        41.1596952,
        75.853878,
        41.1780542
      ]
    },
    {
      "id": 23770,
      "name": "Khamir",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.9882697,
        "lng": 43.9534903
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        43.9134903,
        15.9482697,
        43.9934903,
        16.0282697
      ]
    },
    {
      "id": 23772,
      "name": "Mandal",
      "names": {
        "fr": "De mandal",
        "zh": "曼德尔",
        "ja": "マーンダル",
        "th": "ดัล"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.0268181,
        "lng": 7.4534802
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.4134802,
        57.9868181,
        7.4934802,
        58.0668181
      ]
    },
    {
      "id": 23774,
      "name": "Najasa",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.0734839,
        "lng": -77.7462231
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -77.7862231,
        21.0334839,
        -77.7062231,
        21.1134839
      ]
    },
    {
      "id": 23777,
      "name": "Kunzelsau",
      "names": {
        "de": "Künzelsau",
        "it": "Künzelsau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.279698,
        "lng": 9.689136
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.640952,
        49.240592,
        9.834783,
        49.319976
      ]
    },
    {
      "id": 23784,
      "name": "Siemiatycze",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.427225,
        "lng": 22.867594
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.821323,
        52.376375,
        22.929075,
        52.464737
      ]
    },
    {
      "id": 23787,
      "name": "Sarikamis",
      "names": {
        "zh": "萨瑞卡密斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3337398,
        "lng": 42.5894682
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.1006618,
        39.9346359,
        42.8531062,
        40.4651657
      ]
    },
    {
      "id": 23815,
      "name": "Khao Yoi",
      "names": {
        "ja": "カオヨー",
        "th": "เขาโยโย่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.2346535,
        "lng": 99.8237962
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.6637962,
        13.0746535,
        99.9837962,
        13.3946535
      ]
    },
    {
      "id": 23823,
      "name": "Uusikaupunki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.7973268,
        "lng": 21.4258987
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.4258487,
        60.7972768,
        21.4259487,
        60.7973768
      ]
    },
    {
      "id": 23826,
      "name": "Zelenogradsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.95893,
        "lng": 20.47668
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        20.45668,
        54.93893,
        20.49668,
        54.97893
      ]
    },
    {
      "id": 23831,
      "name": "Limanowa",
      "names": {
        "zh": "利马诺瓦"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.698914,
        "lng": 20.475118
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.292242,
        49.633838,
        20.591798,
        49.823349
      ]
    },
    {
      "id": 23844,
      "name": "Senlis",
      "names": {
        "zh": "桑利斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2065404,
        "lng": 2.5846582
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.5487604,
        49.173044,
        2.634042,
        49.2581482
      ]
    },
    {
      "id": 23856,
      "name": "Fontainebleau",
      "names": {
        "zh": "枫丹白露",
        "ja": "フォンテーヌブロー",
        "th": "ฟองเตนโบล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.407104,
        "lng": 2.704043
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.561241,
        48.345004,
        2.802347,
        48.502249
      ]
    },
    {
      "id": 23858,
      "name": "Budakeszi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5099444,
        "lng": 18.929382
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.8603098,
        47.4710476,
        18.9477351,
        47.5447041
      ]
    },
    {
      "id": 23860,
      "name": "Hilvarenbeek",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.487767,
        "lng": 5.137422
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.048792,
        51.469263,
        5.157562,
        51.545919
      ]
    },
    {
      "id": 23866,
      "name": "Pontivy",
      "names": {
        "zh": "蓬蒂维"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.066668,
        "lng": -2.9667055421348314
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.967248,
        48.066482,
        -2.966496,
        48.066875
      ]
    },
    {
      "id": 23882,
      "name": "Galanta",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.1910498,
        "lng": 17.7270629
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.6823785,
        48.1598358,
        17.7911411,
        48.2237721
      ]
    },
    {
      "id": 23888,
      "name": "Churumuco de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.661187,
        "lng": -101.647055
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.661606,
        18.650762,
        -101.63508,
        18.675476
      ]
    },
    {
      "id": 23895,
      "name": "San Rafael Pie de la Cuesta",
      "names": {
        "ja": "サンラファエルパーデラシースター"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.93333,
        "lng": -91.91667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.93667,
        14.91333,
        -91.89667,
        14.95333
      ]
    },
    {
      "id": 23910,
      "name": "Kerouane",
      "names": {
        "zh": "凯鲁阿内"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 9.2705687,
        "lng": -9.0076196
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -9.0476196,
        9.2305687,
        -8.9676196,
        9.3105687
      ]
    },
    {
      "id": 23911,
      "name": "Birecik",
      "names": {
        "zh": "比雷吉克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0315322,
        "lng": 37.9800265
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.8633426,
        36.830255,
        38.3519204,
        37.2663694
      ]
    },
    {
      "id": 23922,
      "name": "Klimavichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.6102643,
        "lng": 31.9612094
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        31.9053339,
        53.5772706,
        31.9933107,
        53.6304439
      ]
    },
    {
      "id": 23929,
      "name": "Landazuri",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.21826,
        "lng": -73.81121
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.83121,
        6.19826,
        -73.79121,
        6.23826
      ]
    },
    {
      "id": 23931,
      "name": "Amatitan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8320718,
        "lng": -103.7285106
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.7685106,
        20.7920718,
        -103.6885106,
        20.8720718
      ]
    },
    {
      "id": 23933,
      "name": "Villa del Rosario",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.5540883,
        "lng": -63.5352431
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.5488579,
        -31.5720698,
        -63.5220626,
        -31.5382552
      ]
    },
    {
      "id": 23934,
      "name": "Conguaco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0482441,
        "lng": -90.0326536
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.0526536,
        14.0282441,
        -90.0126536,
        14.0682441
      ]
    },
    {
      "id": 23957,
      "name": "Thoen",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.603874,
        "lng": 99.216438
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.9499179298,
        17.5669183068,
        99.2461815322,
        17.752493665
      ]
    },
    {
      "id": 23964,
      "name": "Castro Daire",
      "names": {
        "fr": "Castro-Daire",
        "zh": "卡斯特罗·迪尔尔",
        "ja": "カストロデール"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9014091,
        "lng": -7.9311761
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1326074,
        40.7867224,
        -7.7500805,
        41.0266206
      ]
    },
    {
      "id": 23966,
      "name": "Chaltyr",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.283455,
        "lng": 39.501518
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.4574055,
        47.2701456,
        39.5362183,
        47.3176358
      ]
    },
    {
      "id": 23981,
      "name": "Bar-le-Duc",
      "names": {
        "zh": "巴勒迪克山",
        "ja": "バー・ル・duc",
        "th": "บาร์เลอ-ดูค"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7736604,
        "lng": 5.1672573
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.1622573,
        48.7686604,
        5.1722573,
        48.7786604
      ]
    },
    {
      "id": 23982,
      "name": "Brake",
      "names": {
        "es": "Freno",
        "fr": "Frein",
        "zh": "制动",
        "de": "Bremse",
        "it": "Freno",
        "id": "Rem",
        "ja": "ブレーキ",
        "th": "เบรค",
        "pt": "Freio"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3286418,
        "lng": 8.4811634
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.4761634,
        53.3236418,
        8.4861634,
        53.3336418
      ]
    },
    {
      "id": 23987,
      "name": "Chilcuautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3310816,
        "lng": -99.2314283
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2314783,
        20.3310316,
        -99.2313783,
        20.3311316
      ]
    },
    {
      "id": 23989,
      "name": "Lahoysk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.2064,
        "lng": 27.8512
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.8312,
        54.1864,
        27.8712,
        54.2264
      ]
    },
    {
      "id": 23991,
      "name": "Alta",
      "names": {
        "zh": "阿尔塔",
        "ja": "アルタ",
        "th": "อัลตา"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 69.9666046,
        "lng": 23.2733282
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        22.1858185,
        69.6454509,
        24.2182312,
        70.4480212
      ]
    },
    {
      "id": 23996,
      "name": "Nazare",
      "names": {
        "zh": "纳扎雷",
        "de": "Nazaré"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6029108,
        "lng": -9.0701572
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.115279,
        39.511042,
        -8.9709567,
        39.6501194
      ]
    },
    {
      "id": 24001,
      "name": "Loviisa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4571999,
        "lng": 26.2254672
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.2254172,
        60.4571499,
        26.2255172,
        60.4572499
      ]
    },
    {
      "id": 24005,
      "name": "Villa Paranacito",
      "names": {
        "zh": "帕拉腊迦尼托别墅",
        "ja": "ヴィラパラナシート"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.7142599,
        "lng": -58.6615177
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -58.9834212,
        -34.03536,
        -58.4011316,
        -33.524929
      ]
    },
    {
      "id": 24006,
      "name": "Morlaix",
      "names": {
        "zh": "莫尔莱"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5764184,
        "lng": -3.8285466
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -3.8285966,
        48.5763684,
        -3.8284966,
        48.5764684
      ]
    },
    {
      "id": 24009,
      "name": "Penacova",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2702086,
        "lng": -8.2810743
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3896506,
        40.2186149,
        -8.108248,
        40.3708015
      ]
    },
    {
      "id": 24029,
      "name": "Tapolca",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8820972,
        "lng": 17.4389619
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.3618395,
        46.8442911,
        17.5299835,
        46.946967
      ]
    },
    {
      "id": 24032,
      "name": "Tulle",
      "names": {
        "es": "Tul",
        "zh": "薄纱",
        "de": "Tüll",
        "ja": "チュール",
        "th": "ผ้าโปร่งสำหรับคลุมหน้าสตรี",
        "pt": "Tule"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.267982,
        "lng": 1.772219
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.722961,
        45.23914,
        1.809959,
        45.297898
      ]
    },
    {
      "id": 24035,
      "name": "Sarvar",
      "names": {
        "zh": "萨尔瓦尔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2517426,
        "lng": 16.9342165
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        16.860605,
        47.2095006,
        17.035257,
        47.3108256
      ]
    },
    {
      "id": 24053,
      "name": "Tamesis",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6652526,
        "lng": -75.7139862
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7539862,
        5.6252526,
        -75.6739862,
        5.7052526
      ]
    },
    {
      "id": 24056,
      "name": "Mindelheim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0464164,
        "lng": 10.4882504
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.4346519,
        48.0136994,
        10.5670621,
        48.0989782
      ]
    },
    {
      "id": 24061,
      "name": "La Apartada",
      "names": {
        "fr": "La Apartaada"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.0484697,
        "lng": -75.333651
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.373651,
        8.0084697,
        -75.293651,
        8.0884697
      ]
    },
    {
      "id": 24085,
      "name": "Unye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1262405,
        "lng": 37.2853616
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.0183069,
        40.8732584,
        37.4418494,
        41.1476748
      ]
    },
    {
      "id": 24090,
      "name": "Kilimli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4869266,
        "lng": 31.8384083
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.8222995,
        41.4276515,
        32.0306899,
        41.548994
      ]
    },
    {
      "id": 24092,
      "name": "Masty",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.4134113,
        "lng": 24.5428406
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.5055755,
        53.3856502,
        24.5913036,
        53.4404463
      ]
    },
    {
      "id": 24093,
      "name": "Bitburg",
      "names": {
        "zh": "比特堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9732762,
        "lng": 6.524947
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.4516096,
        49.9351967,
        6.5892508,
        50.0187333
      ]
    },
    {
      "id": 24094,
      "name": "Skalica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8461007,
        "lng": 17.2287837
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        16.978471,
        48.67096,
        17.399415,
        48.878149
      ]
    },
    {
      "id": 24096,
      "name": "San Antonio La Paz",
      "names": {
        "ja": "サンアントニオラパス",
        "th": "ซานอันโตนิโอลาปาซ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.74972,
        "lng": -90.28667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.30667,
        14.72972,
        -90.26667,
        14.76972
      ]
    },
    {
      "id": 24101,
      "name": "Samobor",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8018625,
        "lng": 15.7097105
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        15.4552504,
        45.6764258,
        15.8067834,
        45.8537101
      ]
    },
    {
      "id": 24104,
      "name": "Choszczno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1648121,
        "lng": 15.4041112
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.3991112,
        53.1598121,
        15.4091112,
        53.1698121
      ]
    },
    {
      "id": 24114,
      "name": "Moura",
      "names": {
        "zh": "莫拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.1409785,
        "lng": -7.4500699
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.5327773,
        37.9606066,
        -6.9868297,
        38.3311462
      ]
    },
    {
      "id": 24124,
      "name": "Zozocolco de Hidalgo",
      "names": {
        "de": "Zozcolco de Hidalgo",
        "ja": "Zosocolo de Hidalgo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.139989,
        "lng": -97.57664
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.582911,
        20.1339,
        -97.569383,
        20.147018
      ]
    },
    {
      "id": 24130,
      "name": "Joghtay",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.63619,
        "lng": 57.07284
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        57.05284,
        36.61619,
        57.09284,
        36.65619
      ]
    },
    {
      "id": 24139,
      "name": "Gardabani",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.46054,
        "lng": 45.09283
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        45.07283,
        41.44054,
        45.11283,
        41.48054
      ]
    },
    {
      "id": 24151,
      "name": "Amlash",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.0966,
        "lng": 50.18709
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.16709,
        37.0766,
        50.20709,
        37.1166
      ]
    },
    {
      "id": 24157,
      "name": "Ratzeburg",
      "names": {
        "zh": "拉策堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.7000166,
        "lng": 10.763663
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.763613,
        53.6999666,
        10.763713,
        53.7000666
      ]
    },
    {
      "id": 24182,
      "name": "Battalgazi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4250492,
        "lng": 38.3654977
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.2775473,
        38.1270023,
        38.7404108,
        38.5082621
      ]
    },
    {
      "id": 24188,
      "name": "La Fleche",
      "names": {
        "fr": "La flèche",
        "de": "La Fiege"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6968408,
        "lng": -0.0746149
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.1413986,
        47.6368323,
        0.0205427,
        47.7447872
      ]
    },
    {
      "id": 24191,
      "name": "Tulchyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.67448,
        "lng": 28.84641
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.82641,
        48.65448,
        28.86641,
        48.69448
      ]
    },
    {
      "id": 24193,
      "name": "Pilisvorosvar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6210817,
        "lng": 18.9115754
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.8553131,
        47.6025373,
        18.9567313,
        47.6596374
      ]
    },
    {
      "id": 24210,
      "name": "Salkhad",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.492,
        "lng": 36.71114
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.69114,
        32.472,
        36.73114,
        32.512
      ]
    },
    {
      "id": 24213,
      "name": "Alexandreia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.6279906,
        "lng": 22.4448468
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.2848468,
        40.4679906,
        22.6048468,
        40.7879906
      ]
    },
    {
      "id": 24216,
      "name": "Ylivieska",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.07194229999999,
        "lng": 24.5402029997921
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.5400632,
        64.071774,
        24.5403428,
        64.0721107
      ]
    },
    {
      "id": 24231,
      "name": "Obando",
      "names": {
        "zh": "奥班多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5746578,
        "lng": -75.9721783
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.0397244,
        4.5283334,
        -75.8595014,
        4.6716756
      ]
    },
    {
      "id": 24246,
      "name": "Bludenz",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.153037,
        "lng": 9.8219314
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        9.8023047,
        47.124909,
        9.9226851,
        47.1775153
      ]
    },
    {
      "id": 24252,
      "name": "Bonanza",
      "names": {
        "zh": "幸运",
        "ja": "ボナンザ",
        "th": "โบนันซ่า"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 14.0307223,
        "lng": -84.6000771
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -84.9444133,
        13.8280016,
        -84.3336676,
        14.2548972
      ]
    },
    {
      "id": 24255,
      "name": "Sarmin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.90403,
        "lng": 36.72501
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.70501,
        35.88403,
        36.74501,
        35.92403
      ]
    },
    {
      "id": 24256,
      "name": "Mariestad",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.7093225,
        "lng": 13.8228754
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        13.7828754,
        58.6693225,
        13.8628754,
        58.7493225
      ]
    },
    {
      "id": 24264,
      "name": "Chaguaramas",
      "names": {
        "zh": "查瓜拉马斯"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3361332,
        "lng": -66.255991
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -66.328733,
        9.3092075,
        -66.2225121,
        9.3802849
      ]
    },
    {
      "id": 24275,
      "name": "Verdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.7927932,
        "lng": 11.4820286
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.4420286,
        63.7527932,
        11.5220286,
        63.8327932
      ]
    },
    {
      "id": 24282,
      "name": "Vennesla",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.33986185,
        "lng": 7.770790282061096
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.6238969,
        58.2166357,
        8.0794819,
        58.4629895
      ]
    },
    {
      "id": 24285,
      "name": "Czluchow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.6571094,
        "lng": 17.3703906
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.3653906,
        53.6521094,
        17.3753906,
        53.6621094
      ]
    },
    {
      "id": 24290,
      "name": "Detva",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5595662,
        "lng": 19.4205586
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.237249,
        48.382385,
        19.721316,
        48.661485
      ]
    },
    {
      "id": 24291,
      "name": "Tiszaujvaros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9280341,
        "lng": 21.0467056
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.0008009,
        47.8750189,
        21.1246342,
        47.971844
      ]
    },
    {
      "id": 24298,
      "name": "Tall Abyad",
      "names": {
        "es": "Alto Abyad",
        "fr": "Grande abyade",
        "zh": "高大的abyad",
        "de": "Hoher Abyad",
        "it": "Abyad alto",
        "id": "Abyad tinggi",
        "ja": "背の高い上手",
        "th": "แก่นแท้",
        "pt": "Alto Abyad"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.69648,
        "lng": 38.95382
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        38.93382,
        36.67648,
        38.97382,
        36.71648
      ]
    },
    {
      "id": 24306,
      "name": "Nioro",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 15.2304528,
        "lng": -9.5899063
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -9.7499063,
        15.0704528,
        -9.4299063,
        15.3904528
      ]
    },
    {
      "id": 24310,
      "name": "Nastola",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.9482796,
        "lng": 25.9335713
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.8935713,
        60.9082796,
        25.9735713,
        60.9882796
      ]
    },
    {
      "id": 24316,
      "name": "Levoca",
      "names": {
        "zh": "莱沃恰"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.025087,
        "lng": 20.5887098
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.437783,
        48.95224,
        20.902754,
        49.1550282
      ]
    },
    {
      "id": 24324,
      "name": "Cabanas",
      "names": {
        "es": "Cabañas",
        "zh": "小屋",
        "ja": "カバナ",
        "th": "คาบาน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 13.88329145,
        "lng": -88.73830959983437
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -88.9880499,
        13.7422379,
        -88.491712,
        14.0244963
      ]
    },
    {
      "id": 24333,
      "name": "Woudrichem",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.816148,
        "lng": 5.001658
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.956436,
        51.792478,
        5.01232,
        51.823654
      ]
    },
    {
      "id": 24336,
      "name": "Mineral del Monte",
      "names": {
        "fr": "Minéral del monte",
        "ja": "ミネラルデルモンテ",
        "th": "แร่เดลมอนเต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1384604,
        "lng": -98.6733356
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.7133356,
        20.0984604,
        -98.6333356,
        20.1784604
      ]
    },
    {
      "id": 24340,
      "name": "Paiania",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9537116,
        "lng": 23.8523932
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.8123932,
        37.9137116,
        23.8923932,
        37.9937116
      ]
    },
    {
      "id": 24357,
      "name": "Vrbovec",
      "names": {
        "zh": "弗尔博韦茨"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8856479,
        "lng": 16.4181159
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.2864849,
        45.7999049,
        16.4749002,
        45.9846711
      ]
    },
    {
      "id": 24371,
      "name": "Balassagyarmat",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.0712039,
        "lng": 19.2938426
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.2937926,
        48.0711539,
        19.2938926,
        48.0712539
      ]
    },
    {
      "id": 24379,
      "name": "Thuin",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.3397445,
        "lng": 4.2870475
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.2470475,
        50.2997445,
        4.3270475,
        50.3797445
      ]
    },
    {
      "id": 24392,
      "name": "Bayeux",
      "names": {
        "zh": "巴约",
        "ja": "バイユー"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2764624,
        "lng": -0.7024738
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.7275671,
        49.2608124,
        -0.6757378,
        49.2934736
      ]
    },
    {
      "id": 24399,
      "name": "Grodzisk Wielkopolski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2304733,
        "lng": 16.363892
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.358892,
        52.2254733,
        16.368892,
        52.2354733
      ]
    },
    {
      "id": 24402,
      "name": "Grandola",
      "names": {
        "pt": "Grândola"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.1761212,
        "lng": -8.5680717
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9128675,
        38.0234521,
        -8.2381757,
        38.5053434
      ]
    },
    {
      "id": 24405,
      "name": "Kontiolahti",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.766667,
        "lng": 29.85
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.5600462,
        62.5662741,
        30.2879977,
        63.0657333
      ]
    },
    {
      "id": 24412,
      "name": "Slochteren",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2188327,
        "lng": 6.8061724
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.7383038,
        53.1823553,
        6.8419683,
        53.244657
      ]
    },
    {
      "id": 24436,
      "name": "Ivanic-Grad",
      "names": {
        "es": "Ivanic-de posgrado",
        "zh": "伊万尼奇-毕业生",
        "ja": "ivanic-卒業生",
        "th": "ivanic-สำเร็จการศึกษา",
        "pt": "Ivanic-graduado"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.7073089,
        "lng": 16.3979735
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.3929735,
        45.7023089,
        16.4029735,
        45.7123089
      ]
    },
    {
      "id": 24446,
      "name": "Tepetzintla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1653208,
        "lng": -97.8523214
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.8923214,
        21.1253208,
        -97.8123214,
        21.2053208
      ]
    },
    {
      "id": 24467,
      "name": "Berettyoujfalu",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2134516,
        "lng": 21.5487635
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.5487135,
        47.2134016,
        21.5488135,
        47.2135016
      ]
    },
    {
      "id": 24474,
      "name": "Tamuin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.005168,
        "lng": -98.784765
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.801322,
        21.996895,
        -98.752174,
        22.018911
      ]
    },
    {
      "id": 24480,
      "name": "Maqat",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.65,
        "lng": 53.31667
      },
      "country": "Kazakhstan",
      "importance": 2,
      "bbox": [
        53.29667,
        47.63,
        53.33667,
        47.67
      ]
    },
    {
      "id": 24499,
      "name": "Zabkowice Slaskie",
      "names": {
        "pt": "Slaskie de zabkowice"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.5892698,
        "lng": 16.8111518
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.7901934,
        50.5645405,
        16.8555655,
        50.6101606
      ]
    },
    {
      "id": 24500,
      "name": "Xincun",
      "names": {
        "zh": "新村"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.436737,
        "lng": 113.7049851
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        113.6649851,
        34.396737,
        113.7449851,
        34.476737
      ]
    },
    {
      "id": 24508,
      "name": "Askim",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5832224,
        "lng": 11.1641908
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.1241908,
        59.5432224,
        11.2041908,
        59.6232224
      ]
    },
    {
      "id": 24513,
      "name": "Wschowa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8038354,
        "lng": 16.3172771
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.2960058,
        51.7853601,
        16.3469956,
        51.8294726
      ]
    },
    {
      "id": 24518,
      "name": "Ochakiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.6145957,
        "lng": 31.5452994
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.5021692,
        46.599923,
        31.5899115,
        46.6442199
      ]
    },
    {
      "id": 24529,
      "name": "Carmen de Areco",
      "names": {
        "de": "Carmen de Aeco",
        "ja": "カルメンデレコ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.3766574,
        "lng": -59.8227267
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.8675479,
        -34.4291472,
        -59.8017999,
        -34.3479438
      ]
    },
    {
      "id": 24536,
      "name": "Ardestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.3797762,
        "lng": 52.3724967
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        52.355926,
        33.3361421,
        52.3958342,
        33.408696
      ]
    },
    {
      "id": 24545,
      "name": "Zontecomatlan de Lopez y Fuentes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.761756,
        "lng": -98.342847
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.3468595931,
        20.7593885431,
        -98.3407385407,
        20.7636351996
      ]
    },
    {
      "id": 24556,
      "name": "Nannestad",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.2267034,
        "lng": 10.940940671045684
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.7720265,
        60.1062918,
        11.1291981,
        60.346692
      ]
    },
    {
      "id": 24557,
      "name": "Condoto",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0921969,
        "lng": -76.6513567
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.6913567,
        5.0521969,
        -76.6113567,
        5.1321969
      ]
    },
    {
      "id": 24565,
      "name": "Sepidan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.26061,
        "lng": 51.98424
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.96424,
        30.24061,
        52.00424,
        30.28061
      ]
    },
    {
      "id": 24567,
      "name": "Goure",
      "names": {
        "zh": "古雷"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.9861879,
        "lng": 10.2642868
      },
      "country": "Niger",
      "importance": 2,
      "bbox": [
        10.1042868,
        13.8261879,
        10.4242868,
        14.1461879
      ]
    },
    {
      "id": 24572,
      "name": "Lopik",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.972485,
        "lng": 4.940526
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.860382,
        51.938031,
        5.062638,
        52.00569
      ]
    },
    {
      "id": 24588,
      "name": "Jever",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5736426,
        "lng": 7.9020696
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.815371,
        53.5009369,
        7.9529595,
        53.6028361
      ]
    },
    {
      "id": 24594,
      "name": "Anapoima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5493011,
        "lng": -74.5359119
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5973667,
        4.4839106,
        -74.4626335,
        4.6438864
      ]
    },
    {
      "id": 24597,
      "name": "Radomyshl",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.4961812,
        "lng": 29.2289588
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.1939107,
        50.4718494,
        29.3043566,
        50.5159303
      ]
    },
    {
      "id": 24599,
      "name": "Pastores",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.59667,
        "lng": -90.75472
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.77472,
        14.57667,
        -90.73472,
        14.61667
      ]
    },
    {
      "id": 24603,
      "name": "Lapua",
      "names": {
        "zh": "拉普阿"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9702783,
        "lng": 23.0068628
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.784121,
        62.7746945,
        23.5405275,
        63.078244
      ]
    },
    {
      "id": 24606,
      "name": "Nafplio",
      "names": {
        "zh": "拉夫普里欧"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.567307,
        "lng": 22.805175
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.7907,
        37.549991,
        22.821156,
        37.586362
      ]
    },
    {
      "id": 24619,
      "name": "Cadaval",
      "names": {
        "zh": "卡达瓦尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.242859,
        "lng": -9.1026628
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.1784404,
        39.1676246,
        -8.9752245,
        39.3119873
      ]
    },
    {
      "id": 24621,
      "name": "Lagoa",
      "names": {
        "zh": "拉戈阿"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.13258125,
        "lng": -8.45505094811731
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5324069,
        37.0853284,
        -8.369366,
        37.1798833
      ]
    },
    {
      "id": 24646,
      "name": "Breza",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.0183108,
        "lng": 18.2608208
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.2208208,
        43.9783108,
        18.3008208,
        44.0583108
      ]
    },
    {
      "id": 24648,
      "name": "Mocha",
      "names": {
        "fr": "Moka",
        "zh": "摩卡",
        "de": "Mokka",
        "ja": "モカ",
        "th": "มอคค่า"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 22.31382795,
        "lng": 80.47431334634652
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        80.4714165,
        22.3061744,
        80.4927562,
        22.3212625
      ]
    },
    {
      "id": 24667,
      "name": "Tsimlyansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.643357,
        "lng": 42.094587
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        42.064667,
        47.629917,
        42.113361,
        47.660915
      ]
    },
    {
      "id": 24685,
      "name": "Sopetran",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5009582,
        "lng": -75.7424979
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7824979,
        6.4609582,
        -75.7024979,
        6.5409582
      ]
    },
    {
      "id": 24719,
      "name": "Argentan",
      "names": {
        "zh": "阿尔让坦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.743787600000005,
        "lng": -0.018659206571218376
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.0188755,
        48.7436467,
        -0.018285,
        48.7439469
      ]
    },
    {
      "id": 24728,
      "name": "Rovinj",
      "names": {
        "zh": "罗维尼",
        "it": "Rovigno"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.080725,
        "lng": 13.6390751
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        13.6390251,
        45.080675,
        13.6391251,
        45.080775
      ]
    },
    {
      "id": 24756,
      "name": "Sai Buri",
      "names": {
        "zh": "赛伯里",
        "ja": "サイブリ",
        "th": "ไทรบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.7039359,
        "lng": 101.6213564
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.5813564,
        6.6639359,
        101.6613564,
        6.7439359
      ]
    },
    {
      "id": 24786,
      "name": "Haaren",
      "names": {
        "zh": "哈伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6036127,
        "lng": 5.2272278
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.186861,
        51.5855572,
        5.2787436,
        51.6249284
      ]
    },
    {
      "id": 24795,
      "name": "San Andres Timilpan",
      "names": {
        "es": "San Andrés Timilpan",
        "zh": "圣安德烈斯·蒂姆彭"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8767716,
        "lng": -99.7346809
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.7546809,
        19.8567716,
        -99.7146809,
        19.8967716
      ]
    },
    {
      "id": 24801,
      "name": "Schwaz",
      "names": {
        "zh": "施瓦茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.3497564,
        "lng": 11.7015618
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        11.6965618,
        47.3447564,
        11.7065618,
        47.3547564
      ]
    },
    {
      "id": 24811,
      "name": "Det Udom",
      "names": {
        "es": "Deturona",
        "de": "Det-Udomat",
        "ja": "デットムダム",
        "th": "det urdom",
        "pt": "Det UDM"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.9036924,
        "lng": 105.0757106
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        105.0357106,
        14.8636924,
        105.1157106,
        14.9436924
      ]
    },
    {
      "id": 24813,
      "name": "Pomorie",
      "names": {
        "zh": "帕莫瑞"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.5604568,
        "lng": 27.636269
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        27.636219,
        42.5604068,
        27.636319,
        42.5605068
      ]
    },
    {
      "id": 24814,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 7.7756663,
        "lng": -72.2214154
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -72.3814154,
        7.6156663,
        -72.0614154,
        7.9356663
      ]
    },
    {
      "id": 24816,
      "name": "Koundara",
      "names": {
        "zh": "孔达拉"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 12.48333,
        "lng": -13.3
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -13.32,
        12.46333,
        -13.28,
        12.50333
      ]
    },
    {
      "id": 24822,
      "name": "Pak Thong Chai",
      "names": {
        "de": "Pak Tanga Chai",
        "ja": "パック・トン・チャイ",
        "th": "ปากทองชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.724833,
        "lng": 102.0226008
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.9826008,
        14.684833,
        102.0626008,
        14.764833
      ]
    },
    {
      "id": 24826,
      "name": "Feldkirchen",
      "names": {
        "zh": "费尔德基兴"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.7229178,
        "lng": 14.0964965
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.013117,
        46.6611523,
        14.1860643,
        46.7697343
      ]
    },
    {
      "id": 24834,
      "name": "Cienega de Flores",
      "names": {
        "pt": "Cienaga de flores"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.953326150000002,
        "lng": -100.18742414886418
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.2798497,
        25.8631353,
        -100.104371,
        26.0439343
      ]
    },
    {
      "id": 24843,
      "name": "Montfoort",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0441945,
        "lng": 4.9514009
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8682251,
        52.010014,
        5.0180105,
        52.0799203
      ]
    },
    {
      "id": 24855,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 44.0778456,
        "lng": 22.0991779
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        21.9914196,
        44.0401771,
        22.1433029,
        44.1451152
      ]
    },
    {
      "id": 24860,
      "name": "Estremoz",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8432944,
        "lng": -7.586907
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.799951,
        38.6848638,
        -7.4527137,
        39.0084383
      ]
    },
    {
      "id": 24866,
      "name": "Tenerife",
      "names": {
        "fr": "Ténérife",
        "zh": "特内里费",
        "de": "Teneriffa",
        "ja": "テネリフェ島",
        "th": "เตเนรีเฟ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9003487,
        "lng": -74.8589973
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8731825,
        9.807956,
        -74.5540278,
        10.0178262
      ]
    },
    {
      "id": 24871,
      "name": "Bua Yai",
      "names": {
        "th": "บัวใหญ่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5871679,
        "lng": 102.4257424
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.3857424,
        15.5471679,
        102.4657424,
        15.6271679
      ]
    },
    {
      "id": 24875,
      "name": "Raydah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.816081,
        "lng": 44.035387
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.025387,
        15.806081,
        44.045387,
        15.826081
      ]
    },
    {
      "id": 24886,
      "name": "Korneuburg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3440605,
        "lng": 16.3334321
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.2879926,
        48.3265767,
        16.3496969,
        48.3641025
      ]
    },
    {
      "id": 24887,
      "name": "Svetlogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9417552,
        "lng": 20.1550863
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        20.0731872,
        54.916036,
        20.2242095,
        54.9526087
      ]
    },
    {
      "id": 24891,
      "name": "Sines",
      "names": {
        "zh": "锡尼什",
        "ja": "正弦",
        "th": "ไซนส์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.956549,
        "lng": -8.8689639
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8939176,
        37.804947,
        -8.6814355,
        38.0492931
      ]
    },
    {
      "id": 24897,
      "name": "Kouroussa",
      "names": {
        "zh": "库鲁萨"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.65,
        "lng": -9.88333
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -9.90333,
        10.63,
        -9.86333,
        10.67
      ]
    },
    {
      "id": 24914,
      "name": "Almoloya de Alquisiras",
      "names": {
        "it": "Almoya de Alquisiras",
        "th": "Almoloya de alquesiras",
        "pt": "Almoloya de Alquisira"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.850629249999997,
        "lng": -99.8686946908273
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.9545597,
        18.6965316,
        -99.7800623,
        18.9174267
      ]
    },
    {
      "id": 24923,
      "name": "Juan de Acosta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.8291974,
        "lng": -75.0347039
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0747039,
        10.7891974,
        -74.9947039,
        10.8691974
      ]
    },
    {
      "id": 24937,
      "name": "Valenca",
      "names": {
        "es": "Valença",
        "fr": "Valença",
        "zh": "瓦伦萨",
        "pt": "Valença"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 42.0273051,
        "lng": -8.6404204
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6819074,
        41.9271672,
        -8.5292215,
        42.0568724
      ]
    },
    {
      "id": 24946,
      "name": "El Porvenir de Velasco Suarez",
      "names": {
        "it": "El Porvir de Velasco Suarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.457515,
        "lng": -92.280304
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.3412929,
        15.3895684,
        -92.2037674,
        15.4683888
      ]
    },
    {
      "id": 24955,
      "name": "Puspokladany",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.319139,
        "lng": 21.113685
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.082354,
        47.304749,
        21.144977,
        47.332128
      ]
    },
    {
      "id": 24972,
      "name": "Golub-Dobrzyn",
      "names": {
        "zh": "戈卢布-dobrzyn"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.106955,
        "lng": 19.053424
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.0305,
        53.09406,
        19.077774,
        53.130403
      ]
    },
    {
      "id": 24976,
      "name": "Uitgeest",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.524722,
        "lng": 4.705315
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.690494,
        52.494954,
        4.782476,
        52.551643
      ]
    },
    {
      "id": 24991,
      "name": "Titiribi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.06276,
        "lng": -75.7937
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8137,
        6.04276,
        -75.7737,
        6.08276
      ]
    },
    {
      "id": 24994,
      "name": "Beilen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.8610999,
        "lng": 6.5124319
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.4132933,
        52.8213612,
        6.5805833,
        52.9035505
      ]
    },
    {
      "id": 24998,
      "name": "Puerto Deseado",
      "names": {
        "fr": "Puerto Desado",
        "zh": "波多黎各",
        "de": "Puerto Desado",
        "ja": "プエルトデスデード",
        "th": "เปอร์โตเด ธ โอ",
        "pt": "Puerto desado"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -47.752471,
        "lng": -65.91342914774154
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.9248249,
        -47.7557552,
        -65.9019953,
        -47.7493239
      ]
    },
    {
      "id": 25012,
      "name": "Kerben",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 41.4917036,
        "lng": 71.7552365
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        71.7283735,
        41.4700477,
        71.7752063,
        41.5095608
      ]
    },
    {
      "id": 25013,
      "name": "Rheinfelden",
      "names": {
        "zh": "莱因费尔顿"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5543859,
        "lng": 7.7922905
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        7.7528393,
        47.5327191,
        7.8382097,
        47.5880068
      ]
    },
    {
      "id": 25022,
      "name": "Nafpaktos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.3926119,
        "lng": 21.8287498
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.7887498,
        38.3526119,
        21.8687498,
        38.4326119
      ]
    },
    {
      "id": 25027,
      "name": "Astravyets",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.61378,
        "lng": 25.95537
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        25.93537,
        54.59378,
        25.97537,
        54.63378
      ]
    },
    {
      "id": 25030,
      "name": "Narvik",
      "names": {
        "zh": "纳尔维克",
        "th": "นาร์วิค"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 68.4383796,
        "lng": 17.4271978
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        17.3871978,
        68.3983796,
        17.4671978,
        68.4783796
      ]
    },
    {
      "id": 25034,
      "name": "Lubben (Spreewald)",
      "names": {
        "zh": "Lubben（Spreewald）",
        "ja": "Lubben（Spreewald）"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.944692450000005,
        "lng": 13.900972432732274
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.8536068,
        51.897889,
        13.9960515,
        51.9911172
      ]
    },
    {
      "id": 25037,
      "name": "Nelas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5301833,
        "lng": -7.8499097
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8549097,
        40.5251833,
        -7.8449097,
        40.5351833
      ]
    },
    {
      "id": 25046,
      "name": "Anatuya",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -28.4620045,
        "lng": -62.8351968
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.8352468,
        -28.4620545,
        -62.8351468,
        -28.4619545
      ]
    },
    {
      "id": 25050,
      "name": "Natanz",
      "names": {
        "zh": "纳坦兹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 33.5202276,
        "lng": 51.9127572
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.9127072,
        33.5201776,
        51.9128072,
        33.5202776
      ]
    },
    {
      "id": 25073,
      "name": "Tempio Pausania",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.901182,
        "lng": 9.105765
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.08456,
        40.88068,
        9.12456,
        40.92068
      ]
    },
    {
      "id": 25077,
      "name": "Castelsarrasin",
      "names": {
        "zh": "卡斯特尔萨拉桑"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0395151,
        "lng": 1.1072875
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.0394821,
        43.9939697,
        1.2026228,
        44.1128677
      ]
    },
    {
      "id": 25081,
      "name": "Merzifon",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8721392,
        "lng": 35.4635383
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.2256411,
        40.6363763,
        35.643938,
        41.0423825
      ]
    },
    {
      "id": 25090,
      "name": "Montabaur",
      "names": {
        "zh": "蒙塔鲍尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.438801,
        "lng": 7.825746
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.727702,
        50.383698,
        7.886209,
        50.457131
      ]
    },
    {
      "id": 25093,
      "name": "Villacidro",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 39.458274,
        "lng": 8.742203
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.726887,
        39.443369,
        8.75242,
        39.469681
      ]
    },
    {
      "id": 25094,
      "name": "Mjolby",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.3254361,
        "lng": 15.131448
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        15.091448,
        58.2854361,
        15.171448,
        58.3654361
      ]
    },
    {
      "id": 25097,
      "name": "Gouveia",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4940078,
        "lng": -7.5917814
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.7421003,
        40.3875707,
        -7.4342587,
        40.6079207
      ]
    },
    {
      "id": 25106,
      "name": "Bang Racham",
      "names": {
        "zh": "邦里拉姆",
        "ja": "バンラチャーム",
        "th": "บางราคัม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.886402,
        "lng": 100.310938
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.29038776,
        14.8667391892,
        100.330949556,
        14.9060522569
      ]
    },
    {
      "id": 25109,
      "name": "Enghien",
      "names": {
        "zh": "昂吉安"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6957066,
        "lng": 4.0411928
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        3.9612715,
        50.6307227,
        4.1140971,
        50.7135083
      ]
    },
    {
      "id": 25114,
      "name": "Aleksandrow Kujawski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.8758646,
        "lng": 18.6963912
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        18.6913912,
        52.8708646,
        18.7013912,
        52.8808646
      ]
    },
    {
      "id": 25118,
      "name": "Mor",
      "names": {
        "zh": "铁道部",
        "ja": "粗腐植",
        "th": "หมอ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3720267,
        "lng": 18.2090388
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.0921359,
        47.3347823,
        18.2877657,
        47.4519888
      ]
    },
    {
      "id": 25121,
      "name": "Rio de Oro",
      "names": {
        "zh": "里约热内卢",
        "ja": "リオデオロ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2924446,
        "lng": -73.385798
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6549873,
        8.0608932,
        -73.3584447,
        8.3680377
      ]
    },
    {
      "id": 25122,
      "name": "El Carmen de Atrato",
      "names": {
        "de": "El Carmen de atorato",
        "it": "El Carmen de Atro"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8993328,
        "lng": -76.1422597
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1622597,
        5.8793328,
        -76.1222597,
        5.9193328
      ]
    },
    {
      "id": 25124,
      "name": "Guazacapan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.07417,
        "lng": -90.41667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.43667,
        14.05417,
        -90.39667,
        14.09417
      ]
    },
    {
      "id": 25132,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.3344713,
        "lng": -75.6666238
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.5152362,
        7.357218,
        -74.7851374,
        9.4478271
      ]
    },
    {
      "id": 25135,
      "name": "Famenin",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.11593,
        "lng": 48.97336
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        48.95336,
        35.09593,
        48.99336,
        35.13593
      ]
    },
    {
      "id": 25160,
      "name": "Taphan Hin",
      "names": {
        "it": "Tafano",
        "ja": "タパンヒン",
        "th": "ตะพังตะพัง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.214265,
        "lng": 100.416568
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.257477,
        16.087583,
        100.58823,
        16.309761
      ]
    },
    {
      "id": 25163,
      "name": "San Bartolo",
      "names": {
        "zh": "圣巴托洛",
        "ja": "サンバルトロ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0834936,
        "lng": -91.4546861
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.5115436,
        15.06888,
        -91.3870801,
        15.1454523
      ]
    },
    {
      "id": 25179,
      "name": "Nesebar",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6607865,
        "lng": 27.7167464
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        27.6767464,
        42.6207865,
        27.7567464,
        42.7007865
      ]
    },
    {
      "id": 25199,
      "name": "Alcanena",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4638801,
        "lng": -8.6575744
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6575986,
        39.4638501,
        -8.6575691,
        39.4639287
      ]
    },
    {
      "id": 25209,
      "name": "Kiskoros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6205706,
        "lng": 19.2843551
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.1939088,
        46.5639634,
        19.3884466,
        46.6721157
      ]
    },
    {
      "id": 25223,
      "name": "Polotitlan de la Ilustracion",
      "names": {
        "ja": "Polotitlan de laiustacion"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.225405,
        "lng": -99.817955
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.828788,
        20.210667,
        -99.79961,
        20.237889
      ]
    },
    {
      "id": 25226,
      "name": "Tucacas",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.805822,
        "lng": -68.315391
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -68.415391,
        10.705822,
        -68.215391,
        10.905822
      ]
    },
    {
      "id": 25228,
      "name": "Babaeski",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4325,
        "lng": 27.09306
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.07306,
        41.4125,
        27.11306,
        41.4525
      ]
    },
    {
      "id": 25284,
      "name": "Kauhajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.431748,
        "lng": 22.1841988
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.869059,
        62.1020628,
        22.6632585,
        62.5508739
      ]
    },
    {
      "id": 25289,
      "name": "Erbach",
      "names": {
        "zh": "埃尔巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6376,
        "lng": 9.013
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        8.9367832,
        49.5857469,
        9.0745697,
        49.6749545
      ]
    },
    {
      "id": 25295,
      "name": "Niquinohomo",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.90449865,
        "lng": -86.09538052454799
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.1097604,
        11.8986247,
        -86.0833479,
        11.9103407
      ]
    },
    {
      "id": 25306,
      "name": "Guapi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5708069,
        "lng": -77.882619
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.9280223,
        2.1335663,
        -77.375148,
        2.7056445
      ]
    },
    {
      "id": 25307,
      "name": "Hassfurt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.033516,
        "lng": 10.5096868
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.5096368,
        50.033466,
        10.5097368,
        50.033566
      ]
    },
    {
      "id": 25308,
      "name": "Demre",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.2443603,
        "lng": 29.9875293
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.7888923,
        36.1668111,
        30.0716943,
        36.4104117
      ]
    },
    {
      "id": 25326,
      "name": "Horodyshche",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.121929,
        "lng": 27.072035
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.052035,
        50.101929,
        27.092035,
        50.141929
      ]
    },
    {
      "id": 25328,
      "name": "Apostolove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6617478,
        "lng": 33.7180001
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.6792847,
        47.6388632,
        33.7511376,
        47.6855777
      ]
    },
    {
      "id": 25338,
      "name": "Islahiye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.025109,
        "lng": 36.6331302
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.4434139,
        36.825573,
        36.964505,
        37.1517102
      ]
    },
    {
      "id": 25348,
      "name": "Jindayris",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.39496,
        "lng": 36.6889
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.6689,
        36.37496,
        36.7089,
        36.41496
      ]
    },
    {
      "id": 25353,
      "name": "Jardin",
      "names": {
        "zh": "花园",
        "ja": "ジャルダン",
        "th": "จาร์ดีน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5990503,
        "lng": -75.8191833
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8591833,
        5.5590503,
        -75.7791833,
        5.6390503
      ]
    },
    {
      "id": 25354,
      "name": "Eichstatt",
      "names": {
        "de": "Eichstätt"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8933417,
        "lng": 11.1838965
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.1286199,
        48.8495647,
        11.2756413,
        48.9233981
      ]
    },
    {
      "id": 25366,
      "name": "Lauterbach",
      "names": {
        "zh": "劳特巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6368507,
        "lng": 9.3968633
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.3568633,
        50.5968507,
        9.4368633,
        50.6768507
      ]
    },
    {
      "id": 25371,
      "name": "Wlodawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.55,
        "lng": 23.55
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.489497533,
        51.515486773,
        23.577895064,
        51.567084839
      ]
    },
    {
      "id": 25378,
      "name": "Hola Prystan",
      "names": {
        "ja": "ホーラプリステン"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.5272,
        "lng": 32.52417
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.50417,
        46.5072,
        32.54417,
        46.5472
      ]
    },
    {
      "id": 25387,
      "name": "Goldap",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.3073745,
        "lng": 22.3038748
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.3038248,
        54.3073245,
        22.3039248,
        54.3074245
      ]
    },
    {
      "id": 25391,
      "name": "La Quiaca",
      "names": {
        "ja": "ラキアカ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.10867725,
        "lng": -65.59955733610917
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.6102196,
        -22.1228276,
        -65.5791197,
        -22.096446
      ]
    },
    {
      "id": 25406,
      "name": "Filandia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.67472,
        "lng": -75.65833
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.67833,
        4.65472,
        -75.63833,
        4.69472
      ]
    },
    {
      "id": 25411,
      "name": "Schoonhoven",
      "names": {
        "zh": "斯洪霍芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9463984,
        "lng": 4.8516431
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8272032,
        51.9379917,
        4.877793,
        51.9712864
      ]
    },
    {
      "id": 25417,
      "name": "Xireg",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9283791,
        "lng": 98.4799494
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.3199494,
        36.7683791,
        98.6399494,
        37.0883791
      ]
    },
    {
      "id": 25419,
      "name": "Satoraljaujhely",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.3960978,
        "lng": 21.6561497
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.568056,
        48.3194896,
        21.705868,
        48.4427016
      ]
    },
    {
      "id": 25432,
      "name": "Altos del Rosario",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7920971,
        "lng": -74.1639876
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.2039876,
        8.7520971,
        -74.1239876,
        8.8320971
      ]
    },
    {
      "id": 25443,
      "name": "San Pedro Pochutla",
      "names": {
        "fr": "San Pedro Pokutla",
        "ja": "サンペドロポチュラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.745615,
        "lng": -96.466032
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.477538,
        15.729723,
        -96.457309,
        15.759894
      ]
    },
    {
      "id": 25447,
      "name": "Tavsanli",
      "names": {
        "zh": "塔夫尚勒"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5451181,
        "lng": 29.4955923
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.9441485,
        39.3447303,
        29.8309793,
        39.7870132
      ]
    },
    {
      "id": 25454,
      "name": "Ad Duraykish",
      "names": {
        "zh": "广告Duraykish",
        "de": "Ad duraykisch",
        "it": "Duraykish",
        "id": "Ad durayatkish",
        "ja": "広告デュライキー",
        "th": "โฆษณา Duraykish"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8958651,
        "lng": 36.1374533
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.0974533,
        34.8558651,
        36.1774533,
        34.9358651
      ]
    },
    {
      "id": 25457,
      "name": "Winsum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.328409,
        "lng": 6.5163966
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.4530114,
        53.3005847,
        6.5681293,
        53.3673909
      ]
    },
    {
      "id": 25464,
      "name": "Fontenay-le-Comte",
      "names": {
        "zh": "丰特奈 - 勒孔特",
        "ja": "fontenay・ル・コント",
        "th": "fontenay-เลอ-ไหน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.4660771,
        "lng": -0.8064003
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.8539267,
        46.4322892,
        -0.7236545,
        46.4851116
      ]
    },
    {
      "id": 25468,
      "name": "San Jorge",
      "names": {
        "zh": "圣乔治",
        "ja": "サンホルジュ",
        "th": "ซานจอร์อร์"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": -25.2841324,
        "lng": -57.5520677
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -57.564439,
        -25.2932233,
        -57.5451437,
        -25.27528
      ]
    },
    {
      "id": 25474,
      "name": "Dinant",
      "names": {
        "zh": "迪南",
        "ja": "ディナン"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.2591813,
        "lng": 4.9130588
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.8544268,
        50.1802376,
        5.0481081,
        50.3046782
      ]
    },
    {
      "id": 25511,
      "name": "Glubczyce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2001072,
        "lng": 17.8301977
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.8000309,
        50.1809946,
        17.8622247,
        50.2275563
      ]
    },
    {
      "id": 25520,
      "name": "Trogir",
      "names": {
        "zh": "特罗吉尔",
        "ja": "トロギル"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.516545449999995,
        "lng": 16.24966887403926
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.245963,
        43.5149496,
        16.2525981,
        43.5181022
      ]
    },
    {
      "id": 25541,
      "name": "Kastoria",
      "names": {
        "zh": "卡斯托利亚"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.5216297,
        "lng": 21.2636522
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.1036522,
        40.3616297,
        21.4236522,
        40.6816297
      ]
    },
    {
      "id": 25553,
      "name": "Agua Blanca",
      "names": {
        "ja": "アグアブランカ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4981799,
        "lng": -89.6498579
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.6898579,
        14.4581799,
        -89.6098579,
        14.5381799
      ]
    },
    {
      "id": 25555,
      "name": "San Javier",
      "names": {
        "zh": "圣哈维尔",
        "th": "ซานเจวา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.8738956,
        "lng": -55.1347737
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -55.1607191,
        -27.8942863,
        -55.1273513,
        -27.8439118
      ]
    },
    {
      "id": 25563,
      "name": "Wolsztyn",
      "names": {
        "zh": "沃尔什滕"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.11725,
        "lng": 16.1126622
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.0896099,
        52.0976977,
        16.1377403,
        52.1258569
      ]
    },
    {
      "id": 25565,
      "name": "Nidzica",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.357594,
        "lng": 20.422281
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.396176,
        53.346467,
        20.449783,
        53.379541
      ]
    },
    {
      "id": 25572,
      "name": "Frias",
      "names": {
        "fr": "Frías",
        "zh": "弗里亚斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -28.6377023,
        "lng": -65.1288391
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.1478034,
        -28.6546914,
        -65.1020766,
        -28.6161445
      ]
    },
    {
      "id": 25584,
      "name": "Kaharlyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8650658,
        "lng": 30.8226786
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.7392838,
        49.8129371,
        30.856487,
        49.8890386
      ]
    },
    {
      "id": 25603,
      "name": "Feldbach",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 46.9527822,
        "lng": 15.8886091
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.8208563,
        46.8953259,
        15.951079,
        47.0293621
      ]
    },
    {
      "id": 25614,
      "name": "Vitry-le-Francois",
      "names": {
        "zh": "维特里了-弗朗索瓦",
        "ja": "vitry・ル・フランソワ",
        "th": "vitry-เลอ-ฟรอง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7262225,
        "lng": 4.5851622
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.5730627,
        48.7133012,
        4.6161385,
        48.7451901
      ]
    },
    {
      "id": 25616,
      "name": "Arruda dos Vinhos",
      "names": {
        "zh": "Aruda DoS Vinhos"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.98303,
        "lng": -9.07763
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.1785273,
        38.9214369,
        -9.0218938,
        39.0287911
      ]
    },
    {
      "id": 25626,
      "name": "Chalma",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.20891,
        "lng": -98.396371
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.41799,
        21.18881,
        -98.37799,
        21.22881
      ]
    },
    {
      "id": 25628,
      "name": "Ilamatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7805921,
        "lng": -98.4431823
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4831823,
        20.7405921,
        -98.4031823,
        20.8205921
      ]
    },
    {
      "id": 25629,
      "name": "Chanaral",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -26.347934,
        "lng": -70.6223617
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.7242566,
        -26.9419171,
        -69.8814421,
        -25.894739
      ]
    },
    {
      "id": 25635,
      "name": "Chateaudun",
      "names": {
        "fr": "Châteaudun",
        "zh": "沙托丹"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.073417,
        "lng": 1.3383879
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.3333879,
        48.068417,
        1.3433879,
        48.078417
      ]
    },
    {
      "id": 25641,
      "name": "Kussnacht",
      "names": {
        "de": "Küssnacht",
        "it": "Küssnacht"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.08385,
        "lng": 8.4386308
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.3887412,
        47.0484229,
        8.5079389,
        47.1144263
      ]
    },
    {
      "id": 25646,
      "name": "Woudenberg",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.08025955,
        "lng": 5.4122265761644925
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2983007,
        52.0431232,
        5.497876,
        52.1174262
      ]
    },
    {
      "id": 25649,
      "name": "Dok Kham Tai",
      "names": {
        "ja": "ドックカムタイ",
        "th": "ด๊อคขามไท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1625658,
        "lng": 99.9906059
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.9506059,
        19.1225658,
        100.0306059,
        19.2025658
      ]
    },
    {
      "id": 25651,
      "name": "Mayenne",
      "names": {
        "zh": "马耶纳"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3026537,
        "lng": -0.6171928616853183
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.6534534,
        48.2564015,
        -0.5875715,
        48.3489692
      ]
    },
    {
      "id": 25652,
      "name": "Manati",
      "names": {
        "zh": "马纳蒂"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4470319,
        "lng": -74.9594166
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0743437,
        10.3657713,
        -74.9083821,
        10.5428199
      ]
    },
    {
      "id": 25662,
      "name": "Neunkirchen",
      "names": {
        "zh": "诺因基兴"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.7221147,
        "lng": 16.081602
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.0611805,
        47.7108422,
        16.1331399,
        47.7714069
      ]
    },
    {
      "id": 25669,
      "name": "Gex",
      "names": {
        "zh": "热克斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.333849,
        "lng": 6.0577244
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.9998615,
        46.3157638,
        6.0977496,
        46.3872177
      ]
    },
    {
      "id": 25676,
      "name": "Fereydunshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.94098,
        "lng": 50.121
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.101,
        32.92098,
        50.141,
        32.96098
      ]
    },
    {
      "id": 25680,
      "name": "Union de Tula",
      "names": {
        "zh": "联盟de tula",
        "ja": "ユニオンデトゥーラ",
        "th": "สหภาพเดอตูล"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9567054,
        "lng": -104.2674704
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.3074704,
        19.9167054,
        -104.2274704,
        19.9967054
      ]
    },
    {
      "id": 25696,
      "name": "Kaspi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9252,
        "lng": 44.42568
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        44.40568,
        41.9052,
        44.44568,
        41.9452
      ]
    },
    {
      "id": 25711,
      "name": "Brig-Glis",
      "names": {
        "fr": "Le brigadier-glis",
        "zh": "布里格-glis",
        "it": "-Brig glis",
        "ja": "拘置所-glis",
        "th": "สำเภา-glis"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.313619,
        "lng": 7.987174
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        7.92616,
        46.255032,
        8.04648,
        46.324321
      ]
    },
    {
      "id": 25714,
      "name": "San Jacinto del Cauca",
      "names": {
        "th": "ซานจาคินโซ่คอซกา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2525295,
        "lng": -74.7177669
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8341002,
        8.0314783,
        -74.4986406,
        8.427914
      ]
    },
    {
      "id": 25719,
      "name": "Khoyniki",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 51.8911,
        "lng": 29.9552
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.9352,
        51.8711,
        29.9752,
        51.9111
      ]
    },
    {
      "id": 25724,
      "name": "Develi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3879285,
        "lng": 35.4900997
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.2109567,
        37.9958161,
        36.0209181,
        38.5462819
      ]
    },
    {
      "id": 25725,
      "name": "Schinnen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.944192,
        "lng": 5.889156
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.847201,
        50.924023,
        5.912927,
        50.953985
      ]
    },
    {
      "id": 25745,
      "name": "Tauberbischofsheim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6229682,
        "lng": 9.6627138
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.5853828,
        49.5619523,
        9.7259262,
        49.6838881
      ]
    },
    {
      "id": 25748,
      "name": "Bollnas",
      "names": {
        "de": "Bollnäs"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 61.350454,
        "lng": 16.391902
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        16.386902,
        61.345454,
        16.396902,
        61.355454
      ]
    },
    {
      "id": 25756,
      "name": "Espita",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.013258,
        "lng": -88.306901
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -88.316491,
        20.993309,
        -88.289526,
        21.028707
      ]
    },
    {
      "id": 25761,
      "name": "Rosas",
      "names": {
        "zh": "罗萨斯",
        "ja": "ロサス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.2621018,
        "lng": -76.7392875
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.8424892,
        2.187025,
        -76.6490085,
        2.3194077
      ]
    },
    {
      "id": 25767,
      "name": "Gmunden",
      "names": {
        "zh": "格蒙登"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.9180866,
        "lng": 13.7993935
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.7993435,
        47.9180366,
        13.7994435,
        47.9181366
      ]
    },
    {
      "id": 25785,
      "name": "Grimstad",
      "names": {
        "zh": "格里姆斯塔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.3413026,
        "lng": 8.5934098
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        8.5534098,
        58.3013026,
        8.6334098,
        58.3813026
      ]
    },
    {
      "id": 25791,
      "name": "Soyaniquilpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0141053,
        "lng": -99.5270837
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.5271337,
        20.0140553,
        -99.5270337,
        20.0141553
      ]
    },
    {
      "id": 25793,
      "name": "Bozuyuk",
      "names": {
        "zh": "博聚于克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.90778,
        "lng": 30.03667
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.01667,
        39.88778,
        30.05667,
        39.92778
      ]
    },
    {
      "id": 25794,
      "name": "Gueret",
      "names": {
        "zh": "盖雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1689521,
        "lng": 1.8715763
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.8382111,
        46.1131732,
        1.9027095,
        46.2042651
      ]
    },
    {
      "id": 25796,
      "name": "Joaquin V. Gonzalez",
      "names": {
        "es": "Joaquin V. González",
        "zh": "Joaquin V.Gonzalez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.9381341,
        "lng": -59.7862875
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.7873781,
        -30.9388632,
        -59.7851369,
        -30.9373934
      ]
    },
    {
      "id": 25810,
      "name": "Autun",
      "names": {
        "zh": "欧坦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9510313,
        "lng": 4.2989478
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.2355802,
        46.8823573,
        4.3717358,
        46.9948988
      ]
    },
    {
      "id": 25815,
      "name": "Karakocan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.95583,
        "lng": 40.03861
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.01861,
        38.93583,
        40.05861,
        38.97583
      ]
    },
    {
      "id": 25837,
      "name": "Oudenbosch",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5889415,
        "lng": 4.5278916
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.5109529,
        51.5657764,
        4.5680271,
        51.6171446
      ]
    },
    {
      "id": 25856,
      "name": "Bombarral",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2651095,
        "lng": -9.1533714
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.1583714,
        39.2601095,
        -9.1483714,
        39.2701095
      ]
    },
    {
      "id": 25859,
      "name": "Neustadt",
      "names": {
        "zh": "诺伊施塔特",
        "ja": "ノイシュタット",
        "th": "ตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.84426205,
        "lng": 9.10437940314108
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.049869,
        50.8180981,
        9.150879,
        50.8703159
      ]
    },
    {
      "id": 25861,
      "name": "Al Muzayrib",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.71084,
        "lng": 36.02751
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.00751,
        32.69084,
        36.04751,
        32.73084
      ]
    },
    {
      "id": 25894,
      "name": "Cocotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.22765685,
        "lng": -98.85986662572438
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8814401,
        19.2055929,
        -98.8317115,
        19.2498372
      ]
    },
    {
      "id": 25898,
      "name": "Usumatlan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9475498,
        "lng": -89.7769265
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.7969265,
        14.9275498,
        -89.7569265,
        14.9675498
      ]
    },
    {
      "id": 25899,
      "name": "Beyla",
      "names": {
        "zh": "贝拉"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 8.6898481,
        "lng": -8.648345
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -8.688345,
        8.6498481,
        -8.608345,
        8.7298481
      ]
    },
    {
      "id": 25911,
      "name": "Trzebnica",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.3040435,
        "lng": 17.0632874
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        17.0582874,
        51.2990435,
        17.0682874,
        51.3090435
      ]
    },
    {
      "id": 25926,
      "name": "Ulvila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4333298,
        "lng": 21.8833199
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.8029969,
        61.3846054,
        22.4341638,
        61.5732415
      ]
    },
    {
      "id": 25930,
      "name": "Gvardeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6478265,
        "lng": 21.0719431
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        21.0276702,
        54.6272937,
        21.0962788,
        54.672563
      ]
    },
    {
      "id": 25936,
      "name": "Bergen",
      "names": {
        "zh": "卑尔根",
        "ja": "ベルゲン",
        "th": "เบอร์เกน"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6702278,
        "lng": 4.7011616
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.640392,
        52.6401769,
        4.7524647,
        52.6938396
      ]
    },
    {
      "id": 25937,
      "name": "Sillamae",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.400747,
        "lng": 27.744714
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        27.702803,
        59.380738,
        27.806803,
        59.423876
      ]
    },
    {
      "id": 25951,
      "name": "Erbaa",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6727976,
        "lng": 36.5715055
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.3453912,
        40.4935025,
        36.8727423,
        40.910309
      ]
    },
    {
      "id": 25954,
      "name": "Sabinov",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.1005118,
        "lng": 21.1030225
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.1029725,
        49.1004618,
        21.1030725,
        49.1005618
      ]
    },
    {
      "id": 25969,
      "name": "Vila Pouca de Aguiar",
      "names": {
        "ja": "ヴィラプッシャデアギアール"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.499114,
        "lng": -7.643752
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.65097886403,
        41.4908761813,
        -7.63463629812,
        41.5070658144
      ]
    },
    {
      "id": 25971,
      "name": "Vasylivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.4431859,
        "lng": 35.2803026
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.2322521,
        47.4161792,
        35.3246189,
        47.4681329
      ]
    },
    {
      "id": 25983,
      "name": "Ansiao",
      "names": {
        "pt": "Ansião"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9113771,
        "lng": -8.4359857
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5388121,
        39.855559,
        -8.3347893,
        40.0267272
      ]
    },
    {
      "id": 25985,
      "name": "Miranda do Corvo",
      "names": {
        "zh": "米兰达Do Corvo",
        "th": "มิแรนดาทำ Corvo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.0941517,
        "lng": -8.3336555
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.405621,
        40.0260577,
        -8.2421904,
        40.1943772
      ]
    },
    {
      "id": 25987,
      "name": "Balatonfured",
      "names": {
        "zh": "巴拉顿菲赖德"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.956305,
        "lng": 17.8829694
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.8779694,
        46.951305,
        17.8879694,
        46.961305
      ]
    },
    {
      "id": 25996,
      "name": "Mazatlan Villa de Flores",
      "names": {
        "es": "Mazatlán Villa de Flores"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.01487755,
        "lng": -96.90708723299721
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9889089,
        17.9335773,
        -96.8118345,
        18.0958034
      ]
    },
    {
      "id": 26004,
      "name": "Singuilucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.967801,
        "lng": -98.517783
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.530155,
        19.959739,
        -98.508712,
        19.980808
      ]
    },
    {
      "id": 26018,
      "name": "Agdangan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8759428,
        "lng": 121.9141841
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.8741841,
        13.8359428,
        121.9541841,
        13.9159428
      ]
    },
    {
      "id": 26027,
      "name": "Bonyhad",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2984212,
        "lng": 18.532191
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.4763235,
        46.2686068,
        18.6034152,
        46.3966165
      ]
    },
    {
      "id": 26043,
      "name": "Adygeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.88248,
        "lng": 39.19194
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.17194,
        44.86248,
        39.21194,
        44.90248
      ]
    },
    {
      "id": 26092,
      "name": "Arcachon",
      "names": {
        "zh": "阿尔卡雄",
        "ja": "アルカション"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.6589878,
        "lng": -1.1656322
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.1706322,
        44.6539878,
        -1.1606322,
        44.6639878
      ]
    },
    {
      "id": 26094,
      "name": "Chos Malal",
      "names": {
        "fr": "Chos malole"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.3785324,
        "lng": -70.2719127
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -70.2828313,
        -37.3892334,
        -70.259386,
        -37.3536389
      ]
    },
    {
      "id": 26098,
      "name": "Song Phi Nong",
      "names": {
        "es": "Canción phi nong",
        "zh": "宋皮恩",
        "it": "Canzone phi nong",
        "ja": "ソングピオン",
        "th": "เพลงพี่หนอง",
        "pt": "Canção phi nong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.2309102,
        "lng": 100.0349884
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.9949884,
        14.1909102,
        100.0749884,
        14.2709102
      ]
    },
    {
      "id": 26107,
      "name": "Sarrebourg",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7351659,
        "lng": 7.0537345
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.0536845,
        48.7351159,
        7.0537845,
        48.7352159
      ]
    },
    {
      "id": 26139,
      "name": "Tetiiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.367893,
        "lng": 29.690133
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.670133,
        49.347893,
        29.710133,
        49.387893
      ]
    },
    {
      "id": 26141,
      "name": "Vieira do Minho",
      "names": {
        "zh": "Vieira做Minho",
        "ja": "ヴィエイラはミンホをします"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.6343631,
        "lng": -8.132969
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.133019,
        41.6343131,
        -8.132919,
        41.6344131
      ]
    },
    {
      "id": 26144,
      "name": "Kaji",
      "names": {
        "zh": "加持"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.8559,
        "lng": 104.858
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        104.838,
        23.8359,
        104.878,
        23.8759
      ]
    },
    {
      "id": 26145,
      "name": "Alcacer do Sal",
      "names": {
        "zh": "Alcacer做萨尔",
        "ja": "アルカセルはSalをします"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.3729735,
        "lng": -8.5122705
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8227639,
        38.1783958,
        -8.1296963,
        38.5530991
      ]
    },
    {
      "id": 26163,
      "name": "Shahr-e Herat",
      "names": {
        "zh": "沙哈尔赫拉特",
        "pt": "Herat shahr-e"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.7611717,
        "lng": 62.1019853
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        62.1019853,
        34.7609918,
        62.1020474,
        34.7611717
      ]
    },
    {
      "id": 26175,
      "name": "Anolaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7616096,
        "lng": -74.4649986
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5254523,
        4.7258599,
        -74.3926868,
        4.8479575
      ]
    },
    {
      "id": 26180,
      "name": "Bonneville",
      "names": {
        "zh": "博纳维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.0775797,
        "lng": 6.4086189
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.3471949,
        46.0420063,
        6.460721,
        46.1150528
      ]
    },
    {
      "id": 26191,
      "name": "Notodden",
      "names": {
        "zh": "诺托登"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5592989,
        "lng": 9.2668569
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.2618569,
        59.5542989,
        9.2718569,
        59.5642989
      ]
    },
    {
      "id": 26194,
      "name": "Karapinar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7177584,
        "lng": 33.5476273
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.119598,
        37.4590703,
        33.9734929,
        38.0056986
      ]
    },
    {
      "id": 26202,
      "name": "Bahce",
      "names": {
        "zh": "巴赫切"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2042113,
        "lng": 36.5820496
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.4205434,
        37.1457454,
        36.7239797,
        37.2919761
      ]
    },
    {
      "id": 26205,
      "name": "Chaiyo",
      "names": {
        "th": "ไชโย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6646588,
        "lng": 100.4671088
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.4271088,
        14.6246588,
        100.5071088,
        14.7046588
      ]
    },
    {
      "id": 26214,
      "name": "Perez",
      "names": {
        "es": "Pérez",
        "zh": "·佩雷斯",
        "ja": "・ペレス",
        "th": "เปเรซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1935057,
        "lng": 121.9243637
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        121.8283029,
        14.1012247,
        122.0656582,
        14.3228004
      ]
    },
    {
      "id": 26215,
      "name": "Tapilula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2496804,
        "lng": -93.0167904
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.0481278,
        17.2277907,
        -92.9631812,
        17.2821007
      ]
    },
    {
      "id": 26220,
      "name": "Franeker",
      "names": {
        "zh": "弗拉讷克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1880505,
        "lng": 5.5409648
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.4940179,
        53.1636846,
        5.6012258,
        53.2041945
      ]
    },
    {
      "id": 26235,
      "name": "Idil",
      "names": {
        "zh": "伊迪尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3401862,
        "lng": 41.8924031
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.5454871,
        37.1140938,
        42.1000726,
        37.4582712
      ]
    },
    {
      "id": 26236,
      "name": "Maravilla Tenejapa",
      "names": {
        "ja": "マラビラテネジャパ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.14064,
        "lng": -91.293472
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -91.31595,
        16.12155,
        -91.27595,
        16.16155
      ]
    },
    {
      "id": 26238,
      "name": "Yavoriv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.9456001,
        "lng": 23.3886319
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.3280289,
        49.9060721,
        23.422467,
        49.9692643
      ]
    },
    {
      "id": 26244,
      "name": "Grave",
      "names": {
        "es": "Tumba",
        "fr": "La tombe",
        "zh": "墓穴",
        "de": "Grab",
        "it": "Tomba",
        "id": "Kuburan",
        "ja": "墓",
        "th": "หลุมฝังศพ"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7596504,
        "lng": 5.7411125
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.696002,
        51.7454106,
        5.7579586,
        51.7750339
      ]
    },
    {
      "id": 26267,
      "name": "Vocklabruck",
      "names": {
        "de": "Vöcklabruck"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0062222,
        "lng": 13.6513491
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.6512991,
        48.0061722,
        13.6513991,
        48.0062722
      ]
    },
    {
      "id": 26273,
      "name": "Kapelle",
      "names": {
        "zh": "卡佩勒"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4879711,
        "lng": 3.96063
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.9128202,
        51.4470128,
        4.005408,
        51.5067537
      ]
    },
    {
      "id": 26285,
      "name": "Brzeziny",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8004949,
        "lng": 19.7517694
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.7081071,
        51.7769378,
        19.790762,
        51.8243844
      ]
    },
    {
      "id": 26288,
      "name": "Vel'ky Krtis",
      "names": {
        "it": "VEL'KY KRIS",
        "ja": "ベル語Krtis"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.208867,
        "lng": 19.346423
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.332379,
        48.181645,
        19.363942,
        48.217323
      ]
    },
    {
      "id": 26289,
      "name": "Ban Laem",
      "names": {
        "zh": "班拉",
        "de": "Laisenban",
        "id": "Ban LaM",
        "ja": "ベロムを禁止します",
        "th": "บ้านแหลม",
        "pt": "Proibir laem"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.2090689,
        "lng": 99.9801874
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.9401874,
        13.1690689,
        100.0201874,
        13.2490689
      ]
    },
    {
      "id": 26290,
      "name": "Pfarrkirchen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4320329,
        "lng": 12.9386266
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.8984758,
        48.3922328,
        13.0111395,
        48.484859
      ]
    },
    {
      "id": 26297,
      "name": "Kaeng Khoi",
      "names": {
        "th": "แก่งคอย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5897806,
        "lng": 101.0001303
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.9601303,
        14.5497806,
        101.0401303,
        14.6297806
      ]
    },
    {
      "id": 26304,
      "name": "Sungurlu",
      "names": {
        "zh": "松古尔卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1638239,
        "lng": 34.3753355
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.9589119,
        39.9394362,
        34.691899,
        40.4831089
      ]
    },
    {
      "id": 26310,
      "name": "Malaryta",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 51.7941899,
        "lng": 24.0860123
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.0810123,
        51.7891899,
        24.0910123,
        51.7991899
      ]
    },
    {
      "id": 26313,
      "name": "Lubaczow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1574473,
        "lng": 23.1225859
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        23.0768442,
        50.1220987,
        23.1636542,
        50.1922832
      ]
    },
    {
      "id": 26338,
      "name": "Novhorod-Siverskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 52.004259,
        "lng": 33.2779899
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.2216568,
        51.9827543,
        33.328249,
        52.0505912
      ]
    },
    {
      "id": 26344,
      "name": "Chiquimulilla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.08764,
        "lng": -90.3807835
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.4207835,
        14.04764,
        -90.3407835,
        14.12764
      ]
    },
    {
      "id": 26348,
      "name": "Chiatura",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.287137,
        "lng": 43.2833686
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        43.2783686,
        42.282137,
        43.2883686,
        42.292137
      ]
    },
    {
      "id": 26359,
      "name": "Berkovitsa",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2373155,
        "lng": 23.1288525
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        23.0888525,
        43.1973155,
        23.1688525,
        43.2773155
      ]
    },
    {
      "id": 26361,
      "name": "Zaragoza",
      "names": {
        "fr": "Saragosse",
        "zh": "萨拉戈萨",
        "it": "Saragozza",
        "ja": "サラゴサ",
        "th": "ซาราโกซา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.86954205,
        "lng": -101.52453681159054
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.9972022,
        28.3764617,
        -100.6036666,
        29.3718418
      ]
    },
    {
      "id": 26364,
      "name": "Nong Khae",
      "names": {
        "zh": "作响",
        "th": "หนองแค"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3360152,
        "lng": 100.8714868
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.8314868,
        14.2960152,
        100.9114868,
        14.3760152
      ]
    },
    {
      "id": 26368,
      "name": "Voerendaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8783671,
        "lng": 5.9114393
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9113893,
        50.8783171,
        5.9114893,
        50.8784171
      ]
    },
    {
      "id": 26380,
      "name": "Bershad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.36594,
        "lng": 29.51889
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.4884982,
        48.3512668,
        29.5647823,
        48.3858129
      ]
    },
    {
      "id": 26386,
      "name": "Mora",
      "names": {
        "zh": "莫拉",
        "ja": "モーラ",
        "th": "โมรา"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 61.0086787,
        "lng": 14.5442519
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        14.5042519,
        60.9686787,
        14.5842519,
        61.0486787
      ]
    },
    {
      "id": 26395,
      "name": "Gyomaendrod",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9358032,
        "lng": 20.8260916
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.7020348,
        46.8260228,
        20.9703424,
        47.069455
      ]
    },
    {
      "id": 26398,
      "name": "Diebougou",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 10.9650701,
        "lng": -3.2487858
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -3.2887858,
        10.9250701,
        -3.2087858,
        11.0050701
      ]
    },
    {
      "id": 26411,
      "name": "Provins",
      "names": {
        "zh": "普罗万"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5602959,
        "lng": 3.2988089
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.2476151,
        48.5405249,
        3.3214076,
        48.5852675
      ]
    },
    {
      "id": 26414,
      "name": "Hajduhadhaz",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6844833,
        "lng": 21.6691853
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.5640661,
        47.6157707,
        21.7714976,
        47.7330645
      ]
    },
    {
      "id": 26419,
      "name": "Rosario del Tala",
      "names": {
        "ja": "ロザリオデルタラ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.3008178,
        "lng": -59.138887
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.2007823,
        -32.3539136,
        -59.0738687,
        -32.2456586
      ]
    },
    {
      "id": 26430,
      "name": "Pichilemu",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -34.3851693,
        "lng": -72.0046606
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -72.051757,
        -34.5761425,
        -71.7466072,
        -34.1625064
      ]
    },
    {
      "id": 26436,
      "name": "Tenango del Aire",
      "names": {
        "it": "Tenano del Aire",
        "id": "Tauno del aire"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1571674,
        "lng": -98.8606929
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8806929,
        19.1371674,
        -98.8406929,
        19.1771674
      ]
    },
    {
      "id": 26439,
      "name": "Sankt Veit an der Glan",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 46.766821,
        "lng": 14.361483
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.338978,
        46.745516,
        14.388227,
        46.78009
      ]
    },
    {
      "id": 26478,
      "name": "Tepetzintla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.97,
        "lng": -97.840278
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.880278,
        19.93,
        -97.800278,
        20.01
      ]
    },
    {
      "id": 26494,
      "name": "San Salvador",
      "names": {
        "zh": "圣萨尔瓦多",
        "ja": "サンサルバドール",
        "th": "ซานซัลวาดอร์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -0.26019000000000003,
        "lng": -90.72254665048543
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -90.87103,
        -0.37548,
        -90.54639,
        -0.1448715
      ]
    },
    {
      "id": 26523,
      "name": "Ocotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2257578,
        "lng": -93.1641238
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.2255362,
        17.1823415,
        -93.1231827,
        17.2665198
      ]
    },
    {
      "id": 26526,
      "name": "Los Santos",
      "names": {
        "zh": "洛杉矶桑托斯",
        "ja": "ロスサントス",
        "th": "ลอสซานโตส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.7559026,
        "lng": -73.1018206
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.1418206,
        6.7159026,
        -73.0618206,
        6.7959026
      ]
    },
    {
      "id": 26528,
      "name": "Derventa",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.97839,
        "lng": 17.90779
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.88779,
        44.95839,
        17.92779,
        44.99839
      ]
    },
    {
      "id": 26533,
      "name": "Chok Chai",
      "names": {
        "th": "โชคชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.731245,
        "lng": 102.1680339
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1280339,
        14.691245,
        102.2080339,
        14.771245
      ]
    },
    {
      "id": 26546,
      "name": "Leibnitz",
      "names": {
        "zh": "莱布尼茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.7803138,
        "lng": 15.547076
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.547026,
        46.7802638,
        15.547126,
        46.7803638
      ]
    },
    {
      "id": 26561,
      "name": "Kamianka-Dniprovska",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.478164,
        "lng": 34.414834
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.394834,
        47.458164,
        34.434834,
        47.498164
      ]
    },
    {
      "id": 26562,
      "name": "Hattem",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4773176,
        "lng": 6.0560015
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.0007885,
        52.4437656,
        6.103546,
        52.5096062
      ]
    },
    {
      "id": 26563,
      "name": "Pionerskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.95083,
        "lng": 20.22748
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        20.20748,
        54.93083,
        20.24748,
        54.97083
      ]
    },
    {
      "id": 26574,
      "name": "Kantang",
      "names": {
        "th": "กันตัง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.40512,
        "lng": 99.517413
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.488619,
        7.347973,
        99.539701,
        7.431435
      ]
    },
    {
      "id": 26595,
      "name": "Emiliano Zapata",
      "names": {
        "ja": "Emilianoザパタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6636226,
        "lng": -98.57762013339831
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.6727164,
        19.5977581,
        -98.5245874,
        19.729483
      ]
    },
    {
      "id": 26607,
      "name": "Santa Barbara de Pinto",
      "names": {
        "es": "Santa Bárbara de Pinto",
        "zh": "圣巴巴拉德皮托",
        "ja": "サンタバーバラデピント",
        "th": "ซานต้าบาร์บาร่าเดอปินโต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.4316572,
        "lng": -74.70475
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8088137,
        9.3840833,
        -74.5264351,
        9.6680855
      ]
    },
    {
      "id": 26611,
      "name": "Kalajoki",
      "names": {
        "zh": "卡拉约基"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.260017,
        "lng": 23.950545
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.7788929,
        63.9649767,
        24.3465511,
        64.4837507
      ]
    },
    {
      "id": 26614,
      "name": "Alanya",
      "names": {
        "zh": "阿兰亚",
        "ja": "アラニヤ",
        "th": "อาลานยา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.5436517,
        "lng": 32.0019211
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.6461579,
        36.31382,
        32.5312041,
        36.7836273
      ]
    },
    {
      "id": 26616,
      "name": "Junin de los Andes",
      "names": {
        "id": "Junin de los and"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -39.95043,
        "lng": -71.06936
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -71.08936,
        -39.97043,
        -71.04936,
        -39.93043
      ]
    },
    {
      "id": 26621,
      "name": "Niksar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.5913282,
        "lng": 36.9434927
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.6720572,
        40.3894429,
        37.24789,
        40.7442557
      ]
    },
    {
      "id": 26636,
      "name": "Rong Kwang",
      "names": {
        "zh": "荣思",
        "th": "ร่องกวาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.3400378,
        "lng": 100.3176543
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.2776543,
        18.3000378,
        100.3576543,
        18.3800378
      ]
    },
    {
      "id": 26646,
      "name": "Tiszavasvari",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.956619,
        "lng": 21.3680532
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.236163,
        47.9048785,
        21.5154308,
        48.0034992
      ]
    },
    {
      "id": 26655,
      "name": "Gorazde",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 43.66795,
        "lng": 18.97564
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.95564,
        43.64795,
        18.99564,
        43.68795
      ]
    },
    {
      "id": 26671,
      "name": "Santa Ana Maya",
      "names": {
        "zh": "圣安娜玛雅",
        "ja": "サンタアナマヤ",
        "th": "ซานตาอานามายา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0079446,
        "lng": -101.0217017
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.0417017,
        19.9879446,
        -101.0017017,
        20.0279446
      ]
    },
    {
      "id": 26674,
      "name": "Lienz",
      "names": {
        "zh": "利恩茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.8298662,
        "lng": 12.7681269
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        12.6953694,
        46.8139875,
        12.8086157,
        46.8451726
      ]
    },
    {
      "id": 26676,
      "name": "Mecatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.20718815,
        "lng": -97.66248509652596
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.698891,
        20.1739294,
        -97.5999288,
        20.2407377
      ]
    },
    {
      "id": 26677,
      "name": "Pyrzyce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1461083,
        "lng": 14.8924485
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.8321158,
        53.101972,
        14.9539574,
        53.1818513
      ]
    },
    {
      "id": 26692,
      "name": "Tezoatlan de Segura y Luna",
      "names": {
        "it": "Tezoalanda de Segura y Luna",
        "ja": "Tezoatlan de Seguray Luna"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.65388,
        "lng": -97.81068
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.83068,
        17.63388,
        -97.79068,
        17.67388
      ]
    },
    {
      "id": 26699,
      "name": "Neerijnen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8312888,
        "lng": 5.2793902
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2453118,
        51.820665,
        5.3085089,
        51.8571837
      ]
    },
    {
      "id": 26701,
      "name": "Mira",
      "names": {
        "zh": "米拉",
        "ja": "ミラ",
        "th": "มิร่า"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4284618,
        "lng": -8.7372138
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8256767,
        40.3710038,
        -8.6819745,
        40.5203648
      ]
    },
    {
      "id": 26703,
      "name": "Revuca",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6833333,
        "lng": 20.1166667
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.885814,
        48.397615,
        20.4699481,
        48.806726
      ]
    },
    {
      "id": 26707,
      "name": "Mende",
      "names": {
        "zh": "门代"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5180226,
        "lng": 3.4991057
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.4287318,
        44.4938499,
        3.5534049,
        44.5758211
      ]
    },
    {
      "id": 26711,
      "name": "Sabugal",
      "names": {
        "zh": "萨布加尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3518321,
        "lng": -7.0896479
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.2496479,
        40.1918321,
        -6.9296479,
        40.5118321
      ]
    },
    {
      "id": 26718,
      "name": "Koriukivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.7744549,
        "lng": 32.2512927
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.2169744,
        51.7423824,
        32.3263409,
        51.7999959
      ]
    },
    {
      "id": 26731,
      "name": "La Carlota",
      "names": {
        "ja": "ラカルロタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.4179364,
        "lng": -63.2934951
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.3215133,
        -33.4448337,
        -63.2721479,
        -33.4050525
      ]
    },
    {
      "id": 26732,
      "name": "Urla",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3228184,
        "lng": 26.7672998
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.4390059,
        38.1031889,
        26.887536,
        38.4692145
      ]
    },
    {
      "id": 26741,
      "name": "Ebersberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0782763,
        "lng": 11.9694372
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.9194784,
        48.0479114,
        12.0463161,
        48.1243068
      ]
    },
    {
      "id": 26753,
      "name": "Kamin-Kashyrskyi",
      "names": {
        "zh": "卡明，kashyrskyi",
        "th": "ขมิ้น-kashyrskyi"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.62412,
        "lng": 24.95864
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.93864,
        51.60412,
        24.97864,
        51.64412
      ]
    },
    {
      "id": 26772,
      "name": "Alamos",
      "names": {
        "zh": "洛斯阿拉莫斯",
        "ja": "アラモス",
        "th": "อาลามอส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.027505,
        "lng": -108.940279
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -108.9544,
        27.00326,
        -108.9144,
        27.04326
      ]
    },
    {
      "id": 26774,
      "name": "Satao",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7426067,
        "lng": -7.7336741
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.7755097,
        40.6810741,
        -7.5707047,
        40.8747864
      ]
    },
    {
      "id": 26776,
      "name": "Dokkum",
      "names": {
        "zh": "多克姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3243515,
        "lng": 5.9992306
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9706687,
        53.3069273,
        6.0264988,
        53.3377358
      ]
    },
    {
      "id": 26790,
      "name": "Zunil",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.78333,
        "lng": -91.48333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.50333,
        14.76333,
        -91.46333,
        14.80333
      ]
    },
    {
      "id": 26820,
      "name": "Huai Yot",
      "names": {
        "zh": "淮差",
        "ja": "淮米",
        "th": "ห้วยยอด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.7848105,
        "lng": 99.6359172
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.5959172,
        7.7448105,
        99.6759172,
        7.8248105
      ]
    },
    {
      "id": 26823,
      "name": "Balchik",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.4259186,
        "lng": 28.1602967
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        28.090643,
        43.3805782,
        28.2517476,
        43.503989
      ]
    },
    {
      "id": 26825,
      "name": "Alapli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.180375,
        "lng": 31.3861776
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.2929831,
        41.0155633,
        31.7028394,
        41.2073336
      ]
    },
    {
      "id": 26827,
      "name": "Sever do Vouga",
      "names": {
        "es": "Sever do huouga",
        "fr": "See Sever Do Vouga",
        "zh": "切断做vouga",
        "de": "Trium do vouga",
        "ja": "ヴューガを留めてください"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7341453,
        "lng": -8.3702058
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4164694,
        40.6367633,
        -8.2719323,
        40.7999225
      ]
    },
    {
      "id": 26830,
      "name": "Rezvanshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.54976,
        "lng": 49.13703
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.11703,
        37.52976,
        49.15703,
        37.56976
      ]
    },
    {
      "id": 26838,
      "name": "Kalymnos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 36.9515787,
        "lng": 26.9820732
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        26.9420732,
        36.9115787,
        27.0220732,
        36.9915787
      ]
    },
    {
      "id": 26859,
      "name": "Nagykata",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4138187,
        "lng": 19.7503736
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.6928868,
        47.3562591,
        19.854984,
        47.4811707
      ]
    },
    {
      "id": 26864,
      "name": "Saboya",
      "names": {
        "zh": "萨沃亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.704371,
        "lng": -73.74133899242278
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.8852321,
        5.6277065,
        -73.6523696,
        5.7810662
      ]
    },
    {
      "id": 26867,
      "name": "Pfaffikon",
      "names": {
        "es": "Pfäffikon",
        "fr": "Pfäffikon",
        "zh": "晋费菲孔",
        "de": "Pfäffikon",
        "it": "Pfäffikon",
        "id": "Pfäffikon"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.365582,
        "lng": 8.7819841
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.7514435,
        47.337138,
        8.8416728,
        47.4021892
      ]
    },
    {
      "id": 26880,
      "name": "Cuichapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.771667,
        "lng": -96.869167
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.909167,
        18.731667,
        -96.829167,
        18.811667
      ]
    },
    {
      "id": 26896,
      "name": "Vobkent Shahri",
      "names": {
        "de": "Voknent shahri"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.0211793,
        "lng": 64.5179242
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        64.5004196,
        40.0140791,
        64.5435069,
        40.0408487
      ]
    },
    {
      "id": 26928,
      "name": "Mixtla de Altamirano",
      "names": {
        "es": "MIXTA DE ALTAMIRANO",
        "ja": "アルタミラノのミックス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.595327,
        "lng": -96.993298
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9960374253,
        18.5925467065,
        -96.9908549373,
        18.5977984267
      ]
    },
    {
      "id": 26929,
      "name": "Chateaubriant",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.718818,
        "lng": -1.377651
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.449545,
        47.691773,
        -1.3328,
        47.748167
      ]
    },
    {
      "id": 26943,
      "name": "Hatillo de Loba",
      "names": {
        "ja": "鳩尾デロッボ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9566235,
        "lng": -74.0770262
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4220073,
        8.9395238,
        -73.9775425,
        9.0448436
      ]
    },
    {
      "id": 26953,
      "name": "Foca",
      "names": {
        "zh": "福查",
        "de": "BAZL"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.5058554,
        "lng": 18.774393
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.7661373,
        43.4893113,
        18.8062441,
        43.5416775
      ]
    },
    {
      "id": 26999,
      "name": "Haskoy",
      "names": {
        "zh": "哈斯柯伊"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.73402,
        "lng": 40.540821
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.520821,
        37.71402,
        40.560821,
        37.75402
      ]
    },
    {
      "id": 27016,
      "name": "Zupanja",
      "names": {
        "zh": "茹帕尼亚"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0723677,
        "lng": 18.694355
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.6679065,
        45.0378178,
        18.7938034,
        45.1275567
      ]
    },
    {
      "id": 27017,
      "name": "Pargas",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.29972,
        "lng": 22.3004
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.2804,
        60.27972,
        22.3204,
        60.31972
      ]
    },
    {
      "id": 27018,
      "name": "Weiz",
      "names": {
        "zh": "魏茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2195031,
        "lng": 15.6267882
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.6267382,
        47.2194531,
        15.6268382,
        47.2195531
      ]
    },
    {
      "id": 27023,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.3344713,
        "lng": -75.6666238
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.5152362,
        7.357218,
        -74.7851374,
        9.4478271
      ]
    },
    {
      "id": 27026,
      "name": "Grootegast",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2115071,
        "lng": 6.2744862
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.2561812,
        53.1813309,
        6.3048536,
        53.225956
      ]
    },
    {
      "id": 27027,
      "name": "Weinfelden",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5669187,
        "lng": 9.1097539
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.0699102,
        47.548702,
        9.1459874,
        47.5878123
      ]
    },
    {
      "id": 27054,
      "name": "Citlaltepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.327778,
        "lng": -97.878889
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.918889,
        21.287778,
        -97.838889,
        21.367778
      ]
    },
    {
      "id": 27056,
      "name": "Emet",
      "names": {
        "zh": "太网"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.341462,
        "lng": 29.2585932
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.0369889,
        39.1835203,
        29.6365992,
        39.5017293
      ]
    },
    {
      "id": 27068,
      "name": "Na Wa",
      "names": {
        "ja": "ワワー",
        "th": "นาวา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4836157,
        "lng": 104.0973501
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.0573501,
        17.4436157,
        104.1373501,
        17.5236157
      ]
    },
    {
      "id": 27080,
      "name": "Sapna",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.4916604,
        "lng": 19.0027278
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.9627278,
        44.4516604,
        19.0427278,
        44.5316604
      ]
    },
    {
      "id": 27084,
      "name": "Kirkagac",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.10638,
        "lng": 27.66925
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.64925,
        39.08638,
        27.68925,
        39.12638
      ]
    },
    {
      "id": 27089,
      "name": "Ebejico",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.32598,
        "lng": -75.76835
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.78835,
        6.30598,
        -75.74835,
        6.34598
      ]
    },
    {
      "id": 27090,
      "name": "Mitontic",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8632222,
        "lng": -92.629206
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.649206,
        16.8432222,
        -92.609206,
        16.8832222
      ]
    },
    {
      "id": 27096,
      "name": "Chateau-Gontier",
      "names": {
        "zh": "城堡-gontier",
        "ja": "シャトー-gontier",
        "th": "ชาโตว์-gontier"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.8269594,
        "lng": -0.7047085
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.7047585,
        47.8269094,
        -0.7046585,
        47.8270094
      ]
    },
    {
      "id": 27098,
      "name": "Ahram",
      "names": {
        "zh": "金字塔"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 28.8826,
        "lng": 51.2746
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        51.2546,
        28.8626,
        51.2946,
        28.9026
      ]
    },
    {
      "id": 27104,
      "name": "Chanal",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.6547454,
        "lng": -92.2575164
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.2975164,
        16.6147454,
        -92.2175164,
        16.6947454
      ]
    },
    {
      "id": 27135,
      "name": "Appingedam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3203563,
        "lng": 6.8577654
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.803338,
        53.290126,
        6.8881608,
        53.3473504
      ]
    },
    {
      "id": 27149,
      "name": "Kozloduy",
      "names": {
        "zh": "科兹洛杜伊"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7750934,
        "lng": 23.7293313
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        23.6893313,
        43.7350934,
        23.7693313,
        43.8150934
      ]
    },
    {
      "id": 27150,
      "name": "Arganil",
      "names": {
        "zh": "阿加尼尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.219345,
        "lng": -8.05545
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.069587,
        40.213142,
        -8.041961,
        40.23082
      ]
    },
    {
      "id": 27153,
      "name": "Ilmajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.731342,
        "lng": 22.5797719
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.150146,
        62.597123,
        22.877556,
        62.8759
      ]
    },
    {
      "id": 27171,
      "name": "Liperi",
      "names": {
        "zh": "利佩里"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.531111,
        "lng": 29.385708
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.348867,
        62.51871,
        29.411888,
        62.539611
      ]
    },
    {
      "id": 27174,
      "name": "Ortakoy",
      "names": {
        "de": "Ortaköy"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.657322,
        "lng": 35.578682
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.558682,
        40.637322,
        35.598682,
        40.677322
      ]
    },
    {
      "id": 27188,
      "name": "Beryslav",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.8357749,
        "lng": 33.416743
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.3915785,
        46.8229452,
        33.4618074,
        46.8556149
      ]
    },
    {
      "id": 27190,
      "name": "Nova Gradiska",
      "names": {
        "zh": "Nova Gradista",
        "ja": "ノヴァグラディスカ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2533402,
        "lng": 17.3792875
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        17.3742875,
        45.2483402,
        17.3842875,
        45.2583402
      ]
    },
    {
      "id": 27194,
      "name": "Eura",
      "names": {
        "zh": "埃乌拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1304007,
        "lng": 22.1302126
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.8441028,
        60.8720007,
        22.2636223,
        61.308791
      ]
    },
    {
      "id": 27216,
      "name": "Tabua",
      "names": {
        "fr": "Tábua",
        "pt": "Tábua"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3602946,
        "lng": -8.0284641
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1340003,
        40.2407222,
        -7.8989748,
        40.4239565
      ]
    },
    {
      "id": 27219,
      "name": "Biga",
      "names": {
        "zh": "比加"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.2269793,
        "lng": 27.2428365
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.9011281,
        40.0804996,
        27.5157635,
        40.4750178
      ]
    },
    {
      "id": 27228,
      "name": "Ponte da Barca",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.80600765,
        "lng": -8.415000093962036
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4242236,
        41.8005892,
        -8.4068854,
        41.810612
      ]
    },
    {
      "id": 27229,
      "name": "Curiti",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.60519,
        "lng": -73.06809
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.08809,
        6.58519,
        -73.04809,
        6.62519
      ]
    },
    {
      "id": 27235,
      "name": "Perleberg",
      "names": {
        "zh": "佩勒贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.0762716,
        "lng": 11.8627933
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.7932274,
        53.0059242,
        11.9012449,
        53.104248
      ]
    },
    {
      "id": 27238,
      "name": "Bicske",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4907066,
        "lng": 18.6358046
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.4851646,
        47.4419435,
        18.6960148,
        47.569489
      ]
    },
    {
      "id": 27252,
      "name": "Caimito",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.790591,
        "lng": -75.1163176
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1563176,
        8.750591,
        -75.0763176,
        8.830591
      ]
    },
    {
      "id": 27253,
      "name": "Burhaniye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.503059,
        "lng": 26.9807755
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.852631,
        39.3158317,
        27.2191063,
        39.5609931
      ]
    },
    {
      "id": 27254,
      "name": "San Carlos Yautepec",
      "names": {
        "zh": "圣卡洛斯yautepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4981,
        "lng": -96.106851
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.11237,
        16.491266,
        -96.100196,
        16.505269
      ]
    },
    {
      "id": 27255,
      "name": "Oiba",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.26388,
        "lng": -73.29876
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.31876,
        6.24388,
        -73.27876,
        6.28388
      ]
    },
    {
      "id": 27269,
      "name": "Laren",
      "names": {
        "zh": "拉伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.24253135,
        "lng": 5.216243996233833
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.1888529,
        52.2121287,
        5.2450932,
        52.2729089
      ]
    },
    {
      "id": 27282,
      "name": "Ried im Innkreis",
      "names": {
        "zh": "Ried iminkreis",
        "ja": "Im Innkreisがリードしました",
        "th": "ried imnkreis"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2100666,
        "lng": 13.4882488
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.4881988,
        48.2100166,
        13.4882988,
        48.2101166
      ]
    },
    {
      "id": 27288,
      "name": "Tiszakecske",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.935152,
        "lng": 20.09721
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.071463,
        46.926234,
        20.129022,
        46.951842
      ]
    },
    {
      "id": 27292,
      "name": "Wang Saphung",
      "names": {
        "es": "Wang Safung",
        "zh": "王山",
        "ja": "王サプン",
        "th": "วังสะพุง",
        "pt": "Wang Sapung"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.299909,
        "lng": 101.7679902
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.7279902,
        17.259909,
        101.8079902,
        17.339909
      ]
    },
    {
      "id": 27294,
      "name": "San Antonio Palopo",
      "names": {
        "zh": "圣安东尼奥帕洛普",
        "ja": "サンアントニオパロポ",
        "th": "ซานอันโตนิโอปาโปโล"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7,
        "lng": -91.11667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.13667,
        14.68,
        -91.09667,
        14.72
      ]
    },
    {
      "id": 27308,
      "name": "Don Sak",
      "names": {
        "zh": "唐萨克",
        "id": "Don",
        "ja": "ドンサク",
        "th": "ดอนสัก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.322291,
        "lng": 99.696769
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.667102,
        9.304525,
        99.715526,
        9.347006
      ]
    },
    {
      "id": 27309,
      "name": "Opmeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7031902,
        "lng": 4.9478361
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8986695,
        52.6717655,
        5.0111636,
        52.7552963
      ]
    },
    {
      "id": 27310,
      "name": "Alavus",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.5862041,
        "lng": 23.6185286
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.1612392,
        62.3677449,
        23.9994959,
        62.8089453
      ]
    },
    {
      "id": 27322,
      "name": "Tlacolulan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.666667,
        "lng": -97.001667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.041667,
        19.626667,
        -96.961667,
        19.706667
      ]
    },
    {
      "id": 27329,
      "name": "Huite",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9271343,
        "lng": -89.7153093
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.7553093,
        14.8871343,
        -89.6753093,
        14.9671343
      ]
    },
    {
      "id": 27331,
      "name": "Thiers",
      "names": {
        "zh": "梯也尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.855795,
        "lng": 3.5489707
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.477137,
        45.8304459,
        3.5989612,
        45.9001771
      ]
    },
    {
      "id": 27337,
      "name": "Bad Fallingbostel",
      "names": {
        "es": "Mal caída de bostales",
        "fr": "Mauvais cheachbostel",
        "zh": "糟糕的下降堡",
        "id": "Fallingbostel yang buruk",
        "ja": "悪いFallingbostel"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8654484,
        "lng": 9.6930195
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.6449238,
        52.8384977,
        9.860964,
        52.9572972
      ]
    },
    {
      "id": 27340,
      "name": "Villefranche-de-Rouergue",
      "names": {
        "zh": "自由城去rouergue",
        "ja": "villefranche・ド・rouergue",
        "th": "villefranche-เดอ rouergue"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.3517623,
        "lng": 2.0375431
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.9757047,
        44.3048001,
        2.0835903,
        44.3898318
      ]
    },
    {
      "id": 27348,
      "name": "Altstatten",
      "names": {
        "fr": "Altstätten",
        "de": "Altstätten",
        "it": "Altstätten"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3782189,
        "lng": 9.5413964
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.4839539,
        47.2689297,
        9.593201,
        47.4002683
      ]
    },
    {
      "id": 27350,
      "name": "Saint-Gaudens",
      "names": {
        "zh": "圣 -  gaudens",
        "ja": "サン・gaudens",
        "th": "เซนต์-gaudens"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.10882135,
        "lng": 0.7225558691452726
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.7222379,
        43.1086714,
        0.7228737,
        43.1089698
      ]
    },
    {
      "id": 27355,
      "name": "Nong Bua",
      "names": {
        "it": "Bua di Nong",
        "th": "หนองบัว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8660082,
        "lng": 100.58269
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.54269,
        15.8260082,
        100.62269,
        15.9060082
      ]
    },
    {
      "id": 27356,
      "name": "Torbali",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1519,
        "lng": 27.36223
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.34223,
        38.1319,
        27.38223,
        38.1719
      ]
    },
    {
      "id": 27357,
      "name": "Koszeg",
      "names": {
        "th": "โคสเซ็ก"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3817353,
        "lng": 16.5568922
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        16.5518922,
        47.3767353,
        16.5618922,
        47.3867353
      ]
    },
    {
      "id": 27369,
      "name": "Bilovodsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2068078,
        "lng": 39.5911619
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        39.52366,
        49.16708,
        39.61876,
        49.27969
      ]
    },
    {
      "id": 27373,
      "name": "Ghabaghib",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.18235,
        "lng": 36.22534
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.20534,
        33.16235,
        36.24534,
        33.20235
      ]
    },
    {
      "id": 27378,
      "name": "Zlate Moravce",
      "names": {
        "de": "Zlatte Moravce"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3833333,
        "lng": 18.4
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.3528161,
        48.3465703,
        18.4173184,
        48.4119363
      ]
    },
    {
      "id": 27379,
      "name": "Issoudun",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9477826,
        "lng": 1.9889131
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.9308922,
        46.9086615,
        2.0842602,
        46.9808951
      ]
    },
    {
      "id": 27380,
      "name": "Nyirbator",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.839511,
        "lng": 22.1322309
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        22.0542458,
        47.7702047,
        22.2515493,
        47.88229
      ]
    },
    {
      "id": 27381,
      "name": "Doesburg",
      "names": {
        "zh": "杜斯堡"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0139605,
        "lng": 6.1393699
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.1095906,
        51.9955661,
        6.1772991,
        52.0421128
      ]
    },
    {
      "id": 27384,
      "name": "Volosovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.4453384,
        "lng": 29.4838591
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        29.3859978,
        59.4285388,
        29.5429249,
        59.4721622
      ]
    },
    {
      "id": 27391,
      "name": "Blaricum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2648035,
        "lng": 5.2073544
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2073044,
        52.2647535,
        5.2074044,
        52.2648535
      ]
    },
    {
      "id": 27392,
      "name": "Sarbogard",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8874371,
        "lng": 18.6196247
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.4607028,
        46.7964966,
        18.721089,
        46.9473536
      ]
    },
    {
      "id": 27395,
      "name": "Hildburghausen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.4266327,
        "lng": 10.7300263
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.6404017,
        50.3942385,
        10.830477,
        50.4794483
      ]
    },
    {
      "id": 27413,
      "name": "Alijo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2768619,
        "lng": -7.4753795
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.6036855,
        41.1796724,
        -7.3621269,
        41.4036053
      ]
    },
    {
      "id": 27421,
      "name": "Mykhailivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.2657187,
        "lng": 35.2217134
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.1872324,
        47.2300904,
        35.2764026,
        47.3114433
      ]
    },
    {
      "id": 27423,
      "name": "Janow Lubelski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7065895,
        "lng": 22.4110663
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        22.3686437,
        50.6770236,
        22.458658,
        50.7243739
      ]
    },
    {
      "id": 27453,
      "name": "Sogndal",
      "names": {
        "zh": "松达尔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.2295279,
        "lng": 7.1045036
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.1044536,
        61.2294779,
        7.1045536,
        61.2295779
      ]
    },
    {
      "id": 27463,
      "name": "Myjava",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7540345,
        "lng": 17.5650224
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        17.5503038,
        48.7303505,
        17.6009706,
        48.777892
      ]
    },
    {
      "id": 27471,
      "name": "Nowe Miasto Lubawskie",
      "names": {
        "fr": "Now Miasto Lubawskie",
        "th": "ตอนนี้ Miasto Lubawskie"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4255933,
        "lng": 19.5936277
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        19.5491154,
        53.4063067,
        19.6186459,
        53.4442547
      ]
    },
    {
      "id": 27485,
      "name": "Clermont",
      "names": {
        "zh": "克莱蒙",
        "ja": "クレルモン",
        "th": "มอนต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.3780921,
        "lng": 2.4141907
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.3860026,
        49.3659645,
        2.4337668,
        49.3904646
      ]
    },
    {
      "id": 27496,
      "name": "Aheqi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9356047,
        "lng": 78.4351126
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        78.2751126,
        40.7756047,
        78.5951126,
        41.0956047
      ]
    },
    {
      "id": 27498,
      "name": "Landsmeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4296098,
        "lng": 4.9136088
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8930349,
        52.4116076,
        4.9508795,
        52.4904345
      ]
    },
    {
      "id": 27501,
      "name": "Miesbach",
      "names": {
        "zh": "米斯巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.789542,
        "lng": 11.83299
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.774974,
        47.756662,
        11.904772,
        47.824806
      ]
    },
    {
      "id": 27510,
      "name": "Veurne",
      "names": {
        "fr": "Furnes",
        "zh": "弗尔讷"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0724273,
        "lng": 2.6621323
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        2.5658779,
        50.985329,
        2.7768734,
        51.0977718
      ]
    },
    {
      "id": 27516,
      "name": "Villa Aldama",
      "names": {
        "zh": "别墅阿尔多玛",
        "ja": "ヴィラアルダマ",
        "th": "วิลล่าอัลแดมา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6489805,
        "lng": -97.2228382
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.2628382,
        19.6089805,
        -97.1828382,
        19.6889805
      ]
    },
    {
      "id": 27529,
      "name": "Eijsden",
      "names": {
        "zh": "埃斯登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.778294,
        "lng": 5.708602
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.681757,
        50.754765,
        5.750415,
        50.808564
      ]
    },
    {
      "id": 27530,
      "name": "Vendas Novas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6773855,
        "lng": -8.4552427
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6584679,
        38.5421163,
        -8.3782396,
        38.7546045
      ]
    },
    {
      "id": 27533,
      "name": "Silifke",
      "names": {
        "zh": "锡利夫凯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.3778166,
        "lng": 33.9260372
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.539145,
        36.1338326,
        34.1417068,
        37.0377082
      ]
    },
    {
      "id": 27539,
      "name": "Ojuelos de Jalisco",
      "names": {
        "ja": "オジュルスデ・イラスコ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.8652914,
        "lng": -101.593513
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.633513,
        21.8252914,
        -101.553513,
        21.9052914
      ]
    },
    {
      "id": 27555,
      "name": "El Palmar",
      "names": {
        "ja": "エルパルマー"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.69381085,
        "lng": -91.60183056885774
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.6824416,
        14.6064964,
        -91.5335005,
        14.7811943
      ]
    },
    {
      "id": 27559,
      "name": "Zaragoza",
      "names": {
        "fr": "Saragosse",
        "zh": "萨拉戈萨",
        "it": "Saragozza",
        "ja": "サラゴサ",
        "th": "ซาราโกซา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7462268,
        "lng": -97.57330270171778
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.5934172,
        19.7005249,
        -97.5417117,
        19.7920699
      ]
    },
    {
      "id": 27568,
      "name": "Abasolo",
      "names": {
        "zh": "阿瓦索洛"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.058055,
        "lng": -98.372002
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.399972,
        24.046689,
        -98.355463,
        24.068862
      ]
    },
    {
      "id": 27569,
      "name": "Ovidiopol",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.25747245,
        "lng": 30.44249198701189
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.3526916,
        46.1939306,
        30.5407749,
        46.3210784
      ]
    },
    {
      "id": 27584,
      "name": "Kuchinarai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.535627,
        "lng": 104.051964
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.935029,
        16.48236,
        104.08835,
        16.558368
      ]
    },
    {
      "id": 27597,
      "name": "Malargue",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.4752134,
        "lng": -69.585934
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -69.6167312,
        -35.5245598,
        -69.5495129,
        -35.4468036
      ]
    },
    {
      "id": 27603,
      "name": "Tolcayuca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.956541,
        "lng": -98.917509
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.932875,
        19.948136,
        -98.905495,
        19.968563
      ]
    },
    {
      "id": 27619,
      "name": "Opatija",
      "names": {
        "zh": "欧帕提亚",
        "ja": "オパティア",
        "th": "ออปาติจา"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3349238,
        "lng": 14.3067834
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        14.1816461,
        45.2739227,
        14.337602,
        45.3926444
      ]
    },
    {
      "id": 27631,
      "name": "Haradok",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.4614306,
        "lng": 29.9872766
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.9483189,
        55.44087,
        30.0254089,
        55.4846051
      ]
    },
    {
      "id": 27637,
      "name": "Santa Lucia",
      "names": {
        "es": "Santa Lucía",
        "zh": "圣卢西亚",
        "ja": "サンタルシア",
        "th": "ซานตาลูเซีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 13.8250489,
        "lng": -60.975036
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -61.2855618,
        13.5060753,
        -60.6640451,
        14.2725
      ]
    },
    {
      "id": 27642,
      "name": "Obidos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3649032,
        "lng": -9.1590567
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.1591067,
        39.3648532,
        -9.1590067,
        39.3649532
      ]
    },
    {
      "id": 27646,
      "name": "Cisneros",
      "names": {
        "zh": "锡斯内罗斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5382418,
        "lng": -75.0871316
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1271316,
        6.4982418,
        -75.0471316,
        6.5782418
      ]
    },
    {
      "id": 27649,
      "name": "Labin",
      "names": {
        "zh": "拉宾"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0913954,
        "lng": 14.1236014
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        14.0671543,
        45.0081116,
        14.1984761,
        45.133507
      ]
    },
    {
      "id": 27653,
      "name": "Cafayate",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -26.0729166,
        "lng": -65.9760315
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.9980926,
        -26.0815746,
        -65.9707457,
        -26.0631251
      ]
    },
    {
      "id": 27659,
      "name": "Phon",
      "names": {
        "zh": "响度",
        "ja": "ホン",
        "th": "พล",
        "pt": "Telefone"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8137152,
        "lng": 102.6007983
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.5607983,
        15.7737152,
        102.6407983,
        15.8537152
      ]
    },
    {
      "id": 27673,
      "name": "Kankaanpaa",
      "names": {
        "zh": "坎康佩"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8027147,
        "lng": 22.3965338
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.974675,
        61.6860698,
        22.6951393,
        62.142448
      ]
    },
    {
      "id": 27677,
      "name": "Jarabulus",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.8175,
        "lng": 38.01111
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        37.99111,
        36.7975,
        38.03111,
        36.8375
      ]
    },
    {
      "id": 27678,
      "name": "Lom Sak",
      "names": {
        "ja": "ロムサク",
        "th": "หล่มสัก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.7770781,
        "lng": 101.2431565
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.2031565,
        16.7370781,
        101.2831565,
        16.8170781
      ]
    },
    {
      "id": 27681,
      "name": "Ganserndorf",
      "names": {
        "de": "Gänserndorf"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3403867,
        "lng": 16.7187051
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.6565089,
        48.2800733,
        16.7534628,
        48.376744
      ]
    },
    {
      "id": 27683,
      "name": "Lieksa",
      "names": {
        "zh": "列克萨"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.3182425,
        "lng": 30.0162734
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        30.0112734,
        63.3132425,
        30.0212734,
        63.3232425
      ]
    },
    {
      "id": 27686,
      "name": "Besikduzu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0527305,
        "lng": 39.2280377
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.1396099,
        40.9513392,
        39.2508607,
        41.0749218
      ]
    },
    {
      "id": 27700,
      "name": "Randaberg",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.9994464,
        "lng": 5.6165674
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.5765674,
        58.9594464,
        5.6565674,
        59.0394464
      ]
    },
    {
      "id": 27701,
      "name": "Hollabrunn",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.5625384,
        "lng": 16.0798033
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.9614526,
        48.4887481,
        16.3042734,
        48.6089143
      ]
    },
    {
      "id": 27704,
      "name": "Gora",
      "names": {
        "zh": "戈拉",
        "ja": "強羅"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6681499,
        "lng": 16.5393277
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        16.5075776,
        51.650263,
        16.5936692,
        51.6901878
      ]
    },
    {
      "id": 27708,
      "name": "Puente Nacional",
      "names": {
        "ja": "プエンテネシャル"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8770962,
        "lng": -73.6791063
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.7754508,
        5.7120032,
        -73.5999074,
        5.9294214
      ]
    },
    {
      "id": 27721,
      "name": "Bajestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.51639,
        "lng": 58.1845
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        58.1645,
        34.49639,
        58.2045,
        34.53639
      ]
    },
    {
      "id": 27724,
      "name": "Buldan",
      "names": {
        "zh": "布尔丹"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.045,
        "lng": 28.83056
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.81056,
        38.025,
        28.85056,
        38.065
      ]
    },
    {
      "id": 27736,
      "name": "Nemyriv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.976602,
        "lng": 28.843937
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.823937,
        48.956602,
        28.863937,
        48.996602
      ]
    },
    {
      "id": 27745,
      "name": "Deutschlandsberg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 46.8146272,
        "lng": 15.2137799
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.007921,
        46.7930958,
        15.2664459,
        46.927924
      ]
    },
    {
      "id": 27747,
      "name": "Sint Anthonis",
      "names": {
        "de": "Sint-Anthonis",
        "ja": "シントアントニス"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.626921,
        "lng": 5.8816763
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.8024148,
        51.6031168,
        5.9299975,
        51.6444871
      ]
    },
    {
      "id": 27750,
      "name": "Leidschendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.087682,
        "lng": 4.395224
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.371467,
        52.072974,
        4.427903,
        52.11187
      ]
    },
    {
      "id": 27752,
      "name": "Saverne",
      "names": {
        "zh": "萨韦尔讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7419909,
        "lng": 7.3625953
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.2866267,
        48.7204266,
        7.4204224,
        48.7674137
      ]
    },
    {
      "id": 27760,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.4090481,
        "lng": -76.347379
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.4759061,
        3.2833336,
        -76.2821956,
        3.4713403
      ]
    },
    {
      "id": 27780,
      "name": "Prymorsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.7343149,
        "lng": 36.3472151
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.3243949,
        46.6777922,
        36.4087885,
        46.7502125
      ]
    },
    {
      "id": 27781,
      "name": "Santa Comba Dao",
      "names": {
        "zh": "圣诞老人组合道",
        "ja": "サンタコンボダ",
        "th": "ซานตาคอมบาแด"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.399988750000006,
        "lng": -8.131110454043977
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1464184,
        40.3871156,
        -8.1232231,
        40.4129248
      ]
    },
    {
      "id": 27787,
      "name": "Sereflikochisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.9389329,
        "lng": 33.5438843
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.1200349,
        38.6757357,
        33.8030433,
        39.2789755
      ]
    },
    {
      "id": 27793,
      "name": "Oliva",
      "names": {
        "zh": "奥利瓦",
        "ja": "オリバ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.0424889,
        "lng": -63.5670199
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.5851076,
        -32.0567658,
        -63.5505009,
        -32.028578
      ]
    },
    {
      "id": 27809,
      "name": "Hinwil",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3014144,
        "lng": 8.8429221
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.8428721,
        47.3013644,
        8.8429721,
        47.3014644
      ]
    },
    {
      "id": 27818,
      "name": "Loutraki",
      "names": {
        "id": "Loutráki",
        "pt": "Loutráki"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9743673,
        "lng": 22.9777228
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.9377228,
        37.9343673,
        23.0177228,
        38.0143673
      ]
    },
    {
      "id": 27821,
      "name": "Tlachichilco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.62055,
        "lng": -98.199467
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.203261,
        20.614848,
        -98.19478,
        20.624579
      ]
    },
    {
      "id": 27822,
      "name": "Lyuban'",
      "names": {
        "es": "Lyuban '",
        "fr": "Lyuban '",
        "de": "Lyuban '",
        "it": "Lyuban '",
        "id": "Lyuban '",
        "ja": "lyuban '",
        "th": "lyuban '",
        "pt": "Lyuban '"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.7959497,
        "lng": 27.9981015
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.9646052,
        52.7789824,
        28.0333979,
        52.8171991
      ]
    },
    {
      "id": 27823,
      "name": "Esquipulas Palo Gordo",
      "names": {
        "pt": "Esquípulas Palo Gordo"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.93333,
        "lng": -91.81667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.83667,
        14.91333,
        -91.79667,
        14.95333
      ]
    },
    {
      "id": 27850,
      "name": "Panajachel",
      "names": {
        "zh": "巴纳哈契"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7421699,
        "lng": -91.1559757
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.164062,
        14.7273091,
        -91.1143187,
        14.7674483
      ]
    },
    {
      "id": 27852,
      "name": "Drawsko Pomorskie",
      "names": {
        "fr": "Dessinko Pomorskie"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.535382,
        "lng": 15.810014
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.765311,
        53.502837,
        15.848355,
        53.564466
      ]
    },
    {
      "id": 27853,
      "name": "Mala Vyska",
      "names": {
        "ja": "マラ・ビスカ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.64308,
        "lng": 31.63657
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.61657,
        48.62308,
        31.65657,
        48.66308
      ]
    },
    {
      "id": 27855,
      "name": "Mistelbach",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.5694535,
        "lng": 16.5720327
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.4747074,
        48.513426,
        16.7016041,
        48.6562279
      ]
    },
    {
      "id": 27858,
      "name": "Svidnik",
      "names": {
        "zh": "斯维德尼克"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.30502525,
        "lng": 21.56970799995807
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.5695318,
        49.3047466,
        21.5703987,
        49.3053339
      ]
    },
    {
      "id": 27864,
      "name": "Kalmiuske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6687951,
        "lng": 38.0739208
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        38.0486028,
        47.6354187,
        38.0961083,
        47.6819485
      ]
    },
    {
      "id": 27882,
      "name": "Bytca",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2235748,
        "lng": 18.5586825
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.5016931,
        49.2094598,
        18.5745293,
        49.2643619
      ]
    },
    {
      "id": 27890,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9737744,
        "lng": -75.3613093
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4275839,
        5.8576303,
        -75.2697132,
        6.0453384
      ]
    },
    {
      "id": 27896,
      "name": "Den Chai",
      "names": {
        "zh": "书柴",
        "ja": "デンチャイ",
        "th": "เดนชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.98372,
        "lng": 100.05217
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.03217,
        17.96372,
        100.07217,
        18.00372
      ]
    },
    {
      "id": 27902,
      "name": "Ulricehamn",
      "names": {
        "zh": "乌尔里瑟港"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.791191,
        "lng": 13.418397
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        13.391832,
        57.776115,
        13.449455,
        57.812763
      ]
    },
    {
      "id": 27905,
      "name": "Shengping",
      "names": {
        "zh": "升平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.4877173,
        "lng": 98.9133928
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        98.8043987,
        28.3238028,
        99.0684902,
        28.8249555
      ]
    },
    {
      "id": 27914,
      "name": "Temamatla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2035698,
        "lng": -98.8697687
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.8897687,
        19.1835698,
        -98.8497687,
        19.2235698
      ]
    },
    {
      "id": 27915,
      "name": "Sursee",
      "names": {
        "zh": "苏尔塞"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1725301,
        "lng": 8.1067781
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.0824244,
        47.1629989,
        8.1297003,
        47.1932838
      ]
    },
    {
      "id": 27924,
      "name": "Padre Burgos",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0305246,
        "lng": 125.0169925
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        124.9769925,
        9.9905246,
        125.0569925,
        10.0705246
      ]
    },
    {
      "id": 27929,
      "name": "Cerrillos",
      "names": {
        "zh": "塞里约斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.903531649999998,
        "lng": -65.48724035306654
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.4876107,
        -24.904113,
        -65.4868761,
        -24.9029565
      ]
    },
    {
      "id": 27940,
      "name": "Epazoyucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0181268,
        "lng": -98.6364294
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.6764294,
        19.9781268,
        -98.5964294,
        20.0581268
      ]
    },
    {
      "id": 27959,
      "name": "Cayeli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0878328,
        "lng": 40.7236973
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.6416527,
        40.762658,
        40.8887726,
        41.1408453
      ]
    },
    {
      "id": 27964,
      "name": "Phanat Nikhom",
      "names": {
        "ja": "ファナットニックトン"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.4495893,
        "lng": 101.1808862
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.1408862,
        13.4095893,
        101.2208862,
        13.4895893
      ]
    },
    {
      "id": 27965,
      "name": "Satuek",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.29555,
        "lng": 103.29455
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.256681,
        15.248652,
        103.319247,
        15.319715
      ]
    },
    {
      "id": 27970,
      "name": "Salaya",
      "names": {
        "zh": "萨拉亚",
        "th": "ศาลายา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 24.8590606,
        "lng": 81.11683919164591
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        81.1075955,
        24.856235,
        81.1204499,
        24.8618847
      ]
    },
    {
      "id": 27982,
      "name": "Esil",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.9601511,
        "lng": 66.4088826
      },
      "country": "Kazakhstan",
      "importance": 2,
      "bbox": [
        66.3575681,
        51.9312838,
        66.4717266,
        51.9701032
      ]
    },
    {
      "id": 27994,
      "name": "Cholpon-Ata",
      "names": {
        "zh": "乔尔蓬-数据",
        "ja": "cholpon-のATA"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.64944,
        "lng": 77.08225
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        77.06225,
        42.62944,
        77.10225,
        42.66944
      ]
    },
    {
      "id": 28028,
      "name": "Tekkekoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2134984,
        "lng": 36.4578038
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.3423985,
        41.038176,
        36.5969879,
        41.2582872
      ]
    },
    {
      "id": 28029,
      "name": "Guayabal",
      "names": {
        "zh": "瓜亚瓦尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.0009931,
        "lng": -67.3972028
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -67.4321079,
        7.9659714,
        -67.3585296,
        8.020194
      ]
    },
    {
      "id": 28033,
      "name": "Myronivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.6583412,
        "lng": 30.9824887
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.956725,
        49.6374359,
        31.05507,
        49.6866373
      ]
    },
    {
      "id": 28036,
      "name": "Coronel Dorrego",
      "names": {
        "pt": "Coronel dorego"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.7187129,
        "lng": -61.288505
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.3069766,
        -38.7344776,
        -61.2672155,
        -38.706125
      ]
    },
    {
      "id": 28039,
      "name": "Zierikzee",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6497688,
        "lng": 3.9208365
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        3.7711649,
        51.5936707,
        3.9743852,
        51.6800219
      ]
    },
    {
      "id": 28042,
      "name": "Yakymivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.69636,
        "lng": 35.15522
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.1322238,
        46.6727414,
        35.1847436,
        46.7298603
      ]
    },
    {
      "id": 28045,
      "name": "Crikvenica",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.176098,
        "lng": 14.692797
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        14.679157,
        45.165717,
        14.712341,
        45.18698
      ]
    },
    {
      "id": 28049,
      "name": "Poldasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 39.348,
        "lng": 45.071
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.051,
        39.328,
        45.091,
        39.368
      ]
    },
    {
      "id": 28080,
      "name": "Klyetsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0635,
        "lng": 26.6321
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.6121,
        53.0435,
        26.6521,
        53.0835
      ]
    },
    {
      "id": 28082,
      "name": "Batonyterenye",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.989195,
        "lng": 19.8318398
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.8317898,
        47.989145,
        19.8318898,
        47.989245
      ]
    },
    {
      "id": 28090,
      "name": "Briancon",
      "names": {
        "fr": "Briançon",
        "it": "Briançon",
        "id": "Briançon"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.891207,
        "lng": 6.632279
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.600685,
        44.869191,
        6.706584,
        44.929654
      ]
    },
    {
      "id": 28103,
      "name": "Egersund",
      "names": {
        "zh": "埃格松"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.4515121,
        "lng": 6.0007554
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.9607554,
        58.4115121,
        6.0407554,
        58.4915121
      ]
    },
    {
      "id": 28110,
      "name": "Bad Schwalbach",
      "names": {
        "ja": "悪いシュワルバッハ",
        "pt": "Mau schwalbach"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.139542,
        "lng": 8.067463
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.96019,
        50.09455,
        8.104071,
        50.178612
      ]
    },
    {
      "id": 28121,
      "name": "Resende",
      "names": {
        "zh": "雷森迪"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.0749752,
        "lng": -7.950630855848868
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.0275705,
        40.9992979,
        -7.8503461,
        41.1505084
      ]
    },
    {
      "id": 28135,
      "name": "Kormend",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0126655,
        "lng": 16.6010475
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        16.5378098,
        46.9789403,
        16.6866817,
        47.0575163
      ]
    },
    {
      "id": 28139,
      "name": "Kavarna",
      "names": {
        "zh": "卡瓦尔纳"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.433635,
        "lng": 28.33881
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        28.324088,
        43.422931,
        28.357605,
        43.443875
      ]
    },
    {
      "id": 28149,
      "name": "Virton",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 49.5677263,
        "lng": 5.5329559
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.500803,
        49.5059677,
        5.644178,
        49.6394297
      ]
    },
    {
      "id": 28156,
      "name": "Jimani",
      "names": {
        "es": "Jimaní"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.49169,
        "lng": -71.85022
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -71.87022,
        18.47169,
        -71.83022,
        18.51169
      ]
    },
    {
      "id": 28179,
      "name": "Kastav",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3731673,
        "lng": 14.3487225
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        14.3245866,
        45.3530526,
        14.3728193,
        45.4009459
      ]
    },
    {
      "id": 28186,
      "name": "Covenas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.4017581,
        "lng": -75.6787017
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7187017,
        9.3617581,
        -75.6387017,
        9.4417581
      ]
    },
    {
      "id": 28205,
      "name": "Sarospatak",
      "names": {
        "zh": "沙罗什帕塔克"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.317893,
        "lng": 21.56639
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.550736,
        48.295271,
        21.599925,
        48.339329
      ]
    },
    {
      "id": 28221,
      "name": "Talaigua Nuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.3032374,
        "lng": -74.5654949
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7566989,
        9.190239,
        -74.5231641,
        9.4294986
      ]
    },
    {
      "id": 28227,
      "name": "Monte Quemado",
      "names": {
        "ja": "モンテクイマド"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -25.8034931,
        "lng": -62.8303622
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.8485986,
        -25.8278494,
        -62.806842,
        -25.7681535
      ]
    },
    {
      "id": 28236,
      "name": "Onoto",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.5985397,
        "lng": -65.1901845
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -65.2173516,
        9.5654659,
        -65.1531744,
        9.6323784
      ]
    },
    {
      "id": 28238,
      "name": "Gardony",
      "names": {
        "de": "Gárdony"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2028062,
        "lng": 18.6261978
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.5379784,
        47.1369094,
        18.6946956,
        47.2274159
      ]
    },
    {
      "id": 28241,
      "name": "Sankt Johann im Pongau",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.3488031,
        "lng": 13.202318
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.1392921,
        47.295616,
        13.2891778,
        47.3961301
      ]
    },
    {
      "id": 28248,
      "name": "Lang Suan",
      "names": {
        "zh": "郎苏"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.9492883,
        "lng": 99.0764176
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.0714176,
        9.9442883,
        99.0814176,
        9.9542883
      ]
    },
    {
      "id": 28249,
      "name": "Humahuaca",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -23.20669515,
        "lng": -65.3495664002638
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.3642491,
        -23.2244769,
        -65.3445875,
        -23.1881202
      ]
    },
    {
      "id": 28251,
      "name": "Yangirabot",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.0309665,
        "lng": 65.9695024
      },
      "country": "Uzbekistan",
      "importance": 2,
      "bbox": [
        65.9446701,
        40.0183615,
        65.9878274,
        40.0523877
      ]
    },
    {
      "id": 28264,
      "name": "Kiato",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.010326,
        "lng": 22.744354
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.724354,
        37.990326,
        22.764354,
        38.030326
      ]
    },
    {
      "id": 28267,
      "name": "Belen",
      "names": {
        "zh": "贝伦"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -1.45056,
        "lng": -48.4682453
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -48.6244082,
        -1.5265847,
        -48.2961242,
        -1.0194267
      ]
    },
    {
      "id": 28277,
      "name": "Tortoli",
      "names": {
        "zh": "托尔托利",
        "id": "Tortolì"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.9289586,
        "lng": 9.6584785
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.6534785,
        39.9239586,
        9.6634785,
        39.9339586
      ]
    },
    {
      "id": 28284,
      "name": "Bang Ban",
      "names": {
        "es": "Prohibición",
        "fr": "Interdiction de qn",
        "zh": "爆炸禁令",
        "de": "Knallverbot",
        "id": "Larangan",
        "ja": "バン禁止",
        "th": "บางห้าม",
        "pt": "Banir proibir"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4075172,
        "lng": 100.4686288
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.4156275,
        14.3894268,
        100.5076775,
        14.4658448
      ]
    },
    {
      "id": 28288,
      "name": "Bayindir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.297367,
        "lng": 30.733866
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.713866,
        40.277367,
        30.753866,
        40.317367
      ]
    },
    {
      "id": 28306,
      "name": "Sogne",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.0953248,
        "lng": 7.7893494
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.7693494,
        58.0753248,
        7.8093494,
        58.1153248
      ]
    },
    {
      "id": 28310,
      "name": "Susurluk",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.91361,
        "lng": 28.15778
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.13778,
        39.89361,
        28.17778,
        39.93361
      ]
    },
    {
      "id": 28320,
      "name": "Krosno Odrzanskie",
      "names": {
        "ja": "KrosnoOdrzanskie"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0536361,
        "lng": 15.0980258
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.0755853,
        52.013649,
        15.127393,
        52.0685035
      ]
    },
    {
      "id": 28323,
      "name": "Eisenberg",
      "names": {
        "zh": "艾森伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9691346,
        "lng": 11.8961116
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.8465744,
        50.9350181,
        11.9329253,
        50.9892562
      ]
    },
    {
      "id": 28340,
      "name": "Illintsi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1136358,
        "lng": 29.2024356
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.1644321,
        49.0862165,
        29.2462831,
        49.1313362
      ]
    },
    {
      "id": 28348,
      "name": "Kaset Wisai",
      "names": {
        "ja": "カセットウィダイ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.6563877,
        "lng": 103.5833399
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.5433399,
        15.6163877,
        103.6233399,
        15.6963877
      ]
    },
    {
      "id": 28365,
      "name": "Anamur",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.080323,
        "lng": 32.8312106
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.5375555,
        36.0167224,
        33.0391501,
        36.520351
      ]
    },
    {
      "id": 28367,
      "name": "Tosya",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0164957,
        "lng": 34.0386079
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.7675,
        40.8133361,
        34.373,
        41.1827
      ]
    },
    {
      "id": 28374,
      "name": "El Playon",
      "names": {
        "it": "Playon",
        "ja": "エルプラヤン"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.52300075,
        "lng": -73.19786585908766
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.3260867,
        7.4129401,
        -73.0449029,
        7.6330213
      ]
    },
    {
      "id": 28376,
      "name": "Marcali",
      "names": {
        "zh": "毛尔曹利"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.5833484,
        "lng": 17.4123002
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.3306151,
        46.5115784,
        17.5036838,
        46.6262896
      ]
    },
    {
      "id": 28382,
      "name": "Krizevci",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.0247391,
        "lng": 16.5457794
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.4019449,
        45.9470431,
        16.7247467,
        46.1805408
      ]
    },
    {
      "id": 28392,
      "name": "Joquicingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.050004,
        "lng": -99.533903
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.541978,
        19.041794,
        -99.527556,
        19.055761
      ]
    },
    {
      "id": 28393,
      "name": "Mineral de Angangueo",
      "names": {
        "fr": "Minéral de Angangueo",
        "zh": "矿物质矿园",
        "ja": "ミネラルデアンガニュオ",
        "th": "แร่ DE Angangueo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.618136,
        "lng": -100.286747
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.306889,
        19.60798,
        -100.2724,
        19.637871
      ]
    },
    {
      "id": 28409,
      "name": "Apt",
      "names": {
        "zh": "公寓",
        "id": "Tepat",
        "ja": "APTの",
        "th": "อพาร์ทเมนต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.8757936,
        "lng": 5.3970648
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.3063858,
        43.8417696,
        5.4565767,
        43.9209023
      ]
    },
    {
      "id": 28425,
      "name": "Sanjiaocheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.7420819,
        "lng": 102.8900729
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        102.8700729,
        36.7220819,
        102.9100729,
        36.7620819
      ]
    },
    {
      "id": 28429,
      "name": "Vire",
      "names": {
        "zh": "维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8385198,
        "lng": -0.8893293
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.9434657,
        48.8170363,
        -0.8309623,
        48.8866025
      ]
    },
    {
      "id": 28436,
      "name": "Morazan",
      "names": {
        "es": "Morazán",
        "zh": "莫拉桑",
        "pt": "Morazán"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9325007,
        "lng": -90.1432267
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.1832267,
        14.8925007,
        -90.1032267,
        14.9725007
      ]
    },
    {
      "id": 28445,
      "name": "Tyrnavos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.7388604,
        "lng": 22.2882216
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.1039625,
        39.6083787,
        22.4572581,
        39.9343343
      ]
    },
    {
      "id": 28454,
      "name": "San Jose de Aerocuar",
      "names": {
        "zh": "圣何塞德航空",
        "ja": "サンノゼ・デアロカー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.59973,
        "lng": -63.32852
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -63.34852,
        10.57973,
        -63.30852,
        10.61973
      ]
    },
    {
      "id": 28461,
      "name": "Sansare",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7457695,
        "lng": -90.1158718
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.1558718,
        14.7057695,
        -90.0758718,
        14.7857695
      ]
    },
    {
      "id": 28465,
      "name": "Qasr-e Shirin",
      "names": {
        "ja": "QASR-Eシリン"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.515594,
        "lng": 45.5800155
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        45.5673557,
        34.4921661,
        45.6114282,
        34.5285195
      ]
    },
    {
      "id": 28472,
      "name": "Santa Ana Huista",
      "names": {
        "zh": "圣安娜霍斯塔",
        "ja": "サンタアナハイスタ",
        "th": "ซานตาอานาฮูสตา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.68333,
        "lng": -91.81667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.83667,
        15.66333,
        -91.79667,
        15.70333
      ]
    },
    {
      "id": 28474,
      "name": "Staryya Darohi",
      "names": {
        "fr": "Staryya Darhi",
        "de": "Starysta Darohi",
        "ja": "ダローギスタリヤ"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0402,
        "lng": 28.267
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        28.247,
        53.0202,
        28.287,
        53.0602
      ]
    },
    {
      "id": 28484,
      "name": "Wegorzewo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.2138162,
        "lng": 21.7416569
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.7123129,
        54.183821,
        21.7855222,
        54.2321946
      ]
    },
    {
      "id": 28507,
      "name": "Si Wilai",
      "names": {
        "th": "ศรีวิไล"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.1837212,
        "lng": 103.7420316
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.7020316,
        18.1437212,
        103.7820316,
        18.2237212
      ]
    },
    {
      "id": 28514,
      "name": "Simpelveld",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8315487,
        "lng": 5.9784646
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.9734646,
        50.8265487,
        5.9834646,
        50.8365487
      ]
    },
    {
      "id": 28518,
      "name": "Regen",
      "names": {
        "zh": "再生",
        "ja": "リジェネ",
        "th": "ฟื้นฟู"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.9700842,
        "lng": 13.1262631
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.0471896,
        48.9246362,
        13.2119948,
        49.0106733
      ]
    },
    {
      "id": 28555,
      "name": "Tha Chang",
      "names": {
        "id": "Kekacauan",
        "th": "ท่าช้าง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.2674281,
        "lng": 99.1927154
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.1527154,
        9.2274281,
        99.2327154,
        9.3074281
      ]
    },
    {
      "id": 28558,
      "name": "Al `Amadiyah",
      "names": {
        "zh": "Al`amadiyah",
        "de": "Al` Amadiyah",
        "ja": "Al` Amadiyah",
        "th": "อัล `amadiyah",
        "pt": "Al 'Amadiyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.3381935,
        "lng": 44.6094221
      },
      "country": "Iraq",
      "importance": 2,
      "bbox": [
        44.6092719,
        32.3381935,
        44.6094221,
        32.3386241
      ]
    },
    {
      "id": 28559,
      "name": "Tolna",
      "names": {
        "zh": "托尔瑙"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4226584,
        "lng": 18.7887741
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.7068998,
        46.3658258,
        18.8263613,
        46.5009192
      ]
    },
    {
      "id": 28569,
      "name": "Parthenay",
      "names": {
        "zh": "帕尔特奈"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6481266,
        "lng": -0.2472687
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.2701664,
        46.6281677,
        -0.1997558,
        46.6637155
      ]
    },
    {
      "id": 28572,
      "name": "Tepetlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.672778,
        "lng": -96.796667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.836667,
        19.632778,
        -96.756667,
        19.712778
      ]
    },
    {
      "id": 28582,
      "name": "Rackeve",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1609765,
        "lng": 18.9436266
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.8720859,
        47.1069375,
        19.0243007,
        47.2251664
      ]
    },
    {
      "id": 28583,
      "name": "Viransehir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2328946,
        "lng": 39.7620135
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.4461722,
        36.9782019,
        40.1034205,
        37.5625958
      ]
    },
    {
      "id": 28584,
      "name": "Agua de Dios",
      "names": {
        "ja": "アグアデイオス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3772853,
        "lng": -74.6694759
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.7344881,
        4.3280516,
        -74.5921324,
        4.4157009
      ]
    },
    {
      "id": 28585,
      "name": "El Aguila",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9381541,
        "lng": -76.0602974961269
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1406485,
        4.8288611,
        -75.985732,
        5.0474721
      ]
    },
    {
      "id": 28599,
      "name": "Saraykoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9244344,
        "lng": 28.9230836
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.737141,
        37.8011439,
        29.0482784,
        38.026365
      ]
    },
    {
      "id": 28602,
      "name": "Mysliborz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.924825,
        "lng": 14.8664799
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.8319875,
        52.9111093,
        14.9033383,
        52.9772306
      ]
    },
    {
      "id": 28606,
      "name": "Pedernales",
      "names": {
        "zh": "佩德纳莱斯"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.0372324,
        "lng": -71.7441566
      },
      "country": "Dominican Republic",
      "importance": 2,
      "bbox": [
        -71.7878459,
        17.470584,
        -71.2533125,
        18.3406222
      ]
    },
    {
      "id": 28619,
      "name": "Tournon-sur-Rhone",
      "names": {
        "es": "Tournon-sur-Rhône",
        "fr": "Tournon-sur-Rhône",
        "zh": "图尔农畔普瓦图 - ",
        "ja": "tournonシュルローヌ",
        "th": "tournon-ซูร์โรนห์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.0675156,
        "lng": 4.832852
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.7705314,
        45.0303512,
        4.8608271,
        45.0759959
      ]
    },
    {
      "id": 28627,
      "name": "Fao Rai",
      "names": {
        "zh": "粮农组织rai",
        "th": "fao ไร่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.0164751,
        "lng": 103.3108182
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.1508182,
        17.8564751,
        103.4708182,
        18.1764751
      ]
    },
    {
      "id": 28636,
      "name": "Belen de los Andaquies",
      "names": {
        "fr": "Belen de los andakies",
        "zh": "Belen de Los Andaquers",
        "it": "BELEN DE LOS ANDAQUE",
        "id": "Belen de los danaquies",
        "ja": "Belen de los Andaques"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4200848,
        "lng": -75.8714223
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.1021141,
        1.1878655,
        -75.717571,
        1.7394854
      ]
    },
    {
      "id": 28647,
      "name": "Stropkov",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2039851,
        "lng": 21.6507705
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.6507205,
        49.2039351,
        21.6508205,
        49.2040351
      ]
    },
    {
      "id": 28670,
      "name": "Villa La Angostura",
      "names": {
        "ja": "ヴィララゴスラ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.7620649,
        "lng": -71.6473205
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -71.6921881,
        -40.8111438,
        -71.5799523,
        -40.7166078
      ]
    },
    {
      "id": 28680,
      "name": "Chornomorske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.50657,
        "lng": 32.69776
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.67776,
        45.48657,
        32.71776,
        45.52657
      ]
    },
    {
      "id": 28716,
      "name": "Al Atarib",
      "names": {
        "de": "Alten Atarib",
        "ja": "アルタリブ"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.13907,
        "lng": 36.82504
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.80504,
        36.11907,
        36.84504,
        36.15907
      ]
    },
    {
      "id": 28719,
      "name": "Orlu",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 5.7958217,
        "lng": 7.0335047
      },
      "country": "Nigeria",
      "importance": 2,
      "bbox": [
        6.9935047,
        5.7558217,
        7.0735047,
        5.8358217
      ]
    },
    {
      "id": 28729,
      "name": "Zhydachiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.384399,
        "lng": 24.140535
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.120535,
        49.364399,
        24.160535,
        49.404399
      ]
    },
    {
      "id": 28730,
      "name": "Volkermarkt",
      "names": {
        "de": "Völkermarkt",
        "it": "Völkermarkt"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.6604246,
        "lng": 14.6340505
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.4974096,
        46.6078494,
        14.7375481,
        46.741296
      ]
    },
    {
      "id": 28742,
      "name": "Kiskunmajsa",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4906228,
        "lng": 19.7393121
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.567791,
        46.3502792,
        19.8194911,
        46.5670948
      ]
    },
    {
      "id": 28753,
      "name": "Texcatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5846222,
        "lng": -98.3648454
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4048454,
        20.5446222,
        -98.3248454,
        20.6246222
      ]
    },
    {
      "id": 28768,
      "name": "Phibun Mangsahan",
      "names": {
        "th": "พิบูลมังสาหาร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.24556,
        "lng": 105.22722
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28774,
      "name": "Estanzuela",
      "names": {
        "zh": "埃斯坦苏埃拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9972462,
        "lng": -89.5731035
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.6131035,
        14.9572462,
        -89.5331035,
        15.0372462
      ]
    },
    {
      "id": 28777,
      "name": "San Juanito de Escobedo",
      "names": {
        "zh": "圣Juanito de Escocedo",
        "ja": "サンファニートデスエスコプリ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.799235,
        "lng": -104.00058
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.012511,
        20.783673,
        -103.992338,
        20.808381
      ]
    },
    {
      "id": 28788,
      "name": "El Tarra",
      "names": {
        "zh": "埃尔塔拉",
        "ja": "エルタラ",
        "pt": "El tu tarra"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.57562,
        "lng": -73.09489
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.11489,
        8.55562,
        -73.07489,
        8.59562
      ]
    },
    {
      "id": 28794,
      "name": "Keila",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3110918,
        "lng": 24.420907
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        24.3307894,
        59.2961319,
        24.4422344,
        59.3265682
      ]
    },
    {
      "id": 28797,
      "name": "Rokytne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.6867282,
        "lng": 30.473034
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.4510135,
        49.6669354,
        30.5178455,
        49.7156608
      ]
    },
    {
      "id": 28798,
      "name": "Phrai Bueng",
      "names": {
        "zh": "彭布恩",
        "it": "Phai Bueng",
        "th": "ไพรบึง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.74833,
        "lng": 104.36261
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.34261,
        14.72833,
        104.38261,
        14.76833
      ]
    },
    {
      "id": 28818,
      "name": "Sultanhani",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2474175,
        "lng": 33.5489796
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.4699772,
        38.0732205,
        33.7456537,
        38.3516296
      ]
    },
    {
      "id": 28820,
      "name": "Neman",
      "names": {
        "zh": "涅曼河"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0359656,
        "lng": 22.0293578
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        21.9922846,
        55.0085166,
        22.0853163,
        55.0589214
      ]
    },
    {
      "id": 28837,
      "name": "Zwettl",
      "names": {
        "zh": "茨韦特尔"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.6026601,
        "lng": 15.167302
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.1672716,
        48.6026451,
        15.1673635,
        48.6026805
      ]
    },
    {
      "id": 28866,
      "name": "Emirdag",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0195014,
        "lng": 31.1502583
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.9416587,
        38.7689244,
        31.7319863,
        39.2708544
      ]
    },
    {
      "id": 28867,
      "name": "Yalvac",
      "names": {
        "zh": "亚尔瓦奇"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2994784,
        "lng": 31.1759426
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.7341034,
        38.1206755,
        31.4048701,
        38.505298
      ]
    },
    {
      "id": 28893,
      "name": "Bobrynets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.05729,
        "lng": 32.15938
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.1379163,
        48.0367722,
        32.1883988,
        48.0796334
      ]
    },
    {
      "id": 28899,
      "name": "Khlung",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 12.4543683,
        "lng": 102.2238394
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1838394,
        12.4143683,
        102.2638394,
        12.4943683
      ]
    },
    {
      "id": 28909,
      "name": "Sagarejo",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.7369023,
        "lng": 45.3259549
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        45.3018416,
        41.7070595,
        45.3612143,
        41.7528918
      ]
    },
    {
      "id": 28914,
      "name": "Davos",
      "names": {
        "zh": "达沃斯",
        "ja": "ダボス",
        "th": "ดาวอส"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.796198,
        "lng": 9.8236892
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.6691161,
        46.6702615,
        9.9670622,
        46.8596588
      ]
    },
    {
      "id": 28920,
      "name": "Pua",
      "names": {
        "zh": "聚丙交酯",
        "th": "ปัว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1777676,
        "lng": 100.9151926
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.8751926,
        19.1377676,
        100.9551926,
        19.2177676
      ]
    },
    {
      "id": 28922,
      "name": "Nivala",
      "names": {
        "zh": "尼瓦拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.9303871,
        "lng": 24.9629392
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.9628892,
        63.9303371,
        24.9629892,
        63.9304371
      ]
    },
    {
      "id": 28925,
      "name": "Boekel",
      "names": {
        "zh": "布克尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6029009,
        "lng": 5.6726793
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.6265686,
        51.5823602,
        5.7293412,
        51.629061
      ]
    },
    {
      "id": 28936,
      "name": "Mogotes",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4769907,
        "lng": -72.9706464
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.9763842,
        6.4688546,
        -72.9641801,
        6.4846746
      ]
    },
    {
      "id": 28943,
      "name": "Amioun",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 34.3,
        "lng": 35.80889
      },
      "country": "Lebanon",
      "importance": 2,
      "bbox": [
        35.78889,
        34.28,
        35.82889,
        34.32
      ]
    },
    {
      "id": 28956,
      "name": "Sam Ko",
      "names": {
        "zh": "萨姆ko",
        "ja": "サムカ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6050187,
        "lng": 100.2450041
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.0850041,
        14.4450187,
        100.4050041,
        14.7650187
      ]
    },
    {
      "id": 28962,
      "name": "San Pablo Huixtepec",
      "names": {
        "de": "San Pablo Huixtpec",
        "ja": "サンパブロHUIXTEPEC"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8196711,
        "lng": -96.7821359
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.8221359,
        16.7796711,
        -96.7421359,
        16.8596711
      ]
    },
    {
      "id": 28968,
      "name": "Sarmiento",
      "names": {
        "zh": "萨米恩托"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -45.5867861,
        "lng": -69.0687743
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -69.0920717,
        -45.6005858,
        -69.0374727,
        -45.5598954
      ]
    },
    {
      "id": 28978,
      "name": "Reguengos de Monsaraz",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.4209322,
        "lng": -7.5362271
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.5362771,
        38.4208822,
        -7.5361771,
        38.4209822
      ]
    },
    {
      "id": 28984,
      "name": "Celldomolk",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2579643,
        "lng": 17.1521883
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.1018377,
        47.1921115,
        17.2094936,
        47.2810016
      ]
    },
    {
      "id": 28987,
      "name": "Tatvan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4931961,
        "lng": 42.2878112
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.14625,
        38.2323485,
        42.9287715,
        38.683651
      ]
    },
    {
      "id": 28990,
      "name": "Ixtacomitan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4303032,
        "lng": -93.0964955
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.1364955,
        17.3903032,
        -93.0564955,
        17.4703032
      ]
    },
    {
      "id": 28991,
      "name": "Orsta",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.2010542,
        "lng": 6.1238625
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.0838625,
        62.1610542,
        6.1638625,
        62.2410542
      ]
    },
    {
      "id": 28995,
      "name": "Kitee",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.1001947,
        "lng": 30.1356168
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.4072786,
        61.7220668,
        30.4828957,
        62.2571978
      ]
    },
    {
      "id": 29025,
      "name": "Wang Sombun",
      "names": {
        "zh": "王森林",
        "it": "Wang Skorbun",
        "th": "วังสมบูรณ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3608312,
        "lng": 102.1914254
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1514254,
        13.3208312,
        102.2314254,
        13.4008312
      ]
    },
    {
      "id": 29031,
      "name": "Kolno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4109383,
        "lng": 21.9312439
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.8803909,
        53.3663477,
        21.991886,
        53.4373199
      ]
    },
    {
      "id": 29032,
      "name": "Kurman",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.4959593,
        "lng": 34.2950929
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.2797899,
        45.4679865,
        34.3355718,
        45.5629935
      ]
    },
    {
      "id": 29036,
      "name": "Malkara",
      "names": {
        "zh": "马尔卡拉"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8930819,
        "lng": 26.9023798
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.6933334,
        40.7228681,
        27.2087791,
        41.1231056
      ]
    },
    {
      "id": 29045,
      "name": "Tezontepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.882707,
        "lng": -98.816605
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.83411,
        19.872737,
        -98.806444,
        19.88987
      ]
    },
    {
      "id": 29051,
      "name": "Santo Domingo",
      "names": {
        "ja": "サントドミンゴ",
        "th": "ซานโตโดมิงโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.471221,
        "lng": -75.1643724
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2635563,
        6.3934296,
        -75.018321,
        6.5643708
      ]
    },
    {
      "id": 29052,
      "name": "Volda",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.1450799,
        "lng": 6.0667731
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.0267731,
        62.1050799,
        6.1067731,
        62.1850799
      ]
    },
    {
      "id": 29053,
      "name": "Tiszafured",
      "names": {
        "de": "Tiszafüred"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.615678,
        "lng": 20.752842
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.728683,
        47.597585,
        20.788186,
        47.635605
      ]
    },
    {
      "id": 29072,
      "name": "Oloron-Sainte-Marie",
      "names": {
        "zh": "oloron-圣玛丽",
        "ja": "oloron-サント・マリー",
        "th": "oloron-เซนต์ค-มารี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.19199905,
        "lng": -0.6090423657184163
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.6091463,
        43.1919108,
        -0.6089207,
        43.1921636
      ]
    },
    {
      "id": 29096,
      "name": "Al Musayfirah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.63205,
        "lng": 36.33934
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.31934,
        32.61205,
        36.35934,
        32.65205
      ]
    },
    {
      "id": 29127,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 34.4221319,
        "lng": -119.702667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -119.859791,
        34.336029,
        -119.6399059,
        34.463922
      ]
    },
    {
      "id": 29158,
      "name": "Sao Bras de Alportel",
      "names": {
        "it": "Sao Bras de Aliportel",
        "ja": "サンブラスデアル輸入",
        "pt": "São Bras de Alportel"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.15053,
        "lng": -7.8883379
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8883879,
        37.15048,
        -7.8882879,
        37.15058
      ]
    },
    {
      "id": 29164,
      "name": "Murtosa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7367759,
        "lng": -8.638451
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.688962,
        40.7202536,
        -8.6149592,
        40.7542669
      ]
    },
    {
      "id": 29180,
      "name": "Villa Purificacion",
      "names": {
        "es": "Purificacion de villa",
        "fr": "Villa Purificion",
        "zh": "别墅纯粹",
        "ja": "ヴィラ精製",
        "th": "วิลล่า Purificacion"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.82320785,
        "lng": -104.73273779114446
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.0264409,
        19.6007332,
        -104.4363235,
        20.0465428
      ]
    },
    {
      "id": 29182,
      "name": "Bedum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3003141,
        "lng": 6.5961866
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.5574788,
        53.270281,
        6.6540534,
        53.3303873
      ]
    },
    {
      "id": 29210,
      "name": "Hameenkyro",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.633333,
        "lng": 23.2
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.8240404,
        61.5159863,
        23.5003183,
        61.7372885
      ]
    },
    {
      "id": 29217,
      "name": "Szigetvar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.0406692,
        "lng": 17.808917
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.808867,
        46.0406192,
        17.808967,
        46.0407192
      ]
    },
    {
      "id": 29228,
      "name": "Almoloya",
      "names": {
        "zh": "阿尔莫洛亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.703157,
        "lng": -98.4052876
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.4252876,
        19.683157,
        -98.3852876,
        19.723157
      ]
    },
    {
      "id": 29235,
      "name": "Chuqung",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.36509,
        "lng": 97.12
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        97.1,
        33.34509,
        97.14,
        33.38509
      ]
    },
    {
      "id": 29243,
      "name": "Paimio",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4570244,
        "lng": 22.6883278
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.5746264,
        60.3643345,
        22.8759139,
        60.5480505
      ]
    },
    {
      "id": 29245,
      "name": "Knin",
      "names": {
        "zh": "克宁"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.0366673,
        "lng": 16.1983929
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.1933929,
        44.0316673,
        16.2033929,
        44.0416673
      ]
    },
    {
      "id": 29247,
      "name": "Tubara",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.87444,
        "lng": -74.97889
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.99889,
        10.85444,
        -74.95889,
        10.89444
      ]
    },
    {
      "id": 29270,
      "name": "Marum",
      "names": {
        "zh": "马鲁姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1442439,
        "lng": 6.2647308
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.1756776,
        53.1133845,
        6.2928309,
        53.1682605
      ]
    },
    {
      "id": 29272,
      "name": "Altotting",
      "names": {
        "zh": "旧厄廷",
        "de": "Altötting",
        "it": "Altötting",
        "id": "Altötting",
        "pt": "Altötting"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2268085,
        "lng": 12.6757914
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.6076317,
        48.1997428,
        12.7328493,
        48.2530561
      ]
    },
    {
      "id": 29300,
      "name": "Santiago Suchilquitongo",
      "names": {
        "fr": "Santiago telilquitongo",
        "zh": "Santiago如此",
        "de": "Santiago daneiilquitongo",
        "it": "Santiagochilquitongo",
        "ja": "Santiago Sivilequitongo",
        "th": "ซันติอาโกซึ่ยquitongo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2500051,
        "lng": -96.8670532
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9070532,
        17.2100051,
        -96.8270532,
        17.2900051
      ]
    },
    {
      "id": 29302,
      "name": "Kauniainen",
      "names": {
        "zh": "考尼艾宁"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.2098563,
        "lng": 24.7294936
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.6752776,
        60.2025488,
        24.7509167,
        60.225918
      ]
    },
    {
      "id": 29310,
      "name": "Ergani",
      "names": {
        "zh": "埃尔加尼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2689763,
        "lng": 39.7609219
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.5478545,
        37.7943011,
        39.9585519,
        38.3819471
      ]
    },
    {
      "id": 29314,
      "name": "Vouzela",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.722066,
        "lng": -8.107865
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.116771,
        40.718381,
        -8.101117,
        40.729563
      ]
    },
    {
      "id": 29318,
      "name": "Monnickendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.454824,
        "lng": 5.038923
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.965497,
        52.444987,
        5.055066,
        52.494733
      ]
    },
    {
      "id": 29319,
      "name": "Jablanica",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 43.6617485,
        "lng": 17.7618471
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.7218471,
        43.6217485,
        17.8018471,
        43.7017485
      ]
    },
    {
      "id": 29320,
      "name": "Benito Juarez",
      "names": {
        "es": "Benito Juárez",
        "ja": "ベニートフアレス",
        "th": "เบนิโต Juarez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": 21.0742627,
        "lng": -86.9265622257142
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -87.0971645,
        20.9315194,
        -86.7405259,
        21.2171557
      ]
    },
    {
      "id": 29329,
      "name": "Peschanokopskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.194204,
        "lng": 41.068794
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        41.034721,
        46.18536,
        41.097584,
        46.20475
      ]
    },
    {
      "id": 29349,
      "name": "Imst",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2389956,
        "lng": 10.7374186
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        10.7373686,
        47.2389456,
        10.7374686,
        47.2390456
      ]
    },
    {
      "id": 29355,
      "name": "Armanaz",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.08363,
        "lng": 36.5031
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.4831,
        36.06363,
        36.5231,
        36.10363
      ]
    },
    {
      "id": 29359,
      "name": "Scherpenzeel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.079676,
        "lng": 5.485421
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.459243,
        52.069619,
        5.53916,
        52.111097
      ]
    },
    {
      "id": 29360,
      "name": "Barcs",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 45.9605126,
        "lng": 17.4634121
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.3395128,
        45.9274323,
        17.5670384,
        46.0325145
      ]
    },
    {
      "id": 29369,
      "name": "Doorn",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0333163,
        "lng": 5.3455956
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2917643,
        52.010039,
        5.3920005,
        52.070766
      ]
    },
    {
      "id": 29379,
      "name": "San Pedro La Laguna",
      "names": {
        "zh": "圣佩德罗拉拉古纳",
        "ja": "サンペドロララグーナ",
        "th": "ซานเปโดรลาลากูน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.691599,
        "lng": -91.272016
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.312016,
        14.651599,
        -91.232016,
        14.731599
      ]
    },
    {
      "id": 29404,
      "name": "Montalegre",
      "names": {
        "zh": "蒙塔莱格里"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.822440900000004,
        "lng": -7.783824703325438
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8302428,
        41.7896795,
        -7.753221,
        41.8568889
      ]
    },
    {
      "id": 29431,
      "name": "Serinhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5764593,
        "lng": 29.2662284
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.2106604,
        37.5243029,
        29.3973833,
        37.689506
      ]
    },
    {
      "id": 29445,
      "name": "Sotkamo",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.1318262,
        "lng": 28.3878316
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.7966213,
        63.6688119,
        29.2110464,
        64.3752261
      ]
    },
    {
      "id": 29453,
      "name": "Sortland",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 68.6951304,
        "lng": 15.4148253
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        15.3748253,
        68.6551304,
        15.4548253,
        68.7351304
      ]
    },
    {
      "id": 29460,
      "name": "El Chol",
      "names": {
        "pt": "Elogio elogio"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9605717,
        "lng": -90.4877093
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.5077093,
        14.9405717,
        -90.4677093,
        14.9805717
      ]
    },
    {
      "id": 29465,
      "name": "Regidor",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.6650933,
        "lng": -73.8224537
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.9242633,
        8.615076,
        -73.7888995,
        8.8372649
      ]
    },
    {
      "id": 29466,
      "name": "Vaals",
      "names": {
        "zh": "瓦尔斯"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.771063,
        "lng": 6.016392
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.972608,
        50.75276,
        6.027931,
        50.78522
      ]
    },
    {
      "id": 29468,
      "name": "Prakhon Chai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6099527,
        "lng": 103.0809241
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.0409241,
        14.5699527,
        103.1209241,
        14.6499527
      ]
    },
    {
      "id": 29477,
      "name": "Phai Sali",
      "names": {
        "de": "Phai-Sali",
        "ja": "ファイサー",
        "th": "ไผ่ซอย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.598512,
        "lng": 100.649635
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.56501486,
        15.5875469535,
        100.66056972,
        15.6373641953
      ]
    },
    {
      "id": 29497,
      "name": "Caglayancerit",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7499656,
        "lng": 37.2927846
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.1460215,
        37.6380101,
        37.5131198,
        37.9195183
      ]
    },
    {
      "id": 29503,
      "name": "Huittinen",
      "names": {
        "zh": "胡伊蒂宁"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1770887,
        "lng": 22.6990511
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.4803304,
        60.9825919,
        23.0062873,
        61.3020348
      ]
    },
    {
      "id": 29516,
      "name": "Lyngdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.1379878,
        "lng": 7.0685965
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.0285965,
        58.0979878,
        7.1085965,
        58.1779878
      ]
    },
    {
      "id": 29535,
      "name": "Olevsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.2285475,
        "lng": 27.6477957
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.6427957,
        51.2235475,
        27.6527957,
        51.2335475
      ]
    },
    {
      "id": 29541,
      "name": "Salanso",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.1833,
        "lng": -4.0833
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 29552,
      "name": "Midyat",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4247,
        "lng": 41.33934
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.31934,
        37.4047,
        41.35934,
        37.4447
      ]
    },
    {
      "id": 29562,
      "name": "Oudewater",
      "names": {
        "zh": "奥得瓦特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.02596,
        "lng": 4.868728
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.806704,
        51.986143,
        4.920045,
        52.054167
      ]
    },
    {
      "id": 29577,
      "name": "Kragero",
      "names": {
        "zh": "克拉格勒",
        "de": "Kragerø",
        "it": "Kragerø",
        "id": "Kragerø"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.871972,
        "lng": 9.403197
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.364996,
        58.863793,
        9.418647,
        58.882727
      ]
    },
    {
      "id": 29578,
      "name": "Phon Charoen",
      "names": {
        "ja": "フォンチャルエン",
        "th": "โพนเจริญ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.0236166,
        "lng": 103.7078877
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.6678877,
        17.9836166,
        103.7478877,
        18.0636166
      ]
    },
    {
      "id": 29587,
      "name": "San Juan La Laguna",
      "names": {
        "zh": "圣胡安拉拉古纳",
        "ja": "サンファンララグーナ",
        "th": "ซานฮวนลาลากูน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7,
        "lng": -91.28333
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.30333,
        14.68,
        -91.26333,
        14.72
      ]
    },
    {
      "id": 29592,
      "name": "Lachen",
      "names": {
        "zh": "拉亨"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1923827,
        "lng": 8.8523833
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.8397099,
        47.1840549,
        8.8726395,
        47.2097459
      ]
    },
    {
      "id": 29605,
      "name": "Coroneo",
      "names": {
        "zh": "科罗内奥"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1987764,
        "lng": -100.365323
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.405323,
        20.1587764,
        -100.325323,
        20.2387764
      ]
    },
    {
      "id": 29615,
      "name": "Limoux",
      "names": {
        "zh": "利穆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.0563833,
        "lng": 2.22282812505697
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2227629,
        43.056194,
        2.2228946,
        43.0565722
      ]
    },
    {
      "id": 29617,
      "name": "Tha Muang",
      "names": {
        "ja": "黄金",
        "th": "ท่าทาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.972556,
        "lng": 99.6362514
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.5962514,
        13.932556,
        99.6762514,
        14.012556
      ]
    },
    {
      "id": 29660,
      "name": "El Realejo",
      "names": {
        "es": "El reorejo",
        "zh": "el Reaejo",
        "it": "El relejo"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.54307,
        "lng": -87.16466
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -87.18466,
        12.52307,
        -87.14466,
        12.56307
      ]
    },
    {
      "id": 29668,
      "name": "Havza",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9656928,
        "lng": 35.6671361
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.6621361,
        40.9606928,
        35.6721361,
        40.9706928
      ]
    },
    {
      "id": 29671,
      "name": "Knezha",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.499203,
        "lng": 24.081477
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        24.056333,
        43.477887,
        24.107958,
        43.526438
      ]
    },
    {
      "id": 29677,
      "name": "Kapyl'",
      "names": {
        "es": "Kapyl '",
        "fr": "Kapyl '",
        "de": "Kapyl '",
        "it": "Kapyl '",
        "id": "Kapyl '",
        "ja": "kapyl '",
        "th": "kapyl '",
        "pt": "Kapyl '"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.1514024,
        "lng": 27.0906929
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.0646777,
        53.1350237,
        27.1203663,
        53.1723898
      ]
    },
    {
      "id": 29687,
      "name": "Verkhnodniprovsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.658236,
        "lng": 34.324823
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.304823,
        48.638236,
        34.344823,
        48.678236
      ]
    },
    {
      "id": 29705,
      "name": "Bernay",
      "names": {
        "zh": "贝尔奈"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0902278,
        "lng": 0.5989157
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.5308264,
        49.0607962,
        0.6648236,
        49.1217111
      ]
    },
    {
      "id": 29708,
      "name": "Levanger",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.746342,
        "lng": 11.2999563
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.2599563,
        63.706342,
        11.3399563,
        63.786342
      ]
    },
    {
      "id": 29709,
      "name": "Forde",
      "names": {
        "zh": "福德",
        "th": "ฟอร์ด"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.4521759,
        "lng": 5.8571725
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.8171725,
        61.4121759,
        5.8971725,
        61.4921759
      ]
    },
    {
      "id": 29719,
      "name": "Kut Chap",
      "names": {
        "es": "Kut cap",
        "fr": "Kut",
        "de": "Kusp",
        "it": "Kut cap",
        "id": "Kut"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4196097,
        "lng": 102.5661535
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.5261535,
        17.3796097,
        102.6061535,
        17.4596097
      ]
    },
    {
      "id": 29720,
      "name": "Oostzaan",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4387666,
        "lng": 4.8753615
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.842951,
        52.4244613,
        4.9064697,
        52.4798265
      ]
    },
    {
      "id": 29728,
      "name": "Nagyatad",
      "names": {
        "zh": "瑙吉奥塔德"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.229143,
        "lng": 17.3570964
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.2777142,
        46.1448212,
        17.4085303,
        46.2703902
      ]
    },
    {
      "id": 29742,
      "name": "Sobral de Monte Agraco",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.01835,
        "lng": -9.15171
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -9.2405661,
        38.9478908,
        -9.098558,
        39.0359112
      ]
    },
    {
      "id": 29759,
      "name": "Teocuitatlan de Corona",
      "names": {
        "zh": "Teocitatlan de Corona",
        "it": "Teocuititlan de Corona",
        "ja": "Teocuitaturan de Corona"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0916986,
        "lng": -103.3774447
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.3974447,
        20.0716986,
        -103.3574447,
        20.1116986
      ]
    },
    {
      "id": 29762,
      "name": "Selcuk",
      "names": {
        "zh": "塞尔丘克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.95137,
        "lng": 27.36849
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.34849,
        37.93137,
        27.38849,
        37.97137
      ]
    },
    {
      "id": 29772,
      "name": "Lenguazaque",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.30711,
        "lng": -73.71152
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.73152,
        5.28711,
        -73.69152,
        5.32711
      ]
    },
    {
      "id": 29778,
      "name": "Oliveira de Frades",
      "names": {
        "fr": "Oliveira de Fades",
        "zh": "oliveira de thrades",
        "de": "Oliveira de Frade",
        "ja": "オリビラデフリーデス"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7322108,
        "lng": -8.1739059
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3335879,
        40.563662,
        -8.1295463,
        40.8039268
      ]
    },
    {
      "id": 29787,
      "name": "Kranuan",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.7069169,
        "lng": 103.0807347
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.0407347,
        16.6669169,
        103.1207347,
        16.7469169
      ]
    },
    {
      "id": 29790,
      "name": "Chavinda",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0067244,
        "lng": -102.4591287
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.4991287,
        19.9667244,
        -102.4191287,
        20.0467244
      ]
    },
    {
      "id": 29803,
      "name": "Cumra",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5713333,
        "lng": 32.7811767
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.4116362,
        37.2614196,
        33.1734967,
        37.8018357
      ]
    },
    {
      "id": 29805,
      "name": "Banska Stiavnica",
      "names": {
        "it": "Banska Striavnica",
        "id": "Banska Stivnica",
        "ja": "バンスカスタアヴニカ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4580926,
        "lng": 18.8988414
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.8495849,
        48.3966786,
        18.9639239,
        48.4918594
      ]
    },
    {
      "id": 29818,
      "name": "Bad Ems",
      "names": {
        "es": "Mal ems",
        "fr": "Mauvais ems",
        "zh": "坏血病",
        "de": "Schlechte EMS",
        "it": "Cattivo ems",
        "id": "Ems buruk",
        "ja": "悪いems",
        "th": "ems ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3352062,
        "lng": 7.7128538
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6947632,
        50.3086095,
        7.7555018,
        50.368473
      ]
    },
    {
      "id": 29825,
      "name": "Yahyali",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1003432,
        "lng": 35.3541006
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.1498853,
        37.6914408,
        35.6929583,
        38.2583419
      ]
    },
    {
      "id": 29861,
      "name": "Hecelchakan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1774374,
        "lng": -90.1338849
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -90.1738849,
        20.1374374,
        -90.0938849,
        20.2174374
      ]
    },
    {
      "id": 29863,
      "name": "Santa Clara La Laguna",
      "names": {
        "zh": "圣克拉拉拉拉古纳",
        "ja": "サンタクララララグーナ",
        "th": "ซานตาคลาร่าลาลากูน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.71667,
        "lng": -91.3
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.32,
        14.69667,
        -91.28,
        14.73667
      ]
    },
    {
      "id": 29903,
      "name": "Slatina",
      "names": {
        "zh": "斯拉蒂纳"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.7014436,
        "lng": 17.7016641
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        17.6616641,
        45.6614436,
        17.7416641,
        45.7414436
      ]
    },
    {
      "id": 29908,
      "name": "Kapuvar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5936871,
        "lng": 17.0274744
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        16.9593405,
        47.5455986,
        17.1080896,
        47.7082493
      ]
    },
    {
      "id": 29920,
      "name": "Moimenta da Beira",
      "names": {
        "fr": "Mouimenta da Beira",
        "zh": "莫斯塔达贝拉",
        "it": "Moimta da beira",
        "id": "Moenta da beira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9846411,
        "lng": -7.6185527
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.7573344,
        40.8535785,
        -7.5309866,
        41.0591071
      ]
    },
    {
      "id": 29955,
      "name": "Charala",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2881742,
        "lng": -73.148289
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.2394884,
        5.9824427,
        -73.0038546,
        6.3917686
      ]
    },
    {
      "id": 29968,
      "name": "Tarashcha",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.555498,
        "lng": 30.5023421
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.4651387,
        49.5355283,
        30.5426725,
        49.5811813
      ]
    },
    {
      "id": 29975,
      "name": "Seye",
      "names": {
        "zh": "塞耶"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8371058,
        "lng": -89.3726269
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.3879676,
        20.8254599,
        -89.3557415,
        20.8489058
      ]
    },
    {
      "id": 29984,
      "name": "Sniatyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.44692,
        "lng": 25.56937
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.54937,
        48.42692,
        25.58937,
        48.46692
      ]
    },
    {
      "id": 29986,
      "name": "Shyroke",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6846511,
        "lng": 33.2645369
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.2390295,
        47.6644462,
        33.2898402,
        47.7076075
      ]
    },
    {
      "id": 29989,
      "name": "Pithiviers",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1711672,
        "lng": 2.2546501470779203
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2543757,
        48.1709645,
        2.254801,
        48.1714133
      ]
    },
    {
      "id": 29996,
      "name": "Mstsislaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.0199107,
        "lng": 31.7257763
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        31.6780189,
        53.9948502,
        31.7635563,
        54.0470827
      ]
    },
    {
      "id": 30001,
      "name": "Medina",
      "names": {
        "zh": "麦地那",
        "ja": "メディナ",
        "th": "เมดินา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 29.2836281,
        "lng": -99.1126826
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -99.4138949,
        29.0886492,
        -98.8046637,
        29.6906921
      ]
    },
    {
      "id": 30002,
      "name": "Kocaali",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0542812,
        "lng": 30.8507446
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.7507109,
        40.8666331,
        31.0126662,
        41.080908
      ]
    },
    {
      "id": 30031,
      "name": "San Blas",
      "names": {
        "zh": "圣巴拉斯",
        "ja": "サンブラス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": -21.4860015,
        "lng": -135.57954867813987
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -135.6449962,
        -21.5719775,
        -135.4606694,
        -21.4742514
      ]
    },
    {
      "id": 30034,
      "name": "Nong Ki",
      "names": {
        "it": "Non ki",
        "th": "หนองคี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.684354,
        "lng": 102.5392784
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.4992784,
        14.644354,
        102.5792784,
        14.724354
      ]
    },
    {
      "id": 30041,
      "name": "Molsheim",
      "names": {
        "zh": "莫尔塞姆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5419351,
        "lng": 7.4924679
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.4637186,
        48.52908,
        7.534163,
        48.5619915
      ]
    },
    {
      "id": 30075,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1442998,
        "lng": -75.1844057
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2333456,
        6.018251,
        -75.059149,
        6.2327742
      ]
    },
    {
      "id": 30095,
      "name": "Phak Hai",
      "names": {
        "th": "ผักไหม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4582673,
        "lng": 100.3693831
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.3436601,
        14.4308774,
        100.3967969,
        14.4853576
      ]
    },
    {
      "id": 30097,
      "name": "Sung Noen",
      "names": {
        "es": "Hung noen",
        "zh": "唱noen",
        "th": "ร้องไห้"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8975836,
        "lng": 101.818529
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.778529,
        14.8575836,
        101.858529,
        14.9375836
      ]
    },
    {
      "id": 30107,
      "name": "Opochka",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.71167,
        "lng": 28.656818
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        28.6250047,
        56.6936711,
        28.7527973,
        56.7359538
      ]
    },
    {
      "id": 30120,
      "name": "Choele Choel",
      "names": {
        "es": "Choillero",
        "fr": "Choele choquel",
        "de": "Chofel",
        "it": "Choele mosso",
        "ja": "チェイレの字句"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -39.2946503,
        "lng": -65.6551733
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.6791693,
        -39.3094395,
        -65.6400882,
        -39.2747556
      ]
    },
    {
      "id": 30122,
      "name": "Arteaga",
      "names": {
        "zh": "阿特阿加"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.387978949999997,
        "lng": -102.178567930947
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.8656753,
        18.0883062,
        -101.8557161,
        18.6876692
      ]
    },
    {
      "id": 30135,
      "name": "Santa Isabel Cholula",
      "names": {
        "zh": "圣诞老人isabel cholula",
        "ja": "サンタイザベルチョルラ",
        "th": "ซานตาอิซาเบลโคโลล่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9916983,
        "lng": -98.3727196
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.3727696,
        18.9916483,
        -98.3726696,
        18.9917483
      ]
    },
    {
      "id": 30136,
      "name": "Bucak",
      "names": {
        "zh": "布贾克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4567447,
        "lng": 30.5855392
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.238323,
        37.2065063,
        30.9889037,
        37.5568051
      ]
    },
    {
      "id": 30141,
      "name": "Chamusca",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3560025,
        "lng": -8.4802892
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.5805325,
        39.0711327,
        -8.1725995,
        39.4637897
      ]
    },
    {
      "id": 30155,
      "name": "Keuruu",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2579819,
        "lng": 24.7083599
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.0130258,
        62.0231853,
        25.0718647,
        62.5296747
      ]
    },
    {
      "id": 30170,
      "name": "Judenburg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1749706,
        "lng": 14.6626045
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.6576045,
        47.1699706,
        14.6676045,
        47.1799706
      ]
    },
    {
      "id": 30203,
      "name": "Heves",
      "names": {
        "zh": "赫维什"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.597182,
        "lng": 20.284482
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.257664,
        47.585478,
        20.311177,
        47.616755
      ]
    },
    {
      "id": 30205,
      "name": "Kamalasai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.336657,
        "lng": 103.5750671
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.5350671,
        16.296657,
        103.6150671,
        16.376657
      ]
    },
    {
      "id": 30219,
      "name": "Kaman",
      "names": {
        "zh": "卡曼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3588984,
        "lng": 33.7243959
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.4063338,
        39.1431606,
        34.0275065,
        39.5114646
      ]
    },
    {
      "id": 30233,
      "name": "Makale",
      "names": {
        "zh": "默克莱"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 13.49667,
        "lng": 39.47528
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        39.45528,
        13.47667,
        39.49528,
        13.51667
      ]
    },
    {
      "id": 30234,
      "name": "Hongliuwan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6348198,
        "lng": 94.3384302
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        93.5266112,
        39.2153644,
        94.9459074,
        39.729332
      ]
    },
    {
      "id": 30242,
      "name": "Bergambacht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9314474,
        "lng": 4.7827247
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7168123,
        51.9052421,
        4.8323046,
        51.9731357
      ]
    },
    {
      "id": 30281,
      "name": "Figeac",
      "names": {
        "zh": "菲雅克"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.6092335,
        "lng": 2.0324321
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.9673318,
        44.5671573,
        2.0797165,
        44.6458604
      ]
    },
    {
      "id": 30309,
      "name": "San Pedro Huamelula",
      "names": {
        "zh": "圣佩德罗惠梅拉",
        "it": "San Pedro Huaamelula",
        "ja": "サンペドロフアメーラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.027913,
        "lng": -95.668734
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.677342,
        16.020472,
        -95.660566,
        16.034551
      ]
    },
    {
      "id": 30315,
      "name": "Suaita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1014,
        "lng": -73.44041
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.46041,
        6.0814,
        -73.42041,
        6.1214
      ]
    },
    {
      "id": 30320,
      "name": "Valozhyn",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.084751,
        "lng": 26.524609
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.4896936,
        54.06311,
        26.5724308,
        54.1022916
      ]
    },
    {
      "id": 30332,
      "name": "Nogent-le-Rotrou",
      "names": {
        "zh": "诺让了-rotrou",
        "ja": "nogent・ル・rotrou",
        "th": "nogent-เลอ-rotrou"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3217485,
        "lng": 0.8216664999999996
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.821495,
        48.32161,
        0.821838,
        48.321887
      ]
    },
    {
      "id": 30340,
      "name": "Alajarvi",
      "names": {
        "zh": "阿拉耶尔维"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9998641,
        "lng": 23.8167613
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.4578921,
        62.7300741,
        24.3573509,
        63.1584901
      ]
    },
    {
      "id": 30341,
      "name": "Fatsa",
      "names": {
        "zh": "法特萨"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.02676365,
        "lng": 37.50018233458377
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.4868735,
        41.0169368,
        37.5237965,
        41.0364373
      ]
    },
    {
      "id": 30346,
      "name": "Jerez",
      "names": {
        "zh": "赫雷斯",
        "id": "Jerez de La Frontera",
        "ja": "ヘレス"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0917583,
        "lng": -89.7504944
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.7704944,
        14.0717583,
        -89.7304944,
        14.1117583
      ]
    },
    {
      "id": 30353,
      "name": "Saint-Amand-Montrond",
      "names": {
        "fr": "Saint-Amand Montrond-",
        "zh": "圣 -  amand-montrond",
        "it": "Saint-amand montrond",
        "ja": "サン・amand-montrond",
        "th": "เซนต์ amand-montrond"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.7227062,
        "lng": 2.5046503
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.4653696,
        46.7020812,
        2.5679321,
        46.7449423
      ]
    },
    {
      "id": 30356,
      "name": "Gar",
      "names": {
        "zh": "加尔",
        "ja": "ガー",
        "th": "การ์"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.4945168,
        "lng": 80.0941222
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        79.9341222,
        32.3345168,
        80.2541222,
        32.6545168
      ]
    },
    {
      "id": 30362,
      "name": "Pyetrykaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1289,
        "lng": 28.4921
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        28.4721,
        52.1089,
        28.5121,
        52.1489
      ]
    },
    {
      "id": 30370,
      "name": "Lapinlahti",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.3638712,
        "lng": 27.3984804
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.1191,
        63.1537639,
        28.130545,
        63.5501499
      ]
    },
    {
      "id": 30388,
      "name": "Teotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1193462,
        "lng": -97.08730118659768
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.213423,
        18.0323358,
        -96.995114,
        18.2058672
      ]
    },
    {
      "id": 30389,
      "name": "Bastak",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 27.1977195,
        "lng": 54.362564
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        54.322564,
        27.1577195,
        54.402564,
        27.2377195
      ]
    },
    {
      "id": 30391,
      "name": "Ii",
      "names": {
        "zh": "二",
        "ja": "IIの",
        "th": "ที่สอง"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.3167,
        "lng": 25.3722
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30403,
      "name": "Daruvar",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.5928272,
        "lng": 17.2237617
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        17.1837617,
        45.5528272,
        17.2637617,
        45.6328272
      ]
    },
    {
      "id": 30404,
      "name": "Santa Cruz Atizapan",
      "names": {
        "zh": "圣克鲁斯atizapan",
        "ja": "サンタクルスアトザパン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1766661,
        "lng": -99.4881112
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.5281112,
        19.1366661,
        -99.4481112,
        19.2166661
      ]
    },
    {
      "id": 30418,
      "name": "Leppavirta",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4894313,
        "lng": 27.7860813
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.406764,
        62.2846063,
        28.545454,
        62.70584
      ]
    },
    {
      "id": 30419,
      "name": "Sorkheh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4638851,
        "lng": 53.2135038
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        53.1735038,
        35.4238851,
        53.2535038,
        35.5038851
      ]
    },
    {
      "id": 30426,
      "name": "Liminka",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.808038,
        "lng": 25.415693
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.385739,
        64.79731,
        25.453397,
        64.820303
      ]
    },
    {
      "id": 30429,
      "name": "Amatenango del Valle",
      "names": {
        "fr": "Amaenango del Valle",
        "de": "Amateango del Valle",
        "it": "Amatengo del Valle",
        "ja": "アマテナンゴデルバレー"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.528363,
        "lng": -92.4323263
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.4723263,
        16.488363,
        -92.3923263,
        16.568363
      ]
    },
    {
      "id": 30430,
      "name": "Tha Mai",
      "names": {
        "ja": "マイ",
        "th": "ท่าใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6222804,
        "lng": 102.0088644
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.9688644,
        12.5822804,
        102.0488644,
        12.6622804
      ]
    },
    {
      "id": 30443,
      "name": "Nea Moudania",
      "names": {
        "ja": "ネアモダニア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.239881,
        "lng": 23.2853943
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.2715742,
        40.2346648,
        23.295242,
        40.2482997
      ]
    },
    {
      "id": 30448,
      "name": "Mae Ai",
      "names": {
        "ja": "メーアイ",
        "th": "แม่ไอ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 20.0328666,
        "lng": 99.298
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.258,
        19.9928666,
        99.338,
        20.0728666
      ]
    },
    {
      "id": 30450,
      "name": "Foix",
      "names": {
        "zh": "富瓦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.9638998,
        "lng": 1.6053807
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.5713114,
        42.9313833,
        1.6470594,
        43.0087506
      ]
    },
    {
      "id": 30452,
      "name": "Bogande",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.9763066,
        "lng": -0.1432736
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -0.1832736,
        12.9363066,
        -0.1032736,
        13.0163066
      ]
    },
    {
      "id": 30455,
      "name": "Zell am See",
      "names": {
        "es": "Zell am ver",
        "fr": "Zell am on se voit",
        "zh": "Zell我看到了",
        "th": "Zell ฉันเห็น"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.3239636,
        "lng": 12.7963165
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        12.7355352,
        47.2819997,
        12.8999621,
        47.3653238
      ]
    },
    {
      "id": 30476,
      "name": "Byalynichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.9982176,
        "lng": 29.7099785
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.6798174,
        53.967502,
        29.7639795,
        54.0198315
      ]
    },
    {
      "id": 30478,
      "name": "Cosne sur Loire",
      "names": {
        "ja": "COSNE SURロワール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.41407795,
        "lng": 2.9217107213945686
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.92156,
        47.4138447,
        2.9219113,
        47.4145081
      ]
    },
    {
      "id": 30479,
      "name": "Jilotlan de los Dolores",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3712336,
        "lng": -103.0189823
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.0389823,
        19.3512336,
        -102.9989823,
        19.3912336
      ]
    },
    {
      "id": 30485,
      "name": "Saarijarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.7050975,
        "lng": 25.2582688
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.5042407,
        62.5208326,
        25.6966054,
        62.9096016
      ]
    },
    {
      "id": 30494,
      "name": "Talachyn",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4097573,
        "lng": 29.6938169
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.6700365,
        54.3917176,
        29.7711658,
        54.4243384
      ]
    },
    {
      "id": 30496,
      "name": "Non Sung",
      "names": {
        "es": "No cantado",
        "fr": "Non chanté",
        "zh": "非唱",
        "de": "Nicht gesungen",
        "it": "Non cantato",
        "id": "Nenang",
        "ja": "歌われていない",
        "th": "ไม่ร้องไห้",
        "pt": "Não cantado"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1779242,
        "lng": 102.2501126
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.2101126,
        15.1379242,
        102.2901126,
        15.2179242
      ]
    },
    {
      "id": 30503,
      "name": "Margarita",
      "names": {
        "zh": "玛格丽塔",
        "it": "Margherita",
        "ja": "マルガリータ",
        "th": "เทล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.0574428,
        "lng": -74.22089490793826
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3233911,
        8.9459312,
        -74.0972132,
        9.1689793
      ]
    },
    {
      "id": 30511,
      "name": "Csorna",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6134153,
        "lng": 17.2497955
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.0937422,
        47.5636775,
        17.3090604,
        47.7139039
      ]
    },
    {
      "id": 30512,
      "name": "Trancoso",
      "names": {
        "zh": "特兰科苏"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7790351,
        "lng": -7.3474798
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.3475298,
        40.7789851,
        -7.3474298,
        40.7790851
      ]
    },
    {
      "id": 30524,
      "name": "Nong Wua So",
      "names": {
        "es": "Nong wua asi",
        "fr": "Nong wua donc",
        "zh": "作响所以",
        "it": "Nonnz'ong wua così",
        "id": "Nong wua begitu",
        "ja": "Nong Wuaそう",
        "th": "หนอง wua ดังนั้น",
        "pt": "Nong wua tão"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.27175,
        "lng": 102.59753
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30540,
      "name": "Beypazari",
      "names": {
        "zh": "贝伊帕扎勒"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1661927,
        "lng": 31.9207632
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.6577911,
        39.8491889,
        32.1993826,
        40.5082525
      ]
    },
    {
      "id": 30545,
      "name": "Nopaltepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7786668,
        "lng": -98.709913
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.749913,
        19.7386668,
        -98.669913,
        19.8186668
      ]
    },
    {
      "id": 30557,
      "name": "Ruoqiang",
      "names": {
        "zh": "若羌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.02003,
        "lng": 88.16465
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        88.14465,
        39.00003,
        88.18465,
        39.04003
      ]
    },
    {
      "id": 30560,
      "name": "Norosi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.52692,
        "lng": -74.03736
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.05736,
        8.50692,
        -74.01736,
        8.54692
      ]
    },
    {
      "id": 30569,
      "name": "Kobeliaky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1433333,
        "lng": 34.2002778
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.1443883,
        49.1269802,
        34.2440225,
        49.1670646
      ]
    },
    {
      "id": 30571,
      "name": "Bueng Khong Long",
      "names": {
        "es": "Bueng Khong largo",
        "zh": "布庚康龙",
        "de": "Bueng Khong lang",
        "th": "บึงโขงยาว",
        "pt": "Bueng Khong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.967609,
        "lng": 104.052871
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.966721,
        17.875165,
        104.190788,
        18.167901
      ]
    },
    {
      "id": 30573,
      "name": "Berezivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.2016391,
        "lng": 30.908595
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.8894777,
        47.1848938,
        30.9468818,
        47.2168481
      ]
    },
    {
      "id": 30574,
      "name": "Muurame",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.130169,
        "lng": 25.673277
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.613426,
        62.088739,
        25.708744,
        62.148022
      ]
    },
    {
      "id": 30593,
      "name": "Photharam",
      "names": {
        "th": "โพธาราม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6936859,
        "lng": 99.8507326
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8457326,
        13.6886859,
        99.8557326,
        13.6986859
      ]
    },
    {
      "id": 30594,
      "name": "Chom Thong",
      "names": {
        "es": "Chom tanga",
        "fr": "Chom dring",
        "zh": "丁字裤",
        "de": "Chom-Tanga",
        "ja": "チョンソン",
        "th": "จอมทอง",
        "pt": "Choca"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.4221125,
        "lng": 98.6772798
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.6372798,
        18.3821125,
        98.7172798,
        18.4621125
      ]
    },
    {
      "id": 30599,
      "name": "Ahmetli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.401871,
        "lng": 36.616253
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.596253,
        39.381871,
        36.636253,
        39.421871
      ]
    },
    {
      "id": 30602,
      "name": "La Tour-du-Pin",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5666202,
        "lng": 5.4424054
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.4340082,
        45.5562241,
        5.4577117,
        45.5927145
      ]
    },
    {
      "id": 30636,
      "name": "Masku",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.566667,
        "lng": 22.1
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.7773111,
        60.4795639,
        22.4149969,
        60.7372962
      ]
    },
    {
      "id": 30644,
      "name": "T'q'ibuli",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.35121,
        "lng": 42.99874
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        42.97874,
        42.33121,
        43.01874,
        42.37121
      ]
    },
    {
      "id": 30649,
      "name": "Chiang Klang",
      "names": {
        "zh": "蒋介康",
        "ja": "チェン・クラン",
        "th": "เชียงใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.2847032,
        "lng": 100.862686
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.702686,
        19.1247032,
        101.022686,
        19.4447032
      ]
    },
    {
      "id": 30654,
      "name": "Huaniqueo de Morales",
      "names": {
        "es": "Huanico de morales",
        "zh": "华居大道"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8979256,
        "lng": -101.5061108
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.5261108,
        19.8779256,
        -101.4861108,
        19.9179256
      ]
    },
    {
      "id": 30659,
      "name": "Cay",
      "names": {
        "zh": "沙洲",
        "ja": "岩礁",
        "th": "สันดอน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5910378,
        "lng": 31.0265129
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.6912528,
        38.3370066,
        31.2231444,
        38.672767
      ]
    },
    {
      "id": 30661,
      "name": "Thap Khlo",
      "names": {
        "ja": "khloをタップします",
        "th": "ทับ khlo"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.175902,
        "lng": 100.599436
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.489879,
        16.049579,
        100.721214,
        16.330526
      ]
    },
    {
      "id": 30668,
      "name": "Wiang Sa",
      "names": {
        "zh": "沃恩萨",
        "ja": "ウィアンサー",
        "th": "เวียงสา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.6345716,
        "lng": 99.3707953
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.3307953,
        8.5945716,
        99.4107953,
        8.6745716
      ]
    },
    {
      "id": 30682,
      "name": "Hueyotlipan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.891042,
        "lng": -97.867076
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.877048,
        18.874035,
        -97.849828,
        18.903188
      ]
    },
    {
      "id": 30695,
      "name": "Mae Rim",
      "names": {
        "es": "Mae borde",
        "fr": "Mae jante",
        "it": "Mae bordo",
        "ja": "メーリム",
        "th": "แม่ริม",
        "pt": "Mae aro"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.914458,
        "lng": 98.945274
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.905274,
        18.874458,
        98.985274,
        18.954458
      ]
    },
    {
      "id": 30697,
      "name": "Hostotipaquillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0584771,
        "lng": -104.050605
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.2427129,
        20.9361447,
        -103.8563418,
        21.2053611
      ]
    },
    {
      "id": 30705,
      "name": "Santo Tomas de los Platanos",
      "names": {
        "ja": "サントトマスデロスプラタノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.183495,
        "lng": -100.260038
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.265381,
        19.18169,
        -100.254983,
        19.184589
      ]
    },
    {
      "id": 30708,
      "name": "Kiziltepe",
      "names": {
        "zh": "克孜勒泰佩"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.2194458,
        "lng": 42.7025062
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.6825062,
        39.1994458,
        42.7225062,
        39.2394458
      ]
    },
    {
      "id": 30716,
      "name": "Redon",
      "names": {
        "zh": "雷东"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6510682,
        "lng": -2.0842143
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -2.1142919,
        47.6313855,
        -2.0432011,
        47.6796068
      ]
    },
    {
      "id": 30722,
      "name": "Sarkad",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.7422871,
        "lng": 21.3836478
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.2695665,
        46.6938662,
        21.4600066,
        46.8481593
      ]
    },
    {
      "id": 30732,
      "name": "Puerto Narino",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトナリノ",
        "th": "เปอร์โตนาริโน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -3.779943,
        "lng": -70.3639773
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -70.3683329,
        -3.7840746,
        -70.3581657,
        -3.7749642
      ]
    },
    {
      "id": 30738,
      "name": "Argostoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.1756733,
        "lng": 20.4878796
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        20.4478796,
        38.1356733,
        20.5278796,
        38.2156733
      ]
    },
    {
      "id": 30744,
      "name": "Ussel",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.545591,
        "lng": 2.3119397
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.2320108,
        45.5060109,
        2.3558729,
        45.6015481
      ]
    },
    {
      "id": 30749,
      "name": "Vesele",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.0179164,
        "lng": 34.9211323
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.882789,
        46.9938395,
        34.9457888,
        47.030826
      ]
    },
    {
      "id": 30754,
      "name": "Corella",
      "names": {
        "zh": "科雷利亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6873884,
        "lng": 123.9213409
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.8813409,
        9.6473884,
        123.9613409,
        9.7273884
      ]
    },
    {
      "id": 30756,
      "name": "Voitsberg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.0504742,
        "lng": 15.1474771
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.113502,
        47.0194657,
        15.212794,
        47.0894921
      ]
    },
    {
      "id": 30757,
      "name": "Yalaguina",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.485387,
        "lng": -86.4923654
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.5380231,
        13.4208518,
        -86.4318421,
        13.5326468
      ]
    },
    {
      "id": 30768,
      "name": "Gebze",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.80276,
        "lng": 29.43068
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.41068,
        40.78276,
        29.45068,
        40.82276
      ]
    },
    {
      "id": 30770,
      "name": "Idanha-a-Nova",
      "names": {
        "fr": "Idanha-a-nouvelle-",
        "zh": "idanha-一个-的Nova",
        "ja": "idanha・ア・ノヴァ",
        "th": "idanha รูปแบบโนวา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.924397,
        "lng": -7.24244
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.247854,
        39.919622,
        -7.233845,
        39.931726
      ]
    },
    {
      "id": 30782,
      "name": "Imias",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.06936,
        "lng": -74.6314
      },
      "country": "Cuba",
      "importance": 2,
      "bbox": [
        -74.6314,
        20.06936,
        -74.6314,
        20.06936
      ]
    },
    {
      "id": 30840,
      "name": "Farsund",
      "names": {
        "zh": "法尔松"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.0928152,
        "lng": 6.7926069
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.7925569,
        58.0927652,
        6.7926569,
        58.0928652
      ]
    },
    {
      "id": 30848,
      "name": "Tzitzio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.426425700000003,
        "lng": -100.91173081773198
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.1318476,
        19.180511,
        -100.7480518,
        19.6723598
      ]
    },
    {
      "id": 30853,
      "name": "Krasnogvardeyskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.1443556,
        "lng": 39.5861478
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        39.5344559,
        45.0912945,
        39.6202337,
        45.1591664
      ]
    },
    {
      "id": 30872,
      "name": "Fauske",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 67.2588591,
        "lng": 15.3945952
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        15.3545952,
        67.2188591,
        15.4345952,
        67.2988591
      ]
    },
    {
      "id": 30879,
      "name": "Pinhel",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.771672,
        "lng": -7.067959
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.086469,
        40.766598,
        -7.053322,
        40.777953
      ]
    },
    {
      "id": 30885,
      "name": "Mortagua",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3972486,
        "lng": -8.2287169
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3594469,
        40.3226383,
        -8.1576342,
        40.5169624
      ]
    },
    {
      "id": 30893,
      "name": "Uracoa",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.9946921,
        "lng": -62.3512746
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -62.4009973,
        8.8955868,
        -62.2958064,
        9.0180468
      ]
    },
    {
      "id": 30906,
      "name": "Izium",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.22111,
        "lng": 37.31688
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        37.29688,
        49.20111,
        37.33688,
        49.24111
      ]
    },
    {
      "id": 30932,
      "name": "Bilozerka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.630812,
        "lng": 32.444426
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.424426,
        46.610812,
        32.464426,
        46.650812
      ]
    },
    {
      "id": 30940,
      "name": "Mizhhiria",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5281184,
        "lng": 23.5009289
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.48705,
        48.4987436,
        23.5854404,
        48.5590934
      ]
    },
    {
      "id": 30943,
      "name": "Kanchanadit",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 9.1683953,
        "lng": 99.4770707
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.4370707,
        9.1283953,
        99.5170707,
        9.2083953
      ]
    },
    {
      "id": 30960,
      "name": "Cepin",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.5236008,
        "lng": 18.5737213
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.4931191,
        45.4614588,
        18.6227946,
        45.5643168
      ]
    },
    {
      "id": 30972,
      "name": "Vares",
      "names": {
        "zh": "瓦雷什"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.1633731,
        "lng": 18.3285369
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        18.2885369,
        44.1233731,
        18.3685369,
        44.2033731
      ]
    },
    {
      "id": 30977,
      "name": "Lwowek Slaski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1108891,
        "lng": 15.5850018
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        15.5526825,
        51.0941534,
        15.6589749,
        51.1275507
      ]
    },
    {
      "id": 31000,
      "name": "Langnau",
      "names": {
        "zh": "朗瑙"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.9392339,
        "lng": 7.7882673
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        7.7507568,
        46.906751,
        7.8702745,
        47.0025781
      ]
    },
    {
      "id": 31004,
      "name": "Chita",
      "names": {
        "zh": "赤塔",
        "ja": "チタ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.06537305,
        "lng": -72.43155745155386
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.5582959,
        5.8236154,
        -72.3175045,
        6.3065349
      ]
    },
    {
      "id": 31006,
      "name": "Mogadouro",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3418708,
        "lng": -6.7121674
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -6.9209331,
        41.1934381,
        -6.4181474,
        41.4673226
      ]
    },
    {
      "id": 31015,
      "name": "Bang Khla",
      "names": {
        "th": "บางคล้า"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.725256,
        "lng": 101.2056903
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.1656903,
        13.685256,
        101.2456903,
        13.765256
      ]
    },
    {
      "id": 31023,
      "name": "Pohrebyshche",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.48639,
        "lng": 29.26361
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.24361,
        49.46639,
        29.28361,
        49.50639
      ]
    },
    {
      "id": 31031,
      "name": "Luchow",
      "names": {
        "de": "Lüchow"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.9685123,
        "lng": 11.1573647
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.0625549,
        52.9250307,
        11.2595608,
        53.034494
      ]
    },
    {
      "id": 31037,
      "name": "Ecatzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.958488,
        "lng": -98.7520077
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.7920077,
        18.918488,
        -98.7120077,
        18.998488
      ]
    },
    {
      "id": 31046,
      "name": "Pagqen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.968645,
        "lng": 99.900974
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.740974,
        33.808645,
        100.060974,
        34.128645
      ]
    },
    {
      "id": 31056,
      "name": "Belley",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7611925,
        "lng": 5.687648551678052
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.687433,
        45.760984,
        5.687916,
        45.761392
      ]
    },
    {
      "id": 31082,
      "name": "Miltenberg",
      "names": {
        "zh": "米尔滕贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7017584,
        "lng": 9.2559718
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.121201,
        49.653924,
        9.3726728,
        49.7268636
      ]
    },
    {
      "id": 31088,
      "name": "Nyzhnohirskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.498192200000005,
        "lng": 34.77034344888892
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.5281179,
        45.2371763,
        35.1037622,
        45.7589893
      ]
    },
    {
      "id": 31121,
      "name": "Sindos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.66889395,
        "lng": 22.80713682743642
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.793263,
        40.6585252,
        22.8209321,
        40.6788294
      ]
    },
    {
      "id": 31123,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1442998,
        "lng": -75.1844057
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.2333456,
        6.018251,
        -75.059149,
        6.2327742
      ]
    },
    {
      "id": 31130,
      "name": "Comala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.32145475,
        "lng": -103.76113488984049
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.7702123,
        19.3070554,
        -103.7466021,
        19.3365095
      ]
    },
    {
      "id": 31170,
      "name": "Farsala",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.29472,
        "lng": 22.38472
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.28472,
        39.19472,
        22.48472,
        39.39472
      ]
    },
    {
      "id": 31185,
      "name": "Boyabat",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.46889,
        "lng": 34.76667
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.74667,
        41.44889,
        34.78667,
        41.48889
      ]
    },
    {
      "id": 31196,
      "name": "Bulancak",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9389231,
        "lng": 38.2318776
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.9596658,
        40.5225556,
        38.3094246,
        40.946529
      ]
    },
    {
      "id": 31211,
      "name": "Wunsiedel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0373241,
        "lng": 12.0027278
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.9282347,
        49.9877606,
        12.0923836,
        50.0872489
      ]
    },
    {
      "id": 31215,
      "name": "Castelli",
      "names": {
        "zh": "卡斯泰利",
        "ja": "カステッリ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -36.0914272,
        "lng": -57.807336
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -57.8180201,
        -36.1005039,
        -57.7796131,
        -36.07718
      ]
    },
    {
      "id": 31221,
      "name": "Orivesi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.6775495,
        "lng": 24.3587506
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.0784119,
        61.5137244,
        24.9169484,
        61.862981
      ]
    },
    {
      "id": 31226,
      "name": "Spata",
      "names": {
        "zh": "斯帕塔"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.962715,
        "lng": 23.915865
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.895865,
        37.942715,
        23.935865,
        37.982715
      ]
    },
    {
      "id": 31245,
      "name": "Braslaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.6413,
        "lng": 27.0418
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.0218,
        55.6213,
        27.0618,
        55.6613
      ]
    },
    {
      "id": 31254,
      "name": "Narpes",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4729417,
        "lng": 21.3384612
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.3384112,
        62.4728917,
        21.3385112,
        62.4729917
      ]
    },
    {
      "id": 31257,
      "name": "Tysmenytsia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9018102,
        "lng": 24.8484719
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.81297,
        48.88075,
        24.88318,
        48.92332
      ]
    },
    {
      "id": 31276,
      "name": "Eemnes",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2529315,
        "lng": 5.2483692
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.2483192,
        52.2528815,
        5.2484192,
        52.2529815
      ]
    },
    {
      "id": 31281,
      "name": "Irshava",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.31924,
        "lng": 23.0417599
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.0173873,
        48.3024231,
        23.0663323,
        48.334657
      ]
    },
    {
      "id": 31298,
      "name": "Edeleny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.29604,
        "lng": 20.737384
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.715236,
        48.27257,
        20.761021,
        48.312879
      ]
    },
    {
      "id": 31314,
      "name": "Siteia",
      "names": {
        "id": "Sitia"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 35.2064825,
        "lng": 26.1007832
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        26.0992758,
        35.2058674,
        26.1024541,
        35.2067007
      ]
    },
    {
      "id": 31326,
      "name": "Tvrdosin",
      "names": {
        "de": "Tvrdošín"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.3341474,
        "lng": 19.5554346
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.419837,
        49.196331,
        19.822802,
        49.458676
      ]
    },
    {
      "id": 31329,
      "name": "Pesca",
      "names": {
        "zh": "佩斯卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5582793,
        "lng": -73.0514809
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.1632486,
        5.3449058,
        -72.9970511,
        5.6490484
      ]
    },
    {
      "id": 31341,
      "name": "Nagykallo",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8779979,
        "lng": 21.8400497
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.8399997,
        47.8779479,
        21.8400997,
        47.8780479
      ]
    },
    {
      "id": 31343,
      "name": "Khotyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5068418,
        "lng": 26.4859086
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        26.4657864,
        48.4852003,
        26.520109,
        48.5339343
      ]
    },
    {
      "id": 31365,
      "name": "Vila Nova de Cerveira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.93908245,
        "lng": -8.737901544838174
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7542715,
        41.9298207,
        -8.7073822,
        41.9483527
      ]
    },
    {
      "id": 31375,
      "name": "Kaleybar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.86509,
        "lng": 47.03909
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        47.01909,
        38.84509,
        47.05909,
        38.88509
      ]
    },
    {
      "id": 31398,
      "name": "Nakhon Thai",
      "names": {
        "es": "Nakhon tailandés",
        "it": "Nakhon tailandese",
        "ja": "ナコーンタイ",
        "th": "นครไทย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.101282,
        "lng": 100.827559
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.80664488,
        17.084506879,
        100.850154384,
        17.1157023544
      ]
    },
    {
      "id": 31427,
      "name": "Nong Kung Si",
      "names": {
        "th": "หนองกุง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.65,
        "lng": 103.3
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.28,
        16.63,
        103.32,
        16.67
      ]
    },
    {
      "id": 31431,
      "name": "Coapilla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.130789,
        "lng": -93.159555
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.169992,
        17.122371,
        -93.150451,
        17.138934
      ]
    },
    {
      "id": 31438,
      "name": "Didymoteicho",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 41.3492618,
        "lng": 26.4963524
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        26.4563524,
        41.3092618,
        26.5363524,
        41.3892618
      ]
    },
    {
      "id": 31442,
      "name": "Philippeville",
      "names": {
        "zh": "菲利普维尔"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.196268,
        "lng": 4.5436078
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        4.476171,
        50.090652,
        4.7294335,
        50.2314285
      ]
    },
    {
      "id": 31454,
      "name": "Suwannaphum",
      "names": {
        "th": "สุวรรณภูมิ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.600508,
        "lng": 103.802514
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.784009,
        15.56638,
        103.923511,
        15.616204
      ]
    },
    {
      "id": 31459,
      "name": "Pozo Almonte",
      "names": {
        "ja": "ポゾアルモンテ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -20.2597061,
        "lng": -69.7861372
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.1093069,
        -21.6277488,
        -68.8606071,
        -19.9128373
      ]
    },
    {
      "id": 31462,
      "name": "Aljustrel",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.8815018,
        "lng": -8.1675928
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1676428,
        37.8814518,
        -8.1675428,
        37.8815518
      ]
    },
    {
      "id": 31464,
      "name": "Horodenka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.665896,
        "lng": 25.50826
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.499452,
        48.657224,
        25.517846,
        48.675618
      ]
    },
    {
      "id": 31474,
      "name": "Santa Maria del Tule",
      "names": {
        "zh": "圣玛丽亚del Tule",
        "ja": "サンタマリアデルタール"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.046767,
        "lng": -96.634756
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.643093,
        17.033644,
        -96.622327,
        17.05452
      ]
    },
    {
      "id": 31483,
      "name": "Guarare",
      "names": {
        "zh": "瓜拉雷"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 7.8,
        "lng": -80.28333
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 31488,
      "name": "Paredes de Coura",
      "names": {
        "fr": "Pars de Coura",
        "ja": "パデスクーラ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.913389800000004,
        "lng": -8.56396258972716
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6753867,
        41.8443357,
        -8.4885487,
        41.9825714
      ]
    },
    {
      "id": 31504,
      "name": "Melgaco",
      "names": {
        "zh": "梅尔加苏",
        "pt": "Melgaço"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 42.1130412,
        "lng": -8.2593422
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3423331,
        41.9236004,
        -8.0827652,
        42.1543112
      ]
    },
    {
      "id": 31508,
      "name": "Kalanchak",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.2505608,
        "lng": 33.289748
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.289748,
        46.2505208,
        33.2899507,
        46.2505608
      ]
    },
    {
      "id": 31518,
      "name": "Pong Nam Ron",
      "names": {
        "th": "โป่งน้ำรอน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.905445,
        "lng": 102.266227
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.246040915,
        12.8831019681,
        102.462212588,
        12.9332943558
      ]
    },
    {
      "id": 31535,
      "name": "Akyazi",
      "names": {
        "zh": "阿克亚泽"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8993526,
        "lng": 31.2059385
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.1859385,
        40.8793526,
        31.2259385,
        40.9193526
      ]
    },
    {
      "id": 31542,
      "name": "Saint-Claude",
      "names": {
        "zh": "圣克劳德·",
        "ja": "サン・クロード",
        "th": "เซนต์คลอดด์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3874699,
        "lng": 5.8643866
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.7921055,
        46.3449387,
        5.9683072,
        46.4738648
      ]
    },
    {
      "id": 31545,
      "name": "Abdurahmoni Jomi",
      "names": {
        "ja": "アブラフモニjomi",
        "pt": "Abduraahmoni jomi"
      },
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.9491273,
        "lng": 68.8029959
      },
      "country": "Tajikistan",
      "importance": 2,
      "bbox": [
        68.7629959,
        37.9091273,
        68.8429959,
        37.9891273
      ]
    },
    {
      "id": 31561,
      "name": "Paszto",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.920981,
        "lng": 19.698747
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.678061,
        47.907006,
        19.720941,
        47.933212
      ]
    },
    {
      "id": 31573,
      "name": "Yenice",
      "names": {
        "zh": "耶尼杰"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2212539,
        "lng": 33.320798
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.300798,
        41.2012539,
        33.340798,
        41.2412539
      ]
    },
    {
      "id": 31600,
      "name": "Palu",
      "names": {
        "zh": "帕卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6934846,
        "lng": 39.9288811
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.6977137,
        38.514891,
        40.3515814,
        38.8433178
      ]
    },
    {
      "id": 31620,
      "name": "Tibana",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3179886,
        "lng": -73.3966149
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.4366149,
        5.2779886,
        -73.3566149,
        5.3579886
      ]
    },
    {
      "id": 31664,
      "name": "Divrigi",
      "names": {
        "zh": "迪夫里伊"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3713764,
        "lng": 38.1178512
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.6274122,
        39.0622324,
        38.4173869,
        39.6925262
      ]
    },
    {
      "id": 31677,
      "name": "Plon",
      "names": {
        "zh": "普隆",
        "de": "Plön"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.158082,
        "lng": 10.41771
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        10.3303685,
        54.0819613,
        10.4950778,
        54.1796481
      ]
    },
    {
      "id": 31684,
      "name": "Guerrero",
      "names": {
        "zh": "格雷罗",
        "ja": "ゲレーロ州",
        "th": "เกร์เรโร"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.3093253,
        "lng": -100.3805564
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.4205564,
        28.2693253,
        -100.3405564,
        28.3493253
      ]
    },
    {
      "id": 31691,
      "name": "Hish",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.5472858,
        "lng": 36.645112
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.625112,
        35.5272858,
        36.665112,
        35.5672858
      ]
    },
    {
      "id": 31700,
      "name": "Canilla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.16667,
        "lng": -90.85
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.87,
        15.14667,
        -90.83,
        15.18667
      ]
    },
    {
      "id": 31703,
      "name": "San Zenon",
      "names": {
        "es": "San Zenón",
        "zh": "圣泽顿",
        "ja": "サンゼノン",
        "th": "ซานเดนอน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2468063,
        "lng": -74.49739397061089
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.5028601,
        9.2406015,
        -74.4949209,
        9.2528827
      ]
    },
    {
      "id": 31728,
      "name": "Cinarcik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6434005,
        "lng": 29.1193017
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.905832,
        40.5525062,
        29.1933343,
        40.6611129
      ]
    },
    {
      "id": 31738,
      "name": "Coutances",
      "names": {
        "zh": "库唐斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.047792,
        "lng": -1.4454857
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -1.4802012,
        49.0325979,
        -1.4100035,
        49.0777968
      ]
    },
    {
      "id": 31741,
      "name": "Kaeng Khro",
      "names": {
        "th": "แก้งโคร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.10772,
        "lng": 102.25856
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31746,
      "name": "Thung Sai",
      "names": {
        "th": "ทุ่งไส"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.30739,
        "lng": 99.82137
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31770,
      "name": "Tiachiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0118,
        "lng": 23.57061
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.55061,
        47.9918,
        23.59061,
        48.0318
      ]
    },
    {
      "id": 31784,
      "name": "Somero",
      "names": {
        "zh": "神龙"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.6299165,
        "lng": 23.5139982
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.154716,
        60.4673274,
        23.9205482,
        60.77425
      ]
    },
    {
      "id": 31787,
      "name": "Tutrakan",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 44.04596,
        "lng": 26.6162765
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        26.5485952,
        43.9884623,
        26.6908225,
        44.0653572
      ]
    },
    {
      "id": 31790,
      "name": "Kosum Phisai",
      "names": {
        "ja": "コサムピサイ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.24153,
        "lng": 103.06717
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31793,
      "name": "Akat Amnuai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.595983,
        "lng": 103.983972
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.943972,
        17.555983,
        104.023972,
        17.635983
      ]
    },
    {
      "id": 31820,
      "name": "Tha Luang",
      "names": {
        "ja": "洛陽",
        "th": "ท่าหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.0712655,
        "lng": 101.109146
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.069146,
        15.0312655,
        101.149146,
        15.1112655
      ]
    },
    {
      "id": 31821,
      "name": "Herzberg",
      "names": {
        "zh": "赫茨伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6924078,
        "lng": 13.2358585
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.0508856,
        51.5978837,
        13.3344845,
        51.7600245
      ]
    },
    {
      "id": 31822,
      "name": "Siklos",
      "names": {
        "zh": "希克洛什"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 45.8521446,
        "lng": 18.2983821
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.2434246,
        45.8092633,
        18.3488458,
        45.890101
      ]
    },
    {
      "id": 31830,
      "name": "Livno",
      "names": {
        "zh": "利夫诺"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.82695,
        "lng": 17.00746
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        16.98746,
        43.80695,
        17.02746,
        43.84695
      ]
    },
    {
      "id": 31834,
      "name": "Vinhais",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.831897,
        "lng": -7.008819
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.016367,
        41.820988,
        -6.996177,
        41.840095
      ]
    },
    {
      "id": 31845,
      "name": "Muhos",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.8053864,
        "lng": 25.9919312
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.9869312,
        64.8003864,
        25.9969312,
        64.8103864
      ]
    },
    {
      "id": 31846,
      "name": "Chrysoupoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.984699,
        "lng": 24.7025791
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.6625791,
        40.944699,
        24.7425791,
        41.024699
      ]
    },
    {
      "id": 31853,
      "name": "Pa Mok",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.458886,
        "lng": 99.383624
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.383574,
        17.458836,
        99.383674,
        17.458936
      ]
    },
    {
      "id": 31889,
      "name": "Privas",
      "names": {
        "zh": "普里瓦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.7352708,
        "lng": 4.5986733
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.5699449,
        44.6966661,
        4.6197387,
        44.7457123
      ]
    },
    {
      "id": 31896,
      "name": "San Pablo Villa de Mitla",
      "names": {
        "ja": "サンパブロヴィラデMitla",
        "th": "ซานปาโบลวิลล่าเดอมิทลา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.920833,
        "lng": -96.361667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.401667,
        16.880833,
        -96.321667,
        16.960833
      ]
    },
    {
      "id": 31900,
      "name": "Flekkefjord",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.298773,
        "lng": 6.666105
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.635558,
        58.2845,
        6.684179,
        58.315162
      ]
    },
    {
      "id": 31902,
      "name": "Kozova",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4332743,
        "lng": 25.1484661
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.1116959,
        49.3999538,
        25.1985096,
        49.4572906
      ]
    },
    {
      "id": 31930,
      "name": "Thap Than",
      "names": {
        "es": "Thap que",
        "fr": "THAP que",
        "zh": "比",
        "de": "Thap als",
        "it": "THE",
        "id": "Thap daripada",
        "ja": "THAPよりも",
        "th": "ขอบคุณ",
        "pt": "Thap do que"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.4609786,
        "lng": 99.8907866
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8507866,
        15.4209786,
        99.9307866,
        15.5009786
      ]
    },
    {
      "id": 31933,
      "name": "Bolnisi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.4493333,
        "lng": 44.5369461
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        44.5181961,
        41.4425831,
        44.5808144,
        41.458782
      ]
    },
    {
      "id": 31957,
      "name": "Karkkila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5342609,
        "lng": 24.2105252
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.9712644,
        60.4703968,
        24.4648856,
        60.6444422
      ]
    },
    {
      "id": 31976,
      "name": "Szentgotthard",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9528507,
        "lng": 16.2748541
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        16.2302861,
        46.8828635,
        16.3599499,
        47.0140448
      ]
    },
    {
      "id": 31980,
      "name": "Chamical",
      "names": {
        "zh": "查米卡尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.359464,
        "lng": -66.3128166
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.3295247,
        -30.3843075,
        -66.3024833,
        -30.3430253
      ]
    },
    {
      "id": 31987,
      "name": "Tepechitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.670357,
        "lng": -103.326281
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.334961,
        21.65594,
        -103.317324,
        21.67635
      ]
    },
    {
      "id": 32002,
      "name": "Guatape",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2342179,
        "lng": -75.1617452
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.1961776,
        6.1694632,
        -75.116215,
        6.3359927
      ]
    },
    {
      "id": 32003,
      "name": "Sarlat-la-Caneda",
      "names": {
        "es": "Sarlat-la-canéda",
        "fr": "Sarlat-la-canéda",
        "zh": "萨尔拉-的La-caneda",
        "de": "Sarlat-La-canéda",
        "it": "Sarlat-la-canéda",
        "id": "Sarlat-La-canéda",
        "ja": "サルララカネダ",
        "th": "sarlat-ลา-caneda",
        "pt": "Sarlat-la-canéda"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.88902,
        "lng": 1.21656
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.21656,
        44.88902,
        1.21656,
        44.88902
      ]
    },
    {
      "id": 32015,
      "name": "Nuqui",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.707435,
        "lng": -77.2710963
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.3110963,
        5.667435,
        -77.2310963,
        5.747435
      ]
    },
    {
      "id": 32021,
      "name": "Kui Buri",
      "names": {
        "ja": "クイブリ",
        "th": "กุยบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.0680253,
        "lng": 99.8648158
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8248158,
        12.0280253,
        99.9048158,
        12.1080253
      ]
    },
    {
      "id": 32039,
      "name": "Schleiz",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.577409,
        "lng": 11.80898
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        11.693971,
        50.51551,
        11.944137,
        50.606613
      ]
    },
    {
      "id": 32045,
      "name": "Strijen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7428921,
        "lng": 4.5539685
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4833125,
        51.7144152,
        4.6110386,
        51.7871632
      ]
    },
    {
      "id": 32046,
      "name": "Tlumach",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8665866,
        "lng": 25.0011978
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.9633148,
        48.8316713,
        25.0319897,
        48.8803331
      ]
    },
    {
      "id": 32063,
      "name": "Floro",
      "names": {
        "zh": "弗洛罗"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.6009874,
        "lng": 5.0217048
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.0216548,
        61.6009374,
        5.0217548,
        61.6010374
      ]
    },
    {
      "id": 32070,
      "name": "Hanko",
      "names": {
        "zh": "汉科"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 59.8228008,
        "lng": 22.9695005
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.6589293,
        59.6110023,
        23.2951753,
        59.9371773
      ]
    },
    {
      "id": 32082,
      "name": "Kulu",
      "names": {
        "zh": "古卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0903057,
        "lng": 33.0805302
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.0405302,
        39.0503057,
        33.1205302,
        39.1303057
      ]
    },
    {
      "id": 32087,
      "name": "Taxkorgan",
      "names": {
        "zh": "塔什库尔干"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7720982,
        "lng": 75.2298859
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        74.121067,
        35.5948378,
        77.3180338,
        39.0702208
      ]
    },
    {
      "id": 32122,
      "name": "Takua Pa",
      "names": {
        "ja": "タクアPa"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.8652597,
        "lng": 98.33797638733336
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.316699,
        8.8548537,
        98.349949,
        8.8760253
      ]
    },
    {
      "id": 32123,
      "name": "Makhambet",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.66667,
        "lng": 51.58333
      },
      "country": "Kazakhstan",
      "importance": 2,
      "bbox": [
        51.56333,
        47.64667,
        51.60333,
        47.68667
      ]
    },
    {
      "id": 32132,
      "name": "San Jose de Gracia",
      "names": {
        "es": "San José de Gracia",
        "zh": "圣何塞德格拉西亚",
        "ja": "サンノゼ・デグシア",
        "th": "ซานโฮเซ่เดอเกียเซีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.1426267,
        "lng": -102.53013573250765
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.6933053,
        21.9621292,
        -102.3538467,
        22.3232322
      ]
    },
    {
      "id": 32138,
      "name": "Taftanaz",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.99832,
        "lng": 36.78579
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.76579,
        35.97832,
        36.80579,
        36.01832
      ]
    },
    {
      "id": 32144,
      "name": "Alvarado",
      "names": {
        "zh": "阿尔瓦拉多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.567169,
        "lng": -74.9538251
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0762749,
        4.4494119,
        -74.8623767,
        4.7033829
      ]
    },
    {
      "id": 32163,
      "name": "Santa Maria Xadani",
      "names": {
        "zh": "圣玛丽亚Xadani",
        "ja": "サンタマリアXadani",
        "th": "ซานตามาเรียซาดานี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.36902465,
        "lng": -95.01903813558498
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.0885802,
        16.3176124,
        -94.9592422,
        16.4202729
      ]
    },
    {
      "id": 32173,
      "name": "Kamien Pomorski",
      "names": {
        "id": "Kamiien Pomorski"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9661625,
        "lng": 14.7741918
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        14.7691918,
        53.9611625,
        14.7791918,
        53.9711625
      ]
    },
    {
      "id": 32176,
      "name": "Kolbuszowa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2433277,
        "lng": 21.7754563
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        21.7241335,
        50.220178,
        21.8090895,
        50.2518574
      ]
    },
    {
      "id": 32181,
      "name": "Gilgit",
      "names": {
        "zh": "吉尔吉特"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 35.9208102,
        "lng": 74.314044
      },
      "country": "Pakistan",
      "importance": 2,
      "bbox": [
        74.154044,
        35.7608102,
        74.474044,
        36.0808102
      ]
    },
    {
      "id": 32203,
      "name": "Bojaca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.732482,
        "lng": -74.3416742
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3816742,
        4.692482,
        -74.3016742,
        4.772482
      ]
    },
    {
      "id": 32204,
      "name": "Buda-Kashalyova",
      "names": {
        "zh": "布达-kashalyova",
        "ja": "ブダ-kashalyova",
        "th": "บูดา-kashalyova"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.7163311,
        "lng": 30.5650702
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        30.5396747,
        52.7025555,
        30.5948178,
        52.7411794
      ]
    },
    {
      "id": 32229,
      "name": "Pa Sang",
      "names": {
        "es": "Pa cantante",
        "fr": "Pa chanson",
        "it": "Pa cantava",
        "id": "Pa bernyanyi",
        "th": "ป่าซาง",
        "pt": "PA cantou"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.5222909,
        "lng": 98.939963
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.899963,
        18.4822909,
        98.979963,
        18.5622909
      ]
    },
    {
      "id": 32241,
      "name": "Tuzluca",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.0402158,
        "lng": 43.6637878
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        43.2842723,
        39.7709592,
        43.80886,
        40.1315779
      ]
    },
    {
      "id": 32244,
      "name": "Ciudad Cerralvo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7858107,
        "lng": -100.2756534
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.277193,
        25.7858009,
        -100.2739214,
        25.7861503
      ]
    },
    {
      "id": 32258,
      "name": "Phimai",
      "names": {
        "th": "พิมาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2186109,
        "lng": 102.4945756
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.4545756,
        15.1786109,
        102.5345756,
        15.2586109
      ]
    },
    {
      "id": 32263,
      "name": "Zoeterwoude",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.11718945,
        "lng": 4.503459502079346
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.4708259,
        52.0878376,
        4.550733,
        52.1474052
      ]
    },
    {
      "id": 32297,
      "name": "Kryzhopil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.38568,
        "lng": 28.86941
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.8334834,
        48.3657957,
        28.8907337,
        48.4010835
      ]
    },
    {
      "id": 32319,
      "name": "Balatonalmadi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0264334,
        "lng": 18.0214056
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.0213556,
        47.0263834,
        18.0214556,
        47.0264834
      ]
    },
    {
      "id": 32324,
      "name": "Yang Talat",
      "names": {
        "zh": "杨塔拉特",
        "th": "ยางย่าง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4003186,
        "lng": 103.3703038
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.3303038,
        16.3603186,
        103.4103038,
        16.4403186
      ]
    },
    {
      "id": 32327,
      "name": "Nam Som",
      "names": {
        "th": "นัมส้ม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.7817892,
        "lng": 102.1261789
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.9661789,
        17.6217892,
        102.2861789,
        17.9417892
      ]
    },
    {
      "id": 32330,
      "name": "Kuhmo",
      "names": {
        "zh": "库赫莫"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.1261745,
        "lng": 29.519515
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        28.7519604,
        63.7033596,
        30.5535801,
        64.6353735
      ]
    },
    {
      "id": 32336,
      "name": "Iles",
      "names": {
        "zh": "伊莱斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.9698295,
        "lng": -77.5201636
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.5955451,
        0.9445749,
        -77.4613767,
        1.0266947
      ]
    },
    {
      "id": 32339,
      "name": "Bad Zurzach",
      "names": {
        "es": "Mal zurzach",
        "zh": "坏zurzach",
        "id": "Zurzach buruk",
        "ja": "悪いZurzach"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5866477,
        "lng": 8.2940658
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.2796977,
        47.5776911,
        8.3092255,
        47.5969238
      ]
    },
    {
      "id": 32346,
      "name": "Kukrahill",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.2429517,
        "lng": -83.75045509156148
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -83.7592957,
        12.237179,
        -83.7415122,
        12.2486357
      ]
    },
    {
      "id": 32355,
      "name": "Tirschenreuth",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8780234,
        "lng": 12.3372095
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        12.2479281,
        49.8228475,
        12.4505155,
        49.9546368
      ]
    },
    {
      "id": 32362,
      "name": "Zapatoca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.8145963,
        "lng": -73.2680455335182
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.2773575,
        6.8058792,
        -73.2611149,
        6.8237699
      ]
    },
    {
      "id": 32370,
      "name": "Sodankyla",
      "names": {
        "de": "Sodankylä",
        "th": "โซดานกูลา"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 67.4141356,
        "lng": 26.5868879
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.5868379,
        67.4140856,
        26.5869379,
        67.4141856
      ]
    },
    {
      "id": 32421,
      "name": "Vasarosnameny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1290352,
        "lng": 22.3207868
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        22.3207368,
        48.1289852,
        22.3208368,
        48.1290852
      ]
    },
    {
      "id": 32427,
      "name": "Junin",
      "names": {
        "zh": "朱宁"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6899529,
        "lng": -73.71338474610042
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.8042798,
        4.5378712,
        -73.6156619,
        4.8420721
      ]
    },
    {
      "id": 32452,
      "name": "Nakhon Chai Si",
      "names": {
        "ja": "ナコン・チャイ・シリ",
        "th": "นครชัยศรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8019859,
        "lng": 100.1859031
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.1459031,
        13.7619859,
        100.2259031,
        13.8419859
      ]
    },
    {
      "id": 32456,
      "name": "Ogulin",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2658579,
        "lng": 15.2255172
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        15.1855172,
        45.2258579,
        15.2655172,
        45.3058579
      ]
    },
    {
      "id": 32469,
      "name": "Szeghalom",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.021771,
        "lng": 21.168278
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.14369,
        47.008204,
        21.190333,
        47.038745
      ]
    },
    {
      "id": 32471,
      "name": "Tlanalapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.816659,
        "lng": -98.601321
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.614306,
        19.802712,
        -98.591041,
        19.8327
      ]
    },
    {
      "id": 32485,
      "name": "Rakitovo",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.990834,
        "lng": 24.089164
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        24.071626,
        41.97821,
        24.096618,
        42.00431
      ]
    },
    {
      "id": 32494,
      "name": "Perg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2505114,
        "lng": 14.6337684
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.6337184,
        48.2504614,
        14.6338184,
        48.2505614
      ]
    },
    {
      "id": 32499,
      "name": "Szerencs",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1633851,
        "lng": 21.2010881
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.179524,
        48.108843,
        21.2645052,
        48.2090111
      ]
    },
    {
      "id": 32518,
      "name": "Tibro",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.423108,
        "lng": 14.160878
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        14.116301,
        58.400215,
        14.196254,
        58.432682
      ]
    },
    {
      "id": 32548,
      "name": "Chyhyryn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0775098,
        "lng": 32.6664089
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.5993882,
        49.0547065,
        32.7016042,
        49.0990803
      ]
    },
    {
      "id": 32557,
      "name": "El Espinal",
      "names": {
        "de": "El",
        "ja": "エルスピナー"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4846876,
        "lng": -95.040691
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.080691,
        16.4446876,
        -95.000691,
        16.5246876
      ]
    },
    {
      "id": 32578,
      "name": "La Libertad",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 17.6864165,
        "lng": -91.7210861
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.8716411,
        17.4694479,
        -91.6342877,
        17.731786
      ]
    },
    {
      "id": 32585,
      "name": "Ferreira do Zezere",
      "names": {
        "zh": "Ferreira做Zezere",
        "id": "Ferreira melakukan zezere",
        "ja": "フェリーラはゼーゼレをします",
        "th": "Ferreira ทำ Zezere"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6934308,
        "lng": -8.2905545
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4325896,
        39.644263,
        -8.2254151,
        39.8034931
      ]
    },
    {
      "id": 32592,
      "name": "Yesilhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3522777,
        "lng": 35.0886827
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.8469256,
        38.1353436,
        35.3265014,
        38.4862947
      ]
    },
    {
      "id": 32616,
      "name": "Bang Len",
      "names": {
        "zh": "邦琳",
        "de": "Banglu",
        "id": "Lensa",
        "ja": "バンレン",
        "th": "บางเฉิน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0246298,
        "lng": 100.173358
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.133358,
        13.9846298,
        100.213358,
        14.0646298
      ]
    },
    {
      "id": 32618,
      "name": "Usiacuri",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7423787,
        "lng": -74.9778586
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.0477198,
        10.6848357,
        -74.9256226,
        10.7918955
      ]
    },
    {
      "id": 32622,
      "name": "Conkal",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.073949,
        "lng": -89.5200071
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.5600071,
        21.033949,
        -89.4800071,
        21.113949
      ]
    },
    {
      "id": 32639,
      "name": "Goundam",
      "names": {
        "zh": "贡达姆"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 16.41453,
        "lng": -3.67075
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -3.77075,
        16.31453,
        -3.57075,
        16.51453
      ]
    },
    {
      "id": 32646,
      "name": "La Belleza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8588396,
        "lng": -73.9658291
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.1832648,
        5.8295252,
        -73.9080138,
        6.0344505
      ]
    },
    {
      "id": 32667,
      "name": "Simmern",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.98902585,
        "lng": 7.517386437410961
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.5168152,
        49.9886901,
        7.5179365,
        49.9895606
      ]
    },
    {
      "id": 32671,
      "name": "Kosiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.315802,
        "lng": 25.0983417
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        25.0546431,
        48.3011813,
        25.1297021,
        48.33967
      ]
    },
    {
      "id": 32689,
      "name": "Kiuruvesi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.6527545,
        "lng": 26.6196528
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.1652387,
        63.4331316,
        26.9249406,
        63.9639396
      ]
    },
    {
      "id": 32690,
      "name": "Muzo",
      "names": {
        "zh": "木佐"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5321291,
        "lng": -74.1027796
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.1766262,
        5.43279,
        -74.0496649,
        5.6088002
      ]
    },
    {
      "id": 32693,
      "name": "Sawang Daen Din",
      "names": {
        "ja": "Saiang Daen Din"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4747374,
        "lng": 103.4560845
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.4160845,
        17.4347374,
        103.4960845,
        17.5147374
      ]
    },
    {
      "id": 32704,
      "name": "Na Yung",
      "names": {
        "zh": "呐",
        "th": "นายูง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.9312174,
        "lng": 102.207922
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.047922,
        17.7712174,
        102.367922,
        18.0912174
      ]
    },
    {
      "id": 32728,
      "name": "Torre de Moncorvo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.174648,
        "lng": -7.053682
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.062437,
        41.170303,
        -7.036807,
        41.180506
      ]
    },
    {
      "id": 32740,
      "name": "Dogubayazit",
      "names": {
        "zh": "多乌巴亚泽特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5483366,
        "lng": 44.0793593
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        43.5573741,
        39.3336033,
        44.4871135,
        39.8386828
      ]
    },
    {
      "id": 32770,
      "name": "Imuris",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.7874899,
        "lng": -110.8463861
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.8863861,
        30.7474899,
        -110.8063861,
        30.8274899
      ]
    },
    {
      "id": 32771,
      "name": "Sanluri",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 39.560282,
        "lng": 8.899003
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        8.886775,
        39.551231,
        8.908971,
        39.573033
      ]
    },
    {
      "id": 32774,
      "name": "Bang Mun Nak",
      "names": {
        "zh": "邦蒙纳克",
        "id": "Orang bodoh",
        "ja": "バンマンナック",
        "th": "บางกระญษฎร์ธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0285797,
        "lng": 100.381161
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.376161,
        16.0235797,
        100.386161,
        16.0335797
      ]
    },
    {
      "id": 32787,
      "name": "Non Sang",
      "names": {
        "es": "No cantado",
        "fr": "Non chanté",
        "zh": "非唱歌",
        "de": "Nicht sangen",
        "it": "Non cantato",
        "id": "Tak bernyanyi",
        "ja": "歌っていない",
        "th": "ไม่ร้องเพลง",
        "pt": "Não cantou"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8686402,
        "lng": 102.5691417
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.5291417,
        16.8286402,
        102.6091417,
        16.9086402
      ]
    },
    {
      "id": 32799,
      "name": "San Pablo Huitzo",
      "names": {
        "zh": "圣巴勃罗·惠特佐",
        "ja": "サンパブロHuitzo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2777969,
        "lng": -96.8866692
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.9266692,
        17.2377969,
        -96.8466692,
        17.3177969
      ]
    },
    {
      "id": 32803,
      "name": "Dubovskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.412092,
        "lng": 42.758931
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        42.7407226562,
        47.4007759094,
        42.7701377869,
        47.4218330383
      ]
    },
    {
      "id": 32804,
      "name": "Krupki",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.317196,
        "lng": 29.139851
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.1085252,
        54.2912426,
        29.1810339,
        54.3425101
      ]
    },
    {
      "id": 32811,
      "name": "Habo",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 57.9092463,
        "lng": 14.0743562
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        14.0343562,
        57.8692463,
        14.1143562,
        57.9492463
      ]
    },
    {
      "id": 32828,
      "name": "Laitila",
      "names": {
        "zh": "莱蒂拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8801155,
        "lng": 21.6926352
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.4809545,
        60.7384507,
        22.1284803,
        61.034176
      ]
    },
    {
      "id": 32850,
      "name": "Freistadt",
      "names": {
        "zh": "弗赖施塔特"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.5112961,
        "lng": 14.5047566
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.4582987,
        48.4794578,
        14.525522,
        48.5307176
      ]
    },
    {
      "id": 32866,
      "name": "Ouderkerk aan de Amstel",
      "names": {
        "de": "Oüderkerk Aan de Amstel",
        "id": "Ougerkerk Aan de Amstel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.29606905,
        "lng": 4.912666462216944
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.9125067,
        52.2959656,
        4.9128262,
        52.2961725
      ]
    },
    {
      "id": 32894,
      "name": "Lypovets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2296415,
        "lng": 29.0642148
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.0269648,
        49.1829871,
        29.0887262,
        49.2503621
      ]
    },
    {
      "id": 32903,
      "name": "Kemer",
      "names": {
        "zh": "凯梅尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.6013823,
        "lng": 30.5638561
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.3816633,
        36.398088,
        30.5924536,
        36.7726493
      ]
    },
    {
      "id": 32921,
      "name": "Campo Maior",
      "names": {
        "zh": "坎波迈阿里"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0125063,
        "lng": -7.0692822
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.1493566,
        38.9058539,
        -6.9511303,
        39.1183899
      ]
    },
    {
      "id": 32928,
      "name": "Bunyan",
      "names": {
        "zh": "本仁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8458924,
        "lng": 35.8576944
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.6654744,
        38.55115,
        36.1364714,
        39.0251397
      ]
    },
    {
      "id": 32929,
      "name": "Namsos",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 64.4662492,
        "lng": 11.4957765
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.4557765,
        64.4262492,
        11.5357765,
        64.5062492
      ]
    },
    {
      "id": 32933,
      "name": "Kumphawapi",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.110016,
        "lng": 103.0153298
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.9753298,
        17.070016,
        103.0553298,
        17.150016
      ]
    },
    {
      "id": 32935,
      "name": "Derecske",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3539466,
        "lng": 21.5709171
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.4386458,
        47.2740919,
        21.6377286,
        47.3995671
      ]
    },
    {
      "id": 32962,
      "name": "Fethiye",
      "names": {
        "zh": "费特希耶",
        "ja": "フェティエ",
        "th": "เฟทิเย"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.6221019,
        "lng": 29.1153234
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.8814448,
        36.4343941,
        29.3119525,
        36.8675059
      ]
    },
    {
      "id": 32965,
      "name": "Lure",
      "names": {
        "es": "Señuelo",
        "fr": "Attirer",
        "zh": "饵",
        "id": "Memikat",
        "ja": "ルアー",
        "th": "ล่อ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6831492,
        "lng": 6.4925794
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.4875794,
        47.6781492,
        6.4975794,
        47.6881492
      ]
    },
    {
      "id": 32969,
      "name": "Orocue",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.789484,
        "lng": -71.3406351
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.1558771,
        4.5976076,
        -70.9830689,
        5.2243439
      ]
    },
    {
      "id": 32972,
      "name": "Tha Maka",
      "names": {
        "ja": "マカ",
        "th": "ท่ามาคา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.91808,
        "lng": 99.76458
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32999,
      "name": "Bremgarten",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3514557,
        "lng": 8.3423906
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.3009221,
        47.3203425,
        8.3637046,
        47.372198
      ]
    },
    {
      "id": 33009,
      "name": "Muri",
      "names": {
        "zh": "穆里"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.2756613,
        "lng": 8.3377829
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.313876,
        47.2468918,
        8.3631814,
        47.2966425
      ]
    },
    {
      "id": 33018,
      "name": "Guabito",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.4957629,
        "lng": -82.6121137
      },
      "country": "Panama",
      "importance": 2,
      "bbox": [
        -82.6922284,
        9.3916068,
        -82.5497487,
        9.5111504
      ]
    },
    {
      "id": 33021,
      "name": "Vyetka",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.5591,
        "lng": 31.1794
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        31.1594,
        52.5391,
        31.1994,
        52.5791
      ]
    },
    {
      "id": 33022,
      "name": "Tavas",
      "names": {
        "zh": "塔瓦斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5729263,
        "lng": 29.0712601
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.7477666,
        37.1395738,
        29.2557639,
        37.7323198
      ]
    },
    {
      "id": 33038,
      "name": "Kadinhani",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2400504,
        "lng": 32.2122055
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.0223232,
        38.0933552,
        32.3548457,
        38.8511295
      ]
    },
    {
      "id": 33041,
      "name": "U Thong",
      "names": {
        "es": "Tu tanga",
        "fr": "U string",
        "zh": "你丁字裤",
        "de": "U tanga",
        "it": "U perizoma",
        "id": "Kamu thong",
        "ja": "ひも",
        "th": "คุณทอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3763671,
        "lng": 99.8962761
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8562761,
        14.3363671,
        99.9362761,
        14.4163671
      ]
    },
    {
      "id": 33053,
      "name": "Magtymguly",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 38.4363653,
        "lng": 56.2840092
      },
      "country": "Turkmenistan",
      "importance": 2,
      "bbox": [
        56.2621038,
        38.424575,
        56.3160058,
        38.4473035
      ]
    },
    {
      "id": 33063,
      "name": "Phayakkhaphum Phisai",
      "names": {
        "it": "Phisai Phyakkhaphum",
        "th": "พยัคฆภูมิพิสัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5154777,
        "lng": 103.1948473
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.1548473,
        15.4754777,
        103.2348473,
        15.5554777
      ]
    },
    {
      "id": 33067,
      "name": "Kitzbuhel",
      "names": {
        "es": "En Kitzbühel",
        "fr": "Kitzbühel",
        "zh": "基茨比厄尔",
        "de": "Kitzbühel",
        "it": "Kitzbühel",
        "id": "Kitzbühel",
        "pt": "Kitzbühel"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4463585,
        "lng": 12.3911473
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        12.3410862,
        47.3950497,
        12.509142,
        47.4779337
      ]
    },
    {
      "id": 33077,
      "name": "Suluova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8364128,
        "lng": 35.6455569
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.5357306,
        40.6665807,
        35.9379529,
        40.8996212
      ]
    },
    {
      "id": 33099,
      "name": "Keminmaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 65.804342,
        "lng": 24.544198
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.477035,
        65.756823,
        24.609808,
        65.835454
      ]
    },
    {
      "id": 33102,
      "name": "Puerres",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 0.8835077,
        "lng": -77.5036766
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.5436766,
        0.8435077,
        -77.4636766,
        0.9235077
      ]
    },
    {
      "id": 33106,
      "name": "Janoshalma",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2972264,
        "lng": 19.3235909
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.2288381,
        46.197987,
        19.4319734,
        46.3729905
      ]
    },
    {
      "id": 33134,
      "name": "Khanu Woralaksaburi",
      "names": {
        "de": "Khanu Waldaalaksaburi",
        "pt": "Khanu Woraleksaburi"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.071648,
        "lng": 99.860142
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.642146,
        15.92588,
        99.869578,
        16.081247
      ]
    },
    {
      "id": 33143,
      "name": "Chaiwan",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.209844,
        "lng": 103.279282
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.16489,
        17.108478,
        103.450463,
        17.344164
      ]
    },
    {
      "id": 33149,
      "name": "San Miguel del Puerto",
      "names": {
        "ja": "サンミゲルデルプエルト"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.920809,
        "lng": -96.173199
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.178288,
        15.914869,
        -96.167046,
        15.925968
      ]
    },
    {
      "id": 33150,
      "name": "Neusiedl am See",
      "names": {
        "es": "Neusiedl am ver",
        "fr": "Neusiedl suis voir",
        "zh": "neusiedl我看到了",
        "de": "Neussiedl am See",
        "ja": "Neusiedlが見ています",
        "th": "Neusiedl ฉันเห็น",
        "pt": "Neusiedl am veja"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.9491855,
        "lng": 16.8425149
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.766768,
        47.8445925,
        16.9055577,
        47.9885671
      ]
    },
    {
      "id": 33167,
      "name": "Narowlya",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 51.7976497,
        "lng": 29.5068892
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.47555,
        51.7680587,
        29.5248787,
        51.8281351
      ]
    },
    {
      "id": 33176,
      "name": "Domat/Ems",
      "names": {
        "es": "Domat / el ccsme",
        "fr": "Domat / EMS",
        "zh": "多马特/环境管理体系",
        "de": "Domat / Ems",
        "it": "Domat / EMS",
        "id": "Domat / EMS",
        "ja": "domat / EMSの",
        "th": "domat / อีเอ็มเอส",
        "pt": "Domat / EMS"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8331333,
        "lng": 9.4530517
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        9.4530017,
        46.8330833,
        9.4531017,
        46.8331833
      ]
    },
    {
      "id": 33193,
      "name": "Vila Vicosa",
      "names": {
        "pt": "Vila vidosa"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7665132,
        "lng": -7.4302484
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.4302984,
        38.7664632,
        -7.4301984,
        38.7665632
      ]
    },
    {
      "id": 33207,
      "name": "Chinon",
      "names": {
        "zh": "启侬",
        "ja": "シノン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.1667182,
        "lng": 0.2402095
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.1888891,
        47.1308976,
        0.2976345,
        47.2075624
      ]
    },
    {
      "id": 33215,
      "name": "Saint-Jean-de-Maurienne",
      "names": {
        "zh": "圣让 - 去maurienne",
        "ja": "サン・ジャン・ド・maurienne",
        "th": "เซนต์ฌองเดอ maurienne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.2775258,
        "lng": 6.3453329
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        6.3150169,
        45.2442031,
        6.3779297,
        45.3018517
      ]
    },
    {
      "id": 33216,
      "name": "Texmelucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.584073,
        "lng": -97.1994
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.205475,
        16.575393,
        -97.192625,
        16.590809
      ]
    },
    {
      "id": 33225,
      "name": "Tumbiscatio de Ruiz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.525201,
        "lng": -102.375916
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.386296,
        18.517894,
        -102.367138,
        18.53083
      ]
    },
    {
      "id": 33234,
      "name": "Ikata-cho",
      "names": {
        "zh": "ikata町",
        "ja": "ikata町",
        "th": "ikata-โช"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.48833,
        "lng": 132.35417
      },
      "country": "Japan",
      "importance": 2,
      "bbox": [
        132.33417,
        33.46833,
        132.37417,
        33.50833
      ]
    },
    {
      "id": 33239,
      "name": "Thann",
      "names": {
        "zh": "坦恩"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.8090478,
        "lng": 7.1072598
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.1022598,
        47.8040478,
        7.1122598,
        47.8140478
      ]
    },
    {
      "id": 33243,
      "name": "Suomussalmi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.8847582,
        "lng": 28.9145906
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.9955986,
        64.5662981,
        30.135839,
        65.5208639
      ]
    },
    {
      "id": 33247,
      "name": "Proenca-a-Nova",
      "names": {
        "fr": "Proenca-a-nouvelle-",
        "zh": "proenca-一个-的Nova",
        "ja": "proenca・ア・ノヴァ",
        "th": "proenca รูปแบบโนวา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.750614,
        "lng": -7.924509
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.932779,
        39.747401,
        -7.918274,
        39.755961
      ]
    },
    {
      "id": 33248,
      "name": "Kalampaka",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.704177,
        "lng": 21.627684
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.618693,
        39.695615,
        21.636857,
        39.713778
      ]
    },
    {
      "id": 33258,
      "name": "Aratoca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6945729,
        "lng": -73.0187027
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.106543,
        6.6455539,
        -72.9358497,
        6.8233477
      ]
    },
    {
      "id": 33264,
      "name": "Akhalkalaki",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.40514,
        "lng": 43.48629
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        43.46629,
        41.38514,
        43.50629,
        41.42514
      ]
    },
    {
      "id": 33269,
      "name": "Cine",
      "names": {
        "zh": "电影",
        "ja": "映画",
        "th": "ภาพยนตร์"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.6124716,
        "lng": 28.0623096
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.8214642,
        37.4502281,
        28.2527238,
        37.7745145
      ]
    },
    {
      "id": 33273,
      "name": "Chicomuselo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.74296,
        "lng": -92.28599
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.30599,
        15.72296,
        -92.26599,
        15.76296
      ]
    },
    {
      "id": 33292,
      "name": "Bohorodchany",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.807,
        "lng": 24.53834
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.51834,
        48.787,
        24.55834,
        48.827
      ]
    },
    {
      "id": 33308,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.953252,
        "lng": -74.26457303919443
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.3243829,
        4.8708784,
        -74.2155915,
        5.0356006
      ]
    },
    {
      "id": 33310,
      "name": "Chonnabot",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0902139,
        "lng": 102.6205096
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.5805096,
        16.0502139,
        102.6605096,
        16.1302139
      ]
    },
    {
      "id": 33326,
      "name": "Kunszentmiklos",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0257098,
        "lng": 19.1234855
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.0814423,
        46.9079837,
        19.2557171,
        47.0975471
      ]
    },
    {
      "id": 33338,
      "name": "Les Andelys",
      "names": {
        "fr": "Les olles",
        "zh": "谎言",
        "de": "Les Anpelys",
        "ja": "Lesidelys",
        "pt": "Les e,"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2460658,
        "lng": 1.4158565
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.4158065,
        49.2460158,
        1.4159065,
        49.2461158
      ]
    },
    {
      "id": 33343,
      "name": "Uramita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.8993072,
        "lng": -76.1742258
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.2142258,
        6.8593072,
        -76.1342258,
        6.9393072
      ]
    },
    {
      "id": 33357,
      "name": "Hongwansi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8388338,
        "lng": 99.6215209
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.6063604,
        38.8212782,
        99.6446862,
        38.8442474
      ]
    },
    {
      "id": 33358,
      "name": "Liezen",
      "names": {
        "zh": "利岑"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.567685,
        "lng": 14.2420508
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.1447245,
        47.543057,
        14.332955,
        47.652536
      ]
    },
    {
      "id": 33370,
      "name": "Ron Phibun",
      "names": {
        "zh": "罗恩皮亨",
        "ja": "ロンパイブン",
        "th": "รอนพิมพันธ์",
        "pt": "Ron phian"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.1802907,
        "lng": 99.8557206
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.8157206,
        8.1402907,
        99.8957206,
        8.2202907
      ]
    },
    {
      "id": 33375,
      "name": "Septemvri",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.216436,
        "lng": 24.124697
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        24.108704,
        42.196904,
        24.148023,
        42.232195
      ]
    },
    {
      "id": 33386,
      "name": "Tidaholm",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.179266,
        "lng": 13.958882
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        13.928843,
        58.164432,
        13.987442,
        58.193456
      ]
    },
    {
      "id": 33388,
      "name": "Almirante",
      "names": {
        "zh": "海军上将"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.2939457,
        "lng": -82.3959649
      },
      "country": "Panama",
      "importance": 2,
      "bbox": [
        -82.4795306,
        9.2545597,
        -82.3444659,
        9.3607655
      ]
    },
    {
      "id": 33401,
      "name": "Catarina",
      "names": {
        "zh": "卡塔琳娜",
        "th": "ตากาตารินา"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9123659,
        "lng": -86.0741177
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.0928878,
        11.9063688,
        -86.0308819,
        11.9505079
      ]
    },
    {
      "id": 33406,
      "name": "Pudasjarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 65.360401,
        "lng": 26.9984899
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.1532957,
        65.0869899,
        28.1643233,
        65.9604014
      ]
    },
    {
      "id": 33417,
      "name": "Ferreira do Alentejo",
      "names": {
        "zh": "Ferreira做Alentejo",
        "ja": "フェレイラはアレンテージョを行います"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.0585086,
        "lng": -8.1138197
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4096542,
        37.9646679,
        -7.9770614,
        38.2240721
      ]
    },
    {
      "id": 33428,
      "name": "Sawang Wirawong",
      "names": {
        "es": "Sawang WINAWONG",
        "zh": "Sawang Wiwong",
        "ja": "サワン航空",
        "th": "สว่างวีระวงศ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.24025,
        "lng": 105.09592
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33431,
      "name": "Camas",
      "names": {
        "zh": "卡马斯",
        "ja": "カマス",
        "th": "มาส"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4020115,
        "lng": -6.0331571
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        -6.0630697,
        37.3768298,
        -6.0126896,
        37.4238484
      ]
    },
    {
      "id": 33451,
      "name": "Rakkestad",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.36031595,
        "lng": 11.421125079931825
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.1881553,
        59.2363812,
        11.5974159,
        59.4843177
      ]
    },
    {
      "id": 33456,
      "name": "Sibilia",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.994163,
        "lng": -91.624179
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.6788487,
        14.9466263,
        -91.6000975,
        15.0255073
      ]
    },
    {
      "id": 33496,
      "name": "Bruck an der Leitha",
      "names": {
        "zh": "布鲁克and der Leitha",
        "ja": "ブラックアンデルレイザ"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0255094,
        "lng": 16.7790041
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.7006457,
        48.0091874,
        16.8252164,
        48.0515374
      ]
    },
    {
      "id": 33512,
      "name": "Landeck",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1424135,
        "lng": 10.5704571
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        10.526422,
        47.0981755,
        10.6053675,
        47.1566481
      ]
    },
    {
      "id": 33525,
      "name": "Chinique",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0407263,
        "lng": -91.0266104
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.0666104,
        15.0007263,
        -90.9866104,
        15.0807263
      ]
    },
    {
      "id": 33527,
      "name": "Novi Sanzhary",
      "names": {
        "zh": "诺维三宗"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3338321,
        "lng": 34.3148631
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.2924119,
        49.3144784,
        34.3381899,
        49.3541297
      ]
    },
    {
      "id": 33537,
      "name": "Albarradas",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.06752,
        "lng": -96.20012
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.22012,
        17.04752,
        -96.18012,
        17.08752
      ]
    },
    {
      "id": 33539,
      "name": "Motavita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.57655,
        "lng": -73.36696
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.38696,
        5.55655,
        -73.34696,
        5.59655
      ]
    },
    {
      "id": 33550,
      "name": "Kirawsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.2693,
        "lng": 29.4752
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.4552,
        53.2493,
        29.4952,
        53.2893
      ]
    },
    {
      "id": 33561,
      "name": "Loppi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.7173615,
        "lng": 24.4414178
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.0465148,
        60.5524993,
        24.6909516,
        60.8203637
      ]
    },
    {
      "id": 33571,
      "name": "Hokksund",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.77077,
        "lng": 9.90987
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.88987,
        59.75077,
        9.92987,
        59.79077
      ]
    },
    {
      "id": 33575,
      "name": "Uribe",
      "names": {
        "zh": "乌里韦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2413296,
        "lng": -74.3510657
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9339283,
        2.507408,
        -73.9329726,
        3.7875189
      ]
    },
    {
      "id": 33576,
      "name": "Laprida",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.5382156,
        "lng": -60.8119197
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.8140714,
        -37.5399576,
        -60.810812,
        -37.5373447
      ]
    },
    {
      "id": 33577,
      "name": "Bedekovcina",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.0310381,
        "lng": 15.9893996
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        15.9893496,
        46.0309881,
        15.9894496,
        46.0310881
      ]
    },
    {
      "id": 33589,
      "name": "Dong Luang",
      "names": {
        "zh": "董銮",
        "ja": "ドンルアン",
        "th": "ดงหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8185239,
        "lng": 104.5394034
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.3794034,
        16.6585239,
        104.6994034,
        16.9785239
      ]
    },
    {
      "id": 33600,
      "name": "Beeskow",
      "names": {
        "zh": "贝斯科"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1721569,
        "lng": 14.2471058
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.1767723,
        52.1309459,
        14.3775566,
        52.2437052
      ]
    },
    {
      "id": 33639,
      "name": "Gumushacikoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.87306,
        "lng": 35.21472
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.19472,
        40.85306,
        35.23472,
        40.89306
      ]
    },
    {
      "id": 33646,
      "name": "Tarutyne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.184341,
        "lng": 29.148592
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.128592,
        46.164341,
        29.168592,
        46.204341
      ]
    },
    {
      "id": 33664,
      "name": "Daun",
      "names": {
        "zh": "道恩"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.198667,
        "lng": 6.829066
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        6.743931,
        50.159954,
        6.876205,
        50.24622
      ]
    },
    {
      "id": 33671,
      "name": "Fehergyarmat",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9850963,
        "lng": 22.5167524
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        22.4633199,
        47.9516282,
        22.5678224,
        48.041484
      ]
    },
    {
      "id": 33677,
      "name": "Peronne",
      "names": {
        "zh": "佩罗讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.9289578,
        "lng": 2.9321899
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.8944964,
        49.8939198,
        2.9575581,
        49.9585174
      ]
    },
    {
      "id": 33682,
      "name": "Tarouca",
      "names": {
        "zh": "塔罗卡"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.01624,
        "lng": -7.7752378
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8506536,
        40.9542531,
        -7.6597849,
        41.081012
      ]
    },
    {
      "id": 33684,
      "name": "Manhush",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.0568106,
        "lng": 37.3078191
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        37.2854549,
        47.0297182,
        37.3383546,
        47.0741487
      ]
    },
    {
      "id": 33697,
      "name": "Afsin",
      "names": {
        "zh": "阿夫欣"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.249428,
        "lng": 36.914992
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.889638,
        38.222844,
        36.95913,
        38.269539
      ]
    },
    {
      "id": 33703,
      "name": "Toijala",
      "names": {
        "zh": "托伊亚拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1668495,
        "lng": 23.8635191
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.8235191,
        61.1268495,
        23.9035191,
        61.2068495
      ]
    },
    {
      "id": 33704,
      "name": "San Martin Hidalgo",
      "names": {
        "zh": "圣马丁Hidalgo",
        "ja": "サンマーティンヒダルゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4357022,
        "lng": -103.9301557
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.9489941,
        20.4185821,
        -103.9170436,
        20.4505327
      ]
    },
    {
      "id": 33728,
      "name": "Laihia",
      "names": {
        "zh": "莱希亚"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9761064,
        "lng": 22.0122155
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.77208,
        62.751578,
        22.2916657,
        63.0487646
      ]
    },
    {
      "id": 33736,
      "name": "Solosuchiapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3951354,
        "lng": -93.00278627379103
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.0728987,
        17.3004466,
        -92.9295623,
        17.4898414
      ]
    },
    {
      "id": 33747,
      "name": "Kirchheimbolanden",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6644306,
        "lng": 8.0107385
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.9093345,
        49.640755,
        8.0447803,
        49.6930418
      ]
    },
    {
      "id": 33776,
      "name": "Kunszentmarton",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8399054,
        "lng": 20.2908813
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.2219649,
        46.7621434,
        20.4248282,
        46.9374966
      ]
    },
    {
      "id": 33786,
      "name": "Hammerfest",
      "names": {
        "zh": "哈默弗斯特"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 70.6628361,
        "lng": 23.6831069
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        23.6431069,
        70.6228361,
        23.7231069,
        70.7028361
      ]
    },
    {
      "id": 33787,
      "name": "Pedraza",
      "names": {
        "zh": "佩德拉萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.1901696,
        "lng": -74.9141714
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.9455906,
        10.059227,
        -74.6787611,
        10.2385737
      ]
    },
    {
      "id": 33803,
      "name": "Gurjaani",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.74292,
        "lng": 45.80111
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        45.78111,
        41.72292,
        45.82111,
        41.76292
      ]
    },
    {
      "id": 33842,
      "name": "Semenivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 52.17853,
        "lng": 32.57755
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.5401509,
        52.1416944,
        32.6446975,
        52.2037896
      ]
    },
    {
      "id": 33844,
      "name": "Heyin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0461488,
        "lng": 101.4296692
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.2696692,
        35.8861488,
        101.5896692,
        36.2061488
      ]
    },
    {
      "id": 33854,
      "name": "Tamasi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6293978,
        "lng": 18.2850331
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.2218086,
        46.5503578,
        18.3827031,
        46.7237606
      ]
    },
    {
      "id": 33858,
      "name": "Chumsaeng",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.013823,
        "lng": 101.7926678
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.7726678,
        15.993823,
        101.8126678,
        16.033823
      ]
    },
    {
      "id": 33866,
      "name": "Namestovo",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.4095122,
        "lng": 19.4803756
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.1112,
        49.2793777,
        19.596881,
        49.6138162
      ]
    },
    {
      "id": 33890,
      "name": "Langres",
      "names": {
        "zh": "朗格勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.8639612,
        "lng": 5.333947
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.2943015,
        47.8267831,
        5.3785613,
        47.8921439
      ]
    },
    {
      "id": 33895,
      "name": "Simav",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0882,
        "lng": 28.97767
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.95767,
        39.0682,
        28.99767,
        39.1082
      ]
    },
    {
      "id": 33910,
      "name": "Cosala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.4126863,
        "lng": -106.69127
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -106.73127,
        24.3726863,
        -106.65127,
        24.4526863
      ]
    },
    {
      "id": 33917,
      "name": "Cukurca",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4077098,
        "lng": 40.9443949
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.9243949,
        38.3877098,
        40.9643949,
        38.4277098
      ]
    },
    {
      "id": 33920,
      "name": "Ceret",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 42.485804,
        "lng": 2.7488069
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.7230419,
        42.4226147,
        2.7907243,
        42.5233958
      ]
    },
    {
      "id": 33925,
      "name": "Tenampa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.251389,
        "lng": -96.883333
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.923333,
        19.211389,
        -96.843333,
        19.291389
      ]
    },
    {
      "id": 33934,
      "name": "Lagkadas",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.752422,
        "lng": 23.067114
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.047114,
        40.732422,
        23.087114,
        40.772422
      ]
    },
    {
      "id": 33948,
      "name": "Puerto Parra",
      "names": {
        "ja": "プエルトパララ",
        "th": "เปอร์โตริโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.65149,
        "lng": -74.05734
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.07734,
        6.63149,
        -74.03734,
        6.67149
      ]
    },
    {
      "id": 33950,
      "name": "Penalva do Castelo",
      "names": {
        "zh": "Bargva做Castelo",
        "de": "Strapeva do castelo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.67436,
        "lng": -7.69997
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.7839685,
        40.6241848,
        -7.5351418,
        40.7264785
      ]
    },
    {
      "id": 33970,
      "name": "Nurmes",
      "names": {
        "zh": "努尔梅斯"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.5411328,
        "lng": 29.1375745
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.1325745,
        63.5361328,
        29.1425745,
        63.5461328
      ]
    },
    {
      "id": 33972,
      "name": "Helvecia",
      "names": {
        "zh": "埃尔韦西亚"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.1017987,
        "lng": -60.0888941
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -60.3679519,
        -31.186749,
        -59.8531157,
        -30.9643554
      ]
    },
    {
      "id": 33986,
      "name": "Almyros",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.1807614,
        "lng": 22.7609792
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.7209792,
        39.1407614,
        22.8009792,
        39.2207614
      ]
    },
    {
      "id": 33997,
      "name": "Bahsili",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.813252,
        "lng": 33.4717917
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.1860925,
        39.6222678,
        33.4896956,
        39.8225327
      ]
    },
    {
      "id": 34001,
      "name": "Lillesand",
      "names": {
        "zh": "利勒桑"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.2489597,
        "lng": 8.3779203
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        8.3379203,
        58.2089597,
        8.4179203,
        58.2889597
      ]
    },
    {
      "id": 34020,
      "name": "Krupina",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3527285,
        "lng": 19.069182
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.798402,
        48.156522,
        19.233811,
        48.440725
      ]
    },
    {
      "id": 34032,
      "name": "Koufalia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.779629,
        "lng": 22.575896
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.555896,
        40.759629,
        22.595896,
        40.799629
      ]
    },
    {
      "id": 34059,
      "name": "Bosanski Petrovac",
      "names": {
        "ja": "ボサンスキペトロバック"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.5543706,
        "lng": 16.3686978
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        16.3286978,
        44.5143706,
        16.4086978,
        44.5943706
      ]
    },
    {
      "id": 34063,
      "name": "Tonila",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4294093,
        "lng": -103.5300375376384
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.6182765,
        19.3379452,
        -103.4588442,
        19.5206062
      ]
    },
    {
      "id": 34064,
      "name": "San Luis de Palenque",
      "names": {
        "zh": "圣路易斯德帕伦克",
        "ja": "サンルイスパレネック",
        "th": "ซานหลุยส์เดอปาลุค"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.422039,
        "lng": -71.7314386
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.1194301,
        5.0052965,
        -70.9095733,
        5.5551675
      ]
    },
    {
      "id": 34087,
      "name": "Bogazliyan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.191259,
        "lng": 35.2462844
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.9879056,
        38.9860247,
        35.5055622,
        39.5022855
      ]
    },
    {
      "id": 34090,
      "name": "Slawharad",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.442223,
        "lng": 30.994602
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        30.9741275,
        53.4210666,
        31.0202573,
        53.4618098
      ]
    },
    {
      "id": 34094,
      "name": "Mrkonjic Grad",
      "names": {
        "es": "Graduado mrkonjic",
        "id": "Grad Mrkonjic",
        "pt": "Graduado mrkonjic"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.41729,
        "lng": 17.08288
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.06288,
        44.39729,
        17.10288,
        44.43729
      ]
    },
    {
      "id": 34117,
      "name": "Nasice",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4907759,
        "lng": 18.0938075
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.0663262,
        45.4599952,
        18.13026,
        45.5250742
      ]
    },
    {
      "id": 34118,
      "name": "Rethel",
      "names": {
        "zh": "雷泰尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.5091447,
        "lng": 4.3666467
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.337791,
        49.491325,
        4.424387,
        49.538346
      ]
    },
    {
      "id": 34123,
      "name": "Cherykaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.5697773,
        "lng": 31.3804512
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        31.3509402,
        53.5494349,
        31.4221046,
        53.5939296
      ]
    },
    {
      "id": 34139,
      "name": "Langon",
      "names": {
        "zh": "朗贡"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5530659,
        "lng": -0.2449274
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.273521,
        44.5106237,
        -0.2103206,
        44.5602946
      ]
    },
    {
      "id": 34161,
      "name": "Sao Joao da Pesqueira",
      "names": {
        "pt": "São João da Pesqueira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.147872,
        "lng": -7.4038806
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.5557446,
        40.9993738,
        -7.304573,
        41.2146579
      ]
    },
    {
      "id": 34181,
      "name": "San Julian",
      "names": {
        "zh": "圣朱利安",
        "it": "San Giuliano",
        "ja": "サンジュリアン",
        "th": "ซานจูเลียน",
        "pt": "San juliano"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -17.14634975,
        "lng": -144.3481775735695
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -144.6204842,
        -17.3788566,
        -144.0927709,
        -16.9064733
      ]
    },
    {
      "id": 34182,
      "name": "Jalasjarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.488167,
        "lng": 22.758375
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.722932,
        62.476742,
        22.79103,
        62.508527
      ]
    },
    {
      "id": 34219,
      "name": "Alacam",
      "names": {
        "zh": "阿拉恰姆"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.6068635,
        "lng": 35.5973316
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.3950628,
        41.2685528,
        35.786346,
        41.672604
      ]
    },
    {
      "id": 34228,
      "name": "Mynamaki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.680074,
        "lng": 21.979983
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.962924,
        60.656376,
        22.038328,
        60.696367
      ]
    },
    {
      "id": 34244,
      "name": "Damnoen Saduak",
      "names": {
        "id": "Sialan saduak",
        "ja": "ダムノンサドゥック"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5190148,
        "lng": 99.959346
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.959296,
        13.5189648,
        99.959396,
        13.5190648
      ]
    },
    {
      "id": 34251,
      "name": "Thung Saliam",
      "names": {
        "fr": "Thung Saluam",
        "th": "ทุ่งเสลี่ยม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.3173594,
        "lng": 99.5530996
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.5130996,
        17.2773594,
        99.5930996,
        17.3573594
      ]
    },
    {
      "id": 34256,
      "name": "Akcaabat",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0216276,
        "lng": 39.5706735
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.3935092,
        40.8156371,
        39.6436531,
        41.1017358
      ]
    },
    {
      "id": 34257,
      "name": "Khondab",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.3933112,
        "lng": 49.1840004
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.1762908,
        34.3787806,
        49.2044613,
        34.4106096
      ]
    },
    {
      "id": 34259,
      "name": "Myory",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.622227,
        "lng": 27.615358
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.5915005,
        55.6066896,
        27.6799604,
        55.6360712
      ]
    },
    {
      "id": 34279,
      "name": "Toprakkale",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.067132,
        "lng": 36.1460016
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.0554494,
        37.0032477,
        36.1815169,
        37.128779
      ]
    },
    {
      "id": 34283,
      "name": "Rohatyn",
      "names": {
        "zh": "罗哈廷"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4092703,
        "lng": 24.6105811
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.5899488,
        49.3823434,
        24.6384198,
        49.4296194
      ]
    },
    {
      "id": 34284,
      "name": "Paratebueno",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3765197,
        "lng": -73.2125493
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.4302971,
        4.2149191,
        -73.0508345,
        4.7412587
      ]
    },
    {
      "id": 34317,
      "name": "Khon Buri",
      "names": {
        "zh": "凯武山",
        "th": "ขอนบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.524809,
        "lng": 102.242694
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.147100319,
        14.484158432,
        102.352412492,
        14.5766648046
      ]
    },
    {
      "id": 34326,
      "name": "Soeng Sang",
      "names": {
        "es": "SOGG SANG",
        "zh": "唱桑",
        "it": "Soeng cantava",
        "id": "Sung",
        "ja": "浴衣",
        "th": "จิงซัง",
        "pt": "Soeng cantou"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4264637,
        "lng": 102.4653796
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.4253796,
        14.3864637,
        102.5053796,
        14.4664637
      ]
    },
    {
      "id": 34346,
      "name": "Beloslav",
      "names": {
        "zh": "贝洛斯拉夫"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.1905492,
        "lng": 27.7048397
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        27.627199,
        43.14367,
        27.747686,
        43.216345
      ]
    },
    {
      "id": 34348,
      "name": "Kusadasi",
      "names": {
        "zh": "库萨达斯",
        "ja": "クシャダス",
        "th": "คูซาดาซี"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.85562,
        "lng": 27.2566
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.2366,
        37.83562,
        27.2766,
        37.87562
      ]
    },
    {
      "id": 34362,
      "name": "Ban Phue",
      "names": {
        "es": "Prohibición de fue",
        "fr": "Interdire la phue",
        "zh": "禁止噬菌体",
        "de": "Banphone",
        "it": "Bandito",
        "id": "Larangan phue",
        "ja": "音節を禁止します",
        "th": "บ้านผือ",
        "pt": "Proibir phue"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.6888435,
        "lng": 102.4645617
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.4245617,
        17.6488435,
        102.5045617,
        17.7288435
      ]
    },
    {
      "id": 34368,
      "name": "Santana",
      "names": {
        "zh": "桑塔纳",
        "ja": "サンタナ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -12.979217,
        "lng": -44.05064
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -44.2630277,
        -13.3194945,
        -43.6319445,
        -12.8414239
      ]
    },
    {
      "id": 34386,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 13.9947753,
        "lng": -89.5566069
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.6527771,
        13.8115942,
        -89.3835911,
        14.1326703
      ]
    },
    {
      "id": 34390,
      "name": "Chaiya",
      "names": {
        "th": "ไชยา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.3858077,
        "lng": 99.1964728
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.1564728,
        9.3458077,
        99.2364728,
        9.4258077
      ]
    },
    {
      "id": 34431,
      "name": "Alpiarca",
      "names": {
        "zh": "阿尔皮亚萨"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2599352,
        "lng": -8.5845144
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.6340095,
        39.171476,
        -8.4927088,
        39.3061577
      ]
    },
    {
      "id": 34433,
      "name": "Yerkoy",
      "names": {
        "zh": "耶尔柯伊"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6413341,
        "lng": 34.4682658
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.0606553,
        39.4400733,
        34.7230999,
        39.9719976
      ]
    },
    {
      "id": 34437,
      "name": "Lodeve",
      "names": {
        "fr": "Lodève"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.73106,
        "lng": 3.31892
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.2522289,
        43.6942573,
        3.3424113,
        43.7639663
      ]
    },
    {
      "id": 34438,
      "name": "Kinik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0871916,
        "lng": 27.38349
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.2214174,
        38.922725,
        27.5638497,
        39.1647119
      ]
    },
    {
      "id": 34440,
      "name": "Oberwart",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.28823,
        "lng": 16.207573
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.184221,
        47.268787,
        16.242627,
        47.301836
      ]
    },
    {
      "id": 34455,
      "name": "Santa Rosa de Rio Primero",
      "names": {
        "fr": "Père Santa Rosa de Rio Primero",
        "zh": "圣罗莎德里奥·菲利亚",
        "ja": "サンタローザデリオプリオロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.15347765,
        "lng": -63.39938111313559
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.4001035,
        -31.1541385,
        -63.3986709,
        -31.1528273
      ]
    },
    {
      "id": 34457,
      "name": "Kemijarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 66.7161179,
        "lng": 27.4333534
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.4666205,
        66.289073,
        28.1916531,
        67.0430311
      ]
    },
    {
      "id": 34475,
      "name": "San Diego",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンディエゴ",
        "th": "ซานดิเอโก"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 27.7639145,
        "lng": -98.2388953
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -98.2506364,
        27.7469446,
        -98.2208414,
        27.7745446
      ]
    },
    {
      "id": 34487,
      "name": "Nyiradony",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.691002,
        "lng": 21.906112
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.890769,
        47.675989,
        21.927027,
        47.705103
      ]
    },
    {
      "id": 34499,
      "name": "Neufchateau",
      "names": {
        "es": "Neufchâteau",
        "zh": "讷沙托",
        "de": "Neufchâteau",
        "it": "Neufchâteau"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 49.840802100000005,
        "lng": 5.43505771134108
      },
      "country": "Belgium",
      "importance": 2,
      "bbox": [
        5.3149581,
        49.7788658,
        5.5294192,
        49.9030318
      ]
    },
    {
      "id": 34502,
      "name": "Kryve Ozero",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9532217,
        "lng": 30.3425878
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.2851075,
        47.9340135,
        30.3714242,
        47.9822856
      ]
    },
    {
      "id": 34535,
      "name": "Celorico da Beira",
      "names": {
        "fr": "Célorico da Beira",
        "zh": "Celorico达贝拉",
        "id": "Celori da beira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6358841,
        "lng": -7.3928446
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.511261,
        40.5151841,
        -7.2553643,
        40.7092869
      ]
    },
    {
      "id": 34542,
      "name": "Trostianets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.51227,
        "lng": 29.2226589
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.1826589,
        48.47227,
        29.2626589,
        48.55227
      ]
    },
    {
      "id": 34560,
      "name": "Nusaybin",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0778,
        "lng": 41.2178
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.1978,
        37.0578,
        41.2378,
        37.0978
      ]
    },
    {
      "id": 34561,
      "name": "Bang Pa-in",
      "names": {
        "zh": "爆炸pa-in",
        "de": "Bang-Pa-in",
        "id": "Bang",
        "ja": "バンパイイン",
        "th": "ปังป่า"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.2264014,
        "lng": 100.5769264
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.5701262,
        14.2139065,
        100.5855722,
        14.2441257
      ]
    },
    {
      "id": 34570,
      "name": "Villa Diaz Ordaz",
      "names": {
        "es": "Villa Díaz Ordaz"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.998056,
        "lng": -96.431389
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.471389,
        16.958056,
        -96.391389,
        17.038056
      ]
    },
    {
      "id": 34603,
      "name": "Novoselytsia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2179594,
        "lng": 26.2664831
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        26.2411827,
        48.2109535,
        26.2908699,
        48.2358168
      ]
    },
    {
      "id": 34608,
      "name": "Chapultenango",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.33581795,
        "lng": -93.13150209897267
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.2160234,
        17.261965,
        -93.0438375,
        17.4095182
      ]
    },
    {
      "id": 34611,
      "name": "Dykanka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8227778,
        "lng": 34.5386111
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.4850572,
        49.7989004,
        34.5709438,
        49.8464165
      ]
    },
    {
      "id": 34644,
      "name": "San Miguel Siguila",
      "names": {
        "fr": "SIGUIL SIGUILA SAN MIGUEL"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9,
        "lng": -91.61667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.63667,
        14.88,
        -91.59667,
        14.92
      ]
    },
    {
      "id": 34649,
      "name": "Coromoro",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.29461,
        "lng": -73.04022
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.06022,
        6.27461,
        -73.02022,
        6.31461
      ]
    },
    {
      "id": 34655,
      "name": "Lice",
      "names": {
        "es": "Piojos",
        "fr": "Les Poux",
        "zh": "虱子",
        "de": "Läuse",
        "it": "Pidocchi",
        "id": "Kutu",
        "ja": "シラミ",
        "th": "เหา",
        "pt": "Piolhos"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4603127,
        "lng": 40.6476876
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.4314219,
        38.3075833,
        40.911767,
        38.62052
      ]
    },
    {
      "id": 34660,
      "name": "Zara",
      "names": {
        "zh": "扎拉",
        "ja": "ザラ",
        "th": "ซาร่า"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8972011,
        "lng": 37.759073
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.4738501,
        39.4383806,
        38.0829047,
        40.2195193
      ]
    },
    {
      "id": 34667,
      "name": "Al Mazra`ah",
      "names": {
        "de": "Al Mazra",
        "it": "Al mazra`",
        "ja": "アルマツラワ",
        "pt": "Al Mazra`"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.7825337,
        "lng": 36.4780728
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.4580728,
        32.7625337,
        36.4980728,
        32.8025337
      ]
    },
    {
      "id": 34710,
      "name": "Solone",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.20423,
        "lng": 34.87713
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.8306102,
        48.1899448,
        34.899399,
        48.2243918
      ]
    },
    {
      "id": 34718,
      "name": "Loten",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.8197419,
        "lng": 11.3420623
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.3220623,
        60.7997419,
        11.3620623,
        60.8397419
      ]
    },
    {
      "id": 34725,
      "name": "Dzilam Gonzalez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2806675,
        "lng": -88.9301511
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -88.940829,
        21.266948,
        -88.9195679,
        21.2904312
      ]
    },
    {
      "id": 34732,
      "name": "Porec",
      "names": {
        "zh": "波雷奇",
        "ja": "ポレチュ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2271961,
        "lng": 13.5957333
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        13.567762,
        45.1615033,
        13.7873142,
        45.2779306
      ]
    },
    {
      "id": 34743,
      "name": "Rade",
      "names": {
        "zh": "贸易"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.3474237,
        "lng": 10.8654182
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.8604182,
        59.3424237,
        10.8704182,
        59.3524237
      ]
    },
    {
      "id": 34769,
      "name": "Oulainen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.265088,
        "lng": 24.820217
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.754356,
        64.247978,
        24.919313,
        64.282913
      ]
    },
    {
      "id": 34770,
      "name": "Gaoual",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 11.754096,
        "lng": -13.200333
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -13.2191215,
        11.7369941,
        -13.1902029,
        11.7774595
      ]
    },
    {
      "id": 34777,
      "name": "Sykkylven",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.3928574,
        "lng": 6.5807235
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.5607235,
        62.3728574,
        6.6007235,
        62.4128574
      ]
    },
    {
      "id": 34784,
      "name": "Hang Dong",
      "names": {
        "es": "Colgar dong",
        "fr": "Pendre",
        "zh": "挂东",
        "id": "Menggantung",
        "ja": "ぶらぶら",
        "th": "ดง",
        "pt": "Pendurar dong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.6856866,
        "lng": 98.9192218
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.8792218,
        18.6456866,
        98.9592218,
        18.7256866
      ]
    },
    {
      "id": 34790,
      "name": "Mattersburg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.7368423,
        "lng": 16.3980488
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        16.3090394,
        47.678524,
        16.440122,
        47.7632919
      ]
    },
    {
      "id": 34794,
      "name": "Kokemaki",
      "names": {
        "zh": "科凯迈基"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2513342,
        "lng": 22.3492421
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.1140878,
        61.170631,
        22.6099554,
        61.4889232
      ]
    },
    {
      "id": 34795,
      "name": "Tarso",
      "names": {
        "zh": "塔尔索"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.864064,
        "lng": -75.8217932
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8874037,
        5.809918,
        -75.7583472,
        5.9386638
      ]
    },
    {
      "id": 34801,
      "name": "Stromstad",
      "names": {
        "zh": "斯特伦斯塔德",
        "it": "Strömstad",
        "th": "สตรอมสตัด"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.940627,
        "lng": 11.177587
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        11.166529,
        58.927635,
        11.217065,
        58.95634
      ]
    },
    {
      "id": 34813,
      "name": "Omitlan de Juarez",
      "names": {
        "es": "Omitlan de Juárez",
        "th": "ออมทองเดอ Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.169016,
        "lng": -98.648338
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.656588278,
        20.1621084952,
        -98.6416012879,
        20.1770407153
      ]
    },
    {
      "id": 34819,
      "name": "Ambalema",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7835866,
        "lng": -74.7643927
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.8898187,
        4.7125457,
        -74.7294545,
        4.947019
      ]
    },
    {
      "id": 34852,
      "name": "Uthumphon Phisai",
      "names": {
        "de": "Uthumhon phisai",
        "ja": "ウスモンピサイ",
        "th": "อุทุมีย์พิสัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1059962,
        "lng": 104.1404286
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.1354286,
        15.1009962,
        104.1454286,
        15.1109962
      ]
    },
    {
      "id": 34870,
      "name": "Nykarleby",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.5222473,
        "lng": 22.5284347
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.5994992,
        63.282048,
        22.9806989,
        63.7599523
      ]
    },
    {
      "id": 34876,
      "name": "Kunhegyes",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3699089,
        "lng": 20.6318292
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.5427034,
        47.272765,
        20.7696006,
        47.4231165
      ]
    },
    {
      "id": 34884,
      "name": "Bereznehuvate",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.308722,
        "lng": 32.8486739
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.8268237,
        47.2892181,
        32.8765216,
        47.3263051
      ]
    },
    {
      "id": 34895,
      "name": "Nong Phai",
      "names": {
        "zh": "作响",
        "th": "หนองไผ่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9888238,
        "lng": 101.0634266
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.0234266,
        15.9488238,
        101.1034266,
        16.0288238
      ]
    },
    {
      "id": 34926,
      "name": "Kruhlaye",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.2484021,
        "lng": 29.7971582
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.7595911,
        54.2295906,
        29.8222003,
        54.2758677
      ]
    },
    {
      "id": 34937,
      "name": "Mondim de Basto",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.403339,
        "lng": -7.947090188751002
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.9811486,
        41.3763978,
        -7.9192678,
        41.4309011
      ]
    },
    {
      "id": 34944,
      "name": "Mapimi",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8337403,
        "lng": -103.8480216
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.8880216,
        25.7937403,
        -103.8080216,
        25.8737403
      ]
    },
    {
      "id": 34958,
      "name": "Roshtkhvar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.97512,
        "lng": 59.62482
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        59.60482,
        34.95512,
        59.64482,
        34.99512
      ]
    },
    {
      "id": 34965,
      "name": "Maripi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5495156,
        "lng": -74.0046921
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.0958128,
        5.4963551,
        -73.9098641,
        5.6498163
      ]
    },
    {
      "id": 34976,
      "name": "Phu Kradueng",
      "names": {
        "fr": "Phu KraduNeng",
        "th": "ภูกระ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.88341,
        "lng": 101.882174
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.773459,
        16.874374,
        101.902078,
        16.902944
      ]
    },
    {
      "id": 34989,
      "name": "Drvar",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.37389,
        "lng": 16.38083
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        16.36083,
        44.35389,
        16.40083,
        44.39389
      ]
    },
    {
      "id": 35000,
      "name": "Aybasti",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6846234,
        "lng": 37.398808
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.2603331,
        40.5440289,
        37.4807998,
        40.7452014
      ]
    },
    {
      "id": 35012,
      "name": "Gediz",
      "names": {
        "zh": "盖迪兹"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.993429,
        "lng": 29.3911585
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.1808127,
        38.7910716,
        29.75674,
        39.1835203
      ]
    },
    {
      "id": 35039,
      "name": "Miranda do Douro",
      "names": {
        "zh": "米兰达做杜罗",
        "de": "Miranda do douro do douro",
        "th": "มิแรนดาโดดูโร"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5000816,
        "lng": -6.271331
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -6.5325237,
        41.3435192,
        -6.1891593,
        41.6764053
      ]
    },
    {
      "id": 35041,
      "name": "Birkenfeld",
      "names": {
        "zh": "比肯费尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6488236,
        "lng": 7.1647477
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.128322,
        49.6217812,
        7.2029556,
        49.6701429
      ]
    },
    {
      "id": 35072,
      "name": "Krasnokutsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.059815,
        "lng": 35.137157
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.856359,
        49.851658,
        35.423299,
        50.242506
      ]
    },
    {
      "id": 35074,
      "name": "Beysehir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.6754536,
        "lng": 31.7269088
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.2729488,
        37.4310739,
        31.9878611,
        38.0201707
      ]
    },
    {
      "id": 35089,
      "name": "Anna Paulowna",
      "names": {
        "zh": "安娜波洛纳",
        "ja": "アンナパウロウナ",
        "pt": "Anna Paulooda"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.860219,
        "lng": 4.8245885
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.7588553,
        52.83263,
        4.9097714,
        52.8886672
      ]
    },
    {
      "id": 35105,
      "name": "Kriz",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6632061,
        "lng": 16.5204571
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.4892815,
        45.6409012,
        16.533317,
        45.6772185
      ]
    },
    {
      "id": 35106,
      "name": "Vila Nova da Barquinha",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4616121,
        "lng": -8.4352411
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4402411,
        39.4566121,
        -8.4302411,
        39.4666121
      ]
    },
    {
      "id": 35114,
      "name": "Ra-ngae",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.2973512,
        "lng": 101.7269182
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.7268682,
        6.2973012,
        101.7269682,
        6.2974012
      ]
    },
    {
      "id": 35115,
      "name": "Nisa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 43.7009358,
        "lng": 7.2683912
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        7.1819535,
        43.6454189,
        7.323912,
        43.7607635
      ]
    },
    {
      "id": 35127,
      "name": "Almodovar",
      "names": {
        "zh": "瓦",
        "pt": "Almodóvar"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.511607,
        "lng": -8.0603168
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2757788,
        37.3189368,
        -7.8865415,
        37.631266
      ]
    },
    {
      "id": 35140,
      "name": "Ten Boer",
      "names": {
        "es": "Diez boer",
        "fr": "Dix boer",
        "zh": "十个布尔",
        "de": "Zehn Bur",
        "it": "Dieci boer",
        "id": "Sepuluh boer",
        "ja": "10ベア",
        "th": "สิบเออร์",
        "pt": "Dez boer"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2773154,
        "lng": 6.6951182
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        6.6669489,
        53.252819,
        6.7224753,
        53.294704
      ]
    },
    {
      "id": 35149,
      "name": "Haapajarvi",
      "names": {
        "zh": "哈帕耶尔维"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.7514785,
        "lng": 25.3134586
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.9555567,
        63.5814759,
        25.667808,
        63.9349571
      ]
    },
    {
      "id": 35150,
      "name": "Sivrihisar",
      "names": {
        "zh": "锡夫里希萨尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.4510335,
        "lng": 31.5367967
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.1346344,
        39.0402632,
        31.9735193,
        39.7124244
      ]
    },
    {
      "id": 35153,
      "name": "Lenti",
      "names": {
        "zh": "伦蒂"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6247617,
        "lng": 16.541483
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        16.541433,
        46.6247117,
        16.541533,
        46.6248117
      ]
    },
    {
      "id": 35163,
      "name": "Tsarychanka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.949867,
        "lng": 34.469815
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.449815,
        48.929867,
        34.489815,
        48.969867
      ]
    },
    {
      "id": 35172,
      "name": "Tha Ruea",
      "names": {
        "es": "Thauea",
        "it": "Tu",
        "ja": "ruea",
        "th": "ท่าเรือ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5607428,
        "lng": 100.7221694
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.7133383,
        14.5580371,
        100.7325643,
        14.5720682
      ]
    },
    {
      "id": 35238,
      "name": "Fuzesabony",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7512151,
        "lng": 20.4144571
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.4094571,
        47.7462151,
        20.4194571,
        47.7562151
      ]
    },
    {
      "id": 35242,
      "name": "Sa Bot",
      "names": {
        "ja": "SAボット",
        "th": "บอท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2102908,
        "lng": 100.8290806
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.7890806,
        15.1702908,
        100.8690806,
        15.2502908
      ]
    },
    {
      "id": 35256,
      "name": "Elassona",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.89472,
        "lng": 22.18861
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.16861,
        39.87472,
        22.20861,
        39.91472
      ]
    },
    {
      "id": 35258,
      "name": "Serik",
      "names": {
        "zh": "谢里克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.91694,
        "lng": 31.09889
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.07889,
        36.89694,
        31.11889,
        36.93694
      ]
    },
    {
      "id": 35282,
      "name": "Suonenjoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6241926,
        "lng": 27.1245609
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.8266948,
        62.4423623,
        27.5399259,
        62.849947
      ]
    },
    {
      "id": 35284,
      "name": "Simacota",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4435088,
        "lng": -73.3374205
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.3774205,
        6.4035088,
        -73.2974205,
        6.4835088
      ]
    },
    {
      "id": 35310,
      "name": "Rosales",
      "names": {
        "zh": "罗萨莱斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.1877797,
        "lng": -105.5560715
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.5960715,
        28.1477797,
        -105.5160715,
        28.2277797
      ]
    },
    {
      "id": 35315,
      "name": "Na Chaluai",
      "names": {
        "ja": "ナルアイ",
        "th": "นาชาว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.523138,
        "lng": 105.245931
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        105.201292,
        14.473511,
        105.307746,
        14.587309
      ]
    },
    {
      "id": 35325,
      "name": "Borba",
      "names": {
        "zh": "战斗报"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8057942,
        "lng": -7.4542105
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.5642245,
        38.713014,
        -7.3916731,
        38.9268277
      ]
    },
    {
      "id": 35331,
      "name": "Keskin",
      "names": {
        "zh": "凯斯金"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.673994,
        "lng": 33.615167
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.4235701,
        39.4658653,
        33.9769425,
        39.8187079
      ]
    },
    {
      "id": 35341,
      "name": "Komijan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.72142,
        "lng": 49.32653
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        49.30653,
        34.70142,
        49.34653,
        34.74142
      ]
    },
    {
      "id": 35352,
      "name": "Segre",
      "names": {
        "zh": "瑟格雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6846685,
        "lng": -0.8706239
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.905272,
        47.6732796,
        -0.8349589,
        47.7273458
      ]
    },
    {
      "id": 35354,
      "name": "Arraiolos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7225293,
        "lng": -7.9844448
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.9844948,
        38.7224793,
        -7.9843948,
        38.7225793
      ]
    },
    {
      "id": 35355,
      "name": "Bruzual",
      "names": {
        "zh": "布鲁苏阿尔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.0483948,
        "lng": -69.3326161
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -69.3561959,
        8.0270623,
        -69.3005347,
        8.0645835
      ]
    },
    {
      "id": 35362,
      "name": "San Diego de Alejandria",
      "names": {
        "zh": "圣地亚哥德阿莱·德里亚",
        "ja": "サンディエゴドアリャンドリア",
        "th": "ซานดิเอโกเดอเลตเจนเดรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.95599725,
        "lng": -102.0261866206735
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.1221464,
        20.8461172,
        -101.8864659,
        21.0656143
      ]
    },
    {
      "id": 35365,
      "name": "La Concordia",
      "names": {
        "zh": "洛杉矶哥伦比亚",
        "ja": "ラコンコルディア"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.1958419,
        "lng": -86.1667601
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.261505,
        13.1090963,
        -86.1225289,
        13.266548
      ]
    },
    {
      "id": 35368,
      "name": "Poiares",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2150566,
        "lng": -8.263837179610949
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.3117933,
        40.1760438,
        -8.2188579,
        40.2539066
      ]
    },
    {
      "id": 35374,
      "name": "Santa Marta de Penaguiao",
      "names": {
        "zh": "圣玛塔德比奥奥",
        "ja": "サンタマルタデパネガリア",
        "th": "ซานตามาร์ตาเดอตางูโอะ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2100446,
        "lng": -7.78473
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.8957306,
        41.1735571,
        -7.7436602,
        41.2730489
      ]
    },
    {
      "id": 35382,
      "name": "Thung Fon",
      "names": {
        "ja": "ティックフォン",
        "th": "ตุง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4725014,
        "lng": 103.2658624
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.2258624,
        17.4325014,
        103.3058624,
        17.5125014
      ]
    },
    {
      "id": 35399,
      "name": "Macao",
      "names": {
        "zh": "澳门",
        "id": "Makao",
        "ja": "マカオ",
        "th": "มาเก๊า",
        "pt": "Macau"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.554608,
        "lng": -7.9957035
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.1083101,
        39.4694046,
        -7.8089881,
        39.7478341
      ]
    },
    {
      "id": 35407,
      "name": "Harjavalta",
      "names": {
        "zh": "哈尔亚瓦尔塔"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3125216,
        "lng": 22.1357413
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.0036436,
        61.2651111,
        22.272008,
        61.4319664
      ]
    },
    {
      "id": 35411,
      "name": "Tall Tamr",
      "names": {
        "es": "Tamr altura",
        "fr": "Taille Tamr",
        "zh": "高大的tamr",
        "de": "Hoher Tamr",
        "it": "TAMR TALL",
        "id": "Tamr tinggi",
        "ja": "背の高いタム",
        "th": "THAL TAMR"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.65556,
        "lng": 40.37079
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        40.35079,
        36.63556,
        40.39079,
        36.67556
      ]
    },
    {
      "id": 35432,
      "name": "Si Chiang Mai",
      "names": {
        "ja": "Siチェンマイ",
        "th": "ศรีเชียงใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.9578352,
        "lng": 102.5863997
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.5463997,
        17.9178352,
        102.6263997,
        17.9978352
      ]
    },
    {
      "id": 35433,
      "name": "San Antonino Monteverde",
      "names": {
        "zh": "圣安东尼诺蒙特尔德",
        "ja": "サンアントニーノモンテディーベッド"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.518342,
        "lng": -97.744996
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.804347,
        17.455255,
        -97.695523,
        17.591144
      ]
    },
    {
      "id": 35458,
      "name": "Nakhon Luang",
      "names": {
        "ja": "ナコンルアン",
        "th": "นครหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4678913,
        "lng": 100.6124912
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.5952233,
        14.4476849,
        100.6430295,
        14.4914202
      ]
    },
    {
      "id": 35472,
      "name": "Yssingeaux",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1435556,
        "lng": 4.1244363
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.0542508,
        45.0722486,
        4.1951553,
        45.1960879
      ]
    },
    {
      "id": 35480,
      "name": "Lavrio",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.7142551,
        "lng": 24.0548015
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.0148015,
        37.6742551,
        24.0948015,
        37.7542551
      ]
    },
    {
      "id": 35481,
      "name": "Alvaiazere",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.82256,
        "lng": -8.37999
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.39999,
        39.80256,
        -8.35999,
        39.84256
      ]
    },
    {
      "id": 35486,
      "name": "Ciftlikkoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.073277,
        "lng": 29.3917322
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.3717322,
        40.053277,
        29.4117322,
        40.093277
      ]
    },
    {
      "id": 35503,
      "name": "Yesilyurt",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1798512,
        "lng": 40.2433461
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.2233461,
        40.1598512,
        40.2633461,
        40.1998512
      ]
    },
    {
      "id": 35519,
      "name": "Klichaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.489614,
        "lng": 29.3420691
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        29.3070941,
        53.4659813,
        29.371783,
        53.514734
      ]
    },
    {
      "id": 35523,
      "name": "Freyung",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8099603,
        "lng": 13.5491927
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        13.4772467,
        48.7785183,
        13.6122827,
        48.882965
      ]
    },
    {
      "id": 35525,
      "name": "Vila Nova de Foz Coa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.083118,
        "lng": -7.140839
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.14925306658,
        41.0780977885,
        -7.13321679386,
        41.087364164
      ]
    },
    {
      "id": 35529,
      "name": "Soata",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3333333,
        "lng": -72.6833333
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.6833833,
        6.3332833,
        -72.6832833,
        6.3333833
      ]
    },
    {
      "id": 35534,
      "name": "Karpenisi",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.917248,
        "lng": 21.794586
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.774586,
        38.897248,
        21.814586,
        38.937248
      ]
    },
    {
      "id": 35536,
      "name": "Cavle",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3507639,
        "lng": 14.4920329
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        14.4919829,
        45.3507139,
        14.4920829,
        45.3508139
      ]
    },
    {
      "id": 35573,
      "name": "Castro Verde",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.697995,
        "lng": -8.082275
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.2465382,
        37.5612403,
        -7.8490216,
        37.8273482
      ]
    },
    {
      "id": 35588,
      "name": "Villa Union",
      "names": {
        "fr": "Villa",
        "zh": "别墅联盟",
        "it": "Villaggio",
        "id": "Uni Villa",
        "ja": "ヴィラユニオン",
        "pt": "União da Villa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.0963857,
        "lng": -100.79781255580832
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.0330334,
        27.8214386,
        -100.5381234,
        28.3774648
      ]
    },
    {
      "id": 35598,
      "name": "Mertola",
      "names": {
        "pt": "Mértola"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.6374845,
        "lng": -7.6561725
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.6562225,
        37.6374345,
        -7.6561225,
        37.6375345
      ]
    },
    {
      "id": 35600,
      "name": "Terras de Bouro",
      "names": {
        "ja": "テラ・ド・ブーロ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.71864975,
        "lng": -8.306390766173418
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.314053,
        41.7133423,
        -8.29497,
        41.7239976
      ]
    },
    {
      "id": 35653,
      "name": "Tona",
      "names": {
        "zh": "托纳"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.2020629,
        "lng": -72.9663733
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0659568,
        7.044545,
        -72.8176543,
        7.2635236
      ]
    },
    {
      "id": 35664,
      "name": "Belene",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.6477654,
        "lng": 25.1258325
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        25.0858325,
        43.6077654,
        25.1658325,
        43.6877654
      ]
    },
    {
      "id": 35668,
      "name": "Havran",
      "names": {
        "zh": "哈夫兰"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5576221,
        "lng": 27.1002544
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.0554198,
        39.4635185,
        27.3829949,
        39.703914
      ]
    },
    {
      "id": 35669,
      "name": "Almeida",
      "names": {
        "zh": "阿尔梅达",
        "ja": "アルメイダ",
        "th": "ไมย์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7263333,
        "lng": -6.9067513
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.0518214,
        40.4766803,
        -6.7940144,
        40.7834202
      ]
    },
    {
      "id": 35672,
      "name": "Shyriaieve",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.3843482,
        "lng": 30.1895219
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.1600134,
        47.3469632,
        30.2256118,
        47.4298594
      ]
    },
    {
      "id": 35680,
      "name": "Koshekhabl",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.8964998,
        "lng": 40.4963631
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        40.4725611,
        44.8859453,
        40.5324701,
        44.9125409
      ]
    },
    {
      "id": 35681,
      "name": "Saint-Jean-d'Angely",
      "names": {
        "zh": "圣让 -  d'angely",
        "ja": "サン・ジャン= d'angely",
        "th": "เซนต์ฌอง d'angely"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.9445958,
        "lng": -0.5195756
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.5493754,
        45.9070434,
        -0.4881106,
        45.9793851
      ]
    },
    {
      "id": 35707,
      "name": "Tlilapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.805534,
        "lng": -97.0989684
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1389684,
        18.765534,
        -97.0589684,
        18.845534
      ]
    },
    {
      "id": 35722,
      "name": "Bamnet Narong",
      "names": {
        "fr": "Narong Bamnet",
        "ja": "バメットナロン"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5020403,
        "lng": 101.6893843
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.6493843,
        15.4620403,
        101.7293843,
        15.5420403
      ]
    },
    {
      "id": 35731,
      "name": "Tomatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.031158,
        "lng": -97.0097358
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0497358,
        18.991158,
        -96.9697358,
        19.071158
      ]
    },
    {
      "id": 35741,
      "name": "Colipa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9349429,
        "lng": -96.7192154104769
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.7973819,
        19.8720878,
        -96.6373523,
        19.9985046
      ]
    },
    {
      "id": 35761,
      "name": "Zacualpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4334552,
        "lng": -98.3507345
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.3907345,
        20.3934552,
        -98.3107345,
        20.4734552
      ]
    },
    {
      "id": 35770,
      "name": "Ikaalinen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.7701493,
        "lng": 23.0633777
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.6794455,
        61.6751615,
        23.5938585,
        61.984461
      ]
    },
    {
      "id": 35776,
      "name": "Huautepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.101111,
        "lng": -96.796944
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.836944,
        18.061111,
        -96.756944,
        18.141111
      ]
    },
    {
      "id": 35795,
      "name": "Hanga Roa",
      "names": {
        "it": "Hangha roa",
        "id": "Roa",
        "pt": "Sanga Roa"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -27.1481172,
        "lng": -109.427344
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -109.467344,
        -27.1881172,
        -109.387344,
        -27.1081172
      ]
    },
    {
      "id": 35798,
      "name": "Marathonas",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.1532724,
        "lng": 23.9620892
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.9220892,
        38.1132724,
        24.0020892,
        38.1932724
      ]
    },
    {
      "id": 35814,
      "name": "Ixtapan del Oro",
      "names": {
        "ja": "イスタパンデルオーロ",
        "pt": "Ixapan del oro"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2622941,
        "lng": -100.263935
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.283935,
        19.2422941,
        -100.243935,
        19.2822941
      ]
    },
    {
      "id": 35828,
      "name": "Carhue",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.182861,
        "lng": -62.733313
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -62.7533132341,
        -37.2028609022,
        -62.7133132341,
        -37.1628609022
      ]
    },
    {
      "id": 35832,
      "name": "San Vicente Pacaya",
      "names": {
        "ja": "サンビセンテパカヤ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.41611,
        "lng": -90.63917
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.65917,
        14.39611,
        -90.61917,
        14.43611
      ]
    },
    {
      "id": 35833,
      "name": "La Grandeza",
      "names": {
        "it": "La Granrateza",
        "ja": "ラグランドーザ",
        "th": "ลาแกรนด์ซ่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.5108586,
        "lng": -92.2258142
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.2458142,
        15.4908586,
        -92.2058142,
        15.5308586
      ]
    },
    {
      "id": 35836,
      "name": "Polykastro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9945865,
        "lng": 22.572102
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.532102,
        40.9545865,
        22.612102,
        41.0345865
      ]
    },
    {
      "id": 35850,
      "name": "Socha",
      "names": {
        "zh": "索哈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.997488,
        "lng": -72.6911515
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.7430021,
        5.8667674,
        -72.632776,
        6.0624105
      ]
    },
    {
      "id": 35880,
      "name": "Slivnitsa",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.8517828,
        "lng": 23.0380281
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        23.0081927,
        42.8143352,
        23.090182,
        42.911559
      ]
    },
    {
      "id": 35883,
      "name": "Brioude",
      "names": {
        "zh": "布里尤德"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.293897,
        "lng": 3.3853711
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.3572228,
        45.2744548,
        3.4121415,
        45.3188567
      ]
    },
    {
      "id": 35887,
      "name": "Haapavesi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.1378737,
        "lng": 25.3658176
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.865647,
        63.9216119,
        25.7773077,
        64.3734679
      ]
    },
    {
      "id": 35895,
      "name": "Kato Achaia",
      "names": {
        "zh": "凯托·凯西",
        "ja": "カトアカイア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.1445824,
        "lng": 21.5569637
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.5169637,
        38.1045824,
        21.5969637,
        38.1845824
      ]
    },
    {
      "id": 35917,
      "name": "Saruhanli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.73455,
        "lng": 27.56811
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.54811,
        38.71455,
        27.58811,
        38.75455
      ]
    },
    {
      "id": 35918,
      "name": "Bagneres-de-Bigorre",
      "names": {
        "fr": "Bagnères-de-Bigorre",
        "zh": "巴涅尔 - 德比戈尔",
        "de": "Bagneres de bigorre",
        "it": "Bagneres De bigorre",
        "ja": "bagneres・ド・bigorre",
        "th": "บานช์แยร์เดอ-bigorre"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.064337800000004,
        "lng": 0.15047889251047816
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.1502913,
        43.0642118,
        0.1506665,
        43.0644665
      ]
    },
    {
      "id": 35921,
      "name": "Myadzyel",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.8789,
        "lng": 26.9371
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        26.9171,
        54.8589,
        26.9571,
        54.8989
      ]
    },
    {
      "id": 35922,
      "name": "Chuarrancho",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8197016,
        "lng": -90.5124653
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.5524653,
        14.7797016,
        -90.4724653,
        14.8597016
      ]
    },
    {
      "id": 35931,
      "name": "Outokumpu",
      "names": {
        "zh": "奥托昆普"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.7255326,
        "lng": 29.0186972
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        28.6327043,
        62.5701751,
        29.3039549,
        62.8484753
      ]
    },
    {
      "id": 35951,
      "name": "Bo Phloi",
      "names": {
        "it": "Bo floi",
        "th": "บ่อพล่อย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3253091,
        "lng": 99.5150537
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.4750537,
        14.2853091,
        99.5550537,
        14.3653091
      ]
    },
    {
      "id": 35957,
      "name": "Texhuacan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.616036100000002,
        "lng": -97.03065276661178
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.078353,
        18.5814413,
        -96.9729786,
        18.6510574
      ]
    },
    {
      "id": 35980,
      "name": "Taskopru",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.53366,
        "lng": 36.0223968
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.0023968,
        41.51366,
        36.0423968,
        41.55366
      ]
    },
    {
      "id": 35992,
      "name": "Ko Pha-Ngan",
      "names": {
        "de": "Kopha-ngan",
        "ja": "KO-NGAN",
        "th": "เกาะพะงัน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.72673,
        "lng": 99.987801
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.949991,
        9.68377,
        100.069847,
        9.810134
      ]
    },
    {
      "id": 35994,
      "name": "San Jose de Cusmapa",
      "names": {
        "zh": "圣何塞德·科斯玛帕",
        "ja": "サンノゼ・デ・カスマパ",
        "th": "ซานโฮเซเดอคัสมาปะ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.2879947,
        "lng": -86.6543905
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.7541965,
        13.1915274,
        -86.6192796,
        13.3231156
      ]
    },
    {
      "id": 35997,
      "name": "Stryn",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 61.8218058,
        "lng": 6.802968514467588
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.2566457,
        61.6094839,
        7.4142293,
        62.0340925
      ]
    },
    {
      "id": 35999,
      "name": "Boavita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.33031,
        "lng": -72.58505
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.60505,
        6.31031,
        -72.56505,
        6.35031
      ]
    },
    {
      "id": 36001,
      "name": "Salento",
      "names": {
        "zh": "萨伦托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6373012,
        "lng": -75.569799
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.6428266,
        4.4727394,
        -75.3826882,
        4.716532
      ]
    },
    {
      "id": 36008,
      "name": "Isliam-Terek",
      "names": {
        "zh": "isliam-捷列克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 45.2256,
        "lng": 35.2033
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36009,
      "name": "Sotuta",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5968166,
        "lng": -89.0091948
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.0216309,
        20.585011,
        -88.9972381,
        20.6067408
      ]
    },
    {
      "id": 36018,
      "name": "Akhmeta",
      "names": {
        "zh": "艾哈迈塔"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 42.03111,
        "lng": 45.2075
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        45.1875,
        42.01111,
        45.2275,
        42.05111
      ]
    },
    {
      "id": 36067,
      "name": "Okhansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.723387,
        "lng": 55.385955
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        55.364388,
        57.713055,
        55.407139,
        57.734417
      ]
    },
    {
      "id": 36080,
      "name": "Susehri",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1627343,
        "lng": 38.0855664
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.9047201,
        39.9608725,
        38.3286809,
        40.4100979
      ]
    },
    {
      "id": 36097,
      "name": "Tixpehual",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9769687,
        "lng": -89.4416368
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -89.449371,
        20.9676081,
        -89.4338796,
        20.987163
      ]
    },
    {
      "id": 36106,
      "name": "Sikatuna",
      "names": {
        "zh": "锡卡图纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6878519,
        "lng": 123.9740624
      },
      "country": "Philippines",
      "importance": 2,
      "bbox": [
        123.9340624,
        9.6478519,
        124.0140624,
        9.7278519
      ]
    },
    {
      "id": 36126,
      "name": "Sharhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7498267,
        "lng": 28.08317
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.0370464,
        48.7268729,
        28.1104857,
        48.781518
      ]
    },
    {
      "id": 36137,
      "name": "Aydincik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.1452033,
        "lng": 33.3224232
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.173914,
        36.1248854,
        33.458368,
        36.2994544
      ]
    },
    {
      "id": 36143,
      "name": "Nyons",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 44.3611931,
        "lng": 5.1408743
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.0840475,
        44.3337494,
        5.1647544,
        44.3837351
      ]
    },
    {
      "id": 36147,
      "name": "Sakyla",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.0460137,
        "lng": 22.3430918
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.1419115,
        60.9160157,
        22.7013552,
        61.2519338
      ]
    },
    {
      "id": 36153,
      "name": "Novska",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3267782,
        "lng": 16.9770911
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.9720911,
        45.3217782,
        16.9820911,
        45.3317782
      ]
    },
    {
      "id": 36161,
      "name": "Chadegan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.76825,
        "lng": 50.62873
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        50.60873,
        32.74825,
        50.64873,
        32.78825
      ]
    },
    {
      "id": 36203,
      "name": "Redondo",
      "names": {
        "zh": "雷东多",
        "ja": "レドンド"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6466891,
        "lng": -7.5467636
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.7256631,
        38.4755274,
        -7.4890868,
        38.7551281
      ]
    },
    {
      "id": 36223,
      "name": "Aigina",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.7457282,
        "lng": 23.4271283
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.4270783,
        37.7456782,
        23.4271783,
        37.7457782
      ]
    },
    {
      "id": 36234,
      "name": "Litochoro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.1024494,
        "lng": 22.5018169
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.4618169,
        40.0624494,
        22.5418169,
        40.1424494
      ]
    },
    {
      "id": 36235,
      "name": "Tuzamapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.066373,
        "lng": -97.575352
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.579506,
        20.061484,
        -97.570806,
        20.070663
      ]
    },
    {
      "id": 36246,
      "name": "Polessk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.8630695,
        "lng": 21.1100843
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        21.1100343,
        54.8630195,
        21.1101343,
        54.8631195
      ]
    },
    {
      "id": 36264,
      "name": "Pinotepa de Don Luis",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4283843,
        "lng": -97.9764683
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.9964683,
        16.4083843,
        -97.9564683,
        16.4483843
      ]
    },
    {
      "id": 36278,
      "name": "Tenamaxtlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2185641,
        "lng": -104.1647119
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.1847119,
        20.1985641,
        -104.1447119,
        20.2385641
      ]
    },
    {
      "id": 36283,
      "name": "Aridaia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9755942,
        "lng": 22.0613735
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.0213735,
        40.9355942,
        22.1013735,
        41.0155942
      ]
    },
    {
      "id": 36298,
      "name": "Kistelek",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4704606,
        "lng": 19.9812009
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.8880452,
        46.4400987,
        20.0366225,
        46.5677109
      ]
    },
    {
      "id": 36304,
      "name": "Chaek",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 41.9296803,
        "lng": 74.5236212
      },
      "country": "Kyrgyzstan",
      "importance": 2,
      "bbox": [
        74.4873248,
        41.9131636,
        74.5580503,
        41.9493916
      ]
    },
    {
      "id": 36312,
      "name": "Polgardi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0563978,
        "lng": 18.3067942
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.2514868,
        46.9937426,
        18.3727398,
        47.12103
      ]
    },
    {
      "id": 36324,
      "name": "Turka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1531436,
        "lng": 23.0336345
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        22.9753985,
        49.1199189,
        23.0556883,
        49.1855576
      ]
    },
    {
      "id": 36327,
      "name": "Zlatograd",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.3809141,
        "lng": 25.0950002
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        25.0550002,
        41.3409141,
        25.1350002,
        41.4209141
      ]
    },
    {
      "id": 36328,
      "name": "Virrat",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2402483,
        "lng": 23.7725594
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.2067255,
        62.0740627,
        24.2352515,
        62.4943278
      ]
    },
    {
      "id": 36340,
      "name": "Velika Kladusa",
      "names": {
        "ja": "Kladusa velika"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 45.1843299,
        "lng": 15.8069257
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        15.7669257,
        45.1443299,
        15.8469257,
        45.2243299
      ]
    },
    {
      "id": 36366,
      "name": "Guatavita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9343953,
        "lng": -73.8353008
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.8753008,
        4.8943953,
        -73.7953008,
        4.9743953
      ]
    },
    {
      "id": 36374,
      "name": "Zirc",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2613795,
        "lng": 17.8718188
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.8116773,
        47.2076938,
        17.9055173,
        47.3012836
      ]
    },
    {
      "id": 36381,
      "name": "Kazanka",
      "names": {
        "zh": "卡赞卡"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.835215,
        "lng": 32.8241797
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.7965498,
        47.8054591,
        32.8593246,
        47.871203
      ]
    },
    {
      "id": 36386,
      "name": "Oppdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.535178450000004,
        "lng": 9.62330412000998
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        8.919962,
        62.2557267,
        10.0975831,
        62.8160499
      ]
    },
    {
      "id": 36393,
      "name": "Tomakivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8071544,
        "lng": 34.7474529
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.7097222,
        47.7869389,
        34.7828556,
        47.8308468
      ]
    },
    {
      "id": 36402,
      "name": "Vestnes",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.56819005,
        "lng": 7.11060774159323
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.6765768,
        62.4462399,
        7.4128961,
        62.6901326
      ]
    },
    {
      "id": 36411,
      "name": "Kubrat",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7967009,
        "lng": 26.5006777
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        26.42453,
        43.7549966,
        26.5629775,
        43.8362014
      ]
    },
    {
      "id": 36431,
      "name": "Vyerkhnyadzvinsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.7777,
        "lng": 27.9389
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        27.9189,
        55.7577,
        27.9589,
        55.7977
      ]
    },
    {
      "id": 36441,
      "name": "Dubrowna",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.5716,
        "lng": 30.691
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        30.671,
        54.5516,
        30.711,
        54.5916
      ]
    },
    {
      "id": 36444,
      "name": "Sunndalsora",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.6568743,
        "lng": 8.6810432
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        8.6660432,
        62.6418743,
        8.6960432,
        62.6718743
      ]
    },
    {
      "id": 36448,
      "name": "Hartberg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2809371,
        "lng": 15.9691769
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.9441091,
        47.2460857,
        16.0266916,
        47.3090353
      ]
    },
    {
      "id": 36450,
      "name": "Nerac",
      "names": {
        "fr": "Nérac",
        "zh": "内拉克",
        "it": "Nérac"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.1353979,
        "lng": 0.3392503
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.2791515,
        44.089171,
        0.4147274,
        44.1784647
      ]
    },
    {
      "id": 36453,
      "name": "San Buenaventura",
      "names": {
        "zh": "圣布纳留村",
        "ja": "サンブナベントゥーラ",
        "th": "ซานบัวเวนทูรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.84153645,
        "lng": -101.93663388663362
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.4929442,
        27.0151841,
        -101.4128783,
        28.6676831
      ]
    },
    {
      "id": 36456,
      "name": "Askale",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.92083,
        "lng": 40.695
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.675,
        39.90083,
        40.715,
        39.94083
      ]
    },
    {
      "id": 36466,
      "name": "Sivasli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.49944,
        "lng": 29.68361
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.66361,
        38.47944,
        29.70361,
        38.51944
      ]
    },
    {
      "id": 36470,
      "name": "Charoen Sin",
      "names": {
        "es": "Pecado de caroen",
        "zh": "夏洛森罪",
        "de": "Charoen Sünde",
        "id": "Dosa charoen",
        "ja": "チャルエン罪",
        "th": "เจริญนครศวรรย์",
        "pt": "Pecado de charoen"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.5786646,
        "lng": 103.5397288
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.4997288,
        17.5386646,
        103.5797288,
        17.6186646
      ]
    },
    {
      "id": 36504,
      "name": "Baarle-Nassau",
      "names": {
        "zh": "巴勒拿骚",
        "ja": "baarle-ナッソー",
        "th": "baarle-นัสเซา"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4441976,
        "lng": 4.9317413
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        4.8039704,
        51.3936933,
        5.0109964,
        51.4798271
      ]
    },
    {
      "id": 36545,
      "name": "Seraitang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9360214,
        "lng": 100.7374327
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        99.7633261,
        32.5245995,
        101.2366263,
        33.356289
      ]
    },
    {
      "id": 36550,
      "name": "Neufchateau",
      "names": {
        "es": "Neufchâteau",
        "zh": "讷沙托",
        "de": "Neufchâteau",
        "it": "Neufchâteau"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3553014,
        "lng": 5.6948571
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.644197,
        48.320331,
        5.7354908,
        48.3935895
      ]
    },
    {
      "id": 36555,
      "name": "Menderes",
      "names": {
        "zh": "曼德列斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2516422,
        "lng": 27.1329994
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.9437867,
        37.9800711,
        27.3137026,
        38.3190903
      ]
    },
    {
      "id": 36564,
      "name": "Belmonte",
      "names": {
        "zh": "贝尔蒙特",
        "th": "เบลมอนต์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3583264,
        "lng": -7.3512742
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.3930709,
        40.2444769,
        -7.2699937,
        40.4161318
      ]
    },
    {
      "id": 36565,
      "name": "Guican",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4616779,
        "lng": -72.4119937
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.4448962,
        6.3571717,
        -72.0890485,
        6.748512
      ]
    },
    {
      "id": 36569,
      "name": "Saue",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3217905,
        "lng": 24.55928508785309
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        24.5181996,
        59.3114499,
        24.5859971,
        59.3321564
      ]
    },
    {
      "id": 36598,
      "name": "Interlaken",
      "names": {
        "zh": "茵特拉根",
        "ja": "インターラーケン",
        "th": "ในอินเตอร์เลเคน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.6855231,
        "lng": 7.8585139
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        7.8269307,
        46.6646262,
        7.8887534,
        46.7041702
      ]
    },
    {
      "id": 36607,
      "name": "Jevnaker",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.2410364,
        "lng": 10.3855051
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.3455051,
        60.2010364,
        10.4255051,
        60.2810364
      ]
    },
    {
      "id": 36628,
      "name": "Ocampo",
      "names": {
        "zh": "奥坎波"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.648312,
        "lng": -101.479232
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.495778,
        21.637216,
        -101.467365,
        21.657477
      ]
    },
    {
      "id": 36680,
      "name": "Ban Haet",
      "names": {
        "fr": "Banane",
        "zh": "禁止哈丁",
        "de": "Hals",
        "it": "Bandata",
        "id": "Melarang",
        "ja": "ヘェットを禁止します",
        "th": "บ้านห้าม",
        "pt": "Proibir haet"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.1953427,
        "lng": 102.7480144
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.7280144,
        16.1753427,
        102.7680144,
        16.2153427
      ]
    },
    {
      "id": 36757,
      "name": "Chugqensumdo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.4308321,
        "lng": 101.4831378
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        101.4431378,
        33.3908321,
        101.5231378,
        33.4708321
      ]
    },
    {
      "id": 36769,
      "name": "Altenkirchen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6880109,
        "lng": 7.6477412
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.6077412,
        50.6480109,
        7.6877412,
        50.7280109
      ]
    },
    {
      "id": 36785,
      "name": "Mozonte",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.6601446,
        "lng": -86.4387195
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.490016,
        13.5632481,
        -86.3491905,
        13.7898467
      ]
    },
    {
      "id": 36803,
      "name": "Bilmak",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.358,
        "lng": 36.64908
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.62908,
        47.338,
        36.66908,
        47.378
      ]
    },
    {
      "id": 36808,
      "name": "Chiang Kham",
      "names": {
        "zh": "蒋克汉姆",
        "ja": "チェンカム",
        "th": "เชียงคาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.5237368,
        "lng": 100.302163
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.262163,
        19.4837368,
        100.342163,
        19.5637368
      ]
    },
    {
      "id": 36818,
      "name": "San Francisco del Norte",
      "names": {
        "zh": "旧金山del Norte",
        "ja": "サンフランシスコデルノルテ",
        "th": "ซานฟรานซิสโกเดล Norte",
        "pt": "São Francisco del Norte"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.19923,
        "lng": -86.7717
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -86.7917,
        13.17923,
        -86.7517,
        13.21923
      ]
    },
    {
      "id": 36825,
      "name": "Hornostaivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.00777,
        "lng": 33.72899
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.70899,
        46.98777,
        33.74899,
        47.02777
      ]
    },
    {
      "id": 36827,
      "name": "Tyrnava",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.7621368,
        "lng": 25.6498871
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.5010602,
        64.5627492,
        26.1294901,
        64.9044361
      ]
    },
    {
      "id": 36839,
      "name": "Inari",
      "names": {
        "zh": "伊纳里",
        "ja": "稲荷"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 68.904512,
        "lng": 27.014439
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.995461,
        68.898028,
        27.056679,
        68.908941
      ]
    },
    {
      "id": 36843,
      "name": "Kristinestad",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2736018,
        "lng": 21.3730807
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        20.7281379,
        61.9216727,
        21.7699211,
        62.424292
      ]
    },
    {
      "id": 36852,
      "name": "Phan Thong",
      "names": {
        "zh": "藩丁",
        "de": "Phan-Tanga",
        "it": "Phan perizoma",
        "ja": "ファンのひも",
        "th": "พานทอง",
        "pt": "Tanga de phan"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.4711731,
        "lng": 101.0966223
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.0566223,
        13.4311731,
        101.1366223,
        13.5111731
      ]
    },
    {
      "id": 36855,
      "name": "Risor",
      "names": {
        "pt": "Risør"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.7207522,
        "lng": 9.2343838
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.1943838,
        58.6807522,
        9.2743838,
        58.7607522
      ]
    },
    {
      "id": 36905,
      "name": "Sarkisla",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3504952,
        "lng": 36.4097977
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.106224,
        39.0742431,
        36.6657926,
        39.6933188
      ]
    },
    {
      "id": 36906,
      "name": "Na Duang",
      "names": {
        "zh": "娜杜",
        "th": "น้าดวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.47631,
        "lng": 101.97406
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36909,
      "name": "`Ayn `Isa",
      "names": {
        "es": "'Ayn' ISA",
        "fr": "`Ayn` isa",
        "zh": "`Ayn`isa",
        "de": "`Ayn` Isa",
        "it": "`Ayn` isa",
        "id": "`Ayn` Isa",
        "ja": "`ayn` isa",
        "th": "`Ayn` isa",
        "pt": "`Ayn` isa"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.3855,
        "lng": 38.86228
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        38.84228,
        36.3655,
        38.88228,
        36.4055
      ]
    },
    {
      "id": 36918,
      "name": "Bozdogan",
      "names": {
        "zh": "博兹多安"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.6739133,
        "lng": 28.3132041
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.1678652,
        37.4687827,
        28.6142077,
        37.8426104
      ]
    },
    {
      "id": 36934,
      "name": "Parkano",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.0102011,
        "lng": 23.0245972
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.5207526,
        61.8958713,
        23.3661529,
        62.288904
      ]
    },
    {
      "id": 36939,
      "name": "Reutte",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.487066,
        "lng": 10.718213
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        10.712766,
        47.472453,
        10.725219,
        47.520187
      ]
    },
    {
      "id": 36940,
      "name": "Castro Marim",
      "names": {
        "id": "Mari",
        "ja": "カストロマリム"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.2176794,
        "lng": -7.446708
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.446758,
        37.2176294,
        -7.446658,
        37.2177294
      ]
    },
    {
      "id": 36954,
      "name": "Sofiivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0482032,
        "lng": 33.8668627
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        33.8431293,
        48.0330629,
        33.9516417,
        48.1207462
      ]
    },
    {
      "id": 36967,
      "name": "Ibrany",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1276254,
        "lng": 21.7120093
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.7070093,
        48.1226254,
        21.7170093,
        48.1326254
      ]
    },
    {
      "id": 36971,
      "name": "Avallon",
      "names": {
        "zh": "阿瓦隆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.4885365,
        "lng": 3.9070658
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.873501,
        47.4504872,
        3.9657181,
        47.5109652
      ]
    },
    {
      "id": 36985,
      "name": "Medzilaborce",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2711323,
        "lng": 21.9039645
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        21.7856,
        49.098861,
        22.106737,
        49.3916855
      ]
    },
    {
      "id": 36988,
      "name": "Cihanbeyli",
      "names": {
        "zh": "吉汉贝伊利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6589897,
        "lng": 32.9237305
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.3242866,
        38.3519845,
        33.4328617,
        39.0426529
      ]
    },
    {
      "id": 36991,
      "name": "Santiago Maravatio",
      "names": {
        "zh": "圣地亚哥马拉维塔奥",
        "th": "ซันติอาโก Maravatio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1722092,
        "lng": -100.994364
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -101.034364,
        20.1322092,
        -100.954364,
        20.2122092
      ]
    },
    {
      "id": 36993,
      "name": "Gorom-Gorom",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 14.4445805,
        "lng": -0.2345411
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -0.2745411,
        14.4045805,
        -0.1945411,
        14.4845805
      ]
    },
    {
      "id": 36997,
      "name": "Sobrance",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7446501,
        "lng": 22.1806628
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        22.057037,
        48.573906,
        22.387648,
        48.9302
      ]
    },
    {
      "id": 37004,
      "name": "Ambert",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5504338,
        "lng": 3.742639
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.676109,
        45.508359,
        3.8355015,
        45.5949158
      ]
    },
    {
      "id": 37008,
      "name": "Saint-Girons",
      "names": {
        "zh": "圣 -  girons",
        "ja": "サン・girons",
        "th": "เซนต์ girons"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.984215,
        "lng": 1.146017
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.120964,
        42.936507,
        1.199711,
        42.996517
      ]
    },
    {
      "id": 37028,
      "name": "Enying",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9269341,
        "lng": 18.2449727
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.1740343,
        46.8578764,
        18.3275451,
        46.9719454
      ]
    },
    {
      "id": 37051,
      "name": "Cubara",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0020076,
        "lng": -72.1089218
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.5020216,
        6.6982578,
        -71.9433099,
        7.0548413
      ]
    },
    {
      "id": 37064,
      "name": "Derik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3647781,
        "lng": 40.2691843
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.8841047,
        37.0783087,
        40.4311895,
        37.5624414
      ]
    },
    {
      "id": 37068,
      "name": "Tuxcueca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.154421,
        "lng": -103.184921
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.189783,
        20.148238,
        -103.179002,
        20.159634
      ]
    },
    {
      "id": 37075,
      "name": "Vila Flor",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3067941,
        "lng": -7.1517712
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.2981749,
        41.2279155,
        -7.0326324,
        41.4127059
      ]
    },
    {
      "id": 37076,
      "name": "Vora",
      "names": {
        "zh": "沃拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.1304959,
        "lng": 22.250699
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.3571417,
        63.0561135,
        22.6582021,
        63.6702189
      ]
    },
    {
      "id": 37078,
      "name": "Morelos",
      "names": {
        "zh": "莫雷洛斯",
        "ja": "モレロス",
        "th": "มอเรโลส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.75691415,
        "lng": -99.66168835774002
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.7490547,
        19.6092053,
        -99.5198819,
        19.84058
      ]
    },
    {
      "id": 37087,
      "name": "Seydisehir",
      "names": {
        "zh": "塞伊迪谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4193713,
        "lng": 31.8483332
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.6854596,
        37.2018083,
        32.2078077,
        37.7068443
      ]
    },
    {
      "id": 37092,
      "name": "Phanom Sarakham",
      "names": {
        "th": "ธาตุพนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.748243,
        "lng": 101.3471785
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.3071785,
        13.708243,
        101.3871785,
        13.788243
      ]
    },
    {
      "id": 37098,
      "name": "Tatatila",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.693611,
        "lng": -97.111389
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.151389,
        19.653611,
        -97.071389,
        19.733611
      ]
    },
    {
      "id": 37108,
      "name": "San Juan Mixtepec",
      "names": {
        "zh": "圣胡安mixtepec",
        "it": "San Juan Mixttepec",
        "ja": "サンファンミックステープ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.30539,
        "lng": -97.83158
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.85158,
        17.28539,
        -97.81158,
        17.32539
      ]
    },
    {
      "id": 37112,
      "name": "Yaring",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.86537,
        "lng": 101.36769
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37168,
      "name": "Lityn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.318744,
        "lng": 28.089518
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.081254,
        49.311251,
        28.099816,
        49.329081
      ]
    },
    {
      "id": 37169,
      "name": "Staryi Sambir",
      "names": {
        "de": "Staryst Sambir",
        "ja": "スタリリサンビール"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.44406,
        "lng": 23.0022677
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        22.9882601,
        49.4193838,
        23.0291609,
        49.4601738
      ]
    },
    {
      "id": 37170,
      "name": "Rinconada",
      "names": {
        "zh": "林科纳达"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.4409796,
        "lng": -66.1678621
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.4537846,
        -22.7116731,
        -65.8268095,
        -22.2479977
      ]
    },
    {
      "id": 37172,
      "name": "Kronoby",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.726249,
        "lng": 23.02877
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.005626,
        63.713054,
        23.061118,
        63.735311
      ]
    },
    {
      "id": 37181,
      "name": "Palkane",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3371322,
        "lng": 24.2649422
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.0790851,
        61.2332747,
        24.9151562,
        61.490897
      ]
    },
    {
      "id": 37183,
      "name": "Horn",
      "names": {
        "es": "Cuerno",
        "fr": "Corne",
        "zh": "号角",
        "it": "Corno",
        "ja": "角",
        "th": "ฮอร์น",
        "pt": "Chifre"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.6636596,
        "lng": 15.6563147
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.6228733,
        48.632497,
        15.722911,
        48.7161659
      ]
    },
    {
      "id": 37191,
      "name": "Louhans",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6291425,
        "lng": 5.2232175
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.2021076,
        46.6085338,
        5.2825436,
        46.6603085
      ]
    },
    {
      "id": 37192,
      "name": "That Phanom",
      "names": {
        "es": "Ese phanom",
        "fr": "Ce phanom",
        "zh": "那个菲尼",
        "de": "Das Phanom",
        "it": "Quel Phanom",
        "id": "Phanom itu",
        "ja": "そのファノム",
        "th": "ธาตุนั่น",
        "pt": "Esse phanom"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.942934,
        "lng": 104.723622
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.695808,
        16.924293,
        104.739312,
        16.987034
      ]
    },
    {
      "id": 37216,
      "name": "Viitasaari",
      "names": {
        "zh": "维塔萨里"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.0837203,
        "lng": 25.8528349
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.3093108,
        62.8679805,
        26.3342758,
        63.28983
      ]
    },
    {
      "id": 37230,
      "name": "Ar Rawdah",
      "names": {
        "ja": "Rawdah",
        "th": "arrawadah"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.476294,
        "lng": 47.27461
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        46.9544061,
        14.1379616,
        47.5944806,
        14.8365942
      ]
    },
    {
      "id": 37236,
      "name": "Montdidier",
      "names": {
        "zh": "蒙迪迪耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.6460204,
        "lng": 2.570196
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.5326835,
        49.6251394,
        2.5997551,
        49.6699074
      ]
    },
    {
      "id": 37250,
      "name": "Nong Chik",
      "names": {
        "th": "หนองจิ๊ก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.8438437,
        "lng": 101.1786117
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.1386117,
        6.8038437,
        101.2186117,
        6.8838437
      ]
    },
    {
      "id": 37302,
      "name": "Rissa",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.5842624,
        "lng": 9.959178
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        9.939178,
        63.5642624,
        9.979178,
        63.6042624
      ]
    },
    {
      "id": 37315,
      "name": "Zavetnoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.117554,
        "lng": 43.888069
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        43.8568352,
        47.1009592,
        43.9366079,
        47.1367894
      ]
    },
    {
      "id": 37337,
      "name": "Yemilchyne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.871458,
        "lng": 27.800501
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.780501,
        50.851458,
        27.820501,
        50.891458
      ]
    },
    {
      "id": 37342,
      "name": "Gomez Farias",
      "names": {
        "zh": "戈麦斯法罗斯",
        "ja": "ゴメスファレアス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.1029076,
        "lng": -110.9495841
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.9496175,
        29.097627,
        -110.9495565,
        29.1088848
      ]
    },
    {
      "id": 37350,
      "name": "Ban Tak",
      "names": {
        "es": "Prohibir",
        "fr": "Banaliser",
        "zh": "班德",
        "it": "Bank tak",
        "ja": "禁止",
        "th": "บ้านตาก",
        "pt": "Banimento"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.0428061,
        "lng": 99.0703537
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.0303537,
        17.0028061,
        99.1103537,
        17.0828061
      ]
    },
    {
      "id": 37363,
      "name": "Prades",
      "names": {
        "zh": "普拉德斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.6181232,
        "lng": 2.4230925
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        2.4023852,
        42.5872335,
        2.4537329,
        42.6340357
      ]
    },
    {
      "id": 37383,
      "name": "Belisce",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6830916,
        "lng": 18.4063613
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.3731296,
        45.6694582,
        18.4357923,
        45.6917866
      ]
    },
    {
      "id": 37409,
      "name": "Ploce",
      "names": {
        "zh": "普洛切"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.0524293,
        "lng": 17.4360676
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        17.3428794,
        43.0188299,
        17.5642735,
        43.1576604
      ]
    },
    {
      "id": 37416,
      "name": "Echallens",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 46.6381163,
        "lng": 6.6346951
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        6.6130201,
        46.6251649,
        6.6605612,
        46.6506993
      ]
    },
    {
      "id": 37419,
      "name": "Saint-Flour",
      "names": {
        "es": "Saint-harina",
        "zh": "圣 - 面粉",
        "de": "Saint-Mehl",
        "it": "Saint-Farina",
        "id": "Saint-tepung",
        "ja": "サン・小麦粉",
        "th": "เซนต์แป้ง",
        "pt": "Saint-farinha"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.0334949,
        "lng": 3.0931009
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.0240571,
        44.9836724,
        3.1218627,
        45.0658712
      ]
    },
    {
      "id": 37421,
      "name": "Sarayonu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2639561,
        "lng": 32.405574
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.2784045,
        38.1206485,
        32.639877,
        38.8435695
      ]
    },
    {
      "id": 37434,
      "name": "Pryazovske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.73,
        "lng": 35.63896
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.5945742,
        46.7047926,
        35.6636765,
        46.7687873
      ]
    },
    {
      "id": 37447,
      "name": "Lat Yao",
      "names": {
        "zh": "拉瑶",
        "th": "ลาดยาว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7515633,
        "lng": 99.7899048
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.7499048,
        15.7115633,
        99.8299048,
        15.7915633
      ]
    },
    {
      "id": 37453,
      "name": "Aszod",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.652953,
        "lng": 19.490207
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.458877,
        47.644942,
        19.507334,
        47.659809
      ]
    },
    {
      "id": 37454,
      "name": "Ipsala",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9172568,
        "lng": 26.3807189
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.3806689,
        40.9172068,
        26.3807689,
        40.9173068
      ]
    },
    {
      "id": 37465,
      "name": "Putnok",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2942607,
        "lng": 20.4342869
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.399996,
        48.2744095,
        20.5102046,
        48.3473986
      ]
    },
    {
      "id": 37467,
      "name": "Trysil",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 61.315145,
        "lng": 12.2638609
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        12.2438609,
        61.295145,
        12.2838609,
        61.335145
      ]
    },
    {
      "id": 37471,
      "name": "Turcianske Teplice",
      "names": {
        "fr": "Teplice Turcianske",
        "id": "Teplice Turcianse"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8595802,
        "lng": 18.8629815
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.8629315,
        48.8595302,
        18.8630315,
        48.8596302
      ]
    },
    {
      "id": 37476,
      "name": "Ishqoshim",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 36.72484,
        "lng": 71.61331
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 37483,
      "name": "Mut",
      "names": {
        "zh": "突变型"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.6434207,
        "lng": 33.437263
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.9243945,
        36.4591162,
        33.7975043,
        36.9694896
      ]
    },
    {
      "id": 37484,
      "name": "Condom",
      "names": {
        "es": "Condón",
        "fr": "Préservatif",
        "zh": "避孕套",
        "de": "Kondom",
        "it": "Preservativo",
        "id": "Kondom",
        "ja": "コンドーム",
        "th": "ถุงยางอนามัย",
        "pt": "Preservativo"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.9564563,
        "lng": 0.3745929
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.299759,
        43.9009546,
        0.4567398,
        44.0295576
      ]
    },
    {
      "id": 37486,
      "name": "Ribeira de Pena",
      "names": {
        "ja": "リベイラデペナ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5217103,
        "lng": -7.795143
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.9098663,
        41.4036303,
        -7.7040668,
        41.6523854
      ]
    },
    {
      "id": 37506,
      "name": "Phu Khiao",
      "names": {
        "th": "ภูเขียว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3665359,
        "lng": 102.1335235
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.0935235,
        16.3265359,
        102.1735235,
        16.4065359
      ]
    },
    {
      "id": 37514,
      "name": "Haparanda",
      "names": {
        "zh": "哈帕兰达"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 65.833333,
        "lng": 24.1
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        23.3319477,
        65.3566383,
        24.1776819,
        66.1678169
      ]
    },
    {
      "id": 37554,
      "name": "Juva",
      "names": {
        "zh": "尤瓦"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8961369,
        "lng": 27.859727
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.3713396,
        61.5455897,
        28.2277583,
        62.0652736
      ]
    },
    {
      "id": 37582,
      "name": "Melhus",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.2859208,
        "lng": 10.2773284
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.2573284,
        63.2659208,
        10.2973284,
        63.3059208
      ]
    },
    {
      "id": 37587,
      "name": "Scharding",
      "names": {
        "de": "Schärding"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.4569975,
        "lng": 13.4318
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.4268997,
        48.4421186,
        13.452293,
        48.4788887
      ]
    },
    {
      "id": 37610,
      "name": "Pa Daet",
      "names": {
        "es": "Pa Ataet",
        "zh": "帕刀",
        "id": "Daet PA",
        "th": "ป่า",
        "pt": "Pa doet"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.4993982,
        "lng": 99.9929974
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.9529974,
        19.4593982,
        100.0329974,
        19.5393982
      ]
    },
    {
      "id": 37646,
      "name": "Dan Khun Thot",
      "names": {
        "zh": "丹坤塞",
        "th": "แดนขุนทอด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2087873,
        "lng": 101.7688474
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.7288474,
        15.1687873,
        101.8088474,
        15.2487873
      ]
    },
    {
      "id": 37654,
      "name": "Bagrationovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.38667,
        "lng": 20.63964
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        20.63959,
        54.38662,
        20.63969,
        54.38672
      ]
    },
    {
      "id": 37656,
      "name": "Bacsalmas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.127783,
        "lng": 19.330666
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.317269,
        46.113207,
        19.351485,
        46.138868
      ]
    },
    {
      "id": 37667,
      "name": "Silacayoapam",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.504429,
        "lng": -98.140566
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -98.146622,
        17.498079,
        -98.131852,
        17.512606
      ]
    },
    {
      "id": 37678,
      "name": "San Jeronimo Tlacochahuaya",
      "names": {
        "es": "San Jerónimo Tlacochahuaya",
        "fr": "San Jeronimo Tlacocochahuaya",
        "zh": "San Jeronimo Tlacochahauaya",
        "ja": "サンエロニモトラコシャヤ",
        "pt": "San Jeronimo Tlacochauaya"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0180061,
        "lng": -96.54498007266324
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.5951128,
        16.9709016,
        -96.5124526,
        17.066521
      ]
    },
    {
      "id": 37680,
      "name": "Balsthal",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.316631,
        "lng": 7.697252
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        7.670411,
        47.289014,
        7.745412,
        47.336974
      ]
    },
    {
      "id": 37717,
      "name": "Millingen aan de Rijn",
      "names": {
        "es": "Milingen aan de rijn",
        "ja": "ミレンゲンアアンデリジン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.865036,
        "lng": 6.050122
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.985753,
        51.837195,
        6.063486,
        51.881672
      ]
    },
    {
      "id": 37738,
      "name": "Zarnovica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4833983,
        "lng": 18.7215497
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.478673,
        48.309813,
        18.876198,
        48.633209
      ]
    },
    {
      "id": 37739,
      "name": "Grayvoron",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.4837728,
        "lng": 35.6712018
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        35.6538051,
        50.4584091,
        35.7199216,
        50.4951849
      ]
    },
    {
      "id": 37779,
      "name": "Bua Chet",
      "names": {
        "es": "Chet bua",
        "id": "Bua"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7868918,
        "lng": 102.38651572511668
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.3864503,
        15.7868461,
        102.3865812,
        15.7869376
      ]
    },
    {
      "id": 37794,
      "name": "Dalaba",
      "names": {
        "zh": "达拉巴"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.6911854,
        "lng": -12.2516243
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -12.2916243,
        10.6511854,
        -12.2116243,
        10.7311854
      ]
    },
    {
      "id": 37799,
      "name": "Atengo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2720806,
        "lng": -104.2348266
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.2548266,
        20.2520806,
        -104.2148266,
        20.2920806
      ]
    },
    {
      "id": 37801,
      "name": "Wiset Chaichan",
      "names": {
        "fr": "Wiset Chaican",
        "zh": "聪明的Chaichan",
        "ja": "座席を担当します",
        "pt": "Chaichan da Wiset"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.58764,
        "lng": 100.35214
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37821,
      "name": "Loches",
      "names": {
        "zh": "洛什"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.1281692,
        "lng": 0.9979549
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.9258825,
        47.0839878,
        1.0230831,
        47.1606259
      ]
    },
    {
      "id": 37830,
      "name": "Nikhom Kham Soi",
      "names": {
        "th": "Nikhom Kham ซอย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3749548,
        "lng": 104.5497895
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.5097895,
        16.3349548,
        104.5897895,
        16.4149548
      ]
    },
    {
      "id": 37838,
      "name": "Ingeniero Guillermo N. Juarez",
      "names": {
        "es": "Ingeniero Guillermo N. Juárez",
        "zh": "Ingeniero Guillermo N.Juarez",
        "ja": "Ingeniero Guillermo N. Guarez",
        "pt": "Ingenero Guillermo N. Juarez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -23.8992194,
        "lng": -61.84865390275651
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.849888,
        -23.900238,
        -61.8472321,
        -23.8983504
      ]
    },
    {
      "id": 37859,
      "name": "Lanchkhuti",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.0867738,
        "lng": 42.0308461224039
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        42.0112252,
        42.0646191,
        42.0984936,
        42.1090004
      ]
    },
    {
      "id": 37860,
      "name": "Halych",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.106814,
        "lng": 24.716832
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.707722,
        49.098153,
        24.726092,
        49.116524
      ]
    },
    {
      "id": 37891,
      "name": "Portel",
      "names": {
        "zh": "波特尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.30859,
        "lng": -7.70497
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.9364919,
        38.188454,
        -7.4569925,
        38.4386036
      ]
    },
    {
      "id": 37906,
      "name": "Rumelange",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.459701,
        "lng": 6.032366
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        5.978752,
        49.448261,
        6.04363,
        49.476308
      ]
    },
    {
      "id": 37908,
      "name": "Si Prachan",
      "names": {
        "fr": "Si Prachaan",
        "it": "Si pracan",
        "th": "ศรีประต้น"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6196076,
        "lng": 100.1454289
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.1054289,
        14.5796076,
        100.1854289,
        14.6596076
      ]
    },
    {
      "id": 37911,
      "name": "Hjo",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.304973,
        "lng": 14.279569
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        14.246717,
        58.290062,
        14.305379,
        58.317611
      ]
    },
    {
      "id": 37914,
      "name": "Tervel",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7480676,
        "lng": 27.4058887
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        27.3415136,
        43.7061943,
        27.4748742,
        43.7819077
      ]
    },
    {
      "id": 37925,
      "name": "Le Blanc",
      "names": {
        "zh": "勒碧",
        "ja": "ルブラン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.633292,
        "lng": 1.062694
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        1.0151997,
        46.5946092,
        1.1759493,
        46.6755714
      ]
    },
    {
      "id": 37946,
      "name": "Kittila",
      "names": {
        "zh": "基蒂莱",
        "it": "Kittilä",
        "id": "Kittilä"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 67.651985,
        "lng": 24.9095086
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.0932129,
        67.1511871,
        26.0078362,
        68.3952609
      ]
    },
    {
      "id": 37961,
      "name": "Albania",
      "names": {
        "fr": "L'Albanie",
        "zh": "阿尔巴尼亚",
        "de": "Albanien",
        "ja": "アルバニア",
        "th": "แอลเบเนีย",
        "pt": "Albânia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3290058,
        "lng": -75.8786065
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.9186065,
        1.2890058,
        -75.8386065,
        1.3690058
      ]
    },
    {
      "id": 37966,
      "name": "Phop Phra",
      "names": {
        "th": "ภูพี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.384502,
        "lng": 98.690252
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.675328,
        16.369081,
        98.705044,
        16.41878
      ]
    },
    {
      "id": 37972,
      "name": "Szentlorinc",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.0415605,
        "lng": 17.988914
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.9692589,
        46.0117856,
        18.0437946,
        46.0818625
      ]
    },
    {
      "id": 37983,
      "name": "Sabrosa",
      "names": {
        "zh": "萨布罗萨"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2663847,
        "lng": -7.5757615
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.6581734,
        41.1561174,
        -7.5314974,
        41.4125481
      ]
    },
    {
      "id": 37989,
      "name": "Penon Blanco",
      "names": {
        "ja": "ペノンブランコ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.7837394,
        "lng": -104.03661307176188
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.0544403,
        24.7637877,
        -104.0091882,
        24.8040416
      ]
    },
    {
      "id": 37990,
      "name": "Tasco",
      "names": {
        "zh": "塔斯科"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9099806,
        "lng": -72.7812406
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.8212406,
        5.8699806,
        -72.7412406,
        5.9499806
      ]
    },
    {
      "id": 37996,
      "name": "Tabuaco",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1173591,
        "lng": -7.5662701
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.6402841,
        41.0057014,
        -7.4746304,
        41.1741619
      ]
    },
    {
      "id": 38002,
      "name": "Carrazeda de Anciaes",
      "names": {
        "zh": "Carrazeda de Ancianes",
        "it": "Carrazeda de Anciales",
        "id": "Carrazeda de ancies",
        "ja": "Carazeda de Anciaes",
        "pt": "Carrazeda de Ancias"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.24684,
        "lng": -7.29996
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.31996,
        41.22684,
        -7.27996,
        41.26684
      ]
    },
    {
      "id": 38003,
      "name": "Santa Maria Yucuhiti",
      "names": {
        "zh": "圣玛丽亚尤加利",
        "ja": "サンタマリアユーヒティ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.018056,
        "lng": -97.769444
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.789444,
        16.998056,
        -97.749444,
        17.038056
      ]
    },
    {
      "id": 38046,
      "name": "Manavgat",
      "names": {
        "zh": "马纳夫加特",
        "ja": "マナビガト"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.7870124,
        "lng": 31.4406667
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.0316931,
        36.6624636,
        31.765498,
        37.4424105
      ]
    },
    {
      "id": 38069,
      "name": "General Toshevo",
      "names": {
        "fr": "Général Toshevo",
        "zh": "一般Toshevo",
        "it": "Generale Toshevo",
        "id": "Jenderal Toshevo",
        "ja": "一般的なToshevo",
        "th": "ทั่วไป Toshevo"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7001477,
        "lng": 28.0362083
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        27.9723162,
        43.6716513,
        28.0967702,
        43.788297
      ]
    },
    {
      "id": 38074,
      "name": "Sai Mun",
      "names": {
        "zh": "泗门",
        "ja": "サイムン",
        "th": "ทรายมูล"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9441242,
        "lng": 104.2085204
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        104.1685204,
        15.9041242,
        104.2485204,
        15.9841242
      ]
    },
    {
      "id": 38107,
      "name": "Stavyshche",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3897213,
        "lng": 30.1921389
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        30.1481358,
        49.3761644,
        30.243741,
        49.4072619
      ]
    },
    {
      "id": 38128,
      "name": "Yayladagi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 35.9029545,
        "lng": 36.0625987
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.9177629,
        35.8058974,
        36.2181,
        36.1286489
      ]
    },
    {
      "id": 38137,
      "name": "Villa Cura Brochero",
      "names": {
        "ja": "ヴィラカーラブローチェロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.7059759,
        "lng": -65.0185177
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.0385486,
        -31.7243633,
        -65.0030458,
        -31.683731
      ]
    },
    {
      "id": 38147,
      "name": "Mantta",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.0294216,
        "lng": 24.6232138
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.5832138,
        61.9894216,
        24.6632138,
        62.0694216
      ]
    },
    {
      "id": 38159,
      "name": "Mahdalynivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.92102,
        "lng": 34.91558
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        34.89558,
        48.90102,
        34.93558,
        48.94102
      ]
    },
    {
      "id": 38171,
      "name": "Armamar",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.10765,
        "lng": -7.69139
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.71139,
        41.08765,
        -7.67139,
        41.12765
      ]
    },
    {
      "id": 38175,
      "name": "Krk",
      "names": {
        "zh": "克尔克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0269806,
        "lng": 14.5752862
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        14.5507145,
        45.0125475,
        14.6234979,
        45.0497483
      ]
    },
    {
      "id": 38179,
      "name": "Ihsim",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.72078,
        "lng": 36.55631
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.53631,
        35.70078,
        36.57631,
        35.74078
      ]
    },
    {
      "id": 38201,
      "name": "Messini",
      "names": {
        "zh": "麦西尼"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.05111,
        "lng": 22.00778
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.98778,
        37.03111,
        22.02778,
        37.07111
      ]
    },
    {
      "id": 38206,
      "name": "Zalaszentgrot",
      "names": {
        "zh": "佐洛圣格罗特"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9438023,
        "lng": 17.0790205
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.0065144,
        46.8668131,
        17.1443654,
        46.9852901
      ]
    },
    {
      "id": 38216,
      "name": "Pinarbasi",
      "names": {
        "zh": "珀纳尔巴舍"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7214464,
        "lng": 36.3941324
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.9829581,
        38.4293094,
        36.9597697,
        39.1816731
      ]
    },
    {
      "id": 38219,
      "name": "Svislach",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.036549,
        "lng": 24.094183
      },
      "country": "Belarus",
      "importance": 2,
      "bbox": [
        24.0772654,
        53.0231471,
        24.1242676,
        53.0474761
      ]
    },
    {
      "id": 38232,
      "name": "Florian",
      "names": {
        "zh": "弗洛里安",
        "ja": "フロリアン",
        "th": "ฟลอเรียน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8024191,
        "lng": -73.9709342
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.9744192,
        5.8000788,
        -73.9683501,
        5.8082369
      ]
    },
    {
      "id": 38238,
      "name": "Sindirgi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.2393201,
        "lng": 28.175362790479483
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.1620464,
        39.231679,
        28.1887608,
        39.2480576
      ]
    },
    {
      "id": 38241,
      "name": "Encs",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.3305743,
        "lng": 21.1222112
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        21.0742969,
        48.2942009,
        21.1529655,
        48.3930282
      ]
    },
    {
      "id": 38262,
      "name": "Astrakhan",
      "names": {
        "zh": "阿斯特拉罕",
        "de": "Astrachan",
        "ja": "アストラカン",
        "th": "มณฑลแอซทระแคน"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 46.3652486,
        "lng": 48.05521686177834
      },
      "country": "Kazakhstan",
      "importance": 2,
      "bbox": [
        47.8711165,
        46.259716,
        48.201888,
        46.4708482
      ]
    },
    {
      "id": 38267,
      "name": "Myrina",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.8754188,
        "lng": 25.0634491
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        25.0234491,
        39.8354188,
        25.1034491,
        39.9154188
      ]
    },
    {
      "id": 38270,
      "name": "Teplyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.660868,
        "lng": 29.75472
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.73472,
        48.640868,
        29.77472,
        48.680868
      ]
    },
    {
      "id": 38294,
      "name": "Neustadt",
      "names": {
        "zh": "诺伊施塔特",
        "ja": "ノイシュタット",
        "th": "ตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.84426205,
        "lng": 9.10437940314108
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        9.049869,
        50.8180981,
        9.150879,
        50.8703159
      ]
    },
    {
      "id": 38314,
      "name": "Altkirch",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6238204,
        "lng": 7.2401542
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        7.2199969,
        47.6029458,
        7.2691153,
        47.6382555
      ]
    },
    {
      "id": 38327,
      "name": "Tammela",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8087874,
        "lng": 23.7598248
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.4983063,
        60.6377457,
        24.0632208,
        60.9745077
      ]
    },
    {
      "id": 38338,
      "name": "Arbuzynka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9076,
        "lng": 31.31516
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.2918117,
        47.8751617,
        31.3552263,
        47.936544
      ]
    },
    {
      "id": 38339,
      "name": "Nogent-sur-Seine",
      "names": {
        "es": "Nogent-sur-Sena",
        "zh": "诺让塞纳河畔",
        "ja": "nogentシュルセーヌ",
        "th": "nogent-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4936239,
        "lng": 3.503018
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        3.502968,
        48.4935739,
        3.503068,
        48.4936739
      ]
    },
    {
      "id": 38379,
      "name": "Silivri",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0742476,
        "lng": 28.2481709
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.9708481,
        41.0320583,
        28.3954521,
        41.3637851
      ]
    },
    {
      "id": 38380,
      "name": "Vodice",
      "names": {
        "ja": "ヴォディツェ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.7602196,
        "lng": 15.7788084
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        15.7350322,
        43.6801194,
        15.8593809,
        43.9144577
      ]
    },
    {
      "id": 38381,
      "name": "Figueira de Castelo Rodrigo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.89751,
        "lng": -6.96279
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.1222472,
        40.7485096,
        -6.8001553,
        41.0364126
      ]
    },
    {
      "id": 38394,
      "name": "Saray",
      "names": {
        "zh": "萨拉伊",
        "th": "สะเหร่"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4426795,
        "lng": 27.9213992
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.6152594,
        41.2778182,
        28.155456,
        41.6163247
      ]
    },
    {
      "id": 38397,
      "name": "Mamers",
      "names": {
        "zh": "马梅尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3489647,
        "lng": 0.3695638
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.3695138,
        48.3489147,
        0.3696138,
        48.3490147
      ]
    },
    {
      "id": 38398,
      "name": "Ivankovo",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2886254,
        "lng": 18.6842314
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.6642314,
        45.2686254,
        18.7042314,
        45.3086254
      ]
    },
    {
      "id": 38400,
      "name": "Donji Miholjac",
      "names": {
        "it": "Donji Mioljac",
        "ja": "Miholjac Donji"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.757964,
        "lng": 18.163045
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.137233,
        45.749517,
        18.190154,
        45.767813
      ]
    },
    {
      "id": 38405,
      "name": "Aradan",
      "names": {
        "zh": "阿拉丹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.24941,
        "lng": 52.49422
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        52.47422,
        35.22941,
        52.51422,
        35.26941
      ]
    },
    {
      "id": 38406,
      "name": "Prathai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5315738,
        "lng": 102.7172623
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.6772623,
        15.4915738,
        102.7572623,
        15.5715738
      ]
    },
    {
      "id": 38424,
      "name": "San Andres Huaxpaltepec",
      "names": {
        "es": "San Andrés Huaxpaltepec",
        "zh": "圣安德里斯Huaxpaltepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.330158,
        "lng": -97.915875
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.929241,
        16.322923,
        -97.905459,
        16.33711
      ]
    },
    {
      "id": 38428,
      "name": "Nong Song Hong",
      "names": {
        "es": "Nong canción hong",
        "zh": "浓松洪",
        "it": "Canto di nong di hong",
        "th": "หนองเพลงหง",
        "pt": "Nong canção hong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7310442,
        "lng": 102.793332
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.753332,
        15.6910442,
        102.833332,
        15.7710442
      ]
    },
    {
      "id": 38451,
      "name": "Gole",
      "names": {
        "zh": "戈尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 52.166489,
        "lng": 20.4936506
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        20.4830329,
        52.1331546,
        20.5115343,
        52.1707949
      ]
    },
    {
      "id": 38474,
      "name": "Elva",
      "names": {
        "zh": "萧亚轩"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.2233379,
        "lng": 26.4131027
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        26.3849002,
        58.2081236,
        26.459578,
        58.2440714
      ]
    },
    {
      "id": 38497,
      "name": "Carsibasi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0825808,
        "lng": 39.3784858
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.3447804,
        40.9500041,
        39.4541779,
        41.1076502
      ]
    },
    {
      "id": 38501,
      "name": "Bulanik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.5599159,
        "lng": 43.3634596
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        43.3434596,
        40.5399159,
        43.3834596,
        40.5799159
      ]
    },
    {
      "id": 38524,
      "name": "Rong Kham",
      "names": {
        "zh": "荣kham",
        "th": "จำขาม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.2700202,
        "lng": 103.7493954
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.7093954,
        16.2300202,
        103.7893954,
        16.3100202
      ]
    },
    {
      "id": 38525,
      "name": "Phanna Nikhom",
      "names": {
        "fr": "Phana nikhom",
        "ja": "パンナニコム",
        "th": "พรรณนานิคม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.3499688,
        "lng": 103.849296
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.809296,
        17.3099688,
        103.889296,
        17.3899688
      ]
    },
    {
      "id": 38529,
      "name": "Morahalom",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.217324,
        "lng": 19.884867
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.868825,
        46.207036,
        19.901294,
        46.22951
      ]
    },
    {
      "id": 38536,
      "name": "Khur",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.7761331,
        "lng": 55.0828304
      },
      "country": "Iran",
      "importance": 2,
      "bbox": [
        55.0428304,
        33.7361331,
        55.1228304,
        33.8161331
      ]
    },
    {
      "id": 38566,
      "name": "Siuntio",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.135633,
        "lng": 24.22403
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.213992,
        60.128807,
        24.231529,
        60.140737
      ]
    },
    {
      "id": 38576,
      "name": "Gelnica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8534399,
        "lng": 20.93779
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        20.529609,
        48.667426,
        21.073338,
        48.964694
      ]
    },
    {
      "id": 38577,
      "name": "Figueiro dos Vinhos",
      "names": {
        "ja": "figueirodos vinhos"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.902591,
        "lng": -8.273779
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.295189,
        39.895904,
        -8.267332,
        39.919288
      ]
    },
    {
      "id": 38585,
      "name": "Takhtamukay",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.9215,
        "lng": 38.99583
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.97583,
        44.9015,
        39.01583,
        44.9415
      ]
    },
    {
      "id": 38609,
      "name": "Heliconia",
      "names": {
        "zh": "海丽空苑"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2076404,
        "lng": -75.7339685
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.8197545,
        6.1449425,
        -75.6932444,
        6.2678152
      ]
    },
    {
      "id": 38611,
      "name": "Prinzapolka",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.4065545,
        "lng": -83.5650013
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -83.5670413,
        13.4044141,
        -83.5604976,
        13.4102063
      ]
    },
    {
      "id": 38631,
      "name": "Sorgun",
      "names": {
        "zh": "索尔贡"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8098675,
        "lng": 35.1854305
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.9977207,
        39.5022855,
        35.61528,
        40.0998821
      ]
    },
    {
      "id": 38637,
      "name": "Sachkhere",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.3401451,
        "lng": 43.405233
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        43.3794093,
        42.3239822,
        43.4244235,
        42.3499771
      ]
    },
    {
      "id": 38639,
      "name": "San Cristobal Acasaguastlan",
      "names": {
        "es": "San Cristóbal Acasaguastlan",
        "ja": "サンクリストバルAcasaguastlan"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.925222,
        "lng": -89.8748994
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -89.8948994,
        14.905222,
        -89.8548994,
        14.945222
      ]
    },
    {
      "id": 38647,
      "name": "Devin",
      "names": {
        "zh": "德文"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 41.7433556,
        "lng": 24.3984625
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        24.3584625,
        41.7033556,
        24.4384625,
        41.7833556
      ]
    },
    {
      "id": 38648,
      "name": "Dusheti",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.0844709,
        "lng": 44.6959371
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        44.679036,
        42.0719882,
        44.7069041,
        42.0929092
      ]
    },
    {
      "id": 38655,
      "name": "Gyangze",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9198144,
        "lng": 89.6011926
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        89.5627133,
        28.8646164,
        89.6475465,
        28.9741202
      ]
    },
    {
      "id": 38677,
      "name": "Adilcevaz",
      "names": {
        "zh": "阿迪尔杰瓦兹"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8043058,
        "lng": 42.7346369
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.582064,
        38.6238444,
        43.2009955,
        39.0240651
      ]
    },
    {
      "id": 38680,
      "name": "Mantyharju",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.416127,
        "lng": 26.876381
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.804776,
        61.387617,
        26.914115,
        61.441655
      ]
    },
    {
      "id": 38686,
      "name": "Tonya",
      "names": {
        "zh": "托尼亚",
        "ja": "トーニャ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8859154,
        "lng": 39.2909894
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.2082749,
        40.7250289,
        39.3774503,
        40.9651658
      ]
    },
    {
      "id": 38690,
      "name": "Kuybyshevo",
      "names": {
        "zh": "古比雪沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.8117551,
        "lng": 38.908325
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        38.8829198,
        47.7936397,
        38.9333676,
        47.8273771
      ]
    },
    {
      "id": 38691,
      "name": "Khok Samrong",
      "names": {
        "th": "โคกสำโรง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.066814,
        "lng": 100.718922
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.696875386,
        15.0471731956,
        100.803367806,
        15.097072593
      ]
    },
    {
      "id": 38696,
      "name": "Rusko",
      "names": {
        "zh": "鲁斯科"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5407302,
        "lng": 22.2208801
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.1438329,
        60.5037964,
        22.4149969,
        60.7372962
      ]
    },
    {
      "id": 38699,
      "name": "Polygyros",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.37804,
        "lng": 23.4425494
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.4025494,
        40.33804,
        23.4825494,
        40.41804
      ]
    },
    {
      "id": 38712,
      "name": "Amasra",
      "names": {
        "zh": "阿马萨"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.7489175,
        "lng": 32.3866959
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.2794684,
        41.6729648,
        32.583,
        41.8115579
      ]
    },
    {
      "id": 38723,
      "name": "Kayl",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.4861302,
        "lng": 6.041472
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        5.993067,
        49.4478587,
        6.0616474,
        49.506527
      ]
    },
    {
      "id": 38768,
      "name": "Lom Kao",
      "names": {
        "ja": "ロムカオ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8876475,
        "lng": 101.2271902
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.1871902,
        16.8476475,
        101.2671902,
        16.9276475
      ]
    },
    {
      "id": 38769,
      "name": "Arapgir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0422423,
        "lng": 38.4898342
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.1955574,
        38.7442356,
        38.6780242,
        39.1429424
      ]
    },
    {
      "id": 38770,
      "name": "Sumeg",
      "names": {
        "de": "Sümeg"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9784329,
        "lng": 17.2811657
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.2214484,
        46.91401,
        17.3834642,
        47.0088779
      ]
    },
    {
      "id": 38783,
      "name": "Aralik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.873699,
        "lng": 44.5160371
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        44.284426,
        39.627336,
        44.8176638,
        39.9918571
      ]
    },
    {
      "id": 38790,
      "name": "Bo",
      "names": {
        "zh": "博",
        "ja": "ボー",
        "th": "บ่อ"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.4572,
        "lng": 9.0314
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 38805,
      "name": "Suhut",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5339612,
        "lng": 30.5461288
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.3724662,
        38.2202332,
        30.7981917,
        38.6349626
      ]
    },
    {
      "id": 38825,
      "name": "Chiquilistlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0894396,
        "lng": -103.8621973
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.9021973,
        20.0494396,
        -103.8221973,
        20.1294396
      ]
    },
    {
      "id": 38864,
      "name": "Maintirano",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -18.0608947,
        "lng": 44.023951
      },
      "country": "Madagascar",
      "importance": 2,
      "bbox": [
        43.983951,
        -18.1008947,
        44.063951,
        -18.0208947
      ]
    },
    {
      "id": 38865,
      "name": "Megalopoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.4012364,
        "lng": 22.1356726
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.0956726,
        37.3612364,
        22.1756726,
        37.4412364
      ]
    },
    {
      "id": 38866,
      "name": "Concepcion de Buenos Aires",
      "names": {
        "es": "Concepción de Buenos Aires",
        "ja": "コンセプシオンデブエノスエアーズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9786433,
        "lng": -103.2320743346699
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.3333462,
        19.8776523,
        -103.1505352,
        20.0795025
      ]
    },
    {
      "id": 38871,
      "name": "Klaebu",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.2990156,
        "lng": 10.4840596
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.4640596,
        63.2790156,
        10.5040596,
        63.3190156
      ]
    },
    {
      "id": 38877,
      "name": "Pueai Noi",
      "names": {
        "es": "Peai noi",
        "fr": "Puaii no",
        "ja": "牡羊座ノイ",
        "th": "Pieai Noi",
        "pt": "Puai noi"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.86978,
        "lng": 102.90767
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.88767,
        15.84978,
        102.92767,
        15.88978
      ]
    },
    {
      "id": 38890,
      "name": "Dinguiraye",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 11.292368,
        "lng": -10.714459
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -10.754459,
        11.252368,
        -10.674459,
        11.332368
      ]
    },
    {
      "id": 38902,
      "name": "Spydeberg",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6152567,
        "lng": 11.0788072
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.0588072,
        59.5952567,
        11.0988072,
        59.6352567
      ]
    },
    {
      "id": 38920,
      "name": "Steinfort",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.659688,
        "lng": 5.914874
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        5.882706,
        49.627333,
        5.959873,
        49.676182
      ]
    },
    {
      "id": 38930,
      "name": "Ahtari",
      "names": {
        "id": "Ähtäri"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.5499772,
        "lng": 24.0702272
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.8352467,
        62.3633325,
        24.5842994,
        62.764039
      ]
    },
    {
      "id": 38946,
      "name": "Sandnessjoen",
      "names": {
        "zh": "桑内舍恩"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 66.0215451,
        "lng": 12.6317232
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        12.5917232,
        65.9815451,
        12.6717232,
        66.0615451
      ]
    },
    {
      "id": 38961,
      "name": "Munchwilen",
      "names": {
        "fr": "Münchwilen",
        "de": "Münchwilen",
        "it": "Münchwilen"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4777697,
        "lng": 8.9976456
      },
      "country": "Switzerland",
      "importance": 2,
      "bbox": [
        8.9626325,
        47.4608999,
        9.0307268,
        47.5002666
      ]
    },
    {
      "id": 38968,
      "name": "Turkoglu",
      "names": {
        "zh": "特科格鲁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3864782,
        "lng": 36.8474237
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.6305018,
        37.2161761,
        37.0497166,
        37.4838351
      ]
    },
    {
      "id": 38970,
      "name": "Ra's al Khashufah",
      "names": {
        "fr": "Ra Al Khashaufah",
        "zh": "ra的al khashufah",
        "ja": "RAのアルカシュファ",
        "pt": "Ra al Khasfá"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.84578,
        "lng": 36.05389
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 38975,
      "name": "Alban",
      "names": {
        "zh": "阿尔万",
        "ja": "アルバン",
        "th": "เบลล่า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8780617,
        "lng": -74.4384462
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4920603,
        4.83288,
        -74.4026442,
        4.9563546
      ]
    },
    {
      "id": 38976,
      "name": "Coloso",
      "names": {
        "zh": "科洛索"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.4941082,
        "lng": -75.3530874
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.4135314,
        9.4389733,
        -75.2818114,
        9.6360404
      ]
    },
    {
      "id": 38977,
      "name": "Monchique",
      "names": {
        "zh": "蒙希克"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.3179725,
        "lng": -8.5558655
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.7551336,
        37.2098179,
        -8.4199787,
        37.4119846
      ]
    },
    {
      "id": 38983,
      "name": "San Simon de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.02276,
        "lng": -100.00645
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.02645,
        19.00276,
        -99.98645,
        19.04276
      ]
    },
    {
      "id": 39002,
      "name": "Magdalena Tequisistlan",
      "names": {
        "fr": "Magdalena Tequisisistlan",
        "zh": "Magdalena Tequnisistlan",
        "it": "Magdalena Tequisisistlan",
        "id": "Tequisistlan magdalena",
        "pt": "Tequisistlan de Magdalena"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.399167,
        "lng": -95.603333
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.623333,
        16.379167,
        -95.583333,
        16.419167
      ]
    },
    {
      "id": 39036,
      "name": "Tapa",
      "names": {
        "zh": "塔帕",
        "th": "ปาสร้อย"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 59.2618575,
        "lng": 25.9626869
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        25.9025477,
        59.2227499,
        25.9913497,
        59.278244
      ]
    },
    {
      "id": 39059,
      "name": "Penela",
      "names": {
        "zh": "佩内拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.029442,
        "lng": -8.3902395
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4745867,
        39.923791,
        -8.2701186,
        40.0851887
      ]
    },
    {
      "id": 39071,
      "name": "Chepes",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.349987,
        "lng": -66.599951
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.6199506039,
        -31.3699869601,
        -66.5799506039,
        -31.3299869601
      ]
    },
    {
      "id": 39077,
      "name": "Volodarka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5224979,
        "lng": 29.9280475
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.8869268,
        49.508574,
        29.9502457,
        49.5430133
      ]
    },
    {
      "id": 39088,
      "name": "Montmorillon",
      "names": {
        "zh": "蒙莫里永"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.427526,
        "lng": 0.869659
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        0.825729,
        46.381373,
        0.962288,
        46.476629
      ]
    },
    {
      "id": 39091,
      "name": "Bascharage",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5648895,
        "lng": 5.9169867695999745
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        5.885042,
        49.54619,
        5.957964,
        49.5835726
      ]
    },
    {
      "id": 39092,
      "name": "Froland",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.587284600000004,
        "lng": 8.600437985137697
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        7.9558958,
        58.4527749,
        8.7596971,
        58.7216465
      ]
    },
    {
      "id": 39098,
      "name": "Akcadag",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3443581,
        "lng": 37.9711325
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.58322,
        38.2089076,
        38.1764603,
        38.5295018
      ]
    },
    {
      "id": 39112,
      "name": "Los Reyes",
      "names": {
        "zh": "洛杉矶雷耶斯",
        "ja": "ロスレイ",
        "th": "ลอสเรเยส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6715861,
        "lng": -97.0436089
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.0836089,
        18.6315861,
        -97.0036089,
        18.7115861
      ]
    },
    {
      "id": 39142,
      "name": "Porvenir",
      "names": {
        "zh": "波韦尼尔"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -53.2956738,
        "lng": -70.3687401
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -70.7112265,
        -53.7998837,
        -68.6063661,
        -52.8983152
      ]
    },
    {
      "id": 39161,
      "name": "Wanon Niwat",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.6342731,
        "lng": 103.7547698
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.7147698,
        17.5942731,
        103.7947698,
        17.6742731
      ]
    },
    {
      "id": 39171,
      "name": "Murca",
      "names": {
        "zh": "穆尔萨"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.406018,
        "lng": -7.45103
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.457987,
        41.402274,
        -7.439859,
        41.412541
      ]
    },
    {
      "id": 39191,
      "name": "Sutamarchan",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6204803,
        "lng": -73.6204419
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.6604419,
        5.5804803,
        -73.5804419,
        5.6604803
      ]
    },
    {
      "id": 39193,
      "name": "Al Qutaylibiyah",
      "names": {
        "ja": "アルQutaylibiyah",
        "th": "อัล Qutaylibiyah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.29203,
        "lng": 36.01592
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        35.99592,
        35.27203,
        36.03592,
        35.31203
      ]
    },
    {
      "id": 39201,
      "name": "Kivioli",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.352776,
        "lng": 26.960814
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        26.915355,
        59.337312,
        27.024691,
        59.368805
      ]
    },
    {
      "id": 39206,
      "name": "Kazimierza Wielka",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.267489,
        "lng": 20.4853308
      },
      "country": "Poland",
      "importance": 2,
      "bbox": [
        20.4621391,
        50.2550381,
        20.5101596,
        50.2836758
      ]
    },
    {
      "id": 39231,
      "name": "Lesparre-Medoc",
      "names": {
        "es": "Lesparre-médoc",
        "fr": "Lesparre-Médoc",
        "zh": "lesparre-梅多克",
        "ja": "-medoc lesparre"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.3081854,
        "lng": -0.9379402
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -0.970793,
        45.2191661,
        -0.8762054,
        45.3399175
      ]
    },
    {
      "id": 39238,
      "name": "Lagodekhi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.82681,
        "lng": 46.27667
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        46.25667,
        41.80681,
        46.29667,
        41.84681
      ]
    },
    {
      "id": 39245,
      "name": "San Miguel Panixtlahuaca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.259983,
        "lng": -97.377769
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.3870359077,
        16.2556736186,
        -97.3702011956,
        16.2645978402
      ]
    },
    {
      "id": 39250,
      "name": "Kungsor",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 59.421969,
        "lng": 16.103867
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        16.066209,
        59.408066,
        16.138172,
        59.433379
      ]
    },
    {
      "id": 39251,
      "name": "Vidigueira",
      "names": {
        "zh": "维迪盖拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.20935,
        "lng": -7.801097
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.809218,
        38.199194,
        -7.792453,
        38.216287
      ]
    },
    {
      "id": 39252,
      "name": "Kao Liao",
      "names": {
        "zh": "高廖",
        "ja": "カオリオ",
        "th": "Kao เหลียว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.85053,
        "lng": 100.07914
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.05914,
        15.83053,
        100.09914,
        15.87053
      ]
    },
    {
      "id": 39261,
      "name": "Eurajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2018184,
        "lng": 21.7296943
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        20.8121335,
        61.1381321,
        21.9459406,
        61.5303891
      ]
    },
    {
      "id": 39262,
      "name": "Dedoplists'q'aro",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.46556,
        "lng": 46.10528
      },
      "country": "Georgia",
      "importance": 2,
      "bbox": [
        46.08528,
        41.44556,
        46.12528,
        41.48556
      ]
    },
    {
      "id": 39306,
      "name": "Psachna",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.58333,
        "lng": 23.63333
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        23.61333,
        38.56333,
        23.65333,
        38.60333
      ]
    },
    {
      "id": 39311,
      "name": "Mucuchies",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.74899,
        "lng": -70.91418
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -70.93418,
        8.72899,
        -70.89418,
        8.76899
      ]
    },
    {
      "id": 39318,
      "name": "Yildizeli",
      "names": {
        "zh": "耶尔德泽利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8667215,
        "lng": 36.6016346
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.9826278,
        39.5667651,
        36.9873846,
        40.2187313
      ]
    },
    {
      "id": 39323,
      "name": "Kvinesdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.3168479,
        "lng": 6.9624335
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.9424335,
        58.2968479,
        6.9824335,
        58.3368479
      ]
    },
    {
      "id": 39332,
      "name": "Xylokastro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.076874,
        "lng": 22.636133
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.616133,
        38.056874,
        22.656133,
        38.096874
      ]
    },
    {
      "id": 39349,
      "name": "Ahlat",
      "names": {
        "zh": "阿赫拉特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7546534,
        "lng": 42.4833266
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.0854365,
        38.6228763,
        42.6857249,
        38.957869
      ]
    },
    {
      "id": 39352,
      "name": "Buharkent",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.96396,
        "lng": 28.7427
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.7227,
        37.94396,
        28.7627,
        37.98396
      ]
    },
    {
      "id": 39362,
      "name": "Cifteler",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.376217600000004,
        "lng": 31.04028544888592
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.0223703,
        39.3625999,
        31.0567961,
        39.3896255
      ]
    },
    {
      "id": 39415,
      "name": "Aljezur",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.31915,
        "lng": -8.8033
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.8233,
        37.29915,
        -8.7833,
        37.33915
      ]
    },
    {
      "id": 39422,
      "name": "Murovani Kurylivtsi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.724586,
        "lng": 27.516342
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        27.496342,
        48.704586,
        27.536342,
        48.744586
      ]
    },
    {
      "id": 39445,
      "name": "Visegrad",
      "names": {
        "zh": "维谢格拉德"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.78334505,
        "lng": 19.293666620222858
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        19.2823899,
        43.7748279,
        19.3060738,
        43.7919771
      ]
    },
    {
      "id": 39447,
      "name": "Borabue",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.040019,
        "lng": 103.1211303
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.0811303,
        16.000019,
        103.1611303,
        16.080019
      ]
    },
    {
      "id": 39460,
      "name": "Huai Mek",
      "names": {
        "zh": "淮梅克",
        "th": "ห้วยเม็ก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.600749,
        "lng": 103.2216315
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.0616315,
        16.440749,
        103.3816315,
        16.760749
      ]
    },
    {
      "id": 39463,
      "name": "Dikili",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0738718,
        "lng": 26.8906516
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        26.7686313,
        38.9143495,
        27.0771357,
        39.2438336
      ]
    },
    {
      "id": 39464,
      "name": "Vargarda",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.034826,
        "lng": 12.809174
      },
      "country": "Sweden",
      "importance": 2,
      "bbox": [
        12.785754,
        58.021825,
        12.840141,
        58.047843
      ]
    },
    {
      "id": 39475,
      "name": "Briey",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2480975,
        "lng": 5.9370519
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.9202649,
        49.2312426,
        6.032717,
        49.2856081
      ]
    },
    {
      "id": 39483,
      "name": "Dawran ad Daydah",
      "names": {
        "zh": "Dawran广告Daydah",
        "de": "Dawran-Anzeigen-Daydah",
        "ja": "Dawran ADデイダ",
        "pt": "Dawran adydah"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.73927,
        "lng": 44.20664
      },
      "country": "Yemen",
      "importance": 2,
      "bbox": [
        44.18664,
        14.71927,
        44.22664,
        14.75927
      ]
    },
    {
      "id": 39484,
      "name": "Poltar",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4287221,
        "lng": 19.7953995
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        19.7903995,
        48.4237221,
        19.8003995,
        48.4337221
      ]
    },
    {
      "id": 39485,
      "name": "Mezokovacshaza",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.41015,
        "lng": 20.921858
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.884865,
        46.397085,
        20.938781,
        46.422684
      ]
    },
    {
      "id": 39486,
      "name": "San Juan Numi",
      "names": {
        "es": "San Juan Numbi",
        "zh": "圣胡安Numi",
        "ja": "サンファン・ナンディ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.397292,
        "lng": -97.706668
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.710231,
        17.393251,
        -97.700686,
        17.400026
      ]
    },
    {
      "id": 39487,
      "name": "Can",
      "names": {
        "es": "Puedo",
        "fr": "Pouvez",
        "zh": "能够",
        "de": "Können",
        "it": "Può",
        "id": "Bisa",
        "ja": "できる",
        "th": "สามารถ",
        "pt": "Posso"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 49.1813403,
        "lng": -0.3635615
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        -0.413777,
        49.1530145,
        -0.3307285,
        49.2162655
      ]
    },
    {
      "id": 39501,
      "name": "Surnadal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.9228432,
        "lng": 8.787930646988247
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        8.2437061,
        62.7117709,
        9.3648315,
        63.133919
      ]
    },
    {
      "id": 39512,
      "name": "Moeiwadi",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.38944,
        "lng": 104.1572
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39517,
      "name": "`Assal al Ward",
      "names": {
        "zh": "“assal ar病房",
        "it": "`Assal al reparto",
        "ja": "`asal al区",
        "pt": "`Assal al enfermaria"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.86453,
        "lng": 36.4139
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.3939,
        33.84453,
        36.4339,
        33.88453
      ]
    },
    {
      "id": 39530,
      "name": "Cerkezkoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2862482,
        "lng": 27.999468
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.8906361,
        41.187423,
        28.0850459,
        41.3381136
      ]
    },
    {
      "id": 39546,
      "name": "Catalca",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1436804,
        "lng": 28.4605154
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.0709481,
        41.0557883,
        28.5952014,
        41.5833019
      ]
    },
    {
      "id": 39566,
      "name": "Phachi",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.45654,
        "lng": 100.733295
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.677677,
        14.409683,
        100.781369,
        14.502227
      ]
    },
    {
      "id": 39570,
      "name": "Alandroal",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7020061,
        "lng": -7.4038313
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.4438313,
        38.6620061,
        -7.3638313,
        38.7420061
      ]
    },
    {
      "id": 39572,
      "name": "Martonvasar",
      "names": {
        "zh": "毛尔通瓦沙尔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3141209,
        "lng": 18.7886273
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        18.7486273,
        47.2741209,
        18.8286273,
        47.3541209
      ]
    },
    {
      "id": 39575,
      "name": "Verkhovyna",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1538102,
        "lng": 24.82793
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        24.7701834,
        48.1128861,
        24.9021376,
        48.1792147
      ]
    },
    {
      "id": 39583,
      "name": "Cestica",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.37167,
        "lng": 16.12528
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        16.10528,
        46.35167,
        16.14528,
        46.39167
      ]
    },
    {
      "id": 39621,
      "name": "Varshets",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.194869,
        "lng": 23.285663
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        23.272734,
        43.181792,
        23.299026,
        43.206231
      ]
    },
    {
      "id": 39638,
      "name": "Tancoco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.285329,
        "lng": -97.790134
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.797513,
        21.277692,
        -97.782864,
        21.290897
      ]
    },
    {
      "id": 39656,
      "name": "Valer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.4643626,
        "lng": 10.922437627449577
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.7657744,
        59.3869869,
        11.1379169,
        59.5419728
      ]
    },
    {
      "id": 39663,
      "name": "Chernihivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.19486,
        "lng": 36.2075
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        36.1108374,
        47.1542079,
        36.2994718,
        47.2138008
      ]
    },
    {
      "id": 39673,
      "name": "Sveio",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5438554,
        "lng": 5.3545271
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        5.3345271,
        59.5238554,
        5.3745271,
        59.5638554
      ]
    },
    {
      "id": 39674,
      "name": "Civril",
      "names": {
        "zh": "奇夫里尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3005877,
        "lng": 29.7373813
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.5100821,
        37.9793522,
        30.0831852,
        38.4740857
      ]
    },
    {
      "id": 39684,
      "name": "Non Sila",
      "names": {
        "es": "No sila",
        "zh": "非SILA",
        "de": "Non-Sila",
        "id": "Non-sila",
        "ja": "非シラ",
        "th": "ไม่ใช่ศิลา",
        "pt": "Não sila"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9661359,
        "lng": 102.691403
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.531403,
        15.8061359,
        102.851403,
        16.1261359
      ]
    },
    {
      "id": 39695,
      "name": "Phayuha Khiri",
      "names": {
        "ja": "ファヤハKhiri",
        "th": "พยัยคิริ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.4581578,
        "lng": 100.1368859
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.0968859,
        15.4181578,
        100.1768859,
        15.4981578
      ]
    },
    {
      "id": 39702,
      "name": "Otatitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.177101,
        "lng": -96.0338646
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.0738646,
        18.137101,
        -95.9938646,
        18.217101
      ]
    },
    {
      "id": 39728,
      "name": "Incesu",
      "names": {
        "zh": "因杰苏"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6233834,
        "lng": 35.1838195
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.0450017,
        38.4367728,
        35.4644933,
        38.8363139
      ]
    },
    {
      "id": 39730,
      "name": "Veselynove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.356307,
        "lng": 31.2367979
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.2123322,
        47.3359585,
        31.2641504,
        47.3739344
      ]
    },
    {
      "id": 39738,
      "name": "Mondorf-les-Bains",
      "names": {
        "zh": "蒙多夫-莱斯班",
        "ja": "mondorf・レ・バン",
        "th": "mondorf-เลสแบงส์"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5071747,
        "lng": 6.2814238
      },
      "country": "Luxembourg",
      "importance": 2,
      "bbox": [
        6.2405834,
        49.49299,
        6.3242626,
        49.53438
      ]
    },
    {
      "id": 39739,
      "name": "Metapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 14.83333,
        "lng": -92.18333
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -92.20333,
        14.81333,
        -92.16333,
        14.85333
      ]
    },
    {
      "id": 39744,
      "name": "Tirebolu",
      "names": {
        "zh": "蒂雷博卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0072009,
        "lng": 38.8146309
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.731737,
        40.7911903,
        38.9633769,
        41.0435628
      ]
    },
    {
      "id": 39745,
      "name": "Ao Luek",
      "names": {
        "de": "Ao Leuk",
        "th": "อ่าวลุย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.379684,
        "lng": 98.69276
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.67276,
        8.359684,
        98.71276,
        8.399684
      ]
    },
    {
      "id": 39754,
      "name": "Turi",
      "names": {
        "zh": "图里"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.8101662,
        "lng": 25.4252349
      },
      "country": "Estonia",
      "importance": 2,
      "bbox": [
        25.3920008,
        58.790962,
        25.448599,
        58.824777
      ]
    },
    {
      "id": 39755,
      "name": "Tomislavgrad",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 43.71650215,
        "lng": 17.227392660101977
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2,
      "bbox": [
        17.195927,
        43.7017305,
        17.2612971,
        43.7311815
      ]
    },
    {
      "id": 39766,
      "name": "Debesy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.652351,
        "lng": 53.806393
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        53.7726217,
        57.6360021,
        53.8567634,
        57.6676283
      ]
    },
    {
      "id": 39775,
      "name": "Dobrovelychkivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.386083,
        "lng": 31.177729
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.157729,
        48.366083,
        31.197729,
        48.406083
      ]
    },
    {
      "id": 39807,
      "name": "Ku Kaeo",
      "names": {
        "es": "Kaeo",
        "de": "Ku kaoo"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.1758886,
        "lng": 103.1573007
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.1173007,
        17.1358886,
        103.1973007,
        17.2158886
      ]
    },
    {
      "id": 39808,
      "name": "Acigol",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5501551,
        "lng": 34.508966
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.3416491,
        38.3799665,
        34.6221492,
        38.6892937
      ]
    },
    {
      "id": 39839,
      "name": "Tamsweg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1272016,
        "lng": 13.8019413
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.8019413,
        47.1272016,
        13.8020703,
        47.1272023
      ]
    },
    {
      "id": 39856,
      "name": "Viana do Alentejo",
      "names": {
        "zh": "Viana做Alentejo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.333592,
        "lng": -8.002642
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.013423,
        38.327839,
        -7.992229,
        38.339774
      ]
    },
    {
      "id": 39869,
      "name": "Popilnia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.9452183,
        "lng": 29.4592233
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.4360766,
        49.9360652,
        29.4880196,
        49.9554446
      ]
    },
    {
      "id": 39871,
      "name": "Patnos",
      "names": {
        "zh": "帕特诺斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.2334202,
        "lng": 42.8612338
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        42.5967051,
        38.9299742,
        43.2162343,
        39.4204458
      ]
    },
    {
      "id": 39890,
      "name": "Doganhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1460169,
        "lng": 31.6768638
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        31.4889637,
        37.9884231,
        31.8006096,
        38.2874269
      ]
    },
    {
      "id": 39900,
      "name": "Melk",
      "names": {
        "zh": "修道院"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2272068,
        "lng": 15.3369992
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.2864352,
        48.1818858,
        15.3838323,
        48.2415958
      ]
    },
    {
      "id": 39914,
      "name": "Pong",
      "names": {
        "zh": "乒乓",
        "ja": "ポン",
        "th": "พงษ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.148938,
        "lng": 100.2740052
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.2340052,
        19.108938,
        100.3140052,
        19.188938
      ]
    },
    {
      "id": 39947,
      "name": "Oleiros",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9189303,
        "lng": -7.9140153
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.0882296,
        39.8107072,
        -7.6910889,
        40.0591487
      ]
    },
    {
      "id": 39958,
      "name": "Saraykent",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6936979,
        "lng": 35.5102049
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        35.400053,
        39.614842,
        35.7186234,
        39.9328547
      ]
    },
    {
      "id": 39972,
      "name": "Mezocsat",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.821528,
        "lng": 20.906758
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.880361,
        47.799543,
        20.917991,
        47.838192
      ]
    },
    {
      "id": 39977,
      "name": "Chitaraque",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0279763,
        "lng": -73.4469335
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.4951355,
        5.8893146,
        -73.3710066,
        6.074597
      ]
    },
    {
      "id": 39997,
      "name": "San Andres Huayapam",
      "names": {
        "es": "San Andrés Huayapam",
        "zh": "圣安德雷斯·沃亚塔普姆",
        "ja": "サンアンドレスhuayapam"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1025043,
        "lng": -96.6660836
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.6860836,
        17.0825043,
        -96.6460836,
        17.1225043
      ]
    },
    {
      "id": 40014,
      "name": "Ilava",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0020357,
        "lng": 18.2361821
      },
      "country": "Slovakia",
      "importance": 2,
      "bbox": [
        18.2311821,
        48.9970357,
        18.2411821,
        49.0070357
      ]
    },
    {
      "id": 40021,
      "name": "Avanos",
      "names": {
        "zh": "阿瓦诺斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7128932,
        "lng": 34.8446643
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.8446143,
        38.7128432,
        34.8447143,
        38.7129432
      ]
    },
    {
      "id": 40062,
      "name": "El Rosario",
      "names": {
        "ja": "エルロザリオ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 10.0449049,
        "lng": -84.3763724
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -84.4124497,
        10.0183943,
        -84.3562467,
        10.0715641
      ]
    },
    {
      "id": 40064,
      "name": "Sernancelhe",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.89871,
        "lng": -7.49342
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.51342,
        40.87871,
        -7.47342,
        40.91871
      ]
    },
    {
      "id": 40072,
      "name": "Penamacor",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.168928,
        "lng": -7.1712605
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.3192426,
        40.0491779,
        -6.9322386,
        40.3246425
      ]
    },
    {
      "id": 40078,
      "name": "Novhorodka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.361368,
        "lng": 32.649833
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        32.629833,
        48.341368,
        32.669833,
        48.381368
      ]
    },
    {
      "id": 40097,
      "name": "Nakkila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3664981,
        "lng": 22.0000209
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.7132774,
        61.2919165,
        22.1661193,
        61.4209814
      ]
    },
    {
      "id": 40130,
      "name": "Urgup",
      "names": {
        "ja": "ユルギュップ",
        "th": "เออร์กัป"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6300508,
        "lng": 34.9116025
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.8811132,
        38.6028635,
        35.0921903,
        38.7094644
      ]
    },
    {
      "id": 40151,
      "name": "Villa Huidobro",
      "names": {
        "zh": "卢迪波罗别墅",
        "ja": "ヴィラフイドブロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.83826,
        "lng": -64.58686
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.60686,
        -34.85826,
        -64.56686,
        -34.81826
      ]
    },
    {
      "id": 40185,
      "name": "Zipacon",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7605894,
        "lng": -74.3802642
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -74.4202642,
        4.7205894,
        -74.3402642,
        4.8005894
      ]
    },
    {
      "id": 40195,
      "name": "San Sebastian del Oeste",
      "names": {
        "ja": "サンセバスチャンデルオースト"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.85525095,
        "lng": -104.81653564032943
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -105.0883476,
        20.6872784,
        -104.5866513,
        21.0233591
      ]
    },
    {
      "id": 40207,
      "name": "Huejucar",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.3593882,
        "lng": -103.211439
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -103.251439,
        22.3193882,
        -103.171439,
        22.3993882
      ]
    },
    {
      "id": 40215,
      "name": "Vadso",
      "names": {
        "it": "Vadsø"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 70.0741267,
        "lng": 29.749777
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        29.709777,
        70.0341267,
        29.789777,
        70.1141267
      ]
    },
    {
      "id": 40240,
      "name": "Yenipazar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8232925,
        "lng": 28.1957304
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.0963325,
        37.6964635,
        28.3012908,
        37.8653791
      ]
    },
    {
      "id": 40245,
      "name": "Kangasniemi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.9895047,
        "lng": 26.6440817
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.2207365,
        61.7886638,
        27.129162,
        62.3077227
      ]
    },
    {
      "id": 40246,
      "name": "Golhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1512806,
        "lng": 29.5110916
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.3255914,
        36.846254,
        29.6567859,
        37.2931317
      ]
    },
    {
      "id": 40255,
      "name": "Atarjea",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.26757,
        "lng": -99.718674
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.724963,
        21.26524,
        -99.715243,
        21.27019
      ]
    },
    {
      "id": 40256,
      "name": "Savastepe",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.38319,
        "lng": 27.65612
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.63612,
        39.36319,
        27.67612,
        39.40319
      ]
    },
    {
      "id": 40276,
      "name": "Waidhofen an der Thaya",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.8146165,
        "lng": 15.284518
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        15.2420859,
        48.7731545,
        15.388426,
        48.8574157
      ]
    },
    {
      "id": 40284,
      "name": "Oguzeli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9654263,
        "lng": 37.5106885
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        37.3466803,
        36.7039154,
        37.7833512,
        37.05377
      ]
    },
    {
      "id": 40364,
      "name": "Beydag",
      "names": {
        "zh": "贝伊达"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.0859301,
        "lng": 28.20843
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.1199359,
        38.0332154,
        28.3318627,
        38.1568771
      ]
    },
    {
      "id": 40366,
      "name": "Szecseny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.0802507,
        "lng": 19.5206814
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        19.4523849,
        48.0540829,
        19.5908161,
        48.1196024
      ]
    },
    {
      "id": 40368,
      "name": "Xoxocotla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.648422,
        "lng": -97.1508146
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.1708146,
        18.628422,
        -97.1308146,
        18.668422
      ]
    },
    {
      "id": 40374,
      "name": "Gmund",
      "names": {
        "es": "Gmünd",
        "fr": "Gmünd"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.7729279,
        "lng": 14.9861752
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        14.9531927,
        48.7404657,
        15.0227389,
        48.8239055
      ]
    },
    {
      "id": 40384,
      "name": "Darkush",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.9918,
        "lng": 36.39361
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.37361,
        35.9718,
        36.41361,
        36.0118
      ]
    },
    {
      "id": 40387,
      "name": "Bang Sai",
      "names": {
        "zh": "邦西",
        "th": "บางไทร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3338746,
        "lng": 100.3028123
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.2730536,
        14.3184429,
        100.3576234,
        14.3558668
      ]
    },
    {
      "id": 40413,
      "name": "Mali",
      "names": {
        "es": "Malí",
        "zh": "马里",
        "ja": "マリ",
        "th": "มาลี"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 12.0755231,
        "lng": -12.2956935
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -12.3356935,
        12.0355231,
        -12.2556935,
        12.1155231
      ]
    },
    {
      "id": 40429,
      "name": "Tomashpil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5487589,
        "lng": 28.5188146
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.4979579,
        48.5308287,
        28.5446852,
        48.5660223
      ]
    },
    {
      "id": 40436,
      "name": "Cochem",
      "names": {
        "zh": "科赫姆",
        "ja": "コッヘム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.14791925,
        "lng": 7.166513791022727
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.1662588,
        50.1476597,
        7.1667645,
        50.1480907
      ]
    },
    {
      "id": 40455,
      "name": "Inga",
      "names": {
        "zh": "印加",
        "ja": "インガ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.0461105,
        "lng": 24.0042086
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.7525616,
        59.7506836,
        24.3162842,
        60.1501204
      ]
    },
    {
      "id": 40456,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6712394,
        "lng": -73.1753984
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.2198486,
        6.5993534,
        -73.1129996,
        6.775753
      ]
    },
    {
      "id": 40460,
      "name": "Malax",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9332091,
        "lng": 21.5667698
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        20.1635668,
        62.7356724,
        21.7851317,
        63.2686318
      ]
    },
    {
      "id": 40462,
      "name": "San Nicolas Tolentino",
      "names": {
        "zh": "圣尼古拉斯托莱蒂诺",
        "ja": "サンニコラストレンティーノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.2472349,
        "lng": -100.5541252
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.5741252,
        22.2272349,
        -100.5341252,
        22.2672349
      ]
    },
    {
      "id": 40468,
      "name": "Dabancheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.3552504,
        "lng": 88.3052087
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        88.2652087,
        43.3152504,
        88.3452087,
        43.3952504
      ]
    },
    {
      "id": 40474,
      "name": "Commercy",
      "names": {
        "zh": "科梅尔西"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.763203,
        "lng": 5.5914753
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.491779,
        48.7134233,
        5.6411834,
        48.7844114
      ]
    },
    {
      "id": 40490,
      "name": "Siatista",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.2601606,
        "lng": 21.5509796
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.5109796,
        40.2201606,
        21.5909796,
        40.3001606
      ]
    },
    {
      "id": 40502,
      "name": "Renswoude",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.072421899999995,
        "lng": 5.5308677368115005
      },
      "country": "Netherlands",
      "importance": 2,
      "bbox": [
        5.4955546,
        52.0394688,
        5.5627463,
        52.1054195
      ]
    },
    {
      "id": 40518,
      "name": "Golega",
      "names": {
        "zh": "古勒冈"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4017959,
        "lng": -8.4867361
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.591264,
        39.3165038,
        -8.4345436,
        39.4596554
      ]
    },
    {
      "id": 40535,
      "name": "Kannus",
      "names": {
        "zh": "坎努斯"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.9007773,
        "lng": 23.9170363
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.6565042,
        63.7562623,
        24.2474703,
        64.0919255
      ]
    },
    {
      "id": 40541,
      "name": "Chiquiza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6053336,
        "lng": -73.4849171
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.5145709,
        5.5615998,
        -73.3765984,
        5.7114367
      ]
    },
    {
      "id": 40543,
      "name": "Kae Dam",
      "names": {
        "es": "Kae presa",
        "zh": "凯恩大坝",
        "de": "Kae Damm",
        "id": "Bendungan kaus",
        "ja": "Kaeダム",
        "th": "เขื่อน",
        "pt": "Dam Kae"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0255072,
        "lng": 103.3878173
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.2278173,
        15.8655072,
        103.5478173,
        16.1855072
      ]
    },
    {
      "id": 40573,
      "name": "Seelow",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.5318238,
        "lng": 14.380704
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        14.3378212,
        52.5127185,
        14.4702961,
        52.5951698
      ]
    },
    {
      "id": 40609,
      "name": "Kham Sakae Saeng",
      "names": {
        "ja": "カムサカエーベ",
        "th": "คำสากราก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.3512628,
        "lng": 102.1724353
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1723853,
        15.3512128,
        102.1724853,
        15.3513128
      ]
    },
    {
      "id": 40615,
      "name": "Espiye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.95,
        "lng": 38.73333
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.71333,
        40.93,
        38.75333,
        40.97
      ]
    },
    {
      "id": 40630,
      "name": "Phanom Thuan",
      "names": {
        "ja": "ファノムトゥアン",
        "th": "ธาตุพนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.132572,
        "lng": 99.703859
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.664198,
        14.100899,
        99.77062,
        14.204204
      ]
    },
    {
      "id": 40643,
      "name": "Aguiar da Beira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.81718,
        "lng": -7.54312
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.6281086,
        40.6903023,
        -7.4219819,
        40.8732901
      ]
    },
    {
      "id": 40656,
      "name": "Naco",
      "names": {
        "zh": "碳酸钠"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3229703,
        "lng": -109.9459547
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -109.9859547,
        31.2829703,
        -109.9059547,
        31.3629703
      ]
    },
    {
      "id": 40663,
      "name": "Teuva",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4869027,
        "lng": 21.7460027
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        21.5115953,
        62.3619048,
        22.1113855,
        62.643257
      ]
    },
    {
      "id": 40673,
      "name": "Lanusei",
      "names": {
        "zh": "拉努塞伊"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.8792127,
        "lng": 9.5411469
      },
      "country": "Italy",
      "importance": 2,
      "bbox": [
        9.4902069,
        39.6030456,
        9.6552864,
        39.8942488
      ]
    },
    {
      "id": 40674,
      "name": "Ardesen",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1934208,
        "lng": 40.9872718
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.9872218,
        41.1933708,
        40.9873218,
        41.1934708
      ]
    },
    {
      "id": 40683,
      "name": "General Conesa",
      "names": {
        "fr": "Consa général",
        "zh": "一般科塞萨",
        "it": "Conesa generale",
        "ja": "一般的なコナサ",
        "th": "โคซ่าทั่วไป",
        "pt": "Conesa geral"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.1061785,
        "lng": -64.4530092
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -64.4713156,
        -40.1131891,
        -64.4390603,
        -40.0932205
      ]
    },
    {
      "id": 40689,
      "name": "San Antonio de los Cobres",
      "names": {
        "de": "San Antonio de los Copfres",
        "ja": "サンアントニオデロスコブレス",
        "th": "ซานอันโตนิโอเดอลอสโคโบ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.2269271,
        "lng": -66.3161209
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.3211209,
        -24.2319271,
        -66.3111209,
        -24.2219271
      ]
    },
    {
      "id": 40701,
      "name": "Cermik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1354868,
        "lng": 39.449684
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.1315154,
        37.8498828,
        39.6600899,
        38.2256729
      ]
    },
    {
      "id": 40718,
      "name": "Teojomulco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.593402,
        "lng": -97.223097
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -97.230536,
        16.583217,
        -97.212431,
        16.60812
      ]
    },
    {
      "id": 40720,
      "name": "Caramanta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5487065,
        "lng": -75.6444056
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -75.7144186,
        5.5157002,
        -75.5689925,
        5.60823
      ]
    },
    {
      "id": 40724,
      "name": "Macachin",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.13890135,
        "lng": -63.66659295871024
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -63.678261,
        -37.1542896,
        -63.6555847,
        -37.1237416
      ]
    },
    {
      "id": 40734,
      "name": "Ko Chang",
      "names": {
        "ja": "コチャン",
        "th": "เกาะช้าง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.1066431,
        "lng": 102.3507809
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.1907809,
        11.9466431,
        102.5107809,
        12.2666431
      ]
    },
    {
      "id": 40759,
      "name": "Hisya'",
      "names": {
        "es": "Hisya '",
        "fr": "Hisya '",
        "de": "Hisya '",
        "it": "Hisya '",
        "id": "Hisya '",
        "ja": "hisya '",
        "th": "hisya '",
        "pt": "Hisya '"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.4104265,
        "lng": 36.7594586
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.7394586,
        34.3904265,
        36.7794586,
        34.4304265
      ]
    },
    {
      "id": 40789,
      "name": "Jokioinen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8033158,
        "lng": 23.4868447
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        23.2770094,
        60.739553,
        23.5646882,
        60.9475798
      ]
    },
    {
      "id": 40804,
      "name": "Bodrum",
      "names": {
        "zh": "博德鲁姆",
        "ja": "ボドルム",
        "th": "โบดรัม"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0345827,
        "lng": 27.4302211
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        27.4301711,
        37.0345327,
        27.4302711,
        37.0346327
      ]
    },
    {
      "id": 40810,
      "name": "Valle de Juarez",
      "names": {
        "es": "Valle de Juárez",
        "ja": "ヴァレデフアレス",
        "th": "วัณโรค"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9335065,
        "lng": -102.9436531
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -102.9836531,
        19.8935065,
        -102.9036531,
        19.9735065
      ]
    },
    {
      "id": 40839,
      "name": "Chateaulin",
      "names": {
        "fr": "Châteaulin"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1968026,
        "lng": -4.0916875
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        -4.1205088,
        48.1682194,
        -4.0318574,
        48.2364542
      ]
    },
    {
      "id": 40853,
      "name": "Sam Sung",
      "names": {
        "fr": "Sam chanté",
        "zh": "山姆唱",
        "it": "Sam cantato",
        "th": "แซมร้อง",
        "pt": "Sam cantado"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5305004,
        "lng": 103.0631105
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.0231105,
        16.4905004,
        103.1031105,
        16.5705004
      ]
    },
    {
      "id": 40862,
      "name": "Ayvacik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9890631,
        "lng": 36.6304882
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        36.3738326,
        40.862526,
        36.7182614,
        41.0868414
      ]
    },
    {
      "id": 40866,
      "name": "Grieskirchen",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2350207,
        "lng": 13.8299881
      },
      "country": "Austria",
      "importance": 2,
      "bbox": [
        13.8299381,
        48.2349707,
        13.8300381,
        48.2350707
      ]
    },
    {
      "id": 40867,
      "name": "Skydra",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.7677111,
        "lng": 22.1552339
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.1152339,
        40.7277111,
        22.1952339,
        40.8077111
      ]
    },
    {
      "id": 40894,
      "name": "Hobol",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6009272,
        "lng": 10.9231492
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.9031492,
        59.5809272,
        10.9431492,
        59.6209272
      ]
    },
    {
      "id": 40895,
      "name": "El Limon",
      "names": {
        "de": "El-Limoner"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8511335,
        "lng": -104.11873539877402
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -104.1919462,
        19.7729753,
        -104.0301976,
        19.9296531
      ]
    },
    {
      "id": 40917,
      "name": "Yarang",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.7613815,
        "lng": 101.29418
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.25418,
        6.7213815,
        101.33418,
        6.8013815
      ]
    },
    {
      "id": 40923,
      "name": "Ixtapangajoya",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.496886,
        "lng": -93.00224
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -93.00708,
        17.494316,
        -92.997657,
        17.499858
      ]
    },
    {
      "id": 40929,
      "name": "San Miguel Panan",
      "names": {
        "zh": "圣米格尔·帕南",
        "ja": "サンミゲルパナン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.53333,
        "lng": -91.36667
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -91.38667,
        14.51333,
        -91.34667,
        14.55333
      ]
    },
    {
      "id": 40930,
      "name": "Kusel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.5399844,
        "lng": 7.4008479
      },
      "country": "Germany",
      "importance": 2,
      "bbox": [
        7.3478468,
        49.5185552,
        7.4356041,
        49.5565826
      ]
    },
    {
      "id": 40935,
      "name": "Sebezh",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2774187,
        "lng": 28.4848862
      },
      "country": "Russia",
      "importance": 2,
      "bbox": [
        28.4257078,
        56.2620352,
        28.5065,
        56.3142339
      ]
    },
    {
      "id": 40947,
      "name": "Haymana",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.4332899,
        "lng": 32.4947912
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.2416972,
        38.9541361,
        32.8422546,
        39.6489226
      ]
    },
    {
      "id": 40948,
      "name": "Tota",
      "names": {
        "zh": "托塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5610508,
        "lng": -72.9858895
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.0258895,
        5.5210508,
        -72.9458895,
        5.6010508
      ]
    },
    {
      "id": 40949,
      "name": "Szikszo",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2016557,
        "lng": 20.9417406
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        20.9367406,
        48.1966557,
        20.9467406,
        48.2066557
      ]
    },
    {
      "id": 40950,
      "name": "Eynesil",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0646879,
        "lng": 39.1434047
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.0879016,
        40.9510097,
        39.1824983,
        41.0793437
      ]
    },
    {
      "id": 40962,
      "name": "Ourique",
      "names": {
        "zh": "欧里克"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.651112,
        "lng": -8.2237262
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.4667908,
        37.4092591,
        -8.1357604,
        37.8626538
      ]
    },
    {
      "id": 40987,
      "name": "Seferhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.36852,
        "lng": 26.86916
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40988,
      "name": "General Bravo",
      "names": {
        "fr": "Général bravo",
        "zh": "一般布拉沃",
        "de": "Allgemeiner Bravo",
        "it": "Generale Bravo",
        "ja": "一般的なブラボー",
        "th": "นายพลไชโย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7932962,
        "lng": -99.1811424
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -99.2211424,
        25.7532962,
        -99.1411424,
        25.8332962
      ]
    },
    {
      "id": 40992,
      "name": "Kisber",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4992603,
        "lng": 18.0300173
      },
      "country": "Hungary",
      "importance": 2,
      "bbox": [
        17.9882788,
        47.4158909,
        18.0804834,
        47.5313346
      ]
    },
    {
      "id": 41016,
      "name": "Darda",
      "names": {
        "zh": "达尔达"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6278597,
        "lng": 18.6932831
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        18.5459575,
        45.5728954,
        18.7186271,
        45.6705045
      ]
    },
    {
      "id": 41022,
      "name": "Totontepec Villa de Morelos",
      "names": {
        "de": "Totontecec Villa de Morelos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.256667,
        "lng": -96.026944
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.066944,
        17.216667,
        -95.986944,
        17.296667
      ]
    },
    {
      "id": 41023,
      "name": "Acula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5059695,
        "lng": -95.7734013
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -95.8134013,
        18.4659695,
        -95.7334013,
        18.5459695
      ]
    },
    {
      "id": 41033,
      "name": "Trogstad",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.641372,
        "lng": 11.316474
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 41058,
      "name": "Surmene",
      "names": {
        "zh": "叙尔梅内"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9127715,
        "lng": 40.1134808
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        40.0271621,
        40.6769703,
        40.2268204,
        40.9281397
      ]
    },
    {
      "id": 41086,
      "name": "Pishchanka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2077558,
        "lng": 28.8864581
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        28.8672577,
        48.189592,
        28.9123266,
        48.226658
      ]
    },
    {
      "id": 41118,
      "name": "Krumovgrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.471176,
        "lng": 25.654383
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        25.63672,
        41.458772,
        25.663564,
        41.486726
      ]
    },
    {
      "id": 41131,
      "name": "Ayancik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.9460725,
        "lng": 34.588309
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        34.3356546,
        41.6877429,
        34.8051987,
        41.9765532
      ]
    },
    {
      "id": 41137,
      "name": "Kyparissia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.251085,
        "lng": 21.6690119
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        21.6290119,
        37.211085,
        21.7090119,
        37.291085
      ]
    },
    {
      "id": 41148,
      "name": "Ilomantsi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.668233,
        "lng": 30.937777
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        30.908891,
        62.646146,
        30.975301,
        62.690209
      ]
    },
    {
      "id": 41173,
      "name": "Bratske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8641873,
        "lng": 31.5747875
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        31.5497625,
        47.8499506,
        31.5956411,
        47.8879106
      ]
    },
    {
      "id": 41174,
      "name": "Mapire",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 7.7425806,
        "lng": -64.7105442
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -64.7349966,
        7.7164369,
        -64.6814061,
        7.7595763
      ]
    },
    {
      "id": 41177,
      "name": "Hareid",
      "names": {
        "zh": "哈雷德"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 62.370609,
        "lng": 6.024935
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        6.011582,
        62.360511,
        6.045161,
        62.381401
      ]
    },
    {
      "id": 41204,
      "name": "Uzumlu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7101148,
        "lng": 39.7010311
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.6300748,
        39.5504844,
        40.1869437,
        39.7917506
      ]
    },
    {
      "id": 41207,
      "name": "Ruokolahti",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.290985,
        "lng": 28.822573
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        28.81333,
        61.26333,
        28.85333,
        61.30333
      ]
    },
    {
      "id": 41243,
      "name": "Lampazos de Naranjo",
      "names": {
        "zh": "Lamagazos de Naranjo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.0259558,
        "lng": -100.5056176
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -100.5456176,
        26.9859558,
        -100.4656176,
        27.0659558
      ]
    },
    {
      "id": 41247,
      "name": "Tha Wang Pha",
      "names": {
        "zh": "王皮",
        "th": "ท่าวังผา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1016783,
        "lng": 100.8067935
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.7667935,
        19.0616783,
        100.8467935,
        19.1416783
      ]
    },
    {
      "id": 41250,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.3344713,
        "lng": -75.6666238
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -76.5152362,
        7.357218,
        -74.7851374,
        9.4478271
      ]
    },
    {
      "id": 41271,
      "name": "Waeng Yai",
      "names": {
        "zh": "伟城亚",
        "ja": "ウェンヤイ",
        "th": "วาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9574428,
        "lng": 102.5398689
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.4998689,
        15.9174428,
        102.5798689,
        15.9974428
      ]
    },
    {
      "id": 41298,
      "name": "San Jose de Pare",
      "names": {
        "zh": "圣何塞德派",
        "ja": "サンノゼ・デパレ",
        "th": "ซานโฮเซ่เดอตัด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.01746,
        "lng": -73.54704
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.56704,
        5.99746,
        -73.52704,
        6.03746
      ]
    },
    {
      "id": 41305,
      "name": "Shaykh al Hadid",
      "names": {
        "id": "Syekh Al Hadid"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.50069,
        "lng": 36.59945
      },
      "country": "Syria",
      "importance": 2,
      "bbox": [
        36.57945,
        36.48069,
        36.61945,
        36.52069
      ]
    },
    {
      "id": 41312,
      "name": "Vila do Bispo",
      "names": {
        "ja": "ビラはビスポをします"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0830218,
        "lng": -8.9119311
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -8.9119811,
        37.0829718,
        -8.9118811,
        37.0830718
      ]
    },
    {
      "id": 41341,
      "name": "Kupiansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.707502,
        "lng": 37.608947
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        37.570352,
        49.621661,
        37.696894,
        49.746681
      ]
    },
    {
      "id": 41349,
      "name": "Nong Muang",
      "names": {
        "zh": "锣曼",
        "it": "Nonnnocchia",
        "th": "หนองม่วง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.23274,
        "lng": 100.66031
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.608975,
        15.214202,
        100.781404,
        15.309118
      ]
    },
    {
      "id": 41354,
      "name": "Arcabuco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.75463,
        "lng": -73.43669
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.45669,
        5.73463,
        -73.41669,
        5.77463
      ]
    },
    {
      "id": 41390,
      "name": "El Cocuy",
      "names": {
        "fr": "El cocouy",
        "zh": "El椰子",
        "pt": "El cocia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4076711,
        "lng": -72.4457503
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.4857503,
        6.3676711,
        -72.4057503,
        6.4476711
      ]
    },
    {
      "id": 41416,
      "name": "Novomykolayivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9781,
        "lng": 35.9102
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        35.8902,
        47.9581,
        35.9302,
        47.9981
      ]
    },
    {
      "id": 41417,
      "name": "Besiri",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9162455,
        "lng": 41.2927679
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        41.2073819,
        37.7254471,
        41.7028027,
        38.0839185
      ]
    },
    {
      "id": 41422,
      "name": "Karystos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0155328,
        "lng": 24.4198624
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        24.3798624,
        37.9755328,
        24.4598624,
        38.0555328
      ]
    },
    {
      "id": 41428,
      "name": "Orchomenos",
      "names": {
        "zh": "奥尔霍迈诺斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.4933208,
        "lng": 22.9797069
      },
      "country": "Greece",
      "importance": 2,
      "bbox": [
        22.9397069,
        38.4533208,
        23.0197069,
        38.5333208
      ]
    },
    {
      "id": 41433,
      "name": "Hankasalmi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.3905202,
        "lng": 26.4397473
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        26.1957045,
        62.1928361,
        26.7803229,
        62.5781378
      ]
    },
    {
      "id": 41464,
      "name": "Parikkala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.550416,
        "lng": 29.497351592159685
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.4614935,
        61.5358978,
        29.5268965,
        61.5668902
      ]
    },
    {
      "id": 41469,
      "name": "Buje",
      "names": {
        "it": "Buie"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4083871,
        "lng": 13.6587529
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        13.6187529,
        45.3683871,
        13.6987529,
        45.4483871
      ]
    },
    {
      "id": 41510,
      "name": "Nong Na Kham",
      "names": {
        "th": "หนองนาคำ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.799282,
        "lng": 102.34314
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        102.333765086,
        16.7902523853,
        102.352514288,
        16.8083122927
      ]
    },
    {
      "id": 41526,
      "name": "Sanom",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2052563,
        "lng": 103.7617924
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.7217924,
        15.1652563,
        103.8017924,
        15.2452563
      ]
    },
    {
      "id": 41528,
      "name": "Meda",
      "names": {
        "zh": "梅达",
        "ja": "メダ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.93039395,
        "lng": -7.251758355250185
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.3799921,
        40.8231323,
        -7.1334954,
        41.0374763
      ]
    },
    {
      "id": 41530,
      "name": "Jinzhong",
      "names": {
        "zh": "晋中"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4193738,
        "lng": 103.280146
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        103.240146,
        26.3793738,
        103.320146,
        26.4593738
      ]
    },
    {
      "id": 41537,
      "name": "San Jose del Golfo",
      "names": {
        "zh": "圣何塞德德尔戈尔戈",
        "ja": "サンホセルゴルフー"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7636047,
        "lng": -90.3721555
      },
      "country": "Guatemala",
      "importance": 2,
      "bbox": [
        -90.4121555,
        14.7236047,
        -90.3321555,
        14.8036047
      ]
    },
    {
      "id": 41545,
      "name": "Bobov Dol",
      "names": {
        "es": "Botov dol",
        "zh": "鲍勃DOL",
        "pt": "Dol do Bobov"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.365982,
        "lng": 23.003872
      },
      "country": "Bulgaria",
      "importance": 2,
      "bbox": [
        22.99142,
        42.354523,
        23.016585,
        42.384848
      ]
    },
    {
      "id": 41560,
      "name": "Vila Nova de Paiva",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.8488759,
        "lng": -7.731096262979038
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.7516238,
        40.8293373,
        -7.7110261,
        40.8678327
      ]
    },
    {
      "id": 41575,
      "name": "San Cristobal Amatlan",
      "names": {
        "es": "San Cristóbal Amatlan",
        "ja": "サンクリストバルアマトラン",
        "th": "ซาน Cristobal Amatlan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3187851,
        "lng": -96.4088179
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.4488179,
        16.2787851,
        -96.3688179,
        16.3587851
      ]
    },
    {
      "id": 41602,
      "name": "Larsmo",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.7619778,
        "lng": 22.7564516
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        22.4364516,
        63.4419778,
        23.0764516,
        64.0819778
      ]
    },
    {
      "id": 41612,
      "name": "Forcalquier",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 43.959507,
        "lng": 5.77965
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        5.73912,
        43.910979,
        5.829211,
        44.005379
      ]
    },
    {
      "id": 41633,
      "name": "In Buri",
      "names": {
        "es": "En buri",
        "fr": "À Buri",
        "zh": "在Buri",
        "id": "Di buri",
        "ja": "ブリの中で",
        "th": "ในจังหวัดบุรี",
        "pt": "Em buri"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.0128451,
        "lng": 100.3313284
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        100.2913284,
        14.9728451,
        100.3713284,
        15.0528451
      ]
    },
    {
      "id": 41649,
      "name": "Mae Chai",
      "names": {
        "ja": "メーチャイ",
        "th": "แม่ชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.3478091,
        "lng": 99.8143315
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.7743315,
        19.3078091,
        99.8543315,
        19.3878091
      ]
    },
    {
      "id": 41651,
      "name": "Volovets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7146406,
        "lng": 23.1888322
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        23.097587,
        48.6444177,
        23.2280693,
        48.7208302
      ]
    },
    {
      "id": 41659,
      "name": "Kut Bak",
      "names": {
        "zh": "洗甲",
        "id": "Kucing",
        "th": "กุดบาก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.0898833,
        "lng": 103.8212935
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        103.8212435,
        17.0898333,
        103.8213435,
        17.0899333
      ]
    },
    {
      "id": 41680,
      "name": "Pornainen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4756432,
        "lng": 25.3745932
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.2040775,
        60.4007437,
        25.5101834,
        60.5543001
      ]
    },
    {
      "id": 41684,
      "name": "Gokcebey",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.3056431,
        "lng": 32.1429562
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        32.0496595,
        41.2320963,
        32.2898388,
        41.3745339
      ]
    },
    {
      "id": 41737,
      "name": "Si Sakhon",
      "names": {
        "de": "Siakhon",
        "ja": "シェサコン",
        "th": "ศรีสะเกษ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.2296446,
        "lng": 101.4995905
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        101.4595905,
        6.1896446,
        101.5395905,
        6.2696446
      ]
    },
    {
      "id": 41752,
      "name": "Askola",
      "names": {
        "zh": "阿斯科拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5271593,
        "lng": 25.6000156
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        25.4143135,
        60.469907,
        25.8232078,
        60.6141339
      ]
    },
    {
      "id": 41753,
      "name": "Sievi",
      "names": {
        "zh": "谢维"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.9082625,
        "lng": 24.5159868
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        24.1270467,
        63.6486636,
        24.9685421,
        64.0296207
      ]
    },
    {
      "id": 41769,
      "name": "Cobanlar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.4669313,
        "lng": 30.2347755
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        30.2147755,
        39.4469313,
        30.2547755,
        39.4869313
      ]
    },
    {
      "id": 41773,
      "name": "Betulia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.9006397,
        "lng": -73.2835591
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -73.538098,
        6.8499144,
        -73.2171651,
        7.1536452
      ]
    },
    {
      "id": 41781,
      "name": "Alfandega da Fe",
      "names": {
        "pt": "Alfandaga da fe"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.342797,
        "lng": -6.963304
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -6.968372,
        41.33714,
        -6.956258,
        41.352203
      ]
    },
    {
      "id": 41784,
      "name": "Joroinen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.17934,
        "lng": 27.834178
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        27.79727,
        62.164311,
        27.894063,
        62.194465
      ]
    },
    {
      "id": 41790,
      "name": "Vakfikebir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.04583,
        "lng": 39.27639
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        39.25639,
        41.02583,
        39.29639,
        41.06583
      ]
    },
    {
      "id": 41798,
      "name": "Montbard",
      "names": {
        "zh": "蒙巴尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6241674,
        "lng": 4.3373194
      },
      "country": "France",
      "importance": 2,
      "bbox": [
        4.2784965,
        47.6038355,
        4.4099952,
        47.7137899
      ]
    },
    {
      "id": 41805,
      "name": "Ilok",
      "names": {
        "zh": "伊洛克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.222009,
        "lng": 19.374851
      },
      "country": "Croatia",
      "importance": 2,
      "bbox": [
        19.353143,
        45.21297,
        19.402067,
        45.229509
      ]
    },
    {
      "id": 41815,
      "name": "Labranzagrande",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5629086,
        "lng": -72.5779936
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -72.6179936,
        5.5229086,
        -72.5379936,
        5.6029086
      ]
    },
    {
      "id": 41818,
      "name": "Phanom",
      "names": {
        "zh": "帕侬",
        "th": "พนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.8598548,
        "lng": 98.8145143
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        98.7745143,
        8.8198548,
        98.8545143,
        8.8998548
      ]
    },
    {
      "id": 41819,
      "name": "Thung Yai",
      "names": {
        "th": "ทุ่งใหญ่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.3033101,
        "lng": 99.3659556
      },
      "country": "Thailand",
      "importance": 2,
      "bbox": [
        99.3259556,
        8.2633101,
        99.4059556,
        8.3433101
      ]
    },
    {
      "id": 41833,
      "name": "Oyer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 61.2630319,
        "lng": 10.416242
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        10.396242,
        61.2430319,
        10.436242,
        61.2830319
      ]
    },
    {
      "id": 41847,
      "name": "Sousel",
      "names": {
        "zh": "索西尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.953312,
        "lng": -7.67462
      },
      "country": "Portugal",
      "importance": 2,
      "bbox": [
        -7.688534,
        38.947978,
        -7.66852,
        38.960364
      ]
    },
    {
      "id": 41851,
      "name": "Jalcomulco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.331944,
        "lng": -96.761667
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.801667,
        19.291944,
        -96.721667,
        19.371944
      ]
    },
    {
      "id": 41892,
      "name": "Chaiten",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -42.9165335,
        "lng": -72.7084192
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -73.4370804,
        -43.7972228,
        -72.1184743,
        -42.1173251
      ]
    },
    {
      "id": 41911,
      "name": "Esme",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3998673,
        "lng": 28.9670144
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        28.7543641,
        38.2192873,
        29.1898735,
        38.658874
      ]
    },
    {
      "id": 41927,
      "name": "Basmakci",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8973577,
        "lng": 30.0097784
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        29.8868858,
        37.7774879,
        30.1588123,
        37.9964781
      ]
    },
    {
      "id": 41950,
      "name": "Emirgazi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.90222,
        "lng": 33.83722
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        33.81722,
        37.88222,
        33.85722,
        37.92222
      ]
    },
    {
      "id": 41956,
      "name": "Juuka",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.2412123,
        "lng": 29.2532237
      },
      "country": "Finland",
      "importance": 2,
      "bbox": [
        29.2531737,
        63.2411623,
        29.2532737,
        63.2412623
      ]
    },
    {
      "id": 41963,
      "name": "San Jeronimo Coatlan",
      "names": {
        "es": "SAN JERONIMO ACOPLAN",
        "zh": "San Jeronimo Toodlan",
        "ja": "サンエロニモコートラン",
        "pt": "Coatlan de San Jeronimo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2325,
        "lng": -96.869444
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.889444,
        16.2125,
        -96.849444,
        16.2525
      ]
    },
    {
      "id": 41998,
      "name": "Baskil",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5676802,
        "lng": 38.8232458
      },
      "country": "Turkey",
      "importance": 2,
      "bbox": [
        38.3573921,
        38.3935788,
        38.9765023,
        38.7542924
      ]
    },
    {
      "id": 42002,
      "name": "Chechelnyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2126987,
        "lng": 29.3598689
      },
      "country": "Ukraine",
      "importance": 2,
      "bbox": [
        29.3084874,
        48.2008252,
        29.3870289,
        48.2299275
      ]
    },
    {
      "id": 42022,
      "name": "Svolvaer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 68.2321709,
        "lng": 14.5646019
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        14.5246019,
        68.1921709,
        14.6046019,
        68.2721709
      ]
    },
    {
      "id": 42025,
      "name": "Perito Moreno",
      "names": {
        "zh": "佩里托莫雷诺",
        "ja": "ペリトモレノ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -46.5902897,
        "lng": -70.9254382
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -70.9392924,
        -46.6036193,
        -70.9082528,
        -46.5809092
      ]
    },
    {
      "id": 42029,
      "name": "Gobernador Gregores",
      "names": {
        "zh": "戈伯纳多尔格雷格"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -48.7508681,
        "lng": -70.2485805
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -70.2690375,
        -48.760726,
        -70.2266819,
        -48.7418982
      ]
    },
    {
      "id": 42031,
      "name": "Abra Pampa",
      "names": {
        "ja": "アブラパンパ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.7227697,
        "lng": -65.6969972
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.5913151,
        -23.1335982,
        -65.5078829,
        -22.5567013
      ]
    },
    {
      "id": 42033,
      "name": "Victorica",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -36.2164693,
        "lng": -65.4370456
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.4479224,
        -36.2300185,
        -65.419786,
        -36.2037912
      ]
    },
    {
      "id": 42035,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -16.4955455,
        "lng": -68.1336229
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -68.2458469,
        -16.5923149,
        -67.6237089,
        -15.7586421
      ]
    },
    {
      "id": 42042,
      "name": "Comandante Fontana",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -25.3335851,
        "lng": -59.6848511
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -59.7048511,
        -25.3535851,
        -59.6648511,
        -25.3135851
      ]
    },
    {
      "id": 42054,
      "name": "Nautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2083003,
        "lng": -96.7741507
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -96.8141507,
        20.1683003,
        -96.7341507,
        20.2483003
      ]
    },
    {
      "id": 42055,
      "name": "Finnsnes",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 69.23341,
        "lng": 17.981298
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        17.969156,
        69.226192,
        18.050217,
        69.24429
      ]
    },
    {
      "id": 42056,
      "name": "Sapouy",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 11.55444,
        "lng": -1.77361
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        -1.79361,
        11.53444,
        -1.75361,
        11.57444
      ]
    },
    {
      "id": 42065,
      "name": "Ulaan-Uul",
      "names": {
        "zh": "乌兰-多山"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 44.4452697,
        "lng": 111.0988366
      },
      "country": "Mongolia",
      "importance": 2,
      "bbox": [
        111.0938366,
        44.4402697,
        111.1038366,
        44.4502697
      ]
    },
    {
      "id": 42069,
      "name": "Yomou",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 7.5695786,
        "lng": -9.2597491
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -9.2997491,
        7.5295786,
        -9.2197491,
        7.6095786
      ]
    },
    {
      "id": 42072,
      "name": "Tessalit",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 20.2019542,
        "lng": 1.0124531
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        0.9724531,
        20.1619542,
        1.0524531,
        20.2419542
      ]
    },
    {
      "id": 42075,
      "name": "Kirkenes",
      "names": {
        "zh": "希尔克内斯"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 69.7271478,
        "lng": 30.0448971
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        30.0048971,
        69.6871478,
        30.0848971,
        69.7671478
      ]
    },
    {
      "id": 42080,
      "name": "Sebba",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.44169,
        "lng": 0.5264471
      },
      "country": "Burkina Faso",
      "importance": 2,
      "bbox": [
        0.4864471,
        13.40169,
        0.5664471,
        13.48169
      ]
    },
    {
      "id": 42085,
      "name": "Mkokotoni",
      "names": {
        "zh": "姆科科托尼"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.878998,
        "lng": 39.259999
      },
      "country": "Tanzania",
      "importance": 2,
      "bbox": [
        39.239998618,
        -5.8989979746,
        39.279998618,
        -5.8589979746
      ]
    },
    {
      "id": 42087,
      "name": "Sinnamary",
      "names": {},
      "countryCode": "GF",
      "latLng": {
        "lat": 5.380019,
        "lng": -52.959982
      },
      "country": "French Guiana",
      "importance": 2,
      "bbox": [
        -52.9799821387,
        5.36001914359,
        -52.9399821387,
        5.40001914359
      ]
    },
    {
      "id": 42100,
      "name": "Pampa del Infierno",
      "names": {
        "id": "Pampa del inferno"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.516587,
        "lng": -61.166587
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -61.1865871599,
        -26.5365868949,
        -61.1465871599,
        -26.4965868949
      ]
    },
    {
      "id": 42101,
      "name": "Kailu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.6306824,
        "lng": 121.2790965
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        121.2740965,
        43.6256824,
        121.2840965,
        43.6356824
      ]
    },
    {
      "id": 42108,
      "name": "Cochrane",
      "names": {
        "zh": "科克伦",
        "ja": "コクラン"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": 49.0601478,
        "lng": -81.0232939
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -81.0282939,
        49.0551478,
        -81.0182939,
        49.0651478
      ]
    },
    {
      "id": 42109,
      "name": "Saint-Georges",
      "names": {
        "zh": "圣 - 乔治·",
        "ja": "サン・ジョルジュ",
        "th": "นักบุญจอร์จ"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 12.0535331,
        "lng": -61.751805
      },
      "country": "French Guiana",
      "importance": 2,
      "bbox": [
        -61.791805,
        12.0135331,
        -61.711805,
        12.0935331
      ]
    },
    {
      "id": 42112,
      "name": "Rorvik",
      "names": {
        "zh": "勒尔维克"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 64.8615745,
        "lng": 11.2379128
      },
      "country": "Norway",
      "importance": 2,
      "bbox": [
        11.1979128,
        64.8215745,
        11.2779128,
        64.9015745
      ]
    },
    {
      "id": 42115,
      "name": "Chumbicha",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -28.866624,
        "lng": -66.233306
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.2533063168,
        -28.8866243299,
        -66.2133063168,
        -28.8466243299
      ]
    },
    {
      "id": 42127,
      "name": "Boffa",
      "names": {
        "zh": "博法"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.1812281,
        "lng": -14.0377043
      },
      "country": "Guinea",
      "importance": 2,
      "bbox": [
        -14.0777043,
        10.1412281,
        -13.9977043,
        10.2212281
      ]
    },
    {
      "id": 42129,
      "name": "Jurado",
      "names": {
        "zh": "胡拉多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.1031695,
        "lng": -77.7623252
      },
      "country": "Colombia",
      "importance": 2,
      "bbox": [
        -77.8934597,
        6.6478449,
        -77.3750167,
        7.4944151
      ]
    },
    {
      "id": 42137,
      "name": "Roura",
      "names": {},
      "countryCode": "GF",
      "latLng": {
        "lat": 4.7260468,
        "lng": -52.3259186
      },
      "country": "French Guiana",
      "importance": 2,
      "bbox": [
        -52.8574764,
        4.0952909,
        -52.1628224,
        4.8567426
      ]
    },
    {
      "id": 42147,
      "name": "Mazatan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 28.93120375,
        "lng": -110.35608737715526
      },
      "country": "Mexico",
      "importance": 2,
      "bbox": [
        -110.4332738,
        28.7594344,
        -109.9169842,
        29.103437
      ]
    },
    {
      "id": 42159,
      "name": "Puerto Williams",
      "names": {
        "zh": "波多黎各威廉姆斯",
        "ja": "プエルトウィリアムズ",
        "th": "เปอร์โตวิลเลียมส์"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -54.9336195,
        "lng": -67.6104892
      },
      "country": "Chile",
      "importance": 2,
      "bbox": [
        -67.6130002,
        -54.9338809,
        -67.6072107,
        -54.9333292
      ]
    },
    {
      "id": 42161,
      "name": "Trancas",
      "names": {
        "zh": "特兰卡斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.2292049,
        "lng": -65.28053037797763
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -65.2884735,
        -26.2413671,
        -65.268488,
        -26.2175941
      ]
    },
    {
      "id": 42163,
      "name": "Alto Rio Senguer",
      "names": {
        "zh": "Alto Rio Sengueer",
        "ja": "アルトリオセンファー"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -45.0419751,
        "lng": -70.8234228
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -70.8339967,
        -45.0468581,
        -70.8110902,
        -45.0346086
      ]
    },
    {
      "id": 42166,
      "name": "Sierra Colorada",
      "names": {
        "zh": "Sierra Colotada",
        "ja": "シエラコロラダ",
        "th": "เซียร์ราโคลดา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.5849692,
        "lng": -67.7568775
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -67.7691071,
        -40.5921939,
        -67.7481993,
        -40.579348
      ]
    },
    {
      "id": 42167,
      "name": "Iracoubo",
      "names": {},
      "countryCode": "GF",
      "latLng": {
        "lat": 5.4775939,
        "lng": -53.2068996
      },
      "country": "French Guiana",
      "importance": 2,
      "bbox": [
        -53.5377906,
        4.9169965,
        -53.0580633,
        5.574599
      ]
    },
    {
      "id": 42178,
      "name": "Tarutung",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.0230378,
        "lng": 98.9649283
      },
      "country": "Indonesia",
      "importance": 2,
      "bbox": [
        98.9553798,
        2.0067381,
        98.9786614,
        2.0348623
      ]
    },
    {
      "id": 42181,
      "name": "Jaque",
      "names": {
        "zh": "哈克"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 7.5179233,
        "lng": -78.1623512
      },
      "country": "Panama",
      "importance": 2,
      "bbox": [
        -78.1874603,
        7.2223219,
        -77.8181846,
        7.6923269
      ]
    },
    {
      "id": 42186,
      "name": "Las Lajas",
      "names": {
        "zh": "拉马斯",
        "ja": "ラスラジアス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.5234753,
        "lng": -70.3616441
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -70.3869027,
        -38.5449723,
        -70.317678,
        -38.5156809
      ]
    },
    {
      "id": 42193,
      "name": "Susques",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -23.3991768,
        "lng": -66.3675681
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.8400481,
        -24.2132191,
        -66.187792,
        -23.1340067
      ]
    },
    {
      "id": 42197,
      "name": "Yelimane",
      "names": {
        "zh": "耶利马内"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 15.1189,
        "lng": -10.572839
      },
      "country": "Mali",
      "importance": 2,
      "bbox": [
        -10.732839,
        14.9589,
        -10.412839,
        15.2789
      ]
    },
    {
      "id": 42216,
      "name": "Linxi",
      "names": {
        "zh": "林西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.6141467,
        "lng": 118.049795
      },
      "country": "China",
      "importance": 2,
      "bbox": [
        117.889795,
        43.4541467,
        118.209795,
        43.7741467
      ]
    },
    {
      "id": 42222,
      "name": "Rodeo",
      "names": {
        "zh": "竞技",
        "ja": "ロデオ",
        "th": "ปศุสัตว์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.2101856,
        "lng": -69.1315839
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -69.1515839,
        -30.2301856,
        -69.1115839,
        -30.1901856
      ]
    },
    {
      "id": 42231,
      "name": "Gastre",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -42.2631553,
        "lng": -69.2219873
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -69.2289305,
        -42.2740222,
        -69.216367,
        -42.2575831
      ]
    },
    {
      "id": 42232,
      "name": "Tinogasta",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -28.0655005,
        "lng": -67.5644496
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -68.3535648,
        -28.3841853,
        -66.8452555,
        -27.4388216
      ]
    },
    {
      "id": 42236,
      "name": "Telsen",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -42.4364571,
        "lng": -66.9391849
      },
      "country": "Argentina",
      "importance": 2,
      "bbox": [
        -66.9591849,
        -42.4564571,
        -66.9191849,
        -42.4164571
      ]
    },
    {
      "id": 42259,
      "name": "Greytown",
      "names": {
        "zh": "格雷敦"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 10.946566,
        "lng": -83.7356688
      },
      "country": "Nicaragua",
      "importance": 2,
      "bbox": [
        -84.1583097,
        10.7073945,
        -83.6606437,
        11.1345861
      ]
    },
    {
      "id": 42263,
      "name": "La Esmeralda",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 3.1771934,
        "lng": -65.5433711
      },
      "country": "Venezuela",
      "importance": 2,
      "bbox": [
        -65.5793774,
        3.134817,
        -65.5008746,
        3.2045334
      ]
    },
    {
      "id": 42272,
      "name": "Hodrogo",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.966428,
        "lng": 96.783281
      },
      "country": "Mongolia",
      "importance": 2,
      "bbox": [
        96.7632808014,
        48.9464284455,
        96.8032808014,
        48.9864284455
      ]
    },
    {
      "id": 42281,
      "name": "Charlotte Amalie",
      "names": {
        "zh": "夏洛特阿马利",
        "ja": "シャーロットアマリエ"
      },
      "countryCode": "VI",
      "latLng": {
        "lat": 18.34389,
        "lng": -64.933143
      },
      "country": "U.S. Virgin Islands",
      "importance": 0,
      "bbox": [
        -64.9507,
        18.3219,
        -64.9107,
        18.3619
      ]
    },
    {
      "id": 42387,
      "name": "Iqaluit",
      "names": {
        "zh": "伊卡卢伊特",
        "ja": "イカルイト"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 63.7492738,
        "lng": -68.5213763
      },
      "country": "Canada",
      "importance": 1,
      "bbox": [
        -68.6012079,
        63.7085246,
        -68.4254267,
        63.7763427
      ]
    }
  ]
}